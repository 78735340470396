import {
    DEPARTMENT_LOADING,
    RETRIEVE_DEPARTMENT,
    DELETE_DEPARTMENT,
} from "./types";

import DepartmentService from "../services/Master/DepartmentService";

const departmentLoading = (isStatus) => ({
    type: DEPARTMENT_LOADING,
    payload: isStatus,
});

export const deleteDepartment = (departmentDetailsID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_DEPARTMENT,
            payload: departmentDetailsID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrieveDepartment = (DepartmentId) => async (dispatch) => {
    try {
        dispatch(departmentLoading(true));
        const res = await DepartmentService.GetDepartmentList(DepartmentId);
        dispatch({
            type: RETRIEVE_DEPARTMENT,
            payload: res.data,
        });
        dispatch(departmentLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(departmentLoading(false));
    }
};