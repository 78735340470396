import {
    WORKMANSHIPGROUP_LOADING,
    RETRIEVE_WORKMANSHIPGROUP,
    DELETE_WORKMANSHIPGROUP,
} from "./types";
import WorkmanshipGroup from "../services/Master/WorkmanshiGroup";

const WorkmanshipGrpLoading = (isStatus) => ({
    type: WORKMANSHIPGROUP_LOADING,
    payload: isStatus,
});

export const deleteWorkmanshipGrp = (workmanshipGroupID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_WORKMANSHIPGROUP,
            payload: workmanshipGroupID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrieveworkmanshipGrp = (Operation, Id) => async (dispatch) => {
    try {
        dispatch(WorkmanshipGrpLoading(true));
        const res = await WorkmanshipGroup.GetworkmanshipGroupList(Operation, Id);
        dispatch({
            type: RETRIEVE_WORKMANSHIPGROUP,
            payload: res.data,
        });
        dispatch(WorkmanshipGrpLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(WorkmanshipGrpLoading(false));
    }
};