import React, { useState, useEffect, useMemo, useRef } from "react";
import ReactNotification from 'react-notifications-component';
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import 'react-notifications-component/dist/theme.css';
import Select from "react-validation/build/select";
import PrintColor from "../StrikeOff/PrintColor";
import strikeoffService from "../../../services/Style/StrikeoffService";
import BuyerService from "../../../services/Master/BuyerService";
import styleService from "../../../services/Style/styleService";
import Reference from "../StrikeOff/Reference";
import Nodify from "../../Common/ReactNotification";
import SearchRetain from "../../Common/SearchRetain";
import Reactselect from 'react-select';
import StyleService from "../../../services/Style/styleService";
import BrandEndCustomerStoryTheme from "../../quickMaster/_BrandEndCustomerStoryTheme"
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import moment from "moment";
const StrikeOffListRegister = (props, Buttonview) => {
    const { user: currentUser } = useSelector((state) => state.auth);

    // If check production or sampling
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProduction = activeMenus.IsProduction;
    // End
    //Enable Menu
    let activeMenu;
    let activeMenuSub;
    // let StrikeInfo;
    let StrikeoffInfoQuick = [];
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
    if (menulist !== undefined && menulistSub.length > 0) {
        activeMenu = menulist.filter(i => i.menuName === 'Style');
        if (activeMenu.length > 0) {
            activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);

            if (activeMenuSub.length > 0) {
                for (let index = 0; index < activeMenuSub.length; index++) {
                    if (activeMenuSub[index].menuName === 'StyleStrikeoff') {
                        // StrikeInfo = activeMenuSub[index];
                        StrikeoffInfoQuick.Brand = menulist.filter(i => i.menuName === 'Brand')
                        StrikeoffInfoQuick.PantoneType = menulist.filter(i => i.menuName === 'PantoneType')
                        StrikeoffInfoQuick.Pantone = menulist.filter(i => i.menuName === 'Pantone')
                        StrikeoffInfoQuick.Swatch = menulist.filter(i => i.menuName === 'Swatch')
                    }
                }
            }
        }
    }

    const EmptyInputFields =
    {
        Operation: 1,
        StrikeoffID: 0,
        BuyerID: 0,
        BuyerBrandID: 0,
        StrikeOffName: '',
        StrikeOffCode: '',
        BuyerReferenceNo: '',
        Quality: '',
        PurchaseType: 0,
        TrimSupplier: 0,
        IsMapforTest: 0,
        CreatedBy: currentUser.employeeinformationID,
    }
    const [getStrikeoffList, setStrikeoffList] = useState([EmptyInputFields]);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [PurchaseTypeList, setPurchaseTypeList] = useState([]);
    const [getBuyerList, setBuyerList] = useState([]);
    const [getBuyerBrandList, setBuyerBrandList] = useState([]);
    const [getAllBrandList, setAllBrandList] = useState([]);
    const [getAllTrimSupplierList, setAllTrimSupplierList] = useState([]);
    const [getBuyerTrimSupplierList, setBuyerTrimSupplierList] = useState([]);
    const [getColorgroupList, setColorgroupList] = useState({});
    const [getUOMList, setUOMList] = useState({});
    const [IsParentCallback, setIsParentCallback] = useState(false);
    const [IsUOMParentCallback, setUOMIsParentCallback] = useState(false);
    const [getstate_StrikeoffId, setstate_StrikeoffId] = useState(0);
    const [ButtonName, setButtonName] = useState("Save");
    const [getHeaderName, addHeaderName] = useState("Add");
    const [getPrintColorTabDetails, setPrintColorTabDetails] = useState([]);
    const [getExistReferenceList, setExistReferenceList] = useState([]);
    const [Isdisabled, setDisabled] = useState(false);
    const [IsFromMaster, setIsFromMaster] = useState(false);
    const [IsFromGeneral, setIsFromGenaral] = useState(false);
    const [StyleID, setStyleID] = useState(0);
    const [BuyerID, setBuyerID] = useState(0);
    const [getQuickMasterName, setQuickMasterName] = useState('');
    var defaultStartDate = new Date();
    const [stateDate, setStateDate] = useState([
        {
            startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 190),
            endDate: new Date(),
            key: 'selection',
            isShow: false
        }
    ]);
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),
    }

    const values = [...getStrikeoffList];

    const inputRef = useRef();

    function BindStrikeValue(props) {
        let StrikeoffValue = getStrikeoffList[0];
        if (props.location.state !== undefined) {
            if (props.location.state.params.length !== 0) {
                if (props.location.state.params.strikeOffID !== undefined) {
                    setstate_StrikeoffId(props.location.state.params.strikeOffID);
                    setButtonName("Update");
                    StrikeoffValue.Operation = 2;
                    StrikeoffValue.StrikeoffID = props.location.state.params.strikeOffID;
                    StrikeoffValue.BuyerID = props.location.state.params.buyerID;
                    let respected_Brand = getAllBrandList.filter(x => (x.buyerID === parseInt(props.location.state.params.buyerID)) && (x.buyerMapID === 1));
                    setBuyerBrandList(respected_Brand);
                    let respected_TrimSupplier = getAllTrimSupplierList.filter(x => x.buyerID === parseInt(props.location.state.params.buyerID));
                    setBuyerTrimSupplierList(respected_TrimSupplier);
                    StrikeoffValue.BuyerBrandID = props.location.state.params.buyerBrandID;
                    StrikeoffValue.StrikeOffName = props.location.state.params.strikeOffName;
                    StrikeoffValue.StrikeOffCode = props.location.state.params.strikeOffCode;
                    StrikeoffValue.BuyerReferenceNo = props.location.state.params.buyerReferenceNo;
                    StrikeoffValue.Quality = props.location.state.params.quality;
                    StrikeoffValue.PurchaseType = props.location.state.params.purchaseType;
                    StrikeoffValue.TrimSupplier = props.location.state.params.trimSupplier;
                    StrikeoffValue.IsMapforTest = props.location.state.params.isMapforTest;
                    StrikeoffValue.CreatedBy = currentUser.employeeinformationID;

                    if (props.location.state.params.isStyleMapped === 1) {
                        setDisabled(true);
                    }

                    addHeaderName("Edit");
                    return StrikeoffValue;
                }
                else if (props.location.state.params.From === "Style") {
                    setDisabled(true);
                    setIsFromMaster(true);
                    setStyleID(props.location.state.params.StyleID);
                    StrikeoffValue.BuyerID = props.location.state.params.BuyerID;
                    let respected_Brand = getAllBrandList.filter(x => (x.buyerID === parseInt(props.location.state.params.BuyerID)) && (x.buyerMapID === 1));
                    setBuyerBrandList(respected_Brand);
                    let respected_TrimSupplier = getAllTrimSupplierList.filter(x => x.buyerID === parseInt(props.location.state.params.BuyerID));
                    setBuyerTrimSupplierList(respected_TrimSupplier);
                    StrikeoffValue.BuyerBrandID = props.location.state.params.BrandID;

                    return StrikeoffValue;
                }
            }

        }

        else {
            return StrikeoffValue;
        }
    }
    //Enter Error msg
    // useEffect(() => {
    //     const listener = event => {
    //         if (event.code === "Enter" || event.code === "NumpadEnter") {
    //             event.preventDefault();
    //             //SaveBuyerInfo(event);
    //         }
    //     };
    //     document.addEventListener("keydown", listener);
    //     return () => {
    //         document.removeEventListener("keydown", listener);
    //     };
    // }, []);
    useEffect(() => {

        BuyerService.LoadBuyerList(2).then((response) => {
            if (response.data) {
                setBuyerList(response.data);
            }
        }).catch(() => { });

        strikeoffService.LoadPurchaseTypeList().then((response) => {
            if (response.data) {
                setPurchaseTypeList(response.data);
            }
        }).catch(() => { });

        styleService.LoadBrandThemeStoryEndCustomer(6, 0).then((response) => {
            if (response.data) {
                setAllBrandList(response.data);
                if (props.location.state !== undefined) {
                    if (props.location.state.params.From === "Style") {
                        let respected_Brand = response.data.filter(x => (x.buyerID === parseInt(props.location.state.params.BuyerID)) && (x.buyerMapID === 1));
                        setBuyerBrandList(respected_Brand);
                    }
                    else {
                        let respected_Brand = response.data.filter(x => (x.buyerID === parseInt(props.location.state.params.buyerID)) && (x.buyerMapID === 1));
                        setBuyerBrandList(respected_Brand);
                    }
                }
            }
        }).catch(() => { });

        strikeoffService.LoadTrimSupplierList(0).then((response) => {
            if (response.data) {
                setAllTrimSupplierList(response.data);
                if (props.location.state !== undefined) {
                    if (props.location.state.params.From === "Style") {
                        let respected_TrimSupplier = response.data.filter(x => x.buyerID === parseInt(props.location.state.params.BuyerID));
                        setBuyerTrimSupplierList(respected_TrimSupplier);
                    }
                    else {
                        let respected_TrimSupplier = response.data.filter(x => x.buyerID === parseInt(props.location.state.params.buyerID));
                        setBuyerTrimSupplierList(respected_TrimSupplier);
                    }
                }
            }
        }).catch(() => { });

    }, []);

    useEffect(() => {
        if (props.location.state !== undefined) {
            if (props.location.state.params.Redirecttomaster === undefined) {
                let ExistValue = BindStrikeValue(props);
                setStrikeoffList([ExistValue]);

                strikeoffService.BindStrikeOffTabInfo(props.location.state.params.strikeOffID).then((response) => {
                    debugger
                    if (response.data) {
                        setPrintColorTabDetails(response.data);
                    }
                }).catch(() => { });

                strikeoffService.GetStrikeOffReferenceList(props.location.state.params.strikeOffID).then((response) => {
                    if (response.data) {
                        setExistReferenceList(response.data);
                    }
                }).catch(() => { });
            }
            else {
                getStrikeoffList[0].BuyerID = props.location.state.params.buyerId;
                getStrikeoffList[0].BuyerBrandID = props.location.state.params.brandId;
                styleService.LoadBrandThemeStoryEndCustomer(6, 0).then((response) => {
                    if (response.data) {
                        let respected_Brand = response.data.filter(x => (x.buyerID === parseInt(props.location.state.params.buyerId)) && (x.buyerMapID === 1));
                        setBuyerBrandList(respected_Brand);



                    }
                }).catch(() => { });
                setStrikeoffList(getStrikeoffList);

            }
        }
    }, []);

    const ColorGroupCallBack = (value, IsParentCallback) => {
        setColorgroupList(value);
    }

    const SizeUOMCallBack = (value, IsUOMParentCallback) => {
        setUOMList(value);
    }

    const ResetOperation = (e) => {
        if (e === "Back") {
            if (IsFromMaster) {
                GetStyleList_StrikeOff(StyleID, "PickFromMasterBack");
            }
            else {
                props.history.push('/StrikeOffList')
            }
            if (props.location.state && props.location.state.params.Redirecttomaster === true) {
                let buyerbrandseason = {
                    buyerId: props.location.state.params.buyerId,
                    brandId: props.location.state.params.brandId,
                    seasonId: props.location.state.params.seasonId,
                };
                props.history.push('/SamGenlStrikeOffAddEdit', { params: buyerbrandseason });
            }

        } else {
            window.location.reload();
        }
    }

    const GetStyleList_StrikeOff = (StyleID, Func) => {
        StyleService.GetStyleList(StyleID, IsProduction, 0, '01/01/1949', moment(stateDate[0].endDate).format('MM/DD/YYYY'))
            .then((response) => {
                if (response.data) {
                    let StyleList = response.data.item1[0];
                    StyleList.FromMaster = Func;
                    props.history.push('/StyleListRegister', { params: StyleList });
                }
            })
            .catch(() => { });
    }
    const handleChange = (e, FieldName) => {
        //setSubmitted(false);
        let inputText = '';

        if (FieldName === "BuyerID" ||
            FieldName === "BuyerBrandID" ||
            FieldName === "TrimSupplier") {
            if (e != null) {
                inputText = parseInt(e.value);
            }
            else {
                inputText = 0;
                // values[0].BuyerBrandID = 0;
                values[0].TrimSupplier = 0;
            }
            values[0][FieldName] = inputText;
        }
        else {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            if (e.target.name === "IsSelected") {
                if (e.target.checked === true) {
                    values[0].IsMapforTest = 1
                } else {
                    values[0].IsMapforTest = 0
                }
            }
            if (e.target.name === "PurchaseType") {
                inputText = parseInt(inputText);
            }
            values[0][e.target.name] = inputText;
        }

        if (FieldName === "BuyerID") {
            let respected_Brand = getAllBrandList.filter(x => (x.buyerID === parseInt(inputText)) && (x.buyerMapID === 1));
            setBuyerBrandList(respected_Brand);
            let respected_TrimSupplier = getAllTrimSupplierList.filter(x => x.buyerID === parseInt(inputText));
            setBuyerTrimSupplierList(respected_TrimSupplier);
        }
        setStrikeoffList(values);
    };

    const [EmptyReference, setEmptyReference] = useState([{
        StrikeOffReferenceID: 0,
        Height: '',
        Width: '',
        SizeID: 0,
        IsDeleted: 0
    }]);

    const [EmptyColorGroup, setEmptyColorGroup] = useState([{
        ColorGroupID: 0,
        StrikeoffLogoPath: '',
        StrikeoffLogoName: '',
        IndexName: '0',
        IsDeleted: 0,
    }]);

    const [EmptyColor, setEmptyColor] = useState([{
        ColorID: 0,
        SysNo: 1,
        PantoneType: 0,
        PantoneName: 0,
        PantoneColor: '',
        Critical: 0,
        IsCriticalOpen: 0,
        Notes: '',
        TargettoClose: null,
        CompletedOn: null,
        RemindMe: 0,
        IndexName: '0'
    }]);

    const SaveStrikeOff = (e) => {
        debugger
        setButtonLoader(true);
        let IsValid = true;
        let given_Value = getStrikeoffList[0];
        let newss = getColorgroupList;
        //
        if (given_Value.BuyerID === 0
            || given_Value.BuyerBrandID === 0
            || given_Value.PurchaseType === 0
            || given_Value.StrikeOffName === ''
            || given_Value.StrikeOffCode === ''
            || given_Value.Quality === '' || newss.length === 0) {
            IsValid = false;
            Nodify('Warning!', 'warning', "Please fill the mandatory(*) fields");
            setIsParentCallback(true)
            setButtonLoader(false);
            setSubmitted(true);
            IsValid = false;
        }

        else if (given_Value.PurchaseType === 1) {

            if (given_Value.TrimSupplier === 0) {
                Nodify('Warning!', 'warning', "Please fill the mandatory(*) fields");
                setButtonLoader(false);
                setSubmitted(true);
                IsValid = false;
            }
        }
        if (newss.length !== undefined && newss.length > 0) {
            ;
            getColorgroupList.map((x, inx) => {
                x.ColorGroupList.map((color, index) => {

                    if (color.PantoneType === 0 || color.PantoneName === 0) {
                        IsValid = false;
                        setButtonLoader(false);
                        setIsParentCallback(true)
                        Nodify('Warning!', 'warning', "Please fill the mandatory(*) fields");
                    }
                })
                if (x.StrikeoffLogoName === "") {
                    IsValid = false;
                    setButtonLoader(false);
                    setIsParentCallback(true)
                    Nodify('Warning!', 'warning', "Please upload the Image!");
                }


            })
        }


        if (IsValid) {
            let ColorGroupDetails = [];
            let PrintColorDetails = [];
            if (getColorgroupList.length !== undefined) {
                getColorgroupList.forEach((Info, i) => {


                    if (Info.ColorGroupList) {
                        Info.ColorGroupList.forEach((colors, i) => {
                            if (colors.PantoneType !== 0 && colors.PantoneName !== 0) {
                                ColorGroupDetails.push(colors);
                            }
                        });

                        if (Info.StrikeoffLogoPath !== '' && Info.StrikeoffLogoName !== '') {
                            PrintColorDetails.push({
                                ColorGroupID: Info.ColorGroupID,
                                StrikeoffLogoPath: Info.StrikeoffLogoPath,
                                StrikeoffLogoName: Info.StrikeoffLogoName,
                                IndexName: i.toString(),
                                IsDeleted: Info.IsDeleted,
                            })
                        }
                        else if (Info.StrikeoffLogoPath === '' && Info.StrikeoffLogoName === '') {
                            if (Info.ColorGroupList[0].PantoneType !== 0 && Info.ColorGroupList[0].PantoneName !== 0) {
                                setIsParentCallback(true);
                                IsValid = false;
                                setButtonLoader(false);
                                Nodify('Warning!', 'warning', "Please upload the image in color tab.");
                                setSubmitted(true);
                            }
                        }


                        //      if (Info.StrikeoffLogoPath !== '' && Info.StrikeoffLogoName !== '') {
                        //         PrintColorDetails.push({
                        //             ColorGroupID: Info.ColorGroupID,
                        //             StrikeoffLogoPath: Info.StrikeoffLogoPath,
                        //             StrikeoffLogoName: Info.StrikeoffLogoName,
                        //             IndexName: Info.IndexName,
                        //             IsDeleted: Info.IsDeleted,
                        //         })
                        //         if (Info.ColorGroupList) {
                        //             Info.ColorGroupList.forEach((colors, i) => {
                        //                 if (colors.PantoneType !== 0 && colors.PantoneName !== 0) {
                        //                     ColorGroupDetails.push(colors);
                        //                 }
                        //                 else {
                        //                     setIsParentCallback(true);
                        //                     IsValid = false;
                        //                     setButtonLoader(false);
                        //                     Nodify('Warning!', 'warning', "Please fill the mandatory fields in Print color tab.");
                        //                     setSubmitted(true);
                        //                 }
                        //             });
                        //         }
                        //      }
                        //    else if (Info.StrikeoffLogoPath === '' && Info.StrikeoffLogoName === '') {
                        //         if (Info.ColorGroupList[0].PantoneType !== 0 && Info.ColorGroupList[0].PantoneName !== 0) {
                        //             setIsParentCallback(true);
                        //             IsValid = false;
                        //             setButtonLoader(false);
                        //             Nodify('Warning!', 'warning', "Please upload the image in color tab.");
                        //             setSubmitted(true);
                        //         }
                        //     }
                        //     else {
                        //         setIsParentCallback(true);
                        //         IsValid = false;
                        //         setButtonLoader(false);
                        //         Nodify('Warning!', 'warning', "Please fill the mandatory fields in Print color tab.");
                        //         setSubmitted(true);
                        //     }


                        getStrikeoffList[0].ColorGroupDetails = PrintColorDetails;
                        getStrikeoffList[0].ColorDetails = ColorGroupDetails;
                    }

                });
            }
            else {
                getStrikeoffList[0].ColorGroupDetails = EmptyColorGroup;
                getStrikeoffList[0].ColorDetails = EmptyColor;
                // setIsParentCallback(true);
                // IsValid = false;
                // Nodify('Warning!', 'warning', "Please fill at least one row in Print color tab.");
                // setSubmitted(true);
            }

            if (getUOMList.length === undefined) {
                getStrikeoffList[0].UOMReference = EmptyReference;
                // setUOMIsParentCallback(true);
                // IsValid = false;
                // Nodify('Warning!', 'warning', "Please fill at least one row in Reference tab.");
                // setSubmitted(true);
            }
            else {
                for (var index = 0; index < getUOMList.length; index++) {
                    if (getUOMList[index].Height === ''
                        && getUOMList[index].Width === ''
                        && getUOMList[index].SizeID === 0) { }
                    else if (getUOMList[index].Height === ''
                        || getUOMList[index].Width === ''
                        || getUOMList[index].SizeID === 0
                    ) {
                        setUOMIsParentCallback(true);
                        IsValid = false;
                        setButtonLoader(false);
                        Nodify('Warning!', 'warning', "Please fill the mandatory fields in Reference tab.");
                        setSubmitted(true);
                    }
                }
                getStrikeoffList[0].UOMReference = getUOMList;
            }
        }



        if (IsValid) {
            strikeoffService.InsertUpdateStrikeoff(getStrikeoffList[0]).then(res => {
                var page = "Remove";
                SearchRetain(page);
                let Func = 'Add';
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "-2") {
                    setButtonLoader(false);
                    ValidationPopup("Strike Off code already exists!");
                }
                else if (res.data.outputResult === "0") {
                    setButtonLoader(false);
                    ValidationPopup("Error Occured!");
                }
            });
        }
    }

    const PageRedirect = (Func) => {
        if (IsFromMaster) {
            GetStyleList_StrikeOff(StyleID, "PickFromMaster1");
        }
        else {
            props.history.push({
                pathname: "/StrikeOffList",
                state: {
                    message: Func
                }
            });
        }
        if (props.location.state && props.location.state.params.Redirecttomaster === true) {

            let buyerbrandseason = {
                buyerId: props.location.state.params.buyerId,
                brandId: props.location.state.params.brandId,
                seasonId: props.location.state.params.seasonId,
                message: Func
            };
            props.location.state = { params: buyerbrandseason };
            props.history.push('/SamGenlStrikeOffAddEdit', { params: buyerbrandseason });

        }


    }

    const ValidationPopup = (Msg) => {
        Nodify('Warning!', 'warning', Msg);
        setButtonLoader(false);
        return false;
    }

    const brandCallback = (value) => {
        if (value === true) {
            StyleService.LoadBrandThemeStoryEndCustomer(5, getStrikeoffList[0].BuyerID).then((response) => {
                if (response.data) {
                    let BuyerInfoList = response.data;
                    let respected_Brand = BuyerInfoList.filter(x => x.buyerMapID === 1);
                    setBuyerBrandList(respected_Brand);
                } else {
                }
            })
                .catch(() => { });
            setQuickMasterName('');
        } else {
            setQuickMasterName('');
        }
    }

    const handlePopupClose = () => {
        setQuickMasterName('');
    };

    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">{getHeaderName} Strike Off</h3>
                            <div className="buttons-preview" align="right">

                                <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                    <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                                </span>
                                <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                    <i className="fa fa-times"></i>&nbsp;Reset
                                </span>
                                <button type="submit" className="btn btn-success" onClick={SaveStrikeOff} disabled={buttonLoader}>

                                    <i className="fa fa-check right"></i>&nbsp;{ButtonName}
                                </button>
                            </div>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <Form>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="dataTables_wrapper no-footer">
                                                <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                    <thead className="text-center">
                                                        <tr>
                                                            <th style={{ width: "15%" }}>Buyer<span className="text-danger">*</span></th>
                                                            <th style={{ width: "15%" }}>
                                                                {StrikeoffInfoQuick.Brand.length > 0 && StrikeoffInfoQuick.Brand[0].isQuick === 1 && Buttonview !== "View" &&
                                                                    <button type='button' className="glyphicon glyphicon-plus AddPlusIcon" style={{ backgroundColor: "#222d32" }}

                                                                        onClick={() => setQuickMasterName('Brand')} title="Add Brand"
                                                                        disabled={getStrikeoffList[0].BuyerID === 0 ? true : false}>
                                                                    </button>
                                                                }
                                                                Brand<span className="text-danger">*</span>
                                                            </th>
                                                            <th>Strike Off Name<span className="text-danger">*</span></th>
                                                            <th>Strike Off Code<span className="text-danger">*</span></th>
                                                            {/* <th>Buyer Ref No<span className="text-danger">*</span></th> */}
                                                            <th style={{ width: "10%" }}>Quality<span className="text-danger">*</span></th>
                                                            <th>Purchase Type<span className="text-danger">*</span></th>
                                                            {
                                                                getStrikeoffList[0].PurchaseType === 1 &&
                                                                (
                                                                    <th style={{ width: "15%" }}>Trim Supplier<span className="text-danger">*</span></th>
                                                                )}
                                                            {/* <th>Is Map for Test</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <span className='input-icon icon-right'>
                                                                    <Reactselect className="basic-single" name="BuyerID"
                                                                        id={
                                                                            "BuyerID"
                                                                        }
                                                                        isLoading={false}
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        onChange={event => handleChange(event, "BuyerID")}
                                                                        value={getBuyerList.filter(function (option) {
                                                                            return option.value === getStrikeoffList[0].BuyerID;
                                                                        })}
                                                                        options={getBuyerList}
                                                                        autoFocus
                                                                        styles={submitted && getStrikeoffList[0].BuyerID === 0 ? styles : ''}
                                                                        isDisabled={Isdisabled}
                                                                    ></Reactselect>
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span className='input-icon icon-right'>
                                                                    <Reactselect className="basic-single" name="BuyerBrandID"
                                                                        id={
                                                                            "BuyerBrandID"
                                                                        }
                                                                        isLoading={false}
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        onChange={event => handleChange(event, "BuyerBrandID")}
                                                                        value={getBuyerBrandList.filter(function (option) {
                                                                            return option.value === getStrikeoffList[0].BuyerBrandID;
                                                                        })}
                                                                        options={getBuyerBrandList}
                                                                        styles={submitted && getStrikeoffList[0].BuyerBrandID === 0 ? styles : ''}
                                                                        isDisabled={Isdisabled}
                                                                    ></Reactselect>
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span className='input-icon icon-right'>
                                                                    <Input placeholder="Enter Strike Off Name"
                                                                        id="StrikeOffName"
                                                                        name="StrikeOffName" //className="form-control"
                                                                        value={getStrikeoffList[0].StrikeOffName}
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        onChange={event => handleChange(event, "StrikeOffName")}
                                                                        maxLength="50"
                                                                        ref={inputRef}
                                                                        className={'form-control' + (
                                                                            submitted && !getStrikeoffList[0].StrikeOffName ? ' is-invalid' : ''
                                                                        )}
                                                                        style={{ border: submitted && !getStrikeoffList[0].StrikeOffName ? '1px solid red' : '' }}
                                                                    />
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span className='input-icon icon-right'>
                                                                    <Input placeholder="Enter Strike Off Code" id="StrikeOffCode" name="StrikeOffCode" //className="form-control"
                                                                        value={getStrikeoffList[0].StrikeOffCode}
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        onChange={event => handleChange(event, "StrikeOffCode")}
                                                                        maxLength="50"
                                                                        ref={inputRef}
                                                                        className={'form-control' + (
                                                                            submitted && !getStrikeoffList[0].StrikeOffCode ? ' is-invalid' : ''
                                                                        )}
                                                                        style={{ border: submitted && !getStrikeoffList[0].StrikeOffCode ? '1px solid red' : '' }}
                                                                    />
                                                                </span>
                                                            </td>
                                                            {/* <td>
                                                                <span className='input-icon icon-right'>
                                                                    <Input placeholder="Enter Buyer Reference No" id="BuyerReferenceNo" name="BuyerReferenceNo" //className="form-control"
                                                                        value={getStrikeoffList[0].BuyerReferenceNo}
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        onChange={event => handleChange(event, "BuyerReferenceNo")}
                                                                        maxLength="50"

                                                                        ref={inputRef}
                                                                        className={'form-control' + (
                                                                            submitted && !getStrikeoffList[0].BuyerReferenceNo ? ' is-invalid' : ''
                                                                        )}
                                                                        style={{ border: submitted && !getStrikeoffList[0].BuyerReferenceNo ? '1px solid red' : '' }}
                                                                    />
                                                                </span>
                                                            </td> */}
                                                            <td>
                                                                <span className='input-icon icon-right'>
                                                                    <Input type="text"
                                                                        placeholder="Enter Quality"
                                                                        id="Quality"
                                                                        name="Quality"
                                                                        value={getStrikeoffList[0].Quality}
                                                                        onChange={event => handleChange(event, "Quality")}
                                                                        maxLength="50"
                                                                        className={'form-control' + (
                                                                            submitted && !getStrikeoffList[0].Quality ? ' is-invalid' : ''
                                                                        )}
                                                                        style={{ border: submitted && !getStrikeoffList[0].Quality ? '1px solid red' : '' }}
                                                                    />
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span className='input-icon icon-right'>
                                                                    <Select placeholder="Enter Purchase Type" id="PurchaseType" name="PurchaseType" //className="form-control"
                                                                        value={getStrikeoffList[0].PurchaseType}
                                                                        onChange={event => handleChange(event, "PurchaseType")}
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        maxLength="50"

                                                                        ref={inputRef}
                                                                        className={'form-control'}
                                                                        style={{ border: submitted && getStrikeoffList[0].PurchaseType === 0 ? '1px solid red' : '' }}
                                                                    >
                                                                        <option value="0">- Select Purchase Type -</option>
                                                                        {
                                                                            PurchaseTypeList.map(item => (
                                                                                <option key={
                                                                                    item.id
                                                                                }
                                                                                    value={
                                                                                        item.id
                                                                                    }>
                                                                                    {
                                                                                        item.name
                                                                                    }
                                                                                </option>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </span>
                                                            </td>
                                                            {
                                                                getStrikeoffList[0].PurchaseType === 1 &&
                                                                (
                                                                    <td>
                                                                        <span className='input-icon icon-right'>
                                                                            <Reactselect className="basic-single" name="TrimSupplier"
                                                                                id={
                                                                                    "TrimSupplier"
                                                                                }
                                                                                isDisabled={false}
                                                                                isLoading={false}
                                                                                isClearable={true}
                                                                                isSearchable={true}
                                                                                onChange={event => handleChange(event, "TrimSupplier")}
                                                                                value={getBuyerTrimSupplierList.filter(function (option) {
                                                                                    return option.value === getStrikeoffList[0].TrimSupplier;
                                                                                })}
                                                                                options={getBuyerTrimSupplierList}
                                                                                styles={submitted && getStrikeoffList[0].TrimSupplier === 0 ? styles : ''}

                                                                            ></Reactselect>
                                                                        </span>
                                                                    </td>
                                                                )}
                                                            {/* <td >
                                                                &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                                                                <label style={{ marginTop: '8px' }}>
                                                                    <input type="checkbox" name="IsSelected"
                                                                        onChange={event => (handleChange(event, 'IsSelected'))}
                                                                        value={getStrikeoffList[0].IsMapforTest === 1 ? true : false}
                                                                        checked={getStrikeoffList[0].IsMapforTest === 1 ? true : false}
                                                                        className="clearOpacity textdesign" />
                                                                    <span className="text"> </span>
                                                                </label>
                                                            </td> */}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="tabbable">
                        <ul className="nav nav-tabs nav-justified" id="myTab5">
                            <li className="active" style={{ zIndex: "0" }}>
                                <a data-toggle="tab" href="#idPrintColor" style={{ textAlign: "left" }}>
                                    <h5>Print Color</h5>
                                </a>
                            </li>
                            <li className="" style={{ zIndex: "0" }}>
                                <a data-toggle="tab" href="#idReference" style={{ textAlign: "left" }}>
                                    <h5>Reference</h5>
                                </a>
                            </li>
                        </ul>

                        <div className="tab-content">
                            <div id="idPrintColor" className="tab-pane in active" style={{ width: "100%" }}>
                                <PrintColor ColorCallBack={(e) => ColorGroupCallBack(e, IsParentCallback)}
                                    props={props}
                                    IsValidationCallback={IsParentCallback}
                                    PrintColorDetails={getPrintColorTabDetails}
                                    IsView={false}
                                    Id={getstate_StrikeoffId}
                                    BuyerID={getStrikeoffList[0].BuyerID}
                                    BrandID={getStrikeoffList[0].BuyerBrandID}
                                    getHeaderName={getHeaderName}
                                    StrikeoffInfoQuick={StrikeoffInfoQuick}
                                    PageName={props.location.state && props.location.state.params.From === "Style" ?
                                        'StyleStrikeoff' : 'MasterStrikeoff'}
                                >

                                </PrintColor>
                            </div>

                            <div id="idReference" className="tab-pane" style={{ width: "100%" }}>
                                <Reference UOMCallBack={(e) => SizeUOMCallBack(e, IsUOMParentCallback)}
                                    IsValidationCallback={IsUOMParentCallback}
                                    ReferenceList={getExistReferenceList}
                                    IsView={false}
                                    Id={getstate_StrikeoffId}
                                >
                                </Reference>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>
                        <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                            <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                        </span>
                        <span className="btn btn-danger" onClick={() => ResetOperation()}>
                            <i className="fa fa-times"></i>&nbsp;Reset
                        </span>
                        <button type="submit" className="btn btn-success" onClick={SaveStrikeOff} disabled={buttonLoader}>
                            <i className="fa fa-check right"></i> &nbsp;{ButtonName}
                        </button>
                    </div>
                </div>
            </div>

            {
                getQuickMasterName === 'Brand' ?
                    <Modal show={getQuickMasterName} size="md" dragable backdrop="static" keyboard={false} onHide={() => handlePopupClose()}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add {getQuickMasterName}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <BrandEndCustomerStoryTheme
                                getEndCustomerList={[]}
                                BrandCallback={brandCallback}
                                EndCustomerCallback={[]}
                                StoryCallBack={[]}
                                ThemeCallBack={[]}
                                showpopup={true}
                                BuyerID={getStrikeoffList[0].BuyerID}
                                Brandlist={getBuyerBrandList}
                                Endcustomerlist={[]}
                                Storylist={[]}
                                Themelist={[]}
                                MappingName={getQuickMasterName}

                            />
                        </Modal.Body>
                    </Modal> :
                    null

            }
        </div >




    )
};


export default StrikeOffListRegister;

