import React, { useState, useEffect, useMemo } from "react";
import ReactNotification from 'react-notifications-component';
import { TableHeader, Pagination, Search, PageCount } from "../../Datatable";
import { useDispatch, useSelector } from "react-redux";
import { retrievetemplatelist } from "../../../actions/template"
import CommonService from '../../../services/Common/CommonService';
import Nodify from "../../Common/ReactNotification";
import SearchRetain from "../../Common/SearchRetain";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import SweetAlertPopup from '../../Common/SweetAlertPopup';
import { Redirect } from 'react-router-dom';
import TemplateDetails from "../../../services/Master/TemplateService";
import TemplateItem from "../../../services/Master/TemplateService";
import SelectCombobox from 'react-select';
import ItemService from "../../../services/Master/ItemService";

const EditTemplate = (props) => {
 
  const [ItemList, setItemList] = useState([]);// for Item list
  const [TemplateList, setTemplateList] = useState([]);// for Template list
  const [Checked, setChecked] = useState(0)
  const [Checkedlist, setCheckedList] = useState(true)
  const [getnewitemId, setnewitemId] = useState([]);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [matchingList, setmatchingList] = useState([]);// For TemplateID based matched value ItemID and ItemNAme
  const [submitted, setSubmitted] = useState(false);
  const [getnewlabel, setnewlabel] = useState([]);
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const { user: currentUser } = useSelector((state) => state.auth);

  //Enter Error msg
  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        //SaveBuyerInfo(event);
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [])
  useEffect(() => {
    let ItemDetails = [];
    let TemplateDetail = [];
    let TemplateMatchList = [];

    ItemService.getAllItemList(0).then((res) => {

      if (res.data) {
        res.data.map(x => {
          ItemDetails.push({
            label: x.itemName,
            value: x.itemID
          })
        })
        setItemList(ItemDetails)
      }
    })
    let checked = true;
    TemplateItem.GettemplateList(2, 0).then((response) => {
      if (response.data) {
        checked = !!!response.data.templateEdit2.find(x => x.availableInfoStatus === 1);
        if (!checked) {
          setCheckedList(true)
        } else {
          setCheckedList(false)
        }
      }
    })
    TemplateItem.GettemplateList(2, props.location.state.params.templateInfoID).then(async (res) => {

     ;
      if (res.data) {
        res.data.templateEdit2.map(x => {
          TemplateMatchList.push({
            label: x.itemName,
            value: x.itemid,
            Isdeleted: 0,
            TemplateInfoID: x.templateInfoID,
            Templateid: x.templateid
          })
        })
        setmatchingList(TemplateMatchList)
      }
    }, [])

    TemplateDetails.LoadTemplateItemList(props.location.state.params.templateInfoID).then((res) => {

      if (res.data) {
        res.data.map(x => {
          TemplateDetail.push({
            label: x.label,
            value: x.value,
            Isdeleted: 0
          })
        })
        setTemplateList(TemplateDetail)
      }
    })
    setChecked(props.location.state.params.availableInfoStatus)
  }, [])


  const handleRemoveFields = (index, value) => {
   
    const values = [...matchingList]
    values.splice(index, 1);
    setmatchingList(values)

    let Params = {
      Operation: 136,
      Id: value.value,
    }
    CommonService.CheckMappedItem(Params).then(res => {
      if (res.data.outputResult === "-2") {
        let Title = "Warning!";
        let message = "This item is already mapped.";
        let type = "warning";
        Nodify(Title, type, message);
      }
    });
  };
  const handleAddFields = (index) => {
   
    const values = [...matchingList];
    if (values[index].value !== 0 && values[index].value !== undefined) {
      // Create a new row
      const newRow = { id: Date.now() };

      // Update the rows array
      setmatchingList((prevRows) => {
        // Insert the new row at the specified index
        const updatedRows = [...prevRows.slice(0, index + 1), newRow, ...prevRows.slice(index + 1)];

        // Increment the indices of rows following the insertion point
        const updatedRowsWithIds = updatedRows.map((row, i) => ({ ...row, id: i }));

        return updatedRowsWithIds;
      });
      setSubmitted(false);
      // values.push({ value: 0, label: "" });
      // setmatchingList(values);
    }
    else {
      Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
      setSubmitted(true);
    }
  };
  const ResetOperation = (e) => {
    if (e === "Back") {
      props.history.push('/Template');
    } else {
      window.location.reload();
    }
  }
  const headers = [
    { name: <span>Name<span className="text-danger">*</span></span>, field: "itemName", sortable: false },
    {
      name: "Action", field: "", sortable: false,
      width: "13%"
    },
  ];

  let styles = {
    control: styles => ({ ...styles, border: '1px solid red' }),

  }



  const handleInputChange = (e, index, FieldName) => {
   ;
    const values = [...matchingList]
    if (FieldName === "ItemName") {
      if (e !== null) {
        values[index].value = e.value
        values[index].label = e.label
      }
      else {
        values[index].value = 0
        values[index].label = ''
      }
    }
    setmatchingList(values)

    if (values.length > 1)
      for (var j = 0; j < (matchingList.length); j++) {
        if (j !== index) {
          if (values[j].value === values[index].value) {
            values[index].value = 0;
            setmatchingList(values);
            Nodify('Warning!', 'warning', 'This Item Name is already exist.');
            setSubmitted(true);
            return false;
          }
        }
      }


  }
  const PageRedirect = (Func) => {
    props.history.push({
      pathname: "/Template",
      state: {
        message: Func,
      },
    }
    );
  };
  const SaveTemplateDetials = (e) => {
   ;
    setButtonLoader(true);
    e.preventDefault();
    let isvalid = true
    for (var i = 0; i < (matchingList.length); i++) {
      if (matchingList[i].value === 0 || matchingList[i].value === undefined) {
        Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
        setSubmitted(true);
        setButtonLoader(false);
        isvalid = false
        return false;
      }
    }
    if (isvalid) {

      let TemplateEditID = []
      matchingList.map((x) => {
        TemplateEditID.push({
          Templateid: x.Templateid === undefined ? 0 : x.Templateid,
          Itemid: x.value,
          Isdeleted: x.Isdeleted === undefined ? 0 : x.Isdeleted
        })
      })
      setnewitemId(TemplateEditID)

      let InputMaster = { Operation: 2, Templatename: TemplateList[0].label, AvailableInfoStatus: Checked, TemplateInfoID: props.location.state.params.templateInfoID, templateInformation: TemplateEditID, Createdby: currentUser.employeeinformationID }
      TemplateItem.UpdateTemplate(InputMaster).then(res => {

        var page = "Remove";
        SearchRetain(page);
        let Func = 'Add';
        if (res.data.outputResult === "1") {
          Func = 'Add';
          PageRedirect(Func);
        } else if (res.data.outputResult === "2") {
          Func = 'Edit';
          PageRedirect(Func);
        }
        setButtonLoader(false);
      });
    }
  }
  const handleOnSelect = (e, Feildname, index) => {
   
    if (e.target.checked === true) {
      setChecked(1)
    } else {
      setChecked(0)
      setCheckedList(false)
    }

  }

  return (
    <div className="page-body">
      <ReactNotification />
      <div className="row">
        <div className="col-lg-12 col-sm-12 col-xs-12">
          <div className="widget flat radius-bordered">
            <div className="widget-header heading_box_style">
              <h3 className="heading_style_common">Edit Available Info Template</h3>
            </div>

            <div className="widget-body">

              <div className="row">
                <div className="col-lg-11">
                  <div className="card">
                    <label className="alert alert-info comment-report-note"><b>Note : </b>If you need to check particular Disabled checkbox, Please uncheck the selected checkbox were selected towards the template list</label>
                  </div>
                </div>
                {
                  TemplateList.map((x, index) => (

                    <div className="col-sm-3">
                      <label>Template Name<span className="text-danger">*</span></label>
                      <input type="text"
                        className="form-control"
                        id={"Template"}
                        name="Template"
                        value={x.label}
                        isDisabled={true}
                      />
                    </div>
                  ))
                }
                <div className="col-lg-4 margin-top-9">
                  <label></label>
                  <div className="form-group">
                    <label className="Ajustment"><span className="text"> </span></label>&nbsp;&nbsp;&nbsp;
                    <label style={{ marginTop: '7px' }}>
                      <input type="checkbox" name="IsSelected"
                        onChange={event => (handleOnSelect(event, 'IsSelected'))}
                        value={Checked === 1 ? true : false}
                        checked={Checked === 1 ? true : false}
                        //disabled={Checkedlist === true && Checked === 1 ? false : true}
                        disabled={Checkedlist === false ? false : Checked === 1 ? false : true}
                        className="clearOpacity textdesign" />
                      <span className="text"> Default</span>
                    </label>
                  </div>
                </div>
              </div>
              <div id="registration-form">
                <form>
                  {
                    <table className="table table-striped table-bordered table-hover dataTable no-footer margin-top-10" id="editabledatatable" style={{ width: "50%" }}>
                      <TableHeader headers={headers} onSorting={(field, order) => setSorting({ field, order })} />
                      <tbody>
                        {matchingList.filter(x => x.Isdeleted !== 1).map((inputfeild, index) => (
                          <tr>
                            <td>
                              <SelectCombobox className="basic-single" name="ItemName" id={"ItemName" + index}
                                isDisabled={false}
                                isLoading={false}
                                isSearchable={true}
                                isClearable={true}
                                autocomplete='off'
                                placeholder="Select Template"
                                styles={submitted && (inputfeild.value === 0 || inputfeild.value === undefined) ? styles : ''}
                                onChange={e => handleInputChange(e, index, "ItemName")}
                                options={ItemList}
                                value={matchingList.filter(function (option) {
                                  return option.value === inputfeild.value;
                                })}
                              //value={inputfeild}
                              />
                            </td>

                            <td style={{ width: "13%" }}>
                              {
                                matchingList.filter(x => x.Isdeleted !== 1).length !== 1 && (
                                  <span title='Delete Template' onClick={() => handleRemoveFields(index, inputfeild)}
                                    className="btn btn-danger btn-xs delete">
                                    <i className="fa fa-trash-o"></i>
                                  </span>
                                )

                              }
                              &nbsp;
                              {/* {
                                matchingList.filter(x => x.Isdeleted !== 1).length === (index + 1) && ( */}
                              <span title='Add Template'
                                onClick={() => handleAddFields(index)}
                                className="btn btn-info btn-xs plus">
                                <i className="fa fa-plus"></i>
                              </span>
                              {/* )
                              } */}

                              {/* {matchi.length} */}
                            </td>
                            {/* <span> {index}</span> */}
                          </tr>

                        ))

                        }
                      </tbody>
                    </table>
                  }

                  <div className="col-sm-12">
                    <div className="buttons-preview" align="right">
                      <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                        <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                      </span>
                      <span className="btn btn-danger" onClick={() => ResetOperation()}>
                        <i className="fa fa-times"></i>&nbsp;Reset
                      </span>
                      <button type="button" className="btn btn-success" disabled={buttonLoader} onClick={SaveTemplateDetials}>
                        <i className="fa fa-check right"></i>&nbsp;Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditTemplate