import {
    PACKAGEITEM_LOADING,
    RETRIEVE_PACKAGEITEM,
    DELETE_PACKAGEITEM,
} from "./types";

import Packageitem from "../services/Master/Packageitem";

const PackageItemLoading = (isStatus) => ({
    type: PACKAGEITEM_LOADING,
    payload: isStatus,
});

export const deletePackageItem = (PackageItemID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_PACKAGEITEM,
            payload: PackageItemID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrievePackageItem = (Operation, PackageItemID, PackageItemInfoID) => async (dispatch) => {
    try {
        dispatch(PackageItemLoading(true));
        const res = await Packageitem.GetPackageItemList(Operation, PackageItemID, PackageItemInfoID);
        dispatch({
            type: RETRIEVE_PACKAGEITEM,
            payload: res.data,
        });

        dispatch(PackageItemLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(PackageItemLoading(false));
    }
};


