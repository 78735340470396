import React, { useState, useEffect, Fragment } from "react";
import ReactNotification from 'react-notifications-component';
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import 'react-notifications-component/dist/theme.css';
import Nodify from "../Common/ReactNotification";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import Lightbox from "react-image-lightbox"
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import $ from "jquery";
import { useSelector } from "react-redux";
import { TableHeader, Pagination, Search, PageCount } from "../Datatable";
import TnaService from "../../services/TNA/TNAService";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import Reactselect from 'react-select';


const TrimSubmission = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);


    //const [getOrderinfo, setOrderinfo] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [getHeader, setHeader] = useState("Add");
    const [ShowAddTrimDimensionPopup, setShowAddTrimDimensionPopup] = useState(false);

    const [isOpen, setIsOpen] = useState(false);
    const [getImagename, setImagename] = useState();
    const [showawb, setshowawb] = useState(false);
    const [expandedRows, setExpandedRows] = useState([]);
    const [expandState, setExpandState] = useState({});
    const [expandList, setexpandList] = useState({});
    const [getOrderinfo, setOrderinfo] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [expandStateMultiSelect, setExpandStateMultiSelect] = useState({});
    const [expandedRowsMultiSelect, setExpandedRowsMultiSelect] = useState([]);
    const [expandListMultiSelect, setexpandListMultiSelect] = useState({});
    const [OrderInfoMulticloseMainDetails, setOrderInfoMulticloseMainDetails] = useState([]);
    const [OrderInfoMainDetails, setOrderInfoMainDetails] = useState([]);

    const [buttonLoader, setButtonLoader] = useState(false);
    //gowtam
    const [getStrikeOffDate, setStrikeOffDate] = useState([{ AWB: '', Date: '' }]);
    const [submittedpopup, setSubmittedpopup] = useState(false);
    const [ShowTrimPopup, setShowTrimPopup] = useState(false);
    const [submittedawb, setSubmittedawb] = useState(false);
    const [getSubImageList, setSubImageList] = useState([]);
    const [getCurrimageindex, setCurrimageindex] = useState(0);
    const [TrimDimension, setTrimDimension] = useState([]);
    const [getTaskholder, setTaskholder] = useState([]);
    const [MultiCloseDatas, setMultiCloseDatas] = useState([]);
    const [selectall, setselectall] = useState(false);
    const [showMultiClosediv, setshowMultiClosediv] = useState(true);
    const [showSubmissiondiv, setshowSubmissiondiv] = useState(false);

    let styles1 = {
        control: styles1 => ({ ...styles1, width: "230px" }),
    }

    let styles2 = {
        control: styles2 => ({ ...styles2, width: "230", border: '1px solid red' }),
    }


    // If check production or sampling
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProduction = activeMenus.IsProduction;
    if (props.props.location.pathname === '/Dashboard') { IsProduction = props.IsProductionDD }
    // End

    const [inputFields, setInputFields] = useState([{
        TNAId: 0, BrandID: 0, BuyerID: 0, SeasonID: 0, SupplierID: 0, SupplierName: "", BuyerBrandSeasonName: "",
        SubmittedDate: '', SendTo: "", SendToEdit: '', SendThrough: 0,
        StyleID: 0, StyleName: '', StyleNo: '', IdpoNo: '', EndCustomer: '', AWBName: '', AWBDate: '', AWBDateandNo: '',
        SubmissionApprovalStatus: 0, TrimInfoId: 0, TrimName: '', TrimCode: '', TechnicalSpecification: '',
        TrimReferenceID: 0, TrimDimension: '', SwatchCardImageName: '',
        SwatchCardIamgePath: '', Remarks: '', Createdby: 0, StyleTrimRefId: 0,
        TNATrimSubmittedOption: [],
        TNATrimColorApprovalComments: [], TNATrimRefInfoId: 0
    }]);
    const headers = [
        { name: "Style Name", field: "StyleName", sortable: false },
        { name: "Style No", field: "StyleNo", sortable: false },
        { name: "PO/ID No", field: "IDNo", sortable: false },
        { name: "Details", field: "Details", sortable: false },
        { name: "Task Holder / Follower", field: "", sortable: false },
        { name: "Action", field: "", sortable: false },
    ];

    // useEffect(() => {
    //     setSubmitOptions(Submitoptions);
    // }, [])
    const selector = useSelector((state) => state);
    const isSideBarEnable = selector.auth.isSideBarEnable;
    const isFullSideBarEnable = selector.auth.isFullSideBarEnable;

    let BrowserWidth = isFullSideBarEnable
        ? isSideBarEnable
            ? $(window).width() - 130
            : $(window).width() + 50
        : $(window).width() + 100;

    let DynamicClassName = "col-lg-12 col-sm-12 col-xs-12";
    var ImgClassName = "upload-btn-wrapper2"


    const values = [...inputFields]
    useEffect(() => {
        showLoader();
        if (props.TaskStatus !== 3) {
            values[0].TNATrimSubmittedOption.push(
                { OptionName: "A", SupplierReferenceNo: "" },
                { OptionName: "B", SupplierReferenceNo: "" },
                { OptionName: "C", SupplierReferenceNo: "" },
                { OptionName: "D", SupplierReferenceNo: "" },
            )
        }

        TnaService.GetTrimDimension(2, props.TNATrimRefInfoId, IsProduction).then((response) => {
            if (response.data) {
                setTrimDimension(response.data);
            }

        }).catch(() => { });
        TnaService.TaskHolderList(props.TNAId).then((response) => {
            if (response.data) {
                setTaskholder(response.data);
            }
        }).catch(() => { });

        if (props.TaskStatus !== 3) {
            setshowMultiClosediv(true);
            setshowSubmissiondiv(false);
            TnaService.TrimSubmissionList(1, props.TNATrimRefInfoId, IsProduction).then((response) => {
                if (response.data) {
                    values[0].TNAId = response.data[0].tnaId;
                    values[0].BrandID = response.data[0].brandID;
                    values[0].BuyerID = response.data[0].buyerID;
                    values[0].SeasonID = response.data[0].seasonID;
                    values[0].SupplierID = response.data[0].supplierID;
                    values[0].SupplierName = response.data[0].supplierName;
                    values[0].BuyerBrandSeasonName = response.data[0].buyerBrandSeasonName;
                    values[0].StyleID = response.data[0].styleId;
                    values[0].StyleName = response.data[0].styleName;
                    values[0].StyleNo = response.data[0].styleNo;
                    values[0].IdpoNo = response.data[0].idpoNo;
                    values[0].EndCustomer = response.data[0].endCustomerNameIdList;
                    values[0].TrimInfoId = response.data[0].trimInfoId;
                    values[0].TrimCode = response.data[0].trimCode;
                    values[0].TechnicalSpecification = response.data[0].technicalSpecification;
                    values[0].TrimName = response.data[0].trimName;
                    values[0].TrimReferenceID = response.data[0].trimReferenceId;
                    values[0].StyleTrimRefId = response.data[0].styleTrimRefId;
                    values[0].TNATrimRefInfoId = props.TNATrimRefInfoId;

                    setInputFields(values);

                    var tempsubimagelist = [];
                    tempsubimagelist = response.data[0].trimImageList.split(',');

                    // tempsubimagelist.push({ url: getStyleimage.replaceAll(" ", "%20").replaceAll("(", "%28").replaceAll(")", "%29") });
                    setSubImageList(tempsubimagelist);
                    setCurrimageindex(0);

                }
            }).catch(() => { });

            let params = {
                BuyerId: props.SearchValues.BuyerId, BrandId: props.SearchValues.BrandId, SeasonId: props.SearchValues.SeasonId,
                SupplierId: props.SearchValues.SupplierId, TaskType: props.SearchValues.TaskType, TaskNameId: props.SearchValues.TaskNameId,
                TaskTodoInProgressCompletedStatus: props.SearchValues.TaskTodoInProgressCompletedStatus, StartDate: props.SearchValues.StartDate, EndDate: props.SearchValues.EndDate,
                Operation: 3, ParentIdOrSubChildId: props.TNATrimRefInfoId
            };

            // TnaService.GetTrimSubmissionOrderInfoList(3, props.TNATrimRefInfoId).then((response) => {
            TnaService.GetSubChildMultiTaskSubmissionApprovalList(params).then((response) => {
                if (response.data) {
                    var index = 0;
                    var result = response.data;
                    response.data.forEach(element => {
                        index = index + 1;
                        element.index = index;
                        if (element.tnaTrimRefInfoId === props.TNATrimRefInfoId) {
                            element.isSelected = 1;
                        }
                    })


                    result.map((r, i) => {
                        props.AllChildvalues.map(c => {
                            if (r.tnaStrikeOffRefInfoId === c.ChildId) {
                                result[i].IsDepandency = c.IsDepandency
                                result[i].Depandency = c.Depandency
                                // result[i].dependencyStartstop = result[i].IsDepandency === 0 ? 1 : 0
                                // data[i].dependencyStartstop = c.intDependency;
                                result[i].dependencyStartstop = c.intDependency;
                            }
                        })
                    })

                    setMultiCloseDatas(result);
                    let data = [...new Map(response.data.map(item =>
                        [item['strikeofforTrimLogoId'], item])).values()];
                    setOrderInfoMulticloseMainDetails(data);
                    handleExpandRowMultiSelect(response.data[0].strikeofforTrimLogoPath, response.data);
                    hideLoader();
                }

            }).catch(() => { hideLoader(); });
        }
        else {
            setshowMultiClosediv(false);
            setshowSubmissiondiv(true);

            TnaService.ViewTrimSubmissionApprovalList(props.TNATrimRefInfoId, IsProduction).then((response) => {
                if (response.data) {
                    values[0].TNAId = response.data.trimSubmitApprovalList[0].tnaId;
                    values[0].BrandID = response.data.trimSubmitApprovalList[0].brandID;
                    values[0].BuyerID = response.data.trimSubmitApprovalList[0].buyerID;
                    values[0].SeasonID = response.data.trimSubmitApprovalList[0].seasonID;
                    values[0].SupplierID = response.data.trimSubmitApprovalList[0].supplierID;
                    values[0].SupplierName = response.data.trimSubmitApprovalList[0].supplierName;
                    values[0].BuyerBrandSeasonName = response.data.trimSubmitApprovalList[0].buyerBrandSeasonName;
                    values[0].StyleID = response.data.trimSubmitApprovalList[0].styleId;
                    values[0].StyleName = response.data.trimSubmitApprovalList[0].styleName;
                    values[0].StyleNo = response.data.trimSubmitApprovalList[0].styleNo;
                    values[0].IdpoNo = response.data.trimSubmitApprovalList[0].idpoNo;
                    values[0].EndCustomer = response.data.trimSubmitApprovalList[0].endCustomerNameIdList;
                    values[0].TrimInfoId = response.data.trimSubmitApprovalList[0].trimInfoId;
                    values[0].TrimCode = response.data.trimSubmitApprovalList[0].trimCode;
                    values[0].TechnicalSpecification = response.data.trimSubmitApprovalList[0].technicalSpecification;
                    values[0].TrimName = response.data.trimSubmitApprovalList[0].trimName;
                    values[0].TrimReferenceID = response.data.trimSubmitApprovalList[0].trimReferenceId;
                    values[0].StyleTrimRefId = response.data.trimSubmitApprovalList[0].styleTrimRefId;
                    values[0].TNATrimRefInfoId = props.TNATrimRefInfoId;
                    values[0].SubmittedDate = new Date(response.data.trimSubmitApprovalList[0].submittedDate)
                    values[0].SendThrough = response.data.trimSubmitApprovalList[0].sendThrough;

                    if (response.data.trimSubmitApprovalList[0].awbDate !== "") {

                        if (response.data.trimSubmitApprovalList[0].sendThrough === 2) {
                            setshowawb(true);
                            let getDate = new Date(response.data.trimSubmitApprovalList[0].awbDate);
                            let dd = getDate.getDate();
                            let mm = getDate.getMonth() + 1;
                            let yyyy = getDate.getFullYear();

                            if (dd < 10) {
                                dd = '0' + dd;
                            }
                            if (mm < 10) {
                                mm = '0' + mm;
                            }
                            let coverted = dd + '/' + mm + '/' + yyyy;
                            values[0].AWBDateandNo = response.data.trimSubmitApprovalList[0].awbDate === null ? '' : coverted + ' / ' + response.data.trimSubmitApprovalList[0].awbName;
                            values[0].AWBDate = new Date(response.data.trimSubmitApprovalList[0].awbDate)
                            values[0].AWBName = response.data.trimSubmitApprovalList[0].awbName;
                        }
                    }

                    else {
                        if (response.data.trimSubmitApprovalList[0].sendThrough === 2) {
                           ;
                            setshowawb(true);
                            values[0].AWBDateandNo = '' + '  ' + '';
                            values[0].AWBDate = response.data.trimSubmitApprovalList[0].awbDate === "" ? "" : new Date(response.data.trimSubmitApprovalList[0].awbDate)
                            values[0].AWBName = response.data.trimSubmitApprovalList[0].awbName;

                        }

                    }
                    values[0].SendTo = response.data.trimSubmitApprovalList[0].sendTo;
                    values[0].SendToEdit = response.data.trimSubmitApprovalList[0].sendToNames;
                    values[0].SwatchCardIamgePath = response.data.trimSubmitApprovalList[0].swatchCardImagePath;
                    values[0].SwatchCardImageName = response.data.trimSubmitApprovalList[0].swatchCardImageName;
                    values[0].Remarks = response.data.trimSubmitApprovalList[0].remarks;

                    let images = [];
                    images = response.data.trimSubmitApprovalList[0].trimImageList.split(',');
                    setSubImageList(images);
                    setCurrimageindex(0);

                    //   values[0].TNALabdibSubmittedOption = [];
                    response.data.trimSubmittedOption.forEach(element => {
                        values[0].TNATrimSubmittedOption.push({
                            OptionName: element.optionName,
                            SupplierReferenceNo: element.supplierReferenceNo
                        })
                    })

                    setInputFields(values);
                    setOrderinfo(response.data.trimSubmissionOrderInfoList);
                    let data = [...new Map(response.data.trimSubmissionOrderInfoList.map(item =>
                        [item['trimLogoId'], item])).values()];

                    setOrderInfoMainDetails(data);
                    setOrderinfo(data);
                    handleExpandRow(data[0].trimLogoPath, data);
                    hideLoader();
                }
            }).catch(() => { hideLoader(); });
        }





        //setOrderinfo(getOrderinfo);

    }, [])



    const handleRemoveFields = index => {
        values[0].TNATrimSubmittedOption.splice(index, 1);
        setInputFields(values);
    };


    const handleAddField = (index) => {
        var isvalid = true;

        inputFields.forEach((element, index) => {
            if (values[0].TNATrimSubmittedOption[index].OptionName === '' || values[0].TNATrimSubmittedOption[index].SupplierReferenceNo === '') {
                isvalid = false;
            }
        })
        if (isvalid) {
            values[0].TNATrimSubmittedOption.push({ OptionName: '', SupplierReferenceNo: '' });
            setInputFields(values);
            setSubmitted(false);
        }
        else {
            Nodify('Warning!', 'warning', 'Please fill Option and Supplier Reference No.');
            setSubmitted(true);
        }


    }
    const handleRemoveSample = (index) => {
        const value = [...getOrderinfo]
        value.splice(index, 1);
        setOrderinfo(value);

    }
    const OnChangeOrderinfo = (event, index) => {
        const orderinfo = [...getOrderinfo];
        let SelectedCount = 0;
        orderinfo.forEach(element => {
            if (element.SelectedDetail === 1) {
                SelectedCount = SelectedCount + 1;
            }
        })
        if (event.target.checked === true) {
            if (SelectedCount === 1) {
                orderinfo[index].SelectedDetail = 0;
                Nodify('Warning!', 'warning', 'Please select only one.');
            }
            else {
                orderinfo[index].SelectedDetail = 1;
            }
        }
        else {
            orderinfo[index].SelectedDetail = 0;
        }
        setOrderinfo(orderinfo);

        let SelectedDetail = '';
        let SelectedRef = '';
        orderinfo.forEach(element => {
            if (element.SelectedDetail === 1) {
                SelectedDetail = element.tnaTrimRefInfoId;
                SelectedRef = element.strikeOffOrTrimReferenceID;
            }
        })
        TnaService.GetTrimDimension(2, SelectedDetail, IsProduction).then((response) => {
            if (response.data) {
                setTrimDimension(response.data);
            }

        }).catch(() => { });
        values[0].TrimReferenceID = SelectedRef;
        setInputFields(values);
    }



    const handlepopupchange = (event, feild) => {
        const values = [...inputFields]
        let input = '';

        if (feild === "AWBName") {
            if (event.target.value.trim() !== '') {
                input = event.target.value;
            }
            values[0].AWBName = input;
        }
        else if (feild === "AWBDate") {
            values[0].AWBDate = event;
        }
        setInputFields(values);
    }
    const SaveAwbnoDate = () => {
        let values = [...inputFields]
        if (values[0].AWBName === "" || values[0].AWBDate === "" || values[0].AWBDate === null) {
            values[0].AWBDateandNo = values[0].AWBName + " / " + values[0].AWBDate;
            setShowTrimPopup(false);
            // setSubmittedawb(true)
        }
        else {
            values[0].AWBDateandNo = values[0].AWBName + " / " + values[0].AWBDate.toLocaleDateString('en-GB');
            setShowTrimPopup(false);
        }
        setInputFields(values);
    }
    const ViewFile = (event, filename) => { // event.preventDefault();
        setIsOpen(true);
        setImagename(filename);
    }
    const openFileUpload = () => {
        $('#upload-button').trigger('click');
    }

    const UploadImages = async (e) => {
        const file = e.target.files[0];
        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png'];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#FileUpload').val("");
            }
            else {
                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "TNA");
                try {
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                    values[0].SwatchCardImageName = file.name;
                    values[0].SwatchCardIamgePath = "Images/TNAImages/" + file.name;
                    setInputFields(values);
                    $('#FileUpload').val("");

                } catch (ex) {
                    console.log(ex);
                }
            }
        }
    }




    const handlemultidd = (event, index) => {
       ;
        if (event.length === 0) {
            values[0].SendTo = "";
            values[0].SendToEdit = '';
            // values[index].SkuColorNameIds = "";
        } else {
            let strValue = "";
            for (let Sku of event) {
                if (strValue === "") {
                    strValue = Sku.value.toString();
                } else {
                    strValue = strValue + "," + Sku.value;
                }
            }
            values[0].SendTo = strValue;
            values[0].SendToEdit = event;
        }

        setInputFields(values);
    }
    const handleChange = (event, feild) => {

        let input = '';

        if (feild === "SubmittedDate") {
            values[0].SubmittedDate = event;
        }
        else if (feild === "Sendthrough") {
            if (event.target.value !== '') {
                input = event.target.value;
            }
            values[0].SendThrough = parseInt(input);
            if (input === "2") {
                setshowawb(true);
            }
            else if (input === "1") {
                values[0].AWBDateandNo = ''
                values[0].AWBDate = ''
                values[0].AWBName = ''
                setSubmittedawb(false)
                setshowawb(false);
            }

            else {
                setshowawb(false);
            }
        }

        else if (feild === "Remarks") {
            if (event.target.value !== '') {
                input = event.target.value;
            }
            values[0].Remarks = input;
        }
        else if (feild === "TrimReferenceID") {
            if (event.target.value !== '') {
                input = event.target.value;
            }
            values[0].TrimReferenceID = input;
        }

        setInputFields(values);
    }

    const handleExpandRowMultiSelect = (trimLogoPath, list) => {
        setExpandedRowsMultiSelect([]);
        setexpandListMultiSelect(list.filter(x => x.strikeofforTrimLogoPath === trimLogoPath));
        let currentExpandedRows = expandedRowsMultiSelect;
        const isRowExpanded = currentExpandedRows.includes(trimLogoPath);
        let obj = {};
        isRowExpanded ? (obj[trimLogoPath] = false) : (obj[trimLogoPath] = true);
        setExpandStateMultiSelect(obj);
        currentExpandedRows = [];
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== trimLogoPath) :
            currentExpandedRows.concat(trimLogoPath);
        setExpandedRowsMultiSelect(newExpandedRows);


    }

    const handleExpandRow = (trimLogoPath, list) => {
        setExpandedRows([]);
        setexpandList(list.filter(x => x.strikeofforTrimLogoPath === trimLogoPath));
        let currentExpandedRows = expandedRows;
        const isRowExpanded = currentExpandedRows.includes(trimLogoPath);
        let obj = {};
        isRowExpanded ? (obj[trimLogoPath] = false) : (obj[trimLogoPath] = true);
        setExpandState(obj);
        currentExpandedRows = [];
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== trimLogoPath) :
            currentExpandedRows.concat(trimLogoPath);
        setExpandedRows(newExpandedRows);
    }
    // const openStrikeoffpopup = () => {
    //     setShowTrimPopup(true);
    // }
    const trimModalPopup = () => {
        setShowTrimPopup(true);
    }


    const closeTrimModal = () => {
        setShowTrimPopup(false);
    }
    const handleSubmitoptionchange = (event, feild, index) => {
        let input = '';
        if (event.target.value !== '') {
            input = event.target.value;
        }
        if (feild === "OptionName") {
            values[0].TNATrimSubmittedOption[index].OptionName = input;
        }
        else if (feild === "SupplierReferenceNo") {
            values[0].TNATrimSubmittedOption[index].SupplierReferenceNo = input;
        }
        setInputFields(values);
    }
    const handleRemoveOrderInfo = (index) => {
        const value = [...getOrderinfo]
        value.splice(index, 1);
        setOrderinfo(value);

    }

    const SaveSubmission = () => {

        let CheckSubmittedOption = true;

        values[0].TNATrimSubmittedOption.forEach(element => {
            if (element.OptionName === '' || element.SupplierReferenceNo === '') {
                CheckSubmittedOption = false;
            }
        })


        if (values[0].SubmittedDate === '' || values[0].SendTo === "" || values[0].SendThrough === 0 ||

            values[0].TrimReferenceID === "0") {
            Nodify("Warning!", "warning", "Please fill the mandatory(*) fields.");
            setSubmittedpopup(true);
        }
        else if (CheckSubmittedOption === false) {
            Nodify("Warning!", "warning", "Please fill the mandatory(*) fields in Submitted Option Section.");
            setSubmitted(true);
        }
        else {

            let Orderinfo = [];
            getOrderinfo.forEach((element, index) => {
                Orderinfo.push({
                    TrimOrderInfoId: 0,
                    StyleId: element.styleId, StyleName: element.styleName, StyleNo: element.styleNo,
                    IdPoNo: element.idpoNo, TrimReferenceID: element.strikeOffOrTrimReferenceID,
                    StyleTrimRefId: element.styleStrikeOffOrTrimReferenceId,
                    TaskOwnerIdList: element.taskOwnerIdList, StrikeOffOrderApprovalSubmissionStatus: 1,
                    TrimLogoId: parseInt(element.strikeofforTrimLogoId),
                    TNATrimRefInfoId: element.tnaTrimRefInfoId, TNAId: element.tnaId
                })
            })
            values[0].SubmittedDate = new Date(values[0].SubmittedDate.setMinutes(values[0].SubmittedDate.getMinutes() + 370))
            if (values[0].AWBDate === '' || values[0].AWBDate === null) {
                values[0].AWBDate = ''
            }
            else {
                values[0].AWBDate = new Date(values[0].AWBDate.setMinutes(values[0].AWBDate.getMinutes() + 370))
            }
            values[0].SubmissionApprovalStatus = 1;
            values[0].Operation = 1;
            values[0].TNATrimOrderInfo = Orderinfo;
            //values[0].TNATrimOrderInfo[0].TNATrimRefInfoId = props.TNATrimRefInfoId;
            TnaService.InsertUpdateTrimSubmissionApproval(values[0]).then(res => {
                if (res.data.outputResult === "1") {
                    Nodify('Success!', 'success', 'Trim Submission Added Successfully');
                    props.TrimSubmissionCallback(true, Orderinfo,0)
                } else if (res.data.outputResult === "2") {
                    Nodify('Success!', 'success', 'Trim Submission Updated Successfully');
                    props.TrimSubmissionCallback(true, Orderinfo)
                }
                else if (res.data.outputResult === "0") {
                    Nodify("Error!", "Error Occured!");
                }
            });
        }

    }

    const previmage = () => {
        if (getCurrimageindex === 0) {
            setCurrimageindex(0);
        }
        else {
            setCurrimageindex(getCurrimageindex - 1);
        }
    }

    const nextimage = () => {
        if (getCurrimageindex === getSubImageList.length - 1) {
            setCurrimageindex(getSubImageList.length - 1);
        }
        else {
            setCurrimageindex(getCurrimageindex + 1);
        }
    }


    const OnSelectRecords = (event, index) => {
        const value = [...MultiCloseDatas];

        if (event.target.checked === true) {
            value[index].isSelected = 1;
        }
        else {
            value[index].isSelected = 0;
            setselectall(0);
        }
        setMultiCloseDatas(value);
    }

    const SelectAll = (event, strikeofforTrimLogoPath) => {
        let IsSelected = 0;

        if (event.target.checked === true) {
            IsSelected = 1;
            MultiCloseDatas.filter(x => x.strikeofforTrimLogoPath === strikeofforTrimLogoPath).forEach(element => {
                element.isSelected = 1;
            })
        }
        else {
            IsSelected = 0;
            MultiCloseDatas.filter(x => x.strikeofforTrimLogoPath === strikeofforTrimLogoPath).forEach(element => {
                element.isSelected = 0;
            })
        }
        setselectall(IsSelected);
    }

    const AddRows = () => {
        let FileteredRecords = MultiCloseDatas.filter(x => x.isSelected === 1);
        if (FileteredRecords.length === 0) {
            Nodify("Warning!", "warning", "Select atleast one row.");
        }
        else {
            setshowMultiClosediv(false);
            setshowSubmissiondiv(true);
            // let data = [...new Map(FileteredRecords.map(item =>
            //     [item['trimInfoId'], item])).values()];
            var index = 0;
            FileteredRecords.forEach(element => {
                index = index + 1;
                element.index = index;
            })
            let data = [...new Map(FileteredRecords.map(item =>
                [item['strikeofforTrimLogoId'], item])).values()];
            setOrderInfoMainDetails(data);
            setOrderinfo(FileteredRecords);
            handleExpandRow(FileteredRecords[0].strikeofforTrimLogoPath, FileteredRecords);
        }
    }

    const ShowHideDivs = () => {
        setshowMultiClosediv(true);
        setshowSubmissiondiv(false);
    }


    const CustomInput = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
                style={{
                    border: submittedpopup && props.value === '' ? '1px solid red' : ''
                }}
            />
        )


    }
    const CustomInputAWB = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
                style={{ border: submittedawb && props.value === '' ? '1px solid red' : '' }}
            />
        )
    }

    // const MultiValueLabel = (props) => {
    //     const { innerProps, children, data } = props;
    //     return (
    //       <div title={data.name} {...innerProps}>
    //         {children}
    //       </div>
    //     );
    //   };



    return (

        <div id="registration-form">
            <Form>
                {
                    showMultiClosediv === true ?
                        <>

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="alert alert-info">
                                        Note : If you want to submit multiple, you can select multiple items.
                                    </div>
                                    <div className="dataTables_wrapper no-footer">
                                        {
                                            OrderInfoMulticloseMainDetails.length !== 0 ?

                                                <table className="table table-bordered">

                                                    <tbody>
                                                        {
                                                            OrderInfoMulticloseMainDetails.map((comment, mainindex) => (
                                                                <Fragment>
                                                                    <tr>
                                                                        <td>
                                                                            <div className="row">
                                                                                <div className="col-lg-8">
                                                                                    <i
                                                                                        className={
                                                                                            expandStateMultiSelect[comment.strikeofforTrimLogoPath] ?
                                                                                                'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                        }
                                                                                        onClick={() => handleExpandRowMultiSelect(comment.strikeofforTrimLogoPath, MultiCloseDatas)}
                                                                                    ></i>
                                                                                    <span className="BuyerPaddingLeft">
                                                                                        {
                                                                                            comment.strikeofforTrimCode
                                                                                        }
                                                                                        -
                                                                                        {
                                                                                            comment.strikeofforTrimName
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <span className="Actionbutton">
                                                                                        <img src={window.$APIBaseURL + "Images/Style/Trim/" + comment.strikeofforTrimLogoPath} height="50px" width="50px"></img>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </td>


                                                                    </tr>
                                                                    <>
                                                                        {
                                                                            expandedRowsMultiSelect.includes(comment.strikeofforTrimLogoPath) ?

                                                                                <tr>
                                                                                    <td>
                                                                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>Style Name</th>
                                                                                                    <th>Style No</th>
                                                                                                    <th>PO/ID No</th>
                                                                                                    <th>Details</th>
                                                                                                    <th>Task Holder / Follower</th>
                                                                                                    <th>
                                                                                                        <label style={{ marginTop: '8px' }}>
                                                                                                            <input type="checkbox" name="IsSelected"

                                                                                                                onChange={event => (SelectAll(event, comment.strikeofforTrimLogoPath))}
                                                                                                                checked={selectall === 1 ? true : false}
                                                                                                                className="colored-blue clearOpacity" />
                                                                                                            <span className="text"></span>
                                                                                                        </label>

                                                                                                    </th>

                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {
                                                                                                    expandListMultiSelect.map((childdata, index) => (
                                                                                                        <Fragment>

                                                                                                            <tr>
                                                                                                                <td>{childdata.styleName}</td>
                                                                                                                <td>{childdata.styleNo}</td>
                                                                                                                <td>{childdata.idpoNo}</td>
                                                                                                                <td>{childdata.details}</td>
                                                                                                                <td>{childdata.taskOwnerNameList}</td>
                                                                                                                <td className="Actionbutton fixed-column">
                                                                                                                    <div style={{ width: "80px" }}>
                                                                                                                        <label style={{ marginTop: '8px' }}>
                                                                                                                            {
                                                                                                                                props.commonParams !== undefined ?
                                                                                                                                    // (childdata.dependencyStartstop === 1 || childdata.isSelected === 1 || (!props.commonParams.SelectedDependency) || getSelectedDependencyMap(props.commonParams.SelectedDependency) === true
                                                                                                                                    // ) &&


                                                                                                                                    <input type="checkbox" name="IsSelected"
                                                                                                                                        onChange={event => (OnSelectRecords(event, childdata.index - 1, mainindex))}
                                                                                                                                        checked={childdata.isSelected === 1 ? true : false}
                                                                                                                                        className="colored-blue clearOpacity" />

                                                                                                                                    :
                                                                                                                                    <input type="checkbox" name="IsSelected"
                                                                                                                                        onChange={event => (OnSelectRecords(event, childdata.index - 1, mainindex))}
                                                                                                                                        checked={childdata.isSelected === 1 ? true : false}
                                                                                                                                        className="colored-blue clearOpacity" />

                                                                                                                            }
                                                                                                                            <span className="text"></span>

                                                                                                                        </label>&nbsp;&nbsp;&nbsp;
                                                                                                                        {/* {
                                                                                                                            // props.commonParams && props.commonParams.SelectedDependency &&
                                                                                                                            //   getSelectedDependencyMap(props.commonParams.SelectedDependency) === false && childdata.StatusID !== 3 &&
                                                                                                                            childdata.Depandency !== '' &&
                                                                                                                            <button type="button" className={(childdata.dependencyStartstop === 1 || childdata.isSelected === 1) ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Dependency)" >
                                                                                                                                <i className={(childdata.dependencyStartstop === 1 || childdata.isSelected === 1) ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>
                                                                                                                            </button>
                                                                                                                        } */}

                                                                                                                    </div>

                                                                                                                </td>

                                                                                                            </tr>

                                                                                                        </Fragment>
                                                                                                    ))

                                                                                                }

                                                                                            </tbody>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr> : null
                                                                        }
                                                                    </>
                                                                </Fragment>
                                                            ))
                                                        }






                                                    </tbody>
                                                </table>
                                                :
                                                <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                    <TableHeader headers={headers} />
                                                    <tbody>
                                                        <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                                                    </tbody>
                                                </table>
                                        }
                                    </div>
                                </div>
                            </div>
                            <br />

                            <div className="row">
                                <div className="col-sm-12" align="right">
                                    <button type="button" className="btn btn-success" onClick={() => AddRows()}>

                                        <i className="fa fa-check right"></i> Add
                                    </button>
                                </div>
                            </div>
                        </>
                        : ''
                }
                {
                    showSubmissiondiv === true ?
                        <>
                            <div className="row" style={{ overflowX: "hidden", height: "700px", padding: "5px", margin: "0px" }}>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <div className='form-group'>
                                                <label htmlFor="BuyerID">Buyer-Season-Brand</label>
                                                <span className='input-icon icon-right'>
                                                    <Input
                                                        placeholder="BuyerSeasonBrand"
                                                        className="form-control cut_text"
                                                        title={inputFields[0].BuyerBrandSeasonName}
                                                        name="BuyerSeasonBrand"
                                                        id={
                                                            "BuyerSeasonBrand"
                                                        }
                                                        value={
                                                            inputFields[0].BuyerBrandSeasonName
                                                        }
                                                        disabled
                                                    ></Input>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="col-sm-3">
                                            <div className='form-group'>
                                                <label htmlFor="SeasonID" >Supplier</label>
                                                <span className='input-icon icon-right'>
                                                    <Input
                                                        placeholder="Supplier"
                                                        className="form-control cut_text"
                                                        title={inputFields[0].SupplierName}
                                                        name="Supplier"
                                                        id={
                                                            "Supplier"
                                                        }
                                                        value={
                                                            inputFields[0].SupplierName
                                                        }
                                                        disabled

                                                    ></Input>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="col-sm-3">
                                            <div className='form-group'>
                                                <label htmlFor="Name" >Submit Date<span className="text-danger">*</span></label>
                                                <span className='input-icon icon-right'>
                                                    {
                                                        props.TaskStatus !== 3 ?


                                                            <DatePicker className="form-control" name="SubmittedDate"
                                                                id={
                                                                    "SubmittedDate"
                                                                }
                                                                selected={
                                                                    inputFields[0].SubmittedDate
                                                                }
                                                                onChange={event => handleChange(event, 'SubmittedDate')}
                                                                dateFormat="dd/MM/yyyy"
                                                                peekNextMonth

                                                                dropdownMode="scroll"
                                                                autoComplete="off"
                                                                customInput={<CustomInput />}
                                                                isClearable={inputFields[0].SubmittedDate === null ? false : true}
                                                            />
                                                            // < input id="SubmittedDate" name="SubmittedDate"
                                                            //     value={inputFields.SubmittedDate} placeholder="DD/MM/YYYY"

                                                            //     type="date" autoComplete="off"
                                                            //     className={'form-control'} onKeyDown={(e) => e.preventDefault()}
                                                            //     style={{
                                                            //         border: submittedpopup && inputFields[0].SubmittedDate === '' ? '1px solid red' : ''
                                                            //     }}
                                                            //     onChange={event => handleChange(event, 'SubmittedDate')}
                                                            // />
                                                            :
                                                            <DatePicker className="form-control" name="SubmittedDate"
                                                                id={
                                                                    "SubmittedDate"
                                                                }
                                                                selected={
                                                                    inputFields[0].SubmittedDate
                                                                }

                                                                dateFormat="dd/MM/yyyy"
                                                                peekNextMonth

                                                                //customInput={<CustomInput />}
                                                                disabled
                                                            />






                                                        // < input id="SubmittedDate" name="SubmittedDate"
                                                        //     value={inputFields.SubmittedDate} placeholder="DD/MM/YYYY"

                                                        //     type="date" autoComplete="off"
                                                        //     className={'form-control'} onKeyDown={(e) => e.preventDefault()}
                                                        //     style={{
                                                        //         border: submittedpopup && inputFields[0].SubmittedDate === '' ? '1px solid red' : ''
                                                        //     }}
                                                        //     onChange={event => handleChange(event, 'SubmittedDate')}
                                                        // />
                                                        // :
                                                        // <input
                                                        //     placeholder="SubmittedDate"
                                                        //     className="form-control"
                                                        //     title={inputFields[0].SubmittedDate}
                                                        //     name="SubmittedDate"
                                                        //     id={"SubmittedDate"}
                                                        //     value={inputFields[0].SubmittedDate}
                                                        //     disabled

                                                        // ></input>
                                                    }

                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor={"Quarter"}>Send To<span className="text-danger">*</span></label>
                                                <span className="input-icon icon-right">
                                                    {props.TaskStatus === 3 ?

                                                        <span>
                                                            <input
                                                                className="form-control"
                                                                title={inputFields[0].SendToEdit}
                                                                value={inputFields[0].SendToEdit} />
                                                        </span>
                                                        :
                                                        <span className=''  title={Array.isArray(inputFields[0].SendToEdit) ? inputFields[0].SendToEdit.map(item => item.name).join(', ') : ''}>
                                                            <Reactselect
                                                                className="basic-single SentTo SendToHeight"
                                                                name={"Sku"}
                                                                id={
                                                                    "Sent To"
                                                                }
                                                                // value={item.MeasurementPoints}
                                                                //isDisabled={false}
                                                                isLoading={false}
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                options={getTaskholder}
                                                                value={inputFields[0].SendToEdit}
                                                                isMulti
                                                                // components={{
                                                                //     MultiValueLabel,
                                                                //  }}
                                                                onChange={event => handlemultidd(event, 'SendTo')}
                                                                menuPosition="fixed"
                                                                styles={submittedpopup && inputFields[0].SendTo === "" ? styles2 : styles1}
                                                            // readOnly
                                                            // isDisabled={props.TaskStatus === 3 && (props.editLabdipSubmission === false || chkDataExists === true) ? true : false}
                                                            />
                                                        </span>

                                                    }
                                                    {/* <select id={"SendTo"} name="SendTo" className="form-select"
                                                        value={inputFields[0].SendTo}
                                                        onChange={(event) => handleChange(event, 'SendTo')}
                                                        style={{ border: submittedpopup && inputFields[0].SendTo === "0" ? '1px solid red' : '' }}
                                                        disabled={props.TaskStatus === 3 ? true : false}
                                                    >
                                                        <option value="0">-Select SendTo-</option>
                                                        {
                                                            getTaskholder.map(item => (
                                                                <option key={item.id} value={item.value}>
                                                                    {item.label}
                                                                </option>
                                                            ))
                                                        }
                                                    </select> */}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <div className='form-group'>
                                                <label htmlFor="BuyerID">Style Name  <span className="text-danger">*</span></label>
                                                <span className='input-icon icon-right'>
                                                    <Input
                                                        placeholder="StyleName"
                                                        className="form-control cut_text"
                                                        title={inputFields[0].StyleName}
                                                        name="StyleName"
                                                        id={
                                                            "StyleName"
                                                        }
                                                        value={
                                                            inputFields[0].StyleName
                                                        }
                                                        disabled
                                                    ></Input>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="col-sm-3">
                                            <div className='form-group'>
                                                <label htmlFor="SeasonID" >Style No  <span className="text-danger">*</span></label>
                                                <span className='input-icon icon-right'>
                                                    <Input
                                                        placeholder="StyleNo"
                                                        className="form-control cut_text"
                                                        title={inputFields[0].StyleNo}
                                                        name="StyleNo"
                                                        id={
                                                            "StyleNo"
                                                        }
                                                        value={
                                                            inputFields[0].StyleNo
                                                        }

                                                        disabled
                                                    ></Input>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="col-sm-3">
                                            <div className='form-group'>
                                                <label htmlFor="Name" >PO/ID No  <span className="text-danger">*</span></label>
                                                <span className='input-icon icon-right'>
                                                    <Input
                                                        placeholder="Idpono"
                                                        className="form-control cut_text"
                                                        title={inputFields[0].IdpoNo}
                                                        name="IDPONo"
                                                        id={
                                                            "IDPONo"
                                                        }
                                                        value={
                                                            inputFields[0].IdpoNo
                                                        }

                                                        disabled
                                                    ></Input>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor={"EndCustomer"}>End Customer  <span className="text-danger">*</span></label>
                                                <span className="input-icon icon-right">

                                                    <Input
                                                        placeholder="End Customer"
                                                        className="form-control cut_text"
                                                        title={inputFields[0].EndCustomer}
                                                        name="EndCustomer"
                                                        id={
                                                            "EndCustomer"
                                                        }
                                                        value={
                                                            inputFields[0].EndCustomer
                                                        }
                                                        disabled

                                                    ></Input>

                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor={"Sendthrough"}>Send Through<span className="text-danger">*</span></label>
                                                <select id={"Sendthrough"} name="Sendthrough" className="form-select"
                                                    value={inputFields[0].SendThrough}
                                                    onChange={event => handleChange(event, 'Sendthrough')}
                                                    disabled={props.TaskStatus === 3 ? true : false}
                                                    style={{ border: submittedpopup && inputFields[0].SendThrough === 0 ? '1px solid red' : '' }}
                                                >
                                                    <option value="0" key="0" style={{ color: '#bfdea1' }}> - Select Sendthrough- </option>
                                                    <option value="1" key="1">Hand Carried</option>
                                                    <option value="2" key="2">Courier - DHL</option>

                                                </select>


                                            </div>
                                        </div>
                                        {
                                            showawb === true ?
                                                <div className="col-sm-3">
                                                    <div className='form-group'>
                                                        <label htmlFor="Awbdate" >AWB / Date</label>
                                                        <span className='input-icon icon-right'>
                                                            <Input
                                                                placeholder="AWB / Date"
                                                                className="form-control cut_text"
                                                                title={inputFields[0].AWBDateandNo}
                                                                name="AWBDateandNo"
                                                                id={
                                                                    "AWBDateandNo"
                                                                }
                                                                value={
                                                                    inputFields[0].AWBDateandNo
                                                                }
                                                                onClick={() => trimModalPopup()}
                                                                // style={{ border: submittedpopup && inputFields[0].AWBDateandNo === '' ? '1px solid red' : '' }}
                                                                disabled={props.TaskStatus === 3 ? true : false}
                                                            ></Input>
                                                        </span>
                                                    </div>
                                                </div> : ''
                                        }

                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="tabbable">
                                            <ul className="nav nav-tabs nav-justified" id="myTab5">
                                                <li className="active">
                                                    <a data-toggle="tab" href="#idSubmitInformation">
                                                        <h5>Submit Information</h5>
                                                    </a>
                                                </li>
                                                <li className="" >
                                                    <a data-toggle="tab" href="#idOrderInformation" >

                                                        <h5>Order Information</h5>
                                                    </a>
                                                </li>
                                            </ul>
                                            <div className="tab-content">
                                                <div id="idSubmitInformation" className="tab-pane in active" style={{ width: "100%" }}>
                                                    <div className="row">
                                                        <div className="col-sm-10">
                                                            <div className="row">
                                                                <div className="col-sm-3">
                                                                    <div className='form-group'>
                                                                        <label htmlFor="BuyerID">Trim Code</label>
                                                                        <span className='input-icon icon-right'>
                                                                            <Input
                                                                                placeholder="Trim Code"
                                                                                className="form-control cut_text"
                                                                                title={inputFields[0].TrimCode}
                                                                                name="TrimCode"
                                                                                id={
                                                                                    "TrimCode"
                                                                                }
                                                                                value={
                                                                                    inputFields[0].TrimCode
                                                                                }
                                                                                disabled
                                                                            ></Input>
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-3">
                                                                    <div className='form-group'>
                                                                        <label htmlFor="SeasonID" >Trim Name</label>
                                                                        <span className='input-icon icon-right'>
                                                                            <Input
                                                                                placeholder="Trim Name"
                                                                                className="form-control cut_text"
                                                                                title={inputFields[0].TrimName}
                                                                                name="TrimName"
                                                                                id={
                                                                                    "TrimName"
                                                                                }
                                                                                value={
                                                                                    inputFields[0].TrimName
                                                                                }

                                                                                disabled
                                                                            ></Input>
                                                                        </span>
                                                                    </div>
                                                                </div>



                                                                <div className="col-sm-3">
                                                                    <div className="form-group">
                                                                        <label htmlFor={"Quarter"}>Technical Specification</label>
                                                                        <span className="input-icon icon-right">

                                                                            <Input
                                                                                placeholder="Technical Specification"
                                                                                className="form-control cut_text"
                                                                                title={inputFields[0].TechnicalSpecification}
                                                                                name="TechnicalSpecification"
                                                                                id={
                                                                                    "TechnicalSpecification"
                                                                                }
                                                                                value={
                                                                                    inputFields[0].TechnicalSpecification
                                                                                }

                                                                                disabled
                                                                            ></Input>

                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-3">
                                                                    <div className="form-group">
                                                                        <label htmlFor={"Quarter"}>Trim Image </label>
                                                                        <br />
                                                                        <i className="fa fa-chevron-circle-left fa-2x" onClick={() => previmage()} style={{ cursor: "pointer" }} ></i>
                                                                        {
                                                                            getSubImageList.length !== 0 ?
                                                                                <img src={window.$APIBaseURL + "Images/Style/Trim/" + getSubImageList[getCurrimageindex]} alt="No Image" width="150px" height="150px" style={{ border: "2px solid black" }} />
                                                                                :
                                                                                <div align="center" style={{ width: "150px", height: "150px" }}>
                                                                                    No Images Found
                                                                                </div>
                                                                        }
                                                                        <i className="fa fa-chevron-circle-right fa-2x" onClick={() => nextimage()} style={{ cursor: "pointer" }}></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <div className="form-group">

                                                                        <label htmlFor={"TrimReferenceID"}>Trim Dimension <span className="text-danger">*</span></label>

                                                                        <select id={"TrimReferenceID"} name="TrimReferenceID" className="form-select"
                                                                            value={inputFields[0].TrimReferenceID}
                                                                            onChange={(event) => handleChange(event, 'TrimReferenceID')}
                                                                            style={{ border: submittedpopup && inputFields[0].TrimReferenceID === "0" ? '1px solid red' : '' }}
                                                                            disabled={props.TaskStatus === 3 ? true : false}
                                                                        >
                                                                            <option value="0">-Select StrikeOff-</option>
                                                                            {
                                                                                TrimDimension.map(item => (
                                                                                    <option key={item.id} value={item.value}>
                                                                                        {item.label}
                                                                                    </option>
                                                                                ))
                                                                            }

                                                                        </select>




                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <table>
                                                                    <tr>
                                                                        <td><label>Submit Options</label> </td>
                                                                        <td><label>Swatch Card (Image Upload)</label></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <div className="col-sm-6">
                                                                            <div className="form-group">


                                                                                <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup"
                                                                                    style={{ width: "500px" }}>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th >Options <span className="text-danger">*</span></th>
                                                                                            <th>Supplier Reference No <span className="text-danger">*</span></th>
                                                                                            <th >Action</th>

                                                                                        </tr>

                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            inputFields[0].TNATrimSubmittedOption.map((item, index) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <span className="input-icon icon-right">
                                                                                                                    <Input
                                                                                                                        placeholder=""
                                                                                                                        className="form-control cut_text"
                                                                                                                        title={item.OptionName}
                                                                                                                        name="OptionName"
                                                                                                                        id={
                                                                                                                            "OptionName"
                                                                                                                        }
                                                                                                                        value={
                                                                                                                            item.OptionName
                                                                                                                        }
                                                                                                                        onChange={event => (handleSubmitoptionchange(event, 'OptionName', index))}
                                                                                                                        style={{ width: "250px", border: submitted && item.OptionName === '' ? '1px solid red' : '' }}
                                                                                                                        disabled={props.TaskStatus === 3 ? true : false}
                                                                                                                    ></Input>


                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <span className='input-icon icon-right'>
                                                                                                                    <Input
                                                                                                                        placeholder=""
                                                                                                                        className="form-control cut_text"
                                                                                                                        title={item.SupplierReferenceNo}
                                                                                                                        name="SupplierReferenceNo"
                                                                                                                        id={
                                                                                                                            "SupplierReferenceNo"
                                                                                                                        }
                                                                                                                        value={
                                                                                                                            item.SupplierReferenceNo
                                                                                                                        }
                                                                                                                        onChange={event => (handleSubmitoptionchange(event, 'SupplierReferenceNo', index))}
                                                                                                                        style={{ width: "250px", border: submitted && item.SupplierReferenceNo === '' ? '1px solid red' : '' }}
                                                                                                                        disabled={props.TaskStatus === 3 ? true : false}
                                                                                                                    ></Input>
                                                                                                                </span>

                                                                                                            </td>
                                                                                                            <td className="Actionbutton fixed-column">
                                                                                                                {" "}
                                                                                                                <div style={{ width: "80px" }}>
                                                                                                                    {inputFields[0].TNATrimSubmittedOption.length !== 1 && (
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn  btn-xs  btn-danger"
                                                                                                                            title="Delete Option"
                                                                                                                            onClick={() =>
                                                                                                                                handleRemoveFields(index)
                                                                                                                            }
                                                                                                                            disabled={props.TaskStatus === 3 ? true : false}
                                                                                                                        >
                                                                                                                            <i className="fa fa-trash-o"></i>
                                                                                                                        </button>
                                                                                                                    )}
                                                                                                                    &nbsp;
                                                                                                                    {inputFields[0].TNATrimSubmittedOption.length === index + 1 && (
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn  btn-xs  btn-success"
                                                                                                                            title="Add Option"
                                                                                                                            onClick={() =>
                                                                                                                                handleAddField(index)
                                                                                                                            }
                                                                                                                            disabled={props.TaskStatus === 3 ? true : false}
                                                                                                                        >
                                                                                                                            <i className="fa fa-plus"></i>
                                                                                                                        </button>
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </>

                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                        <td>
                                                                            <div className={ImgClassName}
                                                                                htmlFor="upload-button"
                                                                            >
                                                                                <img
                                                                                    src={inputFields[0].SwatchCardIamgePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + inputFields[0].SwatchCardIamgePath}
                                                                                    // src={"assets/img/emptyImage.jpg"}
                                                                                    onClick={openFileUpload}
                                                                                    alt="Picture" style={{ marginBottom: '5px', height: '200px', width: '200px', border: "1px solid #d5d5d5" }} />
                                                                                <input type="file" id="upload-button" accept=".jpg,.jpeg,.png" onChange={(event) => UploadImages(event)} value=''
                                                                                    disabled={props.TaskStatus === 3 ? true : false}
                                                                                />
                                                                                {
                                                                                    inputFields.SwatchImageFileName !== "assets/img/uploadimage.jpg" && (
                                                                                        <div style={
                                                                                            {
                                                                                                marginTop: '8px',
                                                                                                float: 'right',
                                                                                                bottom: '10px',
                                                                                                position: 'relative',
                                                                                            }
                                                                                        }>
                                                                                            {/* {
                                                            inputFields[0].SwatchCardIamgePath !== "" && inputFields[0].SwatchCardIamgePath !== "assets/img/uploadimage.jpg" ?
                                                              <i className="btn btn-info fa fa-eye" title="View Logo"
                                                                style={{ marginLeft: "15px", position: "absolute" }}
                                                                onClick={
                                                                  event => ViewFile(event, inputFields.SwatchCardIamgePath)
                                                                }></i>
                                                              : ''

                                                          } */}

                                                                                        </div>
                                                                                    )
                                                                                }

                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </table>




                                                                <div> {
                                                                    isOpen && (

                                                                        <Lightbox mainSrc={window.$APIBaseURL + 'Images/SwatchImages/' + getImagename}
                                                                            alt="bg image"
                                                                            onCloseRequest={
                                                                                () => setIsOpen(false)
                                                                            } />
                                                                    )
                                                                } </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="idOrderInformation" className="tab-pane" style={{ width: "100%" }}>
                                                    <div className="row">
                                                        <div className="col-sm-12" align={"right"}>
                                                            <button type="button" className="btn btn-primary" onClick={() => ShowHideDivs()}
                                                                disabled={props.TaskStatus === 3 ? true : false}>
                                                                <i className="fa fa-plus"></i> &nbsp;Add
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="dataTables_wrapper no-footer">
                                                                {
                                                                    OrderInfoMainDetails.length !== 0 ?

                                                                        <table className="table table-bordered">

                                                                            <tbody>
                                                                                {

                                                                                    OrderInfoMainDetails.map(comment => (
                                                                                        <Fragment>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <div className="row">
                                                                                                        <div className="col-lg-8">
                                                                                                            <i
                                                                                                                className={
                                                                                                                    expandState[comment.strikeofforTrimLogoPath] ?
                                                                                                                        'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                                }
                                                                                                                onClick={() => handleExpandRow(comment.strikeofforTrimLogoPath, getOrderinfo)}
                                                                                                            ></i>
                                                                                                            <span className="BuyerPaddingLeft">
                                                                                                                {
                                                                                                                    props.TaskStatus !== 3 ? comment.strikeofforTrimCode : comment.trimCode
                                                                                                                }
                                                                                                                -
                                                                                                                {
                                                                                                                    props.TaskStatus !== 3 ? comment.strikeofforTrimName : comment.trimName
                                                                                                                }
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        <div className="col-lg-4">
                                                                                                            <span className="Actionbutton">
                                                                                                                <img src={window.$APIBaseURL + "Images/Style/Trim/" + comment.strikeofforTrimLogoPath} height="50px" width="50px"></img>
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </td>

                                                                                            </tr>
                                                                                            <>
                                                                                                {
                                                                                                    expandedRows.includes(comment.strikeofforTrimLogoPath) ?

                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                                                    <TableHeader headers={headers} />
                                                                                                                    <tbody>
                                                                                                                        {
                                                                                                                            expandList.map((childdata, index) => (
                                                                                                                                <Fragment>

                                                                                                                                    <tr>
                                                                                                                                        <td>{childdata.styleName}</td>
                                                                                                                                        <td>{childdata.styleNo}</td>
                                                                                                                                        <td>{childdata.idpoNo}</td>
                                                                                                                                        <td>{props.TaskStatus !== 3 ? childdata.details : childdata.trimDetailsWithSize}</td>
                                                                                                                                        <td>{childdata.taskOwnerNameList}</td>
                                                                                                                                        <td className="Actionbutton fixed-column">
                                                                                                                                            {" "}
                                                                                                                                            <div style={{ width: "80px" }}>
                                                                                                                                                <label style={{ marginTop: '8px' }}>
                                                                                                                                                    <input type="checkbox" name="SelectedDetail"

                                                                                                                                                        onChange={event => (OnChangeOrderinfo(event, index))}
                                                                                                                                                        checked={childdata.SelectedDetail === 1 ? true : false}
                                                                                                                                                        className="colored-blue clearOpacity"
                                                                                                                                                        disabled={props.TaskStatus === 3 ? true : false} />
                                                                                                                                                    <span className="text"></span>
                                                                                                                                                </label>
                                                                                                                                                &nbsp;&nbsp;

                                                                                                                                                <button
                                                                                                                                                    type="button"
                                                                                                                                                    className="btn  btn-xs  btn-danger"
                                                                                                                                                    title="Delete Trim"
                                                                                                                                                    onClick={() =>
                                                                                                                                                        handleRemoveSample(index)
                                                                                                                                                    }
                                                                                                                                                    disabled={props.TaskStatus === 3 ? true : false}
                                                                                                                                                >
                                                                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                                                                </button>

                                                                                                                                            </div>
                                                                                                                                        </td>

                                                                                                                                    </tr>

                                                                                                                                </Fragment>
                                                                                                                            ))

                                                                                                                        }

                                                                                                                    </tbody>
                                                                                                                </table>
                                                                                                            </td>
                                                                                                        </tr> : null
                                                                                                }
                                                                                            </>
                                                                                        </Fragment>
                                                                                    ))
                                                                                }


                                                                            </tbody>
                                                                        </table>
                                                                        :
                                                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                                            <TableHeader headers={headers} />
                                                                            <tbody>
                                                                                <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                                                                            </tbody>
                                                                        </table>
                                                                }



                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />


                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                                <div className="mt-10">
                                    <div className="row">
                                        <div className="col-sm-8 col-lg-8">
                                            <div className='form-group'>
                                                <label htmlFor="Remarks">Remarks</label>
                                                <textarea maxLength="300" name="Remarks" value={inputFields[0].Remarks}
                                                    onChange={event => handleChange(event, 'Remarks')}
                                                    rows="4" style={{ width: "100%" }}
                                                    disabled={props.TaskStatus === 3 ? true : false}
                                                ></textarea>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12" align="right">
                                    <button type="button" className="btn btn-success margin-Align" onClick={() => SaveSubmission()}
                                        disabled={props.TaskStatus === 3 ? true : false}>

                                        <i className="fa fa-check right"></i> &nbsp;{getHeader === 'Add' ? 'Save' : 'Update'}
                                    </button>
                                </div>
                            </div>
                            {
                                ShowTrimPopup === true ?
                                    <Modal show={ShowTrimPopup === true} size="md" dragable backdrop="static" keyboard={false} onHide={closeTrimModal} >
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                AWB and Date
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: "350px" }}>AWB<span className="text-danger">*</span></th>
                                                        <th style={{ width: "300px" }}>Date<span className="text-danger">*</span></th>
                                                        {/* <th styles={{ width: "100px" }}>Action</th> */}
                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    {
                                                        getStrikeOffDate.map((item, index) => {
                                                            return (
                                                                <>
                                                                    <tr>
                                                                        <td>
                                                                            <span className='input-icon icon-right'>
                                                                                <input type="text"
                                                                                    placeholder="AWB Name"
                                                                                    id={"AWBName"}
                                                                                    name="AWBName"
                                                                                    value={inputFields[0].AWBName}
                                                                                    style={{ border: submittedawb && !inputFields[0].AWBName ? '1px solid red' : '' }}
                                                                                    autoFocus
                                                                                    maxLength="25"
                                                                                    className={'form-control'}
                                                                                    onChange={event => handlepopupchange(event, 'AWBName')}
                                                                                // disabled={ChildField.DependencyID === 0 ? true : false}
                                                                                />
                                                                            </span>

                                                                        </td>
                                                                        <td>
                                                                            <DatePicker className="form-control" name="AWBDate"
                                                                                id={
                                                                                    "AWBDate"
                                                                                }
                                                                                selected={
                                                                                    inputFields[0].AWBDate
                                                                                }
                                                                                onChange={event => handlepopupchange(event, 'AWBDate')}
                                                                                dateFormat="dd/MM/yyyy"
                                                                                peekNextMonth
                                                                                style={{ border: submittedawb && inputFields[0].AWBDate === '' ? '1px solid red' : '' }}
                                                                                dropdownMode="scroll"
                                                                                autoComplete="off"
                                                                                customInput={<CustomInputAWB />}
                                                                                isClearable={inputFields[0].AWBDate === null ? false : true}
                                                                            />
                                                                            {/* <span className="input-icon icon-right">
                                                                                <input id={"AWBDate"} name="AWBDate"
                                                                                    value={inputFields[0].AWBDate} placeholder="DD/MM/YYYY"
                                                                                    type="date" autoComplete="off" onKeyDown={(e) => e.preventDefault()}
                                                                                    className={'form-control'}
                                                                                    style={{ border: submittedawb && inputFields[0].AWBDate === '' ? '1px solid red' : '' }}
                                                                                    onChange={event => handlepopupchange(event, 'AWBDate')}
                                                                                />
                                                                            </span> */}
                                                                        </td>

                                                                    </tr>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="success" disabled={buttonLoader} onClick={() => SaveAwbnoDate()}>
                                                <i className="fa fa-check right"></i>&nbsp;
                                                Save
                                            </Button>
                                        </Modal.Footer>
                                    </Modal> : ''
                            }

                            {
                                ShowAddTrimDimensionPopup === true ?
                                    <Modal show={ShowAddTrimDimensionPopup} size="md" dragable backdrop="static" keyboard={false} onHide={() => setShowAddTrimDimensionPopup(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                Add Trim Dimension
                                            </Modal.Title>
                                        </Modal.Header>
                                        {/* <Modal.Body>
                                  <Fabric
                                    FabricList={getFabricList}
                                    FabricCallBack={FabricCallback}
                                    showpopup={true}
                                    GroupTypeId={0}
                                  />
                                </Modal.Body> */}
                                    </Modal> : ''
                            }
                        </>
                        : ''
                }

            </Form>
            {loader}
        </div>
    );
};

export default TrimSubmission;