import {
 BUYCUSWAR_LOADING,
 RETRIEVE_BUYCUSWAR,
 DELETE_BUYCUSWAR,
} from "./types";

import BuyCusWarService from "../services/Master/BuyCusWarService";



const BuyCusWarlistsLoading = (isStatus) => ({
 type: BUYCUSWAR_LOADING,
 payload: isStatus,
});

export const deletetBuyCusWar = (buyerID) => async (dispatch) => {

 try {
  dispatch({
   type: DELETE_BUYCUSWAR,
   payload: buyerID,
  });

 } catch (err) {
  console.log(err);
 }
};

export const retrieveBuyCusWarlist = (Operation, Id) => async (dispatch) => {

 try {
  dispatch(BuyCusWarlistsLoading(true));
  const res = await BuyCusWarService.getBuyCusWarList(Operation, Id);
  dispatch({
   type: RETRIEVE_BUYCUSWAR,
   payload: res.data,
  });
  dispatch(BuyCusWarlistsLoading(false));
 } catch (err) {
  console.log(err);
  dispatch(BuyCusWarlistsLoading(false));
 }
};