import axios from "axios";
import authHeader from "../auth-header";

class QACommentPointService {
    GetQACommentPointList() {
        return axios.get(window.$APIBaseURL + "Master/QACommentList", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateQACommentPoints(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/UpdateQACommentInfo',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertQACommentPoints(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertQACommentInfo',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
}
export default new QACommentPointService()