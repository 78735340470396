import React, { useState, useEffect, useMemo,Fragment } from "react";
import ReactNotification from 'react-notifications-component';
import { TableHeader, Pagination, Search, PageCount } from "../../Datatable";
import { useDispatch, useSelector } from "react-redux";
import { retrievePackageImage, deletePackageImage } from "../../../actions/packageimage"
import CommonService from '../../../services/Common/CommonService';
import Nodify from "../../Common/ReactNotification";
import SearchRetain from "../../Common/SearchRetain";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { PackageImageID } from "../../Common/PageActionNumber"
import SweetAlertPopup from '../../Common/SweetAlertPopup';
import { Redirect } from 'react-router-dom';

import moment from 'moment';

const PackageImageList = (props) => {
    const [getPlaceholder] = useState("Package Image");
    const [getID, setID] = useState({ showPopupDelete: false, Params: {} });
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    const dispatch = useDispatch();
    //reducer
    const reducerState = useSelector((state) => state);
    const { user: currentUser } = reducerState.auth;
    const comments = reducerState.PackageImage.PackageImageList;
    let isLoadingPackageimage = reducerState.PackageImage.isLoadingPackageImage;

     //Enable Menu
     let activeMenu;
     const menulist = useSelector((state) => state.common.MenuList.item3);
     if (menulist !== undefined) { activeMenu = menulist.filter(i => i.menuName === "PackageImageList"); }
     // End Enable Menu
    useEffect(() => {
        if (!isLoadingPackageimage) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingPackageimage]);

    useEffect(() => {
        dispatch(retrievePackageImage(1, 0));
        const state1 = props.location.state;
        if (state1 !== undefined && state1 !== null) {
            let Msg;
            let Type = 'success'; // default, info, warning
            let title = 'Success!';
            if (state1.message === 'Edit') {
                Msg = 'Package Image Name updated successfully.';
            } else if (state1.message === 'Add') {
                Msg = 'Package Image Name added successfully.';
            } else {
                Msg = 'Error occured';
                Type = 'warning';
                title = 'Error!';
            }
            window.history.replaceState(null, '')
            Nodify(title, Type, Msg);
        }
        var page = '';
        var setConstraint = SearchRetain(page);
        if (setConstraint !== undefined) {
            setCurrentPage(setConstraint.CurrentPage);
            setITEMS_PER_PAGE(setConstraint.PageItems);
        }
    }, [dispatch]);

    const commentsData = useMemo(() => {
        let computedComments = comments;
        if (search) {
            computedComments = computedComments.filter(comment => comment.packingImageName.toLowerCase().includes(search.toLowerCase()));
        }
        setTotalItems(computedComments.length);
        // Sorting comments
        // if (sorting.field) {
        //     const reversed = sorting.order === "asc" ? 1 : -1;
        //     computedComments = computedComments.sort((a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]));
        // }
        if (sorting.field) {
            const reversed = sorting.order === 'asc' ? 1 : -1;
            computedComments = computedComments.sort((a, b) => {
              const valueA = a[sorting.field];
              const valueB = b[sorting.field];
          
              // Check if both values are numeric
              const isNumericA = !isNaN(valueA);
              const isNumericB = !isNaN(valueB);
          
              // Check if both values are valid date-time strings using moment
              const isDateTimeA = moment(valueA, moment.ISO_8601, true).isValid();
              const isDateTimeB = moment(valueB, moment.ISO_8601, true).isValid();
          
              if (isNumericA && isNumericB) {
                // If both values are numeric, compare as numbers
                return reversed * (parseFloat(valueA) - parseFloat(valueB));
              } else if (isDateTimeA && isDateTimeB) {
                // If both values are valid date-time strings, compare as dates
                return reversed * (moment(valueA).valueOf() - moment(valueB).valueOf());
              } else {
                // If one or both values are non-numeric and non-valid date-time strings, compare as strings
                return reversed * valueA.toString().localeCompare(valueB.toString());
              }
            });
          }
        // Current Page slice
        let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
        if (records.length === 0 && currentPage != 1) {
            setCurrentPage(currentPage - 1);
        }
        setTotalItems(computedComments.length);
        return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
    }, [search,
        comments,
        currentPage,
        sorting,
        ITEMS_PER_PAGE
    ]);

    const headers = [
        { name: "#", field: "", sortable: false },
        { name: "Package Image Name", field: "packingImageName", sortable: true },
        { name: "Created Date", field: "createdDate", sortable: true },
        { name: "Action", field: "", sortable: false, display: activeMenu && activeMenu[0].isEdit === 0 &&
        activeMenu[0].isDelete === 0 ? 'none' : '' },
    ];


    function pageRedirect(dataParams, redirect, page) {

        //localStorage.setItem('Pagination', JSON.stringify({ CurrentPage: currentPage, Page: page, PageItems: ITEMS_PER_PAGE }));
        if (redirect === 'Add') {
            props.history.push('/AddPackageimage', { params: [] });
        } else if (redirect === 'Edit') {
            props.history.push(`/EditPackageimage`, { params: dataParams });
        } else {
            let PackingParams = {
                Operation: 98,
                Id: dataParams.packageImageID,
            }
            CommonService.CheckMappedItem(PackingParams).then(res => {
                if (res.data.outputResult === "-2") {
                    let Title = "Warning!";
                    let message = "This Package Item is already mapped.";
                    let type = "warning";
                    Nodify(Title, type, message);
                }
                else {
                    setID({ showPopupDelete: true, Params: PackingParams });
                }
            });

        }
    }

    function Delcallback(value) {
        dispatch(retrievePackageImage(1, 0));
        setID({ showPopupDelete: false, Params: [] });
    }
    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className="row">
                    <div className="widget">
                        <div className="widget-header ">
                            <span className="widget-caption">Package Image Name List </span>
                        </div>
                        <div className="widget-body" style={{ paddingBottom: '4%' }}>
                            <div id="simpledatatable_wrapper" className="dataTables_wrapper form-inline no-footer">
                                <div className="table-toolbar pull-left">
                                    <Search placeholder={getPlaceholder}
                                        onSearch={
                                            value => {
                                                setSearch(value);
                                                setCurrentPage(1);
                                            }
                                        }
                                        props={props}
                                    />
                                </div>
                                <div className="table-toolbar pull-right"  style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}>

                                    &nbsp;
                                    <span className="btn btn-primary" onClick={() => pageRedirect('', 'Add')} title="Add Package Image Name">
                                        <i className="fa fa-plus"></i>&nbsp;Add
                                    </span>
                                </div>
                                <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                    <TableHeader headers={headers} onSorting={(field, order) => setSorting({ field, order })} />
                                    <tbody>

                                        {
                                            commentsData.map((comment,i) => (
                                                <Fragment key={i}>
                                                    <tr>
                                                        <td style={{width:'25px'}}>  
                                                            { i + 1}
                                                        </td>
                                                        <td>{
                                                            comment.packingImageName
                                                        }</td>
                                                        <td>{
                                                            comment.createdDate
                                                        }</td>
                                                        <td style={{
                                                                display: activeMenu && activeMenu[0].isEdit === 0 &&
                                                                    activeMenu[0].isDelete === 0 ? 'none' : ''
                                                            }}>
                                                            <span style={{ display: activeMenu && activeMenu[0].isEdit === 1 ? '' : 'none' }}  title='Edit PackageImage' onClick={() => pageRedirect(comment, 'Edit')}
                                                                className="btn btn-info btn-xs edit">
                                                                <i className="fa fa-edit"></i>
                                                            </span>
                                                            &nbsp;
                                                            <span  style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }} title='Delete PackageImage' onClick={() => pageRedirect(comment, '')}
                                                                className="btn btn-danger btn-xs delete">
                                                                <i className="fa fa-trash-o"></i>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </Fragment>
                                            ))
                                        }
                                        {
                                            totalItems === 0 ?
                                                <tr>
                                                    <td colSpan="5" className='norecordfound'><span>No record found</span></td>
                                                </tr> : ''
                                        }

                                    </tbody>
                                </table>
                                <PageCount onPageCount={
                                    value => {
                                        setITEMS_PER_PAGE(value);
                                        setCurrentPage(1);
                                    }
                                } />
                                <Pagination total={totalItems}
                                    itemsPerPage={
                                        parseInt(ITEMS_PER_PAGE)
                                    }
                                    currentPage={currentPage}
                                    onPageChange={
                                        page => setCurrentPage(page)
                                    } />
                            </div>
                        </div>
                        {
                            getID.showPopupDelete ? <SweetAlertPopup data={
                                getID.Params
                            }
                                deleteCallback={Delcallback}
                                showpopup={true}
                                pageActionId={PackageImageID}
                                Msg={"Package Image Name deleted successfully."} /> : null
                        }
                    </div>
                </div>
            </div>
            {loader}
        </>
    )
}

export default PackageImageList