import React, { Fragment, useState, useMemo, useEffect } from "react";
import Reactselect from 'react-select';
import TemplateService from "../../../services/Master/TemplateService";
import FinalinspectionService from "../../../services/FinalInspection/FinalinspectionService";
const Confimavailable = ({ props, getInspectionResult, confirmcallback, IsValidationCallback,conformdata }) => {

    const [getconformationInput, setconformationInput] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [submittedConformdata, setSubmittedConformdata] = useState(false);
    useEffect(() => {
        setSubmitted(IsValidationCallback);
    }, [IsValidationCallback === true]);
    useEffect(() => {

        setSubmittedConformdata(conformdata)
      }, [conformdata === true])
    const customStyles = {
        option: (styles, { data }) => {
            return {
                ...styles,
                color: data.value === 1 ? 'green' : data.value === 2 ? "Red" : "Blue"

            };
        }
    }
    let style = {
        control: styles => ({ ...styles, border: '1px solid red' }),
      }
    useEffect(() => {
        let AvadataEdit = [];
        if (props.location.state.params.TaskStatus === 2) {
            FinalinspectionService.GetTNAFinalinspecMainInfo(props.location.state.params.TNAInspectionID, props.location.state.params.details.tnaid).then((res) => {
                if (res.data.finalInsConfirmAvailList.length > 0) {
                    res.data.finalInsConfirmAvailList.map((x) => {
                        AvadataEdit.push({
                            Itemid: x.tnaFinalConfmAvailinfoID,
                            ItemName: x.details,
                            ResultID: x.result,
                            FindingsRemarks: x.findingsRemarks
                        })
                    })
                    setconformationInput(AvadataEdit);
                    confirmcallback(AvadataEdit)
                } else {
                    let Avadata = [];
                    TemplateService.GettemplateList(2, 0).then((response) => {
                        if (response.data) {
                            response.data.templateEdit2.filter(x => x.availableInfoStatus === 1).map((x) => {
                                Avadata.push({
                                    Itemid: 0,
                                    ItemName: x.itemName,
                                    ResultID: 0,
                                    ResultName: '',
                                    FindingsRemarks: ''
                                })
                            })
                            setconformationInput(Avadata);
                            confirmcallback(Avadata)
                        }
                    })
                }
            })


        }
    }, [props.location.state.params.TaskStatus === 2])
    const inpsectionId = JSON.parse(localStorage.getItem("InspectionID"));
    useEffect(() => {
        if (props.location.state.params.TaskStatus === 1) {
            (async () => {

                if (inpsectionId !== null) {
                    let AvadataEdit = [];
                    await FinalinspectionService.GetTNAFinalinspecMainInfo(inpsectionId, props.location.state.params.details.tnaid).then(async (res) => {
                        if (res.data.finalInsConfirmAvailList.length > 0) {
                            res.data.finalInsConfirmAvailList.map((x) => {
                                AvadataEdit.push({
                                    Itemid: x.tnaFinalConfmAvailinfoID,
                                    ItemName: x.details,
                                    ResultID: x.result,
                                    FindingsRemarks: x.findingsRemarks
                                })
                            })
                            setconformationInput(AvadataEdit);
                            confirmcallback(AvadataEdit)
                        } else {
                            let Avadata = [];
                            await TemplateService.GettemplateList(2, 0).then((response) => {
                                if (response.data) {
                                    response.data.templateEdit2.filter(x => x.availableInfoStatus === 1).map((x) => {
                                        Avadata.push({
                                            Itemid: 0,
                                            ItemName: x.itemName,
                                            ResultID: 0,
                                            ResultName: '',
                                            FindingsRemarks: ''
                                        })
                                    })

                                }
                            })
                            setconformationInput(Avadata);
                            confirmcallback(Avadata)
                        }

                    })
                } else {
                    if (inpsectionId === null) {
                        let Avadata = [];
                        TemplateService.GettemplateList(2, 0).then((response) => {
                            if (response.data) {
                                response.data.templateEdit2.filter(x => x.availableInfoStatus === 1).map((x) => {
                                    Avadata.push({
                                        Itemid: 0,
                                        ItemName: x.itemName,
                                        ResultID: 0,
                                        ResultName: '',
                                        FindingsRemarks: ''
                                    })
                                })
                                setconformationInput(Avadata);
                                confirmcallback(Avadata)
                            }
                        })
                    }
                }
            })();
        }
    }, [inpsectionId !== null])

    // useEffect(() => {
    //     if (props.location.state.params.TaskStatus === 1) {
    //         if (inpsectionId === null) {
    //             let Avadata = [];
    //             TemplateService.GettemplateList(2, 0).then((response) => {
    //                 if (response.data) {
    //                     response.data.templateEdit2.filter(x => x.availableInfoStatus === 1).map((x) => {
    //                         Avadata.push({
    //                             Itemid: 0,
    //                             ItemName: x.itemName,
    //                             ResultID: 0,
    //                             ResultName: '',
    //                             FindingsRemarks: ''
    //                         })
    //                     })
    //                     setconformationInput(Avadata);
    //                     confirmcallback(Avadata)
    //                 }
    //             })
    //         }
    //     }
    // }, [inpsectionId === null])

    let styles = {
        control: styles => ({ ...styles, border: '1px solid red', innerHeight: '100px', outerHeight: '100px' }),
    }
    const handleResultInputChange = (index, e, FeildName) => {

        const values = [...getconformationInput];
        let inputText = ""
        if (FeildName === "InspectionResult") {
            if (e !== null) {
                values[index].ResultID = e.value;
                values[index].ResultName = e.label;
            } else {
                values[index].ResultID = 0;
                values[index].ResultName = '';
            }
        } else {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            values[index].FindingsRemarks = inputText;
        }
        setconformationInput(values);
        confirmcallback(values)
    };

    return (
        <table className="table table-striped table-bordered table-hover dataTable no-footer">
            <thead>
                <tr>
                    <th className="InsMainInfoDetailsCol">Details</th>
                    <th className="InsMainInfoResultsCol">Confirmation of Available Information Result<span className="text-danger"> *</span></th>
                    <th className="InsMainInfoRemarksCol">Finding / Remarks<span className="text-danger"> *</span></th>
                    {/* <th>Action</th> */}
                </tr>
            </thead>
            <tbody>
                {
                    getconformationInput.map((comment, index) => (
                        <tr key={
                            comment.index
                        }>
                            <td title={comment.ItemName} className="cut_text"><b>{comment.ItemName}</b></td>
                            <td>

                                <Reactselect className="basic-single" name="InspectionResult"
                                    id={
                                        "InspectionResult" + index
                                    }
                                    isDisabled={false}
                                    isLoading={false}
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={(event) => handleResultInputChange(index, event, 'InspectionResult')}
                                    options={getInspectionResult}
                                    //styles={submitted && comment.ResultID === 0 ? (styles, customStyles) : customStyles}
                                    styles={submittedConformdata && comment.ResultID === 0 ? style : ''}

                                    value={getInspectionResult.filter(function (option) {
                                        return option.value === comment.ResultID;
                                    })}

                                ></Reactselect>

                            </td>
                            <td>
                                <input type="text" className="form-control" name={index + "findingsorremarks"}
                                    id={index + "findingsorremarks"}
                                    value={comment.FindingsRemarks}
                                    autoComplete="off"
                                    maxLength="200"
                                    //placeholder="Enter Findings / Remarks"
                                    disabled={props.location.state.params.Action === 'View' ? true : false}
                                    style={{ border: submitted && (comment.ResultID === 2 && comment.FindingsRemarks === '') ? '1px solid red' : '' }}
                                    onChange={event => handleResultInputChange(index, event, 'FindingsRemarks')} />




                                {/* <textarea className='form-control' value={comment.FindingsRemarks}  name="findingsorremarks" id="txtFindingsOrRemarks" rows="5" placeholder="Enter Findings / Remarks"
                                    autoComplete="off" maxLength="200"></textarea> */}
                            </td>
                            {/* <td>
                                <button type="button" className="btn btn-sm btn-primary" title="View"><i className="fa fa-eye" ></i></button>
                                &nbsp;
                                <button type="button" className="btn btn-sm btn-success" title="Add"><i className="fa fa-plus"></i></button>
                            </td> */}
                        </tr>
                    ))
                }
                {
                    getconformationInput.length === 0 ?
                        <tr>
                            <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
                        </tr> : ''
                }
            </tbody>
        </table>
    )
}

export default Confimavailable;