import axios from "axios";
import authHeader from "../auth-header";

class FabricService {
    LoadGroupTYpe() {
        return axios.get(window.$APIBaseURL + "Master/LoadGroupTypeList", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadFabricTypeList() {
        return axios.get(window.$APIBaseURL + "Master/LoadFabricTypeList", {
            headers: {
                authorization: authHeader()
            }
        });
    }


    GetFabricList(FabricID, FabricorYarn) {
        return axios.get(window.$APIBaseURL + "Master/GetFabricList?FabricID=" + FabricID + "&FabricorYarn=" + FabricorYarn, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateFabricGrid(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateFabricGrid',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }


    InsertUpdateFabric(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateFabric',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadFabricGroupTypeList() {
        return axios({
            method: 'get',
            url: window.$APIBaseURL + 'Master/FabricGroupTypeList',
            //  data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
    LoadMasterbrandWiseTypeList(Operation, BuyerID, BrandID) {
        return axios({
            method: 'get',
            url: window.$APIBaseURL + 'Master/BrandwisemasterDpList?Operation=' + Operation + '&BuyerID=' + BuyerID + '&BrandID=' + BrandID,
            //  data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

}
export default new FabricService();