import {
    GENERAL_TNA_REPORT_LOADING,
    GENERAL_TNA_REPORT_LIST,
    UPDATE_GENERAL_TNA_REPORT_IS_EXPANDED,
    UPDATE_GENERAL_TNA_REPORT_PARENT_IS_EXPANDED,
    CLEAR_GENERAL_TNA_REPORT_LIST
} from "../actions/types";

const initialState = {
    isLoadingGenTNA: true,
    genTNAReportList: [], //all resultset parent and drowpdown ,counbt
    //genTNAParentChildList: [],
    //genTNAChildList: [],
    genTNAReportparams: {}
};

const generalTNAReportReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GENERAL_TNA_REPORT_LOADING:
            return {
                ...state,
                isLoadingGenTNA: payload
            };

        case GENERAL_TNA_REPORT_LIST:
            let params = payload.params;
            let data = payload.data;
            let resultData = { ...state };
            if (params && params.Operation === 1) {
                resultData = { ...state, genTNAReportList: data, genTNAReportparams: params };
            } else if (params && params.Operation === 2) {
                let index = params.Index;
                let TNAReportList = { ...state.genTNAReportList };

                TNAReportList.genTNADetailList[index].genTNAParentChildList = data.genTNAParentChildList ? data.genTNAParentChildList : [];

                resultData = { ...state, genTNAReportList: TNAReportList, genTNAReportparams: params };
            } else if (params && params.Operation === 3) {
                let index = params.Index;
                let ChildIndex = params.ChildIndex;
                let TNAReportList = { ...state.genTNAReportList };
                let TNAParentChildList = TNAReportList.genTNADetailList[index].genTNAParentChildList;
                TNAParentChildList[ChildIndex].genTNAChildList = data.genTNAChildList ? data.genTNAChildList : [];

                TNAReportList.genTNADetailList[index].genTNAParentChildList = TNAParentChildList;

                resultData = { ...state, genTNAReportList: TNAReportList, genTNAReportparams: params };
            }
            return resultData;
        case UPDATE_GENERAL_TNA_REPORT_IS_EXPANDED:

            let expandedParams = payload;
            let TNAReportList = { ...state.genTNAReportList };
            let TNAParentChildList = TNAReportList.genTNADetailList[expandedParams.index].genTNAParentChildList;
            TNAParentChildList[expandedParams.childIndex].isExpanded = expandedParams.isExpanded;
            TNAReportList.genTNADetailList[expandedParams.index].genTNAParentChildList = TNAParentChildList;

            return {
                ...state, genTNAReportList: TNAReportList
            };

        case UPDATE_GENERAL_TNA_REPORT_PARENT_IS_EXPANDED:
            let expParams = payload;
            let ReportList = { ...state.genTNAReportList };
            ReportList.genTNADetailList[expParams.index].genTNAParentChildList = [];

            return {
                ...state, genTNAReportList: ReportList
            };
        case CLEAR_GENERAL_TNA_REPORT_LIST:
            let clearParams = payload;
            let ReportList1 = { ...state.genTNAReportList };
            return {
                ...state, genTNAReportList: [], genTNAReportparams: clearParams
            };
        default:
            return state;
    }
};
export default generalTNAReportReducer;