import React, { useState, useEffect, Fragment, Suspense } from "react";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import 'react-notifications-component/dist/theme.css';
import Nodify from "../Common/ReactNotification";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Reactselect from 'react-select';
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { TableHeader } from "../Datatable/index";
import TnaService from "../../services/TNA/TNAService";
import Draggable from 'react-draggable';
import ModalDialog from 'react-bootstrap/ModalDialog';
import axios from "axios";
import $ from "jquery";
import Lightbox from "react-image-lightbox";
import { InsertUpdateTNAProtoFitSmsSubmissionApproval } from "../../actions/tna";
import { useDispatch } from "react-redux";
import StrikeoffService from "../../services/Style/StrikeoffService";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import CommonService from "../../services/Common/CommonService";
import { ProtoFitSMSSubmissionApprovalId } from '../Common/PageActionNumber'
import SweetAlertPopup from "../Common/SweetAlertPopup";
import moment from "moment";
//import StrikeOff from "../quickMaster/StrikeOff";

const ProtoFitSMSSubmission_Order = (props) => {
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const [Buttonview, SetButtonview] = useState("Hide");
  const [submitted, setSubmitted] = useState(false);
  const [getHeader, setHeader] = useState("Add");
  const [getDisableSave, setDisableSave] = useState(false);    //new implement
  const [getImagename, setImagename] = useState();
  const [getFiles, setFiles] = useState([]);
  const [isOpenimg, setIsOpenimg] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [ShowImportPopup, setShowImportPopup] = useState(false);
  const [submittedpopup, setSubmittedpopup] = useState(false);
  const [submittedawb, setSubmittedawb] = useState(false);
  const [showawb, setshowawb] = useState(false);
  const [getGarmentpopup, SetGarmentpopup] = useState(false);
  const [getsizeqty, setsizeqty] = useState([]);
  const [getTaskholder, setTaskholder] = useState([]);
  const [IsFabricExpanded, setIsFabricExpanded] = useState(false);
  const [IsColorExpanded, setIsColorExpanded] = useState(false);
  const [IsStrikeoffExpanded, setIsStrikeoffExpanded] = useState(false);
  const [IsTrimsExpanded, setIsTrimsExpanded] = useState(false);
  const [IsMeasurementExpanded, setIsMeasurementExpanded] = useState(false);

  const [IsFabricSelected, setIsFabricSelected] = useState(false);
  const [IsColorSelected, setIsColorSelected] = useState(false);
  const [IsStrikeoffSelected, setIsStrikeoffSelected] = useState(false);
  const [IsTrimsSelected, setIsTrimsSelected] = useState(false);
  const [IsMeasurementSelected, setIsMeasurementSelected] = useState(false);
  const [FabricDropdown, setFabricDropdown] = useState([]);
  const [ColorDropdown, setColorDropdown] = useState([]);
  const [StrikeoffDropdown, setStrikeoffDropdown] = useState([]);
  const [TrimDropdown, setTrimDropdown] = useState([]);

  const [fabricSubmitted, setFabricSubmitted] = useState(false);
  const [trimSubmitted, setTrimsubmitted] = useState(false);
  const [strikeoffSubmitted, setStrikeoffSubmitted] = useState(false);
  const [colorSubmitted, setColorSubmitted] = useState(false);

  const [getSubImageList, setSubImageList] = useState([]);
  const [getCurrimageindex, setCurrimageindex] = useState(0);
  const [getmodalpopup, setmodalpopup] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const [chkDataExists, setchkDataExists] = useState(false);
  const [getID, setID] = useState({ showPopupDelete: false, Params: {} });
  const [EditedCommentIndex, setEditedCommentIndex] = useState('');
  const [getComment, setComment] = useState(false);
  const [getCommentIndex, setCommentIndex] = useState();
  let styles = {
    control: styles => ({ ...styles, border: '1px solid red' }),
  }

 
  let taskholder = props.getnewlist;
  let ChildTask = props.ChildTask;
  let ParentTask = props.ParentTask;
  let Operation = 0;
  let ViewOperation = 0;
  let OperationEdit = 0;
  let ViewOperationEdit = 0;
  let DynamicClassName = "col-lg-12 col-sm-12 col-xs-12";

  const [inputFields, setInputFields] = useState([{
    BuyerBrandSeasonName: '', SupplierName: '', StyleName: '', StyleNo: '', SkuName: '', IdpoNo: '',
    SentTo: '', SendToEdit: "", SendToDisplay: '', SendThrough: 0, AWBName: '', AWBDate: '', AWBDateandNo: '', SubmittedFor: '', SubmittedForEdit: '',
    SizeIdQtyIds: '', SizeandQty: '', SubmittedDate: '', ProtoFitSmsSubmissionId: 0, BuyerId: 0, BrandId: 0, SeasonId: 0, SupplierId: 0, SkuId: 0, TaskOwnerIdList: '',
    SkuImageList: '',
    Fabric: [{ fabricID: 0, protoFitSmsFabricId: 0, fabricDetailName: '', quality: 0, value: 0, label: '', fabricIndexName: '', fabricComments: [] }],
    Color: [{ pantoneID: 0, protoFitSmsColorId: 0, pantoneDetails: '', pantoneColorOrSwatchImage: '', quality: 0, value: 0, label: '', colorIndexName: '', colorComments: [] }],
    Strikeoff: [{ strikeOffLogoID: 0, protoFitSmsStrikeOffId: 0, stikeOffCodeandName: '', strikeoffLogoPath: '', quality: 0, value: 0, label: '', strikeoffIndexName: '', strikeoffComments: [] }],
    Trim: [{ trimLogoInfoID: 0, ProtoFitSmsTrimId: 0, trimCodeandName: '', trimLogoPath: '', quality: 0, value: 0, label: '', trimIndexName: '', trimComments: [] }],
    Measurement: { Files: [] }
  }]);

  const MainFields = [{
    Operation: 0,
    TNAId: props.TNAId,
    ProtoFitSmsSubmissionId: 0,
    BuyerId: 0,
    BrandId: 0,
    SeasonId: 0,
    SupplierId: 0,
    StyleId: 0,
    StyleName: '',
    StyleCode: '',
    SkuId: 0,
    IdPoNos: '',
    TaskOwnerIdList: '',
    SendThrough: 0,
    SentTo: 0,
    AWBName: '',
    AWBDate: '',
    SubmitFor: '',
    GarmentSizeandQty: '',
    ProtoFitSmsStatus: 0,
    SubmittedDate: '',
    ApprovedDate: '',
    TNAProtoFitSmsFabricInformation: [],
    TNAProtoFitSmsPantoneColorsInformation: [],
    TNAProtoFitSmsStrikeOffInformation: [],
    TNAProtoFitSmsTrimInformation: [],
    TNAProtoFitOtherComments: [],
    TNAProtoFitSmsFabricCommentsInformation: [],
    TNAProtoFitSmsPantoneColorCommentsInformation: [],
    TNAProtoFitSmsStrikeoffCommentsInformation: [],
    TNAProtoFitSmsTrimCommentsInformation: [],
    TNAProtoFitOrSmsId: props.TNAProtoFitId,
    TaskName: props.TNATaskName,
    OverAllResult: 0,
    CreatedBy: currentUser.employeeinformationID,
  }];

  const values = [...inputFields]
  const filevals = [...getFiles]


  let styles1 = {
    control: styles1 => ({ ...styles1, width: "350px" }),
  }

  let styles2 = {
    control: styles2 => ({ ...styles2, width: "350px", border: '1px solid red' }),
  }
  useEffect(() => {
    if (props.delteProtoFitSmsSubmission === true || props.editProtoFitSmsSubmission === true) {
      let Params = {
        Operation: 51,
        Id: props.TNAProtoFitId,
      };
      CommonService.CheckMappedItem(Params).then(res => {
        if (res.data.outputResult === "-2") {
          setchkDataExists(true);
          let Title = "Warning!";
          let message = "Approval exists against this Submission.";
          let type = "warning";
          Nodify(Title, type, message);
          props.ProtoFitSMSSubmissionCallback(false)

        }
        else {
          if (props.delteProtoFitSmsSubmission === true) {
            setID({ showPopupDelete: true, Params: props.TNAProtoFitId });
            // TnaService.ViewProtoFitSMSSubmissionApprovalList(props.TNAProtoFitId).then((response) => {
            //     // if (response.data) {
            //     //     setOrderinfo(response.data.trimSubmissionOrderInfoList);
            //     // }
            // }).catch(() => { hideLoader(); });

          }
          else {
            let getDateValue = new Date(values[0].SubmittedDate);
            var dd = getDateValue.getDate();
            var mm = getDateValue.getMonth() + 1;
            var yyyy = getDateValue.getFullYear();

            if (dd < 10) {
              dd = '0' + dd;
            }
            if (mm < 10) {
              mm = '0' + mm;
            }
            values[0].SubmittedDate = getDateValue;
            setInputFields(values);
          }
        }
      });
      if (props.editProtoFitSmsSubmission === true) {

        if (ParentTask === "Proto Sample Submission") {
          OperationEdit = 1;
          ViewOperationEdit = 1;

        }
        else if (ParentTask === "Proto Sample Approval") {
          OperationEdit = 2;
          ViewOperationEdit = 2;
        }
        else if (ParentTask === "Fit Sample Submission") {
          OperationEdit = 3;
          ViewOperationEdit = 3;
        }
        else if (ParentTask === "Fit Sample Approval") {
          OperationEdit = 4;
          ViewOperationEdit = 4;
        }
        else if (ParentTask === "SMS Submission"
          || ParentTask === "Size Set Sample Submission"
          || ParentTask === "Pre Production Sample Submission"
          || ParentTask === "Shipment Sample Submission"
          || ParentTask === "Photo Sample Submission") {
          OperationEdit = 5;
          ViewOperationEdit = 5;
        }
        else if (ParentTask === "SMS Approval"
          || ParentTask === "Size Set Sample Approval"
          || ParentTask === "Pre Production Sample Approval"
          || ParentTask === "Shipment Sample Approval"
          || ParentTask === "Photo Sample Approval") {
          OperationEdit = 6;
          ViewOperationEdit = 6;
        }
        let getDateValue = new Date(values[0].ApprovalDate);
        var dd = getDateValue.getDate();
        var mm = getDateValue.getMonth() + 1;
        var yyyy = getDateValue.getFullYear();

        if (dd < 10) {
          dd = '0' + dd;
        }
        if (mm < 10) {
          mm = '0' + mm;
        }
        values[0].ApprovalDate = yyyy + '-' + mm + '-' + dd;;
        TnaService.ViewProtoFitSMSSubmissionApprovalList(OperationEdit, props.TNAProtoFitId).then((response) => {

          values[0].Fabric = [];
          if (response.data.fabricDetails) {
            if (response.data.fabricDetails.length > 0) {
              response.data.fabricDetails.map((element, index) => {
                // values[0].Fabric[index].fabricComments = [];
                ;
                values[0].Fabric.push({
                  fabricID: element.fabricID,
                  fabricDetailName: element.fabricDetailName,
                  protoFitSmsFabricId: element.protoFitSmsFabricId,
                  quality: element.quality,
                  value: element.value,
                  label: element.label,
                  fabricIndexName: element.fabricIndexName,
                  fabricComments: response.data.fabricComments.length > 0 ? response.data.fabricComments : []

                })
              })
            }
            else {
              TnaService.ProtoFitSMSSubmissionList(OperationEdit, props.TNAProtoFitId).then((response) => {
                values[0].Fabric = response.data.fabricDetails;

                response.data.fabricDetails.forEach((element, index) => {
                  values[0].Fabric[index].fabricComments = [];
                  values[0].Fabric[index].EnterComment = '';
                  values[0].Fabric[index].fabricIndexName = (index).toString();

                })
                setFabricDropdown(response.data.fabricDetails);
              })
            }

          }
          values[0].Color = [];
          if (response.data.colorDetails) {
            if (response.data.colorDetails.length > 0) {
              response.data.colorDetails.map((element, index) => {
                // values[0].Fabric[index].fabricComments = [];
                values[0].Color.push({
                  pantoneID: element.pantoneID,
                  protoFitSmsColorId: element.protoFitSmsColorId,
                  pantoneDetails: element.pantoneDetails,
                  pantoneColorOrSwatchImage: element.pantoneColorOrSwatchImage,
                  quality: element.quality,
                  value: element.value,
                  label: element.label,
                  colorIndexName: element.colorIndexName,
                  colorComments: response.data.colorComments.length > 0 ? response.data.colorComments : []
                })
              })
            } else {
              TnaService.ProtoFitSMSSubmissionList(OperationEdit, props.TNAProtoFitId).then((response) => {
                values[0].Color = response.data.colorDetails;

                response.data.colorDetails.forEach((element, index) => {
                  values[0].Color[index].colorComments = [];
                  values[0].Color[index].EnterComment = '';
                  values[0].Color[index].colorIndexName = (index).toString();
                })
                setColorDropdown(response.data.colorDetails);
              })

            }

          }
          values[0].Strikeoff = [];
          if (response.data.strikeoffDetails) {
            if (response.data.strikeoffDetails.length > 0) {
              response.data.strikeoffDetails.map((element, index) => {
                // values[0].Fabric[index].fabricComments = [];
                values[0].Strikeoff.push({
                  strikeOffLogoID: element.strikeOffLogoID,
                  protoFitSmsStrikeOffId: element.protoFitSmsStrikeOffId,
                  stikeOffCodeandName: element.stikeOffCodeandName,
                  strikeoffLogoPath: element.strikeoffLogoPath,
                  quality: element.quality,
                  value: element.value,
                  label: element.label,
                  strikeoffIndexName: element.strikeoffIndexName,
                  strikeoffComments: response.data.strikeoffComments.length > 0 ? response.data.strikeoffComments : []
                })
              })
            } else {
              TnaService.ProtoFitSMSSubmissionList(OperationEdit, props.TNAProtoFitId).then((response) => {
                values[0].Strikeoff = response.data.strikeoffDetails;

                response.data.strikeoffDetails.forEach((element, index) => {
                  values[0].Strikeoff[index].strikeoffComments = [];
                  values[0].Strikeoff[index].EnterComment = '';
                  values[0].Strikeoff[index].strikeoffIndexName = (index).toString();
                })
                setStrikeoffDropdown(response.data.strikeoffDetails);
              })

            }

          }
          values[0].Trim = [];
          if (response.data.trimDetails) {
            if (response.data.trimDetails.length > 0) {
              response.data.trimDetails.map((element, index) => {
                // values[0].Fabric[index].fabricComments = [];
                values[0].Trim.push({
                  trimLogoInfoID: element.trimLogoInfoID,
                  ProtoFitSmsTrimId: element.protoFitSmsTrimId,
                  trimCodeandName: element.trimCodeandName,
                  trimLogoPath: element.trimLogoPath,
                  quality: element.quality,
                  value: element.value,
                  label: element.label,
                  trimIndexName: element.trimIndexName,
                  trimComments: response.data.trimComments.length > 0 ? response.data.trimComments : []
                })
              })
            } else {
              TnaService.ProtoFitSMSSubmissionList(OperationEdit, props.TNAProtoFitId).then((response) => {
                values[0].Trim = response.data.trimDetails;

                response.data.trimDetails.forEach((element, index) => {
                  values[0].Trim[index].trimComments = [];
                  values[0].Trim[index].EnterComment = '';
                  values[0].Trim[index].trimIndexName = (index).toString();
                })
                setTrimDropdown(response.data.trimDetails);
              })

            }

          }
          values[0].Measurement.Files = [];
          if (response.data.measurementFileInformation.length > 0) {
            response.data.measurementFileInformation.map((docs, i) => {
              values[0].Measurement.Files.push({
                FileID: docs.fileID,
                FileName: docs.fileName,
                FilePath: docs.filePath,
                FileType: docs.fileType
              })
            });
          }

          setFiles(values[0].Measurement.Files);
          setFabricDropdown(response.data.fabricDetails);
          setColorDropdown(response.data.colorDetails);
          setStrikeoffDropdown(response.data.strikeoffDetails);
          setTrimDropdown(response.data.trimDetails);
        })
        setInputFields(values);

      }
    }

    else {
      showLoader();

      if (ParentTask === "Proto Sample Submission") {
        Operation = 1;
        ViewOperation = 1;
      }
      else if (ParentTask === "Proto Sample Approval") {
        Operation = 2;
        ViewOperation = 2;
      }
      else if (ParentTask === "Fit Sample Submission") {
        Operation = 3;
        ViewOperation = 3;
      }
      else if (ParentTask === "Fit Sample Approval") {
        Operation = 4;
        ViewOperation = 4;
      }
      else if (ParentTask === "SMS Submission"
        || ParentTask === "Size Set Sample Submission"
        || ParentTask === "Pre Production Sample Submission"
        || ParentTask === "Shipment Sample Submission"
        || ParentTask === "Photo Sample Submission"
      ) {
        Operation = 5;
        ViewOperation = 5;
      }
      else if (ParentTask === "SMS Approval"
        || ParentTask === "Size Set Sample Approval"
        || ParentTask === "Pre Production Sample Approval"
        || ParentTask === "Shipment Sample Approval"
        || ParentTask === "Photo Sample Approval") {
        Operation = 6;
        ViewOperation = 6;
      }

      else {

      }

      StrikeoffService.LoadGroupSizeDetailsList(props.StyleId).then((response) => {

        if (response.data) {
          var Data = response.data;
          var intialsize = { label: "Any Size", value: 0 };
          //   getsizeqty[0].Sizename = "Any Size";
          Data.splice(0, 0, intialsize);
          //setSizeListmeasurement(Data);
          Data.forEach(element => {
            getsizeqty.push({ Size: element.value, Qty: '', Sizename: element.label })
          })
        }
      }).catch(() => { });


      TnaService.TaskHolderList(props.TNAId).then((response) => {
        if (response.data) {
          setTaskholder(response.data);
        }
      }).catch(() => { });

      if (props.TaskStatus !== 3) {
        TnaService.ProtoFitSMSSubmissionList(Operation, props.TNAProtoFitId).then((response) => {
          if (response.data) {

            values[0].BuyerId = response.data.protoFitSMSBasicDetails[0].buyerID;
            values[0].BrandId = response.data.protoFitSMSBasicDetails[0].brandID;
            values[0].SeasonId = response.data.protoFitSMSBasicDetails[0].seasonID;
            values[0].SupplierId = response.data.protoFitSMSBasicDetails[0].supplierID;
            values[0].SkuId = response.data.protoFitSMSBasicDetails[0].skuId;
            values[0].TaskOwnerIdList = response.data.protoFitSMSBasicDetails[0].taskOwnerIdList;


            values[0].SupplierName = response.data.protoFitSMSBasicDetails[0].supplierName;
            values[0].BuyerBrandSeasonName = response.data.protoFitSMSBasicDetails[0].buyerBrandSeasonName;
            values[0].StyleID = response.data.protoFitSMSBasicDetails[0].styleId;
            values[0].StyleName = response.data.protoFitSMSBasicDetails[0].styleName;
            values[0].StyleNo = response.data.protoFitSMSBasicDetails[0].styleNo;
            values[0].IdpoNo = response.data.protoFitSMSBasicDetails[0].idpoNo;
            values[0].SkuName = response.data.protoFitSMSBasicDetails[0].skuName;
            values[0].SizeandQty = response.data.protoFitSMSBasicDetails[0].sizeQtyNameList;
            values[0].SizeIdQtyIds = response.data.protoFitSMSBasicDetails[0].sizeQtyIDList;


            if (Operation !== 3) {
              let FabricSelected = [{ value: '1', label: 'Fabric' }];
              values[0].SubmittedForEdit = FabricSelected;
              values[0].SubmittedFor = "1";
              setIsFabricSelected(true);
              setIsFabricExpanded(true);
            }
            else {

            }

            let images = [];
            images = response.data.protoFitSMSBasicDetails[0].skuImageList.split(',');
            setSubImageList(images);
            setCurrimageindex(0);

            //    let fabricDetailSelected = [{ fabricID: 0, fabricDetailName: "Select Details", value: 0, label: 'Select Details', quality: 0, fabricComments:[],EnterComment:'',EnableComments:'' }];
            //     values[0].Fabric = [...fabricDetailSelected,...response.data.fabricDetails];

            values[0].Fabric = response.data.fabricDetails;

            response.data.fabricDetails.forEach((element, index) => {
              values[0].Fabric[index].fabricComments = [];
              values[0].Fabric[index].EnterComment = '';
              values[0].Fabric[index].fabricIndexName = (index).toString();

            })


            values[0].Color = response.data.colorDetails;

            response.data.colorDetails.forEach((element, index) => {
              values[0].Color[index].colorComments = [];
              values[0].Color[index].EnterComment = '';
              values[0].Color[index].colorIndexName = (index).toString();
            })
            values[0].Strikeoff = response.data.strikeoffDetails;

            response.data.strikeoffDetails.forEach((element, index) => {
              values[0].Strikeoff[index].strikeoffComments = [];
              values[0].Strikeoff[index].EnterComment = '';
              values[0].Strikeoff[index].strikeoffIndexName = (index).toString();
            })
            values[0].Trim = response.data.trimDetails;

            response.data.trimDetails.forEach((element, index) => {
              values[0].Trim[index].trimComments = [];
              values[0].Trim[index].EnterComment = '';
              values[0].Trim[index].trimIndexName = (index).toString();
            })


            setInputFields(values);
            setFabricDropdown(response.data.fabricDetails);
            setColorDropdown(response.data.colorDetails);
            setStrikeoffDropdown(response.data.strikeoffDetails);
            setTrimDropdown(response.data.trimDetails);
            hideLoader();
          }
        }).catch(() => { hideLoader(); });
      }
      else {
        TnaService.ViewProtoFitSMSSubmissionApprovalList(ViewOperation, props.TNAProtoFitId).then((response) => {//assign default value 1 furtherly update it
          ;
          if (response.data) {
            values[0].SupplierName = response.data.protoFitSMSBasicDetails[0].supplierName;
            values[0].BuyerBrandSeasonName = response.data.protoFitSMSBasicDetails[0].buyerBrandSeasonName;
            values[0].StyleID = response.data.protoFitSMSBasicDetails[0].styleId;
            values[0].StyleName = response.data.protoFitSMSBasicDetails[0].styleName;
            values[0].StyleNo = response.data.protoFitSMSBasicDetails[0].styleNo;
            values[0].IdpoNo = response.data.protoFitSMSBasicDetails[0].idpoNo;
            values[0].SkuName = response.data.protoFitSMSBasicDetails[0].skuName;
            values[0].SizeandQty = response.data.protoFitSMSBasicDetails[0].sizeQtyNameList;
            values[0].SizeIdQtyIds = response.data.protoFitSMSBasicDetails[0].sizeQtyIDList;
            values[0].SubmitSentDetails = response.data.protoFitSMSBasicDetails[0].sendThroughName + ' ' + response.data.protoFitSMSBasicDetails[0].awbName + ' ' + response.data.protoFitSMSBasicDetails[0].awbDate;
            //values[0].SubmittedDate = response.data.protoFitSMSBasicDetails[0].submittedDate;
            values[0].ProtoFitSmsSubmissionId = response.data.protoFitSMSBasicDetails[0].protoFitSmsSubmissionId;
            let getDateValue = new Date(response.data.protoFitSMSBasicDetails[0].submittedDate);
            // var dd = getDateValue.getDate();
            // var mm = getDateValue.getMonth() + 1;
            // var yyyy = getDateValue.getFullYear();

            // if (dd < 10) {
            //     dd = '0' + dd;
            // }
            // if (mm < 10) {
            //     mm = '0' + mm;
            // }
            // let coverted = mm + '/' + dd + '/' + yyyy;
            if (response.data.protoFitSMSBasicDetails[0].awbDate !== '') {

              let newvalue = new Date(response.data.protoFitSMSBasicDetails[0].awbDate);
              var dd = newvalue.getDate();
              var mm = newvalue.getMonth() + 1;
              var yyyy = newvalue.getFullYear();

              if (dd < 10) {
                dd = '0' + dd;
              }
              if (mm < 10) {
                mm = '0' + mm;
              }
              let coverted = dd + '/' + mm + '/' + yyyy;
              if (response.data.protoFitSMSBasicDetails[0].sendThrough === 2) {
                ;
                setshowawb(true);
                values[0].AWBDateandNo = response.data.protoFitSMSBasicDetails[0].awbName === '' ? '' : response.data.protoFitSMSBasicDetails[0].awbName + ' / ' + coverted;
                values[0].AWBDate = response.data.protoFitSMSBasicDetails[0].awbDate === "" ? "" : new Date(response.data.protoFitSMSBasicDetails[0].awbDate)
                values[0].AWBName = response.data.protoFitSMSBasicDetails[0].awbName;

              }
            }
            else {
              if (response.data.protoFitSMSBasicDetails[0].sendThrough === 2) {
                ;
                setshowawb(true);
                values[0].AWBDateandNo = '' + '  ' + '';
                values[0].AWBDate = response.data.protoFitSMSBasicDetails[0].awbDate === "" ? "" : new Date(response.data.protoFitSMSBasicDetails[0].awbDate)
                values[0].AWBName = response.data.protoFitSMSBasicDetails[0].awbName;

              }

            }

            values[0].SubmittedDate = getDateValue;
            values[0].SubmittedFor = response.data.protoFitSMSBasicDetails[0].submitFor;
            values[0].BuyerId = response.data.protoFitSMSBasicDetails[0].buyerID;
            values[0].BrandId = response.data.protoFitSMSBasicDetails[0].brandID;
            values[0].SeasonId = response.data.protoFitSMSBasicDetails[0].seasonID;
            values[0].SupplierId = response.data.protoFitSMSBasicDetails[0].supplierID;
            values[0].SkuId = response.data.protoFitSMSBasicDetails[0].skuId;
            values[0].TaskOwnerIdList = response.data.protoFitSMSBasicDetails[0].taskOwnerIdList;
            values[0].SendThrough = response.data.protoFitSMSBasicDetails[0].sendThrough;
            // values[0].AWBDate = response.data.protoFitSMSBasicDetails[0].awbDate !==''  ? new Date(response.data.protoFitSMSBasicDetails[0].awbDate) :'';
            // values[0].AWBName = response.data.protoFitSMSBasicDetails[0].awbName;
            values[0].TNAId = response.data.protoFitSMSBasicDetails[0].tnaId;
            values[0].TNAProtoFitOrSmsId = props.TNAProtoFitId;
            values[0].SentTo = response.data.protoFitSMSBasicDetails[0].sentTo;

            //values[0].AWBDateandNo = response.data.protoFitSMSBasicDetails[0].awbName + " /t " + response.data.protoFitSMSBasicDetails[0].awbDate;

            let images = [];
            images = response.data.protoFitSMSBasicDetails[0].skuImageList.split(',');
            setSubImageList(images);
            setCurrimageindex(0);



            // values[0].Fabric[0].fabricComments = [];
            // values[0].Color[0].colorComments = [];
            // values[0].Strikeoff[0].strikeoffComments = [];
            // values[0].Trim[0].trimComments = [];
            values[0].Fabric = [];
            if (response.data.fabricDetails.length > 0) {
              response.data.fabricDetails.map((element, index) => {
                // values[0].Fabric[index].fabricComments = [];
                ;
                values[0].Fabric.push({
                  fabricID: element.fabricID,
                  fabricDetailName: element.fabricDetailName,
                  protoFitSmsFabricId: element.protoFitSmsFabricId,
                  quality: element.quality,
                  value: element.value,
                  label: element.label,
                  fabricIndexName: element.fabricIndexName,
                  fabricComments: response.data.fabricComments.length > 0 ? response.data.fabricComments : []

                })



              })



            }
            values[0].Color = [];
            if (response.data.colorDetails.length > 0) {
              response.data.colorDetails.map((element, index) => {
                // values[0].Fabric[index].fabricComments = [];
                values[0].Color.push({
                  pantoneID: element.pantoneID,
                  protoFitSmsColorId: element.protoFitSmsColorId,
                  pantoneDetails: element.pantoneDetails,
                  pantoneColorOrSwatchImage: element.pantoneColorOrSwatchImage,
                  quality: element.quality,
                  value: element.value,
                  label: element.label,
                  colorIndexName: element.colorIndexName,
                  colorComments: response.data.colorComments.length > 0 ? response.data.colorComments : []
                })
              })

            }
            values[0].Strikeoff = [];
            if (response.data.strikeoffDetails.length > 0) {
              response.data.strikeoffDetails.map((element, index) => {
                // values[0].Fabric[index].fabricComments = [];
                values[0].Strikeoff.push({
                  strikeOffLogoID: element.strikeOffLogoID,
                  protoFitSmsStrikeOffId: element.protoFitSmsStrikeOffId,
                  stikeOffCodeandName: element.stikeOffCodeandName,
                  strikeoffLogoPath: element.strikeoffLogoPath,
                  quality: element.quality,
                  value: element.value,
                  label: element.label,
                  strikeoffIndexName: element.strikeoffIndexName,
                  strikeoffComments: response.data.strikeoffComments.length > 0 ? response.data.strikeoffComments : []
                })
              })

            }
            values[0].Trim = [];
            if (response.data.trimDetails.length > 0) {
              response.data.trimDetails.map((element, index) => {
                // values[0].Fabric[index].fabricComments = [];
                values[0].Trim.push({
                  trimLogoInfoID: element.trimLogoInfoID,
                  ProtoFitSmsTrimId: element.protoFitSmsTrimId,
                  trimCodeandName: element.trimCodeandName,
                  trimLogoPath: element.trimLogoPath,
                  quality: element.quality,
                  value: element.value,
                  label: element.label,
                  trimIndexName: element.trimIndexName,
                  trimComments: response.data.trimComments.length > 0 ? response.data.trimComments : []
                })
              })

            }
            values[0].Measurement.Files = [];
            if (response.data.measurementFileInformation.length > 0) {
              response.data.measurementFileInformation.map((docs, i) => {
                values[0].Measurement.Files.push({
                  FileID: docs.fileID,
                  FileName: docs.fileName,
                  FilePath: docs.filePath,
                  FileType: docs.fileType
                })
              });
            }

            setFiles(values[0].Measurement.Files);


            let arr = response.data.protoFitSMSBasicDetails[0].submitFor.split(',');
            let arrVal = [];
            for (let size of arr) {
              let res = Submittedforoptions.filter(x => x.value === size);
              arrVal.push({
                value: res[0].value,
                label: res[0].label
              });
            }
            values[0].SubmittedForEdit = arrVal;



           

            let arr1 = response.data.protoFitSMSBasicDetails[0].sentTo.split(',');
            let arrVal1 = [];

            for (let size1 of arr1) {
              let res = taskholder.filter(x => x.id.toString() === size1);
              arrVal1.push({
                value: res[0].id,
                label: res[0].label
              });
            }


            values[0].SendToEdit = arrVal1;
            setInputFields(values);
            setFabricDropdown(response.data.fabricDetails);
            setColorDropdown(response.data.colorDetails);
            setStrikeoffDropdown(response.data.strikeoffDetails);
            setTrimDropdown(response.data.trimDetails);
            ExpandAll();
            hideLoader();
          }
        }).catch(() => { hideLoader(); });
      }
    }

  }, [props.delteProtoFitSmsSubmission === true || props.editProtoFitSmsSubmission === true]);


  // new Implement
  const headers = [
    { name: "Details", field: "StyleName", sortable: false },
    { name: "Quality", field: "StyleNo", sortable: false },
    { name: "Action", field: "", sortable: false },
  ];


  const previmage = () => {
    if (getCurrimageindex === 0) {
      setCurrimageindex(0);
    }
    else {
      setCurrimageindex(getCurrimageindex - 1);
    }
  }

  const nextimage = () => {
    if (getCurrimageindex === getSubImageList.length - 1) {
      setCurrimageindex(getSubImageList.length - 1);
    }
    else {
      setCurrimageindex(getCurrimageindex + 1);
    }
  }


  const modalPopupClose = () => {
    setShowImportPopup(false);
  }
  const SaveAwbnoDate = () => {
    // var isvalid = true;
    // if (values[0].AWBName === '' || values[0].AWBDate === '') {
    //     isvalid = false;
    // }

    // if (isvalid) {
    //     values[0].AWBDateandNo = values[0].AWBName + " / " + values[0].AWBDate;
    //     setInputFields(values);
    //     setShowImportPopup(false);
    // }
    // else {
    //     Nodify('Warning!', 'warning', 'Please fill mandatory (*) fields in AWB Popup.');
    //     setSubmittedpopup(true);
    // }
    let values = [...inputFields]

    if (values[0].AWBName === "" || values[0].AWBDate === "" || values[0].AWBDate === null) {
      values[0].AWBDateandNo = values[0].AWBName + values[0].AWBDate;
      setShowImportPopup(false);
    }
    else {
      values[0].AWBDateandNo = values[0].AWBName + " / " + values[0].AWBDate.toLocaleDateString('en-GB');
      setShowImportPopup(false);
      //setShowImportPopup(false);
    }
    setInputFields(values);

  }



  const handlSendAdd = (event, Field) => {
   
    if (Field === 'SentTo') {
      if (event.length === 0) {
        values[0].SendToEdit = "";
        values[0].SentTo = '';
      } else {
        let strValues = "";
        for (let Sku of event) {
          if (strValues === "") {
            strValues = Sku.value.toString();
          } else {
            strValues = strValues + "," + Sku.value;
          }
        }
        values[0].SentTo = strValues;
        values[0].SendToEdit = event;
      }

    }
    setInputFields(values);

  }




  const handleChange = (event, feild) => {

    // const data = [...inputFields];

    if (feild === "SubmittedDate") {
      values[0].SubmittedDate = event;
    }
    else if (feild === "Sendthrough") {
      let input = '';
      if (event.target.value.trim() !== '') {
        input = event.target.value;
      }
      values[0].SendThrough = parseInt(input);
      if (input === "2") {
        setshowawb(true);
      }
      else if (input === "1") {
        values[0].AWBDateandNo = ''
        values[0].AWBDate = ''
        values[0].AWBName = ''
        setSubmittedawb(false)
        setshowawb(false);
      }

      else {
        setshowawb(false);
      }
    }
    else if (feild === "SentTo") {
      let input = '';
      if (event.target.value !== '') {
        input = event.target.value;
      }
      values[0].SentTo = input;
    }


    setInputFields(values);
  }

  // const handleChangeDetails = (event, index, submitfor, feild) => {
  //     if (event != null) {
  //         // let Isvalid = true;
  //         // let inputID = event.target.value === undefined ? 0 : event.target.value;

  //         // let valid = values[0].Fabric.find(event => event.fabricID === parseInt(inputID));
  //         // if (valid) {

  //         //     Isvalid = false;
  //         // }

  //         // // values[0].Fabric[index].fabricID = event.target.value;
  //         // if (Isvalid) {
  //         let input = event.target.value;

  //         if (submitfor === "Fabric") {
  //             if (feild === "Details") {
  //                 values[0].Fabric[index].fabricID = input;
  //             }
  //         }
  //         else if (submitfor === "Color") {
  //             if (feild === "Details") {
  //                 values[0].Color[index].pantoneID = input;
  //             }
  //         }
  //         else if (submitfor === "Strikeoff") {
  //             if (feild === "Details") {
  //                 values[0].Strikeoff[index].strikeOffLogoID = input;
  //             }
  //         }
  //         else if (submitfor === "Trim") {
  //             if (feild === "Details") {
  //                 values[0].Trim[index].trimLogoInfoID = input;
  //             }
  //         }


  //         if (submitfor === "Fabric") {
  //             if (feild === "Quality") {
  //                 values[0].Fabric[index].quality = input;
  //             }
  //         }
  //         else if (submitfor === "Color") {
  //             if (feild === "Quality") {
  //                 values[0].Color[index].quality = input;
  //             }
  //         }
  //         else if (submitfor === "Strikeoff") {
  //             if (feild === "Quality") {
  //                 values[0].Strikeoff[index].quality = input;
  //             }
  //         }
  //         else if (submitfor === "Trim") {
  //             if (feild === "Quality") {
  //                 values[0].Trim[index].quality = input;
  //             }
  //         }
  //         //  }
  //         // else {
  //         //     values[0].Fabric[index].fabricID = "";
  //         //     setInputFields(values);
  //         //     Nodify('Warning!', 'warning', 'This FabricCategory is already exist.');
  //         //     // setSubmitted(true);
  //         //     return false;
  //         // }
  //     }
  //     // else {
  //     //     values[0].Fabric[index].fabricID = "";

  //     // }
  //     setInputFields(values);
  // }

  const handleChangeDetails = (event, index, submitfor, feild) => {
    let Isvalid = true;
    if (event != null) {
      let input = event.target.value;

      if (submitfor === "Fabric") {
        if (feild === "Details") {
          //
          let valid = !!values[0].Fabric && values[0].Fabric.find(event => event.fabricID === parseInt(input));
          if (valid) {
            Nodify('Warning!', 'warning', 'This Fabric Category is already exist.');
            values[0].Fabric[index].fabricID = "0";
          } else {
            values[0].Fabric[index].fabricID = parseInt(input);
          }

        }
      }

      else if (submitfor === "Color") {

        let Colors = ColorDropdown.filter(x => x.pantoneID === parseInt(input))
        if (feild === "Details") {
          let valid = !!values[0].Color && values[0].Color.find(event => event.pantoneID === parseInt(input));

          if (valid) {
            Nodify('Warning!', 'warning', 'This Color Category is already exist.');
            values[0].Color[index].pantoneID = "0";
          } else {
            values[0].Color[index].pantoneID = parseInt(input);
            if (Colors.length > 0) {
              values[0].Color[index].pantoneColorOrSwatchImage = Colors[0].pantoneColorOrSwatchImage;
            }
          }

        }
      }


      else if (submitfor === "Strikeoff") {

        let Strikeoff = StrikeoffDropdown.filter(x => x.strikeOffLogoID === parseInt(input));
        if (feild === "Details") {
          let valid = !!values[0].Strikeoff && values[0].Strikeoff.find(event => event.strikeOffLogoID === parseInt(input));

          if (valid) {
            Nodify('Warning!', 'warning', 'This Color Category is already exist.');
            values[0].Strikeoff[index].strikeOffLogoID = "0";
          }
          else {
            values[0].Strikeoff[index].strikeOffLogoID = parseInt(input);
            if (Strikeoff.length > 0) {
              values[0].Strikeoff[index].strikeoffLogoPath = Strikeoff[0].strikeoffLogoPath
            }
          }
        }
      }
      else if (submitfor === "Trim") {

        let trim = TrimDropdown.filter(x => x.trimLogoInfoID === parseInt(input))
        if (feild === "Details") {
          let valid = !!values[0].Trim && values[0].Trim.find(event => event.trimLogoInfoID === parseInt(input));
          if (valid) {
            Nodify('Warning!', 'warning', 'This Color Category is already exist.');
            values[0].Trim[index].trimLogoInfoID = "0";

          }
          else {
            values[0].Trim[index].trimLogoInfoID = parseInt(input);
            if (trim.length > 0) {
              values[0].Trim[index].trimLogoPath = trim[0].trimLogoPath
            }
          }
        }
      }

      if (submitfor === "Fabric") {
        if (feild === "Quality") {
          values[0].Fabric[index].quality = input;
        }
      }
      else if (submitfor === "Color") {
        if (feild === "Quality") {
          values[0].Color[index].quality = input;
        }
      }
      else if (submitfor === "Strikeoff") {
        if (feild === "Quality") {
          values[0].Strikeoff[index].quality = input;
        }
      }
      else if (submitfor === "Trim") {
        if (feild === "Quality") {
          values[0].Trim[index].quality = input;
        }
      }
      //  }
      // else {
      //     values[0].Fabric[index].fabricID = "";
      //     setInputFields(values);
      //     Nodify('Warning!', 'warning', 'This FabricCategory is already exist.');
      //     // setSubmitted(true);
      //     return false;
      // }
    }
    // else {
    //     values[0].Fabric[index].fabricID = "";

    // }
    setInputFields(values);
  }
  const openStrikeoffpopup = () => {
    ;
    // setInputFields(values)
    setShowImportPopup(true);
  }
  const GarmentPopoup = () => {
    SetGarmentpopup(false);
  }

  //for Tablefeilds
  const AddFields = (index, Submitfor) => {
    var isvalid = true;
    // let Isvalid = true;

    if (isvalid) {

      if (Submitfor === "Fabric") {

        values[0].Fabric.forEach((element, index) => {

          if (element.fabricDetailName === null) {
            isvalid = false;

          }
        })
        if (isvalid) {
          values[0].Fabric.push({
            fabricID: 0, fabricDetailName: '', quality: 0, value: 0,
            label: '', fabricComments: [], EnterComment: '', EnableComments: ''
          });
          setInputFields(values);
          setFabricSubmitted(false);
        }
        else {
          Nodify('Warning!', 'warning', 'Please fill current row.');
          setFabricSubmitted(true);
        }
      }
      else if (Submitfor === "Color") {
        values[0].Color.forEach((element, index) => {
          if (element.pantoneDetails === null) {
            isvalid = false;
          }
        })
        if (isvalid) {
          values[0].Color.push({
            pantoneID: 0, pantoneDetails: '', pantoneColorOrSwatchImage: '',
            quality: 0, value: 0, label: '', colorComments: [], EnterComment: '', EnableComments: ''
          });
          setInputFields(values);
          setColorSubmitted(false);
        }
        else {
          Nodify('Warning!', 'warning', 'Please fill current row.');
          setColorSubmitted(true);
        }
      }
      else if (Submitfor === "Strikeoff") {

        values[0].Strikeoff.forEach((element, index) => {
          if (element.stikeOffCodeandName === null) {
            isvalid = false;
          }
        })
        if (isvalid) {
          values[0].Strikeoff.push({
            strikeOffLogoID: 0, stikeOffCodeandName: '', strikeoffLogoPath: '',
            quality: 0, value: 0, label: '', strikeoffComments: [], EnterComment: '', EnableComments: ''
          });
          setInputFields(values);
          setStrikeoffSubmitted(false);
        }
        else {
          Nodify('Warning!', 'warning', 'Please fill current row.');
          setStrikeoffSubmitted(true);
        }
      }
      else if (Submitfor === "Trim") {
        values[0].Trim.forEach((element, index) => {
          if (element.trimCodeandName === null) {
            isvalid = false;
          }
        })
        if (isvalid) {
          values[0].Trim.push({ trimLogoInfoID: 0, trimCodeandName: '', trimLogoPath: '', quality: 0, value: 0, label: '', trimComments: [], EnterComment: '', EnableComments: '' });
          setInputFields(values);
          setTrimsubmitted(false);
        }
        else {
          Nodify('Warning!', 'warning', 'Please fill current row.');
          setTrimsubmitted(true);
        }
      }

      setInputFields(values);
    }
  }


  const RemoveFields = (index, Submitfor) => {
    ;
    let fabricdd = [...FabricDropdown];
    let colordd = [...ColorDropdown];
    let strikeoffdd = [...StrikeoffDropdown];
    let trimdd = [...TrimDropdown];

    if (Submitfor === "Fabric") {
      values[0].Fabric.splice(index, 1);
    }
    else if (Submitfor === "Color") {
      values[0].Color.splice(index, 1);
    }
    else if (Submitfor === "Strikeoff") {
      values[0].Strikeoff.splice(index, 1);
    }
    else if (Submitfor === "Trim") {
      values[0].Trim.splice(index, 1);
    }

    setFabricDropdown(fabricdd);
    setColorDropdown(colordd);
    setStrikeoffDropdown(strikeoffdd);
    setTrimDropdown(trimdd);
    setInputFields(values);

  }

  const handlepopupchange = (event, feild) => {
    const values = [...inputFields]

    if (feild === "AWBName") {
      let input = '';
      if (event.target.value !== '') {
        input = event.target.value;
      }
      values[0].AWBName = input;
    }
    else if (feild === "AWBDate") {
      values[0].AWBDate = event;
    }
    setInputFields(values);
  }

  const handleSizeQtyChange = (index, event, Feildname) => {

    const sizeqty = [...getsizeqty]

    if (Feildname === "SizeId") {
      if (event) {
        sizeqty[index].Size = event.value;
        sizeqty[index].Sizename = event.label;
      }
      else {
        sizeqty[index].Size = "";
        sizeqty[index].Sizename = "";
      }

    } else {

      sizeqty[index].Qty = event.target.value.replace(/\D/g, '');
    }

    setSubmittedpopup(false);
    setsizeqty(sizeqty);
    setmodalpopup(true);
  }
  //orderInformation
  function RestrictFirstZero(e) {
    ;
    if (e.target.value.length == 0 && e.which == 48) {
      e.preventDefault();
      return false;
    }
  };


  const Submittedforoptions = [
    { value: '1', label: 'Fabric' },
    { value: '2', label: 'Color' },
    { value: '3', label: 'Strikeoff' },
    { value: '4', label: 'Trims' },
    { value: '5', label: 'Measurement' }
  ]

  const handlemultidd = (event) => {
    if (event.length === 0) {
      values[0].SubmittedForEdit = "";
    } else {
      let strValue = "";
      for (let Sku of event) {
        if (strValue === "") {
          strValue = Sku.value.toString();
        } else {
          strValue = strValue + "," + Sku.value;
        }
      }
      values[0].SubmittedFor = strValue;
      values[0].SubmittedForEdit = event;
    }

    setInputFields(values);
    setIsFabricSelected(false);
    setIsFabricExpanded(false);
    setIsColorSelected(false);
    setIsColorExpanded(false);
    setIsStrikeoffSelected(false);
    setIsStrikeoffExpanded(false);
    setIsTrimsSelected(false);
    setIsTrimsExpanded(false);
    setIsMeasurementSelected(false);
    setIsMeasurementExpanded(false);
    if (event.length > 0) {
      event.forEach(element => {
        if (element.label === "Fabric") {

          setIsFabricSelected(true);
          setIsFabricExpanded(true);
        }

        if (element.label === "Color") {
          setIsColorSelected(true);
          setIsColorExpanded(true);
        }


        if (element.label === "Strikeoff") {
          setIsStrikeoffSelected(true);
          setIsStrikeoffExpanded(true);
        }


        if (element.label === "Trims") {
          setIsTrimsSelected(true);
          setIsTrimsExpanded(true);
        }
        if (element.label === "Measurement")
          setIsMeasurementSelected(true);
        setIsMeasurementExpanded(true);
      });

    }
    else {
      setIsFabricSelected(false);
      setIsFabricExpanded(false);
      setIsColorSelected(false);
      setIsColorExpanded(false);
      setIsStrikeoffSelected(false);
      setIsStrikeoffExpanded(false);
      setIsTrimsSelected(false);
      setIsTrimsExpanded(false);
      setIsMeasurementSelected(false);
      setIsMeasurementExpanded(false);
    }


  }
  const ExpandAll = (event) => {
    // const Sample = [...SubmittedforDetails]

    if (values[0].SubmittedForEdit !== "") {
      values[0].SubmittedForEdit.forEach(element => {
        if (element.label === "Fabric") {
          setIsFabricSelected(true);
          setIsFabricExpanded(true);
        }
        if (element.label === "Color") {
          setIsColorSelected(true);
          setIsColorExpanded(true);
        }

        if (element.label === "Strikeoff") {
          setIsStrikeoffSelected(true);
          setIsStrikeoffExpanded(true);
        }

        if (element.label === "Trims") {
          setIsTrimsSelected(true);
          setIsTrimsExpanded(true);
        }
        if (element.label === "Measurement") {
          setIsMeasurementSelected(true);
          setIsMeasurementExpanded(true);
        }
      });

    }
    else {
      Nodify("Warning!", "warning", "Please select any one Dropdown list in submit for field.");
      // setSubmittedpopup(true);
    }
    // Sample.push({ Details: 0, Quality: 0, SubmitFor: "Fabric" });
    // SetsubmittedforS
  }

  const CollapseAll = () => {
    // setIsFabricSelected(false);
    setIsFabricExpanded(false);
    //  setIsColorSelected(false);
    setIsColorExpanded(false);
    //   setIsStrikeoffSelected(false);
    setIsStrikeoffExpanded(false);
    //  setIsTrimsSelected(false);
    setIsTrimsExpanded(false);
    //   setIsMeasurementSelected(false);
    setIsMeasurementExpanded(false);

  }
  const handleExpandRow = (submitfor) => {
    if (submitfor === "Fabric") {
      if (IsFabricExpanded === true) {
        setIsFabricExpanded(false);
      }
      else {
        setIsFabricExpanded(true);
      }
    }
    if (submitfor === "Color") {
      if (IsColorExpanded === true) {
        setIsColorExpanded(false);
      }
      else {
        setIsColorExpanded(true);
      }
    }
    if (submitfor === "Strikeoff") {
      if (IsStrikeoffExpanded === true) {
        setIsStrikeoffExpanded(false);
      }
      else {
        setIsStrikeoffExpanded(true);
      }
    }
    if (submitfor === "Trims") {
      if (IsTrimsExpanded === true) {
        setIsTrimsExpanded(false);
      }
      else {
        setIsTrimsExpanded(true);
      }
    }
    if (submitfor === "Measurement") {
      if (IsMeasurementExpanded === true) {
        setIsMeasurementExpanded(false);
      }
      else {
        setIsMeasurementExpanded(true);
      }
    }

  }

  const Comments = (index, Submitfordata) => {
    ;
    let Valueindex = ''
    setComment(false);
    if (Submitfordata === "Fabric") {
      values[0].Fabric[index].EnableComments = 1;
      Valueindex = index.toString()
    }
    else if (Submitfordata === "Color") {
      values[0].Color[index].EnableComments = 1;
      Valueindex = index.toString()
    }
    else if (Submitfordata === "Strikeoff") {
      values[0].Strikeoff[index].EnableComments = 1;
      Valueindex = index.toString()
    }
    else if (Submitfordata === "Trim") {
      values[0].Trim[index].EnableComments = 1;
      Valueindex = index.toString()
    }
    setInputFields(values);
    setCommentIndex(Valueindex)
  }

  const RemoveFiles = async (e, index) => {
    ;
    filevals.splice(index, 1);
    setFiles(filevals);
    values[0].Measurement.Files = filevals;
    setInputFields(values);
  }

  const ViewFile = (event, filename) => { // event.preventDefault();
    setIsOpenimg(true);
    setImagename(filename);
  }

  const AppendFiles = async e => {

    const file = e.target.files[0];
    if (file !== null && file !== '' && file !== undefined) {
      const fileType = file['type'];
      const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
      if (!validImageTypes.includes(fileType)) {
        Nodify('Warning!', 'warning', 'Invalid file format selected');
        $('#FileUpload').val("");
      }
      else {
        const formData = new FormData();
        formData.append("FormFile", file);
        formData.append("FileName", file.name);
        formData.append("Page", "TNA");
        try {
          const res = await axios.post(window.$APIBaseURL + "api/file", formData);
          filevals.push({
            FileID: 0,
            FileName: file.name,
            FilePath: "Images/TNAImages/" + file.name,
            FileType: file.type
          })
          values[0].Measurement.Files = filevals;
          setInputFields(values);
          setFiles(filevals);
          $('#FileUpload').val("");

        } catch (ex) {
          console.log(ex);
        }
      }
    }
  }

  const handleSaveComment = (index, assignValue, SubmitFor) => {
    ;
    let IsValid = true;
    if (assignValue === '' || assignValue === undefined) {
      setComment(true); IsValid = false;
      Nodify('Warning!', 'warning', "Please enter comments");
    }
    if (IsValid) {
      if (SubmitFor === "Fabric") {
        if (values[0].Fabric[index].EnterComment === '') {
          // Nodify('Warning!', 'warning', 'Invalid file format selected');
          setComment(true);
        }
        else {

          if (values[0].Fabric[index].fabricComments && values[0].Fabric[index].fabricComments.length !== 0) {
            if (values[0].Fabric[index].fabricComments[0].comments !== '') {
              if (EditedCommentIndex !== '') {
                values[0].Fabric[index].fabricComments[EditedCommentIndex].comments = assignValue;
              }
              else {
                values[0].Fabric[index].fabricComments.push({
                  protoFitSmsFabricCommentId: 0, comments: assignValue,
                  fabricIndexName: (index).toString(),
                  commentDate: props.props.TaskStatus === 1 || props.editProtoFitSmsSubmission === false ? new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                    + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) :
                    new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                    + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                });
              }
            }
            else {
              values[0].Fabric[index].fabricComments[0] = {
                protoFitSmsFabricCommentId: 0, comments: assignValue,
                fabricIndexName: (index).toString(),
                commentDate: props.TaskStatus === 1 || props.editProtoFitSmsSubmission === false ? new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                  + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) :
                  new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                  + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
              };
            }
          }
          else {
            values[0].Fabric[index].fabricComments.push({
              protoFitSmsFabricCommentId: 0, comments: assignValue,
              fabricIndexName: (index).toString(),
              commentDate: props.TaskStatus === 1 || props.editProtoFitSmsSubmission === false ? new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) :
                new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
            });
          }
        }

        setComment(true);
        values[0].Fabric[index].EnterComment = '';
        values[0].Fabric[index].EnableComments = 0;
      }

      if (SubmitFor === "Color") {
        if (values[0].Color[index].EnterComment === '') {
          // Nodify('Warning!', 'warning', 'Invalid file format selected');
          setComment(true);
        } else {
          if (values[0].Color[index].colorComments.length !== 0) {
            if (values[0].Color[index].colorComments[0].comments !== '') {
              if (EditedCommentIndex !== '') {
                values[0].Color[index].colorComments[EditedCommentIndex].comments = assignValue;
              }
              else {
                values[0].Color[index].colorComments.push({
                  protoFitSmsColorCommentId: 0, comments: assignValue,
                  colorIndexName: (index).toString(),
                  commentDate: props.TaskStatus === 1 || props.editProtoFitSmsSubmission === false ? new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                    + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) :
                    new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                    + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                });
              }
            }
            else {
              values[0].Color[index].colorComments[0] = {
                protoFitSmsColorCommentId: 0, comments: assignValue,
                colorIndexName: (index).toString(),
                commentDate: props.TaskStatus === 1 || props.editProtoFitSmsSubmission === false ? new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                  + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) :
                  new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                  + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
              };
            }
          }
          else {
            values[0].Color[index].colorComments.push({
              protoFitSmsColorCommentId: 0, comments: assignValue,
              colorIndexName: (index).toString(),
              commentDate: props.TaskStatus === 1 || props.editProtoFitSmsSubmission === false ? new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) :
                new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })

            });
          }
        }
        setComment(true);
        values[0].Color[index].EnterComment = '';
        values[0].Color[index].EnableComments = 0;
      }
      if (SubmitFor === "Strikeoff") {
        if (values[0].Strikeoff[index].EnterComment === '') {
          // Nodify('Warning!', 'warning', 'Invalid file format selected');
          setComment(true);
        } else {
          if (values[0].Strikeoff[index].strikeoffComments.length !== 0) {
            if (values[0].Strikeoff[index].strikeoffComments[0].comments !== '') {
              if (EditedCommentIndex !== '') {
                values[0].Strikeoff[index].strikeoffComments[EditedCommentIndex].comments = assignValue;
              }
              else {
                values[0].Strikeoff[index].strikeoffComments.push({
                  protoFitSmsStrikeoffCommentId: 0, comments: assignValue,
                  strikeoffIndexName: (index).toString(),
                  commentDate: props.TaskStatus === 1 || props.editProtoFitSmsSubmission === false ? new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                    + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) :
                    new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                    + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                });
              }
            }
            else {
              values[0].Strikeoff[index].strikeoffComments[0] = {
                protoFitSmsStrikeoffCommentId: 0, comments: assignValue,
                strikeoffIndexName: (index).toString(),
                commentDate: props.TaskStatus === 1 || props.editProtoFitSmsSubmission === false ? new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                  + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) :
                  new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                  + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
              };
            }
          }
          else {
            values[0].Strikeoff[index].strikeoffComments.push({
              protoFitSmsStrikeoffCommentId: 0, comments: assignValue,
              strikeoffIndexName: (index).toString(),
              commentDate: props.TaskStatus === 1 || props.editProtoFitSmsSubmission === false ? new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) :
                new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })

            });
          }
        }
        setComment(true);
        values[0].Strikeoff[index].EnterComment = '';
        values[0].Strikeoff[index].EnableComments = 0;
      }
      if (SubmitFor === "Trim") {
        if (values[0].Trim[index].EnterComment === '') {
          // Nodify('Warning!', 'warning', 'Invalid file format selected');
          setComment(true);
        } else {
          if (values[0].Trim[index].trimComments.length !== 0) {
            if (values[0].Trim[index].trimComments[0].comments !== '') {
              if (EditedCommentIndex !== '') {
                values[0].Trim[index].trimComments[EditedCommentIndex].comments = assignValue;
              }
              else {
                values[0].Trim[index].trimComments.push({
                  protoFitSmsTrimCommentId: 0, comments: assignValue,
                  trimIndexName: (index).toString(),
                  commentDate: props.TaskStatus === 1 || props.editProtoFitSmsSubmission === false ? new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                    + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) :
                    new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                    + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                });
              }
            }
            else {
              values[0].Trim[index].trimComments[0] = {
                protoFitSmsTrimCommentId: 0, comments: assignValue,
                trimIndexName: (index).toString(),
                commentDate: props.TaskStatus === 1 || props.editProtoFitSmsSubmission === false ? new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                  + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) :
                  new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                  + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
              };
            }
          }
          else {
            values[0].Trim[index].trimComments.push({
              protoFitSmsTrimCommentId: 0, comments: assignValue,
              trimIndexName: (index).toString(),
              commentDate: props.TaskStatus === 1 || props.editProtoFitSmsSubmission === false ? new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) :
                new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })

            });
          }
        }
        setComment(true);
        values[0].Trim[index].EnterComment = '';
        values[0].Trim[index].EnableComments = 0;

      }
    }

    setInputFields(values);
    setEditedCommentIndex('');
    setSubmitted(true)


  };
  // const handleSaveComment = (index, assignValue, SubmitFor) => {
  //     ;
  //     let IsValid = true;
  //     // if (assignValue === '') {
  //     //     setComment(true); IsValid = false;
  //     //     Nodify('Warning!', 'warning', "Please enter comments");
  //     // }
  //     if (IsValid) {
  //         if (SubmitFor === "Fabric") {
  //             if (values[0].Fabric[index].EnterComment === '') {
  //                 // Nodify('Warning!', 'warning', 'Invalid file format selected');
  //                 setComment(true);
  //             } else {
  //                 if (values[0].Fabric[index].fabricComments && values[0].Fabric[index].fabricComments.length !== 0) {
  //                     if (values[0].Fabric[index].fabricComments[0].comments !== '') {
  //                         if (EditedCommentIndex !== '') {
  //                             values[0].Fabric[index].fabricComments[EditedCommentIndex].comments = assignValue;
  //                         }
  //                         else {
  //                             values[0].Fabric[index].fabricComments.push({
  //                                 protoFitSmsFabricCommentId: 0, comments: assignValue,
  //                                 fabricIndexName: (index).toString(),
  //                                 commentDate: props.props.TaskStatus === 1 || props.editProtoFitSmsSubmission === false ? new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
  //                                     + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) :
  //                                     new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
  //                                     + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
  //                             });
  //                         }
  //                     }
  //                     else {
  //                         values[0].Fabric[index].fabricComments[0] = {
  //                             protoFitSmsFabricCommentId: 0, comments: assignValue,
  //                             fabricIndexName: (index).toString(),
  //                             commentDate: props.TaskStatus === 1 || props.editProtoFitSmsSubmission === false ? new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
  //                                 + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) :
  //                                 new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
  //                                 + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
  //                         };
  //                     }
  //                 }
  //                 else {
  //                     values[0].Fabric[index].fabricComments.push({
  //                         protoFitSmsFabricCommentId: 0, comments: assignValue,
  //                         fabricIndexName: (index).toString(),
  //                         commentDate: props.TaskStatus === 1 || props.editProtoFitSmsSubmission === false ? new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
  //                             + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) :
  //                             new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
  //                             + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
  //                     });
  //                 }
  //             }
  //             setComment(true);
  //             values[0].Fabric[index].EnterComment = '';
  //             values[0].Fabric[index].EnableComments = 0;
  //         }

  //         if (SubmitFor === "Color") {
  //             if (values[0].Color[index].EnterComment === '') {
  //                 // Nodify('Warning!', 'warning', 'Invalid file format selected');
  //                 setComment(true);
  //             } else {
  //                 if (values[0].Color[index].colorComments.length !== 0) {
  //                     if (values[0].Color[index].colorComments[0].comments !== '') {
  //                         if (EditedCommentIndex !== '') {
  //                             values[0].Color[index].colorComments[EditedCommentIndex].comments = assignValue;
  //                         }
  //                         else {
  //                             values[0].Color[index].colorComments.push({
  //                                 protoFitSmsColorCommentId: 0, comments: assignValue,
  //                                 colorIndexName: (index).toString(),
  //                                 commentDate: props.TaskStatus === 1 || props.editProtoFitSmsSubmission === false ? new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
  //                                     + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) :
  //                                     new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
  //                                     + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
  //                             });
  //                         }
  //                     }
  //                     else {
  //                         values[0].Color[index].colorComments[0] = {
  //                             protoFitSmsColorCommentId: 0, comments: assignValue,
  //                             colorIndexName: (index).toString(),
  //                             commentDate: props.TaskStatus === 1 || props.editProtoFitSmsSubmission === false ? new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
  //                                 + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) :
  //                                 new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
  //                                 + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
  //                         };
  //                     }
  //                 }
  //                 else {
  //                     values[0].Color[index].colorComments.push({
  //                         protoFitSmsColorCommentId: 0, comments: assignValue,
  //                         colorIndexName: (index).toString(),
  //                         commentDate: props.TaskStatus === 1 || props.editProtoFitSmsSubmission === false ? new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
  //                             + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) :
  //                             new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
  //                             + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })

  //                     });
  //                 }
  //             }
  //             setComment(true);
  //             values[0].Color[index].EnterComment = '';
  //             values[0].Color[index].EnableComments = 0;
  //         }
  //         if (SubmitFor === "Strikeoff") {
  //             if (values[0].Strikeoff[index].EnterComment === '') {
  //                 // Nodify('Warning!', 'warning', 'Invalid file format selected');
  //                 setComment(true);
  //             } else {
  //                 if (values[0].Strikeoff[index].strikeoffComments.length !== 0) {
  //                     if (values[0].Strikeoff[index].strikeoffComments[0].comments !== '') {
  //                         if (EditedCommentIndex !== '') {
  //                             values[0].Strikeoff[index].strikeoffComments[EditedCommentIndex].comments = assignValue;
  //                         }
  //                         else {
  //                             values[0].Strikeoff[index].strikeoffComments.push({
  //                                 protoFitSmsStrikeoffCommentId: 0, comments: assignValue,
  //                                 strikeoffIndexName: (index).toString(),
  //                                 commentDate: props.TaskStatus === 1 || props.editProtoFitSmsSubmission === false ? new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
  //                                     + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) :
  //                                     new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
  //                                     + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
  //                             });
  //                         }
  //                     }
  //                     else {
  //                         values[0].Strikeoff[index].strikeoffComments[0] = {
  //                             protoFitSmsStrikeoffCommentId: 0, comments: assignValue,
  //                             strikeoffIndexName: (index).toString(),
  //                             commentDate: props.TaskStatus === 1 || props.editProtoFitSmsSubmission === false ? new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
  //                                 + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) :
  //                                 new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
  //                                 + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
  //                         };
  //                     }
  //                 }
  //                 else {
  //                     values[0].Strikeoff[index].strikeoffComments.push({
  //                         protoFitSmsStrikeoffCommentId: 0, comments: assignValue,
  //                         strikeoffIndexName: (index).toString(),
  //                         commentDate: props.TaskStatus === 1 || props.editProtoFitSmsSubmission === false ? new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
  //                             + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) :
  //                             new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
  //                             + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })

  //                     });
  //                 }
  //             }
  //             setComment(true);
  //             values[0].Strikeoff[index].EnterComment = '';
  //             values[0].Strikeoff[index].EnableComments = 0;
  //         }
  //         if (SubmitFor === "Trim") {
  //             if (values[0].Trim[index].EnterComment === '') {
  //                 // Nodify('Warning!', 'warning', 'Invalid file format selected');
  //                 setComment(true);
  //             } else {
  //                 if (values[0].Trim[index].trimComments.length !== 0) {
  //                     if (values[0].Trim[index].trimComments[0].comments !== '') {
  //                         if (EditedCommentIndex !== '') {
  //                             values[0].Trim[index].trimComments[EditedCommentIndex].comments = assignValue;
  //                         }
  //                         else {
  //                             values[0].Trim[index].trimComments.push({
  //                                 protoFitSmsTrimCommentId: 0, comments: assignValue,
  //                                 trimIndexName: (index).toString(),
  //                                 commentDate: props.TaskStatus === 1 || props.editProtoFitSmsSubmission === false ? new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
  //                                     + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) :
  //                                     new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
  //                                     + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
  //                             });
  //                         }
  //                     }
  //                     else {
  //                         values[0].Trim[index].trimComments[0] = {
  //                             protoFitSmsTrimCommentId: 0, comments: assignValue,
  //                             trimIndexName: (index).toString(),
  //                             commentDate: props.TaskStatus === 1 || props.editProtoFitSmsSubmission === false ? new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
  //                                 + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) :
  //                                 new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
  //                                 + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
  //                         };
  //                     }
  //                 }
  //                 else {
  //                     values[0].Trim[index].trimComments.push({
  //                         protoFitSmsTrimCommentId: 0, comments: assignValue,
  //                         trimIndexName: (index).toString(),
  //                         commentDate: props.TaskStatus === 1 || props.editProtoFitSmsSubmission === false ? new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
  //                             + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) :
  //                             new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
  //                             + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })

  //                     });
  //                 }
  //             }
  //             setComment(true);
  //             values[0].Trim[index].EnterComment = '';
  //             values[0].Trim[index].EnableComments = 0;
  //         }
  //     }

  //     setEditedCommentIndex('');
  // };
  //Save

  const SaveSubmission = () => {
  }

  const SaveProtoFitSms = () => {
   ;
    let valid = true;
    if (values[0].SubmittedForEdit !== "") {
      values[0].SubmittedForEdit.forEach(element => {
        if (element.label === "Fabric") {
          values[0].Fabric.forEach(element => {
            if (element.fabricID === 0) {
              setFabricSubmitted(true);
              Nodify('Warning!', 'warning', 'Please select the Fabric Details')
              valid = false;
            }
          })
        }
        if (element.label === "Color") {
          values[0].Color.forEach(Color => {
            if (Color.pantoneID === 0) {
              setColorSubmitted(true);
              Nodify('Warning!', 'warning', 'Please select the Color Details')
              valid = false;
            }
          })
        }

        if (element.label === "Strikeoff") {
          values[0].Strikeoff.forEach(strke => {
            if (strke.strikeOffLogoID === 0) {
              setStrikeoffSubmitted(true);
              Nodify('Warning!', 'warning', 'Please select the Strikeoff Details')
              valid = false;
            }
          })
        }

        if (element.label === "Trims") {
          values[0].Trim.forEach(Trim => {
            if (Trim.trimLogoInfoID === 0) {
              setTrimsubmitted(true);
              valid = false;
              Nodify('Warning!', 'warning', 'Please select the Strikeoff Details')
            }
          })
        }
        // if (element.label === "Measurement") {
        //     values[0].Fabric.forEach(element => {
        //         if (element.quality === 0) {
        //             setFabricSubmitted(true);
        //         }
        //     })
        // }
      });

    }
   
    if (values[0].SubmittedDate === '' || values[0].SentTo === "" || parseInt(values[0].SentTo) === 0 || values[0].SendThrough === 0
    ) {
      Nodify("Warning!", "warning", "Please fill the mandatory(*) fields.");
      valid = false;
      setSubmittedpopup(true);
    }

    if (valid) {
      ;
      setButtonLoader(true);
      //values[0].SubmittedDate = new Date(values[0].SubmittedDate.setMinutes(values[0].SubmittedDate.getMinutes() + 370))
      if (values[0].AWBDate === '' || values[0].AWBDate === null) {
        values[0].AWBDate = ''
      }
      else {
        values[0].AWBDate = new Date(values[0].AWBDate.setMinutes(values[0].AWBDate.getMinutes() + 370))
      }

      MainFields[0].TNAProtoFitSmsFabricInformation = [];
      MainFields[0].TNAProtoFitSmsPantoneColorsInformation = [];
      MainFields[0].TNAProtoFitSmsStrikeOffInformation = [];
      MainFields[0].TNAProtoFitSmsTrimInformation = [];
      MainFields[0].TNAProtoFitOtherComments = [];
      MainFields[0].TNAProtoFitSmsFabricCommentsInformation = [];
      MainFields[0].TNAProtoFitSmsPantoneColorCommentsInformation = [];
      MainFields[0].TNAProtoFitSmsStrikeoffCommentsInformation = [];
      MainFields[0].TNAProtoFitSmsTrimCommentsInformation = [];

      MainFields[0].TNAProtoFitSMSMeasurementFileInformation = [];
     
      MainFields[0].Operation = (props.props.props.location.pathname === '/TNAReport' || props.props.props.location.pathname === '/Dashboard') ? 1 : props.editProtoFitSmsSubmission === false ? 1 : 2;
      MainFields[0].StyleId = values[0].StyleID;
      MainFields[0].StyleName = values[0].StyleName;
      MainFields[0].StyleCode = values[0].StyleNo;
      MainFields[0].IdPoNos = values[0].IdpoNo;
      MainFields[0].SendThrough = values[0].SendThrough;
      MainFields[0].SentTo = values[0].SentTo;
      MainFields[0].AWBName = values[0].AWBName;
      MainFields[0].AWBDate = values[0].AWBDate === '' ? '' : new Date(values[0].AWBDate.setMinutes(values[0].AWBDate.getMinutes() + 370))
      MainFields[0].SubmitFor = values[0].SubmittedFor;
      MainFields[0].GarmentSizeandQty = values[0].SizeIdQtyIds;
      MainFields[0].SubmittedDate = new Date(values[0].SubmittedDate.setMinutes(values[0].SubmittedDate.getMinutes() + 370))
      MainFields[0].ProtoFitSmsSubmissionId = values[0].ProtoFitSmsSubmissionId;
      MainFields[0].ApprovedDate = null;
      MainFields[0].BuyerId = values[0].BuyerId;
      MainFields[0].BrandId = values[0].BrandId;
      MainFields[0].SeasonId = values[0].SeasonId;
      MainFields[0].SupplierId = values[0].SupplierId;
      MainFields[0].SkuId = values[0].SkuId;
      MainFields[0].TaskOwnerIdList = values[0].TaskOwnerIdList;
      MainFields[0].TaskName = props.ParentTask;


      values[0].SubmittedForEdit && values[0].SubmittedForEdit.map((Item, i) => {
        if (Item.label === "Fabric") {
          values[0].Fabric && values[0].Fabric.map((item, f) => {

            MainFields[0].TNAProtoFitSmsFabricInformation.push({
              ProtoFitSmsFabricId: item.protoFitSmsFabricId,
              FabricId: parseInt(item.fabricID),
              Quality: (item.quality === '' || item.quality === undefined) ? null : parseInt(item.quality),
              FabricIndexName: f.toString(),
              ProtoFitSmsFabricStatus: 0,
            });

            item.fabricComments && item.fabricComments.map((item2, j) => {
              MainFields[0].TNAProtoFitSmsFabricCommentsInformation.push({
                ProtoFitSmsFabricCommentId: item2.protoFitSmsFabricCommentId,
                Comments: item2.comments,
                FabricIndexName: item2.fabricIndexName
              });
            });

          });
        } else if (Item.label === "Color") {
          values[0].Color && values[0].Color.map((item, c) => {
            MainFields[0].TNAProtoFitSmsPantoneColorsInformation.push({
              ProtoFitSmsColorId: item.protoFitSmsColorId,
              PantoneId: parseInt(item.pantoneID),
              Quality: (item.quality === '' || item.quality === undefined) ? null : parseInt(item.quality),
              ColorIndexName: c.toString(),
              ProtoFitSmsPantoneStatus: 0,
            });

            item.colorComments && item.colorComments.map((item2, j) => {
              MainFields[0].TNAProtoFitSmsPantoneColorCommentsInformation.push({
                ProtoFitSmsColorCommentId: item2.protoFitSmsColorCommentId,
                Comments: item2.comments,
                ColorIndexName: item2.colorIndexName
              });
            });
          });

        } else if (Item.label === "Strikeoff") {
          values[0].Strikeoff && values[0].Strikeoff.map((item, s) => {
            MainFields[0].TNAProtoFitSmsStrikeOffInformation.push({
              ProtoFitSmsStrikeOffId: item.protoFitSmsStrikeOffId,
              StrikeOffLogoID: parseInt(item.strikeOffLogoID),
              Quality: (item.quality === '' || item.quality === undefined) ? null : parseInt(item.quality),
              StrikeOffIndexName: s.toString(),
              ProtoFitSmsStrikeOffStatus: 0,
            });

            item.strikeoffComments && item.strikeoffComments.map((item2, j) => {
              MainFields[0].TNAProtoFitSmsStrikeoffCommentsInformation.push({
                ProtoFitSmsStrikeoffCommentId: item2.protoFitSmsStrikeoffCommentId,
                Comments: item2.comments,
                StrikeoffIndexName: item2.strikeoffIndexName
              });
            });
          });
        } else if (Item.label === "Trims") {
          values[0].Trim && values[0].Trim.map((item, t) => {
            MainFields[0].TNAProtoFitSmsTrimInformation.push({
              ProtoFitSmsTrimId: item.ProtoFitSmsTrimId,
              TrimLogoID: parseInt(item.trimLogoInfoID),
              Quality: (item.quality === '' || item.quality === undefined) ? null : parseInt(item.quality),
              TrimIndexName: t.toString(),
              ProtoFitSmsTrimStatus: 0,
            });

            item.trimComments && item.trimComments.map((item2, j) => {
              MainFields[0].TNAProtoFitSmsTrimCommentsInformation.push({
                ProtoFitSmsTrimCommentId: item2.protoFitSmsTrimCommentId,
                Comments: item2.comments,
                TrimIndexName: item2.trimIndexName
              });
            });
          });

        } else if (Item.label === "Measurement") {
          MainFields[0].TNAProtoFitSMSMeasurementFileInformation = values[0].Measurement.Files;
        }

      });

      //Fabric
      // values[0].SubmissionApprovalStatus = 1;

      dispatch(InsertUpdateTNAProtoFitSmsSubmissionApproval(MainFields[0]))
        .then(data => {

          if (data.outputResult === "1") {
            Nodify('Success!', 'success', MainFields[0].TaskName === "SMS Submission" ? 'SMS Submission Added Successfully' : MainFields[0].TaskName === "Proto Sample Submission" ? "Proto Sample Submission Added Successfully" : 'Fit Sample Submission Added Successfully');
            props.ProtoFitSMSSubmissionCallback(true, 'Save')
          }
          else if (data.outputResult === "2") {
            Nodify('Success!', 'success', MainFields[0].TaskName === "SMS Submission" ? 'SMS Submission Updated Successfully' : MainFields[0].TaskName === "Proto Sample Submission" ? "Proto Sample Submission Updated Successfully" : 'Fit Sample Submission Updated Successfully');
            props.ProtoFitSMSSubmissionCallback(true, 'Update')
          }
          else if (data.outputResult === "-1") {
            Nodify('Success!', 'success', MainFields[0].TaskName === "SMS Submission" ? 'SMS Submission name already Exist' : MainFields[0].TaskName === "Proto Sample Submission" ? "Proto Sample Submission name already Exist" : 'Fit Sample Submission name already Exist');
          }

        })
        .catch(e => {
          console.log(e);
        });

      // TnaService.InsertUpdateTNAProtoFitSmsSubmissionApproval(MainFields[0]).then(res => {

      //     let Func = 'Add';
      //     if (res.data.outputResult === "1") {

      //     } else if (res.data.outputResult === "2") {

      //     } else if (res.data.outputResult === "-2") {

      //     }
      //     else if (res.data.outputResult === "0") {

      //     }
      // });

    }


  }


  const EditComments = (index, CmdIndex, Value, SubmitFor) => {
    ;

    if (SubmitFor === "Fabric") {
      values[0].Fabric[index].EnterComment = Value;
    }
    else if (SubmitFor === "Color") {
      values[0].Color[index].EnterComment = Value;
    }
    else if (SubmitFor === "Strikeoff") {
      values[0].Strikeoff[index].EnterComment = Value;
    }
    else if (SubmitFor === "Trim") {
      values[0].Trim[index].EnterComment = Value;
    }
    setEditedCommentIndex(CmdIndex);
    setInputFields(values);
  };

  const RemoveComments = (index, subChildIndex2, SubmitFor) => {
    if (SubmitFor === "Fabric") {
      values[0].Fabric[index].fabricComments.splice(subChildIndex2, 1);
    }
    else if (SubmitFor === "Color") {
      values[0].Color[index].colorComments.splice(subChildIndex2, 1);
    }
    else if (SubmitFor === "Strikeoff") {
      values[0].Strikeoff[index].strikeoffComments.splice(subChildIndex2, 1);
    }
    else if (SubmitFor === "Trim") {
      values[0].Trim[index].trimComments.splice(subChildIndex2, 1);
    }
    setInputFields(values);
  };

  const handleChangeComments = (event, parentIndex, SubmitFor) => {
    let inputText = ""
    //inputText = event.target.value.trim();
    if (SubmitFor === "Fabric") {
      if (event.target.value.trim() !== "") {
        inputText = event.target.value;
      }
      values[0].Fabric[parentIndex].EnterComment = inputText;
    }
    else if (SubmitFor === "Color") {
      if (event.target.value.trim() !== "") {
        inputText = event.target.value;
      }
      values[0].Color[parentIndex].EnterComment = inputText;

    }
    else if (SubmitFor === "Strikeoff") {
      if (event.target.value.trim() !== "") {
        inputText = event.target.value;

      }
      values[0].Strikeoff[parentIndex].EnterComment = inputText;


    }
    else if (SubmitFor === "Trim") {
      if (event.target.value.trim() !== "") {
        inputText = event.target.value;
      }
      values[0].Trim[parentIndex].EnterComment = inputText;


    }
    setInputFields(values);

  }

  const handleFullClose = (parentIndex, SubmitFor) => {
    ;
    if (SubmitFor === "Fabric") {
      values[0].Fabric[parentIndex].EnableComments = 0;
      values[0].Fabric[parentIndex].EnterComment = '';
    }
    else if (SubmitFor === "Color") {
      values[0].Color[parentIndex].EnableComments = 0;
      values[0].Color[parentIndex].EnterComment = '';
    }
    else if (SubmitFor === "Strikeoff") {
      values[0].Strikeoff[parentIndex].EnableComments = 0;
      values[0].Strikeoff[parentIndex].EnterComment = '';
    }
    else if (SubmitFor === "Trim") {
      values[0].Trim[parentIndex].EnableComments = 0;
      values[0].Trim[parentIndex].EnterComment = '';
    }
    setInputFields(values);
  }
  const onFocus = (e, field) => {
    var val = e.target.value;
    e.target.value = '';
    e.target.value = val;
  }

  const Drag = (props) => {
    return <Draggable handle=".modal-title"><ModalDialog {...props} /></Draggable>
  }
  const handlePopupClose = () => {
    setmodalpopup(false);
  }

  const openpopup = () => {
    ;
    let value = [...getsizeqty]

    var SplitbyComma = [];
    var SplitbyHypen = [];

    if (ParentTask !== "SMS Submission") {
      if (values[0].SizeandQty !== '' && values[0].SizeandQty !== null) {
        value.forEach(element => {
          element.Qty = ''
        })
        SplitbyComma = values[0].SizeandQty.split(",");
        SplitbyComma.forEach((element, index) => {
          SplitbyHypen.push({ Sizename: SplitbyComma[index].split("-") })
        })

        SplitbyHypen.forEach(element => {
          const index = getsizeqty.findIndex(obj => obj.Sizename === element.Sizename[0]);
          getsizeqty[index].Qty = element.Sizename[1];
        });
        setsizeqty(getsizeqty);
      }
      else {
        value.forEach(element => {
          element.Qty = ''
        })
      }
    }


    setmodalpopup(true);
  }
  const Delcallback = (value) => {

    if (value === true) {
      setID({ showPopupDelete: false, Params: {} });
      props.ProtoFitSMSSubmissionCallback(true, 'Delete')
    } else {
      setID({ showPopupDelete: false, Params: {} });
      props.ProtoFitSMSSubmissionCallback(false, 'Not A Delete')
    }

  }
  const savesizeqty = (e, index) => {
    ;
    // let inputtext = ""
    // if (inputtext ==='') {
    //     Nodify('Warning!', 'warning', 'Please fill Qty.');
    //     setmodalpopup(false);
    // }
    var isvalid = true;
    var EnteredCount = 0;

    getsizeqty.forEach((element, index) => {
      if (getsizeqty[index].Qty !== '') {
        EnteredCount = EnteredCount + 1;
      }
    })

    if (EnteredCount !== 0) {

      let sizeandqtyfordisplay = "";
      let sizeandqtyfordb = "";
      getsizeqty.filter(x => x.Qty !== '').map((item) => {
        if (sizeandqtyfordb === "") {
          sizeandqtyfordb = item.Size + "-" + item.Qty;
        } else {
          sizeandqtyfordb = sizeandqtyfordb + "," + item.Size + "-" + item.Qty;
        }

      });
      getsizeqty.filter(x => x.Qty !== '').map((item) => {
        if (sizeandqtyfordisplay === "") {
          sizeandqtyfordisplay = item.Sizename + "-" + item.Qty;
        } else {
          sizeandqtyfordisplay = sizeandqtyfordisplay + "," + item.Sizename + "-" + item.Qty;
        }

      });

      values[0].SizeandQty = sizeandqtyfordisplay;
      values[0].SizeIdQtyIds = sizeandqtyfordb;
      setInputFields(values);
      setmodalpopup(false);

    }
    else {
      Nodify("Warning!", "warning", "Please fill atleast one quantity.");
      setSubmittedpopup(true);
    }

  }
  const CustomInput = (props) => {
    return (
      <input
        className="form-control DatePickerCalenderHoliday"
        onClick={props.onClick}
        value={props.value}
        type="text"
        readOnly={true}
        placeholder="DD/MM/YYYY"
        style={{ border: submittedpopup && props.value === '' ? '1px solid red' : '', textTransform: "uppercase" }}
      />
    )


  }

  const CustomInputAWB = (props) => {
    return (
      <input
        className="form-control DatePickerCalenderHoliday"
        onClick={props.onClick}
        value={props.value}
        type="text"
        readOnly={true}
        placeholder="DD/MM/YYYY"
      />
    )
  }

  // const MultiValueLabel = (props) => {
  //   const { innerProps, children, data } = props;
  //   return (
  //     <div title={data.name} {...innerProps}>
  //       {children}
  //     </div>
  //   );
  // };

  return (

    <>
      <div className="row">
        <Fragment>
          <div className={DynamicClassName}>
            <div className="widget flat radius-bordered">
              <div className="panel-group accordion" id="accordion">
                <div className="panel panel-default">

                  <div id={"collapsePrograme_"} className={getHeader !== "ADD" ? 'panel-collapse collapse in' : 'panel-collapse collapse in'}>
                    <div className="widget-body">
                      <div id="registration-form">
                        <Form>

                          <div className="row" style={{ overflowX: "hidden", height: "630px", paddingRight: "10px", paddingLeft: "10px" }} >
                            <div className="row"
                            // style={{
                            //     pointerEvents:
                            //         props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                            //             ? true : props.props.props.location.pathname !== '/TNAEdit' && props.TaskStatus === 3 ? true : false
                            // }}
                            >
                              <div className="col-sm-12">
                                <div className="row">
                                  <div className="col-sm-3">
                                    <div className='form-group'>
                                      <label htmlFor="BuyerID">Buyer-Season-Brand</label>
                                      <span className='input-icon icon-right'>
                                        <Input
                                          placeholder="Buyer-Season-Brand"
                                          className="form-control"
                                          name="Buyer-Season-Brand"
                                          id={
                                            "Buyer-Season-Brand"
                                          }
                                          value={
                                            inputFields[0].BuyerBrandSeasonName
                                          }
                                          disabled
                                        ></Input>
                                      </span>
                                    </div>
                                  </div>

                                  <div className="col-sm-3">
                                    <div className='form-group'>
                                      <label htmlFor="SupplierID" >Supplier</label>
                                      <span className='input-icon icon-right'>
                                        <Input
                                          placeholder="Supplier"
                                          className="form-control"
                                          name="SupplierID"
                                          id={
                                            "SupplierID"
                                          }
                                          value={
                                            inputFields[0].SupplierName
                                          }
                                          disabled

                                        ></Input>
                                      </span>
                                    </div>
                                  </div>

                                  <div className="col-sm-3">
                                    <div className='form-group'>
                                      <label htmlFor="BuyerID">Style Name-Style No</label>
                                      <span className='input-icon icon-right'>
                                        <Input
                                          placeholder="StyleName"
                                          className="form-control"
                                          title={inputFields[0].StyleName}
                                          name="StyleName"
                                          id={
                                            "StyleName"
                                          }
                                          value={inputFields[0].StyleName + '-' + inputFields[0].StyleNo}
                                          disabled
                                        ></Input>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-sm-3">

                                    <div className='form-group'>
                                      <label htmlFor="Name" >Submit Date<span className="text-danger">*</span></label>
                                      <span className='input-icon icon-right'>
                                        {(props.props.props.location.pathname === '/TNAReport' || props.props.props.location.pathname === '/Dashboard') && props.TaskStatus === 3 ?
                                          // < Input
                                          //     placeholder="StyleName"
                                          //     className="form-control"
                                          //     title={inputFields[0].SubmittedDate}
                                          //     name="StyleName"
                                          //     id={
                                          //         "StyleName"
                                          //     }
                                          //     value={inputFields[0].SubmittedDate}
                                          //     disabled
                                          // ></Input>

                                          <DatePicker className="form-control" name="SubmittedDate"
                                            id={
                                              "SubmittedDate"
                                            }
                                            selected={
                                              inputFields[0].SubmittedDate
                                            }

                                            dateFormat="dd/MM/yyyy"
                                            peekNextMonth
                                            value={inputFields[0].SubmittedDate}
                                            customInput={<CustomInput />}
                                            disabled
                                          />

                                          :
                                          props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false && chkDataExists === false) ?


                                            <DatePicker className="form-control" name="SubmittedDate"
                                              id={
                                                "SubmittedDate"
                                              }
                                              selected={
                                                inputFields[0].SubmittedDate
                                              }

                                              dateFormat="dd/MM/yyyy"
                                              peekNextMonth

                                              customInput={<CustomInput />}
                                              disabled
                                            />


                                            // <Input
                                            //         placeholder="StyleName"
                                            //         className="form-control"
                                            //         title={inputFields[0].SubmittedDate}
                                            //         name="StyleName"
                                            //         id={
                                            //             "StyleName"
                                            //         }
                                            //         value={inputFields[0].SubmittedDate}
                                            //         disabled

                                            //         format={"DD-MM-YYYY"}
                                            //     ></Input>
                                            :
                                            <DatePicker className="form-control" name="SubmittedDate"
                                              id={
                                                "SubmittedDate"
                                              }
                                              selected={
                                                inputFields[0].SubmittedDate
                                              }
                                              onChange={event => handleChange(event, 'SubmittedDate')}
                                              dateFormat="dd/MM/yyyy"
                                              peekNextMonth

                                              dropdownMode="scroll"
                                              autoComplete="off"
                                              customInput={<CustomInput />}
                                              isClearable={inputFields[0].SubmittedDate === null ? false : true}
                                            />








                                          // <input id="SubmittedDate" name="SubmittedDate"
                                          //     value={inputFields[0].SubmittedDate} placeholder="DD/MM/YYYY"

                                          //     type="date" autoComplete="off"
                                          //     className={'form-control'} onKeyDown={(e) => e.preventDefault()}
                                          //     style={{
                                          //         border: submittedpopup && inputFields[0].SubmittedDate === '' ? '1px solid red' : '', textTransform: "uppercase"
                                          //     }}
                                          //     onChange={event => handleChange(event, 'SubmittedDate')}
                                          // />


                                        }

                                      </span>
                                    </div>

                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-sm-3">
                                    <div className='form-group'>
                                      <label htmlFor="Name" >PO/ID No</label>
                                      <span className='input-icon icon-right'>
                                        <Input
                                          placeholder="PO/ID No"
                                          className="form-control"
                                          title={inputFields[0].IdpoNo}
                                          name="IDNo"
                                          id={
                                            "IDNo"
                                          }
                                          value={
                                            inputFields[0].IdpoNo
                                          }

                                          disabled
                                        ></Input>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-sm-3">
                                    <div className="form-group">
                                      <label htmlFor={"SendToID"}>Send To<span className="text-danger">*</span></label>
                                      <span className="input-icon icon-right"  title={Array.isArray(inputFields[0].SendToEdit) ? inputFields[0].SendToEdit.map(item => item.name).join(', ') : ''}>
                                        {(props.props.props.location.pathname === '/TNAReport' || props.props.props.location.pathname === '/Dashboard') && props.TaskStatus === 3 ?
                                          // <select id={"SentTo"} name="SentTo" className="form-select SentTo"
                                          //   value={inputFields[0].SentTo}
                                          //   onChange={(event) => handleChange(event, 'SentTo')}
                                          //   style={{ border: submittedpopup && (inputFields[0].SentTo === 0 || inputFields[0].SentTo === "0") ? '1px solid red' : '' }}
                                          //   disabled={props.TaskStatus === 3 ? true : false}
                                          // >
                                          //   <option value="0">-Select Sent To-</option>
                                          //   {
                                          //     getTaskholder.map(item => (
                                          //       <option key={item.id} value={item.value}>
                                          //         {item.label}
                                          //       </option>
                                          //     ))
                                          //   }
                                          // </select>
                                          <Reactselect className="basic-single " name="SentTo"
                                            id={
                                              "SentTott"
                                            }
                                            //isDisabled={false}
                                            isLoading={false}

                                            isClearable={true}
                                            isSearchable={true}
                                            isMulti
                                          //   components={{
                                          //     MultiValueLabel,
                                          //  }}	
                                            value={inputFields[0].SendToEdit}
                                            options={getTaskholder}
                                            onChange={event => handlSendAdd(event, 'SentTo')}
                                            isDisabled={props.TaskStatus === 3 ? true : false}
                                            styles={submittedpopup && inputFields[0].SentTo === '' ? styles : ''}
                                          />




                                          :

                                          <Reactselect className="basic-single SendToProtoFit" name="SentTo"
                                            id={
                                              "SentTo"
                                            }
                                            //isDisabled={false}
                                            isLoading={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            isMulti
                                            value={inputFields[0].SendToEdit}
                                            options={getTaskholder}
                                            onChange={event => handlSendAdd(event, 'SentTo')}
                                            styles={submittedpopup && inputFields[0].SentTo === '' ? styles : ''}
                                            isDisabled={props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true) ? true : false}
                                          />
                                          // <select id={"SentTo"} name="SentTo" className="form-select SentTo"
                                          //   value={inputFields[0].SentTo}
                                          //   onChange={(event) => handleChange(event, 'SentTo')}
                                          //   style={{ border: submittedpopup && (inputFields[0].SentTo === 0 || inputFields[0].SentTo === "0") ? '1px solid red' : '' }}
                                          //   disabled={props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true) ? true : false}
                                          // >
                                          //   <option value="0">-Select Sent To-</option>
                                          //   {
                                          //     getTaskholder.map(item => (
                                          //       <option key={item.id} value={item.value}>
                                          //         {item.label}
                                          //       </option>
                                          //     ))
                                          //   }
                                          // </select>
                                        }
                                      </span>
                                    </div>
                                  </div>

                                  <div className="col-sm-3">
                                    <div className="form-group">
                                      <label htmlFor={"Sendthrough"}>Send Through<span className="text-danger">*</span></label>
                                      {
                                        (props.props.props.location.pathname === '/TNAReport' || props.props.props.location.pathname === '/Dashboard') && props.TaskStatus === 3 ?
                                          <select id={"Sendthrough"} name="Sendthrough" className="form-select"
                                            value={inputFields[0].SendThrough}
                                            onChange={event => handleChange(event, 'Sendthrough')}
                                            style={{ border: submittedpopup && inputFields[0].SendThrough === 0 ? '1px solid red' : '' }}
                                            disabled={props.TaskStatus === 3 ? true : false}
                                          >
                                            <option value="0" key="0" style={{ color: '#bfdea1' }}> - Select Send Through - </option>
                                            <option value="1" key="1">Hand Carried</option>
                                            <option value="2" key="2">Courier - DHL</option>

                                          </select> :
                                          <select id={"Sendthrough"} name="Sendthrough" className="form-select"
                                            value={inputFields[0].SendThrough}
                                            onChange={event => handleChange(event, 'Sendthrough')}
                                            style={{ border: submittedpopup && inputFields[0].SendThrough === 0 ? '1px solid red' : '' }}
                                            disabled={props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true) ? true : false}
                                          >
                                            <option value="0" key="0" style={{ color: '#bfdea1' }}> - Select Send Through - </option>
                                            <option value="1" key="1">Hand Carried</option>
                                            <option value="2" key="2">Courier - DHL</option>

                                          </select>

                                      }


                                    </div>
                                  </div>
                                  {
                                    showawb === true ?
                                      <div className="col-sm-3">
                                        <div className='form-group'>
                                          <label htmlFor="Awbdate" >AWB / Date</label>
                                          <span className='input-icon icon-right'>
                                            <Input
                                              placeholder="AWB / Date"
                                              className="form-control cut_text"
                                              title={inputFields.AWBDateandNo}
                                              name="AWBDateandNo"
                                              id={
                                                "AWBDateandNo"
                                              }
                                              value={
                                                inputFields[0].AWBDateandNo
                                              }
                                              onClick={() => openStrikeoffpopup()}
                                              //style={{ border: submittedpopup && inputFields[0].AWBDateandNo === '' ? '1px solid red' : '' }}
                                              disabled={props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true) ? true
                                                : (props.props.props.location.pathname === '/TNAReport' || props.props.props.location.pathname === '/Dashboard') && props.TaskStatus === 3 ? true : false}
                                            ></Input>
                                          </span>
                                        </div>
                                      </div> : ''
                                  }

                                </div>
                                <div className="row">
                                  <div className="col-sm-3">
                                    <div className="form-group">
                                      <label htmlFor={"SubmitFor"}>Submit For</label>
                                      <span className="input-icon icon-right" style={{ display: "inline-block" }}>
                                        {(props.props.props.location.pathname === '/TNAReport' || props.props.props.location.pathname === '/Dashboard') && props.TaskStatus === 3 ?
                                          <Reactselect className="basic-single" name="SubmitFor"
                                            id={
                                              "SubmitFor"
                                            }
                                            //isDisabled={false}
                                            isLoading={false}

                                            isClearable={true}
                                            isSearchable={true}
                                            isMulti
                                            value={inputFields[0].SubmittedForEdit}
                                            options={Submittedforoptions}
                                            onChange={event => handlemultidd(event, 'SubmitFor')}
                                            isDisabled={props.TaskStatus === 3 ? true : false}
                                          /> :
                                          <Reactselect className="basic-single" name="SubmitFor"
                                            id={
                                              "SubmitFor"
                                            }
                                            //isDisabled={false}
                                            isLoading={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            isMulti
                                            value={inputFields[0].SubmittedForEdit}
                                            options={Submittedforoptions}
                                            onChange={event => handlemultidd(event, 'SubmitFor')}
                                            isDisabled={props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true) ? true : false}
                                          />

                                        }

                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-sm-3">
                                    <div className='form-group'>
                                      <label htmlFor="Name" className="lowCaseText" >Garment Size and Qty</label>
                                      <span className='input-icon icon-right'>

                                        <Input
                                          placeholder="Garment Size and Qty"
                                          className="form-control"
                                          name="GarmentSizeQty"
                                          id={
                                            "GarmentSizeQty"
                                          }
                                          value={
                                            inputFields[0].SizeandQty
                                          }
                                          onClick={() => openpopup()}
                                          disabled={props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true) ? true
                                            : (props.props.props.location.pathname === '/TNAReport' || props.props.props.location.pathname === '/Dashboard') && props.TaskStatus === 3 ? true : false}
                                        ></Input>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-sm-3">
                                    <div className="form-group">
                                      <label htmlFor={"SendToID"}>SKU name<span className="text-danger">*</span></label>
                                      <span className="input-icon icon-right">

                                        <Input
                                          placeholder="Enter the Send To"
                                          className="form-control"
                                          title={inputFields[0].SkuName}
                                          name="SentTo"
                                          id={
                                            "SentTo"
                                          }
                                          value={
                                            inputFields[0].SkuName
                                          }
                                          disabled

                                        ></Input>

                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-sm-3">
                                    <div className="form-group" style={{ marginLeft: "5px" }}>
                                      <td>
                                        <div
                                          htmlFor="upload-button"
                                        >
                                          <i className="fa fa-chevron-circle-left fa-2x" onClick={() => previmage()} style={{ cursor: "pointer" }}></i>
                                          {
                                            getSubImageList.length !== 0 ?
                                              <img src={window.$APIBaseURL + "Images" + getSubImageList[getCurrimageindex]} alt="No Image" width="130px" height="130px" style={{ border: "2px solid black" }} />
                                              :
                                              <div align="center" style={{ width: "130px", height: "130px" }}>
                                                No Images Found
                                              </div>
                                          }
                                          <i className="fa fa-chevron-circle-right fa-2x" onClick={() => nextimage()} style={{ cursor: "pointer" }}></i>


                                        </div>
                                      </td>
                                    </div>
                                  </div>

                                </div>

                                <div className="row">
                                  <div className="col-sm-3">
                                    <div className="form-group">
                                      <span type="button" className="btn btn-success" onClick={() => ExpandAll()}>Expand All</span>&nbsp;
                                      <span className="btn btn-success" onClick={() => CollapseAll()}>Collapse All</span>
                                    </div>
                                  </div>
                                  {/* <div className="offset-3">
                                                                    <button className="btn btn-success">CollapseAll</button>
                                                                </div> */}
                                </div>

                                <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">

                                  <tbody> {

                                    <Fragment>
                                      {
                                        IsFabricSelected === true ?
                                          <Fragment>
                                            <tr>
                                              <td style={{ width: "200px" }}>
                                                <i
                                                  className={
                                                    IsFabricExpanded === true ?
                                                      'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                  }
                                                  onClick={event => handleExpandRow("Fabric")}

                                                ></i>
                                                Fabric</td>

                                            </tr>

                                            {
                                              IsFabricExpanded === true ?
                                                <tr>
                                                  <td>
                                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                      <TableHeader headers={headers} />
                                                      <tbody>

                                                        {
                                                          inputFields[0].Fabric && inputFields[0].Fabric.length > 0 ?
                                                            inputFields[0].Fabric.map((item, index) => (
                                                              <Fragment>

                                                                <tr>

                                                                  <td>
                                                                    <div className="row">
                                                                      <div className="col-lg-8">
                                                                        <select id={"Details"} name="Details" className="form-select"
                                                                          value={item.fabricID}
                                                                          onChange={(event) => handleChangeDetails(event, index, 'Fabric', 'Details')}
                                                                          style={{ border: fabricSubmitted && item.fabricID === 0 ? '1px solid red' : '' }}
                                                                          disabled={props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                            ? true : (props.props.props.location.pathname === '/TNAReport' || props.props.props.location.pathname === '/Dashboard') && props.TaskStatus === 3 ? true : false}
                                                                        >
                                                                          <option value="0">-Select Details-</option>
                                                                          {
                                                                            FabricDropdown.map(item => (
                                                                              <option value={item.value}>
                                                                                {item.label}
                                                                              </option>
                                                                            ))
                                                                          }
                                                                        </select>

                                                                      </div>

                                                                    </div>


                                                                  </td>
                                                                  <td><select id={"Quality"} name="Quality" className="form-select"
                                                                    value={item.quality}
                                                                    onChange={(event) => handleChangeDetails(event, index, 'Fabric', 'Quality')}
                                                                    //style={{ border: fabricSubmitted && item.quality === 0 || item.quality === '0' ? '1px solid red' : '' }}
                                                                    disabled={props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                      ? true : (props.props.props.location.pathname === '/TNAReport' || props.props.props.location.pathname === '/Dashboard') && props.TaskStatus === 3 ? true : false}
                                                                  >
                                                                    <option value="0" key="0" style={{ color: '#bfdea1' }}> - Select Quality- </option>
                                                                    <option value="1" key="1">Original</option>
                                                                    <option value="2" key="2">Available</option>'
                                                                    <option value="3" key="3">Not Attach</option>
                                                                  </select></td>
                                                                  <td className='Actionbutton fixed-column'>
                                                                    <table>
                                                                      <tbody>

                                                                        <td style={{ padding: 0 }}>
                                                                          <span style={{ padding: '2px' }}>
                                                                            <span className={item.fabricComments && item.fabricComments.length !== 0 ? 'btn btn-xs btn-danger' : 'btn btn-xs btn-primary'} title="Comments"
                                                                              disabled={props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                                ? true : (props.props.props.location.pathname === '/TNAReport' || props.props.props.location.pathname === '/Dashboard') && props.TaskStatus === 3 ? true : false}
                                                                              onClick={() => Comments(index, 'Fabric')}
                                                                              style={{
                                                                                pointerEvents: props.props.props.location.pathname === '/TNAReport' && props.TaskStatus === 3 ? 'none' :
                                                                                  props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                                    ? 'none' : '', opacity: props.props.props.location.pathname === '/TNAReport' && props.TaskStatus === 3 ? '0.6' :
                                                                                      props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                                        ? '0.6' : '',
                                                                                background: item.fabricComments && item.fabricComments.length === 1 ? 'red' : item.fabricComments && item.fabricComments.length === 1 ? 'red' : '#427fed',
                                                                                color: 'white',
                                                                                padding: '3px 4px',
                                                                                borderRadius: '2px',
                                                                                border: item.fabricComments && item.fabricComments.length === 1 ? 'red' : item.fabricComments && item.fabricComments.length === 1 ? 'red' : '#427fed',
                                                                              }}

                                                                            >
                                                                              <i className="fa fa-comment"></i>
                                                                            </span>
                                                                          </span>
                                                                          {
                                                                            item.EnableComments === 1 ?
                                                                              <Modal show={item.EnableComments === 1} dialogAs={Drag} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleFullClose(index, "Fabric")}>
                                                                                <Modal.Header closeButton>
                                                                                  <Modal.Title>
                                                                                    Comments
                                                                                  </Modal.Title>
                                                                                </Modal.Header>
                                                                                <Modal.Body>
                                                                                  <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                    <thead>
                                                                                      <tr>
                                                                                        <th>Comments<span className="text-danger">*</span></th>
                                                                                        <th style={{ width: '16%' }}>Date</th>
                                                                                        <th style={{ width: '10%' }}>Action</th>
                                                                                      </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                      {
                                                                                        item.fabricComments && item.fabricComments.filter(x => x.fabricIndexName === getCommentIndex).map((Commentitem, subChildIndex2) => {
                                                                                          return (
                                                                                            Commentitem.comments !== '' ?
                                                                                              <tr>
                                                                                                <td>
                                                                                                  {Commentitem.comments}
                                                                                                </td>
                                                                                                <td key={subChildIndex2}>
                                                                                                  {moment(Commentitem.commentDate).format('DD/MM/YYYY')}
                                                                                                  {/* {new Date(Commentitem.commentDate).toLocaleDateString('en-US')}

                                                                                                                                                                                                    &nbsp;
                                                                                                                                                                                                    {new Date(Commentitem.commentDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} */}
                                                                                                </td>
                                                                                                <td>
                                                                                                  {
                                                                                                    <>
                                                                                                      <span style={{ padding: '2px', display: subChildIndex2 === (item.fabricComments.length - 1) ? "" : "none", float: "right" }}>
                                                                                                        <span>
                                                                                                          <span className="btn btn-success" title="Edit Comment" onClick={() => EditComments(index, subChildIndex2, Commentitem.comments, 'Fabric')}>
                                                                                                            <i className="fa fa-edit"></i>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </>
                                                                                                  }
                                                                                                  {

                                                                                                    <span style={{ padding: '2px', display: Commentitem.comments === '' ? "none" : "", float: "right" }}>
                                                                                                      <span>
                                                                                                        <span className="btn btn-danger" title="Delete Comment" onClick={() => RemoveComments(index, subChildIndex2, 'Fabric')}>
                                                                                                          <i className="fa fa-trash-o"></i>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>

                                                                                                  }
                                                                                                </td>
                                                                                              </tr>
                                                                                              : ''
                                                                                          );
                                                                                        })
                                                                                      }
                                                                                      <tr>
                                                                                        <td colSpan="6">
                                                                                          <textarea
                                                                                            rows="3"
                                                                                            placeholder="Enter comments here..."
                                                                                            autoFocus onFocus={onFocus} autoComplete="off"
                                                                                            maxLength="500" name="EnterComment"
                                                                                            id={"EnterComment"}
                                                                                            value={item.EnterComment}
                                                                                            onChange={event => handleChangeComments(event, index, "Fabric")}
                                                                                            // style={{ width: "100%", border: getComment && item.EnterComment === '' ? '1px solid red' : '' }}
                                                                                            style={{ width: "100%", border: getComment && (item.EnterComment === '' || item.EnterComment === undefined) ? '1px solid red' : '' }}
                                                                                          >
                                                                                          </textarea>
                                                                                        </td>
                                                                                      </tr>
                                                                                    </tbody>
                                                                                  </table>
                                                                                </Modal.Body>
                                                                                <Modal.Footer>
                                                                                  <Button variant="success" onClick={() => handleSaveComment(index, item.EnterComment, 'Fabric')}> {/* , skuItem.PurOrdSkuCommentsID, skuItem.PurchaseOrderID */}
                                                                                    {getHeader === 'Add' ? 'Save' : 'Update'}
                                                                                  </Button>
                                                                                </Modal.Footer>
                                                                              </Modal>
                                                                              : ''
                                                                          }

                                                                        </td>

                                                                        <td style={{ padding: 0 }}>

                                                                          <div style={{ padding: '2px', display: inputFields[0].Fabric.length === 1 ? "none" : "" }}>
                                                                            <span>
                                                                              {inputFields[0].Fabric.length !== 1 && (
                                                                                <span className="btn btn-danger" title="Delete"
                                                                                  style={{
                                                                                    pointerEvents: props.props.props.location.pathname === '/TNAReport' && props.TaskStatus === 3 ? 'none' :
                                                                                      props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                                        ? 'none' : '', opacity: props.props.props.location.pathname === '/TNAReport' && props.TaskStatus === 3 ? '0.6' :
                                                                                          props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                                            ? '0.6' : ''
                                                                                  }}
                                                                                  disabled={props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                                    ? true : (props.props.props.location.pathname === '/TNAReport' || props.props.props.location.pathname === '/Dashboard') && props.TaskStatus === 3 ? true : false}
                                                                                  onClick={() => RemoveFields(index, 'Fabric')}>
                                                                                  <i className="fa fa-trash-o"></i>
                                                                                </span>
                                                                              )}
                                                                            </span>
                                                                          </div>
                                                                        </td>

                                                                        <td style={{ padding: 0 }}>
                                                                          <div style={{ padding: '2px', display: inputFields[0].Fabric.length === 0 ? "none" : "" }}>

                                                                            <span>
                                                                              {inputFields[0].Fabric.length === index + 1 && (
                                                                                <span className="btn btn-success" title="Add"
                                                                                  style={{
                                                                                    pointerEvents: props.props.props.location.pathname === '/TNAReport' && props.TaskStatus === 3 ? 'none' :
                                                                                      props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                                        ? 'none' : '', opacity: props.props.props.location.pathname === '/TNAReport' && props.TaskStatus === 3 ? '0.6' : props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                                          ? '0.6' : ''
                                                                                  }}
                                                                                  disabled={props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                                    ? true : (props.props.props.location.pathname === '/TNAReport' || props.props.props.location.pathname === '/Dashboard') && props.TaskStatus === 3 ? true : false}
                                                                                  onClick={() => AddFields(index, 'Fabric')}>
                                                                                  <i className="fa fa-plus"></i>
                                                                                </span>
                                                                              )}
                                                                            </span>

                                                                          </div>
                                                                        </td>

                                                                      </tbody>
                                                                    </table>
                                                                  </td>
                                                                </tr>
                                                                {
                                                                  item.fabricComments.length > 0 ?
                                                                    item.fabricComments.filter(x => x.fabricIndexName === item.fabricIndexName).map((commetdata, index) => (
                                                                      <tr>
                                                                        {
                                                                          props.TaskStatus !== 3 ?
                                                                            <td colSpan={3}>Comments: {commetdata.comments}
                                                                              &nbsp;
                                                                              Date : {moment(commetdata.commentDate).format('DD/MM/YYYY')}
                                                                              {/* &nbsp;
                                                                                                                                                            {commetdata.commentDate} */}
                                                                            </td>
                                                                            :
                                                                            <td colSpan={3}>Comments: {commetdata.comments}
                                                                              &nbsp;
                                                                              Date : {moment(commetdata.commentDate).format('DD/MM/YYYY')}
                                                                            </td>
                                                                        }

                                                                      </tr>
                                                                    )) : ''
                                                                }

                                                              </Fragment>
                                                            )) :

                                                            <tr><td align="center" colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>


                                                        }

                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr> : ''
                                            }
                                          </Fragment>
                                          : ''
                                      }

                                      {
                                        IsColorSelected === true ?
                                          <Fragment>
                                            <tr>
                                              <td>
                                                <i
                                                  className={
                                                    IsColorExpanded === true ?
                                                      'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                  }
                                                  onClick={event => handleExpandRow("Color")}

                                                ></i>
                                                Color</td>

                                            </tr>

                                            {
                                              IsColorExpanded === true ?
                                                <tr>
                                                  <td>
                                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                      <TableHeader headers={headers} />
                                                      <tbody>
                                                        {
                                                          inputFields[0].Color.length > 0 ?
                                                            inputFields[0].Color.map((item, index) => (
                                                              <Fragment>

                                                                <tr>
                                                                  <td>
                                                                    <div className="row">
                                                                      <div className="col-lg-8">
                                                                        <select id={"Details"} name="Details" className="form-select"
                                                                          value={item.pantoneID}
                                                                          onChange={(event) => handleChangeDetails(event, index, 'Color', 'Details')}
                                                                          style={{ border: colorSubmitted && item.pantoneID === 0 ? '1px solid red' : '' }}
                                                                          disabled={props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                            ? true : (props.props.props.location.pathname === '/TNAReport' || props.props.props.location.pathname === '/Dashboard') && props.TaskStatus === 3 ? true : false}
                                                                        >
                                                                          <option value="0">-Select Details-</option>
                                                                          {
                                                                            ColorDropdown.filter(x => x.label !== '').map(item => (
                                                                              <option value={item.value}>
                                                                                {item.label}
                                                                              </option>
                                                                            ))
                                                                          }
                                                                        </select>
                                                                      </div>
                                                                      <div className="col-lg-4">
                                                                        <span className="Actionbutton">
                                                                          <div style={{ background: item.pantoneColorOrSwatchImage, width: '100%', height: '34px', borderRadius: '2px' }}></div>
                                                                        </span>
                                                                      </div>
                                                                    </div>


                                                                  </td>
                                                                  <td><select id={"Quality"} name="Quality" className="form-select"
                                                                    value={item.quality}
                                                                    onChange={(event) => handleChangeDetails(event, index, 'Color', 'Quality')}
                                                                    disabled={props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                      ? true : (props.props.props.location.pathname === '/TNAReport' || props.props.props.location.pathname === '/Dashboard') && props.TaskStatus === 3 ? true : false}
                                                                  // style={{
                                                                  //     border: colorSubmitted && item.quality === 0 || item.quality === '0' ? '1px solid red' : ''
                                                                  // }}

                                                                  >
                                                                    <option value="0" key="0" style={{ color: '#bfdea1' }}> - Select Quality- </option>
                                                                    <option value="1" key="1">Original</option>
                                                                    <option value="2" key="2">Available</option>'
                                                                    <option value="3" key="3">Not Attach</option>
                                                                  </select></td>
                                                                  <td className='Actionbutton fixed-column'>
                                                                    <table>
                                                                      <tbody>

                                                                        <td style={{ padding: 0 }}>
                                                                          <span style={{ padding: '2px' }}>
                                                                            <span className={item.colorComments && item.colorComments.length !== 0 ? 'btn btn-xs btn-danger' : 'btn btn-xs btn-primary'} title="Comments" onClick={() => Comments(index, 'Color')}
                                                                              disabled={props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                                ? true : (props.props.props.location.pathname === '/TNAReport' || props.props.props.location.pathname === '/Dashboard') && props.TaskStatus === 3 ? true : false}
                                                                              style={{
                                                                                pointerEvents: props.props.props.location.pathname === '/TNAReport' && props.TaskStatus === 3 ? 'none' :
                                                                                  props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                                    ? 'none' : '', opacity: props.props.props.location.pathname === '/TNAReport' && props.TaskStatus === 3 ? '0.6' :
                                                                                      props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                                        ? '0.6' : '',
                                                                                background: item.colorComments.length === 0 ? '#427fed' : item.colorComments.length !== 0 ? 'red' : '#427fed',
                                                                                color: 'white',
                                                                                padding: '3px 4px',
                                                                                borderRadius: '2px',
                                                                                border: item.colorComments.length === 0 ? '#427fed' : item.colorComments.length !== 0 ? 'red' : '#427fed',
                                                                              }}
                                                                            >
                                                                              <i className="fa fa-comment"></i>
                                                                            </span>
                                                                          </span>
                                                                          {
                                                                            item.EnableComments === 1 ?
                                                                              <Modal show={item.EnableComments === 1} dialogAs={Drag} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleFullClose(index, 'Color')}>
                                                                                <Modal.Header closeButton>
                                                                                  <Modal.Title>
                                                                                    Comments
                                                                                  </Modal.Title>
                                                                                </Modal.Header>
                                                                                <Modal.Body>
                                                                                  <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                    <thead>
                                                                                      <tr>
                                                                                        <th>Comments<span className="text-danger">*</span></th>
                                                                                        <th style={{ width: '16%' }}>Date</th>
                                                                                        <th style={{ width: '10%' }}>Action</th>
                                                                                      </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                      {
                                                                                        item.colorComments.filter(x => x.colorIndexName === getCommentIndex).map((Commentitem, subChildIndex2) => {
                                                                                          return (
                                                                                            Commentitem.comments !== '' ?
                                                                                              <tr>
                                                                                                <td>
                                                                                                  {Commentitem.comments}
                                                                                                </td>
                                                                                                <td>
                                                                                                  {moment(Commentitem.commentDate).format('DD/MM/YYYY')}
                                                                                                  {/* {new Date(Commentitem.commentDate).toLocaleDateString('en-US')}

                                                                                                                                                                                                    &nbsp;
                                                                                                                                                                                                    {new Date(Commentitem.commentDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} */}
                                                                                                </td>
                                                                                                <td>
                                                                                                  {
                                                                                                    <>
                                                                                                      <span style={{ padding: '2px', display: subChildIndex2 === (item.colorComments.length - 1) ? "" : "none", float: "right" }}>
                                                                                                        <span>
                                                                                                          <span className="btn btn-success" title="Edit Comment" onClick={() => EditComments(index, subChildIndex2, Commentitem.comments, 'Color')}>
                                                                                                            <i className="fa fa-edit"></i>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </>
                                                                                                  }
                                                                                                  {

                                                                                                    <span style={{ padding: '2px', display: Commentitem.comments === '' ? "none" : "", float: "right" }}>
                                                                                                      <span>
                                                                                                        <span className="btn btn-danger" title="Delete Comment" onClick={() => RemoveComments(index, subChildIndex2, 'Color')}>
                                                                                                          <i className="fa fa-trash-o"></i>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>

                                                                                                  }
                                                                                                </td>
                                                                                              </tr>
                                                                                              : ''
                                                                                          );
                                                                                        })
                                                                                      }
                                                                                      <tr>
                                                                                        <td colSpan="6">
                                                                                          <textarea
                                                                                            rows="3"
                                                                                            placeholder="Enter comments here..."
                                                                                            autoFocus onFocus={onFocus} autoComplete="off"
                                                                                            maxLength="500" name="EnterComment"
                                                                                            id={"EnterComment"}
                                                                                            value={item.EnterComment}
                                                                                            onChange={event => handleChangeComments(event, index, 'Color')}
                                                                                            // style={{ width: "100%", border: getComment && item.EnterComment === '' ? '1px solid red' : '' }}
                                                                                            style={{ width: "100%", border: getComment && (item.EnterComment === '' || item.EnterComment === undefined) ? '1px solid red' : '' }}
                                                                                          >
                                                                                          </textarea>
                                                                                        </td>
                                                                                      </tr>
                                                                                    </tbody>
                                                                                  </table>
                                                                                </Modal.Body>
                                                                                <Modal.Footer>
                                                                                  <Button variant="success" onClick={() => handleSaveComment(index, item.EnterComment, 'Color')}> {/* , skuItem.PurOrdSkuCommentsID, skuItem.PurchaseOrderID */}
                                                                                    {getHeader === 'Add' ? 'Save' : 'Update'}
                                                                                  </Button>
                                                                                </Modal.Footer>
                                                                              </Modal>
                                                                              : ''
                                                                          }
                                                                        </td>

                                                                        <td style={{ padding: 0 }}>

                                                                          <div style={{ padding: '2px', display: inputFields[0].Color.length === 1 ? "none" : "" }}>
                                                                            <span>
                                                                              {inputFields[0].Color.length !== 1 && (
                                                                                <span className="btn btn-danger" title="Delete"
                                                                                  style={{
                                                                                    pointerEvents: props.props.props.location.pathname === '/TNAReport' && props.TaskStatus === 3 ? 'none' :
                                                                                      props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                                        ? 'none' : '', opacity: props.props.props.location.pathname === '/TNAReport' && props.TaskStatus === 3 ? '0.6' :
                                                                                          props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                                            ? '0.6' : ''
                                                                                  }}
                                                                                  disabled={props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                                    ? true : (props.props.props.location.pathname === '/TNAReport' || props.props.props.location.pathname === '/Dashboard') && props.TaskStatus === 3 ? true : false}
                                                                                  onClick={() => RemoveFields(index, 'Color')}>
                                                                                  <i className="fa fa-trash-o"></i>
                                                                                </span>
                                                                              )}
                                                                            </span>
                                                                          </div>
                                                                        </td>

                                                                        <td style={{ padding: 0 }}>
                                                                          <div style={{ padding: '2px', display: inputFields[0].Color.length === 0 ? "none" : "" }}>

                                                                            <span>
                                                                              {inputFields[0].Color.length === index + 1 && (
                                                                                <span className="btn btn-success" title="Add"
                                                                                  style={{
                                                                                    pointerEvents: props.props.props.location.pathname === '/TNAReport' && props.TaskStatus === 3 ? 'none' :
                                                                                      props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                                        ? 'none' : '', opacity: props.props.props.location.pathname === '/TNAReport' && props.TaskStatus === 3 ? '0.6' :
                                                                                          props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                                            ? '0.6' : ''
                                                                                  }}
                                                                                  disabled={props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                                    ? true : (props.props.props.location.pathname === '/TNAReport' || props.props.props.location.pathname === '/Dashboard') && props.TaskStatus === 3 ? true : false}
                                                                                  onClick={() => AddFields(index, 'Color')}>
                                                                                  <i className="fa fa-plus"></i>
                                                                                </span>
                                                                              )}
                                                                            </span>

                                                                          </div>
                                                                        </td>


                                                                      </tbody>
                                                                    </table>
                                                                  </td>
                                                                </tr>
                                                                {
                                                                  item.colorComments.length > 0 ?
                                                                    item.colorComments.filter(x => x.colorIndexName === item.colorIndexName).map((commetdata, index) => (
                                                                      <tr>
                                                                        {
                                                                          props.TaskStatus !== 3 ?
                                                                            <td colSpan={3}>Comments: {commetdata.comments}
                                                                              &nbsp;
                                                                              Date : {moment(commetdata.commentDate).format('DD/MM/YYYY')}
                                                                              {/* &nbsp; */}
                                                                              {/* {commetdata.commentDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} */}
                                                                            </td>
                                                                            :
                                                                            <td colSpan={3}>Comments: {commetdata.comments}
                                                                              &nbsp;
                                                                              Date : {moment(commetdata.commentDate).format('DD/MM/YYYY')}
                                                                            </td>
                                                                        }
                                                                      </tr>
                                                                    )) : ''
                                                                }
                                                              </Fragment>
                                                            ))
                                                            :
                                                            <tr><td align="center" colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>


                                                        }

                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr> : ''
                                            }
                                          </Fragment>
                                          : ''
                                      }
                                      {
                                        IsStrikeoffSelected === true ?
                                          <Fragment>
                                            <tr>
                                              <td>
                                                <i
                                                  className={
                                                    IsStrikeoffExpanded === true ?
                                                      'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                  }
                                                  onClick={event => handleExpandRow("Strikeoff")}

                                                ></i>
                                                Strike Off</td>

                                            </tr>

                                            {
                                              IsStrikeoffExpanded === true ?
                                                <tr>
                                                  <td>
                                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                      <TableHeader headers={headers} />
                                                      <tbody>
                                                        {
                                                          inputFields[0].Strikeoff.length > 0 ?
                                                            inputFields[0].Strikeoff.map((item, index) => (
                                                              <Fragment>

                                                                <tr>
                                                                  <td>
                                                                    <div className="row">
                                                                      <div className="col-lg-8">
                                                                        <select id={"Details"} name="Details" className="form-select"
                                                                          value={item.strikeOffLogoID}
                                                                          onChange={(event) => handleChangeDetails(event, index, 'Strikeoff', 'Details')}
                                                                          style={{ border: strikeoffSubmitted && item.strikeOffLogoID === 0 ? '1px solid red' : '' }}
                                                                          disabled={props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                            ? true : (props.props.props.location.pathname === '/TNAReport' || props.props.props.location.pathname === '/Dashboard') && props.TaskStatus === 3 ? true : false}
                                                                        >
                                                                          <option value="0">-Select Details-</option>
                                                                          {
                                                                            StrikeoffDropdown.filter(x => x.label !== '').map(item => (
                                                                              <option value={item.value}>
                                                                                {item.label}
                                                                              </option>
                                                                            ))
                                                                          }
                                                                        </select>
                                                                      </div>
                                                                      <div className="col-lg-4">
                                                                        <span className="Actionbutton">
                                                                          <img src={window.$APIBaseURL + "Images/Style/Strikeoff/" + item.strikeoffLogoPath} height="50px" width="50px"></img>
                                                                        </span>
                                                                      </div>
                                                                    </div>


                                                                  </td>
                                                                  <td><select id={"Quality"} name="Quality" className="form-select"
                                                                    value={item.quality}
                                                                    onChange={(event) => handleChangeDetails(event, index, 'Strikeoff', 'Quality')}
                                                                    //style={{ border: strikeoffSubmitted && item.quality === 0 || item.quality === '0' ? '1px solid red' : '' }}
                                                                    disabled={props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                      ? true : (props.props.props.location.pathname === '/TNAReport' || props.props.props.location.pathname === '/Dashboard') && props.TaskStatus === 3 ? true : false}
                                                                  >
                                                                    <option value="0" key="0" style={{ color: '#bfdea1' }}> - Select Quality- </option>
                                                                    <option value="1" key="1">Original</option>
                                                                    <option value="2" key="2">Available</option>'
                                                                    <option value="3" key="3">Not Attach</option>
                                                                  </select></td>
                                                                  <td className='Actionbutton fixed-column'>
                                                                    <table>
                                                                      <tbody>
                                                                        <td style={{ padding: 0 }}>
                                                                          <span style={{ padding: '2px' }}>
                                                                            <span className={item.strikeoffComments && item.strikeoffComments.length !== 0 ? 'btn btn-xs btn-danger' : 'btn btn-xs btn-primary'} title="Comments" onClick={() => Comments(index, 'Strikeoff')}
                                                                              disabled={props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                                ? true : (props.props.props.location.pathname === '/TNAReport' || props.props.props.location.pathname === '/Dashboard') && props.TaskStatus === 3 ? true : false}
                                                                              style={{
                                                                                pointerEvents: props.props.props.location.pathname === '/TNAReport' && props.TaskStatus === 3 ? 'none' :
                                                                                  props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                                    ? 'none' : '', opacity: props.props.props.location.pathname === '/TNAReport' && props.TaskStatus === 3 ? '0.6' :
                                                                                      props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                                        ? '0.6' : '',
                                                                                background: item.strikeoffComments.length === 0 ? '#427fed' : item.strikeoffComments.length !== 0 ? 'red' : '#427fed',
                                                                                color: 'white',
                                                                                padding: '3px 4px',
                                                                                borderRadius: '2px',
                                                                                border: item.strikeoffComments.length === 0 ? '#427fed' : item.strikeoffComments.length !== 0 ? 'red' : '#427fed',
                                                                              }}
                                                                            >
                                                                              <i className="fa fa-comment"></i>
                                                                            </span>
                                                                          </span>
                                                                          {
                                                                            item.EnableComments === 1 ?
                                                                              <Modal show={item.EnableComments === 1} dialogAs={Drag} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleFullClose(index, 'Strikeoff')}>
                                                                                <Modal.Header closeButton>
                                                                                  <Modal.Title>
                                                                                    Comments
                                                                                  </Modal.Title>
                                                                                </Modal.Header>
                                                                                <Modal.Body>
                                                                                  <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                    <thead>
                                                                                      <tr>
                                                                                        <th>Comments<span className="text-danger">*</span></th>
                                                                                        <th style={{ width: '16%' }}>Date</th>
                                                                                        <th style={{ width: '10%' }}>Action</th>
                                                                                      </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                      {
                                                                                        item.strikeoffComments.filter(x => x.strikeoffIndexName === getCommentIndex).map((Commentitem, subChildIndex2) => {
                                                                                          return (
                                                                                            Commentitem.comments !== '' ?
                                                                                              <tr>
                                                                                                <td>
                                                                                                  {Commentitem.comments}
                                                                                                </td>
                                                                                                <td>
                                                                                                  {moment(Commentitem.commentDate).format('DD/MM/YYYY')}
                                                                                                  {/* {new Date(Commentitem.commentDate).toLocaleDateString('en-US')}

                                                                                                                                                                                                    &nbsp;
                                                                                                                                                                                                    {new Date(Commentitem.commentDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} */}
                                                                                                </td>
                                                                                                <td>
                                                                                                  {
                                                                                                    <>
                                                                                                      <span style={{ padding: '2px', display: subChildIndex2 === (item.strikeoffComments.length - 1) ? "" : "none", float: "right" }}>
                                                                                                        <span>
                                                                                                          <span className="btn btn-success" title="Edit Comment" onClick={() => EditComments(index, subChildIndex2, Commentitem.comments, 'Strikeoff')}>
                                                                                                            <i className="fa fa-edit"></i>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </>
                                                                                                  }
                                                                                                  {

                                                                                                    <span style={{ padding: '2px', display: Commentitem.comments === '' ? "none" : "", float: "right" }}>
                                                                                                      <span>
                                                                                                        <span className="btn btn-danger" title="Delete Comment" onClick={() => RemoveComments(index, subChildIndex2, 'Strikeoff')}>
                                                                                                          <i className="fa fa-trash-o"></i>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>

                                                                                                  }
                                                                                                </td>
                                                                                              </tr>
                                                                                              : ''
                                                                                          );
                                                                                        })
                                                                                      }
                                                                                      <tr>
                                                                                        <td colSpan="6">
                                                                                          <textarea
                                                                                            rows="3"
                                                                                            placeholder="Enter comments here..."
                                                                                            autoFocus onFocus={onFocus} autoComplete="off"
                                                                                            maxLength="500" name="EnterComment"
                                                                                            id={"EnterComment"}
                                                                                            value={item.EnterComment}
                                                                                            onChange={event => handleChangeComments(event, index, 'Strikeoff')}
                                                                                            // style={{ width: "100%", border: getComment && item.EnterComment === '' ? '1px solid red' : '' }}
                                                                                            style={{ width: "100%", border: getComment && (item.EnterComment === '' || item.EnterComment === undefined) ? '1px solid red' : '' }}
                                                                                          >
                                                                                          </textarea>
                                                                                        </td>
                                                                                      </tr>
                                                                                    </tbody>
                                                                                  </table>
                                                                                </Modal.Body>
                                                                                <Modal.Footer>
                                                                                  <Button variant="success" onClick={() => handleSaveComment(index, item.EnterComment, 'Strikeoff')}> {/* , skuItem.PurOrdSkuCommentsID, skuItem.PurchaseOrderID */}
                                                                                    {getHeader === 'Add' ? 'Save' : 'Update'}
                                                                                  </Button>
                                                                                </Modal.Footer>
                                                                              </Modal>
                                                                              : ''
                                                                          }
                                                                        </td>


                                                                        <td style={{ padding: 0 }}>

                                                                          <div style={{ padding: '2px', display: inputFields[0].Strikeoff.length === 1 ? "none" : "" }}>
                                                                            <span>
                                                                              {inputFields[0].Strikeoff.length !== 1 && (
                                                                                <span className="btn btn-danger" title="Delete" onClick={() => RemoveFields(index, 'Strikeoff')}
                                                                                  style={{
                                                                                    pointerEvents: props.props.props.location.pathname === '/TNAReport' && props.TaskStatus === 3 ? 'none' :
                                                                                      props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                                        ? 'none' : '', opacity: props.props.props.location.pathname === '/TNAReport' && props.TaskStatus === 3 ? '0.6' :
                                                                                          props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                                            ? '0.6' : ''
                                                                                  }}
                                                                                >
                                                                                  <i className="fa fa-trash-o"></i>
                                                                                </span>
                                                                              )}
                                                                            </span>
                                                                          </div>
                                                                        </td>
                                                                        <td style={{ padding: 0 }}>
                                                                          <div style={{ padding: '2px', display: inputFields[0].Strikeoff.length === 0 ? "none" : "" }}>

                                                                            <span>
                                                                              {inputFields[0].Strikeoff.length === index + 1 && (
                                                                                <span className="btn btn-success" title="Add" onClick={() => AddFields(index, 'Strikeoff')}
                                                                                  style={{
                                                                                    pointerEvents: props.props.props.location.pathname === '/TNAReport' && props.TaskStatus === 3 ? 'none' :
                                                                                      props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                                        ? 'none' : '', opacity: props.props.props.location.pathname === '/TNAReport' && props.TaskStatus === 3 ? '0.6' :
                                                                                          props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                                            ? '0.6' : ''
                                                                                  }}
                                                                                >
                                                                                  <i className="fa fa-plus"></i>
                                                                                </span>
                                                                              )}
                                                                            </span>

                                                                          </div>
                                                                        </td>


                                                                      </tbody>
                                                                    </table>
                                                                  </td>
                                                                </tr>

                                                                {
                                                                  item.strikeoffComments.length > 0 ?
                                                                    item.strikeoffComments.filter(x => x.strikeoffIndexName === item.strikeoffIndexName).map((commetdata, index) => (
                                                                      <tr>
                                                                        {
                                                                          props.TaskStatus !== 3 ?
                                                                            <td colSpan={3}>Comments: {commetdata.comments}
                                                                              &nbsp;
                                                                              Date : {moment(commetdata.commentDate).format('DD/MM/YYYY')}
                                                                              &nbsp;
                                                                              {/* {commetdata.commentDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} */}
                                                                            </td>
                                                                            :
                                                                            <td colSpan={3}>Comments: {commetdata.comments}
                                                                              &nbsp;
                                                                              Date : {moment(commetdata.commentDate).format('DD/MM/YYYY')}
                                                                            </td>
                                                                        }
                                                                      </tr>
                                                                    )) : ''
                                                                }
                                                              </Fragment>
                                                            )) :

                                                            <tr><td align="center" colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>


                                                        }

                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr> : ''
                                            }
                                          </Fragment>
                                          : ''
                                      }
                                      {
                                        IsTrimsSelected === true ?
                                          <Fragment>
                                            <tr>
                                              <td>
                                                <i
                                                  className={
                                                    IsTrimsExpanded === true ?
                                                      'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                  }
                                                  onClick={event => handleExpandRow("Trims")}

                                                ></i>
                                                Trims</td>

                                            </tr>

                                            {
                                              IsTrimsExpanded === true ?
                                                <tr>
                                                  <td>
                                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                      <TableHeader headers={headers} />
                                                      <tbody>
                                                        {
                                                          inputFields[0].Trim.length > 0 ?
                                                            inputFields[0].Trim.map((item, index) => (
                                                              <Fragment>

                                                                <tr>
                                                                  <td>
                                                                    <div className="row">
                                                                      <div className="col-lg-8">
                                                                        <select id={"Details"} name="Details" className="form-select"
                                                                          value={item.trimLogoInfoID}
                                                                          onChange={(event) => handleChangeDetails(event, index, 'Trim', 'Details')}
                                                                          style={{ border: trimSubmitted && item.trimLogoInfoID === 0 ? '1px solid red' : '' }}
                                                                          disabled={props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                            ? true : (props.props.props.location.pathname === '/TNAReport' || props.props.props.location.pathname === '/Dashboard') && props.TaskStatus === 3 ? true : false}
                                                                        >
                                                                          <option value="0">-Select Details-</option>
                                                                          {
                                                                            TrimDropdown.filter(x => x.label !== '').map(item => (
                                                                              <option value={item.value}>
                                                                                {item.label}
                                                                              </option>
                                                                            ))
                                                                          }
                                                                        </select>
                                                                      </div>
                                                                      <div className="col-lg-4">
                                                                        <span className="Actionbutton">
                                                                          <img src={window.$APIBaseURL + "Images/Style/Trim/" + item.trimLogoPath} height="50px" width="50px"></img>
                                                                        </span>
                                                                      </div>
                                                                    </div>


                                                                  </td>
                                                                  <td><select id={"Quality"} name="Quality" className="form-select"
                                                                    value={item.quality}
                                                                    onChange={(event) => handleChangeDetails(event, index, 'Trim', 'Quality')}
                                                                    //style={{ border: trimSubmitted && item.quality === 0 || item.quality === '0' ? '1px solid red' : '' }}
                                                                    disabled={props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                      ? true : (props.props.props.location.pathname === '/TNAReport' || props.props.props.location.pathname === '/Dashboard') && props.TaskStatus === 3 ? true : false}
                                                                  >
                                                                    <option value="0" key="0" style={{ color: '#bfdea1' }}> - Select Quality- </option>
                                                                    <option value="1" key="1">Original</option>
                                                                    <option value="2" key="2">Available</option>'
                                                                    <option value="3" key="3">Not Attach</option>
                                                                  </select></td>
                                                                  <td className='Actionbutton fixed-column'>
                                                                    <table>
                                                                      <tbody>
                                                                        <td style={{ padding: 0 }}>
                                                                          <span style={{ padding: '2px' }}>
                                                                            <span className={item.trimComments && item.trimComments.length !== 0 ? 'btn btn-xs btn-danger' : 'btn btn-xs btn-primary'} title="Comments" onClick={() => Comments(index, 'Trim')}
                                                                              disabled={props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                                ? true : (props.props.props.location.pathname === '/TNAReport' || props.props.props.location.pathname === '/Dashboard') && props.TaskStatus === 3 ? true : false}
                                                                              style={{
                                                                                pointerEvents: props.props.props.location.pathname === '/TNAReport' && props.TaskStatus === 3 ? 'none' :
                                                                                  props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                                    ? 'none' : '', opacity: props.props.props.location.pathname === '/TNAReport' && props.TaskStatus === 3 ? '0.6' :
                                                                                      props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                                        ? '0.6' : '',
                                                                                background: item.trimComments.length === 0 ? '#427fed' : item.trimComments.length !== 0 ? 'red' : '#427fed',
                                                                                color: 'white',
                                                                                padding: '3px 4px',
                                                                                borderRadius: '2px',
                                                                                border: item.trimComments.length === 0 ? '#427fed' : item.trimComments.length !== 0 ? 'red' : '#427fed',
                                                                              }}
                                                                            >
                                                                              <i className="fa fa-comment"></i>
                                                                            </span>
                                                                          </span>
                                                                          {
                                                                            item.EnableComments === 1 ?
                                                                              <Modal show={item.EnableComments === 1} dialogAs={Drag} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleFullClose(index, 'Trim')}>
                                                                                <Modal.Header closeButton>
                                                                                  <Modal.Title>
                                                                                    Comments
                                                                                  </Modal.Title>
                                                                                </Modal.Header>
                                                                                <Modal.Body>
                                                                                  <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                    <thead>
                                                                                      <tr>
                                                                                        <th>Comments<span className="text-danger">*</span></th>
                                                                                        <th style={{ width: '16%' }}>Date</th>
                                                                                        <th style={{ width: '10%' }}>Action</th>
                                                                                      </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                      {
                                                                                        item.trimComments.filter(x => x.trimIndexName === getCommentIndex).map((Commentitem, subChildIndex2) => {
                                                                                          return (
                                                                                            Commentitem.comments !== '' ?
                                                                                              <tr>
                                                                                                <td>
                                                                                                  {Commentitem.comments}
                                                                                                </td>
                                                                                                <td>
                                                                                                  {moment(Commentitem.commentDate).format('DD/MM/YYYY')}
                                                                                                  {/* {new Date(Commentitem.commentDate).toLocaleDateString('en-US')}

                                                                                                                                                                                                    &nbsp;
                                                                                                                                                                                                    {new Date(Commentitem.commentDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} */}
                                                                                                </td>
                                                                                                <td>
                                                                                                  {
                                                                                                    <>
                                                                                                      <span style={{ padding: '2px', display: subChildIndex2 === (item.trimComments.length - 1) ? "" : "none", float: "right" }}>
                                                                                                        <span>
                                                                                                          <span className="btn btn-success" title="Edit Comment" onClick={() => EditComments(index, subChildIndex2, Commentitem.comments, 'Trim')}>
                                                                                                            <i className="fa fa-edit"></i>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </>
                                                                                                  }
                                                                                                  {

                                                                                                    <span style={{ padding: '2px', display: Commentitem.comments === '' ? "none" : "", float: "right" }}>
                                                                                                      <span>
                                                                                                        <span className="btn btn-danger" title="Delete Comment" onClick={() => RemoveComments(index, subChildIndex2, 'Trim')}>
                                                                                                          <i className="fa fa-trash-o"></i>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>

                                                                                                  }
                                                                                                </td>
                                                                                              </tr>
                                                                                              : ''
                                                                                          );
                                                                                        })
                                                                                      }
                                                                                      <tr>
                                                                                        <td colSpan="6">
                                                                                          <textarea
                                                                                            rows="3"
                                                                                            placeholder="Enter comments here..."
                                                                                            autoFocus onFocus={onFocus} autoComplete="off"
                                                                                            maxLength="500" name="EnterComment"
                                                                                            id={"EnterComment"}
                                                                                            value={item.EnterComment}
                                                                                            onChange={event => handleChangeComments(event, index, 'Trim')}
                                                                                            // style={{ width: "100%", border: getComment && item.EnterComment === '' ? '1px solid red' : '' }}
                                                                                            style={{ width: "100%", border: getComment && (item.EnterComment === '' || item.EnterComment === undefined) ? '1px solid red' : '' }}
                                                                                          >
                                                                                          </textarea>
                                                                                        </td>
                                                                                      </tr>
                                                                                    </tbody>
                                                                                  </table>
                                                                                </Modal.Body>
                                                                                <Modal.Footer>
                                                                                  <Button variant="success" onClick={() => handleSaveComment(index, item.EnterComment, 'Trim')}> {/* , skuItem.PurOrdSkuCommentsID, skuItem.PurchaseOrderID */}
                                                                                    {getHeader === 'Add' ? 'Save' : 'Update'}
                                                                                  </Button>
                                                                                </Modal.Footer>
                                                                              </Modal>
                                                                              : ''
                                                                          }
                                                                        </td>

                                                                        <td style={{ padding: 0 }}>

                                                                          <div style={{ padding: '2px', display: inputFields[0].Trim.length === 1 ? "none" : "" }}>
                                                                            <span>
                                                                              {inputFields[0].Trim.length !== 1 && (
                                                                                <span className="btn btn-danger" title="Delete" onClick={() => RemoveFields(index, 'Trim')}
                                                                                  style={{
                                                                                    pointerEvents: props.props.props.location.pathname === '/TNAReport' && props.TaskStatus === 3 ? 'none' :
                                                                                      props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                                        ? 'none' : '', opacity: props.props.props.location.pathname === '/TNAReport' && props.TaskStatus === 3 ? '0.6' :
                                                                                          props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                                            ? '0.6' : ''
                                                                                  }}
                                                                                >
                                                                                  <i className="fa fa-trash-o"></i>
                                                                                </span>
                                                                              )}
                                                                            </span>
                                                                          </div>
                                                                        </td>
                                                                        <td style={{ padding: 0 }}>
                                                                          <div style={{ padding: '2px', display: inputFields[0].Trim.length === 0 ? "none" : "" }}>

                                                                            <span>
                                                                              {inputFields[0].Trim.length === index + 1 && (
                                                                                <span className="btn btn-success" title="Add" onClick={() => AddFields(index, 'Trim')}
                                                                                  style={{
                                                                                    pointerEvents: props.props.props.location.pathname === '/TNAReport' && props.TaskStatus === 3 ? 'none' :
                                                                                      props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                                        ? 'none' : '', opacity: props.props.props.location.pathname === '/TNAReport' && props.TaskStatus === 3 ? '0.6' :
                                                                                          props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                                            ? '0.6' : ''
                                                                                  }}
                                                                                >
                                                                                  <i className="fa fa-plus"></i>
                                                                                </span>
                                                                              )}
                                                                            </span>

                                                                          </div>
                                                                        </td>


                                                                      </tbody>
                                                                    </table>
                                                                  </td>
                                                                </tr>

                                                                {
                                                                  item.trimComments.length > 0 ?
                                                                    item.trimComments.filter(x => x.trimIndexName === item.trimIndexName).map((commetdata, index) => (
                                                                      <tr>
                                                                        {
                                                                          props.TaskStatus !== 3 ?
                                                                            <td colSpan={3}>Comments: {commetdata.comments}
                                                                              &nbsp;
                                                                              {/* Date : {commetdata.commentDate.toLocaleDateString('en-US')} */}
                                                                              Date : {moment(commetdata.commentDate).format('DD/MM/YYYY')}
                                                                              {/* &nbsp;
                                                                                                                                                            {commetdata.commentDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} */}
                                                                            </td>
                                                                            :
                                                                            <td colSpan={3}>Comments: {commetdata.comments}
                                                                              &nbsp;
                                                                              Date : {moment(commetdata.commentDate).format('DD/MM/YYYY')}
                                                                            </td>
                                                                        }
                                                                      </tr>
                                                                    )) : ''
                                                                }
                                                              </Fragment>
                                                            )) :


                                                            <tr ><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>


                                                        }

                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr> : ''
                                            }
                                          </Fragment>
                                          : ''
                                      }
                                      {
                                        IsMeasurementSelected === true ?
                                          <Fragment>
                                            <tr>
                                              <td style={{ width: "200px" }}>
                                                <i
                                                  className={
                                                    IsMeasurementExpanded === true ?
                                                      'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                  }
                                                  onClick={event => handleExpandRow("Measurement")}

                                                ></i>
                                                Measurement</td>

                                            </tr>

                                            {
                                              IsMeasurementExpanded === true ?
                                                <tr>
                                                  <td>
                                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                      {/* <TableHeader headers={headers} /> */}
                                                      <tbody>

                                                        {
                                                          inputFields[0].Measurement.length > 0 ?
                                                            inputFields[0].Measurement.map((item, index) => (
                                                              <Fragment>

                                                                <tr>
                                                                  <td>

                                                                  </td>

                                                                </tr>


                                                              </Fragment>
                                                            )) :
                                                            <tbody>
                                                              <tr>
                                                                <div className="col-sm-12 col-lg-12">
                                                                  <div className="widget-header" style={{ paddingLeft: 0 }}>
                                                                    <span className="lowCaseText widget-caption" style={{ fontSize: '14px' }}>File Upload <span style={{ color: 'red', fontSize: '14px' }}>(Upload Document Type: Excel, Word, PDF and Image)</span></span>
                                                                  </div>
                                                                </div>
                                                                <div className="col-sm-12 col-lg-12">
                                                                  <div className="row">
                                                                    <div className="col-sm-8 col-lg-8">
                                                                      <div className='form-group'>
                                                                        <tr>
                                                                          <td> <input type="file" id="FileUpload"
                                                                            disabled={props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true) ? true : false}
                                                                            style={{ fontSize: '14px' }} onChange={(e) => AppendFiles(e)} accept=".jpg,.jpeg,.png,.pdf,.xlsx,.doc,.docx" ></input></td>
                                                                        </tr>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <div className="col-lg-12 col-sm-12">
                                                                  <div className="row no-margin">
                                                                    {
                                                                      inputFields[0].Measurement.Files.length !== 0 ?
                                                                        inputFields[0].Measurement.Files.map((files, fileindex) => (
                                                                          <div className="col-lg-4 col-sm-4 file_list_upload">
                                                                            <tr title={files.FileName}>
                                                                              <td className="cut_textInTNA">
                                                                                <span style={{ marginRight: '7px' }}>
                                                                                  {
                                                                                    files.FileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                      files.FileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                        <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                        files.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                          files.FileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                          <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                  }
                                                                                </span>  {files.FileName}
                                                                              </td>

                                                                              <td className="button_style_uploda">
                                                                              {
                                                                                  files.FileType === "application/pdf" || files.FileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files.FileType === 'application/msword' ?
                                                                                    <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + 'Images/TNAImages/' + files.FileName} download><i class="fa fa-download"></i> </a> :

                                                                                    <i className="btn btn-info fa fa-eye" title="View Logo"
                                                                                      // disabled={props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                                      //     ? true : props.props.props.location.pathname !== '/TNAEdit' && props.TaskStatus === 3 ? true : false}
                                                                                      onClick={
                                                                                        event => ViewFile(event, files.FileName)
                                                                                      }></i>
                                                                                }
                                                                                
                                                                                &nbsp;
                                                                                {Buttonview !== "View" &&
                                                                                  <p title='Delete File' onClick={(e) => RemoveFiles(e, fileindex)}
                                                                                    disabled={props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true)
                                                                                      ? true : (props.props.props.location.pathname === '/TNAReport' || props.props.props.location.pathname === '/Dashboard') && props.TaskStatus === 3 ? true : false}
                                                                                    className="btn btn-danger btn-xs delete">
                                                                                    <i className="fa fa-trash-o"></i>


                                                                                  </p>
                                                                                }
                                                                              </td>
                                                                            </tr>
                                                                            {

                                                                              <div> {
                                                                                isOpenimg && (

                                                                                  <Lightbox mainSrc={window.$APIBaseURL + 'Images/TNAImages/' + getImagename}
                                                                                    alt="bg image"
                                                                                    onCloseRequest={
                                                                                      () => setIsOpenimg(false)
                                                                                    } />
                                                                                )
                                                                              } </div>
                                                                            }
                                                                          </div>
                                                                        ))
                                                                        : ''
                                                                    }
                                                                  </div>
                                                                </div>
                                                              </tr>
                                                            </tbody>

                                                        }

                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr> : ''
                                            }
                                          </Fragment>
                                          : ''
                                      }
                                    </Fragment>

                                  } </tbody>
                                </table>

                              </div>

                            </div>
                            <br></br>
                            <div className="row">
                              <div className="col-sm-12">
                                <button type="button" className="btn btn-success pull-right" onClick={() => SaveProtoFitSms()}
                                  disabled={props.TaskStatus === 3 && (props.editProtoFitSmsSubmission === false || chkDataExists === true) || buttonLoader
                                    ? true : (props.props.props.location.pathname === '/TNAReport' || props.props.props.location.pathname === '/Dashboard') && props.TaskStatus === 3 ? true : false}
                                >

                                  <i className="fa fa-check right"></i> &nbsp; {(props.props.props.location.pathname === '/TNAReport' || props.props.props.location.pathname === '/Dashboard') ? 'Save' : (props.editProtoFitSmsSubmission === false || chkDataExists === true) ? 'Save' : 'Update'}
                                </button>
                              </div>
                            </div>
                          </div>


                          {
                            ShowImportPopup === true ?
                              <Modal show={ShowImportPopup} size="md" dragable backdrop="static" keyboard={false} onHide={() => modalPopupClose()}>
                                <Modal.Header closeButton>
                                  <Modal.Title>
                                    AWB and Date
                                  </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                    <thead>
                                      <tr>
                                        <th style={{ width: "350px" }}>AWB<span className="text-danger">*</span></th>
                                        <th style={{ width: "300px" }}>Date<span className="text-danger">*</span></th>
                                        {/* <th styles={{ width: "100px" }}>Action</th> */}
                                      </tr>

                                    </thead>
                                    <tbody>

                                      <tr>
                                        <td>
                                          <span className='input-icon icon-right'>
                                            <input type="text"
                                              placeholder="AWB Name"
                                              id={"AWBName"}
                                              name="AWBName"
                                              value={inputFields[0].AWBName}
                                              style={{ border: submittedawb && inputFields[0].AWBName === '' ? '1px solid red' : '' }}
                                              autoFocus
                                              maxLength="25"
                                              className={'form-control'}
                                              onChange={event => handlepopupchange(event, 'AWBName')}
                                            // disabled={ChildField.DependencyID === 0 ? true : false}
                                            />
                                          </span>

                                        </td>
                                        <td>
                                          <DatePicker className="form-control" name="AWBDate"
                                            id={
                                              "AWBDate"
                                            }
                                            selected={
                                              inputFields[0].AWBDate
                                            }
                                            onChange={event => handlepopupchange(event, 'AWBDate')}
                                            dateFormat="dd/MM/yyyy"
                                            peekNextMonth
                                            style={{ border: submittedawb && inputFields[0].AWBDate === '' ? '1px solid red' : '' }}
                                            dropdownMode="scroll"
                                            autoComplete="off"
                                            customInput={<CustomInputAWB />}
                                            isClearable={inputFields[0].AWBDate === null ? false : true}
                                          />


                                          {/* <span className="input-icon icon-right">
                                                                                        <input id={"AWBDate"} name="AWBDate"
                                                                                            value={inputFields[0].AWBDate} placeholder="DD/MM/YYYY"
                                                                                            type="date" autoComplete="off" onKeyDown={(e) => e.preventDefault()}
                                                                                            className={'form-control'}
                                                                                            style={{ border: submittedawb && inputFields[0].AWBDate === '' ? '1px solid red' : '' }}
                                                                                            onChange={event => handlepopupchange(event, 'AWBDate')}
                                                                                        />
                                                                                    </span> */}
                                        </td>

                                      </tr>


                                    </tbody>
                                  </table>
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button variant="success" onClick={() => SaveAwbnoDate()}>
                                    <i className="fa fa-check right"></i>&nbsp;
                                    Save
                                  </Button>
                                </Modal.Footer>
                              </Modal>
                              : ''
                          }

                          {
                            getmodalpopup === true ?

                              <Modal show={getmodalpopup === true} size="md" dragable backdrop="static" keyboard={false} onHide={() => handlePopupClose()}
                              >

                                <Modal.Header closeButton>
                                  <Modal.Title>Sample Size and Qty</Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{ overflowX: "hidden", height: "540px", paddingRight: "1px", margin: '0px' }}>
                                  <table style={{ width: "550px" }} className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                    <thead>
                                      <tr>
                                        <th style={{ width: "350px" }}>Sizes</th>
                                        <th style={{ width: "160px" }}>Qty<span className="text-danger">*</span></th>
                                        {/* <th styles={{ width: "200px" }}>Action</th> */}
                                      </tr>

                                    </thead>
                                    <tbody>
                                      {
                                        getsizeqty.map((item, index) => {
                                          return (
                                            <>
                                              <tr>
                                                <td>
                                                  <span className="input-icon icon-right">

                                                    {item.Sizename}


                                                  </span>
                                                </td>
                                                <td>
                                                  <span className='input-icon icon-right'>
                                                    <input type="text"
                                                      placeholder="Qty"
                                                      id={"Qty"}
                                                      name="Qty"
                                                      onChange={event => handleSizeQtyChange(index, event, 'Qty')}
                                                      value={item.Qty}
                                                      style={{ border: submittedpopup && !item.Qty ? '1px solid red' : '' }}
                                                      autoFocus
                                                      maxLength="10"
                                                      className={'form-control'}
                                                      onKeyPress={event => RestrictFirstZero(event)}
                                                    // disabled={ChildField.DependencyID === 0 ? true : false}
                                                    />
                                                  </span>

                                                </td>

                                              </tr>
                                            </>

                                          )
                                        })
                                      }
                                    </tbody>
                                  </table>


                                </Modal.Body>
                                <Modal.Footer>
                                  <button type="button" className="btn btn-success" onClick={savesizeqty}>
                                    <i className="fa fa-check right"></i>&nbsp;
                                    Save
                                  </button>
                                </Modal.Footer>

                              </Modal> : ''
                          }


                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            getID.showPopupDelete ? <SweetAlertPopup data={
              getID.Params
            }
              deleteCallback={Delcallback}
              showpopup={true}
              pageActionId={ProtoFitSMSSubmissionApprovalId}
              Msg={props.ParentTask === "SMS Submission" ? 'SMS Submission Deleted Successfully' : props.ParentTask === "Proto Sample Submission" ? "Proto Sample Submission Deleted Successfully" : 'Fit Sample Submission Deleted Successfully'} /> : null
          }
          {loader}
        </Fragment >
      </div >
    </>


  );
};

export default ProtoFitSMSSubmission_Order;