import axios from "axios";
import authHeader from "../auth-header";

class AqlService {

    getAllAqlList(Id) {
        return axios.get(window.$APIBaseURL + "Master/getAllAqlList?SNo=" + Id, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    getAllAqlDpList(Id) {
        return axios.get(window.$APIBaseURL + "Master/getAllAqlDpList?Sno=" + Id, {
            headers: {
                authorization: authHeader()
            }
        });
    }


    InsertUpdateAqlGrd(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateAqlGrd',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
    InsertUpdateAql(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateAql',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

}


export default new AqlService()