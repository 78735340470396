import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import $ from "jquery";
import { fullSidebarMenuVisible } from "../../actions/auth";

const HeaderButton = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [getMax, setMax] = useState();
  const dispatch = useDispatch();

  // const location=useLocation();

  const refreshPage = (e) => {
    window.location.reload(false);
  };

  const sideBarClick = (e) => {
    $("#sidebar").toggleClass("hide");
    $(".sidebar-toggler").toggleClass("active");
    if ($(".sidebar-toggler").hasClass("active")) {
      dispatch(fullSidebarMenuVisible(false));
    } else {
      dispatch(fullSidebarMenuVisible(true));
    }
    return false;
  };

  const fullScreenClick = (e) => {
    var element = document.documentElement;
    if (!$("body").hasClass("full-screen")) {
      setMax("OPEN");
      loadfullscreen(element);
    } else {
      $("body").removeClass("full-screen");
      $("#fullscreen-toggler").removeClass("active");
      if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
      // else if (document.exitFullscreen) {
      //   document.exitFullscreen();
      // }
      if (getMax === "OPEN") {
        loadfullscreen(element);
        setMax("CLOSE");
      }
    }
  };

  function loadfullscreen(element) {
    $("body").addClass("full-screen");
    $("#fullscreen-toggler").addClass("active");
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  }

  // const fullScreenClick = (e) => {
  //   var element = document.documentElement;
  //   if (!$('body').hasClass("full-screen")) {
  //     $('body').addClass("full-screen");
  //     $('#fullscreen-toggler').addClass("active");
  //       if (element.requestFullscreen) {
  //         element.requestFullscreen();
  //       } else if (element.mozRequestFullScreen) {
  //         element.mozRequestFullScreen();
  //       } else if (element.webkitRequestFullscreen) {
  //         element.webkitRequestFullscreen();
  //       } else if (element.msRequestFullscreen) {
  //         element.msRequestFullscreen();
  //       }
  //   } else {
  //     $('body').removeClass("full-screen");
  //     $('#fullscreen-toggler').removeClass("active");
  //     if (document.exitFullscreen) {
  //       document.exitFullscreen();
  //     } else if (document.mozCancelFullScreen) {
  //       document.mozCancelFullScreen();
  //     } else if (document.webkitExitFullscreen) {
  //       document.webkitExitFullscreen();
  //     }
  //   }
  // };

  if (!currentUser) {
    return <Redirect to="/login" />;
  }

  return (
    <div className="header-buttons">
      {/* <a className="sidebar-toggler" onClick={sideBarClick} title="Menu Hide">
        <i className="fa fa-arrows-h"></i>
      </a>
      <a
        className="refresh"
        id="refresh-toggler"
        onClick={refreshPage}
        title="Refresh"
      >
        <i className="glyphicon glyphicon-refresh"></i>
      </a> */}
      <a
        className="fullscreen"
        id="fullscreen-toggler"
        onClick={fullScreenClick}
        title="Full Screen"
      >
        <i className="glyphicon glyphicon-fullscreen"></i>
      </a>
    </div>
  );
};

export default HeaderButton;
