import {
    PANTONETYPE_LOADING,
    RETRIEVE_PANTONETYPE,
    DELETE_PANTONETYPE,
} from "../actions/types";

const initialState = {
    isLoadingPantoneType: true,
    pantoneTypeList: [],
};

const pantoneTypeReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case PANTONETYPE_LOADING:
            return {
                ...state,
                isLoadingPantoneType: payload
            };
        case RETRIEVE_PANTONETYPE:
            return { ...state, pantoneTypeList: payload };
        case DELETE_PANTONETYPE:
            const results = state.pantoneTypeList.filter(c => c.pantoneTypeID !== payload.pantoneTypeID);
            return {
                ...state,
                pantoneTypeList: results
            };

        default:
            return state;
    }
};
export default pantoneTypeReducer;