import React, { useState, useEffect, useRef, Fragment } from "react";
import ReactNotification from 'react-notifications-component';
import Nodify from "../../../../Common/ReactNotification";
import columnInformationService from "../../../../../services/Master/ColumnInformationService";
import validator from 'validator'
import SearchRetain from "../../../../Common/SearchRetain";
import { useSelector } from "react-redux";
import SelectCombobox from 'react-select';
const AddColumnInformationList = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);

    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    if (menulist !== undefined) {
        activeMenu = menulist.filter(i => i.menuName === "Column Information");
    }
    //end enable menu

    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getColumnInformation, setColumnInformation] = useState([{ Id: 0, ModuleID: 0, TableName: '', ColumnName: '', DisplayName: '' }]);
    const [ExistingList, setExistingList] = useState([]);
    const [ButtonName, setButtonName] = useState("Save");
    const [HeaderName, setHeaderName] = useState("Add");
    const [getModuleList, setModuleList] = useState([]);

    function ColumnInformationListing(props) {
       
        let ColumnInformation = [...getColumnInformation];
        Array.from(document.querySelectorAll("input")).forEach(input => (input.value = ""));
        if (props.location.state.params.length !== 0) {
            if (props.location.state.params.Id !== "") {
                setButtonName("Update");
                setHeaderName("Edit");
                ColumnInformation.Id = props.location.state.params.moduleID;
                ColumnInformation.Name = validator.trim(props.location.state.params.moduleName);
                ColumnInformation.Operation = 2;
                return ColumnInformation;
            }
        } else {
            return ColumnInformation;
        }
    }

    useEffect(() => {
       
        let getColumnInformationList = ColumnInformationListing(props);
        setColumnInformation(getColumnInformationList)
        columnInformationService.getColumnInformationList(1, 0).then((response) => {
            if (response.data) {
                setExistingList(response.data);
            }
        });
    }, []);

    //Enter Error msg
    useEffect(() => {
       
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                //SaveBuyerInfo(event);
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);


    useEffect(() => {
       ;
        columnInformationService.loadModuleInformationList()
            .then((response) => {
                if (response.data) {
                    var uniqueimagesMSD = [...new Map(response.data.map(item =>
                        [item['label'], item])).values()];
                    setModuleList(uniqueimagesMSD);
                }
            })
            .catch((error) => {
                console.error("Error loading report module category list:", error);
            });
    }, []);

    const handleAddFields = (index) => {
        const values = [...getColumnInformation];

        const moduleIDToAdd = values.length > 0 ? values[0].ModuleID : 0;

        const newField = {
            Id: 0,
            ModuleID: moduleIDToAdd,
            TableName: '',
            ColumnName: '',
            DisplayName: '',
        };

        if (values[0].ModuleID !== 0 && values[index].TableName.trim() !== '' && values[index].ColumnName.trim() !== '' && values[index].DisplayName.trim() !== '') {

            values.push(newField);
            setColumnInformation(values);
            setSubmitted(false);
        }
        else {
            Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
            setSubmitted(true);
        }
    };


    const handleInputChange = (index, event) => {
       
        const values = [...getColumnInformation];
        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }

        if (event.target.name === "TableName") {
            values[index].TableName = inputText;
        }
        else if (event.target.name === "ColumnName") {
            values[index].ColumnName = inputText;
        }
        else if (event.target.name === "DisplayName") {
            values[index].DisplayName = inputText;
        }

        setColumnInformation(values);
    };

    const handleRemoveFields = index => {
        const values = [...getColumnInformation];
        values.splice(index, 1);
        setColumnInformation(values);
    };

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/ColumnInformationList');
        } else {
            window.location.reload();

        }
    }


    const handleDrpDownChange = (selectedOption) => {
       ;

        if (!selectedOption) {
            setColumnInformation(prevState => prevState.map(row => ({
                ...row,
                ModuleID: 0
            })));
            return;
        }

        const newModuleID = selectedOption.value;

        if (!CheckDuplicate(newModuleID)) {
            Nodify('Warning!', 'warning', 'This Module Name is already exists.');
            setColumnInformation(prevState => prevState.map(row => ({
                ...row,
                ModuleID: 0
            })));

            return;
        }


        setColumnInformation(prevState => prevState.map(row => ({
            ...row,
            ModuleID: newModuleID
        })));
    };


    const CheckDuplicate = (newModuleID) => {
       ;
        if (ExistingList) {
            for (var i = 0; i < ExistingList.length; i++) {

                if (ExistingList[i].moduleID === newModuleID) {
                    return false;
                }
            }
            return true;
        };
    }

    const SaveColumnInformationList = (e) => {
       
        const values = [...getColumnInformation];
        setButtonLoader(true);
        e.preventDefault();
        if (values.length === 1 && (values[0].ModuleID === 0 || values[0].TableName === '' || values[0].ColumnName === '' || values[0].DisplayName === '')) {
            setButtonLoader(false);
            setSubmitted(true);
            ValidationPopup("Please fill atleast one Column details.");
        } else {
            let Isvalid = false;
            for (var j = 0; j < values.length; j++) {
                if (values[j].ModuleID === 0 || values[j].TableName === '' || values[j].ColumnName === '' || values[j].DisplayName === '') {
                    Isvalid = true
                }
            }
            if (!Isvalid) {
                setSubmitted(false);



                let InputCommonMaster = { ModuleID: values[0].ModuleID, Createdby: currentUser.employeeinformationID, MasterInformation: getColumnInformation };
                columnInformationService.insertUpdateColumnInformationGrid(InputCommonMaster).then(res => {
                    var page = "Remove";
                    SearchRetain(page);
                    if (res.data.outputResult === "1") {
                        PageRedirect('Add');
                    } else if (res.data.outputResult === "0") {
                        setButtonLoader(false);
                        ValidationPopup("Error Occured!");
                    }
                });
            } else {
                setButtonLoader(false);
                setSubmitted(true);
                ValidationPopup("Please fill all mandatory(*) fields.");
            }
        }
    }
    const PageRedirect = (Func) => {
        props.history.push({ pathname: "/ColumnInformationList", state: { message: Func } });
    }

    let styles = {
        control: styles => ({ ...styles, border: '1px solid red', innerHeight: '100px', outerHeight: '100px' }),
    }
    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
        return false;
    }

    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">{HeaderName} Column Information	</h3>

                        </div>
                        <div className="widget-body">

                            <div className="row row_left10">

                                <div className="col-lg-3">
                                    <label>Module Name<span className="text-danger">*</span></label>
                                    <SelectCombobox
                                        className="basic-single"
                                        name="ModuleID"
                                        id="ModuleID"
                                        isDisabled={false}
                                        isLoading={false}
                                        isSearchable={true}
                                        isClearable={true} // Enable clearable option
                                        placeholder="Select Module Name"
                                        styles={submitted && getColumnInformation[0].ModuleID === 0 ? styles : ''}
                                        options={getModuleList}
                                        onChange={handleDrpDownChange}

                                        value={getModuleList.find(option => option.value === getColumnInformation[0].ModuleID) || null}

                                    />

                                </div>
                            </div>
                            <div id="registration-form">
                                <form>
                                    {
                                        getColumnInformation.map((inputField, index) => (
                                            <Fragment key={`${inputField}~${index}`}>
                                                <div className="row row_left10 mt-10">

                                                    <div className="col-sm-3 divder_style">
                                                        <div className="form-group">
                                                            {index === 0 && <label >

                                                                Table Name<span className="text-danger">*</span>
                                                            </label>}
                                                            <span className="input-icon icon-right">
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"TableName_" + index}
                                                                    name="TableName"
                                                                    placeholder="Enter Table Name"
                                                                    maxLength="50"
                                                                    value={inputField.TableName}
                                                                    onChange={event => handleInputChange(index, event)}
                                                                    style={{ border: submitted && !inputField.TableName ? '1px solid red' : '' }}
                                                                    autoFocus
                                                                />
                                                            </span>

                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3 divder_style">
                                                        <div className="form-group">
                                                            {index === 0 && <label >
                                                                Column Name<span className="text-danger">*</span>
                                                            </label>}
                                                            <span className="input-icon icon-right">
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"ColumnName" + index}
                                                                    name="ColumnName"
                                                                    placeholder="Enter Column Name"
                                                                    maxLength="50"
                                                                    value={inputField.ColumnName}
                                                                    onChange={event => handleInputChange(index, event)}
                                                                    style={{ border: submitted && !inputField.ColumnName ? '1px solid red' : '' }}
                                                                    autoFocus
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3 divder_style">
                                                        <div className="form-group">
                                                            {index === 0 && <label >
                                                                Display Name<span className="text-danger">*</span>
                                                            </label>}
                                                            <span className="input-icon icon-right">
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"DisplayName" + index}
                                                                    name="DisplayName"
                                                                    placeholder="Enter Display Name"
                                                                    maxLength="50"
                                                                    value={inputField.DisplayName}
                                                                    onChange={event => handleInputChange(index, event)}
                                                                    style={{ border: submitted && !inputField.DisplayName ? '1px solid red' : '' }}
                                                                    autoFocus
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3 paddingstatic">
                                                        {
                                                            getColumnInformation.length !== 1 && (
                                                                <div className="col-lg-1_own col-xs-12">
                                                                    <button type="button" className="btn btn-danger"
                                                                        style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none', marginTop: index === 0 ? '25px' : '11px' }}

                                                                        title="Delete Report Module Category" onClick={() => handleRemoveFields(index)}>
                                                                        <i className="fa fa-trash-o"></i>
                                                                    </button>
                                                                </div>
                                                            )}
                                                        {
                                                            getColumnInformation.length === (index + 1) && (
                                                                <div className="col-lg-1_own col-xs-12">
                                                                    <button type="button" className="btn btn-success" style={{ marginTop: index === 0 ? '25px' : '11px' }} title="Add ReportModuleCategory" onClick={() => handleAddFields(index)}>
                                                                        <i className="fa fa-plus"></i>
                                                                    </button>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </Fragment>
                                        ))
                                    }
                                    <div className="col-sm-12">
                                        <div className="buttons-preview" align="right">
                                            <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                                <i className="fa fa-arrow-circle-left"></i>
                                                &nbsp;Back
                                            </span>
                                            <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                                <i className="fa fa-times"></i>
                                                &nbsp;Reset
                                            </span>
                                            <button type="button" className="btn btn-success" disabled={buttonLoader}
                                                onClick={SaveColumnInformationList}
                                            >
                                                <i className="fa fa-check right"></i>&nbsp;
                                                {ButtonName}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddColumnInformationList;