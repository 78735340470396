import {
    GROUPTYPE_LOADING,
    RETRIEVE_GROUPTYPE,
    DELETE_GROUPTYPE,
} from "./types";

import GroupTypeService from "../services/Master/GroupTypeService";

const groupTypeLoading = (isStatus) => ({
    type: GROUPTYPE_LOADING,
    payload: isStatus,
});

export const deleteGrouptype = (groupTypeID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_GROUPTYPE,
            payload: groupTypeID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrieveGrouptype = () => async (dispatch) => {
    try {
        dispatch(groupTypeLoading(true));
        const res = await GroupTypeService.GetGroupTypeList();
        dispatch({
            type: RETRIEVE_GROUPTYPE,
            payload: res.data,
        });
        dispatch(groupTypeLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(groupTypeLoading(false));
    }
};