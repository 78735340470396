import axios from "axios";
import authHeader from "../auth-header";

class GeneralService {
    GetGeneralLabdipList(IsProduction) {
        return axios.get(window.$APIBaseURL + "General/GetGeneralLabdipList?IsProduction=" + IsProduction, {
            headers: {
                authorization: authHeader()
            }
        });
    }


    InsertGeneralLabdip(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'General/InsertGeneralLabdip',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    UpdateGeneralLabdipManualInfo(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'General/UpdateGeneralLabdipManualInfo',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
    InsertGenralLabdipTNA(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'General/InsertGenralLabdipTNA',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateGeneralLabdipSubmissionApproval(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'General/InsertUpdateGeneralLabdipSubmissionApproval',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }




    GetGenLapdibPantoneOrSwatchList(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'General/GetGenLapdibPantoneOrSwatchList',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetGenLabdipTNASubmissionAndApproval(genralLabdipTNAId, genralLabdipTNAProgramDetailId) {
        return axios.get(window.$APIBaseURL + "General/GetGenLabdipTNASubmissionAndApproval?GenralLabdipTNAId=" + genralLabdipTNAId + "&GenralLabdipTNAProgramDetailId=" + genralLabdipTNAProgramDetailId, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetGenLapdibTNAHolidayList(params) {
        return axios.get(window.$APIBaseURL + "General/GetGenLapdibTNAHolidayList?BrandID=" + params.BrandId + "&SupplierIDList=" + params.SupplierIdList + "&CompanyBuyerSupID=" + params.CompanyBuyerSupID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadFollowerCubeBuyerList(BuyerId, UserLoginID) {
        return axios.get(window.$APIBaseURL + "General/LoadFollowerCubeBuyerList?BuyerId=" + BuyerId + "&UserLoginID=" + UserLoginID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetGeneralLapdipDetailsViewList(params) {
        let SearchParams = {
            BuyerId: params.BuyerId, BrandId: params.BrandId, SeasonId: params.SeasonId,
            PantoneId: params.PantoneId, SearchText: params.SearchText, Operation: 0, StatusId: params.StatusId ? params.StatusId : null, ApprovalTypeId: params.ApprovalTypeId ? params.ApprovalTypeId : null, SupplierId: 0, StartDate: params.StartDate, EndDate: params.EndDate,
            IsProduction: params.IsProduction,
        };
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'General/GetGeneralLapdipDetailsViewList',
            data: SearchParams,
            headers: {
                authorization: authHeader()
            }
        });
    }
    //sampe
    GetGeneralLapdipSubmissionApprovalList(params) {
        let SearchParams = {
            BuyerId: params.BuyerId, BrandId: params.BrandId, SeasonId: params.SeasonId,
            PantoneId: params.PantoneId, SearchText: params.SearchText, Operation: params.Operation,
            StatusId: params.StatusId ? params.StatusId : null,
            SupplierId: params.SupplierId ? params.SupplierId : null, StartDate: params.StartDate, EndDate: params.EndDate,
            IsProduction: params.IsProduction
        };
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'General/GetGeneralLapdipSubmissionList',
            data: SearchParams,
            headers: {
                authorization: authHeader()
            }
        });

        // return axios.get(window.$APIBaseURL + "General/GetGeneralLapdipSubmissionList?BuyerId=" + params.BuyerId + "&BrandId=" + params.BrandId +
        //     "&SeasonId=" + params.SeasonId + "&PantoneId=" + params.PantoneId + "&SearchText=" + params.SearchText + "&Operation=" + params.Operation, {
        //     headers: {
        //         authorization: authHeader()
        //     }
        // });
    }


    GetSelectedLabdip(GenLapdipProgramInfoId) {
        return axios.get(window.$APIBaseURL + "General/GetSelectedLabdip?GenLapdipProgramInfoId=" + GenLapdipProgramInfoId, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetGenLabDipAlreadyMapList(params) {
        return axios.get(window.$APIBaseURL + "General/GetGenLabDipAlreadyMapList?BuyerID=" + params.BuyerID + "&BrandID=" + params.BrandID + "&PantoneID=" + params.PantoneID + "&PantoneTypeID=" + params.PantoneTypeID, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    LoadGeneralLabdipFabricDetailsList(GenLabdipId) {
        return axios.get(window.$APIBaseURL + "General/LoadGeneralLabdipFabricDetailsList?GenLabdipId=" + GenLabdipId, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadGeneralTNAApprovalSubmissionTaskHolderList(GenLabdipId) {
        return axios.get(window.$APIBaseURL + "General/LoadGeneralTNAApprovalSubmissionTaskHolderList?GenLabdipId=" + GenLabdipId, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetGeneralLapdibSubmissionandOrderInfoandManualList(Id, BuyerId, BrandId, SeasonId, SupplierId, Follower, TaskId, PantoneId, Operation,
        GenLapdipProgramInfoId) {

        return axios.get(window.$APIBaseURL + "General/GetGeneralLapdibSubmissionandOrderInfoandManualList?GenralLabdipTNASubmissionAndApprovalId=" + Id +
            "&BuyerId=" + BuyerId + "&BrandId=" + BrandId + "&SeasonId=" + SeasonId + "&SupplierId=" + SupplierId +
            "&Follower=" + Follower + "&TaskId=" + TaskId + "&PantoneId=" + PantoneId + "&Operation=" + Operation + "&GenLapdipProgramInfoId=" + GenLapdipProgramInfoId
            , {
                headers: {
                    authorization: authHeader()
                }
            });
    }

    // GetSelectedPurchaseOrder(PurchaseOrderID) {
    //     return axios.get(window.$APIBaseURL + "PurchaseOrder/GetSelectedPurchaseOrder?PurchaseOrderID=" + PurchaseOrderID, {
    //         headers: {
    //             authorization: authHeader()
    //         }
    //     });
    // }

    // LoadSupplierList() {
    //     return axios(window.$APIBaseURL + "PurchaseOrder/LoadSupplierList", {
    //         method: 'post',
    //         headers: {
    //             authorization: authHeader()
    //         }
    //     });
    // }

    UpdateGenralLabdipTNA(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'General/UpdateGenralLabdipTNA',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetGenLapdipTNAProgramStatusList(Params) {
        return axios.get(window.$APIBaseURL + "General/GetGenLapdipTNAProgramStatusList?GenLabdipId=" + Params.labdipId + "&GenLapdipProgramInfoId=" + Params.lapdipProgramInfoId + "&StatusId=" + Params.StatusId, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    DeleteMultipleGenLabdipApproval(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'General/DeleteMultipleGenLabdipApproval',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    //Strikeoff

    InsertGeneralStrikeoff(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'General/InsertGeneralStrikeoff',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }


    GetGeneralStrikeOffList(IsProduction) {
        return axios.get(window.$APIBaseURL + "General/GetGeneralStrikeOffList?IsProduction=" + IsProduction, {
            headers: {
                authorization: authHeader()
            }
        });
    }




    GetGenTNAStrikeOffReferenceList(params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'General/GetGenTNAStrikeOffReferenceList',
            data: params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadGLDTNATaskOwnerList(Params) {
        return axios.get(window.$APIBaseURL + "General/LoadGLDTNATaskOwnerList?TaskHolderID=" + Params.TaskHolderID + "&BuyerID=" + Params.BuyerID + "&UserLoginID=" + Params.UserLoginID + "&SupplierIdList=" + Params.SupplierIdList, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertGenralStrikeOffTNA(params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'General/InsertGenralStrikeOffTNA',
            data: params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    CheckGeneralStrikeoffDuplicate(Params) {
       ;
        return axios.get(window.$APIBaseURL + "General/CheckGeneralStrikeoffDuplicate?BuyerId=" + Params.BuyerId +
            "&BrandId=" + Params.BrandId + "&SeasonId=" + Params.SeasonId +
            "&StoryId=" + Params.StoryId + "&ThemeId=" + Params.ThemeId + "&StrikeoffId=" + Params.StrikeOffId + '&IsProduction=' + Params.IsProduction, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    CheckGeneralLabdibDuplicate(Params) {
       ;
        return axios.get(window.$APIBaseURL + "General/CheckGeneralLabdibDuplicate?BuyerId=" + Params.BuyerId +
            "&BrandId=" + Params.BrandId + "&SeasonId=" + Params.SeasonId +
            "&StoryId=" + Params.StoryId + "&ThemeId=" + Params.ThemeId + "&PantoneIdOrSwatchId=" + Params.PantoneIdOrSwatchId + '&IsProduction=' + Params.IsProduction, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    GetSelectedGenStrikeoff(GenStrikeoffId) {

        return axios.get(window.$APIBaseURL + "General/GetSelectedStrikeoff?GenStrikeoffId=" + GenStrikeoffId, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    //detaisl list
    getGeneralStrikeOffDetailsViewList(params) {
        let SearchParams = {
            BuyerId: params.BuyerId, BrandId: params.BrandId, SeasonId: params.SeasonId,
            StrikeOffId: params.StrikeOffId, ColorGroupID: params.ColorGroupID, SearchText: params.SearchText, Operation: 0, StatusId: params.StatusId ? params.StatusId : null, ApprovalTypeId: params.ApprovalTypeId ? params.ApprovalTypeId : null, SupplierId: 0, StartDate: params.StartDate, EndDate: params.EndDate,
            IsProduction: params.IsProduction
        };
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'General/GetGenStrikeOffDetailsViewList',
            data: SearchParams,
            headers: {
                authorization: authHeader()
            }
        });
    }
    //gestrike
    GetGeneralStrikeOffSubmissionApprovalGridList(params) {
       ;
        let SearchParams = {
            BuyerId: params.BuyerId, BrandId: params.BrandId, SeasonId: params.SeasonId,
            StrikeOffId: params.StrikeOffId, ColorGroupID: params.ColorGroupID, SearchText: params.SearchText, Operation: params.Operation,
            StatusId: params.StatusId ? params.StatusId : null,
            SupplierId: params.SupplierId ? params.SupplierId : null, StartDate: params.StartDate, EndDate: params.EndDate,
            IsProduction: params.IsProduction
        };

        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'General/GetGeneralStrikeOffSubmissionList',
            data: SearchParams,
            headers: {
                authorization: authHeader()
            }
        });
    }


    LoadGLDTNATaskOwnerList(Params) {
        return axios.get(window.$APIBaseURL + "General/LoadGLDTNATaskOwnerList?TaskHolderID=" + Params.TaskHolderID + "&BuyerID=" + Params.BuyerID + "&UserLoginID=" + Params.UserLoginID + "&SupplierIdList=" + Params.SupplierIdList, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    UpdateGenralStrikeOffTNA(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'General/UpdateGenralStrikeOffTNA',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetGenStrikeOffTNATASKSubmissionAndApproval(genStrikeOffSupplierTNAId, genStrikeOffDetailTNAId) {
        return axios.get(window.$APIBaseURL + "General/GetGenStrikeOffTNATASKSubmissionAndApproval?genStrikeOffSupplierTNAId=" + genStrikeOffSupplierTNAId + "&genStrikeOffDetailTNAId=" + genStrikeOffDetailTNAId, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetGeneralStrikeOffOrderInfoList(Id, BuyerId, BrandId, SeasonId, SupplierId, Follower, TaskId, strikeOffId, GenStrikeOffLogoID, GenStrikeOffId, Operation,
        GenStrikeOffRefernceID) {
        return axios.get(window.$APIBaseURL + "General/GetGenlStrikeOffSubmissionAndOrderAndManualList?GenralStrikeOffTNASubmissionAndApprovalId=" + Id +
            "&BuyerId=" + BuyerId + "&BrandId=" + BrandId + "&SeasonId=" + SeasonId + "&SupplierId=" + SupplierId +
            "&Follower=" + Follower + "&TaskId=" + TaskId + "&StrikeOffId=" + strikeOffId + "&GenStrikeOffLogoID=" + GenStrikeOffLogoID + "&GenStrikeOffId=" + GenStrikeOffId + "&Operation=" + Operation + "&GenStrikeOffRefernceID=" + GenStrikeOffRefernceID
            , {
                headers: {
                    authorization: authHeader()
                }
            });
    }

    GetGeneralStrikeoffDimensionList(GenStrikeOffMainId) {
        return axios.get(window.$APIBaseURL + "General/GetGeneralStrikeoffDimensionList?GenStrikeOffMainId=" + GenStrikeOffMainId, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetGeneralStrikeoffTaskHolderList(GenStrikeOffMainId) {
        return axios.get(window.$APIBaseURL + "General/GetGeneralStrikeoffTaskHolderList?GenStrikeOffMainId=" + GenStrikeOffMainId, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateGeneralStrikeOffSubmissionApproval(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'General/InsertUpdateGeneralStrikeOffSubmissionApproval',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
    UpdateGeneralStrikeOffManualInfo(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'General/UpdateGeneralStrikeOffManualInfo',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetGenStrikeOffTNARefernceStatusList(Params) {
        return axios.get(window.$APIBaseURL + "General/GetGenStrikeOffTNARefernceStatusList?GenStrikeOffId=" + Params.GenStrikeOffId + "&GenStrikeOffRefernceID=" + Params.GenStrikeOffRefernceID + "&StatusId=" + Params.StatusId + "&GenStrikeOffLogoID=" + Params.GenStrikeOffLogoID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    DeleteMultipleGenStrikeoffApproval(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'General/DeleteMultipleGenStrikeoffApproval',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetStrikeOffLogoInformationListInGeneral(StrikeoffID) {
        return axios.get(window.$APIBaseURL + "General/GetStrikeOffLogoInformationListInGeneral?StrikeoffID=" + StrikeoffID, {
            headers: {
                authorization: authHeader()
            }
        });
    }



    GetStrikeOffReferenceListInGeneral(StrikeoffID) {
        return axios.get(window.$APIBaseURL + "General/GetStrikeOffReferenceListInGeneral?StrikeoffID=" + StrikeoffID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    //Trim

    InsertGeneralTrim(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'General/InsertGeneralTrim',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }


    GetGeneralTrimList(IsProduction) {
        return axios.get(window.$APIBaseURL + "General/GetGeneralTrimList?IsProduction=" + IsProduction, {
            headers: {
                authorization: authHeader()
            }
        });
    }


    getGeneralTrimDetailsViewList(params) {
        let SearchParams = {
            BuyerId: params.BuyerId, BrandId: params.BrandId, SeasonId: params.SeasonId,
            TrimId: params.TrimId, ColorGroupID: params.ColorGroupID, SearchText: params.SearchText, Operation: 0,
            StatusId: params.StatusId ? params.StatusId : null, ApprovalTypeId: params.ApprovalTypeId ? params.ApprovalTypeId : null, SupplierId: 0, StartDate: params.StartDate, EndDate: params.EndDate,
            IsProduction: params.IsProduction
        };
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'General/GetGenTrimDetailsViewList',
            data: SearchParams,
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetGenTNATrimReferenceList(params) {
       ;
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'General/GetGenTNATrimReferenceList',
            data: params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadGLDTNATaskOwnerList(Params) {
        return axios.get(window.$APIBaseURL + "General/LoadGLDTNATaskOwnerList?TaskHolderID=" + Params.TaskHolderID + "&BuyerID=" + Params.BuyerID + "&UserLoginID=" + Params.UserLoginID + "&SupplierIdList=" + Params.SupplierIdList, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertGenralTrimTNA(params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'General/InsertGenralTrimTNA',
            data: params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    CheckGeneralTrimDuplicate(Params) {
       ;
        return axios.get(window.$APIBaseURL + "General/CheckGeneralTrimDuplicate?BuyerId=" + Params.BuyerId +
            "&BrandId=" + Params.BrandId + "&SeasonId=" + Params.SeasonId +
            "&StoryId=" + Params.StoryId + "&ThemeId=" + Params.ThemeId + "&TrimId=" + Params.TrimId + '&IsProduction=' + Params.IsProduction, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetSelectedGenTrim(GenTrimId) {

        return axios.get(window.$APIBaseURL + "General/GetSelectedTrim?GenTrimId=" + GenTrimId, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    GetGeneralTrimSubmissionApprovalGridList(params) {
        let SearchParams = {
            BuyerId: params.BuyerId, BrandId: params.BrandId, SeasonId: params.SeasonId,
            TrimId: params.TrimId, ColorGroupID: params.ColorGroupID, SearchText: params.SearchText, Operation: params.Operation,
            StatusId: params.StatusId ? params.StatusId : null,
            SupplierId: params.SupplierId ? params.SupplierId : null, StartDate: params.StartDate, EndDate: params.EndDate,
            IsProduction: params.IsProduction
        };

        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'General/GetGeneralTrimSubmissionList',
            data: SearchParams,
            headers: {
                authorization: authHeader()
            }
        });
    }


    LoadGLDTNATaskOwnerList(Params) {
        return axios.get(window.$APIBaseURL + "General/LoadGLDTNATaskOwnerList?TaskHolderID=" + Params.TaskHolderID + "&BuyerID=" + Params.BuyerID + "&UserLoginID=" + Params.UserLoginID + "&SupplierIdList=" + Params.SupplierIdList, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    UpdateGenralTrimTNA(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'General/UpdateGenralTrimTNA',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetGenTrimTNATASKSubmissionAndApproval(genTrimSupplierTNAId, genTrimDetailTNAId) {
        return axios.get(window.$APIBaseURL + "General/GetGenTrimTNATASKSubmissionAndApproval?genTrimSupplierTNAId=" + genTrimSupplierTNAId + "&genTrimDetailTNAId=" + genTrimDetailTNAId, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetGeneralTrimOrderInfoList(Id, BuyerId, BrandId, SeasonId, SupplierId, Follower, TaskId, trimId, GenTrimLogoID, GenTrimId, Operation,
        GenTrimRefernceID) {
        return axios.get(window.$APIBaseURL + "General/GetGenlTrimSubmissionAndOrderAndManualList?GenralTrimTNASubmissionAndApprovalId=" + Id +
            "&BuyerId=" + BuyerId + "&BrandId=" + BrandId + "&SeasonId=" + SeasonId + "&SupplierId=" + SupplierId +
            "&Follower=" + Follower + "&TaskId=" + TaskId + "&TrimId=" + trimId + "&GenTrimLogoID=" + GenTrimLogoID + "&GenTrimId=" + GenTrimId + "&Operation=" + Operation + "&GenTrimRefernceID=" + GenTrimRefernceID
            , {
                headers: {
                    authorization: authHeader()
                }
            });
    }

    GetGeneralTrimDimensionList(GenTrimMainId) {
        return axios.get(window.$APIBaseURL + "General/GetGeneralTrimDimensionList?GenTrimMainId=" + GenTrimMainId, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetGeneralTrimTaskHolderList(GenTrimMainId) {
        return axios.get(window.$APIBaseURL + "General/GetGeneralTrimTaskHolderList?GenTrimMainId=" + GenTrimMainId, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateGeneralTrimSubmissionApproval(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'General/InsertUpdateGeneralTrimSubmissionApproval',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
    UpdateGeneralTrimManualInfo(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'General/UpdateGeneralTrimManualInfo',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetGenTrimTNARefernceStatusList(Params) {
        return axios.get(window.$APIBaseURL + "General/GetGenTrimTNARefernceStatusList?GenTrimId=" + Params.GenTrimId + "&GenTrimRefernceID=" + Params.GenTrimRefernceID + "&StatusId=" + Params.StatusId + "&GenTrimLogoID=" + Params.GenTrimLogoID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    DeleteMultipleGenTrimApproval(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'General/DeleteMultipleGenTrimApproval',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }


    GetTrimLogoInformationListInGeneral(TrimID) {
        return axios.get(window.$APIBaseURL + "General/GetTrimLogoInformationListInGeneral?TrimID=" + TrimID, {
            headers: {
                authorization: authHeader()
            }
        });
    }



    GetTrimReferenceListInGeneral(TrimID) {
        return axios.get(window.$APIBaseURL + "General/GetTrimReferenceListInGeneral?TrimID=" + TrimID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadGenTNAReportList(Searchvalues) {

        let filterParams = {
            BuyerId: Searchvalues.BuyerId, BrandId: Searchvalues.BrandId, SeasonId: Searchvalues.SeasonId, SupplierId: Searchvalues.SupplierId,
            TaskTypeId: Searchvalues.TaskTypeId, TaskId: Searchvalues.TaskId, TaskTodoInProgressCompletedStatus: Searchvalues.TaskTodoInProgressCompletedStatus, StartDate: Searchvalues.StartDate, EndDate: Searchvalues.EndDate,
            Operation: Searchvalues.Operation, MainId: Searchvalues.MainId, ColorImageId: Searchvalues.ColorImageId, IsProduction: Searchvalues.IsProduction
        };

        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'General/GetGenTNAReportList',
            data: filterParams,
            headers: {
                authorization: authHeader()
            }
        });
    }


    getTNATaskDetailsList(genralLabdipTNAId, genralLabdipTNAProgramDetailId) {
        return axios.get(window.$APIBaseURL + "General/GetTNATaskDetailsList?genralLabdipTNAId=" + genralLabdipTNAId + "&genralLabdipTNAProgramDetailId=" + genralLabdipTNAProgramDetailId, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    getTNATaskReviceList(genralLabdipTNAId, genralLabdipTNAProgramDetailId) {
        return axios.get(window.$APIBaseURL + "General/GetTNATaskReviceList?genralLabdipTNAId=" + genralLabdipTNAId + "&genralLabdipTNAProgramDetailId=" + genralLabdipTNAProgramDetailId, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    getTNATaskRescheduleList(genralLabdipTNAId, genralLabdipTNAProgramDetailId) {
        return axios.get(window.$APIBaseURL + "General/GetTNATaskRescheduleList?genralLabdipTNAId=" + genralLabdipTNAId + "&genralLabdipTNAProgramDetailId=" + genralLabdipTNAProgramDetailId, {
            headers: {
                authorization: authHeader()
            }
        });
    }


    InsertGenTNATaskRevice(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'General/InsertGenTNATaskRevice',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
    InsertGenTNATaskReschedule(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'General/InsertGenTNATaskReschedule',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    getTNATaskDetailsList_Strikeoff(genStrikeOffSupplierTNAId, genStrikeOffDetailTNAId, colorGroupID) {
        return axios.get(window.$APIBaseURL + "General/GetTNATaskDetailsList_Strikeoff?genStrikeOffSupplierTNAId=" + genStrikeOffSupplierTNAId + "&genStrikeOffDetailTNAId=" + genStrikeOffDetailTNAId + "&colorGroupID=" + colorGroupID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    getTNATaskReviceList_Strikeoff(genStrikeOffSupplierTNAId, genStrikeOffDetailTNAId, colorGroupID) {
        return axios.get(window.$APIBaseURL + "General/GetTNATaskReviceList_Strikeoff?genStrikeOffSupplierTNAId=" + genStrikeOffSupplierTNAId + "&genStrikeOffDetailTNAId=" + genStrikeOffDetailTNAId + "&colorGroupID=" + colorGroupID, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    getTNATaskRescheduleList_Strikeoff(genStrikeOffSupplierTNAId, genStrikeOffDetailTNAId, colorGroupID) {
        return axios.get(window.$APIBaseURL + "General/GetTNATaskRescheduleList_Strikeoff?genStrikeOffSupplierTNAId=" + genStrikeOffSupplierTNAId + "&genStrikeOffDetailTNAId=" + genStrikeOffDetailTNAId + "&colorGroupID=" + colorGroupID, {
            headers: {
                authorization: authHeader()
            }
        });
    }


    InsertGenTNATaskRevice_Strikeoff(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'General/InsertGenTNATaskRevice_Strikeoff',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
    InsertGenTNATaskReschedule_Strikeoff(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'General/InsertGenTNATaskReschedule_Strikeoff',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    getTNATaskDetailsList_Trim(genTrimSupplierTNAId, genTrimDetailTNAId, colorGroupID) {

        return axios.get(window.$APIBaseURL + "General/GetTNATaskDetailsList_Trim?genTrimSupplierTNAId=" + genTrimSupplierTNAId + "&genTrimDetailTNAId=" + genTrimDetailTNAId + "&colorGroupID=" + colorGroupID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    getTNATaskReviceList_Trim(genTrimSupplierTNAId, genTrimDetailTNAId, colorGroupID) {
        return axios.get(window.$APIBaseURL + "General/GetTNATaskReviceList_Trim?genTrimSupplierTNAId=" + genTrimSupplierTNAId + "&genTrimDetailTNAId=" + genTrimDetailTNAId + "&colorGroupID=" + colorGroupID, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    getTNATaskRescheduleList_Trim(genTrimSupplierTNAId, genTrimDetailTNAId, colorGroupID) {
        return axios.get(window.$APIBaseURL + "General/GetTNATaskRescheduleList_Trim?genTrimSupplierTNAId=" + genTrimSupplierTNAId + "&genTrimDetailTNAId=" + genTrimDetailTNAId + "&colorGroupID=" + colorGroupID, {
            headers: {
                authorization: authHeader()
            }
        });
    }


    InsertGenTNATaskRevice_Trim(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'General/InsertGenTNATaskRevice_Trim',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
    InsertGenTNATaskReschedule_Trim(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'General/InsertGenTNATaskReschedule_Trim',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

}
export default new GeneralService();