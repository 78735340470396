import React, { useState, useEffect, Fragment, useRef, useMemo } from 'react';
import ReactNotification from 'react-notifications-component';
import { useSelector, useDispatch } from "react-redux";
import Nodify from "../../../Common/ReactNotification";
import SearchRetain from "../../../Common/SearchRetain";
import ColorDepthService from './../../../../services/Master/ColorDepthService';
import { retrieveColorDepth } from '../../../../actions/colordepth';

const ColorDepthRegister = (props) => {
    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getExisting, setExisting] = useState([]);

    const EmptyInputFields = { ColorDepthID: 0, ColorDepth: '' };
    const [inputFields, setInputFields] = useState([EmptyInputFields]);
    const [getExistingSet, setExistingSet] = useState([]);
    const { user: currentUser } = useSelector((state) => state.auth);
    // reducer
    const reducerState = useSelector((state) => state);
    const comments = reducerState.ColorDepthReducer.ColorDepthList.item1;
    const dispatch = useDispatch();
    const values = [...inputFields];

    const handleInputChange = (index, event) => {
        const values = [...inputFields];
        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }
        values[index].ColorDepth = inputText;
        setInputFields(values);
    }

    const handleRemoveFields = index => {
        const values = [...inputFields];
        values.splice(index, 1);
        setInputFields(values);
    }

    const handleAddFields = index => {
        const values = [...inputFields];
        if (values[index].ColorDepth.trim() !== '') {
            let isExisting = !!getExistingSet.find(d => d.colorDepth.trim().toLowerCase() === values[index].ColorDepth.trim().toLowerCase());
            if (isExisting) {
                Nodify('Warning!', 'warning', "This Color Depth is already exist.");
                values[index].ColorDepth = "";
                setInputFields(values);
                return false;
            }
            for (var j = 0; j < (inputFields.length); j++) {
                if (j !== index) {
                    if (values[j].ColorDepth.trim().toLowerCase() === values[index].ColorDepth.trim().toLowerCase()) {
                        Nodify('Warning!', 'warning', 'This Color Depth is already exist.');
                        values[index].ColorDepth = "";
                        setInputFields(values);
                        setSubmitted(true);
                        return false;
                    }
                }
            }
            values.push(EmptyInputFields);
            setInputFields(values);
            setSubmitted(false);
        }
        else {
            Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
            setSubmitted(true);
        }
    }


    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/ColorDepthList');
        } else {
            window.location.reload();
        }
    }

    const InsertColorDepth = (e) => {
        setButtonLoader(true);
        const values = [...inputFields];
        e.preventDefault();
        if (inputFields.length === 1 && inputFields[0].ColorDepth === '') {
            setButtonLoader(false);
            setSubmitted(true);
            ValidationPopup("Please fill atleast one color.");
        }
        else {
            let ExistingSet = [];
            for (var i = 0; i < (inputFields.length); i++) {
                if (inputFields[i].ColorDepth.trim() === '') {
                    Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
                    setSubmitted(true);
                    setButtonLoader(false);
                    return false;
                }
                else if (inputFields[i].ColorDepth.trim() !== '') {
                    if (ExistingSet.length == 0) {
                        ExistingSet.push({ ColorDepth: inputFields[i].ColorDepth });
                    }
                    else if (ExistingSet.length > 0) {
                        let checkExistence = ExistingSet.filter((data) => {
                            if (data.ColorDepth.toLowerCase() == inputFields[i].ColorDepth.toLowerCase()) {
                                return data;
                            }
                        });
                        if (checkExistence.length > 0) {
                            setSubmitted(true);
                            setButtonLoader(false);
                            inputFields[i].ColorDepth = "";
                            Nodify('Warning!', 'warning', "This Color Depth is already exist.");
                            setInputFields(values);
                            return false;
                        }
                        else {
                            setSubmitted(false);
                            setButtonLoader(true);
                            ExistingSet.push({ ColorDepth: inputFields[i].ColorDepth });

                        }
                    }

                }
                let isExisting = !!getExistingSet.find(d => d.colorDepth.trim().toLowerCase() === inputFields[i].ColorDepth.trim().toLowerCase());
                if (isExisting) {
                    values[i].ColorDepth = '';
                    Nodify('Warning!', 'warning', "This Color Depth is already exist.");
                    setSubmitted(true);
                    setButtonLoader(false);
                    setInputFields(values);
                    return false;
                }
                else {

                }
            }
            let Params = { Createdby: currentUser.employeeinformationID, ColorDepthInfo: inputFields };
            ColorDepthService.InsertColorDepth(Params).then(res => {
                var page = "Remove";
                SearchRetain(page);
                let Func = 'Add';
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    PageRedirect(Func);
                }
            });
        }
    }

    const PageRedirect = (Func) => {
        props.history.push({ pathname: "/ColorDepthList", state: { message: Func } });
    }

    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
        return false;
    }

    useEffect(() => {
        if (comments != undefined && comments.length > 0) {
            setExistingSet(comments);
        }
    }, [comments]);

    useEffect(() => {
        dispatch(retrieveColorDepth());
    }, [dispatch]);

    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <div className="widget flat radius-bordered">
                            <div className="widget-header heading_box_style">
                                <h3 className="heading_style_common">Add Color Depth</h3>
                            </div>
                            <div className="widget-body">
                                <div id="registration-form">
                                    <form>
                                        {
                                            inputFields.map((inputField, index) => (
                                                <Fragment key={
                                                    `${inputField}~${index}`
                                                }>
                                                    <div className="row row_left10">
                                                        <div className="col-sm-5 divder_style">
                                                            <div className="form-group">
                                                                <label>Color Depth Name<span className="text-danger">*</span>
                                                                </label>
                                                                <span className="input-icon icon-right">
                                                                    <input type='test' className='form-control'
                                                                        style={{ border: submitted && !inputField.ColorDepth ? '1px solid red' : '' }} name="testitem"
                                                                        id="testitem" rows="10" placeholder="Enter Color Depth" onChange={event => handleInputChange(index, event)}
                                                                        autoComplete="off" maxLength="100" value={inputField.ColorDepth} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3 paddingstatic">
                                                            {
                                                                inputFields.length === (index + 1) && (
                                                                    <div className="col-lg-1_own col-xs-12">
                                                                        <button type="button" className="btn btn-success yarn" title="Add Color Depth"
                                                                            onClick={() => handleAddFields(index)}>
                                                                            <i className="fa fa-plus"></i>
                                                                        </button>
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                inputFields.length !== 1 && (
                                                                    <div className="col-lg-1_own col-xs-12">
                                                                        <button type="button" className="btn btn-danger yarn" onClick={() => handleRemoveFields(index)}
                                                                            title="Delete Color Depth">
                                                                            <i className="fa fa-trash-o"></i>
                                                                        </button>
                                                                    </div>
                                                                )}

                                                        </div>
                                                    </div>
                                                </Fragment>
                                            ))
                                        }
                                        <div className="col-sm-12">
                                            <div className="buttons-preview" align="right">
                                                <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                                    <i className="fa fa-arrow-circle-left"></i>
                                                    &nbsp;Back
                                                </span>
                                                <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                                    <i className="fa fa-times"></i>
                                                    &nbsp;Reset
                                                </span>
                                                <button type="button" className="btn btn-success" disabled={buttonLoader} onClick={InsertColorDepth}>
                                                    <i className="fa fa-check right"></i>&nbsp;
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ColorDepthRegister;