import React, { useState, useEffect } from "react";

const PageCount = ({ onPageCount }) => {
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(50);

    useEffect(() => {
        const PathForColour = localStorage.getItem("Path");
        const searchData = JSON.parse(localStorage.getItem("Search"));
        const pageData = JSON.parse(localStorage.getItem("Pagination"));
        // if (pageData && searchData) {
        //     if (pageData.Page === searchData.Page) {
        //         // if (pageData) {
        //         //     if (pageData.Page) {
        //         setITEMS_PER_PAGE(pageData.PageItems);
        //         onPageCount(pageData.PageItems);
        //     }

        if (pageData) {// && searchData) {
            //if (pageData.Page === searchData.Page) {
            // if (pageData) {
            //     if (pageData.Page) {
            setITEMS_PER_PAGE(pageData.PageItems);
            onPageCount(pageData.PageItems);
            //}

        } else {
            setITEMS_PER_PAGE(PathForColour === 'ScheduleReportDetail' ? 10 : 50);
            onPageCount(PathForColour === 'ScheduleReportDetail' ? 10 : 50);
        }
    }, []);

    const onCountChange = value => {
        setITEMS_PER_PAGE(parseInt(value));
        onPageCount(value);
    };

    return (
        <div className="table-toolbar pull-left">
            <label>
                <select className="form-control input-sm" id="PageCountValue" value={ITEMS_PER_PAGE} onChange={e => onCountChange(e.target.value)} >
                    {/* <option value="5">5</option> */}
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="500">500</option>
                </select>
            </label>

        </div>
    );
};

export default PageCount;
