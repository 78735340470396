import axios from "axios";
import authHeader from "../auth-header";

class ReportModuleCategoryService {




  getReportModuleCategoryList() {
   ;
    return axios.get(window.$APIBaseURL + "Master/GetReportModuleCategoryList?ReportModuleCategoryID=0", {
      headers: {
        authorization: authHeader()
      }
    });
  }

  InsertUpdateReportModuleCategoryGrid(Params) {
   
    return axios({
      method: 'post',
      url: window.$APIBaseURL + 'Master/InsertUpdateReportModuleCategoryGrid',
      data: Params,
      headers: {
        authorization: authHeader()
      }
    });
  }

  AddReportModuleCategory(Params) {
   
    return axios({
      method: 'post',
      url: window.$APIBaseURL + 'Master/InsertUpdateDelReportModuleCategory',
      data: Params,
      headers: {
        authorization: authHeader()
      }
    });
  }

}

export default new ReportModuleCategoryService()