import {
    TESTSTANDARD_LOADING,
    RETRIEVE_TESTSTANDARD,
    DELETE_TESTSTANDARD,
} from "../actions/types";

const initialState = {
    isLoadingTestStandard: true,
    TestStandardList: [],
};

const TestStandardReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case TESTSTANDARD_LOADING:
            return {
                ...state,
                isLoadingTestStandard: payload
            };
        case RETRIEVE_TESTSTANDARD:
            return { ...state, TestStandardList: payload };
        case DELETE_TESTSTANDARD:
            const results = state.TestStandardList.filter(c => c.id !== payload.QCCommentPoint);
            return {
                ...state,
                TestStandardList: results
            };

        default:
            return state;
    }
};
export default TestStandardReducer;