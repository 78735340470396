import React, { Fragment, useState, useMemo, useEffect } from "react";
import Reactselect from 'react-select';
import FinalinspectionService from "../../../services/FinalInspection/FinalinspectionService";
import FinalinspecUpload from "../../Common/FinalinspecUpload";
import TrimService from "../../../services/Style/TrimService";
import Lightbox from "react-image-lightbox"
import Nodify from "../../Common/ReactNotification";

const Packing = ({ props, BasicDetails, PackingCallback, IsValidationCallback, PurchaseorderPassVal }) => {
    const [getPackingPlanValue, setPackingPlanValue] = useState([]);
    const [packingExpandState, setpackingExpandState] = useState({});
    const [packingexpandedRows, setpackingExpandedRows] = useState([]);
    const [getCommonResult, setCommonResult] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [getImagename, setImagename] = useState();
    const [isOpenUpl, setIsOpenUpl] = useState(false);
    const [getImagenameUpl, setImagenameUpl] = useState();
    const [submitted, setSubmitted] = useState(false);
    useEffect(() => {
        setSubmitted(IsValidationCallback);
    }, [IsValidationCallback === true]);

    const inpsectionId = JSON.parse(localStorage.getItem("InspectionID"));
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red', innerHeight: '100px', outerHeight: '100px' }),
    }

    useEffect(() => {

        if (props.location.state.params.ParentTask === "ProductionFLDetails") {
            props.location.state.params.styleID = props.location.state.params.NewDetails.styleID
        }

        if (props.location.state.params.TaskStatus === 2) {
            let StyleAndColorEdit = [];
            FinalinspectionService.GetTNAFinalinspecMainInfo(props.location.state.params.TNAInspectionID, props.location.state.params.details.tnaid).then((res) => {
                if (res.data.finalInsPackingList.length > 0) {
                    res.data.finalInsPackingList.map((y, index) => {
                        StyleAndColorEdit.push({
                            TNAFinalInspecPackingID: y.tnaFinalInspecPackingID,
                            packageItemID: y.packageItemID,
                            packingName: y.packingName,
                            ResultID: y.packingResult,
                            packingFindingsRemarks: y.findingsRemarks,
                            packingMainIndex: y.packingMainIndex,
                            ExpectedImages: [],
                            UploadImagesList: []
                        })

                        res.data.finalInsPackingFileList.filter(file => file.packageItemID === y.packageItemID).map((xy, indexs) => {
                            StyleAndColorEdit[index].ExpectedImages.push({
                                TNAFinalInspecpackingFileID: xy.tnaFinalInspecpackingFileID,
                                packageItemID: xy.packageItemID,
                                packingFileName: xy.packingFileName,
                                packingFilepath: xy.packingFilepath,
                            })
                        })
                        res.data.finalInsPackingSubList.filter(x => x.packingMainIndex === y.packingMainIndex).map((sub, i) => {
                            StyleAndColorEdit[index].UploadImagesList.push({
                                TNAFinalInspecpackingSubID: sub.tnaFinalInspecpackingSubID,
                                UploadedImagePath: sub.uploadedImagePath,
                                MainIndex: sub.packingMainIndex,
                                ImageIndex: sub.imageIndex,
                            })

                        })
                    })
                    setPackingPlanValue(StyleAndColorEdit)
                    PackingCallback(StyleAndColorEdit)
                }
                // else {
                //     //if (ArtWorksInspectionID === 0) {
                //     let Multiselecteddate = []
                //     if (getExfacStrikeoffList.length > 0) {
                //         Multiselecteddate = getExfacStrikeoffList
                //     } else {
                //         Multiselecteddate = BasicDetails.multiselecteddatas.filter(x => x.isSelected === 1)
                //     }

                //     let StrikeOffSaveData = []
                //     StrikeoffService.GetStyleStrikeOffPrintColorList(props.location.state.params.styleID).then((res) => {
                //         Multiselecteddate.map((y, N) => {
                //             res.data.colorGroup.map((x, index) => {
                //                 if (x.styleStrikeOffSkuNames.includes(y.skuName) === true) {
                //                     StrikeOffSaveData.push({
                //                         TNAFinalInspecArtworkID: 0,
                //                         ResultID: 0,
                //                         FindingsRemarks: "",
                //                         StrikeOffName: x.strikeoffFinalInspe,
                //                         StrikeOffID: x.strikeOffID,
                //                         StrikeOffCode: x.strikeOffCode,
                //                         StyleStrikeOffSKUID: x.styleStrikeOffSku,
                //                         StyleStrikeOffSKUName: x.styleStrikeOffSkuNames,
                //                         ApprovalType: x.approvalType,
                //                         MainIndex: N,
                //                         ImagesList: [{
                //                             TNAFinalInspecArtworkSubID: 0,
                //                             UploadedImagePath: "",
                //                             skuFrontPicPath: x.strikeoffLogoPath,
                //                             MainIndex: N,
                //                             ExpectedFilename: x.strikeOffName
                //                         }]
                //                     })
                //                 }
                //             })
                //         })

                //         let uniquevalues = [...new Map(StrikeOffSaveData.map(item =>
                //             [item['StrikeOffName'], item])).values()];
                //         SetArtWorksValue(uniquevalues)
                //         artWorkscallback(uniquevalues)
                //     })
                //     // }
                // }
            })

        }

        // let CommonResult = [];
        // FinalinspectionService.getCommonResultList(0).then((res) => {
        //     if (res.data) {
        //         res.data.map((x) => {
        //             CommonResult.push({
        //                 value: x.resultID,
        //                 label: x.resultName
        //             })
        //         })
        //         setCommonResult(CommonResult);
        //     }
        // })
        let CommonResult = [];
        FinalinspectionService.getCommonResultList(0).then((res) => {
            if (res.data) {
                res.data.map((element) => {

                    let LabelColor = '';
                    if (element.resultID === 1) {
                        LabelColor = <><span style={{ color: "green" }}><b>Ok</b></span></>
                    }
                    else if (element.resultID === 2) {
                        LabelColor = <><span style={{ color: "Red" }}><b>Not ok</b></span></>
                    }
                    else if (element.resultID === 3) {
                        LabelColor = <><span style={{ color: "blue" }}><b>Not Applicable</b></span></>
                    }

                    CommonResult.push({
                        value: element.resultID,
                        label: LabelColor,
                        subLabel: element.value,
                    })
                })
                setCommonResult(CommonResult);
            }
        })
    }, [props.location.state.params.TaskStatus === 2 && PurchaseorderPassVal])

    useEffect(() => {

        if (props.location.state.params.ParentTask === "ProductionFLDetails") {
            BasicDetails.buyerName = BasicDetails.NewDetails.buyerName
            BasicDetails.seasonName = BasicDetails.NewDetails.seasonName
            BasicDetails.brandName = BasicDetails.NewDetails.brandName
            BasicDetails.buyerID = BasicDetails.NewDetails.buyerID
            BasicDetails.brandID = BasicDetails.NewDetails.brandID
            BasicDetails.seasonID = BasicDetails.NewDetails.seasonID
            BasicDetails.supplierID = BasicDetails.NewDetails.supplierID
            BasicDetails.supplierName = BasicDetails.NewDetails.supplierName
            BasicDetails.styleID = BasicDetails.NewDetails.styleID
            BasicDetails.styleName = BasicDetails.NewDetails.styleName
            BasicDetails.TnaID = BasicDetails.details.tnaid
            //BasicDetails.PurchaseOrderSkuID = BasicDetails.NewDetails.purchaseOrderSkuID
        }

        (async () => {
            if (props.location.state.params.TaskStatus === 1) {
               
                if (inpsectionId !== null) {
                    let StyleAndColorEdit = [];
                    await FinalinspectionService.GetTNAFinalinspecMainInfo(inpsectionId, props.location.state.params.details.tnaid).then((res) => {
                        if (res.data.finalInsPackingList.length > 0) {
                            res.data.finalInsPackingList.map((y, index) => {
                                StyleAndColorEdit.push({
                                    TNAFinalInspecPackingID: y.tnaFinalInspecPackingID,
                                    packageItemID: y.packageItemID,
                                    packingName: y.packingName,
                                    ResultID: y.packingResult,
                                    packingFindingsRemarks: y.findingsRemarks,
                                    packingMainIndex: y.packingMainIndex,
                                    ExpectedImages: [],
                                    UploadImagesList: []
                                })
                                res.data.finalInsPackingFileList.filter(file => file.packageItemID === y.packageItemID).map((xy, indexs) => {
                                    StyleAndColorEdit[index].ExpectedImages.push({
                                        TNAFinalInspecpackingFileID: xy.tnaFinalInspecpackingFileID,
                                        packageItemID: xy.packageItemID,
                                        packingFileName: xy.packingFileName,
                                        packingFilepath: xy.packingFilepath,
                                    })
                                })
                                res.data.finalInsPackingSubList.filter(x => x.packingMainIndex === y.packingMainIndex).map((sub, i) => {
                                    StyleAndColorEdit[index].UploadImagesList.push({
                                        TNAFinalInspecpackingSubID: sub.tnaFinalInspecpackingSubID,
                                        UploadedImagePath: sub.uploadedImagePath,
                                        MainIndex: sub.packingMainIndex,
                                        ImageIndex: sub.imageIndex,
                                    })

                                })
                            })
                            setPackingPlanValue(StyleAndColorEdit)
                            PackingCallback(StyleAndColorEdit)
                        }
                    })
                }
                else {
                    if (inpsectionId === null) {
                       
                        let StyleAndColorEdit = [];
                        if (props.location.state.params.TaskStatus === 1) {
                            await FinalinspectionService.GetTNAFinalinspecPacking(1, BasicDetails.buyerID, BasicDetails.brandID,
                                BasicDetails.seasonID, BasicDetails.TnaID,
                                props.location.state.params.ParentTask === "ProductionFLDetails" ? BasicDetails.NewDetails.purchaseOrderSkuID : BasicDetails.PurchaseOrderSkuID).then((res) => {
                                    if (res.data) {
                                        let SubIndex = 0;
                                        res.data.packingRes1.map((x, index) => {
                                            StyleAndColorEdit.push({
                                                TNAFinalInspecPackingID: 0,
                                                packageItemID: x.packageItemID,
                                                packingName: x.packageItemName,
                                                ResultID: 0,
                                                packingFindingsRemarks: '',
                                                packingMainIndex: index,
                                                ExpectedImages: [],
                                                UploadImagesList: []
                                            })

                                            // StyleAndColorEdit[index].ImagesList.push({
                                            //     TNAFinalInspecpackingSubID: 0,
                                            //     UploadedImagePath1: "",
                                            //     MainIndex: index,
                                            //     SubIndex: SubIndex,
                                            //     ExpectedImages: [],
                                            //     UploadImagesList: []
                                            // })
                                            res.data.packingRes2.filter(y => y.packageItemID === x.packageItemID).map((xy, indexs) => {
                                                StyleAndColorEdit[index].ExpectedImages.push({
                                                    TNAFinalInspecpackingFileID: 0,
                                                    packageItemID: xy.packageItemID,
                                                    packingFileName: xy.fileName,
                                                    packingFilepath: xy.filePath,
                                                })
                                            })
                                            StyleAndColorEdit[index].UploadImagesList.push({
                                                TNAFinalInspecpackingSubID: 0,
                                                UploadedImagePath: "",
                                                MainIndex: index,
                                                ImageIndex: 0
                                            })
                                            SubIndex = SubIndex + 1;
                                        })
                                        setPackingPlanValue(StyleAndColorEdit)
                                        PackingCallback(StyleAndColorEdit)
                                    }

                                })

                        }
                    }
                }
            }
        })();

    }, [inpsectionId !== null])

    useEffect(() => {
        if (inpsectionId === null) {
            let StyleAndColorEdit = [];
            if (props.location.state.params.TaskStatus === 1) {
                FinalinspectionService.GetTNAFinalinspecPacking(1, BasicDetails.buyerID,
                    BasicDetails.brandID, BasicDetails.seasonID, BasicDetails.TnaID,
                    props.location.state.params.ParentTask === "ProductionFLDetails" ? BasicDetails.NewDetails.purchaseOrderSkuID : BasicDetails.PurchaseOrderSkuID).then((res) => {
                        if (res.data) {
                            let SubIndex = 0;
                            res.data.packingRes1.map((x, index) => {
                                StyleAndColorEdit.push({
                                    TNAFinalInspecPackingID: 0,
                                    packageItemID: x.packageItemID,
                                    packingName: x.packageItemName,
                                    ResultID: 0,
                                    packingFindingsRemarks: '',
                                    packingMainIndex: index,
                                    ExpectedImages: [],
                                    UploadImagesList: []
                                })

                                // StyleAndColorEdit[index].ImagesList.push({
                                //     TNAFinalInspecpackingSubID: 0,
                                //     UploadedImagePath1: "",
                                //     MainIndex: index,
                                //     SubIndex: SubIndex,
                                //     ExpectedImages: [],
                                //     UploadImagesList: []
                                // })
                                res.data.packingRes2.filter(y => y.packageItemID === x.packageItemID).map((xy, indexs) => {
                                    StyleAndColorEdit[index].ExpectedImages.push({
                                        TNAFinalInspecpackingFileID: 0,
                                        packageItemID: xy.packageItemID,
                                        packingFileName: xy.fileName,
                                        packingFilepath: xy.filePath,
                                    })
                                })
                                StyleAndColorEdit[index].UploadImagesList.push({
                                    TNAFinalPackingImageID: 0,
                                    UploadedImagePath: "",
                                    MainIndex: index,
                                    ImageIndex: 0
                                })
                                SubIndex = SubIndex + 1;
                            })
                            setPackingPlanValue(StyleAndColorEdit)
                            PackingCallback(StyleAndColorEdit)
                        }

                    })

            }
        }
    }, [PurchaseorderPassVal])

    const ImageCallback = (value, index, childindex, FeildName) => {

        var imagevalues = [...getPackingPlanValue]
        let profile = imagevalues !== '' ? "/Style/SKU/" : '';
        if (FeildName === 'Image1') {
            imagevalues[index].UploadImagesList[childindex].MainIndex = index;
            imagevalues[index].UploadImagesList[childindex].UploadedImagePath = profile + value;
        }
        // else {
        //     imagevalues[index].ImagesList[childindex].MainIndex = index;
        //     imagevalues[index].ImagesList[childindex].UploadedImagePath2 = profile + value;
        // }
        //imagevalues[index].UploadedImage = value
        setPackingPlanValue(imagevalues)
        //handleCommonResultInputChange()
    }

    const handleCommonResultInputChange = (index, e, FeildName) => {

        const values = [...getPackingPlanValue];
        let inputText = ""
        if (FeildName === "PackingResult") {
            if (e !== null) {
                values[index].ResultID = e.value;
                values[index].packingResultName = e.label;
                values[index].packingMainIndex = index;
            } else {
                values[index].ResultID = 0;
                values[index].packingResultName = '';
            }
        } else {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            values[index].packingFindingsRemarks = inputText;
        }
        setPackingPlanValue(values);
        PackingCallback(values)
    }

    function ViewFile(event, filename, ViewName) {
        // event.preventDefault();
        if (ViewName === 'Image1') {
            setIsOpen(true);
            setImagename(filename);
        } else {
            setIsOpenUpl(true);
            setImagenameUpl(filename);
        }
    }

    const deleteFile = (index, childindex) => {
        var imagevalues = [...getPackingPlanValue]
        imagevalues[index].UploadImagesList[childindex].UploadedImagePath1 = "";
        setPackingPlanValue(imagevalues);
        PackingCallback(imagevalues)
    }

    function handleAddFields(index, childindex, child) {

        let Values = [];
        var imagevalues = [...getPackingPlanValue]
        let isvalid = true;
        if (imagevalues[index].UploadImagesList[childindex].UploadedImagePath === "") {
            isvalid = false;
            Nodify('Warning!', 'warning', 'Please Upload the Image');
        }

        if (isvalid) {
            imagevalues[index].UploadImagesList.push({ TNAFinalInspecpackingSubID: 0, UploadedImagePath: "", MainIndex: index, SubIndex: childindex + 1 })
        }
        setPackingPlanValue(imagevalues);
        PackingCallback(imagevalues)
    }

    function handleRemoveFields(index, childindex, length) {

        var imagevalues = [...getPackingPlanValue]
        if (length === 1) {
            imagevalues[index].UploadImagesList[childindex].UploadedImagePath = '';
        } else {
            imagevalues[index].UploadImagesList.splice(childindex, 1);
        }
        setPackingPlanValue(imagevalues);
        PackingCallback(imagevalues)
    }
    const handlePackingExpandRow = (rowNumber) => {
        setpackingExpandedRows([]);
        let currentExpandedRows;
        let isRowExpanded = false;
        currentExpandedRows = packingexpandedRows;
        isRowExpanded = currentExpandedRows.includes(rowNumber);
        let obj = {};
        isRowExpanded ? (obj[rowNumber] = false) : (obj[rowNumber] = true);
        setpackingExpandState(obj);
        currentExpandedRows = [];
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== rowNumber) :
            currentExpandedRows.concat(rowNumber);
        setpackingExpandedRows(newExpandedRows);
    }

    return (
        <table className="table table-striped table-bordered table-hover dataTable no-footer">
            <thead>
                <tr>
                    <th className="InsMainInfoDetailsCol">Details</th>
                    <th className="InsMainInfoResultsCol">Packing Result<span className="text-danger"> *</span></th>
                    <th className="InsMainInfoRemarksCol">Finding / Remarks<span className="text-danger"> *</span></th>
                </tr>
            </thead>
            <tbody>
                {
                    getPackingPlanValue.map((comment, index) => (
                        <Fragment>
                            <tr key={
                                comment.index
                            }>
                                <td title={comment.packingName} className="cut_text">
                                    {<i
                                        className={
                                            packingExpandState[index] ?
                                                'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                        } onClick={event => handlePackingExpandRow(index)}
                                    ></i>}&nbsp;
                                    <b>{comment.packingName}</b></td>
                                <td>   <Reactselect className="basic-single" name="Result"
                                    id={
                                        "Result" + index
                                    }
                                    isDisabled={props.location.state.params.Action === 'View' ? true : false}
                                    isLoading={false}
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={(event) => handleCommonResultInputChange(index, event, 'PackingResult')}
                                    options={getCommonResult}
                                    styles={submitted && comment.ResultID === 0 ? styles : ''}
                                    value={getCommonResult.filter(function (option) {
                                        return option.value === comment.ResultID;
                                    })}

                                ></Reactselect></td>
                                <td><input type="text" className="form-control" name={index + "_txtRemarks"}
                                    id={index + "_txtRemarks"}
                                    maxLength="200"
                                    disabled={props.location.state.params.Action === 'View' ? true : false}
                                    style={{ border: submitted && (comment.ResultID === 2 && comment.packingFindingsRemarks === '') ? '1px solid red' : '' }}
                                    value={comment.packingFindingsRemarks} autoComplete="off"
                                    onChange={(event) => handleCommonResultInputChange(index, event, 'FindingRemarks')} /></td>
                            </tr><>
                                {
                                    packingexpandedRows.includes(index) ?
                                        <tr>
                                            <td colspan="3">
                                                <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                    <tbody>
                                                        <Fragment>
                                                            <tr>
                                                                <td className="table-width-40">
                                                                    {

                                                                        <td className="table-width-40">
                                                                            {<span>Expected :</span>}
                                                                            <br />
                                                                            <br />

                                                                            {comment.ExpectedImages.map((Images, imgindex) => (
                                                                                <>
                                                                                    < img src={window.$APIBaseURL + Images.packingFilepath} alt="" width="150px" height="150px" title={Images.packingFileName} />
                                                                                    &nbsp; &nbsp;
                                                                                    <i className="btn btn-info fa fa-eye Packingeyeadjust" title="View Logo" style={{ marginRight: "6px" }}
                                                                                        onClick={
                                                                                            event => ViewFile(event, Images.packingFilepath, 'Image1')
                                                                                        }
                                                                                    ></i>
                                                                                </>
                                                                            ))

                                                                            }
                                                                        </td>

                                                                    }
                                                                </td>

                                                                <td className="table-width-60">
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            Found :
                                                                        </div>

                                                                    </div>
                                                                    <div className="row">
                                                                        {comment.UploadImagesList.map((uplimages, uplindex) => (

                                                                            <div className="text-center col-lg-6">
                                                                                <span className="text-danger">Click on the image to upload new</span><br /><br />
                                                                                <div >
                                                                                    <FinalinspecUpload UploadCallback={(e) => ImageCallback(e, index, uplindex, 'Image1')} PageName='Style/SKU' ShowDiv="1" filePath={uplimages.UploadedImagePath} />
                                                                                </div>
                                                                                {uplimages.UploadedImagePath !== '' ? <div className="row">
                                                                                    <div className="col-md-7">
                                                                                        {/* <i className="btn btn-danger fa fa-trash-o FloatRight" title="Delete Logo" style={{ marginRight: "0px" }}
                                                                                            onClick={event => deleteFile(index, uplindex, "UploadedImagePath2")}></i>&nbsp; */}

                                                                                    </div>
                                                                                </div> : ''}
                                                                                <span className="TnaFinaspecwrkmans" style={{ left: "120px" }}>
                                                                                    {uplimages.UploadedImagePath !== '' ? <button onClick={event => ViewFile(event, uplimages.UploadedImagePath, 'Image2')} className="btn btn-info fa fa-eye" style={{ padding: "5px 6px", width: "7%" }}>
                                                                                        <i title="View Logo" style={{ marginRight: "6px" }}
                                                                                        ></i>
                                                                                    </button> : ''}
                                                                                    &nbsp;
                                                                                    {props.location.state.params.Action !== 'View' ? <button type="button" style={{ display: getPackingPlanValue[index].UploadImagesList.length < 2 ? '' : 'none' }} className="btn btn-xs btn-success" title="Add packing Image"
                                                                                        onClick={() => handleAddFields(index, uplindex)}
                                                                                    >
                                                                                        <i className="fa fa-plus"></i>
                                                                                    </button> : ''}
                                                                                    &nbsp;
                                                                                    <button type="button"
                                                                                        className="btn btn-xs btn-danger" title="Delete packing Image" style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}
                                                                                        onClick={() => handleRemoveFields(index, uplindex, getPackingPlanValue[index].UploadImagesList.length)}
                                                                                    >
                                                                                        <i className="fa fa-trash-o"></i>
                                                                                    </button>
                                                                                </span>
                                                                            </div>
                                                                        ))
                                                                        }
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        </Fragment>

                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr> : ""
                                }</>
                            <div> {
                                isOpen && (

                                    <Lightbox mainSrc={window.$APIBaseURL + getImagename}
                                        alt="bg image"
                                        onCloseRequest={
                                            () => setIsOpen(false)
                                        } />
                                )
                            } </div>
                            <div> {
                                isOpenUpl && (

                                    <Lightbox mainSrc={window.$APIBaseURL + 'Images' + getImagenameUpl}
                                        alt="bg image"
                                        onCloseRequest={
                                            () => setIsOpenUpl(false)
                                        } />
                                )
                            } </div>
                        </Fragment>
                    ))
                }
                {
                    getPackingPlanValue.length === 0 ?
                        <tr>
                            <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
                        </tr> : ''
                }
            </tbody>
        </table>
    )
}

export default React.memo(Packing);