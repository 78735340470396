import React, { useState, useEffect } from "react";
import Nodify from "../../../Common/ReactNotification";
import SearchRetain from "../../../Common/SearchRetain";
import ReactNotification from 'react-notifications-component';
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import { useSelector } from "react-redux";
import QACommentPointService from "../../../../services/Master/QACommentPointService";

const QACommentPointEdit = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const [getQACommentInfo, setQACommentInfo] = useState({});
    const [buttonLoader, setButtonLoader] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    function BindCommentValue(props) {
        let CommentInfoValue = { Operation: 2, ID: 0, Comment: '', Createdby: currentUser.employeeinformationID, };
        if (props.location.state !== undefined) {
            if (props.location.state.params.id !== 0) {
                CommentInfoValue.Operation = 2;
                CommentInfoValue.ID = props.location.state.params.id;
                CommentInfoValue.Comment = props.location.state.params.comment;
                CommentInfoValue.Createdby = currentUser.employeeinformationID;
                return CommentInfoValue;
            }
        }
    }

    useEffect(() => {
        let Commentinfo = BindCommentValue(props);
        setQACommentInfo(Commentinfo);
    }, []);

    //Enter Error msg
    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                //SaveBuyerInfo(event);
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/QACommentPointList",
            state: {
                message: Func,
            },
        }
        );
    };

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/QACommentPointList')
        } else {
            window.location.reload();
        }
    }

    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
    };

    const handleChange = (e) => {
       
        let Value = { ...getQACommentInfo };
        let InputText = '';
        if (e.target.name === "Comment") {
            if (e.target.value.trim() !== '') {
                setSubmitted(true);
                InputText = e.target.value.trim();
            }
            else {
                setSubmitted(false);
            }
            Value[e.target.name] = InputText;
        }
        setQACommentInfo(Value);
    }

    const SaveSeason = (e) => {
        setButtonLoader(true);
        e.preventDefault();
        if (getQACommentInfo.Comment.trim() === '') {
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'QA Comment Point is required');
        }
        if (getQACommentInfo.Comment.trim()) {
            getQACommentInfo.Comment = getQACommentInfo.Comment.trim();
            QACommentPointService.InsertUpdateQACommentPoints(getQACommentInfo).then(res => {
                var page = "Remove";
                SearchRetain(page);
                let Func = 'Add';
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "-2") {
                    getQACommentInfo.Comment = '';
                    setQACommentInfo(getQACommentInfo);
                    Nodify('Warning!', 'warning', 'This Measurement Point Name is already exist.');
                }
                setButtonLoader(false);
            });
        }
    }

    return (
        <div className="page-body">
            <ReactNotification />

            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">
                                Edit QA Comment Point
                            </h3>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <Form>
                                    <div className="row">
                                        <div className="form-group col-sm-4">
                                            <label htmlFor="MeasurePointName">
                                                QA Comment Point<span className="text-danger">*</span>
                                            </label>
                                            <span className="input-icon icon-right">
                                                <textarea type="text"
                                                    autoFocus
                                                    id="txtFindingsOrRemarks"
                                                    name="Comment"
                                                    placeholder="Enter Findings / Remarks"
                                                    //maxLength="300"
                                                    value={getQACommentInfo.Comment}
                                                    autoComplete="off"
                                                    onChange={handleChange}
                                                    style={{ height: "70px", width: "100%", border: submitted && !getQACommentInfo.Comment ? '1px solid red' : '' }}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div
                        className="buttons-preview"
                        align="right"
                        style={{ marginTop: "15px" }}
                    >
                        <div className="buttons-preview" align="right">
                            <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                            </span>
                            <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                <i className="fa fa-times"></i>&nbsp;Reset
                            </span>
                            <button
                                type="submit"
                                className="btn btn-success"
                                onClick={SaveSeason}
                                disabled={buttonLoader}
                            >

                                <i className="fa fa-check right"></i> &nbsp;Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QACommentPointEdit;