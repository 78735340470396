import React, { useState, useEffect, useMemo,Fragment } from "react";
import { TableHeader, Pagination, Search, PageCount } from "../../Datatable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import SweetAlertPopup from '../../Common/SweetAlertPopup';
import { useDispatch, useSelector } from "react-redux";
import CommonService from '../../../services/Common/CommonService';
import { Redirect } from 'react-router-dom';
import Nodify from "../../Common/ReactNotification";
import SearchRetain from "../../Common/SearchRetain";
import StateService from "../../../services/Master/StateService"
import { retrieveState } from "../../../actions/State";
import { StateID } from "../../Common/PageActionNumber";
const StateList = (props) => {
    const [getPlaceholder] = useState("State/Country");
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [getID, setID] = useState({ showPopupDelete: false, Params: {} });
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    const dispatch = useDispatch();

    //reducer
    const reducerState = useSelector((state) => state);
    const { user: currentUser } = reducerState.auth;
    const comments = reducerState.state.StateList;

    let isLoadingState = reducerState.state.isLoadingState;

    useEffect(() => {

        let StateID = 0;
        dispatch(retrieveState(StateID));
        const state1 = props.location.state
        if (state1 !== undefined && state1 != null) {
            let Msg;
            let Type = 'success';
            let title = 'Success!';
            if (state1.message === 'Edit') {
                Msg = 'State/country updated successfully.';
            } else if (state1.message === 'Add') {
                Msg = 'State/country added successfully.';
            } else {
                Msg = 'Error occured';
                Type = 'warning';
                title = 'Error!';
            }
            window.history.replaceState(null, '')
            Nodify(title, Type, Msg);
        }
        var page = getPlaceholder;
        var setConstraint = SearchRetain(page);
        if (setConstraint !== undefined) {
            setCurrentPage(setConstraint.CurrentPage);
            setITEMS_PER_PAGE(setConstraint.PageItems);
        }
    }, [dispatch]);
    useEffect(() => {
        if (!isLoadingState) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingState]);
    const headers = [
        { name: "#", field: "", sortable: false },
        { name: "State", field: "stateName", sortable: true },
        { name: "Country", field: "countryName", sortable: true },
        { name: "Created Date", field: "createddate", sortable: true },
        { name: "Action", field: "", sortable: false, },
    ];
    const commentsData = useMemo(() => {

        let computedComments = comments;
        if (search) {
            computedComments = computedComments.filter(comment => comment.stateName.toLowerCase().includes(search.toLowerCase())
                || comment.countryName.toLowerCase().includes(search.toLowerCase()));
        }
        setTotalItems(computedComments.length);

        // Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort((a, b) => {
              const valueA = a[sorting.field];
              const valueB = b[sorting.field];
            
              // Check if values are numeric
              const isNumericA = !isNaN(valueA);
              const isNumericB = !isNaN(valueB);
            
              if (isNumericA && isNumericB) {
                // If both values are numeric, compare as numbers
                return reversed * (parseFloat(valueA) - parseFloat(valueB));
              } else {
                // If one or both values are non-numeric, compare as strings
                return reversed * valueA.toString().localeCompare(valueB.toString());
              }
            });
              
      }

        // Current Page slice
        let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
        if (records.length === 0 && currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
        return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
    }, [
        comments,
        currentPage,
        search,
        sorting,
        ITEMS_PER_PAGE
    ]);

    if (!currentUser) {
        return <Redirect to="/login" />;
    }

    const Delcallback = (value) => {
        if (value === true) {
            dispatch(retrieveState(0))
        }
        setID({ showPopupDelete: false, Params: [] });
    }
    const pageRedirect = (dataParams, redirect, page) => {

        localStorage.setItem('Pagination', JSON.stringify({ CurrentPage: currentPage, Page: page, PageItems: ITEMS_PER_PAGE }));
        if (redirect === 'Add') {
            props.history.push('/AddState', { params: [] });
        } else if (redirect === 'Edit') {
            props.history.push(`/EditState`, { params: dataParams });
        } else {

            let Params = {
                Operation: 70,
                Id: dataParams.stateID,
            };
            CommonService.CheckMappedItem(Params).then(res => {
                if (res.data.outputResult === "-2") {
                    let Title = "Warning!";
                    let message = "This State is already mapped.";
                    let type = "warning";
                    Nodify(Title, type, message);
                }
                else {
                    setID({ showPopupDelete: true, Params: dataParams });
                }
            });
        }
    };
    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className="row">
                    <div className="col-xs-12 col-md-12">
                        <div className="widget">
                            <div className="widget-header ">
                                <span className="widget-caption">State/Country List</span>
                            </div>
                            <div className="widget-body" style={{ paddingBottom: '4%' }}>
                                <div id="simpledatatable_wrapper" className="dataTables_wrapper form-inline no-footer">
                                    <div className="table-toolbar pull-left">
                                        <Search onSearch={
                                            value => {
                                                setSearch(value);
                                                setCurrentPage(1);
                                            }
                                        }
                                            placeholder={getPlaceholder} props={props} />

                                    </div>
                                    <div className="table-toolbar pull-right">
                                        &nbsp;
                                        <span className="btn btn-primary" onClick={() => pageRedirect('', 'Add', getPlaceholder)} title="Add State">
                                            <i className="fa fa-plus"></i>&nbsp;Add
                                        </span>
                                    </div>

                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                        <TableHeader headers={headers} onSorting={(field, order) => setSorting({ field, order })} />
                                        <tbody>
                                            {
                                                commentsData.map((comment,i)=> (
                                                    <Fragment key={i}>
                                                    <tr key={comment.stateID}>
                                                        <td style={{width:'25px'}}>
                                                            {i+1}
                                                        </td>
                                                        <td>{
                                                            comment.stateName
                                                        }</td>
                                                        <td>{
                                                            comment.countryName
                                                        }</td>
                                                        <td>{
                                                            comment.createddate
                                                        }</td>
                                                        <td>
                                                            <span title='Edit State' onClick={() => pageRedirect(comment, 'Edit', getPlaceholder)}
                                                                className="btn btn-info btn-xs edit">
                                                                <i className="fa fa-edit"></i>
                                                            </span>
                                                            &nbsp;
                                                            <span title='Delete State' onClick={() => pageRedirect(comment, '', getPlaceholder)}
                                                                className="btn btn-danger btn-xs delete">
                                                                <i className="fa fa-trash-o"></i>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    </Fragment>
                                                ))
                                            }
                                            {
                                                totalItems === 0 ?
                                                    <tr>
                                                        <td colSpan="5" className='norecordfound'><span>No record found</span></td>
                                                    </tr> : ''
                                            }
                                        </tbody>
                                    </table>

                                    <PageCount onPageCount={
                                        value => {
                                            setITEMS_PER_PAGE(value);
                                            setCurrentPage(1);
                                        }
                                    } />
                                    <Pagination total={totalItems}
                                        itemsPerPage={
                                            parseInt(ITEMS_PER_PAGE)
                                        }
                                        currentPage={currentPage}
                                        onPageChange={
                                            page => setCurrentPage(page)
                                        } />
                                </div>
                            </div>
                            {
                                getID.showPopupDelete ? <SweetAlertPopup data={
                                    getID.Params
                                }
                                    deleteCallback={Delcallback}
                                    showpopup={true}
                                    pageActionId={StateID}
                                    Msg={"State/Country deleted successfully."} /> : null
                            }

                        </div>
                    </div>
                </div>
            </div>
            {loader}
        </>
    )
}

export default StateList