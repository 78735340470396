/* eslint-disable import/no-anonymous-default-export */
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  ACTIVE_MENUS,
  SIDE_BAR_MENU_VISIBLE,
  FULL_SIDE_BAR_MENU_VISIBLE,
  REQUESTOTP_FORGOTPASSWORD,
  ERASE_FORGOTPASSWORD_OTP
} from "../actions/types";

const user = JSON.parse(localStorage.getItem("user"));
const storeMenuList = JSON.parse(localStorage.getItem("UserMenuList"));
const initialState = user
  ? {

    isLoggedIn: true, user,
    UserMenuList: storeMenuList,
    activeMenuList:
    {
      ParentName: 'Dashboard',
      ChildName: '',
      SubChildName: '',
      ParentDisplayName: 'Dashboard',
      ChildDisplayName: '',
      SubChildDisplayName: 'Dashboard',
      ChildURLPath: '/Dashboard',
      SubChildURLPath: '/Dashboard',
      IsProduction: 0

    },
    isSideBarEnable: true,
    isFullSideBarEnable: true,
    ForgotPasswordOTP: undefined
  }
  : {
    isLoggedIn: false, user: null, UserMenuList: storeMenuList,
    activeMenuList:
    {

      ParentName: 'Dashboard',
      ChildName: '',
      SubChildName: '',
      ParentDisplayName: 'Dashboard',
      ChildDisplayName: '',
      SubChildDisplayName: 'Dashboard',
      ChildURLPath: '/Dashboard',
      SubChildURLPath: '/Dashboard',
      IsProduction: 0
    },
    isSideBarEnable: true,
    isFullSideBarEnable: true
  };

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case LOGIN_SUCCESS:

      let allMenuList = payload.user;
      return {
        ...state,
        isLoggedIn: true,
        user: allMenuList.item4,
        UserMenuList: allMenuList
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case ACTIVE_MENUS:
      return {
        ...state,
        activeMenuList: payload,
      };
    case SIDE_BAR_MENU_VISIBLE:
      return {
        ...state,
        isSideBarEnable: payload,
      };
    case FULL_SIDE_BAR_MENU_VISIBLE:
      return {
        ...state,
        isFullSideBarEnable: payload,
      };
    case REQUESTOTP_FORGOTPASSWORD:
      return {
        ...state,
        ForgotPasswordOTP: payload,
      };
    case ERASE_FORGOTPASSWORD_OTP:
      return {
        ...state,
        ForgotPasswordOTP: undefined,
      };
    default:
      return state;
  }
}
