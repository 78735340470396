import axios from "axios";
import authHeader from "../auth-header";

class UserPermissionService {

    GetUsernameList(Operation) {
        return axios.get(window.$APIBaseURL + "Master/GetUserList?Operation=" + Operation, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetUserPermissionList(EmpID) {
       
        return axios.get(window.$APIBaseURL + "Master/GetEmployeeMenuPermissionList?EmpMenuPermissionId=" + EmpID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

     GetExistingMenuBuyerBrandDetails(EmpID) {
        return axios.get(window.$APIBaseURL + "Master/GetExistingMenuBuyerBrandList?EmpMenuPermissionId=" + EmpID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetMenuList(EmpID, SetAsDefault) {
       
        return axios.get(window.$APIBaseURL + "Master/GetEmployeeRoleMenuPermissionList?EmployeeInformationId=" + EmpID + '&SetAsDefault=' + SetAsDefault, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateUserRightsGrid(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateEmployeeMenuPermissionDetailsGrid',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

}
export default new UserPermissionService();