import React, { useState, useEffect, Fragment } from "react";
import ReactNotification from 'react-notifications-component';
import Input from "react-validation/build/input";
import moment from "moment";
import Form from "react-validation/build/form";
import 'react-notifications-component/dist/theme.css';
import DatePicker from "react-datepicker";
import Nodify from "../Common/ReactNotification";
import "react-datepicker/dist/react-datepicker.css"
import Lightbox from "react-image-lightbox"
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import $ from "jquery";
import { useSelector } from "react-redux";
import { TableHeader, Pagination, Search, PageCount } from "../Datatable";
import TnaService from "../../services/TNA/TNAService";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import CommonService from "../../services/Common/CommonService";
import { TrimSubmissionApprovalId } from '../Common/PageActionNumber'
import SweetAlertPopup from "../Common/SweetAlertPopup";
import Reactselect from 'react-select';
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import Select from "react-validation/build/select";
const TrimSubmission = (props) => {
   
    const { user: currentUser } = useSelector((state) => state.auth);



    //const [getOrderinfo, setOrderinfo] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [getHeader, setHeader] = useState("Add");
    const [ShowAddTrimDimensionPopup, setShowAddTrimDimensionPopup] = useState(false);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [isOpen, setIsOpen] = useState(false);
    const [getImagename, setImagename] = useState();
    const [showawb, setshowawb] = useState(false);
    const [expandedRows, setExpandedRows] = useState([]);
    const [expandState, setExpandState] = useState({});
    const [expandList, setexpandList] = useState({});
    const [getOrderinfo, setOrderinfo] = useState([]);
    const [chkDataExists, setchkDataExists] = useState(false);
    const [expandStateMultiSelect, setExpandStateMultiSelect] = useState({});
    const [expandedRowsMultiSelect, setExpandedRowsMultiSelect] = useState([]);
    const [expandListMultiSelect, setexpandListMultiSelect] = useState({});
    const [OrderInfoMulticloseMainDetails, setOrderInfoMulticloseMainDetails] = useState([]);
    const [OrderInfoMainDetails, setOrderInfoMainDetails] = useState([]);
    const [getID, setID] = useState({ showPopupDelete: false, Params: {} });
    const [buttonLoader, setButtonLoader] = useState(false);
    //gowtam
    const [getStrikeOffDate, setStrikeOffDate] = useState([{ AWB: '', Date: '' }]);
    const [submittedpopup, setSubmittedpopup] = useState(false);
    const [ShowTrimPopup, setShowTrimPopup] = useState(false);
    const [submittedawb, setSubmittedawb] = useState(false);
    const [getSubImageList, setSubImageList] = useState([]);
    const [getCurrimageindex, setCurrimageindex] = useState(0);
    const [TrimDimension, setTrimDimension] = useState([]);
    const [getTaskholder, setTaskholder] = useState([]);
    const [MultiCloseDatas, setMultiCloseDatas] = useState([]);
    const [selectall, setselectall] = useState(false);
    const [showMultiClosediv, setshowMultiClosediv] = useState(true);
    const [showSubmissiondiv, setshowSubmissiondiv] = useState(false);


    const [Swatchdownload, setSwatchdownload] = useState({ Condition: false, Format: "0", RowsandColumn: "0" });
    const [basicdataTrimPdf, setbasicdataTrimPdf] = useState([]);
    const [AllsubmitoptiosTrimPdf, setAllsubmitoptiosTrimPdf] = useState([]);
    const [submitoptiosRefnoTrimPdf, setsubmitoptiosRefnoTrimPdf] = useState([]);
    const [rowsanddatacountforPdf, setrowsanddatacountforPdf] = useState([]);
    const [swatchcardimages, setswatchcardimages] = useState([]);
    const [showswatchcardimages, setshowswatchcardimages] = useState([]);
    const [SwatchDownloadformat, setSwatchDownloadformat] = useState(false);
    const [submittedSwatchFormat, setSubmittedSwatchFormat] = useState(false);
    const [divcolumns, setdivcolumns] = useState();
    const [RowsColumnDdDisable, setRowsColumnDdDisable] = useState(false);
    const [SwatchBoxCssClass, setSwatchBoxCssClass] = useState('');

    // If check production or sampling
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProduction = activeMenus.IsProduction;
    // End

    const [inputFields, setInputFields] = useState([{
        TNAId: 0, BrandID: 0, BuyerID: 0, SeasonID: 0, SupplierID: 0, SupplierName: "", BuyerBrandSeasonName: "", BuyerName: '',
        SubmittedDate: '', SendTo: '', SendToEdit: '', SendToDisplay: '', SendThrough: 0,
        StyleID: 0, StyleName: '', StyleNo: '', IdpoNo: '', EndCustomer: '', AWBName: '', AWBDate: '', AWBDateandNo: '',
        SubmissionApprovalStatus: 0, TrimInfoId: 0, TrimName: '', TrimCode: '', TechnicalSpecification: '',
        TrimReferenceID: 0, TrimDimension: '', SwatchCardImageName: '',
        SwatchCardIamgePath: '', Remarks: '', Createdby: 0, StyleTrimRefId: 0,
        TNATrimSubmittedOption: [],
        TNATrimColorApprovalComments: [], TNATrimRefInfoId: 0, TrimSubmissionApprovalID: 0,
        hasAllDependencyStartPopup: false, MultiDependencyDate: null, MultiDependencyComment: '', submitDependencyStart: false,
        TrimImage: ''
    }]);
    const headers = [
        { name: "Style Name", field: "StyleName", sortable: false },
        { name: "Style No", field: "StyleNo", sortable: false },
        { name: "PO/ID No", field: "IDNo", sortable: false },
        { name: "Details", field: "Details", sortable: false },
        { name: "Task Holder/Follower", field: "", sortable: false },
        { name: "Action", field: "", sortable: false },
    ];
    let styles1 = {
        control: styles1 => ({ ...styles1, width: "230px" }),
    }

    let styles2 = {
        control: styles2 => ({ ...styles2, width: "230", border: '1px solid red' }),
    }
    // useEffect(() => {
    //     setSubmitOptions(Submitoptions);
    // }, [])
    const selector = useSelector((state) => state);
    const isSideBarEnable = selector.auth.isSideBarEnable;
    const isFullSideBarEnable = selector.auth.isFullSideBarEnable;

    let BrowserWidth = isFullSideBarEnable
        ? isSideBarEnable
            ? $(window).width() - 130
            : $(window).width() + 50
        : $(window).width() + 100;

    let DynamicClassName = "col-lg-12 col-sm-12 col-xs-12";
    var ImgClassName = "upload-btn-wrapper2"


    const values = [...inputFields]

    const CustomInputDependency = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                menuPosition="fixed"
                placeholder="DD/MM/YYYY"
                style={{ width: "100%", border: (!values[0].submitDependencyStart) || props.value ? '' : '1px solid red' }}
            />
        )

    }

    useEffect(() => {
       
        if (props.delteTrimSubmission === true || props.editTrimSubmission === true) {
            let Params = {
                Operation: 50,
                Id: props.TNATrimRefInfoId,
            };
            CommonService.CheckMappedItem(Params).then(res => {
                if (res.data.outputResult === "-2") {
                    setchkDataExists(true);
                    let Title = "Warning!";
                    let message = "Approval exists against this Submission.";
                    let type = "warning";
                    Nodify(Title, type, message);
                    props.TrimSubmissionCallback(false)

                }
                else {
                    if (props.delteTrimSubmission === true) {
                        setID({ showPopupDelete: true, Params: props.TNATrimRefInfoId });
                        TnaService.ViewTrimSubmissionApprovalList(props.TNATrimRefInfoId, IsProduction).then((response) => {
                            if (response.data) {
                                setOrderinfo(response.data.trimSubmissionOrderInfoList);
                            }
                        }).catch(() => { hideLoader(); });
                    }
                    else {
                        let getDateValue = new Date(values[0].SubmittedDate);
                        var dd = getDateValue.getDate();
                        var mm = getDateValue.getMonth() + 1;
                        var yyyy = getDateValue.getFullYear();

                        if (dd < 10) {
                            dd = '0' + dd;
                        }
                        if (mm < 10) {
                            mm = '0' + mm;
                        }
                        values[0].SubmittedDate = getDateValue;
                        setInputFields(values);
                    }
                }
            });
        }
        else if (props.TrimswatchDownload === true) {
            setSwatchDownloadformat(true);
        }
        else if (props.TrimswatchUpload === true) {
            $('#upload-button').trigger('click');
        }
        else {
            showLoader();
            if (props.TaskStatus !== 3) {
                values[0].TNATrimSubmittedOption.push(
                    { OptionName: "A", SupplierReferenceNo: "" },
                    { OptionName: "B", SupplierReferenceNo: "" },
                    { OptionName: "C", SupplierReferenceNo: "" },
                    { OptionName: "D", SupplierReferenceNo: "" },
                )
            }

            TnaService.GetTrimDimension(2, props.TNATrimRefInfoId, IsProduction).then((response) => {
                if (response.data) {
                    setTrimDimension(response.data);
                }

            }).catch(() => { });
            TnaService.TaskHolderList(props.TNAId).then((response) => {
                if (response.data) {
                    setTaskholder(response.data);
                }
            }).catch(() => { });

            if (props.TaskStatus !== 3) {
                setshowMultiClosediv(true);
                setshowSubmissiondiv(false);
                TnaService.TrimSubmissionList(1, props.TNATrimRefInfoId, IsProduction).then((response) => {
                    if (response.data) {
                        values[0].TNAId = response.data[0].tnaId;
                        values[0].BrandID = response.data[0].brandID;
                        values[0].BuyerID = response.data[0].buyerID;
                        values[0].SeasonID = response.data[0].seasonID;
                        values[0].SupplierID = response.data[0].supplierID;
                        values[0].SupplierName = response.data[0].supplierName;
                        values[0].BuyerBrandSeasonName = response.data[0].buyerBrandSeasonName;
                        values[0].StyleID = response.data[0].styleId;
                        values[0].StyleName = response.data[0].styleName;
                        values[0].StyleNo = response.data[0].styleNo;
                        values[0].IdpoNo = response.data[0].idpoNo;
                        values[0].EndCustomer = response.data[0].endCustomerNameIdList;
                        values[0].TrimInfoId = response.data[0].trimInfoId;
                        values[0].TrimCode = response.data[0].trimCode;
                        values[0].TechnicalSpecification = response.data[0].technicalSpecification;
                        values[0].TrimName = response.data[0].trimName;
                        values[0].TrimReferenceID = response.data[0].trimReferenceId;
                        values[0].StyleTrimRefId = response.data[0].styleTrimRefId;
                        values[0].TNATrimRefInfoId = props.TNATrimRefInfoId;
                        values[0].TrimSubmissionApprovalID = 0;
                        values[0].TrimImage = response.data[0].trimImage;
                        setInputFields(values);

                        var tempsubimagelist = [];
                        tempsubimagelist = response.data[0].trimImageList.split(',');
                        var TrimImageIndex = tempsubimagelist.indexOf(values[0].TrimImage);
                        // tempsubimagelist.push({ url: getStyleimage.replaceAll(" ", "%20").replaceAll("(", "%28").replaceAll(")", "%29") });
                        setSubImageList(tempsubimagelist);
                        setCurrimageindex(TrimImageIndex);

                    }
                }).catch(() => { });

                TnaService.GetTrimSubmissionOrderInfoList(3, props.TNATrimRefInfoId, IsProduction).then((response) => {
                    if (response.data) {
                        var result = response.data;
                        var index = 0;
                        result.forEach(element => {
                            index = index + 1;
                            element.index = index;
                            if (element.tnaTrimRefInfoId === props.TNATrimRefInfoId) {
                                element.isSelected = 1;
                            }
                        })

                        result.map((r, i) => {
                            props.AllChildvalues.map(c => {
                                if (r.tnaTrimRefInfoId === c.ChildId) {
                                    result[i].IsDepandency = c.IsDepandency
                                    result[i].Depandency = c.Depandency
                                    // result[i].dependencyStartstop = result[i].IsDepandency === 0 ? 1 : 0
                                    // data[i].dependencyStartstop = c.intDependency;
                                    result[i].dependencyStartstop = c.intDependency;
                                }
                            })
                        })



                        setMultiCloseDatas(result);
                        let data = [...new Map(response.data.map(item =>
                            [item['trimLogoId'], item])).values()];
                        setOrderInfoMulticloseMainDetails(data);
                        handleExpandRowMultiSelect(values[0].TrimImage, response.data);
                        hideLoader();
                    }

                }).catch(() => { hideLoader(); });
            }
            else {
                setshowMultiClosediv(false);
                setshowSubmissiondiv(true);
                setShowTrimPopup(false);
                TnaService.ViewTrimSubmissionApprovalList(props.TNATrimRefInfoId, IsProduction).then((response) => {
                   ;
                    if (response.data) {
                        values[0].TNAId = response.data.trimSubmitApprovalList[0].tnaId;
                        values[0].BrandID = response.data.trimSubmitApprovalList[0].brandID;
                        values[0].BuyerID = response.data.trimSubmitApprovalList[0].buyerID;
                        values[0].BuyerName = response.data.trimSubmitApprovalList[0].buyerName;
                        values[0].SeasonID = response.data.trimSubmitApprovalList[0].seasonID;
                        values[0].SupplierID = response.data.trimSubmitApprovalList[0].supplierID;
                        values[0].SupplierName = response.data.trimSubmitApprovalList[0].supplierName;
                        values[0].BuyerBrandSeasonName = response.data.trimSubmitApprovalList[0].buyerBrandSeasonName;
                        values[0].StyleID = response.data.trimSubmitApprovalList[0].styleId;
                        values[0].StyleName = response.data.trimSubmitApprovalList[0].styleName;
                        values[0].StyleNo = response.data.trimSubmitApprovalList[0].styleNo;
                        values[0].IdpoNo = response.data.trimSubmitApprovalList[0].idpoNo;
                        values[0].EndCustomer = response.data.trimSubmitApprovalList[0].endCustomerNameIdList;
                        values[0].TrimInfoId = response.data.trimSubmitApprovalList[0].trimInfoId;
                        values[0].TrimCode = response.data.trimSubmitApprovalList[0].trimCode;
                        values[0].TechnicalSpecification = response.data.trimSubmitApprovalList[0].technicalSpecification;
                        values[0].TrimName = response.data.trimSubmitApprovalList[0].trimName;
                        values[0].TrimReferenceID = response.data.trimSubmitApprovalList[0].trimReferenceId;
                        values[0].StyleTrimRefId = response.data.trimSubmitApprovalList[0].styleTrimRefId;
                        values[0].TNATrimRefInfoId = props.TNATrimRefInfoId;
                        values[0].TrimSubmissionApprovalID = response.data.trimSubmitApprovalList[0].trimSubmissionApprovalID;
                        values[0].SubmittedDate = new Date(response.data.trimSubmitApprovalList[0].submittedDate);
                        values[0].TrimImage = response.data.trimSubmitApprovalList[0].trimImage;
                        // let getDateValue = response.data.trimSubmitApprovalList[0].submittedDate;
                        // var dd = getDateValue.getDate();
                        // var mm = getDateValue.getMonth() + 1;
                        // var yyyy = getDateValue.getFullYear();

                        // if (dd < 10) {
                        //     dd = '0' + dd;
                        // }
                        // if (mm < 10) {
                        //     mm = '0' + mm;
                        // }
                        // let coverted = mm + '/' + dd + '/' + yyyy;
                        // values[0].SubmittedDate = getDateValue;
                        if (response.data.trimSubmitApprovalList[0].awbDate !== "") {
                            if (response.data.trimSubmitApprovalList[0].sendThrough === 2) {
                                let getDateValue = new Date(response.data.trimSubmitApprovalList[0].awbDate);
                                var dd = getDateValue.getDate();
                                var mm = getDateValue.getMonth() + 1;
                                var yyyy = getDateValue.getFullYear();

                                if (dd < 10) {
                                    dd = '0' + dd;
                                }
                                if (mm < 10) {
                                    mm = '0' + mm;
                                }
                                let coverted = dd + '/' + mm + '/' + yyyy;
                                setshowawb(true);
                                //setShowTrimPopup(true);

                                values[0].AWBDateandNo = response.data.trimSubmitApprovalList[0].awbName + ' / ' + coverted;
                                values[0].AWBDate = new Date(response.data.trimSubmitApprovalList[0].awbDate)
                                values[0].AWBName = response.data.trimSubmitApprovalList[0].awbName;

                            }
                        }

                        else {
                            if (response.data.trimSubmitApprovalList[0].sendThrough === 2) {
                               ;
                                setshowawb(true);
                                values[0].AWBDateandNo = '' + '  ' + '';
                                values[0].AWBDate = response.data.trimSubmitApprovalList[0].awbDate === "" ? "" : new Date(response.data.trimSubmitApprovalList[0].awbDate)
                                values[0].AWBName = response.data.trimSubmitApprovalList[0].awbName;

                            }

                        }

                       
                        values[0].SendThrough = response.data.trimSubmitApprovalList[0].sendThrough;
                        values[0].SendTo = response.data.trimSubmitApprovalList[0].sendTo;
                        values[0].SendToDisplay = response.data.trimSubmitApprovalList[0].sendToNames;
                        values[0].SendToEdit = response.data.trimSubmitApprovalList[0].sendToNames;
                        let arr = response.data.trimSubmitApprovalList[0].sendTo.split(',');
                        let arrVal = [];
                        for (let size of arr) {
                            TnaService.TaskHolderList(props.TNAId).then((res) => {
                                if (res.data) {
                                    let sendtoId = res.data.filter(x => x.value === parseInt(size))
                                    arrVal.push({
                                        value: sendtoId[0].value,
                                        label: sendtoId[0].label
                                    })
                                    values[0].SendToEdit = arrVal;
                                    //setInputFields(values)
                                }
                                // setInputFields(values)
                            }).catch(() => { });

                        }
                        values[0].SwatchCardIamgePath = response.data.trimSubmitApprovalList[0].swatchCardImagePath;
                        values[0].SwatchCardImageName = response.data.trimSubmitApprovalList[0].swatchCardImageName;
                        values[0].Remarks = response.data.trimSubmitApprovalList[0].remarks;


                        let images = [];
                        images = response.data.trimSubmitApprovalList[0].trimImageList.split(',');
                        var TrimImageIndex = images.indexOf(values[0].TrimImage);
                        setSubImageList(images);
                        setCurrimageindex(TrimImageIndex);

                        values[0].TNATrimSubmittedOption = [];
                        response.data.trimSubmittedOption.forEach(element => {
                            values[0].TNATrimSubmittedOption.push({
                                OptionName: element.optionName,
                                SupplierReferenceNo: element.supplierReferenceNo
                            })
                        })

                        setInputFields(values);
                        setOrderinfo(response.data.trimSubmissionOrderInfoList);
                        let data = [...new Map(response.data.trimSubmissionOrderInfoList.map(item =>
                            [item['trimLogoId'], item])).values()];

                        setOrderInfoMainDetails(data);
                        setOrderinfo(data);
                        handleExpandRow(data[0].trimLogoPath, data);
                        hideLoader();
                    }
                }).catch(() => { hideLoader(); });
            }




        }
        //setOrderinfo(getOrderinfo);

    }, [props.delteTrimSubmission === true || props.editTrimSubmission === true || props.TrimswatchDownload === true || props.TrimswatchUpload === true])

    useEffect(() => {
       
        if (props.delteTrimSubmission === true || props.editTrimSubmission === true) {
            let Params = {
                Operation: 50,
                Id: props.TNATrimRefInfoId,
            };
            CommonService.CheckMappedItem(Params).then(res => {
                if (res.data.outputResult === "-2") {
                    setchkDataExists(true);
                    let Title = "Warning!";
                    let message = "Approval exists against this Submission.";
                    let type = "warning";
                    Nodify(Title, type, message);
                    props.TrimSubmissionCallback(false)

                }
                else {
                    if (props.delteTrimSubmission === true) {
                        setID({ showPopupDelete: true, Params: props.TNATrimRefInfoId });
                        TnaService.ViewTrimSubmissionApprovalList(props.TNATrimRefInfoId, IsProduction).then((response) => {
                            if (response.data) {
                                setOrderinfo(response.data.trimSubmissionOrderInfoList);
                            }
                        }).catch(() => { hideLoader(); });
                    }
                    else {
                        let getDateValue = new Date(values[0].SubmittedDate);
                        var dd = getDateValue.getDate();
                        var mm = getDateValue.getMonth() + 1;
                        var yyyy = getDateValue.getFullYear();

                        if (dd < 10) {
                            dd = '0' + dd;
                        }
                        if (mm < 10) {
                            mm = '0' + mm;
                        }
                        values[0].SubmittedDate = getDateValue;
                        setInputFields(values);
                    }
                }
            });
        }
        else if (props.TrimswatchDownload === true) {
            setSwatchDownloadformat(true);
        }
        else if (props.TrimswatchUpload === true) {
            $('#upload-button').trigger('click');
        }
    }, [props.delteTrimSubmission === true && props.editTrimSubmission === true])

    const handleRemoveFields = index => {
        values[0].TNATrimSubmittedOption.splice(index, 1);
        setInputFields(values);
    };


    const handleAddField = (index) => {
        var isvalid = true;

        inputFields.forEach((element, index) => {
            if (values[0].TNATrimSubmittedOption[index].OptionName === '' || values[0].TNATrimSubmittedOption[index].SupplierReferenceNo === '') {
                isvalid = false;
            }
        })
        if (isvalid) {
            values[0].TNATrimSubmittedOption.push({ OptionName: '', SupplierReferenceNo: '' });
            setInputFields(values);
            setSubmitted(false);
        }
        else {
            Nodify('Warning!', 'warning', 'Please fill Option and Supplier Reference No.');
            setSubmitted(true);
        }


    }
    const handleRemoveSample = (index) => {
       
        const value = [...expandList]
        value.splice(index, 1);
        setOrderinfo(value);
        setexpandList(value)

    }
    const OnChangeOrderinfo = (event, index) => {
        const orderinfo = [...getOrderinfo];
        let SelectedCount = 0;
        orderinfo.forEach(element => {
            if (element.SelectedDetail === 1) {
                SelectedCount = SelectedCount + 1;
            }
        })
        if (event.target.checked === true) {
            if (SelectedCount === 1) {
                orderinfo[index].SelectedDetail = 0;
                Nodify('Warning!', 'warning', 'Please select only one.');
            }
            else {
                orderinfo[index].SelectedDetail = 1;
            }
        }
        else {
            orderinfo[index].SelectedDetail = 0;
        }
        setOrderinfo(orderinfo);

        let SelectedDetail = '';
        let SelectedRef = '';
        orderinfo.forEach(element => {
            if (element.SelectedDetail === 1) {
                SelectedDetail = element.tnaTrimRefInfoId;
                SelectedRef = element.trimReferenceId;
            }
        })
        TnaService.GetTrimDimension(2, SelectedDetail, IsProduction).then((response) => {
            if (response.data) {
                setTrimDimension(response.data);
            }

        }).catch(() => { });
        values[0].TrimReferenceID = SelectedRef;
        setInputFields(values);
    }


    const CloseAwbpopup = () => {
        if (props.TaskStatus !== 3) {
            values[0].AWBName = '';
            values[0].AWBDate = '';
            values[0].AWBDateandNo = '';
            setInputFields(values);
        }
        setShowTrimPopup(false);
    }

    const handlepopupchange = (event, feild) => {
        const values = [...inputFields]

        if (feild === "AWBName") {
            let input = '';
            if (event.target.value.trim() !== '') {
                input = event.target.value;
            }
            values[0].AWBName = input;
        }
        else if (feild === "AWBDate") {
            values[0].AWBDate = event;
        }
        setInputFields(values);
    }

    const SaveAwbnoDate = () => {
       ;
        let values = [...inputFields]
        if (values[0].AWBName === "" || values[0].AWBDate === "" || values[0].AWBDate === null) {
            values[0].AWBDateandNo = values[0].AWBName + values[0].AWBDate;
            setShowTrimPopup(true);
            setSubmittedawb(true)
        }
        else {
            values[0].AWBDateandNo = values[0].AWBName + " / " + values[0].AWBDate.toLocaleDateString('en-GB')
            setShowTrimPopup(false);
        }
        setInputFields(values);
    }
    const ViewFile = (event, filename) => { // event.preventDefault();
        setIsOpen(true);
        setImagename(filename);
    }
    const openFileUpload = () => {
        $('#upload-button').trigger('click');
    }



    const handlemultidd = (event, index) => {
       ;
        if (event.length === 0) {
            values[0].SendTo = "";
            values[0].SendToEdit = '';
            // values[index].SkuColorNameIds = "";
        } else {
            let strValue = "";
            for (let Sku of event) {
                if (strValue === "") {
                    strValue = Sku.value.toString();
                } else {
                    strValue = strValue + "," + Sku.value;
                }
            }
            values[0].SendTo = strValue;
            values[0].SendToEdit = event;
        }

        setInputFields(values);
    }



    const handleChange = (event, feild) => {
       ;

        if (feild === "SubmittedDate") {
            if (event !== null) {
                values[0].SubmittedDate = event;
            }

        }
        else if (feild === "Sendthrough") {
            let input = '';
            if (event.target.value.trim() !== '') {
                input = event.target.value;
            }
            values[0].SendThrough = parseInt(input);
            if (input === "2") {
                setshowawb(true);
            }
            else if (input === "1") {
                values[0].AWBDateandNo = ''
                values[0].AWBDate = ''
                values[0].AWBName = ''
                setSubmittedawb(false)
                setshowawb(false);
            }

            else {
                setshowawb(false);
            }
        }
        else if (feild === "SendTo") {
            let input = '';
            if (event.target.value.trim() !== '') {
                input = event.target.value;
            }
            values[0].SendTo = input;
        }
        else if (feild === "Remarks") {
            let input = '';
            if (event.target.value.trim() !== '') {
                input = event.target.value;
            }
            values[0].Remarks = input;
        }
        else if (feild === "TrimReferenceID") {
            let input = '';
            if (event.target.value.trim() !== '') {
                input = event.target.value;
            }
            values[0].TrimReferenceID = input;
        }

        setInputFields(values);
    }
    const Delcallback = (value) => {
        if (value === true) {
            setID({ showPopupDelete: false, Params: {} });
            props.TrimSubmissionCallback(true, getOrderinfo, 'Delete')
        } else {
            setID({ showPopupDelete: false, Params: {} });
            props.TrimSubmissionCallback(false, '', 'Not A Delete', '')
        }

    }
    const handleExpandRowMultiSelect = (trimLogoPath, list) => {
        setExpandedRowsMultiSelect([]);
        setexpandListMultiSelect(list.filter(x => x.trimLogoPath === trimLogoPath));
        let currentExpandedRows = expandedRowsMultiSelect;
        const isRowExpanded = currentExpandedRows.includes(trimLogoPath);
        let obj = {};
        isRowExpanded ? (obj[trimLogoPath] = false) : (obj[trimLogoPath] = true);
        setExpandStateMultiSelect(obj);
        currentExpandedRows = [];
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== trimLogoPath) :
            currentExpandedRows.concat(trimLogoPath);
        setExpandedRowsMultiSelect(newExpandedRows);


    }

    const handleExpandRow = (trimLogoPath, list) => {
        setExpandedRows([]);
        setexpandList(list.filter(x => x.trimLogoPath === trimLogoPath));
        let currentExpandedRows = expandedRows;
        const isRowExpanded = currentExpandedRows.includes(trimLogoPath);
        let obj = {};
        isRowExpanded ? (obj[trimLogoPath] = false) : (obj[trimLogoPath] = true);
        setExpandState(obj);
        currentExpandedRows = [];
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== trimLogoPath) :
            currentExpandedRows.concat(trimLogoPath);
        setExpandedRows(newExpandedRows);
    }
    // const openStrikeoffpopup = () => {
    //     setShowTrimPopup(true);
    // }
    const trimModalPopup = () => {
        setShowTrimPopup(true);
    }


    const closeTrimModal = () => {
        setShowTrimPopup(false);
    }
    const handleSubmitoptionchange = (event, feild, index) => {
        let input = '';
        if (event.target.value.trim() !== '') {
            input = event.target.value;
        }
        if (feild === "OptionName") {
            values[0].TNATrimSubmittedOption[index].OptionName = input;
        }
        else if (feild === "SupplierReferenceNo") {
            values[0].TNATrimSubmittedOption[index].SupplierReferenceNo = input;
        }
        if (values[0].TNATrimSubmittedOption[index].OptionName !== '' && values[0].TNATrimSubmittedOption[index].SupplierReferenceNo !== '') {
            let Existoption = values[0].TNATrimSubmittedOption.filter(x => x.OptionName.toLowerCase() === values[0].TNATrimSubmittedOption[index].OptionName.toLowerCase() && x.SupplierReferenceNo.toLowerCase() === values[0].TNATrimSubmittedOption[index].SupplierReferenceNo.toLowerCase())
            if (Existoption.length > 1) {
                values[0].TNATrimSubmittedOption[index].SupplierReferenceNo = '';
                setSubmitted(true);
                Nodify('Warning!', 'warning', 'SupplierReferenceNo Already Exists.');
            }
        }
        setInputFields(values);
    }


    const SaveSubmission = () => {
       ;
        let CheckSubmittedOption = true;

        values[0].TNATrimSubmittedOption.forEach(element => {
            if (element.OptionName === '' || element.SupplierReferenceNo === '') {
                CheckSubmittedOption = false;
            }
        })


        if (values[0].SubmittedDate === '' || values[0].SendTo === "" || values[0].SendThrough === 0 ||

            values[0].TrimReferenceID === "0") {
            Nodify("Warning!", "warning", "Please fill the mandatory(*) fields.");
            setSubmittedpopup(true);
            setButtonLoader(false);
        }
        else if (CheckSubmittedOption === false) {
            Nodify("Warning!", "warning", "Please fill the mandatory(*) fields in Submitted Option Section.");
            setSubmitted(true);
            setButtonLoader(false);
        }
        else {
            setButtonLoader(true);
            values[0].SubmittedDate = new Date(values[0].SubmittedDate.setMinutes(values[0].SubmittedDate.getMinutes() + 370))
            if (values[0].AWBDate === '' || values[0].AWBDate === null) {
                values[0].AWBDate = ''
            }
            else {
                values[0].AWBDate = new Date(values[0].AWBDate.setMinutes(values[0].AWBDate.getMinutes() + 370))
            }
            //values[0].SubmittedDate = values[0].SubmittedDate.setMinutes(values[0].SubmittedDate.getMinutes() + 370)
            values[0].SubmissionApprovalStatus = 1;
            values[0].Operation = props.editTrimSubmission === false ? 1 : 2;
            values[0].TNATrimOrderInfo = getOrderinfo;
            TnaService.InsertUpdateTrimSubmissionApproval(values[0]).then(res => {
                if (res.data.outputResult === "1") {
                    Nodify('Success!', 'success', 'Trim Submission Added Successfully');
                    props.TrimSubmissionCallback(true, getOrderinfo, 'Save', 0)
                } else if (res.data.outputResult === "2") {
                    Nodify('Success!', 'success', 'Trim Submission Updated Successfully');
                    props.TrimSubmissionCallback(true, getOrderinfo, 'Update', 0)
                }
                else if (res.data.outputResult === "0") {
                    Nodify("Error!", "Error Occured!");
                }
            });
        }

    }

    // const previmage = () => {
    //     if (getCurrimageindex === 0) {
    //         setCurrimageindex(0);
    //     }
    //     else {
    //         setCurrimageindex(getCurrimageindex - 1);
    //     }
    // }

    // const nextimage = () => {
    //     if (getCurrimageindex === getSubImageList.length - 1) {
    //         setCurrimageindex(getSubImageList.length - 1);
    //     }
    //     else {
    //         setCurrimageindex(getCurrimageindex + 1);
    //     }
    // }


    const OnSelectRecords = (event, index, mainindex) => {
        const value = [...MultiCloseDatas];
        var Maindetails = [...OrderInfoMulticloseMainDetails];
        if (event.target.checked === true) {
            value[index].isSelected = 1;
        }
        else {
            value[index].isSelected = 0;
            setselectall(0);
        }
        let isSelectAll = 0;
        if (value) {
            isSelectAll = !!!value.find(d => d.isSelected === 0);
            isSelectAll = isSelectAll ? 1 : 0;
        }

        Maindetails[mainindex].isSelectedAll = isSelectAll;
        setOrderInfoMulticloseMainDetails(Maindetails);
        setMultiCloseDatas(value);
    }

    const SelectAll = (event, TrimLogoPath, mainindex) => {
        let IsSelected = 0;
        var Maindetails = [...OrderInfoMulticloseMainDetails];
        if (event.target.checked === true) {
            IsSelected = 1;
            MultiCloseDatas.filter(x => x.trimLogoPath === TrimLogoPath && x.IsDepandency === 0).forEach(element => {
                element.isSelected = 1;
            })
        }
        else {
            IsSelected = 0;
            MultiCloseDatas.filter(x => x.trimLogoPath === TrimLogoPath).forEach(element => {
                element.isSelected = 0;
            })
        }
        Maindetails[mainindex].isSelectedAll = IsSelected;
        setOrderInfoMulticloseMainDetails(Maindetails);
        setselectall(IsSelected);
    }

    const AddRows = () => {
        let FileteredRecords = MultiCloseDatas.filter(x => x.isSelected === 1);
        if (FileteredRecords.length === 0) {
            Nodify("Warning!", "warning", "Select atleast one row.");
        }
        else {
            setshowMultiClosediv(false);
            setshowSubmissiondiv(true);
            // let data = [...new Map(FileteredRecords.map(item =>
            //     [item['trimInfoId'], item])).values()];
            var index = 0;
            FileteredRecords.forEach(element => {
                index = index + 1;
                element.index = index;
            })
            let data = [...new Map(FileteredRecords.map(item =>
                [item['trimLogoId'], item])).values()];
            setOrderInfoMainDetails(data);
            setOrderinfo(FileteredRecords);
            handleExpandRow(FileteredRecords[0].trimLogoPath, FileteredRecords);
        }
    }

    const ShowHideDivs = () => {
        setshowMultiClosediv(true);
        setshowSubmissiondiv(false);
    }

    function getSelectedDependencyMap(SelectedDependency) {
        let Dependency = true;
        if (SelectedDependency) {
            let arrstatus = [];
            arrstatus = SelectedDependency.split(',');
            if (arrstatus.length > 0) {
                arrstatus.map(element => {
                    let val = [];
                    val = element.split('-');
                    let valIndex = props.values[parseInt(val[0]) - 1].StatusID;
                    if (valIndex !== 3) {
                        Dependency = false;
                    }
                });
            }

        }

        return Dependency;
    }

    const handleChangeMultiDependentStart = (event, feild, IsSingleTask) => {
        values[0].submitDependencyStart = false;
        let input = '';

        if (feild === "Comment") {
            if (event.target.value.trim() !== '') {
                input = event.target.value;
            }
            values[0].MultiDependencyComment = input;
        }
        else {
            values[0].MultiDependencyDate = event;
        }
        setInputFields(values);

    }

    const OpenAllDependentStartPopup = () => {
        showLoader();

        values[0].hasAllDependencyStart = 1;
        values[0].hasAllDependencyStartPopup = true;

        setInputFields(values);
        hideLoader();
    }

    const CloseAllDependencyStartPopup = () => {
        values[0].hasAllDependencyStartPopup = false;
        setInputFields(values);

    }

    function convertUTCDateToLocalDate(date) {
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date;
    }

    const handleSaveAllDependencyStart = () => {
       ;
        showLoader();
        let dependencyComments = "";
        let dependencydate = null;
        let CommonIdList = "";
       ;
        dependencyComments = values[0].MultiDependencyComment;
        dependencydate = values[0].MultiDependencyDate;

        if (!dependencyComments || !dependencydate) {
            values[0].submitDependencyStart = true;
            setInputFields(values);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            hideLoader();
            return false;
        }
        else {

            let ManualCloseData = [...MultiCloseDatas];
            let ManualCloseFilter = ManualCloseData.filter(d => d.dependencyStartstop !== 1);

            ManualCloseFilter.map((item) => {
                if (CommonIdList === "") {
                    CommonIdList = item.tnaTrimRefInfoId.toString();
                } else {
                    CommonIdList = CommonIdList + "," + item.tnaTrimRefInfoId.toString();
                }

                // item.dependencyStartstop = 1;

            });

            let params = {
                TNAId: props.TNAId,
                TaskDetailId: props.commonParams.TaskID,
                CommonIdList: CommonIdList,
                DependencyDate: convertUTCDateToLocalDate(new Date(dependencydate)),
                Comments: dependencyComments,
                IsSingleTask: 0,
                CreatedBy: currentUser.employeeinformationID
            }

            TnaService.InsertTNAMultiDependencyStartHistory(params).then((res) => {
                if (res.data.outputResult === "1") {

                    ManualCloseData.map((item) => {
                        item.dependencyStartstop = 1;
                        item.IsDepandency = 0;
                    });

                    values[0].hasAllDependencyStartPopup = false;
                    setMultiCloseDatas(ManualCloseData);
                    handleExpandRowMultiSelect(ManualCloseData[0].trimLogoPath, ManualCloseData);
                    setInputFields(values);

                    Nodify('Success!', 'success', "Dependency Added Successfully");
                    hideLoader();
                } else if (res.data.outputResult === "2") {
                    Nodify('Warning!', 'warning', "This dependency date already added");
                    hideLoader();
                } else if (res.data.outputResult === "-1") {
                    Nodify('Warning!', 'warning', "Error Occured");
                    hideLoader();
                }
            })
        }
    }



    const UploadImages = async (e) => {
       ;

        const files = e.target.files;
        var file = null;
        var Firstfile = files[0];
        const formData = new FormData();
        for (var i = 0; i < files.length; i++) {
            file = files[i];
            if (file !== null && file !== '' && file !== undefined) {
                const fileType = file['type'];
                const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png'];
                if (!validImageTypes.includes(fileType)) {
                    Nodify('Warning!', 'warning', 'Invalid file format selected');
                    $('#FileUpload').val("");
                }
                else {

                    formData.append("FormFile", file);
                    // formData.append("FileName", file.name);
                    // formData.append("Page", "LabdipSwatchCardPdf");
                    try {
                        //  const res = await axios.get(window.$APIBaseURL + "api/file/", formData);
                        swatchcardimages.push({
                            src: "Images/TrimSwatchCardPdf/" + file.name,
                            height: ''
                        })


                    } catch (ex) {
                        console.log(ex);
                    }
                }
            }
        }
        for (const value of formData.values()) {
            console.log(value);
        }
        const res = await axios.post(window.$APIBaseURL + "api/file/TrimSwatchCardUpload", formData);
        var FileName = Firstfile.name.substr(0, Firstfile.name.lastIndexOf('.'));
        values[0].SwatchCardImageName = Firstfile.name;
        values[0].SwatchCardIamgePath = "Images/TrimSwatchCardPdf/" + Firstfile.name;
        setInputFields(values);
        $('#FileUpload').val("");
        setshowswatchcardimages(true);
        UploadTrimSwatchCardPdf(FileName);
    }

    const UploadTrimSwatchCardPdf = (FileName) => {
        var timeout = 5000;
        showLoader();
        const doc = new jsPDF('p', 'pt', 'a4');
        const Page1 = document.getElementById("UploadPage1");
        html2canvas(Page1, { scale: 5 }).then(canvas => {
            var img = new Image();

            img.src = window.$APIBaseURL + swatchcardimages[0].src;
            const imgWidth = 590;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');



            if (document.getElementById("UploadPage2") !== null) {
                const Page2 = document.getElementById("UploadPage2");
                html2canvas(Page2, { scale: 5 }).then(canvas => {
                    var img = new Image();
                    img.src = window.$APIBaseURL + swatchcardimages[1].src;
                    const imgWidth = 590;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    doc.addPage();
                    doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');


                })
            }
            if (document.getElementById("UploadPage3") !== null) {
                const Page3 = document.getElementById("UploadPage3");
                html2canvas(Page3, { scale: 5 }).then(canvas => {
                    var img = new Image();
                    img.src = window.$APIBaseURL + swatchcardimages[2].src;
                    const imgWidth = 590;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    doc.addPage();
                    doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');


                })
            }
            if (document.getElementById("UploadPage4") !== null) {
                const Page4 = document.getElementById("UploadPage4");
                html2canvas(Page4, { scale: 5 }).then(canvas => {
                    var img = new Image();
                    img.src = window.$APIBaseURL + swatchcardimages[3].src;
                    const imgWidth = 590;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    doc.addPage();
                    doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');


                })
            }

            // if (document.getElementById("UploadPage5") !== null) {
            //     const UploadPage5 = document.getElementById("UploadPage5");
            //     html2canvas(UploadPage5, { scale: 5 }).then(canvas => {
            //         var img = new Image();
            //         img.src = window.$APIBaseURL + swatchcardimages[4].src;
            //         const imgWidth = 590;
            //         const imgHeight = (canvas.height * imgWidth) / canvas.width;
            //         doc.addPage();
            //         doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');


            //     })
            // }

            // if (document.getElementById("UploadPage6") !== null) {
            //     timeout = 10000;
            //     const UploadPage6 = document.getElementById("UploadPage6");
            //     html2canvas(UploadPage6, { scale: 5 }).then(canvas => {
            //         var img = new Image();
            //         img.src = window.$APIBaseURL + swatchcardimages[5].src;
            //         const imgWidth = 590;
            //         const imgHeight = (canvas.height * imgWidth) / canvas.width;
            //         doc.addPage();
            //         doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');


            //     })
            // }

            // if (document.getElementById("UploadPage7") !== null) {
            //     const UploadPage7 = document.getElementById("UploadPage7");
            //     html2canvas(UploadPage7, { scale: 5 }).then(canvas => {
            //         var img = new Image();
            //         img.src = window.$APIBaseURL + swatchcardimages[6].src;
            //         const imgWidth = 590;
            //         const imgHeight = (canvas.height * imgWidth) / canvas.width;
            //         doc.addPage();
            //         doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');


            //     })
            // }

            // if (document.getElementById("UploadPage8") !== null) {
            //     const UploadPage8 = document.getElementById("UploadPage8");
            //     html2canvas(UploadPage8, { scale: 5 }).then(canvas => {
            //         var img = new Image();
            //         img.src = window.$APIBaseURL + swatchcardimages[7].src;
            //         const imgWidth = 590;
            //         const imgHeight = (canvas.height * imgWidth) / canvas.width;
            //         doc.addPage();
            //         doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');


            //     })
            // }

            // if (document.getElementById("UploadPage9") !== null) {
            //     const UploadPage9 = document.getElementById("UploadPage9");
            //     html2canvas(UploadPage9, { scale: 5 }).then(canvas => {
            //         var img = new Image();
            //         img.src = window.$APIBaseURL + swatchcardimages[8].src;
            //         const imgWidth = 590;
            //         const imgHeight = (canvas.height * imgWidth) / canvas.width;
            //         doc.addPage();
            //         doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');


            //     })
            // }

            // if (document.getElementById("UploadPage10") !== null) {
            //     const UploadPage10 = document.getElementById("UploadPage10");
            //     html2canvas(UploadPage10, { scale: 5 }).then(canvas => {
            //         var img = new Image();
            //         img.src = window.$APIBaseURL + swatchcardimages[9].src;
            //         const imgWidth = 590;
            //         const imgHeight = (canvas.height * imgWidth) / canvas.width;
            //         doc.addPage();
            //         doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');


            //     })
            // }


            setTimeout(() => {

                //  var file = doc.output("blob");
                const file = new File([doc.output("blob")], "filename.pdf", { type: "image/jpg" });
                //  doc.save("LabdipSwatchCard_" + props.LabdibFabricInfoId + ".pdf");
                hideLoader();
                setshowswatchcardimages(false);

                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", FileName + ".pdf");
                formData.append("Page", "TrimSwatchCardPdf");
                const res = axios.post(window.$APIBaseURL + "api/file", formData);
                TnaService.UpdateTrimSwatchStatus(props.TNATrimRefInfoId, 2, values[0].SwatchCardImageName, values[0].SwatchCardIamgePath, 3).then((res) => {
                    if (res.data) {
                        props.TrimSubmissionCallback(true, getOrderinfo, 'Save', res.data.outputResult)
                    }
                })
            }, timeout);

        });
    }

    const downloadSwatchcard = () => {
       ;
        var FileName = values[0].SwatchCardImageName.substr(0, values[0].SwatchCardImageName.lastIndexOf('.'));
        fetch(window.$APIBaseURL + 'Images/TrimSwatchCardPdf/' + FileName + '.pdf').then(response => {
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                const text = new Response(blob).text();
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'Submitted_' + FileName + '.pdf';
                alink.click();
                window.open(fileURL, "_blank");
            })
        })
    }




    const GenerateTrimSwatchCardPdf = () => {
       ;
        let Isvalid = true;
        if (Swatchdownload.Format === "0" || (Swatchdownload.RowsandColumn === "0" && Swatchdownload.Format === "1")) {
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            setSubmittedSwatchFormat(true);
            Isvalid = false;
        }
        else {
            if (Swatchdownload.Format === '1') {
                Isvalid = true;
            }
            else if (values[0].BuyerName === "Focus International" && Swatchdownload.Format === '2') {
                Isvalid = true;
            } else {
                Isvalid = false;
                Swatchdownload.Format = '0';
                Nodify('Warning!', 'warning', 'Buyer only selectable in FocusInternational');
            }
        }

        if (Isvalid) {

            var Rows; var columns;
            if (Swatchdownload.Format === "1") {
                if (Swatchdownload.RowsandColumn === "1") {
                    Rows = 3; columns = 3;
                    setSwatchBoxCssClass("eleven")
                }
                else if (Swatchdownload.RowsandColumn === "2") {
                    Rows = 2; columns = 2;
                    setSwatchBoxCssClass("one")
                }
                else if (Swatchdownload.RowsandColumn === "3") {
                    Rows = 1; columns = 1;
                    setSwatchBoxCssClass("two")
                }
                else if (Swatchdownload.RowsandColumn === "4") {
                    Rows = 1; columns = 2;
                    setSwatchBoxCssClass("three")
                }
                else if (Swatchdownload.RowsandColumn === "5") {
                    Rows = 1; columns = 3;
                    setSwatchBoxCssClass("four")
                }
                else if (Swatchdownload.RowsandColumn === "6") {
                    Rows = 1; columns = 4;
                    setSwatchBoxCssClass("five")
                }
                else if (Swatchdownload.RowsandColumn === "7") {
                    Rows = 2; columns = 3;
                    setSwatchBoxCssClass("six")
                }
                else if (Swatchdownload.RowsandColumn === "8") {
                    Rows = 2; columns = 4;
                    setSwatchBoxCssClass("seven")
                }
                else if (Swatchdownload.RowsandColumn === "9") {
                    Rows = 2; columns = 1;
                    setSwatchBoxCssClass("eight")
                }
                else if (Swatchdownload.RowsandColumn === "10") {
                    Rows = 3; columns = 1;
                    setSwatchBoxCssClass("nine")
                }
                else if (Swatchdownload.RowsandColumn === "11") {
                    Rows = 4; columns = 1;
                    setSwatchBoxCssClass("ten")
                }
            }
            else {
                Rows = 1; columns = 1;

            }

            if (columns === 1) {
                setdivcolumns("12");
            }
            else if (columns === 2) {
                setdivcolumns("6");
            }
            else if (columns === 3) {
                setdivcolumns("4");
            }
            else if (columns === 4) {
                setdivcolumns("3");
            }





            setSubmittedSwatchFormat(false);
            setSwatchDownloadformat(false);
            var ValAllSubmittedOption = [...AllsubmitoptiosTrimPdf]
            TnaService.ViewTrimSubmissionApprovalList(props.TNATrimRefInfoId, IsProduction).then((response) => {
                if (response.data) {
                    setbasicdataTrimPdf(response.data.trimSubmitApprovalList);
                    setsubmitoptiosRefnoTrimPdf([]);

                    var filtereddata = [...new Map(response.data.trimSubmittedOption.map(item =>
                        [item['supplierReferenceNo'], item])).values()];

                    var rowsanddatacount = [];
                    var SumofDataCounts = [];
                    var NoofRows = 0; var datacount = 0; var datacountbal = 0;
                    filtereddata.map(rn => {
                        var curr = 0;
                        datacount = response.data.trimSubmittedOption.filter(x => x.supplierReferenceNo === rn.supplierReferenceNo).length;
                        datacountbal = datacount;

                        // for get no of rows going to be fetch in pdf
                        if (datacount > columns) {

                            if (datacount % columns === 0) {
                                NoofRows = NoofRows + (datacount / columns);
                                curr = curr + (datacount / columns);
                            }
                            else {
                                var beforeroundoff = parseInt(datacount / columns);
                                var afterroundoff = parseInt(Math.round((datacount / columns)));
                                if (beforeroundoff === afterroundoff) {
                                    NoofRows = NoofRows + Math.round((datacount / columns) + 1);
                                    curr = curr + Math.round((datacount / columns) + 1);
                                }
                                else {
                                    NoofRows = NoofRows + Math.round((datacount / columns));
                                    curr = curr + Math.round((datacount / columns));
                                }

                            }
                        }
                        else {
                            NoofRows = NoofRows + 1;
                            curr = curr + 1;
                        }

                        //for get no of records for each row (i.e no of options)
                        for (var i = 1; i <= curr; i++) {
                            // if (datacountbal !== 0) {
                            if (datacountbal > columns) {
                                rowsanddatacount.push({
                                    count: columns
                                })
                                if (i === 1) {
                                    datacountbal = datacount - columns;
                                }
                                else {
                                    datacountbal = datacountbal - columns;
                                }
                            }
                            else {
                                rowsanddatacount.push({
                                    count: datacountbal
                                })
                            }

                            //}
                        }

                    })

                    //add multiple row counts into single value for get no of sheets
                    var addedcount = 0; var totalcountforpdf = 0; var pageno = 0;
                    for (var j = 0; j < rowsanddatacount.length; j++) {
                        totalcountforpdf = totalcountforpdf + rowsanddatacount[j].count;
                        addedcount++;
                        if (addedcount === Rows || (j === rowsanddatacount.length - 1)) {
                            SumofDataCounts.push({
                                totcountfotpdf: totalcountforpdf,
                                Pageno: pageno + 1
                            })
                            pageno = pageno + 1;
                            totalcountforpdf = 0;
                            addedcount = 0;
                        }

                    }

                    setrowsanddatacountforPdf(SumofDataCounts);
                    var subvalue = [...submitoptiosRefnoTrimPdf]
                    var fixedrows = [];
                    var slicestart = 0; var sliceend = 0;
                    //split datas for bind multiple sheets
                    for (var k = 1; k <= SumofDataCounts.length; k++) {

                        if (k === 1) {
                            slicestart = 0;
                            sliceend = SumofDataCounts[0].totcountfotpdf;
                            fixedrows = [...new Map(response.data.trimSubmittedOption.sort((a, b) => a.supplierReferenceNo - b.supplierReferenceNo).slice(slicestart, sliceend).map(item =>
                                [item['supplierReferenceNo'], item])).values()];

                            fixedrows.map(item => {
                                subvalue.push({
                                    Refno: item.supplierReferenceNo,
                                    Pageno: k
                                })
                            })
                            setsubmitoptiosRefnoTrimPdf(subvalue);
                            var SlicedData = response.data.trimSubmittedOption.sort((a, b) => a.supplierReferenceNo - b.supplierReferenceNo).slice(slicestart, sliceend);
                            SlicedData.map(pg => { pg.Pageno = k })
                            //  setsubmitoptiosTrimPdf(SlicedData);
                            ValAllSubmittedOption.push(
                                SlicedData
                            )

                            setAllsubmitoptiosTrimPdf(ValAllSubmittedOption);

                        }
                        else {

                            let startno = 0;
                            for (let x = 0; x < k - 1; x++) {
                                startno = startno + SumofDataCounts[x].totcountfotpdf;
                            }
                            slicestart = startno;
                            sliceend = SumofDataCounts[k - 1].totcountfotpdf;
                            fixedrows = [...new Map(response.data.trimSubmittedOption.sort((a, b) => a.supplierReferenceNo - b.supplierReferenceNo).slice(slicestart, slicestart + sliceend).map(item =>
                                [item['supplierReferenceNo'], item])).values()];

                            fixedrows.map(item => {
                                subvalue.push({
                                    Refno: item.supplierReferenceNo,
                                    Pageno: k
                                })
                            })
                            setsubmitoptiosRefnoTrimPdf(subvalue);

                            var AdditionalSlicedData = response.data.trimSubmittedOption.sort((a, b) => a.supplierReferenceNo - b.supplierReferenceNo).slice(slicestart, slicestart + sliceend);
                            if (AdditionalSlicedData.length !== 0) {
                                AdditionalSlicedData.map(pg => { pg.Pageno = k })
                                ValAllSubmittedOption.push(
                                    AdditionalSlicedData
                                )
                            }

                            setAllsubmitoptiosTrimPdf(ValAllSubmittedOption);
                        }


                    }

                    setSwatchdownload({ Condition: true, Format: Swatchdownload.Format, RowsandColumn: Swatchdownload.RowsandColumn });
                    // setSwatchCubeorBuyer(CubeorBuyer)
                    DownloadTrimSwatchCardPdf();


                }
            }).catch(() => { });
        }


    }

    const DownloadTrimSwatchCardPdf = () => {
        var timeout = 5000;
        showLoader();
        const doc = new jsPDF('p', 'pt', 'a4', true);
        const Page1 = document.getElementById("Page1");
        html2canvas(Page1, { scale: 5 }).then(canvas => {
            const imgData = canvas.toDataURL('image/png', 1.0);
            const imgWidth = 630;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            doc.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight, undefined, 'FAST');
            if (document.getElementById("Page2") !== null) {
                const Page2 = document.getElementById("Page2");
                html2canvas(Page2, { scale: 5 }).then(canvas => {
                    const imgData = canvas.toDataURL('image/png', 1.0);
                    const imgWidth = 630;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    doc.addPage();
                    doc.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight, undefined, 'FAST');


                })
            }
            if (document.getElementById("Page3") !== null) {
                const Page3 = document.getElementById("Page3");
                html2canvas(Page3, { scale: 5 }).then(canvas => {
                    const imgData = canvas.toDataURL('image/png', 1.0);
                    const imgWidth = 630;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    doc.addPage();
                    doc.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight, undefined, 'FAST');


                })
            }
            if (document.getElementById("Page4") !== null) {
                const Page4 = document.getElementById("Page4");
                html2canvas(Page4, { scale: 5 }).then(canvas => {
                    const imgData = canvas.toDataURL('image/png', 1.0);
                    const imgWidth = 630;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    doc.addPage();
                    doc.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight, undefined, 'FAST');


                })
            }

            // if (document.getElementById("Page5") !== null) {
            //     const Page5 = document.getElementById("Page5");
            //     html2canvas(Page5, { scale: 5 }).then(canvas => {
            //         const imgData = canvas.toDataURL('image/png', 1.0);
            //         const imgWidth = 630;
            //         const imgHeight = (canvas.height * imgWidth) / canvas.width;
            //         doc.addPage();
            //         doc.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight, undefined, 'FAST');

            //     })
            // }

            // if (document.getElementById("Page6") !== null) {
            //     timeout = 10000;
            //     const Page6 = document.getElementById("Page6");
            //     html2canvas(Page6, { scale: 5 }).then(canvas => {
            //         const imgData = canvas.toDataURL('image/png', 1.0);
            //         const imgWidth = 630;
            //         const imgHeight = (canvas.height * imgWidth) / canvas.width;
            //         doc.addPage();
            //         doc.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight, undefined, 'FAST');

            //     })
            // }

            // if (document.getElementById("Page7") !== null) {
            //     const Page7 = document.getElementById("Page7");
            //     html2canvas(Page7, { scale: 5 }).then(canvas => {
            //         const imgData = canvas.toDataURL('image/png', 1.0);
            //         const imgWidth = 630;
            //         const imgHeight = (canvas.height * imgWidth) / canvas.width;
            //         doc.addPage();
            //         doc.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight, undefined, 'FAST');

            //     })
            // }

            // if (document.getElementById("Page8") !== null) {
            //     const Page8 = document.getElementById("Page8");
            //     html2canvas(Page8, { scale: 5 }).then(canvas => {
            //         const imgData = canvas.toDataURL('image/png', 1.0);
            //         const imgWidth = 630;
            //         const imgHeight = (canvas.height * imgWidth) / canvas.width;
            //         doc.addPage();
            //         doc.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight, undefined, 'FAST');

            //     })
            // }

            // if (document.getElementById("Page9") !== null) {
            //     const Page9 = document.getElementById("Page9");
            //     html2canvas(Page9, { scale: 5 }).then(canvas => {
            //         const imgData = canvas.toDataURL('image/png', 1.0);
            //         const imgWidth = 630;
            //         const imgHeight = (canvas.height * imgWidth) / canvas.width;
            //         doc.addPage();
            //         doc.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight, undefined, 'FAST');

            //     })
            // }

            // if (document.getElementById("Page10") !== null) {
            //     const Page10 = document.getElementById("Page10");
            //     html2canvas(Page10, { scale: 5 }).then(canvas => {
            //         const imgData = canvas.toDataURL('image/png', 1.0);
            //         const imgWidth = 630;
            //         const imgHeight = (canvas.height * imgWidth) / canvas.width;
            //         doc.addPage();
            //         doc.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight, undefined, 'FAST');

            //     })
            // }
            var Format = Swatchdownload.Format === "1" ? "Cube" : "Buyer";
            setTimeout(() => {
                doc.save(Format + "TrimSwatchCard_" + props.TNATrimRefInfoId + ".pdf");
                doc.output('dataurlnewwindow', { filename: Format + "TrimSwatchCard_" + props.TNATrimRefInfoId + ".pdf" });
                hideLoader();
                setSwatchdownload({ Condition: false, Format: '' });

                TnaService.UpdateTrimSwatchStatus(props.TNATrimRefInfoId, 1, '', '', 3).then((res) => {
                    if (res.data) {
                        props.TrimSubmissionCallback(true, getOrderinfo, 'Save', res.data.outputResult)

                    }
                })
            }, timeout);

        });

    }


    const handleSwatchFormat = (event, feild) => {
       ;
        let input = '';
        if (event.target.value !== '') {
            input = event.target.value;
        }
        if (feild === "Format") {
            Swatchdownload.Format = input;
            if (input === "2") {
                setRowsColumnDdDisable(true);
            }
            else {
                setRowsColumnDdDisable(false);
            }
        }
        else if (feild === "RowColumns") {
            Swatchdownload.RowsandColumn = input;

        }
        setSwatchdownload({ Format: Swatchdownload.Format, RowsandColumn: Swatchdownload.RowsandColumn });

    }


    const CustomInput = (props) => {
       ;
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
            />
        )
    }
    const CustomInputAWB = (props) => {
       ;
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
                style={{ border: submittedawb && props.value === '' ? '1px solid red' : '' }}
            />
        )
    }
    // const MultiValueLabel = (props) => {
    //     const { innerProps, children, data } = props;
    //     return (
    //       <div title={data.name} {...innerProps}>
    //         {children}
    //       </div>
    //     );
    //   };

    return (

        <div id="registration-form">
            <Form>
                {
                    showMultiClosediv === true ?
                        <>

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="alert alert-info">
                                        Note : If you want to submit multiple, you can select multiple items.
                                        <span style={{ float: 'right' }}>
                                            {props.commonParams &&
                                                props.commonParams.SelectedDependency &&
                                                getSelectedDependencyMap(props.commonParams.SelectedDependency) === false &&

                                                <button type="button" className={props.commonParams.hasAllDependencyStart === 1 ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Dependency)"
                                                    onClick={() => OpenAllDependentStartPopup()}
                                                >
                                                    <i className={props.commonParams.hasAllDependencyStart === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>
                                                </button>
                                            }
                                        </span>
                                        {/* <span style={{ float: 'right' }}>
                                            <button type="button" className={props.commonParams.hasAllDependencyStart === 1 ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Dependency)"
                                                onClick={() => OpenAllDependentStartPopup()}
                                            >
                                                <i className={props.commonParams.hasAllDependencyStart === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>
                                            </button>
                                        </span> */}
                                    </div>
                                    <div className="dataTables_wrapper no-footer">
                                        {
                                            OrderInfoMulticloseMainDetails.length !== 0 ?

                                                <table className="table table-bordered">

                                                    <tbody>
                                                        {
                                                            OrderInfoMulticloseMainDetails.map((comment, mainindex) => (
                                                                <Fragment>
                                                                    <tr>
                                                                        <td>
                                                                            <div className="row">
                                                                                <div className="col-lg-8">
                                                                                    <i
                                                                                        className={
                                                                                            expandStateMultiSelect[comment.trimLogoPath] ?
                                                                                                'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                        }
                                                                                        onClick={() => handleExpandRowMultiSelect(comment.trimLogoPath, MultiCloseDatas)}
                                                                                    ></i>
                                                                                    <span className="BuyerPaddingLeft">
                                                                                        {
                                                                                            comment.trimCode
                                                                                        }
                                                                                        -
                                                                                        {
                                                                                            comment.trimName
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <span className="Actionbutton">
                                                                                        <img src={window.$APIBaseURL + "Images/Style/Trim/" + comment.trimLogoPath} height="50px" width="50px"></img>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </td>


                                                                    </tr>
                                                                    <>
                                                                        {
                                                                            expandedRowsMultiSelect.includes(comment.trimLogoPath) ?

                                                                                <tr>
                                                                                    <td>
                                                                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>Style Name</th>
                                                                                                    <th>Style No</th>
                                                                                                    <th>PO/ID No</th>
                                                                                                    <th>Details</th>
                                                                                                    <th>Task Holder / Follower</th>
                                                                                                    <th>
                                                                                                        <label style={{ marginTop: '8px' }}>
                                                                                                            <input type="checkbox" name="IsSelected"

                                                                                                                onChange={event => (SelectAll(event, comment.trimLogoPath, mainindex))}
                                                                                                                checked={comment.isSelectedAll}
                                                                                                                className="colored-blue clearOpacity" />
                                                                                                            <span className="text"></span>
                                                                                                        </label>

                                                                                                    </th>

                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {
                                                                                                    expandListMultiSelect.map((childdata, index) => (
                                                                                                        <Fragment>

                                                                                                            <tr>
                                                                                                                <td>{childdata.styleName}</td>
                                                                                                                <td>{childdata.styleNo}</td>
                                                                                                                <td>{childdata.idpoNo}</td>
                                                                                                                <td>{childdata.trimDetailsWithSize}</td>
                                                                                                                <td>{childdata.taskOwnerNameList}</td>
                                                                                                                <td className="Actionbutton fixed-column">
                                                                                                                    <div style={{ width: "80px" }}>
                                                                                                                        <label style={{ marginTop: '8px' }}>
                                                                                                                            {
                                                                                                                                props.commonParams !== undefined ?
                                                                                                                                    // (childdata.dependencyStartstop === 1 || childdata.isSelected === 1 || (!props.commonParams.SelectedDependency) || getSelectedDependencyMap(props.commonParams.SelectedDependency) === true
                                                                                                                                    // ) &&
                                                                                                                                    (childdata.Depandency !== '' && childdata.dependencyStartstop === 1) ?
                                                                                                                                        <input type="checkbox" name="IsSelected"
                                                                                                                                            onChange={event => (OnSelectRecords(event, childdata.index - 1, mainindex))}
                                                                                                                                            checked={childdata.isSelected === 1 ? true : false}
                                                                                                                                            className="colored-blue clearOpacity" />
                                                                                                                                        :
                                                                                                                                        childdata.Depandency === '' ?
                                                                                                                                            <input type="checkbox" name="IsSelected"
                                                                                                                                                onChange={event => (OnSelectRecords(event, childdata.index - 1, mainindex))}
                                                                                                                                                checked={childdata.isSelected === 1 ? true : false}
                                                                                                                                                className="colored-blue clearOpacity" /> : ''

                                                                                                                                    :
                                                                                                                                    <input type="checkbox" name="IsSelected"
                                                                                                                                        onChange={event => (OnSelectRecords(event, childdata.index - 1, mainindex))}
                                                                                                                                        checked={childdata.isSelected === 1 ? true : false}
                                                                                                                                        className="colored-blue clearOpacity" />

                                                                                                                            }
                                                                                                                            <span className="text"></span>

                                                                                                                        </label>&nbsp;&nbsp;&nbsp;
                                                                                                                        {
                                                                                                                            // props.commonParams && props.commonParams.SelectedDependency &&
                                                                                                                            //   getSelectedDependencyMap(props.commonParams.SelectedDependency) === false && childdata.StatusID !== 3 &&
                                                                                                                            childdata.Depandency !== '' &&
                                                                                                                            <button type="button" className={(childdata.dependencyStartstop === 1 || childdata.isSelected === 1) ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Dependency)" >
                                                                                                                                <i className={(childdata.dependencyStartstop === 1 || childdata.isSelected === 1) ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>
                                                                                                                            </button>
                                                                                                                        }

                                                                                                                    </div>

                                                                                                                </td>

                                                                                                            </tr>

                                                                                                        </Fragment>
                                                                                                    ))

                                                                                                }

                                                                                            </tbody>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr> : null
                                                                        }
                                                                    </>
                                                                </Fragment>
                                                            ))
                                                        }






                                                    </tbody>
                                                </table>
                                                :
                                                <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                    <TableHeader headers={headers} />
                                                    <tbody>
                                                        <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                                                    </tbody>
                                                </table>
                                        }
                                    </div>
                                </div>
                            </div>
                            <br />

                            <div className="row">
                                <div className="col-sm-12" align="right">
                                    <button type="button" className="btn btn-success" onClick={() => AddRows()}>

                                        <i className="fa fa-check right"></i> Add
                                    </button>
                                </div>
                            </div>
                        </>
                        : ''
                }
                {
                    showSubmissiondiv === true ?
                        <>
                            <div className="row" style={{ overflowX: "hidden", height: "700px", padding: "5px", margin: "0px" }}>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <div className='form-group'>
                                                <label htmlFor="BuyerID">Buyer-Season-Brand</label>
                                                <span className='input-icon icon-right'>
                                                    <Input
                                                        placeholder="BuyerSeasonBrand"
                                                        className="form-control cut_text"
                                                        title={inputFields[0].BuyerBrandSeasonName}
                                                        name="BuyerSeasonBrand"
                                                        id={
                                                            "BuyerSeasonBrand"
                                                        }
                                                        value={
                                                            inputFields[0].BuyerBrandSeasonName
                                                        }
                                                        disabled
                                                    ></Input>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="col-sm-3">
                                            <div className='form-group'>
                                                <label htmlFor="SeasonID" >Supplier</label>
                                                <span className='input-icon icon-right'>
                                                    <Input
                                                        placeholder="Supplier"
                                                        className="form-control cut_text"
                                                        title={inputFields[0].SupplierName}
                                                        name="Supplier"
                                                        id={
                                                            "Supplier"
                                                        }
                                                        value={
                                                            inputFields[0].SupplierName
                                                        }
                                                        disabled

                                                    ></Input>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="col-sm-3">
                                            <div className='form-group'>
                                                <label htmlFor="Name" >Submit Date<span className="text-danger">*</span></label>
                                                <span className='input-icon icon-right' style={{
                                                    border: submittedpopup && inputFields[0].SubmittedDate === '' ? '1px solid red' : '', textTransform: "uppercase"
                                                }}>
                                                    {
                                                        props.TaskStatus === 3 && (props.editTrimSubmission === false || chkDataExists === true) ?

                                                            <DatePicker className="form-control" name="SubmittedDate"
                                                                id={
                                                                    "SubmittedDate"
                                                                }
                                                                selected={
                                                                    inputFields[0].SubmittedDate
                                                                }

                                                                dateFormat="dd/MM/yyyy"
                                                                peekNextMonth
                                                                dropdownMode="scroll"
                                                                autoComplete="off"
                                                                customInput={<CustomInput />}
                                                                disabled
                                                            />





                                                            // <input
                                                            //     placeholder="SubmittedDate"
                                                            //     className="form-control"
                                                            //     title={inputFields[0].SubmittedDate}
                                                            //     name="SubmittedDate"
                                                            //     id={"SubmittedDate"}
                                                            //     value={inputFields[0].SubmittedDate}
                                                            //     disabled

                                                            // ></input>

                                                            :


                                                            <DatePicker className="form-control" name="SubmittedDate"
                                                                id={
                                                                    "SubmittedDate"
                                                                }
                                                                selected={
                                                                    inputFields[0].SubmittedDate
                                                                }
                                                                onChange={event => handleChange(event, 'SubmittedDate')}
                                                                dateFormat="dd/MM/yyyy"
                                                                peekNextMonth

                                                                dropdownMode="scroll"
                                                                autoComplete="off"
                                                                customInput={<CustomInput />}
                                                                isClearable={inputFields[0].SubmittedDate === null ? false : true}
                                                            />



                                                        // < input id="SubmittedDate" name="SubmittedDate"
                                                        //     value={inputFields[0].SubmittedDate}
                                                        //     placeholder="DD/MM/YYYY"
                                                        //     type="date"
                                                        //     autoComplete="off"
                                                        //     className={'form-control'}
                                                        //     onKeyDown={(e) => e.preventDefault()}
                                                        //     style={{
                                                        //         border: submittedpopup && inputFields[0].SubmittedDate === '' ? '1px solid red' : '', textTransform: "uppercase"
                                                        //     }}
                                                        //     onChange={event => handleChange(event, 'SubmittedDate')}
                                                        // />
                                                    }

                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor={"Quarter"}>Send To<span className="text-danger">*</span></label>
                                                <span className="input-icon icon-right">
                                                    {(props.editTrimSubmission === false || chkDataExists === true) && props.TaskStatus === 3 ?

                                                        <span>
                                                            <input
                                                                className="form-control"
                                                                title={inputFields[0].SendToDisplay}
                                                                value={inputFields[0].SendToDisplay}
                                                                disabled
                                                            />
                                                        </span>
                                                        :
                                                        <span className='' title={Array.isArray(inputFields[0].SendToEdit) ? inputFields[0].SendToEdit.map(item => item.name).join(', ') : ''}>
                                                            <Reactselect
                                                                className="basic-single SentTo SendToHeight"
                                                                name={"Sku"}
                                                                id={
                                                                    "Sent To"
                                                                }
                                                                // value={item.MeasurementPoints}
                                                                //isDisabled={false}
                                                                isLoading={false}
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                options={getTaskholder}
                                                                value={inputFields[0].SendToEdit}
                                                                isMulti
                                                                // components={{
                                                                //     MultiValueLabel,
                                                                //  }}
                                                                onChange={event => handlemultidd(event, 'SendTo')}
                                                                menuPosition="fixed"
                                                                styles={submittedpopup && inputFields[0].SendTo === "" ? styles2 : styles1}
                                                            // readOnly
                                                            // isDisabled={props.TaskStatus === 3 && (props.editLabdipSubmission === false || chkDataExists === true) ? true : false}
                                                            />
                                                        </span>

                                                    }
                                                    {/* <select id={"SendTo"} name="SendTo" className="form-select"
                                                        value={inputFields[0].SendTo}
                                                        onChange={(event) => handleChange(event, 'SendTo')}
                                                        style={{ border: submittedpopup && inputFields[0].SendTo === "0" ? '1px solid red' : '' }}
                                                        disabled={props.TaskStatus === 3 && (props.editTrimSubmission === false || chkDataExists === true) ? true : false}
                                                    >
                                                        <option value="0">-Select SendTo-</option>
                                                        {
                                                            getTaskholder.map(item => (
                                                                <option key={item.id} value={item.value}>
                                                                    {item.label}
                                                                </option>
                                                            ))
                                                        }
                                                    </select> */}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <div className='form-group'>
                                                <label htmlFor="BuyerID">Style Name  <span className="text-danger">*</span></label>
                                                <span className='input-icon icon-right'>
                                                    <Input
                                                        placeholder="StyleName"
                                                        className="form-control cut_text"
                                                        title={inputFields[0].StyleName}
                                                        name="StyleName"
                                                        id={
                                                            "StyleName"
                                                        }
                                                        value={
                                                            inputFields[0].StyleName
                                                        }
                                                        disabled
                                                    ></Input>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="col-sm-3">
                                            <div className='form-group'>
                                                <label htmlFor="SeasonID" >Style No  <span className="text-danger">*</span></label>
                                                <span className='input-icon icon-right'>
                                                    <Input
                                                        placeholder="StyleNo"
                                                        className="form-control cut_text"
                                                        title={inputFields[0].StyleNo}
                                                        name="StyleNo"
                                                        id={
                                                            "StyleNo"
                                                        }
                                                        value={
                                                            inputFields[0].StyleNo
                                                        }

                                                        disabled
                                                    ></Input>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="col-sm-3">
                                            <div className='form-group'>
                                                <label htmlFor="Name" > PO/ID No  <span className="text-danger">*</span></label>
                                                <span className='input-icon icon-right'>
                                                    <Input
                                                        placeholder="PO/ID No"
                                                        className="form-control cut_text"
                                                        title={inputFields[0].IdpoNo}
                                                        name="IDPONo"
                                                        id={
                                                            "IDPONo"
                                                        }
                                                        value={
                                                            inputFields[0].IdpoNo
                                                        }

                                                        disabled
                                                    ></Input>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor={"EndCustomer"}>End Customer  <span className="text-danger">*</span></label>
                                                <span className="input-icon icon-right">

                                                    <Input
                                                        placeholder="End Customer"
                                                        className="form-control cut_text"
                                                        title={inputFields[0].EndCustomer}
                                                        name="EndCustomer"
                                                        id={
                                                            "EndCustomer"
                                                        }
                                                        value={
                                                            inputFields[0].EndCustomer
                                                        }
                                                        disabled

                                                    ></Input>

                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor={"Sendthrough"}>Send Through<span className="text-danger">*</span></label>
                                                <select id={"Sendthrough"} name="Sendthrough" className="form-select"
                                                    value={inputFields[0].SendThrough}
                                                    onChange={event => handleChange(event, 'Sendthrough')}
                                                    disabled={props.TaskStatus === 3 && (props.editTrimSubmission === false || chkDataExists === true) ? true : false}
                                                    style={{ border: submittedpopup && inputFields[0].SendThrough === 0 ? '1px solid red' : '' }}
                                                >
                                                    <option value="0" key="0" style={{ color: '#bfdea1' }}> - Select Send Through- </option>
                                                    <option value="1" key="1">Hand Carried</option>
                                                    <option value="2" key="2">Courier - DHL</option>

                                                </select>


                                            </div>
                                        </div>
                                        {
                                            showawb === true ?
                                                <div className="col-sm-3">
                                                    <div className='form-group'>
                                                        <label htmlFor="Awbdate" >AWB / Date</label>
                                                        <span className='input-icon icon-right'>
                                                            <Input
                                                                placeholder="AWB / Date"
                                                                className="form-control cut_text"
                                                                title={inputFields[0].AWBDateandNo}
                                                                name="AWBDateandNo"
                                                                id={
                                                                    "AWBDateandNo"
                                                                }
                                                                value={
                                                                    inputFields[0].AWBDateandNo
                                                                }
                                                                dateFormat="dd/MM/yyyy"
                                                                onClick={() => trimModalPopup()}
                                                                //style={{ border: submittedpopup && inputFields[0].AWBDateandNo === '' ? '1px solid red' : '' }}
                                                                disabled={props.TaskStatus === 3 && (props.editTrimSubmission === false || chkDataExists === true) ? true : false}
                                                            ></Input>
                                                        </span>
                                                    </div>
                                                </div> : ''
                                        }

                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="tabbable">
                                            <ul className="nav nav-tabs nav-justified" id="myTab5">
                                                <li className="active">
                                                    <a data-toggle="tab" href="#idSubmitInformation">
                                                        <h5>Submit Information</h5>
                                                    </a>
                                                </li>
                                                <li className="" >
                                                    <a data-toggle="tab" href="#idOrderInformation" >

                                                        <h5>Order Information</h5>
                                                    </a>
                                                </li>
                                            </ul>
                                            <div className="tab-content">
                                                <div id="idSubmitInformation" className="tab-pane in active" style={{ width: "100%" }}>
                                                    <div className="row">
                                                        <div className="col-sm-10">
                                                            <div className="row">
                                                                <div className="col-sm-3">
                                                                    <div className='form-group'>
                                                                        <label htmlFor="BuyerID">Trim Code</label>
                                                                        <span className='input-icon icon-right'>
                                                                            <Input
                                                                                placeholder="Trim Code"
                                                                                className="form-control cut_text"
                                                                                title={inputFields[0].TrimCode}
                                                                                name="TrimCode"
                                                                                id={
                                                                                    "TrimCode"
                                                                                }
                                                                                value={
                                                                                    inputFields[0].TrimCode
                                                                                }
                                                                                disabled
                                                                            ></Input>
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-3">
                                                                    <div className='form-group'>
                                                                        <label htmlFor="SeasonID" >Trim Name</label>
                                                                        <span className='input-icon icon-right'>
                                                                            <Input
                                                                                placeholder="Trim Name"
                                                                                className="form-control cut_text"
                                                                                title={inputFields[0].TrimName}
                                                                                name="TrimName"
                                                                                id={
                                                                                    "TrimName"
                                                                                }
                                                                                value={
                                                                                    inputFields[0].TrimName
                                                                                }

                                                                                disabled
                                                                            ></Input>
                                                                        </span>
                                                                    </div>
                                                                </div>



                                                                <div className="col-sm-3">
                                                                    <div className="form-group">
                                                                        <label htmlFor={"Quarter"}>Technical Specification</label>
                                                                        <span className="input-icon icon-right">

                                                                            <Input
                                                                                placeholder="Technical Specification"
                                                                                className="form-control cut_text"
                                                                                title={inputFields[0].TechnicalSpecification}
                                                                                name="TechnicalSpecification"
                                                                                id={
                                                                                    "TechnicalSpecification"
                                                                                }
                                                                                value={
                                                                                    inputFields[0].TechnicalSpecification
                                                                                }

                                                                                disabled
                                                                            ></Input>

                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-3">
                                                                    <div className="form-group">
                                                                        <label htmlFor={"Quarter"} style={{ marginLeft: "20px" }}>Trim Image </label>
                                                                        <br />
                                                                        {/* <i className="fa fa-chevron-circle-left fa-2x" onClick={() => previmage()} style={{ cursor: "pointer" }} ></i> */}
                                                                        {
                                                                            getSubImageList.length !== 0 ?
                                                                                <img src={window.$APIBaseURL + "Images/Style/Trim/" + getSubImageList[getCurrimageindex]} alt="No Image" width="150px" height="150px" style={{ border: "2px solid black" }} />
                                                                                :
                                                                                <div align="center" style={{ width: "150px", height: "150px" }}>
                                                                                    No Images Found
                                                                                </div>
                                                                        }
                                                                        {/* <i className="fa fa-chevron-circle-right fa-2x" onClick={() => nextimage()} style={{ cursor: "pointer" }}></i> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <div className="form-group">

                                                                        <label htmlFor={"TrimReferenceID"}>Trim Dimension <span className="text-danger">*</span></label>

                                                                        <select id={"TrimReferenceID"} name="TrimReferenceID" className="form-select"
                                                                            value={inputFields[0].TrimReferenceID}
                                                                            onChange={(event) => handleChange(event, 'TrimReferenceID')}
                                                                            style={{ border: submittedpopup && inputFields[0].TrimReferenceID === "0" ? '1px solid red' : '' }}
                                                                            disabled={props.TaskStatus === 3 ? true : false}
                                                                        >
                                                                            <option value="0">-Select Trims-</option>
                                                                            {
                                                                                TrimDimension.map(item => (
                                                                                    <option key={item.id} value={item.value}>
                                                                                        {item.label}
                                                                                    </option>
                                                                                ))
                                                                            }

                                                                        </select>




                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <table>
                                                                    <tr>
                                                                        <td><label>Submit Options</label> </td>
                                                                        <td><label>Submission Swatch Card
                                                                            <br></br>(Click to download)</label></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <div className="col-sm-6">
                                                                            <div className="form-group">


                                                                                <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup"
                                                                                    style={{ width: "500px" }}>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th >Options <span className="text-danger">*</span></th>
                                                                                            <th>Supplier Reference No <span className="text-danger">*</span></th>
                                                                                            <th >Action</th>

                                                                                        </tr>

                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            inputFields[0].TNATrimSubmittedOption.map((item, index) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <span className="input-icon icon-right">
                                                                                                                    <Input
                                                                                                                        placeholder=""
                                                                                                                        className="form-control cut_text"
                                                                                                                        title={item.OptionName}
                                                                                                                        name="OptionName"
                                                                                                                        id={
                                                                                                                            "OptionName"
                                                                                                                        }
                                                                                                                        value={
                                                                                                                            item.OptionName
                                                                                                                        }
                                                                                                                        onChange={event => (handleSubmitoptionchange(event, 'OptionName', index))}
                                                                                                                        style={{ width: "250px", border: submitted && item.OptionName === '' ? '1px solid red' : '' }}
                                                                                                                        disabled={props.TaskStatus === 3 && (props.editTrimSubmission === false || chkDataExists === true) ? true : false}
                                                                                                                    ></Input>


                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <span className='input-icon icon-right'>
                                                                                                                    <Input
                                                                                                                        placeholder=""
                                                                                                                        className="form-control cut_text"
                                                                                                                        title={item.SupplierReferenceNo}
                                                                                                                        name="SupplierReferenceNo"
                                                                                                                        id={
                                                                                                                            "SupplierReferenceNo"
                                                                                                                        }
                                                                                                                        value={
                                                                                                                            item.SupplierReferenceNo
                                                                                                                        }
                                                                                                                        autocomplete="off"
                                                                                                                        onChange={event => (handleSubmitoptionchange(event, 'SupplierReferenceNo', index))}
                                                                                                                        style={{ width: "250px", border: submitted && item.SupplierReferenceNo === '' ? '1px solid red' : '' }}
                                                                                                                        disabled={props.TaskStatus === 3 && (props.editTrimSubmission === false || chkDataExists === true) ? true : false}
                                                                                                                    ></Input>
                                                                                                                </span>

                                                                                                            </td>
                                                                                                            <td className="Actionbutton fixed-column">
                                                                                                                {" "}
                                                                                                                <div style={{ width: "80px" }}>
                                                                                                                    {inputFields[0].TNATrimSubmittedOption.length !== 1 && (
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn  btn-xs  btn-danger"
                                                                                                                            title="Delete Option"
                                                                                                                            onClick={() =>
                                                                                                                                handleRemoveFields(index)
                                                                                                                            }
                                                                                                                            disabled={props.TaskStatus === 3 && (props.editTrimSubmission === false || chkDataExists === true) ? true : false}
                                                                                                                        >
                                                                                                                            <i className="fa fa-trash-o"></i>
                                                                                                                        </button>
                                                                                                                    )}
                                                                                                                    &nbsp;
                                                                                                                    {inputFields[0].TNATrimSubmittedOption.length === index + 1 && (
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn  btn-xs  btn-success"
                                                                                                                            title="Add Option"
                                                                                                                            onClick={() =>
                                                                                                                                handleAddField(index)
                                                                                                                            }
                                                                                                                            disabled={props.TaskStatus === 3 && (props.editTrimSubmission === false || chkDataExists === true) ? true : false}
                                                                                                                        >
                                                                                                                            <i className="fa fa-plus"></i>
                                                                                                                        </button>
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </>

                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                        <td>
                                                                            <div className={ImgClassName}
                                                                                htmlFor="upload-button"
                                                                            >
                                                                                {
                                                                                    props.SwatchStatus === 2 ?
                                                                                        <img
                                                                                            src={inputFields[0].SwatchCardIamgePath === "" || inputFields[0].SwatchCardIamgePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + inputFields[0].SwatchCardIamgePath}
                                                                                            onClick={downloadSwatchcard}
                                                                                            alt="AltPicture" style={{ marginBottom: '5px', height: '200px', width: '200px', border: "1px solid #d5d5d5" }} />
                                                                                        :
                                                                                        <>
                                                                                            <img
                                                                                                src={inputFields[0].SwatchCardIamgePath === "" || inputFields[0].SwatchCardIamgePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + inputFields[0].SwatchCardIamgePath}
                                                                                                // src={"assets/img/emptyImage.jpg"}
                                                                                                onClick={openFileUpload}
                                                                                                alt="AltPicture" style={{ marginBottom: '5px', height: '200px', width: '200px', border: "1px solid #d5d5d5" }} />
                                                                                            <input type="file" id="upload-button" accept=".jpg,.jpeg,.png" onChange={(event) => UploadImages(event)} value=''
                                                                                                disabled={(props.TrimswatchUpload === true && props.SwatchStatus === 1) ? false : true}
                                                                                                multiple
                                                                                            //disabled={props.TaskStatus === 3 && (props.editLabdipSubmission === false || chkDataExists === true) ? true : false}
                                                                                            />
                                                                                        </>

                                                                                }
                                                                                {
                                                                                    inputFields.SwatchImageFileName !== "assets/img/uploadimage.jpg" && (
                                                                                        <div style={
                                                                                            {
                                                                                                marginTop: '8px',
                                                                                                float: 'right',
                                                                                                bottom: '10px',
                                                                                                position: 'relative',
                                                                                            }
                                                                                        }>

                                                                                        </div>
                                                                                    )
                                                                                }

                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </table>




                                                                <div> {
                                                                    isOpen && (

                                                                        <Lightbox mainSrc={window.$APIBaseURL + 'Images/SwatchImages/' + getImagename}
                                                                            alt="bg image"
                                                                            onCloseRequest={
                                                                                () => setIsOpen(false)
                                                                            } />
                                                                    )
                                                                } </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="idOrderInformation" className="tab-pane" style={{ width: "100%" }}>
                                                    <div className="row">
                                                        <div className="col-sm-12" align={"right"}>
                                                            <button type="button" className="btn btn-primary" onClick={() => ShowHideDivs()}
                                                                disabled={props.TaskStatus === 3 ? true : false}>
                                                                <i className="fa fa-plus"></i> &nbsp;Add

                                                            </button>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="dataTables_wrapper no-footer">
                                                                {
                                                                    OrderInfoMainDetails.length !== 0 ?

                                                                        <table className="table table-bordered">

                                                                            <tbody>
                                                                                {

                                                                                    OrderInfoMainDetails.map(comment => (
                                                                                        <Fragment>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <div className="row">
                                                                                                        <div className="col-lg-8">
                                                                                                            <i
                                                                                                                className={
                                                                                                                    expandState[comment.trimLogoPath] ?
                                                                                                                        'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                                }
                                                                                                                onClick={() => handleExpandRow(comment.trimLogoPath, getOrderinfo)}
                                                                                                            ></i>
                                                                                                            <span className="BuyerPaddingLeft">
                                                                                                                {
                                                                                                                    comment.trimCode
                                                                                                                }
                                                                                                                -
                                                                                                                {
                                                                                                                    comment.trimName
                                                                                                                }
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        <div className="col-lg-4">
                                                                                                            <span className="Actionbutton">
                                                                                                                <img src={window.$APIBaseURL + "Images/Style/Trim/" + comment.trimLogoPath} height="50px" width="50px"></img>
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </td>

                                                                                            </tr>
                                                                                            <>
                                                                                                {
                                                                                                    expandedRows.includes(comment.trimLogoPath) ?

                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                                                    <TableHeader headers={headers} />
                                                                                                                    <tbody>
                                                                                                                        {
                                                                                                                            expandList.map((childdata, index) => (
                                                                                                                                <Fragment>

                                                                                                                                    <tr>
                                                                                                                                        <td>{childdata.styleName}</td>
                                                                                                                                        <td>{childdata.styleNo}</td>
                                                                                                                                        <td>{childdata.idpoNo}</td>
                                                                                                                                        <td>{childdata.trimDetailsWithSize}</td>
                                                                                                                                        <td>{childdata.taskOwnerNameList}</td>
                                                                                                                                        <td className="Actionbutton fixed-column">
                                                                                                                                            {" "}
                                                                                                                                            <div style={{ width: "80px" }}>
                                                                                                                                                <label style={{ marginTop: '8px' }}>
                                                                                                                                                    <input type="checkbox" name="SelectedDetail"

                                                                                                                                                        onChange={event => (OnChangeOrderinfo(event, index))}
                                                                                                                                                        checked={childdata.SelectedDetail === 1 ? true : false}
                                                                                                                                                        className="colored-blue clearOpacity"
                                                                                                                                                        disabled={props.TaskStatus === 3 ? true : false} />
                                                                                                                                                    <span className="text"></span>
                                                                                                                                                </label>
                                                                                                                                                &nbsp;&nbsp;

                                                                                                                                                <button
                                                                                                                                                    type="button" style={{ display: getOrderinfo.length === 1 ? 'none' : '' }}
                                                                                                                                                    className="btn  btn-xs  btn-danger"
                                                                                                                                                    title="Delete Trim"
                                                                                                                                                    onClick={() =>
                                                                                                                                                        handleRemoveSample(index)
                                                                                                                                                    }
                                                                                                                                                    disabled={props.TaskStatus === 3 ? true : false}
                                                                                                                                                >
                                                                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                                                                </button>

                                                                                                                                            </div>
                                                                                                                                        </td>

                                                                                                                                    </tr>

                                                                                                                                </Fragment>
                                                                                                                            ))

                                                                                                                        }

                                                                                                                    </tbody>
                                                                                                                </table>
                                                                                                            </td>
                                                                                                        </tr> : null
                                                                                                }
                                                                                            </>
                                                                                        </Fragment>
                                                                                    ))
                                                                                }


                                                                            </tbody>
                                                                        </table>
                                                                        :
                                                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                                            <TableHeader headers={headers} />
                                                                            <tbody>
                                                                                <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                                                                            </tbody>
                                                                        </table>
                                                                }



                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />


                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                                <div className="mt-10">
                                    <div className="row">
                                        <div className="col-sm-8 col-lg-8">
                                            <div className='form-group'>
                                                <label htmlFor="Remarks">Remarks</label>
                                                <textarea maxLength="300" name="Remarks" value={inputFields[0].Remarks}
                                                    onChange={event => handleChange(event, 'Remarks')}
                                                    rows="4" style={{ width: "100%" }}
                                                    disabled={props.TaskStatus === 3 && (props.editTrimSubmission === false || chkDataExists === true) ? true : false}
                                                ></textarea>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12" align="right">
                                    <button type="button" className="btn btn-success margin-Align" onClick={() => SaveSubmission()}
                                        disabled={props.TaskStatus === 3 && (props.editTrimSubmission === false || chkDataExists === true) || buttonLoader ? true : false}>

                                        <i className="fa fa-check right"></i> &nbsp;{(props.editTrimSubmission === false || chkDataExists === true) ? 'Save' : 'Update'}
                                    </button>
                                </div>
                            </div>
                            {
                                ShowTrimPopup === true ?
                                    <Modal show={ShowTrimPopup === true} size="md" dragable backdrop="static" keyboard={false} onHide={() => CloseAwbpopup()} >
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                AWB and Date
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: "350px" }}>AWB<span className="text-danger">*</span></th>
                                                        <th style={{ width: "300px" }}>Date<span className="text-danger">*</span></th>
                                                        {/* <th styles={{ width: "100px" }}>Action</th> */}
                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    {
                                                        getStrikeOffDate.map((item, index) => {
                                                            return (
                                                                <>
                                                                    <tr>
                                                                        <td>
                                                                            <span className='input-icon icon-right'>
                                                                                <input type="text"
                                                                                    placeholder="AWB Name"
                                                                                    id={"AWBName"}
                                                                                    name="AWBName"
                                                                                    value={inputFields[0].AWBName}
                                                                                    style={{ border: submittedawb && !inputFields[0].AWBName ? '1px solid red' : '' }}
                                                                                    autoFocus
                                                                                    maxLength="25"
                                                                                    className={'form-control'}
                                                                                    onChange={event => handlepopupchange(event, 'AWBName')}
                                                                                // disabled={ChildField.DependencyID === 0 ? true : false}
                                                                                />
                                                                            </span>

                                                                        </td>
                                                                        <td>

                                                                            <DatePicker className="form-control" name="AWBDate"
                                                                                id={
                                                                                    "AWBDate"
                                                                                }
                                                                                selected={
                                                                                    inputFields[0].AWBDate
                                                                                }
                                                                                onChange={event => handlepopupchange(event, 'AWBDate')}
                                                                                dateFormat="dd/MM/yyyy"
                                                                                peekNextMonth
                                                                                style={{ border: submittedawb && inputFields[0].AWBDate === '' ? '1px solid red' : '' }}
                                                                                dropdownMode="scroll"
                                                                                autoComplete="off"
                                                                                customInput={<CustomInputAWB />}
                                                                                isClearable={inputFields[0].AWBDate === null ? false : true}
                                                                            />


                                                                            {/* <span className="input-icon icon-right">
                                                                                <input id={"AWBDate"} name="AWBDate"
                                                                                    value={inputFields[0].AWBDate} placeholder="DD/MM/YYYY"
                                                                                    type="date" autoComplete="off" onKeyDown={(e) => e.preventDefault()}
                                                                                    className={'form-control'}
                                                                                    style={{ border: submittedawb && inputFields[0].AWBDate === '' ? '1px solid red' : '' }}
                                                                                    onChange={event => handlepopupchange(event, 'AWBDate')}
                                                                                />
                                                                            </span> */}
                                                                        </td>

                                                                    </tr>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="success" onClick={() => SaveAwbnoDate()}>
                                                <i className="fa fa-check right"></i>&nbsp;
                                                Save
                                            </Button>
                                        </Modal.Footer>
                                    </Modal> : ''
                            }

                            {
                                ShowAddTrimDimensionPopup === true ?
                                    <Modal show={ShowAddTrimDimensionPopup} size="md" dragable backdrop="static" keyboard={false} onHide={() => setShowAddTrimDimensionPopup(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                Add Trim Dimension
                                            </Modal.Title>
                                        </Modal.Header>
                                        {/* <Modal.Body>
                                  <Fabric
                                    FabricList={getFabricList}
                                    FabricCallBack={FabricCallback}
                                    showpopup={true}
                                    GroupTypeId={0}
                                  />
                                </Modal.Body> */}
                                    </Modal> : ''
                            }
                        </>
                        : ''
                }
                {
                    values[0].hasAllDependencyStartPopup &&
                    <Modal show={values[0].hasAllDependencyStartPopup} size="lg" dragable backdrop="static" keyboard={false} onHide={() => CloseAllDependencyStartPopup()}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Multi Dependency
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Dependencytable">
                                <thead>
                                    <tr>
                                        <th>Date<span className="text-danger">*</span></th>
                                        <th>Comments<span className="text-danger">*</span></th>

                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td>
                                            {
                                                <DatePicker className="form-control" name="DependencyDate"


                                                    // selected={new Date(ManualInfo[0].manualCloseDate)}
                                                    selected={Date.parse(moment(values[0].MultiDependencyDate, 'MM/DD/YYYY').toISOString())}
                                                    onChange={event => handleChangeMultiDependentStart(event, "CommentDate", false)}
                                                    dateFormat="dd/MM/yyyy"
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    dropdownMode="scroll"
                                                    autoComplete="off"
                                                    minDate={new Date()}
                                                    //  maxDate={new Date()}
                                                    customInput={<CustomInputDependency />}

                                                />


                                            }

                                        </td>
                                        <td style={{ width: "600px" }} >
                                            <textarea
                                                rows="3"
                                                placeholder="Enter Comments here..."
                                                autoFocus onFocus={""} autoComplete="off"
                                                maxLength="500" name="EnterRemarks"
                                                value={values[0].MultiDependencyComment}
                                                onChange={event => handleChangeMultiDependentStart(event, "Comment", false)}
                                                style={{ width: "100%", border: values[0].submitDependencyStart && !values[0].MultiDependencyComment ? '1px solid red' : '' }}>
                                            </textarea>
                                        </td>

                                    </tr>
                                </tbody>

                            </table>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="success" onClick={() => handleSaveAllDependencyStart()}>

                                Save
                            </Button>
                        </Modal.Footer>
                    </Modal>

                }
                {
                    getID.showPopupDelete ? <SweetAlertPopup data={
                        getID.Params
                    }
                        deleteCallback={Delcallback}
                        showpopup={true}
                        pageActionId={TrimSubmissionApprovalId}
                        Msg={"Trim Submission Deleted successfully."} /> : null
                }

                {
                    SwatchDownloadformat === true ?
                        <div align="center">
                            <Modal show={SwatchDownloadformat} dialogClassName='centerModal' size="md" dragable backdrop="static" keyboard={false} onHide={() => setSwatchDownloadformat(false)}>
                                <Modal.Header closeButton style={{ background: "white" }}>
                                    <Modal.Title>
                                        Swatch Card Create
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{ height: "14vh" }}>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className='form-group'>
                                                <label htmlFor="Awbdate" >Swatch Format</label><span className="text-danger">*</span>
                                                <span className='input-icon icon-right'>
                                                    <Select placeholder="" id="Format" name="Format" //className="form-control"
                                                        value={Swatchdownload.Format}
                                                        onChange={event => handleSwatchFormat(event, "Format")}
                                                        type="text"
                                                        autoComplete="off"
                                                        maxLength="50"
                                                        className={'form-control'}
                                                        style={{ border: submittedSwatchFormat && Swatchdownload.Format === "0" ? '1px solid red' : '' }}
                                                    >
                                                        <option value="0">- Select Format -</option>
                                                        <option value="1">Cube</option>
                                                        <option value="2">Buyer</option>

                                                    </Select>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className='form-group'>
                                                <label htmlFor="Awbdate" >Option Space Alignment</label><span className="text-danger">*</span>
                                                <span className='input-icon icon-right'>
                                                    <Select placeholder="" id="RowColumns" name="RowColumns" //className="form-control"
                                                        value={Swatchdownload.RowsandColumn}
                                                        onChange={event => handleSwatchFormat(event, "RowColumns")}
                                                        type="text"
                                                        autoComplete="off"
                                                        maxLength="50"
                                                        className={'form-control'}
                                                        style={{ border: submittedSwatchFormat && Swatchdownload.RowsandColumn === "0" ? '1px solid red' : '' }}
                                                        disabled={RowsColumnDdDisable}
                                                    >
                                                        <option value="0">- Select Space Alignment -</option>
                                                        <option value="1">3 Rows 3 Column</option>
                                                        <option value="2">2 Rows 2 Column</option>
                                                        <option value="3">1 Row 1 Column</option>
                                                        <option value="4">1 Row 2 Column</option>
                                                        <option value="5">1 Row 3 Column</option>
                                                        <option value="6">1 Row 4 Column</option>
                                                        <option value="7">2 Rows 3 Column</option>
                                                        <option value="8">2 Rows 4 Column</option>
                                                        <option value="9">2 Rows 1 Column</option>
                                                        <option value="10">3 Rows 1 Column</option>
                                                        <option value="11">4 Rows 1 Column</option>
                                                    </Select>
                                                </span>
                                            </div>
                                        </div>
                                    </div>




                                </Modal.Body>

                                <Modal.Footer>
                                    <Button variant="success" onClick={() => GenerateTrimSwatchCardPdf()}>
                                        Add / Create
                                    </Button>
                                </Modal.Footer>

                            </Modal>
                        </div>
                        : ''

                }



                {
                    Swatchdownload.Condition === true && Swatchdownload.Format === '1' ?
                        rowsanddatacountforPdf.map(pages => {
                            return (
                                <>
                                    <div id={"Page" + pages.Pageno} style={{ background: "white" }}  >
                                        <div className="page-body CustomPdfFont"  >
                                            <div className="row">
                                                <div class="col-xs-6 col-md-8">
                                                    <div className="widget" style={{ width: '900px' }}>
                                                        <div className="header" style={{ border: "1px solid black", display: "flex", justifyContent: "center", background: "white" }}>
                                                            <div style={{ display: "inline-flex", alignItems: "center" }}>
                                                                <img src="assets/img/MainMenu/ICube_logo.PNG" alt="" width={"150%"} height="80vh" />
                                                            </div>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                                            <div >
                                                                <h1 className='text-center'> <b>CUBE FASHIONS</b>  </h1>
                                                                <h3 style={{ margin: "0" }} className='text-center'> <b>TRIM SUBMISSION FORM</b></h3> <br />
                                                            </div>
                                                        </div>

                                                        <div style={{
                                                            border: "1px solid black", padding: "2rem", borderBottom: "none", borderTop: "none",
                                                            background: "white", fontSize: "16px"
                                                        }}>
                                                            <div className="row" style={{ marginTop: "-15px" }} >
                                                                <div className="col-lg-6">
                                                                    <ul className='cfformUL'>
                                                                        <li className='cfformLI'><b className='boldtextStrikeoff'>Attn</b>: {basicdataTrimPdf[0].sendToNames}</li>
                                                                        <li className='cfformLI'><b className='boldtextStrikeoff'>Buyer</b>: {basicdataTrimPdf[0].buyerName}</li>
                                                                        <li className='cfformLI'><b className='boldtextStrikeoff'>Brand</b>: {basicdataTrimPdf[0].brandName}</li>
                                                                        <li className='cfformLI'><b className='boldtextStrikeoff'>Supplier</b>: {basicdataTrimPdf[0].supplierName}</li>
                                                                        <li className='cfformLI'><b className='boldtextStrikeoff'>Trim Name</b>: {basicdataTrimPdf[0].trimName}</li>
                                                                        {/* <li className='cfformLI'><b className='boldtext'>End Customer</b>: {basicdataTrimPdf[0].endCustomerNameIdList}</li> */}


                                                                    </ul>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <ul className='cfformUL'>
                                                                        <li className='cfformLI'><b className='boldtextStrikeoff'>Date  </b>: {basicdataTrimPdf[0].submittedDate}</li>
                                                                        <li className='cfformLI'><b className='boldtextStrikeoff'>Season</b>: {basicdataTrimPdf[0].seasonName}</li>
                                                                        <li className='cfformLI'><b className='boldtextStrikeoff'>ID / PO No</b>: {basicdataTrimPdf[0].idpoNo}</li>
                                                                        {/* <li className='cfformLI'><b className='boldtext'>Style Name</b>: {basicdataTrimPdf[0].styleName}</li> */}
                                                                        <li className='cfformLI'><b className='boldtextStrikeoff'>Submitted For</b>: SMS </li>
                                                                        <li className='cfformLI'><b className='boldtextStrikeoff'>Code</b>: {basicdataTrimPdf[0].trimCode}</li>

                                                                        {/* <li className='cfformLI'><b className='boldtext'>Content </b>: {basicdataTrimPdf[0].fabricContent} </li> */}
                                                                    </ul>
                                                                </div>

                                                            </div>

                                                            <div className="row"  >
                                                                <div className="col-lg-12" style={{ marginTop: "-10px" }} >
                                                                    <ul className='cfformUL'>
                                                                        <li className='cfformLI'><b style={{ marginRight: "102px" }}>Styles</b>: {basicdataTrimPdf[0].styleName}</li>
                                                                        {/* <li className='cfformLI'><b className='boldtext'>Ref/Order No</b>: {basicdataTrimPdf[0].sendToNames}</li>
                                    <li className='cfformLI'><b className='boldtext'>Story</b>: {basicdataTrimPdf[0].storyName}</li>
                                    <li className='cfformLI'><b className='boldtext'>Theme</b>: {basicdataTrimPdf[0].themeName}</li> */}


                                                                    </ul>
                                                                </div>
                                                            </div>

                                                            {/* <div className="row" style={{ marginTop: "22px" }}>
                                                                <div className="col-lg-8">
                                                                    <ul className='cfformUL'>
                                                                        <li className='cfformLI'><b className='boldtext'>Color </b>&nbsp; &nbsp; &nbsp;  : {basicdataTrimPdf[0].pantoneColorOrOrSwatchImage} </li>

                                                                        <li className='cfformLI'><b className='boldtext'>Code </b>&nbsp; &nbsp; &nbsp; : {basicdataTrimPdf[0].pantoneCode} - {basicdataTrimPdf[0].pantoneNameOrSwatchName} - {basicdataTrimPdf[0].pantoneTypeName}</li>

                                                                    </ul>
                                                                </div>
                                                                <div className="col-lg-4">

                                                                </div>

                                                            </div> */}



                                                            <div className="row" style={{ height: '760px', marginTop: "27px" }}>



                                                                <div className="row">
                                                                </div>
                                                                {
                                                                    submitoptiosRefnoTrimPdf.filter(x => x.Pageno === pages.Pageno).map(refnos => {
                                                                        return (
                                                                            <>
                                                                                <div className="row" style={{ marginLeft: "2px" }}>
                                                                                    <div className="col-lg-4">
                                                                                        <ul className='cfformUL'>
                                                                                            <li className='cfformLI'><b className='boldtext'>Pantone/CR Ref</b>: {refnos.Refno}</li>

                                                                                        </ul>

                                                                                    </div>
                                                                                    <div className="col-lg-4">

                                                                                    </div>
                                                                                    <div className="col-lg-4">

                                                                                    </div>
                                                                                    <div className="row">
                                                                                    </div>
                                                                                </div>
                                                                                {

                                                                                    AllsubmitoptiosTrimPdf[pages.Pageno - 1].filter(so => so.supplierReferenceNo === refnos.Refno &&
                                                                                        so.Pageno === refnos.Pageno).map((options, row) => {

                                                                                            return (
                                                                                                <>
                                                                                                    {

                                                                                                        // <div className="row">
                                                                                                        <div className={"col-lg-" + divcolumns} align="center">
                                                                                                            <label htmlFor="" ><b>Option</b> "{options.optionName}"</label>
                                                                                                            <div className="row">
                                                                                                                <div className="col-lg-6">
                                                                                                                    <div class={SwatchBoxCssClass}>

                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        // </div>
                                                                                                    }

                                                                                                </>)

                                                                                        })



                                                                                }
                                                                                <div className="row">

                                                                                </div>




                                                                            </>
                                                                        )



                                                                    })

                                                                }





                                                            </div>

                                                            <div className="row" style={{ marginTop: '28px' }}>
                                                                <div className="col-lg-12">
                                                                    <ul className='cfformUL'>
                                                                        <li className='cfformLI'><b className='boldtext'>Submission Remarks  </b>: {basicdataTrimPdf[0].remarks}</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <ul className='cfformUL'>
                                                                        <li className='cfformLI'><b className='boldtext'>Approval Remarks  </b>:</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="widget-footer" style={{ border: "1px solid black", backdropFilter: "white", paddingLeft: "1rem", backgroundColor: "white" }}>
                                                            <h2 style={{ marginTop: "5px", marginBottom: "2px" }}>Cube Fashions (P) Ltd</h2>

                                                            <h5 style={{ marginBottom: "2px" }}>20/4,Jai Nagar Maniyakaram Palayam Road, Rakkiya Palayam Cross, Kangayam Road Tirupur, INDIA - 641606</h5>

                                                            <h5 style={{ marginBottom: "2px" }}>  Tel: 0091 421 4355958, 4255959, Fax: 0091 421 4355671, Email: info@cubefashions.com</h5>
                                                        </div>
                                                        &nbsp;
                                                        <div className="row">
                                                            <div className="col-lg-6" align='right'>
                                                                <b>Page {pages.Pageno} of {rowsanddatacountforPdf.length}</b>
                                                            </div>
                                                            <div className="col-lg-6" align='right'>
                                                                <b>Ref no : {props.TNATrimRefInfoId}</b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </>
                            )
                        })
                        :
                        Swatchdownload.Condition === true && Swatchdownload.Format === '2' ?
                            rowsanddatacountforPdf.map(pages => {
                                return (
                                    <>
                                        <div id={"Page" + pages.Pageno} >
                                            <div className="page-body CustomPdfFont" style={{ background: "white" }}>
                                                <div className="row">
                                                    <div class="col-xs-6 col-md-6">
                                                        <div className="widget" style={{ width: '900px' }}>
                                                            <div className="header" style={{ border: "1px solid black", display: "flex", background: "black", height: "9.2vh" }}>
                                                                <div style={{ display: "inline-flex", alignItems: "center" }}>
                                                                    <img src="assets/img/MainMenu/Capture.PNG" alt="" width={"221%"} height={"68vh"} />
                                                                </div>
                                                                <div style={{ display: "inline-flex" }}>
                                                                    <h1 className='text-center' >  <span style={{ color: "red" }}> &nbsp; TRIM</span>  <span style={{ color: "white" }}>SUBMISSION FORM</span> </h1>

                                                                </div>
                                                            </div>

                                                            <div style={{
                                                                border: "1px solid black", borderBottom: "none",
                                                                borderTop: "none", padding: "0rem", background: "white"
                                                            }}>
                                                                <div className="row">
                                                                    <div className="col-lg-12" >
                                                                        <table style={{ width: "100%" }}>
                                                                            <tr>
                                                                                <th style={{ width: "20%" }} className='formtable' >SUPPLIER</th>
                                                                                <td style={{ width: "30%" }} className='formtable'> {basicdataTrimPdf[0].supplierName}</td>
                                                                                <th style={{ width: "21%" }} className='formtable'>DATE OF ISSUE</th>
                                                                                <td className='formtable'>{basicdataTrimPdf[0].submittedDate}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th className='formtable' >FROM</th>
                                                                                <td className='formtable'>Cube Fashions</td>
                                                                                <th className='formtable'>BRANDING CODE</th>
                                                                                <td className='formtable'>{basicdataTrimPdf[0].trimCode} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th className='formtable' >ATTN</th>
                                                                                <td className='formtable'>{basicdataTrimPdf[0].sendToNames}</td>
                                                                                <th className='formtable'>COLOURWAY</th>
                                                                                <td className='formtable cut_textInTNA'>{basicdataTrimPdf[0].colourway} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th className='formtable' >ID NO/CUSTOMER</th>
                                                                                <td className='formtable'>{basicdataTrimPdf[0].idpoNo}</td>
                                                                                <th className='formtable'>QUALITY</th>
                                                                                <td className='formtable'>{basicdataTrimPdf[0].trimName}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th className='formtable' >STYLES</th>
                                                                                <td colSpan={3} className='formtable'>{basicdataTrimPdf[0].styleName}</td>

                                                                            </tr>
                                                                            <td class="formtable" colspan="4" style={{ height: "5vh" }} ></td>

                                                                        </table>
                                                                    </div>
                                                                </div>



                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <span style={{ marginLeft: "15px", lineHeight: "3" }}><b > TRIM DETAILS :</b>  {basicdataTrimPdf[0].trimCode} - {basicdataTrimPdf[0].trimName} </span>
                                                                    </div>
                                                                    {/* <div className="row">
                                                                    </div>

                                                                    <div className="col-lg-12">
                                                                        <h5 style={{ marginLeft: "15px" }}> <span>  <b>CODE: </b> {basicdataTrimPdf[0].pantoneCode} - {basicdataTrimPdf[0].pantoneNameOrSwatchName} - {basicdataTrimPdf[0].pantoneTypeName}  </span> </h5>
                                                                    </div> */}




                                                                    <div className="row" style={{ height: '730px' }}>
                                                                        {
                                                                            submitoptiosRefnoTrimPdf.filter(x => x.Pageno === pages.Pageno).map(refnos => {
                                                                                return (
                                                                                    <>
                                                                                        <div className="row" style={{
                                                                                            marginLeft: "2px"
                                                                                        }}>
                                                                                            <div className="col-lg-4">
                                                                                                <span style={{ marginLeft: "30px" }}> <b>PANTONE/CR:</b> {refnos.Refno}  </span>
                                                                                            </div>
                                                                                            <div className="col-lg-4">

                                                                                            </div>
                                                                                            <div className="col-lg-4">

                                                                                            </div>

                                                                                        </div>
                                                                                        {

                                                                                            AllsubmitoptiosTrimPdf[pages.Pageno - 1].filter(so => so.supplierReferenceNo === refnos.Refno && so.Pageno === refnos.Pageno).map((options, row) => {

                                                                                                return (
                                                                                                    <>
                                                                                                        {

                                                                                                            // <div className="row">
                                                                                                            <div className="col-lg-8" >
                                                                                                                <label htmlFor="" className='text-center' style={{ marginLeft: "435px" }} >
                                                                                                                    <b className='text-center'>Option "{options.optionName}"</b></label>
                                                                                                                <table style={{ width: "140%", marginLeft: "5.5%" }}>

                                                                                                                    <tr>
                                                                                                                        <th className='formtable' colspan="2" scope="colgroup" style={{ height: "82vh", borderBottom: "none" }}></th>
                                                                                                                    </tr>
                                                                                                                    {/* <tr>
                                                                                                                        <th className='formtable' style={{ padding: "0rem", textAlign: "center" }} scope="col">APPROVED</th>
                                                                                                                        <th className='formtable' style={{ padding: "0rem", textAlign: "center" }} scope="col">REJECTED</th>

                                                                                                                    </tr> */}

                                                                                                                </table>
                                                                                                            </div>
                                                                                                            // </div>
                                                                                                        }

                                                                                                    </>)

                                                                                            })



                                                                                        }
                                                                                        <div className="row">

                                                                                        </div>


                                                                                    </>
                                                                                )



                                                                            })

                                                                        }
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className="widget-footer" style={{ backdropFilter: "white" }}>
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <table className="commentsTable" width={"100%"}>
                                                                            <tr className="commentsTable">
                                                                                <td className="commentsTable" style={{ padding: "0px" }}>
                                                                                    <h5 style={{ marginLeft: "15px" }}><b>SUPPLIER COMMENTS :&nbsp;</b>  {basicdataTrimPdf[0].remarks}  </h5>
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="commentsTable">
                                                                                <td className="commentsTable" >
                                                                                    <h5 style={{ marginLeft: "15px" }}>  </h5>
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="commentsTable">
                                                                                <td className="commentsTable" >
                                                                                    <h5 style={{ marginLeft: "15px" }}></h5>
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="commentsTable">
                                                                                <td className="commentsTable" style={{ padding: "0px" }}>
                                                                                    <h5 style={{ marginLeft: "15px" }}><b>FOCUS COMMENTS:</b>  </h5>
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="commentsTable">
                                                                                <td className="commentsTable" >
                                                                                    <h5 style={{ marginLeft: "15px" }}>  </h5>
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="commentsTable">
                                                                                <td className="commentsTable" >
                                                                                    <h5 style={{ marginLeft: "15px" }}></h5>
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="commentsTable">
                                                                                <td className="commentsTable" style={{ padding: "0px" }}>

                                                                                    <div className="row">
                                                                                        <div className="col-lg-12">
                                                                                            <div className="col-lg-8">

                                                                                            </div>
                                                                                            <div className="col-lg-1" style={{ background: "black" }}>
                                                                                                <h5 style={{ color: "white" }}><b style={{ marginLeft: "-10px" }}>REJECTED:</b>  </h5>
                                                                                            </div>
                                                                                            <div className="col-lg-1">

                                                                                            </div>
                                                                                            <div className="col-lg-1" style={{ background: "black" }}>
                                                                                                <h5 style={{ color: "white" }}><b style={{ marginLeft: "-15px" }}>APPROVED:</b>  </h5>
                                                                                            </div>
                                                                                            <div className="col-lg-1">

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </table>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            &nbsp;
                                                            <div className="row">
                                                                <div className="col-lg-6" align='right'>
                                                                    <b>Page {pages.Pageno} of {rowsanddatacountforPdf.length}</b>
                                                                </div>
                                                                <div className="col-lg-6" align='right'>
                                                                    <b>Ref no : {props.TNATrimRefInfoId}</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </>
                                )
                            })
                            : ''

                }

                {
                    showswatchcardimages === true &&
                    swatchcardimages.map((images, index) => {
                        return (
                            <>

                                <div id={"UploadPage" + (index + 1)} style={{ width: '950px', height: '1350px' }}>
                                    <img
                                        src={window.$APIBaseURL + images.src}
                                        alt="No Data" />
                                </div>
                            </>
                        )
                    })
                }

                {loader}
            </Form>
        </div>
    );
};

export default TrimSubmission;