import React, { useState } from 'react';
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import { Button } from "react-bootstrap";
import FabricService from "../../services/Master/FabricService";
import Nodify from "../Common/ReactNotification";
import { useSelector } from "react-redux";

const Fabric = ({
    FabricList,
    FabricCallBack,
    GroupTypeId

}) => {
    const { user: currentUser } = useSelector((state) => state.auth);

    let data = [{
        FabricDetailsID: 0,
        GroupTypeID: 0,
        FabricDetailName: '',
        Content: '',
        GSMorCounts: '',
        FabricorYarn: ''
    }]

    const [buttonLoader, setButtonLoader] = useState(false);
    const [getInputFields, setInputFields] = useState(data);
    const [getSubmitted, setSubmitted] = useState(false);



    const CheckDuplicate = (index) => {
       ;
        const values = [...getInputFields]
        if (FabricList) {
            for (var i = 0; i < FabricList.length; i++) {
                const myArr = FabricList[i].label.split("-");
                if (myArr[0].trim().toLowerCase() === values[index].FabricDetailName.trim().toLowerCase()
                    && myArr[1].trim().toLowerCase() === values[index].Content.trim().toLowerCase()
                    && myArr[2].trim().toLowerCase() === values[index].GSMorCounts.trim().toLowerCase()) {
                    values[index].GroupTypeID = 0;
                    values[index].FabricDetailName = '';
                    values[index].Content = '';
                    values[index].GSMorCounts = '';
                    setInputFields(values);
                    Nodify('Warning!', 'warning', "This Fabric is already exist.");
                    return false;
                }

            }
        }
    }

    // useEffect(() => {

    //     FabricService.GetFabricList(0).then((response) => {
    //         if (response.data) {
    //             setExistingList(response.data);
    //         }
    //     });

    // }, []);

    const handleChange = (event) => {
        setSubmitted(false);
        const values = [...getInputFields]
        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }

        if (event.target.name === "FabricName") {
            values[0].FabricDetailName = inputText;
        }
        else if (event.target.name === "Content") {
            values[0].Content = inputText;
        }
        else if (event.target.name === "GSM_Count") {
            values[0].GSMorCounts = inputText;
        }
        values[0].FabricDetailsID = 0;
        values[0].GroupTypeID = GroupTypeId;

        setInputFields(values);

    }

    const SaveFabric = (e) => {
        const values = [...getInputFields]
        setButtonLoader(true);
        e.preventDefault();

        if (values[0].FabricDetailName.trim() === '' && values[0].Content.trim() === '') {
            setSubmitted(false);
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Fabric name and Content name is required.');
        }
        else if (values[0].FabricDetailName.trim() === '') {
            setSubmitted(false);
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Fabric name is required.');
        }
        else if (values[0].Content.trim() === '') {
            setSubmitted(false);
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Content name is required.');
        }
        if (values[0].FabricDetailName.trim() && values[0].Content.trim()) {
            // var data = {
            //     Createdby: currentUser.employeeinformationID, FabricInformation: values,

            // };
            let fabricListParam = {};
            fabricListParam.FabricInformation = values;
            values.map(element => {
                element.FabricorYarn = "F";
            })
            // fabricListParam.FabricInformation.FabricorYarn = "F";
            fabricListParam.Createdby = 1;
            FabricService.InsertUpdateFabricGrid(fabricListParam).then(res => {
                if (res.data.outputResult === "1") {
                    Nodify("Success!", "success", "Fabric added successfully.");
                    FabricCallBack(true);
                } else if (res.data.outputResult === "-2") {
                    Nodify('Warning!', 'warning', 'This name is already exist.');
                }
                setButtonLoader(false);
            });
        }
        else {
            setSubmitted(true);
        }
    }

    return (

        <div id="registration-form">
            <Form>
                <div className="row">
                    <div className="form-group col-sm-4">
                        <label htmlFor="FabricName">
                            Fabric Name
                            <span className="text-danger">*</span>
                        </label>
                        <Input
                            placeholder={"Fabric Name"}
                            id="FabricName"
                            name="FabricName"
                            type="text"
                            maxLength="50"
                            value={getInputFields[0].FabricDetailName}
                            autoComplete="off"
                            onChange={handleChange}
                            className='form-control'
                            onBlur={() => CheckDuplicate(0)}
                            style={{ border: getSubmitted && !getInputFields[0].FabricDetailName ? '1px solid red' : '' }}
                            autoFocus
                        />
                    </div>

                    <div className="form-group col-sm-4">
                        <label htmlFor="Content">
                            Content
                            <span className="text-danger">*</span>
                        </label>
                        <Input
                            placeholder={"Content"}
                            id="Content"
                            name="Content"
                            type="text"
                            maxLength="50"
                            value={getInputFields[0].Content}
                            autoComplete="off"
                            onChange={handleChange}
                            className='form-control'
                            onBlur={() => CheckDuplicate(0)}
                            style={{ border: getSubmitted && !getInputFields[0].Content ? '1px solid red' : '' }}

                        />
                    </div>

                    <div className="form-group col-sm-4">
                        <label htmlFor="GSM_Count">
                            GSM/Count

                        </label>
                        <Input
                            placeholder={"Gsm/Count"}
                            id="GSM_Count"
                            name="GSM_Count"
                            type="text"
                            maxLength="50"
                            value={getInputFields[0].GSMorCounts}
                            autoComplete="off"
                            onChange={handleChange}
                            className='form-control'
                            onBlur={() => CheckDuplicate(0)}


                        />
                    </div>
                </div>
                <br />
                <div style={{ textAlign: "right" }}>
                    <Button type="button" className="btn btn-success" onClick={SaveFabric} disabled={buttonLoader}>
                        <i className="fa fa-check right"></i> &nbsp; Save
                    </Button>
                </div>
            </Form>
        </div>

    )
};

export default Fabric;