import {
    QACOMMENTPOINT_LOADING,
    RETRIEVE_QACOMMENTPOINT,
    DELETE_QACOMMENTPOINT,
} from "../actions/types";

const initialState = {
    isLoadingQACommentpoint: true,
    QACommentpointList: [],
};

const QACommentpointReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case QACOMMENTPOINT_LOADING:
            return {
                ...state,
                isLoadingQACommentpoint: payload
            };
        case RETRIEVE_QACOMMENTPOINT:
            return { ...state, QACommentpointList: payload };
        case DELETE_QACOMMENTPOINT:
            const results = state.QACommentpointList.filter(c => c.id !== payload.QCCommentPoint);
            return {
                ...state,
                QACommentpointList: results
            };

        default:
            return state;
    }
};
export default QACommentpointReducer;