import {
    FLDETAILREPORT_LOADING,
    LOAD_FLDETAILREPORT_LIST,
    LOAD_FINALINSPECTION_LIST,
    LOAD_REFINALINSPECTION_LIST,
    FL_UPDATE_COMMENT_REPORT_VIEW_ALL_CHECK_BOX,
    FL_UPDATE_COMMENT_REPORT_VIEW_CHECK_BOX
} from "../actions/types"

const initialState = {
    FlDetailsReportList: [],
    FinalInspectionList: [],
    ReFinalInspectionList: [],
    isLoadingFlDetailsReport: true,
};

const Tnadetailsreducer = (state = initialState, action) => {

    const { type, payload } = action
    switch (type) {
        case FLDETAILREPORT_LOADING:
            return {
                ...state,
                isLoadingFlDetailsReport: payload
            };
        case LOAD_FLDETAILREPORT_LIST:
            return {
                ...state,
                FlDetailsReportList: payload
            }
        case LOAD_FINALINSPECTION_LIST:
            return {
                ...state,
                FinalInspectionList: payload
            }
        case LOAD_REFINALINSPECTION_LIST:
            return {
                ...state,
                ReFinalInspectionList: payload
            }

        case FL_UPDATE_COMMENT_REPORT_VIEW_ALL_CHECK_BOX:

            let isCheck = payload;
            let List = state.FlDetailsReportList;


            let Totallitem = List && List.item1;
            if (isCheck) {
                Totallitem && Totallitem.filter(x => x.isSelected === true).map((item, Index) => {
                    item.isSelected = isCheck
                });
                List.item1 = [...Totallitem];
            } else {
                Totallitem && Totallitem.filter(x => x.isSelected === false).map((item, Index) => {
                    item.isSelected = isCheck
                });
                List.item1 = Totallitem;
            }

            // List = List
            return {
                ...state,
                FlDetailsReportList: List
            };
        case FL_UPDATE_COMMENT_REPORT_VIEW_CHECK_BOX:
            let Index = payload.index;
            let isChecked = payload.isChecked;
            let Comments = state.FlDetailsReportList;
            let Totallitems = Comments && Comments.item1
            Totallitems[Index].isSelected = isChecked
            Comments.item1 = Totallitems;

            return {
                ...state,
                FlDetailsReportList: Comments
            };


        default:
            return state;
    }
}
export default Tnadetailsreducer;
