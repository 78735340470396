import axios from "axios";
import authHeader from "../auth-header";

class OrderService {

    GetOrderLabdipList(IsProduction) {

        return axios.get(window.$APIBaseURL + "Order/GetOrderLabdipList?IsProduction=" + IsProduction, {
            headers: {
                authorization: authHeader()
            }
        });
    }



    GetOrderTrimList(IsProduction) {

        return axios.get(window.$APIBaseURL + "Order/GetOrderTrimList?IsProduction=" + IsProduction, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    //Protofit start
    GetOrderProtoFitList(IsProduction) {

        return axios.get(window.$APIBaseURL + "Order/GetOrderProtoFitList?IsProduction=" + IsProduction, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetOrderSampleSubmissionList(params) {
        ;
        let SearchParam = {
            BuyerId: params.BuyerId,
            BrandId: params.BrandId,
            SeasonId: params.SeasonId,
            SupplierId: 0,
            Samplecolor: params.Samplecolor,
            SampleName: params.SampleName,
            StyleName: params.StyleName,
            StyleNo: params.StyleNo,
            IdPoNo: params.IdPoNo,
            Operation: params.Operation,
            IsProduction: params.IsProduction,
            Status: params.StatusId ? params.StatusId : null,
            StartDate: params.StartDate,
            EndDate: params.EndDate,




        };
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Order/GetOrderSampleSubmissionList',
            data: SearchParam,
            headers: {
                authorization: authHeader()
            }
        });
    }


    //protofit End
    //reuest details
    GetOrderTrimDetailsViewList(params) {

        let SearchParams = {
            BuyerId: params.BuyerId, BrandId: params.BrandId, SeasonId: params.SeasonId,
            TrimId: params.TrimId, ColorGroupID: params.ColorGroupID, SearchText: params.SearchText, Operation: params.Operation,
            StatusId: params.StatusId ? params.StatusId : null, ApprovalTypeId: params.ApprovalTypeId ? params.ApprovalTypeId : null,
            SupplierId: 0, StartDate: params.StartDate, EndDate: params.EndDate,
            StyleName: params.StyleName, StyleNo: params.StyleNo, IdPoNo: params.IdPoNo, IsProduction: params.IsProduction
        };
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Order/GetOrdTrimDetailsViewList',
            data: SearchParams,
            headers: {
                authorization: authHeader()
            }
        });
    }

    //-----trimRequest/submission/approval
    GetOrderTrimSubmissionApprovalGridList(params) {

        let SearchParams = {
            BuyerId: params.BuyerId, BrandId: params.BrandId, SeasonId: params.SeasonId,
            TrimId: params.TrimId, ColorGroupID: params.ColorGroupID, SearchText: params.SearchText, Operation: params.Operation,
            StatusId: params.StatusId ? params.StatusId : null,
            SupplierId: params.SupplierId ? params.SupplierId : null, StartDate: params.StartDate, EndDate: params.EndDate,
            StyleName: params.StyleName, StyleNo: params.StyleNo, IdPoNo: params.IdPoNo, IsProduction: params.IsProduction
        };

        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Order/GetOrderTrimSubmissionList',
            data: SearchParams,
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetOrderStrikeOffList(IsProduction) {

        return axios.get(window.$APIBaseURL + "Order/GetOrderStrikeOffList?IsProduction=" + IsProduction, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetOrderLapdipTNAProgramStatusList(Params) {
        return axios.get(window.$APIBaseURL + "General/GetOrderLapdipTNAProgramStatusList?GenLabdipId=" + Params.labdipId + "&GenLapdipProgramInfoId=" + Params.lapdipProgramInfoId + "&StatusId=" + Params.StatusId, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    //Labdip
    GetOrderLapdipDetailsViewList(Params) {
        ;
        let SearchParams = {
            BuyerId: Params.BuyerId, BrandId: Params.BrandId, SeasonId: Params.SeasonId,
            PantoneId: Params.PantoneId, SearchText: Params.SearchText, Operation: Params.Operation, Status: Params.Status ? Params.Status : null,
            ApprovalTypeId: Params.ApprovalTypeId ? Params.ApprovalTypeId : null, SupplierId: 0, StartDate: Params.StartDate,
            EndDate: Params.EndDate, StyleName: Params.StyleName, StyleNo: Params.StyleNo, IdPoNo: Params.IdPoNo, IsProduction: Params.IsProduction
        };
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Order/GetOrderLapdipDetailsViewList',
            data: SearchParams,
            headers: {
                authorization: authHeader()
            }
        });
    }
    //sampe
    GetOrderLapdipSubmissionApprovalList(params) {

        let SearchParams = {
            BuyerId: params.BuyerId, BrandId: params.BrandId, SeasonId: params.SeasonId,
            PantoneId: params.PantoneId, SearchText: params.SearchText, Operation: params.Operation,
            Status: params.Status ? params.Status : null,
            SupplierId: params.SupplierId ? params.SupplierId : null, StartDate: params.StartDate, EndDate: params.EndDate,
            StyleName: params.StyleName, StyleNo: params.StyleNo, IdPoNo: params.IdPoNo, IsProduction: params.IsProduction
        };
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Order/GetOrderLapdipSubmissionList',
            data: SearchParams,
            headers: {
                authorization: authHeader()
            }
        });

        // return axios.get(window.$APIBaseURL + "General/GetGeneralLapdipSubmissionList?BuyerId=" + params.BuyerId + "&BrandId=" + params.BrandId +
        //     "&SeasonId=" + params.SeasonId + "&PantoneId=" + params.PantoneId + "&SearchText=" + params.SearchText + "&Operation=" + params.Operation, {
        //     headers: {
        //         authorization: authHeader()
        //     }
        // });
    }

    //Strike off details
    GetOrderStrikeOffDetailsViewList(params) {
        let SearchParams = {
            BuyerId: params.BuyerId, BrandId: params.BrandId, SeasonId: params.SeasonId,
            StrikeOffId: params.StrikeOffId, ColorGroupID: params.ColorGroupID, SearchText: params.SearchText, Operation: params.Operation,
            StatusId: params.StatusId ? params.StatusId : null, ApprovalTypeId: params.ApprovalTypeId ? params.ApprovalTypeId : null,
            SupplierId: 0, StartDate: params.StartDate, EndDate: params.EndDate,
            StyleName: params.StyleName, StyleNo: params.StyleNo, IdPoNo: params.IdPoNo, IsProduction: params.IsProduction
        };
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Order/GetOrdStrikeOffDetailsViewList',
            data: SearchParams,
            headers: {
                authorization: authHeader()
            }
        });
    }
    //grid
    GetOrderStrikeOffSubmissionApprovalGridList(params) {
        ;
        let SearchParams = {
            BuyerId: params.BuyerId, BrandId: params.BrandId, SeasonId: params.SeasonId,
            StrikeOffId: params.StrikeOffId, ColorGroupID: params.ColorGroupID, SearchText: params.SearchText, Operation: params.Operation,
            StatusId: params.StatusId ? params.StatusId : null,
            SupplierId: params.SupplierId ? params.SupplierId : null, StartDate: params.StartDate, EndDate: params.EndDate,
            StyleName: params.StyleName, StyleNo: params.StyleNo, IdPoNo: params.IdPoNo, Isproduction: params.IsProduction
        };

        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Order/GetOrderStrikeOffSubmissionList',
            data: SearchParams,
            headers: {
                authorization: authHeader()
            }
        });
    }

}
export default new OrderService();