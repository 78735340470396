import axios from "axios";
import authHeader from "../auth-header";

class QCNoteService {
    LoadStyleBasedQCNoteList(StyleID) {
        return axios.get(window.$APIBaseURL + "Style/StyleQCNotesList?StyleID=" + StyleID, {
            headers: {
                authorization: authHeader()
            }
        });
    }
}
export default new QCNoteService();