import React, { useState, useEffect, useRef, Fragment } from "react";
import ReactNotification from 'react-notifications-component';
import Nodify from "../../Common/ReactNotification"
import currencyService from "../../../services/Master/CurrencyService";
import validator from 'validator'
import SearchRetain from "../../Common/SearchRetain";
import { useSelector } from "react-redux";

const AddCurrency = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);
    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    if (menulist !== undefined) {
        activeMenu = menulist.filter(i => i.menuName === "Currency");
    }
    //end enable menu
    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getCurrency, setCurrency] = useState([{ Id: 0, CurrencyName: "", CurrencySymbol: "" }]);
    const [ExistingList, setExistingList] = useState([]);
    const [ButtonName, setButtonName] = useState("Save");
    const [HeaderName, setHeaderName] = useState("Add");



    function GroupListing(props) {
        let Currency = [...getCurrency];
        Array.from(document.querySelectorAll("input")).forEach(input => (input.value = ""));
        if (props.location.state.params.length !== 0) {
            if (props.location.state.params.Id !== "") {
                setButtonName("Update");
                setHeaderName("Edit");
                Currency.Id = props.location.state.params.groupId;
                Currency.CurrencyName = validator.trim(props.location.state.params.currencyName);
                Currency.CurrencySymbol = validator.trim(props.location.state.params.currencySymbol);
                Currency.Operation = 2;
                return Currency;
            }
        } else {
            return Currency;
        }
    }

    useEffect(() => {
        let getGroupList = GroupListing(props);
        setCurrency(getGroupList)
        currencyService.GetCurrencyList().then((response) => {
            if (response.data) {
                setExistingList(response.data);
            }
        });
    }, []);

    const handleAddFields = (index) => {
        const values = [...getCurrency];
        if (values[index].CurrencyName !== '' && values[index].CurrencySymbol !== '') {
            values.push({ Id: 0, CurrencyName: "", CurrencySymbol: "" });
            setCurrency(values);
            setSubmitted(false);
        }
        else {
            Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
            setSubmitted(true);
        }
    };

    const handleInputChange = (index, event) => {
        const values = [...getCurrency];
        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }
        if (event.target.name === "CurrencyName") {
            values[index].CurrencyName = inputText;
        } else if (event.target.name === "CurrencySymbol") {
            values[index].CurrencySymbol = inputText;

        }
        setCurrency(values);
    };

    const handleRemoveFields = index => {
        const values = [...getCurrency];
        values.splice(index, 1);
        setCurrency(values);
    };

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/CurrencyList');
        } else {
            window.location.reload();
            // let getGroupList = GroupListing(props);
            // setCurrency(getGroupList)
        }
    }

    const CheckDuplicate = (index, Field) => {
        const values = [...getCurrency];
        if (ExistingList) {
            if (values[index].CurrencyName !== '') { // for avoid empty validation
                for (var i = 0; i < ExistingList.length; i++) {
                    if (ExistingList[i].currencyName.trim().toLowerCase() === values[index].CurrencyName.trim().toLowerCase()
                    ) {

                        values[index].CurrencyName = "";

                        setCurrency(values);
                        Nodify('Warning!', 'warning', "This Currency Name is already exist.");
                        return false;
                    }
                }
            }
        }

        for (var j = 0; j < (getCurrency.length); j++) {
            if (j !== index) {
                if (getCurrency[j].CurrencyName.trim().toLowerCase() === getCurrency[index].CurrencyName.trim().toLowerCase()
                ) {

                    values[index].CurrencyName = "";

                    setCurrency(values);
                    Nodify('Warning!', 'warning', 'This Currency Name is already exist.');
                    return false;
                }
            }
        }


    }

    const SaveGroupTypeList = (e) => {
        setButtonLoader(true);
        e.preventDefault();
        if (getCurrency.length === 1 && getCurrency[0].CurrencyName === '' && getCurrency[0].CurrencySymbol === '') {
            setButtonLoader(false);
            setSubmitted(true);
            ValidationPopup("Please fill atleast one Currency detail.");
        }
        else {
            for (var i = 0; i < (getCurrency.length); i++) {

                if (getCurrency[i].CurrencyName.trim() === '') {
                    Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
                    setSubmitted(true);
                    setButtonLoader(false);
                    return false;
                }
                if (getCurrency[i].CurrencySymbol.trim() === '') {
                    Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
                    setSubmitted(true);
                    setButtonLoader(false);
                    return false;
                }
            }

            let InputCommonMaster = { Createdby: currentUser.employeeinformationID, CurrencyInformation: getCurrency };
            currencyService.InsertUpdateCurrencyGrid(InputCommonMaster).then(res => {
                var page = "Remove";
                SearchRetain(page);

                let Func = 'Add';
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "-2") {
                    setButtonLoader(false);
                    ValidationPopup("This Group Type Name is already exist.");
                }
                else if (res.data.outputResult === "0") {
                    setButtonLoader(false);
                    ValidationPopup("Error Occured!");
                }
            });
        }
    }

    const PageRedirect = (Func) => {
        props.history.push({ pathname: "/CurrencyList", state: { message: Func } });
    }

    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
        return false;
    }

    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">{HeaderName} Currency</h3>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <form>
                                    {
                                        getCurrency.map((inputField, index) => (
                                            <Fragment key={`${inputField}~${index}`}>
                                                <div className="row row_left10">

                                                    <div className="col-sm-3 divder_style">
                                                        <div className="form-group">
                                                            <label htmlFor={
                                                                "CurrencyName" + index
                                                            }>Currency Name<span className="text-danger">*</span>
                                                            </label>
                                                            <span className="input-icon icon-right">
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"CurrencyName" + index}
                                                                    name="CurrencyName"
                                                                    placeholder="Enter Currency Name"
                                                                    maxLength="50"
                                                                    value={inputField.CurrencyName}
                                                                    onChange={event => handleInputChange(index, event)}
                                                                    onBlur={() => CheckDuplicate(index, 'CurrencyName')}
                                                                    style={{ border: submitted && !inputField.CurrencyName ? '1px solid red' : '' }}
                                                                    autoFocus
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3 divder_style">
                                                        <div className="form-group">
                                                            <label htmlFor={
                                                                "CurrencySymbol" + index
                                                            }>Currency Symbol<span className="text-danger">*</span>
                                                            </label>
                                                            <span className="input-icon icon-right">
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"CurrencySymbol" + index}
                                                                    name="CurrencySymbol"
                                                                    placeholder="Enter Currency Symbol"
                                                                    maxLength="50"
                                                                    value={inputField.CurrencySymbol}
                                                                    onChange={event => handleInputChange(index, event)}
                                                                    onBlur={() => CheckDuplicate(index, 'CurrencySymbol')}
                                                                    style={{ border: submitted && !inputField.CurrencySymbol ? '1px solid red' : '' }}

                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3 paddingstatic">
                                                        {
                                                            getCurrency.length !== 1 && (
                                                                <div className="col-lg-1_own col-xs-12 adddeletemargin">
                                                                    <button type="button" className="btn btn-danger"
                                                                        style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}
                                                                        title="Delete Currency" onClick={() => handleRemoveFields(index)}>
                                                                        <i className="fa fa-trash-o"></i>
                                                                    </button>
                                                                </div>
                                                            )}
                                                        {
                                                            getCurrency.length === (index + 1) && (
                                                                <div className="col-lg-1_own col-xs-12 adddeletemargin">
                                                                    <button type="button" className="btn btn-success" title="Add Currency" onClick={() => handleAddFields(index)}>
                                                                        <i className="fa fa-plus"></i>
                                                                    </button>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </Fragment>
                                        ))
                                    }
                                    <div className="col-sm-12">
                                        <div className="buttons-preview" align="right">
                                            <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                                <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                                            </span>
                                            <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                                <i className="fa fa-times"></i>&nbsp;Reset
                                            </span>
                                            <button type="button" className="btn btn-success" disabled={buttonLoader} onClick={SaveGroupTypeList}>
                                                <i className="fa fa-check right"></i>&nbsp;{ButtonName}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddCurrency;