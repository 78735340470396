import React, { useState, useEffect, Fragment, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { retrieveTestStandard } from '../../../../../actions/teststandard';
import Reactselect from 'react-select';
import { event } from 'jquery';
import TNAService from '../../../../../services/TNA/TNAService';
import useFullPageLoader from '../../../../hooks/useFullPageLoader';
import { Modal, Button } from "react-bootstrap";
import ModalDialog from 'react-bootstrap/ModalDialog';
import Draggable from 'react-draggable';

const TrimTest = ({ props, TrimDetails, TrimlabDetails, labdata, labremarkdata,Testingdetail }) => {
  const dispatch = useDispatch();
  const [getTestNames, setTestNames] = useState([]);
  const [gettestselect, settestselect] = useState([]);
  const [getTrimHeaderInfo, setTrimHeaderInfo] = useState([]);
  const [getStandardOptions, setStandardOptions] = useState([]);
  const [getGradeValue, setGradeValue] = useState([]);
  const reducerState = useSelector((state) => state);
  const [getOrder, setOrder] = useState([]);


  const [getAttachmentInfo, setAttachmentInfo] = useState([]);
  const [getSpiralityInfo, setSpiralityInfo] = useState([]);
  const [show, setShow] = useState(false);
  const [submittedlabcomments, setSubmittedlabcomments] = useState(false);
  const [submittedlabremark, setSubmittedlabremark] = useState(false);
  const [submittedtestingdetails, setSubmittedtestingdetails] = useState(false);
  const comments = reducerState.TestStandardReducer.TestStandardList;
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const inpsectionId = JSON.parse(localStorage.getItem("TestingInspectionID"));
  const [checkboxStates, setCheckboxStates] = useState({
    IsShowAttach: true,
  });

  const [getLabComments, setLabComments] = useState([
    {
      value: 0,
      label: "",
      Remarks: ""
    }
  ]);
  let labResult = [
    {
      value: 1,
      label: <><span style={{ color: "green" }}><b>Statisfied</b></span></>,


    },
    {
      value: 2,
      label: <><span style={{ color: "Red" }}><b>Not Statisfy</b></span></>,


    }
  ]

  const inputstyles = {
    border: '1px solid red',
  };
  let IsResult = [
    {
      value: 1,
      label: <><span style={{ color: "green" }}><b>Pass</b></span></>,


    },
    {
      value: 2,
      label: <><span style={{ color: "Red" }}><b>Fail</b></span></>,


    }
  ]

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let styles = {
    control: styles => ({ ...styles, border: '1px solid red' }),
  }
  const textareaStyles = {
    border: '1px solid red',  // Red border for highlighting 
  };

  useEffect(() => {
    debugger
    //setSubmittedlabcomments(false)
    setSubmittedlabcomments(labdata)
  }, [labdata === true])
  useEffect(() => {
    debugger
    setSubmittedlabremark(labremarkdata)
  }, [labremarkdata === true])

  useEffect(() => {
    debugger
    setSubmittedtestingdetails(Testingdetail)
  }, [Testingdetail === true])

  useEffect(() => {
    let TestNameList = []
    let GradeList = [];
    let AttachmentInfo = [];
    (async () => {
      if (props.location.state.params.TaskStatus === 1) {
        if (props.location.state.params.multiselecteddatas.length > 0) {
          let StyleIDs = props.location.state.params.multiselecteddatas.filter(x => x.isSelected === 1).map((data => { return data.styleID })).join(",");
          TNAService.GetTestInfoDetails(props.location.state.params.buyerID, props.location.state.params.brandID, 5, StyleIDs, inpsectionId).then((response) => {
            // // Function to filter and get the latest entries

            const getLatestEntriesWithChildLatestDate = () => {
              // Create an object to store the latest entries by testID and latestDate
              const latestEntries = {};

              // Iterate through the response data to store entries and their indexes
              const entriesWithIndexes = response.data.map((item, index) => ({ item, index }));

              entriesWithIndexes.forEach(({ item, index }) => {
                const { testID, latestDate, childLatestDate } = item;
                const latestDateStr = new Date(latestDate).toJSON();
                const childLatestDateStr = new Date(childLatestDate).toJSON();

                if (!latestEntries[testID] || latestDateStr >= latestEntries[testID].latestDate) {
                  // If it's the latest date or the same date, update the latest entry
                  if (!latestEntries[testID] || latestDateStr > latestEntries[testID].latestDate) {
                    latestEntries[testID] = { latestDate: latestDateStr, entries: [] };
                  }

                  // Check if childLatestDate is greater or equal to the current childLatestDate
                  if (!latestEntries[testID].childLatestDate || childLatestDateStr >= latestEntries[testID].childLatestDate) {
                    // If it's the child latest date or the same date, update the child latest entry
                    if (!latestEntries[testID].childLatestDate || childLatestDateStr > latestEntries[testID].childLatestDate) {
                      latestEntries[testID].childLatestDate = childLatestDateStr;
                      latestEntries[testID].entries = [];
                    }
                    latestEntries[testID].entries.push({ item, index });
                  }
                }
              });

              // Convert the object to an array of the latest entries while maintaining the original order
              const latestEntriesArray = Object.values(latestEntries).reduce((acc, entry) => {
                return [...acc, ...entry.entries];
              }, []);

              // Sort the latest entries based on their original order in the response.data array
              latestEntriesArray.sort((a, b) => a.index - b.index);

              return latestEntriesArray.map(entry => entry.item);
            };

            const latestEntriesWithChildLatestDate = getLatestEntriesWithChildLatestDate();

            setTrimHeaderInfo(latestEntriesWithChildLatestDate)
            latestEntriesWithChildLatestDate.filter(xy => xy.testName === "ATTACHMENT OF STRENGTH").map((x, indexy) => {
              AttachmentInfo.push({
                id: x.id,
                testID: x.testID,
                testTypeID: x.testTypeID,
                testName: x.testName,
                group: x.group,
                fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                fabricDetails: x.fabricDetails,
                standard: x.standard === undefined ? "" : x.standard,
                standardName: x.standardName === undefined ? "" : x.standardName,
                genderID: x.genderID,
                colorDepthID: x.colorDepthID,
                colorDepth: x.colorDepth,
                minResult: x.minResult,
                maxResult: x.maxResult,
                field: x.fieID === undefined ? "" : x.fieID.toString(),
                fieldID: x.fieID === undefined ? "" : x.fieID.toString(),
                fieldName: x.fieldName,
                resultType: x.resultType,
                resultTypeID: x.resultType,
                optionName: x.optionName == undefined ? "" : x.optionName,
                gradeValue: x.gradeValue,
                gradeOptionID: x.gradeOptionID,
                ResultID: 0,
                ResultName: "",
                gradeID: 0,
                gradeName: '',
                changein: 0,
                rowindex: x.rowIndex,
                found: "",
                beforeWashChangeIn: 0,
                afterWash: "",
                afterWashChangeIn: 0,
                foundGSM: "",
                isChecked: 1
              })
            })
            setAttachmentInfo(AttachmentInfo)
            handleChangeinitialload(AttachmentInfo)
            hideLoader();
          }, (error => {
            hideLoader();
          }))

        }
      }
      else {
        await TNAService.LoadTestInfoDetails(props.location.state.params.TestingInspectionID, props.location.state.params.TnaID, 5).then((res) => {

          if (res.data.item2.length > 0) {
            setTrimHeaderInfo(res.data.item1)
            setOrder(res.data.item3)
            res.data.item2.filter(xy => xy.testName === "ATTACHMENT OF STRENGTH").map((x, indexy) => {
              AttachmentInfo.push({
                id: x.id,
                testID: x.testID,
                testTypeID: x.testTypeID,
                testName: x.testName,
                group: x.group,
                fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                fabricDetails: x.fabricDetails,
                standard: x.standard,
                standardName: x.standardName,
                genderID: x.genderID,
                colorDepthID: x.colorDepthID,
                colorDepth: x.colorDepth,
                minResult: x.minResult,
                maxResult: x.maxResult,
                field: x.fieID == undefined ? "" : x.fieID.toString(),
                fieldID: x.fieID == undefined ? "" : x.fieID.toString(),
                fieldName: x.fieldName,
                resultType: x.resultType,
                resultTypeID: x.resultType,
                optionName: x.optionName == undefined ? "" : x.optionName,
                gradeValue: x.gradeValue,
                gradeOptionID: x.gradeOptionID,
                ResultID: x.resultID,
                ResultName: x.resultName,
                gradeID: x.gradeID,
                gradeName: x.gradeName,
                changein: 0,
                rowindex: x.rowIndex,
                found: x.foundGSM,
                beforeWashChangeIn: 0,
                afterWash: "",
                afterWashChangeIn: 0,
                foundGSM: x.foundGSM,
                isChecked: x.isChecked
              })
            })
            setAttachmentInfo(AttachmentInfo)
            handleChangeinitialload(AttachmentInfo)
          }
          else {
            let StyleIDs = props.location.state.params.multiselecteddatas.map((data => { return data.styleID })).join(",");
            TNAService.GetTestInfoDetails(props.location.state.params.buyerID, props.location.state.params.brandID, 5, props.location.state.params.styleID, props.location.state.params.TestingInspectionID).then((response) => {
              // // Function to filter and get the latest entries
              const getLatestEntriesWithChildLatestDate = () => {
                // Create an object to store the latest entries by testID and latestDate
                const latestEntries = {};

                // Iterate through the response data to store entries and their indexes
                const entriesWithIndexes = response.data.map((item, index) => ({ item, index }));

                entriesWithIndexes.forEach(({ item, index }) => {
                  const { testID, latestDate, childLatestDate } = item;
                  const latestDateStr = new Date(latestDate).toJSON();
                  const childLatestDateStr = new Date(childLatestDate).toJSON();

                  if (!latestEntries[testID] || latestDateStr >= latestEntries[testID].latestDate) {
                    // If it's the latest date or the same date, update the latest entry
                    if (!latestEntries[testID] || latestDateStr > latestEntries[testID].latestDate) {
                      latestEntries[testID] = { latestDate: latestDateStr, entries: [] };
                    }

                    // Check if childLatestDate is greater or equal to the current childLatestDate
                    if (!latestEntries[testID].childLatestDate || childLatestDateStr >= latestEntries[testID].childLatestDate) {
                      // If it's the child latest date or the same date, update the child latest entry
                      if (!latestEntries[testID].childLatestDate || childLatestDateStr > latestEntries[testID].childLatestDate) {
                        latestEntries[testID].childLatestDate = childLatestDateStr;
                        latestEntries[testID].entries = [];
                      }
                      latestEntries[testID].entries.push({ item, index });
                    }
                  }
                });

                // Convert the object to an array of the latest entries while maintaining the original order
                const latestEntriesArray = Object.values(latestEntries).reduce((acc, entry) => {
                  return [...acc, ...entry.entries];
                }, []);

                // Sort the latest entries based on their original order in the response.data array
                latestEntriesArray.sort((a, b) => a.index - b.index);

                return latestEntriesArray.map(entry => entry.item);
              };

              const latestEntriesWithChildLatestDate = getLatestEntriesWithChildLatestDate();

              setTrimHeaderInfo(latestEntriesWithChildLatestDate)
              latestEntriesWithChildLatestDate.filter(xy => xy.testName === "ATTACHMENT OF STRENGTH").map((x, indexy) => {
                AttachmentInfo.push({
                  id: x.id,
                  testID: x.testID,
                  testTypeID: x.testTypeID,
                  testName: x.testName,
                  group: x.group,
                  fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                  fabricDetails: x.fabricDetails,
                  standard: x.standard === undefined ? "" : x.standard,
                  standardName: x.standardName === undefined ? "" : x.standardName,
                  genderID: x.genderID,
                  colorDepthID: x.colorDepthID,
                  colorDepth: x.colorDepth,
                  minResult: x.minResult,
                  maxResult: x.maxResult,
                  field: x.fieID === undefined ? "" : x.fieID.toString(),
                  fieldID: x.fieID === undefined ? "" : x.fieID.toString(),
                  fieldName: x.fieldName,
                  resultType: x.resultType,
                  resultTypeID: x.resultType,
                  optionName: x.optionName == undefined ? "" : x.optionName,
                  gradeValue: x.gradeValue,
                  gradeOptionID: x.gradeOptionID,
                  ResultID: 0,
                  ResultName: "",
                  gradeID: 0,
                  gradeName: '',
                  changein: 0,
                  rowindex: x.rowIndex,
                  found: "",
                  beforeWashChangeIn: 0,
                  afterWash: "",
                  afterWashChangeIn: 0,
                  foundGSM: "",
                  isChecked: 1
                })
              })
              setAttachmentInfo(AttachmentInfo)
              handleChangeinitialload(AttachmentInfo)
              hideLoader();
            }, (error => {
              hideLoader();
            }))
          }
        })


        await TNAService.getAllQcInfoList(1, 0, props.location.state.params.TestingInspectionID).then((res) => {


          let newdata = [];
          if (res.data.testingMainDetails) {
            res.data.testingMainDetails.map((x) => {
              newdata.push({
                value: x.labCommentID,
                Remarks: x.labRemarks
              })

            })
            setLabComments(newdata)
            handleChangeNew(newdata)
            hideLoader();
          }
        })
      }
    })();

    TNAService.LoadGradeDetail().then((res) => {
      if (res.data) {
        res.data.map((x, index) => {
          GradeList.push({
            Id: x.id,
            value: x.value,
            label: x.label
          })
        })

      }
      setGradeValue(GradeList)
    })

    if (getTrimHeaderInfo.length > 0) {
      dispatch(retrieveTestStandard(0));
      for (var i = 0; i <= getTrimHeaderInfo.length - 1; i++) {
        if (TestNameList.length == 0) {
          let StandardOptions;
          StandardOptions = getTrimHeaderInfo.filter((data => {
            if (data.testID == getTrimHeaderInfo[i].testID) {
              return getTrimHeaderInfo[i].standard;
            }
          })).map(a => a.standard);
          TestNameList.push({
            testID: getTrimHeaderInfo[i].testID
            , ID: getTrimHeaderInfo[i].id
            , testName: getTrimHeaderInfo[i].testName
            , standard: StandardOptions.join(",")
            , isChecked: getTrimHeaderInfo[i].isChecked
          });
        }
        else if (TestNameList.length > 0) {
          let filterDuplicate = TestNameList.filter((data => {
            return data.testID == getTrimHeaderInfo[i].testID;
          }))
          if (filterDuplicate.length > 0) {

          }
          else {
            TestNameList.push({
              testID: getTrimHeaderInfo[i].testID
              , ID: getTrimHeaderInfo[i].id
              , testName: getTrimHeaderInfo[i].testName
              , standard: getTrimHeaderInfo[i].standard
              , isChecked: getTrimHeaderInfo[i].isChecked
            });
          }
        }
      }
      setTestNames(...getTestNames, TestNameList);
      settestselect(...getTestNames, TestNameList)
    }
  }, [getTrimHeaderInfo && getTrimHeaderInfo.length > 0]);


  useEffect(() => {
    if (comments && comments.item2 && comments.item2.length > 0) {
      let Options = [];
      comments.item2.forEach(element => {
        Options.push({ id: element.commentID, value: element.commentID, name: element.comment, label: element.comment });
      });
      setStandardOptions(Options);
    }
  }, [comments && comments.item2 && comments.item2.length > 0]);

  const handleChangeNew =(newdata) =>{
    TrimlabDetails(newdata);
  }
  // Function to handle changes and send data to the parent
  const handleChange = () => {
    const combinedData = {
      getAttachmentInfo
    };
    TrimDetails(combinedData);
    TrimlabDetails(getLabComments);
  };
  const handleChangeinitialload = (AttachmentInfo) => {
    const arry1 = { getAttachmentInfo: AttachmentInfo };
    TrimDetails(arry1);
    TrimlabDetails(getLabComments);
  };

  const handleAttachmentOnChange = (e, index) => {
    let AttachmentResult = [...getAttachmentInfo]
    let inputtext = ''

    if (AttachmentResult[index].resultType === 4) {
      if (e.target.value !== '') {
        inputtext = e.target.value
        AttachmentResult[index].foundGSM = inputtext

      }
      else {
        inputtext = ''
      }
    }

    setAttachmentInfo(AttachmentResult)
    handleChange()
  };

  const handleCheckboxChange = (event, testname) => {

    // Create a copy of the headerData array
    const updatedHeaderData = [...gettestselect];
    let AttachmentResult = [...getAttachmentInfo]


    // Find the index of the object with the matching testName
    const index = updatedHeaderData.findIndex((item) => item.testName === testname);

    if (index !== -1) {
      // Update the isChecked property of the matching object to 1 (checked)
      updatedHeaderData[index].isChecked = updatedHeaderData[index].isChecked === 1 ? 0 : 1;;

      const checkedItems = updatedHeaderData.filter(item => item.isChecked === 1);
      // Update the detailed information arrays
      updateDetailedInfoArray(AttachmentResult, testname, updatedHeaderData[index].isChecked);
      setTestNames(checkedItems)
    }
  };


  // Function to update the isChecked property in a detailed information array
  const updateDetailedInfoArray = (detailedInfoArray, testName, isChecked) => {

    const updatedArray = [...detailedInfoArray];
    const index = updatedArray.findIndex((item) => item.testName === testName);
    if (index !== -1) {
      updatedArray.forEach((item) => {
        if (item.testName === testName) {
          item.isChecked = isChecked;
        }
      })
    }
    // You may want to set the state for the detailed information array here
    // setStateForDetailedInfoArray(updatedArray);
  };



  const handleResultOnchange = (value, index, fieldName) => {
    let AttachmentResult = [...getAttachmentInfo]

    if (value !== null && fieldName === "ATTACHMENT OF STRENGTH") {
      AttachmentResult[index].ResultName = value.label
      AttachmentResult[index].ResultID = value.value
    }

    setAttachmentInfo(AttachmentResult);
    handleChange()
  };


  // Function to handle changes and send data to the parent
  const handleTrimChange = () => {
    //test
    TrimlabDetails(getLabComments);
  };


  const handleLabResultOnchange = (value, index, fieldName) => {

    let labComments = [...getLabComments]
    if (value !== null && fieldName === 'labcomment') {
      labComments[index].label = value.label
      labComments[index].value = value.value
    }
    else if (fieldName === 'Remarks') {
      labComments[index].Remarks = value.target.value
    }
    else {
      labComments[index].label = ''
      labComments[index].value = 0
    }
    //test
    setLabComments(labComments)
    handleTrimChange()
  };





  return (
    <div className="widget-body">
      <div id="registration-form">
        <div className="row">
          <h1>
            <span>
              {/* <input className='btn btn-info' onClick={handleShow}>Test Selection</input> */}
              <button className='btn btn-info' disabled={true} onClick={handleShow}>Test Selection</button>
            </span>
          </h1>


          <form>
            {
              getTestNames.length > 0 ?
                getTestNames != undefined && getTestNames.map((fabric, index) => (
                  <Fragment>
                    <div
                      style={{
                        display: fabric.isChecked !== 1 ? "none" : ''
                      }}



                      className="row">
                      <div className="col-md-12">
                        <div className="panel-group accordion" id="acc_Garment">
                          <div className="panel panel-default">
                            <div className="panel-heading">
                              <h4 className="panel-title">
                                <a className="accordion-toggle collapsed clr-themegreen" data-toggle="collapse" data-parent="#acc_Quantity_Info"
                                  href={"#" + fabric.testID + "Accordian"}>
                                  {index + 1 + ". " + fabric.testName}
                                </a>
                              </h4>
                            </div>
                            <div id={fabric.testID + "Accordian"} className="panel-collapse collapse">
                              <div className="panel-body border-red">
                                <div className="row">


                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <div className="row">
                                        <div className='col-md-3'>

                                          <label>Standard</label>
                                          <Reactselect className="basic-single"
                                            name={"StandardDetails" + index}
                                            id={"StandardDetails" + index}
                                            isDisabled={true}
                                            isLoading={false}
                                            isClearable={false}
                                            isSearchable={true}
                                            isMulti
                                            menuPosition="fixed"
                                            value={getStandardOptions.filter(data => {
                                              return fabric.standard.split(",").includes((data.id).toString());
                                            })}
                                            options={getStandardOptions}
                                          >
                                          </Reactselect>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12" style={{ marginTop: "10px" }}>

                                    {fabric.testName == "ATTACHMENT OF STRENGTH" && <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th>Details</th>
                                          <th>Required </th>
                                          <th>Found</th>
                                          <th>Result</th>


                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          getAttachmentInfo !== undefined && getAttachmentInfo.map((x, index) => (
                                            <>
                                              {

                                                <tr>
                                                  <td>{index + 1}</td>
                                                  <td>{
                                                    x.fieldName === 'Static' ?
                                                      <>
                                                        <input type="text" value='Fabric Bit 1 test' className='form-control' />

                                                      </>
                                                      :
                                                      x.fieldName
                                                  }


                                                  </td>
                                                  <td>
                                                    <>
                                                      {x.resultType === 2 ? <span> {x.minResult + "  " + "%" + "  " + "to" + "  " + x.maxResult + "  " + "%"} </span>
                                                        : x.resultType === 3 ? <span> {x.gradeValue}</span>
                                                          : <span> {x.minResult + "  " + "NM"}</span>
                                                      }
                                                    </>

                                                  </td>
                                                  <td>
                                                    {

                                                      x.resultType === 4 ? <input type="text" className='form-control' value={x.foundGSM} onChange={e => handleAttachmentOnChange(e, index)} onKeyPress={(e) => {
                                                        if (e.key === 'e' || e.key === 'E') {
                                                          e.preventDefault();
                                                        }
                                                      }}
                                                      style={submittedtestingdetails && (x.foundGSM === '' || x.foundGSM === null || x.foundGSM === undefined || x.foundGSM === 0) ? inputstyles : {}}
                                                      />


                                                        : ""

                                                    }

                                                  </td>
                                                  <td>


                                                    <Reactselect className="basic-single " name="IsProductionStatusID"
                                                      options={IsResult}
                                                      isClearable={true}
                                                      onChange={e => handleResultOnchange(e, index, x.testName)}
                                                      //value={GetResult}
                                                      value={IsResult.filter(function (option) {
                                                        return option.value === x.ResultID;
                                                      })}
                                                    ></Reactselect>
                                                  </td>
                                                </tr>
                                              }
                                            </>
                                          ))
                                        }
                                      </tbody>
                                    </table>}


                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ))
                : <tr><td colSpan="8" className='norecordfound'><span>No Records Found Please Check Masters</span></td></tr>
            }
          </form>
        </div>
        <br />
        <br />

        {
          getLabComments.map((x, index) => (
            <>
              <div className="row">
                <div className="col-lg-4">
                  <label htmlFor="">Lab Comments</label>
                  <br />

                  <Reactselect className="basic-single " name="IsProductionStatusID"
                    options={labResult}
                    isClearable={true}
                    onChange={e => handleLabResultOnchange(e, index, "labcomment")}
                    //value={GetResult}
                    styles={submittedlabcomments && x.value === 0 ? styles : ''}
                    value={labResult.filter(function (option) {
                      return option.value === x.value;
                    })}
                  ></Reactselect>
                </div>
                <div className="col-lg-12">
                  <label htmlFor="">Remarks</label>
                  <br />
                  <textarea name="" id=""
                    style={submittedlabremark && (x.value === 2 && x.Remarks === "") ? textareaStyles : {}}
                    onChange={e => handleLabResultOnchange(e, index, "Remarks")} value={x.Remarks} cols="150" rows="10"></textarea>
                </div>
              </div>
            </>
          ))
        }
      </div>
      <Modal show={show} backdrop="static" onHide={handleClose} dialogClassName='customdialog'>
        <div className="fixTableHeadTnadetail">
          <Modal.Header closeButton>
            <Modal.Title>TEST SELECTION POPUP</Modal.Title>
          </Modal.Header>
          {/* , overflowY: "auto" */}
          <Modal.Body className="customdialogbody">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="row">
                    {
                      gettestselect.map((fabric, index) => (
                        <div className="col-lg-6">
                          <li
                            className="listmodal"
                          >
                            <input
                              className="hidecheckbox"
                              type="checkbox"
                              name="IsShowAW"
                              checked={fabric.isChecked}
                              onChange={e => handleCheckboxChange(e, fabric.testName)}
                            />
                            &nbsp;<label htmlFor="IsShowAW">{fabric.testName}</label>
                          </li>

                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className='btn btn-info' onClick={handleClose}>Save</button>
          </Modal.Footer>
        </div>
      </Modal>
      {loader}
    </div>
  )
}

export default TrimTest