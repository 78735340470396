import React, { useState, useEffect, Fragment, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import ReactNotification from 'react-notifications-component';
import DatePicker from "react-datepicker";
import Reactselect from 'react-select';
import { Modal, Button } from "react-bootstrap";
import Nodify from '../../../Common/ReactNotification';
import FinalinspectionService from './../../../../services/FinalInspection/FinalinspectionService';
import ColorDepthPopUp from './../../../quickMaster/_ColorDepth';
import TestFieldPopUp from '../../../quickMaster/_TestField';
import BrandWiseStandardService from './../../../../services/Master/Brandwisestandard';
import TestStandardPopUp from '../../../quickMaster/_TestStandard';
import Masterservice from './../../../../services/Master/FabricService';
const BrandStandardStrikeOff = ({ props, StrikeOffTestNameList, GroupTypeOptions, StandardOptions, GenderOptions, ColorDepthOptions
    , FieldOptions, ResultReqTypeOptions, StatusUpdationCallBack, ColorDepthParentCall, TestFieldParentCall, SubmitStrikeOffDataCallBack
    , TestStandardParentCall, BuyerID, BrandID, IsSaveFailed }) => {
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red', innerHeight: '100px', outerHeight: '100px' }),
    }
    let styles1 = {
        control: styles => ({ ...styles, innerHeight: '100px', outerHeight: '100px' }),
    }

    const CustomInput = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
            />
        )
    }
    const CustomInputEffectfromDate = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHolidayEffectfromDate"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
            />
        )
    }

    const [getResultTypeGradeOptions, setResultTypeGradeOptions] = useState([
        { id: 1, value: 1, label: "5", name: "5" }
        , { id: 2, value: 2, label: "4-5", name: "4-5" }
        , { id: 3, value: 3, label: "4", name: "4" }
        , { id: 4, value: 4, label: "3-4", name: "3-4" }
        , { id: 5, value: 5, label: "3", name: "3" }
        , { id: 6, value: 6, label: "2-3", name: "2-3" }
        , { id: 7, value: 7, label: "2", name: "2" }
        , { id: 8, value: 8, label: "1-2", name: "1-2" }
        , { id: 9, value: 9, label: "1", name: "1" }
        , { id: 10, value: 10, label: "0-1", name: "0-1" }
        , { id: 11, value: 11, label: "No Changes", name: "No Changes" }
        , { id: 12, value: 12, label: "Changes Observed", name: "Changes Observed" }
    ]);

    const EmptyStatusInputFields = {
        TestStatus: null
        , TestStatusForEdit: ''
        , Comments: ''
        , EffectFromDate: ''
    }
    const [getStatusOptions, setStatusOptions] = useState([
        { id: 1, value: 1, label: 'Active', name: 'Active' }
        , { id: 0, value: 0, label: 'Inactive', name: 'Inactive' }]);
    const [inputFields, setInputFields] = useState([]);
    const [getStatusList, setStatusList] = useState([]);
    const [getTestNameStatusIndex, setTestNameStatusIndex] = useState({ Index: null, TestName: '', TestID: 0, Status: '' });
    const [getTestNameStatusPopUpViewMode, setTestNameStatusPopUpViewMode] = useState({ isShow: false });
    const [getUnitAreaSubmitted, setUnitAreaSubmitted] = useState(false);
    const [getActionTestNameID, setActionTestNameID] = useState(null);
    const [getParentFabricCopyIndex, setParentFabricCopyIndex] = useState({ FabricDetails: '', FabricDetailsForEdit: [], index: null });
    const [getUnitAreaChildSubmitted, setUnitAreaChildSubmitted] = useState(false);
    const [getAddColorDepthPopUpViewMode, setAddColorDepthPopUpViewMode] = useState({ isShow: false });
    const [getAddTestFieldPopUpViewMode, setAddTestFieldPopUpViewMode] = useState({ isShow: false });
    const [getIsInternalGridAdded, setIsInternalGridAdded] = useState(false);
    const [getIsTestNameStatusSubmitted, setIsTestNameStatusSubmitted] = useState(false);
    const [statusInputFields, setstatusInputFields] = useState(EmptyStatusInputFields);
    const { user: currentUser } = useSelector((state) => state.auth);
    const [getStrikeoffDpData, setStrikeoffDpData] = useState([]);
    const [getAddedColorDepthData, setAddedColorDepthData] = useState([]);
    const [getIsReloadColorDepth, setIsReloadColorDepth] = useState([]);
    const [getAddedTestFieldData, setAddedTestFieldData] = useState([]);
    const [getIsReloadTestField, setIsReloadTestField] = useState([]);
    const [getStandardTestID, setStandardTestID] = useState([]);
    const [getAddedTestStandardData, setAddedTestStandardData] = useState([]);
    const [getAddStandardPopUpViewMode, setAddStandardPopUpViewMode] = useState({ isShow: false });
    const [getIsReloadTestStandard, setIsReloadTestStandard] = useState([]);
    const [getMinResultValidation, setMinResultValidation] = useState(false);
    const [getMaxResultValidation, setMaxResultValidation] = useState(false);
    const [getMinMaxInternalIndex, setMinMaxInternalIndex] = useState(false);
    const [getMinMaxStandardIndex, setMinMaxStandardIndex] = useState(false);
    const [getMinMaxFabricIndex, setMinMaxFabricIndex] = useState(false);
    const [getMinMaxTestIndex, setMinMaxTestIndex] = useState(false);
    const [getResultType, setResultType] = useState([]);

    const Values = [...inputFields];

    const ViewTestNameStatusPopUp = (index, testName, testID, status) => {
        BrandWiseStandardService.LoadMappedTestStatus(testID, BuyerID, BrandID, 4).then((response) => {
            setStatusList(response.data.item1);
        }).catch(() => { });
        setTestNameStatusIndex({ Index: index, TestName: testName, TestID: testID, Status: status });
        setTestNameStatusPopUpViewMode({ isShow: true });
    }
    useEffect(() => {
        Masterservice.LoadMasterbrandWiseTypeList(2, BuyerID, BrandID).then((res) => {
            if (res.data) {
                setStrikeoffDpData(res.data)
            }
        })
    }, [])
    const handleExpandRow = (testindex, parentindex, e) => {
        let Value = [...inputFields];
        if (e.currentTarget.className == "fa fa-plus-square-o clsPointer clscollapseexpand") {
            Value[testindex].parentFields[parentindex].IsExpand = 1;
        }
        else if (e.currentTarget.className == "fa fa-minus-square-o clsPointer clscollapseexpand") {
            Value[testindex].parentFields[parentindex].IsExpand = 0;
        }
        for (var i = 0; i < (Value[testindex].parentFields.length); i++) {
            if (i !== parentindex) {
                Value[testindex].parentFields[i].IsExpand = 0;
            }
        }
        setInputFields(Value);
    }

    const handleChangeDate = (e, testindex, parentindex, fieldname) => {
        const Value1 = [...inputFields];
        if (fieldname === "CreatedOn") {
            if (e !== null) {
                Value1[testindex].parentFields[parentindex].CreatedOn = e;
            }
            else {
                Value1[testindex].parentFields[parentindex].CreatedOn = null;
            }
        }
        setInputFields(Value1);
        SubmitStrikeOffDataCallBack(Value1);
    }

    const handlleGradeChange = (event, testindex, parentindex, standardindex, internalindex) => {
        let Value = [...inputFields];
        if (event != null) {
            Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].MinResult = event.id;
            Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].MinResultForEdit = event;
        }
        else {
            Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].MinResult = "";
            Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].MinResultForEdit = [];
        }
        setInputFields(Value);
    }

    const handleParentTextBoxChange = (e, testindex, parentindex, fieldName) => {
        let Value = [...inputFields];
        if (fieldName == "Group") {
            let inputText = '';
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            Value[testindex].parentFields[parentindex].Group = inputText;
        }
        else if (fieldName == "FabricDetails") {
            Value[testindex].parentFields[parentindex].Group = e.target.value;
        }
        setInputFields(Value);
        SubmitStrikeOffDataCallBack(Value);
    }

    const handleChangeParentSelectField = (e, testindex, parentindex, FieldName) => {
        let Value = [...inputFields];
        if (e != null) {
            let fabricValue = "";
            for (let gf of e) {
                if (fabricValue === "") {
                    fabricValue = gf.value.toString();
                } else {
                    fabricValue = fabricValue + "," + gf.value;
                }
            }
            Value[testindex].parentFields[parentindex].FabricDetails = fabricValue;
            Value[testindex].parentFields[parentindex].FabricDetailsForEdit = e;
        }
        else {
            if (e != null) {
                Value[testindex].parentFields[parentindex].FabricDetails = null;
                Value[testindex].parentFields[parentindex].FabricDetailsForEdit = "";
            }
        }
        setInputFields(Value);
        SubmitStrikeOffDataCallBack(Value);
    }

    const CopyFabricIndex = (e, testindex, parentindex) => {
        const Values = [...inputFields];
        setParentFabricCopyIndex({
            FabricDetails: Values[testindex].parentFields[parentindex].FabricDetails
            , FabricDetailsForEdit: Values[testindex].parentFields[parentindex].FabricDetailsForEdit
            , index: parentindex
        });
    }

    const PasteFabricIndex = (e, testindex, parentindex) => {
        const Values = [...inputFields];
        Values[testindex].parentFields[parentindex].FabricDetails = getParentFabricCopyIndex.FabricDetails;
        Values[testindex].parentFields[parentindex].FabricDetailsForEdit = getParentFabricCopyIndex.FabricDetailsForEdit;
        setInputFields(Values);
    }

    const AddUnitAreaRow = (testindex, parentindex, testnameid) => {
        let Value = [...inputFields];
        setActionTestNameID(testnameid);
        setUnitAreaSubmitted(true);
        for (var i = 0; i < (Value.length); i++) {
            if (Value[i].testNameID == testnameid) {
                if ((Value[testindex].parentFields[parentindex].CreatedOn == "" || Value[testindex].parentFields[parentindex].CreatedOn == null || Value[testindex].parentFields[parentindex].CreatedOn == undefined)
                    || (Value[testindex].parentFields[parentindex].Group == "" || Value[testindex].parentFields[parentindex].Group == null || Value[testindex].parentFields[parentindex].Group == undefined)
                    || (Value[testindex].parentFields[parentindex].FabricDetailsForEdit == "" || Value[testindex].parentFields[parentindex].FabricDetailsForEdit == null || Value[testindex].parentFields[parentindex].FabricDetailsForEdit == undefined)) {
                    Nodify('Warning!', 'warning', "Please fill the mandatory(*) fields.");
                    return false;
                }
                else {
                    for (var j = 0; j < (Value[testindex].parentFields[parentindex].StandardResultRequirement.length); j++) {
                        let standardData = Value[testindex].parentFields[parentindex].StandardResultRequirement;
                        if ((standardData[j].CreatedOn == "" || standardData[j].CreatedOn == null || standardData[j].CreatedOn == undefined)
                            || (standardData[j].EffectFromDate == "" || standardData[j].EffectFromDate == null || standardData[j].EffectFromDate == undefined)
                            || (standardData[j].GenderID == "" || standardData[j].GenderID == null || standardData[j].GenderID == undefined)) {
                            Nodify('Warning!', 'warning', "Please fill the mandatory(*) fields.");
                            return false;
                        }
                        else {
                            for (var k = 0; k < (Value[testindex].parentFields[parentindex].StandardResultRequirement[j].InternalGrid.length); k++) {
                                let InternalData = Value[testindex].parentFields[parentindex].StandardResultRequirement[j].InternalGrid;
                                if ((InternalData[k].ColorDepthForEdit == "" || InternalData[k].ColorDepthForEdit == null || InternalData[k].ColorDepthForEdit == undefined
                                    || (InternalData[k].FieldForEdit == "" || InternalData[k].FieldForEdit == null || InternalData[k].FieldForEdit == undefined)
                                    || (InternalData[k].ResultType == "" || InternalData[k].ResultType == null || InternalData[k].ResultType == undefined)
                                    || ((InternalData[k].ResultType == 3 || InternalData[k].ResultType == 4)
                                        && (InternalData[k].MinResult == "" || InternalData[k].MinResult == null || InternalData[k].MinResult == undefined))
                                    || ((InternalData[k].ResultType != 3 && InternalData[k].ResultType != 4)
                                        && ((InternalData[k].MinResult == "" || InternalData[k].MinResult == null || InternalData[k].MinResult == undefined)
                                            || (InternalData[k].MaxResult == "" || InternalData[k].MaxResult == null || InternalData[k].MaxResult == undefined))))) {
                                    Nodify('Warning!', 'warning', "Please fill the mandatory(*) fields.");
                                    return false;
                                }
                            }
                        }
                    }
                }
            }
        }
        Value[testindex].parentFields.push({
            OrderID: 0
            , CreatedOn: ''
            , Group: ''
            , FabricDetails: []
            , FabricDetailsForEdit: ""
            , IsExpand: 0
            , FabricIndexID: testindex
            , Parentindex: parentindex + 1
            , StandardResultRequirement: [
                {
                    CreatedOn: new Date()
                    , EffectFromDate: ''
                    //, StandardID: 0
                    , StandardIDForEdit: []
                    , StandardIDText: ""
                    //, GenderID: 0
                    , GenderIDForEdit: []
                    , GenderIDText: ""
                    , FabricIndexID: testindex
                    , StandardIndexID: 0
                    , Parentindex: parentindex + 1
                    , InternalGrid: [
                        {
                            ColorDepth: ""
                            , ColorDepthForEdit: []
                            , TestFieldText: ""
                            , ColorDepthText: ""
                            , ResultTypeText: ""
                            , ResultTypeForEdit: []
                            , Field: ""
                            , FieldForEdit: []
                            , FabricIndexID: testindex
                            , Parentindex: parentindex + 1
                            , ResultType: 0
                            , StandardIndexID: 0
                            , InternalIndexID: 0
                            , MinResult: ''
                            , MinResultForEdit: []
                            , MaxResult: ''
                        }
                    ]

                }
            ]
        })
        setUnitAreaSubmitted(false);
        setInputFields(Value);
    }

    const RemoveUnitAreaRow = (testindex, parentindex) => {
        const Values = [...inputFields];
        Values[testindex].parentFields.splice(parentindex, 1);
        setInputFields(Values);
    }

    const handleChangeStandardDate = (e, testindex, parentindex, standardindex, FieldName) => {
        let Value = [...inputFields];
        if (FieldName === "StandardCreatedOn") {
            if (e !== null) {
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].CreatedOn = e;
            }
            else {
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].CreatedOn = null;
            }
            setInputFields(Value);
        }
        if (FieldName === "StandardEffectFromDate") {
            if (e !== null) {
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].EffectFromDate = e;
            }
            else {
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].EffectFromDate = null;
            }
            setInputFields(Value);
        }
        SubmitStrikeOffDataCallBack(Value);
    }

    const handleChangeStandardSelectField = (e, testindex, parentindex, standardindex, FieldName) => {
        let Value = [...inputFields];
        if (FieldName === "Gender") {
            // if (e != null) {
            //     var CheckDupilcates = Value[testindex].parentFields[parentindex].StandardResultRequirement.filter((data => {
            //         if (data.GenderID != null) {
            //             return data.GenderID.id == e.id;
            //         }
            //     }))
            //     if (CheckDupilcates.length > 0) {
            //         Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].GenderID = null;
            //         Nodify('Warning!', 'warning', 'This Gender is already exist.');
            //     }
            //     else {
            //         Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].GenderID = e;
            //     }
            // }
            // else {
            //     Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].GenderID = null;
            // }

            if (e != null && e.length != 0) {
                let genderValue = "";
                for (let fv of e) {
                    if (genderValue === "") {
                        genderValue = fv.value.toString();
                    } else {
                        genderValue = genderValue + "," + fv.value;
                    }
                }
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].GenderID = genderValue;
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].GenderIDForEdit = e;
                let GenderReducedText;
                if (e.length > 1) {
                    GenderReducedText = e.reduce(function (a, b) {
                        return (a.label || a) + ", " + b.label
                    });
                }
                else {
                    GenderReducedText = e[0].name;
                }
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].GenderIDText = GenderReducedText;
            }
            else {
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].GenderID = null;
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].GenderIDForEdit = "";
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].GenderIDText = "";
            }









        }
        else if (FieldName === "Standard") {
            if (e != null && e.length != 0) {
                let StandardValue = "";
                for (let cd of e) {
                    if (StandardValue === "") {
                        StandardValue = cd.value.toString();
                    } else {
                        StandardValue = StandardValue + "," + cd.value;
                    }
                }
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].StandardID = StandardValue;
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].StandardIDForEdit = e;
                let StandardReducedText;
                if (e.length > 1) {
                    StandardReducedText = e.reduce(function (a, b) {
                        return (a.label || a) + ", " + b.label
                    });
                }
                else {
                    StandardReducedText = e[0].name;
                }
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].StandardIDText = StandardReducedText;
            }
            else {
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].StandardID = null;
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].StandardIDForEdit = "";
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].StandardIDText = "";
            }
        }
        else if (FieldName === "ColorDepth") {
            if (e != null) {
                let ColorDepthValue = "";
                for (let cd of e) {
                    if (ColorDepthValue === "") {
                        ColorDepthValue = cd.value.toString();
                    } else {
                        ColorDepthValue = ColorDepthValue + "," + cd.value;
                    }
                }
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].ColorDepth = ColorDepthValue;
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].ColorDepthForEdit = e;
            }
            else {
                if (e != null) {
                    Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].ColorDepth = null;
                    Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].ColorDepthForEdit = "";
                }
            }
        }
        else if (FieldName === "Field") {
            if (e != null) {
                let FieldValue = "";
                for (let fv of e) {
                    if (FieldValue === "") {
                        FieldValue = fv.value.toString();
                    } else {
                        FieldValue = FieldValue + "," + fv.value;
                    }
                }
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].Field = FieldValue;
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].FieldForEdit = e;
            }
            else {
                if (e != null) {
                    Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].Field = null;
                    Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].FieldForEdit = "";
                }
            }
        }
        else if (FieldName === "ResultType") {
            if (e != null) {
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].ResultType = e;
            }
            else {
                if (e != null) {
                    Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].FiResultTypeld = null
                }
            }
        }
        setInputFields(Value);
        SubmitStrikeOffDataCallBack(Value);
    }

    const ViewColorDepthCreatePopUp = () => {
        setAddColorDepthPopUpViewMode({ isShow: true });
    }

    const ViewTestFieldCreatePopUp = () => {
        setAddTestFieldPopUpViewMode({ isShow: true });
    }

    const HideTestNameStatusPopUp = () => {
        setIsTestNameStatusSubmitted(false);
        setstatusInputFields(EmptyStatusInputFields);
        setTestNameStatusPopUpViewMode({ isShow: false });
    }

    const HideColorDepthCreatePopUp = () => {
        setAddColorDepthPopUpViewMode({ isShow: false });
    }

    const HideTestFieldCreatePopUp = () => {
        setAddTestFieldPopUpViewMode({ isShow: false });
    }

    const handleChangeInternalSelectField = (e, testindex, parentindex, standardindex, internalindex, FieldName) => {
        let Value = [...inputFields];
        if (FieldName === "ColorDepth") {
            if (e != null && e.length != 0) {
                let ColorDepthValue = "";
                for (let cd of e) {
                    if (ColorDepthValue === "") {
                        ColorDepthValue = cd.value.toString();
                    } else {
                        ColorDepthValue = ColorDepthValue + "," + cd.value;
                    }
                }
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].ColorDepth = ColorDepthValue;
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].ColorDepthForEdit = e;
                let ColorDepthReducedText;
                if (e.length > 1) {
                    ColorDepthReducedText = e.reduce(function (a, b) {
                        return (a.label || a) + ", " + b.label
                    });
                }
                else {
                    ColorDepthReducedText = e[0].name;
                }
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].ColorDepthText = ColorDepthReducedText;
            }
            else {
                if (e != null) {
                    Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].ColorDepth = null;
                    Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].ColorDepthForEdit = "";
                    Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].ColorDepthText = "";
                }
            }
        }
        else if (FieldName === "Field") {
            if (e != null && e.length != 0) {
                let FieldValue = "";
                for (let fv of e) {
                    if (FieldValue === "") {
                        FieldValue = fv.value.toString();
                    } else {
                        FieldValue = FieldValue + "," + fv.value;
                    }
                }
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].Field = FieldValue;
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].FieldForEdit = e;
                let FieldReducedText;
                if (e.length > 1) {
                    FieldReducedText = e.reduce(function (a, b) {
                        return (a.label || a) + ", " + b.label
                    });
                }
                else {
                    FieldReducedText = e[0].name;
                }
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].TestFieldText = FieldReducedText;
            }
            else {
                if (e != null) {
                    Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].Field = null;
                    Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].FieldForEdit = "";
                    Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].TestFieldText = "";
                }
            }
        }
        else if (FieldName === "ResultType") {
            if (e != null) {
                setResultType(e.id);
                if (e.id == 4) {
                    Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].MinResult = "";
                    Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].MinResultForEdit = [];
                    Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].MaxResult = "";
                }
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].ResultType = e.id;
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].ResultTypeForEdit = e;
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].ResultTypeText = e.name;
            }
            else if (e == null) {
                setResultType(0);
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].MinResult = "";
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].MinResultForEdit = [];
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].MaxResult = "";
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].ResultType = null;
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].ResultTypeForEdit = [];
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].ResultTypeText = "";
            }
        }
        setInputFields(Value);
        SubmitStrikeOffDataCallBack(Value);
    }
    const handleMinMaxChange = (e, testindex, parentindex, standardindex, internalindex, fieldName) => {
        let Value = [...inputFields];
        let inputText = '';
        if (e.target.value.trim() !== '') {
            inputText = e.target.value;
        }
        if (fieldName == "MinResult") {
            if (e.target.value) {
                //if (inputText != null && inputText != undefined && inputText.length <= 9) {
                if (inputText != null && inputText != undefined) {
                    Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].MinResult = inputText.toString();
                }
            }
            else {
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].MinResult = null;
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].MinResultForEdit = [];
            }
        }
        if (fieldName == "MaxResult") {
            if (e.target.value) {
                //if (inputText != null && inputText != undefined && inputText.length <= 9) {
                if (inputText != null && inputText != undefined) {
                    Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].MaxResult = inputText.toString();
                }
            }
            else {
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].MaxResult = null;
            }
        }
        setInputFields(Value);
        SubmitStrikeOffDataCallBack(Value);
    }

    const handleMinMaxValidation = (e, testindex, parentindex, standardindex, internalindex, fieldName) => {
        let Value = [...inputFields];
        setMinMaxInternalIndex(internalindex);
        setMinMaxStandardIndex(standardindex);
        setMinMaxFabricIndex(parentindex);
        setMinMaxTestIndex(testindex);
        if (fieldName == "MinResult") {
            setMinResultValidation(true);
            if (e.target.value) {
                if (Math.round(Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].MaxResult) > 0
                    && Math.round(e.target.value) <= Math.round(Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].MaxResult)) {
                    Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].MinResult = Math.round(e.target.value);
                    setMinResultValidation(false);
                    setMinMaxInternalIndex(null);
                    setMinMaxStandardIndex(null);
                    setMinMaxFabricIndex(null);
                    setMinMaxTestIndex(null);
                }
                else if ((Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].MaxResult != "") && Math.round(e.target.value) >
                    (Math.round(Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].MaxResult
                    ))
                ) {
                    Nodify('Warning!', 'warning', 'Min Result must be smaller than Max Result');
                    Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].MinResult = "";
                    Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].MinResultForEdit = [];
                }
                else {
                    setMinResultValidation(false);
                    setMinMaxInternalIndex(null);
                    setMinMaxStandardIndex(null);
                    setMinMaxFabricIndex(null);
                    setMinMaxTestIndex(null);
                    Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].MinResult = Math.round(e.target.value);
                }
            }
            else {
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].MinResult = null;
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].MinResultForEdit = [];
            }
        }
        if (fieldName == "MaxResult") {
            setMaxResultValidation(true);
            if (e.target.value) {
                if (Math.round(Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].MinResult) > 0
                    && Math.round(e.target.value) >= Math.round(Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].MinResult)) {
                    Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].MaxResult = Math.round(e.target.value);
                    setMaxResultValidation(false);
                    setMinMaxInternalIndex(null);
                    setMinMaxStandardIndex(null);
                    setMinMaxFabricIndex(null);
                    setMinMaxTestIndex(null);
                }
                else if ((Math.round(e.target.value) < Math.round(Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].MinResult))
                ) {
                    Nodify('Warning!', 'warning', 'Max Result must be greater than Min Result');
                    Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].MaxResult = "";
                }
                else {
                    setMaxResultValidation(false);
                    setMinMaxInternalIndex(null);
                    setMinMaxStandardIndex(null);
                    setMinMaxFabricIndex(null);
                    setMinMaxTestIndex(null);
                    Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].MaxResult = Math.round(e.target.value);
                }
            }
        }
        setInputFields(Value);
        SubmitStrikeOffDataCallBack(Value);
    }

    const handleInternalTextBoxChange = (e, testindex, parentindex, standardindex, internalindex, fieldName) => {
        let Value = [...inputFields];
        let inputText = '';
        if (e.target.value.trim() !== '') {
            inputText = e.target.value;
        }
        if (fieldName == "MinResult") {
            if (e.target.value) {
                if (inputText != null && inputText != undefined && inputText.length <= 9) {
                    Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].MinResult = inputText.toString();
                }
            }
            else {
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].MinResult = null;
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].MinResultForEdit = [];
            }
        }
        if (fieldName == "MaxResult") {
            if (e.target.value) {
                if (inputText != null && inputText != undefined && inputText.length <= 9) {
                    Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].MaxResult = inputText.toString();
                }
            }
            else {
                Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid[internalindex].MaxResult = null;
            }
        }
        setInputFields(Value);
        SubmitStrikeOffDataCallBack(Value);
    }

    const AddInternalGrid = (e, testindex, parentindex, standardindex, internalindex, testnameid) => {
        setActionTestNameID(testnameid);
        let value = inputFields[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid;
        for (var i = 0; i < (value.length); i++) {
            if ((value[i].ColorDepthForEdit == "" || value[i].ColorDepthForEdit == null || value[i].ColorDepthForEdit == undefined)
                || (value[i].FieldForEdit == "" || value[i].FieldForEdit == null || value[i].FieldForEdit == undefined)
                || (value[i].ResultType == "" || value[i].ResultType == null || value[i].ResultType == undefined)
                || ((value[i].ResultType == 3 || value[i].ResultType == 4)
                    && (value[i].MinResult == "" || value[i].MinResult == null || value[i].MinResult == undefined))
                || ((value[i].ResultType != 3 && value[i].ResultType != 4)
                    && ((value[i].MinResult == "" || value[i].MinResult == null || value[i].MinResult == undefined)
                        || (value[i].MaxResult == "" || value[i].MaxResult == null || value[i].MaxResult == undefined)))) {
                setIsInternalGridAdded(true);
                Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
                return false;
            }
        }
        inputFields[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid.push(
            {
                ColorDepth: ""
                , ColorDepthForEdit: []
                , TestFieldText: ""
                , ColorDepthText: ""
                , ResultTypeText: ""
                , ResultTypeForEdit: []
                , Field: ""
                , FieldForEdit: []
                , ResultType: 0
                , MinResult: ''
                , MinResultForEdit: []
                , MaxResult: ''
                , FabricIndexID: inputFields[testindex].parentFields[parentindex].FabricIndexID
                , StandardIndexID: standardindex
                , GenderIndexID: standardindex
                , InternalIndexID: internalindex + 1
                , Parentindex: inputFields[testindex].parentFields[parentindex].Parentindex
            }
        )
        setIsInternalGridAdded(false);
        setInputFields(Values);
    }

    const RemoveInternalGrid = (e, testindex, parentindex, standardindex, internalindex) => {
        const Values = [...inputFields];
        Values[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid.splice(internalindex, 1);
        setInputFields(Values);
    }

    const AddUnitAreaChildRow = (testindex, parentindex, standardindex, testnameid) => {
        let Value = [...inputFields];
        setActionTestNameID(testnameid);
        setUnitAreaChildSubmitted(true);
        let standardData = Value[testindex].parentFields[parentindex].StandardResultRequirement;
        for (var i = 0; i < (Value[testindex].parentFields[parentindex].StandardResultRequirement.length); i++) {
            if ((standardData[i].CreatedOn == null || standardData[i].CreatedOn == "" || standardData[i].CreatedOn == undefined)
                || (standardData[i].EffectFromDate == null || standardData[i].EffectFromDate == "" || standardData[i].EffectFromDate == undefined)
                || (standardData[i].GenderID == null || standardData[i].GenderID == "" || standardData[i].GenderID == undefined)) {
                Nodify('Warning!', 'warning', "Please fill the mandatory(*) fields.");
                return false;
            }
            else {
                let InternalData = Value[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid;
                for (var j = 0; j < (InternalData.length); j++) {
                    if ((InternalData[j].ColorDepthForEdit == null || InternalData[j].ColorDepthForEdit == "" || InternalData[j].ColorDepthForEdit == undefined)
                        || (InternalData[j].FieldForEdit == null || InternalData[j].FieldForEdit == "" || InternalData[j].FieldForEdit == undefined)
                        || (InternalData[j].ResultType == null || InternalData[j].ResultType == "" || InternalData[j].ResultType == undefined)
                        || ((InternalData[j].ResultType == 3 || InternalData[j].ResultType == 4)
                            && (InternalData[j].MinResult == "" || InternalData[j].MinResult == null || InternalData[j].MinResult == undefined))
                        || ((InternalData[j].ResultType != 3 && InternalData[j].ResultType != 4)
                            && ((InternalData[j].MinResult == "" || InternalData[j].MinResult == null || InternalData[j].MinResult == undefined)
                                || (InternalData[j].MaxResult == "" || InternalData[j].MaxResult == null || InternalData[j].MaxResult == undefined)))) {
                        Nodify('Warning!', 'warning', "Please fill the mandatory(*) fields.");
                        return false;
                    }
                }
            }
        }
        Value[testindex].parentFields[parentindex].StandardResultRequirement.push(
            {
                CreatedOn: new Date()
                , EffectFromDate: ''
                , GenderID: 0
                , FabricIndexID: Value[testindex].parentFields[parentindex].FabricIndexID
                , Parentindex: Value[testindex].parentFields[parentindex].Parentindex
                , Standard: 0
                , StandardIndexID: Value[testindex].parentFields[parentindex].StandardResultRequirement.length
                , GenderIndexID: Value[testindex].parentFields[parentindex].StandardResultRequirement.length
                , InternalGrid: [
                    {
                        ColorDepth: ""
                        , ColorDepthForEdit: []
                        , TestFieldText: ""
                        , ColorDepthText: ""
                        , ResultTypeText: ""
                        , ResultTypeForEdit: []
                        , Field: ""
                        , FieldForEdit: []
                        , ResultType: 0
                        , MinResult: ''
                        , MinResultForEdit: []
                        , MaxResult: ''
                        , Parentindex: Value[testindex].parentFields[parentindex].Parentindex
                        , FabricIndexID: Value[testindex].parentFields[parentindex].FabricIndexID
                        , StandardIndexID: standardindex + 1
                        , GenderIndexID: standardindex + 1
                        , InternalIndexID: 0
                    }
                ]
            })
        setUnitAreaChildSubmitted(false);
        setInputFields(Values);
    }

    const RemoveUnitAreaChildRow = (testindex, parentindex, standardindex) => {
        const Values = [...inputFields];
        Values[testindex].parentFields[parentindex].StandardResultRequirement.splice(standardindex, 1);
        setInputFields(Values);
    }

    const handleTestStatus = (e) => {
        const Values = { ...statusInputFields };
        if (e != null) {
            Values.TestStatus = e.id;
            Values.TestStatusForEdit = e;
        }
        else {
            Values.TestStatus = 0;
            Values.TestStatusForEdit = null;
        }
        setstatusInputFields(Values);
    }

    const handleTestNameStatusComments = (e, FieldName) => {
        const Values = { ...statusInputFields };
        let inputText = '';
        if (e.target.value.trim() !== '') {
            inputText = e.target.value;
        }
        if (FieldName == "Comments") {
            Values.Comments = inputText;
        }
        else if (FieldName == "EffectFromDate") {
            Values.EffectFromDate = inputText;
        }
        setstatusInputFields(Values);
    }

    const SaveTestNameStatus = (TestStatus) => {
        setIsTestNameStatusSubmitted(true);
        if (BuyerID == 0 || BrandID == 0) {
            Nodify("Warning!", "warning", "Please select Buyer and Brand.");
        }
        else {
            const Values = { ...statusInputFields };
            if (
                // (Values.TestStatusForEdit != "" && Values.TestStatusForEdit != undefined && Values.TestStatusForEdit != null)
                // && 
                (Values.Comments != "" && Values.Comments != undefined && Values.Comments != null)
                && (Values.EffectFromDate != "" && Values.EffectFromDate != undefined && Values.EffectFromDate != null)) {
                let params = {
                    TestNameID: getTestNameStatusIndex.TestID
                    , Comment: Values.Comments
                    , EffectFromDate: Values.EffectFromDate
                    , Status: TestStatus
                    , CreatedBy: currentUser.employeeinformationID
                    , BuyerID: BuyerID
                    , BrandID: BrandID
                    , TestType: 4
                };

                BrandWiseStandardService.InsertMappedTestStatus(params).then((response) => {
                    if (response.data.item1) {
                        setStatusList(response.data.item1);
                        let CurrentTestStatus = response.data.item1[0].status;
                        setstatusInputFields(EmptyStatusInputFields);
                        StatusUpdationCallBack(getTestNameStatusIndex.TestID, CurrentTestStatus);
                        Values.TestStatus = "";
                        Values.TestStatusForEdit = "";
                        Values.EffectFromDate = "";
                        Values.Comments = "";
                        TestStatus = "";
                        setstatusInputFields(Values);
                    }
                    setIsTestNameStatusSubmitted(false);
                }, (err) => {
                    setIsTestNameStatusSubmitted(false);
                });
                setTestNameStatusPopUpViewMode({ isShow: false });
            }
            else {
                setIsTestNameStatusSubmitted(true);
            }
        }
    }

    const AddColorDepthCallback = (value, data) => {
        const Values = [...inputFields];
        if (value) {
            setAddedColorDepthData(data);
            setAddColorDepthPopUpViewMode(false);
            Nodify("Success!", "success", "Color Depth added successfully.");
        }
        setIsReloadColorDepth(value);
        ColorDepthParentCall(data);
    }

    const AddTestFieldCallback = (value, data) => {
        const Values = [...inputFields];
        if (value) {
            setAddedTestFieldData(data);
            setAddTestFieldPopUpViewMode(false);
            Nodify("Success!", "success", "Test Field added successfully.");
        }
        setIsReloadTestField(value);
        TestFieldParentCall(data);
    }


    const ViewStandardCreatePopUp = (testID) => {
        setStandardTestID(testID);
        setAddStandardPopUpViewMode({ isShow: true });
    }

    const HideStandardCreatePopUp = () => {
        setAddStandardPopUpViewMode({ isShow: false });
    }

    const AddTestStandardCallback = (value, data) => {
        const Values = [...inputFields];
        if (value) {
            setAddedTestStandardData(data);
            setAddStandardPopUpViewMode(false);
            Nodify("Success!", "success", "Test Standard added successfully.");
        }
        setIsReloadTestStandard(value);
        TestStandardParentCall(data);
    }
    useEffect(() => {
        ;
        let RestrictDuplicatedtestName = [];

        // Filter FabricTestNameList to get only Fabric Tests
        let FabricTests = StrikeOffTestNameList.filter((data) => {
            return data.testType.split(" ").join('').toLowerCase() === "strikeofftest";
        });

        // Iterate over FabricTests and add unique test names
        FabricTests.forEach((ele, eleindex) => {
            // Define the structure to be pushed with each unique test
            let parentFieldStructure = {
                OrderID: 0,
                CreatedOn: '',
                Group: '',
                FabricDetails: [],
                FabricDetailsForEdit: "",
                FabricIndexID: eleindex, // Assigning FabricIndexID here
                IsExpand: 0,
                StandardResultRequirement: [
                    {
                        CreatedOn: new Date(),
                        EffectFromDate: '',
                        StandardID: 0,
                        StandardIDForEdit: "",
                        FabricIndexID: eleindex, // Assign FabricIndexID to this field as well
                        StandardIndexID: 0, // Assign StandardIndexID as 0
                        GenderID: 0,
                        InternalGrid: [
                            {
                                ColorDepth: "",
                                ColorDepthForEdit: [],
                                Field: "",
                                FieldForEdit: [],
                                FabricIndexID: eleindex, // Assign FabricIndexID in the grid
                                StandardIndexID: 0, // Assign StandardIndexID in InternalGrid as well
                                InternalIndexID: 0,
                                ResultType: 0,
                                MinResult: '',
                                MaxResult: ''
                            }
                        ]
                    }
                ]
            };

            // Check for duplicates and push unique structure into RestrictDuplicatedtestName
            if (RestrictDuplicatedtestName.length === 0) {
                // Push the parent field structure along with `ele` details
                RestrictDuplicatedtestName.push({
                    ...ele,
                    parentFields: [parentFieldStructure] // Attach the parent field structure
                });
            } else {
                let filterDuplicate = RestrictDuplicatedtestName.filter((data) => {
                    return data.testName === ele.testName;
                });
                if (filterDuplicate.length === 0) {
                    // Push the parent field structure along with `ele` details
                    RestrictDuplicatedtestName.push({
                        ...ele,
                        parentFields: [parentFieldStructure] // Attach the parent field structure
                    });
                }
            }
        });

        // Set the unique test names with the required structure in the input fields state
        setInputFields([...RestrictDuplicatedtestName]);
    }, [StrikeOffTestNameList]);
    // useEffect(() => {
    //     let RestrictDuplicatedtestName = [];
    //     let FabricTests = StrikeOffTestNameList.filter((data => {
    //         return data.testType.split(" ").join('').toLowerCase() == "strikeofftest";
    //     }))
    //     FabricTests.forEach((ele, eleindex) => {
    //         if (RestrictDuplicatedtestName.length == 0) {
    //             RestrictDuplicatedtestName.push(ele);
    //         }
    //         else if (RestrictDuplicatedtestName.length > 0) {
    //             let filterDuplicate = RestrictDuplicatedtestName.filter((data => {
    //                 return data.testName == ele.testName;
    //             }))
    //             if (filterDuplicate.length > 0) {

    //             }
    //             else {
    //                 RestrictDuplicatedtestName.push(ele);
    //             }
    //         }
    //     })
    //     setInputFields([...RestrictDuplicatedtestName]);
    // }, [StrikeOffTestNameList != undefined && StrikeOffTestNameList.length > 0 && StrikeOffTestNameList])
    return (
        <>
            <div className="page-body">
                {inputFields != undefined && inputFields.length == 0 &&
                    <p className='norecordfound text-center'><span>No Records Found</span>
                    </p>}
                {
                    inputFields != undefined && inputFields.map((test, testindex) => (
                        <>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="panel-group accordion" id="acc_MassPerUnitArea">
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <h4 className="panel-title">
                                                    <a className="accordion-toggle collapsed clr-themegreen" data-toggle="collapse" data-parent="#acc_Quantity_Info"
                                                        href={"#" + test.testNameID + "AccordianStrikeOff"}>
                                                        {test.testName}<span className='text-info' style={{ marginLeft: "2%" }}><b>
                                                            Current Status :  {test.status == 1 ? <span className='text-success'><b>Active</b></span> :
                                                                <span className='text-danger'><b>Inactive</b></span>}</b></span>
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id={test.testNameID + "AccordianStrikeOff"} className="panel-collapse collapse">
                                                <div className="panel-body border-red">
                                                    <div className="col-md-12">
                                                        {
                                                            getStatusList != undefined && getStatusList.length == 0 ?
                                                                <button title='Status' style={{ float: 'right' }}
                                                                    className="btn btn-success btn-xs"
                                                                    onClick={event => ViewTestNameStatusPopUp(0, test.testName, test.testNameID, 'Active')}>
                                                                    {test.status == 1 ? <i className='fa fa-check'>&nbsp;</i> : <i className='fa fa-times'>
                                                                        &nbsp;</i>} Active
                                                                </button> : ""
                                                        }
                                                        {
                                                            getStatusList != undefined && getStatusList.length != 0 ?
                                                                <button title='Status' style={{ float: 'right' }}
                                                                    className={test.status == 1 ? "btn btn-success btn-xs" : "btn btn-danger btn-xs"}
                                                                    onClick={event => ViewTestNameStatusPopUp(0, test.testName, test.testNameID, 'Active')}>
                                                                    {test.status == 1 ? <i className='fa fa-check'>&nbsp;</i> : <i className='fa fa-times'>
                                                                        &nbsp;</i>}
                                                                    {test.status == 1 ? "Active" : 'Inactive'}
                                                                </button> : ""
                                                        }
                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer"
                                                            style={{ marginTop: '2%' }} id="editabledatatable">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: '5%' }}>#</th>
                                                                    <th style={{ width: '8%' }}>Created On<span className='text-danger'>*</span></th>
                                                                    <th style={{ width: '30%' }}>Group<span className='text-danger'>*</span></th>
                                                                    {/* <th style={{ width: '30%' }}>Strike Off Details<span className='text-danger'>*</span></th> */}
                                                                    <th style={{ width: '8%' }}>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    inputFields != undefined &&
                                                                    inputFields[testindex].parentFields != undefined
                                                                    && inputFields[testindex].parentFields.map((parent, parentindex) => (
                                                                        <>
                                                                            <tr>
                                                                                <td> <i className={
                                                                                    parent.IsExpand ?
                                                                                        'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                } onClick={event => handleExpandRow(testindex, parentindex, event)}></i>&nbsp;{parentindex + 1}</td>
                                                                                <td>
                                                                                    <span className="input-icon icon-right"
                                                                                        style={
                                                                                            {
                                                                                                border: (IsSaveFailed && (parent.CreatedOn === null || parent.CreatedOn === undefined || parent.CreatedOn === "")) || (getUnitAreaSubmitted && getActionTestNameID == test.testNameID &&
                                                                                                    (parent.CreatedOn === null || parent.CreatedOn === undefined || parent.CreatedOn === "")) ? '1px solid red' : ''
                                                                                            }
                                                                                        }>
                                                                                        <DatePicker className="form-control" name={"CreatedOn_" + parentindex}
                                                                                            id={"CreatedOn_" + parentindex}
                                                                                            selected={
                                                                                                parent.CreatedOn
                                                                                            }
                                                                                            onChange={
                                                                                                event => handleChangeDate(event, testindex, parentindex, "CreatedOn")
                                                                                            }
                                                                                            dateFormat="dd/MM/yyyy"
                                                                                            peekNextMonth
                                                                                            dropdownMode="scroll"
                                                                                            minDate={parentindex == 0 ? '' : inputFields[testindex].parentFields[parentindex - 1].CreatedOn}
                                                                                            maxDate={parentindex != inputFields[testindex].parentFields.length - 1 ? inputFields[testindex].parentFields[parentindex + 1].CreatedOn : ''}
                                                                                            autoComplete="off"
                                                                                            customInput={<CustomInput />}
                                                                                            isClearable={parent.CreatedOn === null ? false : true}
                                                                                        />
                                                                                    </span>
                                                                                </td>
                                                                                <td>
                                                                                    <input type="text" className='form-control' id="Group"
                                                                                        name="Group" autoComplete="off" value={parent.Group}
                                                                                        maxLength="100" placeholder='Enter Group'
                                                                                        style={{ border: (IsSaveFailed && !parent.Group) || (getUnitAreaSubmitted && getActionTestNameID == test.testNameID && !parent.Group) ? '1px solid red' : '' }}
                                                                                        onChange={
                                                                                            event => handleParentTextBoxChange(event, testindex, parentindex, "Group")
                                                                                        } />
                                                                                </td>
                                                                                {/* <td>
                                                                                    <div className="row">
                                                                                        <div className='col-md-8'>
                                                                                            <Reactselect className="basic-single"
                                                                                                name={"FabricDetails" + parentindex}
                                                                                                id={"FabricDetails" + parentindex}
                                                                                                isDisabled={false}
                                                                                                isLoading={false}
                                                                                                isClearable={true}
                                                                                                isSearchable={true}
                                                                                                isMulti
                                                                                                menuPosition="fixed"
                                                                                                styles={(IsSaveFailed && parent.FabricDetailsForEdit == "") || ((getUnitAreaSubmitted) && getActionTestNameID == test.testNameID && parent.FabricDetailsForEdit == "") ? styles : styles1}
                                                                                                onChange={event => handleChangeParentSelectField(event, testindex, parentindex, "FabricDetails")}
                                                                                                options={getStrikeoffDpData}
                                                                                                value={parent.FabricDetailsForEdit}
                                                                                            >
                                                                                            </Reactselect>
                                                                                        </div>
                                                                                        <div className='col-md-4'>
                                                                                            <button title='Copy' className="btn btn-warning btn-xs" onClick={event => CopyFabricIndex(event, testindex, parentindex)}
                                                                                                disabled={parent.FabricDetailsForEdit == "" ? true : false}>
                                                                                                <i className='fa fa-copy'></i>
                                                                                                {getParentFabricCopyIndex.index == parentindex &&
                                                                                                    parent.FabricDetailsForEdit != "" && <span><b>&nbsp;Copied!</b></span>}
                                                                                            </button>&nbsp;
                                                                                            <button title='Paste' className="btn fafapaste btn-xs"
                                                                                                disabled={getParentFabricCopyIndex.index == null ? true : false}
                                                                                                onClick={event => PasteFabricIndex(event, testindex, parentindex)}>
                                                                                                <i className='fa fa-paste'></i>
                                                                                            </button>&nbsp;
                                                                                        </div>
                                                                                    </div>
                                                                                </td> */}
                                                                                <td>
                                                                                    {inputFields[testindex].parentFields.length === (parentindex + 1) && <span title='Add' className="btn btn-success btn-xs"
                                                                                        onClick={event => AddUnitAreaRow(testindex, parentindex, test.testNameID)}>
                                                                                        <i className='fa fa-plus'></i>
                                                                                    </span>}&nbsp;

                                                                                    {inputFields[testindex].parentFields.length !== 1 &&
                                                                                        <span title='Delete' className="btn btn-danger btn-xs" onClick={event => RemoveUnitAreaRow(testindex, parentindex)}>
                                                                                            <i className='fa fa-trash'></i>
                                                                                        </span>}&nbsp;
                                                                                    <span title='Activity' className="btn fafalist btn-xs">
                                                                                        <i className='fa fa-list'></i>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                            {
                                                                                parent.IsExpand ? <tr>
                                                                                    <td colSpan={5}>
                                                                                        <table style={{ marginTop: '0.5%' }} className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th style={{ width: '30%' }}>Strike Off Details<span className='text-danger'>*</span></th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                <td>
                                                                                                    <div className="row">
                                                                                                        <div className='col-md-11'>
                                                                                                            <Reactselect className="basic-single"
                                                                                                                name={"FabricDetails" + parentindex}
                                                                                                                id={"FabricDetails" + parentindex}
                                                                                                                isDisabled={false}
                                                                                                                isLoading={false}
                                                                                                                isClearable={true}
                                                                                                                isSearchable={true}
                                                                                                                isMulti
                                                                                                                menuPosition="fixed"
                                                                                                                styles={(IsSaveFailed && parent.FabricDetailsForEdit == "") || ((getUnitAreaSubmitted) && getActionTestNameID == test.testNameID && parent.FabricDetailsForEdit == "") ? styles : styles1}
                                                                                                                onChange={event => handleChangeParentSelectField(event, testindex, parentindex, "FabricDetails")}
                                                                                                                options={getStrikeoffDpData}
                                                                                                                value={parent.FabricDetailsForEdit}
                                                                                                            >
                                                                                                            </Reactselect>
                                                                                                        </div>
                                                                                                        <div className='col-md-1'>
                                                                                                            <button title='Copy' className="btn btn-warning btn-xs" style={{ marginTop: "5px" }} onClick={event => CopyFabricIndex(event, testindex, parentindex)}
                                                                                                                disabled={parent.FabricDetailsForEdit == "" ? true : false}>
                                                                                                                <i className='fa fa-copy'></i>
                                                                                                                {getParentFabricCopyIndex.index == parentindex &&
                                                                                                                    parent.FabricDetailsForEdit != "" && <span><b>&nbsp;Copied!</b></span>}
                                                                                                            </button>&nbsp;
                                                                                                            <button title='Paste' className="btn fafapaste btn-xs" style={{ marginTop: "5px" }}
                                                                                                                disabled={getParentFabricCopyIndex.index == null ? true : false}
                                                                                                                onClick={event => PasteFabricIndex(event, testindex, parentindex)}>
                                                                                                                <i className='fa fa-paste'></i>
                                                                                                            </button>&nbsp;
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tbody>
                                                                                        </table>
                                                                                        <table style={{ marginTop: '0.5%' }} className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th rowSpan={2} style={{ width: '10%' }}>Created on</th>
                                                                                                    <th rowSpan={2} style={{ width: '10%' }}>Effect From Date<span className='text-danger'>*</span></th>
                                                                                                    <th rowSpan={2} style={{ width: '10%' }}>
                                                                                                        <i className='fa fa-plus text-success' onClick={event => ViewStandardCreatePopUp(test.testNameID)}></i>&nbsp;&nbsp;Standard
                                                                                                        <span className='text-danger'>*</span></th>
                                                                                                    <th rowSpan={2} style={{ width: '10%' }}>Gender<span className='text-danger'>*</span></th>
                                                                                                    <th>
                                                                                                        Color Details
                                                                                                    </th>
                                                                                                    <th rowSpan={2} style={{ width: '8%' }}>Action</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {
                                                                                                    inputFields[testindex].parentFields[parentindex].StandardResultRequirement.map((standard, standardindex) => (
                                                                                                        <>
                                                                                                            <tr>
                                                                                                                <td style={{ verticalAlign: 'middle' }}>
                                                                                                                    <span className="input-icon icon-right"
                                                                                                                        style={
                                                                                                                            {
                                                                                                                                border: (getUnitAreaSubmitted || getUnitAreaChildSubmitted) && getActionTestNameID == test.testNameID &&
                                                                                                                                    (standard.CreatedOn === null || standard.CreatedOn === undefined || standard.CreatedOn === "") ? '1px solid red' : ''
                                                                                                                            }
                                                                                                                        }>
                                                                                                                        <DatePicker className="form-control" name={"StandardCreatedOn_" + standardindex}
                                                                                                                            id={"StandardCreatedOn_" + standardindex}
                                                                                                                            selected={
                                                                                                                                standard.CreatedOn
                                                                                                                            }
                                                                                                                            readOnly
                                                                                                                            onChange={
                                                                                                                                event => handleChangeStandardDate(event, testindex, parentindex, standardindex, "StandardCreatedOn")
                                                                                                                            }
                                                                                                                            dateFormat="dd/MM/yyyy"
                                                                                                                            peekNextMonth
                                                                                                                            dropdownMode="scroll"
                                                                                                                            autoComplete="off"
                                                                                                                            customInput={<CustomInput />}
                                                                                                                        // isClearable={standard.CreatedOn === null ? false : true}
                                                                                                                        />
                                                                                                                    </span>
                                                                                                                </td>
                                                                                                                <td style={{ verticalAlign: 'middle' }}>
                                                                                                                    <span className="input-icon icon-right"
                                                                                                                        style={
                                                                                                                            {
                                                                                                                                border: (IsSaveFailed && (standard.EffectFromDate === null || standard.EffectFromDate === undefined || standard.EffectFromDate === "")) || ((getUnitAreaSubmitted || getUnitAreaChildSubmitted) && getActionTestNameID == test.testNameID &&
                                                                                                                                    (standard.EffectFromDate === null || standard.EffectFromDate === undefined || standard.EffectFromDate === "")) ? '1px solid red' : ''
                                                                                                                            }
                                                                                                                        }>
                                                                                                                        <DatePicker className="form-control" name={"StandardEffectFromDate_" + standardindex}
                                                                                                                            id={"StandardEffectFromDate_" + standardindex}
                                                                                                                            selected={
                                                                                                                                standard.EffectFromDate
                                                                                                                            }
                                                                                                                            onChange={
                                                                                                                                event => handleChangeStandardDate(event, testindex, parentindex, standardindex, "StandardEffectFromDate")
                                                                                                                            }
                                                                                                                            minDate={new Date()}
                                                                                                                            dateFormat="dd/MM/yyyy"
                                                                                                                            peekNextMonth
                                                                                                                            dropdownMode="scroll"
                                                                                                                            autoComplete="off"
                                                                                                                            customInput={<CustomInputEffectfromDate />}
                                                                                                                            isClearable={standard.EffectFromDate === null ? false : true}
                                                                                                                        /></span>
                                                                                                                </td >
                                                                                                                <td style={{ verticalAlign: 'middle' }} title={standard.StandardIDText}>
                                                                                                                    <Reactselect className="basic-single"
                                                                                                                        name={"Standard_" + standardindex}
                                                                                                                        id={"Standard_" + standardindex}
                                                                                                                        isDisabled={false}
                                                                                                                        isLoading={false}
                                                                                                                        isClearable={true}
                                                                                                                        isSearchable={true}
                                                                                                                        isMulti
                                                                                                                        styles={(IsSaveFailed && !standard.StandardIDForEdit) || ((getUnitAreaSubmitted || getUnitAreaChildSubmitted) && getActionTestNameID == test.testNameID
                                                                                                                            && !standard.StandardIDForEdit) ? styles : styles1}
                                                                                                                        menuPosition="fixed"
                                                                                                                        onChange={event => handleChangeStandardSelectField(event, testindex, parentindex, standardindex, "Standard")}
                                                                                                                        options={StandardOptions.filter((data => {
                                                                                                                            return data.testNameID == test.testNameID;
                                                                                                                        }))}
                                                                                                                        value={standard.StandardIDForEdit}
                                                                                                                    >
                                                                                                                    </Reactselect>
                                                                                                                </td>
                                                                                                                <td style={{ verticalAlign: 'middle' }} title={standard.GenderIDText}>
                                                                                                                    <Reactselect className="basic-single"
                                                                                                                        name={"StandardGender_" + standardindex}
                                                                                                                        id={"StandardGender_" + standardindex}
                                                                                                                        isDisabled={false}
                                                                                                                        isLoading={false}
                                                                                                                        isClearable={true}
                                                                                                                        isSearchable={true}
                                                                                                                        isMulti
                                                                                                                        styles={(IsSaveFailed && standard.GenderIDForEdit == "") || ((getUnitAreaSubmitted || getUnitAreaChildSubmitted) && getActionTestNameID == test.testNameID
                                                                                                                            && standard.GenderIDForEdit == "") ? styles : styles1}
                                                                                                                        menuPosition="fixed"
                                                                                                                        onChange={event => handleChangeStandardSelectField(event, testindex, parentindex, standardindex, "Gender")}
                                                                                                                        options={GenderOptions}
                                                                                                                        value={standard.GenderIDForEdit}
                                                                                                                    >
                                                                                                                    </Reactselect>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <table style={{ marginTop: '0.5%' }} className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                                                        <thead>
                                                                                                                            <tr>
                                                                                                                                <th rowSpan={2} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                                                                                                                    <b><i className='fa fa-plus text-success'
                                                                                                                                        onClick={event => ViewColorDepthCreatePopUp()} ></i></b>&nbsp;  Color Depth
                                                                                                                                    <span className='text-danger'>*</span>
                                                                                                                                </th>
                                                                                                                                <th rowSpan={2} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                                                                                                                    <b><i className='fa fa-plus text-success'
                                                                                                                                        onClick={event => ViewTestFieldCreatePopUp()} ></i></b>&nbsp;  Field(s)
                                                                                                                                    <span className='text-danger'>*</span>
                                                                                                                                </th>
                                                                                                                                <th rowSpan={2} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                                                                                                                    Res Req. Type
                                                                                                                                    <span className='text-danger'>*</span>
                                                                                                                                </th>
                                                                                                                                <th colSpan={2} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                                                                                                                    Res Req
                                                                                                                                    <span className='text-danger'>*</span>
                                                                                                                                </th>
                                                                                                                                <th rowSpan={2} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                                                                                                                    Action
                                                                                                                                </th>
                                                                                                                            </tr>
                                                                                                                            <tr>
                                                                                                                                {/* <th style={{ textAlign: 'center', verticalAlign: 'middle' }}>Min
                                                                                                                                    <span className='text-danger'>*</span></th>
                                                                                                                                <th style={{ textAlign: 'center', verticalAlign: 'middle' }}>Max
                                                                                                                                    <span className='text-danger'>*</span></th> */}
                                                                                                                            </tr>
                                                                                                                        </thead>
                                                                                                                        <tbody>
                                                                                                                            {
                                                                                                                                inputFields[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid.map((internal, internalindex) => (
                                                                                                                                    <>
                                                                                                                                        <tr>
                                                                                                                                            <td style={{ width: '10%' }} title={internal.ColorDepthText}>
                                                                                                                                                <Reactselect className="basic-single"
                                                                                                                                                    name={"StandardColorDepth_" + internalindex}
                                                                                                                                                    id={"StandardColorDepth_" + internalindex}
                                                                                                                                                    isDisabled={false}
                                                                                                                                                    isLoading={false}
                                                                                                                                                    isClearable={true}
                                                                                                                                                    isSearchable={true}
                                                                                                                                                    isMulti
                                                                                                                                                    menuPosition="fixed"
                                                                                                                                                    styles={(IsSaveFailed && internal.ColorDepthForEdit == "") || ((getUnitAreaSubmitted || getUnitAreaChildSubmitted || getIsInternalGridAdded)
                                                                                                                                                        && getActionTestNameID == test.testNameID &&
                                                                                                                                                        internal.ColorDepthForEdit == "") ? styles : styles1}
                                                                                                                                                    onChange={event => handleChangeInternalSelectField(event, testindex, parentindex, standardindex, internalindex, "ColorDepth")}
                                                                                                                                                    options={ColorDepthOptions}
                                                                                                                                                    value={internal.ColorDepthForEdit}
                                                                                                                                                >
                                                                                                                                                </Reactselect>
                                                                                                                                            </td>
                                                                                                                                            <td style={{ width: '10%' }} title={internal.TestFieldText}>
                                                                                                                                                <Reactselect className="basic-single"
                                                                                                                                                    name={"StandardFields_" + internalindex}
                                                                                                                                                    id={"StandardFields_" + internalindex}
                                                                                                                                                    isDisabled={false}
                                                                                                                                                    isLoading={false}
                                                                                                                                                    isClearable={true}
                                                                                                                                                    isSearchable={true}
                                                                                                                                                    isMulti
                                                                                                                                                    menuPosition="fixed"
                                                                                                                                                    styles={(IsSaveFailed && internal.FieldForEdit == "") || ((getUnitAreaSubmitted || getUnitAreaChildSubmitted || getIsInternalGridAdded) && getActionTestNameID == test.testNameID
                                                                                                                                                        && internal.FieldForEdit == "") ? styles : styles1}
                                                                                                                                                    onChange={event => handleChangeInternalSelectField(event, testindex, parentindex, standardindex, internalindex, "Field")}
                                                                                                                                                    options={FieldOptions.filter(option => option.id === test.testNameID)}
                                                                                                                                                    value={internal.FieldForEdit}
                                                                                                                                                >
                                                                                                                                                </Reactselect>
                                                                                                                                            </td>
                                                                                                                                            <td style={{ width: '10%' }} title={internal.ResultTypeText} colSpan={(internal.ResultType == 4 || internal.ResultType == 3) ? 2 : 1}>
                                                                                                                                                <Reactselect className="basic-single"
                                                                                                                                                    name={"StandardResultType_" + internalindex}
                                                                                                                                                    id={"StandardResultType_" + internalindex}
                                                                                                                                                    isDisabled={false}
                                                                                                                                                    isLoading={false}
                                                                                                                                                    isOptionDisabled={(option) => {
                                                                                                                                                        // if (test.testName == "DURABILITY TEST") {
                                                                                                                                                        //     return option.id != 3;
                                                                                                                                                        // }
                                                                                                                                                        return option.id != 3;
                                                                                                                                                    }}
                                                                                                                                                    isClearable={true}
                                                                                                                                                    isSearchable={true}
                                                                                                                                                    menuPosition="fixed"
                                                                                                                                                    onChange={event => handleChangeInternalSelectField(event, testindex, parentindex, standardindex, internalindex, "ResultType")}
                                                                                                                                                    options={ResultReqTypeOptions}
                                                                                                                                                    value={internal.ResultTypeForEdit}
                                                                                                                                                    styles={(IsSaveFailed && !internal.ResultTypeForEdit) || ((getUnitAreaSubmitted || getUnitAreaChildSubmitted || getIsInternalGridAdded) &&
                                                                                                                                                        getActionTestNameID == test.testNameID && !internal.ResultTypeForEdit) ? styles : styles1}
                                                                                                                                                >
                                                                                                                                                </Reactselect>
                                                                                                                                            </td>
                                                                                                                                            <td style={{ width: '8%' }}>
                                                                                                                                                <div style={{ display: 'table', width: '100%' }}>
                                                                                                                                                    {internal.ResultType != 4 && internal.ResultType != 3 && <input type="number" className='form-control' id="StandardMinResult"
                                                                                                                                                        name="StandardMinResult" autoComplete="off" value={internal.MinResult}
                                                                                                                                                        style={{
                                                                                                                                                            border: (IsSaveFailed && !internal.MinResult) || ((getMinResultValidation && testindex == getMinMaxTestIndex && parentindex == getMinMaxFabricIndex && standardindex == getMinMaxStandardIndex &&
                                                                                                                                                                internalindex == getMinMaxInternalIndex) || ((getUnitAreaSubmitted || getUnitAreaChildSubmitted || getIsInternalGridAdded) &&
                                                                                                                                                                    getActionTestNameID == test.testNameID && !internal.MinResult)) ? '1px solid red' : ''
                                                                                                                                                        }}
                                                                                                                                                        maxLength="5"
                                                                                                                                                        onBlur={
                                                                                                                                                            event => handleMinMaxValidation(event, testindex, parentindex, standardindex, internalindex, "MinResult")
                                                                                                                                                        } onChange={
                                                                                                                                                            event => handleInternalTextBoxChange(event, testindex, parentindex, standardindex, internalindex, "MinResult")
                                                                                                                                                        } />}
                                                                                                                                                    {internal.ResultType == 4 &&
                                                                                                                                                        <input type='text' className='form-control' value={internal.MinResult} style={{
                                                                                                                                                            border: (IsSaveFailed && internal.MinResult == "") || (getMinResultValidation && testindex == getMinMaxTestIndex && parentindex == getMinMaxFabricIndex && standardindex == getMinMaxStandardIndex &&
                                                                                                                                                                internalindex == getMinMaxInternalIndex) || ((getUnitAreaSubmitted || getUnitAreaChildSubmitted || getIsInternalGridAdded) &&
                                                                                                                                                                    getActionTestNameID == test.testNameID && !internal.MinResult) ? '1px solid red' : ''
                                                                                                                                                        }} id={"MinResult"} placeholder="Enter Min Result" onChange={event => handleMinMaxChange(event, testindex, parentindex, standardindex, internalindex, "MinResult")}
                                                                                                                                                            maxLength={100} />}
                                                                                                                                                    {internal.ResultType == 3 &&
                                                                                                                                                        <Reactselect className="basic-single"
                                                                                                                                                            name={"ddlGrade"}
                                                                                                                                                            id={"ddlGrade"}
                                                                                                                                                            isDisabled={false}
                                                                                                                                                            isLoading={false}
                                                                                                                                                            isClearable={true}
                                                                                                                                                            isSearchable={true}
                                                                                                                                                            menuPosition="fixed"
                                                                                                                                                            onChange={event => handlleGradeChange(event, testindex, parentindex, standardindex, internalindex)}
                                                                                                                                                            options={getResultTypeGradeOptions}
                                                                                                                                                            value={internal.MinResultForEdit}
                                                                                                                                                            style={{
                                                                                                                                                                border: (IsSaveFailed && !internal.MinResultForEdit) || ((getMinResultValidation && testindex == getMinMaxTestIndex && parentindex == getMinMaxFabricIndex && standardindex == getMinMaxStandardIndex &&
                                                                                                                                                                    internalindex == getMinMaxInternalIndex) || ((getUnitAreaSubmitted || getUnitAreaChildSubmitted || getIsInternalGridAdded) &&
                                                                                                                                                                        getActionTestNameID == test.testNameID && !internal.MinResultForEdit)) ? styles : styles1
                                                                                                                                                            }}  >
                                                                                                                                                        </Reactselect>
                                                                                                                                                    }
                                                                                                                                                    <span style={{ display: 'table-cell', width: '1px', whiteSpace: 'nowrap' }}>
                                                                                                                                                        {internal.ResultType == 2 &&
                                                                                                                                                            <p >&nbsp;%</p>
                                                                                                                                                        }</span>
                                                                                                                                                </div>
                                                                                                                                            </td>
                                                                                                                                            {internal.ResultType != 4 && internal.ResultType != 3 && <td style={{ width: '8%' }}>
                                                                                                                                                <div style={{ display: 'table', width: '100%' }}>
                                                                                                                                                    {internal.ResultType != 4 && <input type="number" className='form-control' id="StandardMaxResult"
                                                                                                                                                        name="StandardMaxResult" autoComplete="off" value={internal.MaxResult}
                                                                                                                                                        style={{
                                                                                                                                                            border: (IsSaveFailed && !internal.MaxResult) || ((getMaxResultValidation && testindex == getMinMaxTestIndex && parentindex == getMinMaxFabricIndex && standardindex == getMinMaxStandardIndex &&
                                                                                                                                                                internalindex == getMinMaxInternalIndex) || ((getUnitAreaSubmitted || getUnitAreaChildSubmitted || getIsInternalGridAdded) &&
                                                                                                                                                                    getActionTestNameID == test.testNameID && !internal.MaxResult)) ? '1px solid red' : ''
                                                                                                                                                        }}
                                                                                                                                                        maxLength="5"
                                                                                                                                                        onBlur={
                                                                                                                                                            event => handleMinMaxValidation(event, testindex, parentindex, standardindex, internalindex, "MaxResult")
                                                                                                                                                        }
                                                                                                                                                        onChange={
                                                                                                                                                            event => handleInternalTextBoxChange(event, testindex, parentindex, standardindex, internalindex, "MaxResult")
                                                                                                                                                        } />}
                                                                                                                                                    {internal.ResultType == 4 &&
                                                                                                                                                        <input type='text' className='form-control' value={internal.MaxResult}
                                                                                                                                                            style={{
                                                                                                                                                                border: (IsSaveFailed && internal.MaxResult == "") || (getMaxResultValidation && testindex == getMinMaxTestIndex && parentindex == getMinMaxFabricIndex && standardindex == getMinMaxStandardIndex &&
                                                                                                                                                                    internalindex == getMinMaxInternalIndex) || ((getUnitAreaSubmitted || getUnitAreaChildSubmitted || getIsInternalGridAdded) &&
                                                                                                                                                                        getActionTestNameID == test.testNameID && !internal.MaxResult) ? '1px solid red' : ''
                                                                                                                                                            }}
                                                                                                                                                            id={"MaxResult"} placeholder="Enter Max Result" onChange={event => handleMinMaxChange(event, testindex, parentindex, standardindex, internalindex, "MaxResult")}
                                                                                                                                                            maxLength={100} />}
                                                                                                                                                    <span style={{ display: 'table-cell', width: '1px', whiteSpace: 'nowrap' }}>
                                                                                                                                                        {internal.ResultType == 2 &&
                                                                                                                                                            <p >&nbsp;%</p>
                                                                                                                                                        }</span>
                                                                                                                                                </div>
                                                                                                                                            </td>
                                                                                                                                            }
                                                                                                                                            <td style={{ width: '8%' }}>
                                                                                                                                                {inputFields[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid.length === (internalindex + 1)
                                                                                                                                                    && <span title='Add' className="btn btn-success btn-xs"
                                                                                                                                                        onClick={
                                                                                                                                                            event => AddInternalGrid(event, testindex, parentindex, standardindex, internalindex, test.testNameID)
                                                                                                                                                        } >

                                                                                                                                                        <i className='fa fa-plus'></i>
                                                                                                                                                    </span>}&nbsp;
                                                                                                                                                {inputFields[testindex].parentFields[parentindex].StandardResultRequirement[standardindex].InternalGrid.length !== 1
                                                                                                                                                    && <span title='Delete' className="btn btn-danger btn-xs" onClick={
                                                                                                                                                        event => RemoveInternalGrid(event, testindex, parentindex, standardindex, internalindex)
                                                                                                                                                    } >
                                                                                                                                                        <i className='fa fa-trash'></i>
                                                                                                                                                    </span>}&nbsp;
                                                                                                                                            </td>
                                                                                                                                        </tr>
                                                                                                                                    </>
                                                                                                                                ))
                                                                                                                            }
                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </td>
                                                                                                                <td style={{ verticalAlign: 'middle' }}>
                                                                                                                    {inputFields[testindex].parentFields[parentindex].StandardResultRequirement.length === (standardindex + 1) && <span title='Add'
                                                                                                                        className="btn btn-success btn-xs" onClick={event => AddUnitAreaChildRow(testindex, parentindex, standardindex, test.testNameID)}>
                                                                                                                        <i className='fa fa-plus'></i>
                                                                                                                    </span>}&nbsp;
                                                                                                                    {inputFields[testindex].parentFields[parentindex].StandardResultRequirement.length !== 1 && <span title='Delete' className="btn btn-danger btn-xs"
                                                                                                                        onClick={event => RemoveUnitAreaChildRow(testindex, parentindex, standardindex)}>
                                                                                                                        <i className='fa fa-trash'></i>
                                                                                                                    </span>}&nbsp;
                                                                                                                    {/* <span title='Activity' className="btn fafalist btn-xs">
                                                                                                                        <i className='fa fa-list'></i>
                                                                                                                    </span> */}
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </>
                                                                                                    ))
                                                                                                }
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr> : ""
                                                                            }
                                                                        </>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Fragment>
                                {
                                    getTestNameStatusPopUpViewMode && getTestNameStatusPopUpViewMode.isShow &&
                                    <Modal dialogClassName="CreatePopup" show={getTestNameStatusPopUpViewMode.isShow} size="sm" dragable backdrop="static" keyboard={false}
                                    >
                                        <Modal.Header closeButton onHide={() => HideTestNameStatusPopUp()}>
                                            <Modal.Title>
                                                Status of {getTestNameStatusIndex.TestName} &nbsp;
                                                (Current Status: {getStatusList != undefined && getStatusList.length > 0 && getStatusList[0].statusText == "Active" && <span className='text-success'><b>Active</b></span>}
                                                {getStatusList != undefined && getStatusList.length > 0 && getStatusList[0].statusText == "Inactive" && <span className='text-danger'><b>Inactive</b></span>}
                                                {getStatusList != undefined && getStatusList.length == 0 && <span className='text-success'><b>Active</b></span>})
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            {((BrandID == 0 || BuyerID == 0)) &&
                                                <div className="card" style={{ marginTop: '1%' }}>
                                                    <label className="alert alert-info comment-report-note"><b>Note : </b>
                                                        Select Buyer and Brand to activate / deactivate {getTestNameStatusIndex.TestName}.</label>
                                                </div>}
                                            <div className="row" style={{ marginTop: '0.5%' }}>
                                                <div className="col-sm-3 col-lg-3" >
                                                    <div className="form-group">
                                                        <label>Status<span className="text-danger">*</span></label>
                                                        <Reactselect className="basic-single"
                                                            name="TestNameStatus"
                                                            id="TestNameStatus"
                                                            // isDisabled={
                                                            //     ((BrandID > 0 && BrandID.length != 0 && BrandID != undefined && BrandID != null)
                                                            //         && (BuyerID > 0 && BuyerID.length != 0 && BuyerID != undefined && BuyerID != null)) ? false : true}
                                                            isDisabled={true}
                                                            isLoading={false}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            // styles={(getIsTestNameStatusSubmitted) &&
                                                            //     (!statusInputFields.TestStatusForEdit) ? styles : styles1}
                                                            menuPosition="fixed"
                                                            onChange={event => handleTestStatus(event)}
                                                            options={getStatusOptions}
                                                            // value={statusInputFields.TestStatusForEdit}
                                                            value={getStatusOptions.filter(function (option) {
                                                                return option.value === (getStatusList != undefined && getStatusList.length > 0 && getStatusList[0].statusText == "Inactive" ? 1 : 0);
                                                            })}>
                                                        </Reactselect>
                                                    </div>
                                                </div>
                                                <div className="col-sm-5 col-lg-5">
                                                    <label>Comments<span className="text-danger">*</span></label>
                                                    <textarea className='form-control' name="comments" id="comments" rows="3"
                                                        placeholder="Enter Commenst"
                                                        disabled={
                                                            ((BrandID > 0 && BuyerID > 0)) ? false : true}
                                                        style={{ border: getIsTestNameStatusSubmitted && !statusInputFields.Comments ? '1px solid red' : '' }}
                                                        value={statusInputFields.Comments} autoComplete="off" maxLength="100"
                                                        onChange={event => handleTestNameStatusComments(event, "Comments")}></textarea>
                                                </div>
                                                <div className="col-sm-4 col-lg-4">
                                                    <label>Effect From Date<span className="text-danger">*</span></label>
                                                    <input type="text" className='form-control' name="effectfromdate" id="effectfromdate"
                                                        placeholder="Enter Effect From Date"
                                                        disabled={
                                                            ((BrandID > 0 && BuyerID > 0)) ? false : true}
                                                        style={{ border: getIsTestNameStatusSubmitted && !statusInputFields.EffectFromDate ? '1px solid red' : '' }}
                                                        value={statusInputFields.EffectFromDate} autoComplete="off" maxLength="100"
                                                        onChange={event => handleTestNameStatusComments(event, "EffectFromDate")} />
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: '1%' }}>
                                                <div className="col-md-12" style={{ overflowY: 'scroll', maxHeight: '400px' }}>
                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                                        <thead>
                                                            <tr>
                                                                <th>Status</th>
                                                                <th>Comments</th>
                                                                <th>Effect From Date</th>
                                                                <th>Created By On Created Date</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                getStatusList.map((status, statusindex) => (
                                                                    <>
                                                                        <tr>
                                                                            <td>{
                                                                                status.statusText == "Active" ?
                                                                                    <span className='text-success'><b>{status.statusText}</b></span> :
                                                                                    <span className='text-danger'><b>{status.statusText}</b></span>}</td>
                                                                            <td>{status.comment}</td>
                                                                            <td>{status.effectFromDate}</td>
                                                                            <td>{status.createdBy + " On " + status.createdDate}</td>
                                                                        </tr>
                                                                    </>
                                                                ))
                                                            }
                                                            {
                                                                (getStatusList.length == 0 || getStatusList == [] || getStatusList == null) ?
                                                                    <tr>
                                                                        <td className='text-danger text-center' colSpan={4}>
                                                                            No Records Found
                                                                        </td>
                                                                    </tr> : ""
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <button type="button" class="btn btn-success" onClick={() => SaveTestNameStatus(getStatusList != undefined && getStatusList.length > 0 && getStatusList[0].statusText == "Inactive" ? 1 : 0)}><i className='fa fa-save'></i>
                                                &nbsp;Save</button>
                                            <button type="button" class="btn btn-danger" onClick={() => HideTestNameStatusPopUp()}><i className='fa fa-times'></i>
                                                &nbsp;Close</button>
                                        </Modal.Footer>
                                    </Modal>
                                }
                                {
                                    getAddColorDepthPopUpViewMode && getAddColorDepthPopUpViewMode.isShow &&
                                    <Modal show={getAddColorDepthPopUpViewMode.isShow} size="md" dragable backdrop="static" keyboard={false}
                                    >
                                        <Modal.Header closeButton onHide={() => HideColorDepthCreatePopUp()}>
                                            <Modal.Title>
                                                Add Color Depth
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="row">
                                                <div className="col-sm-12 col-lg-12">
                                                    <ColorDepthPopUp props={props} ColorDepthPopUpCallback={AddColorDepthCallback}
                                                        ColorDepthOptions={ColorDepthOptions}></ColorDepthPopUp>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                }
                                {
                                    getAddTestFieldPopUpViewMode && getAddTestFieldPopUpViewMode.isShow &&
                                    <Modal show={getAddTestFieldPopUpViewMode.isShow} size="md" dragable backdrop="static" keyboard={false}
                                    >
                                        <Modal.Header closeButton onHide={() => HideTestFieldCreatePopUp()}>
                                            <Modal.Title>
                                                Add Color Depth
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="row">
                                                <div className="col-sm-12 col-lg-12">
                                                    <TestFieldPopUp props={props} TestFieldOptions={FieldOptions}
                                                        TestFieldPopUpCallback={AddTestFieldCallback}></TestFieldPopUp>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                }
                                {
                                    getAddStandardPopUpViewMode && getAddStandardPopUpViewMode.isShow &&
                                    <Modal show={getAddStandardPopUpViewMode.isShow} size="md" dragable backdrop="static" keyboard={false}
                                    >
                                        <Modal.Header closeButton onHide={() => HideStandardCreatePopUp()}>
                                            <Modal.Title>
                                                Add Standard
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="row">
                                                <div className="col-sm-12 col-lg-12">
                                                    <TestStandardPopUp props={props} TestID={getStandardTestID} StandardOptions={StandardOptions}
                                                        TestStandardPopUpCallback={AddTestStandardCallback}></TestStandardPopUp>

                                                </div>
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                }
                            </Fragment>
                        </>
                    ))
                }

            </div >
        </>
    )
}
export default BrandStandardStrikeOff;