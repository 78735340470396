import React, { useState, useEffect, Fragment, useRef, useMemo } from 'react';
import ReactNotification from 'react-notifications-component';
import { useSelector } from "react-redux";
import Nodify from "../../../Common/ReactNotification";
import SearchRetain from "../../../Common/SearchRetain";
import TestFieldService from './../../../../services/Master/testfieldservice';

const TestFieldRegister = (props) => {
    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getExisting, setExisting] = useState([]);

    const EmptyInputFields = { TestFieldID: 0, TestField: '' };
    const [inputFields, setInputFields] = useState([EmptyInputFields]);
    const { user: currentUser } = useSelector((state) => state.auth);
    const values = [...inputFields];

    const handleInputChange = (index, event) => {
        // let inputText = '';
        // if (event.target.value !== '') {
        //     inputText = event.target.value;
        // }
        // values[index].TestField = inputText;
        // setInputFields(values);
        const values = [...inputFields];
        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }
        values[index].TestField = inputText;
        setInputFields(values);
    }

    const handleAddFields = index => {
        const values = [...inputFields];
        if (values[index].TestField.trim() !== '') {
            values.push(EmptyInputFields);
            setInputFields(values);
            setSubmitted(false);
        }
        else {
            Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
            setSubmitted(true);
        }
    }

    const handleRemoveFields = index => {
        const values = [...inputFields];
        values.splice(index, 1);
        setInputFields(values);
    }

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/TestFieldList');
        } else {
            window.location.reload();
        }
    }

    const InsertTestField = (e) => {
        setButtonLoader(true);
        e.preventDefault();
        if (inputFields.length === 1 && inputFields[0].TestField === '') {
            setButtonLoader(false);
            setSubmitted(true);
            ValidationPopup("Please fill atleast one Test Field Name.");
        }
        else {
            for (var i = 0; i < (inputFields.length); i++) {
                if (inputFields[i].TestField.trim() === '') {
                    Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
                    setSubmitted(true);
                    setButtonLoader(false);
                    return false;
                }
            }
            let Params = { Createdby: currentUser.employeeinformationID, TestFieldInfo: inputFields };
            TestFieldService.InsertTestField(Params).then(res => {
                var page = "Remove";
                SearchRetain(page);
                let Func = 'Add';
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    PageRedirect(Func);
                }
            });
        }
    }

    const PageRedirect = (Func) => {
        props.history.push({ pathname: "/TestFieldList", state: { message: Func } });
    }

    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
        return false;
    }

    const CheckDuplicate = (index, Feildname) => {
        const values = [...inputFields];
        let isExisting = !!getExisting.find(d => d.testField.trim().toLowerCase() === values[index].TestField.trim().toLowerCase())
        if (isExisting) {
            values[index].TestField = '';
            setInputFields(values);
            Nodify('Warning!', 'warning', "This Test Field is already exist.");
            return false;
        }
        for (var j = 0; j < (inputFields.length); j++) {
            if (j !== index) {
                if (values[j].TestField.trim().toLowerCase() === values[index].TestField.trim().toLowerCase()) {
                    values[index].TestField = '';
                    setInputFields(values);
                    Nodify('Warning!', 'warning', 'This Test Field is already exist.');
                    setSubmitted(true);
                    return false;
                }
            }
        }
    }

    useEffect(() => {
        TestFieldService.GetTestFieldList().then((res => {
            if (res.data) {
                setExisting(res.data.item1);
            }
        }))
    }, [])

    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <div className="widget flat radius-bordered">
                            <div className="widget-header heading_box_style">
                                <h3 className="heading_style_common">Add Test Field</h3>
                            </div>
                            <div className="widget-body">
                                <div id="registration-form">
                                    <form>
                                        {
                                            inputFields.map((inputField, index) => (
                                                <Fragment key={
                                                    `${inputField}~${index}`
                                                }>
                                                    <div className="row row_left10">
                                                        <div className="col-sm-5 divder_style">
                                                            <div className="form-group">
                                                                <label>Test Field Name<span className="text-danger">*</span>
                                                                </label>
                                                                <span className="input-icon icon-right">
                                                                    <input type='test' className='form-control' style={{ border: submitted && !inputField.TestField ? '1px solid red' : '' }} name="testitem"
                                                                        id="testitem" rows="10" placeholder="Enter Test Field" onChange={event => handleInputChange(index, event)}
                                                                        autoComplete="off" maxLength="100" value={inputField.TestField}
                                                                        onBlur={() => CheckDuplicate(index)} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3 paddingstatic">
                                                            {
                                                                inputFields.length === (index + 1) && (
                                                                    <div className="col-lg-1_own col-xs-12">

                                                                        <button type="button" className="btn btn-success yarn" title="Add Test Field" onClick={() => handleAddFields(index)}>
                                                                            <i className="fa fa-plus"></i>
                                                                        </button>
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                inputFields.length !== 1 && (
                                                                    <div className="col-lg-1_own col-xs-12">
                                                                        <button type="button" className="btn btn-danger yarn"
                                                                            title="Delete Test Field" onClick={() => handleRemoveFields(index)}>
                                                                            <i className="fa fa-trash-o"></i>
                                                                        </button>
                                                                    </div>
                                                                )}

                                                        </div>
                                                    </div>
                                                </Fragment>
                                            ))
                                        }
                                        <div className="col-sm-12">
                                            <div className="buttons-preview" align="right">
                                                <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                                    <i className="fa fa-arrow-circle-left"></i>
                                                    &nbsp;Back
                                                </span>
                                                <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                                    <i className="fa fa-times"></i>
                                                    &nbsp;Reset
                                                </span>
                                                <button type="button" className="btn btn-success" disabled={buttonLoader} onClick={InsertTestField}>
                                                    <i className="fa fa-check right"></i>&nbsp;
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default TestFieldRegister;