import {
    FABRICPART_LOADING,
    RETRIEVE_FABRICPART,
    DELETE_FABRICPART,
} from "../actions/types";

const initialState = {
    isLoadingFabricPart: true,
    FabricPartList: [],
};

const FabricPartReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case FABRICPART_LOADING:
            return {
                ...state,
                isLoadingFabricPart: payload
            };
        case RETRIEVE_FABRICPART:
            return { ...state, FabricPartList: payload };
        case DELETE_FABRICPART:
            const results = state.FabricPartList.filter(c => c.fabricPartID !== payload.fabricPartID);
            return {
                ...state,
                FabricPartList: results
            };

        default:
            return state;
    }
};
export default FabricPartReducer;