import axios from "axios";
import authHeader from "../auth-header";

class TestItemService {
    GetTestItemList() {
        return axios.get(window.$APIBaseURL + "Master/TestItemList", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    UpdateTestItem(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/UpdateTestItem',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertTestItem(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertTestItem',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
}
export default new TestItemService()