import {
    TASKTYPE_LOADING,
    RETRIEVE_TASKTYPE,
    TASKTYPE_DROPDOWN_LIST,
    TASKTYPE_DROPDOWN_MAP_LIST,
    DELETE_TASKTYPE,
} from "./types";

import TaskTypeService from "../services/Master/TaskTypeService";
import taskService from "../services/Master/TaskService";

const taskTypeLoading = (isStatus) => ({
    type: TASKTYPE_LOADING,
    payload: isStatus,
});

export const deleteTaskType = (taskTypeID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_TASKTYPE,
            payload: taskTypeID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const loadTaskTypeList = (Operation) => async (dispatch) => {
    try {
        dispatch(taskTypeLoading(true));
        const res = await taskService.getLoadTaskTypeList(Operation);
        if (Operation === 1)
        {
             dispatch({
            type: TASKTYPE_DROPDOWN_LIST,
            payload: res.data,
        });
        } else
        {
          dispatch({
            type: TASKTYPE_DROPDOWN_MAP_LIST,
            payload: res.data,
           });
        }
       
        dispatch(taskTypeLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(taskTypeLoading(false));
    }
};

export const retrieveTaskType = (TaskTypeId) => async (dispatch) => {
    try {
        dispatch(taskTypeLoading(true));
        const res = await TaskTypeService.getTaskTypeList(TaskTypeId);
        
        dispatch({
            type: RETRIEVE_TASKTYPE,
            payload: res.data,
        });
        dispatch(taskTypeLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(taskTypeLoading(false));
    }
};