import {
    GENDER_LOADING,
    RETRIEVE_GENDER,
    DELETE_GENDER,
} from "../actions/types";

const initialState = {
    isLoadingGender: true,
    genderList: [],
};

const genderReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GENDER_LOADING:
            return {
                ...state,
                isLoadingGender: payload
            };
        case RETRIEVE_GENDER:
            return { ...state, genderList: payload };
        case DELETE_GENDER:
            const results = state.genderList.filter(c => c.genderSizeID !== payload.genderSizeID);
            return {
                ...state,
                genderList: results
            };

        default:
            return state;
    }
};
export default genderReducer;