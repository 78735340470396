import React, { useEffect, useState, useMemo } from "react";
 import Pagination from "react-bootstrap/Pagination";
 //import Pagination from 'react-bootstrap/lib/Pagination';
 //import Pagination from 'react-bootstrap-table2-paginator';
//import Pagination from "bootstrap";

const PaginationComponent = ({
    total = 0,
    itemsPerPage = 10,
    currentPage = 1,
    onPageChange
}) => {
    const [totalPages, setTotalPages] = useState(0);
    const [fromEntry, setfromEntry] = useState("");
    const [toEntry, settoEntry] = useState("");
    const [totalEntry, settotalEntry] = useState("");


    useEffect(() => {
        if (parseInt(total) > 0 && parseInt(itemsPerPage) > 0) {
            setTotalPages(Math.ceil(total / itemsPerPage));
        }
        else {
            setTotalPages(0);
        }

        const pageData = JSON.parse(localStorage.getItem("Pagination"));
        if (pageData) {
            if (pageData.Page) {
                setTotalPages(pageData.CurrentPage);
                setTotalPages(Math.ceil(total / itemsPerPage));
            }
        }
    }, [total, itemsPerPage]);

    const paginationItems = useMemo(() => {
        const pages = [];

        setfromEntry(((currentPage - 1) * (parseInt(itemsPerPage))) + 1);
        let ToEntry = (currentPage - 1) * (parseInt(itemsPerPage)) + (parseInt(itemsPerPage));
        settoEntry(ToEntry > total ? total : ToEntry);
        settotalEntry(total);
        // for (let i = 1; i <= totalPages; i++) {
        //     pages.push(
        //         <Pagination.Item
        //             key={i}
        //             active={i === currentPage}
        //             onClick={() => onPageChange(i)}
        //             style={{ display: i <= 5 ? "" : i===currentPage?"": "none" }}
        //         >
        //             {i}
        //         </Pagination.Item>
        //     );
        // }
        let pageNumber = currentPage;
        if ((pageNumber === totalPages)&&totalPages!==1) {
            pageNumber = pageNumber - 4;
        }
        else if ((pageNumber + 1 === totalPages)&&totalPages!==2) {
            pageNumber = pageNumber - 3;
        }
         else if ((pageNumber + 2 === totalPages)&&totalPages!==3) {
            pageNumber = pageNumber - 2;
        }
         else if ((pageNumber + 3 === totalPages)&&totalPages!==4) {
            pageNumber = pageNumber - 1;
        }

        for (
            let i = pageNumber < 1 ? 1 : pageNumber;
            (i <= currentPage + 4) && (i <= totalPages);
            i++
        ) {
            pages.push(
                <Pagination.Item
                    key={i}
                    active={i === currentPage}
                    onClick={() => onPageChange(i)}
                >
                    {i}
                </Pagination.Item>
            );
        }

        return pages;
    }, [totalPages, currentPage, onPageChange]);

    // [totalPages, currentPage]

    if (totalPages === 0) return null;

    return (
        <>
          <div className="table-toolbar pull-left" style={{margin: "5px"}}>
                                        Showing {fromEntry} to { toEntry} of {totalEntry} Entries
            </div>
             <div className="table-toolbar pull-right">
                <Pagination>
                    <Pagination.First
                     onClick={() => onPageChange(1)}
                        disabled={currentPage === 1} style={{ pointerEvents: (currentPage === 1 ? "none" : "") }}
                    />
            <Pagination.Prev
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1} style={{pointerEvents:(currentPage === 1 ?"none":"")}}
            />
            {paginationItems}
            <Pagination.Next
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                style={{ pointerEvents: (currentPage === totalPages ?"none":"")  }}
                    />
                    <Pagination.Last
                  onClick={() => onPageChange(totalPages)}
                  disabled={currentPage === totalPages}
                  style={{ pointerEvents: (currentPage === totalPages ? "none" : "") }}
                    />
                </Pagination>
                </div>
            </>
    );
};

export default PaginationComponent;
