import React, { useState, useEffect, useRef, useCallback } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import 'react-notifications-component/dist/theme.css';
import ReactNotification from 'react-notifications-component';
import validator from 'validator'
import Nodify from "../../../../Common/ReactNotification";
import { createReportTemplate } from "../../../../../actions/reporttemplate";
import reportTemplateService from "../../../../../services/Master/ReportTemplateService";
import SearchRetain from "../../../../Common/SearchRetain";
import { useDispatch, useSelector } from "react-redux";
import Reactselect from 'react-select';
import columnInformationService from "../../../../../services/Master/ColumnInformationService";
import { event } from "jquery";
const EditReportTemplate = (props) => {

    //const [ReportModulecategory, setInputs] = useState({ReportTemplateName: '' });
    const [submitted, setSubmitted] = useState(false);
    const [getReport, setGetReport] = useState({ arrReportTemplateIDList: [], ReportTemplateName: '', ReportTemplateIDList: '', ReportModuleCategory: '' });
    const [ButtonName, setButtonName] = useState("Save");
    const [HeaderName, setHeaderName] = useState("Add");
    const [buttonLoader, setButtonLoader] = useState(false);
    const { user: currentUser } = useSelector((state) => state.auth);
    const [getreportModuleCategoryList, setreportModuleCategoryList] = useState([]);
    const [getColumnInformation, setColumnInformation] = useState([]);
    const [savedData, setSavedData] = useState({});
    const [removedData, setRemovedData] = useState({});
    const dispatch = useDispatch();

    let styles = {
        control: styles => ({ ...styles, border: '1px solid red', innerHeight: '100px', outerHeight: '100px' }),
    }

    useEffect(() => { // showLoader();
        debugger
        let reportModuleCategoryValue = {
            ReportTemplateID: 0,
            ReportModuleCategoryName: '',
            ReportTemplateName: '',
            ReportTemplateIDList: '',
            Operation: 1,
            ReportModuleCatOperation: 0,
            Createdby: currentUser.employeeinformationID,
        };
        const loadReportCategories = async () => {
            try {
                const response = await reportTemplateService.loadReportModuleCategoryList();
                if (response.data) {

                    setreportModuleCategoryList(response.data);
                }
                if (props.location.state !== undefined && props.location.state.params.length !== 0) {
                    if (props.location.state.params.addressTypeID !== "") {
                        setButtonName("Update");
                        setHeaderName("Edit");
                        reportModuleCategoryValue.ReportTemplateID = props.location.state.params.reportTemplateID;
                        reportModuleCategoryValue.ReportTemplateName = validator.trim(props.location.state.params.reportTemplateName);
                        reportModuleCategoryValue.ReportModuleCategoryName = validator.trim(props.location.state.params.reportModuleCategoryName);
                        reportModuleCategoryValue.ReportModuleCategory = validator.trim(props.location.state.params.reportTemplateIDList);
                        reportModuleCategoryValue.ReportModuleCatOperation = props.location.state.params.repCatOperation
                        reportModuleCategoryValue.ReportTemplateIDList = response.data.filter((data => {
                            if (props.location.state.params.reportTemplateIDList.split(",").includes(data.value.toString())) {
                                return data;
                            }
                        }))
                        reportModuleCategoryValue.Operation = 2;
                    }
                    setGetReport(reportModuleCategoryValue)
                }

                const res1 = await columnInformationService.getColumnInformationList(3, props.location.state.params.reporttempID);

                if (res1.data.outputReportInformationList) {
                    // Initialize an object to store records by module name
                    const recordsByModule = {};

                    // Iterate over each item in the response
                    res1.data.outputReportInformationList.forEach((x) => {
                        // Initialize the array for each module name if it doesn't exist
                        if (!recordsByModule[x.reportModuleName]) {
                            recordsByModule[x.reportModuleName] = [];
                        }

                        // Add the record to the corresponding module name array
                        recordsByModule[x.reportModuleName].push({
                            ReportTempDetailinfoID: x.reportTempDetailinfoID,
                            DisplayName: x.reportTempDetail,
                            IsChecked: x.isChecked,
                            ReportModulename: x.reportModuleName,
                            ModuleID: x.reportModuleID,
                            ColumnName: x.columnName,
                            TableName: x.tableName
                        });
                    });

                    // Set the grouped records to state
                    setColumnInformation(recordsByModule);
                }
            } catch (error) { console.error("Error loading report module category list:", error); }

        }
        loadReportCategories();
    }, [props]);

    const filterRecordsCheckedInAllCategories = (getColumnInformation) => {
        // Filtered records object to store selected records per category
        const filteredRecords = {};

        // Iterate over each category
        Object.keys(getColumnInformation).forEach(category => {
            const records = getColumnInformation[category] || [];
            // Filter records where IsChecked is 1
            filteredRecords[category] = records.filter(record => record.IsChecked === 1 || record.IsChecked === 0);
        });

        return filteredRecords;
    };
    const saveReportTemplate = (e) => {
        debugger;
        setButtonLoader(true);
        e.preventDefault();
        if (getReport.ReportTemplateName.trim() === '') {
            setButtonLoader(false);
            setSubmitted(true);
            Nodify('Warning!', 'warning', 'Report Template name is required');
            // return false;
        }
        else if (getReport.ReportTemplateIDList === '') {
            setButtonLoader(false);
            setSubmitted(true);
            Nodify('Warning!', 'warning', 'Report Module Category  is required');
        }
        const isAnyCategoryChecked = Object.keys(getColumnInformation).every(category => {
            const records = getColumnInformation[category] || [];
            return records.some(record => record.IsChecked === 1);
        });

        if (!isAnyCategoryChecked) {
            Nodify('Warning!', 'warning', 'At least one checkbox must be checked in each category.');
            setButtonLoader(false);
            return false;
        }
        else {
            const filteredColumnInformation = filterRecordsCheckedInAllCategories(getColumnInformation);

            let Reportinfo = [];
            Object.values(filteredColumnInformation).forEach(categoryArray => {
                categoryArray.forEach((x) => {
                    Reportinfo.push({
                        ReportTempDetailinfoID: x.ReportTempDetailinfoID,
                        ReportTempDetail: x.DisplayName,
                        ReportModuleName: (x.ReportModulename !== undefined && x.ReportModulename !== '') ? x.ReportModulename : x.ModuleName,
                        IsChecked: x.IsChecked,
                        ReportModuleID: x.ModuleID,
                        ColumnName: x.ColumnName,
                        TableName: x.TableName
                    });
                });
            });

            if (getReport.ReportTemplateName.trim()) {
                let InputCommonReportMaster = {
                    Operation: 2,
                    ReportTempDetailinfoID: props.location.state.params.reporttempID,
                    Createdby: currentUser.employeeinformationID,
                    ReportTemplateID: getReport.ReportTemplateID,
                    ReportTemplateName: getReport.ReportTemplateName.trim(),
                    ReportModuleCategory: getReport.ReportModuleCategory,
                    ReportModuleCatOperation: getReport.ReportModuleCatOperation,
                    MasterReportInformation: Reportinfo
                };
                // getReport.ReportTemplateName = getReport.ReportTemplateName.trim();

                dispatch(createReportTemplate(InputCommonReportMaster))
                    .then(data => {
                        var page = "Remove";
                        SearchRetain(page);
                        let Func = 'Add';
                        if (data.outputResult === "1") {
                            Func = 'Add';
                            PageRedirect(Func);
                        } else if (data.outputResult === "2") {
                            Func = 'Edit';
                            PageRedirect(Func);
                        } else if (data.outputResult === "-2") {
                            getReport.ReportTemplateName = '';
                            setGetReport(getReport);
                            Nodify('Warning!', 'warning', 'ReportTemplate Name is  already Exist');
                        }
                        setButtonLoader(false);
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }
            else {
                setSubmitted(true);
            }
        }
    };




    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/ReportTemplateList",
            state: {
                message: Func
            }
        });
    }


    const checkConditions = (selectedOptions) => {
        const labels = new Set(selectedOptions.map(option => option.label));

        // Define your condition checks
        if (labels.has('TNA') && labels.size === 1) {
            return 1;
        } else if (labels.has('TNA') && labels.has('TNA-Parent') && labels.size === 2) {
            return 2;
        } else if (labels.has('TNA') && labels.has('TNA-Parent') && labels.has('TNA-Childtask') && labels.size === 3) {
            return 3;
        } else if (labels.has('Style') && labels.size === 1) {
            return 1;
        } else if (labels.has('Style') && labels.has('Style-Fabric') && labels.size === 2) {
            return 2;
        }

        return 0; // Default case if no condition is met
    };

    const handleDrpDownChange = async (selectedOptions) => {
        debugger
        const values = { ...getReport };
        const isArray = Array.isArray(selectedOptions);
        const checkopt = checkConditions(selectedOptions)
        // Update ReportModuleCategory and ReportTemplateIDList
        if (isArray) {
            values.ReportTemplateIDList = selectedOptions;
            const strValue = selectedOptions.map(option => option.value).join(',');
            values.ReportModuleCategory = strValue;
            values.ReportModuleCatOperation = checkopt;
        } else {
            values.ReportTemplateIDList = [selectedOptions];
            values.ReportModuleCategory = selectedOptions.value;
        }

        const previousOptions = getReport.ReportTemplateIDList.map(option => option.value);
        const currentOptions = selectedOptions.map(option => option.value);
        const removedOptions = previousOptions.filter(option => !currentOptions.includes(option));
        const addedOptions = currentOptions.filter(option => !previousOptions.includes(option));

        setGetReport(values);

        let existingData = { ...getColumnInformation };

        // Handle removed options
        removedOptions.forEach(removedOption => {
            const removedDataForOption = {};

            // Capture data before removing
            Object.keys(existingData).forEach(moduleName => {
                const moduleData = existingData[moduleName].filter(column => column.ModuleID === removedOption);
                if (moduleData.length > 0) {
                    removedDataForOption[moduleName] = moduleData;
                }
            });

            // Store the removed data in `removedData` if it's not already stored
            if (!removedData[removedOption]) {
                setRemovedData(prevData => ({
                    ...prevData,
                    [removedOption]: removedDataForOption
                }));
            }

            // Remove the data from `existingData`
            Object.keys(existingData).forEach(moduleName => {
                existingData[moduleName] = existingData[moduleName].filter(column => column.ModuleID !== removedOption);
                if (existingData[moduleName].length === 0) {
                    delete existingData[moduleName];
                }
            });
        });

        // Handle added options
        let newColumnData = {};
        const fetchPromises = addedOptions.map(value => {
            if (savedData[value]) {
                return Promise.resolve({ data: { outputColumnInformationList: savedData[value] } });
            } else if (removedData[value]) {
                return Promise.resolve({ data: { outputColumnInformationList: removedData[value][Object.keys(removedData[value])[0]] } });
            } else {
                return columnInformationService.getColumnInformationList(2, value);
            }
        });

        try {
            const results = await Promise.all(fetchPromises);

            results.forEach(res => {
                if (res.data.outputColumnInformationList) {
                    const fetchedData = res.data.outputColumnInformationList.map(x => ({
                        ReportTempDetailinfoID: x.ReportTempDetailinfoID ? x.ReportTempDetailinfoID : 0,
                        Id: x.columnInformationID,
                        TableName: x.TableName ? x.TableName : x.tableName,
                        ColumnName: x.ColumnName ? x.ColumnName : x.columnName,
                        DisplayName: x.DisplayName ? x.DisplayName : x.displayName,
                        ModuleName: x.ReportModulename ? x.ReportModulename : x.moduleName,
                        ModuleID: x.ReportModulename ? x.ModuleID : x.moduleID,
                        IsChecked: x.IsChecked ? x.IsChecked : 0
                    }));

                    fetchedData.forEach(item => {
                        if (!newColumnData[item.ModuleName]) {
                            newColumnData[item.ModuleName] = [];
                        }
                        newColumnData[item.ModuleName].push(item);
                    });

                    setSavedData(prevData => ({
                        ...prevData,
                        [res.data.outputColumnInformationList[0]?.ModuleID]: res.data.outputColumnInformationList
                    }));
                }
            });

            const mergedData = { ...existingData };

            Object.entries(newColumnData).forEach(([moduleName, columns]) => {
                if (!mergedData[moduleName]) {
                    mergedData[moduleName] = [];
                }

                const uniqueColumnsMap = new Map();

                columns.forEach(item => {
                    uniqueColumnsMap.set(item.DisplayName, item);
                });

                mergedData[moduleName].forEach(item => {
                    uniqueColumnsMap.set(item.DisplayName, item);
                });

                mergedData[moduleName] = Array.from(uniqueColumnsMap.values());
            });

            setColumnInformation(mergedData);
        } catch (error) {
            console.error('Error fetching column information:', error);
        }
    };

    const handleCheckboxChange = (e, moduleName, id, rowIndex) => {
        debugger
        const { checked } = e.target;

        // Convert checked state to integer value (1 or 0)
        const isCheckedValue = checked ? 1 : 0;
        // Make a copy of the current column information state
        const updatedColumnInformation = { ...getColumnInformation };

        // Find the module that corresponds to the moduleName
        const moduleData = updatedColumnInformation[moduleName];

        if (moduleData) {
            updatedColumnInformation[moduleName][rowIndex].IsChecked = isCheckedValue;
            setColumnInformation(updatedColumnInformation);
        }
    };

    function handleChange(e) {
        const { name, value } = e.target;

        setGetReport(prev => ({
            ...prev,
            [name]: value
        }));

        setSubmitted(value.trim() !== '');
    }

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/ReportTemplateList')
        } else {
            window.location.reload();
        }
    }

    const View = getReport.View;
    return (
        <div className="page-body">
            <ReactNotification />
            {
                View === undefined ? (
                    <div className="row">
                        <div className="col-lg-12 col-sm-12 col-xs-12">
                            <div className="widget flat radius-bordered">
                                <div className="widget-header heading_box_style">
                                    <h3 className="heading_style_common">{HeaderName} Report Module Category</h3>
                                    <div className="buttons-preview" align="right">
                                        <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                            <i className="fa fa-arrow-circle-left"></i>
                                            &nbsp;Back
                                        </span>
                                        <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                            <i className="fa fa-times"></i>
                                            &nbsp;Reset
                                        </span>
                                        <button type="submit"
                                            onClick={saveReportTemplate}
                                            className="btn btn-success"
                                            disabled={buttonLoader}
                                        >
                                            <i className="fa fa-check right"></i>&nbsp;
                                            {ButtonName}
                                        </button>
                                    </div>
                                </div>
                                <div className="widget-body">
                                    <div id="registration-form">
                                        <Form>

                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <div className="form-group">


                                                        <label htmlFor="ReportTemplateName">Report Template Name<span className="text-danger">*</span></label>
                                                        <span className="input-icon icon-right">
                                                            <input type="text" name="ReportTemplateName"
                                                                id="ReportTemplateName"
                                                                value={getReport.ReportTemplateName}
                                                                onChange={handleChange}
                                                                style={{ border: submitted && !getReport.ReportTemplateName ? '1px solid red' : '' }}
                                                                maxLength="25"
                                                                className={
                                                                    'form-control' + (
                                                                        submitted && !getReport.ReportTemplateName ? ' is-invalid' : ''
                                                                    )
                                                                }
                                                                autoFocus
                                                                placeholder="Report Module Category"
                                                            />

                                                        </span>

                                                    </div>
                                                </div>

                                                <div className="col-sm-3">
                                                    <div className='form-group'>
                                                        <label htmlFor="ReportModuleCategoryName">Report Module Category<span className="text-danger">*</span></label>
                                                        <span className='input-icon icon-right'>

                                                            <Reactselect
                                                                className="basic-single"
                                                                name="ReportModuleCategory"
                                                                id="ReportModuleCategory"
                                                                isDisabled={false}
                                                                isClearable={false}
                                                                isSearchable={true}
                                                                isMulti={true}
                                                                value={getReport.ReportTemplateIDList}
                                                                onChange={handleDrpDownChange}
                                                                styles={submitted && getReport.ReportTemplateIDList.length === 0 ? styles : ''}
                                                                options={getreportModuleCategoryList}
                                                            />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="clearfix"></div>


                                                {getReport?.ReportTemplateIDList !== '' ? <div className="row" style={{ padding: '14px 14px 14px 14px' }}>
                                                    <div className="col-md-6">
                                                        {Object.keys(getColumnInformation).map((key, index) => (
                                                            <div className="panel-group accordion" id={`${key}`}>
                                                                <div className="panel panel-default" key={index}>
                                                                    <div className="panel-heading">
                                                                        <h4 className="panel-title">
                                                                            <a
                                                                                className="accordion-toggle collapsed clr-themegreen"
                                                                                data-toggle="collapse"
                                                                                data-parent={`${key}`}
                                                                                href={`#collapse_${key}`}
                                                                            >
                                                                                {`Details for ${key}`}
                                                                            </a>
                                                                        </h4>
                                                                    </div>
                                                                    <div id={`collapse_${key}`} className="panel-collapse collapse">
                                                                        <div className="panel-body border-red">
                                                                            <div className="col-md-12">
                                                                                <div className="row">
                                                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th rowspan="2" className="fixed-width" style={{ width: '40px' }}>#</th>
                                                                                                <th rowspan="2" className="fixed-width-th" style={{ width: '500px' }}>Details</th>
                                                                                                <th rowspan="2" className="fixed-width-th" style={{ width: '500px' }}>Checked</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {getColumnInformation[key].length > 0 ? getColumnInformation[key].map((x, rowIndex) => (
                                                                                                <tr key={x.Id}>
                                                                                                    <td>{rowIndex + 1}</td>
                                                                                                    <td>{x.DisplayName}</td>
                                                                                                    <td className="Actionbutton">
                                                                                                        <div style={{ width: "80px" }}>
                                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    name="IsSelected"
                                                                                                                    className="colored-blue clearOpacity"
                                                                                                                    checked={x.IsChecked === 1}
                                                                                                                    onChange={(event) => handleCheckboxChange(event, key, x.Id, rowIndex)}
                                                                                                                />
                                                                                                                <span className="text"></span>
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )) : (
                                                                                                <tr>
                                                                                                    <td colSpan="3" className='norecordfound'>
                                                                                                        <span>No Records Found</span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div> : ''}
                                                <div className="clearfix"></div>

                                                <div className="col-sm-12">
                                                    <div className="buttons-preview" align="right">
                                                        <span className="btn btn-primary"
                                                            onClick={
                                                                () => ResetOperation("Back")
                                                            }>
                                                            <i className="fa fa-arrow-circle-left"></i>
                                                            &nbsp;Back</span>
                                                        <span className="btn btn-danger"
                                                            onClick={
                                                                () => ResetOperation()
                                                            }>
                                                            <i className="fa fa-times"></i>
                                                            &nbsp;Reset</span>
                                                        <button type="submit"
                                                            onClick={saveReportTemplate}
                                                            className="btn btn-success"
                                                            disabled={buttonLoader}
                                                        >

                                                            <i className="fa fa-check right"></i>
                                                            &nbsp;{
                                                                ButtonName
                                                            }</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                ) : (
                    <div className="navbar-expand-lg navbar-light">
                        <h3>Report Template</h3>
                        <div className="form-group">
                            <label name="ReportModuleCategoryName">
                                {
                                    getReport.ReportTemplateName
                                }</label>
                        </div>
                    </div>

                )
            }
            { }</div>
    );
};

export default EditReportTemplate;

