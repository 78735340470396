import axios from "axios";
import authHeader from "../auth-header";

class ItemServices {

 getAllItemList() {
  return axios.get(window.$APIBaseURL + "Master/getAllItemList?ItemID=0", {
   headers: {
    authorization: authHeader()
   }
  });
 }

 InsertUpdateItem(Params) {
  
  return axios({
   method: 'post',
   url: window.$APIBaseURL + 'Master/InsertUpdateItem',
   data: Params,
   headers: {
    authorization: authHeader()
   }
  });
 }

 AddItem(Params) {
  return axios({
   method: 'post',
   url: window.$APIBaseURL + 'Master/InsertUpdateDelitem',
   data: Params,
   headers: {
    authorization: authHeader()
   }
  });
 }




}


export default new ItemServices()