import React, { useState, useEffect, Fragment, Suspense } from "react";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import "react-notifications-component/dist/theme.css";
import Nodify from "../Common/ReactNotification";
import "react-datepicker/dist/react-datepicker.css";
import Lightbox from "react-image-lightbox";
import { Modal, Button } from "react-bootstrap";
import $ from "jquery";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { TableHeader, Pagination, Search, PageCount } from "../Datatable";
import axios from "axios";
import Reactselect from 'react-select';
import TnaService from "../../services/TNA/TNAService";
import Fabric from "../quickMaster/_Fabric";
import ProgramService from "../../services/Style/ProgramService";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import SweetAlertPopup from '../Common/SweetAlertPopup';
import { LabdipSubmissionApprovalId } from "../Common/PageActionNumber";
import PDFJS from 'pdfjs'
import html2canvas from 'html2canvas'
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import download from 'downloadjs'
import jsPDF from 'jspdf'
import FabricService from "../../services/Master/FabricService";
import moment from "moment";


const LabdibApproval = (props, IsProductionDD) => {
    //just Check
    const { user: currentUser } = useSelector((state) => state.auth);

    const [submitted, setSubmitted] = useState(false);
    const [getHeader, setHeader] = useState("Add");

    const [isOpen, setIsOpen] = useState(false);
    const [getImagename, setImagename] = useState();
    const [getOrderinfo, setOrderinfo] = useState([]);
    const [getApprovedOptions, setApprovedOptions] = useState([]);
    const [DisableApprovalOption, setDisableApprovalOption] = useState();
    const [SubmittedOption, setSubmittedOption] = useState([]);
    const [FabricDetails, setFabricDetails] = useState([]);
    // const [showMultiClosediv, setshowMultiClosediv] = useState();
    //const [showSubmissiondiv, setshowSubmissiondiv] = useState();
    const [downloadpdf, setdownloadpdf] = useState('');
    const [selectall, setselectall] = useState(false);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [showswatchcardimages, setshowswatchcardimages] = useState([]);
    const [swatchcardimages, setswatchcardimages] = useState([]);
    const [CurrentSwatchstatus, setCurrentSwatchstatus] = useState(0);
    const [minApprovalDate, setMinApprovalDate] = useState({});
    const [buttonLoader, setbuttonLoader] = useState(false);
    const [getID, setID] = useState({ showPopupDelete: false, Params: {} });
    const ApprovedOption = [
        { key: '1', value: 'Approved' },
        { key: '2', value: 'Approved with Comments' },
        { key: '3', value: 'Rejected' },
        { key: '4', value: 'Hold' }
    ]

    let styles1 = {
        control: styles1 => ({ ...styles1 }),
    }

    let styles2 = {
        control: styles2 => ({ ...styles2, border: '1px solid red' }),

    }
    // If check production or sampling
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProduction = activeMenus.IsProduction;
    // End


    const [inputFields, setInputFields] = useState([{
        TNAId: 0, BrandID: 0, SeasonID: 0, SupplierID: 0, SubmittedDate: '', SendTo: '', SendThroughId: 0, SendThroughName: '',
        StyleID: 0, StyleName: '', StyleNo: '', IdpoNo: '', EndCustomer: '',
        AWBName: '', AWBDate: '', SubmissionApprovalStatus: 0, PantoneID: 0, RefColorName: '',
        PantoneImageName: '', PantoneImagePath: '', FabricID: 0, FabricDetail: '', SwatchCardImageName: '',
        SwatchCardIamgePath: '', Remarks: '', Createdby: 0,
        TNALabdibSubmittedOption: [],
        BuyerSeasonBrand: '', AWBDateandNo: '', PantoneTypeName: '',
        ApprovalDate: '', ApprovalRemark: '', AwbDateandNo: '', SubmitSentDetails: '', PantoneCode: '', SubmissionApprovalID: 0,
        StyleProgramId: 0, Approvedby: '', Result: '', CubeorBuyer: '', IsAllFabricLoad: 0,
    }]);
    const values = [...inputFields]

    useEffect(() => {
        setCurrentSwatchstatus(props.SwatchStatus);
        if (props.deleteLabdipApproval === true) {
            TnaService.ViewLabdipSubmissionApprovalList(props.LabdibFabricInfoId, IsProduction === 0 ? props.IsProductionDD : IsProduction).then((response) => {
                if (response.data) {
                    setOrderinfo(response.data.labdipSubmissionOrderInfoList);
                }
            }).catch(() => { });
            setID({ showPopupDelete: true, Params: props.LabdibFabricInfoId });
        }
        else if (props.editLabdipApproval === true) {

            let getDateValue = new Date(values[0].ApprovalDate);
            var dd = getDateValue.getDate();
            var mm = getDateValue.getMonth() + 1;
            var yyyy = getDateValue.getFullYear();

            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }

            values[0].ApprovalDate = getDateValue;
            setInputFields(values);

        }
        else if (props.LabdipswatchDownload === true) {
            TnaService.ViewLabdipSubmissionApprovalList(props.LabdibFabricInfoId, IsProduction === 0 ? props.IsProductionDD : IsProduction).then((response) => {
                if (response.data) {
                    values[0].SwatchCardIamgePath = response.data.labdibSubmitApprovalList[0].swatchCardImagePath;
                    values[0].SwatchCardImageName = response.data.labdibSubmitApprovalList[0].swatchCardImageName;
                    setInputFields(values);
                }

            }).catch(() => { });
            downloadSwatchcard();
        }
        else if (props.LabdipswatchUpload === true) {
            $('#upload-button').trigger('click');

        }
        else {
            let Optionvalue = [];
            ApprovedOption.forEach(element => {
                let LabelColor = '';
                if (element.value === 'Approved') {
                    LabelColor = <><span style={{ color: "#00b050" }}><b>Approved</b></span></>
                }
                else if (element.value === 'Approved with Comments') {
                    LabelColor = <><span style={{ color: "#fca404" }}><b>Approved with Comments</b></span></>
                }
                else if (element.value === 'Rejected') {
                    LabelColor = <><span style={{ color: "#c40404" }}><b>Rejected</b></span></>
                }
                else if (element.value === 'Hold') {
                    LabelColor = <><span style={{ color: "#073763" }}><b>Hold</b></span></>
                }


                Optionvalue.push({
                    value: element.key,
                    label: LabelColor,
                    subLabel: element.value,
                })
            });

            setApprovedOptions(Optionvalue);

            // ProgramService.LoadStyleFabricDetailsList(props.StyleId).then((response) => {
            //     if (response.data) {
            //         setFabricDetails(response.data);
            //     }
            // }).catch(() => { });

            if (props.TaskStatus !== 3) {
                // setshowMultiClosediv(true);
                // setshowSubmissiondiv(false);



                TnaService.GetLabdipSubmissionSubmittedOption(1, props.SubmittedOptionListId).then((response) => {
                    if (response.data) {
                        values[0].TNALabdibSubmittedOption = response.data;
                        setInputFields(values);
                        setSubmittedOption(response.data);
                    }
                }).catch(() => { });
                // TnaService.GetLabdipSubmissionOrderInfoList(2, props.LabdibFabricInfoId).then((response) => {
                //     if (response.data) {
                //         setOrderinfo(response.data);
                //     }
                // }).catch(() => { });


                TnaService.GetLabdipSubmissionOrderInfoList(4, props.LabdibFabricInfoId, IsProduction === 0 ? props.IsProductionDD : IsProduction).then((response) => {
                    if (response.data) {
                        response.data.forEach(element => {
                            if (element.labdibFabricInfoId === props.LabdibFabricInfoId) {
                                element.isSelected = 1;
                            }
                        })
                        setOrderinfo(response.data);
                    }
                }).catch(() => { });

                //TnaService.LapdipSubmissionList(2, props.LabdibFabricInfoId).then((response) => {
                //     if (response.data) {
                values[0].SubmissionApprovalID = props.LabdipApprovalList[0].submissionApprovalID;
                values[0].TNAId = props.LabdipApprovalList[0].tnaId;
                values[0].BrandID = props.LabdipApprovalList[0].brandID;
                values[0].BuyerID = props.LabdipApprovalList[0].buyerID;
                values[0].SeasonID = props.LabdipApprovalList[0].seasonID;
                values[0].SupplierID = props.LabdipApprovalList[0].supplierID;
                values[0].SupplierName = props.LabdipApprovalList[0].supplierName;
                values[0].BuyerBrandSeasonName = props.LabdipApprovalList[0].buyerBrandSeasonName;
                values[0].StyleID = props.LabdipApprovalList[0].styleId;
                values[0].StyleName = props.LabdipApprovalList[0].styleName;
                values[0].StyleNo = props.LabdipApprovalList[0].styleNo;
                values[0].IdpoNo = props.LabdipApprovalList[0].idpoNo;
                values[0].EndCustomer = props.LabdipApprovalList[0].endCustomerNameIdList;
                values[0].PantoneID = props.LabdipApprovalList[0].pantoneId;
                values[0].PantoneCode = props.LabdipApprovalList[0].pantoneCode;
                values[0].RefColorName = props.LabdipApprovalList[0].refColorName;
                values[0].PantoneNameOrSwatchName = props.LabdipApprovalList[0].pantoneNameOrSwatchName;
                values[0].PantoneColorOrOrSwatchImage = props.LabdipApprovalList[0].pantoneColorOrOrSwatchImage;
                values[0].FabricID = props.LabdipApprovalList[0].fabricId;
                values[0].IsAllFabricLoad = props.LabdipApprovalList[0].isAllFabricLoad;
                //values[0].SubmittedDate = response.data.labdibSubmitApprovalList[0].submittedDate;
                if (values[0].IsAllFabricLoad === 1) {
                    FabricService.GetFabricList(props.LabdipApprovalList[0].fabricId, "F").then((response) => {
                        if (response.data) {
                            setFabricDetails(response.data)
                            values[0].FabricDetail = response.data.label
                        }
                    })
                } else if (values[0].IsAllFabricLoad === 0 || values[0].IsAllFabricLoad === null || values[0].IsAllFabricLoad === undefined) {
                    ProgramService.LoadStyleFabricDetailsList(props.StyleId).then((response) => {
                        if (response.data) {
                            setFabricDetails(response.data);
                        }
                    }).catch(() => { });
                }
                values[0].PantoneTypeName = props.LabdipApprovalList[0].pantoneTypeName;
                values[0].SubmittedDate = new Date(props.LabdipApprovalList[0].submittedDate)
               ;
                //values[0].SubmittedDate = new Date(props.LabdipApprovalList[0].submittedDate.setMinutes(props.LabdipApprovalList[0].submittedDate.getMinutes() + 370))
                // setMinApprovalDate((moment(values[0].SubmittedDate).format("YYYY-MM-DD")).toString());
                values[0].AWBName = props.LabdipApprovalList[0].awbName;
                values[0].AWBDate = props.LabdipApprovalList[0].awbDate;
                values[0].SendThrough = props.LabdipApprovalList[0].sendThroughId;
                values[0].SendThroughName = props.LabdipApprovalList[0].sendThroughName;

                let getDate = new Date(props.LabdipApprovalList[0].awbDate);
                let dd = getDate.getDate();
                let mm = getDate.getMonth() + 1;
                let yyyy = getDate.getFullYear();

                if (dd < 10) {
                    dd = '0' + dd;
                }
                if (mm < 10) {
                    mm = '0' + mm;
                }
                let coverted = dd + '/' + mm + '/' + yyyy;


                values[0].SubmitSentDetails = props.LabdipApprovalList[0].sendThroughId === 1 ? props.LabdipApprovalList[0].sendThroughName : props.LabdipApprovalList[0].sendThroughName + ' ' + props.LabdipApprovalList[0].awbName + ' ' + (props.LabdipApprovalList[0].awbDate === '' ? ' ' : '[' + moment(props.LabdipApprovalList[0].awbDate).format('DD/MM/YYYY') + ']')
                values[0].SwatchCardImageName = props.LabdipApprovalList[0].swatchCardImageName;
                values[0].SwatchCardIamgePath = props.LabdipApprovalList[0].swatchCardImagePath;
                values[0].SubmissionApprovalID = props.LabdipApprovalList[0].submissionApprovalID;
                values[0].Remarks = props.LabdipApprovalList[0].remarks;
                values[0].SendTo = props.LabdipApprovalList[0].sentTo;
                values[0].StyleProgramId = props.LabdipApprovalList[0].styleProgramId;
                values[0].LabdibFabricInfoId = props.LabdibFabricInfoId;
                setInputFields(values);
                //     }
                // }).catch(() => { });

            }
            else {
               
                // setshowMultiClosediv(false);
                // setshowSubmissiondiv(true);
                TnaService.ViewLabdipSubmissionApprovalList(props.LabdibFabricInfoId, IsProduction === 0 ? props.IsProductionDD : IsProduction).then((response) => {
                    if (response.data) {
                        values[0].TNAId = response.data.labdibSubmitApprovalList[0].tnaId;
                        values[0].BrandID = response.data.labdibSubmitApprovalList[0].brandID;
                        values[0].BuyerID = response.data.labdibSubmitApprovalList[0].buyerID;
                        values[0].SeasonID = response.data.labdibSubmitApprovalList[0].seasonID;
                        values[0].SupplierID = response.data.labdibSubmitApprovalList[0].supplierID;
                        values[0].SupplierName = response.data.labdibSubmitApprovalList[0].supplierName;
                        values[0].BuyerBrandSeasonName = response.data.labdibSubmitApprovalList[0].buyerBrandSeasonName;
                        values[0].StyleID = response.data.labdibSubmitApprovalList[0].styleId;
                        values[0].StyleName = response.data.labdibSubmitApprovalList[0].styleName;
                        values[0].StyleNo = response.data.labdibSubmitApprovalList[0].styleNo;
                        values[0].IdpoNo = response.data.labdibSubmitApprovalList[0].idpoNo;
                        values[0].EndCustomer = response.data.labdibSubmitApprovalList[0].endCustomerNameIdList;
                        values[0].PantoneID = response.data.labdibSubmitApprovalList[0].pantoneId;
                        values[0].PantoneCode = response.data.labdibSubmitApprovalList[0].pantoneCode;
                        values[0].RefColorName = response.data.labdibSubmitApprovalList[0].refColorName;
                        values[0].PantoneNameOrSwatchName = response.data.labdibSubmitApprovalList[0].pantoneNameOrSwatchName;
                        values[0].PantoneColorOrOrSwatchImage = response.data.labdibSubmitApprovalList[0].pantoneColorOrOrSwatchImage;
                        values[0].PantoneTypeName = response.data.labdibSubmitApprovalList[0].pantoneTypeName;
                        values[0].StyleProgramId = response.data.labdibSubmitApprovalList[0].styleProgramId;
                        values[0].LabdibFabricInfoId = props.LabdibFabricInfoId;

                        values[0].SubmittedDate = new Date(response.data.labdibSubmitApprovalList[0].submittedDate)
                        //setMinApprovalDate((moment(values[0].SubmittedDate).format("DD-MM-YYYY")).toString());
                        values[0].SendThrough = response.data.labdibSubmitApprovalList[0].sendThrough;
                        values[0].SendTo = response.data.labdibSubmitApprovalList[0].sendTo;
                        values[0].SwatchCardIamgePath = response.data.labdibSubmitApprovalList[0].swatchCardImagePath;
                        values[0].SwatchCardImageName = response.data.labdibSubmitApprovalList[0].swatchCardImageName;
                        // values[0].ApprovalDate = response.data.labdibSubmitApprovalList[0].approvalDate;
                        values[0].SubmissionApprovalID = response.data.labdibSubmitApprovalList[0].submissionApprovalID;
                        let getDateValue = new Date(response.data.labdibSubmitApprovalList[0].approvalDate);
                        var dd = getDateValue.getDate();
                        var mm = getDateValue.getMonth() + 1;
                        var yyyy = getDateValue.getFullYear();

                        if (dd < 10) {
                            dd = '0' + dd;
                        }
                        if (mm < 10) {
                            mm = '0' + mm;
                        }
                        let coverted = mm + '/' + dd + '/' + yyyy;

                        let getDate = new Date(response.data.labdibSubmitApprovalList[0].awbDate);
                        var dd = getDate.getDate();
                        var mm = getDate.getMonth() + 1;
                        var yyyy = getDate.getFullYear();

                        if (dd < 10) {
                            dd = '0' + dd;
                        }
                        if (mm < 10) {
                            mm = '0' + mm;
                        }
                        let covert = dd + '/' + mm + '/' + yyyy;
                       
                        // values[0].SubmittedDate = new Date(values[0].SubmittedDate.setMinutes(values[0].SubmittedDate.getMinutes() + 370))
                        values[0].ApprovalDate = getDateValue;
                        values[0].ApprovalRemark = response.data.labdibSubmitApprovalList[0].approvalRemark;
                        values[0].SubmitSentDetails = props.LabdipApprovalList[0].sendThroughId === 1 ? response.data.labdibSubmitApprovalList[0].sendThroughName : response.data.labdibSubmitApprovalList[0].sendThroughName + ' '
                            + response.data.labdibSubmitApprovalList[0].awbName + ' ' + (response.data.labdibSubmitApprovalList[0].awbDate === '' ? '/' : moment(response.data.labdibSubmitApprovalList[0].awbDate).format('DD/MM/YYYY'));
                        values[0].Remarks = response.data.labdibSubmitApprovalList[0].remarks;
                        values[0].TNALabdibSubmittedOption = response.data.labdibSubmittedOption;
                        values[0].Approvedby = response.data.labdibSubmitApprovalList[0].approvedby;
                        values[0].Result = response.data.labdibSubmitApprovalList[0].approvalInfo;
                        values[0].IsAllFabricLoad = response.data.labdibSubmitApprovalList[0].isAllFabricLoad;


                        // values[0].TNALabdibSubmittedOption = [];
                        // response.data.labdibSubmittedOption.forEach(element => {
                        //     values[0].TNALabdibSubmittedOption.push({
                        //         OptionName: element.optionName,
                        //         SupplierReferenceNo: element.supplierReferenceNo
                        //     })
                        // })
                        values[0].FabricID = response.data.labdibSubmitApprovalList[0].fabricId;
                        if (response.data.labdibSubmitApprovalList[0].swatchCardImageName) {
                            let result = response.data.labdibSubmitApprovalList[0].swatchCardImageName.includes("Cube");
                            values[0].CubeorBuyer = result === true ? 'Cube' : 'Buyer';
                        }
                        if (values[0].IsAllFabricLoad === 1) {
                            FabricService.GetFabricList(props.LabdipApprovalList[0].fabricId, "F").then((response) => {
                                if (response.data) {
                                   
                                    setFabricDetails(response.data)
                                    values[0].FabricDetail = response.data.label
                                }
                            })
                        } else if (values[0].IsAllFabricLoad === 0 || values[0].IsAllFabricLoad === null || values[0].IsAllFabricLoad === undefined) {
                            ProgramService.LoadStyleFabricDetailsList(props.StyleId).then((response) => {
                                if (response.data) {
                                    setFabricDetails(response.data);
                                }
                            }).catch(() => { });
                        }
                        setInputFields(values);
                        setOrderinfo(response.data.labdipSubmissionOrderInfoList);

                    }

                }).catch(() => { });
            }
        }
        //  setOrderinfo(getOrderinfo);

    }, [props.deleteLabdipApproval === true || props.LabdipswatchDownload === true || props.LabdipswatchUpload === true || props.editLabdipApproval === true])


    // For when using Edit cant able to delete this information, so we using unique useeffect for delete
    useEffect(() => {
        // setCurrentSwatchstatus(props.SwatchStatus);
        if (props.deleteLabdipApproval === true) {
            TnaService.ViewLabdipSubmissionApprovalList(props.LabdibFabricInfoId, IsProduction === 0 ? props.IsProductionDD : IsProduction).then((response) => {
                if (response.data) {
                    setOrderinfo(response.data.labdipSubmissionOrderInfoList);
                }
            }).catch(() => { });
            setID({ showPopupDelete: true, Params: props.LabdibFabricInfoId });
        }
    }, [props.deleteLabdipApproval === true && props.editLabdipApproval === true])
    const selector = useSelector((state) => state);
    const isSideBarEnable = selector.auth.isSideBarEnable;
    const isFullSideBarEnable = selector.auth.isFullSideBarEnable;


    const headers = [
        { name: "Style Name", field: "StyleName", sortable: false },
        { name: "Style No", field: "StyleNo", sortable: false },
        { name: "PO/ID No", field: "IDNo", sortable: false },
        { name: "Details", field: "Details", sortable: false },
        { name: "Task Holder/Follower", field: "", sortable: false },
        // { name: "Action", field: "", sortable: false },

    ];

    let BrowserWidth = isFullSideBarEnable
        ? isSideBarEnable
            ? $(window).width() - 130
            : $(window).width() + 50
        : $(window).width() + 100;

    let DynamicClassName = "col-lg-12 col-sm-12 col-xs-12";
    var ImgClassName = "upload-btn-wrapper2"
    function handleChange(event, feild) {

        if (feild === "ApprovalDate") {
            if (event !== null) {
                if (new Date(event) < new Date(values[0].SubmittedDate)) {
                    Nodify("Warning!", "warning", "Approval Date should not lesser than Submit Date.");
                    values[0].ApprovalDate = ''
                }
                else {
                    values[0].ApprovalDate = event;
                }
            }
            else {
                values[0].ApprovalDate = '';
            }

            // setInputFields(values);
        }
        else if (feild === "Remarks") {
            let input = '';
            if (event.target.value.trim() !== '') {
                input = event.target.value;
            }
            values[0].ApprovalRemark = input;
        }
        setInputFields(values);
    }

    const handleSubmitoptionchange = (event, feild, index) => {
        let input = '';
        if (event !== null) {
            setDisableApprovalOption(true);
            input = event.value;
            if (feild === "ApprovedOption") {
                values[0].TNALabdibSubmittedOption[index].approvedOptionName = input;
                if (input === "3") {
                    values[0].TNALabdibSubmittedOption.forEach(element => {
                        element.approvedOptionName = "3";
                    })
                }
                else if (input === "4") {
                    values[0].TNALabdibSubmittedOption.forEach(element => {
                        element.approvedOptionName = "4";
                    })
                }
                else {
                    values[0].TNALabdibSubmittedOption.forEach(element => {
                        element.approvedOptionName = "";
                    })
                    values[0].TNALabdibSubmittedOption[index].approvedOptionName = input;
                }

            }
        }
        else {
            setDisableApprovalOption(false);
            //values[0].TNALabdibSubmittedOption[index].approvedOptionName = '';
            values[0].TNALabdibSubmittedOption.forEach(element => {
                element.approvedOptionName = "";
            })
        }
        setInputFields(values);
    }

    const OnChangeOrderinfo = (event, index) => {
        const orderinfo = [...getOrderinfo];
        let SelectedCount = 0;
        orderinfo.forEach(element => {
            if (element.selectedDetail === 1) {
                SelectedCount = SelectedCount + 1;
            }
        })
        if (event.target.checked === true) {
            if (SelectedCount === 1) {
                orderinfo[index].SelectedDetail = 0;
                Nodify('Warning!', 'warning', 'Please select only one.');
            }
            else {
                orderinfo[index].selectedDetail = 1;
            }
        }
        else {
            orderinfo[index].selectedDetail = 0;
        }
        setOrderinfo(orderinfo);

        let SelectedDetail = '';
        let SelectedStyleID = 0;
        orderinfo.forEach(element => {
            if (element.selectedDetail === 1) {
                SelectedDetail = element.fabricId;
                SelectedStyleID = element.styleId;
            }
        })

        ProgramService.LoadStyleFabricDetailsList(SelectedStyleID).then((response) => {
            if (response.data) {
                setFabricDetails(response.data);
            }
        }).catch(() => { });

        values[0].FabricID = SelectedDetail;
        setInputFields(values);
    }

    const handleRemoveOrderInfo = (index) => {
        const value = [...getOrderinfo]
        value.splice(index, 1);
        setOrderinfo(value);

    }

    // const OnSelectRecords = (event, index) => {
    //     const value = [...MultiCloseDatas];

    //     if (event.target.checked === true) {
    //         value[index].isSelected = 1;
    //     }
    //     else {
    //         value[index].isSelected = 0;
    //         setselectall(0);
    //     }
    //     setMultiCloseDatas(value);
    // }

    // const SelectAll = (event) => {
    //     let IsSelected = 0;

    //     if (event.target.checked === true) {
    //         IsSelected = 1;
    //         MultiCloseDatas.forEach(element => {
    //             element.isSelected = 1;
    //         })
    //     }
    //     else {
    //         IsSelected = 0;
    //         MultiCloseDatas.forEach(element => {
    //             element.isSelected = 0;
    //         })
    //     }
    //     setselectall(IsSelected);
    // }

    // const AddRows = () => {

    //     let FileteredRecords = MultiCloseDatas.filter(x => x.isSelected === 1);
    //     if (FileteredRecords.length === 0) {
    //         Nodify("Warning!", "warning", "Select atleast one row.");
    //     }
    //     else {
    //         setshowMultiClosediv(false);
    //         setshowSubmissiondiv(true);
    //         setOrderinfo(FileteredRecords);
    //     }
    // }

    // const ShowHideDivs = () => {
    //     setshowMultiClosediv(true);
    //     setshowSubmissiondiv(false);
    // }
    const SaveApproval = () => {
        let IsValid = true;
        if (values[0].ApprovalDate === '') {
            IsValid = false;

        }


        var approvecount = 0;
        values[0].TNALabdibSubmittedOption.forEach(element => {
            if (element.approvedOptionName !== '0' && element.approvedOptionName !== '') {
                approvecount = approvecount + 1;
            }
        })
        if (IsValid === false) {
            Nodify("Warning!", "warning", "Please fill the mandatory(*) fields.");
            setSubmitted(true);
        }
        else if (approvecount === 0) {
            Nodify("Warning!", "warning", "Please fill atleast one Approved Option.");
            setSubmitted(true);
        }
        else {
            setbuttonLoader(true)
            values[0].SubmittedDate = new Date(values[0].SubmittedDate.setMinutes(values[0].SubmittedDate.getMinutes() + 370))
            values[0].ApprovalDate = new Date(values[0].ApprovalDate.setMinutes(values[0].ApprovalDate.getMinutes() + 370))
            values[0].Operation = props.editLabdipApproval === false || props.editLabdipApproval === undefined ? 1 : 2;
            values[0].SubmissionApprovalStatus = 2;
            values[0].TNALabdibOrderInfo = getOrderinfo;
            values[0].Createdby = currentUser.employeeinformationID;
            TnaService.InsertUpdateLabdipSubmissionApproval(values[0]).then(res => {
                if (res.data.outputResult === "1") {
                    Nodify('Success!', 'success', 'Lab Dip Approval Added Successfully');
                    props.LapdipApprovalCallback(true, getOrderinfo, 'Save', CurrentSwatchstatus,0);
                } else if (res.data.outputResult === "2") {
                    Nodify('Success!', 'success', 'Lab Dip Approval Updated Successfully');
                    props.LapdipApprovalCallback(true, getOrderinfo, 'Update', CurrentSwatchstatus);
                }
                else if (res.data.outputResult === "0") {
                    Nodify("Error!", "Error Occured!");
                }
            });
        }

    }



    const Delcallback = (value) => {
        if (value === true) {
            setID({ showPopupDelete: false, Params: {} });
            props.LapdipApprovalCallback(true, getOrderinfo, 'Delete');
        }
        else {
            setID({ showPopupDelete: false, Params: {} });
            props.LapdipApprovalCallback(false, '', 'Not A Delete', '')
        }
    }



    const UploadImages = async (e) => {
        const files = e.target.files;
        var file = null;
        var Firstfile = files[0];
        const formData = new FormData();
        for (var i = 0; i < files.length; i++) {
            file = files[i];
            if (file !== null && file !== '' && file !== undefined) {
                const fileType = file['type'];
                const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png'];
                if (!validImageTypes.includes(fileType)) {
                    Nodify('Warning!', 'warning', 'Invalid file format selected');
                    $('#FileUpload').val("");
                }
                else {

                    formData.append("FormFile", file);
                    formData.append("FileName", "Approved_" + file.name);
                    formData.append("Page", "LabdipSwatchCardPdf");
                    try {
                        // const res = await axios.get(window.$APIBaseURL + "api/file/PdfImagesUPload", formData);
                        swatchcardimages.push({
                            src: "Images/LabdipSwatchCardPdf/Approved_" + file.name,
                            height: ''
                        })


                    } catch (ex) {
                        console.log(ex);
                    }
                }
            }
        }
        const res = await axios.post(window.$APIBaseURL + "api/file/LabdipSwatchCardUpload", formData);
        var FileName = Firstfile.name.substr(0, Firstfile.name.lastIndexOf('.'));
        values[0].SwatchCardImageName = "Approved_" + Firstfile.name;
        values[0].SwatchCardIamgePath = "Images/LabdipSwatchCardPdf/Approved_" + Firstfile.name;
        setInputFields(values);
        $('#FileUpload').val("");
        setshowswatchcardimages(true);
        UploadLabdipSwatchCardPdf(FileName);
    }

    const UploadLabdipSwatchCardPdf = (FileName) => {
        var timeout = 5000;
        showLoader();
        const doc = new jsPDF('p', 'pt', 'a4');
        const Page1 = document.getElementById("UploadPage1");
        html2canvas(Page1, { scale: 5 }).then(canvas => {
            var img = new Image();

            img.src = window.$APIBaseURL + swatchcardimages[0].src;
            const imgWidth = 595;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');



            if (document.getElementById("UploadPage2") !== null) {
                const Page2 = document.getElementById("UploadPage2");
                html2canvas(Page2, { scale: 5 }).then(canvas => {
                    var img = new Image();
                    img.src = window.$APIBaseURL + swatchcardimages[1].src;
                    const imgWidth = 595;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    doc.addPage();
                    doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');


                })
            }
            if (document.getElementById("UploadPage3") !== null) {
                const Page3 = document.getElementById("UploadPage3");
                html2canvas(Page3, { scale: 5 }).then(canvas => {
                    var img = new Image();
                    img.src = window.$APIBaseURL + swatchcardimages[2].src;
                    const imgWidth = 595;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    doc.addPage();
                    doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');


                })
            }
            if (document.getElementById("UploadPage4") !== null) {
                const Page4 = document.getElementById("UploadPage4");
                html2canvas(Page4, { scale: 5 }).then(canvas => {
                    var img = new Image();
                    img.src = window.$APIBaseURL + swatchcardimages[3].src;
                    const imgWidth = 595;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    doc.addPage();
                    doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');


                })
            }

            if (document.getElementById("UploadPage5") !== null) {
                const UploadPage5 = document.getElementById("UploadPage5");
                html2canvas(UploadPage5, { scale: 5 }).then(canvas => {
                    var img = new Image();
                    img.src = window.$APIBaseURL + swatchcardimages[4].src;
                    const imgWidth = 590;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    doc.addPage();
                    doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');


                })
            }

            if (document.getElementById("UploadPage6") !== null) {
                timeout = 10000;
                const UploadPage6 = document.getElementById("UploadPage6");
                html2canvas(UploadPage6, { scale: 5 }).then(canvas => {
                    var img = new Image();
                    img.src = window.$APIBaseURL + swatchcardimages[5].src;
                    const imgWidth = 590;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    doc.addPage();
                    doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');


                })
            }

            if (document.getElementById("UploadPage7") !== null) {
                const UploadPage7 = document.getElementById("UploadPage7");
                html2canvas(UploadPage7, { scale: 5 }).then(canvas => {
                    var img = new Image();
                    img.src = window.$APIBaseURL + swatchcardimages[6].src;
                    const imgWidth = 590;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    doc.addPage();
                    doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');


                })
            }

            if (document.getElementById("UploadPage8") !== null) {
                const UploadPage8 = document.getElementById("UploadPage8");
                html2canvas(UploadPage8, { scale: 5 }).then(canvas => {
                    var img = new Image();
                    img.src = window.$APIBaseURL + swatchcardimages[7].src;
                    const imgWidth = 590;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    doc.addPage();
                    doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');


                })
            }

            if (document.getElementById("UploadPage9") !== null) {
                const UploadPage9 = document.getElementById("UploadPage9");
                html2canvas(UploadPage9, { scale: 5 }).then(canvas => {
                    var img = new Image();
                    img.src = window.$APIBaseURL + swatchcardimages[8].src;
                    const imgWidth = 590;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    doc.addPage();
                    doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');


                })
            }

            if (document.getElementById("UploadPage10") !== null) {
                const UploadPage10 = document.getElementById("UploadPage10");
                html2canvas(UploadPage10, { scale: 5 }).then(canvas => {
                    var img = new Image();
                    img.src = window.$APIBaseURL + swatchcardimages[9].src;
                    const imgWidth = 590;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    doc.addPage();
                    doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');


                })
            }


            setTimeout(() => {

                //  var file = doc.output("blob");
                const file = new File([doc.output("blob")], "filename.pdf", { type: "image/jpg" });
                //  doc.save("LabdipSwatchCard_" + props.LabdibFabricInfoId + ".pdf");
                hideLoader();
                setshowswatchcardimages(false);

                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", "Approved_" + FileName + ".pdf");
                formData.append("Page", "LabdipSwatchCardPdf");
                const res = axios.post(window.$APIBaseURL + "api/file", formData);
                TnaService.UpdateLabdipSwatchStatus(props.LabdibFabricInfoId, 3, values[0].SwatchCardImageName, values[0].SwatchCardIamgePath, 1).then((res) => {
                    if (res.data) {
                        props.LapdipApprovalCallback(true, getOrderinfo, 'upload', res.data.outputResult)
                    }
                })
            }, timeout);

        });
    }

    const downloadSwatchcard = async () => {
        var FileName = values[0].SwatchCardImageName.substr(0, values[0].SwatchCardImageName.lastIndexOf('.'));
        const url = window.$APIBaseURL + 'Images/LabdipSwatchCardPdf/' + FileName + '.pdf'
        //  const url = window.$APIBaseURL + 'Images/LabdipSwatchCardPdf/' + FileName + '.pdf'
        const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())

        const pdfDoc = await PDFDocument.load(existingPdfBytes)
        const timesnewromanbold = await pdfDoc.embedFont(StandardFonts.TimesRomanBold);
        const timesnewroman = await pdfDoc.embedFont(StandardFonts.TimesRoman);
        const HelveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

        const pages = pdfDoc.getPages()
        var ResultText = [];
        if (values[0].Result.includes('with')) {
            ResultText = values[0].Result.split('with')
        }

        if (values[0].CubeorBuyer === 'Cube') {

            for (var i = 0; i < pages.length; i++) {
                pages[i].drawText('Approved on', {
                    x: 36,
                    y: 636,
                    size: 10,
                    font: timesnewromanbold,
                    color: rgb(0, 0, 0),
                })

                pages[i].drawText(" : " + values[0].ApprovalDate, {
                    x: 100,
                    y: 636,
                    size: 10,
                    font: timesnewroman,
                    color: rgb(0, 0, 0),
                })

                pages[i].drawText('Approved by', {
                    x: 170,
                    y: 636,
                    size: 10,
                    font: timesnewromanbold,
                    color: rgb(0, 0, 0),
                })

                pages[i].drawText(": " + values[0].Approvedby, {
                    x: 228,
                    y: 636,
                    size: 10,
                    font: timesnewroman,
                    color: rgb(0, 0, 0),
                })

                pages[i].drawText('Result', {
                    x: 340,
                    y: 636,
                    size: 10,
                    font: timesnewromanbold,
                    color: rgb(0, 0, 0),
                })

                // if (ResultText.length === 0) {
                pages[i].drawText(": " + values[0].Result, {
                    x: 380,
                    y: 636,
                    size: 10,
                    font: timesnewroman,
                    color: rgb(0, 0, 0),
                })
                // }
                // else {

                //     pages[i].drawText(": " + ResultText[0] + " with", {
                //         x: 370,
                //         y: 647,
                //         size: 10,
                //         font: timesnewroman,
                //         color: rgb(0, 0, 0),
                //     })

                //     pages[i].drawText(ResultText[1], {
                //         x: 370,
                //         y: 635,
                //         size: 10,
                //         font: timesnewroman,
                //         color: rgb(0, 0, 0),
                //     })

                // }

                pages[i].drawText(values[0].ApprovalRemark, {
                    x: 152,
                    y: 104,
                    size: 10,
                    font: timesnewroman,
                    color: rgb(0, 0, 0),
                })


                const jpgUrl = window.$APIBaseURL + 'Images/LabdipSwatchCardPdf/BlankSpace.png'
                const jpgImageBytes = await fetch(jpgUrl).then((res) => res.arrayBuffer())

                const jpgImage = await pdfDoc.embedPng(jpgImageBytes)
                const jpgDims = jpgImage.scale(0.5)

                pages[i].drawImage(jpgImage, {
                    x: 223,
                    y: 765,
                    width: 200,
                    height: 21
                })

                pages[i].drawText('LAB DIPS APPROVAL FORM', {
                    x: 225,
                    y: 766,
                    size: 14,
                    font: HelveticaBold,
                    color: rgb(0, 0, 0),
                })
            }
        }
        else {
            for (var i = 0; i < pages.length; i++) {
                pages[i].drawText('Approved on  ', {
                    x: 30,
                    y: 610,
                    size: 11,
                    font: timesnewromanbold,
                    color: rgb(0, 0, 0),
                })

                pages[i].drawText(" : " + values[0].ApprovalDate, {
                    x: 95,
                    y: 610,
                    size: 11,
                    font: timesnewroman,
                    color: rgb(0, 0, 0),
                })

                pages[i].drawText('Approved by ', {
                    x: 160,
                    y: 610,
                    size: 11,
                    font: timesnewromanbold,
                    color: rgb(0, 0, 0),
                })

                pages[i].drawText(": " + values[0].Approvedby, {
                    x: 225,
                    y: 610,
                    size: 11,
                    font: timesnewroman,
                    color: rgb(0, 0, 0),
                })

                pages[i].drawText('Result', {
                    x: 335,
                    y: 610,
                    size: 11,
                    font: timesnewromanbold,
                    color: rgb(0, 0, 0),
                })

                pages[i].drawText(": " + values[0].Result, {
                    x: 370,
                    y: 610,
                    size: 11,
                    font: timesnewroman,
                    color: rgb(0, 0, 0),
                })

                const jpgUrl = window.$APIBaseURL + 'Images/LabdipSwatchCardPdf/BlankSpaceBlack.png'
                const jpgImageBytes = await fetch(jpgUrl).then((res) => res.arrayBuffer())

                const jpgImage = await pdfDoc.embedPng(jpgImageBytes)
                const jpgDims = jpgImage.scale(0.5)

                pages[i].drawImage(jpgImage, {
                    x: 218,
                    y: 788,
                    width: 200,
                    height: 30
                })

                pages[i].drawText('APPROVAL FORM', {
                    x: 218,
                    y: 788,
                    size: 20,
                    font: HelveticaBold,
                    color: rgb(1, 1, 1)
                })

                pages[i].drawText(values[0].ApprovalRemark, {
                    x: 115,
                    y: 35,
                    size: 11,
                    font: timesnewroman,
                    color: rgb(0, 0, 0)
                })
            }
        }

        const pdfBytes = await pdfDoc.save()
        download(pdfBytes, FileName + ".pdf", "application/pdf");
        window.open(url, "_blank");
    }

    const ViewFile = (event, filename) => { // event.preventDefault();
        setIsOpen(true);
        setImagename(filename);
    }

    const openFileUpload = () => {
        $('#upload-button').trigger('click');
    }

    const CustomInputApproval = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
                style={{
                    color:
                        props.value === ""
                            ? "#bfdea1"
                            : "#000",
                    border:
                        submitted &&
                            props.value === ""
                            ? "1px solid red"
                            : "",
                }}
            />
        )
    }

    const CustomInput = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
            />
        )
    }

    return (
        <div id="registration-form">
            <Form>
                {

                    <>
                        <div className="row" style={{ overflowX: "hidden", height: "700px", padding: "1px", margin: "0px" }}>
                            <div>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label htmlFor="BuyerID">
                                                Buyer-Season-Brand

                                            </label>
                                            <span className="input-icon icon-right">
                                                <Input
                                                    placeholder="Buyer-Season-Brand"
                                                    className="form-control"
                                                    title={inputFields[0].BuyerBrandSeasonName}
                                                    name=" Buyer-Season-Brand"
                                                    id={" Buyer-Season-Brand"}
                                                    value={inputFields[0].BuyerBrandSeasonName}
                                                    disabled

                                                ></Input>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label htmlFor="SupplierID">
                                                Supplier
                                                {/* <span className="text-danger">*</span> */}
                                            </label>
                                            <span className="input-icon icon-right">
                                                <Input
                                                    placeholder="Supplier"
                                                    className="form-control"
                                                    title={inputFields[0].SupplierName}
                                                    name="SupplierID"
                                                    id={"SupplierID"}
                                                    value={inputFields[0].SupplierName}
                                                    disabled

                                                ></Input>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label htmlFor="Name">
                                                Submit Date
                                                <span className="text-danger">*</span>
                                            </label>
                                            <span className="input-icon icon-right">
                                                <DatePicker className="form-control" name="SubmittedDate"
                                                    id={
                                                        "SubmittedDate"
                                                    }
                                                    selected={
                                                        inputFields[0].SubmittedDate
                                                    }

                                                    dateFormat="dd/MM/yyyy"
                                                    peekNextMonth

                                                    // customInput={<CustomInput />}
                                                    disabled
                                                />
                                                {/* */}
                                                {/* <Input

                                                    className="form-control"
                                                    title={minApprovalDate}
                                                    name="ApprovalDate"
                                                    id={"ApprovalDate"}
                                                    value={minApprovalDate}
                                                    disabled
                                                ></Input> */}


                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label htmlFor={"SendTo"}>
                                                Approval Date
                                                <span className="text-danger">*</span>
                                            </label>
                                            <span className="input-icon icon-right">
                                                {
                                                    props.TaskStatus === 3 && (props.editLabdipApproval === false || props.editLabdipApproval === undefined) ?
                                                        <DatePicker className="form-control" name="SubmittedDate"
                                                            id={
                                                                "SubmittedDate"
                                                            }
                                                            selected={
                                                                inputFields[0].ApprovalDate
                                                            }

                                                            dateFormat="dd/MM/yyyy"
                                                            peekNextMonth

                                                            customInput={<CustomInputApproval />}
                                                            disabled
                                                        />

                                                        :

                                                        <DatePicker className="form-control" name="ApprovalDate"
                                                            id={
                                                                "ApprovalDate"
                                                            }
                                                            selected={
                                                                inputFields[0].ApprovalDate === '' ? '' : inputFields[0].ApprovalDate

                                                            }
                                                            onChange={event => handleChange(event, 'ApprovalDate')}
                                                            dateFormat="dd/MM/yyyy"
                                                            peekNextMonth

                                                            dropdownMode="scroll"
                                                            autoComplete="off"
                                                            customInput={<CustomInputApproval />}
                                                            isClearable={inputFields[0].ApprovalDate === null ? false : true}
                                                        />









                                                    // <input
                                                    //     id="ApprovalDate"
                                                    //     name="ApprovalDate"
                                                    //     value={inputFields[0].ApprovalDate}
                                                    //     placeholder="DD/MM/YYYY"
                                                    //     type="date"
                                                    //     autoComplete="off"
                                                    //     className={"form-control"}
                                                    //     min={minApprovalDate}
                                                    //     onKeyDown={(e) => e.preventDefault()}
                                                    //     style={{
                                                    //         color:
                                                    //             inputFields[0].ApprovalDate === ""
                                                    //                 ? "#bfdea1"
                                                    //                 : "#000",
                                                    //         border:
                                                    //             submitted &&
                                                    //                 inputFields[0].ApprovalDate === ""
                                                    //                 ? "1px solid red"
                                                    //                 : "",
                                                    //     }}
                                                    //     onChange={event => handleChange(event, 'ApprovalDate')}
                                                    // />

                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">


                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label htmlFor={"StyleName"}>
                                                Style Name
                                                <span className="text-danger">*</span>
                                            </label>
                                            <span className="input-icon icon-right">
                                                <Input
                                                    placeholder="Enter the StyleName"
                                                    className="form-control"
                                                    title={inputFields[0].StyleName}
                                                    name="StyleName"
                                                    id={"StyleName"}
                                                    value={inputFields[0].StyleName}
                                                    disabled
                                                ></Input>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label htmlFor={" StyleNoID"}>
                                                Style No
                                                <span className="text-danger">*</span>
                                            </label>
                                            <span className="input-icon icon-right">
                                                <Input
                                                    placeholder="Enter the  StyleNo"
                                                    className="form-control"
                                                    title={inputFields[0].StyleNo}
                                                    name=" StyleNo"
                                                    id={" StyleNo"}
                                                    value={inputFields[0].StyleNo}
                                                    disabled
                                                ></Input>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label htmlFor={"ID/PO No"}>
                                                PO/ID No
                                                <span className="text-danger">*</span>
                                            </label>
                                            <span className="input-icon icon-right">
                                                <Input
                                                    placeholder="Enter the PO/ID No"
                                                    className="form-control"
                                                    title={inputFields[0].IdpoNo}
                                                    name="ID/PO No"
                                                    id={"ID/PO No"}
                                                    value={inputFields[0].IdpoNo}
                                                    disabled

                                                ></Input>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label htmlFor={"Endcustomer"}>
                                                End Customer
                                                <span className="text-danger">*</span>
                                            </label>
                                            <span className="input-icon icon-right">
                                                <Input
                                                    placeholder="Enter the End Customer"
                                                    className="form-control"
                                                    title={inputFields[0].EndCustomer}
                                                    name="End Customer No"
                                                    id={"End Customer"}
                                                    value={inputFields[0].EndCustomer}
                                                    disabled

                                                ></Input>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <div className='form-group'>
                                            <label htmlFor="Awbdate" >Courier Information</label><span className="text-danger">*</span>
                                            <span className='input-icon icon-right'>
                                                <Input
                                                    placeholder="Submit Sent Details"
                                                    className="form-control"
                                                    title={inputFields[0].SubmitSentDetails}
                                                    name="SubmitSentDetails"
                                                    id={
                                                        "SubmitSentDetails"
                                                    }
                                                    value={inputFields[0].SubmitSentDetails}
                                                    disabled
                                                ></Input>
                                            </span>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="tabbable">
                                        <ul
                                            className="nav nav-tabs nav-justified"
                                            id="myTab5"
                                        >
                                            <li
                                                className="active">
                                                <a
                                                    data-toggle="tab"
                                                    href="#idSubmitInformation"
                                                >
                                                    <h5>Submit Information</h5>
                                                </a>
                                            </li>

                                            <li
                                                className=""
                                            >
                                                <a
                                                    data-toggle="tab"
                                                    href="#idOrderInformation"
                                                >
                                                    <h5>Order Information</h5>
                                                </a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div id="idSubmitInformation" className="tab-pane in active" style={{ width: "100%" }}>
                                                <div className="row">
                                                    <div className="col-sm-10">
                                                        <div className="row">
                                                            <div className="col-sm-3">
                                                                <div className='form-group'>
                                                                    {
                                                                        inputFields[0].PantoneTypeName !== "Others" ?
                                                                            <>
                                                                                <label htmlFor="BuyerID">Pantone #</label>
                                                                                <span className='input-icon icon-right'>
                                                                                    <input
                                                                                        placeholder="Pantone #"
                                                                                        className="form-control"

                                                                                        name="PantoneCodeandType"
                                                                                        id={
                                                                                            "PantoneCodeandType"
                                                                                        }
                                                                                        value={
                                                                                            inputFields[0].PantoneTypeName + '-' + inputFields[0].PantoneCode + "-" + inputFields[0].PantoneNameOrSwatchName
                                                                                        }
                                                                                        title={
                                                                                            inputFields[0].PantoneTypeName + '-' + inputFields[0].PantoneCode + "-" + inputFields[0].PantoneNameOrSwatchName
                                                                                        }
                                                                                        disabled

                                                                                    ></input>
                                                                                </span>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <label htmlFor="SwatchName">Swatch Name</label>
                                                                                <span className='input-icon icon-right'>
                                                                                    <input
                                                                                        placeholder="Swatch Name"
                                                                                        className="form-control"
                                                                                        title={inputFields[0].PantoneNameOrSwatchName}
                                                                                        name="SwatchName"
                                                                                        id={
                                                                                            "SwatchName"
                                                                                        }
                                                                                        value={
                                                                                            inputFields[0].PantoneNameOrSwatchName
                                                                                        }
                                                                                        
                                                                                        disabled

                                                                                    ></input>
                                                                                </span>
                                                                            </>
                                                                    }

                                                                </div>
                                                            </div>

                                                            <div className="col-sm-3">
                                                                <div className='form-group'>
                                                                    <label htmlFor="SeasonID" >Ref. Color Name</label>
                                                                    <span className='input-icon icon-right'>
                                                                        <Input
                                                                            placeholder="Ref. Color Name"
                                                                            className="form-control"
                                                                            title={inputFields[0].RefColorName}
                                                                            name="RefColorName"
                                                                            id={
                                                                                "RefColorName"
                                                                            }
                                                                            value={
                                                                                inputFields[0].RefColorName
                                                                            }
                                                                            disabled
                                                                        ></Input>
                                                                    </span>
                                                                </div>
                                                            </div>


                                                            <div className="col-sm-3">
                                                                <div className="form-group">
                                                                    {
                                                                        inputFields[0].PantoneTypeName !== "Others" ?
                                                                            <>
                                                                                <label htmlFor={"Quarter"}>Color / Image</label>
                                                                                {
                                                                                    inputFields[0].PantoneColorOrOrSwatchImage !== '' ?
                                                                                        <div style={{ background: inputFields[0].PantoneColorOrOrSwatchImage, width: '100%', height: '34px', borderRadius: '2px' }}></div>

                                                                                        :
                                                                                        <div style={{ width: '100%', height: '34px', borderRadius: '2px' }}></div>
                                                                                }
                                                                            </> :
                                                                            <>
                                                                                <img src={inputFields[0].PantoneColorOrOrSwatchImage === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + inputFields[0].PantoneColorOrOrSwatchImage}
                                                                                    alt="Picture" style={{ marginBottom: '5px', height: '100px', width: '50%', border: "1px solid #d5d5d5" }}

                                                                                />
                                                                            </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <div className="form-group">
                                                                    <label htmlFor={"FabricID"}>

                                                                        Fabric Details<span className="text-danger">*</span></label>

                                                                    <span className="input-icon icon-right">
                                                                        <select id={"FabricID"} name="FabricID" className="form-select"
                                                                            value={inputFields[0].FabricID}
                                                                            
                                                                            disabled
                                                                        >
                                                                            <option value="0">-Select Fabric-</option>
                                                                            {
                                                                                FabricDetails.map(item => (
                                                                                    <option key={item.id} value={item.value} >
                                                                                        {item.label}
                                                                                    </option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </span>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-lg-12">
                                                            <div className="row">
                                                                <div className="col-sm-8 col-lg-8" style={{ paddingLeft: "0" }}>
                                                                    <div className='form-group'>
                                                                        <label htmlFor="Remarks">Submitted Remarks</label>
                                                                        <textarea maxLength="300" name="Remarks" value={inputFields[0].Remarks}
                                                                            rows="4" style={{ width: "100%" }}
                                                                            disabled
                                                                        ></textarea>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>


                                                        <div >
                                                            <table>
                                                                <tr>
                                                                    <td><label>Submit Options</label> </td>
                                                                    <td><label>Approval Submission Card
                                                                        <br></br>(Click to download)</label></td>
                                                                </tr>
                                                                <tr>
                                                                    <td> <div className="col-sm-6" style={{ paddingLeft: "0" }}>
                                                                        <div className="form-group">


                                                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup"
                                                                                style={{ width: "700px" }}>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th >Options <span className="text-danger">*</span></th>
                                                                                        <th>Supplier Reference No <span className="text-danger">*</span></th>
                                                                                        <th>Approved Option <span className="text-danger">*</span></th>

                                                                                    </tr>

                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        inputFields[0].TNALabdibSubmittedOption && (
                                                                                            inputFields[0].TNALabdibSubmittedOption.map((item, index) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <span className="input-icon icon-right">
                                                                                                                    <Input
                                                                                                                        placeholder="Option Name"
                                                                                                                        className="form-control"
                                                                                                                        title={item.optionName}
                                                                                                                        name="OptionName"
                                                                                                                        id={
                                                                                                                            "OptionName"
                                                                                                                        }
                                                                                                                        value={
                                                                                                                            item.optionName
                                                                                                                        }
                                                                                                                        disabled
                                                                                                                    ></Input>


                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <span className='input-icon icon-right'>
                                                                                                                    <Input
                                                                                                                        placeholder=""
                                                                                                                        className="form-control"
                                                                                                                        title={item.supplierReferenceNo}
                                                                                                                        name="Sendto"
                                                                                                                        id={
                                                                                                                            "Sendto"
                                                                                                                        }
                                                                                                                        value={
                                                                                                                            item.supplierReferenceNo
                                                                                                                        }
                                                                                                                        disabled
                                                                                                                    ></Input>
                                                                                                                </span>

                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <span className='input-icon icon-right'>
                                                                                                                    {/* <select id={"ApprovedOption"} name="ApprovedOption" className="form-select"
                                                                                                                                                    value={item.approvedOptionName}
                                                                                                                                                    onChange={event => handleSubmitoptionchange(event, 'ApprovedOption', index)}
                                                                                                                                                    style={{ border: submitted && item.approvedOptionName === '0' ? '1px solid red' : '' }}>
                                                                                                                                                    <option value="0" key="0" style={{ color: '#bfdea1' }}> - Select Approved Option- </option>
                                                                                                                                                    <option value="1" key="1" style={{ color: item.approvedOptionName === 3 ? '#c40404' : '#00b050' }}>Approved</option>
                                                                                                                                                    <option value="2" key="2" style={{ color: item.approvedOptionName === 3 ? '#c40404' : '#fca404' }}>Approved with Comments</option>
                                                                                                                                                    <option value="3" key="3" style={{ color: item.approvedOptionName === 3 ? '#c40404' : '#c40404' }}>All Rejected</option>
                                                                                                                                                    <option value="4" key="4" style={{ color: item.approvedOptionName === 3 ? '#c40404' : '#fcfc04' }}>All Hold</option>

                                                                                                                                                </select> */}

                                                                                                                    <Reactselect className="basic-single" name="TaskHolderOrFollower"
                                                                                                                        id={
                                                                                                                            "TaskHolderOrFollower"
                                                                                                                        }
                                                                                                                        isLoading={false}
                                                                                                                        isClearable={true}
                                                                                                                        isSearchable={false}
                                                                                                                        onChange={event => handleSubmitoptionchange(event, 'ApprovedOption', index)}
                                                                                                                        value={getApprovedOptions.filter(function (option) {
                                                                                                                            return option.value === item.approvedOptionName;
                                                                                                                        })}
                                                                                                                        options={getApprovedOptions}
                                                                                                                        styles={
                                                                                                                            submitted && item.approvedOptionName === '0' ? styles2 : styles1
                                                                                                                        }
                                                                                                                        menuPosition="fixed"
                                                                                                                        // disabled={props.TaskStatus === 3 ? true : false}
                                                                                                                        isDisabled={props.TaskStatus === 3 && (props.editLabdipApproval === false || props.editLabdipApproval === undefined)}

                                                                                                                    ></Reactselect>
                                                                                                                </span>

                                                                                                            </td>

                                                                                                        </tr>
                                                                                                    </>

                                                                                                )
                                                                                            }))
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div></td>
                                                                    <td>

                                                                        <div className={ImgClassName}
                                                                            htmlFor="upload-button"
                                                                        >

                                                                            {
                                                                                props.SwatchStatus === 3 ?
                                                                                    <img
                                                                                        src={inputFields[0].SwatchCardIamgePath === "" || inputFields[0].SwatchCardIamgePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images/LabdipSwatchCardPdf/" + inputFields[0].SwatchCardImageName}
                                                                                        onClick={downloadSwatchcard}
                                                                                        alt="AltPicture" style={{ marginBottom: '5px', height: '200px', width: '200px', border: "1px solid #d5d5d5" }}
                                                                                    />
                                                                                    :
                                                                                    <>
                                                                                        <img
                                                                                            src={"assets/img/emptyImage.jpg"}
                                                                                            // src={"assets/img/emptyImage.jpg"}
                                                                                            onClick={openFileUpload}
                                                                                            alt="AltPicture" style={{ marginBottom: '5px', height: '200px', width: '200px', border: "1px solid #d5d5d5" }} />
                                                                                        <input type="file" id="upload-button" accept=".jpg,.jpeg,.png" onChange={(event) => UploadImages(event)} value=''
                                                                                            disabled={(props.LabdipswatchUpload === true) ? false : true}
                                                                                            multiple
                                                                                        //disabled={props.TaskStatus === 3 && (props.editLabdipSubmission === false || chkDataExists === true) ? true : false}
                                                                                        />
                                                                                    </>

                                                                            }

                                                                            {
                                                                                inputFields.SwatchImageFileName !== "assets/img/uploadimage.jpg" && (
                                                                                    <div style={
                                                                                        {
                                                                                            marginTop: '8px',
                                                                                            float: 'right',
                                                                                            bottom: '10px',
                                                                                            position: 'relative',
                                                                                        }
                                                                                    }>


                                                                                    </div>
                                                                                )
                                                                            }

                                                                        </div>
                                                                    </td>

                                                                </tr>
                                                            </table>




                                                            <div> {
                                                                isOpen && (

                                                                    <Lightbox mainSrc={window.$APIBaseURL + getImagename}
                                                                        alt="bg image"
                                                                        onCloseRequest={
                                                                            () => setIsOpen(false)
                                                                        } />
                                                                )
                                                            } </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="idOrderInformation" className="tab-pane" style={{ width: "100%" }}>

                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="dataTables_wrapper no-footer" style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                                                            {
                                                                getOrderinfo.length !== 0 ?

                                                                    <table className="table table-bordered">
                                                                        <TableHeader headers={headers} />
                                                                        <tbody>
                                                                            {

                                                                                getOrderinfo.map((item, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <tr>
                                                                                                <td>{item.styleName}</td>
                                                                                                <td>{item.styleNo}</td>
                                                                                                <td>{item.idpoNo}</td>
                                                                                                <td>{item.fabricDetails}</td>
                                                                                                <td>{item.taskOwnerNameList}</td>

                                                                                                {/* <td className="Actionbutton fixed-column">
                                                                                                    <div style={{ width: "80px" }}>
                                                                                                        <label style={{ marginTop: '8px' }}>
                                                                                                            <input type="checkbox" name="SelectedDetail"
                                                                                                                disabled={props.TaskStatus === 3 ? true : false}
                                                                                                                onChange={event => (OnChangeOrderinfo(event, index))}
                                                                                                                checked={item.selectedDetail === 1 ? true : false}
                                                                                                                className="colored-blue clearOpacity" />
                                                                                                            <span className="text"></span>
                                                                                                        </label>&nbsp;&nbsp;&nbsp;
                                                                                                        {props.commonParams.SelectedDependency && getSelectedDependencyMap(props.commonParams.SelectedDependency) === false &&

                                                                                                            <button type="button" className={item.dependencyStartstop === 1 ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Dependency)" >
                                                                                                                <i className={item.dependencyStartstop === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>
                                                                                                            </button>
                                                                                                        }

                                                                                                        &nbsp;&nbsp;

                                                                                                        <button
                                                                                                            disabled={props.TaskStatus === 3 ? true : false}
                                                                                                            type="button"
                                                                                                            className="btn  btn-xs  btn-danger"
                                                                                                            title="Delete"
                                                                                                            onClick={() =>
                                                                                                                handleRemoveOrderInfo(index)
                                                                                                            }
                                                                                                        >
                                                                                                            <i className="fa fa-trash-o"></i>
                                                                                                        </button>


                                                                                                    </div>

                                                                                                </td> */}
                                                                                            </tr>
                                                                                        </>

                                                                                    )
                                                                                })
                                                                            }

                                                                        </tbody>
                                                                    </table>
                                                                    :
                                                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                                        <TableHeader headers={headers} />
                                                                        <tbody>
                                                                            <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                                                                        </tbody>
                                                                    </table>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />


                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>


                            <div className="mt-10">
                                <div className="row">
                                    <div className="col-sm-8 col-lg-8">
                                        <div className='form-group'>
                                            <label htmlFor="Remarks">Remarks</label>
                                            <textarea maxLength="300" name="Remarks" value={inputFields[0].ApprovalRemark}
                                                onChange={event => handleChange(event, 'Remarks')}
                                                rows="4" style={{ width: "110%" }}
                                                disabled={props.TaskStatus === 3 && props.editLabdipApproval === false ? true : false}
                                            ></textarea>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12" align="right">
                                <button type="button" className="btn btn-success margin-Align" onClick={() => SaveApproval()}
                                    disabled={props.TaskStatus === 3 && (props.editLabdipApproval === false || props.editLabdipApproval === undefined) || buttonLoader ? true : false}
                                >
                                    <i className="fa fa-check right"></i> &nbsp;{props.editLabdipApproval === false || props.editLabdipApproval === undefined ? 'Save' : 'Update'}
                                </button>
                            </div>
                        </div>
                    </>
                }


                {
                    getID.showPopupDelete ? <SweetAlertPopup data={
                        getID.Params
                    }
                        deleteCallback={Delcallback}
                        showpopup={true}
                        pageActionId={LabdipSubmissionApprovalId}
                        Msg={"Lab Dip Approval Deleted successfully."} /> : null
                }

                {
                    showswatchcardimages === true &&
                    swatchcardimages.map((images, index) => {
                        return (
                            <>
                                <div id={"UploadPage" + (index + 1)} style={{ width: '950px', height: '1350px' }}>
                                    <img
                                        src={window.$APIBaseURL + images.src}
                                        alt="No Data" />
                                </div>
                            </>
                        )
                    })
                }



                {loader}
            </Form>
        </div>
    );
};

export default LabdibApproval;
