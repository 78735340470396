import React, { useState, useEffect, Fragment } from 'react'
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Reactselect from 'react-select';
import FinalinspecUpload from "../../Common/FinalinspecUpload";
import axios from "axios";
import ReportService from "../../../services/Report/ReportService";
import $ from "jquery";
import Nodify from "../../Common/ReactNotification";
import Lightbox from "react-image-lightbox"
import QcInspection from '../../../services/QCInspection/QcInspection';
import { Label } from "reactstrap";
import FinalinspectionService from "../../../services/FinalInspection/FinalinspectionService";
import QACommentPointService from '../../../services/Master/QACommentPointService';
import Form from "react-validation/build/form";
import DatePicker from "react-datepicker";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
const AddQCReportTab = ({ props, basicDetail, setrerenderstate, Qunatitylist, commentList, FileuploadList, QcConclusionList, IsvalidationCallback, rerenderdep, QCDpvalidation, MainInputCallback, SkuImageCallback }) => {
    const [gettolerance, settolerance] = useState(0);
    const [getInspectionQtyInfo, setInspectionQtyInfo] = useState([]);
    const [getTotalValues, setTotalValues] = useState({ PoQty: 0, Prodqty: 0 });
    const [getFinalInspectionPopup, setFinalInspectionPopup] = useState(false);
    const EmptyInputFields = { CommentID: 0, Comment: '' };
    const [CheckedAreaDroplist, setCheckedAreaDroplist] = useState([])
    const [ExistingList, setExistingList] = useState([]);
    const [getCurrimageindex, setCurrimageindex] = useState(0);
    const [inputFields, setInputFields] = useState([EmptyInputFields]);
    const [MultiCloseDatas, setMultiCloseDatas] = useState([]);
    const [selectall, setselectall] = useState(false);
    const [FactoryDroplist, setFactoryDroplist] = useState([])
    const [getDefect, setDefect] = useState([{ Id: 0, Name: "", }]);
    const [getnewimagevalue, setnewimagevalue] = useState([]);
    const [getDefectPopup, setDefectPopup] = useState(false);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [getCommentsList, setCommentsList] = useState([]);
    const [TestReportExpandState, setTestReportExpandState] = useState({});
    const [TestReportexpandedRows, setTestReportExpandedRows] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [submittedQA, setSubmittedQA] = useState(false);
    const [submittedQCDp, setSubmittedQCDp] = useState(false);
    const [getFiles, setFiles] = useState([]);
    const [isOpenimg, setIsOpenimg] = useState(false);
    const [getImagename, setImagename] = useState();
    const actiondropdown = [{ value: 1, label: 'Yes' },]
    const [dropDownlist, setDropdownlist] = useState([])
    const [CommentDroplist, setCommentDroplist] = useState([])
    const [isOpen, setIsOpen] = useState(false);
    const [getExFactoryList, setExFactoryList] = useState([])
    const [getImagenameupload, setImagenameupload] = useState();
    const [getQcremarksdrpdwn, setQcremarksdrpdwn] = useState({ QARemarks: '', QcDropdownID: 0, QCDropDownn: '' });
    const [getInspectionInfo, setInspectionInfo] = useState([]);
    const Commentvalue = [...getCommentsList];
    const { user: currentUser } = useSelector((state) => state.auth);
    const [getMainInputField, setMainInputField] = useState({ FactoryLocationID: 0, FactoryMerchID: '', FactoryMerchForEdit: '', ReportDate: '', CheckedAreaID: 0, arrcheckAreaID: [], CheckedAreaidList: '', CheckedAreaforedit: '', Savetype: 1 })
    const reducerState = useSelector((state) => state);
    const values = { ...getMainInputField }
    useEffect(() => {
        setSubmitted(IsvalidationCallback);
    }, [IsvalidationCallback === true]);
    useEffect(() => {
        setSubmittedQCDp(QCDpvalidation);
    }, [QCDpvalidation === true]);

    const QcinpsectionId = JSON.parse(localStorage.getItem("QCInspectionID"));

    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                let Exfaclist = [];
                let Inputfilds = [];
                // Fetch initial QACommentPointList data
                showLoader();
                if (props.location.state.params.TaskStatus) {
                    let FactoryMerchList = [];
                    let CheckedAreaList = [];

                    try {
                        let res = await QcInspection.getCommonResultList(1, props.location.state.params.details.supplierId);
                        if (res.data.checkedAreaList) {
                            CheckedAreaList = res.data.checkedAreaList.map((element) => ({
                                value: element.checkResultId,
                                label: element.checkResultName
                            }));
                        }
                        if (res.data.factoryMerchList) {
                            FactoryMerchList = res.data.factoryMerchList.map((element) => ({
                                value: element.employeeID,
                                label: element.employeeName
                            }));
                        }
                        setCheckedAreaDroplist(CheckedAreaList);
                        setFactoryDroplist(FactoryMerchList);
                    } catch (error) {
                        console.error('Error fetching common result list:', error);
                        hideLoader();
                    }

                    try {
                        let res = await ReportService.GetCommentsReportExFactory(props.location.state.params.details.supplierId, 1, 0);
                        if (res.data) {
                            Exfaclist = res.data.exFacCommon.map((x) => ({
                                value: x.value,
                                label: x.label
                            }));
                            setExFactoryList(Exfaclist);
                        }
                    } catch (error) {
                        console.error('Error fetching comments report:', error);
                        hideLoader();
                    }

                    if (QcinpsectionId !== null || props.location.state.params.TNAQCInspectionID !== undefined) {
                        try {
                            let res = await QcInspection.getAllQcInfoList(1, props.location.state.params.details.tnaid, props.location.state.params.TaskStatus === 1 ? QcinpsectionId : props.location.state.params.TNAQCInspectionID);
                            debugger
                            if (res.data.qCinspectionMainDetails.length > 0) {
                                res.data.qCinspectionMainDetails.forEach((x) => {
                                    values.FactoryLocationID = x.factoryLocationID;
                                    values.FactoryMerchID = x.factoryMerchID;
                                    values.CheckedAreaidList = x.checkedAreaID;
                                    let reportDate = new Date(x.reportDate);
                                    if (isNaN(reportDate.getTime())) {
                                        console.error('Invalid Date:', x.reportDate);
                                    } else {
                                        values.ReportDate = reportDate;
                                    }

                                    values.FactoryMerchForEdit = FactoryMerchList.filter((data) => x.factoryMerchID.split(",").includes(data.value.toString()));
                                    // values.CheckedAreaID = x.checkedAreaID;
                                    values.CheckedAreaforedit = CheckedAreaList.filter((data) => x.checkedAreaID.split(",").includes(data.value.toString()));
                                    values.Savetype = x.savetype
                                });
                                setMainInputField(values);
                                MainInputCallback(values)
                            }

                            if (res.data.qCinspectionSkuDetails.length > 0) {
                                let newvalueSku = res.data.qCinspectionSkuDetails.map((x) => ({
                                    TNAQCInspectionSkuID: x.tnaqcInspectionSkuID,
                                    skuName: x.skuFileName,
                                    skuFrontPicPath: x.skuFilePath
                                }));
                                setnewimagevalue(newvalueSku);
                                SkuImageCallback(newvalueSku);
                            }
                        } catch (error) {
                            console.error('Error fetching QC info list:', error);
                            hideLoader();
                        }
                    } else {
                        MainInputCallback(values)
                    }
                }

                if (QcinpsectionId === null && props.location.state.params.TaskStatus === 1) {
                    let Multiselecteddate = props.location.state.params.multiselecteddatas.filter(x => x.isSelected === 1);
                    setCurrimageindex(0);
                    var uniqueimages = [...new Map(Multiselecteddate.map(item => [item['skuName'], item])).values()];
                    setnewimagevalue(uniqueimages);
                    SkuImageCallback(uniqueimages);
                }

                const qaResponse = await QACommentPointService.GetQACommentPointList();
                if (qaResponse.data) {
                    setExistingList(qaResponse.data);
                }

                const taskStatus = props.location.state.params.TaskStatus;
                const tnaid = props.location.state.params.details.tnaid;
                const qcInspectionID = taskStatus === 1 ? QcinpsectionId : props.location.state.params.TNAQCInspectionID;

                if (qcInspectionID !== null) {
                    // Fetch QC Inspection Main Details
                    const qcMainResponse = await QcInspection.getAllQcInfoList(1, tnaid, qcInspectionID);
                    if (qcMainResponse.data?.qCinspectionMainDetails?.length > 0) {
                        const remarksData = qcMainResponse.data.qCinspectionMainDetails.map((x) => ({
                            QARemarks: x.qcRemarks,
                            QcDropdownID: x.qcConclusionID,
                        }))[0];
                        setQcremarksdrpdwn(remarksData);
                        QcConclusionList(remarksData);
                    }

                    // Fetch QC Inspection SKU Quality Details
                    const qcSkuResponse = await QcInspection.getAllQcInfoList(2, tnaid, qcInspectionID);
                    if (qcSkuResponse.data?.qCinspectionSkuQualityDetails?.length > 0) {
                        const skuQualityDetails = qcSkuResponse.data.qCinspectionSkuQualityDetails.map((x) => ({
                            TNAQCinspcQualityinfoID: x.tnaqCinspcQualityinfoID,
                            skuID: x.skuID,
                            purchaseOrderSkuID: x.purchaseOrderSkuID,
                            styleNo: x.styleNo,
                            tnaExFactoryId: x.subTaskID,
                            skuName: x.skuName,
                            endCustomer: x.endCustomer,
                            idNo: x.idno,
                            isSelected: 1,
                            orderQuantity: x.poQty,
                            IsDeleted: 0,
                            prodqty: x.productQty,
                            refInspecSKUID: x.skuID,
                            styleProgramID: x.styleProgramID,
                        }));
                        Qunatitylist(skuQualityDetails);
                        setInspectionQtyInfo(skuQualityDetails);
                        MainInfoCommonCalculation(skuQualityDetails);
                    }

                    // Fetch and handle QC Comments
                    const TestReportSaveData = qcSkuResponse.data.qcCommentsDetails?.map((x, i) => ({
                        TNAQCInspecCommentID: x.tnaqcInspecCommentID,
                        CommnetsID: x.commnetsID,
                        ResultID: x.resultID,
                        CommentMainIndex: x.commentMainIndex,
                        Remarks: x.remarks,
                        IsDeleted: 0,
                        ImagesList: qcSkuResponse.data.qcCommentsSubDetails.filter(nex => nex.mainindex === x.commentMainIndex).map((y) => ({
                            TNAQCInspeCommentsSubID: y.tnaqcInspeCommentsSubID,
                            UploadedImagePath1: y.uploadedImagePath1,
                            UploadedImagePath2: y.uploadedImagePath2,
                            MainIndex: i,
                            IsDeleted: 0,
                        })),
                    })) || [{
                        TNAQCInspecCommentID: 0,
                        CommnetsID: 0,
                        ResultID: 0,
                        CommentMainIndex: 0,
                        IsDeleted: 0,
                        Remarks: '',
                        ImagesList: [{
                            TNAQCInspeCommentsSubID: 0,
                            UploadedImagePath1: "",
                            UploadedImagePath2: "",
                            MainIndex: 0,
                            IsDeleted: 0,
                        }],
                    }];
                    commentList(TestReportSaveData);
                    setCommentsList(TestReportSaveData);

                    // Fetch and handle QC File Upload Details
                    if (qcSkuResponse.data.qcFileuploadDetails?.length > 0) {
                        const FileList = qcSkuResponse.data.qcFileuploadDetails.map((x) => ({
                            QCReportFileID: x.tnaQcInspectionFileupID,
                            FileName: x.fileName,
                            FilePath: x.filepath,
                            FileType: x.fileType,
                            IsDeleted: 0,
                        }));
                        setFiles(FileList);
                        FileuploadList(FileList);
                    }
                    hideLoader();
                } else {
                    // Handle case when QcinpsectionId is null
                    const defaultCommentsData = [{
                        TNAQCInspecCommentID: 0,
                        CommnetsID: 0,
                        ResultID: 0,
                        CommentMainIndex: 0,
                        IsDeleted: 0,
                        ImagesList: [{
                            TNAQCInspeCommentsSubID: 0,
                            UploadedImagePath1: "",
                            UploadedImagePath2: "",
                            MainIndex: 0,
                            IsDeleted: 0,
                        }],
                    }];
                    commentList(defaultCommentsData);
                    setCommentsList(defaultCommentsData);

                    const selectedData = props.location.state.params.multiselecteddatas.filter(x => x.isSelected === 1);
                    setInspectionQtyInfo(selectedData);
                    Qunatitylist(selectedData);
                    MainInfoCommonCalculation(selectedData);
                    QcConclusionList(getQcremarksdrpdwn);
                    hideLoader();
                }

                // Common Dropdown and Comment Points Fetch
                const commonResultResponse = await QcInspection.getCommonResultList(1, props.location.state.params.details.supplierId);
                if (commonResultResponse.data) {
                    const CommonResult = commonResultResponse.data.qcDropDownListDetails.map((element) => ({
                        value: element.resultID,
                        label: element.resultName,
                    }));
                    const Commentlist = commonResultResponse.data.commentPointList.map((element) => ({
                        value: element.value,
                        label: element.label,
                    }));
                    setDropdownlist(CommonResult);
                    setCommentDroplist(Commentlist);
                }

                // Final Inspection Testing Report Fetch
                const finalInspectionResponse = await FinalinspectionService.GetTNAFinalinspecTestingReportList(4, props.location.state.params.buyerID, props.location.state.params.brandID);
                if (finalInspectionResponse.data.length > 0) {
                    settolerance(finalInspectionResponse.data[0].offerQty);
                }
                hideLoader();
            } catch (error) {
                console.error("Error fetching data:", error);
                hideLoader();
            }
        };

        fetchInitialData();
    }, [rerenderdep]);

    const previmage = () => {
        if (getCurrimageindex === 0) {
            setCurrimageindex(0);
        }
        else {
            setCurrimageindex(getCurrimageindex - 1);
        }
    }

    const nextimage = () => {
        if (getCurrimageindex === getnewimagevalue.length - 1) {
            setCurrimageindex(getnewimagevalue.length - 1);
        }
        else {
            setCurrimageindex(getCurrimageindex + 1);
        }
    }

    const CustomInput = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
                style={{ border: submitted && props.value === '' ? '1px solid red' : '', backgroundColor: 'white' }}
            />
        )
    }
    function handleOfferQty(e, index) {
        let isvalid = false;
        const values = [...getInspectionQtyInfo]
        let inputText = ""
        const regexNumber = /^[0-9\b]+$/;
        if (e.target.value.trim() !== '') {
            if (e.target.value.match(regexNumber)) {
                inputText = e.target.value;
            } else {
                inputText = '';
            }
        }
        let balqty = parseInt(values[index].orderQuantity) + Math.round(parseInt(values[index].orderQuantity) * gettolerance / 100);
        if (parseInt(inputText) <= balqty) {
            values[index].prodqty = parseInt(inputText);
        }
        else {
            values[index].prodqty = '';
            if (inputText !== '') {
                isvalid = true;
            }
        }
        if (isvalid) {
            Nodify('Warning!', 'warning', 'offer qty cannot exceed ' + balqty);
        }
        setInspectionQtyInfo(values)
        MainInfoCommonCalculation(values);
        Qunatitylist(values);
    }

    function RemoveQuantity(e, index, purchaseOrderSkuID, comment) {

        let multiselecteddatas = [];
        const values = [...getInspectionQtyInfo]
        props.location.state.params.multiselecteddatas.filter(x => parseInt(x.purchaseOrderSkuID) === parseInt(purchaseOrderSkuID)).map(element => {
            element.isSelected = 0;
        })
        values[index].IsDeleted = 1;
        multiselecteddatas = values.filter(x => x.isSelected === 1)
        let sizeandqtyfordisplay = "";
        let NewArr = [];
        multiselecteddatas.map((Purchaseorder) => {
            if (sizeandqtyfordisplay === '') {
                sizeandqtyfordisplay = Purchaseorder.purchaseOrderSkuID;
            } else {
                sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.purchaseOrderSkuID
            }
        })
        props.location.state.params.PurchaseOrderSkuID = sizeandqtyfordisplay;
        values.splice(index, 1);
        setInspectionQtyInfo(values)
        MainInfoCommonCalculation(values)
        Qunatitylist(values)
        setrerenderstate(props.location.state.params.multiselecteddatas.filter(x => x.isSelected === 1).length);
    }

    function OpenPopup() {
        debugger
        setselectall(false)
        // const TNAAddFinalInspectionList = reducerState.tna.tnaAddfinalInspection !== undefined ? reducerState.tna.tnaAddfinalInspection : [];
        const Multiselected = [];
        if (props.location.state.params.TaskStatus === 1) {
            Multiselected = basicDetail.multiselecteddatas.filter(x => x.isSelected === 0)
        } else {
            const strValue1 = getInspectionQtyInfo.filter(x => x.isSelected === 1).map(option => option.tnaExFactoryId).join(',');
            Multiselected = basicDetail.multiselecteddatas.filter(x => !strValue1.includes(x.tnaExFactoryId));
        }
        Multiselected.map((ms, index) => {
            ms.prodqty = '';
        })
        setMultiCloseDatas(Multiselected)
        setFinalInspectionPopup(true)
    }

    function handleChange(e, fieldName) {

        let InputText = '';
        setSubmitted(false);
        const values = { ...getQcremarksdrpdwn };
        if (fieldName === 'QCRemarks') {
            if (e.target.value.trim() !== '') {
                InputText = e.target.value;
            }
            values.QARemarks = InputText;
        } else {
            if (e !== null) {
                values.QcDropdownID = e.value;
                values.QCDropDownn = e.label;
            } else {
                values.QcDropdownID = 0;
                values.QCDropDownn = '';
            }
        }
        setQcremarksdrpdwn(values)
        QcConclusionList(values)
    }
    function handleMainChange(e, FieldName) {

        if (FieldName === "Factory") {
            if (e !== null) {
                values.FactoryLocationID = e.value;
            } else {
                values.FactoryLocationID = 0;
            }
        } else if (FieldName === "FactoryMerch") {
            if (e.length === 0) {
                values.FactoryMerchID = "";
                values.FactoryMerchForEdit = '';

            } else {
                let strValue = "";
                for (let Sku of e) {
                    if (strValue === "") {
                        strValue = Sku.value.toString();
                    } else {
                        strValue = strValue + "," + Sku.value;
                    }
                }
                values.FactoryMerchID = strValue;
                values.FactoryMerchForEdit = e;
            }
        }
        else if (FieldName === "ReportDate") {
            if (e && e !== '') {
                values.ReportDate = new Date(e);
            } else {
                values.ReportDate = '';
            }
        }
        //  else {
        //     if (e !== null) {
        //         values.CheckedAreaID = e.value;
        //     } else {
        //         values.CheckedAreaID = 0;
        //     }
        // }
        else if (FieldName === 'CheckedAreaidList') {
            let strValue = "";
            for (let Sku of e) {
                if (strValue === "") {
                    strValue = Sku.value.toString();
                } else {
                    strValue = strValue + "," + Sku.value;
                }
            }
            values.CheckedAreaidList = strValue;
            values.CheckedAreaforedit = e;

        }
        setMainInputField(values);
        MainInputCallback(values)
    }
    const filevals = [...getFiles]
    const AppendFiles = async e => {
        const file = e.target.files[0];
        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword', "application/vnd.ms-excel",
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#FileUpload').val("");
            }
            else {
                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "QcFinalinspection");
                try {
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                    filevals.push({
                        QCReportFileID: 0,
                        FileName: res.data + '-' + file.name,
                        FilePath: "Images/QcFinalinspection/" + res.data + '_' + file.name,
                        IsDeleted: 0,
                        FileType: file.type
                    })
                    setFiles(filevals);
                    FileuploadList(filevals)
                    $('#FileUpload').val("");

                } catch (ex) {
                    console.log(ex);
                }
            }
        }
    }

    const RemoveFiles = async (e, index) => {
        filevals.splice(index, 1);
        setFiles(filevals);
        FileuploadList(filevals)
    }

    const ViewAppendFile = (event, filename) => { // event.preventDefault();

        setIsOpenimg(true);
        setImagename(filename);
    }

    function handleClosePopup() {
        setFinalInspectionPopup(false)
    }

    const handleInputChange = (index, event) => {
        const values = [...inputFields];
        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }
        values[index].Comment = inputText;
        setInputFields(values);
    }
    function SaveComment(e, index) {


        let values = [...inputFields]
        if (inputFields.length === 1 && inputFields[0].Comment === '') {
            //setButtonLoader(false);
            setSubmittedQA(true);
            Nodify('Warning!', 'warning', "Please fill atleast one QC Comment Point.");
        } else {
            if (CommentDroplist) {

                let isvalid = true

                for (var i = 0; i < CommentDroplist.length; i++) {
                    if (CommentDroplist[i].label.trim().toLowerCase() === values[0].Comment.trim().toLowerCase()) {
                        values[0].Comment = "";
                        setInputFields(values);
                        Nodify('Warning!', 'warning', "This QC Comment Point Name is already exist-test.");
                        isvalid = false
                        return false;
                    }
                }

                if (isvalid) {
                    let Params = { Createdby: currentUser.employeeinformationID, QACommentPoint: inputFields };
                    QACommentPointService.InsertQACommentPoints(Params).then(res => {
                        if (res.data.outputResult === "1") {
                            Nodify('Success!', 'success', 'Comments saved Successfully');
                            QcInspection.getCommonResultList(1, props.location.state.params.details.supplierId).then((res) => {
                                let Commentlist = [];
                                if (res.data) {
                                    res.data.commentPointList.map((element) => {
                                        Commentlist.push({
                                            value: element.value,
                                            label: element.label
                                        })
                                    })
                                    setCommentDroplist(Commentlist);
                                }
                            })
                            setDefectPopup(false)
                        }
                    })
                }
            }
        }
    }
    const CheckDuplicate = (index) => {
        const values = [...inputFields];
        if (ExistingList.item1) {
            for (var i = 0; i < ExistingList.item1.length; i++) {
                if (ExistingList.item1[i].comment.trim().toLowerCase() === values[index].Comment.trim().toLowerCase()) {
                    values[index].Comment = '';
                    setInputFields(values);
                    Nodify('Warning!', 'warning', "This QC Comment Point Name is already exist.");
                    return false;
                }
            }
            for (var i = 0; i < (inputFields.length); i++) {
                if (i !== index) {
                    if (inputFields[i].Comment.trim().toLowerCase() === inputFields[index].Comment.trim().toLowerCase()) {
                        values[index].Comment = "";
                        setInputFields(values);
                        Nodify('Warning!', 'warning', 'This QC Comment Point Name is already exist.');
                        return false;
                    }
                }
            }
        }
    }

    function MainInfoCommonCalculation(val) {
        let poqty = 0;
        let prodqty = 0;
        val.map(x => {
            if (poqty === 0) {
                poqty = parseFloat(x.orderQuantity);
            }
            else {
                poqty = poqty + parseFloat(x.orderQuantity);
            }
            if (prodqty === 0 && x.prodqty !== undefined && x.prodqty !== '') {
                prodqty = parseFloat(x.prodqty);
            }
            else if (prodqty !== 0 && x.prodqty !== undefined && x.prodqty !== '') {
                prodqty = parseFloat(prodqty) + parseFloat(x.prodqty);
            }
        })
        setTotalValues({ PoQty: poqty, Prodqty: prodqty })
    }

    const OnSelectExFactoryRecords = (event, index, item) => {

        const value = [...MultiCloseDatas];
        if (event.target.checked === true) {
            value[index].isSelected = 1;
            value[index].IscheckReportSelect = 2;
            value[index].offerqty = '';
            props.location.state.params.multiselecteddatas.filter(x => x.purchaseOrderSkuID === item.purchaseOrderSkuID).map((e) => {
                e.isSelected = 1;
            })
        }
        else {
            value[index].isSelected = 0;
            value[index].IscheckReportSelect = 0;
            props.location.state.params.multiselecteddatas.filter(x => x.purchaseOrderSkuID === item.purchaseOrderSkuID).map((e) => {
                e.isSelected = 0;
            })
        }

        let isSelectAll = 0;
        if (value) {
            isSelectAll = !!!value.find(d => d.isSelected === 0);
            isSelectAll = isSelectAll ? 1 : 0;
        }
        setselectall(isSelectAll);
        setMultiCloseDatas(value);

    }
    function OpenPopup1() {

        setDefect([{ Id: 0, Name: "" }])
        setInputFields([{ CommentID: 0, Comment: "" }])
        setSubmittedQA(false);
        setDefectPopup(true)

    }

    function handleClosePopup1() {
        setDefectPopup(false)
    }
    function AddExFactoryForm() {

        let Multiselected = [];
        const Values = [...getInspectionQtyInfo]
        let isvalid = true;
        isvalid = !!MultiCloseDatas.find(x => x.isSelected === 1)
        let sizeandqtyfordisplay = "";
        let NewArr = [];
        NewArr = Values.concat(MultiCloseDatas.filter(x => x.isSelected === 1))
        NewArr.map((Purchaseorder) => {
            Purchaseorder.IsDeleted = 0;
            if (sizeandqtyfordisplay === '') {
                sizeandqtyfordisplay = Purchaseorder.purchaseOrderSkuID;
            } else {
                sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.purchaseOrderSkuID
            }
        })

        props.location.state.params.PurchaseOrderSkuID = sizeandqtyfordisplay;
        if (isvalid) {
            Multiselected = Values.concat(MultiCloseDatas.filter(x => x.isSelected === 1))
            setMultiCloseDatas(Multiselected)
            setInspectionQtyInfo(Multiselected)
            setFinalInspectionPopup(false);
            MainInfoCommonCalculation(Multiselected)
            Qunatitylist(Multiselected)
            setrerenderstate(props.location.state.params.multiselecteddatas.filter(x => x.isSelected === 1).length);
        } else {
            Nodify('Warning!', 'warning', 'Please fill atleast one checkbox');
            return false;
        }

    }

    let styles = {
        control: styles => ({ ...styles, border: '1px solid red', innerHeight: '100px', outerHeight: '100px' }),
    }

    const handleTestReportCheckExpandRow = (rowNumber) => {
        setTestReportExpandedRows([]);
        let currentExpandedRows;
        let isRowExpanded = false;
        currentExpandedRows = TestReportexpandedRows;
        isRowExpanded = currentExpandedRows.includes(rowNumber);
        let obj = {};
        isRowExpanded ? (obj[rowNumber] = false) : (obj[rowNumber] = true);
        setTestReportExpandState(obj);
        currentExpandedRows = [];
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== rowNumber) :
            currentExpandedRows.concat(rowNumber);
        setTestReportExpandedRows(newExpandedRows);
    }

    function ViewFile(e, filepath) {

        setIsOpen(true);
        setImagenameupload(filepath);
    }

    function ImageCallback(value, index, childindex, FeildName) {

        let profile = Commentvalue !== '' ? "/QcFinalinspection/" : '';
        if (FeildName === "Image1") {
            //Commentvalue[index].ImagesList[childindex].MainIndex = index;
            Commentvalue[index].ImagesList[childindex].UploadedImagePath1 = profile + value;
        } else {
            Commentvalue[index].ImagesList[childindex].UploadedImagePath2 = profile + value;
        }
        setCommentsList(Commentvalue);
    }
    function deleteFile(value, index, childindex, FeildName) {
        if (FeildName === "Image1") {
            Commentvalue[index].ImagesList[childindex].UploadedImagePath1 = '';
        } else {
            Commentvalue[index].ImagesList[childindex].UploadedImagePath2 = '';
        }
        setCommentsList(Commentvalue);
    }
    function handleCommonResultInputChange(index, e, FieldName) {
        let InputText = '';
        const updatedCommentvalue = [...Commentvalue];
        if (FieldName === "TestingReport") {
            if (e !== null) {
                Commentvalue[index].CommnetsID = e.value
            } else {
                Commentvalue[index].CommnetsID = 0
            }
        }

        else if (FieldName === 'FindingRemarks') {
            if (e.target.value.trim() !== '') {
                InputText = e.target.value;
            }
            Commentvalue[index].Remarks = InputText;
        } else {
            if (e !== null) {
                Commentvalue[index].ResultID = e.value
            } else {
                Commentvalue[index].ResultID = 0
            }
        }
        setCommentsList(Commentvalue);
        commentList(Commentvalue)
    }

    function handleAddFields(index) {

        if (Commentvalue[index].CommnetsID === 0) {
            setSubmitted(true);
            Nodify('Warning!', 'warning', 'Please fill the Mandatory(*) feilds');
        } else {
            setSubmitted(false);
            Commentvalue.push({
                TNAQCInspecCommentID: 0,
                CommnetsID: 0,
                ResultID: 0,
                CommentMainIndex: index + 1,
                ImagesList: [],
                Remarks: ''
            })
            let newindex = Commentvalue.length - 1;
            Commentvalue[newindex].ImagesList.push({
                TNAQCInspeCommentsSubID: 0,
                UploadedImagePath1: "",
                UploadedImagePath2: "",
                MainIndex: index + 1
            })
        }
        setCommentsList(Commentvalue);
        commentList(Commentvalue)
    }
    function handleRemoveFields(index) {
        Commentvalue.splice(index, 1)
        //Commentvalue[index].IsDeleted = 1;
        setCommentsList(Commentvalue);
        commentList(Commentvalue)
    }
    const SelectAllExFactory = (event) => {

        let IsSelected = 0;
        if (event.target.checked === true) {
            IsSelected = 1;
            MultiCloseDatas.forEach(element => {
                element.isSelected = 1;
                element.IscheckReportSelect = 2;
                element.offerqty = '';
            })
        }
        else {
            IsSelected = 0;
            MultiCloseDatas.forEach(element => {
                element.isSelected = 0;
                element.IscheckReportSelect = 0;
            });
        }
        setselectall(IsSelected);
    }
    return (

        <div className="row" style={{ width: '100%', marginLeft: "1px" }}>
            <div className="widget-body">
                <div id="registration-form">
                    <Form>
                        {/* {(props.location.state.params.Action === "Edit" || props.location.state.params.Action === "View") ?
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="widget-body" style={{ paddingBottom: "0" }}>
                                                    {

                                                        <>
                                                            <div className="">
                                                                {/* <CommonStyle props={props} Style={getStyleInfo} /> */}
                        {/* <CommonInternaQaInfo props={props} Style={getInspectionInfo} />
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        : ''}
                                    <br /> */}
                        <div className="row">
                            <div className="col-md-9">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className='form-group'>
                                            <label htmlFor="txtBuyerSeasonBrand">Buyer-Season-Brand</label>
                                            <span className='input-icon icon-right'>
                                                <input type="text" className='form-control' placeholder="Enter Buyer-Season-Brand" id="txtBuyerSeasonBrand"
                                                    name="buyerseasonbrand" autoComplete="off" maxLength="100"
                                                    value={props.location.state.params.buyerName + " - " + props.location.state.params.seasonName + " - " + props.location.state.params.brandName}
                                                    disabled />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className='form-group'>
                                            <label htmlFor="ddlSupplier">Supplier</label>
                                            <span className='input-icon icon-right'>
                                                <input type="text" className='form-control' placeholder="Enter Buyer-Season-Brand" id="txtBuyerSeasonBrand"
                                                    name="buyerseasonbrand" autoComplete="off" maxLength="100"
                                                    value={props.location.state.params.supplierName}
                                                    disabled />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className='form-group'>
                                            <label htmlFor="ddlFactoryOrLocation">Factory {/* / Location */}<span className="text-danger">*</span></label>

                                            <span className='input-icon icon-right'>
                                                <Reactselect className="basic-single" name="factoryorlocation"
                                                    id={
                                                        "ddlFactoryOrLocation"
                                                    }
                                                    isDisabled={props.location.state.params.Action === 'View' ? true : false}
                                                    isLoading={false}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    options={getExFactoryList}
                                                    styles={submitted && getMainInputField.FactoryLocationID === 0 ? styles : ''}
                                                    value={getExFactoryList.filter(function (option) {
                                                        return option.value === getMainInputField.FactoryLocationID;
                                                    })}
                                                    onChange={event => handleMainChange(event, 'Factory')}
                                                ></Reactselect>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className='form-group'>
                                            <label htmlFor="ddlStyleName">Style Name</label>
                                            <span className='input-icon icon-right'>
                                                <input type="text" className='form-control' placeholder="Enter Buyer-Season-Brand" id="txtBuyerSeasonBrand"
                                                    name="buyerseasonbrand" autoComplete="off" maxLength="100"
                                                    value={props.location.state.params.styleName}
                                                    disabled />
                                            </span>
                                        </div>
                                    </div>
                                    {/* {props.location.state.params.TaskType !== 'PP Meeting' ? < div className="col-sm-4">
                                                    <div className='form-group'>
                                                        <label htmlFor="txtInspectionLoad">PO/ID No</label>
                                                        <span className='input-icon icon-right'>
                                                            <input type="text" className='form-control' id="InspectionLevel   "
                                                                name="InspectionLevel" autoComplete="off"
                                                                //onChange={event => handleMainChange(event, 'InspectionLevel')}
                                                                value={props.location.state.params.details.idPoNos}
                                                                maxLength="100" disabled />

                                                        </span>
                                                    </div>
                                                </div> : ''} */}

                                    <div className="col-sm-4">
                                        <div className='form-group'>
                                            <label htmlFor="txtAQLLevel">Factory Merch <span className='text-danger'>*</span></label>
                                            <span className='input-icon icon-right'>
                                                <Reactselect className="basic-single" name="factoryorlocation"
                                                    id={
                                                        "ddlFactoryOrLocation"
                                                    }
                                                    isDisabled={props.location.state.params.Action === 'View' ? true : false}
                                                    isLoading={false}
                                                    isClearable={true}
                                                    isMulti
                                                    isSearchable={true}
                                                    options={FactoryDroplist}
                                                    styles={submitted && getMainInputField.FactoryMerchID === '' ? styles : ''}
                                                    value={values.FactoryMerchForEdit}
                                                    onChange={event => handleMainChange(event, 'FactoryMerch')}
                                                ></Reactselect>
                                            </span>
                                        </div>
                                    </div>
                                    {props.location.state.params.TaskType === "PP Meeting" ? <div className="col-sm-4">
                                        <div className='form-group'>
                                            <label htmlFor="dteReportDate">Report Date<span className="text-danger">*</span></label>
                                            <DatePicker className="form-control" name="ReceivedDate"
                                                id={
                                                    "dteReportDate"
                                                }
                                                selected={
                                                    getMainInputField.ReportDate
                                                }
                                                onChange={event => handleMainChange(event, 'ReportDate')}
                                                dateFormat="dd/MM/yyyy"
                                                peekNextMonth
                                                ///disabled={props.location.state.params.Action === 'View' ? true : false}
                                                //showMonthDropdown
                                                //showYearDropdown
                                                dropdownMode="scroll"
                                                autoComplete="off"
                                                customInput={<CustomInput />}
                                                isClearable={getMainInputField.ReportDate === null ? false : true}
                                            />
                                        </div>
                                    </div>
                                        : ''}

                                    {props.location.state.params.TaskType !== "PP Meeting" ? <div className="row">
                                        <div className="col-sm-4">
                                            <div className='form-group'>
                                                <label htmlFor="dteReportDate">Report Date<span className="text-danger">*</span></label>
                                                <DatePicker className="form-control" name="ReceivedDate"
                                                    id={
                                                        "dteReportDate"
                                                    }
                                                    selected={
                                                        getMainInputField.ReportDate
                                                    }
                                                    onChange={event => handleMainChange(event, 'ReportDate')}
                                                    dateFormat="dd/MM/yyyy"
                                                    peekNextMonth
                                                    ///disabled={props.location.state.params.Action === 'View' ? true : false}
                                                    //showMonthDropdown
                                                    //showYearDropdown
                                                    dropdownMode="scroll"
                                                    autoComplete="off"
                                                    customInput={<CustomInput />}
                                                    isClearable={getMainInputField.ReportDate === null ? false : true}
                                                />
                                            </div>
                                        </div>
                                        {props.location.state.params.TaskType === "In and Mid Line Inspection" ? <div className="col-sm-4" style={{ marginLeft: "16px" }}>
                                            <div className='form-group'>
                                                <label htmlFor="ddlCustomerShipmentRefNo">Checked Area <span className='text-danger'>*</span></label>
                                                <span className='input-icon icon-right'>
                                                    {/* <Reactselect className="basic-single" name="factoryorlocation"
                                                                id={
                                                                    "ddlFactoryOrLocation"
                                                                }
                                                                //isDisabled={props.location.state.params.Action === 'View' ? true : false}
                                                                isLoading={false}
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                options={CheckedAreaDroplist}
                                                                styles={submitted && getMainInputField.CheckedAreaID === 0 ? styles : ''}
                                                                value={CheckedAreaDroplist.filter(function (option) {
                                                                    return option.value === getMainInputField.CheckedAreaID;
                                                                })}
                                                                onChange={event => handleMainChange(event, 'CheckedArea')}
                                                            ></Reactselect> */}
                                                    <Reactselect lassName="basic-single" name="factoryorlocation"
                                                        id={
                                                            "ddlFactoryOrLocation"
                                                        }
                                                        isDisabled={false}
                                                        isLoading={false}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        isMulti
                                                        onChange={event => handleMainChange(event, 'CheckedAreaidList')}
                                                        options={CheckedAreaDroplist}
                                                        //value={CheckedAreaDroplist.arrcheckAreaID}
                                                        value={values.CheckedAreaforedit}
                                                        // value={SupList.filter(function (option) {
                                                        //     return option.value === getStyleList.SupplierEmployeeID;
                                                        // })}
                                                        styles={submitted && values.CheckedAreaforedit === '' ? styles : ''}

                                                    ></Reactselect>
                                                </span>
                                            </div>
                                        </div> : ''}
                                        {props.location.state.params.TaskType === "PP Meeting" ? <div className="col-sm-4">
                                            <div className='form-group'>
                                                <label htmlFor="dteReportDate">Report Date<span className="text-danger">*</span></label>
                                                <DatePicker className="form-control" name="ReceivedDate"
                                                    id={
                                                        "dteReportDate"
                                                    }
                                                    selected={
                                                        getMainInputField.ReportDate
                                                    }
                                                    onChange={event => handleMainChange(event, 'ReportDate')}
                                                    dateFormat="dd/MM/yyyy"
                                                    peekNextMonth
                                                    ///disabled={props.location.state.params.Action === 'View' ? true : false}
                                                    //showMonthDropdown
                                                    //showYearDropdown
                                                    dropdownMode="scroll"
                                                    autoComplete="off"
                                                    customInput={<CustomInput />}
                                                    isClearable={getMainInputField.ReportDate === null ? false : true}
                                                />
                                            </div>
                                        </div>
                                            : ''}
                                    </div> : ''}
                                </div>
                            </div>
                            <div className="col-sm-3 pull-right">
                                <div className="form-group">

                                    <label style={{ marginLeft: "55px" }} htmlFor="exampleInputEmail2">SKU Image</label>
                                    <br />
                                    {
                                        getnewimagevalue.length > 0 ?
                                            <>
                                                <i className="fa fa-chevron-circle-left fa-2x" onClick={() => previmage()} style={{ cursor: "pointer" }}></i>
                                                <img src={window.$APIBaseURL + "Images" + getnewimagevalue[getCurrimageindex].skuFrontPicPath} alt="" width="150px" height="150px" />
                                                <i className="fa fa-chevron-circle-right fa-2x" onClick={() => nextimage()} style={{ cursor: "pointer" }}></i>
                                            </> : ''
                                    }
                                    <br />
                                    {
                                        getnewimagevalue.length > 0 ?

                                            <label htmlFor="" style={{ marginLeft: "85px", marginTop: "1rem" }}>{getnewimagevalue[getCurrimageindex].skuName}</label>
                                            : ""
                                    }
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            <div className="panel-group accordion" id="acc_Quantity_Info">
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <h4 className="panel-title">
                            <a className="accordion-toggle clr-themegreen" data-toggle="collapse" data-parent="#acc_Quantity_Info" href="#collapse_acc_Quantity_Info">
                                Quantity Information
                            </a>
                        </h4>
                    </div>
                    <div id="collapse_acc_Quantity_Info" className="panel-collapse">
                        <div className="panel-body border-red">
                            <div className="mb-10-float-right">

                                {/* <Label ><label className="btn btn-sm btn-warning">Tolerance: {gettolerance + '%'}</label></Label>&nbsp;&nbsp; */}
                                {(props.location.state.params.TaskType === 'In and Mid Line Inspection' ||
                                    props.location.state.params.TaskType === 'PP Meeting') && (
                                        <Label>
                                            <label className="btn btn-sm btn-warning">
                                                Tolerance: {gettolerance}%
                                            </label>&nbsp;&nbsp;
                                        </Label>
                                    )}
                                <button type="button" className="btn btn-sm btn-success mb-10-float-right" onClick={OpenPopup}>Order Details</button>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                        <thead>
                                            <tr>
                                                <th>SKU ID(PO)</th>
                                                <th>Style No</th>
                                                <th>SKU Name</th>
                                                <th>End Customer</th>
                                                <th>PO/ID No</th>
                                                <th>FO PO No</th>
                                                <th>Order Qty</th>
                                                {(props.location.state.params.TaskType === 'In and Mid Line Inspection' ||
                                                    props.location.state.params.TaskType === 'PP Meeting') && (
                                                        <th>Prod Qty <span className='text-danger'>*</span></th>)}
                                                <th style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                getInspectionQtyInfo.map((comment, index) => (
                                                    <tr>
                                                        <td>{comment.purchaseOrderSkuID}</td>
                                                        <td>{comment.styleNo}</td>
                                                        <td>{comment.skuName}</td>
                                                        <td>{comment.endCustomer}</td>
                                                        <td>{comment.idNo}</td>
                                                        <td>-</td>
                                                        <td>{comment.orderQuantity}</td>
                                                        {(props.location.state.params.TaskType === 'In and Mid Line Inspection' ||
                                                            props.location.state.params.TaskType === 'PP Meeting') && (
                                                                <td><input className="form-control" type="text"
                                                                    name="" id="" value={comment.prodqty}
                                                                    style={{ border: submitted && comment.prodqty === undefined || comment.prodqty === '' ? '1px solid red' : '' }}
                                                                    onChange={event => handleOfferQty(event, index)}
                                                                /></td>
                                                            )}
                                                        <td style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}> <span>
                                                            <span className="btn btn-danger"
                                                                style={{ pointerEvents: getInspectionQtyInfo.length === 1 ? "none" : '', opacity: getInspectionQtyInfo.length === 1 ? '0.6' : '' }}
                                                                onClick={event => RemoveQuantity(event, index, comment.purchaseOrderSkuID, comment)}>
                                                                <i className="fa fa-trash-o"></i>
                                                            </span>
                                                        </span></td>
                                                    </tr>

                                                ))
                                            }
                                            <tr>
                                                <td colSpan="6" className="text-right">Total</td>
                                                <td>{getTotalValues.PoQty}</td>
                                                {(props.location.state.params.TaskType === 'In and Mid Line Inspection' ||
                                                    props.location.state.params.TaskType === 'PP Meeting') && (
                                                        <td>{getTotalValues.Prodqty}</td>
                                                    )}
                                                <td style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}></td>
                                                {/* <td></td> */}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                getFinalInspectionPopup === true ? <Modal dialogClassName="CreatePopup" show={getFinalInspectionPopup} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleClosePopup()}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {/* ADD Final Inspection &nbsp; */}
                            ADD {props.location.state.params.TaskType}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="inspection" style={{ height: "70vh", overflowX: "hidden", overflowY: "scroll" }}>
                            <table className="table table-bordered">
                                <thead>
                                    <tr style={{ whiteSpace: "nowrap" }}>
                                        <th>Style Name</th>
                                        <th>Style No</th>
                                        <th>SKU Name</th>
                                        <th>PO/ID No</th>
                                        <th>FO PO No</th>
                                        <th>Factory</th>
                                        <th>Order Qty</th>
                                        <th>Task Holder / Follower</th>
                                        <th>
                                            <label style={{ marginTop: '8px' }}>
                                                <input type="checkbox" name="IsSelected"

                                                    onChange={event => (SelectAllExFactory(event))}
                                                    checked={selectall === 1 ? true : false}
                                                    className="colored-blue clearOpacity" />
                                                <span className="text"></span>
                                            </label>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        MultiCloseDatas.length !== 0 && MultiCloseDatas.map((item, index) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <td>{item.styleName}</td>
                                                        <td>{item.styleNo}</td>
                                                        <td>{item.skuName}</td>
                                                        {/* <td>-</td> */}
                                                        <td>{item.idNo}</td>
                                                        <td>{item.poNo}</td>
                                                        <td>{item.factory}</td>
                                                        <td>{item.orderQuantity}</td>
                                                        <td className="cut_textInTNA" title={item.taskOwnerNameList} >{item.taskOwnerNameList}</td>
                                                        <td className="Actionbutton fixed-column">
                                                            <div style={{ width: "80px" }}>
                                                                {
                                                                    <label style={{ marginTop: '8px' }}>
                                                                        <input type="checkbox" name="IsSelected"
                                                                            disabled={item.balanceQuantity === "0" ? true : false}
                                                                            onChange={event => (OnSelectExFactoryRecords(event, index, item))}
                                                                            checked={item.isSelected === 1 ? true : false}
                                                                            className="colored-blue clearOpacity" />
                                                                        <span className="text"></span>
                                                                    </label>
                                                                }&nbsp;&nbsp;&nbsp;
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>

                                            )
                                        })

                                    }
                                    {
                                        MultiCloseDatas.length === 0 ?
                                            <tr>
                                                <td colSpan="10" className='norecordfound'><span>No record found</span></td>
                                            </tr> : ''
                                    }
                                </tbody>
                            </table>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={() => AddExFactoryForm()}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal> : ''
            }
            {
                getDefectPopup === true ? <Modal show={getDefectPopup} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleClosePopup1()}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            QA Comments Master&nbsp;
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <span className="input-icon icon-right">

                                <textarea className='form-control' style={{ minHeight: '109px', border: submittedQA && !inputFields[0].Comment ? '1px solid red' : '' }} name="findingsorremarks"
                                    id="txtFindingsOrRemarks" rows="10" placeholder="Enter Findings / Remarks" onChange={event => handleInputChange(0, event)}
                                    autoComplete="off" value={inputFields[0].Comment} maxLength="200" onBlur={() => CheckDuplicate(0)}></textarea>

                            </span>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={SaveComment} >
                            {/* onClick={() => AddExFactoryForm()} */}
                            Save
                        </Button>
                    </Modal.Footer>


                </Modal> : ''
            }
            <br />
            <table className="table table-striped table-bordered table-hover dataTable no-footer">
                <thead>
                    <tr>
                        <th>#</th>
                        <th><button type='button' className="glyphicon glyphicon-plus AddPlusIconTemplate" style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}
                            onClick={() => OpenPopup1()}
                            title="Add Size">
                        </button>Comments<span className="text-danger"> *</span></th>
                        <th>Remarks</th>
                        <th>Action to be taken</th>
                        <th style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        getCommentsList.map((item, index) => (
                            <Fragment>
                                <tr key={index}>
                                    <td >
                                        {<i
                                            className={
                                                TestReportExpandState[index] ?
                                                    'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                            } onClick={event => handleTestReportCheckExpandRow(index)}
                                        ></i>}&nbsp;
                                        <b>{index + 1}</b></td>
                                    <td ><Reactselect className="basic-single" name="Result"
                                        id={
                                            "Result" + index
                                        }
                                        isDisabled={props.location.state.params.Action === 'View' ? true : false}
                                        isLoading={false}
                                        isClearable={true}
                                        isSearchable={true}
                                        styles={submitted && item.CommnetsID === 0 ? styles : ''}
                                        onChange={(event) => handleCommonResultInputChange(index, event, 'TestingReport')}
                                        options={CommentDroplist}
                                        value={CommentDroplist.filter(function (option) {
                                            return option.value === item.CommnetsID;
                                        })}

                                    ></Reactselect></td>
                                    <td>  <input
                                        type="text"
                                        className="form-control"
                                        name={index + "_txtRemarks"}
                                        value={item.Remarks || ''}
                                        autoComplete="off"
                                        maxLength="200"
                                        onChange={(event) => handleCommonResultInputChange(index, event, 'FindingRemarks')}
                                    />
                                    </td>
                                    <td><Reactselect className="basic-single" name="Result"
                                        id={
                                            "Result" + index
                                        }
                                        isDisabled={props.location.state.params.Action === 'View' ? true : false}
                                        isLoading={false}
                                        isClearable={true}
                                        isSearchable={true}
                                        //styles={submitted && item.CommnetsID === 0 ? styles : ''}
                                        onChange={(event) => handleCommonResultInputChange(index, event, 'TestingReport1')}
                                        options={actiondropdown}
                                        value={actiondropdown.filter(function (option) {
                                            return option.value === item.ResultID;
                                        })}

                                    ></Reactselect></td>
                                    <td style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}>
                                        <button type="button" style={{ display: getCommentsList[index].ImagesList.length < 2 ? '' : 'none' }} className="btn btn-xs btn-success" title="Add packing Image"
                                            onClick={() => handleAddFields(index)}
                                        >
                                            <i className="fa fa-plus"></i>
                                        </button>
                                        &nbsp;
                                        {getCommentsList.length !== 1 ? <button type="button"
                                            className="btn btn-xs btn-danger" title="Delete packing Image"
                                            onClick={() => handleRemoveFields(index)}
                                            style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}
                                        >
                                            <i className="fa fa-trash-o"></i>
                                        </button> : ''}
                                    </td>
                                </tr>
                                <Fragment>
                                    {
                                        TestReportexpandedRows.includes(index) ?
                                            <tr>
                                                <td colspan="4">
                                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                        <tbody>
                                                            {
                                                                item.ImagesList.map((child, childindex) => (
                                                                    <Fragment>
                                                                        <tr>
                                                                            <td className="table-width-40">
                                                                                <div className="row">

                                                                                    <div className="row">
                                                                                        <div className="text-center">
                                                                                            <span className="text-danger">Click on the image to upload new</span><br /><br />
                                                                                            <div className="text-center">
                                                                                                <div className="row">
                                                                                                    <div className="text-center">
                                                                                                        <div style={{ width: "50%", marginLeft: "20rem" }}>
                                                                                                            <FinalinspecUpload UploadCallback={(e) => ImageCallback(e, index, childindex, 'Image1')} PageName='QcFinalinspection' ShowDiv="1" filePath={child.UploadedImagePath1} />
                                                                                                        </div>
                                                                                                        {child.UploadedImagePath1 !== '' ? <div className="row" style={{ position: "relative", left: "40px", bottom: "155px" }}>
                                                                                                            <div className="col-md-8 position-relative">
                                                                                                                <i className="btn btn-info fa fa-eye" title="View Logo" style={{ position: 'absolute', padding: '2px 6px', right: '31px' }}



                                                                                                                    onClick={
                                                                                                                        event => ViewFile(event, child.UploadedImagePath1, index)
                                                                                                                    }></i>&nbsp;
                                                                                                                <i className="btn btn-danger fa fa-trash" title="Delete Image" style={{ fontSize: "13px", position: 'absolute', padding: "2px 7px ", right: 0 }}
                                                                                                                    onClick={
                                                                                                                        event => deleteFile(event, index, childindex, 'Image1')
                                                                                                                    }></i>
                                                                                                            </div>
                                                                                                        </div> : ''}
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                            <td className="table-width-60">

                                                                                <div className="row">
                                                                                    <div className="text-center col-lg-6">
                                                                                        <span className="text-danger">Click on the image to upload new</span><br /><br />
                                                                                        <div >
                                                                                            <FinalinspecUpload UploadCallback={(e) => ImageCallback(e, index, childindex, 'Image2')} PageName='QcFinalinspection' ShowDiv="1" filePath={child.UploadedImagePath2} />
                                                                                        </div>
                                                                                        {child.UploadedImagePath2 !== '' ? <div className="row">
                                                                                            <div className="col-md-7">
                                                                                            </div>
                                                                                        </div> : ''}
                                                                                    </div>

                                                                                </div>
                                                                                <span className="TnaFinaspecwrkmans" style={{ left: "290px" }}>

                                                                                    {child.UploadedImagePath2 !== '' ? <button className="btn btn-info fa fa-eye" style={{ marginLeft: "75px", padding: "3px 6px", width: "3.5%" }} onClick={
                                                                                        event => ViewFile(event, child.UploadedImagePath2, index)
                                                                                    }>
                                                                                        <i c title="View Logo" style={{ marginRight: "8px" }}
                                                                                        ></i>&nbsp;
                                                                                        <i className="btn btn-danger fa fa-trash" title="Delete Image" style={{ marginLeft: '5px', marginBottom: '1px' }}
                                                                                            onClick={
                                                                                                event => deleteFile(event, index, childindex, 'Image2')
                                                                                            }></i>
                                                                                    </button> : ''}

                                                                                    {/* &nbsp;
                                                                                    {props.location.state.params.Action !== 'View' ? <button type="button" style={{ display: getCommentsList[index].ImagesList.length < 2 ? '' : 'none' }} className="btn btn-xs btn-success" title="Add packing Image"
                                                                                        onClick={() => handleAddFields(index, childindex)}
                                                                                    >
                                                                                        <i className="fa fa-plus"></i>
                                                                                    </button> : ''}
                                                                                    &nbsp;
                                                                                    {getCommentsList[index].ImagesList.length !== 1 && (<button type="button"
                                                                                        className="btn btn-xs btn-danger" title="Delete packing Image"
                                                                                        onClick={() => handleRemoveFields(index, childindex)}
                                                                                        style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}
                                                                                    >
                                                                                        <i className="fa fa-trash-o"></i>
                                                                                    </button>)} */}
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </Fragment>

                                                                ))}
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr> : ''
                                    }
                                    <div> {
                                        isOpen && (

                                            <Lightbox mainSrc={window.$APIBaseURL + 'Images' + getImagenameupload}
                                                alt="bg image"
                                                onCloseRequest={
                                                    () => setIsOpen(false)
                                                } />
                                        )
                                    } </div>
                                </Fragment>
                            </Fragment>
                        ))
                    }
                </tbody >
            </table>
            <br />
            <br />
            <div className="col-sm-12 col-lg-12">
                <div className="widget-header" style={{ paddingLeft: 0 }}>
                    <span className="lowCaseText widget-caption" style={{ fontSize: '14px' }}>&nbsp;File Upload <span style={{ color: 'red', fontSize: '14px' }} >(Upload Document Type: Excel, Word, PDF and Image)</span></span>
                </div>
            </div>
            <div className="col-sm-12 col-lg-12">
                <div className="row">
                    <div className="col-sm-8 col-lg-8">
                        <div className='form-group'>
                            <tr>
                                <td> <input type="file" id="FileUpload" style={{ fontSize: '14px' }} onChange={(e) => AppendFiles(e)} accept=".jpg,.jpeg,.png,.pdf,.xlsx,.doc,.docx,.xls" ></input></td>
                            </tr>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-12 col-sm-12">
                <div className="row no-margin">
                    {
                        getFiles.length !== 0 ?
                            getFiles.map((files, fileindex) => (
                                <div className="col-lg-4 col-sm-4 file_list_upload">
                                    <tr title={files.FileName}>
                                        <td className="cut_text">
                                            <span style={{ marginRight: '7px' }}>
                                                {
                                                    files.FileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                        files.FileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.FileType === "application/vnd.ms-excel" ?
                                                            <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                            files.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                files.FileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                }
                                            </span>  {files.FileName.substr(33)}
                                        </td>

                                        <td className="button_style_uploda">


                                            {
                                                files.FileType === "application/pdf" || files.FileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                    || files.FileType === 'application/msword' || files.FileType === "application/vnd.ms-excel" ?
                                                    <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + files.FilePath} download><i class="fa fa-download"></i> </a> :
                                                    <i className="btn btn-info fa fa-eye" title="View Logo"
                                                        onClick={
                                                            event => ViewAppendFile(event, files.FilePath)
                                                        }></i>
                                            }

                                            &nbsp;

                                            <p title='Delete File'
                                                onClick={(e) => RemoveFiles(e, fileindex)} style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}
                                                className="btn btn-danger btn-xs delete">
                                                <i className="fa fa-trash-o"></i>

                                            </p>
                                        </td>
                                    </tr>
                                    {

                                        <div> {
                                            isOpenimg && (
                                                <Lightbox mainSrc={window.$APIBaseURL + getImagename}
                                                    alt="bg image"
                                                    onCloseRequest={
                                                        () => setIsOpenimg(false)
                                                    } />
                                            )
                                        } </div>
                                    }
                                </div>
                            ))
                            : ''
                    }
                </div>
            </div>
            <br />
            <div className="col-sm-12 col-lg-12">
                <div className="row">
                    <div className="col-sm-7 col-lg-7">
                        <div className='form-group'>
                            <label htmlFor="Remarks"> QC Remarks {getQcremarksdrpdwn.QcDropdownID === 3 && getQcremarksdrpdwn.QARemarks === '' ? <span className="text-danger">*</span> : ""}   </label>
                            <textarea maxLength="300" name="Remarks" value={getQcremarksdrpdwn.QARemarks} onChange={event => handleChange(event, 'QCRemarks')} rows="4" style={{ width: '100%', border: getQcremarksdrpdwn.QcDropdownID === 3 && getQcremarksdrpdwn.QARemarks === '' ? '1px solid red' : '' }} ></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            {props.location.state.params.TaskType !== 'PP Meeting' && props.location.state.params.TaskType !== 'In and Mid Line Inspection' ? <div className="row">
                <div className="col-sm-3" style={{ marginLeft: '17px' }}>
                    <div className='form-group'>
                        <label htmlFor="Name" >Q.C Conclusion<span className="text-danger">*</span></label>
                        <span className='input-icon icon-right'>
                            <Reactselect className="basic-single" name="TaskHolderOrFollower"
                                id={
                                    "TaskHolderOrFollower"
                                }
                                isLoading={false}
                                isClearable={true}
                                isSearchable={true}
                                onChange={event => handleChange(event, 'QCResult')}
                                value={dropDownlist.filter(function (option) {
                                    return option.value === getQcremarksdrpdwn.QcDropdownID;
                                })}
                                options={dropDownlist}
                                styles={submittedQCDp && getQcremarksdrpdwn.QcDropdownID === 0 ? styles : ''}
                                //isDisabled={props.TaskStatus === 3 && props.editProtoFitSmsApproval === false ? true :
                                //props.props.location.pathname !== '/TNAEdit' && props.TaskStatus === 3 ? true : false}
                                menuPosition="fixed"

                            ></Reactselect>

                        </span>
                    </div>
                </div>


            </div>
                : ''}
            {loader}
        </div>



    )
}

export default React.memo(AddQCReportTab);