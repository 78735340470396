import axios from "axios";
import authHeader from "../auth-header";

class WorkmanshipGroupService {
    GetWorkmanshipItemList() {//Workmanshipitem details
        return axios.get(window.$APIBaseURL + "Master/GetWorkmanshipItemList?workmanshipItemID=0", {
            headers: {
                authorization: authHeader()
            }
        });
    }
    LoadWorkmanGroupList(WorkmanshiGroupId) {
        return axios.get(window.$APIBaseURL + "Master/LoadWorkmanGroupList?WorkmanshiGroupId=" + WorkmanshiGroupId, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    GetworkmanshipGroupList(Operation, Id) {//WorkmanshipGroup details
        return axios.get(window.$APIBaseURL + "Master/GetworkmanshipGroupList?Operation=" + Operation + '&workmanshipGroupID=' + Id, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    InsertUpdateWorkmanshipGrp = async (Params) => {
        return await axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateWorkmanshipGroup',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
    UpdateWorkmanshipGrp = async (Params) => {
        return await axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/UpdateWorkmanshipGrp',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }


}

export default new WorkmanshipGroupService()