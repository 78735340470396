import React, { useState, useEffect, useRef, Fragment } from "react";
import Nodify from "../../Common/ReactNotification";
import validator from 'validator'
import { useSelector } from "react-redux";
import ReactNotification from 'react-notifications-component';
import QAService from "../../../services/Master/QAService";

const QAAdd = (props) => {


 const [getQA, setQA] = useState([{ Id: 0, Name: "", }]);
 const [submitted, setSubmitted] = useState(false);
 const [buttonLoader, setButtonLoader] = useState(false);
 const { user: currentUser } = useSelector((state) => state.auth);
 const [ExistingList, setExistingList] = useState([]);
 const [ButtonName, setButtonName] = useState("Save");
 const [HeaderName, setHeaderName] = useState("Add");

 function QAListing(props) {
  let Comment = [...getQA];
  Array.from(document.querySelectorAll("input")).forEach(input => (input.value = ""));
  if (props.location.state.params.length !== 0) {
   if (props.location.state.params.Id !== "") {
    setButtonName("Update");
    setHeaderName("Edit");
    Comment.Id = props.location.state.params.itemID;
    Comment.Name = validator.trim(props.location.state.params.itemName);
    Comment.Operation = 2;
    return Comment;
   }
  } else {
   return Comment;
  }
 }

  //Enter Error msg
  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        //SaveBuyerInfo(event);
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [])
 const ResetOperation = (e) => {
  if (e === "Back") {
   props.history.push('/QACommentList');
  } else {
   window.location.reload()
  }
 }
 const ValidationPopup = (Msg) => {
  setButtonLoader(false);
  Nodify('Warning!', 'warning', Msg);
  return false;
 }

 const handleInputChange = (index, event) => {

  const values = [...getQA];
  let inputText = '';
  if (event.target.value.trim() !== '') {
   inputText = event.target.value;
  }
  values[index].Name = inputText;
  setQA(values);
 };

 const PageRedirect = (Func) => {
  props.history.push({ pathname: "/QACommentList", state: { message: Func } });
 }

 const SaveQA = (e) => {


  e.preventDefault();
  if (getQA.length === 1 && getQA[0].Name === '') {
   setButtonLoader(false);
   setSubmitted(true);
   ValidationPopup("Please fill atleast one Comment.");
  }
  else {
   for (var i = 0; i < (getQA.length); i++) {
    if (getQA[i].Name.trim() === '') {
     Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
     setSubmitted(true);
     setButtonLoader(false);
     return false;
    }
   }
   let QAInfo = getQA;
   let QAComment = { Createdby: currentUser.employeeinformationID, QAInfo: getQA };

   QAService.InsertUpdateQAComment(QAComment).then(res => {
    let Func = 'Add';
    if (res.data.outputResult === "1") {
     Func = 'Add';
     PageRedirect(Func);
    } else if (res.data.outputResult === "2") {
     Func = 'Edit';
     PageRedirect(Func);
    } else if (res.data.outputResult === "-2") {
     setButtonLoader(false);
     ValidationPopup("This Comment is already exist.");
    }
    else if (res.data.outputResult === "0") {
     setButtonLoader(false);
     ValidationPopup("Error Occured!");
    }
   })


  }


 }

 const CheckDuplicate = (index) => {

  const values = [...getQA];
  if (ExistingList) {
   for (var i = 0; i < ExistingList.length; i++) {
    if (ExistingList[i].comment.trim().toLowerCase() === values[index].Name.trim().toLowerCase()) {
     values[index].Name = '';
     setQA(values);
     Nodify('Warning!', 'warning', "This QA is already exist.");
     return false;
    }
   }
  }

  for (var i = 0; i < (getQA.length); i++) {
   if (i !== index) {
    if (getQA[i].Name.trim() === getQA[index].Name.trim()) {
     values[index].Name = "";
     setQA(values);
      Nodify('Warning!', 'warning', 'This QA is already exist..');
     return false;
    }
   }
  }
 }
 const handleAddFields = (index) => {

  const values = [...getQA];
  if (values[index].Name.trim() !== '') {
   values.push({ Id: 0, Name: "", });
   setQA(values);
   setSubmitted(false);
  }
  else {
   Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
   setSubmitted(true);
  }
 };

 const handleRemoveFields = index => {
  const values = [...getQA];
  values.splice(index, 1);
  setQA(values);
 };
 useEffect(() => {
  let getQAList = QAListing(props);
  setQA(getQAList)
  QAService.getAllQACommentList().then((response) => {
   if (response.data) {
    setExistingList(response.data);
   }
  });
 }, []);









  return (
   <div className="page-body">
    <ReactNotification />
    <div className="row">
     <div className="col-lg-12 col-sm-12 col-xs-12">
      <div className="widget flat radius-bordered">

       <div className="widget-header heading_box_style">
        <h3 className="heading_style_common">QA Comment</h3>
       </div>
       <div className="widget-body">
        <div id="registration-form">
         <form>
          {
           getQA.map((itm, index) => (
            <div className="row row_left10">
             <div className="col-sm-3 divder_style">
              <div className="form-group">
               <span className="input-icon icon-right">
                <input type="text"
                 className="form-control"
                 id={"Name_" + index}
                 name="Name"
                 value={itm.Name}
                 onChange={(event) => handleInputChange(index, event)}
                 placeholder="Enter QA Comment"
                 maxLength="100"
                 onBlur={() => CheckDuplicate(index)}
                 style={{ border: submitted && !itm.Name ? '1px solid red' : '' }}
                />
               </span>

              </div>

             </div>
             <div className="col-sm-3 paddingstatic">
                 {
                   getQA.length !== 1 && (
                     <div className="col-lg-1_own col-xs-12">
                       <button type="button" className="btn btn-danger"
                         title="Delete QA"
                         onClick={() => handleRemoveFields(index)}
                       >
                         <i className="fa fa-trash-o"></i>
                       </button>
                     </div>
                   )}

                 {
                   getQA.length === (index + 1) && (
                     <div className="col-lg-1_own col-xs-12">
                       <button type="button" className="btn btn-success" title="Add QA Name"
                         onClick={() => handleAddFields(index)}
                       >
                         <i className="fa fa-plus"></i>
                       </button>
                     </div>
                   )}



             </div>
            </div>
           ))

          }

          <div className="col-sm-12">
           <div className="buttons-preview" align="right">
            <span className="btn btn-primary"
             onClick={() => ResetOperation("Back")}
            >
             <i className="fa fa-arrow-circle-left"></i>
             &nbsp;Back
            </span>
            <span className="btn btn-danger"
             onClick={() => ResetOperation()}
            >
             <i className="fa fa-times"></i>
             &nbsp;Reset
            </span>
            <button type="button" className="btn btn-success"
             disabled={buttonLoader}
             onClick={SaveQA}
            >
             <i className="fa fa-check right"></i>&nbsp;
             {ButtonName}

            </button>
           </div>
          </div>

         </form>
        </div>
       </div>

      </div>
     </div>
    </div>
   </div>
  )
}

export default QAAdd