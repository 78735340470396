import axios from "axios";
import authHeader from "../auth-header";

class TrimTypeService {    

    GetTrimTypesList() {
        return axios.get(window.$APIBaseURL + "Master/GetTrimTypesList?GroupTypeID=0", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateTrimTypesGrid(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateTrimTypesGrid',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateTrimTypes(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateTrimTypes',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
}
export default new TrimTypeService()