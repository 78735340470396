import {
    PURCHASEORDER_LOADING,
    RETRIEVE_PURCHASEORDER,
    DELETE_PURCHASEORDER,
} from "../actions/types";

const initialState = {
    isLoadingPurchaseOrder: true,
    purchaseorderList: [],
};

const purchaseorderReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case PURCHASEORDER_LOADING:
            return {
                ...state,
                isLoadingPurchaseOrder: payload
            };
        case RETRIEVE_PURCHASEORDER:
          
            return { ...state, purchaseorderList: payload };
        case DELETE_PURCHASEORDER:
            const results = state.purchaseorderList.filter(c => c.purchaseOrderID !== payload.purchaseOrderID);
            return {
                ...state,
                purchaseorderList: results
            };

        default:
            return state;
    }
};
export default purchaseorderReducer;