import {
    BUYER_LOADING,
    RETRIEVE_BUYER,
    DELETE_BUYER,
} from "./types";

import TaskService from "../services/Master/Companyservice";

const buyerLoading = (isStatus) => ({
    type: BUYER_LOADING,
    payload: isStatus,
});

export const deleteBuyer = (companyBuyerSupID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_BUYER,
            payload: companyBuyerSupID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrieveBuyer = (parmas) => async (dispatch) => {
    try {
        dispatch(buyerLoading(true));
        const res = await TaskService.getAllCompanyBuyerSupList(parmas.companyid, parmas.tabletype);
        dispatch({
            type: RETRIEVE_BUYER,
            payload: res.data,
        });
        dispatch(buyerLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(buyerLoading(false));
    }
};