import axios from "axios";
import authHeader from "../auth-header";

class DepartmentService {
        GetDepartmentList() {
        return axios.get(window.$APIBaseURL + "Master/GetDepartmentList?DepartmentID=0", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateDepartmentGrid(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateDepartmentGrid',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

      InsertUpdateDepartment(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateDelDepartment',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
    LoadDepartmentList() {
        return axios.get(window.$APIBaseURL + "Master/LoadDepartmentList", {
            headers: {
                authorization: authHeader()
            }
        });
    }
}

export default new DepartmentService();