import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import { Modal, Button } from "react-bootstrap";
// import ProcessTypeService from "../../services/Master/ProcessTypeService";
import MasterSizeService from "../../services/Master/MasterSizeService"
import Nodify from "../Common/ReactNotification";
// import { insertUpdateProcessTypeGrid } from "../../actions/ProcessType";

const MasterSizePopup = ({ props,
    getMasterSizeList,
    MasterSizeCallback,
    showpopup,
    MasterSizeQuick
}) => {

    const EmptyGroupSizeInputFields =
        [{
            Id: 0,
            Name: '',
            // MasterSizeID: 0,
            // MasterSizeName: '',
        }]

    const [buttonLoader, setButtonLoader] = useState(false);
    const [inputFields, setInputFields] = useState(EmptyGroupSizeInputFields);
    const [masterSizeList, setMasterSizeList] = useState([]);
    const [show, setShow] = useState(true);
    const [sizeSubmitted, setSizeSubmitted] = useState(false);

    const dispatch = useDispatch();
    //reducer
    const reducerState = useSelector((state) => state);
    const { user: currentUser } = reducerState.auth;
    //const getProcessTypeList = reducerState.processType.masterSizeList;

    const handlePopupClose = () => {
        MasterSizeCallback(false);
    };

    const CheckDuplicate = (index) => {

        const values = [...inputFields];
        if (getMasterSizeList) {
            for (var i = 0; i < getMasterSizeList.length; i++) {
                if (getMasterSizeList[i].label.trim().toLowerCase() === values[index].Name.trim().toLowerCase()) {
                    values[index].Name = '';
                    setInputFields(values);
                    setMasterSizeList({ Createdby: currentUser.employeeinformationID, MasterSizeInformation: values });
                    Nodify('Warning!', 'warning', "This name is already exist.");
                    return false;
                }
            }
        }
    }

    const handleMasterSizeChange = (event) => {

        setSizeSubmitted(false);
        const values = [...inputFields];
        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value.trim();
        }
        values[0].Name = inputText;
        setInputFields(values);
        setMasterSizeList({ Createdby: currentUser.employeeinformationID, MasterSizeInformation: values });
    }

    const SaveMasterSize = (e) => {

        setButtonLoader(true);
        e.preventDefault();
        const values = [...inputFields];
        if (values[0].Name.trim() === '') {
            setSizeSubmitted(false);
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Size  is required.');
        }
        if (values[0].Name.trim()) {
            MasterSizeService.InsertUpdateMasterSizeGrid(masterSizeList).then(res => {

                if (res.data.outputResult === "1") {
                    Nodify("Success!", "success", "Size added successfully.");
                    MasterSizeCallback(true);
                } else if (res.data.outputResult === "-2") {
                    Nodify('Warning!', 'warning', 'This name is already exist.');
                }
                setButtonLoader(false);
            })
                .catch(e => {
                    console.log(e);
                });
        }
        else {
            setSizeSubmitted(true);
        }
    }

    return (
        // <Modal show={showpopup} size="md" dragable backdrop="static" keyboard={false} onHide={() => handlePopupClose()}>
        //     <Modal.Header closeButton>
        //     <Modal.Title>Add Season</Modal.Title>
        //     </Modal.Header>
        //       <Modal.Body>
        <div id="registration-form">
            <Form>
                <div className="row">
                    <div className="form-group col-sm-12">
                        <label htmlFor="Name">
                            Size Name
                            <span className="text-danger">*</span>
                        </label>
                        <Input
                            placeholder={"Enter Size"}
                            id="Name"
                            name="Name"
                            type="text"
                            maxLength="150"
                            value={inputFields.Name}
                            autoComplete="off"
                            onChange={handleMasterSizeChange}
                            className='form-control'
                            onBlur={() => CheckDuplicate(0)}
                            style={{ border: sizeSubmitted && !inputFields.Name ? '1px solid red' : '' }}
                            autoFocus
                        />
                    </div>
                </div>
                <br />
                <div style={{ textAlign: "right" }}>
                    <Button type="button" className="btn btn-success" onClick={SaveMasterSize} disabled={buttonLoader}>
                        <i className="fa fa-check right"></i> &nbsp; Save
                    </Button>
                </div>
            </Form>
        </div>

        //   </Modal.Body>
        //   <Modal.Footer>
        //     <Button  type="submit" className="btn btn-success" onClick={SaveSeason} disabled={buttonLoader}>
        //        <i className="fa fa-check right"></i> &nbsp; Save
        //     </Button>
        //   </Modal.Footer>
        // </Modal>
    )
};

export default MasterSizePopup;