import React, { useState, useEffect, Fragment } from "react";
import Form from "react-validation/build/form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import companyService from "../../../services/Master/Companyservice";
import Nodify from "../../Common/ReactNotification"
import { useSelector } from "react-redux";

const CompanyHoliday = ({
    holidayCallback,
    IsValidationCallback,
    HolidayList,
    Id,
    Buttonview,
    MenuPermission,
    PageName
}) => {

    const [inputFields, setInputFields] = useState([{
        HolidayInformationID: 0,
        HolidayName: '',
        HolidayDate: '',
        HolidayEndDate: '',
        IsDeleted: 0,
        NoOfDays: 0,
        // HolidayDate: new Date()
    }]);

    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    if (menulist !== undefined) {
        if (PageName === 'Company') {
            activeMenu = menulist.filter(i => i.menuName === 'Company');
        }
        else if (PageName === 'Buyer') {
            activeMenu = menulist.filter(i => i.menuName === "BuyerList");
        }
        else if (PageName === 'Supplier') {
            activeMenu = menulist.filter(i => i.menuName === "Supplier");
        }
    }
    //end enable menu
    const [submitted, setSubmitted] = useState(false);
    const [getYearList, SetYearList] = useState([]);
    const [getselectedDate, SetselectedDate] = useState(new Date().getFullYear());
    let DynamicClassName = "col-lg-10 col-sm-8 col-xs-12"
    if (Buttonview === "View") {
        DynamicClassName = "col-lg-12 col-sm-12 col-xs-12"
    }

    //     const handleRefreshHoliday = (event)=>{
    //         event.target.value="2021";
    //         setDate(event);
    //    }

    const handleRefreshHoliday = (event) => {
       
        const values = [...inputFields]
        const d = new Date();
        let year = d.getUTCFullYear();
        event.target.value = year.toString();
        setDate(event);
        HolidayList.forEach(holiday => {
            values.push({
                HolidayInformationID: holiday.holidayInformationID,
                HolidayName: holiday.holidayName,
                HolidayDate: new Date(holiday.holidayDate),
                HolidayEndDate: holiday.holidayEndDate === null ? "" : new Date(holiday.holidayEndDate),
                IsDeleted: holiday.isDeleted === undefined ? 0 : holiday.isDeleted,
                NoOfDays: holiday.noOfDays
            });
            setInputFields(values);
        });
    }

    useEffect(() => {
       
        let HolidayInput = []
        HolidayList.forEach(holiday => {
            HolidayInput.push({
                HolidayInformationID: holiday.holidayInformationID,
                HolidayName: holiday.holidayName,
                HolidayDate: new Date(holiday.holidayDate),
                HolidayEndDate: holiday.holidayEndDate === null ? "" : new Date(holiday.holidayEndDate),
                IsDeleted: holiday.isDeleted === undefined ? 0 : holiday.isDeleted,
                NoOfDays: holiday.noOfDays
            });
            setInputFields(HolidayInput);
            holidayCallback(HolidayInput)
        })


        companyService.loadYearList().then((response) => {
            if (response.data) {
                SetYearList(response.data);
            }
        }).catch(() => { });

        // if (Id !== 0) {
        //     if (HolidayList.length !== 0) {
        //         const values = [...inputFields];
        //         values.splice(0, 1);
        //         setInputFields(values);
        //     }
        // }
    }, [HolidayList]);

    useEffect(() => {
        setSubmitted(IsValidationCallback);
    }, [IsValidationCallback === true]);



    const ValidationPopup = (Msg) => {
        Nodify('Warning!', 'warning', Msg);

        return false;
    }

    const handleInputChanges = (index, event, varName) => {
        const values = [...inputFields];

        if (varName === "HolidayDate") { // let Date = moment(event).format('DD/MM/YYYY');
            let Date = event;
            values[index].HolidayDate = Date;
            for (var j = 0; j < values.length; j++) {
                if (j !== index) {
                    if (values[index].HolidayEndDate && values[index].HolidayEndDate !== "" && values[index].HolidayEndDate !== null && values[index].HolidayDate !== "" && values[index].HolidayDate !== null) {
                        if (values[j].HolidayEndDate !== "" && values[j].HolidayEndDate !== null && values[j].HolidayDate !== "" && values[j].HolidayDate !== null) {
                            if (values[j].HolidayDate.getTime() >= values[index].HolidayDate.getTime() && values[j].HolidayEndDate.getTime() <= values[index].HolidayEndDate.getTime()) {
                                values[index].HolidayDate = '';
                                values[index].HolidayEndDate = '';
                                ValidationPopup("This date range already selected.")
                            }
                            else if (values[j].HolidayDate.getTime() === values[index].HolidayDate.getTime()) {
                                values[index].HolidayDate = '';
                                ValidationPopup("This Start date already selected.")
                            }
                            else if (values[j].HolidayEndDate.getTime() === values[index].HolidayEndDate.getTime()) {
                                values[index].HolidayEndDate = '';
                                ValidationPopup("This End date already selected.")
                            }
                            else if (values[j].HolidayDate.getTime() === values[index].HolidayEndDate.getTime()) {
                                values[index].HolidayEndDate = '';
                                ValidationPopup("This End date already selected.")
                            }
                            else if (values[j].HolidayEndDate.getTime() === values[index].HolidayDate.getTime()) {
                                values[index].HolidayDate = '';
                                ValidationPopup("This Start date already selected.")
                            }



                        }
                    }
                    else if (values[index].HolidayEndDate && values[index].HolidayEndDate !== "" && values[index].HolidayEndDate !== null) {
                        if (values[j].HolidayEndDate !== "" && values[j].HolidayEndDate !== null) {
                            if (values[j].HolidayEndDate.getTime() === values[index].HolidayEndDate.getTime()) {
                                values[index].HolidayEndDate = '';
                                ValidationPopup("This End date already selected.")
                            }

                            else if (values[j].HolidayDate.getTime() === values[index].HolidayEndDate.getTime()) {
                                values[index].HolidayEndDate = '';
                                ValidationPopup("This End date already selected.")
                            }
                        }
                    }
                    else if (values[index].HolidayDate !== "" && values[index].HolidayDate !== null) {

                        if (values[j].HolidayDate && values[j].HolidayDate !== "" && values[j].HolidayDate !== null) {
                            if (values[j].HolidayDate.getTime() === values[index].HolidayDate.getTime()) {
                                values[index].HolidayDate = '';
                                ValidationPopup("This Start date already selected.")
                            }
                            else if (values[j].HolidayEndDate && values[j].HolidayEndDate.getTime() === values[index].HolidayDate.getTime()) {
                                values[index].HolidayDate = '';
                                ValidationPopup("This Start date already selected.")
                            }
                            else if (values[j].HolidayEndDate && values[j].HolidayDate.getTime() <= values[index].HolidayDate.getTime() && values[j].HolidayEndDate.getTime() >= values[index].HolidayDate.getTime()) {
                                values[index].HolidayDate = '';
                                values[index].HolidayEndDate = '';
                                ValidationPopup("This Start date already selected.")
                            }

                        }


                    }
                }
            }
            if (values[index].HolidayEndDate !== "" && values[index].HolidayEndDate !== null && values[index].HolidayDate !== "" && values[index].HolidayDate !== null) {
                var Difference_In_Time = values[index].HolidayEndDate.getTime() - values[index].HolidayDate.getTime();
                var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                values[index].NoOfDays = Difference_In_Days === 0 ? 1 : (Difference_In_Days + 1);
            }
            else if ((values[index].HolidayEndDate !== "" && values[index].HolidayEndDate !== null) || (values[index].HolidayDate !== "" && values[index].HolidayDate !== null)) {
                values[index].NoOfDays = 1;
            }
            else {
                values[index].NoOfDays = 0;
            }
        } else if (varName === "HolidayEndDate") { // let Date = moment(event).format('DD/MM/YYYY');
            let Date = event;
            values[index].HolidayEndDate = Date;
            for (var j = 0; j < values.length; j++) {
                if (j !== index) {
                    if (values[index].HolidayEndDate !== "" && values[index].HolidayEndDate !== null && values[index].HolidayDate !== "" && values[index].HolidayDate !== null) {
                        if (values[j].HolidayEndDate !== "" && values[j].HolidayEndDate !== null && values[j].HolidayDate !== "" && values[j].HolidayDate !== null) {

                            if (values[j].HolidayDate.getTime() <= values[index].HolidayEndDate.getTime() && values[j].HolidayEndDate.getTime() >= values[index].HolidayEndDate.getTime()) {
                                values[index].HolidayDate = '';
                                values[index].HolidayEndDate = '';
                                ValidationPopup("This date range already selected.")
                            }
                            else if (values[j].HolidayDate.getTime() === values[index].HolidayDate.getTime()) {
                                values[index].HolidayDate = '';
                                ValidationPopup("This Start date already selected.")
                            }
                            else if (values[j].HolidayEndDate.getTime() === values[index].HolidayEndDate.getTime()) {
                                values[index].HolidayEndDate = '';
                                ValidationPopup("This End date already selected.")
                            }
                            else if (values[j].HolidayDate.getTime() === values[index].HolidayEndDate.getTime()) {
                                values[index].HolidayEndDate = '';
                                ValidationPopup("This End date already selected.")
                            }
                            else if (values[j].HolidayEndDate.getTime() === values[index].HolidayDate.getTime()) {
                                values[index].HolidayDate = '';
                                ValidationPopup("This Start date already selected.")
                            }
                            if (values[index].HolidayEndDate !== "" && values[index].HolidayEndDate !== null && values[index].HolidayDate !== "" && values[index].HolidayDate !== null) {
                                if (values[j].HolidayDate.getTime() >= values[index].HolidayDate.getTime() && values[j].HolidayEndDate.getTime() <= values[index].HolidayEndDate.getTime()) {
                                    values[index].HolidayDate = '';
                                    values[index].HolidayEndDate = '';
                                    ValidationPopup("This date range already selected.")
                                }
                            }

                        }
                    }
                    else if (values[index].HolidayEndDate !== "" && values[index].HolidayEndDate !== null) {
                        if (values[j].HolidayEndDate !== "" && values[j].HolidayEndDate !== null) {
                            if (values[j].HolidayEndDate.getTime() === values[index].HolidayEndDate.getTime()) {
                                values[index].HolidayEndDate = '';
                                ValidationPopup("This End date already selected.")
                            }

                            else if (values[j].HolidayDate.getTime() === values[index].HolidayEndDate.getTime()) {
                                values[index].HolidayEndDate = '';
                                ValidationPopup("This End date already selected.")
                            }
                            else if (values[j].HolidayDate.getTime() <= values[index].HolidayEndDate.getTime() && values[j].HolidayEndDate.getTime() >= values[index].HolidayEndDate.getTime()) {
                                values[index].HolidayDate = '';
                                values[index].HolidayEndDate = '';
                                ValidationPopup("This End date already selected.")
                            }
                        }
                    }
                    else if (values[index].HolidayDate !== "" && values[index].HolidayDate !== null) {
                        if (values[j].HolidayDate !== "" && values[j].HolidayDate !== null) {
                            if (values[j].HolidayDate.getTime() === values[index].HolidayDate.getTime()) {
                                values[index].HolidayDate = '';
                                ValidationPopup("This Start date already selected.")
                            }
                            else if (values[j].HolidayEndDate && values[j].HolidayEndDate.getTime() === values[index].HolidayDate.getTime()) {
                                values[index].HolidayDate = '';
                                ValidationPopup("This Start date already selected.")
                            }

                        }

                    }
                }
            }
            if (values[index].HolidayEndDate !== "" && values[index].HolidayEndDate !== null && values[index].HolidayDate !== "" && values[index].HolidayDate !== null) {
                var Difference_In_Time = values[index].HolidayEndDate.getTime() - values[index].HolidayDate.getTime();
                var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                values[index].NoOfDays = Difference_In_Days === 0 ? 1 : (Difference_In_Days + 1);
            }
            else if ((values[index].HolidayEndDate !== "" && values[index].HolidayEndDate !== null) || (values[index].HolidayDate !== "" && values[index].HolidayDate !== null)) {
                values[index].NoOfDays = 1;
            }
            else {
                values[index].NoOfDays = 0;
            }
        } else if (varName === "HolidayName") {
            let inputText = '';
            if (event.target.value.trim() !== '') { inputText = event.target.value; }
            values[index].HolidayName = inputText;
        }

        setInputFields(values);
        holidayCallback(values);
    };



    const handleAddFields = (index) => {
        const values = [...inputFields];
        var CheckValue1 = [...inputFields].filter(x => x.IsDeleted === 0);
        var CheckValue = CheckValue1[index];
        if (CheckValue.HolidayName && CheckValue.HolidayDate) {
            values.push({ HolidayInformationID: 0, HolidayName: '', HolidayDate: '', HolidayEndDate: '', IsDeleted: 0, NoOfDays: 0 });
            setInputFields(values);
            setSubmitted(false);
        } else {
            Nodify('Warning!', 'warning', 'Please fill this holiday mandatory(*) fields.');

            setSubmitted(true);
        }
    };

    const handleRemoveFields = index => {
        const values = [...inputFields];
        if (values[index].HolidayInformationID === 0) {
            values.splice(index, 1);
        }
        else {
            values[index].IsDeleted = 1;
        } setInputFields(values);
        holidayCallback(values);
    };

    const setDate = (event) => {
        // console.log(inputFields);
        setInputFields([]);
        SetselectedDate(event.target.value);
        companyService.BindHolidayList(Id, event.target.value).then((response) => {
            if (response.data) {
                let holidayList = response.data;
                let arrHolidayList = [];
                if (holidayList.length !== 0) {
                    holidayList.forEach(holiday => {
                        arrHolidayList.push({
                            HolidayInformationID: holiday.holidayInformationID,
                            HolidayName: holiday.holidayName,
                            HolidayDate: new Date(holiday.holidayDate),
                            HolidayEndDate: holiday.holidayEndDate === null ? "" : new Date(holiday.holidayEndDate),
                            IsDeleted: holiday.isDeleted === undefined ? 0 : holiday.isDeleted,
                            NoOfDays: holiday.noOfDays
                        });
                    })
                    setInputFields(arrHolidayList);
                }
                else {
                    arrHolidayList.push({
                        HolidayInformationID: 0,
                        HolidayName: "",
                        HolidayDate: "",
                        HolidayEndDate: "",
                        IsDeleted: 0,
                        NoOfDays: 0
                    });
                    setInputFields(arrHolidayList);
                }

                holidayCallback(arrHolidayList);
            }
        }).catch(() => { });
    };
    const CustomInput = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
            />
        )
    }

    return (
        <>
            <div className="row">

                <div className="col-sm-12">
                    <div className="col-sm-4">
                        &nbsp;
                    </div>
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label htmlFor="drpYear">Year</label>
                            <span className="input-icon icon-right">
                                <select id="drpYear"
                                    name="AddressType"
                                    className="form-select"
                                    value={
                                        getselectedDate
                                    }
                                    onChange={
                                        event => setDate(event)
                                    }

                                >
                                    <option>- Select Year -</option>
                                    {
                                        getYearList.map(item => (
                                            <option key={
                                                item.id
                                            }
                                                value={
                                                    item.id
                                                }>
                                                {
                                                    item.name
                                                } </option>
                                        ))
                                    } </select>
                            </span>
                        </div>
                    </div>


                    <div className="col-sm-4" style={{ marginTop: "24px" }}>
                        <div className="form-group">
                            <label>&nbsp;</label>
                            <i className="fa fa-refresh btn btn-info" title="Refresh Holiday list"
                                onClick={
                                    event => handleRefreshHoliday(event)
                                } >
                            </i>
                        </div>
                    </div>

                </div>
            </div>
            <div className="row">    {
                inputFields.map((inputField, index) => (
                    <Fragment key={
                        `${inputField}~${index}`
                    }>
                        {

                            Buttonview === "View" &&
                            <>
                            </>
                        }
                        <div className={DynamicClassName} style={{ display: inputField.IsDeleted === 1 ? "none" : "block" }}>
                            <div className="widget flat radius-bordered">

                                <div className="panel-group accordion" id="accordion">


                                    <div className="panel panel-default">
                                        <div className="panel-heading ">
                                            <h4 className="mb-0 panel-title">
                                                <a className="btn btn-link font_wight" data-toggle="collapse" data-parent="accordion"
                                                    href={
                                                        "#collapseHol_" + index
                                                    }>
                                                    {inputField.HolidayInformationID === 0 || inputField.HolidayInformationID === undefined ? 'New Holiday' : inputField.HolidayName
                                                    } </a>
                                            </h4>
                                        </div>

                                        <div id={
                                            "collapseHol_" + index
                                        }
                                            className
                                            ={inputField.HolidayInformationID === 0 || inputField.HolidayInformationID === undefined ? 'panel-collapse collapse in' : 'panel-collapse collapse'}>

                                            <div className="widget-body">
                                                <div id="registration-form">
                                                    <Form role="form">
                                                        <div className="row">
                                                            <div className="col-sm-3">
                                                                <div className="form-group">
                                                                    <label htmlFor={
                                                                        "HolidayName_" + index
                                                                    }>Holiday Name<span className="text-danger">*</span>
                                                                    </label>
                                                                    <span className="input-icon icon-right">
                                                                        <input type="text" className="form-control"
                                                                            id={
                                                                                "HolidayName_" + index
                                                                            }
                                                                            name="HolidayName"
                                                                            placeholder="Enter Holiday Name"
                                                                            maxLength="50"
                                                                            value={
                                                                                inputField.HolidayName
                                                                            }
                                                                            onChange={
                                                                                event => handleInputChanges(index, event, "HolidayName")
                                                                            }
                                                                            style={
                                                                                {
                                                                                    border: submitted && !inputField.HolidayName ? '1px solid red' : ''
                                                                                }
                                                                            } />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-3">
                                                                <div className="form-group">
                                                                    <label htmlFor={
                                                                        "HolidayDate" + index
                                                                    }>Holiday (Start Date)<span className="text-danger">*</span>
                                                                    </label>
                                                                    <span className="input-icon icon-right"
                                                                        style={
                                                                            {
                                                                                border: submitted && !inputField.HolidayDate ? '1px solid red' : ''
                                                                            }
                                                                        }>
                                                                        <DatePicker className="form-control" name="HolidayDate"
                                                                            id={
                                                                                "HolidayDate" + index
                                                                            }
                                                                            selected={
                                                                                inputField.HolidayDate
                                                                            }
                                                                            onChange={
                                                                                event => handleInputChanges(index, event, "HolidayDate")
                                                                            }
                                                                            dateFormat="dd/MM/yyyy"
                                                                            peekNextMonth
                                                                            showMonthDropdown
                                                                            // showYearDropdown
                                                                            dropdownMode="scroll"
                                                                            autoComplete="off"
                                                                            minDate={new Date("01/01/" + getselectedDate)}
                                                                            maxDate={inputField.HolidayEndDate === "" ? (new Date("12/31/" + getselectedDate)) : inputField.HolidayEndDate}
                                                                            customInput={<CustomInput />}
                                                                            isClearable={inputField.HolidayDate === '' ? false : true}
                                                                        />

                                                                    </span>
                                                                </div>
                                                                {/* <MultipleDatePicker
      onSubmit={dates => console.log("selected dates ", dates)}
    /> */} </div>
                                                            <div className="col-sm-3">
                                                                <div className="form-group">
                                                                    <label htmlFor={
                                                                        "HolidayEndDate" + index
                                                                    }>End Date</label>
                                                                    <span className="input-icon icon-right">
                                                                        <DatePicker className="form-control"
                                                                            name="HolidayEndDate"
                                                                            id={
                                                                                "HolidayEndDate" + index
                                                                            }
                                                                            selected={
                                                                                inputField.HolidayEndDate
                                                                            }
                                                                            onChange={
                                                                                event => handleInputChanges(index, event, "HolidayEndDate")
                                                                            }
                                                                            dateFormat="dd/MM/yyyy"
                                                                            peekNextMonth
                                                                            showMonthDropdown
                                                                            // showYearDropdown
                                                                            dropdownMode="scroll"
                                                                            autoComplete="off"
                                                                            minDate={inputField.HolidayDate === "" ? (new Date("01/01/" + getselectedDate)) : inputField.HolidayDate}
                                                                            maxDate={new Date("12/31/" + getselectedDate)}
                                                                            customInput={<CustomInput />}
                                                                            isClearable={inputField.HolidayEndDate === '' ? false : true}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label htmlFor={
                                                                        "NoOfDays_" + index
                                                                    }>No of Days
                                                                    </label>
                                                                    <span className="input-icon icon-right">
                                                                        <input type="text" className="form-control"
                                                                            id={
                                                                                "NoOfDays_" + index
                                                                            }
                                                                            name="NoOfDays"
                                                                            placeholder="No of Days"
                                                                            maxLength="50"
                                                                            value={
                                                                                inputField.NoOfDays
                                                                            }
                                                                            disabled
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>



                        {
                            MenuPermission && MenuPermission.isAdd === 1 &&

                            Buttonview !== "View" &&
                            <div className="col-lg-1_own col-xs-12" style={
                                {
                                    display: inputField.IsDeleted === 1 ? "none" : "block"
                                }}>
                                <span>
                                    <span style={
                                        {
                                            display: inputField.IsDeleted === 1 ? "none" : (inputFields.filter(x => x.IsDeleted === 0).length === 1 ? "none" : "")
                                        }
                                    }>
                                        {
                                            MenuPermission && MenuPermission.isDelete === 1 &&
                                            <button className="btn btn-danger" title="Delete Holiday"
                                                // style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}
                                                style={{ display: MenuPermission && MenuPermission.isDelete === 1 ? '' : 'none' }}

                                                onClick={
                                                    () => handleRemoveFields(index)
                                                }>
                                                <i className="fa fa-trash-o"></i>
                                            </button>
                                        }
                                    </span>
                                </span>
                            </div>
                        }
                    </Fragment>
                ))
            }
                {
                    MenuPermission && MenuPermission.isAdd === 1 &&
                    Buttonview !== "View" &&
                    inputFields.filter(x => x.IsDeleted === 0).map((inputField1, index) => (

                        inputFields.filter(x => x.IsDeleted === 0).length === (index + 1) && (
                            <div className="col-lg-1_own col-xs-12">
                                <span>
                                    <button className="btn btn-success" title="Add Holiday"
                                        //   style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}
                                        style={{ display: MenuPermission && MenuPermission.isAdd === 1 ? '' : 'none' }}
                                        onClick={
                                            () => handleAddFields(inputFields.filter(x => x.IsDeleted === 0).length - 1)
                                        }>
                                        <i className="fa fa-plus"></i>
                                    </button>
                                </span>
                            </div>)
                    ))
                }
            </div>
        </>
    );
};

export default CompanyHoliday;
