import React, { useState, useEffect, useRef } from "react";
import CityService from "../../../services/Master/CityService";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
//import Select from "react-validation/build/select";
import $ from "jquery";
//import {store} from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import ReactNotification from 'react-notifications-component';
import validator from 'validator'
import Nodify from "../../Common/ReactNotification"
import SelectCombobox from 'react-select';
import SearchRetain from "../../Common/SearchRetain";
import { useSelector } from "react-redux";

const CityRegister = (props) => {
    const form = useRef();
    const { user: currentUser } = useSelector((state) => state.auth);

    const [stateList, setstateList] = useState([]);
    const [countryList, setcountryList] = useState([]);
    const [getCityList, setCityList] = useState({});
    const [ButtonName, setButtonName] = useState("Save");
    const [HeaderName, setHeaderName] = useState("Add");
    const [submitted, setSubmitted] = useState({ City: false, State: false, country: false });
    const [buttonLoader, setButtonLoader] = useState(false);

    let cityValue = {
        Operation: 1,
        CityID: 0,
        CityName: '',
        StateID: 0,
        CountryID: 0,
        Createdby: currentUser.employeeinformationID
    };
    function BindCity(props) {
        let cityValue = {
            Operation: 1,
            CityID: 0,
            CityName: '',
            StateID: 0,
            CountryID: 0,
            Createdby: currentUser.employeeinformationID
        };
        // Array.from(document.querySelectorAll("input")).forEach(input => (input.value = ""));
        if (props.location.state !== undefined) {
            if (props.location.state.params.CityID !== "") {
                setButtonName("Update");
                setHeaderName("Edit");
                cityValue.CityID = props.location.state.params.cityID;
                cityValue.CityName = validator.trim(props.location.state.params.cityName);
                cityValue.StateID = props.location.state.params.stateID;
                cityValue.CountryID = props.location.state.params.countryID;
                cityValue.Operation = 2;
                return cityValue;
            }
        } else {
            return cityValue;
        }
    }


    useEffect(() => {
        CityService.loadStateList().then((response) => {
            if (response.data) {
                setstateList(response.data);
            }
        }).catch(() => { });

        CityService.loadCountryList().then((response) => {
            if (response.data) {
                setcountryList(response.data);
            }
        }).catch(() => { });

        // sethideValidation(0);
        let bindCityList = BindCity(props);
        setCityList(bindCityList)
    }, []);

    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),

    }

    const handleChangeState = (e) => {
        let cityValue = getCityList;
        cityValue.StateID = 0;
        cityValue.CountryID = 0;
        if (e !== null) {
            const state_Info = stateList.filter(x => x.value === e.value);
            cityValue.StateID = e.value;
            cityValue.CountryID = state_Info[0].countryID;
        }
        if (submitted.City === true && submitted.country === true) {
            setSubmitted({ City: true, State: false, country: true });
        } else if (submitted.City === true && submitted.country === false) {
            setSubmitted({ City: true, State: false, country: false });
        } else if (submitted.City === false && submitted.country === true) {
            setSubmitted({ City: false, State: false, country: true });
        } else {
            setSubmitted({ City: false, State: false, country: false });
        }
        setCityList(cityValue);

    }

    const handleChangeCountry = (e) => {
        let cityValue = getCityList;
        cityValue.CountryID = 0;
        if (e !== null) { cityValue.CountryID = e.value; }
        if (submitted.City === true && submitted.State === true) {
            setSubmitted({ City: true, State: true, country: false });

        } else if (submitted.City === true && submitted.State === false) {
            setSubmitted({ City: true, State: false, country: false });
        } else if (submitted.City === false && submitted.State === true) {
            setSubmitted({ City: false, State: true, country: false });
        } else {
            setSubmitted({ City: false, State: false, country: false });
        }
        setCityList(cityValue);
    }

    const handleChange = (e) => {
        let cityValue = getCityList;
        let inputText = '';
        if (e.target.value.trim() !== '') {
            inputText = e.target.value;
        }
        if (submitted.country === true && submitted.State === true) {
            setSubmitted({ City: false, State: true, country: true });

        } else if (submitted.country === true && submitted.State === false) {
            setSubmitted({ City: false, State: false, country: true });
        } else if (submitted.country === false && submitted.State === true) {
            setSubmitted({ City: false, State: true, country: false });
        } else {
            setSubmitted({ City: false, State: false, country: false });
        }

        cityValue[e.target.name] = inputText
        setCityList(cityValue);
    }

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/cityList",
            state: {
                message: Func
            }
        });
    }

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/cityList')
        } else {
            // setSubmitted({ City: false, State: false, country: false });
            // let bindCityList = BindCity(props);
            // setCityList(bindCityList)
            window.location.reload();
        }
    }

    const SaveCity = (e) => {
        e.preventDefault();
        setSubmitted({ City: true, State: true, country: true });
        if (getCityList.CityName.trim() === '' && getCityList.CountryID === 0 && getCityList.StateID === 0) {
            setButtonLoader(false);

            Nodify('Warning!', 'warning', 'Please fill all mandatory (*) fields.');
        }
        else if (getCityList.CityName.trim() === '') {
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'City is required');
        }
        else if (getCityList.CountryID === 0) {
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Country is required');
        }
        else if (getCityList.StateID === 0) {
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'State is required');
        }

        if (getCityList.CityName.trim() && getCityList.CountryID !== 0 && getCityList.StateID !== 0) {
            getCityList.CityName = getCityList.CityName.trim();
            CityService.addCity(getCityList).then(res => { // const getData = CityService.addCity(getCityList).then(res => {

                var page = "Remove";
                SearchRetain(page);
                let Func = 'Add';
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "-2") {
                    $("#txtCityName").val("");
                    Nodify('Warning!', 'warning', 'City already exist');
                }
                setButtonLoader(false);

            });
        }
    };


    const options = stateList
    // {
    //     value: 'chocolate',
    //     label: 'Chocolate'
    // }, {
    //     value: 'strawberry',
    //     label: 'Strawberry'
    // }, {
    //     value: 'vanilla',
    //     label: 'Vanilla'
    // }

    return (
        <div className="page-body">
            <ReactNotification />


            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">{HeaderName} City</h3>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <Form onSubmit={SaveCity}
                                    ref={form}>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <Input name="CityID" className="form-control"
                                                    onChange={handleChange}
                                                    value={
                                                        getCityList.CityID
                                                    }
                                                    type="number"
                                                    style={
                                                        { display: 'none' }
                                                    } />
                                                <label htmlFor="txtCityName">City
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <span className="input-icon icon-right">
                                                    <Input placeholder="Enter City" id="txtCityName" name="CityName" //className="form-control"
                                                        value={
                                                            getCityList.CityName
                                                        }
                                                        type="text"
                                                        autoComplete="off"
                                                        autoFocus
                                                        maxLength="25"
                                                        style={
                                                            {
                                                                border: submitted.City && !getCityList.CityName ? '1px solid red' : ''
                                                            }
                                                        }
                                                        onChange={handleChange}
                                                        className={
                                                            'form-control' + (
                                                                submitted.City && !getCityList.CityName ? ' is-invalid' : ''
                                                            )} />
                                                    {/* {
                                                    submitted.City && !getCityList.CityName && <div className="alert-danger">City is required</div>
                                                    } */}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="StateID">State</label>
                                                <span className="text-danger">*</span>
                                                <span className="input-icon icon-right">

                                                    <SelectCombobox className="basic-single" name="StateID" id="StateID"

                                                        isDisabled={false}
                                                        isLoading={false}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        styles={submitted.State && !getCityList.StateID ? styles : ''
                                                        }
                                                        onChange={handleChangeState}
                                                        value={stateList.filter(function (option) {
                                                            return option.value === getCityList.StateID;
                                                        })}

                                                        options={stateList}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="CountryID">Country</label>
                                                <span className="text-danger">*</span>
                                                <span className="input-icon icon-right">

                                                    <SelectCombobox className="basic-single" name="countryID" id="countryID"
                                                        //    classNamePrefix="select"
                                                        isDisabled={true}
                                                        isLoading={false}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        styles={submitted.country && !getCityList.CountryID ? styles : ''}

                                                        value={countryList.filter(function (option) {
                                                            return option.value === getCityList.CountryID;
                                                        })}
                                                        options={countryList}

                                                        onChange={handleChangeCountry}

                                                    />
                                                    {/* {
                                                    submitted.country && !getCityList.CountryID && <div className="alert-danger">Country  is required</div>
                                                } */}


                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="buttons-preview" align="right">
                                                <span className="btn btn-primary"
                                                    onClick={
                                                        () => ResetOperation("Back")
                                                    }>
                                                    <i className="fa fa-arrow-circle-left"></i>
                                                    &nbsp;Back</span>
                                                <span className="btn btn-danger"
                                                    onClick={
                                                        () => ResetOperation()
                                                    }>
                                                    <i className="fa fa-times"></i>
                                                    &nbsp;Reset</span>
                                                <button type="submit" className="btn btn-success" disabled={buttonLoader}>

                                                    <i className="fa fa-check right"></i>
                                                    &nbsp;{ButtonName}</button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default CityRegister;
