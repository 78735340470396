

import {
    ALIGNMENT_LOADING,
    RETRIEVE_ALIGNMENT,
    DELETE_ALIGNMENT,
} from "./types";

import AlignmentService from "../services/Master/Alignment";

const AlignmentLoading = (isStatus) => ({
    type: ALIGNMENT_LOADING,
    payload: isStatus,
});

export const deleteAlignent = (alignment) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_ALIGNMENT,
            payload: alignment,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrieveAlignment = () => async (dispatch) => {
    try {
        dispatch(AlignmentLoading(true));
        const res = await AlignmentService.LoadAlignmentTaskOrderDetails();
        dispatch({
            type: RETRIEVE_ALIGNMENT,
            payload: res.data,
        });
        dispatch(AlignmentLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(AlignmentLoading(false));
    }
};