import React, { useState, useEffect, useRef, Fragment } from "react";
import ReactNotification from 'react-notifications-component';
import Nodify from "../../Common/ReactNotification"
import TaskTypeService from "../../../services/Master/TaskTypeService";
import SearchRetain from "../../Common/SearchRetain";
import { useSelector } from "react-redux";

const TaskTypeAdd = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);


    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    if (menulist !== undefined) {
        activeMenu = menulist.filter(i => i.menuName === "TaskType");
    }
    //end enable menu

    const EmptyInputFields =
        [{
            TaskTypeID: 0,
            TaskTypeName: '',
        }]
    const [inputFields, setInputFields] = useState(EmptyInputFields);
    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getTaskTypeList, setTaskTypeList] = useState([]);
    const [ExistingList, setExistingList] = useState([]);

    useEffect(() => {
        TaskTypeService.getTaskTypeList().then((response) => {
            if (response.data) {
                setExistingList(response.data);
            }
        });
    }, []);

    //Enter Error msg
    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                //SaveBuyerInfo(event);
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);

    const handleAddFields = (index) => {
        const values = [...inputFields];
        if (values[index].TaskTypeName.trim() !== '') {
            values.push({
                TaskTypeID: 0,
                TaskTypeName: '',
            });
            setInputFields(values);
            setSubmitted(false);
        }
        else {
            Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
            setSubmitted(true);
        }
    };

    const handleInputChange = (index, event) => {

        const values = [...inputFields];
        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }
        values[index].TaskTypeName = inputText;
        setInputFields(values);
        setTaskTypeList({ Createdby: currentUser.employeeinformationID, TaskTypeInformation: values });
    };

    const handleRemoveFields = index => {
        const values = [...inputFields];
        values.splice(index, 1);
        setInputFields(values);
        setTaskTypeList({ Createdby: currentUser.employeeinformationID, TaskTypeInformation: values });
    };

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/TaskTypeList');
        } else {
            // props.history.push('/AddGender');
            //  setInputFields(EmptyInputFields);
            // setSubmitted(false);
            // setButtonLoader(false);
            window.location.reload();
        }
    }

    const CheckDuplicate = (index) => {
        const values = [...inputFields];
        // genderService.getGenderSizeList().then((response) => {
        if (ExistingList) {
            for (var i = 0; i < ExistingList.length; i++) {
                if (ExistingList[i].taskTypeName.trim().toLowerCase() === values[index].TaskTypeName.trim().toLowerCase()) {
                    values[index].TaskTypeName = '';
                    setInputFields(values);
                    Nodify('Warning!', 'warning', "This name is already exist.");
                    return false;
                }
            }
        }
        // });

        for (var i = 0; i < (inputFields.length); i++) {
            if (i !== index) {
                if (inputFields[i].TaskTypeName.trim().toLowerCase() === inputFields[index].TaskTypeName.trim().toLowerCase()) {
                    values[index].TaskTypeName = "";
                    setInputFields(values);
                    Nodify('Warning!', 'warning', 'This name is already exist.');
                    return false;
                }
            }
        }
    }

    const SaveTaskTypeList = (e) => {
        setButtonLoader(true);
        e.preventDefault();

        if (inputFields.length === 1 && inputFields[0].TaskTypeName === '') {
            setButtonLoader(false);
            setSubmitted(true);
            ValidationPopup("Please fill atleast one Task Type.");
            return false;
        }
        else {
            for (var i = 0; i < (inputFields.length); i++) {
                if (inputFields[i].TaskTypeName.trim() === '') {
                    Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
                    setSubmitted(true);
                    setButtonLoader(false);
                    return false;
                }
            }
            TaskTypeService.InsertUpdateTaskTypeGrid(getTaskTypeList).then(res => {
                var page = "Remove";
                SearchRetain(page);
                let Func = 'Add';
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "-2") {
                    setButtonLoader(false);
                    ValidationPopup("This name is already exist.");
                }
                else if (res.data.outputResult === "0") {
                    setButtonLoader(false);
                    ValidationPopup("Error Occured!");
                }
            });
        }
    }

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "TaskTypeList",
            state: {
                message: Func
            }
        });
    }

    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
        return false;
    }

    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">Add Task Type</h3>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <form>
                                    {
                                        inputFields.map((inputField, index) => (
                                            <Fragment key={
                                                `${inputField}~${index}`
                                            }>
                                                <div className="row row_left10">
                                                    <div className="col-sm-3 divder_style">
                                                        <div className="form-group">
                                                            {/* <label htmlFor={"GenderSizeName_" + index}>Gender Name<span className="text-danger">*</span></label> */}

                                                            <span className="input-icon icon-right">
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"TaskTypeName" + index}
                                                                    name="TaskTypeName"
                                                                    placeholder="Enter Task Type"
                                                                    maxLength="50"
                                                                    value={inputField.TaskTypeName}
                                                                    onChange={event => handleInputChange(index, event)}
                                                                    onBlur={() => CheckDuplicate(index)}
                                                                    style={{ border: submitted && !inputField.TaskTypeName ? '1px solid red' : '' }}
                                                                    autoFocus
                                                                />
                                                            </span>

                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3 paddingstatic">
                                                        {
                                                            inputFields.length !== 1 && (
                                                                <div className="col-lg-1_own col-xs-12">
                                                                    <button type="button" className="btn btn-danger"
                                                                        style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}
                                                                        title="Delete Task Type" onClick={() => handleRemoveFields(index)}>
                                                                        <i className="fa fa-trash-o"></i>
                                                                    </button>
                                                                </div>
                                                            )}
                                                        {
                                                            inputFields.length === (index + 1) && (
                                                                <div className="col-lg-1_own col-xs-12">

                                                                    <button type="button" className="btn btn-success" title="Add Task Type" onClick={() => handleAddFields(index)}>
                                                                        <i className="fa fa-plus"></i>
                                                                    </button>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </Fragment>
                                        ))
                                    }
                                    <div className="col-sm-12">
                                        <div className="buttons-preview" align="right">
                                            <span className="btn btn-primary"
                                                onClick={
                                                    () => ResetOperation("Back")
                                                }>
                                                <i className="fa fa-arrow-circle-left"></i>
                                                &nbsp;Back</span>
                                            <span className="btn btn-danger"
                                                onClick={
                                                    () => ResetOperation()
                                                }>
                                                <i className="fa fa-times"></i>
                                                &nbsp;Reset</span>
                                            <button type="button" className="btn btn-success" disabled={buttonLoader}
                                                onClick={SaveTaskTypeList}>

                                                <i className="fa fa-check right"></i>
                                                &nbsp;Save</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TaskTypeAdd;