import React, { useState, useEffect, useRef, Fragment } from "react";
import ReactNotification from 'react-notifications-component';
import SizeService from "../../../../services/Master/SizeService";
import Nodify from "../../../Common/ReactNotification"
import SearchRetain from "../../../Common/SearchRetain";
import Select from 'react-select';
import MasterSize from "../../../quickMaster/_MasterSize"
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
const AddSize = (props) => {
    const EmptyInputFields =
    {
        SizeDetailsID: 0,
        GroupName: '',
        SizeID: '',
        ReturnSizeID: ''
    }




    const [inputFields, setInputFields] = useState([EmptyInputFields]);
    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getSizeList, setSizeList] = useState([]);
    const [ExistingList, setExistingList] = useState([]);
    const [getMasterSizepopup, setMasterSizepopup] = useState(false);

    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),
    }

    // //Enable Menu
    let activeMenu;
    let MainactiveMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    if (menulist !== undefined) {

        activeMenu = menulist.filter(i => i.menuName === "Size");
        MainactiveMenu = menulist.filter(i => i.menuName === "GroupSize");
    }
    //End Enable Menu

    const values = [...inputFields];

    useEffect(() => {
        SizeService.LoadSizeList().then((response) => {
            if (response.data) {
                let list = response.data;
                setSizeList(list);
            }
        }).catch(() => { });

        SizeService.GetSizeList(0).then((response) => {
            if (response.data) {
                setExistingList(response.data);
            }
        }).catch(() => { });

    }, []);

    const handleAddFields = (index) => {
        if (values[index].GroupName !== '' && values[index].SizeID !== '') {
            values.push(EmptyInputFields);
            setInputFields(values);
            setSubmitted(false);
        } else {
            Nodify('Warning!', 'warning', 'Please fill this mandatory(*) fields.');
            setSubmitted(true);
        }
    }
    const sizeCallback = (value) => {
        if (value === true) {
            SizeService.LoadSizeList().then((response) => {
                if (response.data) {
                    let list = response.data;
                    setSizeList(list);
                }
            }).catch(() => { });

            setMasterSizepopup(false);
        } else {
            setMasterSizepopup(false);
        }
    }

    const handleMasterSize = () => {
        setMasterSizepopup(true);
    }

    const handleInputChange = (index, event) => {
        //setSubmitted(false);
        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }
        values[index].GroupName = inputText;
        setInputFields(values);
    }

    const handleInputChangeSize = (index, event) => {
        debugger
        setSubmitted(false);
        if (event.length === 0) {
            values[index].SizeID = '';
            values[index].ReturnSizeID = '';
        }
        else {
            values[index].ReturnSizeID = event;
            let strValue = '';
            for (let size of event) {
                if (strValue === '') { strValue = size.value.toString() }
                else { strValue = strValue + "," + size.value }
            }
            values[index].SizeID = strValue;
        }
        setInputFields(values);
    }

    const handleRemoveFields = index => {
        values.splice(index, 1);
        setInputFields(values);
    }

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/SizeList');
        } else {
            window.location.reload();
        }
    }

    const CheckDuplicate = (index, Field) => {
        if (ExistingList.length !== 0) {
            if (values[index].GroupName !== '') { // for avoid empty validation
                for (var i = 0; i < ExistingList.length; i++) {
                    let MSG = '';
                    if (ExistingList[i].sizeID === values[index].SizeID &&
                        ExistingList[i].groupName.trim().toLowerCase() === values[index].GroupName.trim().toLowerCase()) {
                        if (Field === "GroupName") {
                            values[index].GroupName = '';
                            MSG = "This group name is already exist.";
                        }
                        else if (Field === "SizeID") {
                            values[index].SizeID = '';
                            values[index].ReturnSizeID = '';
                            MSG = "This size is already exist.";
                        }
                        setInputFields(values);
                        Nodify('Warning!', 'warning', MSG);
                        return false;
                    }
                    else if (ExistingList[i].groupName.trim().toLowerCase() === values[index].GroupName.trim().toLowerCase()) {
                        values[index].GroupName = '';
                        MSG = "This group name is already exist.";
                        setInputFields(values);
                        Nodify('Warning!', 'warning', MSG);
                        return false;
                    }
                }
            }
        }

        for (var j = 0; j < values.length; j++) {
            if (index !== j) {
                if (values[index].GroupName !== '') { // for avoid empty validation
                    if (values[j].SizeID === values[index].SizeID &&
                        values[j].GroupName.trim().toLowerCase() === values[index].GroupName.trim().toLowerCase()) {
                        if (Field === "GroupName") {
                            values[index].GroupName = '';
                        }
                        else if (Field === "SizeID") {
                            values[index].SizeID = '';
                            values[index].ReturnSizeID = '';
                        }
                        setInputFields(values);
                        Nodify('Warning!', 'warning', 'This size is already exist.');
                        return false;
                    }
                    else if (values[j].GroupName.trim().toLowerCase() === values[index].GroupName.trim().toLowerCase()) {
                        values[index].GroupName = '';
                        setInputFields(values);
                        Nodify('Warning!', 'warning', 'This Group Name is already exist.');
                        return false;
                    }
                }
            }
        }
    }

    const SaveCategoryList = (e) => {
        setButtonLoader(true);
        e.preventDefault();
        if (values.length === 1 && (values[0].GroupName === '' || values[0].SizeID === '')) {
            setButtonLoader(false);
            setSubmitted(true);
            ValidationPopup("Please fill atleast one size detail.");
        } else {
            let Isvalid = false;
            for (var j = 0; j < values.length; j++) {
                if (values[j].GroupName === '' || values[j].SizeID === '') {
                    Isvalid = true
                }
            }
            if (!Isvalid) {
                setSubmitted(false);
                let SizeParam = {};
                SizeParam.SizeDetailInformation = values;
                SizeParam.Createdby = 1;
                SizeService.InsertUpdateSizeGrid(SizeParam).then(res => {
                    var page = "Remove";
                    SearchRetain(page);
                    if (res.data.outputResult === "1") {
                        PageRedirect('Add');
                    } else if (res.data.outputResult === "0") {
                        setButtonLoader(false);
                        ValidationPopup("Error Occured!");
                    }
                });
            } else {
                setButtonLoader(false);
                setSubmitted(true);
                ValidationPopup("Please fill all mandatory(*) fields.");
            }
        }
    }

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/SizeList",
            state: {
                message: Func
            }
        });
    }

    const ValidationPopup = (Msg) => {
        Nodify('Warning!', 'warning', Msg);
        setButtonLoader(false);
        return false;
    }

    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">Add Group Size</h3>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <form>
                                    {
                                        inputFields.map((inputField, index) => (
                                            <Fragment key={
                                                `${inputField}~${index}`
                                            }>
                                                <div className="row row_left10">
                                                    <div className="col-sm-12">
                                                        <div className="col-md-3 divder_style">
                                                            <div className="form-group">
                                                                <span className="input-icon icon-right">
                                                                    <label htmlFor={
                                                                        "GroupName_" + index
                                                                    }>Group Name<span className="text-danger">*</span>
                                                                    </label>
                                                                    <input type="text"
                                                                        className="form-control"
                                                                        id={"GroupName_" + index}
                                                                        name="GroupName"
                                                                        placeholder="Enter Category Name"
                                                                        maxLength="50"
                                                                        value={inputField.GroupName}
                                                                        onChange={event => handleInputChange(index, event)}
                                                                        onBlur={() => CheckDuplicate(index, 'GroupName')}
                                                                        style={{ border: submitted && !inputField.GroupName ? '1px solid red' : '' }}
                                                                        autoFocus
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-3 divder_style">
                                                            <div className="form-group">
                                                                <span className="input-icon icon-right">
                                                                    <label htmlFor={
                                                                        "SizeID_" + index
                                                                    }>{
                                                                            activeMenu && activeMenu[0].isQuick === 1 &&

                                                                            <button type='button' className="glyphicon glyphicon-plus AddPlusIcon"
                                                                                onClick={() => handleMasterSize()}
                                                                                title="Add Size">
                                                                            </button>

                                                                        }
                                                                        Size<span className="text-danger">*</span>
                                                                    </label>


                                                                    <Select className="basic-single" name="SizeID"
                                                                        id={
                                                                            "SizeID_" + index
                                                                        }
                                                                        isDisabled={false}
                                                                        isLoading={false}
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        isMulti
                                                                        styles={submitted && inputField.SizeID === '' ? styles : ''}
                                                                        // value={getSizeList.filter(function (option) {
                                                                        //     return option.value === inputField.SizeID;
                                                                        // })}
                                                                        value={inputField.ReturnSizeID}
                                                                        options={getSizeList}

                                                                        onChange={
                                                                            event => handleInputChangeSize(index, event)
                                                                        }
                                                                        onBlur={() => CheckDuplicate(index, 'SizeID')}

                                                                    />

                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-3 paddingstatic">
                                                            {
                                                                inputFields.length !== 1 && (
                                                                    <div className="col-lg-1_own col-xs-12 adddeletemargin">
                                                                        <button type="button" className="btn btn-danger"
                                                                            style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}
                                                                            title="Delete Size" onClick={() => handleRemoveFields(index)}>
                                                                            <i className="fa fa-trash-o"></i>
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            {
                                                                inputFields.length === (index + 1) && (
                                                                    <div className="col-lg-1_own col-xs-12 adddeletemargin">

                                                                        <button type="button" className="btn btn-success" title="Add Size" onClick={() => handleAddFields(index)}>
                                                                            <i className="fa fa-plus"></i>
                                                                        </button>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        getMasterSizepopup === true ?
                                                            <Modal show={getMasterSizepopup === true} size="md" dragable backdrop="static" keyboard={false} onHide={() => setMasterSizepopup(false)}>
                                                                <Modal.Header closeButton><Modal.Title>Add Size</Modal.Title>
                                                                </Modal.Header>
                                                                <Modal.Body>

                                                                    <MasterSize
                                                                        getMasterSizeList={getSizeList}
                                                                        MasterSizeCallback={sizeCallback}
                                                                        showpopup={true}
                                                                    >
                                                                    </MasterSize>
                                                                </Modal.Body>

                                                            </Modal>
                                                            : ''
                                                    }
                                                </div>
                                            </Fragment>
                                        ))
                                    }
                                    <div className="col-sm-12">
                                        <div className="buttons-preview" align="right">
                                            <span className="btn btn-primary"
                                                onClick={
                                                    () => ResetOperation("Back")
                                                }>
                                                <i className="fa fa-arrow-circle-left"></i>
                                                &nbsp;Back</span>
                                            <span className="btn btn-danger"
                                                onClick={
                                                    () => ResetOperation()
                                                }>
                                                <i className="fa fa-times"></i>
                                                &nbsp;Reset</span>
                                            <button type="button" className="btn btn-success"
                                                onClick={SaveCategoryList}
                                                disabled={buttonLoader}>
                                                <i className="fa fa-check right"></i>
                                                &nbsp;Save</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AddSize;
