import {
    TRIMTYPE_LOADING,
    RETRIEVE_TRIMTYPE,
    DELETE_TRIMTYPE,
} from "./types";

import TrimTypeService from "../services/Master/TrimTypeService";

const TrimtypeLoading = (isStatus) => ({
    type: TRIMTYPE_LOADING,
    payload: isStatus,
});

export const deleteTrimtype = (trimTypeID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_TRIMTYPE,
            payload: trimTypeID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrieveTrimtype = () => async (dispatch) => {
    try {
        dispatch(TrimtypeLoading(true));
        const res = await TrimTypeService.GetTrimTypesList();
        dispatch({
            type: RETRIEVE_TRIMTYPE,
            payload: res.data,
        });
        dispatch(TrimtypeLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(TrimtypeLoading(false));
    }
};