import React, { useState, useEffect } from "react";
import CategoryService from "../../../../services/Master/CategoryService";
import Nodify from "../../../Common/ReactNotification";
import SearchRetain from "../../../Common/SearchRetain";
import ReactNotification from 'react-notifications-component';
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import Select from "react-validation/build/select";

const EditCategory = (props) => {
    const [getCategoryTypeList, setCategoryTypeList] = useState([]);
    const [getGroupTypeList, setGroupTypeList] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [getCategory, setCategory] = useState({});
    const [buttonLoader, setButtonLoader] = useState(false);

    function BindCategoryValue(props) {
        let CategoryValue = {};

        if (props.location.state !== undefined) {
            if (props.location.state.params.length !== 0) {
                if (props.location.state.params.categoryID !== 0) {
                    CategoryValue.Operation = 2;
                    CategoryValue.CategoryID = props.location.state.params.categoryID;
                    CategoryValue.CategoryName = props.location.state.params.categoryName;
                    CategoryValue.GroupTypeID = props.location.state.params.groupTypeID;
                    CategoryValue.CategoryTypeID = props.location.state.params.categoryTypeID;
                    CategoryValue.Createdby = 1;
                    return CategoryValue;
                }
            }
        }
        else {
            return CategoryValue;
        }
    }

    useEffect(() => {
        let CategoryInfo = BindCategoryValue(props);
        setCategory(CategoryInfo);

        CategoryService.LoadGroupTYpe().then((response) => {
            if (response.data) {
                setGroupTypeList(response.data);
            }
        }).catch(() => { });

        CategoryService.LoadCategoryTypeList().then((response) => {
            if (response.data) {
                setCategoryTypeList(response.data);
            }
        }).catch(() => { });
    }, []);

    //Enter Error msg
    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                //SaveBuyerInfo(event);
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/CategoryList",
            state: {
                message: Func,
            },
        }
        );
    };

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/CategoryList')
        } else {
            window.location.reload();
        }
    }

    const handleChange = (e) => {
        setSubmitted(false);
        let Value = getCategory;
        if (e.target.name === "GroupTypeID") {
            Value[e.target.name] = parseInt(e.target.value);
        }
        else if (e.target.name === "CategoryName") {
            let inputText = '';
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
                Value[e.target.name] = inputText.trim();
            }
            else {
                Value[e.target.name] = '';
            }
        }
        else if (e.target.name === "CategoryTypeID") {
            Value[e.target.name] = parseInt(e.target.value);
        }

        setCategory(Value);
    }

    const SaveCategory = (e) => {
        setButtonLoader(true);
        e.preventDefault();
        if (getCategory.CategoryName === '' || getCategory.GroupTypeID === 0 || getCategory.CategoryTypeID === 0) {
            setButtonLoader(false);
            setSubmitted(true);
            Nodify('Warning!', 'warning', 'Please fill all mandatory(*) fields.');
            return false;
        }
        if (getCategory.CategoryName.trim()) {
            getCategory.CategoryName = getCategory.CategoryName.trim();
            CategoryService.InsertUpdateCategory(getCategory).then(res => {
                var page = "Remove";
                SearchRetain(page);
                let Func = 'Add';
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "-2") {
                    let CategoryInfo = BindCategoryValue(props);
                    setCategory(CategoryInfo);
                    Nodify('Warning!', 'warning', 'This category is already exist.');
                }
                setButtonLoader(false);
                setSubmitted(false);
            });
        }
        else {
            setSubmitted(true);
        }
    }

    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">
                                Edit Category
                            </h3>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <Form>
                                    <div className="row">
                                        <div className="form-group col-sm-4">
                                            <label htmlFor="GroupTypeID">
                                                Group Type
                                                <span className="text-danger">*</span>
                                            </label>
                                            <span className="input-icon icon-right">
                                                <Select id={
                                                    "GroupTypeID"
                                                }
                                                    name="GroupTypeID"
                                                    className="form-select"
                                                    onChange={
                                                        event => handleChange(event)
                                                    }
                                                    value={
                                                        getCategory.GroupTypeID
                                                    }
                                                    style={
                                                        {
                                                            border: submitted && getCategory.GroupTypeID === 0 ? '1px solid red' : ''
                                                        }
                                                    }>
                                                    <option value="0">- Select Group Type -</option>
                                                    {
                                                        getGroupTypeList.map(item => (
                                                            <option key={
                                                                item.id
                                                            }
                                                                value={
                                                                    item.id
                                                                }>
                                                                {
                                                                    item.name
                                                                } </option>
                                                        ))
                                                    } </Select>
                                            </span>

                                        </div>
                                        <div className="form-group col-sm-4">
                                            <label htmlFor="CategoryName">
                                                Category Name
                                                <span className="text-danger">*</span>
                                            </label>
                                            <span className="input-icon icon-right">
                                                <Input
                                                    placeholder={"Enter Category Name"}
                                                    id="CategoryName"
                                                    name="CategoryName"
                                                    value={getCategory.CategoryName}
                                                    type="text"
                                                    maxLength="50"
                                                    autoComplete="off"
                                                    onChange={handleChange}
                                                    className='form-control'
                                                    style={{ border: submitted && getCategory.CategoryName === '' ? '1px solid red' : '' }}
                                                    autoFocus
                                                />
                                            </span>

                                        </div>
                                        <div className="form-group col-sm-4">
                                            <label htmlFor="CategoryTypeID">
                                                Category Type
                                                <span className="text-danger">*</span>
                                            </label>
                                            <span className="input-icon icon-right">
                                                <Select id={
                                                    "CategoryTypeID"
                                                }
                                                    name={"CategoryTypeID"}
                                                    className="form-select"
                                                    onChange={
                                                        event => handleChange(event)
                                                    }
                                                    value={
                                                        getCategory.CategoryTypeID
                                                    }
                                                    style={
                                                        {
                                                            border: submitted && getCategory.CategoryTypeID === 0 ? '1px solid red' : ''
                                                        }
                                                    }>
                                                    <option value="0">- Select Category Type -</option>
                                                    {
                                                        getCategoryTypeList.map(item => (
                                                            <option key={
                                                                item.id
                                                            }
                                                                value={
                                                                    item.id
                                                                }>
                                                                {
                                                                    item.name
                                                                }
                                                            </option>
                                                        ))
                                                    } </Select>
                                            </span>

                                        </div>
                                    </div>

                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div
                        className="buttons-preview"
                        align="right"
                        style={{ marginTop: "15px" }}
                    >
                        <div className="buttons-preview" align="right">
                            <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                            </span>
                            <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                <i className="fa fa-times"></i>&nbsp;Reset
                            </span>
                            <button
                                type="submit"
                                className="btn btn-success"
                                onClick={SaveCategory}
                                disabled={buttonLoader}
                            >

                                <i className="fa fa-check right"></i>&nbsp;Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )

};

export default EditCategory;