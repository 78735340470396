import {
    REPORTMODULECATEGORY_LOADING,
    RETRIEVE_REPORTMODULECATEGORY,
    DELETE_REPORTMODULECATEGORY
  } from "./types";
import ReportModuleCategoryService from "../services/Master/ReportModuleCategoryService";

const reportmodulecategoryLoading = (isStatus) => ({
    type: REPORTMODULECATEGORY_LOADING,
    payload: isStatus,
  });


  export const createReportModuleCategory = (getReport) => async (dispatch) => {
   
    let resData="";
     await ReportModuleCategoryService.AddReportModuleCategory(getReport).then((res) => {
      resData=res.data;
                
    }).catch((err) => { return Promise.reject(err); });
    return Promise.resolve(resData);
    }
export const retrievereportmodulecategory = () => async (dispatch) => {
   ;
    try {
      
      dispatch(reportmodulecategoryLoading(true));
    const  res = await ReportModuleCategoryService.getReportModuleCategoryList();
      dispatch({
      type: RETRIEVE_REPORTMODULECATEGORY,
      payload: res.data,
    });
    dispatch(reportmodulecategoryLoading(false));
  } catch (err) {
    console.log(err);
    dispatch(reportmodulecategoryLoading(false));
  }
  };

  export const deleteReportModuleCategory = (reportModuleCategoryID) => async (dispatch) => {
   
    try {
      dispatch({
      type: DELETE_REPORTMODULECATEGORY,
      payload:reportModuleCategoryID,
    });
  
  } catch (err) {
    console.log(err);
  }
  };