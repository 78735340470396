import {
    USER_LOADING,
    RETRIEVE_USER,
    RETRIEVE_USERROLE,
    RETRIEVE_MENU,
    RETRIEVE_USERPERMISSON,
    // EXISTING_MENU_BRAND_PERMISSION_LIST,
    EDIT_MENU_USER_PERMISSION_LIST
    //DELETE_USER,
} from "./types";

import RoleService from "../services/Master/RoleService";
import UserService from "../services/Master/UserPermissionService";
import CommonService from "../services/Common/CommonService";

const userLoading = (isStatus) => ({
    type: USER_LOADING,
    payload: isStatus,
});


export const retrieveRole = () => async (dispatch) => {
    try {
       
        dispatch(userLoading(true));
        const res = await RoleService.GetRoleList();
        dispatch({
            type: RETRIEVE_USERROLE,
            payload: res.data,
        });
        dispatch(userLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(userLoading(false));
    }
};


export const retrieveUsername = (Operation) => async (dispatch) => {
    try {
       
        dispatch(userLoading(true));
        const res = await UserService.GetUsernameList(Operation);
        dispatch({
            type: RETRIEVE_USER,
            payload: res.data,
        });
        dispatch(userLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(userLoading(false));
    }
};

export const loadExistingMenuBuyerBrandDetails = (EmpID) => async (dispatch) => {
    dispatch(userLoading(true));
    let resData = "";
    await UserService.GetExistingMenuBuyerBrandDetails(EmpID).then((res) => {
       
        // dispatch({
        //     type: EXISTING_MENU_BRAND_PERMISSION_LIST,
        //     payload: res.data,
        // });
        resData = res.data;
        dispatch(userLoading(false));
    }).catch((err) => {
        dispatch(userLoading(false));
        return Promise.reject(err);
    });
    return Promise.resolve(resData);

     
};

export const GetUserPermissionList= (EmpID) => async (dispatch) => {
    dispatch(userLoading(true));
    let resData = [];
    await UserService.GetUserPermissionList(EmpID).then((res) => {
        dispatch({
            type: EDIT_MENU_USER_PERMISSION_LIST,
            payload: res.data,
        });
        resData = res.data;
        dispatch(userLoading(false));
    }).catch((err) => {
        dispatch(userLoading(false));
        return Promise.reject(err);
    });
    return Promise.resolve(resData);

     
};


export const retrieveMenuname = (EmpId) => async (dispatch) => {
    try {
       
        dispatch(userLoading(true));
        const res = await CommonService.GetMenuList(EmpId);
        dispatch({
            type: RETRIEVE_MENU,
            payload: res.data,
        });
        dispatch(userLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(userLoading(false));
    }
};

export const retrieveUserPermission = (EmpId) => async (dispatch) => {
    try {
       
        dispatch(userLoading(true));
        const res = await UserService.GetUserPermissionList(EmpId);
        dispatch({
            type: RETRIEVE_USERPERMISSON,
            payload: res.data,
        });
        dispatch(userLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(userLoading(false));
    }
};