import {
    GENERALSTRIKEOFF_LOADING,
    RETRIEVE_GENERALSTRIKEOFF,
    DELETE_GENERALSTRIKEOFF,
    RETRIEVE_GENERAL_STRIKEOFF_DETAIL_VIEW_LIST,
    CLEAR_GENERAL_STRIKEOFF_DETAIL_VIEW_SUB_ROW,
    UPDATE_GENERAL_STRIKEOFF_DETAIL_VIEW_CHECK_BOX,
    NEW_TNA_GENERAL_STRIKEOFF_REFERENCE_LIST,
    REMOVE_TNA_GENERAL_STRIKEOFF_REFERENCE_LIST,
    UPDATE_GENERAL_STRIKEOFF_DETAIL_VIEW_ALL_CHECK_BOX,
    TNA_TASK_GENERAL_STRIKEOFF_SUBMISSION_AND_APPROVAL,
    RETRIEVE_GENERAL_STRIKEOFF_SUBMISSION_LIST,
    CLEAR_GENERAL_STRIKEOFF_SUBMISSION_SUB_ROW,
    RETRIEVE_GENERAL_STRIKEOFF_APPROVAL_LIST,
    CLEAR_GENERAL_STRIKEOFF_APPROVAL_SUB_ROW,
    RETRIEVE_GENERAL_STRIKEOFF_APPROVAL_VIEW_LIST,
    CLEAR_GENERAL_STRIKEOFF_APPROVAL_VIEW_SUB_ROW,
    UPDATE_GENERAL_STRIKEOFF_IS_APPROVAL_MANUAL_CLOSE_POPUP,
    REMOVE_GENERAL_STRIKEOFF_REQUEST_VIEW_SUB_ROW
} from "../actions/types";

const initialState = {
    isLoadingGeneralStrikeOff: true,
    generalStrikeOffList: [],
    generalStrikeOffViewDetailParams: {}, //**generalStrikeOffViewDetail**
    generalStrikeOffViewDetailList: [],
    genStrikeOffTNAReferenceList: [],
    genStrikeOffTNATaskList: [],
    genStrikeOffTNAAddtionalTaskList: [],
    generalStrikeOffSubmissionList: [],
    generalStrikeOffSubmissionParams: {},
    editTNATaskStrikeOffSubmissionList: [],
    generalStrikeOffApprovalList: [],
    generalStrikeOffApprovalParams: {},
    generalStrikeOffApprovalViewList: [],
    generalStrikeOffApprovalViewParams: {},

};

const generalStrikeOffReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GENERALSTRIKEOFF_LOADING:
            return {
                ...state,
                isLoadingGeneralStrikeOff: payload
            };
        case RETRIEVE_GENERALSTRIKEOFF:
            return { ...state, generalStrikeOffList: payload };
        case DELETE_GENERALSTRIKEOFF:
            const results = state.generalStrikeOffList.filter(c => c.genStrikeOffId !== payload.genStrikeOffId);
            return {
                ...state,
                generalStrikeOffList: results
            };
        case RETRIEVE_GENERAL_STRIKEOFF_DETAIL_VIEW_LIST:
           
            if (payload.params.StrikeOffId === 0) {

                return {
                    ...state,
                    generalStrikeOffViewDetailList: payload.data.strikeOffList,
                    generalStrikeOffViewDetailParams: payload.params
                };
            } else {

                let index = payload.params.Index;
                let resultSet = payload.data;
                //GeneralStrikeOffViewDetail-(GSOVD)
                let GSOVDStrikeOffList = [...state.generalStrikeOffViewDetailList];
                GSOVDStrikeOffList[index].strikeOffReferenceList = resultSet.strikeOffReferenceList ? resultSet.strikeOffReferenceList : [];
                GSOVDStrikeOffList[index].manualCommentList = resultSet.manualCommentList ? resultSet.manualCommentList : [];
                GSOVDStrikeOffList[index].manualImageList = resultSet.manualImageList ? resultSet.manualImageList : [];
               
                // GSOVDStrikeOffList[index].criticalList = resultSet.criticalList ? resultSet.criticalList : [];

                return {
                    ...state,
                    generalStrikeOffViewDetailList: GSOVDStrikeOffList,
                    generalStrikeOffViewDetailParams: payload.params
                };
            }
        case CLEAR_GENERAL_STRIKEOFF_DETAIL_VIEW_SUB_ROW:
            let index = payload;
            //GeneralStrikeOffViewDetail-(GSOVD)
            let GSOVDStrikeOffList = [...state.generalStrikeOffViewDetailList];
            GSOVDStrikeOffList[index].strikeOffReferenceList = [];
            GSOVDStrikeOffList[index].manualCommentList = [];
            GSOVDStrikeOffList[index].manualImageList = [];
            // GSOVDRefernceList[index].criticalList = [];

            return {
                ...state,
                generalStrikeOffViewDetailList: GSOVDStrikeOffList
            };
        
        case UPDATE_GENERAL_STRIKEOFF_DETAIL_VIEW_ALL_CHECK_BOX:
            let isCheck = payload;
            let List = [...state.generalStrikeOffViewDetailList];
            List.map((item, Index) => {
                if (item && item.strikeOffReferenceList) {
                    let ReferenceList = item.strikeOffReferenceList;
                    ReferenceList && ReferenceList.map((item2, SubIndex) => {
                        ReferenceList[SubIndex].isSelected = isCheck;
                    });
                    List[Index].strikeOffReferenceList = ReferenceList;
                }
                
            });
            return {
                ...state,
                generalStrikeOffViewDetailList: List
            };
        
        case UPDATE_GENERAL_STRIKEOFF_DETAIL_VIEW_CHECK_BOX:
            let Index = payload.index;
            let SubIndex = payload.subIndex;
            let isChecked = payload.isChecked;

            let StrikeOffList = [...state.generalStrikeOffViewDetailList];
            let strikeOffReferenceList = StrikeOffList[Index].strikeOffReferenceList;
            strikeOffReferenceList[SubIndex].isSelected = isChecked;
            StrikeOffList[Index].strikeOffReferenceList = strikeOffReferenceList;

            return {
                ...state,
                generalStrikeOffViewDetailList: StrikeOffList
            };

        case NEW_TNA_GENERAL_STRIKEOFF_REFERENCE_LIST:
            return {
                ...state,
                genStrikeOffTNAReferenceList: payload.item1,
                genStrikeOffTNATaskList: payload.item2,
                genStrikeOffTNAAddtionalTaskList: payload.item3,
            };
        
        case REMOVE_TNA_GENERAL_STRIKEOFF_REFERENCE_LIST:
            let StrikeOffTNAReferenceList = [...state.genStrikeOffTNAReferenceList];
            StrikeOffTNAReferenceList.splice(payload, 1);
            return {
                ...state,
                genStrikeOffTNAReferenceList: StrikeOffTNAReferenceList
            };
        case RETRIEVE_GENERAL_STRIKEOFF_SUBMISSION_LIST:
            if (payload.params.StrikeOffId === 0) {

                return {
                    ...state,
                    generalStrikeOffSubmissionList: payload.data.strikeOffList,
                    generalStrikeOffSubmissionParams: payload.params
                };
            } else {

                let index = payload.params.Index;
                let resultSet = payload.data;
                //GeneralStrikeOffViewDetail-(GSOVD)
                let GSOVDStrikeOffList = [...state.generalStrikeOffSubmissionList];

                GSOVDStrikeOffList[index].strikeOffSubmitApprovalReferenceList = resultSet.strikeOffSubmitApprovalReferenceList ? resultSet.strikeOffSubmitApprovalReferenceList : [];
                // GSOVDStrikeOffList[index].criticalList = resultSet.criticalList ? resultSet.criticalList : [];

                return {
                    ...state,
                    generalStrikeOffSubmissionList: GSOVDStrikeOffList,
                    generalStrikeOffSubmissionParams: payload.params
                };
            }

        case CLEAR_GENERAL_STRIKEOFF_SUBMISSION_SUB_ROW:
            
            let indexVal = payload;
            //GeneralStrikeOffViewDetail-(GSOVD)
            let GSOVD_StrikeOffList = [...state.generalStrikeOffSubmissionList];
            GSOVD_StrikeOffList[indexVal].strikeOffSubmitApprovalReferenceList = [];
            // GSOVDRefernceList[index].criticalList = [];

            return {
                ...state,
                generalStrikeOffSubmissionList: GSOVD_StrikeOffList
            };
        
        case TNA_TASK_GENERAL_STRIKEOFF_SUBMISSION_AND_APPROVAL:
            return {
                ...state,
                editTNATaskStrikeOffSubmissionList: payload
            };
        case RETRIEVE_GENERAL_STRIKEOFF_APPROVAL_LIST:
            if (payload.params.StrikeOffId === 0) {
                return {
                    ...state,
                    generalStrikeOffApprovalList: payload.data.strikeOffList,
                    generalStrikeOffApprovalParams: payload.params
                };
            } else {

                let index = payload.params.Index;
                let resultSet = payload.data;
        
                let GSOVDStrikeOffList = [...state.generalStrikeOffApprovalList];

                GSOVDStrikeOffList[index].strikeOffSubmitApprovalReferenceList = resultSet.strikeOffSubmitApprovalReferenceList ? resultSet.strikeOffSubmitApprovalReferenceList : [];
                // GSOVDStrikeOffList[index].criticalList = resultSet.criticalList ? resultSet.criticalList : [];

                return {
                    ...state,
                    generalStrikeOffApprovalList: GSOVDStrikeOffList,
                    generalStrikeOffApprovalParams: payload.params
                };
            }

        case CLEAR_GENERAL_STRIKEOFF_APPROVAL_SUB_ROW:
            let ApprIndex = payload;
            let GSOA_StrikeOffList = [...state.generalStrikeOffApprovalList];
            GSOA_StrikeOffList[ApprIndex].strikeOffSubmitApprovalReferenceList = [];
            // GSOVDRefernceList[index].criticalList = [];
            return {
                ...state,
                generalStrikeOffApprovalList: GSOA_StrikeOffList
            };
        case RETRIEVE_GENERAL_STRIKEOFF_APPROVAL_VIEW_LIST:
            if (payload.params.StrikeOffId === 0) {
                return {
                    ...state,
                    generalStrikeOffApprovalViewList: payload.data.strikeOffList,
                    generalStrikeOffApprovalViewParams: payload.params
                };
            } else {

                let index = payload.params.Index;
                let resultSet = payload.data;
        
                let GSOVDStrikeOffList = [...state.generalStrikeOffApprovalViewList];

                GSOVDStrikeOffList[index].strikeOffSubmitApprovalReferenceList = resultSet.strikeOffSubmitApprovalReferenceList ? resultSet.strikeOffSubmitApprovalReferenceList : [];
                // GSOVDStrikeOffList[index].criticalList = resultSet.criticalList ? resultSet.criticalList : [];

                return {
                    ...state,
                    generalStrikeOffApprovalViewList: GSOVDStrikeOffList,
                    generalStrikeOffApprovalViewParams: payload.params
                };
            }
        case CLEAR_GENERAL_STRIKEOFF_APPROVAL_VIEW_SUB_ROW:

            let ViewIndex = payload;
            let GSOVA_StrikeOffList = [...state.generalStrikeOffApprovalViewList];
            GSOVA_StrikeOffList[ViewIndex].strikeOffSubmitApprovalReferenceList = [];
            // GSOVDRefernceList[index].criticalList = [];
            return {
                ...state,
                generalStrikeOffApprovalViewList: GSOVA_StrikeOffList
            };
        case UPDATE_GENERAL_STRIKEOFF_IS_APPROVAL_MANUAL_CLOSE_POPUP:
           ;
            let indexRow = payload.index;
            let subIndexRow = payload.subIndex;
            let isOpen = payload.isOpen;

            let StrikeOffApprovalList = [...state.generalStrikeOffApprovalList];
            let strikeOffApprovalReferenceList = StrikeOffApprovalList[indexRow].strikeOffSubmitApprovalReferenceList;
            strikeOffApprovalReferenceList[subIndexRow].isApprovalManualClosePopup = isOpen;
            StrikeOffApprovalList[indexRow].strikeOffSubmitApprovalReferenceList = strikeOffApprovalReferenceList;

            return {
                ...state,
                generalStrikeOffApprovalList: StrikeOffApprovalList
            };
        case REMOVE_GENERAL_STRIKEOFF_REQUEST_VIEW_SUB_ROW:
           ;
            let CurrIndex = payload.searchParams.Index;
            let parentStrikeOffList = [...state.generalStrikeOffSubmissionList];
            let strikeOffSubmitReference = parentStrikeOffList[CurrIndex].strikeOffSubmitApprovalReferenceList;
            strikeOffSubmitReference = strikeOffSubmitReference.filter(d => d.genStrikeOffDetailTNAId !== payload.GenStrikeOffDetailTNAId)
            if (strikeOffSubmitReference && strikeOffSubmitReference.length > 0) {
                parentStrikeOffList[CurrIndex].strikeOffSubmitApprovalReferenceList = strikeOffSubmitReference;
            } else {
                parentStrikeOffList.splice(CurrIndex, 1);
            }
            return {
                ...state,
                generalStrikeOffSubmissionList: parentStrikeOffList,
            };
        

        default:
            return state;
    }
};
export default generalStrikeOffReducer;