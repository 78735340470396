import {
    LOAD_AGENCY_DETAILS,
    AGENCY_LOADING
} from "./types";

import agencyService from './../services/Master/Agency.service';

const AgencyLoading = (isStatus) => ({
    type: AGENCY_LOADING,
    payload: isStatus,
});

export const LoadAgencyDetails = () => async (dispatch) => {
    let AgencyList = [];
    try {
        dispatch(AgencyLoading(true));
        const res = await agencyService.LoadAgencyDetails();
        AgencyList = res.data;
        dispatch({
            type: LOAD_AGENCY_DETAILS,
            payload: res.data,
        });
        dispatch(AgencyLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(AgencyLoading(false));
    }
    dispatch(AgencyLoading(false));
    return Promise.resolve(AgencyList);
}


export const InsertAgencyDetails = () => async (dispatch) => {
    const res = "";
    try {
        dispatch(AgencyLoading(true));
        res = await agencyService.InsertAgencyDetails();
        dispatch(AgencyLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(AgencyLoading(false));
    }
    dispatch(AgencyLoading(false));
    return Promise.resolve(res);
}

export const UpdateAgencyDetails = () => async (dispatch) => {
    const res = "";
    try {
        dispatch(AgencyLoading(true));
        res = await agencyService.UpdateAgencyDetails();
        dispatch(AgencyLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(AgencyLoading(false));
    }
    dispatch(AgencyLoading(false));
    return Promise.resolve(res);
}