import React, { useState, useEffect, useRef, Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import Lightbox from "react-image-lightbox";
import { GetGeneralStrikeOffApprovalList, clearStrikeOffApprovalSubRow, GetGeneralStrikeOffApprovalViewList, UpdateIsApprovalManualClosePopup, GetGeneralStrikeOffSubmissionApprovalGridList } from "../../../../actions/generalStrikeOff";
import { loadSupplierList } from "../../../../actions/tna";
import CommonService from "../../../../services/Common/CommonService";
import Nodify from "../../../Common/ReactNotification"
import SweetAlertPopup from '../../../Common/SweetAlertPopup';
import { GenStrikeOffSubmissionlId, GenManualSubCloseChildStrikeoff } from "../../../Common/PageActionNumber";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import moment from "moment";
import Reactselect from 'react-select';
import { Modal, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import GeneralService from "../../../../services/General/GeneralService";
import input from "react-validation/build/input";
import { TableHeader, Pagination, Search, PageCount } from "../../../Datatable";
import SweetAlert from 'react-bootstrap-sweetalert';
import $, { get } from "jquery";
import axios from "axios";

const StrikeOffViewSubmissionTab = (props) => {

    //declaration
    const dispatch = useDispatch();
    const { user: currentUser } = useSelector((state) => state.auth);
    const [loader, showLoader, hideLoader] = useFullPageLoader();

    //reducer
    const StrikeOffApprovalList = useSelector((state) => state.generalStrikeOff.generalStrikeOffApprovalList);
    //const StrikeOffSubmissionParams = useSelector((state) => state.generalStrikeOff.generalStrikeOffViewDetailParams);
    let isLoadingGeneralStrikeOff = useSelector((state) => state.generalStrikeOff.isLoadingGeneralStrikeOff);
    const getSupplierList = useSelector((state) => state.tna.drpSupplierList);
    let GLDRequestStatusList = useSelector((state) => state.generalLabdip.GLDRequestStatusList);
    const genStrikeOffTNAHolidayList = useSelector((state) => state.generalLabdip.genLapdibTNAHolidayList);
    const sizeList = useSelector((state) => state.size.drpSizeList);

    //state
    const [getID, setID] = useState(0);
    const [ChildId, setChildId] = useState({ ChildId: 0, Status: 0 });
    const [getsID, setsID] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [currentExpand, setcurrentExpand] = useState({ currindex: "", currStrikeOffId: "", currColorGroupID: "" });
    const [getComment, setComment] = useState(false);
    const [selectall, setselectall] = useState(false);
    // const [showManual, setshowManual] = useState(false);
    const [showSubmitManual, setSubmitshowManual] = useState(false);
    const [showApproval, setshowApproval] = useState(false);
    const [ManualInfo, setManualInfo] = useState([{ IdList: '', manualCloseDate: '', manualCloseComment: '', operation: 0 }]);
    const [ApprovalManualInfo, setApprovalManualInfo] = useState([{ approvalManualCloseDate: '', approvalManualCloseComment: '' }]);
    const [getSearch, setSearch] = useState("");
    const [showMultiClosediv, setshowMultiClosediv] = useState();
    const [showApprovaldiv, setshowApprovaldiv] = useState();
    const [getOrderinfo, setOrderinfo] = useState([]);
    const [deleteOrderinfo, setdeleteOrderinfo] = useState([]);
    const [deletepopup, setdeletepopup] = useState(false);
    const [getSizeList, setSizeList] = useState([]);
    const [showSizePopup, setShowSizePopup] = useState({ isPopup: false, detail: '' });

    const [inputFields, setInputFields] = useState([{
        BuyerBrandSeasonName: '',
        StoryName: '',
        ThemeName: '',
        Supplier: '',
        SubmitDate: '',
        SubmitDateEdit: '',
        SendTo: "", SendToEdit: '', SendToDisplay: '',
        SendThrough: 0,
        EndCustomer: "",
        AWBName: "", AWBDate: "", AWBDateEdit: '', AWBDateandNo: "",
        StrikeOffId: 0, StrikeoffCode: 0, StrikeoffName: "", Quality: "",
        StrikeoffDimensionId: 0, SwatchCardImageName: "", SwatchCardIamgePath: "", Remarks: "", CreatedBy: 0,
        GenralStrikeOffTNASubmissionAndApprovalId: 0,
        ApprovalDate: '', ApprovalRemark: '',
        GeneralTNAStrikeOffSubmittedOption: [],
        ColorGroupId: 0
    }]);
    const [submittedpopup, setSubmittedpopup] = useState(false);
    const values = [...inputFields]
    const [showawb, setshowawb] = useState(false);
    const [getTaskholder, setTaskholder] = useState([]);
    const [ShowPopup, setShowPopup] = useState(false);
    const [FabricDetails, setFabricDetails] = useState([]);
    const [ApprovalList, setApprovalList] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [getImagename, setImagename] = useState();
    const [getApprovedOptions, setApprovedOptions] = useState([]);
    const [getheader, setheader] = useState();
    const [submittedoption, setsubmittedoption] = useState([]);
    const [firstsubapprid, setfirstsubapprid] = useState({
        SubApprId: 0,
        GenStrikeOffId: 0,
        GenStrikeOffLogoId: 0,
        GenStrikeOffRefernceId: 0
    });
    const [tab, setTab] = useState(0);
    const [showSubmission, setshowSubmission] = useState(false);
    const [StrikeoffDimension, setStrikeoffDimension] = useState([]);
    const [View, setView] = useState(false);
    const [getCurrimageindex, setCurrimageindex] = useState(0);
    const [getSubImageList, setSubImageList] = useState([]);
    const [OrderInfoMainDetails, setOrderInfoMainDetails] = useState([]);
    const [expandState, setExpandState] = useState({});
    const [expandedRows, setExpandedRows] = useState([]);
    const [expandList, setexpandList] = useState({});
    const [MultiCloseDatas, setMultiCloseDatas] = useState([]);
    const [OrderInfoMulticloseMainDetails, setOrderInfoMulticloseMainDetails] = useState([]);
    const [expandedRowsMultiSelect, setExpandedRowsMultiSelect] = useState([]);
    const [expandListMultiSelect, setexpandListMultiSelect] = useState({});
    const [expandStateMultiSelect, setExpandStateMultiSelect] = useState({});



    const headers = [
        { name: "Story", field: "Story", sortable: false },
        { name: "Theme", field: "Theme", sortable: false },
        { name: "Details", field: "Details", sortable: false },
        { name: "Task Holder / Follower", field: "", sortable: false },
        { name: "Action", field: "", sortable: false },
    ];
    const [getSearchReqValues, setSearchReqValues] = useState({
        StatusId: 0,
        SupplierId: 0,
        SearchTxt: "",
        stateDate: [
            {
                startDate: null,
                endDate: new Date(""),
                key: 'selection',
                isShow: false
            }
        ]

    });

    const [TaskDetails, setTaskDetails] = useState([]);
    const [getSubRescheduleList, setSubRescheduleList] = useState([]);
    const [RescheduleComment, setRescheduleComment] = useState({ SubmissionComment: '', ApprovalComment: '' });
    const [SubmitReschedule, setSubmitReschedule] = useState(false);
    const [openReschedulepopup, setopenReschedulepopup] = useState(false);

    //Enter Error msg
    useEffect(() => {
       ;
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                //SaveBuyerInfo(event);
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [])

    const [stateDate, setStateDate] = useState([
        {
            startDate: null,
            endDate: null,
            key: 'selection',
            isShow: false
        }
    ]);

    useEffect(() => {
        if (!isLoadingGeneralStrikeOff) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingGeneralStrikeOff])

    useEffect(() => {

        dispatch(loadSupplierList());
        searchStrikeOff();

    }, [])

    const ReloadreqTab = () => {
        let StartDate = null;
        let EndDate = null;
        if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
        }
        if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
        }

        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, StrikeOffId: 0, ColorGroupID: 0,
            Index: currentExpand.currindex, SearchText: SearchReqValues.SearchTxt, Operation: 1,
            StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
            IsProduction: IsProduction
        };
        dispatch(GetGeneralStrikeOffSubmissionApprovalGridList(params));
    }

    let activeMenu;
    let activeMenuSub;
    let StrikeOffSubmissionTab;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
   ;
    if (menulist !== undefined && menulistSub.length > 0) {
        activeMenu = menulist.filter(i => i.menuName === "StrikeOffList");
        if (activeMenu.length > 0) {
            activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);


            if (activeMenuSub.length > 0) {

                for (let index = 0; index < activeMenuSub.length; index++) {
                    if (activeMenuSub[index].menuName === "GeneralStrikeOffSubmission") {
                        StrikeOffSubmissionTab = activeMenuSub[index];
                    }
                }
            }
        }
    }


    // If check production or sampling
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
    let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal : activeMenus.IsProduction;
    // End



    const SearchReqValues = { ...getSearchReqValues };

    const [Searchvalues, setSearchvalues] = useState({
        SupplierId: 0, approvalStatus: 0
    });
    let BuyerId = 0;
    let BrandId = 0;
    let SeasonId = 0;
    //get props params
    if (props.props.location.state !== undefined) {

        BuyerId = props.props.location.state.params.buyerId === undefined ? 0 : props.props.location.state.params.buyerId;
        BrandId = props.props.location.state.params.brandId === undefined ? 0 : props.props.location.state.params.brandId;
        SeasonId = props.props.location.state.params.seasonId === undefined ? 0 : props.props.location.state.params.seasonId;

    }



    const ApprovedOption = [
        { key: '1', value: 'Approved' },
        { key: '2', value: 'Approved with Comments' },
        { key: '3', value: 'Rejected' },
        { key: '4', value: 'Hold' }
    ]

    let styles1 = {
        control: styles1 => ({ ...styles1 }),
    }

    let styles2 = {
        control: styles2 => ({ ...styles2, border: '1px solid red' }),

    }
    var ImgClassName = "upload-btn-wrapper2"




    const pageRedirect = (SubmissionParams) => {

        let StartDate = null;
        let EndDate = null;
        if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
        }
        if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
        }

        let searchParams = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, StrikeOffId: 0, ColorGroupID: 0, Index: currentExpand.currindex,
            SearchText: SearchReqValues.SearchTxt, Operation: 2, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
            IsProduction: IsProduction
        };


        setID({ showPopupDelete: true, Params: SubmissionParams, SearchParams: searchParams });



    };


    const TabChange = (val) => {
        setTab(val);

    }

    const openFileUpload = () => {
        $('#upload-button').trigger('click');
    }

    const UploadImages = async (e) => {
        const file = e.target.files[0];
        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png'];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#FileUpload').val("");
            }
            else {
                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "TNA");
                try {
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                    values[0].SwatchCardImageName = file.name;
                    values[0].SwatchCardIamgePath = "Images/TNAImages/" + file.name;
                    setInputFields(values);
                    $('#FileUpload').val("");

                } catch (ex) {
                    console.log(ex);
                }
            }
        }
    }
    //#region  ------------------  search details --------------------------------
    const searchStrikeOff = () => {
        SearchReqValues.stateDate[0].isShow = false;
        setSearchReqValues(SearchReqValues);

        let StartDate = null;
        let EndDate = null;
        if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
        }
        if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
        }

        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, StrikeOffId: 0, ColorGroupID: 0, Index: currentExpand.currindex,
            SearchText: SearchReqValues.SearchTxt, Operation: 2, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
            IsProduction: IsProduction
        };
        dispatch(GetGeneralStrikeOffApprovalList(params));

        // let params = {
        //     BuyerId: StrikeOffViewDetailParams.BuyerId, BrandId: StrikeOffViewDetailParams.BrandId, SeasonId: StrikeOffViewDetailParams.SeasonId,
        //     BuyerName: StrikeOffViewDetailParams.BuyerName, BrandName: StrikeOffViewDetailParams.BrandName, SeasonName: StrikeOffViewDetailParams.SeasonName,
        //     PantoneId: 0, Index: 0, SearchText: SearchReqValues.SearchTxt, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, StartDate: StartDate, EndDate: EndDate
        // };
        // dispatch(getGeneralStrikeOffDetailsViewList(params));
        commonParentExpand();
    }

    const resetStrikeOff = () => {
        SearchReqValues.stateDate[0].startDate = null;
        SearchReqValues.stateDate[0].endDate = new Date("");
        SearchReqValues.stateDate[0].key = 'selection';
        SearchReqValues.stateDate[0].isShow = false;

        SearchReqValues.StatusId = 0;
        SearchReqValues.SupplierId = 0;
        SearchReqValues.SearchTxt = "";
        setSearchReqValues(SearchReqValues);
        searchStrikeOff();

    }


    const clearDateSelect = () => {
        SearchReqValues.stateDate[0].startDate = null;
        SearchReqValues.stateDate[0].endDate = new Date("");
        SearchReqValues.stateDate[0].key = 'selection';
        SearchReqValues.stateDate[0].isShow = false;

        setSearchReqValues(SearchReqValues);
    }
    const handleDateSelect = (event, isShow) => {

        SearchReqValues.stateDate[0].isShow = isShow;
        setSearchReqValues(SearchReqValues);
    }

    const handlePopupDateselection = (item) => {

        SearchReqValues.stateDate = [item.selection];
        setSearchReqValues(SearchReqValues);
    }

    const handleSearchChange = (e, FieldName) => {

        let inputText = '';
        if (FieldName === 'SearchTxt') {
            if (e && e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
        } else if (FieldName === 'Status' || FieldName === 'SupplierId') {
            if (e) {
                inputText = e.value
            }
        }

        if (FieldName === 'Status') {
            SearchReqValues.StatusId = inputText;
        }
        else if (FieldName === 'SupplierId') {
            SearchReqValues.SupplierId = inputText;
        } else if (FieldName === 'SearchTxt') {
            SearchReqValues.SearchTxt = inputText;
        }
        setSearchReqValues(SearchReqValues);

    }

    function convertUTCDateToLocalDate(date) {
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date;
    }

    //#end  ------------------  search details end --------------------------------


    // #region  ------------------  Expanded Toggle --------------------------------

    const handleToggle = (index, StrikeOffId, ColorGroupID) => {
        let btnToggle = document.getElementById("btn-toggle2_" + index);
        let iconToggle = document.getElementById("icon-toggle2_" + index);

        let ariaExpanded = btnToggle.getAttribute('aria-expanded');
        if (ariaExpanded === 'false') {
            btnToggle.setAttribute('aria-expanded', 'true');
            iconToggle.className = iconToggle.className.replace("fa-plus-square-o", "fa-minus-square-o");

            setcurrentExpand({ currindex: index, currStrikeOffId: StrikeOffId, currColorGroupID: ColorGroupID })
            // searchText();
            // dispatch(GetGeneralStrikeOffSubmissionApprovalList(params));
            let StartDate = null;
            let EndDate = null;
            if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
                StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
            }
            if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
                EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
            }
            let params = {
                BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, StrikeOffId: StrikeOffId, ColorGroupID: ColorGroupID, Index: index,
                SearchText: SearchReqValues.SearchTxt, Operation: 2, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
                IsProduction: IsProduction
            };

            dispatch(GetGeneralStrikeOffApprovalList(params));

        }
        else {

            btnToggle.setAttribute('aria-expanded', 'false');
            iconToggle.className = iconToggle.className.replace("fa-minus-square-o", "fa-plus-square-o");
            if (StrikeOffId) {
                dispatch(clearStrikeOffApprovalSubRow(index));
            }
        }
    };

    const commonParentExpand = () => {
        let rowHiddens = document.querySelectorAll(".parentExpandSubmitTNA");
        for (let row of rowHiddens) {
            row.setAttribute('aria-expanded', 'false');
        }
        let rowIconHiddens = document.querySelectorAll(".clscollapseexpandSubmitTNA");
        for (let rowIcon of rowIconHiddens) {
            rowIcon.className = rowIcon.className.replace("fa-minus-square-o", "fa-plus-square-o");
        }
    }



    const Delcallback = (value) => {

        searchStrikeOff();
        let btnToggle = document.getElementById("btn-toggle_" + currentExpand.currindex);
        let iconToggle = document.getElementById("icon-toggle_" + currentExpand.currindex);
        btnToggle.setAttribute('aria-expanded', 'false');
        iconToggle.className = iconToggle.className.replace("fa-minus-square-o", "fa-plus-square-o");

        setID({ showPopupDelete: false, Params: [] });
    }

    const ReloadApproval = () => {
        let StartDate = null;
        let EndDate = null;
        if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
        }
        if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
        }
        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, StrikeOffId: 0, ColorGroupID: 0, Index: "",
            //SearchText: (getSearch && getSearch.trim()), Operation: 3
            SearchText: SearchReqValues.SearchTxt, Operation: 3,
            StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
            SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
            IsProduction: IsProduction
        };
        let submitparams = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, StrikeOffId: 0, ColorGroupID: 0, Index: "",
            //SearchText: (getSearch && getSearch.trim()), Operation: 3
            SearchText: SearchReqValues.SearchTxt, Operation: 2,
            StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
            SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
            IsProduction: IsProduction
        };
        dispatch(GetGeneralStrikeOffApprovalList(submitparams));
        dispatch(GetGeneralStrikeOffApprovalViewList(params));
    }





    const handleCloseManualPopup = (index, subIndex, isOpen) => {

        dispatch(UpdateIsApprovalManualClosePopup(index, subIndex, isOpen));
        // setshowManual(false);
    }

    const handleManualclose = (Id, approvalStatus, BuyerId, BrandId, SeasonId, SupplierId, Follower, TaskId, strikeOffId, GenStrikeOffLogoID, GenStrikeOffId, GenStrikeOffRefernceId, index, subIndex) => {
        showLoader();
        let Operation;
        setComment(false);
        ApprovalManualInfo[0].approvalManualCloseComment = '';
        if (approvalStatus === "Pending") {
            Operation = 2;
            setheader('Manual Close');
        }
        else {
            Operation = 4;
            setheader('View Manual Close');

        }
        GeneralService.GetGeneralStrikeOffOrderInfoList(Id, BuyerId, BrandId, SeasonId, SupplierId, Follower,
            TaskId, strikeOffId, GenStrikeOffLogoID, GenStrikeOffId, Operation, GenStrikeOffRefernceId).then((response) => {
                if (response.data) {
                    let data = response.data.strikeOffSubmitApprovalReferenceList.map((item) => {
                        if (item.genStrikeOffTNASubmissionAndApprovalId === Id) {
                            item.isSelected = 1;
                            return item;
                        } else {
                            item.isSelected = 0;
                            return item;
                        }
                    });
                    setApprovalManualInfo(data);
                    setMultiCloseDatas(data);
                    hideLoader();
                } else {
                    hideLoader();
                }
            }).catch(() => { hideLoader(); });
        // setshowManual(true);
        dispatch(UpdateIsApprovalManualClosePopup(index, subIndex, true));

    }

    const ViewSubmittedManualClose = (Id, approvalStatus, BuyerId, BrandId, SeasonId, SupplierId, Follower, TaskId, strikeOffId, GenStrikeOffLogoID, GenStrikeOffId, GenStrikeOffRefernceId) => {
       
        setheader("View Manual Close");
        showLoader();
        setChildId({ ChildId: Id, Status: 0 });
        GeneralService.GetGeneralStrikeOffOrderInfoList(Id, BuyerId, BrandId, SeasonId, SupplierId, Follower,
            TaskId, strikeOffId, GenStrikeOffLogoID, GenStrikeOffId, 3, GenStrikeOffRefernceId).then((response) => {
                if (response.data) {
                    let data = response.data.strikeOffSubmitApprovalReferenceList.map((item) => {
                        if (item.genStrikeOffTNASubmissionAndApprovalId === Id) {
                            item.isSelected = 1;
                            return item;
                        } else {
                            return item;
                        }
                    });

                    setManualInfo(data);
                    setMultiCloseDatas(data);
                    setSubmitshowManual(true);
                    hideLoader();

                } else {
                    hideLoader();
                }
            }).catch(() => { setSubmitshowManual(true); hideLoader(); });


    }

    const handleChangeIsManualCloseComments = (e, feild) => {
        var Manual = [...ApprovalManualInfo];

        let input = '';

        if (feild === "Comment") {
            if (e.target.value.trim() !== '') {
                input = e.target.value;
            }
            Manual[0].approvalManualCloseComment = input;
        }
        else {
            Manual[0].approvalManualCloseDate = e;
        }
        setApprovalManualInfo(Manual);

    }
    const handledelete = () => {
        let Value = [];
        let Params = {
            Operation: 89,
            Id: ChildId.ChildId,
        };
        CommonService.CheckMappedItem(Params).then(res => {
            if (res.data.outputResult === "-2") {
                // setchkDataExists(true);
                let Title = "Warning!";
                let message = "Approval exists against this Submission.";
                let type = "warning";
                Nodify(Title, type, message);
            }
            else {
                setSubmitshowManual(true);
                // Value.TNAId = props.location.state.params.tnaid;
                Value.Id = ChildId.ChildId;
                setsID({ TNAManualClose: GenManualSubCloseChildStrikeoff, showPopupDelete: true, Params: Value });
            }
        })


    }
    const DeleteCallback = (value) => {
        if (value === true) {
            setsID({ showPopupDelete: false, Params: [] });
            searchStrikeOff();
            setSubmitshowManual(false);
           
        } else {
            setsID({ showPopupDelete: false, Params: [] })
            setSubmitshowManual(true);
        }

    }
    const SaveChildManulaCloseComments = (index, subIndex) => {
        if ((!ApprovalManualInfo[0].approvalManualCloseComment) || (!ApprovalManualInfo[0].approvalManualCloseDate)) {
            setComment(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            return false;
        }
        else {
            showLoader();
            let strValue = "";
            let selected = 0;
            // for (let data of MultiCloseDatas) {
            MultiCloseDatas.map((data) => {
                if (strValue === "") {
                    if (data.isSelected === 1) {
                        strValue = data.genStrikeOffDetailTNAId.toString();
                        selected = selected + 1;
                    }
                } else {
                    if (data.isSelected === 1) {
                        strValue = strValue + "," + data.genStrikeOffDetailTNAId;
                    }
                }
            });
            if (selected === 0) {
                hideLoader();
                Nodify('Warning!', 'warning', "Select atleast one Task");
            } else {
                let ManualCloseData = [{ IdList: '', ManualCloseDate: '', ManualCloseComment: '', Operation: 0 }];
                ManualCloseData[0].ManualCloseDate = convertUTCDateToLocalDate(new Date(ApprovalManualInfo[0].approvalManualCloseDate));

                // ManualCloseData.ManualCloseDate.setMinutes(ManualCloseData[0].manualCloseDate.getMinutes() + 370);
                ManualCloseData[0].ManualCloseComment = ApprovalManualInfo[0].approvalManualCloseComment;
                ManualCloseData[0].IdList = strValue;
                ManualCloseData[0].Operation = 2;
                GeneralService.UpdateGeneralStrikeOffManualInfo(ManualCloseData[0]).then((res) => {

                    if (res.data.outputResult === "1") {
                        Nodify('Success!', 'success', "Manual Comment Added Successfully");
                        dispatch(UpdateIsApprovalManualClosePopup(index, subIndex, false));
                        setSubmitshowManual(false);
                        //searchText();
                        // searchStrikeOff();
                        //handleToggle(currentExpand.currindex, currentExpand.currStrikeOffId, currentExpand.currColorGroupID);
                        ReloadApproval();
                        searchStrikeOff();
                    } else if (res.data.outputResult === "2") {
                        Nodify('Success!', 'success', "Manual Comment Updated Successfully");
                        dispatch(UpdateIsApprovalManualClosePopup(index, subIndex, false));
                        setSubmitshowManual(false);
                        // searchText();
                        //  searchStrikeOff();
                        //handleToggle(currentExpand.currindex, currentExpand.currStrikeOffId, currentExpand.currColorGroupID);
                        ReloadApproval();
                        searchStrikeOff();
                    } else if (res.data.outputResult === "-2") {
                        Nodify('Warning!', 'warning', "Error Occured");
                    }
                })
            }

        }

    }



    const SelectAll = (event) => {
        let IsSelected = 0;

        if (event.target.checked === true) {
            IsSelected = 1;
            MultiCloseDatas.forEach(element => {
                element.isSelected = 1;
            })
        }
        else {
            IsSelected = 0;
            MultiCloseDatas.forEach(element => {
                element.isSelected = 0;
            })
        }
        setselectall(IsSelected);
    }

    const OnSelectRecords = (event, index) => {
        const value = [...MultiCloseDatas];

        if (value) {
            if (event.target.checked === true) {
                value[index].isSelected = 1;
            }
            else {
                value[index].isSelected = 0;
            }
            let isSelectAll = 0;
            if (value) {
                isSelectAll = !!!value.find(d => d.isSelected === 0);
                isSelectAll = isSelectAll ? 1 : 0;
            }
            setselectall(isSelectAll);

            setMultiCloseDatas(value);
        }

    }

    const AddRows = () => {
        let FileteredRecords = MultiCloseDatas.filter(x => x.isSelected === 1);
        if (FileteredRecords.length === 0) {
            Nodify("Warning!", "warning", "Select atleast one row.");
        }
        else {
            setshowMultiClosediv(false);
            setshowApprovaldiv(true);
            // let data = [...new Map(FileteredRecords.map(item =>
            //     [item['strikeoffId'], item])).values()];
            let index = 0;
            FileteredRecords.forEach(element => {
                index = index + 1;
                element.index = index;
            })

            let data = [...new Map(FileteredRecords.map(item =>
                [item['strikeOffLogoId'], item])).values()];

            setOrderInfoMainDetails(data);
            setOrderinfo(FileteredRecords);
            handleExpandRow(FileteredRecords[0].strikeoffLogoPath, FileteredRecords);
        }
    }

    const ViewSubmission = (Id, SubmitStatus, BuyerId, BrandId, SeasonId, SupplierId, Follower, TaskId,
        strikeOffId, GenStrikeOffLogoID, GenStrikeOffId, GenStrikeOffMainId, index, Type, GenStrikeOffRefernceID) => {
        setTab(0);
        showLoader();
        let Operation;
        Operation = 3;
        if (Type === 'View') { setView(true) }
        else { setView(false) }
        setheader('View Manual Close');


        GeneralService.GetGeneralStrikeoffTaskHolderList(GenStrikeOffMainId).then((response) => {
            if (response.data) {
                setTaskholder(response.data);
            }
        }).catch(() => { });

        //clear and fetch new while popup open
        values[0].SubmittedDateEdit = '';
        values[0].SendTo = '';
        values[0].SendThrough = 0;
        values[0].SwatchCardImageName = '';
        values[0].SwatchCardIamgePath = '';
        values[0].Remarks = '';
        values[0].AWBDateandNo = '';
        values[0].AWBDate = '';
        values[0].AWBName = '';
        values[0].AWBDateEdit = '';
        setshowawb(false);

        //  var Main = StrikeOffSubmissionList.filter(x => x.genralStrikeOffTNASubmissionAndApprovalId === Id);
        var Sub = StrikeOffApprovalList[index].strikeOffSubmitApprovalReferenceList.filter(x => x.genStrikeOffTNASubmissionAndApprovalId === Id);
        Sub.forEach(element => {
            if (element.genStrikeOffTNASubmissionAndApprovalId === Id) {
                element.isSelected = 1;
            }
        })
       ;
        values[0].SupplierName = Sub[0].supplierName;
        values[0].BuyerBrandSeasonName = Sub[0].buyerBrandSeasonName;
        values[0].StoryName = Sub[0].storyName;
        values[0].ThemeName = Sub[0].themeName;
        values[0].EndCustomer = Sub[0].endCustomerNameIdList;
        values[0].StrikeoffCode = Sub[0].strikeOffCode;
        values[0].StrikeOffId = Sub[0].strikeOffId;
        values[0].StrikeoffName = Sub[0].strikeOffName;
        values[0].Quality = Sub[0].quality;
        values[0].StrikeoffDimensionId = Sub[0].strikeOffRefernceId;
        values[0].GenralStrikeOffTNASubmissionAndApprovalId = Sub[0].genStrikeOffTNASubmissionAndApprovalId;
        values[0].SubmitStatus = Sub[0].submitStatus;

        values[0].SubmittedDate = new Date(Sub[0].submitDate)
        let getDateValue = new Date(Sub[0].submitDate);
        var dd = getDateValue.getDate();
        var mm = getDateValue.getMonth() + 1;
        var yyyy = getDateValue.getFullYear();

        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        let coverted = yyyy + '-' + mm + '-' + dd;
        values[0].SubmittedDateEdit = getDateValue;
        values[0].SendTo = Sub[0].sendTo;
        values[0].SendToDisplay = Sub[0].sendToNames;
        values[0].SendToEdit = Sub[0].sendToNames;
        if (Type === 'Edit') {
            let arr = Sub[0].sendTo.split(',');
            let arrVal = [];
            for (let size of arr) {
                GeneralService.GetGeneralStrikeoffTaskHolderList(GenStrikeOffMainId).then((res) => {
                    if (res.data) {
                        let sendtoId = res.data.filter(x => x.value === parseInt(size))
                        arrVal.push({
                            value: sendtoId[0].value,
                            label: sendtoId[0].label
                        })
                        values[0].SendToEdit = arrVal;
                        //setInputFields(values)
                    }
                    //setInputFields(values)
                }).catch(() => { });

            }
        }
        values[0].SendThrough = parseInt(Sub[0].sendThrough);
        values[0].SwatchCardImageName = Sub[0].swatchCardImageName;
        values[0].SwatchCardIamgePath = Sub[0].swatchCardImagePath;
        values[0].Remarks = Sub[0].remarks;
        values[0].ColorGroupId = Sub[0].colorGroupId;
        if (parseInt(Sub[0].sendThrough) === 2) {
            setshowawb(true);
            let getDate = new Date(Sub[0].awbDate);
            let dd = getDate.getDate();
            let mm = getDate.getMonth() + 1;
            let yyyy = getDate.getFullYear();

            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            let coverted = dd + '/' + mm + '/' + yyyy;
            values[0].AWBDateandNo = Sub[0].awbDate === null ? '' : coverted + ' / ' + Sub[0].awbName;
            values[0].AWBDate = new Date(Sub[0].awbDate);
            values[0].AWBName = Sub[0].awbName;

            values[0].AWBDateEdit = getDate;
        }
        let images = [];
        images = Sub[0].strikeOffImageList.split(',');
        setSubImageList(images);
        setCurrimageindex(0);
        GeneralService.GetGeneralStrikeOffOrderInfoList(Id, BuyerId, BrandId, SeasonId, SupplierId, Follower,
            TaskId, strikeOffId, GenStrikeOffLogoID, GenStrikeOffId, Operation, GenStrikeOffRefernceID).then((response) => {
                if (response.data.strikeOffSubmitApprovalReferenceList) {
                    let data = response.data.strikeOffSubmitApprovalReferenceList.map((item) => {

                        if (item.genralStrikeOffTNASubmissionAndApprovalId === Id) {
                            item.isSelected = 1;

                            return item;
                        } else {
                            return item;
                        }
                    });

                    let maindata = [...new Map(data.map(item =>
                        [item['strikeOffLogoId'], item])).values()];

                    setOrderInfoMainDetails(maindata);

                    handleExpandRow(data[0].strikeoffLogoPath, data);

                    setOrderinfo(data);

                    GeneralService.GetGeneralStrikeoffDimensionList(GenStrikeOffMainId).then((result) => {
                        if (result.data) {
                            // setStrikeoffDimension(response.data);
                            var StrikeoffDim = [];
                            response.data.strikeOffSubmitApprovalReferenceList.map(Fr => {
                                result.data.map(Td => {
                                    if (Fr.strikeOffRefernceId === Td.id) {
                                        StrikeoffDim.push({
                                            id: Td.id,
                                            name: Td.name,
                                            value: Td.value,
                                            label: Td.label
                                        })
                                    }

                                })
                            })
                            setStrikeoffDimension(StrikeoffDim);
                        }
                    }).catch(() => { });

                    var optios = [];
                    //
                    response.data.generalTNAStrikeOffSubmittedOption.forEach(element => {
                        optios.push({
                            GeneralStrikeOffSubmittedOptionId: element.generalStrikeoffSubmittedOptionId,
                            GenralStrikeOffTNASubmissionAndApprovalId: element.genStrikeOffTNASubmissionAndApprovalId,
                            GenStrikeOffId: element.genStrikeOffId,
                            GenStrikeOffLogoId: element.genStrikeOffLogoId,
                            GenStrikeOffRefernceId: element.genStrikeOffRefernceId,
                            OptionName: element.optionName,
                            SupplierReferenceNo: element.supplierReferenceNo
                        })
                    })
                    setsubmittedoption(optios);
                    setfirstsubapprid(
                        {
                            SubApprId: response.data.generalTNAStrikeOffSubmittedOption[0].genStrikeOffTNASubmissionAndApprovalId,
                            GenStrikeOffId: response.data.generalTNAStrikeOffSubmittedOption[0].genStrikeOffId,
                            GenStrikeOffLogoId: response.data.generalTNAStrikeOffSubmittedOption[0].genStrikeOffLogoId,
                            GenStrikeOffRefernceId: response.data.generalTNAStrikeOffSubmittedOption[0].genStrikeOffRefernceId

                        })
                    setshowSubmission(true);
                    hideLoader();
                } else {
                    hideLoader();
                }
            }).catch(() => { hideLoader(); });

        setInputFields(values);
    }

    const handleExpandRow = (strikeoffLogoPath, list) => {
        setExpandedRows([]);
        setexpandList(list.filter(x => x.strikeoffLogoPath === strikeoffLogoPath));
        let currentExpandedRows = expandedRows;
        const isRowExpanded = currentExpandedRows.includes(strikeoffLogoPath);
        let obj = {};
        isRowExpanded ? (obj[strikeoffLogoPath] = false) : (obj[strikeoffLogoPath] = true);
        setExpandState(obj);
        currentExpandedRows = [];
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== strikeoffLogoPath) :
            currentExpandedRows.concat(strikeoffLogoPath);
        setExpandedRows(newExpandedRows);


    }
    const OpenStrikeOffApprovalpopup = (Id, approvalStatus, BuyerId, BrandId, SeasonId, SupplierId, Follower, TaskId,
        strikeOffId, GenStrikeOffLogoID, GenStrikeOffId, GenStrikeOffMainId, index, GenStrikeOffRefernceID) => {
        setTab(0);
        setSubmitted(false);
        showLoader();
        let Optionvalue = [];
        let Operation;

        values[0].ApprovalDate = '';
        values[0].ApprovalRemark = '';

        if (approvalStatus === "Pending") {
            Operation = 2;
            // setshowMultiClosediv(true);
            // setshowApprovaldiv(false);
            setshowMultiClosediv(false);
            setshowApprovaldiv(true);
            setheader('Manual Close');
        }
        else {
            Operation = 4;
            setshowMultiClosediv(false);
            setshowApprovaldiv(true);
            setheader('View Manual Close');

        }
        ApprovedOption.forEach(element => {
            let LabelColor = '';
            if (element.value === 'Approved') {
                LabelColor = <><span style={{ color: "#00b050" }}><b>Approved</b></span></>
            }
            else if (element.value === 'Approved with Comments') {
                LabelColor = <><span style={{ color: "#fca404" }}><b>Approved with Comments</b></span></>
            }
            else if (element.value === 'Rejected') {
                LabelColor = <><span style={{ color: "#c40404" }}><b>Rejected</b></span></>
            }
            else if (element.value === 'Hold') {
                LabelColor = <><span style={{ color: "#073763" }}><b>Hold</b></span></>
            }


            Optionvalue.push({
                value: element.key,
                label: LabelColor,
                subLabel: element.value,
            })
        });

        setApprovedOptions(Optionvalue);
        GeneralService.GetGeneralStrikeoffDimensionList(GenStrikeOffMainId).then((response) => {
            if (response.data) {
                setStrikeoffDimension(response.data);
            }
        }).catch(() => { });
        GeneralService.GetGeneralStrikeoffTaskHolderList(GenStrikeOffMainId).then((response) => {
            if (response.data) {
                setTaskholder(response.data);
            }
        }).catch(() => { });
       ;
        // var Main = StrikeOffApprovalList.filter(x => x.genralStrikeOffTNASubmissionAndApprovalId === Id);
        var Sub = StrikeOffApprovalList[index].strikeOffSubmitApprovalReferenceList.filter(x => x.genStrikeOffTNASubmissionAndApprovalId === Id);
        Sub.forEach(element => {
            if (element.genStrikeOffTNASubmissionAndApprovalId === Id) {
                element.isSelected = 1;
            }
        })

        values[0].SupplierName = Sub[0].supplierName;
        values[0].BuyerBrandSeasonName = Sub[0].buyerBrandSeasonName;
        values[0].StoryName = Sub[0].storyName;
        values[0].ThemeName = Sub[0].themeName;
        values[0].EndCustomer = Sub[0].endCustomerNameIdList;
        values[0].StrikeoffCode = Sub[0].strikeOffCode;
        values[0].StrikeOffId = Sub[0].strikeOffId;
        values[0].StrikeoffName = Sub[0].strikeOffName;
        values[0].Quality = Sub[0].quality;
        values[0].StrikeoffDimensionId = Sub[0].strikeOffRefernceId;
        values[0].GenralStrikeOffTNASubmissionAndApprovalId = Sub[0].genralStrikeOffTNASubmissionAndApprovalId;
        values[0].ApprovalStatus = Sub[0].approvalStatus;
        values[0].SubmitStatus = Sub[0].submitStatus;
        values[0].Remarks = Sub[0].remarks;

        let getDate = new Date(Sub[0].awbDate);
        let dd = getDate.getDate();
        let mm = getDate.getMonth() + 1;
        let yyyy = getDate.getFullYear();

        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        let coverted = dd + '/' + mm + '/' + yyyy;


        let awbdate = Sub[0].awbDate === null ? '' : coverted;
        values[0].SubmitSentDetails = Sub[0].sendThroughName + ' ' + Sub[0].awbName + ' ' + awbdate;
        values[0].SendTo = Sub[0].sentTo;
        values[0].SubmittedDate = new Date(Sub[0].submitDate);
        values[0].SwatchCardImageName = Sub[0].swatchCardImageName;
        values[0].SwatchCardIamgePath = Sub[0].swatchCardImagePath;
        let images = [];
        images = Sub[0].strikeOffImageList.split(',');
        setSubImageList(images);
        setCurrimageindex(0);

        if (approvalStatus !== "Completed") {
            GeneralService.GetGeneralStrikeOffOrderInfoList(Id, BuyerId, BrandId, SeasonId, SupplierId, Follower,
                TaskId, strikeOffId, GenStrikeOffLogoID, GenStrikeOffId, Operation, GenStrikeOffRefernceID).then((response) => {
                    if (response.data.strikeOffSubmitApprovalReferenceList) {
                        let index = 0;
                        let data = response.data.strikeOffSubmitApprovalReferenceList.map((item) => {
                            index = index + 1;
                            item.index = index;
                            if (item.genStrikeOffTNASubmissionAndApprovalId === Id) {
                                item.isSelected = 1;

                                return item;
                            } else {
                                return item;
                            }
                        });
                        let isSelectAll = 0;
                        if (data) {
                            isSelectAll = !!!data.find(d => d.isSelected === 0);
                            isSelectAll = isSelectAll ? 1 : 0;
                        }
                        setselectall(isSelectAll);

                        setsubmittedoption([]);
                        setsubmittedoption(response.data.generalTNAStrikeOffSubmittedOption);
                        let maindata = [...new Map(response.data.strikeOffSubmitApprovalReferenceList.map(item =>
                            [item['strikeOffLogoId'], item])).values()];

                        // setMultiCloseDatas(data);
                        // setOrderInfoMulticloseMainDetails(maindata);
                        // handleExpandRowMultiSelect(response.data.strikeOffSubmitApprovalReferenceList[0].strikeoffLogoPath, response.data.strikeOffSubmitApprovalReferenceList);

                        setOrderInfoMainDetails(maindata);
                        setOrderinfo(data);
                        handleExpandRow(response.data.strikeOffSubmitApprovalReferenceList[0].strikeoffLogoPath, response.data.strikeOffSubmitApprovalReferenceList);

                        setshowApproval(true);
                        hideLoader();
                    } else {
                        hideLoader();
                    }
                }).catch(() => { hideLoader(); });
        }
    }

    const handleExpandRowMultiSelect = (strikeoffLogoPath, list) => {
        setExpandedRowsMultiSelect([]);
        setexpandListMultiSelect(list.filter(x => x.strikeoffLogoPath === strikeoffLogoPath));
        let currentExpandedRows = expandedRowsMultiSelect;
        const isRowExpanded = currentExpandedRows.includes(strikeoffLogoPath);
        let obj = {};
        isRowExpanded ? (obj[strikeoffLogoPath] = false) : (obj[strikeoffLogoPath] = true);
        setExpandStateMultiSelect(obj);
        currentExpandedRows = [];
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== strikeoffLogoPath) :
            currentExpandedRows.concat(strikeoffLogoPath);
        setExpandedRowsMultiSelect(newExpandedRows);


    }


    const ShowHideDivs = () => {
        setshowMultiClosediv(true);
        setshowApprovaldiv(false);
    }
    const CustomInput = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                menuPosition="fixed"
                placeholder="DD/MM/YYYY"
                style={{ width: "100%", border: (!getComment) || ApprovalManualInfo[0].approvalManualCloseDate ? '' : '1px solid red' }}
            />
        )
    }
    // const searchText = () => {
    //    ;
    //     let params = {
    //         BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, PantoneId: 0, Index: currentExpand.currindex,
    //         SearchText: (getSearch && getSearch.trim()), Operation: 2
    //     };

    //     dispatch(GetGeneralStrikeOffApprovalList(params));
    // }

    const handleChange = (event, feild) => {
       
        let input = '';

        if (feild === "ApprovalDate") {
            if (event !== null) {
                if (new Date(event) < new Date(values[0].SubmittedDate)) {
                    Nodify("Warning!", "warning", "Approval Date should not lesser than Submit Date.");
                    values[0].ApprovalDate = '';
                }
                else {
                    values[0].ApprovalDate = event;
                }
            }
            else {
                values[0].ApprovalDate = '';
            }

        }
        else if (feild === "Remarks") {
            if (event.target.value.trim() !== '') {
                input = event.target.value;
            }
            values[0].ApprovalRemark = input;
        }

        setInputFields(values);
    }
    const SaveSubmission = () => {
       ;
        let CheckSubmittedOption = true;

        submittedoption.forEach(element => {
            if (element.OptionName === '' || element.SupplierReferenceNo === '') {
                CheckSubmittedOption = false;
            }
        })
        if (parseInt(values[0].SendThrough) === 1) {
            values[0].AWBName = '';
            values[0].AWBDate = ''
        }

        if (values[0].SubmittedDate === '' || values[0].SendTo === "" || parseInt(values[0].SendThrough) === 0 ||
            values[0].StrikeoffDimensionId === "0") {
            Nodify("Warning!", "warning", "Please fill the mandatory(*) fields.");
            setSubmittedpopup(true);
            // setSubmitted(true);
        }
        else if ((parseInt(values[0].SendThrough) === 2 && values[0].AWBDateandNo === '')) {
            Nodify("Warning!", "warning", "Please fill Awb Name & Date.");
        }
        else if (CheckSubmittedOption === false) {
            Nodify("Warning!", "warning", "Please fill the mandatory(*) fields in Submitted Option Section.");
            setSubmitted(true);
        }


        else {
           ;
            values[0].SubmittedDate = new Date(values[0].SubmittedDate.setMinutes(values[0].SubmittedDate.getMinutes() + 370))
            //values[0].ApprovalDate = new Date(values[0].ApprovalDate.setMinutes(values[0].ApprovalDate.getMinutes() + 370))
            if (values[0].AWBDate !== '') {
                values[0].AWBDate = new Date(values[0].AWBDate.setMinutes(values[0].AWBDate.getMinutes() + 370))
            }
            values[0].Operation = 1;
            values[0].SendThrough = parseInt(values[0].SendThrough);
            values[0].StrikeoffDimensionId = parseInt(values[0].StrikeoffDimensionId);
            values[0].GeneralTNAStrikeOffOrderInfo = getOrderinfo;
            values[0].GeneralTNAStrikeOffSubmittedOption = [];
            // getOrderinfo.map(ordinf => {
            submittedoption.map(opt => {
                values[0].GeneralTNAStrikeOffSubmittedOption.push({

                    GeneralStrikeOffSubmittedOptionId: opt.generalStrikeOffSubmittedOptionId,
                    GenralStrikeOffTNASubmissionAndApprovalId: firstsubapprid.SubApprId,
                    GenStrikeOffId: firstsubapprid.GenStrikeOffId,
                    GenStrikeOffLogoId: firstsubapprid.GenStrikeOffLogoId,
                    GenStrikeOffRefernceId: firstsubapprid.GenStrikeOffRefernceId,
                    OptionName: opt.OptionName,
                    SupplierReferenceNo: opt.SupplierReferenceNo

                })
            })
            // })

            GeneralService.InsertUpdateGeneralStrikeOffSubmissionApproval(values[0]).then(res => {
                if (res.data.outputResult === "1") {
                    Nodify('Success!', 'success', 'Task Submission Updated Successfully');
                    setshowSubmission(false);
                    //  searchStrikeOff();
                    //handleToggle(currentExpand.currindex, currentExpand.currStrikeOffId, currentExpand.currColorGroupID);
                    ReloadApproval();
                    searchStrikeOff();
                } else if (res.data.outputResult === "2") {
                    Nodify('Success!', 'success', 'Task Approval Updated Successfully');
                    setshowSubmission(false);
                    // searchStrikeOff();
                    // handleToggle(currentExpand.currindex, currentExpand.currStrikeOffId, currentExpand.currColorGroupID);
                    ReloadApproval();
                    searchStrikeOff();
                }
                else if (res.data.outputResult === "0") {
                    Nodify("Error!", "Error Occured!");
                }
            });
        }

    }
    const handlemultidd = (event, index) => {
       ;
        if (event.length === 0) {
            values[0].SendTo = "";
            values[0].SendToEdit = '';
            // values[index].SkuColorNameIds = "";
        } else {
            let strValue = "";
            for (let Sku of event) {
                if (strValue === "") {
                    strValue = Sku.value.toString();
                } else {
                    strValue = strValue + "," + Sku.value;
                }
            }
            values[0].SendTo = strValue;
            values[0].SendToEdit = event;
        }

        setInputFields(values);
    }
    const handleChangeSubmit = (event, feild) => {
        let input = '';

        if (feild === "SubmittedDate") {
            values[0].SubmittedDate = event;
            values[0].SubmittedDateEdit = event;

        }
        else if (feild === "Sendthrough") {
            if (event.target.value.trim() !== '') {
                input = event.target.value;
            }
            values[0].SendThrough = parseInt(input);
            if (input === "2") {
                setshowawb(true);
            }
            else {
                setshowawb(false);
            }
        }
        else if (feild === "SendTo") {
            if (event.target.value.trim() !== '') {
                input = event.target.value;
            }
            values[0].SendTo = input;
        }
        else if (feild === "Remarks") {
            if (event.target.value.trim() !== '') {
                input = event.target.value;
            }
            values[0].Remarks = input;
        }
        else if (feild === "StrikeoffDimensionId") {
            if (event.target.value.trim() !== '') {
                input = event.target.value;
            }
            values[0].StrikeoffDimensionId = input;
        }
        setInputFields(values);
    }



    const OnChangeOrderinfo = (event, index) => {
       ;
        const orderinfo = [...getOrderinfo];
        let SelectedCount = 0;
        orderinfo.forEach(element => {
            if (element.selectedDetail === 1) {
                SelectedCount = SelectedCount + 1;
            }
        })
        if (event.target.checked === true) {
            if (SelectedCount === 1) {
                orderinfo[index].SelectedDetail = 0;
                Nodify('Warning!', 'warning', 'Please select only one.');
            }
            else {
                orderinfo[index].selectedDetail = 1;
            }
        }
        else {
            orderinfo[index].selectedDetail = 0;
        }
        setOrderinfo(orderinfo);

        let SelectedDetail = '';
        let SelectedGenStrikeOffId = 0;
        orderinfo.forEach(element => {
            if (element.selectedDetail === 1) {
                SelectedDetail = element.fabricID;
                SelectedGenStrikeOffId = element.genStrikeOffId;
            }
        })

        GeneralService.LoadGeneralStrikeOffFabricDetailsList(SelectedGenStrikeOffId).then((response) => {
            if (response.data) {
                setFabricDetails(response.data);
            }
        }).catch(() => { });

        values[0].FabricID = SelectedDetail;

        setInputFields(values);
    }

    const handleRemoveOrderinfo = (index) => {
        const value = [...getOrderinfo]
        value.splice(index, 1);
        setOrderinfo(value);

    }



    const SaveApproval = () => {
       ;
        let IsValid = true;
        if (values[0].ApprovalDate === '' || values[0].ApprovalDate === null) {
            IsValid = false;

        }
        var approvecount = 0;
        submittedoption.forEach(element => {
            if (element.approvedOptionName !== null && element.approvedOptionName !== "0" &&
                element.approvedOptionName !== '') {
                approvecount = approvecount + 1;
            }
        })
        if (IsValid === false) {
            Nodify("Warning!", "warning", "Please fill the mandatory(*) fields.");
            setSubmitted(true);
        }
        else if (approvecount === 0) {
            Nodify("Warning!", "warning", "Please fill atleast one Approved Option.");
            setSubmitted(true);
        }
        else {
           ;
            values[0].SubmittedDate = new Date(values[0].SubmittedDate.setMinutes(values[0].SubmittedDate.getMinutes() + 370))
            values[0].ApprovalDate = new Date(values[0].ApprovalDate.setMinutes(values[0].ApprovalDate.getMinutes() + 370))
            if (values[0].AWBDate !== '') {
                values[0].AWBDate = new Date(values[0].AWBDate.setMinutes(values[0].AWBDate.getMinutes() + 370))
            }
            values[0].Operation = 2;
            values[0].GeneralTNAStrikeOffOrderInfo = getOrderinfo;
            values[0].GeneralTNAStrikeOffSubmittedOption = [];
            getOrderinfo.map(ordinf => {
                submittedoption.map(opt => {
                    values[0].GeneralTNAStrikeOffSubmittedOption.push({
                        GeneralStrikeOffSubmittedOptionId: 0,
                        GenralStrikeOffTNASubmissionAndApprovalId: ordinf.genStrikeOffTNASubmissionAndApprovalId,
                        GenStrikeOffId: ordinf.genStrikeOffId,
                        GenStrikeOffLogoId: ordinf.genStrikeOffLogoID,
                        GenStrikeOffRefernceId: ordinf.genStrikeOffRefernceID,
                        OptionName: opt.optionName,
                        SupplierReferenceNo: opt.supplierReferenceNo,
                        ApprovedOptionName: opt.approvedOptionName
                    })
                })
            })
            GeneralService.InsertUpdateGeneralStrikeOffSubmissionApproval(values[0]).then(res => {
                if (res.data.outputResult === "1") {
                    Nodify('Success!', 'success', 'Task Approval Added Successfully');
                    // searchStrikeOff();
                    setshowApproval(false);
                    // handleToggle(currentExpand.currindex, currentExpand.currStrikeOffId, currentExpand.currColorGroupID);
                    ReloadApproval();
                    searchStrikeOff();
                } else if (res.data.outputResult === "2") {
                    Nodify('Success!', 'success', 'Task Approval Updated Successfully');
                    // searchStrikeOff();
                    setshowApproval(false);
                    //handleToggle(currentExpand.currindex, currentExpand.currStrikeOffId, currentExpand.currColorGroupID);
                    ReloadApproval();
                    searchStrikeOff();
                }
                else if (res.data.outputResult === "0") {
                    Nodify("Error!", "Error Occured!");
                }
            });
        }

    }

    const OpenDeletPopup = (Id, SubmitStatus, BuyerId, BrandId, SeasonId, SupplierId, Follower, TaskId,
        strikeOffId, GenStrikeOffLogoID, GenStrikeOffId, GenStrikeOffMainId, index, Type, GenStrikeOffRefernceID) => {

        GeneralService.GetGeneralStrikeOffOrderInfoList(Id, BuyerId, BrandId, SeasonId, SupplierId, Follower,
            TaskId, strikeOffId, GenStrikeOffLogoID, GenStrikeOffId, 5, GenStrikeOffRefernceID).then((response) => {
                if (response.data.strikeOffSubmitApprovalReferenceList) {
                    setdeleteOrderinfo(response.data.strikeOffSubmitApprovalReferenceList);
                    setdeletepopup(true);
                }
            }).catch(() => { });

    }

    const DeleteMultipleApproval = () => {
        var data = { Operation: 1, GeneralTNAStrikeoffOrderInfo: deleteOrderinfo }

        GeneralService.DeleteMultipleGenStrikeoffApproval(data).then(res => {
            if (res.data.outputResult === "1") {
                Nodify('Success!', 'success', 'Strike Off Submission Deleted Successfully.');
                setdeleteOrderinfo([]);
                setdeletepopup(false);
                //searchStrikeOff();
                // handleToggle(currentExpand.currindex, currentExpand.currStrikeOffId, currentExpand.currColorGroupID);
                ReloadApproval();
                searchStrikeOff();
            }
            else if (res.data.outputResult === "0") {
                Nodify("Error!", "Error Occured!");
            }
        });
    }

    const handleRemoveFields = index => {
        submittedoption.splice(index, 1);
        setInputFields(values);
    };
    const handleAddField = (index) => {
        var isvalid = true;

        inputFields.forEach((element, index) => {
            if (submittedoption[index].OptionName === '' || submittedoption[index].SupplierReferenceNo === '') {
                isvalid = false;
            }
        })
        if (isvalid) {
            submittedoption.push({ OptionName: '', SupplierReferenceNo: '', GeneralStrikeOffSubmittedOptionId: 0 });
            setInputFields(values);
            setSubmitted(false);
        }
        else {
            Nodify('Warning!', 'warning', 'Please fill Option and Supplier Reference No.');
            setSubmitted(true);
        }
    }

    const handleSubmitoptionchange = (event, feild, index, Taskname) => {
        var SOValoes = [...submittedoption];
        let input = '';
        if (event !== null) {

            input = event.value;
            if (feild === "OptionName") {
                submittedoption[index].OptionName = event.target.value;
            }
            else if (feild === "SupplierReferenceNo") {
                submittedoption[index].SupplierReferenceNo = event.target.value;
            }
            if (Taskname !== "StrikeOff Approval") {
                if (submittedoption[index].OptionName !== '' && submittedoption[index].SupplierReferenceNo !== '') {
                    let Existoption = submittedoption.filter(x =>
                        x.OptionName.toLowerCase() === submittedoption[index].OptionName.toLowerCase()
                        && x.SupplierReferenceNo === submittedoption[index].SupplierReferenceNo.toLowerCase())
                    if (Existoption.length > 1) {
                        submittedoption[index].SupplierReferenceNo = '';
                        setSubmitted(true);
                        Nodify('Warning!', 'warning', 'SupplierReferenceNo Already Exists.');
                    }
                }
            }
            if (feild === "ApprovedOption") {
                SOValoes[index].approvedOptionName = input;
                if (input === "3") {
                    SOValoes.forEach(element => {
                        element.approvedOptionName = "3";
                    })
                }
                else if (input === "4") {
                    SOValoes.forEach(element => {
                        element.approvedOptionName = "4";
                    })
                }
                else {
                    SOValoes.forEach(element => {
                        element.approvedOptionName = "";
                    })
                    SOValoes[index].approvedOptionName = input;
                }

            }
        }
        else {
            SOValoes.forEach(element => {
                element.approvedOptionName = "";
            })
            SOValoes[index].approvedOptionName = '';
        }


        setsubmittedoption(SOValoes);
    }

    const handlepopupchange = (event, feild) => {
        let input = '';

        if (feild === "AWBName") {
            if (event.target.value !== '') {
                input = event.target.value;
            }
            values[0].AWBName = input;
        }
        else if (feild === "AWBDate") {
            values[0].AWBDate = event;
            values[0].AWBDateEdit = event;

        }
        setInputFields(values);
    }

    const SaveAwbnoDate = () => {
        var isvalid = true;
        if (values[0].AWBName === '' || values[0].AWBDate === '' || values[0].AWBDate === null) {
            isvalid = false;
        }

        if (isvalid) {
            values[0].AWBDateandNo = values[0].AWBName + " / " + values[0].AWBDate.toLocaleDateString('en-GB');
            setInputFields(values);
            setShowPopup(false);
        }
        else {
            Nodify('Warning!', 'warning', 'Please fill mandatory (*) fields in AWB Popup.');
            setSubmittedpopup(true);
        }

    }

    // const previmage = () => {
    //     if (getCurrimageindex === 0) {
    //         setCurrimageindex(0);
    //     }
    //     else {
    //         setCurrimageindex(getCurrimageindex - 1);
    //     }
    // }

    // const nextimage = () => {
    //     if (getCurrimageindex === getSubImageList.length - 1) {
    //         setCurrimageindex(getSubImageList.length - 1);
    //     }
    //     else {
    //         setCurrimageindex(getCurrimageindex + 1);
    //     }
    // }

    const handleStyleDetails = (detail, strikeOffGarmentSize) => {
        const sizeFilter = sizeList.filter(d => strikeOffGarmentSize.includes(d.value));
        setSizeList(sizeFilter);
        setShowSizePopup({ isPopup: true, detail: detail });

    }

    const handleSizePopupClose = () => {

        setShowSizePopup({ isPopup: false, detail: '' });

    }

    const CustomInputAWB = (props) => {
       ;
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
                style={{ border: submittedpopup && props.value === '' ? '1px solid red' : '' }}
            />
        )
    }

    const CustomInputApproval = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
                style={{
                    color:
                        props.value === ""
                            ? "#bfdea1"
                            : "#000",
                    border:
                        submitted &&
                            props.value === ""
                            ? "1px solid red"
                            : "",
                }}
            />
        )
    }

    const CustomInputsubmit = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
                style={{
                    border: submittedpopup && props.value === '' ? '1px solid red' : ''
                }}
            />
        )
    }
    const EndDateCustomInput = (props) => {

        return (
            <input
                className="form-control DatePickerCalenderHoliday min_width_Date"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                menuPosition="fixed"
                placeholder="DD/MM/YYYY"
            //style={{ border: submitdate && props.value === '' ? '1px solid red' : '' }}
            />
        )
    }

    const OpenReschedulePopup = (genStrikeOffSupplierTNAId, genralLabdipTNASubmissionAndApprovalId,
        genStrikeOffDetailTNAId, colorGroupID) => {

        GeneralService.getTNATaskDetailsList_Strikeoff(genStrikeOffSupplierTNAId, genStrikeOffDetailTNAId, colorGroupID).then((response) => {
            if (response.data) {
                setTaskDetails(response.data);

                GeneralService.getTNATaskRescheduleList_Strikeoff(genStrikeOffSupplierTNAId, genStrikeOffDetailTNAId, colorGroupID).then((response) => {
                    if (response.data) {
                        setSubRescheduleList(response.data);
                    }
                }).catch(() => { });

                setopenReschedulepopup(true);
            }
        }).catch(() => { });

        var cmtval = { ...RescheduleComment }
        cmtval.SubmissionComment = '';
        cmtval.ApprovalComment = '';
        setRescheduleComment(cmtval);
    }

    const handleChangeReschedule = (e, index, feild) => {
        var taskval = [...TaskDetails];
        var cmtval = { ...RescheduleComment }


        if (feild === "Duration") {
            taskval[index].duration = e.target.value;
        }
        else if (feild === "EndDate") {
            taskval[index].endDate = e;
        }
        else if (feild === "SubmissionComment") {
            cmtval.SubmissionComment = e.target.value;
        }
        if (feild === "ApprovalComment") {
            cmtval.ApprovalComment = e.target.value;
        }
        setTaskDetails(taskval);
        setRescheduleComment(cmtval);
        if (feild === "Duration" || feild === "EndDate") {
            datecalculation(index, feild)
        }
    }
    const handleSaveReschedule = () => {
        // if ((RescheduleComment.SubmissionComment === '' || RescheduleComment.ApprovalComment === '')) {
            if (RescheduleComment.SubmissionComment === '') {
            setSubmitReschedule(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            return false;
        }
        else {
           
            var InsertData = [];

            TaskDetails.map((item, i) => {
                InsertData.push({
                    GenStrikeOffDetailTNAId: item.genStrikeOffDetailTNAId,
                    GenStrikeOffTNASubmissionAndApprovalId: item.genStrikeOffTNASubmissionAndApprovalId,
                    GenStrikeOffSupplierTNAId: item.genStrikeOffSupplierTNAId,
                    Comments: i === 0 ? RescheduleComment.SubmissionComment : RescheduleComment.ApprovalComment,
                    Createdby: currentUser.employeeinformationID,
                    StartDate: item.startDate,
                    EndDate: item.endDate,
                    Duration: parseInt(item.duration),
                    Type: i === 0 ? "Submission" : "Approval",
                    ColorGroupID: item.colorGroupID
                })
            })

            let savecount = 0;
            InsertData.map(data => {
                GeneralService.InsertGenTNATaskReschedule_Strikeoff(data).then((res) => {
                    if (res.data.outputResult === "1") {
                        savecount = savecount + 1;
                    }
                })
            })
            Nodify('Success!', 'success', "Reschedule Added Successfully");
            setopenReschedulepopup(false);
            ReloadApproval();
            searchStrikeOff();
            ReloadreqTab();
        }
    }


    function datecalculation(index, FieldName) {
       
        var taskval = [...TaskDetails];
        let employee1 = "";
        let employee2 = "";
        let employee3 = "";
        if (taskval[index].taskHolderOrFollowerName) {
            let TaskHolderName = taskval[index].taskHolderOrFollowerName.split('-');
            if (TaskHolderName[0] !== undefined) {
                employee1 = TaskHolderName[0].trim();

                if (TaskHolderName[1] !== undefined) {
                    let FollowerName = TaskHolderName[1].split(',');
                    if (FollowerName[0] !== undefined) {
                        employee2 = FollowerName[0].trim();

                        if (FollowerName[1] !== undefined) {
                            employee3 = FollowerName[1].trim();
                        } else {
                            employee3 = "";
                        }
                    } else {
                        employee2 = "";
                        employee3 = "";
                    }

                } else {
                    employee2 = "";
                    employee3 = "";
                }
            }
        }

        let Duration = taskval[index].duration;
        let StartDate = new Date(taskval[index].startDate);
        let EndDate = new Date(taskval[index].endDate);
        let LeaveDays = taskval[index].leaveDays;
        let titleEmployee1 = employee1;
        let total = 0;
        if (FieldName === "Duration") {
            EndDate = addDays(StartDate, parseInt(Duration) - 1);
            total = totalHolidayAndWE(StartDate, EndDate, LeaveDays, titleEmployee1);
            for (let l = 0; l < total; l++) {
                EndDate = addDays(EndDate, 1);
                EndDate = checkDateBetweenInHolidayAndWE(EndDate, LeaveDays, titleEmployee1, "add");
            }
        }
        else {
            //based on end date
            let CheckDate = checkDateBetweenInHolidayAndWE(EndDate, LeaveDays, titleEmployee1, "add");
            if (!isSameDay(new Date(EndDate), CheckDate)) {
                Nodify('Warning!', 'warning', 'You select the end date to leave day');
                EndDate = CheckDate;
            }
            StartDate = subDays(EndDate, parseInt(Duration) - 1);
            total = totalHolidayAndWE(StartDate, EndDate, LeaveDays, titleEmployee1);
            for (let l = 0; l < total; l++) {
                StartDate = subDays(StartDate, 1);
                StartDate = checkDateBetweenInHolidayAndWE(StartDate, LeaveDays, titleEmployee1, "sub");

            }
        }

        taskval[index].startDate = StartDate;
        taskval[index].endDate = EndDate;
        setTaskDetails(taskval);
    }



    function isSameDay(d1, d2) {
        return d1.getFullYear() === d2.getFullYear() &&
            d1.getDate() === d2.getDate() &&
            d1.getMonth() === d2.getMonth();
    }

    function diffDays(date, otherDate) {
        return Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24)) + 1;
    }

    function addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    function subDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() - days);
        return result;
    }

    function totalHolidayAndWE(startDate, endDate, leaveDays, taskHolderName) {
        let totalSaturday = 0;
        let totalSunday = 0;
        let totalHoliday = 0;
        let total = 0;
        let format = "MM/DD/YYYY";
        // Saturday,Sunday and Holiday Count
        var currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            if (currentDate.getDay() === 0) {
                totalSunday++;
            }
            if (currentDate.getDay() === 6) {
                totalSaturday++;
            }
            if (leaveDays === 2 || leaveDays === 5 || leaveDays === 6 || leaveDays === 8) {
                var newDate = new Date(moment(currentDate, "DD/MM/YYYY").format("MM/DD/YYYY"));
                let holidayList = genStrikeOffTNAHolidayList.filter(d => d.taskHolderName === taskHolderName);

                let HolidayCount = holidayList.filter(d => new Date(moment(d.holidayDate, format)) <= newDate && newDate <= new Date(moment(d.holidayEndDate, format))).length;
                if (HolidayCount > 0) {
                    totalHoliday++;
                }
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }
        // Check LeaveDays base Saturday,Sunday and Holiday Count
        if (leaveDays === 2) {
            total = totalSaturday + totalSunday + totalHoliday;
        } else if (leaveDays === 3) {
            total = totalSaturday + totalSunday;
        } else if (leaveDays === 4) {
            total = totalSunday;
        } else if (leaveDays === 5) {
            total = totalHoliday;
        } else if (leaveDays === 6) {
            total = totalSunday + totalHoliday;
        } else if (leaveDays === 7) {
            total = totalSaturday;
        } else if (leaveDays === 8) {
            total = totalSaturday + totalHoliday;
        }
        return total;
    }

    function checkDateBetweenInHolidayAndWE(flyDate, leaveDays, taskHolderName, status) {
       
        let format = "MM/DD/YYYY";
        let checkDate = new Date(moment(flyDate, "DD/MM/YYYY").format("MM/DD/YYYY"));
        let holidayList = genStrikeOffTNAHolidayList.filter(d => d.taskHolderName === taskHolderName);
        let HolidayCount = holidayList.filter(d => new Date(moment(d.holidayDate, format)) <= checkDate && checkDate <= new Date(moment(d.holidayEndDate, format))).length;
        //ReCheck Date in Saturday,Sunday and Holiday Count
        if (flyDate.getDay() === 0 || flyDate.getDay() === 6 || HolidayCount > 0) {
            let i = 0;
            while (i <= 3) {
                //Weekend Date Check
                if (flyDate.getDay() === 0 && (leaveDays === 2 || leaveDays === 3 || leaveDays === 4 || leaveDays === 6)) {
                    status === "sub" ? flyDate = subDays(flyDate, 1) : flyDate = addDays(flyDate, 1);
                } else {
                    i++;
                }
                if (flyDate.getDay() === 6 && (leaveDays === 2 || leaveDays === 3 || leaveDays === 7 || leaveDays === 8)) {
                    status === "sub" ? flyDate = subDays(flyDate, 1) : flyDate = addDays(flyDate, 1);
                } else {
                    i++;
                }

                if (leaveDays === 2 || leaveDays === 5 || leaveDays === 6 || leaveDays === 8) {
                    let checkDate = new Date(moment(flyDate, "DD/MM/YYYY").format("MM/DD/YYYY"));
                    let HolidayCount = holidayList.filter(d => new Date(moment(d.holidayDate, format)) <= checkDate && checkDate <= new Date(moment(d.holidayEndDate, format))).length;
                    if (HolidayCount > 0) {
                        status === "sub" ? flyDate = subDays(flyDate, 1) : flyDate = addDays(flyDate, 1);
                    } else {
                        i++;
                    }
                } else {
                    i++;
                }

                i === 3 ? i = 4 : i = 0;
            }
        }
        return flyDate;
    }

    return (
        <Fragment>
            <div> {
                isOpen && (
                    <Lightbox
                        mainSrc={isOpen}
                        alt="bg image"

                        onCloseRequest={
                            () => setIsOpen(false)
                        } />
                )
            } </div>
            <div className="widget-body">
                <div id="registration-form">
                    <form>

                    <div className="col-sm-12">
                           
                           <div className="col-md-2">
                               <div className='form-group'>
                                   <span className='input-icon icon-right'>
                                       <label>Strike Off Code / Name</label>
                                       <input type="text" value={SearchReqValues.SearchTxt} onChange={event => handleSearchChange(event, "SearchTxt")} placeholder="Strike Off Code / Name" className="form-control" />
                                   </span>

                               </div>

                           </div>
                           <div className="col-md-2">
                               <div className="form-group">
                                   <label htmlFor="SupplierID">
                                       Supplier
                                       {/* <span className="text-danger">*</span> */}
                                   </label>
                                   <span className="input-icon icon-right">
                                       <Reactselect className="basic-single" name="SupplierId"
                                           id={
                                               "SupplierId"
                                           }
                                           isLoading={false}
                                           isClearable={true}
                                           isSearchable={true}
                                           onChange={event => handleSearchChange(event, 'SupplierId')}
                                           value={getSupplierList.filter(function (option) {
                                               return option.value === SearchReqValues.SupplierId;

                                           })}
                                           options={getSupplierList}
                                           menuPortalTarget={document.body}
                                           styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                                       ></Reactselect>

                                   </span>
                               </div>
                           </div>
                           <div className="col-md-2">
                               <div className="form-group">
                                   <label htmlFor="Status">
                                       Approval Status
                                   </label>
                                   <span className="input-icon icon-right">
                                       <Reactselect className="basic-single" name="Status"
                                           id={
                                               "Status"
                                           }
                                           isLoading={false}
                                           isClearable={true}
                                           isSearchable={true}
                                           onChange={event => handleSearchChange(event, 'Status')}
                                           value={GLDRequestStatusList.filter(function (option) {
                                               return option.value === SearchReqValues.StatusId;
                                           })}
                                           options={GLDRequestStatusList}
                                           menuPortalTarget={document.body}
                                           styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                                       ></Reactselect>
                                   </span>
                               </div>
                           </div>
                     


                      
                           <div className="col-md-2">

                               <div className="form-group">
                                   <label >Date Range <span className="rptDateRangeSpan" title="Clicking on the text box will cause the date picker to appear and disappear">(Clicking on the text box...)</span> </label>
                                   <span className="input-icon icon-right">
                                       <input
                                           value={(SearchReqValues.stateDate[0].startDate === null ? '' : moment(SearchReqValues.stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') + ((SearchReqValues.stateDate[0].endDate === null || !moment(SearchReqValues.stateDate[0].endDate).isValid()) ? '' : moment(SearchReqValues.stateDate[0].endDate).format('DD/MM/YYYY'))}
                                           onClick={event => handleDateSelect(event, !SearchReqValues.stateDate[0].isShow)}
                                           //onBlur={event => handleDateBlur()}
                                           type="text"
                                           className={'form-control'}
                                           placeholder="Select Date"
                                       />
                                       <i className="fa fa-times" onClick={clearDateSelect} ></i>
                                   </span>
                               </div>


                               {SearchReqValues.stateDate[0].isShow &&
                                   <DateRangePicker
                                       //onChange={item => setStateDate([item.selection])}
                                       onChange={item => handlePopupDateselection(item)}
                                       showSelectionPreview={true}
                                       moveRangeOnFirstSelection={false}
                                       months={2}
                                       ranges={SearchReqValues.stateDate}
                                       showDateDisplay={false}
                                       direction="vertical"
                                       className={'TNAReportDatePicker'}

                                   />

                               }

                           </div>

                           <div className="col-md-2" style={{ paddingTop: '29px' }}>

                               <button type="button" className="btn btn-success" title="Search" onClick={event => searchStrikeOff()}
                               >
                                   <i className="fa fa-search"></i>
                               </button>
                               &nbsp;&nbsp;
                               <button type="button" className="btn btn-danger" title="Reset" onClick={resetStrikeOff}
                               >
                                   <i className="fa fa-close"></i>
                               </button>
                           </div>
                       

                   </div>

                        <div className="col-md-12" style={{ overflowX: "auto", minHeight: '100px' }}>
                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable" >
                                <thead>
                                    <tr>
                                        <th className="fixed-column-header-first-tna" style={{ width: "30px" }}> </th>
                                        <th className="fixed-column-header-first-tna" style={{ width: "30px" }}> #</th>
                                        <th>Submitted Date</th>
                                        <th>Story</th>
                                        <th>Theme</th>
                                        <th>Supplier</th>
                                        <th>Details</th>
                                        {/* <th>Task Holder / Follower</th> */}
                                        <th>Approved Target</th>
                                        <th>Approved Status</th>
                                        <th>AWB & Date</th>
                                        <th style={{ width: "200px", paddingBottom: "2px" }} className="text-center fixed-column-header-Route" >Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        StrikeOffApprovalList && StrikeOffApprovalList.length > 0 ?
                                            <>
                                                {
                                                    StrikeOffApprovalList && StrikeOffApprovalList.map((inputField, index) => (
                                                        <Fragment key={`${inputField}~${index}`}>
                                                            <tr class="parent" data-child={"parent_" + index}>
                                                                <td className="fixed-column-first-tna">

                                                                    {
                                                                        <span id={"btn-toggle2_" + index} class="btn-toggle2 parentExpandSubmitTNA" aria-expanded="false" onClick={() => handleToggle(index, inputField.strikeOffId, inputField.colorGroupID)} aria-labelledby="btn-toggle2 id-master">
                                                                            <i id={"icon-toggle2_" + index} class="fa fa-plus-square-o clsPointer clscollapseexpandSubmitTNA"></i>
                                                                        </span>
                                                                    }

                                                                </td>
                                                                <td>{index+1}</td>
                                                                <td>
                                                                    <span className='input-icon icon-right'>
                                                                        {
                                                                            inputField.strikeOff
                                                                        }
                                                                    </span>
                                                                </td>
                                                                <td className="borderRightLeftNone" colSpan="7">
                                                                    <div className="trwidth_manual" style={{ textAlign: "center" }}>
                                                                        <img className="width_manual"
                                                                            src={inputField.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images/Style/Strikeoff/" + inputField.imagePath}
                                                                            alt="Logo"
                                                                            onClick={
                                                                                () => setIsOpen(inputField.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images/Style/Strikeoff/" + inputField.imagePath)
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="fixed-column-Route"></td>
                                                            </tr>
                                                            {
                                                                inputField.strikeOffSubmitApprovalReferenceList && inputField.strikeOffSubmitApprovalReferenceList.map((subField, subIndex) => (

                                                                    <Fragment key={`${subIndex}~${subIndex}`}>
                                                                        <tr class={"child_" + index + " parentChild_" + index + "_" + subIndex} >
                                                                            <td></td>
                                                                            <td className="fixed-column-first-tna" style={{paddingLeft:"15px"}}>
                                                                                {subIndex + 1}
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {moment(subField.submitDate).format('DD/MM/YYYY')} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.storyName} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.themeName} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.supplierName} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.details}
                                                                                        {
                                                                                            subField.strikeOffGarmentSize &&
                                                                                            <span onClick={() => handleStyleDetails(subField.details, subField.strikeOffGarmentSize)} className="text-primary"> {"Size Details"} </span>
                                                                                        }
                                                                                    </span>
                                                                                }
                                                                            </td>
                                                                            {/* <td className="cut_text" title={subField.followerName}>
                                                                                {
                                                                                    <span> {subField.followerName} </span>
                                                                                }
                                                                            </td> */}
                                                                            <td>
                                                                                {
                                                                                    <span> {moment(subField.approvalTarget).format('DD/MM/YYYY')} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.approvalStatus} </span>
                                                                                }
                                                                            </td>

                                                                            <td>
                                                                                {
                                                                                    <span> {subField.awbName} - {subField.awbDate === null ? '' : moment(subField.awbDate).format('DD/MM/YYYY')} </span>
                                                                                }
                                                                            </td>



                                                                            <td className="fixed-column-Route">
                                                                                {


                                                                                    subField.isApprovalManualClosePopup === true ?
                                                                                        <Modal show={subField.isApprovalManualClosePopup === true} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseManualPopup(index, subIndex, false)}>
                                                                                            <Modal.Header closeButton>
                                                                                                <Modal.Title>
                                                                                                    {getheader} : Strike Off Approval (Local Development) 
                                                                                                </Modal.Title>
                                                                                            </Modal.Header>
                                                                                            <Modal.Body>

                                                                                                <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                                    <thead>
                                                                                                        <tr>
                                                                                                            <th>Comments <span className="text-danger">*</span></th>
                                                                                                            <th>Close Date <span className="text-danger">*</span></th>

                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>

                                                                                                        <tr>
                                                                                                            <td style={{ width: "600px" }} >
                                                                                                                <textarea
                                                                                                                    rows="3"
                                                                                                                    placeholder="Enter Comments here..."
                                                                                                                    autoFocus onFocus={""} autoComplete="off"
                                                                                                                    maxLength="500" name="EnterRemarks"
                                                                                                                    id={"EnterRemarks"}
                                                                                                                    value={ApprovalManualInfo[0].approvalManualCloseComment}
                                                                                                                    onChange={event => handleChangeIsManualCloseComments(event, "Comment")}
                                                                                                                    style={{ width: "100%", border: (!getComment) || ApprovalManualInfo[0].approvalManualCloseComment ? '' : '1px solid red' }}
                                                                                                                    disabled={subField.approvalStatus === "Completed" ? true : false}
                                                                                                                >

                                                                                                                </textarea>
                                                                                                            </td>
                                                                                                            <td >
                                                                                                                {

                                                                                                                    subField.approvalStatus !== "Completed" ?
                                                                                                                        <DatePicker className="form-control" name="HolidayDate1"

                                                                                                                            selected={ApprovalManualInfo[0].approvalManualCloseDate}
                                                                                                                            onChange={event => handleChangeIsManualCloseComments(event, "CommentDate")}
                                                                                                                            dateFormat="dd/MM/yyyy"
                                                                                                                            peekNextMonth
                                                                                                                            showMonthDropdown
                                                                                                                            // showYearDropdown
                                                                                                                            dropdownMode="scroll"
                                                                                                                            autoComplete="off"
                                                                                                                            minDate={new Date()}
                                                                                                                            maxDate={new Date()}
                                                                                                                            customInput={<CustomInput />}
                                                                                                                            //style={{ width: "100%", border: getComment && ApprovalManualInfo[0].approvalManualCloseDate === '' ? '1px solid red' : '' }}
                                                                                                                            disabled={subField.approvalStatus === "Completed" ? true : false}
                                                                                                                        />

                                                                                                                        :

                                                                                                                        <input
                                                                                                                            placeholder="Enter the StoryName"
                                                                                                                            className="form-control"
                                                                                                                            title={ApprovalManualInfo[0].approvalManualCloseDate}
                                                                                                                            name="StoryName"
                                                                                                                            id={"StoryName"}
                                                                                                                            value={ApprovalManualInfo[0].approvalManualCloseDate}
                                                                                                                            disabled

                                                                                                                        ></input>
                                                                                                                }


                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </tbody>

                                                                                                </table>
                                                                                                {
                                                                                                    <table className="table table-bordered">
                                                                                                        <thead>
                                                                                                            <tr>
                                                                                                                <th>Story</th>
                                                                                                                <th>Theme</th>
                                                                                                                <th>Details </th>
                                                                                                                <th>Task Holder / Follower</th>
                                                                                                                <th>
                                                                                                                    <label style={{ marginTop: '8px' }}>
                                                                                                                        <input type="checkbox" name="IsSelected"
                                                                                                                            disabled={subField.approvalStatus === "Completed" ? true : false}
                                                                                                                            onChange={event => (SelectAll(event))}
                                                                                                                            checked={selectall === 1 ? true : false}
                                                                                                                            className="colored-blue clearOpacity" />
                                                                                                                        <span className="text"></span>
                                                                                                                    </label>
                                                                                                                </th>

                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            {
                                                                                                                MultiCloseDatas.length !== 0 ?
                                                                                                                    MultiCloseDatas.map((item, index) => {
                                                                                                                        return (
                                                                                                                            <>
                                                                                                                                <tr>
                                                                                                                                    <td>{item.storyName}</td>
                                                                                                                                    <td>{item.themeName}</td>
                                                                                                                                    <td>{item.details}</td>
                                                                                                                                    <td>{item.followerName}</td>

                                                                                                                                    <td className="Actionbutton fixed-column">
                                                                                                                                        <div style={{ width: "80px" }}>
                                                                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                                                                <input type="checkbox" name="IsSelected"
                                                                                                                                                    disabled={subField.approvalStatus === "Completed" ? true : false}
                                                                                                                                                    onChange={event => (OnSelectRecords(event, index))}
                                                                                                                                                    checked={item.isSelected === 1 ? true : false}
                                                                                                                                                    className="colored-blue clearOpacity" />
                                                                                                                                                <span className="text"></span>
                                                                                                                                            </label>

                                                                                                                                        </div>

                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </>

                                                                                                                        )
                                                                                                                    })
                                                                                                                    :
                                                                                                                    <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>

                                                                                                            }

                                                                                                        </tbody>
                                                                                                    </table>


                                                                                                }

                                                                                            </Modal.Body>
                                                                                            <Modal.Footer>
                                                                                                {
                                                                                                    subField.approvalStatus !== "Completed" &&
                                                                                                    <Button variant="success" onClick={() => SaveChildManulaCloseComments(index, subIndex)}>
                                                                                                        Save
                                                                                                    </Button>


                                                                                                }


                                                                                            </Modal.Footer>
                                                                                        </Modal> : ''

                                                                                }






                                                                                {
                                                                                    //StrikeOffSubmissionTab !== undefined && StrikeOffSubmissionTab.isView !== 0 &&
                                                                                    subField.manualCloseComment !== '' && subField.submitStatus === "Completed" ?
                                                                                        <button type="button" className="btn btn-xs btn-success" title="View Submitted Manual Close"
                                                                                            onClick={() => ViewSubmittedManualClose(subField.genStrikeOffTNASubmissionAndApprovalId, subField.submitStatus,
                                                                                                subField.buyerId, subField.brandId, subField.seasonId, subField.supplierId,
                                                                                                subField.follower, subField.taskID, subField.strikeOffId, subField.genStrikeOffLogoID, subField.genStrikeOffId, subField.genStrikeOffRefernceID)}
                                                                                        >
                                                                                            <i className="fa fa-user"></i>&nbsp;
                                                                                            <i className="fa fa-eye"></i>
                                                                                        </button> : ''
                                                                                }
                                                                                &nbsp;
                                                                                {

                                                                                    StrikeOffSubmissionTab !== undefined && StrikeOffSubmissionTab.isAdd !== 0 &&
                                                                                        subField.approvalManualCloseComment === '' && subField.approvalStatus === "Pending" && subField.isManualSubmission === 1 ?
                                                                                        <Fragment>&nbsp;<button type="button" className="btn btn-xs btn-darkorange" title="Add Approval Manual Close"
                                                                                            onClick={() => handleManualclose(subField.genStrikeOffTNASubmissionAndApprovalId, subField.approvalStatus,
                                                                                                subField.buyerId, subField.brandId, subField.seasonId, subField.supplierId,
                                                                                                subField.follower, subField.taskID, subField.strikeOffId, subField.genStrikeOffLogoID, subField.genStrikeOffId, 0, index, subIndex)}

                                                                                        >
                                                                                            <i className="fa fa-user"></i>&nbsp;
                                                                                            <i className="fa fa-close"></i>
                                                                                        </button>
                                                                                        </Fragment>
                                                                                        : ''
                                                                                    /* subField.manualCloseComment !== '' && subField.approvalStatus === "Completed" ?
                                                                                        <button type="button" className="btn btn-xs btn-success" title="View Manual Close Data"
                                                                                    onClick={() => handleManualclose(subField.genralStrikeOffTNASubmissionAndApprovalId, subField.approvalStatus,
                                                                                        subField.buyerId, subField.brandId, subField.seasonId, subField.supplierId,
                                                                                        subField.follower, subField.taskId, subField.pantoneID, subField.genStrikeOffId,
                                                                                        subField.genStrikeOffProgramInfoId)}
                                                                                >
                                                                                    <i className="fa fa-user"></i>&nbsp;
                                                                                    <i className="fa fa-close"></i>
                                                                                </button> : '' */

                                                                                }
                                                                                &nbsp;
                                                                                {
                                                                                    StrikeOffSubmissionTab !== undefined && StrikeOffSubmissionTab.isView !== 0 &&
                                                                                        subField.submitStatus === "Completed" && subField.isFormSubmission === 1 ?

                                                                                        <button type="button" className="btn btn-xs btn-success" title='View Submission'
                                                                                            onClick={() => ViewSubmission(subField.genStrikeOffTNASubmissionAndApprovalId, subField.submitStatus,
                                                                                                subField.buyerId, subField.brandId, subField.seasonId, subField.supplierId,
                                                                                                subField.follower, subField.taskID, subField.strikeOffId, subField.genStrikeOffLogoID, subField.genStrikeOffId,
                                                                                                subField.genStrikeOffMainId, index, 'View', subField.genStrikeOffRefernceID
                                                                                            )}>

                                                                                            <i className="fa fa-eye"></i>
                                                                                        </button> :
                                                                                        ''
                                                                                }
                                                                                &nbsp;
                                                                                {

                                                                                    StrikeOffSubmissionTab !== undefined && StrikeOffSubmissionTab.isAdd !== 0 &&
                                                                                        subField.isShowApprovalButton === 0 && subField.manualCloseComment === '' && subField.isFormSubmission === 1 ?
                                                                                        <button type="button" className="btn btn-xs btn-warning" title="Approval Form"
                                                                                            onClick={() => OpenStrikeOffApprovalpopup(subField.genStrikeOffTNASubmissionAndApprovalId, subField.approvalStatus,
                                                                                                subField.buyerId, subField.brandId, subField.seasonId, subField.supplierId,
                                                                                                subField.follower, subField.taskID, subField.strikeOffId, subField.genStrikeOffLogoID, subField.genStrikeOffId,
                                                                                                subField.genStrikeOffMainId, index, subField.genStrikeOffRefernceID)}

                                                                                        >
                                                                                            <i className="fa fa-check"></i>
                                                                                        </button> : ''
                                                                                    /* subField.isShowApprovalButton === 1 && subField.manualCloseComment === '' ?
                                                                                        <button type="button" className="btn btn-xs btn-success" title='View Approval'
                                                                                    onClick={() => OpenStrikeOffApprovalpopup(subField.genralStrikeOffTNASubmissionAndApprovalId, subField.approvalStatus,
                                                                                        subField.buyerId, subField.brandId, subField.seasonId, subField.supplierId,
                                                                                        subField.follower, subField.taskId, subField.pantoneID, subField.genStrikeOffId,
                                                                                        subField.genStrikeOffProgramInfoId, index)}>

                                                                                    <i className="fa fa-eye "></i>
                                                                                </button> :
                                                                                '' */

                                                                                }
                                                                                &nbsp;
                                                                                {
                                                                                    StrikeOffSubmissionTab !== undefined && StrikeOffSubmissionTab.isEdit !== 0 &&
                                                                                    subField.isFormSubmission === 1 && (
                                                                                        <button type="button" title="Edit Submission" class="btn btn-info btn-xs edit"
                                                                                            onClick={() => ViewSubmission(subField.genStrikeOffTNASubmissionAndApprovalId, subField.submitStatus,
                                                                                                subField.buyerId, subField.brandId, subField.seasonId, subField.supplierId,
                                                                                                subField.follower, subField.taskID, subField.strikeOffId, subField.genStrikeOffLogoID, subField.genStrikeOffId,
                                                                                                subField.genStrikeOffMainId, index, 'Edit', subField.genStrikeOffRefernceID)}
                                                                                            disabled={subField.approvalStatus !== "Pending" ? true : false}
                                                                                        ><i class="fa fa-edit"></i></button>
                                                                                    )
                                                                                }

                                                                                &nbsp;
                                                                                {
                                                                                    StrikeOffSubmissionTab !== undefined && StrikeOffSubmissionTab.isDelete !== 0 &&
                                                                                    subField.isFormSubmission === 1 && (
                                                                                        <button type="button" title='Delete Submission'
                                                                                            className="btn btn-danger btn-xs delete" onClick={() => OpenDeletPopup(subField.genStrikeOffTNASubmissionAndApprovalId, subField.submitStatus,
                                                                                                subField.buyerId, subField.brandId, subField.seasonId, subField.supplierId,
                                                                                                subField.follower, subField.taskID, subField.strikeOffId, subField.genStrikeOffLogoID, subField.genStrikeOffId,
                                                                                                subField.genStrikeOffMainId, index, 'Edit', subField.genStrikeOffRefernceID)}
                                                                                            disabled={subField.approvalStatus !== "Pending" ? true : false}
                                                                                        >
                                                                                            <i className="fa fa-trash-o"></i>
                                                                                        </button>
                                                                                    )
                                                                                }
                                                                                &nbsp;
                                                                                {
                                                                                    // new Date() > new Date(subField.EndDate) &&
                                                                                    subField.submitStatus === "Completed" &&
                                                                                    <button type="button" className={subField.isRescheduled === 0 ? "btn btn-xs btn-light" : "btn btn-xs btn-success"} title='Child Reschedule '
                                                                                        onClick={() => OpenReschedulePopup(subField.genStrikeOffSupplierTNAId, subField.genStrikeOffTNASubmissionAndApprovalId
                                                                                            , subField.genStrikeOffDetailTNAId, inputField.colorGroupID)}
                                                                                    >
                                                                                        <i class="fa fa-calendar"></i>
                                                                                    </button>
                                                                                }

                                                                            </td>
                                                                        </tr>
                                                                        {
                                                                            inputField.criticalList && inputField.criticalList.map((Crit) => (
                                                                                subField.programIndexName === Crit.programIndexName ?
                                                                                    <tr class={"child_" + index + " parentChild_" + index + "_" + subIndex} >
                                                                                        <td colSpan="10">
                                                                                            <span style={{ color: 'red' }}>Critical : {Crit.criticalComment}</span>,&nbsp;
                                                                                            <span style={{ color: '#427fed' }}>Target to Close : {new Date(Crit.targettoClose).toLocaleDateString()}</span>,&nbsp;
                                                                                            <span style={{ color: '#00a65a' }}>Completed On : {Crit.completedOn !== '' ? new Date(Crit.completedOn).toLocaleDateString() : '-'}</span>
                                                                                        </td>

                                                                                    </tr>
                                                                                    : ''
                                                                            ))
                                                                        }
                                                                    </Fragment>
                                                                ))
                                                            }

                                                        </Fragment>
                                                    ))
                                                }
                                            </>
                                            :

                                            <tr><td colSpan="11" className='norecordfound'><span>No Records Found</span></td></tr>

                                    }
                                </tbody>
                            </table>
                        </div>
                    </form>

                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>
                            {/* <span className="btn btn-primary"
                                onClick={
                                    () => ResetOperation("Back")
                                }
                            >
                                <i className="fa fa-arrow-circle-left"></i>
                                &nbsp;Back</span> */}
                            {/* <span className="btn btn-danger"
                                onClick={
                                    () => ResetOperation()
                                }
                            >
                                <i className="fa fa-times"></i>
                                &nbsp;Reset</span> */}
                            {/* <button type="button" disabled={buttonLoader} className="btn btn-success"
                                onClick={SaveTNA}>
                                <i className="fa fa-check right"></i>
                                &nbsp;Map</button> */}
                        </div>
                    </div>
                </div>
            </div >
            {


                showSubmitManual === true ?
                    <Modal show={showSubmitManual === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => setSubmitshowManual(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                View Manual Close : Strike Off Submission (Local Development)   &nbsp;
                                {
                                    <button type="button" className="btn btn-xs btn-danger" title="Delete Manual Close" onClick={() => handledelete()}>
                                        <i className="fa fa-trash-o"></i>
                                    </button>
                                }
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                <thead>
                                    <tr>
                                        <th>Comments <span className="text-danger">*</span></th>
                                        <th>Close Date <span className="text-danger">*</span></th>

                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td style={{ width: "600px" }} >
                                            <textarea
                                                rows="3"
                                                placeholder="Enter Comments here..."
                                                autoFocus onFocus={""} autoComplete="off"
                                                maxLength="500" name="EnterRemarks"
                                                id={"EnterRemarks"}
                                                value={ManualInfo && ManualInfo[0].manualCloseComment}
                                                onChange={event => handleChangeIsManualCloseComments(event, "Comment")}
                                                style={{ width: "100%", border: ManualInfo && ManualInfo[0].manualCloseComment === '' ? '1px solid red' : '' }}
                                                disabled
                                            // disabled={subField.submitStatus === "Completed" ? true : false}
                                            >

                                            </textarea>
                                        </td>
                                        <td >

                                            <DatePicker className="form-control" name="StoryName"
                                                id={
                                                    "StoryName"
                                                }
                                                selected={new Date(ManualInfo[0].manualCloseDate)}

                                                dateFormat="dd/MM/yyyy"
                                                peekNextMonth
                                                disabled
                                            // showYearDropdown

                                            //disabled={subField.approvalStatus === "Completed" ? true : false}
                                            />
                                            {/* <input
                                                placeholder="Enter the StoryName"
                                                className="form-control"
                                                title={ManualInfo[0].manualCloseDate}
                                                name="StoryName"
                                                id={"StoryName"}
                                                value={ManualInfo[0].manualCloseDate}
                                                disabled

                                            ></input> */}


                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                            {
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Story</th>
                                            <th>Theme</th>
                                            <th>Details </th>
                                            <th>Task Holder / Follower</th>


                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            MultiCloseDatas.length !== 0 ?
                                                MultiCloseDatas.map((item, index) => {
                                                    return (
                                                        <>
                                                            <tr>
                                                                <td>{item.storyName}</td>
                                                                <td>{item.themeName}</td>
                                                                <td>{item.details}</td>
                                                                <td>{item.followerName}</td>


                                                            </tr>
                                                        </>

                                                    )
                                                })
                                                :
                                                <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>

                                        }

                                    </tbody>
                                </table>


                            }

                        </Modal.Body>
                        <Modal.Footer>



                        </Modal.Footer>
                    </Modal> : ''

            }

            {


                showSubmission === true ?
                    <Modal dialogClassName="commonwidth" show={showSubmission === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => setshowSubmission(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {
                                    View === true ?
                                        "View" : "Edit"
                                }
                                &nbsp; Strike Off Submission
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row" style={{ overflowX: "hidden", height: "700px", padding: "5px", margin: "0px" }}>
                                <div className="col-sm-12">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="BuyerID">
                                                    Buyer-Season-Brand
                                                    {/* <span className="text-danger">*</span> */}
                                                </label>
                                                <span className="input-icon icon-right">
                                                    <input
                                                        placeholder="Buyer-Season-Brand"
                                                        className="form-control"
                                                        title={inputFields[0].BuyerBrandSeasonName}
                                                        name=" Buyer-Season-Brand"
                                                        id={" Buyer-Season-Brand"}
                                                        value={inputFields[0].BuyerBrandSeasonName}
                                                        disabled

                                                    ></input>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="SupplierID">
                                                    Supplier
                                                    {/* <span className="text-danger">*</span> */}
                                                </label>
                                                <span className="input-icon icon-right">
                                                    <input
                                                        placeholder="Supplier"
                                                        className="form-control"
                                                        title={inputFields[0].SupplierName}
                                                        name="SupplierID"
                                                        id={"SupplierID"}
                                                        value={inputFields[0].SupplierName}
                                                        disabled

                                                    ></input>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="Name">
                                                    Submit Date
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <span className="input-icon icon-right">
                                                    {
                                                        View === true ?
                                                            <DatePicker className="form-control" name="SubmittedDate"
                                                                id={
                                                                    "SubmittedDate"
                                                                }
                                                                selected={
                                                                    inputFields[0].SubmittedDate
                                                                }

                                                                dateFormat="dd/MM/yyyy"
                                                                peekNextMonth

                                                                // customInput={<CustomInput />}
                                                                disabled
                                                            />
                                                            // <input
                                                            //     placeholder="Supplier"
                                                            //     className="form-control"
                                                            //     title={inputFields[0].SubmittedDate}
                                                            //     name="SupplierID"
                                                            //     id={"SupplierID"}
                                                            //     value={inputFields[0].SubmittedDate}
                                                            //     disabled

                                                            // ></input>
                                                            :
                                                            <DatePicker className="form-control" name="SubmittedDate"
                                                                id={
                                                                    "SubmittedDate"
                                                                }
                                                                selected={
                                                                    inputFields[0].SubmittedDateEdit
                                                                }

                                                                dateFormat="dd/MM/yyyy"
                                                                peekNextMonth
                                                                onChange={event => handleChangeSubmit(event, 'SubmittedDate')}
                                                                customInput={<CustomInputsubmit />}

                                                            />
                                                        // <input
                                                        //     id="SubmittedDate"
                                                        //     name="SubmittedDate"
                                                        //     value={inputFields[0].SubmittedDateEdit}
                                                        //     placeholder="DD/MM/YYYY"
                                                        //     type="date"
                                                        //     autoComplete="off"
                                                        //     className={"form-control"}
                                                        //     onKeyDown={(e) => e.preventDefault()}
                                                        //     style={{
                                                        //         border: submittedpopup && inputFields[0].SubmittedDateEdit === '' ? '1px solid red' : ''
                                                        //     }}
                                                        //     onChange={event => handleChangeSubmit(event, 'SubmittedDate')}
                                                        // />

                                                    }

                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor={"SendToID"}>Send To<span className="text-danger">*</span></label>
                                                <span className="input-icon icon-right">
                                                    {
                                                        View === true ?
                                                            <span>
                                                                <input
                                                                    className="form-control"
                                                                    title={inputFields[0].SendToDisplay}
                                                                    value={inputFields[0].SendToDisplay} />
                                                            </span>
                                                            :
                                                            <span className=''  title={Array.isArray(inputFields[0].SendToEdit) ? inputFields[0].SendToEdit.map(item => item.name).join(', ') : ''}>
                                                                <Reactselect
                                                                    className="basic-single"
                                                                    name={"Sku"}
                                                                    id={
                                                                        "Sent To"
                                                                    }
                                                                    // value={item.MeasurementPoints}
                                                                    //isDisabled={false}
                                                                    isLoading={false}
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    options={getTaskholder}
                                                                    value={inputFields[0].SendToEdit}
                                                                    isMulti
                                                                    onChange={event => handlemultidd(event, 'SendTo')}
                                                                    menuPosition="fixed"
                                                                    styles={submittedpopup && inputFields[0].SendTo === "" ? styles2 : styles1}
                                                                // readOnly
                                                                // isDisabled={props.TaskStatus === 3 && (props.editLabdipSubmission === false || chkDataExists === true) ? true : false}
                                                                />
                                                            </span>

                                                    }

                                                    {/* <select id={"SendTo"} name="SendTo" className="form-select"
                                                        value={inputFields[0].SendTo}
                                                        onChange={(event) => handleChangeSubmit(event, 'SendTo')}
                                                        style={{ border: submittedpopup && inputFields[0].SendTo === "0" ? '1px solid red' : '' }}
                                                        disabled={View === true ? true : false}
                                                    >
                                                        <option value="0">-Select SendTo-</option>
                                                        {
                                                            getTaskholder.map(item => (
                                                                <option key={item.id} value={item.value}>
                                                                    {item.label}
                                                                </option>
                                                            ))
                                                        }
                                                    </select> */}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">


                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor={"StoryName"}>
                                                    Story Name
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <span className="input-icon icon-right">
                                                    <input
                                                        placeholder="Enter the StoryName"
                                                        className="form-control"
                                                        title={inputFields[0].StoryName}
                                                        name="StoryName"
                                                        id={"StoryName"}
                                                        value={inputFields[0].StoryName}
                                                        disabled

                                                    ></input>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor={" ThemeName"}>
                                                    Theme Name
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <span className="input-icon icon-right">
                                                    <input
                                                        placeholder="Enter the  ThemeName"
                                                        className="form-control"
                                                        title={inputFields[0].ThemeName}
                                                        name=" ThemeName"
                                                        id={" ThemeName"}
                                                        value={inputFields[0].ThemeName}
                                                        disabled

                                                    ></input>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor={"Endcustomer"}>
                                                    End Customer

                                                </label>
                                                <span className="input-icon icon-right">
                                                    <input
                                                        placeholder="Enter the End Customer"
                                                        className="form-control"
                                                        title={inputFields[0].EndCustomer}
                                                        name="End Customer No"
                                                        id={"End Customer"}
                                                        value={inputFields[0].EndCustomer}
                                                        disabled

                                                    ></input>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor={"Sendthrough"}>Send Through<span className="text-danger">*</span></label>
                                                <select id={"Sendthrough"} name="Sendthrough" className="form-select"
                                                    value={inputFields[0].SendThrough}
                                                    onChange={event => handleChangeSubmit(event, 'Sendthrough')}
                                                    style={{ border: submittedpopup && inputFields[0].SendThrough === 0 ? '1px solid red' : '' }}
                                                    disabled={View === true ? true : false}
                                                >
                                                    <option value="0" key="0" style={{ color: '#bfdea1' }}> - Select Send Through- </option>
                                                    <option value="1" key="1">Hand Carried</option>
                                                    <option value="2" key="2">Courier - DHL</option>
                                                </select>
                                            </div>
                                        </div>
                                        {
                                            showawb === true ?
                                                <div className="col-sm-3">
                                                    <div className='form-group'>
                                                        <label htmlFor="Awbdate" >AWB/Date</label>
                                                        <span className='input-icon icon-right'>
                                                            <input
                                                                placeholder="AWB/Date"
                                                                className="form-control"
                                                                name="AWBDateandNo"
                                                                id={
                                                                    "AWBDateandNo"
                                                                }
                                                                value={inputFields[0].AWBDateandNo}
                                                                // style={{ border: submittedpopup && inputFields[0].AWBDateandNo === '' ? '1px solid red' : '' }}
                                                                onClick={() => setShowPopup(true)}
                                                                disabled={View === true ? true : false}
                                                            ></input>
                                                        </span>
                                                    </div>
                                                </div> : ''
                                        }
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="tabbable">
                                            <ul
                                                className="nav nav-tabs nav-justified"
                                                id="myTab1"
                                            >
                                                <li
                                                    className={tab === 0 ? "active" : ""} >

                                                    <a
                                                        onClick={event => TabChange(0)}

                                                    >
                                                        <h5>Submit Information</h5>
                                                    </a>
                                                </li>

                                                <li
                                                    className={tab === 1 ? "active" : " "}

                                                >
                                                    <a
                                                        onClick={event => TabChange(1)}
                                                    >
                                                        <h5>Order Information</h5>
                                                    </a>
                                                </li>
                                            </ul>
                                            <div className="tab-content">

                                                <div id="idSubmitInformation1" className={tab === 0 ? "tab-pane in active" : "tab-pane"} style={{ width: "100%" }}>

                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="row">
                                                                <div className="col-sm-3">
                                                                    <div className='form-group'>
                                                                        <label htmlFor="BuyerID">Strike Off Code</label>
                                                                        <span className='input-icon icon-right'>
                                                                            <input
                                                                                placeholder="StikeoffCode"
                                                                                className="form-control cut_text"
                                                                                title={inputFields.StrikeoffCode}
                                                                                name="StrikeoffCode"
                                                                                id={
                                                                                    "StrikeoffCode"
                                                                                }
                                                                                value={
                                                                                    inputFields[0].StrikeoffCode
                                                                                }

                                                                                disabled
                                                                            ></input>
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-3">
                                                                    <div className='form-group'>
                                                                        <label htmlFor="SeasonID" >Strike Off Name</label>
                                                                        <span className='input-icon icon-right'>
                                                                            <input
                                                                                placeholder=" Name"
                                                                                className="form-control cut_text"
                                                                                title={inputFields.StrikeoffName}
                                                                                name="StrikeoffName"
                                                                                id={
                                                                                    "StrikeoffName"
                                                                                }
                                                                                value={
                                                                                    inputFields[0].StrikeoffName
                                                                                }

                                                                                disabled
                                                                            ></input>
                                                                        </span>
                                                                    </div>
                                                                </div>



                                                                <div className="col-sm-3">
                                                                    <div className="form-group">
                                                                        <label htmlFor={"Quarter"}>Quality</label>
                                                                        <span className="input-icon icon-right">

                                                                            <input
                                                                                placeholder="Quality"
                                                                                className="form-control cut_text"
                                                                                title={inputFields.Quality}
                                                                                name="StrikeOffQuality"
                                                                                id={
                                                                                    "StrikeOffQuality"
                                                                                }
                                                                                value={
                                                                                    inputFields[0].Quality
                                                                                }

                                                                                disabled
                                                                            ></input>

                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-3">

                                                                    <label style={{ marginLeft: "20px" }} htmlFor={"Quarter"}>Strike Off Image</label>
                                                                    <br />
                                                                    {/* <i className="fa fa-chevron-circle-left fa-2x" onClick={() => previmage()} style={{ cursor: "pointer" }}></i> */}
                                                                    {
                                                                        getSubImageList.length !== 0 ?
                                                                            <img src={window.$APIBaseURL + "Images/Style/Strikeoff/" + getSubImageList[getCurrimageindex]} alt="No Image" width="150px" height="150px" style={{ border: "2px solid black" }} />
                                                                            :
                                                                            <div align="center" style={{ width: "150px", height: "150px" }}>
                                                                                No Images Found
                                                                            </div>
                                                                    }
                                                                    {/* <i className="fa fa-chevron-circle-right fa-2x" onClick={() => nextimage()} style={{ cursor: "pointer" }}></i> */}




                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <div className="form-group">

                                                                        <label htmlFor={"StrikeoffDimensionId"}>Strikeoff Dimension <span className="text-danger">*</span></label>
                                                                        <select id={"StrikeoffDimensionId"} name="StrikeoffDimensionId" className="form-select"
                                                                            value={inputFields[0].StrikeoffDimensionId}
                                                                            onChange={(event) => handleChangeSubmit(event, 'StrikeoffDimensionId')}
                                                                            style={{ border: submittedpopup && inputFields[0].StrikeoffDimensionId === "0" ? '1px solid red' : '' }}
                                                                            disabled={View === true ? true : false}
                                                                        >
                                                                            <option value="0">-Select Strike Off-</option>
                                                                            {
                                                                                StrikeoffDimension.map(item => (
                                                                                    <option key={item.id} value={item.value}>
                                                                                        {item.label}
                                                                                    </option>
                                                                                ))
                                                                            }

                                                                        </select>




                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className="row">
                                                                <table>
                                                                    <tr>
                                                                        <td><label>Submit Options</label> </td>
                                                                        <td><label>Swatch Card (Image Upload)</label></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td> <div className="col-sm-6" style={{ padding: "0" }}>
                                                                            <div className="form-group">


                                                                                <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup"
                                                                                    style={{ width: "635px" }}>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th >Options <span className="text-danger">*</span></th>
                                                                                            <th>Supplier Reference No <span className="text-danger">*</span></th>
                                                                                            {
                                                                                                View !== true && (
                                                                                                    <th >Action</th>
                                                                                                )
                                                                                            }


                                                                                        </tr>

                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            submittedoption.map((item, index) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <span className="input-icon icon-right">
                                                                                                                    <input
                                                                                                                        placeholder="Option Name"
                                                                                                                        className="form-control"
                                                                                                                        title={item.OptionName}
                                                                                                                        name="OptionName"
                                                                                                                        id={
                                                                                                                            "OptionName"
                                                                                                                        }
                                                                                                                        value={
                                                                                                                            item.OptionName
                                                                                                                        }
                                                                                                                        onChange={event => (handleSubmitoptionchange(event, 'OptionName', index, "StrikeOff Submission"))}
                                                                                                                        style={{ width: "250px", border: submittedpopup && item.OptionName === '' ? '1px solid red' : '' }}
                                                                                                                        disabled={View === true ? true : false}
                                                                                                                    ></input>


                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <span className='input-icon icon-right'>
                                                                                                                    <input
                                                                                                                        placeholder=""
                                                                                                                        className="form-control cut_text"
                                                                                                                        title={item.SupplierReferenceNo}
                                                                                                                        name="SupplierReferenceNo"
                                                                                                                        id={
                                                                                                                            "SupplierReferenceNo"
                                                                                                                        }
                                                                                                                        value={
                                                                                                                            item.SupplierReferenceNo
                                                                                                                        }
                                                                                                                        onChange={event => (handleSubmitoptionchange(event, 'SupplierReferenceNo', index, "StrikeOff Submission"))}


                                                                                                                        disabled={View === true ? true : false}
                                                                                                                    ></input>
                                                                                                                </span>

                                                                                                            </td>
                                                                                                            {
                                                                                                                View !== true && (
                                                                                                                    <td className="Actionbutton fixed-column">
                                                                                                                        {" "}
                                                                                                                        <div style={{ width: "80px" }}>
                                                                                                                            {submittedoption.length !== 1 && (
                                                                                                                                <button
                                                                                                                                    type="button"
                                                                                                                                    className="btn  btn-xs  btn-danger"
                                                                                                                                    title="Delete option"
                                                                                                                                    onClick={() =>
                                                                                                                                        handleRemoveFields(index)
                                                                                                                                    }

                                                                                                                                >
                                                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                                                </button>
                                                                                                                            )}
                                                                                                                            &nbsp;
                                                                                                                            {submittedoption.length === index + 1 && (
                                                                                                                                <button
                                                                                                                                    type="button"
                                                                                                                                    className="btn  btn-xs  btn-success"
                                                                                                                                    title="Add option"
                                                                                                                                    onClick={() =>
                                                                                                                                        handleAddField(index)
                                                                                                                                    }

                                                                                                                                >
                                                                                                                                    <i className="fa fa-plus"></i>
                                                                                                                                </button>
                                                                                                                            )}
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                )
                                                                                                            }


                                                                                                        </tr>
                                                                                                    </>

                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div></td>
                                                                        <td>
                                                                            <div className={ImgClassName}
                                                                                htmlFor="upload-button"
                                                                            >
                                                                                <img
                                                                                    src={inputFields[0].SwatchCardIamgePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + inputFields[0].SwatchCardIamgePath}
                                                                                    // src={"assets/img/emptyImage.jpg"}
                                                                                    onClick={openFileUpload}
                                                                                    alt="Picture" style={{ marginBottom: '5px', height: '200px', width: '200px', border: "1px solid #d5d5d5" }} />
                                                                                <input type="file" id="upload-button" accept=".jpg,.jpeg,.png" onChange={(event) => UploadImages(event)} value=''
                                                                                //  disabled={inputFields[0].SubmitStatus === "Completed" ? true : false}
                                                                                />
                                                                                {
                                                                                    inputFields.SwatchImageFileName !== "assets/img/uploadimage.jpg" && (
                                                                                        <div style={
                                                                                            {
                                                                                                marginTop: '8px',
                                                                                                float: 'right',
                                                                                                bottom: '10px',
                                                                                                position: 'relative',
                                                                                            }
                                                                                        }>

                                                                                        </div>
                                                                                    )
                                                                                }

                                                                            </div>
                                                                        </td>

                                                                    </tr>
                                                                </table>




                                                                <div> {
                                                                    isOpen && (

                                                                        <Lightbox mainSrc={window.$APIBaseURL + getImagename}
                                                                            alt="bg image"
                                                                            onCloseRequest={
                                                                                () => setIsOpen(false)
                                                                            } />
                                                                    )
                                                                } </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div id="idOrderInformation1" className={tab === 1 ? "tab-pane in active" : "tab-pane"} style={{ width: "100%" }}>


                                                    <br />
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="dataTables_wrapper no-footer">
                                                                {
                                                                    OrderInfoMainDetails.length !== 0 ?

                                                                        <table className="table table-bordered">

                                                                            <tbody>

                                                                                {
                                                                                    OrderInfoMainDetails.map(comment => (
                                                                                        <Fragment>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <div className="row">
                                                                                                        <div className="col-lg-8">
                                                                                                            <i
                                                                                                                className={
                                                                                                                    expandState[comment.strikeoffLogoPath] ?
                                                                                                                        'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                                }
                                                                                                                onClick={() => handleExpandRow(comment.strikeoffLogoPath, getOrderinfo)}
                                                                                                            ></i>
                                                                                                            <span className="BuyerPaddingLeft">
                                                                                                                {
                                                                                                                    comment.strikeOffCode
                                                                                                                }
                                                                                                                -
                                                                                                                {
                                                                                                                    comment.strikeOffName
                                                                                                                }
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        <div className="col-lg-4">
                                                                                                            <span className="Actionbutton">
                                                                                                                <img src={window.$APIBaseURL + "Images/Style/Strikeoff/" + comment.strikeoffLogoPath} height="50px" width="50px"></img>
                                                                                                            </span>
                                                                                                        </div>

                                                                                                    </div>

                                                                                                </td>

                                                                                            </tr>
                                                                                            <>
                                                                                                {
                                                                                                    expandedRows.includes(comment.strikeoffLogoPath) ?
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                                                    <thead>
                                                                                                                        <tr>
                                                                                                                            <th>Story</th>
                                                                                                                            <th>Theme</th>
                                                                                                                            <th>Details </th>
                                                                                                                            <th>Task Holder / Follower</th>

                                                                                                                        </tr>
                                                                                                                    </thead>

                                                                                                                    <tbody>
                                                                                                                        {
                                                                                                                            expandList.map((item, index) => (
                                                                                                                                <Fragment>
                                                                                                                                    <tr>
                                                                                                                                        <td>{item.storyName}</td>
                                                                                                                                        <td>{item.themeName}</td>
                                                                                                                                        <td>{item.details}</td>
                                                                                                                                        <td>{item.followerName}</td>
                                                                                                                                    </tr>

                                                                                                                                </Fragment>
                                                                                                                            ))

                                                                                                                        }

                                                                                                                    </tbody>
                                                                                                                </table>
                                                                                                            </td>
                                                                                                        </tr> : null
                                                                                                }
                                                                                            </>
                                                                                        </Fragment>
                                                                                    ))
                                                                                }


                                                                            </tbody>
                                                                        </table>
                                                                        :
                                                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                                            <TableHeader headers={headers} />
                                                                            <tbody>
                                                                                <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                                                                            </tbody>
                                                                        </table>


                                                                }



                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>


                                <div className="col-sm-12 col-lg-12">
                                    <div className="row">
                                        <div className="col-sm-8 col-lg-8">
                                            <div className='form-group'>
                                                <label htmlFor="Remarks">Remarks</label>
                                                <textarea maxLength="300" name="Remarks" value={inputFields[0].Remarks}
                                                    onChange={event => handleChangeSubmit(event, 'Remarks')}
                                                    disabled={View === true ? true : false}
                                                    rows="4" style={{ width: "100%" }}></textarea>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                {
                                    View !== true &&
                                    (<div className="row">
                                        <div className="col-sm-12" align="right">
                                            <button type="button" className="btn btn-success margin-Align" onClick={() => SaveSubmission()}
                                                disabled={View === true ? true : false}
                                            >
                                                <i className="fa fa-check right"></i> &nbsp;Save
                                            </button>
                                        </div>
                                    </div>)
                                }


                                {
                                    ShowPopup === true ?
                                        <Modal show={ShowPopup} size="md" dragable backdrop="static" keyboard={false} onHide={() => setShowPopup(false)}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    AWB and Date
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: "350px" }}>AWB<span className="text-danger">*</span></th>
                                                            <th style={{ width: "300px" }}>Date<span className="text-danger">*</span></th>
                                                            {/* <th styles={{ width: "100px" }}>Action</th> */}
                                                        </tr>

                                                    </thead>
                                                    <tbody>

                                                        <tr>
                                                            <td>
                                                                <span className='input-icon icon-right'>
                                                                    <input type="text"
                                                                        placeholder="AWB Name"
                                                                        id={"AWBName"}
                                                                        name="AWBName"
                                                                        value={inputFields[0].AWBName}
                                                                        style={{ border: submittedpopup && !inputFields[0].AWBName ? '1px solid red' : '' }}
                                                                        autoFocus
                                                                        maxLength="25"
                                                                        className={'form-control'}
                                                                        onChange={event => handlepopupchange(event, 'AWBName')}
                                                                        menuPosition="fixed"
                                                                    // disabled={ChildField.DependencyID === 0 ? true : false}
                                                                    />
                                                                </span>

                                                            </td>
                                                            <td>
                                                                <DatePicker className="form-control" name="AWBDate"
                                                                    id={
                                                                        "AWBDate"
                                                                    }
                                                                    selected={
                                                                        inputFields[0].AWBDateEdit
                                                                    }
                                                                    onChange={event => handlepopupchange(event, 'AWBDate')}
                                                                    dateFormat="dd/MM/yyyy"
                                                                    peekNextMonth

                                                                    dropdownMode="scroll"
                                                                    autoComplete="off"
                                                                    customInput={<CustomInputAWB />}
                                                                    isClearable={inputFields[0].AWBDateEdit === null ? false : true}
                                                                />
                                                                {/* <span className="input-icon icon-right">
                                                                    <input id={"AWBDate"} name="AWBDate"
                                                                        value={inputFields[0].AWBDateEdit} placeholder="DD/MM/YYYY"
                                                                        type="date" autoComplete="off" onKeyDown={(e) => e.preventDefault()}
                                                                        className={'form-control'}
                                                                        style={{ border: submittedpopup && inputFields[0].AWBDateEdit === '' ? '1px solid red' : '' }}
                                                                        onChange={event => handlepopupchange(event, 'AWBDate')}
                                                                    />
                                                                </span> */}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="success" onClick={() => SaveAwbnoDate()}>
                                                    <i className="fa fa-check right"></i>&nbsp;
                                                    Save
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                        : ''
                                }
                            </div>
                        </Modal.Body>

                    </Modal> : ''

            }

            {


                showApproval === true ?
                    <Modal dialogClassName="commonwidth" show={showApproval === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => setshowApproval(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                            {/* {getheader}  */}
                            Strike Off Approval  
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            {
                                showMultiClosediv === true ?

                                    <>
                                       
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="alert alert-info">
                                                    Note : If you want to submit multiple, you can select multiple items.
                                                </div>
                                                <div className="dataTables_wrapper no-footer">
                                                    {
                                                        OrderInfoMulticloseMainDetails.length !== 0 ?

                                                            <table className="table table-bordered">

                                                                <tbody>
                                                                    {
                                                                        OrderInfoMulticloseMainDetails.map(comment => (
                                                                            <Fragment>
                                                                                <tr>
                                                                                    <td>
                                                                                        <div className="row">
                                                                                            <div className="col-lg-8">
                                                                                                <i
                                                                                                    className={
                                                                                                        expandStateMultiSelect[comment.strikeoffLogoPath] ?
                                                                                                            'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                    }
                                                                                                    onClick={() => handleExpandRowMultiSelect(comment.strikeoffLogoPath, MultiCloseDatas)}
                                                                                                ></i>
                                                                                                <span className="BuyerPaddingLeft">
                                                                                                    {
                                                                                                        comment.strikeOffCode
                                                                                                    }
                                                                                                    -
                                                                                                    {
                                                                                                        comment.strikeOffName
                                                                                                    }
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className="col-lg-4">
                                                                                                <span className="Actionbutton">
                                                                                                    <img src={window.$APIBaseURL + "Images/Style/Strikeoff/" + comment.strikeoffLogoPath} height="50px" width="50px"></img>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>

                                                                                </tr>
                                                                                <>
                                                                                    {
                                                                                        expandedRowsMultiSelect.includes(comment.strikeoffLogoPath) ?

                                                                                            <tr>
                                                                                                <td>
                                                                                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                                        <thead>
                                                                                                            <tr>
                                                                                                                <th>Story</th>
                                                                                                                <th>Theme</th>
                                                                                                                <th>Details </th>
                                                                                                                <th>Task Holder / Follower</th>
                                                                                                                <th>
                                                                                                                    <label style={{ marginTop: '8px' }}>
                                                                                                                        <input type="checkbox" name="IsSelected"

                                                                                                                            onChange={event => (SelectAll(event, comment.strikeoffLogoPath))}
                                                                                                                            checked={selectall === 1 ? true : false}
                                                                                                                            className="colored-blue clearOpacity" />
                                                                                                                        <span className="text"></span>
                                                                                                                    </label>

                                                                                                                </th>

                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            {
                                                                                                                expandListMultiSelect.map((item, index) => (
                                                                                                                    <Fragment>

                                                                                                                        <tr>
                                                                                                                            <td>{item.storyName}</td>
                                                                                                                            <td>{item.themeName}</td>
                                                                                                                            <td>{item.details}</td>
                                                                                                                            <td>{item.followerName}</td>
                                                                                                                            <td className="Actionbutton fixed-column">
                                                                                                                                <div style={{ width: "80px" }}>
                                                                                                                                    <label style={{ marginTop: '8px' }}>
                                                                                                                                        <input type="checkbox" name="IsSelected"

                                                                                                                                            onChange={event => (OnSelectRecords(event, item.index - 1))}
                                                                                                                                            checked={item.isSelected === 1 ? true : false}
                                                                                                                                            className="colored-blue clearOpacity" />
                                                                                                                                        <span className="text"></span>
                                                                                                                                    </label>

                                                                                                                                </div>

                                                                                                                            </td>

                                                                                                                        </tr>

                                                                                                                    </Fragment>
                                                                                                                ))

                                                                                                            }

                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </td>
                                                                                            </tr> : null
                                                                                    }
                                                                                </>
                                                                            </Fragment>
                                                                        ))
                                                                    }






                                                                </tbody>
                                                            </table>
                                                            :
                                                            <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                                <TableHeader headers={headers} />
                                                                <tbody>
                                                                    <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                                                                </tbody>
                                                            </table>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <br />

                                        {/* <div className="row">
                                            <div className="col-sm-12" align="right">
                                                <button type="button" className="btn btn-success" onClick={() => AddRows()}>

                                                    <i className="fa fa-check right"></i> Add
                                                </button>
                                            </div>
                                        </div> */}
                                    </>
                                    : ''
                            }
                            {
                                showApprovaldiv === true ?
                                    <>
                                        <div className="row" style={{ overflowX: "hidden", height: "700px", padding: "1px", margin: "0px" }}>
                                            <div>
                                                <div className="row">
                                                    <div className="col-sm-3">
                                                        <div className="form-group">
                                                            <label htmlFor="BuyerID">
                                                                Buyer-Season-Brand

                                                            </label>
                                                            <span className="input-icon icon-right">
                                                                <input
                                                                    placeholder="Buyer-Season-Brand"
                                                                    className="form-control"
                                                                    name=" Buyer-Season-Brand"
                                                                    id={" Buyer-Season-Brand"}
                                                                    value={inputFields[0].BuyerBrandSeasonName}
                                                                    disabled

                                                                ></input>
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-3">
                                                        <div className="form-group">
                                                            <label htmlFor="SupplierID">
                                                                Supplier
                                                                {/* <span className="text-danger">*</span> */}
                                                            </label>
                                                            <span className="input-icon icon-right">
                                                                <input
                                                                    placeholder="Supplier"
                                                                    className="form-control"
                                                                    name="SupplierID"
                                                                    id={"SupplierID"}
                                                                    value={inputFields[0].SupplierName}
                                                                    disabled

                                                                ></input>
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-3">
                                                        <div className="form-group">
                                                            <label htmlFor="Name">
                                                                Submit Date
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <span className="input-icon icon-right">
                                                                <DatePicker className="form-control" name="SubmittedDate"
                                                                    id={
                                                                        "SubmittedDate"
                                                                    }
                                                                    selected={
                                                                        inputFields[0].SubmittedDate
                                                                    }

                                                                    dateFormat="dd/MM/yyyy"
                                                                    peekNextMonth


                                                                    disabled
                                                                />

                                                                {/* <input
                                                                    placeholder="SubmittedDate"
                                                                    className="form-control"
                                                                    name="SubmittedDate"
                                                                    id={"SubmittedDate"}
                                                                    value={inputFields[0].SubmittedDate}
                                                                    disabled

                                                                ></input> */}

                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <div className="form-group">
                                                            <label htmlFor={"SendTo"}>
                                                                Approved Date
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <span className="input-icon icon-right">
                                                                {
                                                                    inputFields[0].ApprovalStatus !== "Completed" ?
                                                                        <DatePicker className="form-control" name="ApprovalDate"
                                                                            id={
                                                                                "ApprovalDate"
                                                                            }
                                                                            selected={
                                                                                inputFields[0].ApprovalDate
                                                                            }
                                                                            onChange={event => handleChange(event, 'ApprovalDate')}
                                                                            dateFormat="dd/MM/yyyy"
                                                                            peekNextMonth

                                                                            dropdownMode="scroll"
                                                                            autoComplete="off"
                                                                            customInput={<CustomInputApproval />}
                                                                            isClearable={inputFields[0].ApprovalDate === null ? false : true}
                                                                        />
                                                                        // <input
                                                                        //     id="ApprovalDate"
                                                                        //     name="ApprovalDate"
                                                                        //     value={inputFields[0].ApprovalDate}
                                                                        //     placeholder="DD/MM/YYYY"
                                                                        //     type="date"
                                                                        //     autoComplete="off"
                                                                        //     className={"form-control"}
                                                                        //     onKeyDown={(e) => e.preventDefault()}
                                                                        //     style={{
                                                                        //         color:
                                                                        //             inputFields[0].ApprovalDate === ""
                                                                        //                 ? "#bfdea1"
                                                                        //                 : "#000",
                                                                        //         border:
                                                                        //             submitted &&
                                                                        //                 inputFields[0].ApprovalDate === ""
                                                                        //                 ? "1px solid red"
                                                                        //                 : "",
                                                                        //     }}
                                                                        //     onChange={event => handleChange(event, 'ApprovalDate')}
                                                                        // />
                                                                        :
                                                                        <DatePicker className="form-control" name="SubmittedDate"
                                                                            id={
                                                                                "SubmittedDate"
                                                                            }
                                                                            selected={
                                                                                inputFields[0].ApprovalDate
                                                                            }

                                                                            dateFormat="dd/MM/yyyy"
                                                                            peekNextMonth

                                                                            customInput={<CustomInputApproval />}
                                                                            disabled
                                                                        />

                                                                    // <input
                                                                    //     placeholder="ApprovalDate"
                                                                    //     className="form-control"
                                                                    //     name="ApprovalDate"
                                                                    //     id={"ApprovalDate"}
                                                                    //     value={inputFields[0].ApprovalDate}
                                                                    //     disabled

                                                                    // ></input>
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">


                                                    <div className="col-sm-3">
                                                        <div className="form-group">
                                                            <label htmlFor={"StoryName"}>
                                                                Story Name
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <span className="input-icon icon-right">
                                                                <input
                                                                    placeholder="Enter the StoryName"
                                                                    className="form-control"
                                                                    title={inputFields[0].StoryName}
                                                                    name="StoryName"
                                                                    id={"StoryName"}
                                                                    value={inputFields[0].StoryName}
                                                                    disabled

                                                                ></input>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <div className="form-group">
                                                            <label htmlFor={" ThemeName"}>
                                                                Theme Name
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <span className="input-icon icon-right">
                                                                <input
                                                                    placeholder="Enter the  ThemeName"
                                                                    className="form-control"
                                                                    title={inputFields[0].ThemeName}
                                                                    name=" ThemeName"
                                                                    id={" ThemeName"}
                                                                    value={inputFields[0].ThemeName}
                                                                    disabled

                                                                ></input>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <div className="form-group">
                                                            <label htmlFor={"Endcustomer"}>
                                                                End Customer

                                                            </label>
                                                            <span className="input-icon icon-right">
                                                                <input
                                                                    placeholder="Enter the End Customer"
                                                                    className="form-control"
                                                                    title={inputFields[0].EndCustomer}
                                                                    name="End Customer No"
                                                                    id={"End Customer"}
                                                                    value={inputFields[0].EndCustomer}
                                                                    disabled

                                                                ></input>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-3">
                                                        <div className='form-group'>
                                                            <label htmlFor="Awbdate" >Submit Sent Details</label><span className="text-danger">*</span>
                                                            <span className='input-icon icon-right'>
                                                                <input
                                                                    placeholder="Submit Sent Details"
                                                                    className="form-control"
                                                                    name="SubmitSentDetails"
                                                                    id={
                                                                        "SubmitSentDetails"
                                                                    }
                                                                    value={inputFields[0].SubmitSentDetails}
                                                                    disabled
                                                                ></input>
                                                            </span>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="tabbable">
                                                        <ul
                                                            className="nav nav-tabs nav-justified"
                                                            id="myTab2"
                                                        >
                                                            <li
                                                                className={tab === 0 ? "active" : ""} >

                                                                <a
                                                                    onClick={event => TabChange(0)}

                                                                >
                                                                    <h5>Submit Information</h5>
                                                                </a>
                                                            </li>

                                                            <li
                                                                className={tab === 1 ? "active" : " "}

                                                            >
                                                                <a


                                                                    onClick={event => TabChange(1)}
                                                                >
                                                                    <h5>Order Information</h5>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                        <div className="tab-content">
                                                            <div id="idSubmitInformation2" className={tab === 0 ? "tab-pane in active" : "tab-pane"} style={{ width: "100%" }}>
                                                                <div className="row">
                                                                    <div className="col-sm-10">
                                                                        <div className="row">
                                                                            <div className="col-sm-3">
                                                                                <div className='form-group'>
                                                                                    <label htmlFor="BuyerID">Strike Off Code</label>
                                                                                    <span className='input-icon icon-right'>
                                                                                        <input
                                                                                            placeholder="StikeoffCode"
                                                                                            className="form-control cut_text"
                                                                                            title={inputFields.StrikeoffCode}
                                                                                            name="StrikeoffCode"
                                                                                            id={
                                                                                                "StrikeoffCode"
                                                                                            }
                                                                                            value={
                                                                                                inputFields[0].StrikeoffCode
                                                                                            }

                                                                                            disabled
                                                                                        ></input>
                                                                                    </span>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-sm-3">
                                                                                <div className='form-group'>
                                                                                    <label htmlFor="SeasonID" >Strike Off Name</label>
                                                                                    <span className='input-icon icon-right'>
                                                                                        <input
                                                                                            placeholder=" Name"
                                                                                            className="form-control cut_text"
                                                                                            title={inputFields.StrikeoffName}
                                                                                            name="StrikeoffName"
                                                                                            id={
                                                                                                "StrikeoffName"
                                                                                            }
                                                                                            value={
                                                                                                inputFields[0].StrikeoffName
                                                                                            }

                                                                                            disabled
                                                                                        ></input>
                                                                                    </span>
                                                                                </div>
                                                                            </div>



                                                                            <div className="col-sm-3">
                                                                                <div className="form-group">
                                                                                    <label htmlFor={"Quarter"}>Quality</label>
                                                                                    <span className="input-icon icon-right">

                                                                                        <input
                                                                                            placeholder="Quality"
                                                                                            className="form-control cut_text"
                                                                                            title={inputFields.Quality}
                                                                                            name="StrikeOffQuality"
                                                                                            id={
                                                                                                "StrikeOffQuality"
                                                                                            }
                                                                                            value={
                                                                                                inputFields[0].Quality
                                                                                            }

                                                                                            disabled
                                                                                        ></input>

                                                                                    </span>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-sm-3">

                                                                                <label style={{ marginLeft: "20px" }} htmlFor={"Quarter"}>Strike Off Image</label>
                                                                                <br />
                                                                                {/* <i className="fa fa-chevron-circle-left fa-2x" onClick={() => previmage()} style={{ cursor: "pointer" }}></i> */}
                                                                                {
                                                                                    getSubImageList.length !== 0 ?
                                                                                        <img src={window.$APIBaseURL + "Images/Style/Strikeoff/" + getSubImageList[getCurrimageindex]} alt="No Image" width="150px" height="150px" style={{ border: "2px solid black" }} />
                                                                                        :
                                                                                        <div align="center" style={{ width: "150px", height: "150px" }}>
                                                                                            No Images Found
                                                                                        </div>
                                                                                }
                                                                                {/* <i className="fa fa-chevron-circle-right fa-2x" onClick={() => nextimage()} style={{ cursor: "pointer" }}></i> */}




                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-sm-6">
                                                                                <div className="form-group">

                                                                                    <label htmlFor={"StrikeoffDimensionId"}>Strike Off Dimension <span className="text-danger">*</span></label>
                                                                                    <select id={"StrikeoffDimensionId"} name="StrikeoffDimensionId" className="form-select"
                                                                                        value={inputFields[0].StrikeoffDimensionId}
                                                                                        // onChange={(event) => handleChangeSubmissionMain(event, 'StrikeoffDimensionId')}
                                                                                        style={{ border: submittedpopup && inputFields[0].StrikeoffDimensionId === "0" ? '1px solid red' : '' }}
                                                                                        disabled
                                                                                    >
                                                                                        <option value="0">-Select Strike Off-</option>
                                                                                        {
                                                                                            StrikeoffDimension.map(item => (
                                                                                                <option key={item.id} value={item.value}>
                                                                                                    {item.label}
                                                                                                </option>
                                                                                            ))
                                                                                        }

                                                                                    </select>




                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-12 col-lg-12">
                                                                            <div className="row">
                                                                                <div className="col-sm-8 col-lg-8" style={{ paddingLeft: "0" }}>
                                                                                    <div className='form-group'>
                                                                                        <label htmlFor="Remarks">Submitted Remarks</label>
                                                                                        <textarea maxLength="300" name="Remarks" value={inputFields[0].Remarks}
                                                                                            rows="4" style={{ width: "100%" }}
                                                                                            disabled
                                                                                        ></textarea>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>


                                                                        <div>
                                                                            <table>
                                                                                <tr>
                                                                                    <td><label>Submit Options</label> </td>
                                                                                    <td><label>Submission Card</label></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td> <div className="col-sm-6" style={{ paddingLeft: "0" }}>
                                                                                        <div className="form-group">


                                                                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup"
                                                                                                style={{ width: "700px" }}>
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th >Options <span className="text-danger">*</span></th>
                                                                                                        <th>Supplier Reference No <span className="text-danger">*</span></th>
                                                                                                        <th>Approved Option <span className="text-danger">*</span></th>

                                                                                                    </tr>

                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {
                                                                                                        submittedoption && (
                                                                                                            submittedoption.map((item, index) => {
                                                                                                                return (
                                                                                                                    <>
                                                                                                                        <tr>
                                                                                                                            <td>
                                                                                                                                <span className="input-icon icon-right">
                                                                                                                                    <input
                                                                                                                                        placeholder="Option Name"
                                                                                                                                        className="form-control"
                                                                                                                                        title={item.optionName}
                                                                                                                                        name="OptionName"
                                                                                                                                        id={
                                                                                                                                            "OptionName"
                                                                                                                                        }
                                                                                                                                        value={
                                                                                                                                            item.optionName
                                                                                                                                        }
                                                                                                                                        disabled
                                                                                                                                    ></input>


                                                                                                                                </span>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <span className='input-icon icon-right'>
                                                                                                                                    <input
                                                                                                                                        placeholder=""
                                                                                                                                        className="form-control"
                                                                                                                                        title={item.supplierReferenceNo}
                                                                                                                                        name="Sendto"
                                                                                                                                        id={
                                                                                                                                            "Sendto"
                                                                                                                                        }
                                                                                                                                        value={
                                                                                                                                            item.supplierReferenceNo
                                                                                                                                        }
                                                                                                                                        disabled
                                                                                                                                    ></input>
                                                                                                                                </span>

                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <span className='input-icon icon-right'>


                                                                                                                                    <Reactselect className="basic-single" name="TaskHolderOrFollower"
                                                                                                                                        id={
                                                                                                                                            "TaskHolderOrFollower"
                                                                                                                                        }
                                                                                                                                        isLoading={false}
                                                                                                                                        isClearable={true}
                                                                                                                                        isSearchable={true}
                                                                                                                                        onChange={event => handleSubmitoptionchange(event, 'ApprovedOption', index, "StrikeOff Approval")}
                                                                                                                                        value={getApprovedOptions.filter(function (option) {
                                                                                                                                            return option.value === item.approvedOptionName;
                                                                                                                                        })}
                                                                                                                                        options={getApprovedOptions}
                                                                                                                                        styles={
                                                                                                                                            submitted && (item.approvedOptionName === '0' || item.approvedOptionName === "" || item.approvedOptionName === null) ? styles2 : styles1
                                                                                                                                        }


                                                                                                                                        menuPosition="fixed"
                                                                                                                                        // disabled={inputFields[0].ApprovalStatus === "Completed" ? true : false}
                                                                                                                                        isDisabled={inputFields[0].ApprovalStatus === "Completed"}

                                                                                                                                    ></Reactselect>
                                                                                                                                </span>

                                                                                                                            </td>

                                                                                                                        </tr>
                                                                                                                    </>

                                                                                                                )
                                                                                                            }))
                                                                                                    }
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div></td>
                                                                                    <td>
                                                                                        <div className={ImgClassName}
                                                                                            htmlFor="upload-button"
                                                                                        >
                                                                                            <img
                                                                                                src={inputFields[0].SwatchCardIamgePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + inputFields[0].SwatchCardIamgePath}

                                                                                                alt="Picture" style={{ marginBottom: '5px', height: '200px', width: '200px', border: "1px solid #d5d5d5" }} />


                                                                                        </div>
                                                                                    </td>

                                                                                </tr>
                                                                            </table>




                                                                            <div> {
                                                                                isOpen && (

                                                                                    <Lightbox mainSrc={window.$APIBaseURL + getImagename}
                                                                                        alt="bg image"
                                                                                        onCloseRequest={
                                                                                            () => setIsOpen(false)
                                                                                        } />
                                                                                )
                                                                            } </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div id="idOrderInformation2" className={tab === 1 ? "tab-pane in active" : "tab-pane"} style={{ width: "100%" }}>
                                                                {/* <div className="row">
                                                                    <div className="col-sm-12" align={"right"}>
                                                                        <button type="button" className="btn btn-primary" onClick={() => ShowHideDivs()}
                                                                            disabled={inputFields[0].ApprovalStatus === "Completed" ? true : false}>
                                                                            <i className="fa fa-plus"></i> &nbsp;Add
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <br /> */}
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className="dataTables_wrapper no-footer">
                                                                            {
                                                                                OrderInfoMainDetails.length !== 0 ?

                                                                                    <table className="table table-bordered">

                                                                                        <tbody>

                                                                                            {
                                                                                                OrderInfoMainDetails.map(comment => (
                                                                                                    <Fragment>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <div className="row">
                                                                                                                    <div className="col-lg-8">
                                                                                                                        <i
                                                                                                                            className={
                                                                                                                                expandState[comment.strikeoffLogoPath] ?
                                                                                                                                    'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                                            }
                                                                                                                            onClick={() => handleExpandRow(comment.strikeoffLogoPath, getOrderinfo)}
                                                                                                                        ></i>
                                                                                                                        <span className="BuyerPaddingLeft">
                                                                                                                            {
                                                                                                                                comment.strikeOffCode
                                                                                                                            }
                                                                                                                            -
                                                                                                                            {
                                                                                                                                comment.strikeOffName
                                                                                                                            }
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                    <div className="col-lg-4">
                                                                                                                        <span className="Actionbutton">
                                                                                                                            <img src={window.$APIBaseURL + "Images/Style/Strikeoff/" + comment.strikeoffLogoPath} height="50px" width="50px"></img>
                                                                                                                        </span>
                                                                                                                    </div>

                                                                                                                </div>

                                                                                                            </td>

                                                                                                        </tr>
                                                                                                        <>
                                                                                                            {
                                                                                                                expandedRows.includes(comment.strikeoffLogoPath) ?
                                                                                                                    <tr>
                                                                                                                        <td>
                                                                                                                            <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                                                                <thead>
                                                                                                                                    <tr>
                                                                                                                                        <th>Story</th>
                                                                                                                                        <th>Theme</th>
                                                                                                                                        <th>Details </th>
                                                                                                                                        <th>Task Holder / Follower</th>
                                                                                                                                        {/* <th>Action </th> */}
                                                                                                                                    </tr>
                                                                                                                                </thead>

                                                                                                                                <tbody>
                                                                                                                                    {
                                                                                                                                        expandList.map((item, index) => (
                                                                                                                                            <Fragment>

                                                                                                                                                <tr>
                                                                                                                                                    <td>{item.storyName}</td>
                                                                                                                                                    <td>{item.themeName}</td>
                                                                                                                                                    <td>{item.details}</td>
                                                                                                                                                    <td>{item.followerName}</td>
                                                                                                                                                    {/* <td className="Actionbutton fixed-column">
                                                                                                                                                        {" "}
                                                                                                                                                        <div style={{ width: "80px" }}>
                                                                                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                                                                                <input type="checkbox" name="SelectedDetail"

                                                                                                                                                                    onChange={event => (OnChangeOrderinfo(event, item.index - 1))}
                                                                                                                                                                    checked={item.SelectedDetail === 1 ? true : false}
                                                                                                                                                                    className="colored-blue clearOpacity"
                                                                                                                                                                    disabled={inputFields[0].ApprovalStatus === "Completed" ? true : false} />
                                                                                                                                                                <span className="text"></span>
                                                                                                                                                            </label>
                                                                                                                                                            &nbsp;&nbsp;

                                                                                                                                                            <button
                                                                                                                                                                type="button"
                                                                                                                                                                className="btn  btn-xs  btn-danger"
                                                                                                                                                                title="Delete Strike Off"
                                                                                                                                                                onClick={() =>
                                                                                                                                                                    handleRemoveOrderinfo(index)
                                                                                                                                                                }
                                                                                                                                                                disabled={inputFields[0].ApprovalStatus === "Completed" ? true : false}
                                                                                                                                                            >
                                                                                                                                                                <i className="fa fa-trash-o"></i>
                                                                                                                                                            </button>

                                                                                                                                                        </div>
                                                                                                                                                    </td> */}

                                                                                                                                                </tr>

                                                                                                                                            </Fragment>
                                                                                                                                        ))

                                                                                                                                    }

                                                                                                                                </tbody>
                                                                                                                            </table>
                                                                                                                        </td>
                                                                                                                    </tr> : null
                                                                                                            }
                                                                                                        </>
                                                                                                    </Fragment>
                                                                                                ))
                                                                                            }


                                                                                        </tbody>
                                                                                    </table>
                                                                                    :
                                                                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                                                        <TableHeader headers={headers} />
                                                                                        <tbody>
                                                                                            <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                                                                                        </tbody>
                                                                                    </table>


                                                                            }



                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <br />


                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>


                                            <div className="mt-10">
                                                <div className="row">
                                                    <div className="col-sm-8 col-lg-8">
                                                        <div className='form-group'>
                                                            <label htmlFor="Remarks">Remarks</label>
                                                            <textarea maxLength="300" name="Remarks" value={inputFields[0].ApprovalRemark}
                                                                onChange={event => handleChange(event, 'Remarks')}
                                                                rows="4" style={{ width: "110%" }}
                                                                disabled={inputFields[0].ApprovalStatus === "Completed" ? true : false}
                                                            ></textarea>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12" align="right">
                                                <button type="button" className="btn btn-success margin-Align" onClick={() => SaveApproval()}
                                                    disabled={inputFields[0].ApprovalStatus === "Completed" ? true : false}
                                                >
                                                    <i className="fa fa-check right"></i> &nbsp;Save
                                                </button>
                                            </div>
                                        </div>


                                    </>
                                    : ''
                            }

                        </Modal.Body>

                    </Modal> : ''

            }



            {
                getID.showPopupDelete ? <SweetAlertPopup data={
                    getID.Params
                }
                    deleteCallback={Delcallback}
                    showpopup={true}
                    pageActionId={GenStrikeOffSubmissionlId}
                    searchParams={getID.SearchParams}
                    Msg={"Strike Off Submission Deleted Successfully."}
                /> : null
            }
            {
                <SweetAlert
                    show={deletepopup}
                    warning
                    showCancel
                    confirmBtnText="Yes, delete it!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="primary"
                    // closeOnConfirm
                    // closeOnCancel
                    title="Are you sure do you want to delete it?"
                    onConfirm={DeleteMultipleApproval}
                    onCancel={() => setdeletepopup(false)}
                    showCloseButton
                    focusCancelBtn>
                    {/* I did it! */}
                </SweetAlert>
            }
            {getsID.showPopupDelete ? <SweetAlertPopup

                // data={''}
                data={
                    getsID.Params
                }
                deleteCallback={DeleteCallback}
                showpopup={true}
                pageActionId={getsID.TNAManualClose}
                Msg={'Manual Close Submission Deleted Sucessfully'}
            /> : null}
            {
                //View Size
                showSizePopup.isPopup === true ?
                    <Modal show={showSizePopup.isPopup === true} dialogClassName="modal-lg" dragable backdrop="static" keyboard={false} onHide={() => handleSizePopupClose()} >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                View Size
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="col-md-12">

                                <table className="table table-striped table-bordered table-hover dataTable no-footer" >
                                    <thead>
                                        <tr>
                                            <th>Detail</th>
                                            <th>Size Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            getSizeList && getSizeList.map((inputField) => (
                                                <Fragment>
                                                    <tr>
                                                        <td>
                                                            <span>
                                                                {showSizePopup.detail}

                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span>
                                                                {inputField.label}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </Fragment>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="col-md-12" style={{ paddingTop: '8px' }}>
                                <Button variant="success" onClick={() => handleSizePopupClose()}>
                                    Close
                                </Button>
                            </div>
                        </Modal.Footer>
                    </Modal> : ''
            }

            {
                openReschedulepopup === true &&
                <Modal show={openReschedulepopup === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => setopenReschedulepopup(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Reschedule Add
                            {
                                getSubRescheduleList.length !== 0 ?
                                    <>
                                        <br />
                                        (Initial Date  Duration : {getSubRescheduleList[0].duration} Start Date : {getSubRescheduleList[0].startDate} End Date : {getSubRescheduleList[0].endDate})
                                    </>
                                    : ''

                            }

                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Task Name</th>
                                    <th>Strikeoff</th>
                                    <th>Details</th>
                                    <th>Duration</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Leave Days</th>

                                    {/* <th>Action </th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    TaskDetails.length !== 0 ?
                                        TaskDetails.filter(t => t.taskName === "Strike Off Submission (Local Development)").map((item, index) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <td>
                                                            {item.taskName}

                                                        </td>
                                                        <td>
                                                            <span className='input-icon icon-right'>
                                                                {
                                                                    item.strikeOff
                                                                }
                                                            </span>


                                                            <div className="trwidth_manual" style={{ textAlign: "center" }}>
                                                                <img className="width_manual"
                                                                    src={item.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images/Style/Strikeoff/" + item.imagePath}
                                                                    alt="Logo"
                                                                    onClick={
                                                                        () => setIsOpen(item.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images/Style/Strikeoff/" + item.imagePath)
                                                                    }
                                                                />
                                                            </div>


                                                        </td>
                                                        <td>
                                                            {item.itemDetails}

                                                        </td>
                                                        <td>
                                                            <input type="text"
                                                                placeholder="Duration"
                                                                value={item.duration}
                                                                onChange={event => handleChangeReschedule(event, index, "Duration")}
                                                                maxLength="3"
                                                                className={'form-control'}
                                                            />

                                                        </td>
                                                        <td>
                                                            <DatePicker className="form-control min_width_Date"
                                                                name="ChildStartdate"
                                                                id={
                                                                    "ChildStartdate"
                                                                }
                                                                selected={Date.parse(moment(item.startDate, 'MM/DD/YYYY').toISOString())}
                                                                dateFormat="dd/MM/yyyy"
                                                                readOnly={true}

                                                            />

                                                        </td>
                                                        <td>
                                                            <DatePicker className="form-control min_width_Date"
                                                                name="ChildEnddate"
                                                                id={
                                                                    "ChildEnddate"
                                                                }

                                                                selected={Date.parse(moment(item.endDate, 'MM/DD/YYYY').toISOString())}
                                                                onChange={
                                                                    event => handleChangeReschedule(event, index, "EndDate")
                                                                }
                                                                dateFormat="dd/MM/yyyy"
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="scroll"
                                                                autoComplete="off"
                                                                popperProps={{
                                                                    positionFixed: true // use this to make the popper position: fixed
                                                                }}
                                                                //readOnly={Buttonvisible === 'View' ? true : false}
                                                                customInput={<EndDateCustomInput />}

                                                            />
                                                        </td>
                                                        <td>
                                                            {item.leaveDayName}

                                                        </td>

                                                    </tr>
                                                </>

                                            )
                                        })
                                        :
                                        <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>

                                }

                            </tbody>
                        </table>
                        <br />

                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                            <thead>
                                <tr>
                                    <th>Submission Reschedule Comments<span className="text-danger">*</span></th>
                                    {/* <th>Approval Reschedule Comments<span className="text-danger">*</span></th> */}
                                </tr>
                            </thead>
                            <tbody>

                                <tr>
                                    <td style={{ width: "600px" }} >
                                        <textarea
                                            rows="3"
                                            placeholder="Enter Comments here..."
                                            autoFocus onFocus={""} autoComplete="off"
                                            maxLength="500" name="EnterRemarks"
                                            id={"EnterRemarks"}
                                            value={RescheduleComment.SubmissionComment}
                                            onChange={event => handleChangeReschedule(event, 0, "SubmissionComment")}
                                            style={{ width: "100%", border: SubmitReschedule && RescheduleComment.SubmissionComment === '' ? '1px solid red' : '' }}>
                                        </textarea>
                                    </td>
                                    {/* <td style={{ width: "600px" }} >
                                        <textarea
                                            rows="3"
                                            placeholder="Enter Comments here..."
                                            autoFocus onFocus={""} autoComplete="off"
                                            maxLength="500" name="EnterRemarks"
                                            id={"EnterRemarks"}
                                            value={RescheduleComment.ApprovalComment}
                                            onChange={event => handleChangeReschedule(event, 0, "ApprovalComment")}
                                            style={{ width: "100%", border: SubmitReschedule && RescheduleComment.ApprovalComment === '' ? '1px solid red' : '' }}>
                                        </textarea>
                                    </td> */}

                                </tr>
                            </tbody>

                        </table>
                        {
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Duration</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Comments</th>
                                        <th>Rescheduled Date</th>
                                        <th>Rescheduled By</th>
                                        {/* <th>Action </th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        getSubRescheduleList.length !== 0 ?
                                            getSubRescheduleList.filter(t => t.type === 'Submission').map((item, subindex) => {
                                                // getSubRescheduleList.filter(t => t.type !== 'Initial').map((item, subindex) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td>
                                                                {item.type}

                                                            </td>
                                                            <td style={{ width: "80px" }}>
                                                                {item.duration}

                                                            </td>
                                                            <td>
                                                                {item.startDate}

                                                            </td>
                                                            <td>
                                                                {item.endDate}

                                                            </td>
                                                            <td style={{ width: "300px" }}>
                                                                {item.comments}
                                                            </td>
                                                            <td>
                                                                {item.createdDate}

                                                            </td>
                                                            <td>
                                                                {item.rescheduledBy}

                                                            </td>

                                                        </tr>
                                                    </>

                                                )
                                            })
                                            :
                                            <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>

                                    }

                                </tbody>
                            </table>


                        }

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={() => handleSaveReschedule()}>
                            {/* {getRescheduleAction === 'Add' ? 'Save' : 'Update'} */}
                            Add
                        </Button>
                    </Modal.Footer>
                </Modal>

            }
            {loader}
        </Fragment >

    )
}

export default React.memo(StrikeOffViewSubmissionTab);