import React, { useState, useEffect, Fragment, useMemo } from "react";
import 'react-notifications-component/dist/theme.css';
import { TableHeader, Pagination, Search, PageCount } from "../Datatable";
import SweetAlertPopup from '../Common/SweetAlertPopup';
import Lightbox from "react-image-lightbox"
import CommonService from "../../services/Common/CommonService";
import Nodify from "../Common/ReactNotification";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import { useDispatch, useSelector } from "react-redux";
import ReactNotification from 'react-notifications-component';
import { Redirect } from 'react-router-dom';
import PurchaseOrderService from "../../services/PurchaseOrder/PurchaseOrderService";
import SearchRetain from "../Common/SearchRetain";
import { PurchaseOrderID } from "../Common/PageActionNumber";
import moment from 'moment';
import { retrievePurchaseOrder } from "../../actions/purchaseorder";
import Reactselect from 'react-select';
import StyleService from "../../services/Style/styleService";
import { retrieveStyle, updateQuickStyleCreate, retrieveCopyStyle } from "../../actions/style";


const PurchaseOrderList = (props) => { // Selecters
    // const [getCompanyList, setCompanyList] = useState({});
    // const [comments, setComments] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [getID, setID] = useState(0);
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    const [isOpen, setIsOpen] = useState(false)
    const [ListTile, setListTile] = useState("List");
    const [getPlaceholder] = useState("Buyer / Season / Brand / Supplier / End Customer / ID No");
    const [getBuyersearch, setBuyerSearch] = useState("");
    const dispatch = useDispatch();
    const reducerState = useSelector((state) => state);
    const [getBuyerFilterlist, setBuyerFilterList] = useState();
    const [getQuarterFilterlist, setQuarterFilterList] = useState();
    const [getBrandFilterlist, setBrandFilterlist] = useState();
    const [getidpoFilterlist, setIdpoFilterList] = useState();

    const [getrefnoFilterlist, setrefnoFilterList] = useState();
    const [getReceiveddateFilterlist, setReceiveddateFilterList] = useState();
    
    const [getSupplierFilterlist, setSupplierFilterList] = useState();
    const [getStylenameFilterlist, setStyleNameFilterList] = useState();
    const [getEndCustomerFilterlist, setEndCustomerFilterList] = useState();
    const [getSeasonFilterlist, setSeasonFilterList] = useState();
    const [getDestinationFilterlist, setDestinationFilterList] = useState();
    const [getSeasonsearch, setSeasonSearch] = useState("");
    const [getIdPosearch, setIdPoSearch] = useState("");
    const [getrefnosearch, setrefnoSearch] = useState("");
    const [getEndCustomersearch, setEndCustomerSearch] = useState("");
    const [getReceiveddatesearch, setReceiveddateSearch] = useState("");
    const [getSuppliersearch, setSupplierSearch] = useState("");
    const [getBrandsearch, setBrandSearch] = useState("");
    const [getStylenamesearch, setStylenameSearch] = useState("");
    const [getQuartersearch, setQuarterSearch] = useState("");
    const [getDestinationsearch, setDestinationSearch] = useState("");
    const [ShowFilter, setShowFilter] = useState(true);
    const { user: currentUser } = reducerState.auth;

    const comments = reducerState.purchaseorder.purchaseorderList;
    let isLoadingPurchaseOrder = reducerState.purchaseorder.isLoadingPurchaseOrder;
    const BuyerBrandDetails = reducerState.auth.UserMenuList.item6 !== undefined ? reducerState.auth.UserMenuList.item6 : [];
    // If check production or sampling
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProduction = activeMenus.IsProduction;
    // End

    //Enable Menu
    let activeMenu;

    const menulist = useSelector((state) => state.common.MenuList.item3);
    if (menulist !== undefined) {
        activeMenu = menulist.filter(i => i.menuName === 'PurchaseOrder');

    }


    // End Enable Menu
    useEffect(() => {
        let purchaseOrderID = 0;
        if (IsProduction !== 0) {
            dispatch(retrievePurchaseOrder(purchaseOrderID, IsProduction));
        }
        const state1 = props.location.state;
        if (state1 !== undefined && state1 !== null) {
            let Msg;
            let Type = 'success'; // default, info, warning
            let title = 'Success!';
            if (state1.message === 'Edit') {
                Msg = 'Purchase Order updated successfully.';
            } else if (state1.message === 'Add') {
                Msg = 'Purchase Order added successfully.';
            } else {
                Msg = 'Error occured';
                Type = 'warning';
                title = 'Error!';
            }
            window.history.replaceState(null, '')
            Nodify(title, Type, Msg);
        }
        var page = getPlaceholder;
        var setConstraint = SearchRetain(page);
        if (setConstraint !== undefined) {
            setCurrentPage(setConstraint.CurrentPage);
            setITEMS_PER_PAGE(setConstraint.PageItems);
        }

        // setTimeout(() => {
        //     PurchaseOrderService.GetPurchaseOrderList(0).then((response) => {
        //         if (response.data) {
        //             //hideLoader();
        //             const state1 = props.location.state
        //             if (state1 !== undefined) {
        //                 let Msg;
        //                 let Type = 'success'; //default, info, warning
        //                 let Title = 'Success!';
        //                 if (state1.message === 'Edit') {
        //                     Msg = 'Purchase Order updated successfully.';
        //                     // Type = 'default';
        //                 } else if (state1.message === 'Add') {
        //                     Msg = 'Purchase Order added successfully.';
        //                 } else {
        //                     Msg = 'Error occured';
        //                     Type = 'warning';
        //                     Title = 'Error!';
        //                 }
        //                 window.history.replaceState(null, '')
        //                 Nodify(Title, Type, Msg);
        //             }
        //             var page = getPlaceholder;
        //             var setConstraint = SearchRetain(page);
        //             if (setConstraint !== undefined) {
        //                 setCurrentPage(setConstraint.CurrentPage);
        //                 setITEMS_PER_PAGE(setConstraint.PageItems);
        //             }
        //         } else {
        //             //hideLoader();
        //         }
        //     }).catch(() => {
        //         //hideLoader();
        //     }).finally(() => {
        //         hideLoader();
        //     });
        // }, 500)

    }, [dispatch, IsProduction]);

   

    useEffect(() => {
        if (!isLoadingPurchaseOrder) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingPurchaseOrder])

    const LoadDropdownsBasedOnFilter = (data) => {
       

        var buyerlist = [];  var seasonlist = []; var idpolist = []; var refnolist = [];
        var brandlist = []; var employeelist = []; var stylenamelist =[]; var supplierList =[];
        var receiveddatelist =[]; var quarterlist =[]; var endcustomerList=[]; var destinationList=[];
        data.map(x => {
            buyerlist.push({
                label: x.buyerName,
                value: x.buyerID
            })
            seasonlist.push({
                label: x.seasonName === "" ? "-" : x.seasonName,
                value: x.seasonID
            })
            refnolist.push({
                label: x.focusPONo === "" ? "-" : x.focusPONo,
                value: x.focusPONo
            })
            idpolist.push({
                label: x.idpono === "" ? "-" : x.idpono,
                value: x.idpono
            })
            brandlist.push({
                label: x.brandName === "" ? "-" : x.brandName,
                value: x.brandID
            })
            employeelist.push({
                label: x.createdByStr === "" ? "-" : x.createdByStr,
                value: x.createdBy
            })
            stylenamelist.push({
                label: x.styleName === "" ? "-" : x.styleName,
                value: x.styleName
            })
            supplierList.push({
                label: x.trimSupplierName === "" ? "-" : x.trimSupplierName,
                value: x.trimSupplierName
            })
            
            receiveddatelist.push({
                label: x.createdDate === "" ? "-" : x.createdDate,
                value: x.createdDate
            })
            quarterlist.push({
                label: x.quaterName === "" ? "-" : x.quaterName,
                value: x.quaterName
            })
            endcustomerList.push({
                label: x.endCustomerName === "" ? "-" : x.endCustomerName,
                value: x.endCustomerName
            })
            destinationList.push({
                label: x.destinationName === "" ? "-" : x.destinationName,
                value: x.destinationName
            })
          
        })
        setBuyerFilterList([...new Map(buyerlist.map(item =>
            [item['label'], item])).values()]);

        setrefnoFilterList([...new Map(refnolist.map(item =>
            [item['label'], item])).values()])
            
            setReceiveddateFilterList([...new Map(receiveddatelist.map(item =>
                [item['label'], item])).values()])
        setSupplierFilterList([...new Map(supplierList.map(item =>
                [item['label'], item])).values()])

        setSeasonFilterList([...new Map(seasonlist.map(item =>
            [item['label'], item])).values()])
        // setStyleNameFilterList([...new Map(stylelist.map(item =>
        //         [item['label'], item])).values()])
      
        setIdpoFilterList([...new Map(idpolist.map(item =>
            [item['label'], item])).values()])

        setBrandFilterlist([...new Map(brandlist.map(item =>
            [item['label'], item])).values()])
      
        setQuarterFilterList([...new Map(quarterlist.map(item =>
            [item['label'], item])).values()])

        setStyleNameFilterList([...new Map(stylenamelist.map(item =>
            [item['label'], item])).values()])
        setEndCustomerFilterList([...new Map(endcustomerList.map(item =>
                [item['label'], item])).values()])
        setDestinationFilterList([...new Map(destinationList.map(item =>
            [item['label'], item])).values()])
    }
   

    const resetStyle = () => {
       

        localStorage.removeItem('Buyer');

        localStorage.removeItem('Season');
        localStorage.removeItem('Brand');
        localStorage.removeItem('Supplier')
        localStorage.removeItem('IdPo');
        localStorage.removeItem('Ref');
        localStorage.removeItem('Receiveddate');
        localStorage.removeItem('Stylename');
        
        localStorage.removeItem('EndCustomer')
        localStorage.removeItem('Destination')
        setBuyerSearch('');
        setrefnoSearch('');
        setSeasonSearch('');
        setBrandSearch('');
        setQuarterSearch('');
        setSupplierSearch('');
        setReceiveddateSearch('');
        setIdPoSearch('');
        setStylenameSearch('');
        setEndCustomerSearch('');
        setDestinationSearch('');


        // var defaultStartDate = new Date();
        // const emptyStateDate = [
        //     {
        //         startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
        //         endDate: new Date(),
        //         key: 'selection',
        //         isShow: false
        //     }];
        // setStateDate(emptyStateDate);
        // let StartDate = null;
        // let EndDate = null;
        // if (moment(emptyStateDate[0].startDate).isValid()) {
        //     StartDate = moment(emptyStateDate[0].startDate).format('MM/DD/YYYY');
        // }
        // if (moment(emptyStateDate[0].endDate).isValid()) {
        //     EndDate = moment(emptyStateDate[0].endDate).format('MM/DD/YYYY');
        // }

        // Set PO start date to '1900-01-01 00:00:00.000'
       const PoStartDate = '01/01/1900';
    
        // Set current date as end date
        const PoEndDate = new Date().toLocaleDateString('en-GB'); // Format as DD/MM/YYYY
        let params = { RouteId: 0, IsProduction: IsProduction, employeeinformationID: currentUser.employeeinformationID,PoStartDate, PoEndDate }
        if (IsProduction !== 0) {
            dispatch(retrieveStyle(params));
        }
        var Data = { Searchvalue: '', Page: '' }
        localStorage.setItem('Search', JSON.stringify(Data));
    }

    const commentsData = useMemo(() => {
        //let computedComments = comments;
        let computedComments = comments.filter(o => BuyerBrandDetails.some(({ brandId, buyerId, isAllInfo, isMapped }) =>
            o.brandID === brandId && o.buyerID === buyerId && isAllInfo === 1 && isMapped === 0));
        // let computedCommentsBuyerDetails = comments.filter(o => BuyerBrandDetails.some(({ brandId, buyerId, isAllInfo, isMapped }) =>
        //     o.brandID === brandId && isAllInfo === 0 && isMapped === 1));
        let computedCommentsBuyerDetails = comments.filter(o => BuyerBrandDetails.some(({ brandId, buyerId, isAllInfo, isMapped, seasonID, cubeEmployeeIdList }) =>
            o.brandID === brandId && isAllInfo === 0 &&
            (isMapped === 1 && seasonID === o.seasonID && cubeEmployeeIdList.split(',').includes(currentUser.employeeinformationID.toString()))));
        if (computedCommentsBuyerDetails.length > 0) {
            computedComments = [...computedComments, ...computedCommentsBuyerDetails];
        }
        // if (search) {
        //     computedComments = computedComments.filter(comment =>
        //         comment.buyerName.toLowerCase().includes(search.toLowerCase())
        //         || comment.seasonName.toLowerCase().includes(search.toLowerCase())
        //         || comment.brandName.toLowerCase().includes(search.toLowerCase())
        //         || comment.trimSupplierName.toLowerCase().includes(search.toLowerCase())
        //         || comment.styleName.toLowerCase().includes(search.toLowerCase())
        //         || comment.endCustomerName.toLowerCase().includes(search.toLowerCase())
        //         || comment.idpono.toLowerCase().includes(search.toLowerCase())
        //     );
        // }
       
        if (getBuyersearch) {
            computedComments = computedComments.filter(comment =>
                comment.buyerName.toLowerCase().includes(getBuyersearch.label.toLowerCase())
            )

            setTotalItems(computedComments.length);
        }
        if (getSeasonsearch) {
            computedComments = computedComments.filter(comment =>
                comment.seasonName.toLowerCase().includes(getSeasonsearch.label.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        if (getBrandsearch) {
            computedComments = computedComments.filter(comment =>
                comment.brandName.toLowerCase().includes(getBrandsearch.label.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }

       if (getIdPosearch) {
            computedComments = computedComments.filter(comment =>
                comment.idpono.toLowerCase().includes(getIdPosearch.label.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
      
        if (getrefnosearch) {
            computedComments = computedComments.filter(comment =>
                comment.focusPONo.toLowerCase().includes(getrefnosearch.label.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }

        if (getSuppliersearch) {
            computedComments = computedComments.filter(comment =>
                comment.trimSupplierName.toLowerCase().includes(getSuppliersearch.label.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }

        
        if (getReceiveddatesearch) {
            computedComments = computedComments.filter(comment =>
                comment.createdDate.toLowerCase().includes(getReceiveddatesearch.label.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        // if (getQuartersearch) {
        //     computedComments = computedComments.filter(comment =>
        //         comment.quaterName.toLowerCase().includes(getQuartersearch.label.toLowerCase())
        //     );
        //     setTotalItems(computedComments.length);
        // }

        if (getQuartersearch) {
            computedComments = computedComments.filter(comment => 
                comment.quaterName?.toLowerCase().includes(getQuartersearch.label?.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        
       
        if (getStylenamesearch) {
            computedComments = computedComments.filter(comment =>
                comment.styleName.toLowerCase().includes(getStylenamesearch.label.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        if (getEndCustomersearch) {
            computedComments = computedComments.filter(comment =>
                comment.endCustomerName.toLowerCase().includes(getEndCustomersearch.label.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        if (getDestinationsearch) {
            computedComments = computedComments.filter(comment => {
                // Ensure comment.destination and getDestinationsearch.label are converted to strings
                const destinationName = comment.destinationName != null ? String(comment.destinationName).toLowerCase() : '';
                const searchLabel = getDestinationsearch.label != null ? String(getDestinationsearch.label).toLowerCase() : '';
                
                return destinationName.includes(searchLabel);
            });
            setTotalItems(computedComments.length);
        }
        

       ;
        if (computedComments) {
            setTotalItems(computedComments.length);


            if (sorting.field === 'noOfStyles' || sorting.field === 'totalQuantity') {
                const reversed = sorting.order === "asc" ? 1 : -1;
                computedComments = computedComments.sort((a, b) => reversed * (Number(a[sorting.field]) - Number(b[sorting.field])));
            } else if (sorting.field === 'createdDate') {
                const reversed = sorting.order === "asc" ? 1 : -1;
                computedComments = computedComments.sort((a, b) => reversed * (new moment(a[sorting.field]).format('YYYYMMDD') - new moment(b[sorting.field]).format('YYYYMMDD')));
            } else if (sorting.field && sorting.field !== 'noOfStyles' && sorting.field !== 'createdDate') {
                const reversed = sorting.order === "asc" ? 1 : -1;
                computedComments = computedComments.sort((a, b) => reversed * (a[sorting.field].toString().localeCompare(b[sorting.field].toString())));
            }

            // Current Page slice
            let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
            if (records.length === 0 && currentPage !== 1) {

                setCurrentPage(currentPage - 1);
            }
            LoadDropdownsBasedOnFilter(computedComments);
            return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
        }
    }, [
        getBuyersearch,
        getSeasonsearch,
        getBrandsearch,
        getIdPosearch,
        getrefnosearch,
        getSuppliersearch,
        getReceiveddatesearch,
        getQuartersearch,
        getStylenamesearch,
        getEndCustomersearch,
        getDestinationsearch,
        comments,
        currentPage,
        search,
        sorting,
        ITEMS_PER_PAGE
    ]);
    useEffect(() => {
       
        if (commentsData.length > 0) {
            StyleService.LoadBuyer(2)
                .then((response) => {
                    if (response.data) {
                        BuyerListOptions = response.data;
                        var BuyerBrandInfo = BuyerListOptions.filter(o => BuyerBrandDetails.some(({ buyerId }) => o.id === buyerId));
                        if (BuyerListOptions.length > 0 && BuyerBrandDetails.length > 0) {
                            setBuyerFilterList(BuyerBrandInfo.filter(o => commentsData.some(({ buyerID }) => o.id === buyerID)));
                        }
                    }
                })
                .catch(() => { });
        }
    }, [commentsData])


    if (!currentUser) {
        return <Redirect to="/login" />;
    }


    const headers = [
        { name: "#", field: "", sortable: false },
        { name: "Created Date", field: "createdDate", sortable: true },
        { name: "Buyer", field: "buyerName", sortable: true },
        { name: "Brand", field: "brandName", sortable: true },
        { name: "Season", field: "seasonName", sortable: true },
        { name: "PO/ID No", field: "idpono", sortable: true },
        { name: "Ref No", field: "focusPONo", sortable: true },
        { name: "No of Styles", field: "noOfStyles", sortable: true },
        { name: "Total Quantity", field: "totalQuantity", sortable: true },
        { name: "Total Value", field: "totalValue", sortable: true },
        { name: "Supplier Name", field: "trimSupplierName", sortable: true },
        { name: "TNA Generate %", field: "", sortable: false },
        {
            name: "Action", field: "", sortable: false,
            display: activeMenu && activeMenu[0].isEdit === 0 && activeMenu[0].isDelete === 0 && activeMenu[0].isView === 0 ? 'none' : ''

        },
    ];

    // Actions
    const pageRedirect = (PurchaseOrderParams, redirect, page) => {
       ;
        localStorage.setItem('Pagination', JSON.stringify({ CurrentPage: currentPage, Page: page, PageItems: ITEMS_PER_PAGE }));
        if (redirect === 'Add') {
            props.history.push('/PurchaseOrder');
        }
        else if (redirect === 'Edit') {
            let IsDisable = false;
            let Param = { Operation: 27, Id: PurchaseOrderParams.purchaseOrderID, };
            CommonService.CheckMappedItem(Param).then(res => {
                if (res.data.outputResult === "-2") {
                    IsDisable = true;
                }
                // props.location.state && props.location.state.params.ispurchaseOrderMapped !== '' && props.location.state.params.ispurchaseOrderMapped !== null ? true : false
                props.location.state = { params: PurchaseOrderParams };
                props.history.push(`/PurchaseOrder`, { params: PurchaseOrderParams, IsDisable });
            });
        }
        else if (redirect === 'View') {
            PurchaseOrderParams.action = "View";
            props.location.state = { params: PurchaseOrderParams };
            props.history.push(`/PurchaseOrder`, { params: PurchaseOrderParams });
        }
        else {
            //purchaseOrderID
            let Param = { Operation: 27, Id: PurchaseOrderParams.purchaseOrderID, };
            CommonService.CheckMappedItem(Param).then(res => {
                if (res.data.outputResult === "-2") {
                    let message = "This Purchase Order is already mapped.";
                    Nodify('warning', 'warning', message);
                }
                else {
                    setID({ showPopupDelete: true, Params: PurchaseOrderParams });
                }
            });
        }
    };

    const ListType = () => {
        setListTile("Tile");
    }

    const TileListType = () => {
        setListTile("List");
    }

    if (!currentUser) {
        return <Redirect to="/login" />;
    }

    const Delcallback = (value) => {
        // if (value === true) {
        //     PurchaseOrderService.GetPurchaseOrderList(0).then((response) => {
        //         if (response.data) {
        //             setComments(response.data);
        //         }
        //     });
        // }
        setID({ showPopupDelete: false, Params: [] });
    }
    const FilterClear = (filterName) => {
       
        if (filterName === 'Buyer') {
            localStorage.removeItem('Search');
            localStorage.removeItem('Season');
            localStorage.removeItem('Brand');
            localStorage.removeItem('IdPo');


            setSeasonSearch('');
            setBrandSearch('');
            setQuarterSearch('');

            setIdPoSearch('');

            // var defaultStartDate = new Date();
            // const emptyStateDate = [
            //     {
            //         startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
            //         endDate: new Date(),
            //         key: 'selection',
            //         isShow: false
            //     }];
            // setStateDate(emptyStateDate);
        }

    }
    const handleChangeFilter = (values, filterName) => {
       

        if (filterName === 'Buyer') {
            setBuyerSearch(values)
            let Data = { Searchvalue: values, Page: 'Buyer' }
            localStorage.setItem('Buyer', JSON.stringify(Data));
            FilterClear(filterName);
        }
        else if (filterName === 'Season') {
            setSeasonSearch(values)
            let Data = { Searchvalue: values, Page: 'Season' }
            localStorage.setItem('Season', JSON.stringify(Data));
        }
        else if (filterName === "Brand") {
            setBrandSearch(values)
            let Data = { Searchvalue: values, Page: 'Brand' }
            localStorage.setItem('Brand', JSON.stringify(Data));
        }
        else if (filterName === "IdPo") {
            setIdPoSearch(values)
            let Data = { Searchvalue: values, Page: 'IdPo' }
            localStorage.setItem('IdPo', JSON.stringify(Data));
        }
        else if (filterName === "Ref") {
            setrefnoSearch(values)
            let Data = { Searchvalue: values, Page: 'Ref' }
            localStorage.setItem('Ref', JSON.stringify(Data));
        }
        else if (filterName === "Stylename") {
            setStylenameSearch(values)
            let Data = { Searchvalue: values, Page: 'Stylename' }
            localStorage.setItem('Stylename', JSON.stringify(Data));
        }
        else if (filterName === "Supplier") {
            setSupplierSearch(values)
            let Data = { Searchvalue: values, Page: 'Supplier' }
            localStorage.setItem('Supplier', JSON.stringify(Data));
        }
        else if (filterName === "Receiveddate") {
            setReceiveddateSearch(values)
            let Data = { Searchvalue: values, Page: 'Receiveddate' }
            localStorage.setItem('Receiveddate', JSON.stringify(Data));
        }
        else if (filterName === "Quarter") {
            setQuarterSearch(values)
            let Data = { Searchvalue: values, Page: 'Quarter' }
            localStorage.setItem('Quarter', JSON.stringify(Data));
        }
        else if (filterName === "Endcustomer") {
            setEndCustomerSearch(values)
            let Data = { Searchvalue: values, Page: 'Endcustomer' }
            localStorage.setItem('Endcustomer', JSON.stringify(Data));
        }
        else if (filterName === "Destination") {
            setDestinationSearch(values)
            let Data = { Searchvalue: values, Page: 'Destination' }
            localStorage.setItem('Destination', JSON.stringify(Data));
        }
        
    }
    let BuyerListOptions = [] = [];


    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className="row">
                    <div className="col-xs-12 col-md-12">
                        <div className="widget">
                            <div className="widget-header ">
                                <span className="widget-caption">Purchase Order Information</span>
                                {/* {
                                    ListTile === "List" && <span title='Tile'
                                        onClick={ListType}>
                                        <i className="menu-icon fa fa-th font_size"></i>
                                    </span>
                                }
                                {
                                    ListTile === "Tile" && <span title='List'
                                        onClick={TileListType}>
                                        <i className="menu-icon fa fa-list font_size"></i>
                                    </span>
                                } */}
                            </div>
                            <div>
                                {
                                    isOpen && (
                                        <Lightbox mainSrc={isOpen} alt="bg image" onCloseRequest={() => setIsOpen(false)} />
                                    )
                                }
                            </div>
                             <div className="widget-body">
                                <div className="dataTables_wrapper form-inline no-footer">
                                    {/* <div className="table-toolbar pull-left">
                                        <Search onSearch={value => { setSearch(value); setCurrentPage(1); }} placeholder={getPlaceholder} props={props} />
                                    </div> */}
                                    
                                    <div id="simpledatatable_wrapper" className="dataTables_wrapper form-inline no-footer">
                                        {/* <div className="table-toolbar pull-left"> */}
                                        {/* <Search onSearch={
                                            value => {
                                                setSearch(value);
                                                setCurrentPage(1);
                                            }
                                        }
                                            placeholder={getPlaceholder} /> &nbsp;&nbsp; */}
                                        {
                                            ShowFilter === true ?
                                                <div className="row">
                                                    <div className="col-lg-12 col-sm-12 col-xs-12">
                                                        <div className="widget">
                                                            <div className="widget-body no-padding">
                                                                <div className="widget-main ">
                                                                    <div className="panel-group accordion" id="accordion">
                                                                        <div className="panel panel-default">
                                                                            <div className="panel-heading ">
                                                                                <h4 className="panel-title">
                                                                                    <a className="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseOne">
                                                                                        Search Filter : Date Range <span className="rptDateRangeSpan">(Clicking on the text box will cause the date picker to appear and disappear)</span>
                                                                                    </a>
                                                                                </h4>
                                                                            </div>
                                                                            <div id="collapseOne" className="panel-collapse collapse">
                                                                                <div className="panel-body border-red">
                                                                                    <div className="row">
                                                                                        <div className="col-md-12">

                                                                                            <div className="col-md-2">
                                                                                            <label>Buyer</label>
                                                                                                <span className="input-icon icon-right">

                                                                                                    <Reactselect className="basic-single" name="Buyer"
                                                                                                        options={getBuyerFilterlist}
                                                                                                        isClearable={true}
                                                                                                        onChange={e => handleChangeFilter(e, 'Buyer')}
                                                                                                        value={getBuyersearch}
                                                                                                        placeholder='Select Buyer'
                                                                                                    ></Reactselect>
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className="col-md-2">
                                                                                            <label>Season</label>
                                                                                                <span className="input-icon icon-right">

                                                                                                    <Reactselect className="basic-single" name="Season"
                                                                                                        options={getSeasonFilterlist}
                                                                                                        isClearable={true}
                                                                                                        onChange={e => handleChangeFilter(e, 'Season')}
                                                                                                        value={getSeasonsearch}
                                                                                                        placeholder='Select Season'
                                                                                                    ></Reactselect>
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className="col-md-2">
                                                                                            <label>Brand</label>
                                                                                                <span className="input-icon icon-right">
                                                                                                    <Reactselect className="basic-single" name="Brand"
                                                                                                        options={getBrandFilterlist}
                                                                                                        isClearable={true}
                                                                                                        onChange={e => handleChangeFilter(e, 'Brand')}
                                                                                                        value={getBrandsearch}
                                                                                                        placeholder='Select Brand'

                                                                                                    ></Reactselect>
                                                                                                </span>

                                                                                            </div>
                                                                                            <div className="col-md-2">
                                                                                            <label>Quarter</label>
                                                                                                <span className="input-icon icon-right">

                                                                                                    <Reactselect className="basic-single" name="Quarter"
                                                                                                        options={getQuarterFilterlist}
                                                                                                        isClearable={true}
                                                                                                        onChange={e => handleChangeFilter(e, 'Quarter')}
                                                                                                        value={getQuartersearch}
                                                                                                        placeholder='Select Quarter'
                                                                                                    ></Reactselect>
                                                                                                </span>

                                                                                            </div>
                                                                                            <div className="col-md-2">
                                                                                            <label>PO/ID No.</label>
                                                                                                <span className="input-icon icon-right">
                                                                                                    <Reactselect className="basic-single" name="IdPo"
                                                                                                        options={getidpoFilterlist}
                                                                                                        isClearable={true}
                                                                                                        onChange={e => handleChangeFilter(e, 'IdPo')}
                                                                                                        value={getIdPosearch}
                                                                                                        placeholder='Select PO/ID No'
                                                                                                    ></Reactselect>
                                                                                                </span>

                                                                                            </div>
                                                                                            <div className="col-md-2">
                                                                                            <label>Ref No.</label>
                                                                                                <span className="input-icon icon-right">

                                                                                                    <Reactselect className="basic-single" 
                                                                                                    name="Ref"
                                                                                                        options={getrefnoFilterlist}
                                                                                                        isClearable={true}
                                                                                                        onChange={e => handleChangeFilter(e, 'Ref')}
                                                                                                        value={getrefnosearch}
                                                                                                        placeholder='Select Ref No'
                                                                                                    ></Reactselect>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    &nbsp;
                                                                                    <div className="row">
                                                                                        <div className="col-md-12">
                                                                                            <div className="col-md-2 ">
                                                                                            <label>Style Name</label>
                                                                                                <span className="input-icon icon-right">

                                                                                                    <Reactselect className="basic-single" 
                                                                                                    name="Stylename"
                                                                                                        options={getStylenameFilterlist}
                                                                                                        isClearable={true}
                                                                                                        onChange={e => handleChangeFilter(e, 'Stylename')}
                                                                                                        value={getStylenamesearch}
                                                                                                        placeholder='Select Style Name'
                                                                                                    ></Reactselect>
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className="col-md-2 ">
                                                                                            <label>Style Code</label>
                                                                                                <span className="input-icon icon-right">

                                                                                                    <Reactselect className="basic-single"
                                                                                                    //name="Endcustomer"
                                                                                                        //options={getEndCustomerFilterlist}
                                                                                                        isClearable={true}
                                                                                                        //onChange={e => handleChangeFilter(e, 'Endcustomer')}
                                                                                                        //value={getEndCustomersearch}
                                                                                                        placeholder='Select Style Code'
                                                                                                    ></Reactselect>
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className="col-md-2 " >
                                                                                            <label>Supplier Name</label>
                                                                                                <span className="input-icon icon-right">

                                                                                                    <Reactselect className="basic-single"
                                                                                                    name="Supplier"
                                                                                                        options={getSupplierFilterlist}
                                                                                                        isClearable={true}
                                                                                                        onChange={e => handleChangeFilter(e, 'Supplier')}
                                                                                                        value={getSuppliersearch}
                                                                                                        placeholder='Select Supplier Name'
                                                                                                    ></Reactselect>
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className="col-md-2 " >
                                                                                            <label>End Customer</label>
                                                                                                <span className="input-icon icon-right">

                                                                                                    <Reactselect className="basic-single" 
                                                                                                    name="Endcustomer"
                                                                                                       options={getEndCustomerFilterlist}
                                                                                                        isClearable={true}
                                                                                                        onChange={e => handleChangeFilter(e, 'Endcustomer')}
                                                                                                        value={getEndCustomersearch}
                                                                                                        placeholder='Select End Customer'
                                                                                                    ></Reactselect>
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className="col-md-2 " >
                                                                                            <label>Destination</label>
                                                                                                <span className="input-icon icon-right">
                                                                                                <Reactselect className="basic-single" 
                                                                                                    name="Destination"
                                                                                                       options={getDestinationFilterlist}
                                                                                                        isClearable={true}
                                                                                                        onChange={e => handleChangeFilter(e, 'Destination')}
                                                                                                        value={getDestinationsearch}
                                                                                                        placeholder='Select Destination'
                                                                                                    ></Reactselect>
                                                                                                </span>
                                                                                            </div>
                                                                                            
                                                                                            <div className="col-md-2 ">
                                                                                            <label>TNA Create Status</label>
                                                                                                <span className="input-icon icon-right">
                                                                                                    <Reactselect className="basic-single" 
                                                                                                        //name="IdPo"
                                                                                                        //options={getrefnoFilterlist}
                                                                                                        isClearable={true}
                                                                                                        //onChange={e => handleChangeFilter(e, 'IdPo')}
                                                                                                        //value={getrefnosearch}
                                                                                                        placeholder='Select TNA Create Status'
                                                                                                    ></Reactselect>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    &nbsp;
                                                                                    <div className="row">
                                                                                        <div className="col-md-12">
                                                                                            <div className="col-md-2 ">
                                                                                            <label>Created Date</label>
                                                                                                <span className="input-icon icon-right">
                                                                                                    <Reactselect className="basic-single" 
                                                                                                    name="Receiveddate"
                                                                                                        options={getReceiveddateFilterlist}
                                                                                                        isClearable={true}
                                                                                                        onChange={e => handleChangeFilter(e, 'Receiveddate')}
                                                                                                        value={getReceiveddatesearch}
                                                                                                        placeholder='Select Received Date'
                                                                                                    ></Reactselect>
                                                                                                </span>
                                                                                            </div>

                                                                                            <div className="col-md-2">
                                                                                                <span className="col-md-1 input-icon icon-right margin-top-30">
                                                                                                    <button type="button" className="btn btn-danger" title="Reset" onClick={() => resetStyle()}
                                                                                                    >
                                                                                                    <i className="fa fa-close"> Reset</i>
                                                                                                    </button>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    &nbsp;
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        &nbsp;
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : ''
                                        }
                                        <div />
                                    </div>
                                    <div className="table-toolbar pull-right" style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}>
                                        &nbsp;
                                        <span className="btn btn-primary" onClick={() => pageRedirect('', 'Add', getPlaceholder)} title="Add Purchase Order">
                                            <i className="fa fa-plus"></i>&nbsp;Add
                                        </span>
                                    </div>
                                    {
                                        ListTile === "List" &&
                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                            <TableHeader headers={headers} onSorting={(field, order) => setSorting({ field, order })} />
                                            <tbody>
                                                {
                                                    commentsData && commentsData.map((comment,i) => (
                                                        <Fragment key={i}>
                                                            <tr>
                                                                <td style={{ width:'25px'}}>
                                                                    { i + 1}
                                                                </td>
                                                                <td>{moment(comment.createdDate).format('DD/MM/YYYY')}</td>
                                                                <td>{comment.buyerName}</td>
                                                                <td>{comment.brandName}</td>
                                                                <td>{comment.seasonName}</td>
                                                                <td>{comment.idpono}</td>
                                                                <td>{comment.focusPONo === "" ? "-" : comment.focusPONo}</td>
                                                                <td>{comment.noOfStyles}</td>
                                                                <td>{comment.totalQuantity}</td>
                                                                <td>{comment.currencySymbol} {comment.totalValue}  </td>
                                                                <td>{comment.trimSupplierName}</td>
                                                                <td>-</td>
                                                                <td style={{
                                                                    display: activeMenu && activeMenu[0].isEdit === 0 && activeMenu[0].isDelete === 0 && activeMenu[0].isView === 0 ? 'none' : ''
                                                                }}>
                                                                    <span style={{ display: activeMenu && activeMenu[0].isEdit === 1 ? '' : 'none' }} title='Edit Purchase Order' onClick={() => pageRedirect(comment, 'Edit', getPlaceholder)}
                                                                        className="btn btn-success btn-xs edit">
                                                                        <i className="fa fa-edit "></i>
                                                                    </span>
                                                                    &nbsp;
                                                                    <span style={{ display: activeMenu && activeMenu[0].isView === 1 ? '' : 'none' }} title='View Purchase Order' onClick={() => pageRedirect(comment, 'View', getPlaceholder)}
                                                                        className="btn btn-white btn-xs eye">
                                                                        <i className="fa fa-eye "></i>
                                                                    </span>
                                                                    &nbsp;
                                                                    <span style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }} title='Delete Purchase Order' onClick={() => pageRedirect(comment, '', getPlaceholder)}
                                                                        className="btn btn-danger btn-xs delete">
                                                                        <i className="fa fa-trash-o"></i>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    ))
                                                }

                                                {
                                                    totalItems === 0 ?
                                                        <tr>
                                                            <td colSpan="10" className='norecordfound'><span>No Records Found</span></td>
                                                        </tr> : ''
                                                }
                                            </tbody>
                                        </table>
                                    }
                                    {
                                        ListTile === "Tile" &&
                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">

                                            <tbody> {
                                                commentsData.map(comment => (
                                                    <div className="col-sm-4">
                                                        <div className="client_tumbline">


                                                            <img className="client_logo" src={comment.logoPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.logoPath} alt="Logo" onClick={
                                                                () => setIsOpen(comment.logoPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.logoPath)
                                                            } />

                                                            <div className="ai_button">
                                                                {comment.activeStatus === 1 && <img title="Active" alt='checked.png' src="assets/img/checked.png" />}
                                                                {comment.activeStatus === 0 && <img title="Inactive" alt='cancel.png' src="assets/img/cancel.png" />}
                                                            </div>

                                                            <table className="table_thum">
                                                                <tr className="table_rwo">
                                                                    <td className="bold_text width_10">Company&nbsp;:&nbsp;</td>
                                                                    <td className="bold_text width_20">
                                                                        {
                                                                            comment.name
                                                                        } </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Reference&nbsp;:&nbsp;</td>
                                                                    <td>
                                                                        {
                                                                            comment.referenceName
                                                                        }</td>
                                                                </tr>
                                                            </table>


                                                            <span title='Edit Purchase Order' onClick={() => pageRedirect(comment, 'Edit', getPlaceholder)}
                                                                className="btn btn-success btn-xs edit">
                                                                {/* <i className="fa fa-eye"></i> */}
                                                                <i className="fa fa-edit "></i>
                                                            </span>
                                                            &nbsp;
                                                            <span title='View Purchase Order' onClick={() => pageRedirect(comment, 'View', getPlaceholder)}
                                                                className="btn btn-white btn-xs eye">
                                                                {/* <i className="fa fa-eye"></i> */}
                                                                <i className="fa fa-eye "></i>
                                                            </span>
                                                            &nbsp;
                                                            <span title='Delete Purchase Order' onClick={() => pageRedirect(comment, '', getPlaceholder)}
                                                                className="btn btn-danger btn-xs delete">
                                                                <i className="fa fa-trash-o"></i>
                                                            </span>


                                                        </div>
                                                    </div>


                                                ))
                                            } </tbody>
                                        </table>


                                    }
                                    <div className='norecordfound'>
                                        {
                                            totalItems === 0 && ListTile === "Tile" && <span>No Records Found</span>
                                        }



                                    </div>
                                    <PageCount onPageCount={
                                        value => {
                                            setITEMS_PER_PAGE(value);
                                            setCurrentPage(1);
                                        }
                                    } />
                                    <Pagination total={totalItems}
                                        itemsPerPage={
                                            parseInt(ITEMS_PER_PAGE)
                                        }
                                        currentPage={currentPage}
                                        onPageChange={
                                            page => setCurrentPage(page)
                                        } />

                                </div>
                            </div>
                            {
                                getID.showPopupDelete ? <SweetAlertPopup
                                    data={
                                        getID.Params
                                    }
                                    deleteCallback={Delcallback}
                                    showpopup={true}
                                    pageActionId={PurchaseOrderID}
                                    Msg={"Purchase order deleted successfully."} /> : null
                            } </div>
                    </div>
                </div>
            </div>
            {loader} </>
    )
}

export default PurchaseOrderList;