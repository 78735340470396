
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, NavLink } from 'react-router-dom';
import $ from "jquery";
import CommonService from "../../services/Common/CommonService";
import { logout, activeMenus } from "../../actions/auth";
import { useState } from "react";
import { usePath } from "hookrouter";

const LeftMenu = () => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const activeMenu = useSelector((state) => state.auth.activeMenuList);
    const dispatch = useDispatch();
    const [ParentMenuList, setParentMenuList] = useState([]);
    const path = usePath();

    useEffect(() => {
        CommonService.GetMenuList().then((response) => {
            if (response.data) {
                const menuList = response.data;
                setParentMenuList(menuList);

                try {
                    let childMenu = menuList.item2.filter(d => d.subUrlPath !== null && d.subUrlPath !== "");
                    let activeInfo = childMenu.filter(d => d.subUrlPath.includes(path));
                    if (activeInfo.length === 0) {
                        let subChildMenu = menuList.item3.filter(d => d.subUrlPath !== null && d.subUrlPath !== "");
                        activeInfo = subChildMenu.filter(d => d.subUrlPath.includes(path));
                    }

                    if (activeInfo[0].menuType === 2) {
                        let menuInfo = menuList.item1.filter(d => d.menuID === activeInfo[0].mainMenuID);
                        const ActiveMenus =
                        {
                            ParentName: menuInfo[0].menuName,
                            ChildName: activeInfo[0].menuName,
                            SubChildName: '',
                            ParentDisplayName: menuInfo[0].menuDisplayName,
                            ChildDisplayName: activeInfo[0].menuDisplayName,
                            SubChildDisplayName: '',
                            ChildURLPath: activeInfo[0].urlPath,
                            SubChildURLPath: ''
                        }
                        dispatch(activeMenus(ActiveMenus));
                    } else if (activeInfo[0].menuType == 3) {
                        let childInfo = menuList.item2.filter(d => d.menuID === activeInfo[0].mainMenuID);
                        let menuInfo = menuList.item1.filter(d => d.menuID === childInfo[0].mainMenuID);
                        const ActiveMenus =
                        {
                            ParentName: menuInfo[0].menuName,
                            ChildName: childInfo[0].menuName,
                            SubChildName: activeInfo[0].menuName,
                            ParentDisplayName: menuInfo[0].menuDisplayName,
                            ChildDisplayName: childInfo[0].menuDisplayName,
                            SubChildDisplayName: activeInfo[0].menuDisplayName,
                            ChildURLPath: childInfo[0].urlPath,
                            SubChildURLPath: activeInfo[0].urlPath,
                        }
                        dispatch(activeMenus(ActiveMenus));
                    }
                } catch { }
            }
        });
    }, []);
    const logOut = () => {
        dispatch(logout());
    };

    const bodyClickHandler = (e) => {
        var b = $("#sidebar").hasClass("menu-compact");
        var menuLink = $(e.target).closest("a");
        if (!menuLink || menuLink.length === 0)
            return;
        if (!menuLink.hasClass("menu-dropdown")) {
            if (b && menuLink.get(0).parentNode.parentNode == this) {
                var menuText = menuLink.find(".menu-text").get(0);
                if (e.target !== menuText && !$.contains(menuText, e.target)) {
                    return false;
                }
            }
            return;
        }
        var submenu = menuLink.next().get(0);
        if (!$(submenu).is(":visible")) {
            var c = $(submenu.parentNode).closest("ul");
            if (b && c.hasClass("sidebar-menu"))
                return;
            c.find("> .open > .submenu").each(function () {
                if (this !== submenu && !$(this.parentNode).hasClass("active"))
                    $(this).slideUp(200).parent().removeClass("open");
            });
        }
        if (b && $(submenu.parentNode.parentNode).hasClass("sidebar-menu"))
            return false;
        $(submenu).slideToggle(200).parent().toggleClass("open");
        return false;
    };

    const MenuItemIcon = (props) => {
        return (
            <NavLink to={props.href}>
                <i className={props.iconattr}></i>
                <span className="menu-text">{props.text}</span>
            </NavLink>
        );
    };

    const MenuItem = (props) => {
        return (
            <NavLink to={props.href}>
                <span className="menu-text">{props.text}</span>
            </NavLink>
        );
    };

    const ParentMenuIcon = (props) => {
        return (
            <a className="menu-dropdown" onClick={props.onClick}>
                <i className={props.iconattr}></i>
                <span className="menu-text">{props.text}</span>
                <i className="menu-expand"></i>
            </a>
        );
    };

    const ParentMenu = (props) => {
        return (
            <a className="menu-dropdown" onClick={props.onClick}>
                <span className="menu-text">{props.text}</span>
                <i className="menu-expand"></i>
            </a>
        );
    }
    const ChildClick = (MenuInfo, ChildInfo, IsChildExist) => {
        if (!IsChildExist) {
            const ActiveMenus =
            {
                ParentName: MenuInfo.menuName,
                ChildName: ChildInfo.menuName,
                SubChildName: '',
                ParentDisplayName: MenuInfo.menuDisplayName,
                ChildDisplayName: ChildInfo.menuDisplayName,
                SubChildDisplayName: '',
                ChildURLPath: ChildInfo.urlPath,
                SubChildURLPath: ''
            }
            dispatch(activeMenus(ActiveMenus));
        }
    }
    const SubChildClick = (MenuInfo, ChildInfo, SubChildInfo) => {
        const ActiveMenus =
        {
            ParentName: MenuInfo.menuName,
            ChildName: ChildInfo.menuName,
            SubChildName: SubChildInfo.menuName,
            ParentDisplayName: MenuInfo.menuDisplayName,
            ChildDisplayName: ChildInfo.menuDisplayName,
            SubChildDisplayName: SubChildInfo.menuDisplayName,
            ChildURLPath: ChildInfo.urlPath,
            SubChildURLPath: SubChildInfo.urlPath
        }
        dispatch(activeMenus(ActiveMenus));
    }

    if (!currentUser) {
        return <Redirect to="/login" />;
    }
    return (
        <div className="page-sidebar" id="sidebar">
            <ul className="nav sidebar-menu"
                onClick={bodyClickHandler}>
                {
                    ParentMenuList.item1 && (ParentMenuList.item1).map((parentItem, parentIndex) => (
                        <>
                            {parentItem.menuName === 'Logout' ?
                                <li>
                                    <a href="/Login"
                                        onClick={logOut}>
                                        <i className={parentItem.menuIcon}></i>
                                        <span className="menu-text">{parentItem.menuDisplayName}</span>
                                    </a>
                                </li>
                                :
                                <li className={activeMenu.ParentName === parentItem.menuName ? 'active open' : ''} >
                                    <ParentMenuIcon text={parentItem.menuDisplayName} iconattr={parentItem.menuIcon} />
                                    {
                                        <ul className="submenu">
                                            {
                                                ParentMenuList.item2 && (ParentMenuList.item2).filter(x => x.mainMenuID === parentItem.menuID).map((childItem, childIndex) => (
                                                    <>
                                                        {(ParentMenuList.item3).filter(y => y.mainMenuID === childItem.menuID).length === 0 && (
                                                            <li className={activeMenu.ChildName === childItem.menuName ? 'active' : ''} onClick={() => ChildClick(parentItem, childItem, false)}>
                                                                <MenuItem href={childItem.urlPath} dbID={"ChildSpan_" + childItem.menuID} text={childItem.menuDisplayName} iconattr={childItem.menuIcon} />
                                                            </li>
                                                        )}
                                                        {(ParentMenuList.item3).filter(y => y.mainMenuID === childItem.menuID).length > 0 && (
                                                            <li className={activeMenu.ChildName === childItem.menuName ? 'active open' : ''}>
                                                                <ParentMenu text={childItem.menuDisplayName} onClick={() => ChildClick(parentItem, childItem, true)} />
                                                                <ul className="submenu">
                                                                    {
                                                                        ParentMenuList.item3 && (ParentMenuList.item3).filter(y => y.mainMenuID === childItem.menuID).map((subChildItem, subChildIndex) => (
                                                                            <li className={activeMenu.SubChildName === subChildItem.menuName ? 'active' : ''} onClick={() => SubChildClick(parentItem, childItem, subChildItem)}>
                                                                                <MenuItemIcon href={subChildItem.urlPath} dbID={subChildItem.menuID} text={subChildItem.menuDisplayName} iconattr={subChildItem.menuIcon} />
                                                                            </li>
                                                                        ))
                                                                    }
                                                                </ul>
                                                            </li>
                                                        )}
                                                    </>
                                                ))
                                            }
                                        </ul>
                                    }
                                </li>
                            }
                        </>
                    ))
                }
            </ul>
        </div>
    );
};
export default LeftMenu;