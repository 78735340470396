import React, { useState, useEffect } from "react";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import { Modal, Button } from "react-bootstrap";
import PantoneService from "../../services/Master/PantoneService";
import Nodify from "../Common/ReactNotification";
import { SketchPicker } from "react-color";
import reactCSS from "reactcss";
import { useSelector } from "react-redux";

const PantonePopup = ({
  getPantoneList,
  getPantoneTypeList,
  pantoneCallback,
  showpopup,
}) => {
  const { user: currentUser } = useSelector((state) => state.auth);

  const [colorColorPicker, setColorPicker] = useState(false);
  const [colorHexCode, setColorHexCode] = useState("");
  const EmptyInputFields = [
    {
      PantoneID: 0,
      PantoneType: 0,
      PantoneName: "",
      PantoneCode: "",
      PantoneColor: "",
      Operation: 1,
      Createdby: currentUser.employeeinformationID,
    },
  ];

  const [inputField, setInputField] = useState(EmptyInputFields);
  const [submitted, setSubmitted] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [getIndex, setIndex] = useState(0);

  const values = [...inputField];
  const styles = reactCSS({
    default: {
      swatch: {
        padding: "5px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  const handlePopupClose = () => {
    pantoneCallback(false);
  };

  const handleClickColor = (index) => {
    setIndex(index);
    setColorPicker(true);
  };

  const handleCloseColor = () => {
    setColorPicker(false);
  };

  const handleInputChange = (index, event) => {
    if (event.hex === undefined) {
      let inputText = "";
      if (event.target.value.trim() !== "") {
        inputText = event.target.value;
      }
      if (event.target.name === "PantoneType") {
        values[index].PantoneType = parseInt(inputText);
      } else if (event.target.name === "PantoneName") {
        values[index].PantoneName = inputText;
      } else if (event.target.name === "PantoneCode") {
        values[index].PantoneCode = inputText;
      } else if (event.target.name === "PantoneColor") {
        values[index].PantoneColor = colorHexCode;
        setColorPicker(true);
      }
      setInputField(values);
    } else {
      setColorHexCode(event.hex);
      values[index].PantoneColor = event.hex;
    }
  };

  const CheckOnblur = (index) => {

    if (values[index].PantoneName != "") {
      if (getPantoneList) {
        let checkPantone = getPantoneList.filter(
          (d) =>
            d.pantoneTypeId === values[index].PantoneType &&
            d.pantoneName.trim().toLowerCase() ===
            values[index].PantoneName.trim().toLowerCase()
        );
        if (checkPantone.length > 0) {
          values[index].PantoneName = "";
          setInputField(values);
          Nodify("Warning!", "warning", "Pantone name is already exist.");
          return false;
        }
      }
    }
  };

  const ValidationPopup = (Msg) => {
    Nodify("Warning!", "warning", Msg);
    setButtonLoader(false);
    return false;
  };

  const SavePantone = (e) => {
    setButtonLoader(true);
    e.preventDefault();
    if (
      values.length === 1 &&
      values[0].PantoneType === 0 &&
      values[0].PantoneName === "" &&
      values[0].PantoneCode === "" &&
      values[0].PantoneColor === ""
    ) {
      setButtonLoader(false);
      setSubmitted(true);
      ValidationPopup("Please fill the mandantory(*) fields.");
    } else {
      let Isvalid = false;

      if (
        values[0].PantoneType === 0 ||
        values[0].PantoneName === "" ||
        values[0].PantoneCode === "" ||
        values[0].PantoneColor === ""
      ) {
        Isvalid = true;
      }

      if (!Isvalid) {
        setSubmitted(false);
        let letPantoneType = {};
        letPantoneType.PantoneInformation = values;
        letPantoneType.Createdby = currentUser.employeeinformationID;
        PantoneService.InsertUpdatePantoneGrid(letPantoneType).then((res) => {
          if (res.data.outputResult === "1") {
            Nodify("Success!", "success", "Pantone added successfully.");
            pantoneCallback(true);
          } else if (res.data.outputResult === "0") {
            ValidationPopup("Error Occured!");
          }
        });
      } else {
        setButtonLoader(false);
        setSubmitted(true);
        ValidationPopup("Please fill this pantone details mandatory fields.");
      }
    }
  };

  return (
    <Modal
      show={showpopup}
      size="md"
      dragable
      backdrop="static"
      keyboard={false}
      onHide={() => handlePopupClose()}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Pantone</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="registration-form">
          <Form>
            <div className="row">
              <div className="form-group col-sm-12">
                <label htmlFor="PantoneName">
                  Pantone Type
                  <span className="text-danger">*</span>
                </label>
                <select
                  id={"PantoneType_0"}
                  name="PantoneType"
                  className="form-select"
                  value={inputField[0].PantoneType}
                  onChange={(event) => handleInputChange(0, event)}
                  style={{
                    border:
                      submitted && !inputField[0].PantoneType
                        ? "1px solid red"
                        : "",
                  }}
                >
                  <option value="0">- Select Type -</option>
                  {getPantoneTypeList.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}{" "}
                    </option>
                  ))}{" "}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-sm-12">
                <label htmlFor="PantoneName">
                  Pantone Name
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id={"PantoneName_0"}
                  name="PantoneName"
                  placeholder="Enter Pantone Name"
                  maxLength="50"
                  value={inputField[0].PantoneName}
                  onChange={(event) => handleInputChange(0, event)}
                  onBlur={(event) => CheckOnblur(0)}
                  style={{
                    border:
                      submitted && !inputField[0].PantoneName
                        ? "1px solid red"
                        : "",
                  }}
                  autoFocus
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-sm-12">
                <label htmlFor="PantoneName">
                  Pantone Code
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id={"PantoneCode_0"}
                  name="PantoneCode"
                  placeholder="Enter Pantone Code"
                  maxLength="50"
                  value={inputField[0].PantoneCode}
                  onChange={(event) => handleInputChange(0, event)}
                  onBlur={(event) => CheckOnblur(0)}
                  style={{
                    border:
                      submitted && !inputField[0].PantoneCode
                        ? "1px solid red"
                        : "",
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-sm-12">
                <label htmlFor="PantoneName">
                  Pantone Color
                  <span className="text-danger">*</span>
                </label>
                <div>
                  <div
                    id={"swatch_0"}
                    style={styles.swatch}
                    onClick={() => handleClickColor(0)}
                  >
                    <div
                      id={"Color_0"}
                      style={{
                        width: "120px",
                        height: "20px",
                        borderRadius: "2px",
                        background: inputField[0].PantoneColor,
                      }}
                    />
                  </div>
                  {colorColorPicker && getIndex === 0 ? (
                    <div id={"popover_0"} style={styles.popover}>
                      <div style={styles.cover} onClick={handleCloseColor} />
                      <SketchPicker
                        id={"Color1_0"}
                        color={inputField[0].PantoneColor}
                        onChange={(event) => handleInputChange(0, event)}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          className="btn btn-success"
          onClick={SavePantone}
          disabled={buttonLoader}
        >
          <i className="fa fa-check right"></i> &nbsp; Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default PantonePopup;
