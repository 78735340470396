import React, { useState, useEffect, useRef, Fragment } from "react";
import ReactNotification from 'react-notifications-component';
import Nodify from "../../Common/ReactNotification"
import validator from 'validator'
import SearchRetain from "../../Common/SearchRetain";
import { useSelector } from "react-redux";
import SelectCombobox from 'react-select';
import CityService from "../../../services/Master/CityService";
import StateService from "../../../services/Master/StateService"
const AddState = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);
    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    if (menulist !== undefined) {
        activeMenu = menulist.filter(i => i.menuName === "Currency");
    }
    //end enable menu
    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getState, SetState] = useState([{ StateID: 0, StateName: "", CountryID: 0 }]);
    const [ExistingList, setExistingList] = useState([]);
    const [ButtonName, setButtonName] = useState("Save");
    const [HeaderName, setHeaderName] = useState("Add");
    const [countryList, setcountryList] = useState([]);

    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),

    }
    function GroupListing(props) {
        let Currency = [...getState];
        Array.from(document.querySelectorAll("input")).forEach(input => (input.value = ""));
        if (props.location.state.params.length !== 0) {
            if (props.location.state.params.Id !== "") {
                setButtonName("Update");
                setHeaderName("Edit");
                Currency.StateID = props.location.state.params.StateID;
                Currency.StateName = validator.trim(props.location.state.params.StateName);
                Currency.CountryID = validator.trim(props.location.state.params.CountryID);
                Currency.Operation = 2;
                return Currency;
            }
        } else {
            return Currency;
        }
    }

    useEffect(() => {
        let getGroupList = GroupListing(props);
        SetState(getGroupList)

        CityService.loadCountryList().then((response) => {
            if (response.data) {
                setcountryList(response.data);
            }
        }).catch(() => { });
        StateService.GetStateList(0).then((response) => {
            if (response.data) {
                setExistingList(response.data);
            }
        }).catch(() => { });

    }, []);

    const handleAddFields = (index) => {
       
        const values = [...getState];
        if (values[index].StateName !== '' && values[index].CountryID !== 0) {
            values.push({ StateID: 0, StateName: "", CountryID: 0 });
            SetState(values);
            setSubmitted(false);
        }
        else {
            Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
            setSubmitted(true);
        }
    };

    const handleInputChange = (index, event, FieldName) => {
       
        const values = [...getState];
        if (FieldName === "StateName") {
            values[index].StateName = event.target.value.trim();
        } else if (FieldName === "CountryID") {
            if (event !== null) {
                values[index].CountryID = event.value;
            }
            else {
                values[index].CountryID = 0;

            }
        }
        SetState(values);
    };

    const handleRemoveFields = index => {
        const values = [...getState];
        values.splice(index, 1);
        SetState(values);
    };

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/StateList');
        } else {
            window.location.reload();
            // let getGroupList = GroupListing(props);
            // SetState(getGroupList)
        }
    }

    const CheckDuplicate = (index, Field) => {
       
        const values = [...getState];
        if (ExistingList) {
            // if (values[index].StateName !== '') { // for avoid empty validation
            let Existing = !!ExistingList.find(x => x.stateName.trim().toLowerCase() === values[index].StateName.trim().toLowerCase())
            if (Existing) {
                values[index].StateName = "";
                SetState(values);
                Nodify('Warning!', 'warning', "This State Name is already exist.");
                return false;
            }

            //}
        }

        for (var j = 0; j < (getState.length); j++) {
            if (j !== index) {
                if (getState[j].StateName.trim().toLowerCase() === getState[index].StateName.trim().toLowerCase()
                ) {
                    values[index].StateName = "";
                    SetState(values);
                    Nodify('Warning!', 'warning', 'This State Name is already exist.');
                    return false;
                }
            }
        }


    }

    const SaveState = (e) => {
       
        setButtonLoader(true);
        e.preventDefault();
        if (getState.length === 1 && getState[0].StateName === '' && getState[0].CountryID === 0) {
            setButtonLoader(false);
            setSubmitted(true);
            ValidationPopup("Please fill atleast one State detail.");
        }
        else {
            for (var i = 0; i < (getState.length); i++) {

                if (getState[i].StateName.trim() === '') {
                    Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
                    setSubmitted(true);
                    setButtonLoader(false);
                    return false;
                }
                if (getState[i].CountryID === 0) {
                    Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
                    setSubmitted(true);
                    setButtonLoader(false);
                    return false;
                }
            }

            let InputCommonMaster = { Createdby: currentUser.employeeinformationID, StateInformation: getState };
            StateService.InsertUpdateStateGrid(InputCommonMaster).then(res => {
                var page = "Remove";
                SearchRetain(page);

                let Func = 'Add';
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    PageRedirect(Func);
                }
                else if (res.data.outputResult === "0") {
                    setButtonLoader(false);
                    ValidationPopup("Error Occured!");
                }
            });
        }
    }

    const PageRedirect = (Func) => {
        props.history.push({ pathname: "/StateList", state: { message: Func } });
    }

    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
        return false;
    }

    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">{HeaderName} State/Country</h3>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <form>
                                    {
                                        getState.map((inputField, index) => (
                                            <Fragment key={`${inputField}~${index}`}>
                                                <div className="row row_left10">

                                                    <div className="col-sm-3 divder_style">
                                                        <div className="form-group">
                                                            <label htmlFor={
                                                                "StateName" + index
                                                            }>State Name<span className="text-danger">*</span>
                                                            </label>
                                                            <span className="input-icon icon-right">
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"StateName" + index}
                                                                    name="StateName"
                                                                    placeholder="Enter State Name"
                                                                    maxLength="50"
                                                                    value={inputField.StateName}
                                                                    onChange={event => handleInputChange(index, event, "StateName")}
                                                                    onBlur={() => CheckDuplicate(index, 'StateName')}
                                                                    style={{ border: submitted && !inputField.StateName ? '1px solid red' : '' }}
                                                                    autoFocus
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3 divder_style">
                                                        <div className="form-group">
                                                            <label htmlFor={
                                                                "CountryID" + index
                                                            }>Country<span className="text-danger">*</span>
                                                            </label>
                                                            <SelectCombobox className="basic-single" name="StateID" id={"StateID_" + index}

                                                                isDisabled={false}
                                                                isLoading={false}
                                                                isSearchable={true}
                                                                isClearable={true}
                                                                autocomplete='off'
                                                                placeholder="Select Country"
                                                                styles={submitted && inputField.CountryID === 0 ? styles : ''
                                                                }
                                                                onChange={event => handleInputChange(index, event, "CountryID")}
                                                                //onBlur={() => CheckDuplicate(index, 'StateID')}
                                                                value={countryList.filter(function (option) {
                                                                    return option.value === inputField.CountryID;
                                                                })}

                                                                options={countryList}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3 paddingstatic">
                                                        {
                                                            getState.length !== 1 && (
                                                                <div className="col-lg-1_own col-xs-12 adddeletemargin">
                                                                    <button type="button" className="btn btn-danger"
                                                                        style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}
                                                                        title="Delete Currency" onClick={() => handleRemoveFields(index)}>
                                                                        <i className="fa fa-trash-o"></i>
                                                                    </button>
                                                                </div>
                                                            )}
                                                        {
                                                            getState.length === (index + 1) && (
                                                                <div className="col-lg-1_own col-xs-12 adddeletemargin">
                                                                    <button type="button" className="btn btn-success" title="Add Currency" onClick={() => handleAddFields(index)}>
                                                                        <i className="fa fa-plus"></i>
                                                                    </button>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </Fragment>
                                        ))
                                    }
                                    <div className="col-sm-12">
                                        <div className="buttons-preview" align="right">
                                            <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                                <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                                            </span>
                                            <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                                <i className="fa fa-times"></i>&nbsp;Reset
                                            </span>
                                            <button type="button" className="btn btn-success" disabled={buttonLoader} onClick={SaveState}>
                                                <i className="fa fa-check right"></i>&nbsp;{ButtonName}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddState;