

import {
    COLORDEPTH_LOADING,
    RETRIEVE_COLORDEPTH,
    DELETE_COLORDEPTH,
} from "./types";

import ColorDepthService from "../services/Master/ColorDepthService";

const ColorDepthLoading = (isStatus) => ({
    type: COLORDEPTH_LOADING,
    payload: isStatus,
});

export const deleteColorDepth = (colorDepth) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_COLORDEPTH,
            payload: colorDepth,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrieveColorDepth = () => async (dispatch) => {
    try {
        dispatch(ColorDepthLoading(true));
        const res = await ColorDepthService.GetColorDepthList();
        dispatch({
            type: RETRIEVE_COLORDEPTH,
            payload: res.data,
        });
        dispatch(ColorDepthLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(ColorDepthLoading(false));
    }
};