import axios from "axios";
import authHeader from "../auth-header";
class RouteService {

    LoadTaskList(TaskType) {
        return axios.get(window.$APIBaseURL + "Route/LoadTaskList?GroupTypeID=" + TaskType, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    LoadRouteDependencyList() {
        return axios.get(window.$APIBaseURL + "Route/LoadRouteDependencyList", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadRouteTaskOwner(params) {
        return axios.get(window.$APIBaseURL + "Route/LoadRouteTaskOwnerList?TaskHolderID=" + params.TaskHolderID + "&BuyerID=" + params.BuyerID + "&UserLoginID=" + params.UserLoginID, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    InsertUpdateRouteDetails(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Route/InsertUpdateRouteDetails',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetRouteInformationList(RouteInformationID,IsProduction) {
        return axios.get(window.$APIBaseURL + "Route/GetRouteInformationList?RouteInformationID=" + RouteInformationID + "&IsProduction=" + IsProduction, {
            headers: {
                authorization: authHeader()
            }
        });
    }

     GetRouteInfoList(RouteInformationID) {
        return axios.get(window.$APIBaseURL + "Route/GetRouteInfoList?RouteInformationID=" + RouteInformationID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetRouteTaskInfoList(RouteInformationID) {
        return axios.get(window.$APIBaseURL + "Route/GetRouteInfoList?GetRouteTaskDetailsList=" + RouteInformationID, {
            headers: {
                authorization: authHeader()
            }
        });
    }
}

export default new RouteService();
