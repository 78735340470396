import React, { useState, useEffect, Fragment } from "react";
import 'react-notifications-component/dist/theme.css';
import Form from "react-validation/build/form";
import Nodify from "../../Common/ReactNotification"
import BuyerService from "../../../services/Master/BuyerService";
import { useSelector } from "react-redux";

const MapItem = ({
    mapItemCallback,
    MapItemList,
    BuyerInfoId,
}) => {
    const [inputFields, setInputFields] = useState([{
        BuyerInfoID: 0,
        BuyerMapID: 1,
        MapingTextName: '',
        MapingName: '',
        BuyerInfoStatus: 1,
        BuyerMapItemID: 0,
        IsActiveBrandMapped: 0,
        IsActiveEndCustomerMapped: 0,
        IsActiveStoryMapped: 0,
        IsActiveThemeMapped: 0
    }]);

    //Enable Menu 
    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    if (menulist !== undefined) {
        activeMenu = menulist.filter(i => i.menuName === "BuyerInfo");
    }
    //end enable menu
    const [submitted, setSubmitted] = useState(false);
    const [BuyerMapList, setBuyerMapList] = useState([]);

    useEffect(() => {
       ;
        BuyerService.LoadBuyerMapingList()
            .then((response) => {
                if (response.data) {
                    setBuyerMapList(response.data);
                } else {
                }
            })
            .catch(() => { });
        if (MapItemList.length === 0) {
            setInputFields([]);
            const values = [];
            values.push({
                BuyerInfoID: 0,
                BuyerMapID: 1,
                MapingTextName: '',
                MapingName: '',
                IsMandatory: 0,
                BuyerInfoStatus: 1,
                IsActiveBrandMapped: 0,
                IsActiveEndCustomerMapped: 0,
                IsActiveStoryMapped: 0,
                IsActiveThemeMapped: 0
            });
            setInputFields(values);
        }
        MapItemList.forEach(MapItem => {
           ;
            inputFields.push({
                BuyerInfoID: MapItem.buyerInfoID,
                BuyerMapID: MapItem.buyerMapID,
                MapingTextName: MapItem.mapingTextName,
                MapingName: MapItem.mapingName,
                BuyerInfoStatus: MapItem.buyerInfoStatus,
                BuyerMapItemID: MapItem.buyerMapItemID,
                IsActiveBrandMapped: MapItem.isActiveBrandMapped,
                IsActiveEndCustomerMapped: MapItem.isActiveEndCustomerMapped,
                IsActiveStoryMapped: MapItem.isStoryMandatory,
                IsActiveThemeMapped: MapItem.isThemeMandatory
            });
            setInputFields(inputFields);
        })
        if (BuyerInfoId !== 0) {
            if (MapItemList.length !== 0) {
                const values = [...inputFields];
                values.splice(0, 1);
                setInputFields(values);
            }
        }
    }, [MapItemList]);

    const handleInputChange = (index, event) => {
        if (BuyerInfoId === 0) {
            Nodify('Warning!', 'warning', 'Please select the buyer.');
        }
        else {
            const values = [...inputFields];
            let inputText = '';
            if (event.target.value.trim() !== '') {
                inputText = event.target.value;
            }
            if (event.target.name === "BuyerMapID") {
                values[index].BuyerMapID = parseInt(inputText);
                values[index].MapingTextName = "";
            }
            if (event.target.name === "MapingTextName") {
                values[index].MapingTextName = inputText;
            }
            if (event.target.name === "BuyerInfoStatus") {
                if (!event.target.checked) {
                    values[index].BuyerInfoStatus = 0;
                    if (values[index].MapingName === "Brand" || values[index].MapingName === "End Customer") {
                        if (values[index].IsActiveBrandMapped === 1) {
                            Nodify('Warning!', 'warning', "Brand Name is Mapped.");
                            values[index].BuyerInfoStatus = 1;
                        }
                        if (values[index].IsActiveEndCustomerMapped === 1) {
                            Nodify('Warning!', 'warning', "End Customer Name is Mapped.");
                            values[index].BuyerInfoStatus = 1;
                        }
                    }

                } else {
                    values[index].BuyerInfoStatus = 1;
                }
            }
            setInputFields(values);
            mapItemCallback(values);
        }
    }

    const CheckMapTextName = (index) => {
        const values = [...inputFields];
        var Respective_List = MapItemList.filter(x => x.buyerMapID === values[index].BuyerMapID);
        if (Respective_List.length !== 0) {
            for (var i = 0; i < Respective_List.length; i++) {
                if (Respective_List[i].buyerMapItemID !== values[index].BuyerMapItemID) {
                    if (Respective_List[i].mapingTextName.trim() !== '' && values[index].MapingTextName.trim() !== '') {
                        if (Respective_List[i].mapingTextName.trim().toLowerCase() === values[index].MapingTextName.trim().toLowerCase()) {
                            values[index].MapingTextName = '';
                            setInputFields(values);
                            Nodify('Warning!', 'warning', "This name already exist for the same item.");
                            return false;
                        }
                    }
                }
            }
        }

        for (var j = 0; j < values.length; j++) {
            if (index !== j) {
                if (values[index].BuyerMapID === values[j].BuyerMapID) {
                    if (values[index].MapingTextName.trim() !== '' && values[j].MapingTextName.trim() !== '') {
                        if (values[index].MapingTextName.trim().toLowerCase() === values[j].MapingTextName.trim().toLowerCase()) {
                            values[index].MapingTextName = '';
                            setInputFields(values);
                            Nodify('Warning!', 'warning', 'This name already exist for the same item.');
                            return false;
                        }
                    }
                }
            }
        }
    }


    const handleAddFields = (index) => {
        const values = [...inputFields];
        if (values[index].MapingTextName.trim() !== '') {
            setSubmitted(false);
            values.push({
                BuyerInfoID: 0,
                BuyerMapID: 1,
                MapingTextName: '',
                BuyerInfoStatus: 1,
                BuyerMapItemID: 0,
            });
            setInputFields(values);
            mapItemCallback(values);
            setSubmitted(false);
        } else {
            Nodify('Warning!', 'warning', 'Please fill this Map Item mandatory(*) fields.');
            setSubmitted(true);
        }
    };

    const handleRemoveFields = index => {
       ;
        const values = [...inputFields];

        if (values[index].MapingName === "Brand" || values[index].MapingName === "End Customer" 
        ||  values[index].MapingName === "Theme" || values[index].MapingName === "Story") {
            if (values[index].IsActiveBrandMapped === 1) {
                Nodify('Warning!', 'warning', "Brand Name is Mapped.");
                values[index].BuyerInfoStatus = 1;
            }
            else if (values[index].IsActiveEndCustomerMapped === 1) {
                Nodify('Warning!', 'warning', "End Customer Name is Mapped.");
                values[index].BuyerInfoStatus = 1;
            }
            else if (values[index].IsActiveStoryMapped === 1) {
                Nodify('Warning!', 'warning', "This Story is already mapped.");
                values[index].BuyerInfoStatus = 1;
            }
            else if (values[index].IsActiveThemeMapped === 1) {
                Nodify('Warning!', 'warning', "This Theme is already mapped.");
                values[index].BuyerInfoStatus = 1;
            }
            else {
                values.splice(index, 1);
                setInputFields(values);
                mapItemCallback(values);
            }
        }
        else {
            values.splice(index, 1);
            setInputFields(values);
            mapItemCallback(values);
        }
    };

    return (
        <> {
            inputFields.map((inputField, index) => (
                <Fragment key={
                    `${inputField}~${index}`
                }>
                    <div className="col-lg-10 col-sm-8 col-xs-12">
                        <div className="widget flat radius-bordered">

                            <div className="panel-group accordion" id="accordion">
                                <div className="panel panel-default">
                                    <div className="panel-heading ">
                                        <h4 className="mb-0 panel-title">
                                            <a className="btn btn-link font_wight" data-toggle="collapse" data-parent="accordion"
                                                href={
                                                    "#collapseAdd_" + index
                                                }>

                                                <span className="black">
                                                    {inputField.BuyerMapID === 1 ? "Brand   :  " : inputField.BuyerMapID === 2 ? "End Customer  :  " : inputField.BuyerMapID === 3 ? "Story  :  " : inputField.BuyerMapID === 4 ? "Theme  :  " : ""}
                                                </span>
                                                {
                                                    inputField.BuyerInfoID === 0 ? 'New Buyer Info' : inputField.MapingTextName
                                                }
                                            </a>
                                        </h4>

                                    </div>
                                    <div id={
                                        "collapseAdd_" + index
                                    }
                                        className
                                        ={inputField.BuyerInfoID === 0 ? 'panel-collapse collapse in' : 'panel-collapse collapse'}>
                                        <div className="widget-body">


                                            <div id="registration-form">
                                                <Form role="form">
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label htmlFor={
                                                                    "BuyerMapID_" + index
                                                                }>Select Item(s)<span className="text-danger">*</span>
                                                                </label>
                                                                <select id={
                                                                    "BuyerMapID_" + index
                                                                }
                                                                    name="BuyerMapID"
                                                                    className="form-select"
                                                                    value={
                                                                        inputField.BuyerMapID
                                                                    }
                                                                    onChange={
                                                                        event => handleInputChange(index, event)
                                                                    }
                                                                >
                                                                    {BuyerMapList.map((item) => (
                                                                        <option key={item.id} value={item.id}>
                                                                            {item.name}{" "}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-sm-4">
                                                            <label htmlFor={"MapTextName_" + index}>
                                                                {inputField.BuyerMapID === 1 ? "Brand" : inputField.BuyerMapID === 2 ? "End Customer" : inputField.BuyerMapID === 3 ? "Story" : inputField.BuyerMapID === 4 ? "Theme" : ""}
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <span className="input-icon icon-right">
                                                                <input
                                                                    placeholder={inputField.BuyerMapID === 1 ? "Brand" : inputField.BuyerMapID === 2 ? "End Customer" : inputField.BuyerMapID === 3 ? "Story" : inputField.BuyerMapID === 4 ? "Theme" : ""}
                                                                    id={"MapTextName_" + index}
                                                                    name="MapingTextName"
                                                                    value={inputField.MapingTextName}
                                                                    type="text"
                                                                    maxLength="50"
                                                                    autoComplete="off"
                                                                    onChange={
                                                                        event => handleInputChange(index, event)
                                                                    }
                                                                    className='form-control'
                                                                    style={
                                                                        {
                                                                            border: submitted && !inputField.MapingTextName ? '1px solid red' : ''
                                                                        }
                                                                    }
                                                                    onBlur={event => CheckMapTextName(index)}
                                                                />
                                                            </span>

                                                        </div>

                                                        <div className="form-group col-sm-2">
                                                            <label htmlFor={"BuyerInfoStatus_" + index}>Status</label>
                                                            <span className="input-icon icon-right">
                                                                <input
                                                                    type="checkbox"
                                                                    className="checkbox-slider checkbox-slider_Status colored-blue"
                                                                    id={"BuyerInfoStatus_" + index}
                                                                    name="BuyerInfoStatus"
                                                                    value={
                                                                        inputField.BuyerInfoStatus
                                                                    }
                                                                    checked={inputField.BuyerInfoStatus === 1 ? true : false}
                                                                    autoComplete="off"
                                                                    onChange={
                                                                        event => handleInputChange(index, event)
                                                                    }
                                                                ></input>
                                                                <span className="text"></span>
                                                            </span>
                                                        </div>

                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-1_own col-xs-12">
                        <span style={
                            {
                                display: (inputFields.length === 1 ? "none" : "")
                            }
                        }>
                            <button className="btn btn-danger" title="Delete Item"
                                style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}
                                onClick={
                                    () => handleRemoveFields(index)
                                }>
                                <i className="fa fa-trash-o"></i>
                            </button>
                        </span>
                    </div>
                    <div className="col-lg-1_own col-xs-12">
                        <span>
                            <span style={
                                {
                                    display: (inputFields.length === (index + 1) ? "" : "none")
                                }
                            }>
                                <button className="btn btn-success" title="Add Item"
                                    style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}
                                    onClick={
                                        () => handleAddFields(index)
                                    }>
                                    <i className="fa fa-plus"></i>
                                </button>
                            </span>

                        </span>
                    </div>
                </Fragment>
            ))
        } </>
    );
};

export default MapItem;
