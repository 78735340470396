import React, { useState, useEffect } from "react";
import ReactNotification from "react-notifications-component";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import BuyerService from "../../../services/Master/BuyerService";
import "react-notifications-component/dist/theme.css";
import Nodify from "../../Common/ReactNotification";
import SearchRetain from "../../Common/SearchRetain";
import { useSelector } from "react-redux";

const BuyerInfoRegister = (props) => {
  const { user: currentUser } = useSelector((state) => state.auth);

  const [getBuyerInfo, setBuyerInfo] = useState({});
  const [getBuyerList, setBuyerList] = useState([]);
  const [BuyerMapList, setBuyerMapList] = useState([]);
  const [ButtonName, setButtonName] = useState("Save");
  const [checked, setChecked] = useState({ Staus: true });
  const [getBuyerInfoItemList, setBuyerInfoItemList] = useState([]);

  const [submitted, setSubmitted] = useState({
    Buyer: false,
    IsMapingTextName: false,
  });

  const [getHeaderName, addHeaderName] = useState("Add");
  const [BuyerLabel, setBuyerLabel] = useState("Brand");
  const [buttonLoader, setButtonLoader] = useState(false);

  function setBuyerDetailList(props) {
    let BuyerInfoValue = {
      Operation: 1,
      BuyerInfoID: 0,
      BuyerID: 0,
      BuyerMapID: 1,
      MapingTextName: "",
      IsStoryMandatory: 0,
      IsThemeMandatory: 0,
      BuyerInfoStatus: 1,
      BuyerMapItemID: 0,
      Createdby: currentUser.employeeinformationID,
    };

    if (props.location.state !== undefined) {
      if (props.location.state.params.length !== 0) {
        if (props.location.state.params.buyerInfoID !== "") {

          if (props.location.state.params.buyerInfoID === 0) {
            BuyerInfoValue.Operation = 1;
          }
          else {
            BuyerInfoValue.Operation = 2;
            addHeaderName("Edit");
            setButtonName("Update");
          }

          BuyerInfoValue.BuyerInfoID = props.location.state.params.buyerInfoID;
          BuyerInfoValue.BuyerID = props.location.state.params.buyerID;
          BuyerInfoValue.BuyerMapID = props.location.state.params.buyerMapID;
          BuyerInfoValue.MapingTextName = props.location.state.params.mapingTextName;
          BuyerInfoValue.IsStoryMandatory = props.location.state.params.isStoryMandatory;
          BuyerInfoValue.IsThemeMandatory = props.location.state.params.isThemeMandatory;
          BuyerInfoValue.BuyerInfoStatus = props.location.state.params.buyerInfoStatus;
          BuyerInfoValue.BuyerMapItemID = props.location.state.params.buyerMapItemID;

          if (BuyerInfoValue.BuyerMapID === 1) {
            setBuyerLabel("Brand");
          }
          else if (BuyerInfoValue.BuyerMapID === 2) { setBuyerLabel("End Customer"); }
          else if (BuyerInfoValue.BuyerMapID === 3) { setBuyerLabel("Story"); }
          else if (BuyerInfoValue.BuyerMapID === 4) { setBuyerLabel("Theme"); }

          if (props.location.state.params.buyerInfoStatus === 0) {
            setChecked({ Staus: false });
          }
          else {
            setChecked({ Staus: true });
          }
          return BuyerInfoValue;
        }
      }
    }
    else {
      return BuyerInfoValue;
    }
  }

  //
  useEffect(() => {
    let Buyerinfo = setBuyerDetailList(props);
    setBuyerInfo(Buyerinfo);

    BuyerService.LoadBuyerList(2)
      .then((response) => {
        if (response.data) {
          setBuyerList(response.data);
        } else {
        }
      })
      .catch(() => { });

    BuyerService.LoadBuyerMapingList()
      .then((response) => {
        if (response.data) {
          setBuyerMapList(response.data);
        } else {
        }
      })
      .catch(() => { });

    BuyerService.GetBuyerInfoList(2, parseInt(props.location.state.params.buyerID), 0).then((response) => {
      if (response.data) {
        setBuyerInfoItemList(response.data);
      }
    });

  }, []);

  const handleChange = (e) => {
    const BuyerInfoValue = getBuyerInfo;

    if (e.target.type === "checkbox") {
      if (e.target.name === "BuyerInfoStatus") {
        if (!e.target.checked) {
          BuyerInfoValue[e.target.name] = 0;

          if (props.location.state.params.mapingName === "Brand" || props.location.state.params.mapingName === "End Customer") {
            if (props.location.state.params.isActiveBrandMapped === 1) {
              ValidationPopup("Brand Name is Mapped.");
              BuyerInfoValue[e.target.name] = 1;
            }
            else {
              ValidationPopup("End Customer Name is Mapped.");
              BuyerInfoValue[e.target.name] = 1;
            }
          }
        } else {
          BuyerInfoValue[e.target.name] = 1;
        }

        setChecked({ Staus: BuyerInfoValue[e.target.name] });
      }
    }
    else {
      if (e.target.name === "BuyerID") {
        if (submitted.IsMapingTextName === true) {
          setSubmitted({ BuyerID: false, IsMapingTextName: true });
        } else {
          setSubmitted({ BuyerID: false, IsMapingTextName: false });
        }

        BuyerInfoValue[e.target.name] = parseInt(e.target.value);
      }
      else if (e.target.name === "BuyerMapID") {
        let BuyerMapLabel = BuyerMapList;
        if (e.target.value !== "0") {
          BuyerMapLabel = BuyerMapList.filter(
            (comment) => comment.id === parseInt(e.target.value)
          );
        }
        setBuyerLabel(BuyerMapLabel[0].name);
        BuyerInfoValue[e.target.name] = parseInt(e.target.value);
        if (e.target.value === "1" || e.target.value === "2") {
        }
      }
      else if (e.target.name === "MapingTextName") {
        if (submitted.BuyerID === true) {
          setSubmitted({ BuyerID: true, IsMapingTextName: false });
        } else {
          setSubmitted({ BuyerID: false, IsMapingTextName: false });
        }
        BuyerInfoValue[e.target.name] = e.target.value;

      }
    }
    setBuyerInfo(BuyerInfoValue);
  };

  const PageRedirect = (Func) => {
    props.history.push({
      pathname: "/MapBuyer",
      state: {
        message: Func,
      },
    }
    );
  };

  const ResetOperation = (e) => {
    if (e === "Back") {
      props.history.push('/MapBuyer')
    } else {
      //  props.history.push("/MapBuyerInfoRegister")
      window.location.reload();
    }
  }

  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        //SaveBuyer(event);
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  const SaveBuyer = (e) => {

    setButtonLoader(true);
    setSubmitted({ BuyerID: true, IsMapingTextName: true });
    e.preventDefault();
    getBuyerInfo.MapingTextName = getBuyerInfo.MapingTextName && getBuyerInfo.MapingTextName.trim();
    if (getBuyerInfo.BuyerID === 0 && !getBuyerInfo.MapingTextName) {
      setButtonLoader(false);

      ValidationPopup("Please fill all mandatory (*) fields.");
    }
    else if (getBuyerInfo.BuyerID === 0) {
      setButtonLoader(false);

      ValidationPopup("Buyer is required.");
    }
    else if (!getBuyerInfo.MapingTextName) {
      setButtonLoader(false);

      ValidationPopup(BuyerLabel + " is required.");

    }
    if (getBuyerInfo.BuyerID !== 0 && getBuyerInfo.MapingTextName) {
      var Respective_List = getBuyerInfoItemList.filter(x => x.buyerMapID === getBuyerInfo.BuyerMapID);
      if (Respective_List.length !== 0) {
        for (var i = 0; i < Respective_List.length; i++) {
          if (Respective_List[i].buyerMapItemID === getBuyerInfo.BuyerMapItemID) { }
          else {
            if (Respective_List[i].mapingTextName.trim() !== '' && getBuyerInfo.MapingTextName) {
              if (Respective_List[i].mapingTextName.trim().toLowerCase() === getBuyerInfo.MapingTextName.trim().toLowerCase()) {
                getBuyerInfo.MapingTextName = '';
                setButtonLoader(false);

                Nodify('Warning!', 'warning', "This name already exist for the same item.");
                return false;
              }
            }
          }
        }
      }
      BuyerService.InsertUpdateBuyerInfo(getBuyerInfo).then((res) => {
        var page = "Remove";
        SearchRetain(page);
        let Func = "Add";
        if (res.data.outputResult === "1") {
          Func = "Add";
          PageRedirect(Func);
        } else if (res.data.outputResult === "2") {
          Func = "Edit";
          PageRedirect(Func);
        } else if (res.data.outputResult === "-2") {
          setButtonLoader(false);
          ValidationPopup(BuyerLabel + " name already exists!");
        }
        else if (res.data.outputResult === "0") {
          setButtonLoader(false);
          ValidationPopup("Error occured.");
        }
      });
    }
  };

  const ValidationPopup = (Msg) => {
    setButtonLoader(false);
    Nodify('Warning!', 'warning', Msg);
  };
  return (
    <div className="page-body">
      <ReactNotification />

      <div className="row">
        <div className="col-lg-12 col-sm-12 col-xs-12">
          <div className="widget flat radius-bordered">
            <div className="widget-header heading_box_style">
              <h3 className="heading_style_common">
                {getHeaderName} Buyer Info
              </h3>
              <div className="buttons-preview" align="right">
                <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                  <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                </span>
                <span className="btn btn-danger" onClick={() => ResetOperation()} >
                  <i className="fa fa-times"></i>&nbsp;Reset
                </span>
                <button
                  type="submit"
                  className="btn btn-success"
                  onClick={SaveBuyer}
                  disabled={buttonLoader}
                >
                  <i className="fa fa-check right"></i> &nbsp;{ButtonName}
                </button>
              </div>
            </div>
            <div className="widget-body">
              <div id="registration-form">
                <Form>
                  <div className="row">
                    <div className="form-group col-sm-4">
                      <label htmlFor="BuyerID">
                        Buyer Name <span className="text-danger">*</span>
                      </label>
                      <span className="input-icon icon-right">
                        <select
                          id="BuyerID"
                          name="BuyerID"
                          className="form-select"
                          value={getBuyerInfo.BuyerID}
                          onChange={handleChange}
                          disabled
                          style={{
                            border:
                              submitted.BuyerID && getBuyerInfo.BuyerID === 0
                                ? "1px solid red"
                                : "",
                          }}
                        >
                          <option value="0">- Select Buyer Name -</option>
                          {getBuyerList.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}{" "}
                            </option>
                          ))}

                        </select>
                      </span>

                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="BuyerMapID">Select item(s)</label>
                        <span className="text-danger">*</span>
                        <span className="input-icon icon-right">
                          <select
                            id="BuyerMapID"
                            name="BuyerMapID"
                            className="form-select"
                            value={getBuyerInfo.BuyerMapID}
                            onChange={(event) => handleChange(event)}
                            disabled
                          >
                            {BuyerMapList.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.name}{" "}
                              </option>
                            ))}{" "}
                          </select>
                        </span>
                      </div>

                    </div>
                    <div className="form-group col-sm-4">
                      <label htmlFor="MapingTextName">
                        {BuyerLabel}
                        <span className="text-danger">*</span>
                      </label>
                      <span className="input-icon icon-right">
                        <Input
                          placeholder={"Enter " + BuyerLabel}
                          id="MapingTextName"
                          name="MapingTextName" // className="form-control"
                          value={getBuyerInfo.MapingTextName}
                          type="text"
                          maxLength="50"
                          autoComplete="off"
                          onChange={handleChange}
                          className={'form-control' + (
                            submitted.IsMapingTextName && !getBuyerList.MapingTextName ? ' is-invalid' : ''
                          )}
                          style={{ border: submitted.IsMapingTextName && !getBuyerList.MapingTextName ? '1px solid red' : '' }}
                        />
                      </span>

                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-sm-4">
                      <label htmlFor="BuyerInfoStatus">Status</label>
                      <span className="input-icon icon-right">
                        <input
                          type="checkbox"
                          className="checkbox-slider checkbox-slider_Status colored-blue"
                          id="BuyerInfoStatus"
                          name="BuyerInfoStatus"
                          value={
                            getBuyerInfo.BuyerInfoStatus
                          }
                          checked={checked.Staus}
                          autoComplete="off"
                          onChange={handleChange}
                        ></input>
                        <span className="text"></span>
                      </span>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div
            className="buttons-preview"
            align="right"
            style={{ marginTop: "15px" }}
          >
            <div className="buttons-preview" align="right">
              <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
              </span>
              <span className="btn btn-danger" onClick={() => ResetOperation()} >
                <i className="fa fa-times"></i>&nbsp;Reset
              </span>
              <button
                type="submit"
                className="btn btn-success"
                onClick={SaveBuyer}
                disabled={buttonLoader}
              >

                <i className="fa fa-check right"></i> &nbsp;{ButtonName}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyerInfoRegister;
