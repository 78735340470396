import React, { useState, useEffect } from "react";
import { Collapse } from "reactstrap";

function CollapsiblePanel({ children, ...props }) {
    const { title, collapse } = props;
    const [isCollapse, setIsCollapse] = useState(collapse);
    const [icon, setIcon] = useState("fa fa-chevron-right");
    const toggle = () => {
        setIsCollapse(!isCollapse);
        setIcon(state => {
            return state === "fa fa-chevron-down"
                ? "fa fa-chevron-right"
                : "fa fa-chevron-down";
        });
    };

    const animate = collapse => {
        setIsCollapse(collapse);
        setIcon(state => {
            return state === "fa fa-chevron-down"
                ? "fa fa-chevron-right"
                : "fa fa-chevron-down";
        });
    };

    useEffect(() => {
        animate(!collapse);
    }, [collapse]);

    return (
        <div className="coll-panel">
            <div className="panel-group accordion" id="accordion" >
                <div className="panel panel-default">

                    <div className="panel-heading ">
                        <h4 className="mb-0 panel-title">
                            <button type="button" className="btn btn-link font_wight" data-toggle="collapse" data-parent="accordion"
                                onClick={() => toggle()}>
                                <i className={icon} /> {title}
                            </button>
                        </h4>

                    </div>

                    <Collapse className="border text-left p-2" isOpen={isCollapse}>
                        {children}
                    </Collapse>
                </div>
            </div>
        </div>
    );
}

CollapsiblePanel.defaultProps = {
    children: "Add node as a child",
    title: "Collapsible Panel",
    collapse: true
};

export default CollapsiblePanel;