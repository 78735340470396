import axios from "axios";
import authHeader from "../auth-header";


class CSRTemplateServices {

 getAllCSRTemplateDetails(Operation, Id) { //Buyer and Brand Details get
  return axios.get(window.$APIBaseURL + "Master/getAllCSRTemplateDetails?Operation=" + Operation + '&CsrTemplateInfoID=' + Id, {
   headers: {
    authorization: authHeader()
   }
  });
 }


 InsertCSRTemplateDetails(Params) {

  return axios({
   method: 'post',
   url: window.$APIBaseURL + 'Master/InsertCSRTemplateDetails',
   data: Params,
   headers: {
    authorization: authHeader()
   }
  });
 }

 UpdateDeleteCSRTemplateDetails(Params) {
  return axios({
   method: 'post',
   url: window.$APIBaseURL + 'Master/UpdateDeleteCSRTemplateDetails',
   data: Params,
   headers: {
    authorization: authHeader()
   }
  });
 }


}


export default new CSRTemplateServices()

