import {
    ALIGNMENT_LOADING,
    RETRIEVE_ALIGNMENT,
    DELETE_ALIGNMENT,
} from "../actions/types";

const initialState = {
    isLoadingAlignment: true,
    AlignmentList: [],
};

const AlignmentReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ALIGNMENT_LOADING:
            return {
                ...state,
                isLoadingAlignment: payload
            };
        case RETRIEVE_ALIGNMENT:
            return { ...state, AlignmentList: payload };
        case DELETE_ALIGNMENT:
            const results = state.AlignmentList.filter(c => c.id !== payload.QCCommentPoint);
            return {
                ...state,
                AlignmentList: results
            };

        default:
            return state;
    }
};
export default AlignmentReducer;