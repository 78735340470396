import {
    PACKAGETEMPLATE_LOADING,
    RETRIEVE_PACKAGETEMPLATE,
} from "./types";

import PackageTemplate from "../services/Master/packingtemservice";

const PackageTemplateLoading = (isStatus) => ({
    type: PACKAGETEMPLATE_LOADING,
    payload: isStatus,
});


export const retrievePackageTemplate = (PackageImageID, Operation) => async (dispatch) => {
    try {
        dispatch(PackageTemplateLoading(true));
        const res = await PackageTemplate.GetPackageTemplateList(PackageImageID, Operation);
        dispatch({
            type: RETRIEVE_PACKAGETEMPLATE,
            payload: res.data,
        });

        dispatch(PackageTemplateLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(PackageTemplateLoading(false));
    }
};


