import {
    ROLE_LOADING,
    RETRIEVE_ROLE,
    DELETE_ROLE,
    RETRIEVE_USERTYPE,
} from "./types";

import RoleService from "../services/Master/RoleService";

const roleLoading = (isStatus) => ({
    type: ROLE_LOADING,
    payload: isStatus,
});

export const deleteRole = (roleDetailsID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_ROLE,
            payload: roleDetailsID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrieveUserType = () => async (dispatch) => {
    try {
        dispatch(roleLoading(true));
        const res = await RoleService.GetUserType();
        dispatch({
            type: RETRIEVE_USERTYPE,
            payload: res.data,
        });
        dispatch(roleLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(roleLoading(false));
    }
};

export const retrieveRole = (RoleId) => async (dispatch) => {
    try {
        dispatch(roleLoading(true));
        const res = await RoleService.GetRoleList(RoleId);
        dispatch({
            type: RETRIEVE_ROLE,
            payload: res.data,
        });
        dispatch(roleLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(roleLoading(false));
    }
};