import React from 'react';
import { useState, useEffect, Fragment, useMemo } from "react";
import ReactNotification from 'react-notifications-component';
import { retrieveTask } from '../../../../actions/task';
import { useDispatch, useSelector } from "react-redux";
import Reactselect from 'react-select';
import { select } from 'react-validation/build/select';
import Nodify from '../../../Common/ReactNotification';
import TestService from '../../../../services/Master/TestService';
import TestFieldService from '../../../../services/Master/testfieldservice';
import FieldPopUp from '../../../quickMaster/_Field';
import { Modal, Button } from "react-bootstrap";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";

import SearchRetain from "../../../Common/SearchRetain";

const EditiMappingMaster = (props) => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  let styles = {
    control: styles => ({ ...styles, border: '1px solid red', innerHeight: '100px', outerHeight: '100px' }),
  }
  let styles1 = {
    control: styles => ({ ...styles, innerHeight: '100px', outerHeight: '100px' }),
  }
  const [inputFields, setInputFields] = useState([{
    TestNameID: 0,
    TestName: "",
    TestTaskNameID: 0,
    TestTaskName: '',
    TestFieldDetails: [{
      MappingID: 0,
      TestFieldID: 0,
      TestFieldIDName: '',
      TypeID: '',
      TypeIDEdit: '',
      TypeName: '',
      TestItemID: '',
      TestItemIDEdit: '',
      TestItemName: "",
    }]

  }]);
  const [submitted, setSubmitted] = useState(false);
  const [getTestFieldDuplicateFound, setTestFieldDuplicateFound] = useState(false);
  const [getTestName, setTestName] = useState([]);
  const [getTestField, setTestField] = useState([]);
  const [getTestitem, setTestitem] = useState([]);

  const [getFieldQuickmasterPopUp, setFieldQuickmasterPopUp] = useState({ isShow: false });
  const [getAddedFieldData, setAddedFieldData] = useState([]);
  const [getIsReloadField, setIsReloadField] = useState(false);

  const [gettaskname, settaskname] = useState([]);

  const [getTypeOption, setTypeOption] = useState([
    {
      value: 1,
      label: "Internal"
    },
    {
      value: 2,
      label: "External"
    }
  ]);


  const [selectedOptions, setSelectedOptions] = useState();
  const [getselecteditm, setselecteditm] = useState();



  const [getExisting, setExisting] = useState([]);
  const [buttonLoader, setButtonLoader] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const values = [...inputFields];
  const dispatch = useDispatch();
  //reducer
  const reducerState = useSelector((state) => state);


  // useEffect(() => {
  //   TestService.GetMappingMasterList(2, props.location.state.params.mappingID).then((res => {
  //     showLoader();
  //     let finaldata = []
  //     if (res.data) {
  //       res.data.item1.map((x, index) => {
  //         finaldata.push({
  //           TestNameID: x.testNameID,
  //           TestName: x.testName,
  //           TestTaskNameID: res.data.item3,
  //           TestTaskName: x.testTaskNameID,
  //           MappingID: x.mappingID,
  //           TestFieldDetails: []
  //         })

  //         res.data.item2.map((x, childindex) => {
  //           finaldata[index].TestFieldDetails.push({
  //             MappingChildID: x.mappingChildID,
  //             MappingID: x.mappingID,
  //             TestFieldID: x.testFieldID,
  //             TestFieldIDName: x.testFieldIDName,
  //             TypeID: x.typeID,
  //             TypeIDEdit: [],
  //             TypeName: x.typeName,
  //             TestItemID: x.testItemID,
  //             TestItemIDEdit: [],
  //             TestItemName: x.testItemName
  //           })

  //         })

  //         finaldata.map((f, findex) => {
  //           f.TestFieldDetails.map((tf, tfindex) => {
  //             let arr = tf.TypeID.split(',');
  //             let arrVal = [];
  //             for (let type of arr) {
  //               let res = getTypeOption.filter(x => x.value === parseInt(type));
  //               arrVal.push({
  //                 value: res[0].value,
  //                 label: res[0].label
  //               });
  //             }
  //             let arr1 = tf.TestItemID.split(',');
  //             let arrVal1 = [];
  //             for (let type of arr1) {
  //               let res = props.location.state.TestitemParam.filter(x => x.value === parseInt(type));
  //               if (res != null && res.length > 0) {
  //                 arrVal1.push({
  //                   value: res[0].value,
  //                   label: res[0].label
  //                 });
  //               }
  //             }
  //             finaldata[findex].TestFieldDetails[tfindex].TestItemIDEdit = arrVal1;
  //             finaldata[findex].TestFieldDetails[tfindex].TypeIDEdit = arrVal;
  //           })
  //         })
  //         setInputFields(finaldata);
  //         hideLoader();
  //       })
  //     }
  //   }), (error) => {
  //     hideLoader();
  //   })

  //   TestService.GetTestNameList(2, 0, 0).then((res => {
  //     let taskname = []
  //     if (res.data) {
  //       res.data.taskDetail.map((x, index) => {
  //         taskname.push({
  //           value: x.taskDetailsID,
  //           label: x.taskName,
  //           TestNameId: x.testNameID
  //         })
  //       })
  //     }
  //     settaskname(taskname);
  //   }), (error) => {
  //     hideLoader();
  //   })

  //   let TestItem = []
  //   props.location.state.TestitemParam.map((x, index) => {
  //     TestItem.push({
  //       value: x.value,
  //       label: x.label
  //     })
  //     setTestitem(TestItem)
  //   })

  //   TestService.GetTestNameList(1, 0, 0).then((res => {
  //     if (res.data) {
  //       res.data.testNameList.map((x, index) => {
  //         getTestName.push({
  //           value: x.testNameID,
  //           label: x.testName
  //         })
  //       })

  //       setTestName(getTestName);
  //     }
  //   }), (error) => {
  //     hideLoader();
  //   })

  //   TestFieldService.GetTestFieldList().then((res => {
  //     debugger
  //     let testFild = []
  //     if (res.data) {
  //       res.data.item1.map((x, index) => {
  //         testFild.push({
  //           value: x.testFieldID,
  //           label: x.testFieldIDName
  //         })
  //       })
  //       setTestField(testFild)
  //     }
  //   }), (error) => {
  //     hideLoader();
  //   })

  //   TestService.GetMappingMasterList(1, 0).then((res) => {
  //     let ExisitngData = []
  //     if (res.data) {
  //       res.data.item1.map((x, index) => {
  //         ExisitngData.push({
  //           TestNameID: x.testNameID,
  //           TestName: x.testName,
  //           TestTaskNameID: x.testTaskNameID,
  //           TestTaskName: x.testTaskName,
  //         })
  //       })
  //     }
  //     setExisting(ExisitngData);
  //     hideLoader();
  //   }, (error) => {
  //     hideLoader();
  //   })
  // }, [])
  useEffect(() => {
    const fetchData = async () => {
      try {
        showLoader();

        // Fetch MappingMasterList
        const mappingRes = await TestService.GetMappingMasterList(2, props.location.state.params.mappingID);
        if (mappingRes.data) {
          let finalData = mappingRes.data.item1.map((x) => ({
            TestNameID: x.testNameID,
            TestName: x.testName,
            TestTaskNameID: mappingRes.data.item3,
            TestTaskName: x.testTaskNameID,
            MappingID: x.mappingID,
            TestFieldDetails: [],
          }));

          finalData.forEach((item, index) => {
            mappingRes.data.item2.forEach((childItem) => {
              finalData[index].TestFieldDetails.push({
                MappingChildID: childItem.mappingChildID,
                MappingID: childItem.mappingID,
                TestFieldID: childItem.testFieldID,
                TestFieldIDName: childItem.testFieldIDName,
                TypeID: childItem.typeID,
                TypeIDEdit: [],
                TypeName: childItem.typeName,
                TestItemID: childItem.testItemID,
                TestItemIDEdit: [],
                TestItemName: childItem.testItemName,
              });
            });

            finalData.forEach((f, findex) => {
              f.TestFieldDetails.forEach((tf, tfindex) => {
                let typeIDArray = tf.TypeID.split(',');
                let typeIDEditArray = typeIDArray.map((type) => {
                  let res = getTypeOption.find((option) => option.value === parseInt(type));
                  return {
                    value: res?.value || '',
                    label: res?.label || '',
                  };
                });

                let testItemArray = tf.TestItemID.split(',');
                let testItemEditArray = testItemArray.map((itemID) => {
                  let res = props.location.state.TestitemParam.find((item) => item.value === parseInt(itemID));
                  return res
                    ? {
                      value: res.value,
                      label: res.label,
                    }
                    : null;
                }).filter(Boolean);

                finalData[findex].TestFieldDetails[tfindex].TestItemIDEdit = testItemEditArray;
                finalData[findex].TestFieldDetails[tfindex].TypeIDEdit = typeIDEditArray;
              });
            });
          });

          setInputFields(finalData);
        }

        // Fetch Test Name List 2 (Task Details)
        const testNameRes2 = await TestService.GetTestNameList(2, 0, 0);
        if (testNameRes2.data) {
          const taskNames = testNameRes2.data.taskDetail.map((x) => ({
            value: x.taskDetailsID,
            label: x.taskName,
            TestNameId: x.testNameID,
          }));
          settaskname(taskNames);
        }

        // Set TestItem
        const testItems = props.location.state.TestitemParam.map((x) => ({
          value: x.value,
          label: x.label,
        }));
        setTestitem(testItems);

        // Fetch Test Name List 1
        const testNameRes1 = await TestService.GetTestNameList(1, 0, 0);
        if (testNameRes1.data) {
          const testNames = testNameRes1.data.testNameList.map((x) => ({
            value: x.testNameID,
            label: x.testName,
          }));
          setTestName(testNames);
        }

        // Fetch TestFieldList
        const testFieldRes = await TestFieldService.GetTestFieldList();
        if (testFieldRes.data) {
          const testFields = testFieldRes.data.item2.map((x) => ({
            value: x.testFieldID,
            label: x.testField
          }));
          setTestField(testFields);
        }

        // Fetch Existing Data
        const existingDataRes = await TestService.GetMappingMasterList(1, 0);
        if (existingDataRes.data) {
          const existingData = existingDataRes.data.item1.map((x) => ({
            TestNameID: x.testNameID,
            TestName: x.testName,
            TestTaskNameID: x.testTaskNameID,
            TestTaskName: x.testTaskName,
          }));
          setExisting(existingData);
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        hideLoader();
      }
    };

    fetchData();
  }, []);


  const handleChangeTask = (event, FieldName, index) => {
    let value = [...inputFields]
    let inputText = '';
    if (event !== null && event.length !== 0) {
      if (FieldName === 'TestName') {
        if (getExisting.length > 0) {
          for (var j = 0; j < (getExisting.length); j++) {
            if (event.value === getExisting[j].TestNameID) {
              Nodify('Warning!', 'warning', 'This Test Name already exist.');
              return false;
            }
          }
          value[index].TestNameID = event.value;
          value[index].TestName = event.label;
          inputText = event.value;
          setInputFields(value);
        }
        else {
          value[index].TestNameID = event.value;
          value[index].TestName = event.label;
          inputText = event.value;
          setInputFields(value);
        }
      }
    }
    else {
      if (FieldName === 'TestName') {
        value[index].TestNameID = ''
        value[index].TestName = ''
        value[index].TestTaskNameID = ''
        value[index].TestTaskName = ''
      }

      else {
        value[index].TestTaskNameID = ''
        value[index].TestTaskName = ''
      }

    }
    setInputFields(value);

  }
  function handleSelect(event, FieldName, subindex, index) {
    let value = [...inputFields]
    if (FieldName === "TestType") {
      if (event != null) {
        let taskValue = "";
        let taskName = '';
        for (let task of event) {
          if (taskValue === "") {
            taskValue = task.value.toString();
            taskName = task.label.toString();
          } else {
            taskValue = taskValue + "," + task.value;
            taskName = taskName + ',' + task.label
          }
        }

        value[index].TestFieldDetails[subindex].TypeID = taskValue
        value[index].TestFieldDetails[subindex].TypeIDEdit = event
        value[index].TestFieldDetails[subindex].TypeName = taskName
      } else if (event.length == 0) {
        value[index].TestFieldDetails[subindex].TypeID = '';
        value[index].TestFieldDetails[subindex].TypeIDEdit = [];
        value[index].TestFieldDetails[subindex].TypeName = "";
      }
      setSelectedOptions(event);
    }
    else if (FieldName === 'TestItem') {
      if (event != null) {
        let taskValue = "";
        let taskName = '';
        for (let task of event) {
          if (taskValue === "") {
            taskValue = task.value.toString();
            taskName = task.label.toString();
          } else {
            taskValue = taskValue + "," + task.value;
            taskName = taskName + ',' + task.label
          }
        }

        value[index].TestFieldDetails[subindex].TestItemID = taskValue
        value[index].TestFieldDetails[subindex].TestItemIDEdit = event
        value[index].TestFieldDetails[subindex].TestItemName = taskName
      } else if (event.length == 0) {
        value[index].TestFieldDetails[subindex].TestItemID = "";
        value[index].TestFieldDetails[subindex].TestItemIDEdit = [];
        value[index].TestFieldDetails[subindex].TestItemName = "";
      }
      setselecteditm(event);
    }


    setInputFields(value);
  }
  const handleChild = (event, FieldName, subindex, index) => {
    let value = [...inputFields]
    if (FieldName === 'TestField') {
      if (event != null) {
        let FindDuplicate = value[index].TestFieldDetails.filter((data) => {
          if (event.value == data.TestFieldID) {
            return data;
          }
        })
        if (FindDuplicate.length > 0) {
          setTestFieldDuplicateFound(true);
          Nodify('Warning!', 'warning', 'This Task Field is already exist.');
          value[index].TestFieldDetails[subindex].TestFieldID = 0;
          value[index].TestFieldDetails[subindex].TestFieldIDName = "";
        }
        else {
          setTestFieldDuplicateFound(false);
          value[index].TestFieldDetails[subindex].TestFieldID = event.value;
          value[index].TestFieldDetails[subindex].TestFieldIDName = event.label;
        }

      }
      else if (event == null) {
        value[index].TestFieldDetails[subindex].TestFieldID = 0;
        value[index].TestFieldDetails[subindex].TestFieldIDName = [];
      }
    }
    setInputFields(value);
  }

  const AddChildGrid = (index, subindex) => {
    setSubmitted(true);
    const values = [...inputFields];
    if ((values[index].TestFieldDetails[subindex].TestFieldID === 0
      || (!values[index].TestFieldDetails[subindex].TypeIDEdit || values[index].TestFieldDetails[subindex].TypeIDEdit.length == 0)
      || (!values[index].TestFieldDetails[subindex].TestItemIDEdit || values[index].TestFieldDetails[subindex].TestItemIDEdit.length == 0))) {
      Nodify('Warning!', 'warning', 'Please fill all mandatory(*) fields.');
    }
    else {
      setSubmitted(false);
      values[index].TestFieldDetails.push({
        MappingID: 0,
        TestFieldID: 0,
        TestFieldIDName: '',
        TypeID: 0,
        TypeName: '',
        TestItemID: 0,
        TestItemName: "",
      })
      setInputFields(values);
    }
  }

  const RemoveChildGrid = (index, subindex) => {
    const values = [...inputFields];
    values[index].TestFieldDetails.splice(subindex, 1);
    setInputFields(values);
  }

  const AddMainGrid = (index, subindex) => {
    setSubmitted(true);
    const values = [...inputFields];
    if ((values[index].TestNameID === 0 || !values[index].TestTaskNameID)) {
      Nodify('Warning!', 'warning', 'Please fill all mandatory(*) fields.');
    }
    else {
      for (var i = 0; i < values[index].TestFieldDetails.length; i++) {
        if (values[index].TestFieldDetails[i].TestFieldID == 0 ||
          (!values[index].TestFieldDetails[i].TypeIDEdit || values[index].TestFieldDetails[i].TypeIDEdit.length == 0)
          || (!values[index].TestFieldDetails[i].TestItemIDEdit || values[index].TestFieldDetails[i].TestItemIDEdit.length == 0)) {
          Nodify('Warning!', 'warning', 'Please fill all mandatory(*) fields.');
          return false;
        }
      }
      setSubmitted(false);
      values.push({
        TestNameID: 0,
        TestName: "",
        TestTaskNameID: 0,
        TestTaskName: '',
        MapMainID: index + 1,
        TestFieldDetails: [{
          MapChildID: index,
          MappingID: 0,
          TestFieldID: 0,
          TestFieldIDName: '',
          TypeID: '',
          TypeIDEdit: '',
          TypeName: '',
          TestItemID: '',
          TestItemIDEdit: '',
          TestItemName: ""
        }]
      })
    }
    setInputFields(values);
  }

  const RemoveMainGrid = (index, subindex) => {
    const values = [...inputFields];
    values.splice(index, 1)
    setInputFields(values);
  }


  const SaveMappingMaster = (e) => {
    setButtonLoader(true);
    e.preventDefault();
    let MappingMasterInfo = []
    let MappingheaderInfo = []
    let MappingChildInfo = []

    inputFields.map((Info, index) => {
      MappingheaderInfo.push({
        MappingID: Info.MappingID,
        TestNameID: Info.TestNameID,
        TestName: Info.TestName,
        TestTaskNameID: Info.TestTaskNameID,
        TestTaskName: Info.TestTaskName,
        MapMainID: index,
      })

      Info.TestFieldDetails.map((x) => {
        MappingChildInfo.push({
          MappingChildID: x.MappingChildID,
          TestFieldID: x.TestFieldID,
          TestFieldIDName: x.TestFieldIDName,
          TypeID: x.TypeID,
          MapChildID: index,
          TypeName: x.TypeName,
          TestItemID: x.TestItemID,
          TestItemName: x.TestItemName,
        });
      });
    })


    if (inputFields.length === 1 && inputFields[0].TestName === '') {
      setButtonLoader(false);
      setSubmitted(true);
      ValidationPopup("Please fill atleast one Test Name.");
    }

    else {
      for (var i = 0; i < (inputFields.length); i++) {
        if (inputFields[i].TestName.trim() === '' || inputFields[i].TestTaskName.trim() === '') {
          Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
          setSubmitted(true);
          setButtonLoader(false);
          return false;
        }
        else {
          let TestFieldDetails = inputFields[i].TestFieldDetails;
          for (var j = 0; j < (TestFieldDetails.length); j++) {
            if (!TestFieldDetails[j].TestFieldID || TestFieldDetails[j].TypeID == ""
              || TestFieldDetails[j].TestItemID == "") {
              Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
              setSubmitted(true);
              setButtonLoader(false);
              return false;
            }
          }
        }
      }
      let Params = { Operation: 2,CommonId:props.location.state.params.commonID, Createdby: currentUser.employeeinformationID, MapHeader: MappingheaderInfo, MapChild: MappingChildInfo };
      TestService.InsertMappingMaster(Params).then((res => {
        var page = "Remove";
        SearchRetain(page);
        let Func = 'Add';
        if (res.data.outputResult === "1") {
          Func = 'Edit';
          PageRedirect(Func);
        }
        else if (res.data.outputResult === "2") {
          Func = 'Edit';
          PageRedirect(Func);
        }
        else if (res.data.outputResult === "0") {
          setButtonLoader(false);
          ValidationPopup("Error Occured!");
        }
      }))
    }
  }

  const ResetOperation = (e) => {
    if (e === "Back") {
      props.history.push('/MappingList')
    } else {
      window.location.reload();
    }
  }

  const PageRedirect = (Func) => {
    props.history.push({ pathname: "/MappingList", state: { message: Func } });
  }
  const ValidationPopup = (Msg) => {
    setButtonLoader(false);
    Nodify('Warning!', 'warning', Msg);
    return false;
  }


  const ViewFieldCreatePopUp = () => {
    setFieldQuickmasterPopUp({ isShow: true });
  }

  const HideFieldCreatePopUp = () => {
    setFieldQuickmasterPopUp({ isShow: false });
  }

  const AddFieldCallback = (value, data) => {
    const Values = [...inputFields];
    if (value) {
      setAddedFieldData(data);
      setFieldQuickmasterPopUp(false);
      Nodify("Success!", "success", "Test Field added successfully.");
    }
    setIsReloadField(value);
  }

  useEffect(() => {
    TestFieldService.GetTestFieldList().then((res => {
      let testFild = []
      if (res.data) {
        res.data.item1.map((x, index) => {
          testFild.push({
            value: x.testFieldID,
            label: x.testField
          })
        })
        setTestField(testFild)
      }
    }))
  }, [getAddedFieldData])

  return (
    <>
      <div className="page-body">
        <ReactNotification />
        <div className="row">
          <div className="col-lg-12 col-sm-12 col-xs-12">
            <div className="widget flat radius-bordered">
              <div className="widget-header heading_box_style">
                <h3 className="heading_style_common">Edit Mapping</h3>
              </div>
              <div className="widget-body">
                <div id="registration-form">
                  <div className="row">
                    <div className="col-lg-12">

                    </div>
                  </div>
                  {
                    inputFields.map((inputField, index) => (
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="row" style={{ marginTop: "2rem" }}>
                            <div className="col-lg-3">
                              <label htmlFor="">Test Name</label><span className='text-danger'>*</span>
                              <Reactselect className="basic-single"
                                name={"ddlTestTask"}
                                id={"ddlTestTask"}
                                isDisabled={false}
                                isLoading={false}
                                isClearable={true}
                                isSearchable={true}
                                menuPosition="fixed"
                                onChange={event => handleChangeTask(event, 'TestName', index)}
                                options={getTestName}
                                styles={submitted && inputField.TestName === "" ? styles : styles1}
                                value={getTestName.filter(function (option) {
                                  return option.value === inputField.TestNameID;
                                })}
                              >
                              </Reactselect>
                            </div>
                            <div className="col-lg-3">
                              <label htmlFor="">Test Task Name</label><span className='text-danger'>*</span>
                              <Reactselect className="basic-single"
                                name={"ddlTestTask"}
                                id={"ddlTestTask"}
                                isDisabled={false}
                                isLoading={false}
                                isMulti
                                isClearable={true}
                                isSearchable={true}
                                styles={submitted && inputField.TestTaskNameID === "" ? styles : styles1}
                                menuPosition="fixed"
                                options={gettaskname.filter(x => x.TestNameId === inputField.TestNameID)}
                                value={inputField.TestTaskNameID}
                              > </Reactselect>
                            </div>
                            <div className="col-lg-2">
                              <div style={{ marginTop: '10%' }}>
                                {inputFields.length > 0 && <button type="button"
                                  className="btn btn-xs btn-success" title="Add Test Name" onClick={event => AddMainGrid(index)}>
                                  <i className="fa fa-plus"></i>
                                </button>}{inputFields.length > 1 &&
                                  <button type="button" style={{ marginLeft: '5px' }}
                                    className="btn btn-xs btn-danger" title="Remove Test Name" onClick={event => { RemoveMainGrid(index) }}>
                                    <i className="fa fa-trash"></i>
                                  </button>}
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <br />
                        <div className="col-lg-12" style={{ marginTop: "3rem" }}>
                          <div className="row">
                            <div className="col-lg-12">
                              <form>
                                <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                  <thead>
                                    <tr>
                                      <th style={{ width: '25%' }}><b><i className='fa fa-plus text-success' onClick={event => ViewFieldCreatePopUp()}
                                      ></i></b>&nbsp;Test Field Name<span className='text-danger'>*</span></th>

                                      <th style={{ width: '25%' }}>Type<span className='text-danger'>*</span></th>
                                      <th style={{ width: '25%' }}>Test Items<span className='text-danger'>*</span></th>
                                      <th style={{ width: '25%' }}>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      inputField.TestFieldDetails.map((child, subindex) => (
                                        <>
                                          <tr>
                                            <td>
                                              <Reactselect className="basic-single"
                                                name={"ddlTestTask"}
                                                id={"ddlTestTask"}
                                                isDisabled={false}
                                                isLoading={false}
                                                isClearable={true}
                                                isSearchable={true}
                                                placeholder="Select Test Field Name"
                                                menuPosition="fixed"
                                                styles={(getTestFieldDuplicateFound || submitted) && child.TestFieldIDName == "" ? styles : styles1}
                                                onChange={event => handleChild(event, 'TestField', subindex, index)}
                                                options={getTestField}
                                                value={getTestField.filter(function (option) {
                                                  return option.value === child.TestFieldID;
                                                })}
                                              >
                                              </Reactselect>
                                            </td>
                                            <td>
                                              <Reactselect
                                                options={getTypeOption}
                                                placeholder="Select Type"
                                                value={child.TypeIDEdit}
                                                styles={submitted && (child.TypeIDEdit == "" || !child.TypeIDEdit) ? styles : styles1}
                                                onChange={event => handleSelect(event, 'TestType', subindex, index)}
                                                isMulti
                                              />
                                            </td>
                                            <td>
                                              <Reactselect
                                                options={getTestitem}
                                                placeholder="Select Test Items"
                                                value={child.TestItemIDEdit}
                                                onChange={event => handleSelect(event, 'TestItem', subindex, index)}
                                                isMulti
                                                styles={submitted && (child.TestItemIDEdit == "" || !child.TestItemIDEdit) ? styles : styles1}
                                              />
                                            </td>
                                            <td>
                                              <div>
                                                {inputFields[index].TestFieldDetails.length === (subindex + 1) && <button type="button"
                                                  className="btn btn-xs btn-success" title="Add Test Name" onClick={event => AddChildGrid(index, subindex)}>
                                                  <i className="fa fa-plus"></i>
                                                </button>}{inputFields[index].TestFieldDetails.length !== 1 &&
                                                  <button type="button" style={{ marginLeft: '5px' }}
                                                    className="btn btn-xs btn-danger" title="Remove Test Name" onClick={event => { RemoveChildGrid(index, subindex) }}>
                                                    <i className="fa fa-trash"></i>
                                                  </button>}
                                              </div>
                                            </td>
                                          </tr>
                                        </>
                                      ))
                                    }
                                  </tbody>
                                </table>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                  <br />
                  <br />
                  <div className="col-sm-12" style={{ marginTop: '10px' }}>
                    <div className="buttons-preview" align="right">
                      <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                        <i className="fa fa-arrow-circle-left"></i>
                        &nbsp;Back
                      </span>
                      <span className="btn btn-danger" onClick={() => ResetOperation()}>
                        <i className="fa fa-times"></i>
                        &nbsp;Reset
                      </span>
                      <button type="button" className="btn btn-success" onClick={SaveMappingMaster}>
                        <i className="fa fa-check right"></i>&nbsp;
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {
              getFieldQuickmasterPopUp && getFieldQuickmasterPopUp.isShow &&
              <Modal show={getFieldQuickmasterPopUp.isShow} size="md" dragable backdrop="static" keyboard={false}
              >
                <Modal.Header closeButton onHide={() => HideFieldCreatePopUp()}>
                  <Modal.Title>
                    Add Test Field
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-sm-12 col-lg-12">
                      <FieldPopUp props={props} FieldPopUpCallback={AddFieldCallback}
                        FieldOptions={getTestField}></FieldPopUp>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            }
          </div>
        </div>
      </div>
      {loader}
    </>
  )
}

export default EditiMappingMaster