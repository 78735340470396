import axios from "axios";
import authHeader from "./auth-header";

const InsertUpdateCourierDetails = (CourierDetail) => {
    return axios({
        method: 'post',
        url: window.$APIBaseURL + 'Courier/InsertUpdateCourierInfo',
        data: CourierDetail,
        headers: {
            authorization: authHeader()
        }
    });
}

const DeleteCourierDetails = (Operation, Id) => {
    return axios({
        method: 'post',
        url: window.$APIBaseURL + 'Master/DeleteMasterInformation?Operation=' + Operation + '&Id=' + Id,
        headers: {
            authorization: authHeader()
        }
    });
}


const LoadCourierDetails = () => {
    return axios({
        method: 'get',
        url: window.$APIBaseURL + 'Courier/CourierList',
        headers: {
            authorization: authHeader()
        }
    });
}

const LoadActualCourierList = (ID) => {
    return axios({
        method: 'get',
        url: 'https://api-eu.dhl.com/track/shipments?trackingNumber=' + ID,
        headers: {
            'DHL-API-Key': '4UAg0DvAhScrBERbEaWYMrk3fo3AK4UH'
        }
    });
}

const courierService = {
    InsertUpdateCourierDetails,
    LoadCourierDetails,
    DeleteCourierDetails,
    LoadActualCourierList
};

export default courierService;