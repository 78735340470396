import axios from "axios";
import authHeader from "../auth-header";

class ReportTemplateService {



  getReportTemplateList() {
   ;
    return axios.get(window.$APIBaseURL + "Master/GetReportTemplateList?ReportTemplateID=0", {
      headers: {
        authorization: authHeader()
      }
    });
  }

  getReportModulecategorydropdownList() {
   ;
    return axios.get(window.$APIBaseURL + "Master/GetReportModuleCategorydrpdownList", {
      headers: {
        authorization: authHeader()
      }
    });
  }

  loadReportModuleCategoryList() {
   
    return axios.get(window.$APIBaseURL + "Master/LoadReportModuleCategoryList?ReportModuleCategoryID=0", {
      headers: {
        authorization: authHeader()
      }
    });
  }

  InsertUpdateReportTemplateGrid(Params) {
   
    return axios({
      method: 'post',
      url: window.$APIBaseURL + 'Master/InsertUpdateReportTemplateGrid',
      data: Params,
      headers: {
        authorization: authHeader()
      }
    });
  }

  AddReportTemplate(Params) {
   
    return axios({
      method: 'post',
      url: window.$APIBaseURL + 'Master/InsertUpdateDelReportTemplate',
      data: Params,
      headers: {
        authorization: authHeader()
      }
    });
  }

}

export default new ReportTemplateService()