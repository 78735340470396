import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
//import CommonService from '../../services/Common/CommonService';
//import {store} from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
//import { Redirect, useLocation, NavLink } from 'react-router-dom';

const ConfirmationPopup = ({
    props,
    data,
    stylelistcopyCallback,
    showpopup,
    mainlistcallback,
    IsProductioncallback
    //pageActionId,
    //Msg
}) => {
    const [show, setShow] = useState(true);
    const [value, setValue] = useState(1);
    var IsConfirmbtnShow = true;
    const reducerState = useSelector((state) => state);
    const { user: currentUser } = reducerState.auth;
    const comments = reducerState.style.styleList.item1 !== undefined ? reducerState.style.styleList.item1 : reducerState.style.styleList;
    let StyleListCreatedByCurrentUser = comments.filter(d => d.createdBy === currentUser.employeeinformationID);
    useEffect(() => {
        setShow(true);
        IsProductioncallback(value)
    }, [data]);

    const options = [
        { label: 'Sampling', value: 1 },
        { label: 'Production', value: 2 },
    ];
    const handleClose = () => {
        setShow(false);
        if (data.tabPage === "Copystyle") {
            stylelistcopyCallback(false);
        } else {
            mainlistcallback(false)
        }
    };
    let title = "";
    if (data.tabPage === "Copystyle") {
        title = "Do you want to copy " + data.StyleName + " - " + data.StyleNo + "";
    } else {
        title = data.Parentask === "TNAEdit" ? "Do you want to Redirect to TNA Edit" : "Do you want to Redirect to Final Inspection"
    }
    let confirmBtnText = 'Yes,do it!'
    let cancelBtnText = 'Cancel'
    const copystyle = () => {
        if (data.tabPage === "Copystyle") {
            if (data.tabPage === "Copystyle") {
                stylelistcopyCallback(true, data.StyleID)
            } else {
                props.history.push('/StyleList');
            }
        }
        if (data.tabPage === "MainInfo") {
            if (data.tabPage === "MainInfo") {
                if (data.Parentask === "TNAEdit") {
                    props.history.push('/TNAEdit', { params: props.location.state.params.details });
                } else {
                    props.history.push('/FinalInspection')
                }
                mainlistcallback(true)
            }
        }
    }
    const handleChange = (event) => {
        setValue(event.target.value);
        IsProductioncallback(parseInt(event.target.value))
    };
    return (
        <SweetAlert
            show={show}
            //warning
            info
            defaultValue=''
            showCancel
            showConfirm={IsConfirmbtnShow}
            confirmBtnText={confirmBtnText}
            cancelBtnText={cancelBtnText}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="primary"
            closeOnClickOutside={true}
            inputType={'new'}
            // closeOnConfirm
            // closeOnCancel
            title={title}
            onConfirm={copystyle}
            onCancel={handleClose}
            showCloseButton
            focusCancelBtn
        // style={{ backgroundColor: 'blue', color: 'white' }}
        >
            <select value={value} onChange={handleChange}>
                {options.map((option) => (
                    <option value={option.value}>{option.label}</option>
                ))}

            </select>
        </SweetAlert>
    )
};

export default React.memo(ConfirmationPopup);