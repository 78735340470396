import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import Lightbox from "react-image-lightbox"
import { Modal, Button } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { StyleTrimId } from "../../Common/PageActionNumber";
import { loadStyleTrimColorListByStyleID } from "../../../actions/style";
import SweetAlertPopup from '../../Common/SweetAlertPopup';
import CommonService from '../../../services/Common/CommonService';
import Nodify from "../../Common/ReactNotification"
const Trim = ({
    ParentCallBack,
    StyleInformation,
    StyleId,
    TrimInfo
}) => {

    // const [comments, setComments] = useState({ colorGroup: [], color: [] });
    const [getPopupValue, setPopupValue] = useState([{ Notes: '', TargettoClose: '', CompletedOn: '', RemindMe: '' }]);
    const [getShow, setShow] = useState(false);
    const [isOpen, setIsOpen] = useState(false)
    const [ShowLoader, setShowLoader] = useState(false);
    //const [totalItems, setTotalItems] = useState(0);
    const [getID, setID] = useState(0);

    const dispatch = useDispatch();
    //reducer
    const reducerState = useSelector((state) => state);
    const comments = reducerState.style.trimList;
    const [isOpenimg, setIsOpenimg] = useState(false);
    const [getImagename, setImagename] = useState();

    useEffect(() => {
        if (StyleId !== 0) {
            dispatch(loadStyleTrimColorListByStyleID(StyleId));
        }
    }, [StyleId !== 0]);

    // useEffect(() => {
    //     if (StyleId !== 0) {
    //         // setTimeout(() => {
    //         setShowLoader(true);
    //         TrimService.GetStyleTrimColorTabList(StyleId).then((response) => {
    //             if (response.data) {
    //                 setComments(response.data);
    //                 setShowLoader(false);
    //             }
    //             setTotalItems(response.data.length);
    //         }).catch(() => { });
    //         // }, 100);
    //     }
    // }, [StyleId !== 0]);

    const GetMasterDetails = (value) => {
        ParentCallBack(value);
    }

    const handleClose = () => {
        setShow(false);
    };

    const ShowCritical = (notes, targettoclose, completedon, remindme) => {
        setShow(true);
        getPopupValue[0].Notes = notes;
        getPopupValue[0].TargettoClose = targettoclose;
        getPopupValue[0].CompletedOn = completedon;
        getPopupValue[0].RemindMe = remindme;
        setPopupValue(getPopupValue);
    }

    const RedirecttoMaster = () => {
        ParentCallBack("AddMaster");
    }

    const pageRedirect = (dataParams) => {
        let Params = {
            Operation: 26,
            Id: dataParams.styleTrimLogoInfoID,
        };
        CommonService.CheckMappedItem(Params).then(res => {
            if (res.data.outputResult === "-2") {
                let Title = "Warning!";
                let message = "This Trim is already mapped.";
                let type = "warning";

                Nodify(Title, type, message);
            }
            else {
                setID({ showPopupDelete: true, Params: dataParams });
            }
        });
    };

    const Delcallback = (value) => {
        // if (value === true) {
        //     TrimService.GetStyleTrimColorTabList(StyleId).then((response) => {
        //         if (response.data) {
        //             setComments(response.data);
        //         }
        //         setTotalItems(response.data.length);

        //     }).catch(() => { });
        // }

        setID({ showPopupDelete: false, Params: [] });
        dispatch(loadStyleTrimColorListByStyleID(StyleId));
    }

    const ViewFile = (event, filename) => { // event.preventDefault();
        setIsOpenimg(true);
        setImagename(filename);

    }


    return (
        <>

            <div className="row">
                <div className="col-lg-12">
                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                        <div className="widget-header SkuList">
                            <span className="widget-caption">Trim List</span>
                        </div>
                    </div>
                    {TrimInfo && TrimInfo.isAdd === 1 &&
                        <div className="pull-right">
                            <a onClick={RedirecttoMaster} type="button" className="btn btn-success">
                                <i className="fa fa-plus"></i>
                                &nbsp;  Add Master
                            </a>
                            &nbsp;
                            <button onClick={() => GetMasterDetails(comments.colorGroup.length !== 0 ? "Edit" : "Add")} type="button" className="btn btn-primary ">
                                <i className="fa fa-check-square"></i>
                                &nbsp;  Pick from Master
                            </button>
                        </div>
                    }
                </div>
            </div>
            <div> {
                isOpen && (
                    <Lightbox
                        mainSrc={isOpen}
                        alt="bg image"

                        onCloseRequest={
                            () => setIsOpen(false)
                        } />
                )
            } </div>
            <div className="row">
                {comments.colorGroup.filter(x => x.styleTrimSkuNames !== null && x.styleTrimSkuNames !== '').length === 0 && (
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <div className="widget flat radius-bordered">
                            <div className="panel-group accordion" id="accordion">
                                <div className="panel panel-default">
                                    <div className="panel-heading ">
                                        <h4 className="mb-0 panel-title">
                                            <a className="btn btn-link font_wight" data-toggle="collapse" data-parent="accordion" >
                                                {
                                                    "Trim Info"
                                                }
                                            </a>
                                        </h4>
                                    </div>
                                    <div className={'panel-collapse collapse in'}>
                                        <div className="widget-body">
                                            <div id="registration-form">
                                                <div className="col-sm-12">
                                                    <div className="row">
                                                        {ShowLoader === false && (
                                                            <center>
                                                                <h4 className="text-danger">No Trim information mapped yet.</h4>
                                                            </center>
                                                        )}
                                                        <Loader show={ShowLoader} type="ThreeDots" color="#00BFFF" height={80} width={80} timeout={500} style={{ textAlign: "center" }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {comments.colorGroup.filter(x => x.styleTrimSkuNames !== null && x.styleTrimSkuNames !== '').length !== 0 && (
                    <div className="col-md-12">
                        <div className="widget-body">
                            <div className="dataTables_wrapper form-inline no-footer">
                                <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th style={{ width: "9%" }}>Trim Image</th>
                                            <th>SKU's</th>
                                            <th>Trim Name</th>
                                            <th>Trim Code</th>
                                            <th>Purchase Type</th>
                                            <th>Trim Supplier</th>
                                            <th>Approval Type</th>
                                            <th style={{ display: TrimInfo && TrimInfo.isDelete === 1 ? '' : 'none' }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            comments.colorGroup &&
                                            comments.colorGroup.filter(x => x.styleTrimSkuNames !== null && x.styleTrimSkuNames !== '').map((comment, index) => (
                                                <>
                                                    <tr key={
                                                        comment.trimInfoID
                                                    }>
                                                        <td>{index + 1}</td>
                                                        <td className="trwidth_manual" style={{ textAlign: "center" }}>

                                                            {
                                                                < img className="width_manual"
                                                                    src={comment.trimLogoName === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images/Style/Trim/' + comment.trimLogoName}
                                                                    alt="Logo"

                                                                    onClick={
                                                                        () => setIsOpen(comment.trimLogoName === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images/Style/Trim/' + comment.trimLogoName)
                                                                    }
                                                                />
                                                            }</td>
                                                        <td>{comment.styleTrimSkuNames}</td>
                                                        <td>

                                                            <span>
                                                                {comment.trimName}
                                                            </span>
                                                        </td>
                                                        <td>{comment.trimCode}</td>
                                                        <td>{comment.purchaseTypeName}</td>
                                                        <td>{comment.trimSupplierName === '' ? "-" : comment.purchaseType === 1 ? comment.trimSupplierName : "-"}</td>
                                                        <td>{comment.approvalTypeName === '' ? "-" : comment.approvalTypeName === null ? "-" : comment.approvalTypeName}</td>
                                                        <td style={{ display: TrimInfo && TrimInfo.isDelete === 1 ? '' : 'none' }}>
                                                            <span title='Delete Trim' onClick={() => pageRedirect(comment)}
                                                                className="btn btn-danger btn-xs delete">
                                                                <i className="fa fa-trash-o"></i>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    {
                                                        comments.color &&
                                                        comments.color.filter(x => x.trimInfoID === comment.trimInfoID && x.indexName === comment.indexName).length !== 0 && (
                                                            <>
                                                                {
                                                                    comments.color.filter(x => x.trimInfoID === comment.trimInfoID && x.indexName === comment.indexName).map((critical, indexValue) => (
                                                                        critical.notes !== '' && (

                                                                            <tr>
                                                                                <td colSpan="8">
                                                                                    <span style={{ color: 'red' }}>{"Critical : " + (critical.strPantoneName + ' ' + critical.pantoneTypeName + ' - ' + critical.notes)} ,</span>
                                                                                    <span style={{ color: '#427fed' }}> Target to Close : {critical.targettoClose === null ? '-' : critical.targettoClose} ,</span>
                                                                                    <span style={{ color: '#00a65a' }}> Completed On : {critical.completedOn === null ? '-' : critical.completedOn}</span>
                                                                                    <br />
                                                                                </td>
                                                                            </tr>

                                                                        )
                                                                    ))
                                                                }
                                                            </>

                                                        )
                                                    }
                                                    {
                                                        comments.manual.filter(x => x.trimInfoId === comment.trimInfoID).map((man, j) => {
                                                            return (
                                                                man.ManualCloseComment !== '' && comment.approvalTypeName === 'Manual' ?
                                                                    <tr>
                                                                        <td colSpan={10}>
                                                                            <div className="row-1">
                                                                                <span><b>Manual Comment :</b>  {man.manualCloseComment}</span>
                                                                            </div>
                                                                            {/* <span style={{ color: '#427fed' }}>Date : {new Date(man.manualCloseDate).toLocaleDateString()}</span> */}
                                                                            {
                                                                                comments.manualFiles.length !== 0 ?
                                                                                    comments.manualFiles.filter(x => x.trimInfoId === man.trimInfoId).map((files, fileindex) => (
                                                                                        <div className="col-lg-4 col-sm-4 file_list_upload">
                                                                                            <tr title={files.fileName}>
                                                                                                File :
                                                                                                <td>
                                                                                                    <span style={{ marginRight: '7px' }}>
                                                                                                        {
                                                                                                            files.fileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                                files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                                                    <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                                    files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                                        files.fileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                                        <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                                        }
                                                                                                    </span>  {files.fileName}
                                                                                                </td>

                                                                                                <td>

                                                                                                    {
                                                                                                        files.fileType === "application/pdf" || files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files.fileType === 'application/msword' ?
                                                                                                            <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + 'Images/TNAImages/' + files.fileName} download><i class="fa fa-download"></i> </a> :

                                                                                                            <i className="btn btn-info fa fa-eye" title="View Logo"
                                                                                                                style={{ display: TrimInfo && TrimInfo.isView === 1 ? '' : 'none' }}
                                                                                                                onClick={
                                                                                                                    event => ViewFile(event, files.fileName)
                                                                                                                }></i>
                                                                                                    }
                                                                                                </td>
                                                                                            </tr>
                                                                                            {

                                                                                                <div> {
                                                                                                    isOpenimg && (

                                                                                                        <Lightbox mainSrc={window.$APIBaseURL + 'Images/TNAImages/' + getImagename}
                                                                                                            alt="bg image"
                                                                                                            onCloseRequest={
                                                                                                                () => setIsOpenimg(false)
                                                                                                            } />
                                                                                                    )
                                                                                                } </div>
                                                                                            }
                                                                                        </div>
                                                                                    ))
                                                                                    : ''


                                                                            }
                                                                        </td>

                                                                    </tr>
                                                                    : ''



                                                            );


                                                        })
                                                    }

                                                    {
                                                        comments.commonApproval.map((man, j) => {
                                                            return (
                                                                comment.approvalTypeName === 'Existing' ?
                                                                    <tr>
                                                                        <td colSpan="10">
                                                                            <span><b>Mapped Strikeoff Detail </b> &nbsp;</span>
                                                                            <br />
                                                                            <span><b>Season Name : </b> {man.seasonName} &nbsp;</span>
                                                                            <span><b>Approval Date : </b>  {new Date(man.approvalDate).toLocaleDateString()}&nbsp;</span>
                                                                            <span><b>Details : </b> {man.details}&nbsp;</span>
                                                                            <span><b>Approved By : </b> {man.approvedBy}&nbsp;</span>

                                                                        </td>
                                                                    </tr>
                                                                    : ''



                                                            );


                                                        })
                                                    }
                                                </>
                                            ))
                                        }
                                        {
                                            comments.length === 0 ?
                                                <tr>
                                                    <td colSpan="7" className='norecordfound'><span>No Records Found</span></td>
                                                </tr> : ''
                                        }
                                    </tbody>
                                </table>


                            </div>
                            {
                                getID.showPopupDelete ? <SweetAlertPopup data={
                                    getID.Params
                                }
                                    deleteCallback={Delcallback}
                                    showpopup={true}
                                    pageActionId={StyleTrimId}
                                    Msg={"Trim deleted successfully."} /> : null
                            }
                        </div>
                    </div>
                )}
            </div>
            {getShow &&
                <Modal show={getShow} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleClose()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Critical</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                            <thead>
                                <tr>
                                    <th colSpan="4" style={{ textAlign: "center" }}>Critical
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan="4">
                                        <textarea
                                            placeholder="Enter comments here..."
                                            maxLength="2000"
                                            value={
                                                getPopupValue[0].Notes
                                            }
                                            disabled
                                            rows="4" style={{ width: "100%" }}></textarea>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Target to Close</td>
                                    <td>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            maxLength="50"
                                            className={'form-control'}
                                            value={getPopupValue[0].TargettoClose}
                                            disabled
                                        />
                                    </td>
                                    <td>Remind Me</td>
                                    <td>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            maxLength="50"
                                            className={'form-control'}
                                            value={getPopupValue[0].RemindMe === 1 ? "Current Date Reaches Target Date" : getPopupValue[0].RemindMe === 2 ? "One Time in a Day, until complete" : ""}
                                            disabled
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Completed On</td>
                                    <td>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            maxLength="50"
                                            className={'form-control'}
                                            value={getPopupValue[0].CompletedOn}
                                            disabled
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => handleClose()}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            }

        </>
    )
};
export default Trim; 