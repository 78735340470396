import axios from "axios";
import authHeader from "../auth-header";
class TrimService {
      LoadApprovalTypeList() {
        return axios.get(window.$APIBaseURL + "Style/LoadApprovalTypeList", {
            headers: {
                authorization: authHeader()
            }
        });
    }
    GetTrimList(TrimID) {
        return axios.get(window.$APIBaseURL + "Style/GetTrimList?TrimID=" + TrimID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateSamplingTrimInformation(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Style/InsertUpdateSamplingTrimInformation',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetTrimInformationList(TrimInfoID) {
        return axios.get(window.$APIBaseURL + "Style/GetTrimInformationList?TrimInfoID=" + TrimInfoID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetTrimPrintColorList(TrimInfoID) {
        return axios.get(window.$APIBaseURL + "Style/GetTrimPrintColorList?TrimInfoID=" + TrimInfoID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetTrimLogoInformationList(TrimInfoID) {
        return axios.get(window.$APIBaseURL + "Style/GetTrimLogoInformationList?TrimInfoID=" + TrimInfoID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetTrimReferenceList(TrimInfoID) {
        return axios.get(window.$APIBaseURL + "Style/GetTrimReferenceList?TrimInfoID=" + TrimInfoID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    TrimTabList(TrimInfoID) {
        return axios.get(window.$APIBaseURL + "Style/TrimTabList?TrimInfoID=" + TrimInfoID, {
            headers: {
                authorization: authHeader()
            }
        });
    }


    LoadTrimBuyerBrandList(BuyerID, BrandID) {
        return axios.get(window.$APIBaseURL + "Style/LoadTrimBuyerBrandList?BuyerID=" + BuyerID + "&BrandID=" + BrandID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetTrimBuyerBrandPrintColorList(BuyerID, BrandID) {
        return axios.get(window.$APIBaseURL + "Style/GetTrimBuyerBrandPrintColorList?BuyerID=" + BuyerID + "&BrandID=" + BrandID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateStyleTrimDetails(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Style/InsertUpdateStyleTrimDetails',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetStyleTrimInfoTabList(StyleID) {
        return axios.get(window.$APIBaseURL + "Style/GetStyleTrimInfoTabList?StyleID=" + StyleID, { // data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetStyleTrimColorTabList(StyleID) {
        return axios.get(window.$APIBaseURL + "Style/GetStyleTrimColorTabList?StyleID=" + StyleID, { // data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetStyleTrimList(StyleID) {
        return axios.get(window.$APIBaseURL + "Style/GetStyleTrimList?StyleID=" + StyleID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetStyleTrimReferenceList(StyleID) {
        return axios.get(window.$APIBaseURL + "Style/GetStyleTrimReferenceList?StyleID=" + StyleID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetTNATrimAlreadyMapList(params) {
        return axios.get(window.$APIBaseURL + "Style/GetTNATrimAlreadyMapList?StyleID=" + params.StyleID + "&BuyerID=" + params.BuyerID + "&BrandID=" + params.BrandID + "&TrimInfoId=" + params.TrimInfoId, {
            headers: {
                authorization: authHeader()
            }
        });
    }

}
export default new TrimService();