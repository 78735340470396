import React from 'react';
import { useEffect, useMemo, useState, Fragment } from 'react';
import ReactNotification from 'react-notifications-component';
import { TableHeader, Pagination, Search, PageCount } from "../../../Datatable";
import { useDispatch, useSelector } from "react-redux";
import { retrieveTestItem } from '../../../../actions/testitem';
import Nodify from "../../../Common/ReactNotification";
import SearchRetain from "../../../Common/SearchRetain";
import SweetAlertPopup from '../../../Common/SweetAlertPopup';
import { TestItem } from "../../../Common/PageActionNumber";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import { retrieveTestName } from '../../../../actions/testname';
import Reactselect from 'react-select';
import { Modal, Button } from "react-bootstrap";
import $ from "jquery";
import axios from "axios";
import Lightbox from "react-image-lightbox";
import TestStandardService from '../../../../services/Master/TestStandardService';

const TestStandardRegister = (props) => {
    const EmptyInputFields = {
        TestName: 0
        , TestNameID: 0
        , StandardComment: [{ CommentID: 0, Comment: "", Attachment: [] }]
    };
    const [inputFields, setInputFields] = useState([EmptyInputFields]);
    const [getTestNameOption, setTestNameOption] = useState([]);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getChildSubmitted, setChildSubmitted] = useState(false);
    const [getParentSubmitted, setParentSubmitted] = useState(false);
    const values = [...inputFields];
    const [getAttachmentViewMode, setAttachmentViewMode] = useState({ isShow: false, parentindex: null, childindex: null });
    const [getAttachmentParentIndexID, setAttachmentParentIndexID] = useState(0);
    const [getAttachmentChildIndexID, setAttachmentChildIndexID] = useState(0);
    const [getstandardaddParentIndexID, setstandardaddParentIndexID] = useState(0);
    const [getstandardaddChildIndexID, setstandardaddChildIndexID] = useState(0);
    const [getExistingDataSet, setExistingDataSet] = useState([]);
    const [getImagename, setImagename] = useState();
    const [isOpenimg, setIsOpenimg] = useState(false);
    const { user: currentUser } = useSelector((state) => state.auth);
    const [getExistingTestLength, setExistingTestLength] = useState(0);

    let styles = {
        control: styles => ({ ...styles, border: '1px solid red', innerHeight: '100px', outerHeight: '100px' }),
    }
    let styles1 = {
        control: styles => ({ ...styles, innerHeight: '100px', outerHeight: '100px' }),
    }
    //reducer
    const reducerState = useSelector((state) => state);
    const dispatch = useDispatch();
    const comments = reducerState.testname.TestNameList.testNameList;
    const commentsData = useMemo(() => {
        TestStandardService.GetTestStandardList(0).then((res => {
            setExistingDataSet(res.data.item1);
            if (res.data.item1.length > 0) {
                let TestIndexID = res.data.item1[res.data.item1.length - 1].standardID;
                setExistingTestLength(res.data.item1.length);
            }
        }))
        if (comments != undefined && comments.length > 0) {
            let computedTestNameOptions = comments.map((data => {
                data.id = data.testNameID;
                data.value = data.testNameID;
                data.label = data.testName;
                data.name = data.testName;
                return { id: data.id, name: data.name, label: data.label, value: data.value };
            }));
            setTestNameOption(computedTestNameOptions);
        }
    }, [comments]);

    const handleChangeTestName = (event, index) => {
        // setParentSubmitted(true);
        if (event === null) {
            values[index].TestNameID = 0;
            values[index].IndexID = index != 0 ? values[index - 1].IndexID + 1 : index;
            values[index].TestNameIDForEdit = '';
        }
        else {
            values[index].TestNameID = event.value;
            values[index].TestNameIDForEdit = event;
        }
        setInputFields(values);
        let CheckDuplicate = values.filter((data => {
            if (data.TestNameID === event.value) {
                return data;
            }
        }))
        getExistingDataSet.forEach(element => {
            if (element.testID == event.value && element.status == 1)
                CheckDuplicate.push(element);
        });
        if (CheckDuplicate.length > 1) {
            values[index].TestNameID = 0;
            values[index].TestNameIDForEdit = '';
            Nodify('Warning!', 'warning', 'This Test Name is already exist.');
            setInputFields(values);
        }
        else {
            // setParentSubmitted(false);
        }
    }


    const AddTestStandard = (index) => {
        setParentSubmitted(true);
        const values = [...inputFields];
        let commentnote = values[index].StandardComment[values[index].StandardComment.length - 1].Comment;
        if ((values[index].TestNameID == 0)) {
            Nodify('Warning!', 'warning', 'Please fill all mandatory(*) fields.');
        }
        else if ((values[index].TestNameID != 0) &&
            (values[index].StandardComment[0].Comment == null
                || values[index].StandardComment[0].Comment == ""
                || values[index].StandardComment[0].Comment == undefined)) {
            setChildSubmitted(true);
            Nodify('Warning!', 'warning', 'Please fill atleast one standard');
        }
        else if ((values[index].TestNameID != 0) &&
            (commentnote == null || commentnote == undefined || commentnote == "")) {
            setChildSubmitted(true);
            Nodify('Warning!', 'warning', 'Please fill all mandatory(*) fields.');
        }
        else {
            setParentSubmitted(false);
            values.push(EmptyInputFields);
            setInputFields(values);
        }
    }

    const RemoveTestStandard = (index) => {
        const values = [...inputFields];
        values.splice(index, 1);
        setInputFields(values);
    }

    const handleChangeStandardComment = (event, index, childindex) => {
        const values = [...inputFields];
        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }
        values[index].StandardComment[childindex].Comment = inputText;
        setInputFields(values);
    }

    const CheckDuplicateStandardComment = (event, index, childindex) => {
        if (event.target.value != null && event.target.value != undefined && event.target.value != "") {
            const unique = [];
            for (const item of values[index].StandardComment) {
                const isDuplicate = unique.find((obj) => obj.Comment.toLocaleLowerCase() === item.Comment.toLocaleLowerCase());
                if (!isDuplicate) {
                    unique.push(item);
                    setChildSubmitted(true);
                }
                else {
                    values[index].StandardComment[childindex].Comment = "";
                    setInputFields(values);
                    Nodify('Warning!', 'warning', 'This Standard is already exist.');
                    setChildSubmitted(true);
                    return false;
                }
            }
        }
    }

    const AddTestStandardComment = (parentIndex, childIndex) => {
        setstandardaddChildIndexID(childIndex);
        setstandardaddParentIndexID(parentIndex);
        let comment = values[parentIndex].StandardComment[childIndex].Comment;
        if (comment != null && comment != "" && comment != undefined) {
            values[parentIndex].StandardComment.push({ CommentID: 0, Comment: '', Attachment: [] });
            setChildSubmitted(false);
            setParentSubmitted(false);
        }
        else {
            Nodify('Warning!', 'warning', 'Please fill all mandatory(*) fields.');
            setChildSubmitted(true);
        }
        setInputFields(values);
    }

    const RemoveTestStandardComment = (parentIndex, childIndex) => {
        const values = [...inputFields];
        values[parentIndex].StandardComment.splice(childIndex, 1);
        setInputFields(values);
    }

    const AddOrViewAttachment = (parentindex, childindex) => {
        setAttachmentChildIndexID(childindex);
        setAttachmentParentIndexID(parentindex);
        setAttachmentViewMode({ isShow: true, parentindex: parentindex, childindex: childindex });
    }

    const handleCloseAttachmentPopup = index => {
        setAttachmentViewMode({ isShow: false, parentindex: null, childindex: null });
    }

    const AppendFiles = async (e, parentindex, childindex) => {
        const file = e.target.files[0];
        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword', "application/vnd.ms-excel",
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#FileUpload').val("");
            }
            else {
                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "TestStandardMaster");
                try {
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                    const values = [...inputFields];
                    var d = new Date();

                    var month = d.getMonth() + 1;
                    var day = d.getDate();

                    var output = day + '/' +
                        (month < 10 ? '0' : '') + month + '/' +
                        (day < 10 ? '0' : '') + d.getFullYear();
                    values[getAttachmentParentIndexID].StandardComment[getAttachmentChildIndexID].Attachment.push({
                        fileName: res.data + "_" + file.name
                        , filePath: "Images/Style/QCNotesUpload/" + file.name
                        , fileType: file.type
                        , CommentIndexID: getAttachmentChildIndexID
                        , ParentIndexID: getAttachmentParentIndexID
                        , AttachmentIndexID: values[getAttachmentParentIndexID].StandardComment[getAttachmentChildIndexID].Attachment.length + 1
                        , createdByName: currentUser.empFirstName + " " + currentUser.empLastName
                        , createdDate: output
                    });
                    setInputFields(values);
                    $('#FileUpload').val("");
                }
                catch (ex) {
                    console.log(ex);
                }
            }
        }
    }

    const ViewFile = (parentindex, childnidex, filename) => {
        setImagename(filename);
        setIsOpenimg(true);
    }

    const RemoveImages = async (index, fileindex) => {
        let values = [...inputFields];
        values[index].StandardComment[getAttachmentChildIndexID].Attachment.splice(fileindex, 1);
        setInputFields(values);
    }

    const InsertTestStandard = (e) => {
        setParentSubmitted(true);
        setChildSubmitted(true);
        setButtonLoader(true);
        e.preventDefault();
        let objStandardTestName = [];
        let objStandardComment = [];
        let objStandardAttachment = [];
        for (var i = 0; i < (inputFields.length); i++) {
            if (inputFields[i].TestNameID === 0) {
                Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
                setParentSubmitted(true);
                setButtonLoader(false);
                return false;
            }
            else if (inputFields[i].TestNameID !== 0) {
                objStandardTestName.push({ TestNameID: inputFields[i].TestNameID, IndexID: i + getExistingTestLength });
                for (var j = 0; j < (inputFields[i].StandardComment.length); j++) {
                    let commentdata = inputFields[i].StandardComment[j].Comment;
                    if (commentdata == null || commentdata == "" || commentdata == undefined) {
                        Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
                        setChildSubmitted(true);
                        setButtonLoader(false);
                        return false;
                    }
                    else {
                        objStandardComment.push({
                            CommentID: 0
                            , Comment: inputFields[i].StandardComment[j].Comment
                            , TestIndexID: i + getExistingTestLength
                            , CommentIndexID: j
                        });
                        if (inputFields[i].StandardComment[j].Attachment.length > 0) {
                            for (var k = 0; k < (inputFields[i].StandardComment[j].Attachment.length); k++) {
                                objStandardAttachment.push({
                                    AttachmentID: 0
                                    , FileName: inputFields[i].StandardComment[j].Attachment[k].fileName == undefined ? null : inputFields[i].StandardComment[j].Attachment[k].fileName
                                    , FilePath: inputFields[i].StandardComment[j].Attachment[k].filePath == undefined ? null : inputFields[i].StandardComment[j].Attachment[k].filePath
                                    , FileType: inputFields[i].StandardComment[j].Attachment[k].fileType == undefined ? null : inputFields[i].StandardComment[j].Attachment[k].fileType
                                    , TestIndexID: i + getExistingTestLength
                                    , CommentIndexID: j
                                    , AttachmentIndexID: k
                                });
                            }
                        }
                    }
                }
            }
        }
        let Params = {
            StandardID: 0
            , Createdby: currentUser.employeeinformationID
            , StandardTestName: objStandardTestName
            , StandardTestComment: objStandardComment
            , StandardTestAttachment: objStandardAttachment
        };
        TestStandardService.AddTestStandard(Params).then((res => {
            setParentSubmitted(false);
            setChildSubmitted(false);
            var page = "Remove";
            SearchRetain(page);
            let Func = 'Add';
            if (res.data.outputResult === "1") {
                Func = 'Add';
                PageRedirect(Func);
            }
            else if (res.data.outputResult === "0") {
                setButtonLoader(false);
                ValidationPopup("Error Occured!");
            }
        }))
    }

    const PageRedirect = (Func) => {
        props.history.push({ pathname: "/TestStandardList", state: { message: Func } });
    }

    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
        return false;
    }

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/TestStandardList')
        } else {
            window.location.reload();
        }
    }

    useEffect(() => {
        dispatch(retrieveTestName(1, 0, 0));
    }, [dispatch]);

    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className="row">

                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <div className="widget flat radius-bordered">
                            <div className="widget-header heading_box_style">
                                <h3 className="heading_style_common">Add Test Standard</h3>
                            </div>
                            <div className="widget-body">
                                <div id="registration-form">
                                    <form>
                                        {inputFields.map((inputField, index) => (
                                            <Fragment key={
                                                `${inputField}~${index}`
                                            }>
                                                <div className="row mtpoint5">
                                                    <div className="col-md-4">
                                                        <label>Test Name<span className='text-danger'>*</span></label>
                                                        <Reactselect className="basic-single"
                                                            name={"ddlTestName" + index}
                                                            id={"ddlTestName" + index}
                                                            isDisabled={false}
                                                            isLoading={false}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            menuPosition="fixed"
                                                            options={getTestNameOption}
                                                            styles={getParentSubmitted && (inputField.TestNameID === 0 || !inputField.TestNameIDForEdit) ? styles : styles1}
                                                            value={inputField.TestNameIDForEdit}
                                                            onChange={event => handleChangeTestName(event, index)}
                                                        >
                                                        </Reactselect>
                                                    </div>
                                                    <div className="col-md-8 mt1point4">
                                                        {inputFields.length === (index + 1) && <span className="btn btn-success"
                                                            title="Add Test Name" onClick={event => AddTestStandard(index)}>
                                                            <i className="fa fa-plus"></i>
                                                        </span>
                                                        }&nbsp;
                                                        {inputFields.length !== 1 && <span className="btn btn-danger"
                                                            title="Delete Test Name" onClick={event => RemoveTestStandard(index)}>
                                                            <i className="fa fa-trash"></i>
                                                        </span>}
                                                    </div>
                                                </div>
                                                <div className="row mtpoint5">
                                                    <div className="col-md-12">
                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                                            <thead>
                                                                <tr>
                                                                    <th> Standard<span className='text-danger'>*</span></th>
                                                                    <th> Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {inputFields[index].StandardComment.map((comment, childindex) => (
                                                                    <Fragment key={
                                                                        `${comment}~${childindex}`
                                                                    }>
                                                                        <tr>
                                                                            <td style={{ width: '75%' }}>
                                                                                <input type='text' className='form-control' name={"StandardComment" + childindex}
                                                                                    id={"StandardComment" + childindex} placeholder="Enter Standard"
                                                                                    value={comment.Comment}
                                                                                    style={{ border: (getParentSubmitted && !comment.Comment) || (getChildSubmitted && (!comment.Comment && getstandardaddParentIndexID == index)) ? '1px solid red' : '' }}
                                                                                    onChange={event => handleChangeStandardComment(event, index, childindex)}
                                                                                    onBlur={event => CheckDuplicateStandardComment(event, index, childindex)}
                                                                                    autoComplete="off" maxLength="200" />
                                                                            </td>
                                                                            <td style={{ width: '25%' }}>
                                                                                {inputFields[index].StandardComment.length === (childindex + 1) &&
                                                                                    <span className="btn btn-success" title="Add Test Standard"
                                                                                        onClick={event => AddTestStandardComment(index, childindex)}>
                                                                                        <i className="fa fa-plus"></i>
                                                                                    </span>}&nbsp;
                                                                                <span className="btn btn-warning" title="Attachment"
                                                                                    onClick={() => AddOrViewAttachment(index, childindex)}>
                                                                                    <i className="fa fa-paperclip"></i>
                                                                                    {
                                                                                        inputFields[index].StandardComment[childindex].Attachment != undefined &&
                                                                                        inputFields[index].StandardComment[childindex].Attachment.length > 0 &&
                                                                                        <span>
                                                                                            &nbsp; {inputFields[index].StandardComment[childindex].Attachment.length}
                                                                                        </span>
                                                                                    }
                                                                                </span>&nbsp;
                                                                                {inputFields[index].StandardComment.length !== 1 &&
                                                                                    <span className="btn btn-danger" title="Delete Test Standard"
                                                                                        onClick={event => RemoveTestStandardComment(index, childindex)}>
                                                                                        <i className="fa fa-trash"></i>
                                                                                    </span>}&nbsp;

                                                                            </td>
                                                                        </tr>
                                                                        {
                                                                            getAttachmentViewMode && index == getAttachmentParentIndexID &&
                                                                            <Modal dialogClassName="CreatePopup" show={getAttachmentViewMode.isShow} size="md" dragable backdrop="static" keyboard={false}
                                                                            >
                                                                                <Modal.Header closeButton onHide={() => handleCloseAttachmentPopup()}>
                                                                                    <Modal.Title>
                                                                                        Test Standard Attachment
                                                                                    </Modal.Title>
                                                                                </Modal.Header>
                                                                                <Modal.Body>
                                                                                    <div className="row">
                                                                                        <div className="col-sm-12 col-lg-12">
                                                                                            <input type="file" id="FileUpload" style={{ marginTop: '1%' }} onChange={(e) => AppendFiles(e, index, childindex)}
                                                                                                accept=".jpg,.jpeg,.png,.pdf,.xlsx,.doc,.docx,.xls" ></input>
                                                                                            {
                                                                                                <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable" style={{ marginTop: '1%' }}>
                                                                                                    <thead>
                                                                                                        <tr>
                                                                                                            <th>
                                                                                                                File Name
                                                                                                            </th>

                                                                                                            <th>
                                                                                                                Created / Modified By On Date
                                                                                                            </th>
                                                                                                            <th>
                                                                                                                Action
                                                                                                            </th>
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>
                                                                                                        {inputFields[index].StandardComment[getAttachmentChildIndexID].Attachment.length > 0 ?
                                                                                                            inputFields[index].StandardComment[getAttachmentChildIndexID].Attachment.map((files, fileindex) => (
                                                                                                                <tr>
                                                                                                                    <td>
                                                                                                                        {files.fileName.split("_")[1]}
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        {files.createdByName} On {files.createdDate}
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        {(files.fileType === "image/jpeg" || files.fileType === "image/jpg" || files.fileType === "image/png" ||
                                                                                                                            files.fileType === "image/gif") ? <p title='View'
                                                                                                                                className="btn btn-success btn-xs delete" onClick={() => ViewFile(index, childindex, files.fileName)}>
                                                                                                                            <i className="fa fa-eye"></i>
                                                                                                                        </p> : ""}&nbsp;

                                                                                                                        {(files.fileType === "application/pdf" || files.fileType === "application/msword" || files.fileType === "application/vnd.ms-excel"
                                                                                                                            || files.fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                                                                                                                            files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") ?
                                                                                                                            <a type="button"
                                                                                                                                title="Download" class="btn btn-xs btn-primary" target="_blank"
                                                                                                                                href={window.$APIBaseURL + 'Images/Master/TestStandard/' + files.fileName} download>
                                                                                                                                <i class="fa fa-download"></i> </a> : ""}&nbsp;
                                                                                                                        <p title='Delete'
                                                                                                                            className="btn btn-danger btn-xs delete" onClick={() => RemoveImages(index, fileindex)}>
                                                                                                                            <i className="fa fa-trash-o"></i>
                                                                                                                        </p>&nbsp;
                                                                                                                    </td>
                                                                                                                    {
                                                                                                                        < div > {
                                                                                                                            isOpenimg && (

                                                                                                                                <Lightbox mainSrc={window.$APIBaseURL + 'Images/Master/TestStandard/' + getImagename}
                                                                                                                                    alt="bg image"
                                                                                                                                    onCloseRequest={
                                                                                                                                        () => setIsOpenimg(false)
                                                                                                                                    } />
                                                                                                                            )
                                                                                                                        } </div>
                                                                                                                    }
                                                                                                                </tr>
                                                                                                            )) :
                                                                                                            <Fragment>
                                                                                                                <tr>
                                                                                                                    <td colSpan="3" className='norecordfound'><span>No Records Found</span></td>
                                                                                                                </tr>
                                                                                                            </Fragment>
                                                                                                        }

                                                                                                    </tbody>

                                                                                                </table>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </Modal.Body>
                                                                                <Modal.Footer>
                                                                                    <button type="button" class="btn btn-danger" onClick={() => handleCloseAttachmentPopup()}><i className='fa fa-times'></i>
                                                                                        &nbsp;Close</button>
                                                                                </Modal.Footer>
                                                                            </Modal>
                                                                        }
                                                                    </Fragment>
                                                                ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </Fragment>
                                        ))
                                        }
                                    </form>
                                    <div className="col-sm-12" style={{ marginTop: '10px' }}>
                                        <div className="buttons-preview" align="right">
                                            <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                                <i className="fa fa-arrow-circle-left"></i>
                                                &nbsp;Back
                                            </span>
                                            <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                                <i className="fa fa-times"></i>
                                                &nbsp;Reset
                                            </span>
                                            <button type="button" className="btn btn-success" onClick={InsertTestStandard}>
                                                <i className="fa fa-check right"></i>&nbsp;
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default TestStandardRegister;