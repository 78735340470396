import axios from "axios";
import authHeader from "../auth-header";

class MeasurementPointService {
    GetMeasurementPointsList() {
        return axios.get(window.$APIBaseURL + "Master/GetMeasurementPointsList?GroupTypeID=0", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateMeasurePonits(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateMeasurePonits',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateMeasurePonitsGrid(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateMeasurePonitsGrid',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

}
export default new MeasurementPointService()