import React from 'react';
import { useState, useEffect, Fragment, useMemo } from "react";
import ReactNotification from 'react-notifications-component';
import { retrieveTask } from '../../../../actions/task';
import { useDispatch, useSelector } from "react-redux";
import Form from "react-validation/build/form";
import Reactselect from 'react-select';
import { select } from 'react-validation/build/select';
import Nodify from '../../../Common/ReactNotification';
import TestService from '../../../../services/Master/TestService';
import TaskService from '../../../../services/Master/TaskService';
import AlignmentService from '../../../../services/Master/Alignment';
import SearchRetain from '../../../Common/SearchRetain';
import { retrieveAlignment } from '../../../../actions/alignment';

const AlignmentEdit = (props) => {
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red', innerHeight: '100px', outerHeight: '100px' }),
    }
    let styles1 = {
        control: styles => ({ ...styles, innerHeight: '100px', outerHeight: '100px' }),
    }
    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    if (menulist !== undefined) {
        activeMenu = menulist.filter(i => i.menuName === "Alignment");
    }
    //end enable menu
    const { user: currentUser } = useSelector((state) => state.auth);
    const [getTestAlignment, setTestAlignment] = useState([]);
    const [getTestTask, setTestTask] = useState([]);
    const [getAlignmentInfo, setAlignmentInfo] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getExisting, setExisting] = useState([]);


    const dispatch = useDispatch();
    const reducerState = useSelector((state) => state);
    const commentsTask = reducerState.AlignmentReducer.AlignmentList.item1;

    function handleSelect(event, FieldName, index) {
        let value = [...getAlignmentInfo];
        if (FieldName === "TaskID") {
            if (event != null) {
                value[index].TaskID = event.value;
                value[index].TaskIDForEdit = event;
            }
            else {
                value[index].TaskID = 0;
                value[index].TaskIDForEdit = [];
                value[index].AlignmentOrderID = "";
                value[index].AlignmentOrderIDForEdit = [];
            }

        }
        else if (FieldName === 'AlignmentOrder') {
            let Testalignment = "";
            for (let Alignment of event) {
                if (Testalignment === "") {
                    Testalignment = Alignment.value.toString()
                } else {
                    Testalignment = Testalignment + "," + Alignment.value;
                }
            }
            value[index].AlignmentOrderID = Testalignment
            value[index].AlignmentOrderIDForEdit = event;
        }
        setAlignmentInfo(value);
    }

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/AlignmentList')
        } else {
            window.location.reload();
        }
    }

    const SaveAlignment = (e) => {
        let value = [...getAlignmentInfo];
        setSubmitted(true);
        setButtonLoader(true);
        e.preventDefault();
        if (value[0].TaskID == 0 || value[0].AlignmentOrderID.trim() == '') {
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
        }
        else {
            let isExisting = !!getExisting.find(d => d.taskID === value[0].TaskID && d.id !== props.location.state.params.id);
            if (isExisting) {
                value[0].TaskID = 0;
                value[0].TaskIDForEdit = [];
                value[0].AlignmentOrderID = "";
                value[0].AlignmentOrderIDForEdit = [];
                value[0].TestTypeID = 0;
                setAlignmentInfo(value);
                setSubmitted(true);
                Nodify('Warning!', 'warning', "This Alignment is already exist.");
                return false;
            }
            else {
                let Params = {
                    ID: value[0].ID
                    , TaskID: value[0].TaskID
                    , AlignmentOrder: value[0].AlignmentOrderID
                    , TestTypeID: value[0].TestTypeID
                    , ModifiedBy: currentUser.employeeinformationID
                }
                AlignmentService.UpdateAlignmentMaster(Params).then(res => {
                    var page = "Remove";
                    SearchRetain(page);
                    let Func = 'Add';
                    if (res.data.outputResult === "2") {
                        Func = 'Edit';
                        setSubmitted(false);
                        PageRedirect(Func);
                    }
                    setButtonLoader(false);
                }).catch(err => {
                    setSubmitted(false);
                    setButtonLoader(false);
                });
            }
        }
    }

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/AlignmentList",
            state: {
                message: Func,
            },
        }
        );
    };

    useEffect(() => {
        dispatch(retrieveAlignment());
    }, [dispatch]);

    useEffect(() => {
        if (commentsTask != undefined && commentsTask.length > 0) {
            setExisting(commentsTask);
        }
    }, [commentsTask]);

    useEffect(() => {
        TestService.GetTestNameList(1, 0, 0).then((res => {
            let TestAlignment = []
            if (res.data) {
                res.data.testNameList.map((x, index) => {
                    TestAlignment.push({
                        value: x.testNameID,
                        id: x.testNameID,
                        label: x.testName,
                        name: x.testName,
                        //TaskID: parseInt(x.taskID)
                        TaskID: x.taskID
                    })
                })
                setTestAlignment(TestAlignment);
            }
        }));

        TaskService.GetTaskDetailsList(0).then((res => {
            let TaskData = []
            if (res.data) {
                let TestingTask = res.data.filter((data => {
                    if (data.taskTypeName == "Testing") {
                        return data;
                    }
                }
                ))
                TestingTask.map((data => {
                    TaskData.push({
                        value: data.taskDetailsID
                        , id: data.taskDetailsID
                        , label: data.taskName + " - " + data.taskOrderTypeName
                        , name: data.taskName + " - " + data.taskOrderTypeName
                    })
                }));
                setTestTask(TaskData);
            }
        }))
    }, []);

    function BindAlignmentValue(props) {
        let AlignmentInfoValue = [{
            ID: 0
            , TaskID: 0
            , TaskIDForEdit: []
            , AlignmentOrderID: ""
            , AlignmentOrderIDForEdit: ""
            , TestTypeID: 0
        }];
        if (props.location.state !== undefined) {
            if (props.location.state.params.id !== 0) {
                AlignmentInfoValue[0].ID = props.location.state.params.id;
                AlignmentInfoValue[0].TaskID = props.location.state.params.taskID;
                AlignmentInfoValue[0].TestTypeID = props.location.state.params.testTypeID;
                AlignmentInfoValue[0].AlignmentOrderID = props.location.state.params.alignmentOrder;
                AlignmentInfoValue[0].TaskIDForEdit = getTestTask.filter((task => {
                    return task.id === props.location.state.params.taskID;
                }));
                AlignmentInfoValue[0].AlignmentOrderIDForEdit = getTestAlignment.filter((data => {
                    if (props.location.state.params.alignmentOrder.split(",").includes(data.id.toString())) {
                        return data;
                    }
                }))
                return AlignmentInfoValue;
            }
        }
    }

    useEffect(() => {
        if (getTestTask.length > 0 && getTestAlignment.length > 0) {
            let AlignmentInfoValue = BindAlignmentValue(props);
            setAlignmentInfo(AlignmentInfoValue);
        }
    }, [props.location.state && getTestTask.length > 0 && getTestAlignment.length > 0]);

    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <div className="widget flat radius-bordered">
                            <div className="widget-header heading_box_style">
                                <h3 className="heading_style_common">
                                    Edit Test Details Appearance Alignment
                                </h3>
                            </div>
                            <div className="widget-body">
                                <div id="registration-form">
                                    <Form>
                                        {
                                            getAlignmentInfo.length > 0 && getAlignmentInfo.map((inputField, index) => (
                                                <Fragment>
                                                    <div className="col-md-4">
                                                        <label htmlFor="MeasurePointName">
                                                            Test Task Name<span className="text-danger">*</span>
                                                        </label>
                                                        <Reactselect className="basic-single"
                                                            name="ddlTestTask"
                                                            id="ddlTestTask"
                                                            isDisabled={true}
                                                            isLoading={false}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            menuPosition="fixed"
                                                            onChange={event => handleSelect(event, 'TaskID', 0)}
                                                            options={getTestTask}
                                                            value={inputField.TaskIDForEdit}
                                                            styles={submitted && (!inputField.TaskID) ? styles : styles1}
                                                        >
                                                        </Reactselect>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label htmlFor="MeasurePointName">
                                                            Testing Appearance Alignment Order<span className="text-danger">*</span>
                                                        </label>
                                                        <Reactselect
                                                            options={getTestAlignment.filter(x => {
                                                                return x.TaskID.split(',').includes(inputField.TaskID.toString())
                                                            })}
                                                            //options={getTestAlignment.filter(x => x.TaskID === inputField.TaskID)}
                                                            placeholder="Select color"
                                                            value={inputField.AlignmentOrderIDForEdit}
                                                            onChange={event => handleSelect(event, 'AlignmentOrder', 0)}
                                                            isMulti
                                                            styles={submitted && inputField.AlignmentOrderIDForEdit == "" ? styles : styles1}
                                                        />
                                                    </div>
                                                </Fragment>
                                            ))
                                        }
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div
                            className="buttons-preview"
                            align="right"
                            style={{ marginTop: "15px" }}
                        >
                            <div className="buttons-preview" align="right">
                                <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                    <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                                </span>
                                <span className="btn btn-danger" onClick={() => ResetOperation()}
                                    style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}>
                                    <i className="fa fa-times"></i>&nbsp;Reset
                                </span>
                                <button
                                    type="submit"
                                    className="btn btn-success"
                                    disabled={buttonLoader} onClick={SaveAlignment}
                                >
                                    <i className="fa fa-check right"></i> Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AlignmentEdit