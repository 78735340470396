import React, { useState, useEffect, Fragment, useMemo } from 'react';
import Reactselect from 'react-select';
import { useSelector, useDispatch } from "react-redux";
import ReactNotification from 'react-notifications-component';
import Form from "react-validation/build/form";
import TestStandardService from '../../../../services/Master/TestStandardService';
import TestServices from '../../../../services/Master/TestService';
import { Modal, Button } from "react-bootstrap";
import Lightbox from "react-image-lightbox";
import $ from "jquery";
import Nodify from "../../../Common/ReactNotification";
import axios from "axios";
import SearchRetain from "../../../Common/SearchRetain";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";

const TestStandardEdit = (props) => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const { user: currentUser } = useSelector((state) => state.auth);
    const [getTestStandardInfo, setTestStandardInfo] = useState([]);
    const [getTestNameOption, setTestNameOption] = useState([]);
    const [getDataSubmitted, setDataSubmitted] = useState(false);
    const [getChildSubmitted, setChildSubmitted] = useState(false);
    const [getImagename, setImagename] = useState();
    const [getAttachmentCommentIndexID, setAttachmentCommentIndexID] = useState(null);
    const [isOpenimg, setIsOpenimg] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getExistingDataSet, setExistingDataSet] = useState([]);
    const [getExistingTestLength, setExistingTestLength] = useState(0);
    const [getAttachmentViewMode, setAttachmentViewMode] = useState({ isShow: false, commentIndex: null });
    const values = [...getTestStandardInfo];
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red', innerHeight: '100px', outerHeight: '100px' }),
    }
    let styles1 = {
        control: styles => ({ ...styles, innerHeight: '100px', outerHeight: '100px' }),
    }
    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    if (menulist !== undefined) {
        activeMenu = menulist.filter(i => i.menuName === "TestStandard");
    }
    //end enable menu
    const commentsData = useMemo(() => {
        TestStandardService.GetTestStandardList(0).then((res => {
            setExistingDataSet(res.data.item1);
            if (res.data.item1.length > 0) {
                let TestIndexID = res.data.item1[res.data.item1.length - 1].standardID;
                setExistingTestLength(res.data.item1.length);
            }
        }))
    }, []);

    const handleChangeTestName = (event, index) => {
        if (event === null) {
            values[index].TestNameID = 0;
            values[index].IndexID = index != 0 ? values[index - 1].IndexID + 1 : index;
            values[index].TestNameIDforEdit = '';
        }
        else {
            values[index].TestNameID = event.value;
            values[index].TestNameIDforEdit = event;
        }
        setTestStandardInfo(values);
        if (event !== null) {
            let CheckDuplicate = [];
            getExistingDataSet.forEach(element => {
                if (element.testID == event.value && element.status == 1 && element.standardID !== getTestStandardInfo[0].StandardID) {
                    CheckDuplicate.push(element);
                }
            });
            if (CheckDuplicate.length > 0) {
                values[index].TestNameID = 0;
                values[index].TestNameIDforEdit = "";
                Nodify('Warning!', 'warning', 'This Test Name is already exist.');
                setTestStandardInfo(values);
            }
        }
    }

    const AddOrViewAttachment = (commentindex) => {
        setAttachmentCommentIndexID(commentindex);
        setAttachmentViewMode({ isShow: true, childindex: null });
    }

    const handleCloseAttachmentPopup = index => {
        setAttachmentViewMode({ isShow: false, parentindex: null, childindex: null });
    }

    const ViewFile = (commentindex, filename) => {
        setImagename(filename);
        setIsOpenimg(true);
    }

    const RemoveImages = async (index, fileindex) => {
        let values = [...getTestStandardInfo];
        values[0].Comment[index].Attachment.splice(fileindex, 1);
        setTestStandardInfo(values);
    }

    const AppendFiles = async (e, parentindex, commentIndex) => {
        const file = e.target.files[0];
        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword', "application/vnd.ms-excel",
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#FileUpload').val("");
            }
            else {
                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "TestStandardMaster");
                try {
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                    const values = [...getTestStandardInfo];
                    var d = new Date();

                    var month = d.getMonth() + 1;
                    var day = d.getDate();

                    var output = day + '/' +
                        (month < 10 ? '0' : '') + month + '/' +
                        (day < 10 ? '0' : '') + d.getFullYear();
                    values[0].Comment[commentIndex].Attachment.push({
                        fileName: res.data + "_" + file.name
                        , filePath: "Images/Style/QCNotesUpload/" + file.name
                        , fileType: file.type
                        , CommentIndexID: commentIndex
                        , ParentIndexID: commentIndex
                        , AttachmentIndexID: values[0].Comment[getAttachmentCommentIndexID].Attachment.length + 1
                        , createdByName: currentUser.empFirstName + " " + currentUser.empLastName
                        , createdDate: output
                    });
                    setTestStandardInfo(values);
                    $('#FileUpload').val("");
                }
                catch (ex) {
                    console.log(ex);
                }
            }
        }
    }

    const handleChangeStandardComment = (event, commentIndex) => {
        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }
        values[0].Comment[commentIndex].comment = inputText;
        setTestStandardInfo(values);
    }

    const CheckDuplicateStandardComment = (event, commentIndex) => {
        setAttachmentCommentIndexID(commentIndex);
        if (event.target.value != null && event.target.value != undefined && event.target.value != "") {
            const unique = [];
            for (const item of values[0].Comment) {
                const isDuplicate = unique.find((obj) => obj.comment.trim().toLowerCase() === item.comment.trim().toLowerCase());
                if (!isDuplicate) {
                    unique.push(item);
                    setChildSubmitted(false);
                }
                else {
                    values[0].Comment[commentIndex].comment = "";
                    setTestStandardInfo(values);
                    setChildSubmitted(true);
                    Nodify('Warning!', 'warning', 'This Standard is already exist.');
                    return false;
                }
            }
        }
    }

    const AddTestStandardComment = (index, commentIndex) => {
        setAttachmentCommentIndexID(commentIndex + 1);
        let comment = values[0].Comment[commentIndex].comment;
        if (comment != null && comment != "" && comment != undefined) {
            values[0].Comment.push({ commentID: 0, comment: '', Attachment: [] });
            setChildSubmitted(false);
        }
        else {
            Nodify('Warning!', 'warning', 'Please fill all mandatory(*) fields.');
            setChildSubmitted(true);
        }
        setTestStandardInfo(values);
    }

    const RemoveTestStandardComment = (commentIndex) => {
        const values = [...getTestStandardInfo];
        values[0].Comment.splice(commentIndex, 1);
        setTestStandardInfo(values);
    }

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/TestStandardList",
            state: {
                message: Func,
            },
        }
        );
    };

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/TestStandardList')
        } else {
            window.location.reload();
        }
    }

    const UpdateTestStandard = (e) => {
        setButtonLoader(true);
        e.preventDefault();
        let objStandardTestName = [];
        let objStandardComment = [];
        let objStandardAttachment = [];
        if (getTestStandardInfo[0].TestNameID === 0) {
            Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
            setDataSubmitted(true);
            setButtonLoader(false);
            return false;
        }
        for (var i = 0; i < (getTestStandardInfo[0].Comment.length); i++) {
            if (getTestStandardInfo[0].Comment[i].comment.trim() === "") {
                Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
                setChildSubmitted(true);
                setButtonLoader(false);
                return false;
            }
            else {
                objStandardComment.push({
                    CommentID: getTestStandardInfo[0].Comment[i].commentID
                    , Comment: getTestStandardInfo[0].Comment[i].comment
                    , TestIndexID: getTestStandardInfo[0].StandardID
                    , CommentIndexID: i
                });
                if (getTestStandardInfo[0].Comment[i].Attachment.length > 0) {
                    for (var j = 0; j < (getTestStandardInfo[0].Comment[i].Attachment.length); j++) {
                        objStandardAttachment.push({
                            AttachmentID: getTestStandardInfo[0].Comment[i].Attachment[j].attachmentID
                            , FileName: getTestStandardInfo[0].Comment[i].Attachment[j].fileName == undefined ? null : getTestStandardInfo[0].Comment[i].Attachment[j].fileName
                            , FilePath: getTestStandardInfo[0].Comment[i].Attachment[j].filePath == undefined ? null : getTestStandardInfo[0].Comment[i].Attachment[j].filePath
                            , FileType: getTestStandardInfo[0].Comment[i].Attachment[j].fileType == undefined ? null : getTestStandardInfo[0].Comment[i].Attachment[j].fileType
                            , TestIndexID: getTestStandardInfo[0].StandardID
                            , CommentIndexID: i
                            , AttachmentIndexID: j
                        });
                    }
                }
            }
        }
        let Params = {
            StandardID: getTestStandardInfo[0].StandardID
            , TestID: getTestStandardInfo[0].TestNameID
            , CreatedBy: currentUser.employeeinformationID
            , StandardComment: objStandardComment
            , StandardAttachment: objStandardAttachment
        };
        TestStandardService.UpdateTestStandard(Params).then(res => {
            var page = "Remove";
            SearchRetain(page);
            let Func = 'Add';
            if (res.data.outputResult === "1") {
                Func = 'Add';
                PageRedirect(Func);
            } else if (res.data.outputResult === "2") {
                Func = 'Edit';
                PageRedirect(Func);
            }
            setButtonLoader(false);
        });
    }

    useEffect(() => {
        let CommentInfoValue = [{
            Operation: 2
            , TestNameID: 0
            , StandardID: 0
            , Comment: []
            , Createdby: currentUser.employeeinformationID
            , TestNameIDforEdit: ''
        }];
        if (props.location.state !== undefined) {
            showLoader();
            if (props.location.state.params.standardID !== 0) {
                TestServices.GetTestNameList(1, 0, 0).then((res => {
                    let computedTestNameOptions;
                    if (res.data.testNameList != undefined && res.data.testNameList.length > 0) {
                        computedTestNameOptions = res.data.testNameList.map((data => {
                            data.id = data.testNameID;
                            data.value = data.testNameID;
                            data.label = data.testName;
                            data.name = data.testName;
                            return { id: data.id, name: data.name, label: data.label, value: data.value };
                        }));
                        setTestNameOption(computedTestNameOptions);
                    }
                    TestStandardService.GetTestStandardList(props.location.state.params.standardID).then((res => {
                        CommentInfoValue[0].Operation = 2;
                        CommentInfoValue[0].TestNameID = res.data.item1[0].testID;
                        CommentInfoValue[0].StandardID = props.location.state.params.standardID;
                        CommentInfoValue[0].Comment = res.data.item2;
                        if (res.data.item3 != undefined && res.data.item2.length > 0) {
                            CommentInfoValue[0].Comment.forEach((element, index) => {
                                element.Attachment = res.data.item3.filter((data => {
                                    return data.commentIndexID == element.commentIndexID;
                                }));
                                return element;
                            });
                        }
                        CommentInfoValue[0].TestNameIDforEdit = computedTestNameOptions.filter((data => {
                            return data.id == res.data.item1[0].testID;
                        }));
                        setTestStandardInfo(CommentInfoValue);
                        hideLoader();
                    }), (err => {
                        hideLoader();
                    }))
                }), (err => {
                    hideLoader();
                }))
            }
        }
        else {
            hideLoader();
        }
    }, [props.location.state])

    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <div className="widget flat radius-bordered">
                            <div className="widget-header heading_box_style">
                                <h3 className="heading_style_common">
                                    Edit Test Standard
                                </h3>
                            </div>
                            <div className="widget-body">
                                <div id="registration-form">
                                    <Form>
                                        {
                                            getTestStandardInfo.length > 0 && getTestStandardInfo.map((inputField, index) => (
                                                <Fragment>
                                                    <div className="row mtpoint5">
                                                        <div className="col-md-4">
                                                            <label>Test Name<span className='text-danger'>*</span></label>
                                                            <Reactselect className="basic-single"
                                                                name={"ddlTestName" + index}
                                                                id={"ddlTestName" + index}
                                                                isDisabled={false}
                                                                isLoading={false}
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                menuPosition="fixed"
                                                                options={getTestNameOption}
                                                                //style={{ border: getDataSubmitted && inputField.TestNameID == 0 ? '1px solid red' : '' }}
                                                                styles={getDataSubmitted && inputField.TestNameID === 0 ? styles : styles1}
                                                                value={inputField.TestNameIDforEdit}
                                                                onChange={event => handleChangeTestName(event, index)}
                                                            >
                                                            </Reactselect>
                                                        </div>
                                                    </div>
                                                    <div className="row mtpoint5">
                                                        <div className="col-md-12">
                                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                                                <thead>
                                                                    <tr>
                                                                        <th> Standard<span className='text-danger'>*</span></th>
                                                                        <th> Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        getTestStandardInfo[index].Comment.map((comment, commentindex) => (
                                                                            <Fragment>
                                                                                <tr>
                                                                                    <td style={{ width: '75%' }}>
                                                                                        <input type='text' className='form-control' name={"StandardComment" + commentindex}
                                                                                            id={"StandardComment" + commentindex} placeholder="Enter Standard"
                                                                                            value={comment.comment} onChange={event => handleChangeStandardComment(event, commentindex)}
                                                                                            onBlur={event => CheckDuplicateStandardComment(event, commentindex)}
                                                                                            style={{ border: getChildSubmitted && (!comment.Comment && getAttachmentCommentIndexID == commentindex ? '1px solid red' : '') }}
                                                                                            autoComplete="off" maxLength="100" />
                                                                                    </td>
                                                                                    <td style={{ width: '25%' }}>
                                                                                        {getTestStandardInfo[0].Comment.length === (commentindex + 1) &&
                                                                                            <span className="btn btn-success" title="Add Test Standard"
                                                                                                style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}
                                                                                                onClick={event => AddTestStandardComment(index, commentindex)} >
                                                                                                <i className="fa fa-plus"></i>
                                                                                            </span>}&nbsp;
                                                                                        <span className="btn btn-warning" title="Attachment"
                                                                                            onClick={() => AddOrViewAttachment(commentindex)}>
                                                                                            <i className="fa fa-paperclip"></i>&nbsp;
                                                                                            {comment.Attachment.length > 0 ? comment.Attachment.length : ""}
                                                                                        </span>&nbsp;
                                                                                        {getTestStandardInfo[0].Comment.length !== 1 && <span className="btn btn-danger"
                                                                                            title="Delete Test Standard"
                                                                                            onClick={event => RemoveTestStandardComment(commentindex)}>
                                                                                            <i className="fa fa-trash"></i>
                                                                                        </span>}&nbsp;

                                                                                    </td>
                                                                                </tr>
                                                                                {
                                                                                    getAttachmentViewMode && commentindex == getAttachmentCommentIndexID &&
                                                                                    <Modal dialogClassName="CreatePopup" show={getAttachmentViewMode.isShow} size="md" dragable backdrop="static" keyboard={false}>
                                                                                        <Modal.Header closeButton onHide={() => handleCloseAttachmentPopup()}>
                                                                                            <Modal.Title>
                                                                                                Test Standard Attachment
                                                                                            </Modal.Title>
                                                                                        </Modal.Header>
                                                                                        <Modal.Body>
                                                                                            <div className="row">
                                                                                                <div className="col-sm-12 col-lg-12">
                                                                                                    <input type="file" id="FileUpload" style={{ marginTop: '1%' }}
                                                                                                        onChange={(e) => AppendFiles(e, index, commentindex)}
                                                                                                        accept=".jpg,.jpeg,.png,.pdf,.xlsx,.doc,.docx,.xls" ></input>

                                                                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable" style={{ marginTop: '1%' }}>
                                                                                                        <thead>
                                                                                                            <tr>
                                                                                                                <th>
                                                                                                                    File Name
                                                                                                                </th>
                                                                                                                <th>
                                                                                                                    Created / Modified By On Date
                                                                                                                </th>
                                                                                                                <th>
                                                                                                                    Action
                                                                                                                </th>
                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            {getTestStandardInfo[index].Comment[commentindex].Attachment.length > 0 ?
                                                                                                                getTestStandardInfo[index].Comment[commentindex].Attachment.map((files, fileindex) => (
                                                                                                                    <>
                                                                                                                        <tr>
                                                                                                                            <td>
                                                                                                                                {files.fileName.split("_")[1]}
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                {files.createdByName} On {files.createdDate}
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                {(files.fileType === "image/jpeg" || files.fileType === "image/jpg" || files.fileType === "image/png" ||
                                                                                                                                    files.fileType === "image/gif") ? <p title='View' onClick={() => ViewFile(commentindex, files.fileName)}
                                                                                                                                        className="btn btn-success btn-xs delete" >
                                                                                                                                    <i className="fa fa-eye"></i>
                                                                                                                                </p> : ""}&nbsp;

                                                                                                                                {(files.fileType === "application/pdf" || files.fileType === "application/msword" || files.fileType === "application/vnd.ms-excel"
                                                                                                                                    || files.fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                                                                                                                                    files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") ?
                                                                                                                                    <a type="button"
                                                                                                                                        title="Download" class="btn btn-xs btn-primary" target="_blank"
                                                                                                                                        href={window.$APIBaseURL + 'Images/Master/TestStandard/' + files.fileName} download>
                                                                                                                                        <i class="fa fa-download"></i> </a> : ""}&nbsp;
                                                                                                                                <p title='Delete' onClick={() => RemoveImages(commentindex, fileindex)}
                                                                                                                                    style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}
                                                                                                                                    className="btn btn-danger btn-xs delete">
                                                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                                                </p>&nbsp;
                                                                                                                            </td >
                                                                                                                            {
                                                                                                                                < div > {
                                                                                                                                    isOpenimg && (

                                                                                                                                        <Lightbox mainSrc={window.$APIBaseURL + 'Images/Master/TestStandard/' + getImagename}
                                                                                                                                            alt="bg image"
                                                                                                                                            onCloseRequest={
                                                                                                                                                () => setIsOpenimg(false)
                                                                                                                                            } />
                                                                                                                                    )
                                                                                                                                } </div>
                                                                                                                            }
                                                                                                                        </tr>
                                                                                                                    </>
                                                                                                                )) : <Fragment>
                                                                                                                    <tr>
                                                                                                                        <td colSpan="3" className='norecordfound'><span>No Records Found</span></td>
                                                                                                                    </tr>
                                                                                                                </Fragment>
                                                                                                            }
                                                                                                        </tbody>
                                                                                                    </table>

                                                                                                </div>
                                                                                            </div>
                                                                                        </Modal.Body>
                                                                                        <Modal.Footer>
                                                                                            <button type="button" class="btn btn-danger" onClick={() => handleCloseAttachmentPopup()}><i className='fa fa-times'></i>
                                                                                                &nbsp;Close</button>
                                                                                        </Modal.Footer>
                                                                                    </Modal>
                                                                                }
                                                                            </Fragment>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            ))
                                        }
                                    </Form>
                                    <div className="col-sm-12" style={{ marginTop: '10px' }}>
                                        <div className="buttons-preview" align="right">
                                            <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                                <i className="fa fa-arrow-circle-left"></i>
                                                &nbsp;Back
                                            </span>
                                            <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                                <i className="fa fa-times"></i>
                                                &nbsp;Reset
                                            </span>
                                            <button type="button" className="btn btn-success" onClick={UpdateTestStandard}>
                                                <i className="fa fa-check right"></i>&nbsp;
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div >
                </div >
            </div >
            {loader}
        </>
    )
}
export default TestStandardEdit;