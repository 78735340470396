import {
    TRIM_LOADING,
    RETRIEVE_TRIM,
    DELETE_TRIM,
} from "./types";

import trimService from "../services/Style/TrimService";

const trimLoading = (isStatus) => ({
    type: TRIM_LOADING,
    payload: isStatus,
});

export const deleteTrim = (trimInfoID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_TRIM,
            payload: trimInfoID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrieveTrim = (TrimInfoID) => async (dispatch) => {
    try {
        dispatch(trimLoading(true));
        const res = await trimService.GetTrimLogoInformationList(TrimInfoID);
        dispatch({
            type: RETRIEVE_TRIM,
            payload: res.data,
        });
        dispatch(trimLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(trimLoading(false));
    }
};