import {
    DASHBOARD_CHARTS,
    DASHBOARD_CHART_LOADING,
} from "./types";
import dashboardService from "../services/dashboard.service";

const dashboardChartLoading = (isStatus) => ({
    type: DASHBOARD_CHART_LOADING,
    payload: isStatus,
});

export const DashboardChart = (randomdata, employeeID) => (dispatch) => {
    dispatch(dashboardChartLoading(true));
    return dashboardService.fetchchart(employeeID).then(
        (response) => {
            dispatch({
                type: DASHBOARD_CHARTS,
                payload: response.data,
            });
            dispatch(dashboardChartLoading(false));
            return Promise.resolve(response.data);
        }
    );
};

