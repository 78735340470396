import {
    TNADETAILREPORT_LOADING,
    LOAD_TNADETAILREPORT_LIST
} from "../actions/types"

const initialState = {
    TnaDetailsReportList: [],
    isLoadingTnaDetailsReport: true,
};

const Tnadetailsreducer = (state = initialState, action) => {
  
    const { type, payload } = action
    switch (type) {
        case TNADETAILREPORT_LOADING:
            return {
                ...state,
                isLoadingTnaDetailsReport: payload
            };
        case LOAD_TNADETAILREPORT_LIST:
            return {
                ...state,
                TnaDetailsReportList: payload
            }
        default:
            return state;
    }
}
export default Tnadetailsreducer;
