import React, { useState, useEffect, useRef, Fragment } from "react";
import DefectService from "../../../services/Master/DefectService";
import { useSelector } from "react-redux";
import Nodify from "../../Common/ReactNotification";
import validator from 'validator'
import ReactNotification from 'react-notifications-component';


const DefectsAdd = (props) => {
  const [submitted, setSubmitted] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [getDefect, setDefect] = useState([{ Id: 0, Name: "", }]);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [ExistingList, setExistingList] = useState([]);
  const [ButtonName, setButtonName] = useState("Save");
  const [HeaderName, setHeaderName] = useState("Add");

  function DefectListing(props) {
    let Defect = [...getDefect];
    Array.from(document.querySelectorAll("input")).forEach(input => (input.value = ""));
    if (props.location.state.params.length !== 0) {
      if (props.location.state.params.Id !== "") {
        setButtonName("Update");
        setHeaderName("Edit");
        Defect.Id = props.location.state.params.defectTypeID;
        Defect.Name = validator.trim(props.location.state.params.defectName);
        Defect.Operation = 2;
        return Defect;
      }
    } else {
      return Defect;
    }
  }


    //Enter Error msg
    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                //SaveBuyerInfo(event);
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [])
  const handleInputChange = (index, event) => {

    const values = [...getDefect];
    let inputText = '';
    if (event.target.value.trim() !== '') {
      inputText = event.target.value;
    }
    values[index].Name = inputText;
    setDefect(values);
  };

  const handleAddFields = (index) => {
    const values = [...getDefect];
    if (values[index].Name.trim() !== '') {
      values.push({ Id: 0, Name: "", });
      setDefect(values);
      setSubmitted(false);
    }
    else {
      Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
      setSubmitted(true);
    }
  };

  const handleRemoveFields = index => {
    const values = [...getDefect];
    values.splice(index, 1);
    setDefect(values);
  };

  useEffect(() => {
    let getDefectLists = DefectListing(props);
    setDefect(getDefectLists)
    DefectService.getAllDefectList().then((response) => {
      if (response.data) {
        setExistingList(response.data);
      }
    });
  }, []);










  const SaveDefect = (e) => {

    e.preventDefault();
    if (getDefect.length === 1 && getDefect[0].Name === '') {
      setButtonLoader(false);
      setSubmitted(true);
      ValidationPopup("Please fill atleast one Defect Name.");
    }
    else {
      for (var i = 0; i < (getDefect.length); i++) {
        if (getDefect[i].Name.trim() === '') {
          Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
          setSubmitted(true);
          setButtonLoader(false);
          return false;
        }
      }
      let DefectInfo = getDefect;
      let InputDefectMaster = { Createdby: currentUser.employeeinformationID, DefectInfo: getDefect };

      DefectService.InsertUpdateDefect(InputDefectMaster).then(res => {
        let Func = 'Add';
        if (res.data.outputResult === "1") {
          Func = 'Add';
          PageRedirect(Func);
        } else if (res.data.outputResult === "2") {
          Func = 'Edit';
          PageRedirect(Func);
        } else if (res.data.outputResult === "-2") {
          setButtonLoader(false);
          ValidationPopup("This Defect is already exist.");
        }
        else if (res.data.outputResult === "0") {
          setButtonLoader(false);
          ValidationPopup("Error Occured!");
        }
      })
    }


  }
  const CheckDuplicate = (index) => {

    const values = [...getDefect];
    if (ExistingList) {
      for (var i = 0; i < ExistingList.length; i++) {
        if (ExistingList[i].defectName.trim().toLowerCase() === values[index].Name.trim().toLowerCase()) {
          values[index].Name = '';
          setDefect(values);
          Nodify('Warning!', 'warning', "This Defect is already exist.");
          return false;
        }
      }
    }

    for (var i = 0; i < (getDefect.length); i++) {
      if (i !== index) {
        if (getDefect[i].Name.trim().toLowerCase() === getDefect[index].Name.trim().toLowerCase()) {
          values[index].Name = "";
          setDefect(values);
          Nodify('Warning!', 'warning', 'This Defect is already exist.');
          return false;
        }
      }
    }
  }


  const PageRedirect = (Func) => {
    props.history.push({ pathname: "/DefectsList", state: { message: Func } });
  }

  const ValidationPopup = (Msg) => {
    setButtonLoader(false);
    Nodify('Warning!', 'warning', Msg);
    return false;
  }

  const ResetOperation = (e) => {
    if (e === "Back") {
      props.history.push('/DefectsList');
    } else {
      window.location.reload()
    }
  }

  return (
    <div className="page-body">
      <ReactNotification />
      <div className="row">
        <div className="col-lg-12 col-sm-12 col-xs-12">
          <div className="widget flat radius-bordered">
            <div className="widget-header heading_box_style">
              <h3 className="heading_style_common">{HeaderName} Defects Name</h3>
            </div>
            <div className="widget-body">
              <div id="registration-form">
                <form>
                  {
                    getDefect.map((x, index) => (

                      <div className="row row_left10">
                        <div className="col-sm-3 divder_style">
                          <div className="form-group">
                            <span className="input-icon icon-right">
                              <input type="text"
                                className="form-control"
                                id={"Name_" + index}
                                name="Name"
                                value={x.Name}
                                onChange={(event) => handleInputChange(index, event)}
                                placeholder="Enter Defects Name"
                                maxLength="100"
                                onBlur={() => CheckDuplicate(index)}
                                style={{ border: submitted && !x.Name ? '1px solid red' : '' }}
                              />
                            </span>

                          </div>

                        </div>
                        <div className="col-sm-3 paddingstatic">
                          {
                            getDefect.length !== 1 && (
                              <div className="col-lg-1_own col-xs-12">
                                <button type="button" className="btn btn-danger"
                                  title="Delete Defect"
                                  onClick={() => handleRemoveFields(index)}>
                                  <i className="fa fa-trash-o"></i>
                                </button>
                              </div>
                            )
                          }

                          {
                            getDefect.length === (index + 1) && (
                              <div className="col-lg-1_own col-xs-12">
                                <button type="button" className="btn btn-success" title="Add Defect Name" onClick={() => handleAddFields(index)}>
                                  <i className="fa fa-plus"></i>
                                </button>
                              </div>
                            )
                          }
                        </div>
                      </div>
                    ))
                  }

                  <div className="col-sm-12">
                    <div className="buttons-preview" align="right">
                      <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                        <i className="fa fa-arrow-circle-left"></i>
                        &nbsp;Back
                      </span>
                      <span className="btn btn-danger" onClick={() => ResetOperation()}>
                        <i className="fa fa-times"></i>
                        &nbsp;Reset
                      </span>
                      <button type="button" className="btn btn-success" disabled={buttonLoader} onClick={SaveDefect} >
                        <i className="fa fa-check right"></i>&nbsp;
                        {ButtonName}

                      </button>
                    </div>
                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DefectsAdd