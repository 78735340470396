import React, { useState, useEffect } from 'react';
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import { Button } from "react-bootstrap";
import RoleService from "../../services/Master/RoleService";
import Nodify from "../Common/ReactNotification";
import {  useSelector } from "react-redux";

const RolePopup = ({
    getRoleList,
    roleCallback,
    userType,
}) => {
    const EmptyRoleInputFields =
        [{
            ID: 0,
            Name: "",
            CompBuySupTableType: userType,
        }]
    const { user: currentUser } = useSelector((state) => state.auth);

    const [buttonLoader, setButtonLoader] = useState(false);
    const [roleInputFields, setRoleInputFields] = useState(EmptyRoleInputFields);
    const [roleList, setRoleList] = useState([{ Id: 0, Name: "", CompBuySupTableType: userType, MenuDetails: [] }]);
    const [RoleSubmitted, setRoleSubmitted] = useState(false);
    const EmptyInputFields = [];
    const [inputFields, setInputFields] = useState(EmptyInputFields);
    const UserRightsValue = [...inputFields];
   // const menuList = useSelector((state) => state.userpermission.menuList);
    const menuList = useSelector((state) => state.auth.UserMenuList);
    useEffect(() => {
       
        if (menuList.item1 !== undefined) {
           ;
            if (menuList.item1.length > 0) {
                for (var i = 0; i < menuList.item1.length; i++) {
                    UserRightsValue.push({ ValidateMenu: menuList.item1[i].menuName, userType: 1, subParentUserType: 77, MenuChk: 0, RoleMenuPermissionId: 0, Menu: menuList.item1[i].menuDisplayName, MenuId: menuList.item1[i].menuID, ShowButton: 0, AddButton: 0, EditButton: 0, ViewButton: 0, DeleteButton: 0, ParentMenu: 1 });
                    for (var j = 0; j < menuList.item2.length; j++) {
                        if (menuList.item2[j].mainMenuID === menuList.item1[i].menuID) { UserRightsValue.push({ ValidateMenu: menuList.item2[j].menuName, userType: 2, subParentUserType: menuList.item2[j].mainMenuID, MenuChk: 0, RoleMenuPermissionId: 0, Menu: menuList.item2[j].menuDisplayName, MenuId: menuList.item2[j].menuID, ShowButton: 0, AddButton: 0, EditButton: 0, ViewButton: 0, DeleteButton: 0, ParentMenu: 2 }); }
                        for (var k = 0; k < menuList.item3.length; k++) {
                            if (menuList.item1[i].menuID === menuList.item2[j].mainMenuID) {
                                if (menuList.item2[j].menuID === menuList.item3[k].mainMenuID)
                                {
                                    UserRightsValue.push({ ValidateMenu: menuList.item3[k].menuName, userType: 3, subParentUserType: 0, MenuChk: 0, RoleMenuPermissionId: 0, Menu: menuList.item3[k].menuDisplayName, MenuId: menuList.item3[k].menuID, ShowButton: 0, AddButton: 0, EditButton: 0, ViewButton: 0, DeleteButton: 0, ParentMenu: 0 });
                                   // if (menuList.item3[k].menuName === "Style" || menuList.item3[k].menuName === "Route" || menuList.item3[k].menuName === "TNA") {
                                    if (menuList.item3[k].menuName === "LabDip" || menuList.item3[k].menuName === "StrikeOffList" || menuList.item3[k].menuName === "TrimList" || menuList.item3[k].menuName === "Style" || menuList.item3[k].menuName === "Route" || menuList.item3[k].menuName === "TNA" || menuList.item3[k].menuName === "TNAReport" || menuList.item3[k].menuName === "Company" || menuList.item3[k].menuName === "BuyerList" || menuList.item3[k].menuName === "Supplier") {

                                        for (var l = 0; l < menuList.item5.length; l++) {
                                            if (menuList.item3[k].menuID === menuList.item5[l].mainMenuID) {
                                                UserRightsValue.push({ ValidateMenu: menuList.item5[l].menuName, userType: 4, subParentUserType: 0, MenuChk: 0, RoleMenuPermissionId: 0, Menu: menuList.item5[l].menuDisplayName, MenuId: menuList.item5[l].menuID, ShowButton: 0, AddButton: 0, EditButton: 0, ViewButton: 0, DeleteButton: 0, ParentMenu: 4 });

                                            }
                                        }
                                    }
                                }
                            }

                        }

                    }


                }
            }
             setInputFields(UserRightsValue);
        }

    }, []);

    const CheckDuplicate = (index) => {
        const values = [...roleInputFields];
        if (getRoleList) {
            for (var i = 0; i < getRoleList.length; i++) {
                if (getRoleList[i].label.trim().toLowerCase() === values[index].Name.trim().toLowerCase()) {
                    values[index].Name = '';
                     values[index].ID=0;
                    setRoleInputFields(values);
                    setRoleList({ Createdby: currentUser.employeeinformationID, MasterInformation: values, MenuDetails: UserRightsValue});
                    Nodify('Warning!', 'warning', "This name is already exist.");
                    return false;
                }
            }
        }
    }

    const handleGroupTypeChanges = (event) => {
        const values = [...roleInputFields];
        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }
        values[0].Name = inputText;
        setRoleInputFields(values);
        setRoleList({ Createdby: currentUser.employeeinformationID, MasterInformation: values, MenuDetails: UserRightsValue });
    }

    const SaveRole = (e) => {
        setButtonLoader(true);
        e.preventDefault();
        const values = [...roleInputFields];
        if (values[0].Name.trim() === '') {
           ;
            setRoleSubmitted(false);
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Role  name is required.');
        }
        if (values[0].Name.trim()) {
           
            //let InputCommonMaster = { Createdby: currentUser.employeeinformationID, MasterInformation: roleList, MenuDetails: UserRightsValue };
            RoleService.InsertUpdateRoleGrid(roleList).then(res => {
                if (res.data.outputResult === "1") {
                    Nodify("Success!", "success", "Role added successfully.");
                    roleCallback(true);
                } else if (res.data.outputResult === "-2") {

                    Nodify('Warning!', 'warning', 'This name is already exist.');
                }
                setButtonLoader(true);
            });
        }
        else {
            setRoleSubmitted(true);
        }
    }

    return (
        <div id="registration-form">
            <Form>
                <div className="row">
                    <div className="form-group col-sm-12">
                        {/* <div className="col-sm-8"> */}
                        <div className='form-group'>
                            <label htmlFor="Name">
                                Role Name
                                <span className="text-danger">*</span>
                            </label>
                            <Input
                                placeholder={"Enter Role"}
                                id="Name"
                                name="Name"
                                type="text"
                                maxLength="50"
                                value={roleInputFields[0].Name}
                                autoComplete="off"
                                onChange={handleGroupTypeChanges}
                                className='form-control'
                                onBlur={() => CheckDuplicate(0)}
                                style={{ border: RoleSubmitted && !roleInputFields.Name ? '1px solid red' : '' }}
                                autoFocus
                            />
                        </div>
                    </div>
                </div>
                <div className="text-right">
                    <Button type="button" className="btn btn-success" onClick={SaveRole} disabled={buttonLoader}>
                        <i className="fa fa-check "></i> &nbsp; Save
                    </Button>
                </div>
            </Form>
        </div>
    )
};

export default RolePopup;