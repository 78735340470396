import {
    FLDETAILREPORT_LOADING,
    LOAD_FLDETAILREPORT_LIST,
    LOAD_FINALINSPECTION_LIST,
    LOAD_REFINALINSPECTION_LIST,
    FL_UPDATE_COMMENT_REPORT_VIEW_ALL_CHECK_BOX,
    FL_UPDATE_COMMENT_REPORT_VIEW_CHECK_BOX
}
    from './types'
import FinalInspectionService from "../services/FinalInspection/FinalinspectionService";

const FlDetailsLoadig = (isStatus) => ({
    type: FLDETAILREPORT_LOADING,
    payload: isStatus
})

export const LoadFlDetailsreport = (Params) => async (dispatch) => {
    try {
        dispatch(FlDetailsLoadig(true))
        const res = await FinalInspectionService.GetFLDetailsReportList(Params.IsProduction, Params.PoStartDate, Params.PoEndDate, Params.ExfacStartDate, Params.ExfacEndDate, Params.TNAStartDate, Params.TNAEndDate, Params.InspectionStartDate, Params.InspectionEndDate);
        dispatch({
            type: LOAD_FLDETAILREPORT_LIST,
            payload: res.data,
        });
        dispatch(FlDetailsLoadig(false))
    } catch (err) {
        console.log(err);
        dispatch(FlDetailsLoadig(false));
    }
    dispatch(FlDetailsLoadig(false));
}

export const LoadFinalInspectionList = (Params) => async (dispatch) => {
    try {
        dispatch(FlDetailsLoadig(true))
        const res = await FinalInspectionService.GetFinalInspectionList(Params.IsProduction, Params.PoStartDate, Params.PoEndDate, Params.ApStartDate, Params.ApEndDate, Params.ApprovedStatus);
        dispatch({
            type: LOAD_FINALINSPECTION_LIST,
            payload: res.data,
        });
        dispatch(FlDetailsLoadig(false))
    } catch (err) {
        console.log(err);
        dispatch(FlDetailsLoadig(false));
    }
    dispatch(FlDetailsLoadig(false));
}

export const LoadReFinalInspectionList = (Params) => async (dispatch) => {
    try {
        dispatch(FlDetailsLoadig(true))
        const res = await FinalInspectionService.GetReFinalInspectionList(Params.IsProduction, Params.PoStartDate, Params.PoEndDate, Params.ApStartDate, Params.ApEndDate, Params.ApprovedStatus);
        dispatch({
            type: LOAD_REFINALINSPECTION_LIST,
            payload: res.data,
        });
        dispatch(FlDetailsLoadig(false))
    } catch (err) {
        console.log(err);
        dispatch(FlDetailsLoadig(false));
    }
    dispatch(FlDetailsLoadig(false));
}

export const UpdateCommentsreportViewAllCheckBox = (isChecked) => async (dispatch) => {

    dispatch(FlDetailsLoadig(true));
    dispatch({
        type: FL_UPDATE_COMMENT_REPORT_VIEW_ALL_CHECK_BOX,
        payload: isChecked,
    });
    dispatch(FlDetailsLoadig(false));

};
export const UpdateCommentsreportViewCheckBox = (params) => async (dispatch) => {
    dispatch(FlDetailsLoadig(true));
    dispatch({
        type: FL_UPDATE_COMMENT_REPORT_VIEW_CHECK_BOX,
        payload: params,
    });

    dispatch(FlDetailsLoadig(false));

};
