import {
    TRIM_LOADING,
    RETRIEVE_TRIM,
    DELETE_TRIM,
} from "../actions/types";

const initialState = {
    isLoadingTrim: true,
    trimList: [],
};

const trimReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case TRIM_LOADING:
            return {
                ...state,
                isLoadingTrim: payload
            };
        case RETRIEVE_TRIM:
            return { ...state, trimList: payload };
        case DELETE_TRIM:
            const results = state.trimList.filter(c => c.trimInfoID !== payload.trimInfoID);
            return {
                ...state,
                trimList: results
            };

        default:
            return state;
    }
};
export default trimReducer;