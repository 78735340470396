import React, { Fragment, useState, useMemo, useEffect } from "react";
import Reactselect from 'react-select';
import Lightbox from "react-image-lightbox"
import Nodify from "../../../Common/ReactNotification";
import QcInspection from '../../../../services/QCInspection/QcInspection';
export const TrimandAccesssories = ({ props, rendarstate, Trimaccesscallback, Rerender }) => {
    const [gettrimValue, SettrimValue] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [getImagename, setImagename] = useState();
    const QcinpsectionId = JSON.parse(localStorage.getItem("QCInspectionID"));

    useEffect(() => {
        var Multiselecteddate = [];
        let FinalInsTrimSaveData = [];
        Multiselecteddate = props.location.state.params.multiselecteddatas.filter(x => x.isSelected === 1);
        if (props.location.state.params.TaskStatus === 2) {
            QcInspection.getAllQcInfoList(4, props.location.state.params.details.tnaid, props.location.state.params.TaskStatus === 1 ? QcinpsectionId : props.location.state.params.TNAQCInspectionID).then((res) => {
               
                if (res.data.qcppTrimDetails.length > 0) {
                    res.data.qcppTrimDetails.map((x, index) => {
                        FinalInsTrimSaveData.push({
                            TNAQcInspecPPTrimID: x.tnaqcInspecPPTrimID,
                            FindingsRemarks: x.trimFindingsRemarks,
                            TrimLogopath: x.trimLogoPath,
                            TrimName: x.trimName,
                            SkuName: x.skuName,
                            MainIndex: index,
                        })
                    })
                    SettrimValue(FinalInsTrimSaveData)
                    Trimaccesscallback(FinalInsTrimSaveData);
                } else {
                    QcInspection.GetPPMeetingTrimList(1, props.location.state.params.styleID, 0).then((res) => {
                        res.data.inputPPTrimList.map((x, index) => {
                            if (Multiselecteddate.filter(m => m.skuName === x.skuName).length > 0) {
                                FinalInsTrimSaveData.push({
                                    TNAQcInspecPPTrimID: 0,
                                    FindingsRemarks: "",
                                    TrimLogopath: x.trimLogoPath,
                                    TrimName: x.trimFinalInspe + ' (' + x.skuName + ')',
                                    SkuName: x.skuName,
                                    MainIndex: index,
                                })
                            }
                        })
                        SettrimValue(FinalInsTrimSaveData)
                        Trimaccesscallback(FinalInsTrimSaveData);
                    })
                }

            })
        } else {
            if (QcinpsectionId !== null) {
                QcInspection.getAllQcInfoList(4, props.location.state.params.details.tnaid, props.location.state.params.TaskStatus === 1 ? QcinpsectionId : props.location.state.params.TNAQCInspectionID).then((res) => {
                   
                    if (res.data.qcppTrimDetails.length > 0) {
                        res.data.qcppTrimDetails.map((x, index) => {
                            FinalInsTrimSaveData.push({
                                TNAQcInspecPPTrimID: x.tnaqcInspecPPTrimID,
                                FindingsRemarks: x.trimFindingsRemarks,
                                TrimLogopath: x.trimLogoPath,
                                TrimName: x.trimName,
                                SkuName: x.skuName,
                                MainIndex: index,
                            })
                        })
                        SettrimValue(FinalInsTrimSaveData)
                        Trimaccesscallback(FinalInsTrimSaveData);
                    } else {
                        QcInspection.GetPPMeetingTrimList(1, props.location.state.params.styleID, 0).then((res) => {
                            res.data.inputPPTrimList.map((x, index) => {
                                if (Multiselecteddate.filter(m => m.skuName === x.skuName).length > 0) {
                                    FinalInsTrimSaveData.push({
                                        TNAQcInspecPPTrimID: 0,
                                        FindingsRemarks: "",
                                        TrimLogopath: x.trimLogoPath,
                                        TrimName: x.trimFinalInspe + ' (' + x.skuName + ')',
                                        SkuName: x.skuName,
                                        MainIndex: index,
                                    })
                                }
                            })
                            SettrimValue(FinalInsTrimSaveData)
                            Trimaccesscallback(FinalInsTrimSaveData);
                        })
                    }

                })
            } else {
                QcInspection.GetPPMeetingTrimList(1, props.location.state.params.styleID, 0).then((res) => {
                    res.data.inputPPTrimList.map((x, index) => {
                        if (Multiselecteddate.filter(m => m.skuName === x.skuName).length > 0) {
                            FinalInsTrimSaveData.push({
                                TNAQcInspecPPTrimID: 0,
                                FindingsRemarks: "",
                                TrimLogopath: x.trimLogoPath,
                                TrimName: x.trimFinalInspe + ' (' + x.skuName + ')',
                                SkuName: x.skuName,
                                MainIndex: index,
                            })
                        }
                    })
                    SettrimValue(FinalInsTrimSaveData)
                    Trimaccesscallback(FinalInsTrimSaveData);
                })
            }
        }

    }, [rendarstate])

    function handleCommonResultInputChange(index, e, FeildName) {
        const values = [...gettrimValue];
        let inputText = ""
        if (e.target.value.trim() !== '') {
            inputText = e.target.value;
        }
        values[index].FindingsRemarks = inputText;
        SettrimValue(values);
        Trimaccesscallback(values);
    }

    function ViewFile(event, filename, ViewName) {
       
        if (ViewName === 'Image1') {
            setIsOpen(true);
            setImagename(filename);
        }
    }

    return (
        <table className="table table-striped table-bordered table-hover dataTable no-footer">
            <thead>
                <tr>
                    <th className="InsMainInfoDetailsCol" style={{ width: '25px' }}>Trim Image</th>
                    <th className="InsMainInfoResultsCol">Details<span className="text-danger">*</span></th>
                    <th className="InsMainInfoRemarksCol">Notes</th>
                </tr>
            </thead>
            <tbody>
                {
                    gettrimValue.map((comment, index) => (
                        <Fragment>
                            <tr key={
                                comment.index
                            }>
                                <td className="cut_text" style={{ width: '25px' }}>
                                    <img src={window.$APIBaseURL + "Images/Style/Trim/" + comment.TrimLogopath} onClick={
                                        event => ViewFile(event, comment.TrimLogopath, 'Image1')
                                    } alt="" width="50px" height="50px" /></td>
                                <td>{comment.TrimName}</td>
                                <td><input type="text" className="form-control" name={index + "_txtRemarks"}
                                    id={index + "_txtRemarks"}
                                    maxLength="500"
                                    disabled={props.location.state.params.Action === 'View' ? true : false}
                                    style={{ border: submitted && (comment.WorkmanResultID === 2 && comment.FindingsRemarks === '') ? '1px solid red' : '' }}
                                    value={comment.FindingsRemarks} autoComplete="off"
                                    onChange={(event) => handleCommonResultInputChange(index, event, 'FindingRemarks')} /></td>
                            </tr>
                        </Fragment>
                    ))
                }
                {
                    gettrimValue.length === 0 ?
                        <tr>
                            <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
                        </tr> : ''
                }
                <div> {
                    isOpen && (

                        <Lightbox mainSrc={window.$APIBaseURL + 'Images/Style/Trim/' + getImagename}
                            alt="bg image"
                            onCloseRequest={
                                () => setIsOpen(false)
                            } />
                    )
                } </div>
            </tbody>
        </table>
    )
}
