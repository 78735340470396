import React, { useState, useEffect, Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetTestingMultExFactorySKUList } from './../../../../actions/tna';
import Nodify from "../../../Common/ReactNotification";

const NewTesting = ({ props, QCfinalInspectionList, QCFinalInspectionCallback, Taskname,SubTaskInfo }) => {
    debugger
    const [selectall, setselectall] = useState(false);
    const [MultiCloseDatas, setMultiCloseDatas] = useState([]);
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
    const dispatch = useDispatch();
    const reducerState = useSelector((state) => state);
    const TNAAddFinalInspectionList = reducerState.tna.tnaAddTestinginspection !== undefined ? reducerState.tna.tnaAddTestinginspection : [];
    let StartStopInfo;
    let activeMenu;
    let activeMenuSub;
    if (menulist !== undefined && menulistSub.length > 0) {
        activeMenu = menulist.filter(i => i.menuName === 'TNA');
        if (activeMenu.length > 0) {
            activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);
            if (activeMenuSub.length > 0) {
                for (let index = 0; index < activeMenuSub.length; index++) {
                    if (activeMenuSub[index].menuName === 'TNAstartstop') {
                        StartStopInfo = activeMenuSub[index];
                    }
                }
            }
        }
    }

    useEffect(() => {
        dispatch(GetTestingMultExFactorySKUList(QCfinalInspectionList));
    }, []);

    // useMemo(() => {

    //     if(TNAAddFinalInspectionList.length > 0)
    //         {
    //           TNAAddFinalInspectionList.map((r, i) => {
    //                 SubTaskInfo.map(c => {
    //                     if (r.subTaskInfoId === c.ChildId) {
    //                         TNAAddFinalInspectionList[i].IsDepandency = c.IsDepandency;
    //                         TNAAddFinalInspectionList[i].Depandency = c.Depandency;
    //                         TNAAddFinalInspectionList[i].dependencyStartstop = c.intDependency;
    //                         TNAAddFinalInspectionList[i].dependencyStartstop = c.intDependency;
    //                     }
    //                 })
    //             })


    //     let computedComments = TNAAddFinalInspectionList;
    //     if (QCfinalInspectionList.Operation === 2) {
    //         computedComments.map((res, index) => {
    //             if (parseInt(res.strikeOffID) === parseInt(QCfinalInspectionList.PurchaseOrderSkuid)
    //                 && parseInt(res.subId) === parseInt(QCfinalInspectionList.NewID) && res.tnaId === parseInt(QCfinalInspectionList.TnaId)) {
    //                 res.isSelected = 1;
    //                 return res;
    //             } else {
    //                 return res;
    //             }
    //         })
    //     } else if (QCfinalInspectionList.Operation === 3) {
    //         computedComments.map((res, index) => {
    //             if (parseInt(res.trimInfoID) === parseInt(QCfinalInspectionList.PurchaseOrderSkuid)
    //                 && parseInt(res.subId) === parseInt(QCfinalInspectionList.NewID) && res.tnaId === parseInt(QCfinalInspectionList.TnaId)) {
    //                 res.isSelected = 1;
    //                 return res;
    //             } else {
    //                 return res;
    //             }
    //         })
    //     } else {
    //         computedComments.map((res, index) => {
    //             if (parseInt(res.purchaseOrderSkuID) === parseInt(QCfinalInspectionList.PurchaseOrderSkuid)) {
    //                 res.isSelected = 1;
    //                 return res;
    //             } else {
    //                 return res;
    //             }
    //         })
    //     }
    //     setMultiCloseDatas(computedComments)
    //     QCFinalInspectionCallback(computedComments)
    //     return computedComments;
    // }
    // }, [TNAAddFinalInspectionList]);
    useMemo(() => {
        // Default to an empty array if TNAAddFinalInspectionList is undefined or null
        const finalInspectionList = TNAAddFinalInspectionList || [];
        const subTaskInfoList = SubTaskInfo || [];
        const qcInspectionList = QCfinalInspectionList || {};
    
        if (finalInspectionList.length > 0) {
            finalInspectionList.forEach((r, i) => {
                subTaskInfoList.forEach(c => {
                    if (r.subTaskInfoId === c.ChildId) {
                        finalInspectionList[i].IsDepandency = c.IsDepandency;
                        finalInspectionList[i].Depandency = c.Depandency;
                        finalInspectionList[i].dependencyStartstop = c.intDependency;
                    }
                });
            });
    
            let computedComments = [...finalInspectionList];
    
            if (qcInspectionList.Operation === 2) {
                computedComments = computedComments.map((res) => {
                    if (parseInt(res.strikeOffID) === parseInt(qcInspectionList.PurchaseOrderSkuid)
                        && parseInt(res.subId) === parseInt(qcInspectionList.NewID) 
                        && res.tnaId === parseInt(qcInspectionList.TnaId)) {
                        res.isSelected = 1;
                    }
                    return res;
                });
            } else if (qcInspectionList.Operation === 3) {
                computedComments = computedComments.map((res) => {
                    if (parseInt(res.trimInfoID) === parseInt(qcInspectionList.PurchaseOrderSkuid)
                        && parseInt(res.subId) === parseInt(qcInspectionList.NewID) 
                        && res.tnaId === parseInt(qcInspectionList.TnaId)) {
                        res.isSelected = 1;
                    }
                    return res;
                });
            } else {
                computedComments = computedComments.map((res) => {
                    if (parseInt(res.purchaseOrderSkuID) === parseInt(qcInspectionList.PurchaseOrderSkuid)) {
                        res.isSelected = 1;
                    }
                    return res;
                });
            }
    
            setMultiCloseDatas(computedComments);
            QCFinalInspectionCallback(computedComments);
            return computedComments;
        }
    }, [TNAAddFinalInspectionList, SubTaskInfo, QCfinalInspectionList]);
    

    // const SelectAllExFactory = (event) => {
    //     debugger;
    //     let IsSelected = 0;
    //     if (event.target.checked === true) {
    //         IsSelected = 1;

    //         TNAAddFinalInspectionList.forEach(element => {
    //             if(QCfinalInspectionList.selectedDependency.trim() !== '' && IsSelected === 1 && element.dependencyStartstop === 1)
    //             {
    //                 element.isSelected = 1;
    //             }
    //             else{
    //                 element.isSelected = 0;
    //             }

    //         })

    //         // TNAAddFinalInspectionList.map(element => ({
    //         //     ...element,
    //         //     isSelected: QCfinalInspectionList.selectedDependency.trim() !== '' && IsSelected === 1 
    //         //         ? (element.dependencyStartstop === 1 ? 1 : 0) 
    //         //         : IsSelected
    //         // }));
    //         MultiSelecteddate(TNAAddFinalInspectionList)
    //     }
    //     else 
    //     {
    //         IsSelected = 0;
    //         TNAAddFinalInspectionList.forEach(element => {
    //             element.isSelected = 0;
    //         });
    //     }


    //    //  const isSelected = isChecked ? 1 : 0;

    //     // Update the list with the selected status and dependency check
    //     // const updatedList = TNAAddFinalInspectionList.map(element => ({
    //     //     ...element,
    //     //     isSelected: QCfinalInspectionList.selectedDependency.trim() !== '' && isSelected === 1 
    //     //         ? (element.dependencyStartstop === 1 ? 1 : 0) 
    //     //         : isSelected
    //     // }));



    //     setselectall(IsSelected);
    //     QCFinalInspectionCallback(TNAAddFinalInspectionList)
    // }

    const SelectAllExFactory = (event) => {
        debugger;
        const IsSelected = event.target.checked ? 1 : 0;

        // TNAAddFinalInspectionList.forEach(element => {
        //     element.isSelected = (IsSelected === 1 && QCfinalInspectionList.selectedDependency !== null && QCfinalInspectionList.selectedDependency.trim() !== '') 
        //         ? 1 
        //         : 0;
        // });

        const updatedList = TNAAddFinalInspectionList.map(element => ({
            ...element,
            isSelected: QCfinalInspectionList.selectedDependency !== null && QCfinalInspectionList.selectedDependency.trim() !== '' && IsSelected === 1
                ? (element.dependencyStartstop === 1 ? 1 : 0)
                : IsSelected
        }));

        setselectall(IsSelected);
        MultiSelecteddate(updatedList);
        QCFinalInspectionCallback(updatedList);
    };


    // const SelectAllExFactory = (event) => {
    //     debugger;
    //     let IsSelected = event.target.checked ? 1 : 0;

    //     TNAAddFinalInspectionList = TNAAddFinalInspectionList.map(element => {
    //         let newSelectedValue = IsSelected;

    //         // Check the dependency condition
    //         if (QCfinalInspectionList.selectedDependency.trim() !== '' && IsSelected === 1) {
    //             newSelectedValue = element.dependencyStartstop === 1 ? 1 : 0;
    //         }

    //         return {
    //             ...element,
    //             isSelected: newSelectedValue
    //         };
    //     });

    //     // Pass the updated list to the callback and other functions
    //     MultiSelecteddate(TNAAddFinalInspectionList);
    //     setselectall(IsSelected);
    //     QCFinalInspectionCallback(TNAAddFinalInspectionList);
    // };


    const OnSelectExFactoryRecords = (event, index) => {

        const value = [...MultiCloseDatas];
        // if (value[index].dependencyStartstop === 1) {
        if (event.target.checked === true) {
            value[index].isSelected = 1;
            //MultiSelecteddate(value)
        }
        else {
            value[index].isSelected = 0;

        }
        // }
        // else {
        //     Nodify('Warning!', 'warning', 'please select the dependency for particular task');
        // }

        let isSelectAll = 0;
        if (value) {
            isSelectAll = !!!value.find(d => d.isSelected === 0);
            isSelectAll = isSelectAll ? 1 : 0;
        }
        setselectall(isSelectAll);
        setMultiCloseDatas(value);
        QCFinalInspectionCallback(value)

    }
    const MultiSelecteddate = (value) => {
        QCFinalInspectionCallback(value);
    }

    function getSelectedDependencyMap(SelectedDependency) {
        let Dependency = true;
        // if (SelectedDependency) {
        //     let arrstatus = [];
        //     arrstatus = SelectedDependency.split(',');
        //     if (arrstatus.length > 0) {
        //         arrstatus.map(element => {
        //             let val = [];
        //             val = element.split('-');
        //             let valIndex = QCfinalInspectionList[parseInt(val[0]) - 1].statusID;
        //             if (valIndex !== 3) {
        //                 Dependency = false;
        //             }
        //         });
        //     }
        // }
        return Dependency;
    }

    return (
        <Fragment>
            <div className="inspection" style={{ height: "70vh", overflowX: "scroll", overflowY: "scroll", width: "100%" }}>

                <table className="table table-bordered">
                    <thead>
                        <tr style={{ whiteSpace: "nowrap" }}>
                            <th>Sl No</th>
                            {Taskname === 'Strike off Test' ? <th>Strike off Code </th> : Taskname === 'Trims Test' ? <th>Trim Code</th> : ''}
                            {Taskname === 'Strike off Test' ? <th> Strike off Name </th> : Taskname === 'Trims Test' ? <th>Trim Name</th> : ''}
                            {Taskname === 'Strike off Test' ? <th>Image</th> : Taskname === 'Trims Test' ? <th>Trim Image</th> : ''}
                            <th>Style Name</th>
                            {Taskname === 'Strike off Test' ? <th>TNAID</th> : <th>SKU ID</th>}
                            {Taskname === 'Strike off Test' ? <th>SubID</th> : ''}
                            <th>Style No</th>
                            <th>SKU Name</th>
                            <th>PO/ID No</th>
                            <th>PO No</th>
                            {(Taskname === 'Strike off Test' || Taskname === "Trims Test") ? '' : <th> Order Qty</th>}
                            <th>Task Holder / Follower</th>
                            {/* <th>Pre Reports</th> */}
                            {/* <th>Bal. for Inspection</th> */}
                            <th>
                                <label style={{ marginTop: '8px' }}>
                                    <input type="checkbox" name="IsSelected"

                                        onChange={event => (SelectAllExFactory(event))}
                                        checked={selectall === 1 ? true : false}
                                        className="colored-blue clearOpacity" />
                                    <span className="text"></span>
                                </label>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            MultiCloseDatas.length !== 0 && MultiCloseDatas.map((item, index) => {
                                return (
                                    <>
                                        <tr>
                                            <td>{index + 1}</td>
                                            {Taskname === 'Strike off Test' ? <td>{item.styleStrikeOffCode}</td> : Taskname === 'Trims Test' ? <td>{item.trimCode}</td> : ''}
                                            {Taskname === 'Strike off Test' ? <td> {item.styleStikeOffName}</td> : Taskname === 'Trims Test' ? <td>{item.trimName}</td> : ''}
                                            {Taskname === 'Strike off Test' ? <td><div className="col-lg-4">
                                                <span className="Actionbutton">
                                                    <img src={window.$APIBaseURL + "Images/Style/Strikeoff/" + item.strikeoffLogoPath} height="50px" width="50px"></img>
                                                </span>
                                            </div></td> : Taskname === 'Trims Test' ? <td><div className="col-lg-4">
                                                <span className="Actionbutton">
                                                    <img src={window.$APIBaseURL + "Images/Style/Trim/" + item.trimLogoPath} height="50px" width="50px"></img>
                                                </span>
                                            </div></td> : ''}
                                            <td>{item.styleName}</td>
                                            {Taskname === 'Strike off Test' || Taskname === 'Trims Test' ? <td>{item.tnaId}</td> : <td>{item.skuID}</td>}
                                            {Taskname === 'Strike off Test' ? <td>{item.subId}</td> : ''}
                                            <td>{item.styleNo}</td>
                                            <td>{item.skuName}</td>
                                            <td>{item.idPoNo}</td>
                                            <td>{item.poNo === '' ? '-' : item.poNo}</td>
                                            {/* <td >{item.factory === null ? '-' : item.factory}</td> */}
                                            {(Taskname === 'Strike off Test' || Taskname === "Trims Test") ? '' : <td>{item.orderQuantity}</td>}
                                            <td className="cut_textInTNA" title={item.taskOwnerNameList} >{item.taskOwnerNameList}</td>
                                            {/* <td className="Actionbutton">
                                                <div style={{ width: "80px" }}>
                                                    {
                                                        (item.dependencyStartstop === 1 || (!QCfinalInspectionList.selectedDependency) || getSelectedDependencyMap(QCfinalInspectionList.selectedDependency) === true || item.isSelected === 1
                                                        ) &&
                                                        <label style={{ marginTop: '8px' }}>
                                                            <input type="checkbox" name="IsSelected"
                                                                //   disabled={item.balanceQuantity === "0" ? true : false}
                                                                onChange={event => (OnSelectExFactoryRecords(event, index))}
                                                                checked={item.isSelected === 1 ? true : false}
                                                                className="colored-blue clearOpacity" />
                                                            <span className="text"></span>
                                                        </label>
                                                    }&nbsp;&nbsp;&nbsp;
                                                </div>
                                            </td> */}
                                            {/* <td className="Actionbutton">
                                              <div style={{ width: "80px" }}>
                                                  {
                                                     // (item.dependencyStartstop === 1 || (!QCfinalInspectionList.selectedDependency) || getSelectedDependencyMap(QCfinalInspectionList.selectedDependency) === true || item.isSelected === 1
                                                     (QCfinalInspectionList.selectedDependency !== '' && item.dependencyStartstop === 1 
                                                      ) ?
                                                      <label style={{ marginTop: '8px' }}>
                                                          <input type="checkbox" name="IsSelected"
                                                              //   disabled={item.balanceQuantity === "0" ? true : false}
                                                              onChange={event => (OnSelectExFactoryRecords(event, index,item))}
                                                              checked={item.isSelected === 1 ? true : false}
                                                              className="colored-blue clearOpacity" />
                                                          <span className="text"></span>
                                                      </label>
                                                      : 
                                                      QCfinalInspectionList.selectedDependency === '' ?
                                                      <label style={{ marginTop: '8px' }}>
                                                          <input type="checkbox" name="IsSelected"
                                                              //   disabled={item.balanceQuantity === "0" ? true : false}
                                                              onChange={event => (OnSelectExFactoryRecords(event, index,item))}
                                                              checked={item.isSelected === 1 ? true : false}
                                                              className="colored-blue clearOpacity" />
                                                          <span className="text"></span>
                                                      </label>
                                                      :''

                                                  } &nbsp;&nbsp;&nbsp;



                                                                                                                                                                                                                 {
                                                                                                                                                                                                                      // StartStopInfo && StartStopInfo.isAdd !== 0 &&
                                                                                                                                                                                                                      // inputField.SelectedDependency && getSelectedDependencyMap(inputField.SelectedDependency) === false && childTask.StatusID !== 3 &&
                                                                                                                                                                                                                      item.Depandency !== '' && QCfinalInspectionList.selectedDependency !== '' &&
                                                                                                                                                                                                                      <button type="button" className={(item.dependencyStartstop === 1) ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Dependency)"
                                                                                                                                                                                                                      // onClick={() => OpenDependentStartPopup(index, subIndex, childIndex, item.dependencyStartstop, props.location.state.params.tnaid, inputField.TaskID, childTask.ChildId, inputField.IsSingleTask)}
                                                                                                                                                                                                                      >
                                                                                                                                                                                                                          <i className={item.dependencyStartstop === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>

                                                                                                                                                                                                                      </button>
                                                                                                                                                                                                                  }
                                                  
                                              </div>
                                             </td> */}

                                            {/* {props.location.pathname === '/TNAEdit' ?
                                                <td className="Actionbutton">
                                                    <div style={{ width: "80px" }}>
                                                        {
                                                            // (item.dependencyStartstop === 1 || (!QCfinalInspectionList.selectedDependency) || getSelectedDependencyMap(QCfinalInspectionList.selectedDependency) === true || item.isSelected === 1
                                                            (QCfinalInspectionList.selectedDependency !== '' && item.dependencyStartstop === 1
                                                            ) ?
                                                                <label style={{ marginTop: '8px' }}>
                                                                    <input type="checkbox" name="IsSelected"
                                                                        //   disabled={item.balanceQuantity === "0" ? true : false}
                                                                        onChange={event => (OnSelectExFactoryRecords(event, index, item))}
                                                                        checked={item.isSelected === 1 ? true : false}
                                                                        className="colored-blue clearOpacity" />
                                                                    <span className="text"></span>
                                                                </label>
                                                                :
                                                                QCfinalInspectionList.selectedDependency === '' ?
                                                                    <label style={{ marginTop: '8px' }}>
                                                                        <input type="checkbox" name="IsSelected"
                                                                            //   disabled={item.balanceQuantity === "0" ? true : false}
                                                                            onChange={event => (OnSelectExFactoryRecords(event, index, item))}
                                                                            checked={item.isSelected === 1 ? true : false}
                                                                            className="colored-blue clearOpacity" />
                                                                        <span className="text"></span>
                                                                    </label>
                                                                    : ''

                                                        }  &nbsp;&nbsp;&nbsp; {


                                                            // StartStopInfo && StartStopInfo.isAdd !== 0 &&
                                                            // inputField.SelectedDependency && getSelectedDependencyMap(inputField.SelectedDependency) === false && childTask.StatusID !== 3 &&
                                                            item.Depandency !== '' && QCfinalInspectionList.selectedDependency !== '' &&
                                                            <button type="button" className={(item.dependencyStartstop === 1) ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Dependency)"
                                                            // onClick={() => OpenDependentStartPopup(index, subIndex, childIndex, item.dependencyStartstop, props.location.state.params.tnaid, inputField.TaskID, childTask.ChildId, inputField.IsSingleTask)}
                                                            >
                                                                <i className={item.dependencyStartstop === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>

                                                            </button>
                                                        }

                                                    </div>
                                                </td> :
                                                <td className="Actionbutton">
                                                    <label style={{ marginTop: '8px' }}>
                                                        <input type="checkbox"
                                                            name="IsSelected"
                                                            onChange={event => OnSelectExFactoryRecords(event, index, item)}
                                                            checked={item.isSelected === 1}
                                                            className="colored-blue clearOpacity" />
                                                        <span className="text"></span>
                                                    </label>
                                                </td>} */}



{props.location.pathname === '/TNAEdit' ?
                                <td className="Actionbutton fixed-column">
                                                                                                                                                                    { 
                                                                                                                                                                     
                                                                                                                                                                     (item.Depandency !== '' && item.dependencyStartstop === 1) ?
                                                                                                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                                                                                                <input type="checkbox" name="IsSelected"
                                                                                                                                                                                    onChange={event => (OnSelectExFactoryRecords(event, index,item))}
                                                                                                                                                                                    checked={item.isSelected === 1 ? true : false}
                                                                                                                                                                                    className="colored-blue h clearOpacity" />
                                                                                                                                                                                <span className="text"></span>
                                                                                                                                                                            </label>
                                                                                                                                                                            :
                                                                                                                                                                            item.Depandency === '' ?
                                                                                                                                                                                <label style={{ marginTop: '8px' }}>
                                                                                                                                                                                    <input type="checkbox" name="IsSelected"
                                                                                                                                                                                        onChange={event => (OnSelectExFactoryRecords(event, index,item))}
                                                                                                                                                                                        checked={item.isSelected === 1 ? true : false}
                                                                                                                                                                                        className="colored-blue hd clearOpacity" />
                                                                                                                                                                                    <span className="text"></span>
                                                                                                                                                                                </label> : ''
                                                                                                                                                                    }&nbsp;&nbsp;&nbsp;

                                                                                                                                                                    {
                                                                                                                                                                        // StartStopInfo && StartStopInfo.isAdd !== 0 &&
                                                                                                                                                                        // inputField.SelectedDependency && getSelectedDependencyMap(inputField.SelectedDependency) === false && childTask.StatusID !== 3 &&
                                                                                                                                                                        item.Depandency !== '' &&
                                                                                                                                                                        <button type="button" className={(item.dependencyStartstop === 1) ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Dependency)"
                                                                                                                                                                        // onClick={() => OpenDependentStartPopup(index, subIndex, childIndex, item.dependencyStartstop, props.location.state.params.tnaid, inputField.TaskID, childTask.ChildId, inputField.IsSingleTask)}
                                                                                                                                                                        >
                                                                                                                                                                            <i className={item.dependencyStartstop === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>

                                                                                                                                                                        </button>
                                                                                                                                                                    }

                                                                                                                                                                </td>
                                
                                :
                                <td className="Actionbutton">
                                    <label style={{ marginTop: '8px' }}>
                                        <input type="checkbox"
                                            name="IsSelected"
                                            onChange={event => OnSelectExFactoryRecords(event, index, item)}
                                            checked={item.isSelected === 1}
                                            className="colored-blue clearOpacity" />
                                        <span className="text"></span>
                                    </label>
                                   </td>}



                                        </tr>
                                    </>

                                )
                            })
                        }
                        {MultiCloseDatas.length === 0 && <tr><td colSpan="11" className='norecordfound'><span>No Records Found</span></td></tr>}
                    </tbody>
                </table>
            </div>
        </Fragment>
    )
}

export default NewTesting