import {
    ORDERLABDIP_LOADING,
    ORDERLABDIP_RELOADING,
    RETRIEVE_ORDERLABDIP,
    DELETE_ORDERLABDIP,
    RETRIEVE_ORDER_LABDIP_DETAIL_VIEW_LIST,
    CLEAR_ORDER_LABDIP_DETAIL_VIEW_SUB_ROW,
    UPDATE_ORD_PROGRAM_GRID_LIST,
    RETRIEVE_ORD_LABDIP_ALREADY_MAP_LIST,
    RETRIEVE_ORDER_LABDIP_SUBMISSION_LIST,
    CLEAR_ORDER_LABDIP_SUBMISSION_SUB_ROW,
    RETRIEVE_ORDER_LABDIP_APPROVAL_LIST,
    CLEAR_ORDER_LABDIP_APPROVAL_SUB_ROW,
    RETRIEVE_ORDER_LABDIP_APPROVAL_VIEW_LIST,
    CLEAR_ORDER_LABDIP_APPROVAL_VIEW_SUB_ROW,
    UPDATE_ORDER_LABDIP_DETAIL_VIEW_CHECK_BOX,
    NEW_TNA_ORDER_LABDIP_LIST,
    TNA_ORDER_LABDIP_HOLIDAY_LIST,
    REMOVE_TNA_ORDER_LABDIP_PANTONE_SWATCH_LIST,
    TNA_ORDER_LABDIP_SUBMISSION_AND_APPROVAL,
    OLD_TNA_TASK_OWNER_LIST,
    UPDATE_ORDER_LAPDIP_DETAIL_VIEW_ALL_CHECK_BOX
} from "../actions/types";
const OLDStatusList = [
    { value: 1, label: 'To Do', isdisabled: false },
    { value: 2, label: 'Requested', isdisabled: false },
    { value: 3, label: 'Submitted', isdisabled: false },
    { value: 4, label: 'Approved', isdisabled: false }
];
const OLDRequestStatusList = [

    { value: 1, label: 'Pending', isdisabled: false },
    { value: 2, label: 'Completed', isdisabled: false },
];

const initialState = {
    isLoadingOrderLabdip: true,
    isReLoadingOrderLabdip: true,
    orderLabdipList: [],
    orderLabdipViewDetailParams: {}, //**generalLabdipViewDetail**
    orderLabdipSubmissionParams: {},
    orderLabdipApprovalParams: {},
    orderLabdipApprovalViewParams: {},
    orderLabdipViewDetailList: [], //GeneralLabDipViewDetail-(GLDVD)
    programGridList: [],
    //  TNATrimMapList: [],
    allLabDipMapApproval: [],
    ORDLabDipMapList: [],
    orderLabdipSubmissionList: [],
    orderLabdipApprovalList: [],
    orderLabdipApprovalViewList: [],
    // GLDVDPantoneSubList: [],
    // GLDVDCriticalList: [],
    ordLabdipTNAPantoneOrSwatchList: [],
    ordLabdipTNATaskList: [],
    ordLapdibTNAHolidayList: [],
    ordLabdipTNAAddtionalTaskList: [],
    editTNAPantoneOrSwatchList: [],
    OLDStatusList: OLDStatusList,
    OLDRequestStatusList: OLDRequestStatusList,
    drpOLDTNATaskOwnerList: [],

};

const orderLabdipReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case ORDERLABDIP_LOADING:
            return {
                ...state,
                isLoadingOrderLabdip: payload
            };
        case ORDERLABDIP_RELOADING:
            return {
                ...state,
                isReLoadingOrderLabdip: payload
            };
        case RETRIEVE_ORDERLABDIP:
            return { ...state, orderLabdipList: payload };
        case DELETE_ORDERLABDIP:
            const results = state.orderLabdipList.filter(c => c.ordLabdipId !== payload.ordLabdipId);
            return {
                ...state,
                orderLabdipList: results
            };
        case RETRIEVE_ORDER_LABDIP_DETAIL_VIEW_LIST:


            if (payload.params.PantoneId === 0) {

                return {
                    ...state,
                    orderLabdipViewDetailList: payload.data.pantoneCodeList,
                    orderLabdipViewDetailParams: payload.params
                };
            } else {

                let index = payload.params.Index;
                let resultSet = payload.data;
                //GeneralLabDipViewDetail-(GLDVD)
                let OLDVDPantoneCodeList = [...state.orderLabdipViewDetailList];


                OLDVDPantoneCodeList[index].pantoneSubList = resultSet.pantoneSubList ? resultSet.pantoneSubList : [];
                OLDVDPantoneCodeList[index].criticalList = resultSet.criticalList ? resultSet.criticalList : [];
                OLDVDPantoneCodeList[index].manualCommentList = resultSet.manualCommentList ? resultSet.manualCommentList : [];
                OLDVDPantoneCodeList[index].manualImageList = resultSet.manualImageList ? resultSet.manualImageList : [];
                OLDVDPantoneCodeList[index].existingList = resultSet.existingList ? resultSet.existingList : [];



                return {
                    ...state,
                    orderLabdipViewDetailList: OLDVDPantoneCodeList,
                    orderLabdipViewDetailParams: payload.params
                };
            }
        case CLEAR_ORDER_LABDIP_DETAIL_VIEW_SUB_ROW:
            let index = payload;
            //GeneralLabDipViewDetail-(GLDVD)
            let OLDVDPantoneCodeList = [...state.orderLabdipViewDetailList];
            OLDVDPantoneCodeList[index].pantoneSubList = [];
            OLDVDPantoneCodeList[index].criticalList = [];
            OLDVDPantoneCodeList[index].manualCommentList = [];
            OLDVDPantoneCodeList[index].manualImageList = [];
            OLDVDPantoneCodeList[index].existingList = [];

            return {
                ...state,
                orderLabdipViewDetailList: OLDVDPantoneCodeList
            };
        case UPDATE_ORD_PROGRAM_GRID_LIST:

            let indexs = payload.index;
            let labdibSubmissionApprovalID = payload.labdibSubmissionApprovalID;
            let updateProgram = state.programGridList;
            updateProgram[indexs].labdibSubmissionApprovalID = labdibSubmissionApprovalID

            return {
                ...state,
                programGridList: updateProgram
            };
        case RETRIEVE_ORD_LABDIP_ALREADY_MAP_LIST:
            return { ...state, ORDLabDipMapList: payload };

        case RETRIEVE_ORDER_LABDIP_SUBMISSION_LIST:

            if (payload.params.PantoneId === 0) {

                return {
                    ...state,
                    orderLabdipSubmissionList: payload.data.pantoneCodeList,
                    orderLabdipSubmissionParams: payload.params
                };
            } else {

                let index = payload.params.Index;
                let resultSet = payload.data;
                //GeneralLabDipSubmissionDetail-(GLBSD)
                let OLBSDPantoneCodeList = [...state.orderLabdipSubmissionList];


                OLBSDPantoneCodeList[index].pantoneSubList = resultSet.orderLabdipPantoneSubInfo ? resultSet.orderLabdipPantoneSubInfo : [];
                OLBSDPantoneCodeList[index].criticalList = resultSet.criticalList ? resultSet.criticalList : [];

                return {
                    ...state,
                    orderLabdipSubmissionList: OLBSDPantoneCodeList,
                    orderLabdipSubmissionParams: payload.params
                };
            }
        case CLEAR_ORDER_LABDIP_SUBMISSION_SUB_ROW:
            let index1 = payload;
            //GeneralLabDipViewDetail-(GLDVD)
            let OLBSDPantoneCodeList = [...state.orderLabdipSubmissionList];
            OLBSDPantoneCodeList[index1].pantoneSubList = [];
            OLBSDPantoneCodeList[index1].criticalList = [];

            return {
                ...state,
                orderLabdipSubmissionList: OLBSDPantoneCodeList
            };
        case RETRIEVE_ORDER_LABDIP_APPROVAL_LIST:

            if (payload.params.PantoneId === 0) {

                return {
                    ...state,
                    orderLabdipApprovalList: payload.data.pantoneCodeList,
                    orderLabdipApprovalParams: payload.params
                };
            } else {

                let index = payload.params.Index;
                let resultSet = payload.data;
                //GeneralLabDipSubmissionDetail-(GLBSD)
                let OLBSDPantoneCodeList = [...state.orderLabdipApprovalList];

                OLBSDPantoneCodeList[index].orderLabdipMultiApprovalList = resultSet.orderLabdipMultiApprovalList ? resultSet.orderLabdipMultiApprovalList : [];
                OLBSDPantoneCodeList[index].pantoneSubList = resultSet.orderLabdipPantoneSubInfo ? resultSet.orderLabdipPantoneSubInfo : [];
                OLBSDPantoneCodeList[index].criticalList = resultSet.criticalList ? resultSet.criticalList : [];

                return {
                    ...state,
                    orderLabdipApprovalList: OLBSDPantoneCodeList,
                    orderLabdipApprovalParams: payload.params
                };
            }
        case CLEAR_ORDER_LABDIP_APPROVAL_SUB_ROW:
            let index2 = payload;
            //GeneralLabDipViewDetail-(GLDVD)
            let OLBAPantoneCodeList = [...state.orderLabdipApprovalList];
            OLBAPantoneCodeList[index2].pantoneSubList = [];
            OLBAPantoneCodeList[index2].criticalList = [];

            return {
                ...state,
                orderLabdipApprovalList: OLBAPantoneCodeList
            };
        case RETRIEVE_ORDER_LABDIP_APPROVAL_VIEW_LIST:

            if (payload.params.PantoneId === 0) {

                return {
                    ...state,
                    orderLabdipApprovalViewList: payload.data.pantoneCodeList,
                    orderLabdipApprovalViewParams: payload.params
                };
            } else {

                let index = payload.params.Index;
                let resultSet = payload.data;
                //GeneralLabDipSubmissionDetail-(GLBSD)
                let OLBSDPantoneCodeList = [...state.orderLabdipApprovalViewList];


                OLBSDPantoneCodeList[index].pantoneSubList = resultSet.orderLabdipPantoneSubInfo ? resultSet.orderLabdipPantoneSubInfo : [];
                OLBSDPantoneCodeList[index].criticalList = resultSet.criticalList ? resultSet.criticalList : [];

                return {
                    ...state,
                    orderLabdipApprovalViewList: OLBSDPantoneCodeList,
                    orderLabdipApprovalViewParams: payload.params
                };
            }
        case CLEAR_ORDER_LABDIP_APPROVAL_VIEW_SUB_ROW:
            let index3 = payload;
            //GeneralLabDipViewDetail-(GLDVD)
            let OLBAVPantoneCodeList = [...state.orderLabdipApprovalViewList];
            OLBAVPantoneCodeList[index3].pantoneSubList = [];
            OLBAVPantoneCodeList[index3].criticalList = [];

            return {
                ...state,
                orderLabdipApprovalViewList: OLBAVPantoneCodeList
            };

        case UPDATE_ORDER_LAPDIP_DETAIL_VIEW_ALL_CHECK_BOX:
            let isCheck = payload;
            let List = [...state.orderLabdipViewDetailList];
           ;
            List.map((item, Index) => {
                if (item && item.pantoneSubList) {
                    let SubList = item.pantoneSubList;
                    SubList && SubList.map((item2, SubIndex) => {
                        SubList[SubIndex].isSelected = isCheck;
                    });
                    List[Index].pantoneSubList = SubList;
                }

            });
            return {
                ...state,
                orderLapdipViewDetailList: List
            };

        case UPDATE_ORDER_LABDIP_DETAIL_VIEW_CHECK_BOX:
            let Index = payload.index;
            let SubIndex = payload.subIndex;
            let isChecked = payload.isChecked;

            let PantoneCodeList = [...state.orderLabdipViewDetailList];
            let pantoneSubList = PantoneCodeList[Index].pantoneSubList;
            pantoneSubList[SubIndex].isSelected = isChecked;
            PantoneCodeList[Index].pantoneSubList = pantoneSubList;

            return {
                ...state,
                orderLabdipViewDetailList: PantoneCodeList
            };

        case NEW_TNA_ORDER_LABDIP_LIST:
            return {
                ...state,
                ordLabdipTNAPantoneOrSwatchList: payload.item1,
                ordLabdipTNATaskList: payload.item2,
                ordLabdipTNAAddtionalTaskList: payload.item3,
            };
        case TNA_ORDER_LABDIP_HOLIDAY_LIST:
            return {
                ...state,
                ordLapdibTNAHolidayList: payload
            };

        case REMOVE_TNA_ORDER_LABDIP_PANTONE_SWATCH_LIST:

            let pantoneOrSwatchList = [...state.ordLabdipTNAPantoneOrSwatchList];
            pantoneOrSwatchList.splice(payload, 1);
            return {
                ...state,
                ordLabdipTNAPantoneOrSwatchList: pantoneOrSwatchList
            };
        case TNA_ORDER_LABDIP_SUBMISSION_AND_APPROVAL:
            return {
                ...state,
                editTNAPantoneOrSwatchList: payload
            };
        case OLD_TNA_TASK_OWNER_LIST:
            return { ...state, drpOLDTNATaskOwnerList: payload };

        default:
            return state;
    }
};

export default orderLabdipReducer;