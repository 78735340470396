import React, { useState, useEffect, useRef, Fragment } from "react";
import ReactNotification from 'react-notifications-component';
import Nodify from "../../../Common/ReactNotification"
import groupTypeService from "../../../../services/Master/GroupTypeService";
import validator from 'validator'
import SearchRetain from "../../../Common/SearchRetain";
import { useSelector } from "react-redux";

const GrouptTypeListRegister = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);
    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    if (menulist !== undefined) {
        activeMenu = menulist.filter(i => i.menuName === "GroupType");
    }
    //end enable menu
    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getGroupType, setGroupType] = useState([{ Id: 0, Name: "", }]);
    const [ExistingList, setExistingList] = useState([]);
    const [ButtonName, setButtonName] = useState("Save");
    const [HeaderName, setHeaderName] = useState("Add");

    function GroupListing(props) {
        let GroupType = [...getGroupType];
        Array.from(document.querySelectorAll("input")).forEach(input => (input.value = ""));
        if (props.location.state.params.length !== 0) {
            if (props.location.state.params.Id !== "") {
                setButtonName("Update");
                setHeaderName("Edit");
                GroupType.Id = props.location.state.params.groupId;
                GroupType.Name = validator.trim(props.location.state.params.groupName);
                GroupType.Operation = 2;
                return GroupType;
            }
        } else {
            return GroupType;
        }
    }

    useEffect(() => {
        let getGroupList = GroupListing(props);
        setGroupType(getGroupList)
        groupTypeService.GetGroupTypeList().then((response) => {
            if (response.data) {
                setExistingList(response.data);
            }
        });
    }, []);

    const handleAddFields = (index) => {
        const values = [...getGroupType];
        if (values[index].Name.trim() !== '') {
            values.push({ Id: 0, Name: "", });
            setGroupType(values);
            setSubmitted(false);
        }
        else {
            Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
            setSubmitted(true);
        }
    };

    const handleInputChange = (index, event) => {
        const values = [...getGroupType];
        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }
        values[index].Name = inputText;
        setGroupType(values);
    };

    const handleRemoveFields = index => {
        const values = [...getGroupType];
        values.splice(index, 1);
        setGroupType(values);
    };

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/GroupTypeList');
        } else {
            window.location.reload();
            // let getGroupList = GroupListing(props);
            // setGroupType(getGroupList)
        }
    }
    //Enter Error msg
    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                //SaveBuyerInfo(event);
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);
    const CheckDuplicate = (index) => {
        const values = [...getGroupType];
        if (ExistingList) {
            for (var i = 0; i < ExistingList.length; i++) {
                if (ExistingList[i].groupName.trim().toLowerCase() === values[index].Name.trim().toLowerCase()) {
                    values[index].Name = '';
                    setGroupType(values);
                    Nodify('Warning!', 'warning', "This Group Type Name is already exist.");
                    return false;
                }
            }
        }

        for (var i = 0; i < (getGroupType.length); i++) {
            if (i !== index) {
                if (getGroupType[i].Name.trim().toLowerCase() === getGroupType[index].Name.trim().toLowerCase()) {
                    values[index].Name = "";
                    setGroupType(values);
                    Nodify('Warning!', 'warning', 'This Group Type Name is already exist.');
                    return false;
                }
            }
        }
    }

    const SaveGroupTypeList = (e) => {
        setButtonLoader(true);
        e.preventDefault();
        if (getGroupType.length === 1 && getGroupType[0].Name === '') {
            setButtonLoader(false);
            setSubmitted(true);
            ValidationPopup("Please fill atleast one Group Type Name.");
        }
        else {
            for (var i = 0; i < (getGroupType.length); i++) {
                if (getGroupType[i].Name.trim() === '') {
                    Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
                    setSubmitted(true);
                    setButtonLoader(false);
                    return false;
                }
            }

            let InputCommonMaster = { Createdby: currentUser.employeeinformationID, MasterInformation: getGroupType };
            groupTypeService.InsertUpdateGroupTypeGrid(InputCommonMaster).then(res => {
                var page = "Remove";
                SearchRetain(page);

                let Func = 'Add';
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "-2") {
                    setButtonLoader(false);
                    ValidationPopup("This Group Type Name is already exist.");
                }
                else if (res.data.outputResult === "0") {
                    setButtonLoader(false);
                    ValidationPopup("Error Occured!");
                }
            });
        }
    }

    const PageRedirect = (Func) => {
        props.history.push({ pathname: "/GroupTypeList", state: { message: Func } });
    }

    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
        return false;
    }

    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">{HeaderName} Group Type Name</h3>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <form>
                                    {
                                        getGroupType.map((inputField, index) => (
                                            <Fragment key={`${inputField}~${index}`}>
                                                <div className="row row_left10">
                                                    <div className="col-sm-3 divder_style">
                                                        <div className="form-group">
                                                            <span className="input-icon icon-right">
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"Name_" + index}
                                                                    name="Name"
                                                                    placeholder="Enter Group Type Name"
                                                                    maxLength="50"
                                                                    value={inputField.Name}
                                                                    onChange={event => handleInputChange(index, event)}
                                                                    onBlur={() => CheckDuplicate(index)}
                                                                    style={{ border: submitted && !inputField.Name ? '1px solid red' : '' }}
                                                                    autoFocus
                                                                />
                                                            </span>

                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3 paddingstatic">
                                                        {
                                                            getGroupType.length !== 1 && (
                                                                <div className="col-lg-1_own col-xs-12">
                                                                    <button type="button" className="btn btn-danger"
                                                                        style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}
                                                                        title="Delete Group Type Name" onClick={() => handleRemoveFields(index)}>
                                                                        <i className="fa fa-trash-o"></i>
                                                                    </button>
                                                                </div>
                                                            )}
                                                        {
                                                            getGroupType.length === (index + 1) && (
                                                                <div className="col-lg-1_own col-xs-12">
                                                                    <button type="button" className="btn btn-success" title="Add Group Type Name" onClick={() => handleAddFields(index)}>
                                                                        <i className="fa fa-plus"></i>
                                                                    </button>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </Fragment>
                                        ))
                                    }
                                    <div className="col-sm-12">
                                        <div className="buttons-preview" align="right">
                                            <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                                <i className="fa fa-arrow-circle-left"></i>
                                                &nbsp;Back
                                            </span>
                                            <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                                <i className="fa fa-times"></i>
                                                &nbsp;Reset
                                            </span>
                                            <button type="button" className="btn btn-success" disabled={buttonLoader} onClick={SaveGroupTypeList}>
                                                <i className="fa fa-check right"></i>&nbsp;
                                                {ButtonName}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GrouptTypeListRegister;