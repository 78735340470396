import React, { useEffect, useMemo, useState,Fragment } from 'react';
import ReactNotification from 'react-notifications-component';
import { TableHeader, Pagination, Search, PageCount } from "../../../Datatable";
import { useDispatch, useSelector } from "react-redux";
import { retrieveAlignment } from '../../../../actions/alignment';
import useFullPageLoader from '../../../../hooks/useFullPageLoader';
import Nodify from '../../../Common/ReactNotification';
import SearchRetain from '../../../Common/SearchRetain';
import TestService from '../../../../services/Master/TestService';
import SweetAlertPopup from '../../../Common/SweetAlertPopup';
import { TestAlignment } from "../../../Common/PageActionNumber";
import moment from 'moment';

const AlignmentList = (props) => {
    const [getPlaceholder] = useState("Task Name");
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [expandState, setExpandState] = useState({});
    const [getAlignmentDetails, setAlignmentDetails] = useState({});
    const [expandedRows, setExpandedRows] = useState([]);
    const [getTestAlignment, setTestAlignment] = useState([]);
    const [getID, setID] = useState({ showPopupDelete: false, Params: {} });

    let activeMenu;
    const menulist = useSelector((state) => state.common.MenuList.item3);
    if (menulist !== undefined) {
        activeMenu = menulist.filter(i => i.menuName === "Alignment");
    }

    const headers = [
        { name: "#", field: "", sortable: false },
        { name: "Test Task Name", field: "taskName", sortable: true },
        { name: "Created / Modified By On Date", field: "createdDate", sortable: true },
        {
            name: "Action", field: "", sortable: false,
            display: activeMenu && activeMenu[0].isEdit === 0 &&
                activeMenu[0].isDelete === 0 ? 'none' : ''
        },
    ];

    //reducer
    const dispatch = useDispatch();
    const reducerState = useSelector((state) => state);
    const commentsTask = reducerState.AlignmentReducer.AlignmentList.item1;
    const commentsOrder = reducerState.AlignmentReducer.AlignmentList.item2;
    let isLoadingAligment = reducerState.AlignmentReducer.isLoadingAlignment;

    const commentsData = useMemo(() => {
        if (commentsTask) {
            let computedComments = commentsTask.filter((data => {
                return data.status != 0
            }));
            if (computedComments != undefined) {
                if (search) {
                    computedComments = computedComments.filter(test => test.taskName.toLowerCase().includes(search.toLowerCase()));
                }
                // if (sorting.field) {
                //     const reversed = sorting.order === "asc" ? 1 : -1;
                //     computedComments = computedComments.sort((a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]));
                // }
                if (sorting.field) {
                    const reversed = sorting.order === 'asc' ? 1 : -1;
                    computedComments = computedComments.sort((a, b) => {
                      const valueA = a[sorting.field];
                      const valueB = b[sorting.field];
                  
                      // Check if both values are numeric
                      const isNumericA = !isNaN(valueA);
                      const isNumericB = !isNaN(valueB);
                  
                      // Check if both values are valid date-time strings using moment
                      const isDateTimeA = moment(valueA, moment.ISO_8601, true).isValid();
                      const isDateTimeB = moment(valueB, moment.ISO_8601, true).isValid();
                  
                      if (isNumericA && isNumericB) {
                        // If both values are numeric, compare as numbers
                        return reversed * (parseFloat(valueA) - parseFloat(valueB));
                      } else if (isDateTimeA && isDateTimeB) {
                        // If both values are valid date-time strings, compare as dates
                        return reversed * (moment(valueA).valueOf() - moment(valueB).valueOf());
                      } else {
                        // If one or both values are non-numeric and non-valid date-time strings, compare as strings
                        return reversed * valueA.toString().localeCompare(valueB.toString());
                      }
                    });
                  }
                  
                let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
                if (records.length === 0 && currentPage != 1) {
                    setCurrentPage(currentPage - 1);
                }
                setTotalItems(computedComments.length);
                return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
            }
        }
    }, [
        commentsTask,
        currentPage,
        search,
        sorting,
        ITEMS_PER_PAGE
    ]);


    const pageRedirect = (AlignmentParams, redirect, page) => {
        localStorage.setItem('Pagination', JSON.stringify({ CurrentPage: currentPage, Page: page, PageItems: ITEMS_PER_PAGE }));
        if (redirect === 'Add') {
            props.history.push(`/AlignmentAdd`);
        }
        else if (redirect === 'Edit') {
            props.history.push(`/AlignmentEdit`, { params: AlignmentParams });
        }
        else {
            setID({ showPopupDelete: true, Params: AlignmentParams });
        }
    };

    const handleExpandRow = (id) => {
        let OrderDetails = [];
        if (commentsOrder != undefined && commentsOrder.length > 0) {
            commentsOrder.map((comment, index) => {
                if (id == comment.id) {
                    let OrderText = "";
                    let splitalignmrntOrder = comment.alignmentOrder.split(',');
                    for (var i = 0; i < splitalignmrntOrder.length; i++) {
                        let orderName = getTestAlignment.filter((order => {
                            { return order.id == splitalignmrntOrder[i] };
                        }));
                        if (i + 1 == splitalignmrntOrder.length) {
                            OrderText = OrderText + (i + 1) + '. ' + orderName[0].label;
                        }
                        else {
                            OrderText = OrderText + (i + 1) + '. ' + orderName[0].label + ", ";
                        }

                    }
                    comment.alignmentOrderText = OrderText;
                    OrderDetails.push(comment);
                }
            });
            setAlignmentDetails(OrderDetails);
            let currentExpandedRows = expandedRows;
            const isRowExpanded = currentExpandedRows.includes(id);
            let obj = {};
            isRowExpanded ? (obj[id] = false) : (obj[id] = true);
            setExpandState(obj);
            currentExpandedRows = [];
            const newExpandedRows = isRowExpanded ?
                currentExpandedRows.filter(id => id !== id) :
                currentExpandedRows.concat(id);
            setExpandedRows(newExpandedRows);
        }
    }

    const Delcallback = (value) => {
        dispatch(retrieveAlignment());
        setID({ showPopupDelete: false, Params: [] });
    }


    useEffect(() => {
        dispatch(retrieveAlignment());
        const state1 = props.location.state;
        if (state1 !== undefined && state1 != null) {
            let Msg;
            let Type = 'success';
            let title = 'Success!';
            if (state1.message === 'Edit') {
                Msg = 'Alignment updated successfully.';
            } else if (state1.message === 'Add') {
                Msg = 'Alignment added successfully.';
            } else {
                Msg = 'Error occured';
                Type = 'warning';
                title = 'Error!';
            }
            window.history.replaceState(null, '')
            Nodify(title, Type, Msg);
        }
        var page = getPlaceholder;
        var setConstraint = SearchRetain(page);
        if (setConstraint !== undefined) {
            setCurrentPage(setConstraint.CurrentPage);
            setITEMS_PER_PAGE(setConstraint.PageItems);
        }
    }, [dispatch]);

    useEffect(() => {
        if (!isLoadingAligment) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingAligment]);

    useEffect(() => {
        TestService.GetTestNameList(1, 0, 0).then((res => {
            let TestAlignment = []
            if (res.data) {
                res.data.testNameList.map((x, index) => {
                    TestAlignment.push({
                        value: x.testNameID,
                        id: x.testNameID,
                        label: x.testName,
                        name: x.testName,
                        TaskID: parseInt(x.taskID)
                    })
                })
                setTestAlignment(TestAlignment);
            }
        }))
    }, [])

    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className="widget-header">
                    <span className="widget-caption">Alignment List</span>
                </div>
                <div className="widget-body" style={
                    { paddingBottom: '4%' }
                }>
                    <div className="dataTables_wrapper form-inline no-footer">
                        <div className="table-toolbar pull-left">
                            <Search
                                onSearch={
                                    value => {
                                        setSearch(value);
                                        setCurrentPage(1);
                                    }
                                }
                                placeholder={getPlaceholder} props={props} />
                        </div>
                        <div className="table-toolbar pull-right"  style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}>
                            &nbsp;
                            <span
                                className="btn btn-primary" style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}
                                onClick={() => pageRedirect("", 'Add')} title="Add Alignment">
                                <i className="fa fa-plus"></i>&nbsp;Add
                            </span>
                        </div>
                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                            <TableHeader headers={headers} onSorting={(field, order) => setSorting({ field, order })} />
                            <tbody>
                                {
                                    commentsData != undefined && commentsData.map((comment,i) => (
                                        <Fragment key={i}>
                                        <>
                                            <tr>
                                                <td style={{width:'25px'}}>  
                                                   { i + 1}
                                                </td>
                                                <td>
                                                    <i className={
                                                        expandState[comment.id] ?
                                                            'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                    } onClick={event => handleExpandRow(comment.id)}></i>&nbsp;{comment.taskName}
                                                </td>
                                                <td>{comment.createdByName != null && comment.createdByName != undefined ? comment.createdByName + " On " + comment.createdDate : ""}</td>
                                                <td style={{
                                                    display: activeMenu && activeMenu[0].isEdit === 0 &&
                                                        activeMenu[0].isDelete === 0 ? 'none' : ''
                                                }}>
                                                    <span style={{ display: activeMenu && activeMenu[0].isEdit === 1 ? '' : 'none' }}
                                                        onClick={() => pageRedirect(comment, 'Edit', getPlaceholder)}
                                                        title='Edit Alignment' className="btn btn-info btn-xs edit">
                                                        <i className='fa fa-edit'></i>
                                                    </span>&nbsp;
                                                    <span title='Delete Alignment' onClick={() => pageRedirect(comment, '', getPlaceholder)}
                                                        style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}
                                                        className="btn btn-danger btn-xs delete">
                                                        <i className='fa fa-trash'></i>
                                                    </span>&nbsp;
                                                </td>
                                            </tr>
                                            {
                                                expandedRows[0] === comment.id ? <tr>
                                                    <td colSpan={3}>
                                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                            <thead>
                                                                <tr>
                                                                    <th>Alignment Order</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    getAlignmentDetails.length > 0 && getAlignmentDetails.map(item => {
                                                                        return (
                                                                            <tr>
                                                                                <td>{item.alignmentOrderText}</td>

                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr> : ""
                                            }
                                        </>
                                        </Fragment>
                                    ))
                                }
                                {
                                    totalItems === 0 ?
                                        <tr>
                                            <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
                                        </tr> : ''
                                }
                            </tbody>
                        </table>
                        <PageCount onPageCount={
                            value => {
                                setITEMS_PER_PAGE(value);
                                setCurrentPage(1);
                            }
                        } />
                        <Pagination total={totalItems}
                            itemsPerPage={
                                parseInt(ITEMS_PER_PAGE)
                            }
                            currentPage={currentPage}
                            onPageChange={
                                page => setCurrentPage(page)
                            } />
                    </div>
                </div>
                {
                    getID.showPopupDelete ? <SweetAlertPopup data={
                        getID.Params
                    }
                        deleteCallback={Delcallback}
                        showpopup={true}
                        pageActionId={TestAlignment}
                        Msg={"Alignment deleted successfully."} /> : null
                }
            </div>
            {loader}
        </>
    )
}
export default AlignmentList;