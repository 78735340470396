import axios from "axios";
import authHeader from "../auth-header";

class TemplateServices {

    GetItemMastersList() {//item master details
        return axios.get(window.$APIBaseURL + "Master/GetItemMastersList?ItemID=0", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadTemplateItemList(TemplateInfoID) {//Loading Dropdown details
        return axios.get(window.$APIBaseURL + "Master/LoadTemplateItemList?TemplateInfoID=" + TemplateInfoID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GettemplateList(Operation, Id) {//template details
        return axios.get(window.$APIBaseURL + "Master/GettemplateList?Operation=" + Operation + '&TemplateInfoID=' + Id, {
            headers: {
                authorization: authHeader()
            }
        });
    }


    InsertUpdatetemplate = async (Params) => {
        return await axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdatetemplate',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    // GetTemplateAndItemList(Operation, Id) {//dropdown and template details
    //  return axios.get(window.$APIBaseURL + "Master/GetTemplateAndItemList?Operation=" + Operation + '&Templateid=' + Id, {
    //   headers: {
    //    authorization: authHeader()
    //   }
    //  });
    // }

    UpdateTemplate = async (Params) => { //Update Template

        return await axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/UpdateTemplate',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
}


export default new TemplateServices()