
import React, { useState, useEffect, useMemo, Fragment } from "react";
import ReactNotification from 'react-notifications-component';
import Nodify from "../../Common/ReactNotification"
import { useSelector } from "react-redux";
import AqlService from "../../../services/Master/AqlService";
const AddAQLLsit = (props) => {

    const [inputfeild, setinputfeild] = useState([{
        SNo: 0, InspectionLevel: "", LotSizeFrom: '', LotSizeTo: '', SampleSize: '', AQL: '', Accept: ''
    }]);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [ExistingList, setExistingList] = useState([]);
    const { user: currentUser } = useSelector((state) => state.auth);
    const values = [...inputfeild];
    useEffect(() => {
        AqlService.getAllAqlList(0).then((res) => {
           
            if (res.data) {
                setExistingList(res.data)
            }
        })
    }, [])

    function handleInputChange(index, e, FieldName) {
        const reg = new RegExp('^[0-9]+$');
        var Aqlreg = new RegExp(/^\d*\.?\d*$/);
        let inputText = '';
        let inputAql = '';
        if (e && FieldName !== "AQL") {
            if (e.target.value.match(reg)) {
                inputText = e.target.value.trim();
            } else {
                inputText = "";
            }
        }
        if (e && FieldName === "AQL") {
            if (e.target.value.match(Aqlreg)) {
                inputAql = e.target.value.trim();
            } else {
                inputAql = "";
            }
        }
        if (FieldName === "InspectionLevel") {
            values[index].InspectionLevel = e.target.value.trim()
        }
        else if (FieldName === "LotSizeFrom") {
            values[index].LotSizeFrom = inputText
        }
        else if (FieldName === "LotSizeTo") {
            values[index].LotSizeTo = inputText
        }
        else if (FieldName === "SampleSize") {
            values[index].SampleSize = inputText
        }
        else if (FieldName === "AQL") {
            values[index].AQL = inputAql
        }
        else {
            values[index].Accept = inputText
        }
        setinputfeild(values);
    }


    function CheckDuplicate(index, FieldName) {
       
        let Existing = !!ExistingList.find(x => x.inspectionLevel.trim().toLowerCase() === values[index].InspectionLevel.trim().toLowerCase() && x.lotSizeFromm.trim().toLowerCase() === values[index].LotSizeFrom.trim().toLowerCase()
            && x.lotSizeTo.trim().toLowerCase() === values[index].LotSizeTo.trim().toLowerCase() && x.aql.trim().toLowerCase() === values[index].AQL.trim().toLowerCase()
            && x.aql.trim().toLowerCase() === values[index].AQL.trim().toLowerCase() && x.accept.trim().toLowerCase() === values[index].Accept.trim().toLowerCase())

        if (Existing) {
            values[index].LotSizeFrom = "";
            values[index].LotSizeTo = "";
            values[index].SampleSize = "";
            values[index].AQL = "";
            values[index].Accept = "";
            setinputfeild(values);
            Nodify('Warning!', 'warning', 'These details are already exists, please enter differently');
            return false;
        }

        for (var j = 0; j < (inputfeild.length); j++) {
            if (j !== index) {
                if (values[j].InspectionLevel.trim().toLowerCase() === values[index].InspectionLevel.trim().toLowerCase() &&
                    values[j].LotSizeFrom.trim() === values[index].LotSizeFrom.trim()
                    && values[j].LotSizeTo.trim() === values[index].LotSizeTo.trim()
                    && values[j].SampleSize.trim() === values[index].SampleSize.trim() &&
                    values[j].AQL.trim() === values[index].AQL.trim() && values[j].Accept.trim() === values[index].Accept.trim()) {
                    values[index].LotSizeFrom = "";
                    values[index].LotSizeTo = "";
                    values[index].SampleSize = "";
                    values[index].AQL = "";
                    values[index].Accept = "";
                    setinputfeild(values);
                    Nodify('Warning!', 'warning', 'These details are already exists, please enter differently');
                    return false;
                }

            }
        }
    }

    function handleAddFields(e, index) {
        const values = [...inputfeild];
        if (values[index].InspectionLevel !== "" && values[index].LotSizeFrom !== '' && values[index].LotSizeTo !== '' &&
            values[index].SampleSize !== '' && values[index].AQL !== '' && values[index].Accept !== '') {
            values.push({ SNo: 0, InspectionLevel: "", LotSizeFrom: '', LotSizeTo: '', SampleSize: '', AQL: '', Accept: '' });
            setinputfeild(values);
            setSubmitted(false);
        }
        else {
            Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
            setSubmitted(true);
        }
    }

    function handleRemoveFields(index) {
        values.splice(index, 1);
        setinputfeild(values);
    }

    function ResetOperation(e) {
        if (e === "Back") {
            props.history.push('/AQLList');
        } else {
            window.location.reload();
        }
    }
    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/AQLList",
            state: {
                message: Func
            }
        });
    }
    function SaveAqlList(e) {
       
        let isvalid = true;
        setButtonLoader(true);
        e.preventDefault();
        for (var i = 0; i < values.length; i++) {
            if (values[i].InspectionLevel === "" || values[i].LotSizeFrom === '' || values[i].LotSizeTo === '' ||
                values[i].SampleSize === '' | values[i].AQL === '' || values[i].Accept === '') {
                setButtonLoader(false);
                setSubmitted(true);
                isvalid = false;
                Nodify('Warning!', 'warning', 'Please fill This mandantory(*) fields.');
                return false;
            }
        }
        if (isvalid) {
            let GetAqllist = { Operation: 1, AQLInformation: [], Createdby: currentUser.employeeinformationID }
            inputfeild.forEach((Info, i) => {
                GetAqllist.AQLInformation.push({
                    SNo: 0,
                    InspectionLevel: Info.InspectionLevel,
                    LotSizeFrom: Info.LotSizeFrom,
                    LotSizeTo: Info.LotSizeTo,
                    SampleSize: Info.SampleSize,
                    AQL: Info.AQL,
                    Accept: Info.Accept
                })

            })
            AqlService.InsertUpdateAqlGrd(GetAqllist).then((res) => {
                let Func = 'Add';
                if (res.data.outputResult === '1') {
                    Func = 'Add';
                    PageRedirect(Func);
                }
                else if (res.data.outputResult === "0") {
                    setButtonLoader(false);
                    Nodify('Warning!', 'warning', "Error Occured!");
                }
            })
        }
    }

    return (
        <>
            <div className="page-body">
                <ReactNotification />

                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <div className="widget flat radius-bordered">
                            <div className="widget-header heading_box_style">
                                <h3 className="heading_style_common">Add AQL</h3>
                                <div className="buttons-preview" align="right">
                                    <span className="btn btn-primary"
                                        onClick={
                                            () => ResetOperation("Back")
                                        }>
                                        <i className="fa fa-arrow-circle-left"></i>
                                        &nbsp;Back</span>
                                    <span className="btn btn-danger"
                                        onClick={
                                            () => ResetOperation()
                                        }>
                                        <i className="fa fa-times"></i>
                                        &nbsp;Reset</span>
                                    <button type="button" className="btn btn-success" disabled={buttonLoader}
                                        onClick={SaveAqlList}>

                                        <i className="fa fa-check right"></i>
                                        &nbsp;Save</button>
                                </div>
                            </div>
                            <div className="widget-body">
                                <div id="registration-form">
                                    <form>
                                        <div >
                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: "15%" }}>Inspection Level<span className="text-danger">*</span></th>
                                                        <th style={{ width: "15%" }}>Lot Size From<span className="text-danger">*</span></th>
                                                        <th style={{ width: "15%" }}>Lot Size To<span className="text-danger">*</span></th>
                                                        <th style={{ width: "15%" }}>Sample Size<span className="text-danger">*</span></th>
                                                        <th style={{ width: "15%" }}>AQL<span className="text-danger">*</span></th>
                                                        <th style={{ width: "15%" }}>Accept<span className="text-danger">*</span></th>
                                                        <th style={{ width: "15%" }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {inputfeild.map((inputField, index) => (
                                                        <tr>
                                                            <td>
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"InspectionLevel"}
                                                                    name="Inspection Level"
                                                                    placeholder="Enter Inspection Level"
                                                                    maxLength="50"
                                                                    autoComplete="off"
                                                                    value={inputField.InspectionLevel}
                                                                    onChange={event => handleInputChange(index, event, "InspectionLevel")}
                                                                    onBlur={() => CheckDuplicate(index, 'InspectionLevel')}
                                                                    style={{ border: submitted && inputField.InspectionLevel === "" ? '1px solid red' : '' }}
                                                                    autoFocus
                                                                />
                                                            </td>
                                                            <td>
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"LotSizeFrom"}
                                                                    name="Lot Size From"
                                                                    placeholder="Enter Lot Size From"
                                                                    maxLength="50"
                                                                    autoComplete="off"
                                                                    value={inputField.LotSizeFrom}
                                                                    onChange={event => handleInputChange(index, event, "LotSizeFrom")}
                                                                    onBlur={() => CheckDuplicate(index, 'LotSizeFrom')}
                                                                    style={{ border: submitted && inputField.LotSizeFrom === "" ? '1px solid red' : '' }}
                                                                    autoFocus
                                                                />
                                                            </td>
                                                            <td>
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"LotSizeTo"}
                                                                    name="Lot Size To"
                                                                    placeholder="Enter Lot Size To"
                                                                    maxLength="50"
                                                                    autoComplete="off"
                                                                    value={inputField.LotSizeTo}
                                                                    onChange={event => handleInputChange(index, event, "LotSizeTo")}
                                                                    onBlur={() => CheckDuplicate(index, 'LotSizeTo')}
                                                                    style={{ border: submitted && inputField.LotSizeTo === "" ? '1px solid red' : '' }}
                                                                    autoFocus
                                                                />
                                                            </td>
                                                            <td>
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"SampleSize"}
                                                                    name="Sample Size"
                                                                    placeholder="Enter Sample Size"
                                                                    maxLength="50"
                                                                    autoComplete="off"
                                                                    value={inputField.SampleSize}
                                                                    onChange={event => handleInputChange(index, event, "SampleSize")}
                                                                    onBlur={() => CheckDuplicate(index, 'SampleSize')}
                                                                    style={{ border: submitted && inputField.SampleSize === "" ? '1px solid red' : '' }}
                                                                    autoFocus
                                                                />
                                                            </td>
                                                            <td>
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"AQL"}
                                                                    name="AQL"
                                                                    placeholder="Enter AQL"
                                                                    maxLength="50"
                                                                    autoComplete="off"
                                                                    value={inputField.AQL}
                                                                    onChange={event => handleInputChange(index, event, "AQL")}
                                                                    onBlur={() => CheckDuplicate(index, 'AQL')}
                                                                    style={{ border: submitted && inputField.AQL === "" ? '1px solid red' : '' }}
                                                                    autoFocus
                                                                />
                                                            </td>
                                                            <td>
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"Accept"}
                                                                    name="Accept"
                                                                    placeholder="Enter Accept"
                                                                    maxLength="50"
                                                                    autoComplete="off"
                                                                    value={inputField.Accept}
                                                                    onChange={event => handleInputChange(index, event, "Accept")}
                                                                    onBlur={() => CheckDuplicate(index, 'Accept')}
                                                                    style={{ border: submitted && inputField.Accept === "" ? '1px solid red' : '' }}
                                                                    autoFocus
                                                                />
                                                            </td>
                                                            <td>
                                                                {index == (inputfeild.length - 1) && <button type="button" className="btn  btn-xs  btn-success" title="Add Additional Task"
                                                                    onClick={(event) => handleAddFields(event, index)}>
                                                                    <i className="fa fa-plus"></i>
                                                                </button>}
                                                                &nbsp;
                                                                {inputfeild.length !== 1 && (
                                                                    <button type="button" className="btn  btn-xs  btn-danger" title="Delete Additional Task"
                                                                        onClick={() => handleRemoveFields(index)}>
                                                                        <i className="fa fa-trash-o"></i>
                                                                    </button>
                                                                )}

                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>
                            <span className="btn btn-primary"
                                onClick={
                                    () => ResetOperation("Back")
                                }>
                                <i className="fa fa-arrow-circle-left"></i>
                                &nbsp;Back</span>
                            <span className="btn btn-danger"
                                onClick={
                                    () => ResetOperation()
                                }>
                                <i className="fa fa-times"></i>
                                &nbsp;Reset</span>
                            <button type="button" className="btn btn-success" disabled={buttonLoader}
                                onClick={SaveAqlList}>

                                <i className="fa fa-check right"></i>
                                &nbsp;Save</button>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}
export default AddAQLLsit