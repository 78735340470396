import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";

import 'react-notifications-component/dist/theme.css';
import Form from "react-validation/build/form";
import Nodify from "../../Common/ReactNotification";
import Reactselect from 'react-select';
import CategoryService from "../../../services/Master/CategoryService";
import MeasurementService from "../../../services/Style/measurementService";
import $ from "jquery";
import CommonLoader from "../../Common/CommonLoader";
import readXlsxFile from 'read-excel-file'
import { useSelector } from "react-redux";
import MeasurementPointPopup from "../../quickMaster/_MeasurementPoint";
import { Modal } from "react-bootstrap";
import Select from "react-validation/build/select";

import Input from "react-validation/build/input";

const Measurement = ({ props, MeasurementList, setSizeListmeasurement, MeasurementCallback, IsValidationCallback, IsValidationChildCallback, MeasurementPageCallback, MeasurementInfo,
    Buttonview, MeasurementInfoQuick }) => {
    const emptyFields = [
        {
            StyleMeasurementID: 0,
            MeasurementSpec: '',
            MeasurementGrandingSize: '',
            IsDeleted: 0,
            MeasurementOperation: 1,
            Category: [{
                StyleMeasurementCategoryID: 0,
                PointsUOM: 1,
                MeasurementCategoryID: "0",
                IsDeleted: 0,
                IndexName: '0',
                MeasuremnentSpecImportName: '',
                MeasuremnentSpecImportPath: '',
                Point: [{
                    MeasurementPoints: '',
                    MeasurementGranding: '',
                    TolerancePlus: '',
                    ToleranceMinus: '',
                    GradingSizes: '',
                    GradingSizesId: '',
                    GradingSizesValue: '',
                    arrGradingSizes: [],
                    Remark: '',
                    IndexName: '0',
                    IsDeleted: 0,
                }]
            }],
        }
    ];
    const [inputFields, setInputFields] = useState(emptyFields);
    const [getGradingSize, setGradingSize] = useState([]);
    const [getCategoryType, setCategoryType] = useState([]);
    const [getUOMType, setUOMType] = useState([]);
    const [getMeasurement, setMeasurement] = useState([]);
    const [getSizeDisabled, setSizeDisabled] = useState([]);
    const [getEdit, setEdit] = useState();
    const [getCommonLoader, setCommonLoader] = useState();
    const [getReload, setReload] = useState(1);
    const [getReload1, setReload1] = useState(1);
    const [getOverflow, setOverflow] = useState("auto");
    const [getShowUpload, setShowUpload] = useState(false);
    //#region Popup Initialization
    const [getQuickMasterName, setQuickMasterName] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [childSubmitted, childSetSubmitted] = useState(false);
    const [getHeader, setHeader] = useState("Add");
    const [getLoadFunction, setLoadFunction] = useState(0);



    const history = useHistory();
    const selector = useSelector((state) => state);
    const isSideBarEnable = selector.auth.isSideBarEnable;
    const isFullSideBarEnable = selector.auth.isFullSideBarEnable;
    // let BrowserWidth = isFullSideBarEnable
    //     ? isSideBarEnable
    //         ? $(window).width() - 130
    //         : $(window).width() + 50
    //     : $(window).width() + 100;

    let BrowserWidth = isFullSideBarEnable
        ? isSideBarEnable
            ? $(window).width() - 160
            : $(window).width() + 50
        : $(window).width() + 100;

    let styles1 = {
        control: styles1 => ({ ...styles1, width: "350px" }),
    }

    let styles2 = {
        control: styles2 => ({ ...styles2, width: "350px", border: '1px solid red' }),
    }

    // const styles1 = {
    //     menu: (base) => ({
    //         ...base,
    //         zIndex: '5',
    //         width: "350px",
    //     }),
    // };

    const [Size, SetSize] = useState("");


    const values = [...inputFields];

    useEffect(() => {

        setCommonLoader(true);
        let categorytypelist;
        CategoryService.LoadCategoryTypeList().then((response) => {
            if (response.data) {
                setCategoryType(response.data);
                categorytypelist = response.data;
            }
        }).catch(() => { });

        CategoryService.LoadUOMTypeList().then((response) => {
            if (response.data) { setUOMType(response.data); }
        }).catch(() => { });
        let pointlist;
        MeasurementService.LoadMeasurement().then((response) => {
            if (response.data) {
                setMeasurement(response.data);
                pointlist = response.data;
            }
        }).catch(() => { });
        let Count = 0;

        setSizeListmeasurement && setSizeListmeasurement.forEach(element => {
            if (Count === 0) {
                Count++;
                values[0].Category[0].Point[0] && values[0].Category[0].Point[0].arrGradingSizes.push({
                    [element.label]: ''
                })
            }
            else {
                values[0].Category[0].Point[0].arrGradingSizes[0][element.label] = '';
            }
        });
        if (MeasurementList !== undefined && MeasurementList.styleMeasurementID !== undefined) {
           ;
            setEdit(true);
            MeasurementService.GetAllStyleMeasurementCatPointList(MeasurementList.styleMeasurementID).then((response) => {
                if (response.data) {
                    if (response.data.specList.length > 0) {
                        setShowUpload(true);
                        values[0].StyleMeasurementID = response.data.specList[0].styleMeasurementID;
                        if (MeasurementList.measurementOpreation === 1) { values[0].MeasurementSpec = ""; }
                        else { values[0].MeasurementSpec = response.data.specList[0].measurementSpecReason; }
                        values[0].MeasurementGrandingSize = response.data.specList[0].measurementGrandingSize;
                        values[0].MeasurementOperation = MeasurementList.measurementOpreation;
                        values[0].Category = [];
                        if (setSizeListmeasurement.length !== 0) {
                            if (response.data.specList[0].measurementGrandingSize !== 0) {
                                let sizename = setSizeListmeasurement.filter(x => x.value === response.data.specList[0].measurementGrandingSize);
                                if(sizename.length > 0 )
                                {
                                    SetSize(sizename[0].label);

                                }

                               // SetSize(sizename.length > 0 ?sizename[0].label :  ) 
                               
                            }

                        }
                        setSizeDisabled({ Disabled: "disabled" });

                    }

                    if (response.data.categoryList.length > 0) {
                        response.data.categoryList.forEach((category, catIndex) => {
                            //Revise
                            category.styleMeasurementCategoryID = MeasurementList.measurementOpreation === 1 ? 0 : category.styleMeasurementCategoryID;
                            values[0].Category.push({
                                StyleMeasurementCategoryID: category.styleMeasurementCategoryID,
                                PointsUOM: category.pointsUom,
                                MeasurementCategoryID: category.measurementCategoryID,
                                StyleMeasurementID: category.styleMeasurementID,
                                IsDeleted: 0,
                                IndexName: category.indexName,
                                MeasuremnentSpecImportName: category.measuremnentSpecImportName,
                                MeasuremnentSpecImportPath: category.measuremnentSpecImportPath,
                                Point: []
                            });
                            let pointList = response.data.pointList.filter(x => x.indexName === category.indexName);
                            pointList.forEach((point, pointIndex) => {
                                //Revise
                                point.styleMeasurementCategoryPointsID = MeasurementList.measurementOpreation === 1 ? 0 : point.styleMeasurementCategoryPointsID;
                                values[0].Category[catIndex].Point.push({
                                    StyleMeasurementCategoryPointsID: point.styleMeasurementCategoryPointsID,
                                    StyleMeasurementID: point.styleMeasurementID,
                                    MeasurementPoints: point.measurementPoints,
                                    MeasurementGranding: point.measurementGranding,
                                    TolerancePlus: point.tolerancePlus,
                                    ToleranceMinus: point.toleranceMinus,
                                    GradingSizes: point.gradingSizes,
                                    GradingSizesId: point.gradingSizesId,
                                    GradingSizesValue: point.gradingSizesValue,
                                    arrGradingSizes: [],
                                    Remark: point.remarks,
                                    IndexName: point.indexName,
                                    IsDeleted: 0,
                                });
                                let arrGradingSizes = point.gradingSizes.split(",");
                                let arrGradingSizesId = point.gradingSizesId.split(",");
                                let arrGradingSizesValue = point.gradingSizesValue.split(",");
                                let arrcount = 0;
                                arrGradingSizes.forEach((val, valIndex) => {
                                    if (arrcount === 0) {
                                        arrcount++;
                                        values[0].Category[catIndex].Point[pointIndex].arrGradingSizes.push({
                                            [val]: arrGradingSizesValue[valIndex]
                                        });
                                    }
                                    else {
                                        values[0].Category[catIndex].Point[pointIndex].arrGradingSizes[0][val] = arrGradingSizesValue[valIndex];
                                    }
                                });
                            });
                        });
                        setInputFields(values);
                        MeasurementCallback(values);
                        setGradingSize(setSizeListmeasurement);

                    }
                }


                categorytypelist.forEach((data, i) => {
                    categorytypelist[i].isdisabled = false;

                })
                values[0].Category.forEach((data, i) => {
                    var SelectedIndex = categorytypelist.findIndex(x => x.id === parseInt(data.MeasurementCategoryID));
                    if (SelectedIndex !== -1) {
                        categorytypelist[SelectedIndex].isdisabled = true;
                    }
                })

                getCategoryType = categorytypelist;

                pointlist.forEach((data, i) => {
                    pointlist[i].isdisabled = false;
                })
                values[0].Category.forEach((data, i) => {
                    values[0].Category[i].Point.forEach((data, j) => {
                        var SelectedIndex = pointlist.findIndex(x => x.value === data.MeasurementPoints);
                        if (SelectedIndex !== -1) {
                            pointlist[SelectedIndex].isdisabled = true;
                        }
                    })
                })

                getMeasurement = pointlist;

            }).catch(() => { }).finally(() => {
                setCommonLoader(false);
            });
        }
        else {
            for (let i = 1; i <= 9; i++) {
                values[0].Category[0].Point.push(
                    {
                        MeasurementPoints: '',
                        MeasurementGranding: '',
                        TolerancePlus: '',
                        ToleranceMinus: '',
                        GradingSizes: '',
                        GradingSizesId: '',
                        GradingSizesValue: '',
                        arrGradingSizes: [],
                        Remark: '',
                        IndexName: '0',
                        // PreferenceId: i+1,
                        IsDeleted: 0,
                    }
                )
                let Count = 0;
                setSizeListmeasurement.forEach(element => {
                    if (Count === 0) {
                        Count++;
                        values[0].Category[0].Point[i].arrGradingSizes.push({
                            [element.label]: ''
                        })
                    }
                    else {
                        values[0].Category[0].Point[i].arrGradingSizes[0][element.label] = '';
                    }
                });
            }

            setInputFields(values);
            MeasurementCallback(values);
            setGradingSize(setSizeListmeasurement);
            setCommonLoader(false);

        }
        // setLoadFunction(1);

    }, []);

    useEffect(() => {
        getCategoryType.forEach((data, i) => {
            getCategoryType[i].isdisabled = false;

        })
        values[0].Category.forEach((data, i) => {
            var SelectedIndex = getCategoryType.findIndex(x => x.id === parseInt(data.MeasurementCategoryID));
            if (SelectedIndex !== -1) {
                getCategoryType[SelectedIndex].isdisabled = true;

            }
        })

        // getCategoryType = categorytypelist;

        getMeasurement.forEach((data, i) => {
            getMeasurement[i].isdisabled = false;
        })
        values[0].Category.forEach((data, i) => {
            values[0].Category[i].Point.forEach((data, j) => {
                var SelectedIndex = getMeasurement.findIndex(x => x.value === data.MeasurementPoints);
                if (SelectedIndex !== -1) {
                    getMeasurement[SelectedIndex].isdisabled = true;
                }
            })
        })

        //  getMeasurement = pointlist;
    }, []);

    useEffect(() => {
        setSubmitted(IsValidationCallback);
    }, [IsValidationCallback === true]);

    useEffect(() => {
        childSetSubmitted(IsValidationChildCallback);
    }, [IsValidationChildCallback === true]);


    let DynamicClassName = "col-lg-12 col-sm-12 col-xs-12";

    let IsValid = true;
    const handleAddFields = (index, catIndex) => {
        if (values[index].Category[catIndex].MeasurementCategoryID && values[index].Category[catIndex].MeasurementCategoryID !== '0') {
            values[index].Category[catIndex].Point.forEach(element => {

            });
            if (IsValid) {
                values[index].Category.push({
                    StyleMeasurementCategoryID: 0,
                    PointsUOM: 1,
                    MeasurementCategoryID: "0",
                    IsDeleted: 0,
                    IndexName: (catIndex + 1).toString(),
                    MeasuremnentSpecImportName: '',
                    MeasuremnentSpecImportPath: '',
                    Point: [{
                        MeasurementPoints: '',
                        MeasurementGranding: '',
                        TolerancePlus: '',
                        ToleranceMinus: '',
                        GradingSizes: '',
                        GradingSizesId: '',
                        GradingSizesValue: '',
                        arrGradingSizes: [],
                        Remark: '',
                        IndexName: (catIndex + 1).toString(),
                        //PreferenceId: (catIndex + 1).toString(),
                        IsDeleted: 0,
                    }]
                });

                let Count = 0;
                setSizeListmeasurement.forEach(element => {
                    if (Count === 0) {
                        Count++;
                        values[index].Category[(catIndex + 1)].Point[0].arrGradingSizes.push({
                            [element.label]: ''
                        })
                    }
                    else {
                        values[index].Category[(catIndex + 1)].Point[0].arrGradingSizes[0][element.label] = '';
                    }
                });

                for (let i = 1; i <= 9; i++) {
                    values[index].Category[(catIndex + 1)].Point.push(
                        {
                            MeasurementPoints: '',
                            MeasurementGranding: '',
                            TolerancePlus: '',
                            ToleranceMinus: '',
                            GradingSizes: '',
                            GradingSizesId: '',
                            GradingSizesValue: '',
                            arrGradingSizes: [],
                            Remark: '',
                            IndexName: (catIndex + 1).toString(),
                            //PreferenceId: i+1,
                            IsDeleted: 0,
                        }
                    )
                    let Count1 = 0;
                    setSizeListmeasurement.forEach(element => {
                        if (Count1 === 0) {
                            Count1++;
                            values[index].Category[(catIndex + 1)].Point[i].arrGradingSizes.push({
                                [element.label]: ''
                            })
                        }
                        else {
                            values[index].Category[(catIndex + 1)].Point[i].arrGradingSizes[0][element.label] = '';
                        }
                    });
                }

                setSubmitted(false);
                setInputFields(values);
                MeasurementCallback(values);
            }
        }
        else {
            Nodify('Warning!', 'warning', "Please fill all mandatory fields.");
            setSubmitted(true);
            IsValid = false;
        }
    }

    const handleRemoveFields = (index, catIndex) => {
        values[index].Category.splice(catIndex, 1);
        setInputFields(values);
        MeasurementCallback(values);
        DisabeSelectedCategotyType();
    };

    const handleRowAddFields = (index, catIndex, pointIndex) => {
        let IsValid = true;
        let count = pointIndex;
        count++;
        if (values[index].Category[catIndex].MeasurementCategoryID !== 0 && values[index].Category[catIndex].MeasurementCategoryID !== '0') {

            if (IsValid === true) {
                let Count = 0;

                let emptypoints = {
                    MeasurementPoints: '',
                    MeasurementGranding: '',
                    TolerancePlus: '',
                    ToleranceMinus: '',
                    GradingSizes: '',
                    GradingSizesId: '',
                    GradingSizesValue: '',
                    arrGradingSizes: [],
                    Remark: '',
                    IndexName: (catIndex).toString(),
                    //PreferenceId: (catIndex + 1).toString(),
                    IsDeleted: 0,
                }

                setSizeListmeasurement.forEach(element => {
                    if (Count === 0) {
                        Count++;
                        emptypoints.arrGradingSizes.push({
                            [element.label]: ''
                        })
                    }
                    else {
                        emptypoints.arrGradingSizes[0][element.label] = '';
                    }
                });
                values[index].Category[catIndex].Point.splice(count, 0, emptypoints);
                if (values[index].Category[catIndex].Point.length > 0) {
                    for (let i = 0; i <= values[index].Category[catIndex].Point.length - 1; i++) {
                        values[index].Category[catIndex].Point[i].PreferenceId = i + 1;
                    }

                }
                setInputFields(values);
                MeasurementCallback(values);
            }
        }
        else {
            Nodify('Warning!', 'warning', "Please fill all mandatory fields.");
            childSetSubmitted(true);
            IsValid = false;
            return false;
        }
    }

    useEffect(() => {
        setInputFields(values);
    }, [getReload]);

    const handleRowRemoveFields = (index, catIndex, pointIndex) => {
        values[index].Category[catIndex].Point.splice(pointIndex, 1);
        if (values[index].Category[catIndex].Point.length > 0) {
            for (var i = 0; i <= values[index].Category[catIndex].Point.length - 1; i++) {
                values[index].Category[catIndex].Point[i].PreferenceId = i + 1;
            }

        }
        setInputFields(values);
        MeasurementCallback(values);
        setReload(getReload + 1);
        DisabeSelectedCategotyPoints();
    }

    const specInputChange = (event, index, FieldName) => {
       ;
        //setSubmitted(false);
        let inputText = '';
        if (FieldName === "MeasurementGrandingSize") {
            if (event !== null) {
                inputText = event.value;
                SetSize(event.label);
                values[index][FieldName] = inputText;


                for (let i = 0; i < values.length; i++) {
                    let IsHighValue = false;
                    if (values[i].MeasurementGrandingSize === 0 || values[i].MeasurementGrandingSize === '') {
                    }
                    else {
                        for (let j = 0; j < values[i].Category.length; j++) {
                            for (let k = 0; k < values[i].Category[j].Point.length; k++) {
                                let SelectedValue = setSizeListmeasurement.filter(x => x.value === values[i].MeasurementGrandingSize);

                                let Value = values[i].Category[j].Point[k].arrGradingSizes[0][SelectedValue[0].label];
                                let selectedIndex = SelectedValue[0].rowID;
                                Value = Value === '' ? 0 : Value;

                                // eslint-disable-next-line no-loop-func
                                setSizeListmeasurement.forEach(element => {
                                    let loopIndex = element.rowID;
                                    let MeasurementGradingValue = values[i].Category[j].Point[k].MeasurementGranding;
                                    if (MeasurementGradingValue === "" || MeasurementGradingValue === undefined || MeasurementGradingValue === null) {
                                    }
                                    else {

                                        MeasurementGradingValue = MeasurementGradingValue === '' ? 0 : MeasurementGradingValue;
                                        let BindingValue = '';
                                        if (values[i].MeasurementGrandingSize === element.value) {
                                            IsHighValue = true;
                                            if (parseFloat(Value) > 0) {
                                                BindingValue = parseFloat(Value);
                                            }
                                            values[i].Category[j].Point[k].arrGradingSizes[0][element.label] = BindingValue;
                                            if (parseFloat(values[i].Category[j].Point[k].arrGradingSizes[0][element.label]) < 0) {
                                                values[i].Category[j].Point[k].arrGradingSizes[0][element.label] = '';
                                            }
                                        }
                                        else if (IsHighValue === true) {
                                            if ((parseFloat(Value) + parseFloat(MeasurementGradingValue * (loopIndex - selectedIndex))) > 0) {
                                                BindingValue = parseFloat(Value) + parseFloat(MeasurementGradingValue * (loopIndex - selectedIndex));
                                            }
                                            values[i].Category[j].Point[k].arrGradingSizes[0][element.label] = BindingValue;
                                            if (parseFloat(values[i].Category[j].Point[k].arrGradingSizes[0][element.label]) < 0) {
                                                values[i].Category[j].Point[k].arrGradingSizes[0][element.label] = '';
                                            }
                                        }
                                        else {
                                            if ((parseFloat(Value) - parseFloat(MeasurementGradingValue * (selectedIndex - loopIndex))) > 0) {
                                                BindingValue = parseFloat(Value) - parseFloat(MeasurementGradingValue * (selectedIndex - loopIndex));
                                            }
                                            values[i].Category[j].Point[k].arrGradingSizes[0][element.label] = BindingValue;
                                            if (parseFloat(values[i].Category[j].Point[k].arrGradingSizes[0][element.label]) < 0) {
                                                values[i].Category[j].Point[k].arrGradingSizes[0][element.label] = '';
                                            }
                                        }
                                    }

                                });
                            }
                        }
                    }
                }
            }
            else {
                values[index][FieldName] = inputText;
                SetSize('');
            }

        }
        else {
            if (event.target.value.trim() !== '') {
                inputText = event.target.value;
            }
            values[index][event.target.name] = inputText;
        }
        setInputFields(values);
        MeasurementCallback(values);
    }

    const categoryInputChange = (event, index, catIndex, FieldName) => {

        //setSubmitted(false);
        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }
        if (FieldName === "PointsUOM") {
            values[index].Category[catIndex][event.target.name] = inputText;
        }
        else {
            let ValueExist = values[index].Category.filter(x => parseFloat(x.MeasurementCategoryID) === parseFloat(inputText) && x.IsDeleted === 0);
            if (ValueExist.length !== 0) {
                if (inputText !== 0 && inputText !== "0") {
                    Nodify('Warning!', 'warning', "This category type is already exist!");
                }
                values[index].Category[catIndex][event.target.name] = '';
            }
            else {
               ;
                values[index].Category[catIndex][event.target.name] = inputText;
                //  values[index].Category[catIndex]['StyleMeasurementCategoryID'] = inputText;
                //  values[index]['StyleMeasurementID'] = inputText;
            }
            DisabeSelectedCategotyType();


        }
        // let ValueExist = values[index].Category.filter(x => parseFloat(x.MeasurementCategoryID) === parseFloat(inputText) && x.IsDeleted === 0);
        // if (ValueExist.length !== 0) {
        //     if (inputText !== 0 && inputText !== "0") {
        //         Nodify('Warning!', 'warning', "This category type is already exist!");
        //     }
        //     values[index].Category[catIndex][event.target.name] = '';
        // }
        // else {
        //     values[index].Category[catIndex][event.target.name] = inputText;
        // }
       
        setInputFields(values);
        MeasurementCallback(values);
    }

    const controlOverflow = (event, index, catIndex, pointIndex, FieldName) => {
        setOverflow("unset");
    }

    const pointInputChange = (event, index, catIndex, pointIndex, FieldName) => {
       
        setOverflow("auto");
        //S childSetSubmitted(false);
        let inputText = '';

        if (FieldName === 'MeasurementPoints') {
           ;
            if (event !== null) {
                // inputText = event.target.value; // Dropdown
                inputText = event.value; // React select

                let ValueExist = values[index].Category[catIndex].Point.filter(x => x.MeasurementPoints === parseFloat(inputText) && x.IsDeleted === 0);
                if (ValueExist.length !== 0) {
                    Nodify('Warning!', 'warning', "This point is already exist!");
                    values[index].Category[catIndex].Point[pointIndex][FieldName] = '';
                }
                else {
                    values[index].Category[catIndex].Point[pointIndex][FieldName] = inputText;
                }
            }
            else {
                values[index].Category[catIndex].Point[pointIndex][FieldName] = '';
            }

            DisabeSelectedCategotyPoints();
        }

        else {
            if (FieldName === "TolerancePlus") {
                let input = '';
                if (event.target.value.trim() !== '') {
                    input = event.target.value
                }
                // let ToleranceMinus = values[index].Category[catIndex].Point[pointIndex].ToleranceMinus
                // if (input === '' || parseFloat(ToleranceMinus) === 0 || input === undefined) {
                values[index].Category[catIndex].Point[pointIndex].ToleranceMinus = input;

                // }
            }

            if (FieldName === "Remark") {
                let input = '';
                if (event.target.value.trim() !== '') {
                    input = event.target.value
                }
                let Remark = values[index].Category[catIndex].Point[pointIndex].Remark
                values[index].Category[catIndex].Point[pointIndex][event.target.name] = input;
            }
            else {
                let input = '';
                if (event.target.value.trim() !== '') {
                    input = event.target.value
                }
                values[index].Category[catIndex].Point[pointIndex][event.target.name] = input;
            }

            if (FieldName === "MeasurementGranding") {
                if (event.target.value.trim() !== '') {
                    // inputText = event.target.value;
                    //var checkNumber = inputText.replace(/\D/g, '').match('^[0-9]*$');
                    //inputText = checkNumber[0];
                    inputText = event.target.value;
                    var regex = /^[0-9.]+$/;
                    let isValid = regex.test(inputText);
                    if (!isValid) {
                        inputText = '';
                    }

                }
                inputText = inputText === "" ? '0' : inputText;
                if (inputText !== '') {
                    let IsHighValue = false;
                    if (values[index].MeasurementGrandingSize === 0 || values[index].MeasurementGrandingSize === '') {
                        // values[index].Category[catIndex].Point[pointIndex][event.target.name] = '';
                        // Nodify('Warning!', 'warning', "Please select Grading!");
                    }
                    else {

                        let SelectedValue = setSizeListmeasurement.filter(x => x.value === values[index].MeasurementGrandingSize);

                        let Value = values[index].Category[catIndex].Point[pointIndex].arrGradingSizes[0][SelectedValue[0].label];
                        let selectedIndex = SelectedValue[0].rowID;
                        Value = Value === '' ? 0 : Value;
                        if (Value === 0) {
                            // values[index].Category[catIndex].Point[pointIndex][event.target.name] = '';
                            // let msg = "Please enter value for selected grading size (" + SelectedValue[0].label + ")!";
                            // Nodify('Warning!', 'warning', msg);
                        }
                        else {

                            for (var j = 0; j < setSizeListmeasurement.length; j++) {
                                if (setSizeListmeasurement[j].label === Size) {
                                    setSizeDisabled({ Disabled: "" });
                                }
                                else {
                                    setSizeDisabled({ Disabled: "disabled" });
                                }
                            }

                            setSizeListmeasurement.forEach(element => {
                                let loopIndex = element.rowID;
                                let BindingValue = '';
                                if (values[index].MeasurementGrandingSize === element.value) {
                                    IsHighValue = true;
                                    if (parseFloat(Value) > 0) {
                                        BindingValue = parseFloat(Value);
                                    }
                                    values[index].Category[catIndex].Point[pointIndex].arrGradingSizes[0][element.label] = BindingValue;
                                    if (parseFloat(values[index].Category[catIndex].Point[pointIndex].arrGradingSizes[0][element.label]) < 0) {
                                        values[index].Category[catIndex].Point[pointIndex].arrGradingSizes[0][element.label] = 0;
                                    }
                                }
                                else if (IsHighValue === true) {
                                    if ((parseFloat(Value) + parseFloat(inputText * (loopIndex - selectedIndex))) > 0) {
                                        BindingValue = parseFloat(Value) + parseFloat(inputText * (loopIndex - selectedIndex));
                                    }
                                    values[index].Category[catIndex].Point[pointIndex].arrGradingSizes[0][element.label] = BindingValue;
                                    if (parseFloat(values[index].Category[catIndex].Point[pointIndex].arrGradingSizes[0][element.label]) < 0) {
                                        values[index].Category[catIndex].Point[pointIndex].arrGradingSizes[0][element.label] = 0;
                                    }
                                }
                                else {
                                    if ((parseFloat(Value) - parseFloat(inputText * (selectedIndex - loopIndex))) > 0) {
                                        BindingValue = parseFloat(Value) - parseFloat(inputText * (selectedIndex - loopIndex));
                                    }
                                    values[index].Category[catIndex].Point[pointIndex].arrGradingSizes[0][element.label] = BindingValue;
                                    if (parseFloat(values[index].Category[catIndex].Point[pointIndex].arrGradingSizes[0][element.label]) < 0) {
                                        values[index].Category[catIndex].Point[pointIndex].arrGradingSizes[0][element.label] = 0;
                                    }
                                }
                            });
                        }
                    }
                }

            }
        }
        setInputFields(values);
        MeasurementCallback(values);
    }

    const sizeInputChange = (event, index, catIndex, pointIndex, FieldName) => {
       ;
        //childSetSubmitted(false);
        let inputText = '';
        //   if (event.target.value.trim() !== '') {
        inputText = event.target.value;
        var regex = /^[0-9.]+$/;
        let isValid = regex.test(inputText);
        if (values[index].Category[catIndex].Point[pointIndex].arrGradingSizes && values[index].Category[catIndex].Point[pointIndex].arrGradingSizes.length > 0) {
            if (isValid) {
                values[index].Category[catIndex].Point[pointIndex].arrGradingSizes[0][event.target.name] = inputText;
            }
            else {
                values[index].Category[catIndex].Point[pointIndex].arrGradingSizes[0][event.target.name] = '';
                inputText = '';
            }
        }
        else {
            if (isValid) {
                values[index].Category[catIndex].Point[pointIndex].arrGradingSizes.push({
                    [event.target.name]: inputText
                });
            } else {
                values[index].Category[catIndex].Point[pointIndex].arrGradingSizes.push({
                    [event.target.name]: ''
                });
            }
        }
        //  }
        // if (inputText !== '' && inputText !== 0 && inputText !== undefined) {
        //     let SelectedValue = setSizeListmeasurement.filter(x => x.value === values[index].MeasurementGrandingSize);
        //     if (SelectedValue.length !== 0) {
        //         let GradingPoint = values[index].Category[catIndex].Point[pointIndex].MeasurementGranding;
        //         GradingPoint = GradingPoint === '' ? 0 : GradingPoint;
        //         let selectedIndex = SelectedValue[0].rowID;

        //         if (SelectedValue[0].label === event.target.name) {
        //             let IsHighValue = false;
        //             setSizeListmeasurement.forEach(element => {
        //                 let loopIndex = element.rowID;
        //                 let BindingValue = '';
        //                 if (values[index].MeasurementGrandingSize === element.value) {
        //                     IsHighValue = true;
        //                     if (parseFloat(inputText) > 0) {

        //                         // BindingValue = parseFloat(inputText);
        //                         BindingValue = inputText;
        //                     }
        //                     values[index].Category[catIndex].Point[pointIndex].arrGradingSizes[0][element.label] = BindingValue;
        //                     if (parseFloat(values[index].Category[catIndex].Point[pointIndex].arrGradingSizes[0][element.label]) < 0) {
        //                         values[index].Category[catIndex].Point[pointIndex].arrGradingSizes[0][element.label] = 0;
        //                     }
        //                 }
        //                 else if (IsHighValue === true) {
        //                     if ((parseFloat(inputText) + parseFloat(GradingPoint * (loopIndex - selectedIndex))) > 0) {
        //                         BindingValue = parseFloat(inputText) + parseFloat(GradingPoint * (loopIndex - selectedIndex));
        //                     }
        //                     values[index].Category[catIndex].Point[pointIndex].arrGradingSizes[0][element.label] = BindingValue;
        //                     if (parseFloat(values[index].Category[catIndex].Point[pointIndex].arrGradingSizes[0][element.label]) < 0) {
        //                         values[index].Category[catIndex].Point[pointIndex].arrGradingSizes[0][element.label] = 0;
        //                     }
        //                 }
        //                 else {
        //                     if ((parseFloat(inputText) - parseFloat(GradingPoint * (selectedIndex - loopIndex))) > 0) {
        //                         BindingValue = parseFloat(inputText) - parseFloat(GradingPoint * (selectedIndex - loopIndex));
        //                     }
        //                     values[index].Category[catIndex].Point[pointIndex].arrGradingSizes[0][element.label] = BindingValue;
        //                     if (parseFloat(values[index].Category[catIndex].Point[pointIndex].arrGradingSizes[0][element.label]) < 0) {
        //                         values[index].Category[catIndex].Point[pointIndex].arrGradingSizes[0][element.label] = 0;
        //                     }
        //                 }
        //             });
        //         }
        //     }
        // }
        // else {
        //     values[index].Category[catIndex].Point[pointIndex].arrGradingSizes[0][event.target.name] = '';
        //     inputText = '';

        // }

        setInputFields(values);
        MeasurementCallback(values);
    }

    const input = document.getElementById('input')

    const ExcelImport = (e) => {
       ;
        const file = e.target.files[0];
        const fileType = file['type'];
        const validImageTypes = ['application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
        if (!validImageTypes.includes(fileType)) {
            Nodify('Warning!', 'warning', 'Invalid file format selected');
            $('#FileUpload').val("");
        }

        else {
            setCommonLoader(true);
            readXlsxFile(e.target.files[0]).then((rows) => {
                try {
                    var pointList = rows;

                    pointList = pointList.filter(x => x[0]);

                    const CategoryHeader = pointList[0][pointList[0].length - 1].toString().trim().toUpperCase();
                    // With CategoryHeader
                    if (CategoryHeader === "CATEGORYTYPE" || CategoryHeader === "CATEGORY TYPE") {
                        values[0].Category = [];
                        //Remove Empty Value
                        pointList = pointList.filter(x => x[pointList[0].length - 1]);

                        //Get Category Type Array Value
                        let arrayUnique = pointList.map(x => x[pointList[0].length - 1].trim().toUpperCase());
                        arrayUnique.shift();
                        const arrCategoryType = arrayUnique.filter((item, index) => arrayUnique.indexOf(item) == index);

                        //Check Grading Sizes Header And Add GradingSizes Header
                        let sizestart = 4;
                        let IsBothTotal = false;
                        if (pointList[0][2].trim().toUpperCase() === "TOL+-" || pointList[0][2].trim().toUpperCase() === "TOL +-" || pointList[0][2].trim().toUpperCase() === "TOL + -") {
                            sizestart = 3;
                            IsBothTotal = true;
                        }
                        let sizeend = pointList[0].length - 2;
                        let arrGradingSizes = [];
                        for (let s = sizestart; s < sizeend; s++) {
                            // eslint-disable-next-line no-loop-func
                            let checkSize = getGradingSize.find(x => x.label.toString().trim().toUpperCase() === pointList[0][s].toString().trim().toUpperCase());

                            if (checkSize !== undefined) {
                                arrGradingSizes.push(pointList[0][s].toString().toUpperCase())
                            }
                        }

                        let i = 0;
                        //Unique Category List
                        arrCategoryType.map((item, val) => {
                            let category = getCategoryType.filter(x => x.name.toString().trim().toUpperCase() === item)[0];

                            if (category != null && category !== undefined) {
                               ;
                                values[0].Category.push({
                                    StyleMeasurementCategoryID: category.id,
                                    PointsUOM: 1,
                                    MeasurementCategoryID: category.id,
                                    IsDeleted: 0,
                                    IndexName: i,
                                    MeasuremnentSpecImportName: '',
                                    MeasuremnentSpecImportPath: '',
                                    Point: []
                                });

                                values[0].Category[i].Point = [];
                                let pointIndex = 0;
                                var points = pointList.filter(x => x[pointList[0].length - 1].toString().trim().toUpperCase() === category.name.toString().trim().toUpperCase());

                                for (var k = 0; k < points.length; k++) {
                                    let isPointName = false;
                                    if (pointList[k][0] !== "" && points[k][0] != null) {
                                        if (points[k][0].trim() !== "" && points[k][0].trim() != null) {
                                            //Check Point Name in row
                                            // eslint-disable-next-line no-loop-func
                                            var PointName = getMeasurement.filter(x => x.name.toString().trim().toUpperCase() === points[k][0].toString().trim().toUpperCase());
                                            if (PointName.length > 0) {
                                                isPointName = true;
                                                //Push Default Value
                                                values[0].Category[i].Point.push({
                                                    MeasurementPoints: PointName[0].value,
                                                    MeasurementGranding: points[k][1] == null ? '' : points[k][1].toString(),
                                                    TolerancePlus: points[k][2] == null ? '' : points[k][2].toString(),
                                                    ToleranceMinus: IsBothTotal ? points[k][2] == null ? '' : points[k][2].toString() : points[k][3] == null ? '' : points[k][3].toString(),
                                                    IndexName: i.toString(),
                                                    arrGradingSizes: [],
                                                    Remark: points[k][points[k].length - 2] == null ? '' : points[k][points[k].length - 2].toString(),
                                                    PreferenceId: i + 1,
                                                    IsDeleted: 0,
                                                });
                                            }
                                        }
                                    }

                                    if (!isPointName) {
                                        //Push Point Default Value
                                        values[0].Category[i].Point.push({
                                            MeasurementPoints: '0',
                                            MeasurementGranding: points[k][1] == null ? '' : points[k][1].toString(),
                                            TolerancePlus: points[k][2] == null ? '' : points[k][2].toString(),
                                            ToleranceMinus: IsBothTotal ? points[k][2] == null ? '' : points[k][2].toString() : points[k][3] == null ? '' : points[k][3].toString(),
                                            IndexName: i.toString(),
                                            arrGradingSizes: [],
                                            Remark: points[k][points[k].length - 2] == null ? '' : points[k][points[k].length - 2].toString(),
                                            PreferenceId: i + 1,
                                            IsDeleted: 0,
                                        });
                                    }

                                    //Check GradingSizes Value And ADD GradingSizes Value
                                    let arrGradingSizesValue = [];
                                    for (var j = sizestart; j < sizeend; j++) {
                                        try {
                                            // eslint-disable-next-line no-loop-func
                                            let checkSizeValue = getGradingSize.find(x => x.label.toString().toUpperCase() === pointList[0][j].toString().trim().toUpperCase());
                                            if (checkSizeValue !== undefined) {
                                                arrGradingSizesValue.push(points[k][j].toString())
                                            }
                                        } catch
                                        {
                                        }
                                    }

                                    //Push Dynamic Value
                                    let arrcount = 0;
                                    // eslint-disable-next-line no-loop-func
                                    arrGradingSizes.forEach((val, valIndex) => {

                                        if (arrcount === 0) {
                                            arrcount++;
                                            try {

                                                values[0].Category[i].Point[pointIndex].arrGradingSizes.push({
                                                    [val]: arrGradingSizesValue[valIndex]
                                                });
                                            } catch { }
                                        }
                                        else {
                                            try {
                                                values[0].Category[i].Point[pointIndex].arrGradingSizes[0][val] = arrGradingSizesValue[valIndex];
                                            } catch { }
                                        }
                                    });
                                    pointIndex++;
                                }

                                if (values[0].Category[i].Point.length == 0) {
                                    values[0].Category[i].Point.push({
                                        MeasurementPoints: '',
                                        MeasurementGranding: '',
                                        TolerancePlus: '',
                                        ToleranceMinus: '',
                                        IndexName: i.toString(),
                                        arrGradingSizes: [],
                                        Remark: '',
                                        PreferenceId: i + 1,
                                        IsDeleted: 0,
                                    });
                                }
                                i++;
                            }
                        })

                        setInputFields(values);
                        MeasurementCallback(values);
                        setGradingSize(setSizeListmeasurement);
                        setReload1(getReload1 + 1);
                        setCommonLoader(false);
                    } else {
                        // With Out CategoryHeader

                        //Check Grading Sizes Header And Add GradingSizes Header
                       ;
                        let sizestart = 4;
                        let IsBothTotal = false;
                        if (pointList[0][2].trim().toUpperCase() === "TOL+-" || pointList[0][2].trim().toUpperCase() === "TOL +-" || pointList[0][2].trim().toUpperCase() === "TOL + -") {
                            sizestart = 3;
                            IsBothTotal = true;
                        }
                        let sizeend = pointList[0].length - 1;
                        let arrGradingSizes = [];
                        for (let s = sizestart; s < sizeend; s++) {
                            // eslint-disable-next-line no-loop-func
                            let checkSize = getGradingSize.find(x => x.label.toString().trim().toUpperCase() === pointList[0][s].toString().trim().toUpperCase());

                            if (checkSize !== undefined) {
                                arrGradingSizes.push(pointList[0][s].toString().toUpperCase())
                            }
                        }

                        let i = 0;

                        // values[0].Category.push({
                        //     StyleMeasurementCategoryID: 0,
                        //     PointsUOM: values[0].Category[0].PointsUOM,
                        //     MeasurementCategoryID: values[0].Category[0].MeasurementCategoryID,
                        //     IsDeleted: 0,
                        //     IndexName: i,
                        //     MeasuremnentSpecImportName: '',
                        //     MeasuremnentSpecImportPath: '',
                        //     Point: []
                        // });

                        values[0].Category[i].Point = [];
                        let pointIndex = 0;
                        let points = pointList.slice(1);
                        for (let k = 0; k < points.length; k++) {
                            let isPointName = false;
                            if (pointList[k][0] !== "" && points[k][0] != null) {
                                if (points[k][0].trim() !== "" && points[k][0].trim() != null) {
                                    //Check Point Name in row
                                    // eslint-disable-next-line no-loop-func
                                    let PointName = getMeasurement.filter(x => x.name.toString().trim().toUpperCase() === points[k][0].toString().trim().toUpperCase());
                                    if (PointName.length > 0) {
                                        isPointName = true;
                                        //Push Default Value
                                        values[0].Category[i].Point.push({
                                            MeasurementPoints: PointName[0].value,
                                            MeasurementGranding: points[k][1] == null ? '' : points[k][1].toString(),
                                            TolerancePlus: points[k][2] == null ? '' : points[k][2].toString(),
                                            ToleranceMinus: IsBothTotal ? points[k][2] == null ? '' : points[k][2].toString() : points[k][3] == null ? '' : points[k][3].toString(),
                                            IndexName: i.toString(),
                                            arrGradingSizes: [],
                                            Remark: points[k][points[k].length - 1] == null ? '' : points[k][points[k].length - 1].toString(),
                                            PreferenceId: i + 1,
                                            IsDeleted: 0,
                                        });
                                    }
                                }
                            }

                            if (!isPointName) {
                                //Push Point Default Value
                                values[0].Category[i].Point.push({
                                    MeasurementPoints: '0',
                                    MeasurementGranding: points[k][1] == null ? '' : points[k][1].toString(),
                                    TolerancePlus: points[k][2] == null ? '' : points[k][2].toString(),
                                    ToleranceMinus: IsBothTotal ? points[k][2] == null ? '' : points[k][2].toString() : points[k][3] == null ? '' : points[k][3].toString(),
                                    IndexName: i.toString(),
                                    arrGradingSizes: [],
                                    Remark: points[k][points[k].length - 1] == null ? '' : points[k][points[k].length - 1].toString(),
                                    PreferenceId: i + 1,
                                    IsDeleted: 0,
                                });
                            }

                            //Check GradingSizes Value And ADD GradingSizes Value
                            let arrGradingSizesValue = [];
                            for (let j = sizestart; j < sizeend; j++) {
                                try {
                                    // eslint-disable-next-line no-loop-func
                                    let checkSizeValue = getGradingSize.find(x => x.label.toString().toUpperCase() === pointList[0][j].toString().trim().toUpperCase());
                                    if (checkSizeValue !== undefined) {
                                        arrGradingSizesValue.push(points[k][j].toString())
                                    }
                                } catch
                                {
                                }
                            }

                            //Push Dynamic Value
                            let arrcount = 0;
                            // eslint-disable-next-line no-loop-func
                            arrGradingSizes.forEach((val, valIndex) => {

                                if (arrcount === 0) {
                                    arrcount++;
                                    try {

                                        values[0].Category[i].Point[pointIndex].arrGradingSizes.push({
                                            [val]: arrGradingSizesValue[valIndex]
                                        });
                                    } catch { }
                                }
                                else {
                                    try {
                                        values[0].Category[i].Point[pointIndex].arrGradingSizes[0][val] = arrGradingSizesValue[valIndex];
                                    } catch { }
                                }
                            });
                            pointIndex++;
                        }

                        if (values[0].Category[i].Point.length === 0) {
                            values[0].Category[i].Point.push({
                                MeasurementPoints: '',
                                MeasurementGranding: '',
                                TolerancePlus: '',
                                ToleranceMinus: '',
                                IndexName: i.toString(),
                                arrGradingSizes: [],
                                Remark: '',
                                PreferenceId: i + 1,
                                IsDeleted: 0,
                            });
                        }

                        setInputFields(values);
                        MeasurementCallback(values);
                        setGradingSize(setSizeListmeasurement);
                        setReload1(getReload1 + 1);
                        setCommonLoader(false);
                    }
                } catch
                {

                    MeasurementPageCallback("Please select the correct format  (xls or xlsx)  and correct format match data.");
                    setCommonLoader(false);

                }
            });

        }

    }
    useEffect(() => {
        setInputFields(values);
    }, [getReload1]);

    const MeasurementPointCallback = (value) => {
        if (value === true) {
            MeasurementService.LoadMeasurement().then((response) => {
                if (response.data) {
                    setMeasurement(response.data);
                } else {
                }
            })
                .catch(() => { });
            setQuickMasterName('');
        } else {
            setQuickMasterName('');
        }
    }
    const handlePopupClose = () => {
        setQuickMasterName('');
    };

    const DisabeSelectedCategotyType = () => {
        getCategoryType.forEach((data, i) => {
            getCategoryType[i].isdisabled = false;
        })
       ;
        values[0].Category.forEach((data, i) => {
            var SelectedIndex = getCategoryType.findIndex(x => x.id === parseInt(data.MeasurementCategoryID));
            if (SelectedIndex !== -1) {
                getCategoryType[SelectedIndex].isdisabled = true;

            }
        })
    }

    const DisabeSelectedCategotyPoints = () => {
       ;
        getMeasurement.forEach((data, i) => {
            getMeasurement[i].isdisabled = false;
        })
        values[0].Category.forEach((data, i) => {
            values[0].Category[i].Point.forEach((data, j) => {
                var SelectedIndex = getMeasurement.findIndex(x => x.value === data.MeasurementPoints);
                if (SelectedIndex !== -1) {
                    getMeasurement[SelectedIndex].isdisabled = true;
                }
            })
        })
    }


    return (
        <>
            {
                getCommonLoader === false ?


                    <div className="row">
                        {
                            inputFields.map((item, index) => (
                                <Fragment key={`${item}~${index}`}>
                                    <div className={DynamicClassName}
                                        style={{ display: item.IsDeleted === 1 ? "none" : "block" }}>
                                        <div className="widget flat radius-bordered">
                                            <div className="panel-group accordion" id="accordion">
                                                <div className="panel panel-default">
                                                    <div className="panel-heading ">
                                                        <h4 className="mb-0 panel-title">
                                                            <a className="btn btn-link font_wight" data-toggle="collapse" data-parent="accordion"
                                                                href={"#collapseMeasurement_" + index}>
                                                                {
                                                                    item.StyleMeasurementID === 0 ? 'Measurement Details' :
                                                                        item.MeasurementOperation === 1 ? 'Revised : ' + item.MeasurementSpec : item.MeasurementSpec
                                                                }
                                                            </a>
                                                        </h4>
                                                    </div>

                                                    <div id={"collapseMeasurement_" + index}
                                                        className='panel-collapse collapse in' >
                                                        <div className="widget-body">
                                                            <div id="registration-form">
                                                                <Form role="form">
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="row">

                                                                                <div className="col-md-3">
                                                                                    <div className="form-group">
                                                                                        <label htmlFor={"MeasurementSpec" + index}>Spec Reason</label><span className="text-danger">*</span>
                                                                                        <Input placeholder="Enter Spec Reason"
                                                                                            id={"MeasurementSpec" + index}
                                                                                            name="MeasurementSpec"
                                                                                            value={item.MeasurementSpec}
                                                                                            onChange={event => specInputChange(event, index, "MeasurementSpec")}
                                                                                            type="text"
                                                                                            maxLength="50" autoComplete="off" className='form-control'
                                                                                            style={{
                                                                                                border: submitted && item.MeasurementSpec === '' ? '1px solid red' : ''
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-3">
                                                                                    <div className="form-group">
                                                                                        <label htmlFor={"MeasurementGrandingSize" + index}>Grading</label>
                                                                                        <Reactselect className="basic-single"
                                                                                            name={"MeasurementGrandingSize" + index}
                                                                                            id={
                                                                                                "MeasurementGrandingSize" + index
                                                                                            }
                                                                                            isDisabled={false}
                                                                                            isLoading={false}
                                                                                            isClearable={true}
                                                                                            isSearchable={true}
                                                                                            options={getGradingSize}
                                                                                            value={getGradingSize.filter(function (option) {
                                                                                                return option.value === item.MeasurementGrandingSize;
                                                                                            })}
                                                                                            onChange={event => specInputChange(event, index, "MeasurementGrandingSize")}
                                                                                        ></Reactselect>


                                                                                    </div>
                                                                                </div>
                                                                                {/* {getShowUpload === false && (
                                                                                    <div className="col-lg-3" style={{ marginTop: "6px" }}>
                                                                                        <div className="form-group">
                                                                                            <label>
                                                                                                &nbsp;
                                                                                            </label>
                                                                                            <span className="input-icon icon-right">
                                                                                                <input type="file" id="input" onChange={event => ExcelImport(event)} multiple={false} />
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                )} */}

                                                                                <div className="col-lg-3" style={{ marginTop: "6px" }}>
                                                                                    <div className="form-group">
                                                                                        <label>
                                                                                            &nbsp;
                                                                                        </label>
                                                                                        <span className="input-icon icon-right">
                                                                                            <input type="file" id="input" style={{ display: MeasurementInfo && MeasurementInfo.isAdd === 1 ? '' : 'none' }} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={event => ExcelImport(event)} multiple={false} />
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* &nbsp;
                                                            {
                                                                item.Category.filter(prod => prod.IsDeleted === 0).map((inputField1, index1) => (

                                                                    item.Category.filter(prod => prod.IsDeleted === 0).length === (index1 + 1) && (
                                                                        <div className="col-lg-12 alignRight">
                                                                            <span>
                                                                                <button type="button" className="btn btn-xs btn-success" title="Add category details"
                                                                                    onClick={
                                                                                        () => handleAddFields(index, index1)
                                                                                    }>
                                                                                    <i className="fa fa-plus"></i>
                                                                                </button>
                                                                            </span>
                                                                        </div>
                                                                    )
                                                                ))
                                                            } */}
                                                                    {
                                                                        item.Category.map((Catitem, catIndex) => {
                                                                            return (
                                                                                Catitem.IsDeleted === 0 ?
                                                                                    <>
                                                                                        <div className="row">
                                                                                            <div className="col-lg-3">
                                                                                                <div className="form-group">
                                                                                                    <label htmlFor={"CategoryType" + catIndex}>
                                                                                                        Category Type <span className="text-danger">*</span>
                                                                                                    </label>
                                                                                                    <span className="input-icon icon-right">
                                                                                                        <select id={"MeasurementCategoryID" + catIndex}
                                                                                                            name="MeasurementCategoryID"
                                                                                                            className="form-select"
                                                                                                            value={Catitem.MeasurementCategoryID}
                                                                                                            onChange={event => categoryInputChange(event, index, catIndex, 'MeasurementCategoryID')}
                                                                                                            style={{
                                                                                                                border: submitted && Catitem.MeasurementCategoryID === "0" ? '1px solid red' : ''
                                                                                                            }}
                                                                                                        // isOptionDisabled={(option) => option.isdisabled}
                                                                                                        //   disabled={true}
                                                                                                        >
                                                                                                            <option value="0">-Select Category Type-</option>
                                                                                                            {
                                                                                                                getCategoryType.map(item1 => (
                                                                                                                    <option key={
                                                                                                                        item1.id
                                                                                                                    }
                                                                                                                        value={
                                                                                                                            item1.id
                                                                                                                        }
                                                                                                                        disabled={item1.isdisabled}
                                                                                                                    // selected={item1.id}

                                                                                                                    >
                                                                                                                        {
                                                                                                                            item1.name
                                                                                                                        }

                                                                                                                    </option>
                                                                                                                ))
                                                                                                            }

                                                                                                        </select>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-lg-3">
                                                                                                <div className="form-group">
                                                                                                    <label htmlFor={"PointsUOM" + catIndex}>
                                                                                                        UOM <span className="text-danger">*</span>
                                                                                                    </label>
                                                                                                    <span className="input-icon icon-right">
                                                                                                        <select id={"PointsUOM" + catIndex}
                                                                                                            name="PointsUOM"
                                                                                                            className="form-select"
                                                                                                            value={Catitem.PointsUOM}
                                                                                                            onChange={event => categoryInputChange(event, index, catIndex, 'PointsUOM')}
                                                                                                        // style={{
                                                                                                        //     border: submitted && Catitem.PointsUOM === 0 ? '1px solid red' : ''
                                                                                                        // }}
                                                                                                        >
                                                                                                            {/* <option value="0">-Select UOM-</option> */}
                                                                                                            {
                                                                                                                getUOMType.map(item1 => (
                                                                                                                    <option key={item1.index}
                                                                                                                        value={item1.id}>
                                                                                                                        {item1.name}
                                                                                                                    </option>
                                                                                                                ))
                                                                                                            }
                                                                                                        </select>
                                                                                                    </span>
                                                                                                </div>

                                                                                            </div>





                                                                                            <div className="col-lg-3" style={{ marginTop: "36px" }}>
                                                                                                {item.Category.length !== 1 && (
                                                                                                    <span style={{ display: MeasurementInfo && MeasurementInfo.isDelete === 1 ? '' : 'none' }}>
                                                                                                        <button type="button" className="btn  btn-xs  btn-danger" title="Delete Category"
                                                                                                            onClick={event => handleRemoveFields(index, catIndex)}>
                                                                                                            <i className="fa fa-trash-o"></i>
                                                                                                        </button>
                                                                                                    </span>
                                                                                                )}
                                                                                                &nbsp;
                                                                                                {
                                                                                                    item.Category.length === (catIndex + 1) && (
                                                                                                        <span style={{ display: MeasurementInfo && MeasurementInfo.isAdd === 1 ? '' : 'none' }}>
                                                                                                            <button type="button" className="btn btn-xs btn-success" title="Add category details"
                                                                                                                onClick={
                                                                                                                    () => handleAddFields(index, catIndex)
                                                                                                                }>
                                                                                                                <i className="fa fa-plus"></i>
                                                                                                            </button>
                                                                                                        </span>
                                                                                                    )
                                                                                                }
                                                                                            </div>




                                                                                        </div>

                                                                                        <div className="row">
                                                                                            <div className="col-lg-12">
                                                                                                <div className="dataTables_wrapper no-footer" style={{ width: BrowserWidth, overflowX: "auto" }}>
                                                                                                    {
                                                                                                        inputFields[0].Category[0].Point.length > 0 ?
                                                                                                            <>
                                                                                                                {

                                                                                                                    <table className="table table-bordered">
                                                                                                                        <thead className="text-center">
                                                                                                                            <tr>
                                                                                                                                <th>#</th>
                                                                                                                                <th className="word_warp_padding text-center ">
                                                                                                                                    {MeasurementInfoQuick && MeasurementInfoQuick.MeasurementPoint[0].isQuick === 1 && Buttonview !== "View" &&
                                                                                                                                        <button type='button' className="glyphicon glyphicon-plus TableAddPlusIcon"
                                                                                                                                            onClick={() => setQuickMasterName('Measurement Point')} title="Add Measurement Point">
                                                                                                                                        </button>}Points
                                                                                                                                </th>
                                                                                                                                <th className="text-center ">Grading</th>
                                                                                                                                <th className="text-center ">Tol +<span className='text-danger'> *</span></th>
                                                                                                                                <th className="text-center ">Tol -<span className='text-danger'> *</span></th>
                                                                                                                                {
                                                                                                                                    getGradingSize.map((size, i) => {
                                                                                                                                        return (
                                                                                                                                            // <th className="text-center ">{size.label}</th>
                                                                                                                                            // Size === size.label ? <span className="text-danger">*</span> : ''
                                                                                                                                            <th className="text-center ">{size.label}
                                                                                                                                                {
                                                                                                                                                    Size === size.label ? <span className="text-danger">*</span> : ''
                                                                                                                                                }
                                                                                                                                            </th>
                                                                                                                                        )
                                                                                                                                    })
                                                                                                                                }
                                                                                                                                <th className="text-center ">Remarks</th>
                                                                                                                                {
                                                                                                                                    getHeader === 'Add' ?
                                                                                                                                        <th className="text-center Actionbutton fixed-column-header"
                                                                                                                                            style={{ display: MeasurementInfo && MeasurementInfo.isAdd === 0 && MeasurementInfo.isDelete === 0 ? 'none' : '' }}
                                                                                                                                        >Action</th> : ''
                                                                                                                                }
                                                                                                                            </tr>
                                                                                                                        </thead>
                                                                                                                        <tbody>
                                                                                                                            {
                                                                                                                                Catitem.Point.map((Pointitem, pointIndex) => {
                                                                                                                                    return (
                                                                                                                                        <>
                                                                                                                                            <tr key={pointIndex}>
                                                                                                                                                <td>{pointIndex+1}</td>
                                                                                                                                                <td className=''>
                                                                                                                                                    <Reactselect
                                                                                                                                                        className="basic-single"
                                                                                                                                                        name={"MeasurementPoints"}
                                                                                                                                                        id={
                                                                                                                                                            "MeasurementPoints" + pointIndex
                                                                                                                                                        }
                                                                                                                                                        // value={Pointitem.MeasurementPoints}
                                                                                                                                                        isDisabled={false}
                                                                                                                                                        isLoading={false}
                                                                                                                                                        isClearable={true}
                                                                                                                                                        isSearchable={true}
                                                                                                                                                        options={getMeasurement}
                                                                                                                                                        value={getMeasurement.filter(function (option) {
                                                                                                                                                            return option.value === Pointitem.MeasurementPoints;
                                                                                                                                                        })}
                                                                                                                                                        onChange={event => pointInputChange(event, index, catIndex, pointIndex, 'MeasurementPoints')}
                                                                                                                                                        onMenuOpen={event => controlOverflow(event, index, catIndex, pointIndex, 'MeasurementPoints')}
                                                                                                                                                        //  menuPosition="fixed"
                                                                                                                                                        menuPosition="fixed"
                                                                                                                                                        styles={childSubmitted && (Pointitem.MeasurementPoints === 0 || Pointitem.MeasurementPoints === '' ||
                                                                                                                                                            Pointitem.MeasurementPoints === '0' || Pointitem.MeasurementPoints === undefined || Pointitem.MeasurementPoints === null) ? styles2 : styles1}
                                                                                                                                                        // style={{
                                                                                                                                                        //     width: "550px",
                                                                                                                                                        //     border: submitted ? '1px solid red' : ''
                                                                                                                                                        // }}
                                                                                                                                                        isOptionDisabled={(option) => option.isdisabled}

                                                                                                                                                    ></Reactselect>

                                                                                                                                                    {/* <select id={"MeasurementPoints" + catIndex}
                                                                                                                                        name="MeasurementPoints"
                                                                                                                                        className="form-select"
                                                                                                                                        value={Pointitem.MeasurementPoints}
                                                                                                                                        onChange={event => pointInputChange(event, index, catIndex, pointIndex, 'MeasurementPoints')}
                                                                                                                                        onMenuOpen={event => controlOverflow(event, index, catIndex, pointIndex, 'MeasurementPoints')}
                                                                                                                                        style={{ width: "250px",textTransform:"lowercase" }}

                                                                                                                                    >

                                                                                                                                        <option value="0">-Select Point-</option>
                                                                                                                                        {
                                                                                                                                            getMeasurement.map(item1 => (
                                                                                                                                                <option key={
                                                                                                                                                    item1.id
                                                                                                                                                }
                                                                                                                                                    value={
                                                                                                                                                        item1.id
                                                                                                                                                    }>
                                                                                                                                                    {
                                                                                                                                                        item1.name
                                                                                                                                                    }
                                                                                                                                                </option>
                                                                                                                                            ))
                                                                                                                                        }
                                                                                                                                    </select> */}

                                                                                                                                                </td>
                                                                                                                                                <td className=''>
                                                                                                                                                    <Input placeholder=""
                                                                                                                                                        id={
                                                                                                                                                            "MeasurementGranding" + pointIndex
                                                                                                                                                        }
                                                                                                                                                        name={
                                                                                                                                                            "MeasurementGranding"
                                                                                                                                                        }
                                                                                                                                                        value={Pointitem.MeasurementGranding}
                                                                                                                                                        type="text"
                                                                                                                                                        onChange={event => pointInputChange(event, index, catIndex, pointIndex, 'MeasurementGranding')}
                                                                                                                                                        maxLength="5" autoComplete="off" className='form-control'
                                                                                                                                                        style={{ width: "60px" }}


                                                                                                                                                    />
                                                                                                                                                </td>
                                                                                                                                                <td className=''>
                                                                                                                                                    <Input
                                                                                                                                                        //className=""
                                                                                                                                                        placeholder=""
                                                                                                                                                        id={
                                                                                                                                                            "TolerancePlus" + pointIndex
                                                                                                                                                        }
                                                                                                                                                        name={
                                                                                                                                                            "TolerancePlus"
                                                                                                                                                        }
                                                                                                                                                        value={Pointitem.TolerancePlus}
                                                                                                                                                        type="text"
                                                                                                                                                        onChange={event => pointInputChange(event, index, catIndex, pointIndex, 'TolerancePlus')}
                                                                                                                                                        maxLength="5" autoComplete="off" className='form-control sizePadding'
                                                                                                                                                        style={{
                                                                                                                                                            width: "60px",
                                                                                                                                                            border: childSubmitted && Pointitem.TolerancePlus === '' ? '1px solid red' : ''
                                                                                                                                                        }}
                                                                                                                                                    />
                                                                                                                                                </td>
                                                                                                                                                <td className=''>
                                                                                                                                                    <Input
                                                                                                                                                        //className=""
                                                                                                                                                        placeholder=""
                                                                                                                                                        id={
                                                                                                                                                            "ToleranceMinus" + pointIndex
                                                                                                                                                        }
                                                                                                                                                        name={
                                                                                                                                                            "ToleranceMinus"
                                                                                                                                                        }
                                                                                                                                                        value={Pointitem.ToleranceMinus}
                                                                                                                                                        type="text"
                                                                                                                                                        onChange={event => pointInputChange(event, index, catIndex, pointIndex, 'ToleranceMinus')}
                                                                                                                                                        maxLength="5" autoComplete="off" className='form-control sizePadding'
                                                                                                                                                        //style={{ width: "60px" }}

                                                                                                                                                        style={{
                                                                                                                                                            width: "60px",
                                                                                                                                                            border: childSubmitted && Pointitem.ToleranceMinus === '' ? '1px solid red' : ''
                                                                                                                                                        }}
                                                                                                                                                    />
                                                                                                                                                </td>

                                                                                                                                                {
                                                                                                                                                    getGradingSize.map((size, i) => {
                                                                                                                                                        return (
                                                                                                                                                            <td>
                                                                                                                                                                <Input

                                                                                                                                                                    id={
                                                                                                                                                                        size.label + "_" + pointIndex
                                                                                                                                                                    }
                                                                                                                                                                    name={
                                                                                                                                                                        size.label
                                                                                                                                                                    }
                                                                                                                                                                    value={
                                                                                                                                                                        Pointitem.arrGradingSizes[0] === undefined ? '' :
                                                                                                                                                                            Pointitem.arrGradingSizes[0][size.label]
                                                                                                                                                                    }
                                                                                                                                                                    disabled={item.MeasurementGrandingSize === 0 || item.MeasurementGrandingSize === '' ? '' : Pointitem.MeasurementGranding === '' ? '' :
                                                                                                                                                                        Size.toUpperCase() === size.label.toUpperCase() ? '' : getSizeDisabled.Disabled}
                                                                                                                                                                    onChange={event => sizeInputChange(event, index, catIndex, pointIndex, "GradingSize")}

                                                                                                                                                                    type="text"
                                                                                                                                                                    maxLength="5" autoComplete="off" className='form-control sizePadding'
                                                                                                                                                                    style={{
                                                                                                                                                                        width: "60px",
                                                                                                                                                                        border: childSubmitted && Pointitem.arrGradingSizes[0][size.label] === '' ? '1px solid red' : ''
                                                                                                                                                                    }}
                                                                                                                                                                // style={{ width: "60px", border: Size.toUpperCase() === size.label.toUpperCase() && submitted ? '1px solid red' : '' }}
                                                                                                                                                                />
                                                                                                                                                            </td>
                                                                                                                                                        );
                                                                                                                                                    })
                                                                                                                                                }

                                                                                                                                                <td>
                                                                                                                                                    <Input
                                                                                                                                                        placeholder="Remarks"
                                                                                                                                                        className="form-control"
                                                                                                                                                        name="Remark"
                                                                                                                                                        id={
                                                                                                                                                            "Remark" + pointIndex
                                                                                                                                                        }
                                                                                                                                                        value={
                                                                                                                                                            Pointitem.Remark
                                                                                                                                                        }
                                                                                                                                                        onChange={event => pointInputChange(event, index, catIndex, pointIndex, "Remark")}
                                                                                                                                                        style={{ width: "200px" }}></Input>
                                                                                                                                                </td>
                                                                                                                                                <td className='Actionbutton fixed-column' style={{ display: MeasurementInfo && MeasurementInfo.isAdd === 0 && MeasurementInfo.isDelete === 0 ? 'none' : '' }}>                                                                                                                                    <div style={{ width: "80px" }}>
                                                                                                                                                    {
                                                                                                                                                        <button style={{ display: MeasurementInfo && MeasurementInfo.isAdd === 1 ? '' : 'none' }} type="button" className="btn  btn-xs  btn-success" title="Add Points"
                                                                                                                                                            onClick={
                                                                                                                                                                () => handleRowAddFields(index, catIndex, pointIndex)
                                                                                                                                                            }>
                                                                                                                                                            <i className="fa fa-plus"></i>
                                                                                                                                                        </button>
                                                                                                                                                    }
                                                                                                                                                    &nbsp;
                                                                                                                                                    {
                                                                                                                                                        Catitem.Point.length !== 1 && (
                                                                                                                                                            <button style={{ display: MeasurementInfo && MeasurementInfo.isDelete === 1 ? '' : 'none' }} type="button" className="btn  btn-xs  btn-danger" title="Delete Points"
                                                                                                                                                                onClick={() => handleRowRemoveFields(index, catIndex, pointIndex)}>
                                                                                                                                                                <i className="fa fa-trash-o"></i>
                                                                                                                                                            </button>
                                                                                                                                                        )
                                                                                                                                                    }
                                                                                                                                                </div>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                        </>

                                                                                                                                    )
                                                                                                                                })
                                                                                                                            }
                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                }
                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                {

                                                                                                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                                                                                        <thead className="text-center">
                                                                                                                            <tr>
                                                                                                                                <th className="word_warp_padding text-center ">Points</th>
                                                                                                                                <th className="text-center ">Grading</th>
                                                                                                                                <th className="text-center ">Tol +</th>
                                                                                                                                <th className="text-center ">Tol -</th>

                                                                                                                                <th className="text-center ">Remarks</th>
                                                                                                                                <th className="text-center Actionbutton fixed-column-header">Action</th>
                                                                                                                            </tr>
                                                                                                                        </thead>
                                                                                                                        <tbody>
                                                                                                                            <tr><td colSpan="6" className='norecordfound'><span>No Records Found</span></td></tr>
                                                                                                                        </tbody>
                                                                                                                    </table>


                                                                                                                }

                                                                                                            </>
                                                                                                    }

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <Modal show={getQuickMasterName} size="md" dragable backdrop="static" keyboard={false} onHide={() => handlePopupClose()}>
                                                                                            <Modal.Header closeButton>
                                                                                                <Modal.Title>Add {getQuickMasterName}</Modal.Title>
                                                                                            </Modal.Header>
                                                                                            <Modal.Body>
                                                                                                {
                                                                                                    getQuickMasterName !== '' ?
                                                                                                        getQuickMasterName === 'Measurement Point' ?
                                                                                                            <MeasurementPointPopup
                                                                                                                MeasurementPointCallback={MeasurementPointCallback}
                                                                                                                getMeasurementPointList={getMeasurement}
                                                                                                                showpopup={true}
                                                                                                            /> :
                                                                                                            null
                                                                                                        : ''
                                                                                                }
                                                                                            </Modal.Body>
                                                                                        </Modal>


                                                                                    </>
                                                                                    : ''
                                                                            )
                                                                        })
                                                                    }
                                                                </Form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            ))
                        }

                    </div >

                    :
                    <CommonLoader />
            }
        </>

    )
}
export default Measurement;
