import React, { useState, useCallback } from 'react';
import Form from "react-validation/build/form";
import Nodify from '../Common/ReactNotification';
import { useSelector } from 'react-redux';
import TestFieldService from '../../services/Master/testfieldservice';

const FieldPopUp = ({ props, FieldOptions, FieldPopUpCallback }) => {
    const { user: currentUser } = useSelector((state) => state.auth);
    //const EmptyInputFields = { ColorDepthID: 0, ColorDepth: '' };
    const EmptyInputFields = { TestFieldID: 0, TestField: '' };

    const [inputFields, setInputFields] = useState([EmptyInputFields]);
    const values = [...inputFields];
    const [buttonLoader, setButtonLoader] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const handleInputChange = (event) => {
        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }
        values[0].TestField = inputText;
        setInputFields(values);
    }

    const SaveField = () => {
        //AddColorDepthCallback(true);
        setSubmitted(true);
        const values = [...inputFields];
        if (inputFields.length === 1 && inputFields[0].TestField === '') {
            setButtonLoader(false);
            setSubmitted(true);
            ValidationPopup("Please fill the mandatory(*) fields.");
        }
        else {
            var DuplicatedData = FieldOptions.filter((data => {
                return data.label == inputFields[0].TestField
            }));
            if (DuplicatedData.length > 0) {
                inputFields[0].TestField = "";
                Nodify('Warning!', 'warning', 'This Field is already exist.');
            }
            else {
                setSubmitted(false);
                let Params = { Createdby: currentUser.employeeinformationID, TestFieldInfo: inputFields };
                TestFieldService.InsertTestField(Params).then(res => {
                    if (res.data.outputResult === "1") {
                        FieldPopUpCallback(true, inputFields);
                    }
                });
            }
            setInputFields(values);
        }
    }

    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
        return false;
    }

    return (
        <div id="registration-form">
            <Form>
                <div className="row row_left10">
                    <div className="col-sm-12 col-md-12 divder_style">
                        <div className="form-group">
                            <label>Test Field Name<span className="text-danger">*</span>
                            </label>
                            <span className="input-icon icon-right">
                                <input type='test' className='form-control' name="TestField"
                                    id="TestField" rows="10" placeholder="Enter Test Field"
                                    onChange={event => handleInputChange(event)}
                                    style={{ border: submitted && !inputFields[0].TestField ? '1px solid red' : '' }}
                                    autoComplete="off" maxLength="100" value={inputFields[0].TestField} />
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className="buttons-preview" align="right">
                        <button type="button" className="btn btn-success" onClick={SaveField} disabled={buttonLoader}>
                            <i className="fa fa-check right"></i>&nbsp;
                            Save
                        </button>
                    </div>
                </div>
            </Form>
        </div>
    )
};
export default FieldPopUp;
