import axios from "axios";
import authHeader from "../auth-header";

class TestingReportItemServices {

 getAllTestingItemList() {
  return axios.get(window.$APIBaseURL + "Master/getAllTestingItemList?ItemID=0", {
   headers: {
    authorization: authHeader()
   }
  });
 }

 TestReportInsertUpdateItem(Params) {

  return axios({
   method: 'post',
   url: window.$APIBaseURL + 'Master/TestReportInsertUpdateItem',
   data: Params,
   headers: {
    authorization: authHeader()
   }
  });
 }

 AddTestingItem(Params) {
  return axios({
   method: 'post',
   url: window.$APIBaseURL + 'Master/InsertUpdateDelTestingitem',
   data: Params,
   headers: {
    authorization: authHeader()
   }
  });
 }




}


export default new TestingReportItemServices()