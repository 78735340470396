

import {
    BRAND_WISE_STANDARD_MASTER_LOADING,
    RETRIEVE_BRAND_WISE_STANDARD_MASTER,
    DELETE_BRAND_WISE_STANDARD_MASTER,
} from "./types";

import BrandWiseStandardService from "../services/Master/Brandwisestandard";

const BrandWiseStandardLoading = (isStatus) => ({
    type: BRAND_WISE_STANDARD_MASTER_LOADING,
    payload: isStatus,
});

export const deleteBrandWiseStandard = (standard) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_BRAND_WISE_STANDARD_MASTER,
            payload: standard,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrieveBrandWiseStandard = () => async (dispatch) => {
    try {
        dispatch(BrandWiseStandardLoading(true));
        const res = await BrandWiseStandardService.LoadBrandWiseStandardMasterList();
        dispatch({
            type: RETRIEVE_BRAND_WISE_STANDARD_MASTER,
            payload: res.data,
        });
        dispatch(BrandWiseStandardLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(BrandWiseStandardLoading(false));
    }
};