import {
    CURRENCY_LOADING,
    RETRIEVE_CURRENCY,
    DELETE_CURRENCY,
} from "./types";
import CurrencyService from "../services/Master/CurrencyService";

const currencyLoading = (isStatus) => ({
    type: CURRENCY_LOADING,
    payload: isStatus,
});

export const deleteCurrency = (currencyID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_CURRENCY,
            payload: currencyID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrieveCurrency = (currencyID) => async (dispatch) => {
    try {
        dispatch(currencyLoading(true));
        const res = await CurrencyService.GetCurrencyList(currencyID);
        dispatch({
            type: RETRIEVE_CURRENCY,
            payload: res.data,
        });
        dispatch(currencyLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(currencyLoading(false));
    }
};