import React, { useState } from 'react';
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import { Button } from "react-bootstrap";
import MeasurementPointService from "../../services/Master/MeasurementPointService";
import Nodify from "../Common/ReactNotification";
import { useSelector } from "react-redux";

const MeasurementPointPopup = ({
    getMeasurementPointList,
    MeasurementPointCallback,
    showpopup,
}) => {
    const EmptyMeasurementPointInputFields =
        [{
            Id: 0,
            Name: '',
        }]
    const { user: currentUser } = useSelector((state) => state.auth);

    const [buttonLoader, setButtonLoader] = useState(false);
    const [measurementPointInputFields, setMeasurementPointInputFields] = useState(EmptyMeasurementPointInputFields);
    const [measurementPointList, setmeasurementPointList] = useState([]);
    const [measurementSubmitted, setMeasurementSubmitted] = useState(false);

    const handlePopupClose = () => {
        MeasurementPointCallback(false);
    };

    const CheckDuplicate = (index) => {
        const values = [...measurementPointInputFields];
        if (getMeasurementPointList) {
            for (var i = 0; i < getMeasurementPointList.length; i++) {
                if (getMeasurementPointList[i].label.trim().toLowerCase() === values[index].Name.trim().toLowerCase()) {
                    values[index].Name = '';
                    // values[index].Id=0;
                    setMeasurementPointInputFields(values);
                    setmeasurementPointList({ Createdby: currentUser.employeeinformationID, MasterInformation: values });
                    Nodify('Warning!', 'warning', "This name is already exist.");
                    return false;
                }
            }
        }
    }

    const handleGroupTypeChange = (event) => {
        const values = [...measurementPointInputFields];
        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }
        values[0].Name = inputText;
        setMeasurementPointInputFields(values);
        setmeasurementPointList({ Createdby: currentUser.employeeinformationID, MasterInformation: values });
    }

    const SaveMeasurementPoint = (e) => {
        setButtonLoader(true);
        e.preventDefault();
        const values = [...measurementPointInputFields];
        if (values[0].Name.trim() === '') {
            setMeasurementSubmitted(false);
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Measurement Point Name is required.');
        }
        if (values[0].Name.trim()) {
            MeasurementPointService.InsertUpdateMeasurePonitsGrid(measurementPointList).then(res => {
                if (res.data.outputResult === "1") {
                    Nodify("Success!", "success", "Measurement Point Name added successfully.");
                    MeasurementPointCallback(true);
                } else if (res.data.outputResult === "-2") {

                    Nodify('Warning!', 'warning', 'This name is already exist.');
                }
                setButtonLoader(false);
            });
        }
        else {
            setMeasurementSubmitted(true);
        }
    }

    return (
        // <Modal show={showpopup} size="md" dragable backdrop="static" keyboard={false} onHide={() => handlePopupClose()}>
        //     <Modal.Header closeButton>
        //     <Modal.Title>Add Gender</Modal.Title>
        //     </Modal.Header>
        //       <Modal.Body>
        <div id="registration-form">
            <Form>
                <div className="row">
                    <div className="form-group col-sm-12">
                        <label htmlFor="Name">
                        Measurement Point Name
                            <span className="text-danger">*</span>
                        </label>
                        <Input
                            placeholder={"Enter Measurement Point Name"}
                            id="Name"
                            name="Name"
                            type="text"
                            maxLength="100"
                            value={measurementPointInputFields[0].Name}
                            autoComplete="off"
                            onChange={handleGroupTypeChange}
                            className='form-control'
                            onBlur={() => CheckDuplicate(0)}
                            style={{ border: measurementSubmitted && !measurementPointInputFields[0].Name ? '1px solid red' : '' }}
                            autoFocus
                        />
                    </div>
                </div>
                <div style={{ textAlign: "right" }}>
                    <Button type="button" className="btn btn-success" onClick={SaveMeasurementPoint} disabled={buttonLoader}>
                        <i className="fa fa-check right"></i> &nbsp; Save
                    </Button>
                </div>
            </Form>
        </div>
        //     //   </Modal.Body>
        //     //   <Modal.Footer>
        //     //     <Button  type="submit" className="btn btn-success" onClick={SaveGenderSize} disabled={buttonLoader}>
        //     //        <i className="fa fa-check right"></i> &nbsp; Save
        //     //     </Button>
        //     //   </Modal.Footer>
        // </Modal>
    )
};

export default MeasurementPointPopup;