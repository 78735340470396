import axios from "axios";
import authHeader from "../auth-header";

class AqlFinalInspectionService {

    GetAqlFinalInspection(Id, Operation) {
        return axios.get(window.$APIBaseURL + "Master/GetAqlFinalInspectionList?AqlInspectionID=" + Id + "&Operation=" + Operation, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateAqlFinalInspectionGrd(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateAqlFinalInspectionGrd',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

}


export default new AqlFinalInspectionService()