import React, { Fragment, useState, useMemo, useEffect } from "react";
import Reactselect from 'react-select';
import FinalinspectionService from "../../../../services/FinalInspection/FinalinspectionService";
import DocumentUpload from "../../../Common/FinalinspecUpload";
import Lightbox from "react-image-lightbox"
import Nodify from "../../../Common/ReactNotification";
import useFullPageLoader from "../../../hooks/useFullPageLoader";

const FinalInspectionPackingImages = ({ props, InspectionID, PackingImageCallback, BasicDetails, rerenderdep }) => {

    const [PackagingInputField, setPackagingInputField] = useState([]);
    const [PackagingImagesExpandState, setPackagingImagesState] = useState({});
    const [PackagingImagesExpandedRows, setPackagingImagesExpandedRows] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenBack, setIsOpenBack] = useState(false);
    const [getImagenameBack, setImagenameBack] = useState();
    const [isOpenUPL, setIsOpenUPL] = useState(false);
    const [getImagenameUPL, setImagenameUPL] = useState();
    const [isOpenMaster, setIsOpenMaster] = useState(false);
    const [getImagenameMaster, setImagenameMaster] = useState();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [getImagename, setImagename] = useState();
    const [Isdataload, setIsdataload] = useState(false);
    const inpsectionId = JSON.parse(localStorage.getItem("InspectionID"));

    useEffect(() => {

        let PackingImagedata = [];
        if (props.location.state.params.TaskStatus === 1) {
           
            showLoader();
            FinalinspectionService.GetTNAFinalinspecMainInfo(inpsectionId, props.location.state.params.details.tnaid).then((res) => {
                if (res.data.finalInsPackingImageTab.length > 0) {
                    res.data.finalInsPackingImageTab.map((x, index) => {
                        PackingImagedata.push({
                            TNAFinalInspecPackingImageID: x.tnaFinalInspecPackingImageID,
                            ProductPictureName: x.productPictureName,
                            PackingImageIndex: x.packingImageIndex,
                            PurchaseOrderSkuID: x.purchaseOrderSkuID,
                            ImagesList: []
                        })
                        res.data.finalInsPackingImageSubList.filter(y => y.packingImageIndex === x.packingImageIndex).map((y, i) => {
                            PackingImagedata[index].ImagesList.push({
                                TNAFinalInspecPacimageSubID: y.tnaFinalInspecPacimageSubID,
                                UploadedImagePath: y.uploadedImagePath,
                                skuFrontPicPath: y.skuFrontPicPath,
                                PackingImageIndex: y.packingImageIndex,
                                ImageUniqueID: y.imageUniqueID,
                                DisplaySkuName: y.displaySkuName,
                                skuBackPicPath: y.skuBackPicName,
                                PackingUploadImageList: []
                            })
                            res.data.finalPackingTabfileList.filter(y => y.packingMainindex === x.packingImageIndex && y.subIndex === i).map((chl, chlindex) => {
                                PackingImagedata[index].ImagesList[i].PackingUploadImageList.push({
                                    TNAFinalInspecpackingImageID: chl.tnaFinalInspecpackingImageID,
                                    UploadedImagePath: chl.uploadedImagePath,
                                    PackingImageIndex: index,
                                    ImageIndex: chlindex
                                })
                            })
                        })
                    })


                    let SkuItems = []; let Poskuids = ''; let SlpittedSkuItems = [];
                    if (props.location.state.params.PurchaseOrderSkuID.includes(',')) {
                        SlpittedSkuItems = BasicDetails.PurchaseOrderSkuID.split(',')
                        //  SkuItems = BasicDetails.PurchaseOrderSkuID.split(',');
                        for (var i = 0; i <= SlpittedSkuItems.length; i++) {

                            if (PackingImagedata.filter(x => parseInt(x.PurchaseOrderSkuID) === parseInt(SlpittedSkuItems[i])).length > 0) {
                                SkuItems.splice(0, 1);
                            }


                        }
                        if (SkuItems.length > 0) {
                            if (SkuItems.length === 1) {
                                Poskuids = SkuItems[0];
                            }
                            else {
                                SkuItems.map(si => {
                                    if (Poskuids === '') {
                                        Poskuids = si;
                                    }
                                    else {
                                        Poskuids = Poskuids + "," + si
                                    }
                                })
                            }
                        }
                    }
                    else {
                        var PurchaseOrderSkuID = BasicDetails.PurchaseOrderSkuID

                        if (PackingImagedata.filter(x => parseInt(x.PurchaseOrderSkuID) === parseInt(BasicDetails.PurchaseOrderSkuI)).length === 0) {
                            Poskuids = PurchaseOrderSkuID;
                        }
                    }
                    if (Poskuids !== '') {
                        let CurrIndex = PackingImagedata.length;
                        FinalinspectionService.GetTNAFinalinspecPacking(2, props.location.state.params.buyerID, props.location.state.params.brandID,
                            props.location.state.params.seasonID, props.location.state.params.details.tnaid,
                            Poskuids).then((res) => {
                                res.data.packingRes3.map((x, index) => {
                                    PackingImagedata.push({
                                        TNAFinalInspecPackingImageID: 0,
                                        ProductPictureName: x.skuName,
                                        PackingImageIndex: CurrIndex,
                                        PurchaseOrderSkuID: x.purchaseOrderSkuID,
                                        ImagesList: []
                                    })
                                    PackingImagedata[CurrIndex].ImagesList.push({
                                        TNAFinalInspecPacimageSubID: 0,
                                        UploadedImagePath: "",
                                        skuFrontPicPath: x.skuFrontPicName,
                                        PackingImageIndex: CurrIndex,
                                        ImageUniqueID: x.imageUnique,
                                        DisplaySkuName: x.displaySkuName,
                                        skuBackPicPath: x.skuBackPicName,
                                        PackingUploadImageList: []
                                    })
                                    let currindex = PackingImagedata[CurrIndex].ImagesList.length - 1;
                                    PackingImagedata[CurrIndex].ImagesList[currindex].PackingUploadImageList.push({
                                        TNAFinalInspecpackingImageID: 0,
                                        UploadedImagePath: "",
                                        PackingImageIndex: CurrIndex,
                                        ImageIndex: 0
                                    })
                                    CurrIndex++;
                                })

                                let uniquevalues = [...new Map(PackingImagedata.map(item =>
                                    [item['ProductPictureName'], item])).values()];
                                setPackagingInputField(uniquevalues);
                                PackingImageCallback(uniquevalues);
                                if (uniquevalues.length > 0) {
                                    setIsdataload(true);
                                }
                                hideLoader();
                            })

                    }
                    else {
                        setPackagingInputField(PackingImagedata);
                        PackingImageCallback(PackingImagedata);
                        if (PackingImagedata.length > 0) {
                            setIsdataload(true);
                        }
                        hideLoader();
                    }
                } else {
                   
                    FinalinspectionService.GetTNAFinalinspecPacking(2, props.location.state.params.buyerID, props.location.state.params.brandID,
                        props.location.state.params.seasonID, props.location.state.params.details.tnaid,
                        props.location.state.params.PurchaseOrderSkuID).then((res) => {
                            let mainindex = 0;
                            res.data.packingRes3.map((x, index) => {

                                if (PackingImagedata.length === 0) {
                                    PackingImagedata.push({
                                        TNAFinalInspecPackingImageID: 0,
                                        ProductPictureName: x.skuName,
                                        PackingImageIndex: mainindex,
                                        PurchaseOrderSkuID: x.purchaseOrderSkuID,
                                        ImagesList: []
                                    })
                                    PackingImagedata[mainindex].ImagesList.push({
                                        TNAFinalInspecPacimageSubID: 0,
                                        UploadedImagePath: "",
                                        skuFrontPicPath: x.skuFrontPicName,
                                        PackingImageIndex: mainindex,
                                        ImageUniqueID: x.imageUnique,
                                        DisplaySkuName: x.displaySkuName,
                                        skuBackPicPath: x.skuBackPicName,
                                        PackingUploadImageList: []
                                    })
                                    let currindex = PackingImagedata[mainindex].ImagesList.length - 1;
                                    PackingImagedata[mainindex].ImagesList[currindex].PackingUploadImageList.push({
                                        TNAFinalInspecpackingImageID: 0,
                                        UploadedImagePath: "",
                                        PackingImageIndex: mainindex,
                                        ImageIndex: 0
                                    })
                                    mainindex++;
                                }
                                else {
                                    if (PackingImagedata.filter(p => p.ProductPictureName === x.skuName).length === 0) {

                                        PackingImagedata.push({
                                            TNAFinalInspecPackingImageID: 0,
                                            ProductPictureName: x.skuName,
                                            PackingImageIndex: mainindex,
                                            PurchaseOrderSkuID: x.purchaseOrderSkuID,
                                            ImagesList: []
                                        })

                                        PackingImagedata[mainindex].ImagesList.push({
                                            TNAFinalInspecPacimageSubID: 0,
                                            UploadedImagePath: "",
                                            skuFrontPicPath: x.skuFrontPicName,
                                            PackingImageIndex: mainindex,
                                            ImageUniqueID: x.imageUnique,
                                            DisplaySkuName: x.displaySkuName,
                                            skuBackPicPath: x.skuBackPicName,
                                            PackingUploadImageList: []
                                        })
                                        let currindex = PackingImagedata[mainindex].ImagesList.length - 1;
                                        PackingImagedata[mainindex].ImagesList[currindex].PackingUploadImageList.push({
                                            TNAFinalInspecpackingImageID: 0,
                                            UploadedImagePath: "",
                                            PackingImageIndex: mainindex,
                                            ImageIndex: 0
                                        })
                                        mainindex++;
                                    }
                                    else {
                                        let existingindex = PackingImagedata.findIndex(i => i.ProductPictureName === x.skuName);
                                        PackingImagedata[existingindex].ImagesList.push({
                                            TNAFinalInspecPacimageSubID: 0,
                                            UploadedImagePath: "",
                                            skuFrontPicPath: x.skuFrontPicName,
                                            PackingImageIndex: existingindex,
                                            ImageUniqueID: x.imageUnique,
                                            DisplaySkuName: x.displaySkuName,
                                            skuBackPicPath: x.skuBackPicName,
                                            PackingUploadImageList: []
                                        })
                                        let currindex = PackingImagedata[existingindex].ImagesList.length - 1;
                                        PackingImagedata[existingindex].ImagesList[currindex].PackingUploadImageList.push({
                                            TNAFinalInspecpackingImageID: 0,
                                            UploadedImagePath: "",
                                            PackingImageIndex: mainindex,
                                            ImageIndex: 0
                                        })
                                    }
                                }

                            })
                            // let uniquevalues = [...new Map(PackingImagedata.map(item =>
                            //     [item['ProductPictureName'], item])).values()];
                            setPackagingInputField(PackingImagedata);
                            PackingImageCallback(PackingImagedata);
                            if (PackingImagedata.length > 0) {
                                setIsdataload(true);
                            }
                            hideLoader();
                        })
                }
            })


        }

    }, [rerenderdep])

    useEffect(() => {
       
        let PackingImagedata = [];
        if (props.location.state.params.TaskStatus === 2) {
            showLoader();
            FinalinspectionService.GetTNAFinalinspecMainInfo(props.location.state.params.TNAInspectionID, props.location.state.params.details.tnaid).then((res) => {
                if (res.data.finalInsPackingImageTab.length > 0) {
                    res.data.finalInsPackingImageTab.map((x, index) => {
                        PackingImagedata.push({
                            TNAFinalInspecPackingImageID: x.tnaFinalInspecPackingImageID,
                            ProductPictureName: x.productPictureName,
                            PackingImageIndex: x.packingImageIndex,
                            PurchaseOrderSkuID: x.purchaseOrderSkuID,
                            ImagesList: []
                        })
                        res.data.finalInsPackingImageSubList.filter(y => y.packingImageIndex === x.packingImageIndex).map((y, i) => {
                            PackingImagedata[index].ImagesList.push({
                                TNAFinalInspecPacimageSubID: y.tnaFinalInspecPacimageSubID,
                                UploadedImagePath: y.uploadedImagePath,
                                skuFrontPicPath: y.skuFrontPicPath,
                                PackingImageIndex: y.packingImageIndex,
                                ImageUniqueID: y.imageUniqueID,
                                DisplaySkuName: y.displaySkuName,
                                skuBackPicPath: y.skuBackPicName,
                                PackingUploadImageList: []
                            })
                            res.data.finalPackingTabfileList.filter(chl => chl.packingMainindex === y.packingImageIndex && chl.subIndex === i).map((chl, chlindex) => {
                                PackingImagedata[index].ImagesList[i].PackingUploadImageList.push({
                                    TNAFinalInspecpackingImageID: chl.tnaFinalInspecpackingImageID,
                                    UploadedImagePath: chl.uploadedImagePath,
                                    PackingImageIndex: index,
                                    ImageIndex: chlindex
                                })
                            })
                        })
                    })
                    let SkuItems = []; let Poskuids = ''; let SlpittedSkuItems = [];
                    if (props.location.state.params.PurchaseOrderSkuID.toString().includes(',')) {
                        SlpittedSkuItems = props.location.state.params.PurchaseOrderSkuID.split(',')
                        //SkuItems = props.location.state.params.PurchaseOrderSkuID.split(',');
                        for (var i = 0; i <= SlpittedSkuItems.length; i++) {
                            if (PackingImagedata.filter(x => parseInt(x.PurchaseOrderSkuID) === parseInt(SlpittedSkuItems[i])).length > 0) {
                                SkuItems.splice(0, 1);
                            }

                        }

                        if (SkuItems.length > 0) {
                            if (SkuItems.length === 1) {
                                Poskuids = SkuItems[0];
                            }
                            else {
                                SkuItems.map(si => {
                                    if (Poskuids === '') {
                                        Poskuids = si;
                                    }
                                    else {
                                        Poskuids = Poskuids + "," + si
                                    }
                                })
                            }
                        }
                    }
                    else {
                        var PurchaseOrderSkuID = props.location.state.params.PurchaseOrderSkuID

                        if (PackingImagedata.filter(x => parseInt(x.PurchaseOrderSkuID) === parseInt(PurchaseOrderSkuID)).length === 0) {
                            Poskuids = PurchaseOrderSkuID;
                        }
                    }
                    if (Poskuids !== '') {
                        let CurrIndex = PackingImagedata.length;
                        FinalinspectionService.GetTNAFinalinspecPacking(2, props.location.state.params.buyerID, props.location.state.params.brandID,
                            props.location.state.params.seasonID, props.location.state.params.details.tnaid,
                            Poskuids).then((res) => {
                                res.data.packingRes3.map((x, index) => {
                                    PackingImagedata.push({
                                        TNAFinalInspecPackingImageID: 0,
                                        ProductPictureName: x.skuName,
                                        PackingImageIndex: CurrIndex,
                                        PurchaseOrderSkuID: x.purchaseOrderSkuID,
                                        ImagesList: []
                                    })
                                    PackingImagedata[CurrIndex].ImagesList.push({
                                        TNAFinalInspecPacimageSubID: 0,
                                        UploadedImagePath: "",
                                        skuFrontPicPath: x.skuFrontPicName,
                                        PackingImageIndex: CurrIndex,
                                        ImageUniqueID: x.imageUnique,
                                        DisplaySkuName: x.displaySkuName,
                                        skuBackPicPath: x.skuBackPicName,
                                        PackingUploadImageList: []
                                    })
                                    PackingImagedata[CurrIndex].ImagesList[0].PackingUploadImageList.push({
                                        TNAFinalInspecpackingImageID: 0,
                                        UploadedImagePath: "",
                                        PackingImageIndex: CurrIndex,
                                        ImageIndex: 0
                                    })
                                    CurrIndex++;
                                })
                                let uniquevalues = [...new Map(PackingImagedata.map(item =>
                                    [item['ProductPictureName'], item])).values()];
                                setPackagingInputField(uniquevalues);
                                PackingImageCallback(uniquevalues);
                                if (uniquevalues.length > 0) {
                                    setIsdataload(true);
                                }
                                hideLoader();
                            })

                    }
                    else {
                        setPackagingInputField(PackingImagedata);
                        PackingImageCallback(PackingImagedata);
                        if (PackingImagedata.length > 0) {
                            setIsdataload(true);
                        }
                        hideLoader();
                    }
                } else {
                   
                    FinalinspectionService.GetTNAFinalinspecPacking(2, props.location.state.params.buyerID,
                        props.location.state.params.brandID, props.location.state.params.seasonID,
                        props.location.state.params.details.tnaid, props.location.state.params.PurchaseOrderSkuID).then((res) => {
                            let mainindex = 0;
                            res.data.packingRes3.map((x, index) => {
                                if (PackingImagedata.length === 0) {
                                    PackingImagedata.push({
                                        TNAFinalInspecPackingImageID: 0,
                                        ProductPictureName: x.skuName,
                                        PackingImageIndex: mainindex,
                                        PurchaseOrderSkuID: x.purchaseOrderSkuID,
                                        ImagesList: []
                                    })
                                    PackingImagedata[mainindex].ImagesList.push({
                                        TNAFinalInspecPacimageSubID: 0,
                                        UploadedImagePath: "",
                                        skuFrontPicPath: x.skuFrontPicName,
                                        PackingImageIndex: mainindex,
                                        ImageUniqueID: x.imageUnique,
                                        DisplaySkuName: x.displaySkuName,
                                        skuBackPicPath: x.skuBackPicName,
                                        PackingUploadImageList: []
                                    })
                                    let currindex = PackingImagedata[mainindex].ImagesList.length - 1;
                                    PackingImagedata[mainindex].ImagesList[currindex].PackingUploadImageList.push({
                                        TNAFinalInspecpackingImageID: 0,
                                        UploadedImagePath: "",
                                        PackingImageIndex: mainindex,
                                        ImageIndex: 0
                                    })

                                    mainindex++;
                                }
                                else {
                                    if (PackingImagedata.filter(p => p.ProductPictureName === x.skuName).length === 0) {

                                        PackingImagedata.push({
                                            TNAFinalInspecPackingImageID: 0,
                                            ProductPictureName: x.skuName,
                                            PackingImageIndex: mainindex,
                                            PurchaseOrderSkuID: x.purchaseOrderSkuID,
                                            ImagesList: []
                                        })

                                        PackingImagedata[mainindex].ImagesList.push({
                                            TNAFinalInspecPacimageSubID: 0,
                                            UploadedImagePath: "",
                                            skuFrontPicPath: x.skuFrontPicName,
                                            PackingImageIndex: index,
                                            ImageUniqueID: x.imageUnique,
                                            DisplaySkuName: x.displaySkuName,
                                            skuBackPicPath: x.skuBackPicName,
                                            PackingUploadImageList: []
                                        })
                                        let currindex = PackingImagedata[mainindex].ImagesList.length - 1;
                                        PackingImagedata[mainindex].ImagesList[currindex].PackingUploadImageList.push({
                                            TNAFinalInspecpackingImageID: 0,
                                            UploadedImagePath: "",
                                            PackingImageIndex: mainindex,
                                            ImageIndex: 0
                                        })
                                        mainindex++;
                                    }
                                    else {
                                        let existingindex = PackingImagedata.findIndex(i => i.ProductPictureName === x.skuName);

                                        PackingImagedata[existingindex].ImagesList.push({
                                            TNAFinalInspecPacimageSubID: 0,
                                            UploadedImagePath: "",
                                            skuFrontPicPath: x.skuFrontPicName,
                                            PackingImageIndex: existingindex,
                                            ImageUniqueID: x.imageUnique,
                                            DisplaySkuName: x.displaySkuName,
                                            skuBackPicPath: x.skuBackPicName,
                                            PackingUploadImageList: []
                                        })
                                        let currindex = PackingImagedata[existingindex].ImagesList.length - 1;
                                        //  for (let i = 0; i < PackingImagedata[existingindex].ImagesList.length; i++) {
                                        PackingImagedata[existingindex].ImagesList[currindex].PackingUploadImageList.push({
                                            TNAFinalInspecpackingImageID: 0,
                                            UploadedImagePath: "",
                                            PackingImageIndex: existingindex,
                                            ImageIndex: 0
                                        })
                                        // }
                                    }
                                }
                            })
                            // let uniquevalues = [...new Map(PackingImagedata.map(item =>
                            //     [item['ProductPictureName'], item])).values()];
                            setPackagingInputField(PackingImagedata);
                            PackingImageCallback(PackingImagedata);
                            if (PackingImagedata.length > 0) {
                                setIsdataload(true);
                            }
                            hideLoader();
                        })
                }
            })


        }
    }, [rerenderdep])

    useEffect(() => {

        if (props.location.state.params.IsSkuRemoved &&
            props.location.state.params.IsSkuRemoved === true) {
            var PackingImagedata = [...PackagingInputField]
            var RemovedData = props.location.state.params.RemovedData.filter(x => x.IsDeleted !== 1);;
            PackingImagedata.map(ev => {
                if (RemovedData.filter(x => parseInt(x.purchaseOrderSkuID) === parseInt(ev.PurchaseOrderSkuID)).length === 0) {
                    let index = PackingImagedata.findIndex(x => x.PurchaseOrderSkuID === ev.PurchaseOrderSkuID);
                    PackingImagedata.splice(index, 1);

                }
            })
            setPackagingInputField(PackingImagedata);
            PackingImageCallback(PackingImagedata);
        }
    }, [Isdataload === true])

    const handlePackingPicturesExpandRow = (rowNumber) => {
        setPackagingImagesExpandedRows([]);
        let currentExpandedRows;
        let isRowExpanded = false;
        currentExpandedRows = PackagingImagesExpandedRows;
        isRowExpanded = currentExpandedRows.includes(rowNumber);
        let obj = {};
        isRowExpanded ? (obj[rowNumber] = false) : (obj[rowNumber] = true);
        setPackagingImagesState(obj);
        currentExpandedRows = [];
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== rowNumber) :
            currentExpandedRows.concat(rowNumber);
        setPackagingImagesExpandedRows(newExpandedRows);
    }
    const ImageCallback = (value, index, childindex, imgindex) => {
        var imagevalues = [...PackagingInputField]
        let profile = imagevalues !== '' ? "/Style/SKU/" : '';
        imagevalues[index].ImagesList[childindex].PackingUploadImageList[imgindex].PackingImageIndex = index;
        imagevalues[index].ImagesList[childindex].PackingUploadImageList[imgindex].UploadedImageName = value;
        imagevalues[index].ImagesList[childindex].PackingUploadImageList[imgindex].UploadedImagePath = profile + value;

        //imagevalues[index].UploadedImage = value
        setPackagingInputField(imagevalues)

    }
    const deleteFile = (index, childindex, imgindex) => {
        var imagevalues = [...PackagingInputField]
        imagevalues[index].ImagesList[childindex].PackingUploadImageList[imgindex].UploadedImageName = "";
        imagevalues[index].ImagesList[childindex].PackingUploadImageList[imgindex].UploadedImagePath = "";
        setPackagingInputField(imagevalues);
        PackingImageCallback(imagevalues)
    }
    function ViewFile(event, filename, index, child, FieldName) {
       
        if (FieldName === 'Front') {

            setIsOpen(true);
            setImagename(filename)
        } else if (FieldName === 'Back') {
            setIsOpenBack(true)
            setImagenameBack(filename)
        } else {
            setIsOpenMaster(true);
            setImagenameMaster(filename);
        }
    }
    function ViewFileUPl(event, filename, index) {
       
        // event.preventDefault();
        setIsOpenUPL(true);
        setImagenameUPL(filename);
    }

    function handleAddFields(index, childindex, imgindex, skuFrontPicPath, ImageUniqueID) {
       
        var imagevalues = [...PackagingInputField]
        let isvalid = true;
        if (imagevalues[index].ImagesList[childindex].PackingUploadImageList[imgindex].UploadedImagePath === "") {
            isvalid = false;
            Nodify('Warning!', 'warning', 'Please Upload the Image');
        }

        if (isvalid) {
            imagevalues[index].ImagesList[childindex].PackingUploadImageList.push({ skuFrontPicPath: skuFrontPicPath, ImageUniqueID: ImageUniqueID, UploadedImagePath: "", UploadedImageName: '', MainIndex: index })
        }
        setPackagingInputField(imagevalues);
        PackingImageCallback(imagevalues)
    }

    function handleRemoveFields(index, childindex, imgindex, length) {
       
        var imagevalues = [...PackagingInputField]
        if (length === 1) {
            imagevalues[index].ImagesList[childindex].PackingUploadImageList[imgindex].UploadedImageName = "";
            imagevalues[index].ImagesList[childindex].PackingUploadImageList[imgindex].UploadedImagePath = "";

        } else {
            imagevalues[index].ImagesList[childindex].PackingUploadImageList.splice(imgindex, 1);
        }
        setPackagingInputField(imagevalues);
        PackingImageCallback(imagevalues)
    }
    return (
        <Fragment>
            <div className="page-body" >
                <div className="row" >
                    <div className="row text-right mr-5">
                        <button className="btn btn-sm btn-primary">Customer Warehouse Code</button>&nbsp;
                        <button className="btn btn-sm btn-info">View Packing Guide</button>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-md-12">
                        {
                            PackagingInputField.map((comment, index) => (
                                <div className="row">
                                    <div className="panel-group accordion" id="acc_trims_Info">
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <h4 className="panel-title">
                                                    <a id={index} className="accordion-toggle collapsed clr-themegreen" data-toggle="collapse" data-parent="#acc_trims_Info" href={"#collapse_acc_trims_Info" + index}>
                                                        {comment.ProductPictureName}
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id={"collapse_acc_trims_Info" + index} className="panel-collapse collapse">
                                                <div id={index} className="" >
                                                    <div className="">
                                                        {
                                                            <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                <tbody>
                                                                    {
                                                                        comment.ImagesList.map((child, childindex) => (
                                                                            <Fragment>
                                                                                <tr key={
                                                                                    child.index
                                                                                }>

                                                                                    {

                                                                                        <td className="table-width-40">
                                                                                            {<span>Expected : Front
                                                                                                {
                                                                                                    child.ImageUniqueID === 1 ? ",Back(Sku Name - " + child.DisplaySkuName + ")" : ''
                                                                                                }
                                                                                            </span>}
                                                                                            <br />
                                                                                            <br />
                                                                                            {child.ImageUniqueID === 1 ?
                                                                                                <>
                                                                                                    <tr>
                                                                                                        <td>  <img src={window.$APIBaseURL + "Images" + child.skuFrontPicPath} alt="" width="150px" height="150px" />
                                                                                                            &nbsp; <i className="btn btn-info fa fa-eye FloatRight" title="View Logo" style={{ marginRight: "6px" }}
                                                                                                                onClick={
                                                                                                                    event => ViewFile(event, child.skuFrontPicPath, index, child, 'Front')
                                                                                                                }></i>
                                                                                                        </td>
                                                                                                        <td>  {child.skuBackPicPath !== '' &&
                                                                                                            <img src={window.$APIBaseURL + "Images" + child.skuBackPicPath} alt="" width="150px" height="150px" />}
                                                                                                            &nbsp;
                                                                                                            <i className="btn btn-info fa fa-eye FloatRight" title="View Logo" style={{ marginRight: "6px" }}
                                                                                                                onClick={
                                                                                                                    event => ViewFile(event, child.skuBackPicPath, index, child, 'Back')
                                                                                                                }></i>
                                                                                                        </td>
                                                                                                    </tr>

                                                                                                </>
                                                                                                :
                                                                                                <td><img src={window.$APIBaseURL + child.skuFrontPicPath} alt="" width="150px" height="150px" />&nbsp;
                                                                                                    <i className="btn btn-info fa fa-eye FloatRight" title="View Logo" style={{ marginRight: "6px" }}
                                                                                                        onClick={
                                                                                                            event => ViewFile(event, child.skuFrontPicPath, index, child, 'FromMaster')
                                                                                                        }></i></td>

                                                                                            }
                                                                                            {/* <i className="btn btn-info fa fa-eye FloatRight" title="View Logo" style={{ marginRight: "6px" }}
                                                                                                onClick={
                                                                                                    event => ViewFile(event, child.skuFrontPicPath, index)
                                                                                                }></i> */}
                                                                                            {
                                                                                                isOpen && (

                                                                                                    child.ImageUniqueID === 1 ? <Lightbox mainSrc={window.$APIBaseURL + 'Images' + getImagename}
                                                                                                        alt="bg image"
                                                                                                        onCloseRequest={
                                                                                                            () => setIsOpen(false)
                                                                                                        } />
                                                                                                        //          : <Lightbox mainSrc={window.$APIBaseURL + getImagename}
                                                                                                        //             alt="bg image"
                                                                                                        //             onCloseRequest={
                                                                                                        //                 () => setIsOpen(false)
                                                                                                        //             } />
                                                                                                        : '')
                                                                                            }
                                                                                            {
                                                                                                isOpenBack && (

                                                                                                    child.ImageUniqueID === 1 ? <Lightbox mainSrc={window.$APIBaseURL + 'Images' + getImagenameBack}
                                                                                                        alt="bg image"
                                                                                                        onCloseRequest={
                                                                                                            () => setIsOpenBack(false)
                                                                                                        } />
                                                                                                        : '')
                                                                                            }
                                                                                            {
                                                                                                isOpenMaster && (

                                                                                                    child.ImageUniqueID !== 1 ? <Lightbox mainSrc={window.$APIBaseURL + getImagenameMaster}
                                                                                                        alt="bg image"
                                                                                                        onCloseRequest={
                                                                                                            () => setIsOpenMaster(false)
                                                                                                        } />
                                                                                                        : '')
                                                                                            }
                                                                                        </td>

                                                                                    }
                                                                                    {<td className="table-width-60">
                                                                                        <div className="row">
                                                                                            <div className="col-md-6">
                                                                                                Found :
                                                                                            </div>

                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <td style={{ float: "right" }}>

                                                                                            </td>
                                                                                            {child.PackingUploadImageList.map((Images, imgindex) => (
                                                                                                <div className="text-center col-md-6">
                                                                                                    <span className="text-danger">Click on the image to upload new</span><br /><br />
                                                                                                    <div>
                                                                                                        <DocumentUpload UploadCallback={(e) => ImageCallback(e, index, childindex, imgindex)} PageName='Style/SKU' ShowDiv="1" filePath={Images.UploadedImagePath} />
                                                                                                    </div>
                                                                                                    {/* {Images.UploadedImagePath !== '' ? <div className="row">
                                                                                                        <div className="col-md-7">
                                                                                                            <i className="btn btn-danger fa fa-trash-o FloatRight" title="Delete Logo"
                                                                                                                style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}
                                                                                                                onClick={event => deleteFile(index, childindex, imgindex)}></i>
                                                                                                        </div>
                                                                                                    </div> : ''} */}


                                                                                                    {props.location.state.params.Action !== 'View' ? <span className="TnaFinaspecwrkmans" style={{ left: "120px" }}>


                                                                                                        <button type="button" style={{ display: PackagingInputField[index].ImagesList[childindex].PackingUploadImageList.length < 2 ? '' : 'none' }} className="btn btn-xs btn-success" title="Add packing Image"
                                                                                                            onClick={() => handleAddFields(index, childindex, imgindex, child.skuFrontPicPath, child.ImageUniqueID)}
                                                                                                        >
                                                                                                            <i className="fa fa-plus"></i>
                                                                                                        </button>
                                                                                                        &nbsp;
                                                                                                        {Images.UploadedImagePath !== '' ?
                                                                                                            <button className="btn btn-info fa fa-eye" style={{ padding: "5px 5px", width: "25px" }} onClick={
                                                                                                                event => ViewFileUPl(event, Images.UploadedImagePath, index,)
                                                                                                            }>
                                                                                                                <i title="View Logo" style={{ marginRight: "6px" }}
                                                                                                                ></i>

                                                                                                            </button>
                                                                                                            : ''}



                                                                                                        &nbsp;
                                                                                                        {/* {PackagingInputField[index].ImagesList[childindex].PackingUploadImageList.length !== 1 && ( */}
                                                                                                        <button type="button"
                                                                                                            className="btn btn-xs btn-danger" title="Delete packing Image"
                                                                                                            onClick={() => handleRemoveFields(index, childindex, imgindex, PackagingInputField[index].ImagesList[childindex].PackingUploadImageList.length)}
                                                                                                        >
                                                                                                            <i className="fa fa-trash-o"></i>
                                                                                                        </button>
                                                                                                        {/* )} */}
                                                                                                    </span> : ''}
                                                                                                </div>

                                                                                            ))}
                                                                                        </div>

                                                                                    </td>
                                                                                    }
                                                                                </tr>
                                                                            </Fragment>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            < div > {
                                                isOpenUPL && (

                                                    <Lightbox mainSrc={window.$APIBaseURL + 'Images' + getImagenameUPL}
                                                        alt="bg image"
                                                        onCloseRequest={
                                                            () => setIsOpenUPL(false)
                                                        } />
                                                )
                                            } </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        {
                            PackagingInputField.length === 0 ?
                                <div className="text-center">
                                    <span className="norecordfound">No Records Found</span>
                                </div>
                                // <tr>
                                //     <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
                                // </tr>
                                : ''
                        }
                    </div>
                </div>
            </div>
            {loader}
        </Fragment >
    )
};
export default FinalInspectionPackingImages;