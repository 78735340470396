import React, { useState, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import Reactselect from 'react-select';
import Nodify from "../../Common/ReactNotification"
import useFullPageLoader from "../../hooks/useFullPageLoader";
import $ from "jquery";
import Lightbox from "react-image-lightbox"
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";
import ProtoFitSMSApproval from '../../TNA/ProtoFitSMSApproval';

const StyleQCNotes = ({ props, StyleInfo, MailRedirect, QCNotesList, SKUSampleApproval, QCNotesCallback }) => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [submitted, setSubmitted] = useState(false);
    const [Buttonview, SetButtonview] = useState("Hide");
    const [getAttachmentIndexID, setAttachmentIndexID] = useState(0);
    const [getImagename, setImagename] = useState();
    const [getViewSamplesApproval, setViewSamplesApproval] = useState({ isShow: false, value: '' });
    const [isOpenimg, setIsOpenimg] = useState(false);
    const [getAddOrViewAttachment, setAddOrViewAttachment] = useState({ isShow: false, index: null });
    const [getSKUIDOptionList, setSKUIDOptionList] = useState([]);
    const SKUIDOptionList = useSelector((state) => state.style.SKUList.item1);
    const [getParentTask, setParentTask] = useState();
    const EmptyCCInputFields =
        [{
            QCNoteID: 0,
            IndexID: 0,
            skuid: 0,
            Comment: '',
            Attachment: [],
        }];

    const [getCCFields, setCCFields] = useState(EmptyCCInputFields);
    const values = [...getCCFields];

    useEffect(() => {
        if (SKUIDOptionList != undefined && SKUIDOptionList.length > 0) {
            setSKUIDOptionList(SKUIDOptionList.map((data => {
                data.id = data.skuID;
                data.value = data.skuID;
                data.label = data.skuName;
                data.name = data.skuName;
                return { id: data.id, name: data.name, label: data.label, value: data.value };
            })));
        }
    }, [SKUIDOptionList]);

    useEffect(() => {
        if (getSKUIDOptionList != undefined && QCNotesList != undefined && QCNotesList.item1 != undefined && QCNotesList.item1.length > 0) {
            setCCFields(QCNotesList.item1.map((data => {
                let QCskuID = data.skuid.split(",");
                let arrVal = QCskuID.map((data => {
                    if (getSKUIDOptionList.length > 0) {
                        let res = getSKUIDOptionList.filter(x => x.id === Number(data));
                        return {
                            value: res[0].id
                            , label: res[0].label
                        }
                    }
                }));
                return {
                    QCNoteID: data.qcNoteID
                    , IndexID: data.indexID
                    , SkuidforEdit: arrVal
                    , skuid: data.skuid
                    , Comment: data.comment
                    , Attachment: QCNotesList != undefined && QCNotesList.item2.length > 0 ? QCNotesList.item2.filter(((attachmentdata, atchindex) => {
                        if (data.indexID == attachmentdata.parentIndexID) {
                            let Atchmnt = {
                                attachmentID: attachmentdata.attachmentID
                                , fileName: attachmentdata.fileName
                                , filePath: attachmentdata.filePath
                                , fileType: attachmentdata.fileType
                                , indexID: attachmentdata.indexID
                                , parentIndexID: data.IndexID
                                , qcNoteID: attachmentdata.qcNoteID
                            }
                            return Atchmnt;
                        }
                    })) : []
                }
            })));
            const values = [...getCCFields];
            QCNotesCallback(values);
        }
    }, [QCNotesList, getSKUIDOptionList]);

    const handleChangeSKUID = (event, index) => {
        if (event.length === 0) {
            values[index].skuid = "";
            values[index].IndexID = index !== 0 ? values[index - 1].IndexID + 1 : index;
            values[index].SkuidforEdit = '';
        } else {
            let skuidValues = "";
            let skuNames = '';
    
            values[index].SkuidforEdit = event;
    
            for (let sku of event) {
                const { value, label } = sku;
    
                if (!skuidValues || !skuNames) {
                    skuidValues = value.toString();
                    skuNames = label;
                } else {
                    skuidValues += `,${value}`;
                    skuNames += `,${label}`;
                }
            }
    
            values[index].skuid = skuidValues;
            values[index].skuName = skuNames;
        }
    
        setCCFields([...values]); 
        QCNotesCallback(values);
    };
    

    const handleCommentChange = (e, index) => {
       ;
        const values = [...getCCFields];
        let inputText = '';
        if (e.target.value.trim() !== '') {
            inputText = e.target.value;
        }
        values[index].Comment = inputText;

        //values[index].Comment = e.target.value.trim();
        //values[index].Comment = e.target.value;
        values[index].IndexID = index != 0 ? values[index - 1].IndexID + 1 : index;
        setCCFields(values);
        QCNotesCallback(values);
    }


    const handleAddFields = (index) => {
       ;
        const values = [...getCCFields];
    
        let existingskuID = [];
        let RepeatedSkuIDs = 0;
        if (values.length > 1) {
            existingskuID.push(values.map((data => {
                if (typeof data.skuid === 'string') {
                    return data.skuid.split(',').sort().join(',');
                } else {
                    return ''; 
                }
            })))
            RepeatedSkuIDs = find_duplicate_in_array(existingskuID[0]).length;
        }
        if (RepeatedSkuIDs > 0) {
            Nodify('Warning!', 'warning', 'This SKU Name is Already Exist.');
            setSubmitted(true);
        } else {
            if (values[index].skuid !== 0 && values[index].skuid !== "") {
                let CurrentIndexID = values[index].IndexID + 1;
                values.push({
                    QCNoteID: 0,
                    IndexID: CurrentIndexID,
                    skuid: 0,
                    skuName: '',
                    Comment: '',
                    Attachment: [],
                    ParentIndex: 0
                });
                QCNotesCallback(values);
                setCCFields(values);
                setSubmitted(false);
            } else {
                Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
                setSubmitted(true);
            }
        }
    }
    
    function find_duplicate_in_array(arra1) {
        var object = {};
        var result = [];

        arra1.forEach(function (item) {
            if (!object[item])
                object[item] = 0;
            object[item] += 1;
        })

        for (var prop in object) {
            if (object[prop] >= 2) {
                result.push(prop);
            }
        }
        return result;
    }

    const handleRemoveFields = index => {
       ;
        const values = [...getCCFields];
       
       
        values[index].skuName = '';
        values[index].Comment = '';
        values.forEach((element, i) => {
            element.IndexID = i;
           
          });

          values.splice(index, 1);
      
          setCCFields(values);
    }

    const AddOrViewAttachment = index => {
        // setAddOrViewAttachment(true);
        setAttachmentIndexID(index);
        setAddOrViewAttachment({ isShow: true, index: index });
    }

    const handleCloseQCNotesFormPopup = index => {
        //  setAddOrViewAttachment(false);
        // if (Type === 'save') {
        //     setAddOrViewAttachment({ isShow: false, index: null });
        // } else {
        //     values[getAttachmentIndexID].Attachment = [];
        //     setCCFields(values);
        //     QCNotesCallback(values);
        //     setAddOrViewAttachment({ isShow: false, index: null });
        // }
        setAddOrViewAttachment({ isShow: false, index: null });

    }


    const ViewSamplesApproval = (SampleApproval) => {
        setParentTask(SampleApproval.taskName);
        setViewSamplesApproval({ isShow: true, value: SampleApproval });
    }

    const handleCloseSamplesApproval = () => {
        setViewSamplesApproval({ isShow: false, value: null });

    }

    let FileIndex = 0;
    const AppendFiles = async (e, index) => {
        const file = e.target.files[0];
        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword', "application/vnd.ms-excel",
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#FileUpload').val("");
            }
            else {
                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "QCNotesUpload");
                try {
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                    const values = [...getCCFields];
                    let ExitingQCNoteID = 0;
                    if (values[index].Attachment.length > 0) {
                        ExitingQCNoteID = values[index].Attachment[0].qcNoteID;
                    }
                    values[index].Attachment.push({
                        styleQCNotesFileId: 0
                        , fileName: res.data + "_" + file.name
                        , filePath: "Images/Style/QCNotesUpload/" + file.name
                        , fileType: file.type
                        , indexID: values[index].Attachment.length
                        , parentIndexID: values[index].IndexID
                        , attachmentID: 0
                        , qcNoteID: ExitingQCNoteID
                    })
                    QCNotesCallback(values);
                    setCCFields(values);
                    $('#FileUpload').val("");
                }
                catch (ex) {
                    console.log(ex);
                }
            }
        }
    }

    const ViewFile = (index, filename) => {
        setImagename(filename);
        setIsOpenimg(true);
    }

    const RemoveImages = async (index, fileindex) => {
       
        let values = [...getCCFields];
        values.filter((data, dataindex) => {
            if (index == dataindex) {
                data.Attachment.splice(fileindex, 1);
            }
        });
        QCNotesCallback(values);
        setCCFields(values);
    }

    return (
        <Fragment>
            <div className="page-body">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="panel-group accordion" id="style_QCNotes_Info">
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">
                                            <a className="accordion-toggle clr-themegreen" data-toggle="collapse" data-parent="#style_QCNotes_Info" href="#collapse_style_QCNotes_Info">
                                                Custom Comments
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse_style_QCNotes_Info" className="panel-collapse collapse in">
                                        <div className="panel-body border-red">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <table className="table table-bordered table-striped table-condensed flip-content">
                                                        <thead>
                                                            <tr>
                                                                <th>SKU Name<span className='text-danger'>*</span></th>
                                                                <th>Comments</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                getCCFields.length !== 0 ?
                                                                    getCCFields.map((ccinput, index) => (
                                                                        <>
                                                                            <tr>
                                                                                <td style={{ width: '25%' }}>
                                                                                    <Reactselect className="basic-single"
                                                                                        name={"ddlSKUID_" + index}
                                                                                        id={"ddlSKUID_" + index}
                                                                                        isDisabled={false}
                                                                                        isLoading={false}
                                                                                        isClearable={true}
                                                                                        isSearchable={true}
                                                                                        isMulti
                                                                                        menuPosition="fixed"
                                                                                        onChange={event => handleChangeSKUID(event, index)}
                                                                                        value={getCCFields.skuName}
                                                                                        //  value={getCCFields[index]?.skuName ? { label: getCCFields[index]?.skuName, value: getCCFields[index]?.skuName } : null} 
                                                                                        options={getSKUIDOptionList}
                                                                                       style={{ border: submitted && ccinput.SkuidforEdit == "" ? '1px solid red' : '' }}
                                                                                    >
                                                                                    </Reactselect>
                                                                                </td>
                                                                                <td style={{ width: '50%' }}>
                                                                                    <span className="input-icon icon-right">
                                                                                        <textarea type="text" style={{ width: '100%' }}
                                                                                            id={"txtComment_" + index}
                                                                                            name={"txtComment_" + index}
                                                                                            placeholder="Enter Comments"
                                                                                            maxLength="500"
                                                                                            value={ccinput.Comment}
                                                                                            rows={3}
                                                                                            onChange={event => handleCommentChange(event, index)} />
                                                                                    </span>
                                                                                    {/* <input type="text"
                                                                                        id={"txtComment_" + index}
                                                                                        autoComplete="off"
                                                                                        maxLength="200"
                                                                                        className={'form-control'}
                                                                                        name={"txtComment_" + index}
                                                                                        placeholder="Enter Comments"
                                                                                        value={ccinput.Comment}
                                                                                        onChange={event => handleCommentChange(event, index)} /> */}
                                                                                </td>
                                                                                <td style={{ width: '25%' }}>
                                                                                    {getCCFields.length === (index + 1) && <button type="button" onClick={() => handleAddFields(index)}
                                                                                        className="btn btn-xs btn-success" title="Add Task">
                                                                                        <i className="fa fa-plus"></i>
                                                                                    </button>}&nbsp;

                                                                                    {getCCFields.length !== 1 && <button type="button" onClick={() => handleRemoveFields(index)}
                                                                                        className="btn btn-xs btn-danger" title="Add Task">
                                                                                        <i className="fa fa-times"></i>
                                                                                    </button>
                                                                                    }&nbsp;
                                                                                    {<button type="button" onClick={() => AddOrViewAttachment(index)}
                                                                                        className="btn btn-xs btn-warning" title="Add QA Notes Attachment">
                                                                                        <i className="fa fa-paperclip"></i>{ccinput.Attachment.length > 0 && <>&nbsp;&nbsp;</>}{ccinput.Attachment.length > 0 ? ccinput.Attachment.length : ''}
                                                                                    </button>
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    ))
                                                                    : ''
                                                            }
                                                        </tbody>
                                                    </table>
                                                    {
                                                        getAddOrViewAttachment.isShow && <>
                                                            <Modal dialogClassName="commonwidth" show={getAddOrViewAttachment.isShow} size="md" dragable backdrop="static" keyboard={false}
                                                                onHide={() => handleCloseQCNotesFormPopup()}>
                                                                <Modal.Header closeButton>
                                                                    <Modal.Title>
                                                                        QA Notes Attachment
                                                                    </Modal.Title>
                                                                </Modal.Header>
                                                                <Modal.Body>
                                                                    <div className="row">
                                                                        <div className="col-sm-12 col-lg-12">
                                                                            <div className="row">
                                                                                <div className="col-sm-8 col-lg-8">
                                                                                    <div className='form-group'>
                                                                                        <tr>
                                                                                            <td> <input type="file" id="FileUpload"
                                                                                                onChange={(e) => AppendFiles(e, getAddOrViewAttachment.index)} accept=".jpg,.jpeg,.png,.pdf,.xlsx,.doc,.docx,.xls" ></input></td>
                                                                                        </tr>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            <div className="col-lg-12 col-sm-12">
                                                                                <div className="row no-margin">
                                                                                    {
                                                                                        getCCFields[getAddOrViewAttachment.index].Attachment.map((files, fileindex) => (
                                                                                            files.fileType === "image/jpeg" || files.fileType === "image/jpg" || files.fileType === "image/png" ||
                                                                                                files.fileType === "image/gif" ?
                                                                                                <>
                                                                                                    <div className="float-Left_washcare file_Image_uploads">
                                                                                                        <div className="client_tumblineWashcare2">
                                                                                                            <img className="width_manual1"
                                                                                                                src={window.$APIBaseURL + 'Images/Style/QCNotesUpload/' + files.fileName}
                                                                                                            />
                                                                                                            <div>
                                                                                                                <a className="washcareView" title='View QC Notes' onClick={() => ViewFile(fileindex, files.fileName)}>
                                                                                                                    <i className="btn btn-info fa fa-eye"></i>
                                                                                                                </a>&nbsp;
                                                                                                                <a title='Remove QC Notes' onClick={() => RemoveImages(getAddOrViewAttachment.index, fileindex)}>
                                                                                                                    <i className="btn btn-danger fa fa-trash-o"></i>
                                                                                                                </a>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                    {
                                                                                                        < div > {
                                                                                                            isOpenimg && (

                                                                                                                <Lightbox mainSrc={window.$APIBaseURL + 'Images/Style/QCNotesUpload/' + getImagename}
                                                                                                                    alt="bg image"
                                                                                                                    onCloseRequest={
                                                                                                                        () => setIsOpenimg(false)
                                                                                                                    } />
                                                                                                            )
                                                                                                        } </div>
                                                                                                    }
                                                                                                </>
                                                                                                : ""
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            <div className="col-lg-12 col-sm-12">
                                                                                <div className="row no-margin">
                                                                                    {
                                                                                        getCCFields[getAddOrViewAttachment.index].Attachment.map((files, fileindex) => (
                                                                                            files.fileType === "application/pdf" || files.fileType === "application/msword" || files.fileType === "application/vnd.ms-excel"
                                                                                                || files.fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ?
                                                                                                <>
                                                                                                    <div className="col-lg-4 col-sm-4 file_list_upload">
                                                                                                        <tr title={files.fileName}>
                                                                                                            <td className="cut_textInTNA">
                                                                                                                <span style={{ marginRight: '6px' }}>
                                                                                                                    {
                                                                                                                        files.fileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                                            files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                                                                <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                                                files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                                                    files.fileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                                                    <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        files.fileType === "image/jpeg" || files.fileType === "image/jpg" || files.fileType === "image/png" ||
                                                                                                                            files.fileType === "image/gif" ? <i class="fa fa-picture-o fa-lg image_icon_style"></i> : ''
                                                                                                                    }
                                                                                                                </span>  {files.fileName}
                                                                                                            </td>
                                                                                                            <td className="button_style_uploda">
                                                                                                                {
                                                                                                                    files.fileType === "application/pdf" || files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                                                                                                                        files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                                                                                        || files.fileType === 'application/msword' || files.fileType === "application/vnd.ms-excel" ?
                                                                                                                        <a type="button"
                                                                                                                            title="Download" class="btn btn-xs btn-primary" target="_blank"
                                                                                                                            href={window.$APIBaseURL + 'Images/Style/QCNotesUpload/' + files.fileName} download><i class="fa fa-download"></i> </a> : ''
                                                                                                                } &nbsp;
                                                                                                                {Buttonview !== "View" &&

                                                                                                                    <p title='Delete File'
                                                                                                                        onClick={() => RemoveImages(getAddOrViewAttachment.index, fileindex)}
                                                                                                                        className="btn btn-danger btn-xs delete">
                                                                                                                        <i className="fa fa-trash-o"></i>

                                                                                                                    </p>
                                                                                                                }
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </div>
                                                                                                </>
                                                                                                : ""
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>

                                                                </Modal.Body>
                                                                <Modal.Footer>
                                                                    <button class="btn btn btn-success" onClick={()=>handleCloseQCNotesFormPopup()}>Save</button>
                                                                </Modal.Footer>
                                                            </Modal>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="panel-group accordion" id="style_QCNotes_SampleComments_Info">
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">
                                            <a className="accordion-toggle clr-themegreen" data-toggle="collapse" data-parent="#style_QCNotes_SampleComments_Info" href="#collapse_style_QCNotes_SampleComments_Info">
                                                Sample Comments<span className='warning'> (TNA ID - Task Name - SKU - Approval Status on (Date) by (User)) </span>
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse_style_QCNotes_SampleComments_Info" className="panel-collapse collapse in">
                                        <div className="panel-body border-red">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                                        <thead>
                                                            <tr>
                                                                <th>Sample Details</th>
                                                                <th>Form / Manual Close</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                SKUSampleApproval.length !== 0 ?
                                                                    SKUSampleApproval.map((SampleApproval, index) => (
                                                                        <>
                                                                            <tr>
                                                                                <td>

                                                                                    {SampleApproval.manualCloseComment === '' ?
                                                                                        <>


                                                                                            {SampleApproval.sampleDetails} - {SampleApproval.skuName === null ? <b> <span class='primary'>Any Color</span></b> : <b><span class='primary'>{SampleApproval.skuName}</span></b>} - <b>{SampleApproval.approvedStatus === 'Rejected' ? <span class='danger'>{SampleApproval.approvedStatus}</span> : <span class='success'>{SampleApproval.approvedStatus}</span>} </b>
                                                                                            On {moment(SampleApproval.approvedDate).format("DD/MM/YYYY")} By {SampleApproval.approvedBy}

                                                                                        </> : <>{SampleApproval.sampleDetails} - {SampleApproval.skuName === null ? <b><span class='primary'>Any Color</span></b> : <b><span class='primary'>{SampleApproval.skuName}</span></b>} </>}


                                                                                    {/* {SampleApproval.approvedStatus} { SampleApproval.approvedDate}- {SampleApproval.approvedBy} */}

                                                                                    {/* {SampleApproval.manualCloseComment === '' &&
                                                                                <>
                                                                                 {SampleApproval.sampleDetails} - {SampleApproval.skuName === null ? 'Any Color' : SampleApproval.skuName} - {SampleApproval.approvedStatus - SampleApproval.approvedDate - SampleApproval.approvedBy}
                                                                                 </>
                                                                                } */}


                                                                                </td>
                                                                                <td>
                                                                                    {SampleApproval.manualCloseComment === '' ? 'Form' : 'Manual Close'}

                                                                                </td>
                                                                                <td>

                                                                                    <button type="button" onClick={() => ViewSamplesApproval(SampleApproval)}
                                                                                        className="btn btn-xs btn-warning" title="View">
                                                                                        <i className="fa fa-eye"></i>
                                                                                    </button>

                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    ))
                                                                    : <tr>
                                                                        <td colSpan="3" className='norecordfound'><span>No Records Found</span></td>
                                                                    </tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                    {
                                                        getViewSamplesApproval.isShow && <>
                                                            <Modal dialogClassName="commonwidth" show={getViewSamplesApproval.isShow} size="md" dragable backdrop="static" keyboard={false}
                                                                onHide={() => handleCloseSamplesApproval()}>
                                                                <Modal.Header closeButton>
                                                                    <Modal.Title>
                                                                        Approval Result
                                                                    </Modal.Title>
                                                                </Modal.Header>
                                                                <Modal.Body>
                                                                    <div className="row">

                                                                        <div className="col-md-12">
                                                                            {getViewSamplesApproval.value.manualCloseComment !== '' ?
                                                                                <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>ManualClose Comment</th>
                                                                                            <th>ManualClose Date</th>

                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>
                                                                                                {getViewSamplesApproval.value.manualCloseComment}
                                                                                            </td>
                                                                                            <td>
                                                                                                {moment(getViewSamplesApproval.value.manualCloseDate).format("DD/MM/YYYY")}
                                                                                            </td>
                                                                                        </tr>

                                                                                    </tbody>
                                                                                </table>
                                                                                : ''
                                                                            }
                                                                            {getViewSamplesApproval.value.manualCloseComment === '' ?
                                                                                <div>
                                                                                    <ProtoFitSMSApproval props={props}
                                                                                        ParentTask={getParentTask}
                                                                                        TaskStatus={3}
                                                                                        TNAProtoFitId={getViewSamplesApproval.value.sampleDetailsID}
                                                                                    ></ProtoFitSMSApproval>
                                                                                </div>
                                                                                : ''
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </Modal.Body>
                                                                <Modal.Footer>
                                                                    <button class="btn btn-xs btn-danger" onClick={() => handleCloseSamplesApproval()}>close</button>
                                                                </Modal.Footer>
                                                            </Modal>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-group accordion" id="style_QCNotes_TestReports_Info">
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">
                                            <a className="accordion-toggle clr-themegreen" data-toggle="collapse" data-parent="#style_QCNotes_TestReports_Info" href="#collapse_style_QCNotes_TestReports">
                                                Test Reports
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse_style_QCNotes_TestReports" className="panel-collapse collapse">
                                        <div className="panel-body border-red">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h2 className='blue'> Work in progress</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
}
export default StyleQCNotes;