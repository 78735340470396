import React, { useState, useEffect, useMemo, Fragment } from "react";
import ReactNotification from 'react-notifications-component';
import Nodify from "../../Common/ReactNotification"
import AqlService from "../../../services/Master/AqlService";
import { useSelector } from "react-redux";
const EditAQLList = (props) => {
   
    const EmptyInputFields = [{
        SNo: 0, InspectionLevel: "", LotSizeFrom: '', LotSizeTo: '', SampleSize: '', AQL: '', Accept: '', Createdby: 0
    }];
    const [inputfeild, setinputfeild] = useState(EmptyInputFields);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const { user: currentUser } = useSelector((state) => state.auth);
    const values = [...inputfeild];

    function BindAqlValue(props) {
        let AqlValue = {};

        if (props.location.state !== undefined) {
            if (props.location.state.params.length !== 0) {
                if (props.location.state.params.categoryID !== 0) {
                    AqlValue.Operation = 2;
                    AqlValue.SNo = props.location.state.params.sNo;
                    AqlValue.InspectionLevel = props.location.state.params.inspectionLevel;
                    AqlValue.LotSizeFrom = props.location.state.params.lotSizeFromm;
                    AqlValue.LotSizeTo = props.location.state.params.lotSizeTo;
                    AqlValue.SampleSize = props.location.state.params.sampleSize;
                    AqlValue.AQL = props.location.state.params.aql;
                    AqlValue.Accept = props.location.state.params.accept;
                    AqlValue.Createdby = currentUser.employeeinformationID;
                    return AqlValue;
                }
            }
        }
        else {
            return AqlValue;
        }
    }

    useEffect(() => {
        let AqlInfo = BindAqlValue(props);
        setinputfeild([AqlInfo]);
    }, []);

    function ResetOperation(e) {
        if (e === "Back") {
            props.history.push('/AQLList');
        } else {
            window.location.reload();
        }
    }

    function handleInputChange(index, e, FieldName) {
        const reg = new RegExp('^[0-9]+$');
        var Aqlreg = new RegExp(/^\d*\.?\d*$/);
        let inputText = '';
        let inputAql = '';
        if (e && FieldName !== "AQL") {
            if (e.target.value.match(reg)) {
                inputText = e.target.value.trim();
            } else {
                inputText = "";
            }
        }
        if (e && FieldName === "AQL") {
            if (e.target.value.match(Aqlreg)) {
                inputAql = e.target.value.trim();
            } else {
                inputAql = "";
            }
        }
        if (FieldName === "InspectionLevel") {
            values[index].InspectionLevel = e.target.value.trim()
        }
        else if (FieldName === "LotSizeFrom") {
            values[index].LotSizeFrom = inputText
        }
        else if (FieldName === "LotSizeTo") {
            values[index].LotSizeTo = inputText
        }
        else if (FieldName === "SampleSize") {
            values[index].SampleSize = inputText
        }
        else if (FieldName === "AQL") {
            values[index].AQL = inputAql
        }
        else {
            values[index].Accept = inputText
        }
        setinputfeild(values);
    }

    function ValidationPopup(Msg) {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
        return false;
    }

    function PageRedirect(Func) {
        props.history.push({
            pathname: "/AQLList",
            state: {
                message: Func
            }
        });
    }

    function updateAqlList(e) {
        let isvalid = true;
        setButtonLoader(true);
        e.preventDefault();
        for (var i = 0; i < values.length; i++) {
            if (values[i].InspectionLevel === "" || values[i].LotSizeFrom === '' || values[i].LotSizeTo === '' ||
                values[i].SampleSize === '' | values[i].AQL === '' || values[i].Accept === '') {
                setButtonLoader(false);
                setSubmitted(true);
                isvalid = false;
                Nodify('Warning!', 'warning', 'Please fill This mandantory(*) fields.');
                return false;
            }
        }
        if (isvalid) {
            AqlService.InsertUpdateAql(values[0]).then((res) => {
                let Func = 'Add';
                if (res.data.outputResult === '1') {
                    Func = 'Add';
                    PageRedirect(Func);
                }
                else if (res.data.outputResult === '2') {
                    Func = 'Edit';
                    PageRedirect(Func);
                }
                else if (res.data.outputResult === '-2') {
                    setButtonLoader(false);
                    ValidationPopup("This AQL Details is already exist.");
                }
                else if (res.data.outputResult === "0") {
                    setButtonLoader(false);
                    Nodify('Warning!', 'warning', "Error Occured!");
                }
            })
        }
    }
    return (
        <Fragment>
            <div className="page-body">
                <ReactNotification />

                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <div className="widget flat radius-bordered">
                            <div className="widget-header heading_box_style">
                                <h3 className="heading_style_common">Edit AQL</h3>
                                <div className="buttons-preview" align="right">
                                    {/* <span className="btn btn-primary"
                                        onClick={
                                            () => ResetOperation("Back")
                                        }>
                                        <i className="fa fa-arrow-circle-left"></i>
                                        &nbsp;Back</span>
                                    <span className="btn btn-danger"
                                        onClick={
                                            () => ResetOperation()
                                        }>
                                        <i className="fa fa-times"></i>
                                        &nbsp;Reset</span>
                                    <button type="button" className="btn btn-success" disabled={buttonLoader}
                                        onClick={updateAqlList}>

                                        <i className="fa fa-check right"></i>
                                        &nbsp;Save</button> */}
                                </div>
                            </div>
                            <div className="widget-body">
                                <div id="registration-form">
                                    <form>
                                        <div >
                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: "15%" }}>Inspection Level<span className="text-danger">*</span></th>
                                                        <th style={{ width: "15%" }}>Lot Size From<span className="text-danger">*</span></th>
                                                        <th style={{ width: "15%" }}>Lot Size To<span className="text-danger">*</span></th>
                                                        <th style={{ width: "15%" }}>Sample Size<span className="text-danger">*</span></th>
                                                        <th style={{ width: "15%" }}>AQL<span className="text-danger">*</span></th>
                                                        <th style={{ width: "15%" }}>Accept<span className="text-danger">*</span></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {inputfeild.map((inputField, index) => (
                                                        <tr>
                                                            <td>
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"InspectionLevel"}
                                                                    name="Inspection Level"
                                                                    placeholder="Enter Inspection Level"
                                                                    maxLength="50"
                                                                    autoComplete="off"
                                                                    value={inputField.InspectionLevel}
                                                                    onChange={event => handleInputChange(index, event, "InspectionLevel")}
                                                                    style={{ border: submitted && inputField.InspectionLevel === "" ? '1px solid red' : '' }}
                                                                    autoFocus
                                                                />
                                                            </td>
                                                            <td>
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"LotSizeFrom"}
                                                                    name="Lot Size From"
                                                                    placeholder="Enter Lot Size From"
                                                                    maxLength="50"
                                                                    autoComplete="off"
                                                                    value={inputField.LotSizeFrom}
                                                                    onChange={event => handleInputChange(index, event, "LotSizeFrom")}
                                                                    style={{ border: submitted && inputField.LotSizeFrom === "" ? '1px solid red' : '' }}
                                                                    autoFocus
                                                                />
                                                            </td>
                                                            <td>
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"LotSizeTo"}
                                                                    name="Lot Size To"
                                                                    placeholder="Enter Lot Size To"
                                                                    maxLength="50"
                                                                    autoComplete="off"
                                                                    value={inputField.LotSizeTo}
                                                                    onChange={event => handleInputChange(index, event, "LotSizeTo")}
                                                                    style={{ border: submitted && inputField.LotSizeTo === "" ? '1px solid red' : '' }}
                                                                    autoFocus
                                                                />
                                                            </td>
                                                            <td>
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"SampleSize"}
                                                                    name="Sample Size"
                                                                    placeholder="Enter Sample Size"
                                                                    maxLength="50"
                                                                    autoComplete="off"
                                                                    value={inputField.SampleSize}
                                                                    onChange={event => handleInputChange(index, event, "SampleSize")}
                                                                    style={{ border: submitted && inputField.SampleSize === "" ? '1px solid red' : '' }}
                                                                    autoFocus
                                                                />
                                                            </td>
                                                            <td>
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"AQL"}
                                                                    name="AQL"
                                                                    placeholder="Enter AQL"
                                                                    maxLength="50"
                                                                    autoComplete="off"
                                                                    value={inputField.AQL}
                                                                    onChange={event => handleInputChange(index, event, "AQL")}
                                                                    style={{ border: submitted && inputField.AQL === "" ? '1px solid red' : '' }}
                                                                    autoFocus
                                                                />
                                                            </td>
                                                            <td>
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"Accept"}
                                                                    name="Accept"
                                                                    placeholder="Enter Accept"
                                                                    maxLength="50"
                                                                    autoComplete="off"
                                                                    value={inputField.Accept}
                                                                    onChange={event => handleInputChange(index, event, "Accept")}
                                                                    style={{ border: submitted && inputField.Accept === "" ? '1px solid red' : '' }}
                                                                    autoFocus
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>
                            <span className="btn btn-primary"
                                onClick={
                                    () => ResetOperation("Back")
                                }>
                                <i className="fa fa-arrow-circle-left"></i>
                                &nbsp;Back</span>
                            <span className="btn btn-danger"
                                onClick={
                                    () => ResetOperation()
                                }>
                                <i className="fa fa-times"></i>
                                &nbsp;Reset</span>
                            <button type="button" className="btn btn-success" disabled={buttonLoader}
                                onClick={updateAqlList}>

                                <i className="fa fa-check right"></i>
                                &nbsp;Update</button>
                        </div>
                    </div>
                </div>
            </div >
        </Fragment>
    )
}

export default EditAQLList