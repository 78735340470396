import React, { useState, useEffect, useRef } from "react";
import UploadImage from "../../Common/EmployeeUploadImage"
import ReactNotification from 'react-notifications-component';
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import Address from "../CommonComponent/Address";
import Employee from "../CommonComponent/Employee";
import Holiday from "../CommonComponent/HoliDay";
import companyService from "../../../services/Master/Companyservice";
import supplierService from "../../../services/Master/SupplierService";
import 'react-notifications-component/dist/theme.css';
import validator from 'validator';
import { emailExp } from "../../Common/RegExpression";
import Nodify from "../../Common/ReactNotification"
import { SupplierModuleId } from "../../Common/ModuleID";
import SearchRetain from "../../Common/SearchRetain";
import FactoryInfo from "../Supplier/FactoryInfo";
import CityService from "../../../services/Master/CityService";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { useSelector } from "react-redux";
//import CityService from "../../../services/Master/CityService";
import CommonService from '../../../services/Common/CommonService';
import ConfirmationPopup from "../../Common/ConfirmationPopup";


const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const SupplierRegisterNew = (props) => {
   
    const { user: currentUser } = useSelector((state) => state.auth);
    const [getPage, setPage] = useState([]);
    const [getID, setID] = useState({ showPopupConfirm: false, Params: {} });
    // //Enable Menu
    const [refershstate, setrefershstate] = useState(0)
    let activeMenu;
    let activeMenuSub;
    let AddressInfo;
    let EmployeeInfo;
    let HolidayInfo;
    let AddressInfoQuick;
    let EmployeeInfoQuick = [];
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
    if (menulist !== undefined && menulistSub.length > 0) {
        activeMenu = menulist.filter(i => i.menuName === "Supplier");
        if (activeMenu.length > 0) {
            activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);
            if (activeMenuSub.length > 0) {
                for (let index = 0; index < activeMenuSub.length; index++) {
                    if (activeMenuSub[index].menuName === "SupplierAddress") {

                        AddressInfo = activeMenuSub[index];
                        AddressInfoQuick = menulist.filter(i => i.menuName === 'Branch');
                    }
                    else if (activeMenuSub[index].menuName === "SupplierEmployee") {
                        EmployeeInfo = activeMenuSub[index];
                        EmployeeInfoQuick.Role = menulist.filter(i => i.menuName === 'Role')
                        EmployeeInfoQuick.Department = menulist.filter(i => i.menuName === 'Department')
                    }
                    else if (activeMenuSub[index].menuName === "SupplierHoliday") {
                        HolidayInfo = activeMenuSub[index];

                    }

                }

            }
        }
    }

    // // End Enable Menu
    const [getSupplierList, setSupplierList] = useState({});
    const [getSupplierAddressList, setSupplierAddressList] = useState({});
    const [getSupplierEmployeeList, setSupplierEmployeeList] = useState({});
    const [getSupplierHolidayList, setSupplierHolidayList] = useState({});
    const [getCurrencyList, setCurrencyList] = useState([]);
    const [cityList, setcityList] = useState([]);
    const [BranchList, setBranchList] = useState([]);
    const [getcountryList, setcountryList] = useState([]);
    const [genderList, setgenderList] = useState([]);
    const [AddressList, setAddressList] = useState([]);
    const [EmployeeList, setEmployeeList] = useState([]);
    const [HolidayList, setHolidayList] = useState([]);
    const [ButtonName, setButtonName] = useState("Save");
    const [submitted, setSubmitted] = useState({ Supplier: false, Reference: false, CurrencyID: false });
    const [checked, setChecked] = useState({ SupplierStaus: true });
    const [getstate_SupplierID, setstate_SupplierID] = useState(0);
    const [getHeaderName, addHeaderName] = useState("Add");
    const [Buttonvisible, SetButtonvisible] = useState("Show");
    const [buttonLoader, setButtonLoader] = useState(false);
    const [stateList, setstateList] = useState([]);
    //const [countryList, setcountryList] = useState([]);
    const [IsParentCallback, setIsParentCallback] = useState(false);
    const [IsEmpParentCallback, setIsEmpParentCallback] = useState(false);
    const [IsHolidayParentCallback, setIsHolidayParentCallback] = useState(false);
    const [Disable, setDisable] = useState(false);
    const inputRef = useRef();

    const [loader, showLoader, hideLoader] = useFullPageLoader();

    const [getFactoryInfo, setFactoryInfoList] = useState({});
    const [ProductionList, setProductionInfoList] = useState({});
    const [CustomerList, setCustomerInfoList] = useState({});
    const [CertificateList, setCertificateInfoList] = useState({});
    const [PolicyList, setPolicyInfoList] = useState({});
    const [FactoryPhotoList, setFactoryPhotoInfoList] = useState({});
    const [getItemCategory, setItemCategory] = useState([]);

    const [FactoryList, setFactoryList] = useState([]);
    const [getGroupType, setGroupType] = useState([]);
    const [getFactoryInformation, setFactoryInformation] = useState([{
        Factory: [], Production: [], Customer: [], Certificates: [], FactoryPhotos: [], Policies: []
    }]);
    const [getFactoryInformationList, setFactoryInformationList] = useState([{
        Factory: [], Production: [], Customer: [], Certificates: [], FactoryPhotos: [], Policies: []
    }]);
    const CombuysubId = JSON.parse(localStorage.getItem("CompanySubbuyID"));
    function SetSupplier(props) {
        let SupplierValue = {
            Operation: 1,
            SupplierID: 0,
            Name: '',
            ReferenceName: '',
            CurrencyID: 0,
            AddressType: '',
            Currency: '',
            OwnerName: '',
            LogoName: '',
            LogoPath: '',
            Status: 1,
            Tabletype: SupplierModuleId,
            Createdby: currentUser.employeeinformationID
        };
        setPage('SupplierRegister');
        if (CombuysubId !== null) {
            setstate_SupplierID(CombuysubId);
            setButtonName("Update");
            SupplierValue.Operation = 2;
            SupplierValue.SupplierID = CombuysubId;
            SupplierValue.Name = validator.trim(props[0].name);
            SupplierValue.ReferenceName = validator.trim(props[0].referenceName)
            SupplierValue.CurrencyID = props[0].currencyID;
            SupplierValue.Currency = props[0].currencyAmount;
            SupplierValue.OwnerName = validator.trim(props[0].ownerName);
            SupplierValue.LogoName = props[0].logoName;
            SupplierValue.LogoPath = props[0].logoPath;
            SupplierValue.Status = props[0].activeStatus;
            SupplierValue.Tabletype = props[0].tabletype;
            SupplierValue.AddressType = props[0].currencyID;
            SetButtonvisible(props[0].action);
            if (SupplierValue.Status === 1) {
                setChecked({ SupplierStaus: checked });
            } else {
                setChecked({
                    SupplierStaus: !checked
                });
            }

            if (CombuysubId) {
                addHeaderName("View");
            } else {
                addHeaderName("Edit");
            }
            return SupplierValue;
        }
        if (props.location.state !== undefined && (props.location.state.params.action === 'Edit' || props.location.state.params.action === 'View')) {
            if (props.location.state.params.length !== 0) {
                if (props.location.state.params.companyBuyerSupID !== "") {
                    setstate_SupplierID(props.location.state.params.companyBuyerSupID);
                    setButtonName("Update");
                    SupplierValue.Operation = 2;
                    SupplierValue.SupplierID = props.location.state.params.companyBuyerSupID;
                    SupplierValue.Name = validator.trim(props.location.state.params.name);
                    SupplierValue.ReferenceName = validator.trim(props.location.state.params.referenceName)
                    SupplierValue.CurrencyID = props.location.state.params.currencyID;
                    SupplierValue.Currency = props.location.state.params.currencyAmount;
                    SupplierValue.OwnerName = validator.trim(props.location.state.params.ownerName);
                    SupplierValue.LogoName = props.location.state.params.logoName;
                    SupplierValue.LogoPath = props.location.state.params.logoPath;
                    SupplierValue.Status = props.location.state.params.activeStatus;
                    SupplierValue.Tabletype = props.location.state.params.tabletype;
                    SupplierValue.AddressType = props.location.state.params.currencyID;
                    SetButtonvisible(props.location.state.params.action);
                    if (SupplierValue.Status === 1) {
                        setChecked({ SupplierStaus: checked });
                    } else {
                        setChecked({
                            SupplierStaus: !checked
                        });
                    }

                    if (props.location.state.params.action) {
                        addHeaderName("View");
                    } else {
                        addHeaderName("Edit");
                    }
                    return SupplierValue;
                }
            }
        } else {
            return SupplierValue;
        }
    }

    useEffect(() => {
        showLoader();
        // Supplier Information

        if (props.location.state.params.action === 'Edit') {
            let getSupplier = SetSupplier(props);
            setSupplierList(getSupplier)
        } else {
            if (CombuysubId !== null) {
                companyService.getAllCompanyBuyerSupList(CombuysubId, 4).then(async (res) => {
                    if (res.data) {
                        let getSupplier = await SetSupplier(res.data);
                        setSupplierList(getSupplier)
                    }
                })
            } else {
                let getSupplier = SetSupplier(props);
                setSupplierList(getSupplier)

            }
        }
        supplierService.LoadCurrencyist().then((response) => {
            if (response.data) {
                setCurrencyList(response.data);
            }
        }).catch(() => { });

        companyService.loadCityList().then((response) => {
            if (response.data) {
                setcityList(response.data);
            }
        }).catch(() => { });

        companyService.loadGenderList().then((response) => {
            if (response.data) {
                setgenderList(response.data);
            }
        }).catch(() => { });

        companyService.loadAddressTypeList().then((response) => {
            if (response.data) {
                setBranchList(response.data);
            }
        }).catch(() => { });
        CityService.loadStateList().then((response) => {
            if (response.data) {
                setstateList(response.data);
            }
        }).catch(() => { });

        CityService.loadCountryList().then((response) => {
            if (response.data) {
                setcountryList(response.data);
            }
        }).catch(() => { });

        supplierService.LoadGroupTYpe().then((response) => {
            if (response.data) {
                setGroupType(response.data);
            }
        })

        supplierService.LoadCategoryList(0).then((response) => {
            if (response.data) {
                setItemCategory(response.data);
            }
        });
        // //;
        // // let Params = {
        // //     Operation: 3,
        // //     Id: props.location.state.params.companyBuyerSupID, //compBuySupID delete
        // // };
        // // CommonService.CheckMappedItem(Params).then(res => {
        // //     if (res.data.outputResult === "-2") {
        // //         setDisable(true)
        // //     }
        // // })
        // AddressList
        if (props.location.state && props.location.state.params.companyBuyerSupID !== undefined && (props.location.state.params.action === 'Edit' || props.location.state.params.action === 'View')) {
            // 1 => Address List Against Supplier
            companyService.BindAddressList(props.location.state.params.companyBuyerSupID).then((response) => {
                if (response.data) {
                    setAddressList(response.data);
                }
            }).catch(() => { });

            // 2 => Employee List Against Supplier
            companyService.BindEmployeeList(props.location.state.params.companyBuyerSupID).then((response) => {
                if (response.data) {
                    setEmployeeList(response.data);
                }
            }).catch(() => { });

            // 3 => Employee List Against Supplier
            companyService.BindHolidayList(props.location.state.params.companyBuyerSupID, new Date().getFullYear()).then((response) => {
                if (response.data) {
                    setHolidayList(response.data);
                }
            }).catch(() => { });

            supplierService.BindAllFactoryInformations(props.location.state.params.companyBuyerSupID).then((response) => {
                if (response.data) {
                    setFactoryList(response.data);
                }
            })
            hideLoader();
        } else if (props.location.state.params.action === 'Add' && CombuysubId !== null) {
            companyService.BindAddressList(CombuysubId).then((response) => {
                if (response.data) {
                    setAddressList(response.data);
                }
            }).catch(() => { });

            // 2 => Employee List Against Supplier
            companyService.BindEmployeeList(CombuysubId).then((response) => {
                if (response.data) {
                    setEmployeeList(response.data);
                }
            }).catch(() => { });

            // 3 => Employee List Against Supplier
            companyService.BindHolidayList(CombuysubId, new Date().getFullYear()).then((response) => {
                if (response.data) {
                    setHolidayList(response.data);
                }
            }).catch(() => { });

            supplierService.BindAllFactoryInformations(CombuysubId).then((response) => {
                if (response.data) {
                    setFactoryList(response.data);
                }
            })
            hideLoader();
        }
    }, [refershstate]);

    const handleChange = (e) => {
       ;
        let companyValue = getSupplierList;
        let inputText = '';
        if (e.target.value.trim() !== '') {
            inputText = e.target.value;
            setSubmitted(true);
        }
        if (e.target.type === 'checkbox') {
            if (!e.target.checked) {
                let Params = {
                    Operation: 143,
                    Id: companyValue.SupplierID,
                };

                CommonService.CheckMappedItem(Params).then(res => {
                    if (res.data.outputResult === "-2") {

                        let Title = "Warning!";
                        let message = "This Employee is already mapped.";
                        let type = "warning";
                        Nodify(Title, type, message);
                        //companyValue[0].EmpCompanyAdministrator = 1;
                        setChecked(companyValue);
                        //employeeCallback(values);
                    } else {
                        if (e && e.target) {
                            e.target.value = 0;
                            companyValue.Status = parseInt(e.target.value);
                        } else {
                            companyValue.Status = 0;
                        }
                        setChecked({ SupplierStaus: companyValue.Status });
                        //employeeCallback(values);
                    }
                });
            } else {
                companyValue.Status = 1;
            }
            setChecked({ SupplierStaus: e.target.checked });
        }
        else {
            if (e.target.name === "Name") {
                if (submitted.Reference === true && submitted.CurrencyID === true) {
                    setSubmitted({ company: false, Reference: true, CurrencyID: true });
                }
                else {
                    setSubmitted({ company: false, Reference: false, CurrencyID: false });
                }
                companyValue[e.target.name] = inputText
            }
            else if (e.target.name === "ReferenceName") {
                if (submitted.company === true && submitted.CurrencyID === true) {
                    setSubmitted({ company: true, Reference: false, CurrencyID: true });
                }
                else {
                    setSubmitted({ company: false, Reference: false, CurrencyID: false });
                }
                companyValue[e.target.name] = inputText
            }
            else if (e.target.name === "CurrencyID") {
                if (submitted.Reference === true && submitted.company === true) {
                    setSubmitted({ Supplier: true, Reference: true, CurrencyID: false });
                }
                else {
                    setSubmitted({ company: false, Reference: false, CurrencyID: false });
                }
                companyValue.CurrencyID = parseInt(inputText);
                companyValue.AddressType = parseInt(inputText);
            }
            else if (e.target.name === "Currency") {
                companyValue[e.target.name] = (inputText);
            }
            else if (e.target.name === "OwnerName") {
                companyValue[e.target.name] = (inputText);
            }
        }
        setSupplierList(companyValue);
    }

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/SupplierList",
            state: {
                message: Func
            }
        });
    }

    const AddSupplierback = (value) => {

        getSupplierList.LogoName = value;
    }

    const Addcallback = async (value) => {
        await companyService.loadCityList().then((response) => {
            if (response.data) {
                setcityList(response.data);
            }
        }).catch(() => { });
        setSupplierAddressList(value);
        setIsParentCallback(false);

    }

    const Empcallback = (value) => {
        setSupplierEmployeeList(value);
        setIsEmpParentCallback(false);
    }

    const Holidaycallback = (value) => {
        setSupplierHolidayList(value);
        setIsHolidayParentCallback(false);
    }

    const Factorycallback = (value) => {
        setFactoryInfoList(value);
    }

    const [SupplierinputFields, setSupplierinputFields] = useState([{
        AddressInformationID: 0,
        AddressType: 0,
        Country: 0,
        CompanyState: 0,
        City: 0,
        PrimaryContactNumber: '',
        SecondaryContactNumber: '',
        PrimaryEmailAddress: '',
        SecondaryEmailAddress: '',
        PostalCode: '',
        Website: '',
        AddressLine1: '',
        AddressLine2: ''
    }]);

    const [EmployeeinputFields, setEmployeeinputFields] = useState([{
        EmployeeinformationID: 0,
        EmpFirstName: '',
        EmpLastName: '',
        EmpDateofBirth: '',
        EmpGender: 0,
        EmpDateofJoin: '',
        EmpDepartmentID: 0,
        EmpRoleID: 0,
        EmpUserName: '',
        EmpPassword: '',
        ConfirmPassword: '',
        EmpCompanyAdministrator: 0,
        EmpAddressLine1: '',
        EmpAddressLine2: '',
        EmpCity: 0,
        EmpState: 0,
        EmpCountry: 0,
        EmpPostalCode: '',
        EmpPrimaryContactNumber: '',
        EmpSecondaryContactNumber: '',
        EmpProfilePicName: '',
        EmpProfilePicPath: '',
        ActiveStatus: 1,
        IsDeleted: 0,

    }]);

    const [HolidayinputFields, setHolidayinputFields] = useState([{
        HolidayInformationID: 0,
        HolidayName: '',
        HolidayDate: new Date(),
        HolidayEndDate: '',
        IsDeleted: 0
    }]);

    const [getFactory, setFactory] = useState([{
        FactoryInfoID: 0, FactoryName: "", FactorySize: 0, EmployeesStrength: 0, AnnualTurnOver: "", IndexName: "", IsDeleted: 0,
        FactoryInfoLogoName: "", FactoryInfoLogoPath: "",
    }]);

    const [getCustomer, setCustomer] = useState([{
        SupCustomerID: 0, CustomerName: "", ExportCountry: 0, CustomerTrunOver: "", ProductName: "", IndexName: "", IsDeleted: 0,
    }])

    const [getProduction, setProduction] = useState([{
        SupProductionID: 0, GroupType: 0, ItemCategory: 0, CapacityInPcs: "", LeadTimeInDays: "", ReOrderTimeInDays: "", IndexName: "",
        IsDeleted: 0,
    }])

    const [getPolicies, setPolicies] = useState([{
        SupPolicyID: 0, FileName: "", FilePath: "", IndexName: "", IsDeleted: 0,
    }])

    const [getCertificates, setCertificates] = useState([{
        SupCertificateID: 0, FileName: "", FilePath: "", IndexName: "", IsDeleted: 0,
    }])

    const [getFactoryPhotos, setFactoryPhotos] = useState([{
        SupFactoryPhotoID: 0, FileName: "", FilePath: "", IndexName: "", IsDeleted: 0,
    }])

    const viewtoEdit = (e) => {
        SetButtonvisible("Edit");
        addHeaderName("EDIT");
    }

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/SupplierList')
            localStorage.removeItem("CompanySubbuyID");
        } else {
            // props.history.push('/SupplierRegister')
            // setSubmitted({ Supplier: false, Reference: false, CurrencyID: false });
            // let getSupplier = SetSupplier(props);
            // setSupplierList(getSupplier)
            window.location.reload();
        }
    }

    const Delcallback = (value) => {
        if (value === true) {
            setID({ showPopupConfirm: false, Params: [] });
            localStorage.removeItem("CompanySubbuyID");
        } else {
            let x = Math.floor((Math.random() * 100) + 1);
            setrefershstate(x);
            setID({ showPopupConfirm: false, Params: [] });
        }
        //setPage("deleteClose");
    }

    const SaveSupplier = (e) => {
        setButtonLoader(true);
        let IsValid = true;
        let ValidationMsg = "";
        setSubmitted({ Supplier: true, Reference: true, CurrencyID: true });
        e.preventDefault();
        if (getSupplierList.Name.trim() === '' && getSupplierList.ReferenceName.trim() === '' && getSupplierList.CurrencyID === 0) {
            ValidationPopup("Please fill all mandatory (*) fields.");
        }
        else if (getSupplierList.Name.trim() === '') {
            ValidationPopup("Supplier Name is required.");
        }
        else if (getSupplierList.ReferenceName.trim() === '') {
            ValidationPopup("Reference Name is required.");
        }
        else if (getSupplierList.CurrencyID === 0) {
            ValidationPopup("Currency Type is required.");
        }

        if (getSupplierList.Name !== '' && getSupplierList.ReferenceName !== '' && getSupplierList.CurrencyID !== 0) {
            getSupplierList.Name = getSupplierList.Name.trim();
            getSupplierList.ReferenceName = getSupplierList.ReferenceName.trim();
            getSupplierList.CurrencyID = getSupplierList.CurrencyID;
            if (getSupplierAddressList.length === undefined) {
                getSupplierList.CompanyInformation = SupplierinputFields;
            }
            else {
                for (var index = 0; index < getSupplierAddressList.length; index++) {
                    if (getSupplierAddressList[index].AddressType === 0
                        && getSupplierAddressList[index].City === 0
                        && getSupplierAddressList[index].PrimaryContactNumber === ""
                        && getSupplierAddressList[index].PostalCode === ""
                        && getSupplierAddressList[index].AddressLine1 === ""
                        && getSupplierAddressList[index].AddressLine2 === ""
                        && getSupplierAddressList[index].PrimaryEmailAddress === ""
                        && getSupplierAddressList[index].Website === "") {
                        // getSupplierAddressList[index].IsDeleted = 1;
                    }
                    else if (getSupplierAddressList[index].AddressType > 0 && getSupplierAddressList[index].City > 0 && getSupplierAddressList[index].PrimaryContactNumber !== "" && getSupplierAddressList[index].PostalCode !== "" && getSupplierAddressList[index].AddressLine1 !== "") {
                        getSupplierAddressList[index].AddressLine1 = getSupplierAddressList[index].AddressLine1.trim();
                        getSupplierAddressList[index].AddressLine2 = getSupplierAddressList[index].AddressLine2.trim();
                        getSupplierAddressList[index].PrimaryEmailAddress = getSupplierAddressList[index].PrimaryEmailAddress.trim();
                        getSupplierAddressList[index].Website = getSupplierAddressList[index].Website.trim();

                        if (getSupplierAddressList[index].PrimaryEmailAddress !== "") {
                            if (emailExp.test(getSupplierAddressList[index].PrimaryEmailAddress) === false) {
                                IsValid = false;
                                if (getSupplierAddressList[index].AddressTypeName !== undefined) {
                                    ValidationMsg = "Email address is not valid in Address tab (" + getSupplierAddressList[index].AddressTypeName + ")";
                                }
                                else {
                                    ValidationMsg = "Email address is not valid in Address tab";
                                }
                                ValidationPopup(ValidationMsg);
                                setIsParentCallback(true);
                                getSupplierAddressList[index].PrimaryEmailAddress = '';
                                return false;
                            }
                        }
                        // if (getSupplierAddressList[index].PrimaryContactNumber.length !== 14) {
                        //     IsValid = false;
                        //     if (getSupplierAddressList[index].AddressTypeName !== undefined) {
                        //         ValidationMsg = "Contact number is not valid in Address tab (" + getSupplierAddressList[index].AddressTypeName + ")";
                        //     }
                        //     else {
                        //         ValidationMsg = "Contact number is not valid in Address tab";
                        //     }
                        //     ValidationPopup(ValidationMsg);
                        //     setIsParentCallback(true);
                        //     return false;

                        // }
                        // if (getSupplierAddressList[index].PostalCode.length !== 7) {
                        //     IsValid = false;
                        //     if (getSupplierAddressList[index].AddressTypeName !== undefined) {
                        //         ValidationMsg = "Postal code is not valid in Address tab (" + getSupplierAddressList[index].AddressTypeName + ")";
                        //     }
                        //     else {
                        //         ValidationMsg = "Postal code is not valid in Address tab";
                        //     }

                        //     ValidationPopup(ValidationMsg);
                        //     setIsParentCallback(true);
                        //     return false;
                        // }
                    }
                    else {
                        IsValid = false;
                        if (getSupplierAddressList[index].AddressTypeName !== undefined) {
                            ValidationMsg = "Please fill all mandatory (*) fields in Address tab (" + getSupplierAddressList[index].AddressTypeName + ")";
                        }
                        else {
                            ValidationMsg = "Please fill all mandatory (*) fields in Address tab";
                        }
                        ValidationPopup(ValidationMsg);
                        setIsParentCallback(true);

                        return false;
                    }
                }
                getSupplierList.CompanyInformation = getSupplierAddressList;
            }

            if (getSupplierEmployeeList.length === undefined) {
                getSupplierList.EmployeeInformation = EmployeeinputFields;
            }
            else {
                for (var index = 0; index < getSupplierEmployeeList.length; index++) {
                    if (getSupplierEmployeeList[index].EmpFirstName === ''
                        && getSupplierEmployeeList[index].EmpLastName === ''
                        && getSupplierEmployeeList[index].EmpGender === 0
                        && (getSupplierEmployeeList[index].EmpDateofBirth === '' || getSupplierEmployeeList[index].EmpDateofBirth === null)
                        && (getSupplierEmployeeList[index].EmpDateofJoin === '' || getSupplierEmployeeList[index].EmpDateofJoin === null)
                        && getSupplierEmployeeList[index].EmpDepartmentID === 0
                        && getSupplierEmployeeList[index].EmpRoleID === 0
                        && getSupplierEmployeeList[index].EmpAddressLine1 === ''
                        && getSupplierEmployeeList[index].EmpAddressLine2 === ''
                        && getSupplierEmployeeList[index].EmpCity === 0
                        && getSupplierEmployeeList[index].EmpCompanyAdministrator === 0
                        && getSupplierEmployeeList[index].ActiveStatus === 1
                        && getSupplierEmployeeList[index].EmpPostalCode === ''
                        && getSupplierEmployeeList[index].EmpPrimaryContactNumber === ''
                        && getSupplierEmployeeList[index].EmpUserName === ''
                        && getSupplierEmployeeList[index].EmpPassword === ''
                        && getSupplierEmployeeList[index].ConfirmPassword === '') {
                        //  getSupplierEmployeeList[index].IsDeleted = 1;
                    }
                    else if (getSupplierEmployeeList[index].EmpFirstName && getSupplierEmployeeList[index].EmpLastName && getSupplierEmployeeList[index].EmpGender && getSupplierEmployeeList[index].EmpRoleID && getSupplierEmployeeList[index].EmpAddressLine1 && getSupplierEmployeeList[index].EmpCity && getSupplierEmployeeList[index].EmpPostalCode && getSupplierEmployeeList[index].EmpPrimaryContactNumber && getSupplierEmployeeList[index].EmpUserName && getSupplierEmployeeList[index].EmpPassword && getSupplierEmployeeList[index].ConfirmPassword) {
                        getSupplierEmployeeList[index].EmpFirstName = getSupplierEmployeeList[index].EmpFirstName.trim();
                        getSupplierEmployeeList[index].EmpLastName = getSupplierEmployeeList[index].EmpLastName.trim();
                        getSupplierEmployeeList[index].EmpAddressLine1 = getSupplierEmployeeList[index].EmpAddressLine1.trim();
                        getSupplierEmployeeList[index].EmpAddressLine2 = getSupplierEmployeeList[index].EmpAddressLine2.trim();
                        getSupplierEmployeeList[index].EmpUserName = getSupplierEmployeeList[index].EmpUserName.trim();
                        getSupplierEmployeeList[index].EmpPassword = getSupplierEmployeeList[index].EmpPassword.trim();
                        getSupplierEmployeeList[index].ConfirmPassword = getSupplierEmployeeList[index].ConfirmPassword.trim();

                        if (getSupplierEmployeeList[index].EmpPassword.length < 8) {
                            IsValid = false;
                            ValidationMsg = "Password should be above 7 characters for (" + getSupplierEmployeeList[index].EmpFirstName + " " + getSupplierEmployeeList[index].EmpLastName + ")";
                            ValidationPopup(ValidationMsg);
                            setIsEmpParentCallback(true);
                            return false;
                        }
                        if (getSupplierEmployeeList[index].EmpPassword !== getSupplierEmployeeList[index].ConfirmPassword) {
                            IsValid = false;
                            ValidationMsg = "Password and Confirm password is must be same for (" + getSupplierEmployeeList[index].EmpFirstName + " " + getSupplierEmployeeList[index].EmpLastName + ")";
                            ValidationPopup(ValidationMsg);
                            setIsEmpParentCallback(true);
                            return false;
                        }
                        if (emailExp.test(getSupplierEmployeeList[index].EmpUserName) === false) {
                            IsValid = false;
                            ValidationMsg = "Email Address is not valid in Employee Tab (" + getSupplierEmployeeList[index].EmpFirstName + " " + getSupplierEmployeeList[index].EmpLastName + ")";
                            ValidationPopup(ValidationMsg);
                            setIsEmpParentCallback(true);
                            getSupplierEmployeeList[index].EmpUserName = '';
                            return false;
                        }
                        // if (getSupplierEmployeeList[index].EmpPrimaryContactNumber.length !== 14) {
                        //     IsValid = false;
                        //     ValidationMsg = "Contact number is not valid in Employee Tab (" + getSupplierEmployeeList[index].EmpFirstName + " " + getSupplierEmployeeList[index].EmpLastName + ")";
                        //     ValidationPopup(ValidationMsg);
                        //     setIsEmpParentCallback(true);
                        //     return false;
                        // }
                        // if (getSupplierEmployeeList[index].EmpPostalCode.length !== 7) {
                        //     IsValid = false;
                        //     ValidationMsg = "Postal code is not valid in Employee Tab (" + getSupplierEmployeeList[index].EmpFirstName + " " + getSupplierEmployeeList[index].EmpLastName + ")";
                        //     ValidationPopup(ValidationMsg);
                        //     setIsEmpParentCallback(true);
                        //     return false;
                        // }
                    }
                    else {
                        IsValid = false;
                        ValidationMsg = "Please fill all mandatory (*) fields in Employee tab (" + getSupplierEmployeeList[index].EmpFirstName + " " + getSupplierEmployeeList[index].EmpLastName + ")";
                        ValidationPopup(ValidationMsg);
                        setIsEmpParentCallback(true);
                        return false;
                    }
                    if (getSupplierEmployeeList[index] != undefined) {
                        if (getSupplierEmployeeList[index].EmpDateofBirth !== "" && getSupplierEmployeeList[index].EmpDateofBirth !== null && getSupplierEmployeeList[index].EmpDateofBirth !== undefined) {
                            getSupplierEmployeeList[index].EmpDateofBirth.setDate(getSupplierEmployeeList[index].EmpDateofBirth.getDate() + 1);
                        }
                        if (getSupplierEmployeeList[index].EmpDateofJoin !== "" && getSupplierEmployeeList[index].EmpDateofJoin !== null && getSupplierEmployeeList[index].EmpDateofJoin !== undefined) {
                            getSupplierEmployeeList[index].EmpDateofJoin.setDate(getSupplierEmployeeList[index].EmpDateofJoin.getDate() + 1);
                        }
                    }
                }
                getSupplierList.EmployeeInformation = getSupplierEmployeeList;
            }

            if (getSupplierHolidayList.length === undefined) {
                getSupplierList.HolidayInformation = HolidayinputFields;
            }
            else {
                for (var index = 0; index < getSupplierHolidayList.length; index++) {
                    var IsEmpty = getSupplierHolidayList.filter((x => ((x.HolidayName === '' || x.HolidayDate === null) && x.IsDeleted === 0)));
                    if (IsEmpty.length !== 0) {
                        if (IsEmpty[0].HolidayName === '' || IsEmpty[0].HolidayDate === null) { }
                        else {
                            IsValid = false;
                            ValidationMsg = "Please fill all mandatory (*) fields in Holiday tab (" + getSupplierHolidayList[index].HolidayName + ")";
                            setButtonLoader(false);
                            ValidationPopup(ValidationMsg);
                            setIsHolidayParentCallback(true);
                            return false;
                        }
                    }
                    if (getSupplierHolidayList[index].HolidayName === ''
                        && (getSupplierHolidayList[index].HolidayDate === '' || getSupplierHolidayList[index].HolidayDate === null)
                        && (getSupplierHolidayList[index].HolidayEndDate === '' || getSupplierHolidayList[index].HolidayEndDate === null)) {
                        // getSupplierHolidayList[index].IsDeleted = 1;
                    }
                    else if (getSupplierHolidayList[index].HolidayName && getSupplierHolidayList[index].HolidayDate) {
                        getSupplierHolidayList[index].HolidayName = getSupplierHolidayList[index].HolidayName.trim();
                        getSupplierHolidayList[index].HolidayDate.setDate(getSupplierHolidayList[index].HolidayDate.getDate() + 1);
                        if (getSupplierHolidayList[index].HolidayEndDate !== "" && getSupplierHolidayList[index].HolidayEndDate !== null && getSupplierHolidayList[index].HolidayEndDate !== undefined) {
                            getSupplierHolidayList[index].HolidayEndDate.setDate(getSupplierHolidayList[index].HolidayEndDate.getDate() + 1);
                        }
                    }
                    else {
                        IsValid = false;
                        ValidationMsg = "Please fill all mandatory (*) fields in Holiday tab (" + getSupplierHolidayList[index].HolidayName + ")";
                        ValidationPopup(ValidationMsg);
                        setIsHolidayParentCallback(true);
                        return false;
                    }
                }
                getSupplierList.HolidayInformation = getSupplierHolidayList;
            }

            let FactoryInfo = []; let CustomerInfo = []; let ProductionInfo = [];
            let Policy = []; let Certificate = []; let FactoryPhoto = [];
            if (getFactoryInfo.length !== undefined) {
                getFactoryInfo.forEach((Info, i) => {
                    if (Info.FactoryName !== '' && Info.IsDeleted !== 1) {
                        FactoryInfo.push({
                            FactoryInfoID: Info.FactoryInfoID, FactoryName: Info.FactoryName, FactorySize: Info.FactorySize === '' ? 0 : parseInt(Info.FactorySize),
                            EmployeesStrength: Info.EmployeesStrength === '' ? 0 : parseInt(Info.EmployeesStrength),
                            AnnualTurnOver: Info.AnnualTurnOver, FactoryInfoLogoName: Info.FactoryInfoLogoName, FactoryInfoLogoPath: Info.FactoryInfoLogoPath,
                            IndexName: Info.IndexName,
                        })
                    } else {
                        IsValid = false;
                        ValidationPopup("Please fill all mandatory (*) fields in Factory Information tab.");
                        return false;
                    }

                    if (Info.ProductionCapacity) {
                        Info.ProductionCapacity.forEach((production, i) => {
                            if (production.IsDeleted !== 1) {
                                if (production.GroupType === 0 || production.ItemCategory === 0 || production.CapacityInPcs === "" ||
                                    production.LeadTimeInDays === "" || production.ReOrderTimeInDays === "") {
                                    IsValid = false;
                                    ValidationPopup("Please fill all mandatory (*) fields in Factory Information tab.");
                                    return false;
                                }
                            } else {
                                ProductionInfo.push(production);
                            }
                        });
                        getSupplierList.ProductionCapacityInformation = IsValid === false ? getProduction : ProductionInfo;
                    } else {
                        getSupplierList.ProductionCapacityInformation = getProduction;
                    }

                    if (Info.Customers) {
                        Info.Customers.forEach((customer, i) => {
                            if (customer.CustomerName === "" || customer.ExportCountry === 0 || customer.CustomerTrunOver === "" ||
                                customer.ProductName === "") {
                                if (customer.IsDeleted !== 1) {
                                    IsValid = false;
                                    ValidationPopup("Please fill all mandatory (*) fields in Factory Information tab.");
                                    return false;
                                }
                            } else {
                                CustomerInfo.push(customer);
                            }
                        });
                        getSupplierList.CustomerInformation = IsValid === false ? getCustomer : CustomerInfo;
                    } else {
                        getSupplierList.CustomerInformation = getCustomer;
                    }

                    if (Info.Policies) {
                        Info.Policies.forEach((policy, i) => {
                            Policy.push(policy);
                        });
                        getSupplierList.SupplierPoliciesInformation = Policy;
                    } else {
                        getSupplierList.SupplierPoliciesInformation = getPolicies;
                    }

                    if (Info.Certificates) {
                        Info.Certificates.forEach((policy, i) => {
                            Certificate.push(policy);
                        });
                        getSupplierList.SupplierCertificateInformation = Certificate;
                    } else {
                        getSupplierList.SupplierCertificateInformation = getCertificates;
                    }

                    if (Info.FactoryPhotos) {
                        Info.FactoryPhotos.forEach((policy, i) => {
                            FactoryPhoto.push(policy);
                        });
                        getSupplierList.SupplierFactoryPhotosInformation = FactoryPhoto;
                    } else {
                        getSupplierList.SupplierFactoryPhotosInformation = getFactoryPhotos;
                    }
                });
                getSupplierList.FactoryInformation = FactoryInfo;
            } else {
                getSupplierList.FactoryInformation = getFactory;
                getSupplierList.CustomerInformation = getCustomer;
                getSupplierList.ProductionCapacityInformation = getProduction;
                getSupplierList.SupplierPoliciesInformation = getPolicies;
                getSupplierList.SupplierCertificateInformation = getCertificates;
                getSupplierList.SupplierFactoryPhotosInformation = getFactoryPhotos;
            }

            if (IsValid) {
                getSupplierList.LogoPath = '';
                if (getSupplierList.LogoName !== '' && getSupplierList.LogoName !== undefined) {
                    let val = "/SupplierLogo/" + getSupplierList.LogoName;
                    getSupplierList.LogoPath = val;
                }

                supplierService.InsertUpdateSupplier(getSupplierList).then(res => {
                    var page = "Remove";
                    SearchRetain(page);
                    let Func = 'Add';
                    res.data.tabPage = getPage;
                    if (res.data.outputResult === "1") {
                        Func = 'Add';
                        //setButtonLoader(false);
                        Func = 'Add';
                        setID({ showPopupConfirm: true, Params: res.data });
                        localStorage.setItem('CompanySubbuyID', (res.data.outputResultStyleID));
                    } else if (res.data.outputResult === "2") {
                        Func = 'Edit';
                        //setButtonLoader(false);
                        setID({ showPopupConfirm: true, Params: res.data });
                        // PageRedirect(Func);
                    } else if (res.data.outputResult === "-2") {
                        ValidationPopup("Supplier name already exists!");
                    }
                    else if (res.data.outputResult === "0") {
                        ValidationPopup("Error Occured!");
                    }

                    res.data.Func = Func;
                    setButtonLoader(false);
                });
            }
        }
        else {
            // scrollToRef(inputRef);
        }
    }

    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);

        return false;
    }
    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">{getHeaderName} Supplier</h3>
                            <div className="buttons-preview" align="right">
                                <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                    <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                                </span>
                                <a className="btn btn-danger" onClick={() => ResetOperation()}>
                                    <i className="fa fa-times"></i>&nbsp;Reset
                                </a>
                                {Buttonvisible !== "View" &&
                                    (<button type="submit" className="btn btn-success" onClick={SaveSupplier} disabled={buttonLoader}>
                                        <i className="fa fa-check right"></i> &nbsp;{ButtonName}
                                    </button>)
                                }
                                {Buttonvisible === "View" && activeMenu && activeMenu[0].isView === 1 ? '' :
                                    activeMenu && activeMenu[0].isView === 0 ? '' :
                                        (<button type="submit" style={{ display: activeMenu && activeMenu[0].isView === 0 ? '' : 'none' }} className="btn btn-info" onClick={viewtoEdit}>
                                            <i className="fa fa-edit"></i>&nbsp;Edit
                                        </button>)
                                }
                            </div>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <Form>
                                    <div className="row">
                                        <div className="col-sm-7">
                                            <div className="col-lg-12">
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label htmlFor="Name">Supplier Name<span className="text-danger">*</span></label>
                                                            <span className='input-icon icon-right'>
                                                                <Input placeholder="Enter Supplier Name" id="Name" name="Name" //className="form-control"
                                                                    value={getSupplierList.Name}
                                                                    type="text"
                                                                    autoComplete="off"
                                                                    onChange={event => handleChange(event)}
                                                                    maxLength="50"
                                                                    autoFocus
                                                                    ref={inputRef}
                                                                    className={'form-control' + (
                                                                        submitted.Supplier && !getSupplierList.Name ? ' is-invalid' : ''
                                                                    )}
                                                                    style={{ border: submitted.Supplier && !getSupplierList.Name ? '1px solid red' : '' }}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label htmlFor="ReferenceName">
                                                                Reference Name<span className="text-danger">*</span>
                                                            </label>
                                                            <span className="input-icon icon-right">
                                                                <Input placeholder="Enter Reference Name" id="ReferenceName" name="ReferenceName" // className="form-control"
                                                                    value={getSupplierList.ReferenceName}
                                                                    type="text"
                                                                    maxLength="50"
                                                                    autoComplete="off"
                                                                    onChange={event => handleChange(event)}
                                                                    ref={inputRef}
                                                                    className={'form-control' + (
                                                                        submitted.Reference && !getSupplierList.ReferenceName ? ' is-invalid' : ''
                                                                    )}
                                                                    style={{ border: submitted.Reference && !getSupplierList.ReferenceName ? '1px solid red' : '' }}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label htmlFor="OwnerName">Owner Name</label>
                                                            <span className="input-icon icon-right">
                                                                <Input placeholder="Enter Owner Name" id="OwnerName" name="OwnerName"
                                                                    value={getSupplierList.OwnerName}
                                                                    type="text"
                                                                    maxLength="50"
                                                                    autoComplete="off"
                                                                    onChange={event => handleChange(event)}
                                                                    ref={inputRef}
                                                                    className='form-control'
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label htmlFor={"CurrencyID"}>Currency<span className="text-danger">*</span></label>
                                                            <select id={"CurrencyID"}
                                                                name="CurrencyID" className="form-select"
                                                                value={getSupplierList.CurrencyID}
                                                                onChange={event => handleChange(event)}
                                                                ref={inputRef}
                                                                style={{ border: submitted.CurrencyID && (getSupplierList.CurrencyID === 0) ? '1px solid red' : '' }}
                                                            >
                                                                <option value="0">- Select Currency -</option>
                                                                {
                                                                    getCurrencyList.map(item => (
                                                                        <option key={item.id} value={item.id}>
                                                                            {item.name}
                                                                        </option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {/* <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label htmlFor="Currency">Currency Amount</label>
                                                            <span className="input-icon icon-right">
                                                                <Input placeholder="Enter Currency Amount" id="Currency" name="Currency"
                                                                    value={getSupplierList.Currency}
                                                                    type="text"
                                                                    maxLength="50"
                                                                    autoComplete="off"
                                                                    onChange={event => handleChange(event)}
                                                                    ref={inputRef}
                                                                    className='form-control'
                                                                />
                                                            </span>
                                                        </div>
                                                    </div> */}
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label htmlFor="Status">Status</label>
                                                            <span className="input-icon icon-right">
                                                                <input className="checkbox-slider checkbox-slider_Status colored-blue" id="Status" name="Status"
                                                                    value={getSupplierList.Status} checked={checked.SupplierStaus} disabled={Disable}
                                                                    autoComplete="off" onChange={event => handleChange(event)} type="checkbox">
                                                                </input>
                                                                <span className="text"></span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-5 pull-right">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail2">Supplier Logo</label>
                                                <span className="text-danger">(Click on the image to upload new)</span><br />
                                                <UploadImage UploadCallback={AddSupplierback} PageName='SupplierLogo' filePath={getSupplierList.LogoPath} Buttonview={Buttonvisible} />
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="tabbable">
                        <ul className="nav nav-tabs nav-justified" id="myTab5">

                            <li className='active'  >
                                <a data-toggle="tab" href="#idSupplierAddress">
                                    <h5>Address</h5>
                                </a>
                            </li>


                            <li className=''  >
                                <a data-toggle="tab" href="#idSupplierEmployee">
                                    <h5>Employee</h5>
                                </a>
                            </li>


                            <li className='' >
                                <a data-toggle="tab" href="#idSupplierHoliday">
                                    <h5>Holiday</h5>
                                </a>
                            </li>

                            {/* <li className="">
                                <a data-toggle="tab" href="#idFactoryInfo">
                                    <h5>FACTORY INFO</h5>
                                </a>
                            </li> */}
                        </ul>

                        <div className="tab-content">

                            <div id="idSupplierAddress" className='tab-pane in active' style={{ width: "100%" }}>
                                {AddressInfo && AddressInfo.isList === 1 ?
                                    <Address addressCallback={Addcallback}
                                        IsValidationCallback={IsParentCallback}
                                        citylist={cityList}
                                        branchlist={BranchList}
                                        AddressList={AddressList}
                                        Id={getstate_SupplierID}
                                        Buttonview={Buttonvisible}
                                        MenuPermission={AddressInfo}
                                        AddressInfoQuick={AddressInfoQuick}
                                        PageName={"Supplier"}
                                        stateList={stateList}
                                        countryList={getcountryList}
                                    />
                                    : <span><h3 className="red">User has access denied. Please contact the administrator! </h3></span>
                                }
                            </div>


                            <div id="idSupplierEmployee" className='tab-pane' style={{ width: "100%" }}>
                                {EmployeeInfo && EmployeeInfo.isList === 1 ?
                                    <Employee employeeCallback={Empcallback}
                                        IsValidationCallback={IsEmpParentCallback}
                                        citylist={cityList}
                                        genderlist={genderList}
                                        EmployeeList={EmployeeList}
                                        Id={getstate_SupplierID}
                                        Buttonview={Buttonvisible}
                                        PageName={"Supplier"}
                                        MenuPermission={EmployeeInfo}
                                        EmployeeInfoQuick={EmployeeInfoQuick}
                                        props={props}

                                    />
                                    : <span><h3 className="red">User has access denied. Please contact the administrator! </h3></span>
                                }
                            </div>


                            <div id="idSupplierHoliday" className='tab-pane' style={{ width: "100%" }}>
                                {HolidayInfo && HolidayInfo.isList === 1 ?
                                    <Holiday holidayCallback={Holidaycallback}
                                        IsValidationCallback={IsHolidayParentCallback}
                                        HolidayList={HolidayList}
                                        Id={getstate_SupplierID}
                                        Buttonview={Buttonvisible}
                                        MenuPermission={HolidayInfo}
                                        PageName={"Supplier"}
                                    />
                                    : <span><h3 className="red">User has access denied. Please contact the administrator! </h3></span>
                                }
                            </div>

                            {/* <div id="idFactoryInfo" className="tab-pane" style={{ width: "100%" }}>
                                <FactoryInfo
                                    addressCallback={Factorycallback}
                                    FactoryList={FactoryList}
                                    citylist={getcountryList}
                                    Id={getstate_SupplierID}
                                    Buttonview={Buttonvisible}
                                    Grouptype={getGroupType}
                                    getItemCategory={getItemCategory}
                                // productionList={ProductionList}
                                // customerList={CustomerList}
                                // policyList={PolicyList}
                                // certificateList={CertificateList}
                                // factoryPhotoList={FactoryPhotoList}
                                />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>
                        <a className="btn btn-primary" onClick={() => ResetOperation('Back')}>
                            <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                        </a>
                        <a className="btn btn-danger" onClick={() => ResetOperation()}>
                            <i className="fa fa-times"></i>&nbsp;Reset
                        </a>
                        {Buttonvisible !== "View" &&
                            (<button type="submit" className="btn btn-success" onClick={SaveSupplier} disabled={buttonLoader}>
                                <i className="fa fa-check right"></i> &nbsp;{ButtonName}
                            </button>)
                        }
                        {Buttonvisible === "View" && activeMenu && activeMenu[0].isView === 1 ? '' :
                            activeMenu && activeMenu[0].isView === 0 ? '' :
                                (<button type="submit" style={{ display: activeMenu && activeMenu[0].isView === 0 ? '' : 'none' }} className="btn btn-info" onClick={viewtoEdit}>
                                    <i className="fa fa-edit"></i>&nbsp;Edit
                                </button>)
                        }
                    </div>
                </div>
            </div>

            {
                getID.showPopupConfirm ? <ConfirmationPopup props={props} data={
                    getID.Params
                }
                    deleteCallback={Delcallback}
                    showpopup={true}
                /> : null
            }
        </div>
    );
};


export default SupplierRegisterNew;