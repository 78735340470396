import React, { useState, useCallback } from 'react';
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import { Modal, Button } from "react-bootstrap";
import Nodify from '../Common/ReactNotification';
import SwatchMasterService from '../../services/Master/SwatchMasterService';
import { useSelector } from "react-redux";
import $ from "jquery";
import Lightbox from "react-image-lightbox"
import axios from "axios";
const SwatchPopup = ({
    SwatchList,
    swatchCallback,
    showpopup,
    BuyerID,
    BrandID
}) => {


    const { user: currentUser } = useSelector((state) => state.auth);

    const EmptyInputFields =
    {
        SwatchMasterID: 0, BuyerID: 0, BrandID: 0, SwatchName: "", SwatchImageFileName: "", SwatchImageFilePath: ""
    }

    const [buttonLoader, setButtonLoader] = useState(false);
    const [InputFields, setInputFields] = useState([EmptyInputFields]);
    const [getSubmitted, setSubmitted] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [getImagename, setImagename] = useState('');
    var ImgClassName = "upload-btn-wrapper2"

    const CheckDuplicate = (index) => {
        const values = [...InputFields];
        if (SwatchList) {
            for (var i = 0; i < SwatchList.length; i++) {
                if (SwatchList[i].name.trim().toLowerCase() === values[index].SwatchName.trim().toLowerCase()) {
                    values[index].SwatchName = '';
                    setInputFields(values);
                    Nodify('Warning!', 'warning', "This name is already exist.");
                    return false;
                }
            }
        }
    }


    const handleChange = (event) => {
        const values = [...InputFields];
        let inputText = "";

        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }
        values[0].SwatchName = inputText;
        setSubmitted(false);
        setInputFields(values);
    }


    const openFileUpload = () => {
        $('#upload-button').trigger('click');
    }

    const UploadImages = async (e) => {
       ;
        const values = [...InputFields];
        const file = e.target.files[0];
        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png'];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#FileUpload').val("");
            }
            else {
                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "Swatch");
                try {
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                    values[0].SwatchImageFileName = file.name;
                    values[0].SwatchImageFilePath = "Images/SwatchImages/" + res.data + '_' + file.name;
                    setInputFields(values);
                    $('#FileUpload').val("");
                    setImagename("Images/SwatchImages/" + file.name);

                } catch (ex) {
                    console.log(ex);
                }
            }
        }
    }

    const ViewFile = (filename) => { // event.preventDefault();
        setIsOpen(true);
        // setImagename(filename);
    }

    const handlePopupClose = () => {
        swatchCallback(false);
    };

    const SaveSwatch = (e) => {
        setButtonLoader(true);
        e.preventDefault();
        const Values = [...InputFields];
        if (Values[0].SwatchName.trim() === '') {
            setSubmitted(false);
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Swatch Name is required.');
        }
        else if (Values[0].SwatchImageFileName.trim() === '') {
            setSubmitted(false);
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Swatch Image is required.');
        }
        if (Values[0].SwatchName.trim() && Values[0].SwatchImageFileName.trim()) {
            let data = {};
            Values[0].BuyerID = BuyerID;
            Values[0].BrandID = BrandID;
            data.SwatchMasterInformation = Values;
            data.Createdby = currentUser.employeeinformationID;

            SwatchMasterService.InsertUpdateSwatchMasterGrid(data).then(res => {
                if (res.data.outputResult === "1") {
                    Nodify("Success!", "success", "Swatch added successfully.");
                    swatchCallback(true);
                }
                else if (res.data.outputResult === "-2") {
                    Nodify('Warning!', 'warning', 'This name is already exist.');
                }
                setButtonLoader(true);
            });
        }
        else {
            setSubmitted(true);
        }
    }
    return (
        <Modal show={showpopup} size="md" dragable backdrop="static" keyboard={false} onHide={() => handlePopupClose()}>
            <Modal.Header closeButton>
                <Modal.Title>Add Swatch</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div id="registration-form">
                    <Form>
                        <div className="row">

                            <div className="col-sm-6 divder_style">
                                <div className="form-group">
                                    <label htmlFor={"SwatchName"}>Swatch Name<span className="text-danger">*</span></label>
                                    <Input placeholder={"Enter Swatch Name"}
                                        id={"SwatchName"} name="SwatchName"
                                        value={InputFields[0].SwatchName}
                                        type="text" maxLength="75" autoComplete="off"
                                        onBlur={() => CheckDuplicate(0)}
                                        onChange={event => handleChange(event)}
                                        className='form-control'
                                        style={{ border: getSubmitted && !InputFields[0].SwatchName ? '1px solid red' : '' }}
                                    />
                                </div>
                            </div>

                            <div className="col-sm-6 divder_style">
                                <div className="form-group">
                                    <label htmlFor={"SwatchImageFilePath"}>Swatch Image<span className="text-danger">*</span></label>


                                    <div className={ImgClassName}
                                        htmlFor="upload-button"
                                    >
                                        <img src={InputFields[0].SwatchImageFilePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + InputFields[0].SwatchImageFilePath} onClick={openFileUpload}
                                            alt="Picture" style={{ marginBottom: '5px', height: '100px', width: '40%', border: "1px solid #d5d5d5" }} />
                                        <input type="file" id="upload-button" accept=".jpg,.jpeg,.png" onChange={(event) => UploadImages(event)} value='' />
                                        {
                                            InputFields.SwatchImageFileName !== "assets/img/uploadimage.jpg" && (
                                                <div style={
                                                    {
                                                        marginTop: '8px',
                                                        float: 'right',
                                                        bottom: '10px',
                                                        position: 'relative',
                                                    }
                                                }>
                                                    {/* {
                                                        getImagename !== "" && getImagename !== "assets/img/uploadimage.jpg" ?
                                                            <i className="btn btn-info fa fa-eye" title="View Logo"
                                                                style={{ marginLeft: "-100px", position: "absolute" }}
                                                                onClick={
                                                                    event => ViewFile(event, getImagename)
                                                                }></i>
                                                            : ''

                                                    } */}
                                                </div>
                                            )
                                        }
                                        {
                                            <div> {
                                                isOpen && (

                                                    <Lightbox mainSrc={window.$APIBaseURL + getImagename}
                                                        alt="bg image"
                                                        onCloseRequest={
                                                            () => setIsOpen(false)
                                                        } />
                                                )
                                            } </div>
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="text-right">
                            <Button type="submit" className="btn btn-success" onClick={SaveSwatch} disabled={buttonLoader}>
                                <i className="fa fa-check"></i> &nbsp; Save
                            </Button>

                        </div>
                    </Form>
                </div>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    )
};
export default SwatchPopup;
