import React, { useState, useEffect } from "react";
import Nodify from "../../../Common/ReactNotification";
import SearchRetain from "../../../Common/SearchRetain";
import ReactNotification from 'react-notifications-component';
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import { useSelector } from "react-redux";
import TestItemService from './../../../../services/Master/tesitemservice';
import $ from "jquery";

const TestItemEdit = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const [getTestItemInfo, setTestItemInfo] = useState({});
    const [buttonLoader, setButtonLoader] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [getExisting, setExisting] = useState([]);
    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    if (menulist !== undefined) {
        activeMenu = menulist.filter(i => i.menuName === "TestItem");
    }
    //end enable menu
    function BindCommentValue(props) {
        let CommentInfoValue = { Operation: 2, ID: 0, TestItem: '', Createdby: currentUser.employeeinformationID };
        if (props.location.state !== undefined) {
            if (props.location.state.params.testItemID !== 0) {
                CommentInfoValue.Operation = 2;
                CommentInfoValue.TestItemID = props.location.state.params.testItemID;
                CommentInfoValue.TestItem = props.location.state.params.testItem;
                CommentInfoValue.Createdby = currentUser.employeeinformationID;
                return CommentInfoValue;
            }
        }
    }

    const handleChange = (e) => {
        let Value = getTestItemInfo;
        if (e.target.name === "TestItem") {
            if (e.target.value.trim() !== '') {
                setSubmitted(true);
            }
            else {
                setSubmitted(false);
            }
            Value[e.target.name] = e.target.value;
        }
        setTestItemInfo(Value);
    }

    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
    };

    //Enter Error msg
    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                //SaveBuyerInfo(event);
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);

    useEffect(() => {
        let Commentinfo = BindCommentValue(props);
        setTestItemInfo(Commentinfo);
    }, []);

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/TestItemList')
        } else {
            window.location.reload();
        }
    }

    const SaveTestItem = (e) => {
        setSubmitted(true);
        setButtonLoader(true);
        e.preventDefault();
        if (getTestItemInfo.TestItem.trim() === '') {
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Test Item is required');
        }
        if (getTestItemInfo.TestItem.trim()) {
            getTestItemInfo.TestItem = getTestItemInfo.TestItem.trim();
            TestItemService.UpdateTestItem(getTestItemInfo).then(res => {
                setSubmitted(false);
                var page = "Remove";
                SearchRetain(page);
                let Func = 'Add';
                if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    PageRedirect(Func);
                }
                setButtonLoader(false);
            }).catch((errr => {
                setSubmitted(false);
            }));
        }
    }

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/TestItemList",
            state: {
                message: Func,
            },
        }
        );
    };

    const CheckDuplicate = (e) => {
        const values = { ...getTestItemInfo };
        let isExisting = !!getExisting.find(d => d.testItem.trim().toLowerCase() === values.TestItem.trim().toLowerCase() && d.testItemID !== props.location.state.params.testItemID);
        if (isExisting) {
            values.TestItem = '';
            setTestItemInfo(values);
            Nodify('Warning!', 'warning', "This Test Item is already exist.");
            setSubmitted(true);
            return false;
        }
    }

    useEffect(() => {
        TestItemService.GetTestItemList().then((res => {
            if (res.data) {
                setExisting(res.data.item1);
            }
        }))
    }, [])

    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">
                                Edit Test Item
                            </h3>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <Form>
                                    <div className="row">
                                        <div className="form-group col-sm-4">
                                            <label htmlFor="MeasurePointName">
                                                Test Item Name<span className="text-danger">*</span>
                                            </label>
                                            <span className="input-icon icon-right">
                                                <Input
                                                    placeholder={"Enter Test Item"}
                                                    id="TestItem"
                                                    name="TestItem"
                                                    type="text"
                                                    maxLength="100"
                                                    autoComplete="off"
                                                    onChange={handleChange}
                                                    onBlur={CheckDuplicate}
                                                    value={getTestItemInfo.TestItem}
                                                    className='form-control'
                                                    style={{ border: submitted && !getTestItemInfo.TestItem ? '1px solid red' : '' }}
                                                    autoFocus
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div
                        className="buttons-preview"
                        align="right"
                        style={{ marginTop: "15px" }}
                    >
                        <div className="buttons-preview" align="right">
                            <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                            </span>
                            <span className="btn btn-danger" onClick={() => ResetOperation()}
                                style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}>
                                <i className="fa fa-times"></i>&nbsp;Reset
                            </span>
                            <button
                                type="submit"
                                className="btn btn-success"
                                onClick={SaveTestItem}
                                disabled={buttonLoader}
                            >

                                <i className="fa fa-check right"></i> &nbsp;Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TestItemEdit;