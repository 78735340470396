import {
    BUYER_LOADING,
    RETRIEVE_BUYER,
    DELETE_BUYER,
} from "../actions/types";

const initialState = {
    isLoadingBuyer: true,
    buyerList: [],
};

const buyerReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case BUYER_LOADING:
            return {
                ...state,
                isLoadingBuyer: payload
            };
        case RETRIEVE_BUYER:
            return { ...state, buyerList: payload };
        case DELETE_BUYER:
            const results = state.buyerList.filter(c => c.companyBuyerSupID !== payload.companyBuyerSupID);
            return {
                ...state,
                buyerList: results
            };

        default:
            return state;
    }
};
export default buyerReducer;