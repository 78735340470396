import axios from "axios";
import authHeader from "../auth-header";
class StrikeoffService {
    LoadApprovalTypeList() {
        return axios.get(window.$APIBaseURL + "Style/LoadApprovalTypeList", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadPurchaseTypeList() {
        return axios.get(window.$APIBaseURL + "Style/LoadPurchaseTypeList", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadStrikeoffSizeList() {
        return axios.get(window.$APIBaseURL + "Style/LoadStrikeoffSizeList", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadTrimSupplierList(BuyerID) {
        return axios.get(window.$APIBaseURL + "Style/LoadTrimSupplierList?BuyerID=" + BuyerID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateStrikeoff(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Style/InsertUpdateStrikeoff',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateStyleStrikeOffDetails(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Style/InsertUpdateStyleStrikeOffDetails',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }


    GetStrikeOffList(StrikeoffID) {
        return axios.get(window.$APIBaseURL + "Style/GetStrikeOffList?StrikeoffID=" + StrikeoffID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetStrikeOffPrintColorList(StrikeoffID) {
        return axios.get(window.$APIBaseURL + "Style/GetStrikeOffPrintColorList?StrikeoffID=" + StrikeoffID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetStrikeOffBuyerBrandPrintColorList(BuyerID, BrandID) {
        return axios.get(window.$APIBaseURL + "Style/GetStrikeOffBuyerBrandPrintColorList?BuyerID=" + BuyerID + "&BrandID=" + BrandID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetStrikeOffLogoInformationList(StrikeoffID) {
        return axios.get(window.$APIBaseURL + "Style/GetStrikeOffLogoInformationList?StrikeoffID=" + StrikeoffID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetStrikeOffReferenceList(StrikeoffID) {
        return axios.get(window.$APIBaseURL + "Style/GetStrikeOffReferenceList?StrikeoffID=" + StrikeoffID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetStyleStrikeOffList(StyleID) {
        return axios.get(window.$APIBaseURL + "Style/GetStyleStrikeOffList?StyleID=" + StyleID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetStyleStrikeOffReferenceList(StyleID) {
        return axios.get(window.$APIBaseURL + "Style/GetStyleStrikeOffReferenceList?StyleID=" + StyleID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    BindStrikeOffTabInfo(StrikeoffID) {
        return axios.get(window.$APIBaseURL + "Style/GetAllStrikeoffColors?StrikeoffID=" + StrikeoffID, { // data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadStrikeOffBuyerBrandList(BuyerID, BrandID) {
        return axios.get(window.$APIBaseURL + "Style/LoadStrikeOffBuyerBrandList?BuyerID=" + BuyerID + "&BrandID=" + BrandID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadSkuNameList(StyleID) {
        return axios.get(window.$APIBaseURL + "Style/LoadSkuNameList?StyleID=" + StyleID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadGroupSizeDetailsList(StyleID) {
        return axios.get(window.$APIBaseURL + "Style/LoadGroupSizeDetailsList?StyleID=" + StyleID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetStyleStrikeOffPrintColorList(StyleID) {
        return axios.get(window.$APIBaseURL + "Style/GetStyleStrikeOffPrintColorList?StyleID=" + StyleID, { // data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetStyleStrikeOffTabList(StyleID) {
        return axios.get(window.$APIBaseURL + "Style/GetStyleStrikeOffTabList?StyleID=" + StyleID, { // data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }


    CheckColorGroupMapped(Operation, Id) {
        return axios.get(window.$APIBaseURL + "Style/CheckColorGroupMapped?Operation=" + Operation + "&Id=" + Id, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetStyleStrikeOffLogoInformationList(StyleID) {
        return axios.get(window.$APIBaseURL + "Style/GetStyleStrikeOffLogoInformationList?StyleID=" + StyleID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetTNAStrikeOffAlreadyMapList(params) {
        return axios.get(window.$APIBaseURL + "Style/GetTNAStrikeOffAlreadyMapList?StyleID=" + params.StyleID + "&BuyerID=" + params.BuyerID + "&BrandID=" + params.BrandID + "&StrikeOffId=" + params.StrikeOffId, {
            headers: {
                authorization: authHeader()
            }
        });
    }

}
export default new StrikeoffService();
