import React from 'react';
import { useEffect, useMemo, useState,Fragment } from 'react';
import ReactNotification from 'react-notifications-component';
import { TableHeader, Pagination, Search, PageCount } from "../../../Datatable";
import { useDispatch, useSelector } from "react-redux";
import { retrieveTestName } from '../../../../actions/testname';
import SearchRetain from "../../../Common/SearchRetain";
import Nodify from "../../../Common/ReactNotification";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import { TestName } from "../../../Common/PageActionNumber";
import SweetAlertPopup from '../../../Common/SweetAlertPopup';
import CommonService from '../../../../services/Common/CommonService';
import ReactCompareImage from 'react-compare-image'; //https://www.npmjs.com/package/react-compare-image
import moment from 'moment';
const TestNameList = (props) => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [getPlaceholder] = useState("Test Name");
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const dispatch = useDispatch();
    const [getID, setID] = useState({ showPopupDelete: false, Params: {} });
    let activeMenu;
    const menulist = useSelector((state) => state.common.MenuList.item3);
    const reducerState = useSelector((state) => state);
    const comments = reducerState.testname.TestNameList.testNameList;
    let isLoadingTestName = reducerState.testname.isLoadingTestName;
    if (menulist !== undefined) {
        activeMenu = menulist.filter(i => i.menuName === "TestName");
    }

    const headers = [
        { name: "#", field: "", sortable: false },
        { name: "Test Name", field: "testName", sortable: true },
        { name: "Test Type", field: "typeName", sortable: true },
        { name: "Created / Modified By On Date", field: "createdDate", sortable: true },
        {
            name: "Action", field: "", sortable: false, display: activeMenu && activeMenu[0].isEdit === 0 &&
            activeMenu[0].isDelete === 0 ? 'none' : ''
        },
    ];

    const pageRedirect = (TestNameParams, redirect, page) => {
        localStorage.setItem('Pagination', JSON.stringify({ CurrentPage: currentPage, Page: page, PageItems: ITEMS_PER_PAGE }));
        if (redirect === 'Add') {
            props.history.push(`/TestNameRegister`);
        } else if (redirect === 'Edit') {
            props.history.push(`/TestNameEdit`, { params: TestNameParams });
        } else {
            let Params = {
                Operation: TestName,
                Id: TestNameParams.testNameID,
            };
            CommonService.CheckMappedItem(Params).then(res => {
                if (res.data.outputResult === "-2") {
                    let Title = "Warning!";
                    let message = "This Test Name is already mapped.";
                    let type = "warning";

                    Nodify(Title, type, message);
                }
                else {
                    setID({ showPopupDelete: true, Params: TestNameParams });
                }
            });
        }
    };

    const Delcallback = (value) => {
        dispatch(retrieveTestName(1, 0, 0));
        setID({ showPopupDelete: false, Params: [] });
    }

    const commentsData = useMemo(() => {
        let computedComments = comments;
        if (computedComments != undefined) {
            if (search) {
                computedComments = computedComments.filter(test => test.testName.toLowerCase().includes(search.toLowerCase()));
            }
            // if (sorting.field) {
            //     const reversed = sorting.order === "asc" ? 1 : -1;
            //     computedComments = computedComments.sort((a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]));
            // }
            if (sorting.field) {
                const reversed = sorting.order === 'asc' ? 1 : -1;
                computedComments = computedComments.sort((a, b) => {
                  const valueA = a[sorting.field];
                  const valueB = b[sorting.field];
              
                  // Check if both values are numeric
                  const isNumericA = !isNaN(valueA);
                  const isNumericB = !isNaN(valueB);
              
                  // Check if both values are valid date-time strings using moment
                  const isDateTimeA = moment(valueA, moment.ISO_8601, true).isValid();
                  const isDateTimeB = moment(valueB, moment.ISO_8601, true).isValid();
              
                  if (isNumericA && isNumericB) {
                    // If both values are numeric, compare as numbers
                    return reversed * (parseFloat(valueA) - parseFloat(valueB));
                  } else if (isDateTimeA && isDateTimeB) {
                    // If both values are valid date-time strings, compare as dates
                    return reversed * (moment(valueA).valueOf() - moment(valueB).valueOf());
                  } else {
                    // If one or both values are non-numeric and non-valid date-time strings, compare as strings
                    return reversed * valueA.toString().localeCompare(valueB.toString());
                  }
                });
              }
            let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
            if (records.length === 0 && currentPage != 1) {
                setCurrentPage(currentPage - 1);
            }
            setTotalItems(computedComments.length);
            return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
        }
    }, [
        comments,
        currentPage,
        search,
        sorting,
        ITEMS_PER_PAGE
    ]);

    useEffect(() => {
        dispatch(retrieveTestName(1, 0, 0));
        const state1 = props.location.state;
        if (state1 !== undefined && state1 != null) {
            let Msg;
            let Type = 'success';
            let title = 'Success!';
            if (state1.message === 'Edit') {
                Msg = 'Test Name updated successfully.';
            } else if (state1.message === 'Add') {
                Msg = 'Test Name added successfully.';
            } else {
                Msg = 'Error occured';
                Type = 'warning';
                title = 'Error!';
            }
            window.history.replaceState(null, '')
            Nodify(title, Type, Msg);
        }
        var page = getPlaceholder;
        var setConstraint = SearchRetain(page);
        if (setConstraint !== undefined) {
            setCurrentPage(setConstraint.CurrentPage);
            setITEMS_PER_PAGE(setConstraint.PageItems);
        }
    }, [dispatch]);

    useEffect(() => {
        if (!isLoadingTestName) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingTestName]);

    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className="widget-header">
                    <span className="widget-caption">Test Name List</span>
                </div>
                <div className="widget-body" style={
                    { paddingBottom: '4%' }
                }>
                    <div className="dataTables_wrapper form-inline no-footer">
                        <div className="table-toolbar pull-left">
                            <Search
                                onSearch={
                                    value => {
                                        setSearch(value);
                                        setCurrentPage(1);
                                    }
                                }
                                placeholder={getPlaceholder} props={props} />
                        </div>
                        <div className="table-toolbar pull-right" style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}>
                            &nbsp;
                            <span
                                className="btn btn-primary" style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}
                                onClick={() => pageRedirect("", 'Add')} title="Add Test Name">
                                <i className="fa fa-plus"></i>&nbsp;Add
                            </span>
                        </div>
                        {/* <div style={{ width: '50%', height: "200px", background: "red" }}>
                            <ReactCompareImage
                                leftImage="https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80"
                                rightImage="https://images.unsplash.com/photo-1501504905252-473c47e087f8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1548&q=80" />;
                        </div> */}
                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                            <TableHeader headers={headers} onSorting={(field, order) => setSorting({ field, order })} />
                            <tbody>
                                {
                                    commentsData != undefined && commentsData.map((comment,i) => (
                                        <Fragment key={i}>
                                        <tr key={comment.testNameID}>
                                           <td style={{width:'25px'}}>  
                                              { i + 1}
                                            </td>
                                            <td className='cut_text' title={comment.testName}>{comment.testName}</td>
                                            <td className='cut_text' title={comment.typeName}>{comment.typeName}</td>
                                            <td className='cut_text'>{comment.createdByName != null && comment.createdByName != undefined ? comment.createdByName + " On " + comment.createdDate : ""}</td>

                                            <td style={{
                                                            display: activeMenu && activeMenu[0].isEdit === 0 &&
                                                                activeMenu[0].isDelete === 0 ? 'none' : ''
                                                        }}>
                                                <span style={{ display: activeMenu && activeMenu[0].isEdit === 1 ? '' : 'none' }} title='Edit Test Name' className="btn btn-info btn-xs edit"
                                                    onClick={() => pageRedirect(comment, 'Edit', getPlaceholder)}>
                                                    <i className='fa fa-edit'></i>
                                                </span>&nbsp;
                                                <span style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }} title='Delete Test Name' className="btn btn-danger btn-xs delete" onClick={() => pageRedirect(comment, '', getPlaceholder)}>
                                                    <i className='fa fa-trash'></i>
                                                </span>
                                            </td>
                                        </tr>
                                        </Fragment>
                                    ))
                                }
                                {
                                    totalItems === 0 ?
                                        <tr>
                                            <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
                                        </tr> : ''
                                }
                            </tbody>
                        </table>
                        <PageCount onPageCount={
                            value => {
                                setITEMS_PER_PAGE(value);
                                setCurrentPage(1);
                            }
                        } />
                        <Pagination total={totalItems}
                            itemsPerPage={
                                parseInt(ITEMS_PER_PAGE)
                            }
                            currentPage={currentPage}
                            onPageChange={
                                page => setCurrentPage(page)
                            } />
                    </div>
                </div>

                {
                    getID.showPopupDelete ? <SweetAlertPopup data={
                        getID.Params
                    }
                        deleteCallback={Delcallback}
                        showpopup={true}
                        pageActionId={TestName}
                        Msg={"Test Name deleted successfully."} /> : null
                }
            </div>
            {loader}
        </>
    )
}
export default TestNameList;