import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import { Modal, Button } from "react-bootstrap";
import SeasonService from "../../services/Master/SeasonService";
import Nodify from "../Common/ReactNotification";
import { insertUpdateSeasonGrid } from "../../actions/season";

const SeasonPopup = ({
    seasonCallback,
    showpopup,
}) => {

    const EmptySeasonInputFields =
        [{
            SeasonID: 0,
            SeasonName: '',
            Status:1,
        }]

    const [buttonLoader, setButtonLoader] = useState(false);
    const [seasonInputFields, setSeasonInputFields] = useState(EmptySeasonInputFields);
    const [seasonList, setSeasonList] = useState([]);
    const [show, setShow] = useState(true);
    const [seasonSubmitted, setSeasonSubmitted] = useState(false);

    const dispatch = useDispatch();
    //reducer
    const reducerState = useSelector((state) => state);
    const { user: currentUser } = reducerState.auth;
    const getSeasonList = reducerState.season.seasonList;
    const values = [...seasonInputFields];
    const handlePopupClose = () => {
        seasonCallback(false);
    };

    const CheckDuplicate = (index) => {
       ;
        
        if (getSeasonList) {
            for (var i = 0; i < getSeasonList.length; i++) {
                if (getSeasonList[i].label.trim().toLowerCase() === values[index].SeasonName.trim().toLowerCase()) {
                    values[index].SeasonName = '';
                    setSeasonInputFields(values);
                    setSeasonList({ Createdby: currentUser.employeeinformationID, SeasonInformation: values });
                    Nodify('Warning!', 'warning', "This name is already exist.");
                    return false;
                }
            }
        }
    }

    const handleSeasonChange = (event) => {
        setSeasonSubmitted(false);
       // const values = [...seasonInputFields];
        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value.trim();
        }
        values[0].SeasonName = inputText;
        setSeasonInputFields(values);
        setSeasonList({ Createdby: currentUser.employeeinformationID, SeasonInformation: values });
    }

    const SaveSeason = (e) => {
        setButtonLoader(true);
       ;
        e.preventDefault();
       // const values = [...seasonInputFields];
        if (values[0].SeasonName === '') {
            setSeasonSubmitted(false);
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Season name is required.');
        }
        else 
         {
            dispatch(insertUpdateSeasonGrid(seasonList))
                .then(data => {
                    if (data.outputResult === "1") {
                        Nodify("Success!", "success", "Season added successfully.");
                        seasonCallback(true);
                    } else if (data.outputResult === "-2") {
                        values[0].SeasonName = '';
                        Nodify('Warning!', 'warning', 'This name is already exist.');
                    }
                    setButtonLoader(false);
                })
                .catch(e => {
                    console.log(e);
                });
        }
        // else {
        //     setSeasonSubmitted(true);
        // }
    }
    const handleCheckboxChange = (event) => {
       
        const isChecked = event.target.checked;
        setSeasonInputFields((prevState) => {
            const updatedFields = [...prevState];
            updatedFields[0].Status = isChecked ? 1 : 0;
            return updatedFields;
        });
    };

    return (
        // <Modal show={showpopup} size="md" dragable backdrop="static" keyboard={false} onHide={() => handlePopupClose()}>
        //     <Modal.Header closeButton>
        //     <Modal.Title>Add Season</Modal.Title>
        //     </Modal.Header>
        //       <Modal.Body>
        <div id="registration-form">
            <Form>
                <div className="row">
                    <div className="form-group col-sm-8">
                        <label htmlFor="SeasonName">
                            Season Name
                            <span className="text-danger">*</span>
                        </label>
                        <Input
                            placeholder={"Enter Season"}
                            id="SeasonName"
                            name="SeasonName"
                            type="text"
                            maxLength="50"
                            value={seasonInputFields[0].SeasonName}
                            autoComplete="off"
                            onChange={handleSeasonChange}
                            className='form-control'
                            onBlur={() => CheckDuplicate(0)}
                            style={{ border: seasonSubmitted && !seasonInputFields[0].SeasonName ? '1px solid red' : '' }}
                            autoFocus
                        />
                    </div>
                    <div className="form-group col-sm-4">
            <label htmlFor="Status" className="mt-5">Status</label>
            <span className="input-icon icon-right">
                <input
                    className="checkbox-slider checkbox-slider_Status colored-blue mt-5"
                    id="Status"
                    name="Status"
                    checked={seasonInputFields[0].Status === 1}
                    autoComplete="off"
                    onChange={handleCheckboxChange}
                    type="checkbox"
                />
                <span className="text"></span>
            </span>
        </div>
                </div>
                <br />
                <div style={{ textAlign: "right" }}>
                    <Button type="button" className="btn btn-success" onClick={SaveSeason} disabled={buttonLoader}>
                        <i className="fa fa-check right"></i> &nbsp; Save
                    </Button>
                </div>
            </Form>
        </div>

        //   </Modal.Body>
        //   <Modal.Footer>
        //     <Button  type="submit" className="btn btn-success" onClick={SaveSeason} disabled={buttonLoader}>
        //        <i className="fa fa-check right"></i> &nbsp; Save
        //     </Button>
        //   </Modal.Footer>
        // </Modal>
    )
};

export default SeasonPopup;