import React, { useState, useEffect, useRef, Fragment } from "react";
import ReactNotification from 'react-notifications-component';
import Nodify from "../../../Common/ReactNotification"
import SwatchMasterService from "../../../../services/Master/SwatchMasterService";
import BuyerService from "../../../../services/Master/BuyerService";
import StyleService from "../../../../services/Style/styleService";
import validator from 'validator'
import SearchRetain from "../../../Common/SearchRetain";
import axios from "axios";
import $ from "jquery";
import Lightbox from "react-image-lightbox"
import { useSelector } from "react-redux";

const AddSwatchMaster = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);

    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    if (menulist !== undefined) {
        activeMenu = menulist.filter(i => i.menuName === "Swatch");
    }
    //end enable menu

    const EmptyInputFields =
    {
        SwatchMasterID: 0, BuyerID: 0, BrandID: 0, SwatchName: "", SwatchImageFileName: "", SwatchImageFilePath: ""
    }

    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getSwatchMasterList, setSwatchMasterList] = useState([EmptyInputFields]);
    const [ExistingList, setExistingList] = useState([]);
    const [ButtonName, setButtonName] = useState("Save");
    const [HeaderName, setHeaderName] = useState("Add");
    const [getBuyerList, setBuyerList] = useState([]);
    const [getBrandList, setBrandList] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [getImagename, setImagename] = useState();


    let ListOperation = 2;
    let BuyerIDDefault = 0;
    var ImgClassName = "upload-btn-wrapper2"

    const values = [...getSwatchMasterList];

    function GroupListing(props) {
        //let GroupType = [{ Id: 0, Name: '', Operation: 1, Createdby: 1 }];
        let SwatchMaster = [...getSwatchMasterList];
        Array.from(document.querySelectorAll("input")).forEach(input => (input.value = ""));
        if (props.location.state.params.length !== 0) {
            if (props.location.state.params.Id !== "") {
                setButtonName("Update");
                setHeaderName("Edit");
                SwatchMaster.SwatchMasterID = props.location.state.params.swatchMasterID;
                SwatchMaster.BuyerID = validator.trim(props.location.state.params.buyerID);
                SwatchMaster.BrandID = validator.trim(props.location.state.params.brandID);
                SwatchMaster.SwatchName = validator.trim(props.location.state.params.swatchName);
                SwatchMaster.SwatchImageFileName = validator.trim(props.location.state.params.swatchImageFileName);
                SwatchMaster.SwatchImageFilePath = validator.trim(props.location.state.params.swatchImageFilePath);
                SwatchMaster.Operation = 2;
                return SwatchMaster;
            }
        } else {
            return SwatchMaster;
        }
    }

    useEffect(() => {
        let getGroupList = GroupListing(props);
        setSwatchMasterList(getGroupList)
        SwatchMasterService.GetSwatchMasterList(1, 0, 0).then((response) => {
            if (response.data) {
                setExistingList(response.data);
            }
        });

        BuyerService.LoadBuyerList(ListOperation)
            .then((response) => {
                if (response.data) {
                    setBuyerList(response.data);
                } else {
                }
            })
            .catch(() => { });


        StyleService.LoadBrandThemeStoryEndCustomer(6, 0).then((response) => {
            if (response.data) {
                let BuyerInfoList = response.data;
                let respected_Brand = BuyerInfoList.filter(x => x.buyerMapID === 1);
                setBrandList(respected_Brand);
            }

        }, []);

    }, []);




    const handleInputChange = (index, event) => {

        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }
        if (event.target.name === "BuyerID") {
            values[index].BuyerID = parseInt(inputText);

        } else if (event.target.name === "BrandID") {
            values[index].BrandID = parseInt(inputText);
        } else if (event.target.name === "SwatchName") {
            values[index].SwatchName = inputText;
        }
        setSwatchMasterList(values);
    };


    const CheckDuplicate = (index, Field) => {

        if (ExistingList) {
            if (values[index].BuyerID !== 0 && values[index].BrandID !== 0 && values[index].SwatchName !== '') { // for avoid empty validation
                for (var i = 0; i < ExistingList.length; i++) {
                    if (ExistingList[i].buyerID === values[index].BuyerID
                        && ExistingList[i].brandID === values[index].BrandID
                        && ExistingList[i].swatchName.trim().toLowerCase() === values[index].SwatchName.trim().toLowerCase()
                    ) {

                        values[index].SwatchName = "";

                        setSwatchMasterList(values);
                        Nodify('Warning!', 'warning', "This Swatch Name Combination is already exist.");
                        return false;
                    }
                }
            }
        }

        for (var j = 0; j < (getSwatchMasterList.length); j++) {
            if (j !== index) {
                if (getSwatchMasterList[j].BuyerID === getSwatchMasterList[index].BuyerID
                    && getSwatchMasterList[j].BrandID === getSwatchMasterList[index].BrandID
                    && getSwatchMasterList[j].SwatchName.trim().toLowerCase() === getSwatchMasterList[index].SwatchName.trim().toLowerCase()
                ) {
                    values[index].SwatchName = "";
                    setSwatchMasterList(values);
                    Nodify('Warning!', 'warning', 'This Swatch Name Combination is already exist.');
                    return false;
                }
            }
        }


    }


    const SaveSwatchMasterList = (e) => {

        setButtonLoader(true);
        e.preventDefault();
        if (getSwatchMasterList.length === 1 && getSwatchMasterList[0].BuyerID === 0
            && getSwatchMasterList[0].BrandID === 0 && getSwatchMasterList[0].SwatchName !== '') {
            setButtonLoader(false);
            setSubmitted(true);
            ValidationPopup("Please fill atleast one Swatch detail.");
        }
        else {
            for (var i = 0; i < (getSwatchMasterList.length); i++) {

                if (getSwatchMasterList[i].BuyerID === 0) {
                    Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
                    setSubmitted(true);
                    setButtonLoader(false);
                    return false;
                }
                if (getSwatchMasterList[i].BrandID === 0) {
                    Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
                    setSubmitted(true);
                    setButtonLoader(false);
                    return false;
                }
                if (getSwatchMasterList[i].SwatchName.trim() === '') {
                    Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
                    setSubmitted(true);
                    setButtonLoader(false);
                    return false;
                }
                if (getSwatchMasterList[i].SwatchImageFileName.trim() === '') {
                    Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
                    setSubmitted(true);
                    setButtonLoader(false);
                    return false;
                }
            }

            let InputCommonMaster = { Createdby: currentUser.employeeinformationID, SwatchMasterInformation: getSwatchMasterList };
            SwatchMasterService.InsertUpdateSwatchMasterGrid(InputCommonMaster).then(res => {
                var page = "Remove";
                SearchRetain(page);

                let Func = 'Add';
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "-2") {
                    setButtonLoader(false);
                    ValidationPopup("This Swatch Name is already exist.");
                }
                else if (res.data.outputResult === "0") {
                    setButtonLoader(false);
                    ValidationPopup("Error Occured!");
                }
            });
        }
    }

    const PageRedirect = (Func) => {
        props.history.push({ pathname: "/SwatchMasterList", state: { message: Func } });
    }

    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
        return false;
    }

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/SwatchMasterList')
        } else {
            window.location.reload();
        }
    }
    const handleRemoveFields = index => {
        values.splice(index, 1);
        setSwatchMasterList(values);
    }

    const handleAddFields = (index) => {
        if (values[index].BuyerID !== 0 && values[index].BrandID !== 0 && values[index].SwatchName !== ''
            && values[index].SwatchImageFileName !== '') {
            values.push(EmptyInputFields);
            setSwatchMasterList(values);
            setSubmitted(false);
        } else {
            Nodify('Warning!', 'warning', 'Please fill this mandatory(*) fields.');
            setSubmitted(true);
        }
    }

    const openFileUpload = () => {
        $('#upload-button').trigger('click');
    }

    const UploadImages = async (index, e) => {
       ;
        const file = e.target.files[0];
        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png'];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#FileUpload').val("");
            }

            else {
                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "Swatch");
                try {
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                    values[index].SwatchImageFileName = file.name;
                    values[index].SwatchImageFilePath = "Images/SwatchImages/" + res.data + '_' + file.name;
                    setSwatchMasterList(values);
                    $('#FileUpload').val("");

                } catch (ex) {
                    console.log(ex);
                }
            }
        }
    }

    const ViewFile = (event, filename) => { // event.preventDefault();
       
        setIsOpen(true);
        setImagename(filename);
    }

    return (
        <div className="page-body">
            <ReactNotification />
            {
                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <div className="widget flat radius-bordered">
                            <div className="widget-header heading_box_style">
                                <h3 className="heading_style_common">{HeaderName} Swatch Master</h3>
                            </div>
                            <div className="widget-body">
                                <div id="registration-form">
                                    {/* <Form onSubmit={}> */}
                                    <form>
                                        {
                                            getSwatchMasterList.map((inputField, index) => (
                                                <Fragment key={
                                                    `${inputField}~${index}`
                                                }>
                                                    <div className="row row_left12">
                                                        <div className="col-sm-3 divder_style">
                                                            <div className="form-group">
                                                                <span className="input-icon icon-right">
                                                                    <label htmlFor={
                                                                        "BuyerID_" + index
                                                                    }>Buyer<span className="text-danger">*</span>
                                                                    </label>
                                                                    <select id={
                                                                        "BuyerID_" + index
                                                                    }
                                                                        name="BuyerID"
                                                                        className="form-select"
                                                                        value={
                                                                            inputField.BuyerID
                                                                        }
                                                                        onChange={
                                                                            event => handleInputChange(index, event)
                                                                        }
                                                                        onBlur={() => CheckDuplicate(index, 'BuyerID')}
                                                                        style={{ border: submitted && inputField.BuyerID === 0 ? '1px solid red' : '' }}
                                                                    >
                                                                        <option value='0'>- Select Buyer -</option>
                                                                        {
                                                                            getBuyerList.map(item => (
                                                                                <option key={
                                                                                    item.id
                                                                                }
                                                                                    value={
                                                                                        item.id
                                                                                    }>
                                                                                    {
                                                                                        item.name
                                                                                    }
                                                                                </option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-3 divder_style">
                                                            <div className="form-group">
                                                                <span className="input-icon icon-right">
                                                                    <label htmlFor={
                                                                        "BrandID_" + index
                                                                    }>Brand<span className="text-danger">*</span>
                                                                    </label>
                                                                    <select id={
                                                                        "BrandID_" + index
                                                                    }
                                                                        name="BrandID"
                                                                        className="form-select"
                                                                        value={
                                                                            inputField.BrandID
                                                                        }
                                                                        onChange={
                                                                            event => handleInputChange(index, event)
                                                                        }
                                                                        onBlur={() => CheckDuplicate(index, 'BrandID')}
                                                                        style={{ border: submitted && inputField.BrandID === 0 ? '1px solid red' : '' }}
                                                                    >
                                                                        <option value='0'>- Select Brand -</option>
                                                                        {/* {
                                                                            getBrandList.map(item => (
                                                                                <option key={
                                                                                    item.id
                                                                                }
                                                                                    value={
                                                                                        item.id
                                                                                    }>
                                                                                    {
                                                                                        item.name
                                                                                    }
                                                                                </option>
                                                                            ))
                                                                        } */}

                                                                        {
                                                                            getBrandList.filter(x => x.buyerID === parseInt(inputField.BuyerID)).map(items => (
                                                                                <>
                                                                                    <option key={items.id} value={items.id}>
                                                                                        {items.name}
                                                                                    </option>
                                                                                </>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </span>
                                                            </div>
                                                        </div>



                                                        <div className="col-sm-2 divder_style">
                                                            <div className="form-group">
                                                                <label htmlFor={
                                                                    "SwatchName_" + index
                                                                }>Swatch Name<span className="text-danger">*</span></label>
                                                                <span className="input-icon icon-right">
                                                                    <input
                                                                        placeholder={"Enter Swatch Name"}
                                                                        id={"SwatchName_" + index}
                                                                        name="SwatchName"
                                                                        value={inputField.SwatchName}
                                                                        type="text"
                                                                        maxLength="75"
                                                                        autoComplete="off"
                                                                        onBlur={() => CheckDuplicate(index, 'SwatchName')}
                                                                        onChange={
                                                                            event => handleInputChange(index, event)
                                                                        }
                                                                        className='form-control'
                                                                        style={{ border: submitted && !inputField.SwatchName ? '1px solid red' : '' }}
                                                                    /></span>

                                                            </div>
                                                        </div>

                                                        <div className="col-sm-2 divder_style">
                                                            <div className="form-group">
                                                                <label htmlFor={"SwatchImageFilePath_" + index}>Swatch Image<span className="text-danger">*</span></label>


                                                                <div className={ImgClassName}
                                                                    htmlFor="upload-button"
                                                                >
                                                                    <img src={inputField.SwatchImageFilePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + inputField.SwatchImageFilePath} onClick={openFileUpload}
                                                                        alt="Picture" style={{ marginBottom: '5px', height: '125px', width: '100%', border: "1px solid #d5d5d5" }} />
                                                                    <input type="file" id="upload-button" accept=".jpg,.jpeg,.png" onChange={(event) => UploadImages(index, event)} value='' />
                                                                    {
                                                                        inputField.SwatchImageFileName !== "assets/img/uploadimage.jpg" && (
                                                                            <div style={
                                                                                {
                                                                                    marginTop: '8px',
                                                                                    float: 'right',
                                                                                    bottom: '10px',
                                                                                    position: 'relative',
                                                                                }
                                                                            }>
                                                                                {
                                                                                    inputField.SwatchImageFilePath !== "" && inputField.SwatchImageFilePath !== "assets/img/uploadimage.jpg" ?
                                                                                        <i className="btn btn-info fa fa-eye" title="View Logo"
                                                                                            style={{ marginLeft: "1rem", position: "absolute" }}
                                                                                            onClick={
                                                                                                event => ViewFile(event, inputField.SwatchImageFilePath)
                                                                                            }></i>
                                                                                        : ''

                                                                                }

                                                                            </div>
                                                                        )
                                                                    }

                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div className="col-sm-2 paddingstatic">
                                                            {
                                                                getSwatchMasterList.length !== 1 && (
                                                                    <div style={{ marginLeft: "18px" }} className="col-lg-1_own col-xs-12 adddeletemargin">
                                                                        <button type="button" className="btn btn-danger"
                                                                            style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}
                                                                            title="Delete Swatch Master" onClick={() => handleRemoveFields(index)}>
                                                                            <i className="fa fa-trash-o"></i>
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            {
                                                                getSwatchMasterList.length === (index + 1) && (
                                                                    <div style={{ marginLeft: "18px" }} className="col-lg-1_own col-xs-12 adddeletemargin">

                                                                        <button type="button" className="btn btn-success" title="Add Swatch Master" onClick={() => handleAddFields(index)}>
                                                                            <i className="fa fa-plus"></i>
                                                                        </button>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            ))
                                        }

                                        <div className="clearfix"></div>
                                        <div className="col-sm-12">
                                            <div className="buttons-preview" align="right">
                                                <span className="btn btn-primary"
                                                    onClick={
                                                        () => ResetOperation("Back")
                                                    }>
                                                    <i className="fa fa-arrow-circle-left"></i>
                                                    &nbsp;Back</span>
                                                <span className="btn btn-danger"
                                                    onClick={
                                                        () => ResetOperation()
                                                    }>
                                                    <i className="fa fa-times"></i>
                                                    &nbsp;Reset</span>
                                                <button type="submit" className="btn btn-success" onClick={SaveSwatchMasterList}
                                                    disabled={buttonLoader}>

                                                    <i className="fa fa-check right"></i>
                                                    &nbsp;{
                                                        ButtonName
                                                    }</button>
                                            </div>
                                        </div>

                                        <div> {
                                            isOpen && (
                                                // <Lightbox mainSrc={window.$APIBaseURL + 'Images/SwatchImages/' + getImagename}
                                                <Lightbox mainSrc={window.$APIBaseURL + getImagename}
                                                    alt="bg image"
                                                    onCloseRequest={
                                                        () => setIsOpen(false)
                                                    } />
                                            )
                                        } </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            { }
        </div>
    );

}
export default AddSwatchMaster

