import {
    ROLE_LOADING,
    RETRIEVE_ROLE,
    DELETE_ROLE,
    RETRIEVE_USERTYPE,
} from "../actions/types";

const initialState = {
    isLoadingRole: true,
    roleList: [],
    userTypeList: [],
};

const roleReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case ROLE_LOADING:
            return {
                ...state,
                isLoadingRole: payload
            };
        case RETRIEVE_ROLE:
            return { ...state, roleList: payload };
        case RETRIEVE_USERTYPE:
            return { ...state, userTypeList: payload };
        case DELETE_ROLE:
            const results = state.roleList.filter(c => c.roleID !== payload.roleID);
            return {
                ...state,
                roleList: results
            };

        default:
            return state;
    }
};
export default roleReducer;