import {
    STRIKEOFFTYPE_LOADING,
    RETRIEVE_STRIKEOFFTYPE,
    DELETE_STRIKEOFFTYPE,
} from "./types";

import StrikeOffTypeService from "../services/Master/StrikeOffTypeService";

const strikeofftypeLoading = (isStatus) => ({
    type: STRIKEOFFTYPE_LOADING,
    payload: isStatus,
});

export const deleteStrikeofftype = (strikeOffTypeID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_STRIKEOFFTYPE,
            payload: strikeOffTypeID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrieveStrikeofftype = () => async (dispatch) => {
    try {
        dispatch(strikeofftypeLoading(true));
        const res = await StrikeOffTypeService.GetStrikeOffTypesList();
        dispatch({
            type: RETRIEVE_STRIKEOFFTYPE,
            payload: res.data,
        });
        dispatch(strikeofftypeLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(strikeofftypeLoading(false));
    }
};