
import {
    SCHEDULEREPORT_LOADING,
    RETRIEVE_SCHEDULEREPORT
} from "./types";
import ScheduleReportService from "../services/ScheduleReport/ScheduleReportService";

const styleLoading = (isStatus) => ({
    type: SCHEDULEREPORT_LOADING,
    payload: isStatus,
});

export const retrieveScheduleReportList = (Params) => async (dispatch) => {
    try {
        dispatch(styleLoading(true));
        const res = await ScheduleReportService.GetScheduleReportList(Params.RouteId, Params.IsProduction, Params.employeeinformationID, Params.PoStartDate, Params.PoEndDate);
        dispatch({
            type: RETRIEVE_SCHEDULEREPORT,
            payload: res.data,
        });
        dispatch(styleLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(styleLoading(false));
    }
};

export const retrievePurchaseOrder = (purchaseOrderID, IsProduction) => async (dispatch) => {
    try {
        dispatch(styleLoading(true));
        const res = await ScheduleReportService.GetPurchaseOrderList(purchaseOrderID, IsProduction);
        dispatch({
            type: RETRIEVE_SCHEDULEREPORT,
            payload: res.data,
        });
        dispatch(styleLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(styleLoading(false));
    }
};

export const retrieveTNA = (Params) => async (dispatch) => {
    try {
        dispatch(styleLoading(true));
        const res = await ScheduleReportService.GetScheduleTNAList(Params.Operation,Params.TNAStartDate, Params.TNAEndDate);
        dispatch({
            type: RETRIEVE_SCHEDULEREPORT,
            payload: res.data,
        });
        dispatch(styleLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(styleLoading(false));
    }
};
