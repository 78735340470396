import React, { useState, useEffect, useRef, Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactNotification from 'react-notifications-component';
// import LabDipViewDetailTab from "./LabDipViewDetailTab";
// import LabDipViewRequestTab from "./LabDipViewRequestTab";
import LabTestsViewSubmissionTab from "./SamOrdLabTestsViewApprovalView";
import LabTestsViewApprovalTab from "./SamOrdLabTestsViewApprovalView";
import { getOrderLapdipDetailsViewList } from "../../../../actions/orderLabdip";


const SamOrdLabTestsView = (props) => {
    const dispatch = useDispatch();
    let BuyerId = 0;
    let BrandId = 0;
    let SeasonId = 0;
    let PantoneId = 0;

    let BuyerName = "";
    let BrandName = "";
    let SeasonName = "";

    //get props params
    if (props.location.state !== undefined) {

        BuyerId = props.location.state.params.buyerId === undefined ? 0 : props.location.state.params.buyerId;
        BrandId = props.location.state.params.brandId === undefined ? 0 : props.location.state.params.brandId;
        SeasonId = props.location.state.params.seasonId === undefined ? 0 : props.location.state.params.seasonId;
        BuyerName = props.location.state.params.buyerName === undefined ? 0 : props.location.state.params.buyerName;
        BrandName = props.location.state.params.brandName === undefined ? 0 : props.location.state.params.brandName;
        SeasonName = props.location.state.params.seasonName === undefined ? 0 : props.location.state.params.seasonName;
    }

    //status
    const [tabStatus, setTabStatus] = useState(1);

    //Tabs
    const tabClick = (tab) => {
        setTabStatus(tab);
    }


    useEffect(() => {
        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, PantoneId: PantoneId, Index: 0,
            SearchText: "", Operation: 1
        };
        dispatch(getOrderLapdipDetailsViewList(params));

    }, []);



    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/LabTestslist');

        } else {

        }
    }

    return (
        <Fragment>
            <div className="page-body">
                <ReactNotification />
                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <div className="widget flat radius-bordered">
                            <div className="widget-header heading_box_style">
                                <h3 className="heading_style_common"> LabTests View </h3>
                                <h5 className="heading_style_common"> &nbsp;&nbsp; ({BuyerName + " - " + SeasonName + " - " + BrandName})  </h5>
                                <div className="buttons-preview" align="right">
                                    <span className="btn btn-primary"
                                        onClick={
                                            () => ResetOperation("Back")
                                        }
                                    >
                                        <i className="fa fa-arrow-circle-left"></i>
                                        &nbsp;Back</span>
                                </div>
                            </div>

                            <div className="widget-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="tabbable">
                                            <ul className="nav nav-tabs nav-justified" id="myTab5">
                                                {/* <li  onClick={() => tabClick(1)}>
                                                    <a data-toggle="tab" href="#detailTab">
                                                        <h5>Details</h5>
                                                    </a>
                                                </li> */}
                                                {/* <li className="" onClick={() => tabClick(2)} >
                                                    <a data-toggle="tab" href="#requestTab">
                                                        <h5>Requested (TNA)</h5>
                                                    </a>
                                                </li> */}
                                                <li className="active" onClick={() => tabClick(1)} >
                                                    <a data-toggle="tab" href="#submissionTab">
                                                        <h5>Submitted</h5>
                                                    </a>
                                                </li>
                                                <li className="" onClick={() => tabClick(2)} >
                                                    <a data-toggle="tab" href="#approvalTab">
                                                        <h5>Approved</h5>
                                                    </a>
                                                </li>
                                            </ul>
                                            <div className="tab-content">
                                                {/* <div id="detailTab" className="tab-pane in active" style={{ width: "100%" }}> */}
                                                {/* {
                                                        <LabDipViewDetailTab
                                                            props={props} />
                                                    } */}
                                                {/* </div> */}
                                                {/* <div id="requestTab" className="tab-pane" style={{ width: "100%" }}> */}

                                                {/* <LabDipViewRequestTab props={props} /> */}
                                                {/* </div> */}
                                                <div id="submissionTab" className="tab-pane in active" style={{ width: "100%" }}>
                                                    <LabTestsViewSubmissionTab props={props} />

                                                </div>
                                                <div id="approvalTab" className="tab-pane" style={{ width: "100%" }}>
                                                    <LabTestsViewApprovalTab props={props} />

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </Fragment>
    )

}

export default SamOrdLabTestsView;