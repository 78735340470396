import React from 'react';
import { useState, useEffect, Fragment, useMemo } from "react";
import ReactNotification from 'react-notifications-component';
import { retrieveTask } from '../../../../actions/task';
import { useDispatch, useSelector } from "react-redux";
import Reactselect from 'react-select';
import Nodify from '../../../Common/ReactNotification';
import TestService from '../../../../services/Master/TestService';
import SearchRetain from "../../../Common/SearchRetain";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";

const TestNameRegister = (props) => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const EmptyInputFields = { TestNameID: 0, TestName: '', TaskID: '', TaskIDforEdit: '', TypeID: '' };
    const [inputFields, setInputFields] = useState([EmptyInputFields]);
    const [submitted, setSubmitted] = useState(false);
    const [getIsTestNameDuplicated, setIsTestNameDuplicated] = useState(false);

    const [getTaskOption, setTaskOption] = useState([]);
    const [getTypeOption, setTypeOption] = useState([]);
    const [getExisting, setExisting] = useState([]);
    const [buttonLoader, setButtonLoader] = useState(false);
    const { user: currentUser } = useSelector((state) => state.auth);
    const values = [...inputFields];
    const dispatch = useDispatch();
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red', innerHeight: '100px', outerHeight: '100px' }),
    }
    let styles1 = {
        control: styles => ({ ...styles, innerHeight: '100px', outerHeight: '100px' }),
    }
    //reducer
    const reducerState = useSelector((state) => state);
    const comments = reducerState.task.taskList;
    useEffect(() => {
        dispatch(retrieveTask(0));
    }, [dispatch]);

    const commentsData = useMemo(() => {
        if (comments.length > 0) {
            let TestingType = comments.filter((data => {
                if (data.taskTypeName == "Testing") {
                    return data;
                }
            }));
            let computedTaskOptions = TestingType.map((data => {
                data.id = data.taskDetailsID;
                data.value = data.taskDetailsID;
                data.label = data.taskName + " - " + data.taskOrderTypeName;
                data.name = data.taskName + " - " + data.taskOrderTypeName;
                return { id: data.id, name: data.name, label: data.label, value: data.value };
            }));
            setTaskOption(computedTaskOptions);
            setTypeOption(
                [{ id: 1, value: 1, label: 'Internal', name: 'Internal' }
                    , { id: 2, value: 2, label: 'External', name: 'External' }])
        }
    }, [comments]);

    useEffect(() => {
        TestService.GetTestNameList(1, 0, 0).then((res => {
            if (res.data) {
                setExisting(res.data.testNameList);
            }
        }))
    }, [])

    //Enter Error msg
    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                //SaveBuyerInfo(event);
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);

    const handleInputChange = (index, event) => {
        const values = [...inputFields];
        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }
        values[index].TestName = inputText;
        setInputFields(values);
    }

    const handleChangeTask = (event, index) => {
        if (event.length === 0) {
            values[index].TaskID = "";
            values[index].IndexID = index != 0 ? values[index - 1].IndexID + 1 : index;
            values[index].TaskIDforEdit = '';
        } else {
            let taskValue = "";
            for (let task of event) {
                if (taskValue === "") {
                    taskValue = task.value.toString();
                } else {
                    taskValue = taskValue + "," + task.value;
                }
            }
            values[index].TaskID = taskValue;
            values[index].TaskIDforEdit = event;
        }
        setInputFields(values);
    }

    const handleChangeType = (event, index) => {
        {
            if (event.length === 0) {
                values[index].TypeID = "";
                values[index].IndexID = index != 0 ? values[index - 1].IndexID + 1 : index;
                values[index].TypeIDforEdit = '';
            } else {
                let typeValue = "";
                for (let type of event) {
                    if (typeValue === "") {
                        typeValue = type.value.toString();
                    } else {
                        typeValue = typeValue + "," + type.value;
                    }
                }
                values[index].TypeID = typeValue;
                values[index].TypeIDforEdit = event;
            }
            setInputFields(values);
        }
    }

    const AddTestName = (index) => {
        setSubmitted(true);
        const values = [...inputFields];
        if ((values[index].TestName == null || values[index].TestName == "" || values[index].TestName == undefined)
            || (values[index].TaskIDforEdit == null || values[index].TaskIDforEdit == "" || values[index].TaskIDforEdit == undefined)
            || (values[index].TypeIDforEdit == null || values[index].TypeIDforEdit == "" || values[index].TypeIDforEdit == undefined)) {
            Nodify('Warning!', 'warning', 'Please fill all mandatory(*) fields.');
        }
        else {
            setSubmitted(false);
            values.push(EmptyInputFields);
            setInputFields(values);
        }
    }

    const RemoveTestName = (index) => {
        const values = [...inputFields];
        values.splice(index, 1);
        setInputFields(values);
    }

    const InsertTestName = (e) => {
        showLoader();
        setButtonLoader(true);
        e.preventDefault();
        if (inputFields.length === 1 && (inputFields[0].TestName === '' || !inputFields[0].TaskIDforEdit || !inputFields[0].TypeIDforEdit)) {
            setButtonLoader(false);
            setSubmitted(true);
            ValidationPopup("Please fill the mandatory(*) fields.");
        }
        else {
            for (var i = 0; i < (inputFields.length); i++) {
                if (inputFields[i].TestName.trim() === '' || !inputFields[i].TaskIDforEdit || !inputFields[i].TaskIDforEdit) {
                    Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
                    setSubmitted(true);
                    setButtonLoader(false);
                    return false;
                }
            }
            let Params = { Createdby: currentUser.employeeinformationID, TestNameInfo: inputFields };
            TestService.AddTestName(Params).then((res => {
                hideLoader();
                var page = "Remove";
                SearchRetain(page);
                let Func = 'Add';
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    PageRedirect(Func);
                }
                else if (res.data.outputResult === "0") {
                    setButtonLoader(false);
                    ValidationPopup("Error Occured!");
                }
            }), (error) => {
                hideLoader();
                setButtonLoader(false);
            })
        }
    }

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/TestNameList')
        } else {
            window.location.reload();
        }
    }

    const PageRedirect = (Func) => {
        props.history.push({ pathname: "/TestNameList", state: { message: Func } });
    }
    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
        return false;
    }

    const CheckDuplicate = (index, Feildname) => {
        const values = [...inputFields];
        setIsTestNameDuplicated(true);
        let isExisting = !!getExisting.find(d => d.testName.trim().toLowerCase() === values[index].TestName.trim().toLowerCase())
        if (isExisting) {
            values[index].TestName = '';
            setInputFields(values);
            Nodify('Warning!', 'warning', "This Test Name is already exist.");
            return false;
        }
        else {
            setIsTestNameDuplicated(false);
        }
        for (var j = 0; j < (inputFields.length); j++) {
            if (j !== index) {
                if (values[j].TestName.trim().toLowerCase() === values[index].TestName.trim().toLowerCase()) {
                    values[index].TestName = '';
                    setInputFields(values);
                    Nodify('Warning!', 'warning', 'This Test Name is already exist.');
                    return false;
                }
                else {
                    setIsTestNameDuplicated(false);
                }
            }
            else {
                setIsTestNameDuplicated(false);
            }
        }
    }

    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <div className="widget flat radius-bordered">
                            <div className="widget-header heading_box_style">
                                <h3 className="heading_style_common">Add Test Name</h3>
                            </div>
                            <div className="widget-body">
                                <div id="registration-form">
                                    <form>
                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '25%' }}>Test Name<span className='text-danger'>*</span></th>
                                                    <th style={{ width: '25%' }}>Test Task Name<span className='text-danger'>*</span></th>
                                                    <th style={{ width: '25%' }}>Test Type<span className='text-danger'>*</span></th>
                                                    <th style={{ width: '25%' }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {inputFields.map((inputField, index) => (
                                                    <Fragment key={
                                                        `${inputField}~${index}`
                                                    }>
                                                        <tr>
                                                            <td>
                                                                <input type='text' className='form-control'
                                                                    style={{ border: (getIsTestNameDuplicated || submitted) && (!inputField.TestName || inputField.TestName == "") ? '1px solid red' : '' }} name={"TestName_" + index}
                                                                    id={"TestName_" + index} placeholder="Enter Test Name"
                                                                    value={inputField.TestName}
                                                                    onChange={event => handleInputChange(index, event)}
                                                                    onBlur={() => CheckDuplicate(index)}
                                                                    autoComplete="off" maxLength="100" />
                                                            </td>
                                                            <td>
                                                                <Reactselect className="basic-single"
                                                                    name={"ddlTestTask" + index}
                                                                    id={"ddlTestTask" + index}
                                                                    isDisabled={false}
                                                                    isLoading={false}
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    isMulti
                                                                    menuPosition="fixed"
                                                                    onChange={event => handleChangeTask(event, index)}
                                                                    options={getTaskOption}
                                                                    value={inputField.TaskIDforEdit}
                                                                    styles={submitted && inputField.TaskIDforEdit == "" ? styles : styles1}
                                                                >
                                                                </Reactselect>
                                                            </td>
                                                            <td>
                                                                <Reactselect className="basic-single"
                                                                    name={"ddlTestType" + index}
                                                                    id={"ddlTestType" + index}
                                                                    isDisabled={false}
                                                                    isLoading={false}
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    isMulti
                                                                    menuPosition="fixed"
                                                                    onChange={event => handleChangeType(event, index)}
                                                                    options={getTypeOption}
                                                                    value={inputField.TypeIDforEdit}
                                                                    styles={submitted && (inputField.TypeIDforEdit == "" || inputField.TypeIDforEdit == undefined) ? styles : styles1}
                                                                >
                                                                </Reactselect>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    {inputFields.length === (index + 1) && <button type="button"
                                                                        className="btn btn-xs btn-success" title="Add Test Name" onClick={event => AddTestName(index)}>
                                                                        <i className="fa fa-plus"></i>
                                                                    </button>}{inputFields.length !== 1 &&
                                                                        <button type="button" style={{ marginLeft: '5px' }}
                                                                            className="btn btn-xs btn-danger" title="Remove Test Name" onClick={event => { RemoveTestName(index) }}>
                                                                            <i className="fa fa-trash"></i>
                                                                        </button>}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </Fragment>
                                                ))}
                                            </tbody>
                                        </table>
                                    </form>
                                    <div className="col-sm-12" style={{ marginTop: '10px' }}>
                                        <div className="buttons-preview" align="right">
                                            <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                                <i className="fa fa-arrow-circle-left"></i>
                                                &nbsp;Back
                                            </span>
                                            <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                                <i className="fa fa-times"></i>
                                                &nbsp;Reset
                                            </span>
                                            <button type="button" className="btn btn-success" onClick={InsertTestName}>
                                                <i className="fa fa-check right"></i>&nbsp;
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {loader}
            </div>
        </>
    )
}
export default TestNameRegister;