import {
    WORKMANSHIPITEM_LOADING,
    RETRIEVE_WORKMANSHIPITEM,
    DELETE_WORKMANSHIPITEM,
} from "./types";
import WorkmanshipItem from "../services/Master/WorkmanshipItem";

const WorkmanshipLoading = (isStatus) => ({
    type: WORKMANSHIPITEM_LOADING,
    payload: isStatus,
});

export const deleteWorkmanshipIT = (workmanshipItemID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_WORKMANSHIPITEM,
            payload: workmanshipItemID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrieveworkmanshipitem = (workmanshipItemID) => async (dispatch) => {
    try {
        dispatch(WorkmanshipLoading(true));
        const res = await WorkmanshipItem.GetWorkmanshipItemList(workmanshipItemID);
        dispatch({
            type: RETRIEVE_WORKMANSHIPITEM,
            payload: res.data,
        });
        dispatch(WorkmanshipLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(WorkmanshipLoading(false));
    }
};