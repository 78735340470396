import {
    PACKAGEIMAGE_LOADING,
    RETRIEVE_PACKAGEIMAGE,
    DELETE_PACKAGEIMAGE,
} from "./types";

import Packageimage from "../services/Master/PackageImage";

const PackageImageLoading = (isStatus) => ({
    type: PACKAGEIMAGE_LOADING,
    payload: isStatus,
});

export const deletePackageImage = (PackageImageID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_PACKAGEIMAGE,
            payload: PackageImageID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrievePackageImage = (Operation, PackageImageID) => async (dispatch) => {
    try {
        dispatch(PackageImageLoading(true));
        const res = await Packageimage.GetPackageImageList(Operation, PackageImageID);
        dispatch({
            type: RETRIEVE_PACKAGEIMAGE,
            payload: res.data,
        });

        dispatch(PackageImageLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(PackageImageLoading(false));
    }
};


