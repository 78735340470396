import React, { useState, useEffect, useMemo,Fragment } from "react";
import companyService from "../../../services/Master/Companyservice";
import supplierService from "../../../services/Master/SupplierService";
import { TableHeader, Pagination, Search, PageCount } from "../../Datatable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import SweetAlertPopup from '../../Common/SweetAlertPopup';
import { CompanyActionId } from "../../Common/PageActionNumber";
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import Lightbox from "react-image-lightbox"
import { Redirect } from 'react-router-dom';
//import { useSelector } from "react-redux";
import Nodify from "../../Common/ReactNotification";
import { SupplierModuleId } from "../../Common/ModuleID";
import SearchRetain from "../../Common/SearchRetain";
import { useDispatch, useSelector } from "react-redux";
import { retrieveSupplier } from "../../../actions/supplier"
import CommonService from '../../../services/Common/CommonService';

const SupplierList = (props) => { // Selecters
    //const [comments, setComments] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [getID, setID] = useState(0);
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    const [isOpen, setIsOpen] = useState(false)
    const [ListTile, setListTile] = useState("List");
    //const { user: currentUser } = useSelector((state) => state.auth);
    const [getPlaceholder] = useState("Supplier / Reference / Currency");

    const dispatch = useDispatch();

    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.common.MenuList.item3);
    if (menulist !== undefined) { activeMenu = menulist.filter(i => i.menuName === "Supplier"); }
    // End Enable Menu

    //reducer
    const reducerState = useSelector((state) => state);
    const { user: currentUser } = reducerState.auth;
    const comments = reducerState.supplier.supplierList;
    let isLoadingSupplier = reducerState.supplier.isLoadingSupplier;



    useEffect(() => {
        let params = {
            companyid: 0, tabletype: 4
        }
        dispatch(retrieveSupplier(params));
        // showLoader();
        // setTimeout(() => {
        //     companyService.getAllCompanyBuyerSupList(SupplierModuleId).then((response) => {
        //         if (response.data) {
        //             setComments(response.data);
        const state1 = props.location.state
        if (state1 !== undefined && state1 != null) {
            let Msg;
            let Type = 'success'; //default, info, warning
            let Title = 'Success!';
            if (state1.message === 'Edit') {
                Msg = 'Supplier information updated successfully.';
                // Type = 'default';
            } else if (state1.message === 'Add') {
                Msg = 'Supplier information added successfully.';
            } else {
                Msg = 'Error occured';
                Type = 'warning';
                Title = 'Error!';
            }
            window.history.replaceState(null, '')
            Nodify(Title, Type, Msg);
        }
        var page = getPlaceholder;
        var setConstraint = SearchRetain(page);
        if (setConstraint !== undefined) {
            setCurrentPage(setConstraint.CurrentPage);
            setITEMS_PER_PAGE(setConstraint.PageItems);
        }
        //         } else {
        //             //hideLoader();
        //         }
        //     }).catch(() => {
        //         //hideLoader();
        //     }).finally(() => {
        //         hideLoader();
        //     });
        // }, 500)
    }, [dispatch]);

    useEffect(() => {
        if (!isLoadingSupplier) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingSupplier]);


    const headers = [
        {
            name: "#",
            field: "",
            sortable: false
        },
        {
            name: "Logo",
            field: "",
            sortable: false
        },
        {
            name: "Supplier Name",
            field: "name",
            sortable: true
        },
        {
            name: "Reference Name",
            field: "referenceName",
            sortable: true
        },
        {
            name: "Currency",
            field: "currencyType",
            sortable: true
        },
        {
            name: "Status",
            field: "statusName",
            sortable: true
        },
        {
            name: "Action ",
            field: "",
            sortable: false,
            display: activeMenu[0].isEdit === 0 && activeMenu[0].isDelete === 0 && activeMenu[0].isView === 0 ? 'none' : ''
        },
    ];

    const commentsData = useMemo(() => {
        let computedComments = comments;
        if (search) {
            computedComments = computedComments.filter(comment => comment.name.toLowerCase().includes(search.toLowerCase()) || comment.referenceName.toLowerCase().includes(search.toLowerCase()) || comment.currencyType.toLowerCase().includes(search.toLowerCase()) || comment.currencyName.toLowerCase().includes(search.toLowerCase()));
        }
        setTotalItems(computedComments.length);
        // Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort((a, b) => {
                const valueA = a[sorting.field];
                const valueB = b[sorting.field];

                // Check if values are numeric
                const isNumericA = !isNaN(valueA);
                const isNumericB = !isNaN(valueB);

                if (isNumericA && isNumericB) {
                    // If both values are numeric, compare as numbers
                    return reversed * (parseFloat(valueA) - parseFloat(valueB));
                } else {
                    // If one or both values are non-numeric, compare as strings
                    return reversed * valueA.toString().localeCompare(valueB.toString());
                }
            });

        }
        // Current Page slice
        let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
        if (records.length === 0 && currentPage != 1) {
            setCurrentPage(currentPage - 1);
        }
        return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
    }, [
        comments,
        currentPage,
        search,
        sorting,
        ITEMS_PER_PAGE
    ]);

    // Actions
    const pageRedirect = (companyParams, redirect, page) => {
       
        localStorage.setItem('Pagination', JSON.stringify({ CurrentPage: currentPage, Page: page, PageItems: ITEMS_PER_PAGE }));
        if (redirect === 'Add') {
            props.history.push('/SupplierRegister', { params: { action: "Add" } });
        }
        else if (redirect === 'Edit') {
            companyParams.action = "Edit";
            props.history.push(`/SupplierRegister`, { params: companyParams });
        }
        else if (redirect === 'View') {
            companyParams.action = "View";
            props.history.push(`/SupplierRegister`, { params: companyParams });
        }
        else {
            let Params = {
                Operation: 3,
                Id: companyParams.companyBuyerSupID, //compBuySupID delete
            };
            CommonService.CheckMappedItem(Params).then(res => {
                if (res.data.outputResult === "-2") {
                    let Title = "Warning!";
                    let message = "This Supplier is already mapped.";
                    let type = "warning";
                    Nodify(Title, type, message);
                }
                else {
                    setID({ showPopupDelete: true, Params: companyParams });
                }
            });
            // setID({ showPopupDelete: true, Params: companyParams });
        }
    };

    const ListType = () => {
        setListTile("Tile");
    }

    const TileListType = () => {
        setListTile("List");
    }

    if (!currentUser) {
        return <Redirect to="/login" />;
    }

    const Delcallback = (value) => {
        // if (value === true) {
        //     companyService.getAllCompanyBuyerSupList(SupplierModuleId).then((response) => {
        //         if (response.data) {
        //             setComments(response.data);
        //         }
        //     });
        // }
        setID({ showPopupDelete: false, Params: [] });
    }

    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className="row">
                    <div className="col-xs-12 col-md-12">
                        <div className="widget">
                            <div className="widget-header ">
                                <span className="widget-caption">Supplier Information</span>
                                {
                                    ListTile === "List" && <span title='Tile'
                                        onClick={ListType}>
                                        <i className="menu-icon fa fa-th font_size"></i>
                                    </span>
                                }
                                {
                                    ListTile === "Tile" && <span title='List'
                                        onClick={TileListType}>
                                        <i className="menu-icon fa fa-list font_size"></i>
                                    </span>
                                } </div>
                            <div> {
                                isOpen && (
                                    <Lightbox
                                        mainSrc={isOpen}
                                        alt="bg image"

                                        onCloseRequest={
                                            () => setIsOpen(false)
                                        } />
                                )
                            } </div>
                            <div className="widget-body">
                                <div className="dataTables_wrapper form-inline no-footer">
                                    <div className="table-toolbar pull-left">
                                        <Search onSearch={
                                            value => {
                                                setSearch(value);
                                                setCurrentPage(1);
                                            }
                                        }
                                            placeholder={getPlaceholder} props={props}
                                        />

                                    </div>
                                    <div className="table-toolbar pull-right" style={{ display: activeMenu[0].isAdd !== undefined && activeMenu[0].isAdd === 1 ? '' : 'none' }}>
                                        &nbsp;
                                        <span className="btn btn-primary" onClick={() => pageRedirect('', 'Add', getPlaceholder)} title="Add Supplier">
                                            <i className="fa fa-plus"></i>&nbsp;Add
                                        </span>
                                    </div>
                                    {

                                        ListTile === "List" &&
                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                            <TableHeader headers={headers}
                                                onSorting={
                                                    (field, order) => setSorting({ field, order })
                                                } />
                                            <tbody>
                                                {
                                                    commentsData.map((comment, index1)=> (
                                                      <Fragment key={index1}>
                                                        <tr>
                                                            <td style={{width:'25px'}}>
                                                                    {
                                                                        index1 + 1
                                                                    }
                                                            </td>
                                                            <td className="trwidth_manual">
                                                                {
                                                                    < img className="width_manual"
                                                                        src={comment.logoPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.logoPath}
                                                                        alt="Logo"

                                                                        onClick={
                                                                            () => setIsOpen(comment.logoPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.logoPath)
                                                                        }
                                                                    />
                                                                }</td>

                                                            <td>{
                                                                comment.name
                                                            }</td>
                                                            <td>{
                                                                comment.referenceName
                                                            }</td>
                                                            <td>{comment.currencyName + ' - ' + comment.currencyType}</td>
                                                            <td> {
                                                                comment.activeStatus === 1 &&
                                                                // <i className="stat-icon glyphicon glyphicon-ok green"></i>
                                                                <span className="green">Active</span>
                                                            }
                                                                {
                                                                    comment.activeStatus === 0 &&
                                                                    // <i className="stat-icon glyphicon glyphicon-remove red"></i>
                                                                    <span className="red">Inactive</span>
                                                                } </td>
                                                            {/* <td>{
                                                    comment.createddate
                                                }</td> */}
                                                            <td style={{
                                                                display: activeMenu[0].isEdit !== undefined && activeMenu[0].isEdit === 0 &&
                                                                    activeMenu[0].isDelete !== undefined && activeMenu[0].isDelete === 0 &&
                                                                    activeMenu[0].isView !== undefined && activeMenu[0].isView === 0 ? 'none' : ''
                                                            }}>


                                                                <span style={{ display: activeMenu[0].isEdit !== undefined && activeMenu[0].isEdit === 1 ? '' : 'none' }} title='Edit Supplier' onClick={() => pageRedirect(comment, 'Edit', getPlaceholder)}
                                                                    className="btn btn-success btn-xs edit">
                                                                    {/* <i className="fa fa-eye"></i> */}
                                                                    <i className="fa fa-edit "></i>
                                                                </span>
                                                                &nbsp;
                                                                <span style={{ display: activeMenu[0].isView !== undefined && activeMenu[0].isView === 1 ? '' : 'none' }} title='View Supplier' onClick={() => pageRedirect(comment, 'View', getPlaceholder)}
                                                                    className="btn btn-white btn-xs eye">
                                                                    {/* <i className="fa fa-eye"></i> */}
                                                                    <i className="fa fa-eye "></i>
                                                                </span>
                                                                &nbsp;
                                                                <span style={{ display: activeMenu[0].isDelete !== undefined && activeMenu[0].isDelete === 1 ? '' : 'none' }} title='Delete Supplier' onClick={() => pageRedirect(comment, '', getPlaceholder)}
                                                                    className="btn btn-danger btn-xs delete">
                                                                    <i className="fa fa-trash-o"></i>
                                                                </span>
                                                            </td>

                                                            {/* {

                                                    isOpen && (
    <Lightbox
        mainSrc ={comment.companyLogoPath===""?"assets/img/uploadimage.jpg" : window.$APIBaseURL + 'Images' + comment.companyLogoPath}
        //mainSrc="assets/img/uploadimage.jpg"
        alt="bg image"

            onCloseRequest={
                () => setIsOpen(false)
            }/>

)
} */}


                                                        </tr>
                                                        </Fragment>
                                                    ))
                                                }

                                                {
                                                    totalItems === 0 ?
                                                        <tr>
                                                            <td colSpan="6" className='norecordfound'><span>No Records Found</span></td>
                                                        </tr> : ''
                                                }


                                            </tbody>
                                        </table>

                                    }
                                    {
                                        ListTile === "Tile" &&
                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">

                                            <tbody> {
                                                commentsData.map(comment => (
                                                    <div className="col-sm-4">
                                                        <div className="client_tumbline">


                                                            <img className="client_logo" src={comment.logoPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.logoPath} alt="Logo" onClick={
                                                                () => setIsOpen(comment.logoPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.logoPath)
                                                            } />

                                                            <div className="ai_button">
                                                                {comment.activeStatus === 1 && <img title="Active" alt='checked.png' src="assets/img/checked.png" />}
                                                                {comment.activeStatus === 0 && <img title="Inactive" alt='cancel.png' src="assets/img/cancel.png" />}
                                                            </div>

                                                            <table className="table_thum">
                                                                <tr className="table_rwo">
                                                                    <td className="bold_text width_10">Supplier&nbsp;:&nbsp;</td>
                                                                    <td className="bold_text width_20 cut_textInStyle" title={comment.name}>
                                                                        {
                                                                            comment.name
                                                                        } </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Reference&nbsp;:&nbsp;</td>
                                                                    <td className="cut_textInStyle" Title={comment.referenceName}>
                                                                        {
                                                                            comment.referenceName
                                                                        }</td>
                                                                </tr>
                                                            </table>


                                                            <span title='Edit Supplier' style={{ display: activeMenu[0].isDelete !== undefined && activeMenu[0].isEdit === 1 ? '' : 'none' }} onClick={() => pageRedirect(comment, 'Edit', getPlaceholder)}
                                                                className="btn btn-success btn-xs edit">
                                                                {/* <i className="fa fa-eye"></i> */}
                                                                <i className="fa fa-edit "></i>
                                                            </span>
                                                            &nbsp;
                                                            <span title='View Supplier' style={{ display: activeMenu[0].isView !== undefined && activeMenu[0].isView === 1 ? '' : 'none' }} onClick={() => pageRedirect(comment, 'View', getPlaceholder)}
                                                                className="btn btn-white btn-xs eye">
                                                                {/* <i className="fa fa-eye"></i> */}
                                                                <i className="fa fa-eye "></i>
                                                            </span>
                                                            &nbsp;
                                                            <span title='Delete Supplier' style={{ display: activeMenu[0].isDelete !== undefined && activeMenu[0].isDelete === 1 ? '' : 'none' }} onClick={() => pageRedirect(comment, '', getPlaceholder)}
                                                                className="btn btn-danger btn-xs delete">
                                                                <i className="fa fa-trash-o"></i>
                                                            </span>


                                                        </div>
                                                    </div>


                                                ))
                                            } </tbody>
                                        </table>


                                    }
                                    <div className='norecordfound'>


                                        {
                                            totalItems === 0 && ListTile === "Tile" && <span>No Records Found</span>
                                        }



                                    </div>
                                    <PageCount onPageCount={
                                        value => {
                                            setITEMS_PER_PAGE(value);
                                            setCurrentPage(1);
                                        }
                                    } />
                                    <Pagination total={totalItems}
                                        itemsPerPage={
                                            parseInt(ITEMS_PER_PAGE)
                                        }
                                        currentPage={currentPage}
                                        onPageChange={
                                            page => setCurrentPage(page)
                                        } />

                                </div>
                            </div>
                            {
                                getID.showPopupDelete ? <SweetAlertPopup data={
                                    getID.Params
                                }
                                    deleteCallback={Delcallback}
                                    showpopup={true}
                                    pageActionId={CompanyActionId}
                                    Msg={"Supplier information deleted successfully."} /> : null
                            } </div>
                    </div>
                </div>
            </div>
            {loader} </>
    )
}

export default SupplierList