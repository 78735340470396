import axios from "axios";
import authHeader from "../auth-header";

class CategoryService {
    LoadGroupTYpe() {
        return axios.get(window.$APIBaseURL + "Master/LoadGroupTypeList", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadCategoryTypeList() {
        return axios.get(window.$APIBaseURL + "Master/LoadCategoryTypeList", {
            headers: {
                authorization: authHeader()
            }
        });
    }

     LoadUOMTypeList() {
        return axios.get(window.$APIBaseURL + "Master/LoadUOMTypeList", {
            headers: {
                authorization: authHeader()
            }
        });
    }

      GetCategoryList(CategoryID) {
        return axios.get(window.$APIBaseURL + "Master/GetCategoryList?CategoryID="+CategoryID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateCategoryGrid(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateCategoryGrid',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }


    InsertUpdateCategory(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateCategory',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

}
export default new CategoryService();