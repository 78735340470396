import React, { Fragment, useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Reactselect from 'react-select';
import DatePicker from "react-datepicker";
import axios from "axios";
import $ from "jquery";
import Lightbox from "react-image-lightbox"

const Summary = () => {
 return (
  <Fragment>
   <div className="page-body">
    <div className="row">
     <div className="col-md-12">
      <div className="form-group">
       <label htmlFor="">1. MASS PER UNIT AREA (GSM) - (STANDARD)</label>
       <br />
       <table className="table table-striped table-bordered table-hover dataTable no-footer">
        <thead>
         <tr>
          <th>#</th>
          <th>Required GSM</th>
          <th>Found GSM</th>
          <th>Change in</th>
          <th>Aollowed Tol</th>
          <th>Result</th>
         </tr>
        </thead>
        <tbody>
         <tr>
          <td>XXXX</td>
          <td>XXXX</td>
          <td>XXXX</td>
          <td>XXXX</td>
          <td>XXXX</td>
          <td>XXXX</td>
         </tr>

        </tbody>
       </table>
      </div>
     </div>
     <br />
     <br />
     <div className="col-md-12">
      <div className="form-group">
       <label htmlFor="">2. DIMENSIONAL STABILTY TO WASHING - (WASH COUNT) - (STANDARD)</label>
       <br />
       <table className="table table-striped table-bordered table-hover dataTable no-footer">
        <thead>
         <tr>
          <th>#</th>
          <th>Details</th>
          <th>Before Wash</th>
          <th>After Wash (Final)</th>
          <th>Change In</th>
          <th>Allowed Tol</th>
          <th>Result</th>
         </tr>
        </thead>
        <tbody>
         <tr>
          <td>XXXX</td>
          <td>XXXX</td>
          <td>XXXX</td>
          <td>XXXX</td>
          <td>XXXX</td>
          <td>XXXX</td>
          <td>XXXX</td>
         </tr>

        </tbody>
       </table>
      </div>
     </div>
    </div>
    <br />
    <div className="row">
     <div className="col-lg-12">
      <div className="form-group">
       <label htmlFor="">Lab Comments</label>
       <br />
       <textarea name="" id="" cols="80" rows="3"></textarea>
      </div>
     </div>
     <div className="col-lg-12">
      <div className="form-group">
       <label htmlFor="">Remarks</label>
       <br />
       <textarea name="" id="" cols="215" rows="3"></textarea>
      </div>
     </div>
    </div>
   </div>
  </Fragment>
 )
}

export default Summary