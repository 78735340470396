
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetTestingMultExFactorySKUList } from './../../../../actions/tna';
import Tnaservice from '../../../../services/TNA/TNAService';
import Nodify from "../../../Common/ReactNotification";

const NewTesting = ({ props, QCfinalInspectionList, QCFinalInspectionCallback, Taskname ,SubTaskInfo}) => {
    const [selectall, setSelectAll] = useState(false);
    const [multiCloseDatas, setMultiCloseDatas] = useState([]);
    const dispatch = useDispatch();
    const reducerState = useSelector(state => state);
    const TNAAddFinalInspectionList = reducerState.tna.tnaAddTestinginspection || [];
    const menulist = useSelector(state => state.auth.UserMenuList.item3);
    const menulistSub = useSelector(state => state.auth.UserMenuList.item5);
    

    // Set up menu and sub-menu info
    const startStopInfo = React.useMemo(() => {
        if (menulist && menulistSub.length > 0) {
            const activeMenu = menulist.find(menu => menu.menuName === 'TNA');
            if (activeMenu) {
                const activeMenuSub = menulistSub.filter(sub => sub.mainMenuID === activeMenu.menuID);
                return activeMenuSub.find(sub => sub.menuName === 'TNAstartstop') || null;
            }
        }
        return null;
    }, [menulist, menulistSub]);

    // useMemo(() => {
    //      if(TNAAddFinalInspectionList && TNAAddFinalInspectionList.length > 0)
    //     {
    //       TNAAddFinalInspectionList.map((r, i) => {
    //             SubTaskInfo.map(c => {
    //                 if (r.subTaskInfoId === c.ChildId) {
    //                     TNAAddFinalInspectionList[i].IsDepandency = c.IsDepandency;
    //                     TNAAddFinalInspectionList[i].Depandency = c.Depandency;
    //                     TNAAddFinalInspectionList[i].dependencyStartstop = c.intDependency;
    //                     TNAAddFinalInspectionList[i].dependencyStartstop = c.intDependency;
    //                 }
    //             })
    //         })
    //         debugger;
    //       let computedComments = TNAAddFinalInspectionList;
    //       computedComments.forEach(element => {
    //         if (QCfinalInspectionList.PurchaseOrderSkuid.toString() === element.purchaseOrderSkuID.toString() && QCfinalInspectionList.TnaId === element.tnaId) {
    //             element.isSelected = 1;
    //         }
    //     });
    //     setMultiCloseDatas(computedComments)
    //     QCFinalInspectionCallback(computedComments)
    //     return computedComments;
    // }

    // }, [TNAAddFinalInspectionList ,SubTaskInfo]);
    useMemo(() => {
        if (TNAAddFinalInspectionList?.length > 0) {
            TNAAddFinalInspectionList.forEach((r, i) => {
                SubTaskInfo?.forEach(c => {
                    if (r.subTaskInfoId === c?.ChildId) {
                        TNAAddFinalInspectionList[i] = {
                            ...TNAAddFinalInspectionList[i],
                            IsDepandency: c.IsDepandency,
                            Depandency: c.Depandency,
                            dependencyStartstop: c.intDependency
                        };
                    }
                });
            });
            
            let computedComments = [...TNAAddFinalInspectionList]; // create a new array to avoid mutating state directly
            computedComments.forEach(element => {
                if (
                    QCfinalInspectionList.PurchaseOrderSkuid?.toString() === element.purchaseOrderSkuID?.toString() &&
                    QCfinalInspectionList.TnaId === element.tnaId
                ) {
                    element.isSelected = 1;
                }
            });
            
            setMultiCloseDatas(computedComments);
            QCFinalInspectionCallback(computedComments);
            
            return computedComments;
        }
    }, [TNAAddFinalInspectionList, SubTaskInfo, QCfinalInspectionList]);

    useEffect(() => {
        dispatch(GetTestingMultExFactorySKUList(QCfinalInspectionList));
    }, [dispatch, QCfinalInspectionList]);

    // useEffect(() => {
    //     setMultiCloseDatas(TNAAddFinalInspectionList);
    // }, [TNAAddFinalInspectionList]);

    const selectAllExFactory = useCallback(async (event) => {
        const isChecked = event.target.checked;

        // Extract unique SKUIDs and StyleIDs
        const uniqueSkuid = [...new Set(TNAAddFinalInspectionList.map(option => option.refInspecSKUID))];
        const uniqueStyleID = [...new Set(TNAAddFinalInspectionList.map(option => option.styleID))];

        // Join the unique IDs into comma-separated strings
        const params = {
            StyleIDListMulti: uniqueStyleID.join(','),
            CheckProgramSkuIDList: uniqueSkuid.join(',')
        };


        try {
            const res = await Tnaservice.GetCheckProgramSKUList(params);

            if (res.data.outputResult === "1") {
                Nodify('Warning!', 'warning', 'There is no program task for some particular SKU');
                return;
            }

            const isSelected = isChecked ? 1 : 0;

            // // Update the list with the selected status and dependency check
            // const updatedList = TNAAddFinalInspectionList.map(element => ({
            //     ...element,
            //     isSelected: QCfinalInspectionList.selectedDependency.trim() !== '' && isSelected === 1 ? (element.dependencyStartstop === 1 ? 1 : 0)
            //         : isSelected
            // }));
            // Update the list with the selected status and dependency check
            const updatedList = TNAAddFinalInspectionList.map(element => ({
                ...element,
                isSelected: QCfinalInspectionList.selectedDependency !== null && QCfinalInspectionList.selectedDependency.trim() !== '' && isSelected === 1
                    ? (element.dependencyStartstop === 1 ? 1 : 0)
                    : isSelected
            }));

            setSelectAll(isSelected);
            setMultiCloseDatas(updatedList);
            QCFinalInspectionCallback(updatedList);

        } catch (error) {
            console.error('Error in selectAllExFactory:', error);
            Nodify('Error!', 'error', 'An unexpected error occurred.');
        }
    }, [TNAAddFinalInspectionList, QCFinalInspectionCallback]);



    const onSelectExFactoryRecords = useCallback(async (event, index, item) => {
        const isChecked = event.target.checked;

        const params = {
            StyleIDListMulti: item.styleID.toString(),
            CheckProgramSkuIDList: item.refInspecSKUID.toString()
        };

        try {
            const res = await Tnaservice.GetCheckProgramSKUList(params);

            if (res.data.outputResult === "1") {
                Nodify('Warning!', 'warning', 'There is no program task for the particular SKU');
                return;
            }

            const updatedList = [...multiCloseDatas];
            updatedList[index].isSelected = isChecked ? 1 : 0;
            const isSelectAll = !updatedList.some(d => d.isSelected === 0);

            setSelectAll(isSelectAll ? 1 : 0);
            setMultiCloseDatas(updatedList);
            QCFinalInspectionCallback(updatedList);

        } catch (error) {
            console.error('Error in onSelectExFactoryRecords:', error);
            Nodify('Error!', 'error', 'An unexpected error occurred.');
        }
    }, [multiCloseDatas, QCFinalInspectionCallback]);

    const getSelectedDependencyMap = useCallback((selectedDependency) => {
        // Assuming the logic for checking dependencies is commented out
        // If there's any dependency logic to add, place it here
        return true;
    }, []);

    return (
        <div className="inspection" style={{ height: "70vh", overflowX: "scroll", overflowY: "scroll", width: "100%" }}>
            <table className="table table-bordered">
                <thead>
                    <tr style={{ whiteSpace: "nowrap" }}>
                        <th>Sl No</th>
                        {Taskname === 'Strike off Test' && <th>Strike off Code</th>}
                        {Taskname === 'Strike off Test' && <th>Strike off Name</th>}
                        {Taskname === 'Strike off Test' && <th>Image</th>}
                        <th>Style Name</th>
                        {Taskname === 'Strike off Test' ? <th>TNAID</th> : <th>SKU ID</th>}
                        {Taskname === 'Strike off Test' && <th>SubID</th>}
                        <th>Style No</th>
                        <th>SKU Name</th>
                        <th>PO/ID No</th>
                        <th>PO No</th>
                        {(Taskname === 'Strike off Test' || Taskname === "Trims Test") ? '' : <th>Order Qty</th>}
                        <th>Task Holder / Follower</th>
                        <th>
                            <label style={{ marginTop: '8px' }}>
                                <input type="checkbox"
                                    name="IsSelected"
                                    onChange={selectAllExFactory}
                                    checked={selectall === 1}
                                    className="colored-blue clearOpacity" />
                                <span className="text"></span>
                            </label>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {multiCloseDatas.length > 0 ? multiCloseDatas.map((item, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            {Taskname === 'Strike off Test' && <td>{item.styleStrikeOffCode}</td>}
                            {Taskname === 'Strike off Test' && <td>{item.styleStikeOffName}</td>}
                            {Taskname === 'Strike off Test' && <td>
                                <img src={`${window.$APIBaseURL}Images/Style/Strikeoff/${item.strikeoffLogoPath}`} height="50px" width="50px" alt="Strikeoff" />
                            </td>}
                            {Taskname === 'Trims Test' && <td>
                                <img src={`${window.$APIBaseURL}Images/Style/Trim/${item.trimLogoPath}`} height="50px" width="50px" alt="Trim" />
                            </td>}
                            <td>{item.styleName}</td>
                            {Taskname === 'Strike off Test' ? <td>{item.tnaId}</td> : <td>{item.skuID}</td>}
                            {Taskname === 'Strike off Test' && <td>{item.subId}</td>}
                            <td>{item.styleNo}</td>
                            <td>{item.skuName}</td>
                            <td>{item.idPoNo}</td>
                            <td>{item.poNo || '-'}</td>
                            {(Taskname === 'Strike off Test' || Taskname === "Trims Test") ? '' : <td>{item.orderQuantity}</td>}
                            <td className="cut_textInTNA" title={item.taskOwnerNameList}>{item.taskOwnerNameList}</td>

                            {props.location.pathname === '/TNAEdit' ?
                                // <td className="Actionbutton">
                                //     <div style={{ width: "80px" }}>
                                //         {
                                //             // (item.dependencyStartstop === 1 || (!QCfinalInspectionList.selectedDependency) || getSelectedDependencyMap(QCfinalInspectionList.selectedDependency) === true || item.isSelected === 1
                                //             (QCfinalInspectionList.selectedDependency !== '' && item.dependencyStartstop === 1
                                //             ) ?
                                //                 <label style={{ marginTop: '8px' }}>
                                //                     <input type="checkbox" name="IsSelected"
                                //                         //   disabled={item.balanceQuantity === "0" ? true : false}
                                //                         onChange={event => (onSelectExFactoryRecords(event, index, item))}
                                //                         checked={item.isSelected === 1 ? true : false}
                                //                         className="colored-blue clearOpacity" />
                                //                     <span className="text"></span>
                                //                 </label>
                                //                 :
                                //                 QCfinalInspectionList.selectedDependency === '' ?
                                //                     <label style={{ marginTop: '8px' }}>
                                //                         <input type="checkbox" name="IsSelected"
                                //                             //   disabled={item.balanceQuantity === "0" ? true : false}
                                //                             onChange={event => (onSelectExFactoryRecords(event, index, item))}
                                //                             checked={item.isSelected === 1 ? true : false}
                                //                             className="colored-blue clearOpacity" />
                                //                         <span className="text"></span>
                                //                     </label>
                                //                     : ''

                                //         }  &nbsp;&nbsp;&nbsp; {


                                //             // StartStopInfo && StartStopInfo.isAdd !== 0 &&
                                //             // inputField.SelectedDependency && getSelectedDependencyMap(inputField.SelectedDependency) === false && childTask.StatusID !== 3 &&
                                //             item.Depandency !== '' && QCfinalInspectionList.selectedDependency !== '' &&
                                //             <button type="button" className={(item.dependencyStartstop === 1) ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Dependency)"
                                //             // onClick={() => OpenDependentStartPopup(index, subIndex, childIndex, item.dependencyStartstop, props.location.state.params.tnaid, inputField.TaskID, childTask.ChildId, inputField.IsSingleTask)}
                                //             >
                                //                 <i className={item.dependencyStartstop === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>

                                //             </button>
                                //         }

                                //     </div>
                                // </td>

                                <td className="Actionbutton fixed-column">
                                                                                                                                                                    {


                                                                                                                                                                        // (item.dependencyStartstop === 1 || (!inputField.SelectedDependency) || getSelectedDependencyMap(inputField.SelectedDependency) === true || item.isSelected === 1
                                                                                                                                                                        //  ) &&
                                                                                                                                                                        (item.Depandency !== '' && item.dependencyStartstop === 1) ?
                                                                                                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                                                                                                <input type="checkbox" name="IsSelected"
                                                                                                                                                                                    onChange={event => (onSelectExFactoryRecords(event, index,item))}
                                                                                                                                                                                    checked={item.isSelected === 1 ? true : false}
                                                                                                                                                                                    className="colored-blue h clearOpacity" />
                                                                                                                                                                                <span className="text"></span>
                                                                                                                                                                            </label>
                                                                                                                                                                            :
                                                                                                                                                                            item.Depandency === '' ?
                                                                                                                                                                                <label style={{ marginTop: '8px' }}>
                                                                                                                                                                                    <input type="checkbox" name="IsSelected"
                                                                                                                                                                                        onChange={event => (onSelectExFactoryRecords(event, index,item))}
                                                                                                                                                                                        checked={item.isSelected === 1 ? true : false}
                                                                                                                                                                                        className="colored-blue hd clearOpacity" />
                                                                                                                                                                                    <span className="text"></span>
                                                                                                                                                                                </label> : ''
                                                                                                                                                                    }&nbsp;&nbsp;&nbsp;

                                                                                                                                                                    {
                                                                                                                                                                        // StartStopInfo && StartStopInfo.isAdd !== 0 &&
                                                                                                                                                                        // inputField.SelectedDependency && getSelectedDependencyMap(inputField.SelectedDependency) === false && childTask.StatusID !== 3 &&
                                                                                                                                                                        item.Depandency !== '' &&
                                                                                                                                                                        <button type="button" className={(item.dependencyStartstop === 1) ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Dependency)"
                                                                                                                                                                        // onClick={() => OpenDependentStartPopup(index, subIndex, childIndex, item.dependencyStartstop, props.location.state.params.tnaid, inputField.TaskID, childTask.ChildId, inputField.IsSingleTask)}
                                                                                                                                                                        >
                                                                                                                                                                            <i className={item.dependencyStartstop === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>

                                                                                                                                                                        </button>
                                                                                                                                                                    }

                                                                                                                                                                </td>
                                
                                :
                                <td className="Actionbutton">
                                    <label style={{ marginTop: '8px' }}>
                                        <input type="checkbox"
                                            name="IsSelected"
                                            onChange={event => onSelectExFactoryRecords(event, index, item)}
                                            checked={item.isSelected === 1}
                                            className="colored-blue clearOpacity" />
                                        <span className="text"></span>
                                    </label>
                                </td>}






                        </tr>
                    )) : (
                        <tr>
                            <td colSpan="11" className='norecordfound'><span>No Records Found</span></td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default NewTesting;
