import axios from "axios";
import authHeader from "../auth-header";

class PurchaseOrderService {
    GetPurchaseOrderList(PurchaseOrderID, IsProduction) {
        return axios.get(window.$APIBaseURL + "Style/GetPurchaseOrderList?PurchaseOrderID=" + PurchaseOrderID + "&IsProduction=" + IsProduction, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadPOStyleList(POStyle) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'PurchaseOrder/LoadPOStyleList',
            data: POStyle,
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdatePurchaseOrderDetails(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'PurchaseOrder/InsertUpdatePurchaseOrderDetails',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetSelectedPurchaseOrder(PurchaseOrderID) {
        return axios.get(window.$APIBaseURL + "PurchaseOrder/GetSelectedPurchaseOrder?PurchaseOrderID=" + PurchaseOrderID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadSupplierList() {
        return axios(window.$APIBaseURL + "PurchaseOrder/LoadSupplierList", {
            method: 'post',
            headers: {
                authorization: authHeader()
            }
        });
    }

    CheckPOMappedItem(Params) {
        return axios.get(window.$APIBaseURL + "PurchaseOrder/CheckPOAlreadyMappedorNot?Operation=" + Params.Operation + "&PurOrderStyleDetailID=" + Params.PurOrderStyleDetailID + "&PurchaseOrderSkuID=" + Params.PurchaseOrderSkuID + "&SKUID=" + Params.SKUID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

}
export default new PurchaseOrderService();