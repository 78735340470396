import React, { useState, useEffect, Fragment } from "react";
import 'react-notifications-component/dist/theme.css';
import ReactNotification from 'react-notifications-component';
import { useDispatch, useSelector } from "react-redux";
import columnInformationService from "../../../../../services/Master/ColumnInformationService";
import SelectCombobox from 'react-select';
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import Nodify from "../../../../Common/ReactNotification";
import SearchRetain from "../../../../Common/SearchRetain";

const EditColumnInformation = (props) => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [submitted, setSubmitted] = useState(false);
    const [getModuleID, setModuleID] = useState({ ModuleID: 0 });
    const [getColumnInformation, setColumnInformation] = useState([]);
    const [ExistingList, setExistingList] = useState([]);
    const [ButtonName, setButtonName] = useState("Update");
    const [HeaderName, setHeaderName] = useState("Edit");
    const [buttonLoader, setButtonLoader] = useState(false);
    const { user: currentUser } = useSelector((state) => state.auth);
    const [getModuleList, setModuleList] = useState([]);
    const dispatch = useDispatch();


    useEffect(() => {
        showLoader();
        const fetchData = async () => {
            const res = await columnInformationService.getColumnInformationList(2, props.location.state.params.moduleID);
            let columnValue = [];
            if (res.data.outputColumnInformationshowList) {
                res.data.outputColumnInformationList.map((x, index) => {
                    columnValue.push({
                        Id: x.columnInformationID,
                        TableName: x.tableName,
                        ColumnName: x.columnName,
                        DisplayName: x.displayName,
                        ModuleName: x.moduleName,
                        ModuleID: x.moduleID,
                    })
                })
                setModuleID({ ModuleID: res.data.outputColumnInformationshowList[0].moduleID })
                setColumnInformation(columnValue)
            }

            await columnInformationService.loadModuleInformationList()
                .then((response) => {
                    if (response.data) {
                        var uniqueimagesMSD = [...new Map(response.data.map(item =>
                            [item['label'], item])).values()];
                        setModuleList(uniqueimagesMSD);
                        hideLoader();
                    }
                })
                .catch((error) => {
                    console.error("Error loading column Information list:", error);
                    hideLoader();
                });

            await columnInformationService.getColumnInformationList(1, 0).then((response) => {
                if (response.data) {
                    setExistingList(response.data.outputColumnInformationshowList);
                }
            });
        }
        fetchData();
    }, [props]);



    const handleRemoveFields = index => {
        const values = [...getColumnInformation];
        values.splice(index, 1);
        setColumnInformation(values);
    };

    const handleAddFields = (index) => {
        const values = [...getColumnInformation];

        const moduleIDToAdd = values.length > 0 ? values[0].ModuleID : 0;

        const newField = {
            Id: 0,
            ModuleID: moduleIDToAdd,
            TableName: '',
            ColumnName: '',
            DisplayName: '',
        };

        if (values[0].ModuleID !== 0 && values[index].TableName.trim() !== '' && values[index].ColumnName.trim() !== '' && values[index].DisplayName.trim() !== '') {

            values.push(newField);
            setColumnInformation(values);
            setSubmitted(false);
        }
        else {
            Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
            setSubmitted(true);
        }
    };

    const handleDrpDownChange = (selectedOption) => {
       ;

        if (!selectedOption) {
            setModuleID({ ModuleID: 0 });
            return;
        }

        const newModuleID = selectedOption.value;

        if (!CheckDuplicate(newModuleID)) {
            Nodify('Warning!', 'warning', 'This Module Name is already exists.');
            setModuleID({ ModuleID: props.location.state.params.moduleID });

            return;
        } else {
            setModuleID({ ModuleID: newModuleID });
        }
    };


    const CheckDuplicate = (newModuleID) => {
       ;
        if (ExistingList) {
            for (var i = 0; i < ExistingList.length; i++) {

                if (ExistingList[i].moduleID === newModuleID) {
                    return false;
                }
            }
            return true;
        };
    }

    const handleInputChange = (index, event) => {
       
        const values = [...getColumnInformation];
        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }

        if (event.target.name === "TableName") {
            values[index].TableName = inputText;
        }
        else if (event.target.name === "ColumnName") {
            values[index].ColumnName = inputText;
        }
        else if (event.target.name === "DisplayName") {
            values[index].DisplayName = inputText;
        }

        setColumnInformation(values);
    };

    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
        return false;
    }

    const SaveColumnInformationList = (e) => {
       
        const values = [...getColumnInformation];
        setButtonLoader(true);
        e.preventDefault();

        let Isvalid = false;
        for (var j = 0; j < values.length; j++) {
            if (values[j].ModuleID === 0 || values[j].TableName === '' || values[j].ColumnName === '' || values[j].DisplayName === '') {
                Isvalid = true
            }
        }
        if (!Isvalid) {
            setSubmitted(false);
            let InputCommonMaster = { ModuleID: values[0].ModuleID, Createdby: currentUser.employeeinformationID, MasterInformation: getColumnInformation };
            columnInformationService.insertUpdateColumnInformationGrid(InputCommonMaster).then(res => {
                var page = "Remove";
                SearchRetain(page);
                if (res.data.outputResult === "1") {
                    PageRedirect('Edit');
                } else if (res.data.outputResult === "0") {
                    setButtonLoader(false);
                    ValidationPopup("Error Occured!");
                }
            });
        } else {
            setButtonLoader(false);
            setSubmitted(true);
            ValidationPopup("Please fill all mandatory(*) fields.");
        }

    }

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/ColumnInformationList",
            state: {
                message: Func
            }
        });
    }
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red', innerHeight: '100px', outerHeight: '100px' }),
    }


    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/ColumnInformationList')
        } else {
            window.location.reload();
        }
    }

    return (
        <div className="page-body">
            <ReactNotification />
            {/* <ReactNotification />
            
            {
                View === undefined ? ( */}
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">{HeaderName} Column Information</h3>
                            <div className="buttons-preview" align="right">
                                <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                    <i className="fa fa-arrow-circle-left"></i>
                                    &nbsp;Back
                                </span>
                                <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                    <i className="fa fa-times"></i>
                                    &nbsp;Reset
                                </span>
                                <button type="button" className="btn btn-success" disabled={buttonLoader}
                                    onClick={SaveColumnInformationList}
                                >
                                    <i className="fa fa-check right"></i>&nbsp;
                                    {ButtonName}
                                </button>
                            </div>
                        </div>
                        {/* <div className="widget-body">
                            <div id="registration-form">
                                <Form>

                                    <div className="row">

                                        <div className="form-group col-sm-3">
                                            <label
                                            // htmlFor="ModuleID"
                                            >
                                                Module Name
                                                <span className="text-danger">*</span>
                                            </label>
                                            <span className="input-icon icon-right">
                                                <Select id={
                                                    "ModuleID"
                                                }
                                                    name="ModuleID"
                                                    className="form-select"
                                                    onChange={
                                                        event => handleChange(event)
                                                    }
                                                    value={
                                                        getColumnInformation.ModuleID
                                                    }
                                                    style={
                                                        {
                                                            border: submitted && getColumnInformation.ModuleID === 0 ? '1px solid red' : ''
                                                        }
                                                    }>
                                                    <option value="0">- Select Module Name -</option>
                                                    {
                                                        getModuleList.map(item => (
                                                            <option key={
                                                                item.id
                                                            }
                                                                value={
                                                                    item.id
                                                                }>
                                                                {
                                                                    item.name
                                                                } </option>
                                                        ))
                                                    } </Select>
                                            </span>

                                        </div>
                                        <div className="col-sm-3 divder_style">
                                            <div className="form-group">
                                                <label >

                                                    Table Name<span className="text-danger">*</span>
                                                </label>
                                                <span className="input-icon icon-right">
                                                    <input type="text"
                                                        className="form-control"
                                                        id={"TableName"}
                                                        name="TableName"
                                                        placeholder="Enter Table Name"
                                                        maxLength="50"
                                                        value={getColumnInformation.TableName}
                                                        onChange={handleChange}

                                                        style={{ border: submitted && !getColumnInformation.TableName ? '1px solid red' : '' }}
                                                        autoFocus
                                                    />
                                                </span>

                                            </div>
                                        </div>
                                        <div className="col-sm-3 divder_style">
                                            <div className="form-group">
                                                <label >
                                                    Column Name<span className="text-danger">*</span>
                                                </label>
                                                <span className="input-icon icon-right">
                                                    <input type="text"
                                                        className="form-control"
                                                        id={"ColumnName"}
                                                        name="ColumnName"
                                                        placeholder="Enter Column Name"
                                                        maxLength="50"
                                                        onChange={handleChange}
                                                        value={getColumnInformation.ColumnName}
                                                        style={{ border: submitted && !getColumnInformation.ColumnName ? '1px solid red' : '' }}
                                                        autoFocus
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-sm-3 divder_style">
                                            <div className="form-group">
                                                <label >
                                                    Display Name<span className="text-danger">*</span>
                                                </label>
                                                <span className="input-icon icon-right">
                                                    <input type="text"
                                                        className="form-control"
                                                        id={"DisplayName"}
                                                        name="DisplayName"
                                                        placeholder="Enter Display Name"
                                                        maxLength="50"
                                                        onChange={handleChange}
                                                        value={getColumnInformation.DisplayName}
                                                        style={{ border: submitted && !getColumnInformation.DisplayName ? '1px solid red' : '' }}
                                                        autoFocus
                                                    />
                                                </span>
                                            </div>
                                        </div>


                                        <div className="clearfix"></div>
                                        <div className="col-sm-12">
                                            <div className="buttons-preview" align="right">
                                                <span className="btn btn-primary"
                                                    onClick={
                                                        () => ResetOperation("Back")
                                                    }>
                                                    <i className="fa fa-arrow-circle-left"></i>
                                                    &nbsp;Back</span>
                                                <span className="btn btn-danger"
                                                    onClick={
                                                        () => ResetOperation()
                                                    }>
                                                    <i className="fa fa-times"></i>
                                                    &nbsp;Reset</span>
                                                <button type="submit"
                                                    onClick={saveColumnInformation}
                                                    className="btn btn-success"
                                                    disabled={buttonLoader}
                                                >

                                                    <i className="fa fa-check right"></i>
                                                    &nbsp;{
                                                        ButtonName
                                                    }</button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div> */}
                        <div className="widget-body">

                            <div className="row row_left10">

                                <div className="col-lg-3">
                                    <label>Module Name<span className="text-danger">*</span></label>
                                    <SelectCombobox
                                        className="basic-single"
                                        name="ModuleID"
                                        id="ModuleID"
                                        isDisabled={false}
                                        isLoading={false}
                                        isSearchable={true}
                                        isClearable={true} // Enable clearable option
                                        placeholder="Select Module Name"
                                        styles={submitted && getModuleID.ModuleID === 0 ? styles : ''}
                                        options={getModuleList}
                                        onChange={handleDrpDownChange}

                                        value={getModuleList.find(option => option.value === getModuleID.ModuleID) || null}

                                    />

                                </div>
                            </div>
                            <div id="registration-form">
                                <form>
                                    {
                                        getColumnInformation.map((inputField, index) => (
                                            <Fragment key={`${inputField}~${index}`}>
                                                <div className="row row_left10 mt-10">

                                                    <div className="col-sm-3 divder_style">
                                                        <div className="form-group">
                                                            {index === 0 && <label >

                                                                Table Name<span className="text-danger">*</span>
                                                            </label>}
                                                            <span className="input-icon icon-right">
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"TableName_" + index}
                                                                    name="TableName"
                                                                    placeholder="Enter Table Name"
                                                                    maxLength="50"
                                                                    value={inputField.TableName}
                                                                    onChange={event => handleInputChange(index, event)}
                                                                    style={{ border: submitted && !inputField.TableName ? '1px solid red' : '' }}
                                                                    autoFocus
                                                                />
                                                            </span>

                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3 divder_style">
                                                        <div className="form-group">
                                                            {index === 0 && <label >
                                                                Column Name<span className="text-danger">*</span>
                                                            </label>}
                                                            <span className="input-icon icon-right">
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"ColumnName" + index}
                                                                    name="ColumnName"
                                                                    placeholder="Enter Column Name"
                                                                    maxLength="50"
                                                                    value={inputField.ColumnName}
                                                                    onChange={event => handleInputChange(index, event)}
                                                                    style={{ border: submitted && !inputField.ColumnName ? '1px solid red' : '' }}
                                                                    autoFocus
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3 divder_style">
                                                        <div className="form-group">
                                                            {index === 0 && <label >
                                                                Display Name<span className="text-danger">*</span>
                                                            </label>}
                                                            <span className="input-icon icon-right">
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"DisplayName" + index}
                                                                    name="DisplayName"
                                                                    placeholder="Enter Display Name"
                                                                    maxLength="50"
                                                                    value={inputField.DisplayName}
                                                                    onChange={event => handleInputChange(index, event)}
                                                                    style={{ border: submitted && !inputField.DisplayName ? '1px solid red' : '' }}
                                                                    autoFocus
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3 paddingstatic">
                                                        {
                                                            getColumnInformation.length !== 1 && (
                                                                <div className="col-lg-1_own col-xs-12">
                                                                    <button type="button" className="btn btn-danger"
                                                                        // style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none', marginTop: '25px' }}
                                                                        style={{ marginTop: index === 0 ? '25px' : '11px' }}
                                                                        title="Delete Report Module Category" onClick={() => handleRemoveFields(index)}
                                                                    >
                                                                        <i className="fa fa-trash-o"></i>
                                                                    </button>
                                                                </div>
                                                            )}
                                                        {
                                                            getColumnInformation.length === (index + 1) && (
                                                                <div className="col-lg-1_own col-xs-12">
                                                                    <button type="button" className="btn btn-success" style={{ marginTop: index === 0 ? '25px' : '11px' }} title="Add ReportModuleCategory"
                                                                        onClick={() => handleAddFields(index)}
                                                                    >
                                                                        <i className="fa fa-plus"></i>
                                                                    </button>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </Fragment>
                                        ))
                                    }
                                    <div className="col-sm-12">
                                        <div className="buttons-preview" align="right">
                                            <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                                <i className="fa fa-arrow-circle-left"></i>
                                                &nbsp;Back
                                            </span>
                                            <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                                <i className="fa fa-times"></i>
                                                &nbsp;Reset
                                            </span>
                                            <button type="button" className="btn btn-success" disabled={buttonLoader}
                                                onClick={SaveColumnInformationList}
                                            >
                                                <i className="fa fa-check right"></i>&nbsp;
                                                {ButtonName}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {loader}
        </div>
    );
};

export default EditColumnInformation;

