import React, { useState, useEffect, Fragment } from "react";
import ReactNotification from 'react-notifications-component';
import Nodify from "../Common/ReactNotification"
import SearchRetain from "../Common/SearchRetain";
import Reactselect from 'react-select';
import Packageimage from "../../services/Master/PackageImage";
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";


function AddPackageimageQuick({ packageImagecallback }) {
    const [buttonLoader, setButtonLoader] = useState(false);
    const [inputfeild, setinputfeild] = useState([{ PackageImageID: 0, PackingImageName: '', IsSkuimageID: 0, IsSkuimage: 'Ts' }]);
    const [submitted, setSubmitted] = useState(false);
    const [styleskuimage, setstyleskuimage] = useState([]);
    const [getExisting, setExisting] = useState([]);
    const { user: currentUser } = useSelector((state) => state.auth);


    const Values = [...inputfeild];
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),
    }

    useEffect(() => {

        Packageimage.GetPackageImageList(2, 0).then((res) => {
            if (res.data) {
                setstyleskuimage(res.data.commonDropdown)
            }
        })
        Packageimage.GetPackageImageList(1, 0).then((res) => {
            if (res.data) {
                setExisting(res.data.inputPackageimageList1)
            }
        })
    }, [])

    function handleAddFields(index) {
        if (Values[index].PackingImageName !== '') {
            Values.push({
                PackageImageID: 0, PackingImageName: '', IsSkuimageID: 0, IsSkuimage: ''
            });
            setSubmitted(false);
            setinputfeild(Values);
        } else {
            setSubmitted(true);
            Nodify('Warning!', 'warning', 'Please fill the mandantory(*) fields');
        }
    }

    function handleRemoveFields(index) {
        Values.splice(index, 1);
        setinputfeild(Values);
    }

    function CheckDuplicate(index) {
        let ExistingValue = !!getExisting.find(x => x.packingImageName === Values[index].PackingImageName)
        if (ExistingValue) {
            Values[index].PackingImageName = '';
            Values[index].IsSkuimage = '';
            Values[index].IsSkuimageID = 0;
            setinputfeild(Values);
            Nodify('Warning!', 'warning', 'These details are already exists, please enter differently');
            return false;
        }
        for (var j = 0; j < (Values.length); j++) {
            if (j !== index) {
                if (Values[j].PackingImageName === Values[index].PackingImageName) {
                    Values[index].PackingImageName = '';
                    Values[index].IsSkuimage = '';
                    Values[index].IsSkuimageID = 0;
                    setinputfeild(Values);
                    Nodify('Warning!', 'warning', 'These details are already exists, please enter differently');
                    return false;
                }

            }
        }
    }

    function handleInputChange(index, event, FieldName) {

        let inputText = '';
        if (FieldName === "Packingimage") {
            if (event.target.value.trim() !== '') {
                inputText = event.target.value;
            }
            Values[index].PackingImageName = inputText;
        } else {
            if (event.target.checked === true) {
                Values[index].IsSkuimageID = 1
            } else {
                Values[index].IsSkuimageID = 0
            }
        }
        setinputfeild(Values);
    }

    function SavePackageImage() {
        setButtonLoader(true);
        let isvalid = true;
        if (Values[0].PackingImageName === '') {
            isvalid = false;
            setSubmitted(true);
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Please Atleast one Packing Image details');
        }
        else {
            for (var j = 0; j < (Values.length); j++) {
                if (Values[j].PackingImageName === '') {
                    isvalid = false;
                    setSubmitted(true);
                    setButtonLoader(false);
                    Nodify('Warning!', 'warning', 'Please fill the mandantory(*) fields');
                    return false;
                }
            }
        }
        if (isvalid) {
            let Packageimageinformation = []
            let inputmaster = { Packageimageinformation: Values, CreatedBy: currentUser.employeeinformationID }
            Packageimage.InsertUpdatePackageImageGrd(inputmaster).then((res) => {
                if (res.data.outputResult === "1") {
                    Nodify("Success!", "success", "Size added successfully.");
                    packageImagecallback(true)
                }
            })
        }
    }

    return (
        <Fragment>
            <form>
                <div >
                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                        <thead>
                            <tr>
                                <th style={{ width: "17%" }}>Packing Image Name<span className="text-danger">*</span></th>
                                <th style={{ width: "17%" }}>Is Style sku Image<span className="text-danger">*</span></th>
                                <th style={{ width: "10%" }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                inputfeild.map((InputValue, index) => (
                                    <Fragment >
                                        <tr>
                                            <td>
                                                <input type="text"
                                                    className="form-control"
                                                    id={"PackingImageName"}
                                                    name="PackingImageName"
                                                    placeholder="Enter PackingImageName"
                                                    maxLength="50"
                                                    autoComplete="off"
                                                    value={InputValue.PackingImageName}
                                                    onChange={event => handleInputChange(index, event, "Packingimage")}
                                                    onBlur={() => CheckDuplicate(index)}
                                                    style={{ border: submitted && InputValue.PackingImageName === "" ? '1px solid red' : '' }}
                                                    autoFocus
                                                />
                                            </td>

                                            <td>
                                                <label style={{ marginTop: '8px' }}>
                                                    <input type="checkbox" name="IsSelected"
                                                        onChange={event => handleInputChange(index, event, "Isstylesku")}
                                                        value={InputValue.IsSkuimageID === 1 ? true : false}
                                                        checked={InputValue.IsSkuimageID === 1 ? true : false}
                                                        className="clearOpacity textdesign" />
                                                    <span className="text"> </span>
                                                </label>
                                            </td>
                                            <td>
                                                <button type="button" className="btn btn-xs btn-success" title="Add packing Image"
                                                    onClick={() => handleAddFields(index)}
                                                >
                                                    <i className="fa fa-plus"></i>
                                                </button>
                                                &nbsp;
                                                {inputfeild.length !== 1 && (<button type="button"
                                                    className="btn btn-xs btn-danger" title="Delete packing Image"
                                                    onClick={() => handleRemoveFields(index)}
                                                >
                                                    <i className="fa fa-trash-o"></i>
                                                </button>
                                                )}
                                            </td>
                                        </tr>

                                    </Fragment>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </form>
            <br />
            <div className="row">
                <div className="col-sm-12">
                    <button type="button" className="btn btn-success pull-right" disabled={buttonLoader}
                        onClick={SavePackageImage}>

                        <i className="fa fa-check right"></i>
                        &nbsp;Save</button>
                </div>
            </div>

        </Fragment>
    )
}

export default AddPackageimageQuick;