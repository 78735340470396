import axios from "axios";
import authHeader from "../auth-header";

class CompanyService {

    getAllCompanyBuyerSupList(CompbuysubId, CompanyModuleId) {
        return axios.get(window.$APIBaseURL + "Master/GetCompanyBuyerSupplierList?CompanyBuyerSupID=" + CompbuysubId + '&Tabletype=' + CompanyModuleId, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    loadGenderList() {
        return axios.get(window.$APIBaseURL + "Master/LoadGenderList", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    loadCityList() {
        return axios.get(window.$APIBaseURL + "Master/LoadCityList", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    loadAddressTypeList() {
        return axios.get(window.$APIBaseURL + "Master/LoadAddressTypeList", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    loadStateList() {
        return axios.get(window.$APIBaseURL + "Master/LoadStateList?CityID=0", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    loadCountryList() {
        return axios.get(window.$APIBaseURL + "Master/LoadCountryList?CityID=0", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    loadYearList() {
        return axios.get(window.$APIBaseURL + "Master/LoadYearList", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    loadCompanyList() {
        return axios.get(window.$APIBaseURL + "Master/LoadCompanyList", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    BindAddressList(CompanyID) {
        return axios.get(window.$APIBaseURL + "Master/GetCompanyBuyerSupplierAddressList?CompanyBuyerSupID=" + CompanyID, { // data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    BindEmployeeList(CompanyID) {
        return axios.get(window.$APIBaseURL + "Master/GetCompanyBuyerSupplierEmployeeList?CompanyBuyerSupID=" + CompanyID, { // data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    BindHolidayList(CompanyID, Year) {
        return axios.get(window.$APIBaseURL + "Master/GetCompanyBuyerSupplierHolidayList?CompanyBuyerSupID=" + CompanyID + " &Year=" + Year, { // data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateCompany = async (Params) => {
        return await axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateCompany',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    CheckEmailIdExist(Operation, EmailId) {
        return axios.get(window.$APIBaseURL + "Master/CheckEmailIdExist?Operation=" + Operation + " &CompanyHolidayID=0&EmailId=" + EmailId, { // data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
}
export default new CompanyService()
