import {
    QADETAILREPORT_LOADING,
    LOAD_QADETAILREPORT_LIST,
    LOAD_QAFINALINSPECTION_LIST,
    LOAD_QAREFINALINSPECTION_LIST,
    QA_UPDATE_COMMENT_REPORT_VIEW_ALL_CHECK_BOX,
    QA_UPDATE_COMMENT_REPORT_VIEW_CHECK_BOX
} from "./types";
import InspectionQCService from "../services/InspectionQC/InspectionQCService";

const QADetailsLoadig = (isStatus) => ({
    type: QADETAILREPORT_LOADING,
    payload: isStatus
})

export const LoadQADetailsreport = (Params) => async (dispatch) => {
    try {
        dispatch(QADetailsLoadig(true))
        const res = await InspectionQCService.GetQADetailsReportList(Params.IsProduction, Params.TaskDetailsName, Params.ExfacStartDate, Params.ExfacEndDate, Params.TNAStartDate, Params.TNAEndDate,);
        dispatch({
            type: LOAD_QADETAILREPORT_LIST,
            payload: res.data,
        });
        dispatch(QADetailsLoadig(false))
    } catch (err) {
        console.log(err);
        dispatch(QADetailsLoadig(false));
    }
    dispatch(QADetailsLoadig(false));
}

export const LoaQAInspectionList = (Params) => async (dispatch) => {
    try {
        dispatch(QADetailsLoadig(true))
        const res = await InspectionQCService.GetQAInspectionList(Params.IsProduction, Params.Taskname, Params.PoStartDate, Params.PoEndDate, Params.ApStartDate, Params.ApEndDate, Params.ApprovedStatus);
        dispatch({
            type: LOAD_QAFINALINSPECTION_LIST,
            payload: res.data,
        });
        dispatch(QADetailsLoadig(false))
    } catch (err) {
        console.log(err);
        dispatch(QADetailsLoadig(false));
    }
    dispatch(QADetailsLoadig(false));
}

export const LoadReQAInspectionList = (Params) => async (dispatch) => {
    try {
       ;
        dispatch(QADetailsLoadig(true))
        const res = await InspectionQCService.GetReQAInspectionList(Params.IsProduction, Params.Taskname, Params.PoStartDate, Params.PoEndDate, Params.ApStartDate, Params.ApEndDate, Params.ApprovedStatus);
        dispatch({
            type: LOAD_QAREFINALINSPECTION_LIST,
            payload: res.data,
        });
        dispatch(QADetailsLoadig(false))
    } catch (err) {
        console.log(err);
        dispatch(QADetailsLoadig(false));
    }
    dispatch(QADetailsLoadig(false));
}

export const UpdateCommentsreportViewAllCheckBox = (isChecked) => async (dispatch) => {
    dispatch(QADetailsLoadig(true));
    dispatch({
        type: QA_UPDATE_COMMENT_REPORT_VIEW_ALL_CHECK_BOX,
        payload: isChecked,
    });
    dispatch(QADetailsLoadig(false));

};
export const UpdateCommentsreportViewCheckBox = (params) => async (dispatch) => {
    dispatch(QADetailsLoadig(true));
    dispatch({
        type: QA_UPDATE_COMMENT_REPORT_VIEW_CHECK_BOX,
        payload: params,
    });

    dispatch(QADetailsLoadig(false));

};
