import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useState, useMemo, useEffect } from "react";
import Reactselect from 'react-select';
import DatePicker from "react-datepicker";
import { Modal, Button } from "react-bootstrap";
import ReportService from "../../../../services/Report/ReportService";
import TNAService from "../../../../services/TNA/TNAService";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import moment from "moment";
import Nodify from "../../../Common/ReactNotification";
import { retrieveColorDepth } from "../../../../actions/colordepth";
import UploadImage from "../../../Common/UploadImage"
import { input } from "react-validation/build/input";
import Lightbox from "react-image-lightbox";
import $ from "jquery";
import axios from "axios";
import FinalinspecUpload from "../../../Common/FinalinspecUpload";
const GarmanetorderDetails = ({ props, Mainfieldscallback, Startdatevalidation, Qualitycallback, IsValidationCallback, rerenderdep, ExternalFiles,
    ExLabDetails, TestNameDeatils, IssaveType
}) => {

    const [selectall, setselectall] = useState(false);
    const [getnewimagevalue, setnewimagevalue] = useState([]);
    const [getCurrimageindex, setCurrimageindex] = useState(0);
    const [getExFactoryList, setExFactoryList] = useState([])
    const [getMainInputField, setMainInputField] = useState({
        SupplierID: 0, SupplierIDForEdit: "", SupplierReferenceNo: '', FabricLotNo: '', LotWeight: ''
        , ColorDepth: 0, ColorDepthForEdit: "", WashingType: 0, DryerType: 0, NoofWashes: '0', TestStartDate: '', TestEndDate: '',
        ReportdateandNo: '', Agencyname: '', StyleLogoPath: '', CategoryType: ''
    })
    const [getFiles, setFiles] = useState([]);
    const [Buttonview, SetButtonview] = useState("Hide");
    const [getMQualityInfolist, setQualityInfolist] = useState([])
    const [isOpenimg, setIsOpenimg] = useState(false);
    const [getImagename, setImagename] = useState();
    const [isOpenimgEX, setIsOpenimgEX] = useState(false);
    const [getImagenameEX, setImagenameEX] = useState();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [submitted, setSubmitted] = useState(false);
    const [MultiCloseDatas, setMultiCloseDatas] = useState([]);
    const [getFinalInspectionPopup, setFinalInspectionPopup] = useState(false);
    const [getTotalValues, setTotalValues] = useState({ PoQty: 0, Prodqty: 0 });
    const [rerenderval, setrerenderval] = useState(0);
    const [getWashtpeDp, setWastTypeDp] = useState([]);
    const [getDryertpeDp, setdryerTypeDp] = useState([]);
    const [Externalvalue, setExternalvalue] = useState([])
    const [ExternalvalueSample, setExternalvalueSample] = useState([])
    const [getColordepthtpeDp, setColordepthTypeDp] = useState([])
    const [ispopup, setispopup] = useState(false);
    const [submittedenddate, setSubmittedenddate] = useState(false);
    const [getLabComments, setLabComments] = useState(
        {
            value: 0,
            label: "",
            Remarks: ""
        }
    );
    const MainInputValues = { ...getMainInputField };
    const dispatch = useDispatch();
    const reducerState = useSelector((state) => state);
    const comments = reducerState.ColorDepthReducer.ColorDepthList.item1;
    useEffect(() => {
        setSubmitted(IsValidationCallback);
    }, [IsValidationCallback === true]);
    useEffect(() => { setSubmittedenddate(Startdatevalidation) }, [Startdatevalidation === true])
    const inpsectionId = JSON.parse(localStorage.getItem("TestingInspectionID"));


    useEffect(() => {
        const Exfaclist = [];
        const TnaPantoneinfoList = [];
        const Washcarelist = [];
        const dryerlist = [];
        const colordeptlist = [];
        showLoader();

        (async () => {
            const { TaskStatus, SkuID, Isproduction, TestingInspectionID, buyerID, brandID, seasonID, type } =
                props.location.state.params;

            // Fetch Testing Multi SKU List when inpsectionId is null
            const fetchTestingMultiSKUList = async () => {
                Mainfieldscallback(MainInputValues);
                const params = {
                    Operation: 7,
                    SkuIDList: SkuID,
                    SkuId: 0,
                    StyleId: 0,
                    BuyerId: 0,
                    BrandId: 0,
                    SeasonId: 0,
                    SupplierId: 0,
                    TNAId: 0,
                    TaskId: 0,
                    MainId: 0,
                    IsProduction: Isproduction,
                    StrikeoffTrimID: 0,
                    SUbtaskID: 0,
                };
                const res = await TNAService.GetTestingMultiSKUList(params);

                if (res.data.getTestingPantoneInfo) {
                    TnaPantoneinfoList.push(
                        ...res.data.getTestingPantoneInfo.map((x) => ({
                            TNATestmaininfoID: 0,
                            BuyerID: x.buyerID,
                            BrandID: x.brandID,
                            SeasonID: x.seasonID,
                            SkuID: x.skuID,
                            refInspecSKUID: x.skuID,
                            SkuName: x.skuName,
                            StyleID: x.styleID,
                            IsExpanded: false,
                            SubTask: [],
                            isSelected: 1,
                            CategoryName: x.categoryName,
                            CategoryTypeName: x.categoryTypeName,
                            StyleName: x.styleName,
                        }))
                    );
                }
                setQualityInfolist(TnaPantoneinfoList);
                Qualitycallback(TnaPantoneinfoList);
            };

            // Fetch QC Info List
            const fetchQCInfoList = async (inspectionId) => {
                const res = await TNAService.getAllQcInfoList(1, 0, inspectionId);

                if (res.data.testingMainDetails) {
                    debugger
                    res.data.testingMainDetails.forEach((x) => {
                        MainInputValues.ColorDepth = x.colorDepthID;
                        MainInputValues.DryerType = x.dryerTypeID;
                        MainInputValues.FabricLotNo = x.fabricLotNo;
                        MainInputValues.LotWeight = x.lotWeight;
                        MainInputValues.WashingType = x.washingTypeID;
                        MainInputValues.SupplierReferenceNo = x.supplierRefNo;
                        MainInputValues.TestStartDate = new Date(x.testStartDate);
                        MainInputValues.TestEndDate = x.testEndDate ? new Date(x.testEndDate) : "";
                        MainInputValues.NoofWashes = x.noofWashesID;
                        MainInputValues.StyleLogoPath = x.externalFilePath;
                        MainInputValues.CategoryType = x.categoryType;
                        MainInputValues.IsSaveType = x.isSaveType
                    });
                    setMainInputField(MainInputValues);
                    Mainfieldscallback(MainInputValues);
                }

                if (res.data.testingMaininfoDetails) {
                    const PantoneList = res.data.testingMaininfoDetails.map((x, i) => {
                        const subTasks = res.data.testingQualityDetails
                            .filter((fin) => fin.mainindex === i)
                            .map((y) => ({
                                TNATestQualityinfoID: y.tnaTestQualityinfoID,
                                TnaExFactoryId: y.subTaskID,
                                TaskID: y.taskID,
                                StyleName: y.styleName,
                                StyleNo: y.styleNo,
                                SkuName: y.skuName,
                                SkuID: y.skuID,
                                PurchaseOrderSkuID: y.purchaseOrderSkuID,
                                PurchaseOrderID: y.purchaseOrderID,
                                EndCustomer: y.endCustomer,
                                IdNo: y.idno,
                                PoNo: y.focusPo,
                                OrderQuantity: y.poQty,
                                offerqty: y.offerQty,
                                IsDeleted: 0,
                                TnaID: y.tnaId,
                            }));

                        return {
                            TNATestmaininfoID: x.tnaTestmaininfoID,
                            BuyerID: buyerID,
                            BrandID: brandID,
                            SeasonID: seasonID,
                            CategoryName: x.catogery,
                            CategoryTypeName: x.catogeryType,
                            StyleName: x.styleName,
                            SkuID: x.skuID,
                            refInspecSKUID: x.skuID,
                            SkuName: x.skuName,
                            StyleID: x.styleID,
                            IsExpanded: false,
                            SubTask: subTasks,
                            isSelected: 1,
                        };
                    });
                    setQualityInfolist(PantoneList);
                    Qualitycallback(PantoneList);
                    hideLoader();
                }
            };

            // Fetch dropdown testing data
            const fetchDropdownTesting = async () => {
                const res = await TNAService.GetDropDownTesting(1);

                if (res.data.testingDropDownListwashtypeList) {
                    res.data.testingDropDownListwashtypeList.forEach((x) => {
                        Washcarelist.push({ value: x.washTypeID, label: x.washTypeName });
                    });
                    setWastTypeDp(Washcarelist);
                }

                res.data.testingDropDownListDryertypeList.forEach((xi) => {
                    dryerlist.push({ value: xi.dryerTypeID, label: xi.dryerTypeName });
                });
                setdryerTypeDp(dryerlist);

                if (res.data.testingDropDownListcolordepthList) {
                    res.data.testingDropDownListcolordepthList.forEach((xy) => {
                        colordeptlist.push({ value: xy.colorDepthID, label: xy.colorDepth });
                    });
                    setColordepthTypeDp(colordeptlist);
                }
                hideLoader();
            };

            // Fetch external test info if type === 2
            const fetchExternalTestInfo = async () => {
                if (props.location.state.params.type === 2 && (inpsectionId === null && (TestingInspectionID === null || TestingInspectionID === undefined))) {
                    const res = await TNAService.LoadTestInfoExternal(1);
                    if (res.data) {
                        const Exterval = res.data.map((x) => ({
                            TestName: x.testName,
                            TestNameID: x.testNameID,
                            Testresult: 0,
                            ischecked: 1,
                        }));
                        setExternalvalue(Exterval);
                        setExternalvalueSample(Exterval);
                        TestNameDeatils(Exterval);
                    }
                }
            };
            const fetchexternaldatas = async (TestinspectionID) => {
                await TNAService.getAllQcInfoList(1, 0, TestinspectionID).then((res) => {
                    if (res.data.testingMainDetails) {
                        debugger
                        const Arrayval = res.data.externalTestInfo.map((x, ind) => ({
                            ID: x.id,
                            TestID: 0,
                            TestName: x.testName,
                            TestNameID: x.testNameID || 0,
                            Testresult: x.testResult,
                            ischecked: 1
                        }))
                        setExternalvalue(Arrayval || []);
                        setExternalvalueSample(Arrayval || [])
                        TestNameDeatils(Arrayval || [])
                        setLabComments({
                            value: parseInt(res.data.testingMainDetails[0].labCommentID),
                            label: "",
                            Remarks: res.data.testingMainDetails[0].externalRemarks,
                        });
                        ExLabDetails({
                            value: parseInt(res.data.testingMainDetails[0].labCommentID),
                            label: "",
                            Remarks: res.data.testingMainDetails[0].externalRemarks,
                        })
                        const ExFiles = res.data.externalTestReportInfo.map((x, ind) => ({
                            ExternalFileupID: x.id,
                            FileName: x.fileName,
                            Filepath: x.filePath,
                            FileType: x.fileType,
                            IsDeleted: 0
                        }))
                        setFiles(ExFiles || [])
                        ExternalFiles(ExFiles || [])
                    }
                    hideLoader();
                });
            }
            // Main logic based on TaskStatus and inpsectionId
            if (TaskStatus === 1) {
                if (inpsectionId === null) {
                    await fetchTestingMultiSKUList();
                } else {
                    await fetchQCInfoList(inpsectionId);
                    await fetchexternaldatas(inpsectionId);
                }
            } else {
                await fetchQCInfoList(TestingInspectionID);
                await fetchexternaldatas(TestingInspectionID);
            }

            await fetchDropdownTesting();

            if (type === 2) {
                await fetchExternalTestInfo();
            }
            resetAllToggles()
        })();
    }, [rerenderval, rerenderdep]);

    const resetAllToggles = () => {
        // Query all toggle buttons and icons
        const btnToggles = document.querySelectorAll(".btn-toggle"); // All buttons with class 'btn-toggle'
        const iconToggles = document.querySelectorAll(".fa"); // All icons with 'fa' class (FontAwesome icons)

        // Loop through each toggle button and reset its aria-expanded attribute to 'false'
        btnToggles.forEach(btnToggle => {
            btnToggle.setAttribute('aria-expanded', 'false');
        });

        // Loop through each icon and reset the minus-square icon to plus-square
        iconToggles.forEach(iconToggle => {
            // Check if the icon has the 'fa-minus-square-o' class, if so, replace it with 'fa-plus-square-o'
            if (iconToggle.classList.contains("fa-minus-square-o")) {
                iconToggle.classList.replace("fa-minus-square-o", "fa-plus-square-o"); // More efficient than replace() method
            }
        });
    };
    function handleClosePopup() {
        setFinalInspectionPopup(false)
    }
    function handleClosePopupEX() {
        setispopup(false)
    }
    function OnSelectFactoryRecordsEX(e, index, item) {

        const values = [...Externalvalue];
        const Exvalues = [...ExternalvalueSample]
        //Exvalues.filter((x => x.TestName === 1))
        if (e.target.checked === true) {
            values.filter(x => x.TestName === item.TestName).map((x) => {
                x.ischecked = 1
            })
            //values[index].ischecked = 1;
        } else {
            values.filter(x => x.TestName === item.TestName).map((x) => {
                x.ischecked = 0
            })
            //  values[index].ischecked = 0;
        }
        setExternalvalue(values);
        TestNameDeatils(values);
    }
    function AddExternalpopForm() {
        setispopup(false)
    }
    const labResult = [{
        value: 1, label: 'Satisfied'
    }, {
        value: 2, label: ' Not Statisfy'
    }]
    const filevals = [...getFiles]
    const AppendFiles = async e => {
        const file = e.target.files[0];
        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword', "application/vnd.ms-excel",
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#FileUpload').val("");
            }
            else {
                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "FinalInspection");
                try {
                    //const valuess = [...getFiles]
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                    // valuess[0].FileName = res.data + "_" + file.name
                    // valuess[0].FilePath = "Images/Finalinspection/ExternalMainInfo/" + res.data + "_" + file.name
                    // valuess[0].FileType = file.type


                    filevals.push({
                        ExternalFileupID: 0,
                        FileName: res.data + "_" + file.name,
                        Filepath: "Images/Finalinspection/ExternalMainInfo/" + res.data + "_" + file.name,
                        FileType: file.type,
                        IsDeleted: 0
                    })
                    setFiles(filevals);
                    ExternalFiles(filevals)
                    $('#FileUpload').val("");
                } catch (ex) {
                    console.log(ex);
                }
            }
        }
    }
    function ViewFileExternal(fileindex, files) {
        setImagenameEX(files);
        setIsOpenimgEX(true);
    }
    const RemoveImages = (index) => {
        const filesvalue = [...getFiles]
        filesvalue.splice(index, 1);
        setFiles(filesvalue);
        ExternalFiles(filesvalue)

    }
    const RemoveFiles = async (e, index) => {
        const filesvalue = [...getFiles]
        filesvalue.splice(index, 1);
        setFiles(filesvalue);
        ExternalFiles(filesvalue)
    }

    const Options = [{
        value: 1, label: 'Pass'
    }, {
        value: 2, label: 'Fail'
    }]
    const CatogeryType = [{
        value: 1, label: 'Top'
    }, {
        value: 2, label: 'Bottom'
    },
    {
        value: 3, label: 'Onesie'
    }, {
        value: 4, label: 'Top (Setpack)'
    }, {
        value: 5, label: 'Bottom (Setpack)'
    }]
    function MainInfoCommonCalculation(val) {
        let poqty = 0;
        let prodqty = 0;
        val.map(x => {
            if (poqty === 0) {
                poqty = parseFloat(x.OrderQuantity);
            }
            else {
                poqty = poqty + parseFloat(x.OrderQuantity);
            }
            if (prodqty === 0 && x.offerqty !== undefined && x.offerqty !== '') {
                prodqty = parseFloat(x.offerqty);
            }
            else if (prodqty !== 0 && x.offerqty !== undefined && x.offerqty !== '') {
                prodqty = parseFloat(prodqty) + parseFloat(x.offerqty);
            }
        })
        setTotalValues({ PoQty: poqty, Prodqty: prodqty })
    }
    const SelectAllExFactory = (event) => {

        let IsSelected = 0;
        if (event.target.checked === true) {
            IsSelected = 1;
            MultiCloseDatas.forEach(element => {
                element.isSelected = 1;
                element.IscheckReportSelect = 2;
                element.offerqty = '';
            })
        }
        else {
            IsSelected = 0;
            MultiCloseDatas.forEach(element => {
                element.isSelected = 0;
                element.IscheckReportSelect = 0;
            });
        }
        setselectall(IsSelected);
    }
    function AddExFactoryForm() {
        let Multiselected = [];
        const Values = [...getMQualityInfolist]
        let isvalid = true;
        isvalid = !!MultiCloseDatas.find(x => x.isSelected === 1)
        let sizeandqtyfordisplay = "";
        let NewArr = [];
        NewArr = Values.concat(MultiCloseDatas.filter(x => x.isSelected === 1))
        NewArr.map((Purchaseorder) => {
            Purchaseorder.IsDeleted = 0;
            if (sizeandqtyfordisplay === '') {
                sizeandqtyfordisplay = Purchaseorder.refInspecSKUID//props.location.state.params.SkuID
            } else {
                sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.refInspecSKUID
            }
        })

        props.location.state.params.SkuID = sizeandqtyfordisplay;
        if (isvalid) {
            Multiselected = Values.concat(MultiCloseDatas.filter(x => x.isSelected === 1))
            setMultiCloseDatas(Multiselected)
            setFinalInspectionPopup(false);
            MainInfoCommonCalculation(Multiselected)
            Qualitycallback(Multiselected)
            setrerenderval(props.location.state.params.multiselecteddatas.filter(x => x.isSelected === 1).length);
        } else {
            Nodify('Warning!', 'warning', 'Please fill atleast one checkbox');
            return false;
        }

    }
    function OpenPopupEx() {
        setispopup(true)
    }

    async function handleToggle(index, Input) {

        //setSubmitted(false);
        showLoader();
        let PantoneSkuList = [];
        const values = [...getMQualityInfolist];
        let btnToggle = document.getElementById("btn-toggle_" + index);
        let iconToggle = document.getElementById("icon-toggle_" + index);
        let ariaExpanded = btnToggle.getAttribute('aria-expanded');
        if (ariaExpanded === 'false') {
            values[index].IsExpanded = true;
            btnToggle.setAttribute('aria-expanded', 'true');
            iconToggle.className = iconToggle.className.replace("fa-plus-square-o", "fa-minus-square-o");
            if (props.location.state.params.TaskStatus === 1) {
                if (inpsectionId === null) {
                    let params = {
                        Operation: props.location.state.params.TaskType === "Strike off Test" ? 3 : props.location.state.params.TaskType === "Trims Test" ? 4 : 2, SkuIDList: props.location.state.params.SkuID, SkuId: Input.SkuID, StyleId: Input.StyleID,
                        BuyerId: Input.BuyerID, BrandId: Input.BrandID, SeasonId: Input.SeasonID, SupplierId: props.location.state.params.supplierID,
                        TaskId: props.location.state.params.quantityinfo.TaskId, IsProduction: props.location.state.params.Isproduction,
                        TNAId: props.location.state.params.details.tnaid, StrikeoffTrimID: 0, SUbtaskID: 0
                    };
                    await TNAService.GetTestingMultiSKUList(params).then((res) => {
                        let arrGradingSizes = props.location.state.params.PurchaseSkuID.split(",").map(id => parseInt(id.trim(), 10));
                        const filteredItems = res.data.getTestingSkuinfo.filter(x => arrGradingSizes.includes(x.purchaseOrderSkuID));
                        if (filteredItems) {
                            filteredItems.map((x, i) => {
                                values[index].SubTask.push({
                                    TNATestQualityinfoID: 0,
                                    TnaExFactoryId: x.tnaExFactoryId,
                                    TaskID: x.taskID,
                                    StyleName: x.styleName,
                                    StyleNo: x.styleNo,
                                    SkuName: x.skuName,
                                    SkuID: x.skuID,
                                    PurchaseOrderSkuID: x.purchaseOrderSkuID,
                                    PurchaseOrderID: x.purchaseOrderID,
                                    EndCustomer: x.endCustomer,
                                    IdNo: x.idNo,
                                    PoNo: x.poNo,
                                    OrderQuantity: x.orderQuantity,
                                    //OrderQuantity: 1,
                                    offerqty: '',
                                    IsDeleted: 0,
                                    TnaID: x.tnaId,

                                })
                            })
                        }
                        setQualityInfolist(values);
                        Qualitycallback(values)
                        MainInfoCommonCalculation(values[index].SubTask);
                        hideLoader();
                    })
                } else {
                    // values[index].IsExpanded = true;
                    // btnToggle.setAttribute('aria-expanded', 'true');
                    // iconToggle.className = iconToggle.className.replace("fa-plus-square-o", "fa-minus-square-o");
                    // hideLoader();
                    await TNAService.getAllQcInfoList(1, 0, inpsectionId).then((res) => {
                        let arrGradingSizes = props.location.state.params.PurchaseSkuID.split(",").map(id => parseInt(id.trim(), 10));
                        const filteredItems = res.data.testingQualityDetails.filter(x => arrGradingSizes.includes(x.purchaseOrderSkuID));
                        filteredItems.filter(fin => fin.mainindex === index).map((y, yindex) => {
                            values[index].SubTask.push({
                                TNATestQualityinfoID: y.tnaTestQualityinfoID,
                                TnaExFactoryId: y.subTaskID,
                                TaskID: y.taskID,
                                StyleName: y.styleName,
                                StyleNo: y.styleNo,
                                SkuName: y.skuName,
                                SkuID: y.skuID,
                                PurchaseOrderSkuID: y.purchaseOrderSkuID,
                                PurchaseOrderID: y.purchaseOrderID,
                                EndCustomer: y.endCustomer,
                                IdNo: y.idno,
                                PoNo: y.focusPo,
                                OrderQuantity: y.poQty,
                                offerqty: y.offerQty,
                                IsDeleted: 0,
                                TnaID: y.tnaId
                            })
                        })
                        var uniqueimagesMSD = [...new Map(values[index].SubTask.map(item =>
                            [item['purchaseOrderSkuID'], item])).values()];
                        values[index].SubTask = uniqueimagesMSD;
                        setQualityInfolist(values);
                        Qualitycallback(values)
                        MainInfoCommonCalculation(values[index].SubTask);
                        hideLoader();
                    })
                }
            } else {
                values[index].SubTask = [];
                setQualityInfolist(values);
                await TNAService.getAllQcInfoList(1, 0, props.location.state.params.TestingInspectionID).then((res) => {
                    res.data.testingQualityDetails.filter(fin => fin.mainindex === index).map((y, yindex) => {
                        values[index].SubTask.push({
                            TNATestQualityinfoID: y.tnaTestQualityinfoID,
                            TnaExFactoryId: y.subTaskID,
                            TaskID: y.taskID,
                            StyleName: y.styleName,
                            StyleNo: y.styleNo,
                            SkuName: y.skuName,
                            SkuID: y.skuID,
                            PurchaseOrderSkuID: y.purchaseOrderSkuID,
                            PurchaseOrderID: y.purchaseOrderID,
                            EndCustomer: y.endCustomer,
                            IdNo: y.idno,
                            PoNo: y.focusPo,
                            OrderQuantity: y.poQty,
                            offerqty: y.offerQty,
                            IsDeleted: 0,
                            TnaID: y.tnaId,

                        })
                    })
                    setQualityInfolist(values);
                    Qualitycallback(values)
                    MainInfoCommonCalculation(values[index].SubTask);
                    hideLoader();
                })
            }
        } else {
            values[index].IsExpanded = false;
            values[index].SubTask = [];
            btnToggle.setAttribute('aria-expanded', 'false');
            iconToggle.className = iconToggle.className.replace("fa-minus-square-o", "fa-plus-square-o");
            hideLoader();
            setQualityInfolist(values);
        }
    }
    const previmage = () => {
        if (getCurrimageindex === 0) {
            setCurrimageindex(0);
        }
        else {
            setCurrimageindex(getCurrimageindex - 1);
        }
    }

    let styles1 = {
        control: styles1 => ({ ...styles1 }),
    }

    let styles2 = {
        control: styles2 => ({ ...styles2, border: '1px solid red' }),
    }
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),
    }
    const OnSelectExFactoryRecords = (event, index, item) => {
        const value = [...MultiCloseDatas];
        if (event.target.checked === true) {
            value[index].isSelected = 1;
            value[index].IscheckReportSelect = 2;
            value[index].offerqty = '';
            props.location.state.params.multiselecteddatas.filter(x => x.purchaseOrderSkuID === item.purchaseOrderSkuID).map((e) => {
                e.isSelected = 1;
            })
        }
        else {
            value[index].isSelected = 0;
            value[index].IscheckReportSelect = 0;
            props.location.state.params.multiselecteddatas.filter(x => x.purchaseOrderSkuID === item.purchaseOrderSkuID).map((e) => {
                e.isSelected = 0;
            })
        }

        let isSelectAll = 0;
        if (value) {
            isSelectAll = !!!value.find(d => d.isSelected === 0);
            isSelectAll = isSelectAll ? 1 : 0;
        }
        setselectall(isSelectAll);
        setMultiCloseDatas(value);
    }
    const CustomInputEndDate = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
                // style={{
                //     border: submitted && props.value === "" ? "1px solid red" : "",
                //     color:
                //         props.value === ""
                //             ? "#bfdea1"
                //             : "#000"

                //     , textTransform: "uppercase"
                // }}
                style={{
                    border: submittedenddate && props.value === "" ? "1px solid red" : "",
                    color:
                        props.value === ""
                            ? "#bfdea1"
                            : "#000"

                    , textTransform: "uppercase"
                }}
            />
        )
    }
    const CustomInput = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
                style={{
                    border: submitted && props.value === "" ? "1px solid red" : "",
                    color:
                        props.value === ""
                            ? "#bfdea1"
                            : "#000"

                    , textTransform: "uppercase"
                }}
            />
        )
    }
    const nextimage = () => {
        if (getCurrimageindex === getnewimagevalue.length - 1) {
            setCurrimageindex(getnewimagevalue.length - 1);
        }
        else {
            setCurrimageindex(getCurrimageindex + 1);
        }
    }
    const ViewFile = (event, filename) => { // event.preventDefault();
        setIsOpenimg(true);
        setImagename(filename);
    }
    const ImageCallback = (value) => {

        const values = { ...getMainInputField }
        let profile = values !== '' ? "/Style/SKU/" : '';
        values.StyleLogoPath = profile + value;
        setMainInputField(values);
        Mainfieldscallback(values);
    }
    function handleRemoveFields() {
        const values = { ...getMainInputField }
        values.StyleLogoPath = '';
        setMainInputField(values);

        Mainfieldscallback(values);
    }
    function handleOfferQty(e, index, subIndex) {
        const values = [...getMQualityInfolist]
        let inputText = ""
        const regexNumber = /^[0-9\b]+$/;
        if (e.target.value.trim() !== '') {
            if (e.target.value.match(regexNumber)) {
                inputText = e.target.value;
            } else {
                inputText = '';
            }
        }
        if (values[index].SubTask[subIndex].OrderQuantity >= parseInt(inputText)) {
            values[index].SubTask[subIndex].offerqty = inputText;
        } else {
            values[index].SubTask[subIndex].offerqty = '';
            //  Nodify('Warning!', 'warning', 'offer qty cannot exceed ' + values[index].SubTask[subIndex].OrderQuantity)
        }
        setQualityInfolist(values);
        MainInfoCommonCalculation(values[index].SubTask);
        Qualitycallback(values)
    }
    function RemoveQuantity(e, index, sub, subIndex) {
        let multiselecteddatas = [];
        const values = [...getMQualityInfolist]
        props.location.state.params.multiselecteddatas.filter(x => parseInt(x.purchaseOrderSkuID) === parseInt(sub.PurchaseOrderSkuID)).map(element => {
            element.isSelected = 0;
        })
        values[index].SubTask[subIndex].IsDeleted = 1;
        values[index].isSelected = 0
        multiselecteddatas = values.filter(x => x.isSelected === 1)
        let sizeandqtyfordisplay = "";
        let NewArr = [];
        props.location.state.params.multiselecteddatas.filter(x => x.isSelected === 1).map((Purchaseorder) => {
            if (sizeandqtyfordisplay === '') {
                sizeandqtyfordisplay = Purchaseorder.refInspecSKUID.toString();
            } else {
                sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.refInspecSKUID
            }
        })
        props.location.state.params.SkuID = sizeandqtyfordisplay;
        values.splice(index, 1);
        setQualityInfolist(values);
        MainInfoCommonCalculation(multiselecteddatas)
        //Qunatitylist(values)
        setrerenderval(props.location.state.params.multiselecteddatas.filter(x => x.isSelected === 1).length);
    }
    function OpenPopup() {
        let Multiselected = [];
        Multiselected = props.location.state.params.multiselecteddatas.filter(x => x.isSelected === 0)
        setMultiCloseDatas(Multiselected)
        setFinalInspectionPopup(true)
    }

    function handleExternalTest(e, index, Name) {

        const values = [...Externalvalue];
        let TempIndex = values.findIndex(x => x.TestName === Name);
        values[TempIndex].Testresult = e.value;
        setExternalvalue(values)
        TestNameDeatils(values);
    }
    const handleInputChange = (event, field) => {
        const values = { ...getMainInputField };
        let inputText = '';
        if (field == "SupplierRefNo") {
            if (event.target.value.trim() !== '') {
                inputText = event.target.value;
            }
            values.SupplierReferenceNo = inputText;
        }
        else if (field == "FabricLotNo") {
            if (event.target.value.trim() !== '') {
                inputText = event.target.value;
            }
            values.FabricLotNo = inputText;
        }
        else if (field == "lotWeight") {
            if (event.target.value.trim() !== '') {
                inputText = event.target.value;
            }
            values.LotWeight = inputText;
        }
        else if (field == "Colordepth") {
            if (event !== null) {
                values.ColorDepth = event.value;
            } else {
                values.ColorDepth = 0;
            }
        }
        else if (field === 'WashType') {
            if (event !== null) {
                values.WashingType = event.value;
            } else {
                values.WashingType = 0;
            }
        }
        else if (field == "DryerType") {
            if (event !== null) {
                values.DryerType = event.value;
            } else {
                values.DryerType = 0;
            }
        }
        else if (field == "Noofwashes") {
            if (event !== null) {
                values.NoofWashes = event.target.value;
            } else {
                values.NoofWashes = '0';
            }
        } else if (field == "TestStartDate") {
            if (new Date(event) >= new Date(values.TestEndDate)) {
                values.TestEndDate = '';
                values.TestStartDate = event;
            } else {
                values.TestStartDate = event;
            }
        }
        else if (field === "TestEndDate") {
            if (new Date(event) >= new Date(values.TestStartDate)) {

                values.TestEndDate = event;
            }
            else {
                values.TestEndDate = '';
                Nodify('Warning!', 'warning', 'End Date Should not less than Start Date.');
                //return false;
            }
        } else if (field === 'AgencyName') {
            if (event.target.value.trim() !== '') {
                inputText = event.target.value;
            }
            values.Agencyname = inputText;
        }

        else if (field === 'ReportdateandNo') {
            if (event.target.value.trim() !== '') {
                inputText = event.target.value;
            }
            values.ReportdateandNo = inputText;
        } else if (field === 'CategoryType') {
            if (event !== null) {
                values.CategoryType = event.value;
            }
            else {
                values.CategoryType = 0;
            }
        }
        setMainInputField(values);
        Mainfieldscallback(values)
    }

    useEffect(() => {
        dispatch(retrieveColorDepth());
    }, [dispatch]);

    const handleLabResultOnchange = (value, fieldName) => {
        let labComments = { ...getLabComments }
        if (value !== null && fieldName === 'labcomment') {
            labComments.label = value.label
            labComments.value = value.value
        }
        else if (fieldName === 'Remarks') {
            labComments.Remarks = value.target.value
        }
        setLabComments(labComments)
        ExLabDetails(labComments);
    };
    return (
        <Fragment>
            <div className="page-body">
                <div className="row">
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-sm-4">
                                <div className='form-group'>
                                    <label htmlFor="txtBuyerSeasonBrand">Buyer-Season-Brand<span className="text-danger">*</span></label>
                                    <span className='input-icon icon-right'>
                                        <input type="text" className='form-control' placeholder="Enter Buyer-Season-Brand" id="txtBuyerSeasonBrand"
                                            name="buyerseasonbrand" autoComplete="off" maxLength="100"
                                            value={props.location.state.params.buyerName + ' - ' + props.location.state.params.seasonName + ' - ' + props.location.state.params.brandName}
                                            disabled />
                                    </span>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className='form-group'>
                                    <label htmlFor="ddlSupplier">Supplier<span className="text-danger">*</span></label>
                                    <span className='input-icon icon-right'>
                                        <input type="text" className='form-control' id="SupplierRefNo"
                                            name="SupplierRefNo" placeholder="Enter Supplier Ref No." autoComplete="off"
                                            // onChange={event => handleMainChange(event, 'CustomerShipmentRefNo')}
                                            value={props.location.state.params.supplierName}
                                            // onChange={event => handleChangeBasicDetails(event, "SupplierRefNo")}
                                            disabled={props.location.state.params.pageType === 'ViewInspection' ? true : false}
                                            maxLength="100" />
                                    </span>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className='form-group'>
                                    <label htmlFor="ddlCustomerShipmentRefNo">Supplier Ref No<span className="text-danger">*</span></label>
                                    <span className='input-icon icon-right'>
                                        <input type="text" className='form-control' id="SupplierRefNo"
                                            name="SupplierRefNo" placeholder="Enter Supplier Ref No." autoComplete="off"
                                            // onChange={event => handleMainChange(event, 'CustomerShipmentRefNo')}
                                            value={getMainInputField.SupplierReferenceNo}
                                            style={{ border: submitted && getMainInputField.SupplierReferenceNo === '' ? '1px solid red' : '' }}
                                            onChange={event => handleInputChange(event, "SupplierRefNo")}
                                            disabled={props.location.state.params.pageType === 'ViewInspection' ? true : false}
                                            maxLength="100" />
                                    </span>
                                </div>
                            </div>
                            {(props.location.state.params.type === 1 || props.location.state.params.type === 2) ? <div className="col-sm-4">
                                <div className='form-group'>
                                    <label htmlFor="ddlFactoryOrLocation">Color Depth {/* / Location */}<span className="text-danger">*</span></label>

                                    <span className='input-icon icon-right'>
                                        <Reactselect className="basic-single" name="factoryorlocation"
                                            id={
                                                "ddlFactoryOrLocation"
                                            }
                                            isDisabled={props.location.state.params.pageType === 'ViewInspection' ? true : false}
                                            isLoading={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            options={getColordepthtpeDp}
                                            styles={submitted && getMainInputField.ColorDepth === 0 ? styles : ''}
                                            value={getColordepthtpeDp.filter(function (option) {
                                                return option.value === getMainInputField.ColorDepth;
                                            })}
                                            onChange={event => handleInputChange(event, 'Colordepth')}
                                        ></Reactselect>
                                    </span>
                                </div>
                            </div> : ''}

                            {props.location.state.params.type === 2 ? <div className="col-sm-4"> <div className='form-group'>
                                <label htmlFor="ddlCustomerShipmentRefNo">Agency Name<span className="text-danger">*</span></label>
                                <span className='input-icon icon-right'>
                                    <input type="text" className='form-control' id="AgencyName"
                                        name="AgencyName" placeholder="Enter Supplier Ref No." autoComplete="off"
                                        // onChange={event => handleMainChange(event, 'CustomerShipmentRefNo')}
                                        value={getMainInputField.Agencyname}
                                        style={{ border: submitted && getMainInputField.Agencyname === '' ? '1px solid red' : '' }}
                                        onChange={event => handleInputChange(event, "AgencyName")}
                                        disabled={props.location.state.params.pageType === 'ViewInspection' ? true : false}
                                        maxLength="100" />
                                </span>
                            </div>
                            </div> : ''}
                            {props.location.state.params.type === 2 ? <div className="row">
                                <div className="col-sm-4">
                                    <div className='form-group'>
                                        <label htmlFor="ddlStyleName">Category Type<span className="text-danger">*</span></label>
                                        <span className='input-icon icon-right'>
                                            <Reactselect className="basic-single" name="factoryorlocation"
                                                id={
                                                    "ddlStyleName"
                                                }
                                                isDisabled={props.location.state.params.pageType === 'ViewInspection' ? true : false}
                                                // isDisabled={true}
                                                isLoading={false}
                                                isClearable={true}
                                                isSearchable={true}
                                                options={CatogeryType}
                                                value={CatogeryType.filter(function (option) {
                                                    return option.value === getMainInputField.CategoryType;
                                                })}
                                                styles={submitted && (getMainInputField.CategoryType === '' || getMainInputField.CategoryType === 0) ? styles : ''}
                                                onChange={event => handleInputChange(event, 'CategoryType')}
                                            ></Reactselect>
                                        </span>
                                    </div>
                                </div>
                            </div> : ''}
                            {props.location.state.params.type === 2 ? <div className="col-sm-4"> <div className='form-group'>
                                <label htmlFor="ddlCustomerShipmentRefNo">Report No. & Date<span className="text-danger">*</span></label>
                                <span className='input-icon icon-right'>
                                    <input type="text" className='form-control' id="ReportdateandNo"
                                        name="ReportdateandNo" placeholder="Enter Supplier Ref No." autoComplete="off"
                                        // onChange={event => handleMainChange(event, 'CustomerShipmentRefNo')}
                                        value={getMainInputField.ReportdateandNo}
                                        style={{ border: submitted && getMainInputField.ReportdateandNo === '' ? '1px solid red' : '' }}
                                        onChange={event => handleInputChange(event, "ReportdateandNo")}
                                        disabled={props.location.state.params.pageType === 'ViewInspection' ? true : false}
                                        maxLength="100" />
                                </span>
                            </div>
                            </div> : ''}
                            {props.location.state.params.type === 1 ? <div className="col-sm-4">
                                <div className='form-group'>
                                    <label htmlFor="ddlStyleName">Washing Type<span className="text-danger">*</span></label>
                                    <span className='input-icon icon-right'>
                                        <Reactselect className="basic-single" name="factoryorlocation"
                                            id={
                                                "ddlStyleName"
                                            }
                                            isDisabled={props.location.state.params.pageType === 'ViewInspection' ? true : false}
                                            //isDisabled={true}
                                            isLoading={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            options={getWashtpeDp}
                                            styles={submitted && getMainInputField.WashingType === 0 ? styles : ''}
                                            value={getWashtpeDp.filter(function (option) {
                                                return option.value === getMainInputField.WashingType;
                                            })}
                                            onChange={event => handleInputChange(event, 'WashType')}
                                        ></Reactselect>
                                    </span>
                                </div>
                            </div> : ''}
                            {props.location.state.params.type === 1 ? <div className="col-sm-4">
                                <div className='form-group'>
                                    <label htmlFor="ddlStyleName">Dryer Type<span className="text-danger">*</span></label>
                                    <span className='input-icon icon-right'>
                                        <Reactselect className="basic-single" name="factoryorlocation"
                                            id={
                                                "ddlStyleName"
                                            }
                                            isDisabled={props.location.state.params.pageType === 'ViewInspection' ? true : false}
                                            // isDisabled={true}
                                            isLoading={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            options={getDryertpeDp}
                                            value={getDryertpeDp.filter(function (option) {
                                                return option.value === getMainInputField.DryerType;
                                            })}
                                            styles={submitted && getMainInputField.DryerType === 0 ? styles : ''}
                                            onChange={event => handleInputChange(event, 'DryerType')}
                                        ></Reactselect>
                                    </span>
                                </div>
                            </div> : ''}
                        </div>
                        <div className="row">
                            {props.location.state.params.type === 1 ? <div className="col-sm-4">
                                <div className='form-group'>
                                    <label htmlFor="ddlStyleName">No of Wash(es)<span className="text-danger">*</span></label>
                                    <span className='input-icon icon-right'>
                                        <select id={"Noofwashes"} name="Noofwashes" className="form-select"
                                            value={getMainInputField.NoofWashes}
                                            onChange={event => handleInputChange(event, 'Noofwashes')}
                                            style={{ border: submitted && getMainInputField.NoofWashes === '0' ? '1px solid red' : '' }}
                                            disabled={props.location.state.params.pageType === 'ViewInspection' ? true : false}
                                        >
                                            <option value="0" key="0" style={{ color: '#bfdea1' }}> - Select washes- </option>
                                            <option value="51" key="51">NA</option>
                                            <option value="1" key="1">1</option>
                                            <option value="2" key="2">2</option>
                                            <option value="3" key="3">3</option>
                                            <option value="4" key="4">4</option>
                                            <option value="5" key="5">5</option>
                                            <option value="6" key="6">6</option>
                                            <option value="7" key="7">7</option>
                                            <option value="8" key="8">8</option>
                                            <option value="9" key="9">9</option>
                                            <option value="10" key="10">10</option>
                                            <option value="11" key="11">11</option>
                                            <option value="12" key="12">12</option>
                                            <option value="13" key="13">13</option>
                                            <option value="14" key="14">14</option>
                                            <option value="15" key="15">15</option>
                                            <option value="16" key="16">16</option>
                                            <option value="17" key="17">17</option>
                                            <option value="18" key="18">18</option>
                                            <option value="19" key="19">19</option>
                                            <option value="20" key="20">20</option>
                                            <option value="21" key="21">21</option>
                                            <option value="22" key="22">22</option>
                                            <option value="23" key="23">23</option>
                                            <option value="24" key="24">24</option>
                                            <option value="25" key="25">25</option>
                                            <option value="26" key="26">26</option>
                                            <option value="27" key="27">27</option>
                                            <option value="28" key="28">28</option>
                                            <option value="29" key="29">29</option>
                                            <option value="30" key="30">30</option>
                                            <option value="31" key="31">31</option>
                                            <option value="32" key="32">32</option>
                                            <option value="33" key="33">33</option>
                                            <option value="34" key="34">34</option>
                                            <option value="35" key="35">35</option>
                                            <option value="36" key="36">36</option>
                                            <option value="37" key="37">37</option>
                                            <option value="38" key="38">38</option>
                                            <option value="39" key="39">39</option>
                                            <option value="40" key="40">40</option>
                                            <option value="41" key="41">41</option>
                                            <option value="42" key="42">42</option>
                                            <option value="43" key="43">43</option>
                                            <option value="44" key="44">44</option>
                                            <option value="45" key="45">45</option>
                                            <option value="46" key="46">46</option>
                                            <option value="47" key="47">47</option>
                                            <option value="48" key="48">48</option>
                                            <option value="49" key="49">49</option>
                                            <option value="50" key="50">50</option>
                                        </select>
                                    </span>
                                </div>
                            </div> : ''}
                            {props.location.state.params.type === 1 ? <div className="col-sm-4">
                                <div className='form-group'>
                                    <label htmlFor="ddlStyleName">Test Start Date<span className="text-danger">*</span></label>
                                    <span className='input-icon icon-right'>
                                        <DatePicker className="form-control" name="TestStartDate"
                                            id={
                                                "TestStartDate"
                                            }

                                            selected={
                                                getMainInputField.TestStartDate
                                            }
                                            onChange={event => handleInputChange(event, 'TestStartDate')}
                                            dateFormat="dd/MM/yyyy"
                                            peekNextMonth
                                            style={{
                                                border: submitted && getMainInputField.TestStartDate === "" ? "1px solid red" : "",
                                                color:
                                                    getMainInputField.TestStartDate === ""
                                                        ? "#bfdea1"
                                                        : "#000"

                                                , textTransform: "uppercase"
                                            }}
                                            dropdownMode="scroll"
                                            autoComplete="off"
                                            customInput={<CustomInput />}
                                            isClearable={getMainInputField.TestStartDate === null ? false : true}
                                            //onBlur={event => handleChangedate(event, 'TestStartDate')}
                                            disabled={props.location.state.params.pageType === 'ViewInspection' ? true : false}
                                        />
                                    </span>
                                </div>
                            </div> : ''}
                            {props.location.state.params.type === 1 ? <div className="col-sm-4">
                                <div className='form-group'>
                                    <label htmlFor="ddlStyleName">Test End Date</label>
                                    <span className='input-icon icon-right'>
                                        <DatePicker className="form-control" name="TestEndDate"
                                            id={
                                                "TestEndDate"
                                            }
                                            selected={
                                                getMainInputField.TestEndDate
                                            }
                                            onChange={event => handleInputChange(event, 'TestEndDate')}
                                            dateFormat="dd/MM/yyyy"
                                            peekNextMonth
                                            // style={{
                                            //     border: submitted && getMainInputField.TestEndDate === "" ? "1px solid red" : "",
                                            //     color:
                                            //         getMainInputField.TestEndDate === ""
                                            //             ? "#bfdea1"
                                            //             : "#000"

                                            //     , textTransform: "uppercase"
                                            // }}
                                            dropdownMode="scroll"
                                            autoComplete="off"
                                            customInput={<CustomInputEndDate />}
                                            isClearable={getMainInputField.TestEndDate === null ? false : true}
                                            //onBlur={event => handleChangedate(event, 'TestEndDate')}
                                            disabled={props.location.state.params.pageType === 'ViewInspection' ? true : false}
                                        />
                                    </span>
                                </div>
                            </div> : ''}
                        </div>
                        <div className="row">
                            {props.location.state.params.type === 0 ? <div className="col-sm-4"> <div className='form-group'>
                                <label htmlFor="ddlCustomerShipmentRefNo">Agency Name<span className="text-danger">*</span></label>
                                <span className='input-icon icon-right'>
                                    <input type="text" className='form-control' id="AgencyName"
                                        name="AgencyName" placeholder="Enter Supplier Ref No." autoComplete="off"
                                        // onChange={event => handleMainChange(event, 'CustomerShipmentRefNo')}
                                        value={getMainInputField.Agencyname}
                                        style={{ border: submitted && getMainInputField.Agencyname === '' ? '1px solid red' : '' }}
                                        onChange={event => handleInputChange(event, "AgencyName")}
                                        disabled={props.location.state.params.pageType === 'ViewInspection' ? true : false}
                                        maxLength="100" />
                                </span>
                            </div>
                            </div> : ''}
                            {props.location.state.params.type === 0 ? <div className="col-sm-4"> <div className='form-group'>
                                <label htmlFor="ddlCustomerShipmentRefNo">Report No. & Date<span className="text-danger">*</span></label>
                                <span className='input-icon icon-right'>
                                    <input type="text" className='form-control' id="ReportdateandNo"
                                        name="ReportdateandNo" placeholder="Enter Supplier Ref No." autoComplete="off"
                                        // onChange={event => handleMainChange(event, 'CustomerShipmentRefNo')}
                                        value={getMainInputField.ReportdateandNo}
                                        style={{ border: submitted && getMainInputField.ReportdateandNo === '' ? '1px solid red' : '' }}
                                        onChange={event => handleInputChange(event, "ReportdateandNo")}
                                        disabled={props.location.state.params.pageType === 'ViewInspection' ? true : false}
                                        maxLength="100" />
                                </span>
                            </div>
                            </div> : ''}
                        </div>
                        {props.location.state.params.type === 1 ? <div className="row">
                            <div className="col-sm-4">
                                <div className='form-group'>
                                    <label htmlFor="ddlStyleName">Category Type<span className="text-danger">*</span></label>
                                    <span className='input-icon icon-right'>
                                        <Reactselect className="basic-single" name="factoryorlocation"
                                            id={
                                                "ddlStyleName"
                                            }
                                            isDisabled={props.location.state.params.pageType === 'ViewInspection' ? true : false}
                                            // isDisabled={true}
                                            isLoading={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            options={CatogeryType}
                                            value={CatogeryType.filter(function (option) {
                                                return option.value === getMainInputField.CategoryType;
                                            })}
                                            styles={submitted && getMainInputField.CategoryType === 0 ? styles : ''}
                                            onChange={event => handleInputChange(event, 'CategoryType')}
                                        ></Reactselect>
                                    </span>
                                </div>
                            </div>
                        </div> : ''}

                    </div>
                    <div className="col-sm-3 pull-right">
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail2">{props.location.state.params.TaskType} image <span className="text-danger">*</span></label>
                            <span className="text-danger">Click on the image to upload new</span><br /><br />
                            <div style={{ width: "150px" }}>
                                <FinalinspecUpload UploadCallback={(e) => ImageCallback(e)} PageName='Style/SKU' Buttonview={''} ShowDiv="1" filePath={getMainInputField.StyleLogoPath} filedata={IsValidationCallback}/>
                            </div>
                            <span className="s" style={{ left: "70px", bottom: '0px' }}>
                                <button onClick={
                                    event => ViewFile(event, getMainInputField.StyleLogoPath, 'Image2')
                                } className="btn btn-info fa fa-eye" style={{ padding: "5px 6px", width: "7%" }}>
                                    <i title="View Logo" style={{ marginRight: "6px" }}
                                    ></i>
                                </button>
                                &nbsp;
                                <button type="button"
                                    className="btn btn-xs btn-danger" title="Delete packing Image" style={{ display: props.location.state.params.pageType === 'ViewInspection' ? 'none' : '' }}
                                    onClick={() => handleRemoveFields()}
                                >
                                    <i className="fa fa-trash-o"></i>
                                </button>
                            </span>
                            <div> {
                                isOpenimg && (

                                    <Lightbox mainSrc={window.$APIBaseURL + 'Images' + getMainInputField.StyleLogoPath}
                                        alt="bg image"
                                        onCloseRequest={
                                            () => setIsOpenimg(false)
                                        } />
                                )
                            } </div>
                        </div>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="panel-group accordion" id="acc_Quantity_Info">
                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <h4 className="panel-title">
                                    <a className="accordion-toggle clr-themegreen" data-toggle="collapse" data-parent="#acc_Quantity_Info" href="#collapse_acc_Quantity_Info">
                                        {`${props.location.state.params.TaskType === 'FABRIC TEST' ? 'FABRIC TEST' :
                                            props.location.state.params.TaskType === 'GARMENT TEST' ? 'GARMENT TEST' : props.location.state.params.TaskType === 'YARN TEST' ? 'YARN TEST' :
                                                props.location.state.params.TaskType === 'Strike off Test' ? 'STRIKEE OFF TEST' : 'TRIMS TEST'}`} & ORDER DETAILS
                                    </a>
                                </h4>
                            </div>
                            <div id="collapse_acc_Quantity_Info" className="panel-collapse">
                                <div className="panel-body border-red">
                                    <div className="mb-10-float-right">
                                        <label className="btn btn-sm btn-warning">Previous Test</label>&nbsp;&nbsp;
                                        <button type="button" className="btn btn-sm btn-success mb-10-float-right" onClick={OpenPopup}>Order Details</button>
                                    </div>


                                    <div className="row">
                                        <div className="col-md-12">

                                            <>
                                                <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Style Name</th>
                                                            <th>Category Name</th>
                                                            <th>Category Type</th>
                                                        </tr>
                                                    </thead>
                                                    {getMQualityInfolist.map((Input, index) => (
                                                        <tbody>
                                                            <tr>
                                                                <td className="fixed-column-first-tna">
                                                                    {
                                                                        <span id={"btn-toggle_" + index} class="btn-toggle parentExpandViewTNA" aria-expanded="false" onClick={() => handleToggle(index, Input)} aria-labelledby="btn-toggle id-master">
                                                                            <i id={"icon-toggle_" + index} class="fa fa-plus-square-o clsPointer clscollapseexpandViewTNA"></i>
                                                                        </span>
                                                                    }

                                                                </td>
                                                                <td>{Input.StyleName}</td>
                                                                <td>{Input.CategoryName}</td>
                                                                <td>{Input.CategoryTypeName}</td>
                                                            </tr>

                                                            {
                                                                Input.IsExpanded === true && <tr class={Input.IsExpanded === true ? "child_" + index + " parentChild_" + index + "_" + 0 : ''}>
                                                                    < td colspan="9">
                                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                                                            <thead>
                                                                                <tr >
                                                                                    <th>SKU ID</th>
                                                                                    <th>Style Name</th>
                                                                                    <th>Style No</th>
                                                                                    <th>SKU Name</th>
                                                                                    <th>PO/ID No</th>
                                                                                    <th>Focus PO No.</th>
                                                                                    <th>Order Qty</th>
                                                                                    <th>Test Order Qty</th>
                                                                                    <th>Action</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    Input.IsExpanded === true && Input.SubTask.length !== 0 && Input.SubTask.map((sub, subIndex) => (<tr >
                                                                                        <td>{sub.SkuID}</td>
                                                                                        <td>{sub.StyleName}</td>
                                                                                        <td>{sub.StyleNo}</td>
                                                                                        <td>{sub.SkuName}</td>
                                                                                        <td>{sub.IdNo}</td>
                                                                                        <td>{sub.PoNo === '' ? '-' : sub.PoNo}</td>
                                                                                        <td>{sub.OrderQuantity}</td>
                                                                                        <td><input className="form-control" type="text"
                                                                                            name="" id="" value={sub.offerqty}
                                                                                            // style={{ border: submitted && (sub.offerqty === undefined || sub.offerqty === '') ? '1px solid red' : '' }}
                                                                                            onChange={event => handleOfferQty(event, index, subIndex)}
                                                                                        /></td>
                                                                                        <td style={{ display: props.location.state.params.pageType === 'ViewInspection' ? 'none' : '' }}> <span>
                                                                                            <span className="btn btn-danger"
                                                                                                // style={{ pointerEvents: getMQualityInfolist.length === 1 ? "none" : '', opacity: getMQualityInfolist.length === 1 ? '0.6' : '' }}
                                                                                                onClick={event => RemoveQuantity(event, index, sub, subIndex)}>
                                                                                                <i className="fa fa-trash-o"></i>
                                                                                            </span>
                                                                                        </span></td>
                                                                                    </tr>))
                                                                                }
                                                                                <tr>
                                                                                    <td colSpan="6" className="text-right"><b>Total</b></td>
                                                                                    <td>{getTotalValues.PoQty}</td>
                                                                                    <td>{getTotalValues.offqty}</td>
                                                                                    <td style={{ display: props.location.state.params.pageType === 'ViewInspection' ? 'none' : '' }}></td>
                                                                                    {/* <td></td> */}
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>}


                                                        </tbody>
                                                    ))
                                                    }
                                                </table>
                                                <br />
                                            </>

                                        </div>
                                        <br />
                                    </div>
                                </div>
                            </div>

                        </div>

                        {
                            getFinalInspectionPopup === true ? <Modal dialogClassName="CreatePopup" show={getFinalInspectionPopup} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleClosePopup()}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        Add&nbsp;{`${props.location.state.params.TaskType === 'FABRIC TEST' ? 'Fabric Test' :
                                            props.location.state.params.TaskType === 'GARMENT TEST' ? 'Garment Test' : props.location.state.params.TaskType === 'YARN TEST' ? 'Yarn Test' :
                                                props.location.state.params.TaskType === 'Strike off Test' ? 'Strike Off Test' : 'Trims Test'}`}&nbsp;
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="inspection" style={{ height: "70vh", overflowX: "hidden", overflowY: "scroll" }}>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr style={{ whiteSpace: "nowrap" }}>
                                                    <th>Style Name</th>
                                                    <th>Style No</th>
                                                    <th>SKU Name</th>
                                                    <th>PO/ID No</th>
                                                    <th>FO PO No</th>
                                                    <th>Factory</th>
                                                    <th>Order Qty</th>
                                                    <th>Task Holder / Follower</th>
                                                    <th>
                                                        <label style={{ marginTop: '8px' }}>
                                                            <input type="checkbox" name="IsSelected"

                                                                onChange={event => (SelectAllExFactory(event))}
                                                                checked={selectall === 1 ? true : false}
                                                                className="colored-blue clearOpacity" />
                                                            <span className="text"></span>
                                                        </label>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    MultiCloseDatas.length !== 0 && MultiCloseDatas.map((item, index) => {
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td>{item.styleName}</td>
                                                                    <td>{item.styleNo}</td>
                                                                    <td>{item.skuName}</td>
                                                                    {/* <td>-</td> */}
                                                                    <td>{item.idNo}</td>
                                                                    <td>{item.poNo}</td>
                                                                    <td>{item.factory}</td>
                                                                    <td>{item.orderQuantity}</td>
                                                                    <td className="cut_textInTNA" title={item.taskOwnerNameList} >{item.taskOwnerNameList}</td>
                                                                    <td className="Actionbutton fixed-column">
                                                                        <div style={{ width: "80px" }}>
                                                                            {
                                                                                <label style={{ marginTop: '8px' }}>
                                                                                    <input type="checkbox" name="IsSelected"
                                                                                        //disabled={item.balanceQuantity === "0" ? true : false}
                                                                                        onChange={event => (OnSelectExFactoryRecords(event, index, item))}
                                                                                        checked={item.isSelected === 1 ? true : false}
                                                                                        className="colored-blue clearOpacity" />
                                                                                    <span className="text"></span>
                                                                                </label>
                                                                            }&nbsp;&nbsp;&nbsp;
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </>

                                                        )
                                                    })

                                                }
                                                {
                                                    MultiCloseDatas.length === 0 ?
                                                        <tr>
                                                            <td colSpan="10" className='norecordfound'><span>No record found</span></td>
                                                        </tr> : ''
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="success" onClick={() => AddExFactoryForm()}>
                                        Save
                                    </Button>
                                </Modal.Footer>
                            </Modal> : ''
                        }

                    </div>
                </div>
                {
                    props.location.state.params.type === 2 ? <div className="row">
                        <div className="panel-group accordion1" id="acc_Quantity_Info1">
                            <div id="collapse_acc_Quantity_Info1" className="panel-collapse">
                                <div className="panel-body border-red">
                                    <div className="mb-10-float-right">

                                        <button type="button" className="btn btn-sm btn-success mb-10-float-right" onClick={OpenPopupEx}>Test Selection</button>
                                    </div>


                                    <div className="row">
                                        <div className="col-md-12">
                                            <>
                                                <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                                    <thead>
                                                        <tr>
                                                            <th >Name of Test</th>
                                                            <th >Test Result<span className="text-danger"> *</span></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Externalvalue.filter(x => x.ischecked === 1).map((Input, index) => (
                                                            <tr>
                                                                <td>{Input.TestName} </td>
                                                                <td>
                                                                    <Reactselect className="basic-single" name="factoryorlocation"
                                                                        id={
                                                                            "ddlStyleName"
                                                                        }
                                                                        isDisabled={props.location.state.params.pageType === 'ViewInspection' ? true : false}
                                                                        //isDisabled={true}
                                                                        isLoading={false}
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        options={Options}
                                                                        styles={submitted && Input.Testresult === 0 ? styles : ''}
                                                                        value={Options.filter(function (option) {
                                                                            return option.value === Input.Testresult;
                                                                        })}
                                                                        onChange={event => handleExternalTest(event, index, Input.TestName)}
                                                                    ></Reactselect>
                                                                </td>
                                                            </tr>
                                                        ))
                                                        }
                                                    </tbody>
                                                </table>
                                                <br />
                                            </>

                                        </div>
                                        <br />
                                    </div>
                                </div>
                            </div>
                            {
                                ispopup === true ? <Modal dialogClassName="CreatePopup" show={ispopup} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleClosePopupEX()}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            TEST SELECTION POPUP &nbsp;
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="inspection" style={{ height: "70vh", overflowX: "hidden", overflowY: "scroll" }}>
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr style={{ whiteSpace: "nowrap" }}>
                                                        <th>Test Name</th>
                                                        <th>Select</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        ExternalvalueSample.length !== 0 && ExternalvalueSample.map((item, index) => {
                                                            return (
                                                                <>
                                                                    <tr>
                                                                        <td>{item.TestName}</td>
                                                                        <td className="Actionbutton fixed-column">
                                                                            <div style={{ width: "80px" }}>
                                                                                {
                                                                                    <label style={{ marginTop: '8px' }}>
                                                                                        <input type="checkbox" name="IsSelected"
                                                                                            //disabled={item.balanceQuantity === "0" ? true : false}
                                                                                            onChange={event => (OnSelectFactoryRecordsEX(event, index, item))}
                                                                                            checked={item.ischecked === 1 ? true : false}
                                                                                            className="colored-blue clearOpacity" />
                                                                                        <span className="text"></span>
                                                                                    </label>
                                                                                }&nbsp;&nbsp;&nbsp;
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </>

                                                            )
                                                        })

                                                    }
                                                    {
                                                        ExternalvalueSample.length === 0 ?
                                                            <tr>
                                                                <td colSpan="10" className='norecordfound'><span>No record found</span></td>
                                                            </tr> : ''
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="success" onClick={() => AddExternalpopForm()}>
                                            Save
                                        </Button>
                                    </Modal.Footer>
                                </Modal> : ''
                            }
                        </div>
                    </div> : ''}
                {props.location.state.params.type === 2 ?
                    < div className="row" style={{ marginLeft: "0", marginTop: "2rem" }}>
                        <div className='form-group'>
                            <label htmlFor="InspectosConclusion">Inspection Report Upload<span className="text-danger">*</span> </label>

                            <br />
                            <div className="widget-header" style={{ paddingLeft: 0, background: 'none' }}>
                                <span className="widget-caption" style={{ fontSize: '14px' }}>File Upload <span
                                    style={{ color: 'red', fontSize: '14px' }}>(Upload Document Type: Excel, Word, PDF and Image)</span></span>
                            </div>
                            <div className='form-group'>
                                <tr>
                                    <td> <input type="file" id="FileUpload" disabled={getFiles.length >= 5 ? true : false}//disabled={getFiles.length === 1 ? true : false}
                                        onChange={(e) => AppendFiles(e)} accept=".jpg,.jpeg,.png,.pdf,.xlsx,.doc,.docx,.xls" 
                                        style={{
                                            ...(submitted && getFiles.length === 0 ? 
                                                { border: '1px solid red', height: '50px' } : {}),
                                        }}
                                        ></input></td>
                                </tr>
                            </div>
                        </div>
                        {
                            <div className="col-lg-12 col-sm-12">
                                <div className="row no-margin">
                                    {
                                        getFiles.length !== 0 ?
                                            getFiles.map((files, fileindex) => (
                                                files.FileType === "image/jpeg" || files.FileType === "image/jpg" || files.FileType === "image/png" ||
                                                    files.FileType === "image/gif" ?
                                                    <div className="float-Left_washcare file_Image_uploads">
                                                        {/* <div className="col-sm-3"> */}
                                                        <div className="client_tumblineWashcare">

                                                            {files.FileName !== '' ? <img className="width_manual1"
                                                                src={window.$APIBaseURL + files.Filepath}
                                                            //onClick={() => setIsOpenimg(window.$APIBaseURL + 'Images/Style/WashCare/' + getImagename)}
                                                            /> : ''}

                                                            <table className="table_thum">
                                                                <tr className="table_rwo">
                                                                    {/* <td title={files.FileName.length > 32 ? files.FileName.substr(33) : files.FileName.substr(5)} className="bold_text width_20 sku_textoverflow">
                                                                                                                    {files.FileName.length > 32 ? files.FileName.substr(33) : files.FileName.substr(5)}
                                                                                                                </td> */}
                                                                </tr>
                                                            </table>

                                                            <a className="washcareView" title='View External image' onClick={() => ViewFileExternal(fileindex, files.Filepath)}>
                                                                <i className="btn btn-info fa fa-eye" ></i>
                                                            </a>&nbsp;
                                                            <a style={{ display: props.location.state.params.pageType === 'ViewInspection' ? 'none' : '' }} title='Remove Wash Care' onClick={() => RemoveImages(fileindex)}>
                                                                <i className="btn btn-danger fa fa-trash-o"></i>
                                                            </a>

                                                        </div>
                                                        {/* </div> */}
                                                        {

                                                            < div > {
                                                                isOpenimgEX && (

                                                                    <Lightbox mainSrc={window.$APIBaseURL + getImagenameEX}
                                                                        alt="bg image"
                                                                        onCloseRequest={
                                                                            () => setIsOpenimgEX(false)
                                                                        } />
                                                                )
                                                            } </div>
                                                        }
                                                    </div> : ''
                                            ))
                                            : ''
                                    }
                                </div>
                            </div>
                        }
                        &nbsp;
                        &nbsp;
                        {

                            <div className="col-lg-12 col-sm-12">
                                <div className="row no-margin">
                                    {
                                        getFiles.length !== 0 ?
                                            getFiles.map((files, fileindex) => (
                                                files.FileType === "application/pdf" || files.FileType === "application/msword" || files.FileType === "application/vnd.ms-excel"
                                                    || files.FileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || files.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ?
                                                    <div className="col-lg-4 col-sm-4 file_list_upload">
                                                        <tr title={files.FileName}>
                                                            {files.FileName !== '' ? <td className="cut_textInTNA">
                                                                <span style={{ marginRight: '6px' }}>
                                                                    {
                                                                        files.FileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                            files.FileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                files.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                    files.FileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                    <i class="fa fa-picture-o fa-lg image_icon_style"></i>

                                                                    }
                                                                    {
                                                                        files.FileType === "image/jpeg" || files.FileType === "image/jpg" || files.FileType === "image/png" ||
                                                                            files.FileType === "image/gif" ? <i class="fa fa-picture-o fa-lg image_icon_style"></i> : ''
                                                                    }
                                                                </span>  {files.FileName}
                                                            </td> : ''}

                                                            <td className="button_style_uploda">
                                                                {Buttonview !== "View" &&

                                                                    <p title='Delete File'
                                                                        style={{ display: props.location.state.params.pageType === 'ViewInspection' ? 'none' : '' }}
                                                                        onClick={(e) => RemoveFiles(e, fileindex)}
                                                                        className="btn btn-danger btn-xs delete">
                                                                        <i className="fa fa-trash-o"></i>

                                                                    </p>
                                                                }
                                                                &nbsp;
                                                                {
                                                                    files.FileType === "application/pdf" || files.FileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                                                                        files.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                                        || files.FileType === 'application/msword' || files.FileType === "application/vnd.ms-excel" ?
                                                                        <a type="button" style={{ display: props.location.state.params.pageType === 'ViewInspection' ? 'none' : '' }}
                                                                            title="Download" class="btn btn-xs btn-primary" target="_blank"
                                                                            href={window.$APIBaseURL + files.Filepath} download><i class="fa fa-download"></i> </a> : ''
                                                                }
                                                            </td>
                                                        </tr>
                                                    </div> : ''
                                            ))
                                            : ''
                                    }
                                </div>
                            </div>
                        }
                    </div> : ''
                }
                {
                    props.location.state.params.type === 2 ? <div className="row">
                        <div className="col-lg-4">
                            <label htmlFor="">Lab Comments<span className="text-danger">*</span></label>
                            <br />

                            <Reactselect className="basic-single " name="IsProductionStatusID"
                                options={labResult}
                                isClearable={true}
                                onChange={e => handleLabResultOnchange(e, "labcomment")}
                                styles={submitted && getLabComments.value === 0 ? styles : ''}
                                value={labResult.filter(function (option) {
                                    return option.value === getLabComments.value;
                                })}
                            ></Reactselect>
                        </div>



                        <div className="col-lg-12">
                            <label htmlFor="">Remarks</label>
                            <br />
                            <textarea name=""
                                id="" onChange={e => handleLabResultOnchange(e, "Remarks")}
                                value={getLabComments.Remarks} cols="150" rows="5"
                                style={{ border: submitted && (getLabComments.value === 2 && getLabComments.Remarks === '') ? '1px solid red' : '' }}></textarea>
                        </div>
                    </div> : ''}

                {loader}
            </div>
        </Fragment >
    )
}

export default GarmanetorderDetails