import {
    WORKMANSHIPITEM_LOADING,
    RETRIEVE_WORKMANSHIPITEM,
    DELETE_WORKMANSHIPITEM,
} from "../actions/types";

const initialState = {
    isLoadingWorkmanship: true,
    WorkmanshipItem: []
}
const WorkmanItemReducer = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case WORKMANSHIPITEM_LOADING:
            return {
                ...state,
                isLoadingWorkmanship: payload
            };
        case RETRIEVE_WORKMANSHIPITEM:
            return {
                ...state, WorkmanshipItem: payload
            }
        case DELETE_WORKMANSHIPITEM:
            let results = state.WorkmanshipItem.filter(c => c.workmanshipItemID !== payload.workmanshipItemID);
            return {
                ...state,
                WorkmanshipItem: results
            }
        default:
            return state

    }

}
export default WorkmanItemReducer