import {
  BRANCH_LOADING,
  CREATE_BRANCH,
  RETRIEVE_BRANCH,
//   UPDATE_BRANCH,
 DELETE_BRANCH
} from "./types";



import BranchService from "../services/Master/BranchService";
// import  useFullPageLoader from "../hooks/useFullPageLoader";


// export const [loader, showLoader, hideLoader] = useFullPageLoader();

const branchLoading = (isStatus) => ({
  type: BRANCH_LOADING,
  payload: isStatus,
});


export const createBranch = (getAddress) => async (dispatch) => {
let resData="";
 await BranchService.AddAddress(getAddress).then((res) => {
  resData=res.data;
            
}).catch((err) => { return Promise.reject(err); });
return Promise.resolve(resData);
}

export const deleteBranch = (addressTypeID) => async (dispatch) => {
  try {
    dispatch({
    type: DELETE_BRANCH,
    payload:addressTypeID,
  });

} catch (err) {
  console.log(err);
}
};


export const retrieveBranch = () => async (dispatch) => {
  try {
    
    dispatch(branchLoading(true));
  const  res = await BranchService.getAllAddressType();
    dispatch({
    type: RETRIEVE_BRANCH,
    payload: res.data,
  });
  dispatch(branchLoading(false));
} catch (err) {
  console.log(err);
  dispatch(branchLoading(false));
}
};



