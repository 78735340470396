import React, { useState, useEffect, Fragment } from "react";
import ReactNotification from 'react-notifications-component';
import Nodify from "../../Common/ReactNotification"
import SearchRetain from "../../Common/SearchRetain";
import Reactselect from 'react-select';
import taskService from "../../../services/Master/TaskService";
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";

const TaskAdd = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);
    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.common.MenuList.item3);
    if (menulist !== undefined) { activeMenu = menulist.filter(i => i.menuName === "Task"); }
    // End Enable Menu
    const EmptyInputFields =
        [{
            TaskDetailsID: 0,
            DetailTask: 0,
            TaskType: 0,
            TaskName: '',
            OrderType: 0,
            TaskHolderOrFollower: 0,
            LeaveDays: 0,
            AdditionalFollowup: 0,
            IndexName: '0',
            IsPopupOpen: 0,
            FollowupDetails: [{
                AdditonalTaskDetailsID: 0,
                AddtionalTaskName: '',
                IndexName: '0',
                ChildIndexName: '0',
                PreferenceId: 0,
            }],
        }]
    const [inputFields, setInputFields] = useState(EmptyInputFields);
    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getTaskHolderList, setTaskHolderList] = useState([]);
    const [getLeaveDaysList, setLeaveDaysList] = useState([]);
    const [getTaskTypeList, setTaskTypeList] = useState([]);
    const [getTaskOrderTypeList, setTaskOrderTypeList] = useState([]);
    const [getDetailType, setDetailTypeList] = useState([]);
    const [ExistingList, setExistingList] = useState([]);

    const values = [...inputFields];

    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),
    }

    useEffect(() => {
        taskService.getLoadTaskHolderList().then((response) => {
            if (response.data) {
                let Optionvalue = [];
                response.data.forEach(element => {
                    let LabelColor = '';
                    if (element.label === 'Buyer - -') {
                        LabelColor = <><span style={{ color: "#0b0992" }}><b>Buyer</b></span></>
                    }
                    else if (element.label === 'Buyer - Cube') {
                        LabelColor = <><span style={{ color: "#0b0992" }}><b>Buyer</b></span> - <span style={{ color: "#00a65a" }}><b>Cube</b></span></>
                    }
                    else if (element.label === 'Buyer - Supplier') {
                        LabelColor = <><span style={{ color: "#0b0992" }}><b>Buyer</b></span> - <span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                    }
                    else if (element.label === 'Buyer - Cube,Supplier') {
                        LabelColor = <><span style={{ color: "#0b0992" }}><b>Buyer</b></span> - <span style={{ color: "#00a65a" }}><b>Cube</b></span>, <span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                    }
                    else if (element.label === 'Cube - -') {
                        LabelColor = <><span style={{ color: "#00a65a" }}><b>Cube</b></span></>
                    }
                    else if (element.label === 'Cube - Buyer') {
                        LabelColor = <><span style={{ color: "#00a65a" }}><b>Cube</b></span> - <span style={{ color: "#0b0992" }}><b>Buyer</b></span></>
                    }
                    else if (element.label === 'Cube - Supplier') {
                        LabelColor = <><span style={{ color: "#00a65a" }}><b>Cube</b></span> - <span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                    }
                    else if (element.label === 'Cube - Buyer,Supplier') {
                        LabelColor = <><span style={{ color: "#00a65a" }}><b>Cube</b></span> - <span style={{ color: "#0b0992" }}><b>Buyer</b></span>, <span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                    }
                    else if (element.label === 'Supplier - -') {
                        LabelColor = <><span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                    }
                    else if (element.label === 'Supplier - Buyer') {
                        LabelColor = <><span style={{ color: "#e27607" }}><b>Supplier</b></span> - <span style={{ color: "#0b0992" }}><b>Buyer</b></span></>
                    }
                    else if (element.label === 'Supplier - Cube') {
                        LabelColor = <><span style={{ color: "#e27607" }}><b>Supplier</b></span> - <span style={{ color: "#00a65a" }}><b>Cube</b></span></>
                    }
                    else if (element.label === 'Supplier - Buyer,Cube') {
                        LabelColor = <><span style={{ color: "#e27607" }}><b>Supplier</b></span> - <span style={{ color: "#0b0992" }}><b>Buyer</b></span>, <span style={{ color: "#00a65a" }}><b>Cube</b></span></>
                    }

                    Optionvalue.push({
                        value: element.value,
                        label: LabelColor,
                        subLabel: element.label,
                    })
                });

                setTaskHolderList(Optionvalue);
            }
        });


        taskService.getLoadLeaveDaysList().then((response) => {
            if (response.data) {
                setLeaveDaysList(response.data);
            }
        });

        taskService.getLoadTaskTypeList(1).then((response) => {
            if (response.data) {
                setTaskTypeList(response.data);
            }
        });

        taskService.getLoadTaskOrderTypeList().then((response) => {
            if (response.data) {
                setTaskOrderTypeList(response.data);
            }
        });

        taskService.GetTaskDetailsList(0).then((response) => {
            if (response.data) {
                setExistingList(response.data);
            }
        });

        taskService.GetDetailTypeList(0).then((response) => {
            if (response.data) {
                setDetailTypeList(response.data);
            }
        });

    }, []);

    const handleAddFields = (index) => {
        let count = index;
        count++;
        let EmptyList = {};

        // if (values[index].TaskName.trim() !== ''
        //     && values[index].TaskType !== 0
        //     && values[index].OrderType !== 0) {
        EmptyList = {
            TaskDetailsID: 0,
            TaskType: 0,
            TaskName: '',
            OrderType: 0,
            TaskHolderOrFollower: 0,
            LeaveDays: 0,
            AdditionalFollowup: 0,
            IndexName: (index + 1).toString(),
            IsPopupOpen: 0,
            FollowupDetails: [{
                AdditonalTaskDetailsID: 0,
                AddtionalTaskName: '',
                IndexName: (index + 1).toString(),
                ChildIndexName: '0',
                PreferenceId: 0,
            }],
        };
        values.splice(count, 0, EmptyList);
        setInputFields(values);
        setSubmitted(false);
        // }
        // else {
        //     Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
        //     setSubmitted(true);
        // }
    };

    const handleRemoveFields = index => {
        values.splice(index, 1);
        setInputFields(values);
    };

    const handleChange = (e, index, FieldName) => {
       ;
        //setSubmitted(false);
        let inputText = '';
        if (FieldName === "TaskType" ||
            FieldName === "DetailTask" ||
            FieldName === "OrderType" ||
            FieldName === "TaskHolderOrFollower" ||
            FieldName === "LeaveDays") {
            if (e != null) {
                inputText = parseInt(e.value);
            }
            else {
                inputText = 0;
            }
            values[index][FieldName] = inputText;
        }
        else {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }

            if (e.target.name === "AdditionalFollowup") {
                values[index].FollowupDetails = [];
                var checkNumber = inputText.replace(/\D/g, '').match('^[0-9]*$');
                inputText = checkNumber[0];

                if (parseInt(inputText) > 0) {
                    for (let i = 0; i < inputText; i++) {
                        values[index].FollowupDetails.push({
                            AdditonalTaskDetailsID: 0,
                            AddtionalTaskName: '',
                            IndexName: values[index].IndexName,
                            PreferenceId: 0,
                        })
                    }
                }
            }
            values[index][e.target.name] = inputText;
        }
        setInputFields(values);
    }

    const CheckDuplicate = (index, Field) => {
        if (ExistingList.length !== 0) {
            if (values[index].TaskName !== '') { // for avoid empty validation
                for (var i = 0; i < ExistingList.length; i++) {
                    let MSG = '';
                    if (ExistingList[i].taskType === values[index].TaskType &&
                        ExistingList[i].orderType === values[index].OrderType &&
                        ExistingList[i].taskName.trim().toLowerCase() === values[index].TaskName.trim().toLowerCase()) {
                        if (Field === "TaskName") {
                            values[index].TaskName = '';
                            MSG = "This task name is already exist.";
                        }
                        else if (Field === "TaskType") {
                            values[index].TaskType = 0;
                            MSG = "This task type is already exist.";
                        }
                        else if (Field === "OrderType") {
                            values[index].OrderType = 0;
                            MSG = "This order type is already exist.";
                        }
                        setInputFields(values);
                        Nodify('Warning!', 'warning', MSG);
                        return false;
                    }
                }
            }
        }

        for (var j = 0; j < values.length; j++) {
            let MSG = '';
            if (index !== j) {
                if (values[index].TaskName !== '') { // for avoid empty validation
                    if (values[j].TaskType === values[index].TaskType &&
                        values[j].OrderType === values[index].OrderType &&
                        values[j].TaskName.trim().toLowerCase() === values[index].TaskName.trim().toLowerCase()) {
                        if (Field === "TaskName") {
                            values[index].TaskName = '';
                            MSG = "This task name is already exist.";
                        }
                        else if (Field === "TaskType") {
                            values[index].TaskType = 0;
                            MSG = "This task type is already exist.";
                        }
                        else if (Field === "OrderType") {
                            values[index].OrderType = 0;
                            MSG = "This order type is already exist.";
                        }
                        setInputFields(values);
                        Nodify('Warning!', 'warning', MSG);
                        return false;
                    }
                }
            }
        }
    }

    const CheckChildDuplicate = (index, childIndex) => {
        for (var j = 0; j < values[index].FollowupDetails.length; j++) {
            let MSG = '';
            if (childIndex !== j) {
                if (values[index].FollowupDetails[childIndex].AddtionalTaskName !== '') { // for avoid empty validation
                    if (values[index].FollowupDetails[j].AddtionalTaskName.trim().toLowerCase() === values[index].FollowupDetails[childIndex].AddtionalTaskName.trim().toLowerCase()) {
                        values[index].FollowupDetails[childIndex].AddtionalTaskName = '';
                        MSG = "This task name is already exist.";

                        values[index].AdditionalFollowup = values[index].FollowupDetails.filter(x => x.AddtionalTaskName.trim() !== '').length;

                        setInputFields(values);
                        Nodify('Warning!', 'warning', MSG);
                        return false;
                    }
                }
            }
        }
    }

    const handleChildChange = (e, index, childIndex, FieldName) => {
        setSubmitted(false);
        let inputText = '';
        if (e.target.value.trim() !== '') {
            inputText = e.target.value;
            values[index].FollowupDetails[childIndex][e.target.name] = inputText;
        }
        else {
            values[index].FollowupDetails[childIndex][e.target.name] = '';
        }

        values[index].AdditionalFollowup = values[index].FollowupDetails.filter(x => x.AddtionalTaskName.trim() !== '').length;
        setInputFields(values);
    }

    const handleFollowup = index => {
        values[index].IsPopupOpen = 1;
        setInputFields(values);
    };

    const handleSave = (e, index, ButtonName) => {
       ;
        let isvalid = true;
        let inputText = ''
        for (let i = 0; i < (values[index].FollowupDetails.length); i++) {
            if (values[index].FollowupDetails[i].AddtionalTaskName === "") {
                isvalid = false;
                setSubmitted(true);
                Nodify('Warning!', 'warning', "Please fill this mandantory* fields");
            }
        }

        if (ButtonName === "Save" && isvalid) {
            inputText = e.value
            values[index].IsPopupOpen = 0;
            Nodify('Success!', 'success', "Your changes will be saved.");
        }

        setInputFields(values);
    };
    const handleClose = (index, ButtonName) => {
       ;
        if (ButtonName === "Close") {
            //values[index].FollowupDetails[index].AddtionalTaskName = ""
            values[index].IsPopupOpen = 0;
            setInputFields(values);
        }

    };


    const handleChildRemoveFields = (index, childIndex) => {
        values[index].FollowupDetails.splice(childIndex, 1);
        if (values[index].FollowupDetails.length > 0) {
            for (var i = 0; i <= values[index].FollowupDetails.length - 1; i++) {
                values[index].FollowupDetails[i].PreferenceId = i + 1;
            }
        }
        values[index].AdditionalFollowup = values[index].FollowupDetails.filter(x => x.AddtionalTaskName.trim() !== '').length;
        setInputFields(values);
    }

    const handleChildAddFields = (e, index, childIndex) => {
        let count = childIndex;
        count++;
        let EmptyList = {};
        if (values[index].FollowupDetails[childIndex].AddtionalTaskName.trim() !== '') {
            EmptyList = {
                AdditonalTaskDetailsID: 0,
                AddtionalTaskName: '',
                IndexName: index.toString(),
                ChildIndexName: (childIndex + 1).toString(),
                PreferenceId: 0,
            };

            values[index].FollowupDetails.splice(count, 0, EmptyList);

            if (values[index].FollowupDetails.length > 0) {
                for (var i = 0; i <= values[index].FollowupDetails.length - 1; i++) {
                    values[index].FollowupDetails[i].PreferenceId = i + 1;
                }
            }

            setInputFields(values);
            setSubmitted(false);
        }
        else {
            Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
            setSubmitted(true);
        }
    }

    const SaveTaskList = (e) => {
       ;
        setButtonLoader(true);
        e.preventDefault();
        let IsValid = true;

        if (inputFields.length === 1 && (inputFields[0].TaskName === '' || inputFields[0].DetailTask === '' || inputFields[0].TaskType === 0 || inputFields[0].OrderType === 0)) {
            setButtonLoader(false);
            setSubmitted(true);
            ValidationPopup("Please fill at least one Task.");
        }
        else {
            const GetTaskDetails = { Operation: 1, TaskInformationID: 0, TaskInformation: [], AdditionalTaskInformation: [], Createdby: currentUser.employeeinformationID };

            inputFields.forEach((Info, i) => {
                if (Info.TaskName === '' || Info.TaskType === 0 || Info.OrderType === 0) {

                    setButtonLoader(false);
                    if (IsValid === true) {
                        Nodify('Warning!', 'warning', "Please fill the mandatory(*) fields.");
                        IsValid = false;
                    }
                    setSubmitted(true);
                }
                else {
                   ;
                    GetTaskDetails.TaskInformation.push({
                        TaskDetailsID: Info.TaskDetailsID,
                        TaskType: Info.TaskType,
                        DetailTypeID: Info.DetailTask,
                        TaskName: Info.TaskName.trim(),
                        OrderType: Info.OrderType,
                        TaskHolderOrFollower: Info.TaskHolderOrFollower,
                        LeaveDays: Info.LeaveDays,
                        AdditionalFollowup: Info.AdditionalFollowup,
                        IndexName: i.toString(),
                    })
                    if (Info.FollowupDetails) {
                        Info.FollowupDetails.forEach((child, j) => {
                            if (child.AddtionalTaskName !== '') {
                                GetTaskDetails.AdditionalTaskInformation.push({
                                    AdditonalTaskDetailsID: 0,
                                    AddtionalTaskName: child.AddtionalTaskName,
                                    IndexName: i.toString(),
                                    ChildIndexName: j.toString(),
                                    PreferenceId: 0,
                                });
                            }
                        });
                    }
                }
            });

            if (IsValid) {
                taskService.InsertUpdateTaskDetails(GetTaskDetails).then(res => {
                    var page = "Remove";
                    SearchRetain(page);
                    let Func = 'Add';
                    if (res.data.outputResult === "1") {
                        Func = 'Add';
                        PageRedirect(Func);
                    } else if (res.data.outputResult === "2") {
                        Func = 'Edit';
                        PageRedirect(Func);
                    } else if (res.data.outputResult === "-2") {
                        setButtonLoader(false);
                        ValidationPopup("This name is already exist.");
                    }
                    else if (res.data.outputResult === "0") {
                        setButtonLoader(false);
                        ValidationPopup("Error Occured!");
                    }
                });
            }
        }
    }

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/ListTask",
            state: {
                message: Func
            }
        });
    }

    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
        return false;
    }

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/ListTask');
        } else {
            window.location.reload();
        }
    }

    const filterOption = (option, inputValue) => {
        let trimValue = inputValue.trim();
        if (trimValue === "") {
            return option;
        }
        return option.data.subLabel.toLowerCase().includes(trimValue.toLowerCase());
    };

    return (
        <>
            <div className="page-body">
                <ReactNotification />

                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <div className="widget flat radius-bordered">
                            <div className="widget-header heading_box_style">
                                <h3 className="heading_style_common">Add Task</h3>
                                <div className="buttons-preview" align="right">
                                    <span className="btn btn-primary"
                                        onClick={
                                            () => ResetOperation("Back")
                                        }>
                                        <i className="fa fa-arrow-circle-left"></i>
                                        &nbsp;Back</span>
                                    <span className="btn btn-danger"
                                        onClick={
                                            () => ResetOperation()
                                        }>
                                        <i className="fa fa-times"></i>
                                        &nbsp;Reset</span>
                                    <button type="button" className="btn btn-success" disabled={buttonLoader}
                                        onClick={SaveTaskList}>

                                        <i className="fa fa-check right"></i>
                                        &nbsp;Save</button>
                                </div>
                            </div>
                            <div className="widget-body">
                                <div id="registration-form">
                                    <form>
                                        <div >
                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: "17%" }}>Detail Type<span className="text-danger">*</span></th>
                                                        <th style={{ width: "17%" }}>Task Type<span className="text-danger">*</span></th>
                                                        <th style={{ width: "15%" }}>Task Name<span className="text-danger">*</span></th>
                                                        <th style={{ width: "15%" }}>Order Type<span className="text-danger">*</span></th>
                                                        <th style={{ width: "16%" }}>Task Holder / Follower</th>
                                                        <th style={{ width: "17%" }}>Leave Days</th>
                                                        {/* <th style={{ width: "10%" }}>Additional Followup</th> */}
                                                        <th style={{ width: "10%" }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        inputFields.map((inputField, index) => (
                                                            <Fragment key={
                                                                `${inputField}~${index}`
                                                            }>
                                                                <tr>
                                                                    <td> <span className='input-icon icon-right'>
                                                                        <Reactselect className="basic-single" name="DetailTask"

                                                                            onChange={event => handleChange(event, index, "DetailTask")}
                                                                            value={getDetailType.filter(function (option) {
                                                                                return option.value === inputField.DetailTask;
                                                                            })}
                                                                            options={getDetailType}
                                                                            autoFocus
                                                                            styles={submitted && inputField.DetailTask === 0 ? styles : ''}
                                                                        ></Reactselect>
                                                                    </span></td>
                                                                    <td>
                                                                        <span className='input-icon icon-right'>
                                                                            <Reactselect className="basic-single" name="TaskType"
                                                                                id={
                                                                                    "TaskType"
                                                                                }
                                                                                isLoading={false}
                                                                                isClearable={true}
                                                                                isSearchable={true}
                                                                                onChange={event => handleChange(event, index, "TaskType")}
                                                                                onBlur={() => CheckDuplicate(index, 'TaskType')}
                                                                                value={getTaskTypeList.filter(function (option) {
                                                                                    return option.value === inputField.TaskType;
                                                                                })}
                                                                                options={getTaskTypeList}
                                                                                autoFocus
                                                                                styles={submitted && inputField.TaskType === 0 ? styles : ''}
                                                                            ></Reactselect>
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span className='input-icon icon-right'>
                                                                            <input type="text"
                                                                                placeholder="Enter Task Name"
                                                                                id="TaskName"
                                                                                name="TaskName"
                                                                                value={inputField.TaskName}
                                                                                onChange={event => handleChange(event, index, "TaskName")}
                                                                                onBlur={() => CheckDuplicate(index, 'TaskName')}
                                                                                maxLength="100"
                                                                                className={'form-control' + (
                                                                                    submitted && !inputField.TaskName ? ' is-invalid' : ''
                                                                                )}
                                                                                style={{ border: submitted && !inputField.TaskName ? '1px solid red' : '' }}
                                                                            />
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span className='input-icon icon-right'>
                                                                            <Reactselect className="basic-single" name="OrderType"
                                                                                id={
                                                                                    "OrderType"
                                                                                }
                                                                                isLoading={false}
                                                                                isClearable={true}
                                                                                isSearchable={true}
                                                                                onChange={event => handleChange(event, index, "OrderType")}
                                                                                onBlur={() => CheckDuplicate(index, 'OrderType')}
                                                                                value={getTaskOrderTypeList.filter(function (option) {
                                                                                    return option.value === inputField.OrderType;
                                                                                })}
                                                                                options={getTaskOrderTypeList}
                                                                                styles={submitted && inputField.OrderType === 0 ? styles : ''}
                                                                                //   menuPosition="fixed"
                                                                                menuPlacement="auto"
                                                                            ></Reactselect>
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <Reactselect className="basic-single" name="TaskHolderOrFollower"
                                                                            id={
                                                                                "TaskHolderOrFollower"
                                                                            }
                                                                            isLoading={false}
                                                                            isClearable={true}
                                                                            isSearchable={true}
                                                                            onChange={event => handleChange(event, index, "TaskHolderOrFollower")}
                                                                            value={getTaskHolderList.filter(function (option) {
                                                                                return option.value === inputField.TaskHolderOrFollower;
                                                                            })}
                                                                            options={getTaskHolderList}
                                                                            filterOption={filterOption}
                                                                            // styles={submitted && inputField.TaskHolderOrFollower === 0 ? styles : ''}
                                                                            //   menuPosition="fixed"
                                                                            menuPlacement="auto"

                                                                        ></Reactselect>
                                                                    </td>
                                                                    <td>
                                                                        <Reactselect className="basic-single" name="LeaveDays"
                                                                            id={
                                                                                "LeaveDays"
                                                                            }
                                                                            isLoading={false}
                                                                            isClearable={true}
                                                                            isSearchable={true}
                                                                            onChange={event => handleChange(event, index, "LeaveDays")}
                                                                            value={getLeaveDaysList.filter(function (option) {
                                                                                return option.value === inputField.LeaveDays;
                                                                            })}
                                                                            options={getLeaveDaysList}
                                                                            //  styles={submitted && inputField.LeaveDays === 0 ? styles : ''}
                                                                            // menuPosition="fixed"
                                                                            menuPlacement="auto"
                                                                        ></Reactselect>
                                                                    </td>
                                                                    <td>
                                                                        {/* <span className='input-icon icon-right'>
                                                                        <input type="text"
                                                                            placeholder="Additional Followup"
                                                                            id="AdditionalFollowup"
                                                                            name="AdditionalFollowup"
                                                                            value={inputField.AdditionalFollowup}
                                                                            onChange={event => handleChange(event, index, "AdditionalFollowup")}
                                                                            maxLength="3"
                                                                            className={'form-control' + (
                                                                                submitted && !inputField.AdditionalFollowup ? ' is-invalid' : ''
                                                                            )}
                                                                        //  style={{ border: submitted && !inputField.AdditionalFollowup ? '1px solid red' : '' }}
                                                                        />
                                                                    </span> */}
                                                                        {inputField.IsPopupOpen === 1 &&
                                                                            <Modal show={inputField.IsPopupOpen === 1} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleClose(index, "Close")}>
                                                                                <Modal.Header closeButton>
                                                                                    <Modal.Title>Additional Task</Modal.Title>
                                                                                </Modal.Header>
                                                                                <Modal.Body>
                                                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>#</th>
                                                                                                <th>Task Name<span className="text-danger">*</span></th>
                                                                                                <th>Action</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                inputField.FollowupDetails.map((ChildField, childIndex) => (
                                                                                                    <Fragment key={
                                                                                                        `${inputField}~${index}`
                                                                                                    }>
                                                                                                        <tr>
                                                                                                            <td>{childIndex + 1}</td>
                                                                                                            <td>
                                                                                                                <input type="text"
                                                                                                                    placeholder="Task Name"
                                                                                                                    id={"AddtionalTaskName_" + index + "_" + childIndex}
                                                                                                                    name="AddtionalTaskName"
                                                                                                                    value={ChildField.AddtionalTaskName}
                                                                                                                    autoFocus
                                                                                                                    onChange={event => handleChildChange(event, index, childIndex, "AddtionalTaskName")}
                                                                                                                    onBlur={() => CheckChildDuplicate(index, childIndex)}
                                                                                                                    maxLength="100"
                                                                                                                    className={'form-control' + (
                                                                                                                        submitted && !ChildField.AddtionalTaskName ? ' is-invalid' : ''
                                                                                                                    )}
                                                                                                                    style={{ border: submitted && ChildField.AddtionalTaskName === "" ? '1px solid red' : '' }}
                                                                                                                />
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <button type="button" className="btn  btn-xs  btn-success" title="Add Additional Task"
                                                                                                                    onClick={(event) => handleChildAddFields(event, index, childIndex)}>
                                                                                                                    <i className="fa fa-plus"></i>
                                                                                                                </button>
                                                                                                                &nbsp;
                                                                                                                {inputField.FollowupDetails.length !== 1 && (
                                                                                                                    <button type="button" className="btn  btn-xs  btn-danger" title="Delete Additional Task"
                                                                                                                        onClick={() => handleChildRemoveFields(index, childIndex)}>
                                                                                                                        <i className="fa fa-trash-o"></i>
                                                                                                                    </button>
                                                                                                                )}

                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </Fragment>
                                                                                                ))
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </Modal.Body>
                                                                                <Modal.Footer>
                                                                                    <Button variant="success" onClick={(e) => handleSave(e, index, "Save")}>
                                                                                        Save
                                                                                    </Button>
                                                                                </Modal.Footer>
                                                                            </Modal>
                                                                        }

                                                                        {/* {(inputField.AdditionalFollowup !== '' && inputField.AdditionalFollowup !== 0) && ( */}
                                                                        <button type="button" className="btn btn-xs btn-primary" title="Add Additional Task"
                                                                            onClick={() => handleFollowup(index)}>
                                                                            <b> {inputField.AdditionalFollowup} </b>
                                                                            <i className="fa fa-tasks"></i>
                                                                        </button>
                                                                        {/* )} */}

                                                                        &nbsp;
                                                                        <button type="button" className="btn btn-xs btn-success" title="Add Task"
                                                                            onClick={() => handleAddFields(index)}>
                                                                            <i className="fa fa-plus"></i>
                                                                        </button>
                                                                        &nbsp;
                                                                        {inputFields.length !== 1 && (
                                                                            <button type="button"
                                                                                style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}
                                                                                className="btn btn-xs btn-danger" title="Delete Task"
                                                                                onClick={() => handleRemoveFields(index)}>
                                                                                <i className="fa fa-trash-o"></i>
                                                                            </button>
                                                                        )}
                                                                    </td>
                                                                </tr>

                                                            </Fragment>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>
                            <span className="btn btn-primary"
                                onClick={
                                    () => ResetOperation("Back")
                                }>
                                <i className="fa fa-arrow-circle-left"></i>
                                &nbsp;Back</span>
                            <span className="btn btn-danger"
                                onClick={
                                    () => ResetOperation()
                                }>
                                <i className="fa fa-times"></i>
                                &nbsp;Reset</span>
                            <button type="button" className="btn btn-success" disabled={buttonLoader}
                                onClick={SaveTaskList}>

                                <i className="fa fa-check right"></i>
                                &nbsp;Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default TaskAdd;