import React, { useState, useEffect, Fragment } from "react";
import Nodify from "../../../Common/ReactNotification";
import SearchRetain from "../../../Common/SearchRetain";
import ReactNotification from 'react-notifications-component';
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import RoleService from "../../../../services/Master/RoleService";
import { useDispatch, useSelector } from "react-redux";
import Reactselect from 'react-select';
import { retrieveRole, retrieveUserType } from "../../../../actions/role";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";



const Role = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const userType = useSelector((state) => state.role.userTypeList);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [getRoleInfo, setRoleInfo] = useState({ RoleID: 0, RoleName: "", UserType: 0, Createdby: '', ExtMenuDetails: [], MenuDetails: [], MenuDetailsHeader: [{ ShowButton: 0, AddButton: 0, EditButton: 0, ViewButton: 0, DeleteButton: 0 }] });
    const dispatch = useDispatch();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    //

    const MainValues = { ...getRoleInfo };


    useEffect(() => {
        let RoleInfoValue = {};
        showLoader();
        if (props.location.state !== undefined) {
            if (props.location.state.params.length !== 0) {
                if (props.location.state.params.roleID !== 0) {
                    RoleService.GetRoleList(props.location.state.params.roleID).then((response) => {
                        if (response.data) {

                            RoleInfoValue.Operation = 2;
                            RoleInfoValue.RoleID = props.location.state.params.roleID;
                            // dispatch(retrieveRole(RoleInfoValue.RoleID));
                            RoleInfoValue.RoleName = props.location.state.params.roleName;
                            RoleInfoValue.UserType = props.location.state.params.compBuySupTableType;
                            RoleInfoValue.Createdby = currentUser.employeeinformationID;
                            let res = response.data.item2;
                            //For header
                            let outputReswithoutlastrow = [];
                            if (res.length > 0) {

                               ;
                                for (var i = 0; i < res.length; i++) {
                                    outputReswithoutlastrow.push({ validateMenu: res[i].validateMenu, userType: res[i].userType, subParentUserType: res[i].subParentUserType, menuDisplayName: res[i].menuDisplayName, roleId: res[i].roleId, roleMenuPermissionId: res[i].roleMenuPermissionId, menuId: res[i].menuId, addButton: res[i].addButton, editButton: res[i].editButton, deleteButton: res[i].deleteButton, viewButton: res[i].viewButton, showButton: res[i].showButton, quickButton: res[i].quickButton, menuChk: res[i].menuChk, isParentMenu: res[i].isParentMenu });
                                }


                            }
                            RoleInfoValue.MenuDetailsHeader = [{ showButton: 0, addButton: 0, editButton: 0, viewButton: 0, quickButton: 0 }] // Header data
                            RoleInfoValue.MenuDetails = outputReswithoutlastrow;

                            let isShow = !!RoleInfoValue.MenuDetails.find(d => d.showButton === 0);
                            if (!isShow) {
                                RoleInfoValue.MenuDetailsHeader[0].showButton = 1;
                            }
                            let isAdd = !!RoleInfoValue.MenuDetails.find(d => d.addButton === 0);
                            if (!isAdd) {
                                RoleInfoValue.MenuDetailsHeader[0].addButton = 1;
                            }
                            let isEdit = !!RoleInfoValue.MenuDetails.find(d => d.editButton === 0);
                            if (!isEdit) {
                                RoleInfoValue.MenuDetailsHeader[0].editButton = 1;
                            }
                            let isView = !!RoleInfoValue.MenuDetails.find(d => d.viewButton === 0);
                            if (!isView) {
                                RoleInfoValue.MenuDetailsHeader[0].viewButton = 1;
                            }
                            let isDelete = !!RoleInfoValue.MenuDetails.find(d => d.deleteButton === 0);
                            if (!isDelete) {
                                RoleInfoValue.MenuDetailsHeader[0].deleteButton = 1;
                            }
                            let isQuick = !!RoleInfoValue.MenuDetails.find(d => d.quickButton === 0);
                            if (!isQuick) {
                                RoleInfoValue.MenuDetailsHeader[0].quickButton = 1;
                            }
                            //

                            setRoleInfo(RoleInfoValue);
                            hideLoader();

                        }
                    });
                }

            }
        }
        dispatch(retrieveUserType());
    }, []);

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/RoleList",
            state: {
                message: Func,
            },
        }
        );
    };

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/RoleList')
        } else {
            window.location.reload();
        }
    }

    const handleChange = (e, FieldName) => {
        if (FieldName === "RoleName") {
            if (e.target.value.trim() !== '') {
                MainValues[e.target.name] = e.target.value;
            } else { MainValues[e.target.name] = ''; }

        }
        else if (FieldName === "UserType") {
            if (e !== null) { MainValues[FieldName] = e.value }
        }
        setRoleInfo(MainValues);
    }

    const handleOnSelectRecords = (event, index, FieldName) => {

        if (FieldName === 'menuChk') {
            MainValues.MenuDetails[index].menuChk = 0;
            MainValues.MenuDetails[index].showButton = 0;
            MainValues.MenuDetails[index].addButton = 0;
            MainValues.MenuDetails[index].editButton = 0;
            MainValues.MenuDetails[index].viewButton = 0;
            MainValues.MenuDetails[index].deleteButton = 0;
            MainValues.MenuDetails[index].quickButton = 0;
            if (event.target.checked) {
                MainValues.MenuDetails[index].menuChk = 1;
                MainValues.MenuDetails[index].showButton = 1;
                MainValues.MenuDetails[index].addButton = 1;
                MainValues.MenuDetails[index].editButton = 1;
                MainValues.MenuDetails[index].viewButton = 1;
                MainValues.MenuDetails[index].deleteButton = 1;
                MainValues.MenuDetails[index].quickButton = 1;

                let isShow = !!MainValues.MenuDetails.find(d => d.showButton === 0);
                if (!isShow) {
                    MainValues.MenuDetailsHeader[0].showButton = 1;
                }
                let isAdd = !!MainValues.MenuDetails.find(d => d.addButton === 0);
                if (!isAdd) {
                    MainValues.MenuDetailsHeader[0].addButton = 1;
                }
                let isEdit = !!MainValues.MenuDetails.find(d => d.editButton === 0);
                if (!isEdit) {
                    MainValues.MenuDetailsHeader[0].editButton = 1;
                }
                let isView = !!MainValues.MenuDetails.find(d => d.viewButton === 0);
                if (!isView) {
                    MainValues.MenuDetailsHeader[0].viewButton = 1;
                }
                let isDelete = !!MainValues.MenuDetails.find(d => d.deleteButton === 0);
                if (!isDelete) {
                    MainValues.MenuDetailsHeader[0].deleteButton = 1;
                }
                let isQuick = !!MainValues.MenuDetails.find(d => d.quickButton === 0);
                if (!isQuick) {
                    MainValues.MenuDetailsHeader[0].quickButton = 1;
                }


            } else {

                MainValues.MenuDetailsHeader[0].showButton = 0;
                MainValues.MenuDetailsHeader[0].addButton = 0;
                MainValues.MenuDetailsHeader[0].editButton = 0;
                MainValues.MenuDetailsHeader[0].viewButton = 0;
                MainValues.MenuDetailsHeader[0].deleteButton = 0;
                MainValues.MenuDetailsHeader[0].quickButton = 0;
            }

        }
        else {
            MainValues.MenuDetails[index][FieldName] = event.target.checked ? 1 : 0;
            if (event.target.checked) {

                let hasNotChecked = !!MainValues.MenuDetails.find(d => d[FieldName] === 0);

                if (!hasNotChecked) {
                    MainValues.MenuDetailsHeader[0][FieldName] = 1;
                }

                if (MainValues.MenuDetails[index].showButton === 1 &&
                    MainValues.MenuDetails[index].addButton === 1 &&
                    MainValues.MenuDetails[index].editButton === 1 &&
                    MainValues.MenuDetails[index].viewButton === 1 &&
                    MainValues.MenuDetails[index].deleteButton === 1 &&
                    MainValues.MenuDetails[index].quickButton === 1) {
                    MainValues.MenuDetails[index].menuChk = 1;
                }
            } else {
                MainValues.MenuDetailsHeader[0][FieldName] = 0;
                MainValues.MenuDetails[index].menuChk = 0;
            }
        }
        //debugger;
        //BindChildParent(MainValues.MenuDetails, index, event);
        setRoleInfo(MainValues);


    };

    const handleOnSelectRecordsHeader = (event, FieldName) => {

        for (var i = 0; i < MainValues.MenuDetails.length; i++) {
            if (event.target.checked) {
                MainValues.MenuDetails[i][FieldName] = 1;

                if (MainValues.MenuDetails[i].showButton === 1 &&
                    MainValues.MenuDetails[i].addButton === 1 &&
                    MainValues.MenuDetails[i].editButton === 1 &&
                    MainValues.MenuDetails[i].viewButton === 1 &&
                    MainValues.MenuDetails[i].deleteButton === 1 &&
                    MainValues.MenuDetails[i].quickButton === 1) {
                    MainValues.MenuDetails[i].menuChk = 1;
                }

            }
            else {
                if (MainValues.MenuDetails[i].validateMenu !== 'Dashboard') { MainValues.MenuDetails[i][FieldName] = 0; }
                else { MainValues.MenuDetails[i][FieldName] = 1; }
                // MainValues.MenuDetails[i][FieldName] = 0;
                MainValues.MenuDetails[i].menuChk = 0;
            }

        }
        // //For header
        // for (var k = 0; k < MainValues.MenuDetailsHeader.length; k++) {
        //     if (event.target.checked) { MainValues.MenuDetailsHeader[k][FieldName] = 1; }
        //     else { MainValues.MenuDetailsHeader[k][FieldName] = 0; }
        // }
        // //
        if (event.target.checked) {
            MainValues.MenuDetailsHeader[0][FieldName] = 1;
        }
        else {

            MainValues.MenuDetailsHeader[0][FieldName] = 0;
        }
        setRoleInfo(MainValues);
    };



    const SaveRole = (e) => {
        setButtonLoader(true);
        e.preventDefault();
        if (MainValues.RoleName.trim() === '') {
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Role is required');
        }
        else {

            // let MenuValidate = false;
            //  for (var i = 0; i < MainValues.MenuDetails.length; i++) {
            //     if (MainValues.MenuDetails[i].addButton === 1 || MainValues.MenuDetails[i].editButton === 1 || MainValues.MenuDetails[i].deleteButton === 1 || MainValues.MenuDetails[i].viewButton || MainValues.MenuDetails[i].showButton === 1) { MenuValidate = true; }
            //      // MainValues.ExtMenuDetails.push({ roleMenuPermissionId: MainValues.MenuDetails[i].roleMenuPermissionId, menuId: MainValues.MenuDetails[i].menuId, addButton: MainValues.MenuDetails[i].addButton, editButton: MainValues.MenuDetails[i].editButton, deleteButton: MainValues.MenuDetails[i].deleteButton, viewButton: MainValues.MenuDetails[i].viewButton, showButton: MainValues.MenuDetails[i].showButton, menuChk: MainValues.MenuDetails[i].menuChk });

            // }
            // if (!MenuValidate) {
            //     setButtonLoader(false);
            //     Nodify('Warning!', 'warning', 'Please fill the menu permission');
            //     MainValues.MenuDetails = [];
            //     setSubmitted(true);
            // }
            // else {

            BindChildParent(MainValues.MenuDetails);
           ;
            MainValues.CompBuySupTableType = MainValues.UserType;
            RoleService.InsertUpdateRole(MainValues).then(res => {
                var page = "Remove";
                SearchRetain(page);
                let Func = 'Add';
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "-2") {
                    MainValues.RoleName = '';
                    //MainValues.MenuDetails = [];
                    setRoleInfo(MainValues);
                    Nodify('Warning!', 'warning', 'This Role is already exist.');
                }
                setButtonLoader(false);
            });
            // }

        }
        // if (MainValues.RoleName.trim()) {
        //     MainValues.RoleName = MainValues.RoleName.trim();
        //     MainValues.CompBuySupTableType = MainValues.UserType;
        //     // //For Header
        //     // MainValues.MenuDetails.push({ roleId: MainValues.MenuDetailsHeader[0].roleId, roleMenuPermissionId: MainValues.MenuDetailsHeader[0].roleMenuPermissionId, menuId: MainValues.MenuDetailsHeader[0].menuId, addButton: MainValues.MenuDetailsHeader[0].addButton, editButton: MainValues.MenuDetailsHeader[0].editButton, deleteButton: MainValues.MenuDetailsHeader[0].deleteButton, viewButton: MainValues.MenuDetailsHeader[0].viewButton, showButton: MainValues.MenuDetailsHeader[0].showButton, menuChk: MainValues.MenuDetailsHeader[0].menuChk });
        //     // //
        //     RoleService.InsertUpdateRole(MainValues).then(res => {
        //         var page = "Remove";
        //         SearchRetain(page);
        //         let Func = 'Add';
        //         if (res.data.outputResult === "1") {
        //             Func = 'Add';
        //             PageRedirect(Func);
        //         } else if (res.data.outputResult === "2") {
        //             Func = 'Edit';
        //             PageRedirect(Func);
        //         } else if (res.data.outputResult === "-2") {
        //             MainValues.RoleName = '';
        //             setRoleInfo(MainValues);
        //             Nodify('Warning!', 'warning', 'This Role is already exist.');
        //         }
        //         setButtonLoader(false);
        //     });
        // }
        // else {
        //     setSubmitted(true);
        // }
    }

    return (
        <div className="page-body">
            <ReactNotification />

            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">
                                Edit Role
                            </h3>
                            <div className="buttons-preview" align="right">
                                <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                    <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                                </span>
                                <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                    <i className="fa fa-times"></i>&nbsp;Reset
                                </span>
                                <button
                                    type="submit"
                                    className="btn btn-success"
                                    onClick={SaveRole}
                                    disabled={buttonLoader}
                                >

                                    <i className="fa fa-check right"></i> &nbsp; Update
                                </button>
                            </div>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <Form>
                                    <div className="row">
                                        <div className="form-role col-sm-4">
                                            <label htmlFor="RoleName">
                                                Role<span className="text-danger">*</span>
                                            </label>
                                            <span className="input-icon icon-right">
                                                <Input
                                                    placeholder={"Enter Role"}
                                                    id="RoleName"
                                                    name="RoleName"
                                                    value={MainValues.RoleName}
                                                    type="text"
                                                    maxLength="50"
                                                    autoComplete="off"
                                                    onChange={event => handleChange(event, 'RoleName')}
                                                    className='form-control'
                                                    style={{ border: submitted && !getRoleInfo.RoleName ? '1px solid red' : '' }}
                                                    autoFocus
                                                />
                                            </span>

                                        </div>
                                        <div className="col-sm-3 divder_style">
                                            <div className="form-group">
                                                <label htmlFor="UserType" >User Type <span className="text-danger">*</span></label>
                                                <span className='input-icon icon-right'>
                                                    <Reactselect name="UserType" className="basic-single"
                                                        id='UserType'
                                                        isDisabled={false}
                                                        isLoading={false}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        onChange={event => handleChange(event, 'UserType')}
                                                        value={userType.filter(function (option) {
                                                            return option.value === MainValues.UserType;
                                                        })}
                                                        options={userType}
                                                    ></Reactselect>
                                                </span>



                                            </div>
                                        </div>

                                        {MainValues.MenuDetails.length > 0 ?



                                            <div className="col-md-12" style={{ overflowX: "auto", minHeight: '100px' }}>
                                                <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                                    <thead>
                                                        <tr>
                                                            {/* <th style={{ width: "210px" }} className="fixed-column-header-first-tna"> #</th> */}
                                                            <th style={{ width: "300px" }}>Menu</th>
                                                            <th style={{ width: "120px" }}>
                                                                <label style={{ marginTop: '8px' }}>
                                                                    <input type="checkbox" name="ShowButtonHeader"
                                                                        value={MainValues.MenuDetailsHeader[0].showButton}
                                                                        onChange={event => (handleOnSelectRecordsHeader(event, 'showButton'))}
                                                                        checked={MainValues.MenuDetailsHeader[0].showButton === 1 ? true : false}
                                                                        className="clearOpacity" title="Show Menu" />
                                                                    <span className="text">List</span>
                                                                </label>

                                                            </th>
                                                            <th style={{ width: "40px" }}>
                                                                <label style={{ marginTop: '8px' }}>
                                                                    <input type="checkbox" name="AddButton"
                                                                        value={MainValues.MenuDetailsHeader[0].addButton}
                                                                        onChange={event => (handleOnSelectRecordsHeader(event, 'addButton'))}
                                                                        checked={MainValues.MenuDetailsHeader[0].addButton === 1 ? true : false}
                                                                        className="clearOpacity" title="Add" />
                                                                    <span className="text">Add</span>
                                                                </label></th>
                                                            <th style={{ width: "40px" }}>
                                                                <label style={{ marginTop: '8px' }}>
                                                                    <input type="checkbox" name="EditButton"
                                                                        value={MainValues.MenuDetailsHeader[0].editButton}
                                                                        onChange={event => (handleOnSelectRecordsHeader(event, 'editButton'))}
                                                                        checked={MainValues.MenuDetailsHeader[0].editButton === 1 ? true : false}
                                                                        className="clearOpacity" title="Edit" />
                                                                    <span className="text">Edit</span>
                                                                </label></th>
                                                            <th style={{ width: "40px" }}>
                                                                <label style={{ marginTop: '8px' }}>
                                                                    <input type="checkbox" name="ViewButton"
                                                                        value={MainValues.MenuDetailsHeader[0].viewButton}
                                                                        onChange={event => (handleOnSelectRecordsHeader(event, 'viewButton'))}
                                                                        checked={MainValues.MenuDetailsHeader[0].viewButton === 1 ? true : false}
                                                                        className="clearOpacity" title="View" />
                                                                    <span className="text">View</span>
                                                                </label></th>
                                                            <th style={{ width: "40px" }}>
                                                                <label style={{ marginTop: '8px' }}>
                                                                    <input type="checkbox" name="DeleteButton"
                                                                        value={MainValues.MenuDetailsHeader[0].deleteButton}
                                                                        onChange={event => (handleOnSelectRecordsHeader(event, 'deleteButton'))}
                                                                        checked={MainValues.MenuDetailsHeader[0].deleteButton === 1 ? true : false}
                                                                        className="clearOpacity" title="Delete" />
                                                                    <span className="text">Delete</span>
                                                                </label></th>
                                                            <th style={{ width: "1040px" }}>
                                                                <label style={{ marginTop: '8px' }}>
                                                                    <input type="checkbox" name="quickButton"
                                                                        value={MainValues.MenuDetailsHeader[0].quickButton}
                                                                        onChange={event => (handleOnSelectRecordsHeader(event, 'quickButton'))}
                                                                        checked={MainValues.MenuDetailsHeader[0].quickButton === 1 ? true : false}
                                                                        className="clearOpacity textdesign" title="Quick Master" />
                                                                    <span className="text">Quick Master</span>

                                                                </label></th>


                                                        </tr>


                                                    </thead>
                                                    <tbody>
                                                        {MainValues.MenuDetails && (MainValues.MenuDetails).map((inputField, index) => (
                                                            <Fragment key={`${inputField}~${index}`}>

                                                                <tr class="parent" data-child={"parent_" + index}>

                                                                    {
                                                                        inputField.isParentMenu === 1 ?
                                                                            inputField.menuDisplayName === 'Dashboard' ?
                                                                                <>

                                                                                    <td colSpan='1' style={{ backgroundColor: "#ffffff" }}>
                                                                                        <span style={{ color: "#333333" }} className='input-icon icon-right text'>
                                                                                            {inputField.menuDisplayName}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td colSpan='6' style={{ backgroundColor: "#ffffff" }}>
                                                                                        <div style={{ width: "80px" }}>
                                                                                            <label>
                                                                                                <input type="checkbox" name="ShowButton"
                                                                                                    value={inputField.showButton}
                                                                                                    onChange={event => (handleOnSelectRecords(event, index, 'ShowButton'))}
                                                                                                    checked={inputField.showButton === 1 ? true : false}
                                                                                                    className="clearOpacity textdesign"
                                                                                                    disabled />
                                                                                                <span title={inputField.menuDisplayName} className="text"></span>
                                                                                            </label>

                                                                                        </div>

                                                                                    </td>
                                                                                </>

                                                                                :

                                                                                <td colSpan='7' style={{ backgroundColor: "#222d32" }}>
                                                                                    <span style={{ color: "#FFF" }} className='input-icon icon-right text'>
                                                                                        {inputField.menuDisplayName}
                                                                                    </span>
                                                                                </td>
                                                                            //     <td colSpan='6' style={{ backgroundColor: "#222d32" }}>
                                                                            //         <span style={{ color: "#FFF" }} className='input-icon icon-right text'>
                                                                            //             {inputField.menuDisplayName}
                                                                            //         </span>
                                                                            //     </td>
                                                                            : (inputField.isParentMenu === 0 || inputField.isParentMenu === 4) ?
                                                                                <td>
                                                                                    {/* <span className='input-icon icon-right'>
                                                                                    <b>{inputField.menuDisplayName}</b>
                                                                                </span> */}
                                                                                    <div>
                                                                                        <label style={{ marginTop: '8px', marginLeft: inputField.isParentMenu === 4 ? '25px' : '1px' }}>
                                                                                            <input type="checkbox" name="MenuChk" title={inputField.menuDisplayName}
                                                                                                value={inputField.menuChk}
                                                                                                onChange={event => (handleOnSelectRecords(event, index, 'menuChk'))}
                                                                                                checked={inputField.menuChk === 1 ? true : false}
                                                                                                className="clearOpacity" />
                                                                                            <span title={inputField.menuDisplayName} className="text">{inputField.menuDisplayName}</span>
                                                                                        </label>

                                                                                    </div>
                                                                                </td>
                                                                                : ''



                                                                    }
                                                                    {
                                                                        (inputField.isParentMenu === 0 || inputField.isParentMenu === 4) &&
                                                                        <>


                                                                            {inputField.validateMenu === 'RouteExport' ||
                                                                                inputField.validateMenu === 'TNAActivate' ||
                                                                                inputField.validateMenu === 'TNACloseComplate' ||
                                                                                inputField.validateMenu === 'TNAComment' ||
                                                                                inputField.validateMenu === 'TNAAttachment' ||
                                                                                inputField.validateMenu === 'TNAstartstop' ||
                                                                                inputField.ValidateMenu === 'TNAReportActivate' ||
                                                                                inputField.ValidateMenu === 'TNAReportCloseComplate' ||
                                                                                inputField.ValidateMenu === 'TNAReportComment' ||
                                                                                inputField.ValidateMenu === 'TNAReportAttachment' ||
                                                                                inputField.ValidateMenu === 'TNAReportStartStop' ?
                                                                                <>
                                                                                    <td>
                                                                                        <div style={{ width: "80px" }}>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div style={{ width: "80px" }}>
                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                <input type="checkbox" name="addButton"
                                                                                                    value={inputField.addButton}
                                                                                                    onChange={event => (handleOnSelectRecords(event, index, 'addButton'))}
                                                                                                    checked={inputField.addButton === 1 ? true : false}
                                                                                                    className="clearOpacity" />
                                                                                                <span title='Add' className="text"></span>
                                                                                            </label>

                                                                                        </div>

                                                                                    </td>
                                                                                    <td>
                                                                                        <div style={{ width: "80px" }}>
                                                                                        </div>
                                                                                    </td><td>
                                                                                        <div style={{ width: "80px" }}>
                                                                                        </div>
                                                                                    </td><td>
                                                                                        <div style={{ width: "80px" }}>
                                                                                        </div>
                                                                                    </td><td>
                                                                                        <div style={{ width: "80px" }}>
                                                                                        </div>
                                                                                    </td>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <td>
                                                                                        <div style={{ width: "80px" }}>
                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                <input type="checkbox" name="showButton"
                                                                                                    value={inputField.showButton}
                                                                                                    onChange={event => (handleOnSelectRecords(event, index, 'showButton'))}
                                                                                                    checked={inputField.showButton === 1 ? true : false}
                                                                                                    className="clearOpacity" />
                                                                                                <span title='Show' className="text"></span>
                                                                                            </label>

                                                                                        </div>

                                                                                    </td>
                                                                                    <td>
                                                                                        <div style={{ width: "80px" }}>
                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                <input type="checkbox" name="addButton"
                                                                                                    value={inputField.addButton}
                                                                                                    onChange={event => (handleOnSelectRecords(event, index, 'addButton'))}
                                                                                                    checked={inputField.addButton === 1 ? true : false}
                                                                                                    className="clearOpacity" />
                                                                                                <span title='Add' className="text"></span>
                                                                                            </label>

                                                                                        </div>

                                                                                    </td>
                                                                                    <td>
                                                                                        <div style={{ width: "80px" }}>
                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                <input type="checkbox" name="editButton"
                                                                                                    value={inputField.editButton}
                                                                                                    onChange={event => (handleOnSelectRecords(event, index, 'editButton'))}
                                                                                                    checked={inputField.editButton === 1 ? true : false}
                                                                                                    className="clearOpacity" />
                                                                                                <span title='Edit' className="text"></span>
                                                                                            </label>

                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div style={{ width: "80px" }}>
                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                <input type="checkbox" name="viewButton"
                                                                                                    value={inputField.viewButton}
                                                                                                    onChange={event => (handleOnSelectRecords(event, index, 'viewButton'))}
                                                                                                    checked={inputField.viewButton === 1 ? true : false}
                                                                                                    className="clearOpacity" />
                                                                                                <span title='View' className="text"></span>
                                                                                            </label>

                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div style={{ width: "80px" }}>
                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                <input type="checkbox" name="deleteButton"
                                                                                                    value={inputField.deleteButton}
                                                                                                    onChange={event => (handleOnSelectRecords(event, index, 'deleteButton'))}
                                                                                                    checked={inputField.deleteButton === 1 ? true : false}
                                                                                                    className="clearOpacity" />
                                                                                                <span title='Delete' className="text"></span>
                                                                                            </label>

                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div style={{ width: "80px" }}>
                                                                                            {/* {inputField.ValidateMenu} */}
                                                                                            {(inputField.validateMenu === 'Season' || inputField.validateMenu === 'Branch' || inputField.validateMenu === 'City' || inputField.validateMenu === 'Gender'
                                                                                                || inputField.ValidateMenu === 'Pantone' || inputField.validateMenu === 'GroupType' || inputField.validateMenu === 'Category' || inputField.validateMenu === 'MeasurementPoint' ||
                                                                                                inputField.validateMenu === 'FabricType' || inputField.validateMenu === 'Role' || inputField.validateMenu === 'City' || inputField.validateMenu === 'FabricPart' ||
                                                                                                inputField.validateMenu === 'Swatch' || inputField.validateMenu === 'Department' || inputField.validateMenu === 'GroupSize' ||
                                                                                                inputField.validateMenu === 'PantoneType' || inputField.validateMenu === 'Size' || inputField.validateMenu === 'ProcessType') &&
                                                                                                <label style={{ marginTop: '8px' }}>
                                                                                                    <input type="checkbox" name="quickButton"
                                                                                                        value={inputField.quickButton}
                                                                                                        onChange={event => (handleOnSelectRecords(event, index, 'quickButton'))}
                                                                                                        checked={inputField.quickButton === 1 ? true : false}
                                                                                                        className="clearOpacity textdesign" />
                                                                                                    <span title={"Quick Master - " + inputField.menuDisplayName} className="text"></span>
                                                                                                </label>
                                                                                            }


                                                                                        </div>
                                                                                    </td>

                                                                                </>

                                                                            }


                                                                        </>
                                                                    }

                                                                </tr>
                                                            </Fragment>
                                                        ))
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                            :
                                            <div className="col-md-12" style={{ overflowX: "auto", minHeight: '100px', marginTop:"4rem", textAlign:"center" }}>
                                                <span className="red">No Records Found</span>
                                            </div>
                                        }
                                    </div>


                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div
                        className="buttons-preview"
                        align="right"
                        style={{ marginTop: "15px" }}
                    >
                        <div className="buttons-preview" align="right">
                            <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                            </span>
                            <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                <i className="fa fa-times"></i>&nbsp;Reset
                            </span>
                            <button
                                type="submit"
                                className="btn btn-success"
                                onClick={SaveRole}
                                disabled={buttonLoader}
                            >

                                <i className="fa fa-check right"></i> &nbsp;Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Role;

// function BindChildParent(UserRightsValue) {
//     let ParentList;
//     let childParentList;
//     let isShowGenLab, isShowGenStrike, isShowGenTrim;
//     let isShowStyle, isShowOrdLab, isShowOrdStrike, isShowOrdTrim, isShowPurchase, isShowRoute, isShowTNA, isShowTNAReport;
//     let isShowStrikeMaster, isShowTrimMaster;
//     let isShowCompany, isShowSupplier, isShowBuyerList, isShowBuyerInfo;
//     let isShowGroup, isShowCategory, isShowStrikeOffType, isShowTrimType, isShowMeasurement, isShowGroupFType, isShowGroupFPart;
//     let isShowSwatch, isShowRole, isShowPermission, isShowDepartment, isShowSize, isShowGroupSize, isShowProcess;
//     let isShowTaskType, isShowTask;



//     for (var index = 0; index < UserRightsValue.length; index++) {

//         //General(sampling)
//         if (UserRightsValue[index].menuDisplayName === 'General Lab Dip' || UserRightsValue[index].menuDisplayName === 'General Strike Off List' || UserRightsValue[index].menuDisplayName === 'General Trim List') {
//             if (!isShowGenLab && !isShowGenStrike && !isShowGenTrim) {
//                 isShowGenLab = UserRightsValue.find(m => m.menuDisplayName === 'General Lab Dip' && m.showButton === 1);
//                 isShowGenStrike = UserRightsValue.find(m => m.menuDisplayName === 'General Strike Off List' && m.showButton === 1);
//                 isShowGenTrim = UserRightsValue.find(m => m.menuDisplayName === 'General Trim List' && m.showButton === 1);

//                 ParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Sampling' && d.subParentUserType === 77);
//                 childParentList = UserRightsValue.filter(d => d.menuDisplayName === 'General' && d.userType === 2 && d.subParentUserType === 1);
//                 if (isShowGenLab || isShowGenStrike || isShowGenTrim) {
//                     // alert(0);
//                     if (childParentList.length > 0) { childParentList[0].showButton = 1 }
//                     if (ParentList.length > 0) { ParentList[0].showButton = 1 }
//                 }
//                 // else {
//                 //     if (childParentList.length > 0) { childParentList[0].showButton = 0 }
//                 //     if (ParentList.length > 0) { ParentList[0].showButton = 0 }
//                 // }
//             }
//         }
//         //Order(sampling)
//         else if (UserRightsValue[index].menuDisplayName === 'Style' || UserRightsValue[index].menuDisplayName === 'Order Lab Dip' || UserRightsValue[index].menuDisplayName === 'Order Strike Off List' || UserRightsValue[index].menuDisplayName === 'Order Trim List' || UserRightsValue[index].menuDisplayName === 'Purchase Order' || UserRightsValue[index].menuDisplayName === 'Route' || UserRightsValue[index].menuDisplayName === 'TNA' || UserRightsValue[index].menuDisplayName === 'TNA Report') {
//             if (!isShowStyle && !isShowOrdLab && !isShowOrdStrike && !isShowOrdTrim && !isShowPurchase && !isShowRoute && !isShowTNA && !isShowTNAReport) {
//                 // alert(1);
//                 isShowStyle = UserRightsValue.find(m => m.menuDisplayName === 'Style' && m.showButton === 1);
//                 isShowOrdLab = UserRightsValue.find(m => m.menuDisplayName === 'Order Strike Off List' && m.showButton === 1);
//                 isShowOrdStrike = UserRightsValue.find(m => m.menuDisplayName === 'Order Trim List' && m.showButton === 1);
//                 isShowOrdTrim = UserRightsValue.find(m => m.menuDisplayName === 'Order Trim List' && m.showButton === 1);
//                 isShowPurchase = UserRightsValue.find(m => m.menuDisplayName === 'Purchase Order' && m.showButton === 1);
//                 isShowRoute = UserRightsValue.find(m => m.menuDisplayName === 'Route' && m.showButton === 1);
//                 isShowTNA = UserRightsValue.find(m => m.menuDisplayName === 'TNA' && m.showButton === 1);
//                 isShowTNAReport = UserRightsValue.find(m => m.menuDisplayName === 'TNA Report' && m.showButton === 1);

//                 ParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Sampling' && d.subParentUserType === 77);
//                 childParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Order' && d.userType === 2 && d.subParentUserType === 1);
//                 if (isShowStyle || isShowOrdLab || isShowOrdStrike || isShowOrdTrim || isShowPurchase || isShowRoute || isShowTNA || isShowTNAReport) {
//                     if (childParentList.length > 0) { childParentList[0].showButton = 1 }
//                     if (ParentList.length > 0) { ParentList[0].showButton = 1 }
//                 }
//                 // else {
//                 //     if (childParentList.length > 0) { childParentList[0].showButton = 0 }
//                 //     if (ParentList.length > 0) { ParentList[0].showButton = 0 }
//                 // }
//             }

//         }
//         //Component(sampling)
//         else if (UserRightsValue[index].menuDisplayName === 'Strike off Master' || UserRightsValue[index].menuDisplayName === 'Trim Master') {
//             if (!isShowStrikeMaster && !isShowTrimMaster) {
//                 isShowStrikeMaster = UserRightsValue.find(m => m.menuDisplayName === 'Strike off Master' && m.showButton === 1);
//                 isShowTrimMaster = UserRightsValue.find(m => m.menuDisplayName === 'Trim Master' && m.showButton === 1);
//                 ParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Sampling' && d.subParentUserType === 77);
//                 childParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Component' && d.userType === 2 && d.subParentUserType === 1);

//                 if (isShowStrikeMaster || isShowTrimMaster) {
//                     if (childParentList.length > 0) { childParentList[0].showButton = 1 }
//                     if (ParentList.length > 0) { ParentList[0].showButton = 1 }
//                 }
//             }
//         }
//         //Company Info(Master)
//         else if (UserRightsValue[index].menuDisplayName === 'Company') {
//             if (!isShowCompany) {
//                ;
//                 isShowCompany = UserRightsValue.find(m => m.menuDisplayName === 'Company' && m.showButton === 1);
//                 ParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Master' && d.subParentUserType === 77);
//                 childParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Company Info' && d.userType === 2 && d.subParentUserType === 3);
//                 if (isShowCompany) {
//                     if (childParentList.length > 0) { childParentList[0].showButton = 1 }
//                     if (ParentList.length > 0) { ParentList[0].showButton = 1 }
//                 }
//             }
//         }

//         //Buyer Info(Master)
//         else if (UserRightsValue[index].menuDisplayName === 'Buyer List' || UserRightsValue[index].menuDisplayName === 'Buyer Info') {
//             if (!isShowBuyerList && !isShowBuyerInfo) {
//                 isShowBuyerList = UserRightsValue.find(m => m.menuDisplayName === 'Buyer List' && m.showButton === 1);
//                 isShowBuyerInfo = UserRightsValue.find(m => m.menuDisplayName === 'Buyer Info' && m.showButton === 1);
//                 ParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Master' && d.subParentUserType === 77);
//                 childParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Buyer Info' && d.userType === 2 && d.subParentUserType === 3);
//                 if (isShowBuyerList || isShowBuyerInfo) {
//                     if (childParentList.length > 0) { childParentList[0].showButton = 1 }
//                     if (ParentList.length > 0) { ParentList[0].showButton = 1 }
//                 }
//             }
//         }

//         //Supplier(Master)
//         else if (UserRightsValue[index].menuDisplayName === 'Supplier') {
//             if (!isShowSupplier) {
//                 isShowSupplier = UserRightsValue.find(m => m.menuDisplayName === 'Supplier' && m.showButton === 1);
//                 ParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Master' && d.subParentUserType === 77);
//                 childParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Supplier Info' && d.userType === 2 && d.subParentUserType === 3);
//                 if (isShowSupplier) {
//                     if (childParentList.length > 0) { childParentList[0].showButton = 1 }
//                     if (ParentList.length > 0) { ParentList[0].showButton = 1 }
//                 }
//             }
//         }


//         //General(Master)
//         else if (UserRightsValue[index].menuDisplayName === 'Branch' || UserRightsValue[index].menuDisplayName === 'City' || UserRightsValue[index].menuDisplayName === 'Season' || UserRightsValue[index].menuDisplayName === 'Gender' || UserRightsValue[index].menuDisplayName === 'Currency') {
//             if (!isShowBuyerList && !isShowBuyerInfo) {
//                 isShowBuyerList = UserRightsValue.find(m => m.menuDisplayName === 'Buyer List' && m.showButton === 1);
//                 isShowBuyerInfo = UserRightsValue.find(m => m.menuDisplayName === 'Buyer Info' && m.showButton === 1);
//                 ParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Master' && d.subParentUserType === 77);
//                 childParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Buyer Info' && d.userType === 2 && d.subParentUserType === 3);
//                 if (isShowBuyerList || isShowBuyerInfo) {
//                     if (childParentList.length > 0) { childParentList[0].showButton = 1 }
//                     if (ParentList.length > 0) { ParentList[0].showButton = 1 }
//                 }
//             }
//         }

//         //Pantone Info(Master)
//         else if (UserRightsValue[index].menuDisplayName === 'Pantone' || UserRightsValue[index].menuDisplayName === 'Pantone Type') {
//             if (!isShowBuyerList && !isShowBuyerInfo) {
//                 isShowBuyerList = UserRightsValue.find(m => m.menuDisplayName === 'Buyer List' && m.showButton === 1);
//                 isShowBuyerInfo = UserRightsValue.find(m => m.menuDisplayName === 'Buyer Info' && m.showButton === 1);
//                 ParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Master' && d.subParentUserType === 77);
//                 childParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Buyer Info' && d.userType === 2 && d.subParentUserType === 3);
//                 if (isShowBuyerList || isShowBuyerInfo) {
//                     if (childParentList.length > 0) { childParentList[0].showButton = 1 }
//                     if (ParentList.length > 0) { ParentList[0].showButton = 1 }
//                 }
//             }
//         }

//         //Component(Master)
//         else if (UserRightsValue[index].menuDisplayName === 'Group Type' || UserRightsValue[index].menuDisplayName === 'Category' || UserRightsValue[index].menuDisplayName === 'Strike Off Type' || UserRightsValue[index].menuDisplayName === 'Trim Type' || UserRightsValue[index].menuDisplayName === 'Measurement Point' || UserRightsValue[index].menuDisplayName === 'Fabric Type' || UserRightsValue[index].menuDisplayName === 'Fabric Part') {
//             if (!isShowGroup && !isShowCategory && !isShowStrikeOffType && !isShowTrimType && !isShowMeasurement && !isShowGroupFType && !isShowGroupFPart) {

//                 isShowGroup = UserRightsValue.find(m => m.menuDisplayName === 'Group Type' && m.showButton === 1);
//                 isShowCategory = UserRightsValue.find(m => m.menuDisplayName === 'Category' && m.showButton === 1);
//                 isShowStrikeOffType = UserRightsValue.find(m => m.menuDisplayName === 'Strike Off Type' && m.showButton === 1);
//                 isShowTrimType = UserRightsValue.find(m => m.menuDisplayName === 'Trim Type' && m.showButton === 1);
//                 isShowMeasurement = UserRightsValue.find(m => m.menuDisplayName === 'Measurement Point' && m.showButton === 1);
//                 isShowGroupFType = UserRightsValue.find(m => m.menuDisplayName === 'Fabric Type' && m.showButton === 1);
//                 isShowGroupFPart = UserRightsValue.find(m => m.menuDisplayName === 'Fabric Part' && m.showButton === 1);

//                 ParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Master' && d.subParentUserType === 77);
//                 childParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Component' && d.userType === 2 && d.subParentUserType === 3);
//                 if (isShowGroup || isShowCategory || isShowStrikeOffType || isShowTrimType || isShowMeasurement || isShowGroupFType || isShowGroupFPart) {
//                     if (childParentList.length > 0) { childParentList[0].showButton = 1 }
//                     if (ParentList.length > 0) { ParentList[0].showButton = 1 }
//                 }
//             }
//         }

//         //Component1(Master)
//         else if (UserRightsValue[index].menuDisplayName === 'Swatch' || UserRightsValue[index].menuDisplayName === 'Role' || UserRightsValue[index].menuDisplayName === 'User Permission' || UserRightsValue[index].menuDisplayName === 'Department' || UserRightsValue[index].menuDisplayName === 'Size' || UserRightsValue[index].menuDisplayName === 'Group Size' || UserRightsValue[index].menuDisplayName === 'Process Type') {
//             if (!isShowSwatch && !isShowRole && !isShowPermission && !isShowDepartment && !isShowSize && !isShowGroupSize && !isShowProcess) {

//                 isShowBuyerList = UserRightsValue.find(m => m.menuDisplayName === 'Swatch' && m.showButton === 1);
//                 isShowBuyerInfo = UserRightsValue.find(m => m.menuDisplayName === 'Role' && m.showButton === 1);
//                 isShowBuyerList = UserRightsValue.find(m => m.menuDisplayName === 'User Permission' && m.showButton === 1);
//                 isShowBuyerInfo = UserRightsValue.find(m => m.menuDisplayName === 'Department' && m.showButton === 1);
//                 isShowBuyerList = UserRightsValue.find(m => m.menuDisplayName === 'Size' && m.showButton === 1);
//                 isShowBuyerInfo = UserRightsValue.find(m => m.menuDisplayName === 'Group Size' && m.showButton === 1);
//                 isShowBuyerList = UserRightsValue.find(m => m.menuDisplayName === 'Process Type' && m.showButton === 1);


//                 ParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Master' && d.subParentUserType === 77);
//                 childParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Component 1' && d.userType === 2 && d.subParentUserType === 3);
//                 if (isShowSwatch || isShowRole || isShowPermission || isShowDepartment || isShowSize || isShowGroupSize || isShowProcess) {
//                     if (childParentList.length > 0) { childParentList[0].showButton = 1 }
//                     if (ParentList.length > 0) { ParentList[0].showButton = 1 }
//                 }
//             }
//         }

//         //Task Info(Master)
//         else if (UserRightsValue[index].menuDisplayName === 'Task Type' || UserRightsValue[index].menuDisplayName === 'Task') {
//             if (!isShowTaskType && !isShowTask) {
//                 isShowTaskType = UserRightsValue.find(m => m.menuDisplayName === 'Task Type' && m.showButton === 1);
//                 isShowTask = UserRightsValue.find(m => m.menuDisplayName === 'Task' && m.showButton === 1);
//                 ParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Master' && d.subParentUserType === 77);
//                 childParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Task Info' && d.userType === 2 && d.subParentUserType === 3);
//                 if (isShowTaskType || isShowTask) {
//                     if (childParentList.length > 0) { childParentList[0].showButton = 1 }
//                     if (ParentList.length > 0) { ParentList[0].showButton = 1 }
//                 }
//             }
//         }

//     }



// }


function BindChildParent(UserRightsValue) {
    let ParentList;
    let childParentList;
    let isShowGenLab, isShowGenStrike, isShowGenTrim;
    let isShowStyle, isShowOrdLab, isShowOrdStrike, isShowOrdTrim, isShowPurchase, isShowRoute, isShowTNA, isShowTNAReport;
    let isShowStrikeMaster, isShowTrimMaster;
    let isShowCompany, isShowSupplier, isShowBuyerList, isShowBuyerInfo;
    let isShowGroup, isShowCategory, isShowStrikeOffType, isShowTrimType, isShowMeasurement, isShowGroupFType, isShowGroupFPart;
    let isShowSwatch, isShowRole, isShowPermission, isShowDepartment, isShowSize, isShowGroupSize, isShowProcess;
    let isShowBranch, isShowCity, isShowSeason, isShowGender, isShowCurrency;
    let isShowTaskType, isShowTask;



    for (var index = 0; index < UserRightsValue.length; index++) {

        //General(sampling)
        if (UserRightsValue[index].validateMenu === 'LabDip' || UserRightsValue[index].validateMenu === 'StrikeOffList' || UserRightsValue[index].validateMenu === 'TrimList') {
            if (!isShowGenLab && !isShowGenStrike && !isShowGenTrim) {
                isShowGenLab = UserRightsValue.find(m => m.validateMenu === 'LabDip' && m.showButton === 1);
                isShowGenStrike = UserRightsValue.find(m => m.validateMenu === 'StrikeOffList' && m.showButton === 1);
                isShowGenTrim = UserRightsValue.find(m => m.validateMenu === 'TrimList' && m.showButton === 1);

                ParentList = UserRightsValue.filter(d => d.validateMenu === 'Sampling' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.validateMenu === 'SamplingGeneral' && d.userType === 2 && d.subParentUserType === 1);
                if (isShowGenLab || isShowGenStrike || isShowGenTrim) {
                    // alert(0);
                    if (childParentList.length > 0) { childParentList[0].ShowButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].ShowButton = 1 }
                }
                // else {
                //     if (childParentList.length > 0) { childParentList[0].ShowButton = 0 }
                //     if (ParentList.length > 0) { ParentList[0].ShowButton = 0 }
                // }
            }
        }
        //Order(sampling)
        else if (UserRightsValue[index].validateMenu === 'Style' || UserRightsValue[index].validateMenu === 'OrderLabDip' || UserRightsValue[index].validateMenu === 'OrderStrikeOff' || UserRightsValue[index].validateMenu === 'OrderTrim' || UserRightsValue[index].validateMenu === 'PurchaseOrder' || UserRightsValue[index].validateMenu === 'Route' || UserRightsValue[index].validateMenu === 'TNA' || UserRightsValue[index].validateMenu === 'TNAReport') {
            if (!isShowStyle && !isShowOrdLab && !isShowOrdStrike && !isShowOrdTrim && !isShowPurchase && !isShowRoute && !isShowTNA && !isShowTNAReport) {
                // alert(1);
                isShowStyle = UserRightsValue.find(m => m.validateMenu === 'Style' && m.showButton === 1);
                isShowOrdLab = UserRightsValue.find(m => m.validateMenu === 'OrderLabDip' && m.showButton === 1);
                isShowOrdStrike = UserRightsValue.find(m => m.validateMenu === 'OrderStrikeOff' && m.showButton === 1);
                isShowOrdTrim = UserRightsValue.find(m => m.validateMenu === 'OrderTrim' && m.showButton === 1);
                isShowPurchase = UserRightsValue.find(m => m.validateMenu === 'PurchaseOrder' && m.showButton === 1);
                isShowRoute = UserRightsValue.find(m => m.validateMenu === 'Route' && m.showButton === 1);
                isShowTNA = UserRightsValue.find(m => m.validateMenu === 'TNA' && m.showButton === 1);
                isShowTNAReport = UserRightsValue.find(m => m.validateMenu === 'TNAReport' && m.showButton === 1);

                ParentList = UserRightsValue.filter(d => d.validateMenu === 'Sampling' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.validateMenu === 'SamplingOrder' && d.userType === 2 && d.subParentUserType === 1);
                if (isShowStyle || isShowOrdLab || isShowOrdStrike || isShowOrdTrim || isShowPurchase || isShowRoute || isShowTNA || isShowTNAReport) {
                    if (childParentList.length > 0) { childParentList[0].ShowButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].ShowButton = 1 }
                }
                // else {
                //     if (childParentList.length > 0) { childParentList[0].ShowButton = 0 }
                //     if (ParentList.length > 0) { ParentList[0].ShowButton = 0 }
                // }
            }

        }
        //Component(sampling)
        else if (UserRightsValue[index].validateMenu === 'StrikeoffMaster' || UserRightsValue[index].validateMenu === 'TrimMaster') {
            if (!isShowStrikeMaster && !isShowTrimMaster) {
                isShowStrikeMaster = UserRightsValue.find(m => m.validateMenu === 'StrikeoffMaster' && m.showButton === 1);
                isShowTrimMaster = UserRightsValue.find(m => m.validateMenu === 'TrimMaster' && m.showButton === 1);
                ParentList = UserRightsValue.filter(d => d.validateMenu === 'Sampling' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.validateMenu === 'SamplingComponent' && d.userType === 2 && d.subParentUserType === 1);

                if (isShowStrikeMaster || isShowTrimMaster) {
                    if (childParentList.length > 0) { childParentList[0].ShowButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].ShowButton = 1 }
                }
            }
        }
        //Company Info(Master)
        else if (UserRightsValue[index].validateMenu === 'Company') {
            if (!isShowCompany) {
               ;
                isShowCompany = UserRightsValue.find(m => m.validateMenu === 'Company' && m.showButton === 1);
                ParentList = UserRightsValue.filter(d => d.validateMenu === 'Master' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.validateMenu === 'CompanyInformation' && d.userType === 2 && d.subParentUserType === 3);
                if (isShowCompany) {
                    if (childParentList.length > 0) { childParentList[0].ShowButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].ShowButton = 1 }
                }
            }
        }

        //Buyer Info(Master)
        else if (UserRightsValue[index].validateMenu === 'BuyerList' || UserRightsValue[index].validateMenu === 'BuyerInfo') {
            if (!isShowBuyerList && !isShowBuyerInfo) {
                isShowBuyerList = UserRightsValue.find(m => m.validateMenu === 'BuyerList' && m.showButton === 1);
                isShowBuyerInfo = UserRightsValue.find(m => m.validateMenu === 'BuyerInfo' && m.showButton === 1);
                ParentList = UserRightsValue.filter(d => d.validateMenu === 'Master' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.validateMenu === 'BuyerInformation' && d.userType === 2 && d.subParentUserType === 3);
                if (isShowBuyerList || isShowBuyerInfo) {
                    if (childParentList.length > 0) { childParentList[0].ShowButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].ShowButton = 1 }
                }
            }
        }

        //Supplier(Master)
        else if (UserRightsValue[index].validateMenu === 'Supplier') {
            if (!isShowSupplier) {
                isShowSupplier = UserRightsValue.find(m => m.validateMenu === 'Supplier' && m.showButton === 1);
                ParentList = UserRightsValue.filter(d => d.validateMenu === 'Master' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.validateMenu === 'SupplierInformation' && d.userType === 2 && d.subParentUserType === 3);
                if (isShowSupplier) {
                    if (childParentList.length > 0) { childParentList[0].ShowButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].ShowButton = 1 }
                }
            }
        }


        //General(Master)
        else if (UserRightsValue[index].validateMenu === 'Branch' || UserRightsValue[index].validateMenu === 'City' || UserRightsValue[index].validateMenu === 'Season' || UserRightsValue[index].validateMenu === 'Gender' || UserRightsValue[index].validateMenu === 'Currency') {
            if (!isShowBranch && !isShowCity && !isShowSeason && !isShowGender && !isShowCurrency) {
                isShowBranch = UserRightsValue.find(m => m.validateMenu === 'Branch' && m.showButton === 1);
                isShowCity = UserRightsValue.find(m => m.validateMenu === 'City' && m.showButton === 1);
                isShowSeason = UserRightsValue.find(m => m.validateMenu === 'Season' && m.showButton === 1);
                isShowGender = UserRightsValue.find(m => m.validateMenu === 'Gender' && m.showButton === 1);
                isShowCurrency = UserRightsValue.find(m => m.validateMenu === 'Currency' && m.showButton === 1);
                ParentList = UserRightsValue.filter(d => d.validateMenu === 'Master' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.validateMenu === 'General' && d.userType === 2 && d.subParentUserType === 3);
                if (isShowBranch || isShowCity || isShowSeason || isShowGender || isShowCurrency) {
                    if (childParentList.length > 0) { childParentList[0].ShowButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].ShowButton = 1 }
                }
            }
        }

        //Pantone Info(Master)
        else if (UserRightsValue[index].validateMenu === 'Pantone' || UserRightsValue[index].validateMenu === 'PantoneType') {
            if (!isShowBuyerList && !isShowBuyerInfo) {
                isShowBuyerList = UserRightsValue.find(m => m.validateMenu === 'Pantone' && m.showButton === 1);
                isShowBuyerInfo = UserRightsValue.find(m => m.validateMenu === 'PantoneType' && m.showButton === 1);
                ParentList = UserRightsValue.filter(d => d.validateMenu === 'Master' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.validateMenu === 'PantoneInformation' && d.userType === 2 && d.subParentUserType === 3);
                if (isShowBuyerList || isShowBuyerInfo) {
                    if (childParentList.length > 0) { childParentList[0].ShowButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].ShowButton = 1 }
                }
            }
        }

        //Component(Master)
        else if (UserRightsValue[index].validateMenu === 'GroupType' || UserRightsValue[index].validateMenu === 'Category' || UserRightsValue[index].validateMenu === 'StrikeOffType' || UserRightsValue[index].validateMenu === 'TrimType' || UserRightsValue[index].validateMenu === 'MeasurementPoint' || UserRightsValue[index].validateMenu === 'FabricType' || UserRightsValue[index].validateMenu === 'FabricPart') {
            if (!isShowGroup && !isShowCategory && !isShowStrikeOffType && !isShowTrimType && !isShowMeasurement && !isShowGroupFType && !isShowGroupFPart) {

                isShowGroup = UserRightsValue.find(m => m.validateMenu === 'GroupType' && m.showButton === 1);
                isShowCategory = UserRightsValue.find(m => m.validateMenu === 'Category' && m.showButton === 1);
                isShowStrikeOffType = UserRightsValue.find(m => m.validateMenu === 'StrikeOffType' && m.showButton === 1);
                isShowTrimType = UserRightsValue.find(m => m.validateMenu === 'TrimType' && m.showButton === 1);
                isShowMeasurement = UserRightsValue.find(m => m.validateMenu === 'MeasurementPoint' && m.showButton === 1);
                isShowGroupFType = UserRightsValue.find(m => m.validateMenu === 'FabricType' && m.showButton === 1);
                isShowGroupFPart = UserRightsValue.find(m => m.validateMenu === 'FabricPart' && m.showButton === 1);

                ParentList = UserRightsValue.filter(d => d.validateMenu === 'Master' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.validateMenu === 'Component' && d.userType === 2 && d.subParentUserType === 3);
                if (isShowGroup || isShowCategory || isShowStrikeOffType || isShowTrimType || isShowMeasurement || isShowGroupFType || isShowGroupFPart) {
                    if (childParentList.length > 0) { childParentList[0].ShowButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].ShowButton = 1 }
                }
            }
        }

        //Component1(Master)
        else if (UserRightsValue[index].validateMenu === 'Swatch' || UserRightsValue[index].validateMenu === 'Role' || UserRightsValue[index].validateMenu === 'UserPermission' || UserRightsValue[index].validateMenu === 'Department' || UserRightsValue[index].validateMenu === 'Size' || UserRightsValue[index].validateMenu === 'GroupSize' || UserRightsValue[index].validateMenu === 'ProcessType') {
            if (!isShowSwatch && !isShowRole && !isShowPermission && !isShowDepartment && !isShowSize && !isShowGroupSize && !isShowProcess) {

                isShowBuyerList = UserRightsValue.find(m => m.validateMenu === 'Swatch' && m.showButton === 1);
                isShowBuyerInfo = UserRightsValue.find(m => m.validateMenu === 'Role' && m.showButton === 1);
                isShowBuyerList = UserRightsValue.find(m => m.validateMenu === 'UserPermission' && m.showButton === 1);
                isShowBuyerInfo = UserRightsValue.find(m => m.validateMenu === 'Department' && m.showButton === 1);
                isShowBuyerList = UserRightsValue.find(m => m.validateMenu === 'Size' && m.showButton === 1);
                isShowBuyerInfo = UserRightsValue.find(m => m.validateMenu === 'GroupSize' && m.showButton === 1);
                isShowBuyerList = UserRightsValue.find(m => m.validateMenu === 'ProcessType' && m.showButton === 1);


                ParentList = UserRightsValue.filter(d => d.validateMenu === 'Master' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.validateMenu === 'AddtionalComponent' && d.userType === 2 && d.subParentUserType === 3);
                if (isShowSwatch || isShowRole || isShowPermission || isShowDepartment || isShowSize || isShowGroupSize || isShowProcess) {
                    if (childParentList.length > 0) { childParentList[0].ShowButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].ShowButton = 1 }
                }
            }
        }

        //Task Info(Master)
        else if (UserRightsValue[index].validateMenu === 'TaskType' || UserRightsValue[index].validateMenu === 'Task') {
            if (!isShowTaskType && !isShowTask) {
                isShowTaskType = UserRightsValue.find(m => m.validateMenu === 'TaskType' && m.showButton === 1);
                isShowTask = UserRightsValue.find(m => m.validateMenu === 'Task' && m.showButton === 1);
                ParentList = UserRightsValue.filter(d => d.validateMenu === 'Master' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.validateMenu === 'TaskInfo' && d.userType === 2 && d.subParentUserType === 3);
                if (isShowTaskType || isShowTask) {
                    if (childParentList.length > 0) { childParentList[0].ShowButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].ShowButton = 1 }
                }
            }
        }

    }

}

