import axios from "axios";
import authHeader from "../auth-header";

class FabricService {

    LoadYarnTypeList() {
        return axios.get(window.$APIBaseURL + "Master/LoadYarnTypeList", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetYarnList(YarnID) {
        return axios.get(window.$APIBaseURL + "Master/GetYarnList?YarnID=" + YarnID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateYarnGrid(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateYarnGrid',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }


    InsertUpdateYarn(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateYarn',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

}
export default new FabricService();