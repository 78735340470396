import React, { useState, useEffect } from 'react';
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import { Modal, Button } from "react-bootstrap";
import GroupTypeService from "../../services/Master/GroupTypeService";
import Nodify from "../Common/ReactNotification";
import { useSelector } from "react-redux";

const GroupTypePopup = ({
    getGroupList,
    groupTypeCallback,
    showpopup,
}) => {
    const { user: currentUser } = useSelector((state) => state.auth);

    const EmptyGroupTypeInputFields =
        [{
            Id: 0, Name: "",
            // GroupTypeID: 0,
            // GroupTypeName: '',
        }]

    const [buttonLoader, setButtonLoader] = useState(false);
    const [groupTypeInputFields, setGroupTypeInputFields] = useState(EmptyGroupTypeInputFields);
    const [groupTypeList, setGroupTypeList] = useState([]);
    const [show, setShow] = useState(true);
    const [GroupTypeSubmitted, setGroupTypeSubmitted] = useState(false);

    const handlePopupClose = () => {
        groupTypeCallback(false);
    };

    const CheckDuplicate = (index) => {
        const values = [...groupTypeInputFields];
        if (getGroupList) {
            for (var i = 0; i < getGroupList.length; i++) {
                if (getGroupList[i].label.trim().toLowerCase() === values[index].Name.trim().toLowerCase()) {
                    values[index].Name = '';
                    // values[index].Id=0;
                    setGroupTypeInputFields(values);
                    setGroupTypeList({ Createdby: currentUser.employeeinformationID, MasterInformation: values });
                    Nodify('Warning!', 'warning', "This name is already exist.");
                    return false;
                }
            }
        }
    }

    const handleGroupTypeChange = (event) => {
        const values = [...groupTypeInputFields];
        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }
        values[0].Name = inputText;
        setGroupTypeInputFields(values);
        setGroupTypeList({ Createdby: currentUser.employeeinformationID, MasterInformation: values });
    }

    const SaveGroupType = (e) => {
        setButtonLoader(true);
        e.preventDefault();
        const values = [...groupTypeInputFields];
        if (values[0].Name.trim() === '') {
            setGroupTypeSubmitted(false);
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Group Type  name is required.');
        }
        if (values[0].Name.trim()) {
            GroupTypeService.InsertUpdateGroupTypeGrid(groupTypeList).then(res => {
                if (res.data.outputResult === "1") {
                    Nodify("Success!", "success", "Group Type added successfully.");
                    groupTypeCallback(true);
                } else if (res.data.outputResult === "-2") {

                    Nodify('Warning!', 'warning', 'This name is already exist.');
                }
                setButtonLoader(false);
            });
        }
        else {
            setGroupTypeSubmitted(true);
        }
    }

    return (
        // <Modal show={showpopup} size="md" dragable backdrop="static" keyboard={false} onHide={() => handlePopupClose()}>
        //     <Modal.Header closeButton>
        //     <Modal.Title>Add Group Type</Modal.Title>
        //     </Modal.Header>
        //       <Modal.Body>
        <div id="registration-form">
            <Form>
                <div className="row">
                    <div className="form-group col-sm-12">
                        <label htmlFor="GroupType">
                            Group Type
                            <span className="text-danger">*</span>
                        </label>
                        <Input
                            placeholder={"Enter Group Type"}
                            id="GroupType"
                            name="GroupType"
                            type="text"
                            maxLength="50"
                            value={groupTypeInputFields[0].Name}
                            autoComplete="off"
                            onChange={handleGroupTypeChange}
                            className='form-control'
                            onBlur={() => CheckDuplicate(0)}
                            style={{ border: GroupTypeSubmitted && !groupTypeInputFields[0].Name ? '1px solid red' : '' }}
                            autoFocus
                        />
                    </div>
                </div>

                <br />
                <div style={{ textAlign: "right" }}>
                    <Button type="button" className="btn btn-success" onClick={SaveGroupType} disabled={buttonLoader}>
                        <i className="fa fa-check right"></i> &nbsp; Save
                    </Button>
                </div>
            </Form>
        </div>
        //       </Modal.Body>
        //       <Modal.Footer>
        //         <Button  type="submit" className="btn btn-success" onClick={SaveGroupType} disabled={buttonLoader}>
        //            <i className="fa fa-check right"></i> &nbsp; Save
        //         </Button>
        //       </Modal.Footer>
        // </Modal>
    )
};

export default GroupTypePopup;