import React, { useState, useEffect } from 'react';
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import { Modal, Button } from "react-bootstrap";
import SizeService from "../../services/Master/SizeService";
import Nodify from "../Common/ReactNotification";
import Select from 'react-select';
import { useSelector } from "react-redux";

const SizePopup = ({
    getSizeList,
    sizeCallback,
    showpopup,
    LoadSizeList,
}) => {
    const { user: currentUser } = useSelector((state) => state.auth);

    const EmptySizeInputFields =
        [{
            SizeDetailsID: 0,
            GroupName: '',
            SizeID: '',
            ReturnSizeID: ''
        }]

    const [buttonLoader, setButtonLoader] = useState(false);
    const [sizeInputFields, setsizeInputFields] = useState(EmptySizeInputFields);
    const [sizeList, setSizeList] = useState([]);
    const [SizeSubmitted, setSizeSubmitted] = useState(false);

    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),
    }

    const handlePopupClose = () => {
        sizeCallback(false);
    };

    const CheckDuplicate = (index, Field) => {
        const values = [...sizeInputFields];
        if (getSizeList.length !== 0) {
            if (values[index].GroupName !== '') { // for avoid empty validation
                for (var i = 0; i < getSizeList.length; i++) {
                    let MSG = '';
                    if (getSizeList[i].label.trim().toLowerCase() === values[index].GroupName.trim().toLowerCase()) {

                        values[index].GroupName = '';
                        MSG = "This group name is already exist.";
                        setsizeInputFields(values);
                        setSizeList({ Createdby: currentUser.employeeinformationID, SizeDetailInformation: values });
                        Nodify('Warning!', 'warning', MSG);
                        return false;

                    }

                }
            }
        }
    }

    const handleInputChange = (index, event) => {
        const values = [...sizeInputFields];
        setSizeSubmitted(false);
        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }
        values[index].GroupName = inputText;
        setsizeInputFields(values);
        setSizeList({ Createdby: currentUser.employeeinformationID, SizeDetailInformation: values });
    }

    const handleInputChangeSize = (index, event) => {
        const values = [...sizeInputFields];
        setSizeSubmitted(false);
        if (event.length === 0) {
            values[index].SizeID = '';
            values[index].ReturnSizeID = '';
        }
        else {
            values[index].ReturnSizeID = event;
            let strValue = '';
            for (let size of event) {
                if (strValue === '') { strValue = size.value.toString() }
                else { strValue = strValue + "," + size.value }
            }
            values[index].SizeID = strValue;
        }
        setsizeInputFields(values);
        setSizeList({ Createdby: currentUser.employeeinformationID, SizeDetailInformation: values });
    }

    const SaveSize = (e) => {
        setButtonLoader(true);
        e.preventDefault();
        const values = [...sizeInputFields];
        if (values[0].GroupName.trim() === '' && values[0].SizeID === '') {
            setSizeSubmitted(false);
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Group Name and Size is required.');
        }
        else if (values[0].GroupName.trim() === '') {
            setSizeSubmitted(false);
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Group Name is required.');
        }
        else if (values[0].SizeID === '') {
            setSizeSubmitted(false);
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Size is required.');
        }
        if (values[0].GroupName.trim() && values[0].SizeID) {
            SizeService.InsertUpdateSizeGrid(sizeList).then(res => {
                if (res.data.outputResult === "1") {
                    Nodify("Success!", "success", "Size added successfully.");
                    sizeCallback(true);
                } else if (res.data.outputResult === "-2") {

                    Nodify('Warning!', 'warning', 'This name is already exist.');
                }
                setButtonLoader(false);
            });
        }
        else {
            setSizeSubmitted(true);
        }
    }

    return (
        // <Modal show={showpopup} size="md" dragable backdrop="static" keyboard={false} onHide={() => handlePopupClose()}>
        //     <Modal.Header closeButton>
        //         <Modal.Title>Add Size</Modal.Title>
        //     </Modal.Header>
        //     <Modal.Body>
        <div id="registration-form">
            <Form>
                <div className="row">
                    <div className="form-group col-sm-12">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="GroupName">
                                    Group Name
                                    <span className="text-danger">*</span>
                                </label>
                                <Input
                                    placeholder={"Enter Group Name"}
                                    id="GroupName"
                                    name="GroupName"
                                    type="text"
                                    maxLength="50"
                                    value={sizeInputFields[0].GroupName}
                                    autoComplete="off"
                                    onChange={event => handleInputChange(0, event)}
                                    className='form-control'
                                    onBlur={() => CheckDuplicate(0, 'GroupName')}
                                    style={{ border: SizeSubmitted && !sizeInputFields[0].GroupName ? '1px solid red' : '' }}
                                    autoFocus
                                />
                            </div>
                        </div>
                        <div className="col-sm-6 divder_style">
                            <div className="form-group">
                                <span className="input-icon icon-right">
                                    <label htmlFor={
                                        "SizeID"
                                    }>Size<span className="text-danger">*</span>
                                    </label>
                                    <Select className="basic-single" name="SizeID"
                                        id={
                                            "SizeID"
                                        }
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={true}
                                        isSearchable={true}
                                        isMulti
                                        styles={SizeSubmitted && sizeInputFields[0].SizeID === '' ? styles : ''}
                                        value={sizeInputFields[0].ReturnSizeID}
                                        options={LoadSizeList}

                                        onChange={
                                            event => handleInputChangeSize(0, event)
                                        }
                                        onBlur={() => CheckDuplicate(0, 'SizeID')}
                                    />

                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <br />
                <div style={{ textAlign: "right" }}>
                    <Button type="button" className="btn btn-success" onClick={SaveSize} disabled={buttonLoader}>
                        <i className="fa fa-check right"></i> &nbsp; Save
                    </Button>
                </div>
            </Form>
        </div>
        //     </Modal.Body>
        //     <Modal.Footer>
        //         <Button type="submit" className="btn btn-success" onClick={SaveSize} disabled={buttonLoader}>
        //             <i className="fa fa-check right"></i> &nbsp; Save
        //         </Button>
        //     </Modal.Footer>
        // </Modal>
    )
};

export default SizePopup;