import React, { useState, useEffect, useRef, Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Reactselect from 'react-select';
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import Lightbox from "react-image-lightbox";
// import ReactNotification from 'react-notifications-component';
import { getOrderStrikeOffDetailsViewList, clearStrikeOffDetailsViewSubRow } from "../../../../actions/orderStrikeOff";
import CommonService from "../../../../services/Common/CommonService";
import Nodify from "../../../Common/ReactNotification";
import SweetAlertPopup from '../../../Common/SweetAlertPopup';
import { GenStrikeoffInfoId } from "../../../Common/PageActionNumber";
import { DateRangePicker } from 'react-date-range';
import moment from "moment";
import { Modal, Button } from "react-bootstrap";
import SizeService from "../../../../services/Master/SizeService";
import strikeoffService from "../../../../services/Style/StrikeoffService";
// import { event } from "jquery";


const StrikeOffViewDetailTab = (props) => {

    //declaration
    const dispatch = useDispatch();
    const [loader, showLoader, hideLoader] = useFullPageLoader();

    //reducer
    const strikeOffViewDetailList = useSelector((state) => state.orderStrikeOff.orderStrikeOffViewDetailList);
    const strikeOffViewDetailParams = useSelector((state) => state.orderStrikeOff.orderStrikeOffViewDetailParams);
    let isLoadingOrderStrikeOff = useSelector((state) => state.orderStrikeOff.isLoadingOrderStrikeOff);

    const getSizeList = useSelector((state) => state.size.drpSizeList);
    let OLDStatusList = useSelector((state) => state.orderStrikeOff.OLDStatusList);
    //state

    const [getID, setID] = useState(0);
    const [getSearch, setSearch] = useState("");

    const [isOpen, setIsOpen] = useState(false);


    const [currentExpand, setcurrentExpand] = useState({ currindex: "", currStrikeOffId: "", currColorGroupID: "" });
    const [getPlaceholder] = useState("Buyer / Season / Brand");
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [isOpenimg, setIsOpenimg] = useState(false);
    const [getImagename, setImagename] = useState();
    const [getApprovalList, setApprovalList] = useState([]);
    const [getSizeFilterList, setSizeFilterList] = useState([]);
    const [showSizePopup, setShowSizePopup] = useState({ isPopup: false, detail: '' });
    const [getStyleNameSearch, setgetStyleNameSearch] = useState("");
    const [getStyleNoSearch, setgetStyleNoSearch] = useState("");
    const [getIdPoSearch, setgetIdPoSearch] = useState("");
    const [getSearchvalues, setSearchvalues] = useState({
        StatusId: 0,
        SearchTxt: "",
        StyleName: "",
        StyleNo: "",
        IdPoNo: "",
        ApprovalTypeId: 0,
        stateDate: [
            {
                startDate: null,
                endDate: new Date(""),
                key: 'selection',
                isShow: false
            }
        ]

    });
    // let activeMenu;
    // let activeMenuSub;
    // // let StrikeOffViewDetailTab;
    // const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    // const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
    // if (menulist !== undefined && menulistSub.length > 0) {
    //     activeMenu = menulist.filter(i => i.menuName === 'StrikeOff');
    // }
    // if (activeMenu.length > 0) {
    //     activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);

    //     if (activeMenuSub.length > 0) {

    //         for (let index = 0; index < activeMenuSub.length; index++) {
    //             if (activeMenuSub[index].menuName === "GeneralStrikeOffDetails") {
    //                 // StrikeOffViewDetailTab = activeMenuSub[index];
    //             }
    //         }
    //     }



    // }

    const Searchvalues = { ...getSearchvalues };

    useEffect(() => {
        strikeoffService.LoadApprovalTypeList().then((response) => {
            if (response.data) {
                setApprovalList(response.data);
            }
        }).catch(() => { });
    }, [])

    // If check production or sampling
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProduction = activeMenus.IsProduction;
    // End

    //#region  ------------------  search details --------------------------------

    const searchStrikeOff = () => {
        Searchvalues.stateDate[0].isShow = false;
        setSearchvalues(Searchvalues);

        let StartDate = null;
        let EndDate = null;
        if (moment(Searchvalues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(Searchvalues.stateDate[0].startDate));
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(Searchvalues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(Searchvalues.stateDate[0].endDate));
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let params = {
            BuyerId: strikeOffViewDetailParams.BuyerId, BrandId: strikeOffViewDetailParams.BrandId, SeasonId: strikeOffViewDetailParams.SeasonId,
            BuyerName: strikeOffViewDetailParams.BuyerName, BrandName: strikeOffViewDetailParams.BrandName, SeasonName: strikeOffViewDetailParams.SeasonName,
            StrikeOffId: 0, ColorGroupID: 0, Index: 0, SearchText: Searchvalues.SearchTxt, StatusId: Searchvalues.StatusId ? Searchvalues.StatusId : null,
            ApprovalTypeId: Searchvalues.ApprovalTypeId, StartDate: StartDate, EndDate: EndDate, Operation: 1,
            StyleName: Searchvalues.StyleName, StyleNo: Searchvalues.StyleNo, IdPoNo: Searchvalues.IdPoNo,
            IsProduction: IsProduction
        };
        dispatch(getOrderStrikeOffDetailsViewList(params));
        commonParentExpand();
    }

    const resetStrikeOff = () => {
        Searchvalues.stateDate[0].startDate = null;
        Searchvalues.stateDate[0].endDate = new Date("");
        Searchvalues.stateDate[0].key = 'selection';
        Searchvalues.stateDate[0].isShow = false;

        Searchvalues.StatusId = "";
        Searchvalues.SearchTxt = "";
        Searchvalues.ApprovalTypeId = "";
        Searchvalues.StyleName = "";
        Searchvalues.StyleNo = "";
        Searchvalues.IdPoNo = "";
        setSearchvalues(Searchvalues);
        searchStrikeOff();

    }


    const clearDateSelect = () => {
        Searchvalues.stateDate[0].startDate = null;
        Searchvalues.stateDate[0].endDate = new Date("");
        Searchvalues.stateDate[0].key = 'selection';
        Searchvalues.stateDate[0].isShow = false;

        setSearchvalues(Searchvalues);
    }
    const handleDateSelect = (event, isShow) => {

        Searchvalues.stateDate[0].isShow = isShow;
        setSearchvalues(Searchvalues);
    }

    const handlePopupDateselection = (item) => {

        Searchvalues.stateDate = [item.selection];
        setSearchvalues(Searchvalues);
    }

    const handleChange = (e, FieldName) => {

        let inputText = '';
        if (FieldName === 'SearchTxt' || FieldName === 'StyleName' || FieldName === 'StyleNo' || FieldName === 'IdPoNo') {
            if (e && e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
        } else if (FieldName === 'StatusId' || FieldName === 'ApprovalType') {
            if (e) {
                inputText = e.value
            }
        }

        if (FieldName === 'StatusId') {
            Searchvalues.StatusId = inputText;
        } else if (FieldName === 'ApprovalType') {
            Searchvalues.ApprovalTypeId = inputText;
        } else if (FieldName === 'SearchTxt') {
            Searchvalues.SearchTxt = inputText;
        } else if (FieldName === 'StyleName') {
            Searchvalues.StyleName = inputText;
        }
        else if (FieldName === 'StyleNo') {
            Searchvalues.StyleNo = inputText;
        }
        else if (FieldName === 'IdPoNo') {
            Searchvalues.IdPoNo = inputText;
        }
        setSearchvalues(Searchvalues);

    }


    function convertUTCDateToLocalDate(date) {
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date;
    }

    //#end  ------------------  search details end --------------------------------

    useEffect(() => {
        if (!isLoadingOrderStrikeOff) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingOrderStrikeOff])



    const handleToggle = (index, StrikeOffId, ColorGroupID) => {
        let btnToggle = document.getElementById("btn-toggle_" + index);
        let iconToggle = document.getElementById("icon-toggle_" + index);
        let ariaExpanded = btnToggle.getAttribute('aria-expanded');
        if (ariaExpanded === 'false') {
            btnToggle.setAttribute('aria-expanded', 'true');
            iconToggle.className = iconToggle.className.replace("fa-plus-square-o", "fa-minus-square-o");
            let StartDate = null;
            let EndDate = null;
            if (moment(Searchvalues.stateDate[0].startDate).isValid()) {
                StartDate = convertUTCDateToLocalDate(new Date(Searchvalues.stateDate[0].startDate));
                //StartDate.setMinutes(StartDate.getMinutes() + 370);
            }
            if (moment(Searchvalues.stateDate[0].endDate).isValid()) {
                EndDate = convertUTCDateToLocalDate(new Date(Searchvalues.stateDate[0].endDate));
                //EndDate.setMinutes(EndDate.getMinutes() + 370);
            }
            let params = {
                BuyerId: strikeOffViewDetailParams.BuyerId, BrandId: strikeOffViewDetailParams.BrandId, SeasonId: strikeOffViewDetailParams.SeasonId,
                BuyerName: strikeOffViewDetailParams.BuyerName, BrandName: strikeOffViewDetailParams.BrandName,
                SeasonName: strikeOffViewDetailParams.SeasonName, Operation: 1,
                StrikeOffId: StrikeOffId, ColorGroupID: ColorGroupID, Index: index, SearchText: Searchvalues.SearchTxt,
                StatusId: Searchvalues.StatusId, ApprovalTypeId: Searchvalues.ApprovalTypeId, StartDate: StartDate, EndDate: EndDate,
                StyleName: Searchvalues.StyleName, StyleNo: Searchvalues.StyleNo, IdPoNo: Searchvalues.IdPoNo,
                IsProduction: IsProduction

            };
            setcurrentExpand({ currindex: index, currStrikeOffId: StrikeOffId, currColorGroupID: ColorGroupID })
            dispatch(getOrderStrikeOffDetailsViewList(params));
        }
        else {

            btnToggle.setAttribute('aria-expanded', 'false');
            iconToggle.className = iconToggle.className.replace("fa-minus-square-o", "fa-plus-square-o");
            if (StrikeOffId) {
                dispatch(clearStrikeOffDetailsViewSubRow(index));

            }
        }
    };

    const commonParentExpand = () => {
        let rowHiddens = document.querySelectorAll(".parentExpandViewTNA");
        for (let row of rowHiddens) {
            row.setAttribute('aria-expanded', 'false');
        }
        let rowIconHiddens = document.querySelectorAll(".clscollapseexpandViewTNA");
        for (let rowIcon of rowIconHiddens) {
            rowIcon.className = rowIcon.className.replace("fa-minus-square-o", "fa-plus-square-o");
        }
    }

    // useEffect(() => {

    //     // SizeService.LoadSizeList().then((response) => {
    //     //     if (response.data) {
    //     //         let list = response.data;
    //     //         setSizeList(list);
    //     //     }
    //     // }).catch(() => { });
    //     const listener = event => {
    //         if (event.code === "Enter" || event.code === "NumpadEnter") {
    //             event.preventDefault();
    //             searchStrikeOff();
    //         }
    //     };
    //     document.addEventListener("keydown", listener);
    //     return () => {
    //         document.removeEventListener("keydown", listener);
    //     };
    // }, []);

    const handleStyleDetails = (detail, strikeOffGarmentSize) => {
        let sizes;
        var sizeFilter = [];
        if (strikeOffGarmentSize.includes(',')) {
            sizes = strikeOffGarmentSize.split(',');

            getSizeList.map(item => {
                sizes.map(s => {
                    if (item.value === parseInt(s)) {
                        sizeFilter.push({ label: item.label })
                    }
                })
            })
        }
        else {
            sizes = getSizeList.filter(x => x.value === parseInt(strikeOffGarmentSize))[0].label;
            sizeFilter.push({ label: sizes })
        }

        // const sizeFilter = getSizeList.filter(d => strikeOffGarmentSize.includes(d.value));
        setSizeFilterList(sizeFilter);
        setShowSizePopup({ isPopup: true, detail: detail });

    }
    const handleSizePopupClose = () => {

        setShowSizePopup({ isPopup: false, detail: '' });

    }
    const handleserach = () => {

    }
    const ViewFile = (event, filename) => { // event.preventDefault();

        setIsOpenimg(true);
        setImagename(filename);
    }
    return (
        <Fragment>
            <div> {
                isOpen && (
                    <Lightbox
                        mainSrc={isOpen}
                        alt="bg image"

                        onCloseRequest={
                            () => setIsOpen(false)
                        } />
                )
            } </div>
            <div className="widget-body">
                <div id="registration-form">
                    <form>
                    <div className="col-sm-12">
                           
                           <div className="col-md-2">
                               <div className='form-group'>
                                   <label>Strike Off Code / Name </label>
                                   <span className='input-icon icon-right'>
                                       <input type="text" name="SearchTxt" value={Searchvalues.SearchTxt} onChange={event => handleChange(event, "SearchTxt")} placeholder="Strike Off Code / Name" className="form-control" />
                                       {/* <span title='Search' onClick={() => searchText()}
                                       className="btn btn-danger btn-xs search"> */}
                                       {/* <i onClick={() => searchText()} className="fa fa-search"></i> */}
                                       {/* </span> */}
                                   </span>

                               </div>
                           </div>


                           <div className="col-md-2">
                               <div className="form-group">
                                   <label htmlFor="ApprovalType">
                                       Approval Type
                                   </label>
                                   <span className="input-icon icon-right">
                                       <Reactselect className="basic-single" name="ApprovalType"
                                           id={
                                               "ApprovalType"
                                           }
                                           isLoading={false}
                                           isClearable={true}
                                           isSearchable={true}
                                           onChange={event => handleChange(event, 'ApprovalType')}
                                           value={getApprovalList.filter(function (option) {
                                               return option.value === Searchvalues.ApprovalTypeId;
                                           })}
                                           options={getApprovalList}
                                           menuPortalTarget={document.body}
                                           styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                                       ></Reactselect>
                                   </span>
                               </div>
                           </div>

                           <div className="col-md-2">
                               <label htmlFor="Status">
                                   Style Name
                               </label>
                               <span className="input-icon icon-right">
                                   <input
                                       type="text"
                                       className="form-control StyleList"
                                       title={'Style Name'}
                                       placeholder={'Style Name'}
                                       value={Searchvalues.StyleName}
                                       onChange={e => handleChange(e, 'StyleName')}
                                   />
                               </span>
                           </div>

                           <div className="col-md-2">
                               <label htmlFor="Status">
                                   Style No
                               </label>
                               <span className="input-icon icon-right">
                                   <input
                                       type="text"
                                       className="form-control StyleList"
                                       title={'Style No'}
                                       placeholder={'Style No'}
                                       value={Searchvalues.StyleNo}
                                       onChange={e => handleChange(e, 'StyleNo')}
                                   />
                               </span>
                           </div>

                      


                     
                           <div className="col-md-2">
                               <label htmlFor="Status">
                                      ID/PO NO
                               </label>
                               <span className="input-icon icon-right">
                                   <input
                                       type="text"
                                       className="form-control StyleList"
                                       title={'ID/PO No'}
                                       placeholder={'ID/PO NO'}
                                       value={Searchvalues.IdPoNo}
                                       onChange={e => handleChange(e, 'IdPoNo')}
                                   />
                               </span>

                           </div>

                           <div className="col-md-2">
                               <div className="form-group">
                                   <label htmlFor="StatusId">
                                       Status
                                   </label>
                                   <span className="input-icon icon-right">
                                       <Reactselect className="basic-single" name="StatusId"
                                           id={
                                               "StatusId"
                                           }
                                           isLoading={false}
                                           isClearable={true}
                                           isSearchable={true}
                                           onChange={event => handleChange(event, 'StatusId')}
                                           value={OLDStatusList.filter(function (option) {
                                               return option.value === Searchvalues.StatusId;
                                           })}
                                           options={OLDStatusList}
                                           menuPortalTarget={document.body}
                                           styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                                       ></Reactselect>
                                   </span>
                               </div>
                           </div>
                   </div>







                        <div className="col-md-12" style={{ overflowX: "auto", minHeight: '100px' }}>
                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                <thead>
                                    <tr>
                                        <th className="fixed-column-header-first-tna col-action-or-other" style={{ width: "35px" }}> </th>
                                        <th className="fixed-column-header-first-tna col-action-or-other" style={{ width: "35px" }}> #</th>
                                        <th className="col-action-or-other">Date</th>
                                        <th className="col-action-or-other">Style Name</th>
                                        <th className="col-action-or-other">Style No</th>
                                        <th className="col-action-or-other">ID/PO NO</th>
                                        <th className="col-action-or-other">Details</th>
                                        <th className="col-action-or-other">Purchase Type</th>
                                        <th className="col-action-or-other">Trim Supplier</th>
                                        <th className="col-action-or-other">Approval Type</th>
                                        <th className="col-action-or-other">Task Holder / Follower</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        strikeOffViewDetailList && strikeOffViewDetailList.length > 0 ?
                                            <>
                                                {
                                                    strikeOffViewDetailList && strikeOffViewDetailList.map((inputField, index) => (
                                                        <Fragment key={`${inputField}~${index}`}>
                                                            <tr class="parent" data-child={"parent_" + index}>
                                                                <td className="fixed-column-first-tna">

                                                                    {
                                                                        <span id={"btn-toggle_" + index} class="btn-toggle parentExpandViewTNA" aria-expanded="false" onClick={() => handleToggle(index, inputField.strikeOffId, inputField.colorGroupID)} aria-labelledby="btn-toggle id-master">
                                                                            <i id={"icon-toggle_" + index} class="fa fa-plus-square-o clsPointer clscollapseexpandViewTNA"></i>
                                                                        </span>
                                                                    }

                                                                </td>
                                                                <td>{index+1}</td>
                                                                <td>
                                                                    <span className='input-icon icon-right'>
                                                                        {
                                                                            inputField.strikeOff
                                                                        }
                                                                    </span>
                                                                </td>
                                                                <td colSpan="8">
                                                                    <div className="trwidth_manual" style={{ textAlign: "center" }}>
                                                                        <img className="width_manual"
                                                                            src={inputField.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images/Style/Strikeoff/" + inputField.imagePath}
                                                                            alt="Logo"
                                                                            onClick={
                                                                                () => setIsOpen(inputField.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images/Style/Strikeoff/" + inputField.imagePath)
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                            {
                                                                inputField.strikeOffReferenceList && inputField.strikeOffReferenceList.map((subField, subIndex) => (

                                                                    <Fragment key={`${subIndex}~${subIndex}`}>
                                                                        <tr class={"child_" + index + " parentChild_" + index + "_" + subIndex} >
                                                                            <td></td>
                                                                            <td className="fixed-column-first-tna" style={{paddingLeft:"16px"}}>
                                                                                {subIndex + 1}
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {moment(subField.date).format('DD/MM/YYYY')} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.styleName} </span>
                                                                                }
                                                                            </td>

                                                                            <td>
                                                                                {
                                                                                    <span> {subField.styleNo} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {subField.idpono != null && subField.idpono != undefined &&
                                                                                    <span> {subField.idpono} </span>
                                                                                }
                                                                                {(subField.idpono == null || subField.idpono == "") &&
                                                                                    <span className="text-danger"> NA </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.details}
                                                                                        {
                                                                                            subField.strikeOffGarmentSize &&
                                                                                            <span onClick={() => handleStyleDetails(subField.details, subField.strikeOffGarmentSize)}
                                                                                                className="text-primary"> {"Size Details"} </span>
                                                                                        }
                                                                                    </span>
                                                                                }
                                                                            </td>

                                                                            <td>
                                                                                {
                                                                                    <span> {subField.purchaseTypeName ? subField.purchaseTypeName : "-"} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {(subField.trimSupplierName && subField.purchaseTypeID !== 3) ? subField.trimSupplierName : "-"} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.purchaseTypeID === 3 && (subField.approvalTypeName ? subField.approvalTypeName : "-")} </span>
                                                                                }
                                                                            </td>
                                                                            <td className="cut_text" title={subField.taskHolderFollowerNames}>
                                                                                {
                                                                                    <span> {subField.taskHolderFollowerNames} </span>
                                                                                }
                                                                            </td>


                                                                        </tr>

                                                                        {



                                                                            inputField.manualCommentList && subField.approvalTypeID === 2 && inputField.manualCommentList.map((Comment) => (
                                                                                subField.styleId === Comment.styleId && subField.strikeOffId === Comment.strikeOffId &&

                                                                                <tr class={"child_" + index + " parentChild_" + index + "_" + subIndex} title={Comment.manualCloseComment} >
                                                                                    <td colSpan="12">
                                                                                        <div className="row-1">
                                                                                            <span><b>Manual Comment{inputField.approvalTypeID} :</b> {Comment.manualCloseComment}
                                                                                            </span>
                                                                                        </div>

                                                                                        {
                                                                                            inputField.manualImageList && inputField.manualImageList.map((files, fileindex) => (
                                                                                                Comment.styleId === files.styleId && Comment.strikeOffId === files.strikeOffId &&

                                                                                                <div className="col-md-2 file_list_upload">
                                                                                                    <tr class={"child_" + index + " parentChild_" + index + "_" + subIndex} title={files.fileName}  >
                                                                                                        File:
                                                                                                        <td className="cut_textInTNA">
                                                                                                            <span style={{ marginRight: '7px' }}>
                                                                                                                {
                                                                                                                    files.fileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                                        files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                                                            <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                                            files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                                                files.fileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                                                <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                                                }
                                                                                                            </span>  {files.fileName}
                                                                                                        </td>

                                                                                                        <td >

                                                                                                            {
                                                                                                                files.fileType === "application/pdf" || files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files.fileType === 'application/msword' ?
                                                                                                                    <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + "Images/Style/Strikeoff/" + files.fileName} download><i class="fa fa-download"></i> </a> :
                                                                                                                    // StrikeOffViewDetailTab !== undefined && StrikeOffViewDetailTab.isView !== 0 &&
                                                                                                                    <i className="btn btn-info fa fa-eye" title="View Logo"
                                                                                                                        onClick={
                                                                                                                            event => ViewFile(event, files.fileName)
                                                                                                                        }></i>

                                                                                                            }
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    {

                                                                                                        <div> {
                                                                                                            isOpenimg && (

                                                                                                                <Lightbox mainSrc={window.$APIBaseURL + 'Images/TNAImages/' + getImagename}
                                                                                                                    alt="bg image"
                                                                                                                    onCloseRequest={
                                                                                                                        () => setIsOpenimg(false)
                                                                                                                    } />
                                                                                                            )
                                                                                                        } </div>
                                                                                                    }
                                                                                                </div>
                                                                                                //    : ''
                                                                                            ))

                                                                                        }
                                                                                    </td>
                                                                                </tr>




                                                                                // : ''
                                                                            ))
                                                                        }

                                                                        {
                                                                            inputField.existingList && subField.approvalTypeID === 3 && inputField.existingList.map((exist) => (
                                                                                subField.strikeOffId === exist.strikeOffId && subField.styleId === exist.styleID ?
                                                                                    <tr class={"child_" + index + " parentChild_" + index + "_" + subIndex}>
                                                                                        <td colSpan="10">
                                                                                            <span><b>Season Name : </b> {exist.seasonName} &nbsp;</span>
                                                                                            <span><b>Approval Date : </b>  {new Date(exist.approvalDate).toLocaleDateString()} &nbsp;</span>
                                                                                            <span><b>Details : </b> {exist.details} &nbsp;</span>
                                                                                            <span><b>Approved By : </b> {exist.approvedBy}</span>

                                                                                        </td>
                                                                                    </tr>
                                                                                    : ''
                                                                            ))
                                                                        }
                                                                    </Fragment>
                                                                ))
                                                            }

                                                        </Fragment>
                                                    ))
                                                }
                                            </>
                                            :

                                            <tr><td colSpan="11" className='norecordfound'><span>No Records Found</span></td></tr>

                                    }
                                </tbody>
                            </table>
                        </div>
                    </form>

                </div>

            </div >
            {
                //View Size
                showSizePopup.isPopup === true ?
                    <Modal show={showSizePopup.isPopup === true} dialogClassName="modal-lg" dragable backdrop="static" keyboard={false} onHide={() => handleSizePopupClose()} >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                View Size
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="col-md-12">

                                <table className="table table-striped table-bordered table-hover dataTable no-footer" >
                                    <thead>
                                        <tr>
                                            <th>Detail</th>
                                            <th>Size Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            getSizeFilterList && getSizeFilterList.map((inputField) => (
                                                <Fragment>
                                                    <tr>
                                                        <td>
                                                            <span>
                                                                {showSizePopup.detail}

                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span>
                                                                {inputField.label}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </Fragment>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="col-md-12" style={{ paddingTop: '8px' }}>
                                <Button variant="danger" onClick={() => handleSizePopupClose()}>
                                    <i className="fa fa-times"></i>&nbsp;Close
                                </Button>
                            </div>
                        </Modal.Footer>
                    </Modal> : ''
            }
            {loader}

        </Fragment >

    )
}

export default React.memo(StrikeOffViewDetailTab);