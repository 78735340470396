import axios from "axios";
import authHeader from "../auth-header";

class CityService {
   
    loadStateList() {
        return axios.get(window.$APIBaseURL + "Master/LoadStateList?CityID=0", {
            headers: {
                authorization: authHeader()
            }
        });
    }
    
    loadCountryList() {
        return axios.get(window.$APIBaseURL + "Master/LoadCountryList?CityID=0", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    getCityList() {
        return axios.get(window.$APIBaseURL + "Master/GetCityList?CityID=0", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    addCity(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateDelCity',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateCityGrid(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateCityGrid',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

}
export default new CityService()
