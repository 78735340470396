import {
    PANTONE_LOADING,
    RETRIEVE_PANTONE,
    DELETE_PANTONE,
} from "./types";
import PantoneService from "../services/Master/PantoneService";

const pantoneLoading = (isStatus) => ({
    type: PANTONE_LOADING,
    payload: isStatus,
});

export const deletePantone = (pantoneID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_PANTONE,
            payload: pantoneID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrievePantone = (pantoneID) => async (dispatch) => {
    try {
        dispatch(pantoneLoading(true));
        const res = await PantoneService.getPantonelist(1,pantoneID,0);
        dispatch({
            type: RETRIEVE_PANTONE,
            payload: res.data,
        });
        dispatch(pantoneLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(pantoneLoading(false));
    }
};