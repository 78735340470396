import React, { useState } from "react";

const QaInternalAuditHeader = ({ headers, onSorting, Taskname }) => {
    const [sortingField, setSortingField] = useState("");
    const [sortingOrder, setSortingOrder] = useState("asc");
    const [sortingIcon, setSortingIcon] = useState("");
    const [classname, setclassname] = useState("");
   
    const onSortingChange = (field) => {
        const order =
            (field === sortingField && sortingOrder === "asc") ? "desc" : "asc";
        setSortingField(field);
        setSortingOrder(order);
        setSortingIcon(order);
        onSorting(field, order);
    };
    return (
        // <thead >
        //     {Taskname === 'PP Meeting' || Taskname === 'In and Mid Line Inspection' ? <tr className="table_title">
        //         {headers.map(({ name, field, sortable, widthsize, display, className, position, left, background, zindex, padding, minWidth }) => (
        //             <th className={(
        //                 sortable === true ? (sortingField === field ?
        //                     (sortingIcon === "asc"
        //                         ? "sorting_desc"
        //                         : sortingIcon === "desc" ? "sorting_asc" : "sorting") : "sorting") : (className !== "" ? className : '')) || (className == "" ? '' : (display = "none"))
        //             }
        //                 key={name}
        //                 onClick={() =>
        //                     sortable ? onSortingChange(field) : null
        //                 }
        //                 style={{ minWidth: minWidth, width: widthsize === undefined ? (name === "Action" ? "" : "") : (name === "Action" ? "" : widthsize), display: display, position: position, left: left, background: background, zIndex: zindex, padding: padding }}
        //             >
        //                 {(name !== 'Report Status' && name !== "Review Status") && <span style={{ width: "100%", display: "block" }} className="hdrHeaderHeight">{name}</span>}
        //                 {(name === 'Report Status' || name === "Review Status") && <span>{name}</span>}
        //                 {field === 'reportStatus' &&
        //                     <table style={{ margin: "auto" }}>
        //                         <tr>
        //                             <td>
        //                                 <span title='Draft' className="btn btn-warning">
        //                                     D
        //                                 </span>
        //                             </td>
        //                             <td >
        //                                 <span title='Pass' className="btn  btn-success">
        //                                     P
        //                                 </span>
        //                             </td>
        //                         </tr>
        //                     </table>
        //                 }
        //                 {field === 'reviewStatus' &&
        //                     <table style={{ margin: "auto" }}>
        //                         <tr>
        //                             <td>
        //                                 <span title='Pending' className="btn btn-warning">
        //                                     P
        //                                 </span>
        //                             </td>
        //                             <td >
        //                                 <span title='Approved' className="btn  btn-success">
        //                                     A
        //                                 </span>
        //                             </td>
        //                         </tr>
        //                     </table>
        //                 }


        //             </th>

        //         ))}
        //     </tr> : <tr className="table_title">
        //         {headers.map(({ name, field, sortable, widthsize, display, className, position, left, background, zindex, padding, minWidth }) => (
        //             <th className={(
        //                 sortable === true ? (sortingField === field ?
        //                     (sortingIcon === "asc"
        //                         ? "sorting_desc"
        //                         : sortingIcon === "desc" ? "sorting_asc" : "sorting") : "sorting") : (className !== "" ? className : '')) || (className == "" ? '' : (display = "none"))
        //             }
        //                 key={name}
        //                 onClick={() =>
        //                     sortable ? onSortingChange(field) : null
        //                 }
        //                 style={{ minWidth: minWidth, width: widthsize === undefined ? (name === "Action" ? "" : "") : (name === "Action" ? "" : widthsize), display: display, position: position, left: left, background: background, zIndex: zindex, padding: padding }}
        //             >
        //                 {(name !== 'Report Status' && name !== "Review Status") && <span style={{ width: "100%", display: "block" }} className="hdrHeaderHeight">{name}</span>}
        //                 {(name === 'Report Status' || name === "Review Status") && <span>{name}</span>}
        //                 {field === 'reportStatus' &&
        //                     <table style={{ margin: "auto" }}>
        //                         <tr>
        //                             <td>
        //                                 <span title='Draft' className="btn btn-warning">
        //                                     D
        //                                 </span>
        //                             </td>
        //                             <td >
        //                                 <span title='Pass' className="btn  btn-success">
        //                                     Ok
        //                                 </span>
        //                             </td>
        //                             <td >
        //                                 <span title='Pass' className="btn  btn-primary">
        //                                     Ok.C
        //                                 </span>
        //                             </td>
        //                             <td >
        //                                 <span title='Pass' className="btn  btn-danger">
        //                                     N Ok
        //                                 </span>
        //                             </td>
        //                         </tr>
        //                     </table>
        //                 }
        //                 {field === 'reviewStatus' &&
        //                     <table style={{ margin: "auto" }}>
        //                         <tr>
        //                             <td>
        //                                 <span title='Pending' className="btn btn-warning">
        //                                     P
        //                                 </span>
        //                             </td>
        //                             <td >
        //                                 <span title='Approved' className="btn  btn-success">
        //                                     A
        //                                 </span>
        //                             </td>
        //                             <td>
        //                                 <span title='Commercial Approved' className="btn  btn-primary">
        //                                     C A
        //                                 </span>
        //                             </td>
        //                             <td>
        //                                 <span title='Rejected' className="btn  btn-danger">
        //                                     R
        //                                 </span>
        //                             </td>
        //                         </tr>
        //                     </table>
        //                 }


        //             </th>

        //         ))}
        //     </tr>}
        // </thead >
        <thead>
            <tr className="table_title">
                {headers.map(({ name, field, sortable, widthsize, display, className, position, left, background, zindex, padding, minWidth }) => {
                    const columnClassName = sortable
                        ? (sortingField === field
                            ? (sortingOrder === "asc" ? "sorting_asc" : "sorting_desc")
                            : "sorting")
                        : className || '';

                    const displayStyle = sortable ? 'table-cell' : (display === "none" ? "none" : "table-cell");

                    return (
                        <th
                            className={columnClassName}
                            key={name}
                            onClick={() => sortable ? onSortingChange(field) : null}
                            style={{
                                minWidth: minWidth,
                                width: widthsize ?? "",
                                display: displayStyle,
                                position: position,
                                left: left,
                                background: background,
                                zIndex: zindex,
                                padding: padding
                            }}
                        >
                            <span style={{ width: "100%", display: "block" }} className="hdrHeaderHeight">
                                {name}
                            </span>
                            {field === 'reportStatus' && (
                                <table style={{ margin: "auto" }}>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span title='Draft' className="btn btn-warning">D</span>
                                            </td>
                                            {(Taskname !== 'PP Meeting' && Taskname !== 'In and Mid Line Inspection') ? <td>
                                                <span title='Ok' className="btn btn-success">Ok</span>
                                            </td> : <td><span title='Reviewed' className="btn btn-success">S</span></td>}
                                            {(Taskname !== 'PP Meeting' && Taskname !== 'In and Mid Line Inspection') ? < td >
                                                <span title='Ok with coments' className="btn btn-primary">C.Ok</span>
                                            </td> : ''}
                                            {(Taskname !== 'PP Meeting' && Taskname !== 'In and Mid Line Inspection') ? < td >
                                                <span title='Not Ok' className="btn btn-danger">N.Ok</span>
                                            </td> : ''}
                                        </tr>
                                    </tbody>
                                </table>
                            )
                            }
                            {field === 'reviewStatus' && (
                                <table style={{ margin: "auto" }}>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span title='Pending' className="btn btn-warning">P</span>
                                            </td>
                                            {(Taskname !== 'PP Meeting' && Taskname !== 'In and Mid Line Inspection') ? <td>
                                                <span title='Approved' className="btn btn-success">A</span>
                                            </td> : ''}
                                            {(Taskname !== 'PP Meeting' && Taskname !== 'In and Mid Line Inspection') ? <td>
                                                <span title='Approved with comments' className="btn btn-primary">C.A</span>
                                            </td> : ''}
                                            <td>
                                                <span title={(Taskname !== 'PP Meeting' && Taskname !== 'In and Mid Line Inspection') ? 'Rejected' : 'Reviewed'}
                                                    className={(Taskname !== 'PP Meeting' && Taskname !== 'In and Mid Line Inspection') ? "btn btn-danger" : 'btn btn-success'}>R</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            )}
                        </th>
                    );
                })}
            </tr>
        </thead >

    );
};

export default QaInternalAuditHeader;

