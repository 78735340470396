import axios from "axios";
import authHeader from "../auth-header";

class GroupTypeService {
    GetGroupTypeList() {
        return axios.get(window.$APIBaseURL + "Master/GetGroupTypeList?GroupTypeID=0", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateGroupTypeGrid(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateGroupTypeGrid',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

      InsertUpdateGroupType(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateGroupType',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
}
export default new GroupTypeService()