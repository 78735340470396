
import React, { useState, useEffect, useMemo, Fragment } from "react";
import ReactNotification from 'react-notifications-component';
import Nodify from "../../Common/ReactNotification"
import validator from 'validator'
import SearchRetain from "../../Common/SearchRetain";
import { useSelector } from "react-redux";
import SelectCombobox from 'react-select';
import WorkmanshipItem from "../../../services/Master/WorkmanshipItem";
import { values } from "pdf-lib";
const AddWorkmanship = (props) => {
    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getWorkmanship, SetWorkmanship] = useState([{ workmanshipItemID: 0, workmanItemName: "" }]);
    const [ExistingList, setExistingList] = useState([]);
    const [ButtonName, setButtonName] = useState("Save");
    const [HeaderName, setHeaderName] = useState("Add");
    const { user: currentUser } = useSelector((state) => state.auth);
    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    if (menulist !== undefined) {
        activeMenu = menulist.filter(i => i.menuName === "Currency");
    }
    useEffect(() => {
        WorkmanshipItem.GetWorkmanshipItemList(0).then(res => {
            if (res.data) {
                setExistingList(res.data)
            }
        })
    }, [])
    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/WorkmanshipList');
        } else {
            window.location.reload();
            // let getGroupList = GroupListing(props);
            // SetState(getGroupList)
        }
    }
    const handleAddFields = (index) => {
       
        const values = [...getWorkmanship];
        if (values[index].workmanItemName !== '') {
            values.push({ workmanshipItemID: 0, workmanItemName: "" });
            SetWorkmanship(values);
            setSubmitted(false);
        }
        else {
            Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
            setSubmitted(true);
        }
    };
    const PageRedirect = (Func) => {
        props.history.push({ pathname: "/WorkmanshipList", state: { message: Func } });
    }
    const handleRemoveFields = index => {
        const values = [...getWorkmanship];
        values.splice(index, 1);
        SetWorkmanship(values);
    };
    const handleInputChange = (index, event, FieldName) => {
       
        const values = [...getWorkmanship];
        let inputText = '';
        if (FieldName === "Workmanship") {
            if (event.target.value.trim()) {
                inputText = event.target.value
            }
            values[index].workmanItemName = inputText
        }
        SetWorkmanship(values);
    };
    const SaveState = (e) => {
       
        let Isvalid = true;
        setButtonLoader(true);
        e.preventDefault();
        if (getWorkmanship.length === 1 && getWorkmanship[0].GenderSizeName === '') {
            setButtonLoader(false);
            setSubmitted(true);
            Nodify('Warning!', 'warning', "Please fill atleast one Gender.");
        }
        else {
            for (var i = 0; i < (getWorkmanship.length); i++) {
                if (getWorkmanship[i].workmanItemName.trim() === '') {
                    Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
                    Isvalid = false;
                    setSubmitted(true);
                    setButtonLoader(false);
                }
            }
            if (Isvalid) {
                let InputCommonMaster = { Operation: 1, workmanshipItemID: 0, workmanItemName: '', WorkmanItemInformation: getWorkmanship, Createdby: currentUser.employeeinformationID };
                WorkmanshipItem.InsertUpdateWorkmanshipItem(InputCommonMaster).then(res => {
                    var page = "Remove";
                    SearchRetain(page);
                    let Func = 'Add';
                    if (res.data.outputResult === "1") {
                        Func = 'Add';
                        PageRedirect(Func);
                    } else if (res.data.outputResult === "2") {
                        Func = 'Edit';
                        PageRedirect(Func);
                    } else if (res.data.outputResult === "-2") {
                        setButtonLoader(false);
                        Nodify('Warning!', 'warning', "This name is already exist.");
                    }
                    else if (res.data.outputResult === "0") {
                        setButtonLoader(false);
                        Nodify('Warning!', 'warning', "Error Occured!");
                    }
                });
            }
        }
    }

    const CheckDuplicate = (index, Field) => {
        const values = [...getWorkmanship];
        let Existing = !!ExistingList.find(x => x.workmanItemName.trim().toLowerCase() === values[index].workmanItemName.trim().toLowerCase())
        if (Existing) {
            values[index].workmanItemName = "";
            SetWorkmanship(values);
            Nodify('Warning!', 'warning', "This State Name is already exist.");
            return false;
        }
        for (var j = 0; j < (getWorkmanship.length); j++) {
            if (j !== index) {
                if (getWorkmanship[j].workmanItemName.trim().toLowerCase() === getWorkmanship[index].workmanItemName.trim().toLowerCase()
                ) {
                    values[index].workmanItemName = "";
                    SetWorkmanship(values);
                    Nodify('Warning!', 'warning', 'This Workmanship Name is already exist.');
                    return false;
                }
            }
        }


    }
    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">{HeaderName} Workmanship Details</h3>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <form>
                                    {
                                        getWorkmanship.map((inputField, index) => (
                                            <Fragment key={`${inputField}~${index}`}>
                                                <div className="row row_left10">

                                                    <div className="col-sm-3 divder_style">
                                                        <div className="form-group">
                                                            <label htmlFor={
                                                                "Workmanship" + index
                                                            }>Workmanship Name<span className="text-danger">*</span>
                                                            </label>
                                                            <span className="input-icon icon-right">
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"Workmanship" + index}
                                                                    name="Workmanship"
                                                                    placeholder="Enter Workmanship Name"
                                                                    maxLength="50"
                                                                    value={inputField.workmanItemName}
                                                                    onChange={event => handleInputChange(index, event, "Workmanship")}
                                                                    onBlur={() => CheckDuplicate(index, 'Workmanship')}
                                                                    style={{ border: submitted && !inputField.workmanItemName ? '1px solid red' : '' }}
                                                                    autoFocus
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-3 paddingstatic">
                                                        {
                                                            getWorkmanship.length !== 1 && (
                                                                <div className="col-lg-1_own col-xs-12 adddeletemargin">
                                                                    <button type="button" className="btn btn-danger"
                                                                        style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}
                                                                        title="Delete Currency" onClick={() => handleRemoveFields(index)}>
                                                                        <i className="fa fa-trash-o"></i>
                                                                    </button>
                                                                </div>
                                                            )}
                                                        {
                                                            getWorkmanship.length === (index + 1) && (
                                                                <div className="col-lg-1_own col-xs-12 adddeletemargin">
                                                                    <button type="button" className="btn btn-success" title="Add Currency" onClick={() => handleAddFields(index)}>
                                                                        <i className="fa fa-plus"></i>
                                                                    </button>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </Fragment>
                                        ))
                                    }
                                    <div className="col-sm-12">
                                        <div className="buttons-preview" align="right">
                                            <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                                <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                                            </span>
                                            <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                                <i className="fa fa-times"></i>&nbsp;Reset
                                            </span>
                                            <button type="button" className="btn btn-success" disabled={buttonLoader} onClick={SaveState}>
                                                <i className="fa fa-check right"></i>&nbsp;{ButtonName}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AddWorkmanship