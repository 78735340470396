import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
//import CommonService from '../../services/Common/CommonService';
//import {store} from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
//import { Redirect, useLocation, NavLink } from 'react-router-dom';

const ConfirmationPopup = ({
    props,
    data,
    deleteCallback,
    showpopup,
    //pageActionId,
    //Msg
}) => {
debugger    
    const [show, setShow] = useState(true);
    var IsConfirmbtnShow = true;
    const reducerState = useSelector((state) => state);
    const { user: currentUser } = reducerState.auth;
    const comments = reducerState.style.styleList.item1 !== undefined ? reducerState.style.styleList.item1 : reducerState.style.styleList;
    let StyleListCreatedByCurrentUser = comments.filter(d => d.createdBy === currentUser.employeeinformationID);
    useEffect(() => {
        setShow(showpopup);
    }, [data]);

    const handleClose = () => {
        setShow(false);
        deleteCallback(false);

    };
    let titleName = ' ';

    if (data.tabPage === 'ADDSKU') {
        titleName = ' SKU ';
    }
    else if (data.tabPage === 'ADDPROGRAM') {
        titleName = ' program ';

    }
    else if (data.tabPage === 'ADDMEASUREMENT') {
        titleName = ' measurement ';
    } else if (data.tabPage === 'UPDATEPURCHASEORDER') {
        titleName = ' purchase order ';
    } else if (data.tabPage === "AddTNA") {
        titleName = ' TNA ';
    } else if (data.tabPage === "ADDSAMPLEREQUEST") {
        titleName = ' sample request ';
    } else if (data.tabPage === "AddStyle") {
        titleName = ' Existing Saved ';
    }
     





    let confMsg = "";
    let title = "";
    let confirmBtnText = 'Yes,do it!'
    let cancelBtnText = 'Cancel'
    if (data.tabPage === 'UPDATEPURCHASEORDER') {
        confMsg = titleName + 'details updated successfully!';
        title = confMsg + ' Do you want to redirect the create TNA ?'
    } else if (data.tabPage === "AddTNA") {
        title = 'Do you want to create TNA ?';
    }
    else if (data.tabPage === "AddStyle" && StyleListCreatedByCurrentUser.length !== 0) {
        title = 'Do you want existing style Information?';
        confirmBtnText = 'Existing'
        cancelBtnText = 'New'

    }
    else if (data.tabPage === "MainInfo") {
        title = 'Do you want draft Information?';
        confirmBtnText = 'Existing'
        cancelBtnText = 'New'

    }
    else if (data.tabPage === "AddStyle" && StyleListCreatedByCurrentUser.length === 0) {
        title = 'No styles created by you, Click new to add a new style!';
        IsConfirmbtnShow = false;
        cancelBtnText = 'New'
    }
    else if (data.tabPage === "BrandwiseEdit") {
        confMsg = "Brand Wise Standard saved successfully";
        if (data.Func === 'Edit') {
            confMsg = "Brandwise Standard updated successfully";
        }
        else {
            confMsg = "Brandwise Standard saved successfully";
        }
        title = confMsg + ' Do you want to redirect the Brandwise Standard list page ?'
    }
    else if (data.tabPage === "CompanyRegister") {
        confMsg = 'Company' + titleName + 'details saved successfully!';
        if (data.Func === 'Edit') {
            confMsg = 'Company' + titleName + 'details updated successfully!';

        }
        title = confMsg + ' Do you want to redirect the Company list page?'
    }

    else if (data.tabPage === "BuyerRegister") {
        confMsg = 'Buyer' + titleName + 'details saved successfully!';
        if (data.Func === 'Edit') {
            confMsg = 'Buyer' + titleName + 'details updated successfully!';

        }
        title = confMsg + ' Do you want to redirect the Buyer list page?'
    }

    else if (data.tabPage === "SupplierRegister") {
        confMsg = 'Supplier' + titleName + 'details saved successfully!';
        if (data.Func === 'Edit') {
            confMsg = 'Supplier' + titleName + 'details updated successfully!';

        }
        title = confMsg + ' Do you want to redirect the Supplier list page?'
    }
    else if (data.tabPage === "TNAEdit") {
        confMsg = 'TNAEdit' + titleName + 'details saved successfully!';
        if (data.Func === 'Edit') {
            confMsg = 'TNAEdit' + titleName + 'details updated successfully!';

        }
        title = confMsg + ' Do you want to redirect the TNA list page?'
    }
    else if (data.tabPage === "TNAView") {
        confMsg = 'TNAView' + titleName + 'details saved successfully!';
        if (data.Func === 'View') {
            confMsg = 'TNAView' + titleName + 'details updated successfully!';

        }
        title = confMsg + ' Do you want to redirect the TNA list page?'
    }

    else if (data.tabPage === "BrandwiseEdit") { 
        confMsg = 'Brandwise Standard' + titleName + " details saved successfully";
        if (data.Func === 'Edit') {
            confMsg = 'Brandwise Standard' + titleName + 'details updated successfully!';
        }
               title = confMsg + ' Do you want to redirect the Brandwise Standard list page ?'
    }
    else if (data.tabPage === "EditPackageTemplate") {
        confMsg = 'Package Template' + titleName + " details saved successfully";
        if (data.Func === 'Edit') {
            confMsg = 'Package Template' + titleName + 'details updated successfully!';
        }
               title = confMsg + ' Do you want to redirect the Package Template list page ?'
    }
    else if (data.tabPage === "AddPackageTemplate") {
        confMsg = 'Package Template' + titleName + " details saved successfully";
        if (data.Func === 'Edit') {
            confMsg = 'Package Template' + titleName + 'details updated successfully!';
        }
               title = confMsg + ' Do you want to redirect the Package Template list page ?'
    }
 
    else {
        confMsg = 'Style' + titleName + 'details saved successfully!';
        if (data.Func === 'Edit') {
            confMsg = 'Style' + titleName + 'details updated successfully!';
        }
        title = confMsg + ' Do you want to redirect the style list page ?'
    }


    const deleteRow = () => {
        if (data.tabPage === 'UPDATEPURCHASEORDER') {
            props.location.state = { params: { purchaseOrderID: parseInt(data.outputResultPurchaseOrderID), brandID: data.outputBrandID, seasonID: data.outputSeasonID, supplierID: data.outputSupplierID } };
            props.history.push({ pathname: "/TNARegister", state: { params: { purchaseOrderID: parseInt(data.outputResultPurchaseOrderID), brandID: data.outputBrandID, seasonID: data.outputSeasonID, supplierID: data.outputSupplierID } } });
        } else if (data.tabPage === "AddTNA") {
            deleteCallback(true);
        } else if (data.tabPage === "AddStyle") {
            deleteCallback(true);

        } else if (data.tabPage === "BrandwiseEdit") {
            props.history.push('/BrandWiseStandardList');
        }
        else if (data.tabPage === "CompanyRegister") {
            props.history.push('/Company');
            localStorage.removeItem("CompanySubbuyID");
        }
        else if (data.tabPage === "BuyerRegister") {
            props.history.push('/Buyer');
            localStorage.removeItem("CompanySubbuyID");
        }
        else if (data.tabPage === "SupplierRegister") {
            props.history.push('/SupplierList');
            localStorage.removeItem("CompanySubbuyID");
        }
        else if (data.tabPage === "TNAEdit") {
            props.history.push('/TNAList');
        }
        else if (data.tabPage === "TNAView") {
            props.history.push('/TNAList');
        }
        else if (data.tabPage === "EditPackageTemplate") {
            props.history.push('/PackageGroup');
        }
        else if (data.tabPage === "AddPackageTemplate") {
            props.history.push('/PackageGroup');
        }

        else {
            props.history.push('/StyleList');
        }
    }



    return (
        <SweetAlert
            show={show}
            //warning
            success
            showCancel
            showConfirm={IsConfirmbtnShow}
            confirmBtnText={confirmBtnText}
            cancelBtnText={cancelBtnText}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="primary"
            // closeOnConfirm
            // closeOnCancel
            title={title}
            onConfirm={deleteRow}
            onCancel={handleClose}
            showCloseButton
            focusCancelBtn
        // style={{ backgroundColor: 'blue', color: 'white' }}
        >
            {/* I did it! */}
        </SweetAlert>
    )
};

export default ConfirmationPopup;