import axios from "axios";
import authHeader from "../auth-header";


const sendstylemail = (mailcontent) => {
    return axios({
        method: 'post',
        url: window.$APIBaseURL + 'Style/SendStyleMail',
        data: mailcontent,
        headers: {
            authorization: authHeader()
        }
    });
};

const getEmployeeListForStyleEmail = (styleID) => {
    return axios({
        method: 'post',
        url: window.$APIBaseURL + "Style/GetEmployeeListForStyleEmail?StyleID=" + styleID,
        headers: {
            authorization: authHeader()
        }
    });
}


const getStyleEmailList = (styleID) => {
    return axios({
        method: 'post',
        url: window.$APIBaseURL + "Style/StyleMailList?StyleID=" + styleID,
        headers: {
            authorization: authHeader()
        }
    });
}

const insertEmailAttachment = (attachment) => {
    return axios({
        method: 'post',
        url: window.$APIBaseURL + "Style/InsertEmailAttachment",
        data: attachment[0],
        headers: {
            authorization: authHeader()
        }
    });
}

const styleMailService = {
    sendstylemail,
    getEmployeeListForStyleEmail,
    getStyleEmailList,
    insertEmailAttachment
};

export default styleMailService;
