import React, { useState } from "react";

const Header = ({ headers, onSorting }) => {
    const [sortingField, setSortingField] = useState("");
    const [sortingOrder, setSortingOrder] = useState("asc");
    const [sortingIcon, setSortingIcon] = useState("");
    const [classname, setclassname] = useState("");

    const onSortingChange = (field) => {
        const order =
            (field === sortingField && sortingOrder === "asc") ? "desc" : "asc";
        setSortingField(field);
        setSortingOrder(order);
        setSortingIcon(order);
        onSorting(field, order);
    };

    return (
        <thead >
            {/* <tr className="table_title">
                {headers.map(({ name, field, sortable, widthsize, display, className, position, left, background, zindex }) => (
                    <th className={(sortable === true ? (sortingField === field ?
                        (sortingIcon === "asc"
                            ? "sorting_desc"
                            : sortingIcon === "desc" ? "sorting_asc" : "sorting") : "sorting") : (className !== "" ? className : '')) || (className == "" ? '' : (display = "none"))
                    }
                        key={name}
                        onClick={() =>
                            sortable ? onSortingChange(field) : null
                        }
                        //  style={{ width: name === "Action" ? "10%" : "" }}
                        style={{ width: widthsize === undefined ? (name === "Action" ? "" : "") : (name === "Action" ? "" : widthsize), display: display, position: position, left: left, background: background, zIndex: zindex }}
                    >
                        &nbsp;&nbsp;&nbsp;&nbsp;{name}&nbsp;&nbsp;
                        {field === 'inspectionStatus' &&

                            <tr>
                                <td>
                                    <span title='Draft' className="btn btn-warning">
                                        D
                                    </span>
                                </td>
                                <td >
                                    <span title='Pass' className="btn  btn-success">
                                        P
                                    </span>

                                </td>
                                <td>
                                    <span title='Fail' className="btn  btn-danger">
                                        F
                                    </span>
                                </td>
                            </tr>

                        }
                        {field === 'approvalStatus' &&

                            <tr>
                                <td>
                                    <span title='Pending' className="btn btn-warning">
                                        P
                                    </span>
                                </td>
                                <td >
                                    <span title='Approved' className="btn  btn-success">
                                        A
                                    </span>
                                </td>
                                <td>
                                    <span title='Commercial Approved' className="btn  btn-primary">
                                        C A
                                    </span>
                                </td>
                                <td>
                                    <span title='Rejected' className="btn  btn-danger">
                                        R
                                    </span>
                                </td>
                            </tr>

                        }


                    </th>

                ))}
            </tr> */}
            <tr className="table_title">
                {headers.map(({ name, field, sortable, widthsize, display, className, position, left, background, zindex, padding, minWidth }) => {
                    // Determine the className for the column
                    const columnClassName = sortable
                        ? (sortingField === field
                            ? (sortingIcon === "asc" ? "sorting_desc" : "sorting_asc")
                            : "sorting")
                        : className || '';

                    // Set the display property
                    const displayStyle = sortable ? 'table-cell' : (display === "none" ? "none" : "table-cell");

                    return (
                        <th
                            className={columnClassName}
                            key={name}
                            onClick={() => sortable ? onSortingChange(field) : null}
                            style={{
                                minWidth: minWidth,
                                width: widthsize === undefined ? (name === "Action" ? "" : "") : (name === "Action" ? "" : widthsize),
                                display: displayStyle,
                                position: position,
                                left: left,
                                background: background,
                                zIndex: zindex,
                                padding: padding
                            }}
                        >
                            {(name !== 'Report Status' && name !== "Review Status") &&
                                <span style={{ width: "100%", display: "block" }} className="hdrHeaderHeight">{name}</span>}
                            {(name === 'Report Status' || name === "Review Status") && <span>{name}</span>}

                            {field === 'reportStatus' &&
                                <table style={{ margin: "auto" }}>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span title='Draft' className="btn btn-warning">D</span>
                                            </td>
                                            <td>
                                                <span title='Satisfied' className="btn btn-success">S</span>
                                            </td>
                                            <td>
                                                <span title='Not Satisfied' className="btn btn-danger">NTS</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            }

                            {field === 'reviewStatus' &&
                                <table style={{ margin: "auto" }}>
                                    <tbody>
                                        {/* <tr>
                                            <td>
                                                <span title='Pending' className="btn btn-warning">P</span>
                                            </td>
                                            <td>
                                                <span title='Approved' className="btn btn-success">A</span>
                                            </td>
                                            <td>
                                                <span title='Approved With Comments' className="btn btn-primary">C A</span>
                                            </td>
                                            <td>
                                                <span title='Rejected' className="btn btn-danger">R</span>
                                            </td>
                                        </tr> */}
                                    </tbody>
                                </table>
                            }
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
};

export default Header;
