import React, { useState, useEffect } from "react";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import { Modal, Button } from "react-bootstrap";
import PantoneService from "../../services/Master/PantoneService";
import Nodify from "../Common/ReactNotification";

const PantoneTypePopup = ({
  getPantoneTypeList,
  pantoneTypeCallback,
  showpopup,
}) => {
  const EmptyInputFields = [
    {
      PantoneTypeID: 0,
      PantoneTypeName: "",
    },
  ];

  const [buttonLoader, setButtonLoader] = useState(false);
  const [inputFields, setInputFields] = useState(EmptyInputFields);
  const [pantoneTypeList, setPantoneTypeList] = useState([]);
  const [show, setShow] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const values = [...inputFields];

  const handlePopupClose = () => {
    pantoneTypeCallback(false);
  };

  const handleInputChange = (index, event) => {
    let inputText = "";
    if (event.target.value.trim() !== "") {
      inputText = event.target.value;
    }
    values[index].PantoneTypeName = inputText;
    setInputFields(values);
  };

  const CheckOnblur = (index) => {
    if (getPantoneTypeList) {
      let ExistingPanotneType=getPantoneTypeList.find(d=>d.name.trim().toLowerCase()===values[index].PantoneTypeName.trim().toLowerCase());
        if (ExistingPanotneType!==undefined) {
          values[index].PantoneTypeName = "";
          setInputFields(values);
          Nodify("Warning!", "warning", "This name is already exist.");
          return false;
        }
    }
  };

  const ValidationPopup = (Msg) => {
    Nodify("Warning!", "warning", Msg);
    setButtonLoader(false);
    return false;
  };

  const SavePantoneInfo = (e) => {
    setButtonLoader(true);
    e.preventDefault();
    let Isvalid = false;
    
    if (!Isvalid) {
   
    if (values[0].PantoneTypeName === "") {
        setButtonLoader(false);
        setSubmitted(true);
        ValidationPopup("Please fill Pantone type.");
        Isvalid = true;

      }
      else
      {
        let ExistingPanotneType = getPantoneTypeList.find(d => d.name.trim().toLowerCase() === values[0].PantoneTypeName.trim().toLowerCase());
        if (ExistingPanotneType !== undefined) {
          setInputFields(values);
          Nodify("Warning!", "warning", "This name is already exist.");
          setButtonLoader(false);
          setSubmitted(true);
          values[0].PantoneTypeName = "";
          return false;
        } else {
          setSubmitted(false);
          let letPantoneType = {};
          letPantoneType.PantoneTypeInformation = values;
          letPantoneType.Createdby = 1;
          PantoneService.InsertUpdatePantoneTypeGrid(letPantoneType).then((res) => {
            if (res.data.outputResult === "1") {
              Nodify("Success!", "success", "Pantone Type added successfully.");
              pantoneTypeCallback(true);
            } else if (res.data.outputResult === "0") {
              setButtonLoader(false);
            }
          });
        }
        
      }
      // if (getPantoneTypeList) {
      //   let ExistingPanotneType = getPantoneTypeList.find(d => d.name.trim().toLowerCase() === values[0].PantoneTypeName.trim().toLowerCase());
      //   if (ExistingPanotneType !== undefined) {
      //     setInputFields(values);
      //     Nodify("Warning!", "warning", "This name is already exist.");
      //     setButtonLoader(false);
      //     setSubmitted(true);
      //     values[0].PantoneTypeName = "";
      //     return false;
      //   }  else {
      //     setSubmitted(false);
      //     let letPantoneType = {};
      //     letPantoneType.PantoneTypeInformation = values;
      //     letPantoneType.Createdby = 1;
      //     PantoneService.InsertUpdatePantoneTypeGrid(letPantoneType).then((res) => {
      //       if (res.data.outputResult === "1") {
      //         Nodify("Success!", "success", "Pantone Type added successfully.");
      //         pantoneTypeCallback(true);
      //       } else if (res.data.outputResult === "0") {
      //         setButtonLoader(false);
      //       }
      //     });
      //   }
      // } else {
      //   setButtonLoader(false);
      //   setSubmitted(true);
      //   ValidationPopup("Please fill this pantone type mandatory fields.");
      // }
      // if (values[0].PantoneTypeName === "") {
      //   setButtonLoader(false);
      //   setSubmitted(true);
      //   ValidationPopup("Please fill Pantone type.");
      // } else {
      //   if (values[0].PantoneTypeName === "") {
      //     Isvalid = true;
      //   }
      // }
    }
  };

  return (
    <Modal
      show={showpopup}
      size="md"
      dragable
      backdrop="static"
      keyboard={false}
      onHide={() => handlePopupClose()}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add PantoneType</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="registration-form">
          <Form>
            <div className="row">
              <div className="form-group col-sm-12">
                <label htmlFor="PantoneTypeName">
                  Pantone Type Name
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id={"PantoneTypeName_" + 0}
                  name="PantoneTypeName"
                  placeholder="Enter Pantone Type"
                  maxLength="50"
                  value={inputFields[0].PantoneTypeName}
                  onChange={(event) => handleInputChange(0, event)}
                  style={{
                    border:
                      submitted && !inputFields[0].PantoneTypeName
                        ? "1px solid red"
                        : "",
                  }}
                  // onBlur={() => CheckOnblur(0)}
                  autoFocus
                />
              </div>
            </div>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          className="btn btn-success"
          onClick={SavePantoneInfo}
          disabled={buttonLoader}
        >
          <i className="fa fa-check right"></i> &nbsp; Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default PantoneTypePopup;
