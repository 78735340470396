import {
    SAMPLEREQ_LOADING,
    RETRIEVE_SAMPLEREQ,
    DELETE_SAMPLEREQ
} from "../actions/types";

const initialState = {
    isLoadingSamplereq: true,
    SamplereqList: [],
};

const samplereqReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SAMPLEREQ_LOADING:
            return {
                ...state,
                isLoadingSamplereq: payload
            };
        case RETRIEVE_SAMPLEREQ:
            return { ...state, SamplereqList: payload };
        case DELETE_SAMPLEREQ:
            const results = state.SamplereqList.filter(c => c.sampleReqID !== payload.sampleReqID);
            return {
                ...state,
                SamplereqList: results
            };

        default:
            return state;
    }
};
export default samplereqReducer;