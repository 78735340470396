import React, { useCallback } from 'react';
import { useEffect, useMemo, useState, Fragment } from 'react';
import Form from "react-validation/build/form";
import Nodify from '../Common/ReactNotification';
import { useSelector } from 'react-redux';
import TestStandardService from '../../services/Master/TestStandardService';
import BrandWiseStandardService from '../../services/Master/Brandwisestandard';

const TestStandardPopUp = ({ props, TestID, StandardOptions, TestStandardPopUpCallback }) => {
    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const EmptyInputFields = { TestID: 0, CommentID: 0, Comment: '' };
    const [inputFields, setInputFields] = useState([EmptyInputFields]);
    const { user: currentUser } = useSelector((state) => state.auth);
    const values = [...inputFields];

    const SaveTestStandard = (e) => {
        setSubmitted(true);
        if (inputFields.length === 1 && inputFields[0].Comment !== '' && inputFields[0].Comment !== null && inputFields[0].Comment !== undefined) {
            var DuplicatedData = StandardOptions.filter((data => {
                if (data.label.toLowerCase().trim() === inputFields[0].Comment.toLowerCase().trim() && TestID === data.testNameID) {
                    return data;
                }
            }));
            if (DuplicatedData.length > 0) {
                inputFields[0].Comment = "";
                Nodify('Warning!', 'warning', 'This Test Standard is already exist.');
            }
            else {
                setSubmitted(false);
                let Param = {
                    TestID: TestID
                    , Comment: inputFields[0].Comment
                    , CreatedBy: currentUser.employeeinformationID
                }
                BrandWiseStandardService.InsertStandardQuickMaster(Param).then((res => {
                    if (res.data.outputResult === "1") {
                        TestStandardPopUpCallback(1, inputFields);
                    }
                    else if (res.data.outputResult === "0") {
                        setButtonLoader(false);
                        ValidationPopup("Error Occured!");
                    }
                }));
            }
        }
        else {
            setButtonLoader(false);
            setSubmitted(true);
            ValidationPopup("Please fill the mandatory(*) fields.");
        }
    }

    const handleInputChange = (event) => {
        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }
        values[0].Comment = inputText;
        setInputFields(values);
    }

    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
        return false;
    }

    return (
        <div id="registration-form">
            <Form>
                <div className="row row_left10">
                    <div className="col-sm-12 col-md-12 divder_style">
                        <div className="form-group">
                            <label>Standard<span className="text-danger">*</span>
                            </label>
                            <span className="input-icon icon-right">
                                <input type='test' className='form-control' name="txtTestStandard"
                                    id="txtTestStandard" rows="10" placeholder="Enter Test Standard"
                                    onChange={event => handleInputChange(event)}
                                    style={{ border: submitted && !inputFields[0].Comment ? '1px solid red' : '' }}
                                    autoComplete="off" maxLength="100" value={inputFields[0].Comment} />
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className="buttons-preview" align="right">
                        <button type="button" className="btn btn-success" onClick={SaveTestStandard} disabled={buttonLoader}>
                            <i className="fa fa-check right"></i>&nbsp;
                            Save
                        </button>
                    </div>
                </div>
            </Form>
        </div>
    )
};
export default TestStandardPopUp;
