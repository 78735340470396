import {
    LEAVE_DAY_DROPDOWN_LIST,
} from "./types";

import taskService from "../services/Master/TaskService";

export const loadLeaveDaysList = (Operation) => async (dispatch) => {
    try {
        const res = await taskService.getLoadLeaveDaysList();
        
        dispatch({
            type: LEAVE_DAY_DROPDOWN_LIST,
            payload: res.data,
        });
      
    } catch (err) {
        console.log(err);
    }
};