import {
    AQLFINALINSPECTION_LOADING,
    RETRIEVE_AQLFINALINSPECTION,
} from "./types";


import AqlFinalInsepctionService from "../services/Master/Aqlfinalinspection";


const AqlFinalInspectionLoading = (isStatus) => ({
    type: AQLFINALINSPECTION_LOADING,
    payload: isStatus,
});



export const retrieveAqlFinalInspection = (Id, operation) => async (dispatch) => {
    try {

        dispatch(AqlFinalInspectionLoading(true));
        const res = await AqlFinalInsepctionService.GetAqlFinalInspection(Id, operation);
        dispatch({
            type: RETRIEVE_AQLFINALINSPECTION,
            payload: res.data,
        });
        dispatch(AqlFinalInspectionLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(AqlFinalInspectionLoading(false));
    }
};
