import {
    COMPANY_LOADING,
    RETRIEVE_COMPANY,
    DELETE_COMPANY
} from "./types";



import companyService from "../services/Master/Companyservice";
//import { CompanyModuleId } from "../components/Common/ModuleID";

const companyloading = (isStatus) => ({
    type: COMPANY_LOADING,
    payload: isStatus,
});


export const deleteCompany = (companyBuyerSupID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_COMPANY,
            payload: companyBuyerSupID,
        });

    } catch (err) {
        console.log(err);
    }
};


export const retrieveCompany = (parmas) => async (dispatch) => {
    try {
       
        dispatch(companyloading(true));
        const res = await companyService.getAllCompanyBuyerSupList(parmas.companyid, parmas.tabletype);
        dispatch({
            type: RETRIEVE_COMPANY,
            payload: res.data,
        });
        dispatch(companyloading(false));
    } catch (err) {
        console.log(err);
        dispatch(companyloading(false));
    }
};



