import React, { useState, useEffect, Fragment } from "react";
import 'react-notifications-component/dist/theme.css';
import Form from "react-validation/build/form";
import Select from "react-validation/build/select";
import Nodify from "../../Common/ReactNotification"
import { emailExp } from "../../Common/RegExpression";
import { useSelector } from "react-redux";

const BuyerBank = ({
    BankCallback,
    IsValidationCallback,
    citylist,
    BankList,
    Id,
    Buttonview,
    MenuPermission,
    PageName
}) => {
    const [inputFields, setInputFields] = useState([{
        BankID: 0,
        BankName: '',
        BranchName: '',
        BranchCode: '',
        BankAccountNumber: '',
        BankAccountType: 0,
        BankAddress: '',
        BankCity: 0,
        BankState: 0,
        BankCountry: 0,
        BankPrimaryEmail: '',
        BankSecondaryEmail: '',
        BankPrimaryContactNo: '',
        BankSecondaryContactNo: '',
        BankAddress2: '',
        StateName: '',
        CountryName: '',
        IsDeleted: 0
    }]);
    //enbale Menu
    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    if (menulist !== undefined) {
        if (PageName === 'Buyer') {
            activeMenu = menulist.filter(i => i.menuName === "BuyerList");
        }
    }
    //end enable
    const cityList = citylist;
    const [submitted, setSubmitted] = useState(false);

    let DynamicClassName = "col-lg-10 col-sm-8 col-xs-12"
    if (Buttonview === "View") {
        DynamicClassName = "col-lg-12 col-sm-12 col-xs-12"
    }
    useEffect(() => {
        if (BankList.length !== 0) {
            setInputFields([]);
        }
        let bankinput = [];
        BankList.forEach(BankList => {
            bankinput.push({
                BankID: parseInt(BankList.bankID),
                BankName: BankList.bankName,
                BranchName: BankList.branchName,
                BranchCode: BankList.branchCode,
                BankAccountNumber: BankList.bankAccountNumber,
                BankAccountType: parseInt(BankList.bankAccountType),
                BankAddress: BankList.bankAddress,
                BankCity: parseInt(BankList.bankCity),
                BankState: parseInt(BankList.bankState),
                BankCountry: parseInt(BankList.bankCountry),
                BankPrimaryEmail: BankList.bankPrimaryEmail,
                BankSecondaryEmail: BankList.bankSecondaryEmail,
                BankPrimaryContactNo: BankList.bankPrimaryContactNo,
                BankSecondaryContactNo: BankList.bankSecondaryContactNo,
                BankAddress2: BankList.bankAddress2,
                StateName: BankList.stateName + " / " + BankList.countryName,
                CountryName: BankList.countryName,
                IsDeleted: BankList.isDeleted === undefined ? 0 : BankList.isDeleted,

            });
            setInputFields(bankinput);
            BankCallback(bankinput);
        })
        // if (Id !== 0) {
        //     if (BankList.length !== 0) {
        //         const values = [...inputFields];
        //         values.splice(0, 1);
        //         setInputFields(values);
        //     }
        // }
    }, [BankList]);

    useEffect(() => {
        setSubmitted(IsValidationCallback);
    }, [IsValidationCallback === true]);

    const handleInputChange = (index, event) => {
        const values = [...inputFields];
        let inputText = '';
        if (event.target.value.trim() !== '') { inputText = event.target.value; }
        if (event.target.name === "BankName") {
            values[index].BankName = inputText;
        } else if (event.target.name === "BankAccountNumber") {
            var x = inputText.replace(/\D/g, '').match('^[0-9]*$');
            values[index].BankAccountNumber = x[0];

        } else if (event.target.name === "BankCity") {
            values[index].BankCity = parseInt(event.target.value);
            let CityList = cityList;
            CityList = CityList.filter(city => city.id === parseInt(event.target.value));
            if (CityList.length === 0) {
                values[index].BankState = 0;
                values[index].BankCountry = 0;
                values[index].BankCity = 0;
                values[index].StateName = '';
                values[index].CountryName = '';
            }
            else if (CityList.length > 0) {
                values[index].BankState = parseInt(CityList[0].stateId);
                values[index].BankCountry = parseInt(CityList[0].countryId);
                values[index].StateName = (CityList[0].stateName + " / " + CityList[0].countryName);
                values[index].CountryName = (CityList[0].countryName);
            }
        }
        else if (event.target.name === "BankPrimaryEmail") {
            values[index].BankPrimaryEmail = event.target.value;
            // if (emailExp.test(event.target.value.trim() !== false)) {
            //     return false;
            // }
            // for (var i = 0; i < (inputFields.length); i++) {
            //     if (i === index) { }
            //     else if (values[i].BankPrimaryEmail === values[index].BankPrimaryEmail) {
            //         values[index].BankPrimaryEmail = "";
            //         Nodify('Warning!', 'warning', 'Email Address already Exist.');
            //     }
            // }
        }
        else if (event.target.name === "BranchName") {
            values[index].BranchName = inputText;
        }
        else if (event.target.name === "BankAccountType") {
            values[index].BankAccountType = parseInt(event.target.value);
        }
        else if (event.target.name === "BranchCode") {
            values[index].BranchCode = inputText;
            // for (var i = 0; i < (inputFields.length); i++) {
            //     if (i === index) { }
            //     else if (values[i].BranchCode === values[index].BranchCode) {
            //         if (values[index].BranchCode !== '') {
            //             values[index].BranchCode = "";
            //             Nodify('Warning!', 'warning', 'Bank Code already Exist.');
            //         }
            //     }
            // }
        }
        else if (event.target.name === "BankAddress") {
            values[index].BankAddress = event.target.value;
        }
        else if (event.target.name === "BankAddress2") {
            values[index].BankAddress2 = event.target.value;
        }
        else if (event.target.name === "BankPrimaryContactNo") {
            // var z = event.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            // event.target.value = !z[2] ? z[1] : '(' + z[1] + ') ' + z[2] + (z[3] ? '-' + z[3] : '');
            values[index].BankPrimaryContactNo = event.target.value;
            for (var i = 0; i < (inputFields.length); i++) {
                if (i === index) { }
                else if (values[i].BankPrimaryContactNo === values[index].BankPrimaryContactNo) {
                    if (values[i].BankPrimaryContactNo !== '' && values[index].BankPrimaryContactNo !== '') {
                        values[index].BankPrimaryContactNo = "";
                        Nodify('Warning!', 'warning', 'Contact Number already Exist.');
                    }
                }
            }
        }
        setInputFields(values);
        BankCallback(values);
    };

    const CheckEmailAddress = (index) => {
        const values = [...inputFields];
        if (emailExp.test(values[index].BankPrimaryEmail.trim() !== false)) {
            return false;
        }
        for (var i = 0; i < (inputFields.length); i++) {
            if (i === index) { }
            else if (values[i].BankPrimaryEmail.trim() === values[index].BankPrimaryEmail.trim()) {
                if (values[i].BankPrimaryEmail !== '' && values[index].BankPrimaryEmail !== '') {
                    values[index].BankPrimaryEmail = "";
                    Nodify('Warning!', 'warning', 'Email Address already Exist.');
                }
            }
        }
        setInputFields(values);
        BankCallback(values);
    }

    const CheckBankCodeName = (index) => {
        const values = [...inputFields];
        for (var i = 0; i < (inputFields.length); i++) {
            if (i === index) { }
            else if (values[i].BranchCode === values[index].BranchCode) {
                if (values[index].BranchCode !== '' && values[index].BranchCode !== '') {
                    values[index].BranchCode = "";
                    Nodify('Warning!', 'warning', 'Bank Code already Exist.');
                }
            }
        }
        setInputFields(values);
        BankCallback(values);
    }

    const CheckAccountNumber = (index) => {
        const values = [...inputFields];
        for (var i = 0; i < (inputFields.length); i++) {
            if (i === index) { }
            else if (values[i].BankAccountNumber === values[index].BankAccountNumber) {
                if (values[index].BankAccountNumber !== '' && values[index].BankAccountNumber !== '') {
                    values[index].BankAccountNumber = "";
                    Nodify('Warning!', 'warning', 'Account Number already Exist.');
                }
            }
        }
        setInputFields(values);
        BankCallback(values);
    }


    const handleAddFields = (index) => {
        const values = [...inputFields];
        var CheckValue1 = [...inputFields].filter(x => x.IsDeleted === 0);
        var CheckValue = CheckValue1[index];
        if (CheckValue.BankName !== '' && CheckValue.BranchCode !== '' && CheckValue.BranchName !== '' &&
            CheckValue.BankAccountNumber !== '' && CheckValue.BankAccountType !== 0 &&
            CheckValue.BankAddress !== '' && CheckValue.BankCity !== 0 && CheckValue.BankState !== 0 &&
            CheckValue.BankCountry !== 0 && CheckValue.BankPrimaryContactNo !== ''
        ) {
            let Empmsg = "";
            if (CheckValue.BankPrimaryEmail !== "") {
                if (emailExp.test(CheckValue.BankPrimaryEmail) === false) {
                    Empmsg = "Email Address is not valid";
                    ValidationPopup(Empmsg);
                }
            }
            // if (CheckValue.BankPrimaryContactNo.length !== 20) {
            //     Empmsg = "Contact number is not valid";
            //     ValidationPopup(Empmsg);
            // }
            if (Empmsg === "") {
                values.push({
                    BankID: 0,
                    BankName: '',
                    BranchName: '',
                    BranchCode: '',
                    BankAccountNumber: '',
                    BankAccountType: 0,
                    BankAddress: '',
                    BankCity: 0,
                    BankState: 0,
                    BankCountry: 0,
                    BankPrimaryEmail: '',
                    BankSecondaryEmail: '',
                    BankPrimaryContactNo: '',
                    BankSecondaryContactNo: '',
                    BankAddress2: '',
                    IsDeleted: 0
                });
                setInputFields(values);
                setSubmitted(false);
            }
        } else {
            setSubmitted(true);
            ValidationPopup("Please fill this Bank mandatory(*) fields.");
        }
    };
    const ValidationPopup = (Msg) => {
        Nodify('Warning!', 'warning', Msg);

        return false;
    }
    const handleRemoveFields = index => {
        const values = [...inputFields];
        if (values[index].BankID === 0) {
            values.splice(index, 1);
        }
        else {
            values[index].IsDeleted = 1;
        }
        setInputFields(values);
        BankCallback(values);
    };

    return (
        <>
            {
                inputFields.map((inputField, index) => (
                    <Fragment key={`${inputField}~${index}`}>
                        <div className={DynamicClassName} style={
                            {
                                display: inputField.IsDeleted === 1 ? "none" : "block"
                            }
                        }>
                            <div className="widget flat radius-bordered">
                                <div className="panel-group accordion" id="accordion">
                                    <div className="panel panel-default">
                                        <div className="panel-heading ">
                                            <h4 className="mb-0 panel-title">
                                                <a className="btn btn-link font_wight" data-toggle="collapse" data-parent="accordion" href={"#collapseBank_" + index}>
                                                    {inputField.BankID === 0 ? 'New bank' : inputField.BankName}
                                                </a>
                                            </h4>
                                        </div>
                                        <div id={"collapseBank_" + index} className={inputField.BankID === 0 ? 'panel-collapse collapse in' : 'panel-collapse collapse'}>
                                            <div className="widget-body">
                                                <div id="registration-form">
                                                    <Form role="form">
                                                        <div className="row">
                                                            <div className="col-sm-4">
                                                                <div className="form-group">
                                                                    <label htmlFor={"BankName_" + index}>
                                                                        Bank Name<span className="text-danger">*</span>
                                                                    </label>
                                                                    <span className="input-icon icon-right">
                                                                        <input type="text" className="form-control" id={"BankName_" + index}
                                                                            name="BankName" placeholder="Enter Bank Name"
                                                                            maxLength="50" value={inputField.BankName}
                                                                            onChange={event => handleInputChange(index, event)}
                                                                            style={{ border: submitted && !inputField.BankName ? '1px solid red' : '' }}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <div className="col-sm-4">
                                                                <div className="form-group">
                                                                    <label htmlFor={"BranchName_" + index}>
                                                                        Branch  Name<span className="text-danger">*</span>
                                                                    </label>
                                                                    <span className="input-icon icon-right">
                                                                        <input type="text" className="form-control" id={"BranchName_" + index}
                                                                            name="BranchName" placeholder="Enter Branch Name"
                                                                            maxLength="50" value={inputField.BranchName}
                                                                            onChange={event => handleInputChange(index, event)}
                                                                            style={{ border: submitted && !inputField.BranchName ? '1px solid red' : '' }}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <div className="col-sm-4">
                                                                <div className="form-group">
                                                                    <label htmlFor={"BranchCode_" + index}>
                                                                        Bank Code<span className="text-danger">*</span>
                                                                    </label>
                                                                    <span className="input-icon icon-right">
                                                                        <input type="text" className="form-control" id={"BranchCode_" + index}
                                                                            name="BranchCode" placeholder="Enter Bank Code" maxLength="50"
                                                                            value={inputField.BranchCode}
                                                                            onChange={event => handleInputChange(index, event)}
                                                                            style={{ border: submitted && !inputField.BranchCode ? '1px solid red' : '' }}
                                                                            onBlur={event => CheckBankCodeName(index)}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-sm-4">
                                                                <div className="form-group">
                                                                    <label htmlFor={"BankAccountNumber_" + index}>
                                                                        Account Number<span className="text-danger">*</span>
                                                                    </label>
                                                                    <span className="input-icon icon-right">
                                                                        <input type="text" className="form-control" id={"BankAccountNumber_" + index}
                                                                            name="BankAccountNumber" placeholder="Enter Account Number"
                                                                            maxLength="50" value={inputField.BankAccountNumber}
                                                                            onChange={event => handleInputChange(index, event)}
                                                                            style={{ border: submitted && !inputField.BankAccountNumber ? '1px solid red' : '' }}
                                                                            onBlur={event => CheckAccountNumber(index)}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <div className="col-sm-4">
                                                                <div className="form-group">
                                                                    <label htmlFor={"BankAccountType_" + index}>
                                                                        Account Type <span className="text-danger">*</span>
                                                                    </label>
                                                                    <span className="input-icon icon-right">
                                                                        <select id={"BankAccountType_" + index} name="BankAccountType" className="form-select"
                                                                            value={parseInt(inputField.BankAccountType)}
                                                                            onChange={event => handleInputChange(index, event)}
                                                                            style={{ border: submitted && !inputField.BankAccountType ? '1px solid red' : '' }}
                                                                        >
                                                                            <option value="0">- Select Account Type -</option>
                                                                            <option value="1" key="1">Current</option>
                                                                            <option value="2" key="2">Savings</option>
                                                                        </select>
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <div className="col-sm-4">
                                                                <div className="form-group">
                                                                    <label htmlFor={"BankAddress_" + index}>
                                                                        Address 1<span className="text-danger">*</span>
                                                                    </label>
                                                                    <span className="input-icon icon-right">
                                                                        <textarea type="text" id={"BankAddress_" + index}
                                                                            name="BankAddress" placeholder="Enter Address" maxLength="300"
                                                                            value={inputField.BankAddress}
                                                                            onChange={event => handleInputChange(index, event)}
                                                                            style={{
                                                                                height: "70px", width: "100%",
                                                                                border: submitted && !inputField.BankAddress ? '1px solid red' : ''
                                                                            }}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-sm-4">
                                                                <div className="form-group">
                                                                    <label htmlFor={"BankAddress2_" + index}>
                                                                        Address 2
                                                                    </label>
                                                                    <span className="input-icon icon-right">
                                                                        <textarea type="text" className="form-control" id={"BankAddress2_" + index}
                                                                            name="BankAddress2" placeholder="Enter Address" maxLength="300"
                                                                            value={inputField.BankAddress2}
                                                                            onChange={event => handleInputChange(index, event)}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="form-group">
                                                                    <label htmlFor={"BankCity_" + index}>
                                                                        City<span className="text-danger">*</span>
                                                                    </label>
                                                                    <span className="input-icon icon-right">
                                                                        <Select id={"BankCity_" + index} name="BankCity"
                                                                            className="form-select" value={inputField.BankCity}
                                                                            onChange={event => handleInputChange(index, event)}
                                                                            style={{ border: submitted && !inputField.BankCity ? '1px solid red' : '' }}
                                                                        >
                                                                            <option value="0">- Select City -</option>
                                                                            {
                                                                                cityList.map(item => (
                                                                                    <option key={item.id} value={item.id}>
                                                                                        {item.name}
                                                                                    </option>
                                                                                ))
                                                                            }
                                                                        </Select>
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <div className="col-sm-4">

                                                                <div className="form-group">
                                                                    <label htmlFor={"BankState_" + index}>
                                                                        State / Country<span className="text-danger">*</span>
                                                                    </label>
                                                                    <span className="input-icon icon-right">
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            id={"BankState_" + index}
                                                                            name="BankState"
                                                                            placeholder="State / Country"
                                                                            maxLength="50"
                                                                            value={inputField.StateName}
                                                                            disabled
                                                                            tabIndex="-1"
                                                                            style={
                                                                                {
                                                                                    border: submitted && !inputField.StateName ? '1px solid red' : ''
                                                                                }}
                                                                        />

                                                                    </span>
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-sm-6">
                                                                    <div className="form-group">
                                                                        <label htmlFor={"BankCountry_" + index}>
                                                                            Country<span className="text-danger">*</span>
                                                                        </label>
                                                                        <span className="input-icon icon-right">
                                                                            <input type="text"
                                                                                className="form-control"
                                                                                id={"BankCountry_" + index}
                                                                                name="BankCountry"
                                                                                placeholder="Country"
                                                                                maxLength="50"
                                                                                value={inputField.CountryName}
                                                                                disabled
                                                                                tabIndex="-1"
                                                                                style={
                                                                                    {
                                                                                        border: submitted && !inputField.CountryName ? '1px solid red' : ''
                                                                                    }}
                                                                            />

                                                                        </span>
                                                                    </div>
                                                                </div> */}


                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-4">
                                                                <div className="form-group">
                                                                    <label htmlFor={"BankPrimaryEmail_" + index}>
                                                                        Email Address
                                                                    </label>
                                                                    <span className="input-icon icon-right">
                                                                        <input type="text" className="form-control"
                                                                            id={"BankPrimaryEmail_" + index} name="BankPrimaryEmail"
                                                                            maxLength="50" placeholder="Enter Email Address" autoComplete="off"
                                                                            value={inputField.BankPrimaryEmail}
                                                                            onChange={event => handleInputChange(index, event)}
                                                                            onBlur={event => CheckEmailAddress(index)}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>


                                                            <div className="col-sm-4">
                                                                <div className="form-group">
                                                                    <label htmlFor={"BankPrimaryContactNo_" + index}>
                                                                        Contact Number<span className="text-danger"> *</span>
                                                                    </label>
                                                                    <span className="input-icon icon-right">
                                                                        <input type="text" className="form-control" id={"BankPrimaryContactNo_" + index}
                                                                            name="BankPrimaryContactNo" placeholder="Enter Contact Number"
                                                                            maxLength={20}
                                                                            value={inputField.BankPrimaryContactNo}
                                                                            onChange={event => handleInputChange(index, event)}
                                                                            style={{ border: submitted && !inputField.BankPrimaryContactNo ? '1px solid red' : '' }}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* {
                            Buttonview !== "View" &&
                            <>
                                <div className="col-lg-1_own col-xs-12">
                                    <span style={
                                        {
                                            display: (inputFields.length === 1 ? "none" : "")
                                        }
                                    }>
                                        <button className="btn btn-danger" title="Delete Bank"
                                            onClick={
                                                () => handleRemoveFields(index)
                                            }>
                                            <i className="fa fa-trash-o"></i>
                                        </button>
                                    </span>
                                </div>
                                <div className="col-lg-1_own col-xs-12">
                                    <span>
                                        <span style={
                                            {
                                                display: (inputFields.length === (index + 1) ? "" : "none")
                                            }
                                        }>
                                            <button className="btn btn-success" title="Add Bank"
                                                onClick={
                                                    () => handleAddFields(index)
                                                }>
                                                <i className="fa fa-plus"></i>
                                            </button>
                                        </span>

                                    </span>
                                </div>

                            </>
                        } */}
                        {MenuPermission && MenuPermission.isAdd === 1 &&
                            Buttonview !== "View" && <div className="col-lg-1_own col-xs-12"
                                style={
                                    {
                                        display: inputField.IsDeleted === 1 ? "none" : "block"
                                    }
                                } >
                                <span>
                                    <span style={
                                        {
                                            display: inputField.IsDeleted === 1 ? "none" : (inputFields.filter(x => x.IsDeleted === 0).length === 1 ? "none" : "")
                                        }
                                    }>{MenuPermission && MenuPermission.isDelete === 1 &&

                                        <button className="btn btn-danger" title="Delete Bank"
                                            style={{ display: MenuPermission && MenuPermission.isDelete === 1 ? '' : 'none' }}
                                            onClick={
                                                () => handleRemoveFields(index)
                                            }>
                                            <i className="fa fa-trash-o"></i>
                                        </button>
                                        }
                                    </span>
                                </span>
                            </div>
                        }
                    </Fragment>
                ))
            }
            {MenuPermission !== undefined && MenuPermission.isAdd === 1 &&
                Buttonview !== "View" &&
                inputFields.filter(x => x.IsDeleted === 0).map((inputField1, index) => (

                    inputFields.filter(x => x.IsDeleted === 0).length === (index + 1) && (
                        <div className="col-lg-1_own col-xs-12">
                            <span>
                                <button className="btn btn-success" title="Add Bank"
                                    style={{ display: MenuPermission && MenuPermission.isAdd === 1 ? '' : 'none' }}
                                    onClick={
                                        () => handleAddFields(inputFields.filter(x => x.IsDeleted === 0).length - 1)
                                    }>
                                    <i className="fa fa-plus"></i>
                                </button>
                            </span>
                        </div>)
                ))
            }
        </>
    );
};

export default BuyerBank;
