import axios from "axios";
import authHeader from "../auth-header";

class DefectServices {

 getAllDefectList() {
  return axios.get(window.$APIBaseURL + "Master/getAllDefectList?DefectTypeID=0", {
   headers: {
    authorization: authHeader()
   }
  });
 }

 InsertUpdateDefect(Params) {
  
  return axios({
   method: 'post',
   url: window.$APIBaseURL + 'Master/InsertUpdateDefect',
   data: Params,
   headers: {
    authorization: authHeader()
   }
  });
 }

 AddDefect(Params) {
  return axios({
   method: 'post',
   url: window.$APIBaseURL + 'Master/InsertUpdateDelDefect',
   data: Params,
   headers: {
    authorization: authHeader()
   }
  });
 }




}


export default new DefectServices()