import {
    SIZE_LOADING,
    RETRIEVE_SIZE,
    DELETE_SIZE,
    DROPDOWN_SIZE_LIST
} from "../actions/types";

const initialState = {
    isLoadingSize: true,
    sizeList: [],
    drpSizeList:[]
};

const sizeReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SIZE_LOADING:
            return {
                ...state,
                isLoadingSize: payload
            };
        case RETRIEVE_SIZE:
            return { ...state, sizeList: payload };
        case DELETE_SIZE:
            const results = state.sizeList.filter(c => c.sizeDetailID !== payload.sizeDetailID);
            return {
                ...state,
                sizeList: results
            };
        case DROPDOWN_SIZE_LIST:
            
            return {
                ...state,
                drpSizeList: payload
            };
        default:
            return state;
    }
};
export default sizeReducer;