import axios from "axios";
import authHeader from "../auth-header";

class CurrencyService {
    GetCurrencyList() {
        return axios.get(window.$APIBaseURL + "Master/GetCurrencyList?CurrencyID=0", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateCurrencyGrid(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateCurrencyGrid',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

      InsertUpdateCurrency(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateCurrency',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
}
export default new CurrencyService()