import React, { useState, useEffect, Fragment } from "react";
import Form from "react-validation/build/form";
import Select from "react-validation/build/select";
import DatePicker from "react-datepicker";
import UploadImage from "../../Common/EmployeeUploadImage";
//import UploadImage from "../../Common/UploadImage";
import $ from "jquery";
import companyService from "../../../services/Master/Companyservice";
import PasswordStrengthBar from 'react-password-strength-bar';
import { emailExp } from "../../Common/RegExpression";
import Nodify from "../../Common/ReactNotification"
import SelectCombobox from 'react-select';
import { Modal, Button } from "react-bootstrap";
import DepartmentService from "../../../services/Master/DepartmentService";
import DepartmentPopup from "../../quickMaster/_Department";
import RoleService from "../../../services/Master/RoleService";
import RolePopup from "../../quickMaster/_Role";
import CommonService from '../../../services/Common/CommonService';
import { retrieveMenuname } from "../../../actions/UserPermission";
import { useDispatch, useSelector } from "react-redux";


const CompanyEmployee = ({
    props,
    employeeCallback,
    IsValidationCallback,
    citylist,
    genderlist,
    EmployeeList,
    Id,
    Buttonview,
    PageName,
    MenuPermission,
    EmployeeInfoQuick
}) => {
    const [inputFields, setInputFields] = useState([{
        EmployeeinformationID: 0,
        EmpFirstName: '',
        EmpLastName: '',
        EmpDateofBirth: '',
        EmpGender: 0,
        EmpDateofJoin: '',
        EmpDepartmentID: 0,
        EmpRoleID: 0,
        EmpUserName: '',
        EmpPassword: '',
        ConfirmPassword: '',
        EmpCompanyAdministrator: 0,
        EmpAddressLine1: '',
        EmpAddressLine2: '',
        EmpCity: 0,
        EmpState: 0,
        EmpCountry: 0,
        EmpPostalCode: '',
        EmpPrimaryContactNumber: '',
        EmpSecondaryContactNumber: '',
        EmpProfilePicName: '',
        EmpProfilePicPath: '',
        ActiveStatus: 1,
        IsDeleted: 0,
        StateName: '',
        CountryName: ''
    }]);
    const handlePopupClose = () => {
        setQuickMasterName('');
    };


    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    if (menulist !== undefined) {
        if (PageName === 'Company') {
            activeMenu = menulist.filter(i => i.menuName === 'Company');
        }
        else if (PageName === 'Buyer') {
            activeMenu = menulist.filter(i => i.menuName === "BuyerList");
        }
        else if (PageName === 'Supplier') {
            activeMenu = menulist.filter(i => i.menuName === "Supplier");
        }
    }
    //end enable menu
    const cityList = citylist;
    const genderList = genderlist;
    const [submitted, setSubmitted] = useState(false);
    const [getEmpDropdown, setEmpDropdown] = useState("0");
    const [CompanyEmployeeList, setCompanyEmployeeList] = useState([]);
    const [getQuickMasterName, setQuickMasterName] = useState('');
    //const [DepartmentList, setDepartmentList] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [DepartmentList, setDepartmentList] = useState([]);
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth();
    let day = currentDate.getDate();
    const DOBDate = new Date(year - 18, month, day);

    const dispatch = useDispatch();


    let DynamicClassName = "col-lg-10 col-sm-8 col-xs-12"

    if (Buttonview === "View") {
        DynamicClassName = "col-lg-12 col-sm-12 col-xs-12"
    }

    let UserTypeID = 2
    if (PageName === 'Company') { UserTypeID = 2; }
    else if (PageName === 'Buyer') { UserTypeID = 3; }
    else if (PageName === 'Supplier') { UserTypeID = 4; }

    const AddEmployeeback = (value, index) => {

        const values = [...inputFields];
        let profile = value !== '' ? "/EmployeeImg/" : '';
        values[index].EmpProfilePicName = value;
        values[index].EmpProfilePicPath = profile + value;
        setInputFields(values);
        employeeCallback(values);
    }

    useEffect(() => {

        dispatch(retrieveMenuname());
    }, []);



    useEffect(() => {
       ;
        DepartmentService.LoadDepartmentList().then((response) => {
            if (response.data) {
                setDepartmentList(response.data);
            }
        }).catch(() => { });
        let UserTypeID = 2
        if (PageName === 'Company') { UserTypeID = 2; }
        else if (PageName === 'Buyer') { UserTypeID = 3; }
        else if (PageName === 'Supplier') { UserTypeID = 4; }
        RoleService.LoadRoleList(UserTypeID).then((response) => {
            if (response.data) {
                setRoleList(response.data);
            }
        }).catch(() => { });

    }, []);

    useEffect(() => {
        if (EmployeeList.length !== 0) {
            setInputFields([]);
        }
        let employeeList = [];
        EmployeeList.forEach(EmployeeList => {
            employeeList.push({
                EmployeeinformationID: parseInt(EmployeeList.employeeinformationID),
                EmpFirstName: EmployeeList.empFirstName,
                EmpLastName: EmployeeList.empLastName,
                EmpDateofBirth: EmployeeList.empDateofBirth === null ? "" : new Date(EmployeeList.empDateofBirth),
                EmpGender: EmployeeList.empGender,
                EmpDateofJoin: EmployeeList.empDateofJoin === null ? "" : new Date(EmployeeList.empDateofJoin),
                EmpDepartmentID: parseInt(EmployeeList.empDepartmentID),
                EmpRoleID: parseInt(EmployeeList.empRoleID),
                EmpUserName: EmployeeList.empUserName,
                EmpPassword: EmployeeList.empPassword,
                ConfirmPassword: EmployeeList.empPassword,
                EmpCompanyAdministrator: EmployeeList.empCompanyAdministrator,
                EmpAddressLine1: EmployeeList.empAddressLine1,
                EmpAddressLine2: EmployeeList.empAddressLine2,
                EmpCity: parseInt(EmployeeList.empCity),
                EmpState: parseInt(EmployeeList.empState),
                EmpCountry: parseInt(EmployeeList.empCountry),
                EmpPostalCode: EmployeeList.empPostalCode,
                EmpPrimaryContactNumber: EmployeeList.empPrimaryContactNumber,
                EmpSecondaryContactNumber: EmployeeList.empSecondaryContactNumber,
                EmpProfilePicName: EmployeeList.empProfilePicName,
                EmpProfilePicPath: EmployeeList.empProfilePicPath,
                ActiveStatus: EmployeeList.activeStatus,
                IsDeleted: EmployeeList.isDeleted === undefined ? 0 : EmployeeList.isDeleted,
                StateName: EmployeeList.stateName + " / " + EmployeeList.countryName,
                CountryName: EmployeeList.countryName

            });
            setInputFields(employeeList);
            setCompanyEmployeeList(employeeList.filter(item => item.EmployeeinformationID !== 0).sort((a, b) => 1 * a.EmpFirstName.localeCompare(b.EmpFirstName)));
            employeeCallback(employeeList)
        })
        // if (Id !== 0) {
        //     if (EmployeeList.length !== 0) {
        //         const values = [...inputFields];
        //         values.splice(0, 1);
        //         setInputFields(values);
        //     }
        // }
    }, [EmployeeList]);

    useEffect(() => {
        setSubmitted(IsValidationCallback);
    }, [IsValidationCallback === true]);


    const handleInputDateChange = (index, event, varName) => {
        const values = [...inputFields];
        if (varName === "EmpDateofBirth") {
            values[index].EmpDateofBirth = event;
        } else if (varName === "EmpDateofJoin") {
            values[index].EmpDateofJoin = event;
        }

        setInputFields(values);
        employeeCallback(values);
    };

    //Enter Error msg
    // useEffect(() => {
    //     const listener = event => {
    //         if (event.code === "Enter" || event.code === "NumpadEnter") {
    //             event.preventDefault();
    //             //SaveBuyerInfo(event);
    //         }
    //     };
    //     document.addEventListener("keydown", listener);
    //     return () => {
    //         document.removeEventListener("keydown", listener);
    //     };
    // }, []);

    const handleRefreshEmployee = (event) => {
        setInputFields([]);
        setTimeout(() => {
            let empEmpList = [];
            EmployeeList.forEach(Employee => {
                empEmpList.push({
                    EmployeeinformationID: parseInt(Employee.employeeinformationID),
                    EmpFirstName: Employee.empFirstName,
                    EmpLastName: Employee.empLastName,
                    EmpDateofBirth: Employee.empDateofBirth === null ? "" : new Date(Employee.empDateofBirth),
                    EmpGender: Employee.empGender,
                    EmpDateofJoin: Employee.empDateofJoin === null ? "" : new Date(Employee.empDateofJoin),
                    EmpDepartmentID: parseInt(Employee.empDepartmentID),
                    EmpRoleID: parseInt(Employee.empRoleID),
                    EmpUserName: Employee.empUserName,
                    EmpPassword: Employee.empPassword,
                    ConfirmPassword: Employee.empPassword,
                    EmpCompanyAdministrator: Employee.empCompanyAdministrator,
                    EmpAddressLine1: Employee.empAddressLine1,
                    EmpAddressLine2: Employee.empAddressLine2,
                    EmpCity: parseInt(Employee.empCity),
                    EmpState: parseInt(Employee.empState),
                    EmpCountry: parseInt(Employee.empCountry),
                    EmpPostalCode: Employee.empPostalCode,
                    EmpPrimaryContactNumber: Employee.empPrimaryContactNumber,
                    EmpSecondaryContactNumber: Employee.empSecondaryContactNumber,
                    EmpProfilePicName: Employee.empProfilePicName,
                    EmpProfilePicPath: Employee.empProfilePicPath,
                    ActiveStatus: Employee.activeStatus,
                    IsDeleted: Employee.isDeleted === undefined ? 0 : Employee.isDeleted,
                    StateName: Employee.stateName + " / " + Employee.countryName,
                    CountryName: Employee.countryName

                });
            });
            setInputFields(empEmpList);
            setCompanyEmployeeList(empEmpList.filter(item => item.EmployeeinformationID !== 0).sort((a, b) => 1 * a.EmpFirstName.localeCompare(b.EmpFirstName)));
            setEmpDropdown(0);
        }, 500)
    }

    const handleInputChange = (index, event) => {
       ;
        const values = [...inputFields];
        let inputText = '';
        if (event.target.value.trim() !== '') { inputText = event.target.value; }
    
        if (event.target.name === "EmpFirstName") {
            values[index].EmpFirstName = inputText;
        } else if (event.target.name === "EmpLastName") {
            values[index].EmpLastName = inputText;
        } else if (event.target.name === "EmpGender") {
            values[index].EmpGender = parseInt(event.target.value);
        } else if (event.target.name === "EmpDepartmentID") {
            values[index].EmpDepartmentID = parseInt(event.target.value);
        } else if (event.target.name === "EmpRoleID") {
            values[index].EmpRoleID = parseInt(event.target.value);
        } else if (event.target.name === "EmpUserName") {
            values[index].EmpUserName = inputText.trim();
        } else if (event.target.name === "EmpPassword") {
            values[index].EmpPassword = inputText.trim();
        } else if (event.target.name === "ConfirmPassword") {
            values[index].ConfirmPassword = inputText.trim();
        } else if (event.target.name === "EmpCompanyAdministrator") {
            if (event.target.type === 'checkbox') {
                if (!event.target.checked) {
                    let Params = {
                        Operation: 44,
                        Id: values[index].EmployeeinformationID,
                    };
    
                    CommonService.CheckMappedItem(Params).then(res => {
                        if (res.data.outputResult === "-2") {
    
                            let Title = "Warning!";
                            let message = "This Employee is already mapped.";
                            let type = "warning";
                            Nodify(Title, type, message);
                            values[index].EmpCompanyAdministrator = 1;
                            setInputFields(values);
                            employeeCallback(values);
                        } else {
                            if (event && event.target) {
                                event.target.value = 0;
                                values[index].EmpCompanyAdministrator = parseInt(event.target.value);
                            } else {
                                values[index].EmpCompanyAdministrator = 0;
                            }
                            setInputFields(values);
                            employeeCallback(values);
                        }
                    });
                } else {
                    if (event && event.target) {
                        event.target.value = 1;
                        values[index].EmpCompanyAdministrator = parseInt(event.target.value);
                    } else {
                        values[index].EmpCompanyAdministrator = 1;
                    }
                    setInputFields(values);
                    employeeCallback(values);
                }
            } else {
                values[index].EmpCompanyAdministrator = parseInt(event.target.value);
                setInputFields(values);
                employeeCallback(values);
            }
        } else if (event.target.name === "EmpAddressLine1") {
            values[index].EmpAddressLine1 = inputText;
        } else if (event.target.name === "EmpAddressLine2") {
            values[index].EmpAddressLine2 = inputText;
        } else if (event.target.name === "EmpRoleID") {
            values[index].EmpRoleID = parseInt(event.target.value);
        } else if (event.target.name === "EmpDepartmentID") {
            values[index].EmpDepartmentID = parseInt(event.target.value);
        } else if (event.target.name === "EmpCity") {
            values[index].EmpCity = parseInt(event.target.value);
            let CityList = cityList;
            CityList = CityList.filter(city => city.id === parseInt(event.target.value));
            if (CityList.length === 0) {
                values[index].EmpState = 0;
                values[index].EmpCountry = 0;
                values[index].EmpCity = 0
                values[index].StateName = '';
                values[index].CountryName = '';
            } else if (CityList.length > 0) {
                values[index].EmpState = parseInt(CityList[0].stateId);
                values[index].EmpCountry = parseInt(CityList[0].countryId);
                values[index].StateName = (CityList[0].stateName + " / " + CityList[0].countryName);
                values[index].CountryName = (CityList[0].countryName);
            }
        } else if (event.target.name === "EmpPostalCode") {
            values[index].EmpPostalCode = inputText;
        } else if (event.target.name === "EmpPrimaryContactNumber") {
            values[index].EmpPrimaryContactNumber = inputText;
        } else if (event.target.name === "EmpSecondaryContactNumber") {
            values[index].EmpSecondaryContactNumber = event.target.value;
        } else if (event.target.name === "ActiveStatus") {
            if (event.target.type === 'checkbox') {
                 let Opreation;  
                ;
                if (PageName === 'Company') { Opreation = 140; }
                else if (PageName === 'Buyer') { Opreation = 141; }
                else if (PageName === 'Supplier') { Opreation = 142; }

                if (!event.target.checked) {
                    let Params = {
                        Operation: Opreation,
                        Id: values[index].EmployeeinformationID,
                    };
    
                    CommonService.CheckMappedItem(Params).then(res => {
                        if (res.data.outputResult === "-2") {
    
                            let Title = "Warning!";
                            let message = "This Employee is already mapped.";
                            let type = "warning";
                            Nodify(Title, type, message);
                            values[index].EmpCompanyAdministrator = 1;
                            setInputFields(values);
                            employeeCallback(values);
                        } else {
                            if (event && event.target) {
                                event.target.value = 0;
                                values[index].ActiveStatus = parseInt(event.target.value);
                            } else {
                                values[index].ActiveStatus = 0;
                            }
                            setInputFields(values);
                            employeeCallback(values);
                        }
                    });
                }
                else{
                        event.target.value = 1;
                        values[index].ActiveStatus = parseInt(event.target.value);
                        setInputFields(values);     
                }
            }
        }
    
        if (event.target.name !== "EmpCompanyAdministrator" && event.target.name !== "ActiveStatus") {
            setInputFields(values);
            employeeCallback(values);
        }
    }
    

    const CheckEmailAddress = (index) => {
        const values = [...inputFields];
        if (emailExp.test(values[index].EmpUserName) === false) { // return false;
        } else {
            for (var i = 0; i < (inputFields.length); i++) {
                if (i === index) { }
                else if (inputFields[i].EmpUserName.trim() === inputFields[index].EmpUserName.trim()) {
                    inputFields[index].EmpUserName = "";
                    values[index].EmpUserName = "";
                    $("#UserName_" + index).val("");
                    Nodify('Warning!', 'warning', "Email Address already Exist (" + values[index].EmpFirstName + " " + values[index].EmpLastName + ")");

                }
            }

            companyService.CheckEmailIdExist(1, values[index].EmpUserName).then((response) => {
                if (response.data.outputResult === "-1") {
                    inputFields[index].EmpUserName = "";
                    values[index].EmpUserName = "";
                    $("#UserName_" + index).val("");
                    Nodify('Warning!', 'warning', "Email Address already Exist (" + values[index].EmpFirstName + " " + values[index].EmpLastName + ")");
                }
            }).catch(() => { });
        }
        setInputFields(values);
        employeeCallback(values);
    }


    const CheckDuplicate = (index, event) => {
       ;
        const values = [...inputFields];

        if (event.target.name === "EmpPrimaryContactNumber") {
            for (var i = 0; i < (values.length); i++) {
                if (i !== index) {
                    if (values[i].EmpPrimaryContactNumber.trim() === values[index].EmpPrimaryContactNumber.trim()) {
                        values[index].EmpPrimaryContactNumber = "";
                        setInputFields(values);
                        //addressCallback(values);
                        employeeCallback(values);
                        Nodify('Warning!', 'warning', 'This Mobilenumber is already exist.');
                        return false;
                    }
                }
            }
        }
        else if (event.target.name === "EmpFirstName") {
            for (var i = 0; i < (values.length); i++) {
                if (i !== index) {
                    if (values[index].EmpFirstName.trim().toLocaleLowerCase() === values[i].EmpFirstName.trim().toLocaleLowerCase()) {
                        values[index].EmpFirstName = "";
                        setInputFields(values);
                        //addressCallback(values);
                        employeeCallback(values);
                        Nodify('Warning!', 'warning', 'This Employee Name is already exist.');
                        return false;
                    }
                }
            }
        }
    }

    const handleAddFields = (index) => {
        const values = [...inputFields];
        var CheckValue1 = [...inputFields].filter(x => x.IsDeleted === 0);
        var CheckValue = CheckValue1[index];
        if (CheckValue.EmpFirstName && CheckValue.EmpLastName && CheckValue.EmpGender && CheckValue.EmpAddressLine1 && CheckValue.EmpCity !== 0 && CheckValue.EmpPostalCode && CheckValue.EmpPrimaryContactNumber && CheckValue.EmpUserName && CheckValue.EmpPassword && CheckValue.ConfirmPassword) {
            let Empmsg = "";
            if (CheckValue.EmpPassword.length < 8) {
                Empmsg = "Password should be above 7 characters for (" + CheckValue.EmpFirstName + " " + CheckValue.EmpLastName + ")";
                ValidationPopup(Empmsg);
            }
            if (CheckValue.EmpPassword !== CheckValue.ConfirmPassword) {
                Empmsg = "Password and Confirm password is must be same for (" + CheckValue.EmpFirstName + " " + CheckValue.EmpLastName + ")";
                ValidationPopup(Empmsg);
            }
            if (emailExp.test(CheckValue.EmpUserName) === false) {
                Empmsg = "Email Address is not valid (" + CheckValue.EmpFirstName + " " + CheckValue.EmpLastName + ")";
                ValidationPopup(Empmsg);
            }
            // if (CheckValue.EmpPrimaryContactNumber.length !== 20) {
            //     Empmsg = "Contact number is not valid (" + CheckValue.EmpFirstName + " " + CheckValue.EmpLastName + ")";
            //     ValidationPopup(Empmsg);
            // }
            // if (CheckValue.EmpPostalCode.length !== 20) {
            //     Empmsg = "Postal code is not valid (" + CheckValue.EmpFirstName + " " + CheckValue.EmpLastName + ")";
            //     ValidationPopup(Empmsg);
            // }
            if (Empmsg === "") {
                values.push({
                    EmployeeinformationID: 0,
                    EmpFirstName: '',
                    EmpLastName: '',
                    EmpDateofBirth: '',
                    EmpGender: 0,
                    EmpDateofJoin: '',
                    EmpDepartmentID: 0,
                    EmpRoleID: 0,
                    EmpUserName: '',
                    EmpPassword: '',
                    ConfirmPassword: '',
                    EmpCompanyAdministrator: 0,
                    EmpAddressLine1: '',
                    EmpAddressLine2: '',
                    EmpCity: 0,
                    EmpState: 0,
                    EmpCountry: 0,
                    EmpPostalCode: '',
                    EmpPrimaryContactNumber: '',
                    EmpSecondaryContactNumber: '',
                    EmpProfilePicName: '',
                    EmpProfilePicPath: "",
                    ActiveStatus: 1,
                    IsDeleted: 0
                });
                setInputFields(values);
                setSubmitted(false);
            }
        } else {
            setSubmitted(true);
            ValidationPopup("Please fill this employee mandatory(*) fields (" + CheckValue.EmpFirstName + " " + CheckValue.EmpLastName + ")");
        }
    };

    const ValidationPopup = (Msg) => {
        Nodify('Warning!', 'warning', Msg);

        return false;
    }

    const handleRemoveFields = index => {
        const values = [...inputFields];
        let Params = {
            Operation: 44,
            Id: values[index].EmployeeinformationID,
        };

        CommonService.CheckMappedItem(Params).then(res => {
            if (res.data.outputResult === "-2") {
                let Title = "Warning!";
                let message = "This Employee is already mapped.";
                let type = "warning";

                Nodify(Title, type, message);
            }
            else {

                if (values[index].EmployeeinformationID === 0) {
                    values.splice(index, 1);
                }
                else {
                    values[index].IsDeleted = 1;
                }
                setInputFields(values);
                employeeCallback(values);
                setCompanyEmployeeList(values.filter(item => item.EmployeeinformationID !== 0 && item.IsDeleted === 0).sort((a, b) => 1 * a.EmpFirstName.localeCompare(b.EmpFirstName)));

            }
        });

    };

    const ShowHidePassword = (inputPassword, Icon) => {
        var Props = document.getElementById(inputPassword);
        if (Props.type === "password") {
            $("#" + Icon).removeClass("fa fa-fw fa-eye");
            $("#" + Icon).addClass("fa fa-eye-slash");
            Props.type = "text";
        } else {
            $("#" + Icon).removeClass("fa fa-eye-slash");
            $("#" + Icon).addClass("fa fa-fw fa-eye");
            Props.type = "password";
        }
    }

    const CustomInput = (props) => {
        return (
            <input className="form-control DatePickerCalender"
                onClick={
                    props.onClick
                }
                value={
                    props.value
                }
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY" />
        )
    }

    const changeEmpDropdown = (event) => {
        setEmpDropdown(event.target.value);
        let empEmpList = CompanyEmployeeList;
        if (event.target.value !== "0") {
            empEmpList = empEmpList.filter(comment => comment.EmployeeinformationID === parseInt(event.target.value));
            setInputFields(empEmpList);
        }
        else {
            handleRefreshEmployee();
        }
    }
    const DepartmentCallback = (value) => {
        if (value === true) {
            DepartmentService.LoadDepartmentList()
                .then((response) => {
                    if (response.data) {
                        setDepartmentList(response.data);
                    } else {
                    }
                })
                .catch(() => { });
            setQuickMasterName('');
        } else {
            setQuickMasterName('');
        }

    }
    const roleCallback = (value) => {

        if (value === true) {

            RoleService.LoadRoleList(UserTypeID)
                .then((response) => {
                    if (response.data) {
                        setRoleList(response.data);
                    } else {
                    }
                })
                .catch(() => { });
            setQuickMasterName('');
        } else {
            setQuickMasterName('');
        }

    }


    return (
        <>
            <div className="row"
                style={
                    {
                        display: (CompanyEmployeeList.length === 0 ? "none" : "block")
                    }
                }>
                <div className="col-sm-12">
                    <div className="col-sm-4">
                        &nbsp;
                    </div>
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label htmlFor="drpYear">Employee</label>
                            <span className="input-icon icon-right">
                                <select id="drpYear" name="AddressType" className="form-select"
                                    value={getEmpDropdown}
                                    onChange={
                                        event => changeEmpDropdown(event)
                                    }>
                                    <option value="0">- Select Employee -</option>
                                    {
                                        CompanyEmployeeList.map(item => (
                                            <option key={
                                                item.EmployeeinformationID
                                            }
                                                value={
                                                    item.EmployeeinformationID
                                                }>
                                                {
                                                    item.EmpFirstName + " " + item.EmpLastName
                                                } </option>
                                        ))
                                    } </select>
                            </span>
                        </div>
                    </div>
                    <div className="col-sm-4"
                        style={
                            { marginTop: "24px" }
                        }>
                        <div className="form-group">
                            <label>&nbsp;</label>
                            <i className="fa fa-refresh btn btn-info" title="Refresh employee list"
                                onClick={
                                    event => handleRefreshEmployee(event)
                                }></i>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                {
                    inputFields.map((inputField, index) => (
                        <Fragment key={
                            `${inputField}~${index}`
                        }>
                            <div className={DynamicClassName}
                                style={
                                    {
                                        display: inputField.IsDeleted === 1 ? "none" : "block"
                                    }
                                }>
                                <div className="widget flat radius-bordered">
                                    <div className="panel-group accordion" id="accordion">
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <h4 className="mb-0 panel-title">
                                                    <a className="btn btn-link font_wight" data-toggle="collapse" data-parent="accordion"
                                                        href={
                                                            "#collapseEmp_" + index
                                                        }>
                                                        {
                                                            inputField.EmployeeinformationID === 0 ? 'New Employee' : inputField.EmpFirstName + " " + inputField.EmpLastName
                                                        }
                                                    </a>

                                                    {
                                                        inputField.ActiveStatus === 1 &&
                                                        <img alt="Active" title="Active" src="assets/img/Icube-active.gif" className="status_img"></img>
                                                    }
                                                    {
                                                        inputField.ActiveStatus === 0 &&
                                                        <img alt="Inactive" title="Inactive" src="assets/img/animation-inactive.gif" className="status_img"></img>
                                                    }
                                                </h4>
                                            </div>
                                            <div id={
                                                "collapseEmp_" + index
                                            }
                                                className=
                                                {inputField.EmployeeinformationID === 0 ? 'panel-collapse collapse in'
                                                    : 'panel-collapse collapse'}>
                                                <div className="widget-body">
                                                    <div id="registration-form">
                                                        <Form role="form">
                                                            <div className="row">
                                                                <div className="col-lg-8">

                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor={"FirstName_" + index}>
                                                                                    First Name<span className="text-danger">*</span>
                                                                                </label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input type="text" className="form-control" id={"FirstName_" + index} name="EmpFirstName"
                                                                                        placeholder="Enter First Name" maxLength="50" value={inputField.EmpFirstName}
                                                                                        onChange={event => handleInputChange(index, event)}
                                                                                        onBlur={event => CheckDuplicate(index, event)}
                                                                                        style={{ border: submitted && !inputField.EmpFirstName ? '1px solid red' : '' }}
                                                                                    />
                                                                                </span>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor={"LastName_" + index}>
                                                                                    Last Name<span className="text-danger">*</span>
                                                                                </label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input type="text" className="form-control" id={"LastName_" + index} name="EmpLastName"
                                                                                        placeholder="Enter Last Name" maxLength="50"
                                                                                        value={inputField.EmpLastName} onChange={event => handleInputChange(index, event)}
                                                                                        style={{ border: submitted && !inputField.EmpLastName ? '1px solid red' : '' }}
                                                                                    />
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor={"Gender_" + index}>
                                                                                    Gender<span className="text-danger">*</span>
                                                                                </label>
                                                                                <span className="input-icon icon-right">
                                                                                    <select id={"Gender_" + index} name="EmpGender" className="form-select"
                                                                                        value={parseInt(inputField.EmpGender)} onChange={event => handleInputChange(index, event)}
                                                                                        style={{ border: submitted && !inputField.EmpGender ? '1px solid red' : '' }}
                                                                                    >
                                                                                        <option value="0">- Select Gender -</option>
                                                                                        {
                                                                                            genderList.map(item => (
                                                                                                <option key={item.id} value={item.id}>
                                                                                                    {item.name}
                                                                                                </option>
                                                                                            ))
                                                                                        }
                                                                                    </select>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        {PageName === "Company" && <div className="col-md-6 Calvisi">
                                                                            <div className="form-group">
                                                                                <label htmlFor={
                                                                                    "CompanyAdmin_" + index
                                                                                }>Company Admin</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input className="checkbox-slider checkbox-slider_Admin colored-blue"
                                                                                        id={
                                                                                            "CompanyAdmin_" + index
                                                                                        }
                                                                                        name="EmpCompanyAdministrator"
                                                                                        value={
                                                                                            inputField.EmpCompanyAdministrator
                                                                                        }
                                                                                        checked={
                                                                                            inputField.EmpCompanyAdministrator === 1 ? true : false
                                                                                        }
                                                                                        autoComplete="off"
                                                                                        onChange={
                                                                                            event => handleInputChange(index, event)
                                                                                        }
                                                                                        type="checkbox"
                                                                                    ></input>
                                                                                    <span className="text"></span>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        }
                                                                        {(PageName !== "Company") && <div className="col-md-6 Calvisi">
                                                                            <div className="form-group">
                                                                                <label htmlFor={
                                                                                    "ActiveStatus_" + index
                                                                                }>Status</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input className="checkbox-slider checkbox-slider_Status colored-blue"
                                                                                        id={
                                                                                            "ActiveStatus_" + index
                                                                                        }
                                                                                        name="ActiveStatus"
                                                                                        value={
                                                                                            inputField.ActiveStatus
                                                                                        }
                                                                                        checked={
                                                                                            inputField.ActiveStatus === 1 ? true : false
                                                                                        }
                                                                                        autoComplete="off"
                                                                                        onChange={
                                                                                            event => handleInputChange(index, event)
                                                                                        }
                                                                                        type="checkbox"
                                                                                    ></input>
                                                                                    <span className="text"></span>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        }


                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor={"DateofBirth_" + index}>Date of Birth</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <DatePicker className="form-control" id={"DateofBirth_" + index}
                                                                                        name="DateofBirth" selected={inputField.EmpDateofBirth}
                                                                                        onChange={event => handleInputDateChange(index, event, "EmpDateofBirth")}
                                                                                        dateFormat="dd/MM/yyyy" peekNextMonth showMonthDropdown showYearDropdown
                                                                                        dropdownMode="scroll"
                                                                                        // maxDate={inputField.EmpDateofJoin === "" ? (DOBDate) : inputField.EmpDateofJoin}
                                                                                        maxDate={(DOBDate)}
                                                                                        autoComplete="off"
                                                                                        customInput={<CustomInput />}
                                                                                        isClearable={inputField.EmpDateofBirth === '' ? false : true}
                                                                                    /> </span>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor={"DateofJoin_" + index}>Date of Join</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <DatePicker className="form-control" id={"DateofJoin_" + index}
                                                                                        name="EmpDateofJoin"
                                                                                        selected={inputField.EmpDateofJoin}
                                                                                        onChange={event => handleInputDateChange(index, event, "EmpDateofJoin")}
                                                                                        dateFormat="dd/MM/yyyy"
                                                                                        peekNextMonth
                                                                                        showMonthDropdown
                                                                                        showYearDropdown
                                                                                        dropdownMode="scroll"
                                                                                        minDate={inputField.EmpDateofBirth === "" ? (new Date("01/01/2000")) : inputField.EmpDateofBirth}
                                                                                        maxDate={new Date()}
                                                                                        autoComplete="off"
                                                                                        customInput={<CustomInput />}
                                                                                        isClearable={inputField.EmpDateofJoin === '' ? false : true}
                                                                                    />
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor={"EmpDepartmentID_" + index}>
                                                                                    {

                                                                                        EmployeeInfoQuick && EmployeeInfoQuick.Department[0].isQuick === 1 &&
                                                                                        Buttonview !== "View" &&
                                                                                        <button type='button' className="glyphicon glyphicon-plus AddPlusIcon"
                                                                                            onClick={() => setQuickMasterName('Department')} title="Add Department"></button>
                                                                                    }
                                                                                    Department</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <Select id={"EmpDepartmentID_" + index} name="EmpDepartmentID" className="form-select"
                                                                                        value={inputField.EmpDepartmentID}
                                                                                        onChange={event => handleInputChange(index, event)}
                                                                                    >
                                                                                        <option value="0">- Select Deparment -</option>
                                                                                        {
                                                                                            DepartmentList.map(item => (
                                                                                                <option key={item.id} value={item.id}>
                                                                                                    {item.name}
                                                                                                </option>
                                                                                            ))
                                                                                        }
                                                                                    </Select>
                                                                                </span>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor={"EmpRoleID_" + index}>
                                                                                    {EmployeeInfoQuick && EmployeeInfoQuick.Role[0].isQuick === 1 &&
                                                                                        Buttonview !== "View" &&
                                                                                        <button type='button' className="glyphicon glyphicon-plus AddPlusIcon"
                                                                                            onClick={() => setQuickMasterName('Role')} title="Add Role"></button>
                                                                                    }
                                                                                    Role<span className="text-danger">*</span></label>
                                                                                <span className="input-icon icon-right">
                                                                                    <Select id={"EmpRoleID_" + index} name="EmpRoleID" className="form-select"
                                                                                        value={inputField.EmpRoleID}
                                                                                        onChange={event => handleInputChange(index, event)}
                                                                                        style={{ border: submitted && !inputField.EmpRoleID ? '1px solid red' : '' }}
                                                                                    >
                                                                                        <option value="0">- Select Role -</option>
                                                                                        {
                                                                                            roleList.map(item => (
                                                                                                <option key={item.id} value={item.id}>
                                                                                                    {item.name}
                                                                                                </option>
                                                                                            ))
                                                                                        }
                                                                                    </Select>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor={"Address1_" + index}>
                                                                                    Address 1<span className="text-danger">*</span>
                                                                                </label>
                                                                                <span className="input-icon icon-right">
                                                                                    <textarea type="text" id={"Address1_" + index}
                                                                                        name="EmpAddressLine1" placeholder="Enter Address" maxLength="300"
                                                                                        value={inputField.EmpAddressLine1}
                                                                                        onChange={event => handleInputChange(index, event)}
                                                                                        style={{
                                                                                            height: "70px", width: "100%",
                                                                                            border: submitted && !inputField.EmpAddressLine1 ? '1px solid red' : ''
                                                                                        }}
                                                                                    />
                                                                                </span>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor={"Address2_" + index}>Address 2</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <textarea type="text" id={"Address2_" + index}
                                                                                        name="EmpAddressLine2" placeholder="Enter Address" maxLength="300"
                                                                                        value={inputField.EmpAddressLine2}
                                                                                        onChange={event => handleInputChange(index, event)}
                                                                                        style={{
                                                                                            height: "70px", width: "100%",

                                                                                        }}
                                                                                    />
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor={"EmpCity_" + index}>
                                                                                    City<span className="text-danger">*</span>
                                                                                </label>
                                                                                <span className="input-icon icon-right">
                                                                                    <Select id={"EmpCity_" + index} name="EmpCity" className="form-select"
                                                                                        value={inputField.EmpCity}
                                                                                        onChange={event => handleInputChange(index, event)}
                                                                                        style={{ border: submitted && !inputField.EmpCity ? '1px solid red' : '' }}
                                                                                    >
                                                                                        <option value="0">- Select City -</option>
                                                                                        {
                                                                                            cityList.map(item => (
                                                                                                <option key={item.id} value={item.id}>
                                                                                                    {item.name}
                                                                                                </option>
                                                                                            ))
                                                                                        }
                                                                                    </Select>
                                                                                </span>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-6">

                                                                            <div className="form-group">
                                                                                <label htmlFor={"State_" + index}>
                                                                                    State / Country<span className="text-danger">*</span>
                                                                                </label>
                                                                                <input type="text"
                                                                                    className="form-control"
                                                                                    id={"State_" + index}
                                                                                    name="EmpState"
                                                                                    placeholder="State / Country"
                                                                                    maxLength="50"
                                                                                    value={inputField.StateName}
                                                                                    disabled
                                                                                    tabIndex="-1"
                                                                                    style={
                                                                                        {
                                                                                            border: submitted && !inputField.StateName ? '1px solid red' : ''
                                                                                        }}
                                                                                />
                                                                            </div>


                                                                            {/* <div className="col-md-6">
                                                                                <div className="form-group">
                                                                                    <label htmlFor={"Country_" + index}>
                                                                                        Country<span className="text-danger">*</span>
                                                                                    </label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input type="text"
                                                                                            className="form-control"
                                                                                            id={"Country_" + index}
                                                                                            name="EmpCountry"
                                                                                            placeholder="Country"
                                                                                            maxLength="50"
                                                                                            value={inputField.CountryName}
                                                                                            disabled
                                                                                            tabIndex="-1"
                                                                                            style={
                                                                                                {
                                                                                                    border: submitted && !inputField.CountryName ? '1px solid red' : ''
                                                                                                }}
                                                                                        />

                                                                                    </span>
                                                                                </div>
                                                                            </div> */}

                                                                        </div>
                                                                    </div>
                                                                    <div className="row">

                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor={"EmpPostalCode_" + index}>
                                                                                    Postal Code<span className="text-danger">*</span>
                                                                                </label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input type="text" className="form-control" id={"EmpPostalCode_" + index}
                                                                                        name="EmpPostalCode" placeholder="Enter Postal Code" value={inputField.EmpPostalCode}
                                                                                        onChange={event => handleInputChange(index, event)}
                                                                                        maxLength="20"
                                                                                        style={{ border: submitted && !inputField.EmpPostalCode ? '1px solid red' : '' }}
                                                                                    />
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor={"PrimaryContact_" + index}>
                                                                                    Contact Number<span className="text-danger">*</span>
                                                                                </label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input type="text" className="form-control" id={"PrimaryContact_" + index}
                                                                                        name="EmpPrimaryContactNumber" placeholder="Enter Contact Number"
                                                                                        value={inputField.EmpPrimaryContactNumber}
                                                                                        maxLength="20"
                                                                                        onChange={event => handleInputChange(index, event)}
                                                                                        onBlur={event => CheckDuplicate(index, event)}
                                                                                        style={{ border: submitted && !inputField.EmpPrimaryContactNumber ? '1px solid red' : '' }}
                                                                                    />
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        {PageName === "Company" && <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor={
                                                                                    "ActiveStatus_" + index
                                                                                }>Status</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input className="checkbox-slider checkbox-slider_Status colored-blue"
                                                                                        id={
                                                                                            "ActiveStatus_" + index
                                                                                        }
                                                                                        name="ActiveStatus"
                                                                                        value={
                                                                                            inputField.ActiveStatus
                                                                                        }
                                                                                        checked={
                                                                                            inputField.ActiveStatus === 1 ? true : false
                                                                                        }
                                                                                        autoComplete="off"
                                                                                        onChange={
                                                                                            event => handleInputChange(index, event)
                                                                                        }
                                                                                        type="checkbox"
                                                                                    ></input>
                                                                                    <span className="text"></span>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        }
                                                                    </div>

                                                                    <div className="col-md-6">
                                                                        <div className="form-group" style={{ display: "none" }}>
                                                                            <label htmlFor={"SecondaryContact_" + index}>Secondary Contact</label>
                                                                            <span className="input-icon icon-right">
                                                                                <input type="text" className="form-control"
                                                                                    id={"SecondaryContact_" + index}
                                                                                    name="EmpSecondaryContactNumber"
                                                                                    placeholder="(999) 999-9999"
                                                                                    value={inputField.EmpSecondaryContactNumber}
                                                                                    onChange={event => handleInputChange(index, event)}
                                                                                />
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="exampleInputEmail2">Profile Picture</label>
                                                                        <span className="text-danger">(Click on the image to upload new)</span><br />
                                                                        <UploadImage UploadCallback={(e) => AddEmployeeback(e, index)}
                                                                            PageName={props.location.pathname && props.location.pathname === "/companyRegister" ? 'Company' :
                                                                                props.location.pathname === '/BuyerRegister' ? 'Buyer' : 'Supplier'
                                                                            }
                                                                            filePath={inputField.EmpProfilePicPath} Buttonview={Buttonview} />
                                                                    </div>
                                                                    <div style={{ marginTop: "50px" }}>
                                                                        <label htmlFor={"UserName_" + index}>
                                                                            Username<span className="text-danger">*</span>(E-Mail)
                                                                        </label>
                                                                        <span className="input-icon icon-right">
                                                                            <input type="text" className="form-control" disabled={(inputField.EmployeeinformationID !== 0 ? true : false)}
                                                                                id={"UserName_" + index}
                                                                                name="EmpUserName"
                                                                                maxLength="50"
                                                                                placeholder="Enter Username"
                                                                                autoComplete="email" value={inputField.EmpUserName}
                                                                                onChange={event => handleInputChange(index, event)}
                                                                                style={{ border: submitted && !inputField.EmpUserName ? '1px solid red' : '' }}
                                                                                onBlur={event => CheckEmailAddress(index)}
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                    <div style={{ marginTop: "40px", display: (inputField.EmployeeinformationID !== 0 ? "none" : "block") }}>
                                                                        <label htmlFor={"Password_" + index}>
                                                                            Password<span className="text-danger">*</span>
                                                                        </label>
                                                                        <span className="input-icon icon-right">
                                                                            <input type="password" className="form-control" id={"Password_" + index}
                                                                                name="EmpPassword" maxLength="20" placeholder="Enter Password"
                                                                                autoComplete="new-password" value={inputField.EmpPassword}
                                                                                onChange={event => handleInputChange(index, event)}
                                                                                style={{ border: submitted && !inputField.EmpPassword ? '1px solid red' : '' }}
                                                                            />
                                                                            <i className="fa fa-eye PasswordHideShow" id={"showHideIcon_" + index}
                                                                                onClick={event => ShowHidePassword(("Password_" + index), ("showHideIcon_" + index))}
                                                                            ></i>
                                                                            <PasswordStrengthBar password={inputField.EmpPassword} />
                                                                        </span>
                                                                    </div>
                                                                    <div className="form-group" style={{ marginTop: "-24px", display: (inputField.EmployeeinformationID !== 0 ? "none" : "block") }}>
                                                                        <label htmlFor={"ConfirmPassword_" + index}>
                                                                            Confirm Password<span className="text-danger">*</span>
                                                                        </label>
                                                                        <span className="input-icon icon-right">
                                                                            <input type="password"
                                                                                className="form-control"
                                                                                id={"ConfirmPassword_" + index}
                                                                                name="ConfirmPassword"
                                                                                maxLength="20" placeholder="Enter Confirm Password"
                                                                                value={inputField.ConfirmPassword} onChange={event => handleInputChange(index, event)}
                                                                                style={{ border: submitted && !inputField.ConfirmPassword ? '1px solid red' : '' }}
                                                                            />
                                                                            <i className="fa fa-eye PasswordHideShow" id={"showHideConfirmIcon_" + index}
                                                                                onClick={event => ShowHidePassword(("ConfirmPassword_" + index), ("showHideConfirmIcon_" + index))}
                                                                            ></i>
                                                                            <PasswordStrengthBar password={inputField.ConfirmPassword} />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <Modal show={getQuickMasterName} size="md" dragable backdrop="static" keyboard={false} onHide={() => handlePopupClose()}>
                                                                <Modal.Header closeButton>
                                                                    <Modal.Title>Add {getQuickMasterName}</Modal.Title>
                                                                </Modal.Header>
                                                                <Modal.Body>

                                                                    {
                                                                        getQuickMasterName !== '' ?
                                                                            getQuickMasterName === 'Department' ?
                                                                                <DepartmentPopup
                                                                                    DepartmentCallback={DepartmentCallback}
                                                                                    getDepartmentList={DepartmentList}
                                                                                    showpopup={true}

                                                                                />
                                                                                :
                                                                                getQuickMasterName === 'Role' ?
                                                                                    <RolePopup
                                                                                        roleCallback={roleCallback}
                                                                                        getRoleList={roleList}
                                                                                        showpopup={true}
                                                                                        userType={UserTypeID}


                                                                                    /> : null
                                                                            : ''

                                                                    }
                                                                </Modal.Body>
                                                            </Modal>
                                                        </Form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                MenuPermission && MenuPermission.isAdd === 1 &&
                                Buttonview !== "View" && <div className="col-lg-1_own col-xs-12"
                                    style={
                                        {
                                            display: inputField.IsDeleted === 1 ? "none" : "block"
                                        }
                                    } >
                                    <span>
                                        <span style={
                                            {
                                                display: inputField.IsDeleted === 1 ? "none" : (inputFields.filter(x => x.IsDeleted === 0).length === 1 ? "none" : "")
                                            }
                                        }>
                                            {
                                                MenuPermission && MenuPermission.isDelete === 1 &&

                                                <button className="btn btn-danger" title="Delete Employee"
                                                    // style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}

                                                    style={{ display: MenuPermission && MenuPermission.isDelete === 1 ? '' : 'none' }}
                                                    onClick={
                                                        () => handleRemoveFields(index)
                                                    }>
                                                    <i className="fa fa-trash-o"></i>
                                                </button>
                                            }
                                        </span>
                                    </span>
                                </div>
                            }
                        </Fragment>
                    ))
                }
                {MenuPermission && MenuPermission.isAdd === 1 &&
                    Buttonview !== "View" &&
                    inputFields.filter(x => x.IsDeleted === 0).map((inputField1, index) => (

                        inputFields.filter(x => x.IsDeleted === 0).length === (index + 1) && (
                            <div className="col-lg-1_own col-xs-12">
                                <span>
                                    <button className="btn btn-success" title="Add Employee"
                                        // style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}
                                        style={{ display: MenuPermission && MenuPermission.isAdd === 1 ? '' : 'none' }}
                                        onClick={
                                            () => handleAddFields(inputFields.filter(x => x.IsDeleted === 0).length - 1)
                                        }>
                                        <i className="fa fa-plus"></i>
                                    </button>
                                </span>
                            </div>)
                    ))
                }
            </div>
        </>
    );
};

export default CompanyEmployee;
