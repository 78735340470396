import axios from "axios";
import authHeader from "./auth-header";

const fetchchart = (EmployeeID) => {
    // //EmployeeID
   ;
    return axios({
        method: 'post',
        url: window.$APIBaseURL + 'Dashboard/DashboardList?EmployeeID=' + EmployeeID,
        headers: {
            authorization: authHeader()
        }
    });
};

const dashboardService = {
    fetchchart
};

export default dashboardService;
