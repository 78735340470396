import {
    TNA_LOADING,
    TNA_PURCHASE_ORDER_LIST,
    // TNA_PURCHASE_ORDER_PAGINATION,
    RETRIEVE_DROPDOWN_BRAND,
    RETRIEVE_DROPDOWN_SEASON,
    RETRIEVE_DROPDOWN_SUPPLIER,
    TNA_PURCHASE_ORDER_STYLE_ID_LIST,
    RETRIEVE_DROPDOWN_TNA_ROUTE,
    TNA_ALL_ROUTE_INFO_LIST,
    TNA_DROPDOWN_ROUTE_ID,
    TNA_LIST,
    TNA_COMMENTSLIST,
    TNA_ATTACHMENTSLIST,
    TNA_DETIALLIST,
    TNA_REPORTLIST,
    TNA_VIEWLIST,
    TNA_TASK_OWNER_LIST,
    TNA_COMPANY_HOLIDAY_LIST,
    TNA_TASK_INPUT_FIELDS_LIST,
    TNA_DROPDOWN_SUPPLIER_LIST,
    TNA_DROPDOWN_ACTIVEBUYER_LIST,
    RETRIEVE_DROPDOWN_TNA_SUPPLIER_EMPLOYEE,
    NEW_PURCHASE_ORDER_SKU_LIST,
    UPDATE_NEW_PURCHASE_ORDER_SKU_LIST_CHECK_BOX,
    TNA_ADD_FINAL_INSPECTION,
    TNA_ADD_TESTING_TNA,
    LOAD_FABRIC_TESTING_DETAILS,
    TEST_UPDATE_COMMENT_REPORT_VIEW_ALL_CHECK_BOX,
    TEST_UPDATE_COMMENT_REPORT_VIEW_CHECK_BOX,
    LOAD_TEST_ENTRY_DETAILS
} from "../actions/types";

const StatusList = [
    { value: 1, label: 'To Do', isdisabled: false },
    { value: 2, label: 'In-progress', isdisabled: false },
    { value: 3, label: 'Completed', isdisabled: true }
];
// const SingleTask = ["SMS Purchase Order", "Barcode File Receipt From Buyer", "Barcode Approval", "Style Catalog Received"]


const CommonTask = {
    FIT_SAMPLE_SUBMISSION: "fit sample submission",
    FIT_SAMPLE_APPROVAL: "fit sample approval",
    PROTO_SAMPLE_SUBMISSION: "proto sample submission",
    PROTO_SAMPLE_APPROVAL: "proto sample approval",
    TRIM_ORDER_TO_LOCAL_SUPPLIER: "trim order to local supplier",
    TRIM_RECEIPT_FROM_LOCAL_SUPPLIER: "trim receipt from local supplier",
    TRIM_APPROVAL_LOCAL_DEVELOPMENT: "trim approval (local development)",
    TRIM_SUBMISSION_LOCAL_DEVELOPMENT: "trim submission (local development)",
    TRIM_RECEIPT_FROM_BUYER: "trim receipt from buyer",
    TRIM_ORDER_TO_NOMINATED_SUPPLIER: "trim order to nominated supplier",
    TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER: "trim receipt from nominated supplier",
    STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER: "strike off order to nominated supplier",
    STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER: "strike off receipt from nominated supplier",
    STRIKE_OFF_RECEIPT_FROM_BUYER: "strike off receipt from buyer",
    STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT: "strike off submission (local development)",
    STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT: "strike off approval (local development)",
    SMS_PRODUCTION_FOLLOW: "sms production follow",
    //Single
    SMS_PURCHASE_ORDER: "sms purchase order",
    SMS_FABRIC_ORDER: "sms fabric order",
    SMS_FABRIC_IN_HOUSE: "sms fabric in-house",
    SMS_SUBMISSION: "sms submission",
    SMS_APPROVAL: "sms approval",

    APPEARANCE_AFTER_WASH: "appearance after wash",
    //Single
    BARCODE_FILE_RECEIPT_FROM_BUYER: "barcode file receipt from buyer",
    //Single
    BARCODE_APPROVAL: "barcode approval",
    //Single
    STYLE_CATALOG_RECEIVED: "style catalog received",
    LAB_DIP_SUBMISSION: "lab dip submission",
    LAB_DIP_APPROVAL: "lab dip approval",
    STRIKEOFF_TEST: "strike off test",
    TRIMS_TEST: "trims test",
    SIZE_SET_SUBMISSION: "size set sample submission",
    SIZE_SET_APPROVAL: "size set sample approval",
    PRE_PRODUCTION_SUBMISSION: "pre production sample submission",
    PRE_PRODUCTION_APPROVAL: "pre production sample approval",
    SHIPMENT_SUBMISSION: "shipment sample submission",
    SHIPMENT_APPROVAL: "shipment sample approval",
    PHOTO_SUBMISSION: "photo sample submission",
    PHOTO_APPROVAL: "photo sample approval",
    FINAL_INSPECTION: 'final inspection',
    //tests
    YARN_TEST: "yarn test",
    GARMENT_TEST: "garment test",
    STRIKE_OFF_TEST: "strike off test",
    TRIMS_TEST: "trims test",
    FABRIC_TEST: "fabric test",
    YARN_ORDER: "yarn order",
    PREPRODUCTION_MEETING: "pre production meeting",
    YARN_INHOUSE: "Yarn In-house",

    STYLE_CATALOG_RECEIVED_SKU: "style catalog received",


}

const MultiTask = [
    "fit sample submission",
    "Style Catalog Received",
    "fit sample approval",
    "proto sample submission",
    "proto sample approval",
    "trim order to local supplier",
    "trim receipt from local supplier",
    "trim approval (local development)",
    "trim submission (local development)",
    "trim receipt from buyer",
    "trim order to nominated supplier",
    "trim receipt from nominated supplier",
    "strike off order to nominated supplier",
    "strike off receipt from nominated supplier",
    "strike off receipt from buyer",
    "strike off submission (local development)",
    "strike off approval (local development)",
    "sms production follow",
    "sms fabric order",
    "sms fabric in-house",
    "sms submission",
    "sms approval",
    "appearance after wash",
    "lab dip submission",
    "lab dip approval",
    "bulk fabric in-house",
    "fabric test",
    "appearance after wash (garment test)",
    "production follow-up",
    "inline inspection - qa audit",
    "mid line inspection - qa audit",
    "final inspection",
    "inspection report sent to buyer",
    "ex-factory",
    "document received and sent to buyer",
    "buyer document sent through supplier bank",
    "payment",
    "strike off test",
    "trims test",
    "size set sample submission",
    "size set sample approval",
    "pre production sample submission",
    "pre production sample approval",
    "shipment sample submission",
    "shipment sample approval",
    "photo sample submission",
    "photo sample approval",
    "size set check",
    "in and mid line inspection"
]

const MultiTaskWithOutChild = [
    "fit sample submission",
    "Style Catalog Received",
    "fit sample approval",
    "proto sample submission",
    "proto sample approval",
    "sms production follow",
    "sms fabric order",
    "sms fabric in-house",
    "sms submission",
    "sms approval",
    "appearance after wash",
    "bulk fabric in-house",
    "fabric test",
    "appearance after wash (garment test)",
    "production follow-up",
    "inline inspection - qa audit",
    "mid line inspection - qa audit",
    "final inspection",
    "inspection report sent to buyer",
    "ex-factory",
    "document received and sent to buyer",
    "buyer document sent through supplier bank",
    "payment",
    "size set sample submission",
    "size set sample approval",
    "pre production sample submission",
    "pre production sample approval",
    "shipment sample submission",
    "shipment sample approval",
    "photo sample submission",
    "photo sample approval",
    "size set check",
    "pilot run",
    "pp meeting",
    "in and mid line inspection",
    "GARMENT TEST",
    "YARN TEST",
    "sms purchase order",
    "Bulk Purchase Order",
    "SMS Purchase Order",
    "Barcode File Receipt From Buyer",
    "yarn order",
    "pre production meeting",
    "Yarn In-house",

]



const initialState = {
    isLoadingTNA: true,
    TNAPurchaseOrderList: [],
    drpBrandList: [],
    drpSeasonList: [],
    drpSupplierList: [],
    drpTNARouteList: [],
    purchaseOrderIDList: [],
    selectedRowIndex: {},
    TNAAllRouteInfo: [],
    drpTNARouteID: 0,
    TNAList: [],
    TNACommentsList: [],
    TNAAttachmentsList: [],
    TNADetailList: [],
    TNAReportList: [], //TNAReport
    TNAParentChildList: [],
    TNAChildList: [],
    TNAViewList: [],
    drpTNATaskOwnerList: [],
    TNACompanyHolidayList: [],
    lastBrandID: 0,
    lastSupplierID: 0,
    TNABrandID: 0,
    TNASeasonID: 0,
    TNASupplierID: 0,
    TNATaskInputFields: [],
    // TNASingleTask: SingleTask,
    TNAMultiTask: MultiTask,
    TNAStatusList: StatusList,
    StaticTask: CommonTask,
    TNAMultiTaskWithOutChild: MultiTaskWithOutChild,
    drpActiveBuyerList: [],
    drpSupplierEmployeeList: [],
    newPurchaseOrderSKUList: [],
    POSkuIsAllChecked: false,
    styleSampleRequestSKUList: [],
    styleStrikeOffAndTrimSKUList: [],
    tnaAddfinalInspection: [],
    tnaAddTestinginspection: []
    , testingDetailsList: []
    , testingEntryDetailsList: []
    // TNAPOPagination: {
    //     pageSize: 5,
    //     page: undefined,
    //     sorted: undefined,
    //     totalRecords:0
    // }
};

const tnaReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case TNA_LOADING:
            return {
                ...state,
                isLoadingTNA: payload
            };

        case TNA_PURCHASE_ORDER_LIST:

            let result = payload.list;
            let selectedRow = result.filter(d => d.isChecked === 1).reduce((prev, current) => {
                prev[current.rowNumber] = true;
                return prev;
            }, {})
            return {
                ...state,
                TNAPurchaseOrderList: payload.list,
                purchaseOrderIDList: state.purchaseOrderIDList,
                selectedRowIndex: selectedRow,
                drpTNARouteID: 0,
                TNABrandID: payload.brandId,
                TNASeasonID: payload.seasonId,
                TNASupplierID: payload.supplierId,
                TNATaskInputFields: []
            };
        case RETRIEVE_DROPDOWN_BRAND:
            let brandID = payload.length > 0 ? payload[payload.length - 1].value : 0;

            return {
                ...state,
                drpBrandList: payload,
                lastBrandID: brandID
            };
        case RETRIEVE_DROPDOWN_SEASON:
            //let brandID = payload.length > 0 ? payload[payload.length - 1].value : 0;

            return {
                ...state,
                drpSeasonList: payload,
                //lastBrandID: brandID
            };
        case RETRIEVE_DROPDOWN_SUPPLIER:
            let supplier = payload.length > 0 ? payload[payload.length - 1].value : 0;

            return {
                ...state,
                ...state,
                drpSupplierList: payload,
                lastSupplierID: supplier
            };
        case TNA_DROPDOWN_SUPPLIER_LIST:
            let supplierID = payload.length > 0 ? payload[payload.length - 1].value : 0;
            return {
                ...state,
                drpSupplierList: payload,
                lastSupplierID: supplierID
            };
        case RETRIEVE_DROPDOWN_TNA_ROUTE:
            return {
                ...state,
                drpTNARouteList: payload
            };
        case TNA_PURCHASE_ORDER_STYLE_ID_LIST:

            return {
                ...state,
                purchaseOrderIDList: payload
            };
        case TNA_ALL_ROUTE_INFO_LIST:
            return {
                ...state,
                TNAAllRouteInfo: payload
            };
        case TNA_DROPDOWN_ROUTE_ID:

            return {
                ...state,
                drpTNARouteID: payload, TNAAllRouteInfo: payload > 0 ? state.TNAAllRouteInfo : []
            };
        case TNA_LIST:
           
            return {
                ...state,
                TNAList: payload.outputTNAList,

            };
        case TNA_COMMENTSLIST:

            return {
                ...state,
                TNACommentsList: payload,

            };
        case TNA_ATTACHMENTSLIST:

            return {
                ...state,
                TNAAttachmentsList: payload,

            };
        case TNA_DETIALLIST:

            return {
                ...state,
                TNADetailList: payload
            };
        case TNA_REPORTLIST:
            let params = payload.params;
            let data = payload.data;
            let resultData = { ...state };
            if (params && params.Operation === 1) {
                resultData = { ...state, TNAReportList: data, TNAParentChildList: [], TNAChildList: [] };
            } else if (params && params.Operation === 2) {
                resultData = { ...state, TNAParentChildList: data.tnaParentChildList, TNAChildList: [] };
            } else if (params && params.Operation === 3) {
                resultData = { ...state, TNAChildList: data.tnaChildList };
            } else {
                resultData = { ...state, TNAReportList: [], TNAParentChildList: [], TNAChildList: [] };
            }
            return resultData;

        case TNA_VIEWLIST:

            return {
                ...state,
                TNAViewList: payload
            };
        case TNA_TASK_OWNER_LIST:

            return {
                ...state,
                drpTNATaskOwnerList: payload
            };

        case TNA_COMPANY_HOLIDAY_LIST:
            return {
                ...state,
                TNACompanyHolidayList: payload
            };
        case TNA_TASK_INPUT_FIELDS_LIST:
            return {
                ...state,
                TNATaskInputFields: payload
            };
        case TNA_DROPDOWN_ACTIVEBUYER_LIST:
            return {
                ...state,
                drpActiveBuyerList: payload
            };

        case RETRIEVE_DROPDOWN_TNA_SUPPLIER_EMPLOYEE:
            return {
                ...state,
                drpSupplierEmployeeList: payload
            };
        case NEW_PURCHASE_ORDER_SKU_LIST:

            return {
                ...state, POSkuIsAllChecked: false,
                newPurchaseOrderSKUList: payload.item1,
                styleSampleRequestSKUList: payload.item2,
                styleStrikeOffAndTrimSKUList: payload.item3

            };
        case UPDATE_NEW_PURCHASE_ORDER_SKU_LIST_CHECK_BOX:

            let paramsLoad = payload;
            let IsAllChecked = false;
            let POSKUList = [...state.newPurchaseOrderSKUList];
            if (paramsLoad.selectAll) {
                POSKUList && POSKUList.map((item, SubIndex) => {
                    POSKUList[SubIndex].isChecked = paramsLoad.isChecked;
                });
                IsAllChecked = paramsLoad.isChecked;

            } else {
                POSKUList[paramsLoad.index].isChecked = paramsLoad.isChecked;
                if (paramsLoad.isChecked) {
                    let SKUList = POSKUList.filter(d => d.isChecked === false);
                    if (SKUList && SKUList.length > 0) {
                        IsAllChecked = false;
                    } else {
                        IsAllChecked = true;
                    }

                } else {
                    IsAllChecked = false;
                }
            }

            return {
                ...state, POSkuIsAllChecked: IsAllChecked,
                newPurchaseOrderSKUList: POSKUList
            };
        case TNA_ADD_FINAL_INSPECTION:
            return {
                ...state,
                tnaAddfinalInspection: payload
            };
        case TNA_ADD_TESTING_TNA:
            return {
                ...state,
                tnaAddTestinginspection: payload
            };
        case LOAD_FABRIC_TESTING_DETAILS:
            return {
                ...state,
                testingDetailsList: payload
            };
        case LOAD_TEST_ENTRY_DETAILS:
            return {

                ...state,
                testingEntryDetailsList: [],
                testingEntryDetailsList: payload
            };
        case TEST_UPDATE_COMMENT_REPORT_VIEW_ALL_CHECK_BOX:
            let isCheck = payload;
            let List = state.testingDetailsList;


            let Totallitem = List && List.item1;
            if (isCheck) {
                Totallitem && Totallitem.filter(x => x.isSelected === true).map((item, Index) => {
                    item.isSelected = isCheck
                });
                List.item1 = [...Totallitem];
            } else {
                Totallitem && Totallitem.filter(x => x.isSelected === false).map((item, Index) => {
                    item.isSelected = isCheck
                });
                List.item1 = Totallitem;
            }
            return {
                ...state,
                testingDetailsList: List
            };
        case TEST_UPDATE_COMMENT_REPORT_VIEW_CHECK_BOX:
            let Index = payload.index;
            let isChecked = payload.isChecked;
            let Comments = state.testingDetailsList;
            let Totallitems = Comments && Comments.item1
            Totallitems[Index].isSelected = payload.isChecked;
            Comments.item1 = Totallitems;

            return {
                ...state,
                testingDetailsList: Comments
            };


        default:
            return state;
    }
};
export default tnaReducer;