import axios from "axios";
import authHeader from "../auth-header";

class FinalInspectionService {

    GetFinalInspectionMainInformation(Operation, AqlInspectionID, BuyerID) {
        return axios.get(window.$APIBaseURL + "FinalInspection/GetFinalInspectionMainInformation?Operation=" + Operation + "&AqlInspectionID=" + AqlInspectionID + '&BuyerID=' + BuyerID, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    getResultList() {
        return axios.get(window.$APIBaseURL + "FinalInspection/getResultList?ResultID=0", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    getWorkmantempList(StyleID) {
        return axios.get(window.$APIBaseURL + "FinalInspection/GetTNAFinalinspecWorkManList?StyleID=" + StyleID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetTNAFinalinspecTestingReportList(Operation, BuyerID, BrandID) {
        return axios.get(window.$APIBaseURL + "FinalInspection/GetTNAFinalinspecTestingReportList?Operation=" + Operation + "&BuyerID=" + BuyerID + '&BrandID=' + BrandID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetBuyerCustomerWarehouseList(BuyerID, CustomerID) {
        return axios.get(window.$APIBaseURL + "FinalInspection/GetBuyerCustomerWarehouseList?BuyerID=" + BuyerID, {
            headers: {
                authorization: authHeader()
            }
        });
    }


    GetTNAFinalinspecPacking(Operation, BuyerID, BrandID, SeasonID, TNAId, PurchaseOrderSkuID) {
        return axios.get(window.$APIBaseURL + "FinalInspection/GetFinalInspecPackingList?Operation=" + Operation + "&BuyerID=" + BuyerID + '&BrandID=' + BrandID + "&SeasonID=" + SeasonID + "&TNAID=" + TNAId + '&PurchaseOrderSkuID=' + PurchaseOrderSkuID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    getCommonResultList() {
        return axios.get(window.$APIBaseURL + "FinalInspection/getCommonResultList?ResultID=0", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    getConfrimationResultList() {
        return axios.get(window.$APIBaseURL + "FinalInspection/getConfrimationResultList?ResultID=0", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    getCSRList() {
        return axios.get(window.$APIBaseURL + "FinalInspection/getCSRList?ResultID=0", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateAqlFinalInspectionGrd(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'FinalInspection/InsertUpdateAqlFinalInspectionGrd',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
    InsertFinalInspectionGrid(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'FinalInspection/InsertUpdateFinalInspectionGrid',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetTNAExfacReportList(SubTaskID) {
        return axios.get(window.$APIBaseURL + "FinalInspection/GetTNAExfacReportList?SubTaskID=" + SubTaskID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetTNAFinalinspecMainInfo(TNAFinalInspectionID, TNAId) {
        return axios.get(window.$APIBaseURL + "FinalInspection/GetTNAFinalinspecMainInfo?TNAFinalInspectionID=" + TNAFinalInspectionID + '&TNAId=' + TNAId, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    GetTNAFinalinspecDefactList(Operation, DefectTypeID) {
        return axios.get(window.$APIBaseURL + "FinalInspection/GetTNAFinalinspecDefactList?Operation=" + Operation + '&DefectTypeID=' + DefectTypeID, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    //Measurement List
    GetTNAFinalinspecMeasurementList(StyleID) {
        return axios.get(window.$APIBaseURL + "FinalInspection/GetFinalinpsecMeasurmentList?StyleID=" + StyleID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    //FLDetailsreport
    GetFLDetailsReportList(IsProduction, PoStartDate, PoEndDate, ExfacStartDate, ExfacEndDate, TNAStartDate, TNAEndDate, InspectionStartDate, InspectionEndDate) {
        return axios.get(window.$APIBaseURL + "FinalInspection/GetFLDetailsReportList?IsProduction=" + IsProduction + "&PoStartDate=" + PoStartDate +
            "&PoEndDate=" + PoEndDate + "&ExfacStartDate=" + ExfacStartDate + "&ExfacEndDate=" + ExfacEndDate + "&TNAStartDate=" + TNAStartDate + "&TNAEndDate=" +
            TNAEndDate + "&InspectionStartDate=" + InspectionStartDate + "&InstectionEndDate=" + InspectionEndDate,
            {
                headers: {
                    authorization: authHeader()
                }
            });
    }
    //FinalInspectionList - Inspection Entry (Main Module)
    GetFinalInspectionList(IsProduction, PoStartDate, PoEndDate, ApStartDate, ApEndDate, ApprovedStatus) {
        return axios.get(window.$APIBaseURL + "FinalInspection/GetFinalInspectionList?IsProduction=" + IsProduction + "&PoStartDate=" + PoStartDate + "&PoEndDate=" + PoEndDate + "&ApStartDate=" + ApStartDate + "&ApEndDate=" + ApEndDate + "&ApprovedStatus=" + ApprovedStatus,
            {
                headers: {
                    authorization: authHeader()
                }
            });
    }

    //FinalInspectionList - ReInspection (Main Module)
    GetReFinalInspectionList(IsProduction, PoStartDate, PoEndDate, ApStartDate, ApEndDate, ApprovedStatus) {
        return axios.get(window.$APIBaseURL + "FinalInspection/GetFinalInspectionList?IsProduction=" + IsProduction + "&PoStartDate=" + PoStartDate + "&PoEndDate=" + PoEndDate + "&ApStartDate=" + ApStartDate + "&ApEndDate=" + ApEndDate + "&ApprovedStatus=" + ApprovedStatus,
            {
                headers: {
                    authorization: authHeader()
                }
            });
    }


    //FL CommentReportList
    GetFLPurchaseOrderSkuReportComments(Id, Operation) {
        return axios.get(window.$APIBaseURL + "FinalInspection/GetFLPurchaseOrderSkuReportComments?PurchaseOrderCommentsID=" + Id + "&Operation=" + Operation,
            {
                headers: {
                    authorization: authHeader()
                }
            });
    }

    //FL RemarksList
    GetFIApprovalHistory(FinalInspectionID) {
        return axios.get(window.$APIBaseURL + "FinalInspection/GetFIApprovalHistory?FinalInspectionID=" + FinalInspectionID,
            {
                headers: {
                    authorization: authHeader()
                }
            });
    }


    GetFLCommentsReportExFactory(SupplierID, Operation, PurchaseOrderCommentsID) {
        return axios.get(window.$APIBaseURL + "FinalInspection/GetFLCommentsReportExFactory?SupplierID=" + SupplierID + "&Opertation=" + Operation + "&PurchaseOrderCommentsID=" + PurchaseOrderCommentsID,
            {
                headers: {
                    authorization: authHeader()
                }
            });
    }

    LoadGetFLApproval() {
        return axios.get(window.$APIBaseURL + "FinalInspection/LoadGetFLApproval",
            {
                headers: {
                    authorization: authHeader()
                }
            });
    }

    InsertUpdateFLreportComment(Params) {
       
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'FinalInspection/InsertUpdateFLreportComment',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    //Approval For Inspection
    InsertUpdateApproval(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'FinalInspection/InsertUpdateApproval',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
    //OrderStatus
    InsertUpdatereportOrderStatus(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'FinalInspection/InsertUpdateFLreportOrderStatus',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }


    InsertTolerance(Params) {

        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'FinalInspection/InsertTolerance',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
    //REinspectionComment
    InsertUpdateReinspectionComment(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'FinalInspection/InsertUpdateReinspectionComment',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
    //REinspectionCommentList
    GetReinspectionCommentsList(TNAFinalInspectionID) {
        return axios.get(window.$APIBaseURL + "FinalInspection/GetReinspectionCommentsList?TNAFinalInspectionID=" + TNAFinalInspectionID,
            {
                headers: {
                    authorization: authHeader()
                }
            });
    }
    //Strikeoff and Trim list
    GetFinalTrimStrikeoffList(Opertation, StyleID, SkuID) {
        return axios.get(window.$APIBaseURL + "FinalInspection/GetFinalinpsecTrimStrikeoffList?Opertation=" + Opertation + '&StyleID=' + StyleID + '&SkuID=' + SkuID,
            {
                headers: {
                    authorization: authHeader()
                }
            });
    }

    getToleranceList(Id) {
        return axios.get(window.$APIBaseURL + "FinalInspection/getToleranceList?ToleranceID=" + Id, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetPurchaseOrderSKUQty(finalInspectionID) {
        return axios.get(window.$APIBaseURL + "FinalInspection/GetPurchaseOrderSKUQty?FinalInspectionID=" + finalInspectionID, {
            headers: {
                authorization: authHeader()
            }
        });
    }



    ToleranceHistory(BuyerID, BrandID) {
        return axios.get(window.$APIBaseURL + "FinalInspection/ToleranceHistory?BuyerID=" + BuyerID + "&BrandID=" + BrandID, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    GetFinalinspecConclusionList(Operation, TnaFinalInspectionID) {
        return axios.get(window.$APIBaseURL + "FinalInspection/GetFinalinspecConclusionList?Operation=" + Operation + "&TnaFinalInspectionID=" + TnaFinalInspectionID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetReInspectionDetailList(Operation, FinalInspectionID, TNAId) {
        return axios.get(window.$APIBaseURL + "FinalInspection/GetReInspectionDetailList?Operation=" + Operation + "&FinalInspectionID=" + FinalInspectionID + "&TNAId=" + TNAId, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetInspectionandApprovalList(Operation, Id) {
        return axios.get(window.$APIBaseURL + "FinalInspection/GetInspectionandApprovalList?Operation=" + Operation + "&PoSKUID=" + Id,
            {
                headers: {
                    authorization: authHeader()
                }
            });
    }

    ExporttoExcel(TNAInspectionID) {
        return axios.get(window.$APIBaseURL + "FinalInspection/ExporttoExcel?TNAInspectionID=" + TNAInspectionID,
            {
                headers: {
                    authorization: authHeader()
                }
            });
    }

    GetFinalInspectionExportFocusformatList(TNAInspectionID) {
        return axios.get(window.$APIBaseURL + "FinalInspection/GetFinalInspectionExportFocusformatList?TNAFinalInspectionID=" + TNAInspectionID,
            {
                headers: {
                    authorization: authHeader()
                }
            });
    }
    FinalinspectionMeasureMentRpt(TNAInspectionID) {
        return axios.get(window.$APIBaseURL + "FinalInspection/GetFinalinpsecMeasurmentListRpt?TNAFinalInspectionID=" + TNAInspectionID,
            {
                headers: {
                    authorization: authHeader()
                }
            });
    }

    GetFinalInspectionExportJDformatList(TNAInspectionID) {
        return axios.get(window.$APIBaseURL + "FinalInspection/GetFinalInspectionExportJDformatList?TNAFinalInspectionID=" + TNAInspectionID,
            {
                headers: {
                    authorization: authHeader()
                }
            });
    }

}


export default new FinalInspectionService()