import React, { useState, useEffect, useRef, Fragment } from "react";
import ReactNotification from 'react-notifications-component';
import CityService from "../../../services/Master/CityService";
import Nodify from "../../Common/ReactNotification"
import SearchRetain from "../../Common/SearchRetain";
import SelectCombobox from 'react-select';
import { useSelector } from "react-redux";
const AddCity = (props) => {
    const EmptyInputFields =
    {
        CityID: 0,
        CountryID: 0,
        StateID: 0,
        CityName: '',
    }
    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    if (menulist !== undefined) {
        activeMenu = menulist.filter(i => i.menuName === "City");
    }
    //end enable menu
    const [inputFields, setInputFields] = useState([EmptyInputFields]);
    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [ExistingList, setExistingList] = useState([]);
    const [stateList, setstateList] = useState([]);
    const [countryList, setcountryList] = useState([]);
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),

    }

    const values = [...inputFields];

    useEffect(() => {
        CityService.loadStateList().then((response) => {
            if (response.data) {
                setstateList(response.data);
            }
        }).catch(() => { });

        CityService.loadCountryList().then((response) => {
            if (response.data) {
                setcountryList(response.data);
            }
        }).catch(() => { });

        CityService.getCityList(0).then((response) => {
            if (response.data) {
                setExistingList(response.data);
            }
        }).catch(() => { });

    }, []);

    const handleAddFields = (index) => {
        if (values[index].CityName !== '' && values[index].StateID !== 0 && values[index].CountryID !== 0) {
            values.push(EmptyInputFields);
            setInputFields(values);
            setSubmitted(false);
        } else {
            Nodify('Warning!', 'warning', 'Please fill this mandatory(*) fields.');
            setSubmitted(true);
        }
    }

    const handleInputChange = (index, event, FieldName) => {
        //setSubmitted(false);
        let inputText = '';

        if (FieldName === "StateID") {
            if (event !== null) {
                inputText = event.value;
                const state_Info = stateList.filter(x => x.value === event.value);
                values[index].CountryID = state_Info[0].countryID;
            }
            else {
                inputText = 0;
                values[index].CountryID = 0;
            }
            values[index].StateID = parseInt(inputText);
        } else if (FieldName === "CityName") {
            if (event.target.value.trim() !== '') {
                inputText = event.target.value;
            }
            values[index].CityName = inputText;
        } else if (FieldName === "CountryID") {
            if (event !== null) {
                inputText = event.value;
            }
            else {
                inputText = 0;
            }
            values[index].CountryID = parseInt(inputText);
        }
        setInputFields(values);
    }

    const handleRemoveFields = index => {
        values.splice(index, 1);
        setInputFields(values);
    }

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/cityList');
        } else {
            window.location.reload();
        }
    }

    const CheckDuplicate = (index, Field) => {
        if (ExistingList.length !== 0) {
            if (values[index].CityName !== '' && values[index].StateID !== 0 && values[index].CountryID !== 0) { // for avoid empty validation
                for (var i = 0; i < ExistingList.length; i++) {
                    if (ExistingList[i].stateID === values[index].StateID &&
                        ExistingList[i].countryID === values[index].CountryID &&
                        ExistingList[i].cityName.trim().toLowerCase() === values[index].CityName.trim().toLowerCase()) {
                        if (Field === "StateID") {
                            values[index].StateID = 0;
                        }
                        else if (Field === "CountryID") {
                            values[index].CountryID = 0;
                        }
                        else if (Field === "CityName") {
                            values[index].CityName = '';
                        }
                        setInputFields(values);
                        Nodify('Warning!', 'warning', "This city is already exist.");
                        return false;
                    }
                }
            }
        }

        for (var j = 0; j < values.length; j++) {
            if (index !== j) {
                if (values[index].CityName !== '' && values[index].StateID !== 0 && values[index].CountryID !== 0) { // for avoid empty validation
                    if (values[j].StateID === values[index].StateID &&
                        values[j].CountryID === values[index].CountryID &&
                        values[j].CityName.trim().toLowerCase() === values[index].CityName.trim().toLowerCase()) {
                        values[index].StateID = 0;
                        //values[index].CountryID = 0;
                        values[index].CityName = '';
                        setInputFields(values);
                        Nodify('Warning!', 'warning', 'This city is already exist.');
                        return false;
                    }
                }
            }
        }
    }

    const SaveCityList = (e) => {
        setButtonLoader(true);
       ;
        e.preventDefault();
        if (values.length === 1 && (values[0].GroupTypeID === 0 || values[0].CityName === '' || values[0].CityTypeID === 0)) {
            setButtonLoader(false);
            setSubmitted(true);
            ValidationPopup("Please fill atleast one City details.");
        } else {
            let Isvalid = false;
            for (var j = 0; j < values.length; j++) {
                if (values[j].GroupTypeID === 0 || values[j].CityName === '' || values[j].StateID === 0) {
                    Isvalid = true
                }
            }
            if (!Isvalid) {
                setSubmitted(false);
                let cityListParam = {};
                cityListParam.CityInformation = values;
                cityListParam.Createdby = 1;
                CityService.InsertUpdateCityGrid(cityListParam).then(res => {
                    var page = "Remove";
                    SearchRetain(page);
                    if (res.data.outputResult === "1") {
                        PageRedirect('Add');
                    } else if (res.data.outputResult === "0") {
                        setButtonLoader(false);
                        ValidationPopup("Error Occured!");
                    }
                });
            } else {
                setButtonLoader(false);
                setSubmitted(true);
                ValidationPopup("Please fill all mandatory(*) fields.");
            }
        }
    }

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/cityList",
            state: {
                message: Func
            }
        });
    }

    const ValidationPopup = (Msg) => {
        Nodify('Warning!', 'warning', Msg);
        setButtonLoader(false);
        return false;
    }

    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">Add City</h3>
                            {/* <div className="buttons-preview" align="right">
                                <span className="btn btn-primary"
                                    onClick={
                                        () => ResetOperation("Back")
                                    }>
                                    <i className="fa fa-arrow-circle-left"></i>
                                    &nbsp; Back</span>
                                <span className="btn btn-danger"
                                    onClick={
                                        () => ResetOperation()
                                    }>
                                    <i className="fa fa-times"></i>
                                    &nbsp; Reset</span>
                                <button type="button" className="btn btn-success"
                                    onClick={SaveCityList}
                                    disabled={buttonLoader}>
                                    <i className="fa fa-check right"></i>
                                    &nbsp; Save</button>
                            </div> */}
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <form>
                                    {
                                        inputFields.map((inputField, index) => (
                                            <Fragment key={
                                                `${inputField}~${index}`
                                            }>
                                                <div className="row row_left10">

                                                    <div className="col-sm-3 divder_style">
                                                        <div className="form-group">
                                                            <span className="input-icon icon-right">
                                                                <label htmlFor={
                                                                    "CityName_" + index
                                                                }>City Name<span className="text-danger">*</span>
                                                                </label>
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"CityName_" + index}
                                                                    name="CityName"
                                                                    placeholder="Enter City Name"
                                                                    maxLength="50"
                                                                    value={inputField.CityName}
                                                                    onChange={event => handleInputChange(index, event, "CityName")}
                                                                    onBlur={() => CheckDuplicate(index, 'CityName')}
                                                                    style={{ border: submitted && !inputField.CityName ? '1px solid red' : '' }}
                                                                    autoFocus
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-3 divder_style">
                                                        <div className="form-group">
                                                            <span className="input-icon icon-right">
                                                                <label htmlFor={
                                                                    "StateID_" + index
                                                                }>State<span className="text-danger">*</span>
                                                                </label>
                                                                <SelectCombobox className="basic-single" name="StateID" id={"StateID_" + index}

                                                                    isDisabled={false}
                                                                    isLoading={false}
                                                                    isSearchable={true}
                                                                    isClearable={true}
                                                                    styles={submitted && inputField.StateID === 0 ? styles : ''
                                                                    }
                                                                    onChange={event => handleInputChange(index, event, "StateID")}
                                                                    onBlur={() => CheckDuplicate(index, 'StateID')}
                                                                    value={stateList.filter(function (option) {
                                                                        return option.value === inputField.StateID;
                                                                    })}

                                                                    options={stateList}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3 divder_style">
                                                        <div className="form-group">
                                                            <span className="input-icon icon-right">
                                                                <label htmlFor={
                                                                    "CountryID_" + index
                                                                }>Country<span className="text-danger">*</span>
                                                                </label>
                                                                <SelectCombobox className="basic-single" name="CountryID" id={"CountryID_" + index}

                                                                    isDisabled={true}
                                                                    isLoading={false}
                                                                    isSearchable={true}
                                                                    isClearable={true}
                                                                    styles={submitted && inputField.CountryID === 0 ? styles : ''
                                                                    }
                                                                    onChange={event => handleInputChange(index, event, "CountryID")}
                                                                    onBlur={() => CheckDuplicate(index, 'CountryID')}
                                                                    value={countryList.filter(function (option) {
                                                                        return option.value === inputField.CountryID;
                                                                    })}

                                                                    options={countryList}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-3 paddingstatic">
                                                        {
                                                            inputFields.length !== 1 && (
                                                                <div className="col-lg-1_own col-xs-12 adddeletemargin">
                                                                    <button type="button" className="btn btn-danger"
                                                                        style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}
                                                                        title="Delete City" onClick={() => handleRemoveFields(index)}>
                                                                        <i className="fa fa-trash-o"></i>
                                                                    </button>
                                                                </div>
                                                            )}
                                                        {
                                                            inputFields.length === (index + 1) && (
                                                                <div className="col-lg-1_own col-xs-12 adddeletemargin">

                                                                    <button type="button" className="btn btn-success" title="Add City" onClick={() => handleAddFields(index)}>
                                                                        <i className="fa fa-plus"></i>
                                                                    </button>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </Fragment>
                                        ))
                                    }
                                    <div className="col-sm-12">
                                        <div className="buttons-preview" align="right">
                                            <span className="btn btn-primary"
                                                onClick={
                                                    () => ResetOperation("Back")
                                                }>
                                                <i className="fa fa-arrow-circle-left"></i>
                                                &nbsp;Back</span>
                                            <span className="btn btn-danger"
                                                onClick={
                                                    () => ResetOperation()
                                                }>
                                                <i className="fa fa-times"></i>
                                                &nbsp;Reset</span>
                                            <button type="button" className="btn btn-success"
                                                onClick={SaveCityList}
                                                disabled={buttonLoader}>
                                                <i className="fa fa-check right"></i>
                                                &nbsp;Save</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};
export default AddCity;