import React, { useState, useEffect } from "react";
import { Redirect } from 'react-router-dom';
import { Doughnut, Line } from "react-chartjs-2";
import Chart from 'chart.js/auto';
import { DashboardChart } from "../../actions/chart";
import { Col } from 'react-bootstrap';
import { DateRangePicker } from 'react-date-range';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import DashBoardTNAReport from "./DashBoardreport";
import Tnareport from '../../components/TNA/TNAReport'
import ReactNotification from 'react-notifications-component';
import CommentStyle from "../Reports/CommentStyle";
const Dashboard = (props) => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  let isLoadingDashboard = useSelector((state) => state.dashboardreducer.isLoadingDashboard);
  const CurrentUser = useSelector((state) => state.auth.user);
  const [ChartCount, setChartCount] = useState({ totalStyleCount: 0, createdStyleCount: 0 });
  const dispatch = useDispatch();
  const [chosenChart, setchosenChart] = useState("1");

  let activeMenuCommentsReport;
  const menulist = useSelector((state) => state.auth.UserMenuList.item3);
  activeMenuCommentsReport = menulist.filter(i => i.menuName === "CommentsReport");
  var chartStartDate = new Date();
  const [chartDateRange, setchartDateRange] = useState([
    {
      startDate: chartStartDate.setDate(chartStartDate.getDate() - 30),
      endDate: new Date(),
      key: 'selection',
      isShow: false
    }
  ]);
  const handleDateSelect = (event, isShow) => {
    let getchartDateRange = [...chartDateRange];
    getchartDateRange[0].isShow = isShow;
    setchartDateRange(getchartDateRange);
  }
  const clearDateSelect = () => {
    const emptychartDateRange = [
      {
        startDate: null,
        endDate: new Date(""),
        key: 'selection',
        isShow: false
      }];
    setchartDateRange(emptychartDateRange);
  }
  const DoughnutData = {
    labels: ["Total Style", "Created Style"],
    datasets: [
      {
        data: [ChartCount.totalStyleCount, ChartCount.createdStyleCount],
        borderColor: "#45B39D",
        backgroundColor: ["rgba(69, 179, 157, 0.5)", "rgba(69, 179, 157, 0.9)"],
        fill: true
      }
    ]
  };
  const Linedatapoints = [ChartCount.totalStyleCount, ChartCount.createdStyleCount];
  const LineData = {
    labels: ["Total Style", "Created Style Count"],
    datasets: [
      {
        label: "illustration of total styles to created styles",
        borderColor: "#45B39D",
        backgroundColor: ["rgba(69, 179, 157, 0.9)"],
        data: Linedatapoints,
        fill: false,
        cubicInterpolationMode: 'linear ',
      }
    ]
  };
  const SMSStyles = (
    <Doughnut
      type="doughnut"
      options={{
        color: '#FDFEFE',
        title: {
          display: true,
          fontSize: 15
        },
        legend: {
          display: true, //Is the legend shown?
          position: "top" //Position of the legend.
        },

      }}
      data={DoughnutData}
    />
  );
  const SMSLineStyles = (
    <Line
      type="line"
      width={130}
      height={60}
      options={{
        color: '#FDFEFE',
        tension: 3,
        plugins: {
          title: {
            display: true,
            text: 'Style Receipt',
            color: "#FDFEFE"
          }
        },
        responsive: true,
        interaction: {
          intersect: false,
        },
        scales: {
          x: {
            display: true,
            title: {
              display: true
            },
            ticks: {
              color: "white",
            },
            grid: {
              color: '',
              borderColor: '#FDFEFE'
            }
          },
          y: {
            display: true,
            title: {
              display: true,
              text: 'Value'
            },
            ticks: {
              color: "white"
            },
            grid: {
              color: '',
              borderColor: '#FDFEFE'
            },
            suggestedMin: -10,
            suggestedMax: 200
          }
        }
      }}
      data={LineData}

    />
  );


  useEffect(() => {
    chartDateRange[0].isShow = false;
    dispatch(DashboardChart(ChartCount, CurrentUser.employeeinformationID)).then((res) => {
      setChartCount(res.item1[0]);
      var SMSStylesChart = new Chart(SMSStyles);
      var SMSLineStyles = new Chart(SMSLineStyles);

      SMSStylesChart.destroy();
      SMSLineStyles.destroy();
    }).catch(() => {

    });
  }, [chosenChart]);

  useEffect(() => {
    if (!isLoadingDashboard) {
      hideLoader();
    } else {
      showLoader();
    }
  }, [isLoadingDashboard])

  const { user: currentUser } = useSelector((state) => state.auth);
  if (!currentUser) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <ReactNotification />
      {/* <h1>Work In-Progress</h1> */}
      &nbsp;
      <div className="">
        <div className="col-md-12">

          <div className="tabbable" style={{ width: "100%" }}>
            <ul className="nav nav-tabs nav-justified" id="myTab5" >
              <li className="active"  >
                <a data-toggle="tab" href="#idCompanyEmployee" >
                  <h5>Overview</h5>
                </a>
              </li>
              <li className="" >
                <a data-toggle="tab" href="#idCompanyHoliday" >
                  <h5>Task</h5>
                </a>
              </li>
              {
                activeMenuCommentsReport.length > 0 && activeMenuCommentsReport[0].isView === 1 &&
                <li className="" >
                  <a data-toggle="tab" href="#idCommentsReport" >
                    <h5>Comments Report</h5>
                  </a>
                </li>
              }

            </ul>
            <div className="tab-content Dashboard">

              <div id="idCompanyEmployee" className="tab-pane  in active col-lg-12" >
                {/* <AddEditStrikeOffList
                  ParentCallBack={RedirectToMaster}
                  StyleInformation={getStyleInfoList}
                  StyleId={getStyleID}
                  StrikeInfo={StrikeInfo}
                ></AddEditStrikeOffList> */}
                <h2 className="blue">Work In Progress</h2>
              </div>
              <div id="idCompanyHoliday" className="tab-pane" >
                {/* <div className="page-body"> */}
                <DashBoardTNAReport
                  props={props}
                />
                {/* </div> */}
                {/* <Tnareport /> */}
                {/* <h2 className="red">Work In Progress</h2> */}
              </div>
              {activeMenuCommentsReport.length > 0 && activeMenuCommentsReport[0].isView === 1 &&
                <div id="idCommentsReport" className="tab-pane" >
                  {/* <h2 className="blue">Work In Progress</h2> */}
                  {/* <div className="page-body"> */}
                  <CommentStyle
                    props={props}
                  />
                  {/* </div> */}
                </div>
              }
            </div>

            {loader}

          </div>
        </div>
      </div>









      <div className="page-body" style={{ display: 'none' }}>
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <div className="widget">
              <div className="widget-header">
                <span className="widget-caption">Dashboard</span>
              </div>
              {/* <h1>Work In-Progress</h1> */}
              <div className="widget-body">
                <div className="row">
                  <div className="form-group col-md-7">
                  </div>
                  <div className="form-group col-md-5">
                    <div className="col-md-6"></div>
                    <div className="col-md-6">
                      <span className='input-icon icon-right  mb-5'>
                        <label>Date Range</label>
                        <span className="input-icon icon-right">
                          <input
                            value={(chartDateRange[0].startDate === null ? '' : moment(chartDateRange[0].startDate).format('DD/MM/YYYY') + ' To ') + ((chartDateRange[0].endDate === null || !moment(chartDateRange[0].endDate).isValid()) ? '' : moment(chartDateRange[0].endDate).format('DD/MM/YYYY'))}
                            onClick={event => handleDateSelect(event, !chartDateRange[0].isShow)}
                            type="text"
                            className={'form-control'}
                            placeholder="Select Date"
                          />
                          <i className="fa fa-times" onClick={clearDateSelect} ></i>
                        </span>
                      </span>
                    </div>
                    <span className='input-icon icon-right  mb-5'>
                      <br />
                      {chartDateRange[0].isShow && <DateRangePicker
                        onChange={item => setchartDateRange([item.selection])}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={chartDateRange}
                        showDateDisplay={false}
                        direction="vertical"
                        className={'ChartDateRangePicker'}
                      />}

                    </span>
                  </div>
                </div>
                <div className="row" style={{ display: 'none' }}>
                  <div className="form-group col-md-3">
                    <span className='input-icon icon-right  mb-5'>
                      <label>Select an item to view its illustration</label>
                      <select id={'Dashboard'} name="Dashboard" className="form-select" value={chosenChart} onChange={(e) => { setchosenChart(e.target.value) }}>

                        <option value="1">Style Receipt Information for SMS and Bulk</option>
                        <option value="2">Style SKU Receipt Information for SMS and Bulk</option>
                        <option value="3">PO Order Quantity</option>
                        <option value="4">Delivery Plan</option>
                      </select>
                    </span>
                  </div>
                </div>
                <div>
                  {/* chartMarginTop */}
                  <div className="row ChartBackground" >
                    <Col md={4} sm={12} className='dashboarddoughnutchart'>
                      {SMSStyles}
                    </Col>
                    <Col md={8} sm={12}>
                      {SMSLineStyles}
                    </Col>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader}
    </>
  );
};

export default Dashboard;