import {
  REPORTMODULECATEGORY_LOADING,
  CREATE_REPORTMODULECATEGORY,
  RETRIEVE_REPORTMODULECATEGORY,
  DELETE_REPORTMODULECATEGORY
} from "../actions/types";

const initialState = {
  isLoadingreportmodulecategory: true,
  reportmodulecategorys: [],
};

const reportmodulecategoryReducer = (state = initialState, action) => {
 ;
  const { type, payload } = action;
  switch (type) {
    case REPORTMODULECATEGORY_LOADING:
      return {
        ...state,
        isLoadingreportmodulecategory: payload
      };
    case CREATE_REPORTMODULECATEGORY:
      return { ...state, reportmodulecategorys: payload };

    case DELETE_REPORTMODULECATEGORY:
      const results = state.reportmodulecategorys.filter(c => c.reportModuleCategoryID !== payload.reportModuleCategoryID);

      return {
        ...state,
        reportmodulecategorys: results
      };
    case RETRIEVE_REPORTMODULECATEGORY:
      return { ...state, reportmodulecategorys: payload };




    default:
      return state;
  }
};
export default reportmodulecategoryReducer;