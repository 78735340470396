import axios from "axios";
import authHeader from "../auth-header";

class MasterSizeService {
    InsertUpdateMasterSizeGrid = async (Params) => {
        return await axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateSizeMasterGrid',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    getMasterSizeList() {
        return axios.get(window.$APIBaseURL + "Master/GetSizeMasterList?SizeID=0", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateMasterSize(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateSizeMaster',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
}
export default new MasterSizeService()