import {
  COLUMNINFORMATION_LOADING,
  RETRIEVE_COLUMNINFORMATION,
  DELETE_COLUMNINFORMATION
} from "./types";
import ColumnInformationService from "../services/Master/ColumnInformationService";

const columninformationLoading = (isStatus) => ({
  type: COLUMNINFORMATION_LOADING,
  payload: isStatus,
});


export const createColumnInformation = (getColumnInformation) => async (dispatch) => {
 
  let resData = "";
  await ColumnInformationService.AddColumnInformation(getColumnInformation).then((res) => {
    resData = res.data;

  }).catch((err) => { return Promise.reject(err); });
  return Promise.resolve(resData);
}
export const retrievecolumninformation = (Operation, ModuleId) => async (dispatch) => {
 ;
  try {

    dispatch(columninformationLoading(true));
    const res = await ColumnInformationService.getColumnInformationList(Operation, ModuleId);
    dispatch({
      type: RETRIEVE_COLUMNINFORMATION,
      payload: res.data,
    });
    dispatch(columninformationLoading(false));
  } catch (err) {
    console.log(err);
    dispatch(columninformationLoading(false));
  }
};

export const deleteColumnInforamtion = (columnInformationID) => async (dispatch) => {
 
  try {
    dispatch({
      type: DELETE_COLUMNINFORMATION,
      payload: columnInformationID,
    });

  } catch (err) {
    console.log(err);
  }
};