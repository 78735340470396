import axios from "axios";
import authHeader from "../auth-header";

class BuyerService {

     LoadBuyerList(Operation) {
        return axios.get(window.$APIBaseURL + "Master/LoadBuyerList?Operation="+Operation, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    LoadBuyerMapingList() {
        return axios.get(window.$APIBaseURL + "Master/LoadBuyerMapingList", {
            headers: {
                authorization: authHeader()
            }
        });
    }


    getAllBuyerlist() {
        return axios.get(window.$APIBaseURL + "Master/GetBuyerList?BuyerID=0&Status=2", {
            headers: {
                authorization: authHeader()
            }
        });
    }     

    BindSupplierList(CompanyBuyerSupID) {
        return axios.get(window.$APIBaseURL + "Master/GetTrimSupplierList?CompanyBuyerSupID=" + CompanyBuyerSupID , { // data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    BindBankList(CompanyBuyerSupID) {
        return axios.get(window.$APIBaseURL + "Master/GetBankList?CompanyBuyerSupID=" + CompanyBuyerSupID , { // data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
  
 InsertUpdateBuyer(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateBuyer',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

     InsertUpdateBuyerInfo(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateBuyerInfo',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateBuyerInfoGrid(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateBuyerInfoGrid',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

      CheckEmailIdExist(Operation,EmailId) {
        return axios.get(window.$APIBaseURL + "Master/CheckEmailIdExist?Operation=" + Operation + " &CompanyHolidayID=0&EmailId="+EmailId, { // data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetBuyerInfoList(Operation, BuyerID, BuyerInfoID) {
        return axios.get(window.$APIBaseURL + "Master/GetBuyerInfoList?Operation=" + Operation + "&BuyerID=" + BuyerID + "&BuyerInfoID=" + BuyerInfoID, {
            headers: {
                authorization: authHeader()
            }
        });
    }


}
export default new BuyerService()
