import React, { useState, useEffect, Fragment } from "react";
import 'react-notifications-component/dist/theme.css';
import Form from "react-validation/build/form";
import Nodify from "../../Common/ReactNotification"
import { emailExp } from "../../Common/RegExpression";
import CommonService from '../../../services/Common/CommonService';
import { useSelector } from "react-redux";

const BuyerTrimSupplier = ({
    SupplierCallback,
    IsValidationCallback,
    citylist,
    SupplierList,
    Id,
    Buttonview,
    MenuPermission,
    PageName
}) => {
    const [inputFields, setInputFields] = useState([{
        TrimSupplierID: 0,
        SupplierName: '',
        SupplierItems: '',
        SupplierAddress1: '',
        SupplierCity: 0,
        SupplierState: 0,
        SupplierCountry: 0,
        SupplierPostalCode: '',
        SupplierPrimaryEmail: '',
        SupplierSecondaryEmail: '',
        SupplierPrimaryContactNo: '',
        SupplierSecondaryContactNo: '',
        SupplierAddress2: '',
        StateName: '',
        CountryName: '',
        IsDeleted: 0
    }]);
    const cityList = citylist;
    const [submitted, setSubmitted] = useState(false);

    let DynamicClassName = "col-lg-10 col-sm-8 col-xs-12"

    if (Buttonview === "View") {
        DynamicClassName = "col-lg-12 col-sm-12 col-xs-12"
    }
    //enbale Menu
    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    if (menulist !== undefined) {
        if (PageName === 'Buyer') {
            activeMenu = menulist.filter(i => i.menuName === "BuyerList");
        }
    }
    //end enable
    useEffect(() => {
        if (SupplierList.length !== 0) {
            setInputFields([]);
        }
        let SupplierInput = []
        SupplierList.forEach(SupplierList => {
            SupplierInput.push({
                TrimSupplierID: parseInt(SupplierList.trimSupplierID),
                SupplierName: SupplierList.supplierName,
                SupplierItems: SupplierList.supplierItems,
                SupplierAddress1: SupplierList.supplierAddress1,
                SupplierCity: parseInt(SupplierList.supplierCity),
                SupplierState: parseInt(SupplierList.supplierState),
                SupplierCountry: parseInt(SupplierList.supplierCountry),
                SupplierPostalCode: SupplierList.supplierPostalCode,
                SupplierPrimaryEmail: SupplierList.supplierPrimaryEmail,
                SupplierSecondaryEmail: SupplierList.supplierSecondaryEmail,
                SupplierPrimaryContactNo: SupplierList.supplierPrimaryContactNo,
                SupplierSecondaryContactNo: SupplierList.supplierSecondaryContactNo,
                SupplierAddress2: SupplierList.supplierAddress2,
                StateName: SupplierList.stateName + " / " + SupplierList.countryName,
                CountryName: SupplierList.countryName,
                IsDeleted: SupplierList.isDeleted === undefined ? 0 : SupplierList.isDeleted,

            });
            setInputFields(SupplierInput);
            SupplierCallback(SupplierInput)
        })
        // if (Id !== 0) {
        //     if (SupplierList.length !== 0) {
        //         const values = [...inputFields];
        //         values.splice(0, 1);
        //         setInputFields(values);
        //     }
        // }
    }, [SupplierList]);

    useEffect(() => {
        setSubmitted(IsValidationCallback);
    }, [IsValidationCallback === true]);

    const CheckDuplicate = (index) => {
       ;
        const values = [...inputFields];
        for (var i = 0; i < (inputFields.length); i++) {
            if (i !== index) {
                if (inputFields[i].SupplierPrimaryContactNo.trim() === inputFields[index].SupplierPrimaryContactNo.trim()) {
                    values[index].SupplierPrimaryContactNo = "";
                    setInputFields(values);
                    SupplierCallback(values);
                    Nodify('Warning!', 'warning', 'This Mobilenumber is already exist.');
                    return false;
                }
                else if (inputFields[i].SupplierPrimaryEmail.trim() === inputFields[index].SupplierPrimaryEmail.trim()) {
                    values[index].SupplierPrimaryEmail = "";
                    setInputFields(values);
                    SupplierCallback(values);
                    Nodify('Warning!', 'warning', 'This Email is already exist.');
                    return false;
                }
            }
        }
    }









    const handleInputChange = (index, event) => {
        const values = [...inputFields];
        let inputText = '';
        if (event.target.value.trim() !== '') { inputText = event.target.value; }
        if (event.target.name === "SupplierName") {
            values[index].SupplierName = inputText
        } else if (event.target.name === "SupplyItems") {
            values[index].SupplierItems = inputText
        } else if (event.target.name === "SupplierAddress1") {
            values[index].SupplierAddress1 = inputText;
        } else if (event.target.name === "SupplierAddress2") {
            values[index].SupplierAddress2 = inputText;
        } else if (event.target.name === "SupplierCity") {
            values[index].SupplierCity = parseInt(event.target.value);
            let CityList = cityList;
            CityList = CityList.filter(city => city.id === parseInt(event.target.value));
            if (CityList.length === 0) {
                values[index].SupplierState = 0;
                values[index].SupplierCountry = 0;
                values[index].SupplierCity = 0;
                values[index].StateName = '';
                values[index].CountryName = '';
            }
            else if (CityList.length > 0) {
                values[index].SupplierState = parseInt(CityList[0].stateId);
                values[index].SupplierCountry = parseInt(CityList[0].countryId);
                values[index].StateName = (CityList[0].stateName + " / " + CityList[0].countryName);
                values[index].CountryName = (CityList[0].countryName);
            }
        }
        else if (event.target.name === "SupplierPrimaryEmail") {
            values[index].SupplierPrimaryEmail = inputText;
            if (emailExp.test(inputText.trim() !== false)) {
                return false;
            }
        }

        else if (event.target.name === "SupplierPostalCode") {
            // var x = inputText.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})/);
            // inputText = !x[2] ? x[1] : + x[1] + ' ' + x[2];
            values[index].SupplierPostalCode = inputText;
        }
        else if (event.target.name === "SupplierPostalCode") {
            // var x = inputText.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})/);
            // inputText = !x[2] ? x[1] : + x[1] + ' ' + x[2];
            values[index].SupplierPostalCode = inputText;
        }

        else if (event.target.name === "SupplierPrimaryContact") {
            // var z = inputText.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            // inputText = !z[2] ? z[1] : '(' + z[1] + ') ' + z[2] + (z[3] ? '-' + z[3] : '');
            values[index].SupplierPrimaryContactNo = inputText;
        }
        setInputFields(values);
        SupplierCallback(values);
    };

    const handleAddFields = (index) => {
        const values = [...inputFields];
        var CheckValue1 = [...inputFields].filter(x => x.IsDeleted === 0);
        var CheckValue = CheckValue1[index];
        if (CheckValue.SupplierName && CheckValue.SupplierItems && CheckValue.SupplierAddress1 && CheckValue.SupplierCity && CheckValue.SupplierState && CheckValue.SupplierCountry && CheckValue.SupplierPrimaryContactNo) {
            let Empmsg = "";
            if (CheckValue.SupplierPrimaryEmail !== "") {
                if (emailExp.test(CheckValue.SupplierPrimaryEmail) === false) {
                    Empmsg = "Email Address is not valid";
                    ValidationPopup(Empmsg);
                }
            }
            // if (CheckValue.SupplierPrimaryContactNo.length !== 20) {
            //     Empmsg = "Contact number is not valid";
            //     ValidationPopup(Empmsg);
            // }
            // if (CheckValue.SupplierPostalCode.length !== 20) {
            //     Empmsg = "Postal code is not valid";
            //     ValidationPopup(Empmsg);
            // }
            if (Empmsg === "") {
                values.push({
                    TrimSupplierID: 0,
                    SupplierName: '',
                    SupplierItems: '',
                    SupplierAddress1: '',
                    SupplierCity: 0,
                    SupplierState: 0,
                    SupplierCountry: 0,
                    SupplierPostalCode: '',
                    SupplierPrimaryEmail: '',
                    SupplierSecondaryEmail: '',
                    SupplierPrimaryContactNo: '',
                    SupplierSecondaryContactNo: '',
                    SupplierAddress2: '',
                    IsDeleted: 0
                });
                setInputFields(values);
                setSubmitted(false);
            }
        } else {
            setSubmitted(true);
            ValidationPopup("Please fill this Trim Supplier mandatory(*) fields.");
        }
    };

    const ValidationPopup = (Msg) => {
        Nodify('Warning!', 'warning', Msg);
        return false;
    }

    const handleRemoveFields = index => {
        const values = [...inputFields];
        if (values[index].TrimSupplierID === 0) {
            values.splice(index, 1);
        }
        else {
            // values[index].IsDeleted = 1;
            let Params = {
                Operation: 36,
                Id: values[index].TrimSupplierID,
            };

            CommonService.CheckMappedItem(Params).then(res => {
                // if (res.data.length !== 0) {
                if (res.data.outputResult === "-2") {
                    let Title = "Warning!";
                    let message = "This Trim Supplier is already mapped.";
                    let type = "warning";
                    Nodify(Title, type, message);
                }
                else {
                    values[index].IsDeleted = 1;
                    setInputFields(values);
                    SupplierCallback(values);
                }
                //}
                // else {
                //     values[index].IsDeleted = 1;
                // }
            });

        }
        setInputFields(values);
        SupplierCallback(values);
    };

    return (
        <>      {
            inputFields.map((inputField, index) => (
                <Fragment key={`${inputField}~${index}`}>
                    <div className={DynamicClassName} style={
                        {
                            display: inputField.IsDeleted === 1 ? "none" : "block"
                        }
                    }>
                        <div className="widget flat radius-bordered">
                            <div className="panel-group accordion" id="accordion">
                                <div className="panel panel-default">
                                    <div className="panel-heading ">
                                        <h4 className="mb=0 panel-title">
                                            <a className="btn btn-link font_wight" data-toggle="collapse" data-parent="accordion" href={"#collapseSub_" + index} >
                                                {inputField.TrimSupplierID === 0 ? 'New Trim Supplier' : inputField.SupplierName}
                                            </a>
                                        </h4>
                                    </div>
                                    <div id={"collapseSub_" + index} className={inputField.TrimSupplierID === 0 ? 'panel-collapse collapse in' : 'panel-collapse collapse'}>
                                        <div className="widget-body">
                                            <div id="registration-form">
                                                <Form role="form">
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label htmlFor={"SupplierName_" + index}>
                                                                    Supplier Name<span className="text-danger">*</span>
                                                                </label>
                                                                <span className="input-icon icon-right">
                                                                    <input type="text" className="form-control" id={"SupplierName_" + index}
                                                                        name="SupplierName" placeholder="Enter Supplier Name"
                                                                        maxLength="50" value={inputField.SupplierName}
                                                                        onChange={event => handleInputChange(index, event)}
                                                                        style={{ border: submitted && !inputField.SupplierName ? '1px solid red' : '' }}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label htmlFor={"SupplierItems_" + index}>
                                                                    Supply Items<span className="text-danger">*</span>
                                                                </label>
                                                                <span className="input-icon icon-right">
                                                                    <input type="text" className="form-control" id={"SupplierItems_" + index}
                                                                        name="SupplyItems" placeholder="Enter Supply Items"
                                                                        maxLength="50" value={inputField.SupplierItems}
                                                                        onChange={event => handleInputChange(index, event)}
                                                                        style={{ border: submitted && !inputField.SupplierItems ? '1px solid red' : '' }}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label htmlFor={"SupplierAddress1_" + index}>
                                                                    Address 1<span className="text-danger">*</span>
                                                                </label>
                                                                <span className="input-icon icon-right">
                                                                    {/* <textarea type="text" className="form-control" id={"SupplierAddress1_" + index}
                                                                        name="SupplierAddress1" placeholder="Enter Address"
                                                                        rows="4"
                                                                        maxLength="100" value={inputField.SupplierAddress1}
                                                                        onChange={event => handleInputChange(index, event)}
                                                                        style={{ border: submitted && !inputField.SupplierAddress1 ? '1px solid red' : '' }}
                                                                    /> */}
                                                                    <textarea type="text" id={"SupplierAddress1_" + index}
                                                                        name="SupplierAddress1" placeholder="Enter Address" maxLength="300"
                                                                        value={inputField.SupplierAddress1}
                                                                        onChange={event => handleInputChange(index, event)}
                                                                        style={{
                                                                            height: "70px", width: "100%",
                                                                            border: submitted && !inputField.SupplierAddress1 ? '1px solid red' : ''
                                                                        }}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label htmlFor={"SupplierAddress2_" + index}>
                                                                    Address 2
                                                                </label>
                                                                <span className="input-icon icon-right">
                                                                    <textarea type="text" rows="2" cols="53" id={"SupplierAddress2_" + index}
                                                                        name="SupplierAddress2" placeholder="Enter Address"
                                                                        maxLength="300" value={inputField.SupplierAddress2}
                                                                        onChange={event => handleInputChange(index, event)}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label htmlFor={"SupplierCity_" + index}>
                                                                    City<span className="text-danger">*</span>
                                                                </label>
                                                                <span className="input-icon icon-right">
                                                                    <select id={"SupplierCity_" + index} name="SupplierCity"
                                                                        className="form-select" value={inputField.SupplierCity}
                                                                        onChange={event => handleInputChange(index, event)}
                                                                        style={{ border: submitted && !inputField.SupplierCity ? '1px solid red' : '' }}
                                                                    >
                                                                        <option value="0">- Select City -</option>
                                                                        {
                                                                            cityList.map(item => (
                                                                                <option key={item.id} value={item.id}>
                                                                                    {item.name}
                                                                                </option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-4">

                                                            <div className="form-group">
                                                                <label htmlFor={"SupplierState_" + index}>
                                                                    State / Country<span className="text-danger">*</span>
                                                                </label>
                                                                <span className="input-icon icon-right">
                                                                    <input type="text"
                                                                        className="form-control"
                                                                        id={"SupplierState_" + index}
                                                                        name="SupplierState"
                                                                        placeholder="State / Country"
                                                                        maxLength="50"
                                                                        value={inputField.StateName}
                                                                        disabled
                                                                        tabIndex="-1"
                                                                        style={
                                                                            {
                                                                                border: submitted && !inputField.StateName ? '1px solid red' : ''
                                                                            }}
                                                                    />

                                                                </span>
                                                            </div>

                                                            {/* <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label htmlFor={"SupplierCountry_" + index}>
                                                                    Country<span className="text-danger">*</span>
                                                                </label>
                                                                <span className="input-icon icon-right">
                                                                    <input type="text"
                                                                        className="form-control"
                                                                        id={"SupplierCountry_" + index}
                                                                        name="SupplierCountry"
                                                                        placeholder="Country"
                                                                        maxLength="50"
                                                                        value={inputField.CountryName}
                                                                        disabled
                                                                        tabIndex="-1"
                                                                        style={
                                                                            {
                                                                                border: submitted && !inputField.CountryName ? '1px solid red' : ''
                                                                            }}
                                                                    />

                                                                </span>
                                                            </div>
                                                        </div> */}
                                                        </div>
                                                    </div>

                                                    <div className="row">

                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label htmlFor={"SupplierPostalCode_" + index}>
                                                                    Postal Code<span className="text-danger">*</span>
                                                                </label>
                                                                <span className="input-icon icon-right">
                                                                    <input type="text" className="form-control" id={"SupplierPostalCode_" + index}
                                                                        name="SupplierPostalCode" placeholder="Enter Postal Code"
                                                                        value={inputField.SupplierPostalCode}
                                                                        maxLength="20"
                                                                        onChange={event => handleInputChange(index, event)}
                                                                        style={{ border: submitted && !inputField.SupplierPostalCode ? '1px solid red' : '' }}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label htmlFor={"SupplierPrimaryEmail_" + index}>
                                                                    Email Address
                                                                </label>
                                                                <span className="input-icon icon-right">
                                                                    <input type="text" className="form-control"
                                                                        id={"SupplierPrimaryEmail_" + index} name="SupplierPrimaryEmail"
                                                                        maxLength="50" placeholder="Enter Email Address" autoComplete="off"
                                                                        value={inputField.SupplierPrimaryEmail}
                                                                        onChange={event => handleInputChange(index, event)}
                                                                        onBlur={event => CheckDuplicate(index)}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label htmlFor={"SupplierPrimaryContact_" + index}>
                                                                    Contact Number<span className="text-danger">*</span>
                                                                </label>
                                                                <span className="input-icon icon-right">
                                                                    <input type="text" className="form-control" id={"SupplierPrimaryContact_" + index}
                                                                        name="SupplierPrimaryContact" placeholder="Enter Contact Number"
                                                                        value={inputField.SupplierPrimaryContactNo}
                                                                        maxLength="20"
                                                                        onChange={event => handleInputChange(index, event)}
                                                                        onBlur={event => CheckDuplicate(index)}
                                                                        style={{ border: submitted && !inputField.SupplierPrimaryContactNo ? '1px solid red' : '' }}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {
                        Buttonview !== "View" &&
                        <>
                            <div className="col-lg-1_own col-xs-12">
                                <span style={
                                    {
                                        display: (inputFields.length === 1 ? "none" : "")
                                    }
                                }>
                                    <button className="btn btn-danger" title="Delete Trim Supplier"
                                        onClick={
                                            () => handleRemoveFields(index)
                                        }>
                                        <i className="fa fa-trash-o"></i>
                                    </button>
                                </span>
                            </div>
                            <div className="col-lg-1_own col-xs-12">
                                <span>
                                    <span style={
                                        {
                                            display: (inputFields.length === (index + 1) ? "" : "none")
                                        }
                                    }>
                                        <button className="btn btn-success" title="Add Trim Supplier"
                                            onClick={
                                                () => handleAddFields(index)
                                            }>
                                            <i className="fa fa-plus"></i>
                                        </button>
                                    </span>

                                </span>
                            </div>

                        </>
                    } */}
                    {MenuPermission && MenuPermission.isAdd === 1 &&
                        Buttonview !== "View" && <div className="col-lg-1_own col-xs-12"
                            style={
                                {
                                    display: inputField.IsDeleted === 1 ? "none" : "block"
                                }
                            } >
                            <span>
                                <span style={
                                    {
                                        display: inputField.IsDeleted === 1 ? "none" : (inputFields.filter(x => x.IsDeleted === 0).length === 1 ? "none" : "")
                                    }
                                }>{MenuPermission && MenuPermission.isDelete === 1 &&
                                    <button className="btn btn-danger" title="Delete Trim Supplier"
                                        style={{ display: MenuPermission && MenuPermission.isDelete === 1 ? '' : 'none' }}
                                        onClick={
                                            () => handleRemoveFields(index)
                                        }>
                                        <i className="fa fa-trash-o"></i>
                                    </button>
                                    }
                                </span>
                            </span>
                        </div>
                    }
                </Fragment>
            ))
        }
            {MenuPermission && MenuPermission.isAdd === 1 &&
                Buttonview !== "View" &&
                inputFields.filter(x => x.IsDeleted === 0).map((inputField1, index) => (

                    inputFields.filter(x => x.IsDeleted === 0).length === (index + 1) && (
                        <div className="col-lg-1_own col-xs-12">
                            <span>
                                <button className="btn btn-success" title="Add Trim Supplier"
                                    style={{ display: MenuPermission && MenuPermission.isAdd === 1 ? '' : 'none' }}
                                    onClick={
                                        () => handleAddFields(inputFields.filter(x => x.IsDeleted === 0).length - 1)
                                    }>
                                    <i className="fa fa-plus"></i>
                                </button>
                            </span>
                        </div>)
                ))
            }
        </>
    );
};

export default BuyerTrimSupplier;