import React, { useState, useEffect, useCallback, Fragment } from "react";
import { Redirect } from 'react-router-dom';
// import InternalQAAuditDetails from './InspectionQCDetails'
// import InternalQAAuditInspectionEntry from './InspectionQCInspectionEntry'
// import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { useDispatch, useSelector } from "react-redux";
import TestingDetails from './TestingDetails';
import TestEntries from './TestEntries';
import Retest from './Retest';
import moment from "moment";
// import { LoadQADetailsreport } from '../../../../actions/inspectionQC'
const StrikeOffTesting = ({ props, TaskValue }) => {

   ;
    var defaultStartDate = new Date();
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
    let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal : activeMenus.IsProduction;
    const dispatch = useDispatch();
    const [getTabName, setTabName] = useState("TestDetails");

    useEffect(() => {
        ActivateDetailsTab();
    }, [])

    const ActivateDetailsTab = (tabName) => {
        setTabName(tabName);
        document.getElementById("liTestingDetails2").className = "active";
        var elementEntry = document.getElementById("liTestingEntry2");
        elementEntry.classList.remove("active");
        var elementReTest = document.getElementById("liTestingReTest2");
        elementReTest.classList.remove("active");
        document.getElementById("idTestingDetail2").className = "tab-pane col-lg-12 active";
        document.getElementById("idTestingEntry2").className = "tab-pane col-lg-12";
        document.getElementById("idTestingReTest2").className = "tab-pane col-lg-12";

    }
    const ActivateEntryTab = (tabName) => {
        setTabName(tabName);
        document.getElementById("liTestingEntry2").className = "active";
        var elementDetails = document.getElementById("liTestingDetails2");
        elementDetails.classList.remove("active");
        var elementEntry = document.getElementById("liTestingReTest2");
        elementEntry.classList.remove("active");
        document.getElementById("idTestingDetail2").className = "tab-pane col-lg-12";
        document.getElementById("idTestingEntry2").className = "tab-pane col-lg-12 active";
        document.getElementById("idTestingReTest2").className = "tab-pane col-lg-12";
    }

    const ActivateReTestTab = (tabName) => {
        setTabName(tabName);
        document.getElementById("liTestingReTest2").className = "active";
        var elementDetails = document.getElementById("liTestingDetails2");
        elementDetails.classList.remove("active");
        var elementEntry = document.getElementById("liTestingEntry2");
        elementEntry.classList.remove("active");
        document.getElementById("idTestingDetail2").className = "tab-pane col-lg-12";
        document.getElementById("idTestingEntry2").className = "tab-pane col-lg-12";
        document.getElementById("idTestingReTest2").className = "tab-pane col-lg-12 active";
    }



    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12">
                    <div className="tabbable max-width-view-port">
                        <ul className="nav nav-tabs nav-justified" id="myTab5" >
                            <li className="active" id="liTestingDetails2">
                                <a data-toggle="tab" href="#idTestingDetail2" id="aidTestingDetail"
                                    onClick={() => ActivateDetailsTab("TestDetails")}>
                                    <h5>Testing Details</h5>
                                </a>
                            </li>
                            <li className="" id="liTestingEntry2" >
                                <a data-toggle="tab" href="#idTestingEntry2" id="aidTestingEntry"
                                    onClick={() => ActivateEntryTab("TestEntries")}
                                >
                                    <h5>Testing  Entry</h5>
                                </a>
                            </li>
                            <li className="" id="liTestingReTest2" >
                                <a data-toggle="tab" href="#idTestingReTest2" id="aidTestingReTest"
                                    onClick={() => ActivateReTestTab("ReTest")}
                                >
                                    <h5>To do Re Test</h5>
                                </a>
                            </li>
                        </ul>

                        <div className="tab-content Dashboard" style={{ padding: "0px" }}>
                            <div id="idTestingDetail2" className="" style={{ padding: "0px" }} >
                                {/* //{getTabName === "TestDetails" && */}
                                <TestingDetails props={props} Taskcallback={TaskValue} />
                                {/* //} */}
                            </div>
                            <div id="idTestingEntry2" className="" style={{ padding: "0px" }}>
                                {getTabName === "TestEntries" && <TestEntries props={props} Taskcallback={TaskValue} />}
                            </div>
                            <div id="idTestingReTest2" className="" style={{ padding: "0px" }}>
                                {getTabName === "ReTest" && <Retest props={props} Taskcallback={TaskValue} />}
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </Fragment>
    )
}

export default StrikeOffTesting