import React, { useState, useEffect, Fragment } from "react";
import 'react-notifications-component/dist/theme.css';
import SweetAlertPopup from '../../Common/SweetAlertPopup';
import { TableHeader } from "../../Datatable";
import { ProgramID } from "../../Common/PageActionNumber";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';
import ProgramService from "../../../services/Style/ProgramService";
import CommonService from "../../../services/Common/CommonService";
import { loadProgramListByStyleID } from "../../../actions/style";
import Loader from "react-loader-spinner";
import Lightbox from "react-image-lightbox";
import SKUService from "../../../services/Style/SKUService";

const ProgramList = ({ props, programredirect, ProgramList, StyleInfo, ValidationCallBack, ProgramCallback, ProgramInfo,
}) => {
    // const [getProgramList, setProgramList] = useState([]);
   ;
    const [getID, setID] = useState(0);
    const [isOpen, setIsOpen] = useState(false)
    const [getres, setres] = useState();
    const [getImagename, setImagename] = useState();

    const dispatch = useDispatch();
    const [getFiles, setFiles] = useState([]);
    const [isOpenimg, setIsOpenimg] = useState(false);
    //reducer
    const reducerState = useSelector((state) => state);
    const { user: currentUser } = reducerState.auth;
    const getProgramList = reducerState.style.programGridList;
    const allLabDipMapApproval = reducerState.style.allLabDipMapApproval;
    const StyleID = StyleInfo.styleID !== undefined ? StyleInfo.styleID : StyleInfo.StyleID;
    


    // useEffect(() => {
    //     SKUService.LoadSkuNameList(StyleID).then((response) => {
    //         if (response.data) {
    //             setSKUList(response.data);
    //         }
    //     }).catch(() => { });
    // }, [dispatch]);

    const headers = [
        { name: "#", field: "", sortable: false,widthsize:'1%' },
        { name: "SKU Name", field: "", sortable: false },
        { name: "Pantone Details / Swatch Name", field: "", sortable: false },
        { name: "Color / Swatch Image", field: "", sortable: false },
        { name: "Fabric", field: "", sortable: false },
        { name: "Process Type", field: "", sortable: false },
        { name: "Approval Type", field: "", sortable: false },
        //{ name: "Approval Status", field: "", sortable: false },
        {
            name: "Action", field: "", sortable: false,
            display: ProgramInfo && ProgramInfo.isEdit === 0 && ProgramInfo.isDelete === 0 ? 'none' : ''
        },
    ];

    const pageRedirect = (Params, redirect) => {
       ;
        if (redirect === 'Edit') {
            let value = { data: Params, Page: 'ADDPROGRAM' }
            programredirect(value);
        }
        else {
            let Param = {
                Operation: 20,
                Id: Params.styleProgramID,
            };
            CommonService.CheckMappedItem(Param).then(res => {
                if (res.data.outputResult === "-2") {
                    let message = "This Program is already mapped.";
                    ValidationCallBack(message);
                }
                else {
                    setID({ showPopupDelete: true, Params: Params });
                }
            });
        }
    };

    if (!currentUser) {
        return <Redirect to="/login" />;
    }

    const Delcallback = (value) => {
        setID({ showPopupDelete: false, Params: [] });

    }

    const ViewFile = (event, filename) => { // event.preventDefault();
        setIsOpenimg(true);
        setImagename(filename);
    }
    const ViewSwatchImage = (event, filename) => { // event.preventDefault();
        setIsOpen(true);
        setImagename(filename);
    }



    return (
        <>
            <div className="row">
                <div className="col-xs-12 col-md-12">
                    <div className="widget">
                        <div className="widget-body">
                            <div className="dataTables_wrapper form-inline no-footer">
                                <div className="table-toolbar pull-left"></div>
                                {
                                    getProgramList.length > 0 ?
                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                            <TableHeader headers={headers} />
                                            <tbody>
                                                {
                                                    getProgramList.map((program,index) => (
                                                        <>
                                                            <tr>
                                                                <td>{index+1}</td>
                                                                <td>
                                                                    {
                                                                        program.skuName === undefined ? program.SKUName : program.skuName
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        program.pantoneCode === undefined ? program.PantoneCode : program.pantoneCode
                                                                    }
                                                                    &nbsp;
                                                                    {
                                                                        program.pantoneTypeName === undefined ? program.PantoneTypeName : program.pantoneTypeName
                                                                    }
                                                                </td>
                                                                <td style={{ width: "180px" }}>

                                                                    {
                                                                        program.pantoneTypeName !== "Others" ?

                                                                            program.pantoneColor !== '' ?
                                                                                <div style={{ background: program.pantoneColor === undefined ? program.PantoneColor : program.pantoneColor, width: '120px', height: '34px', borderRadius: '2px' }}> </div>
                                                                                :
                                                                                < img className="width_manual"
                                                                                    src={program.swatchImagePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + program.swatchImagePath}
                                                                                    alt="Logo"
                                                                                    onClick={
                                                                                        () => setIsOpen(program.swatchImagePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + program.swatchImagePath)
                                                                                    }
                                                                                />
                                                                            :
                                                                            <img src={program.pantoneColor === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + program.pantoneColor}
                                                                                alt="Picture" style={{ marginBottom: '5px', height: '120px', width: '70%', border: "1px solid #d5d5d5" }}
                                                                                onClick={
                                                                                    event => ViewSwatchImage(event, program.pantoneColor)}
                                                                            />



                                                                    }


                                                                </td>
                                                                <td>
                                                                    {program.fabricDetailName === undefined ? program.FabricName : program.fabricDetailName}
                                                                    {/* - {" "}
                                                                    {program.fabricPartName === undefined ? program.FabricPartName : program.fabricPartName} */}
                                                                </td>
                                                                <td>{program.processTypeName === undefined ? program.ProcessTypeName : program.processTypeName}</td>
                                                                <td>
                                                                    {/* {program.approvalTypeID === 1 ? 'Direct New' : program.approvalTypeID === 2 ? 'Manual' : program.approvalTypeID === 3 ? 'Existing' : ''} */}
                                                                    {/* {program.approvalTypeName === undefined ? program.ApprovalTypeName : program.approvalTypeName} */}
                                                                    {program.approvalTypeID === 1 ? 'Direct New' : program.ApprovalTypeID === 1 ? 'Direct New' : ''}
                                                                    {program.approvalTypeID === 2 ? 'Manual' : program.ApprovalTypeID === 2 ? 'Manual' : ''}
                                                                    {program.approvalTypeID === 3 ? 'Existing' : program.ApprovalTypeID === 3 ? 'Existing' : ''}
                                                                </td>
                                                                {/* <td>{program.approvalStatus}</td> */}
                                                                <td style={{ display: ProgramInfo && ProgramInfo.isEdit === 0 && ProgramInfo.isDelete === 0 ? 'none' : '' }}>
                                                                    {ProgramInfo && ProgramInfo.isEdit === 1 &&
                                                                        <span title='Edit Program' onClick={() => pageRedirect(program, 'Edit')}
                                                                            className="btn btn-success btn-xs edit">
                                                                            <i className="fa fa-edit "></i>
                                                                        </span>
                                                                    }
                                                                    &nbsp;
                                                                    {ProgramInfo && ProgramInfo.isDelete === 1 &&
                                                                        <span title='Delete Program' onClick={() => pageRedirect(program, '')}
                                                                            className="btn btn-danger btn-xs delete">
                                                                            <i className="fa fa-trash-o"></i>
                                                                        </span>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            {
                                                                program.IsCriticals.map((Crit, j) => {
                                                                    return (
                                                                        Crit.IsDeleted === 0 && Crit.IsCritical !== '' && Crit.TargettoClose !== '' && Crit.RemindMe !== 0 ?

                                                                            <tr>
                                                                                <td colSpan="10">
                                                                                    <span style={{ color: 'red' }}>Critical : {Crit.IsCritical}</span>,&nbsp;
                                                                                    <span style={{ color: '#427fed' }}>Target to Close : {new Date(Crit.TargettoClose).toLocaleDateString()}</span>,&nbsp;
                                                                                    <span style={{ color: '#00a65a' }}>Completed On : {Crit.CompletedOn !== '' ? new Date(Crit.CompletedOn).toLocaleDateString() : '-'}</span>
                                                                                </td>
                                                                            </tr>

                                                                            : ''
                                                                    );
                                                                })
                                                            }

                                                            {
                                                                program.IsManual.map((man, j) => {
                                                                    return (
                                                                        man.ManualCloseComment !== '' && (program.approvalTypeID === 2 || program.ApprovalTypeID === 2) ?
                                                                            <tr>
                                                                                <td colSpan={10}>
                                                                                    <div className="row-1">
                                                                                        <span><b> Manual Comment :</b> {man.ManualCloseComment}</span>
                                                                                    </div>
                                                                                    {/* <span style={{ color: '#427fed' }}>Date : {new Date(man.ManualCloseDate).toLocaleDateString()}</span> */}




                                                                                    {
                                                                                        man.Files && man.Files.length !== 0 ?
                                                                                            man.Files.filter(x => x.styleProgramIndexName === man.StyleProgramIndexName).map((files, fileindex) => (

                                                                                                <div className="col-lg-4 col-sm-4 file_list_upload" style={{ width: "24%" }}>
                                                                                                    <tr title={files.fileName}>
                                                                                                        File :
                                                                                                        <td className="cut_textInTNA">
                                                                                                            <span style={{ marginRight: '7px' }}>
                                                                                                                {
                                                                                                                    files.fileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                                        files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                                                            <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                                            files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                                                files.fileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                                                <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                                                }
                                                                                                            </span>  {files.fileName}
                                                                                                        </td>

                                                                                                        <td>

                                                                                                            {
                                                                                                                files.fileType === "application/pdf" || files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files.fileType === 'application/msword' ?
                                                                                                                    <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + 'Images/TNAImages/' + files.fileName} download><i class="fa fa-download"></i> </a> :


                                                                                                                    <i className="btn btn-info fa fa-eye" title="View Logo"
                                                                                                                        style={{ display: ProgramInfo && ProgramInfo.isView === 1 ? '' : 'none' }}
                                                                                                                        onClick={
                                                                                                                            event => ViewFile(event, files.fileName)
                                                                                                                        }></i>

                                                                                                            }
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    {

                                                                                                        <div> {
                                                                                                            isOpenimg && (

                                                                                                                <Lightbox mainSrc={window.$APIBaseURL + 'Images/TNAImages/' + getImagename}
                                                                                                                    alt="bg image"
                                                                                                                    onCloseRequest={
                                                                                                                        () => setIsOpenimg(false)
                                                                                                                    } />
                                                                                                            )
                                                                                                        } </div>
                                                                                                    }
                                                                                                </div>



                                                                                            ))
                                                                                            : ''


                                                                                    }

                                                                                </td>

                                                                            </tr>
                                                                            : ''



                                                                    );


                                                                })
                                                            }

                                                            {(program.approvalTypeID === 3 || program.ApprovalTypeID === 3) &&
                                                                allLabDipMapApproval && allLabDipMapApproval.filter(x => x.commonApprovalId === program.labdibSubmissionApprovalID && x.styleProgramID === program.styleProgramID).map((exist, j) => {
                                                                    return (
                                                                        <tr>
                                                                            <td colSpan="7">
                                                                                <span><b>Season Name : </b> {exist.seasonName} &nbsp;</span>
                                                                                <span><b>Approval Date : </b>  {new Date(exist.approvalDate).toLocaleDateString()} &nbsp;</span>
                                                                                <span><b>Details : </b> {exist.details} &nbsp;</span>
                                                                                <span><b>Approved By : </b> {exist.approvedBy}</span>

                                                                            </td>
                                                                        </tr>

                                                                    );


                                                                })
                                                            }
                                                        </>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                        :
                                        <>
                                            {
                                                getProgramList.length === 0 ?
                                                    <>
                                                        <div className="col-lg-12 col-sm-12 col-xs-12">
                                                            <div className="widget flat radius-bordered">
                                                                <div className="panel-group accordion" id="accordion">
                                                                    <div className="panel panel-default">
                                                                        <div className="panel-heading ">
                                                                            <h4 className="mb-0 panel-title">
                                                                                <a className="btn btn-link font_wight" data-toggle="collapse" data-parent="accordion" >
                                                                                    {
                                                                                        "Program Info"
                                                                                    }
                                                                                </a>
                                                                            </h4>
                                                                        </div>
                                                                        <div className={'panel-collapse collapse in'}>
                                                                            <div className="widget-body">
                                                                                <div id="registration-form">
                                                                                    <div className="col-sm-12">
                                                                                        <div className="row">

                                                                                            <center>
                                                                                                <h4 className="text-danger">No Program information mapped yet.</h4>
                                                                                            </center>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    : ''
                                            }
                                            {getres === 0 ? <Loader type="ThreeDots" color="#b6da94" height={80} width={80} timeout={9000} style={{ textAlign: "center" }} /> : ''}
                                        </>
                                }
                            </div>
                        </div>
                        {getID.showPopupDelete ?
                            <SweetAlertPopup data={getID.Params}
                                deleteCallback={Delcallback}
                                showpopup={true}
                                pageActionId={ProgramID}
                                Msg={"Program deleted successfully."} /> : null //Program deleted successfully.
                        } </div>
                    <div> {
                        isOpen && (

                            <Lightbox mainSrc={window.$APIBaseURL + getImagename}
                                alt="bg image"
                                onCloseRequest={
                                    () => setIsOpen(false)
                                } />
                        )
                    } </div>
                </div>
            </div>
        </>
    );
};

export default ProgramList;