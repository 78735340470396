import React, { useState, useEffect, useRef, useCallback } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import 'react-notifications-component/dist/theme.css';
import ReactNotification from 'react-notifications-component';
import validator from 'validator'
import Nodify from "../../Common/ReactNotification";
import SearchRetain from "../../Common/SearchRetain";
import { useDispatch, useSelector } from "react-redux";
import { createQA } from "../../../actions/qa";
import QAService from "../../../services/Master/QAService";
const QAEdit = (props) => {
 
  const [GetQA, setQA] = useState({ Comment: '' });
  const [submitted, setSubmitted] = useState(false);
  const [getQaValue, setOutputs] = useState([]);
  const [ButtonName, setButtonName] = useState("Save");
  const [HeaderName, setHeaderName] = useState("Add");
  const [buttonLoader, setButtonLoader] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  //Enter Error msg
  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        //SaveBuyerInfo(event);
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [])

  
  function QAAssign(props) {

    let QAValue = {
      CommentID: 0,
      Comment: '',
      Operation: 1,
      Createdby: currentUser.employeeinformationID
    };


    Array.from(document.querySelectorAll("input")).forEach(input => (input.value = ""));
    if (props.location.state !== undefined && props.location.state.params.length !== 0) {
      if (props.location.state.params.commentID !== "") {
        setButtonName("Update");
        setHeaderName("Edit");
        QAValue.CommentID = props.location.state.params.commentID;
        QAValue.Comment = validator.trim(props.location.state.params.comment);
        QAValue.Operation = 2;
        return QAValue;
      }
    } else {
      return QAValue;

    }

  }

  useEffect(() => {
    let getQA = QAAssign(props);
    setOutputs(getQA)
  }, []);


  function handleChange(e) {

    const { name, value } = e.target;
    setQA(inputs => ({
      ...inputs,
      [name]: value
    }));
    let qaValue = GetQA;
    let inputText = '';
    if (e.target.value.trim() !== '') {
      inputText = e.target.value;
      setSubmitted(true);
    }
    else { setSubmitted(false); }
    qaValue[e.target.name] = inputText;
    setOutputs(qaValue);
  }

  const SaveQA = (e) => {

    setButtonLoader(true);
    e.preventDefault();
    if (getQaValue.Comment.trim() === '') {
      setButtonLoader(false);
      setSubmitted(true);
      Nodify('Warning!', 'warning', 'QA comment is required');
      // return false;
    }
    else {
      if (getQaValue.Comment.trim()) {
        getQaValue.Operation = 2;
        getQaValue.CommentID = props.location.state.params.commentID;
        getQaValue.Comment = getQaValue.Comment.trim();
        getQaValue.Createdby = currentUser.employeeinformationID
        QAService.AddQAComment(getQaValue).then(data => {

          var page = "Remove";
          SearchRetain(page);
          let Func = 'Add';
          if (data.data.outputResult === "1") {
            Func = 'Add';
            PageRedirect(Func);
          } else if (data.data.outputResult === "2") {
            Func = 'Edit';
            PageRedirect(Func);
          } else if (data.data.outputResult === "-1") {

            Nodify('Warning!', 'warning', 'QA Comment already Exist');
          }
          setButtonLoader(false);
        })
          .catch(e => {
            console.log(e);
          });
      }
      else {
        setSubmitted(true);
      }
    }


  }

  const PageRedirect = (Func) => {
    props.history.push({
      pathname: "/QACommentList",
      state: {
        message: Func
      }
    });
  }


  const ResetOperation = (e) => {
    if (e === "Back") {
      props.history.push('/QACommentList')
    } else {
      window.location.reload();
    }
  }


  return (
    <div className="page-body">
      <ReactNotification />
    <div className="row">
      <div className="col-lg-12 col-sm-12 col-xs-12">
        <div className="widget flat radius-bordered">
          <div className="widget-header heading_box_style">
            <h3 className="heading_style_common">Edit QA Comment</h3>
          </div>
          <div className="widget-body">
            <div id="registration-form">
              <Form>

                <div className="row">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label htmlFor="Comment">QA Comment<span className="text-danger">*</span></label>
                      <span className="input-icon icon-right">
                        <input type="text" name="Comment"
                          id="Comment"
                          value={getQaValue.Comment}
                          onChange={handleChange}
                          maxLength="25"
                          className={'form-control'}
                          autoFocus
                          style={{border:submitted && GetQA.Comment ==="" ? "1px solid red" : "" }}
                            placeholder="QA Comment"
                        />
                      </span>


                    </div>
                  </div>
                  <div className="clearfix"></div>
                  <div className="col-sm-12">
                    <div className="buttons-preview" align="right">
                      <span className="btn btn-primary"
                        onClick={
                          () => ResetOperation("Back")
                        }>
                        <i className="fa fa-arrow-circle-left"></i>
                          &nbsp;Back</span>
                      <span className="btn btn-danger"
                        onClick={
                          () => ResetOperation()
                        }>
                        <i className="fa fa-times"></i>
                          &nbsp;Reset</span>
                      <button type="submit" onClick={SaveQA} className="btn btn-success"
                        disabled={buttonLoader}
                      >

                        <i className="fa fa-check right"></i>
                        &nbsp;{
                          ButtonName
                        }</button>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default QAEdit