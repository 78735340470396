import axios from "axios";
import authHeader from "../auth-header";

class PackageItemService {
    GetPackageTemplateList(PackageImageID, Operation) {
        return axios.get(window.$APIBaseURL + "Master/GetPackageTemplateList?PackageItemImageID=" + PackageImageID + "&Operation=" + Operation, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    LoadPackageItem() {
        return axios.get(window.$APIBaseURL + "Master/LoadPackageItem", {
            headers: {
                authorization: authHeader()
            }
        });
    }
    LoadPackageImage() {
        return axios.get(window.$APIBaseURL + "Master/LoadPackageImage", {
            headers: {
                authorization: authHeader()
            }
        });
    }
    InsertUpdatePackageTemplateGrd(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdatePackageTemplateGrd',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
}
export default new PackageItemService;