import axios from "axios";
import authHeader from "../auth-header";

class measurementService {
   
   LoadMeasurement() {
        return axios.get(window.$APIBaseURL + "Style/LoadMeasurePointList", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetAllStyleMeasurementCatPointList(MeasurementID) {
        return axios.get(window.$APIBaseURL + "Style/GetAllStyleMeasurementCatPointList?MeasurementID="+ MeasurementID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    
   GetStyleList(StyleID) {
        return axios.get(window.$APIBaseURL + "Style/GetStyleList?StyleID="+ StyleID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateStyle(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Style/InsertUpdateStyle',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetMeasurement(StyleID) {
        return axios.get(window.$APIBaseURL + "Style/GetStyleMeasurementList?StyleID="+ StyleID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetStyleMeasurementCategoryList(Operation,StyleID,StyleMeasurementID) {
        return axios.get(window.$APIBaseURL + "Style/GetStyleMeasurementCategoryList?Operation="+Operation+"&StyleID="+ StyleID+"&StyleMeasurementID="+ StyleMeasurementID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetStyleMeasurementCategoryPointsList(Operation,StyleID,StyleMeasurementID) {
        return axios.get(window.$APIBaseURL + "Style/GetStyleMeasurementCategoryPointsList?Operation="+Operation+"&StyleID="+ StyleID+"&StyleMeasurementID="+ StyleMeasurementID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    

  GetRemarksInfo(StyleID) {
        return axios.get(window.$APIBaseURL + "Style/GetStyleRemarkDetailsInfoList?StyleID="+ StyleID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetFabricInfo(StyleID) {
        return axios.get(window.$APIBaseURL + "Style/GetStyleFabricDetailsInfoList?StyleID="+ StyleID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

}
export default new measurementService();