import {
    SAMPLES_LOADING,
    SAMPLES_RELOADING,
    RETRIEVE_SAMPLES
}
    from '../actions/types'


const initialState = {
    isLoadingSamples: true,
    isReLoadingSamples: true,
    SamplesList: [],
}

const SamplesReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SAMPLES_LOADING:
            return {
                ...state,
                isLoadingSamples: payload
            };
        case SAMPLES_RELOADING:
            return {
                ...state,
                isReLoadingSamples: payload
            };
        case RETRIEVE_SAMPLES:
            return { ...state, SamplesList: payload };
        default:
            return state;
    }
}
export default SamplesReducer;