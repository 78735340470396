import React, { useState, useCallback } from 'react';
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import { Modal, Button } from "react-bootstrap";
import Nodify from '../Common/ReactNotification';
import BranchService from '../../services/Master/BranchService';
import { useSelector } from "react-redux";

const BranchPopup = ({
    getBranchList,
    branchCallback,
    showpopup,

}) => {
   
    const EmptyBranchInputFields =
        [{
            Id: 0,
            Name: "",
        }]
    const { user: currentUser } = useSelector((state) => state.auth);


    const [buttonLoader, setButtonLoader] = useState(false);
    const [BranchInputFields, setBranchInputFields] = useState(EmptyBranchInputFields);
    //const [getChildMapItemList, setChildMapItemList] = useState({});
    const [BranchList, setBranchList] = useState([]);
    const [show, setShow] = useState(true);
    const [BranchSubmitted, setBranchsubmitted] = useState(false);
    const [checked, setChecked] = useState({ Staus: true });

    const handlePopupClose = () => {
        branchCallback(false);
    }

    const CheckDuplicate = (index) => {
        const values = [...BranchInputFields];
        if (getBranchList) {
            for (var i = 0; i < getBranchList.length; i++) {
                if (getBranchList[i].label.trim().toLowerCase() === values[index].Name.trim().toLowerCase()) {
                    values[index].Name = '';
                    setBranchInputFields(values);
                    setBranchList({ Createdby: currentUser.employeeinformationID, MasterInformation: values });
                    Nodify('Warning!', 'warning', "This name is already exist.");
                    return false;
                }
            }
        }
    }

    const handleBranchChange = (event) => {
        const values = [...BranchInputFields];
        let inputText = "";

        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }
        values[0].Name = inputText;
        setBranchInputFields(values);
        setBranchList({ Createdby: currentUser.employeeinformationID, MasterInformation: values });
    }

    const SaveBranch = (e) => {
        setButtonLoader(true);
        e.preventDefault();
        const Values = [...BranchInputFields];
        if (Values[0].Name.trim() === '') {
            setBranchsubmitted(false);
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Branch name is required.');
        }
        if (Values[0].Name.trim()) {
            BranchService.InsertUpdateBranchGrid(BranchList).then(res => {
                if (res.data.outputResult === "1") {
                    Nodify("Success!", "success", "Branch added successfully.");
                    branchCallback(true);
                }
                else if (res.data.outputResult === "-2") {
                    Nodify('Warning!', 'warning', 'This name is already exist.');
                }
                setButtonLoader(true);
            });
        }
        else {
            setBranchsubmitted(true);
        }
    }
    return (
        // <Modal show={showpopup} size="md" dragable backdrop="static" keyboard={false} onHide={() => handlePopupClose()}>
        //     <Modal.Header closeButton>
        //         <Modal.Title>Buyer Name : {BuyerName}</Modal.Title>
        //     </Modal.Header>
        //     <Modal.Body>
        <div id="registration-form">
            <Form>
                <div className="row">
                    <div className="form-group col-sm-12">
                        {/* <div className="col-sm-8"> */}
                        <div className='form-group'>
                            {/* <label htmlFor="MapingTextName">
                                           {BuyerID}
                                            <span className="text-danger">*</span>
                                        </label> */}
                            <label htmlFor="Name">
                                Branch Name
                                <span className="text-danger">*</span>
                            </label>
                            <Input
                                placeholder={"Enter Branch"}
                                id="Name"
                                name="BranchName"
                                type="text"
                                maxLength="50"
                                value={BranchInputFields[0].Name}
                                autoComplete="off"
                                onChange={handleBranchChange}
                                className='form-control'
                                onBlur={() => CheckDuplicate(0)}
                                style={{ border: BranchSubmitted && !BranchInputFields[0].Name ? '1px solid red' : '' }}
                                autoFocus
                            />
                        </div>
                    </div>
                </div>
                <div className="text-right">
                    <Button type="submit" className="btn btn-success" onClick={SaveBranch} disabled={buttonLoader}>
                        <i className="fa fa-check"></i> &nbsp; Save
                    </Button>

                </div>
            </Form>
        </div>
        //     </Modal.Body>
        //     <Modal.Footer>

        //     </Modal.Footer>
        // </Modal>
    )
};
export default BranchPopup;
