import React, { useState, useEffect } from "react";
import FabricService from "../../../../services/Master/FabricService";
import Nodify from "../../../Common/ReactNotification";
import SearchRetain from "../../../Common/SearchRetain";
import ReactNotification from 'react-notifications-component';
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import Select from "react-validation/build/select";

const EditFabric = (props) => {
    const [getCategoryTypeList, setCategoryTypeList] = useState([]);
    const [getGroupTypeList, setGroupTypeList] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [getFabric, setFabric] = useState({});
    const [buttonLoader, setButtonLoader] = useState(false);

    function BindFabricValue(props) {
        let FabricValue = {};

        if (props.location.state !== undefined) {
            if (props.location.state.params.length !== 0) {
                if (props.location.state.params.fabricDetailsID !== 0) {
                    FabricValue.Operation = 2;
                    FabricValue.FabricDetailsID = props.location.state.params.fabricDetailsID;
                    FabricValue.FabricDetailName = props.location.state.params.fabricDetailName;
                    FabricValue.GroupTypeID = props.location.state.params.groupTypeID;
                    FabricValue.Content = props.location.state.params.content;
                    let split = props.location.state.params.gsMorCounts.split("-");
                    FabricValue.GSMorCounts = split[0];
                    FabricValue.FabricorYarn = props.location.state.params.fabricorYarn;
                    // CategoryValue.CategoryTypeID = props.location.state.params.categoryTypeID;
                    FabricValue.Createdby = 1;
                    return FabricValue;
                }
            }
        }
        else {
            return FabricValue;
        }
    }

    useEffect(() => {
        let FabricValue = BindFabricValue(props);
        setFabric(FabricValue);

        FabricService.LoadGroupTYpe().then((response) => {
            if (response.data) {
                setGroupTypeList(response.data);
            }
        }).catch(() => { });

        // CategoryService.LoadCategoryTypeList().then((response) => {
        //     if (response.data) {
        //         setCategoryTypeList(response.data);
        //     }
        // }).catch(() => { });
    }, []);

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/FabricList",
            state: {
                message: Func,
            },
        }
        );
    };

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/FabricList')
        } else {
            window.location.reload();
        }
    }

    const handleChange = (e) => {
        let Value = getFabric;
        if (e.target.name === "GroupTypeID") {
            if (e.target.value !== '0') {
                setSubmitted(true);
            }
            else {
                setSubmitted(false);
            }
            Value.GroupTypeID = parseInt(e.target.value);
        }
        else if (e.target.name === "FabricDetailName") {
            if (e.target.value.trim() !== '') {
                setSubmitted(true);
            }
            else {
                setSubmitted(false);
            }
            Value.FabricDetailName = e.target.value;
        }
        else if (e.target.name === "Content") {
            if (e.target.value !== '') {
                setSubmitted(true);
            }
            else {
                setSubmitted(false);
            }
            Value.Content = e.target.value;
        }
        else if (e.target.name === "GSMorCounts") {
            if (e.target.value !== '') {
                setSubmitted(true);
            }
            else {
                setSubmitted(false);
            }
            Value.GSMorCounts = e.target.value;
        }
        setFabric(Value);
    }

    const SaveFabric = (e) => {
        setButtonLoader(true);
        e.preventDefault();
        if (getFabric.FabricDetailName === '' || getFabric.GroupTypeID === 0 || getFabric.Content === '') {
            setButtonLoader(false);
            setSubmitted(true);
            Nodify('Warning!', 'warning', 'Please fill all mandatory(*) fields.');
            return false;
        }
        if (getFabric.FabricDetailName.trim()) {
            getFabric.FabricDetailName = getFabric.FabricDetailName.trim();
            getFabric.GSMorCounts = getFabric.GSMorCounts.trim();
            FabricService.InsertUpdateFabric(getFabric).then(res => {
                var page = "Remove";
                SearchRetain(page);
                let Func = 'Add';
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "-2") {
                    let FabricInfo = BindFabricValue(props);
                    setFabric(FabricInfo);
                    Nodify('Warning!', 'warning', 'Fabric name is already exist.');
                }
                setButtonLoader(false);
                setSubmitted(false);
            });
        }
        else {
            setSubmitted(true);
        }
    }

    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">
                                Edit Fabric Detail
                            </h3>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <Form>
                                    <div className="row">
                                        <div className="form-group col-sm-3">
                                            <label htmlFor="GroupTypeID">
                                                Group Type
                                                <span className="text-danger">*</span>
                                            </label>
                                            <span className="input-icon icon-right">
                                                <Select id={
                                                    "GroupTypeID"
                                                }
                                                    name="GroupTypeID"
                                                    className="form-select"
                                                    onChange={
                                                        event => handleChange(event)
                                                    }
                                                    value={
                                                        getFabric.GroupTypeID
                                                    }
                                                    style={
                                                        {
                                                            border: submitted && getFabric.GroupTypeID === 0 ? '1px solid red' : ''
                                                        }
                                                    }>
                                                    <option value="0">- Select Group Type -</option>
                                                    {
                                                        getGroupTypeList.map(item => (
                                                            <option key={
                                                                item.id
                                                            }
                                                                value={
                                                                    item.id
                                                                }>
                                                                {
                                                                    item.name
                                                                } </option>
                                                        ))
                                                    } </Select>
                                            </span>

                                        </div>
                                        <div className="form-group col-sm-3">
                                            <label htmlFor="FabricDetailName">
                                                Fabric Name
                                                <span className="text-danger">*</span>
                                            </label>
                                            <span className="input-icon icon-right">
                                                <Input
                                                    placeholder={"Enter Fabric Name"}
                                                    id="FabricDetailName"
                                                    name="FabricDetailName"
                                                    value={getFabric.FabricDetailName}
                                                    type="text"
                                                    maxLength="50"
                                                    autoComplete="off"
                                                    onChange={handleChange}
                                                    className='form-control'
                                                    style={{ border: submitted && getFabric.FabricDetailName === '' ? '1px solid red' : '' }}
                                                    autoFocus
                                                />
                                            </span>

                                        </div>

                                        <div className="form-group col-sm-3">
                                            <label htmlFor="Content">
                                                Content
                                                <span className="text-danger">*</span>
                                            </label>
                                            <span className="input-icon icon-right">
                                                <Input
                                                    placeholder={"Enter Content"}
                                                    id="Content"
                                                    name="Content"
                                                    value={getFabric.Content}
                                                    type="text"
                                                    maxLength="50"
                                                    autoComplete="off"
                                                    onChange={handleChange}
                                                    className='form-control'
                                                    style={{ border: submitted && getFabric.Content === '' ? '1px solid red' : '' }}

                                                />
                                            </span>

                                        </div>


                                        <div className="form-group col-sm-3">
                                            <label htmlFor="GSMorCounts">
                                                GSM

                                            </label>
                                            <span className="input-icon icon-right">
                                                <Input
                                                    placeholder={"Enter GSM"}
                                                    id="GSMorCounts"
                                                    name="GSMorCounts"
                                                    value={getFabric.GSMorCounts}
                                                    type="text"
                                                    maxLength="50"
                                                    autoComplete="off"
                                                    onChange={handleChange}
                                                    className='form-control'
                                                //style={{ border: submitted && getCategory.CategoryName === '' ? '1px solid red' : '' }}

                                                />
                                            </span>

                                        </div>


                                    </div>

                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div
                        className="buttons-preview"
                        align="right"
                        style={{ marginTop: "15px" }}
                    >
                        <div className="buttons-preview" align="right">
                            <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                            </span>
                            <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                <i className="fa fa-times"></i>&nbsp;Reset
                            </span>
                            <button
                                type="submit"
                                className="btn btn-success"
                                onClick={SaveFabric}
                                disabled={buttonLoader} >

                                <i className="fa fa-check right"></i> &nbsp;Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )

};

export default EditFabric;