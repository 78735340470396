import {
    SWATCH_LOADING,
    RETRIEVE_SWATCH,
    DELETE_SWATCH,
} from "./types";

import SwatchMasterService from "../services/Master/SwatchMasterService";

const swatchLoading = (isStatus) => ({
    type: SWATCH_LOADING,
    payload: isStatus,
});

export const deleteSwatch = (swatchMasterID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_SWATCH,
            payload: swatchMasterID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrieveSwatch = () => async (dispatch) => {
    try {
        dispatch(swatchLoading(true));
        const res = await SwatchMasterService.GetSwatchMasterList(1, 0, 0, 0);
        dispatch({
            type: RETRIEVE_SWATCH,
            payload: res.data,
        });
        dispatch(swatchLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(swatchLoading(false));
    }
};