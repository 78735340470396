import React, { useState, useEffect } from "react";
import Nodify from "../../../Common/ReactNotification";
import SearchRetain from "../../../Common/SearchRetain";
import ReactNotification from 'react-notifications-component';
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import StrikeOffTypeService from "../../../../services/Master/StrikeOffTypeService";
import { useSelector } from "react-redux";

const StrikeOffType = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);

    const [getGroupTypeInfo, setGroupTypeInfo] = useState({});
    const [buttonLoader, setButtonLoader] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    function BindSeasonValue(props) {
        let SeasonInfoValue = { Operation: 2, StrikeOffTypeID: 0, StrikeOffTypeName: '', Createdby: currentUser.employeeinformationID };
        if (props.location.state !== undefined) {
            if (props.location.state.params.length !== 0) {
                if (props.location.state.params.StrikeOffTypeID !== 0) {
                    SeasonInfoValue.Operation = 2;
                    SeasonInfoValue.StrikeOffTypeID = props.location.state.params.strikeOffTypeID;
                    SeasonInfoValue.StrikeOffTypeName = props.location.state.params.strikeOffTypeName;
                    SeasonInfoValue.Createdby = currentUser.employeeinformationID;
                    return SeasonInfoValue;
                }
            }
        }
        else {
            return SeasonInfoValue;
        }
    }

    useEffect(() => {
        let Seasoninfo = BindSeasonValue(props);
        setGroupTypeInfo(Seasoninfo);
    }, []);

    //Enter Error msg
    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                //SaveBuyerInfo(event);
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/StrikeOffTypeList",
            state: {
                message: Func,
            },
        }
        );
    };

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/StrikeOffTypeList')
        } else {
            window.location.reload();
        }
    }

    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
    };

    const handleChange = (e) => {
        let Value = getGroupTypeInfo;
        if (e.target.name === "StrikeOffTypeName") {
            if (e.target.value.trim() !== '') {
                setSubmitted(true);
            }
            else {
                setSubmitted(false);
            }
            Value[e.target.name] = e.target.value;
        }
        setGroupTypeInfo(Value);
    }

    const SaveSeason = (e) => {
        setButtonLoader(true);
        e.preventDefault();
        if (getGroupTypeInfo.StrikeOffTypeName.trim() === '') {
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Strike Off Type Name is required.');
        }
        if (getGroupTypeInfo.StrikeOffTypeName.trim()) {
            getGroupTypeInfo.StrikeOffTypeName = getGroupTypeInfo.StrikeOffTypeName.trim();
            StrikeOffTypeService.InsertUpdateStrikeOffTypes(getGroupTypeInfo).then(res => {
                var page = "Remove";
                SearchRetain(page);
                let Func = 'Add';
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "-2") {
                    getGroupTypeInfo.StrikeOffTypeName = '';
                    setGroupTypeInfo(getGroupTypeInfo);
                    Nodify('Warning!', 'warning', 'This Strike Off Type Name is already exist.');
                }
                setButtonLoader(false);
            });
        }
        else {
            setSubmitted(true);
        }
    }

    return (
        <div className="page-body">
            <ReactNotification />

            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">
                                Edit Strike Off Type Name
                            </h3>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <Form>
                                    <div className="row">
                                        <div className="form-group col-sm-4">
                                            <label htmlFor="StrikeOffTypeName">
                                                Strike Off Type Name<span className="text-danger">*</span>
                                            </label>
                                            <span className="input-icon icon-right">
                                                <Input
                                                    placeholder={"Enter Strike Off Type Name"}
                                                    id="StrikeOffTypeName"
                                                    name="StrikeOffTypeName"
                                                    value={getGroupTypeInfo.StrikeOffTypeName}
                                                    type="text"
                                                    maxLength="50"
                                                    autoComplete="off"
                                                    onChange={handleChange}
                                                    className='form-control'
                                                    style={{ border: submitted && !getGroupTypeInfo.StrikeOffTypeName ? '1px solid red' : '' }}
                                                    autoFocus
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div
                        className="buttons-preview"
                        align="right"
                        style={{ marginTop: "15px" }}
                    >
                        <div className="buttons-preview" align="right">
                            <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                            </span>
                            <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                <i className="fa fa-times"></i>&nbsp;Reset
                            </span>
                            <button
                                type="submit"
                                className="btn btn-success"
                                onClick={SaveSeason}
                                disabled={buttonLoader}
                            >

                                <i className="fa fa-check right"></i> &nbsp;Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default StrikeOffType;