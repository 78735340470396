import React, { useState } from "react";
//import FullPageLoader from "../components/Datatable/FullPageLoader";
import loader from "../../resources/images/pageloader.gif";


const useFullPageLoader = () => {
    const [loading, setLoading] = useState(false);


    return [
        loading ?
            <div className="loading-container loading_FullPageLoader">
                {/* <div className="loader"></div> */}
                <img src={loader} className="preloader" alt="loading" />
            </div>
            : null,
        () => setLoading(true), //Show loader
        () => setLoading(false) //Hide Loader
    ];
};

export default useFullPageLoader;