import axios from "axios";
import authHeader from "../auth-header";

class PackageItemService {
    GetPackageImageList(Operation, PackageImageID) {
        return axios.get(window.$APIBaseURL + "Master/GetPackageImageList?Operation=" + Operation + '&PackageImageID=' + PackageImageID, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    InsertUpdatePackageImageGrd(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdatePackageImageGrid',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
    UpdatePackageImageGrd(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/UpdatePackageImageGrid',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
}
export default new PackageItemService;