import React, { useState, useEffect } from "react";
import { OTPForForgotPassword, UpdateForgotPassword, EraseforgotPasswordOTP } from './../../actions/auth';
import { useDispatch, useSelector } from "react-redux";
import Nodify from "./../Common/ReactNotification";
import ReactNotification from 'react-notifications-component';


const ForgotPassword = (props) => {
    const [getIsMailValid, setIsMailValid] = useState(true);
    const [getIsOTPValid, setIsOTPValid] = useState(true);
    const [getEnteredOTP, setIsOTPEntered] = useState(true);
    const [getIsDisableOTPbtn, setIsDisableOTPbtn] = useState(false);
    const [isRevealNewPwd, setIsRevealNewPwd] = useState(false);
    const [isRevealConfirmPwd, setIsRevealConfirmPwd] = useState(false);
    const [getDoPasswordsMatch, setDoPasswordsMatch] = useState(true);
    let ForgotPassOTP = useSelector((state) => state.auth.ForgotPasswordOTP);
    const [getVerifictaionOTP, setVerifictaionOTP] = useState({ VerifictaionCode: '' });
    const [getErrorMsg, setErrorMsg] = useState('Enter a valid Email address.');
    const [getOTPValidationMsg, setOTPValidationMsg] = useState('');
    const [getIsBtnLoad, setIsBtnLoad] = useState(false);
    const dispatch = useDispatch();
    const [getVerifictaionSucceded, setVerifictaionSucceded] = useState(false);
    const [getRemoveOTPFromState, setRemoveOTPFromState] = useState(false);
    const [getPassMandatoryFields, setPassMandatoryFields] = useState(false);
    const [getNewAndConfirmPasswords, setNewAndConfirmPasswords] = useState({
        NewPassword: ""
        , ConfirmPassword: ''
    });
    const [getForgotPasswordParams, setForgotPasswordParams] = useState({
        EmailAddress: '',
        OTP: '',
    });

    const [getUpdatePassword, setUpdatePassword] = useState({
        EmailAddress: '',
        EmployeeInformationID: 0,
        Password: '',
        ConfirmPassword: '',
    });

    let ForgotPasswordValues = {
        ...getForgotPasswordParams
    };
    let verifictaionCode = {
        ...getVerifictaionOTP
    };
    let NewPassvalue = {
        ...getUpdatePassword
    };

    const GoToLogin = () => {
        props.history.push("/login");
    }

    useEffect(() => {
        if (ForgotPassOTP !== undefined && ForgotPassOTP.id !== 0) {
            setIsDisableOTPbtn(false);
            setIsBtnLoad(false);
            if (getForgotPasswordParams.EmailAddress != "" &&
                getForgotPasswordParams.EmailAddress != undefined &&
                getForgotPasswordParams.EmailAddress != null) {
                setOTPValidationMsg("OTP sent to your registered email address.");
            }
            else if (getForgotPasswordParams.EmailAddress == "" ||
                getForgotPasswordParams.EmailAddress == undefined ||
                getForgotPasswordParams.EmailAddress == null) {
                setOTPValidationMsg("");
            }

        }
        if (ForgotPassOTP !== undefined && ForgotPassOTP.id === 0) {
            setIsBtnLoad(false);
            dispatch(EraseforgotPasswordOTP());
            setIsDisableOTPbtn(false);
            setOTPValidationMsg("User not found");
        }
    }, [(ForgotPassOTP !== undefined || ForgotPassOTP !== undefined && ForgotPassOTP.id === 0)])

    useEffect(() => {
        dispatch(EraseforgotPasswordOTP());
    }, [getRemoveOTPFromState])



    const handleInputChange = (e, fieldType) => {
        if (fieldType === "mailaddress") {
            ForgotPasswordValues.EmailAddress = e.target.value.trim();
            setForgotPasswordParams(ForgotPasswordValues);
        }
        else if (fieldType === "otp") {
            setOTPValidationMsg("");
            verifictaionCode.VerifictaionCode = e.target.value.trim();
            setVerifictaionOTP(verifictaionCode);
        }
        else if (fieldType === "password") {
            NewPassvalue.Password = e.target.value.trim();
            if (NewPassvalue.Password.trim() !== "") {
                setPassMandatoryFields(false);
            }
            setUpdatePassword(NewPassvalue);
        }
        else if (fieldType === "confirmpassword") {
            NewPassvalue.ConfirmPassword = e.target.value.trim();
            if (NewPassvalue.ConfirmPassword.trim() !== "") {
                setPassMandatoryFields(false);
            }
            setUpdatePassword(NewPassvalue);
        }

    }
    const ValidateMailPattern = (e) => {
        if (e.target.value.trim() !== "") {
            const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            if (!e.target.value || regex.test(e.target.value) === false) {
                setIsMailValid(false);
                setErrorMsg('Enter a valid email address.');
                return false;
            }
            setIsMailValid(true);
            setErrorMsg('');
            return true;
        }
        else {
            setOTPValidationMsg("");
            setIsMailValid(true);
        }
    }
    const ValidateandSendOTP = () => {
        setIsBtnLoad(true);
        if (ForgotPassOTP === undefined) {
            if (getForgotPasswordParams.EmailAddress.trim()) {
                if (getIsMailValid) {
                    dispatch(OTPForForgotPassword(getForgotPasswordParams));
                }
                else {
                    setErrorMsg('Enter a valid email address.');
                    setIsMailValid(false);
                    setIsBtnLoad(false);
                }
            }
            else {
                setErrorMsg('Enter a email address.');
                setIsMailValid(false);
                setIsBtnLoad(false);
            }
        }
        else {
            setIsBtnLoad(false);
            if (getVerifictaionOTP.VerifictaionCode === ForgotPassOTP.otp) {
                NewPassvalue.EmailAddress = ForgotPassOTP.emailAddress;
                NewPassvalue.EmployeeInformationID = ForgotPassOTP.id;
                setUpdatePassword(NewPassvalue);
                setVerifictaionSucceded(true);
                setIsOTPEntered(true);
                setIsOTPValid(true);
            }
            else if (getVerifictaionOTP.VerifictaionCode == "") {
                getVerifictaionOTP.VerifictaionCode = "";
                setVerifictaionOTP(verifictaionCode);
                setIsOTPEntered(false);
                setOTPValidationMsg("");
            }
            else {
                getVerifictaionOTP.VerifictaionCode = "";
                setVerifictaionOTP(verifictaionCode);
                setIsOTPValid(false);
            }
        }
    }
    const UpdateNewOTP = () => {
        setNewAndConfirmPasswords({
            NewPassword: NewPassvalue.Password
            , ConfirmPassword: NewPassvalue.ConfirmPassword
        })
       ;
        var bc = getNewAndConfirmPasswords;
        if (NewPassvalue.Password !== "" && NewPassvalue.ConfirmPassword !== "") {
            setPassMandatoryFields(false);
            if (getUpdatePassword.Password.trim() === getUpdatePassword.ConfirmPassword.trim()) {
                dispatch(UpdateForgotPassword(getUpdatePassword)).then((res) => {
                    if (res.outputResult === "1") {
                        setRemoveOTPFromState(true);
                        props.history.push({
                            pathname: "/login",
                            state: {
                                message: "Password updated successfully!"
                            }
                        });
                    }
                });
            }
            else {

            }
        }
        else if (NewPassvalue.Password === "" && NewPassvalue.ConfirmPassword === "") {
            setPassMandatoryFields(true);
            //Nodify('Warning!', 'warning', 'Please fill all mandatory(*) fields.');
            //alert('Please fill all mandatory(*) fields.');
        }
    }

    const ComparePasswords = (e) => {
        if (getUpdatePassword.Password.trim() !== getUpdatePassword.ConfirmPassword.trim()
            && getUpdatePassword.Password.trim() !== "" && getUpdatePassword.ConfirmPassword.trim() !== "") {
            setDoPasswordsMatch(false);
        }
        else {
            setDoPasswordsMatch(true);
        }
    }
    return (
        <section>

            <div className="login">
                <ReactNotification />
                <div className="container">
                    <div className="row login-box">
                        <div className="col-lg-7 bg-color-15 pad-0 none-992 bg-img">
                            <img src="assets/img/logo.png" className="logo_1" alt=""></img>
                            <p className="text_highlight">We are adding and updating information about hundreds of thousands of companies every day, and periodically add companies to the queue for being updated. You can ask for a company to be added to the front of the queue for updating, especially useful if the address, directors, or other critical information has changed.</p>
                        </div>
                        <div className="col-lg-5 pad-0 form-info">
                            <div className="form-section align-self-center">
                                <img src="assets/img/mobile_logo.png" className="mobile_logo" alt=""></img>
                                <h1>Welcome back !</h1>
                                <h3>Forgot Password/
                                    <a onClick={GoToLogin} className="forgot-passwordlogin blue">Login</a>
                                </h3>
                                <div className="clearfix"></div>

                                {(ForgotPassOTP === undefined || ForgotPassOTP !== undefined && ForgotPassOTP.id === 0) && <div className="form-group form-box">
                                    <label>Email address</label>
                                    <input type="text" placeholder="Enter Email Address" name="emailaddress"
                                        value={getForgotPasswordParams.EmailAddress}
                                        maxLength={50}
                                        onBlur={(event) => ValidateMailPattern(event)}
                                        onChange={(event) => handleInputChange(event, 'mailaddress')}
                                        className='form-control' />
                                </div>}
                                {ForgotPassOTP !== undefined && ForgotPassOTP.id !== 0 && getVerifictaionSucceded === false && <div className="form-group form-box">

                                    <label>OTP</label>
                                    <input type="text" className='form-control' placeholder="Enter OTP"
                                        minLength={6} maxLength={6}
                                        value={getVerifictaionOTP.VerifictaionCode}
                                        onChange={(event) => handleInputChange(event, 'otp')} />
                                </div>}

                                {ForgotPassOTP !== undefined && ForgotPassOTP.id !== 0 && getVerifictaionSucceded === true && <div className="form-group form-box">
                                    <label>New Password<span className="text-danger"> *</span></label>
                                    <input type={isRevealNewPwd ? "text" : "password"} className='form-control' placeholder="Enter New Password"
                                        value={getUpdatePassword.Password}
                                        onChange={(event) => handleInputChange(event, 'password')}
                                        onBlur={(event) => ComparePasswords(event)}
                                    />
                                    <i title={isRevealNewPwd ? "Hide password" : "Show password"}
                                        className={isRevealNewPwd ? "fa fa-eye-slash PasswordHideShow" : "fa fa-eye PasswordHideShow"}
                                        onClick={() => setIsRevealNewPwd(prevState => !prevState)}

                                    />
                                </div>}
                                {ForgotPassOTP !== undefined && ForgotPassOTP.id !== 0 && getVerifictaionSucceded === true && <div className="form-group form-box">
                                    <label>Confirm Password<span className="text-danger"> *</span></label>
                                    <input type={isRevealConfirmPwd ? "text" : "password"} className='form-control' placeholder="Enter Confirm Password"
                                        value={getUpdatePassword.ConfirmPassword}
                                        onChange={(event) => handleInputChange(event, 'confirmpassword')}
                                        onBlur={(event) => ComparePasswords(event)}
                                    />
                                    <i title={isRevealConfirmPwd ? "Hide password" : "Show password"}
                                        className={isRevealConfirmPwd ? "fa fa-eye-slash PasswordHideShow" : "fa fa-eye PasswordHideShow"}
                                        onClick={() => setIsRevealConfirmPwd(prevState => !prevState)}
                                    />
                                </div>}

                                <div className="form-group form-box clearfix">

                                </div>
                                <div className="form-group clearfix">
                                    {getVerifictaionSucceded === false &&
                                        <button className="btn-md btn-theme btn-block" onClick={ValidateandSendOTP} disabled={getIsDisableOTPbtn}>
                                            {!getIsBtnLoad && ForgotPassOTP === undefined && getIsDisableOTPbtn === false && <span>Send OTP</span>}
                                            {ForgotPassOTP !== undefined && <span>Verify OTP</span>}
                                            {getIsBtnLoad && <span>Sending OTP...</span>}
                                        </button>}
                                    {getVerifictaionSucceded === true && <button className="btn-md btn-theme btn-block" onClick={UpdateNewOTP}
                                        disabled={!getDoPasswordsMatch}>
                                        Update Password
                                    </button>}
                                </div>
                                {/* make use of below code if resend password is required */}
                                {/* {ForgotPassOTP !== undefined && getVerifictaionSucceded === false && <div className="checkbox form-group clearfix">
                                    <a href="#Empty" className="forgot-password">Resend OTP?</a>
                                </div>} */}
                                <span className="text-danger">{getEnteredOTP && getOTPValidationMsg}</span>
                                {!getIsMailValid && <span className="text-danger">{getErrorMsg}</span>}
                                {!getIsOTPValid && !getIsBtnLoad && <span className="text-danger" >Verifictaion code is not valid.</span>}
                                {!getEnteredOTP && !getIsBtnLoad && <span className="text-danger" >Please enter OTP.</span>}
                                {getDoPasswordsMatch === false && <span className="text-danger">New Password and Confirm Password should match </span>}
                                {getPassMandatoryFields && <span className="text-danger">Please fill all mandatory(*) fields.</span>}
                                {((getNewAndConfirmPasswords.NewPassword == '' || getNewAndConfirmPasswords.NewPassword == undefined) &&
                                    (getNewAndConfirmPasswords.ConfirmPassword != '' && getNewAndConfirmPasswords.ConfirmPassword != undefined)) &&
                                    <span className="text-danger">Please enter New Password.</span>}
                                {((getNewAndConfirmPasswords.NewPassword != '' && getNewAndConfirmPasswords.NewPassword != undefined) &&
                                    (getNewAndConfirmPasswords.ConfirmPassword == '' || getNewAndConfirmPasswords.ConfirmPassword == undefined)) &&
                                    <span className="text-danger">Please enter Confirm Password.</span>}
                                {/* <span className="forgotpassword-validtaions">Please enter Confirm Password.</span> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
};
export default ForgotPassword;
