import React, { useState, useEffect } from "react";
import Form from "react-validation/build/form";
//import Input from "react-validation/build/input";
import PantoneService from "../../../services/Master/PantoneService";
//import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import ReactNotification from 'react-notifications-component';
import $ from "jquery";
import validator from 'validator'
import Nodify from "../../Common/ReactNotification";
import SearchRetain from "../../Common/SearchRetain";
import Input from "react-validation/build/input";
import { useSelector } from "react-redux";


const PantoneTypeRegister = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const [submitted, setSubmitted] = useState(false);
    const [getAddress, setOutputs] = useState({});
    const [ButtonName, setButtonName] = useState("Save");
    const [HeaderName, setHeaderName] = useState("Add");
    const [buttonLoader, setButtonLoader] = useState(false);

    function pantoneAssign(props) {
        let pantoneAssign = {
            PantoneTypeID: 0,
            PantoneTypeName: '',
            // Operation: 1,
            Createdby: currentUser.employeeinformationID
        };

        Array.from(document.querySelectorAll("input")).forEach(input => (input.value = ""));
        if (props.location.state.params.length !== 0) {
            if (props.location.state.params.pantoneTypeID !== "") {
                setButtonName("Update");
                setHeaderName("Edit");
                pantoneAssign.PantoneTypeID = props.location.state.params.pantoneTypeID;
                pantoneAssign.PantoneTypeName = validator.trim(props.location.state.params.pantoneTypeName);
                pantoneAssign.Operation = 2;
                return pantoneAssign;
            }
        } else {
            return pantoneAssign;
        }
    }

    useEffect(() => { // showLoader();
        let getAddressAssign = pantoneAssign(props);
        setOutputs(getAddressAssign)
    }, []);

    const handlePantone = (e) => {
        setButtonLoader(true);
        e.preventDefault();
        if (getAddress.PantoneTypeName.trim() === '') {
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Pantone type is required');
        }
        else {
            getAddress.PantoneTypeName = getAddress.PantoneTypeName.trim();
            PantoneService.AddPantoneType(getAddress).then(res => {
                var page = "Remove";
                SearchRetain(page);
                let Func = 'Add';
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "-2") {
                    getAddress.PantoneTypeName = '';
                    setOutputs(getAddress);
                    Nodify('Warning!', 'warning', 'Pantone type already Exist');
                }
                setButtonLoader(false);

            });
        }

    };

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/TypePantoneList",
            state: {
                message: Func
            }
        });
    }


    //     function handleChange(e) {
    //         const { name, value } = e.target;
    //         setInputs(inputs => ({
    //             ...inputs,
    //             [name]: value
    //         }));
    //         let addressValue = getAddress;
    //         let inputText = '';
    //         if (e.target.value.trim() !== '') {
    //             inputText = e.target.value;
    //             setSubmitted(true); }
    //         addressValue[e.target.name] = inputText;
    //         setOutputs(addressValue);
    //   }

    const handleChange = (e) => {
        let Value = getAddress;

        if (e.target.name === "PantoneTypeName") {
            if (e.target.value.trim() !== '') {
                setSubmitted(true);
            }
            else {
                setSubmitted(false);
            }
            Value[e.target.name] = e.target.value;
        }
        setOutputs(Value);
    }



    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/TypePantoneList')
        } else {
            // sethideValidation(1);
            // setSubmitted(false);
            // let getAddressAssign = pantoneAssign(props);
            // setOutputs(getAddressAssign)
            window.location.reload();
        }
    }



    return (
        <div className="page-body">
            <ReactNotification />
            {
                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <div className="widget flat radius-bordered">
                            <div className="widget-header heading_box_style">
                                <h3 className="heading_style_common">{HeaderName} Pantone Type</h3>
                            </div>
                            <div className="widget-body">
                                <div id="registration-form">
                                    <Form onSubmit={handlePantone}>
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <div className="form-group">
                                                    {/* <Input name="AddressTypeID" className="form-control"
                                                            onChange={handleChange}
                                                            value={getAddress.AddressTypeID}
                                                            type="number"
                                                            style={{ display: 'none' }} /> */}

                                                    <label htmlFor="PantoneTypeName">Pantone Type Name<span className="text-danger">*</span></label>
                                                    <span className="input-icon icon-right">
                                                        {/* <input type="text" name="PantoneTypeName"
                                                                id="PantoneTypeName"
                                                                value={getAddress.PantoneTypeName}
                                                                onChange={
                                                                    event => handleChange(event)
                                                                }
                                                                style={{ border: submitted && !getAddress.PantoneTypeName ? '1px solid red' : '' }}
                                                                maxLength="25"
                                                                className={
                                                                    'form-control' + (
                                                                        submitted && !getAddress.PantoneTypeName ? ' is-invalid' : ''
                                                                    )
                                                                }
                                                                autoFocus
                                                                placeholder="Pantone Type Name"
                                                            /> */}

                                                        <Input
                                                            placeholder={"Enter Pantone Type"}
                                                            id="PantoneTypeName"
                                                            name="PantoneTypeName"
                                                            value={getAddress.PantoneTypeName}
                                                            type="text"
                                                            maxLength="50"
                                                            autoComplete="off"
                                                            onChange={handleChange}
                                                            className='form-control'
                                                            autoFocus
                                                            style={{ border: submitted && !getAddress.PantoneTypeName ? '1px solid red' : '' }}
                                                        />

                                                    </span>

                                                </div>
                                            </div>
                                            <div className="clearfix"></div>
                                            <div className="col-sm-12">
                                                <div className="buttons-preview" align="right">
                                                    <span className="btn btn-primary"
                                                        onClick={
                                                            () => ResetOperation("Back")
                                                        }>
                                                        <i className="fa fa-arrow-circle-left"></i>
                                                        &nbsp;Back</span>
                                                    <span className="btn btn-danger"
                                                        onClick={
                                                            () => ResetOperation()
                                                        }>
                                                        <i className="fa fa-times"></i>
                                                        &nbsp;Reset</span>
                                                    <button type="submit" className="btn btn-success"
                                                        disabled={buttonLoader}
                                                    >

                                                        <i className="fa fa-check right"></i>
                                                        &nbsp;{
                                                            ButtonName
                                                        }</button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            }
            { }</div>
    );
};

export default PantoneTypeRegister;
