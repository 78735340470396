import React, { useState, useEffect } from "react";
import Nodify from "../../Common/ReactNotification";
import SearchRetain from "../../Common/SearchRetain";
import ReactNotification from 'react-notifications-component';
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import { useSelector } from "react-redux";
import WorkmanshipItem from "../../../services/Master/WorkmanshipItem";

const EditWorkmanship = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);

    const [getWorkmanship, setWorkmanship] = useState({});
    const [buttonLoader, setButtonLoader] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [countryList, setcountryList] = useState([]);
    const [getWorkmanshipEm, SetWorkmanshipEm] = useState([{ workmanshipItemID: 0, workmanItemName: "" }]);

    function BindCurrencyValue(props) {
       
        let StateInfoValue = { Operation: 2, workmanshipItemID: 0, workmanItemName: '', WorkmanItemInformation: getWorkmanshipEm, Createdby: currentUser.employeeinformationID, };
        if (props.location.state !== undefined) {
            if (props.location.state.params.length !== 0) {
                if (props.location.state.params.stateID !== 0) {
                    StateInfoValue.Operation = 2;
                    StateInfoValue.workmanshipItemID = props.location.state.params.workmanshipItemID;
                    StateInfoValue.workmanItemName = props.location.state.params.workmanItemName;
                    StateInfoValue.WorkmanItemInformation = getWorkmanshipEm;
                    StateInfoValue.Createdby = currentUser.employeeinformationID;
                    return StateInfoValue;
                }
            }
        }
        else {
            return StateInfoValue;
        }
    }
    useEffect(() => {
        let StateInfo = BindCurrencyValue(props);
        setWorkmanship(StateInfo);
    }, []);
    //Enter Error msg
    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                //SaveBuyerInfo(event);
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/WorkmanshipList",
            state: {
                message: Func,
            },
        }
        );
    };
    const handleChange = (e, FieldName) => {
       
        let Value = getWorkmanship;
        if (FieldName === "Workmanship") {
            if (e.target.value.trim() !== '') {
                setSubmitted(false);
            }
            else {
                setSubmitted(true);
            }
            Value.workmanItemName = e.target.value;
        }

        setWorkmanship(Value);
    }
    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/WorkmanshipList')
        } else {
            window.location.reload();
        }
    }
    const SaveWorkmanship = (e) => {
        setButtonLoader(true);
        e.preventDefault();
       
        if (getWorkmanship.workmanItemName.trim() === '') {
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Please fill the mandatory (*) fields.');
            return false;
        }

        if (getWorkmanship.workmanItemName.trim()) {
            let InputMaster = {
                Operation: getWorkmanship.Operation, workmanshipItemID: getWorkmanship.workmanshipItemID,
                workmanItemName: getWorkmanship.workmanItemName, WorkmanItemInformation: getWorkmanshipEm, Createdby: getWorkmanship.Createdby
            }
            WorkmanshipItem.InsertUpdateWorkmanshipItem(InputMaster).then(res => {
                var page = "Remove";
                SearchRetain(page);
                let Func = 'Add';
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "-2") {
                    getWorkmanship.workmanItemName = '';
                    setWorkmanship(getWorkmanship);
                    setSubmitted(true);
                    Nodify('Warning!', 'warning', 'This Workmanship details is already exist.');
                }
                setButtonLoader(false);
            });
        }
        else {
            setSubmitted(true);
        }
    }
    return (
        <div className="page-body">
            <ReactNotification />

            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">
                                Edit Workmanship Details
                            </h3>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <Form>
                                    <div className="row">

                                        <div className="form-group col-sm-4">
                                            <label htmlFor="Workmanship">
                                                Workmanship Name<span className="text-danger">*</span>
                                            </label>
                                            <span className="input-icon icon-right">
                                                <Input
                                                    placeholder={"Enter Workmanship Name"}
                                                    id="Workmanship"
                                                    name="Workmanship"
                                                    value={getWorkmanship.workmanItemName}
                                                    type="text"
                                                    maxLength="50"
                                                    autoComplete="off"
                                                    onChange={event => handleChange(event, "Workmanship")}
                                                    className='form-control'
                                                    style={{ border: submitted && !getWorkmanship.workmanItemName ? '1px solid red' : '' }}
                                                    autoFocus
                                                />
                                            </span>

                                        </div>

                                    </div>

                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div
                        className="buttons-preview"
                        align="right"
                        style={{ marginTop: "15px" }}
                    >
                        <div className="buttons-preview" align="right">
                            <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                            </span>
                            <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                <i className="fa fa-times"></i>&nbsp;Reset
                            </span>
                            <button
                                type="submit"
                                className="btn btn-success"
                                onClick={SaveWorkmanship}
                                disabled={buttonLoader}
                            >

                                <i className="fa fa-check right"></i>&nbsp;Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EditWorkmanship