import {
  DEFECT_LOADING,
  RETRIEVE_DEFECT,
  DELETE_DEFECT
} from "../actions/types";

const initialState = {

  isLoadingDefect: true,
  defectsList: [],
};

const DefectReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case DEFECT_LOADING:
      return {
        ...state,
        isLoadingDefect: payload
      };
    // case CREATE_BRANCH:
    //  return { ...state, branches: payload };

    case DELETE_DEFECT:
      
      const results = state.defectsList.filter(c => c.defectTypeID !== payload.defectTypeID);
      return {
        ...state,
        defectsList: results
      };
    case RETRIEVE_DEFECT:
      return { ...state, defectsList: payload };


    default:
      return state;
  }

}

export default DefectReducer;