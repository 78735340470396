import React, { useState, useEffect, useMemo } from "react";
import SweetAlertPopup from '../../Common/SweetAlertPopup';
import Form from "react-validation/build/form";
import $ from "jquery";
import Nodify from "../../Common/ReactNotification";
import { StyleSampleRequestID } from "../../Common/PageActionNumber";
import CommonService from "../../../services/Common/CommonService";
import styleservice from "../../../services/Style/styleService";
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { retrieveSamplereq } from "../../../actions/samplereq"
import SearchRetain from "../../Common/SearchRetain"
import ReactNotification from 'react-notifications-component';
import { TableHeader, Pagination, Search, PageCount } from "../../Datatable";
import Loader from "react-loader-spinner";

const SampleReqList = ({ props, SamplereqRedirect, SampletreqList, SamplereqCallback, StyleInfo,
    ValidationCallBack, IsValidationCallback, SampleInfo }) => {

    // Selecters
    //const [comments, setComments] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState();
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    const [getID, setID] = useState(0);
    const [getPlaceholder] = useState("Sample / SKU");
    const [getres, setres] = useState(0);
    const [getSamplereqList, setSamplereqList] = useState([]);
    const StyleID = StyleInfo.styleID !== undefined ? StyleInfo.styleID : StyleInfo.StyleID;

    const dispatch = useDispatch();

    //reducer
    const reducerState = useSelector((state) => state);
    const { user: currentUser } = reducerState.auth;
    // const comments = reducerState.samplereq.SamplereqList;
    // let isLoadingSamplereq = reducerState.samplereq.isLoadingSamplereq;

    useEffect(() => {
        // setres(0);

        setres(0);
        //  dispatch(retrieveSamplereq(StyleID));
        styleservice.GetSamplereqList(1, StyleID, 0).then((response) => {
            if (response.data) {
                setSamplereqList(response.data);
                if (response.data.length === 0) {
                    setres(1);
                }
            }
        });
    }, []);

    // useEffect(() => {
    //     if (!isLoadingSamplereq) {
    //         hideLoader();
    //     } else {
    //         showLoader();
    //     }
    // }, [isLoadingSamplereq]);

    const pageRedirect = (Params, redirect) => {
        if (redirect === 'Edit') {
            let value = { data: Params, Page: 'ADDSAMPLEREQUEST' }
            SamplereqRedirect(value);
        }
        else {
            let Param = {
                Operation: 37,
                Id: Params.styleSampleRequestID,
            };
            CommonService.CheckMappedItem(Param).then(res => {
                if (res.data.outputResult === "-2") {
                    // let message = "This Sample Request is already mapped.";
                    // ValidationCallBack(message);
                    Nodify('Warning!', 'warning', 'This Sample Request is already mapped.');
                }
                else {
                    setID({ showPopupDelete: true, Params: Params });
                }
            });
        }
    };

    const headers = [
        { name: "#", field: "", sortable: false,widthsize:'3%'},
        { name: "Sample", field: "SampleRequestName", sortable: false },
        { name: "SKU's", field: "SkuColorNames", sortable: false },
        { name: "Size and Qty", field: "SizeNamesandQty", sortable: false },
        { name: "Action", field: "", sortable: false, display: SampleInfo && SampleInfo.isEdit === 0 && SampleInfo.isDelete === 0 ? 'none' : '' },
    ];

    if (!currentUser) {
        return <Redirect to="/login" />;
    }
    const Delcallback = (value) => {
        if (value === true) {
            setres(0);
            //  dispatch(retrieveSamplereq(StyleID));
            styleservice.GetSamplereqList(1, StyleID, 0).then((response) => {
                if (response.data) {
                    setSamplereqList(response.data);
                    if (response.data.length === 0) {
                        setres(1);
                    }
                }

            }).catch(() => { });
        } else if (value === false) {
            setID({ showPopupDelete: false, Params: [] });
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-xs-12 col-md-12">
                    <div className="widget">
                        <div className="widget-body">
                            <div className="dataTables_wrapper form-inline no-footer">
                                <div className="table-toolbar pull-left"></div>
                                {
                                    getSamplereqList.length > 0 ?
                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                            <TableHeader headers={headers} />
                                            <tbody>
                                                {
                                                    getSamplereqList.map((program,index)=> (
                                                        <>
                                                            <tr>
                                                                <td>{index+1}</td>
                                                                <td style={{ width: "200px" }}>{program.sampleRequestName}</td>
                                                                <td style={{ width: "200px" }}>{program.skuColorNames}</td>
                                                                <td style={{ width: "200px" }}>{program.sizeNamesandQty}</td>
                                                                <td style={{ display: SampleInfo && SampleInfo.isEdit === 0 && SampleInfo.isDelete === 0 ? 'none' : '' }}>
                                                                    <span style={{ display: SampleInfo && SampleInfo.isEdit === 1 ? '' : 'none' }} title='Edit Sample Request' onClick={() => pageRedirect(program, 'Edit')}
                                                                        className="btn btn-success btn-xs edit">
                                                                        <i className="fa fa-edit "></i>
                                                                    </span>
                                                                    &nbsp;
                                                                    <span style={{ display: SampleInfo && SampleInfo.isDelete === 1 ? '' : 'none' }} title='Delete Sample Request' onClick={() => pageRedirect(program, '')}
                                                                        className="btn btn-danger btn-xs delete">
                                                                        <i className="fa fa-trash-o"></i>
                                                                    </span>
                                                                </td>
                                                            </tr>

                                                        </>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                        :
                                        <>
                                            {
                                                getSamplereqList.length === 0 && getres === 1 ?
                                                    <>
                                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                            <TableHeader headers={headers} />
                                                            <tbody>
                                                                <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                                                            </tbody>
                                                        </table>
                                                    </>
                                                    : ''
                                            }
                                            {getres === 0 ? <Loader type="ThreeDots" color="#b6da94" height={80} width={80} timeout={2000} style={{ textAlign: "center" }} /> : ''}
                                        </>
                                }
                            </div>
                        </div>
                        {
                            getID.showPopupDelete ?
                                <SweetAlertPopup data={getID.Params}
                                    deleteCallback={Delcallback}
                                    showpopup={true}
                                    pageActionId={StyleSampleRequestID}
                                    Msg={"Sample request deleted successfully."} /> : null //Program deleted successfully.
                        } </div>
                </div>
            </div>
        </>
    );
}
export default SampleReqList;