import React, { useState, useEffect, Fragment, useRef } from "react";
import ReactNotification from 'react-notifications-component';
import agencyService from "../../../../services/Master/Agency.service";
import Nodify from "../../../Common/ReactNotification"
import { useDispatch, useSelector } from "react-redux";
import Reactselect from 'react-select';
import { LoadAgencyDetails } from './../../../../actions/agency';

const AgencyRegister = (props) => {
    const dispatch = useDispatch();
    const EmptyInputFields = { AgencyTypeID: 0, AgencyName: '' };
    const [inputFields, setInputFields] = useState([EmptyInputFields]);
    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [valid, setvalid] = useState(true);
    const [ExistingList, setExistingList] = useState([]);
    const [index, setindex] = useState()
    const values = [...inputFields];
    let activeMenu;
    const CurrentUser = useSelector((state) => state.auth.user);
    const menulist = useSelector((state) => state.common.MenuList.item3);
    const AgencyDDL = useSelector((state) => state.agencyReducer.AgencyType);
    const AgencyGridList = useSelector((state) => state.agencyReducer.AgencyList);
    const AgencyTypeRef = useRef(null);
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),
    }
    if (menulist !== undefined) { activeMenu = menulist.filter(i => i.menuName === "Agency"); }

    useEffect(() => {
        dispatch(LoadAgencyDetails());
    }, []);

    useEffect(() => {
        setExistingList(AgencyGridList);
    }, [AgencyGridList]);

    const handleAddFields = (index) => {


        if (ExistingList.length !== 0) {
            if (values[index].AgencyName !== '' && values[index].AgencyTypeID !== 0) {
                for (var i = 0; i < ExistingList.length; i++) {
                    if (ExistingList[i].agencyName.trim().toLowerCase() === values[index].AgencyName.trim().toLowerCase()
                        && ExistingList[i].agencyTypeID === values[index].AgencyTypeID

                    ) {
                        values[index].AgencyName = '';
                        setInputFields(values);
                        Nodify('Warning!', 'warning', "Agency name already exist.");
                        return false;
                    }
                }
            }
        }
        for (var j = 0; j < values.length; j++) {
            if (index !== j) {
                if (values[index].AgencyName !== '' && values[index].AgencyTypeID !== '') { // for avoid empty validation
                    if (values[j].AgencyName === values[index].AgencyName
                        && values[j].AgencyTypeID === values[index].AgencyTypeID
                    ) {
                        values[index].AgencyName = '';
                        setInputFields(values);
                        Nodify('Warning!', 'warning', 'Agency name already exist.');
                        return false;
                    }
                }
            }
        }
        if (values[index].AgencyName !== '' && values[index].AgencyTypeID !== 0) {
            values.push(EmptyInputFields);
            setInputFields(values);
            setSubmitted(false);
        } else {
            Nodify('Warning!', 'warning', 'Please fill this mandatory(*) fields.');
            setSubmitted(true);
        }
    }

    const handleAgencyTypeChange = (index, event) => {
        let inputText = '';
        if (event !== null) {
            if (event.value !== '') {
                inputText = event.value;
                values[index].AgencyTypeID = inputText;
                setInputFields(values);
            }
        }
        else {
            values[index].AgencyTypeID = 0;
            setInputFields(values);
        }
        for (var j = 0; j < values.length; j++) {
            if (index !== j) {
                if (values[index].AgencyName !== '' && values[index].AgencyTypeID !== '') { // for avoid empty validation
                    if (values[j].AgencyName === values[index].AgencyName
                        && values[j].AgencyTypeID === values[index].AgencyTypeID
                    ) {
                        values[index].AgencyTypeID = 0;
                        setInputFields(values);
                        setButtonLoader(false)
                        setSubmitted(false)
                        Nodify('Warning!', 'warning', 'Agency Name and Type Allready Exists.');
                        return false;
                    }
                }
            }
        }
    }

    const handleInputChange = (index, event) => {
        let inputText = '';
        if (event.target.value !== '') {
            inputText = event.target.value;
        }
        values[index].AgencyName = inputText;
        setInputFields(values);
    }

    const handleRemoveFields = index => {
        values.splice(index, 1);
        setInputFields(values);
    }

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/AgencyList');
        } else {
            window.location.reload();
        }
    }

    const CheckDuplicate = (index) => {
        if (ExistingList.length !== 0) {
            if (values[index].AgencyName !== '' && values[index].AgencyTypeID !== 0) {
                for (var i = 0; i < ExistingList.length; i++) {
                    if (ExistingList[i].agencyName.trim().toLowerCase() === values[index].AgencyName.trim().toLowerCase()
                        && ExistingList[i].agencyTypeID === values[index].AgencyTypeID

                    ) {
                        values[index].AgencyName = '';
                        setInputFields(values);
                        Nodify('Warning!', 'warning', "Agency name already exist.");
                        return false;
                    }
                }
            }
        }

        for (var j = 0; j < values.length; j++) {
            if (index !== j) {
                if (values[index].AgencyName !== '' && values[index].AgencyTypeID !== '') { // for avoid empty validation
                    if (values[j].AgencyName.trim().toLowerCase() === values[index].AgencyName.trim().toLowerCase()
                        && values[j].AgencyTypeID === values[index].AgencyTypeID
                    ) {
                        values[index].AgencyName = '';
                        values[index].AgencyTypeID = 0;
                        setInputFields(values);
                        setButtonLoader(false)
                        setSubmitted(false)
                        Nodify('Warning!', 'warning', 'Agency Name and Type Allready Exists.');
                        return false;
                    }
                }
            }
        }


    }

    const SaveAgencyList = (e) => {
        setButtonLoader(true);
        e.preventDefault();
        if (values.length === 1 && (values[0].AgencyName.trim() === '' || values[0].AgencyTypeID === 0)) {
            setButtonLoader(false);
            setSubmitted(true);
            ValidationPopup("Please fill all mandatory(*) fields.");
        } else {
            let Isvalid = false;
            for (var j = 0; j < values.length; j++) {
                if (values[j].AgencyName === '' || values[j].AgencyTypeID === 0) {
                    Isvalid = true
                }
            }
            if (!Isvalid) {
                setSubmitted(false);
                let AgencyListParam = {};
                AgencyListParam.TypeAgency = values;
                AgencyListParam.CreatedBy = CurrentUser.employeeinformationID;
                agencyService.InsertAgencyDetails(AgencyListParam).then(res => {
                    var page = "Remove";
                    let Func = 'ADDED';
                    if (res.data.outputResult === "1") {
                        Func = 'ADDED';
                        PageRedirect(Func);
                    } if (res.data.outputResult === "0") {
                        Nodify("Warning", "warning", "Agency name already exist.");
                    }
                });
            } else {
                setButtonLoader(false);
                setSubmitted(true);
                ValidationPopup("Please fill all mandatory(*) fields.");
            }
        }
    }

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/AgencyList",
            state: {
                message: Func
            }
        });
    }

    const ValidationPopup = (Msg) => {
        Nodify('Warning!', 'warning', Msg);
        setButtonLoader(false);
        return false;
    }
    const FocusAgencyType = () => {
        AgencyTypeRef.current.focus();
    }

    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">Add Agency</h3>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <form>
                                    {
                                        inputFields.map((inputField, index) => (
                                            <Fragment key={
                                                `${inputField}~${index}`
                                            }>
                                                <div className="row row_left10">
                                                    <div className="col-sm-3 divder_style">
                                                        <div className="form-group">
                                                            <label htmlFor={
                                                                "AgencyName_" + index
                                                            }>Agency Name<span className="text-danger">*</span>
                                                            </label>
                                                            <span className="input-icon icon-right">
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"AgencyName_" + index}
                                                                    name="Content"
                                                                    placeholder="Enter Agency Name"
                                                                    maxLength="50"
                                                                    value={inputField.AgencyName}
                                                                    onChange={event => handleInputChange(index, event)}
                                                                    onBlur={() => CheckDuplicate(index, 'AgencyName')}
                                                                    style={{ border: submitted && !inputField.AgencyName ? '1px solid red' : '' }}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3 divder_style">
                                                        <div className="form-group">
                                                            <label htmlFor={
                                                                "AgencyType_" + index
                                                            } onClick={FocusAgencyType}>Agency Type<span className="text-danger">*</span>
                                                            </label>
                                                            <span className="input-icon icon-right">
                                                                <Reactselect className="basic-single" name="AgencyType"
                                                                    id={"AgencyType_" + index}
                                                                    ref={AgencyTypeRef}
                                                                    isDisabled={false}
                                                                    isLoading={false}
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    options={AgencyDDL}
                                                                    value={AgencyDDL.filter(function (option) {
                                                                        return option.value === inputField.AgencyTypeID;
                                                                    })}
                                                                    onBlur={() => CheckDuplicate(index, 'AgencyName')}
                                                                    onChange={event => handleAgencyTypeChange(index, event)}
                                                                    autoFocus
                                                                    // isMulti
                                                                    styles={submitted && inputField.AgencyTypeID === 0 ? styles : ''}
                                                                ></Reactselect>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3 paddingstatic">
                                                        {
                                                            inputFields.length !== 1 && (
                                                                <div className="col-lg-1_own col-xs-12">
                                                                    <button type="button" className="btn btn-danger yarn"
                                                                        style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}
                                                                        title="Delete Agency detail" onClick={() => handleRemoveFields(index)}>
                                                                        <i className="fa fa-trash-o"></i>
                                                                    </button>
                                                                </div>
                                                            )}
                                                        {
                                                            inputFields.length === (index + 1) && (
                                                                <div className="col-lg-1_own col-xs-12">

                                                                    <button type="button" className="btn btn-success yarn" title="Add Agency detail" onClick={() => handleAddFields(index)}>
                                                                        <i className="fa fa-plus"></i>
                                                                    </button>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </Fragment>
                                        ))
                                    }
                                    <div className="col-sm-12">
                                        <div className="buttons-preview" align="right">
                                            <span className="btn btn-primary"
                                                onClick={
                                                    () => ResetOperation("Back")
                                                }>
                                                <i className="fa fa-arrow-circle-left"></i>
                                                &nbsp;Back</span>
                                            <span className="btn btn-danger"
                                                onClick={
                                                    () => ResetOperation()
                                                }>
                                                <i className="fa fa-times"></i>
                                                &nbsp;Reset</span>
                                            <button type="button" className="btn btn-success"
                                                onClick={SaveAgencyList}
                                                disabled={buttonLoader}>
                                                <i className="fa fa-check right"></i>
                                                &nbsp;Save</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AgencyRegister;