import React, { useState, useEffect, Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import TNAService from "../../../../services/TNA/TNAService";
const QAReInspection = ({ props, Reinspectionparams, QCFinalInspectionCallback, Taskname }) => {

  const [selectall, setselectall] = useState(false);
  const [MultiCloseDatas, setMultiCloseDatas] = useState([]);
  const menulist = useSelector((state) => state.auth.UserMenuList.item3);
  const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
  const dispatch = useDispatch();
  const reducerState = useSelector((state) => state);

  let StartStopInfo;
  let activeMenu;
  let activeMenuSub;
  if (menulist !== undefined && menulistSub.length > 0) {
    activeMenu = menulist.filter(i => i.menuName === 'TNA');
    if (activeMenu.length > 0) {
      activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);
      if (activeMenuSub.length > 0) {
        for (let index = 0; index < activeMenuSub.length; index++) {
          if (activeMenuSub[index].menuName === 'TNAstartstop') {
            StartStopInfo = activeMenuSub[index];
          }
        }
      }
    }
  }

  useEffect(() => {
   
    TNAService.GetTestReInspectionDetailList((Taskname === 'FABRIC TEST' || Taskname === 'YARN TEST' || Taskname === 'GARMENT TEST') ? 1 :
      Taskname === 'Strike off Test' ? 3 : 5, 0, Reinspectionparams.tnaid, Taskname).then((response) => {
        if (response.data) {
          setMultiCloseDatas(response.data)
        }
      }).catch(() => { });
  }, []);



  const SelectAll = (event) => {
    let IsSelected = 0;

    if (event.target.checked === true) {
      IsSelected = 1;
      MultiCloseDatas.forEach(element => {
        element.isSelected = 1;
      })
    }
    else {
      IsSelected = 0;
      MultiCloseDatas.forEach(element => {
        element.isSelected = 0;
      })
    }
    setselectall(IsSelected);
    MultiSelecteddate(MultiCloseDatas)
  }

  const OnSelectRecords = (event, index) => {
   
    const value = [...MultiCloseDatas];

    if (event.target.checked === true) {
      value[index].isSelected = 1;

    }
    else {
      value[index].isSelected = 0;

    }

    // let isSelectAll = 0;
    // if (value) {
    //     isSelectAll = !!!value.find(d => d.isSelected === 0);
    //     isSelectAll = isSelectAll ? 1 : 0;
    // }
    // setselectall(isSelectAll);
    MultiSelecteddate(value);
    setMultiCloseDatas(value);

  }
  const MultiSelecteddate = (value) => {
    QCFinalInspectionCallback(value);
  }


  return (
    <Fragment>
      <div className="inspection" style={{ height: "70vh", overflowX: "scroll", overflowY: "scroll", width: "101%" }}>
        <label className="alert alert-info comment-report-note"><b>Note : </b>Added comments report factory info, shown here!</label>
        <table className="table table-bordered">
          <thead>
            <tr style={{ whiteSpace: "nowrap" }}>
              <th>Report No</th>
              <th>Style Name</th>
              <th>Style No</th>
              <th>SKU ID</th>
              <th>SKU Name</th>
              <th>PO/ID No</th>
              <th>FO PO No</th>
              {/* <th>Factory</th> */}
              {(Taskname === 'Strike off Test' || Taskname === 'Trims Test') ? '' : <th>Inspected Qty</th>}
              <th>Task Holder / Follower</th>
              <th>
                <label style={{ marginTop: '8px' }}>
                  <input type="checkbox" name="IsSelected"

                    onChange={event => (SelectAll(event))}
                    checked={selectall === 1 ? true : false}
                    className="colored-blue clearOpacity" />
                  <span className="text"></span>
                </label>
              </th>
            </tr>
          </thead>
          <tbody>
            {
              MultiCloseDatas.length !== 0 ?
                MultiCloseDatas.map((item, index) => {
                  return (
                    <>
                      <tr>
                        <td>{item.tnaTestingInspectionID}</td>
                        <td>{item.styleName}</td>
                        <td>{item.styleNo}</td>
                        {(Taskname === 'Strike off Test' || Taskname === 'Trims Test') ? <td>{item.skuIdList}</td> : <td>{item.skuID}</td>}
                        <td>{item.skuName}</td>
                        <td>{item.idNo}</td>
                        <td>{item.poNo}</td>
                        {/* <td>{item.factory}</td> */}
                        {(Taskname === 'Strike off Test' || Taskname === 'Trims Test') ? '' : <td>{item.inspectedQty}</td>}
                        <td className="cut_textInTNA" title={item.taskOwnerNameList}>{item.taskOwnerNameList}</td>
                        <td className="Actionbutton">
                          <div style={{ width: "80px" }}>
                            {

                              <label style={{ marginTop: '8px' }}>
                                <input type="checkbox" name="IsSelected"
                                  disabled={item.balanceQuantity === "0" ? true : false}
                                  onChange={event => (OnSelectRecords(event, index))}
                                  checked={item.isSelected === 1 ? true : false}
                                  className="colored-blue clearOpacity" />
                                <span className="text"></span>
                              </label>
                            }&nbsp;&nbsp;&nbsp;
                          </div>
                        </td>
                      </tr>
                    </>

                  )
                }) :
                <tr>
                  <td colSpan="12" className='norecordfound'><span>No Records Found</span></td>
                </tr>
            }
          </tbody>
        </table>
      </div>
    </Fragment>
  )
};
export default QAReInspection;