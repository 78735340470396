import React, { useState, useEffect, useMemo, Fragment } from "react";
import ReactNotification from 'react-notifications-component';
import { TableHeader, Pagination, Search, PageCount, ReactSearch } from "../Datatable";
import SweetAlertPopup from '../Common/SweetAlertPopup';
import Reactselect from 'react-select';
import useFullPageLoader from "../../hooks/useFullPageLoader";
import Nodify from "../Common/ReactNotification"
import SearchRetain from "../Common/SearchRetain";
import { Redirect } from 'react-router-dom';
import { StyleId } from "../Common/PageActionNumber";
import StyleService from "../../services/Style/styleService";
import strikeoffService from "../../services/Style/StrikeoffService"
import TrimService from "../../services/Style/TrimService";
import CommonService from '../../services/Common/CommonService';
import { retrieveScheduleReportList } from "../../actions/schedulereport";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, ModalBody } from "react-bootstrap";
import { $, data, DataTable } from "jquery";
import ConfirmationPopup from '../Common/ConfirmationPopup';
import StyleCopyconfirmation from '../Common/StylecopyList'
import { loadCubeBuyerSupplierEmp } from "../../actions/common";
import * as XLSX from 'xlsx';
import { GetUserPermissionList } from "../../actions/UserPermission";
import UserService from "../../services/Master/UserPermissionService";
// import * as FileSaver from 'file-saver';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { DateRangePicker } from 'react-date-range';
import Input from "react-validation/build/input";

/*-------------------Slider--------------------------*/
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import styleService from "../../services/Style/styleService";
import { login } from '../../actions/auth';
// import Lightbox from "react-image-lightbox";
//import "react-lightbox-pack/dist/index.css";

const ScheduleReportDetailMail = (props) => {
    const [ShowFilter, setShowFilter] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    const [getPlaceholder] = useState("Buyer / Style Name / Style No / Season / Brand / Story / Theme  / Group Type");
    const [getBuyerPlaceholder] = useState("Buyer");
    const [sampleorprod, setsampleorprod] = useState('1');
    const [getID, setID] = useState({ showPopupDelete: false, Params: {} });
    //Start SearchFilter
    const [search, setSearch] = useState("");
    const [getBuyersearch, setBuyerSearch] = useState("");
    const [getStylesearch, setStyleSearch] = useState("");
    const [getSeasonsearch, setSeasonSearch] = useState("");
    const [getBrandsearch, setBrandSearch] = useState("");
    const [getStorysearch, setStorySearch] = useState("");
    const [getThemesearch, setThemeSearch] = useState("");
    const [getGendersearch, setGenderSearch] = useState("");
    const [getCreatedbysearch, setCreatedbySearch] = useState("");
    const [getIdPosearch, setIdPoSearch] = useState("");
    const [getimportance, setimportance] = useState("");
    const [getStyleCopyList, setStyleCopyList] = useState([]);
    const [getStyleCopyRemarkList, setStyleCopyRemarkList] = useState([]);
    const [styleId, setStyleId] = useState();
    //End SearchFilter
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [getcomment, setComment] = useState();
    const [ListTile, setListTile] = useState("List");
    // const [getSizeList, setSizeList] = useState([]);
    //const [getStyleList, setStyleList] = useState([]);
    const [getMainImageList, setMainImageList] = useState([]);
    const [getSubImageList, setSubImageList] = useState([]);
    const [getSkuID, setSkuID] = useState();
    const [getCurrimageindex, setCurrimageindex] = useState(0);
    const [getStylename, setStylename] = useState();
    const [getStyleno, setStyleno] = useState();
    // const [getcreatedby, setcreatedby] = useState();
    //filter list
    const [getBrandFilterlist, setBrandFilterlist] = useState();
    const [getSeasonFilterlist, setSeasonFilterList] = useState();
    const [getstyleFilterlist, setStyleFilterList] = useState();
    const [getBuyerFilterlist, setBuyerFilterList] = useState();
    const [getidpoFilterlist, setIdpoFilterList] = useState();
    const [getStoryFilterlist, setStoryFilterList] = useState();
    const [getThemeFilterlist, setThemeFilterList] = useState();
    const [getGenderFilterlist, setGenderFilterList] = useState();
    const [getEmployeeFilterlist, setEmployeeFilterList] = useState();
    const [getimportanceFilterlist, setimportanceFilterList] = useState();
    const [getStyleimage, setStyleimage] = useState();
    const [getPrint, setPrint] = useState([{
        componentRef: React.useRef(null),
        onBeforeGetContentResolve: React.useRef(null)
    }]);

    const [getPrintID, setPrintID] = useState(false);
    const [addStyleConfirmation, setAddStyleConfirmation] = useState({ showPopupConfirm: false, Params: {} });
    const [copyConfirmation, setCopyConfirmation] = useState({ showPopupConfirm: false, Params: {} });
    // const [getPdfDownload, setPdfDownload] = useState([]);
    //Slider
    const [getisOpenSlider, setisOpenSlider] = useState(false);
    const [getSeasonList, setSeasonList] = useState();
    var defaultStartDate = new Date();
    let DateInterval = 210;
    const [stateDate, setStateDate] = useState([
        {
            startDate: defaultStartDate.setDate(defaultStartDate.getDate() - DateInterval),
            endDate: new Date(),
            key: 'selection',
            isShow: false
        }
    ]);

    const [getSearchReqValues, setSearchReqValues] = useState({ getStylesearch: "", })
    const dispatch = useDispatch();

    const [print, setprint] = useState();

    let navstyle = {
        control: navstyle => ({ ...navstyle, width: "750px" }),
    }
    //reducer
    const reducerState = useSelector((state) => state);
    const { user: currentUser } = reducerState.auth;
    let BuyerListOptions = [] = [];
    const BuyerBrandDetails = reducerState.auth.UserMenuList.item6 !== undefined ? reducerState.auth.UserMenuList.item6 : [];
    const comments = reducerState.ScheduleReport.schedulereportList.item1 !== undefined ? reducerState.ScheduleReport.schedulereportList.item1 : reducerState.ScheduleReport.schedulereportList;
    //
    // const Commentcopy = reducerState.style.copyStyleList;
    //const commentsResult = reducerState.ScheduleReport.schedulereportList.item1;
    let isLoadingStyle = reducerState.ScheduleReport.isLoadingStyle;

    let ImageArray = [];

    // ;
    if (comments.length > 0) {
        if (!comments[0].ImageArray) {

            const skuImg = reducerState.ScheduleReport.schedulereportList.item2;
            for (let index = 0; index < comments.length; index++) {
                ImageArray = skuImg.filter(d => d.styleID === comments[index].styleID);
                let imgsa = ImageArray.filter(d => d.skuID !== 0 || d.skuID !== null)

                let count = 0;
                let Total = 0;
                let Index = 0;
                for (let Imgval = 0; Imgval < imgsa.length; Imgval++) {

                    if (imgsa[Imgval].front === 1) {
                        Total = imgsa.filter(d => d.skuID === imgsa[Imgval].skuID).length;
                        count = 1;
                        Index = count;
                        ImageArray[Imgval].IndexTotal = '[' + Index + ' - ' + Total + ']';
                    }
                    else {
                        // ImageArray[Imgval].Index = count
                        Index = count;
                        ImageArray[Imgval].IndexTotal = Index === 0 ? '' : '[' + Index + ' - ' + Total + ']';
                    }
                    count = count + 1;
                }

                comments[index].ImageArray = ImageArray;

            }
        }
    }

    // If check production or sampling
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProduction = activeMenus.IsProduction;
    // End

    // useEffect(() => {
    //     
    //     let RouteId = 0;
    //     dispatch(retrieveScheduleReportList(RouteId,IsProduction));
    // }, [IsProduction]);


    //Enable Menu
    let activeMenu;
    // let activeMenu = { isAdd: 1, isEdit: 1, isDelete: 1, };
    const menulist = useSelector((state) => state.common.MenuList.item3);
    if (menulist !== undefined) { activeMenu = menulist.filter(i => i.menuName === 'Style'); }
    // End Enable Menu

    // const news = localStorage.setItem("a", moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ' + moment(stateDate[0].endDate).format('DD/MM/YYYY'))
    useEffect(() => {
        let Page = localStorage.getItem("Listpage")
        if (Page) {
            setListTile(Page)
        }

        localStorage.removeItem('CreatedDate');
        const storageData = JSON.parse(localStorage.getItem("Search"));
        const storageDatas = JSON.parse(localStorage.getItem("CreatedBy"));
        const Buyer = JSON.parse(localStorage.getItem("Buyer"));
        const Style = JSON.parse(localStorage.getItem("StyleName"));
        const Season = JSON.parse(localStorage.getItem("Season"));
        const Brand = JSON.parse(localStorage.getItem("Brand"));
        const Story = JSON.parse(localStorage.getItem("Story"));
        const IdPo = JSON.parse(localStorage.getItem("IdPo"));
        const Theme = JSON.parse(localStorage.getItem("Theme"));
        const Gender = JSON.parse(localStorage.getItem("Gender"));
        const CreatedBy = JSON.parse(localStorage.getItem("CreatedBy"));
        const CreatedDate = JSON.parse(localStorage.getItem('CreatedDate'));
        const PoStartdate = localStorage.getItem("StylePodateStatedate")
        const POenddate = localStorage.getItem("StylePodateenddate")
        stateDate[0].startDate = PoStartdate === null ? new Date(stateDate[0].startDate) : new Date(PoStartdate);
        stateDate[0].endDate = POenddate === null ? new Date() : new Date(POenddate);
        setStateDate(stateDate);
        if (props.location.state && props.location.state.params.localStorage) {
            if (storageData) { // if storage is not null it will executed

                if (storageData.Page === "" && storageData.Searchvalue === "") {
                    localStorage.removeItem('Search');
                }

                if (Buyer !== null && Buyer.Page === 'Buyer') {
                    if (Buyer.Page === 'Buyer' && Buyer.Searchvalue !== '') {
                        setBuyerSearch(Buyer.Searchvalue);
                    }
                    else {
                        localStorage.removeItem('Buyer');
                        setBuyerSearch("");
                    }
                }
                if (Style !== null && Style.Page === 'StyleName') {
                    if (Style.Page === 'StyleName' && Style.Searchvalue !== '') {
                        setStyleSearch(Style.Searchvalue);
                    } else {
                        localStorage.removeItem('StyleName');
                        setStyleSearch("");
                    }
                }
                if (Season !== null && Season.Page === 'Season') {
                    if (Season.Page === 'Season' && Season.Searchvalue !== '') {
                        setSeasonSearch(Season.Searchvalue);
                    } else {
                        localStorage.removeItem('Season');
                        setSeasonSearch('');
                    }
                }
                if (Brand !== null && Brand.Page === 'Brand') {
                    if (Brand.Page === 'Brand' && Brand.Searchvalue !== '') {
                        setBrandSearch(Brand.Searchvalue);
                    } else {
                        localStorage.removeItem('Brand');
                        setBrandSearch('');
                    }

                }
                if (Story !== null && Story.Page === 'Story') {
                    if (Story.Page === 'Story' && Story.Searchvalue !== '') {
                        setStorySearch(Story.Searchvalue);
                    } else {
                        localStorage.removeItem('Story');
                        setStorySearch('');
                    }
                }
                if (IdPo !== null && IdPo.Page === 'IdPo') {
                    if (IdPo.Page === 'IdPo' && IdPo.Searchvalue !== '') {
                        setIdPoSearch(IdPo.Searchvalue);
                    } else {
                        localStorage.removeItem('IdPo');
                        setIdPoSearch('');
                    }
                }
                if (Theme !== null && Theme.Page === 'Theme') {
                    if (Theme.Page === 'Theme' && Theme.Searchvalue !== '') {
                        setThemeSearch(Theme.Searchvalue);
                    } else {
                        localStorage.removeItem('Theme');
                        setThemeSearch('');
                    }

                }
                if (Gender !== null && Gender.Page === 'Gender') {
                    if (Gender.Page === 'Gender' && Gender.Searchvalue !== '') {
                        setGenderSearch(Gender.Searchvalue);
                    }
                    else {
                        localStorage.removeItem('Gender');
                        setGenderSearch('');
                    }
                }
                if (CreatedBy !== null && CreatedBy.Page === 'CreatedBy') {
                    if (CreatedBy.Page === 'CreatedBy' && CreatedBy.Searchvalue !== '') {
                        setCreatedbySearch(CreatedBy.Searchvalue);
                    } else {
                        localStorage.removeItem('CreatedBy');
                        setCreatedbySearch('');
                    }
                }

                if (CreatedDate) {
                    if (CreatedDate.EndDate && CreatedDate.EndDate !== null && CreatedDate.Startdate && CreatedDate.Startdate !== null) {
                        //setCreatedbySearch(CreatedDate.Startdate + "To" + CreatedDate.EndDate);
                        let date = [...stateDate]
                        date[0].startDate = CreatedDate.Startdate;
                        date[0].endDate = CreatedDate.EndDate;
                        setStateDate(date);
                    }
                    else {
                        localStorage.removeItem('CreatedDate');
                        // setCreatedbySearch('');
                    }
                }
            }
            // storage is null & not null means also its executed for store filter value in local storage.
            if (Theme !== null && Theme.Page === 'Theme') {
                if (Theme.Page === 'Theme' && Theme.Searchvalue !== '') {
                    setThemeSearch(Theme.Searchvalue);
                } else {
                    localStorage.removeItem('Theme');
                    setThemeSearch('');
                }

            }
            if (Gender !== null && Gender.Page === 'Gender') {
                if (Gender.Page === 'Gender' && Gender.Searchvalue !== '') {
                    setGenderSearch(Gender.Searchvalue);
                }
                else {
                    localStorage.removeItem('Gender');
                    setGenderSearch('');
                }
            }
            if (IdPo !== null && IdPo.Page === 'IdPo') {
                if (IdPo.Page === 'IdPo' && IdPo.Searchvalue !== '') {
                    setIdPoSearch(IdPo.Searchvalue);
                } else {
                    localStorage.removeItem('IdPo');
                    setIdPoSearch('');
                }
            }
            if (Story !== null && Story.Page === 'Story') {
                if (Story.Page === 'Story' && Story.Searchvalue !== '') {
                    setStorySearch(Story.Searchvalue);
                } else {
                    localStorage.removeItem('Story');
                    setStorySearch('');
                }
            }
            if (Season !== null && Season.Page === 'Season') {
                if (Season.Page === 'Season' && Season.Searchvalue !== '') {
                    setSeasonSearch(Season.Searchvalue);
                } else {
                    localStorage.removeItem('Season');
                    setSeasonSearch('');
                }
            }
            if (Buyer !== null && Buyer.Page === 'Buyer') {
                if (Buyer.Page === 'Buyer' && Buyer.Searchvalue !== '') {
                    setBuyerSearch(Buyer.Searchvalue);
                }
                else {
                    localStorage.removeItem('Buyer');
                    setBuyerSearch("");
                }
            }
            if (Style !== null && Style.Page === 'StyleName') {
                if (Style.Page === 'StyleName' && Style.Searchvalue !== '') {
                    setStyleSearch(Style.Searchvalue);
                } else {
                    localStorage.removeItem('StyleName');
                    setStyleSearch("");
                }
            }
            if (Brand !== null && Brand.Page === 'Brand') {
                if (Brand.Page === 'Brand' && Brand.Searchvalue !== '') {
                    setBrandSearch(Brand.Searchvalue);
                } else {
                    localStorage.removeItem('Brand');
                    setBrandSearch('');
                }

            }
            if (storageDatas) {
                if (CreatedBy !== null && CreatedBy.Page === 'CreatedBy') {
                    if (CreatedBy.Page === 'CreatedBy' && CreatedBy.Searchvalue !== '') {
                        setCreatedbySearch(CreatedBy.Searchvalue);
                    } else {
                        localStorage.removeItem('CreatedBy');
                        setCreatedbySearch('');
                    }
                }
            }
            else if (CreatedDate) {
                if (CreatedDate.EndDate && CreatedDate.EndDate !== null && CreatedDate.Startdate && CreatedDate.Startdate !== null) {
                    //setCreatedbySearch(CreatedDate.Startdate + "To" + CreatedDate.EndDate);
                    let date = [...stateDate]
                    date[0].startDate = CreatedDate.Startdate;
                    date[0].endDate = CreatedDate.EndDate;
                    setStateDate(date);
                }
                else {
                    localStorage.removeItem('CreatedDate');
                    //setCreatedbySearch('');
                }
            }
        }
        else {
            localStorage.removeItem('Search');
            localStorage.removeItem('Buyer');
            localStorage.removeItem('StyleName');
            localStorage.removeItem('Season');
            localStorage.removeItem('Brand');
            localStorage.removeItem('Story');
            localStorage.removeItem('IdPo');
            localStorage.removeItem('Theme');
            localStorage.removeItem('Gender');
            localStorage.removeItem('CreatedBy');
            localStorage.removeItem('CreatedDate');
            setBuyerSearch("");
            setStyleSearch("");
            setSeasonSearch('');
            setBrandSearch('');
            setStorySearch('');
            setIdPoSearch('');
            setThemeSearch('');
            setGenderSearch('');
            setCreatedbySearch('');
        }
    }, []);
    useEffect(() => {

        styleService.GetStyleCreatedByList(0).then((response) => {
            if (response.data) {
                setEmployeeFilterList(response.data);
            }
        });
        // styleService.GetStyleFilterList(0).then((response) => {
        //     if (response.data) {
        //         BuyerListOptions = response.data.buyerList;
        //         if (BuyerListOptions.length > 0 && BuyerBrandDetails.length > 0) {
        //             setBuyerFilterList(BuyerListOptions.filter(o => BuyerBrandDetails.some(({ buyerId }) => o.value === buyerId)));
        //         }
        //     }
        // });


        // StyleService.LoadBuyer(2)
        //     .then((response) => {
        //         if (response.data) {
        //             BuyerListOptions = response.data;
        //             if (BuyerListOptions.length > 0 && BuyerBrandDetails.length > 0) {
        //                 setBuyerFilterList(BuyerListOptions.filter(o => BuyerBrandDetails.some(({ buyerId }) => o.id === buyerId)));
        //             }
        //         }
        //     })
        //     .catch(() => { });
    }, [])

    useEffect(() => {
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }

        let params = { RouteId: 0, IsProduction: 0, employeeinformationID: currentUser.employeeinformationID, PoStartDate: StartDate, PoEndDate: EndDate }
        // if (IsProduction !== 0) {
        //     dispatch(retrieveScheduleReportList(params));
        // }
        dispatch(retrieveScheduleReportList(params));
        if (IsProduction && props.location.state === null) {
            localStorage.removeItem('Search');
            localStorage.removeItem('StyleName');
            localStorage.removeItem('Season');
            localStorage.removeItem('Brand');
            localStorage.removeItem('Story');
            localStorage.removeItem('IdPo');
            localStorage.removeItem('Theme');
            localStorage.removeItem('Gender');
            localStorage.removeItem('CreatedBy');
            setStyleSearch('');
            setSeasonSearch('');
            setBrandSearch('');
            setStorySearch('');
            setBuyerSearch('')
            setIdPoSearch('');
            setThemeSearch('');
            setGenderSearch('');
            setCreatedbySearch('');
            var defaultStartDate = new Date();
            const emptyStateDate = [
                {
                    startDate: defaultStartDate.setDate(defaultStartDate.getDate() - DateInterval),
                    endDate: new Date(),
                    key: 'selection',
                    isShow: false
                }];
            setStateDate(emptyStateDate);
        }


        // // const state1 = props.location.state;

        // // if (state1 !== undefined && state1 !== null) {
        // //     ;
        // //     let Msg;
        // //     let Type = 'success'; // default, info, warning
        // //     let title = 'Success!';
        // //     if (state1.message === 'Edit') {
        // //         Msg = 'Style details updated successfully.';
        // //     } else if (state1.message === 'Add') {
        // //         Msg = 'Style details added successfully.';
        // //     }
        // //     else if (state1.params.localStorage === true) {
        // //         return
        // //     }
        // //     else {
        // //         Msg = 'Error occured';
        // //         Type = 'warning';
        // //         title = 'Error!';
        // //     }
        // //     window.history.replaceState(null, '')
        // //     Nodify(title, Type, Msg);
        // // }

        var page = getPlaceholder;
        var setConstraint = SearchRetain(page);
        if (setConstraint !== undefined) {

            setCurrentPage(setConstraint.CurrentPage);
            setITEMS_PER_PAGE(setConstraint.PageItems);
        }


        var buyerlist = [];

        comments.map(x => {
            buyerlist.push({
                label: x.buyerName,
                value: x.buyerID
            })
        })
        // setBuyerFilterList([...new Map(buyerlist.map(item =>
        //     [item['label'], item])).values()]);




    }, [dispatch, IsProduction]);


    useEffect(() => {
        if (!isLoadingStyle) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingStyle])

    const headers = [
        {
            name: "#",
            field: "",
            sortable: false

        },
        {
            name: "Picture",
            field: "styleLogoPath",
            sortable: false,
            widthsize: "6%"
        }, {
            name: "Style Name",
            field: "styleName",
            sortable: true,
            widthsize: "10%"
        }, {
            name: "Style No",
            field: "styleNo",
            sortable: true,
            widthsize: "10%"
        }, {
            name: "Season",
            field: "seasonName",
            sortable: true,
            widthsize: "9%"
        },
        {
            name: "Brand",
            field: "brandName",
            sortable: true,
            widthsize: "8%"
        },
        {
            name: "ID/PO No",
            field: "idPoNo",
            sortable: true,
            widthsize: "8%"
        },
        {
            name: "Story",
            field: "stroryName",
            sortable: true,
            widthsize: "7%"
        }, {
            name: "Theme",
            field: "themeName",
            sortable: true,
            widthsize: "7%"
        },
        // {
        //     name: "CreatedDate",
        //     field: "createddate",
        //     sortable: true,
        //     widthsize: "9%"
        // },
        {
            name: "Gender",
            field: "genderSizeName",
            sortable: true,
            widthsize: "7%"
        },

        {
            name: "Created Date",
            field: "createddate",
            sortable: true,
            widthsize: "8%"
        },
        {
            name: "Created By",
            field: "createdByStr",
            sortable: true,
            widthsize: "8%",
            //display: activeMenu !== undefined && activeMenu[0].isEdit === 0 && activeMenu !== undefined && activeMenu[0].isDelete === 0 ? 'none' : ''

        },
        // {
        //     name: "Action",
        //     field: "",
        //     sortable: false,
        //     widthsize: "20%",
        //     display: activeMenu !== undefined && activeMenu[0].isEdit === 0 && activeMenu !== undefined && activeMenu[0].isDelete === 0 ? 'none' : ''

        // },

    ];
    const headerss = [
        {
            name: "#",
            field: "",
            sortable: false

        },
        {
            name: "Style Name",
            field: "styleName",
            sortable: true,
            widthsize: "14%"
        }, {
            name: "Style No",
            field: "styleNo",
            sortable: true,
            widthsize: "11%"
        }, {
            name: "Season",
            field: "seasonName",
            sortable: true,
            widthsize: "9%"
        },
        {
            name: "Brand",
            field: "brandName",
            sortable: true,
            widthsize: "8%"
        },
        {
            name: "ID/PO No",
            field: "idPoNo",
            sortable: true,
            widthsize: "8%"
        },
        {
            name: "Story",
            field: "stroryName",
            sortable: true,
            widthsize: "8%"
        }, {
            name: "Theme",
            field: "themeName",
            sortable: true,
            widthsize: "8%"
        },
        // {
        //     name: "CreatedDate",
        //     field: "createddate",
        //     sortable: true,
        //     widthsize: "9%"
        // },
        {
            name: "Gender",
            field: "genderSizeName",
            sortable: true,
            widthsize: "8%"
        },
        {
            name: "Created Date",
            field: "createddate",
            sortable: true,
            widthsize: "8%"
        },
        {
            name: "Created By",
            field: "createdByStr",
            sortable: true,
            widthsize: "9%"
        },

    ];
    const columns = [
        {

            title: "Style Name",
            field: "styleName",

        }, {
            title: "Style No",
            field: "styleNo",

        }, {
            title: "Season",
            field: "seasonName",

        },
        {
            title: "Brand",
            field: "brandName",

        },
        {
            title: "ID/PO No",
            field: "idPoNo",
        },
        {
            title: "Story",
            field: "stroryName",

        }, {
            title: "Theme",
            field: "themeName",

        }, {
            title: "Gender",
            field: "genderSizeName",

        },
        {
            title: "Created Date",
            field: "createddate",

        },
        {
            title: "Created By",
            field: "createdByStr",

        },



    ]
    window.addEventListener('beforeunload', function () {
        // Clear localStorage
        localStorage.removeItem("Importance")
        localStorage.removeItem("CreatedBy")
        localStorage.removeItem("Gender")
        localStorage.removeItem("Theme")
        localStorage.removeItem("Story")
        localStorage.removeItem("IdPo")
        localStorage.removeItem("Brand")
        localStorage.removeItem("Season")
        localStorage.removeItem("StyleName")
        localStorage.removeItem("Buyer")
        localStorage.removeItem("StylePodateStatedate");
        localStorage.removeItem("StylePodateenddate");
    });
    //-----------------Print---------------



    const handleOnBeforeGetContent = React.useCallback(() => {
        ;
        showLoader();
        return new Promise((resolve) => {
            getPrint[0].onBeforeGetContentResolve.current = resolve;
            setTimeout(() => {
                setPrintID(false);
                hideLoader();
                resolve();
            }, 2000);
        });
    }, [showLoader]);

    const reactToPrintContent = React.useCallback(() => {
        setPrintID(true);

        return getPrint[0].componentRef.current;

    }, [getPrint[0].componentRef.current,]);

    React.useEffect(() => {
        if (
            typeof getPrint[0].onBeforeGetContentResolve.current === "function"
        ) {
            getPrint[0].onBeforeGetContentResolve.current();
        }

    }, [getPrint[0].onBeforeGetContentResolve.current]);

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: "StyleList",
        onBeforeGetContent: handleOnBeforeGetContent,
        removeAfterPrint: true
    });
    // -----------------------------------------------------------



    //Excel
    let ExcelValues = []
    const DownloadExcel = () => {

        commentsData.map(comment => {
            ExcelValues.push({
                StyleName: comment.styleName,
                StyleNo: comment.styleNo,
                SeasonName: comment.seasonName,
                BrandName: comment.brandName,
                IDPONo: comment.idPoNo,
                StoryName: comment.stroryName,
                ThemeName: comment.themeName,
                GenderSizeName: comment.genderSizeName,
                CreatedDate: comment.createddate,
                CreatedBy: comment.createdByStr
            })
        })
        const newdata = ExcelValues.map(comment => {
            delete comment.tableData
            return comment
        })
        const worksheet = XLSX.utils.json_to_sheet(newdata)
        const Workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(Workbook, worksheet, 'StyleList')
        //Buffer
        XLSX.write(Workbook, { bookType: 'xlsx', type: 'buffer' })
        //Binary strnig
        XLSX.write(Workbook, { bookType: 'xlsx', type: 'binary' })
        //download
        XLSX.writeFile(Workbook, 'StyleList.xlsx')
    }
    //PDF
    let PdfValues = [];

    const DownloadPDF = () => {
        commentsData.map(comment => {
            PdfValues.push({
                styleName: comment.styleName,
                styleNo: comment.styleNo,
                seasonName: comment.seasonName,
                brandName: comment.brandName,
                idPoNo: comment.idPoNo,
                stroryName: comment.stroryName,
                themeName: comment.themeName,
                genderSizeName: comment.genderSizeName,
                createddate: comment.createddate,
                createdByStr: comment.createdByStr
            })

        })
        const doc = new jsPDF()
        doc.text("STYLELIST", 20, 10)
        doc.autoTable({
            theme: "grid",
            columns: columns.map(col => ({ ...col, dataKey: col.field })),
            body: PdfValues
        })
        doc.save('Stylelist.pdf')
    }
    const LoadDropdownsBasedOnFilter = (data) => {

        var buyerlist = []; var stylelist = []; var seasonlist = []; var idpolist = []; var storylist = [];
        var themelist = []; var genderlist = []; var brandlist = []; var employeelist = [];
        var importancelist = [];
        data.map(x => {
            buyerlist.push({
                label: x.buyerName,
                value: x.buyerID
            })
            seasonlist.push({
                label: x.seasonName === "" ? "-" : x.seasonName,
                value: x.seasonID
            })
            stylelist.push({
                label: x.styleName === "" ? "-" : x.styleName,
                value: x.styleID
            })
            idpolist.push({
                label: x.idPoNo === "" ? "-" : x.idPoNo,
                value: x.idPoNo
            })
            storylist.push({
                label: x.stroryName === "" ? "-" : x.stroryName,
                value: x.storyID
            })
            themelist.push({
                label: x.themeName === "" ? "-" : x.themeName,
                value: x.themeID
            })
            genderlist.push({
                label: x.genderSizeName === "" ? "-" : x.genderSizeName,
                value: x.genderID
            })
            brandlist.push({
                label: x.brandName === "" ? "-" : x.brandName,
                value: x.brandID
            })
            employeelist.push({
                label: x.createdByStr === "" ? "-" : x.createdByStr,
                value: x.createdBy
            })

            importancelist.push({
                label: x.importance === "" ? "-" : x.importance === "1" ? "Priority" : x.importance === "2" ? "Critical" : x.importance === "1,2" ? "Priority , Critical" : x.importance === "2,1" ? "Critical , Priority" : "",
                value: x.importance
            })
        })
        // setBuyerFilterList([...new Map(buyerlist.map(item =>
        //     [item['label'], item])).values()]);

        setimportanceFilterList([...new Map(importancelist.map(item =>
            [item['label'], item])).values()])


        setSeasonFilterList([...new Map(seasonlist.map(item =>
            [item['label'], item])).values()])

        setStyleFilterList([...new Map(stylelist.map(item =>
            [item['label'], item])).values()])

        setIdpoFilterList([...new Map(idpolist.map(item =>
            [item['label'], item])).values()])

        setStoryFilterList([...new Map(storylist.map(item =>
            [item['label'], item])).values()])

        setThemeFilterList([...new Map(themelist.map(item =>
            [item['label'], item])).values()])

        setGenderFilterList([...new Map(genderlist.map(item =>
            [item['label'], item])).values()])

        setBrandFilterlist([...new Map(brandlist.map(item =>
            [item['label'], item])).values()])

        setEmployeeFilterList([...new Map(employeelist.map(item =>
            [item['label'], item])).values()])


    }

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/ScheduleReportList');
        } else {
            window.location.reload();

        }
    }

    const commentsData = useMemo(() => {
        let computedComments = comments.filter(o => BuyerBrandDetails.some(({ brandId, buyerId, isAllInfo, isMapped }) =>
            o.brandID === brandId && o.buyerID === buyerId && isAllInfo === 1 && isMapped === 0));
        let computedCommentsBuyerDetails = comments.filter(o => BuyerBrandDetails.some(({ brandId, buyerId, isAllInfo, isMapped }) =>
            o.brandID === brandId && isAllInfo === 0 && isMapped === 1 && o.cubeEmployeeIdList != undefined
            && o.cubeEmployeeIdList.split(',').includes(currentUser.employeeinformationID.toString())));
        if (computedCommentsBuyerDetails.length > 0) {
            computedComments = [...computedComments, ...computedCommentsBuyerDetails];
        }

        if (getBuyersearch) {
            computedComments = computedComments.filter(comment =>
                comment.buyerName.toLowerCase().includes(getBuyersearch.label.toLowerCase())
            )

            setTotalItems(computedComments.length);
        }

        if (getStylesearch) {
            computedComments = computedComments.filter(comment =>
                comment.styleName.toLowerCase().includes(getStylesearch.label.toLowerCase())
            )

        }

        if (getSeasonsearch) {
            computedComments = computedComments.filter(comment =>
                comment.seasonName.toLowerCase().includes(getSeasonsearch.label.toLowerCase())
            );
        }


        if (getBrandsearch) {
            computedComments = computedComments.filter(comment =>
                comment.brandName.toLowerCase().includes(getBrandsearch.label.toLowerCase())
            );
        }
        if (getStorysearch) {
            computedComments = computedComments.filter(comment =>
                comment.stroryName.toLowerCase().includes(getStorysearch.label.toLowerCase())
            );
        }
        if (getThemesearch) {
            computedComments = computedComments.filter(comment =>
                comment.themeName.toLowerCase().includes(getThemesearch.label.toLowerCase())
            );
        }

        if (getGendersearch) {
            computedComments = computedComments.filter(comment =>
                comment.genderSizeName.toLowerCase().includes(getGendersearch.label.toLowerCase())
            );
        }
        if (getCreatedbysearch) {
            ;
            computedComments = computedComments.filter(comment =>
                comment.createdByStr.toLowerCase().includes(getCreatedbysearch.label.toLowerCase())
            );
        }
        if (getimportance) {
            // computedComments = computedComments.filter(comment =>
            //     comment.importance.toLowerCase().includes(getimportance.label.toLowerCase())
            // );
            computedComments = computedComments.filter(comment => comment.importance === getimportance.value)
        }
        if (getIdPosearch) {
            computedComments = computedComments.filter(comment =>
                comment.idPoNo.toLowerCase().includes(getIdPosearch.label.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        setTotalItems(computedComments.length);

        let format = "DD/MM/YYYY";
        let StartDate = null;
        let EndDate = null;
        // if (moment(stateDate[0].startDate).isValid()) {
        //     StartDate = new Date(moment(stateDate[0].startDate, "DD/MM/YYYY").format("DD/MM/YYYY"));
        //     //StartDate.setMinutes(StartDate.getMinutes() + 370);
        // }
        // if (moment(stateDate[0].endDate).isValid()) {
        //     EndDate = new Date(moment(stateDate[0].endDate, "DD/MM/YYYY").format("DD/MM/YYYY"));
        //     //EndDate.setMinutes(EndDate.getMinutes() + 370);
        // }

        // if (stateDate[0].startDate && stateDate[0].endDate) {
        //     computedComments = computedComments.filter(comment =>
        //         new Date(moment(comment.createddate, format)) >= stateDate[0].startDate && new Date(moment(comment.createddate, format)) <= stateDate[0].endDate
        //     )
        // }
        setTotalItems(computedComments.length);


        // Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort((a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]));
        }

        // Current Page slice
        let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
        if (records.length === 0 && currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
        LoadDropdownsBasedOnFilter(computedComments);
        // Current Page slice
        return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
    },
        [
            comments,
            currentPage,
            search,
            getBuyersearch,
            getStylesearch,
            getSeasonsearch,
            getBrandsearch,
            getStorysearch,
            getThemesearch,
            getGendersearch,
            getCreatedbysearch,
            getIdPosearch,
            getimportance,
            stateDate,
            sorting,
            ITEMS_PER_PAGE,
        ]);

    const resetStyle = () => {
        
        localStorage.removeItem('Search');
        localStorage.removeItem('Buyer');
        localStorage.removeItem('StyleName');
        localStorage.removeItem('Season');
        localStorage.removeItem('Brand');
        localStorage.removeItem('Story');
        localStorage.removeItem('IdPo');
        localStorage.removeItem('Theme');
        localStorage.removeItem('Gender');
        localStorage.removeItem('CreatedBy');
        localStorage.removeItem('Importance')
        localStorage.removeItem("StylePodateStatedate");
        localStorage.removeItem("StylePodateenddate");
        setBuyerSearch('');
        setStyleSearch('');
        setSeasonSearch('');
        setBrandSearch('');
        setStorySearch('');
        setIdPoSearch('');
        setThemeSearch('');
        setGenderSearch('');
        setCreatedbySearch('');
        setimportance("");
        var defaultStartDate = new Date();
        const emptyStateDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - DateInterval),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];
        setStateDate(emptyStateDate);
        let StartDate = null;
        let EndDate = null;
        if (moment(emptyStateDate[0].startDate).isValid()) {
            StartDate = moment(emptyStateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(emptyStateDate[0].endDate).isValid()) {
            EndDate = moment(emptyStateDate[0].endDate).format('MM/DD/YYYY');
        }

        let params = { RouteId: 0, IsProduction: 0, employeeinformationID: currentUser.employeeinformationID, PoStartDate: StartDate, PoEndDate: EndDate }
        // if (IsProduction !== 0) {
        //     dispatch(retrieveScheduleReportList(params));
        // }
        dispatch(retrieveScheduleReportList(params));
        var Data = { Searchvalue: '', Page: '' }
        localStorage.setItem('Search', JSON.stringify(Data));
    }

    useEffect(() => {
        if (commentsData.length > 0) {
            StyleService.LoadBuyer(2)
                .then((response) => {
                    if (response.data) {
                        BuyerListOptions = response.data;
                        var BuyerBrandInfo = BuyerListOptions.filter(o => BuyerBrandDetails.some(({ buyerId }) => o.id === buyerId));
                        if (BuyerListOptions.length > 0 && BuyerBrandDetails.length > 0) {
                            setBuyerFilterList(BuyerBrandInfo.filter(o => commentsData.some(({ buyerID }) => o.id === buyerID)));
                        }
                    }
                })
                .catch(() => { });
        }
    }, [commentsData])


    const CopyStyleList = (comment, styleID, Stylename, Styleno, copy) => {
        setComment('');
        setStyleId('')
        setStyleId(styleID);
        setComment(comment);
        let params = { tabPage: "Copystyle", StyleName: Stylename, StyleNo: Styleno, StyleID: styleID };
        setCopyConfirmation({ showPopupConfirm: true, Params: params });
    }

    const pageRedirect = (Params, redirect, page) => {
        
        localStorage.setItem('Pagination', JSON.stringify({ CurrentPage: currentPage, Page: page, PageItems: ITEMS_PER_PAGE }));
        if (redirect === 'Add') {
            ;
            dispatch(loadCubeBuyerSupplierEmp(1));
            dispatch(loadCubeBuyerSupplierEmp(3));
            let params = { tabPage: "AddStyle" };
            setAddStyleConfirmation({ showPopupConfirm: true, Params: params });

            // props.history.push('/StyleListRegister');
        }
        else if (redirect === 'Edit') {

            // dispatch(loadCubeBuyerSupplierEmp(1));
            // dispatch(loadCubeBuyerSupplierEmp(3));
            let params = { isQuickStyle: false, LastStyle: {}, }
            //dispatch(updateQuickStyleCreate(params));
            props.history.push(`/StyleListRegister`, { params: Params });
            let CreDate = {
                Startdate: stateDate[0].startDate,
                EndDate: stateDate[0].endDate
            }
            let CreatedDate = JSON.stringify(CreDate)
            //let Newocnvert = JSON.parse(CreatedDate)
            localStorage.setItem('CreatedDate', CreatedDate)
        }
        else {
            let Param = {
                Operation: StyleId,
                Id: Params.styleID,
            };

            CommonService.CheckMappedItem(Param).then(res => {
                if (res.data.outputResult === "-2") {
                    let Title = "Warning!";
                    let message = "This Style is already mapped.";
                    let type = "warning";

                    Nodify(Title, type, message);
                }
                else {
                    dispatch(login(currentUser.empUserName, currentUser.empPassword, 0)).then((res) => {

                    });
                    setID({ showPopupDelete: true, Params: Params });

                }
            });
        }

        // else {
        //     setID({ showPopupDelete: true, Params: Params });
        // }
    };

    function SetStyle(style, measurelst) {
        let StyleValue = {};

        if (style !== undefined) {
            if (style.length !== 0) {
                StyleValue.Operation = 1
                StyleValue.BuyerID = style[0].buyerID
                StyleValue.SeasonID = style[0].seasonID
                StyleValue.BrandID = style[0].brandID
                StyleValue.GroupTypeID = style[0].groupTypeID
                StyleValue.StyleName = ""
                StyleValue.StyleNo = ""
                StyleValue.CategoryID = style[0].categoryID
                StyleValue.GenderID = style[0].genderID
                StyleValue.SizeID = style[0].sizeID
                StyleValue.ProcessType = style[0].processType
                StyleValue.ProcessTypeID = style[0].processTypeId
                StyleValue.IDPoNo = style[0].idPoNo
                StyleValue.StyleLogoName = style[0].styleLogoName
                StyleValue.StyleLogoPath = style[0].styleLogoPath
                StyleValue.CubeEmployeeName = style[0].cubeEmployeeName
                StyleValue.Importance = style[0].importance
                StyleValue.EndCustomerID = style[0].endCustomerID
                StyleValue.StoryID = style[0].storyID
                StyleValue.ThemeID = style[0].themeID
                //StyleValue.BuyerEmployeeID =style.buyerEmployeeID
                StyleValue.BuyerEmployeeIdList = style[0].buyerEmployeeIdList
                StyleValue.CubeEmployeeIdList = style[0].cubeEmployeeIdList
                StyleValue.SupplierEmployeeIdList = style[0].supplierEmployeeIdList
                StyleValue.StyleID = 0
                //StyleValue.StyleMeasurementID = measurelst.length === 0 ? 0 : 0
                //StyleValue.MeasurementSpecReason = measurelst.length === 0 ? null : measurelst[0].measurementSpecReason
                //StyleValue.MeasurementGrandingSize = measurelst.length === 0 ? 0 : measurelst[0].measurementGrandingSize
                //StyleValue.MeasurementOperation = measurelst.length === 0 ? 0 : 1
                StyleValue.IsStoryMandatory = style[0].isStoryMandatory
                StyleValue.IsThemeMandatory = style[0].isThemeMandatory
                StyleValue.CreatedBy = currentUser.employeeinformationID;
                return StyleValue;

            }
        } else { return StyleValue; }
    }
    function productioncallback(value) {
        setsampleorprod(value)
    }
    const styleCopyConfirmation = async (value, styleId) => {
        
        showLoader();
        let getStyleCopy = [];
        let getStyleCopyfabricList = [];
        var fileuploadlist = [];
        var skudetailsinfo = [];
        var programfileuploadlist = [];
        var StyleProgramList = [];
        var StyleProgramManualInformation = [];
        var StyleProgramCirticalInformation = [];
        var StyleSampleReqInformation = [];
        let StyleStrikeOffDetails = [];
        let StyleStrikeOffRefernceDetails = [];
        let StyleStrikeOffPrintColorsInformation = [];
        let StyleStrikeManualInformation = [];
        let StyleProgramManualFileInformation = [];
        /*trim*/
        let StyleTrimInfoDetails = [];
        let StyleTrimInfoRefernceDetails = [];
        let StyleTrimInfoLogoInformation = [];
        let StyleTrimManualInformation = [];
        let StyleTrimManualFileInformation = [];
        if (value) {
            let StartDate = null;
            let EndDate = null;
            if (moment(stateDate[0].startDate).isValid()) {
                StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
            }
            if (moment(stateDate[0].endDate).isValid()) {
                EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
            }
            await styleService.GetStyleCopy(styleId, IsProduction, currentUser.employeeinformationID, StartDate, EndDate).then(async (response) => {/* For StyleId  based Copy style Funcationality*/
                if (response.data) {
                    let style = response.data.stylelist;
                    let skuDetails = response.data.styleSkulist;
                    let programstyle = response.data.styleProgramlist;
                    let programstylecric = response.data.styleProgramCric;
                    let programstylecom = response.data.styleProgramMancom;
                    let programstylefile = response.data.styleprogramfile;
                    //let measurelst = response.data.styleMeasurelist;
                    //let measurecat = response.data.styleMeasurecat;
                    //let measurepoint = response.data.styleMeasurecatpoint;
                    let sampreq = response.data.stylesamplelist;
                    let stylewshcare = response.data.stylewashcarelist;
                    let stylebarcode = response.data.styleBarcodelist;

                    getStyleCopy = SetStyle(style);

                    /*Getting SKUid and StyleId 0 for Skuinsert */
                    if (skuDetails) {
                        if (skuDetails.length !== 0) {

                            skuDetails.map(x => {
                                x.skuID = 0;
                                x.styleID = 0;

                            })

                        } else {
                            skudetailsinfo.push({
                                SKUID: 0, SKUName: '', SKUFrontPicName: "", SKUFrontPicPath: "",
                                SKUBackPicName: "", SKUBackPicPath: "", AdditionalPicName1: "", AdditionalPicPath1: "",
                                AdditionalPicName2: "", AdditionalPicPath2: "", AdditionalPicName3: "", AdditionalPicPath3: "",
                                AdditionalPicName4: "", AdditionalPicPath4: "", PickStylePic: 0, IsDeleted: 0,
                            })
                            skuDetails = skudetailsinfo

                        }
                        getStyleCopy.StyleSkuDetails = skuDetails;
                    }

                    /*styleProgram insert id with 0 for copystyle*/
                    if (programstyle) {
                        if (programstyle.length !== 0) {
                            programstyle.map(x => {
                                x.styleProgramID = 0;
                                x.styleID = 0;
                            })

                        } else {
                            StyleProgramList.push({
                                StyleProgramID: 0, SKUID: '', PantoneTypeID: 0, PantoneID: 0,
                                PantoneColor: '', RefColorName: '', BuyerRefNo: '', SwatchImageID: 0, ProcessTypeName: '', ApprovalTypeID: 0, swatchImagePath: "",
                                ApprovalTypeName: '', Remark: '', Cirtical: 0, IndexName: '', IsDeleted: 0, FabricID: 0, FabricPartID: 0, LabdibFabricInfoId: 0,
                            })
                            programstyle = StyleProgramList;
                        }
                        getStyleCopy.StyleProgramDetails = programstyle
                    }
                    /*Programcric insert id with 0 for copystyle*/
                    if (programstylecric) {
                        if (programstylecric.length !== 0) {
                            programstylecric.map(x => {
                                x.programCirticalInfoID = 0;
                                x.styleID = 0;

                            })

                        } else {
                            StyleProgramCirticalInformation.push({
                                ProgramCirticalInfoID: 0,
                                IsCritical: '',
                                TargettoClose: '',
                                CompletedOn: '',
                                RemindMe: 0,
                                IndexName: '',
                                IsDeleted: 0,
                            })

                            programstylecric = StyleProgramCirticalInformation
                        }
                        getStyleCopy.StyleProgramCirticalInformation = programstylecric
                    }
                    /*programstylecom insert id with 0 for copystyle*/
                    if (programstylecom) {
                        if (programstylecom.length !== 0) {
                            programstylecom.map(x => {
                                x.styleProgramManualId = 0;
                                x.styleID = 0;

                            })

                        } else {
                            programstylecom.map(x => {
                                StyleProgramManualInformation.push({
                                    StyleProgramManualId: x.styleprogrammanualid,
                                    ManualCloseDate: x.manualCloseDate,
                                    ManualCloseComment: x.manualCloseComment,
                                    StyleProgramIndexName: x.styleProgramIndexName,
                                    //StyleProgramIndexName: man.StyleProgramIndexName.toString(),
                                    IsDeleted: null,
                                })
                            })
                            programstylecom = StyleProgramManualInformation
                        }
                        getStyleCopy.StyleProgramManualInformation = programstylecom
                    }

                    /*programstylecom insert id with 0 for copystyle*/
                    if (programstylefile) {
                        if (programstylefile.length !== 0) {
                            programstylefile.map(x => {
                                x.styleProgramManualFilelId = 0;
                                x.styleID = 0;

                            })

                        }
                        else {
                            programstylefile.map(x => {
                                programfileuploadlist.push({
                                    StyleRemarkFileId: x.styleProgramManualFilelId,
                                    FileName: x.fileName,
                                    FilePath: x.filePath,
                                    FileType: x.fileType,
                                    StyleProgramIndexName: x.styleProgramIndexName,
                                    StyleProgramFileIndexName: x.styleProgramFileIndexName,
                                    IsDeleted: null
                                })

                            })
                            programstylefile = programfileuploadlist
                        }
                        getStyleCopy.StyleProgramManualFileInformation = programstylefile
                    }
                    /*Strikeoff*/
                    await strikeoffService.GetStyleStrikeOffTabList(styleId).then(async (response) => {
                        let StrikeOffInfo = response.data.strikeOffList;
                        let ReferenceInfo = response.data.referenceList;
                        let ColorGroupInfo = response.data.printColorList;
                        let StrikeManual = response.data.manual;
                        let StrikeManualFiles = response.data.manualFiles;
                        if (StrikeOffInfo) {
                            if (StrikeOffInfo.length > 0) {
                                StrikeOffInfo.map(x => {
                                    StyleStrikeOffDetails.push({
                                        StyleStrikeOffID: 0,
                                        StrikeOffID: x.strikeoffID,
                                        StyleStikeOffName: x.strikeOffName,
                                        StyleStrikeOffCode: x.strikeOffCode,
                                        StyleStrikeOffApprovalType: x.approvalType,
                                        StyleStrikeOffRemarks: x.remarks,
                                        IsMapforTest: x.isMapforTest,
                                        IsDeleted: 0,
                                        StrikeoffSubmissionApprovalID: x.strikeoffSubmissionApprovalID
                                    })
                                })
                            }
                            getStyleCopy.StyleStrikeOffDetails = StyleStrikeOffDetails;
                        }
                        if (ReferenceInfo) {
                            if (ReferenceInfo.length > 0) {
                                ReferenceInfo.map(x => {
                                    StyleStrikeOffRefernceDetails.push({
                                        StyleStrikeOffRefernceID: 0,
                                        StrikeOffID: x.strikeOffID,
                                        StrikeOffRefernceID: x.strikeOffReferenceID,
                                        StyleStrikeOffGarmentSize: "",
                                        IsDeleted: 0,
                                    });
                                })

                            }
                            getStyleCopy.StyleStrikeOffRefernceDetails = StyleStrikeOffRefernceDetails;
                        }
                        if (ColorGroupInfo) {
                            if (ColorGroupInfo.length > 0) {
                                ColorGroupInfo.map(x => {
                                    StyleStrikeOffPrintColorsInformation.push({
                                        StyleStrikeOffPrintColorID: 0,
                                        StrikeOffID: x.strikeOffID,
                                        StrikeOffPrintColorID: x.colorGroupID,
                                        StyleStrikeOffSku: '',
                                        IsDeleted: 0
                                    });
                                })

                            }
                            getStyleCopy.StyleStrikeOffPrintColorsInformation = StyleStrikeOffPrintColorsInformation;
                        }
                        if (StrikeManual) {
                            if (StrikeManual.length > 0) {
                                StrikeManual.map(x => {
                                    StyleStrikeManualInformation.push({
                                        StyleStrikeOffOrTrimManualId: 0,
                                        StrikeoffOrTrimInfoId: x.strikeOffId,
                                        ManualCloseDate: x.manualCloseDate,
                                        ManualCloseComment: x.manualCloseComment,
                                        StyleStrikeOffOrTrimIndexName: x.styleStrikeOffIndexName,
                                        IsDeleted: 0
                                    })
                                })

                            }
                            // else {
                            //     StyleStrikeManualInformation.push({
                            //         StyleStrikeOffOrTrimManualId: 0,
                            //         StrikeoffOrTrimInfoId: 0,
                            //         ManualCloseDate: '',
                            //         ManualCloseComment: '',
                            //         StyleStrikeOffOrTrimIndexName: "",
                            //         IsDeleted: 0

                            //     })
                            //     getStyleCopy.StylestrikeProgramManualInformation = StyleProgramManualInformation;
                            // }
                            getStyleCopy.StylestrikeProgramManualInformation = StyleStrikeManualInformation;
                        }
                        if (StrikeManualFiles) {
                            if (StrikeManualFiles.length > 0) {
                                StrikeManualFiles.map(x => {
                                    StyleProgramManualFileInformation.push({
                                        StyleStrikeOffOrTrimManualFilelId: 0,
                                        StrikeoffOrTrimInfoId: x.strikeOffId,
                                        FileType: x.fileType,
                                        FileName: x.fileName,
                                        FilePath: x.filePath,
                                        StyleStrikeOffOrTrimIndexName: x.styleStrikeOffIndexName,
                                        StyleStrikeOffOrTrimFileIndexName: x.styleStrikeOffFileIndexName,
                                        IsDeleted: 0
                                    })
                                })

                            }
                            // else {
                            //     StyleProgramManualFileInformation.push({
                            //         StyleStrikeOffOrTrimManualFilelId: 0,
                            //         StrikeoffOrTrimInfoId: 0,
                            //         FileType: '',
                            //         FileName: '',
                            //         FilePath: '',
                            //         StyleStrikeOffOrTrimIndexName: '',
                            //         StyleStrikeOffOrTrimFileIndexName: 0,
                            //         IsDeleted: 0
                            //     })
                            //     getStyleCopy.StyleStrikeProgramManualFileInformation = StyleProgramManualFileInformation;
                            // }
                            getStyleCopy.StyleStrikeProgramManualFileInformation = StyleProgramManualFileInformation;
                        }
                    })
                    /*Trim*/
                    await TrimService.GetStyleTrimInfoTabList(styleId).then(async (response) => {
                        let trimList = response.data.trimList;
                        let ReferenceInfo = response.data.referenceList;
                        let logoInfoList = response.data.logoInfoList;
                        let TrimManual = response.data.manual;
                        let TrimManualFiles = response.data.manualFiles;
                        if (trimList) {
                            if (trimList.length > 0) {
                                trimList.map(x => {
                                    StyleTrimInfoDetails.push({
                                        StyleTrimInfoID: 0,
                                        TrimInfoID: x.trimInfoID,
                                        StyleTrimInfoName: x.trimName,
                                        StyleTrimInfoCode: x.trimCode,
                                        StyleTrimInfoApprovalType: x.approvalType,
                                        StyleTrimInfofRemarks: x.remarks,
                                        IsMapforTest: x.isMapforTest,
                                        IsDeleted: 0,
                                        TrimSubmissionApprovalID: x.TrimSubmissionApprovalID
                                    })
                                })
                            }
                            getStyleCopy.StyleTrimDetails = StyleTrimInfoDetails;
                        }
                        if (ReferenceInfo) {
                            if (ReferenceInfo.length > 0) {
                                ReferenceInfo.map(x => {
                                    StyleTrimInfoRefernceDetails.push({
                                        StyleTrimReferenceID: 0,
                                        TrimInfoID: x.trimInfoID,
                                        TrimReferenceID: x.trimReferenceID,
                                        IsTrimStyleChecked: x.isTrimStyleChecked,
                                        IsDeleted: 0,
                                    });
                                })

                            }
                            getStyleCopy.StyleTrimRefernceDetails = StyleTrimInfoRefernceDetails;
                        }
                        if (logoInfoList) {
                            if (logoInfoList.length > 0) {
                                logoInfoList.map(x => {
                                    StyleTrimInfoLogoInformation.push({
                                        StyleTrimLogoInfoID: 0,
                                        TrimInfoID: x.trimInfoID,
                                        TrimInfoLogoInfoID: x.trimLogoInfoID,
                                        StyleTrimSku: "",
                                        IsDeleted: 0
                                    });
                                })
                            }
                            getStyleCopy.StyleTrimLogoInformation = StyleTrimInfoLogoInformation;
                        }
                        if (TrimManual) {
                            if (TrimManual.length > 0) {
                                TrimManual.map(x => {
                                    StyleTrimManualInformation.push({
                                        StyleStrikeOffOrTrimManualId: 0,
                                        StrikeoffOrTrimInfoId: x.trimInfoId,
                                        ManualCloseDate: x.manualCloseDate,
                                        ManualCloseComment: x.manualCloseComment,
                                        StyleStrikeOffOrTrimIndexName: x.styleTrimIndexName,
                                        IsDeleted: 0
                                    })
                                })

                            }
                            getStyleCopy.StyleTrimManualInformation = StyleTrimManualInformation;
                        }
                        if (TrimManualFiles) {
                            if (TrimManualFiles.length > 0) {
                                TrimManualFiles.map(x => {
                                    StyleTrimManualFileInformation.push({
                                        StyleStrikeOffOrTrimManualFilelId: 0,
                                        StrikeoffOrTrimInfoId: x.trimInfoId,
                                        FileType: x.fileType,
                                        FileName: x.fileName,
                                        FilePath: x.filePath,
                                        StyleStrikeOffOrTrimIndexName: x.styleTrimIndexName,
                                        StyleStrikeOffOrTrimFileIndexName: x.styleTrimFileIndexName,
                                        IsDeleted: 0
                                    })
                                })

                            }
                            getStyleCopy.StyleTrimManualFileInformation = StyleTrimManualFileInformation;
                        }
                    })
                    /*Measurement*/
                    // if (measurecat) {
                    //     if (measurecat.length > 0) {
                    //         measurecat.map(x => {
                    //             x.styleMeasurementCategoryID = 0;
                    //             x.styleMeasurementID = 0;
                    //             x.styleID = 0;

                    //         })
                    //     }
                    //     getStyleCopy.MeasurementCategory = measurecat;
                    // }
                    // if (measurepoint) {
                    //     if (measurepoint.length > 0) {
                    //         measurepoint.map(x => {
                    //             x.styleMeasurementCategoryPointsID = 0;
                    //             x.styleMeasurementID = 0;
                    //             x.styleID = 0;
                    //         })
                    //     }
                    //     getStyleCopy.MeasurementPoints = measurepoint;
                    // }
                    /*samplerequest*/
                    // if (sampreq) {
                    //     if (sampreq.length > 0) {
                    //         sampreq.map(x => {
                    //             x.styleSampleRequestID = 0;
                    //             x.styleID = 0
                    //         })
                    //     }
                    //     else {
                    //         StyleSampleReqInformation.push({
                    //             StyleSampleRequestID: 0, SampleRequestID: 0, SkuColorNameIds: '', QtyIds: ''
                    //         })
                    //         sampreq = StyleSampleReqInformation;
                    //     }
                    //     getStyleCopy.StyleSampleRequestInformation = sampreq;
                    // }
                    /*washcare*/
                    if (stylewshcare) {
                        if (stylewshcare.length > 0) {
                            stylewshcare.map(x => {
                                x.styleWashCareFileId = 0
                            })
                        }
                        getStyleCopy.StyleWashCareFileUploadInformation = stylewshcare;
                    }
                    /*stylebarcode*/
                    if (stylebarcode) {
                        if (stylebarcode.length > 0) {
                            stylebarcode.map(x => {
                                x.styleBarcodesFileId = 0
                            })
                        }
                        getStyleCopy.StyleBarcodesFileUploadInformation = stylebarcode;
                    }
                    /*Fabric Info for copystyle Funcationality*/
                    await StyleService.GetFabricInfo(styleId).then(async (response) => {

                        let copyfabriclist = response.data;
                        copyfabriclist.map((Remarksinfo, index) => {
                            getStyleCopyfabricList.push({
                                StyleFabricInfoID: 0,
                                ShellName: Remarksinfo.shellName,
                                FabricDetails: Remarksinfo.fabricDetails,
                            });
                        });
                        getStyleCopy.StyleFabricDetailsInformation = getStyleCopyfabricList;

                        /*Remark Info for Copystyle Funcationality*/
                        await StyleService.GetRemarksInfo(styleId).then(async (response) => {
                            let Remark = response.data.remarkDetailsList;
                            if (Remark.length !== 0) {

                                if (response.data.remarkFileUploadList.length !== 0) {
                                    await response.data.remarkFileUploadList.map(element => {
                                        fileuploadlist.push({
                                            StyleRemarkFileId: 0,
                                            FileName: element.fileName,
                                            FilePath: element.filePath,
                                            FileType: element.fileType,
                                            StyleRemarksIndexName: element.styleRemarksIndexName,
                                            StyleRemarksFileIndexName: element.styleRemarksFileIndexName
                                        })
                                    })
                                }
                                getStyleCopy.StyleRemarksFileUploadInformation = fileuploadlist

                                await Remark.map((Remarksinfo, index) => {
                                    getStyleCopyRemarkList.push({
                                        StyleRemarksInfoID: 0,
                                        Remarks: Remarksinfo.remarks,
                                        ReceivedDate: Remarksinfo.receivedDate,
                                        StyleRemarksIndexName: Remarksinfo.styleRemarksIndexName
                                    });
                                });
                                //setStyleCopyRemarkList(getStyleCopyRemarkList)
                                getStyleCopy.StyleRemarksInformation = getStyleCopyRemarkList;

                                /*styleProgramList*/
                                getStyleCopy.IsProduction = parseInt(sampleorprod);
                                /*Insert the whole styleList*/
                                StyleService.InsertUpdateCopyStyle(getStyleCopy).then(res => {
                                    if (res.data.outputResult === "1") {
                                        let StartDate = null;
                                        let EndDate = null;
                                        if (moment(stateDate[0].startDate).isValid()) {
                                            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
                                        }
                                        if (moment(stateDate[0].endDate).isValid()) {
                                            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
                                        }

                                        let params = { RouteId: 0, IsProduction: 0, employeeinformationID: currentUser.employeeinformationID, PoStartDate: StartDate, PoEndDate: EndDate }
                                        // if (IsProduction !== 0) {
                                        //     dispatch(retrieveScheduleReportList(params));
                                        // }
                                        dispatch(retrieveScheduleReportList(params));
                                        //dispatch(retrieveScheduleReportList(0, IsProduction, currentUser.employeeinformationID));
                                        hideLoader();
                                        Nodify('success!', 'success', 'Copy Style saved successfully .');
                                    }
                                    else if (res.data.outputResult === "-1") {
                                        hideLoader();
                                        Nodify('warning!', 'warning', 'Error occured during copystyle saving .');
                                    }
                                    else if (res.data.outputResult === "-2") {
                                        hideLoader();
                                        Nodify('warning!', 'warning', 'Error occured during copystyle saving .');
                                    }

                                })
                            }

                            //  if (getStyleCopy.Remarklist.length > 1) { getStyleCopy.Remarklist.splice(0, 1); }

                        }).catch(() => { hideLoader(); })
                    }).catch(() => { hideLoader(); });

                }
            })
        } else {
            hideLoader();
        }
        setCopyConfirmation({ showPopupConfirm: false, Params: {} });
    }


    const styleConfirmation = (value) => {
        if (value) {
            if (comments) {
                let StyleList = comments.filter(d => d.createdBy === currentUser.employeeinformationID);
                if (StyleList && StyleList.length > 0) {
                    //Order by descending ( -1 : 1)
                    StyleList = StyleList && StyleList.sort((a, b) => (a["styleID"] > b["styleID"] ? -1 : 1));
                    let LastStyle = StyleList[0];
                    let params = { isQuickStyle: true, LastStyle: LastStyle }
                    // dispatch(updateQuickStyleCreate(params));
                    //props.history.push('/StyleListRegister');
                } else {
                    Nodify('warning', 'warning', 'Currently this user not created any style Kindly select new option');
                }
            } else {
                Nodify('warning', 'warning', 'Currently this user not created any style Kindly select new option');
            }

        } else {
            let params = { isQuickStyle: false, LastStyle: {} }
            // dispatch(updateQuickStyleCreate(params));
            // props.history.push('/StyleList');
            //props.history.push('/StyleListRegister');
        }
        props.history.push('/StyleListRegister');
    }

    if (!currentUser) {
        return <Redirect to="/login" />;
    }

    const ListType = () => {
        setListTile("Tile");
        localStorage.setItem('Listpage', 'Tile')

    }

    const TileListType = () => {
        setListTile("List");
        localStorage.setItem('Listpage', 'List')
    }

    const Delcallback = (value) => {
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }

        let params = { RouteId: 0, IsProduction: 0, employeeinformationID: currentUser.employeeinformationID, PoStartDate: StartDate, PoEndDate: EndDate }
        // if (IsProduction !== 0) {
        //     dispatch(retrieveScheduleReportList(params));
        // }
        dispatch(retrieveScheduleReportList(params));
        //let RouteId = 0;
        //dispatch(retrieveScheduleReportList(RouteId, IsProduction, currentUser.employeeinformationID));
        setID({ showPopupDelete: false, Params: [] });
    }

    const handleDateSelect = (event, isShow) => {

        let getStateDate = [...stateDate];
        getStateDate[0].isShow = isShow;
        setStateDate(getStateDate);
        //let Data = { Searchvalue: getStateDate, Page: 'CreatedDate' }
        // localStorage.setItem('CreatedDate', JSON.stringify(Data));
    }
    const clearDateSelect = () => {
        var defaultStartDate = new Date();
        const emptyStateDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - DateInterval),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];
        setStateDate(emptyStateDate);
        var defaultStartDate = new Date();
        const newemptyStateDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - DateInterval),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];
        let StartDate = null;
        let EndDate = null;
        if (moment(newemptyStateDate[0].startDate).isValid()) {
            StartDate = moment(newemptyStateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(newemptyStateDate[0].endDate).isValid()) {
            EndDate = moment(newemptyStateDate[0].endDate).format('MM/DD/YYYY');
        }

        let params = { RouteId: 0, IsProduction: 0, employeeinformationID: currentUser.employeeinformationID, PoStartDate: StartDate, PoEndDate: EndDate }
        // if (IsProduction !== 0) {
        //     dispatch(retrieveScheduleReportList(params));
        // }
        dispatch(retrieveScheduleReportList(params));
    }

    function DateRangechange(item) {
        stateDate[0].startDate = item[0].startDate;
        stateDate[0].endDate = item[0].endDate
        setStateDate(item)
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }

        let params = { RouteId: 0, IsProduction: 0, employeeinformationID: currentUser.employeeinformationID, PoStartDate: StartDate, PoEndDate: EndDate }
        // if (IsProduction !== 0) {
        //     dispatch(retrieveScheduleReportList(params));
        // }
        dispatch(retrieveScheduleReportList(params));
        localStorage.setItem('StylePodateStatedate', moment(stateDate[0].startDate).format('MM/DD/YYYY'))
        localStorage.setItem('StylePodateenddate', moment(stateDate[0].endDate).format('MM/DD/YYYY'))
    }
    const handleChangeOrdStatus = (values) => {

        setCreatedbySearch(values)
        let Data = { Searchvalue: values, Page: 'CreatedBy' }
        localStorage.setItem('CreatedBy', JSON.stringify(Data));
    }

    const FilterClear = (filterName) => {
        if (filterName === 'Buyer') {
            localStorage.removeItem('Search');
            localStorage.removeItem('StyleName');
            localStorage.removeItem('Season');
            localStorage.removeItem('Brand');
            localStorage.removeItem('Story');
            localStorage.removeItem('IdPo');
            localStorage.removeItem('Theme');
            localStorage.removeItem('Gender');
            localStorage.removeItem('CreatedBy');
            setStyleSearch('');
            setSeasonSearch('');
            setBrandSearch('');
            setStorySearch('');
            setIdPoSearch('');
            setThemeSearch('');
            setGenderSearch('');
            setCreatedbySearch('');
            // var defaultStartDate = new Date();
            // const emptyStateDate = [
            //     {
            //         startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
            //         endDate: new Date(),
            //         key: 'selection',
            //         isShow: false
            //     }];
            // setStateDate(emptyStateDate);
        }

    }
    const handleChangeFilter = (values, filterName) => {

        if (filterName === 'Buyer') {
            setBuyerSearch(values)
            let Data = { Searchvalue: values, Page: 'Buyer' }
            localStorage.setItem('Buyer', JSON.stringify(Data));
            FilterClear(filterName);
        }
        else if (filterName === 'StyleName') {
            setStyleSearch(values)
            let Data = { Searchvalue: values, Page: 'StyleName' }
            localStorage.setItem('StyleName', JSON.stringify(Data));
        }
        else if (filterName === 'Season') {
            setSeasonSearch(values)
            let Data = { Searchvalue: values, Page: 'Season' }
            localStorage.setItem('Season', JSON.stringify(Data));
        }
        else if (filterName === "Brand") {
            setBrandSearch(values)
            let Data = { Searchvalue: values, Page: 'Brand' }
            localStorage.setItem('Brand', JSON.stringify(Data));
        }
        else if (filterName === "IdPo") {
            setIdPoSearch(values)
            let Data = { Searchvalue: values, Page: 'IdPo' }
            localStorage.setItem('IdPo', JSON.stringify(Data));
        }
        else if (filterName === "Story") {
            setStorySearch(values)
            let Data = { Searchvalue: values, Page: 'Story' }
            localStorage.setItem('Story', JSON.stringify(Data));
        }
        else if (filterName === "Theme") {
            setThemeSearch(values)
            let Data = { Searchvalue: values, Page: 'Theme' }
            localStorage.setItem('Theme', JSON.stringify(Data));
        }
        else if (filterName === "Gender") {
            setGenderSearch(values)
            let Data = { Searchvalue: values, Page: 'Gender' }
            localStorage.setItem('Gender', JSON.stringify(Data));
        }
        else if (filterName === "Importance") {
            setimportance(values)
            let Data = { Searchvalue: values, Page: 'Importance' }
            localStorage.setItem('Importance', JSON.stringify(Data));
        }
    }


    const handleclick = () => {

        let getStateDate = [...stateDate];
        getStateDate[0].isShow = false;
        setStateDate(getStateDate);
    }
    function handleChange(e, filterName) {
        let inputText = '';
        let Data;

        //var Data = { Searchvalue: inputText, Page: filterName }
        if (filterName === 'Buyer') {

            // let values = [...getBuyersearch];
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            // values= inputText;
            setBuyerSearch(inputText);
            Data = { Searchvalue: inputText, Page: filterName }
            localStorage.setItem('Buyer', JSON.stringify(Data));

        }
        if (filterName === 'Style') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setStyleSearch(inputText);
            Data = { Searchvalue: inputText, Page: filterName }
            localStorage.setItem('Style', JSON.stringify(Data));

        }
        else if (filterName === 'Season') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setSeasonSearch(inputText);
            Data = { Searchvalue: inputText, Page: filterName }
            localStorage.setItem('Season', JSON.stringify(Data));
        }
        else if (filterName === 'Brand') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setBrandSearch(inputText);
            Data = { Searchvalue: inputText, Page: filterName }
            localStorage.setItem('Brand', JSON.stringify(Data));
        }
        else if (filterName === 'Story') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setStorySearch(inputText);
            Data = { Searchvalue: inputText, Page: filterName }
            localStorage.setItem('Story', JSON.stringify(Data));
        }
        else if (filterName === 'IdPo') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setIdPoSearch(inputText);
            Data = { Searchvalue: inputText, Page: filterName }
            localStorage.setItem('IdPo', JSON.stringify(Data));
        }
        else if (filterName === 'Theme') {
            if (e.target.value.trim()) {
                inputText = e.target.value;

            }
            setThemeSearch(inputText);
            Data = { Searchvalue: inputText, Page: filterName }
            localStorage.setItem('Theme', JSON.stringify(Data));
        }
        else if (filterName === 'Gender') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setGenderSearch(inputText);
            Data = { Searchvalue: inputText, Page: filterName }
            localStorage.setItem('Gender', JSON.stringify(Data));
        }
        else if (filterName === 'CreatedDate') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            //setCreatedbySearch(inputText);
            Data = { Searchvalue: inputText, Page: filterName }
            localStorage.setItem('CreatedDate', JSON.stringify(Data));
        }
        //var Data = { Searchvalue: inputText, Page: filterName }
        localStorage.setItem('Search', JSON.stringify(Data));

    };

    return (

        <>

            <div className="page-body">
                <ReactNotification />
                {/* <div className="col-sm-12">
                                        <div className="buttons-preview" align="right">
                                            <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                                <i className="fa fa-arrow-circle-left"></i>
                                                &nbsp;Back
                                            </span>
                                            
                                        </div>
                                    </div> */}
                <div className="row">
                    <div className="col-xs-12 col-md-12">
                        <div className="widget">
                            <div className="widget-header">
                                <span className="widget-caption">Schedule Detail (Mail)
                                    {/* <i title="Serach Filter"
                                        className={ShowFilter === true ? 'fa fa-filter filter-icon red' : 'fa fa-filter filter-icon green'}
                                        aria-hidden="true"
                                        style={{ display: 'none' }}
                                        onClick={
                                            () => handleInputChange()
                                        }
                                    >
                                    </i> */}

                                </span>
                                &nbsp;

                                <div className="table-toolbar pull-right">
                                            <span className="btn btn-danger pdficon" onClick={() => ResetOperation("Back")}>
                                                <i className="fa fa-arrow-circle-left"></i>
                                                &nbsp;Back
                                            </span>

                                        </div>
                                   </div>

                           

                            
                            {/* <div> {
                                isOpen && (
                                    <Lightbox
                                        mainSrc={isOpen}
                                        alt="bg image"

                                        onCloseRequest={
                                            () => setIsOpen(false)
                                        } />
                                )
                            } </div> */}
                            <div className="widget-body"
                                style={
                                    { paddingBottom: '4%' }
                                }>


                                <div id="simpledatatable_wrapper" className="dataTables_wrapper form-inline no-footer">
                                    {/* <div className="table-toolbar pull-left"> */}
                                    {/* <Search onSearch={
                                            value => {
                                                setSearch(value);
                                                setCurrentPage(1);
                                            }
                                        }
                                            placeholder={getPlaceholder} /> &nbsp;&nbsp; */}
                                    {
                                        ShowFilter === true ?
                                            <div className="row">
                                                <div className="col-lg-12 col-sm-12 col-xs-12">
                                                    <div className="widget">
                                                        <div className="widget-body no-padding">
                                                            <div className="widget-main ">
                                                                <div className="panel-group accordion" id="accordion">
                                                                    <div className="panel panel-default">
                                                                        <div className="panel-heading ">
                                                                            <h4 className="panel-title">
                                                                                <a className="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseOne">
                                                                                    Search Filter : Date Range <span className="rptDateRangeSpan">(Clicking on the text box will cause the date picker to appear and disappear)</span>
                                                                                </a>
                                                                            </h4>
                                                                        </div>
                                                                        <div id="collapseOne" className="panel-collapse collapse">
                                                                            <div className="panel-body border-red">
                                                                                <div className="row">
                                                                                    <div className="col-md-12">

                                                                                        <div className="col-md-2">
                                                                                            <label>Buyer</label>
                                                                                            <span className="input-icon icon-right">
                                                                                                {/* <input
                                                                                                    type="text"
                                                                                                    className="form-control StyleList"
                                                                                                    title={'Buyer'}
                                                                                                    placeholder={'Buyer'}
                                                                                                    value={getBuyersearch}
                                                                                                    maxlength={"50"}
                                                                                                    onChange={e => handleChange(e, 'Buyer')}
                                                                                                /> */}
                                                                                                <Reactselect className="basic-single" name="Buyer"
                                                                                                    options={getBuyerFilterlist}
                                                                                                    isClearable={true}
                                                                                                    onChange={e => handleChangeFilter(e, 'Buyer')}
                                                                                                    value={getBuyersearch}
                                                                                                    placeholder='Select Buyer'
                                                                                                ></Reactselect>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="col-md-2" style={{ display: 'none' }}>
                                                                                            <label>Style Name</label>
                                                                                            <span className="input-icon icon-right">
                                                                                                {/* <input
                                                                                                    type="text"
                                                                                                    className="form-control StyleList"
                                                                                                    title={'Style Name'}
                                                                                                    placeholder={'Style Name'}
                                                                                                    value={getStylesearch}
                                                                                                    maxlength={"50"}
                                                                                                    onChange={e => handleChange(e, 'Style')}
                                                                                                /> */}
                                                                                                <Reactselect className="basic-single" name="StyleName"
                                                                                                    id={"StyleName"}
                                                                                                    classNamePrefix="style"
                                                                                                    options={getstyleFilterlist}
                                                                                                    isClearable={true}

                                                                                                    onChange={e => handleChangeFilter(e, 'StyleName')}
                                                                                                    value={getStylesearch}
                                                                                                    placeholder='Select Style Name'
                                                                                                ></Reactselect>
                                                                                            </span>

                                                                                        </div>

                                                                                        <div className="col-md-2">
                                                                                            <label>Season</label>
                                                                                            <span className="input-icon icon-right">
                                                                                                {/* <input
                                                                                                    type="text"
                                                                                                    className="form-control StyleList"
                                                                                                    title={'Season'}
                                                                                                    placeholder={'Season'}
                                                                                                    maxlength={"50"}
                                                                                                    value={getSeasonsearch}
                                                                                                    onChange={e => handleChange(e, 'Season')}
                                                                                                /> */}
                                                                                                <Reactselect className="basic-single" name="Season"
                                                                                                    options={getSeasonFilterlist}
                                                                                                    isClearable={true}
                                                                                                    onChange={e => handleChangeFilter(e, 'Season')}
                                                                                                    value={getSeasonsearch}
                                                                                                    placeholder='Select Season'
                                                                                                ></Reactselect>

                                                                                            </span>



                                                                                        </div>
                                                                                        <div className="col-md-2">
                                                                                            <label>Brand</label>
                                                                                            <span className="input-icon icon-right">
                                                                                                {/* <input
                                                                                                    type="text"
                                                                                                    className="form-control StyleList"
                                                                                                    title={'Brand'}
                                                                                                    placeholder={'Brand'}
                                                                                                    maxlength={"50"}
                                                                                                    value={getBrandsearch}
                                                                                                    onChange={e => handleChange(e, 'Brand')}
                                                                                                /> */}
                                                                                                <Reactselect className="basic-single" name="Brand"
                                                                                                    options={getBrandFilterlist}
                                                                                                    isClearable={true}
                                                                                                    onChange={e => handleChangeFilter(e, 'Brand')}
                                                                                                    value={getBrandsearch}
                                                                                                    placeholder='Select Brand'
                                                                                                //     .filter(function (option) {
                                                                                                //     return option.label === getcreatedby.label
                                                                                                // })}
                                                                                                ></Reactselect>
                                                                                            </span>

                                                                                        </div>
                                                                                        <div className="col-md-2" style={{ display: 'none' }}>
                                                                                            <label>ID/PO No</label>
                                                                                            <span className="input-icon icon-right">
                                                                                                {/* <input
                                                                                                    type="text"
                                                                                                    className="form-control StyleList"
                                                                                                    title={'IdPo'}
                                                                                                    placeholder={'ID/PO No'}
                                                                                                    maxlength={"50"}
                                                                                                    value={getIdPosearch}
                                                                                                    onChange={e => handleChange(e, 'IdPo')}
                                                                                                /> */}
                                                                                                <Reactselect className="basic-single" name="IdPo"
                                                                                                    options={getidpoFilterlist}
                                                                                                    isClearable={true}
                                                                                                    onChange={e => handleChangeFilter(e, 'IdPo')}
                                                                                                    value={getIdPosearch}
                                                                                                    placeholder='Select ID/PO No'
                                                                                                ></Reactselect>
                                                                                            </span>

                                                                                        </div>
                                                                                        <div className="col-md-2" style={{ display: 'none' }}>
                                                                                            <label>Story</label>
                                                                                            <span className="input-icon icon-right">
                                                                                                {/* <input
                                                                                                    type="text"
                                                                                                    className="form-control StyleList"
                                                                                                    title={'Story'}
                                                                                                    placeholder={'Story'}
                                                                                                    value={getStorysearch}
                                                                                                    maxlength={"50"}
                                                                                                    onChange={e => handleChange(e, 'Story')}
                                                                                                /> */}
                                                                                                <Reactselect className="basic-single" name="Story"
                                                                                                    options={getStoryFilterlist}
                                                                                                    classNamePrefix="style"
                                                                                                    isClearable={true}
                                                                                                    onChange={e => handleChangeFilter(e, 'Story')}
                                                                                                    value={getStorysearch}
                                                                                                    placeholder='Select Story'
                                                                                                ></Reactselect>
                                                                                            </span>

                                                                                        </div>
                                                                                        <div className="col-md-2">
                                                                                            <label>Created Date</label>
                                                                                            <span className="input-icon icon-right">
                                                                                                <input
                                                                                                    value={(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                                                        ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' :
                                                                                                            moment(stateDate[0].endDate).format('DD/MM/YYYY'))}
                                                                                                    onClick={event => handleDateSelect(event, !stateDate[0].isShow)}
                                                                                                    //onBlur={event => handleDateBlur()}
                                                                                                    //onChange={e => handleChange(e, 'CreatedDate')}
                                                                                                    type="text"
                                                                                                    className={'form-control styledatapicker'}
                                                                                                    placeholder="Select Date"

                                                                                                />
                                                                                                <i className="fa fa-times" onClick={clearDateSelect} ></i>
                                                                                            </span>


                                                                                            <span className='input-icon icon-right mb-5'>
                                                                                                <br />
                                                                                                {stateDate[0].isShow &&
                                                                                                    <DateRangePicker
                                                                                                        //onChange={item => setStateDate([item.selection])}
                                                                                                        onChange={item => DateRangechange([item.selection])}
                                                                                                        showSelectionPreview={true}
                                                                                                        moveRangeOnFirstSelection={false}
                                                                                                        months={2}
                                                                                                        ranges={stateDate}
                                                                                                        showDateDisplay={false}
                                                                                                        direction="vertical"
                                                                                                        className={'StyleDateRangePicker'}

                                                                                                    />

                                                                                                }
                                                                                            </span>
                                                                                        </div>

                                                                                        <div className="col-md-2">
                                                                                            <span className="col-md-1 input-icon icon-right margin-top-30">
                                                                                                <button type="button" className="btn btn-danger" title="Reset" onClick={() => resetStyle()}
                                                                                                >
                                                                                                    <i className="fa fa-close"> Reset</i>
                                                                                                </button>
                                                                                            </span>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                &nbsp;
                                                                                <div className="row">
                                                                                    <div className="col-md-12">




                                                                                    </div>
                                                                                </div>
                                                                                &nbsp;
                                                                                <div className="row" style={{ display: 'none' }}>
                                                                                    <div className="col-md-12">
                                                                                        <div className="col-md-2">
                                                                                            <label>Theme</label>
                                                                                            <span className="input-icon icon-right">
                                                                                                {/* <input
                                                                                                    type="text"
                                                                                                    className="form-control StyleList"
                                                                                                    title={'Theme'}
                                                                                                    placeholder={'Theme'}
                                                                                                    maxlength={"50"}
                                                                                                    value={getThemesearch}
                                                                                                    onChange={e => handleChange(e, 'Theme')}
                                                                                                /> */}
                                                                                                {/* <label>&nbsp;</label> */}
                                                                                                <Reactselect className="basic-single" name="Theme"
                                                                                                    options={getThemeFilterlist}
                                                                                                    isClearable={true}
                                                                                                    onChange={e => handleChangeFilter(e, 'Theme')}
                                                                                                    value={getThemesearch}
                                                                                                    placeholder='Select Theme'
                                                                                                ></Reactselect>
                                                                                            </span>

                                                                                        </div>

                                                                                        <div className="col-md-2">
                                                                                            <label>Created By</label>
                                                                                            <span className="input-icon icon-right" onClick={handleclick}>

                                                                                                <Reactselect className="basic-single" name="CreatedBy"
                                                                                                    options={getEmployeeFilterlist}
                                                                                                    isClearable={true}
                                                                                                    onChange={e => handleChangeOrdStatus(e, 'CreatedBy')}
                                                                                                    value={getCreatedbysearch}
                                                                                                //     .filter(function (option) {
                                                                                                //     return option.label === getcreatedby.label
                                                                                                // })}
                                                                                                ></Reactselect>
                                                                                            </span>

                                                                                        </div>

                                                                                        <div className="col-md-2">
                                                                                            <label>Importance</label>
                                                                                            <span className="input-icon icon-right" onClick={handleclick}>

                                                                                                <Reactselect className="basic-single" name="importance"
                                                                                                    options={getimportanceFilterlist}
                                                                                                    isClearable={true}
                                                                                                    onChange={e => handleChangeFilter(e, 'Importance')}
                                                                                                    value={getimportance}
                                                                                                //     .filter(function (option) {
                                                                                                //     return option.label === getcreatedby.label
                                                                                                // })}
                                                                                                ></Reactselect>
                                                                                            </span>

                                                                                        </div>

                                                                                        <div className="col-md-2">
                                                                                            <label>Created Date</label>
                                                                                            <span className="input-icon icon-right">
                                                                                                <input
                                                                                                    value={(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                                                        ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' :
                                                                                                            moment(stateDate[0].endDate).format('DD/MM/YYYY'))}
                                                                                                    onClick={event => handleDateSelect(event, !stateDate[0].isShow)}
                                                                                                    //onBlur={event => handleDateBlur()}
                                                                                                    //onChange={e => handleChange(e, 'CreatedDate')}
                                                                                                    type="text"
                                                                                                    className={'form-control styledatapicker'}
                                                                                                    placeholder="Select Date"

                                                                                                />
                                                                                                <i className="fa fa-times" onClick={clearDateSelect} ></i>
                                                                                            </span>


                                                                                            <span className='input-icon icon-right mb-5'>
                                                                                                <br />
                                                                                                {stateDate[0].isShow &&
                                                                                                    <DateRangePicker
                                                                                                        //onChange={item => setStateDate([item.selection])}
                                                                                                        onChange={item => DateRangechange([item.selection])}
                                                                                                        showSelectionPreview={true}
                                                                                                        moveRangeOnFirstSelection={false}
                                                                                                        months={2}
                                                                                                        ranges={stateDate}
                                                                                                        showDateDisplay={false}
                                                                                                        direction="vertical"
                                                                                                        className={'StyleDateRangePicker'}

                                                                                                    />

                                                                                                }
                                                                                            </span>
                                                                                        </div>
                                                                                        {/* <div className='col-md-3'>
                                                                                            <span className='input-icon icon-right mb-5'>
                                                                                                <span className="input-icon icon-right">
                                                                                                    <input
                                                                                                        onClick={event => handleDateSelect(event, !stateDate[0].isShow)}
                                                                                                        value={(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                                                            ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' :
                                                                                                                moment(stateDate[0].endDate).format('DD/MM/YYYY'))}
                                                                                                        type="text"
                                                                                                        className={'form-control'}
                                                                                                        placeholder="Select Date"
                                                                                                    />
                                                                                                    <i className="fa fa-times" onClick={clearDateSelect}></i>
                                                                                                </span>
                                                                                                <span className='input-icon icon-right mb-5'>
                                                                                                    <br />
                                                                                                    {stateDate[0].isShow &&
                                                                                                        <DateRangePicker
                                                                                                            onChange={item => setStateDate([item.selection])}
                                                                                                            showSelectionPreview={true}
                                                                                                            moveRangeOnFirstSelection={false}
                                                                                                            months={2}
                                                                                                            ranges={stateDate}
                                                                                                            showDateDisplay={false}
                                                                                                            direction="vertical"
                                                                                                            className={'StyleDateRangePicker'}
                                                                                                        />}
                                                                                                </span>
                                                                                            </span>
                                                                                        </div> */}

                                                                                        <div className="col-md-2">
                                                                                            <span className="col-md-1 input-icon icon-right margin-top-30">
                                                                                                <button type="button" className="btn btn-danger" title="Reset" onClick={() => resetStyle()}
                                                                                                >
                                                                                                    <i className="fa fa-close"> Reset</i>
                                                                                                </button>
                                                                                            </span>

                                                                                        </div>



                                                                                    </div>
                                                                                </div>

                                                                                &nbsp;

                                                                                {/* <div className="row">
                                                                                    <div className="col-md-12">


                                                                                    </div>
                                                                                </div> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    &nbsp;


                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>

                                            : ''
                                    }



                                    <div />
                                    {/* {
                                        commentsData.length !== 0 &&
                                        // <div className="table-toolbar pull-left">
                                        //     <img src="assets/img/bell-yellow.png" width={"15%"} alt="" title="Priority" />    Priority
                                        //     &nbsp; <img src="assets/img/bell-red.png" width={"15%"} alt="" title="Critical" /> Critical

                                        // </div>

                                    } */}

                                   



                                   



                                  

                                </div>



                            </div>
                            {/* <div className="table-toolbar pull-right">
                                        <span className="btn btn-primary" onClick={() => ResetOperation("Back")} title="Back">
                                            <i className="fa fa-arrow"></i>&nbsp;Back
                                        </span>
                                    </div> */}
                            

                        </div>



                    </div>

                </div>
            </div >
            {loader}
        </>
    )
};
export default ScheduleReportDetailMail;