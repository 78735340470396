import {
    STYLE_LOADING,
    RETRIEVE_STYLE,
    RETRIEVE_COPYSTYLE,
    DELETE_STYLE,
    RETRIEVE_SKU_STYLE,
    DELETE_SKU_STYLE,
    RETRIEVE_PROGRAM_STYLE,
    DELETE_PROGRAM_STYLE,
    RETRIEVE_STRIKE_OFF_STYLE,
    DELETE_STRIKE_OFF_STYLE,
    RETRIEVE_TRIM_STYLE,
    DELETE_TRIM_STYLE,
    RETRIEVE_TNA_LABDIP_ALREADY_MAP_LIST,
    RETRIEVE_TNA_STRIKEOFF_ALREADY_MAP_LIST,
    RETRIEVE_TNA_TRIM_ALREADY_MAP_LIST,
    UPDATE_PROGRAM_GRID_LIST,
    UPDATE_STRIKEOFF_MAP_LIST,
    UPDATE_TRIM_MAP_LIST,
    UPDATE_QUICK_STYLE_CREATE,
    SEND_STYLE_MAIL,
    STYLE_EMAIL_EMPLOYEE_LIST,
    GET_STYLE_EMAIL_LIST,
    DELETE_MAIL_ATTACHMENT
} from "./types";

import StyleService from "../services/Style/styleService";
import SKUService from "../services/Style/SKUService";
import ProgramService from "../services/Style/ProgramService";
import StrikeoffService from "../services/Style/StrikeoffService";
import TrimService from "../services/Style/TrimService";
import styleMailService from "../services/Style/MailService";

const styleLoading = (isStatus) => ({
    type: STYLE_LOADING,
    payload: isStatus,
});

export const deleteStyle = (styleID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_STYLE,
            payload: styleID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrieveStyle = (Params) => async (dispatch) => {
    try {
        dispatch(styleLoading(true));
        const res = await StyleService.GetStyleList(Params.RouteId, Params.IsProduction, Params.employeeinformationID, Params.PoStartDate, Params.PoEndDate,);
        dispatch({
            type: RETRIEVE_STYLE,
            payload: res.data,
        });
        dispatch(styleLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(styleLoading(false));
    }
};

// using For Copy Style 
export const retrieveCopyStyle = (StyleId) => async (dispatch) => {
    try {
        dispatch(styleLoading(true));
        const res = await StyleService.GetStyleCopy(StyleId);
        dispatch({
            type: RETRIEVE_COPYSTYLE,
            payload: res.data,
        });
        dispatch(styleLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(styleLoading(false));
    }
};

export const loadSKUListByStyleID = (StyleId) => async (dispatch) => {
    try {
       ;
        dispatch(styleLoading(true));
        const res = await SKUService.LoadSKUList(StyleId);
        dispatch({
            type: RETRIEVE_SKU_STYLE,
            payload: res.data,
        });
        dispatch(styleLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(styleLoading(false));
    }
};


export const deleteSKUStyle = (SKUID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_SKU_STYLE,
            payload: SKUID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const loadProgramListByStyleID = (StyleID) => async (dispatch) => {
    try {
        dispatch(styleLoading(true));
        const res = await ProgramService.GetAllPrograms(StyleID);

        dispatch({
            type: RETRIEVE_PROGRAM_STYLE,
            payload: {
                program: res.data.program,
                critical: res.data.critical,
                manual: res.data.manual,
                manualfiles: res.data.manualFiles,
                commonApproval: res.data.commonApproval
            }
        });
        dispatch(styleLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(styleLoading(false));
    }
};


export const deleteProgramStyle = (styleProgramID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_PROGRAM_STYLE,
            payload: styleProgramID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const loadStrikeoffListByStyleID = (StyleID) => async (dispatch) => {
    try {
        dispatch(styleLoading(true));
        const res = await StrikeoffService.GetStyleStrikeOffPrintColorList(StyleID);
        dispatch({
            type: RETRIEVE_STRIKE_OFF_STYLE,
            payload: res.data,
        });
        dispatch(styleLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(styleLoading(false));
    }
};

export const deleteStrikeoffStyle = (styleStrikeOffPrintColorID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_STRIKE_OFF_STYLE,
            payload: styleStrikeOffPrintColorID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const loadStyleTrimColorListByStyleID = (StyleID) => async (dispatch) => {
    try {
        dispatch(styleLoading(true));
        const res = await TrimService.GetStyleTrimColorTabList(StyleID);
        dispatch({
            type: RETRIEVE_TRIM_STYLE,
            payload: res.data,
        });
        dispatch(styleLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(styleLoading(false));
    }
};

export const deleteTrimColorStyle = (styleTrimLogoInfoID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_TRIM_STYLE,
            payload: styleTrimLogoInfoID,
        });

    } catch (err) {
        console.log(err);
    }
};


export const getTNALabDipAlreadyMapList = (params) => async (dispatch) => {

    let isSuccess = false;
    try {
        dispatch(styleLoading(true));
        const res = await ProgramService.GetTNALabDipAlreadyMapList(params);
        dispatch({
            type: RETRIEVE_TNA_LABDIP_ALREADY_MAP_LIST,
            payload: res.data,
        });
        isSuccess = res.data.length > 0 ? true : false;
        dispatch(styleLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(styleLoading(false));
        return Promise.reject(err);
    }
    return Promise.resolve(isSuccess);
};

export const getTNAStrikeOffAlreadyMapList = (params) => async (dispatch) => {

    let isSuccess = false;
    try {
        dispatch(styleLoading(true));
        const res = await StrikeoffService.GetTNAStrikeOffAlreadyMapList(params);
        dispatch({
            type: RETRIEVE_TNA_STRIKEOFF_ALREADY_MAP_LIST,
            payload: res.data,
        });
        isSuccess = res.data.length > 0 ? true : false;
        dispatch(styleLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(styleLoading(false));
        return Promise.reject(err);
    }
    return Promise.resolve(isSuccess);
};

export const getTNATrimAlreadyMapList = (params) => async (dispatch) => {

    let isSuccess = false;
    try {
        dispatch(styleLoading(true));
        const res = await TrimService.GetTNATrimAlreadyMapList(params);
        dispatch({
            type: RETRIEVE_TNA_TRIM_ALREADY_MAP_LIST,
            payload: res.data,
        });
        isSuccess = res.data.length > 0 ? true : false;
        dispatch(styleLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(styleLoading(false));
        return Promise.reject(err);
    }
    return Promise.resolve(isSuccess);
};

export const updateProgramGridList = (index, labdibSubmissionApprovalID) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_PROGRAM_GRID_LIST,
            payload: { index: index, labdibSubmissionApprovalID: labdibSubmissionApprovalID },
        });

    } catch (err) {
        console.log(err);
    }
};

export const updateStrikeoffMapList = (index, selected) => async (dispatch) => {
    try {

        dispatch({
            type: UPDATE_STRIKEOFF_MAP_LIST,
            payload: { index: index, selected: selected },
        });

    } catch (err) {
        console.log(err);
    }
};

export const updateTrimMapList = (index, selected) => async (dispatch) => {
    try {

        dispatch({
            type: UPDATE_TRIM_MAP_LIST,
            payload: { index: index, selected: selected },
        });

    } catch (err) {
        console.log(err);
    }
};

export const updateQuickStyleCreate = (params) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_QUICK_STYLE_CREATE,
            payload: params,
        });

    } catch (err) {
        console.log(err);
    }
};
export const sendStyleMail = (mailcontent) => (dispatch) => {
    dispatch(styleLoading(true));
    try {
        return styleMailService.sendstylemail(mailcontent).then(
            (data) => {
                dispatch(styleLoading(false));
                return Promise.resolve(data);
            }
        );
    }
    catch (err) {
        console.log(err);
        dispatch(styleLoading(false));
    }

    dispatch(styleLoading(false));
};
export const getstyleEmailEmpList = (styleID) => async (dispatch) => {
    let Emplist = [];
    try {
        dispatch(styleLoading(true));
        const res = await styleMailService.getEmployeeListForStyleEmail(styleID);
        Emplist = res.data;
        dispatch({
            type: STYLE_EMAIL_EMPLOYEE_LIST,
            payload: res.data,
        });
        dispatch(styleLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(styleLoading(false));
    }
    return Promise.resolve(Emplist);
};

export const getStyleEmailList = (StyleID) => async (dispatch) => {
    let Maillist = [];
    dispatch(styleLoading(true));
    try {
        const res = await styleMailService.getStyleEmailList(StyleID);
        Maillist = res.data;
        dispatch({
            type: GET_STYLE_EMAIL_LIST,
            payload: res.data,
        });
        dispatch(styleLoading(false));
    }
    catch (err) {
        console.log(err);
        dispatch(styleLoading(false));
    }
    return Promise.resolve(Maillist);
}

export const insertEmailAttachment = (data) => async (dispatch) => {
    let MailAttachment = [];
    dispatch(styleLoading(true));
    try {
        const res = await styleMailService.insertEmailAttachment(data);
        MailAttachment = res.data;
        dispatch(styleLoading(false));
    }
    catch (err) {
        console.log(err);
        dispatch(styleLoading(false));
    }
    return Promise.resolve(MailAttachment);

}

export const deleteMailAttachment = (attachmentId) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_MAIL_ATTACHMENT,
            payload: attachmentId,
        }
        );
    }

    catch (err) {
        console.log(err);
    }
};

