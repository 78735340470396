import axios from "axios";
import authHeader from "../auth-header";

class StrikeOffTypeService {
    GetStrikeOffTypesList() {
        return axios.get(window.$APIBaseURL + "Master/GetStrikeOffTypesList?GroupTypeID=0", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateStrikeOffTypes(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateStrikeOffTypes',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateStrikeOffTypesGrid(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateStrikeOffTypesGrid',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
}
export default new StrikeOffTypeService()