import axios from "axios";
import authHeader from "../auth-header";

class TestServices {

    GetTestNameList(Operation, TaskId, TestNameId) {
        return axios.get(window.$APIBaseURL + "Master/TestNameList?Operation=" + Operation + '&TaskId=' + TaskId + '&TestNameId=' + TestNameId, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    AddTestName(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertTestName',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }


    UpdateTestName(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/UpdateTestName',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }


    InsertMappingMaster(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertMappingMaster',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }


    GetMappingMasterList(Operation, MappingID) {
        return axios.get(window.$APIBaseURL + "Master/GetMappingMasterList?Operation=" + Operation + '&MappingID=' + MappingID, {
            headers: {
                authorization: authHeader()
            }
        });
    }







}

export default new TestServices()