import {
    FABRICPART_LOADING,
    RETRIEVE_FABRICPART,
    DELETE_FABRICPART,
} from "./types";

import FabricPartService from "../services/Master/FabricPartService";

const FabricPartLoading = (isStatus) => ({
    type: FABRICPART_LOADING,
    payload: isStatus,
});

export const deleteFabricPart = (fabricPartID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_FABRICPART,
            payload: fabricPartID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrieveFabricPart = () => async (dispatch) => {
    try {
        dispatch(FabricPartLoading(true));
        const res = await FabricPartService.GetFabricPartList();
        dispatch({
            type: RETRIEVE_FABRICPART,
            payload: res.data,
        });
        dispatch(FabricPartLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(FabricPartLoading(false));
    }
};