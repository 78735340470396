import axios from "axios";
import authHeader from "../auth-header";

class CSRServices {

 getAllCSRDetails() {
  return axios.get(window.$APIBaseURL + "Master/getAllCSRDetails?ItemID=0", {
   headers: {
    authorization: authHeader()
   }
  });
 }

 InsertCSRDetails(Params) {
  
  return axios({
   method: 'post',
   url: window.$APIBaseURL + 'Master/InsertCSRDetails',
   data: Params,
   headers: {
    authorization: authHeader()
   }
  });
 }

 UpdateDeleteCSRDetails(Params) {
  return axios({
   method: 'post',
   url: window.$APIBaseURL + 'Master/UpdateDeleteCSRDetails',
   data: Params,
   headers: {
    authorization: authHeader()
   }
  });
 }


 getAllCSRDropDownDetails() {
  return axios.get(window.$APIBaseURL + "Master/getAllCSRDropdownDetails?ItemID=0", {
   headers: {
    authorization: authHeader()
   }
  });
 }

 InsertCSRDropdownDetails(Params) {

  return axios({
   method: 'post',
   url: window.$APIBaseURL + 'Master/InsertCSRDropdownDetails',
   data: Params,
   headers: {
    authorization: authHeader()
   }
  });
 }

 UpdateDeleteCSRDropdownDetails(Params) {
  return axios({
   method: 'post',
   url: window.$APIBaseURL + 'Master/UpdateDeleteCSRDropdownDetails',
   data: Params,
   headers: {
    authorization: authHeader()
   }
  });
 }






















}


export default new CSRServices()