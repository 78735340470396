import {
    USER_LOADING,
    RETRIEVE_USER,
    RETRIEVE_USERROLE,
    RETRIEVE_MENU,
    RETRIEVE_USERPERMISSON,
    // EXISTING_MENU_BRAND_PERMISSION_LIST,
    EDIT_MENU_USER_PERMISSION_LIST
} from "../actions/types";

const initialState = {
    isLoadingRole: true,
    roleList: [],
    userList: [],
    menuList: [],
    userpermissionList: [],
    // existingMenuBuyerBrandDetails: [],
    editUserPermissionList: [],
};

const userpermissionReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case USER_LOADING:
            return {
                ...state,
                isLoadingRole: payload
            };
        case RETRIEVE_USER:
            return { ...state, userList: payload };
        case RETRIEVE_USERROLE:
            return { ...state, roleList: payload };
        case RETRIEVE_MENU:
            return { ...state, menuList: payload };
        case RETRIEVE_USERPERMISSON:
            return { ...state, userpermissionList: payload };
        case EDIT_MENU_USER_PERMISSION_LIST:
            return { ...state, editUserPermissionList: payload.item3 };
        // case EXISTING_MENU_BRAND_PERMISSION_LIST:
        //    ;
        //     return { ...state,editUserPermissionList:state.editUserPermissionList, existingMenuBuyerBrandDetails: payload };
        
        default:
            return state;
    }
};
export default userpermissionReducer;