import {
    LEAVE_DAY_DROPDOWN_LIST
} from "../actions/types";

const initialState = {
    drpLeaveDaysList: [],
};

const leaveDay = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case LEAVE_DAY_DROPDOWN_LIST:
             return { ...state, drpLeaveDaysList: payload };
        default:
            return state;
    }
};
export default leaveDay;