import {
    REPORT_LOADING,
    LOAD_REPORT_LIST,
    UPDATE_COMMENT_REPORT_VIEW_ALL_CHECK_BOX,
    UPDATE_COMMENT_REPORT_VIEW_CHECK_BOX

} from "../actions/types";

const initialState = {
    CommentsReportList: [],
    isLoadingReport: true,
    //AgencyList: []
};

const reportReducer = (state = initialState, action) => {

    const { type, payload } = action;
    switch (type) {
        case REPORT_LOADING:
            return {
                ...state,
                isLoadingReport: payload
            };
        case LOAD_REPORT_LIST:
           ;
            return {
                ...state,
                CommentsReportList: payload

            };
        case UPDATE_COMMENT_REPORT_VIEW_ALL_CHECK_BOX:
           ;
            let isCheck = payload;
            let List = state.CommentsReportList;
           ;

            let Totallitem = List && List.item1;
            if (isCheck) {
                Totallitem && Totallitem.filter(x => x.isSelected === true).map((item, Index) => {
                    item.isSelected = isCheck
                });
                List.item1 = [...Totallitem];
            } else {
                Totallitem && Totallitem.filter(x => x.isSelected === false).map((item, Index) => {
                    item.isSelected = isCheck
                });
                List.item1 = Totallitem;
            }
           ;
            // List = List
            return {
                ...state,
                CommentsReportList: List
            };
        case UPDATE_COMMENT_REPORT_VIEW_CHECK_BOX:
            let Index = payload.index;
            let isChecked = payload.isChecked;
           
            let Comments = state.CommentsReportList;
            let Totallitems = Comments && Comments.item1
            Totallitems[Index].isSelected = isChecked
            Comments.item1 = Totallitems;

            return {
                ...state,
                CommentsReportList: Comments
            };


        default:
            return state;
    }
}
export default reportReducer;