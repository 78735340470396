import React, { useState, useEffect, useRef, Fragment } from "react";
import ReactNotification from 'react-notifications-component';
import CategoryService from "../../../../services/Master/CategoryService";
import Nodify from "../../../Common/ReactNotification"
import SearchRetain from "../../../Common/SearchRetain";
import { useSelector } from "react-redux";
const AddCategory = (props) => {
    const EmptyInputFields =
    {
        CategoryID: 0,
        GroupTypeID: 0,
        CategoryName: '',
        CategoryTypeID: 0
    }
    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    if (menulist !== undefined) {
        activeMenu = menulist.filter(i => i.menuName === "Category");
    }
    //end enable menu
    const [inputFields, setInputFields] = useState([EmptyInputFields]);
    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getCategoryTypeList, setCategoryTypeList] = useState([]);
    const [getGroupTypeList, setGroupTypeList] = useState([]);
    const [ExistingList, setExistingList] = useState([]);
    const values = [...inputFields];

    useEffect(() => {
        CategoryService.LoadGroupTYpe().then((response) => {
            if (response.data) {
                setGroupTypeList(response.data);
            }
        }).catch(() => { });

        CategoryService.LoadCategoryTypeList().then((response) => {
            if (response.data) {
                setCategoryTypeList(response.data);
            }
        }).catch(() => { });

        CategoryService.GetCategoryList(0).then((response) => {
            if (response.data) {
                setExistingList(response.data);
            }
        });

    }, []);

    const handleAddFields = (index) => {
        if (values[index].CategoryName !== '' && values[index].GroupTypeID !== 0 && values[index].CategoryTypeID !== 0) {
            values.push(EmptyInputFields);
            setInputFields(values);
            setSubmitted(false);
        } else {
            Nodify('Warning!', 'warning', 'Please fill this mandatory(*) fields.');
            setSubmitted(true);
        }
    }

    //Enter Error msg
    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                //SaveBuyerInfo(event);
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);

    const handleInputChange = (index, event) => {
        //setSubmitted(false);
        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }
        if (event.target.name === "GroupTypeID") {
            values[index].GroupTypeID = parseInt(inputText);
        } else if (event.target.name === "CategoryName") {
            values[index].CategoryName = inputText;
        } else if (event.target.name === "CategoryTypeID") {
            values[index].CategoryTypeID = parseInt(inputText);
        }
        setInputFields(values);
    }

    const handleRemoveFields = index => {
        values.splice(index, 1);
        setInputFields(values);
    }

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/CategoryList');
        } else {
            window.location.reload();
        }
    }

    const CheckDuplicate = (index, Field) => {
        if (ExistingList.length !== 0) {
            if (values[index].CategoryName !== '' && values[index].GroupTypeID !== 0 && values[index].CategoryTypeID !== 0) { // for avoid empty validation
                for (var i = 0; i < ExistingList.length; i++) {
                    if (ExistingList[i].groupTypeID === values[index].GroupTypeID &&
                        ExistingList[i].categoryTypeID === values[index].CategoryTypeID &&
                        ExistingList[i].categoryName.trim().toLowerCase() === values[index].CategoryName.trim().toLowerCase()) {
                        if (Field === "GroupTypeID") {
                            values[index].GroupTypeID = 0;
                        }
                        else if (Field === "CategoryTypeID") {
                            values[index].CategoryTypeID = 0;
                        }
                        else if (Field === "CategoryName") {
                            values[index].CategoryName = '';
                        }
                        setInputFields(values);
                        Nodify('Warning!', 'warning', "This category is already exist.");
                        return false;
                    }
                }
            }
        }

        for (var j = 0; j < values.length; j++) {
            if (index !== j) {
                if (values[index].CategoryName !== '' && values[index].GroupTypeID !== 0 && values[index].CategoryTypeID !== 0) { // for avoid empty validation
                    if (values[j].GroupTypeID === values[index].GroupTypeID &&
                        values[j].CategoryTypeID === values[index].CategoryTypeID &&
                        values[j].CategoryName.trim().toLowerCase() === values[index].CategoryName.trim().toLowerCase()) {
                        if (Field === "GroupTypeID") {
                            values[index].GroupTypeID = 0;
                        }
                        else if (Field === "CategoryTypeID") {
                            values[index].CategoryTypeID = 0;
                        }
                        else if (Field === "CategoryName") {
                            values[index].CategoryName = '';
                        }
                        setInputFields(values);
                        Nodify('Warning!', 'warning', 'This category is already exist.');
                        return false;
                    }
                }
            }
        }
    }

    const SaveCategoryList = (e) => {
        setButtonLoader(true);
        e.preventDefault();
        if (values.length === 1 && (values[0].GroupTypeID === 0 || values[0].CategoryName === '' || values[0].CategoryTypeID === 0)) {
            setButtonLoader(false);
            setSubmitted(true);
            ValidationPopup("Please fill atleast one Category details.");
        } else {
            let Isvalid = false;
            for (var j = 0; j < values.length; j++) {
                if (values[j].GroupTypeID === 0 || values[j].CategoryName === '' || values[j].CategoryTypeID === 0) {
                    Isvalid = true
                }
            }
            if (!Isvalid) {
                setSubmitted(false);
                let categoryListParam = {};
                categoryListParam.CategoryInformation = values;
                categoryListParam.Createdby = 1;
                CategoryService.InsertUpdateCategoryGrid(categoryListParam).then(res => {
                    var page = "Remove";
                    SearchRetain(page);
                    if (res.data.outputResult === "1") {
                        PageRedirect('Add');
                    } else if (res.data.outputResult === "0") {
                        setButtonLoader(false);
                        ValidationPopup("Error Occured!");
                    }
                });
            } else {
                setButtonLoader(false);
                setSubmitted(true);
                ValidationPopup("Please fill all mandatory(*) fields.");
            }
        }
    }

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/CategoryList",
            state: {
                message: Func
            }
        });
    }

    const ValidationPopup = (Msg) => {
        Nodify('Warning!', 'warning', Msg);
        setButtonLoader(false);
        return false;
    }

    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">Add Category</h3>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <form>
                                    {
                                        inputFields.map((inputField, index) => (
                                            <Fragment key={
                                                `${inputField}~${index}`
                                            }>
                                                <div className="row row_left10">
                                                    <div className="col-sm-3 divder_style">
                                                        <div className="form-group">
                                                            <span className="input-icon icon-right">
                                                                <label htmlFor={
                                                                    "GroupTypeID_" + index
                                                                }>Group Type<span className="text-danger">*</span>
                                                                </label>
                                                                <select id={
                                                                    "GroupTypeID_" + index
                                                                }
                                                                    name="GroupTypeID"
                                                                    className="form-select"
                                                                    value={
                                                                        inputField.GroupTypeID
                                                                    }
                                                                    onChange={
                                                                        event => handleInputChange(index, event)
                                                                    }
                                                                    onBlur={() => CheckDuplicate(index, 'GroupTypeID')}
                                                                    style={{ border: submitted && inputField.GroupTypeID === 0 ? '1px solid red' : '' }}
                                                                >
                                                                    <option value='0'>- Select Group Type -</option>
                                                                    {
                                                                        getGroupTypeList.map(item => (
                                                                            <option key={
                                                                                item.id
                                                                            }
                                                                                value={
                                                                                    item.id
                                                                                }>
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-3 divder_style">
                                                        <div className="form-group">
                                                            <span className="input-icon icon-right">
                                                                <label htmlFor={
                                                                    "CategoryName_" + index
                                                                }>Category Name<span className="text-danger">*</span>
                                                                </label>
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"CategoryName_" + index}
                                                                    name="CategoryName"
                                                                    placeholder="Enter Category Name"
                                                                    maxLength="50"
                                                                    value={inputField.CategoryName}
                                                                    onChange={event => handleInputChange(index, event)}
                                                                    onBlur={() => CheckDuplicate(index, 'CategoryName')}
                                                                    style={{ border: submitted && !inputField.CategoryName ? '1px solid red' : '' }}
                                                                    autoFocus
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-3 divder_style">
                                                        <div className="form-group">
                                                            <span className="input-icon icon-right">
                                                                <label htmlFor={
                                                                    "CategoryTypeID_" + index
                                                                }>Category Type<span className="text-danger">*</span>
                                                                </label>
                                                                <select id={
                                                                    "CategoryTypeID_" + index
                                                                }
                                                                    name="CategoryTypeID"
                                                                    className="form-select"
                                                                    value={
                                                                        inputField.CategoryTypeID
                                                                    }
                                                                    onChange={
                                                                        event => handleInputChange(index, event)
                                                                    }
                                                                    onBlur={() => CheckDuplicate(index, 'CategoryTypeID')}
                                                                    style={{ border: submitted && inputField.CategoryTypeID === 0 ? '1px solid red' : '' }}
                                                                >
                                                                    <option value='0'>- Select Category Type -</option>
                                                                    {
                                                                        getCategoryTypeList.map(item => (
                                                                            <option key={
                                                                                item.id
                                                                            }
                                                                                value={
                                                                                    item.id
                                                                                }>
                                                                                {
                                                                                    item.name
                                                                                } </option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-3 paddingstatic">
                                                        {
                                                            inputFields.length !== 1 && (
                                                                <div className="col-lg-1_own col-xs-12 adddeletemargin">
                                                                    <button type="button" className="btn btn-danger"
                                                                        style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}
                                                                        title="Delete Category" onClick={() => handleRemoveFields(index)}>
                                                                        <i className="fa fa-trash-o"></i>
                                                                    </button>
                                                                </div>
                                                            )}
                                                        {
                                                            inputFields.length === (index + 1) && (
                                                                <div className="col-lg-1_own col-xs-12 adddeletemargin">

                                                                    <button type="button" className="btn btn-success" title="Add Category" onClick={() => handleAddFields(index)}>
                                                                        <i className="fa fa-plus"></i>
                                                                    </button>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </Fragment>
                                        ))
                                    }
                                    <div className="col-sm-12">
                                        <div className="buttons-preview" align="right">
                                            <span className="btn btn-primary"
                                                onClick={
                                                    () => ResetOperation("Back")
                                                }>
                                                <i className="fa fa-arrow-circle-left"></i>
                                                &nbsp;Back</span>
                                            <span className="btn btn-danger"
                                                onClick={
                                                    () => ResetOperation()
                                                }>
                                                <i className="fa fa-times"></i>
                                                &nbsp;Reset</span>
                                            <button type="button" className="btn btn-success"
                                                onClick={SaveCategoryList}
                                                disabled={buttonLoader}>
                                                <i className="fa fa-check right"></i>
                                                &nbsp;Save</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};
export default AddCategory;