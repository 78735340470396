import axios from "axios";
import authHeader from "../auth-header";

class TestFieldService {
    GetTestFieldList() {
        return axios.get(window.$APIBaseURL + "Master/TestFieldList", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    UpdateTestField(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/UpdateTestField',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertTestField(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertTestField',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
}
export default new TestFieldService()