import {
    SIZE_LOADING,
    RETRIEVE_SIZE,
    DELETE_SIZE,
    DROPDOWN_SIZE_LIST
} from "./types";

import SizeService from "../services/Master/SizeService";

const sizeLoading = (isStatus) => ({
    type: SIZE_LOADING,
    payload: isStatus,
});

export const deleteSize = (sizeDetailID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_SIZE,
            payload: sizeDetailID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const LoadSizeList = () => async (dispatch) => {

    try {
        dispatch(sizeLoading(true));
        const res = await SizeService.LoadSizeList();
        dispatch({
            type: DROPDOWN_SIZE_LIST,
            payload: res.data,
        });
        dispatch(sizeLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(sizeLoading(false));
    }
};



export const retrieveSize = (sizeDetailID) => async (dispatch) => {
    try {
        dispatch(sizeLoading(true));
        const res = await SizeService.GetSizeList(sizeDetailID);
        dispatch({
            type: RETRIEVE_SIZE,
            payload: res.data,
        });
        dispatch(sizeLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(sizeLoading(false));
    }
};