import axios from "axios";
import authHeader from "../auth-header";

class PackageItemService {
    GetPackageItemList(Operation, PackageItemID, PackageItemInfoID) {
        return axios.get(window.$APIBaseURL + "Master/GetPackageItemList?Operation=" + Operation + '&PackageItemID=' + PackageItemID + '&PackageItemInfoID=' + PackageItemInfoID, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    InsertUpdatePackageItemGrd(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdatePackageItemGrd',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

}
export default new PackageItemService;