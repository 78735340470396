import axios from "axios";
import authHeader from "../auth-header";

class TaskTypeService {
    getTaskTypeList() {
        return axios.get(window.$APIBaseURL + "Master/GetTaskTypeList?TaskTypeID=0", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateTaskTypeGrid = async (Params) => {
        return await axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateTaskTypeGrid',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateTaskType(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateTaskType',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
}

export default new TaskTypeService()