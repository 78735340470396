import {
    ORDER_STRIKE_OFF_LOADING,
    ORDER_STRIKE_OFF_RELOADING,
    RETRIEVE_ORDER_STRIKE_OFF,
    DELETE_ORDER_STRIKE_OFF,
    RETRIEVE_ORDER_STRIKEOFF_SUBMISSION_LIST,
    RETRIEVE_ORDER_STRIKE_OFF_DETAIL_VIEW_LIST,
    CLEAR_ORDER_STRIKE_OFF_DETAIL_VIEW_SUB_ROW,
    CLEAR_ORDER_STRIKEOFF_SUBMISSION_SUB_ROW,
    TNA_TASK_ORDER_STRIKEOFF_SUBMISSION_AND_APPROVAL,
    REMOVE_ORDER_STRIKEOFF_REQUEST_VIEW_SUB_ROW,
    RETRIEVE_ORDER_STRIKEOFF_APPROVAL_LIST,
    CLEAR_ORDER_STRIKEOFF_APPROVAL_SUB_ROW,
    UPDATE_ORDER_STRIKEOFF_IS_APPROVAL_MANUAL_CLOSE_POPUP,
    CLEAR_ORDER_STRIKEOFF_APPROVAL_VIEW_SUB_ROW,
    RETRIEVE_ORDER_STRIKEOFF_APPROVAL_VIEW_LIST
} from "../actions/types";
const OLDStatusList = [
    { value: 1, label: 'To Do', isdisabled: false },
    { value: 2, label: 'Requested', isdisabled: false },
    { value: 3, label: 'Submitted', isdisabled: false },
    { value: 4, label: 'Approved', isdisabled: false }
];
const OLDRequestStatusList = [

    { value: 1, label: 'Pending', isdisabled: false },
    { value: 2, label: 'Completed', isdisabled: false },
];
const initialState = {
    isLoadingOrderStrikeOff: true,
    isReLoadingOrderStrikeOff: true,
    orderStrikeOffList: [],
    orderStrikeOffSubmissionList: [],
    orderStrikeOffSubmissionParams: {},
    orderStrikeOffViewDetailParams: {}, //**orderStrikeOffViewDetail**
    orderStrikeOffViewDetailList: [], //OrderStrikeOffViewDetail-(GLDVD)
    editTNATaskStrikeOffSubmissionList: [],
    orderStrikeOffApprovalList: [],
    orderStrikeOffApprovalParams: {},
    orderStrikeOffApprovalViewList: [],
    orderStrikeOffApprovalViewParams: {},
    OLDStatusList: OLDStatusList,
    OLDRequestStatusList: OLDRequestStatusList,
    // GLDVDPantoneSubList: [],

    // GLDVDCriticalList: [],
};

const orderStrikeOffReducer = (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case ORDER_STRIKE_OFF_LOADING:
            return {
                ...state,
                isLoadingOrderStrikeOff: payload
            };
        case ORDER_STRIKE_OFF_RELOADING:
            return {
                ...state,
                isReLoadingOrderStrikeOff: payload
            };
        case RETRIEVE_ORDER_STRIKE_OFF:
            return { ...state, orderStrikeOffList: payload };
        case DELETE_ORDER_STRIKE_OFF:
            const results = state.orderStrikeOffList.filter(c => c.genStrikeOffId !== payload.genStrikeOffId);
            return {
                ...state,
                orderStrikeOffList: results
            };
        // case RETRIEVE_ORDER_STRIKE_OFF_DETAIL_VIEW_LIST:

        //     if (payload.params.PantoneId === 0) {

        //         return {
        //             ...state,
        //             orderStrikeOffViewDetailList: payload.data.strikeOffList,
        //             orderStrikeOffViewDetailParams: payload.params
        //         };
        //     } else {

        //         let index = payload.params.Index;
        //         let resultSet = payload.data;
        //         //OrderStrikeOffViewDetail-(GLDVD)
        //         let GLDVDPantoneCodeList = [...state.orderStrikeOffViewDetailList];


        //         GLDVDPantoneCodeList[index].pantoneSubList = resultSet.pantoneSubList ? resultSet.pantoneSubList : [];
        //         GLDVDPantoneCodeList[index].criticalList = resultSet.criticalList ? resultSet.criticalList : [];

        //         return {
        //             ...state,
        //             orderStrikeOffViewDetailList: GLDVDPantoneCodeList,
        //             orderStrikeOffViewDetailParams: payload.params
        //         };
        //     }
        case RETRIEVE_ORDER_STRIKE_OFF_DETAIL_VIEW_LIST:
           ;
            if (payload.params.StrikeOffId === 0) {

                return {
                    ...state,
                    orderStrikeOffViewDetailList: payload.data.strikeOffList,
                    orderStrikeOffViewDetailParams: payload.params
                };
            } else {

                let index = payload.params.Index;
                let resultSet = payload.data;
                //GeneralStrikeOffViewDetail-(GSOVD)
                let GSOVDStrikeOffList = [...state.orderStrikeOffViewDetailList];
                GSOVDStrikeOffList[index].strikeOffReferenceList = resultSet.strikeOffReferenceList ? resultSet.strikeOffReferenceList : [];
                GSOVDStrikeOffList[index].manualCommentList = resultSet.manualCommentList ? resultSet.manualCommentList : [];
                GSOVDStrikeOffList[index].manualImageList = resultSet.manualImageList ? resultSet.manualImageList : [];
                GSOVDStrikeOffList[index].existingList = resultSet.existingList ? resultSet.existingList : [];
                // GSOVDStrikeOffList[index].criticalList = resultSet.criticalList ? resultSet.criticalList : [];

                return {
                    ...state,
                    generalStrikeOffViewDetailList: GSOVDStrikeOffList,
                    generalStrikeOffViewDetailParams: payload.params
                };
            }
        //Approval
        case RETRIEVE_ORDER_STRIKEOFF_APPROVAL_VIEW_LIST:
            if (payload.params.StrikeOffId === 0) {
                return {
                    ...state,
                    orderStrikeOffApprovalViewList: payload.data.strikeOffList,
                    orderStrikeOffApprovalViewParams: payload.params
                };
            } else {

                let index = payload.params.Index;
                let resultSet = payload.data;

                let GSOVDStrikeOffList = [...state.orderStrikeOffApprovalViewList];

                GSOVDStrikeOffList[index].strikeOffSubmitApprovalReferenceList = resultSet.strikeOffSubmitApprovalReferenceList ? resultSet.strikeOffSubmitApprovalReferenceList : [];
                // GSOVDStrikeOffList[index].criticalList = resultSet.criticalList ? resultSet.criticalList : [];

                return {
                    ...state,
                    orderStrikeOffApprovalViewList: GSOVDStrikeOffList,
                    orderStrikeOffApprovalViewParams: payload.params
                };
            }
        case CLEAR_ORDER_STRIKEOFF_APPROVAL_VIEW_SUB_ROW:

            let ViewIndex = payload;
            let GSOVA_StrikeOffList = [...state.orderStrikeOffApprovalViewList];
            GSOVA_StrikeOffList[ViewIndex].strikeOffSubmitApprovalReferenceList = [];
            // GSOVDRefernceList[index].criticalList = [];
            return {
                ...state,
                orderStrikeOffApprovalViewList: GSOVA_StrikeOffList
            };
        case RETRIEVE_ORDER_STRIKEOFF_APPROVAL_LIST:
            if (payload.params.StrikeOffId === 0) {
                return {
                    ...state,
                    orderStrikeOffApprovalList: payload.data.strikeOffList,
                    orderStrikeOffApprovalParams: payload.params
                };
            } else {

                let index = payload.params.Index;
                let resultSet = payload.data;

                let GSOVDStrikeOffList = [...state.orderStrikeOffApprovalList];

                GSOVDStrikeOffList[index].strikeOffSubmitApprovalReferenceList = resultSet.strikeOffSubmitApprovalReferenceList ? resultSet.strikeOffSubmitApprovalReferenceList : [];
                // GSOVDStrikeOffList[index].criticalList = resultSet.criticalList ? resultSet.criticalList : [];

                return {
                    ...state,
                    orderStrikeOffApprovalList: GSOVDStrikeOffList,
                    orderStrikeOffApprovalParams: payload.params
                };
            }
        case CLEAR_ORDER_STRIKEOFF_APPROVAL_SUB_ROW:
            let ApprIndex = payload;
            let GSOA_StrikeOffList = [...state.orderStrikeOffApprovalList];
            GSOA_StrikeOffList[ApprIndex].strikeOffSubmitApprovalReferenceList = [];
            // GSOVDRefernceList[index].criticalList = [];
            return {
                ...state,
                orderStrikeOffApprovalList: GSOA_StrikeOffList
            };
        case UPDATE_ORDER_STRIKEOFF_IS_APPROVAL_MANUAL_CLOSE_POPUP:
            let indexRow = payload.index;
            let subIndexRow = payload.subIndex;
            let isOpen = payload.isOpen;
            let StrikeOffApprovalList = [...state.orderStrikeOffApprovalList];
            let strikeOffApprovalReferenceList = StrikeOffApprovalList[indexRow].strikeOffSubmitApprovalReferenceList;
            strikeOffApprovalReferenceList[subIndexRow].isApprovalManualClosePopup = isOpen;
            StrikeOffApprovalList[indexRow].strikeOffSubmitApprovalReferenceList = strikeOffApprovalReferenceList;

            return {
                ...state,
                orderStrikeOffApprovalList: StrikeOffApprovalList
            };
        case RETRIEVE_ORDER_STRIKEOFF_SUBMISSION_LIST:
            if (payload.params.StrikeOffId === 0) {

                return {
                    ...state,
                    orderStrikeOffSubmissionList: payload.data.strikeOffList,
                    orderStrikeOffSubmissionParams: payload.params
                };
            } else {

                let index = payload.params.Index;
                let resultSet = payload.data;
                //GeneralStrikeOffViewDetail-(GSOVD)
                let GSOVDStrikeOffList = [...state.orderStrikeOffSubmissionList];

                GSOVDStrikeOffList[index].strikeOffSubmitApprovalReferenceList = resultSet.strikeOffSubmitApprovalReferenceList ? resultSet.strikeOffSubmitApprovalReferenceList : [];
                // GSOVDStrikeOffList[index].criticalList = resultSet.criticalList ? resultSet.criticalList : [];

                return {
                    ...state,
                    orderStrikeOffSubmissionList: GSOVDStrikeOffList,
                    orderStrikeOffSubmissionParams: payload.params
                };
            }
        case TNA_TASK_ORDER_STRIKEOFF_SUBMISSION_AND_APPROVAL:
            return {
                ...state,
                editTNATaskStrikeOffSubmissionList: payload
            };
        case CLEAR_ORDER_STRIKEOFF_SUBMISSION_SUB_ROW:

            let indexVal = payload;
            //GeneralStrikeOffViewDetail-(GSOVD)
            let GSOVD_StrikeOffList = [...state.orderStrikeOffSubmissionList];
            GSOVD_StrikeOffList[indexVal].strikeOffSubmitApprovalReferenceList = [];
            // GSOVDRefernceList[index].criticalList = [];

            return {
                ...state,
                orderStrikeOffSubmissionList: GSOVD_StrikeOffList
            };

        case CLEAR_ORDER_STRIKE_OFF_DETAIL_VIEW_SUB_ROW:
            let index = payload;
            //OrderStrikeOffViewDetail-(GLDVD)
            let GLDVDPantoneCodeList = [...state.orderStrikeOffViewDetailList];
            GLDVDPantoneCodeList[index].strikeOffReferenceList = [];
            GLDVDPantoneCodeList[index].criticalList = [];
            GLDVDPantoneCodeList[index].manualCommentList = [];

            return {
                ...state,
                orderStrikeOffViewDetailList: GLDVDPantoneCodeList
            };
        case REMOVE_ORDER_STRIKEOFF_REQUEST_VIEW_SUB_ROW:
            let CurrIndex = payload.searchParams.Index;
            let parentStrikeOffList = [...state.orderStrikeOffSubmissionList];
            let strikeOffSubmitReference = parentStrikeOffList[CurrIndex].strikeOffSubmitApprovalReferenceList;
            strikeOffSubmitReference = strikeOffSubmitReference.filter(d => d.genStrikeOffDetailTNAId !== payload.GenStrikeOffDetailTNAId)
            if (strikeOffSubmitReference && strikeOffSubmitReference.length > 0) {
                parentStrikeOffList[CurrIndex].strikeOffSubmitApprovalReferenceList = strikeOffSubmitReference;
            } else {
                parentStrikeOffList.splice(CurrIndex, 1);
            }
            return {
                ...state,
                orderStrikeOffSubmissionList: parentStrikeOffList,
            };
        default:
            return state;
    }
};
export default orderStrikeOffReducer;