import {
    CATEGORY_LOADING,
    RETRIEVE_CATEGORY,
    DELETE_CATEGORY,
} from "./types";

import CategoryService from "../services/Master/CategoryService";

const categoryLoading = (isStatus) => ({
    type: CATEGORY_LOADING,
    payload: isStatus,
});

export const deleteCategory = (categoryID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_CATEGORY,
            payload: categoryID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrieveCategory = () => async (dispatch) => {
    try {
        dispatch(categoryLoading(true));
        const res = await CategoryService.GetCategoryList(0);
        dispatch({
            type: RETRIEVE_CATEGORY,
            payload: res.data,
        });
        dispatch(categoryLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(categoryLoading(false));
    }
};