import {
    QACOMMENTPOINT_LOADING,
    RETRIEVE_QACOMMENTPOINT,
    DELETE_QACOMMENTPOINT,
} from "./types";

import QACommentPointService from "../services/Master/QACommentPointService";

const QACommentpointLoading = (isStatus) => ({
    type: QACOMMENTPOINT_LOADING,
    payload: isStatus,
});

export const deleteQCCommentPoint = (QCCommentPoint) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_QACOMMENTPOINT,
            payload: QCCommentPoint,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrieveQACommentpoint = () => async (dispatch) => {
    try {
        dispatch(QACommentpointLoading(true));
        const res = await QACommentPointService.GetQACommentPointList();
        dispatch({
            type: RETRIEVE_QACOMMENTPOINT,
            payload: res.data,
        });
        dispatch(QACommentpointLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(QACommentpointLoading(false));
    }
};