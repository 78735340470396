import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Nodify from "../../../Common/ReactNotification";
import SearchRetain from "../../../Common/SearchRetain";
import ReactNotification from 'react-notifications-component';
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import { updateMasterSize } from "../../../../actions/MasterSize";
import MasterSizeService from "../../../../services/Master/MasterSizeService";

const MasterSizeEdit = (props) => {
    const dispatch = useDispatch();
    const { user: currentUser } = useSelector((state) => state.auth);

    const [getSizeInfo, setSizeInfo] = useState({});
    const [buttonLoader, setButtonLoader] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    function BindProcessTypeValue(props) {
        let SizeInfoValue = {
            Operation: 2,
            SizeDetailsID: 0,
            SizeID: '',
            Createdby: currentUser.employeeinformationID,
        };
        if (props.location.state !== undefined) {
            if (props.location.state.params.length !== 0) {
                if (props.location.state.params.sizeID !== 0) {
                    SizeInfoValue.Operation = 2;
                    SizeInfoValue.SizeDetailsID = parseInt(props.location.state.params.sizeID);
                    SizeInfoValue.SizeID = props.location.state.params.sizeNames;
                    return SizeInfoValue;
                }
            }
        }
        else {
            return SizeInfoValue;
        }
    }

    //
    useEffect(() => {
        let ProcessTypeinfo = BindProcessTypeValue(props);
        setSizeInfo(ProcessTypeinfo);
    }, []);


    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/MasterSizeList",
            state: {
                message: Func,
            },
        }
        );
    };
    //Enter Error msg
    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                // SaveBuyerInfo(event);
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);
    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/MasterSizeList')
        } else {
            //      setSubmitted(false);
            // let Seasoninfo = BindSeasonValue(props);
            // setSeasonInfo(Seasoninfo);
            window.location.reload();
        }
    }

    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
    };

    const handleChange = (e) => {
        let Value = getSizeInfo;

        if (e.target.name === "SizeID") {
            if (e.target.value.trim() !== '') {
                setSubmitted(true);
            }
            else {
                setSubmitted(false);
            }
            Value[e.target.name] = e.target.value;
        }
        setSizeInfo(Value);
    }

    const SaveSizeType = (e) => {
       ;
        setButtonLoader(true);
        e.preventDefault();
        if (getSizeInfo.SizeID.trim() === '') {
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Size  is required.');
        }
        if (getSizeInfo.SizeID.trim()) {
            getSizeInfo.SizeID = getSizeInfo.SizeID.trim();

            MasterSizeService.InsertUpdateMasterSize(getSizeInfo).then(res => {
                var page = "Remove";
                SearchRetain(page);
                let Func = 'Add';
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "-2") {
                    getSizeInfo.ProcessType = '';
                    setSizeInfo(getSizeInfo);
                    Nodify('Warning!', 'warning', 'This name is already exist.');
                }
                setButtonLoader(false);
            })
                .catch(e => {
                    console.log(e);
                });
        }
        else {
            setSubmitted(true);
        }
    }

    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">
                                Edit Size
                            </h3>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <Form>
                                    <div className="row">
                                        <div className="form-group col-sm-4">
                                            <label htmlFor="SizeName">
                                                Size
                                                <span className="text-danger">*</span>
                                            </label>
                                            <span className="input-icon icon-right">
                                                <Input
                                                    placeholder={"Enter Size"}
                                                    id="SizeID"
                                                    name="SizeID"
                                                    value={getSizeInfo.SizeID}
                                                    type="text"
                                                    maxLength="150"
                                                    autoComplete="off"
                                                    onChange={handleChange}
                                                    className='form-control'
                                                    style={{ border: submitted && !getSizeInfo.SizeID ? '1px solid red' : '' }}
                                                    autoFocus
                                                />
                                            </span>

                                        </div>
                                    </div>

                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div
                        className="buttons-preview"
                        align="right"
                        style={{ marginTop: "15px" }}
                    >
                        <div className="buttons-preview" align="right">
                            <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                            </span>
                            <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                <i className="fa fa-times"></i>&nbsp;Reset
                            </span>
                            <button
                                type="submit"
                                className="btn btn-success"
                                onClick={SaveSizeType}
                                disabled={buttonLoader}
                            >

                                <i className="fa fa-check right"></i> &nbsp;Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default MasterSizeEdit;