import React, { useState, useEffect, useMemo } from "react";
import { TableHeader, Pagination, Search, PageCount } from "../../Datatable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import SweetAlertPopup from '../../Common/SweetAlertPopup';
import { Redirect } from 'react-router-dom';
import Nodify from "../../Common/ReactNotification"
import SearchRetain from "../../Common/SearchRetain";
import CommonService from '../../../services/Common/CommonService';
import { retriveQA } from "../../../actions/qa";
import { useDispatch, useSelector } from "react-redux";
import { commentID } from "../../Common/PageActionNumber";

const QAList = (props) => {
 const [getPlaceholder] = useState("QA Comment");
 const [loader, showLoader, hideLoader] = useFullPageLoader();
 const [totalItems, setTotalItems] = useState(0);
 const [currentPage, setCurrentPage] = useState(1);
 const [search, setSearch] = useState("");
 const [sorting, setSorting] = useState({ field: "", order: "" });
 const [getID, setID] = useState({ showPopupDelete: false, Params: {} });
 const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
 const dispatch = useDispatch();


 const reducerState = useSelector((state) => state);
 const { user: currentUser } = reducerState.auth;
 const comments = reducerState.qa.qaList;

 let isLoadingQA = reducerState.qa.isLoadingQA;


 const headers = [
  {
   name: "QA Comment",
     field: "comment",
   sortable: true
  }, {
   name: "Created Date",
   field: "createdDate",
   sortable: true
  },
  {
   name: "Action",
   field: "",
   sortable: false,

  },
 ];

 const commentsData = useMemo(() => {
  let computedComments = comments;

  if (search) {
   computedComments = computedComments.filter(comment => comment.comment.toLowerCase().includes(search.toLowerCase()));
  }
  setTotalItems(computedComments.length);

  // Sorting comments
  if (sorting.field) {
   const reversed = sorting.order === "asc" ? 1 : -1;
   computedComments = computedComments.sort((a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]));
  }
  // Current Page slice
  let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
  if (records.length === 0 && currentPage !== 1) {
   setCurrentPage(currentPage - 1);
  }
  return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
 }, [
  comments,
  currentPage,
  search,
  sorting,
  ITEMS_PER_PAGE
 ]);

 useEffect(() => {

  dispatch(retriveQA(0));
  const state1 = props.location.state
  if (state1 !== undefined && state1 !== null) {
   let Msg;
   let Type = 'success'; // default, info, warning
   let title = 'Success!';
   if (state1.message === 'Edit') {
    Msg = 'QA updated successfully.';
    // Type = 'default';
   } else if (state1.message === 'Add') {
    Msg = 'QA added successfully.';
   } else {
    Msg = 'Error occured';
    Type = 'warning';
    title = 'Error!';
   }
   window.history.replaceState(null, '')
   Nodify(title, Type, Msg);
  }
  var page = getPlaceholder;
  var setConstraint = SearchRetain(page);
  if (setConstraint !== undefined) {
   setCurrentPage(setConstraint.CurrentPage);
   setITEMS_PER_PAGE(setConstraint.PageItems);
  }

 }, [dispatch])

 useEffect(() => {
  if (!isLoadingQA) {
   hideLoader();
  } else {
   showLoader();
  }
 }, [isLoadingQA])

 const pageRedirect = (dataParams, redirect) => {

  if (redirect === 'Add') {
   props.history.push('/QACommentAdd', { params: [] });
  }
  else if (redirect === 'Edit') {
   props.history.push(`/QACommentEdit`, { params: dataParams });
  }
  else {
   let Params = {
    Operation: 75,
    Id: dataParams.commentID,
   }
   setID({ showPopupDelete: true, Params: dataParams });
  }
 }
 const Delcallback = (value) => {
  setID({ showPopupDelete: false, Params: [] });
 }




 return (
  <>

  <div className="page-body">
   <ReactNotification />
   <div className="row">
    <div className="col-lg-12 col-sm-12 col-xs-12">
     <div className="widget">
      <div className="widget-header ">
       <span className="widget-caption">QA List</span>
      </div>
      <div className="widget-body">
       <div id="simpledatatable_wrapper" className="dataTables_wrapper form-inline no-footer">
        <div className="table-toolbar pull-left">
         <Search onSearch={
          value => {
           setSearch(value);
           setCurrentPage(1);
          }
         }

                     placeholder={getPlaceholder} props={props} />
        </div>
        <div className="table-toolbar pull-right">

         &nbsp;
         <span className="btn btn-primary" onClick={() => pageRedirect('', 'Add')}>
          <i className="fa fa-plus"></i>&nbsp;Add
         </span>
        </div>

        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
         <TableHeader headers={headers}
          onSorting={
           (field, order) => setSorting({ field, order })
          } />
         <tbody>
          {
           commentsData.map(comment => (
            <tr key={
             comment.commentID
            }>
             <td>{
              comment.comment
             }</td>
             <td>{
              comment.createdDate
             }</td>
             <td>
              <span title='Edit QA' onClick={() => pageRedirect(comment, 'Edit', getPlaceholder)}
               className="btn btn-info btn-xs edit">
               <i className="fa fa-edit"></i>
              </span>
              &nbsp;
              <span title='Delete QA' onClick={() => pageRedirect(comment, '', getPlaceholder)}
               className="btn btn-danger btn-xs delete">
               <i className="fa fa-trash-o"></i>
              </span>
             </td>
            </tr>
           ))

          }
          {
           totalItems === 0 ?
            <tr>
             <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
            </tr> : ''
          }

         </tbody>
        </table>

        <PageCount onPageCount={
         value => {
          setITEMS_PER_PAGE(value);
          setCurrentPage(1);
         }
        } />
        <Pagination total={totalItems}
         itemsPerPage={
          parseInt(ITEMS_PER_PAGE)
         }
         currentPage={currentPage}
         onPageChange={
          page => setCurrentPage(page)
         } />

       </div>
      </div>
      {
       getID.showPopupDelete ? <SweetAlertPopup data={
        getID.Params
       }
        deleteCallback={Delcallback}
        showpopup={true}
                 pageActionId={commentID}
        Msg={"QA deleted successfully."} /> : null
      }
     </div>
    </div>
   </div>
  </div>

   </>
 )
}

export default QAList