import {
    MEASUREMENTPOINT_LOADING,
    RETRIEVE_MEASUREMENTPOINT,
    DELETE_MEASUREMENTPOINT,
} from "./types";

import MeasurementPointService from "../services/Master/MeasurementPointService";

const MeasurementpointLoading = (isStatus) => ({
    type: MEASUREMENTPOINT_LOADING,
    payload: isStatus,
});

export const deleteMeasurementpoint = (measurePointID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_MEASUREMENTPOINT,
            payload: measurePointID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrieveMeasurementpoint = () => async (dispatch) => {
    try {
        dispatch(MeasurementpointLoading(true));
        const res = await MeasurementPointService.GetMeasurementPointsList();
        dispatch({
            type: RETRIEVE_MEASUREMENTPOINT,
            payload: res.data,
        });
        dispatch(MeasurementpointLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(MeasurementpointLoading(false));
    }
};