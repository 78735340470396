import {
    STRIKEOFFTYPE_LOADING,
    RETRIEVE_STRIKEOFFTYPE,
    DELETE_STRIKEOFFTYPE,
} from "../actions/types";

const initialState = {
    isLoadingStrikeofftype: true,
    StrikeofftypeList: [],
};

const strikeoffTypeReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case STRIKEOFFTYPE_LOADING:
            return {
                ...state,
                isLoadingStrikeofftype: payload
            };
        case RETRIEVE_STRIKEOFFTYPE:
            return { ...state, StrikeofftypeList: payload };
        case DELETE_STRIKEOFFTYPE:
            const results = state.StrikeofftypeList.filter(c => c.strikeOffTypeID !== payload.strikeOffTypeID);
            return {
                ...state,
                StrikeofftypeList: results
            };

        default:
            return state;
    }
};
export default strikeoffTypeReducer;