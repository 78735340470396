import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { useSelector, useDispatch } from 'react-redux';
import Nodify from "../Common/ReactNotification";
import ReactNotification from 'react-notifications-component';
import { LoadCourierDetails } from '../../actions/courier';
import { InsertUpdateCourierDetails, ViewCourierStatus } from "../../actions/courier";
import moment from 'moment';
import useFullPageLoader from "../../hooks/useFullPageLoader";
import Reactselect from 'react-select';
import $ from "jquery";
import axios from "axios";
import Lightbox from "react-image-lightbox";
import DatePicker from "react-datepicker";
import { loadCubeBuyerSupplierEmp } from "../../actions/common";
const CourierTracker = (props) => {
    const CurrentUser = useSelector((state) => state.auth.user);
    let isLoadingCourier = useSelector((state) => state.courierReducer.isLoadingCourier);
    let AgencyList = useSelector((state) => state.courierReducer.AgencyList);

    const courierDeliverystatus = [
        { id: 1, value: 1, label: 'Pre transit'},
        { id: 2, value: 2, label: 'Shipment Picked Up'},
        { id: 3, value: 3, label: 'In transit'},
        { id: 4, value: 4, label: 'Delay in 12hrs'},
        { id: 5, value: 5, label: 'Delay in 24hrs'},
        { id: 6, value: 6, label: 'Delivered'},
        { id: 7, value: 7, label: 'Returned'}
       ];

    //   const cubeEmp = [
    //     { id: 1, value: 1, label: 'karthik' },
    //     { id: 2, value: 2, label: 'Rajesh' },
        
    //   ];

      let cubeEmp = useSelector((state) => state.common.CubeList);

      
    const dispatch = useDispatch();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [getHeaderName, addHeaderName] = useState("Add");
    const [getSubmitted, setSubmitted] = useState(false);
    const [getSubmittedMail, setSubmittedmail] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getstate_CourierID, setstate_CourierID] = useState(0);
    const [getIsView, setIsView] = useState(true);
    const [getBtnText, setBtnText] = useState("Save");
    const [getCourierStatus, setCourierStatus] = useState([]);
    const [getres, setres] = useState(0);
    const [getCourierAvailability, setCourierAvailability] = useState(false);
    const [getCourierEvents, setCourierEvents] = useState([]);
    const AgencyTypeRef = useRef(null);
    const [isOpenimg, setIsOpenimg] = useState(false);
    const [getFiles, setFiles] = useState([]);
    const [getImagename, setImagename] = useState();
    const [Buttonview, SetButtonview] = useState("Hide");
    const [getMailInfo, SetMailInfo] = useState([{CourierMailId :0, DeliveryStatus:'', DeliveryStatusID: [], CubeEmp:'', CubeEmpID: [] ,CourierId : 0, AddtionalEmail:''}]);
    let CubeList = useSelector((state) => state.common.CubeList);
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),
    }

    const [getCourierInfo, setCourierInfo] = useState([
        {
            Operation: 1,
            ID: 0,
            AWPNumber: '',
            AgencyID: 0,
            FromAddress: '',
            ToAddress: '',
            CreatedBy: CurrentUser.employeeinformationID,
            ModifiedBy: 0,
            Status: '',
            Comment,
            SendDate: '',
            CourierFileUploadInformation: [],
            CourierMailInformation: []
        }]
    );


    useEffect(() => {
        
        
             dispatch(loadCubeBuyerSupplierEmp(1))
            .then(data => {
        if (data) {
            if (data.length > 0) {
               

                    //let CubeEmployeeIdList = existList.CubeEmployeeIdList.split(',');
                    // let arrVal = [];
                    // for (let StrCubeEmployeeIdList of CubeEmployeeIdList) {
                    //     let res = data.filter(x => x.value === parseInt(StrCubeEmployeeIdList));
                    //     arrVal.push({
                    //         value: res[0].value,
                    //         label: res[0].label
                    //     });
                    // }
                    //getStyleList.arrCubeEmployeeID = arrVal;
                    //getStyleList.CubeEmployeeIdList = existList.CubeEmployeeIdList;

                    
                    if(props.location.state !== undefined)
                        {

                    if (courierDeliverystatus.length > 0) 
                        
                        {
                       
                        props.location.state.params.courierMailInformation.forEach(Mail => {
                            
                            
                            let DeliveryStatusID = Mail.DeliveryStatusID.split(',');
                            let arrVal = [];

                            for (let StrDeliveryStatusIDList of DeliveryStatusID) {
                                let res = courierDeliverystatus.filter(x => x.value === parseInt(StrDeliveryStatusIDList));
                                arrVal.push({
                                    value: res[0].value,
                                    label: res[0].label
                                });
                            }
                            //Mail.arrDeliveryStatusList = arrVal;
                            Mail.DeliveryStatus = Mail.DeliveryStatusID;
                            Mail.DeliveryStatusID = arrVal;
                            if(Mail.CubeEmpID !== '' && Mail.CubeEmpID !== null)
                            {
                            let CubeEmployee = Mail.CubeEmpID.split(',');
                            let EmployeearrVal = [];
                            debugger;
                            for (let StrCubeEmployeeList of CubeEmployee) {
                                let res = data.filter(x => x.value === parseInt(StrCubeEmployeeList));
                                EmployeearrVal.push({
                                    value: res[0].value,
                                    label: res[0].label
                                });
                            }
                           
                            Mail.CubeEmp = Mail.CubeEmpID;
                            Mail.CubeEmpID = EmployeearrVal;
                            }
                           
                           });

                        }

                       
                       if(props.location.state.params.courierMailInformation.length === 0)
                        { props.location.state.params.courierMailInformation = getMailInfo; }
                       SetMailInfo(props.location.state.params.courierMailInformation);
                    }


               
               
            
            }

        }
            })
           
        }, []); 
   

    const filevals = [...getFiles]
    let courierValues = [...getCourierInfo];
    function SetCourier(props) {
        let CourierValue = {
            Operation: 1,
            ID: 0,
            AWPNumber: '',
            FromAddress: '',
            ToAddress: '',
            CreatedBy: CurrentUser.employeeinformationID,
            ModifiedBy: 0,
            Status: '',
            Comment,
            CourierFileUploadInformation:[],
            CourierMailInformation: []
        };
        if (props.location.state !== undefined) {
            if (props.location.state.params.length !== 0) {
                if (props.location.state.params.id !== "") {
                    setCourierAvailability(false);
                    setstate_CourierID(props.location.state.params.id);
                    CourierValue.Operation = 2;
                    CourierValue.ID = props.location.state.params.id;
                    CourierValue.AWPNumber = props.location.state.params.awpNumber;
                    CourierValue.FromAddress = props.location.state.params.fromAddress;
                    CourierValue.ToAddress = props.location.state.params.toAddress;
                    CourierValue.CreatedBy = props.location.state.params.createdBy;
                    //CourierValue.SendDate = props.location.state.params.sendDate;
                  // Check and convert the sendDate to a valid JavaScript Date object
                   CourierValue.SendDate = props.location.state.params.sendDate === null ? null : new Date(props.location.state.params.sendDate);
                  // CourierValue.SendDate = props.location.state.params.sendDate
                  // ? moment(props.location.state.params.sendDate, 'DD/MM/YYYY').toDate()
                  // : null;
                   if (props.location.state.params.createdDate) {
                    const formattedDate = moment(props.location.state.params.createdDate).format('DD/MM/YYYY');
                    CourierValue.DisCreatedDate = formattedDate;
                    }
                    
                    CourierValue.ModifiedBy = CurrentUser.employeeinformationID;
                    //CourierValue.Status = 1;
                    CourierValue.Status = props.location.state.params.status;
                    CourierValue.Comment = props.location.state.params.comment;
                    CourierValue.AgencyID = props.location.state.params.agencyID;
                    //SetMailInfo(props.location.state.params.courierMailInformation);
                    //Delivery Status
                   
                      

                    setFiles(props.location.state.params.courierFileUploadInformation);
                    if (props.location.state.params.action === "View") {
                        setIsView(true);
                        addHeaderName("View");
                        setres(1);
                        dispatch(ViewCourierStatus(CourierValue.AWPNumber)).then((res) => {
                            if (res.data !== undefined) {
                                
                                setCourierStatus(res.data.shipments[0]);
                                setCourierEvents(res.data.shipments[0].events);
                                getCourierStatus.id === '' ? setCourierAvailability(false) : setCourierAvailability(true);
                                setres(0);
                            }
                        });
                    }
                    else if (props.location.state.params.action === "Edit") {
                        // dispatch(ViewCourierStatus(CourierValue.AWPNumber)).then((res) => {
                        //     if (res.data !== undefined) {
                        //         
                        //         if (res.data.shipments[0].status.status === "delivered") {
                        //             courierValues[0].Status = res.data.shipments[0].status.status;
                        //             setCourierInfo(courierValues);
                        //         }
                        //     }
                        // });
                        setIsView(false);
                        setBtnText("Update");
                        addHeaderName("Update");
                    }
                    return CourierValue;
                }
            }
        }
    }
    const CustomInput = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="MM/DD/YYYY"
            />
        )
    }
    useEffect(() => {
        setres(0);
    }, [getCourierStatus]);

    useEffect(() => {
        if (!isLoadingCourier) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingCourier])
    useEffect(() => {
        dispatch(LoadCourierDetails()).then((res) => {

        });
    }, [])
    const handleChange = (e) => {
        if (e.target.value.trim() != '') {
            if (e.target.name === "awpnumber") {
                courierValues[0].AWPNumber = e.target.value;
            }
            else if (e.target.name === "fromaddres") {
                courierValues[0].FromAddress = e.target.value;
            }
            else if (e.target.name === "toaddress") {
                courierValues[0].ToAddress = e.target.value;
            }
            else if(e.target.name === "comment") {
                courierValues[0].Comment = e.target.value;
            } 
            setCourierInfo(courierValues);
        }
        else {
            if (e.target.name === "awpnumber") {
                courierValues[0].AWPNumber = "";
            }
            else if (e.target.name === "fromaddres") {
                courierValues[0].FromAddress = "";
            }
            else if (e.target.name === "toaddress") {
                courierValues[0].ToAddress = "";
            }
            else if (e.target.name === "comment") {
                courierValues[0].Comment = "";
            }
            setCourierInfo(courierValues);
        }
    }
    const handleChangeSendDate = (e, FieldName) => {
        
        const updatedCourierInfo = [...getCourierInfo]; // Copy the current state
    
        if (FieldName === 'SendDate') {
            updatedCourierInfo[0].SendDate = e ? e : null; // Update SendDate for the first object
        }
    
        setCourierInfo(updatedCourierInfo); // Update the state with the new array
    };
    useEffect(() => {
        if (props.location.state !== undefined) {
            if (props.location.state.params.length !== 0) {
                if (props.location.state.params.id !== "") {
                    showLoader();
                    let getCourier = SetCourier(props);
                    setCourierInfo([getCourier]);
                    hideLoader();
                }
            }
        }
        else {
            setIsView(false);
        }
    }, [AgencyList]);

    //     const SaveCourierInfo = (e) => {
    // debugger
    //         let CourierFileUploadInformation = []
    //         getFiles.forEach((file) => {
    //             CourierFileUploadInformation.push({
    //                 CourierFileId: file.ExternalFileupID,
    //                 FileType: file.FileType,
    //                 FileName: file.FileName,
    //                 FilePath: file.Filepath
    //             });
    //         });
    //           setCourierInfo(prevState => [
    //         {
    //             ...prevState[0],
    //             AWPNumber: courierValues[0].AWPNumber,
    //             AgencyID: courierValues[0].AgencyID,
    //             FromAddress: courierValues[0].FromAddress,
    //             ToAddress: courierValues[0].ToAddress,
    //             Comment: courierValues[0].Comment,
    //             CourierFileUploadInformation,
    //         }
    //     ]);
    //         //setCourierInfo(CourierFileUploadInformation);
    //         setSubmitted(true);
    //         if (courierValues[0].AWPNumber.trim() === ''
    //             && courierValues[0].FromAddress.trim() === ''
    //             && courierValues[0].AgencyID === 0
    //             && courierValues[0].ToAddress.trim() === '' && courierValues[0].AWPNumber.trim().length === 10) {
    //             Nodify('Warning!', 'warning', "Please fill all mandatory (*) fields.");
    //         }
    //         else if (courierValues[0].AWPNumber.trim() === '') {
    //             Nodify('Warning!', 'warning', "AWB Number is required.");
    //         }
    //         else if (courierValues[0].FromAddress.trim() === '') {
    //             Nodify('Warning!', 'warning', "From Address is required.");
    //         }
    //         else if (courierValues[0].ToAddress.trim() === '') {
    //             Nodify('Warning!', 'warning', "To Address is required.");
    //         }
    //         else if (courierValues[0].AgencyID === 0) {
    //             Nodify('Warning!', 'warning', "Agency Name is required.");
    //         }
    //         else if (courierValues[0].Comment.trim() === '') {
    //             Nodify('Warning!', 'warning', "Comments is required.");
    //         }
    //         else if (courierValues[0].AWPNumber.trim() !== '' && courierValues[0].FromAddress.trim() !== '' && courierValues[0].ToAddress.trim() !== '' && courierValues[0].AWPNumber.trim().length !== 10) {
    //             Nodify('Warning!', 'warning', "AWB Number should be 10 digits long.");
    //         }
    //         if (courierValues[0].AWPNumber.trim() !== ''
    //             && courierValues[0].FromAddress.trim() !== ''
    //             && courierValues[0].AgencyID !== 0
    //             && courierValues[0].ToAddress.trim() !== ''
    //             && courierValues[0].Comment.trim() !== ''
    //             && courierValues[0].AWPNumber.trim().length === 10) {
    //             
    //             dispatch(InsertUpdateCourierDetails(getCourierInfo[0])).then((res) => {
    //                 if (res.data.outputResult == "1") {
    //                     Nodify('success!', 'success', 'Courier added');
    //                     setTimeout(() => {
    //                         props.history.push('/CourierList');
    //                     }, 500);
    //                 }
    //                 else if (res.data.outputResult == "2") {
    //                     Nodify('success!', 'success', 'Courier updated');
    //                     setTimeout(() => {
    //                         props.history.push('/CourierList');
    //                     }, 500);
    //                 }
    //                 else {
    //                     Nodify('Warning!', 'warning', 'Courier Failed.');
    //                 }
    //             });
    //         }
    //     }

    // const SaveCourierInfo = (e) => {
    //     
    //     // let CourierFileUploadInformation = [];
    //     // CourierFileUploadInformation = getFiles;
    //     // getFiles.forEach((file) => {
    //     //     CourierFileUploadInformation.push({
    //     //         CourierFileId: file.ExternalFileupID,
    //     //         FileType: file.FileType,
    //     //         FileName: file.FileName,
    //     //         FilePath: file.FilePath

    //     //     });
    //     // });

    //     setCourierInfo((prevState) => {
    //         return [
    //             {
    //                 ...prevState[0],
    //                 AWPNumber: courierValues[0].AWPNumber,
    //                 AgencyID: courierValues[0].AgencyID,
    //                 FromAddress: courierValues[0].FromAddress,
    //                 ToAddress: courierValues[0].ToAddress,
    //                 Comment: courierValues[0].Comment,

    //             }
    //         ];
    //     });

    //     setSubmitted(true);

    //     // Validate input fields
    //     if (
    //         courierValues[0].AWPNumber.trim() === '' ||
    //         courierValues[0].FromAddress.trim() === '' ||
    //         courierValues[0].AgencyID === 0 ||
    //         courierValues[0].ToAddress.trim() === '' ||
    //         courierValues[0].AWPNumber.trim().length !== 10
    //     ) {
    //         Nodify('Warning!', 'warning', "Please fill all mandatory (*) fields.");
    //         return;
    //     }

    //     // Access the updated state after the update
    //     setTimeout(() => {
    //         // Using the updated state value to dispatch
    //         const updatedCourierInfo = getCourierInfo[0];

    //         getFiles.forEach((file) => {
    //             updatedCourierInfo.CourierFileUploadInformation.push({
    //             CourierFileId: file.courierFileId,
    //             FileType: file.FileType,
    //             FileName: file.FileName,
    //             FilePath: file.FilePath
    //            });
    //         });


    //         if (updatedCourierInfo.CourierFileUploadInformation.length > 0) {
    //             dispatch(InsertUpdateCourierDetails(updatedCourierInfo)).then((res) => {
    //                 if (res.data.outputResult === "1") {
    //                     Nodify('success!', 'success', 'Courier added successfully');
    //                     setTimeout(() => {
    //                         props.history.push('/CourierList');
    //                     }, 500);
    //                 } else if (res.data.outputResult === "2") {
    //                     Nodify('success!', 'success', 'Courier updated successfully');
    //                     setTimeout(() => {
    //                         props.history.push('/CourierList');
    //                     }, 500);
    //                 } else {
    //                     Nodify('Warning!', 'warning', 'Courier Failed.');
    //                 }
    //             });
    //         } 
    //         // else {
    //         //     Nodify('Warning!', 'warning', 'Courier File Upload Information is missing.');
    //         // }
    //     }, 0);
    // };

    const SaveCourierInfo = (e) => {
        

        // // Prepare CourierFileUploadInformation array
        // let CourierFileUploadInformation = [];
        // getFiles.forEach((file) => {
        //     CourierFileUploadInformation.push({
        //         CourierFileId: file.ExternalFileupID,
        //         FileType: file.FileType,
        //         FileName: file.FileName,
        //         FilePath: file.FilePath
        //     });
        // });

        // Update the courier info state with file upload info
        const updatedCourierInfo = {
            ...getCourierInfo[0],
            AWPNumber: courierValues[0].AWPNumber,
            AgencyID: courierValues[0].AgencyID,
            FromAddress: courierValues[0].FromAddress,
            ToAddress: courierValues[0].ToAddress,
            Comment: courierValues[0].Comment,
            SendDate:courierValues[0].SendDate
            //CourierFileUploadInformation  // Add file upload information
        };

        // Update state synchronously with new courier info
        setCourierInfo([updatedCourierInfo]);

        // Set submitted state to true
        setSubmitted(true);

        // Validate input fields
        if (
            courierValues[0].AWPNumber.trim() === '' ||
            courierValues[0].FromAddress.trim() === '' ||
            courierValues[0].AgencyID === 0 ||
            courierValues[0].ToAddress.trim() === '' ||
            courierValues[0].SendDate === '' ||
            courierValues[0].AWPNumber.trim().length !== 10
        ) {
            Nodify('Warning!', 'warning', "Please fill all mandatory (*) fields.");
            return; // Stop function execution if validation fails
        }

        getFiles.forEach((file) => {
            updatedCourierInfo.CourierFileUploadInformation.push({
                CourierFileId: file.courierFileId,
                FileType: file.FileType,
                FileName: file.FileName,
                FilePath: file.FilePath
            });
        });

         
          getMailInfo.forEach(Mail => {
             if(Mail.CubeEmp !== '' || Mail.DeliveryStatus !== '')
             {
                updatedCourierInfo.CourierMailInformation.push({
                    CourierMailId:Mail.CourierMailId,
                    CourierId:Mail.CourierId,
                      CourierDeliveryID: Mail.DeliveryStatus,
                    CubeEmployee: Mail.CubeEmp,
                    AddtionalEmail: Mail.AddtionalEmail
                  });
             }
            
        });

        //   getMailInfo.forEach(Mail => {
           
        //     if(Mail.DeliveryStatusID !== 0 || Mail.CubeEmpID !== 0)
        //     {
        //         updatedCourierInfo.CourierMailInformation.push({
        //             CourierId:Mail.CourierId,
        //             CourierMailId:Mail.CourierMailId,
        //             CourierDeliveryID: Mail.DeliveryStatusID,
        //             CubeEmployee: Mail.CubeEmpID.toString(),
        //           });
        //     }
            
        // });

        
        //updatedCourierInfo.MailInfo = getMailInfo;
        
        dispatch(InsertUpdateCourierDetails(updatedCourierInfo)).then((res) => {
            if (res.data.outputResult === "1") {
                Nodify('success!', 'success', 'Courier added successfully');
                setTimeout(() => {
                    props.history.push('/CourierList');
                }, 500);
            } else if (res.data.outputResult === "2") {
                Nodify('success!', 'success', 'Courier updated successfully');
                setTimeout(() => {
                    props.history.push('/CourierList');
                }, 500);
            } else {
                Nodify('Warning!', 'warning', 'Courier Failed.');
            }
        });

        // // Now that the state is updated, you can directly access the updated state
        // if (updatedCourierInfo.CourierFileUploadInformation.length > 0) {
        //     // Dispatch action to save or update courier details
        //     dispatch(InsertUpdateCourierDetails(updatedCourierInfo)).then((res) => {
        //         if (res.data.outputResult === "1") {
        //             Nodify('success!', 'success', 'Courier added successfully');
        //             setTimeout(() => {
        //                 props.history.push('/CourierList');
        //             }, 500);
        //         } else if (res.data.outputResult === "2") {
        //             Nodify('success!', 'success', 'Courier updated successfully');
        //             setTimeout(() => {
        //                 props.history.push('/CourierList');
        //             }, 500);
        //         } else {
        //             Nodify('Warning!', 'warning', 'Courier Failed.');
        //         }
        //     });
        // } else {
        //     Nodify('Warning!', 'warning', 'Courier File Upload Information is missing.');
        // }
    };



    const [getTaskIndexFileupload, setTaskIndexFileupload] = useState(0);

    const AppendFiles = async e => {
        
        const file = e.target.files[0];
        // let a = getTaskIndexFileupload;
        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword', "application/vnd.ms-excel",
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#FileUpload').val("");
            }
            else {
                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "Courier");
                try {
                    //const valuess = [...getFiles]
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                    filevals.push({
                        //ExternalFileupID: 0,
                        FileName: res.data + "_" + file.name,
                        FilePath: "Images/Courier/" + res.data + "_" + file.name,
                        FileType: file.type,
                        IsDeleted: 0,
                        CourierIndexName: getTaskIndexFileupload.toString(),
                        CourierFileIndexName: getTaskIndexFileupload.toString(),
                    })
                    setTaskIndexFileupload(parseInt(getTaskIndexFileupload) + 1);
                    setFiles(filevals);
                    $('#FileUpload').val("");
                } catch (ex) {
                    console.log(ex);
                }
            }
        }
    }

    const ResetOperation = (value) => {
        if (value === "Back") {
            props.history.push('/CourierList');
        } else {
            // props.history.push('/BuyerRegister')
            window.location.reload();
        }
    }

    const handleAgencyTypeChange = (e) => {
        if (e !== null) {
            courierValues[0].AgencyID = e.id;
        }
        else {
            courierValues[0].AgencyID = 0;
        }
        setCourierInfo(courierValues);
    }
    const FocusAgencyType = () => {
        AgencyTypeRef.current.focus();
    }

    const ViewFile = (index, filename) => {
        setImagename(filename);
        setIsOpenimg(true);
    }

    const RemoveImages = (index) => {
        const filesvalue = [...getFiles]
        filesvalue.splice(index, 1);
        setFiles(filesvalue);
    
    }
    const RemoveFiles = async (e, index) => {
        const filesvalue = [...getFiles]
        filesvalue.splice(index, 1);
        setFiles(filesvalue);
    }

    const handleRemoveFields = index => {
        
        const values = [...getMailInfo];
        values.splice(index, 1);
        SetMailInfo(values);
    };

    const handleAddFields = (index) => {
       
        const values = [...getMailInfo];
        if (values[index].DeliveryStatusID.length !== 0 && values[index].CubeEmpID.length !== 0) {
            values.push({ CourierMailId :0, DeliveryStatusID: [],  CubeEmpID: [] , CourierId : 0 ,AddtionalEmail:''});
             SetMailInfo(values);
             setSubmittedmail(false);
        }
        else {
            Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
            setSubmittedmail(true);
        }
    };

    const handleInputChange = (index, event, FieldName) => {
        const values = [...getMailInfo];
       if ( FieldName === 'DeliveryStatus' ) 
        {
        if (event !== null)
            {
             let strValue = '';
             for (let Emp of event) {
                 if (strValue === '') { strValue = Emp.value.toString() }
                 else { strValue = strValue + "," + Emp.value }
             }
             values[index]['DeliveryStatusID'] = event;
             values[index][FieldName] = strValue;
            }
            else
            {
             values[index][FieldName] = 0;
            }
        }
         else if (FieldName === 'CubeEmp') {
        if (event !== null)
           {
            let strValue = '';
            for (let Emp of event) {
                if (strValue === '') { strValue = Emp.value.toString() }
                else { strValue = strValue + "," + Emp.value }
            }
            values[index]['CubeEmpID'] = event;
            values[index][FieldName] = strValue;
           }
           else
           {
            values[index][FieldName] = 0;
           }
        }
        // else if(FieldName ==='AddtionalEmail')
        // {
        //     if (event.target.value.trim() !== '') {
                
        //         values[index][FieldName] = event.target.value;
        //     }
        //  }
        else if (FieldName === 'AddtionalEmail') {
            // Update the AddtionalEmail directly from the textarea
            values[index][FieldName] = event.target.value;
        } 
        SetMailInfo(values);
    };

    return (
        <>
            <div className="page-body">
            <div className="col-md-12">
                    <div className="tabbable">
                    {
                                            getHeaderName === "Update" &&
                                       
                                        <Row>
                                        <Col md={2}>
                                                <div className='form-group'>
                                                    <label htmlFor="txtFromAddres">Entry No</label>
                                                    <span className='input-icon icon-right'>
                                                    {courierValues[0].ID}
                                                    </span>
                                                </div>
                                         </Col>
                                        <Col md={2}>    
                                                <div className='form-group'>
                                                    <label htmlFor="txtFromAddres">Created Date</label>
                                                    <span className='input-icon icon-right'>
                                                    {courierValues[0].DisCreatedDate} 
                                                    </span>
                                                </div>
                                        </Col>
                                        <Col md={2}>
                                                <div className='form-group'>
                                                    <label htmlFor="txtFromAddres">Awb No</label>
                                                    <span className='input-icon icon-right'>
                                                    {courierValues[0].AWPNumber}
                                                    </span>
                                                </div>
                                        </Col>
                                        <Col md={2}>
                                                <div className='form-group'>
                                                    <label htmlFor="txtFromAddres">Current Status</label>
                                                    <span className='input-icon icon-right'>
                                                    {courierValues[0].Status}
                                                    </span>
                                                </div>
                                        </Col>
                                        </Row>
                                        }
                                             &nbsp;

                        <ul className="nav nav-tabs nav-justified" id="myTab5">
                            <li className="active" >
                                <a data-toggle="tab" href="#idCompanyAddress">
                                    <h5> {getHeaderName} Courier Info</h5>
                                </a>
                            </li>
                            <li className="">
                                <a data-toggle="tab" href="#idCompanyEmployee">
                                    <h5>Mail Notification</h5>
                                </a>
                            </li>
                            
                        </ul>

                        <div className="tab-content">
                            <div id="idCompanyAddress" className="tab-pane in active" style={{ width: "100%" }}>

                            <div className="row">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <div className="widget flat radius-bordered">
                            {/* <div className="widget-header heading_box_style">
                                <h3 className="heading_style_common">{getHeaderName} Courier</h3>
                                {getIsView && <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                    <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                                </span>}
                            </div> */}
                            <div className="widget-body">
                                <div id="courier-form">
                                    <Form>
                                       
                                        <Row>
                                            <ReactNotification />
                                            <Col md={2}>
                                                <div className='form-group'>
                                                    <label htmlFor="txtFromAddres">Sender<span className="text-danger">*</span></label>
                                                    <span className='input-icon icon-right'>
                                                        <Input placeholder="Enter From Address"
                                                            id="txtFromAddres"
                                                            name="fromaddres"
                                                            type="text"
                                                            autoComplete="off"
                                                            maxLength="50"
                                                            onChange={handleChange}
                                                            className='form-control'
                                                            value={courierValues[0].FromAddress}
                                                            style=
                                                            {{border: getSubmitted && courierValues[0].FromAddress === '' ? '1px solid red' : '' }}
                                                        />
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col md={2}>
                                                <div className='form-group'>
                                                    <label htmlFor="txtToAddres">Receiver<span className="text-danger">*</span></label>
                                                    <span className='input-icon icon-right'>
                                                        <Input placeholder="Enter To Address"
                                                            id="txtToAddres"
                                                            name="toaddress"
                                                            type="text"
                                                            autoComplete="off"
                                                            maxLength="50"
                                                            className='form-control'
                                                            onChange={handleChange}
                                                            value={courierValues[0].ToAddress}
                                                            style={
                                                                {
                                                                    border: getSubmitted && courierValues[0].ToAddress === '' ? '1px solid red' : ''
                                                                }
                                                            }
                                                        />
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col md={2}>
                                            <div className='form-group'>
        <label htmlFor="txtToAddres">Sent Date<span className="text-danger">*</span></label>
        {/* <span className='input-icon icon-right'>
            <DatePicker
                className="form-control"
                name="SendDate"
                id="SendDate"
                selected={courierValues[0].SendDate}
               // selected={courierValues[0].SendDate ? new Date(courierValues[0].SendDate) : null} // Ensure SendDate is a valid Date object
                onChange={event => handleChangeSendDate(event, "SendDate")}
                style={
                    {
                        border: getSubmitted && courierValues[0].SendDate === '' ? '1px solid red' : ''
                    }
                }
                dateFormat="dd/MM/yyyy"
                peekNextMonth
                dropdownMode="scroll"
                autoComplete="off"
                customInput={<CustomInput />}
            />
        </span> */}

<span className="input-icon icon-right"
 style={{
    border: getSubmitted && !courierValues[0].SendDate ? '1px solid red' : ''
}}

>
    <DatePicker
        className="form-control"
        name="SendDate"
        id="SendDate"
        selected={courierValues[0].SendDate ? new Date(courierValues[0].SendDate) : null} // Ensure SendDate is a valid Date object
        onChange={(event) => handleChangeSendDate(event, "SendDate")}
        style={{
            border: getSubmitted && !courierValues[0].SendDate ? '1px solid red' : ''
        }} // Highlight red if getSubmitted is true and SendDate is empty
        dateFormat="dd/MM/yyyy"
        peekNextMonth
        dropdownMode="scroll"
        autoComplete="off"
        customInput={<CustomInput />}
    />
</span>
    </div>
                                            </Col>
                                            <Col md={2}>
                                                <div className='form-Agency'>
                                                    <label htmlFor="Agency" onClick={FocusAgencyType}>Agency<span className="text-danger">*</span></label>
                                                    <span className='input-icon icon-right'>
                                                        <Reactselect className="basic-single" name="AgencyID"
                                                            id={
                                                                "Agency"
                                                            }
                                                            ref={AgencyTypeRef}
                                                            isDisabled={false}
                                                            isLoading={false}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            options={AgencyList}
                                                            value={AgencyList.filter(function (option) {
                                                                return option.value === courierValues[0].AgencyID;
                                                            })}
                                                            onChange={event => handleAgencyTypeChange(event)}
                                                            styles={getSubmitted && courierValues[0].AgencyID === 0 ? styles : ''}
                                                        ></Reactselect>
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col md={2}>
                                                <div className='form-group'>
                                                    <label htmlFor="txtAWPNumber">AWB Number<span className="text-danger">*</span></label>
                                                    <span className='input-icon icon-right'>
                                                        <Input placeholder="Enter AWB Number"
                                                            id="txtAWPNumber"
                                                            name="awpnumber"
                                                            type="text"
                                                            autoComplete="off"
                                                            maxLength="50"
                                                            autoFocus
                                                            className='form-control'
                                                            onChange={handleChange}
                                                            value={courierValues[0].AWPNumber}
                                                            //  styles={getSubmitted && courierValues[0].AWPNumber === '' ? styles : ''}
                                                            style={
                                                                {
                                                                    border: getSubmitted && (courierValues[0].AWPNumber === '' || (courierValues[0].AWPNumber !== '' && courierValues[0].AWPNumber.length !== 10)) ? '1px solid red' : ''
                                                                }
                                                            }
                                                        />
                                                    </span>
                                                </div>
                                            </Col>


                                        </Row>
                                        <Row>
                                            <Col md={8}>
                                                <div className='form-group' >
                                                    <label htmlFor="txtFindingsOrRemarks">Comments</label>
                                                    <span className='input-icon icon-right'>
                                                        <textarea className='form-control' style={{ minHeight: '109px' }}
                                                            value={getCourierInfo[0].Comment} onChange={handleChange}
                                                            id="comment" rows="10" placeholder="Enter Comments"
                                                            name="comment"
                                                            autoComplete="off" maxLength="100"></textarea>
                                                    </span>
                                                </div>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <div className="widget-header" style={{ paddingLeft: 0, background: 'none' }}>
                                                <span className="widget-caption" style={{ fontSize: '14px' }}>
                                                    File Upload
                                                    <span style={{ color: 'red', fontSize: '14px' }}></span>
                                                </span>
                                            </div>
                                            <div className="form-group">
                                                <input type="file" id="FileUpload"
                                                    //disabled={getFiles.length >= 5 ? true : false}//disabled={getFiles.length === 1 ? true : false}
                                                    onChange={(e) => AppendFiles(e)} accept=".jpg,.jpeg,.png,.pdf,.xlsx,.doc,.docx,.xls"
                                                // style={{
                                                //     ...(submitted && getFiles.length === 0 ? 
                                                //         { border: '1px solid red', height: '40px' } : {}),
                                                // }}
                                                ></input>
                                            </div>



                                            {
                                                <div className="col-lg-12 col-sm-12">
                                                    <div className="row no-margin">
                                                        {
                                                            getFiles.length !== 0 ?
                                                                getFiles.map((files, fileindex) => (
                                                                    files.FileType === "image/jpeg" || files.FileType === "image/jpg" || files.FileType === "image/png" ||
                                                                        files.FileType === "image/gif" ?
                                                                        <div className="float-Left_washcare file_Image_uploads">
                                                                            {/* <div className="col-sm-3"> */}
                                                                            <div className="client_tumblineWashcare">

                                                                                {files.FileName !== '' ? <img className="width_manual1"
                                                                                    src={window.$APIBaseURL + files.FilePath}
                                                                                //onClick={() => setIsOpenimg(window.$APIBaseURL + 'Images/Style/WashCare/' + getImagename)}
                                                                                /> : ''}

                                                                                <table className="table_thum">
                                                                                    <tr className="table_rwo">
                                                                                        {/* <td title={files.FileName.length > 32 ? files.FileName.substr(33) : files.FileName.substr(5)} className="bold_text width_20 sku_textoverflow">
                                                                                                                    {files.FileName.length > 32 ? files.FileName.substr(33) : files.FileName.substr(5)}
                                                                                                                </td> */}
                                                                                    </tr>
                                                                                </table>

                                                                                <a className="washcareView" title='View image' onClick={() => ViewFile(fileindex, files.FilePath)}>
                                                                                    <i className="btn btn-info fa fa-eye" ></i>
                                                                                </a>&nbsp;
                                                                                <a title='Remove image' onClick={() => RemoveImages(fileindex)}>
                                                                                    <i className="btn btn-danger fa fa-trash-o"></i>
                                                                                </a>

                                                                            </div>
                                                                            {/* </div> */}
                                                                            {

                                                                                < div > {
                                                                                    isOpenimg && (

                                                                                        <Lightbox mainSrc={window.$APIBaseURL + getImagename}
                                                                                            alt="bg image"
                                                                                            onCloseRequest={
                                                                                                () => setIsOpenimg(false)
                                                                                            } />
                                                                                    )
                                                                                } </div>
                                                                            }
                                                                        </div> : ''
                                                                ))
                                                                : ''
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            &nbsp;
                                            &nbsp;
                                            {

                                                <div className="col-lg-12 col-sm-12">
                                                    <div className="row no-margin">
                                                        {
                                                            getFiles.length !== 0 ?
                                                                getFiles.map((files, fileindex) => (
                                                                    files.FileType === "application/pdf" || files.FileType === "application/msword" || files.FileType === "application/vnd.ms-excel"
                                                                        || files.FileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || files.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ?
                                                                        <div className="col-lg-4 col-sm-4 file_list_upload">
                                                                            <tr title={files.FileName.split('_')[1] ? files.FileName.split('_')[1] : files.FileName}>
                                                                                {files.FileName !== '' ? <td className="cut_textInTNA">
                                                                                    <span style={{ marginRight: '6px' }}>
                                                                                        {
                                                                                            files.FileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                files.FileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                                    <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                    files.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                        files.FileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                        <i class="fa fa-picture-o fa-lg image_icon_style"></i>

                                                                                        }
                                                                                        {
                                                                                            files.FileType === "image/jpeg" || files.FileType === "image/jpg" || files.FileType === "image/png" ||
                                                                                                files.FileType === "image/gif" ? <i class="fa fa-picture-o fa-lg image_icon_style"></i> : ''
                                                                                        }
                                                                                    </span>  {files.FileName.split('_')[1] ? files.FileName.split('_')[1] : files.FileName}
                                                                                </td> : ''}

                                                                                <td className="button_style_uploda">
                                                                                    {Buttonview !== "View" && 

                                                                                

                                                                                        // <p title='Delete File'
                                                                                        //     style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}
                                                                                        //     onClick={(e) => RemoveFiles(e, fileindex)}
                                                                                        //     className="btn btn-danger btn-xs delete">
                                                                                        //     <i className="fa fa-trash-o"></i>

                                                                                        // </p>

                                                                                        <p title='Delete File' onClick={(e) => RemoveFiles(e, fileindex)}
                                                                                        className="btn btn-danger btn-xs delete">
                                                                                        <i className="fa fa-trash-o"></i>

                                                                                    </p>
                                                                                    }
                                                                                    &nbsp;
                                                                                    {
                                                                                        files.FileType === "application/pdf" || files.FileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                                                                                            files.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                                                            || files.FileType === 'application/msword' || files.FileType === "application/vnd.ms-excel" ?
                                                                                            // <a type="button" style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}
                                                                                            //     title="Download" class="btn btn-xs btn-primary" target="_blank"
                                                                                            //     href={window.$APIBaseURL + files.FilePath} download><i class="fa fa-download"></i> </a> : ''

                                                                                            <a type="button"
                                                                                            title="Download" class="btn btn-xs btn-primary" target="_blank"
                                                                                            href={window.$APIBaseURL + files.FilePath} download><i class="fa fa-download"></i> </a> : ''
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        </div> : ''
                                                                ))
                                                                : ''
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </Row>

                                        {getres === 0 && getIsView &&
                                            <Row className='margin-top-20 margin-bottom-20'>
                                                <Col md={1}></Col>
                                                <Col md={10}>
                                                    <div className='CourierContainerOne'>
                                                        <span className='CourierContainerOneText'>Tracking Code: {getCourierStatus.id}</span><br />
                                                        {getCourierStatus.length != 0 && <span className='CourierContainerOneText'>Status:&nbsp;<span className='font-bold'>{getCourierStatus.status.statusCode}</span>&nbsp;&nbsp;
                                                            <span className='Eventfont-bold'>{getCourierStatus.status.statusCode.toLowerCase() == "delivered" && <i className='fa-lg fa fa-check-circle'></i>}</span>
                                                            <span className='Eventfonttransit-bold'>{getCourierStatus.status.statusCode.toLowerCase() == "transit" && <i className='fa-lg fa fa-plane'></i>}</span>
                                                            <span className='Eventfontintransit-bold'>{getCourierStatus.status.statusCode.toLowerCase() == "intransit" && <i className='fa-lg fa fa-send'></i>}</span>
                                                        </span>}
                                                    </div>
                                                    <div className='CourierContainerOne'>
                                                        {getCourierStatus.length != 0 && <span className='CourierContainerOneText font-bold'><i className='fa fa-map-marker fa-lg'></i>&nbsp;{getCourierStatus.status.location.address.addressLocality}</span>}<br />
                                                        {getCourierStatus.length != 0 && <span className='CourierContainerOneText font-bold'>{moment(new Date(getCourierStatus.status.timestamp)).format("DD/MM/YYYY HH:MM:A")}</span>}<br />
                                                        {/* <span className='CourierContainerOneText'>02. May 2022 05:33 Local time, ONTARIO SERVICE AREA, ON - CANADA</span> */}
                                                        <br />
                                                        <div className='CourierProcessFlow'>
                                                            {getCourierStatus.length != 0 && <span className='font-bold'>Service From: {getCourierStatus.origin.address.addressLocality}</span>}<br />
                                                            {/* <div className="block"></div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <div className="block"></div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <div className="block"></div> */}
                                                            <div className="row">
                                                                <div className="col-md-12 hh-grayBox pt45 pb20">
                                                                    <div className="row justify-content-between">
                                                                        <div className="order-tracking completed col-md-4">
                                                                            <span className="is-complete"></span>
                                                                            <p>{getCourierStatus.length !== 0 && <span>{getCourierStatus.events[getCourierStatus.events.length - 1].location.address.addressLocality}</span>}<br />
                                                                                <span>{getCourierStatus.length !== 0 && <span> {moment(new Date(getCourierStatus.events[getCourierStatus.events.length - 1].timestamp)).format("DD/MM/YYYY HH:MM:A")}</span>}</span></p>

                                                                        </div>
                                                                        <div className="order-tracking completed col-md-4">
                                                                            <span className="is-complete"></span>
                                                                            {/* <p>{getCourierStatus.length !== 0 && <span>{getCourierStatus.status.location.address.addressLocality}</span>}<br />
                                                                                <span>{getCourierStatus.length !== 0 && <span> {moment(new Date(getCourierStatus.status.timestamp)).format("DD/MM/YYYY HH:MM:A")}</span>}</span></p> */}
                                                                        </div>
                                                                        <div className={getCourierStatus.length !== 0 && getCourierStatus.status.statusCode === "delivered" ? "order-tracking completed col-md-4" : "order-tracking  col-md-4"}>
                                                                            <span className="is-complete"></span>
                                                                            <p>{getCourierStatus.length !== 0 && <span>{getCourierStatus.destination.address.addressLocality}</span>}<br />
                                                                                <span>{getCourierStatus.length !== 0 && getCourierStatus.status.statusCode === "delivered" && <span> {moment(new Date(getCourierStatus.status.timestamp)).format("DD/MM/YYYY HH:MM:A")}</span>}</span><br />
                                                                                <span>{getCourierStatus.length !== 0 && getCourierStatus.status.statusCode === "delivered" && <span className='Eventfont-bold'> {getCourierStatus.status.statusCode}</span>}</span></p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {getCourierStatus.length != 0 && <span className='font-bold pull-right'>Service To: {getCourierStatus.destination.address.addressLocality}</span>}<br />
                                                        </div>
                                                    </div>
                                                    <div className='CourierContainerOne'>
                                                        <span className=' CourierProcessFlow font-bold'>More Shipment Details</span><br />
                                                        <Row className='CourierProcessFlow'>
                                                            <Col md={12} className=" pieceIDBG">
                                                                <Col md={6}>
                                                                    <span> Piece ID</span>
                                                                </Col>

                                                                <Col md={6}>
                                                                    {getCourierStatus.length != 0 && <span>
                                                                        {getCourierStatus.details.pieceIds}</span>}
                                                                </Col>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <div className='CourierContainerTwo'>
                                                        <span className=' CourierProcessFlow font-bold'>All Shipment Updates</span><br />
                                                        <table border="0" className='table table-bordered table-hover margin-top-20' style={{ backgroundColor: '#ddd0d0' }}>

                                                            <tbody>
                                                                {
                                                                    getCourierStatus.length != 0 && getCourierEvents.map(event => (
                                                                        <>
                                                                            <tr>
                                                                                <td colSpan="1">
                                                                                    {moment(new Date(event.timestamp)).format("DD/MM/YYYY HH:MM:A")}
                                                                                </td>
                                                                                <td colSpan="5">
                                                                                    <p className='Eventfont-bold'>{event.description}</p><br />
                                                                                    <span>{event.location.address.addressLocality}</span>
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                        {/* <table border="0" className='table table-bordered table-hover margin-top-20'>
                                                            <thead>
                                                                <tr>
                                                                    <th colSpan="5">Courier Details</th>
                                                                    <th >Status :{getCourierStatus.length != 0 && <span className='CourierCurrentStatus'>&nbsp;{getCourierStatus.status.status}</span>} </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Origin:{getCourierStatus.length != 0 && <span className='CourierStatus'>&nbsp;{getCourierStatus.origin.address.addressLocality}</span>} </td>
                                                                    <td>Destination:{getCourierStatus.length != 0 && <span className='CourierStatus'>&nbsp;{getCourierStatus.destination.address.addressLocality}</span>}</td>
                                                                    <td>Number of Pieces: :{getCourierStatus.length != 0 && <span className='CourierStatus'>&nbsp;{getCourierStatus.details.totalNumberOfPieces}</span>}</td>
                                                                    <td>Piece ID: {getCourierStatus.length != 0 && <span className='CourierStatus'>&nbsp;{getCourierStatus.details.pieceIds}</span>}</td>
                                                                    <td colSpan="2">AWP Number: {getCourierStatus.length != 0 && <span className='CourierStatus'>&nbsp;{getCourierStatus.id}</span>}</td>
                                                                </tr>
                                                                <tr >
                                                                    <td colSpan="6">
                                                                        Current Loctaion :{getCourierStatus.length != 0 && <span className='CourierCurrentStatus'>&nbsp;{getCourierStatus.status.location.address.addressLocality}</span>}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th colSpan="1">Date</th>
                                                                    <th colSpan="3">Location</th>
                                                                    <th colSpan="2">Activity</th>
                                                                </tr>
                                                                {
                                                                    getCourierStatus.length != 0 && getCourierEvents.map(event => (
                                                                        <>

                                                                            <tr>
                                                                                <td colSpan="1">{moment(new Date(event.timestamp)).format("DD/MM/YYYY HH:MM:A")}</td>
                                                                                <td colSpan="3">{event.location.address.addressLocality}</td>
                                                                                <td colSpan="2">{event.description}</td>
                                                                            </tr>
                                                                        </>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table> */}
                                                    </div>

                                                </Col>
                                            </Row>

                                        }
                                        {!getCourierAvailability && getIsView &&
                                            <Row className='margin-top-20 margin-bottom-20 text-center'>
                                                <span className='alert alert-warning '>
                                                    Sorry, your tracking attempt was not successful. Please check your tracking number.
                                                </span>
                                            </Row>
                                        }
                                    </Form>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                            </div>
                            
                            
                            <div id="idCompanyEmployee" className="tab-pane" style={{ width: "100%" }}> 
                            <div className="widget-body">
                            <h5 class="WarningMsg">NOTE : You entered emails in the format: abc@gmail.com, def@gmail.com Custom Mail Receptionist</h5>
                            <div id="registration-form">
                                <form>
                                    <div>
 {/* Static Column Headers */}
 <div className="row static-column-header">
        <div className="col-sm-3" style={{paddingLeft:'32px'}}>
            <h4>Status</h4>
        </div>
        <div className="col-sm-3" style={{paddingLeft:'22px'}}>
            {/* <h4>Primary Mail Receptionlist</h4> */}
            <h4> Primary Mail Receptionist</h4>
           
        </div>
        <div className="col-sm-3" style={{paddingLeft:'22px'}}>
            <h4>Custom Mail Receptionist</h4>
        </div>
        
    </div>
                                    </div>
                                    {
                                        
                                        getMailInfo.map((inputField, index) => (
                                            <Fragment key={`${inputField}~${index}`}>
                                                <div>
   

    {/* Rows of Data */}
    <div className="row row_left10">
        <div className="col-sm-3 divder_style">
            <div className="form-group">
                <Reactselect
                    className="basic-single"
                    name="DeliveryStatusID"
                    id={"DeliveryStatus"}
                    ref={AgencyTypeRef}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isSearchable={true}
                    options={courierDeliverystatus}
                    isMulti
                    value={inputField.DeliveryStatusID}
                    // value={courierDeliverystatus.filter(function (option) {
                    //     return option.value === inputField.DeliveryStatusID;
                    // })}
                    onChange={(event) => handleInputChange(index, event, "DeliveryStatus")}
                    styles={getSubmittedMail && inputField.DeliveryStatusID.length === 0 ? styles : ''}
                    autoFocus
                />
            </div>
        </div>
        <div className="col-sm-3 divder_style">
            <div className="form-group">
                <Reactselect
                    className="basic-single"
                    name="CubeEmpID"
                    id={"CubeEmp"}
                    ref={AgencyTypeRef}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isSearchable={true}
                    options={cubeEmp}
                    isMulti
                    value={inputField.CubeEmpID}
                    // value={cubeEmp.filter(function (option) {
                    //     return option.value === inputField.CubeEmpID;
                    // })}
                    onChange={(event) => handleInputChange(index, event, "CubeEmp")}
                    styles={getSubmittedMail && inputField.CubeEmpID.length === 0 ? styles : ''}
                />
            </div>
        </div>

        <div className="col-sm-3 divder_style">
            <div className="form-group">
            <span className='input-icon icon-right'>
            <textarea className='form-control' style={{ minHeight: '40px' }}
             id="comment" rows="2" placeholder="Enter Comments"
             name="comment"
             value={inputField.AddtionalEmail}
             autoComplete="off" 
             onChange={(event) => handleInputChange(index, event, "AddtionalEmail")}
             maxLength="100"></textarea>
                                                                    {/* <Input placeholder="Additional Email"
                                                                        id="AddtionalEmail" name="AddtionalEmail"
                                                                        value={inputField.AddtionalEmail}
                                                                        onChange={(event) => handleInputChange(index, event, "AddtionalEmail")}
                                                                        //onChange={event => handleChange(event, 'IDPoNo')}
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        maxLength="50"
                                                                        
                                                                        className={'form-control'}
                                                                    /> */}

                                                                </span>
            </div>
        </div>

        <div className="col-sm-3 paddingstatic">
            {
                getMailInfo.length !== 1 && (
                    <div className="col-lg-1_own col-xs-12">
                        <button type="button" className="btn btn-danger" title="Delete Currency" onClick={() => handleRemoveFields(index)}>
                            <i className="fa fa-trash-o"></i>
                        </button>
                    </div>
                )
            }
            {
                getMailInfo.length === (index + 1) && (
                    <div className="col-lg-1_own col-xs-12">
                        <button type="button" className="btn btn-success" title="Add Currency" onClick={() => handleAddFields(index)}>
                            <i className="fa fa-plus"></i>
                        </button>
                    </div>
                )
            }
        </div>
    </div>
</div>

                                            </Fragment>
                                        ))
                                    }
                                   
                                </form>
                            </div>
                        </div>

                            </div>
                           
                            </div>

                        </div></div>
               
               
            </div>
           
           
           
            <div className="row">
                <div className="col-sm-12">
                    <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>
                        <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                            <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                        </span>
                        {!getIsView && <span className="btn btn-danger" onClick={() => ResetOperation()}>
                            <i className="fa fa-times"></i>&nbsp;Reset
                        </span>}
                        {!getIsView && <button type="submit" className="btn btn-success" onClick={SaveCourierInfo}>
                            <i className="fa fa-check right"></i> &nbsp;{getBtnText}
                        </button>}
                    </div>
                </div>

            </div>
            {loader}
        </>
    )
}

export default CourierTracker;