import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState,Fragment } from 'react';
import { LoadAgencyDetails } from './../../../../actions/agency';
import ReactNotification from 'react-notifications-component';
import SweetAlertPopup from '../../../Common/SweetAlertPopup';
import { TableHeader, Pagination, Search, PageCount } from "./../../../Datatable";
import Nodify from "../../../Common/ReactNotification";
import SearchRetain from "../../../Common/SearchRetain";
import { AgencyID } from "../../../Common/PageActionNumber";
import useFullPageLoader from "../../../hooks/useFullPageLoader";

const AgencyList = (props) => {
    const dispatch = useDispatch();
    const [totalItems, setTotalItems] = useState(0);
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [currentPage, setCurrentPage] = useState(1);
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    const [search, setSearch] = useState("");
    const [getPlaceholder] = useState("Agency Name / Agency Type");
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [getID, setID] = useState({ showPopupDelete: false, Params: {} });
    const AgencyGridFromState = useSelector((state) => state.agencyReducer.AgencyList);
    const isLoadAgency = useSelector((state) => state.agencyReducer.isLoadingAgency);
    let activeMenu;
    const menulist = useSelector((state) => state.common.MenuList.item3);
    if (menulist !== undefined) { activeMenu = menulist.filter(i => i.menuName === "Agency"); }

    const
        headers = [
            {
                name: "#",
                field: "",
                sortable: false
            },
            {
                name: "Agency Name",
                field: "agencyName",
                sortable: true
            },
            {
                name: "Agency Type",
                field: "agencyTypeName",
                sortable: true
            },
            {
                name: "Action",
                field: "",
                sortable: false,
                display: activeMenu && activeMenu[0].isEdit === 0 &&
                    activeMenu[0].isDelete === 0 ? 'none' : ''
            }
        ];

    useEffect(() => {
        dispatch(LoadAgencyDetails());
        const state1 = props.location.state;
        if (state1 !== undefined && state1 !== null) {
            let Msg;
            let Type = 'success'; // default, info, warning
            let title = 'Success!';
            if (state1.message === 'Edit') {
                Msg = 'Agency updated successfully.';
            }
            else if (state1.message === 'View') {
            }
            else if (state1.message === 'ADDED') {
                Msg = 'Agency added successfully.';
            }
            else if (state1.message === 'ALREADY REGISTERED') {
                Msg = 'Agency name already registered.';
            }
            else {
                Msg = 'Error occured';
                Type = 'warning';
                title = 'Error!';
            }
            window.history.replaceState(null, '')
            if (state1.message !== 'View') {
                Nodify(title, Type, Msg);
            }
            var page = getPlaceholder;
            var setConstraint = SearchRetain(page);
            if (setConstraint !== undefined) {
                setCurrentPage(setConstraint.CurrentPage);
                setITEMS_PER_PAGE(setConstraint.PageItems);
            }
        }
    }, []);

    useEffect(() => {
        if (!isLoadAgency) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadAgency]);

    const AgencyListData = useMemo(() => {
        let AgencyGridData = AgencyGridFromState;
        if (search) {
            AgencyGridData = AgencyGridData.filter(comment =>
                comment.agencyName.toLowerCase().includes(search.toLowerCase())
                || comment.agencyTypeName.toLowerCase().includes(search.toLowerCase()));
        }
        setTotalItems(AgencyGridData.length);
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            AgencyGridData = AgencyGridData.sort((a, b) => {
              const valueA = a[sorting.field];
              const valueB = b[sorting.field];
          
              // Check if both values are numeric
              const isNumericA = !isNaN(valueA);
              const isNumericB = !isNaN(valueB);
          
              if (isNumericA && isNumericB) {
                // If both values are numeric, compare as numbers
                return reversed * (parseFloat(valueA) - parseFloat(valueB));
              } else {
                // If one or both values are non-numeric, compare as strings
                return reversed * valueA.toString().localeCompare(valueB.toString());
              }
            });
          }
          
        let records = AgencyGridData.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
        if (records.length === 0 && currentPage != 1) {
            setCurrentPage(currentPage - 1);
        }
        return AgencyGridData.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
    }, [AgencyGridFromState, currentPage,
        search,
        sorting,
        ITEMS_PER_PAGE,
        currentPage,]);

    const pageRedirect = (agencyParams, redirect, page) => {
        localStorage.setItem('Pagination', JSON.stringify({ CurrentPage: currentPage, Page: page, PageItems: ITEMS_PER_PAGE }));
        if (redirect === 'Add') {
            props.history.push(`/AgencyRegister`);
        }
        else if (redirect === 'Edit') {
            props.history.push(`/EditAgency`, { params: agencyParams });
        }
        else {
            setID({ showPopupDelete: true, Params: agencyParams });
        }
    };

    const Delcallback = (value) => {
        setID({ showPopupDelete: false, Params: [] });
    }

    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className="widget-header">
                    <span className="widget-caption">Agency List</span>
                </div>
                <div className="widget-body" style={
                    { paddingBottom: '4%' }
                }>
                    <div className="dataTables_wrapper form-inline no-footer">
                        <div className="table-toolbar pull-left">
                            <Search
                                onSearch={
                                    value => {
                                        setSearch(value);
                                        setCurrentPage(1);
                                    }
                                }
                                placeholder={getPlaceholder} props={props} />
                        </div>
                        <div className="table-toolbar pull-right">
                            &nbsp;
                            <span
                                className="btn btn-primary" title='Add Agency' style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }} onClick={() => pageRedirect("", 'Add')}>
                                <i className="fa fa-plus"></i>&nbsp;Add
                            </span>
                        </div>
                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                            <TableHeader headers={headers} onSorting={
                                (field, order) => setSorting({ field, order })
                            } />
                            <tbody>
                                {
                                    AgencyListData.map((agency,i) => (
                                        <Fragment key={i}>
                                            <>
                                                <tr>
                                                    <td style={{width:'25px'}}>  
                                                        { i + 1}
                                                    </td>
                                                    <td>{agency.agencyName}</td>
                                                    <td>{agency.agencyTypeName}</td>
                                                    <td style={{
                                                        display: activeMenu && activeMenu[0].isEdit === 0 &&
                                                            activeMenu[0].isDelete === 0 ? 'none' : ''
                                                    }}>
                                                        <span style={{ display: activeMenu && activeMenu[0].isEdit === 1 ? '' : 'none' }} title='Edit Agency'
                                                            className="btn btn-success btn-xs edit" onClick={() => pageRedirect(agency, 'Edit', getPlaceholder)}>
                                                            <i className="fa fa-edit "></i>
                                                        </span>&nbsp;
                                                        <span style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }} title='Delete Agency' onClick={() => pageRedirect(agency, '', getPlaceholder)}
                                                            className="btn btn-danger btn-xs edit">
                                                            <i className="fa fa-trash "></i>
                                                        </span>&nbsp;
                                                    </td>
                                                </tr>
                                            </>
                                        </Fragment>
                                    ))
                                }
                                {
                                    totalItems === 0 ?
                                        <tr>
                                            <td colSpan="3" className='norecordfound'><span>No Records Found</span></td>
                                        </tr> : ''
                                }
                            </tbody>
                        </table>
                        <PageCount onPageCount={
                            value => {
                                setITEMS_PER_PAGE(value);
                                setCurrentPage(1);
                            }
                        } />
                        <Pagination total={totalItems}
                            itemsPerPage={
                                parseInt(ITEMS_PER_PAGE)
                            }
                            currentPage={currentPage}
                            onPageChange={
                                page => setCurrentPage(page)
                            } />
                    </div>
                </div>
                {
                    getID.showPopupDelete ? <SweetAlertPopup data={
                        getID.Params
                    }
                        deleteCallback={Delcallback}
                        showpopup={true}
                        pageActionId={AgencyID}
                        Msg={"Agency deleted successfully."} /> : null
                }
            </div>
            {loader}
        </>
    )
}
export default AgencyList;