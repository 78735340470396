import axios from "axios";
import authHeader from "../auth-header";

class QAServices {

 getAllQACommentList() {
  return axios.get(window.$APIBaseURL + "Master/getAllQACommentList?CommentID=0", {
   headers: {
    authorization: authHeader()
   }
  });
 }

 InsertUpdateQAComment(Params) {
  
  return axios({
   method: 'post',
   url: window.$APIBaseURL + 'Master/InsertUpdateQAComment',
   data: Params,
   headers: {
    authorization: authHeader()
   }
  });
 }

 AddQAComment(Params) {
  return axios({
   method: 'post',
   url: window.$APIBaseURL + 'Master/InsertUpdateDelQAComment',
   data: Params,
   headers: {
    authorization: authHeader()
   }
  });
 }




}


export default new QAServices()