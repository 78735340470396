import React, { useState, useEffect, Fragment } from "react";
import ReactNotification from 'react-notifications-component';
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import 'react-notifications-component/dist/theme.css';
import Select from "react-validation/build/select";
import Reactselect from 'react-select';
import strikeoffService from "../../../services/Style/StrikeoffService";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import Nodify from "../../Common/ReactNotification";
import SearchRetain from "../../Common/SearchRetain";
import Lightbox from "react-image-lightbox";
import TrimService from "../../../services/Style/TrimService";
import { Modal, Button } from "react-bootstrap";
import CollapsiblePanel from "../../Common/CollapseExpandPanel";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import $ from "jquery";
import DatePicker from "react-datepicker";
import { getTNATrimAlreadyMapList, updateTrimMapList } from "../../../actions/style";
import CommonService from '../../../services/Common/CommonService';

const PickFromMaster = (props) => {
    let Style_Id = 0;
    let Buyer_Id = 0;
    let Brand_Id = 0;
    let SaveInfo = [];

    if (props.location.state !== undefined) {
        Style_Id = props.location.state.params.styleID;
        Buyer_Id = props.location.state.params.buyerID;
        Brand_Id = props.location.state.params.brandID;
        SaveInfo = props.location.state.params.SaveInfo;
    }

    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item5);
    if (menulist !== undefined) { activeMenu = menulist.filter(i => i.menuName === "Styletrim"); }

    // End Enable Menu
    const EmptyInputFields =
    {
        StyleTrimInfoID: 0,
        TrimInfoID: 0,
        StyleTrimInfoName: '',
        StyleTrimInfoCode: '',
        StyleTrimInfoApprovalType: 0,
        StyleTrimInfofRemarks: '',
        LogoList: [],
        RefList: [],
        RefMappedList: [],
        MapColorSKUList: [],
        IsDeleted: 0,
        IsApprovalType: '0',
        Manual: 0,
        IsMapforTest: 0,
        IsManual: [{ StyleStrikeOffOrTrimManualId: 0, StrikeoffOrTrimInfoId: 0, ManualCloseComment: '', ManualCloseDate: '', StyleStrikeOffOrTrimIndexName: '', Files: [] }],
        ExitingApprovalTypePop: 0,
        TrimSubmissionApprovalID: 0,
        SelectedMapRow: []
    }
    const { user: currentUser } = useSelector((state) => state.auth);
    const reducerState = useSelector((state) => state.style);
    const TNATrimMapList = reducerState.TNATrimMapList;

    const dispatch = useDispatch();
    const [inputFields, setInputFields] = useState(SaveInfo === "Add" ? [EmptyInputFields] : []);
    const [getAllBrandList, setAllBrandList] = useState([]);
    const [getApprovalList, setApprovalList] = useState([]);
    const [getTrimList, setTrimList] = useState([]);
    const [getSKUList, setSKUList] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [isOpen, setIsOpen] = useState(false)
    const [comments, setComments] = useState([]);
    const [getPopupValue, setPopupValue] = useState([{ Notes: '', TargettoClose: '', CompletedOn: '', RemindMe: '' }]);
    const [getShow, setShow] = useState(false);
    const [collapse, setCollapse] = useState(false);
    const [title, setTitle] = useState("Collapse All");
    const [icon, setIcon] = useState("fa fa-chevron-down");
    const [Buttonview, SetButtonview] = useState("Hide");
    const [getFiles, setFiles] = useState([]);
    const [isOpenimg, setIsOpenimg] = useState(false);
    const [getImagename, setImagename] = useState();
    const [getPopUpValidation, setPopUpValidation] = useState(false);
    const [manualsubmit, setmanualsubmit] = useState(false);
    const [newFilestate, setNewFileState] = useState([])
    const [selected, setselected] = useState();
    const collapseAll = () => {
        setCollapse(!collapse);
        setIcon(state => {
            return state === "fa fa-chevron-right"
                ? "fa fa-chevron-down"
                : "fa fa-chevron-right";
        });
        setTitle(state => {
            return state === "Expand All" ? "Collapse All" : "Expand All";
        });
    };

    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),
    }

    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const selectAllOption = {
        value: "-1",
        label: "Select All Sizes"
    };
    const values = [...inputFields];

    const ShowCritical = (notes, targettoclose, completedon, remindme) => {
        setShow(true);
        getPopupValue[0].Notes = notes;
        getPopupValue[0].TargettoClose = targettoclose;
        getPopupValue[0].CompletedOn = completedon;
        getPopupValue[0].RemindMe = remindme;
        setPopupValue(getPopupValue);
    }
    const handleClose = () => {
        setShow(false);
    };


    useEffect(() => {
        TrimService.LoadTrimBuyerBrandList(Buyer_Id, Brand_Id).then((response) => {
            if (response.data) {
                setAllBrandList(response.data);
            }
        }).catch(() => { });

        strikeoffService.LoadApprovalTypeList().then((response) => {
            if (response.data) {
                setApprovalList(response.data);
            }
        }).catch(() => { });

        TrimService.GetTrimInformationList(0).then((response) => {
            if (response.data) {

                setTrimList(response.data);
            }
        }).catch(() => { });

        strikeoffService.LoadSkuNameList(Style_Id).then((response) => {
            if (response.data) {
                setSKUList(response.data);
            }
        }).catch(() => { });

        TrimService.GetTrimPrintColorList(0).then((response) => {
            if (response.data) {

                setComments(response.data);
            }
        }).catch(() => { });

    }, []);

    useEffect(() => {
        if (SaveInfo === "Edit") {
            showLoader();
            TrimService.GetStyleTrimInfoTabList(Style_Id).then((response) => {
                if (response.data) {
                    if (response.data.trimList) {
                        response.data.trimList.forEach((trim, i) => {
                            inputFields.push({
                                StyleTrimInfoID: trim.styleTrimInfoID,
                                TrimInfoID: trim.trimInfoID,
                                StyleTrimInfoName: trim.trimName,
                                StyleTrimInfoCode: trim.trimCode,
                                StyleTrimInfoApprovalType: trim.approvalType,
                                StyleTrimInfofRemarks: trim.remarks,
                                PurchaseType: trim.purchaseType,
                                TechnicalSpecification: trim.technicalSpecification,
                                TrimSupplierName: trim.trimSupplierName,
                                PurchaseTypeName: trim.purchaseTypeName,
                                IsMapforTest: trim.isMapforTest,
                                LogoList: [],
                                RefList: [],
                                RefMappedList: [],
                                MapColorSKUList: [],
                                IsDeleted: 0,
                                IsApprovalType: trim.purchaseType === 3 ? "1" : '0',
                                Manual: 0,
                                IsManual: [],
                                ExitingApprovalTypePop: 0,
                                TrimSubmissionApprovalID: trim.trimSubmissionApprovalID,
                                SelectedMapRow: []
                            });

                            if (response.data.manual.length !== 0) {
                                response.data.manual.forEach((s, j) => {
                                    if (s.trimInfoId === trim.trimInfoID) {
                                        inputFields[i].IsManual.push({
                                            StyleStrikeOffOrTrimManualId: s.styleTrimManualId, StrikeoffOrTrimInfoId: s.strikeoffOrTrimInfoId,
                                            ManualCloseComment: s.manualCloseComment, ManualCloseDate: new Date(s.manualCloseDate),
                                            StyleStrikeOffOrTrimIndexName: s.styleTrimIndexName, Files: []
                                        });


                                        if (response.data.manualFiles.length !== 0) {
                                            response.data.manualFiles.forEach((fil, f) => {
                                                if (fil.trimInfoId === s.trimInfoId) {
                                                    inputFields[i].IsManual[0].Files[f] = fil;
                                                }
                                            })

                                        }
                                    }
                                })

                            }
                            else {

                                inputFields[i].IsManual.push({
                                    StyleStrikeOffOrTrimManualId: 0, StrikeoffOrTrimInfoId: 0, ManualCloseComment: '', ManualCloseDate: '', StyleStrikeOffOrTrimIndexName: '', Files: []
                                });
                            }

                            if (response.data.commonApproval.length !== 0) {
                                response.data.commonApproval.forEach((s, j) => {
                                    if (s.styleTrimInfoID === trim.styleTrimInfoID) {
                                        inputFields[i].SelectedMapRow.push({
                                            seasonName: s.seasonName, approvalDate: new Date(s.approvalDate),
                                            details: s.details, approvedBy: s.approvedBy,
                                            commonApprovalId: s.commonApprovalId, isSelected: 1

                                        });
                                        setselected(s.commonApprovalId);
                                    }
                                })

                            }
                            else {

                                inputFields[i].SelectedMapRow.push({
                                    SeasonName: '', ApprovalDate: '', Details: '', ApprovedBy: 0, CommonApprovalId: 0, isSelected: 0
                                });


                            }


                        })
                        // let tempindex = 0;
                        // inputFields.map((v, vi) => {
                        //     v.IsManual[0].Files.map((x, indexs) => {
                        //         inputFields[vi].IsManual[0].Files[indexs].ManualIndex = tempindex;
                        //         tempindex++;
                        //     })
                        // })
                    }
                    if (response.data.referenceList) {
                        response.data.referenceList.forEach((ref, i) => {
                            inputFields.forEach((trimInfo, j) => {
                                if (parseInt(trimInfo.TrimInfoID) === parseInt(ref.trimInfoID)) {
                                    if (ref.sizeID !== 0) {
                                        inputFields[j].RefList.push(ref);
                                        inputFields[j].RefMappedList.push({
                                            StyleTrimReferenceID: ref.styleTrimReferenceID,
                                            TrimInfoID: ref.trimInfoID,
                                            TrimReferenceID: ref.trimReferenceID,
                                            IsTrimStyleChecked: ref.isTrimStyleChecked,
                                            IsDeleted: 0,
                                        })
                                    }
                                }
                            });
                        });
                    }

                    if (response.data.logoInfoList) {
                        response.data.logoInfoList.forEach((color, i) => {
                            inputFields.forEach((trimInfo, j) => {
                                if (parseInt(trimInfo.TrimInfoID) === parseInt(color.trimInfoID)) {
                                    inputFields[j].LogoList.push(color);

                                    let arr = color.styleTrimSku.split(',');
                                    let arrVal = [];
                                    if (arr[0] !== '') {
                                        for (let size of arr) {
                                            let res = props.location.state.params.SKUList.filter(x => x.value === parseInt(size));
                                            if (res.length !== 0) {
                                                arrVal.push({
                                                    value: res[0].value,
                                                    label: res[0].label
                                                });
                                            }
                                        }
                                    }

                                    inputFields[j].MapColorSKUList.push({
                                        StyleTrimLogoInfoID: color.styleTrimLogoInfoID,
                                        TrimInfoID: color.trimInfoID,
                                        TrimInfoLogoInfoID: color.trimLogoInfoID,
                                        StyleTrimSku: color.styleTrimSku,
                                        IsDeleted: 0,
                                        ArrSKUIds: arrVal,
                                    })
                                }
                            });
                        });
                    }

                    setTimeout(() => {
                        setInputFields(inputFields);
                        hideLoader();
                    }, 900);
                }
            }).catch(() => { });
        }
    }, []);


    const handleSelectedChange = (event, index) => {
        setSubmitted(false);
        if (event !== null) {
            let ValueExist = values.filter(x => x.TrimInfoID === parseInt(event.value) && x.IsDeleted === 0);
            if (ValueExist.length === 0) {
                values[index].LogoList = [];
                values[index].MapColorSKUList = [];
                values[index].RefMappedList = [];
                values[index].RefList = [];
                //   showLoader();

                values[index].TrimInfoID = event.value;
                let respected_Trim = getTrimList.filter(x => x.trimInfoID === parseInt(event.value));
                values[index].StyleTrimInfoName = respected_Trim[0].trimName;
                values[index].StyleTrimInfoCode = respected_Trim[0].trimCode;
                values[index].IsApprovalType = respected_Trim[0].purchaseType === 3 ? "1" : "0";
                values[index].PurchaseType = respected_Trim[0].purchaseType;
                values[index].TechnicalSpecification = respected_Trim[0].technicalSpecification;
                values[index].TrimSupplierName = respected_Trim[0].trimSupplierName;
                values[index].PurchaseTypeName = respected_Trim[0].purchaseTypeName;
                values[index].StyleTrimInfoApprovalType = respected_Trim[0].approvalType;

                TrimService.GetTrimLogoInformationList(parseInt(event.value)).then((response) => {
                    if (response.data) {
                        let returnList = response.data.filter(x => x.trimLogoName !== null);

                        values[index].LogoList = (returnList);
                        returnList.forEach(element => {
                            values[index].MapColorSKUList.push({
                                StyleTrimLogoInfoID: 0,
                                TrimInfoID: element.trimInfoID,
                                TrimInfoLogoInfoID: element.trimLogoInfoID,
                                StyleTrimSku: '',
                                IsDeleted: 0,
                                ArrSKUIds: '',
                            })
                        });

                        TrimService.GetTrimReferenceList(parseInt(event.value)).then((response) => {
                            if (response.data) {
                                values[index].RefList = (response.data);
                                response.data.forEach(element => {
                                    values[index].RefMappedList.push({
                                        StyleTrimReferenceID: 0,
                                        TrimInfoID: element.trimInfoID,
                                        TrimReferenceID: element.trimReferenceID,
                                        IsTrimStyleChecked: 0,
                                        IsDeleted: 0,
                                    })
                                });

                                setInputFields(values);
                            }
                        }).catch(() => { });
                    }
                }).catch(() => { });
            }
            else {
                Nodify('Warning!', 'warning', "Trim already selected.");
            }

        }
        else {
            values[index].TrimInfoID = 0;
            values[index].StyleTrimInfoName = '';
            values[index].StyleTrimInfoCode = '';
            values[index].TechnicalSpecification = '';
            values[index].TrimSupplierName = '';
            values[index].PurchaseTypeName = '';
            values[index].IsApprovalType = '0';
            values[index].StyleTrimInfoApprovalType = 0;
            values[index].LogoList = [];
            values[index].MapColorSKUList = [];
            values[index].RefMappedList = [];
            values[index].RefList = [];
            setInputFields(values);
        }
    }


    const PageRedirect = (Func) => {
        props.location.state.params.TrimFromMaster = Func;
        props.history.push('/StyleListRegister', { params: props.location.state.params });
    }

    const ResetOperation = (e) => {
        window.location.reload();
    }

    const SaveTrim = (e) => {
        e.preventDefault();
        setSubmitted(false);
        setButtonLoader(true);
        let IsValid = true;
        let ManualFilled = true;
        let OperationValue = 1;
        if (SaveInfo === "Edit") {
            OperationValue = 2;
        }

        let saveParams = {
            Operation: OperationValue,
            StyleID: Style_Id,
            StyleTrimDetails: [],
            StyleTrimRefernceDetails: [],
            StyleTrimLogoInformation: [],
            StyleProgramManualInformation: [],
            StyleProgramManualFileInformation: [],
            Createdby: currentUser.employeeinformationID,
        };

        let StyleTrimInfoDetails = [];
        let StyleTrimInfoRefernceDetails = [];
        let StyleTrimInfoLogoInformation = [];
        let StyleProgramManualInformation = [];
        let StyleProgramManualFileInformation = [];

        inputFields.forEach((Info, i) => {
            if ((Info.TrimInfoID !== 0) || Info.IsDeleted === 1) {
                let IsApproved = 0;
                if (Info.IsApprovalType === "1") {
                    if (Info.StyleTrimInfoApprovalType === 0) {
                        IsApproved = 1;
                    }
                }
                if (IsApproved === 0) {
                    StyleTrimInfoDetails.push({
                        StyleTrimInfoID: Info.StyleTrimInfoID,
                        TrimInfoID: Info.TrimInfoID,
                        StyleTrimInfoName: Info.StyleTrimInfoName,
                        StyleTrimInfoCode: Info.StyleTrimInfoCode,
                        StyleTrimInfoApprovalType: Info.StyleTrimInfoApprovalType,
                        StyleTrimInfofRemarks: Info.StyleTrimInfofRemarks,
                        IsDeleted: Info.IsDeleted,
                        TrimSubmissionApprovalID: Info.TrimSubmissionApprovalID,
                        IsMapforTest: Info.IsMapforTest
                    })
                    if (Info.StyleTrimInfoApprovalType === 2 && Info.IsManual[0].ManualCloseComment === '') {
                        ManualFilled = false;
                    }
                    if (Info.MapColorSKUList) {
                        Info.MapColorSKUList.forEach((colors, i) => {
                            StyleTrimInfoLogoInformation.push({
                                StyleTrimLogoInfoID: colors.StyleTrimLogoInfoID,
                                TrimInfoID: colors.TrimInfoID,
                                TrimInfoLogoInfoID: colors.TrimInfoLogoInfoID,
                                StyleTrimSku: colors.StyleTrimSku,
                                IsDeleted: colors.IsDeleted
                            });
                        });
                    }
                    if (Info.RefMappedList) {
                        Info.RefMappedList.forEach((refs, i) => {
                            StyleTrimInfoRefernceDetails.push({
                                StyleTrimReferenceID: refs.StyleTrimReferenceID,
                                TrimInfoID: refs.TrimInfoID,
                                TrimReferenceID: refs.TrimReferenceID,
                                IsTrimStyleChecked: refs.IsTrimStyleChecked,
                                IsDeleted: refs.IsDeleted,
                            });
                        });
                    }
                    if (Info.IsManual) {
                        Info.IsManual.forEach((man, m) => {
                            StyleProgramManualInformation.push({
                                StyleStrikeOffOrTrimManualId: man.StyleStrikeOffOrTrimManualId,
                                StrikeoffOrTrimInfoId: Info.TrimInfoID,
                                ManualCloseDate: man.ManualCloseDate,
                                ManualCloseComment: man.ManualCloseComment,
                                StyleStrikeOffOrTrimIndexName: i.toString(),
                                IsDeleted: man.ManualCloseComment !== '' ? 0 : 1
                            })

                            if (man.Files && man.Files.length !== 0) {
                                man.Files.forEach((f, k) => {

                                    StyleProgramManualFileInformation.push({
                                        StyleStrikeOffOrTrimManualFilelId: f.styleTrimManualFilelId,
                                        StrikeoffOrTrimInfoId: Info.TrimInfoID,
                                        FileType: f.fileType,
                                        FileName: f.fileName,
                                        FilePath: f.filePath,
                                        StyleStrikeOffOrTrimIndexName: i.toString(),
                                        StyleStrikeOffOrTrimFileIndexName: f.styleTrimFileIndexName,
                                        IsDeleted: f.IsDeleted
                                    })
                                })
                                // StyleProgramManualFileInformation = man.Files
                            }
                        })
                    }
                }
                else {
                    IsValid = false;

                }
            }
            else {
                IsValid = false;

            }
        });
        if (IsValid == false) {
            Nodify('Warning!', 'warning', "Please fill the mandatory(*) fields.");
            setSubmitted(true);
            setButtonLoader(false);
            return false;
        }
        if (ManualFilled === false) {
            Nodify('Warning!', 'warning', "Manual data should be fill when approval type is Manual.");
            setSubmitted(true);
            setButtonLoader(false);
            return false;
        }
        saveParams.StyleTrimDetails = StyleTrimInfoDetails;
        saveParams.StyleTrimRefernceDetails = StyleTrimInfoRefernceDetails;
        saveParams.StyleTrimLogoInformation = StyleTrimInfoLogoInformation;
        saveParams.StyleProgramManualInformation = StyleProgramManualInformation;
        saveParams.StyleProgramManualFileInformation = StyleProgramManualFileInformation;

        if (IsValid) {
            TrimService.InsertUpdateStyleTrimDetails(saveParams).then(res => {
                var page = "Remove";
                SearchRetain(page);
                let Func = 'TrimPickFromMaster1';
                if (res.data.outputResult === "1") {
                    ValidationPopup('success', "Trim details added successfully.");
                    Func = 'TrimPickFromMaster1';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "2") {
                    ValidationPopup('success', "Trim details updated successfully.");
                    Func = 'TrimPickFromMaster2';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "-2") {
                    setButtonLoader(false);
                    ValidationPopup('warning', "Trim already exists!");
                }
                else if (res.data.outputResult === "-1") {
                    setButtonLoader(false);
                    ValidationPopup('warning', "Error Occured during save!");
                }
                else if (res.data.outputResult === "0") {
                    setButtonLoader(false);
                    ValidationPopup('warning', "Error Occured!");
                }
            });
        }
    }


    const handleChange = (event, index, FieldName) => {
        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }
        if (FieldName === "StyleTrimInfoApprovalType") {
            values[index][FieldName] = parseInt(inputText);
            if (event.target.value !== '2') {
                if (values[index].IsManual && values[index].IsManual[0]) {
                    values[index].IsManual[0].ManualCloseComment = '';
                    values[index].IsManual[0].IsSaved = 0;
                    values[index].IsManual[0].Files = [];
                    // values[index].IsManual[0].Files.map((element, i) => {
                    //     values[index].IsManual[0].Files[i].fileName = '';
                    // });
                    values[index].IsManual[0].IsDeleted = 1;
                }
            }
            else {
                if (event.target.value === '2') {
                    if (values[index].IsManual !== undefined && values[index].IsManual.length !== 0) {
                        values[index].IsManual[0].IsDeleted = 0;
                    }
                }
            }
        }
        else {
            values[index][FieldName] = inputText;
        }
        if (values[index].IsManual.length === 0) {
            values[index].IsManual.push({
                StyleStrikeOffOrTrimManualId: 0, StrikeoffOrTrimInfoId: 0, ManualCloseComment: '',
                ManualCloseDate: '', StyleStrikeOffOrTrimIndexName: (index + 1).toString(), Files: []
            })
        }
        setInputFields(values);
    }

    const ClearRefInfo = (index, refindex) => {
        values[index].RefMappedList[refindex].IsTrimStyleChecked = 0;
        setInputFields(values);
    }

    const ClearColorInfo = (index, colorindex) => {
        values[index].MapColorSKUList[colorindex].StyleTrimSku = '';
        values[index].MapColorSKUList[colorindex].ArrSKUIds = '';
        setInputFields(values);
    }

    const handleRemoveFields = async (index) => {
        if (values[index].StyleTrimInfoID !== 0) {
            let Params = {
                Operation: 26,
                Id: values[index].LogoList[0].styleTrimLogoInfoID,
            };
            await CommonService.CheckMappedItem(Params).then(res => {
                if (res.data.outputResult === "-2") {
                    let Title = "Warning!";
                    let message = "This Trim is already mapped.";
                    let type = "warning";

                    Nodify(Title, type, message);
                }
                else {
                    //values.splice(index, 1);
                    values[index].IsDeleted = 1;
                    values[index].RefMappedList.forEach((element, i) => {
                        values[index].RefMappedList[i].IsDeleted = 1;
                    });
                    values[index].MapColorSKUList.forEach((element, i) => {
                        values[index].MapColorSKUList[i].IsDeleted = 1;
                    });
                    values[index].IsManual.forEach((element, i) => {
                        element.IsDeleted = 1;
                        element.Files.forEach((element, j) => {
                            values[index].IsManual[i].Files[j].IsDeleted = 1;
                        });
                    });
                }
                setInputFields(values);
            });
        } else {
            values[index].IsDeleted = 1;
            values[index].RefMappedList.forEach((element, i) => {
                values[index].RefMappedList[i].IsDeleted = 1;
            });
            values[index].MapColorSKUList.forEach((element, i) => {
                values[index].MapColorSKUList[i].IsDeleted = 1;
            });
            values[index].IsManual.forEach((element, i) => {
                element.IsDeleted = 1;
                element.Files.forEach((element, j) => {
                    values[index].IsManual[i].Files[j].IsDeleted = 1;
                });
            });

        }
        setInputFields(values);
    }

    const handleAddFields = (index) => {
        var CheckValue1 = [...inputFields].filter(x => x.IsDeleted === 0);
        var CheckValue = CheckValue1[index];
        if (CheckValue.TrimInfoID !== 0) {
            let IsApproved = 0;
            if (CheckValue.IsApprovalType === "1") {
                if (CheckValue.StyleTrimInfoApprovalType === 0) {
                    IsApproved = 1;
                }
            }
            if (IsApproved === 0) {
                values.push({
                    StyleTrimInfoID: 0,
                    TrimInfoID: 0,
                    StyleTrimInfoName: '',
                    StyleTrimInfoCode: '',
                    StyleTrimInfoApprovalType: 0,
                    StyleTrimInfofRemarks: '',
                    LogoList: [],
                    RefList: [],
                    RefMappedList: [],
                    MapColorSKUList: [],
                    IsDeleted: 0,
                    IsApprovalType: '0',
                    Manual: 0,
                    IsManual: [{
                        StyleStrikeOffOrTrimManualId: 0, StrikeoffOrTrimInfoId: 0, ManualCloseComment: '', IsDeleted: 0,
                        ManualCloseDate: '', StyleStrikeOffOrTrimIndexName: (index + 1).toString(), Files: []
                    }],
                    TrimSubmissionApprovalID: 0,
                    SelectedMapRow: []

                })
                setInputFields(values);
            }
            else {
                Nodify('Warning!', 'warning', 'Please fill this trim mandatory(*) fields.');
                setSubmitted(true);
            }
        }
        else {
            Nodify('Warning!', 'warning', 'Please fill this trim mandatory(*) fields.');
            setSubmitted(true);
        }
    }

    const handleColorChange = (event, index, colorindex) => {
        setSubmitted(false);
        if (event.length === 0) {
            values[index].MapColorSKUList[colorindex].StyleTrimSku = '';
            values[index].MapColorSKUList[colorindex].ArrSKUIds = '';
        }
        else {
            values[index].MapColorSKUList[colorindex].ArrSKUIds = event;
            let strValue = '';
            for (let size of event) {
                if (strValue === '') { strValue = size.value.toString() }
                else { strValue = strValue + "," + size.value }
            }
            values[index].MapColorSKUList[colorindex].StyleTrimSku = strValue;
        }
        setInputFields(values);
    }

    const handleRefChange = (e, index, refindex) => {
        setSubmitted(false);
        if (e.target.type === 'checkbox') {
            if (!e.target.checked) {
                values[index].RefMappedList[refindex].IsTrimStyleChecked = 0;
            } else {
                values[index].RefMappedList[refindex].IsTrimStyleChecked = 1;
            }
            setInputFields(values);
        }
    }

    const ValidationPopup = (ButtonType, Msg) => {
        let ShowMsg = 'Success!';
        if (ButtonType === 'warning') {
            ShowMsg = 'Warning!';
        }
        Nodify(ShowMsg, ButtonType, Msg);
        setButtonLoader(false);
        return false;
    }

    const showManualClose = (index, trimInfoID) => {
        setNewFileState([]);
        let FileDeleteId = [];
        const values = [...inputFields];
        values[index].Manual = 1;
        var newfileid = values.filter(x => x.TrimInfoID === trimInfoID)
        newfileid[0].IsManual[0].Files.map(element => {
            FileDeleteId.push({
                styleTrimManualFilelId: element.styleTrimManualFilelId,
                fileName: element.fileName,
                filePath: element.filePath,
                fileType: element.fileType,
                styleTrimIndexName: element.styleTrimIndexName,
                styleTrimFileIndexName: element.styleTrimFileIndexName,
                IsDeleted: 0
            })
            setNewFileState(FileDeleteId)
        })


        setInputFields(values);

    }



    const handleCloseManualPopup = (index) => {
        if (SaveInfo === "Edit") {
            if (values[index].IsManual[0].StyleStrikeOffOrTrimManualId !== 0 || values[index].IsManual[0].IsSaved === 1) {
                values[index].Manual = 0;
            } else {
                values[index].IsManual[0].ManualCloseComment = '';
                values[index].IsManual[0].Files = [];
                values[index].Manual = 0;
            }


        }
        else if (SaveInfo === "Add") {
            if (values[index].IsManual[0].IsSaved === 1) {
                values[index].Manual = 0;
            }
            else {
                values[index].IsManual[0].ManualCloseComment = '';
                values[index].IsManual[0].Files = [];
                values[index].Manual = 0;

            }
        }
        else {
            if (values[index].IsManual[0].ManualCloseComment === '') {
                values[index].IsManual[0].Files = [];
                values[index].StyleTrimInfoApprovalType
                    = 0;
            } else {
                values[index].IsManual[0].ManualCloseComment = '';
                values[index].IsManual[0].Files = [];
                values[index].Manual = 0;
            }
        }
        setInputFields(values);
    };
    const ClearManual = (index) => {
        if (values[index].IsManual[0].StyleStrikeOffOrTrimManualId === 0) {
            values[index].IsManual[0].ManualCloseDate = '';
            values[index].IsManual[0].ManualCloseComment = '';
            values[index].IsManual[0].Files = [];
            values[index].IsManual[0].IsSaved = 0;
        } else {
            values[index].IsManual[0].ManualCloseDate = '';
            values[index].IsManual[0].ManualCloseComment = '';
            values[index].IsManual[0].Files.map(x => {
                x.IsDeleted = 1;
            })
            values[index].IsManual[0].IsDeleted = 1;
            values[index].IsManual[0].IsSaved = 0;
        }
        //values[index].ApprovalTypeId = 0;
        setInputFields(values);
    }

    const handleChangeIsManualCloseComments = (event, index, feild, subindex) => {
        //  const values = [...inputFields];
        let inputText = '';

        if (feild === 'Comment') {
            if (event.target.value.trim() !== '') { inputText = event.target.value; }
            values[index].IsManual[subindex].ManualCloseComment = inputText;
        } else {
            values[index].IsManual[subindex].ManualCloseDate = event;
        }
        setInputFields(values);

    }
    const CustomInput = (props) => {
        return (
            <>
                <input
                    className="form-control DatePickerCalenderHoliday"
                    onClick={props.onClick}
                    value={props.value}
                    type="text"
                    readOnly={true}
                    placeholder="DD/MM/YYYY"
                    style={{ width: '220px', border: getPopUpValidation === true && props.id === 'TargettoClose' && props.value === '' ? '1px solid red' : '' }}
                />
            </>
        )
    }

    const RemoveFiles = async (e, index, fileindex, manindex, styleTrimManualFilelId, styleTrimFileIndexName, styleTrimIndexName) => {
        if (SaveInfo === "Add") {
            values[index].IsManual[0].Files.splice(fileindex, 1);
        }
        else {
            const Mainindexs = values[index].IsManual[0].Files.filter(obj => obj.styleTrimIndexName === styleTrimIndexName);
            const storeindex = newFilestate.filter(x => x.styleTrimManualFilelId !== 0 && x.IsDeleted !== 1)
            if (storeindex.length === Mainindexs.length) {
                newFilestate[fileindex].IsDeleted = 1
                values[index].IsManual[manindex].Files = newFilestate
            } else {
                // let tempindex = 0;
                // inputFields.map((v, vi) => {
                //     v.IsManual[0].Files.map((x, indexs) => {
                //         inputFields[vi].IsManual[0].Files[indexs].ManualIndex = tempindex;
                //         tempindex++;
                //     })
                // })
                const indexs = values[index].IsManual[0].Files.findIndex(obj => obj.styleTrimManualFilelId === styleTrimManualFilelId && obj.styleTrimFileIndexName === styleTrimFileIndexName);
                values[index].IsManual[0].Files[indexs].IsDeleted = 1;
            }

            setInputFields(values);
        }
        setInputFields(values);
    }

    const ViewFile = (event, filename) => { // event.preventDefault();
        setIsOpenimg(true);
        setImagename(filename);
    }

    const filevals = [...getFiles]

    const AppendFiles = async (e, index, subindex) => {
        const file = e.target.files[0];
        var filevals = [];
        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png'];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#FileUpload').val("");
            }
            else {
                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "TNA");
                try {
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                    values[index].IsManual[0].Files.push({
                        styleTrimManualFilelId: 0,
                        fileName: file.name,
                        filePath: "Images/TNAImages/" + file.name,
                        fileType: file.type,
                        styleTrimIndexName: index.toString(),
                        styleTrimFileIndexName: values[index].IsManual[0].Files.length.toString(),
                        IsDeleted: 0
                    })
                    // values[index].IsManual[0].Files = filevals;
                    setInputFields(values);
                    setFiles(filevals);
                    $('#FileUpload').val("");

                } catch (ex) {
                    console.log(ex);
                }
            }
        }
    }

    const handleSaveManual = (index) => {
        if (values[index].IsManual[0].ManualCloseComment === '') {
            // values[index].ApprovalTypeId = 0;
            Nodify('Warning!', 'warning', 'Comment Should be fill');
            setmanualsubmit(true);
        }
        else {
            values[index].IsManual[0].IsSaved = 1;
            values[index].Manual = 0;
            setInputFields(values);
        }
    };

    const getTNATrimMapList = (index, trimInfoId) => {

        let BuyerID = props.location.state.params.buyerID;
        let BrandID = props.location.state.params.brandID;
        let StyleID = props.location.state.params.styleID;
        let params = { StyleID: StyleID, BuyerID: BuyerID, BrandID: BrandID, TrimInfoId: trimInfoId }
        dispatch(getTNATrimAlreadyMapList(params))
            .then(isSuccess => {
                if (isSuccess) {
                    // if (selected && selected !== 0) {
                    const indexVal = TNATrimMapList.findIndex(obj => obj.commonApprovalId === selected);
                    dispatch(updateTrimMapList(indexVal, 1));
                    values[index].ExitingApprovalTypePop = 1;
                    setInputFields(values);
                    // }
                    // else {
                    //     Nodify("Warning!", "warning", "This data not approved");
                    // }

                } else {
                    Nodify("Warning!", "warning", "This data not approved");
                }
            })
            .catch(e => {
                console.log(e);
            });

    }

    const handleCloseExistMapPopup = (index) => {
        values[index].ExitingApprovalTypePop = 0;
        setInputFields(values);

    };

    const OnSelectRecords = (event, index) => {


        let selected;
        // TNAStrikeOffMapList.forEach((element, index) => {
        //     element.isSelected = 0;
        // })
        if (event.target.checked === true) {
            selected = 1;
        } else {
            selected = 0;
        }
        dispatch(updateTrimMapList(index, selected));
    }
    function handleChangecheckbox(event, index) {
        if (event.target.name === "IsSelected") {
            if (event.target.checked === true) {
                values[index].IsMapforTest = 1
            } else {
                values[index].IsMapforTest = 0
            }
        }
        setInputFields(values);
    }

    const saveTrimMap = (index) => {

        let FileteredRecords = TNATrimMapList.filter(x => x.isSelected === 1);
        if (FileteredRecords.length === 0) {
            Nodify("Warning!", "warning", "Select atleast one row.");
        }
        else {
            values[index].TrimSubmissionApprovalID = FileteredRecords[0].commonApprovalId;
            values[index].ExitingApprovalTypePop = 0;
            values[index].SelectedMapRow = FileteredRecords;
            setInputFields(values);
        }

        // ProgramCallback(values);
    }

    return (
        <>
            <div className="page-body">
                <ReactNotification />

                <div> {
                    isOpen && (
                        <Lightbox
                            mainSrc={isOpen}
                            alt="bg image"

                            onCloseRequest={
                                () => setIsOpen(false)
                            } />
                    )
                } </div>

                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <div className="widget flat radius-bordered">
                            <div className="widget-header heading_box_style">
                                <h3 className="heading_style_common">Pick from Master</h3>
                                <div className="buttons-preview" align="right">
                                    <button
                                        type="button"
                                        className="btn btn-success"
                                        onClick={collapseAll}
                                    >
                                        <i className={icon} /> {title}
                                    </button>
                                    <span className="btn btn-primary" onClick={() => PageRedirect("TrimPickFromMasterBack")}>
                                        <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                                    </span>
                                    <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                        <i className="fa fa-times"></i>&nbsp;Reset
                                    </span>
                                    <button type="button" className="btn btn-success" onClick={SaveTrim} disabled={buttonLoader}>
                                        <i className="fa fa-check right"></i>&nbsp;Save
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <h5 className="WarningMsg">Notes : By select atleast one SKU in color section, then only picked trim details will display in listing.
                                </h5>
                            </div>
                            <div className="widget-body">
                                <div id="registration-form">
                                    {
                                        inputFields.map((inputField, index) => (
                                            <Fragment key={
                                                `${inputField}~${index}`
                                            }>
                                                <div className="col-lg-10 col-sm-8 col-xs-12"
                                                    style={{ display: inputField.IsDeleted === 1 ? "none" : "block" }}
                                                >
                                                    <div className="widget flat radius-bordered">
                                                        <CollapsiblePanel title={
                                                            inputField.StyleTrimInfoID === 0 ? 'Pick Trim' : inputField.StyleTrimInfoName
                                                        } collapse={inputField.StyleTrimInfoID === 0 ? false : collapse}>
                                                            <span>
                                                                <div className="widget-body">
                                                                    <div id="registration-form">
                                                                        <Form>
                                                                            <div className="col-sm-12">
                                                                                <div className="row">
                                                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th style={{ width: "19%" }}>Select Trim Code <span className="text-danger">*</span></th>
                                                                                                <th>Trim Name</th>
                                                                                                <th>Technical Specification</th>
                                                                                                <th>Purchase Type</th>
                                                                                                {inputField.PurchaseType === 1 && (
                                                                                                    <th>Trim Supplier</th>
                                                                                                )}
                                                                                                {inputField.PurchaseType === 3 && (
                                                                                                    <th>Approval Type <span className="text-danger">*</span>

                                                                                                        {
                                                                                                            inputField.StyleTrimInfoApprovalType === 2 ?
                                                                                                                (inputField.IsManual[0].ManualCloseComment === '' ?
                                                                                                                    <button type="button" className="btn btn-xs btn-darkorange" title="Manual"
                                                                                                                        onClick={() => showManualClose(index, inputField.TrimInfoID)}
                                                                                                                    >
                                                                                                                        <i className="fa fa-user"></i>&nbsp;
                                                                                                                        <i className="fa fa-close"></i>
                                                                                                                    </button>
                                                                                                                    :
                                                                                                                    <button type="button" className="btn btn-xs btn-success" title="View Manual Data"
                                                                                                                        onClick={() => showManualClose(index, inputField.TrimInfoID)}
                                                                                                                    >
                                                                                                                        <i className="fa fa-user"></i>&nbsp;
                                                                                                                        <i className="fa fa-close"></i>
                                                                                                                    </button>)
                                                                                                                :
                                                                                                                inputField.StyleTrimInfoApprovalType === 3 &&

                                                                                                                // (item.IsManual[0].ManualCloseComment === '' ?
                                                                                                                <button type="button" className="btn btn-xs btn-darkorange" title="Existing Approval"
                                                                                                                    onClick={() => getTNATrimMapList(index, inputField.TrimInfoID)}
                                                                                                                >
                                                                                                                    <i class="fa fa-retweet" aria-hidden="true"></i>&nbsp;
                                                                                                                    {/* <i className="fa fa-close"></i> */}
                                                                                                                </button>
                                                                                                        }
                                                                                                    </th>
                                                                                                )}
                                                                                                <th>Is Map for Test</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span className='input-icon icon-right'>
                                                                                                        <Reactselect className="basic-single"
                                                                                                            name="TrimId"
                                                                                                            id={"TrimInfoIDPickFromMaster"}
                                                                                                            isDisabled={false}
                                                                                                            isLoading={false}
                                                                                                            isClearable={true}
                                                                                                            isSearchable={true}
                                                                                                            options={getAllBrandList}
                                                                                                            value={getAllBrandList.filter(function (option) {
                                                                                                                return option.value === inputField.TrimInfoID;
                                                                                                            })}
                                                                                                            onChange={(event) => handleSelectedChange(event, index)}
                                                                                                            autoFocus
                                                                                                            styles={submitted && inputField.TrimInfoID === 0 ? styles : ''}

                                                                                                        ></Reactselect>
                                                                                                    </span>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <span className='input-icon icon-right'>
                                                                                                        <Input placeholder="Trim Name"
                                                                                                            id="StyleTrimInfoName"
                                                                                                            name="StyleTrimInfoName"
                                                                                                            type="text"
                                                                                                            autoComplete="off"
                                                                                                            maxLength="50"
                                                                                                            className={'form-control'}
                                                                                                            value={inputField.StyleTrimInfoName}
                                                                                                            disabled
                                                                                                        />
                                                                                                    </span>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <span className='input-icon icon-right'>
                                                                                                        <Input placeholder="Technical Specification"
                                                                                                            id="TechnicalSpecification"
                                                                                                            name="TechnicalSpecification"
                                                                                                            type="text"
                                                                                                            autoComplete="off"
                                                                                                            maxLength="50"
                                                                                                            className={'form-control'}
                                                                                                            value={inputField.TechnicalSpecification}
                                                                                                            disabled
                                                                                                        />
                                                                                                    </span>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <span className='input-icon icon-right'>
                                                                                                        <Input placeholder="Purchase Type"
                                                                                                            id="PurchaseTypeName"
                                                                                                            name="PurchaseTypeName"
                                                                                                            type="text"
                                                                                                            autoComplete="off"
                                                                                                            maxLength="50"
                                                                                                            className={'form-control'}
                                                                                                            value={inputField.PurchaseTypeName}
                                                                                                            disabled
                                                                                                        />
                                                                                                    </span>
                                                                                                </td>
                                                                                                {inputField.PurchaseType === 1 && (
                                                                                                    <td>
                                                                                                        <span className='input-icon icon-right'>
                                                                                                            <Input placeholder="Trim Supplier Name"
                                                                                                                id="TrimSupplierName"
                                                                                                                name="TrimSupplierName"
                                                                                                                type="text"
                                                                                                                autoComplete="off"
                                                                                                                maxLength="50"
                                                                                                                className={'form-control'}
                                                                                                                value={inputField.TrimSupplierName}
                                                                                                                disabled
                                                                                                            />
                                                                                                        </span>
                                                                                                    </td>
                                                                                                )}

                                                                                                {inputField.PurchaseType === 3 && (
                                                                                                    <td>
                                                                                                        <span className='input-icon icon-right'>
                                                                                                            <Select
                                                                                                                id="StyleTrimInfoApprovalType"
                                                                                                                name="StyleTrimInfoApprovalType"
                                                                                                                value={inputField.StyleTrimInfoApprovalType}
                                                                                                                onChange={event => handleChange(event, index, 'StyleTrimInfoApprovalType')}
                                                                                                                type="text"
                                                                                                                autoComplete="off"
                                                                                                                maxLength="50"
                                                                                                                className={'form-control'}
                                                                                                                style={
                                                                                                                    {
                                                                                                                        border: submitted && inputField.StyleTrimInfoApprovalType === 0 ? '1px solid red' : ''
                                                                                                                    }
                                                                                                                }
                                                                                                            >
                                                                                                                <option value="0">- Select -</option>
                                                                                                                {
                                                                                                                    getApprovalList.map(item => (
                                                                                                                        <option key={
                                                                                                                            item.id
                                                                                                                        }
                                                                                                                            value={
                                                                                                                                item.id
                                                                                                                            }>
                                                                                                                            {
                                                                                                                                item.name
                                                                                                                            }
                                                                                                                        </option>
                                                                                                                    ))
                                                                                                                }
                                                                                                            </Select>
                                                                                                        </span>
                                                                                                        {
                                                                                                            inputField.Manual === 1 && (
                                                                                                                <>
                                                                                                                    <Modal show={inputField.Manual === 1} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseManualPopup(index)}>
                                                                                                                        <Modal.Header closeButton>
                                                                                                                            <Modal.Title>
                                                                                                                                Manual
                                                                                                                            </Modal.Title>
                                                                                                                        </Modal.Header>
                                                                                                                        <Modal.Body>

                                                                                                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                                                                {
                                                                                                                                    inputField.IsManual.map((manitem, k) => {
                                                                                                                                        return (
                                                                                                                                            <>
                                                                                                                                                <thead>
                                                                                                                                                    <tr>
                                                                                                                                                        <th>Comments <span className="text-danger">*</span>
                                                                                                                                                            <button type="button" className="btn btn-xs btn-danger pull-right"
                                                                                                                                                                style={{ display: activeMenu && activeMenu[0].isDelete === 0 ? 'none' : '' }}
                                                                                                                                                                onClick={event => ClearManual(index)}
                                                                                                                                                                title="Clear Critical" ><i className="fa fa-trash-o"></i>
                                                                                                                                                            </button>
                                                                                                                                                        </th>
                                                                                                                                                        {/* <th>Date <span className="text-danger">*</span>
                                                                                                                                                        </th> */}

                                                                                                                                                    </tr>
                                                                                                                                                </thead>
                                                                                                                                                <tbody>

                                                                                                                                                    <tr>
                                                                                                                                                        <td>
                                                                                                                                                            <textarea
                                                                                                                                                                style={{ width: "100%", border: manualsubmit && manitem.ManualCloseComment === '' ? '1px solid red' : '' }}
                                                                                                                                                                rows="3"
                                                                                                                                                                placeholder="Enter Comments here..."
                                                                                                                                                                autoFocus onFocus={""} autoComplete="off"
                                                                                                                                                                maxLength="500" name="EnterRemarks"
                                                                                                                                                                id={"EnterRemarks"}
                                                                                                                                                                value={manitem.ManualCloseComment}
                                                                                                                                                                onChange={event => handleChangeIsManualCloseComments(event, index, "Comment", k)}


                                                                                                                                                            >

                                                                                                                                                            </textarea>
                                                                                                                                                        </td>
                                                                                                                                                        {/* <td >
                                                                                                                                                            <DatePicker className="form-control" name="HolidayDate"
                                                                                                                                                                id={
                                                                                                                                                                    "HolidayDate" + index
                                                                                                                                                                }
                                                                                                                                                                selected={
                                                                                                                                                                    manitem.ManualCloseDate
                                                                                                                                                                }
                                                                                                                                                                onChange={event => handleChangeIsManualCloseComments(event, index, "CommentDate", k)}
                                                                                                                                                                dateFormat="dd/MM/yyyy"
                                                                                                                                                                peekNextMonth
                                                                                                                                                                showMonthDropdown
                                                                                                                                                                // showYearDropdown
                                                                                                                                                                dropdownMode="scroll"
                                                                                                                                                                autoComplete="off"
                                                                                                                                                                // minDate={new Date()}
                                                                                                                                                                // maxDate={new Date()}
                                                                                                                                                                customInput={<CustomInput />}
                                                                                                                                                            //  disabled={childTask.StatusID === 3 ? true : false}
                                                                                                                                                            // disabled

                                                                                                                                                            // isClearable={inputField.HolidayDate === '' ? false : true}
                                                                                                                                                            />


                                                                                                                                                        </td> */}
                                                                                                                                                    </tr>

                                                                                                                                                    <tr>
                                                                                                                                                        <td colSpan={2}>
                                                                                                                                                            <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                                                                                                {/* <TableHeader headers={headers} /> */}
                                                                                                                                                                <tbody>


                                                                                                                                                                    <tr>
                                                                                                                                                                        <div className="col-sm-12 col-lg-12">
                                                                                                                                                                            <div style={{ paddingLeft: 0 }}>
                                                                                                                                                                                <span className="widget-caption" style={{ fontSize: '14px' }}>File Upload <span style={{ color: 'red', fontSize: '14px' }}>(Upload Document Type:Image Only)</span></span>
                                                                                                                                                                            </div>
                                                                                                                                                                        </div>
                                                                                                                                                                        <div className="col-sm-12 col-lg-12">
                                                                                                                                                                            <div className="row">
                                                                                                                                                                                <div className="col-sm-8 col-lg-8">
                                                                                                                                                                                    <div className='form-group'>
                                                                                                                                                                                        <tr>
                                                                                                                                                                                            <td> <input type="file" id="FileUpload" style={{ fontSize: '14px' }} onChange={(e) => AppendFiles(e, index, k)} accept=".jpg,.jpeg,.png,.pdf,.xlsx,.doc,.docx" ></input></td>
                                                                                                                                                                                        </tr>
                                                                                                                                                                                    </div>
                                                                                                                                                                                </div>
                                                                                                                                                                            </div>
                                                                                                                                                                        </div>
                                                                                                                                                                        <div className="col-lg-12 col-sm-12">
                                                                                                                                                                            <div className="row no-margin">
                                                                                                                                                                                {
                                                                                                                                                                                    manitem.Files &&
                                                                                                                                                                                        manitem.Files.length !== 0 ?
                                                                                                                                                                                        manitem.Files.filter(x => x.IsDeleted !== 1).map((files, fileindex) => (
                                                                                                                                                                                            <div className="col-lg-4 col-sm-4 file_list_upload">
                                                                                                                                                                                                <tr title={files.fileName}>
                                                                                                                                                                                                    <td className="cut_textInTNA">
                                                                                                                                                                                                        <span style={{ marginRight: '7px' }}>
                                                                                                                                                                                                            {
                                                                                                                                                                                                                files.fileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                                                                                                                                    files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                                                                                                                                                        <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                                                                                                                                        files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                                                                                                                                            files.fileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                                                                                                                                            <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                                                                                                                                            }
                                                                                                                                                                                                        </span>  {files.fileName}
                                                                                                                                                                                                    </td>

                                                                                                                                                                                                    <td className="button_style_uploda">
                                    
                                                                                                                                                                                                        
                                                                                                                                                                                                        {
                                                                                                                                                                                                            files.FileType === "application/pdf" || files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files.fileType === 'application/msword' ?
                                                                                                                                                                                                                <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + 'Images/TNAImages/' + files.fileName} download><i class="fa fa-download"></i> </a> :
                                                                                                                                                                                                                <i className="btn btn-info fa fa-eye" title="View Logo"
                                                                                                                                                                                                                    style={{ display: activeMenu && activeMenu[0].isView === 1 ? '' : 'none' }}
                                                                                                                                                                                                                    onClick={
                                                                                                                                                                                                                        event => ViewFile(event, files.fileName)
                                                                                                                                                                                                                    }></i>
                                                                                                                                                                                                        }
                                                                                                                                                                                                        &nbsp;

                                                                                                                                                                                                        {Buttonview !== "View" &&
                                                                                                                                                                                                            <p title='Delete File' onClick={(e) => RemoveFiles(e, index, fileindex, k, files.styleTrimManualFilelId, files.styleTrimFileIndexName, files.styleTrimIndexName)}
                                                                                                                                                                                                                style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}
                                                                                                                                                                                                                className="btn btn-danger btn-xs delete">
                                                                                                                                                                                                                <i className="fa fa-trash-o"></i>

                                                                                                                                                                                                            </p>
                                                                                                                                                                                                        }

                                                                                                                                                                                                    </td>
                                                                                                                                                                                                </tr>
                                                                                                                                                                                                {

                                                                                                                                                                                                    <div> {
                                                                                                                                                                                                        isOpenimg && (

                                                                                                                                                                                                            <Lightbox mainSrc={window.$APIBaseURL + 'Images/TNAImages/' + getImagename}
                                                                                                                                                                                                                alt="bg image"
                                                                                                                                                                                                                onCloseRequest={
                                                                                                                                                                                                                    () => setIsOpenimg(false)
                                                                                                                                                                                                                } />
                                                                                                                                                                                                        )
                                                                                                                                                                                                    } </div>
                                                                                                                                                                                                }
                                                                                                                                                                                            </div>
                                                                                                                                                                                        ))
                                                                                                                                                                                        : ''
                                                                                                                                                                                }
                                                                                                                                                                            </div>
                                                                                                                                                                        </div>
                                                                                                                                                                    </tr>
                                                                                                                                                                </tbody>


                                                                                                                                                            </table>
                                                                                                                                                        </td>
                                                                                                                                                    </tr>
                                                                                                                                                </tbody>
                                                                                                                                            </>
                                                                                                                                        )
                                                                                                                                    })
                                                                                                                                }



                                                                                                                            </table>


                                                                                                                        </Modal.Body>
                                                                                                                        <Modal.Footer>
                                                                                                                            {

                                                                                                                                <Button variant="success" onClick={() => handleSaveManual(index)}>
                                                                                                                                    Save
                                                                                                                                </Button>


                                                                                                                            }


                                                                                                                        </Modal.Footer>
                                                                                                                    </Modal>
                                                                                                                </>
                                                                                                            )
                                                                                                        }

                                                                                                        {
                                                                                                            inputField.ExitingApprovalTypePop === 1 ?
                                                                                                                <Modal show={inputField.ExitingApprovalTypePop === 1} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseExistMapPopup(index)}>
                                                                                                                    <Modal.Header closeButton>
                                                                                                                        <Modal.Title>
                                                                                                                            Existing Approval Information
                                                                                                                        </Modal.Title>
                                                                                                                    </Modal.Header>
                                                                                                                    <Modal.Body>

                                                                                                                        <table className="table table-bordered">
                                                                                                                            <thead>
                                                                                                                                <tr>
                                                                                                                                    <th>Season Name</th>
                                                                                                                                    <th>Approval Date</th>
                                                                                                                                    <th>Details</th>
                                                                                                                                    <th>Approved By</th>
                                                                                                                                    <th></th>


                                                                                                                                </tr>
                                                                                                                            </thead>
                                                                                                                            <tbody>
                                                                                                                                {
                                                                                                                                    TNATrimMapList && TNATrimMapList.length !== 0 ?
                                                                                                                                        TNATrimMapList.map((Exiting, ExitingIndex) => {
                                                                                                                                            return (
                                                                                                                                                <>
                                                                                                                                                    <tr>
                                                                                                                                                        <td>{Exiting.seasonName}</td>
                                                                                                                                                        <td>{Exiting.approvalDate}</td>
                                                                                                                                                        <td>{Exiting.details}</td>
                                                                                                                                                        <td>{Exiting.approvedBy}</td>
                                                                                                                                                        <td className="Actionbutton fixed-column">
                                                                                                                                                            <div style={{ width: "80px" }}>
                                                                                                                                                                <label style={{ marginTop: '8px' }}>
                                                                                                                                                                    <input type="checkbox" name="IsSelected"
                                                                                                                                                                        onChange={event => (OnSelectRecords(event, index))}
                                                                                                                                                                        checked={Exiting.isSelected === 1 ? true : false}
                                                                                                                                                                        className="colored-blue clearOpacity" />
                                                                                                                                                                    <span className="text"></span>
                                                                                                                                                                </label>

                                                                                                                                                            </div>

                                                                                                                                                        </td>
                                                                                                                                                    </tr>
                                                                                                                                                </>

                                                                                                                                            )
                                                                                                                                        })
                                                                                                                                        :
                                                                                                                                        <tr>
                                                                                                                                            <td colSpan={4}>
                                                                                                                                                <div align="center"> <span>No Records Found</span></div>
                                                                                                                                            </td>
                                                                                                                                        </tr>
                                                                                                                                }

                                                                                                                            </tbody>
                                                                                                                        </table>
                                                                                                                    </Modal.Body>
                                                                                                                    <Modal.Footer>
                                                                                                                        <Button variant="success"
                                                                                                                            onClick={() => saveTrimMap(index)}
                                                                                                                        >
                                                                                                                            Save
                                                                                                                        </Button>

                                                                                                                    </Modal.Footer>
                                                                                                                </Modal> : ''
                                                                                                        }
                                                                                                    </td>
                                                                                                )}
                                                                                                <td >
                                                                                                    &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                                                                                                    <label style={{ marginTop: '8px' }}>
                                                                                                        <input type="checkbox" name="IsSelected"
                                                                                                            onChange={event => (handleChangecheckbox(event, index, 'IsSelected'))}
                                                                                                            value={inputField.IsMapforTest === 1 ? true : false}
                                                                                                            checked={inputField.IsMapforTest === 1 ? true : false}
                                                                                                            className="clearOpacity textdesign" />
                                                                                                        <span className="text"> </span>
                                                                                                    </label>
                                                                                                </td>
                                                                                            </tr>
                                                                                            {
                                                                                                inputField.IsManual.map((man, j) => {
                                                                                                    return (
                                                                                                        man.ManualCloseComment && man.ManualCloseComment !== '' && inputField.StyleTrimInfoApprovalType === 2 ?
                                                                                                            <tr>
                                                                                                                <td colSpan="10">
                                                                                                                    <span><b>Manual Comment :</b> {man.ManualCloseComment}</span>
                                                                                                                    {/* <span style={{ color: '#427fed' }}>Date : {new Date(man.ManualCloseDate).toLocaleDateString()}</span> */}
                                                                                                                    <tr>
                                                                                                                        <td>
                                                                                                                            {
                                                                                                                                man.Files &&
                                                                                                                                    man.Files.length !== 0 ?
                                                                                                                                    SaveInfo === "Add" ?
                                                                                                                                        man.Files.filter(x => x.IsDeleted !== 1).map((files, fileindex) => (
                                                                                                                                            <div>
                                                                                                                                                <tr title={files.fileName}>
                                                                                                                                                    File :
                                                                                                                                                    <td>
                                                                                                                                                        <span style={{ marginRight: '7px' }}>
                                                                                                                                                            {
                                                                                                                                                                files.fileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                                                                                    files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                                                                                                        <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                                                                                        files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                                                                                            files.fileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                                                                                            <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                                                                                            }
                                                                                                                                                        </span>  {files.fileName}
                                                                                                                                                    </td>

                                                                                                                                                    <td>

                                                                                                                                                        {
                                                                                                                                                            files.fileType === "application/pdf" || files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files.fileType === 'application/msword' ?
                                                                                                                                                                <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + 'Images/TNAImages/' + files.fileName} download><i class="fa fa-download"></i> </a> :
                                                                                                                                                                <i className="btn btn-info fa fa-eye" title="View Logo"
                                                                                                                                                                    style={{ display: activeMenu && activeMenu[0].isView === 1 ? '' : 'none' }}
                                                                                                                                                                    onClick={
                                                                                                                                                                        event => ViewFile(event, files.fileName)
                                                                                                                                                                    }></i>
                                                                                                                                                        }
                                                                                                                                                    </td>
                                                                                                                                                </tr>
                                                                                                                                                {

                                                                                                                                                    <div> {
                                                                                                                                                        isOpenimg && (

                                                                                                                                                            <Lightbox mainSrc={window.$APIBaseURL + 'Images/TNAImages/' + getImagename}
                                                                                                                                                                alt="bg image"
                                                                                                                                                                onCloseRequest={
                                                                                                                                                                    () => setIsOpenimg(false)
                                                                                                                                                                } />
                                                                                                                                                        )
                                                                                                                                                    } </div>
                                                                                                                                                }
                                                                                                                                            </div>
                                                                                                                                        ))
                                                                                                                                        :
                                                                                                                                        man.Files.filter(x => x.IsDeleted !== 1).map((files, fileindex) => (
                                                                                                                                            <div>
                                                                                                                                                <tr title={files.fileName}>
                                                                                                                                                    File :
                                                                                                                                                    <td>
                                                                                                                                                        <span style={{ marginRight: '7px' }}>
                                                                                                                                                            {
                                                                                                                                                                files.fileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                                                                                    files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                                                                                                        <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                                                                                        files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                                                                                            files.fileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                                                                                            <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                                                                                            }
                                                                                                                                                        </span>  {files.fileName}
                                                                                                                                                    </td>

                                                                                                                                                    <td>

                                                                                                                                                        {
                                                                                                                                                            files.fileType === "application/pdf" || files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files.fileType === 'application/msword' ?
                                                                                                                                                                <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + 'Images/TNAImages/' + files.fileName} download><i class="fa fa-download"></i> </a> :
                                                                                                                                                                <i className="btn btn-info fa fa-eye" title="View Logo"
                                                                                                                                                                    style={{ display: activeMenu && activeMenu[0].isView === 1 ? '' : 'none' }}
                                                                                                                                                                    onClick={
                                                                                                                                                                        event => ViewFile(event, files.fileName)
                                                                                                                                                                    }></i>
                                                                                                                                                        }
                                                                                                                                                    </td>
                                                                                                                                                </tr>
                                                                                                                                                {

                                                                                                                                                    <div> {
                                                                                                                                                        isOpenimg && (

                                                                                                                                                            <Lightbox mainSrc={window.$APIBaseURL + 'Images/TNAImages/' + getImagename}
                                                                                                                                                                alt="bg image"
                                                                                                                                                                onCloseRequest={
                                                                                                                                                                    () => setIsOpenimg(false)
                                                                                                                                                                } />
                                                                                                                                                        )
                                                                                                                                                    } </div>
                                                                                                                                                }
                                                                                                                                            </div>
                                                                                                                                        ))
                                                                                                                                    : ''


                                                                                                                            }
                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                                    <span>

                                                                                                                    </span>

                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            : ''



                                                                                                    );


                                                                                                })
                                                                                            }

                                                                                            {
                                                                                                inputField.SelectedMapRow.map((man, j) => {
                                                                                                    return (
                                                                                                        inputField.StyleTrimInfoApprovalType === 3 && man.isSelected === 1 ?
                                                                                                            <tr>
                                                                                                                <td colSpan="10">
                                                                                                                    <span><b>Mapped Trim Detail </b> &nbsp;</span>
                                                                                                                    <br />
                                                                                                                    <span><b>Season Name : </b> {man.seasonName} &nbsp;</span>
                                                                                                                    <span><b>Approval Date : </b>  {new Date(man.approvalDate).toLocaleDateString()}&nbsp;</span>
                                                                                                                    <span><b>Details : </b> {man.details}&nbsp;</span>
                                                                                                                    <span><b>Approved By : </b> {man.approvedBy}&nbsp;</span>

                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            : ''



                                                                                                    );


                                                                                                })
                                                                                            }
                                                                                            <tr>
                                                                                                <td colSpan="6">
                                                                                                    {/* <div style={{ overflowX: "auto" }}> */}
                                                                                                    <div>
                                                                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                                                                                            <thead>
                                                                                                                <tr>
                                                                                                                    <th>Height</th>
                                                                                                                    <th>Width</th>
                                                                                                                    <th>UOM</th>
                                                                                                                    <th>Mapped</th>
                                                                                                                    {/* <th>Action</th> */}
                                                                                                                </tr>
                                                                                                            </thead>
                                                                                                            <tbody>
                                                                                                                {
                                                                                                                    inputField.RefList.map((refInfo, refindex) => (
                                                                                                                        <tr>
                                                                                                                            <td>{refInfo.height}</td>
                                                                                                                            <td>{refInfo.width}</td>
                                                                                                                            <td>{refInfo.sizeName}</td>

                                                                                                                            <td>  <div className="checkbox" style={{ marginTop: "0px" }}>
                                                                                                                                <label>
                                                                                                                                    <input type="checkbox" name="PickStylePic"
                                                                                                                                        isMulti
                                                                                                                                        onChange={event => handleRefChange(event, index, refindex)}
                                                                                                                                        checked={inputField.RefMappedList[refindex].IsTrimStyleChecked === 1 ? true : false}
                                                                                                                                        className="colored-blue clearOpacity" />
                                                                                                                                    <span className="text"></span>
                                                                                                                                </label>
                                                                                                                            </div>
                                                                                                                            </td>

                                                                                                                        </tr>

                                                                                                                    ))
                                                                                                                }
                                                                                                                {
                                                                                                                    inputField.RefList.length === 0 ?
                                                                                                                        <tr>
                                                                                                                            <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
                                                                                                                        </tr> : ''
                                                                                                                }
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </div>
                                                                                                    {/* </div> */}
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td colSpan="6">

                                                                                                    <div>

                                                                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                                                                                            <thead>
                                                                                                                <tr>
                                                                                                                    <th style={{ width: "19%" }}>Image</th>
                                                                                                                    <th>SKU</th>
                                                                                                                    <th>Pantone / Swatch Details</th>
                                                                                                                    <th style={{ display: activeMenu && activeMenu[0].isDelete === 0 ? 'none' : '' }}>Action</th>
                                                                                                                </tr>
                                                                                                            </thead>
                                                                                                            <tbody>
                                                                                                                {
                                                                                                                    inputField.LogoList.map((logoInfo, colorindex) => (
                                                                                                                        <tr>
                                                                                                                            <td><img alt="img" className="width_manual" src={window.$APIBaseURL + "Images/Style/Trim/" + logoInfo.trimLogoPath}
                                                                                                                                onClick={
                                                                                                                                    () => setIsOpen(window.$APIBaseURL + 'Images/Style/Trim/' + logoInfo.trimLogoPath)
                                                                                                                                }
                                                                                                                            ></img></td>
                                                                                                                            <td> <Reactselect className="basic-single"
                                                                                                                                name="SKUId"
                                                                                                                                id={"SKUId"}
                                                                                                                                isDisabled={false}
                                                                                                                                isLoading={false}
                                                                                                                                isClearable={true}
                                                                                                                                isSearchable={true}
                                                                                                                                options={getSKUList}
                                                                                                                                value={inputField.MapColorSKUList[colorindex].ArrSKUIds}
                                                                                                                                isMulti
                                                                                                                                // menuPosition="fixed"
                                                                                                                                onChange={event => handleColorChange(event, index, colorindex)}
                                                                                                                                menuPlacement="auto"
                                                                                                                            // styles={submitted && inputField.MapColorSKUList[colorindex].StyleTrimSku === '' ? styles : ''}

                                                                                                                            ></Reactselect></td>
                                                                                                                            <td>
                                                                                                                                <table className="table_inside" style={{ width: "auto" }}>
                                                                                                                                    <tbody>
                                                                                                                                        {
                                                                                                                                            comments.filter(x => x.indexName === logoInfo.indexName && x.trimInfoID === logoInfo.trimInfoID).map((color, index_Value) => (
                                                                                                                                                <>

                                                                                                                                                    <tr>
                                                                                                                                                        {
                                                                                                                                                            color.pantoneTypeName !== 'Others' &&

                                                                                                                                                            <td>
                                                                                                                                                                <div style={{ background: color.pantoneColor, marginLeft: "5px", verticalAlign: "bottom", width: '120px', display: "inline-block", height: '34px', borderRadius: '2px', marginBottom: "2px" }}></div>
                                                                                                                                                            </td>
                                                                                                                                                        }
                                                                                                                                                        <td>{color.pantoneCode} - {color.pantoneTypeName}</td>
                                                                                                                                                        {/* <td>
                                                                                                                                                            <div style={{ background: color.pantoneColor, marginLeft: "5px", verticalAlign: "bottom", width: '120px', display: "inline-block", height: '34px', borderRadius: '2px', marginBottom: "2px" }}></div>
                                                                                                                                                        </td> */}


                                                                                                                                                        {
                                                                                                                                                            color.pantoneTypeName === 'Others' &&

                                                                                                                                                            <td className="trwidth_manual">
                                                                                                                                                                {
                                                                                                                                                                    < img className="width_manual"
                                                                                                                                                                        src={color.pantoneColor === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + color.pantoneColor}
                                                                                                                                                                        alt="Logo"

                                                                                                                                                                        onClick={() => setIsOpen(color.pantoneColor === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + color.pantoneColor)}
                                                                                                                                                                    //onClick={() => OpenSlider(color.pantoneColor === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + color.pantoneColor)}
                                                                                                                                                                    />
                                                                                                                                                                }</td>
                                                                                                                                                        }

                                                                                                                                                        {(color.notes !== '' || color.targettoClose != null || color.completedOn !== null || color.remindMe !== 0) && (
                                                                                                                                                            <td>
                                                                                                                                                                <i class="fa fa-exclamation-triangle" style={{ color: "red" }}
                                                                                                                                                                    onClick={() => ShowCritical(color.notes, color.targettoClose, color.completedOn, color.remindMe)}></i>
                                                                                                                                                            </td>
                                                                                                                                                        )
                                                                                                                                                        }
                                                                                                                                                    </tr>
                                                                                                                                                </>
                                                                                                                                            ))
                                                                                                                                        }
                                                                                                                                    </tbody>
                                                                                                                                </table>
                                                                                                                            </td>
                                                                                                                            <td style={{ display: activeMenu && activeMenu[0].isDelete === 0 ? 'none' : '' }}>
                                                                                                                                <button type="button" className="btn  btn-xs  btn-danger" title="Delete Color"
                                                                                                                                    style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}
                                                                                                                                    onClick={
                                                                                                                                        () => ClearColorInfo(index, colorindex)
                                                                                                                                    }>
                                                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                                                </button>
                                                                                                                            </td>
                                                                                                                        </tr>

                                                                                                                    ))
                                                                                                                }
                                                                                                                {
                                                                                                                    inputField.LogoList.length === 0 ?
                                                                                                                        <tr>
                                                                                                                            <td colSpan="4" className='norecordfound'><span>No Records Found</span></td>
                                                                                                                        </tr> : ''
                                                                                                                }
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </div>
                                                                                                    {/* </div> */}
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </Form>
                                                                    </div>
                                                                </div>
                                                            </span>
                                                        </CollapsiblePanel>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1_own col-xs-12"
                                                    style={
                                                        {
                                                            display: inputField.IsDeleted === 1 ? "none" : "block"
                                                        }
                                                    } >
                                                    <span>
                                                        <span style={
                                                            {
                                                                display: inputField.IsDeleted === 1 ? "none" : (inputFields.filter(x => x.IsDeleted === 0).length === 1 ? "none" : "")
                                                            }
                                                        }>
                                                            <button className="btn btn-danger" title="Delete Trim"
                                                                style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}
                                                                onClick={
                                                                    () => handleRemoveFields(index)
                                                                }>
                                                                <i className="fa fa-trash-o"></i>
                                                            </button>
                                                        </span>
                                                    </span>
                                                </div>
                                            </Fragment>
                                        ))
                                    }
                                    {
                                        inputFields.filter(x => x.IsDeleted === 0).map((inputField1, index) => (
                                            inputFields.filter(x => x.IsDeleted === 0).length === (index + 1) && (
                                                <div className="col-lg-1_own col-xs-12">
                                                    <button type="button" className="btn btn-success" title="Add Trim" onClick={() => handleAddFields(index)}>
                                                        <i className="fa fa-plus"></i>
                                                    </button>
                                                </div>
                                            )
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row" style={{ marginTop: "15px" }}>
                    <div className="col-sm-12">
                        <div className="buttons-preview" align="right">

                            <span className="btn btn-primary" onClick={() => PageRedirect("TrimPickFromMasterBack")}>
                                <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                            </span>
                            <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                <i className="fa fa-times"></i>&nbsp;Reset
                            </span>
                            <button type="button" className="btn btn-success" onClick={SaveTrim} disabled={buttonLoader}>
                                <i className="fa fa-check right"></i>&nbsp;Save
                            </button>
                        </div>
                    </div>
                </div>
                {getShow &&
                    <Modal show={getShow} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleClose()}>
                        <Modal.Header closeButton>
                            <Modal.Title>Critical Comments</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                <thead>
                                    <tr>
                                        <th colSpan="4" style={{ textAlign: "center" }}>Comments<span className="text-danger">*</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan="4">
                                            <textarea
                                                placeholder="Enter comments here..."
                                                maxLength="2000"
                                                value={
                                                    getPopupValue[0].Notes
                                                }
                                                disabled
                                                rows="4" style={{ width: "100%" }}></textarea>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Target to Close</td>
                                        <td>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                maxLength="50"
                                                className={'form-control'}
                                                value={getPopupValue[0].TargettoClose}
                                                disabled
                                            />
                                        </td>
                                        <td>Remind Me</td>
                                        <td>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                maxLength="50"
                                                className={'form-control'}
                                                value={getPopupValue[0].RemindMe === 1 ? "Current Date Reaches Target Date" : getPopupValue[0].RemindMe === 2 ? "One Time in a Day, until complete" : ""}
                                                disabled
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Completed On</td>
                                        <td>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                maxLength="50"
                                                className={'form-control'}
                                                value={getPopupValue[0].CompletedOn}
                                                disabled
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={() => handleClose()}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                }
            </div>
            {loader}
        </>
    )
};
export default PickFromMaster;
