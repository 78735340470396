import {
    TRIMTYPE_LOADING,
    RETRIEVE_TRIMTYPE,
    DELETE_TRIMTYPE,
} from "../actions/types";

const initialState = {
    isLoadingTrimtype: true,
    TrimtypeList: [],
};

const trimtypeReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case TRIMTYPE_LOADING:
            return {
                ...state,
                isLoadingTrimtype: payload
            };
        case RETRIEVE_TRIMTYPE:
            return { ...state, TrimtypeList: payload };
        case DELETE_TRIMTYPE:
            const results = state.TrimtypeList.filter(c => c.trimTypeID !== payload.trimTypeID);
            return {
                ...state,
                TrimtypeList: results
            };

        default:
            return state;
    }
};
export default trimtypeReducer;