import axios from "axios";
import authHeader from "../auth-header";

class BuyerService {

    LoadCurrencyist() {
        return axios.get(window.$APIBaseURL + "Master/LoadCurrencyist", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateSupplier(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateSupplier',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadGroupTYpe() {
        return axios.get(window.$APIBaseURL + "Master/LoadGroupTypeList", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadCategoryList(GroupTypeID) {
        return axios.get(window.$APIBaseURL + "Master/LoadCategoryList?GroupTypeID=" + GroupTypeID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    BindFactoryList(CompanyID) {
        return axios.get(window.$APIBaseURL + "Master/GetFactoryInfoList?CompanyBuyerSupID=" + CompanyID, { // data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    BindCustomerList(CompanyID) {
        return axios.get(window.$APIBaseURL + "Master/GetSupplierCustomersList?CompanyBuyerSupID=" + CompanyID, { // data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    BindProductionList(CompanyID) {
        return axios.get(window.$APIBaseURL + "Master/GetProductionCapacityList?CompanyBuyerSupID=" + CompanyID, { // data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    BindPolicyList(CompanyID) {
        return axios.get(window.$APIBaseURL + "Master/GetSupplierPoliciesList?CompanyBuyerSupID=" + CompanyID, { // data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    BindCertificateList(CompanyID) {
        return axios.get(window.$APIBaseURL + "Master/GetSupplierCertificateList?CompanyBuyerSupID=" + CompanyID, { // data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    BindFactoryPhotoList(CompanyID) {
        return axios.get(window.$APIBaseURL + "Master/GetSupplierFactoryPhotosList?CompanyBuyerSupID=" + CompanyID, { // data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    BindAllFactoryInformations(CompanyID) {
        return axios.get(window.$APIBaseURL + "Master/GetAllFactoryInformations?CompanyBuyerSupID=" + CompanyID, { // data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

}
export default new BuyerService();