import React, { useState, useEffect, useDebugValue } from "react";
import 'react-notifications-component/dist/theme.css';
import UploadImage from "../Common/UploadImage";
import Lightbox from "react-image-lightbox"

const CommonInternalQaInfo = ({ props, Style }) => {
    debugger
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        debugger
    }, []);
    const buyerInfo = `${Style.buyerName} - ${Style.seasonName} - ${Style.brandName}`;
    const charLimit = 42;
    const firstLine = buyerInfo.length > charLimit ? buyerInfo.substring(0, charLimit) : buyerInfo;
    const secondLine = buyerInfo.length > charLimit ? buyerInfo.substring(charLimit) : '';

    return (
        <>
            <div> {
                isOpen && (
                    <Lightbox
                        mainSrc={isOpen}
                        alt="bg image"

                        onCloseRequest={
                            () => setIsOpen(false)
                        } />
                )
            } </div>
            {Style !== undefined && (
                <div className="col-lg-12">
                    <div className="row style-row no_padding">
                        <div className="col-lg-11">
                            <div>
                                <div>


                                    <div className="style-item">
                                        <div className="title-bg">
                                            <label htmlFor="BuyerInfo"><b>Buyer Info</b></label><br />

                                            <span>{firstLine}</span>
                                            {secondLine && (
                                                <>
                                                    <br />
                                                    <span>{secondLine}</span>
                                                </>
                                            )}
                                        </div>

                                        <div className="title-bg" style={{ marginLeft: "35px" }}>
                                            <label htmlFor="BuyerInfo"><b>Style Name</b></label><br />
                                            <span >{Style.styleName}</span>
                                        </div>
                                        <div className="title-bg" style={{ marginLeft: "35px" }} >
                                            <label htmlFor="BuyerInfo"><b>Entry No.</b></label><br />
                                            <span >{Style.reportType} - {Style.qcInspectionID}</span>

                                        </div>

                                        {/* <>
                                            {
                                                props.location.state.params.type === 1 ? ( */}
                                        {/* <> */}
                                        <div className="title-bg" style={{ marginLeft: "35px" }} >
                                            <label htmlFor="BuyerInfo"><b>Report Date</b></label><br />
                                            <span >{Style.reportDate}</span>

                                        </div>
                                        {/* </>
                                                    ) : (
                                                        ""
                                                    )
                                                }
                                            
                                        </> */}

                                        <div className="title-bg" style={{ marginLeft: "35px" }}>
                                            <label htmlFor="BuyerInfo"><b>Suppiler & Factory</b></label><br />
                                            <span >{Style.supplierName} & {Style.factory}</span>
                                        </div>
                                        <div className="title-bg" style={{ marginLeft: "35px" }}>
                                            <label htmlFor="BuyerInfo"><b>Report Status</b></label><br />
                                            <b className={Style.reportstatus === 'Draft' ? 'warning' : Style.reportstatus === 'Save' ? 'success' : ''}>
                                                {Style.reportstatus}
                                            </b>
                                        </div>
                                        {(props.location.state.params.TaskType === "In and Mid Line Inspection" || props.location.state.params.TaskType === "PP Meeting") ? (


                                            <div className="title-bg" style={{ marginLeft: "35px" }} >
                                                <label htmlFor="BuyerInfo"><b>Review Status</b></label><br />
                                                <b className={Style.reviewStatus === 'Pending' ? 'danger' : Style.reviewStatus === 'Completed' ? 'success' : ''}>
                                                    {Style.reviewStatus}
                                                </b>
                                            </div>
                                        ):''}
                                     
                                        {(props.location.state.params.TaskType ==="Pilot Run" || props.location.state.params.TaskType ==="Size Set Check" || props.location.state.params.TaskType ==="Size Set check") ?(
                                        <div className="title-bg" style={{ marginLeft: "35px" }} >
                                            <label htmlFor="BuyerInfo"><b>Approval Status</b></label><br />
                                            <b className={Style.approvalResult === 'Pending' ? 'blue' : Style.approvalResult === 'Approved' ? 'success' : Style.approvalResult === 'Approved With Comments' ? 'warning':Style.approvalResult === 'Rejected' ? 'danger':''}>
                                                {Style.approvalResult}
                                            </b>
                                        </div>
                                        ):''
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1">
                            <img className="img-fluid"
                                src={Style.styleLogoPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images" + Style.styleLogoPath}
                                alt="Style Pic"
                                onClick={() => setIsOpen(Style.styleLogoPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images" + Style.styleLogoPath)}
                                style={{ height: '60px', float: "right", marginBottom: "5px" }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default CommonInternalQaInfo;