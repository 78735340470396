import {
    BRAND_WISE_STANDARD_MASTER_LOADING,
    RETRIEVE_BRAND_WISE_STANDARD_MASTER,
    DELETE_BRAND_WISE_STANDARD_MASTER,
} from "../actions/types";


const initialState = {
    isLoadingBrandwiseStandard: true,
    BrandWiseStandardList: [],
};

const BrandWiseStandardReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case BRAND_WISE_STANDARD_MASTER_LOADING:
            return {
                ...state,
                isLoadingBrandwiseStandard: payload
            };
        case RETRIEVE_BRAND_WISE_STANDARD_MASTER:
            return { ...state, BrandWiseStandardList: payload };
        case DELETE_BRAND_WISE_STANDARD_MASTER:
            const results = state.BrandWiseStandardList.filter(c => c.id !== payload.QCCommentPoint);
            return {
                ...state,
                BrandWiseStandardList: results
            };

        default:
            return state;
    }
};
export default BrandWiseStandardReducer;