import React, { useState, useEffect, useRef, Fragment } from "react";
import Nodify from "../../Common/ReactNotification";
import { useSelector } from "react-redux";
import ReactNotification from 'react-notifications-component';
import BuyerService from "../../../services/Master/BuyerService"
import SelectCombobox from 'react-select';
import SearchRetain from "../../Common/SearchRetain";
import AqlService from "../../../services/Master/AqlService";
import Aqlfinalinspection from "../../../services/Master/Aqlfinalinspection";
import styleService from "../../../services/Style/styleService"


const EditAqlFinalInspection = (props) => {

    const [ExistingList, setExistingList] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getBuyerDetails, setBuyerDetails] = useState([]);
    const [getBuyerBrandDetails, setBuyerBrandDetails] = useState([]);
    const [getAqlFinalinspeclist, setAqlFinalinspeclist] = useState([]);
    const [InspectionList, setInspectionList] = useState([]);
    const { user: currentUser } = useSelector((state) => state.auth);

    const values = [...getAqlFinalinspeclist]
    useEffect(() => {
        let BuyerDetail = [];
        let respected_Brand = [];
        BuyerService.LoadBuyerList(2).then((res) => {
            if (res.data) {
                res.data.map(x => {
                    BuyerDetail.push({
                        label: x.name,
                        value: x.id
                    })
                })

                setBuyerDetails(BuyerDetail)
            }
        })

        Aqlfinalinspection.GetAqlFinalInspection(0, 2).then((res) => {
            if (res.data.length > 0) {
                setExistingList(res.data)
            }
        })

        styleService.LoadBrandThemeStoryEndCustomer(6, 0).then((res) => {
            if (res.data) {
                respected_Brand = res.data.filter(x => (x.buyerID === props.location.state.params.buyerID) && (x.buyerMapID === 1));
            }
            
            setBuyerBrandDetails(respected_Brand);
        })

        Aqlfinalinspection.GetAqlFinalInspection(props.location.state.params.buyerID, 2).then((res) => {
            if (res.data.length > 0) {
                
                setAqlFinalinspeclist(res.data)
            }
        })

        let InspectionLevel = [];
        AqlService.getAllAqlDpList(0).then((res) => {
           
            if (res.data) {
                res.data.map((x, index) => {
                    InspectionLevel.push({
                        label: x.inspectionLevel,
                        value: index + 1
                    })
                })
                setInspectionList(InspectionLevel)
                // setInspectionList([...new Map(InspectionLevel.sort((a, b) => b.value - a.value).map((item, index) =>
                //     [item['label'], item])).values()]);
            }
        })
    }, [])

    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),

    }

    const optionsMajorAql = [
        { value: 1, label: '1' },
        { value: 2, label: '1.5' },
        { value: 3, label: '2.5' },
        { value: 4, label: '4' },
        { value: 5, label: '6.5' },
        { value: 6, label: '10' },
        { value: 7, label: '15' },
    ]

    function handleRemoveFields(index) {
        values.splice(index, 1);
        setAqlFinalinspeclist(values)
    }

    function handleAddFields(event, index) {
        let isvalid = true;
        if (isvalid) {
            if (values[index].inspectionLevelID !== 0 && values[index].brandID !== 0 && values[index].inspectionStandard !== '' &&
                values[index].majorAQLID !== 0 && values[index].minorAQLID !== 0) {
                values.push({
                    AqlInspectionID: 0, inspectionLevelID: 0, inspectionLevel: '', brandID: 0, brandName: '',
                    inspectionStandard: '', majorAQL: '', majorAQLID: 0, minorAQLID: 0, minorAQL: '',
                    isSelected: 0
                });
                setAqlFinalinspeclist(values);
                setSubmitted(false);
            }
            else {
                Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
                setSubmitted(true);
            }
        }
    };

    function CheckDuplicate(index) {
        for (var j = 0; j < (values.length); j++) {
            if (j !== index) {
                if (values[j].inspectionStandard.toLowerCase().trim() === values[index].inspectionStandard.toLowerCase().trim()) {
                    values[index].inspectionStandard = '';
                    setAqlFinalinspeclist(values);
                    setSubmitted(true);
                    //isvalid = false;
                    Nodify('Warning!', 'warning', 'These details are already exists, please enter differently');
                    return false;
                }
            }
        }
        for (var j = 0; j < (values.length); j++) {
            if (j !== index) {
                if (values[j].brandID === values[index].brandID &&
                    values[j].inspectionLevelID === values[index].inspectionLevelID
                    && values[j].inspectionStandard.toLowerCase().trim() === values[index].inspectionStandard.toLowerCase().trim()
                    && values[j].minorAQLID === values[index].minorAQLID
                    && values[j].majorAQLID === values[index].majorAQLID) {
                    values[index].brandID = 0;
                    values[index].brandName = '';
                    values[index].inspectionStandard = '';
                    values[index].inspectionLevelID = 0;
                    values[index].inspectionLevel = '';
                    values[index].majorAQLID = 0;
                    values[index].majorAQL = '';
                    values[index].minorAQLID = 0;
                    values[index].minorAQLID = 0;
                    values[index].minorAQL = ''
                    setAqlFinalinspeclist(values);
                    Nodify('Warning!', 'warning', 'These details are already exists, please enter differently');
                    return false;
                }

            }
        }
    }

    function handleOnSelect(e, fields, index) {
        values.map(x => {
            x.isSelected = 0
        })
        if (e.target.checked) {
            values[index].isSelected = 1
        }
        else {
            values[index].isSelected = 0
        }

        setAqlFinalinspeclist(values);
    }

    function handleInputChange(index, event, FieldName) {
       
        if (FieldName === "Brand") {
            if (event !== null) {
                values[index].brandID = event.value;
                values[index].brandName = event.label;
            } else {
                values[index].brandID = 0;
                values[index].brandName = ''
            }
        } else if (FieldName === "InspectionStandard") {
            values[index].inspectionStandard = event.target.value;
        } else if (FieldName === "InspectionLevel") {
            if (event !== null) {
                values[index].inspectionLevelID = event.value;
                values[index].inspectionLevel = event.label;
                //GetMajorminorList(event.label)
            } else {
                values[index].inspectionLevelID = 0;
                values[index].inspectionLevel = ''
            }
        }
        else if (FieldName === "MajorAQL") {
            if (event !== null) {
                values[index].majorAQLID = event.value;
                values[index].majorAQL = event.label;
            } else {
                values[index].majorAQLID = 0;
                values[index].majorAQL = ''
            }
        }
        else {
            if (event !== null) {
                values[index].minorAQLID = event.value;
                values[index].minorAQL = event.label;
            } else {
                values[index].minorAQLID = 0;
                values[index].minorAQL = ''
            }
        }
        setAqlFinalinspeclist(values);
    }

    function ResetOperation(e) {
        if (e === "Back") {
            props.history.push('/AQLInspectionList');
        } else {
            window.location.reload();
        }
    }

    function PageRedirect(Func) {
        props.history.push({ pathname: "/AQLInspectionList", state: { message: Func } });
    }

    function UpdateAqlFinalInspection() {
        
        setButtonLoader(true);
        let isvalid = true;
        for (var j = 0; j < (values.length); j++) {
            if (values[j].inspectionLevelID === 0 || values[j].brandID === 0 || values[j].inspectionStandard === '' ||
                values[j].majorAQLID === 0 || values[j].minorAQLID === 0) {
                isvalid = false;
                setSubmitted(true);
                setButtonLoader(false);
                Nodify('Warning!', 'warning', 'Please fill the mandantory(*) fields');
            }
        }
        if (isvalid) {
            let AqlFinalinspec = []
            values.map((x) => {
                AqlFinalinspec.push({
                    AqlInspectionID: x.aqlInspectionID,
                    BrandID: parseInt(x.brandID),
                    BrandName: x.brandName,
                    InspectionStandard: x.inspectionStandard,
                    InspectionLevelID: x.inspectionLevelID,
                    InspectionLevel: x.inspectionLevel,
                    MajorAQLID: x.majorAQLID,
                    MajorAQL: x.majorAQL,
                    MinorAQLID: x.minorAQLID,
                    MinorAQL: x.minorAQL,
                    IsSelected: x.isSelected
                })
            })
            let Inputmaster = {
                Operation: 2, BuyerID: props.location.state.params.buyerID, BuyerName: props.location.state.params.buyerName,
                AqlFinInspecInformation: AqlFinalinspec, CreatedBy: currentUser.employeeinformationID
            }
            Aqlfinalinspection.InsertUpdateAqlFinalInspectionGrd(Inputmaster).then((res) => {
                var page = "Remove";
                SearchRetain(page);

                let Func = 'Add';
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    PageRedirect(Func);
                }
                else if (res.data.outputResult === "0") {
                    setButtonLoader(false);
                    Nodify('Warning!', 'warning', "Error Occured!");
                }
            })
        }
    }

    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">Edit AQL Inspeciton</h3>
                        </div>

                        <div className="widget-body">
                            <div className="row">


                                <div className="col-lg-3">
                                    <label>Buyer<span className="text-danger">*</span></label>
                                    <SelectCombobox className="basic-single" name="Buyer" id={"Buyer"}
                                        isDisabled={true}
                                        isLoading={false}
                                        isSearchable={true}
                                        isClearable={true}
                                        autocomplete='off'
                                        placeholder="Select Buyer Detail"
                                        options={getBuyerDetails}
                                        value={getBuyerDetails.filter(function (option) {
                                            return option.value === props.location.state.params.buyerID;
                                        })}

                                    />
                                </div>
                            </div>
                            <div className="widget-body">
                                <div id="registration-form">
                                    <form>
                                        <div >
                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: "15%" }}>Brand<span className="text-danger">*</span></th>
                                                        <th style={{ width: "15%" }}>Inspection Standard<span className="text-danger">*</span></th>
                                                        <th style={{ width: "15%" }}>Inspection Level<span className="text-danger">*</span></th>
                                                        <th style={{ width: "15%" }}>Major AQL<span className="text-danger">*</span></th>
                                                        <th style={{ width: "15%" }}>Minor AQL<span className="text-danger">*</span></th>
                                                        <th>Default</th>
                                                        <th style={{ width: "15%" }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {getAqlFinalinspeclist.map((input, index) => (
                                                        <tr>
                                                            <td>
                                                                <SelectCombobox className="basic-single" name="Brand" id={"Brand"}
                                                                    isDisabled={false}
                                                                    isLoading={false}
                                                                    isSearchable={true}
                                                                    isClearable={true}
                                                                    autocomplete='off'
                                                                    onBlur={() => CheckDuplicate(index)}
                                                                    placeholder="Select Brand Detail"
                                                                    styles={submitted && input.brandID === 0 ? styles : ''}
                                                                    onChange={event => handleInputChange(index, event, "Brand")}
                                                                    options={getBuyerBrandDetails}
                                                                    value={getBuyerBrandDetails.filter(function (option) {
                                                                        return option.value === parseInt(input.brandID);
                                                                    })}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"InspectionStandard"}
                                                                    name="InspectionStandard"
                                                                    placeholder="Enter InspectionStandard"
                                                                    maxLength="50"
                                                                    autoComplete="off"
                                                                    value={input.inspectionStandard}
                                                                    onChange={event => handleInputChange(index, event, "InspectionStandard")}
                                                                    onBlur={() => CheckDuplicate(index)}
                                                                    style={{ border: submitted && input.inspectionStandard === "" ? '1px solid red' : '' }}
                                                                    autoFocus
                                                                />
                                                            </td>
                                                            <td>
                                                                <SelectCombobox className="basic-single" name="Brand" id={"Brand"}
                                                                    isDisabled={false}
                                                                    isLoading={false}
                                                                    isSearchable={true}
                                                                    isClearable={true}
                                                                    autocomplete='off'
                                                                    placeholder="Select InspectionLevel"
                                                                    onBlur={() => CheckDuplicate(index)}
                                                                    styles={submitted && input.inspectionLevelID === 0 ? styles : ''}
                                                                    onChange={event => handleInputChange(index, event, "InspectionLevel")}
                                                                    options={InspectionList}
                                                                    value={InspectionList.filter(function (option) {
                                                                        return option.value === input.inspectionLevelID;
                                                                    })}
                                                                />
                                                            </td>
                                                            <td>
                                                                <SelectCombobox className="basic-single" name="Brand" id={"Brand"}
                                                                    isDisabled={false}
                                                                    isLoading={false}
                                                                    isSearchable={true}
                                                                    isClearable={true}
                                                                    autocomplete='off'
                                                                    placeholder="Select MajorAQL"
                                                                    onBlur={() => CheckDuplicate(index)}
                                                                    styles={submitted && input.majorAQLID === 0 ? styles : ''}
                                                                    onChange={event => handleInputChange(index, event, "MajorAQL")}
                                                                    options={optionsMajorAql}
                                                                    value={optionsMajorAql.filter(function (option) {
                                                                        return option.value === input.majorAQLID;
                                                                    })}
                                                                />
                                                            </td>
                                                            <td>
                                                                <SelectCombobox className="basic-single" name="Brand" id={"Brand"}
                                                                    isDisabled={false}
                                                                    isLoading={false}
                                                                    isSearchable={true}
                                                                    isClearable={true}
                                                                    autocomplete='off'
                                                                    placeholder="Select MinorAQL"
                                                                    onBlur={() => CheckDuplicate(index)}
                                                                    styles={submitted && input.minorAQLID === 0 ? styles : ''}
                                                                    onChange={event => handleInputChange(index, event, "MinorAQL")}
                                                                    options={optionsMajorAql}
                                                                    value={optionsMajorAql.filter(function (option) {
                                                                        return option.value === input.minorAQLID;
                                                                    })}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input type="checkbox" name="IsSelected"
                                                                    className="checkbox clearOpacityfinal"
                                                                    value={input.isSelected}
                                                                    checked={input.isSelected === 1 ? true : false}
                                                                    onChange={event => (handleOnSelect(event, 'IsSelected', index))}
                                                                />
                                                            </td>

                                                            <td>
                                                                {index == (getAqlFinalinspeclist.length - 1) && <button type="button" className="btn  btn-xs  btn-success" title="Add Additional Task"
                                                                    onClick={(event) => handleAddFields(event, index)}
                                                                >
                                                                    <i className="fa fa-plus"></i>
                                                                </button>}
                                                                &nbsp;
                                                                {getAqlFinalinspeclist.length !== 1 && (
                                                                    <button type="button" className="btn  btn-xs  btn-danger" title="Delete Additional Task"
                                                                        onClick={() => handleRemoveFields(index)}
                                                                    >
                                                                        <i className="fa fa-trash-o"></i>
                                                                    </button>

                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))
                                                    }
                                                </tbody>
                                            </table>

                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>
                        <span className="btn btn-primary"
                            onClick={
                                () => ResetOperation("Back")
                            }>
                            <i className="fa fa-arrow-circle-left"></i>
                            &nbsp;Back</span>
                        <span className="btn btn-danger"
                            onClick={
                                () => ResetOperation()
                            }>
                            <i className="fa fa-times"></i>
                            &nbsp;Reset</span>
                        <button type="button" className="btn btn-success" disabled={buttonLoader}
                            onClick={UpdateAqlFinalInspection}>

                            <i className="fa fa-check right"></i>
                            &nbsp;Update</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditAqlFinalInspection