import {
    RETRIEVE_ALLMENU,
    RETRIEVE_CUBEEMP,
    RETRIEVE_SUPEMP,
} from "./types";

import CommonService from "../services/Common/CommonService";

export const loadMenu = (EmpId) => async (dispatch) => {
    let resData = [];
    await CommonService.GetMenuList(EmpId).then((res) => {
        resData = res.data;

        dispatch({
            type: RETRIEVE_ALLMENU,
            payload: resData,
        });
        localStorage.setItem("menuList", JSON.stringify(res.data));

    }).catch((err) => { return Promise.reject(err); });
    return Promise.resolve(resData);
};

export const loadCubeBuyerSupplierEmp = (Operation) => async (dispatch) => {
    try {
        let resData = [];
        await CommonService.GetCubeSupList(Operation).then((res) => {
            resData = res.data;
            if (Operation === 1) {
                dispatch({
                    type: RETRIEVE_CUBEEMP,
                    payload: resData,
                });

            }
            else if (Operation === 3) {
                dispatch({
                    type: RETRIEVE_SUPEMP,
                    payload: resData,
                });
            }
        })

        return Promise.resolve(resData);


    } catch (err) {
        return Promise.reject(err);
        //console.log(err);
    }

};


