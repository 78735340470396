import {
    GENDER_LOADING,
    RETRIEVE_GENDER,
    DELETE_GENDER,
} from "./types";
import GenderService from "../services/Master/GenderService";

const genderLoading = (isStatus) => ({
    type: GENDER_LOADING,
    payload: isStatus,
});

export const deleteGender = (genderSizeID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_GENDER,
            payload: genderSizeID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrieveGender = (genderSizeID) => async (dispatch) => {
    try {
        dispatch(genderLoading(true));
        const res = await GenderService.getGenderSizeList(genderSizeID);
        dispatch({
            type: RETRIEVE_GENDER,
            payload: res.data,
        });
        dispatch(genderLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(genderLoading(false));
    }
};