import {
 CSRTEMPLATEDETAILS_LOADING,
 RETRIEVE_CSRTEMPLATEDETAILS,
 DELETE_CSRTEMPLATEDETAILS,
} from "../actions/types";

const initialState = {
 isLoadingtemplate: true,
 csrtemplatelist: [],
 csrtemplateedit: [],
}

const CSRTemplateReducer = (state = initialState, action) => {
  
 const { type, payload } = action
 switch (type) {
  case CSRTEMPLATEDETAILS_LOADING:
   return {
    ...state,
    isLoadingtemplate: payload
   };
  case RETRIEVE_CSRTEMPLATEDETAILS:
   return {
    ...state,
     csrtemplatelist: payload.csrTemplateList1,
     csrtemplateedit: payload.csrtemplatesEdit2
   }
  case DELETE_CSRTEMPLATEDETAILS:

     let results = state.csrtemplatelist.filter(c => c.brandID !== payload.brandID);
   return {
    ...state,
    csrtemplatelist: results
   }
  default:
   return state

 }

}
export default CSRTemplateReducer