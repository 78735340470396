import axios from "axios";
import authHeader from "../auth-header";

class WorkmanshipService {
    GetWorkmanshipItemList() {
        return axios.get(window.$APIBaseURL + "Master/GetWorkmanshipItemList?workmanshipItemID=0", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateWorkmanshipItem = async (Params) => {
        return await axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateWorkmanshipItem',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }




}

export default new WorkmanshipService()