import {
 CSRDETAILS_LOADING,
 RETRIEVE_CSRDETAILS,
 DELETE_CSRDETAILS,
 CSRDROPDOWNDETAILS_LOADING,
 RETRIEVE_CSRDROPDOWNDETAILS,
 DELETE_CSRDROPDOWNDETAILS
}

 from "../actions/types"

const initialState = {

 isLoadingCsrDetails: true,
 csrDetailsList: [],
};

const initialStateone = {

 isLoadingCsrDropdownDetails: true,
 csrDropdownDetailsList: [],
};



export  const CsrDetailsReducer = (state = initialState, action) => {
 const { type, payload } = action;
 switch (type) {
  case CSRDETAILS_LOADING:
   return {
    ...state,
    isLoadingCsrDetails: payload
   };

  case DELETE_CSRDETAILS:
   
   const results = state.csrDetailsList.filter(c => c.itemID !== payload.itemID);
   return {
    ...state,
    csrDetailsList: results
   };
  case RETRIEVE_CSRDETAILS:
   return { ...state, csrDetailsList: payload };
  default:
   return state;
 }

}


export  const CsrdropdownDetailsReducer = (state = initialStateone, action) => {
 const { type, payload } = action;
 switch (type) {
  case CSRDROPDOWNDETAILS_LOADING:
   return {
    ...state,
    isLoadingCsrDropdownDetails: payload
   };

  case DELETE_CSRDROPDOWNDETAILS:

   const results = state.csrDropdownDetailsList.filter(c => c.itemID !== payload.itemID);
   return {
    ...state,
    csrDropdownDetailsList: results
   };
  case RETRIEVE_CSRDROPDOWNDETAILS:
   return { ...state, csrDropdownDetailsList: payload };
  default:
   return state;
 }

}




// export default {CsrDetailsReducer,CsrdropdownDetailsReducer};