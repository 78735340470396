import axios from "axios";
import authHeader from "../auth-header";

class StateService {
    GetStateList() {
        return axios.get(window.$APIBaseURL + "Master/GetStateList?StateID=0", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateStateGrid(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateStateGrid',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateState(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateState',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

}
export default new StateService()