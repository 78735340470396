
import React, { useState, useEffect, useMemo, Fragment } from "react";
import ReactNotification from 'react-notifications-component';
import Nodify from "../../Common/ReactNotification"
import CategoryService from "../../../services/Master/CategoryService";
import validator from 'validator'
import SearchRetain from "../../Common/SearchRetain";
import { useSelector } from "react-redux";
import { TableHeader, Pagination, Search, PageCount } from "../../Datatable";
import { Modal, Button } from "react-bootstrap";
import { select } from "react-validation/build/select";
import Packageitem from "../../../services/Master/Packageitem";
const AddPackageItem = (props) => {
    const EmptyInputFields =
        [{
            PackageItemID: 0,
            PackageItemName: '',
            IndexName: '0',
            IsSelected: 0,

            Dimension: [{
                DimensionID: 0,
                Height: '',
                Width: '',
                Length: '',
                UOM: 0,
                MainIndexName: '0',
                ChildIndexName: '0',
                Isdeleted: 0,
            }],
        }]
    const [inputfeild, setinputfeild] = useState(EmptyInputFields);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [submittedCS, setSubmittedCS] = useState(false);
    const [Dimension, setDimension] = useState(false);
    const [getUOMType, setUOMType] = useState([]);
    const [getExisting, setExisting] = useState([]);
    const { user: currentUser } = useSelector((state) => state.auth);

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/Package');
        } else {
            window.location.reload();
        }
    }

    useEffect(() => {
        Packageitem.GetPackageItemList(1, 0, 0).then((res) => {
            if (res.data) {
                setExisting(res.data.inputPackageItemList1)
            }
        })
        CategoryService.LoadUOMTypeList().then((response) => {
            if (response.data) {
                setUOMType(response.data);
            }
        }).catch(() => { });
    }, [])

    //Enter Error msg
    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                //SaveBuyerInfo(event);
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);

    const handleClose = (index) => {
        const values = [...inputfeild];
        let isvalid = true;
        for (var j = 0; j < values[index].Dimension.length; j++) {
            if (values[index].Dimension[j].Height === '' || values[index].Dimension[j].Width === '' ||
                values[index].Dimension[j].Length === '' || values[index].Dimension[j].UOM === 0) {
                isvalid = false;
                setSubmittedCS(true);
                Nodify('Warning!', 'warning', 'Please fill the mandantory(*) fields');
            }
        }
        if (isvalid) {
            values[index].closeCheck = 1;
            Nodify('Success!', 'success', 'Dimension Saved successfully');
            setDimension(false)
        }
        setinputfeild(values);

    }

    const CheckDataDimension = (index) => {
        const values = [...inputfeild];
        if (values[index].IsSelected === 1) {
            for (var j = 0; j < values[index].Dimension.length; j++) {
                if (values[index].Dimension[j].Height === '' || values[index].Dimension[j].Width === '' ||
                    values[index].Dimension[j].Length === '' || values[index].Dimension[j].UOM === 0) {
                    return false
                } else {
                    return true
                }
            }
        }
        else if (values[index].IsSelected === 0) {
            return true
        }
    }

    const handleAddFields = (index) => {
        let check = false;
        const values = [...inputfeild];
        if (values[index].PackageItemName !== "") {
            check = CheckDataDimension(index)
            if (check === true) {
                values.push({
                    PackageItemID: 0,
                    PackageItemName: '',
                    closeCheck: 0,
                    IsSelected: 0,
                    IndexName: (index + 1).toString(),
                    Dimension: [{
                        DimensionID: 0,
                        Height: '',
                        Width: '',
                        Length: '',
                        UOM: 0,
                        MainIndexName: (index + 1).toString(),
                        ChildIndexName: '0',
                        Isdeleted: 0
                    }],
                });
            }
            else {
                Nodify('Warning!', 'warning', 'Please fill the Selected Dimension');
                setSubmitted(true);
            }

            setinputfeild(values);
            setSubmitted(false);
        }
        else {
            Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields');
            setSubmitted(true);
        }
    };

    const handleRemoveFields = index => {
        const values = [...inputfeild];
        values.splice(index, 1);
        setinputfeild(values);
    };

    const handleDimension = (index) => {
        setDimension(true)
    }
    const setDimensionpop = (index) => {
        const values = [...inputfeild];
        if (values[index].closeCheck === 1) {
            setDimension(false)
        }
        else {
            for (var i = 0; i < values[index].Dimension.length; i++) {
                values[index].Dimension[i].Height = '';
                values[index].Dimension[i].Width = '';
                values[index].Dimension[i].Length = '';
                values[index].Dimension[i].UOM = 0;
            }
            values[index].closeCheck = 0;
        }
        setSubmittedCS(false)
        setinputfeild(values)
        setDimension(false)
    }

    const handleChildRemoveFields = (index, childindex) => {
        const values = [...inputfeild];
        //values[index].Dimension[childindex].IsSaved = 0;
        values[index].Dimension.splice(childindex, 1);
        setinputfeild(values);
    }

    const handleChildAddFields = (index, childindex) => {
        setSubmittedCS(false);
        const values = [...inputfeild];
        if (values[index].Dimension[childindex].Height === '' || values[index].Dimension[childindex].Width === '' ||
            values[index].Dimension[childindex].Length === '' || values[index].Dimension[childindex].UOM === 0) {
            setSubmittedCS(true)
            Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.')
        }
        else {
            values[index].Dimension.push({
                DimensionID: 0,
                Height: '',
                Width: '',
                Length: '',
                UOM: 0,
                MainIndexName: index.toString(),
                ChildIndexName: (childindex + 1).toString(),
                Isdeleted: 0,
            })
        }
        setinputfeild(values)
    }

    function RestrictFirstZero(e) {
        if (e.target.value.length == 0 && e.which == 48) {
            e.preventDefault();
            return false;
        }
    };

    const handleChildInputChange = (index, childindex, event, Feildname) => {
        const values = [...inputfeild];
        let inputtext = "";
        if (event.target.value !== '') {
            inputtext = event.target.value.trim();
        }
        if (Feildname === "Height") {
            if (event.currentTarget.value !== '') {
                values[index].Dimension[childindex].Height = inputtext
            } else {
                values[index].Dimension[childindex].Height = '';
            }

        }
        else if (Feildname === "Width") {
            if (event.currentTarget.value !== '') {
                values[index].Dimension[childindex].Width = inputtext
            } else {
                values[index].Dimension[childindex].Width = '';
            }

        }
        else if (Feildname === "Length") {
            if (event.currentTarget.value !== '') {
                values[index].Dimension[childindex].Length = inputtext
            } else {
                values[index].Dimension[childindex].Length = '';
            }

        } else {
            if (event.currentTarget.value !== '') {
                values[index].Dimension[childindex].UOM = parseInt(event.currentTarget.value);
            } else {
                values[index].Dimension[childindex].UOM = 0;
            }
        }
        setinputfeild(values)
    }
    const handleOnSelect = (e, Feildname, index) => {
        const values = [...inputfeild]
        if (e.target.checked === true) {
            values[index].IsSelected = 1
        } else {
            values[index].IsSelected = 0
        }
        setinputfeild(values)
    }
    const handleInputChange = (index, event, FieldName) => {

        let inputText = '';
        if (event.currentTarget.value.trim() !== '') {
            inputText = event.currentTarget.value;
        }
        const values = [...inputfeild];
        if (FieldName === "PackageItem") {
            if (event !== null) {
                values[index].PackageItemName = inputText
            } else {
                values[index].PackageItemName = ''
            }
        }
        setinputfeild(values)
    }

    const CheckDuplicate = (index, Feildname) => {
        const values = [...inputfeild];
        let isExisting = !!getExisting.find(d => d.packageItemName.trim().toLowerCase() === values[index].PackageItemName.trim().toLowerCase())
        if (isExisting) {
            values[index].PackageItemName = '';
            setinputfeild(values);
            Nodify('Warning!', 'warning', "This PackageItem Name is already exist.");
            return false;
        }
        for (var j = 0; j < (inputfeild.length); j++) {
            if (j !== index) {
                if (values[j].PackageItemName.trim().toLowerCase() === values[index].PackageItemName.trim().toLowerCase()) {
                    values[index].PackageItemName = '';
                    setinputfeild(values);
                    Nodify('Warning!', 'warning', 'This PackageItem  is already exist.');
                    setSubmitted(true);
                    return false;
                }
            }
        }
    }

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/Package",
            state: {
                message: Func
            }
        });
    }

    const SavepackageItem = (e) => {
        setButtonLoader(true);
        e.preventDefault();
        let IsValid = true;
        if (inputfeild.length === 1 && inputfeild[0].PackageItemName === '') {
            setButtonLoader(false);
            setSubmitted(true);
            Nodify('Warning!', 'warning', "Please fill at least one Task.");
        }
        else {
            for (var i = 0; i < inputfeild.length; i++) {
                if (inputfeild[i].PackageItemName === '') {
                    setButtonLoader(false);
                    setSubmitted(true);
                    IsValid = false;
                    Nodify('Warning!', 'warning', 'Please fill This PackageItem.');
                    return false;
                }
                if (inputfeild[i].IsSelected === 1) {
                    for (var j = 0; j < inputfeild[i].Dimension.length; j++) {
                        if (inputfeild[i].Dimension[j].Height === '' || inputfeild[i].Dimension[j].Width === '' ||
                            inputfeild[i].Dimension[j].Length === '' || inputfeild[i].Dimension[j].UOM === 0) {
                            setButtonLoader(false);
                            setSubmittedCS(true);
                            IsValid = false;
                            Nodify('Warning!', 'warning', 'Please fill The Carton Dimension Popup.');
                            return false;
                        }
                    }
                }
            }
            if (IsValid === true) {
                const GetTaskDetails = { Operation: 1, PackageItemInfoID: 0, PackageInformation: [], DimensionInformation: [], Createdby: currentUser.employeeinformationID };
                inputfeild.forEach((Info, i) => {
                    GetTaskDetails.PackageInformation.push({
                        PackageItemID: 0,
                        PackageItemName: Info.PackageItemName,
                        IndexName: i.toString(),
                        IsSelected: Info.IsSelected
                    })
                    if (Info.Dimension) {
                        Info.Dimension.forEach((child, j) => {
                            if (inputfeild[i].Dimension[j].Height !== '' || inputfeild[i].Dimension[j].Width !== '' ||
                                inputfeild[i].Dimension[j].Length !== '' || inputfeild[i].Dimension[j].UOM !== 0) {
                                GetTaskDetails.DimensionInformation.push({
                                    DimensionID: 0,
                                    Height: child.Height,
                                    Width: child.Width,
                                    Length: child.Length,
                                    UOM: child.UOM,
                                    MainIndexName: i.toString(),
                                    ChildIndexName: j.toString(),
                                    Isdeleted: 0
                                })
                            }

                        })
                    }
                })
                // console.log(GetTaskDetails)
                Packageitem.InsertUpdatePackageItemGrd(GetTaskDetails).then((res) => {
                    var page = "Remove";
                    SearchRetain(page);
                    let Func = 'Add';
                    if (res.data.outputResult === "1") {
                        Func = 'Add';
                        PageRedirect(Func);
                    } else if (res.data.outputResult === "2") {
                        Func = 'Edit';
                        PageRedirect(Func);
                    } else if (res.data.outputResult === "-2") {
                        setButtonLoader(false);
                        Nodify('Warning!', 'warning', "This name is already exist.");
                    }
                    else if (res.data.outputResult === "0") {
                        setButtonLoader(false);
                        Nodify('Warning!', 'warning', "Error Occured!");
                    }
                })
            }

        }

    }
    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <div className="widget flat radius-bordered">
                            <div className="widget-header heading_box_style">
                                <h3 className="heading_style_common">Add Package Item</h3>
                            </div>

                            <div className="widget-body">
                                <div id="registration-form">
                                    <form>
                                        {
                                            <table className="table table-striped table-bordered table-hover dataTable no-footer margin-top-10" id="editabledatatable" style={{ width: "70%" }}>

                                                <tbody>
                                                    <tr className="table_title">
                                                        <th colspan="2">Package Item Name<span className="text-danger">*</span></th>
                                                        <th>Action</th>
                                                    </tr>
                                                    {
                                                        inputfeild.map((input, index) => (
                                                            <tr>
                                                                <td style={{ width: "63%" }}>
                                                                    <input type="text"
                                                                        className="form-control"
                                                                        id={"PackageItem"}
                                                                        name="PackageItem"
                                                                        placeholder="Enter PackageItem"
                                                                        maxLength="100"
                                                                        value={input.PackageItemName}
                                                                        onChange={event => handleInputChange(index, event, "PackageItem")}
                                                                        onBlur={() => CheckDuplicate(index)}
                                                                        style={{ border: submitted && input.PackageItemName === "" ? '1px solid red' : '' }}
                                                                        autoFocus
                                                                    />
                                                                </td>
                                                                <td >
                                                                    <label className="Ajustment"><span className="text">Is Carton Box </span></label>&nbsp;&nbsp;&nbsp;
                                                                    <label style={{ marginTop: '8px' }}>
                                                                        <input type="checkbox" name="IsSelected"
                                                                            onChange={event => (handleOnSelect(event, 'IsSelected', index))}
                                                                            value={input.IsSelected === 1 ? true : false}
                                                                            checked={input.IsSelected === 1 ? true : false}
                                                                            className="clearOpacity textdesign" />
                                                                        <span className="text"> </span>
                                                                    </label>
                                                                </td>

                                                                <td style={{ width: "13%" }}>
                                                                    {
                                                                        Dimension === true ?
                                                                            <Modal show={Dimension === true} size="lg" dragable backdrop="static" keyboard={false} onHide={() => setDimensionpop(index)}>
                                                                                <Modal.Header closeButton>
                                                                                    <Modal.Title>Add Carton Dimension</Modal.Title>
                                                                                </Modal.Header>
                                                                                <Modal.Body style={{ overflow: "auto", height: "650px" }} align="center">
                                                                                    <div className="col-md-12" style={{ overflowX: "auto", minHeight: '100px' }}>
                                                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th className="new" style={{ width: "220px" }}>Height<span className="text-danger">*</span></th>
                                                                                                    <th style={{ width: "220px" }}>Width<span className="text-danger">*</span></th>
                                                                                                    <th style={{ width: "220px" }}>Length<span className="text-danger">*</span></th>
                                                                                                    <th style={{ width: "220px" }}>Uom<span className="text-danger">*</span></th>
                                                                                                    <th style={{ width: "150px" }}>Action</th>
                                                                                                </tr>

                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {
                                                                                                    input.Dimension.map((childInput, childindex) => (
                                                                                                        <>
                                                                                                            <tr>
                                                                                                                <td style={{ width: "230px" }}>
                                                                                                                    <input
                                                                                                                        className="form-control"
                                                                                                                        id={"Height"}
                                                                                                                        name="Height"
                                                                                                                        placeholder="Enter Height"
                                                                                                                        maxLength="50"
                                                                                                                        value={childInput.Height}
                                                                                                                        autocomplete="off"
                                                                                                                        onChange={event => handleChildInputChange(index, childindex, event, "Height")}
                                                                                                                        onKeyPress={event => RestrictFirstZero(event)}
                                                                                                                        //onBlur={() => CheckDuplicate(index, 'Buyer')}
                                                                                                                        style={{ border: submittedCS && childInput.Height === '' ? '1px solid red' : '' }}
                                                                                                                        autoFocus />
                                                                                                                </td>
                                                                                                                <td style={{ width: "230px" }}>
                                                                                                                    <input
                                                                                                                        className="form-control"
                                                                                                                        id={"Width"}
                                                                                                                        name="Width"
                                                                                                                        placeholder="Enter Width"
                                                                                                                        maxLength="50"
                                                                                                                        value={childInput.Width}
                                                                                                                        autocomplete="off"
                                                                                                                        onChange={event => handleChildInputChange(index, childindex, event, "Width")}
                                                                                                                        onKeyPress={event => RestrictFirstZero(event)}
                                                                                                                        //onBlur={() => CheckDuplicate(index, 'Buyer')}
                                                                                                                        style={{ border: submittedCS && childInput.Width === '' ? '1px solid red' : '' }}
                                                                                                                        autoFocus />
                                                                                                                </td>
                                                                                                                <td style={{ width: "230px" }}>
                                                                                                                    <input
                                                                                                                        className="form-control"
                                                                                                                        id={"Length"}
                                                                                                                        name="Length"
                                                                                                                        placeholder="Enter Length"
                                                                                                                        maxLength="50"
                                                                                                                        value={childInput.Length}
                                                                                                                        autocomplete="off"
                                                                                                                        onChange={event => handleChildInputChange(index, childindex, event, "Length")}
                                                                                                                        onKeyPress={event => RestrictFirstZero(event)}
                                                                                                                        //onBlur={() => CheckDuplicate(index, 'Buyer')}
                                                                                                                        style={{ border: submittedCS && childInput.Length === '' ? '1px solid red' : '' }}
                                                                                                                        autoFocus />
                                                                                                                </td>
                                                                                                                <td style={{ width: "387px" }}>
                                                                                                                    {/* <select
                                                                                                                        id={"Uom"}
                                                                                                                        name={"Uom"}
                                                                                                                        className="form-select"
                                                                                                                        value={childInput.UOM}
                                                                                                                        onChange={(event) =>
                                                                                                                            handleChildInputChange(index, childindex, event, "Uom")
                                                                                                                        }
                                                                                                                        //onBlur={() => CheckDuplicate(index, 'Sample')}
                                                                                                                        style={{ border: submittedCS && childInput.UOM === 0 ? '1px solid red' : '' }}
                                                                                                                    //disabled={getHeader === 'Add' ? false : true}
                                                                                                                    >
                                                                                                                        <option value="0">Select....</option>
                                                                                                                        <option value="1">Inches</option>
                                                                                                                        <option value="2">CMS</option>
                                                                                                                    </select> */}
                                                                                                                    <span className="input-icon icon-right">
                                                                                                                        <select id={"Uom"}
                                                                                                                            name={"Uom"}
                                                                                                                            className="form-select"
                                                                                                                            value={childInput.UOM}
                                                                                                                            onChange={event => handleChildInputChange(index, childindex, event, "Uom")}
                                                                                                                            style={{ border: submittedCS && childInput.UOM === 0 ? '1px solid red' : '' }}
                                                                                                                        >
                                                                                                                            <option value="0">-Select UOM-</option>
                                                                                                                            {
                                                                                                                                getUOMType.map(item1 => (
                                                                                                                                    <option key={item1.index}
                                                                                                                                        value={item1.id}>
                                                                                                                                        {item1.name}
                                                                                                                                    </option>
                                                                                                                                ))
                                                                                                                            }
                                                                                                                        </select>
                                                                                                                    </span>
                                                                                                                </td>
                                                                                                                <td style={{ width: "220px" }}>
                                                                                                                    {inputfeild[index].Dimension.length !== 1 && (
                                                                                                                        <span title='Delete Workmanship Group' onClick={() => handleChildRemoveFields(index, childindex)}
                                                                                                                            className="btn btn-danger btn-xs delete">
                                                                                                                            <i className="fa fa-trash-o"></i>
                                                                                                                        </span>
                                                                                                                    )

                                                                                                                    }
                                                                                                                    &nbsp; &nbsp;
                                                                                                                    {inputfeild[index].Dimension.length === (childindex + 1) && (
                                                                                                                        <span title='Add Workmanship Group' onClick={() => handleChildAddFields(index, childindex)}
                                                                                                                            className="btn btn-info btn-xs plus">
                                                                                                                            <i className="fa fa-plus"></i>
                                                                                                                        </span>
                                                                                                                    )}
                                                                                                                </td>
                                                                                                            </tr>

                                                                                                        </>
                                                                                                    ))
                                                                                                }

                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>

                                                                                </Modal.Body>
                                                                                <Modal.Footer>
                                                                                    <Button variant="success" onClick={() => handleClose(index, "Save")} >
                                                                                        Save
                                                                                    </Button>
                                                                                </Modal.Footer>

                                                                            </Modal> : ''
                                                                    }
                                                                    {<span title='Add Workmanship Group' onClick={() => handleAddFields(index)}
                                                                        className="btn btn-info btn-xs plus">
                                                                        <i className="fa fa-plus"></i>
                                                                    </span>
                                                                    }
                                                                    &nbsp;
                                                                    {inputfeild.length !== 1 && (
                                                                        <span title='Delete Workmanship Group' onClick={() => handleRemoveFields(index)}
                                                                            className="btn btn-danger btn-xs delete">
                                                                            <i className="fa fa-trash-o"></i>
                                                                        </span>
                                                                    )}
                                                                    &nbsp;
                                                                    {input.IsSelected === 1 && (
                                                                        <span title='Dimension' onClick={() => handleDimension(index)}
                                                                            className="btn btn-danger btn-xs delete">
                                                                            <i className="fa fa-fire"></i>
                                                                        </span>
                                                                    )}


                                                                </td>
                                                                {/* <span> {index}</span> */}
                                                            </tr>
                                                        ))
                                                    }

                                                </tbody>
                                            </table>
                                        }

                                        <div className="col-sm-12 margin-top-20">
                                            <div className="buttons-preview" align="right">
                                                <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                                    <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                                                </span>
                                                <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                                    <i className="fa fa-times"></i>&nbsp;Reset
                                                </span>
                                                <button type="button" className="btn btn-success" disabled={buttonLoader} onClick={SavepackageItem}>
                                                    <i className="fa fa-check right"></i>&nbsp;Save
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AddPackageItem;