import React, { useState } from "react";

const ScheduleHeader = ({ headers, onSorting }) => {
    const [sortingField, setSortingField] = useState("");
    const [sortingOrder, setSortingOrder] = useState("asc");
    const [sortingIcon, setSortingIcon] = useState("");


   
    const onSortingChange = (field) => {
        const order =
            (field === sortingField && sortingOrder === "asc") ? "desc" : "asc";
        setSortingField(field);
        setSortingOrder(order);
        setSortingIcon(order);
        onSorting(field, order);
    };

    return (
        <thead >
            <tr className={"Table_Reportschedule"} >
                {headers.map(({ name, field, sortable, widthsize, display }) => (
                    <th className={sortable === true ? (sortingField === field ?
                        (sortingIcon === "asc"
                            ? "sorting_desc"
                            : sortingIcon === "desc" ? "sorting_asc" : "sorting") : "sorting") : "col-action-or-other"
                    }
                        key={name}
                        onClick={() =>
                            sortable ? onSortingChange(field) : null
                        }
                        //  style={{ width: name === "Action" ? "10%" : "" }}
                        style={{ width: widthsize === undefined ? (name === "Action" ? "10%" : "") : (name === "Action" ? "10%" : widthsize), display: display }}
                    >
                        {name}&nbsp;&nbsp;&nbsp;&nbsp;
                    </th>
                ))}
            </tr>
        </thead>
    );
};

export default ScheduleHeader;
