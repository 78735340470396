import React, { useState, useEffect, Fragment } from "react";
import SweetAlertPopup from '../../Common/SweetAlertPopup';
import { TableHeader } from "../../Datatable";
import Form from "react-validation/build/form";
import $ from "jquery";
import Nodify from "../../Common/ReactNotification";
import { MeasurementID } from "../../Common/PageActionNumber";
import CommonService from "../../../services/Common/CommonService";
import measurementService from "../../../services/Style/measurementService";
import { useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';
import Loader from "react-loader-spinner";
import Measurement from "./Measurement";
import StrikeoffService from "../../../services/Style/StrikeoffService";

const MeasurementList = ({ props, MeasurementRedirect, MeasurementList, MeasurementCallback, StyleInfo, ValidationCallBack, setSizeListmeasurement, IsValidationCallback, MeasurementInfo }) => {

    const [getID, setID] = useState(0);
    const [getres, setres] = useState();
    const StyleID = StyleInfo.styleID !== undefined ? StyleInfo.styleID : StyleInfo.StyleID;
    const { user: currentUser } = useSelector((state) => state.auth);
    const [inputFields, setInputFields] = useState([]);
    const [expandState, setExpandState] = useState(false);
    const [expandedRows, setExpandedRows] = useState([]);
    const [expandMeasurementCategoryList, setexpandMeasurementCategoryList] = useState([]);
    const [expandMeasurementCategoryPointsList, setexpandMeasurementCategoryPointsList] = useState([]);
    const [GradingSize, setGradingSize] = useState([]);
    const [getIsDeleted, setIsDeleted] = useState(false);



    const [SizeValues, setSizeValues] = useState([
        {

            StyleMeasurementID: 0,
            MeasurementSpec: '',
            MeasurementGrandingSize: '',
            IsDeleted: 0,
            MeasurementOperation: 1,
            Category: [{
                StyleMeasurementCategoryID: 0,
                CategoryName: '',
                MeasurementCategoryID: 0,
                PointsUom: 1,
                IsDeleted: 0,
                IndexName: '0',
                MeasuremnentSpecImportName: '',
                MeasuremnentSpecImportPath: '',
                Point: [{
                    MeasurementPointName: '',
                    MeasurementPoints: '',
                    MeasurementGranding: '',
                    TolerancePlus: '',
                    ToleranceMinus: '',
                    GradingSizes: '',
                    GradingSizesId: '',
                    GradingSizesValue: '',
                    arrGradingSizes: [],
                    Remark: '',
                    IndexName: '0',
                    IsDeleted: 0,
                }]
            }],


        }
    ]);

    const values = [...SizeValues];

    useEffect(() => {
        setres(1);
        measurementService.GetMeasurement(StyleID).then((response) => {
            if (response.data) {
                setInputFields(response.data);
                if (response.data.length !== 0) {
                    handleExpandRow(response.data[0].styleMeasurementID)
                }
                if (response.data.length === 0) {
                    setTimeout(() => {
                        setres(0);
                        setInputFields(response.data);

                        //  console.log(response.data[0].styleMeasurementID);
                    }, 1000)
                }
            }
        })


    }, []);

    useEffect(() => {
        StrikeoffService.LoadGroupSizeDetailsList(StyleID).then((response2) => {
            if (response2.data) {
                setGradingSize(response2.data);
            }
        }).catch(() => { });
    }, []);

    const headers = [
        { name: "#", field: "", sortable: false,widthsize:'2%' },
        { name: "Spec", field: "measurementSpec", sortable: false },
        { name: "Spec Reason", field: "measurementSpecReason", sortable: false },
        { name: "Created Date", field: "createddate", sortable: false },
        {
            name: "Action", field: "", sortable: false,
            display: MeasurementInfo && MeasurementInfo.isAdd === 0 && MeasurementInfo.isEdit === 0 && MeasurementInfo.isDelete === 0 ? 'none' : ''
        },
    ];
    const childHeaders = [
        { name: "CategoryType", sortable: false, widthsize: "5%" },
        { name: "End Customer", sortable: false, widthsize: "5%" }
    ];


    const pageRedirect = (Params, redirect) => {
        if (redirect === 'Edit') {
            Params.measurementOpreation = 2;
            let value = { data: Params, Page: 'ADDMEASUREMENT' }
            MeasurementRedirect(value);
        }
        if (redirect === 'Revise') {
            Params.measurementOpreation = 1;
            let value = { data: Params, Page: 'ADDMEASUREMENT' }
            MeasurementRedirect(value);
        }
        else {
            let Param = { Operation: MeasurementID, Id: Params.styleMeasurementID, };
            CommonService.CheckMappedItem(Param).then(res => {
                if (res.data.outputResult === "-2") {
                    let message = "This Measurement is already mapped.";
                    ValidationCallBack(message);
                }
                else {
                    setID({ showPopupDelete: true, Params: Params });
                }
            });
        }
    };

    if (!currentUser) { return <Redirect to="/login" />; }


    const Delcallback = (value) => {

        if (value === true) {
            setIsDeleted(true);
            measurementService.GetMeasurement(StyleID).then((response) => {
                if (response.data) {
                    setInputFields(response.data);
                    if (response.data.length !== 0) {
                        OpenFirstrowafterDelete(response.data[0].styleMeasurementID)
                    }
                    if (response.data.length === 0) {
                        setTimeout(() => {
                            setres(0);
                            setInputFields(response.data);
                        }, 1000)
                    }
                }
            }).catch(() => { });
        }
        else if (value === false) {
            setID({ showPopupDelete: false, Params: [] });
        }
    }

    const handleExpandRow = (StyleMeasurementID, IndexName) => {
        Expandrow(StyleMeasurementID);
    }


    const OpenFirstrowafterDelete = (StyleMeasurementID, IndexName) => {
        Expandrow(StyleMeasurementID);
    }

    const Expandrow = (StyleMeasurementID) => {
        setIsDeleted(false);
        setExpandedRows([]);

        measurementService.GetStyleMeasurementCategoryList(2, 0, StyleMeasurementID).then((response) => {
            if (response.data) {
                setexpandMeasurementCategoryList(response.data);

                // Expand row Start
                let currentExpandedRows = expandedRows;
                const isRowExpanded = currentExpandedRows.includes(StyleMeasurementID);
                let obj = {};
                isRowExpanded ? (obj[StyleMeasurementID] = false) : (obj[StyleMeasurementID] = true);
                setExpandState(obj);
                currentExpandedRows = [];
                const newExpandedRows = isRowExpanded ?
                    currentExpandedRows.filter(id => id !== StyleMeasurementID) :
                    currentExpandedRows.concat(StyleMeasurementID);
                setExpandedRows(newExpandedRows);
                // Expand row End
            }
        });
        let Count = 0;


        measurementService.GetStyleMeasurementCategoryPointsList(2, 0, StyleMeasurementID).then((response1) => {
            if (response1.data) {
                setexpandMeasurementCategoryPointsList(response1.data);

            }
        })

        measurementService.GetAllStyleMeasurementCatPointList(StyleMeasurementID).then((response) => {
            if (response.data) {

                if (response.data.specList.length > 0) {
                    values[0].StyleMeasurementID = response.data.specList[0].styleMeasurementID;
                    // values[0].PointsUom = response.data.specList[0].pointsUom;
                    values[0].MeasurementSpec = response.data.specList[0].measurementSpecReason;
                    values[0].MeasurementGrandingSize = response.data.specList[0].measurementGrandingSize;
                    values[0].MeasurementOperation = MeasurementList.measurementOpreation;
                    values[0].Category = [];
                }

                if (response.data.categoryList.length > 0) {
                    response.data.categoryList.forEach((category, catIndex) => {

                        //Revise
                        category.styleMeasurementCategoryID = MeasurementList.measurementOpreation === 1 ? 0 : category.styleMeasurementCategoryID;
                        values[0].Category.push({
                            StyleMeasurementCategoryID: category.styleMeasurementCategoryID,
                            CategoryName: category.categoryName,
                            MeasurementCategoryID: category.measurementCategoryID,
                            PointsUom: category.pointsUom,
                            StyleMeasurementID: category.styleMeasurementID,
                            IsDeleted: 0,
                            IndexName: category.indexName,
                            MeasuremnentSpecImportName: category.measuremnentSpecImportName,
                            MeasuremnentSpecImportPath: category.measuremnentSpecImportPath,
                            Point: []
                        });
                        var pointList = response.data.pointList.filter(x => x.indexName === category.indexName);
                        pointList.forEach((point, pointIndex) => {
                            //Revise
                            point.styleMeasurementCategoryPointsID = MeasurementList.measurementOpreation === 1 ? 0 : point.styleMeasurementCategoryPointsID;
                            values[0].Category[catIndex].Point.push({
                                StyleMeasurementCategoryPointsID: point.styleMeasurementCategoryPointsID,
                                StyleMeasurementID: point.styleMeasurementID,
                                MeasurementPoints: point.measurementPoints,
                                MeasurementPointName: point.measurePointName,
                                MeasurementGranding: point.measurementGranding,
                                TolerancePlus: point.tolerancePlus,
                                ToleranceMinus: point.toleranceMinus,
                                GradingSizes: point.gradingSizes,
                                GradingSizesId: point.gradingSizesId,
                                GradingSizesValue: point.gradingSizesValue,
                                arrGradingSizes: [],
                                Remark: point.remarks,
                                IndexName: point.indexName,
                                IsDeleted: 0,
                            });
                            let arrGradingSizes = point.gradingSizes.split(",");
                            let arrGradingSizesId = point.gradingSizesId.split(",");
                            let arrGradingSizesValue = point.gradingSizesValue.split(",");
                            let arrcount = 0;
                            arrGradingSizes.forEach((val, valIndex) => {
                                if (arrcount === 0) {
                                    arrcount++;
                                    values[0].Category[catIndex].Point[pointIndex].arrGradingSizes.push({
                                        [val]: arrGradingSizesValue[valIndex]
                                    });
                                }
                                else {
                                    values[0].Category[catIndex].Point[pointIndex].arrGradingSizes[0][val] = arrGradingSizesValue[valIndex];
                                }
                            });
                        });
                    });
                    setSizeValues(values);
                    // setGradingSize(setSizeListmeasurement);
                }
            }
        }).catch(() => { });
    }





    return (
        <>
            <div className="row">
                <div className="col-xs-12 col-md-12">
                    <div className="widget">
                        <div className="widget-body">
                            <div className="dataTables_wrapper_Measurement form-inline no-footer">
                                <div className="table-toolbar pull-left"></div>
                                {
                                    inputFields.length > 0 ?
                                        <>
                                            {
                                                <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                    <TableHeader headers={headers} />
                                                    <tbody>

                                                        {
                                                            inputFields.map((measurement, index) => (
                                                                <>
                                                                    <tr>
                                                                        <td>{index+1}</td>
                                                                        <td>
                                                                            <i
                                                                                className={
                                                                                    expandState[measurement.styleMeasurementID] || (index === 0 && getIsDeleted === true) ?
                                                                                        'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                }
                                                                                onClick={event => handleExpandRow(measurement.styleMeasurementID, measurement.indexName)}
                                                                            ></i>
                                                                            {measurement.measurementSpec}</td>
                                                                        <td>{measurement.measurementSpecReason}</td>
                                                                        <td>{measurement.createddate}</td>

                                                                        <td style={{ display: MeasurementInfo && MeasurementInfo.isAdd === 0 && MeasurementInfo.isEdit === 0 && MeasurementInfo.isDelete === 0 ? 'none' : '' }}>
                                                                            <span style={{ display: MeasurementInfo && MeasurementInfo.isAdd === 1 ? '' : 'none' }} title='Revise Measurement' onClick={() => pageRedirect(measurement, 'Revise')}
                                                                                className="btn btn-primary btn-xs Revise">
                                                                                <i className="fa fa-history"></i>
                                                                            </span>
                                                                            &nbsp;
                                                                            <span style={{ display: MeasurementInfo && MeasurementInfo.isEdit === 1 ? '' : 'none' }} title='Edit Measurement' onClick={() => pageRedirect(measurement, 'Edit')}
                                                                                className="btn btn-success btn-xs edit">
                                                                                <i className="fa fa-edit "></i>
                                                                            </span>

                                                                            &nbsp;

                                                                            <span style={{ display: MeasurementInfo && MeasurementInfo.isDelete === 1 ? '' : 'none' }} title='Delete Measurement' onClick={() => pageRedirect(measurement, '')}
                                                                                className="btn btn-danger btn-xs delete">
                                                                                <i className="fa fa-trash-o"></i>
                                                                            </span>
                                                                        </td>
                                                                    </tr>

                                                                    <>

                                                                        {

                                                                            expandedRows.includes(measurement.styleMeasurementID) || (index === 0 && getIsDeleted === true) ?
                                                                                <tr>
                                                                                    <td colSpan="4" width="100%">
                                                                                        {
                                                                                            SizeValues.map((item, index) => (


                                                                                                item.Category.map((Catitem, catIndex) => {
                                                                                                    return (
                                                                                                        <>
                                                                                                            <b> Category Type :</b><sapn style={{ color: 'green' }}><b> {Catitem.CategoryName} </b></sapn>
                                                                                                            <table className="table table-bordered" width="100%">
                                                                                                                <thead className="text-center">
                                                                                                                    <tr>
                                                                                                                        <th>#</th>
                                                                                                                        <th className="text-center " style={{ width: "51%" }}>Points</th>
                                                                                                                        <th className="text-center ">Grading</th>
                                                                                                                        <th className="text-center ">Tol +</th>
                                                                                                                        <th className="text-center ">Tol -</th>
                                                                                                                        {
                                                                                                                            GradingSize.map((size, i) => {
                                                                                                                                return (
                                                                                                                                    <th className="text-center ">{size.label}</th>
                                                                                                                                )
                                                                                                                            })
                                                                                                                        }
                                                                                                                        <th className="text-center ">Remarks</th>

                                                                                                                    </tr>
                                                                                                                </thead>
                                                                                                                <tbody>
                                                                                                                    {
                                                                                                                        Catitem.Point.map((Pointitem, pointIndex) => {
                                                                                                                            return (
                                                                                                                                <>
                                                                                                                                    <tr key={pointIndex}>
                                                                                                                                        <td>{pointIndex+1}</td>
                                                                                                                                        <td className=''>
                                                                                                                                            {Pointitem.MeasurementPointName}
                                                                                                                                        </td>
                                                                                                                                        <td className=''>
                                                                                                                                            {Pointitem.MeasurementGranding}
                                                                                                                                        </td>
                                                                                                                                        <td className=''>
                                                                                                                                            {Pointitem.TolerancePlus}
                                                                                                                                        </td>
                                                                                                                                        <td className=''>
                                                                                                                                            {Pointitem.ToleranceMinus}
                                                                                                                                        </td>
                                                                                                                                        {
                                                                                                                                            GradingSize.map((size, i) => {
                                                                                                                                                return (
                                                                                                                                                    <td>
                                                                                                                                                        {
                                                                                                                                                            Pointitem.arrGradingSizes[0] === undefined ? '' :
                                                                                                                                                                Pointitem.arrGradingSizes[0][size.label]
                                                                                                                                                        }
                                                                                                                                                    </td>

                                                                                                                                                );
                                                                                                                                            })
                                                                                                                                        }

                                                                                                                                        <td>
                                                                                                                                            {Pointitem.Remark}
                                                                                                                                        </td>

                                                                                                                                    </tr>
                                                                                                                                </>
                                                                                                                            )
                                                                                                                        })
                                                                                                                    }
                                                                                                                </tbody>
                                                                                                            </table>


                                                                                                        </>

                                                                                                    )

                                                                                                })


                                                                                            ))

                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                                : ''
                                                                        }

                                                                    </>
                                                                </>


                                                            ))


                                                        }

                                                    </tbody>
                                                </table>
                                            }




                                        </>
                                        :
                                        <>
                                            {
                                                inputFields.length === 0 && getres === 0 ?
                                                    <>
                                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                            <TableHeader headers={headers} />
                                                            <tbody>
                                                                <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                                                            </tbody>
                                                        </table>
                                                    </>
                                                    : ''
                                            }
                                            {
                                                getres === 1 ? <Loader type="ThreeDots" color="#b6da94" height={80} width={80} timeout={5000} style={{ textAlign: "center" }} /> : ''
                                            }
                                        </>
                                }
                            </div>
                        </div>
                        {
                            getID.showPopupDelete ?
                                <SweetAlertPopup data={getID.Params}
                                    deleteCallback={Delcallback}
                                    showpopup={true}
                                    pageActionId={MeasurementID}
                                    Msg={"Measurement deleted successfully."} /> : null
                        } </div>
                </div>
            </div>
        </>
    )
}
export default MeasurementList;