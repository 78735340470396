import{
 ITEMS_LOADING,
 RETRIEVE_ITEMS,
 DELETE_ITEMS
}

from"./types"


import ItemService from "../services/Master/ItemService"

const itemLoading = (isStatus) => ({
 type: ITEMS_LOADING,
 payload: isStatus,
});

export const createItem = (getItem) => async (dispatch) => {
 
 let resData = "";
 await ItemService.AddItem(getItem).then((res) => {
  resData = res.data;

 }).catch((err) => { return Promise.reject(err); });
 return Promise.resolve(resData);
}


export const deleteItem = (itemID) => async (dispatch) => {

 try {
  dispatch({
   type: DELETE_ITEMS,
   payload: itemID,
  });

 } catch (err) {
  console.log(err);
 }
};


export const retriveItem = () => async (dispatch) => {
 try {
  dispatch(itemLoading(true));
  const res = await ItemService.getAllItemList();
  dispatch({
   type: RETRIEVE_ITEMS,
   payload: res.data,
  });
  dispatch(itemLoading(false));
 } catch (err) {
  console.log(err);
  dispatch(itemLoading(false));
 }
};
