import {
    GENERALLABDIP_LOADING,
    RETRIEVE_GENERALLABDIP,
    DELETE_GENERALLABDIP,
    RETRIEVE_GENERAL_LABDIP_DETAIL_VIEW_LIST,
    CLEAR_GENERAL_LABDIP_DETAIL_VIEW_SUB_ROW,
    UPDATE_GEN_PROGRAM_GRID_LIST,
    RETRIEVE_GEN_LABDIP_ALREADY_MAP_LIST,
    RETRIEVE_GENERAL_LABDIP_SUBMISSION_LIST,
    CLEAR_GENERAL_LABDIP_SUBMISSION_SUB_ROW,
    RETRIEVE_GENERAL_LABDIP_APPROVAL_LIST,
    CLEAR_GENERAL_LABDIP_APPROVAL_SUB_ROW,
    RETRIEVE_GENERAL_LABDIP_APPROVAL_VIEW_LIST,
    CLEAR_GENERAL_LABDIP_APPROVAL_VIEW_SUB_ROW,
    UPDATE_GENERAL_LABDIP_DETAIL_VIEW_CHECK_BOX,
    NEW_TNA_GENERAL_LABDIP_LIST,
    TNA_GENERAL_LABDIP_HOLIDAY_LIST,
    REMOVE_TNA_GENERAL_LABDIP_PANTONE_SWATCH_LIST,
    TNA_GENERAL_LABDIP_SUBMISSION_AND_APPROVAL,
    GLD_TNA_TASK_OWNER_LIST,
    UPDATE_GENERAL_LAPDIP_DETAIL_VIEW_ALL_CHECK_BOX
} from "../actions/types";

const GLDStatusList = [
    { value: 1, label: 'To Do', isdisabled: false },
    { value: 2, label: 'Requested', isdisabled: false },
    { value: 3, label: 'Submitted', isdisabled: false },
    { value: 4, label: 'Approved', isdisabled: false }
];

const GLDRequestStatusList = [

    { value: 1, label: 'Pending', isdisabled: false },
    { value: 2, label: 'Completed', isdisabled: false },
];
// const GLDSubmitStatusList = [

//     { value: 3, label: 'Pending', isdisabled: false },
//     { value: 4, label: 'Completed', isdisabled: false },
// ];

const initialState = {
    isLoadingGeneralLabdip: true,
    generalLabdipList: [],
    generalLabdipViewDetailParams: {}, //**generalLabdipViewDetail**
    generalLabdipSubmissionParams: {},
    generalLabdipApprovalParams: {},
    generalLabdipApprovalViewParams: {},
    generalLabdipViewDetailList: [], //GeneralLabDipViewDetail-(GLDVD)
    programGridList: [],
    //  TNATrimMapList: [],
    allLabDipMapApproval: [],
    GENLabDipMapList: [],
    generalLabdipSubmissionList: [],
    generalLabdipApprovalList: [],
    generalLabdipApprovalViewList: [],
    // GLDVDPantoneSubList: [],
    // GLDVDCriticalList: [],
    genLabdipTNAPantoneOrSwatchList: [],
    genLabdipTNATaskList: [],
    genLapdibTNAHolidayList: [],
    genLabdipTNAAddtionalTaskList: [],
    editTNAPantoneOrSwatchList: [],
    GLDStatusList: GLDStatusList,
    GLDRequestStatusList: GLDRequestStatusList,
    drpGLDTNATaskOwnerList: [],

};

const generalLabdipReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GENERALLABDIP_LOADING:
            return {
                ...state,
                isLoadingGeneralLabdip: payload
            };
        case RETRIEVE_GENERALLABDIP:
            return { ...state, generalLabdipList: payload };
        case DELETE_GENERALLABDIP:
            const results = state.generalLabdipList.filter(c => c.genLabdipId !== payload.genLabdipId);
            return {
                ...state,
                generalLabdipList: results
            };
        case RETRIEVE_GENERAL_LABDIP_DETAIL_VIEW_LIST:


            if (payload.params.PantoneId === 0) {

                return {
                    ...state,
                    generalLabdipViewDetailList: payload.data.pantoneCodeList,
                    generalLabdipViewDetailParams: payload.params
                };
            } else {

                let index = payload.params.Index;
                let resultSet = payload.data;
                //GeneralLabDipViewDetail-(GLDVD)
                let GLDVDPantoneCodeList = [...state.generalLabdipViewDetailList];


                GLDVDPantoneCodeList[index].pantoneSubList = resultSet.pantoneSubList ? resultSet.pantoneSubList : [];
                GLDVDPantoneCodeList[index].criticalList = resultSet.criticalList ? resultSet.criticalList : [];
                GLDVDPantoneCodeList[index].manualCommentList = resultSet.manualCommentList ? resultSet.manualCommentList : [];
                GLDVDPantoneCodeList[index].manualImageList = resultSet.manualImageList ? resultSet.manualImageList : [];
                GLDVDPantoneCodeList[index].existingList = resultSet.existingList ? resultSet.existingList : [];



                return {
                    ...state,
                    generalLabdipViewDetailList: GLDVDPantoneCodeList,
                    generalLabdipViewDetailParams: payload.params
                };
            }
        case CLEAR_GENERAL_LABDIP_DETAIL_VIEW_SUB_ROW:
            let index = payload;
            //GeneralLabDipViewDetail-(GLDVD)
            let GLDVDPantoneCodeList = [...state.generalLabdipViewDetailList];
            GLDVDPantoneCodeList[index].pantoneSubList = [];
            GLDVDPantoneCodeList[index].criticalList = [];
            GLDVDPantoneCodeList[index].manualCommentList = [];
            GLDVDPantoneCodeList[index].manualImageList = [];
            GLDVDPantoneCodeList[index].existingList = [];

            return {
                ...state,
                generalLabdipViewDetailList: GLDVDPantoneCodeList
            };
        case UPDATE_GEN_PROGRAM_GRID_LIST:

            let indexs = payload.index;
            let labdibSubmissionApprovalID = payload.labdibSubmissionApprovalID;
            let updateProgram = state.programGridList;
            updateProgram[indexs].labdibSubmissionApprovalID = labdibSubmissionApprovalID

            return {
                ...state,
                programGridList: updateProgram
            };
        case RETRIEVE_GEN_LABDIP_ALREADY_MAP_LIST:
            return { ...state, GENLabDipMapList: payload };

        case RETRIEVE_GENERAL_LABDIP_SUBMISSION_LIST:

            if (payload.params.PantoneId === 0) {

                return {
                    ...state,
                    generalLabdipSubmissionList: payload.data.pantoneCodeList,
                    generalLabdipSubmissionParams: payload.params
                };
            } else {

                let index = payload.params.Index;
                let resultSet = payload.data;
                //GeneralLabDipSubmissionDetail-(GLBSD)
                let GLBSDPantoneCodeList = [...state.generalLabdipSubmissionList];


                GLBSDPantoneCodeList[index].pantoneSubList = resultSet.generalLabdipPantoneSubInfo ? resultSet.generalLabdipPantoneSubInfo : [];
                GLBSDPantoneCodeList[index].criticalList = resultSet.criticalList ? resultSet.criticalList : [];

                return {
                    ...state,
                    generalLabdipSubmissionList: GLBSDPantoneCodeList,
                    generalLabdipSubmissionParams: payload.params,
                    isLoadingGeneralLabdip: false
                };
            }
        case CLEAR_GENERAL_LABDIP_SUBMISSION_SUB_ROW:
            let index1 = payload;
            //GeneralLabDipViewDetail-(GLDVD)
            let GLBSDPantoneCodeList = [...state.generalLabdipSubmissionList];
            GLBSDPantoneCodeList[index1].pantoneSubList = [];
            GLBSDPantoneCodeList[index1].criticalList = [];

            return {
                ...state,
                generalLabdipSubmissionList: GLBSDPantoneCodeList
            };
        case RETRIEVE_GENERAL_LABDIP_APPROVAL_LIST:

            if (payload.params.PantoneId === 0) {

                return {
                    ...state,
                    generalLabdipApprovalList: payload.data.pantoneCodeList,
                    generalLabdipApprovalParams: payload.params
                };
            } else {

                let index = payload.params.Index;
                let resultSet = payload.data;
                //GeneralLabDipSubmissionDetail-(GLBSD)
                let GLBSDPantoneCodeList = [...state.generalLabdipApprovalList];

                GLBSDPantoneCodeList[index].generalLabdipMultiApprovalList = resultSet.generalLabdipMultiApprovalList ? resultSet.generalLabdipMultiApprovalList : [];
                GLBSDPantoneCodeList[index].pantoneSubList = resultSet.generalLabdipPantoneSubInfo ? resultSet.generalLabdipPantoneSubInfo : [];
                GLBSDPantoneCodeList[index].criticalList = resultSet.criticalList ? resultSet.criticalList : [];

                return {
                    ...state,
                    generalLabdipApprovalList: GLBSDPantoneCodeList,
                    generalLabdipApprovalParams: payload.params
                };
            }
        case CLEAR_GENERAL_LABDIP_APPROVAL_SUB_ROW:
            let index2 = payload;
            //GeneralLabDipViewDetail-(GLDVD)
            let GLBAPantoneCodeList = [...state.generalLabdipApprovalList];
            GLBAPantoneCodeList[index2].pantoneSubList = [];
            GLBAPantoneCodeList[index2].criticalList = [];

            return {
                ...state,
                generalLabdipApprovalList: GLBAPantoneCodeList
            };
        case RETRIEVE_GENERAL_LABDIP_APPROVAL_VIEW_LIST:

            if (payload.params.PantoneId === 0) {

                return {
                    ...state,
                    generalLabdipApprovalViewList: payload.data.pantoneCodeList,
                    generalLabdipApprovalViewParams: payload.params
                };
            } else {

                let index = payload.params.Index;
                let resultSet = payload.data;
                //GeneralLabDipSubmissionDetail-(GLBSD)
                let GLBSDPantoneCodeList = [...state.generalLabdipApprovalViewList];


                GLBSDPantoneCodeList[index].pantoneSubList = resultSet.generalLabdipPantoneSubInfo ? resultSet.generalLabdipPantoneSubInfo : [];
                GLBSDPantoneCodeList[index].criticalList = resultSet.criticalList ? resultSet.criticalList : [];

                return {
                    ...state,
                    generalLabdipApprovalViewList: GLBSDPantoneCodeList,
                    generalLabdipApprovalViewParams: payload.params
                };
            }
        case CLEAR_GENERAL_LABDIP_APPROVAL_VIEW_SUB_ROW:
            let index3 = payload;
            //GeneralLabDipViewDetail-(GLDVD)
            let GLBAVPantoneCodeList = [...state.generalLabdipApprovalViewList];
            GLBAVPantoneCodeList[index3].pantoneSubList = [];
            GLBAVPantoneCodeList[index3].criticalList = [];

            return {
                ...state,
                generalLabdipApprovalViewList: GLBAVPantoneCodeList
            };

        case UPDATE_GENERAL_LAPDIP_DETAIL_VIEW_ALL_CHECK_BOX:
            let isCheck = payload;
            let List = [...state.generalLabdipViewDetailList];
           ;
            List.map((item, Index) => {
                if (item && item.pantoneSubList) {
                    let SubList = item.pantoneSubList;
                    SubList && SubList.map((item2, SubIndex) => {
                        SubList[SubIndex].isSelected = isCheck;
                    });
                    List[Index].pantoneSubList = SubList;
                }

            });
            return {
                ...state,
                generalLapdipViewDetailList: List
            };

        case UPDATE_GENERAL_LABDIP_DETAIL_VIEW_CHECK_BOX:
            let Index = payload.index;
            let SubIndex = payload.subIndex;
            let isChecked = payload.isChecked;
           ;
            let PantoneCodeList = [...state.generalLabdipViewDetailList];
            let pantoneSubList = PantoneCodeList[Index].pantoneSubList;
            pantoneSubList[SubIndex].isSelected = isChecked;
            PantoneCodeList[Index].pantoneSubList = pantoneSubList;

            return {
                ...state,
                generalLabdipViewDetailList: PantoneCodeList
            };

        case NEW_TNA_GENERAL_LABDIP_LIST:
            return {
                ...state,
                genLabdipTNAPantoneOrSwatchList: payload.item1,
                genLabdipTNATaskList: payload.item2,
                genLabdipTNAAddtionalTaskList: payload.item3,
            };
        case TNA_GENERAL_LABDIP_HOLIDAY_LIST:
            return {
                ...state,
                genLapdibTNAHolidayList: payload
            };

        case REMOVE_TNA_GENERAL_LABDIP_PANTONE_SWATCH_LIST:

            let pantoneOrSwatchList = [...state.genLabdipTNAPantoneOrSwatchList];
            pantoneOrSwatchList.splice(payload, 1);
            return {
                ...state,
                genLabdipTNAPantoneOrSwatchList: pantoneOrSwatchList
            };
        case TNA_GENERAL_LABDIP_SUBMISSION_AND_APPROVAL:
            return {
                ...state,
                editTNAPantoneOrSwatchList: payload
            };
        case GLD_TNA_TASK_OWNER_LIST:
            return { ...state, drpGLDTNATaskOwnerList: payload };

        default:
            return state;
    }
};
export default generalLabdipReducer;