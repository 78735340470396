import React, { useState, useEffect, Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Reactselect from 'react-select';
import { Modal, Button } from "react-bootstrap";

import Nodify from "../Common/ReactNotification";
import useFullPageLoader from "../../hooks/useFullPageLoader";

import DatePicker from "react-datepicker";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import moment from "moment";
import { loadTNAReportList } from "../../actions/tna";
import Lightbox from "react-image-lightbox";
import CommonService from '../../services/Common/CommonService';
import TrimSubmission from "../../components/TNA/TrimSubmissionReport";
import TrimApproval from "../../components/TNA/TrimApproval";
import LabdibSubmission from "../../components/TNA/LabdibSubmissionReport";
import LabdibApproval from "../../components/TNA/LabdibApproval";
import StrikeOffSubmission from "../../components/TNA/StrikeOffSubmissionReport";
import StrikeApproval from "../../components/TNA/StrikeApproval";
import ProtoFitSMSSubmission from "../../components/TNA/ProtoFitSMSSubmission";
import ProtoFitSMSApproval from "../../components/TNA/ProtoFitSMSApproval";
import AfterWashList from "../../components/TNA/AppearanceAfterWash";

import ReactNotification from 'react-notifications-component';

import { retrieveSeason } from "../../actions/season";
import { loadSupplierList, loadActiveBuyerList, getSkuAndStyleDetails, getTNACompanyHolidayList, loadTNATaskOwner, GetSubChildMultExFactoryFormSKUList, GetTestingMultExFactorySKUList } from "../../actions/tna";
import TnaService from "../../services/TNA/TNAService";
import StyleService from "../../services/Style/styleService";
import tnaReducer from "../../reducers/tna";
import TNAService from "../../services/TNA/TNAService";
import SweetAlertPopup from "../Common/SweetAlertPopup";
import { retrieveTask, loadTaskList, loadTaskHolderList } from "../../actions/task";
import { loadTaskTypeList } from "../../actions/taskType";
import { loadLeaveDaysList } from "../../actions/leaveDay";
import { loadRouteDependencyList } from "../../actions/route";
import { TNAManualCloseParent, TNAManualCloseChildLibDip, TNAManualCloseChildStrikeOff, TNAManualCloseChildTrim, TNAManualCloseChildProtoFit, TNAManualCloseChildSms, QAInspectionId, TestingMainlist, FinalinspectionID } from "../Common/PageActionNumber";
import AddInspection from "./../Production/Inspection_Quality Control/InspectionQC/AddInspection";
import ReInspection from "./../Production/Inspection_Quality Control/InspectionQC/ReInspection";
import FinalinspectionService from "../../services/FinalInspection/FinalinspectionService";
import AddQCInspection from "../Production/Inspection_Quality Control/InspectionQC/AddQCinspection";
import QcInspection from "../../services/QCInspection/QcInspection";
import QAReInspection from "../Production/Inspection_Quality Control/InspectionQC/QAReinspection";
import NewTesting from "../Production/Inspection_Quality Control/InspectionQC/NewTesting";
import ReTesting from "../Production/Inspection_Quality Control/InspectionQC/ReTesting";
const EditTNAReport = ({ props }) => {

    //reducer
    const AllRouteInfo = useSelector((state) => state.tna.TNAReportList);
    const TNAParentChildList = useSelector((state) => state.tna.TNAParentChildList);
    const TNAChildList = useSelector((state) => state.tna.TNAChildList);
    const taskHolderList = useSelector((state) => state.task.drpTaskHolderList);
    const isLoadingTNA = useSelector((state) => state.tna.isLoadingTNA);
    const getLeaveDaysList = useSelector((state) => state.leaveDay.drpLeaveDaysList);
    const getTNAStatusList = useSelector((state) => state.tna.TNAStatusList);
    const TNAMultiTask = useSelector((state) => state.tna.TNAMultiTask);
    const TNAMultiTaskWithOutChild = useSelector((state) => state.tna.TNAMultiTaskWithOutChild);
    const drpTNATaskOwnerList = useSelector((state) => state.tna.drpTNATaskOwnerList);
    const StaticTask = useSelector((state) => state.tna.StaticTask);
    const { user: currentUser } = useSelector((state) => state.auth);
    const reducerState = useSelector((state) => state);
    const [getTrimmodalpopup, setTrimmodalpopup] = useState(false);
    const [getTrimApprovalpopup, setTrimApprovalpopup] = useState(false);
    const [getLapmodal, setLapmodal] = useState(false);
    const [getnewQAReportlist, setnewQAReportlist] = useState([]);
    const [getQAheaderData, setQAheaderdata] = useState([]);
    const [getOpenLabdipApppopup, setOpenLabdipApppopup] = useState(false);
    const [strikeoffModalpopup, setStrikeOffmodalpopup] = useState(false);
    const [getstrikeoffApppopup, setStrikeOffApppopup] = useState(false);
    const [getSampleSubModalpopup, setSampleSubModalpopup] = useState(false);
    const [getSampleAppModalpopup, setSampleAppModalpopup] = useState(false);
    const [getApperancemodalpopup, setApperancemodalpopup] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isLastEdit, setLastEdit] = useState(true);
    // props.location.state.params.creationStatus === 1 ? false : true
    const [TNAId, setTNAId] = useState();
    const [getTaskHolderList, setTaskHolderList] = useState([]);
    const [StyleId, setStyleId] = useState();
    const [LabdibFabricInfoId, setLabdibFabricInfoId] = useState();
    const [TNAStrikeOffRefInfoId, setTNAStrikeOffRefInfoId] = useState();
    const [TNATrimRefInfoId, setTNATrimRefInfoId] = useState();
    const [SubmittedOptionListId, setSubmittedOptionListId] = useState();
    const [ColorAppCommentsId, setColorAppCommentsId] = useState();
    const [TNAProtoFitId, setTNAProtoFitId] = useState();
    const [getIsAddFinalInspection, setIsAddFinalInspection] = useState(false);
    const [getfinalinspectionMulti, setfinalinspectionMulti] = useState([]);

    const [AppareanceTestId, setAppareanceTestId] = useState();
    const [Indexes, setIndexes] = useState({ Index: 0, SubIndex: 0, ChildIndex: 0 });
    const [getID, setID] = useState(0);
    const getSupplierList = useSelector((state) => state.tna.drpSupplierList);
    const [getSearchvaluestoSub, setSearchvaluesToSub] = useState([]);
    const [getTestingTaskListParam, setTestingTaskListParam] = useState([]);
    const [getTestingReportPageView, setTestingReportPageView] = useState('AddInspection');
    const [getIDSingleTask, setIDSingleTask] = useState({ showPopupDelete: false, Params: {} });
    const [MultExFactorySKUList, setMultExFactorySKUList] = useState([]);
    const [getFinalInspectionPageView, setFinalInspectionPageView] = useState('AddInspection');
    const [getAddInspectionListParam, setAddInspectionListParam] = useState([]);
    //Testing
    const [getQCfinalinspectionMulti, setQCfinalinspectionMulti] = useState([]);
    const [getnewTestingReportlist, setnewTestingReportlist] = useState([]);
    const [getTestingheaderData, setTestingheaderdata] = useState([]);
    const [getnewfinalReportlist, setnewfinalReportlist] = useState([]);
    const [getnewheaderdata, setnewheaderdata] = useState([]);
    const [isSkuAndStyleDetailPopup, setSkuAndStyleDetailPopup] = useState(false);
    const [SkuAndStyleDetails, setSkuAndStyleDetails] = useState([]);
    const [getInspectionInfo, setInspectionInfo] = useState([{
        buyerName: ''
        , buyerID: 0
        , brandName: ''
        , brandID: 0
        , seasonName: ''
        , seasonID: 0
        , type: 0,
        TaskStatus: 0
    }]);
    const [getQCInspectionInfo, setQCInspectionInfo] = useState([{
        buyerName: ''
        , buyerID: 0
        , brandName: ''
        , brandID: 0
        , seasonName: ''
        , seasonID: 0
        , type: 0,
        TaskStatus: 0
    }]);
    const [getTestingTaskInfo, setTestingTaskInfo] = useState([{
        buyerName: ''
        , buyerID: 0
        , brandName: ''
        , brandID: 0
        , seasonName: ''
        , seasonID: 0
        , type: 0,
        TaskStatus: 0
    }]);
    const [getSearchvalues, setSearchvalues] = useState({
        Buyer: 0, Brand: 0, Season: 0,
        Supplier: 0, TaskType: 0,
        TaskName: 0, Status: 4,
        lstTaskType: [],
        lstTaskName: [],
        TodoDelay: 0, TodoUpcoming: 0, TodoToday: 0, TodoAll: 0,
        CompletedEarly: 0, CompletedOntime: 0, CompletedDelay: 0, CompletedAll: 0,
        OverAllCount: 0,
        ApprovedDirectCount: 0,
        Operation: 1,
        CurrentIndex: 0,
        CurrentChildIndex: 0,
    });
    const [getAddQCInspectionListParam, setQCAddInspectionListParam] = useState([]);
    const [getQAInspectionPageView, setQAInspectionPageView] = useState('AddInspection');
    var defaultStartDate = new Date();
    const [stateDate, setStateDate] = useState([
        {
            startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 30),
            endDate: new Date(),
            key: 'selection',
            isShow: false
        }
    ]);

    //Enable Menu

    let activeMenu;
    let activeMenuSub;
    let TaskCloseCompleteInfo;
    let StartStopInfo;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
    if (menulist !== undefined && menulistSub.length > 0) {
        activeMenu = menulist.filter(i => i.menuName === "TNAReport");
        if (activeMenu.length > 0) {
            activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);

            if (activeMenuSub.length > 0) {
                for (let index = 0; index < activeMenuSub.length; index++) {
                    if (activeMenuSub[index].menuName === "TNAReportCloseComplate") {
                        TaskCloseCompleteInfo = activeMenuSub[index];
                    }
                    else if (activeMenuSub[index].menuName === "TNAReportStartStop") {
                        StartStopInfo = activeMenuSub[index];

                    }
                }
            }
        }
    }

    // End Enable Menu

    const Searchvalues = { ...getSearchvalues };

    const [getBuyerList, setBuyerList] = useState([]);
    const getSeasonList = useSelector((state) => state.season.seasonList);
    const [getBrandList, setBrandList] = useState([]);


    const [MultiCloseDatas, setMultiCloseDatas] = useState([]);

    const [OrderInfoMulticloseMainDetails, setOrderInfoMulticloseMainDetails] = useState([]);
    const [expandStateMultiSelect, setExpandStateMultiSelect] = useState({});
    const [expandedRowsMultiSelect, setExpandedRowsMultiSelect] = useState([]);
    const [expandListMultiSelect, setexpandListMultiSelect] = useState({});

    const [selectall, setselectall] = useState(false);

    //declaration
    const dispatch = useDispatch();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    // let styles = {
    //     control: styles => ({ ...styles, border: '1px solid red' }),
    // }
    //const [isLastEdit, setLastEdit] = useState(props.location.state.params.creationStatus === 1 ? false : true);
    const getTNABrandID = [props];
    const getTNASupplierID = [];
    // const getTNABrandID = props.location.state.params.brandID;
    // const getTNASupplierID = props.location.state.params.supplierId;
    // const getTNABuyerID = props.location.state.params.buyerID;
    // const getTNASeasonID = props.location.state.params.seasonID;

    const EmptyInputFields = [];
    const [inputFields, setInputFields] = useState(EmptyInputFields);
    const [submitted, setSubmitted] = useState(false);
    // const [buttonLoader, setButtonLoader] = useState(false);
    // const [getTaskHolderList, setTaskHolderList] = useState([]);
    // const [getHeaderName, addHeaderName] = useState("Edit");
    // const [101AllRouteInfo, setAllRouteInfo] = useState([]);
    const [getTestinginspectionMulti, setTestinginspectionMulti] = useState([]);
    const [getIDQATask, setIDQATask] = useState({ showPopupDelete: false, Params: {} });
    //gowtham
    const [getIsProductionStatussearch, setIsProductionStatussearch] = useState({ label: "Sampling", selected: true, value: 1 });
    const [getComment, setComment] = useState(false);
    const [EditedCommentIndex, setEditedCommentIndex] = useState('');
    // const [getFiles, setFiles] = useState([]);
    // const [isOpenimg, setIsOpenimg] = useState(false);
    // const [getImagename, setImagename] = useState();
    // const [getTaskIndexFileupload, setTaskIndexFileupload] = useState();
    // const [getTNAtaskIdFileupload, setTNAtaskIdFileupload] = useState();
    // const [getCurrentChildTask, setCurrentChildTask] = useState({ value: 0, lablel: '' });
    const [ParentTask, setParentTask] = useState('');
    const [ChildTask, setChildTask] = useState('');
    const [TaskStatus, setTaskStatus] = useState();
    const [LabdipApprovalList, setLabdipApprovalList] = useState([]);
    const [MultiManualCloseFeilds, setMultiManualCloseFeilds] = useState({ IdList: '', TaskStatus: 0, ManualCloseDate: '', ManualCloseComment: '', Createdby: 1 });
    const values = [...inputFields];

    const [getImagename, setImagename] = useState();
    const [isOpenimg, setIsOpenimg] = useState(false);
    const [manualApprovalData, setmanualApprovalData] = useState({ manualCloseComment: '', files: [] });
    const [existingApprovalData, setexistingApprovalData] = useState([]);

    //////////////////**************searchTNA  *////////////////////////////////
    const [getIDTestTask, setIDTestTask] = useState({ showPopupDelete: false, Params: {} });
    const searchTNA = () => {
        let getStateDate = [...stateDate];
        getStateDate[0].isShow = false;
        setStateDate(getStateDate);

        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(stateDate[0].startDate));
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(stateDate[0].endDate));
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        if (Searchvalues.Status) { }

        let params = {
            BuyerId: Searchvalues.Buyer, BrandId: Searchvalues.Brand, SeasonId: Searchvalues.Season, SupplierId: Searchvalues.Supplier,
            TaskType: Searchvalues.TaskType, TaskNameId: Searchvalues.TaskName, TaskTodoInProgressCompletedStatus: Searchvalues.Status, StartDate: StartDate, EndDate: EndDate, Operation: 1
            , IsProduction: getIsProductionStatussearch.value
        };

        setSearchvaluesToSub(params);
        dispatch(loadTNAReportList(params));
        //Searchvalues.Status = 4;
        setSearchvalues(Searchvalues);
        commonParentExpand();
    }
    let IsProductionStatus = [
        {
            value: 1,
            label: 'Sampling',
            selected: true,

        },
        {
            value: 2,
            label: 'Production',
            selected: false,

        }
    ]
    const resetTNA = () => {
        Searchvalues.Buyer = 0;
        Searchvalues.Brand = 0;
        Searchvalues.Season = 0;
        Searchvalues.Supplier = 0;
        Searchvalues.Status = 4;

        Searchvalues.TaskType = 0;
        Searchvalues.TaskName = 0;

        let params = {
            BuyerId: Searchvalues.Buyer, BrandId: Searchvalues.Brand, SeasonId: Searchvalues.Season,
            SupplierId: Searchvalues.Supplier, TaskType: 0, TaskNameId: 0, TaskTodoInProgressCompletedStatus: 4,
            StartDate: null, EndDate: null, Operation: 1, IsProduction: 1
        };
        setSearchvaluesToSub(params);
        dispatch(loadTNAReportList(params));
        setSearchvalues(Searchvalues);
        let getStateDate = [...stateDate];
        getStateDate[0].isShow = false;
        setStateDate(getStateDate);

    }

    function LoadBuyerBased(BuyerIDDefault, setBrandList) {
        StyleService.LoadBrandThemeStoryEndCustomer(5, BuyerIDDefault).then((response) => {
            if (response.data) {
                let BuyerInfoList = response.data;
                let respected_Brand = BuyerInfoList.filter(x => x.buyerMapID === 1);
                setBrandList(respected_Brand);
            }
        }).catch(() => { });
    }

    useEffect(() => {
        if (!isLoadingTNA) {
            // hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingTNA])


    useEffect(() => {
        dispatch(loadTaskTypeList(2));
        dispatch(loadLeaveDaysList());
        dispatch(retrieveTask(0));
        dispatch(loadTaskList(0));
        dispatch(loadRouteDependencyList());
        dispatch(loadTaskHolderList());
        //dispatch(getTNACompanyHolidayList(getTNABrandID, getTNASupplierID, currentUser.companyBuyerSupID));
    }, []);


    useEffect(() => {
        showLoader();
        resetTNA();
        dispatch(retrieveSeason(0));
        StyleService.LoadBuyer(2)
            .then((response) => {
                if (response.data) {

                    setBuyerList(response.data);
                }
            })
            .catch(() => { });
        //dispatch(loadBrandThemeStoryEndCustomer(7, 0));
        dispatch(loadSupplierList());
        //dispatch(loadTNAReportList());
        dispatch(loadActiveBuyerList(2));
    }, []);

    useEffect(() => {
        if (getSupplierList.length === 1) {
            Searchvalues.Supplier = getSupplierList[0].value;
        }
        else {
            Searchvalues.Supplier = 0;
        }
        setSearchvalues(Searchvalues);
    }, [getSupplierList.length === 1])

    //Taskholdernameslist
    useMemo(() => {
        let Optionvalue = [];
        taskHolderList && taskHolderList.map(element => {

            let LabelColor = '';
            if (element.label === 'Buyer - -') {
                LabelColor = <><span style={{ color: "#0b0992" }}><b>Buyer</b></span></>
            }
            else if (element.label === 'Buyer - Cube') {
                LabelColor = <><span style={{ color: "#0b0992" }}><b>Buyer</b></span> - <span style={{ color: "#00a65a" }}><b>Cube</b></span></>
            }
            else if (element.label === 'Buyer - Supplier') {
                LabelColor = <><span style={{ color: "#0b0992" }}><b>Buyer</b></span> - <span style={{ color: "#e27607" }}><b>Supplier</b></span></>
            }
            else if (element.label === 'Buyer - Cube,Supplier') {
                LabelColor = <><span style={{ color: "#0b0992" }}><b>Buyer</b></span> - <span style={{ color: "#00a65a" }}><b>Cube</b></span>, <span style={{ color: "#e27607" }}><b>Supplier</b></span></>
            }
            else if (element.label === 'Cube - -') {
                LabelColor = <><span style={{ color: "#00a65a" }}><b>Cube</b></span></>
            }
            else if (element.label === 'Cube - Buyer') {
                LabelColor = <><span style={{ color: "#00a65a" }}><b>Cube</b></span> - <span style={{ color: "#0b0992" }}><b>Buyer</b></span></>
            }
            else if (element.label === 'Cube - Supplier') {
                LabelColor = <><span style={{ color: "#00a65a" }}><b>Cube</b></span> - <span style={{ color: "#e27607" }}><b>Supplier</b></span></>
            }
            else if (element.label === 'Cube - Buyer,Supplier') {
                LabelColor = <><span style={{ color: "#00a65a" }}><b>Cube</b></span> - <span style={{ color: "#0b0992" }}><b>Buyer</b></span>, <span style={{ color: "#e27607" }}><b>Supplier</b></span></>
            }
            else if (element.label === 'Supplier - -') {
                LabelColor = <><span style={{ color: "#e27607" }}><b>Supplier</b></span></>
            }
            else if (element.label === 'Supplier - Buyer') {
                LabelColor = <><span style={{ color: "#e27607" }}><b>Supplier</b></span> - <span style={{ color: "#0b0992" }}><b>Buyer</b></span></>
            }
            else if (element.label === 'Supplier - Cube') {
                LabelColor = <><span style={{ color: "#e27607" }}><b>Supplier</b></span> - <span style={{ color: "#00a65a" }}><b>Cube</b></span></>
            }
            else if (element.label === 'Supplier - Buyer,Cube') {
                LabelColor = <><span style={{ color: "#e27607" }}><b>Supplier</b></span> - <span style={{ color: "#0b0992" }}><b>Buyer</b></span>, <span style={{ color: "#00a65a" }}><b>Cube</b></span></>
            }

            Optionvalue.push({
                value: element.value,
                label: LabelColor,
                subLabel: element.label,
            })
        });

        setTaskHolderList(Optionvalue);

    },
        [taskHolderList]
    );

    // Main Task load
    useMemo(
        () => {

            if (AllRouteInfo.tnaDetailList !== undefined) {

                let routeInfo = AllRouteInfo.tnaDetailList;
                // let parentChildList = AllRouteInfo.tnaParentChildList;
                // let childList = AllRouteInfo.tnaChildList;
                // let Comments = AllRouteInfo.tnaCommentsList;

                let TaskTypeList = AllRouteInfo.tnaTaskTypeList;
                let TaskNameList = AllRouteInfo.tnaTaskNameList;
                let TodoInprogressList = AllRouteInfo.tnaTodoInprogressList;
                let CompletedList = AllRouteInfo.tnaCompletedList;
                Searchvalues.lstTaskType = TaskTypeList
                Searchvalues.lstTaskName = TaskNameList

                if (TodoInprogressList.length !== 0) {
                    Searchvalues.TodoDelay = TodoInprogressList[0].todoInProgressDelayCount;
                    Searchvalues.TodoUpcoming = TodoInprogressList[0].todoInProgressUpComingCount;
                    Searchvalues.TodoToday = TodoInprogressList[0].todoInProgressTodayCount;
                    Searchvalues.TodoAll = TodoInprogressList[0].todoInProgressAllCount;
                }
                if (CompletedList.length !== 0) {
                    Searchvalues.CompletedDelay = CompletedList[0].completedDelayCount;
                    Searchvalues.CompletedEarly = CompletedList[0].completedEarlierCount;
                    Searchvalues.CompletedOntime = CompletedList[0].completedOnTimeCount;
                    Searchvalues.CompletedAll = CompletedList[0].completedAllCount;
                    Searchvalues.OverAllCount = CompletedList[0].overAllCount;
                    Searchvalues.ApprovedDirectCount = CompletedList[0].approvedDirectCount;
                }
                setSearchvalues(Searchvalues);



                let routeInformation = [];
                if (AllRouteInfo) {
                    showLoader();
                    if (routeInfo) {
                        if (routeInfo.length > 0) {
                            //setInputFields([]);

                            routeInfo.map((route, i) => {
                                let ImageUrl = "";

                                if (route.taskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || route.taskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                                    route.taskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || route.taskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT
                                    || route.taskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT || route.taskName.toLowerCase() === StaticTask.STRIKEOFF_TEST) {
                                    ImageUrl = 'Images/Style/Strikeoff/';
                                } else if (route.taskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || route.taskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
                                    || route.taskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || route.taskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT
                                    || route.taskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || route.taskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                                    || route.taskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER || route.taskName.toLowerCase() === StaticTask.TRIMS_TEST) {
                                    ImageUrl = 'Images/Style/Trim/';
                                }
                                let EndDate = route.endDate;
                                let ActualEndDate = route.actualEndDate;
                                let IsSingleTask = !!!TNAMultiTask.find(element => element.toLowerCase() === route.taskName.toLowerCase());
                                let SubTaskList = [];
                                let IsMultiTaskWithOutChild = !!TNAMultiTaskWithOutChild.find(element => element.toLowerCase() === route.taskName.toLowerCase());

                                if (IsSingleTask || IsMultiTaskWithOutChild) {
                                    SubTaskList.push({
                                        ParentId: 0,
                                        TaskID: route.taskType,
                                        MainId: 0,
                                        Code: "",
                                        Color: "",
                                        ColorOrName: "",
                                        ImagePath: "",
                                        StrikeOffOrTrimInfoId: "",
                                        IsExpanded: true,
                                        ChildTaskList: []
                                    })
                                }


                                routeInformation.push({
                                    TNARouteApplyID: route.tnaRouteApplyID,
                                    TaskFieldEnable: 0,
                                    TaskType: route.taskType,
                                    TaskID: route.taskNameID,
                                    strTaskName: route.taskName,
                                    EndDate: new Date(route.endDate),
                                    StrEndDate: EndDate,
                                    StartDate: new Date(route.planStartDate),
                                    Duration: route.duration,
                                    intDependency: parseInt(route.dependencyStartstop),
                                    // arrDependency: [],
                                    arrComment: [],
                                    // arrAttachment: [],
                                    arrManual: [],
                                    ManualComments: route.manualCloseComment,
                                    //ManualCloseDate: route.ManualCloseDate,
                                    // CommentsCount: route.taskCommentsCount,
                                    // AttachmentCount: route.taskDocumentsCount,
                                    // TaskHolderList: '',
                                    // TaskHolderOrFollower: route.taskHolderID,
                                    TaskHolderOwnerTxtList: route.taskOwnerNames,
                                    // TaskHolderIDList: route.taskHolderIDList,
                                    // FollowerIDList1: route.followerIDList1,
                                    // FollowerIDList2: route.followerIDList2,
                                    // TaskHolderBuyer: route.taskHolderIDList,
                                    // FollowerCube: route.followerIDList1,
                                    // FollowerSupplier: route.followerIDList2,
                                    // TaskHolderOwnerIdList: route.taskOwner,
                                    // titleEmployee1: employee1,
                                    // titleEmployee2: employee2,
                                    // titleEmployee3: employee3,
                                    // LeaveDays: route.leaveDays,
                                    IsTHFirstTime: 0,
                                    LeaveDaysTxt: route.leaveDayName,
                                    // AdditionalFollowup: additionalInfo.filter(x => x.indexName === route.indexName && x.isTaskSelected === 1).length,
                                    // IsFollowupExist: additionalInfo.filter(x => x.indexName === route.indexName).length,
                                    // DependencyCount: dependentInfo.filter(x => x.indexName === route.indexName && x.dependencyID !== 0).length,
                                    IndexName: route.indexName,
                                    //PreferenceId: route.preferenceId,
                                    IsPopupOpen: 0,
                                    IsDependencyOpen: 0,
                                    IsTHLeaveDaysOpen: 0,
                                    IsComment: 0,
                                    IsAttachment: 0,
                                    IsSelectAll: route.isSelectAll,
                                    FollowupDetails: [],
                                    SelectedDependency: route.dependency,
                                    // arrTaskHolderBuyer: [],
                                    // arrFollowerCube: [],
                                    // arrFollowerSupplier: [],
                                    // TaskHolderBuyerTxt: '',
                                    // FollowerCubeTxt: '',
                                    // FollowerSupplierTxt: '',
                                    IsSingleTask: IsSingleTask,
                                    IsMultiTaskWithOutChild: IsMultiTaskWithOutChild,
                                    //IsNewRow: true,
                                    StatusID: route.routeApplyStatus === 0 ? 1 : route.routeApplyStatus,
                                    // StatusName: Statusname,
                                    AWBStatus: route.awbStatus,
                                    ActualEndDate: route.actualEndDate,
                                    StrActualEndDate: ActualEndDate,
                                    ManualCloseDate: route.actualEndDate,
                                    SubTaskList: SubTaskList,
                                    TaskTypeName: route.taskTypeName,
                                    Dependency: route.dependency,
                                    TNANo: route.tnaNo,
                                    ImageUrl: ImageUrl

                                });
                            });
                        }


                        // If todo and inprogress list is Zero means check to Overall count check(Show List)
                        if (routeInformation.length === 0) { routeInformation = AllRouteInfo.tnaCompletedList.length > 0 && AllRouteInfo.tnaCompletedList[0].overAllCount > 0 ? AllRouteInfo.tnaCompletedList : routeInformation }
                        setInputFields(routeInformation);
                        hideLoader();

                    } else { setInputFields(routeInformation); hideLoader(); }
                } else {
                    setInputFields([]);
                    hideLoader();
                }
            } else {
                setInputFields([]);
                hideLoader();
            }
        },
        [AllRouteInfo.tnaDetailList]
    );


    ///  ParentChild List //
    useMemo(
        () => {

            if (TNAParentChildList && TNAParentChildList.length > 0) {
                let SubTaskList = []
                TNAParentChildList.map((element, p) => {
                    SubTaskList.push({
                        ParentId: element.parentId,
                        TaskID: element.taskID,
                        MainId: element.mainId,
                        Code: element.code,
                        Color: element.color,
                        ColorOrName: element.colorOrName,
                        ImagePath: element.imagePath,
                        StrikeOffOrTrimInfoId: element.strikeOffIdOrTrimInfoId,
                        IsExpanded: false,
                        ChildTaskList: []
                    })
                });

                if (values[Searchvalues.CurrentIndex]) {
                    values[Searchvalues.CurrentIndex].SubTaskList = SubTaskList;
                }
                setInputFields(values);
                hideLoader();
            } else {
                // if (values && values[Searchvalues.CurrentIndex]) {
                //     values[Searchvalues.CurrentIndex].SubTaskList = [];
                //     values[Searchvalues.CurrentIndex] && setInputFields(values);
                // }

                hideLoader();
            }
        },
        [TNAParentChildList]
    );

    ///  Child List //
    useMemo(
        () => {

            if (TNAChildList && TNAChildList.length > 0) {

                let route = values[Searchvalues.CurrentIndex];
                let ChildTaskList = [];
                TNAChildList.map((child, k) => {
                    let employee1 = "";
                    let employee2 = "";
                    let employee3 = "";

                    if (child.taskHolderName !== null && child.taskHolderName !== undefined) {
                        let TaskHolderName = child.taskHolderName.split('-');
                        if (TaskHolderName[0] !== undefined) {
                            employee1 = TaskHolderName[0].trim();

                            if (TaskHolderName[1] !== undefined) {
                                let FollowerName = TaskHolderName[1].split(',');
                                if (FollowerName[0] !== undefined) {
                                    employee2 = FollowerName[0].trim();

                                    if (FollowerName[1] !== undefined) {
                                        employee3 = FollowerName[1].trim();
                                    } else {
                                        employee3 = "";
                                    }
                                } else {
                                    employee2 = "";
                                    employee3 = "";
                                }

                            } else {
                                employee2 = "";
                                employee3 = "";
                            }
                        }
                    }
                    let EndDate = child.endDate;
                    let ActualEndDate = child.actualEndDate;
                    ChildTaskList.push({
                        ChildId: child.childId,
                        SubId: child.subId,
                        Name: child.name,
                        // TaskName: child.taskName,
                        TaskName: route && route.strTaskName,
                        TaskID: child.taskID,
                        MainId: child.mainId,
                        StrEndDate: EndDate,
                        Duration: child.duration,
                        EndDate: new Date(child.endDate),
                        StartDate: new Date(child.planStartDate),
                        // EndDate: isLastEdit ? moment(child.endDate).toDate() : moment(child.endDate).toDate(),
                        // StartDate: isLastEdit ? moment(child.planStartDate).toDate() : moment(child.planStartDate).toDate(),
                        // arrDependency: [],
                        // TaskHolderList: '',
                        // TaskHolderOrFollower: route.taskHolderID,
                        TaskHolderOwnerTxtList: route && route.TaskHolderOwnerTxtList,
                        titleEmployee1: employee1,
                        titleEmployee2: employee2,
                        titleEmployee3: employee3,
                        LeaveDays: child.leaveDays,
                        IsTHFirstTime: 0,
                        //DependencyCount: dependentInfo.filter(x => x.indexName === route.indexName && x.dependencyID !== 0).length,
                        IsDependencyOpen: 0,
                        IsTHLeaveDaysOpen: 0,
                        SelectedDependency: route && route.Duration,
                        // LeaveDays: '',
                        LeaveDaysTxt: route && route.LeaveDaysTxt,
                        StatusID: child.routeApplyStatus,
                        AWBStatus: '',
                        arrTaskHolderBuyer: [],
                        arrFollowerCube: [],
                        arrFollowerSupplier: [],
                        IsAddChildTaskOpen: 0,
                        StrikeOffOrTrimInfoId: child.strikeOffIdOrTrimInfoId,
                        drpChildTaskList: [],
                        IsManualClose: 0,
                        ManualCloseComment: child.manualCloseComment,
                        ManualCloseDate: child.manualCloseDate === null || child.manualCloseDate === "" ? '' : new Date(child.manualCloseDate),
                        StrActualEndDate: ActualEndDate,
                        IsShowSubmitButton: child.isShowSubmitButton,
                        IsShowApprovalButton: child.isShowApprovalButton,
                        ManualCloseTaskwise: child.ManualClose,
                        ChildActualEndDate: child.actualEndDate,
                        TNAId: child.tnaId,
                        StyleId: child.styleId,
                        TNANo: child.tnaNo,
                        StyleName: child.styleName,
                        StyleNo: child.styleNo,
                        IDPoNos: child.idPoNos,
                        TaskOwnerName: child.taskOwnerName,
                        Dependency: child.dependency,
                        LeaveDaysName: child.leaveDaysName,
                        ManualFilePath: child.manualFilePath,
                        ManualFileType: child.manualFileType,
                        ManualApprovalType: 0,
                        ExistingApprovalType: 0,
                        SKUIdList: child.skuIdList,
                        StyleStrikeOffGarmentSizeIdList: child.styleStrikeOffGarmentSizeIdList,
                        TaskHolderID: child.taskHolderID,
                        TaskHolderIDList: child.taskHolderIDList,
                        FollowerIDList1: child.followerIDList1,
                        FollowerIDList2: child.followerIDList2,
                        FollowerCube: child.followerIDList1,
                        FollowerSupplier: child.followerIDList2,
                        TaskHolderBuyerTxt: '',
                        FollowerCubeTxt: '',
                        FollowerSupplierTxt: '',
                        TaskHolderOwnerIdList: '',
                        DetailTypeID: child.detailTypeID,
                        IsDraft: child.isDraft,
                        IsQAdraft: child.isQAdraft,
                        IsTestDraft: child.isTestDraft,
                        IsStrikedraft: child.isStrikedraft,
                        IsTrimDraft: child.isTrimDraft,
                        intDependency: child.dependencyStartstop
                    });
                });


                if ((route && route.IsSingleTask) || (route && route.IsMultiTaskWithOutChild)) {
                    //Skip second parent row
                    if (values[Searchvalues.CurrentIndex].SubTaskList && values[Searchvalues.CurrentIndex].SubTaskList.length > 0) {
                        values[Searchvalues.CurrentIndex].SubTaskList[Searchvalues.CurrentChildIndex].ChildTaskList = ChildTaskList;
                        setInputFields(values);
                    } else {
                        values[Searchvalues.CurrentIndex].SubTaskList.push({
                            ParentId: 0,
                            TaskID: route.taskType,
                            MainId: 0,
                            Code: "",
                            Color: "",
                            ColorOrName: "",
                            ImagePath: "",
                            StrikeOffOrTrimInfoId: "",
                            IsExpanded: true,
                            ChildTaskList: []
                        });

                        if (values[Searchvalues.CurrentIndex]) {
                            values[Searchvalues.CurrentIndex].SubTaskList[Searchvalues.CurrentChildIndex].ChildTaskList = ChildTaskList;
                        }

                    }

                } else {
                    if (values[Searchvalues.CurrentIndex] && values[Searchvalues.CurrentIndex].SubTaskList[Searchvalues.CurrentChildIndex]) {
                        values[Searchvalues.CurrentIndex].SubTaskList[Searchvalues.CurrentChildIndex].ChildTaskList = ChildTaskList;
                    }
                    setInputFields(values);
                }

                hideLoader();
            } else {
                hideLoader();
            }
        },
        [TNAChildList]
    );
    // const MainValues = [...MainFields];

    useEffect(() => {
        if (!isLoadingTNA) {
            // hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingTNA])

    const handleTHLeaveDaysClose = (index, subIndex, childIndex) => {
        if (values[index] && values[index].SubTaskList[subIndex] && values[index].SubTaskList[subIndex].ChildTaskList[childIndex]) {
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsTHLeaveDaysOpen = 0;
        } else {
            values[index].IsTHLeaveDaysOpen = 0;
        }

        // let TxtList = "";
        // let IdList = "";
        // if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].TaskHolderIDList !== '') {
        //     TxtList = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].TaskHolderBuyerTxt;
        //     IdList = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].TaskHolderIDList;
        // }
        // if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerCube !== '') {
        //     TxtList = TxtList === "" ? values[index].FollowerCubeTxt : TxtList + ", " + values[index].FollowerCubeTxt;
        //     IdList = IdList === "" ? values[index].FollowerCube : IdList + "," + values[index].FollowerCube;
        // }
        // if (values[index].FollowerSupplier !== '') {
        //     TxtList = TxtList === "" ? values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerSupplierTxt : TxtList + ", " + values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerSupplierTxt;
        //     IdList = IdList === "" ? values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerSupplier : IdList + "," + values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerSupplier;
        // }

        // values[index].SubTaskList[subIndex].ChildTaskList[childIndex].TaskHolderOwnerTxtList = TxtList;
        // values[index].SubTaskList[subIndex].ChildTaskList[childIndex].TaskHolderOwnerIdList = IdList;

        setInputFields(values);
        //commonCalculation(false, false);
    }

    const handleTaskHolderLeaveDays = (index, subIndex, childIndex, isTHFirstTime) => {
        showLoader();
        if (values[index].TaskID === 0) {
            Nodify('Warning!', 'warning', 'Please select the task name.');
        }
        else {

            if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].titleEmployee1 !== "Cube" && values[index].SubTaskList[subIndex].ChildTaskList[childIndex].titleEmployee2 !== "Cube" && values[index].SubTaskList[subIndex].ChildTaskList[childIndex].titleEmployee3 !== "Cube") {
                // dispatch(loadTNATaskOwner(values[index].TaskHolderOrFollower, 0));
                dispatch(loadTNATaskOwner(values[index].SubTaskList[subIndex].ChildTaskList[childIndex].TaskHolderID, Searchvalues.Brand, Searchvalues.Supplier, 0))
                    .then(data => {
                        if (data != null) {
                            setTNATaskOwner(data, index, subIndex, childIndex, isTHFirstTime);
                        }
                        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsTHLeaveDaysOpen = 1;
                        setInputFields(values);
                        hideLoader();
                    });
            } else {
                // dispatch(loadTNATaskOwner(values[index].TaskHolderOrFollower, currentUser.employeeinformationID));
                dispatch(loadTNATaskOwner(values[index].SubTaskList[subIndex].ChildTaskList[childIndex].TaskHolderID,
                    Searchvalues.Brand, Searchvalues.Supplier, currentUser.employeeinformationID))
                    .then(data => {
                        if (data != null) {
                            setTNATaskOwner(data, index, subIndex, childIndex, isTHFirstTime);
                        }
                        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsTHLeaveDaysOpen = 1;
                        setInputFields(values);
                        hideLoader();
                    });
            }
        }
    };

    function setTNATaskOwner(data, index, subIndex, childIndex, isTHFirstTime) {
        if (data.buyerEmployeeList && data.buyerEmployeeList.length > 0) {

            let taskHolderBuyer = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].TaskHolderIDList && data.buyerEmployeeList.filter(item => (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].TaskHolderIDList.split(',').includes(item.value.toString())));
            if (taskHolderBuyer.length > 0) {

                if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrTaskHolderBuyer.length === 0 && isTHFirstTime === 0) {
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrTaskHolderBuyer = [...taskHolderBuyer];
                } else {
                    const isData = !!values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrTaskHolderBuyer.filter(d => d.value === taskHolderBuyer.value);
                    if (isData === false && isTHFirstTime === 0) {
                        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrTaskHolderBuyer = [...values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrTaskHolderBuyer, ...taskHolderBuyer];
                    }
                }

            }
        }
        if (data.cubeEmployeeList !== undefined) {
            let followerCube = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerIDList1 && data.cubeEmployeeList.filter(item => (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerIDList1.split(',').includes(item.value.toString())));
            if (followerCube.length > 0) {
                if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrFollowerCube.length === 0 && isTHFirstTime === 0) {
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrFollowerCube = [...followerCube];
                } else {
                    const isData = !!values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrFollowerCube.filter(d => d.value === followerCube.value);
                    if (isData === false && isTHFirstTime === 0) {
                        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrFollowerCube = [...followerCube];
                    }
                }
            }
        }
        if (data.supplierEmployeeList !== undefined) {
            let followerSupplier = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerIDList2 && data.supplierEmployeeList.filter(item => (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerIDList2.split(',').includes(item.value.toString())));
            if (followerSupplier.length > 0) {
                if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrFollowerSupplier.length === 0 && isTHFirstTime === 0) {
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrFollowerSupplier = [...followerSupplier];
                } else {
                    const isData = !!values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrFollowerSupplier.filter(d => d.value === followerSupplier.value);
                    if (isData === false && isTHFirstTime === 0) {
                        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrFollowerSupplier = [...followerSupplier];
                    }
                }
            }
        }

        // if (isTHFirstTime === 0) {
        //     if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrTaskHolderBuyer.length > 0) {
        //         let names = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrTaskHolderBuyer.map(x => x.label).join(",");
        //         values[index].SubTaskList[subIndex].ChildTaskList[childIndex].TaskHolderBuyerTxt = names;
        //     }
        //     if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrFollowerCube.length > 0) {
        //         let names = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrFollowerCube.map(x => x.label).join(",");
        //         values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerCubeTxt = names;
        //     }
        //     if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrFollowerSupplier.length > 0) {
        //         let names = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrFollowerSupplier.map(x => x.label).join(",");
        //         values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerSupplierTxt = names;
        //     }
        // }
        // values[index].IsTHFirstTime = 1;
    }

    const handleSkuAndStyleDetails = (SubId, MainId, StrikeOffIdOrTrimInfoId, strTaskName, SKUIdList, StyleStrikeOffGarmentSizeIdList, TnaId, styleId) => {
        showLoader();
        if (SKUIdList) {
            dispatch(getSkuAndStyleDetails({
                TNAId: TnaId, SubId: SubId,
                MainId: MainId, StrikeOffIdOrTrimInfoId: StrikeOffIdOrTrimInfoId,
                TaskName: strTaskName, StyleId: styleId
                , SKUIdList: SKUIdList, StyleStrikeOffGarmentSizeIdList: StyleStrikeOffGarmentSizeIdList

            }))
                .then(data => {
                    if (data) {
                        setSkuAndStyleDetailPopup(true);
                        setSkuAndStyleDetails(data);
                        hideLoader();
                    }
                });
        } else {
            hideLoader();
            let Title = "Warning!";
            let message = "No SKU in " + strTaskName;
            let type = "warning";
            Nodify(Title, type, message);
        }
    };

    const hideSkuAndStyleDetail = () => {
        setSkuAndStyleDetailPopup(false);
    }

    // #region  ------------------  Expanded Toggle --------------------------------

    const handleToggle = (index) => {
        let btnToggle = document.getElementById("btn-toggle_" + index);
        let iconToggle = document.getElementById("icon-toggle_" + index);
        let hasSingleTask = values[index].IsSingleTask;
        let hasMultiTaskWithOutChild = values[index].IsMultiTaskWithOutChild;

        // var rowHiddens = document.querySelectorAll(".parent_" + index);
        // var rowChildHiddens = document.querySelectorAll(".child_" + index);

        let ariaExpanded = btnToggle.getAttribute('aria-expanded');
        if (ariaExpanded === 'false') {
            //let i = 0;
            // for (let row of rowHiddens) {
            //     row.className = row.className.replace("hidden", " ");
            //     values[index].SubTaskList[i].IsExpanded = true;
            //     i++;
            // }
            btnToggle.setAttribute('aria-expanded', 'true');
            iconToggle.className = iconToggle.className.replace("fa-plus-square-o", "fa-minus-square-o");
            setInputFields(values);

            let taskRow = values[index];
            let StartDate = null;
            let EndDate = null;
            if (moment(stateDate[0].startDate).isValid()) {
                StartDate = convertUTCDateToLocalDate(new Date(stateDate[0].startDate));
            }
            if (moment(stateDate[0].endDate).isValid()) {
                EndDate = convertUTCDateToLocalDate(new Date(stateDate[0].endDate));
            }

            if (hasSingleTask || hasMultiTaskWithOutChild) {
                //Skip second parent row
                let params = {
                    BuyerId: Searchvalues.Buyer, BrandId: Searchvalues.Brand, SeasonId: Searchvalues.Season, SupplierId: Searchvalues.Supplier,
                    TaskType: taskRow.TaskType, TaskNameId: taskRow.TaskID, TaskTodoInProgressCompletedStatus: Searchvalues.Status, StartDate: StartDate, EndDate: EndDate, Operation: 3, MainId: 0,
                    IsProduction: getIsProductionStatussearch.value
                };
                setSearchvaluesToSub(params);
                dispatch(loadTNAReportList(params));
                Searchvalues.Operation = 3;
                Searchvalues.CurrentIndex = index;
                Searchvalues.CurrentChildIndex = 0;
                setSearchvalues(Searchvalues);

            } else {
                let params = {
                    BuyerId: Searchvalues.Buyer, BrandId: Searchvalues.Brand, SeasonId: Searchvalues.Season, SupplierId: Searchvalues.Supplier,
                    TaskType: taskRow.TaskType, TaskNameId: taskRow.TaskID, TaskTodoInProgressCompletedStatus: Searchvalues.Status, StartDate: StartDate, EndDate: EndDate, Operation: 2,
                    IsProduction: getIsProductionStatussearch.value
                };
                setSearchvaluesToSub(params);
                dispatch(loadTNAReportList(params));
                Searchvalues.Operation = 2;
                Searchvalues.CurrentIndex = index;
                setSearchvalues(Searchvalues);

            }

        }
        else {
            // let i = 0;
            // for (let row of rowHiddens) {
            //     if (!row.className.includes("hidden")) {
            //         row.className = row.className + ' hidden';

            //         values[index].SubTaskList[i].IsExpanded = false;
            //     }
            //     i++;
            // }
            // for (let row2 of rowChildHiddens) {

            //     if (!row2.className.includes("hidden")) {
            //         row2.className = row2.className + ' hidden';
            //         try {
            //             let dataChild = row2.classList[1];
            //             var btnToggleChild = document.getElementById("btn-toggle_" + dataChild);
            //             var iconToggleChild = document.getElementById("icon-toggle_" + dataChild);
            //             btnToggleChild.setAttribute('aria-expanded', 'false');

            //             iconToggleChild.className = iconToggleChild.className.replace("fa-minus-square-o", "fa-plus-square-o");
            //         } catch { }
            //     }
            // }
            btnToggle.setAttribute('aria-expanded', 'false');
            iconToggle.className = iconToggle.className.replace("fa-minus-square-o", "fa-plus-square-o");
            if (values && values[index]) {
                values[index].SubTaskList = [];

            }
            setInputFields(values);
        }
    };



    const handleChildToggle = (index, childIndex, mainId) => {
        let btnToggle = document.getElementById("btn-toggle_parentChild_" + index + "_" + childIndex);
        let iconToggle = document.getElementById("icon-toggle_parentChild_" + index + "_" + childIndex);
        let rowHiddens = document.querySelectorAll(".parentChild_" + index + "_" + childIndex);
        let ariaExpanded = btnToggle.getAttribute('aria-expanded');
        if (ariaExpanded === 'false') {
            values[index].SubTaskList[childIndex].IsExpanded = true;

            for (let row of rowHiddens) {
                row.className = row.className.replace("hidden", " ");
            }
            btnToggle.setAttribute('aria-expanded', 'true');
            iconToggle.className = iconToggle.className.replace("fa-plus-square-o", "fa-minus-square-o");

            let taskRow = values[index].SubTaskList[childIndex];
            let StartDate = null;
            let EndDate = null;
            if (moment(stateDate[0].startDate).isValid()) {
                StartDate = convertUTCDateToLocalDate(new Date(stateDate[0].startDate));
            }
            if (moment(stateDate[0].endDate).isValid()) {
                EndDate = convertUTCDateToLocalDate(new Date(stateDate[0].endDate));
            }
            let params = {
                BuyerId: Searchvalues.Buyer, BrandId: Searchvalues.Brand, SeasonId: Searchvalues.Season, SupplierId: Searchvalues.Supplier,
                TaskType: taskRow.TaskType, TaskNameId: taskRow.TaskID, TaskTodoInProgressCompletedStatus: Searchvalues.Status, StartDate: StartDate, EndDate: EndDate, Operation: 3, MainId: mainId,
                IsProduction: getIsProductionStatussearch.value
            };
            setSearchvaluesToSub(params);
            dispatch(loadTNAReportList(params));
            Searchvalues.Operation = 3;
            Searchvalues.CurrentIndex = index;
            Searchvalues.CurrentChildIndex = childIndex;
            setSearchvalues(Searchvalues);
        }
        else {
            for (let row of rowHiddens) {
                if (!row.className.includes("hidden")) {
                    row.className = row.className + ' hidden';
                }
            }

            values[index].SubTaskList[childIndex].IsExpanded = false;

            btnToggle.setAttribute('aria-expanded', 'false');
            iconToggle.className = iconToggle.className.replace("fa-minus-square-o", "fa-plus-square-o");
        }
        setInputFields(values);
    };


    const commonParentExpand = () => {
        let rowHiddens = document.querySelectorAll(".parentExpand");
        for (let row of rowHiddens) {
            row.setAttribute('aria-expanded', 'false');
        }
        let rowIconHiddens = document.querySelectorAll(".clscollapseexpandTNA");
        for (let rowIcon of rowIconHiddens) {
            rowIcon.className = rowIcon.className.replace("fa-minus-square-o", "fa-plus-square-o");
        }
    }
    // #endregion  ------------------  Expanded Toggle End --------------------------------


    // const handleChangeTNAComments = (event, index, feild) => {
    //     //  const values = { ...inputFields };

    //     if (feild === 'Comment') {
    //         values[index].Comments = event.target.value;
    //     }
    //     else {
    //         values[index].ManualCloseDate = event;
    //     }
    //     setInputFields(values);
    // }

    // const handleSave = (index, Childindex, assignValue, TaskID, TaskName, Id) => {
    //     let IsValid = true;
    //     if (assignValue === '' || assignValue === undefined) {
    //         setComment(true); IsValid = false;
    //         Nodify('Warning!', 'warning', "Please enter comments");
    //     }
    //     if (IsValid) {
    //         if (values[index].Comments.length !== 0) {
    //             if (values[index].Comments !== '') {
    //                 if (EditedCommentIndex !== '') {
    //                     values[index].arrComment[Childindex - 1].TNAComments = assignValue;
    //                 }
    //                 else {
    //                     values[index].arrComment.push({
    //                         TNACommentsID: 0, TaskNameId: TaskID, TNAComments: assignValue, IndexName: values[index].arrComment.length.toString(), TaskIndexName: (index).toString(),
    //                         Date: new Date(),

    //                     });
    //                 }
    //             }
    //             else {
    //                 values[index].arrComment = {
    //                     TNACommentsID: 0, TaskNameId: TaskID, TNAComments: assignValue, IndexName: values[index].arrComment.length.toString(), TaskIndexName: (index).toString(),
    //                     Date: new Date(),
    //                 };
    //             }
    //         }
    //         else {
    //             values[index].arrComments.push({
    //                 TNACommentsID: 0, TaskNameId: TaskID, TNAComments: assignValue, IndexName: values[index].arrComment.length.toString(), TaskIndexName: (index).toString(),
    //                 Date: new Date(),
    //             });
    //         }
    //         values[index].Comments = '';
    //         values[index].IsComment = 0;
    //         values[index].CommentsCount = values[index].arrComment.length;
    //         setInputFields(values);
    //         setEditedCommentIndex('');
    //     }

    //     let ChildStatus = 0;
    //     if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
    //         ChildStatus = 1;
    //     }
    //     else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
    //         TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
    //         TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
    //         ChildStatus = 2;

    //     } else if (TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER ||
    //         TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT ||
    //         TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER ||
    //         TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {

    //         ChildStatus = 3;

    //     } else if (TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE ||
    //         TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || TaskName.toLowerCase() === StaticTask.SMS_APPROVAL || TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH) {
    //         ChildStatus = 4;
    //     }
    //     else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL ||
    //         TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
    //         ChildStatus = 5;
    //     }

    //     var ManualClose = { Id: 0, TaskStatus: 0, ManualCloseComment: '' }
    //     ManualClose.Id = Id;
    //     ManualClose.TaskStatus = ChildStatus;
    //     ManualClose.ManualCloseComment = assignValue;
    //     TnaService.UpdateTaskManualCloseDetails(ManualClose).then(res => {
    //         if (res.data.outputResult === "1") {
    //             Nodify('Success!', 'success', 'Task Manually Closed Successfully');
    //         }
    //         else if (res.data.outputResult === "0") {
    //             Nodify("Error!", "Error Occured!");
    //         }
    //     });


    // };





    const handleChange = (e, index, FieldName) => {
        setSubmitted(false);
        let inputText = '';

        if (e.target.value.trim() !== '') {
            inputText = e.target.value;
        }
        values[index][e.target.name] = inputText;

        setInputFields(values);
    }


    // const handleCloseComment = (index) => {

    //     values[index].IsComment = 0;
    //     setInputFields(values);
    // };



    const handleDependent = (index, value) => {

        values[index].intDependency = value === 0 ? 1 : 0;
        setInputFields(values);
    }





    //#endregion ------------------  Dependency End --------------------------------


    // #region  ------------------  Child Start --------------------------------




    const filterOption = (option, inputValue) => {
        let trimValue = inputValue.trim();
        if (trimValue === "") {
            return option;
        }

        return option.data.subLabel.toLowerCase().includes(trimValue.toLowerCase());
    };


    const CustomInput = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                style={{ width: "100%", border: getComment && props.value === '' ? '1px solid red' : '' }}
                menuPosition="fixed"
                placeholder="DD/MM/YYYY"
            />
        )
    }





    const handletrimmPopupClose = () => {
        setTrimmodalpopup(false);
    }
    const handleTrimappPopupClose = () => {
        setTrimApprovalpopup(false);
    }
    const handleLabDipApp = () => {

        setOpenLabdipApppopup(false);

    }
    const handleStrikeOffApp = () => {
        setStrikeOffApppopup(false);
    }
    const handleprotoSubApp = () => {
        setSampleSubModalpopup(false);
    }
    const handleprotofitApp = () => {
        setSampleAppModalpopup(false);
    }
    const HandleAfterWashList = () => {
        setApperancemodalpopup(false);
    }



    const OpenLabdipSubmssionpopup = (TNAId, LabdibFabricInfoId, StyleId, index, subIndex, childIndex, StatusId) => {
        showLoader();
        // if (values[index].intDependency === 0) {
        //     let Dependency = false;
        //     if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.length > 0) {
        //         let arrstatus = [];
        //         arrstatus = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.split(',');
        //         if (arrstatus.length > 0) {
        //             arrstatus.forEach(element => {
        //                 let val = [];
        //                 val = element.split('-');
        //                 let valIndex = values[parseInt(val[0]) - 1].StatusID
        //                 if (valIndex !== 3) {
        //                     Dependency = true;
        //                 }
        //             });
        //         }
        //         if (Dependency) {
        //             Nodify('Warning!', 'warning', "Please close dependency task");
        //             hideLoader();
        //         }
        //         else {
        //             // values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
        //             setTNAId(TNAId);
        //             setLabdibFabricInfoId(LabdibFabricInfoId);
        //             setStyleId(StyleId);
        //             setTaskStatus(StatusId);
        //             setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
        //             setLapmodal(true);
        //             hideLoader();
        //         }
        //     } else {
        //         //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
        //         setTNAId(TNAId);
        //         setLabdibFabricInfoId(LabdibFabricInfoId);
        //         setStyleId(StyleId);
        //         setTaskStatus(StatusId);
        //         setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
        //         setLapmodal(true);
        //         hideLoader();
        //     }

        // }
        // else {
        setTNAId(TNAId);
        setLabdibFabricInfoId(LabdibFabricInfoId);
        setStyleId(StyleId);
        setTaskStatus(StatusId);
        //setIsProductionStatussearch(getIsProductionStatussearch.value);
        // setSearchvaluesToSub(getSearchvaluestoSub);
        setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
        setLapmodal(true);
        //setSearchvaluestoSub(getSearchvaluestoSub);
        //setSearchValues(SearchValues);
        hideLoader();
        //     //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;

        // }

        // TnaService.LapdipSubmissionList(1, LabdibFabricInfoId,getIsProductionStatussearch.value).then((response) => {
        //     if (response.data) {
        //         setLabdipApprovalList(response.data);
        //         setOpenLabdipApppopup(true);
        //     }
        // }).catch(() => { })

    }

    const OpenLabdipApprovalpopup = (LabdibFabricInfoId, StyleId, index, subIndex, childIndex, StatusId) => {

        TnaService.LapdipSubmissionList(2, LabdibFabricInfoId, getIsProductionStatussearch.value).then((response) => {
            if (response.data.length !== 0) {
                // if (values[index].intDependency === 0) {
                //     let Dependency = false;
                //     if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.length > 0) {
                //         let arrstatus = [];
                //         arrstatus = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.split(',');
                //         if (arrstatus.length > 0) {
                //             arrstatus.forEach(element => {
                //                 let val = [];
                //                 val = element.split('-');
                //                 let valIndex = values[parseInt(val[0]) - 1].StatusID
                //                 if (valIndex !== 3) {
                //                     Dependency = true;
                //                 }
                //             });
                //         }
                //         if (Dependency) {
                //             Nodify('Warning!', 'warning', "Please close dependency task");
                //         }
                //         else {
                //             // values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
                //             setLabdibFabricInfoId(LabdibFabricInfoId);
                //             setSubmittedOptionListId(LabdibFabricInfoId);
                //             setStyleId(StyleId)
                //             setTaskStatus(StatusId);
                //             setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                //             TnaService.LapdipSubmissionList(2, LabdibFabricInfoId).then((response) => {
                //                 if (response.data) {
                //                     setLabdipApprovalList(response.data);
                //                     setOpenLabdipApppopup(true);
                //                 }
                //             }).catch(() => { })
                //         }
                //     } else {
                //         //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
                //         setLabdibFabricInfoId(LabdibFabricInfoId);
                //         setSubmittedOptionListId(LabdibFabricInfoId);
                //         setStyleId(StyleId)
                //         setTaskStatus(StatusId);
                //         setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                //         TnaService.LapdipSubmissionList(2, LabdibFabricInfoId).then((response) => {
                //             if (response.data) {
                //                 setLabdipApprovalList(response.data);
                //                 setOpenLabdipApppopup(true);
                //             }
                //         }).catch(() => { })
                //     }
                // }
                // else {
                setLabdibFabricInfoId(LabdibFabricInfoId);
                setSubmittedOptionListId(LabdibFabricInfoId);
                setStyleId(StyleId)
                setTaskStatus(StatusId);
                setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                TnaService.LapdipSubmissionList(2, LabdibFabricInfoId, getIsProductionStatussearch.value).then((response) => {
                    if (response.data) {
                        setLabdipApprovalList(response.data);
                        setOpenLabdipApppopup(true);
                    }
                }).catch(() => { })
                // }
            }
            else {
                Nodify('Warning!', 'warning', "Submission not yet added for this task");
                return false;
            }

        }).catch(() => { });

    }

    // const OpenLabdipApprovalpopup = (LabdibFabricInfoId, StyleId, index, subIndex, childIndex) => {
    //     setLabdibFabricInfoId(LabdibFabricInfoId);
    //     setSubmittedOptionListId(LabdibFabricInfoId);
    //     setStyleId(StyleId)
    //     setOpenLabdipApppopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }

    const OpenStrikeoffSubmssionpopup = (TNAId, TNAStrikeOffRefInfoId, index, subIndex, childIndex, StatusId) => {
        // if (values[index].intDependency === 0) {
        //     let Dependency = false;
        //     if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.length > 0) {
        //         let arrstatus = [];
        //         arrstatus = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.split(',');
        //         if (arrstatus.length > 0) {
        //             arrstatus.forEach(element => {
        //                 let val = [];
        //                 val = element.split('-');
        //                 let valIndex = values[parseInt(val[0]) - 1].StatusID
        //                 if (valIndex !== 3) {
        //                     Dependency = true;
        //                 }
        //             });
        //         }
        //         if (Dependency) {
        //             Nodify('Warning!', 'warning', "Please close dependency task");
        //         }
        //         else {
        //             setTNAId(TNAId);
        //             setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
        //             setTaskStatus(StatusId);
        //             setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
        //             setStrikeOffmodalpopup(true);
        //         }
        //     } else {
        //         setTNAId(TNAId);
        //         setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
        //         setTaskStatus(StatusId);
        //         setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
        //         setStrikeOffmodalpopup(true);
        //     }
        // }
        // else {
        setTNAId(TNAId);
        setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
        setTaskStatus(StatusId);
        setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
        setStrikeOffmodalpopup(true);

        // }

    }

    // const OpenStrikeoffSubmssionpopup = (TNAId, TNAStrikeOffRefInfoId, index, subIndex, childIndex) => {
    //     setTNAId(TNAId);
    //     setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
    //     setStrikeOffmodalpopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }

    const OpenStrikeoffApprovalpopup = (TNAStrikeOffRefInfoId, index, subIndex, childIndex, StatusId) => {
        TnaService.StrikeoffSubmissionList(3, TNAStrikeOffRefInfoId, getIsProductionStatussearch.value).then((response) => {
            if (response.data.length !== 0) {
                // if (values[index].intDependency === 0) {
                //     let Dependency = false;
                //     if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.length > 0) {
                //         let arrstatus = [];
                //         arrstatus = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.split(',');
                //         if (arrstatus.length > 0) {
                //             arrstatus.forEach(element => {
                //                 let val = [];
                //                 val = element.split('-');
                //                 let valIndex = values[parseInt(val[0]) - 1].StatusID
                //                 if (valIndex !== 3) {
                //                     Dependency = true;
                //                 }
                //             });
                //         }
                //         if (Dependency) {
                //             Nodify('Warning!', 'warning', "Please close dependency task");
                //         }
                //         else {
                //             setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
                //             setSubmittedOptionListId(TNAStrikeOffRefInfoId);
                //             setColorAppCommentsId(TNAStrikeOffRefInfoId);
                //             setTaskStatus(StatusId);
                //             setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                //             setStrikeOffApppopup(true);
                //         }
                //     } else {
                //         setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
                //         setSubmittedOptionListId(TNAStrikeOffRefInfoId);
                //         setColorAppCommentsId(TNAStrikeOffRefInfoId);
                //         setTaskStatus(StatusId);
                //         setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                //         setStrikeOffApppopup(true);
                //     }
                // }
                // else {
                setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
                setSubmittedOptionListId(TNAStrikeOffRefInfoId);
                setColorAppCommentsId(TNAStrikeOffRefInfoId);
                setTaskStatus(StatusId);
                setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                setStrikeOffApppopup(true);

                // }
            }
            else {
                Nodify('Warning!', 'warning', "Submission not yet added for this task");
                return false;
            }

        }).catch(() => { });

    }

    // const OpenStrikeoffApprovalpopup = (TNAStrikeOffRefInfoId, index, subIndex, childIndex) => {
    //     setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
    //     setSubmittedOptionListId(TNAStrikeOffRefInfoId);
    //     setColorAppCommentsId(TNAStrikeOffRefInfoId);
    //     setStrikeOffApppopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }

    const OpenTrimSubmssionpopup = (TNAId, TNATrimRefInfoId, index, subIndex, childIndex, StatusId) => {
        // if (values[index].intDependency === 0) {
        //     let Dependency = false;
        //     if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.length > 0) {
        //         let arrstatus = [];
        //         arrstatus = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.split(',');
        //         if (arrstatus.length > 0) {
        //             arrstatus.forEach(element => {
        //                 let val = [];
        //                 val = element.split('-');
        //                 let valIndex = values[parseInt(val[0]) - 1].StatusID
        //                 if (valIndex !== 3) {
        //                     Dependency = true;
        //                 }
        //             });
        //         }
        //         if (Dependency) {
        //             Nodify('Warning!', 'warning', "Please close dependency task");
        //         }
        //         else {
        //             setTNAId(TNAId);
        //             setTNATrimRefInfoId(TNATrimRefInfoId);
        //             setTaskStatus(StatusId);
        //             setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex });
        //             setTrimmodalpopup(true);
        //         }
        //     } else {
        //         setTNAId(TNAId);
        //         setTNATrimRefInfoId(TNATrimRefInfoId);
        //         setTaskStatus(StatusId);
        //         setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex });
        //         setTrimmodalpopup(true);
        //     }
        // }
        // else {
        setTNAId(TNAId);
        setTNATrimRefInfoId(TNATrimRefInfoId);
        setTaskStatus(StatusId);
        setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex });
        setTrimmodalpopup(true);

        // }

    }

    // const OpenTrimSubmssionpopup = (TNAId, TNATrimRefInfoId, index, subIndex, childIndex) => {
    //     setTNAId(TNAId);
    //     setTNATrimRefInfoId(TNATrimRefInfoId);
    //     setTrimmodalpopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }

    const OpenTrimApprovalpopup = (TNATrimRefInfoId, index, subIndex, childIndex, StatusId) => {
        TnaService.TrimSubmissionList(3, TNATrimRefInfoId, getIsProductionStatussearch.value).then((response) => {
            if (response.data.length !== 0) {
                // if (values[index].intDependency === 0) {
                //     let Dependency = false;
                //     if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.length > 0) {
                //         let arrstatus = [];
                //         arrstatus = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.split(',');
                //         if (arrstatus.length > 0) {
                //             arrstatus.forEach(element => {
                //                 let val = [];
                //                 val = element.split('-');
                //                 let valIndex = values[parseInt(val[0]) - 1].StatusID
                //                 if (valIndex !== 3) {
                //                     Dependency = true;
                //                 }
                //             });
                //         }
                //         if (Dependency) {
                //             Nodify('Warning!', 'warning', "Please close dependency task");
                //         }
                //         else {
                //             setTNATrimRefInfoId(TNATrimRefInfoId);
                //             setSubmittedOptionListId(TNATrimRefInfoId);
                //             setColorAppCommentsId(TNATrimRefInfoId);
                //             setTaskStatus(StatusId);
                //             setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                //             setTrimApprovalpopup(true);
                //         }
                //     } else {
                //         setTNATrimRefInfoId(TNATrimRefInfoId);
                //         setSubmittedOptionListId(TNATrimRefInfoId);
                //         setColorAppCommentsId(TNATrimRefInfoId);
                //         setTaskStatus(StatusId);
                //         setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex });
                //         setTrimApprovalpopup(true);
                //     }
                // }
                // else {
                setTNATrimRefInfoId(TNATrimRefInfoId);
                setSubmittedOptionListId(TNATrimRefInfoId);
                setColorAppCommentsId(TNATrimRefInfoId);
                setTaskStatus(StatusId);
                setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex });
                setTrimApprovalpopup(true);

                // }
            }
            else {
                Nodify('Warning!', 'warning', "Submission not yet added for this task");
                return false;
            }

        }).catch(() => { });

    }



    // const OpenTrimApprovalpopup = (TNATrimRefInfoId, index, subIndex, childIndex) => {
    //     setTNATrimRefInfoId(TNATrimRefInfoId);
    //     setSubmittedOptionListId(TNATrimRefInfoId);
    //     setColorAppCommentsId(TNATrimRefInfoId);
    //     setTrimApprovalpopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }

    const OpenProtoFitSamplepopup = (TNAId, TNAProtoFitId, index, subIndex, childIndex, ChildTaskName, ParentTask, Styleid, StatusId) => {
        // if (values[index].intDependency === 0) {
        //     let Dependency = false;
        //     if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.length > 0) {
        //         let arrstatus = [];
        //         arrstatus = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.split(',');
        //         if (arrstatus.length > 0) {
        //             arrstatus.forEach(element => {
        //                 let val = [];
        //                 val = element.split('-');
        //                 let valIndex = values[parseInt(val[0]) - 1].StatusID
        //                 if (valIndex !== 3) {
        //                     Dependency = true;
        //                 }
        //             });
        //         }
        //         if (Dependency) {
        //             Nodify('Warning!', 'warning', "Please close dependency task");
        //         }
        //         else {
        //             setTNAId(TNAId);
        //             setTNAProtoFitId(TNAProtoFitId);
        //             setChildTask(ChildTaskName);
        //             setParentTask(ParentTask);
        //             setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
        //             setStyleId(Styleid);
        //             setTaskStatus(StatusId);
        //             setSampleSubModalpopup(true);
        //         }
        //     } else {
        //         setTNAId(TNAId);
        //         setTNAProtoFitId(TNAProtoFitId);
        //         setChildTask(ChildTaskName);
        //         setParentTask(ParentTask);
        //         setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
        //         setStyleId(Styleid);
        //         setTaskStatus(StatusId);
        //         setSampleSubModalpopup(true);
        //     }
        // }
        // else {
        setTNAId(TNAId);
        setTNAProtoFitId(TNAProtoFitId);
        setChildTask(ChildTaskName);
        setParentTask(ParentTask);
        setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
        setStyleId(Styleid);
        setTaskStatus(StatusId);
        setSampleSubModalpopup(true);

        // }

    }

    // const OpenProtoFitSamplepopup = (TNAId, TNAProtoFitId, index, subIndex, childIndex, TaskName) => {
    //     setTNAId(TNAId);
    //     setTNAProtoFitId(TNAProtoFitId);
    //     setTNATaskName(TaskName);
    //     setSampleSubModalpopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }


    const OpenProtoFitApprovalpopup = (TNAProtoFitId, index, subIndex, childIndex, ParentTask, StatusId) => {
        var Operation = 0;
        if (ParentTask === "Proto Sample Approval") {
            Operation = 7;
        }
        else if (ParentTask === "Fit Sample Approval") {
            Operation = 8;
        }
        else if (ParentTask === "SMS Approval" || ParentTask === "'Size Set Sample Approval'" || ParentTask === "Pre Production Sample Approval"
            || ParentTask === "Shipment Sample Approval"
            || ParentTask === "Photo Sample Approval") {
            Operation = 9;
        }
        else {
            Operation = 0;
        }

        TnaService.GetProtoFitSMSSubmission(Operation, TNAProtoFitId).then((response) => {

            if (response.data.length !== 0) {
                // if (values[index].intDependency === 0) {
                //     let Dependency = false;
                //     if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.length > 0) {
                //         let arrstatus = [];
                //         arrstatus = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.split(',');
                //         if (arrstatus.length > 0) {
                //             arrstatus.forEach(element => {
                //                 let val = [];
                //                 val = element.split('-');
                //                 let valIndex = values[parseInt(val[0]) - 1].StatusID
                //                 if (valIndex !== 3) {
                //                     Dependency = true;
                //                 }
                //             });
                //         }
                //         if (Dependency) {
                //             Nodify('Warning!', 'warning', "Please close dependency task");
                //         }
                //         else {
                //             setTNAProtoFitId(TNAProtoFitId);
                //             setParentTask(ParentTask);
                //             setTaskStatus(StatusId);
                //             setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                //             setSampleAppModalpopup(true);
                //         }
                //     } else {
                //         setTNAProtoFitId(TNAProtoFitId);
                //         setParentTask(ParentTask);
                //         setTaskStatus(StatusId);
                //         setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                //         setSampleAppModalpopup(true);
                //     }
                // }
                // else {
                setTNAProtoFitId(TNAProtoFitId);
                setParentTask(ParentTask);
                setTaskStatus(StatusId);
                setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                setSampleAppModalpopup(true);
                // }
            }
            else {
                Nodify('Warning!', 'warning', "Submission not yet added for this task");
                return false;
            }

        }).catch(() => { });

    }
    // const OpenProtoFitApprovalpopup = (TNAProtoFitId, index, subIndex, childIndex) => {
    //     setTNAProtoFitId(TNAProtoFitId);
    //     setSampleAppModalpopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }

    const OpenAppearenceAfterWashpopup = (TNAId, AppareanceTestId, StyleId, index, subIndex, childIndex, StatusId) => {
        // if (values[index].intDependency === 0) {
        //     let Dependency = false;
        //     if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.length > 0) {
        //         let arrstatus = [];
        //         arrstatus = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.split(',');
        //         if (arrstatus.length > 0) {
        //             arrstatus.forEach(element => {
        //                 let val = [];
        //                 val = element.split('-');
        //                 let valIndex = values[parseInt(val[0]) - 1].StatusID
        //                 if (valIndex !== 3) {
        //                     Dependency = true;
        //                 }
        //             });
        //         }
        //         if (Dependency) {
        //             Nodify('Warning!', 'warning', "Please close dependency task");
        //         }
        //         else {
        //             setTNAId(TNAId);
        //             setAppareanceTestId(AppareanceTestId);
        //             setStyleId(StyleId)
        //             setTaskStatus(StatusId);
        //             setApperancemodalpopup(true);
        //             setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
        //         }
        //     } else {
        //         setTNAId(TNAId);
        //         setAppareanceTestId(AppareanceTestId);
        //         setStyleId(StyleId)
        //         setTaskStatus(StatusId);
        //         setApperancemodalpopup(true);
        //         setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
        //     }
        // }
        // else {
        setTNAId(TNAId);
        setAppareanceTestId(AppareanceTestId);
        setStyleId(StyleId)
        setTaskStatus(StatusId);
        setApperancemodalpopup(true);
        setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
        // }

    }
    const handleLabDip = () => {
        setLapmodal(false);
    };



    const LapdipSubmissionCallback = (value, getOrderinfo) => {

        if (value === true) {
            setLapmodal(false);

            getOrderinfo.forEach((element, index) => {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList.forEach((element1, index1) => {
                    if (element.labdibFabricInfoId === element1.ChildId) {
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].IsShowSubmitButton = 0;
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].StatusID = 3;
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ManualCloseDate = new Date();
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate = new Date();
                    }
                })
            })

            setInputFields(values);
        }
    }

    const LapdipApprovalCallback = (value, getOrderinfo) => {
        if (value === true) {
            setOpenLabdipApppopup(false);
            getOrderinfo.forEach((element, index) => {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList.forEach((element1, index1) => {
                    if (element.labdibFabricInfoId === element1.ChildId) {
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].IsShowApprovalButton = 0;
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].StatusID = 3;
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ManualCloseDate = new Date();
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate = new Date();
                    }
                })
            })

            setInputFields(values);
        }
    }

    const StrikeoffSubmissionCallback = (value, getOrderinfo) => {

        if (value === true) {
            setStrikeOffmodalpopup(false);
            getOrderinfo.forEach((element, index) => {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList.forEach((element1, index1) => {
                    if (element.TNAStrikeOffRefInfoId === element1.ChildId) {
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].IsShowSubmitButton = 0;
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].StatusID = 3;
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ManualCloseDate = new Date();
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate = new Date();
                    }
                })
            })


            setInputFields(values);

        }
    }

    const StrikeoffApprovalCallback = (value, getOrderinfo) => {

        if (value === true) {
            getOrderinfo.forEach((element, index) => {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList.forEach((element1, index1) => {
                    if (element.tnaStrikeOffRefInfoId === element1.ChildId) {
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].IsShowApprovalButton = 0;
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].StatusID = 3;
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ManualCloseDate = new Date();
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate = new Date();
                    }
                })
            })

            setInputFields(values);
            setStrikeOffApppopup(false);
        }
    }

    const TrimSubmissionCallback = (value, getOrderinfo) => {
        if (value === true) {
            getOrderinfo.forEach((element, index) => {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList.forEach((element1, index1) => {
                    if (element.TNATrimRefInfoId === element1.ChildId) {
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].IsShowSubmitButton = 0;
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].StatusID = 3;
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ManualCloseDate = new Date();
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate = new Date();
                    }
                })
            })


            setInputFields(values);
            setTrimmodalpopup(false);
        }
    }

    const TrimApprovalCallback = (value, getOrderinfo) => {
        if (value === true) {
            getOrderinfo.forEach((element, index) => {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList.forEach((element1, index1) => {
                    if (element.tnaTrimRefInfoId === element1.ChildId) {
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].IsShowApprovalButton = 0;
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].StatusID = 3;
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ManualCloseDate = new Date();
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate = new Date();
                    }
                })
            })

            setInputFields(values);
            setTrimApprovalpopup(false);
        }
    }



    const ProtoFitSMSSubmissionCallback = (value) => {
        if (value === true) {
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StatusID = 3;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ManualCloseDate = new Date();
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate = new Date();
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StrActualEndDate = moment(new Date()).format("MM/DD/YYYY");
            setInputFields(values);
            setSampleSubModalpopup(false);
        }
    }

    const ProtoFitAprovalCallback = (value) => {
        if (value === true) {
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StatusID = 3;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ManualCloseDate = new Date();
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate = new Date();
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StrActualEndDate = moment(new Date()).format("MM/DD/YYYY");
            setInputFields(values);
            setSampleAppModalpopup(false);
        }
    }

    const AppareanceTestSubmissionCallback = (value, Operation) => {
        if (value === true) {
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StatusID = 3;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ManualCloseDate = new Date();
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate = new Date();
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StrActualEndDate = moment(new Date()).format("MM/DD/YYYY");

        }

        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].IsShowSubmitButton = 3;
        setInputFields(values);
        setApperancemodalpopup(false);
    }

    function DeleteCallbackQA(value) {
        if (value === true) {
            let getStateDate = [...stateDate];
            getStateDate[0].isShow = false;
            setStateDate(getStateDate);

            let StartDate = null;
            let EndDate = null;
            if (moment(stateDate[0].startDate).isValid()) {
                StartDate = convertUTCDateToLocalDate(new Date(stateDate[0].startDate));
                //StartDate.setMinutes(StartDate.getMinutes() + 370);
            }
            if (moment(stateDate[0].endDate).isValid()) {
                EndDate = convertUTCDateToLocalDate(new Date(stateDate[0].endDate));
                //EndDate.setMinutes(EndDate.getMinutes() + 370);
            }
            if (Searchvalues.Status) { }

            let params = {
                BuyerId: Searchvalues.Buyer, BrandId: Searchvalues.Brand, SeasonId: Searchvalues.Season, SupplierId: Searchvalues.Supplier,
                TaskType: Searchvalues.TaskType, TaskNameId: Searchvalues.TaskName, TaskTodoInProgressCompletedStatus: Searchvalues.Status, StartDate: StartDate, EndDate: EndDate, Operation: 1
                , IsProduction: getIsProductionStatussearch.value
            };

            setSearchvaluesToSub(params);
            dispatch(loadTNAReportList(params));
            //Searchvalues.Status = 4;
            setSearchvalues(Searchvalues);
            commonParentExpand();
            setID({ showPopupDelete: false, Params: [] });
        } else {
            setID({ showPopupDelete: false, Params: [] });
        }
    }
    function delCallbackTest(value) {
        if (value === true) {
            let getStateDate = [...stateDate];
            getStateDate[0].isShow = false;
            setStateDate(getStateDate);

            let StartDate = null;
            let EndDate = null;
            if (moment(stateDate[0].startDate).isValid()) {
                StartDate = convertUTCDateToLocalDate(new Date(stateDate[0].startDate));
                //StartDate.setMinutes(StartDate.getMinutes() + 370);
            }
            if (moment(stateDate[0].endDate).isValid()) {
                EndDate = convertUTCDateToLocalDate(new Date(stateDate[0].endDate));
                //EndDate.setMinutes(EndDate.getMinutes() + 370);
            }
            if (Searchvalues.Status) { }

            let params = {
                BuyerId: Searchvalues.Buyer, BrandId: Searchvalues.Brand, SeasonId: Searchvalues.Season, SupplierId: Searchvalues.Supplier,
                TaskType: Searchvalues.TaskType, TaskNameId: Searchvalues.TaskName, TaskTodoInProgressCompletedStatus: Searchvalues.Status, StartDate: StartDate, EndDate: EndDate, Operation: 1
                , IsProduction: getIsProductionStatussearch.value
            };

            setSearchvaluesToSub(params);
            dispatch(loadTNAReportList(params));
            //Searchvalues.Status = 4;
            setSearchvalues(Searchvalues);
            commonParentExpand();
            setIDTestTask({ showPopupDelete: false, Params: [] });
        } else {
            setIDTestTask({ showPopupDelete: false, Params: [] });
        }
    }
    function delCallbackFinal(value) {
        if (value === true) {
            let getStateDate = [...stateDate];
            getStateDate[0].isShow = false;
            setStateDate(getStateDate);

            let StartDate = null;
            let EndDate = null;
            if (moment(stateDate[0].startDate).isValid()) {
                StartDate = convertUTCDateToLocalDate(new Date(stateDate[0].startDate));
                //StartDate.setMinutes(StartDate.getMinutes() + 370);
            }
            if (moment(stateDate[0].endDate).isValid()) {
                EndDate = convertUTCDateToLocalDate(new Date(stateDate[0].endDate));
                //EndDate.setMinutes(EndDate.getMinutes() + 370);
            }
            if (Searchvalues.Status) { }

            let params = {
                BuyerId: Searchvalues.Buyer, BrandId: Searchvalues.Brand, SeasonId: Searchvalues.Season, SupplierId: Searchvalues.Supplier,
                TaskType: Searchvalues.TaskType, TaskNameId: Searchvalues.TaskName, TaskTodoInProgressCompletedStatus: Searchvalues.Status, StartDate: StartDate, EndDate: EndDate, Operation: 1
                , IsProduction: getIsProductionStatussearch.value
            };

            setSearchvaluesToSub(params);
            dispatch(loadTNAReportList(params));
            //Searchvalues.Status = 4;
            setSearchvalues(Searchvalues);
            commonParentExpand();
            setIDSingleTask({ showPopupDelete: false, Params: [] });
        } else {
            setIDSingleTask({ showPopupDelete: false, Params: [] });
        }
    }
    // const EditComments = (index, Value, Childindex) => {

    //     values[index].Comments = Value;
    //     //values.Comments = Value;
    //     setEditedCommentIndex(Childindex);
    //     setInputFields(values);
    // }

    // const RemoveComments = (index, ChildIndex2) => {
    //     values[index].arrComment.splice(ChildIndex2, 1);
    //     values[index].CommentsCount = values[index].arrComment.length;
    //     setInputFields(values);


    // }

    const handleChildManualclose = (index, subIndex, childIndex, TaskName, ChildId, Status) => {
       
        setComment(false)
        let Operation;
        if (TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
            Operation = 1;
        }
        else if (TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT) {
            Operation = 3;
        }
        else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
            Operation = 2;
        }
        else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL) {
            Operation = 4;
        }
        else if (TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
            Operation = 5;
        }

        else if (TaskName.toLowerCase() === StaticTask.SMS_APPROVAL) {
            Operation = 6;
        }
        else if (TaskName.toLowerCase() === StaticTask.SIZE_SET_APPROVAL) {
            Operation = 8;
        }
        else if (TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL) {
            Operation = 9;
        }
        else if (TaskName.toLowerCase() === StaticTask.SHIPMENT_APPROVAL) {
            Operation = 10;
        }
        else if (TaskName.toLowerCase() === StaticTask.PHOTO_APPROVAL) {
            Operation = 11;
        }
        else {
            Operation = 7;

        }
        if (Operation !== 7) { // check submission exists for approvals
            TnaService.GetTNAManualCloseSubmissionList(ChildId, TaskName).then((response) => {
                if (response.data.length !== 0) {
                    LoadManualCloseDataList(index, subIndex, childIndex, TaskName, ChildId, Status);
                }
                else {
                    Nodify('Warning!', 'warning', "Manual Submission not yet added for this task");
                    return false;
                }

            }).catch(() => { });
        }
        else {
            LoadManualCloseDataList(index, subIndex, childIndex, TaskName, ChildId, Status);
        }
        //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
        //setInputFields(values);

        // if (values[index].intDependency === 0) {
        //     let Dependency = false;
        //     if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.length > 0) {
        //         let arrstatus = [];
        //         arrstatus = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.split(',');
        //         if (arrstatus.length > 0) {
        //             arrstatus.forEach(element => {
        //                 let val = [];
        //                 val = element.split('-');
        //                 let valIndex = values[parseInt(val[0]) - 1].StatusID
        //                 if (valIndex !== 3) {
        //                     Dependency = true;
        //                 }
        //             });
        //         }
        //         if (Dependency) {
        //             Nodify('Warning!', 'warning', "Please close dependency task");
        //         }
        //         else {
        //             LoadManualCloseDataList(index, subIndex, childIndex, TaskName, ChildId);
        //             //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
        //             // setInputFields(values);
        //         }
        //     } else {
        //         LoadManualCloseDataList(index, subIndex, childIndex, TaskName, ChildId);
        //         // values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
        //         // setInputFields(values);
        //     }
        // }
        // else {

        //     // values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
        //     // setInputFields(values);

        // }


        //setInputFields(values);
    };
    const pageRedirectForFinalInspection = (Page) => {

        setFinalInspectionPageView(Page);
        // props.location.state.params.page = "ReInspection";
        // props.location.state.params.ParentTask = "ReInspection";
        // props.location.state.params.Mode = "ReInspection";
    }
    const AddExFactoryForm = (index, subIndex, childIndex) => {

        let Multiselected = [];
        const Values = [...getfinalinspectionMulti]
        let isvalid = true;
        isvalid = !!getfinalinspectionMulti.find(x => x.isSelected === 1)
        if (isvalid) {
            Multiselected = Values.concat(getfinalinspectionMulti.filter(x => x.isSelected === 1))
            //setMultiCloseDatas(Multiselected)
            // setfinalinspectionMulti(Multiselected)


        } else {
            Nodify('Warning!', 'warning', 'Please fill atleast one checkbox');
            return false;
        }



        //-----------------------below codes were commented to just ignore the functionality---------------------//
        // let strValue = "";
        // let selected = 0;
        // for (let data of MultExFactorySKUList) {
        //     if (strValue === "") {
        //         if (data.isSelected === 1) {
        //             strValue = data.tnaExFactoryId.toString();
        //             selected = selected + 1;
        //         }
        //     } else {
        //         if (data.isSelected === 1) {
        //             strValue = strValue + "," + data.tnaExFactoryId;
        //         }
        //     }
        // }
        // if (selected === 0) {
        //     Nodify('Warning!', 'warning', "Select atleast one Task");
        // }
        // else {
        //     let SkuId = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubId;
        //     let TaskName = values[index].strTaskName;
        //     let TaskID = values[index].TaskID;
        //     let dataParams = {
        //         BuyerID: getTNABuyerID, SeasonID: getTNASeasonID,
        //         BrandID: getTNABrandID, SupplierID: getTNASupplierID,
        //         StyleId: props.location.state.params.styleId, SkuId: SkuId,
        //         TaskName: TaskName, TaskID: TaskID,
        //         TnaExFactoryIdList: strValue, MultExFactorySKUList: MultExFactorySKUList,
        //         StyleInfo: getStyleInfo

        //     };
        //     props.history.push(`/ReportCreationMain`, { params: dataParams });
        // }
        /////////////////temporary code////////////////-kokilapriya
        setIsAddFinalInspection(true);
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsOpenInspectionConfirmationType = 1;
        //props.history.push(`/ReportCreationMain`, { params: '' });
    };
    function pageRedirectForTestingInspection(val) {
        setTestingReportPageView(val)
    }
    const AddTestingExFactoryForm = (index, subIndex, childIndex) => {

        let Multiselected = [];
        const Values = [...getTestinginspectionMulti]
        let isvalid = true;
        let isvalidED = true;
        isvalid = !!getTestinginspectionMulti.find(x => x.isSelected === 1)
        if (isvalid) {
            Multiselected = Values.concat(getTestinginspectionMulti.filter(x => x.isSelected === 1))
            //setMultiCloseDatas(Multiselected)
            // setfinalinspectionMulti(Multiselected)


        } else {
            Nodify('Warning!', 'warning', 'Please check atleast one checkbox');
            isvalidED = false;
            return false;
        }
        // }
        /////////////////temporary code////////////////-kokilapriya
        if (isvalidED) {
            setIsAddFinalInspection(true);
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsOpenTestingConfirmationType = 1;
        }
        //props.history.push(`/ReportCreationMain`, { params: '' });
    };
    const handleCloseTestingExFactoryFormPopup = (index, subIndex, childIndex) => {
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsOpenTestingConfirmationType = 0;
        //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsExFactoryForm = 0;
        setInputFields(values);
    };
    async function CreateTesting(type, tnaID) { // type=1=> internal, 2=> external
       
        let isvalid = true;
        if (isvalid) {
            let sizeandqtyfordisplay = "";
            let PurchaseorderSKUID = '';
            let NewArr = [];
            NewArr = getTestinginspectionMulti.filter(x => x.isSelected === 1)
            NewArr.map((Purchaseorder) => {
                if (sizeandqtyfordisplay === '' && PurchaseorderSKUID === '') {
                    sizeandqtyfordisplay = Purchaseorder.refInspecSKUID.toString();
                    //PurchaseorderSKUID = Purchaseorder.purchaseOrderSkuID.toString();
                } else {
                    sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.refInspecSKUID
                    //PurchaseorderSKUID = PurchaseorderSKUID + ',' + Purchaseorder.purchaseOrderSkuID
                }
            })
            if (getTestingReportPageView === "ReInspection") {
                let selectedInspIds = '';
                NewArr.map(x => {
                    if (x.isSelected === 1) {
                        if (selectedInspIds === '') {
                            if (!selectedInspIds.includes(x.tnaTestingInspectionID)) {
                                selectedInspIds = x.tnaTestingInspectionID.toString();
                            }
                        }
                        else {
                            if (!selectedInspIds.includes(x.tnaTestingInspectionID)) {
                                selectedInspIds = selectedInspIds + "," + x.tnaTestingInspectionID.toString();
                            }
                        }
                    }
                })

                let uniquevalues = [...new Map(NewArr.map(item =>
                    [item['skuName'], item])).values()];

                uniquevalues.map(x => {
                    let totqty = 0;
                    x.FinalInspectionIds = selectedInspIds;
                })
                uniquevalues.map(x => {
                    let totqty = 0;
                    x.refInspecSKUID = x.skuID;
                })

                getTestingTaskInfo.multiselecteddatas = uniquevalues;
                getTestingTaskInfo.FinalInspectionIds = uniquevalues[0].reInspectionAgainst != '' && uniquevalues[0].reInspectionAgainst != null
                    ? uniquevalues[0].reInspectionAgainst : uniquevalues[0].FinalInspectionIds;
            }
            else {
                getTestingTaskInfo.multiselecteddatas = getTestinginspectionMulti;
            }
            getTestingTaskInfo.supplierID = Searchvalues.Supplier;
            getTestingTaskInfo.supplierName = NewArr[0].supplierName;
            getTestingTaskInfo.multiselecteddatas = getTestinginspectionMulti;
            getTestingTaskInfo.quantityinfo = getTestingTaskListParam// For Binding Selected checkbox values in quantity inforamtion
            getTestingTaskInfo.details = { tnaid: tnaID, purchaseOrderID: 0 };
            getTestingTaskInfo.TnaID = tnaID
            getTestingTaskInfo.type = type;//For InterNal Or External
            getTestingTaskInfo.SkuID = sizeandqtyfordisplay;//For Multiple SKUID like(4123,4122) for Base load list for order&detaails
            //.PurchaseOrderSkuID = PurchaseorderSKUID;//For Multiple SKUID like(4123,4122) for Packing and PackingImage
            getTestingTaskInfo.Isproduction = getIsProductionStatussearch.value;
            getTestingTaskInfo.page = getTestingReportPageView;//For InspectionType(Reinspection Or New inspection)
            getTestingTaskInfo.pageType = 'AddInspection';//For HeaderName Add,view or edit
            getTestingTaskInfo.ParentTask = "Dashboard";//Task Based back or save redirect function
            props.history.push(`/createTesting`, { params: getTestingTaskInfo });
        }
    }
    const handleCloseTestingTaskFormPopup = (index, subIndex, childIndex) => {
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsTestingTaskFactory = 0;
        setInputFields(values);
    };
    function QCTestingCallback(value) {
        setTestinginspectionMulti(value)
    }
    function FinalInspectionCallback(value) {

        setfinalinspectionMulti(value)
        // props.location.state.params.page = "InspectionAdd";
        //  props.location.state.params.ParentTask = "TNAEdit";
        // props.location.state.params.Mode = "InspectionAdd";
    }
    function QCFinalInspectionCallback(value) {
        setQCfinalinspectionMulti(value);
    }
    function QCInspectionCallback(value) {
        setQCfinalinspectionMulti(value);
    }
    const handleCloseQCinspectionFormPopup = (index, subIndex, childIndex) => {
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsQCinsectionFactory = 0;
        setInputFields(values);
    };
    const CreateFinalInspection = (type, Inspectionpage) => { // type=1=> internal, 2=> external

        // StyleService.GetStyleList(getAddInspectionListParam.StyleId, IsProduction, 0, '', '').then((response) => {
        //     if (response.data) {

        //         setInspectionInfo({
        //             buyerID: response.data.item1[0].buyerID
        //             , buyerName: response.data.item1[0].buyerName
        //             , brandID: response.data.item1[0].brandID
        //             , brandName: response.data.item1[0].brandName
        //             , seasonName: response.data.item1[0].seasonName
        //             , seasonID: response.data.item1[0].seasonID
        //             , supplierID: response.data.item1[0].supplierID
        //             , supplierName: props.location.state.params.supplierName
        //             , styleID: props.location.state.params.styleId
        //             , styleName: props.location.state.params.styleNames
        //             , type: 0
        //             , TaskStatus: 0
        //         });
        //     }
        // }).catch(() => { });
        let sizeandqtyfordisplay = "";
        let NewArr = [];
        NewArr = getfinalinspectionMulti.filter(x => x.isSelected === 1)
        NewArr.map((Purchaseorder) => {
            if (sizeandqtyfordisplay === '') {
                sizeandqtyfordisplay = Purchaseorder.purchaseOrderSkuID;
            } else {
                sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.purchaseOrderSkuID
            }
        })
        getfinalinspectionMulti.filter(x => x.isSelected === 1).map((x) => {
            if (x.isSelected === 1) {
                x.IscheckReportSelect = 1;
            } else {
                x.IscheckReportSelect = 0;
            }

        })

        if (getFinalInspectionPageView === "ReInspection") {
            let selectedInspIds = '';
            NewArr.map(x => {
                if (x.isSelected === 1) {
                    if (selectedInspIds === '') {
                        if (!selectedInspIds.includes(x.tnaFinalInspectionID)) {
                            selectedInspIds = x.tnaFinalInspectionID.toString();
                        }
                    }
                    else {
                        if (!selectedInspIds.includes(x.tnaFinalInspectionID)) {
                            selectedInspIds = selectedInspIds + "," + x.tnaFinalInspectionID.toString();
                        }
                    }
                }
            })

            let uniquevalues = [...new Map(NewArr.map(item =>
                [item['skuName'], item])).values()];

            uniquevalues.map(x => {
                let totqty = 0;
                if (NewArr.filter(v => v.skuName === x.skuName && v.isSelected === 1).length !== 0) {
                    let sameskudetails = NewArr.filter(v => v.skuName === x.skuName);
                    sameskudetails.map(s => {
                        if (totqty === 0) {
                            totqty = s.balanceQuantity;
                        }
                        else {
                            totqty = totqty + s.balanceQuantity;
                        }
                    })
                }
                x.balanceQuantity = totqty;
                x.FinalInspectionIds = selectedInspIds;
                totqty = 0;
            })

            getInspectionInfo.multiselecteddatas = uniquevalues;
            getInspectionInfo.FinalInspectionIds = uniquevalues[0].reInspectionAgainst != '' && uniquevalues[0].reInspectionAgainst != null
                ? uniquevalues[0].reInspectionAgainst : uniquevalues[0].FinalInspectionIds;
        }
        else {
            getInspectionInfo.multiselecteddatas = getfinalinspectionMulti;
        }

        var maindetails = {
            supplierId: Searchvalues.Supplier
            , supplierName: NewArr[0].supplierName
            , tnaid: getAddInspectionListParam.TnaId
            , purchaseOrderID: NewArr[0].purchaseOrderID

        }
        getInspectionInfo.quantityinfo = getAddInspectionListParam
        getInspectionInfo.details = maindetails;
        getInspectionInfo.TnaID = getAddInspectionListParam.TnaId
        getInspectionInfo.type = type;//For InterNal Or External
        getInspectionInfo.PurchaseOrderSkuID = sizeandqtyfordisplay;//For Multiple POSKUID like(4123,4122) for Packing and PackingImage
        getInspectionInfo.Isproduction = getIsProductionStatussearch.value;
        getInspectionInfo.page = getFinalInspectionPageView;//For InspectionType(Reinspection Or New inspection)
        getInspectionInfo.pageType = 'AddInspection';//For HeaderName Add,view or edit
        getInspectionInfo.ParentTask = "Dashboard";
        getInspectionInfo.Mode = getFinalInspectionPageView === "ReInspection" ? 'ReInspection' : "InspectionAdd";//For Some CalculationWorks
        getInspectionInfo.IsAddorEditReinspection = 'Add';
        getInspectionInfo.buyerName = NewArr[0].buyerName;
        getInspectionInfo.seasonName = NewArr[0].seasonName;
        getInspectionInfo.brandName = NewArr[0].brandName;
        getInspectionInfo.buyerID = NewArr[0].buyerID;
        getInspectionInfo.brandID = NewArr[0].brandID;
        getInspectionInfo.seasonID = NewArr[0].seasonID;
        getInspectionInfo.styleID = NewArr[0].styleID;
        getInspectionInfo.styleName = NewArr[0].styleName;
        getInspectionInfo.supplierId = Searchvalues.Supplier
        //NewArr[0].supplierID;
        // props.location.state.params.styleID = NewArr[0].styleID;


        props.history.push(`/CreateFinalInspection`, { params: getInspectionInfo });
    }

    async function AddQCFactoryForm() {
       
        let isvalid = false;
        let Isvalid = false
        Isvalid = !!!getQCfinalinspectionMulti.find(x => x.isSelected === 1)
        if (Isvalid) {
            Nodify('Warning!', 'warning', 'Please check atleast one checkbox');
            return false;
        }
        await FinalinspectionService.GetTNAFinalinspecTestingReportList(4, Searchvalues.Buyer, Searchvalues.Brand).then((res) => {
            if (res.data[0] !== undefined) {
                if (res.data[0].offerQty) {
                    isvalid = true
                }
            } else {
                Nodify('Warning!', 'warning', 'Please enter tolerance in master for this buyer')
            }
        })
        if (isvalid && !Isvalid) {
            let sizeandqtyfordisplay = "";
            let NewArr = [];
            NewArr = getQCfinalinspectionMulti.filter(x => x.isSelected === 1)
            NewArr.map((Purchaseorder) => {
                if (sizeandqtyfordisplay === '') {
                    sizeandqtyfordisplay = Purchaseorder.purchaseOrderSkuID;
                } else {
                    sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.purchaseOrderSkuID
                }
            })
            if (getQAInspectionPageView === "ReInspection") {
                let selectedInspIds = '';
                NewArr.map(x => {
                    if (x.isSelected === 1) {
                        if (selectedInspIds === '') {
                            if (!selectedInspIds.includes(x.tnaqcInspectionID)) {
                                selectedInspIds = x.tnaqcInspectionID.toString();
                            }
                        }
                        else {
                            if (!selectedInspIds.includes(x.tnaqcInspectionID)) {
                                selectedInspIds = selectedInspIds + "," + x.tnaqcInspectionID.toString();
                            }
                        }
                    }
                })

                let uniquevalues = [...new Map(NewArr.map(item =>
                    [item['skuName'], item])).values()];

                uniquevalues.map(x => {
                    let totqty = 0;
                    x.FinalInspectionIds = selectedInspIds;
                })
                uniquevalues.map(x => {
                    let totqty = 0;
                    x.refInspecSKUID = x.skuID;
                })
                // let maindetails = {
                //     supplierId: Searchvalues.Supplier
                //     , supplierName: NewArr[0].supplierName
                //     , tnaid: getAddQCInspectionListParam.TnaId
                //     , purchaseOrderID: NewArr[0].purchaseOrderID
                //     , idPoNos: NewArr[0].idPoNo
                // }
                getQCInspectionInfo.multiselecteddatas = uniquevalues;
                getQCInspectionInfo.FinalInspectionIds = uniquevalues[0].reInspectionAgainst != '' && uniquevalues[0].reInspectionAgainst != null
                    ? uniquevalues[0].reInspectionAgainst : uniquevalues[0].FinalInspectionIds;
            }
            else {
                getQCInspectionInfo.multiselecteddatas = getQCfinalinspectionMulti;
            }
            getQCInspectionInfo.buyerName = NewArr[0].buyerName;
            getQCInspectionInfo.seasonName = NewArr[0].seasonName;
            getQCInspectionInfo.brandName = NewArr[0].brandName;
            getQCInspectionInfo.buyerID = NewArr[0].buyerID;
            getQCInspectionInfo.brandID = NewArr[0].brandID;
            getQCInspectionInfo.seasonID = NewArr[0].seasonID;
            getQCInspectionInfo.styleID = NewArr[0].styleID;
            getQCInspectionInfo.styleName = NewArr[0].styleName;
            getQCInspectionInfo.supplierId = Searchvalues.Supplier;
            getQCInspectionInfo.supplierName = NewArr[0].supplierName
            getQCInspectionInfo.quantityinfo = getAddQCInspectionListParam// For Binding Selected checkbox values in quantity inforamtion
            getQCInspectionInfo.details = { supplierId: Searchvalues.Supplier, supplierName: NewArr[0].supplierName, tnaid: getAddQCInspectionListParam.TnaId, purchaseOrderID: NewArr[0].purchaseOrderID, idPoNos: NewArr[0].idPoNo };
            getQCInspectionInfo.TnaID = getAddQCInspectionListParam.TnaId
            getQCInspectionInfo.PurchaseOrderSkuID = sizeandqtyfordisplay;//For Multiple POSKUID like(4123,4122) for Packing and PackingImage
            getQCInspectionInfo.Isproduction = getIsProductionStatussearch.value;
            getQCInspectionInfo.Action = 'Add';
            getQCInspectionInfo.ParentTask = "Dashboard";
            getQCInspectionInfo.Mode = getQAInspectionPageView;
            getQCInspectionInfo.IsAddorEditReinspection = getQAInspectionPageView === 'AddInspection' ? 'Add Inspection' : 'Add ReInspection';
            props.history.push(`/AddQCInspection`, { params: getQCInspectionInfo });
        }
    }
    const handleFinalInspecViewDetails = (type, TNAInspectionID, Action, inpectionpage, item) => {
       
        const TNAAddFinalInspectionList = reducerState.tna.tnaAddfinalInspection !== undefined ? reducerState.tna.tnaAddfinalInspection : [];
        let sizeandqtyfordisplay = "";
        let NewArr = [];
        NewArr = TNAAddFinalInspectionList.filter(x => x.refInspecSKUID === parseInt(item.skuID))
        NewArr.map((Purchaseorder) => {
            if (sizeandqtyfordisplay === '') {
                sizeandqtyfordisplay = Purchaseorder.purchaseOrderSkuID;
            } else {
                sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.purchaseOrderSkuID
            }
        })
        TNAAddFinalInspectionList.filter(x => x.refInspecSKUID === parseInt(item.skuID)).map((element) => {
            element.IscheckReportSelect = 1;
            element.isSelected = 1;
        })

        var maindetails = {
            supplierId: Searchvalues.Supplier
            , supplierName: NewArr[0].supplierName
            , tnaid: getAddInspectionListParam.TnaId
            , purchaseOrderID: NewArr[0].purchaseOrderID

        }
        getInspectionInfo.multiselecteddatas = TNAAddFinalInspectionList;
        getInspectionInfo.quantityinfo = getAddInspectionListParam
        getInspectionInfo.details = maindetails;
        getInspectionInfo.type = type;
        getInspectionInfo.PurchaseOrderSkuID = sizeandqtyfordisplay;
        getInspectionInfo.TNAInspectionID = TNAInspectionID;
        getInspectionInfo.Isproduction = getIsProductionStatussearch.value
        getInspectionInfo.page = getFinalInspectionPageView;
        getInspectionInfo.pageType = inpectionpage;
        getInspectionInfo.Action = Action;
        getInspectionInfo.ParentTask = "Dashboard";
        getInspectionInfo.Mode = item.reportType === '2' ? 'ReInspection' : "InspectionView";
        getInspectionInfo.IsAddorEditReinspection = item.reportType === '2' ? Action : '';
        getInspectionInfo.buyerName = NewArr[0].buyerName;
        getInspectionInfo.seasonName = NewArr[0].seasonName;
        getInspectionInfo.brandName = NewArr[0].brandName;
        getInspectionInfo.buyerID = NewArr[0].buyerID;
        getInspectionInfo.brandID = NewArr[0].brandID;
        getInspectionInfo.seasonID = NewArr[0].seasonID;
        getInspectionInfo.styleID = NewArr[0].styleID;
        getInspectionInfo.styleName = NewArr[0].styleName;
        getInspectionInfo.supplierID = NewArr[0].supplierID;
        getInspectionInfo.supplierName = NewArr[0].supplierName;

        // getInspectionInfo.styleID = props.location.state.params.styleId;
        // getInspectionInfo.buyerID = getTNABuyerID;
        // getInspectionInfo.brandID = getTNABrandID;
        // getInspectionInfo.supplierID = getTNASupplierID;
        // getInspectionInfo.brandID = getTNABrandID;

        props.history.push(`/CreateFinalInspection`, { params: getInspectionInfo });
    };
    function handleDeletefinalinspection(type, TNAInspectionID, Action) {
        let PackingParams = {
            Operation: 104,
            Id: TNAInspectionID,
        }
        CommonService.CheckMappedItem(PackingParams).then(res => {
            if (res.data.outputResult === "-2") {
                let Title = "Warning!";
                let message = "This finalinspection has already approved in inspection Entry Tab, So action not allowed to Delete.";
                let type = "warning";
                Nodify(Title, type, message);
            }
            else {
                setIDSingleTask({ showPopupDelete: true, Params: PackingParams });
            }
        })
    }
    const handleCloseFinalInspecView = (index, subIndex, childIndex) => {
        //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsOpenInspectionConfirmationType = 0;
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsFinalInsView = 0;
        setInputFields(values);
    };
    async function handleFinalInspecView(index, subIndex, childIndex, TaskName, TaskID, SubId, Status, TaskOwnerIdList, TaskHolderId, TNAExFactoryId,
        SelectedDependency, OperationType, StyleId, TNAId) {

        showLoader();
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsFinalInsView = 1;
        setInputFields(values);
        let Datea = '';
        Datea = new Date()

        let balanceqty = 0
        await FinalinspectionService.GetTNAExfacReportList(TNAExFactoryId).then((res) => {
            let uniquevalues = [...new Map(res.data.map(item =>
                [item['skuName'], item])).values()];

            // res.data.map((inspc) => {
            //     if (balanceqty === 0) {
            //         balanceqty = (parseInt(inspc.poQty) + parseInt(inspc.tolerance)) - parseInt(inspc.inspectedQty);
            //         inspc.balanceQuantity = balanceqty;
            //     }
            //     else {
            //         inspc.balanceQuantity = (parseInt(inspc.poQty) + parseInt(inspc.tolerance)) - parseInt(inspc.inspectedQty);
            //         balanceqty = inspc.balanceQuantity;
            //     }


            // })
            setnewfinalReportlist(res.data)
            setnewheaderdata(uniquevalues)
            hideLoader();
        })

        getInspectionInfo.TaskStatus = OperationType;
        setInspectionInfo(getInspectionInfo);

        await StyleService.GetStyleList(StyleId, getIsProductionStatussearch.value, 0, '01/01/1949', moment(Datea).format('MM/DD/YYYY')).then((response) => {
            if (response.data) {
                let params = {
                    Operation: values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StatusID !== 3 ? 1 : 2,
                    BuyerId: Searchvalues.Buyer, BrandId: Searchvalues.Brand, SeasonId: Searchvalues.Season,
                    SupplierId: Searchvalues.Supplier, TaskId: TaskID, StyleId: StyleId,
                    SkuId: 0, TaskOwnerIdList: TaskOwnerIdList, TaskHolderId: TaskHolderId,
                    tnaExFactoryId: TNAExFactoryId, status: Status, selectedDependency: SelectedDependency, NewID: SubId,
                    Isproduction: getIsProductionStatussearch.value, TnaId: TNAId, BuyerName: response.data.item1[0].buyerName
                };
                setAddInspectionListParam(params);
                dispatch(GetSubChildMultExFactoryFormSKUList(params));
            }
        })
    }
    const handleCloseExFactoryFormPopup = (index, subIndex, childIndex) => {
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsOpenInspectionConfirmationType = 0;
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsExFactoryForm = 0;
        setInputFields(values);
    };
    function handleQCInspectionFormOpen(index, subIndex, childIndex, TaskName, TaskID, SubId, Status, TaskOwnerIdList,
        TaskHolderId, TNAExFactoryId, SelectedDependency, OperationType, StyleId, TNAId) {
        let Operation = 1
        let Dependency = false;
        if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency === 0) {

            if (values[index].SelectedDependency) {
                let arrstatus = [];
                arrstatus = values[index].SelectedDependency.split(',');
                if (arrstatus.length > 0) {
                    arrstatus.forEach(element => {

                        let val = [];
                        val = element.split('-');
                        let valIndex = values[parseInt(val[0]) - 1].StatusID
                        if (valIndex !== 3) {
                            Dependency = true;
                        }
                    });
                }
                if (Dependency) {
                    Nodify('Warning!', 'warning', "Please close dependency task");
                }
                else {

                }
            }
        }

        if (!Dependency) {
            //  SubId(SKUID) set 0 for entire record showed
            let params = {
                Operation: values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StatusID !== 3 ? 1 : 2,
                BuyerId: Searchvalues.Buyer, BrandId: Searchvalues.Brand, SeasonId: Searchvalues.Season,
                SupplierId: Searchvalues.Supplier, TaskId: TaskID, StyleId: StyleId,
                SkuId: 0, TaskOwnerIdList: TaskOwnerIdList, TaskHolderId: TaskHolderId,
                tnaExFactoryId: TNAExFactoryId, status: Status, selectedDependency: SelectedDependency, NewID: SubId,
                Isproduction: getIsProductionStatussearch.value, TnaId: TNAId,
            };
            setQCAddInspectionListParam(params);
            setQAInspectionPageView('AddInspection');
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsQCinsectionFactory = 1;
            getQCInspectionInfo.TaskStatus = OperationType;
            getQCInspectionInfo.TaskType = TaskName;//is for Midline or pilotrun or set sizecheck
            setInputFields(values);
            setQCInspectionInfo(getQCInspectionInfo);
        }
    }
    function handleQAInspecView(index, subIndex, childIndex, TaskName, TaskID, SubId, Status, TaskOwnerIdList, TaskHolderId, TNAExFactoryId, SelectedDependency, OperationType, StyleID, TNAId) {
       
        showLoader();
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsQAInsView = 1;
        let Datea = '';
        Datea = new Date()
        setInputFields(values);
        QcInspection.GetTNAExfacQAReportList(TNAExFactoryId).then((res) => {
            let uniquevalues = [...new Map(res.data.map(item =>
                [item['skuName'], item])).values()];
            setnewQAReportlist(res.data)
            setQAheaderdata(uniquevalues);

            StyleService.GetStyleList(StyleID, getIsProductionStatussearch.value, 0, '01/01/1949', moment(Datea).format('MM/DD/YYYY')).then((response) => {
                if (response.data) {
                    setQCInspectionInfo({
                        buyerID: response.data.item1[0].buyerID
                        , buyerName: response.data.item1[0].buyerName
                        , brandID: response.data.item1[0].brandID
                        , brandName: response.data.item1[0].brandName
                        , seasonName: response.data.item1[0].seasonName
                        , seasonID: response.data.item1[0].seasonID
                        //, supplierID: response.data.item1[0].supplierID
                        //, supplierName: props.location.state.params.supplierName
                        , styleID: response.data.item1[0].styleID
                        , styleName: response.data.item1[0].styleName
                        , styleNo: response.data.item1[0].styleNo
                    })
                    getQCInspectionInfo.TaskStatus = OperationType;
                    getQCInspectionInfo.TaskType = TaskName;
                    setQCInspectionInfo(getQCInspectionInfo);
                }
            })
            hideLoader();
        })


        let params = {
            Operation: values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StatusID !== 3 ? 1 : 2,
            BuyerId: Searchvalues.Buyer, BrandId: Searchvalues.Brand, SeasonId: Searchvalues.Season,
            SupplierId: Searchvalues.Supplier, TaskId: TaskID, StyleId: StyleID,
            SkuId: 0, TaskOwnerIdList: TaskOwnerIdList, TaskHolderId: TaskHolderId,
            tnaExFactoryId: TNAExFactoryId, status: Status, selectedDependency: SelectedDependency, NewID: SubId,
            Isproduction: getIsProductionStatussearch.value, TnaId: TNAId
        };

        setQCAddInspectionListParam(params);
        dispatch(GetSubChildMultExFactoryFormSKUList(params));

    }
    function handleCloseQAInspecView(index, subIndex, childIndex) {
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsQAInsView = 0;
        setInputFields(values);
    }
    function handleDeleteQAinspection(item) {
        let PackingParams = {
            Operation: 110,
            Id: item.tnaqcInspectionID,
        }
        setIDQATask({ showPopupDelete: true, Params: PackingParams });
    }
    function handleQAInspecViewDetails(item, ActionType) {
       
        const TNAAddFinalInspectionList = reducerState.tna.tnaAddfinalInspection !== undefined ? reducerState.tna.tnaAddfinalInspection : [];
        let sizeandqtyfordisplay = "";
        let NewArr = [];
        NewArr = TNAAddFinalInspectionList.filter(x => x.skuID === parseInt(item.skuID))
        NewArr.map((Purchaseorder) => {
            if (sizeandqtyfordisplay === '') {
                sizeandqtyfordisplay = Purchaseorder.purchaseOrderSkuID;
            } else {
                sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.purchaseOrderSkuID
            }
        })
        getQCInspectionInfo.supplierID = Searchvalues.Supplier;
        getQCInspectionInfo.supplierName = item.name;
        getQCInspectionInfo.multiselecteddatas = TNAAddFinalInspectionList;
        getQCInspectionInfo.quantityinfo = getAddQCInspectionListParam// For Binding Selected checkbox values in quantity inforamtion
        getQCInspectionInfo.details = { supplierId: Searchvalues.Supplier, supplierName: item.name, tnaid: getAddQCInspectionListParam.TnaId, purchaseOrderID: item.purchaseOrderID, idPoNos: item.idpono };;
        getQCInspectionInfo.TNAQCInspectionID = item.tnaqcInspectionID;
        getQCInspectionInfo.TnaID = getAddQCInspectionListParam.TnaId
        getQCInspectionInfo.PurchaseOrderSkuID = sizeandqtyfordisplay;//For Multiple POSKUID like(4123,4122) for Packing and PackingImage
        getQCInspectionInfo.Isproduction = getIsProductionStatussearch.value;
        getQCInspectionInfo.Action = ActionType;
        getQCInspectionInfo.ParentTask = "Dashboard";
        getQCInspectionInfo.Mode = "AddInspection";
        getQCInspectionInfo.IsAddorEditReinspection = item.inspectionType === 1 ? ActionType + ' Inspection' : ActionType + ' ReInspection';
        props.history.push(`/AddQCInspection`, { params: getQCInspectionInfo });
    }
    const handleCloseInspectionConfirmationPopup = (index, subIndex, childIndex) => {
        setIsAddFinalInspection(false);
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsOpenInspectionConfirmationType = 0;

    };
    async function handleExFactoryFormOpen(index, subIndex, childIndex, TaskName, TaskID, SubId, Status, TaskOwnerIdList, TaskHolderId, TNAExFactoryId,
        SelectedDependency, OperationType, StyleId, TNAId) {
       
        let Operation = 1
        let Dependency = false;
        if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency === 0) {

            if (values[index].SelectedDependency) {
                let arrstatus = [];
                arrstatus = values[index].SelectedDependency.split(',');
                if (arrstatus.length > 0) {
                    arrstatus.forEach(element => {

                        let val = [];
                        val = element.split('-');
                        let valIndex = values[parseInt(val[0]) - 1].StatusID
                        if (valIndex !== 3) {
                            Dependency = true;
                        }
                    });
                }
                if (Dependency) {
                    Nodify('Warning!', 'warning', "Please close dependency task");
                }
                else {

                }
            }
        }

        if (!Dependency) {
           
            //  SubId(SKUID) set 0 for entire record showed
            let Datea = '';
            Datea = new Date()
            await StyleService.GetStyleList(StyleId, getIsProductionStatussearch.value, 0, '01/01/1949', moment(Datea).format('MM/DD/YYYY')).then((response) => {
                if (response.data) {
                    let params = {
                        Operation: values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StatusID !== 3 ? 1 : 2,
                        BuyerId: Searchvalues.Buyer, BrandId: Searchvalues.Brand, SeasonId: Searchvalues.Season,
                        SupplierId: Searchvalues.Supplier, TaskId: TaskID, StyleId: StyleId,
                        SkuId: 0, TaskOwnerIdList: TaskOwnerIdList, TaskHolderId: TaskHolderId,
                        tnaExFactoryId: TNAExFactoryId, status: Status, selectedDependency: SelectedDependency, NewID: SubId,
                        Isproduction: getIsProductionStatussearch.value, TnaId: TNAId, BuyerName: response.data.item1[0].buyerName
                    };
                    setAddInspectionListParam(params);
                }
            })
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsExFactoryForm = 1;
            setInputFields(values);
            //  }
            getInspectionInfo.TaskStatus = OperationType;
            setInspectionInfo(getInspectionInfo);
            //}).catch(() => { });
        }
    };
    function pageRedirectForQAInspection(value) {
        setQAInspectionPageView(value);
    }
    async function handleTestingTaskFormOpen(index, subIndex, childIndex, TaskName, TaskID, SubId, Status, TaskOwnerIdList, TaskHolderId, TNAExFactoryId, SelectedDependency, OperationType, StyleID, TNAId) {
       
        let Operation = 1
        let Dependency = false;
        if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency === 0) {

            if (values[index].SelectedDependency) {
                let arrstatus = [];
                arrstatus = values[index].SelectedDependency.split(',');
                if (arrstatus.length > 0) {
                    arrstatus.forEach(element => {

                        let val = [];
                        val = element.split('-');
                        let valIndex = values[parseInt(val[0]) - 1].StatusID
                        if (valIndex !== 3) {
                            Dependency = true;
                        }
                    });
                }
                if (Dependency) {
                    Nodify('Warning!', 'warning', "Please close dependency task");
                }
                else {

                }
            }
        }
        let Datea = '';
        Datea = new Date()
        await StyleService.GetStyleList(StyleID, getIsProductionStatussearch.value, 0, '01/01/1949', moment(Datea).format('MM/DD/YYYY')).then((response) => {
            if (response.data) {
                // setTestingTaskInfo({
                getTestingTaskInfo.buyerID = response.data.item1[0].buyerID;
                getTestingTaskInfo.buyerName = response.data.item1[0].buyerName;
                getTestingTaskInfo.brandID = response.data.item1[0].brandID;
                getTestingTaskInfo.brandName = response.data.item1[0].brandName;
                getTestingTaskInfo.seasonName = response.data.item1[0].seasonName
                getTestingTaskInfo.seasonID = response.data.item1[0].seasonID;
                //, supplierID: response.data.item1[0].supplierID
                //, supplierName: props.location.state.params.supplierName
                getTestingTaskInfo.styleID = response.data.item1[0].styleID;
                getTestingTaskInfo.styleName = response.data.item1[0].styleName;
                getTestingTaskInfo.styleNo = response.data.item1[0].styleNo;
                // })
                getTestingTaskInfo.TaskStatus = OperationType;
                getTestingTaskInfo.TaskType = TaskName;
                setTestingTaskInfo(getTestingTaskInfo);
            }
        })

        if (!Dependency) {
            //  SubId(SKUID) set 0 for entire record showed
            let params = {
                Operation: (TaskName === 'FABRIC TEST' || TaskName === 'YARN TEST' || TaskName === 'GARMENT TEST') ? 1 : TaskName === 'Strike off Test' ? 2 : 3,
                //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StatusID !== 3 ? 1 : 2,
                BuyerId: Searchvalues.Buyer, BrandId: Searchvalues.Brand, SeasonId: Searchvalues.Season,
                SupplierId: Searchvalues.Supplier, TaskId: TaskID, StyleId: StyleID,
                SkuId: 0, TaskOwnerIdList: TaskOwnerIdList, TaskHolderId: TaskHolderId,
                tnaExFactoryId: TNAExFactoryId, status: Status, selectedDependency: SelectedDependency, NewID: SubId,
                Isproduction: getIsProductionStatussearch.value, TnaId: TNAId,
            };
            setTestingTaskListParam(params);
            setTestingReportPageView('AddInspection')
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsTestingTaskFactory = 1;
            // getTestingTaskInfo.TaskStatus = OperationType;
            // getTestingTaskInfo.TaskType = TaskName;//is for Midline or pilotrun or set sizecheck
            setInputFields(values);
            // setTestingTaskInfo(getTestingTaskInfo);
        }
    }
    function handleCloseTestingView(index, subIndex, childIndex) {
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsTestingInsView = 0;
        setInputFields(values);
    }
    function handleTestInspecViewDetails(item, ActionType, tnaid) {
       
        const TNAAddFinalInspectionList = reducerState.tna.tnaAddTestinginspection !== undefined ? reducerState.tna.tnaAddTestinginspection : [];
        let sizeandqtyfordisplay = "";
        let NewArr = [];
        NewArr = TNAAddFinalInspectionList.filter(x => x.skuID === parseInt(item.skuID))
        NewArr.map((Purchaseorder) => {
            if (sizeandqtyfordisplay === '') {
                sizeandqtyfordisplay = Purchaseorder.refInspecSKUID.toString();
            } else {
                sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.refInspecSKUID
            }
        })
        getTestingTaskInfo.supplierID = item.compBuySupID;
        getTestingTaskInfo.supplierName = item.name;
        getTestingTaskInfo.multiselecteddatas = TNAAddFinalInspectionList;
        getTestingTaskInfo.quantityinfo = getTestingTaskListParam// For Binding Selected checkbox values in quantity inforamtion
        //  getTestingTaskInfo.details = { tnaid: tnaID, purchaseOrderID: 0 };
        // getTestingTaskInfo.TnaID = tnaID
        getTestingTaskInfo.type = item.typeInternorExtern;//For InterNal Or External
        getTestingTaskInfo.SkuID = sizeandqtyfordisplay;//For Multiple SKUID like(4123,4122) for Packing and PackingImage
        getTestingTaskInfo.Isproduction = getIsProductionStatussearch.value;
        getTestingTaskInfo.TestingInspectionID = item.tnaTestingInspectionID;
        getTestingTaskInfo.page = getTestingReportPageView;//For InspectionType(Reinspection Or New inspection)
        getTestingTaskInfo.pageType = ActionType;//For HeaderName Add,view or edit
        getTestingTaskInfo.ParentTask = "Dashboard";//Task Based back or save redirect function
        props.history.push(`/createTesting`, { params: getTestingTaskInfo });
    }
    async function handleTestinInspecView(index, subIndex, childIndex, TaskName, TaskID, SubId, Status, TaskOwnerIdList, TaskHolderId, TNAExFactoryId, SelectedDependency, OperationType, StyleID, TNAID, operation) {
        showLoader();
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsTestingInsView = 1;
        setInputFields(values);
        await TnaService.TestingreportList(TNAExFactoryId, operation, TNAID).then((res) => {
            let uniquevalues = [...new Map(res.data.map(item =>
                [item['skuName'], item])).values()];
            setnewTestingReportlist(res.data)
            setTestingheaderdata(uniquevalues);
            let Datea = '';
            Datea = new Date()
            StyleService.GetStyleList(StyleID, getIsProductionStatussearch.value, 0, '01/01/1949', moment(Datea).format('MM/DD/YYYY')).then((response) => {
                if (response.data) {
                    // setTestingTaskInfo({
                    getTestingTaskInfo.buyerID = response.data.item1[0].buyerID;
                    getTestingTaskInfo.buyerName = response.data.item1[0].buyerName;
                    getTestingTaskInfo.brandID = response.data.item1[0].brandID;
                    getTestingTaskInfo.brandName = response.data.item1[0].brandName;
                    getTestingTaskInfo.seasonName = response.data.item1[0].seasonName
                    getTestingTaskInfo.seasonID = response.data.item1[0].seasonID;
                    //, supplierID: response.data.item1[0].supplierID
                    //, supplierName: props.location.state.params.supplierName
                    getTestingTaskInfo.styleID = response.data.item1[0].styleID;
                    getTestingTaskInfo.styleName = response.data.item1[0].styleName;
                    getTestingTaskInfo.styleNo = response.data.item1[0].styleNo;
                    getTestingTaskInfo.details = { tnaid: TNAID, purchaseOrderID: 0 };
                    getTestingTaskInfo.TaskStatus = OperationType;
                    getTestingTaskInfo.TaskType = TaskName;
                    getTestingTaskInfo.TnaID = TNAID;
                    setTestingTaskInfo(getTestingTaskInfo);
                }
            })
            hideLoader();
        })
        // getTestingTaskInfo.TaskStatus = OperationType;
        // getTestingTaskInfo.TaskType = TaskName;
        // setTestingTaskInfo(getTestingTaskInfo);

        let params = {
            Operation: values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StatusID !== 3 ? 1 : 2,
            BuyerId: Searchvalues.Buyer, BrandId: Searchvalues.Brand, SeasonId: Searchvalues.Season,
            SupplierId: Searchvalues.Supplier, TaskId: TaskID, StyleId: StyleID,
            SkuId: 0, TaskOwnerIdList: TaskOwnerIdList, TaskHolderId: TaskHolderId,
            tnaExFactoryId: TNAExFactoryId, status: Status, selectedDependency: SelectedDependency, NewID: SubId,
            Isproduction: getIsProductionStatussearch.value, TnaId: TNAID
        };
        setQCAddInspectionListParam(params);
        dispatch(GetTestingMultExFactorySKUList(params));
    }

    function handleDeleteTestinspection(item) {

        let PackingParams = {
            Operation: 126,
            Id: item.tnaTestingInspectionID,
        }
        setIDTestTask({ showPopupDelete: true, Params: PackingParams });
    }
    const LoadManualCloseDataList = (index, subIndex, childIndex, TaskName, ChildId, Status) => {

        showLoader();
        let Operation;
        if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
            Operation = 1;
        }
        else if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
            || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
            Operation = 3;
        }
        else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
            || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER) {
            Operation = 2;
        }
        else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL) {
            Operation = 4;
        }
        else if (TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
            Operation = 5;
        }
        //|| TaskName.toLowerCase() === StaticTask.SMS_PURCHASE_ORDER
        else if (TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || TaskName.toLowerCase() === StaticTask.SMS_APPROVAL
            || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER
            || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE || TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW
            || TaskName.toLowerCase() === StaticTask.SIZE_SET_SUBMISSION || TaskName.toLowerCase() === StaticTask.SIZE_SET_APPROVAL
            || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_SUBMISSION || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL
            || TaskName.toLowerCase() === StaticTask.SHIPMENT_SUBMISSION || TaskName.toLowerCase() === StaticTask.SHIPMENT_APPROVAL
            || TaskName.toLowerCase() === StaticTask.PHOTO_SUBMISSION || TaskName.toLowerCase() === StaticTask.PHOTO_APPROVAL) {
            Operation = 6;
        }
        else if (TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH) {
            Operation = 7;
        }
        else if (TaskName.toLowerCase() === StaticTask.STRIKEOFF_TEST) {
            Operation = 9;
        }
        else if (TaskName.toLowerCase() === StaticTask.TRIMS_TEST) {
            Operation = 10;
        }
        else if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].DetailTypeID === 4) {
            Operation = 11;
        }
        else {
            Operation = 8;
        }

        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(stateDate[0].startDate));
            // StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(stateDate[0].endDate));
            // EndDate.setMinutes(EndDate.getMinutes() + 370);
        }

        let params = {
            BuyerId: Searchvalues.Buyer, BrandId: Searchvalues.Brand, SeasonId: Searchvalues.Season,
            SupplierId: Searchvalues.Supplier, TaskType: Searchvalues.TaskType, TaskNameId: Searchvalues.TaskName,
            TaskTodoInProgressCompletedStatus: Searchvalues.Status, StartDate: StartDate, EndDate: EndDate,
            Operation: Operation, ParentIdOrSubChildId: ChildId, IsProduction: getIsProductionStatussearch.value
        };

        TnaService.GetSubChildMultiTaskManualCloseList(params).then((response) => {
            if (response.data) {


                let data = response.data.map((item) => {
                    if (item.subTaskInfoId === ChildId) {
                        item.isSelected = 1;
                        return item;
                    }
                    return item;
                    //[item['strikeofforTrimLogoId'], item.subTaskInfoId === ChildId ? (item = item.isSelected = 1) : item]
                });

                // let data = [...new Map(response.data.map(item =>


                //     [item['strikeofforTrimLogoId'], item.subTaskInfoId === ChildId ? (item = item.isSelected = 1) : item]
                // )).values()];
                hideLoader();

                let isSelectAll = 0;
                if (data) {
                    isSelectAll = !!!data.find(d => d.isSelected === 0);
                    isSelectAll = isSelectAll ? 1 : 0;
                }

                setselectall(isSelectAll)

                // if (Operation === 2 || Operation === 3) {
                //     let maindata = [...new Map(data.map(item =>
                //         [item['strikeofforTrimLogoId'], item])).values()];
                //     setOrderInfoMulticloseMainDetails(maindata);
                //     if (Status !== 3) {
                //         setOrderInfoMulticloseMainDetails(maindata);
                //     }
                //     else {
                //         setOrderInfoMulticloseMainDetails(maindata.filter(x => x.subTaskInfoId === ChildId));
                //     }
                //     // setMultiCloseDatas(data);
                // }
                // else {

                if (Status !== 3) {
                    setMultiCloseDatas(data);
                }
                else {
                    setMultiCloseDatas(data.filter(x => x.subTaskInfoId === ChildId));
                }

                // }


                // handleExpandRowMultiSelect(response.data[0].strikeofforTrimLogoPath, response.data);
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
                setInputFields(values);

            }
        }).catch(() => { hideLoader(); });
    }

    const handleCloseManualPopup = (index, subIndex, childIndex, StatusID) => {

        if (StatusID !== 3) {
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseComment = "";
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseDate = '';
        }
        else {
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
        }
        setInputFields(values);
    }
    const showManualApproval = (index, subIndex, childIndex, childId, TaskName) => {
        let Operation;
        if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
            Operation = 1;
        }
        else if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
            || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
            Operation = 3;
        }
        else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
            || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER) {
            Operation = 2;
        }

        TNAService.getManualApproval(childId, Operation).then((response) => {
            if (response.data) {
                setmanualApprovalData(response.data);
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualApprovalType = 1;
                setInputFields(values);
            }
            else {
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualApprovalType = 1;
                setInputFields(values);
            }
        }).catch(() => { });
    }

    const hideManualApproval = (index, subIndex, childIndex) => {
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualApprovalType = 0;
        setInputFields(values);
    }

    const showExistingApproval = (index, subIndex, childIndex, childId, TaskName) => {
        let Operation;
        if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
            Operation = 1;
        }
        else if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
            || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
            Operation = 3;
        }
        else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
            || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER) {
            Operation = 2;
        }

        TNAService.getExistingApproval(childId, Operation).then((response) => {
            if (response.data) {
                setexistingApprovalData(response.data);
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ExistingApprovalType = 1;
                setInputFields(values);
            }
            else {
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ExistingApprovalType = 1;
                setInputFields(values);
            }
        }).catch(() => { });
    }

    const hideExistingApproval = (index, subIndex, childIndex) => {
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ExistingApprovalType = 0;
        setInputFields(values);
    }


    const handleChangeIsManualCloseComments = (event, index, subIndex, childIndex, feild) => {
        setComment(false);

        let input = '';

        if (feild === "Comment") {
            if (event.target.value.trim() !== '') {
                input = event.target.value;
            }
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseComment = input;
            // ManualCloseData.ManualCloseComment = event.target.value;
        }
        else {
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseDate = event;
            // ManualCloseData.ManualCloseDate = event;
        }
        setInputFields(values);
        //setMultiManualCloseFeilds(ManualCloseData);

    }

    const handleChangeTNAComments = (event, index) => {
        //  const values = { ...inputFields };

        let input = '';
        if (event.target.value.trim() !== '') {
            input = event.target.value;
        }
        if (event.target.name === 'Comments') {
            values[index].Comments = input;

        }
        else {
            values[index].ManualComments = input;
            values[index].ManualCloseDate = new Date();

        }
        setInputFields(values);

    }
    const handleCloseManual = (index) => {

        values[index].IsManual = 0;
        setInputFields(values);
    };
    const handleManualclose = (index, StatusID, subIndex, childIndex,) => {

        if (StatusID !== 3) {
            if (values[index].TaskID === 0) {
                Nodify('Warning!', 'warning', 'Please select the task name.');
            }
            else {
                if (values[index].intDependency === 0) {
                    let Dependency = false;
                    if (values[index].SelectedDependency) {
                        let arrstatus = [];
                        arrstatus = values[index].SelectedDependency.split(',');
                        if (arrstatus.length > 0) {
                            arrstatus.forEach(element => {
                                let val = [];
                                val = element.split('-');
                                let valIndex = values[parseInt(val[0]) - 1].StatusID
                                //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StatusID
                                if (valIndex !== 3) {
                                    Dependency = true;
                                }
                            });
                        }
                        if (Dependency) {
                            // setComment(true);
                            Nodify('Warning!', 'warning', "Please close dependency task");
                        }
                        else { values[index].IsManual = 1; }
                    } else {
                        values[index].IsManual = 1;
                    }
                    setInputFields(values);

                }
                else {
                    values[index].IsManual = 1;
                    setInputFields(values);

                }

            }

        } else {
            values[index].IsManual = 1;
            values[index].ManualComments = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseComment;
            setInputFields(values)

        }

    };
    const handleManual = (index, subIndex, childIndex, assignValue) => {

        if (assignValue === '' || assignValue === undefined) {
            setComment(true);
            Nodify('Warning!', 'warning', "Please enter comments");
        }
        else {

            values[index].IsManualClose = 0;
            values[index].StatusID = 3;
            values[index].ManualCloseComment = assignValue;
            values[index].ManualCloseDate = new Date();
            values[index].ActualEndDate = new Date();
            values[index].Manual = '';
            values[index].IsManual = 0;


            var ManualCloseData = { ...MultiManualCloseFeilds };
            ManualCloseData.ManualCloseDate = new Date();
            ManualCloseData.ManualCloseDate.setMinutes(ManualCloseData.ManualCloseDate.getMinutes() + 370);
            // .setDate(values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseDate.getDate() + 1);
            ManualCloseData.ManualCloseComment = assignValue;
            ManualCloseData.IdList = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildId.toString();
            ManualCloseData.TaskStatus = 6;
            ManualCloseData.CreatedBy = 1;

            TnaService.UpdateTaskManualCloseDetails(ManualCloseData).then(res => {
                if (res.data.outputResult === "2") {
                    Nodify('Success!', 'success', 'Task Manually Closed Successfully');
                }
            })

            setInputFields(values);

        }

    }
    const DeleteCallback = () => {

        setID({ showPopupDelete: false, Params: [] });
        // dispatch(loadTNADetailList({ TNAId: props.location.state.params.tnaid }));
        // dispatch(loadTNASupplierEmployeeList(getTNASupplierID));
        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].IsManualClose = 0;
        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StatusID = 1;
        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ManualCloseComment = null;
        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ManualCloseDate = null;
        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate = null;
        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StrActualEndDate = null;
        // values[index].SubTaskList[subIndex].ChildTaskList[index1].intDependency = element.dependencyStartstop;
        // values[index].SubTaskList[subIndex].ChildTaskList[index1].DependencyComment = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].MultiDependencyComment;
        // values[index].SubTaskList[subIndex].ChildTaskList[index1].DependencyDate = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].MultiDependencyDate;
        setInputFields(values);
    }
    const handledelete = (index, subIndex, childIndex, val) => {

        setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex });
        let Value = [];
        Value.TNAId = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].TNAId;
        if (val === 'Parent') {
            values[index].IsManual = 0;
            Value.Id = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildId;
            setID({ TNAManualClose: TNAManualCloseParent, showPopupDelete: true, Params: Value });
        }
        else {
            let ManualTaskName;
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
            //Value = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildId;
            Value.Id = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubId;

            // Hardcoded for Taskname ID  - Refer this select * from MasTaskDetails
            if (val === 81 || val === 82) { ManualTaskName = TNAManualCloseChildLibDip; }
            else if (val === 59 || val === 60 || val === 68 || val === 69 || val === 78 || val === 79 || val === 80) { ManualTaskName = TNAManualCloseChildTrim; }
            else if (val === 61 || val === 62 || val === 63 || val === 64 || val === 65) { ManualTaskName = TNAManualCloseChildStrikeOff; }
            else if (val === 56 || val === 77 || val === 66 || val === 67 || val === 70 || val === 73 || val === 74) { ManualTaskName = TNAManualCloseChildSms; }
            else if (val === 57 || val === 58 || val === 71 || val === 72) { ManualTaskName = TNAManualCloseChildProtoFit; }
            setID({ TNAManualClose: ManualTaskName, showPopupDelete: true, Params: Value });

        }


    }
    const SaveChildManulaCloseComments = (index, subIndex, childIndex, value, CloseDate, TaskName, ChildId) => {

        if ((value === '' || value === undefined) || CloseDate === '') {
            setComment(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
        }

        else {
            let ChildStatus = 6;
            if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
                ChildStatus = 1;
            }
            else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
                ChildStatus = 2;

            } else if (TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER ||
                TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT ||
                TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER ||
                TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {

                ChildStatus = 3;

            } else if (TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE ||
                TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || TaskName.toLowerCase() === StaticTask.SMS_APPROVAL || TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH
                || TaskName.toLowerCase() === StaticTask.SIZE_SET_SUBMISSION || TaskName.toLowerCase() === StaticTask.SIZE_SET_APPROVAL
                || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_SUBMISSION || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL
                || TaskName.toLowerCase() === StaticTask.SHIPMENT_SUBMISSION || TaskName.toLowerCase() === StaticTask.SHIPMENT_APPROVAL
                || TaskName.toLowerCase() === StaticTask.PHOTO_SUBMISSION || TaskName.toLowerCase() === StaticTask.PHOTO_APPROVAL) {
                ChildStatus = 4;
            }
            else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL ||
                TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
                ChildStatus = 5;
            }
            else if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].DetailTypeID === 4) {
                ChildStatus = 7;
            }
            else if (TaskName.toLowerCase() === StaticTask.STRIKEOFF_TEST) {
                ChildStatus = 8;
            }
            else if (TaskName.toLowerCase() === StaticTask.TRIMS_TEST) {
                ChildStatus = 9;
            }

            let strValue = "";
            let selected = 0;
            // if (TaskName.toLowerCase() !== StaticTask.LAB_DIP_APPROVAL &&
            //     TaskName.toLowerCase() !== StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT &&
            //     TaskName.toLowerCase() !== StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT &&
            //     TaskName.toLowerCase() !== StaticTask.FIT_SAMPLE_APPROVAL &&
            //     TaskName.toLowerCase() !== StaticTask.PROTO_SAMPLE_APPROVAL &&
            //     TaskName.toLowerCase() !== StaticTask.SMS_APPROVAL) {
            for (let data of MultiCloseDatas) {
                if (strValue === "") {
                    if (data.isSelected === 1) {

                        strValue = data.subTaskInfoId.toString();
                        selected = selected + 1;
                    }
                } else {
                    if (data.isSelected === 1) {
                        strValue = strValue + "," + data.subTaskInfoId.toString();
                    }
                }
            }
            // }
            // else {
            //     strValue = ChildId.toString();
            //     selected = 1;
            // }
            if (selected === 0) {
                Nodify('Warning!', 'warning', "Select atleast one Task");
            }
            else {
                var ManualCloseData = { ...MultiManualCloseFeilds };
                ManualCloseData.ManualCloseDate = new Date(values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseDate);
                ManualCloseData.ManualCloseDate.setMinutes(ManualCloseData.ManualCloseDate.getMinutes() + 370);
                // .setDate(values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseDate.getDate() + 1);
                ManualCloseData.ManualCloseComment = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseComment;
                ManualCloseData.IdList = strValue;
                ManualCloseData.TaskStatus = ChildStatus;
                ManualCloseData.CreatedBy = currentUser.employeeinformationID;;

                TnaService.UpdateTaskManualCloseDetails(ManualCloseData).then(res => {
                    if (res.data.outputResult === "2") {
                        Nodify('Success!', 'success', 'Task Manually Closed Successfully');
                        searchTNA();
                        setselectall(0);
                        // let CloseDate = convertUTCDateToLocalDate(new Date(values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseDate));
                        // MultiCloseDatas.filter(x => x.isSelected === 1).forEach(element => {
                        //     values[index].SubTaskList[subIndex].ChildTaskList.forEach((element1, index1) => {
                        //         if (element.subTaskInfoId === element1.ChildId) {
                        //             values[index].SubTaskList[subIndex].ChildTaskList[index1].IsManualClose = 0;
                        //             values[index].SubTaskList[subIndex].ChildTaskList[index1].StatusID = 3;
                        //             values[index].SubTaskList[subIndex].ChildTaskList[index1].ManualCloseComment = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseComment;
                        //             values[index].SubTaskList[subIndex].ChildTaskList[index1].ManualCloseDate = CloseDate;
                        //             values[index].SubTaskList[subIndex].ChildTaskList[index1].ChildActualEndDate = CloseDate;
                        //             values[index].SubTaskList[subIndex].ChildTaskList[index1].StrActualEndDate = moment(CloseDate).format("MM/DD/YYYY");
                        //         }
                        //     })
                        // })
                        setInputFields(values);
                    }
                    else if (res.data.outputResult === "0") {
                        Nodify("Error!", "Error Occured!");
                    }
                });
            }

        }

    }

    const handleExpandRowMultiSelect = (strikeofforTrimLogoPath, list) => {
        setExpandedRowsMultiSelect([]);
        setexpandListMultiSelect(list.filter(x => x.strikeofforTrimLogoPath === strikeofforTrimLogoPath));
        let currentExpandedRows = expandedRowsMultiSelect;
        const isRowExpanded = currentExpandedRows.includes(strikeofforTrimLogoPath);
        let obj = {};
        isRowExpanded ? (obj[strikeofforTrimLogoPath] = false) : (obj[strikeofforTrimLogoPath] = true);
        setExpandStateMultiSelect(obj);
        currentExpandedRows = [];
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== strikeofforTrimLogoPath) :
            currentExpandedRows.concat(strikeofforTrimLogoPath);
        setExpandedRowsMultiSelect(newExpandedRows);


    }

    // const handleSelect = (date) => {

    //     console.log(date); // native Date object
    // }

    const handleDateSelect = (event, isShow) => {
        let getStateDate = [...stateDate];
        getStateDate[0].isShow = isShow;
        setStateDate(getStateDate);
    }
    const handleChangeIsProductionStatus = (value) => {

        if (value === null) {
            value = { label: "Sampling", selected: true, value: 1 }
        }

        setIsProductionStatussearch(value);
    }
    const handleDateBlur = () => {
        //
        // alert("0");
        // const concernedElement = document.querySelector(".TNAReportDatePicker");
        // document.addEventListener("focusin", (event) => {
        //     if (concernedElement && concernedElement.contains(event.target)) {
        //         // let getStateDate = [...stateDate];
        //         // getStateDate[0].isShow = false;
        //         // setStateDate(getStateDate);
        //         alert("1");
        //         return;
        //     } else {
        //         alert("2");


        //     }
        // });

    }



    const clearDateSelect = () => {
        const emptyStateDate = [
            {
                startDate: null,
                endDate: new Date(""),
                key: 'selection',
                isShow: false
            }];
        setStateDate(emptyStateDate);
    }

    const handlechange = (e, field) => {
        let getStateDate = [...stateDate];
        getStateDate[0].isShow = false;
        setStateDate(getStateDate);

        if (e === null) {
            Searchvalues[field] = 0;

        }
        else {
            if (e.length === 0) {
                Searchvalues[field] = 0;
            }
            else {
                Searchvalues[field] = e.value;
            }
        }

        if (field === 'TaskType' || field === 'TaskName' || field === '1' || field === '2' || field === '3' || field === '4' || field === '5' || field === '6' || field === '7' || field === '8' || field === '9' || field === '10') {
            if (field === '1' || field === '2' || field === '3' || field === '4' || field === '5' || field === '6' || field === '7' || field === '8' || field === '9' || field === '10') { Searchvalues.Status = parseInt(field) }
            let StartDate = null;
            let EndDate = null;
            if (moment(stateDate[0].startDate).isValid()) {
                StartDate = convertUTCDateToLocalDate(new Date(stateDate[0].startDate));
                //  StartDate.setMinutes(StartDate.getMinutes() + 370);
            }
            if (moment(stateDate[0].endDate).isValid()) {
                EndDate = convertUTCDateToLocalDate(new Date(stateDate[0].endDate));
                // EndDate.setMinutes(EndDate.getMinutes() + 370);
            }
            let params = {
                BuyerId: Searchvalues.Buyer, BrandId: Searchvalues.Brand, SeasonId: Searchvalues.Season, SupplierId: Searchvalues.Supplier,
                TaskType: Searchvalues.TaskType, TaskNameId: Searchvalues.TaskName, TaskTodoInProgressCompletedStatus: Searchvalues.Status,
                StartDate: StartDate, EndDate: EndDate, Operation: 1, IsProduction: getIsProductionStatussearch.value
            };
            setSearchvaluesToSub(params);
            dispatch(loadTNAReportList(params));
            commonParentExpand();

        }
        else if (field === 'Buyer') {
            if (e !== null) LoadBuyerBased(e.value, setBrandList)
            Searchvalues.Brand = 0
        }


        setSearchvalues(Searchvalues);
    }



    const SelectAll = (event) => {
        let IsSelected = 0;

        if (event.target.checked === true) {
            IsSelected = 1;
            MultiCloseDatas.forEach(element => {
                element.isSelected = 1;
            })
        }
        else {
            IsSelected = 0;
            MultiCloseDatas.forEach(element => {
                element.isSelected = 0;
            })
        }
        setselectall(IsSelected);
    }

    const OnSelectRecords = (event, index) => {
        const value = [...MultiCloseDatas];

        if (value) {
            if (event.target.checked === true) {
                value[index].isSelected = 1;
            }
            else {
                value[index].isSelected = 0;
            }
            let isSelectAll = 0;
            if (value) {
                isSelectAll = !!!value.find(d => d.isSelected === 0);
                isSelectAll = isSelectAll ? 1 : 0;
            }
            setselectall(isSelectAll);

            setMultiCloseDatas(value);
        }

    }

    function convertUTCDateToLocalDate(date) {
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date;
    }

    const ViewFile = (event, filename) => { // event.preventDefault();
        setIsOpenimg(true);
        setImagename(filename);
    }

    return (

        <Fragment>
            {/* <ReactNotification /> */}
            <div> {
                isOpen && (
                    <Lightbox
                        mainSrc={isOpen}
                        alt="bg image"

                        onCloseRequest={
                            () => setIsOpen(false)
                        } />
                )
            } </div>
            <div className="row">
                <div className="col-xs-12 col-md-12">
                    <div className="widget">
                        <div className="widget-body">
                            <div id="registration-form">
                                <form>

                                    {<span style={{ marginLeft: "15px", color: "gray" }}><b>Note: Select below information, and get TNA Report!</b></span>}

                                    <div className="col-sm-12">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="Buyer">
                                                        Buyer
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <span className="input-icon icon-right">
                                                        <Reactselect className="basic-single" name="Buyer"
                                                            id={
                                                                "Buyer"
                                                            }
                                                            isLoading={false}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            onChange={event => handlechange(event, 'Buyer')}
                                                            value={getBuyerList.filter(function (option) {
                                                                return option.value === Searchvalues.Buyer;
                                                            })}
                                                            options={getBuyerList}
                                                            menuPortalTarget={document.body}
                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                                                        ></Reactselect>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="SupplierID">
                                                        Brand
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <span className="input-icon icon-right">
                                                        <Reactselect className="basic-single" name="Brand"
                                                            id={
                                                                "Brand"
                                                            }
                                                            classNamePrefix="brand"
                                                            isLoading={false}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            onChange={event => handlechange(event, 'Brand')}
                                                            value={getBrandList.filter(function (option) {
                                                                return option.value === Searchvalues.Brand;
                                                            })}
                                                            options={getBrandList}
                                                        ></Reactselect>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="Name">
                                                        Season
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <span className="input-icon icon-right">
                                                        <Reactselect className="basic-single" name="Season"
                                                            id={
                                                                "Season"
                                                            }
                                                            isLoading={false}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            onChange={event => handlechange(event, 'Season')}
                                                            value={getSeasonList.filter(function (option) {
                                                                return option.value === Searchvalues.Season;
                                                            })}
                                                            styles={{
                                                                // Fixes the overlapping problem of the component
                                                                menu: provided => ({ ...provided, zIndex: 9999 })
                                                            }}
                                                            options={getSeasonList}
                                                        ></Reactselect>

                                                    </span>
                                                </div>
                                            </div>


                                        </div>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor={"Supplier"}>Supplier
                                                        <span className="text-danger">*</span></label>
                                                    <span className="input-icon icon-right">
                                                        <Reactselect className="basic-single " name="Supplier"
                                                            id={
                                                                "Supplier"
                                                            }
                                                            isLoading={false}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            onChange={event => handlechange(event, 'Supplier')}
                                                            value={getSupplierList.filter(function (option) {
                                                                return option.value ===
                                                                    (getSupplierList.length === 1 ? getSupplierList[0].value :
                                                                        Searchvalues.Supplier);

                                                            })}
                                                            options={getSupplierList}
                                                            menuPortalTarget={document.body}
                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                                                        ></Reactselect>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">

                                                <div className="form-group">
                                                    <label >Date Range <span className="rptDateRangeSpan">(Clicking on the text box will cause the date picker to appear and disappear)</span> </label>
                                                    <span className="input-icon icon-right">
                                                        <input
                                                            value={(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') + ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' : moment(stateDate[0].endDate).format('DD/MM/YYYY'))}
                                                            onClick={event => handleDateSelect(event, !stateDate[0].isShow)}
                                                            //onBlur={event => handleDateBlur()}
                                                            type="text"
                                                            className={'form-control'}
                                                            placeholder="Select Date"
                                                        />
                                                        <i className="fa fa-times" onClick={clearDateSelect} ></i>
                                                    </span>
                                                </div>


                                                {stateDate[0].isShow &&
                                                    <DateRangePicker
                                                        onChange={item => setStateDate([item.selection])}
                                                        showSelectionPreview={true}
                                                        moveRangeOnFirstSelection={false}
                                                        months={2}
                                                        ranges={stateDate}
                                                        showDateDisplay={false}
                                                        direction="vertical"
                                                        className={'TNAReportDatePicker'}

                                                    />

                                                }



                                            </div>
                                            <div className="col-md-3">
                                                <label>Sampling / Production</label>
                                                <span className='input-icon icon-right'>
                                                    <Reactselect className="basic-single reportstatus" name="IsProductionStatusID"
                                                        options={IsProductionStatus}
                                                        isClearable={true}
                                                        onChange={e => handleChangeIsProductionStatus(e, 'IsProductionStatus')}
                                                        value={getIsProductionStatussearch}
                                                    ></Reactselect>
                                                </span>
                                            </div>
                                            <div className="col-sm-1">
                                                <label >&nbsp;</label>
                                                <span className="input-icon icon-right">
                                                    <button type="button" className="btn btn-success" title="Search" onClick={event => searchTNA()}
                                                        // style={{ width: "230px" }}
                                                        disabled={Searchvalues.Buyer === 0 || Searchvalues.Brand === 0 ||
                                                            Searchvalues.Season === 0 || Searchvalues.Supplier === 0 ? true : false}
                                                    >
                                                        <i className="fa fa-search"></i>
                                                        {/* &nbsp;Search */}
                                                    </button>
                                                    &nbsp;&nbsp;
                                                    <button type="button" className="btn btn-danger" title="Reset" onClick={resetTNA}
                                                    //style={{ width: "150px" }}
                                                    >
                                                        <i className="fa fa-close"></i>
                                                        {/* &nbsp;Reset */}
                                                    </button>
                                                </span>

                                            </div>

                                        </div>



                                        {inputFields.length !== 0 ?
                                            <div style={{ padding: "5px" }}>
                                                <div className="row">


                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label htmlFor="Buyer">
                                                                Task Type
                                                                {/* <span className="text-danger">*</span> */}
                                                            </label>
                                                            <span className="input-icon icon-right">
                                                                <Reactselect className="basic-single rptDrpTaskType" name="Task Type"
                                                                    id={
                                                                        "TaskType"
                                                                    }
                                                                    isLoading={false}
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    onChange={event => handlechange(event, 'TaskType')}
                                                                    value={Searchvalues.lstTaskType.filter(function (option) {
                                                                        return option.value === Searchvalues.TaskType;
                                                                    })}
                                                                    styles={{
                                                                        // Fixes the overlapping problem of the component
                                                                        menu: provided => ({ ...provided, zIndex: 9999 })
                                                                    }}
                                                                    options={Searchvalues.lstTaskType}
                                                                ></Reactselect>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label htmlFor="SupplierID">
                                                                Task Name
                                                                {/* <span className="text-danger">*</span> */}
                                                            </label>
                                                            <span className="input-icon icon-right">
                                                                <Reactselect className="basic-single" name="TaskName"
                                                                    id={
                                                                        "TaskName"
                                                                    }
                                                                    isLoading={false}
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    onChange={event => handlechange(event, 'TaskName')}
                                                                    value={Searchvalues.lstTaskName.filter(function (option) {
                                                                        return option.value === Searchvalues.TaskName;
                                                                    })}
                                                                    options={Searchvalues.lstTaskName}
                                                                ></Reactselect>
                                                            </span>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div className="row">
                                                    <br></br>
                                                    <div className="btn-toolbar rptBtnGroup" role="toolbar" aria-label="Toolbar with button groups ">
                                                        <div className="btn-group mr-2" role="group" aria-label="First group">

                                                            <button type="button" className="btn btn-xs btn-danger "
                                                                onClick={event => handlechange(event, '1')} disabled={Searchvalues.TodoDelay > 0 ? false : true} >
                                                                Delay
                                                                &nbsp;
                                                                <b> {Searchvalues.TodoDelay}</b>
                                                                {Searchvalues.Status === 1 &&
                                                                    // <img className="FilterIcon" src="assets/img/Gif/tick.gif" alt=""></img>
                                                                    <img className="FilterIcon" src="assets/img/Gif/tick.gif" alt=""></img>
                                                                }
                                                            </button>



                                                            <button type="button" className="btn btn-xs btn-success"
                                                                onClick={event => handlechange(event, '2')} disabled={Searchvalues.TodoUpcoming > 0 ? false : true} >
                                                                Upcoming
                                                                &nbsp;
                                                                <b> {Searchvalues.TodoUpcoming} </b>
                                                                {Searchvalues.Status === 2 &&
                                                                    <img className="FilterIcon" src="assets/img/Gif/tick.gif" alt=""></img>
                                                                }
                                                            </button>



                                                            <button type="button" className="btn btn-xs btn-primary"
                                                                onClick={event => handlechange(event, '3')} disabled={Searchvalues.TodoToday > 0 ? false : true}>
                                                                Today
                                                                &nbsp;
                                                                <b> {Searchvalues.TodoToday} </b>
                                                                {Searchvalues.Status === 3 &&
                                                                    <img className="FilterIcon" src="assets/img/Gif/tick.gif" alt=""></img>
                                                                }

                                                            </button>



                                                            <button type="button" className="btn btn-xs btn-warning "
                                                                onClick={event => handlechange(event, '4')} disabled={Searchvalues.TodoAll > 0 ? false : true} >
                                                                To Do & In-progress All
                                                                &nbsp;
                                                                <b> {Searchvalues.TodoAll} </b>
                                                                {Searchvalues.Status === 4 &&
                                                                    <img className="FilterIcon" src="assets/img/Gif/tick.gif" alt=""></img>
                                                                }


                                                            </button>



                                                            <button type="button" className="btn btn-xs btn-success "
                                                                onClick={event => handlechange(event, '5')} disabled={Searchvalues.CompletedEarly > 0 ? false : true}>
                                                                Completed Early
                                                                &nbsp;
                                                                <b> {Searchvalues.CompletedEarly} </b>
                                                                {Searchvalues.Status === 5 &&
                                                                    <img className="FilterIcon" src="assets/img/Gif/tick.gif" alt=""></img>
                                                                }

                                                            </button>



                                                            <button type="button" className="btn btn-xs btn-primary "
                                                                onClick={event => handlechange(event, '6')} disabled={Searchvalues.CompletedOntime > 0 ? false : true} >
                                                                Completed Ontime
                                                                &nbsp;
                                                                <b> {Searchvalues.CompletedOntime} </b>
                                                                {Searchvalues.Status === 6 &&
                                                                    <img className="FilterIcon" src="assets/img/Gif/tick.gif" alt=""></img>
                                                                }

                                                            </button>



                                                            <button type="button" className="btn btn-xs btn-danger "
                                                                onClick={event => handlechange(event, '7')} disabled={Searchvalues.CompletedDelay > 0 ? false : true} >
                                                                Completed Delay
                                                                &nbsp;
                                                                <b> {Searchvalues.CompletedDelay} </b>
                                                                {Searchvalues.Status === 7 &&
                                                                    <img className="FilterIcon" src="assets/img/Gif/tick.gif" alt=""></img>
                                                                }

                                                            </button>
                                                            <button type="button" className="btn btn-xs btn-info-ManualAndExistingCount"
                                                                onClick={event => handlechange(event, '10')}
                                                                disabled={Searchvalues.ApprovedDirectCount > 0 ? false : true}
                                                            >
                                                                Approved Direct
                                                                &nbsp;
                                                                <b> {Searchvalues.ApprovedDirectCount} </b>
                                                                {Searchvalues.Status === 10 &&
                                                                    <img className="FilterIcon" src="assets/img/Gif/tick.gif" alt=""></img>
                                                                }

                                                            </button>


                                                            <button type="button" className="btn btn-xs btn-warning "
                                                                onClick={event => handlechange(event, '8')} disabled={Searchvalues.CompletedAll > 0 ? false : true} >
                                                                Completed All
                                                                &nbsp;
                                                                <b> {Searchvalues.CompletedAll} </b>
                                                                {Searchvalues.Status === 8 &&
                                                                    <img className="FilterIcon" src="assets/img/Gif/tick.gif" alt=""></img>
                                                                }

                                                            </button>


                                                            <button type="button" className="btn btn-xs btn-success"
                                                                onClick={event => handlechange(event, '9')}
                                                            // disabled={Searchvalues.OverAllCount > 0 ? false : true}
                                                            >
                                                                Overall
                                                                &nbsp;
                                                                <b> {Searchvalues.OverAllCount} </b>
                                                                {Searchvalues.Status === 9 &&
                                                                    <img className="FilterIcon" src="assets/img/Gif/tick.gif" alt=""></img>
                                                                }

                                                            </button>

                                                        </div>



                                                    </div>
                                                </div>
                                            </div>
                                            : <div className="row">
                                                <div className="col-sm-5">
                                                </div>
                                                <div className="col-sm-3">
                                                    {/* <span className="danger">No records found</span> */}
                                                </div>
                                            </div>

                                        }

                                    </div>
                                    <br />
                                    {inputFields.length !== 0 && inputFields[0].TaskTypeName !== undefined ?
                                        <div className="col-md-12" style={{ overflowX: "auto", minHeight: '100px' }}>
                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable" style={{ width: "130%" }}>
                                                <thead>
                                                    <tr >
                                                        <th style={{ width: "125px" }} className="fixed-column-header-first-tna"> #</th>
                                                        {/* <th style={{ width: "250px" }}>TNA No</th> */}
                                                        <th style={{ width: "450px" }}>Task Type</th>
                                                        <th className="borderRightLeftNone" style={{ width: "1000px" }}>Task Name</th>
                                                        <th className="borderRightLeftNone" style={{ width: "400px" }}></th>
                                                        <th className="borderRightLeftNone" style={{ width: "300px" }}></th>
                                                        <th className="borderRightLeftNone" style={{ width: "300px" }}></th>
                                                        <th style={{ width: "200px" }}>Plan End</th>
                                                        <th style={{ width: "200px" }}>Actual End</th>
                                                        <th className="borderRightLeftNone" style={{ width: "320px" }}></th>
                                                        <th className="borderRightLeftNone" style={{ width: "320px" }}></th>
                                                        <th className="borderRightLeftNone" style={{ width: "280px" }}></th>
                                                        <th className="borderRightLeftNone" style={{ width: "360px" }}></th>
                                                        <th className="borderRightLeftNone" style={{ width: "620px" }}></th>
                                                        <th style={{ width: "300px" }} className="text-center fixed-column-header-Route">Action</th>

                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    {

                                                        inputFields.map((inputField, index) => (
                                                            <Fragment key={`${inputField}~${index}`}>

                                                                <tr class="parent" data-child={"parent_" + index}>
                                                                    <td className="fixed-column-first-tna">
                                                                        <b> {index + 1}&nbsp;</b>
                                                                        {
                                                                            <span id={"btn-toggle_" + index} class="btn-toggle parentExpand" aria-expanded="false" onClick={() => handleToggle(index)} aria-labelledby="btn-toggle id-master">
                                                                                <i id={"icon-toggle_" + index} class="fa fa-plus-square-o clsPointer clscollapseexpandTNA"></i>
                                                                            </span>
                                                                        }

                                                                    </td>

                                                                    <td>
                                                                        <span className='input-icon icon-right'>

                                                                            {
                                                                                inputField.TaskTypeName
                                                                            }
                                                                        </span>
                                                                    </td>
                                                                    <td colSpan="4">
                                                                        <span className='input-icon icon-right'>
                                                                            {
                                                                                inputField.strTaskName
                                                                            }
                                                                        </span>
                                                                    </td>
                                                                    <td >
                                                                        {
                                                                            (inputField.StrEndDate === null || inputField.StrEndDate === '') ? '' : moment(inputField.StrEndDate).format('DD/MM/YYYY')
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            (inputField.StrActualEndDate === null || inputField.StrActualEndDate === '') ? '' : moment(inputField.StrActualEndDate).format('DD/MM/YYYY')

                                                                        }
                                                                    </td>
                                                                    <td colSpan="5">
                                                                        {/* style={{ display: StartStopInfo.isAdd === 0 ? 'none' : '' }} */}
                                                                    </td>
                                                                    <td className="fixed-column-Route">
                                                                        {/* {StartStopInfo && StartStopInfo.isAdd !== 0 &&
                                                                            <button type="button" className={inputField.intDependency === 1 ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Depedency)"
                                                                                onClick={() => handleDependent(index, inputField.intDependency)}
                                                                            >


                                                                                <i className={inputField.intDependency === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>

                                                                            </button>
                                                                        } */}
                                                                        {/* {inputField.IsSingleTask &&
                                                                           <Fragment>&nbsp;<button type="button" className="btn btn-xs btn-warning" title="Submit Form"
                                                                            // onClick={() => handleAttachment(index)}
                                                                            >
                                                                                <i className="fa fa-file-text-o"></i>
                                                                            </button>
                                                                        </Fragment>
                                                                        } */}



                                                                        {/* <span title="TNA Closed" onClick={() => OpenProtoFitSamplepopup(1, 1)} class="btn btn-info btn-xs edit"><i class="fa fa-edit">ProtoFitSample Sub</i></span> */}
                                                                        {/* <span title="TNA Closed" onClick={() => OpenLabdipSubmssionpopup(1, 2, 48)} class="btn btn-info btn-xs edit"><i class="fa fa-edit"> LapDip Sub</i></span>
                                                                        <span title="TNA Closed" onClick={() => OpenStrikeoffSubmssionpopup(1, 1)} class="btn btn-info btn-xs edit"><i class="fa fa-edit"> Strikeoff Sub</i></span>
                                                                        <span title="TNA Closed" onClick={() => OpenStrikeoffApprovalpopup(1)} class="btn btn-info btn-xs edit"><i class="fa fa-edit"> Approval</i></span>
                                                                        <span title="TNA Closed" onClick={() => OpenTrimSubmssionpopup(1, 1)} class="btn btn-info btn-xs edit"><i class="fa fa-edit"> Trim Sub</i></span>
                                                                        <span title="TNA Closed" onClick={() => OpenProtoFitSamplepopup(1, 1)} class="btn btn-info btn-xs edit"><i class="fa fa-edit">ProtoFitSample Sub</i></span> */}
                                                                        {/* <span title="TNA Closed" onClick={() => OpenLabdipSubmssionpopup()} class="btn btn-info btn-xs edit"><i class="fa fa-edit"> LapDip Sub</i></span>
                                                                        &nbsp;
                                                                        <span title="TNA Closed" onClick={() => OpenLabdipApprovalpopup()} class="btn btn-info btn-xs edit"><i class="fa fa-edit"> Approval</i></span>

                                                                        &nbsp;
                                                                        <span title="TNA Closed" onClick={() => OpenStrikeoffApprovalpopup()} class="btn btn-info btn-xs edit"><i class="fa fa-edit"> Approval</i></span>
                                                                        <span title="TNA Closed" onClick={() => OpenTrimSubmssionpopup()} class="btn btn-info btn-xs edit"><i class="fa fa-edit"> Trim Sub</i></span>
                                                                        &nbsp;
                                                                        <span title="TNA Closed" onClick={() => OpenTrimApprovalpopup()} class="btn btn-info btn-xs edit"><i class="fa fa-edit"> Approval</i></span>
                                                                        <span title="TNA Closed" onClick={() => setSampleSubModalpopup(true)} class="btn btn-info btn-xs edit"><i class="fa fa-edit"> Sample Sub</i></span>
                                                                        &nbsp;
                                                                        <span title="TNA Closed" onClick={() => setSampleAppModalpopup(true)} class="btn btn-info btn-xs edit"><i class="fa fa-edit"> Approval</i></span>
                                                                        <span title="TNA Closed" onClick={() => setApperancemodalpopup(true)} class="btn btn-info btn-xs edit"><i class="fa fa-edit"> Wash Sub</i></span> */}
                                                                    </td>
                                                                </tr>
                                                                {

                                                                    inputField.SubTaskList && inputField.SubTaskList.map((subTask, subIndex) => (

                                                                        <Fragment key={
                                                                            `${subTask}~${subIndex}`
                                                                        }>
                                                                            {/* Skip second parent row */}
                                                                            {(inputField.IsSingleTask === false && inputField.IsMultiTaskWithOutChild === false) &&
                                                                                <tr class={"parentChild parent_" + index + " "} data-child={"parentChild_" + index + "_" + subIndex} >
                                                                                    <td className="fixed-column-first-tna">
                                                                                        <span id={"btn-toggle_parentChild_" + index + "_" + subIndex} class="btn-toggle " aria-expanded={subTask.IsExpanded} onClick={() => handleChildToggle(index, subIndex, subTask.MainId)} >
                                                                                            <i id={"icon-toggle_parentChild_" + index + "_" + subIndex} class={subTask.IsExpanded ? "fa fa-minus-square-o clsPointer clscollapseexpand-child" : "fa fa-plus-square-o clsPointer clscollapseexpand-child"}></i>
                                                                                        </span>
                                                                                    </td>
                                                                                    <td className="borderRightLeftNone" colSpan={2}>
                                                                                        {
                                                                                            subTask.MainId > 0 ? <span> {subTask.Code} </span> : <span> {inputField.strTaskName} </span>
                                                                                        }

                                                                                    </td>
                                                                                    <td className="borderRightLeftNone" colSpan="10">
                                                                                        {!inputField.IsSingleTask && subTask.MainId > 0 &&
                                                                                            (subTask.ColorOrName === "" || subTask.ColorOrName === null) ?
                                                                                            <div className="trwidth_manual" style={{ textAlign: "center" }}>
                                                                                                <img className="width_manual"
                                                                                                    src={subTask.ImagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + inputField.ImageUrl + subTask.ImagePath}
                                                                                                    alt="Logo"
                                                                                                    onClick={
                                                                                                        () => setIsOpen(subTask.ImagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + inputField.ImageUrl + subTask.ImagePath)
                                                                                                    }
                                                                                                />
                                                                                            </div> :
                                                                                            <div style={{ background: subTask.ColorOrName, width: '120px', height: '25px', marginLeft: '20px', borderRadius: '2px', display: 'inline-block' }}> </div>
                                                                                        }



                                                                                    </td>

                                                                                    <td className="fixed-column-Route"></td>

                                                                                </tr>

                                                                            }



                                                                            {

                                                                                subTask.ChildTaskList && subTask.ChildTaskList.map((childTask, childIndex) => (

                                                                                    <Fragment key={
                                                                                        `${childTask}~${childIndex}`
                                                                                    }>
                                                                                        {childIndex === 0 &&
                                                                                            <tr class={subTask.IsExpanded ? "child_" + index + " parentChild_" + index + "_" + subIndex + " TNAReportChildHeader " : "child_" + index + " parentChild_" + index + "_" + subIndex + " TNAReportChildHeader "}>
                                                                                                <th className="fixed-column-first-tna TNAReportChildHeader">#</th>
                                                                                                <th colSpan="2">Details</th>
                                                                                                <th>Style Name</th>
                                                                                                <th>Style No</th>
                                                                                                <th>PO/ID No</th>
                                                                                                <th>Plan End</th>
                                                                                                <th>Actual End</th>
                                                                                                <th>Dependency</th>
                                                                                                <th>Task Holder / Follower</th>
                                                                                                <th>Leave Days</th>
                                                                                                <th>Status</th>
                                                                                                <th>AWB & Status</th>
                                                                                                <th className="fixed-column-Route" style={{ background: "#222d32" }}>Action</th>
                                                                                            </tr>
                                                                                        }


                                                                                        <tr class={subTask.IsExpanded ? "child_" + index + " parentChild_" + index + "_" + subIndex + " " : "child_" + index + " parentChild_" + index + "_" + subIndex + " "} >

                                                                                            <td className="fixed-column-first-tna">{childIndex + 1}</td>

                                                                                            <td colSpan="2">
                                                                                                {
                                                                                                    inputField.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || inputField.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                                                                                                        inputField.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || inputField.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                                                                                                        inputField.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT || inputField.strTaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER ||
                                                                                                        inputField.strTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || inputField.strTaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT ||
                                                                                                        inputField.strTaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || inputField.strTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER ||
                                                                                                        inputField.strTaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER || inputField.strTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                                                                                                        inputField.strTaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || inputField.strTaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL
                                                                                                        || inputField.strTaskName.toLowerCase() === StaticTask.YARN_TEST
                                                                                                        || inputField.strTaskName.toLowerCase() === StaticTask.GARMENT_TEST
                                                                                                        || inputField.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_TEST
                                                                                                        || inputField.strTaskName.toLowerCase() === StaticTask.TRIMS_TEST
                                                                                                        || inputField.strTaskName.toLowerCase() === StaticTask.FABRIC_TEST
                                                                                                        || inputField.strTaskName.toLowerCase() === StaticTask.YARN_ORDER
                                                                                                        || inputField.strTaskName.toLowerCase() === StaticTask.PREPRODUCTION_MEETING
                                                                                                        ?
                                                                                                        <>
                                                                                                            {
                                                                                                                (inputField.strTaskName.toLowerCase() !== StaticTask.YARN_TEST
                                                                                                                    && inputField.strTaskName.toLowerCase() !== StaticTask.GARMENT_TEST
                                                                                                                    && inputField.strTaskName.toLowerCase() !== StaticTask.STRIKE_OFF_TEST
                                                                                                                    && inputField.strTaskName.toLowerCase() !== StaticTask.TRIMS_TEST
                                                                                                                    && inputField.strTaskName.toLowerCase() !== StaticTask.FABRIC_TEST
                                                                                                                    && inputField.strTaskName.toLowerCase() !== StaticTask.YARN_ORDER
                                                                                                                    && inputField.strTaskName.toLowerCase() !== StaticTask.PREPRODUCTION_MEETING
                                                                                                                ) &&
                                                                                                                <i class="fa fa-eye" title="SKU & Other Info"
                                                                                                                    onClick={() => handleSkuAndStyleDetails(childTask.SubId, childTask.MainId, childTask.StrikeOffOrTrimInfoId, inputField.strTaskName, childTask.SKUIdList, childTask.StyleStrikeOffGarmentSizeIdList, childTask.TNAId, childTask.StyleId)}>
                                                                                                                </i>}
                                                                                                            <span>{" " + childTask.Name}</span>
                                                                                                        </>

                                                                                                        : inputField.IsSingleTask ? inputField.strTaskName : childTask.Name
                                                                                                }
                                                                                                {/* {
                                                                                                inputField.IsSingleTask ? inputField.strTaskName : childTask.Name} */}
                                                                                            </td>

                                                                                            <td>
                                                                                                {childTask.StyleName}
                                                                                            </td>
                                                                                            <td>
                                                                                                {childTask.StyleNo}
                                                                                            </td>
                                                                                            <td title={childTask.IDPoNos} className="TNAReport_IDPoNos_textoverflow">
                                                                                                {childTask.IDPoNos}
                                                                                            </td>
                                                                                            <td>

                                                                                                <span className="input-icon icon-right">
                                                                                                    {

                                                                                                        (childTask.StrEndDate === null || childTask.StrEndDate === '') ? '' : moment(childTask.StrEndDate).format('DD/MM/YYYY')



                                                                                                    }

                                                                                                </span>
                                                                                            </td>

                                                                                            <td>

                                                                                                {
                                                                                                    childTask.StrActualEndDate === "Manual" ?
                                                                                                        <p><a style={{ cursor: "pointer" }} onClick={event => showManualApproval(index, subIndex, childIndex,
                                                                                                            childTask.SubId, childTask.TaskName)}>
                                                                                                            {/* {(childTask.StrActualEndDate === null || childTask.StrActualEndDate === '') ? '' : moment(childTask.StrActualEndDate).format('DD/MM/YYYY')} */}
                                                                                                            {childTask.StrActualEndDate === "Manual" ? childTask.StrActualEndDate : moment(childTask.StrActualEndDate).format('DD/MM/YYYY')}
                                                                                                        </a></p> :
                                                                                                        childTask.StrActualEndDate === "Existing" ?
                                                                                                            <p><a style={{ cursor: "pointer" }} onClick={event => showExistingApproval(index, subIndex, childIndex,
                                                                                                                childTask.SubId, childTask.TaskName)}>
                                                                                                                {/* {(childTask.StrActualEndDate === null || childTask.StrActualEndDate === '') ? '' : moment(childTask.StrActualEndDate).format('DD/MM/YYYY')} */}
                                                                                                                {childTask.StrActualEndDate === "Existing" ? childTask.StrActualEndDate : moment(childTask.StrActualEndDate).format('DD/MM/YYYY')}
                                                                                                            </a></p> :
                                                                                                            (childTask.StrActualEndDate === null || childTask.StrActualEndDate === '') ? '' : moment(childTask.StrActualEndDate).format('DD/MM/YYYY')

                                                                                                }
                                                                                                {
                                                                                                    childTask.ManualApprovalType === 1 && (
                                                                                                        <>
                                                                                                            <Modal show={childTask.ManualApprovalType} size="lg" dragable backdrop="static" keyboard={false} onHide={() => hideManualApproval(index, subIndex, childIndex)}>
                                                                                                                <Modal.Header closeButton>
                                                                                                                    <Modal.Title>
                                                                                                                        Manual Approval Information
                                                                                                                    </Modal.Title>
                                                                                                                </Modal.Header>
                                                                                                                <Modal.Body>

                                                                                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">

                                                                                                                        <thead>
                                                                                                                            <tr>
                                                                                                                                <th style={{ width: "300px" }}>Comments<span className="text-danger">*</span></th>
                                                                                                                            </tr>
                                                                                                                        </thead>
                                                                                                                        <tbody>

                                                                                                                            <tr>
                                                                                                                                <td >
                                                                                                                                    <textarea
                                                                                                                                        rows="3"
                                                                                                                                        placeholder="Enter Comments here..."
                                                                                                                                        autoFocus onFocus={""} autoComplete="off"
                                                                                                                                        maxLength="500" name="EnterRemarks"
                                                                                                                                        id={"EnterRemarks"}
                                                                                                                                        value={manualApprovalData.manualCloseComment}
                                                                                                                                        style={{ width: "100%" }}
                                                                                                                                    >
                                                                                                                                    </textarea>
                                                                                                                                </td>

                                                                                                                            </tr>

                                                                                                                            <tr>
                                                                                                                                <td colSpan={2}>
                                                                                                                                    <table className="table table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                                                                        <tbody>  <tr>

                                                                                                                                            <div className="col-lg-12 col-sm-12">
                                                                                                                                                <div className="row no-margin">
                                                                                                                                                    {
                                                                                                                                                        manualApprovalData.files && manualApprovalData.files.length !== 0 ?
                                                                                                                                                            manualApprovalData.files.map((files, fileindex) => (
                                                                                                                                                                <> {
                                                                                                                                                                    <div className="col-lg-4 col-sm-4 file_list_upload">
                                                                                                                                                                        <tr title={files.fileName}>
                                                                                                                                                                            <td className="cut_textInTNA">
                                                                                                                                                                                <span style={{ marginRight: '7px' }}>
                                                                                                                                                                                    {
                                                                                                                                                                                        files.fileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                                                                                                            files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                                                                                                                                <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                                                                                                                files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                                                                                                                    files.fileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                                                                                                                    <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                                                                                                                    }
                                                                                                                                                                                </span>  {files.fileName}
                                                                                                                                                                            </td>

                                                                                                                                                                            <td className="button_style_uploda">

                                                                                                                                                                                &nbsp;
                                                                                                                                                                                {
                                                                                                                                                                                    files.FileType === "application/pdf" || files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files.fileType === 'application/msword' ?
                                                                                                                                                                                        <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + 'Images/TNAImages/' + files.fileName} download><i class="fa fa-download"></i> </a> :
                                                                                                                                                                                        <i className="btn btn-info fa fa-eye" title="View Logo"
                                                                                                                                                                                            onClick={
                                                                                                                                                                                                event => ViewFile(event, files.fileName)
                                                                                                                                                                                            }></i>
                                                                                                                                                                                }
                                                                                                                                                                            </td>
                                                                                                                                                                        </tr>



                                                                                                                                                                        {

                                                                                                                                                                            <div> {
                                                                                                                                                                                isOpenimg && (

                                                                                                                                                                                    <Lightbox mainSrc={window.$APIBaseURL + 'Images/TNAImages/' + getImagename}
                                                                                                                                                                                        alt="bg image"
                                                                                                                                                                                        onCloseRequest={
                                                                                                                                                                                            () => setIsOpenimg(false)
                                                                                                                                                                                        } />
                                                                                                                                                                                )
                                                                                                                                                                            } </div>
                                                                                                                                                                        }
                                                                                                                                                                    </div>
                                                                                                                                                                }
                                                                                                                                                                </>
                                                                                                                                                            ))
                                                                                                                                                            :

                                                                                                                                                            <div style={{ textAlign: 'center', color: '#df5138' }}>
                                                                                                                                                                <span>No Attachments Found</span>
                                                                                                                                                            </div>

                                                                                                                                                    }
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </tr>
                                                                                                                                        </tbody>


                                                                                                                                    </table>
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </Modal.Body>

                                                                                                            </Modal>
                                                                                                        </>
                                                                                                    )
                                                                                                }

                                                                                                {
                                                                                                    childTask.ExistingApprovalType === 1 ?
                                                                                                        <Modal show={childTask.ExistingApprovalType === 1} size="lg" dragable backdrop="static" keyboard={false} onHide={() => hideExistingApproval(index, subIndex, childIndex)}>
                                                                                                            <Modal.Header closeButton>
                                                                                                                <Modal.Title>
                                                                                                                    Existing Approval Information
                                                                                                                </Modal.Title>
                                                                                                            </Modal.Header>
                                                                                                            <Modal.Body>

                                                                                                                <table className="table table-bordered">
                                                                                                                    <thead>
                                                                                                                        <tr>
                                                                                                                            <th>Season Name</th>
                                                                                                                            <th>Approval Date</th>
                                                                                                                            <th>Details</th>
                                                                                                                            <th>Approved By</th>


                                                                                                                        </tr>
                                                                                                                    </thead>
                                                                                                                    <tbody>
                                                                                                                        {
                                                                                                                            existingApprovalData && existingApprovalData.length !== 0 ?
                                                                                                                                <>

                                                                                                                                    <tr>
                                                                                                                                        <td>{existingApprovalData[0].seasonName}</td>
                                                                                                                                        <td>{existingApprovalData[0].approvalDate}</td>
                                                                                                                                        <td>{existingApprovalData[0].details}</td>
                                                                                                                                        <td>{existingApprovalData[0].approvedBy}</td>

                                                                                                                                    </tr>
                                                                                                                                </> :
                                                                                                                                <tr>
                                                                                                                                    <td colSpan={4} className='norecordfound'>
                                                                                                                                        <div align="center"> <span>No Records Found</span></div>
                                                                                                                                    </td>
                                                                                                                                </tr>


                                                                                                                        }

                                                                                                                    </tbody>
                                                                                                                </table>
                                                                                                            </Modal.Body>

                                                                                                        </Modal> : ''
                                                                                                }

                                                                                            </td>

                                                                                            <td title={childTask.Dependency} className="TNAReport_Dependency_textoverflow">
                                                                                                {/* {index === 0 ? '' : <input type="text"
                                                                                                    value={inputField.SelectedDependency}
                                                                                                    title={inputField.SelectedDependency}
                                                                                                    //onClick={() => handleDependency(index,subIndex, childIndex, false)}
                                                                                                    className={'form-control'}
                                                                                                    readOnly={true}
                                                                                                    style={{ width: "100%", float: "left" }}
                                                                                                />
                                                                                                } */}
                                                                                                {
                                                                                                    childTask.Dependency
                                                                                                }
                                                                                            </td>
                                                                                            <td title={childTask.TaskOwnerName} className="TNAReport_TaskOwnerName_textoverflow">

                                                                                                {/* {childTask.TaskHolderID} */}
                                                                                                <i class="fa fa-eye" title="Task Holder" onClick={() => handleTaskHolderLeaveDays(index, subIndex, childIndex, inputField.IsTHFirstTime)}></i> &nbsp; &nbsp; &nbsp;
                                                                                                {
                                                                                                    getTaskHolderList.filter(d => d.value === childTask.TaskHolderID).map(task => {
                                                                                                        return task.label;
                                                                                                    })
                                                                                                }
                                                                                            </td>

                                                                                            <td title={childTask.LeaveDaysName} className="TNAReport_LeaveDays_textoverflow">
                                                                                                {/* <input type="text"
                                                                                                    value={inputField.LeaveDaysTxt}
                                                                                                    title={inputField.LeaveDaysTxt}
                                                                                                    //onClick={() => handleTaskHolderLeaveDaysChild(index, subIndex, childIndex, childTask.IsTHFirstTime)}
                                                                                                    className={'form-control'}
                                                                                                    readOnly={true}
                                                                                                    style={{ width: "100%", float: "left" }}
                                                                                                /> */}
                                                                                                {
                                                                                                    childTask.LeaveDaysName
                                                                                                }

                                                                                            </td>
                                                                                            <td>
                                                                                                <span className='input-icon icon-right'>
                                                                                                    <Reactselect className="basic-single" name="StatusID"
                                                                                                        // id={
                                                                                                        //     "Status" + index
                                                                                                        // }
                                                                                                        isLoading={false}
                                                                                                        isClearable={true}
                                                                                                        isSearchable={true}
                                                                                                        // onChange={event => handleChangeChild(event, index, subIndex, childIndex, "StatusID")}
                                                                                                        value={getTNAStatusList.filter(function (option) {
                                                                                                            return option.value === childTask.StatusID;
                                                                                                        })}
                                                                                                        options={getTNAStatusList}
                                                                                                        isDisabled={true}
                                                                                                        menuPosition="fixed"
                                                                                                    ></Reactselect>
                                                                                                </span>
                                                                                            </td>
                                                                                            <td>
                                                                                                <span className='input-icon icon-right'>
                                                                                                    <input type="text"
                                                                                                        placeholder="AWB & Status"
                                                                                                        // id={"AWBStatus" + index}
                                                                                                        // name="AWBStatus"
                                                                                                        value={childTask.AWBStatus}
                                                                                                        //onChange={event => handleChangeChild(event, index, subIndex, childIndex, "AWBStatus")}
                                                                                                        className={'form-control' + (
                                                                                                            submitted && !childTask.AWBStatus ? ' is-invalid' : ''
                                                                                                        )}
                                                                                                        readOnly={true}
                                                                                                    />
                                                                                                </span>
                                                                                            </td>
                                                                                            <td className="fixed-column-Route" style={{ display: TaskCloseCompleteInfo && TaskCloseCompleteInfo.isAdd === 0 ? 'none' : '' }}>
                                                                                                {
                                                                                                    isLastEdit === true ?
                                                                                                        <>
                                                                                                            {childTask.IsTHLeaveDaysOpen === 1 &&
                                                                                                                <Modal show={childTask.IsTHLeaveDaysOpen === 1} size="lg" backdrop="static" keyboard={false} onHide={() => handleTHLeaveDaysClose(index, subIndex, childIndex)}>
                                                                                                                    <Modal.Header closeButton>
                                                                                                                        <Modal.Title>
                                                                                                                            Leave Days / In-charges - {inputField.strTaskName}
                                                                                                                        </Modal.Title>
                                                                                                                    </Modal.Header>
                                                                                                                    <Modal.Body>
                                                                                                                        <div className="row">
                                                                                                                            <div className="col-md-12">
                                                                                                                                <div className='form-group'>
                                                                                                                                    <label> Task Holder / Follower  </label>
                                                                                                                                    <span className='input-icon icon-right'>
                                                                                                                                        <Reactselect className="basic-single" name="TaskHolderOrFollower"
                                                                                                                                            id={"TaskHolderOrFollower"}
                                                                                                                                            isLoading={false}
                                                                                                                                            isClearable={false}
                                                                                                                                            isSearchable={true}
                                                                                                                                            value={getTaskHolderList.filter(function (option) {
                                                                                                                                                return option.value === childTask.TaskHolderID;
                                                                                                                                            })}
                                                                                                                                            menuPosition="fixed"
                                                                                                                                        //filterOption={filterOption}
                                                                                                                                        //isDisabled={true}
                                                                                                                                        ></Reactselect>

                                                                                                                                    </span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="row">
                                                                                                                            <div className="col-md-12">
                                                                                                                                <div className='form-group'>
                                                                                                                                    <label>Leave Days</label>
                                                                                                                                    <span className='input-icon icon-right'>
                                                                                                                                        <Reactselect className="basic-single" name="LeaveDays"
                                                                                                                                            id={
                                                                                                                                                "LeaveDays"
                                                                                                                                            }
                                                                                                                                            isLoading={false}
                                                                                                                                            isClearable={false}
                                                                                                                                            isSearchable={true}
                                                                                                                                            value={getLeaveDaysList.filter(function (option) {
                                                                                                                                                return option.value === childTask.LeaveDays;
                                                                                                                                            })}
                                                                                                                                            //isDisabled={true}
                                                                                                                                            // options={getLeaveDaysList}
                                                                                                                                            // styles={submitted && inputField.LeaveDays === 0 ? styles : ''}
                                                                                                                                            menuPosition="fixed"
                                                                                                                                        ></Reactselect>
                                                                                                                                    </span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        {childTask.titleEmployee1 && <div className="row">
                                                                                                                            <div className="col-md-12">
                                                                                                                                <div className='form-group'>
                                                                                                                                    <label htmlFor={childTask.titleEmployee1 === "Buyer" ? 'TaskHolderBuyer' : childTask.titleEmployee1 === "Cube" ? "FollowerCube" : "FollowerSupplier"} >Task Holder - {childTask.titleEmployee1 + " Employee"}<span className="text-danger">*</span> </label>
                                                                                                                                    <span className='input-icon icon-right'>
                                                                                                                                        <Reactselect className="basic-single" name={childTask.titleEmployee1 === "Buyer" ? 'TaskHolderBuyer' : childTask.titleEmployee1 === "Cube" ? "FollowerCube" : "FollowerSupplier"}
                                                                                                                                            //isDisabled={true}
                                                                                                                                            isLoading={false}
                                                                                                                                            isClearable={false}
                                                                                                                                            isSearchable={true}
                                                                                                                                            isMulti
                                                                                                                                            //onChange={event => handleChange(event, index, inputField.titleEmployee1 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee1 === "Cube" ? "FollowerCube" : "FollowerSupplier")}
                                                                                                                                            //options={inputField.titleEmployee1 === "Buyer" ? drpTNATaskOwnerList.buyerEmployeeList : inputField.titleEmployee1 === "Cube" ? drpTNATaskOwnerList.cubeEmployeeList : drpTNATaskOwnerList.supplierEmployeeList}
                                                                                                                                            value={childTask.titleEmployee1 === "Buyer" ? drpTNATaskOwnerList.buyerEmployeeList && childTask.arrTaskHolderBuyer : childTask.titleEmployee1 === "Cube" ? drpTNATaskOwnerList.cubeEmployeeList && childTask.arrFollowerCube : drpTNATaskOwnerList.supplierEmployeeList && childTask.arrFollowerSupplier}
                                                                                                                                        >  </Reactselect>

                                                                                                                                    </span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        }

                                                                                                                        {
                                                                                                                            childTask.titleEmployee2 && <div className="row">
                                                                                                                                <div className="col-md-12">
                                                                                                                                    <div className='form-group'>
                                                                                                                                        <label htmlFor={childTask.titleEmployee2 === "Buyer" ? 'TaskHolderBuyer' : childTask.titleEmployee2 === "Cube" ? "FollowerCube" : "FollowerSupplier"} >Follower - {childTask.titleEmployee2 + " Employee"}</label>
                                                                                                                                        <span className='input-icon icon-right'>
                                                                                                                                            <Reactselect className="basic-single" name={childTask.titleEmployee2 === "Buyer" ? 'TaskHolderBuyer' : childTask.titleEmployee2 === "Cube" ? "FollowerCube" : "FollowerSupplier"}
                                                                                                                                                //isDisabled={true}
                                                                                                                                                isLoading={false}
                                                                                                                                                isClearable={false}
                                                                                                                                                isSearchable={true}
                                                                                                                                                isMulti
                                                                                                                                                //onChange={event => handleChange(event, index, inputField.titleEmployee2 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee2 === "Cube" ? "FollowerCube" : "FollowerSupplier")}
                                                                                                                                                //options={inputField.titleEmployee2 === "Buyer" ? drpTNATaskOwnerList.buyerEmployeeList : inputField.titleEmployee2 === "Cube" ? drpTNATaskOwnerList.cubeEmployeeList : drpTNATaskOwnerList.supplierEmployeeList}
                                                                                                                                                value={childTask.titleEmployee2 === "Buyer" ? childTask.arrTaskHolderBuyer : childTask.titleEmployee2 === "Cube" ? childTask.arrFollowerCube : childTask.arrFollowerSupplier}
                                                                                                                                            // options={IMPORTANCE}
                                                                                                                                            // value={getStyleList.arrImportance}
                                                                                                                                            //  styles={submitted && getStyleList.arrImportance === 0 ? styles : ''}
                                                                                                                                            >  </Reactselect>

                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                        {childTask.titleEmployee3 && <div className="row">
                                                                                                                            <div className="col-md-12">
                                                                                                                                <div className='form-group'>
                                                                                                                                    <label htmlFor="FollowerSupplier" >Follower - {childTask.titleEmployee3 + " Employee"}</label>
                                                                                                                                    <span className='input-icon icon-right'>
                                                                                                                                        <Reactselect className="basic-single" name={childTask.titleEmployee3 === "Buyer" ? 'TaskHolderBuyer' : childTask.titleEmployee3 === "Cube" ? "FollowerCube" : "FollowerSupplier"}
                                                                                                                                            isDisabled={false}
                                                                                                                                            isLoading={false}
                                                                                                                                            isClearable={true}
                                                                                                                                            isSearchable={true}
                                                                                                                                            isMulti
                                                                                                                                            //onChange={event => handleChange(event, index, inputField.titleEmployee3 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee3 === "Cube" ? "FollowerCube" : "FollowerSupplier")}
                                                                                                                                            //options={inputField.titleEmployee3 === "Buyer" ? drpTNATaskOwnerList.buyerEmployeeList : inputField.titleEmployee3 === "Cube" ? drpTNATaskOwnerList.cubeEmployeeList : drpTNATaskOwnerList.supplierEmployeeList}
                                                                                                                                            value={childTask.titleEmployee3 === "Buyer" ? childTask.arrTaskHolderBuyer : childTask.titleEmployee3 === "Cube" ? childTask.arrFollowerCube : childTask.arrFollowerSupplier}
                                                                                                                                        // options={IMPORTANCE}
                                                                                                                                        // value={getStyleList.arrImportance}
                                                                                                                                        //  styles={submitted && getStyleList.arrImportance === 0 ? styles : ''}
                                                                                                                                        >  </Reactselect>
                                                                                                                                    </span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                        }

                                                                                                                    </Modal.Body>
                                                                                                                    <Modal.Footer>
                                                                                                                        <Button variant="danger" onClick={() => handleTHLeaveDaysClose(index, subIndex, childIndex)}>
                                                                                                                            <i class="fa fa-close"></i>&nbsp;Close
                                                                                                                        </Button>

                                                                                                                    </Modal.Footer>
                                                                                                                </Modal>
                                                                                                            }
                                                                                                            {
                                                                                                                childTask.IsManualClose === 1 ?
                                                                                                                    <Modal show={childTask.IsManualClose === 1} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseManualPopup(index, subIndex, childIndex, childTask.StatusID, childTask.ManualCloseComment, childTask.ManualCloseDate)}>
                                                                                                                        <Modal.Header closeButton>
                                                                                                                            <Modal.Title>
                                                                                                                                {childTask.StatusID == 3 ? 'View ' : ''} Manual Close : {inputField.strTaskName}
                                                                                                                            </Modal.Title>
                                                                                                                        </Modal.Header>
                                                                                                                        <Modal.Body>

                                                                                                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                                                                <thead>
                                                                                                                                    <tr>
                                                                                                                                        <th>Comments <span className="text-danger">*</span></th>
                                                                                                                                        <th>Close Date <span className="text-danger">*</span></th>

                                                                                                                                    </tr>
                                                                                                                                </thead>
                                                                                                                                <tbody>

                                                                                                                                    <tr>
                                                                                                                                        <td style={{ width: "600px" }} >
                                                                                                                                            <textarea
                                                                                                                                                rows="3"
                                                                                                                                                placeholder="Enter Comments here..."
                                                                                                                                                autoFocus onFocus={""} autoComplete="off"
                                                                                                                                                maxLength="500" name="EnterRemarks"
                                                                                                                                                id={"EnterRemarks"}
                                                                                                                                                value={childTask.ManualCloseComment}
                                                                                                                                                onChange={event => handleChangeIsManualCloseComments(event, index, subIndex, childIndex, "Comment")}
                                                                                                                                                style={{ width: "100%", border: getComment && childTask.ManualCloseComment === '' ? '1px solid red' : '' }}
                                                                                                                                                disabled={childTask.StatusID === 3 ? true : false}
                                                                                                                                            >

                                                                                                                                            </textarea>
                                                                                                                                        </td>
                                                                                                                                        <td >
                                                                                                                                            <DatePicker className="form-control" name="HolidayDate"
                                                                                                                                                id={
                                                                                                                                                    "HolidayDate" + index
                                                                                                                                                }
                                                                                                                                                // selected={
                                                                                                                                                //     childTask.StatusID !== 3 ?
                                                                                                                                                //         new Date() :
                                                                                                                                                //         childTask.ManualCloseDate
                                                                                                                                                // }
                                                                                                                                                selected={
                                                                                                                                                    childTask.ManualCloseDate
                                                                                                                                                }
                                                                                                                                                onChange={event => handleChangeIsManualCloseComments(event, index, subIndex, childIndex, "CommentDate")}
                                                                                                                                                dateFormat="dd/MM/yyyy"
                                                                                                                                                peekNextMonth
                                                                                                                                                showMonthDropdown
                                                                                                                                                // showYearDropdown
                                                                                                                                                dropdownMode="scroll"
                                                                                                                                                autoComplete="off"
                                                                                                                                                minDate={new Date()}
                                                                                                                                                maxDate={new Date()}
                                                                                                                                                customInput={<CustomInput />}
                                                                                                                                                disabled={childTask.StatusID === 3 ? true : false}
                                                                                                                                            // disabled

                                                                                                                                            // isClearable={inputField.HolidayDate === '' ? false : true}
                                                                                                                                            />


                                                                                                                                        </td>
                                                                                                                                    </tr>
                                                                                                                                </tbody>

                                                                                                                            </table>
                                                                                                                            <table className="table table-bordered">
                                                                                                                                <thead>
                                                                                                                                    <tr>
                                                                                                                                        <th>Style Name</th>
                                                                                                                                        <th>Style No</th>
                                                                                                                                        <th>ID Po</th>
                                                                                                                                        <th>
                                                                                                                                            {
                                                                                                                                                childTask.TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION ||
                                                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION ||
                                                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION ?
                                                                                                                                                    "SKU Name" : "SKU Name"

                                                                                                                                            }
                                                                                                                                        </th>
                                                                                                                                        <th>Task Holder / Follower</th>
                                                                                                                                        <th>
                                                                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                                                                <input type="checkbox" name="IsSelected"

                                                                                                                                                    onChange={event => (SelectAll(event))}
                                                                                                                                                    checked={selectall === 1 ? true : false}
                                                                                                                                                    className="colored-blue clearOpacity" />
                                                                                                                                                <span className="text"></span>
                                                                                                                                            </label>
                                                                                                                                        </th>

                                                                                                                                    </tr>
                                                                                                                                </thead>
                                                                                                                                <tbody>
                                                                                                                                    {
                                                                                                                                        MultiCloseDatas.length !== 0 ?
                                                                                                                                            MultiCloseDatas.map((item, index) => {
                                                                                                                                                return (
                                                                                                                                                    <>
                                                                                                                                                        <tr>
                                                                                                                                                            <td>{item.styleName}</td>
                                                                                                                                                            <td>{item.styleNo}</td>
                                                                                                                                                            <td>{item.idPoNo}</td>
                                                                                                                                                            <td>{item.details}</td>
                                                                                                                                                            <td>{item.taskOwnerNameList}</td>
                                                                                                                                                            <td className="Actionbutton fixed-column">
                                                                                                                                                                <div style={{ width: "80px" }}>
                                                                                                                                                                    <label style={{ marginTop: '8px' }}>
                                                                                                                                                                        <input type="checkbox" name="IsSelected"

                                                                                                                                                                            onChange={event => (OnSelectRecords(event, index))}
                                                                                                                                                                            checked={item.isSelected === 1 ? true : false}
                                                                                                                                                                            className="colored-blue clearOpacity" />
                                                                                                                                                                        <span className="text"></span>
                                                                                                                                                                    </label>

                                                                                                                                                                </div>

                                                                                                                                                            </td>
                                                                                                                                                        </tr>
                                                                                                                                                    </>

                                                                                                                                                )
                                                                                                                                            })
                                                                                                                                            :
                                                                                                                                            <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>

                                                                                                                                    }

                                                                                                                                </tbody>
                                                                                                                            </table>
                                                                                                                            {/* {



                                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                                                                                                                                        childTask.TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT ?

                                                                                                                                        <table className="table table-bordered">
                                                                                                                                            <tbody>
                                                                                                                                                {
                                                                                                                                                    OrderInfoMulticloseMainDetails.length !== 0 ?
                                                                                                                                                        OrderInfoMulticloseMainDetails.map(comment => (
                                                                                                                                                            <Fragment>
                                                                                                                                                                <tr>
                                                                                                                                                                    <td>
                                                                                                                                                                        <div className="row">
                                                                                                                                                                            <div className="col-lg-8">
                                                                                                                                                                                <i
                                                                                                                                                                                    className={
                                                                                                                                                                                        expandStateMultiSelect[comment.strikeofforTrimLogoPath] ?
                                                                                                                                                                                            'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                                                                                                    }
                                                                                                                                                                                    onClick={() => handleExpandRowMultiSelect(comment.strikeofforTrimLogoPath, MultiCloseDatas)}
                                                                                                                                                                                ></i>
                                                                                                                                                                                <span className="BuyerPaddingLeft">
                                                                                                                                                                                    {
                                                                                                                                                                                        comment.strikeofforTrimCode
                                                                                                                                                                                    }
                                                                                                                                                                                    -
                                                                                                                                                                                    {
                                                                                                                                                                                        comment.strikeofforTrimName
                                                                                                                                                                                    }
                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div className="col-lg-4">
                                                                                                                                                                                <span className="Actionbutton">
                                                                                                                                                                                    {
                                                                                                                                                                                        childTask.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ?
                                                                                                                                                                                            <img src={window.$APIBaseURL + "Images/Style/Strikeoff/" + comment.strikeofforTrimLogoPath} height="50px" width="50px"></img>
                                                                                                                                                                                            :
                                                                                                                                                                                            <img src={window.$APIBaseURL + "Images/Style/Trim/" + comment.strikeofforTrimLogoPath} height="50px" width="50px"></img>
                                                                                                                                                                                    }

                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                        </div>
                                                                                                                                                                    </td>

                                                                                                                                                                </tr>
                                                                                                                                                                <>
                                                                                                                                                                    {
                                                                                                                                                                        expandedRowsMultiSelect.includes(comment.strikeofforTrimLogoPath) ?

                                                                                                                                                                            <tr>
                                                                                                                                                                                <td>
                                                                                                                                                                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                                                                                                                        <thead>
                                                                                                                                                                                            <tr>
                                                                                                                                                                                                <th>StyleName</th>
                                                                                                                                                                                                <th>StyleNo</th>
                                                                                                                                                                                                <th>ID Po</th>
                                                                                                                                                                                                <th>Details</th>
                                                                                                                                                                                                <th>Task Holder / Follower</th>
                                                                                                                                                                                                <th>
                                                                                                                                                                                                    <label style={{ marginTop: '8px' }}>
                                                                                                                                                                                                        <input type="checkbox" name="IsSelected"

                                                                                                                                                                                                            onChange={event => (SelectAll(event, comment.strikeofforTrimLogoPath))}
                                                                                                                                                                                                            checked={selectall === 1 ? true : false}
                                                                                                                                                                                                            className="colored-blue clearOpacity" />
                                                                                                                                                                                                        <span className="text"></span>
                                                                                                                                                                                                    </label>

                                                                                                                                                                                                </th>

                                                                                                                                                                                            </tr>
                                                                                                                                                                                        </thead>
                                                                                                                                                                                        <tbody>
                                                                                                                                                                                            {
                                                                                                                                                                                                expandListMultiSelect.map((item, index) => (
                                                                                                                                                                                                    <Fragment>

                                                                                                                                                                                                        <tr>
                                                                                                                                                                                                            <td>{item.styleName}</td>
                                                                                                                                                                                                            <td>{item.styleNo}</td>
                                                                                                                                                                                                            <td>{item.idPoNo}</td>
                                                                                                                                                                                                            <td>{item.details}</td>
                                                                                                                                                                                                            <td>{item.taskOwnerNameList}</td>
                                                                                                                                                                                                            <td className="Actionbutton fixed-column">
                                                                                                                                                                                                                <div style={{ width: "80px" }}>
                                                                                                                                                                                                                    <label style={{ marginTop: '8px' }}>
                                                                                                                                                                                                                        <input type="checkbox" name="IsSelected"


                                                                                                                                                                                                                            onChange={event => (OnSelectRecords(event, index))}
                                                                                                                                                                                                                            checked={item.isSelected === 1 ? true : false}
                                                                                                                                                                                                                            className="colored-blue clearOpacity" />
                                                                                                                                                                                                                        <span className="text"></span>
                                                                                                                                                                                                                    </label>

                                                                                                                                                                                                                </div>

                                                                                                                                                                                                            </td>

                                                                                                                                                                                                        </tr>

                                                                                                                                                                                                    </Fragment>
                                                                                                                                                                                                ))

                                                                                                                                                                                            }

                                                                                                                                                                                        </tbody>
                                                                                                                                                                                    </table>
                                                                                                                                                                                </td>
                                                                                                                                                                            </tr> : null
                                                                                                                                                                    }
                                                                                                                                                                </>
                                                                                                                                                            </Fragment>
                                                                                                                                                        )) :
                                                                                                                                                        <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>

                                                                                                                                                }
                                                                                                                                            </tbody>
                                                                                                                                        </table>
                                                                                                                                        :

                                                                                                                                        <table className="table table-bordered">
                                                                                                                                            <thead>
                                                                                                                                                <tr>
                                                                                                                                                    <th>StyleName</th>
                                                                                                                                                    <th>StyleNo</th>
                                                                                                                                                    <th>ID Po</th>
                                                                                                                                                    <th>
                                                                                                                                                        {
                                                                                                                                                            childTask.TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION ||
                                                                                                                                                                childTask.TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION ||
                                                                                                                                                                childTask.TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION ?
                                                                                                                                                                "Sku" : "Details"

                                                                                                                                                        }
                                                                                                                                                    </th>
                                                                                                                                                    <th>Task Holder / Follower</th>
                                                                                                                                                    <th>
                                                                                                                                                        <label style={{ marginTop: '8px' }}>
                                                                                                                                                            <input type="checkbox" name="IsSelected"

                                                                                                                                                                onChange={event => (SelectAll(event))}
                                                                                                                                                                checked={selectall === 1 ? true : false}
                                                                                                                                                                className="colored-blue clearOpacity" />
                                                                                                                                                            <span className="text"></span>
                                                                                                                                                        </label>
                                                                                                                                                    </th>

                                                                                                                                                </tr>
                                                                                                                                            </thead>
                                                                                                                                            <tbody>
                                                                                                                                                {
                                                                                                                                                    MultiCloseDatas.length !== 0 ?
                                                                                                                                                        MultiCloseDatas.map((item, index) => {
                                                                                                                                                            return (
                                                                                                                                                                <>
                                                                                                                                                                    <tr>
                                                                                                                                                                        <td>{item.styleName}</td>
                                                                                                                                                                        <td>{item.styleNo}</td>
                                                                                                                                                                        <td>{item.idPoNo}</td>
                                                                                                                                                                        <td>{item.details}</td>
                                                                                                                                                                        <td>{item.taskOwnerNameList}</td>
                                                                                                                                                                        <td className="Actionbutton fixed-column">
                                                                                                                                                                            <div style={{ width: "80px" }}>
                                                                                                                                                                                <label style={{ marginTop: '8px' }}>
                                                                                                                                                                                    <input type="checkbox" name="IsSelected"

                                                                                                                                                                                        onChange={event => (OnSelectRecords(event, index))}
                                                                                                                                                                                        checked={item.isSelected === 1 ? true : false}
                                                                                                                                                                                        className="colored-blue clearOpacity" />
                                                                                                                                                                                    <span className="text"></span>
                                                                                                                                                                                </label>

                                                                                                                                                                            </div>

                                                                                                                                                                        </td>
                                                                                                                                                                    </tr>
                                                                                                                                                                </>

                                                                                                                                                            )
                                                                                                                                                        })
                                                                                                                                                        :
                                                                                                                                                        <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>

                                                                                                                                                }

                                                                                                                                            </tbody>
                                                                                                                                        </table>


                                                                                                                                } */}

                                                                                                                        </Modal.Body>
                                                                                                                        <Modal.Footer>
                                                                                                                            {
                                                                                                                                childTask.StatusID !== 3 &&
                                                                                                                                <Button variant="success" onClick={() => SaveChildManulaCloseComments(index, subIndex, childIndex, childTask.ManualCloseComment, childTask.ManualCloseDate, childTask.TaskName, childTask.ChildId)}>
                                                                                                                                    Save
                                                                                                                                </Button>


                                                                                                                            }


                                                                                                                        </Modal.Footer>
                                                                                                                    </Modal> : ''
                                                                                                            }
                                                                                                            {/* {

                                                                                                                    inputField.IsManual === 1 &&

                                                                                                                    <Modal show={inputField.IsManual === 1} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseManual(index)}>
                                                                                                                        <Modal.Header closeButton>
                                                                                                                            <Modal.Title>Manual Close&nbsp;</Modal.Title>
                                                                                                                            {
                                                                                                                                childTask.StatusID === 3 &&

                                                                                                                                <button type="button" className="btn btn-xs btn-danger" title="Delete Manual Close" onClick={() => handledelete(index, 0, 0, 'Parent')}>
                                                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                                                </button>
                                                                                                                            }
                                                                                                                        </Modal.Header>
                                                                                                                        <Modal.Body>
                                                                                                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                                                                <thead>
                                                                                                                                    <tr>
                                                                                                                                        <th>Comments </th>

                                                                                                                                    </tr>
                                                                                                                                </thead>
                                                                                                                                <tbody>

                                                                                                                                    <tr>
                                                                                                                                        <td colSpan="6">
                                                                                                                                            <textarea
                                                                                                                                                rows="3"
                                                                                                                                                placeholder="Enter Comments here..."
                                                                                                                                                autoFocus onFocus={""} autoComplete="off"
                                                                                                                                                maxLength="500" name="ManualComments"
                                                                                                                                                id={"ManualComments"}
                                                                                                                                                value={inputField.ManualComments}
                                                                                                                                                onChange={event => handleChangeTNAComments(event, index)}
                                                                                                                                                style={{ width: "100%", border: getComment && inputField.ManualComments === '' ? '1px solid red' : '' }}>

                                                                                                                                            </textarea>
                                                                                                                                        </td>
                                                                                                                                    </tr>
                                                                                                                                </tbody>
                                                                                                                            </table>


                                                                                                                        </Modal.Body>
                                                                                                                        <Modal.Footer>
                                                                                                                            {
                                                                                                                                childTask.StatusID !== 3 &&
                                                                                                                                <Button variant="success"
                                                                                                                                    onClick={() => handleManual(index, subIndex, childIndex, inputField.ManualComments)}
                                                                                                                                > Save </Button>
                                                                                                                            }

                                                                                                                        </Modal.Footer>
                                                                                                                    </Modal>
                                                                                                                } */}
                                                                                                            {/* {(childTask.IsShowSubmitButton === 1 || childTask.IsShowSubmitButton === 2) && childTask.StatusID !== 3 ?
                                                                                                                    <Fragment>&nbsp;<button type="button" className={childTask.ManualCloseComment === '' || childTask.ManualCloseComment === null || childTask.ManualCloseComment === undefined ? "btn btn-xs btn-darkorange" : "btn btn-xs btn-success"} title="Manual Close"
                                                                                                                        onClick={() => handleChildManualclose(index, subIndex, childIndex, childTask.TaskName, childTask.ChildId)}
                                                                                                                    >
                                                                                                                        <i className="fa fa-user"></i>&nbsp;
                                                                                                                        <i className="fa fa-close"></i>
                                                                                                                    </button>
                                                                                                                    </Fragment>
                                                                                                                    : (childTask.ManualCloseComment !== '' && childTask.ManualCloseComment !== null && childTask.ManualCloseComment !== undefined) && childTask.StatusID === 3 ?
                                                                                                                        <button type="button" className={childTask.ManualCloseComment === '' || childTask.ManualCloseComment === null || childTask.ManualCloseComment === undefined ? "btn btn-xs btn-darkorange" : "btn btn-xs btn-success"} title="Manual Close"
                                                                                                                            onClick={() => handleChildManualclose(index, subIndex, childIndex, childTask.TaskName, childTask.ChildId,)}
                                                                                                                        >
                                                                                                                            <i className="fa fa-user"></i>&nbsp;
                                                                                                                            <i className="fa fa-close"></i>
                                                                                                                        </button>
                                                                                                                        : ''
                                                                                                                } */}
                                                                                                            {TaskCloseCompleteInfo && TaskCloseCompleteInfo.isAdd !== 0 &&
                                                                                                                <>
                                                                                                                    {(childTask.IsShowSubmitButton === 1 || childTask.IsShowSubmitButton === 2) && childTask.StatusID !== 3 && childTask.IsDraft !== 1 && childTask.IsQAdraft !== 1 && childTask.IsTestDraft !== 1 && childTask.IsStrikedraft !== 1 && childTask.IsTrimDraft !== 1 ?
                                                                                                                        <Fragment>&nbsp;<button type="button" className={childTask.ManualCloseComment === '' || childTask.ManualCloseComment === null || childTask.ManualCloseComment === undefined ? "btn btn-xs btn-darkorange" : "btn btn-xs btn-success"} title="Manual Close"
                                                                                                                            onClick={() => handleChildManualclose(index, subIndex, childIndex, childTask.TaskName, childTask.ChildId, childTask.StatusID)}
                                                                                                                        >
                                                                                                                            <i className="fa fa-user"></i>&nbsp;
                                                                                                                            <i className="fa fa-close"></i>
                                                                                                                        </button>
                                                                                                                        </Fragment>
                                                                                                                        : (childTask.ManualCloseComment !== '' && childTask.ManualCloseComment !== null && childTask.ManualCloseComment !== undefined) && childTask.StatusID === 3 ?
                                                                                                                            <button type="button" className={childTask.ManualCloseComment === '' || childTask.ManualCloseComment === null || childTask.ManualCloseComment === undefined ? "btn btn-xs btn-darkorange" : "btn btn-xs btn-success"} title="View Manual Close Data"
                                                                                                                                onClick={() => handleChildManualclose(index, subIndex, childIndex, childTask.TaskName, childTask.ChildId, childTask.StatusID)}
                                                                                                                            >
                                                                                                                                <i className="fa fa-user"></i>&nbsp;
                                                                                                                                <i className="fa fa-close"></i>
                                                                                                                            </button>
                                                                                                                            : ''
                                                                                                                    }
                                                                                                                </>
                                                                                                            }
                                                                                                            {/* {isLastEdit && inputField.IsSingleTask ?
                                                                                                                    (inputField.ManualComments === '' || inputField.ManualComments === null || inputField.ManualComments === undefined) && inputField.IsSingleTask === true && childTask.StatusID !== 3 ?
                                                                                                                        <Fragment>&nbsp;
                                                                                                                            {TaskCloseCompleteInfo !== undefined && TaskCloseCompleteInfo.isAdd !== 0 &&

                                                                                                                                <button type="button" className={inputField.ManualComments === '' || inputField.ManualComments === null ? "btn btn-xs btn-darkorange" : "btn btn-xs btn-success"} title="Manual Close"
                                                                                                                                    onClick={() => handleManualclose(index, childTask.StatusID, subIndex, childIndex,)}
                                                                                                                                >

                                                                                                                                    <i className="fa fa-user"></i>&nbsp;
                                                                                                                                    <i className="fa fa-close"></i>
                                                                                                                                </button>
                                                                                                                            }
                                                                                                                        </Fragment>
                                                                                                                        :
                                                                                                                        <button type="button" className={inputField.ManualComments !== '' || inputField.ManualComments !== null ? "btn btn-xs btn-success" : "btn btn-xs btn-darkorange"} title="View Manual Close Data"
                                                                                                                            onClick={() => handleManualclose(index, childTask.StatusID, subIndex, childIndex,)}
                                                                                                                        >
                                                                                                                            <i className="fa fa-user"></i>&nbsp;
                                                                                                                            <i className="fa fa-close"></i>
                                                                                                                        </button>
                                                                                                                    : ''
                                                                                                                } */}
                                                                                                            &nbsp;
                                                                                                            {childTask.DetailTypeID === 4 && childTask.StatusID !== 3 && childTask.TaskName === 'FINAL INSPECTION' &&
                                                                                                                <>
                                                                                                                    {
                                                                                                                        childTask.StatusID !== 4 ?
                                                                                                                            <Fragment>&nbsp;<button type="button" className={"btn btn-xs btn-Report"} style={{ color: '' }} title="Report Create"
                                                                                                                                onClick={() => handleExFactoryFormOpen(index, subIndex, childIndex, inputField.strTaskName, inputField.TaskID,
                                                                                                                                    childTask.SubId, childTask.StatusID, childTask.TaskHolderIDList, childTask.TaskHolderOrFollower,
                                                                                                                                    childTask.ChildId, inputField.SelectedDependency, 1, childTask.StyleId, childTask.TNAId)}
                                                                                                                            >
                                                                                                                                <i className="fa fa-external-link"></i>

                                                                                                                            </button>
                                                                                                                            </Fragment>
                                                                                                                            : ""
                                                                                                                    }

                                                                                                                </>
                                                                                                            }
                                                                                                            {childTask.IsDraft === 1 ?
                                                                                                                <Fragment>&nbsp;<button type="button" className={"btn btn-xs btn-ReportView"} title="Report View"
                                                                                                                    onClick={() => handleFinalInspecView(index, subIndex, childIndex, inputField.strTaskName, inputField.TaskID, childTask.SubId,
                                                                                                                        childTask.StatusID, childTask.TaskHolderIDList, childTask.TaskHolderOrFollower, childTask.ChildId,
                                                                                                                        inputField.SelectedDependency, 2, childTask.StyleId, childTask.TNAId)}
                                                                                                                >
                                                                                                                    <i className="fa fa-check-square"></i>

                                                                                                                </button>
                                                                                                                </Fragment> : ""
                                                                                                            }
                                                                                                            {childTask.DetailTypeID === 4 && childTask.StatusID !== 3 && (childTask.TaskName === 'Pilot Run' || childTask.TaskName === "In and Mid Line Inspection" ||
                                                                                                                childTask.TaskName === "Size Set check" || childTask.TaskName === "PP Meeting") &&
                                                                                                                <>
                                                                                                                    {
                                                                                                                        childTask.StatusID !== 4 ?
                                                                                                                            <Fragment>&nbsp;<button type="button" className={"btn btn-xs btn-Report"} style={{ color: '' }} title="Inspection Create"
                                                                                                                                onClick={() => handleQCInspectionFormOpen(index, subIndex, childIndex, inputField.strTaskName,
                                                                                                                                    inputField.TaskID, childTask.SubId, childTask.StatusID, childTask.TaskHolderIDList,
                                                                                                                                    childTask.TaskHolderOrFollower, childTask.ChildId, inputField.SelectedDependency, 1, childTask.StyleId, childTask.TNAId)}
                                                                                                                            >
                                                                                                                                <i className="fa fa-external-link"></i>

                                                                                                                            </button>
                                                                                                                            </Fragment>
                                                                                                                            : ""
                                                                                                                    }

                                                                                                                </>
                                                                                                            }
                                                                                                            {childTask.IsQAdraft === 1 ?
                                                                                                                <Fragment>&nbsp;<button type="button" className={"btn btn-xs btn-ReportView"} title="QA Report View"
                                                                                                                    onClick={() => handleQAInspecView(index, subIndex, childIndex, inputField.strTaskName, inputField.TaskID, childTask.SubId, childTask.StatusID, inputField.TaskHolderOwnerIdList, inputField.TaskHolderOrFollower, childTask.ChildId, inputField.SelectedDependency, 2, childTask.StyleID, childTask.TNAId)}
                                                                                                                >
                                                                                                                    <i className="fa fa-check-square"></i>

                                                                                                                </button>
                                                                                                                </Fragment> : ""
                                                                                                            }
                                                                                                            {childTask.StatusID !== 3 && (childTask.TaskName === 'Strike off Test' || childTask.TaskName === "Trims Test" ||
                                                                                                                childTask.TaskName === "FABRIC TEST" || childTask.TaskName === "YARN TEST" || childTask.TaskName === "GARMENT TEST") &&
                                                                                                                <>
                                                                                                                    {
                                                                                                                        childTask.StatusID !== 4 ?
                                                                                                                            <Fragment>&nbsp;<button type="button" className={"btn btn-xs btn-Report"} style={{ color: '' }} title="Testing Report Create"
                                                                                                                                onClick={() => handleTestingTaskFormOpen(index, subIndex, childIndex, inputField.strTaskName, inputField.TaskID, childTask.SubId, childTask.StatusID, childTask.TaskOwnerName,
                                                                                                                                    childTask.TaskHolderOrFollower, childTask.ChildId, inputField.SelectedDependency, 1, childTask.StyleId, childTask.TNAId)}
                                                                                                                            >
                                                                                                                                <i className="fa fa-external-link"></i>

                                                                                                                            </button>
                                                                                                                            </Fragment>
                                                                                                                            : ""
                                                                                                                    }

                                                                                                                </>
                                                                                                            }
                                                                                                            {(childTask.TaskName === "FABRIC TEST" || childTask.TaskName === "YARN TEST" || childTask.TaskName === "GARMENT TEST") && childTask.IsTestDraft === 1 ?
                                                                                                                <Fragment>&nbsp;<button type="button" className={"btn btn-xs btn-ReportView"} title="Test Report View"
                                                                                                                    onClick={() => handleTestinInspecView(index, subIndex, childIndex, inputField.strTaskName, inputField.TaskID, childTask.SubId, childTask.StatusID, childTask.TaskOwnerName,
                                                                                                                        childTask.TaskHolderOrFollower, childTask.ChildId, inputField.SelectedDependency, 2, childTask.StyleId, childTask.TNAId, 1)}
                                                                                                                >
                                                                                                                    <i className="fa fa-check-square"></i>

                                                                                                                </button>
                                                                                                                </Fragment> : ""
                                                                                                            }
                                                                                                            {childTask.TaskName === 'Strike off Test' && childTask.IsStrikedraft === 1 ?
                                                                                                                <Fragment>&nbsp;<button type="button" className={"btn btn-xs btn-ReportView"} title="Test Report View"
                                                                                                                    onClick={() => handleTestinInspecView(index, subIndex, childIndex, inputField.strTaskName, inputField.TaskID, childTask.SubId, childTask.StatusID, childTask.TaskOwnerName,
                                                                                                                        childTask.TaskHolderOrFollower, childTask.ChildId, inputField.SelectedDependency, 2, childTask.StyleId, childTask.TNAId, 2)}
                                                                                                                >
                                                                                                                    <i className="fa fa-check-square"></i>

                                                                                                                </button>
                                                                                                                </Fragment> : ""
                                                                                                            }
                                                                                                            {childTask.TaskName === 'Trims Test' && childTask.IsTrimDraft === 1 ?
                                                                                                                <Fragment>&nbsp;<button type="button" className={"btn btn-xs btn-ReportView"} title="Test Report View"
                                                                                                                    onClick={() => handleTestinInspecView(index, subIndex, childIndex, inputField.strTaskName, inputField.TaskID, childTask.SubId, childTask.StatusID, childTask.TaskOwnerName,
                                                                                                                        childTask.TaskHolderOrFollower, childTask.ChildId, inputField.SelectedDependency, 2, childTask.StyleId, childTask.TNAId, 3)}
                                                                                                                >
                                                                                                                    <i className="fa fa-check-square"></i>

                                                                                                                </button>
                                                                                                                </Fragment> : ""
                                                                                                            }
                                                                                                            {
                                                                                                                childTask.IsTestingInsView === 1 ?
                                                                                                                    <Modal show={(childTask.IsTestingInsView === 1)} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseTestingView(index, subIndex, childIndex)} >
                                                                                                                        <Modal.Header closeButton>
                                                                                                                            <Modal.Title>
                                                                                                                                Testing Inspection List
                                                                                                                            </Modal.Title>

                                                                                                                        </Modal.Header>
                                                                                                                        <Modal.Body>
                                                                                                                            {
                                                                                                                                getTestingheaderData.length !== 0 && getTestingheaderData.map((item) => {
                                                                                                                                    return (
                                                                                                                                        <>
                                                                                                                                            <h5>Sku ID:-{item.skuID},&nbsp; &nbsp; PO/ID No:- {item.idno},&nbsp; &nbsp; Focus PO No:- {item.pono}
                                                                                                                                                ,&nbsp; &nbsp;Style:- {item.styleName},&nbsp; &nbsp; SKU:- {item.skuName},&nbsp; &nbsp;
                                                                                                                                                <b>PO Qty:- {item.poQty}</b>&nbsp; &nbsp;Ex-Fac:- {moment(item.exFacdate).format('DD/MM/YYYY')}</h5>
                                                                                                                                        </>
                                                                                                                                    )
                                                                                                                                })
                                                                                                                            }

                                                                                                                            <table className="table table-bordered">
                                                                                                                                <thead>
                                                                                                                                    <tr>
                                                                                                                                        <th>Report No. & Date</th>
                                                                                                                                        <th>Factory</th>
                                                                                                                                        <th>Product Qty</th>
                                                                                                                                        <th>Created By</th>
                                                                                                                                        <th>Action</th>

                                                                                                                                    </tr>
                                                                                                                                </thead>
                                                                                                                                <tbody>
                                                                                                                                    {
                                                                                                                                        getnewTestingReportlist.length !== 0 && getnewTestingReportlist.map((item, index) => {
                                                                                                                                            return (
                                                                                                                                                <>
                                                                                                                                                    <tr>
                                                                                                                                                        <td >
                                                                                                                                                            {item.reportNo}
                                                                                                                                                        </td>

                                                                                                                                                        <td>
                                                                                                                                                            {'-'}
                                                                                                                                                        </td>
                                                                                                                                                        <td>
                                                                                                                                                            {item.offerQty}
                                                                                                                                                        </td>
                                                                                                                                                        <td>
                                                                                                                                                            {item.createdBy}
                                                                                                                                                        </td>
                                                                                                                                                        <td style={{ width: "13%" }}>
                                                                                                                                                            <button type="button" className="btn btn-xs btn-primary" title='View FinalList'
                                                                                                                                                                onClick={() => handleTestInspecViewDetails(item, 'ViewInspection', childTask.TNAId)}>
                                                                                                                                                                <i className="fa fa-eye "></i>
                                                                                                                                                            </button>
                                                                                                                                                            &nbsp;
                                                                                                                                                            <button type="button" className="btn btn-xs btn-success" title='Edit FinalList'
                                                                                                                                                                onClick={() => handleTestInspecViewDetails(item, 'EditInspection', childTask.TNAId)}>
                                                                                                                                                                <i className="fa fa-edit"></i>
                                                                                                                                                            </button>
                                                                                                                                                            &nbsp;
                                                                                                                                                            {getnewTestingReportlist.length === (index + 1) && <button type="button" className="btn btn-xs btn-danger" title='Delete FinalList'
                                                                                                                                                                onClick={() => handleDeleteTestinspection(item, 'Delete')}
                                                                                                                                                            >
                                                                                                                                                                <i className="fa fa-trash-o"></i>
                                                                                                                                                            </button>
                                                                                                                                                            }

                                                                                                                                                        </td>

                                                                                                                                                    </tr>
                                                                                                                                                </>

                                                                                                                                            )

                                                                                                                                        })
                                                                                                                                    }
                                                                                                                                </tbody>

                                                                                                                            </table>


                                                                                                                        </Modal.Body>
                                                                                                                    </Modal>
                                                                                                                    : ""

                                                                                                            }
                                                                                                            {
                                                                                                                childTask.IsTestingTaskFactory === 1 ?
                                                                                                                    <Modal show={childTask.IsTestingTaskFactory === 1} dialogClassName="CreatePopup" size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseTestingTaskFormPopup(index, subIndex, childIndex)}>
                                                                                                                        <Modal.Header closeButton>
                                                                                                                            <Modal.Title>

                                                                                                                                ADD &nbsp;{inputField.strTaskName}

                                                                                                                            </Modal.Title>
                                                                                                                        </Modal.Header>
                                                                                                                        <Modal.Body>
                                                                                                                            <ul className="nav nav-tabs nav-justified" id="tab_newinspaction">
                                                                                                                                <li className="active">
                                                                                                                                    <a data-toggle="tab" href="#idaddinspection" onClick={() => pageRedirectForTestingInspection('AddInspection')}>
                                                                                                                                        <h5>New Testing</h5>
                                                                                                                                    </a>
                                                                                                                                </li>
                                                                                                                                <li>
                                                                                                                                    <a data-toggle="tab" href="#idreinspection" onClick={() => pageRedirectForTestingInspection('ReInspection')}>
                                                                                                                                        <h5>Re Testing </h5>
                                                                                                                                    </a>
                                                                                                                                </li>
                                                                                                                            </ul>
                                                                                                                            <div className="tab-content">
                                                                                                                                {getTestingReportPageView === 'AddInspection' ? <>
                                                                                                                                    <NewTesting props={props} QCfinalInspectionList={getTestingTaskListParam} Taskname={inputField.strTaskName}
                                                                                                                                        QCcommonSubmitApprovalParams={''} QCFinalInspectionCallback={QCTestingCallback} />
                                                                                                                                </> :
                                                                                                                                    <><ReTesting props={props} Reinspectionparams={getTestingTaskListParam}
                                                                                                                                        QCFinalInspectionCallback={QCTestingCallback} Taskname={inputField.strTaskName} /></>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </Modal.Body>
                                                                                                                        <Modal.Footer>
                                                                                                                            {
                                                                                                                                childTask.StatusID !== 3 &&
                                                                                                                                <Button variant="success" onClick={() => AddTestingExFactoryForm(index, subIndex, childIndex)}>
                                                                                                                                    ADD
                                                                                                                                </Button>
                                                                                                                            }
                                                                                                                        </Modal.Footer>
                                                                                                                    </Modal> : ''
                                                                                                            }
                                                                                                            {
                                                                                                                <Modal show={childTask.IsOpenTestingConfirmationType === 1} size="sm" dragable backdrop="static" keyboard={false} onHide={() => handleCloseTestingExFactoryFormPopup(index, subIndex, childIndex)}>
                                                                                                                    <Modal.Header closeButton>
                                                                                                                        <Modal.Title>
                                                                                                                            Inspection Type Confirm
                                                                                                                        </Modal.Title>
                                                                                                                    </Modal.Header>
                                                                                                                    <Modal.Body>
                                                                                                                        <div className="row">
                                                                                                                            <div className="col-md-6 btn-action-center">
                                                                                                                                <button className="btn btn-sm btn-success" onClick={() => CreateTesting(1, childTask.TNAId)}>Internal</button>
                                                                                                                            </div>
                                                                                                                            <div className="col-md-6 btn-action-center">
                                                                                                                                <button className="btn btn-sm btn-primary" onClick={() => CreateTesting(2, childTask.TNAId)}>External</button>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </Modal.Body>

                                                                                                                </Modal>
                                                                                                            }
                                                                                                            {
                                                                                                                childTask.IsQAInsView === 1 ?
                                                                                                                    <Modal show={(childTask.IsQAInsView === 1)} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseQAInspecView(index, subIndex, childIndex)} >
                                                                                                                        <Modal.Header closeButton>
                                                                                                                            <Modal.Title>
                                                                                                                                QA Inspection List
                                                                                                                            </Modal.Title>

                                                                                                                        </Modal.Header>
                                                                                                                        <Modal.Body>
                                                                                                                            {
                                                                                                                                getQAheaderData.length !== 0 && getQAheaderData.map((item) => {
                                                                                                                                    return (
                                                                                                                                        <>
                                                                                                                                            <h5>Sku ID:-{item.skuID},&nbsp; &nbsp; PO/ID No:- {item.idno},&nbsp; &nbsp; Focus PO No:- {item.pono}
                                                                                                                                                ,&nbsp; &nbsp;Style:- {item.styleName},&nbsp; &nbsp; SKU:- {item.skuName},&nbsp; &nbsp;
                                                                                                                                                <b>PO Qty:- {item.poQty}</b>&nbsp; &nbsp;Ex-Fac:- {moment(item.exFacdate).format('DD/MM/YYYY')}</h5>
                                                                                                                                        </>
                                                                                                                                    )
                                                                                                                                })
                                                                                                                            }

                                                                                                                            <table className="table table-bordered">
                                                                                                                                <thead>
                                                                                                                                    <tr>
                                                                                                                                        <th>Report No. & Date</th>
                                                                                                                                        <th>Factory</th>
                                                                                                                                        <th>Product Qty</th>
                                                                                                                                        <th>Created By</th>
                                                                                                                                        <th>Action</th>

                                                                                                                                    </tr>
                                                                                                                                </thead>
                                                                                                                                <tbody>
                                                                                                                                    {
                                                                                                                                        getnewQAReportlist.length !== 0 && getnewQAReportlist.map((item, index) => {
                                                                                                                                            return (
                                                                                                                                                <>
                                                                                                                                                    <tr>
                                                                                                                                                        <td >
                                                                                                                                                            {item.reportNo}
                                                                                                                                                        </td>

                                                                                                                                                        <td>
                                                                                                                                                            {item.factory}
                                                                                                                                                        </td>
                                                                                                                                                        <td>
                                                                                                                                                            {item.productQty}
                                                                                                                                                        </td>
                                                                                                                                                        <td>
                                                                                                                                                            {item.createdBy}
                                                                                                                                                        </td>
                                                                                                                                                        <td style={{ width: "13%" }}>
                                                                                                                                                            <button type="button" className="btn btn-xs btn-primary" title='View FinalList'
                                                                                                                                                                onClick={() => handleQAInspecViewDetails(item, 'View')}>
                                                                                                                                                                <i className="fa fa-eye "></i>
                                                                                                                                                            </button>
                                                                                                                                                            &nbsp;
                                                                                                                                                            <button type="button" className="btn btn-xs btn-success" title='Edit FinalList'
                                                                                                                                                                onClick={() => handleQAInspecViewDetails(item, 'Edit')}>
                                                                                                                                                                <i className="fa fa-edit"></i>
                                                                                                                                                            </button>
                                                                                                                                                            &nbsp;
                                                                                                                                                            {getnewQAReportlist.length === (index + 1) && <button type="button" className="btn btn-xs btn-danger" title='Delete FinalList'
                                                                                                                                                                onClick={() => handleDeleteQAinspection(item, 'Delete')}
                                                                                                                                                            >
                                                                                                                                                                <i className="fa fa-trash-o"></i>
                                                                                                                                                            </button>
                                                                                                                                                            }

                                                                                                                                                        </td>

                                                                                                                                                    </tr>
                                                                                                                                                </>

                                                                                                                                            )

                                                                                                                                        })
                                                                                                                                    }
                                                                                                                                </tbody>

                                                                                                                            </table>


                                                                                                                        </Modal.Body>
                                                                                                                    </Modal>
                                                                                                                    : ""

                                                                                                            }
                                                                                                            {
                                                                                                                childTask.IsFinalInsView === 1 ?
                                                                                                                    <Modal show={(childTask.IsFinalInsView === 1)} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseFinalInspecView(index, subIndex, childIndex)} >
                                                                                                                        <Modal.Header closeButton>
                                                                                                                            <Modal.Title>
                                                                                                                                Final Inspection List
                                                                                                                            </Modal.Title>

                                                                                                                        </Modal.Header>
                                                                                                                        <Modal.Body>
                                                                                                                            {
                                                                                                                                getnewheaderdata.length !== 0 && getnewheaderdata.map((item) => {
                                                                                                                                    return (
                                                                                                                                        <>
                                                                                                                                            <h5>Sku ID:-{item.skuID},&nbsp; &nbsp; PO/ID No:- {item.idno},&nbsp; &nbsp; {getAddInspectionListParam.BuyerName === 'Focus International' ? <>Focus PO No: {item.pono}&nbsp; </> : ''} &nbsp;Style:- {item.styleName},&nbsp; &nbsp; SKU:- {item.skuName},&nbsp; &nbsp; <b>PO Qty:- {item.poQty}</b>&nbsp; &nbsp;Ex-Fac:- {moment(item.exFacdate).format('DD/MM/YYYY')}</h5>
                                                                                                                                        </>
                                                                                                                                    )
                                                                                                                                })
                                                                                                                            }

                                                                                                                            <table className="table table-bordered">
                                                                                                                                <thead>
                                                                                                                                    <tr>
                                                                                                                                        <th>Report No. & Date</th>
                                                                                                                                        <th>Report Type</th>
                                                                                                                                        <th>Factory</th>
                                                                                                                                        <th>Inspected Qty</th>
                                                                                                                                        <th>Bal. for Inspection</th>
                                                                                                                                        <th>Created By</th>
                                                                                                                                        <th>Report Conclusion</th>
                                                                                                                                        <th>Approval Result</th>
                                                                                                                                        <th>Action</th>

                                                                                                                                    </tr>
                                                                                                                                </thead>
                                                                                                                                <tbody>
                                                                                                                                    {
                                                                                                                                        getnewfinalReportlist.length !== 0 && getnewfinalReportlist.map((item, index) => {
                                                                                                                                            return (
                                                                                                                                                <>
                                                                                                                                                    <tr>
                                                                                                                                                        <td >
                                                                                                                                                            {item.reportNo}

                                                                                                                                                        </td>
                                                                                                                                                        <td>
                                                                                                                                                            {item.reportType}

                                                                                                                                                        </td>
                                                                                                                                                        <td>
                                                                                                                                                            {item.factory}

                                                                                                                                                        </td>
                                                                                                                                                        <td >
                                                                                                                                                            {item.inspectedQty}
                                                                                                                                                        </td>
                                                                                                                                                        <td >
                                                                                                                                                            {item.balanceQuantity}
                                                                                                                                                        </td>
                                                                                                                                                        <td>
                                                                                                                                                            {item.createdBy}

                                                                                                                                                        </td>
                                                                                                                                                        <td>
                                                                                                                                                            -
                                                                                                                                                        </td>
                                                                                                                                                        <td>
                                                                                                                                                            -

                                                                                                                                                        </td>
                                                                                                                                                        <td style={{ width: "13%" }}>
                                                                                                                                                            <button type="button" className="btn btn-xs btn-primary" title='View FinalList'
                                                                                                                                                                onClick={() => handleFinalInspecViewDetails(item.confirmInspectionType, item.tnaFinalInspectionID, 'View', 'ViewInspection', item)}>
                                                                                                                                                                <i className="fa fa-eye "></i>
                                                                                                                                                            </button>
                                                                                                                                                            &nbsp;
                                                                                                                                                            <button type="button" className="btn btn-xs btn-success" title='Edit FinalList'
                                                                                                                                                                onClick={() => handleFinalInspecViewDetails(item.confirmInspectionType, item.tnaFinalInspectionID, 'Edit', "EditInspection", item)}>
                                                                                                                                                                <i className="fa fa-edit"></i>
                                                                                                                                                            </button>
                                                                                                                                                            &nbsp;
                                                                                                                                                            {getnewfinalReportlist.length === (index + 1) && <button type="button" className="btn btn-xs btn-danger" title='Delete FinalList'
                                                                                                                                                                onClick={() => handleDeletefinalinspection(item.confirmInspectionType, item.tnaFinalInspectionID, 'delete')}
                                                                                                                                                            >
                                                                                                                                                                <i className="fa fa-trash-o"></i>
                                                                                                                                                            </button>
                                                                                                                                                            }

                                                                                                                                                        </td>

                                                                                                                                                    </tr>
                                                                                                                                                </>

                                                                                                                                            )

                                                                                                                                        })
                                                                                                                                    }
                                                                                                                                </tbody>

                                                                                                                            </table>


                                                                                                                        </Modal.Body>
                                                                                                                    </Modal>
                                                                                                                    : ""

                                                                                                            }
                                                                                                            {
                                                                                                                <Modal show={childTask.IsOpenInspectionConfirmationType === 1} size="sm" dragable backdrop="static" keyboard={false} onHide={() => handleCloseInspectionConfirmationPopup(index, subIndex, childIndex)}>
                                                                                                                    <Modal.Header closeButton>
                                                                                                                        <Modal.Title>
                                                                                                                            CONFIRM INSPECTION TYPE
                                                                                                                        </Modal.Title>
                                                                                                                    </Modal.Header>
                                                                                                                    <Modal.Body>
                                                                                                                        <div className="row">
                                                                                                                            <div className="col-md-6 btn-action-center">
                                                                                                                                <button className="btn btn-sm btn-success" onClick={() => CreateFinalInspection(1)}>Internal</button>
                                                                                                                            </div>
                                                                                                                            <div className="col-md-6 btn-action-center">
                                                                                                                                <button className="btn btn-sm btn-primary" onClick={() => CreateFinalInspection(2)}>External</button>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </Modal.Body>
                                                                                                                    {/* <Modal.Footer>
                                                                                                                        {
                                                                                                                            childTask.StatusID !== 3 &&
                                                                                                                            <Button variant="success">
                                                                                                                                Save
                                                                                                                            </Button>
                                                                                                                        }
                                                                                                                    </Modal.Footer> */}
                                                                                                                </Modal>
                                                                                                            }
                                                                                                            {
                                                                                                                childTask.IsQCinsectionFactory === 1 ?
                                                                                                                    <Modal show={childTask.IsQCinsectionFactory === 1} dialogClassName="CreatePopup" size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseQCinspectionFormPopup(index, subIndex, childIndex)}>
                                                                                                                        <Modal.Header closeButton>
                                                                                                                            <Modal.Title>
                                                                                                                                {/* QC Internal Inspection Add  : {inputField.strTaskName} &nbsp; */}
                                                                                                                                ADD QC Internal Inspection {inputField.strTaskName} &nbsp; {"(Style Name - " + getInspectionInfo.styleName + ")"}

                                                                                                                            </Modal.Title>
                                                                                                                        </Modal.Header>
                                                                                                                        <Modal.Body>
                                                                                                                            <ul className="nav nav-tabs nav-justified" id="tab_newinspaction">
                                                                                                                                <li className="active">
                                                                                                                                    <a data-toggle="tab" href="#idaddinspection" onClick={() => pageRedirectForQAInspection('AddInspection')}>
                                                                                                                                        <h5>Add Inspection </h5>
                                                                                                                                    </a>
                                                                                                                                </li>
                                                                                                                                {
                                                                                                                                    inputField.strTaskName !== 'In and Mid Line Inspection' && inputField.strTaskName !== 'PP Meeting' ? <li>
                                                                                                                                        <a data-toggle="tab" href="#idreinspection" onClick={() => pageRedirectForQAInspection('ReInspection')}>
                                                                                                                                            <h5>Re Inspection </h5>
                                                                                                                                        </a>
                                                                                                                                    </li> : ''}
                                                                                                                            </ul>
                                                                                                                            <div className="tab-content">
                                                                                                                                {getQAInspectionPageView === 'AddInspection' ? <>
                                                                                                                                    <AddQCInspection props={props} QCfinalInspectionList={getAddQCInspectionListParam}
                                                                                                                                        QCcommonSubmitApprovalParams={''} QCFinalInspectionCallback={QCFinalInspectionCallback} />
                                                                                                                                </> :
                                                                                                                                    <><QAReInspection props={props} Reinspectionparams={getAddQCInspectionListParam}
                                                                                                                                        QCInspectionCallback={QCInspectionCallback} Taskname={inputField.strTaskName} /></>
                                                                                                                                }
                                                                                                                            </div>

                                                                                                                        </Modal.Body>
                                                                                                                        <Modal.Footer>
                                                                                                                            {
                                                                                                                                getQCfinalinspectionMulti.length === 0 ? '' :
                                                                                                                                    <Button variant="success" onClick={() => AddQCFactoryForm(index, subIndex, childIndex)}>
                                                                                                                                        Save
                                                                                                                                    </Button>
                                                                                                                            }
                                                                                                                        </Modal.Footer>
                                                                                                                    </Modal> : ''
                                                                                                            }
                                                                                                            {
                                                                                                                childTask.IsExFactoryForm === 1 ?
                                                                                                                    <Modal show={childTask.IsExFactoryForm === 1} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseExFactoryFormPopup(index, subIndex, childIndex)}>
                                                                                                                        <Modal.Header closeButton>
                                                                                                                            <Modal.Title>
                                                                                                                                {/* QC Internal Inspection Add  : {inputField.strTaskName} &nbsp; */}
                                                                                                                                ADD {inputField.strTaskName} &nbsp;

                                                                                                                            </Modal.Title>
                                                                                                                        </Modal.Header>
                                                                                                                        <Modal.Body>
                                                                                                                            <ul className="nav nav-tabs nav-justified" id="tab_newinspaction">
                                                                                                                                <li className="active">
                                                                                                                                    <a data-toggle="tab" href="#idaddinspection" onClick={() => pageRedirectForFinalInspection('AddInspection')}>
                                                                                                                                        <h5>Add Inspection </h5>
                                                                                                                                    </a>
                                                                                                                                </li>
                                                                                                                                <li>
                                                                                                                                    <a data-toggle="tab" href="#idreinspection" onClick={() => pageRedirectForFinalInspection('ReInspection')}>
                                                                                                                                        <h5>Re Inspection </h5>
                                                                                                                                    </a>
                                                                                                                                </li>
                                                                                                                            </ul>
                                                                                                                            <div className="tab-content">
                                                                                                                                {getFinalInspectionPageView === "AddInspection" ?
                                                                                                                                    <>
                                                                                                                                        <AddInspection props={props} finalInspectionList={getAddInspectionListParam}
                                                                                                                                            commonSubmitApprovalParams={''} FinalInspectionCallback={FinalInspectionCallback} />
                                                                                                                                    </> : ''}
                                                                                                                                {getFinalInspectionPageView === "ReInspection" ?
                                                                                                                                    <><ReInspection props={props} Reinspectionparams={getAddInspectionListParam}
                                                                                                                                        FinalInspectionCallback={FinalInspectionCallback} /></> : ''}
                                                                                                                            </div>
                                                                                                                        </Modal.Body>
                                                                                                                        <Modal.Footer>
                                                                                                                            {
                                                                                                                                childTask.StatusID !== 3 &&
                                                                                                                                <Button variant="success" onClick={() => AddExFactoryForm(index, subIndex, childIndex)}>
                                                                                                                                    Save
                                                                                                                                </Button>
                                                                                                                            }
                                                                                                                        </Modal.Footer>
                                                                                                                    </Modal> : ''
                                                                                                            }
                                                                                                            &nbsp;
                                                                                                            {
                                                                                                                TaskCloseCompleteInfo && TaskCloseCompleteInfo.isAdd !== 0 &&
                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION && childTask.IsShowSubmitButton === 2 && childTask.StatusID !== 3 ?
                                                                                                                    <button type="button" className="btn btn-xs btn-warning" title="Submit Form"
                                                                                                                        onClick={() => OpenLabdipSubmssionpopup(childTask.TNAId, childTask.ChildId,
                                                                                                                            childTask.StyleId, index, subIndex, childIndex, childTask.StatusID, getSearchvaluestoSub
                                                                                                                        )}
                                                                                                                    >
                                                                                                                        <i className="fa fa-send"></i>
                                                                                                                    </button> :
                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION && childTask.StatusID === 3 && childTask.IsShowSubmitButton !== 0 && childTask.ManualCloseComment === '' ?
                                                                                                                        <button type="button" className="btn btn-xs btn-success" title='View Approval'
                                                                                                                            onClick={() => OpenLabdipSubmssionpopup(childTask.TNAId, childTask.ChildId,
                                                                                                                                childTask.StyleId, index, subIndex, childIndex, childTask.StatusID

                                                                                                                            )}
                                                                                                                        //  disabled
                                                                                                                        >
                                                                                                                            <i className="fa fa-eye "></i>
                                                                                                                        </button> :
                                                                                                                        childTask.TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION && childTask.IsShowSubmitButton === 0 ?
                                                                                                                            <button type="button" className="btn btn-xs btn-success" title='Already Approved' disabled>
                                                                                                                                <i className="fa fa-check"></i>
                                                                                                                            </button>
                                                                                                                            : ''

                                                                                                            }
                                                                                                            {TaskCloseCompleteInfo && TaskCloseCompleteInfo.isAdd !== 0 &&
                                                                                                                childTask.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT && childTask.IsShowSubmitButton === 2 && childTask.StatusID !== 3 ?
                                                                                                                <button type="button" className="btn btn-xs btn-warning" title="Submit Form"
                                                                                                                    onClick={() => OpenStrikeoffSubmssionpopup(childTask.TNAId, childTask.ChildId
                                                                                                                        , index, subIndex, childIndex, childTask.StatusID)}
                                                                                                                >
                                                                                                                    <i className="fa fa-send"></i>
                                                                                                                </button> :
                                                                                                                childTask.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT && childTask.StatusID === 3 && childTask.IsShowSubmitButton !== 0 && childTask.ManualCloseComment === '' ?
                                                                                                                    <button type="button" className="btn btn-xs btn-success" title='View Approval'
                                                                                                                        onClick={() => OpenStrikeoffSubmssionpopup(childTask.TNAId, childTask.ChildId
                                                                                                                            , index, subIndex, childIndex, childTask.StatusID)}
                                                                                                                    >
                                                                                                                        <i className="fa fa-eye "></i>
                                                                                                                    </button> :
                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT && childTask.IsShowSubmitButton === 0 ?
                                                                                                                        <button type="button" className="btn btn-xs btn-success" title='Already Approved' disabled>
                                                                                                                            <i className="fa fa-check"></i>
                                                                                                                        </button>
                                                                                                                        : ''

                                                                                                            }
                                                                                                            {TaskCloseCompleteInfo && TaskCloseCompleteInfo.isAdd !== 0 &&
                                                                                                                childTask.TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL && childTask.IsShowSubmitButton === 2 && childTask.StatusID !== 3 ?

                                                                                                                <button type="button" className="btn btn-xs btn-warning" title="Approve Form"
                                                                                                                    onClick={() => OpenLabdipApprovalpopup(childTask.ChildId, childTask.StyleId
                                                                                                                        , index, subIndex, childIndex, childTask.StatusID)}
                                                                                                                >
                                                                                                                    <i className="fa fa-check"></i>
                                                                                                                </button> :
                                                                                                                childTask.TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL && childTask.StatusID === 3 && childTask.IsShowSubmitButton !== 0 && childTask.ManualCloseComment === '' ?
                                                                                                                    <button type="button" className="btn btn-xs btn-success" title='View Approval'
                                                                                                                        onClick={() => OpenLabdipApprovalpopup(childTask.ChildId, childTask.StyleId
                                                                                                                            , index, subIndex, childIndex, childTask.StatusID)}
                                                                                                                    >
                                                                                                                        <i className="fa fa-eye "></i>
                                                                                                                    </button> :
                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL && childTask.IsShowSubmitButton === 0 ?
                                                                                                                        <button type="button" className="btn btn-xs btn-success" title='Already Approved' disabled>
                                                                                                                            <i className="fa fa-check"></i>
                                                                                                                        </button>
                                                                                                                        : ''

                                                                                                            }
                                                                                                            {TaskCloseCompleteInfo && TaskCloseCompleteInfo.isAdd !== 0 &&
                                                                                                                childTask.TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT && childTask.IsShowSubmitButton === 2 && childTask.StatusID !== 3 ?
                                                                                                                <button type="button" className="btn btn-xs btn-warning" title="Submit Form"
                                                                                                                    onClick={() => OpenTrimSubmssionpopup(childTask.TNAId, childTask.ChildId
                                                                                                                        , index, subIndex, childIndex, childTask.StatusID)}
                                                                                                                >
                                                                                                                    <i className="fa fa-send"></i>
                                                                                                                </button> :
                                                                                                                childTask.TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT && childTask.StatusID === 3 && childTask.IsShowSubmitButton !== 0 && childTask.ManualCloseComment === '' ?
                                                                                                                    <button type="button" className="btn btn-xs btn-success" title="View Submission"
                                                                                                                        onClick={() => OpenTrimSubmssionpopup(childTask.TNAId, childTask.ChildId
                                                                                                                            , index, subIndex, childIndex, childTask.StatusID)}
                                                                                                                    >
                                                                                                                        <i className="fa fa-eye "></i>
                                                                                                                    </button>
                                                                                                                    :
                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT && childTask.IsShowSubmitButton === 0 ?
                                                                                                                        <button type="button" className="btn btn-xs btn-success" title='Already Approved' disabled>
                                                                                                                            <i className="fa fa-check"></i>
                                                                                                                        </button>
                                                                                                                        : ''

                                                                                                            }
                                                                                                            {TaskCloseCompleteInfo && TaskCloseCompleteInfo.isAdd !== 0 &&
                                                                                                                childTask.TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT && childTask.IsShowSubmitButton === 2 && childTask.StatusID !== 3 ?
                                                                                                                <button type="button" className="btn btn-xs btn-warning" title="Approve Form"
                                                                                                                    onClick={() => OpenTrimApprovalpopup(childTask.ChildId
                                                                                                                        , index, subIndex, childIndex, childTask.StatusID)}
                                                                                                                >
                                                                                                                    <i className="fa fa-check"></i>
                                                                                                                </button> :
                                                                                                                childTask.TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT && childTask.StatusID === 3 && childTask.IsShowSubmitButton !== 0 && childTask.ManualCloseComment === '' ?
                                                                                                                    <button type="button" className="btn btn-xs btn-success" title='View Approval'
                                                                                                                        onClick={() => OpenTrimApprovalpopup(childTask.ChildId
                                                                                                                            , index, subIndex, childIndex, childTask.StatusID)}
                                                                                                                    >
                                                                                                                        <i className="fa fa-eye "></i>
                                                                                                                    </button> :
                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT && childTask.IsShowSubmitButton === 0 ?
                                                                                                                        <button type="button" className="btn btn-xs btn-success" title='Already Approved' disabled>
                                                                                                                            <i className="fa fa-check"></i>
                                                                                                                        </button>
                                                                                                                        : ''

                                                                                                            }

                                                                                                            {TaskCloseCompleteInfo && TaskCloseCompleteInfo.isAdd !== 0 &&
                                                                                                                childTask.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT && childTask.IsShowSubmitButton === 2 && childTask.StatusID !== 3 ?
                                                                                                                <button type="button" className="btn btn-xs btn-warning" title="Approve Form"
                                                                                                                    onClick={() => OpenStrikeoffApprovalpopup(childTask.ChildId
                                                                                                                        , index, subIndex, childIndex, childTask.StatusID)}
                                                                                                                >
                                                                                                                    <i className="fa fa-check"></i>
                                                                                                                </button> :
                                                                                                                childTask.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT && childTask.StatusID === 3 && childTask.IsShowSubmitButton !== 0 && childTask.ManualCloseComment === '' ?
                                                                                                                    <button type="button" className="btn btn-xs btn-success" title='View Approval'
                                                                                                                        onClick={() => OpenStrikeoffApprovalpopup(childTask.ChildId
                                                                                                                            , index, subIndex, childIndex, childTask.StatusID)}
                                                                                                                    >
                                                                                                                        <i className="fa fa-eye "></i>
                                                                                                                    </button> :
                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT && childTask.IsShowSubmitButton === 0 ?
                                                                                                                        <button type="button" className="btn btn-xs btn-success" title='Already Approved' disabled>
                                                                                                                            <i className="fa fa-check"></i>
                                                                                                                        </button>
                                                                                                                        : ''

                                                                                                            }


                                                                                                            {TaskCloseCompleteInfo && TaskCloseCompleteInfo.isAdd !== 0 &&
                                                                                                                (childTask.TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || childTask.TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || childTask.TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION) && childTask.StatusID !== 3 ?
                                                                                                                <button type="button" className="btn btn-xs btn-warning" title="Submit Form"
                                                                                                                    onClick={() => OpenProtoFitSamplepopup(childTask.TNAId, childTask.ChildId, index,
                                                                                                                        subIndex, childIndex, childTask.Name, childTask.TaskName, childTask.StyleId, childTask.StatusID)}
                                                                                                                >
                                                                                                                    <i className="fa fa-send"></i>
                                                                                                                </button>
                                                                                                                :
                                                                                                                (childTask.TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || childTask.TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || childTask.TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION) && childTask.StatusID === 3 && childTask.IsShowSubmitButton !== 0 && childTask.ManualCloseComment === '' ?
                                                                                                                    <button type="button" className="btn btn-xs btn-success" title='View Submission'
                                                                                                                        onClick={() => OpenProtoFitSamplepopup(childTask.TNAId, childTask.ChildId, index,
                                                                                                                            subIndex, childIndex, childTask.Name, childTask.TaskName, childTask.StyleId, childTask.StatusID)}
                                                                                                                    >
                                                                                                                        <i className="fa fa-eye "></i>
                                                                                                                    </button>

                                                                                                                    :
                                                                                                                    (childTask.TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || childTask.TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || childTask.TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION) && childTask.IsShowSubmitButton === 0 ?
                                                                                                                        <button type="button" className="btn btn-xs btn-success" title='Already Approved' disabled>
                                                                                                                            <i className="fa fa-check"></i>
                                                                                                                        </button>
                                                                                                                        : ''

                                                                                                            }



                                                                                                            {TaskCloseCompleteInfo && TaskCloseCompleteInfo.isAdd !== 0 &&
                                                                                                                (childTask.TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL || childTask.TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL || childTask.TaskName.toLowerCase() === StaticTask.SMS_APPROVAL) && childTask.IsShowSubmitButton === 2 && childTask.StatusID !== 3 ?
                                                                                                                <button type="button" className="btn btn-xs btn-warning" title="Approval Form"
                                                                                                                    onClick={() => OpenProtoFitApprovalpopup(childTask.ChildId, index, subIndex, childIndex, childTask.TaskName, childTask.StatusID)}
                                                                                                                >
                                                                                                                    <i className="fa fa-check"></i>
                                                                                                                </button>
                                                                                                                :
                                                                                                                (childTask.TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL || childTask.TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL || childTask.TaskName.toLowerCase() === StaticTask.SMS_APPROVAL) && childTask.StatusID === 3 && childTask.IsShowSubmitButton !== 0 && childTask.ManualCloseComment === '' ?
                                                                                                                    <button type="button" className="btn btn-xs btn-success" title='View Approval'
                                                                                                                        onClick={() => OpenProtoFitApprovalpopup(childTask.ChildId, index, subIndex, childIndex, childTask.TaskName, childTask.StatusID)}
                                                                                                                    >
                                                                                                                        <i className="fa fa-eye "></i>
                                                                                                                    </button>

                                                                                                                    :
                                                                                                                    (childTask.TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL || childTask.TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL || childTask.TaskName.toLowerCase() === StaticTask.SMS_APPROVAL) && childTask.IsShowSubmitButton === 0 ?
                                                                                                                        <button type="button" className="btn btn-xs btn-success" title='Already Updated' disabled>
                                                                                                                            <i className="fa fa-check"></i>
                                                                                                                        </button>
                                                                                                                        : ''

                                                                                                            }
                                                                                                            {TaskCloseCompleteInfo && TaskCloseCompleteInfo.isAdd !== 0 &&
                                                                                                                childTask.TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH && childTask.IsShowSubmitButton === 2 && childTask.StatusID !== 3 ?
                                                                                                                <button type="button" className="btn btn-xs btn-warning" title="Submit Form"
                                                                                                                    onClick={() => OpenAppearenceAfterWashpopup(childTask.TNAId, childTask.ChildId, childTask.StyleId,
                                                                                                                        index, subIndex, childIndex, childTask.StatusID)}
                                                                                                                >
                                                                                                                    <i className="fa fa-send"></i>
                                                                                                                </button>
                                                                                                                :
                                                                                                                childTask.TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH && childTask.IsShowSubmitButton === 3 && childTask.StatusID !== 3 ?
                                                                                                                    <button type="button" className="btn btn-xs btn-warning" title="Approval Form"
                                                                                                                        onClick={() => OpenAppearenceAfterWashpopup(childTask.TNAId, childTask.ChildId, childTask.StyleId,
                                                                                                                            index, subIndex, childIndex, childTask.StatusID)}
                                                                                                                    >
                                                                                                                        <i className="fa fa-check"></i>
                                                                                                                    </button> :

                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH && childTask.StatusID === 3 && childTask.IsShowSubmitButton !== 0 && childTask.ManualCloseComment === '' ?
                                                                                                                        <button type="button" className="btn btn-xs btn-success" title='View Submission'
                                                                                                                            onClick={() => OpenAppearenceAfterWashpopup(childTask.TNAId, childTask.ChildId, childTask.StyleId,
                                                                                                                                index, subIndex, childIndex, childTask.StatusID)}
                                                                                                                        >
                                                                                                                            <i className="fa fa-eye "></i>
                                                                                                                        </button>

                                                                                                                        // :
                                                                                                                        // childTask.TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH && childTask.IsShowSubmitButton === 0 ?
                                                                                                                        //     <button type="button" className="btn btn-xs btn-success" title='Already Updated' disabled>
                                                                                                                        //         <i className="fa fa-check-square-o"></i>
                                                                                                                        //     </button>
                                                                                                                        : ''

                                                                                                            }
                                                                                                            {/* &nbsp; &nbsp;
                                                                                                                {
                                                                                                                    childTask.ManualFilePath !== '' ?

                                                                                                                        <i className="btn btn-info fa fa-eye" title="View Logo"
                                                                                                                            onClick={
                                                                                                                                event => ViewFile(event, childTask.ManualFilePath)
                                                                                                                            }></i> : ''
                                                                                                                } */}




                                                                                                        </>

                                                                                                        : ''
                                                                                                }
                                                                                            </td>
                                                                                        </tr>
                                                                                    </Fragment>
                                                                                ))
                                                                            }
                                                                        </Fragment>

                                                                    ))

                                                                }

                                                            </Fragment>
                                                        ))
                                                    }
                                                    {
                                                        inputFields.length === 0 ?
                                                            <tr>
                                                                <td colSpan="11" className='norecordfound'><span>No Records Found</span></td>
                                                                {/* {
                                                                    inputFields.length === 0 ?
                                                                        <tr>
                                                                            <td colSpan="10" className='norecordfound'><span>No Records Found</span></td>

                                                                        </tr> : ''
                                                                } */}
                                                            </tr> : ''
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        : <div className="row">
                                            <div className="col-sm-5">
                                            </div>
                                            <div className="col-sm-3">
                                                <span className="danger">No records found</span>
                                            </div>
                                        </div>
                                    }

                                </form>

                            </div>
                        </div>


                        {getLapmodal === true ? (
                            <Modal
                                show={getLapmodal === true}
                                size="xl"
                                dragable
                                backdrop="static"
                                keyboard={false}
                                onHide={() => handleLabDip()}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        {
                                            TaskStatus === 3 ?
                                                "View " : ''
                                        }
                                        Lab Dip  Submission
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <LabdibSubmission props={props}
                                        TNAId={TNAId}
                                        StyleId={StyleId}
                                        LabdibFabricInfoId={LabdibFabricInfoId}
                                        LapdipSubmissionCallback={LapdipSubmissionCallback}
                                        TaskStatus={TaskStatus}
                                        SearchValues={getSearchvaluestoSub}
                                        IsProductionDD={getIsProductionStatussearch.value}
                                    > </LabdibSubmission>
                                </Modal.Body>
                                <Modal.Footer></Modal.Footer>
                            </Modal>
                        ) : (
                            ""
                        )}
                        {getOpenLabdipApppopup === true ? (
                            <Modal
                                show={getOpenLabdipApppopup === true}
                                size="xl"
                                dragable
                                backdrop="static"
                                keyboard={false}
                                onHide={() => handleLabDipApp()}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        {
                                            TaskStatus === 3 ?
                                                "View " : ''
                                        }
                                        Lab Dip  Approval</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <LabdibApproval props={props}
                                        LapdipApprovalCallback={LapdipApprovalCallback}
                                        StyleId={StyleId}
                                        LabdibFabricInfoId={LabdibFabricInfoId}
                                        SubmittedOptionListId={SubmittedOptionListId}
                                        TaskStatus={TaskStatus}
                                        LabdipApprovalList={LabdipApprovalList}
                                        SearchValues={getSearchvaluestoSub}
                                        IsProductionDD={getIsProductionStatussearch.value}
                                    ></LabdibApproval>
                                </Modal.Body>
                                <Modal.Footer></Modal.Footer>
                            </Modal>
                        ) : (
                            ""
                        )}
                        {
                            strikeoffModalpopup === true ?

                                <Modal show={strikeoffModalpopup === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => setStrikeOffmodalpopup(false)}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            {
                                                TaskStatus === 3 ?
                                                    "View " : ''
                                            }
                                            Strike Off submission </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <StrikeOffSubmission props={props}
                                            StrikeoffSubmissionCallback={StrikeoffSubmissionCallback}
                                            TNAId={TNAId}
                                            TNAStrikeOffRefInfoId={TNAStrikeOffRefInfoId}
                                            TaskStatus={TaskStatus}
                                            SearchValues={getSearchvaluestoSub}
                                            IsProductionDD={getIsProductionStatussearch.value}
                                        ></StrikeOffSubmission>
                                    </Modal.Body>
                                    <Modal.Footer>

                                    </Modal.Footer>

                                </Modal> : ''
                        }
                        {
                            getstrikeoffApppopup === true ?

                                <Modal show={getstrikeoffApppopup === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handleStrikeOffApp(false)}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            {
                                                TaskStatus === 3 ?
                                                    "View " : ''
                                            }
                                            Strike Off Approval </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <StrikeApproval props={props}
                                            StrikeoffApprovalCallback={StrikeoffApprovalCallback}
                                            TNAStrikeOffRefInfoId={TNAStrikeOffRefInfoId}
                                            SubmittedOptionListId={SubmittedOptionListId}
                                            ColorAppCommentsId={ColorAppCommentsId}
                                            TaskStatus={TaskStatus}
                                            SearchValues={getSearchvaluestoSub}
                                            IsProductionDD={getIsProductionStatussearch.value}
                                        ></StrikeApproval>
                                    </Modal.Body>
                                    <Modal.Footer>

                                    </Modal.Footer>

                                </Modal> : ''
                        }

                        {
                            getTrimmodalpopup === true ?

                                <Modal show={getTrimmodalpopup === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handletrimmPopupClose()}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            {
                                                TaskStatus === 3 ?
                                                    "View " : ''
                                            }Trim Submission </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>

                                        <TrimSubmission props={props}
                                            TrimSubmissionCallback={TrimSubmissionCallback}
                                            TNAId={TNAId}
                                            TNATrimRefInfoId={TNATrimRefInfoId}
                                            TaskStatus={TaskStatus}
                                            SearchValues={getSearchvaluestoSub}
                                            IsProductionDD={getIsProductionStatussearch.value}
                                        ></TrimSubmission>


                                    </Modal.Body>
                                    <Modal.Footer>

                                    </Modal.Footer>

                                </Modal> : ''
                        }
                        {
                            getTrimApprovalpopup === true ?

                                <Modal show={getTrimApprovalpopup === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handleTrimappPopupClose()}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            {
                                                TaskStatus === 3 ?
                                                    "View " : ''
                                            }Trim Approval </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>

                                        <TrimApproval props={props}
                                            TrimApprovalCallback={TrimApprovalCallback}
                                            TNATrimRefInfoId={TNATrimRefInfoId}
                                            SubmittedOptionListId={SubmittedOptionListId}
                                            ColorAppCommentsId={ColorAppCommentsId}
                                            TaskStatus={TaskStatus}
                                            SearchValues={getSearchvaluestoSub}
                                            IsProductionDD={getIsProductionStatussearch.value}
                                        ></TrimApproval>

                                    </Modal.Body>
                                    <Modal.Footer>

                                    </Modal.Footer>

                                </Modal> : ''
                        }

                        {
                            getSampleSubModalpopup === true ?

                                <Modal show={getSampleSubModalpopup === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handleprotoSubApp(false)}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            {
                                                TaskStatus === 3 ?
                                                    "View " : ''
                                            }
                                            {ParentTask} </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <ProtoFitSMSSubmission props={props}
                                            ProtoFitSMSSubmissionCallback={ProtoFitSMSSubmissionCallback}
                                            TNAId={TNAId}
                                            TNAProtoFitId={TNAProtoFitId}
                                            ChildTask={ChildTask}
                                            ParentTask={ParentTask}
                                            StyleId={StyleId}
                                            TaskStatus={TaskStatus}
                                        ></ProtoFitSMSSubmission>
                                    </Modal.Body>
                                    <Modal.Footer>

                                    </Modal.Footer>

                                </Modal> : ''
                        }
                        {
                            getSampleAppModalpopup === true ?

                                <Modal show={getSampleAppModalpopup === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handleprotofitApp(false)}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            {
                                                TaskStatus === 3 ?
                                                    "View " : ''
                                            }
                                            {ParentTask} </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <ProtoFitSMSApproval props={props}
                                            ProtoFitAprovalCallback={ProtoFitAprovalCallback}
                                            TNAId={TNAId}
                                            TNAProtoFitId={TNAProtoFitId}
                                            ChildTask={ChildTask}
                                            ParentTask={ParentTask}
                                            TaskStatus={TaskStatus}
                                        ></ProtoFitSMSApproval>
                                    </Modal.Body>
                                    <Modal.Footer>

                                    </Modal.Footer>

                                </Modal> : ''
                        }
                        {
                            getApperancemodalpopup === true ?

                                <Modal show={getApperancemodalpopup === true} size="xl" dragable backdrop="static" dialogClassName="commonwidth"
                                    keyboard={false} onHide={() => HandleAfterWashList(false)}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            {
                                                TaskStatus === 3 ?
                                                    "View " : ''
                                            }
                                            Appearance After Wash</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <AfterWashList props={props}
                                            AppareanceTestSubmissionCallback={AppareanceTestSubmissionCallback}
                                            TNAId={TNAId}
                                            StyleId={StyleId}
                                            AppareanceTestId={AppareanceTestId}
                                            TaskStatus={TaskStatus}
                                        ></AfterWashList>
                                    </Modal.Body>
                                    <Modal.Footer>

                                    </Modal.Footer>

                                </Modal> : ''

                        } {getID.showPopupDelete ? <SweetAlertPopup
                            // data={''}
                            data={
                                getID.Params
                            }
                            deleteCallback={DeleteCallback}
                            showpopup={true}
                            pageActionId={getID.TNAManualClose}
                            Msg={'Deleted Successfully'}
                        /> : null}
                        <div> {
                            isOpenimg && (

                                <Lightbox mainSrc={window.$APIBaseURL + getImagename}
                                    alt="bg image"
                                    onCloseRequest={
                                        () => setIsOpenimg(false)
                                    } />
                            )
                        } </div>
                        <div>{
                            getIDQATask.showPopupDelete ? <SweetAlertPopup data={
                                getIDQATask.Params
                            }
                                deleteCallback={DeleteCallbackQA}
                                showpopup={true}
                                pageActionId={QAInspectionId}
                                Msg={"QA Inspection deleted successfully"} /> : null
                        }</div>
                        {
                            getIDSingleTask.showPopupDelete ? <SweetAlertPopup data={
                                getIDSingleTask.Params
                            }
                                deleteCallback={delCallbackFinal}
                                showpopup={true}
                                pageActionId={FinalinspectionID}
                                Msg={"Final Inspection deleted successfully"} /> : null
                        }
                        <div>{
                            getIDTestTask.showPopupDelete ? <SweetAlertPopup data={
                                getIDTestTask.Params
                            }
                                deleteCallback={delCallbackTest}
                                showpopup={true}
                                pageActionId={TestingMainlist}
                                Msg={"Test Inspection deleted successfully"} /> : null
                        }</div>
                        {
                            isSkuAndStyleDetailPopup &&
                            <Modal show={isSkuAndStyleDetailPopup} size="lg" dragable backdrop="static" keyboard={false} onHide={() => hideSkuAndStyleDetail()}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        SKU and Size Details
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>SKU Name</th>
                                                <th>Size Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{SkuAndStyleDetails.skuNameList}</td>
                                                <td>{SkuAndStyleDetails.sizeNameList ? SkuAndStyleDetails.sizeNameList : " - "}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button type="button" className="btn btn-danger" onClick={hideSkuAndStyleDetail}>
                                        <i className="fa fa-times"></i>&nbsp;
                                        Close
                                    </button>
                                </Modal.Footer>
                            </Modal>
                        }
                        {/* </div> */}



                    </div>
                </div>
            </div >
            {loader}
        </Fragment >


    );
}
export default EditTNAReport;
