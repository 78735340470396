import {
    SUPPLIERINFO_LOADING,
    RETRIEVEINFO_SUPPLIER,
    DELETEINFO_SUPPLIER,
} from "./types";

import Companyservice from "../services/Master/Companyservice";

const supplierLoading = (isStatus) => ({
    type: SUPPLIERINFO_LOADING,
    payload: isStatus,
});

export const deleteSupplier = (companyBuyerSupID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETEINFO_SUPPLIER,
            payload: companyBuyerSupID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrieveSupplier = (parmas) => async (dispatch) => {
    try {
        dispatch(supplierLoading(true));
        const res = await Companyservice.getAllCompanyBuyerSupList(parmas.companyid, parmas.tabletype);
        dispatch({
            type: RETRIEVEINFO_SUPPLIER,
            payload: res.data,
        });
        dispatch(supplierLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(supplierLoading(false));
    }
};