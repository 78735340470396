import {
    SCHEDULEREPORT_LOADING,
    RETRIEVE_SCHEDULEREPORT
} from "../actions/types";

const initialState = {  
    isLoadingStyle: true,
    schedulereportList: []
};

const ScheduleReportReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SCHEDULEREPORT_LOADING:
            return {
                ...state,
                isLoadingStyle: payload
            };
        case RETRIEVE_SCHEDULEREPORT:
            return { ...state, schedulereportList: payload };
        default:
            return state;
    }
}
export default ScheduleReportReducer;