import axios from "axios";
import authHeader from "../auth-header";

class ColorDepthService {
    GetColorDepthList() {
        return axios.get(window.$APIBaseURL + "Master/ColorDepthList", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    UpdateColorDepth(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/UpdateColorDepth',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertColorDepth(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertColorDepth',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
}
export default new ColorDepthService()