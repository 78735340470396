import axios from "axios";
import authHeader from "../auth-header";
class scheduleReportService {
    GetScheduleReportList(StyleID, IsProduction, employeeinformationID, PoStartDate, PoEndDate) {
        return axios.get(window.$APIBaseURL + "ScheduleReport/GetScheduleReportList?StyleID=" + StyleID + "&IsProduction=" + IsProduction + "&EmployeeinformationID=" + employeeinformationID + "&PoStartDate=" + PoStartDate + "&PoEndDate=" + PoEndDate, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetPurchaseOrderList(PurchaseOrderID, IsProduction) {
        return axios.get(window.$APIBaseURL + "ScheduleReport/GetPurchaseOrderList?PurchaseOrderID=" + PurchaseOrderID + "&IsProduction=" + IsProduction, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetScheduleTNAList(Operation,TNAStartDate, TNAEndDate) {
        return axios.get(window.$APIBaseURL + "ScheduleReport/GetScheduleTNAList?Operation=" + Operation + "&TNAStartDate=" + TNAStartDate + "&TNAEndDate=" + TNAEndDate, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    



}
export default new scheduleReportService();