import React, { useState, useEffect, Fragment, useRef } from "react";
import "react-notifications-component/dist/theme.css";
import Form from "react-validation/build/form";
import UploadImage from "../../Common/UploadImage";
import Select from "react-validation/build/select";
import PantoneService from "../../../services/Master/PantoneService";
import StrikeoffService from "../../../services/Style/StrikeoffService";
import Nodify from "../../Common/ReactNotification";
import { Modal, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Draggable from "react-draggable";
import ModalDialog from "react-bootstrap/ModalDialog";
import moment from "moment";
import CollapsiblePanel from "../../Common/CollapseExpandPanel";
import PantoneTypePopup from "../../quickMaster/_PantoneType";
import PantonePopup from "../../quickMaster/_Pantone";
import SwatchPopup from "../../quickMaster/_Swatch";
import { useSelector } from "react-redux";
import Lightbox from "react-image-lightbox";
const PrintColor = ({
  props,
  ColorCallBack,
  IsValidationCallback,
  PrintColorDetails,
  IsView,
  Id,
  BuyerID,
  BrandID,
  getHeaderName,
  Buttonview,
  StrikeoffInfoQuick,
  PageName
}) => {
  //Enable Menu
  let activeMenu;
  const Mainmenulist = useSelector((state) => state.auth.UserMenuList.item3);
  const Submenulist = useSelector((state) => state.auth.UserMenuList.item5);
  if (Mainmenulist !== undefined && Submenulist !== undefined) {
    if (PageName === "MasterStrikeoff") {
      activeMenu = Mainmenulist.filter(i => i.menuName === "StrikeoffMaster");
    }
    else if (PageName === 'StyleStrikeoff') {
      activeMenu = Submenulist.filter(i => i.menuName === "StyleStrikeoff");

    }
  }


  // End Enable Menu style={{ display: activeMenu[0].isDelete === 1 ? '' : 'none' }}"StrikeoffMaster" "StyleStrikeoff"

  const [inputFields, setInputFields] = useState([
    {
      ColorGroupID: 0,
      StrikeoffLogoPath: "",
      StrikeoffLogoName: "",
      ColorGroupList: [
        {
          ColorID: 0,
          SysNo: 1,
          PantoneType: 0,
          PantoneTypeName: '',
          PantoneName: 0,
          PantoneColor: "",
          IndexName: "0",
          Critical: 0,
          IsCriticalOpen: 0,
          Notes: "",
          TargettoClose: null,
          CompletedOn: null,
          RemindMe: 0,
          SwatchName: '',
          IsSaved: 0,
          SwatchImagePath: ''

        },
      ],
      IndexName: "0",
      IsDeleted: 0,
    },
  ]);





  const [submitted, setSubmitted] = useState(false);
  const [criticSubmitted, setcriticSubmitted] = useState(false)
  const [PantonetypeList, setPantonetypeList] = useState([]);
  const [AllPantonetypeList, setAllPantonetypeList] = useState([]);
  const [getPantone, setPantone] = useState([]);
  const [getSwatch, setSwatch] = useState([]);

  const [collapse, setCollapse] = useState(false);
  const [title, setTitle] = useState("Collapse All");
  const [icon, setIcon] = useState("fa fa-chevron-down");
  const [isOpen, setIsOpen] = useState(false)
  const collapseAll = () => {
    setCollapse(!collapse);
    setIcon((state) => {
      return state === "fa fa-chevron-right"
        ? "fa fa-chevron-down"
        : "fa fa-chevron-right";
    });
    setTitle((state) => {
      return state === "Expand All" ? "Collapse All" : "Expand All";
    });
  };

  const [getSwatchSelected, setSwatchSelected] = useState(false);

  //#region Popup Initialization
  const [isPantonetypePopupOpen, setIsPantonetypePopupOpen] = useState(false);
  const [isPantonePopupOpen, setIsPantonePopupOpen] = useState(false);
  const [isSwatchPopupOpen, setIsSwatchPopupOpen] = useState(false);

  //#endregion
  const values = [...inputFields];

  const handleClose = (index, colorindex, Feild) => {
    if (Feild === "Save") {
      if (values[index].ColorGroupList[colorindex].Notes === '' ||
        values[index].ColorGroupList[colorindex].TargettoClose === null ||
        values[index].ColorGroupList[colorindex].RemindMe === 0) {

        Nodify('Warning!', 'warning', "Please fill the mandatory(*) fields");
        values[index].ColorGroupList[colorindex].IsCriticalOpen = 1;
        //setSubmitted(true);
        setcriticSubmitted(true)


      }
      else {
        values[index].ColorGroupList[colorindex].IsSaved = 1;
        values[index].ColorGroupList[colorindex].IsCriticalOpen = 0;
        Nodify('Success!', 'success', "Critical comments Saved Successfully");
        setcriticSubmitted(false)

      }
    }
    // if (Feild !== "Close") {
    //   values[index].ColorGroupList[colorindex].IsSaved = 1;
    //   values[index].ColorGroupList[colorindex].IsCriticalOpen = 0;

    // }
    //else if (Feild === "Close" && values[index].ColorGroupList[colorindex].IsSaved !== 1 && values[index].ColorGroupList[colorindex].IsSaved !== undefined) {
    // 28-12-2023 Changed By Ajay
    else if (Feild === "Close" && values[index].ColorGroupList[colorindex].IsSaved === undefined && values[index].ColorGroupList[colorindex].IsSaved === undefined) {
      values[index].ColorGroupList[colorindex].Notes = '';
      values[index].ColorGroupList[colorindex].TargettoClose = null;
      values[index].ColorGroupList[colorindex].CompletedOn = null;
      values[index].ColorGroupList[colorindex].RemindMe = 0;
      values[index].ColorGroupList[colorindex].IsCriticalOpen = 0;
      setcriticSubmitted(false)
    } else {
      values[index].ColorGroupList[colorindex].IsCriticalOpen = 0;
      setcriticSubmitted(false)
    }
    setInputFields(values);
    ColorCallBack(values);
  };



  useEffect(() => {
    PantoneService.getPantonelist(1, 0, 0).then((response) => {
      if (response.data) {
        setPantonetypeList(response.data);
      }
    });

    PantoneService.loadPantoneTypeList().then((response) => {
      if (response.data) {
        setAllPantonetypeList(response.data);
      }
    }).catch(() => { });

    PantoneService.LoadPantoneList().then((response) => {
      if (response.data) {
        setPantone(response.data);
      }
    });
  }, []);

  useEffect(() => {
    setSubmitted(IsValidationCallback);
  }, [IsValidationCallback === true]);

  //const [getLoad, setLoad] = useState(false);

  useEffect(() => {

    PantoneService.LoadSwatchList(BuyerID, BrandID).then((response) => {
      if (response.data.length > 0) {
        setSwatch(response.data);
      }
    });

    if (PrintColorDetails !== undefined) {
      if (PrintColorDetails.colorGroup !== undefined) {
        if (PrintColorDetails.color.length !== 0) {
          if (PrintColorDetails.colorGroup.length > 0) {
            PrintColorDetails.colorGroup.forEach((groupInfo, i) => {
              inputFields.push({
                ColorGroupID: groupInfo.colorGroupID,
                StrikeOffID: groupInfo.strikeOffID,
                ColorGroup: groupInfo.colorGroupID,
                StrikeoffLogoPath: groupInfo.strikeoffLogoPath,
                StrikeoffLogoName: groupInfo.strikeoffLogoName,
                IndexName: groupInfo.indexName,
                IsDeleted: 0,
                ColorGroupList: [],
              });
            });
          }

          // let Pantone;
          if (PrintColorDetails.color.length > 0) {
            debugger
            PrintColorDetails.color.forEach((element, i) => {
              inputFields.forEach((groupInfo, j) => {
                if (parseInt(groupInfo.IndexName) === parseInt(element.indexName)) {
                  let getPantoneName = PantonetypeList.filter(x => x.pantoneTypeID === parseInt(element.pantoneType));

                  inputFields[j].ColorGroupList.push({
                    ColorID: element.colorID,
                    StrikeOffID: element.strikeOffID,
                    SysNo: element.sysNo,
                    PantoneType: element.pantoneType,
                    PantoneTypeName: getPantoneName.length !== 0 ? getPantoneName[0].pantoneTypeName : '',
                    PantoneName: element.pantoneName,
                    PantoneColor: element.pantoneColor,
                    IndexName: element.indexName,
                    Critical: element.critical,
                    IsCriticalOpen: element.isCriticalOpen,
                    Notes: element.notes,
                    TargettoClose:
                      element.targettoClose === null
                        ? null
                        : new Date(element.targettoClose),
                    CompletedOn:
                      element.completedOn === null
                        ? null
                        : new Date(element.completedOn),
                    RemindMe: element.remindMe,
                    // IsSaved: 0,
                  });
                }
              });
            });

          }

          setInputFields(inputFields);

        }
      }
      ColorCallBack(props.location.state === undefined ? inputFields : inputFields.filter(x => x.ColorGroupID !== 0));
    }

    if (PrintColorDetails !== undefined) {
      if (PrintColorDetails.colorGroup !== undefined) {
        if (PrintColorDetails.color.length !== 0) {
          if (PrintColorDetails.colorGroup.length > 0) {
            const values = [...inputFields];
            values.splice(0, 1);
            setInputFields(values);
          }
        }
      }
    }

    // setLoad(true);
  }, [PrintColorDetails]);

  // useEffect(() => {
  //   // Load the swatch list from the service
  //   PantoneService.LoadSwatchList(BuyerID, BrandID).then((response) => {
  //     if (response.data.length > 0) {
  //       setSwatch(response.data);
  //     }
  //   });

  //   // Exit early if PrintColorDetails is not defined
  //   if (!PrintColorDetails || !PrintColorDetails.colorGroup || PrintColorDetails.color.length === 0) {
  //     return;
  //   }

  //   const newInputFields = [];

  //   // Populate inputFields based on colorGroup
  //   if (PrintColorDetails.colorGroup.length > 0) {
  //     PrintColorDetails.colorGroup.forEach((groupInfo) => {
  //       newInputFields.push({
  //         ColorGroupID: groupInfo.colorGroupID,
  //         StrikeOffID: groupInfo.strikeOffID,
  //         ColorGroup: groupInfo.colorGroupID,
  //         StrikeoffLogoPath: groupInfo.strikeoffLogoPath,
  //         StrikeoffLogoName: groupInfo.strikeoffLogoName,
  //         IndexName: groupInfo.indexName,
  //         IsDeleted: 0,
  //         ColorGroupList: [],
  //       });
  //     });
  //   }

  //   // Process the color data and map it to inputFields
  //   if (PrintColorDetails.color.length > 0) {
  //     debugger
  //     newInputFields.forEach((groupInfo) => {
  //       // Filter for matching index names
  //       const matchingElements = PrintColorDetails.color.filter(
  //         (element) => parseInt(groupInfo.IndexName, 10) === parseInt(element.indexName, 10)
  //       );

  //       // Iterate over matching elements and push to ColorGroupList only if it's unique
  //       matchingElements.forEach((element) => {
  //         const pantoneMatch = PantonetypeList.find(
  //           (x) => x.pantoneTypeID === parseInt(element.pantoneType, 10)
  //         );

  //         // Check if the element already exists in the ColorGroupList by its unique identifier (e.g., ColorID or PrimaryKeyID)
  //         const isAlreadyInList = groupInfo.ColorGroupList.some(
  //           (existingItem) => existingItem.ColorID === element.colorID
  //         );

  //         // If the item is not already in the list, push it
  //         if (!isAlreadyInList) {
  //           groupInfo.ColorGroupList.push({
  //             ColorID: element.colorID,
  //             StrikeOffID: element.strikeOffID,
  //             SysNo: element.sysNo,
  //             PantoneType: element.pantoneType,
  //             PantoneTypeName: pantoneMatch ? pantoneMatch.pantoneTypeName : '',
  //             PantoneName: element.pantoneName,
  //             PantoneColor: element.pantoneColor,
  //             IndexName: element.indexName,
  //             Critical: element.critical,
  //             IsCriticalOpen: element.isCriticalOpen,
  //             Notes: element.notes,
  //             TargettoClose: element.targettoClose ? new Date(element.targettoClose) : null,
  //             CompletedOn: element.completedOn ? new Date(element.completedOn) : null,
  //             RemindMe: element.remindMe,
  //           });
  //         }
  //       });
  //     });
  //   }


  //   // Update inputFields state
  //   setInputFields(newInputFields);

  //   // Callback with inputFields excluding ColorGroupID === 0 if required
  //   ColorCallBack(
  //     props.location.state === undefined
  //       ? newInputFields
  //       : newInputFields.filter((x) => x.ColorGroupID !== 0)
  //   );

  //   //If there is colorGroup data, remove the first element in inputFields and update the state
  //   if (PrintColorDetails !== undefined) {
  //     if (PrintColorDetails.colorGroup !== undefined) {
  //       if (PrintColorDetails.color.length !== 0) {
  //         if (PrintColorDetails.colorGroup.length > 0) {
  //           const values = [...inputFields];
  //           values.splice(0, 1);
  //           setInputFields(values);
  //         }
  //       }
  //     }
  //   }
  // }, [PrintColorDetails]);


  if (BuyerID === 0 || BrandID === 0) {
    for (var i = 0; i < values[0].ColorGroupList.length; i++) {
      if (values[0].ColorGroupList[i].PantoneTypeName === "Others") {
        values[0].ColorGroupList[i].PantoneType = 0;
        values[0].ColorGroupList[i].PantoneTypeName = "";
        values[0].ColorGroupList[i].PantoneName = "";
        values[0].ColorGroupList[i].PantoneColor = "";
      }
    }
  }


  const Drag = (props) => {
    return (
      <Draggable handle=".modal-title">
        <ModalDialog {...props} />
      </Draggable>
    );
  };

  const handleRemoveFields = (index) => {
    if (values[index].ColorGroupID === 0) {
      values.splice(index, 1);
      setInputFields(values);
      ColorCallBack(values);
    } else {
      StrikeoffService.CheckColorGroupMapped(
        1,
        parseInt(values[index].ColorGroupID)
      ).then((response) => {
        if (response.data) {
          if (response.data.outputResult === "-1") {
            Nodify(
              "Warning!",
              "warning",
              "This print color is already mapped!"
            );
            return false;
          } else {
            // values[index].IsDeleted = 1;
            values.splice(index, 1);
            setInputFields(values);
            ColorCallBack(values);
          }
        }
      });
    }
  };

  const handleAddFields = (index) => {
    let IsValid = true;
    var CheckValue1 = [...inputFields].filter((x) => x.IsDeleted === 0);
    if (CheckValue1[index].StrikeoffLogoPath !== "") {
      for (let item of CheckValue1[index].ColorGroupList) {
        if (item.PantoneType === 0 || item.PantoneName === 0) {
          Nodify("Warning!", "warning", "Please fill the mandatory(*) fields.");
          setSubmitted(true);
          return false;
        }
      }
      values.push({
        ColorGroupID: 0,
        StrikeoffLogoPath: "",
        StrikeoffLogoName: "",
        ColorGroupList: [
          {
            ColorID: 0,
            SysNo: 1,
            PantoneType: 0,
            PantoneName: 0,
            PantoneColor: "",
            IndexName: (index + 1).toString(),
            Critical: 0,
            IsCriticalOpen: 0,
            Notes: "",
            TargettoClose: null,
            CompletedOn: null,
            RemindMe: 0,
            IsSaved: 0,
          },
        ],
        IndexName: (index + 1).toString(),
        IsDeleted: 0,
      });
      setInputFields(values);
      ColorCallBack(values);
      setSubmitted(false);
    } else {
      for (let item of CheckValue1[index].ColorGroupList) {
        if (item.PantoneType === 0 || item.PantoneName === 0) {
          Nodify("Warning!", "warning", "Please fill the mandatory(*) fields.");
          setSubmitted(true);
          return false;
        }
      }
      Nodify("Warning!", "warning", "Please upload the Image!");
      setSubmitted(true);
      return false;
    }
  };

  const CheckDuplicate = (index, colorindex, FieldName) => {
    let ColorGroupList = values[index].ColorGroupList;
    for (let item = 0; item < ColorGroupList.length; item++) {
      if (item !== colorindex) {
        if (
          ColorGroupList[colorindex].PantoneType ===
          ColorGroupList[item].PantoneType &&
          ColorGroupList[colorindex].PantoneName ===
          ColorGroupList[item].PantoneName
        ) {
          values[index].ColorGroupList[colorindex][FieldName] = 0;
          values[index].ColorGroupList[colorindex].PantoneColor = "";
          Nodify("Warning!", "warning", "This value is already exist!");
          setSubmitted(true);
          return false;
        }
      }
    }
  };

  const onFocus = (e) => {
    var val = e.target.value;
    e.target.value = "";
    e.target.value = val;
  };

  const AddColorFields = (index, colorindex) => {
    let IsValid = true;
    let ColorGroupList = values[index].ColorGroupList;
    for (let item of ColorGroupList) {
      if (item.PantoneType === 0 || item.PantoneName === 0) {
        IsValid = false;
        setSubmitted(true);
      }
      else {
        IsValid = true;
        setSubmitted(false);
      }
    }
    if (IsValid) {
      values[index].ColorGroupList.push({
        ColorID: 0,
        SysNo: parseInt(values[index].ColorGroupList[colorindex].SysNo) + 1,
        PantoneType: 0,
        PantoneName: 0,
        PantoneColor: "",
        IndexName: index.toString(),
        Critical: 0,
        IsCriticalOpen: 0,
        Notes: "",
        TargettoClose: null,
        CompletedOn: null,
        RemindMe: 0,
        IsSaved: 0,
      });
      setInputFields(values);
      ColorCallBack(values);
    }
    else {
      Nodify("Warning!", "warning", "Please fill the mandatory(*) fields.");
      setSubmitted(true);
      return false;
    }
  };

  const RemoveColorFields = (index, colorindex) => {
    values[index].ColorGroupList.splice(colorindex, 1);
    setInputFields(values);
    ColorCallBack(values);
  };

  const DateChange = (event, index, colorindex, FieldName) => {
    let Date = event;
    if (FieldName === "TargettoClose") {
      values[index].ColorGroupList[colorindex].TargettoClose = Date;
    } else if (FieldName === "CompletedOn") {
      values[index].ColorGroupList[colorindex].CompletedOn = Date;
    }
    setInputFields(values);
    ColorCallBack(values);
  };

  const ClearCritical = (event, index, colorindex) => {
    values[index].ColorGroupList[colorindex].Notes = "";
    values[index].ColorGroupList[colorindex].TargettoClose = null;
    values[index].ColorGroupList[colorindex].CompletedOn = null;
    values[index].ColorGroupList[colorindex].RemindMe = 0;
    values[index].ColorGroupList[colorindex].IsSaved = 0
    Nodify('Success!', 'success', 'Comment deleted successfully');
    setInputFields(values);
    ColorCallBack(values);
  };

  const colorInputChange = (event, index, colorindex) => {
    ;
    //setSubmitted(false);

    let inputText = "";
    if (event.target.type === "checkbox") {
      if (!event.target.checked) {
        values[index].ColorGroupList[colorindex][event.target.name] = 0;
      } else {
        values[index].ColorGroupList[colorindex][event.target.name] = 1;
      }
    } else {
      if (event.target.value.trim() !== "") {
        inputText = event.target.value;
      }
      if (
        event.target.name === "PantoneName" ||
        event.target.name === "PantoneType" ||
        event.target.name === "RemindMe"

      ) {
        inputText = parseInt(inputText);
      }
      values[index].ColorGroupList[colorindex][event.target.name] = inputText;
    }
    if (event.target.name === "PantoneType") {
      if (inputText === 0) {
        values[index].ColorGroupList[colorindex].PantoneName = 0;
        values[index].ColorGroupList[colorindex].PantoneColor = "";
      }
      else {
        values[index].ColorGroupList[colorindex].PantoneName = 0;
        values[index].ColorGroupList[colorindex].PantoneColor = "";
        var Pantonetype = PantonetypeList.filter(x => x.pantoneTypeID === parseInt(inputText));
        values[index].ColorGroupList[colorindex].PantoneTypeName = Pantonetype[0].pantoneTypeName;

        if (Pantonetype[0].pantoneTypeName === "Others") {
          if (BuyerID === 0 || BrandID === 0) {
            values[index].ColorGroupList[colorindex].PantoneType = 0;
            values[index].ColorGroupList[colorindex].PantoneTypeName = "";
            values[index].ColorGroupList[colorindex].PantoneColor = "";
            Nodify("Warning!", "warning", "Please Select Buyer and Brand");
            // setSubmitted(true);
            return false;
          }
          else {

            PantoneService.LoadSwatchList(BuyerID, BrandID).then((response) => {
              if (response.data) {
                setSwatch(response.data);
              }
            });
            setSwatchSelected(true);
          }
        }
        else {

          setSwatchSelected(false);
        }
      }
    }

    // if (event.target.name === "SwatchName") {
    //   if (inputText === 0) {
    //     values[index].ColorGroupList[colorindex].SwatchName = 0;
    //     values[index].ColorGroupList[colorindex].SwatchImagePath = "";
    //   }
    //   else {
    //     var Swatch = getSwatch.filter(x => x.id === parseInt(values[index].ColorGroupList[colorindex].SwatchName));
    //     values[index].ColorGroupList[colorindex].SwatchImagePath = Swatch[0].label;
    //   }
    // }

    if (event.target.name === "PantoneName") {
      if (event.target.value !== "0") {
        if (values[index].ColorGroupList[colorindex].PantoneTypeName !== "Others") {
          let respected_Pantone = getPantone.filter(
            (x) => x.pantoneID === parseInt(event.target.value)
          );
          values[index].ColorGroupList[colorindex].PantoneColor =
            respected_Pantone[0].pantoneColor;
        }
        else {
          var Swatch = getSwatch.filter(x => x.id === parseInt(values[index].ColorGroupList[colorindex].PantoneName));
          if (Swatch.length !== 0) {
            values[index].ColorGroupList[colorindex].PantoneColor = Swatch[0].label;
          }
        }
      }
      else {
        values[index].ColorGroupList[colorindex].PantoneColor = "";
      }
    }
    setInputFields(values);
    if (event.target.name !== "Notes") {
      ColorCallBack(values);
    }
  };

  const ShowCritical = (index, colorindex) => {
    values[index].ColorGroupList[colorindex].IsCriticalOpen = 1;
    setInputFields(values);
    ColorCallBack(values);
  };

  const ImgCallBack = (value, index) => {
    values[index].StrikeoffLogoName = value;
    values[index].StrikeoffLogoPath = value;
    setInputFields(values);
    ColorCallBack(values);
  };

  const handleAddPantoneType = () => {
    setIsPantonetypePopupOpen(true);
  };

  const handleAddPantone = () => {
    setIsPantonePopupOpen(true);
  };
  const handleAddSwatch = () => {
    setIsSwatchPopupOpen(true);
  }


  //#region PopupCallBack
  const pantoneTypeCallback = (value) => {
    if (value === true) {
      PantoneService.getPantonelist(1, 0, 0).then((response) => {
        if (response.data) {
          setPantonetypeList(response.data);
        }
      });

      PantoneService.loadPantoneTypeList().then((response) => {
        if (response.data) {
          setAllPantonetypeList(response.data);
        }
      }).catch(() => { });

      setIsPantonetypePopupOpen(false);
    } else {
      setIsPantonetypePopupOpen(false);
    }
  };

  const pantoneCallback = (value) => {
    if (value === true) {
      PantoneService.getPantonelist(1, 0, 0).then((response) => {
        if (response.data) {
          setPantonetypeList(response.data);
        }
      });

      PantoneService.LoadPantoneList().then((response) => {
        if (response.data) {
          setPantone(response.data);
        }
      });

      setIsPantonePopupOpen(false);
    } else {
      setIsPantonePopupOpen(false);
    }
  };

  const swatchCallback = (value) => {
    if (value === true) {
      PantoneService.LoadSwatchList(BuyerID, BrandID).then((response) => {
        if (response.data.length > 0) {
          setSwatch(response.data);
        }
      }).catch(() => { });

      setIsSwatchPopupOpen(false);
    } else {
      setIsSwatchPopupOpen(false);
    }
  };
  //#endregion Popup Callback

  const CustomInput = (props) => {
    return (
      <input
        className="form-control DatePickerCalenderHoliday"
        onClick={props.onClick}
        value={props.value}
        type="text"
        readOnly={true}
        placeholder="DD/MM/YYYY"
      //style={{ border: submitted && props.value === ''  ? "1px solid red" : "", }}
      />
    );
  };

  const CustomInputDate = (props) => {
    return (
      <input
        className="form-control DatePickerCalenderHoliday"
        onClick={props.onClick}
        value={props.value}
        type="text"
        readOnly={true}
        placeholder="DD/MM/YYYY"
        style={{ border: criticSubmitted && props.value === '' ? "1px solid red" : "", }}
      />
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div
            className="buttons-preview"
            align="right"
            style={{ marginBottom: "15px" }}
          >

            {getHeaderName === "Add" ? '' : <button
              type="button"
              className="btn btn-success"
              onClick={collapseAll}
            >
              <i className={icon} /> {title}
            </button>}
          </div>
        </div>
      </div>
      {inputFields.map((inputField, index) => (
        <Fragment key={`${inputField}~${index}`}>
          <div
            className={"col-lg-10 col-sm-8 col-xs-12"}
            style={{ display: inputField.IsDeleted === 1 ? "none" : "block" }}
          >
            <div className="widget flat radius-bordered">
              <CollapsiblePanel
                title={
                  inputField.ColorGroup === undefined
                    ? "New Color Group"
                    : "Print Color " + (index + 1)
                }
                collapse={
                  inputField.ColorGroup === undefined ? false : collapse
                }
              >
                <span>
                  <div className="widget-body">
                    <div id="registration-form">
                      <Form role="form">
                        <div className="row">
                          <div className="col-md-12">
                            {inputField.ColorGroupList.length !== 0 && (
                              <>
                                <table
                                  className="table table-striped table-bordered table-hover dataTable no-footer"
                                  id="editabledatatable"
                                >
                                  <thead>
                                    <tr>
                                      <th style={{ textAlign: "center" }}>
                                        <span className="">
                                          Color Information
                                        </span>
                                      </th>
                                      <th style={{ textAlign: "center" }}>
                                        <span className="">
                                          Strike Off Image
                                          <span className="text-danger">*</span>
                                        </span>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <table
                                          className="table table-striped table-bordered table-hover dataTable no-footer"
                                          id="editabledatatable"
                                        >
                                          <thead>
                                            <tr>
                                              {/* <th>#</th> */}
                                              <th>
                                                {StrikeoffInfoQuick.PantoneType.length > 0 && StrikeoffInfoQuick.PantoneType[0].isQuick === 1 && Buttonview !== "View" &&
                                                  <button type='button' className="glyphicon glyphicon-plus AddPlusIcon" style={{ backgroundColor: "#222d32" }}
                                                    onClick={() => handleAddPantoneType()} title="Add Pantone Type">

                                                  </button>
                                                }
                                                {/* <a onClick={() => handleAddPantoneType()} title="Add Pantone Type">
                                                  <i className="fa fa-plus"></i>
                                                </a>
                                                &nbsp; */}
                                                Pantone Type
                                                <span className="text-danger">
                                                  *
                                                </span>

                                              </th>
                                              <th>
                                                {StrikeoffInfoQuick.Pantone.length > 0 && StrikeoffInfoQuick.Pantone[0].isQuick === 1 && Buttonview !== "View" &&

                                                  getSwatchSelected === false ?
                                                  <button type='button' className="glyphicon glyphicon-plus AddPlusIcon" style={{ backgroundColor: "#222d32" }}
                                                    onClick={() => handleAddPantone()} title="Add Pantone">
                                                  </button> : ''

                                                }
                                                Pantone Code /

                                                {StrikeoffInfoQuick.Swatch.length > 0 && StrikeoffInfoQuick.Swatch[0].isQuick === 1 && Buttonview !== "View" &&
                                                  getSwatchSelected === true ?
                                                  <button type='button' className="glyphicon glyphicon-plus AddPlusIcon" style={{ backgroundColor: "#222d32" }}
                                                    onClick={() => handleAddSwatch()} title="Add Swatch">
                                                  </button> : ''
                                                }

                                                &nbsp;Swatch Name<span className="text-danger">*</span></th>


                                              <th>Pantone Color / Swatch Image</th>
                                              <th style={{ width: "17%" }}>
                                                Action
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {
                                              inputField.ColorGroupList.map(
                                                (colorGroup, colorindex) => (
                                                  <>
                                                    <tr>
                                                      <td>
                                                        <select id={"PantoneType_" + colorindex} name="PantoneType"
                                                          className="form-select" value={colorGroup.PantoneType}
                                                          onChange={(event) => colorInputChange(event, index, colorindex)}
                                                          onBlur={() => CheckDuplicate(index, colorindex, "PantoneType")}
                                                          style={{ border: submitted && colorGroup.PantoneType === 0 ? "1px solid red" : "", }}
                                                        >
                                                          <option value="0">- Pantone Type -</option>
                                                          {
                                                            PantonetypeList.map((item) => (
                                                              <option key={item.pantoneTypeID} value={item.pantoneTypeID}>
                                                                {item.pantoneTypeName} {" "}
                                                              </option>
                                                            ))
                                                          }
                                                        </select>
                                                      </td>
                                                      <td>
                                                        {
                                                          colorGroup.PantoneTypeName !== "Others" ?
                                                            <select id={"PantoneName_" + colorindex} name="PantoneName"
                                                              className="form-select" value={colorGroup.PantoneName}
                                                              onChange={(event) => colorInputChange(event, index, colorindex)}
                                                              onBlur={() => CheckDuplicate(index, colorindex, "PantoneName")}
                                                              style={{ border: submitted && colorGroup.PantoneName === 0 ? "1px solid red" : "", }}
                                                            >
                                                              <option value="0">- Pantone Code -</option>
                                                              {
                                                                getPantone.filter((x) => x.pantoneTypeId === parseInt(colorGroup.PantoneType)).map((items) => (
                                                                  <>
                                                                    <option key={items.pantoneID} value={items.pantoneID}
                                                                      style={{ background: "url" + items.pantoneColor + "96% / 15% no-repeat", width: "120px", height: "34px", borderRadius: "2px", }}
                                                                    >
                                                                      {items.pantoneCode} {" "}
                                                                      &nbsp;
                                                                    </option>
                                                                  </>
                                                                ))}
                                                            </select>
                                                            :
                                                            <select id={"PantoneName_" + colorindex} name="PantoneName"
                                                              className="form-select" value={colorGroup.PantoneName}
                                                              onChange={(event) => colorInputChange(event, index, colorindex)}
                                                              onBlur={() => CheckDuplicate(index, colorindex, "PantoneName")}
                                                              style={{ border: submitted && colorGroup.PantoneName === 0 ? "1px solid red" : "", }}
                                                            >
                                                              <option value="0">- Swatch -</option>
                                                              {

                                                                getSwatch.map(item => (
                                                                  <option key={item.id} value={item.id}>
                                                                    {item.name}
                                                                  </option>
                                                                ))
                                                              }
                                                            </select>
                                                        }
                                                      </td>
                                                      <td>
                                                        {
                                                          colorGroup.PantoneTypeName !== "Others" ?
                                                            <div
                                                              style={{
                                                                background:
                                                                  colorGroup.PantoneColor ===
                                                                    ""
                                                                    ? "#f9f9f9"
                                                                    : colorGroup.PantoneColor,
                                                                width: "120px",
                                                                height: "34px",
                                                                borderRadius: "2px",
                                                              }}
                                                            ></div>
                                                            :
                                                            <img src={colorGroup.PantoneColor === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + colorGroup.PantoneColor}
                                                              alt="Picture" style={{ marginBottom: '5px', height: '40px', width: '50px', border: "1px solid #d5d5d5" }}
                                                              onClick={
                                                                () => setIsOpen(colorGroup.PantoneColor === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + colorGroup.PantoneColor)
                                                              }
                                                            />
                                                        }
                                                      </td>
                                                      <td>
                                                        <button
                                                          className={
                                                            colorGroup.Notes !==
                                                              "" ||
                                                              colorGroup.TargettoClose !==
                                                              null ||
                                                              colorGroup.CompletedOn !==
                                                              null ||
                                                              colorGroup.RemindMe !==
                                                              0
                                                              ? "btn btn-xs btn-danger"
                                                              : "btn btn-xs btn-primary"
                                                          }
                                                          title="Critical"
                                                          type="button"
                                                          onClick={() =>
                                                            ShowCritical(
                                                              index,
                                                              colorindex
                                                            )
                                                          }
                                                        >
                                                          <i class="fa fa-exclamation-triangle"></i>
                                                        </button>
                                                        &nbsp;
                                                        {inputField.ColorGroupList
                                                          .length !== 1 && (
                                                            <button
                                                              type="button"
                                                              className="btn  btn-xs  btn-danger"
                                                              title="Delete Color"
                                                              style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}
                                                              onClick={() =>
                                                                RemoveColorFields(
                                                                  index,
                                                                  colorindex
                                                                )
                                                              }
                                                            >
                                                              <i className="fa fa-trash-o"></i>
                                                            </button>
                                                          )}
                                                        &nbsp;
                                                        {inputField.ColorGroupList
                                                          .length ===
                                                          colorindex + 1 && (
                                                            <button
                                                              type="button"
                                                              className="btn  btn-xs  btn-success"
                                                              title="Add Color"

                                                              onClick={() =>
                                                                AddColorFields(
                                                                  index,
                                                                  colorindex
                                                                )
                                                              }
                                                            >
                                                              <i className="fa fa-plus"></i>
                                                            </button>
                                                          )}


                                                        {colorGroup.IsCriticalOpen === 1 &&
                                                          <Modal
                                                            show={
                                                              colorGroup.IsCriticalOpen ===
                                                              1
                                                            }
                                                            dialogAs={Drag}
                                                            size="lg"
                                                            dragable
                                                            backdrop="static"
                                                            keyboard={false}
                                                            onHide={() =>
                                                              handleClose(
                                                                index,
                                                                colorindex,
                                                                "Close"
                                                              )
                                                            }
                                                          >
                                                            <Modal.Header
                                                              closeButton
                                                            >
                                                              <Modal.Title>
                                                                {"Critical Comments"}
                                                              </Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                              <table
                                                                className="table table-striped table-bordered table-hover dataTable no-footer"
                                                                id="Criticaltable"
                                                              >
                                                                <thead>
                                                                  <tr>
                                                                    <th
                                                                      colSpan="4"
                                                                      style={{
                                                                        textAlign:
                                                                          "center",
                                                                      }}
                                                                    >
                                                                      Comments<span className="text-danger">*</span>
                                                                      <button
                                                                        type="button"
                                                                        className="btn btn-xs btn-danger pull-right"
                                                                        style={{ display: colorGroup.IsSaved === 1 ? 'block' : 'none' }}
                                                                        onClick={(
                                                                          event
                                                                        ) =>
                                                                          ClearCritical(
                                                                            event,
                                                                            index,
                                                                            colorindex
                                                                          )
                                                                        }
                                                                        title="Clear Critical"
                                                                      >
                                                                        <i className="fa fa-trash-o"></i>
                                                                      </button>
                                                                    </th>
                                                                  </tr>
                                                                </thead>
                                                                <tbody>
                                                                  <tr>
                                                                    <td colSpan="4">
                                                                      <textarea
                                                                        placeholder="Enter comments here..."
                                                                        autoFocus
                                                                        onFocus={
                                                                          onFocus
                                                                        }
                                                                        maxLength="500"
                                                                        name="Notes"
                                                                        id={
                                                                          "Notes" +
                                                                          colorindex
                                                                        }
                                                                        value={
                                                                          colorGroup.Notes
                                                                        }
                                                                        onChange={(
                                                                          event
                                                                        ) =>
                                                                          colorInputChange(
                                                                            event,
                                                                            index,
                                                                            colorindex
                                                                          )
                                                                        }
                                                                        rows="4"
                                                                        // style={{
                                                                        //   width:
                                                                        //     "100%",
                                                                        // }}
                                                                        style={{ width: "100%", border: criticSubmitted && colorGroup.Notes === '' ? "1px solid red" : "", }}

                                                                      ></textarea>
                                                                    </td>
                                                                  </tr>
                                                                  <tr>
                                                                    <td>
                                                                      Target to
                                                                      Close<span className="text-danger">*</span>
                                                                    </td>
                                                                    <td>
                                                                      <DatePicker
                                                                        className="form-control"
                                                                        name="TargettoClose"
                                                                        id={
                                                                          "TargettoClose" +
                                                                          colorindex
                                                                        }
                                                                        selected={
                                                                          colorGroup.TargettoClose
                                                                        }
                                                                        onChange={(
                                                                          event
                                                                        ) =>
                                                                          DateChange(
                                                                            event,
                                                                            index,
                                                                            colorindex,
                                                                            "TargettoClose"
                                                                          )
                                                                        }
                                                                        dateFormat="dd/MM/yyyy"
                                                                        peekNextMonth
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        dropdownMode="scroll"
                                                                        autoComplete="off"
                                                                        customInput={
                                                                          <CustomInputDate />
                                                                        }
                                                                        isClearable={
                                                                          colorGroup.TargettoClose ===
                                                                            null
                                                                            ? false
                                                                            : true
                                                                        }
                                                                      />
                                                                    </td>
                                                                    <td>
                                                                      Remind Me<span className="text-danger">*</span>
                                                                    </td>
                                                                    <td>
                                                                      <Select
                                                                        className="form-select"
                                                                        name="RemindMe"
                                                                        id={
                                                                          "RemindMe" +
                                                                          colorindex
                                                                        }
                                                                        value={
                                                                          colorGroup.RemindMe
                                                                        }
                                                                        onChange={(
                                                                          event
                                                                        ) =>
                                                                          colorInputChange(
                                                                            event,
                                                                            index,
                                                                            colorindex
                                                                          )
                                                                        }
                                                                        style={{ border: criticSubmitted && colorGroup.RemindMe === 0 ? "1px solid red" : "", }}
                                                                      >
                                                                        <option value="0">
                                                                          - select -
                                                                        </option>
                                                                        <option value="1">
                                                                          Current
                                                                          Date
                                                                          Reaches
                                                                          Target
                                                                          Date{" "}
                                                                        </option>
                                                                        <option value="2">
                                                                          One Time
                                                                          in a Day,
                                                                          until
                                                                          complete
                                                                        </option>
                                                                      </Select>
                                                                    </td>
                                                                  </tr>
                                                                  <tr>
                                                                    <td>
                                                                      Completed On
                                                                    </td>
                                                                    <td>
                                                                      {" "}
                                                                      <DatePicker
                                                                        className="form-control"
                                                                        name="CompletedOn"
                                                                        id={
                                                                          "CompletedOn" +
                                                                          colorindex
                                                                        }
                                                                        selected={
                                                                          colorGroup.CompletedOn
                                                                        }
                                                                        onChange={(
                                                                          event
                                                                        ) =>
                                                                          DateChange(
                                                                            event,
                                                                            index,
                                                                            colorindex,
                                                                            "CompletedOn"
                                                                          )
                                                                        }
                                                                        dateFormat="dd/MM/yyyy"
                                                                        peekNextMonth
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        dropdownMode="scroll"
                                                                        autoComplete="off"
                                                                        customInput={
                                                                          <CustomInput />
                                                                        }
                                                                        isClearable={
                                                                          colorGroup.CompletedOn ===
                                                                            null
                                                                            ? false
                                                                            : true
                                                                        }
                                                                      />
                                                                    </td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                              <Button
                                                                variant="success"
                                                                onClick={() =>
                                                                  handleClose(
                                                                    index,
                                                                    colorindex,
                                                                    "Save"
                                                                  )
                                                                }
                                                              >
                                                                Save
                                                              </Button>
                                                            </Modal.Footer>
                                                          </Modal>
                                                        }
                                                      </td>
                                                    </tr>
                                                    {(colorGroup.Notes !== "" ||
                                                      colorGroup.TargettoClose !==
                                                      null ||
                                                      colorGroup.CompletedOn !==
                                                      null) && (
                                                        <tr>
                                                          <td className="strikeoffcrictical" colSpan="4">
                                                            <span
                                                              style={{
                                                                color: "red",
                                                              }}
                                                            >
                                                              Critical :{" "}
                                                              {colorGroup.Notes ===
                                                                ""
                                                                ? "-"
                                                                : colorGroup.Notes}{" "}
                                                              ,
                                                            </span>
                                                            <span
                                                              style={{
                                                                color: "#427fed",
                                                              }}
                                                            >
                                                              {" "}
                                                              Target to Close :{" "}
                                                              {colorGroup.TargettoClose !==
                                                                null
                                                                ? moment(
                                                                  colorGroup.TargettoClose
                                                                ).format(
                                                                  "DD-MM-YYYY"
                                                                )
                                                                : "-"}{" "}
                                                              ,
                                                            </span>
                                                            <span
                                                              style={{
                                                                color: "#00a65a",
                                                              }}
                                                            >
                                                              {" "}
                                                              Completed On :{" "}
                                                              {colorGroup.CompletedOn !==
                                                                null
                                                                ? moment(
                                                                  colorGroup.CompletedOn
                                                                ).format(
                                                                  "DD-MM-YYYY"
                                                                )
                                                                : "-"}
                                                            </span>
                                                          </td>
                                                        </tr>
                                                      )}
                                                  </>
                                                )
                                              )}
                                          </tbody>
                                        </table>
                                      </td>
                                      <td className="strikeoffwidth">
                                        <UploadImage
                                          UploadCallback={(e) =>
                                            ImgCallBack(e, index)
                                          }
                                          PageName={props.location.state && props.location.state.params.From === "Style" ?
                                            'StyleStrikeoff' : 'MasterStrikeoff'}
                                          filePath={
                                            inputField.StrikeoffLogoPath
                                              ? "/Style/Strikeoff/" + inputField.StrikeoffLogoPath
                                              : inputField.StrikeoffLogoPath
                                          }
                                          Buttonview={""}
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </>
                            )}
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </span>
              </CollapsiblePanel>
            </div>
          </div>
          <div> {
            isOpen && (
              <Lightbox
                mainSrc={isOpen}
                alt="bg image"

                onCloseRequest={
                  () => setIsOpen(false)
                } />
            )
          }
          </div>
          <div
            className="col-lg-1_own col-xs-12"
            style={{
              display: inputField.IsDeleted === 1 ? "none" : "block",
            }}
          >
            <span>
              <span
                style={{
                  display:
                    inputField.IsDeleted === 1
                      ? "none"
                      : inputFields.filter((x) => x.IsDeleted === 0).length ===
                        1
                        ? "none"
                        : "",
                }}
              >
                <button
                  className="btn btn-danger"
                  title="Delete Color group"
                  style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}
                  onClick={() => handleRemoveFields(index)}
                >
                  <i className="fa fa-trash-o"></i>
                </button>
              </span>
            </span>
            {isPantonetypePopupOpen ? (
              <PantoneTypePopup
                getPantoneTypeList={AllPantonetypeList}
                pantoneTypeCallback={pantoneTypeCallback}
                showpopup={true}
              />
            ) : null}
            {isPantonePopupOpen ? (
              <PantonePopup
                getPantoneList={getPantone}
                getPantoneTypeList={AllPantonetypeList}
                pantoneCallback={pantoneCallback}
                showpopup={true}
              />
            ) : null}
            {isSwatchPopupOpen ? (
              <SwatchPopup
                SwatchList={getSwatch}
                swatchCallback={swatchCallback}
                showpopup={true}
                BuyerID={BuyerID}
                BrandID={BrandID}
              />
            ) : null}
          </div>
        </Fragment>
      ))}
      {inputFields
        .filter((x) => x.IsDeleted === 0)
        .map(
          (inputField1, index) =>
            inputFields.filter((x) => x.IsDeleted === 0).length ===
            index + 1 && (
              <div className="col-lg-1_own col-xs-12">
                <span>
                  <button
                    className="btn btn-success"
                    title="Add Color group"

                    onClick={() =>
                      handleAddFields(
                        inputFields.filter((x) => x.IsDeleted === 0).length - 1
                      )
                    }
                  >
                    <i className="fa fa-plus"></i>
                  </button>
                </span>
              </div>
            )
        )}
    </>
  );
};

export default PrintColor;
