import axios from "axios";
import authHeader from "../auth-header";

class FabricPartService {
    GetFabricPartList() {
        return axios.get(window.$APIBaseURL + "Master/GetFabricPartList?FabricPartID=0", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateFabricPartGrid(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateFabricPartGrid',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateFabricPart(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateFabricPart',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
}
export default new FabricPartService()