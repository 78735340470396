import {
 QA_LOADING,
 RETRIEVE_QA,
 DELETE_QA
}

 from "./types"


import QAService from "../services/Master/QAService"

const QALoading = (isStatus) => ({
 type: QA_LOADING,
 payload: isStatus,
});

export const createQA = (getQaValue) => async (dispatch) => {
 
 let resData = "";
 await QAService.AddQAComment(getQaValue).then((res) => {
  resData = res.data;

 }).catch((err) => { return Promise.reject(err); });
 return Promise.resolve(resData);
}


export const deleteQA = (commentID) => async (dispatch) => {

 try {
  dispatch({
   type: DELETE_QA,
   payload: commentID,
  });

 } catch (err) {
  console.log(err);
 }
};


export const retriveQA = () => async (dispatch) => {
 try {
  dispatch(QALoading(true));
  const res = await QAService.getAllQACommentList();
  dispatch({
   type: RETRIEVE_QA,
   payload: res.data,
  });
  dispatch(QALoading(false));
 } catch (err) {
  console.log(err);
  dispatch(QALoading(false));
 }
};
