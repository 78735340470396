import {
    TESTITEM_LOADING,
    RETRIEVE_TESTITEM,
    DELETE_TESTITEM,
} from "../actions/types";

const initialState = {
    isLoadingTestItem: true,
    TestItemNameList: [],
};

const TestItemReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case TESTITEM_LOADING:
            return {
                ...state,
                isLoadingTestItem: payload
            };
        case RETRIEVE_TESTITEM:
            return { ...state, TestItemNameList: payload };
        case DELETE_TESTITEM:
            const results = state.TestItemNameList.filter(c => c.id !== payload.QCCommentPoint);
            return {
                ...state,
                TestItemNameList: results
            };

        default:
            return state;
    }
};
export default TestItemReducer;