import {
  BRANCH_LOADING,
  CREATE_BRANCH,
  RETRIEVE_BRANCH,
  DELETE_BRANCH
} from "../actions/types";

const initialState = {
  isLoadingBranche:true,
  branches: [],
};

const branchReducer = (state = initialState, action) => {
   const { type, payload } = action;
   

 switch (type) {
  case BRANCH_LOADING:
    return{
      ...state,
      isLoadingBranche: payload
    };
   case CREATE_BRANCH:
     return {...state,branches: payload};

     case DELETE_BRANCH:
      const results=state.branches.filter(c => c.addressTypeID!==payload.addressTypeID);
      return {
        ...state,
        branches:results
      };
   case RETRIEVE_BRANCH:
    return {...state,branches: payload};

   // case UPDATE_TUTORIAL:
   //   return tutorials.map((tutorial) => {
   //     if (tutorial.id === payload.id) {
   //       return {
   //         ...tutorial,
   //         ...payload,
   //       };
   //     } else {
   //       return tutorial;
   //     }
   //   });

   // case DELETE_TUTORIAL:
   //   return tutorials.filter(({ id }) => id !== payload.id);

   // case DELETE_ALL_TUTORIALS:
   //   return [];

   default:
     return state;
 }
};
export default branchReducer;