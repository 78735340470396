import axios from "axios";
import authHeader from "../auth-header";

class SwatchMasterService {

    InsertUpdateSwatchMasterGrid(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateSwatchMasterGrid',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }


    InsertUpdateSwatchMaster(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateSwatchMaster',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetSwatchMasterList(Operation, BuyerID, SwatchMasterID, BrandID) {
        return axios.get(window.$APIBaseURL + "Master/GetSwatchMasterList?Operation=" + Operation +
            "&BuyerID=" + BuyerID + "&SwatchMasterID=" + SwatchMasterID + "&BrandID=" + BrandID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

}
export default new SwatchMasterService();