import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import 'react-notifications-component/dist/theme.css';
import Form from "react-validation/build/form";
import Nodify from "../../../Common/ReactNotification";
import Reactselect from 'react-select';
import $ from "jquery";

import axios from "axios";

import { useDispatch, useSelector, } from "react-redux";
import DatePicker from "react-datepicker";
import Lightbox from "react-image-lightbox"
import ReactNotification from 'react-notifications-component';
import StyleService from "../../../../services/Style/styleService";
import GeneralService from "../../../../services/General/GeneralService";
import { Modal, Button } from "react-bootstrap";
import SearchRetain from "../../../Common/SearchRetain";
import PantoneService from "../../../../services/Master/PantoneService";
import styleService from "../../../../services/Style/styleService";
import ProgramService from "../../../../services/Style/ProgramService";
import { getGenLabDipAlreadyMapList, updateProgramGridList } from "../../../../actions/generalLabdip";
import PantoneTypePopup from "../../../../components/quickMaster/_PantoneType";
import ProcessTypePopup from "../../../../components/quickMaster/_ProcessType";
import PantonePopup from "../../../../components/quickMaster/_Pantone";
import SwatchPopup from "../../../../components/quickMaster/_Swatch";
import moment from "moment";





const SamGenLabdipAddEdit = (props, ProgramCallback, Buttonviews) => {
    const emptyFields = [
        {
            Operation: 1,
            GenLabdipId: 0,
            BuyerId: 0,
            IsStoryMandatory: 0,
            IsThemeMandatory: 0,
            BrandId: 0,
            SeasonId: 0,
            ReceivedDate: '',
            StoryTheme: [{
                GenLapdipStoryThemeId: 0,
                StoryId: 0,
                ThemeId: 0,
                IndexName: '',
                Program: [{
                    GenLapdipProgramInfoId: 0,
                    PantoneType: 0,
                    PantoneIdOrSwatchId: 0,
                    ColororSwatchImage: '',
                    RefColorName: '',
                    FabricTypeOrYarn: 0,
                    ProcessTypeId: 0,
                    ApprovalTypeId: 0,
                    IsFolower: 0,
                    Follower: '',
                    FollowerDisplay: '',
                    FollowerBuyer: '',
                    FollowerBuyerIds: '',
                    FollowerCube: '',
                    FollowerCubeIds: '',
                    IsCritical: 0,
                    IsManual: 0,
                    IsOpen: 0,
                    IsExiting: 0,
                    PantoneTypeName: '',
                    IndexName: '',
                    StoryThemeIndexName: '',
                    CriticalInfo: [{
                        GenLapdipProgramCirticalInfoId: 0, CriticalComment: '', RemindMe: 0, CheckSave: false,
                        TargettoClose: '', CompletedOn: '', IndexName: '', StoryThemeIndexName: '', ProgramIndexName: ''
                    }],
                    IsManualInfo: [{
                        GenLapdipManulCloseCommentId: 0, ManualCloseComment: '', ManualCloseDate: '', CheckSave: false,
                        StoryThemeIndexName: '', IsDeleted: 0, Files: []
                    }],
                    //ExitingApprovalTypePop: [{ ExitingApprovalId: 0, SeasonName: '', ApprovalDate: '', Details: '', ApprovedBy: '' }],

                    CommonApprovalId: 0,
                    labdibSubmissionApprovalID: 0,
                    labdibSubmissionApproval: []
                }]
            }],
        }
    ];
    // //Enable Menu
    let activeMenu;
    let activeMenuSub;
    let ProgramInfo;
    let ProgramInfoQuick = [];
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
    if (menulist !== undefined) {
        activeMenu = menulist.filter(i => i.menuName === "LabDip");
        if (activeMenu.length > 0) {
            activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);
            if (activeMenuSub.length > 0) {
                for (let index = 0; index < activeMenuSub.length; index++) {
                    if (activeMenuSub[index].menuName === 'GeneralLabdipDetails') {
                        ProgramInfo = activeMenuSub[index];
                        ProgramInfoQuick.PantoneType = menulist.filter(i => i.menuName === 'PantoneType')
                        ProgramInfoQuick.Pantone = menulist.filter(i => i.menuName === 'Pantone')
                        ProgramInfoQuick.Swatch = menulist.filter(i => i.menuName === 'Swatch')
                        ProgramInfoQuick.ProcessType = menulist.filter(i => i.menuName === 'ProcessType')

                    }

                }
            }
        }
    }
    //End Enable Menu


    // If check production or sampling
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
    let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal : activeMenus.IsProduction;
    // End


    const { user: currentUser } = useSelector((state) => state.auth);
    const [inputFields, setInputFields] = useState(emptyFields);
    const [submitted, setSubmitted] = useState(false);
    const [pantoneID, setpantoneId] = useState();
    const [getHeader, setHeader] = useState("Add");
    const history = useHistory();
    const selector = useSelector((state) => state);
    const isSideBarEnable = selector.auth.isSideBarEnable;
    const isFullSideBarEnable = selector.auth.isFullSideBarEnable;
    const [getBuyerList, setBuyerList] = useState([]);
    const [getSeasonList, setSeasonList] = useState([]);
    const [getBrandList, setBrandList] = useState([]);
    const [getStoryList, setStoryList] = useState([]);
    const [getThemeList, setThemeList] = useState([]);
    const [getPantoneType, setPantoneType] = useState([]);
    const [getPantone, setPantone] = useState([]);
    const [getFabric, setFabric] = useState([]);
    const [getFollowerCube, setFollowerCube] = useState([]);
    const [getFollowerBuyer, setFollowerBuyer] = useState([]);
    const [getApprovalType, setApprovalType] = useState([{ ID: 1, Name: 'Direct New' }, { ID: 2, Name: 'Manual' }, { ID: 3, Name: 'Existing' }]);
    const [getSwatch, setSwatch] = useState([]);
    const [isOpenimg, setIsOpenimg] = useState(false);
    const [getImagename, setImagename] = useState();
    const [getProcessTypeList, setProcessTypeList] = useState([]);
    const [Buttonvisible, SetButtonvisible] = useState("Show");
    const [Buttonview, SetButtonview] = useState("Hide");
    const [getDisableSave, setDisableSave] = useState(false);
    const [critPopupValidation, setcritPopupValidation] = useState(false);
    const [followerpopupvalidation, setfollowerpopupvalidation] = useState(false);
    const [getSwatchSelected, setSwatchSelected] = useState(false);
    const [getFollowerCubeBuyerList, setFollowerCubeBuyerList] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [Pindexforedit, setPindexforedit] = useState();
    const [STindexforedit, setSTindexforedit] = useState();
    const dispatch = useDispatch();
    //Manual
    const [submittedPop, setSubmittedPop] = useState(false);
    // const [getPopUpValidation, setPopUpValidation] = useState(false);
    const [getFiles, setFiles] = useState([]);
    //reducer
    // const { user: currentUser } = useSelector((state) => state.auth);
    const reducerState = useSelector((state) => state.generalLabdip);
    const getProgramList = reducerState.programGridList;
    const GENLabDipMapList = reducerState.GENLabDipMapList;
    const allLabDipMapApproval = reducerState.allLabDipMapApproval

    //new file state for get index in edit-->whch delete file update and add new file it will cause error
    const [newFilestate, setNewFileState] = useState([])

    {/* New changes by hari for popup start */ }
    const [isPantonetypePopupOpen, setIsPantonetypePopupOpen] = useState(false);
    const [AllPantonetypeList, setAllPantonetypeList] = useState([]);
    const [isProcesstypePopupOpen, setProcessTypePopupOpen] = useState(false);
    const [getMaxidProcessType, setMaxidProcessType] = useState(0);
    const [isPantonePopupOpen, setIsPantonePopupOpen] = useState(false);
    const [isSwatchPopupOpen, setIsSwatchPopupOpen] = useState(false);
    {/* New changes by hari for popup end */ }


    let EmptyManual = [{ IsManual: '', ManualCloseComment: '', ManualCloseDate: '', CheckSave: false, StyleProgramIndexName: '', Files: [] }]
    let BrowserWidth = isFullSideBarEnable
        ? isSideBarEnable
            ? $(window).width() - 160
            : $(window).width() + 50
        : $(window).width() + 100;

    let styles1 = {
        control: styles1 => ({ ...styles1, width: "350px" }),
    }

    let styles2 = {
        control: styles2 => ({ ...styles2, width: "350px", border: '1px solid red' }),
    }
    const values = [...inputFields];

    useEffect(() => {
        StyleService.LoadBuyer(2)
            .then((response) => {
                if (response.data) {
                    setBuyerList(response.data);
                    if (props.location.state.params) {
                        values[0].BuyerId = props.location.state.params.buyerId;
                        var BuyerInfo = response.data.filter(x => x.value === values[0].BuyerId);
                        values[0].IsStoryMandatory = BuyerInfo[0].isStoryMandatory;
                        values[0].IsThemeMandatory = BuyerInfo[0].isThemeMandatory;
                        LoadBrandSeasonStoryTheme(props.location.state.params.buyerId)
                        values[0].BrandId = props.location.state.params.brandId;
                        values[0].SeasonId = props.location.state.params.seasonId;
                        setInputFields(values);
                    }
                }
            })
            .catch(() => { });
        PantoneService.getPantonelist(1, 0, 0).then((response) => {
            if (response.data) {
                setPantoneType(response.data);
            }
        });

        PantoneService.LoadPantoneList().then((response) => {
            if (response.data) {
                setPantone(response.data);
            }
        });
        styleService.LoadProcessType()
            .then((response) => {
                if (response.data) {
                    setProcessTypeList(response.data);
                } else {
                }
            })
            .catch(() => { });

        styleService.LoadFabricYarnDetailsList(0).then((response) => {
            if (response.data) {
                setFabric(response.data);
            }
        });

        if (props.location.state !== undefined) {
            if (props.location.state.params.genLapdipProgramInfoId !== 0 && props.location.state.params.genLapdipProgramInfoId !== undefined) {

                setHeader("Edit")
                GeneralService.GetSelectedLabdip(props.location.state.params.genLapdipProgramInfoId).then((response) => {
                    if (response.data) {
                        let mainDetails = response.data.mainDetails[0];
                        let storyThemeListInfo = response.data.storyThemeListInfo[0];
                        let programListInfo = response.data.programListInfo[0];
                        let criticalInfo = response.data.criticalInfo[0];
                        let ismanualInfo = response.data.manualInfo[0];
                        let manualInfoFiles = response.data.manualInfoFiles;

                        let commonApproval = response.data.commonApproval[0];

                        values[0].Operation = 2;
                        values[0].GenLabdipId = mainDetails.genLabdipId;
                        values[0].BuyerId = mainDetails.buyerId;
                        LoadBrandSeasonStoryTheme(values[0].BuyerId);
                        PantoneService.LoadSwatchList(mainDetails.buyerId, mainDetails.brandId).then((response) => {
                            if (response.data) {
                                setSwatch(response.data);
                            }
                        });
                        values[0].BrandId = mainDetails.brandId;
                        values[0].SeasonId = mainDetails.seasonId;
                        values[0].ReceivedDate = new Date(mainDetails.recievedDate);
                        values[0].StoryTheme[0].GenLapdipStoryThemeId = storyThemeListInfo.genLapdipStoryThemeId;
                        values[0].StoryTheme[0].StoryId = storyThemeListInfo.storyId;
                        values[0].StoryTheme[0].ThemeId = storyThemeListInfo.themeId;
                        values[0].StoryTheme[0].IndexName = storyThemeListInfo.indexName;
                        values[0].StoryTheme[0].Program[0].GenLapdipProgramInfoId = programListInfo.genLapdipProgramInfoId;
                        values[0].StoryTheme[0].Program[0].PantoneType = programListInfo.pantoneType;
                        //  var Pantonetype = getPantoneType.filter(x => x.pantoneTypeID === parseInt(programListInfo.pantoneType));

                        values[0].StoryTheme[0].Program[0].PantoneTypeName = programListInfo.pantoneTypeName;
                        values[0].StoryTheme[0].Program[0].PantoneIdOrSwatchId = programListInfo.pantoneIdOrSwatchId;
                        values[0].StoryTheme[0].Program[0].ColororSwatchImage = programListInfo.pantoneColorOrSwatchImage;
                        values[0].StoryTheme[0].Program[0].RefColorName = programListInfo.refColorName;
                        values[0].StoryTheme[0].Program[0].FabricTypeOrYarn = programListInfo.fabricTypeOrYarn;
                        values[0].StoryTheme[0].Program[0].ProcessTypeId = programListInfo.processTypeId;
                        values[0].StoryTheme[0].Program[0].ApprovalTypeId = programListInfo.approvalTypeId;
                        values[0].StoryTheme[0].Program[0].CommonApprovalId = programListInfo.commonApprovalId;
                        values[0].StoryTheme[0].Program[0].labdibSubmissionApprovalID = programListInfo.commonApprovalId;

                        values[0].StoryTheme[0].Program[0].IsFolower = programListInfo.isFolower;
                        values[0].StoryTheme[0].Program[0].Follower = programListInfo.follower;
                        values[0].StoryTheme[0].Program[0].FollowerDisplay = programListInfo.followerName;
                        values[0].StoryTheme[0].Program[0].FollowerBuyer = programListInfo.followerBuyer;
                        // values[0].StoryTheme[0].Program[0].FollowerBuyerIds = programListInfo.followerBuyerIds;
                        values[0].StoryTheme[0].Program[0].FollowerCube = programListInfo.followerCube;
                        //  values[0].StoryTheme[0].Program[0].FollowerCubeIds = programListInfo.followerCubeIds;
                        values[0].StoryTheme[0].Program[0].IsCritical = programListInfo.isCritical;
                        values[0].StoryTheme[0].Program[0].IsManual = programListInfo.ismanual;
                        // values[0].StoryTheme[0].Program[0].IsManual = programListInfo.manualInfoFiles;
                        values[0].StoryTheme[0].Program[0].IndexName = programListInfo.indexName;

                        values[0].StoryTheme[0].Program[0].StoryThemeIndexName = programListInfo.storyThemeIndexName;
                        setPindexforedit(programListInfo.indexName);
                        setSTindexforedit(programListInfo.storyThemeIndexName);

                        if (criticalInfo && criticalInfo.length !== 0) {
                            values[0].StoryTheme[0].Program[0].CriticalInfo[0].GenLapdipProgramCirticalInfoId = criticalInfo.genLapdipProgramCirticalInfoId;
                            values[0].StoryTheme[0].Program[0].CriticalInfo[0].CriticalComment = criticalInfo.criticalComment;
                            values[0].StoryTheme[0].Program[0].CriticalInfo[0].RemindMe = criticalInfo.remindMe;
                            values[0].StoryTheme[0].Program[0].CriticalInfo[0].CheckSave = true;
                            values[0].StoryTheme[0].Program[0].CriticalInfo[0].TargettoClose = new Date(criticalInfo.targettoClose);
                            values[0].StoryTheme[0].Program[0].CriticalInfo[0].CompletedOn = new Date(criticalInfo.completedOn);
                            values[0].StoryTheme[0].Program[0].CriticalInfo[0].IndexName = criticalInfo.indexName;
                            values[0].StoryTheme[0].Program[0].CriticalInfo[0].StoryThemeIndexName = criticalInfo.storyThemeIndexName;
                            values[0].StoryTheme[0].Program[0].CriticalInfo[0].ProgramIndexName = criticalInfo.programIndexName;
                        }

                        if (ismanualInfo && ismanualInfo.length !== 0) {
                            values[0].StoryTheme[0].Program[0].IsManualInfo[0].GenLapdipManulCloseCommentId = ismanualInfo.genLapdipManulCloseCommentId;
                            values[0].StoryTheme[0].Program[0].IsManualInfo[0].ManualCloseComment = ismanualInfo.manualCloseComment;
                            values[0].StoryTheme[0].Program[0].IsManualInfo[0].ManualCloseDate = ismanualInfo.manualCloseDate;
                            values[0].StoryTheme[0].Program[0].IsManualInfo[0].ProgramIndexName = ismanualInfo.programIndexName;
                            values[0].StoryTheme[0].Program[0].IsManualInfo[0].CheckSave = true;

                        }
                        if (manualInfoFiles && manualInfoFiles.length !== 0) {
                            manualInfoFiles.map((fileDetail, fileIndex) => {
                                values[0].StoryTheme[0].Program[0].IsManualInfo[0].Files.push({
                                    GenLapdipManulCloseCommentFileId: fileDetail.genLapdipManulCloseCommentFileId,
                                    fileType: fileDetail.fileType,
                                    fileName: fileDetail.fileName,
                                    filePath: fileDetail.filePath,
                                    storyThemeIndexName: fileDetail.storyThemeIndexName,
                                    programIndexName: fileDetail.programIndexName,
                                    FileIndex: fileDetail.fileIndex
                                })
                            })

                        }
                        if (commonApproval && commonApproval.length !== 0) {
                            values[0].StoryTheme[0].Program[0].labdibSubmissionApproval.push({
                                SeasonName: commonApproval.seasonName,
                                ApprovalDate: commonApproval.approvalDate,
                                Details: commonApproval.details,
                                CommonApprovalId: commonApproval.commonApprovalId,
                                ApprovedBy: commonApproval.approvedBy

                            })

                        }

                        GeneralService.LoadFollowerCubeBuyerList(values[0].BuyerId, currentUser.employeeinformationID).then((response) => {
                            if (response.data) {
                                setFollowerCubeBuyerList(response.data);
                                setFollowerCube(response.data.followerCubeDropdown);
                                setFollowerBuyer(response.data.followerBuyerDropdown);
                                let arrcube = programListInfo.followerCube.split(',');

                                let CubeBuyerList = response.data;
                                let arrValcube = [];
                                for (let size of arrcube) {
                                    let res = CubeBuyerList.followerCubeDropdown.filter(x => x.value === parseInt(size));

                                    res && res.length > 0 && arrValcube.push({
                                        value: res[0].value,
                                        label: res[0].label
                                    });
                                }
                                let arrbuyer = programListInfo.followerBuyer.split(',');
                                let arrValbuyer = [];
                                for (let size of arrbuyer) {
                                    let res = CubeBuyerList.followerBuyerDropdown.filter(x => x.value === parseInt(size));
                                    res && res.length > 0 && arrValbuyer.push({
                                        value: res[0].value,
                                        label: res[0].label
                                    });
                                }

                                values[0].StoryTheme[0].Program[0].FollowerCubeIds = arrValcube;
                                values[0].StoryTheme[0].Program[0].FollowerBuyerIds = arrValbuyer;
                                setInputFields(values);
                            }
                        });

                    }
                }).catch(() => { });
            }

        }

        StyleService.LoadSeason(1, 0)
            .then((response) => {
                if (response.data) {
                    setSeasonList(response.data);
                }
            })
            .catch(() => { });
    }, []);

    useEffect(() => {
        if (values[0].BuyerId) {
            GeneralService.LoadFollowerCubeBuyerList(values[0].BuyerId, currentUser.employeeinformationID).then((response) => {
                if (response.data) {
                    setFollowerCubeBuyerList(response.data);
                    setFollowerCube(response.data.followerCubeDropdown);
                    setFollowerBuyer(response.data.followerBuyerDropdown);
                }
            });
        } else {
            setFollowerCubeBuyerList([]);
        }

    }, [values[0].BuyerId]);

    function LoadBrandSeasonStoryTheme(ID) {
        if (!isNaN(ID)) {
            // if (getFollowerCubeBuyerList && getFollowerCubeBuyerList.length > 0) { }
            // else {
            //     GeneralService.LoadFollowerCubeBuyerList(ID, currentUser.employeeinformationID).then((response) => {
            //         if (response.data) {
            //             setFollowerCubeBuyerList(response.data);
            //             setFollowerCube(response.data.followerCubeDropdown);
            //             setFollowerBuyer(response.data.followerBuyerDropdown);

            //         }
            //     });
            // }

            StyleService.LoadBrandThemeStoryEndCustomer(5, parseInt(ID)).then((response) => {
                if (response.data) {
                    let BuyerInfoList = response.data;
                    let respected_Brand = BuyerInfoList.filter(x => x.buyerMapID === 1);
                    setBrandList(respected_Brand);
                    let respected_Story = BuyerInfoList.filter(x => x.buyerMapID === 3);
                    setStoryList(respected_Story);
                    let respected_Theory = BuyerInfoList.filter(x => x.buyerMapID === 4);
                    setThemeList(respected_Theory);

                }
            }).catch(() => { });



        }
    }

    const handleAddFields = (operation, index, STindex) => {
        let IsValid = true;
        let IsValidProgram = true;
        if (values[index].StoryTheme[STindex].StoryId !== 0 && values[index].StoryTheme[STindex].ThemeId !== 0) {

            values[index].StoryTheme[STindex].Program.forEach(element1 => {
                if (element1.PantoneType === 0 || element1.PantoneIdOrSwatchId === 0 || element1.FabricTypeOrYarn === 0 || element1.ProcessTypeId === 0
                    || element1.ApprovalTypeId === 0 || element1.FollowerDisplay === '') {
                    IsValidProgram = false;

                }
            })
            if (IsValidProgram) {
                let Emptylist = {};
                var copy = values[index].StoryTheme[STindex];
                if (operation === "Add") {
                    Emptylist = {
                        GenLapdipStoryThemeId: 0,
                        StoryId: 0,
                        ThemeId: 0,
                        IndexName: '',
                        Program: [{
                            GenLapdipProgramInfoId: 0,
                            PantoneType: 0,
                            PantoneIdOrSwatchId: 0,
                            ColororSwatchImage: '',
                            RefColorName: '',
                            FabricTypeOrYarn: 0,
                            ProcessTypeId: 0,
                            ApprovalTypeId: 0,
                            IsFolower: 0,
                            Follower: '',
                            FollowerDisplay: '',
                            FollowerBuyer: 0,
                            FollowerCube: 0,
                            IsCritical: 0,
                            IsManual: 0,
                            PantoneTypeName: '',
                            IndexName: '',
                            StoryThemeIndexName: '',
                            CriticalInfo: [{
                                GenLapdipProgramCirticalInfoId: 0, CriticalComment: '', RemindMe: 0, CheckSave: false,
                                TargettoClose: '', CompletedOn: '', IndexName: '', StoryThemeIndexName: '', ProgramIndexName: ''
                            }],
                            IsManualInfo: [{ GenLapdipManulCloseCommentId: 0, ManualCloseComment: '', ManualCloseDate: '', CheckSave: false, StyleProgramIndexName: '', IsDeleted: 0, Files: [] }],
                            CommonApprovalId: 0,
                            labdibSubmissionApprovalID: 0
                        }]
                    }
                }
                else {
                    let CopyProgram = [];

                    copy.Program.map((element, indexVal) => {
                        CopyProgram.push({
                            GenLapdipProgramInfoId: element.GenLapdipProgramInfoId,
                            PantoneType: element.PantoneType,
                            PantoneIdOrSwatchId: element.PantoneIdOrSwatchId,
                            ColororSwatchImage: element.ColororSwatchImage,
                            RefColorName: element.RefColorName,
                            FabricTypeOrYarn: element.FabricTypeOrYarn,
                            ProcessTypeId: element.ProcessTypeId,
                            ApprovalTypeId: element.ApprovalTypeId,
                            IsFolower: element.IsFolower,
                            Follower: element.Follower,
                            FollowerDisplay: element.FollowerDisplay,
                            FollowerBuyer: element.FollowerBuyer,
                            FollowerCube: element.FollowerCube,
                            IsCritical: element.IsCritical,
                            IsManual: element.IsManual,
                            PantoneTypeName: element.PantoneTypeName,
                            IndexName: element.IndexName,
                            StoryThemeIndexName: element.StoryThemeIndexName,
                            CommonApprovalId: element.CommonApprovalId,
                            CriticalInfo: [],
                            IsManualInfo: [],
                            FollowerCubeIds: [],
                            FollowerBuyerIds: []

                        })
                        //FollowerCubeIds and FollowerBuyerIds Added
                        let arrValcube = [];
                        if (element.FollowerCube) {
                            let arrcube = element.FollowerCube.split(',');
                            for (let size of arrcube) {
                                let res = getFollowerCubeBuyerList.followerCubeDropdown.filter(x => x.value === parseInt(size));
                                res && res.length > 0 && arrValcube.push({
                                    value: res[0].value,
                                    label: res[0].label
                                });
                            }
                        }
                        let arrValbuyer = [];
                        if (element.FollowerBuyer) {
                            let arrbuyer = element.FollowerBuyer.split(',');
                            for (let size of arrbuyer) {
                                let res = getFollowerCubeBuyerList.followerBuyerDropdown.filter(x => x.value === parseInt(size));
                                res && res.length > 0 && arrValbuyer.push({
                                    value: res[0].value,
                                    label: res[0].label
                                });
                            }
                        }
                        CopyProgram[indexVal].FollowerCubeIds = arrValcube;
                        CopyProgram[indexVal].FollowerBuyerIds = arrValbuyer;

                        element.CriticalInfo.forEach((element1, index) => {
                            CopyProgram[indexVal].CriticalInfo.push({
                                GenLapdipProgramCirticalInfoId: element1.GenLapdipProgramCirticalInfoId, CriticalComment: element1.CriticalComment,
                                RemindMe: element1.RemindMe, CheckSave: element1.CheckSave,
                                TargettoClose: element1.TargettoClose, CompletedOn: element1.CompletedOn, IndexName: element1.IndexName,
                                StoryThemeIndexName: element1.StoryThemeIndexName, ProgramIndexName: element1.ProgramIndexName
                            })
                        })
                        element.IsManualInfo.forEach((element1, index) => {
                            CopyProgram[indexVal].IsManualInfo.push({
                                GenLapdipManulCloseCommentId: element1.GenLapdipManulCloseCommentId, ManualCloseComment: element1.ManualCloseComment,
                                Files: [], CheckSave: element1.CheckSave, StyleProgramIndexName: element1.StyleProgramIndexName


                            })

                        })

                        element.IsManualInfo[0].Files.forEach(fileDetail => {
                            CopyProgram[indexVal].IsManualInfo[0].Files.push({
                                GenLapdipManulCloseCommentFileId: fileDetail.genLapdipManulCloseCommentFileId,
                                fileType: fileDetail.fileType,
                                fileName: fileDetail.fileName,
                                filePath: fileDetail.filePath,
                                storyThemeIndexName: fileDetail.storyThemeIndexName,
                                programIndexName: fileDetail.programIndexName,
                                FileIndex: fileDetail.fileIndex
                            })
                        })


                    })


                    Emptylist = {
                        GenLapdipStoryThemeId: copy.GenLapdipStoryThemeId,
                        StoryId: copy.StoryId,
                        ThemeId: copy.ThemeId,
                        IndexName: copy.IndexName,
                        Program: CopyProgram
                    }
                }
                values[index].StoryTheme.splice(STindex + 1, 0, Emptylist);
                setSubmitted(false);
                setInputFields(values);
            }
            else {
                Nodify('Warning!', 'warning', "Please fill all mandatory(*) fields in pantone section.");
                setSubmitted(true);
                return false;
            }
        }
        else {
            Nodify('Warning!', 'warning', "Please fill all mandatory(*) fields.");
            setSubmitted(true);
            IsValid = false;
        }
    }

    const handleRemoveFields = (index, subindex) => {
        values[index].StoryTheme.splice(subindex, 1);
        setInputFields(values);

    };

    const handlechange = (e, index, feild) => {
        if (e === null) {
            values[index][feild] = 0;
        }
        else {
            if (e.length === 0) {
                values[index][feild] = 0;
            }
            else {
                if (feild !== "ReceivedDate") {
                    values[index][feild] = e.value;
                    if (feild === "BuyerId") {
                        LoadBrandSeasonStoryTheme(e.value);
                        values[index].IsStoryMandatory = e.isStoryMandatory;
                        values[index].IsThemeMandatory = e.isThemeMandatory;
                    }
                }
                else {
                    values[index][feild] = e;
                }
            }

        }
        setInputFields(values);

    }

    const handleChangeProgram = async (e, index, childindex, subchildindex, feild) => {
        if (e.target.value !== '' && feild !== "RefColorName" && feild !== "PantoneType") {
            values[index].StoryTheme[childindex].Program[subchildindex][feild] = parseInt(e.target.value)
            if (getHeader !== "Edit") {
                if (values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo[0].ManualCloseComment !== ''
                    && values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo[0].GenLapdipManulCloseCommentId === 0
                ) {
                    values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo[0].ManualCloseComment = '';
                    values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo[0].IsSaved = 0;
                    values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo.map((element, i) => {
                        element.Files = [];
                    });
                }
            } else {

                if (values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo[0].ManualCloseComment !== undefined
                    && values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo[0].GenLapdipManulCloseCommentId !== 0 &&
                    values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo.length !== 0) {
                    values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo[0].ManualCloseComment = '';
                    values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo[0].IsDeleted = 1;
                    values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo[0].IsSaved = 0;
                    values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo.map((element, i) => {
                        element.Files = [];
                    });

                }
            }

        } else if (feild === "RefColorName") {
            values[index].StoryTheme[childindex].Program[subchildindex][feild] = e.target.value;
        }

        if (feild === "PantoneType") {
            let isvalid = true
            if (isvalid) {
                if (e.target.value !== '0') {
                    values[index].StoryTheme[childindex].Program[subchildindex][feild] = parseInt(e.target.value)
                    setpantoneId(parseInt(e.target.value));
                    var Pantonetype = getPantoneType.filter(x => x.pantoneTypeID === parseInt(e.target.value));
                    values[index].StoryTheme[childindex].Program[subchildindex].PantoneTypeName = Pantonetype[0] && Pantonetype[0].pantoneTypeName;

                    var BuyerID = values[0].BuyerId;
                    var BrandID = values[0].BrandId;

                    if (Pantonetype && Pantonetype[0] && Pantonetype[0].pantoneTypeName === "Others") {
                        if (BuyerID === 0 || BrandID === 0) {
                            values[index].StoryTheme[childindex].Program[subchildindex].PantoneType = 0;
                            values[index].StoryTheme[childindex].Program[subchildindex].PantoneIdOrSwatchId = 0;
                            values[index].StoryTheme[childindex].Program[subchildindex].ColororSwatchImage = "";
                            Nodify("Warning!", "warning", "Please Select Buyer and Brand");
                            // setSubmitted(true);
                            return false;
                        } else if (Pantonetype && Pantonetype[0]) {
                            // values[index].PantoneTypeID = 0;
                            values[index].StoryTheme[childindex].Program[subchildindex].PantoneIdOrSwatchId = 0;
                            values[index].StoryTheme[childindex].Program[subchildindex].ColororSwatchImage = "";
                            PantoneService.LoadSwatchList(BuyerID, BrandID).then((response) => {
                                if (response.data) {
                                    setSwatch(response.data);
                                }
                            });
                            setSwatchSelected(true);
                        } else {
                            values[index].StoryTheme[childindex].Program[subchildindex].PantoneIdOrSwatchId = 0;
                            values[index].StoryTheme[childindex].Program[subchildindex].ColororSwatchImage = "";
                            setSwatch([]);
                        }
                    }
                    else {
                        setSwatchSelected(false);
                        values[index].StoryTheme[childindex].Program[subchildindex].PantoneIdOrSwatchId = 0;
                        values[index].StoryTheme[childindex].Program[subchildindex].ColororSwatchImage = "";
                    }
                } else {
                    values[index].StoryTheme[childindex].Program[subchildindex].PantoneIdOrSwatchId = 0;
                    values[index].StoryTheme[childindex].Program[subchildindex].ColororSwatchImage = "";

                    setSwatch([]);

                }
            }
        }
        if (feild === "PantoneIdOrSwatchId") {
            // let ValueExistinDb = [];
            // let ValueExistinLocal = [];
            // let ValueExist = true;
            let isvalid = true;
            // let Params = {};
            // Params.BuyerId = values[0].BuyerId;
            // Params.BrandId = values[0].BrandId;
            // Params.SeasonId = values[0].SeasonId;
            // Params.StoryId = values[0].StoryTheme[childindex].StoryId;
            // Params.ThemeId = values[0].StoryTheme[childindex].ThemeId;
            // Params.PantoneIdOrSwatchId = parseInt(e.target.value);
            // Params.IsProduction = IsProduction;
            // await GeneralService.CheckGeneralLabdibDuplicate(Params).then((response) => {
            //     if (response.data) {
            //         ValueExistinDb = response.data;
            //         if (values[index].StoryTheme[childindex].Program.length > 1) {
            //             ValueExistinLocal = values[index].StoryTheme[childindex].Program.filter(x => x.PantoneIdOrSwatchId === Params.PantoneIdOrSwatchId && x.PantoneType === pantoneID);
            //         }
            //         if (ValueExistinDb.length > 0 || ValueExistinLocal.length > 1) {
            //             isvalid = false
            //         }

            //     }
            // })
            if (isvalid) {
                if (e && e.target.value !== '0') {
                    if (values[index].StoryTheme[childindex].Program[subchildindex].PantoneTypeName !== "Others") {
                        for (var getColor = 0; getColor < getPantone.length; getColor++) {
                            if (parseInt(e.target.value) === getPantone[getColor].pantoneID) {
                                values[index].StoryTheme[childindex].Program[subchildindex].ColororSwatchImage = (getPantone[getColor].pantoneColor);
                            }
                        }
                    }
                    else {
                        var Swatch = getSwatch.filter(x => x.id === parseInt(values[index].StoryTheme[childindex].Program[subchildindex].PantoneIdOrSwatchId));
                        if (Swatch.length !== 0) {
                            values[index].StoryTheme[childindex].Program[subchildindex].ColororSwatchImage = Swatch[0].label;
                        }
                    }
                } else {
                    values[index].StoryTheme[childindex].Program[subchildindex].ColororSwatchImage = "";

                }
            }
            // else {
            //     values[index].StoryTheme[childindex].Program[subchildindex].PantoneIdOrSwatchId = 0;
            //     Nodify('Warning!', 'warning', "PantoneIdOrSwatchId already Exists.");
            // }

        }
        setInputFields(values);
    }

    const ShowCritical = (index, childindex, subchildindex) => {
        values[index].StoryTheme[childindex].Program[subchildindex].IsCritical = 1;
        setInputFields(values);
    }
    const HideCritical = (index, childindex, subchildindex) => {
        if (values[index].StoryTheme[childindex].Program[subchildindex].CriticalInfo[0].CheckSave === false) {
            values[index].StoryTheme[childindex].Program[subchildindex].CriticalInfo[0].CriticalComment = '';
            values[index].StoryTheme[childindex].Program[subchildindex].CriticalInfo[0].TargettoClose = '';
            values[index].StoryTheme[childindex].Program[subchildindex].CriticalInfo[0].CompletedOn = '';
            values[index].StoryTheme[childindex].Program[subchildindex].CriticalInfo[0].RemindMe = 0;
        }
        values[index].StoryTheme[childindex].Program[subchildindex].IsCritical = 0;
        setcritPopupValidation(false);
        setInputFields(values);
    }


    const ClearCritical = (index, childindex, subchildindex) => {
        values[index].StoryTheme[childindex].Program[subchildindex].CriticalInfo[0].CriticalComment = '';
        values[index].StoryTheme[childindex].Program[subchildindex].CriticalInfo[0].TargettoClose = '';
        values[index].StoryTheme[childindex].Program[subchildindex].CriticalInfo[0].CompletedOn = '';
        values[index].StoryTheme[childindex].Program[subchildindex].CriticalInfo[0].RemindMe = 0;
        values[index].StoryTheme[childindex].Program[subchildindex].CriticalInfo[0].CheckSave = false
        Nodify('Success!', 'success', 'Comment deleted successfully');
        setInputFields(values);

    }

    const handleChangeCritical = (e, index, childindex, subchildindex, critindex, feild) => {

        //  if (e !== null && e.target.value !== '') {
        if (feild === "CriticalComment") {
            values[index].StoryTheme[childindex].Program[subchildindex].CriticalInfo[0].CriticalComment = e.target.value;
        }
        else if (feild === "TargettoClose") {
            values[index].StoryTheme[childindex].Program[subchildindex].CriticalInfo[0].TargettoClose = e;
        }
        else if (feild === "RemindMe") {
            values[index].StoryTheme[childindex].Program[subchildindex].CriticalInfo[0].RemindMe = e.target.value;
        }
        else if (feild === "CompletedOn") {
            values[index].StoryTheme[childindex].Program[subchildindex].CriticalInfo[0].CompletedOn = e;
        }
        setInputFields(values);
        // }

    }

    const handleSaveCriticalInfo = (index, childindex, subchildindex, critindex) => {
        let CheckCritical = true;
        values[0].StoryTheme[childindex].Program[subchildindex].CriticalInfo.forEach((Chk, i) => {
            if (Chk.CriticalComment === '' || Chk.RemindMe === 0 || Chk.RemindMe === '0' || Chk.TargettoClose === null || Chk.TargettoClose === '') {
                CheckCritical = false;
                setcritPopupValidation(true);
                Nodify('Warning!', 'warning', "Please fill the mandantory(*) fields");
            }
        });
        if (CheckCritical) {
            values[index].StoryTheme[childindex].Program[subchildindex].IsCritical = 0;
            values[0].StoryTheme[childindex].Program[subchildindex].CriticalInfo[0].CheckSave = true;
            setInputFields(values);
            setcritPopupValidation(false);
            Nodify('Success!', 'success', "Critical comments Saved Successfully");
        }
    }

    const onFocus = (e) => {
        var val = e.target.value;
        e.target.value = '';
        e.target.value = val;
    }

    const handleChangeStoryTheme = (e, index, subchild, feild) => {
        if (e === null) {
            values[index].StoryTheme[subchild][feild] = 0;
        }
        else {

            if (e.length === 0) {
                values[index].StoryTheme[subchild][feild] = 0;
            }
            else {
                values[index].StoryTheme[subchild][feild] = e.value;
            }
        }
        setInputFields(values);
    }
    const CheckDuplicateStoryTheme = (index, subchild) => {
        let StoryId = values[index].StoryTheme[subchild].StoryId;
        let ThemeId = values[index].StoryTheme[subchild].ThemeId;
        if (StoryId !== 0 && ThemeId !== 0) {
            values[index].StoryTheme.forEach((element, stindex) => {
                if (element.StoryId === StoryId && element.ThemeId === ThemeId && stindex !== subchild) {
                    values[index].StoryTheme[subchild].StoryId = 0;
                    values[index].StoryTheme[subchild].ThemeId = 0;
                    setInputFields(values);
                    Nodify("Warning!", "warning", "Story Theme Already Selected");
                    return false;
                }
            })
        }
    }
    const showCubeFollowePopup = (index, childindex, subchildindex) => {
        if (values[0].BuyerId === 0) {
            Nodify("Warning!", "warning", "Please Select Buyer");
            // setSubmitted(true);
            return false;
        }
        else {
            values[index].StoryTheme[childindex].Program[subchildindex].IsFolower = 1;
            setInputFields(values);
        }
    }
    const hideCubeFollowePopup = (index, childindex, subchildindex) => {
        if (values[index].StoryTheme[childindex].Program[subchildindex].FollowerDisplay == "") {
            setfollowerpopupvalidation(false);
        }
        values[index].StoryTheme[childindex].Program[subchildindex].IsFolower = 0;
        setInputFields(values);
    }
    const handleChangeFollower = (event, index, childindex, subchildindex, feild) => {
        if (feild === "Buyer") {
            if (event.length === 0) {
                values[index].StoryTheme[childindex].Program[subchildindex].FollowerBuyer = "";
                values[index].StoryTheme[childindex].Program[subchildindex].FollowerBuyerIds = "";
            } else {
                let strValue = "";
                for (let Sku of event) {
                    if (strValue === "") {
                        strValue = Sku.value.toString();
                    } else {
                        strValue = strValue + "," + Sku.value;
                    }
                }
                values[index].StoryTheme[childindex].Program[subchildindex].FollowerBuyer = strValue;
                values[index].StoryTheme[childindex].Program[subchildindex].FollowerBuyerIds = event;
            }
        }
        else {
            if (event.length === 0) {
                values[index].StoryTheme[childindex].Program[subchildindex].FollowerCube = "";
                values[index].StoryTheme[childindex].Program[subchildindex].FollowerCubeIds = "";
            } else {
                let strValue = "";
                for (let Sku of event) {
                    if (strValue === "") {
                        strValue = Sku.value.toString();
                    } else {
                        strValue = strValue + "," + Sku.value;
                    }
                }
                values[index].StoryTheme[childindex].Program[subchildindex].FollowerCube = strValue;
                values[index].StoryTheme[childindex].Program[subchildindex].FollowerCubeIds = event;
            }
        }
        setInputFields(values);
    }

    const handleSaveFollower = (index, childindex, subchildindex) => {

        if (values[index].StoryTheme[childindex].Program[subchildindex].FollowerBuyerIds === "" ||
            values[index].StoryTheme[childindex].Program[subchildindex].FollowerCubeIds === "") {
            Nodify('Warning!', 'warning', "Please fill all mandatory(*) fields.");
            setfollowerpopupvalidation(true);
            return false;
        }
        else {
            let followebuyers = '';
            let followecubes = '';
            let followebuyersdisplay = '';
            let followecubesdisplay = '';
            values[index].StoryTheme[childindex].Program[subchildindex].FollowerBuyerIds &&
                values[index].StoryTheme[childindex].Program[subchildindex].FollowerBuyerIds.forEach(element => {
                    if (followebuyers === '') {
                        followebuyersdisplay = element.label;
                        followebuyers = element.value;
                    }
                    else {
                        followebuyersdisplay = followebuyersdisplay + ',' + element.label;
                        followebuyers = followebuyers + ',' + element.value;
                    }
                })
            values[index].StoryTheme[childindex].Program[subchildindex].FollowerCubeIds &&
                values[index].StoryTheme[childindex].Program[subchildindex].FollowerCubeIds.forEach(element => {
                    if (followecubes === '') {
                        followecubesdisplay = element.label;
                        followecubes = element.value;
                    }
                    else {
                        followecubesdisplay = followecubesdisplay + ',' + element.label;
                        followecubes = followecubes + ',' + element.value;
                    }
                })
            values[index].StoryTheme[childindex].Program[subchildindex].Follower = followecubes + "," + followebuyers;
            values[index].StoryTheme[childindex].Program[subchildindex].FollowerDisplay = followecubesdisplay + "," + followebuyersdisplay;
            values[index].StoryTheme[childindex].Program[subchildindex].IsFolower = 0;
        }
        setInputFields(values);

    }
    const handleRemoveProgram = (index, childindex, subchildindex) => {
        values[index].StoryTheme[childindex].Program.splice(subchildindex, 1);
        setInputFields(values);
    }

    const handleAddProgram = (operation, index, childindex, subchildindex) => {
        setSubmitted(false);
        let isvalid = true;
        var copy = values[index].StoryTheme[childindex].Program[subchildindex];
        values[index].StoryTheme[childindex].Program.forEach(element1 => {
            if (element1.PantoneType === 0 || element1.PantoneIdOrSwatchId === 0 || element1.FabricTypeOrYarn === 0 || element1.ProcessTypeId === 0
                || element1.ApprovalTypeId === 0 || element1.FollowerDisplay === '') {
                isvalid = false;

            }
        })
        if (isvalid === false) {
            setSubmitted(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            return false;
        }
        else {
            let EmptyList = {};
            if (operation === "Add") {
                EmptyList = {
                    GenLapdipProgramInfoId: 0,
                    PantoneType: 0,
                    PantoneIdOrSwatchId: 0,
                    ColororSwatchImage: '',
                    RefColorName: '',
                    FabricTypeOrYarn: 0,
                    ProcessTypeId: 0,
                    ApprovalTypeId: 0,
                    IsFolower: 0,
                    Follower: '',
                    FollowerDisplay: '',
                    FollowerBuyer: '',
                    FollowerBuyerIds: '',
                    FollowerCube: '',
                    FollowerCubeIds: '',
                    IsCritical: 0,
                    IsManual: 0,
                    CommonApprovalId: 0,
                    PantoneTypeName: '',
                    IndexName: '',
                    StoryThemeIndexName: '',
                    CriticalInfo: [{
                        GenLapdipProgramCirticalInfoId: 0, CriticalComment: '', RemindMe: 0, CheckSave: false,
                        TargettoClose: '', CompletedOn: '', IndexName: '', StoryThemeIndexName: '', ProgramIndexName: ''
                    }],
                    IsManualInfo: [{ GenLapdipManulCloseCommentId: 0, ManualCloseComment: '', ManualCloseDate: '', CheckSave: false, StyleProgramIndexName: '', IsDeleted: 0, Files: [] }],

                }



            }
            else {
                let CopyCritical = [];
                if (copy.CriticalInfo[0].CriticalComment === '') {
                    CopyCritical = [{
                        GenLapdipProgramCirticalInfoId: 0, CriticalComment: '', RemindMe: 0, CheckSave: false,
                        TargettoClose: '', CompletedOn: '', IndexName: '', StoryThemeIndexName: '', ProgramIndexName: ''
                    }]
                } else {
                    CopyCritical = [{
                        GenLapdipProgramCirticalInfoId: copy.CriticalInfo[0].GenLapdipProgramCirticalInfoId, CriticalComment: copy.CriticalInfo[0].CriticalComment,
                        RemindMe: parseInt(copy.CriticalInfo[0].RemindMe), CheckSave: false,
                        TargettoClose: copy.CriticalInfo[0].TargettoClose, CompletedOn: copy.CriticalInfo[0].CompletedOn,
                        IndexName: '', StoryThemeIndexName: '', ProgramIndexName: ''
                    }]
                }
                let CopyIsManualInfo = [];
                if (copy.IsManualInfo[0].ManualCloseComment === '') {
                    CopyIsManualInfo = [{
                        GenLapdipManulCloseCommentId: 0, ManualCloseComment: '', CheckSave: false,
                        StoryThemeIndexName: '', ProgramIndexName: '', Files: []
                    }]


                } else {
                    CopyIsManualInfo = [{
                        GenLapdipManulCloseCommentId: copy.IsManualInfo[0].GenLapdipManulCloseCommentId, ManualCloseComment: copy.IsManualInfo[0].ManualCloseComment,
                        CheckSave: false, StoryThemeIndexName: '', ProgramIndexName: '', Files: []

                    }]
                    copy.IsManualInfo[0].Files.forEach(fileDetail => {
                        CopyIsManualInfo[0].Files.push({
                            GenLapdipManulCloseCommentFileId: fileDetail.genLapdipManulCloseCommentFileId,
                            fileType: fileDetail.fileType,
                            fileName: fileDetail.fileName,
                            filePath: fileDetail.filePath,
                            storyThemeIndexName: fileDetail.storyThemeIndexName,
                            programIndexName: fileDetail.programIndexName,
                            FileIndex: fileDetail.fileIndex
                        })
                    })


                }





                EmptyList = {
                    GenLapdipProgramInfoId: copy.GenLapdipProgramInfoId,
                    PantoneType: copy.PantoneType,
                    PantoneIdOrSwatchId: copy.PantoneIdOrSwatchId,
                    ColororSwatchImage: copy.ColororSwatchImage,
                    RefColorName: copy.RefColorName,
                    FabricTypeOrYarn: copy.FabricTypeOrYarn,
                    ProcessTypeId: copy.ProcessTypeId,
                    ApprovalTypeId: copy.ApprovalTypeId,
                    IsFolower: copy.IsFolower,
                    Follower: copy.Follower,
                    FollowerDisplay: copy.FollowerDisplay,
                    FollowerBuyer: copy.FollowerBuyer,
                    FollowerCube: copy.FollowerCube,
                    IsCritical: copy.IsCritical,
                    IsManual: copy.IsManual,
                    PantoneTypeName: copy.PantoneTypeName,
                    IndexName: copy.IndexName,
                    StoryThemeIndexName: copy.StoryThemeIndexName,
                    CommonApprovalId: copy.CommonApprovalId,
                    labdibSubmissionApprovalID: copy.CommonApprovalId,
                    CriticalInfo: CopyCritical,
                    IsManualInfo: CopyIsManualInfo,
                    FollowerCubeIds: [],
                    FollowerBuyerIds: []
                }
                //FollowerCubeIds and FollowerBuyerIds Added
                let arrValcube = [];
                if (copy.FollowerCube) {
                    let arrcube = copy.FollowerCube.split(',');

                    for (let size of arrcube) {
                        let res = getFollowerCubeBuyerList.followerCubeDropdown.filter(x => x.value === parseInt(size));
                        res && res.length > 0 && arrValcube.push({
                            value: res[0].value,
                            label: res[0].label
                        });
                    }
                }
                let arrValbuyer = [];
                if (copy.FollowerBuyer) {
                    let arrbuyer = copy.FollowerBuyer.split(',');
                    for (let size of arrbuyer) {
                        let res = getFollowerCubeBuyerList.followerBuyerDropdown.filter(x => x.value === parseInt(size));
                        res && res.length > 0 && arrValbuyer.push({
                            value: res[0].value,
                            label: res[0].label
                        });
                    }
                }
                EmptyList.FollowerCubeIds = arrValcube;
                EmptyList.FollowerBuyerIds = arrValbuyer;

            }
            values[index].StoryTheme[childindex].Program.splice(subchildindex + 1, 0, EmptyList);
            setInputFields(values);
            dispatch(updateProgramGridList(index, childindex, subchildindex + 1, copy.CommonApprovalId));
        }
    }





    const CustomInput = (props) => {
        return (
            <>
                <input
                    className="form-control DatePickerCalenderHoliday"
                    onClick={props.onClick}
                    value={props.value}
                    type="text"
                    readOnly={true}
                    placeholder="DD/MM/YYYY"
                    style={{ width: '250px', border: submitted && props.value === '' ? '1px solid red' : '' }}

                />
            </>
        )
    }

    const TargettocloseInput = (props) => {
        return (
            <>
                <input
                    className="form-control DatePickerCalenderHoliday"
                    onClick={props.onClick}
                    value={props.value}
                    type="text"
                    readOnly={true}
                    placeholder="DD/MM/YYYY"
                    //style={{ width: '250px', border: submitted && props.value === '' ? '1px solid red' : '' }}
                    // style={{
                    //     border: critPopupValidation && (Crititem.TargettoClose === '' || Crititem.TargettoClose === null || Crititem.TargettoClose === "1/1/1970") ? '1px solid red !important' : ''
                    // }}
                    style={{ width: '250px', border: critPopupValidation && props.value === '' ? '1px solid red' : '' }}
                />
            </>
        )
    }

    const CustomInputCompletedOn = (props) => {
        return (
            <>
                <input
                    className="form-control DatePickerCalenderHoliday"
                    onClick={props.onClick}
                    value={props.value}
                    type="text"
                    readOnly={true}
                    placeholder="DD/MM/YYYY"
                    style={{ width: '250px' }}
                //style={{ width: '250px', border: submitted && props.value === '' ? '1px solid red' : '' }}
                // style={{
                //     border: critPopupValidation && (Crititem.TargettoClose === '' || Crititem.TargettoClose === null || Crititem.TargettoClose === "1/1/1970") ? '1px solid red !important' : ''
                // }}
                // style={{ width: '250px', border: critPopupValidation && props.value === '' ? '1px solid red' : '' }}
                />
            </>
        )
    }

    const viewtoEdit = (e) => {

        SetButtonvisible("Edit");
        setHeader("Edit");
        SetButtonview("Hide");
    }
    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/SamGenlLabdipList",
            state: {
                message: Func
            }
        });
    }
    //manual popup
    const showManualClose = (index, childindex, subchildindex, GenLabdipId) => {
        setNewFileState([]);
        let FileDeleteId = [];
        values[index].StoryTheme[childindex].Program[subchildindex].IsManual = 1;
        var newfileid = values.filter(x => x.GenLabdipId === GenLabdipId)
        newfileid[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo[0].Files.map((x) => {
            FileDeleteId.push({
                GenLapdipManulCloseCommentFileId: x.GenLapdipManulCloseCommentFileId,
                fileName: x.fileName,
                filePath: x.filePath,
                fileType: x.fileType,
                programIndexName: x.programIndexName,
                storyThemeIndexName: x.storyThemeIndexName,
                fileIndex: x.FileIndex,
                IsDeleted: 0

            })
            setNewFileState(FileDeleteId)
        })
        setSubmittedPop(false);
        setFileIndex(index);
        setInputFields(values);


    }


    const handleCloseManualPopup = (index, childindex, subchildindex) => {
        if (getHeader !== "Edit") {
            if (values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo[0].IsSaved === 1) {
                values[index].StoryTheme[childindex].Program[subchildindex].IsManual = 0
            } else {
                values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo[0].ManualCloseComment = '';
                values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo[0].Files = [];
                values[index].StoryTheme[childindex].Program[subchildindex].IsManual = 0
            }

        } else {
            values[index].StoryTheme[childindex].Program[subchildindex].IsManual = 0
        }
        setInputFields(values);
        setSubmittedPop(false);

    };
    const ClearManual = (index, childindex, subchildindex) => {
        if (getHeader !== "Edit") {
            values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo[0].ManualCloseDate = '';
            values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo[0].ManualCloseComment = '';
            values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo[0].Files = [];
            values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo[0].IsSaved = 0;
        } else {
            values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo[0].ManualCloseDate = '';
            values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo[0].ManualCloseComment = '';
            values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo[0].Files = [];
            values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo[0].IsSaved = 0;
            values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo[0].IsDeleted = 1;
        }
        //values[index].ApprovalTypeID = 0;
        setInputFields(values);

    }
    const handleChangeIsManualCloseComments = (event, index, childindex, subchildindex, feild) => {

        let input = '';

        //  const values = [...inputFields];
        if (feild === 'Comment') {
            if (event.target.value.trim() !== '') {
                input = event.target.value;
            }
            values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo[0].ManualCloseComment = input;
        }
        else {
            values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo[0].ManualCloseDate = event;
        }
        setInputFields(values);
        //ProgramCallback(values);


    }

    const RemoveFiles = async (index, childindex, subchildindex, fileindex, GenLapdipManulCloseCommentFileId, programIndexName, fileIndex) => {
        if (getHeader === "Edit") {

            const Mainindexs = values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo[0].Files.filter(x => x.programIndexName === programIndexName)
            let storeindex = newFilestate.filter(x => x.GenLapdipManulCloseCommentFileId !== 0 && x.IsDeleted !== 1)

            if (storeindex.length === Mainindexs.length) {
                newFilestate[fileindex].IsDeleted = 1;
                values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo[0].Files = newFilestate
            } else {
                const indexs = values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo[0].Files.findIndex(obj => obj.GenLapdipManulCloseCommentFileId === GenLapdipManulCloseCommentFileId && obj.fileIndex === fileIndex)
                values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo[0].Files[indexs].IsDeleted = 1;
            }
        } else {
            values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo[0].Files.splice(fileindex, 1);
            setFiles(values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo[0].Files);
            values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo[0].Files = values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo[0].Files;
        }
        setInputFields(values);
    }

    const ViewFile = (event, filename) => { // event.preventDefault();

        setIsOpenimg(true);
        setImagename(filename);
    }

    const ViewSwatchImage = (event, filename) => { // event.preventDefault();

        setIsOpenimg(true);
        setImagename(filename);
    }


    const filevals = [...getFiles]
    const [getFileIndex, setFileIndex] = useState();
    const AppendFiles = async (e, index, STindex, progindex) => {
        setFileIndex(index);
        const file = e.target.files[0];
        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png'];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#FileUpload').val("");
            }
            else {
                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "TNA");
                try {
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                    values[index].StoryTheme[STindex].Program[progindex].IsManualInfo[0].Files.push({
                        GenLapdipManulCloseCommentFileId: 0,
                        fileName: file.name,
                        filePath: "Images/TNAImages/" + file.name,
                        fileType: file.type,
                        programIndexName: getHeader === "Edit" ? Pindexforedit : progindex.toString(),
                        storyThemeIndexName: getHeader === "Edit" ? STindexforedit : STindex.toString(),
                        fileIndex: values[index].StoryTheme[STindex].Program[progindex].IsManualInfo[0].Files.length.toString(),
                        IsDeleted: 0
                    })
                    //values[index].IsManual[subindex].Files = filevals;
                    setInputFields(values);
                    setFiles(filevals);
                    $('#FileUpload').val("");

                } catch (ex) {
                    console.log(ex);
                }
            }
        }
    }

    const handleSaveManual = (index, childindex, subchildindex) => {

        //values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo[0].Manual = 0;
        values[index].StoryTheme[childindex].Program[subchildindex].IsManual = 0;
        if (values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo[0].ManualCloseComment === '') {
            Nodify("Warning!", "warning", "Please enter comments");
            setSubmittedPop(true);
            values[index].StoryTheme[childindex].Program[subchildindex].IsManual = 1;
            return false;
        } else {
            values[index].StoryTheme[childindex].Program[subchildindex].IsManualInfo[0].IsSaved = 1;
        }
        setInputFields(values);
        // ProgramCallback(values);
        //setSubmittedPop(false);

    };
    const handleCloseLapDipMapPopup = (index, childindex, subchildindex) => {
        values[index].StoryTheme[childindex].Program[subchildindex].IsExiting = 0;
        setInputFields(values);
        setSubmittedPop(false);


    };



    const getGENLabDipMapList = (index, childindex, subchildindex, PantoneID, PantoneTypeID) => {

        let BuyerID = values[0].BuyerId;
        let BrandID = values[0].BrandId;
        // let SeasonID = props.location.state.params.seasonID;
        let params = { BuyerID: BuyerID, BrandID: BrandID, PantoneID: PantoneID, PantoneTypeID: PantoneTypeID }
        dispatch(getGenLabDipAlreadyMapList(params))
            .then(isSuccess => {
                if (isSuccess) {
                    values[index].StoryTheme[childindex].Program[subchildindex].IsExiting = 1;
                    setInputFields(values);
                } else {
                    Nodify("Warning!", "warning", "This data not approved");
                }


            })
            .catch(e => {
                console.log(e);
            });
    }

    const OnSelectRecords = (event, index, childindex, subchildindex, labdibSubmissionApprovalID) => {
        //
        if (event.target.checked === true) {

        } else {
            labdibSubmissionApprovalID = 0;
        }
        dispatch(updateProgramGridList(index, childindex, subchildindex, labdibSubmissionApprovalID));
        values[index].StoryTheme[childindex].Program[subchildindex].labdibSubmissionApprovalID = labdibSubmissionApprovalID;
        values[index].StoryTheme[childindex].Program[subchildindex].CommonApprovalId = labdibSubmissionApprovalID;
        setInputFields(values);
    }

    const saveLapDipMap = (index, childindex, subchildindex) => {
        values[index].StoryTheme[childindex].Program[subchildindex].IsExiting = 0;
        setInputFields(values);
        //ProgramCallback(values);
    }
    const SaveGeneralLabdip = () => {
        setDisableSave(true);
        let Isvalid = true;
        let IsvalidStoryId = true;
        let IsvalidThemeId = true;
        let IsvalidManaual = true;
        let Params = {};

        if (values[0].BuyerId === 0 || values[0].BrandId === 0 || values[0].SeasonId === 0 || values[0].ReceivedDate === '') {
            Isvalid = false;
        }
        values[0].StoryTheme.forEach(element => {
            if (values[0].IsStoryMandatory === 1 && element.StoryId === 0) {
                IsvalidStoryId = false;
            }
            if (values[0].IsThemeMandatory === 1 && element.ThemeId === 0) {
                IsvalidThemeId = false;
            }

            element.Program.forEach(element1 => {
                if (parseInt(element1.PantoneType) === 0 || parseInt(element1.PantoneIdOrSwatchId) === 0 || parseInt(element1.FabricTypeOrYarn) === 0 ||
                    parseInt(element1.ProcessTypeId) === 0 || parseInt(element1.ApprovalTypeId) === 0 || element1.FollowerDisplay === '') {
                    Isvalid = false;
                }
                element1.IsManualInfo.forEach((element2, ind2) => {
                    if (element1.ApprovalTypeId === 2 && element2.ManualCloseComment === '') {
                        IsvalidManaual = false;
                    }
                })
            })
        })

        if (Isvalid === false) {
            setSubmitted(true);
            setDisableSave(false);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            return false;
        }
        else if (IsvalidStoryId === false) {
            setSubmitted(true);
            setDisableSave(false);
            Nodify('Warning!', 'warning', 'Please select a story for this buyer.');
            return false;
        }
        else if (IsvalidThemeId === false) {
            setSubmitted(true);
            setDisableSave(false);
            Nodify('Warning!', 'warning', 'Please select a theme for this buyer.');
            return false;
        }
        else if (IsvalidManaual === false) {
            setSubmittedPop(true);
            setDisableSave(false);
            Nodify('Warning!', 'warning', "Manual data should be fill when approval type is Manual")
        }
        // if (values[0].IsStoryMandatory === 1 && values[0].StoryID === 0) {
        //     Nodify('Warning!', 'warning', 'Please select a story for this buyer.');
        //     setSubmitted(true);
        //     setDisableSave(false);
        //     return false;

        // }
        // else if (values[0].IsThemeMandatory === 1 && values[0].ThemeID === 0) {
        //     Nodify('Warning!', 'warning', 'Please select a theme for this buyer.');
        //     setSubmitted(true);
        //     setDisableSave(false);
        //     return false;

        // }
        else {
            Params = {
                Operation: parseInt(values[0].Operation), BuyerId: parseInt(values[0].BuyerId), BrandId: parseInt(values[0].BrandId),
                SeasonId: parseInt(values[0].SeasonId), ReceivedDate: convertUTCDateToLocalDate(new Date(values[0].ReceivedDate)), GenLabdipId: parseInt(values[0].GenLabdipId),
                StoryThemeListInfo: [], ProgramListInfo: [], CriticalInfo: [], ManualInfo: [], ManualInfoFiles: [],
            }


            values[0].StoryTheme.forEach((element, stindex) => {
                Params.StoryThemeListInfo.push({
                    GenLapdipStoryThemeId: parseInt(element.GenLapdipStoryThemeId), StoryId: parseInt(element.StoryId),
                    ThemeId: parseInt(element.ThemeId), IndexName: getHeader === "Edit" ? element.IndexName : stindex.toString()
                })

                element.Program.forEach((element1, pindex) => {
                    Params.ProgramListInfo.push({
                        GenLapdipProgramInfoId: parseInt(element1.GenLapdipProgramInfoId), PantoneType: parseInt(element1.PantoneType), PantoneIdOrSwatchId: parseInt(element1.PantoneIdOrSwatchId),
                        PantoneColorOrSwatchImage: element1.ColororSwatchImage, RefColorName: element1.RefColorName, FabricTypeOrYarn: parseInt(element1.FabricTypeOrYarn),
                        ProcessTypeId: parseInt(element1.ProcessTypeId), ApprovalTypeId: parseInt(element1.ApprovalTypeId), Follower: element1.Follower, FollowerBuyer: element1.FollowerBuyer,
                        FollowerCube: element1.FollowerCube, IsCritical: parseInt(element1.IsCritical), IsManual: parseInt(element1.IsManual), CommonApprovalId: parseInt(element1.CommonApprovalId),
                        StoryThemeIndexName: getHeader === "Edit" ? element1.StoryThemeIndexName : stindex.toString(),
                        IndexName: getHeader === "Edit" ? element1.IndexName : pindex.toString(), AssignFabricID: parseInt(element1.FabricTypeOrYarn)
                    })

                    element1.CriticalInfo.forEach((element2, cindex) => {
                        Params.CriticalInfo.push({
                            GenLapdipProgramCirticalInfoId: parseInt(element2.GenLapdipProgramCirticalInfoId), CriticalComment: element2.CriticalComment,
                            TargettoClose: element2.TargettoClose, CompletedOn: element2.CompletedOn, RemindMe: parseInt(element2.RemindMe),
                            StoryThemeIndexName: parseInt(element2.GenLapdipProgramCirticalInfoId) !== 0 ? element2.StoryThemeIndexName
                                : parseInt(element1.GenLapdipProgramInfoId) !== 0 ? element1.StoryThemeIndexName : stindex.toString(),
                            ProgramIndexName: parseInt(element2.GenLapdipProgramCirticalInfoId) !== 0 ? element2.ProgramIndexName :
                                parseInt(element1.GenLapdipProgramInfoId) !== 0 ? element1.IndexName : pindex.toString(),
                            IndexName: parseInt(element2.GenLapdipProgramCirticalInfoId) !== 0 ? element2.IndexName : cindex.toString(),


                        })
                    })

                    element1.IsManualInfo.forEach((element3, Mindex) => {

                        Params.ManualInfo.push({
                            GenLapdipManulCloseCommentId: parseInt(element3.GenLapdipManulCloseCommentId),
                            ManualCloseComment: element3.ManualCloseComment,
                            StoryThemeIndexName: getHeader === "Edit" ? STindexforedit : stindex.toString(),
                            ProgramIndexName: getHeader === "Edit" ? Pindexforedit : pindex.toString(),
                            IsDeleted: element3.IsDeleted
                        })
                        if (element3.Files) {
                            if (element3.Files.length !== 0) {
                                element3.Files.forEach((f, k, i) => {
                                    let genLapdipManulCloseCommentFileId = (f.GenLapdipManulCloseCommentFileId) === undefined ? 0 : parseInt(f.GenLapdipManulCloseCommentFileId);
                                    let fileType = f.fileType === undefined ? "" : (f.fileType).toString();
                                    let fileName = f.fileName === undefined ? "" : (f.fileName).toString();
                                    let filePath = f.filePath === undefined ? "" : (f.filePath).toString();
                                    // let storyThemeIndexName = ((f.storyThemeIndexName) === undefined || (f.storyThemeIndexName) === "") ? stindex.toString() : (f.storyThemeIndexName).toString();
                                    //  let programIndexName = ((f.programIndexName) === undefined || (f.programIndexName) === "") ? pindex.toString() : (f.programIndexName).toString();
                                    let fileIndex = (k.toString())
                                    let IsDeleted = f.IsDeleted === undefined ? 0 : f.IsDeleted
                                    Params.ManualInfoFiles.push({
                                        GenLapdipManulCloseCommentFileId: genLapdipManulCloseCommentFileId,
                                        FileType: fileType,
                                        FileName: fileName,
                                        FilePath: filePath,
                                        StoryThemeIndexName: getHeader === "Edit" ? STindexforedit : stindex.toString(),
                                        ProgramIndexName: getHeader === "Edit" ? Pindexforedit : pindex.toString(),
                                        FileIndex: fileIndex,
                                        IsDeleted: IsDeleted

                                    })
                                })
                                // StyleProgramManualFileInformation = man.Files
                            }
                        }

                    })

                })
            })
            // For Isproduction Check
            Params.IsProduction = IsProduction;
            GeneralService.InsertGeneralLabdip(Params).then((res) => {
                setDisableSave(false);
                var page = "Remove";
                SearchRetain(page);
                let Func = 'Add';
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "-2") {
                    Nodify('Warning!', 'warning', "Error Occured");
                }

            })


        }


    }

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/SamGenlLabdipList')
        } else {
            window.location.reload();
        }
    }

    function convertUTCDateToLocalDate(date) {
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date;
    }


    {/* New changes by hari for popup start */ }
    const pantoneTypeCallback = (value) => {
        if (value === true) {
            PantoneService.getPantonelist(1, 0, 0).then((response) => {
                if (response.data) {
                    setPantoneType(response.data);
                }
            });

            PantoneService.loadPantoneTypeList()
                .then((response) => {
                    if (response.data) {
                        setAllPantonetypeList(response.data);
                    }
                })
                .catch(() => { });

            setIsPantonetypePopupOpen(false);
        } else {
            setIsPantonetypePopupOpen(false);
        }
    };

    const processTypeCallback = (value) => {
        if (value === true) {
            styleService
                .LoadProcessType()
                .then((response) => {
                    if (response.data) {
                        setProcessTypeList(response.data);
                        // setProcessTypeList(response.data);
                        var ProcessType = response.data;
                        var maxid = ProcessType.reduce(
                            (max, data) => Math.max(max, data.value),
                            ProcessType[0].value
                        );
                        setMaxidProcessType(maxid);
                        values[0].ProcessTypeId = maxid;
                        setInputFields(values);
                    } else {
                    }
                })
                .catch(() => { });

            setProcessTypePopupOpen(false);
        } else {
            setProcessTypePopupOpen(false);
        }
    };
    const pantoneCallback = (value) => {
        if (value === true) {
            PantoneService.getPantonelist(1, 0, 0).then((response) => {
                if (response.data) {
                    setPantoneType(response.data);
                }
            });

            PantoneService.LoadPantoneList().then((response) => {
                if (response.data) {
                    setPantone(response.data);
                }
            });

            setIsPantonePopupOpen(false);
        } else {
            setIsPantonePopupOpen(false);
        }
    };

    const swatchCallback = (value) => {
        if (value === true) {
            var BuyerID = props.location.state.params.buyerID;
            var BrandID = props.location.state.params.brandID;
            PantoneService.LoadSwatchList(BuyerID, BrandID)
                .then((response) => {
                    if (response.data.length > 0) {
                        setSwatch(response.data);
                    }
                })
                .catch(() => { });

            setIsSwatchPopupOpen(false);
        } else {
            setIsSwatchPopupOpen(false);
        }
    };

    const handleAddPantoneType = () => {
        setIsPantonetypePopupOpen(true);
    };

    const handleProcessType = () => {
        setProcessTypePopupOpen(true);
    };

    const handleAddPantone = () => {
        setIsPantonePopupOpen(true);
    };

    const handleAddSwatch = () => {
        setIsSwatchPopupOpen(true);
    };


    {/* New changes by hari for popup end */ }








    return (
        <>
            {
                <div className="page-body">
                    <ReactNotification />
                    <div className="row">
                        <div className="col-lg-12 col-sm-12 col-xs-12">

                            <div className="widget flat radius-bordered">
                                <div className="widget-header heading_box_style">
                                    <h3 className="heading_style_common">{getHeader} General Lab Dip </h3>
                                    <div className="buttons-preview" align="right">

                                        <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                            <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                                        </span>
                                        <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                            <i className="fa fa-times"></i>&nbsp;Reset
                                        </span>
                                        {Buttonvisible !== "View" &&
                                            (<button type="submit" className="btn btn-success" onClick={(e) => SaveGeneralLabdip(e)} disabled={getDisableSave}>

                                                <i className="fa fa-check right"></i> &nbsp;{getHeader === 'Add' ? 'Save' : 'Update'}
                                            </button>)
                                        }
                                        {Buttonvisible === "View" && activeMenu[0].isView === 1 ? '' :
                                            activeMenu[0].isView === 0 ? '' :
                                                (<button type="submit" style={{ display: activeMenu[0].isView === 0 ? '' : 'none' }} className="btn btn-info" onClick={(e) => viewtoEdit(e)}>
                                                    <i className="fa fa-edit"></i>&nbsp;Edit
                                                </button>)
                                        }
                                    </div>
                                </div>
                                <div className="widget-body">
                                    <div id="registration-form">
                                        <Form>
                                            {
                                                <div className="row">
                                                    {
                                                        inputFields.map((item, index) => (
                                                            <Fragment key={`${item}~${index}`}>
                                                                <Form role="form">

                                                                    <div className="col-lg-12">
                                                                        <div className="row">
                                                                            <div className="col-sm-3">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="Buyer">
                                                                                        Buyer
                                                                                        <span className="text-danger">*</span>
                                                                                    </label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <Reactselect className="basic-single" name="Buyer"
                                                                                            id={
                                                                                                "Buyer"
                                                                                            }
                                                                                            classNamePrefix="react-select"
                                                                                            isLoading={false}
                                                                                            // isClearable={true}
                                                                                            isSearchable={true}
                                                                                            onChange={event => handlechange(event, index, 'BuyerId')}
                                                                                            value={getBuyerList.filter(function (option) {
                                                                                                return option.value === item.BuyerId;
                                                                                            })}
                                                                                            options={getBuyerList}
                                                                                            styles={submitted && item.BuyerId === 0 ? styles2 : styles1}
                                                                                            isDisabled={getHeader === "Edit" ? true : false}

                                                                                        ></Reactselect>
                                                                                    </span>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-sm-3">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="SupplierID">
                                                                                        Brand
                                                                                        <span className="text-danger">*</span>
                                                                                    </label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <Reactselect className="basic-single" name="Brand"
                                                                                            id={
                                                                                                "Brand"
                                                                                            }
                                                                                            classNamePrefix="react-select"
                                                                                            isLoading={false}
                                                                                            // isClearable={true}
                                                                                            isSearchable={true}
                                                                                            onChange={event => handlechange(event, index, 'BrandId')}
                                                                                            value={getBrandList.filter(function (option) {
                                                                                                return option.value === item.BrandId;
                                                                                            })}
                                                                                            options={getBrandList}
                                                                                            styles={submitted && item.BrandId === 0 ? styles2 : styles1}
                                                                                            isDisabled={getHeader === "Edit" ? true : false}
                                                                                        ></Reactselect>
                                                                                    </span>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-sm-3">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="Name">
                                                                                        Season
                                                                                        <span className="text-danger">*</span>
                                                                                    </label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <Reactselect className="basic-single" name="Season"
                                                                                            id={
                                                                                                "Season"
                                                                                            }
                                                                                            classNamePrefix="react-select"
                                                                                            isLoading={false}
                                                                                            // isClearable={true}
                                                                                            isSearchable={true}
                                                                                            onChange={event => handlechange(event, index, 'SeasonId')}
                                                                                            value={getSeasonList.filter(function (option) {
                                                                                                return option.value === item.SeasonId;
                                                                                            })}

                                                                                            options={getSeasonList}
                                                                                            styles={submitted && item.SeasonId === 0 ? styles2 : styles1}
                                                                                            isDisabled={getHeader === "Edit" ? true : false}
                                                                                        ></Reactselect>

                                                                                    </span>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-sm-3">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="Name">
                                                                                        Received Date
                                                                                        <span className="text-danger">*</span>
                                                                                    </label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <DatePicker className="form-control" name="ReceivedDate"
                                                                                            id={
                                                                                                "ReceivedDate" + index
                                                                                            }
                                                                                            selected={
                                                                                                item.ReceivedDate
                                                                                            }
                                                                                            onChange={event => handlechange(event, index, "ReceivedDate")}
                                                                                            dateFormat="dd/MM/yyyy"
                                                                                            peekNextMonth
                                                                                            showMonthDropdown
                                                                                            dropdownMode="scroll"
                                                                                            autoComplete="off"
                                                                                            customInput={<CustomInput />}
                                                                                            style={{ border: submitted && item.ReceivedDate === '' ? '1px solid red' : '' }}
                                                                                            disabled={getHeader === "Edit" ? true : false}
                                                                                        />

                                                                                    </span>
                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12">

                                                                        {
                                                                            item.StoryTheme.map((STitem, STindex) => {
                                                                                return (

                                                                                    <>
                                                                                        <div className="row">
                                                                                            <div className="col-sm-3">
                                                                                                <div className="form-group">
                                                                                                    <label htmlFor={"Story" + STindex}>
                                                                                                        Story
                                                                                                        {
                                                                                                            item.IsStoryMandatory === 1 ?
                                                                                                                <span className="text-danger">*</span> : ''
                                                                                                        }

                                                                                                    </label>
                                                                                                    <span className="input-icon icon-right">
                                                                                                        <Reactselect className="basic-single" name="StoryID"
                                                                                                            id={
                                                                                                                "StoryID"
                                                                                                            }
                                                                                                            classNamePrefix="react-select"
                                                                                                            // isDisabled={false}
                                                                                                            isLoading={false}
                                                                                                            // isClearable={true}
                                                                                                            isSearchable={true}
                                                                                                            options={getStoryList}
                                                                                                            // value={getStyleList.StoryID}
                                                                                                            value={getStoryList.filter(function (option) {
                                                                                                                return option.value === STitem.StoryId;
                                                                                                            })}
                                                                                                            onChange={event => handleChangeStoryTheme(event, index, STindex, 'StoryId')}
                                                                                                            // onBlur={() => CheckDuplicateStoryTheme(index, STindex)}
                                                                                                            styles={submitted && item.IsStoryMandatory === 1 && STitem.StoryId === 0 ? styles2 : styles1}
                                                                                                        //  isDisabled={getHeader === "Edit" ? true : false}

                                                                                                        ></Reactselect>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-sm-3">
                                                                                                <div className="form-group">
                                                                                                    <label htmlFor={"Theme" + STindex}>
                                                                                                        Theme

                                                                                                        {
                                                                                                            item.IsThemeMandatory === 1 ?
                                                                                                                <span className="text-danger">*</span> : ''
                                                                                                        }

                                                                                                        {/* <span className="text-danger">*</span> */}
                                                                                                    </label>
                                                                                                    <span className="input-icon icon-right">
                                                                                                        <Reactselect className="basic-single" name="ThemeID"
                                                                                                            id={
                                                                                                                "ThemeID"
                                                                                                            }
                                                                                                            classNamePrefix="react-select"
                                                                                                            // isDisabled={false}
                                                                                                            isLoading={false}
                                                                                                            // isClearable={true}
                                                                                                            isSearchable={true}
                                                                                                            options={getThemeList}
                                                                                                            // value={getStyleList.ThemeID}
                                                                                                            value={getThemeList.filter(function (option) {
                                                                                                                return option.value === STitem.ThemeId;
                                                                                                            })}
                                                                                                            onChange={event => handleChangeStoryTheme(event, index, STindex, 'ThemeId')}
                                                                                                            // onBlur={() => CheckDuplicateStoryTheme(index, STindex)}
                                                                                                            styles={submitted && item.IsThemeMandatory && STitem.ThemeId === 0 ? styles2 : styles1}
                                                                                                        // isDisabled={getHeader === "Edit" ? true : false}

                                                                                                        ></Reactselect>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            {
                                                                                                getHeader === "Add" && (
                                                                                                    <div className="col-sm-3" style={{ marginTop: "36px" }}>
                                                                                                        {item.StoryTheme.length !== 1 && (
                                                                                                            <span >
                                                                                                                <button type="button" className="btn  btn-xs  btn-danger" title="Delete Story Theme"
                                                                                                                    onClick={event => handleRemoveFields(index, STindex)}>
                                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                                </button>
                                                                                                            </span>
                                                                                                        )}
                                                                                                        &nbsp;
                                                                                                        {
                                                                                                            item.StoryTheme.length === (STindex + 1) && (
                                                                                                                <span>
                                                                                                                    <button type="button" className="btn btn-xs btn-success" title="Add Story Theme"
                                                                                                                        onClick={
                                                                                                                            () => handleAddFields('Add', index, STindex)
                                                                                                                        }>
                                                                                                                        <i className="fa fa-plus"></i>
                                                                                                                    </button>
                                                                                                                </span>
                                                                                                            )
                                                                                                        }
                                                                                                        &nbsp;

                                                                                                        <span className="btn btn-primary" title="Copy Story Theme" onClick={() => handleAddFields('Copy', index, STindex)}>
                                                                                                            <i className="fa fa-copy"></i>
                                                                                                        </span>


                                                                                                    </div>
                                                                                                )
                                                                                            }

                                                                                        </div>

                                                                                        <div className="row">
                                                                                            <div className="col-lg-12">

                                                                                                <div className="dataTables_wrapper no-footer">
                                                                                                    {/* style={{ width: "1700px", overflowX: "auto" }} */}
                                                                                                    {
                                                                                                        inputFields[0].StoryTheme[0].Program.length > 0 ?
                                                                                                            <>
                                                                                                                {

                                                                                                                    <table className="table table-bordered"  >
                                                                                                                        {/* style={{ width: "1700px" }} */}
                                                                                                                        <thead className="text-center">
                                                                                                                            <tr>
                                                                                                                                <th
                                                                                                                                    style={{
                                                                                                                                        width: "251px",
                                                                                                                                    }}
                                                                                                                                    className="float-left">



                                                                                                                                    {ProgramInfoQuick.PantoneType.length > 0 && ProgramInfoQuick.PantoneType[0].isQuick === 1 &&
                                                                                                                                        <button type='button' className="glyphicon glyphicon-plus AddPlusIcon" style={{ backgroundColor: "#222d32" }}
                                                                                                                                            onClick={() => handleAddPantoneType()} title="Add Pantone Type">
                                                                                                                                        </button>
                                                                                                                                    }



                                                                                                                                    Pantone Type
                                                                                                                                    <span className="text-danger">
                                                                                                                                        *
                                                                                                                                    </span>
                                                                                                                                </th>

                                                                                                                                <th
                                                                                                                                    style={{
                                                                                                                                        width: "450px",
                                                                                                                                    }}>
                                                                                                                                    {
                                                                                                                                        getSwatchSelected === false ?
                                                                                                                                            ProgramInfoQuick.Pantone.length > 0 && ProgramInfoQuick.Pantone[0].isQuick === 1 &&
                                                                                                                                            <button type='button' className="glyphicon glyphicon-plus AddPlusIcon" style={{ backgroundColor: "#222d32" }}
                                                                                                                                                onClick={() => handleAddPantone()} title="Add Pantone">
                                                                                                                                            </button> : ''
                                                                                                                                    }
                                                                                                                                    Pantone Code&nbsp;/
                                                                                                                                    {ProgramInfoQuick.Swatch.length > 0 && ProgramInfoQuick.Swatch[0].isQuick === 1 &&
                                                                                                                                        getSwatchSelected === true ?

                                                                                                                                        <button type='button' className="glyphicon glyphicon-plus AddPlusIcon" style={{ backgroundColor: "#222d32" }}
                                                                                                                                            onClick={() => handleAddSwatch()} title="Add Swatch">
                                                                                                                                        </button> : ''
                                                                                                                                    }
                                                                                                                                    &nbsp;Swatch Name
                                                                                                                                    <span className="text-danger">
                                                                                                                                        *
                                                                                                                                    </span>
                                                                                                                                </th>

                                                                                                                                <th style={{ width: "150px" }} className="float-left">Color / Swatch Image</th>
                                                                                                                                <th style={{ width: "200px" }} className="float-left">Ref. Color Name</th>
                                                                                                                                <th style={{ width: "200px" }} className="float-left">Item (Fabric / Yarn)<span className="text-danger">*</span></th>
                                                                                                                                <th
                                                                                                                                    style={{
                                                                                                                                        width: "200px",
                                                                                                                                    }}
                                                                                                                                    className="float-left">

                                                                                                                                    {ProgramInfoQuick.ProcessType.length > 0 && ProgramInfoQuick.ProcessType[0].isQuick === 1 &&
                                                                                                                                        <button type='button' className="glyphicon glyphicon-plus AddPlusIcon" style={{ backgroundColor: "#222d32" }}
                                                                                                                                            onClick={() => handleProcessType()}
                                                                                                                                            title="Add ProcessType">
                                                                                                                                        </button>
                                                                                                                                    }



                                                                                                                                    Process Type
                                                                                                                                    <span className="text-danger">
                                                                                                                                        *
                                                                                                                                    </span>
                                                                                                                                </th>
                                                                                                                                <th style={{ width: "200px" }} className="float-left">Approval Type<span className="text-danger">*</span></th>
                                                                                                                                <th style={{ width: "200px" }} className="float-left">Follower<span className="text-danger">*</span></th>
                                                                                                                                <th style={{ width: "220px" }} className="float-left Actionbutton fixed-column-header">Action</th>
                                                                                                                                {/* {
                                                                                                                                    getHeader === 'Add' ?
                                                                                                                                        <th style={{ width: "150px" }} className="text-center Actionbutton fixed-column-header">Action</th> : ''
                                                                                                                                } */}
                                                                                                                            </tr>
                                                                                                                        </thead>
                                                                                                                        <tbody>
                                                                                                                            {
                                                                                                                                STitem.Program &&
                                                                                                                                STitem.Program.map((ProgItem, progindex) => {
                                                                                                                                    return (
                                                                                                                                        <>
                                                                                                                                            <tr>
                                                                                                                                                <td>
                                                                                                                                                    <select
                                                                                                                                                        id={
                                                                                                                                                            "PantoneTypeID"
                                                                                                                                                        }
                                                                                                                                                        name="PantoneTypeID"
                                                                                                                                                        className="form-select"
                                                                                                                                                        value={
                                                                                                                                                            ProgItem.PantoneType
                                                                                                                                                        }
                                                                                                                                                        onChange={(
                                                                                                                                                            event
                                                                                                                                                        ) =>
                                                                                                                                                            handleChangeProgram(
                                                                                                                                                                event,
                                                                                                                                                                index,
                                                                                                                                                                STindex,
                                                                                                                                                                progindex,
                                                                                                                                                                "PantoneType"
                                                                                                                                                            )
                                                                                                                                                        }
                                                                                                                                                        style={{
                                                                                                                                                            border:
                                                                                                                                                                submitted &&
                                                                                                                                                                    ProgItem.PantoneType ===
                                                                                                                                                                    0
                                                                                                                                                                    ? "1px solid red"
                                                                                                                                                                    : "",
                                                                                                                                                        }}>
                                                                                                                                                        <option value="0">
                                                                                                                                                            -Select
                                                                                                                                                            Pantone
                                                                                                                                                            Type-
                                                                                                                                                        </option>
                                                                                                                                                        {getPantoneType.map(
                                                                                                                                                            (
                                                                                                                                                                item
                                                                                                                                                            ) => (
                                                                                                                                                                <option
                                                                                                                                                                    key={
                                                                                                                                                                        item.pantoneTypeID
                                                                                                                                                                    }
                                                                                                                                                                    value={
                                                                                                                                                                        item.pantoneTypeID
                                                                                                                                                                    }>
                                                                                                                                                                    {
                                                                                                                                                                        item.pantoneTypeName
                                                                                                                                                                    }
                                                                                                                                                                </option>
                                                                                                                                                            )
                                                                                                                                                        )}
                                                                                                                                                    </select>
                                                                                                                                                </td>

                                                                                                                                                <td>
                                                                                                                                                    {ProgItem.PantoneTypeName !==
                                                                                                                                                        "Others" ? (
                                                                                                                                                        <span className="input-icon icon-right">
                                                                                                                                                            <select
                                                                                                                                                                id={
                                                                                                                                                                    "PantoneID"
                                                                                                                                                                }
                                                                                                                                                                name="PantoneID"
                                                                                                                                                                className="form-select"
                                                                                                                                                                value={
                                                                                                                                                                    ProgItem.PantoneIdOrSwatchId
                                                                                                                                                                }
                                                                                                                                                                onChange={(
                                                                                                                                                                    event
                                                                                                                                                                ) =>
                                                                                                                                                                    handleChangeProgram(
                                                                                                                                                                        event,
                                                                                                                                                                        index,
                                                                                                                                                                        STindex,
                                                                                                                                                                        progindex,
                                                                                                                                                                        "PantoneIdOrSwatchId"
                                                                                                                                                                    )
                                                                                                                                                                }
                                                                                                                                                                style={{
                                                                                                                                                                    border:
                                                                                                                                                                        submitted &&
                                                                                                                                                                            ProgItem.PantoneIdOrSwatchId ===
                                                                                                                                                                            0
                                                                                                                                                                            ? "1px solid red"
                                                                                                                                                                            : "",
                                                                                                                                                                }}>
                                                                                                                                                                <option value="0">
                                                                                                                                                                    -Select
                                                                                                                                                                    Pantone
                                                                                                                                                                    Code-
                                                                                                                                                                </option>
                                                                                                                                                                {getPantone
                                                                                                                                                                    .filter(
                                                                                                                                                                        (
                                                                                                                                                                            x
                                                                                                                                                                        ) =>
                                                                                                                                                                            x.pantoneTypeId ===
                                                                                                                                                                            parseInt(
                                                                                                                                                                                ProgItem.PantoneType
                                                                                                                                                                            )
                                                                                                                                                                    )
                                                                                                                                                                    .map(
                                                                                                                                                                        (
                                                                                                                                                                            items
                                                                                                                                                                        ) => (
                                                                                                                                                                            <>
                                                                                                                                                                                <option
                                                                                                                                                                                    key={
                                                                                                                                                                                        items.pantoneID
                                                                                                                                                                                    }
                                                                                                                                                                                    value={
                                                                                                                                                                                        items.pantoneID
                                                                                                                                                                                    }
                                                                                                                                                                                    style={{
                                                                                                                                                                                        background:
                                                                                                                                                                                            "url" +
                                                                                                                                                                                            items.pantoneColor +
                                                                                                                                                                                            "96% / 15% no-repeat",
                                                                                                                                                                                        width:
                                                                                                                                                                                            "120px",
                                                                                                                                                                                        height:
                                                                                                                                                                                            "34px",
                                                                                                                                                                                        borderRadius:
                                                                                                                                                                                            "2px",
                                                                                                                                                                                    }}>
                                                                                                                                                                                    {/* style={{ background: (items.pantoneColor)96% / 15% no-repeat, color: '#fff', width: '120px', height: '34px', borderRadius: '2px' }} */}
                                                                                                                                                                                    {
                                                                                                                                                                                        items.pantoneCode
                                                                                                                                                                                    }{" "}
                                                                                                                                                                                    &nbsp;
                                                                                                                                                                                </option>
                                                                                                                                                                            </>
                                                                                                                                                                        )
                                                                                                                                                                    )}
                                                                                                                                                            </select>
                                                                                                                                                            <span className="text"></span>
                                                                                                                                                        </span>
                                                                                                                                                    ) : (
                                                                                                                                                        <span className="input-icon icon-right">
                                                                                                                                                            <select
                                                                                                                                                                id={
                                                                                                                                                                    "PantoneID"
                                                                                                                                                                }
                                                                                                                                                                name="PantoneID"
                                                                                                                                                                className="form-select"
                                                                                                                                                                value={
                                                                                                                                                                    ProgItem.PantoneIdOrSwatchId
                                                                                                                                                                }
                                                                                                                                                                onChange={(
                                                                                                                                                                    event
                                                                                                                                                                ) =>
                                                                                                                                                                    handleChangeProgram(
                                                                                                                                                                        event,
                                                                                                                                                                        index,
                                                                                                                                                                        STindex,
                                                                                                                                                                        progindex,
                                                                                                                                                                        "PantoneIdOrSwatchId"
                                                                                                                                                                    )
                                                                                                                                                                }
                                                                                                                                                                style={{
                                                                                                                                                                    border:
                                                                                                                                                                        submitted &&
                                                                                                                                                                            ProgItem.PantoneIdOrSwatchId ===
                                                                                                                                                                            0
                                                                                                                                                                            ? "1px solid red"
                                                                                                                                                                            : "",
                                                                                                                                                                }}>
                                                                                                                                                                <option value="0">
                                                                                                                                                                    -Select
                                                                                                                                                                    Swatch-
                                                                                                                                                                </option>
                                                                                                                                                                {getSwatch.map(
                                                                                                                                                                    (
                                                                                                                                                                        item
                                                                                                                                                                    ) => (
                                                                                                                                                                        <option
                                                                                                                                                                            key={
                                                                                                                                                                                item.id
                                                                                                                                                                            }
                                                                                                                                                                            value={
                                                                                                                                                                                item.id
                                                                                                                                                                            }>
                                                                                                                                                                            {
                                                                                                                                                                                item.name
                                                                                                                                                                            }
                                                                                                                                                                        </option>
                                                                                                                                                                    )
                                                                                                                                                                )}
                                                                                                                                                            </select>
                                                                                                                                                            <span className="text"></span>
                                                                                                                                                        </span>
                                                                                                                                                    )}
                                                                                                                                                </td>
                                                                                                                                                <td>
                                                                                                                                                    {
                                                                                                                                                        ProgItem.PantoneTypeName !== "Others" ?
                                                                                                                                                            <div className="form-group">

                                                                                                                                                                {
                                                                                                                                                                    ProgItem.PantoneCodeorSwatchName !== '' ?
                                                                                                                                                                        <div style={{ background: ProgItem.ColororSwatchImage, width: '100%', height: '34px', borderRadius: '2px' }}></div>
                                                                                                                                                                        :
                                                                                                                                                                        ''
                                                                                                                                                                }
                                                                                                                                                            </div>
                                                                                                                                                            :
                                                                                                                                                            <img src={ProgItem.ColororSwatchImage === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + ProgItem.ColororSwatchImage}
                                                                                                                                                                alt="Picture" style={{ marginBottom: '5px', height: '40px', width: '50px', border: "1px solid #d5d5d5" }}
                                                                                                                                                                onClick={
                                                                                                                                                                    event => ViewSwatchImage(event, ProgItem.ColororSwatchImage)} />
                                                                                                                                                    }

                                                                                                                                                </td>
                                                                                                                                                <td>
                                                                                                                                                    <span className="input-icon icon-right">
                                                                                                                                                        <input placeholder="Enter Ref Color Name" id="RefColorName" name="RefColorName"
                                                                                                                                                            value={ProgItem.RefColorName} type="text"
                                                                                                                                                            onChange={event => handleChangeProgram(event, index, STindex, progindex, "RefColorName")}
                                                                                                                                                            maxLength="50" autoComplete="off" className='form-control'
                                                                                                                                                        />
                                                                                                                                                        <span className="text"></span>
                                                                                                                                                    </span>
                                                                                                                                                </td>
                                                                                                                                                <td>
                                                                                                                                                    <span className="input-icon icon-right">
                                                                                                                                                        <select id={"FabricID" + index} name="FabricID" className="form-select"
                                                                                                                                                            value={ProgItem.FabricTypeOrYarn}
                                                                                                                                                            onChange={event => handleChangeProgram(event, index, STindex, progindex, "FabricTypeOrYarn")}
                                                                                                                                                            style={{ border: submitted && ProgItem.FabricTypeOrYarn === 0 ? '1px solid red' : '' }}
                                                                                                                                                        >
                                                                                                                                                            <option value="0">-Select Fabric-</option>
                                                                                                                                                            {
                                                                                                                                                                getFabric.map(item => (
                                                                                                                                                                    <option key={item.id} value={item.value}>
                                                                                                                                                                        {item.label}
                                                                                                                                                                    </option>
                                                                                                                                                                ))
                                                                                                                                                            }
                                                                                                                                                        </select>
                                                                                                                                                    </span>
                                                                                                                                                </td>

                                                                                                                                                <td>
                                                                                                                                                    <span className="input-icon icon-right">
                                                                                                                                                        <span className="input-icon icon-right">
                                                                                                                                                            <select
                                                                                                                                                                id={
                                                                                                                                                                    "ProcessTypeId" +
                                                                                                                                                                    index
                                                                                                                                                                }
                                                                                                                                                                name="ProcessTypeId"
                                                                                                                                                                className="form-select"
                                                                                                                                                                value={
                                                                                                                                                                    getMaxidProcessType !==
                                                                                                                                                                        0
                                                                                                                                                                        ? getMaxidProcessType
                                                                                                                                                                        : ProgItem.ProcessTypeId
                                                                                                                                                                }
                                                                                                                                                                onChange={(
                                                                                                                                                                    event
                                                                                                                                                                ) =>
                                                                                                                                                                    handleChangeProgram(
                                                                                                                                                                        event,
                                                                                                                                                                        index,
                                                                                                                                                                        STindex,
                                                                                                                                                                        progindex,
                                                                                                                                                                        "ProcessTypeId"
                                                                                                                                                                    )
                                                                                                                                                                }
                                                                                                                                                                style={{
                                                                                                                                                                    border:
                                                                                                                                                                        submitted &&
                                                                                                                                                                            ProgItem.ProcessTypeId ===
                                                                                                                                                                            0
                                                                                                                                                                            ? "1px solid red"
                                                                                                                                                                            : "",
                                                                                                                                                                }}>
                                                                                                                                                                <option value="0">
                                                                                                                                                                    -Select
                                                                                                                                                                    Process
                                                                                                                                                                    Type-
                                                                                                                                                                </option>
                                                                                                                                                                {getProcessTypeList.map(
                                                                                                                                                                    (
                                                                                                                                                                        item
                                                                                                                                                                    ) => (
                                                                                                                                                                        <option
                                                                                                                                                                            key={
                                                                                                                                                                                item.id
                                                                                                                                                                            }
                                                                                                                                                                            value={
                                                                                                                                                                                item.id
                                                                                                                                                                            }>
                                                                                                                                                                            {
                                                                                                                                                                                item.name
                                                                                                                                                                            }
                                                                                                                                                                        </option>
                                                                                                                                                                    )
                                                                                                                                                                )}
                                                                                                                                                            </select>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </td>
                                                                                                                                                <td>
                                                                                                                                                    <span className="input-icon icon-right">

                                                                                                                                                        <select id={"ApprovalTypeID"} name="ApprovalTypeID" className="form-select"
                                                                                                                                                            value={ProgItem.ApprovalTypeId}
                                                                                                                                                            onChange={event => handleChangeProgram(event, index, STindex, progindex, "ApprovalTypeId")}
                                                                                                                                                            style={{ border: submitted && ProgItem.ApprovalTypeId === 0 ? '1px solid red' : '' }}
                                                                                                                                                        >
                                                                                                                                                            <option value="0">-Select Approval Type-</option>
                                                                                                                                                            {
                                                                                                                                                                getApprovalType.map(item => (
                                                                                                                                                                    <option key={item.ID} value={item.ID}>
                                                                                                                                                                        {item.Name}
                                                                                                                                                                    </option>
                                                                                                                                                                ))
                                                                                                                                                            }
                                                                                                                                                        </select>

                                                                                                                                                    </span>
                                                                                                                                                </td>
                                                                                                                                                <td >
                                                                                                                                                    {(ProgItem.FollowerDisplay != null && ProgItem.FollowerDisplay != undefined && ProgItem.FollowerDisplay != "") && <span>
                                                                                                                                                        <input type="text"
                                                                                                                                                            value={ProgItem.FollowerDisplay}
                                                                                                                                                            title={ProgItem.FollowerDisplay}
                                                                                                                                                            onClick={() => showCubeFollowePopup(index, STindex, progindex)}
                                                                                                                                                            className={'form-control'}
                                                                                                                                                            style={{ width: "100%", float: "left", border: submitted && !ProgItem.FollowerDisplay ? '1px solid red' : '' }}
                                                                                                                                                        />
                                                                                                                                                    </span>
                                                                                                                                                    }
                                                                                                                                                    {(ProgItem.FollowerDisplay == null || ProgItem.FollowerDisplay == undefined || ProgItem.FollowerDisplay == "") &&
                                                                                                                                                        <span
                                                                                                                                                            className={'form-control'}
                                                                                                                                                            style={{ width: "100%", paddingTop: "8px", float: "left", border: submitted && !ProgItem.FollowerDisplay ? '1px solid red' : '' }}
                                                                                                                                                            onClick={() => showCubeFollowePopup(index, STindex, progindex)}>
                                                                                                                                                            <span style={{ color: "rgb(0, 166, 90)" }}>Cube</span>&nbsp;-&nbsp;
                                                                                                                                                            <span style={{ color: "rgb(11, 9, 146)" }}>Buyer</span>
                                                                                                                                                        </span>
                                                                                                                                                    }
                                                                                                                                                    {ProgItem.IsFolower === 1 &&
                                                                                                                                                        <Modal show={ProgItem.IsFolower === 1} size="md" backdrop="static" keyboard={false} onHide={() => hideCubeFollowePopup(index, STindex, progindex)}>
                                                                                                                                                            <Modal.Header closeButton>
                                                                                                                                                                <Modal.Title>
                                                                                                                                                                    Follower
                                                                                                                                                                </Modal.Title>
                                                                                                                                                            </Modal.Header>
                                                                                                                                                            <Modal.Body>

                                                                                                                                                                <div className="row">
                                                                                                                                                                    <div className="col-md-8">
                                                                                                                                                                        <div className='form-group'>
                                                                                                                                                                            <label htmlFor={"Cube"} >Cube Employee<span className="text-danger">*</span></label>
                                                                                                                                                                            <span className='input-icon icon-right'>
                                                                                                                                                                                <Reactselect className="basic-single" name={"Cube"}
                                                                                                                                                                                    isDisabled={false}
                                                                                                                                                                                    isLoading={false}
                                                                                                                                                                                    isClearable={true}
                                                                                                                                                                                    isSearchable={true}
                                                                                                                                                                                    isMulti
                                                                                                                                                                                    onChange={event => handleChangeFollower(event, index, STindex, progindex, "Cube")}
                                                                                                                                                                                    options={getFollowerCube}
                                                                                                                                                                                    // value={getFollowerCube.filter(function (option) {
                                                                                                                                                                                    //     return option.value === ProgItem.FollowerCubeIds;
                                                                                                                                                                                    // })}
                                                                                                                                                                                    value={ProgItem.FollowerCubeIds}
                                                                                                                                                                                    styles={followerpopupvalidation && ProgItem.FollowerCubeIds === '' ? styles2 : styles1}
                                                                                                                                                                                >  </Reactselect>

                                                                                                                                                                            </span>
                                                                                                                                                                        </div>
                                                                                                                                                                    </div>
                                                                                                                                                                </div>


                                                                                                                                                                <div className="row">
                                                                                                                                                                    <div className="col-md-8">
                                                                                                                                                                        <div className='form-group'>
                                                                                                                                                                            <label htmlFor={"Cube"} >Buyer Employee<span className="text-danger">*</span></label>
                                                                                                                                                                            <span className='input-icon icon-right'>
                                                                                                                                                                                <Reactselect className="basic-single" name={"Cube"}
                                                                                                                                                                                    isDisabled={false}
                                                                                                                                                                                    isLoading={false}
                                                                                                                                                                                    isClearable={true}
                                                                                                                                                                                    isSearchable={true}
                                                                                                                                                                                    isMulti
                                                                                                                                                                                    onChange={event => handleChangeFollower(event, index, STindex, progindex, "Buyer")}
                                                                                                                                                                                    options={getFollowerBuyer}
                                                                                                                                                                                    // value={getFollowerBuyer.filter(function (option) {
                                                                                                                                                                                    //     return option.value === ProgItem.FollowerBuyerIds;
                                                                                                                                                                                    // })}
                                                                                                                                                                                    value={ProgItem.FollowerBuyerIds}
                                                                                                                                                                                    styles={followerpopupvalidation && ProgItem.FollowerBuyerIds === '' ? styles2 : styles1}
                                                                                                                                                                                >  </Reactselect>

                                                                                                                                                                            </span>
                                                                                                                                                                        </div>
                                                                                                                                                                    </div>
                                                                                                                                                                </div>
                                                                                                                                                            </Modal.Body>
                                                                                                                                                            <Modal.Footer>
                                                                                                                                                                <Button variant="success" onClick={() => handleSaveFollower(index, STindex, progindex)}>
                                                                                                                                                                    Save
                                                                                                                                                                </Button>

                                                                                                                                                            </Modal.Footer>
                                                                                                                                                        </Modal>
                                                                                                                                                    }
                                                                                                                                                </td>
                                                                                                                                                <td>


                                                                                                                                                    {
                                                                                                                                                        ProgItem.ApprovalTypeId === 2 &&
                                                                                                                                                        (ProgItem.IsManualInfo[0].ManualCloseComment === '' ?
                                                                                                                                                            <button type="button" style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }} className="btn btn-xs btn-darkorange" title="Manual"
                                                                                                                                                                onClick={() => showManualClose(index, STindex, progindex, item.GenLabdipId)}
                                                                                                                                                            >
                                                                                                                                                                <i className="fa fa-user"></i>&nbsp;
                                                                                                                                                                <i className="fa fa-close"></i>
                                                                                                                                                            </button>
                                                                                                                                                            :
                                                                                                                                                            <button type="button" style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }} className="btn btn-xs btn-success" title="View Manual Data"
                                                                                                                                                                onClick={() => showManualClose(index, STindex, progindex, item.GenLabdipId)}
                                                                                                                                                            >
                                                                                                                                                                <i className="fa fa-user"></i>&nbsp;
                                                                                                                                                                <i className="fa fa-close"></i>
                                                                                                                                                            </button>
                                                                                                                                                        )
                                                                                                                                                    }

                                                                                                                                                    {
                                                                                                                                                        ProgItem.ApprovalTypeId === 3 &&
                                                                                                                                                        <button type="button" className={ProgItem.labdibSubmissionApprovalID === 0 || ProgItem.labdibSubmissionApprovalID === null ? "btn btn-xs btn-darkorange" : "btn btn-xs btn-success"} title="Existing Approval"
                                                                                                                                                            onClick={() => getGENLabDipMapList(index, STindex, progindex, ProgItem.PantoneIdOrSwatchId, ProgItem.PantoneType,)}
                                                                                                                                                        >
                                                                                                                                                            <i className="fa fa-retweet"></i>

                                                                                                                                                        </button>


                                                                                                                                                    }
                                                                                                                                                    &nbsp;
                                                                                                                                                    {
                                                                                                                                                        getHeader !== 'Edit' ?
                                                                                                                                                            <>
                                                                                                                                                                {
                                                                                                                                                                    STitem.Program.length !== 1 && (

                                                                                                                                                                        <button type="button" className="btn  btn-xs  btn-danger" title="Delete Lab Dip"
                                                                                                                                                                            style={{ display: activeMenu[0].isDelete === 1 ? '' : 'none' }}
                                                                                                                                                                            onClick={event => handleRemoveProgram(index, STindex, progindex)}>
                                                                                                                                                                            <i className="fa fa-trash-o"></i>
                                                                                                                                                                        </button>

                                                                                                                                                                    )
                                                                                                                                                                }
                                                                                                                                                            </>
                                                                                                                                                            : ''
                                                                                                                                                    }

                                                                                                                                                    &nbsp;
                                                                                                                                                    {
                                                                                                                                                        getHeader !== 'Edit' ?
                                                                                                                                                            <>
                                                                                                                                                                {
                                                                                                                                                                    <button type="button" className="btn btn-xs btn-success" title="Add Lab Dip"
                                                                                                                                                                        onClick={
                                                                                                                                                                            () => handleAddProgram('Add', index, STindex, progindex)
                                                                                                                                                                        }>
                                                                                                                                                                        <i className="fa fa-plus"></i>
                                                                                                                                                                    </button>
                                                                                                                                                                }
                                                                                                                                                            </>
                                                                                                                                                            : ''
                                                                                                                                                    }

                                                                                                                                                    &nbsp;
                                                                                                                                                    {
                                                                                                                                                        getHeader !== 'Edit' ?
                                                                                                                                                            <>
                                                                                                                                                                {
                                                                                                                                                                    <span className="btn btn-primary" title="Copy Lab Dip" onClick={() => handleAddProgram('Copy', index, STindex, progindex)}>
                                                                                                                                                                        <i className="fa fa-copy"></i>
                                                                                                                                                                    </span>
                                                                                                                                                                }
                                                                                                                                                            </>
                                                                                                                                                            : ''
                                                                                                                                                    }

                                                                                                                                                    &nbsp;
                                                                                                                                                    <span className="btn btn-custom" title="Critical" onClick={() => ShowCritical(index, STindex, progindex)}
                                                                                                                                                        style={{
                                                                                                                                                            backgroundColor: ProgItem.CriticalInfo[0] && ProgItem.CriticalInfo[0].CriticalComment && ProgItem.CriticalInfo[0].TargettoClose && ProgItem.CriticalInfo[0].RemindMe !== 0 ? 'red' : '#427fed',
                                                                                                                                                            color: 'white',

                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <i class="fa fa-exclamation-triangle"></i>
                                                                                                                                                    </span>


                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                            {

                                                                                                                                                <div> {
                                                                                                                                                    isOpenimg && (

                                                                                                                                                        <Lightbox mainSrc={window.$APIBaseURL + ProgItem.ColororSwatchImage}
                                                                                                                                                            alt="bg image"
                                                                                                                                                            onCloseRequest={
                                                                                                                                                                () => setIsOpenimg(false)
                                                                                                                                                            } />
                                                                                                                                                    )
                                                                                                                                                } </div>
                                                                                                                                            }
                                                                                                                                            {
                                                                                                                                                ProgItem.IsCritical === 1 && (
                                                                                                                                                    <>
                                                                                                                                                        <Modal show={ProgItem.IsCritical === 1} size="xl" dragable backdrop="static" keyboard={false} onHide={() => HideCritical(index, STindex, progindex)}>
                                                                                                                                                            <Modal.Header closeButton>
                                                                                                                                                                <Modal.Title>
                                                                                                                                                                    {"Critical Comments"}
                                                                                                                                                                </Modal.Title>
                                                                                                                                                            </Modal.Header>
                                                                                                                                                            <Modal.Body>
                                                                                                                                                                <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                                                                                                    {
                                                                                                                                                                        ProgItem.CriticalInfo &&
                                                                                                                                                                        ProgItem.CriticalInfo.map((Crititem, critindex) => {
                                                                                                                                                                            return (
                                                                                                                                                                                <>
                                                                                                                                                                                    <thead>
                                                                                                                                                                                        <tr>
                                                                                                                                                                                            <th colSpan="6" style={{ textAlign: "center" }}>Comments<span className="text-danger">*</span>
                                                                                                                                                                                                <button type="button" className="btn btn-xs btn-danger pull-right"
                                                                                                                                                                                                    style={{ display: Crititem.CheckSave ? 'block' : 'none' }}
                                                                                                                                                                                                    onClick={event => ClearCritical(index, STindex, progindex)}
                                                                                                                                                                                                    title="Clear Critical" ><i className="fa fa-trash-o"></i>
                                                                                                                                                                                                </button>
                                                                                                                                                                                            </th>
                                                                                                                                                                                        </tr>
                                                                                                                                                                                    </thead>
                                                                                                                                                                                    <tbody>
                                                                                                                                                                                        <tr>
                                                                                                                                                                                            <td colSpan="6">
                                                                                                                                                                                                <textarea placeholder="Enter comments here..."
                                                                                                                                                                                                    autoFocus onFocus={onFocus}
                                                                                                                                                                                                    maxLength="500" name="CriticalComment"
                                                                                                                                                                                                    id={"CriticalComment"}
                                                                                                                                                                                                    value={Crititem.CriticalComment}
                                                                                                                                                                                                    onChange={event => handleChangeCritical(event, index, STindex, progindex, critindex, 'CriticalComment')}
                                                                                                                                                                                                    rows="4" style={{ width: "100%", border: critPopupValidation && Crititem.CriticalComment === '' ? '1px solid red' : '' }}>
                                                                                                                                                                                                </textarea>
                                                                                                                                                                                            </td>
                                                                                                                                                                                        </tr>
                                                                                                                                                                                        <tr>
                                                                                                                                                                                            <td>Target to Close<span className="text-danger">*</span></td>
                                                                                                                                                                                            <td>


                                                                                                                                                                                                <DatePicker className="form-control" name="TargettoClose" id={"TargettoClose"}
                                                                                                                                                                                                    selected={Crititem.TargettoClose}
                                                                                                                                                                                                    onChange={event => handleChangeCritical(event, index, STindex, progindex, critindex, 'TargettoClose')}
                                                                                                                                                                                                    dateFormat="dd/MM/yyyy"
                                                                                                                                                                                                    peekNextMonth
                                                                                                                                                                                                    showMonthDropdown
                                                                                                                                                                                                    showYearDropdown
                                                                                                                                                                                                    dropdownMode="scroll"
                                                                                                                                                                                                    autoComplete="off"
                                                                                                                                                                                                    customInput={<TargettocloseInput />}
                                                                                                                                                                                                    isClearable={Crititem.TargettoClose === '' ? false : true}

                                                                                                                                                                                                />

                                                                                                                                                                                            </td>



                                                                                                                                                                                            <td>Remind Me<span className="text-danger">*</span></td>
                                                                                                                                                                                            <td>
                                                                                                                                                                                                <select className="form-select" name="RemindMe" id={"RemindMe"}
                                                                                                                                                                                                    value={Crititem.RemindMe}
                                                                                                                                                                                                    onChange={event => handleChangeCritical(event, index, STindex, progindex, critindex, 'RemindMe')}
                                                                                                                                                                                                    style={{ border: critPopupValidation && (Crititem.RemindMe === "0" || Crititem.RemindMe === 0) ? '1px solid red' : '' }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <option value="0">- select -</option>
                                                                                                                                                                                                    <option value="1">Current Date Reaches Target Date </option>
                                                                                                                                                                                                    <option value="2">One Time in a Day, until complete</option>
                                                                                                                                                                                                </select>
                                                                                                                                                                                            </td>
                                                                                                                                                                                        </tr>
                                                                                                                                                                                        <tr>
                                                                                                                                                                                            <td>Completed On</td>
                                                                                                                                                                                            <td>
                                                                                                                                                                                                <DatePicker className="form-control" name="CompletedOn"
                                                                                                                                                                                                    id={"CompletedOn"} selected={Crititem.CompletedOn}
                                                                                                                                                                                                    onChange={event => handleChangeCritical(event, index, STindex, progindex, critindex, 'CompletedOn')}
                                                                                                                                                                                                    dateFormat="dd/MM/yyyy"
                                                                                                                                                                                                    peekNextMonth
                                                                                                                                                                                                    showMonthDropdown
                                                                                                                                                                                                    showYearDropdown
                                                                                                                                                                                                    dropdownMode="scroll"
                                                                                                                                                                                                    autoComplete="off"
                                                                                                                                                                                                    customInput={<CustomInputCompletedOn />}
                                                                                                                                                                                                    isClearable={Crititem.CompletedOn === '' ? false : true}
                                                                                                                                                                                                />
                                                                                                                                                                                            </td>
                                                                                                                                                                                        </tr>
                                                                                                                                                                                    </tbody>
                                                                                                                                                                                </>
                                                                                                                                                                            )
                                                                                                                                                                        })
                                                                                                                                                                    }
                                                                                                                                                                </table>
                                                                                                                                                            </Modal.Body>
                                                                                                                                                            <Modal.Footer>
                                                                                                                                                                <Button variant="success" onClick={() => handleSaveCriticalInfo(index, STindex, progindex)}>
                                                                                                                                                                    {getHeader === 'Add' ? 'Save' : 'Update'}
                                                                                                                                                                </Button>
                                                                                                                                                            </Modal.Footer>
                                                                                                                                                        </Modal>
                                                                                                                                                    </>
                                                                                                                                                )
                                                                                                                                            }
                                                                                                                                            {
                                                                                                                                                ProgItem.CriticalInfo &&
                                                                                                                                                ProgItem.CriticalInfo.map((Crit, j) => {
                                                                                                                                                    return (
                                                                                                                                                        Crit.CriticalComment !== '' && Crit.TargettoClose !== '' && Crit.RemindMe !== 0 ?

                                                                                                                                                            <tr>
                                                                                                                                                                <td colSpan="10">
                                                                                                                                                                    <span style={{ color: 'red' }}>Critical : {Crit.CriticalComment}</span>,&nbsp;
                                                                                                                                                                    <span style={{ color: '#427fed' }}>Target to Close : {Crit.TargettoClose && moment(Crit.TargettoClose).format('DD/MM/YYYY')}</span>,&nbsp;
                                                                                                                                                                    <span style={{ color: '#00a65a' }}>Completed On : {Crit.CompletedOn && Crit.CompletedOn !== '' ? moment(Crit.CompletedOn).format('DD/MM/YYYY') : '-'}</span>
                                                                                                                                                                    {/* new Date(Crit.CompletedOn).toLocaleDateString()  */}
                                                                                                                                                                </td>
                                                                                                                                                            </tr>

                                                                                                                                                            : ''
                                                                                                                                                    );
                                                                                                                                                })
                                                                                                                                            }
                                                                                                                                            {

                                                                                                                                                ProgItem.IsManual === 1 && (
                                                                                                                                                    <>
                                                                                                                                                        <Modal show={ProgItem.IsManual === 1} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseManualPopup(index, STindex, progindex)}>
                                                                                                                                                            <Modal.Header closeButton>
                                                                                                                                                                <Modal.Title>
                                                                                                                                                                    Manual Approval Information
                                                                                                                                                                </Modal.Title>
                                                                                                                                                            </Modal.Header>
                                                                                                                                                            <Modal.Body>
                                                                                                                                                                <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                                                                                                    {

                                                                                                                                                                        ProgItem.IsManualInfo.map((manitem, k) => {
                                                                                                                                                                            return (
                                                                                                                                                                                <>

                                                                                                                                                                                    <thead>
                                                                                                                                                                                        <tr>
                                                                                                                                                                                            <th>Comments <span className="text-danger">*</span>
                                                                                                                                                                                                <button type="button" className="btn btn-xs btn-danger pull-right"
                                                                                                                                                                                                    style={{ display: activeMenu[0].isDelete === 1 ? '' : 'none' }}
                                                                                                                                                                                                    onClick={event => ClearManual(index, STindex, progindex)}
                                                                                                                                                                                                    title="Clear Critical" ><i className="fa fa-trash-o"></i>
                                                                                                                                                                                                </button>
                                                                                                                                                                                            </th>


                                                                                                                                                                                        </tr>
                                                                                                                                                                                    </thead>
                                                                                                                                                                                    <tbody>

                                                                                                                                                                                        <tr>
                                                                                                                                                                                            <td >
                                                                                                                                                                                                <textarea

                                                                                                                                                                                                    rows="3"
                                                                                                                                                                                                    placeholder="Enter Comments here..."
                                                                                                                                                                                                    autoFocus onFocus={""} autoComplete="off"
                                                                                                                                                                                                    maxLength="500" name="EnterRemarks"
                                                                                                                                                                                                    id={"EnterRemarks"}
                                                                                                                                                                                                    value={manitem.ManualCloseComment}
                                                                                                                                                                                                    onChange={event => handleChangeIsManualCloseComments(event, index, STindex, progindex, 'Comment', k)}
                                                                                                                                                                                                    style={{ width: "100%", border: submittedPop && manitem.ManualCloseComment === '' ? '1px solid red' : '' }}



                                                                                                                                                                                                >

                                                                                                                                                                                                </textarea>
                                                                                                                                                                                            </td>

                                                                                                                                                                                        </tr>

                                                                                                                                                                                        <tr>
                                                                                                                                                                                            <td colSpan={2}>
                                                                                                                                                                                                <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                                                                                                                                    <tbody>
                                                                                                                                                                                                        <tr>
                                                                                                                                                                                                            <div className="col-sm-12 col-lg-12">
                                                                                                                                                                                                                <div style={{ paddingLeft: 0 }}>
                                                                                                                                                                                                                    <span className="widget-caption" style={{ fontSize: '14px' }}>File Upload <span style={{ color: 'red', fontSize: '14px' }}>(Upload Document Type:Image Only)</span></span>
                                                                                                                                                                                                                </div>
                                                                                                                                                                                                            </div>
                                                                                                                                                                                                            <div className="col-sm-12 col-lg-12">
                                                                                                                                                                                                                <div className="row">
                                                                                                                                                                                                                    <div className="col-sm-8 col-lg-8">
                                                                                                                                                                                                                        <div className='form-group'>
                                                                                                                                                                                                                            <tr>
                                                                                                                                                                                                                                <td> <input type="file" id="FileUpload" style={{ fontSize: '14px' }} onChange={(e) => AppendFiles(e, index, STindex, progindex)} accept=".jpg,.jpeg,.png" ></input></td>
                                                                                                                                                                                                                            </tr>
                                                                                                                                                                                                                        </div>
                                                                                                                                                                                                                    </div>
                                                                                                                                                                                                                </div>
                                                                                                                                                                                                            </div>
                                                                                                                                                                                                            <div className="col-lg-12 col-sm-12">
                                                                                                                                                                                                                <div className="row no-margin">
                                                                                                                                                                                                                    {
                                                                                                                                                                                                                        manitem.Files && manitem.Files.length !== 0 ?
                                                                                                                                                                                                                            manitem.Files.filter(x => x.IsDeleted !== 1).map((files, fileindex) => (
                                                                                                                                                                                                                                <> {
                                                                                                                                                                                                                                    <div className="col-lg-4 col-sm-4 file_list_upload">
                                                                                                                                                                                                                                        <tr title={files.fileName}>
                                                                                                                                                                                                                                            <td className="cut_textInTNAS">
                                                                                                                                                                                                                                                <span style={{ marginRight: '7px' }}>
                                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                                        files.fileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                                                                                                                                                                            files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                                                                                                                                                                                                <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                                                                                                                                                                                files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                                                                                                                                                                                    files.fileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                                                                                                                                                                                    <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                </span>  {files.fileName}
                                                                                                                                                                                                                                            </td>

                                                                                                                                                                                                                                            <td className="button_style_uploda">
                                                                 
                                                                                                                                                                                                                                                
                                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                                    files.FileType === "application/pdf" || files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files.fileType === 'application/msword' ?
                                                                                                                                                                                                                                                        <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + 'Images/TNAImages/' + files.fileName} download><i class="fa fa-download"></i> </a> :
                                                                                                                                                                                                                                                        <i className="btn btn-info fa fa-eye" title="View Image"
                                                                                                                                                                                                                                                            style={{ display: activeMenu && activeMenu[0].isView === 1 ? '' : 'none' }}
                                                                                                                                                                                                                                                            onClick={
                                                                                                                                                                                                                                                                event => ViewFile(event, files.fileName)
                                                                                                                                                                                                                                                            }></i>
                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                &nbsp;
                                                                                                                                                                                                                                                {Buttonview !== "View" &&
                                                                                                                                                                                                                                                    <p style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }} title='Delete File' onClick={() => RemoveFiles(index, STindex, progindex, fileindex, files.GenLapdipManulCloseCommentFileId, files.programIndexName, files.fileIndex)}
                                                                                                                                                                                                                                                        className="btn btn-danger btn-xs delete">
                                                                                                                                                                                                                                                        <i className="fa fa-trash-o"></i>

                                                                                                                                                                                                                                                    </p>
                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                            </td>
                                                                                                                                                                                                                                        </tr>



                                                                                                                                                                                                                                        {

                                                                                                                                                                                                                                            <div> {
                                                                                                                                                                                                                                                isOpenimg && (

                                                                                                                                                                                                                                                    <Lightbox mainSrc={window.$APIBaseURL + 'Images/TNAImages/' + getImagename}
                                                                                                                                                                                                                                                        alt="bg image"
                                                                                                                                                                                                                                                        onCloseRequest={
                                                                                                                                                                                                                                                            () => setIsOpenimg(false)
                                                                                                                                                                                                                                                        } />
                                                                                                                                                                                                                                                )
                                                                                                                                                                                                                                            } </div>
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                    </div>
                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                </>
                                                                                                                                                                                                                            ))
                                                                                                                                                                                                                            : ''
                                                                                                                                                                                                                    }
                                                                                                                                                                                                                </div>
                                                                                                                                                                                                            </div>
                                                                                                                                                                                                        </tr>
                                                                                                                                                                                                    </tbody>


                                                                                                                                                                                                </table>
                                                                                                                                                                                            </td>
                                                                                                                                                                                        </tr>
                                                                                                                                                                                    </tbody>

                                                                                                                                                                                </>
                                                                                                                                                                            )

                                                                                                                                                                        })



                                                                                                                                                                    }
                                                                                                                                                                </table>




                                                                                                                                                            </Modal.Body>
                                                                                                                                                            <Modal.Footer>
                                                                                                                                                                {

                                                                                                                                                                    <Button variant="success" onClick={() => handleSaveManual(index, STindex, progindex)} >
                                                                                                                                                                        Save
                                                                                                                                                                    </Button>


                                                                                                                                                                }


                                                                                                                                                            </Modal.Footer>
                                                                                                                                                        </Modal>
                                                                                                                                                    </>
                                                                                                                                                )
                                                                                                                                            }
                                                                                                                                            

                                                                                                                                            <div> {
                                                                                                                                                isOpen && (

                                                                                                                                                    <Lightbox mainSrc={isOpen}
                                                                                                                                                        alt="bg image"
                                                                                                                                                        onCloseRequest={
                                                                                                                                                            () => setIsOpen(false)
                                                                                                                                                        } />
                                                                                                                                                )

                                                                                                                                            }
                                                                                                                                            </div>
                                                                                                                                            {
                                                                                                                                                ProgItem.IsExiting === 1 && (
                                                                                                                                                    <>
                                                                                                                                                        <Modal show={ProgItem.IsExiting === 1} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseLapDipMapPopup(index, STindex, progindex)}>
                                                                                                                                                            <Modal.Header closeButton>
                                                                                                                                                                <Modal.Title>
                                                                                                                                                                    Existing Approval Information
                                                                                                                                                                </Modal.Title>
                                                                                                                                                            </Modal.Header>
                                                                                                                                                            <Modal.Body>

                                                                                                                                                                <table className="table table-bordered">
                                                                                                                                                                    <thead>
                                                                                                                                                                        <tr>
                                                                                                                                                                            <th>Season Name</th>
                                                                                                                                                                            <th>Approval Date</th>
                                                                                                                                                                            <th>Details</th>
                                                                                                                                                                            <th>Approved By</th>
                                                                                                                                                                            <th>
                                                                                                                                                                            </th>

                                                                                                                                                                        </tr>
                                                                                                                                                                    </thead>
                                                                                                                                                                    <tbody>
                                                                                                                                                                        {

                                                                                                                                                                            GENLabDipMapList.map((Exiting, ExitingIndex) => {
                                                                                                                                                                                return (
                                                                                                                                                                                    <>
                                                                                                                                                                                        <tr>
                                                                                                                                                                                            <td>{Exiting.seasonName}</td>
                                                                                                                                                                                            <td>{Exiting.approvalDate}</td>
                                                                                                                                                                                            <td>{Exiting.details}</td>
                                                                                                                                                                                            <td>{Exiting.approvedBy}</td>
                                                                                                                                                                                            <td className="Actionbutton fixed-column">
                                                                                                                                                                                                <div style={{ width: "80px" }}>
                                                                                                                                                                                                    <label style={{ marginTop: '8px' }}>
                                                                                                                                                                                                        <input type="checkbox" name="IsSelected"
                                                                                                                                                                                                            onChange={event => (OnSelectRecords(event, index, STindex, progindex, Exiting.commonApprovalId))}
                                                                                                                                                                                                            checked={(ProgItem.labdibSubmissionApprovalID === Exiting.commonApprovalId && Exiting.commonApprovalId !== 0) ? true : false}
                                                                                                                                                                                                            className="colored-blue clearOpacity" />
                                                                                                                                                                                                        <span className="text"></span>
                                                                                                                                                                                                    </label>

                                                                                                                                                                                                </div>

                                                                                                                                                                                            </td>
                                                                                                                                                                                        </tr>
                                                                                                                                                                                    </>

                                                                                                                                                                                )
                                                                                                                                                                            })
                                                                                                                                                                        }

                                                                                                                                                                    </tbody>
                                                                                                                                                                </table>
                                                                                                                                                            </Modal.Body>
                                                                                                                                                            <Modal.Footer>
                                                                                                                                                                <Button variant="success" onClick={() => saveLapDipMap(index, STindex, progindex)}>
                                                                                                                                                                    Save
                                                                                                                                                                </Button>

                                                                                                                                                            </Modal.Footer>
                                                                                                                                                        </Modal>
                                                                                                                                                        : ''
                                                                                                                                                    </>
                                                                                                                                                )


                                                                                                                                            }


                                                                                                                                            {
                                                                                                                                                ProgItem.IsManualInfo.map((man, j) => {
                                                                                                                                                    return (
                                                                                                                                                        man.ManualCloseComment !== '' && ProgItem.ApprovalTypeId === 2 ?
                                                                                                                                                            <tr>
                                                                                                                                                                <td colSpan="12">
                                                                                                                                                                    <span><b>Manual Comment :</b> {man.ManualCloseComment}</span>

                                                                                                                                                                    <tr>
                                                                                                                                                                        <td>
                                                                                                                                                                            {

                                                                                                                                                                                man.Files && man.Files.length !== 0 ?
                                                                                                                                                                                    getHeader === "Add" ?
                                                                                                                                                                                        man.Files.map((files, fileindex) => (
                                                                                                                                                                                            <div className="col-md-2 file_list_uploads fixed_width">
                                                                                                                                                                                                <tr title={files.fileName}>
                                                                                                                                                                                                    File:
                                                                                                                                                                                                    <td className="cut_textInTNAS">
                                                                                                                                                                                                        <span style={{ marginRight: '7px' }}>
                                                                                                                                                                                                            {
                                                                                                                                                                                                                files.fileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                                                                                                                                    files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                                                                                                                                                        <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                                                                                                                                        files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                                                                                                                                            files.fileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                                                                                                                                            <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                                                                                                                                            }
                                                                                                                                                                                                        </span>  {files.fileName}
                                                                                                                                                                                                    </td>

                                                                                                                                                                                                    <td>

                                                                                                                                                                                                        {
                                                                                                                                                                                                            files.fileType === "application/pdf" || files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files.fileType === 'application/msword' ?
                                                                                                                                                                                                                <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + 'Images/TNAImages/' + files.fileName} download><i class="fa fa-download"></i> </a> :
                                                                                                                                                                                                                <i className="btn btn-info fa fa-eye" title="View Logo"
                                                                                                                                                                                                                    style={{ display: activeMenu && activeMenu[0].isView === 1 ? '' : 'none' }}
                                                                                                                                                                                                                    onClick={
                                                                                                                                                                                                                        event => ViewFile(event, files.fileName)
                                                                                                                                                                                                                    }></i>
                                                                                                                                                                                                        }
                                                                                                                                                                                                    </td>
                                                                                                                                                                                                </tr>
                                                                                                                                                                                                {

                                                                                                                                                                                                    <div> {
                                                                                                                                                                                                        isOpenimg && (

                                                                                                                                                                                                            <Lightbox mainSrc={window.$APIBaseURL + 'Images/TNAImages/' + getImagename}
                                                                                                                                                                                                                alt="bg image"
                                                                                                                                                                                                                onCloseRequest={
                                                                                                                                                                                                                    () => setIsOpenimg(false)
                                                                                                                                                                                                                } />
                                                                                                                                                                                                        )
                                                                                                                                                                                                    } </div>
                                                                                                                                                                                                }
                                                                                                                                                                                            </div>
                                                                                                                                                                                        ))
                                                                                                                                                                                        :

                                                                                                                                                                                        man.Files.filter(x => x.IsDeleted !== 1).map((files, fileindex) => (
                                                                                                                                                                                            files.programIndexName === man.ProgramIndexName ?
                                                                                                                                                                                                <div className="col-md-2 file_list_uploads fixed_width">
                                                                                                                                                                                                    <tr title={files.fileName}  >
                                                                                                                                                                                                        File:
                                                                                                                                                                                                        <td className="cut_textInTNA" >
                                                                                                                                                                                                            <span style={{ marginRight: '7px' }}>
                                                                                                                                                                                                                {
                                                                                                                                                                                                                    files.fileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                                                                                                                                        files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                                                                                                                                                            <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                                                                                                                                            files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                                                                                                                                                files.fileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                                                                                                                                                <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                                                                                                                                                }
                                                                                                                                                                                                            </span>  {files.fileName}
                                                                                                                                                                                                        </td>

                                                                                                                                                                                                        <td >

                                                                                                                                                                                                            {
                                                                                                                                                                                                                files.fileType === "application/pdf" || files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files.fileType === 'application/msword' ?
                                                                                                                                                                                                                    <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + 'Images/TNAImages/' + files.fileName} download><i class="fa fa-download"></i> </a> :
                                                                                                                                                                                                                    <i className="btn btn-info fa fa-eye" title="View Logo"
                                                                                                                                                                                                                        style={{ display: activeMenu && activeMenu[0].isView === 1 ? '' : 'none' }}
                                                                                                                                                                                                                        onClick={
                                                                                                                                                                                                                            event => ViewFile(event, files.fileName)
                                                                                                                                                                                                                        }></i>
                                                                                                                                                                                                            }
                                                                                                                                                                                                        </td>
                                                                                                                                                                                                    </tr>
                                                                                                                                                                                                    {

                                                                                                                                                                                                        <div> {
                                                                                                                                                                                                            isOpenimg && (

                                                                                                                                                                                                                <Lightbox mainSrc={window.$APIBaseURL + 'Images/TNAImages/' + getImagename}
                                                                                                                                                                                                                    alt="bg image"
                                                                                                                                                                                                                    onCloseRequest={
                                                                                                                                                                                                                        () => setIsOpenimg(false)
                                                                                                                                                                                                                    } />
                                                                                                                                                                                                            )
                                                                                                                                                                                                        } </div>
                                                                                                                                                                                                    }
                                                                                                                                                                                                </div>
                                                                                                                                                                                                : ''
                                                                                                                                                                                        ))
                                                                                                                                                                                    : ''


                                                                                                                                                                            }
                                                                                                                                                                        </td>
                                                                                                                                                                    </tr>
                                                                                                                                                                    <span>

                                                                                                                                                                    </span>

                                                                                                                                                                </td>
                                                                                                                                                            </tr>
                                                                                                                                                            : ''



                                                                                                                                                    );


                                                                                                                                                })
                                                                                                                                            }
                                                                                                                                            {ProgItem.ApprovalTypeId === 3 &&
                                                                                                                                                GENLabDipMapList && GENLabDipMapList.length > 0 ? GENLabDipMapList.filter(x => x.commonApprovalId === ProgItem.labdibSubmissionApprovalID).map((exist, j) => {
                                                                                                                                                    return (
                                                                                                                                                        <tr>
                                                                                                                                                            <td colSpan="10">
                                                                                                                                                                <span><b>Season Name : </b> {exist.seasonName} &nbsp;</span>
                                                                                                                                                                <span><b>Approval Date : </b>  {new Date(exist.approvalDate).toLocaleDateString()} &nbsp;</span>
                                                                                                                                                                <span><b>Details : </b> {exist.details} &nbsp;</span>
                                                                                                                                                                <span><b>Approved By : </b> {exist.approvedBy}</span>

                                                                                                                                                            </td>
                                                                                                                                                        </tr>

                                                                                                                                                    )
                                                                                                                                                }) :
                                                                                                                                                ProgItem.ApprovalTypeId === 3 && ProgItem.labdibSubmissionApproval && ProgItem.labdibSubmissionApproval.filter(x => x.CommonApprovalId === ProgItem.labdibSubmissionApprovalID).map((exist, j) => {
                                                                                                                                                    return (
                                                                                                                                                        <tr>
                                                                                                                                                            <td colSpan="10">
                                                                                                                                                                <span><b>Season Name : </b> {exist.SeasonName} &nbsp;</span>
                                                                                                                                                                <span><b>Approval Date : </b>  {new Date(exist.ApprovalDate).toLocaleDateString()} &nbsp;</span>
                                                                                                                                                                <span><b>Details : </b> {exist.Details} &nbsp;</span>
                                                                                                                                                                <span><b>Approved By : </b> {exist.ApprovedBy}</span>

                                                                                                                                                            </td>
                                                                                                                                                        </tr>

                                                                                                                                                    );

                                                                                                                                                })
                                                                                                                                            }

                                                                                                                                        </>

                                                                                                                                    )
                                                                                                                                })
                                                                                                                            }
                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                }
                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                {

                                                                                                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                                                                                        <thead className="text-center">
                                                                                                                            <tr>
                                                                                                                                <th className="text-center">Pantone Type</th>
                                                                                                                                <th className="text-center">Pantone Code / Swatch Name</th>
                                                                                                                                <th className="text-center">Color / Swatch Image</th>
                                                                                                                                <th className="text-center">Ref. Color Name</th>
                                                                                                                                <th className="text-center ">Item (Fabric / Yarn)</th>
                                                                                                                                <th className="text-center">Process Type</th>
                                                                                                                                <th className="text-center">Approval Type</th>
                                                                                                                                <th className="text-center">Follower</th>
                                                                                                                                <th className="text-center Actionbutton fixed-column-header">Action</th>
                                                                                                                            </tr>
                                                                                                                        </thead>
                                                                                                                        <tbody>
                                                                                                                            <tr><td colSpan="6" className='norecordfound'><span>No Records Found</span></td></tr>
                                                                                                                        </tbody>
                                                                                                                    </table>


                                                                                                                }

                                                                                                            </>
                                                                                                    }

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>




                                                                                    </>

                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </Form>
                                                            </Fragment>
                                                        ))
                                                    }

                                                </div >
                                            }
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* New changes by hari for popup start */}
                    {isPantonetypePopupOpen ? (
                        <PantoneTypePopup
                            getPantoneTypeList={AllPantonetypeList}
                            pantoneTypeCallback={pantoneTypeCallback}
                            showpopup={true}
                        />
                    ) : null}

                    {isPantonePopupOpen ? (
                        <PantonePopup
                            getPantoneList={getPantone}
                            getPantoneTypeList={AllPantonetypeList}
                            pantoneCallback={pantoneCallback}
                            showpopup={true}
                        />
                    ) : null}
                    {isSwatchPopupOpen ? (
                        <SwatchPopup
                            SwatchList={getSwatch}
                            swatchCallback={swatchCallback}
                            showpopup={true}
                            BuyerID={props.location.state.params.buyerID}
                            BrandID={props.location.state.params.brandID}
                        />
                    ) : null}

                    {isProcesstypePopupOpen ? (
                        <Modal
                            show={isProcesstypePopupOpen === true}
                            size="md"
                            dragable
                            backdrop="static"
                            keyboard={false}
                            onHide={() => setProcessTypePopupOpen(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Add Process Type</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <ProcessTypePopup
                                    getProcessTypeList={getProcessTypeList}
                                    LoadProcessTypeList={getProcessTypeList}
                                    processTypeCallback={processTypeCallback}
                                    showpopup={true}
                                />
                            </Modal.Body>
                        </Modal>
                    ) : (
                        ""
                    )}
                    {/* New changes by hari for popup end */}

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>
                                <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                    <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                                </span>
                                <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                    <i className="fa fa-times"></i>&nbsp;Reset
                                </span>
                                {Buttonvisible !== "View" &&

                                    (<button type="submit" className="btn btn-success" onClick={(e) => SaveGeneralLabdip(e)} disabled={getDisableSave}>
                                        <i className="fa fa-check right"></i> &nbsp;{getHeader === 'Add' ? 'Save' : 'Update'}
                                    </button>)
                                }
                                {Buttonvisible === "View" && activeMenu[0].isView === 1 ? '' :
                                    activeMenu[0].isView === 0 ? '' :
                                        (<button type="submit" style={{ display: activeMenu[0].isView === 0 ? '' : 'none' }} className="btn btn-info" onClick={(e) => viewtoEdit(e)}>
                                            <i className="fa fa-edit"></i>&nbsp;Edit
                                        </button>)
                                }
                            </div>
                        </div>
                    </div>

                </div >




            }
        </>

    )
}
export default SamGenLabdipAddEdit;
