const SearchRetain = (page) => {
    var listing = [];

    // if (localStorage.getItem('Pagination')) {
    //     listing = (JSON.parse(localStorage.getItem('Pagination')));
    //     if (listing) {
    //         if (listing.Page !== page) {
    //             localStorage.removeItem('Pagination');
    //         }
    //     }
    //      localStorage.removeItem('Pagination');
    //     return listing;
    // }

    if (localStorage.getItem('Pagination') && page !== 'Remove') {
        listing = (JSON.parse(localStorage.getItem('Pagination')));
        if (listing) {
            if (listing.Page !== page) {
                localStorage.removeItem('Pagination');
            }
        }
         localStorage.removeItem('Pagination');
        return listing;
    }
    else if (page === 'Remove') {
        localStorage.removeItem('Search');
        localStorage.removeItem('Pagination');
    }
};

export default SearchRetain;