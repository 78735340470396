import React, { Fragment, useState, useMemo, useEffect } from "react";
import Reactselect from 'react-select';
import FinalinspectionService from "../../../services/FinalInspection/FinalinspectionService";
import FinalinspecUpload from "../../Common/FinalinspecUpload";
import StrikeoffService from "../../../services/Style/StrikeoffService";
import Lightbox from "react-image-lightbox"
import Nodify from "../../Common/ReactNotification";
const ArtWorks = ({ props, BasicDetails, artWorkscallback, getExfacStrikeoffList, IsValidationCallback, orderDetailsCheck }) => {

  const [getArtWorksValue, SetArtWorksValue] = useState([]);
  const [artworksExpandState, setartworksExpandState] = useState({});
  const [artworksexpandedRows, setartworksExpandedRows] = useState([]);
  const [getCommonResult, setCommonResult] = useState([]);
  const [Buttonvisible, SetButtonvisible] = useState("Show");
  const [isOpen, setIsOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [getImagename, setImagename] = useState();
  const [isOpenUpl, setIsOpenUpl] = useState(false);
  const [getImagenameUpl, setImagenameUpl] = useState();

  useEffect(() => {
    setSubmitted(IsValidationCallback);
  }, [IsValidationCallback === true]);
  const inpsectionId = JSON.parse(localStorage.getItem("InspectionID"));
  const Artmwork = JSON.parse(localStorage.getItem("Exfacconfirm"));

  useEffect(() => {

    if (props.location.state.params.ParentTask === "ProductionFLDetails") {
      props.location.state.params.styleID = props.location.state.params.NewDetails.styleID
    }
    let StrikeOffEditData = []
    debugger
    const inspectID = props.location.state.params.TaskStatus === 1 ? inpsectionId || 0 : props.location.state.params.TNAInspectionID;

    FinalinspectionService.GetTNAFinalinspecMainInfo(inspectID, props.location.state.params.details.tnaid).then((res) => {
      if (res.data.finalInsArtWorkList.length > 0) {
        res.data.finalInsArtWorkList.map((y, index) => {
          StrikeOffEditData.push({
            TNAFinalInspecArtworkID: y.tnaFinalInspecArtworkID,
            ResultID: y.result,
            FindingsRemarks: y.findingsRemarks,
            SkuName: y.skuName,
            StrikeOffName: y.artworkDetails,
            MainIndex: y.artMainindex,
            ImagesList: []
          })

          res.data.finalInsArtWorkSubList.filter(x => x.artMainindex === y.artMainindex).map((x, i) => {
            StrikeOffEditData[index].ImagesList.push({
              TNAFinalInspecArtworkSubID: x.tnaFinalInspecArtworkSubID,
              //UploadedImagePath: x.artworkFilePath,
              skuFrontPicPath: x.expectedFilepath,
              MainIndex: x.artMainindex,
              SubIndex: x.subIndex,
              StrikeoffImagesList: []
            })

            res.data.finalInsArtworkImages.filter(chl => chl.artWorkMainindex === x.artMainindex && chl.subIndex === x.subIndex).map((child, childindex) => {
              StrikeOffEditData[index].ImagesList[i].StrikeoffImagesList.push({
                TNAFinalInspecArtworkImagesID: child.tnaFinalInspecArtworkImagesID,
                UploadedImagePath: child.uploadedImagePath,
                MainIndex: child.artWorkMainindex,
                ImageIndex: child.imageIndex,
                SubIndex: child.subIndex,
              })
            })
          })
        })
        SetArtWorksValue(StrikeOffEditData)
        artWorkscallback(StrikeOffEditData)
      } else {
        debugger
        //if (ArtWorksInspectionID === 0) {
        let Multiselecteddate = []
        if (getExfacStrikeoffList.length > 0) {
          Multiselecteddate = getExfacStrikeoffList
        } else {
          Multiselecteddate = BasicDetails.multiselecteddatas.filter(x => x.isSelected === 1)
        }

        let StrikeOffSaveData = []
        FinalinspectionService.GetFinalTrimStrikeoffList(1, props.location.state.params.styleID, 0).then((res) => {
          // Multiselecteddate.map((y, N) => {
          let mainindex = 0;
          res.data.finalInsStrikeoffNamelist.map((x, index) => {
            // Split x.skuName into an array of individual SKU names
            const xSkuNamesArray = x.skuName.split(',').map(s => s.trim().toUpperCase());

            // Find the matching skuNames from Multiselecteddate
            const matchedSkuNames = [...new Set(Multiselecteddate
              .filter(m => xSkuNamesArray.includes(m.skuName.toUpperCase()))
              .map(m => m.skuName))];// Keep only the matched skuName

            // If there are any matched skuNames, proceed
            if (matchedSkuNames.length > 0) {
              // if (Multiselecteddate.filter(m => m.skuName === x.skuName).length > 0) {
              StrikeOffSaveData.push({
                TNAFinalInspecArtworkID: 0,
                ResultID: 0,
                FindingsRemarks: "",
                StrikeOffName: x.strikeoffFinalInspe + ' (' + matchedSkuNames.join(', ') + ')',
                SkuName: matchedSkuNames.join(', '), // Update SkuName to only include the matched ones
                MainIndex: mainindex,
                ImagesList: []

              })
              res.data.finalInsStrikeoffSkuImageList
                .filter(child => child.skuName === x.skuName && child.strikeoffID === x.strikeoffID
                  && child.styleStrikeOffPrintColorID === x.styleStrikeOffPrintColorID).map((Child, childindex) => {
                    StrikeOffSaveData[mainindex].ImagesList.push({
                      TNAFinalInspecArtworkSubID: 0,
                      //UploadedImagePath: "",
                      skuFrontPicPath: Child.strikeoffLogoPath,
                      MainIndex: mainindex,
                      ExpectedFilename: Child.strikeoffLogoName,
                      SubIndex: childindex,
                      StrikeoffImagesList: []
                    })
                    StrikeOffSaveData[mainindex].ImagesList[childindex].StrikeoffImagesList.push({
                      TNAFinalInspecArtworkImagesID: 0,
                      UploadedImagePath: "",
                      MainIndex: mainindex,
                      SubIndex: childindex,
                      ImageIndex: 0
                    })
                  })
              mainindex++
            }
          })
          //  })

          let uniquevalues = [...new Map(StrikeOffSaveData.map(item =>
            [item['StrikeOffName'], item])).values()];
          SetArtWorksValue(uniquevalues)
          artWorkscallback(uniquevalues)
        })
        // }
      }
    })

    let CommonResult = [];
    FinalinspectionService.getCommonResultList(0).then((res) => {
      if (res.data) {
        res.data.map((element) => {

          let LabelColor = '';
          if (element.resultID === 1) {
            LabelColor = <><span style={{ color: "green" }}><b>Ok</b></span></>
          }
          else if (element.resultID === 2) {
            LabelColor = <><span style={{ color: "Red" }}><b>Not ok</b></span></>
          }
          else if (element.resultID === 3) {
            LabelColor = <><span style={{ color: "blue" }}><b>Not Applicable</b></span></>
          }

          CommonResult.push({
            value: element.resultID,
            label: LabelColor,
            subLabel: element.value,
          })
        })
        setCommonResult(CommonResult);
      }
      // if (res.data) {
      //   res.data.map((x) => {
      //     CommonResult.push({
      //       value: x.resultID,
      //       label: x.resultName
      //     })
      //   })
      //   setCommonResult(CommonResult);
      // }
    })
  }, [props.location.state.params.TaskStatus === 2])

  useEffect(() => {

    if (orderDetailsCheck === 1) {
      //localStorage.removeItem("Exfacconfirm");
      //if (props.location.state.params.TaskStatus === 1) {
      let Multiselecteddate = []
      // if (getExfacStrikeoffList.length > 0) {
      //   Multiselecteddate = getExfacStrikeoffList
      // } else {
      //   Multiselecteddate = BasicDetails.multiselecteddatas.filter(x => x.isSelected === 1)
      // }

      var ExistingValue = getArtWorksValue;

      Multiselecteddate = BasicDetails.multiselecteddatas.filter(x => x.isSelected === 1)

      //compare and remove already exist data
      ExistingValue.map(ev => {
        Multiselecteddate.map((ms, index) => {
          if (ev.StyleStrikeOffSKUName === ms.skuName) {
            Multiselecteddate.splice(index, 1)
          }
        })
      })

      let CurrIndex = ExistingValue.length;

      let StrikeOffSaveData = []
      //
      FinalinspectionService.GetFinalTrimStrikeoffList(1, props.location.state.params.styleID, props.location.state.params.PurchaseOrderSkuID).then((res) => {
        //Multiselecteddate.map(y => (
        res.data.finalInsStrikeoffNamelist.map((x, index) => {
          //if (Multiselecteddate.filter(m => m.skuName === x.skuName).length > 0) {
          ExistingValue.push({
            TNAFinalInspecArtworkID: 0,
            ResultID: 0,
            FindingsRemarks: "",
            StrikeOffName: x.strikeoffFinalInspe + ' (' + x.skuName + ')',
            SkuName: x.skuName,
            MainIndex: CurrIndex,
            ImagesList: []
          })
          res.data.finalInsStrikeoffSkuImageList.filter(child => child.strikeoffID === x.strikeoffID).map((Child, childindex) => {
            ExistingValue[CurrIndex].ImagesList.push({
              TNAFinalInspecArtworkSubID: 0,
              //UploadedImagePath: "",
              skuFrontPicPath: Child.strikeoffLogoPath,
              MainIndex: CurrIndex,
              ExpectedFilename: Child.strikeoffLogoName,
              SubIndex: childindex,
              StrikeoffImagesList: []
            })
            ExistingValue[CurrIndex].ImagesList[childindex].StrikeoffImagesList.push({
              TNAFinalInspecArtworkImagesID: 0,
              UploadedImagePath: "",
              MainIndex: CurrIndex,
              SubIndex: childindex,
              ImageIndex: 0
            })
          })
          CurrIndex++;
          //}
        })
        //))

        let uniquevalues = [...new Map(ExistingValue.map(item =>
          [item['StrikeOffName'], item])).values()];
        SetArtWorksValue(uniquevalues)
        artWorkscallback(uniquevalues)
      })
      //}
    } else {

      var ExistingValue = getArtWorksValue;
      let Multiselecteddate = [];

      Multiselecteddate = BasicDetails.multiselecteddatas.filter(x => x.isSelected === 0)

      var deletevalue = getExfacStrikeoffList
      //compare and remove data
      // ExistingValue.map(ev => {


      //   let finalsins = Multiselecteddate.filter(x => x.skuName === ev.SkuName)
      //   if (finalsins.length > 0) {
      //     let index = ExistingValue.findIndex(x => x.SkuName === finalsins[0].skuName)
      //     ExistingValue.splice(index, 1)
      //   }



      //   // let index = ExistingValue.findIndex(x => x.skuName === finalsins.SkuName)
      //   // ExistingValue.splice(index, 1)
      //   // if (Multiselecteddate.filter(x => x.skuName === ev.SkuName).length === 0) {
      //   //   let index = ExistingValue.findIndex(x => x.skuName === ev.SkuName);
      //   //   ExistingValue.splice(index, 1)
      //   // }
      // })



      ExistingValue.forEach((ev, index) => {
        if (ev.SkuName.includes(',')) { // Check if SkuName contains a comma
          const skuNames = ev.SkuName.split(',').map(name => name.trim()); // Split by comma and trim whitespace
          const matchingItems = skuNames.filter(name => {
            return Multiselecteddate.some(x => x.skuName === name);
          });

          // Remove the matched items from the skuNames array
          matchingItems.forEach(name => {
            const idx = skuNames.indexOf(name);
            if (idx !== -1) {
              skuNames.splice(idx, 1);
            }
          });

          // Update the SkuName property with the remaining values
          ev.SkuName = skuNames.join(', ');
        } else {
          // No comma found, check if the SkuName matches any skuName in Multiselecteddate
          const matchingItems = Multiselecteddate.filter(x => x.skuName === ev.SkuName);
          // If any matches are found, remove the corresponding elements from ExistingValue
          if (matchingItems.length > 0) {
            let i = 0;
            while (i < ExistingValue.length) {
              const item = ExistingValue[i];
              if (matchingItems.some(match => match.skuName === item.SkuName)) {
                ExistingValue.splice(i, 1);
                console.log(`Removed item at index ${i}`);
                console.log(ExistingValue);
              } else {
                i++;
              }
            }
          }
        }
      });


      // const commonValues = ExistingValue.filter(existingItem =>
      //   Multiselecteddate.some(selectedItem => selectedItem.skuName === existingItem.SkuName)
      // );

      //console.log(commonValues);
      let uniquevalues = [...new Map(ExistingValue.map(item =>
        [item['StrikeOffName'], item])).values()];
      SetArtWorksValue(uniquevalues)
      artWorkscallback(uniquevalues)
    }
  }, [getExfacStrikeoffList])

  useEffect(() => {

    if (props.location.state.params.ParentTask === "ProductionFLDetails") {
      props.location.state.params.styleID = props.location.state.params.NewDetails.styleID
    }
    if (props.location.state.params.TaskStatus === 1) {
      let Multiselecteddate = []
      let StrikeOffEditData = [];
      if (inpsectionId !== null) {
        FinalinspectionService.GetTNAFinalinspecMainInfo(inpsectionId, props.location.state.params.details.tnaid).then((res) => {
          if (res.data.finalInsArtWorkList.length > 0) {
            res.data.finalInsArtWorkList.map((y, index) => {
              StrikeOffEditData.push({
                TNAFinalInspecArtworkID: y.tnaFinalInspecArtworkID,
                ResultID: y.result,
                FindingsRemarks: y.findingsRemarks,
                StrikeOffName: y.artworkDetails,
                SkuName: y.skuName,
                MainIndex: y.artMainindex,
                ImagesList: []
              })

              res.data.finalInsArtWorkSubList.filter(x => x.artMainindex === y.artMainindex).map((x, i) => {
                StrikeOffEditData[index].ImagesList.push({
                  TNAFinalInspecArtworkSubID: x.tnaFinalInspecArtworkSubID,
                  //UploadedImagePath: x.artworkFilePath,
                  skuFrontPicPath: x.expectedFilepath,
                  MainIndex: x.artMainindex,
                  SubIndex: x.subIndex,
                  StrikeoffImagesList: []
                })

                res.data.finalInsArtworkImages.filter(chl => chl.artWorkMainindex === x.artMainindex && chl.subIndex === x.subIndex).map((child, childindex) => {
                  StrikeOffEditData[index].ImagesList[i].StrikeoffImagesList.push({
                    TNAFinalInspecArtworkImagesID: child.tnaFinalInspecArtworkImagesID,
                    UploadedImagePath: child.uploadedImagePath,
                    MainIndex: child.artWorkMainindex,
                    ImageIndex: child.imageIndex,
                    SubIndex: child.subIndex,
                  })
                })
              })

              SetArtWorksValue(StrikeOffEditData)
              artWorkscallback(StrikeOffEditData)
            })
          } else {
            let Multiselecteddate = []
            if (getExfacStrikeoffList.length > 0) {
              Multiselecteddate = getExfacStrikeoffList
            } else {
              Multiselecteddate = BasicDetails.multiselecteddatas.filter(x => x.isSelected === 1)
            }
            let StrikeOffSaveData = []

            FinalinspectionService.GetFinalTrimStrikeoffList(1, props.location.state.params.styleID, props.location.state.params.PurchaseOrderSkuID).then((res) => {

              //Multiselecteddate.map((y, N) => {
              res.data.finalInsStrikeoffNamelist.map((x, index) => {
                // if (Multiselecteddate.filter(m => m.skuName === x.skuName).length > 0) {
                StrikeOffSaveData.push({
                  TNAFinalInspecArtworkID: 0,
                  ResultID: 0,
                  FindingsRemarks: "",
                  StrikeOffName: x.strikeoffFinalInspe + ' (' + x.skuName + ')',
                  SkuName: x.skuName,
                  MainIndex: index,
                  ImagesList: []

                })
                res.data.finalInsStrikeoffSkuImageList.filter(child => child.strikeoffID === x.strikeoffID).map((Child, childindex) => {
                  StrikeOffSaveData[index].ImagesList.push({
                    TNAFinalInspecArtworkSubID: 0,
                    //UploadedImagePath: "",
                    skuFrontPicPath: Child.strikeoffLogoPath,
                    MainIndex: index,
                    ExpectedFilename: Child.strikeoffLogoName,
                    SubIndex: childindex,
                    StrikeoffImagesList: []
                  })
                  StrikeOffSaveData[index].ImagesList[childindex].StrikeoffImagesList.push({
                    TNAFinalInspecArtworkImagesID: 0,
                    UploadedImagePath: "",
                    MainIndex: index,
                    SubIndex: childindex,
                    ImageIndex: 0
                  })
                })
                //}
              })
              //})

              let uniquevalues = [...new Map(StrikeOffSaveData.map(item =>
                [item['StrikeOffName'], item])).values()];
              SetArtWorksValue(uniquevalues)
              artWorkscallback(uniquevalues)
            })
          }
        })

      } else {
        let Multiselecteddate = []
        if (getExfacStrikeoffList.length > 0) {
          Multiselecteddate = getExfacStrikeoffList
        } else {
          Multiselecteddate = BasicDetails.multiselecteddatas.filter(x => x.isSelected === 1)
        }

        let StrikeOffSaveData = []
        FinalinspectionService.GetFinalTrimStrikeoffList(1, props.location.state.params.styleID, props.location.state.params.PurchaseOrderSkuID).then((res) => {
          //Multiselecteddate.map((y, N) => {
          let mainindex = 0;
          res.data.finalInsStrikeoffNamelist.map((x, index) => {
            // if (Multiselecteddate.filter(m => m.skuName === x.skuName).length > 0) {
            StrikeOffSaveData.push({
              TNAFinalInspecArtworkID: 0,
              ResultID: 0,
              FindingsRemarks: "",
              StrikeOffName: x.strikeoffFinalInspe + ' (' + x.skuName + ')',
              SkuName: x.skuName,
              MainIndex: mainindex,
              ImagesList: []
            })

            res.data.finalInsStrikeoffSkuImageList.filter(child => child.strikeoffID === x.strikeoffID).map((Child, childindex) => {

              StrikeOffSaveData[mainindex].ImagesList.push({
                TNAFinalInspecArtworkSubID: 0,
                //UploadedImagePath: "",
                skuFrontPicPath: Child.strikeoffLogoPath,
                MainIndex: mainindex,
                ExpectedFilename: Child.strikeoffLogoName,
                SubIndex: childindex,
                StrikeoffImagesList: []
              })
              StrikeOffSaveData[mainindex].ImagesList[childindex].StrikeoffImagesList.push({
                TNAFinalInspecArtworkImagesID: 0,
                UploadedImagePath: "",
                MainIndex: mainindex,
                SubIndex: childindex,
                ImageIndex: 0
              })
            })
            mainindex++;
            //}
          })
          // })
          let uniquevalues = [...new Map(StrikeOffSaveData.map(item =>
            [item['StrikeOffName'], item])).values()];
          SetArtWorksValue(uniquevalues)
          artWorkscallback(uniquevalues)

        })

        // }

      }
    }
  }, [inpsectionId !== null && props.location.state.params.TaskStatus === 1])


  let styles = {
    control: styles => ({ ...styles, border: '1px solid red', innerHeight: '100px', outerHeight: '100px' }),
  }

  const ImageCallback = (value, index, childindex, imgindex) => {
    var imagevalues = [...getArtWorksValue]
    let profile = imagevalues !== '' ? "/Style/SKU/" : '';
    imagevalues[index].ImagesList[childindex].StrikeoffImagesList[imgindex].MainIndex = index;
    imagevalues[index].ImagesList[childindex].StrikeoffImagesList[imgindex].UploadedImageName = value;
    imagevalues[index].ImagesList[childindex].StrikeoffImagesList[imgindex].UploadedImagePath = profile + value;
    SetArtWorksValue(imagevalues)

  }

  const handleCommonResultInputChange = (index, e, FeildName) => {
    const values = [...getArtWorksValue];
    let inputText = ""
    if (FeildName === "InspectionResult") {
      if (e !== null) {
        values[index].ResultID = e.value;
        values[index].ResultName = e.label;
        values[index].MainIndex = index;
      } else {
        values[index].ResultID = 0;
        values[index].ResultName = '';
      }
    } else {
      if (e.target.value.trim() !== '') {
        inputText = e.target.value;
      }
      values[index].FindingsRemarks = inputText;
    }
    SetArtWorksValue(values);
    artWorkscallback(values)
  };
  const handleArtworksExpandRow = (rowNumber) => {
    setartworksExpandedRows([]);
    let currentExpandedRows;
    let isRowExpanded = false;
    currentExpandedRows = artworksexpandedRows;
    isRowExpanded = currentExpandedRows.includes(rowNumber);
    let obj = {};
    isRowExpanded ? (obj[rowNumber] = false) : (obj[rowNumber] = true);
    setartworksExpandState(obj);
    currentExpandedRows = [];
    const newExpandedRows = isRowExpanded ?
      currentExpandedRows.filter(id => id !== rowNumber) :
      currentExpandedRows.concat(rowNumber);
    setartworksExpandedRows(newExpandedRows);
  }

  const deleteFile = (index, childindex, imgindex) => {
    var imagevalues = [...getArtWorksValue]
    imagevalues[index].ImagesList[childindex].StrikeoffImagesList[imgindex].UploadedImageName = ''
    imagevalues[index].ImagesList[childindex].StrikeoffImagesList[imgindex].UploadedImagePath = "";
    SetArtWorksValue(imagevalues);
    artWorkscallback(imagevalues)
  }
  function ViewFile(event, filename, ViewName) {

    if (ViewName === 'Image1') {
      setIsOpen(true);
      setImagename(filename);
    } else {
      setIsOpenUpl(true);
      setImagenameUpl(filename);
    }
  }

  function handleAddFields(index, childindex, imgindex, skuFrontPicPath, ExpectedFilename) {

    var imagevalues = [...getArtWorksValue]
    let isvalid = true;
    if (imagevalues[index].ImagesList[childindex].StrikeoffImagesList[imgindex].UploadedImagePath === "") {
      isvalid = false;
      Nodify('Warning!', 'warning', 'Please Upload the Image');
    }

    if (isvalid) {
      imagevalues[index].ImagesList[childindex].StrikeoffImagesList.push({ UploadedImagePath: "", UploadedImageName: '', MainIndex: index, ImageIndex: imgindex + 1 })
    }
    SetArtWorksValue(imagevalues);
    artWorkscallback(imagevalues)
  }

  function handleRemoveFields(index, childindex, imgindex, length) {
    var imagevalues = [...getArtWorksValue]
    if (length === 1) {
      imagevalues[index].ImagesList[childindex].StrikeoffImagesList[imgindex].UploadedImageName = ''
      imagevalues[index].ImagesList[childindex].StrikeoffImagesList[imgindex].UploadedImagePath = "";
    } else {
      imagevalues[index].ImagesList[childindex].StrikeoffImagesList.splice(imgindex, 1);
    }
    SetArtWorksValue(imagevalues);
    artWorkscallback(imagevalues)
  }

  return (
    <table className="table table-striped table-bordered table-hover dataTable no-footer">
      <thead>
        <tr>
          <th className="InsMainInfoDetailsCol">Details</th>
          <th className="InsMainInfoResultsCol">Strike Off Artworks Result<span className="text-danger"> *</span></th>
          <th className="InsMainInfoRemarksCol">Finding / Remarks<span className="text-danger"> *</span></th>
        </tr>
      </thead>
      <tbody>
        {

          getArtWorksValue.map((comment, index) => (

            <Fragment>

              <tr key={
                index
              }>
                <td title={comment.StrikeOffName} className="cut_text">
                  <i
                    className={
                      artworksExpandState[index] ?
                        'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                    } onClick={event => handleArtworksExpandRow(index)}
                  ></i>&nbsp;
                  <b>{comment.StrikeOffName}</b>
                  {/* -{comment.StyleStrikeOffSKUName} */}
                </td>
                <td>   <Reactselect className="basic-single" name="Result"
                  id={
                    "Result" + index
                  }
                  isDisabled={props.location.state.params.Action === 'View' ? true : false}
                  isLoading={false}
                  isClearable={true}
                  isSearchable={true}
                  onChange={(event) => handleCommonResultInputChange(index, event, 'InspectionResult')}
                  options={getCommonResult}
                  styles={submitted && comment.ResultID === 0 ? styles : ''}
                  value={getCommonResult.filter(function (option) {
                    return option.value === comment.ResultID;
                  })}

                ></Reactselect></td>
                <td><input type="text" className="form-control" name={index + "_txtRemarks"}
                  id={index + "_txtRemarks"}
                  value={comment.FindingsRemarks}
                  style={{ border: submitted && (comment.ResultID === 2 && comment.FindingsRemarks === '') ? '1px solid red' : '' }}
                  autoComplete="off"
                  maxLength="200"
                  disabled={props.location.state.params.Action === 'View' ? true : false}
                  onChange={(event) => handleCommonResultInputChange(index, event, 'FindingRemarks')} /></td>
              </tr><>
                {
                  artworksexpandedRows.includes(index) ?
                    <tr>
                      <td colspan="3">
                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                          <tbody>
                            {
                              comment.ImagesList.map((child, childindex) => (
                                <Fragment>
                                  <tr key={
                                    child.index
                                  }>

                                    {

                                      <td className="table-width-40">
                                        <td className="table-width-40">
                                          {<span>Expected : </span>}
                                          <br />
                                          <br />
                                          {
                                            <img src={window.$APIBaseURL + "Images/Style/Strikeoff/" + child.skuFrontPicPath} alt="" width="150px" height="150px" />
                                          }&nbsp; &nbsp;
                                          <i className="btn btn-info fa fa-eye Packingeyeadjust" title="View Logo" style={{ marginRight: "6px" }}
                                            onClick={
                                              event => ViewFile(event, child.skuFrontPicPath, 'Image1')
                                            }></i></td>
                                      </td>


                                    }
                                    {<td className="table-width-60">
                                      <div className="row">
                                        <div className="col-md-6">
                                          Found :
                                        </div>

                                      </div>
                                      <div className="row">
                                        {child.StrikeoffImagesList.map((Images, imgindex) => (
                                          <div className="col-lg-6 text-center">
                                            <span className="text-danger">Click on the image to upload new</span><br /><br />

                                            <div>
                                              <FinalinspecUpload UploadCallback={(e) => ImageCallback(e, index, childindex, imgindex)} PageName='Style/SKU' Buttonview={Buttonvisible} ShowDiv="1" filePath={Images.UploadedImagePath} />
                                            </div>

                                            {Images.UploadedImagePath !== '' ? <div className="row">
                                              <div className="col-md-7">
                                                {/* <i style={{ marginRight: "0" }} className="btn btn-danger fa fa-trash-o FloatRight" title="Delete Logo"
                                                  onClick={event => deleteFile(index, childindex, "UploadedImagePath1", imgindex)}></i> */}

                                              </div>
                                            </div> : ''}
                                            <span className="TnaFinaspecwrkmans" style={{ left: "120px" }}>
                                              {Images.UploadedImagePath !== '' ? <button onClick={
                                                event => ViewFile(event, Images.UploadedImagePath, 'Image2')
                                              } className="btn btn-info fa fa-eye" style={{ padding: "5px 6px", width: "7%" }}>
                                                <i title="View Logo" style={{ marginRight: "6px" }}
                                                ></i>
                                              </button> : ''}

                                              &nbsp;
                                              {props.location.state.params.Action !== 'View' ? <button type="button" style={{ display: getArtWorksValue[index].ImagesList[childindex].StrikeoffImagesList.length < 2 ? '' : 'none' }} className="btn btn-xs btn-success" title="Add packing Image"
                                                onClick={() => handleAddFields(index, childindex, imgindex, child.skuFrontPicPath, child.ExpectedFilename)}
                                              >
                                                <i className="fa fa-plus"></i>
                                              </button> : ''}
                                              &nbsp;
                                              <button type="button"
                                                className="btn btn-xs btn-danger" title="Delete packing Image" style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}
                                                onClick={() => handleRemoveFields(index, childindex, imgindex, getArtWorksValue[index].ImagesList[childindex].StrikeoffImagesList.length)}
                                              >
                                                <i className="fa fa-trash-o"></i>
                                              </button>
                                            </span>
                                          </div>
                                        ))
                                        }
                                      </div>

                                    </td>
                                    }
                                  </tr>
                                </Fragment>
                              ))
                            }
                          </tbody>
                        </table>
                      </td>
                    </tr> : ""
                }</>
              <div> {
                isOpen && (

                  <Lightbox mainSrc={window.$APIBaseURL + 'Images/Style/Strikeoff/' + getImagename}
                    alt="bg image"
                    onCloseRequest={
                      () => setIsOpen(false)
                    } />
                )
              } </div>
              <div> {
                isOpenUpl && (

                  <Lightbox mainSrc={window.$APIBaseURL + 'Images' + getImagenameUpl}
                    alt="bg image"
                    onCloseRequest={
                      () => setIsOpenUpl(false)
                    } />
                )
              } </div>
            </Fragment>
          ))
        }
        {
          getArtWorksValue.length === 0 ?
            <tr>
              <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
            </tr> : ''
        }
      </tbody>
    </table >
  )
}

export default ArtWorks;