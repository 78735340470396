import React, { Fragment, useState, useMemo, useEffect } from "react";
import Reactselect from 'react-select';
import FinalinspectionService from "../../../services/FinalInspection/FinalinspectionService";
import FinalinspecUpload from "../../Common/FinalinspecUpload";
import Lightbox from "react-image-lightbox"
import Nodify from "../../Common/ReactNotification";
const StyleAndColors = ({ props, BasicDetails, styleandcolorcallback, getExfacStrikeoffList, StyleAndColorsInspectionID, IsValidationCallback, orderDetailsCheck }) => {
  const [getStyleAndColorValue, setStyleAndColorValue] = useState([]);
  const [styleAndColorsExpandState, setStyleAndColorsExpandState] = useState({});
  const [styleAndColorsexpandedRows, setStyleAndColorsExpandedRows] = useState([]);
  const [getCommonResult, setCommonResult] = useState([]);
  const [Buttonvisible, SetButtonvisible] = useState("Show");
  const [isOpen, setIsOpen] = useState(false);
  const [getImagename, setImagename] = useState();
  const [submitted, setSubmitted] = useState(false);
  useEffect(() => {
    setSubmitted(IsValidationCallback);
  }, [IsValidationCallback === true]);
  const Artmwork = JSON.parse(localStorage.getItem("Exfacconfirm"));
  const inpsectionId = JSON.parse(localStorage.getItem("InspectionID"));
  useEffect(() => {
    let StyleAndColorEdit = [];
    if (props.location.state.params.TaskStatus === 2) {
      FinalinspectionService.GetTNAFinalinspecMainInfo(props.location.state.params.TNAInspectionID, props.location.state.params.details.tnaid).then((res) => {
        if (res.data.finalInsStyleColorList.length > 0) {
          res.data.finalInsStyleColorList.map((y, index) => {
            StyleAndColorEdit.push({
              TNAFinalInspecStylecolorID: y.tnaFinalInspecStylecolorID,
              ResultID: y.result,
              FindingsRemarks: y.findingsRemarks,
              skuName: y.stylecolorDetails,
              MainIndex: y.mainindex,
              ImagesList: []
            })

            res.data.finalInsStyleColorSubList.filter(x => x.mainindex === y.mainindex && x.skuname === y.stylecolorDetails).map((x, i) => {
              StyleAndColorEdit[index].ImagesList.push({
                TNAFinalInspecStylecolorSubID: x.tnaFinalInspecStylecolorSubID,
                UploadedImagePath: x.stylecolorFilePath,
                skuFrontPicPath: x.expectedFilepath,
                skuPicPathposition: x.skubackpicpath,
                MainIndex: x.mainindex,
                StylecolorImagesList: []
              })
              res.data.finalInsStyleColorsImageList.filter(Kn => Kn.mainindex === y.mainindex && Kn.subindex === x.subindex).map((child, childindex) => {
                StyleAndColorEdit[index].ImagesList[i].StylecolorImagesList.push({
                  TNAFinalcStylecolorImageID: child.tnaFinalcStylecolorImageID,
                  UploadedImagePath: child.uploadedImagePath,
                  MainIndex: child.mainindex,
                  ImageIndex: child.imageIndex,
                })
              })
            })

          })
          var uniqueimages = [...new Map(StyleAndColorEdit.map(item =>
            [item['skuName'], item])).values()];
          setStyleAndColorValue(uniqueimages)
          styleandcolorcallback(uniqueimages)
        } else {
          let newdatass = []
          let Multiselecteddate = [];

          Multiselecteddate = BasicDetails.multiselecteddatas.filter(x => x.isSelected === 1)
          var uniqueimages = [...new Map(Multiselecteddate.map(item =>
            [item['skuName'], item])).values()];

          //uniqueimages.map((y, index) => {
          FinalinspectionService.GetFinalTrimStrikeoffList(3, props.location.state.params.styleID, '0').then((res) => {
            if (res.data.finalInsStylecolorslist.length > 0) {
              res.data.finalInsStylecolorslist.map((main, mainindex) => {

                if (uniqueimages.filter(m => m.skuName === main.skuName).length > 0) {
                  newdatass.push({
                    TNAFinalInspecStylecolorID: 0,
                    ResultID: 0,
                    FindingsRemarks: "",
                    skuName: main.skuName,
                    MainIndex: mainindex,
                    ImagesList: []
                  })
                  if (res.data.finalInsStylecolorsSkuImageList > 0) {
                    res.data.finalInsStylecolorsSkuImageList.filter(sub => sub.skuName === main.skuName && sub.skuPicPath !== '').map((chil, chilindex) => {
                      newdatass[mainindex].ImagesList.push({
                        TNAFinalInspecStylecolorSubID: 0,
                        //UploadedImagePath: "",
                        skuFrontPicPath: chil.skuPicPath,
                        skuPicPathposition: chil.skuPosition,
                        MainIndex: mainindex,
                        StylecolorImagesList: []
                      })
                      newdatass[mainindex].ImagesList[chilindex].StylecolorImagesList.push({
                        TNAFinalcStylecolorImageID: 0,
                        UploadedImagePath: "",
                        MainIndex: mainindex,
                        ImageIndex: 0
                      })
                    })
                  }
                }
              })
            }
            setStyleAndColorValue(newdatass)
            styleandcolorcallback(newdatass)
          })
        }
      })
    }

    // let CommonResult = [];
    // FinalinspectionService.getCommonResultList(0).then((res) => {
    //   if (res.data) {
    //     res.data.map((x) => {
    //       CommonResult.push({
    //         value: x.resultID,
    //         label: x.resultName
    //       })
    //     })
    //     setCommonResult(CommonResult);
    //   }
    // })
    let CommonResult = [];
    FinalinspectionService.getCommonResultList(0).then((res) => {
      if (res.data) {
        res.data.map((element) => {

          let LabelColor = '';
          if (element.resultID === 1) {
            LabelColor = <><span style={{ color: "green" }}><b>Ok</b></span></>
          }
          else if (element.resultID === 2) {
            LabelColor = <><span style={{ color: "Red" }}><b>Not ok</b></span></>
          }
          else if (element.resultID === 3) {
            LabelColor = <><span style={{ color: "blue" }}><b>Not Applicable</b></span></>
          }

          CommonResult.push({
            value: element.resultID,
            label: LabelColor,
            subLabel: element.value,
          })
        })
        setCommonResult(CommonResult);
      }
    })


  }, [props.location.state.params.TaskStatus === 2])

  useEffect(() => {

    if (orderDetailsCheck === 1) {
      var ExistingValue = getStyleAndColorValue;
      let Multiselecteddate = [];

      Multiselecteddate = BasicDetails.multiselecteddatas.filter(x => x.isSelected === 1)

      //compare and remove already exist data
      ExistingValue.map(ev => {
        Multiselecteddate.map((ms, index) => {
          if (ev.skuName === ms.skuName) {
            Multiselecteddate.splice(index, 1)
          }
        })
      })

      var uniqueimages = [...new Map(Multiselecteddate.map(item =>
        [item['skuName'], item])).values()];

      let CurrIndex = ExistingValue.length;
      FinalinspectionService.GetFinalTrimStrikeoffList(3, props.location.state.params.styleID, '0').then((res) => {
        res.data.finalInsStylecolorslist.map((main, mainindex) => {
          if (uniqueimages.filter(m => m.skuName === main.skuName).length > 0) {
            ExistingValue.push({
              TNAFinalInspecStylecolorID: 0,
              ResultID: 0,
              FindingsRemarks: "",
              skuName: main.skuName,
              MainIndex: mainindex,
              ImagesList: []
            })
            res.data.finalInsStylecolorsSkuImageList.filter(sub => sub.skuName === main.skuName && sub.skuPicPath !== '').map((chil, chilindex) => {
              ExistingValue[mainindex].ImagesList.push({
                TNAFinalInspecStylecolorSubID: 0,
                //UploadedImagePath: "",
                skuFrontPicPath: chil.skuPicPath,
                skuPicPathposition: chil.skuPosition,
                MainIndex: mainindex,
                StylecolorImagesList: []
              })
              ExistingValue[mainindex].ImagesList[chilindex].StylecolorImagesList.push({
                TNAFinalcStylecolorImageID: 0,
                UploadedImagePath: "",
                MainIndex: mainindex,
                ImageIndex: 0
              })
            })
          }
        })
        setStyleAndColorValue(ExistingValue)
        styleandcolorcallback(ExistingValue)
      })


      //re assign main index
      // ExistingValue.map((item, index) => {
      //   item.MainIndex = index
      //   ExistingValue[index].ImagesList.map((sitem, sindex) => {
      //     sitem.MainIndex = index;
      //   })
      // })

    } else {
      var ExistingValue = getStyleAndColorValue;
      let Multiselecteddate = [];

      Multiselecteddate = BasicDetails.multiselecteddatas.filter(x => x.isSelected === 1)

      //compare and remove data
      ExistingValue.map(ev => {
        if (Multiselecteddate.filter(x => x.skuName === ev.skuName).length === 0) {
          let index = ExistingValue.findIndex(x => x.skuName === ev.skuName);
          ExistingValue.splice(index, 1)
        }
      })


    }
    setStyleAndColorValue(ExistingValue)
    styleandcolorcallback(ExistingValue)
  }, [getExfacStrikeoffList])

  useEffect(() => {
    let StyleAndColorEdit = [];
    (async () => {
      if (props.location.state.params.TaskStatus === 1) {
       
        if (inpsectionId !== null) {
          FinalinspectionService.GetTNAFinalinspecMainInfo(inpsectionId, props.location.state.params.details.tnaid).then((res) => {
            if (res.data.finalInsStyleColorList.length > 0) {
              res.data.finalInsStyleColorList.map((y, index) => {
                StyleAndColorEdit.push({
                  TNAFinalInspecStylecolorID: y.tnaFinalInspecStylecolorID,
                  ResultID: y.result,
                  FindingsRemarks: y.findingsRemarks,
                  skuName: y.stylecolorDetails,
                  MainIndex: y.mainindex,
                  ImagesList: []
                })

                res.data.finalInsStyleColorSubList.filter(x => x.mainindex === y.mainindex && x.skuname === y.stylecolorDetails).map((x, i) => {
                  StyleAndColorEdit[index].ImagesList.push({
                    TNAFinalInspecStylecolorSubID: x.tnaFinalInspecStylecolorSubID,
                    UploadedImagePath: x.stylecolorFilePath,
                    skuFrontPicPath: x.expectedFilepath,
                    skuPicPathposition: x.skubackpicpath,
                    MainIndex: x.mainindex,
                    StylecolorImagesList: []
                  })
                  res.data.finalInsStyleColorsImageList.filter(Kn => Kn.mainindex === y.mainindex && Kn.subindex === x.subindex).map((child, childindex) => {
                    StyleAndColorEdit[index].ImagesList[i].StylecolorImagesList.push({
                      TNAFinalcStylecolorImageID: child.tnaFinalcStylecolorImageID,
                      UploadedImagePath: child.uploadedImagePath,
                      MainIndex: child.mainindex,
                      ImageIndex: child.imageIndex,
                    })
                  })
                })

              })
              var uniqueimages = [...new Map(StyleAndColorEdit.map(item =>
                [item['skuName'], item])).values()];
              setStyleAndColorValue(uniqueimages)
              styleandcolorcallback(uniqueimages)
            } else {
              let newdatass = []
              let Multiselecteddate = [];

              Multiselecteddate = BasicDetails.multiselecteddatas.filter(x => x.isSelected === 1)
              var uniqueimages = [...new Map(Multiselecteddate.map(item =>
                [item['skuName'], item])).values()];
              FinalinspectionService.GetFinalTrimStrikeoffList(3, props.location.state.params.styleID, '0').then((res) => {
                res.data.finalInsStylecolorslist.map((main, mainindex) => {
                  if (uniqueimages.filter(m => m.skuName === main.skuName).length > 0) {
                    newdatass.push({
                      TNAFinalInspecStylecolorID: 0,
                      ResultID: 0,
                      FindingsRemarks: "",
                      skuName: main.skuName,
                      MainIndex: mainindex,
                      ImagesList: []
                    })

                   ;
                    res.data.finalInsStylecolorsSkuImageList.filter(sub => sub.skuName === main.skuName && sub.skuPicPath !== '').map((chil, chilindex) => {

                      if (newdatass[mainindex] !== undefined) {
                        newdatass[mainindex].ImagesList.push({
                          TNAFinalInspecStylecolorSubID: 0,
                          //UploadedImagePath: "",
                          skuFrontPicPath: chil.skuPicPath,
                          skuPicPathposition: chil.skuPosition,
                          MainIndex: mainindex,
                          StylecolorImagesList: []
                        })
                        newdatass[mainindex].ImagesList[chilindex].StylecolorImagesList.push({
                          TNAFinalcStylecolorImageID: 0,
                          UploadedImagePath: "",
                          MainIndex: mainindex,
                          ImageIndex: 0
                        })
                      }
                    })
                  }
                })
                setStyleAndColorValue(newdatass)
                styleandcolorcallback(newdatass)
              })

            }
          })

        } else {
          let newdatass = []
          let Multiselecteddate = [];
          let mainindex = 0;
         
          Multiselecteddate = BasicDetails.multiselecteddatas.filter(x => x.isSelected === 1)
          var uniqueimages = [...new Map(Multiselecteddate.map(item =>
            [item['skuName'], item])).values()];
          await FinalinspectionService.GetFinalTrimStrikeoffList(3, props.location.state.params.styleID, '0').then(async (res) => {
            await res.data.finalInsStylecolorslist.map((main) => {
              if (uniqueimages.filter(m => m.skuName === main.skuName).length > 0) {
                newdatass.push({
                  TNAFinalInspecStylecolorID: 0,
                  ResultID: 0,
                  FindingsRemarks: "",
                  skuName: main.skuName,
                  MainIndex: mainindex,
                  ImagesList: []
                })
                res.data.finalInsStylecolorsSkuImageList.filter(sub => sub.skuName === main.skuName && sub.skuPicPath !== '').map((chil, chilindex) => {
                  newdatass[mainindex].ImagesList.push({
                    TNAFinalInspecStylecolorSubID: 0,
                    //UploadedImagePath: "",
                    skuFrontPicPath: chil.skuPicPath,
                    skuPicPathposition: chil.skuPosition,
                    MainIndex: mainindex,
                    StylecolorImagesList: []
                  })
                  newdatass[mainindex].ImagesList[chilindex].StylecolorImagesList.push({
                    TNAFinalcStylecolorImageID: 0,
                    UploadedImagePath: "",
                    MainIndex: mainindex,
                    ImageIndex: 0
                  })
                })
                mainindex++
              }
            })
            setStyleAndColorValue(newdatass)
            styleandcolorcallback(newdatass)
          })
        }
      }
    })();
  }, [inpsectionId !== null])

  // useEffect(() => {
  //   if (props.location.state.params.TaskStatus === 1) {
  //     let newdatass = []
  //     let Multiselecteddate = [];

  //     Multiselecteddate = BasicDetails.multiselecteddatas.filter(x => x.isSelected === 1)
  //     var uniqueimages = [...new Map(Multiselecteddate.map(item =>
  //       [item['skuName'], item])).values()];
  //     uniqueimages.map((y, index) => {
  //       newdatass.push({
  //         TNAFinalInspecStylecolorID: 0,
  //         ResultID: 0,
  //         FindingsRemarks: "",
  //         skuName: y.skuName,
  //         MainIndex: index,
  //         ImagesList: []
  //       })

  //       newdatass[index].ImagesList.push({
  //         TNAFinalInspecStylecolorSubID: 0,
  //         UploadedImagePath: "",
  //         skuFrontPicPath: y.skuFrontPicPath,
  //         MainIndex: index
  //       })
  //     })

  //     setStyleAndColorValue(newdatass)
  //     styleandcolorcallback(newdatass)
  //   }
  // }, [inpsectionId === null])

  let styles = {
    control: styles => ({ ...styles, border: '1px solid red', innerHeight: '100px', outerHeight: '100px' }),
  }
  const ImageCallback = (value, index, childindex, imgindex) => {
    var imagevalues = [...getStyleAndColorValue]
    let profile = imagevalues !== '' ? "/Style/SKU/" : '';
    imagevalues[index].ImagesList[childindex].StylecolorImagesList[imgindex].MainIndex = index;
    imagevalues[index].ImagesList[childindex].StylecolorImagesList[imgindex].UploadedImageName = value;
    imagevalues[index].ImagesList[childindex].StylecolorImagesList[imgindex].UploadedImagePath = profile + value;

    //imagevalues[index].UploadedImage = value
    setStyleAndColorValue(imagevalues)

  }
  function ImageCallback1(value, index, childindex, imgindex) {
    var imagevalues = [...getStyleAndColorValue]
    let profile = imagevalues !== '' ? "/Style/SKU/" : '';
    let childindexS = childindex + 1;
    imagevalues[index].ImagesList[childindexS].StylecolorImagesList[imgindex].MainIndex = index;
    imagevalues[index].ImagesList[childindexS].StylecolorImagesList[imgindex].UploadedImageName = value;
    imagevalues[index].ImagesList[childindexS].StylecolorImagesList[imgindex].UploadedImagePath = profile + value;

    //imagevalues[index].UploadedImage = value
    setStyleAndColorValue(imagevalues)
  }
  const handleStyleAndColorsExpandRow = (rowNumber) => {

    setStyleAndColorsExpandedRows([]);
    let currentExpandedRows;
    let isRowExpanded = false;
    currentExpandedRows = styleAndColorsexpandedRows;
    isRowExpanded = currentExpandedRows.includes(rowNumber);
    let obj = {};
    isRowExpanded ? (obj[rowNumber] = false) : (obj[rowNumber] = true);
    setStyleAndColorsExpandState(obj);
    currentExpandedRows = [];
    const newExpandedRows = isRowExpanded ?
      currentExpandedRows.filter(id => id !== rowNumber) :
      currentExpandedRows.concat(rowNumber);
    setStyleAndColorsExpandedRows(newExpandedRows);
  }

  const handleCommonResultInputChange = (index, e, FeildName) => {
    const values = [...getStyleAndColorValue];
    let inputText = ""
    if (FeildName === "InspectionResult") {
      if (e !== null) {
        values[index].ResultID = e.value;
        values[index].ResultName = e.label;
        values[index].MainIndex = index;
      } else {
        values[index].ResultID = 0;
        values[index].ResultName = '';
      }
    } else {
      if (e.target.value.trim() !== '') {
        inputText = e.target.value;
      }
      values[index].FindingsRemarks = inputText;
    }

    setStyleAndColorValue(values);
    styleandcolorcallback(values)
  };

  const deleteFile = (index, childindex, imgindex) => {
    var imagevalues = [...getStyleAndColorValue]
    imagevalues[index].ImagesList[childindex].StylecolorImagesList[imgindex].UploadedImageName = "";
    imagevalues[index].ImagesList[childindex].StylecolorImagesList[imgindex].UploadedImagePath = "";
    setStyleAndColorValue(imagevalues);
    styleandcolorcallback(imagevalues)
  }
  function ViewFile(event, filename, index) {
    // event.preventDefault();
    setIsOpen(true);
    setImagename(filename);
  }


  function handleAddFields(index, childindex, imgindex, skuFrontPicPath) {
    var imagevalues = [...getStyleAndColorValue]
    let isvalid = true;
    if (imagevalues[index].ImagesList[childindex].StylecolorImagesList[imgindex].UploadedImagePath === "") {
      isvalid = false;
      Nodify('Warning!', 'warning', 'Please Upload file');
    }
    // if (getStyleAndColorValue.length <= 2)
    if (isvalid) {
      imagevalues[index].ImagesList[0].StylecolorImagesList.push({ UploadedImagePath: "", UploadedImageName: '', MainIndex: index, childindex: imgindex + 1 })
    }
    setStyleAndColorValue(imagevalues);
    styleandcolorcallback(imagevalues)
  }

  function handleRemoveFields(index, childindex, imgindex, length) {
    var imagevalues = [...getStyleAndColorValue]
    if (length === 1) {
      imagevalues[index].ImagesList[childindex].StylecolorImagesList[imgindex].UploadedImageName = "";
      imagevalues[index].ImagesList[childindex].StylecolorImagesList[imgindex].UploadedImagePath = "";
    } else {
      imagevalues[index].ImagesList[childindex].StylecolorImagesList.splice(imgindex, 1);
    }
    setStyleAndColorValue(imagevalues);
    styleandcolorcallback(imagevalues)
  }

  return (
    <table className="table table-striped table-bordered table-hover dataTable no-footer">
      <thead>
        <tr>
          <th className="InsMainInfoDetailsCol">Details</th>
          <th className="InsMainInfoResultsCol"> Style and Colors Result<span className="text-danger"> *</span></th>
          <th className="InsMainInfoRemarksCol">Finding / Remarks<span className="text-danger"> *</span></th>
        </tr>
      </thead>
      <tbody>
        {
          getStyleAndColorValue.map((comment, index) => (
            <Fragment>
              <tr key={
                comment.index
              }>
                <td title={comment.skuName} className="cut_text">
                  <i
                    className={
                      styleAndColorsExpandState[index] ?
                        'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                    } onClick={event => handleStyleAndColorsExpandRow(index)}
                  ></i>&nbsp;
                  <b> {comment.skuName}</b></td>
                <td>   <Reactselect
                  className="basic-single"

                  name="Result"
                  id={
                    "Result" + index
                  }
                  isDisabled={props.location.state.params.Action === 'View' ? true : false}
                  isLoading={false}
                  isClearable={true}
                  isSearchable={true}
                  onChange={(event) => handleCommonResultInputChange(index, event, 'InspectionResult')}
                  options={getCommonResult}
                  styles={submitted && comment.ResultID === 0 ? styles : ''}
                  value={getCommonResult.filter(function (option) {
                    return option.value === comment.ResultID;
                  })}

                ></Reactselect></td>
                <td><input type="text" className="form-control" name={index + "_txtRemarks"}
                  id={index + "_txtRemarks"}
                  value={comment.FindingsRemarks}
                  autoComplete="off"
                  maxLength="200"
                  disabled={props.location.state.params.Action === 'View' ? true : false}
                  style={{ border: submitted && (comment.ResultID === 2 && comment.FindingsRemarks === '') ? '1px solid red' : '' }}
                  onChange={(event) => handleCommonResultInputChange(index, event, 'FindingRemarks')} /></td>
              </tr><>
                {
                  styleAndColorsexpandedRows.includes(index) ?
                    <tr>
                      <td colspan="3">
                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                          <tbody>
                            {
                              comment.ImagesList.map((child, childindex) => (
                                <Fragment>
                                  <tr key={
                                    child.index
                                  }>

                                    {

                                      <td className="table-width-40">
                                        <td className="table-width-40">
                                          {<span>Expected : {child.skuPicPathposition}</span>}
                                          <br />
                                          <br />
                                          {
                                            <img src={window.$APIBaseURL + "Images" + child.skuFrontPicPath} alt="" width="150px" height="150px" />
                                          }
                                          &nbsp; &nbsp;
                                          <i className="btn btn-info fa fa-eye Packingeyeadjust" title="View Logo" style={{ marginRight: "6px" }}
                                            onClick={
                                              event => ViewFile(event, child.skuFrontPicPath, index)
                                            }></i>
                                        </td>
                                      </td>

                                    }
                                    {<td className="table-width-60">
                                      <div className="row">
                                        <div className="col-md-6">
                                          Found :
                                        </div>

                                      </div>
                                      <div className="row">
                                        {child.StylecolorImagesList.map((Images, imgindex) => (
                                          <div className="text-center col-lg-6">
                                            <span className="text-danger">Click on the image to upload new</span><br /><br />
                                            <div>
                                              <FinalinspecUpload UploadCallback={(e) => ImageCallback(e, index, childindex, imgindex)} PageName='Style/SKU' Buttonview={Buttonvisible} ShowDiv="1" filePath={Images.UploadedImagePath} Isnepctionfrom={props.location.state.params.Action} />
                                            </div>
                                            {Images.UploadedImagePath !== '' ? <div className="row">
                                              <div className="col-md-7">
                                                {/* <i className="btn btn-danger fa fa-trash-o FloatRight" title="Delete Logo" style={{ marginRight: "0px" }}
                                                    onClick={event => deleteFile(index, childindex, imgindex, "UploadedImagePath1")}></i> */}

                                              </div>
                                            </div> : ''}
                                            <span className="TnaFinaspecwrkmans" style={{ left: "120px" }}>
                                              {Images.UploadedImagePath !== '' ? <button style={{ padding: "5px 6px", width: "7%" }} onClick={
                                                event => ViewFile(event, Images.UploadedImagePath, index, childindex, imgindex)
                                              } className="btn btn-info fa fa-eye">
                                                <i title="View Logo" style={{ marginRight: "6px" }}
                                                ></i>
                                              </button> : ''}

                                              &nbsp;

                                              {props.location.state.params.Action !== 'View' ? <button type="button" style={{ display: getStyleAndColorValue[index].ImagesList[childindex].StylecolorImagesList.length < 2 ? '' : 'none' }} className="btn btn-xs btn-success" title="Add Style and Colors Image"
                                                onClick={() => handleAddFields(index, childindex, imgindex, child.skuFrontPicPath, 'UP1')}
                                              >
                                                <i className="fa fa-plus"></i>
                                              </button> : ''
                                              }
                                              &nbsp;
                                              <button type="button"
                                                style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }} className="btn btn-xs btn-danger" title="Delete packing Image"
                                                onClick={() => handleRemoveFields(index, childindex, imgindex, getStyleAndColorValue[index].ImagesList[childindex].StylecolorImagesList.length, 'UP1')}
                                              >
                                                <i className="fa fa-trash-o"></i>
                                              </button>
                                            </span>
                                          </div>
                                        ))}
                                      </div>


                                    </td>
                                    }
                                  </tr>
                                </Fragment>
                              ))
                            }
                          </tbody>
                        </table>
                      </td>
                    </tr> : ""
                }</>
              <div> {
                isOpen && (

                  <Lightbox mainSrc={window.$APIBaseURL + 'Images' + getImagename}
                    alt="bg image"
                    onCloseRequest={
                      () => setIsOpen(false)
                    } />
                )
              } </div>
            </Fragment>
          ))
        }
        {
          getStyleAndColorValue.length === 0 ?
            <tr>
              <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
            </tr> : ''
        }
      </tbody>
    </table >
  )
}

export default StyleAndColors;