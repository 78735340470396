import {
 ORDERPROTOFITSMS_LOADING,
 RETRIEVE_ORDERPROTOFITSMS,
 DELETE_ORDERPROTOFITSMS,
 RETRIEVE_ORDER_SAMPLES_DETAIL_VIEW_LIST,
 CLEAR_ORDER_SAMPLES_DETAIL_VIEW_SUB_ROW,
 RETRIEVE_ORDER_SAMPLES_REQUEST_VIEW_LIST,
 RETRIEVE_ORDER_SAMPLES_SUBMISSION_VIEW_LIST,
 RETRIEVE_ORDER_SAMPLES_APPROVAL_VIEW_LIST,

} from "./types";
import OrderService from "../services/Order/OrderService";


const orderprotofitLoading = (isStatus) => ({
 type: ORDERPROTOFITSMS_LOADING,
 payload: isStatus,
});


export const retrieveorderProtoFit = (IsProduction) => async (dispatch) => {
 try {
  dispatch(orderprotofitLoading(true));
  const res = await OrderService.GetOrderProtoFitList(IsProduction);
  dispatch({
   type: RETRIEVE_ORDERPROTOFITSMS,
   payload: res.data,
  });
  dispatch(orderprotofitLoading(false));
 } catch (err) {
  console.log(err);
  dispatch(orderprotofitLoading(false));
 }
};

export const getOrderSamplesDetailsViewList = (params) => async (dispatch) => {
 try {
  dispatch(orderprotofitLoading(true));
  const res = await OrderService.GetOrderSampleSubmissionList(params);
  dispatch({
   type: RETRIEVE_ORDER_SAMPLES_DETAIL_VIEW_LIST,
   payload: { data: res.data, params: params },
  });
  dispatch(orderprotofitLoading(false));
 } catch (err) {
  console.log(err);
  dispatch(orderprotofitLoading(false));
 }
};

export const getOrderSamplesRequestViewList = (params) => async (dispatch) => {
 try {
  dispatch(orderprotofitLoading(true));
  const res = await OrderService.GetOrderSampleSubmissionList(params);
  dispatch({
   type: RETRIEVE_ORDER_SAMPLES_REQUEST_VIEW_LIST,
   payload: { data: res.data, params: params },
  });
  dispatch(orderprotofitLoading(false));
 } catch (err) {
  console.log(err);
  dispatch(orderprotofitLoading(false));
 }
};

export const getReloadOrderSamplesRequestViewList = (params) => async (dispatch) => {
 try {
  dispatch(orderprotofitLoading(true));
  const res = await OrderService.GetOrderSampleSubmissionList(params);
  dispatch({
   type: RETRIEVE_ORDER_SAMPLES_REQUEST_VIEW_LIST,
   payload: { data: res.data, params: params },
  });
  dispatch(orderprotofitLoading(false));
 } catch (err) {
  console.log(err);
  dispatch(orderprotofitLoading(false));
 }
};

export const getOrderSamplesSubmissionViewList = (params) => async (dispatch) => {
 try {
  dispatch(orderprotofitLoading(true));
  const res = await OrderService.GetOrderSampleSubmissionList(params);
  dispatch({
   type: RETRIEVE_ORDER_SAMPLES_SUBMISSION_VIEW_LIST,
   payload: { data: res.data, params: params },
  });
  dispatch(orderprotofitLoading(false));
 } catch (err) {
  console.log(err);
  dispatch(orderprotofitLoading(false));
 }
};
export const getReloadOrderSamplesSubmissionViewList = (params) => async (dispatch) => {
 try {
  dispatch(orderprotofitLoading(true));
  const res = await OrderService.GetOrderSampleSubmissionList(params);
  dispatch({
   type: RETRIEVE_ORDER_SAMPLES_SUBMISSION_VIEW_LIST,
   payload: { data: res.data, params: params },
  });
  dispatch(orderprotofitLoading(false));
 } catch (err) {
  console.log(err);
  dispatch(orderprotofitLoading(false));
 }
};

export const getOrderSamplesApprovalViewList = (params) => async (dispatch) => {
 try {
  dispatch(orderprotofitLoading(true));
  const res = await OrderService.GetOrderSampleSubmissionList(params);
  dispatch({
   type: RETRIEVE_ORDER_SAMPLES_APPROVAL_VIEW_LIST,
   payload: { data: res.data, params: params },
  });
  dispatch(orderprotofitLoading(false));
 } catch (err) {
  console.log(err);
  dispatch(orderprotofitLoading(false));
 }
};

export const getReloadOrderSamplesApprovalViewList = (params) => async (dispatch) => {
 try {
  dispatch(orderprotofitLoading(true));
  const res = await OrderService.GetOrderSampleSubmissionList(params);
  dispatch({
   type: RETRIEVE_ORDER_SAMPLES_APPROVAL_VIEW_LIST,
   payload: { data: res.data, params: params },
  });
  dispatch(orderprotofitLoading(false));
 } catch (err) {
  console.log(err);
  dispatch(orderprotofitLoading(false));
 }
};









export const clearSamplesDetailsViewSubRow = (index) => async (dispatch) => {
 dispatch(orderprotofitLoading(true));
 dispatch({
  type: CLEAR_ORDER_SAMPLES_DETAIL_VIEW_SUB_ROW,
  payload: index,
 });
 dispatch(orderprotofitLoading(false));

};
