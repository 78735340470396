import React, { useState, useEffect, useMemo ,Fragment} from "react";
import Address from "../../../services/Master/BranchService";
import { TableHeader, Pagination, Search, PageCount } from "../../Datatable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import SweetAlertPopup from '../../Common/SweetAlertPopup';
import { BranchActionId } from "../../Common/PageActionNumber";
import { Redirect } from 'react-router-dom';
import Nodify from "../../Common/ReactNotification"
import SearchRetain from "../../Common/SearchRetain";
import CommonService from '../../../services/Common/CommonService';
import { retrieveBranch, deleteBranch } from "../../../actions/branch";
import { useDispatch, useSelector } from "react-redux";


const AddressType = (props) => {
    // const [Action, ActionData] = props.location.ActionData;

    // const [comments, setComments] = useState([]);

    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [getID, setID] = useState({ showPopupDelete: false, Params: {} });
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    const { user: currentUser } = useSelector((state) => state.auth);
    const [getPlaceholder] = useState("Branch");

    //setComments(useSelector(state => state.branch));
    const dispatch = useDispatch();

    const selector = useSelector((state) => state.branch);
    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.common.MenuList.item3);
    if (menulist !== undefined) { activeMenu = menulist.filter(i => i.menuName === 'Branch'); }
    // End Enable Menu

    const comments = selector.branches;
    let isLoadingBranche = selector.isLoadingBranche;
    const headers = [
        {
            name: "#",
            field: "",
            sortable: false
        },
        {
            name: "Branch",
            field: "addressTypeName",
            sortable: true
        }, {
            name: "Created Date",
            field: "createddate",
            sortable: true
        },


        {
            name: "Action",
            field: "",
            sortable: false,
            display: activeMenu && activeMenu[0].isEdit === 0 &&
                activeMenu[0].isDelete === 0 ? 'none' : ''

        },
    ];

    const commentsData = useMemo(() => {
        let computedComments = comments;

        if (search) {
            computedComments = computedComments.filter(comment => comment.addressTypeName.toLowerCase().includes(search.toLowerCase()));
        }
        setTotalItems(computedComments.length);

        // Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort((a, b) => {
              const valueA = a[sorting.field];
              const valueB = b[sorting.field];
            
              // Check if values are numeric
              const isNumericA = !isNaN(valueA);
              const isNumericB = !isNaN(valueB);
            
              if (isNumericA && isNumericB) {
                // If both values are numeric, compare as numbers
                return reversed * (parseFloat(valueA) - parseFloat(valueB));
              } else {
                // If one or both values are non-numeric, compare as strings
                return reversed * valueA.toString().localeCompare(valueB.toString());
              }
            });
              
      }
        // Current Page slice
        let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
        if (records.length === 0 && currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
        return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
    }, [
        comments,
        currentPage,
        search,
        sorting,
        ITEMS_PER_PAGE
    ]);

    useEffect(() => {
        dispatch(retrieveBranch());

        const state1 = props.location.state
        if (state1 !== undefined && state1 !== null) {
            let Msg;
            let Type = 'success'; // default, info, warning
            let title = 'Success!';
            if (state1.message === 'Edit') {
                Msg = 'Branch updated successfully.';
                // Type = 'default';
            } else if (state1.message === 'Add') {
                Msg = 'Branch added successfully.';
            } else {
                Msg = 'Error occured';
                Type = 'warning';
                title = 'Error!';
            }
            window.history.replaceState(null, '')
            Nodify(title, Type, Msg);
        }
        var page = getPlaceholder;
        var setConstraint = SearchRetain(page);
        if (setConstraint !== undefined) {
            setCurrentPage(setConstraint.CurrentPage);
            setITEMS_PER_PAGE(setConstraint.PageItems);
        }

    }, [dispatch])

    useEffect(() => {
        if (!isLoadingBranche) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingBranche])

    // useEffect(() => {
    //
    //     showLoader();
    //     setTimeout(() => {
    //         dispatch(retrieveBranch());
    //         // dispatch(retrieveBranch().then((response) => {
    //         //     if (response.data) {
    //         //         setComments(response.data);
    //                 // hideLoader();
    //                 const state1 = props.location.state
    //                 if (state1 !== undefined) {
    //                     let Msg;
    //                     let Type = 'success'; // default, info, warning
    //                     let title = 'Success!';
    //                     if (state1.message === 'Edit') {
    //                         Msg = 'Branch updated successfully.';
    //                         // Type = 'default';
    //                     } else if (state1.message === 'Add') {
    //                         Msg = 'Branch added successfully.';
    //                     } else {
    //                         Msg = 'Error occured';
    //                         Type = 'warning';
    //                         title = 'Error!';
    //                     }
    //                     window.history.replaceState(null, '')
    //                     Nodify(title, Type, Msg);
    //                 }
    //                 var page = getPlaceholder;
    //                 var setConstraint = SearchRetain(page);
    //                 if (setConstraint !== undefined) {
    //                     setCurrentPage(setConstraint.CurrentPage);
    //                     setITEMS_PER_PAGE(setConstraint.PageItems);
    //                 }
    //     }
    //             // else {
    //             //     //hideLoader();
    //             // }
    //         // }).catch(() => {
    //         //     // hideLoader();
    //         // }).finally(() => {
    //         //     hideLoader();
    //         // }));
    //    // }, 500)
    // }, []);

    const pageRedirect = (dataParams, redirect, page) => {
        localStorage.setItem('Pagination', JSON.stringify({ CurrentPage: currentPage, Page: page, PageItems: ITEMS_PER_PAGE }));
        if (redirect === 'Add') {
            props.history.push('/addBranch', { params: [] });
        } else if (redirect === 'Edit') {
            props.history.push(`/editBranch`, { params: dataParams });
        } else {
            let Params = {
                Operation: 1,
                Id: dataParams.addressTypeID,
            };
            CommonService.CheckMappedItem(Params).then(res => {
                if (res.data.outputResult === "-2") {
                    let Title = "Warning!";
                    let message = "This Branch is already mapped.";
                    let type = "warning";

                    Nodify(Title, type, message);
                }
                else {
                    setID({ showPopupDelete: true, Params: dataParams });
                }
            });
        }
    };

    if (!currentUser) {
        return <Redirect to="/login" />;
    }

    const Delcallback = (value) => {
        setID({ showPopupDelete: false, Params: [] });
    }


    // const Delcallback = (value) => {
    //     if (value === true) {
    //         Address.getAllAddressType().then((response) => {
    //             if (response.data) {
    //                 setComments(response.data);
    //             }
    //         });
    //     }
    //     setID({ showPopupDelete: false, Params: [] });
    // }

    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className="row">
                    <div className="col-xs-12 col-md-12">
                        <div className="widget">
                            <div className="widget-header ">
                                <span className="widget-caption">Branch List</span>
                            </div>
                            <div className="widget-body"
                                style={
                                    { paddingBottom: '4%' }
                                }>
                                <div id="simpledatatable_wrapper" className="dataTables_wrapper form-inline no-footer">
                                    <div className="table-toolbar pull-left">
                                        <Search onSearch={
                                            value => {
                                                setSearch(value);
                                                setCurrentPage(1);
                                            }
                                        }

                                            placeholder={getPlaceholder} props={props} />

                                    </div>

                                    <div className="table-toolbar pull-right" style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}>

                                        &nbsp;
                                        <span className="btn btn-primary" onClick={() => pageRedirect('', 'Add', getPlaceholder)} title="Add Branch">
                                            <i className="fa fa-plus"></i>&nbsp;Add
                                        </span>
                                    </div>


                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                        <TableHeader headers={headers}
                                            onSorting={
                                                (field, order) => setSorting({ field, order })
                                            } />
                                        <tbody>
                                            {
                                                commentsData.map((comment, index1) => (
                                                    <Fragment key={index1}>
                                                    <tr key={
                                                        comment.addressTypeID
                                                    }>
                                                        <td style={{width:'25px'}}> 
                                                            {index1+1}
                                                        </td>
                                                        <td>{
                                                            comment.addressTypeName
                                                        }</td>
                                                        <td>{
                                                            comment.createddate
                                                        }</td>
                                                        <td style={{
                                                            display: activeMenu && activeMenu[0].isEdit === 0 &&
                                                                activeMenu[0].isDelete === 0 ? 'none' : ''
                                                        }}>
                                                            <span style={{ display: activeMenu && activeMenu[0].isEdit === 1 ? '' : 'none' }} title='Edit Branch' onClick={() => pageRedirect(comment, 'Edit', getPlaceholder)}
                                                                className="btn btn-info btn-xs edit">
                                                                <i className="fa fa-edit"></i>
                                                            </span>
                                                            &nbsp;
                                                            <span style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }} title='Delete Branch' onClick={() => pageRedirect(comment, '', getPlaceholder)}
                                                                className="btn btn-danger btn-xs delete">
                                                                <i className="fa fa-trash-o"></i>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    </Fragment>
                                                ))

                                            }
                                            {
                                                totalItems === 0 ?
                                                    <tr>
                                                        <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
                                                    </tr> : ''
                                            }

                                        </tbody>
                                    </table>

                                    <PageCount onPageCount={
                                        value => {
                                            setITEMS_PER_PAGE(value);
                                            setCurrentPage(1);
                                        }
                                    } />
                                    <Pagination total={totalItems}
                                        itemsPerPage={
                                            parseInt(ITEMS_PER_PAGE)
                                        }
                                        currentPage={currentPage}
                                        onPageChange={
                                            page => setCurrentPage(page)
                                        } />
                                </div>
                            </div>

                            {
                                getID.showPopupDelete ? <SweetAlertPopup data={
                                    getID.Params
                                }
                                    deleteCallback={Delcallback}
                                    showpopup={true}
                                    pageActionId={BranchActionId}
                                    Msg={"Branch deleted successfully."} /> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
            {loader}
        </>
    );
};

export default AddressType;