import React, { useState, useEffect, useRef, Fragment } from "react";
import ReactNotification from 'react-notifications-component';
import Nodify from "../../../../Common/ReactNotification"
//import branchService from "../../../services/Master/BranchService";
import validator from 'validator'
import SearchRetain from "../../../../Common/SearchRetain";
import { useSelector } from "react-redux";
import reportTemplateService from "../../../../../services/Master/ReportTemplateService";
import ReactSelect from 'react-select';
import columnInformationService from "../../../../../services/Master/ColumnInformationService";
const AddReportTemplate = (props) => {

    const { user: currentUser } = useSelector((state) => state.auth);

    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    if (menulist !== undefined) {
        activeMenu = menulist.filter(i => i.menuName === "ReportTemplate");
    }
    //end enable menu
    const [getColumnInformation, setColumnInformation] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getReportTemplate, setReportTemplate] = useState([{ ReportTemplateName: '', Id: 0, ReportModuleCategory: '', ReturnReportCategoryID: '', Name: '', ReportModuleCatOperation: 0 }]);
    const [ExistingList, setExistingList] = useState([]);
    const [ButtonName, setButtonName] = useState("Save");
    const [HeaderName, setHeaderName] = useState("Add");
    const [getreportModuleCategoryList, setreportModuleCategoryList] = useState([]);
    function ReportTemplateListing(props) {

        let ReportTemplate = [...getReportTemplate];
        Array.from(document.querySelectorAll("input")).forEach(input => (input.value = ""));
        if (props.location.state.params.length !== 0) {
            if (props.location.state.params.Id !== "") {
                setButtonName("Update");
                setHeaderName("Edit");
                ReportTemplate.Id = props.location.state.params.reportTemplateID;
                ReportTemplate.Name = validator.trim(props.location.state.params.reportTemplateName);
                ReportTemplate.Operation = 2;
                return ReportTemplate;
            }
        } else {
            return ReportTemplate;
        }
    }
    const values = [...getReportTemplate];
    useEffect(() => {

        let getReportTemplateList = ReportTemplateListing(props);
        setReportTemplate(getReportTemplateList)
        reportTemplateService.getReportTemplateList().then((response) => {
            if (response.data) {
                setExistingList(response.data);
            }
        });
    }, []);

    useEffect(() => {
        ;
        reportTemplateService.loadReportModuleCategoryList()
            .then((response) => {
                if (response.data) {
                    setreportModuleCategoryList(response.data);
                }
            })
            .catch((error) => {
                console.error("Error loading report module category list:", error);
            });
    }, []);

    //Enter Error msg
    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                //SaveBuyerInfo(event);
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);


    const handleInputChange = (index, event) => {
        const values = [...getReportTemplate];
        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }
        values[index].ReportTemplateName = inputText;
        setReportTemplate(values);
    };


    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/ReportTemplateList');
        } else {
            window.location.reload();
        }
    }

    const CheckDuplicate = (index) => {

        const values = [...getReportTemplate];
        if (ExistingList) {
            for (var i = 0; i < ExistingList.length; i++) {
                if (ExistingList[i].reportTemplateName.trim().toLowerCase() === values[index].ReportTemplateName.trim().toLowerCase()) {
                    values[index].ReportTemplateName = '';
                    setReportTemplate(values);
                    Nodify('Warning!', 'warning', "This Report Template Name already exist.");
                    return false;
                }
            }
        }

        for (var i = 0; i < (getReportTemplate.length); i++) {
            if (i !== index) {
                if (getReportTemplate[i].ReportTemplateName.trim().toLowerCase() === getReportTemplate[index].ReportTemplateName.trim().toLowerCase()) {
                    values[index].ReportTemplateName = "";
                    setReportTemplate(values);
                    Nodify('Warning!', 'warning', 'This Report Template Name already exist.');
                    return false;
                }
            }
        }
    }

    const checkConditions = (selectedOptions) => {
        const labels = new Set(selectedOptions.map(option => option.label));

        // Define your condition checks
        if (labels.has('TNA') && labels.size === 1) {
            return 1;
        } else if (labels.has('TNA') && labels.has('TNA-Parent') && labels.size === 2) {
            return 2;
        } else if (labels.has('TNA') && labels.has('TNA-Parent') && labels.has('TNA-Childtask') && labels.size === 3) {
            return 3;
        } else if (labels.has('Style') && labels.size === 1) {
            return 1;
        } else if (labels.has('Style') && labels.has('Style-Fabric') && labels.size === 2) {
            return 2;
        }

        return 0; // Default case if no condition is met
    };

    const handleDrpDownChange = async (selectedOptions) => {
        debugger;

        // Check if selectedOptions is an array or single object
        const isArray = Array.isArray(selectedOptions);
        const checkopt = checkConditions(selectedOptions)
        // Update ReportModuleCategory and ReturnReportCategoryID
        if (isArray) {
            values[0].ReturnReportCategoryID = selectedOptions;
            const strValue = selectedOptions.map(option => option.value).join(',');
            values[0].ReportModuleCategory = strValue;
            values[0].ReportModuleCatOperation = checkopt;
        } else {
            values[0].ReturnReportCategoryID = [selectedOptions];
            values[0].ReportModuleCategory = selectedOptions.value;
        }

        // Determine values to fetch
        const valuesToFetch = isArray ? selectedOptions.map(option => option.value) : [selectedOptions.value];

        try {
            // Fetch data for each selected value
            const fetchPromises = valuesToFetch.map(value => columnInformationService.getColumnInformationList(2, value));
            const valuesToFetchLable = isArray ? selectedOptions.map(option => option.label) : [selectedOptions.label];
            // Await all fetch operations
            const results = await Promise.all(fetchPromises);

            // Create a map to store results separately
            const resultsMap = {};
            valuesToFetchLable.forEach((value, index) => {
                const result = results[index];
                if (result.data.outputColumnInformationList) {
                    resultsMap[value] = result.data.outputColumnInformationList.map(x => ({
                        ReportTempDetailinfoID: 0,
                        Id: x.columnInformationID,
                        TableName: x.tableName,
                        ColumnName: x.columnName,
                        DisplayName: x.displayName,
                        ModuleName: x.moduleName,
                        ModuleID: x.moduleID,
                        IsChecked: 0
                    }));
                } else {
                    resultsMap[value] = [];
                }
            });

            // Update state with the map of results
            setColumnInformation(resultsMap);

        } catch (error) {
            console.error('Error fetching column information:', error);
            // Optionally handle error state
            setColumnInformation({});
        }
    };

    const filterRecordsCheckedInAllCategories = (getColumnInformation) => {
        // Filtered records object to store selected records per category
        const filteredRecords = {};

        // Iterate over each category
        Object.keys(getColumnInformation).forEach(category => {
            const records = getColumnInformation[category] || [];
            // Filter records where IsChecked is 1
            filteredRecords[category] = records.filter(record => record.IsChecked === 1 || record.IsChecked === 0);
        });

        return filteredRecords;
    };

    const SaveReportTemplateList = (e) => {
        debugger;
        setButtonLoader(true);
        e.preventDefault();

        if (getReportTemplate.length === 1 && getReportTemplate[0].ReportTemplateName === '') {
            Nodify('Warning!', 'warning', 'Report Template name is required');
            setSubmitted(true);
            setButtonLoader(false);
            return false;
        }
        else if (getReportTemplate.length === 1 && getReportTemplate[0].ReportModuleCategory === '') {
            Nodify('Warning!', 'warning', 'Report Module Category is required');
            setSubmitted(true);
            setButtonLoader(false);
            return false;
        }
        const isAnyCategoryChecked = Object.keys(getColumnInformation).every(category => {
            const records = getColumnInformation[category] || [];
            return records.some(record => record.IsChecked === 1);
        });

        if (!isAnyCategoryChecked) {
            Nodify('Warning!', 'warning', 'At least one checkbox must be checked in each category.');
            setButtonLoader(false);
            return false;
        }
        const filteredColumnInformation = filterRecordsCheckedInAllCategories(getColumnInformation);

        let Reportinfo = [];
        Object.values(filteredColumnInformation).forEach(categoryArray => {
            categoryArray.forEach((x) => {
                Reportinfo.push({
                    ReportTempDetailinfoID: x.ReportTempDetailinfoID,
                    ReportTempDetail: x.DisplayName,
                    ReportModuleName: x.ModuleName,
                    IsChecked: x.IsChecked,
                    ReportModuleID: x.ModuleID,
                    ColumnName: x.ColumnName,
                    TableName: x.TableName
                });
            });
        });

        let InputCommonReportMaster = {
            ReportTempDetailinfoID: 0,
            Createdby: currentUser.employeeinformationID,
            MasterInformation: getReportTemplate, MasterReportInformation: Reportinfo
        };
        reportTemplateService.InsertUpdateReportTemplateGrid(InputCommonReportMaster).then(res => {
            var page = "Remove";
            SearchRetain(page);
            let Func = 'Add';
            if (res.data.outputResult === "1") {
                Func = 'Add';
                PageRedirect(Func);
            } else if (res.data.outputResult === "2") {
                Func = 'Edit';
                PageRedirect(Func);
            } else if (res.data.outputResult === "-2") {
                setButtonLoader(false);
                ValidationPopup("This Report Module Category already exists.");
            } else if (res.data.outputResult === "0") {
                setButtonLoader(false);
                ValidationPopup("Error Occurred!");
            }
        });
    };

    let styles = {
        control: styles => ({ ...styles, border: '1px solid red', innerHeight: '100px', outerHeight: '100px' }),
    }
    const PageRedirect = (Func) => {
        props.history.push({ pathname: "/ReportTemplateList", state: { message: Func } });
    }

    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
        return false;
    }
    const handleCheckboxChange = (key, columnId) => {
        debugger
        setColumnInformation(prevState => {
            // Create a new copy of the state
            const updatedState = { ...prevState };

            // Ensure the key exists in the state
            if (!updatedState[key]) return updatedState;

            // Find the column to update
            const updatedColumns = updatedState[key].map(column => {
                if (column.Id === columnId) {
                    // Toggle the IsChecked state
                    return { ...column, IsChecked: column.IsChecked === 1 ? 0 : 1 };
                }
                return column;
            });

            // Update the state with the modified columns
            updatedState[key] = updatedColumns;
            return updatedState;
        });
    };

    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">{HeaderName} Report Template</h3>
                            <div className="buttons-preview" align="right">
                                <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                    <i className="fa fa-arrow-circle-left"></i>
                                    &nbsp;Back
                                </span>
                                <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                    <i className="fa fa-times"></i>
                                    &nbsp;Reset
                                </span>
                                <button type="button" className="btn btn-success" disabled={buttonLoader}
                                    onClick={SaveReportTemplateList}
                                >
                                    <i className="fa fa-check right"></i>&nbsp;
                                    {ButtonName}
                                </button>
                            </div>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <form>
                                    {
                                        Array.isArray(getReportTemplate) && getReportTemplate.map((inputField, index) => (
                                            <Fragment key={`${inputField}~${index}`}>
                                                <div className="row row_left10">
                                                    <div className="col-sm-3 divder_style">
                                                        <div className="form-group">
                                                            <label htmlFor="ReportTemplateName">Report Template Name<span className="text-danger">*</span></label>
                                                            <span className="input-icon icon-right">
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"ReportTemplateName_" + index}
                                                                    name="ReportTemplateName"
                                                                    placeholder="Enter ReportTemplate Name"
                                                                    maxLength="50"
                                                                    value={inputField.ReportTemplateName}
                                                                    onChange={event => handleInputChange(index, event)}
                                                                    onBlur={() => CheckDuplicate(index)}
                                                                    style={{ border: submitted && !inputField.ReportTemplateName ? '1px solid red' : '' }}
                                                                    autoFocus
                                                                />
                                                            </span>

                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <div className='form-group'>
                                                            <label htmlFor="ReportModuleCategoryName">Report Module Category<span className="text-danger">*</span></label>
                                                            <span className='input-icon icon-right'>
                                                                <ReactSelect
                                                                    className="basic-single"
                                                                    name="ReportModuleCategory"
                                                                    id="ReportModuleCategory"
                                                                    isDisabled={false}
                                                                    isLoading={false}
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    isMulti={true}
                                                                    value={values[0].ReturnReportCategoryID}
                                                                    onChange={handleDrpDownChange}
                                                                    options={getreportModuleCategoryList}
                                                                    styles={submitted && inputField.ReturnReportCategoryID.length === 0 ? styles : ''}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </Fragment>
                                        ))
                                    }

                                    {getReportTemplate[0].ReturnReportCategoryID !== '' ? <div className="row" style={{ padding: '14px 14px 14px 14px' }}>
                                        <div className="col-md-6">
                                            {Object.keys(getColumnInformation).map((key, index) => (
                                                <div className="panel-group accordion" id={`${key}`}>
                                                    <div className="panel panel-default" key={index}>
                                                        <div className="panel-heading">
                                                            <h4 className="panel-title">
                                                                <a
                                                                    className="accordion-toggle collapsed clr-themegreen"
                                                                    data-toggle="collapse"
                                                                    data-parent={`${key}`}
                                                                    href={`#collapse_${key}`}
                                                                >
                                                                    {`Details for ${key}`}
                                                                </a>
                                                            </h4>
                                                        </div>
                                                        <div id={`collapse_${key}`} className="panel-collapse collapse">
                                                            <div className="panel-body border-red">
                                                                <div className="col-md-12">
                                                                    <div className="row">
                                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th rowspan="2" className="fixed-width" style={{ width: '40px' }}>#</th>
                                                                                    <th rowspan="2" className="fixed-width-th" style={{ width: '500px' }}>Details</th>
                                                                                    <th rowspan="2" className="fixed-width-th" style={{ width: '500px' }}>Checked</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {getColumnInformation[key].length > 0 ? getColumnInformation[key].map((x, rowIndex) => (
                                                                                    <tr key={x.Id}>
                                                                                        <td>{rowIndex + 1}</td>
                                                                                        <td>{x.DisplayName}</td>
                                                                                        <td className="Actionbutton">
                                                                                            <div style={{ width: "80px" }}>
                                                                                                <label style={{ marginTop: '8px' }}>
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="IsSelected"
                                                                                                        className="colored-blue clearOpacity"
                                                                                                        checked={x.IsChecked === 1}
                                                                                                        onChange={() => handleCheckboxChange(key, x.Id)}
                                                                                                    />
                                                                                                    <span className="text"></span>
                                                                                                </label>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                )) : (
                                                                                    <tr>
                                                                                        <td colSpan="3" className='norecordfound'>
                                                                                            <span>No Records Found</span>
                                                                                        </td>
                                                                                    </tr>
                                                                                )}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div> : ''}

                                    <div className="col-sm-12">
                                        <div className="buttons-preview" align="right">
                                            <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                                <i className="fa fa-arrow-circle-left"></i>
                                                &nbsp;Back
                                            </span>
                                            <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                                <i className="fa fa-times"></i>
                                                &nbsp;Reset
                                            </span>
                                            <button type="button" className="btn btn-success" disabled={buttonLoader}
                                                onClick={SaveReportTemplateList}
                                            >
                                                <i className="fa fa-check right"></i>&nbsp;
                                                {ButtonName}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddReportTemplate;
