import {
    AQLFINALINSPECTION_LOADING,
    RETRIEVE_AQLFINALINSPECTION,
    DELETE_AQLFINALINSPECTION
} from "../actions/types";

const initialState = {

    isLoadingAQL: true,
    aqlinpsectionList: [],
};

const AqlInspectionReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case AQLFINALINSPECTION_LOADING:
            return {
                ...state,
                isLoadingAQL: payload
            };
        case RETRIEVE_AQLFINALINSPECTION:
           ;
            return { ...state, aqlinpsectionList: payload };


        default:
            return state;
    }

}

export default AqlInspectionReducer;