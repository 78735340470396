import React, { useState, useEffect, useRef, Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import Lightbox from "react-image-lightbox";
import { GetGeneralTrimSubmissionApprovalGridList, clearTrimSubmissionSubRow, getGenTrimTNASubmissionAndApproval, UpdateGenralTrimTNA, GetGeneralTrimApprovalList } from "../../../../actions/generalTrim";
import { loadSupplierList } from "../../../../actions/tna";
import CommonService from "../../../../services/Common/CommonService";
import Nodify from "../../../Common/ReactNotification"
import SweetAlertPopup from '../../../Common/SweetAlertPopup';
import { GenTrimRequestlId } from "../../../Common/PageActionNumber";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Reactselect from 'react-select';
import { Modal, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import GeneralService from "../../../../services/General/GeneralService";
import input from "react-validation/build/input";
import $, { get } from "jquery";
import axios from "axios";
import { TableHeader, Pagination, Search, PageCount } from "../../../Datatable";
import { loadRouteDependencyList } from "../../../../actions/route";
import taskService from "../../../../services/Master/TaskService";
import { DateRangePicker } from 'react-date-range';
import moment from "moment";
import { loadTaskHolderList } from "../../../../actions/task";
import { loadGLDTNATaskOwnerList } from "../../../../actions/generalLabdip";


function TrimViewRequestTab(props) {
    //declaration
    const dispatch = useDispatch();
    const [loader, showLoader, hideLoader] = useFullPageLoader();

    //reducer
    const { user: currentUser } = useSelector((state) => state.auth);
    const TrimSubmissionList = useSelector((state) => state.generalTrim.generalTrimSubmissionList);

    let isLoadingGeneralTrim = useSelector((state) => state.generalTrim.isLoadingGeneralTrim);
    const getSupplierList = useSelector((state) => state.tna.drpSupplierList);
    const editTNATaskTrimSubmissionList = useSelector((state) => state.generalTrim.editTNATaskTrimSubmissionList);
    // const StaticTask = useSelector((state) => state.tna.StaticTask);

    const getDependencyList = useSelector((state) => state.route.routeDependencyList);
    const taskHolderList = useSelector((state) => state.task.drpTaskHolderList);
    let GLDRequestStatusList = useSelector((state) => state.generalLabdip.GLDRequestStatusList);
    const drpGLDTNATaskOwnerList = useSelector((state) => state.generalLabdip.drpGLDTNATaskOwnerList);
    const genTrimTNAHolidayList = useSelector((state) => state.generalLabdip.genTrimTNAHolidayList);


    //state
    const [getID, setID] = useState(0);
    const [getTaskHolderList, setTaskHolderList] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [currentExpand, setcurrentExpand] = useState({ currindex: "", currTrimId: "", currColorGroupID: "" });
    const [getComment, setComment] = useState(false);
    const [selectall, setselectall] = useState(false);
    const [showSOManual, setShowSOManual] = useState(false);
    const [showSubmission, setshowSubmission] = useState(false);
    const [ManualInfo, setManualInfo] = useState([{ IdList: '', manualCloseDate: '', manualCloseComment: '', operation: 0 }]);
    const [getSearch, setSearch] = useState("");
    const [showMultiClosediv, setshowMultiClosediv] = useState();
    const [showSubmissiondiv, setshowSubmissiondiv] = useState();
    const [getOrderinfo, setOrderinfo] = useState([]);
    const [submittedoption, setsubmittedoption] = useState([]);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getSubImageList, setSubImageList] = useState([]);
    const [getCurrimageindex, setCurrimageindex] = useState(0);
    const [submittedawb, setSubmittedawb] = useState(false);
    const [OrderInfoMulticloseMainDetails, setOrderInfoMulticloseMainDetails] = useState([]);
    const [OrderInfoMainDetails, setOrderInfoMainDetails] = useState([]);
    const [expandedRowsMultiSelect, setExpandedRowsMultiSelect] = useState([]);
    const [expandListMultiSelect, setexpandListMultiSelect] = useState({});
    const [expandStateMultiSelect, setExpandStateMultiSelect] = useState({});
    const [TrimDimension, setTrimDimension] = useState([]);
    const [expandState, setExpandState] = useState({});
    const [expandedRows, setExpandedRows] = useState([]);
    const [expandList, setexpandList] = useState({});
    const [getEditTNASupplierId, setEditTNASupplierId] = useState(0);
    const [getHeader, setHeader] = useState("Add");

    const [MainFields, setMainFields] = useState({
        GenTrimSupplierTNAId: 0,
        GenTrimDetailTNAId: 0,
        GenTrimTNASubmissionAndApproval: [],
        GenTNAAdditionalTaskDetails: [],
        CreatedBy: currentUser.employeeinformationID,
    });
    const [tab, setTab] = useState(0);
    const MainValues = { ...MainFields };

    const [inputFields, setInputFields] = useState([{
        BuyerBrandSeasonName: '',
        StoryName: '',
        ThemeName: '',
        Supplier: '',
        SubmittedDate: '',
        SendTo: "", SendToEdit: '',
        SendThrough: 0,
        EndCustomer: "",
        AWBName: "", AWBDate: "", AWBDateandNo: "",
        TrimId: 0, TrimCode: 0, TrimName: "", TechnicalSpecification: "",
        TrimDimensionId: 0, SwatchCardImageName: "", SwatchCardIamgePath: "", Remarks: "", CreatedBy: 0,
        SubmitStatus: '',
        GenralTrimTNASubmissionAndApprovalId: 0,
        GeneralTNATrimSubmittedOption: [],
        ColorGroupId: 0
    }]);
    const [submittedpopup, setSubmittedpopup] = useState(false);
    const values = [...inputFields]


    const [showawb, setshowawb] = useState(false);
    const [getTaskholder, setTaskholder] = useState([]);
    const [ShowPopup, setShowPopup] = useState(false);
    const [FabricDetails, setFabricDetails] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [getImagename, setImagename] = useState();
    const [getheader, setheader] = useState();
    var ImgClassName = "upload-btn-wrapper2"
    const [inputTaskFields, setInputTaskFields] = useState([]);
    const [isTaskFieldChange, setIsTaskFieldChange] = useState(0);
    const [getCalculation, setCalculation] = useState({
        isLoad: 0, isDuration: false, isEndDate: false
    });
    const [showTaskPopup, setShowTaskPopup] = useState(false);
    const [getLeaveDaysList, setLeaveDaysList] = useState([]);


    const [getSearchReqValues, setSearchReqValues] = useState({
        StatusId: 0,
        SupplierId: 0,
        SearchTxt: "",
        stateDate: [
            {
                startDate: null,
                endDate: new Date(""),
                key: 'selection',
                isShow: false
            }
        ]

    });

    let activeMenu;
    let activeMenuSub;
    let TrimRequestTab;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
    if (menulist !== undefined && menulistSub.length > 0) {
        activeMenu = menulist.filter(i => i.menuName === 'TrimList');
        if (activeMenu.length > 0) {
            activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);
            ;

            if (activeMenuSub.length > 0) {
                ;
                for (let index = 0; index < activeMenuSub.length; index++) {
                    if (activeMenuSub[index].menuName === "GeneralTrimRequest") {
                        TrimRequestTab = activeMenuSub[index];
                    }
                }
            }
        }
    }

    // If check production or sampling
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
    let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal : activeMenus.IsProduction;
    // End






    const SearchReqValues = { ...getSearchReqValues };
    const valuesTask = [...inputTaskFields]
    const CalculationValue = { ...getCalculation };


    const [TaskDetails, setTaskDetails] = useState([]);
    const [ReviceComment, setReviceComment] = useState({ SubmissionComment: '', ApprovalComment: '' });
    const [getSubReviceList, setSubReviceList] = useState([]);
    const [Submitrevice, setSubmitRevice] = useState(false);
    const [openRevicepopup, setopenRevicepopup] = useState(false);

    const [getSubRescheduleList, setSubRescheduleList] = useState([]);
    const [RescheduleComment, setRescheduleComment] = useState({ SubmissionComment: '', ApprovalComment: '' });
    const [SubmitReschedule, setSubmitReschedule] = useState(false);
    const [openReschedulepopup, setopenReschedulepopup] = useState(false);

    const headers = [
        { name: "Story", field: "Story", sortable: false },
        { name: "Theme", field: "Theme", sortable: false },
        { name: "Details", field: "Details", sortable: false },
        { name: "Task Holder / Follower", field: "", sortable: false },
        { name: "Action", field: "", sortable: false },
    ];

    const [Searchvalues, setSearchvalues] = useState({
        SupplierId: 0, SubmitStatus: 0
    });
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),
    }

    let BuyerId = 0;
    let BrandId = 0;
    let SeasonId = 0;
    //get props params
    if (props.props.location.state !== undefined) {

        BuyerId = props.props.location.state.params.buyerId === undefined ? 0 : props.props.location.state.params.buyerId;
        BrandId = props.props.location.state.params.brandId === undefined ? 0 : props.props.location.state.params.brandId;
        SeasonId = props.props.location.state.params.seasonId === undefined ? 0 : props.props.location.state.params.seasonId;

    }
    const [MultiCloseDatas, setMultiCloseDatas] = useState([]);
    useEffect(() => {
        if (!isLoadingGeneralTrim) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingGeneralTrim])

    useEffect(() => {


        dispatch(loadSupplierList());
        dispatch(loadTaskHolderList());
        dispatch(loadRouteDependencyList());
        taskService.getLoadLeaveDaysList().then((response) => {
            if (response.data) {
                setLeaveDaysList(response.data);
            }
        });
        searchTrim();
    }, [])


    //#region  ------------------  search details --------------------------------
    const searchTrim = () => {
        SearchReqValues.stateDate[0].isShow = false;
        setSearchReqValues(SearchReqValues);

        let StartDate = null;
        let EndDate = null;
        if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
        }
        if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
        }

        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, TrimId: 0, ColorGroupID: 0,
            Index: currentExpand.currindex, SearchText: SearchReqValues.SearchTxt, Operation: 1,
            StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
            IsProduction: IsProduction
        };
        dispatch(GetGeneralTrimSubmissionApprovalGridList(params));

        // let params = {
        //     BuyerId: TrimViewDetailParams.BuyerId, BrandId: TrimViewDetailParams.BrandId, SeasonId: TrimViewDetailParams.SeasonId,
        //     BuyerName: TrimViewDetailParams.BuyerName, BrandName: TrimViewDetailParams.BrandName, SeasonName: TrimViewDetailParams.SeasonName,
        //     PantoneId: 0, Index: 0, SearchText: SearchReqValues.SearchTxt, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, StartDate: StartDate, EndDate: EndDate
        // };
        // dispatch(getGeneralTrimDetailsViewList(params));
        commonParentExpand();
    }

    const resetTrim = () => {
        SearchReqValues.stateDate[0].startDate = null;
        SearchReqValues.stateDate[0].endDate = new Date("");
        SearchReqValues.stateDate[0].key = 'selection';
        SearchReqValues.stateDate[0].isShow = false;

        SearchReqValues.StatusId = 0;
        SearchReqValues.SupplierId = 0;
        SearchReqValues.SearchTxt = "";
        setSearchReqValues(SearchReqValues);
        searchTrim();

    }


    const clearDateSelect = () => {
        SearchReqValues.stateDate[0].startDate = null;
        SearchReqValues.stateDate[0].endDate = new Date("");
        SearchReqValues.stateDate[0].key = 'selection';
        SearchReqValues.stateDate[0].isShow = false;

        setSearchReqValues(SearchReqValues);
    }
    const handleDateSelect = (event, isShow) => {

        SearchReqValues.stateDate[0].isShow = isShow;
        setSearchReqValues(SearchReqValues);
    }

    const handlePopupDateselection = (item) => {

        SearchReqValues.stateDate = [item.selection];
        setSearchReqValues(SearchReqValues);
    }

    const handleSearchChange = (e, FieldName) => {

        let inputText = '';
        if (FieldName === 'SearchTxt') {
            if (e && e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
        } else if (FieldName === 'Status' || FieldName === 'SupplierId') {
            if (e) {
                inputText = e.value
            }
        }

        if (FieldName === 'Status') {
            SearchReqValues.StatusId = inputText;
        }
        else if (FieldName === 'SupplierId') {
            SearchReqValues.SupplierId = inputText;
        } else if (FieldName === 'SearchTxt') {
            SearchReqValues.SearchTxt = inputText;
        }
        setSearchReqValues(SearchReqValues);

    }

    // const ViewStatus = (TrimProgramInfoId, TrimId) => {
    //     let params = {
    //         TrimProgramInfoId: TrimProgramInfoId, TrimId: TrimId
    //     };
    //     dispatch(getGenTrimTNAProgramStatusList(params))
    //         .then(data => {
    //             if (data && data.length > 0) {
    //                 setViewStatusList(data);
    //                 setShowViewStatusPopup(true);
    //             } else {
    //                 Nodify('Warning', 'warning', 'No Records Found');
    //                 setViewStatusList([]);

    //             }
    //         })
    //         .catch(e => {
    //             console.log(e);
    //             hideLoader();
    //         });
    // }

    // const handleViewStatusPopupClose = () => {
    //     setShowViewStatusPopup(false);
    // }

    //#end  ------------------  search details end --------------------------------



    // #region  ------------------  Expanded Toggle --------------------------------

    const handleToggle = (index, TrimId, ColorGroupID, isExpandedChild) => {

        if (isExpandedChild) {
            //Child row only reload data
            let StartDate = null;
            let EndDate = null;
            if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
                StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
            }
            if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
                EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
            }
            let params = {
                BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, TrimId: TrimId, ColorGroupID: ColorGroupID, Index: index,
                SearchText: SearchReqValues.SearchTxt, Operation: 1, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
                IsProduction: IsProduction
            };
            setcurrentExpand({ currindex: index, currTrimId: TrimId, currColorGroupID: ColorGroupID })
            dispatch(GetGeneralTrimSubmissionApprovalGridList(params));

        } else {

            let btnToggle = document.getElementById("btn-toggle1_" + index);
            let iconToggle = document.getElementById("icon-toggle1_" + index);
            let ariaExpanded = btnToggle.getAttribute('aria-expanded');
            if (ariaExpanded === 'false') {
                btnToggle.setAttribute('aria-expanded', 'true');
                iconToggle.className = iconToggle.className.replace("fa-plus-square-o", "fa-minus-square-o");
                let StartDate = null;
                let EndDate = null;
                if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
                    StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
                }
                if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
                    EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
                }
                let params = {
                    BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, TrimId: TrimId, ColorGroupID: ColorGroupID, Index: index,
                    SearchText: SearchReqValues.SearchTxt, Operation: 1, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
                    IsProduction: IsProduction
                };
                setcurrentExpand({ currindex: index, currTrimId: TrimId, currColorGroupID: ColorGroupID })
                dispatch(GetGeneralTrimSubmissionApprovalGridList(params));
            }
            else {

                btnToggle.setAttribute('aria-expanded', 'false');
                iconToggle.className = iconToggle.className.replace("fa-minus-square-o", "fa-plus-square-o");
                if (TrimId) {
                    dispatch(clearTrimSubmissionSubRow(index));

                }
            }
        }
    };
    const commonParentExpand = () => {
        let rowHiddens = document.querySelectorAll(".parentExpandRequestTNA");
        for (let row of rowHiddens) {
            row.setAttribute('aria-expanded', 'false');
        }
        let rowIconHiddens = document.querySelectorAll(".clscollapseexpandRequestTNA");
        for (let rowIcon of rowIconHiddens) {
            rowIcon.className = rowIcon.className.replace("fa-minus-square-o", "fa-plus-square-o");
        }
    }



    const Delcallback = (value) => {
        searchTrim();
        // let btnToggle = document.getElementById("btn-toggle_" + currentExpand.currindex);
        // let iconToggle = document.getElementById("icon-toggle_" + currentExpand.currindex);
        // btnToggle.setAttribute('aria-expanded', 'false');
        // iconToggle.className = iconToggle.className.replace("fa-minus-square-o", "fa-plus-square-o");

        setID({ showPopupDelete: false, Params: [] });
    }

    const ReloadSubmission = () => {
        let StartDate = null;
        let EndDate = null;
        if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
        }
        if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
        }
        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, TrimId: 0, ColorGroupID: 0, Index: "",
            // SearchText: (getSearch && getSearch.trim()), Operation: 2
            SearchText: SearchReqValues.SearchTxt, Operation: 2,
            StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
            SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
            IsProduction: IsProduction
        };
        let reqparams = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, TrimId: 0, ColorGroupID: 0, Index: "",
            // SearchText: (getSearch && getSearch.trim()), Operation: 2
            SearchText: SearchReqValues.SearchTxt, Operation: 1,
            StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
            SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
            IsProduction: IsProduction
        };

        dispatch(GetGeneralTrimApprovalList(params));
        dispatch(GetGeneralTrimSubmissionApprovalGridList(reqparams));

    }
    const [stateDate, setStateDate] = useState([
        {
            startDate: null,
            endDate: null,
            key: 'selection',
            isShow: false
        }
    ]);

    function convertUTCDateToLocalDate(date) {
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date;
    }
    const handlechange = (e, field) => {
        let getStateDate = [...stateDate];
        getStateDate[0].isShow = false;
        setStateDate(getStateDate);

        if (e === null) {
            Searchvalues[field] = 0;
        }
        else {
            if (e.length === 0) {
                Searchvalues[field] = 0;
            }
            else {
                Searchvalues[field] = e.value;
            }
        }
        setSearchvalues(Searchvalues);
    }


    const Filter = () => {

    }
    const handleCloseManualPopup = () => {
        setShowSOManual(false);
    }
    const handleManualclose = (Id, SubmitStatus, BuyerId, BrandId, SeasonId, SupplierId, Follower, TaskId, trimId,
        GenTrimLogoID, GenTrimId, index) => {
        showLoader();
        let Operation;
        setComment(false);
        if (SubmitStatus === "Pending") {
            Operation = 1;
            setheader('Manual Close');
        }
        else {
            Operation = 3;
            setheader('View Manual Close');
        }
        setShowSOManual(true);
        var Sub = TrimSubmissionList[index].trimSubmitApprovalReferenceList.filter(x => x.genTrimTNASubmissionAndApprovalId === Id);
        GeneralService.GetGeneralTrimOrderInfoList(Id, BuyerId, BrandId, SeasonId, SupplierId, Follower,
            TaskId, trimId, GenTrimLogoID, GenTrimId, Operation, 0).then((response) => {
                if (response.data) {
                    let manindex = 1;
                    let data = response.data.trimSubmitApprovalReferenceList.map((item) => {
                        item.index = manindex;
                        manindex = manindex + 1;

                        if (item.genTrimTNASubmissionAndApprovalId === Id) {
                            item.isSelected = 1;
                            return item;
                        } else {
                            return item;
                        }
                    });

                    setManualInfo(data);
                    setMultiCloseDatas(data);
                    let maindata = [...new Map(response.data.trimSubmitApprovalReferenceList.map(item =>
                        [item['trimLogoId'], item])).values()];


                    setOrderInfoMulticloseMainDetails(maindata);
                    DefaultExpandRowMultiSelect(Sub[0].trimLogoPath, response.data.trimSubmitApprovalReferenceList);
                    hideLoader();

                } else {
                    hideLoader();
                }
            }).catch(() => { hideLoader(); });


    }
    const OpenTrimSubmssionpopup = (Id, SubmitStatus, BuyerId, BrandId, SeasonId, SupplierId, Follower, TaskId,
        trimId, GenTrimLogoID, GenTrimId, GenTrimMainId, index) => {
        setTab(0);
        showLoader();
        setSubmittedpopup(false);
        let Operation;
        values[0].AWBDateandNo = ''
        values[0].SubmittedDate = '';
        values[0].SendTo = '';
        values[0].SendToEdit = '';
        values[0].SendThrough = 0;
        values[0].SwatchCardImageName = '';
        values[0].SwatchCardIamgePath = '';
        values[0].Remarks = '';
        setshowawb(false);
        if (SubmitStatus === "Pending") {
            Operation = 1;
            setshowMultiClosediv(true);
            setshowSubmissiondiv(false);
            setheader('Manual Close');
        }
        else {
            Operation = 3;
            setshowMultiClosediv(false);
            setshowSubmissiondiv(true);
            setheader('View Manual Close');

        }
        if (SubmitStatus === "Pending") {
            setsubmittedoption([]);
            var options = [];
            options.push(
                { OptionName: "A", SupplierReferenceNo: "", GeneralTrimSubmittedOptionId: 0 },
                { OptionName: "B", SupplierReferenceNo: "", GeneralTrimSubmittedOptionId: 0 },
                { OptionName: "C", SupplierReferenceNo: "", GeneralTrimSubmittedOptionId: 0 },
                { OptionName: "D", SupplierReferenceNo: "", GeneralTrimSubmittedOptionId: 0 },
            )
            setsubmittedoption(options);
        }

        GeneralService.GetGeneralTrimDimensionList(GenTrimMainId).then((response) => {
            if (response.data) {
                setTrimDimension(response.data);
            }
        }).catch(() => { });
        GeneralService.GetGeneralTrimTaskHolderList(GenTrimMainId).then((response) => {
            if (response.data) {
                setTaskholder(response.data);
            }
        }).catch(() => { });

        //  var Main = TrimSubmissionList.filter(x => x.genralTrimTNASubmissionAndApprovalId === Id);
        var Sub = TrimSubmissionList[index].trimSubmitApprovalReferenceList.filter(x => x.genTrimTNASubmissionAndApprovalId === Id);
        Sub.forEach(element => {
            if (element.genTrimTNASubmissionAndApprovalId === Id) {
                element.isSelected = 1;
            }
        })

        values[0].SupplierName = Sub[0].supplierName;
        values[0].BuyerBrandSeasonName = Sub[0].buyerBrandSeasonName;
        values[0].StoryName = Sub[0].storyName;
        values[0].ThemeName = Sub[0].themeName;
        values[0].EndCustomer = Sub[0].endCustomerNameIdList;
        values[0].TrimCode = Sub[0].trimCode;
        values[0].TrimId = Sub[0].trimId;
        values[0].TrimName = Sub[0].trimName;
        values[0].TechnicalSpecification = Sub[0].technicalSpecification;
        values[0].TrimDimensionId = Sub[0].trimRefernceId;
        values[0].GenralTrimTNASubmissionAndApprovalId = Sub[0].genTrimTNASubmissionAndApprovalId;
        values[0].SubmitStatus = Sub[0].submitStatus;
        values[0].ColorGroupId = Sub[0].colorGroupId;
        let images = [];
        images = Sub[0].trimImageList.split(',');
        setSubImageList(images);
        setCurrimageindex(0);



        if (SubmitStatus !== "Completed") {
            GeneralService.GetGeneralTrimOrderInfoList(Id, BuyerId, BrandId, SeasonId, SupplierId, Follower,
                TaskId, trimId, GenTrimLogoID, GenTrimId, Operation, 0).then((response) => {
                    if (response.data) {
                        let index = 0;
                        let result = response.data.trimSubmitApprovalReferenceList.map((item) => {
                            index = index + 1;
                            item.index = index;
                            if (item.genTrimTNASubmissionAndApprovalId === Id) {
                                item.isSelected = 1;

                                return item;
                            } else {
                                return item;
                            }
                        });
                        let isSelectAll = 0;
                        if (result) {
                            isSelectAll = !!!result.find(d => d.isSelected === 0);
                            isSelectAll = isSelectAll ? 1 : 0;
                        }
                        setselectall(isSelectAll);
                        setMultiCloseDatas(result);
                        let data = [...new Map(response.data.trimSubmitApprovalReferenceList.map(item =>
                            [item['trimLogoId'], item])).values()];


                        setOrderInfoMulticloseMainDetails(data);
                        DefaultExpandRowMultiSelect(Sub[0].trimLogoPath, response.data.trimSubmitApprovalReferenceList);

                        setshowSubmission(true);
                        hideLoader();

                    } else {
                        hideLoader();
                    }
                }).catch(() => { hideLoader(); });
        }

        setInputFields(values);
    }

    const DefaultExpandRowMultiSelect = (trimLogoPath, list) => {

        setExpandedRowsMultiSelect([]);
        setexpandListMultiSelect(list.filter(x => x.trimLogoPath === trimLogoPath));
        let currentExpandedRows = expandedRowsMultiSelect;
        const isRowExpanded = false;
        let obj = {};
        isRowExpanded ? (obj[trimLogoPath] = false) : (obj[trimLogoPath] = true);
        setExpandStateMultiSelect(obj);
        currentExpandedRows = [];
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== trimLogoPath) :
            currentExpandedRows.concat(trimLogoPath);
        setExpandedRowsMultiSelect(newExpandedRows);


    }

    const handleExpandRowMultiSelect = (trimLogoPath, list) => {
        setExpandedRowsMultiSelect([]);
        setexpandListMultiSelect(list.filter(x => x.trimLogoPath === trimLogoPath));
        let currentExpandedRows = expandedRowsMultiSelect;
        const isRowExpanded = currentExpandedRows.includes(trimLogoPath);
        let obj = {};
        isRowExpanded ? (obj[trimLogoPath] = false) : (obj[trimLogoPath] = true);
        setExpandStateMultiSelect(obj);
        currentExpandedRows = [];
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== trimLogoPath) :
            currentExpandedRows.concat(trimLogoPath);
        setExpandedRowsMultiSelect(newExpandedRows);


    }

    const handleChangeIsManualCloseComments = (e, feild) => {
        var Manual = [...ManualInfo];

        let input = '';

        if (feild === "Comment") {
            if (e.target.value.trim() !== '') {
                input = e.target.value;
            }
            Manual[0].manualCloseComment = input;
        }
        else {
            Manual[0].manualCloseDate = e;
        }
        setManualInfo(Manual);

    }

    const SaveChildManulaCloseComments = () => {
        if ((!ManualInfo[0].manualCloseComment) || (!ManualInfo[0].manualCloseDate)) {
            setComment(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            return false;
        }
        else {
            showLoader();
            let strValue = "";
            let selected = 0;
            MultiCloseDatas.map((data) => {
                if (strValue === "") {
                    if (data.isSelected === 1) {
                        strValue = data.genTrimDetailTNAId.toString();
                        selected = selected + 1;
                    }
                } else {
                    if (data.isSelected === 1) {
                        strValue = strValue + "," + data.genTrimDetailTNAId;
                    }
                }
            });
            if (selected === 0) {
                hideLoader();
                Nodify('Warning!', 'warning', "Select atleast one Task");

            } else {

                var ManualCloseData = [{ IdList: '', ManualCloseDate: '', ManualCloseComment: '', Operation: 0 }];

                ManualCloseData[0].ManualCloseDate = convertUTCDateToLocalDate(new Date(ManualInfo[0].manualCloseDate));
                // ManualCloseData[0].ManualCloseDate.setMinutes(ManualCloseData[0].manualCloseDate.getMinutes() + 370);

                ManualCloseData[0].ManualCloseComment = ManualInfo[0].manualCloseComment;
                ManualCloseData[0].IdList = strValue;
                ManualCloseData[0].Operation = 1;

                GeneralService.UpdateGeneralTrimManualInfo(ManualCloseData[0]).then((res) => {

                    if (res.data.outputResult === "1") {
                        Nodify('Success!', 'success', "Manual Comment Added Successfully");
                        setShowSOManual(false);

                        //handleToggle(currentExpand.currindex, currentExpand.currTrimId, currentExpand.currGenTrimLogoId, true);
                        ReloadSubmission();
                        searchTrim();
                        hideLoader();
                    } else if (res.data.outputResult === "2") {
                        Nodify('Success!', 'success', "Manual Comment Updated Successfully");
                        setShowSOManual(false);
                        // searchTrim();
                        //handleToggle(currentExpand.currindex, currentExpand.currTrimId, currentExpand.currGenTrimLogoId, true);
                        ReloadSubmission();
                        searchTrim();
                        hideLoader();
                    } else if (res.data.outputResult === "-2") {
                        Nodify('Warning!', 'warning', "Error Occured");
                        hideLoader();
                    }
                }).catch(e => {
                    console.log(e);
                    hideLoader();

                });
            }

        }

    }



    const SelectAll = (event, trimLogoPath, mainindex) => {
        let IsSelected = 0;
        var Maindetails = [...OrderInfoMulticloseMainDetails];
        if (event.target.checked === true) {
            IsSelected = 1;
            MultiCloseDatas.filter(x => x.trimLogoPath === trimLogoPath).forEach(element => {
                element.isSelected = 1;
            })
        }
        else {
            IsSelected = 0;
            MultiCloseDatas.filter(x => x.trimLogoPath === trimLogoPath).forEach(element => {
                element.isSelected = 0;
            })
        }
        Maindetails[mainindex].isSelectedAll = IsSelected;
        setOrderInfoMulticloseMainDetails(Maindetails);

        //setselectall({ IsAllSelected: IsSelected, AllSelectedIndex: mainindex });
    }

    const OnSelectRecords = (event, index, mainindex) => {
        const value = [...MultiCloseDatas];
        var Maindetails = [...OrderInfoMulticloseMainDetails];
        if (value) {
            if (event.target.checked === true) {
                value[index].isSelected = 1;
            }
            else {
                value[index].isSelected = 0;
            }
            let isSelectAll = 0;
            if (value) {
                isSelectAll = !!!value.find(d => d.isSelected === 0);
                isSelectAll = isSelectAll ? 1 : 0;
            }

            Maindetails[mainindex].isSelectedAll = isSelectAll;
            setOrderInfoMulticloseMainDetails(Maindetails);
            // setselectall({ IsAllSelected: isSelectAll, AllSelectedIndex: mainindex });
            setMultiCloseDatas(value);
        }

    }

    const AddRows = () => {
        let FileteredRecords = MultiCloseDatas.filter(x => x.isSelected === 1);
        if (FileteredRecords.length === 0) {
            Nodify("Warning!", "warning", "Select atleast one row.");
        }
        else {
            setshowMultiClosediv(false);
            setshowSubmissiondiv(true);
            // let data = [...new Map(FileteredRecords.map(item =>
            //     [item['trimId'], item])).values()];
            let index = 0;
            FileteredRecords.forEach(element => {
                index = index + 1;
                element.index = index;
            })

            let data = [...new Map(FileteredRecords.map(item =>
                [item['trimLogoId'], item])).values()];

            setOrderInfoMainDetails(data);
            setOrderinfo(FileteredRecords);
            handleExpandRow(FileteredRecords[0].trimLogoPath, FileteredRecords);

            var TrimDim = [];
            FileteredRecords.map(Fr => {
                TrimDimension.map(Td => {
                    if (Fr.trimRefernceId === Td.id) {
                        TrimDim.push({
                            id: Td.id,
                            name: Td.name,
                            value: Td.value,
                            label: Td.label
                        })
                    }

                })
            })
            let uniqueTrimDim = [...new Map(TrimDim.map((item) => [item["id"], item])).values()];
            setTrimDimension(uniqueTrimDim);
        }
    }

    const handleExpandRow = (trimLogoPath, list) => {
        setExpandedRows([]);
        setexpandList(list.filter(x => x.trimLogoPath === trimLogoPath));
        let currentExpandedRows = expandedRows;
        const isRowExpanded = currentExpandedRows.includes(trimLogoPath);
        let obj = {};
        isRowExpanded ? (obj[trimLogoPath] = false) : (obj[trimLogoPath] = true);
        setExpandState(obj);
        currentExpandedRows = [];
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== trimLogoPath) :
            currentExpandedRows.concat(trimLogoPath);
        setExpandedRows(newExpandedRows);


    }

    const ShowHideDivs = () => {
        setshowMultiClosediv(true);
        setshowSubmissiondiv(false);
    }




    //#region ---- Edit Task---

    const handleTaskPopupClose = () => {
        setShowTaskPopup(false);
    }

    const pageRedirect = (SubmissionParams, index) => {

        let searchParams = {
            Index: index,
            GenTrimDetailTNAId: SubmissionParams.genTrimDetailTNAId
        };
        setID({ showPopupDelete: true, Params: SubmissionParams, SearchParams: searchParams });

    };

    const OpenTrimEditPopup = (genTrimSupplierTNAId, genTrimDetailTNAId, supplierId) => {
        ;
        dispatch(getGenTrimTNASubmissionAndApproval(genTrimSupplierTNAId, genTrimDetailTNAId))
            .then(data => {
                if (data) {
                    setIsTaskFieldChange(1);
                    setShowTaskPopup(true);
                    setEditTNASupplierId(supplierId);
                    MainValues.GenTrimSupplierTNAId = genTrimSupplierTNAId;
                    MainValues.GenTrimDetailTNAId = genTrimDetailTNAId;
                    setMainFields(MainValues);
                    hideLoader();
                }
            })
            .catch(e => {
                console.log(e);
                hideLoader();

            });
    }

    useMemo(
        () => {

            let Optionvalue = [];
            taskHolderList && taskHolderList.map(element => {
                let LabelColor = '';
                if (element.label === 'Buyer - -') {
                    LabelColor = <><span style={{ color: "#0b0992" }}><b>Buyer</b></span></>
                }
                else if (element.label === 'Buyer - Cube') {
                    LabelColor = <><span style={{ color: "#0b0992" }}><b>Buyer</b></span> - <span style={{ color: "#00a65a" }}><b>Cube</b></span></>
                }
                else if (element.label === 'Buyer - Supplier') {
                    LabelColor = <><span style={{ color: "#0b0992" }}><b>Buyer</b></span> - <span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                }
                else if (element.label === 'Buyer - Cube,Supplier') {
                    LabelColor = <><span style={{ color: "#0b0992" }}><b>Buyer</b></span> - <span style={{ color: "#00a65a" }}><b>Cube</b></span>, <span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                }
                else if (element.label === 'Cube - -') {
                    LabelColor = <><span style={{ color: "#00a65a" }}><b>Cube</b></span></>
                }
                else if (element.label === 'Cube - Buyer') {
                    LabelColor = <><span style={{ color: "#00a65a" }}><b>Cube</b></span> - <span style={{ color: "#0b0992" }}><b>Buyer</b></span></>
                }
                else if (element.label === 'Cube - Supplier') {
                    LabelColor = <><span style={{ color: "#00a65a" }}><b>Cube</b></span> - <span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                }
                else if (element.label === 'Cube - Buyer,Supplier') {
                    LabelColor = <><span style={{ color: "#00a65a" }}><b>Cube</b></span> - <span style={{ color: "#0b0992" }}><b>Buyer</b></span>, <span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                }
                else if (element.label === 'Supplier - -') {
                    LabelColor = <><span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                }
                else if (element.label === 'Supplier - Buyer') {
                    LabelColor = <><span style={{ color: "#e27607" }}><b>Supplier</b></span> - <span style={{ color: "#0b0992" }}><b>Buyer</b></span></>
                }
                else if (element.label === 'Supplier - Cube') {
                    LabelColor = <><span style={{ color: "#e27607" }}><b>Supplier</b></span> - <span style={{ color: "#00a65a" }}><b>Cube</b></span></>
                }
                else if (element.label === 'Supplier - Buyer,Cube') {
                    LabelColor = <><span style={{ color: "#e27607" }}><b>Supplier</b></span> - <span style={{ color: "#0b0992" }}><b>Buyer</b></span>, <span style={{ color: "#00a65a" }}><b>Cube</b></span></>
                }

                Optionvalue.push({
                    value: element.value,
                    label: LabelColor,
                    subLabel: element.label,
                })
            });

            setTaskHolderList(Optionvalue);

        },
        [taskHolderList]
    );



    useMemo(
        () => {
            setIsTaskFieldChange(0);
            ;
            if (editTNATaskTrimSubmissionList) {
                ;
                let List = editTNATaskTrimSubmissionList.item1;
                // let additionalInfo = AllRouteInfo.additionalInfo;
                let TNAReferenceListList = [];
                List && List.map((item, i) => {
                    ;
                    let StartDate = new Date(item.planStartDate);
                    let EndDate = new Date(item.endDate);
                    let employee1 = "";
                    let employee2 = "";
                    let employee3 = "";

                    if (item.taskHolderOrFollowerName) {
                        let TaskHolderName = item.taskHolderOrFollowerName.split('-');
                        if (TaskHolderName[0] !== undefined) {
                            employee1 = TaskHolderName[0].trim();

                            if (TaskHolderName[1] !== undefined) {
                                let FollowerName = TaskHolderName[1].split(',');
                                if (FollowerName[0] !== undefined) {
                                    employee2 = FollowerName[0].trim();

                                    if (FollowerName[1] !== undefined) {
                                        employee3 = FollowerName[1].trim();
                                    } else {
                                        employee3 = "";
                                    }
                                } else {
                                    employee2 = "";
                                    employee3 = "";
                                }

                            } else {
                                employee2 = "";
                                employee3 = "";
                            }
                        }
                    }

                    let FollowupDetails = [];

                    if (editTNATaskTrimSubmissionList.item2 && editTNATaskTrimSubmissionList.item2.length > 0) {
                        editTNATaskTrimSubmissionList.item2.filter(x => x.indexName === item.indexName && x.taskID === item.taskID).map((element, j) => {
                            FollowupDetails.push({
                                GenTrimTNAAddTaskId: element.genTrimTNAAddTaskId,
                                TaskId: element.taskID,
                                TaskTypeId: element.taskTypeID,
                                AdditonalTaskDetailsID: element.additonalTaskDetailsID,
                                AddtionalTaskName: element.addtionalTaskName,
                                IsTaskSelected: element.isTaskSelected,
                                IndexName: element.indexName.toString()
                            })
                        });
                    }
                    let FollowupDetailCount = FollowupDetails && FollowupDetails.filter(d => d.IsTaskSelected === 1).length;
                    let IsSelectAll = 0;
                    if (FollowupDetailCount === FollowupDetails.length && FollowupDetails.length > 0) {
                        IsSelectAll = 1;
                    }


                    TNAReferenceListList.push({
                        GenralTrimTNASubmissionAndApprovalId: item.genTrimTNASubmissionAndApprovalId,
                        TaskID: item.taskID,
                        TaskName: item.taskName,
                        TaskType: item.taskTypeID,
                        TaskTypeName: item.taskTypeName,
                        EndDate: EndDate,
                        StartDate: StartDate,
                        PreStartDate: StartDate,
                        PreEndDate: EndDate,
                        Duration: item.duration,
                        LogDays: item.logDays,
                        AdditionalFollowup: FollowupDetailCount,
                        IsFollowupExist: 0,
                        IndexName: '0',
                        IsPopupOpen: 0,
                        IsSelectAll: IsSelectAll,
                        FollowupDetails: FollowupDetails,
                        TaskHolderOrFollower: item.taskHolderID, //TaskHolder
                        TaskHolderOwnerTxtList: '',
                        TaskHolderOwnerIdList: item.taskOwner,
                        TaskHolderList: '',
                        titleEmployee1: employee1,
                        titleEmployee2: employee2,
                        titleEmployee3: employee3,
                        TaskHolderBuyer: item.taskHolderIDList,
                        arrTaskHolderBuyer: [],
                        FollowerCube: item.followerIDList1,
                        arrFollowerCube: [],
                        FollowerSupplier: item.followerIDList2,
                        arrFollowerSupplier: [],
                        LeaveDays: item.leaveDays,
                        LeaveDaysTxt: item.leaveDayName,
                        IsTHLeaveDaysOpen: 0,
                        TaskHolderBuyerTxt: '',
                        FollowerCubeTxt: '',
                        FollowerSupplierTxt: '',
                        DependencyID: item.dependencyID,
                        EndDateChange: 0,
                        TaskHolderIDList: item.taskHolderIDList,
                        FollowerIDList1: item.followerIDList1,
                        FollowerIDList2: item.followerIDList2,
                        IsTHFirstTime: 0,
                    });
                });

                // if (genTrimTNAAddtionalTaskList && genTrimTNAAddtionalTaskList.length > 0) {
                //     TNAPantoneOrSwatchList.map((add, i) => {


                //     })
                // }
                setInputTaskFields(TNAReferenceListList);
                CalculationValue.isDuration = false;
                CalculationValue.isEndDate = false;
                CalculationValue.isLoad = CalculationValue.isLoad + 1;
                setCalculation(CalculationValue);
            } else {
                setInputTaskFields([]);
            }

        },
        [isTaskFieldChange === 1]
    );

    // useEffect(() => {
    //     if (valuesTask.length > 0) {
    //         if (getLeaveDaysList.isLoad === true) {
    //             getLeaveDaysList.isLoad = false;
    //             setCalculation(getLeaveDaysList);
    //         }
    //     }
    // }, [getLeaveDaysList.isLoad === true]);


    // #region ------------------  TaskHolderLeaveDays Start --------------------------------
    const handleFollowup = index => {
        if (valuesTask[index].FollowupDetails && valuesTask[index].FollowupDetails.length > 0) {
            valuesTask[index].IsPopupOpen = 1;
            setInputTaskFields(valuesTask);
        } else {
            Nodify('Warning!', 'warning', 'No data in additional task');
        }

    };

    const handleTaskHolderLeaveDays = (index, isTHFirstTime) => {
        let SupplierId = getEditTNASupplierId.toString();
        if (valuesTask[index].titleEmployee1 !== "Cube" && valuesTask[index].titleEmployee2 !== "Cube" && valuesTask[index].titleEmployee3 !== "Cube") {
            dispatch(loadGLDTNATaskOwnerList(valuesTask[index].TaskHolderOrFollower, BuyerId, 0, SupplierId))
                .then(data => {
                    if (data) {
                        setTNATaskOwner(data, index, isTHFirstTime);
                    }
                    valuesTask[index].IsTHLeaveDaysOpen = 1;
                    setInputTaskFields(valuesTask);
                })
                .catch(e => {
                    console.log(e);
                    setInputTaskFields(valuesTask);
                });
        } else {
            dispatch(loadGLDTNATaskOwnerList(valuesTask[index].TaskHolderOrFollower, BuyerId, currentUser.employeeinformationID, SupplierId))
                .then(data => {
                    if (data) {
                        setTNATaskOwner(data, index, isTHFirstTime);
                    }
                    valuesTask[index].IsTHLeaveDaysOpen = 1;
                    setInputTaskFields(valuesTask);
                })
                .catch(e => {
                    console.log(e);
                    setInputTaskFields(valuesTask);
                });
        }


    };

    function setTNATaskOwner(data, index, isTHFirstTime) {
        let titleEmployee1 = valuesTask[index].titleEmployee1;
        let titleEmployee2 = valuesTask[index].titleEmployee2;
        let titleEmployee3 = valuesTask[index].titleEmployee3;

        if (data.buyerEmployeeList && data.buyerEmployeeList.length > 0) {

            let taskHolderBuyer = valuesTask[index].TaskHolderIDList && data.buyerEmployeeList.filter(item => (valuesTask[index].TaskHolderIDList.split(',').includes(item.value.toString())));
            if (taskHolderBuyer && taskHolderBuyer.length > 0) {
                if (valuesTask[index].arrTaskHolderBuyer.length === 0 && isTHFirstTime === 0) {
                    valuesTask[index].arrTaskHolderBuyer = [...taskHolderBuyer];
                } else {
                    const isData = !!valuesTask[index].arrTaskHolderBuyer.filter(d => d.value === taskHolderBuyer.value);
                    if (isData === false && isTHFirstTime === 0) {
                        valuesTask[index].arrTaskHolderBuyer = [...valuesTask[index].arrTaskHolderBuyer, ...taskHolderBuyer];
                    }
                }

            }
        }
        if (data.cubeEmployeeList !== undefined) {
            let followerCube = valuesTask[index].FollowerIDList1 && data.cubeEmployeeList.filter(item => (valuesTask[index].FollowerIDList1.split(',').includes(item.value.toString())));
            if (followerCube && followerCube.length > 0) {
                if (valuesTask[index].arrFollowerCube.length === 0 && isTHFirstTime === 0) {
                    valuesTask[index].arrFollowerCube = [...followerCube];
                } else {
                    const isData = !!valuesTask[index].arrFollowerCube.filter(d => d.value === followerCube.value);
                    if (isData === false && isTHFirstTime === 0) {
                        valuesTask[index].arrFollowerCube = [...followerCube];
                    }
                }
            }
        }
        if (data.supplierEmployeeList !== undefined && (titleEmployee1 === "Supplier" || titleEmployee2 === "Supplier" || titleEmployee3 === "Supplier")) {
            let followerSupplier = valuesTask[index].FollowerIDList2 && data.supplierEmployeeList.filter(item => (valuesTask[index].FollowerIDList2.includes(item.value.toString())));
            if (followerSupplier && followerSupplier.length > 0) {
                if (valuesTask[index].arrFollowerSupplier.length === 0 && isTHFirstTime === 0) {
                    valuesTask[index].arrFollowerSupplier = [...followerSupplier];
                } else {
                    const isData = !!valuesTask[index].arrFollowerSupplier.filter(d => d.value === followerSupplier.value);
                    if (isData === false && isTHFirstTime === 0) {
                        valuesTask[index].arrFollowerSupplier = [...followerSupplier];
                    }
                }
            }
        }

        valuesTask[index].IsTHFirstTime = 1;
    }

    const handleTHLeaveDaysSave = (index) => {

        let Taskhoder = BindTaskHoldername(valuesTask, index);
        if (Taskhoder === "") {
            Nodify('Warning!', 'warning', 'Please select task holder');
        } else {
            valuesTask[index].IsTHLeaveDaysOpen = 0;
            setInputTaskFields(valuesTask);

        }
    }

    const handleTHLeaveDaysSaveNext = (index) => {
        let Taskhoder = BindTaskHoldername(valuesTask, index);
        if (Taskhoder === "") {
            Nodify('Warning!', 'warning', 'Please select task holder name');
        } else {
            valuesTask[index].IsTHLeaveDaysOpen = 0;
            // save Next instance
            index = index + 1;
            let totalValue = valuesTask.length - 1;
            if (totalValue >= index) {
                handleTaskHolderLeaveDays(index, valuesTask[index].IsTHFirstTime)
            }
            else {
                valuesTask[index - 1].IsTHLeaveDaysOpen = 0;
                setInputTaskFields(valuesTask);
            }
        }
    }

    function BindTaskHoldername(valuesTask, index) {

        let TxtList = "";
        let IdList = "";
        let Taskhoder = "";
        let titleEmployee1 = valuesTask[index].titleEmployee1;
        let titleEmployee2 = valuesTask[index].titleEmployee2;
        let titleEmployee3 = valuesTask[index].titleEmployee3;

        if (valuesTask[index].TaskHolderBuyer && (titleEmployee1 === "Buyer" || titleEmployee2 === "Buyer" || titleEmployee3 === "Buyer")) {
            // TxtList = valuesTask[index].TaskHolderBuyerTxt;
            IdList = valuesTask[index].TaskHolderBuyer;
            //Taskhoder = values[index].TaskHolderBuyer;
        } else {
            valuesTask[index].TaskHolderBuyer = "";
            // valuesTask[index].TaskHolderBuyerTxt = "";
            valuesTask[index].arrTaskHolderBuyer = [];
        }
        if (valuesTask[index].FollowerCube && (titleEmployee1 === "Cube" || titleEmployee2 === "Cube" || titleEmployee3 === "Cube")) {
            //TxtList = TxtList === "" ? valuesTask[index].FollowerCubeTxt : TxtList + ", " + valuesTask[index].FollowerCubeTxt;
            IdList = IdList === "" ? valuesTask[index].FollowerCube : IdList + "," + valuesTask[index].FollowerCube;
        }
        else {
            valuesTask[index].FollowerCube = "";
            //valuesTask[index].FollowerCubeTxt = "";
            valuesTask[index].arrFollowerCube = [];
        }
        if (valuesTask[index].FollowerSupplier && (titleEmployee1 === "Supplier" || titleEmployee2 === "Supplier" || titleEmployee3 === "Supplier")) {
            //TxtList = TxtList === "" ? valuesTask[index].FollowerSupplierTxt : TxtList + ", " + valuesTask[index].FollowerSupplierTxt;
            IdList = IdList === "" ? valuesTask[index].FollowerSupplier : IdList + "," + valuesTask[index].FollowerSupplier;
        } else {
            valuesTask[index].FollowerSupplier = "";
            // valuesTask[index].FollowerSupplierTxt = "";
            valuesTask[index].arrFollowerSupplier = [];
        }

        // valuesTask[index].TaskHolderOwnerTxtList = TxtList;
        valuesTask[index].TaskHolderOwnerIdList = IdList;

        if (valuesTask[index].titleEmployee1 === 'Cube') {
            Taskhoder = valuesTask[index].FollowerCube;
        }
        else if (valuesTask[index].titleEmployee1 === 'Buyer') {
            Taskhoder = valuesTask[index].TaskHolderBuyer;
        }
        else if (valuesTask[index].titleEmployee1 === 'Supplier') { Taskhoder = valuesTask[index].FollowerSupplier; }
        return Taskhoder;
    }



    // #end region ------------------  TaskHolderLeaveDays End --------------------------------


    const handleAdditionalTaskClose = (index) => {
        valuesTask[index].IsPopupOpen = 0;
        setInputTaskFields(valuesTask);
    };

    const handleAddTaskChange = (e, index, childIndex, FieldName) => {
        ;
        if (e.target.checked) {
            valuesTask[index].FollowupDetails[childIndex].IsTaskSelected = 1;
            let IsSelected = 1;
            let UnselectedRecords = valuesTask[index].FollowupDetails.filter(x => x.IsTaskSelected === 0);
            if (UnselectedRecords.length > 0) {
                IsSelected = 0;
            }
            valuesTask[index].IsSelectAll = IsSelected;
            valuesTask[index].AdditionalFollowup = valuesTask[index].FollowupDetails.filter(x => x.IsTaskSelected === 1).length;

        } else {
            valuesTask[index].FollowupDetails[childIndex].IsTaskSelected = 0;
            valuesTask[index].IsSelectAll = 0;
            valuesTask[index].AdditionalFollowup = valuesTask[index].FollowupDetails.filter(x => x.IsTaskSelected === 1).length;
        }
        setInputTaskFields(valuesTask);
    }

    const filterOption = (option, inputValue) => {
        let trimValue = inputValue.trim();
        if (trimValue === "") {
            return option;
        }
        return option.data.subLabel.toLowerCase().includes(trimValue.toLowerCase());
    };

    const handleTaskChange = (e, index, FieldName) => {
        setSubmitted(false);
        let inputText = '';
        if (FieldName === "IsSelectAll") {
            if (e.target.checked) {
                for (var i = 0; i < valuesTask[index].FollowupDetails.length; i++) {
                    valuesTask[index].FollowupDetails[i].IsTaskSelected = 1;
                }
                valuesTask[index][FieldName] = 1;
            } else {
                for (var i = 0; i < valuesTask[index].FollowupDetails.length; i++) {
                    valuesTask[index].FollowupDetails[i].IsTaskSelected = 0;
                }
                valuesTask[index][FieldName] = 0;
            }

            valuesTask[index].AdditionalFollowup = valuesTask[index].FollowupDetails.filter(x => x.IsTaskSelected === 1).length;
            valuesTask[index].IsFollowupExist = valuesTask[index].FollowupDetails.length;

        } else if (FieldName === "TaskHolderOrFollower" || FieldName === "LeaveDays") {
            if (e != null) {

                inputText = parseInt(e.value);
                if (FieldName === "TaskHolderOrFollower") {
                    // setTaskHolderID(inputText);
                    showLoader();
                    valuesTask[index].TaskHolderOrFollowerTxt = e.subLabel && e.subLabel.replace(" - -", "");
                    let TaskHolderName = valuesTask[index].TaskHolderOrFollowerTxt && valuesTask[index].TaskHolderOrFollowerTxt.split('-');
                    if (TaskHolderName[0]) {
                        valuesTask[index].titleEmployee1 = TaskHolderName[0].trim();

                        if (TaskHolderName[1]) {
                            let FollowerName = TaskHolderName[1].split(',');
                            if (FollowerName[0]) {
                                valuesTask[index].titleEmployee2 = FollowerName[0].trim();

                                if (FollowerName[1]) {
                                    valuesTask[index].titleEmployee3 = FollowerName[1].trim();
                                } else {
                                    valuesTask[index].titleEmployee3 = "";
                                }
                            } else {
                                valuesTask[index].titleEmployee2 = "";
                                valuesTask[index].titleEmployee3 = "";
                            }

                        } else {
                            valuesTask[index].titleEmployee2 = "";
                            valuesTask[index].titleEmployee3 = "";
                        }
                        let SupplierId = getEditTNASupplierId.toString();
                        if (valuesTask[index].titleEmployee1 !== "Cube" && valuesTask[index].titleEmployee2 !== "Cube" && valuesTask[index].titleEmployee3 !== "Cube") {

                            dispatch(loadGLDTNATaskOwnerList(inputText, BuyerId, 0, SupplierId))
                                .then(data => {
                                    if (data) {
                                        hideLoader();
                                    }
                                })
                                .catch(e => {
                                    console.log(e);
                                    hideLoader();

                                });
                        } else {
                            dispatch(loadGLDTNATaskOwnerList(inputText, BuyerId, currentUser.employeeinformationID, SupplierId))
                                .then(data => {
                                    if (data) {
                                        hideLoader();
                                    }
                                })
                                .catch(e => {
                                    console.log(e);
                                    hideLoader();

                                });
                        }

                        // if (values[index].titleEmployee1 === "Supplier" || values[index].titleEmployee2 === "Supplier" || values[index].titleEmployee3 === "Supplier") {
                        //     if (drpRouteTaskOwnerList.supplierEmployeeList && drpRouteTaskOwnerList.supplierEmployeeList.length === 1) {
                        //         values[index].arrFollowerSupplier = [drpRouteTaskOwnerList.supplierEmployeeList[0]];
                        //         values[index].FollowerSupplier = drpRouteTaskOwnerList.supplierEmployeeList[0].value;
                        //     }
                        // }

                    } else {
                        valuesTask[index].titleEmployee1 = "";
                        valuesTask[index].titleEmployee2 = "";
                        valuesTask[index].titleEmployee3 = "";
                    }


                } else {
                    valuesTask[index].LeaveDaysTxt = e.label;
                    CalculationValue.isDuration = false;
                    CalculationValue.isEndDate = false;
                    CalculationValue.isLoad = CalculationValue.isLoad + 1;
                    setCalculation(CalculationValue);
                }
            }
            else {
                inputText = 0;
                if (FieldName === "TaskHolderOrFollower") {
                    valuesTask[index].TaskHolderOrFollowerTxt = '';
                    valuesTask[index].titleEmployee1 = "";
                    valuesTask[index].titleEmployee2 = "";
                    valuesTask[index].titleEmployee3 = "";
                }
                valuesTask[index].LeaveDaysTxt = "";
            }
            valuesTask[index][FieldName] = inputText;
        }
        else if (FieldName === "Duration") {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            var checkNumber = inputText.replace(/\D/g, '').match('^[0-9]*$');
            inputText = checkNumber[0];

            if (parseInt(inputText) === 0) {
                inputText = '';
            }
            valuesTask[index][e.target.name] = inputText;
            if (valuesTask[index].EndDateChange === 1) {
                valuesTask[index].EndDateChange = 2;
            }
        }
        else if (FieldName === "LogDays") {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            var checkNumber = inputText.replace(/\D/g, '').match('^[0-9]*$');
            inputText = checkNumber[0];

            if (parseInt(inputText) === 0) {
                inputText = '';
            }
            valuesTask[index][e.target.name] = inputText;
        }
        else if (FieldName === "EndDate") {
            valuesTask[index].PreEndDate = valuesTask[index].EndDate;
            valuesTask[index].EndDate = e;
            if (valuesTask[index].EndDateChange === 0) {
                valuesTask[index].EndDateChange = 1;
            }

        }
        else if (FieldName === "TaskHolderBuyer" || FieldName === "FollowerCube" || FieldName === "FollowerSupplier") {

            let strValue = '';
            let strTxt = '';
            e.map((val) => {
                if (strValue === '') { strValue = val.value.toString(); strTxt = val.label.toString(); }
                else { strValue = strValue + "," + val.value; strTxt = strTxt + ", " + val.label.toString(); }
            });
            valuesTask[index]["arr" + FieldName] = e;
            valuesTask[index][FieldName] = strValue;
            valuesTask[index][FieldName + "Txt"] = strTxt;

        }
        else if (FieldName === "DependencyID") {
            let DependencyID = 0;
            let DependencyTxt = "";
            if (e != null) {
                DependencyID = parseInt(e.value);
            } else {
                DependencyID = 0;
            }

            valuesTask[index].DependencyID = DependencyID;
        }
        else {
            if (e.target.value.trim() !== '') {
                inputText = parseInt(e.value);
            }
            valuesTask[index][e.target.name] = inputText;
        }
        setInputTaskFields(valuesTask);
        if (FieldName === "EndDate") {
            CalculationValue.isDuration = false;
            CalculationValue.isEndDate = true;
            CalculationValue.isLoad = CalculationValue.isLoad + 1;
            // commonCalculation(false, true);
        } else if (FieldName === "Duration" || FieldName === "LogDays") {
            CalculationValue.isDuration = true;
            CalculationValue.isEndDate = false;
            CalculationValue.isLoad = CalculationValue.isLoad + 1;
            //commonCalculation(true, false);
        } else if (FieldName === "DependencyID") {
            CalculationValue.isDuration = false;
            CalculationValue.isEndDate = false;
            CalculationValue.isLoad = CalculationValue.isLoad + 1;
            //commonCalculation(false, false);
        }
        setCalculation(CalculationValue);

    }

    //    ------------- **** Calculation Begin ***** --------------------------

    //Main Calculation Function


    useMemo(() => {
        let isDuration = CalculationValue.isDuration;
        let isEndDate = CalculationValue.isEndDate;
        CalculationValue.isLoad !== 0 && valuesTask && valuesTask.map((Item, i) => {
            let Duration = (Item.Duration) ? parseInt(Item.Duration) > 0 ? parseInt(Item.Duration) - 1 : 0 : 0;
            let dependencyCount = Item.DependencyID ? 1 : 0;

            if (isEndDate) {
                let CheckDate = checkDateBetweenInHolidayAndWE(new Date(Item.EndDate), Item.LeaveDays, Item.titleEmployee1, "add");
                if (!isSameDay(new Date(Item.EndDate), CheckDate)) {
                    Nodify('Warning!', 'warning', 'You select the end date to leave day');
                }
            }

            if (((i === 0 || dependencyCount === 0) && isEndDate === false) || (isDuration === true && dependencyCount === 0)) {
                // Dependency Calculation Without
                let dbStartDate = new Date(Item.StartDate);
                let total = 0;
                //add Saturday,Sunday and Holiday Count
                dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, Item.LeaveDays, Item.titleEmployee1, "add");
                let EndDate = addDays(dbStartDate, Duration);
                //Total Saturday,Sunday and Holiday Count
                total = totalHolidayAndWE(dbStartDate, EndDate, Item.LeaveDays, Item.titleEmployee1);
                for (let l = 0; l < total; l++) {
                    EndDate = addDays(EndDate, 1);
                    EndDate = checkDateBetweenInHolidayAndWE(EndDate, Item.LeaveDays, Item.titleEmployee1, "add");
                }

                Item.StartDate = dbStartDate;
                Item.EndDate = EndDate;
                Item.PreStartDate = dbStartDate;
                Item.PreEndDate = EndDate;
                valuesTask[i] = Item;
            }
            else if ((i === 0 || dependencyCount === 0) || (isEndDate === true && dependencyCount === 0)) {

                let dbEndDate = Item.EndDate;
                let total = 0;
                //add Saturday,Sunday and Holiday Count
                dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, Item.LeaveDays, Item.titleEmployee1, "add");
                let StartDate = subDays(dbEndDate, Duration);
                //Total Saturday,Sunday and Holiday Count
                total = totalHolidayAndWE(StartDate, dbEndDate, Item.LeaveDays, Item.titleEmployee1);
                for (let l = 0; l < total; l++) {
                    StartDate = subDays(StartDate, 1);
                    StartDate = checkDateBetweenInHolidayAndWE(StartDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                }

                Item.StartDate = StartDate;
                Item.EndDate = dbEndDate;
                Item.PreStartDate = StartDate;
                Item.PreEndDate = dbEndDate;
                valuesTask[i] = Item;
            }
            else {
                //Dependency Calculation

                let dbEndDate = new Date();
                let dbStartDate = new Date();
                let total = 0;

                if (Item.DependencyID) {
                    //-------Single  Dependent Calculation----------------
                    //Get Dependent Name and Index

                    let indexVal = 1;
                    let dependencyVal = (Item.DependencyID === 1 ? "ES" : Item.DependencyID === 2 ? "SS" : Item.DependencyID === 3 ? "SE" : Item.DependencyID === 4 ? "EE" : "");

                    //EndDate Change Duration Increase and Decrease
                    if (isEndDate === true && Item.EndDateChange !== 2) {
                        let chkStartDate = Item.StartDate;
                        let chkEndDate = Item.EndDate;
                        chkStartDate.setHours(0, 0, 0, 0);
                        chkEndDate.setHours(0, 0, 0, 0);
                        if (chkStartDate <= chkEndDate) {
                            if (dependencyVal === "SS" || dependencyVal === "ES") {
                                let LastDate = checkDateBetweenInHolidayAndWE(new Date(Item.EndDate), Item.LeaveDays, Item.titleEmployee1, "add");
                                let diffDayCount = diffDays(new Date(Item.StartDate), LastDate);
                                let holidayTotal = totalHolidayAndWE(Item.StartDate, LastDate, Item.LeaveDays, Item.titleEmployee1);
                                diffDayCount = diffDayCount - holidayTotal;
                                Item.Duration = diffDayCount;
                                Duration = diffDayCount - 1;
                            }


                        } else {
                            Item.EndDate = Item.PreEndDate;
                            Nodify('Warning!', 'warning', 'End date should not be less than start date.');
                        }
                    }

                    // //Skip First Step in SE
                    // if (dependencyVal === "SE" && isEndDate === true) {
                    //     Item.EndDateChange = 2;
                    // }

                    //Check Dependency Value
                    if (dependencyVal === "SS") {
                        //---- Start-Start ---- DependencyID 2------

                        //Get Dependent Map StartDate
                        let preStartDate = valuesTask[indexVal - 1].StartDate;
                        let LogDate = new Date();

                        if (Item.EndDateChange === 2) {
                            //Manual Calculation function------(preStartDate + LogDays = StartDate))-------
                            dbEndDate = checkDateBetweenInHolidayAndWE(Item.EndDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                            dbStartDate = subDays(dbEndDate, Duration);
                            //Total Saturday,Sunday and Holiday Count
                            total = totalHolidayAndWE(dbStartDate, dbEndDate, Item.LeaveDays, Item.titleEmployee1);
                            for (let l = 0; l < total; l++) {
                                dbStartDate = subDays(dbStartDate, 1);
                                dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                            }
                            LogDate = preStartDate;

                            if (Item.LogDays) {
                                //LogDays Add
                                LogDate = addDays(LogDate, parseInt(Item.LogDays));
                                //LogDate = checkDateBetweenInHolidayAndWE(LogDate, Item.LeaveDays, "sub");
                            }
                        }
                        LogDate.setHours(0, 0, 0, 0);
                        dbStartDate.setHours(0, 0, 0, 0);
                        if (LogDate <= dbStartDate && Item.EndDateChange === 2) {
                        } else {
                            //Auto Calculation function
                            if (Item.LogDays) {
                                //LogDays Add
                                dbStartDate = addDays(preStartDate, parseInt(Item.LogDays));
                                dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, Item.LeaveDays, Item.titleEmployee1, "add");
                            } else {
                                //Calculation Saturday,Sunday and Holiday Count
                                dbStartDate = checkDateBetweenInHolidayAndWE(preStartDate, Item.LeaveDays, Item.titleEmployee1, "add");
                            }

                            dbEndDate = addDays(dbStartDate, Duration);
                            //Total Saturday,Sunday and Holiday Count
                            total = totalHolidayAndWE(dbStartDate, dbEndDate, Item.LeaveDays, Item.titleEmployee1);
                            for (let l = 0; l < total; l++) {
                                dbEndDate = addDays(dbEndDate, 1);
                                dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, Item.LeaveDays, Item.titleEmployee1, "add");
                            }
                        }

                        Item.PreStartDate = dbStartDate;
                        Item.PreEndDate = dbEndDate;
                        Item.EndDate = dbEndDate;
                        Item.StartDate = dbStartDate;

                        valuesTask[i] = Item;

                    } else if (dependencyVal === "SE") {  //---- Start-End ----DependencyID 3---

                        let preStartDate = subDays(valuesTask[indexVal - 1].StartDate, 1);
                        let LogDate = preStartDate;


                        if (isEndDate === true && Item.EndDateChange !== 2) {
                            if (Item.LogDays) {
                                //LogDays Add
                                LogDate = addDays(LogDate, parseInt(Item.LogDays));
                            }
                            LogDate.setHours(0, 0, 0, 0);
                            let newDate = checkDateBetweenInHolidayAndWE(new Date(Item.EndDate), Item.LeaveDays, Item.titleEmployee1, "add");
                            newDate.setHours(0, 0, 0, 0);
                            if (LogDate <= newDate) {
                                let LastDate = checkDateBetweenInHolidayAndWE(new Date(Item.EndDate), Item.LeaveDays, Item.titleEmployee1, "add");
                                let diffDayCount = diffDays(new Date(Item.StartDate), LastDate);

                                let holidayTotal = totalHolidayAndWE(Item.StartDate, LastDate, Item.LeaveDays, Item.titleEmployee1);
                                diffDayCount = diffDayCount - holidayTotal;
                                Item.Duration = diffDayCount;
                                // Duration = diffDayCount - 1;
                                dbStartDate = Item.StartDate;
                                dbEndDate = LastDate;
                                // Item.Duration = Duration;
                            } else {
                                dbStartDate = Item.StartDate;
                                dbEndDate = Item.PreEndDate;
                                Item.EndDate = Item.PreEndDate;
                            }
                        } else {

                            if (Item.EndDateChange === 2) {
                                //Manual Calculation function----------((preStartDate - 1) + LogDays = EndDate))-------
                                dbEndDate = checkDateBetweenInHolidayAndWE(Item.EndDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                                dbStartDate = subDays(dbEndDate, Duration);
                                //Total Saturday,Sunday and Holiday Count
                                total = totalHolidayAndWE(dbStartDate, dbEndDate, Item.LeaveDays, Item.titleEmployee1);
                                for (let l = 0; l < total; l++) {
                                    dbStartDate = subDays(dbStartDate, 1);
                                    dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                                }

                                if (Item.LogDays) {
                                    //LogDays Add
                                    LogDate = addDays(LogDate, parseInt(Item.LogDays));
                                    //LogDate = checkDateBetweenInHolidayAndWE(LogDate, Item.LeaveDays, "sub");
                                }
                            }

                            LogDate.setHours(0, 0, 0, 0);
                            dbEndDate.setHours(0, 0, 0, 0);
                            if (LogDate <= dbEndDate && Item.EndDateChange === 2) {
                                //Skip
                            } else {
                                //Auto Calculation function

                                if (Item.LogDays) {
                                    //LogDays Add
                                    dbEndDate = addDays(preStartDate, parseInt(Item.LogDays));
                                    dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, Item.LeaveDays, Item.titleEmployee1, "add");
                                } else {
                                    dbEndDate = checkDateBetweenInHolidayAndWE(preStartDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                                }

                                dbStartDate = subDays(dbEndDate, Duration);
                                //Total Saturday,Sunday and Holiday Count
                                total = totalHolidayAndWE(dbStartDate, dbEndDate, Item.LeaveDays, Item.titleEmployee1);
                                for (let l = 0; l < total; l++) {
                                    dbStartDate = subDays(dbStartDate, 1);
                                    dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                                }
                            }
                        }

                        Item.PreStartDate = dbStartDate;
                        Item.PreEndDate = dbEndDate;
                        Item.EndDate = dbEndDate;
                        Item.StartDate = dbStartDate;

                        valuesTask[i] = Item;

                    } else if (dependencyVal === "ES") {
                        //Manual Calculation function-------- End-Start ----DependencyID 1------

                        //Get Dependent Map StartDate
                        let preEndDate = valuesTask[indexVal - 1].EndDate;
                        let LogDate = new Date();

                        if (Item.EndDateChange === 2) {
                            // ---Manual Calculation function---------((preEndDate + 1) + LogDays = StartDate))-------
                            dbEndDate = checkDateBetweenInHolidayAndWE(Item.EndDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                            dbStartDate = subDays(dbEndDate, Duration);
                            //Total Saturday,Sunday and Holiday Count
                            total = totalHolidayAndWE(dbStartDate, dbEndDate, Item.LeaveDays, Item.titleEmployee1);
                            for (let l = 0; l < total; l++) {
                                dbStartDate = subDays(dbStartDate, 1);
                                dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                            }
                            LogDate = dbStartDate;

                            if (Item.LogDays) {
                                //LogDays Add
                                LogDate = subDays(LogDate, parseInt(Item.LogDays));
                                //LogDate = checkDateBetweenInHolidayAndWE(LogDate, Item.LeaveDays, "sub");
                            }
                        }
                        LogDate.setHours(0, 0, 0, 0);
                        preEndDate.setHours(0, 0, 0, 0);
                        if (preEndDate < LogDate && Item.EndDateChange === 2) {
                        } else {
                            //Auto Calculation function
                            dbStartDate = addDays(preEndDate, 1);

                            if (Item.LogDays) {
                                //LogDays Add
                                dbStartDate = addDays(dbStartDate, parseInt(Item.LogDays));
                            }
                            //Calculation Saturday,Sunday and Holiday Count
                            dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, Item.LeaveDays, Item.titleEmployee1, "add");
                            dbEndDate = addDays(dbStartDate, Duration);
                            //Total Saturday,Sunday and Holiday Count
                            total = totalHolidayAndWE(dbStartDate, dbEndDate, Item.LeaveDays, Item.titleEmployee1);
                            for (let l = 0; l < total; l++) {
                                dbEndDate = addDays(dbEndDate, 1);
                                dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, Item.LeaveDays, Item.titleEmployee1, "add");
                            }
                        }

                        Item.PreStartDate = dbStartDate;
                        Item.PreEndDate = dbEndDate;

                        Item.EndDate = dbEndDate;
                        Item.StartDate = dbStartDate;
                        valuesTask[i] = Item;

                    } else if (dependencyVal === "EE") {

                        //---- End-End --------DependencyID 4-----------((preEndDate) + LogDays = EndDate))-------
                        //Get Dependent Map StartDate
                        let preEndDate = valuesTask[indexVal - 1].EndDate;
                        let LogDate = preEndDate;


                        if (isEndDate === true && Item.EndDateChange !== 2) {
                            if (Item.LogDays) {
                                //LogDays Add
                                LogDate = addDays(LogDate, parseInt(Item.LogDays));
                            }
                            LogDate.setHours(0, 0, 0, 0);
                            let newDate = checkDateBetweenInHolidayAndWE(new Date(Item.EndDate), Item.LeaveDays, Item.titleEmployee1, "add");
                            newDate.setHours(0, 0, 0, 0);
                            if (LogDate <= newDate) {
                                let LastDate = checkDateBetweenInHolidayAndWE(new Date(Item.EndDate), Item.LeaveDays, Item.titleEmployee1, "add");
                                let diffDayCount = diffDays(new Date(Item.StartDate), LastDate);
                                let holidayTotal = totalHolidayAndWE(Item.StartDate, LastDate, Item.LeaveDays, Item.titleEmployee1);
                                diffDayCount = diffDayCount - holidayTotal;
                                Item.Duration = diffDayCount;
                                dbStartDate = Item.StartDate;
                                dbEndDate = LastDate;
                            } else {
                                dbStartDate = Item.StartDate;
                                dbEndDate = Item.PreEndDate;
                                Item.EndDate = Item.PreEndDate;
                            }
                        } else {

                            if (Item.EndDateChange === 2) {
                                // ---Manual Calculation function------
                                dbEndDate = checkDateBetweenInHolidayAndWE(Item.EndDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                                dbStartDate = subDays(dbEndDate, Duration);
                                //Total Saturday,Sunday and Holiday Count
                                total = totalHolidayAndWE(dbStartDate, dbEndDate, Item.LeaveDays, Item.titleEmployee1);
                                for (let l = 0; l < total; l++) {
                                    dbStartDate = subDays(dbStartDate, 1);
                                    dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                                }
                                LogDate = preEndDate;

                                if (Item.LogDays) {
                                    //LogDays Add
                                    LogDate = addDays(LogDate, parseInt(Item.LogDays));
                                    //LogDate = checkDateBetweenInHolidayAndWE(LogDate, Item.LeaveDays, "sub");
                                }
                            }
                            LogDate.setHours(0, 0, 0, 0);
                            dbEndDate.setHours(0, 0, 0, 0);
                            if (LogDate <= dbEndDate && Item.EndDateChange === 2) {
                            } else {
                                //Auto Calculation function
                                if (Item.LogDays) {
                                    //LogDays Add
                                    dbEndDate = addDays(preEndDate, parseInt(Item.LogDays));
                                    dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, Item.LeaveDays, Item.titleEmployee1, "add");
                                } else {
                                    dbEndDate = checkDateBetweenInHolidayAndWE(preEndDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                                }
                                dbStartDate = subDays(dbEndDate, Duration);
                                //Total Saturday,Sunday and Holiday Count
                                total = totalHolidayAndWE(dbStartDate, dbEndDate, Item.LeaveDays, Item.titleEmployee1);
                                for (let l = 0; l < total; l++) {
                                    dbStartDate = subDays(dbStartDate, 1);
                                    dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                                }
                            }
                        }

                        Item.EndDate = dbEndDate;
                        Item.StartDate = dbStartDate;
                        Item.PreStartDate = dbStartDate;
                        Item.PreEndDate = dbEndDate;

                        valuesTask[i] = Item;
                    }
                }
            }
        });
        setIsTaskFieldChange(valuesTask);
    },
        [CalculationValue.isDuration, CalculationValue.isEndDate, CalculationValue.isLoad]);


    function isSameDay(d1, d2) {
        return d1.getFullYear() === d2.getFullYear() &&
            d1.getDate() === d2.getDate() &&
            d1.getMonth() === d2.getMonth();
    }

    function diffDays(date, otherDate) {
        return Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24)) + 1;
    }

    function addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    function subDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() - days);
        return result;
    }

    function totalHolidayAndWE(startDate, endDate, leaveDays, taskHolderName) {
        let totalSaturday = 0;
        let totalSunday = 0;
        let totalHoliday = 0;
        let total = 0;
        let format = "MM/DD/YYYY";
        // Saturday,Sunday and Holiday Count
        var currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            if (currentDate.getDay() === 0) {
                totalSunday++;
            }
            if (currentDate.getDay() === 6) {
                totalSaturday++;
            }
            if (leaveDays === 2 || leaveDays === 5 || leaveDays === 6 || leaveDays === 8) {
                var newDate = new Date(moment(currentDate, "DD/MM/YYYY").format("MM/DD/YYYY"));
                let holidayList = genTrimTNAHolidayList && genTrimTNAHolidayList.filter(d => d.taskHolderName === taskHolderName);

                let HolidayCount = holidayList && holidayList.filter(d => new Date(moment(d.holidayDate, format)) <= newDate && newDate <= new Date(moment(d.holidayEndDate, format))).length;
                if (HolidayCount > 0) {
                    totalHoliday++;
                }
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }
        // Check LeaveDays base Saturday,Sunday and Holiday Count
        if (leaveDays === 2) {
            total = totalSaturday + totalSunday + totalHoliday;
        } else if (leaveDays === 3) {
            total = totalSaturday + totalSunday;
        } else if (leaveDays === 4) {
            total = totalSunday;
        } else if (leaveDays === 5) {
            total = totalHoliday;
        } else if (leaveDays === 6) {
            total = totalSunday + totalHoliday;
        } else if (leaveDays === 7) {
            total = totalSaturday;
        } else if (leaveDays === 8) {
            total = totalSaturday + totalHoliday;
        }
        return total;
    }

    function checkDateBetweenInHolidayAndWE(flyDate, leaveDays, taskHolderName, status) {
        let format = "MM/DD/YYYY";
        let checkDate = new Date(moment(flyDate, "DD/MM/YYYY").format("MM/DD/YYYY"));
        let holidayList = genTrimTNAHolidayList && genTrimTNAHolidayList.filter(d => d.taskHolderName === taskHolderName);
        let HolidayCount = holidayList && holidayList.filter(d => new Date(moment(d.holidayDate, format)) <= checkDate && checkDate <= new Date(moment(d.holidayEndDate, format))).length;
        //ReCheck Date in Saturday,Sunday and Holiday Count
        if (flyDate.getDay() === 0 || flyDate.getDay() === 6 || HolidayCount > 0) {
            let i = 0;
            while (i <= 3) {
                //Weekend Date Check
                if (flyDate.getDay() === 0 && (leaveDays === 2 || leaveDays === 3 || leaveDays === 4 || leaveDays === 6)) {
                    status === "sub" ? flyDate = subDays(flyDate, 1) : flyDate = addDays(flyDate, 1);
                } else {
                    i++;
                }
                if (flyDate.getDay() === 6 && (leaveDays === 2 || leaveDays === 3 || leaveDays === 7 || leaveDays === 8)) {
                    status === "sub" ? flyDate = subDays(flyDate, 1) : flyDate = addDays(flyDate, 1);
                } else {
                    i++;
                }

                if (leaveDays === 2 || leaveDays === 5 || leaveDays === 6 || leaveDays === 8) {
                    let checkDate = new Date(moment(flyDate, "DD/MM/YYYY").format("MM/DD/YYYY"));
                    let HolidayCount = holidayList && holidayList.filter(d => new Date(moment(d.holidayDate, format)) <= checkDate && checkDate <= new Date(moment(d.holidayEndDate, format))).length;
                    if (HolidayCount > 0) {
                        status === "sub" ? flyDate = subDays(flyDate, 1) : flyDate = addDays(flyDate, 1);
                    } else {
                        i++;
                    }
                } else {
                    i++;
                }

                i === 3 ? i = 4 : i = 0;
            }
        }
        return flyDate;
    }

    //    ------------- **** Calculation End ***** --------------------------

    //#end region --- Edit Task ----

    const CustomInput = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                menuPosition="fixed"
                placeholder="DD/MM/YYYY"
            />
        )
    }

    const ManualCloseCustomInput = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                menuPosition="fixed"
                placeholder="DD/MM/YYYY"
                style={{ width: "100%", border: (!getComment) || ManualInfo[0].manualCloseDate ? '' : '1px solid red' }}
            />
        )

    }
    let styles1 = {
        control: styles1 => ({ ...styles1, width: "230px" }),
    }

    let styles2 = {
        control: styles2 => ({ ...styles2, width: "230px", border: '1px solid red' }),
    }
    const handlemultidd = (event, index) => {
        ;
        if (event.length === 0) {
            values[0].SendTo = "";
            values[0].SendToEdit = '';
            // values[index].SkuColorNameIds = "";
        } else {
            let strValue = "";
            for (let Sku of event) {
                if (strValue === "") {
                    strValue = Sku.value.toString();
                } else {
                    strValue = strValue + "," + Sku.value;
                }
            }
            values[0].SendTo = strValue;
            values[0].SendToEdit = event;
        }

        setInputFields(values);
    }

    const handleChangeSubmissionMain = (event, feild) => {
        ;
        let input = '';

        if (feild === "SubmittedDate") {
            values[0].SubmittedDate = event;
        }
        else if (feild === "Sendthrough") {
            if (event.target.value !== '') {
                input = event.target.value;
            }
            values[0].SendThrough = parseInt(input);
            if (input === "2") {
                setshowawb(true);
            }
            else if (input === "1") {
                values[0].AWBDateandNo = ''
                values[0].AWBDate = ''
                values[0].AWBName = ''
                setSubmittedawb(false)
                setshowawb(false);
            }
            else {
                setshowawb(false);
            }
        }
        else if (feild === "SendTo") {
            if (event.target.value !== '') {
                input = event.target.value;
            }
            values[0].SendTo = input;
        }
        else if (feild === "Remarks") {
            if (event.target.value !== '') {
                input = event.target.value;
            }
            values[0].Remarks = input;
        }
        else if (feild === "TrimDimensionId") {
            if (event.target.value !== '') {
                input = event.target.value;
            }
            values[0].TrimDimensionId = input;
        }
        setInputFields(values);
    }


    const handleSubmitoptionchange = (event, feild, index) => {
        let input = '';
        if (event.target.value !== '') {
            input = event.target.value;
        }
        if (feild === "OptionName") {
            submittedoption[index].OptionName = input;
        }
        else if (feild === "SupplierReferenceNo") {
            submittedoption[index].SupplierReferenceNo = input;
        }
        if (submittedoption[index].OptionName !== '' && submittedoption[index].SupplierReferenceNo !== '') {
            let Existoption = submittedoption.filter(x =>
                x.OptionName.toLowerCase() === submittedoption[index].OptionName.toLowerCase()
                && x.SupplierReferenceNo === submittedoption[index].SupplierReferenceNo.toLowerCase())
            if (Existoption.length > 1) {
                submittedoption[index].SupplierReferenceNo = '';
                setSubmitted(true);
                Nodify('Warning!', 'warning', 'SupplierReferenceNo Already Exists.');
            }
        }
        setInputFields(values);

    }
    const handleRemoveFields = index => {
        submittedoption.splice(index, 1);
        setInputFields(values);
    };
    const handleAddField = (index) => {
        var isvalid = true;

        inputFields.forEach((element, index) => {
            if (submittedoption[index].OptionName === '' || submittedoption[index].SupplierReferenceNo === '') {
                isvalid = false;
            }
        })
        if (isvalid) {
            submittedoption.push({ OptionName: '', SupplierReferenceNo: '', GeneralTrimSubmittedOptionId: 0 });
            setInputFields(values);
            setSubmitted(false);
        }
        else {
            Nodify('Warning!', 'warning', 'Please fill Option and Supplier Reference No.');
            setSubmitted(true);
        }
    }

    const UploadImages = async (e) => {
        const file = e.target.files[0];
        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png'];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#FileUpload').val("");
            }
            else {
                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "TNA");
                try {
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                    values[0].SwatchCardImageName = file.name;
                    values[0].SwatchCardIamgePath = "Images/TNAImages/" + file.name;
                    setInputFields(values);
                    $('#FileUpload').val("");

                } catch (ex) {
                    console.log(ex);
                }
            }
        }
    }


    const openFileUpload = () => {
        $('#upload-button').trigger('click');
    }

    const OnChangeOrderinfo = (event, index) => {
        ;
        const orderinfo = [...getOrderinfo];
        let SelectedCount = 0;
        let SelectedColorGroup = 0;
        orderinfo.forEach(element => {
            if (element.SelectedDetail === 1) {
                SelectedCount = SelectedCount + 1;
            }
        })
        if (event.target.checked === true) {
            if (SelectedCount === 1) {
                orderinfo[index].SelectedDetail = 0;
                Nodify('Warning!', 'warning', 'Please select only one.');
            }
            else {
                orderinfo[index].SelectedDetail = 1;
            }
        }
        else {
            orderinfo[index].SelectedDetail = 0;
        }
        setOrderinfo(orderinfo);

        let SelectedDetail = '';
        let GenTrimMainId = 0;
        orderinfo.forEach(element => {
            if (element.SelectedDetail === 1) {
                SelectedDetail = element.trimRefernceId;
                GenTrimMainId = element.genTrimMainId;
                SelectedColorGroup = element.colorGroupId;
            }
        })

        GeneralService.GetGeneralTrimDimensionList(GenTrimMainId).then((response) => {
            if (response.data) {
                setTrimDimension(response.data);
            }
        }).catch(() => { });

        values[0].TrimDimensionId = SelectedDetail;
        values[0].ColorGroupId = SelectedColorGroup;

        setInputFields(values);
    }




    const handleRemoveOrderinfo = (index) => {
        const value = [...getOrderinfo]
        value.splice(index, 1);
        setOrderinfo(value);

    }

    const SaveSubmission = () => {
        ;
        let CheckSubmittedOption = true;

        submittedoption.forEach(element => {
            if (element.OptionName === '' || element.SupplierReferenceNo === '') {
                CheckSubmittedOption = false;
            }
        })

        if (values[0].SubmittedDate === '' || values[0].SubmittedDate === null || values[0].SendTo === "" || values[0].SendThrough === 0 ||
            values[0].TrimDimensionId === "0") {
            Nodify("Warning!", "warning", "Please fill the mandatory(*) fields.");
            setSubmittedpopup(true);
            // setSubmitted(true);
        }
        else if (CheckSubmittedOption === false) {
            Nodify("Warning!", "warning", "Please fill the mandatory(*) fields in Submitted Option Section.");
            setSubmitted(true);
        }
        // else if ((parseInt(values[0].SendThrough) === 2 && values[0].AWBDateandNo === '')) {
        //     Nodify("Warning!", "warning", "Please fill Awb Name & Date.");
        // }

        else {
            values[0].SubmittedDate = new Date(values[0].SubmittedDate.setMinutes(values[0].SubmittedDate.getMinutes() + 370))
            //values[0].ApprovalDate = new Date(values[0].ApprovalDate.setMinutes(values[0].ApprovalDate.getMinutes() + 370))
            if (values[0].AWBDate !== '') {
                values[0].AWBDate = new Date(values[0].AWBDate.setMinutes(values[0].AWBDate.getMinutes() + 370))
            }
            values[0].Operation = 1;
            values[0].GeneralTNATrimOrderInfo = getOrderinfo;
            values[0].GeneralTNATrimSubmittedOption = [];
            getOrderinfo.map(ordinf => {
                submittedoption.map(opt => {
                    values[0].GeneralTNATrimSubmittedOption.push({
                        GeneralTrimSubmittedOptionId: opt.GeneralTrimSubmittedOptionId,
                        GenralTrimTNASubmissionAndApprovalId: ordinf.genTrimTNASubmissionAndApprovalId,
                        GenTrimId: ordinf.genTrimId,
                        GenTrimLogoId: ordinf.genTrimLogoID,
                        GenTrimRefernceId: ordinf.genTrimRefernceID,
                        OptionName: opt.OptionName,
                        SupplierReferenceNo: opt.SupplierReferenceNo

                    })
                })
            })

            GeneralService.InsertUpdateGeneralTrimSubmissionApproval(values[0]).then(res => {
                if (res.data.outputResult === "1") {
                    Nodify('Success!', 'success', 'Task Submission Added Successfully');
                    setshowSubmission(false);
                    //searchTrim();
                    //handleToggle(currentExpand.currindex, currentExpand.currTrimId, currentExpand.currColorGroupID, true);
                    ReloadSubmission();
                    searchTrim();
                } else if (res.data.outputResult === "2") {
                    Nodify('Success!', 'success', 'Task Submission Updated Successfully');
                    setshowSubmission(false);
                    //searchTrim();
                    // handleToggle(currentExpand.currindex, currentExpand.currTrimId, currentExpand.currColorGroupID, true);
                    ReloadSubmission();
                    searchTrim();
                }
                else if (res.data.outputResult === "0") {
                    Nodify("Error!", "Error Occured!");
                }
            });
        }

    }

    const handlepopupchange = (event, feild) => {
        let input = '';

        if (feild === "AWBName") {
            if (event.target.value !== '') {
                input = event.target.value;
            }
            values[0].AWBName = input;
        }
        else if (feild === "AWBDate") {
            values[0].AWBDate = event;
        }
        setInputFields(values);
    }

    const SaveAwbnoDate = () => {
        // values[0].AWBDateandNo = values[0].AWBName + " / " + values[0].AWBDate;
        // setInputFields(values);
        // setShowPopup(false);
        let values = [...inputFields]

        if (values[0].AWBName === "" || values[0].AWBDate === "" || values[0].AWBDate === null) {
            Nodify('Warning!', 'warning', 'Please fill all mandatory (*) fields in AWB Popup.');
            setShowPopup(true);
            setSubmittedawb(true)
        }
        else {
            values[0].AWBDateandNo = values[0].AWBName + " / " + values[0].AWBDate.toLocaleDateString('en-GB');
            setShowPopup(false);
        }
        setInputFields(values);

    }

    const SaveTNATask = (e) => {
        setButtonLoader(true);
        e.preventDefault();
        let IsValid = true;
        MainValues.GenTrimTNASubmissionAndApproval = [];
        MainValues.GenTNAAdditionalTaskDetails = [];
        valuesTask.map((Info, i) => {
            if (Info.Duration === '' || Info.TaskHolderOwnerIdList.length === 0 || Info.LeaveDays === 0) {
                IsValid = false;
                setButtonLoader(false);
                Nodify('Warning!', 'warning', "Please fill the mandatory(*) fields.");
                setSubmitted(true);
                return false;
            }
            else {

                MainValues.GenTrimTNASubmissionAndApproval.push({

                    Id: Info.GenralTrimTNASubmissionAndApprovalId,
                    TaskTypeID: Info.TaskType,
                    TaskID: Info.TaskID,
                    Duration: Info.Duration && parseInt(Info.Duration),
                    PlanStartDate: convertUTCDateToLocalDate(new Date(Info.StartDate)),
                    EndDate: convertUTCDateToLocalDate(new Date(Info.EndDate)),
                    DependencyID: Info.DependencyID,
                    LeaveDays: Info.LeaveDays,
                    LogDays: (Info.LogDays === "" || Info.LogDays === null) ? 0 : parseInt(Info.LogDays),
                    TaskOwner: Info.TaskHolderOwnerIdList,
                    TaskHolderID: Info.TaskHolderOrFollower,
                    TaskHolderIDList: Info.TaskHolderBuyer && Info.TaskHolderBuyer.toString(),
                    FollowerIDList1: Info.FollowerCube && Info.FollowerCube.toString(),
                    FollowerIDList2: Info.FollowerSupplier && Info.FollowerSupplier.toString(),
                    IndexName: i.toString(),
                    Status: 0
                })

                if (Info.FollowupDetails) {
                    Info.FollowupDetails.forEach((child, k) => {
                        MainValues.GenTNAAdditionalTaskDetails.push({
                            Id: child.genTrimTNAAddTaskId,
                            TaskId: child.taskId,
                            TaskTypeId: child.taskTypeId,
                            AdditonalTaskDetailsID: child.additonalTaskDetailsID,
                            AddtionalTaskName: child.addtionalTaskName,
                            IsTaskSelected: child.isTaskSelected,
                            IndexName: child.indexName
                        });
                    });
                }
            }
        });

        if (IsValid) {
            dispatch(UpdateGenralTrimTNA(MainValues))
                .then(data => {
                    if (data.outputResult === "2") {
                        Nodify('Success!', 'success', 'Task Updated Successfully.');
                        setShowTaskPopup(false);
                    } else if (data.outputResult === "-1") {
                    }
                    setButtonLoader(false);
                })
                .catch(e => {
                    console.log(e);
                });
        }
    }

    // const previmage = () => {
    //     if (getCurrimageindex === 0) {
    //         setCurrimageindex(0);
    //     }
    //     else {
    //         setCurrimageindex(getCurrimageindex - 1);
    //     }
    // }

    // const nextimage = () => {
    //     if (getCurrimageindex === getSubImageList.length - 1) {
    //         setCurrimageindex(getSubImageList.length - 1);
    //     }
    //     else {
    //         setCurrimageindex(getCurrimageindex + 1);
    //     }
    // }


    const TabChange = (val) => {
        setTab(val);

    }


    const CustomInputsubmit = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
                style={{
                    border: submittedpopup && props.value === '' ? '1px solid red' : ''
                }}
            />
        )
    }

    const CustomInputAWB = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
                style={{ border: submittedpopup && props.value === '' ? '1px solid red' : '' }}
            />
        )
    }

    const EndDateCustomInput = (props) => {

        return (
            <input
                className="form-control DatePickerCalenderHoliday min_width_Date"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                menuPosition="fixed"
                placeholder="DD/MM/YYYY"
            //style={{ border: submitdate && props.value === '' ? '1px solid red' : '' }}
            />
        )
    }


    //revice functionalities
    const OpenRevicePopup = (genTrimSupplierTNAId, genTrimTNASubmissionAndApprovalId, genTrimDetailTNAId, colorGroupID) => {

        GeneralService.getTNATaskDetailsList_Trim(genTrimSupplierTNAId, genTrimDetailTNAId, colorGroupID).then((response) => {
            if (response.data) {
                setTaskDetails(response.data);

                GeneralService.getTNATaskReviceList_Trim(genTrimSupplierTNAId, genTrimDetailTNAId, colorGroupID).then((response) => {
                    if (response.data) {
                        setSubReviceList(response.data);
                    }
                }).catch(() => { });

                setopenRevicepopup(true);
            }
        }).catch(() => { });

        var cmtval = { ...ReviceComment }
        cmtval.SubmissionComment = '';
        cmtval.ApprovalComment = '';
        setReviceComment(cmtval);
    }

    const handleChangeRevice = (e, index, feild) => {
        var taskval = [...TaskDetails];
        var cmtval = { ...ReviceComment }


        if (feild === "Duration") {
            taskval[index].duration = e.target.value;
        }
        else if (feild === "EndDate") {
            taskval[index].endDate = e;
        }
        else if (feild === "SubmissionComment") {
            cmtval.SubmissionComment = e.target.value;
        }
        if (feild === "ApprovalComment") {
            cmtval.ApprovalComment = e.target.value;
        }
        setTaskDetails(taskval);
        setReviceComment(cmtval);
        if (feild === "Duration" || feild === "EndDate") {
            datecalculation(index, feild)
        }
    }

    const handleSaveRevice = () => {
        if ((ReviceComment.SubmissionComment === '' || ReviceComment.ApprovalComment === '')) {
            setSubmitRevice(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            return false;
        }
        else {
            var InsertData = [];

            TaskDetails.map((item, i) => {
                InsertData.push({
                    GenTrimDetailTNAId: item.genTrimDetailTNAId,
                    GenTrimTNASubmissionAndApprovalId: item.genTrimTNASubmissionAndApprovalId,
                    GenTrimSupplierTNAId: item.genTrimSupplierTNAId,
                    Comments: i === 0 ? ReviceComment.SubmissionComment : ReviceComment.ApprovalComment,
                    Createdby: currentUser.employeeinformationID,
                    StartDate: item.startDate,
                    EndDate: item.endDate,
                    Duration: parseInt(item.duration),
                    Type: i === 0 ? "Submission" : "Approval",
                    ColorGroupID: item.colorGroupID
                })
            })

            let savecount = 0;
            InsertData.map(data => {
                GeneralService.InsertGenTNATaskRevice_Trim(data).then((res) => {
                    if (res.data.outputResult === "1") {
                        savecount = savecount + 1;
                    }
                })
            })

            //  if (savecount === 2) {
            Nodify('Success!', 'success', "Revise Added Successfully");
            setopenRevicepopup(false);
            ReloadSubmission();
            searchTrim();
            //  }


            // }

        }
    }

    const OpenReschedulePopup = (genTrimSupplierTNAId, genralLabdipTNASubmissionAndApprovalId,
        genTrimDetailTNAId, colorGroupID) => {

        GeneralService.getTNATaskDetailsList_Trim(genTrimSupplierTNAId, genTrimDetailTNAId, colorGroupID).then((response) => {
            if (response.data) {
                setTaskDetails(response.data);

                GeneralService.getTNATaskRescheduleList_Trim(genTrimSupplierTNAId, genTrimDetailTNAId, colorGroupID).then((response) => {
                    if (response.data) {
                        setSubRescheduleList(response.data);
                    }
                }).catch(() => { });

                setopenReschedulepopup(true);
            }
        }).catch(() => { });

        var cmtval = { ...RescheduleComment }
        cmtval.SubmissionComment = '';
        cmtval.ApprovalComment = '';
        setRescheduleComment(cmtval);
    }


    function datecalculation(index, FieldName) {
        var taskval = [...TaskDetails];
        let employee1 = "";
        let employee2 = "";
        let employee3 = "";
        if (taskval[index].taskHolderOrFollowerName) {
            let TaskHolderName = taskval[index].taskHolderOrFollowerName.split('-');
            if (TaskHolderName[0] !== undefined) {
                employee1 = TaskHolderName[0].trim();

                if (TaskHolderName[1] !== undefined) {
                    let FollowerName = TaskHolderName[1].split(',');
                    if (FollowerName[0] !== undefined) {
                        employee2 = FollowerName[0].trim();

                        if (FollowerName[1] !== undefined) {
                            employee3 = FollowerName[1].trim();
                        } else {
                            employee3 = "";
                        }
                    } else {
                        employee2 = "";
                        employee3 = "";
                    }

                } else {
                    employee2 = "";
                    employee3 = "";
                }
            }
        }

        let Duration = taskval[index].duration;
        let StartDate = new Date(taskval[index].startDate);
        let EndDate = new Date(taskval[index].endDate);
        let LeaveDays = taskval[index].leaveDays;
        let titleEmployee1 = employee1;
        let total = 0;
        if (FieldName === "Duration") {
            EndDate = addDays(StartDate, parseInt(Duration) - 1);
            total = totalHolidayAndWE(StartDate, EndDate, LeaveDays, titleEmployee1);
            for (let l = 0; l < total; l++) {
                EndDate = addDays(EndDate, 1);
                EndDate = checkDateBetweenInHolidayAndWE(EndDate, LeaveDays, titleEmployee1, "add");
            }
        }
        else {
            //based on end date
            let CheckDate = checkDateBetweenInHolidayAndWE(EndDate, LeaveDays, titleEmployee1, "add");
            if (!isSameDay(new Date(EndDate), CheckDate)) {
                Nodify('Warning!', 'warning', 'You select the end date to leave day');
                EndDate = CheckDate;
            }
            StartDate = subDays(EndDate, parseInt(Duration) - 1);
            total = totalHolidayAndWE(StartDate, EndDate, LeaveDays, titleEmployee1);
            for (let l = 0; l < total; l++) {
                StartDate = subDays(StartDate, 1);
                StartDate = checkDateBetweenInHolidayAndWE(StartDate, LeaveDays, titleEmployee1, "sub");

            }
        }

        taskval[index].startDate = StartDate;
        taskval[index].endDate = EndDate;
        setTaskDetails(taskval);
    }

    return (
        <Fragment>
            <div> {
                isOpen && (
                    <Lightbox
                        mainSrc={isOpen}
                        alt="bg image"

                        onCloseRequest={
                            () => setIsOpen(false)
                        } />
                )
            } </div>
            <div className="widget-body">
                <div id="registration-form">
                    <form>


                    <div className="col-sm-12">
                            
                            <div className="col-md-2">
                                <div className='form-group'>
                                    <label>Trim Code / Name</label>
                                    <span className='input-icon icon-right'>
                                        <input type="text" value={SearchReqValues.SearchTxt} onChange={event => handleSearchChange(event, "SearchTxt")} placeholder="Trim Code / Name" className="form-control" />
                                    </span>

                                </div>

                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label htmlFor="SupplierID">
                                        Supplier
                                        {/* <span className="text-danger">*</span> */}
                                    </label>
                                    <span className="input-icon icon-right">
                                        <Reactselect className="basic-single" name="SupplierId"
                                            id={
                                                "SupplierId"
                                            }
                                            isLoading={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={event => handleSearchChange(event, 'SupplierId')}
                                            value={getSupplierList.filter(function (option) {
                                                return option.value === SearchReqValues.SupplierId;

                                            })}
                                            options={getSupplierList}
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                                        ></Reactselect>

                                    </span>
                                </div>
                            </div>

                            <div className="col-md-2">
                                <div className="form-group">
                                    <label htmlFor="Status">
                                        Submit Status
                                    </label>
                                    <span className="input-icon icon-right">
                                        <Reactselect className="basic-single" name="Status"
                                            id={
                                                "Status"
                                            }
                                            isLoading={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={event => handleSearchChange(event, 'Status')}
                                            value={GLDRequestStatusList && GLDRequestStatusList.filter(function (option) {
                                                return option.value === SearchReqValues.StatusId;
                                            })}
                                            options={GLDRequestStatusList}
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                                        ></Reactselect>
                                    </span>
                                </div>
                            </div>
                        


                        
                            <div className="col-md-2">

                                <div className="form-group"> 
                                    <label >Date Range <span className="rptDateRangeSpan" title="Clicking on the text box will cause the date picker to appear and disappear">(Clicking on the text box...)</span> </label>
                                    <span className="input-icon icon-right">
                                        <input
                                            value={(SearchReqValues.stateDate[0].startDate === null ? '' : moment(SearchReqValues.stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') + ((SearchReqValues.stateDate[0].endDate === null || !moment(SearchReqValues.stateDate[0].endDate).isValid()) ? '' : moment(SearchReqValues.stateDate[0].endDate).format('DD/MM/YYYY'))}
                                            onClick={event => handleDateSelect(event, !SearchReqValues.stateDate[0].isShow)}
                                            //onBlur={event => handleDateBlur()}
                                            type="text"
                                            className={'form-control'}
                                            placeholder="Select Date"
                                        />
                                        <i className="fa fa-times" onClick={clearDateSelect} ></i>
                                    </span>
                                </div>


                                {SearchReqValues.stateDate[0].isShow &&
                                    <DateRangePicker
                                        //onChange={item => setStateDate([item.selection])}
                                        onChange={item => handlePopupDateselection(item)}
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        months={2}
                                        ranges={SearchReqValues.stateDate}
                                        showDateDisplay={false}
                                        direction="vertical"
                                        className={'TNAReportDatePicker'}

                                    />

                                }

                            </div>

                            <div className="col-md-2" style={{ paddingTop: '29px' }}>

                                <button type="button" className="btn btn-success" title="Search" onClick={event => searchTrim()}
                                >
                                    <i className="fa fa-search"></i>
                                </button>
                                &nbsp;&nbsp;
                                <button type="button" className="btn btn-danger" title="Reset" onClick={resetTrim}
                                >
                                    <i className="fa fa-close"></i>
                                </button>
                            </div>
                       
                    </div>

                        <div className="col-md-12" style={{ overflowX: "auto", minHeight: '100px' }}>
                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                <thead>
                                    <tr>
                                        <th className="fixed-column-header-first-tna" style={{ width: "35px" }}> </th>
                                        <th className="fixed-column-header-first-tna" style={{ width: "35px" }}> #</th>
                                        <th>Requested Date</th>
                                        <th>Story</th>
                                        <th>Theme</th>
                                        <th>Supplier</th>
                                        <th>Details</th>
                                        {/* <th>Task Holder / Follower</th> */}
                                        <th>Submit Target</th>
                                        <th>Submit Status</th>
                                        <th>AWB & Date</th>
                                        <th className="text-center fixed-column-header-Route" style={{ width: "180px" }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        TrimSubmissionList && TrimSubmissionList.length > 0 ?
                                            <>
                                                {
                                                    TrimSubmissionList && TrimSubmissionList.map((inputField, index) => (
                                                        <Fragment key={`${inputField}~${index}`}>
                                                            <tr class="parent" data-child={"parent_" + index}>
                                                                <td className="fixed-column-first-tna">

                                                                    {
                                                                        <span id={"btn-toggle1_" + index} class="btn-toggle1 parentExpandRequestTNA" aria-expanded="false" onClick={() => handleToggle(index, inputField.trimId, inputField.colorGroupID, false)} aria-labelledby="btn-toggle1 id-master">
                                                                            <i id={"icon-toggle1_" + index} class="fa fa-plus-square-o clsPointer clscollapseexpandRequestTNA"></i>
                                                                        </span>
                                                                    }

                                                                </td>
                                                            <td>{index+1}</td>
                                                                <td>
                                                                    <span className='input-icon icon-right'>
                                                                        {
                                                                            inputField.trim
                                                                        }
                                                                    </span>
                                                                </td>
                                                                <td className="borderRightLeftNone" colSpan="7">

                                                                    <div className="trwidth_manual" style={{ textAlign: "center" }}>
                                                                        <img className="width_manual"
                                                                            src={inputField.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images/Style/Trim/" + inputField.imagePath}
                                                                            alt="Logo"
                                                                            onClick={
                                                                                () => setIsOpen(inputField.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images/Style/Trim/" + inputField.imagePath)
                                                                            }
                                                                        />
                                                                    </div>

                                                                </td>
                                                                <td className="fixed-column-Route"></td>
                                                            </tr>
                                                            {
                                                                inputField.trimSubmitApprovalReferenceList && inputField.trimSubmitApprovalReferenceList.map((subField, subIndex) => (

                                                                    <Fragment key={`${subIndex}~${subIndex}`}>
                                                                        <tr class={"child_" + index + " parentChild_" + index + "_" + subIndex} >
                                                                            <td></td>
                                                                            <td className="fixed-column-first-tna" style={{paddingLeft:"16px"}}>
                                                                                {subIndex + 1}
                                                                                {/* {JSON.stringify(subField)} */}
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {moment(subField.submitDate).format('DD/MM/YYYY')} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.storyName} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.themeName} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.supplierName} </span>
                                                                                }
                                                                            </td>

                                                                            <td>
                                                                                {
                                                                                    <span> {subField.details} </span>
                                                                                }
                                                                            </td>
                                                                            {/* <td className="cut_text" title={subField.followerName}>
                                                                                {
                                                                                    <span> {subField.followerName} </span>
                                                                                }
                                                                            </td> */}
                                                                            <td>
                                                                                {
                                                                                    <span> {moment(subField.submitTarget).format('DD/MM/YYYY')} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.submitStatus} </span>
                                                                                }
                                                                            </td>

                                                                            <td>
                                                                                {
                                                                                    <span> {subField.awbName} - {subField.awbDate === null ? '' : moment(subField.awbDate).format('DD/MM/YYYY')} </span>
                                                                                }
                                                                            </td>



                                                                            <td className="fixed-column-Route">




                                                                                {


                                                                                    TrimRequestTab !== undefined && TrimRequestTab.isAdd !== 0 &&
                                                                                        subField.manualCloseComment === '' && subField.submitStatus === "Pending" ?
                                                                                        <Fragment>&nbsp;<button type="button" className="btn btn-xs btn-darkorange" title="Manual Close"
                                                                                            onClick={() => handleManualclose(subField.genTrimTNASubmissionAndApprovalId, subField.submitStatus,
                                                                                                subField.buyerId, subField.brandId, subField.seasonId, subField.supplierId,
                                                                                                subField.follower, subField.taskID, subField.trimId, subField.genTrimLogoID, subField.genTrimId, index
                                                                                            )}
                                                                                        >
                                                                                            <i className="fa fa-user"></i>&nbsp;
                                                                                            <i className="fa fa-close"></i>
                                                                                        </button>
                                                                                        </Fragment>
                                                                                        : ''

                                                                                }
                                                                                {/* &nbsp;
                                                                                {
                                                                                    // new Date() > new Date(subField.EndDate) &&
                                                                                    subField.isRescheduled === 1 &&
                                                                                    <button type="button" className={subField.isRescheduled === 0 ? "btn btn-xs btn-light" : "btn btn-xs btn-success"} title='Child Reschedule '
                                                                                        onClick={() => OpenReschedulePopup(subField.genTrimSupplierTNAId, subField.genTrimTNASubmissionAndApprovalId
                                                                                            , subField.genTrimDetailTNAId, subField.colorGroupId)}
                                                                                    >
                                                                                        <i class="fa fa-calendar"></i>
                                                                                    </button>
                                                                                } */}
                                                                                &nbsp;
                                                                                {
                                                                                    TrimRequestTab !== undefined && TrimRequestTab.isAdd !== 0 &&
                                                                                        subField.isShowSubmitButton === 0 && subField.manualCloseComment === '' ?
                                                                                        <button type="button" className="btn btn-xs btn-warning" title="Submit Form"
                                                                                            onClick={() => OpenTrimSubmssionpopup(subField.genTrimTNASubmissionAndApprovalId, subField.submitStatus,
                                                                                                subField.buyerId, subField.brandId, subField.seasonId, subField.supplierId,
                                                                                                subField.follower, subField.taskID, subField.trimId, subField.genTrimLogoID, subField.genTrimId,
                                                                                                subField.genTrimMainId, index
                                                                                            )}

                                                                                        >
                                                                                            <i className="fa fa-send"></i>
                                                                                        </button> : ''


                                                                                }
                                                                                &nbsp;
                                                                                {
                                                                                    // new Date() > new Date(subField.EndDate) &&
                                                                                    subField.submitStatus === "Pending" &&
                                                                                    <button type="button" className={subField.isReviced === 0 ? "btn btn-xs btn-yellow" : "btn btn-xs btn-success"} title='Child Revise'
                                                                                        onClick={() => OpenRevicePopup(subField.genTrimSupplierTNAId, subField.genTrimTNASubmissionAndApprovalId
                                                                                            , subField.genTrimDetailTNAId, subField.colorGroupId)}
                                                                                    >
                                                                                        <i className="fa fa-retweet"></i>
                                                                                    </button>
                                                                                }
                                                                                &nbsp;
                                                                                {TrimRequestTab !== undefined && TrimRequestTab.isEdit !== 0 &&
                                                                                    <button type="button" className="btn btn-info btn-xs edit" title="Edit Request"
                                                                                        onClick={() => OpenTrimEditPopup(subField.genTrimSupplierTNAId, subField.genTrimDetailTNAId, subField.supplierId)}
                                                                                        disabled={subField.submitStatus !== "Pending" ? true : false}

                                                                                    >
                                                                                        <i className="fa fa-edit"></i>
                                                                                    </button>
                                                                                }
                                                                                &nbsp;
                                                                                {TrimRequestTab !== undefined && TrimRequestTab.isDelete !== 0 &&
                                                                                    <button type="button" title='Delete Request'
                                                                                        className="btn btn-danger btn-xs delete" onClick={() => pageRedirect(subField, index)}
                                                                                        disabled={subField.submitStatus !== "Pending" ? true : false}
                                                                                    >
                                                                                        <i className="fa fa-trash-o"></i>

                                                                                    </button>
                                                                                }






                                                                            </td>
                                                                        </tr>
                                                                        {
                                                                            inputField.criticalList && inputField.criticalList.map((Crit) => (
                                                                                subField.programIndexName === Crit.programIndexName ?
                                                                                    <tr class={"child_" + index + " parentChild_" + index + "_" + subIndex} >
                                                                                        <td colSpan="10">
                                                                                            <span style={{ color: 'red' }}>Critical : {Crit.criticalComment}</span>,&nbsp;
                                                                                            <span style={{ color: '#427fed' }}>Target to Close : {new Date(Crit.targettoClose).toLocaleDateString()}</span>,&nbsp;
                                                                                            <span style={{ color: '#00a65a' }}>Completed On : {Crit.completedOn !== '' ? new Date(Crit.completedOn).toLocaleDateString() : '-'}</span>
                                                                                        </td>

                                                                                    </tr>
                                                                                    : ''
                                                                            ))
                                                                        }


                                                                    </Fragment>
                                                                ))
                                                            }

                                                        </Fragment>
                                                    ))
                                                }
                                            </>
                                            :

                                            <tr><td colSpan="11" className='norecordfound'><span>No Records Found</span></td></tr>

                                    }
                                </tbody>
                            </table>
                            {
                                //Task Edit Popup
                                showTaskPopup === true ?
                                    <Modal show={showTaskPopup === true} dialogClassName="modal-90w" dragable backdrop="static" keyboard={false} onHide={() => handleTaskPopupClose()} >
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                Edit TNA
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="col-md-12" style={{ overflowX: "auto", minHeight: '100px' }}>

                                                <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable" style={{ width: "130%" }}>
                                                    <thead>
                                                        <tr>

                                                            <th style={{ width: "450px" }}>Task Type</th>
                                                            <th style={{ width: "800px" }}>Task</th>
                                                            <th style={{ width: "100px" }}>Duration<span className="text-danger">*</span></th>
                                                            <th style={{ width: "450px" }}>Start Date</th>
                                                            <th style={{ width: "450px" }}>End Date</th>
                                                            <th style={{ width: "450px" }}>Dependency</th>
                                                            <th style={{ width: "450px" }}>Log Days</th>
                                                            <th style={{ width: "320px" }}>Task Holder / Follower<span className="text-danger">*</span></th>
                                                            <th style={{ width: "280px" }}>Leave Days<span className="text-danger">*</span></th>
                                                            <th style={{ width: "200px" }} className="text-center fixed-column-header-Route">Action</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            valuesTask.map((inputField, index) => (
                                                                <Fragment>
                                                                    <tr>
                                                                        <td>
                                                                            <span>
                                                                                {inputField.TaskTypeName}

                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <span>
                                                                                {inputField.TaskName}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <span className='input-icon icon-right'>
                                                                                <input type="text"
                                                                                    placeholder="Duration"
                                                                                    id={"Duration" + index}
                                                                                    name="Duration"
                                                                                    value={inputField.Duration}
                                                                                    onChange={event => handleTaskChange(event, index, "Duration")}
                                                                                    // onChange={event => handleChange(event, index, "Duration")}
                                                                                    // onBlur={() => CheckDuplicate(index, 'Duration')}
                                                                                    maxLength="3"
                                                                                    className="form-control"
                                                                                    style={{ border: submitted && !inputField.Duration ? '1px solid red' : '' }}
                                                                                />
                                                                            </span>
                                                                        </td>
                                                                        <td>

                                                                            <span className="input-icon icon-right">
                                                                                <DatePicker className="form-control"
                                                                                    name="StartDate"
                                                                                    id={
                                                                                        "StartDate" + index
                                                                                    }
                                                                                    selected={
                                                                                        inputField.StartDate
                                                                                    }
                                                                                    dateFormat="dd/MM/yyyy"
                                                                                    readOnly={true}

                                                                                />

                                                                            </span>
                                                                        </td>
                                                                        <td>

                                                                            <span className="input-icon icon-right">
                                                                                <DatePicker className="form-control"
                                                                                    name="EndDate"
                                                                                    id={
                                                                                        "EndDate" + index
                                                                                    }
                                                                                    selected={
                                                                                        inputField.EndDate
                                                                                    }
                                                                                    onChange={
                                                                                        event => handleTaskChange(event, index, "EndDate")
                                                                                    }
                                                                                    dateFormat="dd/MM/yyyy"
                                                                                    peekNextMonth
                                                                                    showMonthDropdown
                                                                                    showYearDropdown
                                                                                    dropdownMode="scroll"
                                                                                    autoComplete="off"
                                                                                    popperProps={{
                                                                                        positionFixed: true // use this to make the popper position: fixed
                                                                                    }}
                                                                                    //menuPosition="fixed"
                                                                                    // minDate={inputField.HolidayDate === "" ? (new Date("01/01/" + getselectedDate)) : inputField.HolidayDate}
                                                                                    //maxDate={new Date("12/31/" + getselectedDate)}
                                                                                    customInput={<CustomInput />}
                                                                                // isClearable={inputField.EndDate === '' ? false : true}
                                                                                />

                                                                            </span>
                                                                        </td>
                                                                        <td>

                                                                            <span className='input-icon icon-right'>
                                                                                {index !== 0 &&
                                                                                    <Reactselect className="basic-single" name="DependencyID"
                                                                                        id={
                                                                                            "DependencyID" + index
                                                                                        }
                                                                                        isLoading={false}
                                                                                        isClearable={true}
                                                                                        isSearchable={true}
                                                                                        onChange={event => handleTaskChange(event, index, "DependencyID")}
                                                                                        value={getDependencyList.filter(function (option) {
                                                                                            return option.value === inputField.DependencyID;
                                                                                        })}
                                                                                        options={getDependencyList}
                                                                                    ></Reactselect>
                                                                                }

                                                                            </span>

                                                                        </td>
                                                                        <td>
                                                                            <span className='input-icon icon-right'>
                                                                                {index !== 0 &&
                                                                                    <input type="text"
                                                                                        placeholder="Log days"
                                                                                        id={"LogDays" + index}
                                                                                        name="LogDays"
                                                                                        className="form-control"
                                                                                        onChange={event => handleTaskChange(event, index, "LogDays")}
                                                                                        value={inputField.LogDays}
                                                                                    // onChange={event => handleChildChange(event, index, childIndex, "LogDays")}
                                                                                    // maxLength="3"
                                                                                    // className={'form-control' + (
                                                                                    //     submitted && !inputField.LogDays ? ' is-invalid' : ''
                                                                                    // )}
                                                                                    // disabled={ChildField.DependencyID === 0 ? true : false}
                                                                                    />
                                                                                }
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <span
                                                                                className={'form-control'} onClick={() => handleTaskHolderLeaveDays(index, inputField.IsTHFirstTime)}
                                                                                style={{ width: "100%", paddingTop: "8px", float: "left", border: submitted && !inputField.TaskHolderOwnerIdList ? '1px solid red' : '' }}
                                                                            >
                                                                                {
                                                                                    getTaskHolderList.filter(d => d.value === inputField.TaskHolderOrFollower).map(task => {
                                                                                        return task.label;
                                                                                    })
                                                                                }
                                                                            </span>



                                                                        </td>
                                                                        <td>
                                                                            <input type="text"
                                                                                value={inputField.LeaveDaysTxt}
                                                                                title={inputField.LeaveDaysTxt}
                                                                                onClick={() => handleTaskHolderLeaveDays(index, inputField.IsTHFirstTime)}
                                                                                className={'form-control'}
                                                                                // style={{ width: "100%", float: "left" }}
                                                                                style={{ width: "100%", float: "left", border: submitted && !inputField.LeaveDaysTxt ? '1px solid red' : '' }}
                                                                            //styles={submitted && inputField.LeaveDays === 0 ? styles : ''}
                                                                            />

                                                                        </td>

                                                                        <td className="fixed-column-Route">

                                                                            {
                                                                                inputField.IsTHLeaveDaysOpen === 1 &&

                                                                                <Modal show={inputField.IsTHLeaveDaysOpen === 1} size="lg" backdrop="static" keyboard={false} onHide={() => handleTHLeaveDaysSave(index)}>
                                                                                    <Modal.Header closeButton>
                                                                                        <Modal.Title>
                                                                                            Leave Days / In-charges - {inputField.TaskName}
                                                                                        </Modal.Title>
                                                                                    </Modal.Header>
                                                                                    <Modal.Body>
                                                                                        <div className="row">
                                                                                            <div className="col-md-12">
                                                                                                <div className='form-group'>
                                                                                                    <label> Task Holder / Follower </label>
                                                                                                    <span className='input-icon icon-right'>
                                                                                                        <Reactselect className="basic-single" name="TaskHolderOrFollower"
                                                                                                            id={"TaskHolderOrFollower"}
                                                                                                            isLoading={false}
                                                                                                            isClearable={true}
                                                                                                            isSearchable={true}
                                                                                                            onChange={event => handleTaskChange(event, index, "TaskHolderOrFollower")}
                                                                                                            value={getTaskHolderList.filter(function (option) {
                                                                                                                return option.value === inputField.TaskHolderOrFollower;
                                                                                                            })}
                                                                                                            options={getTaskHolderList}
                                                                                                            styles={submitted && inputField.TaskHolderOrFollower === 0 ? styles : ''}
                                                                                                            menuPosition="fixed"
                                                                                                            filterOption={filterOption}
                                                                                                        ></Reactselect>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col-md-12">
                                                                                                <div className='form-group'>
                                                                                                    <label>Leave Days</label>
                                                                                                    <span className='input-icon icon-right'>
                                                                                                        <Reactselect className="basic-single" name="LeaveDays"
                                                                                                            id={
                                                                                                                "LeaveDays"
                                                                                                            }
                                                                                                            isLoading={false}
                                                                                                            isClearable={true}
                                                                                                            isSearchable={true}
                                                                                                            onChange={event => handleTaskChange(event, index, "LeaveDays")}
                                                                                                            value={getLeaveDaysList.filter(function (option) {
                                                                                                                return option.value === inputField.LeaveDays;
                                                                                                            })}
                                                                                                            options={getLeaveDaysList}
                                                                                                            styles={submitted && inputField.LeaveDays === 0 ? styles : ''}
                                                                                                            menuPosition="fixed"

                                                                                                        ></Reactselect>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {inputField.titleEmployee1 && <div className="row">
                                                                                            <div className="col-md-12">
                                                                                                <div className='form-group'>
                                                                                                    <label htmlFor={inputField.titleEmployee1 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee1 === "Cube" ? "FollowerCube" : "FollowerSupplier"} >Task Holder - {inputField.titleEmployee1 + " Employee"}<span className="text-danger">*</span> </label>
                                                                                                    <span className='input-icon icon-right'>
                                                                                                        <Reactselect className="basic-single" name={inputField.titleEmployee1 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee1 === "Cube" ? "FollowerCube" : "FollowerSupplier"}
                                                                                                            isDisabled={false}
                                                                                                            isLoading={false}
                                                                                                            isClearable={true}
                                                                                                            isSearchable={true}
                                                                                                            isMulti
                                                                                                            onChange={event => handleTaskChange(event, index, inputField.titleEmployee1 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee1 === "Cube" ? "FollowerCube" : "FollowerSupplier")}
                                                                                                            options={inputField.titleEmployee1 === "Buyer" ? drpGLDTNATaskOwnerList.buyerEmployeeList : inputField.titleEmployee1 === "Cube" ? drpGLDTNATaskOwnerList.cubeEmployeeList : drpGLDTNATaskOwnerList.supplierEmployeeList}
                                                                                                            value={inputField.titleEmployee1 === "Buyer" ?
                                                                                                                inputField.arrTaskHolderBuyer : inputField.titleEmployee1 === "Cube" ? inputField.arrFollowerCube : inputField.arrFollowerSupplier}
                                                                                                        >  </Reactselect>

                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        }

                                                                                        {
                                                                                            inputField.titleEmployee2 && <div className="row">
                                                                                                <div className="col-md-12">
                                                                                                    <div className='form-group'>
                                                                                                        <label htmlFor={inputField.titleEmployee2 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee2 === "Cube" ? "FollowerCube" : "FollowerSupplier"} >Follower - {inputField.titleEmployee2 + " Employee"}</label>
                                                                                                        <span className='input-icon icon-right'>
                                                                                                            <Reactselect className="basic-single" name={inputField.titleEmployee2 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee2 === "Cube" ? "FollowerCube" : "FollowerSupplier"}
                                                                                                                isDisabled={false}
                                                                                                                isLoading={false}
                                                                                                                isClearable={true}
                                                                                                                isSearchable={true}
                                                                                                                isMulti
                                                                                                                onChange={event => handleTaskChange(event, index, inputField.titleEmployee2 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee2 === "Cube" ? "FollowerCube" : "FollowerSupplier")}
                                                                                                                options={inputField.titleEmployee2 === "Buyer" ? drpGLDTNATaskOwnerList.buyerEmployeeList : inputField.titleEmployee2 === "Cube" ? drpGLDTNATaskOwnerList.cubeEmployeeList : drpGLDTNATaskOwnerList.supplierEmployeeList}
                                                                                                                value={inputField.titleEmployee2 === "Buyer" ? inputField.arrTaskHolderBuyer : inputField.titleEmployee2 === "Cube" ? inputField.arrFollowerCube : inputField.arrFollowerSupplier}
                                                                                                            // options={IMPORTANCE}
                                                                                                            // value={getStyleList.arrImportance}
                                                                                                            //  styles={submitted && getStyleList.arrImportance === 0 ? styles : ''}
                                                                                                            >  </Reactselect>

                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                        {inputField.titleEmployee3 && <div className="row">
                                                                                            <div className="col-md-12">
                                                                                                <div className='form-group'>
                                                                                                    <label htmlFor="FollowerSupplier" >Follower - {inputField.titleEmployee3}</label>
                                                                                                    <span className='input-icon icon-right'>
                                                                                                        <Reactselect className="basic-single" name={inputField.titleEmployee3 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee3 === "Cube" ? "FollowerCube" : "FollowerSupplier"}
                                                                                                            isDisabled={false}
                                                                                                            isLoading={false}
                                                                                                            isClearable={true}
                                                                                                            isSearchable={true}
                                                                                                            isMulti
                                                                                                            onChange={event => handleTaskChange(event, index, inputField.titleEmployee3 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee3 === "Cube" ? "FollowerCube" : "FollowerSupplier")}
                                                                                                            options={inputField.titleEmployee3 === "Buyer" ? drpGLDTNATaskOwnerList.buyerEmployeeList : inputField.titleEmployee3 === "Cube" ? drpGLDTNATaskOwnerList.cubeEmployeeList : drpGLDTNATaskOwnerList.supplierEmployeeList}
                                                                                                            value={inputField.titleEmployee3 === "Buyer" ? inputField.arrTaskHolderBuyer : inputField.titleEmployee3 === "Cube" ? inputField.arrFollowerCube : inputField.arrFollowerSupplier}
                                                                                                        // options={IMPORTANCE}
                                                                                                        // value={getStyleList.arrImportance}
                                                                                                        //  styles={submitted && getStyleList.arrImportance === 0 ? styles : ''}
                                                                                                        >  </Reactselect>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        }



                                                                                    </Modal.Body>
                                                                                    <Modal.Footer>
                                                                                        <Button variant="success" onClick={() => handleTHLeaveDaysSave(index)}>
                                                                                            Save
                                                                                        </Button>

                                                                                        <Button variant="success" onClick={() => handleTHLeaveDaysSaveNext(index)}>
                                                                                            Save & Next
                                                                                        </Button>

                                                                                    </Modal.Footer>
                                                                                </Modal>
                                                                            }
                                                                            {
                                                                                inputField.IsPopupOpen === 1 &&

                                                                                <Modal show={inputField.IsPopupOpen === 1} size="md" dragable backdrop="static" keyboard={false} onHide={() => handleAdditionalTaskClose(index)}>
                                                                                    <Modal.Header closeButton>
                                                                                        <Modal.Title>Additional Task</Modal.Title>
                                                                                    </Modal.Header>
                                                                                    <Modal.Body>
                                                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>#</th>
                                                                                                    <th>Task Name</th>
                                                                                                    <th> <div className="checkbox" style={{ marginTop: "0px" }}>
                                                                                                        <label>
                                                                                                            <input type="checkbox"
                                                                                                                name="IsSelectAll"
                                                                                                                id={"IsSelectAll_" + index}
                                                                                                                isMulti
                                                                                                                onChange={event => handleTaskChange(event, index, "IsSelectAll")}
                                                                                                                checked={inputField.IsSelectAll === 1 ? true : false}
                                                                                                                className="colored-blue clearOpacity" />
                                                                                                            <span className="text"></span>
                                                                                                        </label>
                                                                                                    </div></th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {
                                                                                                    inputField.FollowupDetails.map((ChildField, childIndex) => (
                                                                                                        <Fragment key={
                                                                                                            `${inputField}~${index}`
                                                                                                        }>
                                                                                                            <tr>
                                                                                                                <td>{childIndex + 1}</td>
                                                                                                                <td>
                                                                                                                    <input type="text"
                                                                                                                        value={ChildField.AddtionalTaskName}
                                                                                                                        className={'form-control'}
                                                                                                                        disabled
                                                                                                                    />
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <div className="checkbox" style={{ marginTop: "0px" }}>
                                                                                                                        <label>
                                                                                                                            <input type="checkbox"
                                                                                                                                name="IsTaskSelected"
                                                                                                                                id={"IsTaskSelected_" + index + "_" + childIndex}
                                                                                                                                isMulti
                                                                                                                                onChange={event => handleAddTaskChange(event, index, childIndex, "IsTaskSelected")}
                                                                                                                                checked={ChildField.IsTaskSelected === 1 ? true : false}
                                                                                                                                className="colored-blue clearOpacity" />
                                                                                                                            <span className="text"></span>
                                                                                                                        </label>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </Fragment>
                                                                                                    ))
                                                                                                }
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </Modal.Body>
                                                                                    <Modal.Footer>
                                                                                        <Button variant="success" onClick={() => handleAdditionalTaskClose(index)}>
                                                                                            Save
                                                                                        </Button>
                                                                                    </Modal.Footer>
                                                                                </Modal>
                                                                            }

                                                                            <button type="button" className="btn btn-xs btn-primary" title={"Additional Task"}
                                                                                onClick={() => handleFollowup(index)}>
                                                                                <b> {inputField.AdditionalFollowup} </b>
                                                                                <i className="fa fa-tasks"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                </Fragment>
                                                            ))
                                                        }
                                                        {
                                                            valuesTask.length === 0 ?
                                                                <tr>
                                                                    <td colSpan="10" className='norecordfound'><span>No Records Found</span></td>
                                                                </tr> : ''
                                                        }


                                                    </tbody>
                                                </table>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="success" onClick={SaveTNATask}>
                                                Save
                                            </Button>
                                        </Modal.Footer>
                                    </Modal> : ''

                            }
                        </div>
                    </form>

                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>

                        </div>
                    </div>
                </div>
            </div >
            {


                showSOManual === true ?
                    <Modal show={showSOManual === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handleCloseManualPopup()}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {getheader} : Trim Submission (Local Development)
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                <thead>
                                    <tr>
                                        <th>Comments <span className="text-danger">*</span></th>
                                        <th>Close Date <span className="text-danger">*</span></th>

                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td style={{ width: "600px" }} >
                                            <textarea
                                                rows="3"
                                                placeholder="Enter Comments here..."
                                                autoFocus onFocus={""} autoComplete="off"
                                                maxLength="500" name="EnterRemarks"
                                                id={"EnterRemarks"}
                                                value={ManualInfo[0].manualCloseComment}
                                                onChange={event => handleChangeIsManualCloseComments(event, "Comment")}
                                                style={{ width: "100%", border: getComment && ManualInfo[0].manualCloseComment === '' ? '1px solid red' : '' }}
                                            // disabled={subField.submitStatus === "Completed" ? true : false}
                                            >

                                            </textarea>
                                        </td>
                                        <td >
                                            {

                                                //  getheader !== "Completed" ?
                                                <DatePicker className="form-control" name="CloseDate"
                                                    id={
                                                        "CloseDate"
                                                    }
                                                    // selected={new Date(ManualInfo[0].manualCloseDate)}
                                                    selected={Date.parse(moment(ManualInfo[0].manualCloseDate, 'MM/DD/YYYY').toISOString())}
                                                    onChange={event => handleChangeIsManualCloseComments(event, "CommentDate")}
                                                    dateFormat="dd/MM/yyyy"
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    dropdownMode="scroll"
                                                    autoComplete="off"
                                                    minDate={new Date()}
                                                    maxDate={new Date()}
                                                    customInput={<ManualCloseCustomInput />}
                                                    disabled={getheader !== "Manual Close" ? true : false}
                                                />


                                            }

                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                            {
                                OrderInfoMulticloseMainDetails.length !== 0 ?

                                    <table className="table table-bordered">

                                        <tbody>
                                            {
                                                OrderInfoMulticloseMainDetails.map((comment, mainindex) => (
                                                    <Fragment>
                                                        <tr>
                                                            <td>
                                                                <div className="row">
                                                                    <div className="col-lg-8">
                                                                        <i
                                                                            className={
                                                                                expandStateMultiSelect[comment.trimLogoPath] ?
                                                                                    'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                            }
                                                                            onClick={() => handleExpandRowMultiSelect(comment.trimLogoPath, MultiCloseDatas)}
                                                                        ></i>
                                                                        <span className="BuyerPaddingLeft">
                                                                            {
                                                                                comment.trimCode
                                                                            }
                                                                            -
                                                                            {
                                                                                comment.trimName
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <span className="Actionbutton">
                                                                            <img src={window.$APIBaseURL + "Images/Style/Trim/" + comment.trimLogoPath} height="50px" width="50px"></img>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                        </tr>
                                                        <>
                                                            {
                                                                expandedRowsMultiSelect.includes(comment.trimLogoPath) ?

                                                                    <tr>
                                                                        <td>
                                                                            <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Story</th>
                                                                                        <th>Theme</th>
                                                                                        <th>Details </th>
                                                                                        <th>Task Holder / Follower</th>
                                                                                        <th>
                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                <input type="checkbox" name="IsSelected"

                                                                                                    onChange={event => (SelectAll(event, comment.trimLogoPath, mainindex))}
                                                                                                    checked={comment.isSelectedAll}
                                                                                                    className="colored-blue clearOpacity" />
                                                                                                <span className="text"></span>
                                                                                            </label>

                                                                                        </th>

                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        expandListMultiSelect.map((item, index) => (
                                                                                            <Fragment>

                                                                                                <tr>
                                                                                                    <td>{item.storyName}</td>
                                                                                                    <td>{item.themeName}</td>
                                                                                                    <td>{item.details}</td>
                                                                                                    <td>{item.followerName}</td>
                                                                                                    <td className="Actionbutton fixed-column">
                                                                                                        <div style={{ width: "80px" }}>
                                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                                <input type="checkbox" name="IsSelected"

                                                                                                                    onChange={event => (OnSelectRecords(event, item.index - 1, mainindex))}
                                                                                                                    checked={item.isSelected === 1 ? true : false}
                                                                                                                    className="colored-blue clearOpacity" />
                                                                                                                <span className="text"></span>
                                                                                                            </label>

                                                                                                        </div>

                                                                                                    </td>

                                                                                                </tr>

                                                                                            </Fragment>
                                                                                        ))

                                                                                    }

                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr> : null
                                                            }
                                                        </>
                                                    </Fragment>
                                                ))
                                            }






                                        </tbody>
                                    </table>
                                    :
                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                        <TableHeader headers={headers} />
                                        <tbody>
                                            <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                                        </tbody>
                                    </table>


                            }

                        </Modal.Body>
                        <Modal.Footer>
                            {
                                getheader === "Manual Close" &&
                                <Button variant="success" onClick={() => SaveChildManulaCloseComments()}>
                                    Save
                                </Button>


                            }


                        </Modal.Footer>
                    </Modal> : ''

            }
            {
                getID.showPopupDelete ? <SweetAlertPopup data={
                    getID.Params
                }
                    deleteCallback={Delcallback}
                    showpopup={true}
                    pageActionId={GenTrimRequestlId}
                    searchParams={getID.SearchParams}
                    Msg={"Trim Request TNA Deleted Successfully."} /> : null
            }

            {


                showSubmission === true ?
                    <Modal dialogClassName="commonwidth" show={showSubmission === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => setshowSubmission(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                               Trim Submission (Local Development)
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            {
                                showMultiClosediv === true ?
                                    <>

                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="dataTables_wrapper no-footer">
                                                    {
                                                        OrderInfoMulticloseMainDetails.length !== 0 ?

                                                            <table className="table table-bordered">

                                                                <tbody>
                                                                    {
                                                                        OrderInfoMulticloseMainDetails.map((comment, mainindex) => (
                                                                            <Fragment>
                                                                                <tr>
                                                                                    <td>
                                                                                        <div className="row">
                                                                                            <div className="col-lg-8">
                                                                                                <i
                                                                                                    className={
                                                                                                        expandStateMultiSelect[comment.trimLogoPath] ?
                                                                                                            'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                    }
                                                                                                    onClick={() => handleExpandRowMultiSelect(comment.trimLogoPath, MultiCloseDatas)}
                                                                                                ></i>
                                                                                                <span className="BuyerPaddingLeft">
                                                                                                    {
                                                                                                        comment.trimCode
                                                                                                    }
                                                                                                    -
                                                                                                    {
                                                                                                        comment.trimName
                                                                                                    }
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className="col-lg-4">
                                                                                                <span className="Actionbutton">
                                                                                                    <img src={window.$APIBaseURL + "Images/Style/Trim/" + comment.trimLogoPath} height="50px" width="50px"></img>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>

                                                                                </tr>
                                                                                <>
                                                                                    {
                                                                                        expandedRowsMultiSelect.includes(comment.trimLogoPath) ?

                                                                                            <tr>
                                                                                                <td>
                                                                                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                                        <thead>
                                                                                                            <tr>
                                                                                                                <th>Story</th>
                                                                                                                <th>Theme</th>
                                                                                                                <th>Details </th>
                                                                                                                <th>Task Holder / Follower</th>
                                                                                                                <th>
                                                                                                                    <label style={{ marginTop: '8px' }}>
                                                                                                                        <input type="checkbox" name="IsSelected"

                                                                                                                            onChange={event => (SelectAll(event, comment.trimLogoPath, mainindex))}
                                                                                                                            checked={comment.isSelectedAll}
                                                                                                                            className="colored-blue clearOpacity" />
                                                                                                                        <span className="text"></span>
                                                                                                                    </label>

                                                                                                                </th>

                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            {
                                                                                                                expandListMultiSelect.map((item, index) => (
                                                                                                                    <Fragment>

                                                                                                                        <tr>
                                                                                                                            <td>{item.storyName}</td>
                                                                                                                            <td>{item.themeName}</td>
                                                                                                                            <td>{item.details}</td>
                                                                                                                            <td>{item.followerName}</td>
                                                                                                                            <td className="Actionbutton fixed-column">
                                                                                                                                <div style={{ width: "80px" }}>
                                                                                                                                    <label style={{ marginTop: '8px' }}>
                                                                                                                                        <input type="checkbox" name="IsSelected"

                                                                                                                                            onChange={event => (OnSelectRecords(event, item.index - 1, mainindex))}
                                                                                                                                            checked={item.isSelected === 1 ? true : false}
                                                                                                                                            className="colored-blue clearOpacity" />
                                                                                                                                        <span className="text"></span>
                                                                                                                                    </label>

                                                                                                                                </div>

                                                                                                                            </td>

                                                                                                                        </tr>

                                                                                                                    </Fragment>
                                                                                                                ))

                                                                                                            }

                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </td>
                                                                                            </tr> : null
                                                                                    }
                                                                                </>
                                                                            </Fragment>
                                                                        ))
                                                                    }






                                                                </tbody>
                                                            </table>
                                                            :
                                                            <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                                <TableHeader headers={headers} />
                                                                <tbody>
                                                                    <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                                                                </tbody>
                                                            </table>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <br />

                                        <div className="row">
                                            <div className="col-sm-12" align="right">
                                                <button type="button" className="btn btn-success" onClick={() => AddRows()}>

                                                    <i className="fa fa-check right"></i> Add
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                    : ''
                            }
                            {
                                showSubmissiondiv === true ?
                                    <>
                                        <div className="row" style={{ overflowX: "hidden", height: "700px", padding: "1px", margin: "0px" }}>
                                            <div>
                                                <div className="row">
                                                    <div className="col-sm-3">
                                                        <div className='form-group'>
                                                            <label htmlFor="BuyerID">Buyer-Season-Brand</label>
                                                            <span className="input-icon icon-right">
                                                                <input
                                                                    placeholder="Buyer-Season-Brand"
                                                                    className="form-control"
                                                                    title={inputFields[0].BuyerBrandSeasonName}
                                                                    name=" Buyer-Season-Brand"
                                                                    id={" Buyer-Season-Brand"}
                                                                    value={inputFields[0].BuyerBrandSeasonName}
                                                                    disabled

                                                                ></input>
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-3">
                                                        <div className='form-group'>
                                                            <label htmlFor="SupplierID" >Supplier</label>
                                                            <span className="input-icon icon-right">
                                                                <input
                                                                    placeholder="Supplier"
                                                                    className="form-control"
                                                                    title={inputFields[0].SupplierName}
                                                                    name="SupplierID"
                                                                    id={"SupplierID"}
                                                                    value={inputFields[0].SupplierName}
                                                                    disabled

                                                                ></input>
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-3">
                                                        <div className='form-group'>
                                                            <label htmlFor="Name" >Submit Date<span className="text-danger">*</span></label>
                                                            <span className='input-icon icon-right'>
                                                                {
                                                                    inputFields[0].SubmitStatus !== "Completed" ?
                                                                        <DatePicker className="form-control" name="SubmittedDate"
                                                                            id={
                                                                                "SubmittedDate"
                                                                            }
                                                                            selected={
                                                                                inputFields[0].SubmittedDate
                                                                            }

                                                                            dateFormat="dd/MM/yyyy"
                                                                            peekNextMonth
                                                                            onChange={event => handleChangeSubmissionMain(event, 'SubmittedDate')}
                                                                            customInput={<CustomInputsubmit />}

                                                                        />
                                                                        // < input id="SubmittedDate" name="SubmittedDate"
                                                                        //     value={inputFields.SubmittedDate} placeholder="DD/MM/YYYY"

                                                                        //     type="date" autoComplete="off"
                                                                        //     className={'form-control'} onKeyDown={(e) => e.preventDefault()}
                                                                        //     style={{
                                                                        //         border: submittedpopup && inputFields[0].SubmittedDate === '' ? '1px solid red' : ''
                                                                        //     }}
                                                                        //     onChange={event => handleChangeSubmissionMain(event, 'SubmittedDate')}
                                                                        // />
                                                                        :


                                                                        <DatePicker className="form-control" name="SubmittedDate"
                                                                            id={
                                                                                "SubmittedDate"
                                                                            }
                                                                            selected={
                                                                                inputFields[0].SubmittedDate
                                                                            }

                                                                            dateFormat="dd/MM/yyyy"
                                                                            peekNextMonth

                                                                            // customInput={<CustomInput />}
                                                                            disabled
                                                                        />
                                                                    // <input
                                                                    //     placeholder="SubmittedDate"
                                                                    //     className="form-control"
                                                                    //     title={inputFields[0].SubmittedDate}
                                                                    //     name="SubmittedDate"
                                                                    //     id={"SubmittedDate"}
                                                                    //     value={inputFields[0].SubmittedDate}
                                                                    //     disabled

                                                                    // ></input>
                                                                }

                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <div className="form-group">
                                                            <label htmlFor={"SendToID"}>Send To<span className="text-danger">*</span></label>
                                                            <span className="input-icon icon-right"  title={Array.isArray(inputFields[0].SendToEdit) ? inputFields[0].SendToEdit.map(item => item.name).join(', ') : ''}>
                                                                <Reactselect
                                                                    className="basic-single"
                                                                    name={"Sku"}
                                                                    id={
                                                                        "Sent To"
                                                                    }
                                                                    // value={item.MeasurementPoints}
                                                                    //isDisabled={false}
                                                                    isLoading={false}
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    options={getTaskholder}
                                                                    value={inputFields[0].SendToEdit}
                                                                    isMulti
                                                                    onChange={event => handlemultidd(event, 'SendTo')}
                                                                    menuPosition="fixed"
                                                                    styles={submittedpopup && inputFields[0].SendTo === "" ? styles2 : styles1}
                                                                // readOnly
                                                                // isDisabled={props.TaskStatus === 3 && (props.editLabdipSubmission === false || chkDataExists === true) ? true : false}
                                                                />

                                                                {/* <select id={"SendTo"} name="SendTo" className="form-select"
                                                                    value={inputFields[0].SendTo}
                                                                    onChange={(event) => handleChangeSubmissionMain(event, 'SendTo')}
                                                                    style={{ border: submittedpopup && inputFields[0].SendTo === "0" ? '1px solid red' : '' }}
                                                                    disabled={inputFields[0].SubmitStatus === "Completed" ? true : false}
                                                                >
                                                                    <option value="0">-Select SendTo-</option>
                                                                    {
                                                                        getTaskholder.map(item => (
                                                                            <option key={item.id} value={item.value}>
                                                                                {item.label}
                                                                            </option>
                                                                        ))
                                                                    }
                                                                </select> */}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">


                                                    <div className="col-sm-3">
                                                        <div className="form-group">
                                                            <label htmlFor={"StoryName"}>
                                                                Story Name
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <span className="input-icon icon-right">
                                                                <input
                                                                    placeholder="Enter the StoryName"
                                                                    className="form-control"
                                                                    title={inputFields[0].StoryName}
                                                                    name="StoryName"
                                                                    id={"StoryName"}
                                                                    value={inputFields[0].StoryName}
                                                                    disabled

                                                                ></input>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <div className="form-group">
                                                            <label htmlFor={" ThemeName"}>
                                                                Theme Name
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <span className="input-icon icon-right">
                                                                <input
                                                                    placeholder="Enter the  ThemeName"
                                                                    className="form-control"
                                                                    title={inputFields[0].ThemeName}
                                                                    name=" ThemeName"
                                                                    id={" ThemeName"}
                                                                    value={inputFields[0].ThemeName}
                                                                    disabled

                                                                ></input>
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-3">
                                                        <div className="form-group">
                                                            <label htmlFor={"Endcustomer"}>
                                                                End Customer

                                                            </label>
                                                            <span className="input-icon icon-right">
                                                                <input
                                                                    placeholder="Enter the End Customer"
                                                                    className="form-control"
                                                                    title={inputFields[0].EndCustomer}
                                                                    name="End Customer No"
                                                                    id={"End Customer"}
                                                                    value={inputFields[0].EndCustomer}
                                                                    disabled

                                                                ></input>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-3">
                                                        <div className="form-group">
                                                            <label htmlFor={"Sendthrough"}>Send Through<span className="text-danger">*</span></label>
                                                            <select id={"Sendthrough"} name="Sendthrough" className="form-select"
                                                                value={inputFields[0].SendThrough}
                                                                onChange={event => handleChangeSubmissionMain(event, 'Sendthrough')}
                                                                disabled={inputFields[0].SubmitStatus === "Completed" ? true : false}
                                                                style={{ border: submittedpopup && inputFields[0].SendThrough === 0 ? '1px solid red' : '' }}
                                                            >
                                                                <option value="0" key="0" style={{ color: '#bfdea1' }}> - Select Send Through- </option>
                                                                <option value="1" key="1">Hand Carried</option>
                                                                <option value="2" key="2">Courier - DHL</option>

                                                            </select>


                                                        </div>
                                                    </div>
                                                    {
                                                        showawb === true ?
                                                            <div className="col-sm-3">
                                                                <div className='form-group'>
                                                                    <label htmlFor="Awbdate" >AWB/Date</label>
                                                                    <span className='input-icon icon-right'>
                                                                        <input
                                                                            placeholder="AWB/Date"
                                                                            className="form-control"
                                                                            name="AWBDateandNo"
                                                                            id={
                                                                                "AWBDateandNo"
                                                                            }
                                                                            value={inputFields[0].AWBDateandNo}
                                                                            // style={{ border: submittedpopup && inputFields[0].AWBDateandNo === '' ? '1px solid red' : '' }}
                                                                            onClick={() => setShowPopup(true)}
                                                                            disabled={inputFields[0].SubmitStatus === "Completed" ? true : false}
                                                                        ></input>
                                                                    </span>
                                                                </div>
                                                            </div> : ''
                                                    }

                                                </div>
                                            </div>


                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="tabbable">
                                                        <ul
                                                            className="nav nav-tabs nav-justified"
                                                            id="myTab1"
                                                        >
                                                            <li
                                                                className={tab === 0 ? "active" : ""} >

                                                                <a
                                                                    onClick={event => TabChange(0)}

                                                                >
                                                                    <h5>Submit Information</h5>
                                                                </a>
                                                            </li>

                                                            <li
                                                                className={tab === 1 ? "active" : " "}

                                                            >
                                                                <a
                                                                    onClick={event => TabChange(1)}
                                                                >
                                                                    <h5>Order Information</h5>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                        <div className="tab-content">
                                                            <div id="idSubmitInformation" className={tab === 0 ? "tab-pane in active" : "tab-pane"} style={{ width: "100%" }}>
                                                                <div className="row">
                                                                    <div className="col-sm-10">
                                                                        <div className="row">
                                                                            <div className="col-sm-3">
                                                                                <div className='form-group'>
                                                                                    <label htmlFor="BuyerID">Trim Code</label>
                                                                                    <span className='input-icon icon-right'>
                                                                                        <input
                                                                                            placeholder="StikeoffCode"
                                                                                            className="form-control cut_text"
                                                                                            title={inputFields.TrimCode}
                                                                                            name="TrimCode"
                                                                                            id={
                                                                                                "TrimCode"
                                                                                            }
                                                                                            value={
                                                                                                inputFields[0].TrimCode
                                                                                            }

                                                                                            disabled
                                                                                        ></input>
                                                                                    </span>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-sm-3">
                                                                                <div className='form-group'>
                                                                                    <label htmlFor="SeasonID" >Trim Name</label>
                                                                                    <span className='input-icon icon-right'>
                                                                                        <input
                                                                                            placeholder=" Name"
                                                                                            className="form-control cut_text"
                                                                                            title={inputFields.TrimName}
                                                                                            name="TrimName"
                                                                                            id={
                                                                                                "TrimName"
                                                                                            }
                                                                                            value={
                                                                                                inputFields[0].TrimName
                                                                                            }

                                                                                            disabled
                                                                                        ></input>
                                                                                    </span>
                                                                                </div>
                                                                            </div>



                                                                            <div className="col-sm-3">
                                                                                <div className="form-group">
                                                                                    <label htmlFor={"Quarter"}>Technical Specification</label>
                                                                                    <span className="input-icon icon-right">

                                                                                        <input
                                                                                            placeholder="Quality"
                                                                                            className="form-control cut_text"
                                                                                            title={inputFields.TechnicalSpecification}
                                                                                            name="TrimQuality"
                                                                                            id={
                                                                                                "TrimQuality"
                                                                                            }
                                                                                            value={
                                                                                                inputFields[0].TechnicalSpecification
                                                                                            }

                                                                                            disabled
                                                                                        ></input>

                                                                                    </span>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-sm-3">

                                                                                <label style={{ marginLeft: "20px" }} htmlFor={"Quarter"}>Trim Image</label>
                                                                                <br />
                                                                                {/* <i className="fa fa-chevron-circle-left fa-2x" onClick={() => previmage()} style={{ cursor: "pointer" }}></i> */}
                                                                                {
                                                                                    getSubImageList.length !== 0 ?
                                                                                        <img src={window.$APIBaseURL + "Images/Style/Trim/" + getSubImageList[getCurrimageindex]} alt="No Image" width="150px" height="150px" style={{ border: "2px solid black" }} />
                                                                                        :
                                                                                        <div align="center" style={{ width: "150px", height: "150px" }}>
                                                                                            No Images Found
                                                                                        </div>
                                                                                }
                                                                                {/* <i className="fa fa-chevron-circle-right fa-2x" onClick={() => nextimage()} style={{ cursor: "pointer" }}></i> */}




                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-sm-6">
                                                                                <div className="form-group">

                                                                                    <label htmlFor={"TrimDimensionId"}>Trim Dimension <span className="text-danger">*</span></label>
                                                                                    <select id={"TrimDimensionId"} name="TrimDimensionId" className="form-select"
                                                                                        value={inputFields[0].TrimDimensionId}
                                                                                        onChange={(event) => handleChangeSubmissionMain(event, 'TrimDimensionId')}
                                                                                        style={{ border: submittedpopup && inputFields[0].TrimDimensionId === "0" ? '1px solid red' : '' }}
                                                                                        disabled={inputFields[0].SubmitStatus === "Completed" ? true : false}
                                                                                    >
                                                                                        <option value="0">-Select Trim-</option>
                                                                                        {
                                                                                            TrimDimension.map(item => (
                                                                                                <option key={item.id} value={item.value}>
                                                                                                    {item.label}
                                                                                                </option>
                                                                                            ))
                                                                                        }

                                                                                    </select>




                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <table>
                                                                                <tr>
                                                                                    <td><label>Submit Options</label> </td>
                                                                                    <td><label>Swatch Card (Image Upload)</label></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <div className="col-sm-6">
                                                                                        <div className="form-group">


                                                                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup"
                                                                                                style={{ width: "500px" }}>
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th >Options <span className="text-danger">*</span></th>
                                                                                                        <th>Supplier Reference No <span className="text-danger">*</span></th>
                                                                                                        <th >Action</th>

                                                                                                    </tr>

                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {
                                                                                                        submittedoption.map((item, index) => {
                                                                                                            return (
                                                                                                                <>
                                                                                                                    <tr>
                                                                                                                        <td>
                                                                                                                            <span className="input-icon icon-right">
                                                                                                                                <input
                                                                                                                                    placeholder=""
                                                                                                                                    className="form-control cut_text"
                                                                                                                                    title={item.OptionName}
                                                                                                                                    name="OptionName"
                                                                                                                                    id={
                                                                                                                                        "OptionName"
                                                                                                                                    }
                                                                                                                                    value={
                                                                                                                                        item.OptionName
                                                                                                                                    }
                                                                                                                                    autoComplete="off"
                                                                                                                                    style={{ width: "250px", border: submitted && item.OptionName === '' ? '1px solid red' : '' }}
                                                                                                                                    onChange={event => handleSubmitoptionchange(event, 'OptionName', index)}
                                                                                                                                    disabled={inputFields[0].SubmitStatus === "Completed" ? true : false}
                                                                                                                                ></input>



                                                                                                                            </span>
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            <span className='input-icon icon-right'>
                                                                                                                                <input
                                                                                                                                    placeholder=""
                                                                                                                                    className="form-control cut_text"
                                                                                                                                    title={item.SupplierReferenceNo}
                                                                                                                                    name="SupplierReferenceNo"
                                                                                                                                    id={
                                                                                                                                        "SupplierReferenceNo"
                                                                                                                                    }
                                                                                                                                    value={
                                                                                                                                        item.SupplierReferenceNo
                                                                                                                                    }
                                                                                                                                    style={{ width: "250px", border: submitted && item.SupplierReferenceNo === '' ? '1px solid red' : '' }}
                                                                                                                                    onChange={event => handleSubmitoptionchange(event, 'SupplierReferenceNo', index)}
                                                                                                                                    disabled={inputFields[0].SubmitStatus === "Completed" ? true : false}
                                                                                                                                ></input>
                                                                                                                            </span>

                                                                                                                        </td>
                                                                                                                        <td className="Actionbutton fixed-column">
                                                                                                                            {" "}
                                                                                                                            <div style={{ width: "80px" }}>
                                                                                                                                {submittedoption.length !== 1 && (
                                                                                                                                    <button
                                                                                                                                        type="button"
                                                                                                                                        className="btn  btn-xs  btn-danger"
                                                                                                                                        title="Delete Trim"
                                                                                                                                        onClick={() =>
                                                                                                                                            handleRemoveFields(index)
                                                                                                                                        }
                                                                                                                                        disabled={inputFields[0].SubmitStatus === "Completed" ? true : false}
                                                                                                                                    >
                                                                                                                                        <i className="fa fa-trash-o"></i>
                                                                                                                                    </button>
                                                                                                                                )}
                                                                                                                                &nbsp;
                                                                                                                                {submittedoption.length === index + 1 && (
                                                                                                                                    <button
                                                                                                                                        type="button"
                                                                                                                                        className="btn  btn-xs  btn-success"
                                                                                                                                        title="Add Trim"
                                                                                                                                        onClick={() =>
                                                                                                                                            handleAddField(index)
                                                                                                                                        }
                                                                                                                                        disabled={inputFields[0].SubmitStatus === "Completed" ? true : false}
                                                                                                                                    >
                                                                                                                                        <i className="fa fa-plus"></i>
                                                                                                                                    </button>
                                                                                                                                )}
                                                                                                                            </div>
                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                                </>

                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                    <td>
                                                                                        <div className={ImgClassName}
                                                                                            htmlFor="upload-button"
                                                                                        >
                                                                                            <img
                                                                                                src={inputFields[0].SwatchCardIamgePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + inputFields[0].SwatchCardIamgePath}
                                                                                                // src={"assets/img/emptyImage.jpg"}
                                                                                                onClick={openFileUpload}
                                                                                                alt="Picture" style={{ marginBottom: '5px', height: '200px', width: '200px', border: "1px solid #d5d5d5" }} />
                                                                                            <input type="file" id="upload-button" accept=".jpg,.jpeg,.png" onChange={(event) => UploadImages(event)} value=''
                                                                                                disabled={inputFields[0].SubmitStatus === "Completed" ? true : false}
                                                                                            />
                                                                                            {
                                                                                                inputFields.SwatchImageFileName !== "assets/img/uploadimage.jpg" && (
                                                                                                    <div style={
                                                                                                        {
                                                                                                            marginTop: '8px',
                                                                                                            float: 'right',
                                                                                                            bottom: '10px',
                                                                                                            position: 'relative',
                                                                                                        }
                                                                                                    }>

                                                                                                    </div>
                                                                                                )
                                                                                            }

                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </table>




                                                                            <div> {
                                                                                isOpen && (

                                                                                    <Lightbox mainSrc={window.$APIBaseURL + 'Images/SwatchImages/' + getImagename}
                                                                                        alt="bg image"
                                                                                        onCloseRequest={
                                                                                            () => setIsOpen(false)
                                                                                        } />
                                                                                )
                                                                            } </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div id="idOrderInformation" className={tab === 1 ? "tab-pane in active" : "tab-pane"} style={{ width: "100%" }}>
                                                                <div className="row">
                                                                    <div className="col-sm-12" align={"right"}>
                                                                        <button type="button" className="btn btn-primary" onClick={() => ShowHideDivs()}
                                                                            disabled={inputFields[0].SubmitStatus === "Completed" ? true : false}>
                                                                            <i className="fa fa-plus"></i> &nbsp;Add
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <br />
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className="dataTables_wrapper no-footer">
                                                                            {
                                                                                OrderInfoMainDetails.length !== 0 ?

                                                                                    <table className="table table-bordered">

                                                                                        <tbody>

                                                                                            {
                                                                                                OrderInfoMainDetails.map(comment => (
                                                                                                    <Fragment>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <div className="row">
                                                                                                                    <div className="col-lg-8">
                                                                                                                        <i
                                                                                                                            className={
                                                                                                                                expandState[comment.trimLogoPath] ?
                                                                                                                                    'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                                            }
                                                                                                                            onClick={() => handleExpandRow(comment.trimLogoPath, getOrderinfo)}
                                                                                                                        ></i>
                                                                                                                        <span className="BuyerPaddingLeft">
                                                                                                                            {
                                                                                                                                comment.trimCode
                                                                                                                            }
                                                                                                                            -
                                                                                                                            {
                                                                                                                                comment.trimName
                                                                                                                            }
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                    <div className="col-lg-4">
                                                                                                                        <span className="Actionbutton">
                                                                                                                            <img src={window.$APIBaseURL + "Images/Style/Trim/" + comment.trimLogoPath} height="50px" width="50px"></img>
                                                                                                                        </span>
                                                                                                                    </div>

                                                                                                                </div>

                                                                                                            </td>

                                                                                                        </tr>
                                                                                                        <>
                                                                                                            {
                                                                                                                expandedRows.includes(comment.trimLogoPath) ?
                                                                                                                    <tr>
                                                                                                                        <td>
                                                                                                                            <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                                                                <thead>
                                                                                                                                    <tr>
                                                                                                                                        <th>Story</th>
                                                                                                                                        <th>Theme</th>
                                                                                                                                        <th>Details </th>
                                                                                                                                        <th>Task Holder / Follower</th>
                                                                                                                                        <th>Action </th>
                                                                                                                                    </tr>
                                                                                                                                </thead>

                                                                                                                                <tbody>
                                                                                                                                    {
                                                                                                                                        expandList.map((item, index) => (
                                                                                                                                            <Fragment>

                                                                                                                                                <tr>
                                                                                                                                                    <td>{item.storyName}</td>
                                                                                                                                                    <td>{item.themeName}</td>
                                                                                                                                                    <td>{item.details}</td>
                                                                                                                                                    <td>{item.followerName}</td>
                                                                                                                                                    <td className="Actionbutton fixed-column">
                                                                                                                                                        {" "}
                                                                                                                                                        <div style={{ width: "80px" }}>
                                                                                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                                                                                <input type="checkbox" name="SelectedDetail"

                                                                                                                                                                    onChange={event => (OnChangeOrderinfo(event, item.index - 1))}
                                                                                                                                                                    checked={item.SelectedDetail === 1 ? true : false}
                                                                                                                                                                    className="colored-blue clearOpacity"
                                                                                                                                                                    disabled={inputFields[0].SubmitStatus === "Completed" ? true : false} />
                                                                                                                                                                <span className="text"></span>
                                                                                                                                                            </label>
                                                                                                                                                            &nbsp;&nbsp;

                                                                                                                                                            {/* <button
                                                                                                                                                                type="button"
                                                                                                                                                                className="btn  btn-xs  btn-danger"
                                                                                                                                                                title="Delete Trim"
                                                                                                                                                                onClick={() =>
                                                                                                                                                                    handleRemoveOrderinfo(index)
                                                                                                                                                                }
                                                                                                                                                                disabled={inputFields[0].SubmitStatus === "Completed" ? true : false}
                                                                                                                                                            >
                                                                                                                                                                <i className="fa fa-trash-o"></i>
                                                                                                                                                            </button> */}

                                                                                                                                                        </div>
                                                                                                                                                    </td>

                                                                                                                                                </tr>

                                                                                                                                            </Fragment>
                                                                                                                                        ))

                                                                                                                                    }

                                                                                                                                </tbody>
                                                                                                                            </table>
                                                                                                                        </td>
                                                                                                                    </tr> : null
                                                                                                            }
                                                                                                        </>
                                                                                                    </Fragment>
                                                                                                ))
                                                                                            }


                                                                                        </tbody>
                                                                                    </table>
                                                                                    :
                                                                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                                                        <TableHeader headers={headers} />
                                                                                        <tbody>
                                                                                            <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                                                                                        </tbody>
                                                                                    </table>


                                                                            }



                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <br />


                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>


                                            <div className="mt-10">
                                                <div className="row">
                                                    <div className="col-sm-8 col-lg-8">
                                                        <div className='form-group'>
                                                            <label htmlFor="Remarks">Remarks</label>
                                                            <textarea maxLength="300" name="Remarks" value={inputFields[0].Remarks}
                                                                onChange={event => handleChangeSubmissionMain(event, 'Remarks')}
                                                                rows="4" style={{ width: "100%" }}
                                                                disabled={inputFields[0].SubmitStatus === "Completed" ? true : false}
                                                            ></textarea>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12" align="right">
                                                <button type="button" className="btn btn-success margin-Align" onClick={() => SaveSubmission()}
                                                    disabled={inputFields[0].SubmitStatus === "Completed" ? true : false}>

                                                    <i className="fa fa-check right"></i> &nbsp;{getHeader === 'Add' ? 'Save' : 'Update'}
                                                </button>
                                            </div>
                                        </div>
                                        {
                                            ShowPopup === true ?
                                                <Modal show={ShowPopup} size="md" dragable backdrop="static" keyboard={false} onHide={() => setShowPopup(false)}>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>
                                                            AWB and Date
                                                        </Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: "350px" }}>AWB<span className="text-danger">*</span></th>
                                                                    <th style={{ width: "300px" }}>Date<span className="text-danger">*</span></th>
                                                                    {/* <th styles={{ width: "100px" }}>Action</th> */}
                                                                </tr>

                                                            </thead>
                                                            <tbody>

                                                                <tr>
                                                                    <td>
                                                                        <span className='input-icon icon-right'>
                                                                            <input type="text"
                                                                                placeholder="AWB Name"
                                                                                id={"AWBName"}
                                                                                name="AWBName"
                                                                                value={inputFields[0].AWBName}
                                                                                style={{ border: submittedawb && !inputFields[0].AWBName ? '1px solid red' : '' }}
                                                                                autoFocus
                                                                                maxLength="25"
                                                                                className={'form-control'}
                                                                                onChange={event => handlepopupchange(event, 'AWBName')}
                                                                                menuPosition="fixed"
                                                                            // disabled={ChildField.DependencyID === 0 ? true : false}
                                                                            />
                                                                        </span>

                                                                    </td>
                                                                    <td>
                                                                        <DatePicker className="form-control" name="AWBDate"
                                                                            id={
                                                                                "AWBDate"
                                                                            }
                                                                            selected={
                                                                                inputFields[0].AWBDate
                                                                            }
                                                                            onChange={event => handlepopupchange(event, 'AWBDate')}
                                                                            dateFormat="dd/MM/yyyy"
                                                                            peekNextMonth

                                                                            dropdownMode="scroll"
                                                                            autoComplete="off"
                                                                            customInput={<CustomInputAWB />}
                                                                            isClearable={inputFields[0].AWBDate === null ? false : true}
                                                                        />
                                                                        {/* <span className="input-icon icon-right">
                                                                            <input id={"AWBDate"} name="AWBDate"
                                                                                value={inputFields[0].AWBDate} placeholder="DD/MM/YYYY"
                                                                                type="date" autoComplete="off" onKeyDown={(e) => e.preventDefault()}
                                                                                className={'form-control'}
                                                                                style={{ border: submittedawb && inputFields[0].AWBDate === '' ? '1px solid red' : '' }}
                                                                                onChange={event => handlepopupchange(event, 'AWBDate')}
                                                                            />
                                                                        </span> */}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button variant="success" onClick={() => SaveAwbnoDate()}>
                                                            <i className="fa fa-check right"></i>&nbsp;
                                                            Save
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>
                                                : ''
                                        }
                                    </>
                                    : ''
                            }

                        </Modal.Body>

                    </Modal> : ''

            }

            {
                openRevicepopup === true &&
                <Modal show={openRevicepopup === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => setopenRevicepopup(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Revise Add
                            {
                                getSubReviceList.length !== 0 ?
                                    <>
                                        <br />
                                        (Initial Date  Duration : {getSubReviceList[0].duration} Start Date : {getSubReviceList[0].startDate} End Date : {getSubReviceList[0].endDate})
                                    </>
                                    : ''

                            }

                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Task Name</th>
                                    <th>Trim</th>
                                    <th>Details</th>
                                    <th>Duration</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Leave Days</th>

                                    {/* <th>Action </th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    TaskDetails.length !== 0 ?
                                        TaskDetails.map((item, index) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <td>
                                                            {item.taskName}

                                                        </td>
                                                        <td>
                                                            <span className='input-icon icon-right'>
                                                                {
                                                                    item.trim
                                                                }
                                                            </span>


                                                            <div className="trwidth_manual" style={{ textAlign: "center" }}>
                                                                <img className="width_manual"
                                                                    src={item.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images/Style/Trim/" + item.imagePath}
                                                                    alt="Logo"
                                                                    onClick={
                                                                        () => setIsOpen(item.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images/Style/Trim/" + item.imagePath)
                                                                    }
                                                                />
                                                            </div>


                                                        </td>
                                                        <td>
                                                            {item.itemDetails}

                                                        </td>
                                                        <td>
                                                            <input type="text"
                                                                placeholder="Duration"
                                                                value={item.duration}
                                                                onChange={event => handleChangeRevice(event, index, "Duration")}
                                                                maxLength="3"
                                                                className={'form-control'}
                                                            />

                                                        </td>
                                                        <td>
                                                            <DatePicker className="form-control min_width_Date"
                                                                name="ChildStartdate"
                                                                id={
                                                                    "ChildStartdate"
                                                                }
                                                                selected={Date.parse(moment(item.startDate, 'MM/DD/YYYY').toISOString())}
                                                                dateFormat="dd/MM/yyyy"
                                                                readOnly={true}

                                                            />

                                                        </td>
                                                        <td>
                                                            <DatePicker className="form-control min_width_Date"
                                                                name="ChildEnddate"
                                                                id={
                                                                    "ChildEnddate"
                                                                }

                                                                selected={Date.parse(moment(item.endDate, 'MM/DD/YYYY').toISOString())}
                                                                onChange={
                                                                    event => handleChangeRevice(event, index, "EndDate")
                                                                }
                                                                dateFormat="dd/MM/yyyy"
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="scroll"
                                                                autoComplete="off"
                                                                popperProps={{
                                                                    positionFixed: true // use this to make the popper position: fixed
                                                                }}
                                                                //readOnly={Buttonvisible === 'View' ? true : false}
                                                                customInput={<EndDateCustomInput />}

                                                            />
                                                        </td>
                                                        <td>
                                                            {item.leaveDayName}

                                                        </td>

                                                    </tr>
                                                </>

                                            )
                                        })
                                        :
                                        <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>

                                }

                            </tbody>
                        </table>
                        <br />

                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                            <thead>
                                <tr>
                                    <th>Submission Revice Comments<span className="text-danger">*</span></th>
                                    <th>Approval Revice Comments<span className="text-danger">*</span></th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr>
                                    <td style={{ width: "600px" }} >
                                        <textarea
                                            rows="3"
                                            placeholder="Enter Comments here..."
                                            autoFocus onFocus={""} autoComplete="off"
                                            maxLength="500" name="EnterRemarks"
                                            id={"EnterRemarks"}
                                            value={ReviceComment.SubmissionComment}
                                            onChange={event => handleChangeRevice(event, 0, "SubmissionComment")}
                                            style={{ width: "100%", border: Submitrevice && ReviceComment.SubmissionComment === '' ? '1px solid red' : '' }}>
                                        </textarea>
                                    </td>
                                    <td style={{ width: "600px" }} >
                                        <textarea
                                            rows="3"
                                            placeholder="Enter Comments here..."
                                            autoFocus onFocus={""} autoComplete="off"
                                            maxLength="500" name="EnterRemarks"
                                            id={"EnterRemarks"}
                                            value={ReviceComment.ApprovalComment}
                                            onChange={event => handleChangeRevice(event, 0, "ApprovalComment")}
                                            style={{ width: "100%", border: Submitrevice && ReviceComment.ApprovalComment === '' ? '1px solid red' : '' }}>
                                        </textarea>
                                    </td>

                                </tr>
                            </tbody>

                        </table>
                        {
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Duration</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Comments</th>
                                        <th>Reviced Date</th>
                                        <th>Reviced By</th>
                                        {/* <th>Action </th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        getSubReviceList.length !== 0 ?
                                            getSubReviceList.filter(t => t.type !== 'Initial').map((item, subindex) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td>
                                                                {item.type}

                                                            </td>
                                                            <td style={{ width: "80px" }}>
                                                                {item.duration}

                                                            </td>
                                                            <td>
                                                                {item.startDate}

                                                            </td>
                                                            <td>
                                                                {item.endDate}

                                                            </td>
                                                            <td style={{ width: "300px" }}>
                                                                {item.comments}
                                                            </td>
                                                            <td>
                                                                {item.createdDate}

                                                            </td>
                                                            <td>
                                                                {item.revicedBy}

                                                            </td>

                                                        </tr>
                                                    </>

                                                )
                                            })
                                            :
                                            <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>

                                    }

                                </tbody>
                            </table>


                        }

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={() => handleSaveRevice()}>
                            {/* {getReviceAction === 'Add' ? 'Save' : 'Update'} */}
                            Add
                        </Button>
                    </Modal.Footer>
                </Modal>

            }
            {
                openReschedulepopup === true &&
                <Modal show={openReschedulepopup === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => setopenReschedulepopup(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Reschedule Add
                            {
                                getSubRescheduleList.length !== 0 ?
                                    <>
                                        <br />
                                        (Initial Date  Duration : {getSubRescheduleList[0].duration} Start Date : {getSubRescheduleList[0].startDate} End Date : {getSubRescheduleList[0].endDate})
                                    </>
                                    : ''

                            }

                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Task Name</th>
                                    <th>Trim</th>
                                    <th>Details</th>
                                    <th>Duration</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Leave Days</th>

                                    {/* <th>Action </th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    TaskDetails.length !== 0 ?
                                        TaskDetails.map((item, index) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <td>
                                                            {item.taskName}

                                                        </td>
                                                        <td>
                                                            <span className='input-icon icon-right'>
                                                                {
                                                                    item.trim
                                                                }
                                                            </span>


                                                            <div className="trwidth_manual" style={{ textAlign: "center" }}>
                                                                <img className="width_manual"
                                                                    src={item.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images/Style/Trim/" + item.imagePath}
                                                                    alt="Logo"
                                                                    onClick={
                                                                        () => setIsOpen(item.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images/Style/Trim/" + item.imagePath)
                                                                    }
                                                                />
                                                            </div>


                                                        </td>
                                                        <td>
                                                            {item.itemDetails}

                                                        </td>
                                                        <td>
                                                            <input type="text"
                                                                placeholder="Duration"
                                                                value={item.duration}
                                                                // onChange={event => handleChangeReschedule(event, index, "Duration")}
                                                                maxLength="3"
                                                                className={'form-control'}
                                                            />

                                                        </td>
                                                        <td>
                                                            <DatePicker className="form-control min_width_Date"
                                                                name="ChildStartdate"
                                                                id={
                                                                    "ChildStartdate"
                                                                }
                                                                selected={Date.parse(moment(item.startDate, 'MM/DD/YYYY').toISOString())}
                                                                dateFormat="dd/MM/yyyy"
                                                                readOnly={true}

                                                            />

                                                        </td>
                                                        <td>
                                                            <DatePicker className="form-control min_width_Date"
                                                                name="ChildEnddate"
                                                                id={
                                                                    "ChildEnddate"
                                                                }

                                                                selected={Date.parse(moment(item.endDate, 'MM/DD/YYYY').toISOString())}
                                                                // onChange={
                                                                //     event => handleChangeReschedule(event, index, "EndDate")
                                                                // }
                                                                dateFormat="dd/MM/yyyy"
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="scroll"
                                                                autoComplete="off"
                                                                popperProps={{
                                                                    positionFixed: true // use this to make the popper position: fixed
                                                                }}
                                                                //readOnly={Buttonvisible === 'View' ? true : false}
                                                                customInput={<EndDateCustomInput />}

                                                            />
                                                        </td>
                                                        <td>
                                                            {item.leaveDayName}

                                                        </td>

                                                    </tr>
                                                </>

                                            )
                                        })
                                        :
                                        <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>

                                }

                            </tbody>
                        </table>
                        <br />

                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                            <thead>
                                <tr>
                                    <th>Submission Reschedule Comments<span className="text-danger">*</span></th>
                                    <th>Approval Reschedule Comments<span className="text-danger">*</span></th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr>
                                    <td style={{ width: "600px" }} >
                                        <textarea
                                            rows="3"
                                            placeholder="Enter Comments here..."
                                            autoFocus onFocus={""} autoComplete="off"
                                            maxLength="500" name="EnterRemarks"
                                            id={"EnterRemarks"}
                                            value={RescheduleComment.SubmissionComment}
                                            // onChange={event => handleChangeReschedule(event, 0, "SubmissionComment")}
                                            style={{ width: "100%", border: SubmitReschedule && RescheduleComment.SubmissionComment === '' ? '1px solid red' : '' }}>
                                        </textarea>
                                    </td>
                                    <td style={{ width: "600px" }} >
                                        <textarea
                                            rows="3"
                                            placeholder="Enter Comments here..."
                                            autoFocus onFocus={""} autoComplete="off"
                                            maxLength="500" name="EnterRemarks"
                                            id={"EnterRemarks"}
                                            value={RescheduleComment.ApprovalComment}
                                            //   onChange={event => handleChangeReschedule(event, 0, "ApprovalComment")}
                                            style={{ width: "100%", border: SubmitReschedule && RescheduleComment.ApprovalComment === '' ? '1px solid red' : '' }}>
                                        </textarea>
                                    </td>

                                </tr>
                            </tbody>

                        </table>
                        {
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Duration</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Comments</th>
                                        <th>Rescheduled Date</th>
                                        <th>Rescheduled By</th>
                                        {/* <th>Action </th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        getSubRescheduleList.length !== 0 ?
                                            getSubRescheduleList.filter(t => t.type !== 'Initial').map((item, subindex) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td>
                                                                {item.type}

                                                            </td>
                                                            <td style={{ width: "80px" }}>
                                                                {item.duration}

                                                            </td>
                                                            <td>
                                                                {item.startDate}

                                                            </td>
                                                            <td>
                                                                {item.endDate}

                                                            </td>
                                                            <td style={{ width: "300px" }}>
                                                                {item.comments}
                                                            </td>
                                                            <td>
                                                                {item.createdDate}

                                                            </td>
                                                            <td>
                                                                {item.rescheduledBy}

                                                            </td>

                                                        </tr>
                                                    </>

                                                )
                                            })
                                            :
                                            <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>

                                    }

                                </tbody>
                            </table>


                        }

                    </Modal.Body>
                    <Modal.Footer>

                    </Modal.Footer>
                </Modal>

            }
            {loader}
        </Fragment >

    )
}

export default React.memo(TrimViewRequestTab);