import {
 TEMPLATELIST_LOADING,
 RETRIEVE_TEMPLATELIST,
 DELETE_TEMPLATELIST,
} from "./types";
import TemplateService from "../services/Master/TemplateService";

const templatelistsLoading = (isStatus) => ({
 type: TEMPLATELIST_LOADING,
 payload: isStatus,
});

export const deletetemplate = (templateInfoID) => async (dispatch) => {
 
 try {
  dispatch({
   type: DELETE_TEMPLATELIST,
   payload: templateInfoID,
  });

 } catch (err) {
  console.log(err);
 }
};

export const retrievetemplatelist = (Operation, Id) => async (dispatch) => {

 try {
  dispatch(templatelistsLoading(true));
  const res = await TemplateService.GettemplateList(Operation, Id);
  dispatch({
   type: RETRIEVE_TEMPLATELIST,
   payload: res.data,
  });
  dispatch(templatelistsLoading(false));
 } catch (err) {
  console.log(err);
  dispatch(templatelistsLoading(false));
 }
};