import React, { useState, useEffect } from "react";
import ReactNotification from 'react-notifications-component';
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import 'react-notifications-component/dist/theme.css';
import Nodify from "../Common/ReactNotification";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Reactselect from 'react-select';
import StyleService from "../../services/Style/styleService";
import supplierService from "../../services/Master/SupplierService";
import CountryService from "../../services/Master/CityService";
import Lightbox from "react-image-lightbox"
import Draggable from 'react-draggable';
import ModalDialog from 'react-bootstrap/ModalDialog';
import { Modal, Button } from "react-bootstrap";
import PurchaseOrderService from "../../services/PurchaseOrder/PurchaseOrderService";
// import strikeoffService from "../../services/Style/StrikeoffService";
import axios from "axios";
import $ from "jquery";
// import moment from 'moment';
import Loader from "react-loader-spinner";
import { useSelector } from "react-redux";
import ConfirmationPopup from '../Common/ConfirmationPopup';
import useFullPageLoader from "../../hooks/useFullPageLoader";
import FinalinspectionService from "../../services/FinalInspection/FinalinspectionService";

const PurchaseOrder = (props) => {
    //let ScreenWidth = (window.innerWidth / window.devicePixelRatio).toString() + "px";
    const { user: currentUser } = useSelector((state) => state.auth);

    //currentUser.employeeMenuPermissionID

    // If check production or sampling
    // If check production or sampling
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
    let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal : activeMenus.IsProduction;
    // End


    const EmptyInputFields = {
        Operation: 1, BuyerID: '', SeasonID: '', BrandID: '', Quarter: '0', ReceivedOn: '', CurrencyID: '', PurchaseOrderID: 0,
        SupplierID: '', EndCustomerID: '', DestinationID: '', IDPONOOption: 0, DestinationOption: 0,ReceivedDateOption:0,
        EndCustomerOption: 0,
        
        StyleID: 0, OrderEndCustomerID: 0, ShowHideImage: 1, ShowHidepacking: 1,//ShowHideImage: '1',
        Remarks: '', TotalQuantity: 0, TotalValue: '0.00', IDPoNo: '', IsFocusPONoOption: 0, FocusPONo: '',
        PackingPlanID: 0,
        // Remarks: '', TotalQuantity: 0, TotalValue: 0, IDPoNo: '',
        StyleLists: [{
            StyleID: 0, StyleName: '', StyleNo: '', IsDeleted: 0, ArrangeData: 0, IndexName: '', CheckALLSKU: 0, AppliedSKUs: [],
            PurchaseOrderID: 0, PurOrderStyleDetailID: 0, IndexCounting: 1,
            SKUInfo: [{
                SKUID: 0, SKUName: '', SKUFrontPicPath: '', SKUFrontPicName: '', Quantity: '', Price: '', Value: '', ShipmentMode: IsProduction === 1 ? '0' : '1', POIDPONO: '', POFocusPONo: '',
                ExFacDate: '',ReceivedDate:'', FacDate: '', EndCustomerID: 0, EndCustomerName: '', DestinationID: 0, DestinationName: '', IsDeleted: 0, IndexName: '', SkuIndexName: '',
                EnableComments: 0, EnterComment: '', SaveComment: false, CheckSKU: 0, OpenChkPopUP: 0, PurchaseOrderSkuID: 0, PurchaseOrderID: 0,
                Sizes: [], SizeHeading: [],
                PackingPlanID: 0,
                Comments: [{
                    PurOrdSkuCommentsID: 0, PurOrdSkuComments: '', IndexName: '0', SkuIndexName: '0',
                    IsDeleted: 0, //Date: moment(new Date()).format('DD-MM-YYYY'),
                    Date: new Date(), Order: 0,
                }],
                IndexCoun: 1,
            }],
        }],
        RefNo: '', ShipmentMode: '', ExFacDate: '',ReceivedDate:'', UnitPrice: '', EndCustomer: '', Destination: '',

    }

    const [inputFields, setInputFields] = useState(EmptyInputFields);
    const [submitted, setSubmitted] = useState(false);
    const [getHeader, setHeader] = useState("Add");
    const [Buttonvisible, SetButtonvisible] = useState("Show");
    const [Buttonview, SetButtonview] = useState("Hide");
    const [getCurrencyList, setCurrencyList] = useState([]);
    const [getBuyerList, setBuyerList] = useState([]);
    const [getBrandList, setBrandList] = useState([]);
    const [getEndCustomerList, setEndCustomerList] = useState([]);
    const [getSeasonList, setSeasonList] = useState([]);
    const [getSupplierList, setBuyerTrimSupplierList] = useState([]);
    const [getcountryList, setcountryList] = useState([]);
    const [getstyleList, setstyleList] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [getComment, setComment] = useState(false);
    const [getFocus, setFocus] = useState({ Ref: false, UP: false, FPONo: false });
    const [getReload, setReload] = useState(1);
    const [ShowImportPopup, setShowImportPopup] = useState(false);
    const [options, setOptions] = useState(false);
    const [getImportValue, setImportValue] = useState([]);
    const [getFiles, setFiles] = useState([]);
    const [getpackingoption, setpackingoption] = useState([]);
    const [show, setShow] = useState(false);

    const [isOpenimg, setIsOpenimg] = useState(false);
    const [getImagename, setImagename] = useState();
    const [EditedCommentIndex, setEditedCommentIndex] = useState('');
    const [getCurrencysymbol, setCurrencysymbol] = useState();
    const [getres, setres] = useState(0);
    const [getDisableImport, setDisableImport] = useState(true);
    const [getDisableSave, setDisableSave] = useState(false);
    const [getPage, setPage] = useState("UPDATEPURCHASEORDER");
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [getID, setID] = useState({ showPopupConfirm: false, Params: {} });
    const [isHideFocusPONo, setIsHideFocusPONo] = useState(true);

    const [isHideTNACreate, setIsHideTNACreate] = useState(false);

    const reducerState = useSelector((state) => state);
    const selector = useSelector((state) => state);
    const isSideBarEnable = selector.auth.isSideBarEnable;
    const isFullSideBarEnable = selector.auth.isFullSideBarEnable;
    let BrowserWidth = isFullSideBarEnable
        ? isSideBarEnable
            ? $(window).width() - 10
            : $(window).width() + 50
        : $(window).width() + 100;
    let ispurchaseodrloading = reducerState.purchaseorder.isLoadingPurchaseOrder;
    const [allstyleTNAcreated, setallstyleTNAcreated] = useState(false);
    //Enable Menu
    let activeMenu;
    let activeTNAMenu;
    const menulist = useSelector((state) => state.common.MenuList.item3);
    if (menulist !== undefined) {
        activeMenu = menulist.filter(i => i.menuName === "PurchaseOrder");
        activeTNAMenu = menulist.filter(i => i.menuName === "TNA");
    }
    // End Enable Menu


    let BuyerListOptions = [] = [];
    const BuyerBrandDetails = reducerState.auth.UserMenuList.item6 !== undefined ? reducerState.auth.UserMenuList.item6 : [];
    // End

    // useEffect(() => {
    //     if (!ispurchaseodrloading) {
    //         hideLoader();
    //     } else {
    //         showLoader();
    //     }
    // }, [ispurchaseodrloading])

    useEffect(() => {
        let packingoption = [];

        StyleService.LoadTaskWiseBuyer(1, IsProduction)
            .then((response) => {
                if (response.data) {
                    //setBuyerList(response.data);
                    BuyerListOptions = response.data;
                    setBuyerList(BuyerListOptions.filter(o => BuyerBrandDetails.some(({ buyerId }) => o.id === buyerId)));

                }
            })
            .catch(() => { });

        // StyleService.LoadSeason()
        //     .then((response) => {
        //         if (response.data) {
        //             setSeasonList(response.data);
        //         }
        //     })
        //     .catch(() => { });

        supplierService.LoadCurrencyist().then((response) => {
            if (response.data) {
                setCurrencyList(response.data);
            }
        }).catch(() => { });

        CountryService.loadCountryList().then((response) => {
            if (response.data) {
                setcountryList(response.data);
            }
        }).catch(() => { });

        // // when click Reset Buyer not loaded for comment below code
        // StyleService.LoadBuyer(2)
        //     .then((response) => {
        //         if (response.data) {
        //             setBuyerList(response.data);
        //         }
        //     }).catch(() => { });

        PurchaseOrderService.LoadSupplierList().then((response) => {
            if (response.data) {
                setBuyerTrimSupplierList(response.data);
            }
        }).catch(() => { });
        localStorage.removeItem('TotalAssignImport');
        localStorage.removeItem('TotalImport');
        localStorage.removeItem('GetChkStyleImport')

        if (props.location.state !== undefined) {
            setHeader("Edit")
            setCurrencysymbol(props.location.state.params.currencySymbol);
            if (props.location.state.params.action === 'View') {
                SetButtonvisible("View")
                SetButtonview("View")
                setHeader("View")
            }


            showLoader();
            LoadBuyerBasedValues(parseInt(props.location.state.params.buyerID));
            FinalinspectionService.GetTNAFinalinspecTestingReportList(3, props.location.state.params.buyerID, props.location.state.params.brandID).then((res) => {
                if (res.data) {
                    res.data.map((x, index) => {
                        packingoption.push({
                            value: x.comTempID,
                            label: x.typeName,
                        })

                    })

                }
                setpackingoption(packingoption)
            })

            PurchaseOrderService.GetSelectedPurchaseOrder(props.location.state.params.purchaseOrderID).then((response) => {
                if (response.data) {
                    let getCommonData = response.data.commonPurchaseOrders;
                    let skuCommentPurchaseOrders = response.data.skuCommentPurchaseOrders;
                    let skuPurchaseOrders = response.data.skuPurchaseOrders;
                    let stylePurchaseOrders = response.data.stylePurchaseOrders;
                    let DocsPurchaseOrders = response.data.getPurchaseOrderDocs;

                    if (stylePurchaseOrders) {
                        let isTNAPOS = !!!stylePurchaseOrders.find(d => d.isTNAPOS === false);
                        isTNAPOS && setIsHideTNACreate(isTNAPOS);
                    }


                    let DateValue = new Date(getCommonData[0].recievedOn);
                    var dd = DateValue.getDate();
                    var mm = DateValue.getMonth() + 1;
                    var yyyy = DateValue.getFullYear();

                    if (dd < 10) {
                        dd = '0' + dd;
                    }
                    if (mm < 10) {
                        mm = '0' + mm;
                    }
                   
                    let AssignDate = yyyy + '-' + mm + '-' + dd;
                    let AddTotalValue = parseFloat(getCommonData[0].totalValue).toFixed(2);
                    let values = {
                        Operation: 2, BuyerID: getCommonData[0].buyerID, SeasonID: getCommonData[0].seasonID, BrandID: getCommonData[0].brandID, Quarter: getCommonData[0].quaterID.toString(),
                        CurrencyID: getCommonData[0].currencyID,
                        SupplierID: getCommonData[0].trimSupplierID,
                        EndCustomerID: getCommonData[0].endCustomerOption === 1 ? '' : getCommonData[0].endCustomerID, DestinationID: getCommonData[0].destinationOption === 1 ? '' : getCommonData[0].destination,
                        ReceivedDateOn: getCommonData[0].receivedDateOption === 1 ? '' : getCommonData[0].receivedOn,
                        //ReceivedDate:getCommonData[0].receivedDateOption === 1 ? '' : getCommonData[0].recievedOn, DestinationID: getCommonData[0].destinationOption === 1 ? '' : getCommonData[0].destination,
                        IDPONOOption: getCommonData[0].idponoOption,
                        DestinationOption: getCommonData[0].destinationOption,
                        ReceivedDateOption:getCommonData[0].receivedDateOption,
                        PackingPlanID: getCommonData[0].packingPlanID,
                        EndCustomerOption: getCommonData[0].endCustomerOption, ShowHideImage: getCommonData[0].showHideImage,
                        ShowHidepacking: 1,
                        //ReceivedDateOption:getCommonData[0].receivedDateOption, ShowHideImage: getCommonData[0].showHideImage,
                        Remarks: getCommonData[0].remarks, TotalQuantity: getCommonData[0].totalQuantity, TotalValue: parseFloat(getCommonData[0].totalValue).toFixed(2),
                        IDPoNo: getCommonData[0].idponoOption === 1 ? '' : getCommonData[0].idpono,
                        PurchaseOrderID: getCommonData[0].purchaseOrderID,
                        IsFocusPONoOption: getCommonData[0].isFocusPONoOption ? 1 : 0,
                        FocusPONo: getCommonData[0].isFocusPONoOption === true ? '' : getCommonData[0].focusPONo,
                        // ReceivedOn: new Date(getCommonData[0].recievedOn),
                        // ReceivedOn: DateValue,
                        ReceivedOn: getCommonData[0].receivedDateOption === 1 ? '':DateValue,
                        StyleLists: [],
                        RefNo: '', ShipmentMode: '', ExFacDate: '',ReceivedDate:'', UnitPrice: '', EndCustomer: '', Destination: '',
                    };
                    if (getCommonData[0].buyerName === "Focus International" && IsProduction === 2) {
                        setIsHideFocusPONo(false);
                    } else {
                        setIsHideFocusPONo(true);
                    }

                    // if (getCommonData[0].isFocusPONoOption) {
                    //     setIsHideFocusPONo(false);
                    // }
                    let POStyle = { EmployeeMenuPermissionID: currentUser.employeeMenuPermissionID, Operation: 1, BuyerID: parseInt(values.BuyerID), SeasonID: parseInt(values.SeasonID), BrandID: parseInt(values.BrandID), StyleID: 0, IsProduction: IsProduction }
                    LoadPOStyles(POStyle);

                    // values.StyleLists[index].AppliedSKUs = [];
                    stylePurchaseOrders.forEach((getStyle, i) => {
                        values.StyleLists.push({
                            StyleID: getStyle.styleID,
                            StyleName: '', StyleNo: getStyle.styleNo, IsDeleted: 0, ArrangeData: 0, IndexName: getStyle.indexName, CheckALLSKU: 0, AppliedSKUs: [],
                            SKUInfo: [], PurchaseOrderID: getStyle.purchaseOrderID, PurOrderStyleDetailID: getStyle.purOrderStyleDetailID,
                            IndexCounting: i + 1,
                        })
                    });

                    skuPurchaseOrders.forEach((getSkus, asI) => {
                        values.StyleLists.forEach((getStyle, i) => {
                            if (getSkus.indexName === getStyle.IndexName) {
                                let SplitID = getSkus.routeSkuizesId.split(",");
                                let SplitName = getSkus.routeSkuSizes.split(",");
                                let SplitValues = getSkus.routeSkuSizesValue.split(",");
                                let getFinalSizes = [];
                                SplitID.forEach((pushEachSize, i) => {
                                    let assignField = SplitName[i];
                                    getFinalSizes.push({ SizeID: pushEachSize, BindSizeName: SplitName[i], [assignField]: '', AssgignValue: SplitValues[i], IsDeleted: 0, IndexName: '' });
                                })

                                let PicName = []; let PicPath = []; let SkuName = []; let SkuID = [];
                                if (getSkus.skuFrontPicNameValues !== null) {
                                    PicName = getSkus.skuFrontPicNameValues.split(",");
                                }
                                if (getSkus.skuFrontPicPathValues !== null) {
                                    PicPath = getSkus.skuFrontPicPathValues.split(",");
                                }
                                if (getSkus.skuNameValues !== null) {
                                    SkuName = getSkus.skuNameValues.split(",");
                                }
                                if (getSkus.skuidValues !== null) {
                                    SkuID = getSkus.skuidValues.split(",");
                                }

                                getStyle.AppliedSKUs = [];
                                SkuID.forEach((passArg, Skus) => {
                                    getStyle.AppliedSKUs.push({
                                        SKUFrontPicName: PicName[Skus], SKUName: SkuName[Skus], SKUFrontPicPath: PicPath[Skus],
                                        SKUID: SkuID[Skus],
                                    })
                                });

                                let SKULength = getStyle.SKUInfo.length;
                                getStyle.SKUInfo.push({
                                    SKUID: getSkus.skuNameID, SKUName: '',
                                    SKUFrontPicPath: getSkus.skuFrontPicPath,
                                    SKUFrontPicName: getSkus.skuFrontPicName,
                                    Quantity: getSkus.quantity, Price: getSkus.price, Value: getSkus.value,
                                    ShipmentMode: getSkus.shipmentMode, POIDPONO: getSkus.poidpono,
                                    POFocusPONo: getSkus.poFocusPONo,
                                    EndCustomerID: getSkus.endCustomerID, EndCustomerName: '',
                                    DestinationID: getSkus.destinationID,
                                    PackingPlanID: getSkus.packingPlanID,
                                    DestinationName: '', IsDeleted: 0, IndexName: getSkus.indexName, SkuIndexName: getSkus.skuIndexName,
                                    EnableComments: 0, EnterComment: '', SaveComment: false, CheckSKU: 0, OpenChkPopUP: 0,
                                    PurchaseOrderID: getSkus.purchaseOrderID, PurchaseOrderSkuID: getSkus.purchaseOrderSkuID,
                                    Sizes: getFinalSizes, SizeHeading: SKULength === 0 ? getFinalSizes : [],
                                    Comments: [],
                                    // ExFacDate: new Date(getSkus.exFacDate),
                                    ExFacDate: (getSkus.exFacDate),
                                    ReceivedDate:(getSkus.receivedDate),
                                    FacDate: getSkus.facDate && convertUTCDateToLocalDate(new Date(getSkus.facDate)),
                                    IndexCoun: SKULength + 1,
                                    IsUsedTNA: getSkus.isUsedTNA
                                });

                                let getDateValue = new Date(getStyle.SKUInfo[SKULength].ExFacDate);
                                var dd = getDateValue.getDate();
                                var mm = getDateValue.getMonth() + 1;
                                var yyyy = getDateValue.getFullYear();

                                if (dd < 10) {
                                    dd = '0' + dd;
                                }
                                if (mm < 10) {
                                    mm = '0' + mm;
                                }
                                let today = yyyy + '-' + mm + '-' + dd;
                                getStyle.SKUInfo[SKULength].ExFacDate = today;

                                
                                let getRecievedDateValue = new Date(getStyle.SKUInfo[SKULength].ReceivedDate);
                                var dd = getRecievedDateValue.getDate();
                                var mm = getRecievedDateValue.getMonth() + 1;
                                var yyyy = getRecievedDateValue.getFullYear();

                                if (dd < 10) {
                                    dd = '0' + dd;
                                }
                                if (mm < 10) {
                                    mm = '0' + mm;
                                }
                                let Recievedtoday = yyyy + '-' + mm + '-' + dd;
                                getStyle.SKUInfo[SKULength].ReceivedDate = Recievedtoday;


                                // FacDate format
                                if (getStyle.SKUInfo[SKULength].FacDate) {
                                    let getDateValue2 = getStyle.SKUInfo[SKULength].FacDate === null ? '' : new Date(getStyle.SKUInfo[SKULength].FacDate);
                                    let dd2 = getDateValue2.getDate();
                                    let mm2 = getDateValue2.getMonth() + 1;
                                    let yyyy2 = getDateValue2.getFullYear();

                                    if (dd2 < 10) {
                                        dd2 = '0' + dd2;
                                    }
                                    if (mm2 < 10) {
                                        mm2 = '0' + mm2;
                                    }
                                    let today2 = yyyy2 + '-' + mm2 + '-' + dd2;
                                    getStyle.SKUInfo[SKULength].FacDate = today2;
                                }


                            }
                        });
                    });

                    values.StyleLists.forEach((getStyle, i) => {
                        getStyle.SKUInfo.forEach((getStyle1, j) => {
                            skuCommentPurchaseOrders.forEach((getComment, asI) => {
                                if (getComment.indexName === getStyle.IndexName && getComment.skuIndexName === getStyle1.SkuIndexName) {
                                    getStyle1.Comments.push({
                                        Date: new Date(getComment.createddate), ModifiedDate: new Date(getComment.modifieddate),
                                        PurOrdSkuComments: getComment.purOrdSkuComments, PurOrdSkuCommentsID: getComment.purOrdSkuCommentsID,
                                        IndexName: '', SkuIndexName: '',
                                        IsDeleted: 0,//PurchaseOrderID: getComment.purchaseOrderID,
                                    });
                                }
                            });
                        });
                    });

                    values.StyleLists.forEach((getStyle, i) => {
                        getStyle.SKUInfo.forEach((getStyle1, j) => {
                            skuCommentPurchaseOrders.forEach((getComment, asI) => {
                                getStyle1.IndexName = '';
                                getStyle.IndexName = '';
                            });
                        });
                    });

                    if (DocsPurchaseOrders.length > 0) {
                        DocsPurchaseOrders.forEach((docs, i) => {
                            filevals.push({
                                PurOrderFileID: docs.purOrderFileID,
                                FileName: docs.fileName,
                                FilePath: docs.filePath,
                                FileType: docs.fileType,
                                IsDeleted: 0
                            })
                        });
                        setFiles(filevals);
                    }
                    setInputFields(values);
                }
            })
                .catch(() => { })
                .finally(() => {
                    // hideLoader();
                });
        }


        else {
            setInputFields(EmptyInputFields);
        }
    }, []);

    const handleCloses = () => setShow(false);
    const handleShow = () => setShow(true);

    function LoadBuyerBasedValues(ID) {
        if (!isNaN(ID)) {
            StyleService.LoadBrandThemeStoryEndCustomer(5, parseInt(ID)).then((response) => {
                if (response.data) {
                    let BuyerInfoList = response.data;
                    BuyerListOptions = response.data;
                    // let respected_Brand = BuyerInfoList.filter(x => x.buyerMapID === 1);
                    setBrandList(BuyerListOptions.filter(o => BuyerBrandDetails.some(({ buyerId, brandId }) => o.buyerID === buyerId && o.value === brandId)));
                    let respected_EndCustomer = BuyerInfoList.filter(x => x.buyerMapID === 2);
                    setEndCustomerList(respected_EndCustomer);
                }
            }).catch(() => { });

            StyleService.LoadSeason(2, parseInt(ID))
                .then((response) => {
                    if (response.data) {
                        setSeasonList(response.data);
                    }
                })
                .catch(() => { });
        }
    }

    function LoadPOStyles(POStyle, index) {
        showLoader();
        if (POStyle.Operation === 1) {
            PurchaseOrderService.LoadPOStyleList(POStyle).then((response) => {
                if (response.data) {
                    hideLoader();
                    setstyleList(response.data);
                    if (response.data.length === response.data[0].styleTNACount) {
                        setallstyleTNAcreated(true);
                    }
                }
            }).catch(() => { hideLoader(); });
        } else {
            // setres(1);
            PurchaseOrderService.LoadPOStyleList(POStyle).then((response) => {
                if (response.data) {
                    hideLoader();
                    let values = { ...inputFields };
                    if (index === 1 && values.StyleLists[0].StyleID === 0) {
                        index = 0;
                    }

                    values.StyleLists[index].AppliedSKUs = [];

                    let TotalLength = localStorage.getItem('TotalImport') === undefined || localStorage.getItem('TotalImport') === null ? 0 : parseInt(localStorage.getItem('TotalImport'));
                    let TotalAssignLength = localStorage.getItem('TotalAssignImport') === undefined || localStorage.getItem('TotalAssignImport') === null ? 0 : parseInt(localStorage.getItem('TotalAssignImport'));
                    let getImport = localStorage.getItem('GetChkStyleImport') === undefined || localStorage.getItem('GetChkStyleImport') === null ? false : localStorage.getItem('GetChkStyleImport');
                    let CountImport = 0;
                    CountImport++;

                    response.data.forEach((asSKU, i) => {
                        let getSize = values.StyleLists[index].SKUInfo[0].Sizes;
                        let getFinalSizes = [];
                        getSize.forEach((pushEachSize, i) => {
                            getFinalSizes.push({ SizeID: pushEachSize.SizeID, BindSizeName: getSize[i].BindSizeName, [getSize[i].BindSizeName]: '', AssgignValue: '', IsDeleted: 0, IndexName: '' });
                        })
                        let getSizeHeading = values.StyleLists[index].SKUInfo[0].SizeHeading;

                        values.StyleLists[index].AppliedSKUs.push({
                            SKUFrontPicName: asSKU.skuFrontPicName, SKUName: asSKU.skuName, SKUFrontPicPath: asSKU.skuFrontPicPath,
                            SKUID: asSKU.value
                        })

                        // if (getImport === 'true') {
                        values.StyleLists[index].SKUInfo[i] = ({
                            SKUID: asSKU.value, SKUName: asSKU.skuName, SKUFrontPicPath: asSKU.skuFrontPicPath, SKUFrontPicName: asSKU.skuFrontPicName,
                            Quantity: '', Price: '', Value: '', ShipmentMode: IsProduction === 1 ? '0' : '1', POIDPONO: '', POFocusPONo: '',
                            ExFacDate: '',ReceivedDate:'', FacDate: '', EndCustomerID: 0, EndCustomerName: '', DestinationID: 0, DestinationName: '', IsDeleted: 0, IndexName: '', SkuIndexName: '',
                            EnableComments: 0, EnterComment: '', SaveComment: false, CheckSKU: 0, OpenChkPopUP: 0, PurchaseOrderSkuID: 0, PurchaseOrderID: 0,
                            Sizes: getFinalSizes, SizeHeading: i === 0 ? getFinalSizes : [],
                            Comments: [{
                                PurOrdSkuCommentsID: 0, PurOrdSkuComments: '', IndexName: '0', SkuIndexName: '0',
                                IsDeleted: 0, //Date: moment(new Date()).format('DD-MM-YYYY'),
                                Date: new Date(), Order: 0,
                            }],
                            IndexCoun: i + 1,
                            IsUsedTNA: false
                        })
                        // }
                    });
                    TotalAssignLength += CountImport;

                    if (getImport === false) {
                        setInputFields(values);
                    } else {
                        if (TotalLength === TotalAssignLength) {
                            setInputFields(values);
                            localStorage.removeItem('TotalAssignImport');
                            localStorage.removeItem('TotalImport');
                            localStorage.removeItem('GetChkStyleImport');
                        } else {
                            localStorage.setItem('TotalAssignImport', (TotalAssignLength));
                        }
                    }
                }
            }).catch(() => { })
                .finally(() => { hideLoader(); });
        }
    }

    const handleChangecheckbox = (e) => {
        const values = { ...inputFields };
        if (e.target.checked === true) {
            values.ShowHidepacking = 1;
            setInputFields(values);
        } else {
            values.ShowHidepacking = 0;
            setInputFields(values);
        }
    }

    const handleChange = (e, index, subIndex, subChildIndex1, subChildIndex2) => {
       
        const values = { ...inputFields };
        setres(1);
       

        if (subChildIndex2 === "PackingPlanID" && e !== null) {

            values.PackingPlanID = e.value
            values.StyleLists.map(s => {
                s.SKUInfo.map(sk => {
                    sk.PackingPlanID = e.value
                })
            })

        }
        if (subChildIndex2 === "PackingSubPlanID" && e !== null) {

            values.StyleLists[index].SKUInfo[subIndex].PackingPlanID = e.target.value;
            //setInputFields(values);
        }



        if (index === '' && subIndex === '' && subChildIndex1 === '') {
            let getBuyerID; let getSeasonID; let getBrandID;
            if (e === null) {
                let value = e === null ? '' : e.value;
                //setIsHideFocusPONo(true);
                //values.IsFocusPONoOption = 0;
                // setInputFields(inputs => ({ ...inputs, [subChildIndex2]: value }));
                values[subChildIndex2] = value;
                getBuyerID = subChildIndex2 === 'BuyerID' ? value : getSeasonID = subChildIndex2 === 'SeasonID' ? value : subChildIndex2 === 'BrandID' ? value : '';
                if (subChildIndex2 === 'BuyerID' || subChildIndex2 === 'BrandID') {
                    if (subChildIndex2 === 'BuyerID') {
                        values.BuyerID = '';
                        values.BrandID = ''; values.SupplierID = ''; values.EndCustomerID = '';
                    }
                    if (subChildIndex2 === 'BrandID') {
                        values.BrandID = '';
                    }
                    values.TotalQuantity = 0; values.TotalValue = 0;
                    values.StyleLists = [{
                        StyleID: 0, StyleName: '', StyleNo: '', IsDeleted: 0, ArrangeData: 0, IndexName: '', CheckALLSKU: 0, AppliedSKUs: [],
                        PurchaseOrderID: 0, PurOrderStyleDetailID: 0,
                        SKUInfo: [{
                            SKUID: 0, SKUName: '', SKUFrontPicPath: '', SKUFrontPicName: '', Quantity: '', Price: '', Value: '', ShipmentMode: IsProduction === 1 ? '0' : '1', POIDPONO: '', POFocusPONo: '',
                            ExFacDate: '',ReceivedDate:'', FacDate: '', EndCustomerID: 0, EndCustomerName: '', DestinationID: 0, DestinationName: '', IsDeleted: 0, IndexName: '', SkuIndexName: '',
                            EnableComments: 0, EnterComment: '', SaveComment: false, CheckSKU: 0, OpenChkPopUP: 0, PurchaseOrderSkuID: 0, PurchaseOrderID: 0,
                            Sizes: [], SizeHeading: [], IndexCoun: 1,
                            Comments: [{
                                PurOrdSkuCommentsID: 0, PurOrdSkuComments: '', IndexName: '0', SkuIndexName: '0',
                                IsDeleted: 0, //Date: moment(new Date()).format('DD-MM-YYYY'),
                                Date: new Date(), Order: 0,
                            }],
                        }],
                    }]
                }
                setInputFields(values);
            } else if (e.target === undefined) {
                if (subChildIndex2 === 'BuyerID' && e.isPOFocusPONo) {
                    if (e.label === "Focus International" && IsProduction === 2) {
                        setIsHideFocusPONo(false);
                    } else {
                        setIsHideFocusPONo(true);
                    }
                }
                else if (subChildIndex2 === 'BuyerID') {
                    values.IsFocusPONoOption = 0;
                    setIsHideFocusPONo(true);
                }
                getBuyerID = subChildIndex2 === 'BuyerID' ? parseInt(e.value) : values.BuyerID !== '' ? parseInt(values.BuyerID) : 0;
                getSeasonID = subChildIndex2 === 'SeasonID' ? parseInt(e.value) : values.SeasonID !== '' ? parseInt(values.SeasonID) : 0;
                getBrandID = subChildIndex2 === 'BrandID' ? parseInt(e.value) : values.BrandID !== '' ? parseInt(values.BrandID) : 0;


                let value = e === null ? '' : e.value;
                setInputFields(inputs => ({ ...inputs, [subChildIndex2]: value }));
                if (subChildIndex2 === 'BuyerID') {
                    LoadBuyerBasedValues(parseInt(value));
                }

                else if (subChildIndex2 === 'BrandID') {
                    let packingoption = []
                    FinalinspectionService.GetTNAFinalinspecTestingReportList(3, getBuyerID, getBrandID).then((res) => {
                        if (res.data) {
                            res.data.map((x, index) => {
                                packingoption.push({
                                    value: x.comTempID,
                                    label: x.typeName,
                                })

                            })

                        }
                        setpackingoption(packingoption)
                    })

                }
                if (getBuyerID !== 0 && getSeasonID !== 0 && getBrandID !== 0) {
                    localStorage.setItem('IsTopChanged', (true));
                    let POStyle = { EmployeeMenuPermissionID: currentUser.employeeMenuPermissionID, Operation: 1, BuyerID: getBuyerID, SeasonID: getSeasonID, BrandID: getBrandID, StyleID: 0, IsProduction: IsProduction }
                    LoadPOStyles(POStyle);




                    values.StyleLists = [{
                        StyleID: 0, StyleName: '', StyleNo: '', IsDeleted: 0, ArrangeData: 0, IndexName: '', CheckALLSKU: 0, AppliedSKUs: [],
                        PurchaseOrderID: 0, PurOrderStyleDetailID: 0,
                        SKUInfo: [{
                            SKUID: 0, SKUName: '', SKUFrontPicPath: '', SKUFrontPicName: '', Quantity: '', Price: '', Value: '', ShipmentMode: IsProduction === 1 ? '0' : '1', POIDPONO: '', POFocusPONo: '',
                            ExFacDate: '',ReceivedDate:'', FacDate: '', EndCustomerID: 0, EndCustomerName: '', DestinationID: 0, DestinationName: '', IsDeleted: 0, IndexName: '', SkuIndexName: '',
                            EnableComments: 0, EnterComment: '', SaveComment: false, CheckSKU: 0, OpenChkPopUP: 0, PurchaseOrderSkuID: 0, PurchaseOrderID: 0,
                            Sizes: [], SizeHeading: [], IndexCoun: 1, IsUsedTNA: false,
                            Comments: [{
                                PurOrdSkuCommentsID: 0, PurOrdSkuComments: '', IndexName: '0', SkuIndexName: '0',
                                IsDeleted: 0,
                                Date: new Date(), Order: 0,
                            }],
                        }],
                    }]
                    setTimeout(() => {
                        if (subChildIndex2 === 'BuyerID' || subChildIndex2 === 'SeasonID' || subChildIndex2 === 'BrandID') {
                            if (subChildIndex2 === 'BuyerID') {
                                values.BrandID = '';
                                values.EndCustomerID = '';
                            }
                            values[subChildIndex2] = value;
                            setInputFields(values);
                        }
                    }, 100)
                }


            } else if (e.target.name === 'Remarks' || e.target.name === 'IDPONOOption' || e.target.name === 'IsFocusPONoOption' || e.target.name === 'DestinationOption' ||e.target.name === 'ReceivedDateOption' || e.target.name === 'EndCustomerOption' || e.target.name === 'ShowHideImage') {

                //values.IsFocusPONoOption = 0;
                if (e.target.checked === true) {
                    values[e.target.name] = 1;
                    setInputFields(values);
                } else {
                    values[e.target.name] = 0;
                    setInputFields(values);
                }
                if (e.target.name === 'Remarks') {
                    let inputtext = ''
                    if (e.target.value.trim() !== '') {
                        inputtext = e.target.value

                    }
                    values.Remarks = inputtext
                    setInputFields(values)
                }

                if (e.target.name === 'IDPONOOption') {
                    setInputFields(inputs => ({ ...inputs, IDPoNo: '' }));
                } else if (e.target.name === 'IsFocusPONoOption') {

                    setInputFields(inputs => ({ ...inputs, FocusPONo: '' }));
                }
                else if (e.target.name === 'DestinationOption') {
                    setInputFields(inputs => ({ ...inputs, DestinationID: '' }));
                } 
                else if (e.target.name === 'EndCustomerOption') {
                    setInputFields(inputs => ({ ...inputs, EndCustomerID: '' }));
                }
                else if (e.target.name === 'ReceivedDateOption') {
                    setInputFields(inputs => ({ ...inputs, ReceivedDateOn: '' }));
                }
                if (props.location.state !== undefined) {
                    if (e.target.name === 'IDPONOOption' && e.target.checked === false) {
                        setInputFields(inputs => ({ ...inputs, IDPoNo: props.location.state.params.idpono }));
                    } else if (e.target.name === 'IsFocusPONoOption' && e.target.checked === false) {
                        setInputFields(inputs => ({ ...inputs, FocusPONo: props.location.state.params.focusPONo }));
                    }
                    else if (e.target.name === 'DestinationOption' && e.target.checked === false) {
                        setInputFields(inputs => ({ ...inputs, DestinationID: props.location.state.params.destination }));
                    } else if (e.target.name === 'EndCustomerOption' && e.target.checked === false) {
                        setInputFields(inputs => ({ ...inputs, EndCustomerID: props.location.state.params.endCustomerID }));
                    }
                    else if (e.target.name === 'ReceivedDateOption' && e.target.checked === false) {
                        setInputFields(inputs => ({ ...inputs, ReceivedDateOn: props.location.state.params.receivedOn }));
                    }
                }
                setInputFields(values);
            } else {
               ;
                values.IsFocusPONoOption = 0;
                let inputText = '';
                if (e.target.value.trim() !== '') {
                    inputText = e.target.value;
                }
                values[e.target.name] = inputText;
                // if (e.target.name === 'IDPoNo') {
                //     var re1 = /^[0-9\b]+$/;
                //     if (re1.test(e.target.value)) {
                //         values[e.target.name] = e.target.value;
                //     } else {
                //         Nodify('Warning!', 'warning', "Only numbers are allowed");
                //     }
                // } else {
                // values[e.target.name] = e.target.value;
                // }
                setInputFields(values);
                if (e.target.name === 'BuyerID') {
                    LoadBuyerBasedValues(parseInt(e.target.value));
                }
                setInputFields(values);
            }
            if (subChildIndex2 === 'CurrencyID' && e !== null) {
                setCurrencysymbol(e.currencySymbol);
            } else if (subChildIndex2 === 'CurrencyID' && e === null) { setCurrencysymbol(''); }
        } else if (index !== '' && subIndex === '' && subChildIndex1 === '') {
            values.StyleLists[index][e.target.name] = e.target.value;
            if (e.target.value === '0') {
                values.StyleLists[index].StyleNo = '';
            }
            if (e.target.name === 'StyleID') {
                values.StyleLists[index].AppliedSKUs = [];
                values.StyleLists[index].SKUInfo = [{
                    SKUID: 0, SKUName: '', SKUFrontPicPath: '', SKUFrontPicName: '', Quantity: '', Price: '', Value: '', ShipmentMode: IsProduction === 1 ? '0' : '1', POIDPONO: '', POFocusPONo: '',
                    ExFacDate: '',ReceivedDate:'', FacDate: '', EndCustomerID: 0, EndCustomerName: '', DestinationID: 0, DestinationName: '', IsDeleted: 0, IndexName: '',
                    EnableComments: 0, EnterComment: '', SaveComment: false, CheckSKU: 0, OpenChkPopUP: 0,
                    Sizes: [], SizeHeading: [], SkuIndexName: '',
                    Comments: [], IndexCoun: 1, IsUsedTNA: false
                }];
                for (var getStyle = 0; getStyle < getstyleList.length; getStyle++) {
                    if (parseInt(e.target.value) === parseInt(getstyleList[getStyle].value)) {
                        values.StyleLists[index].StyleNo = (getstyleList[getStyle].styleNo);
                        let SplitID = getstyleList[getStyle].sizeID.split(",");
                        let SplitName = getstyleList[getStyle].sizeName.split(",");
                        let getFinalSizes = [];
                        SplitID.forEach((pushEachSize, i) => {
                            let assignField = SplitName[i];
                            getFinalSizes.push({ SizeID: pushEachSize, BindSizeName: SplitName[i], [assignField]: '', AssgignValue: '', IsDeleted: 0, IndexName: '' });
                        })
                        values.StyleLists[index].SKUInfo[0].SizeHeading = getFinalSizes;
                        values.StyleLists[index].SKUInfo[0].Sizes = getFinalSizes;
                        let POStyle = { EmployeeMenuPermissionID: currentUser.employeeMenuPermissionID, Operation: 2, BuyerID: parseInt(values.BuyerID), SeasonID: parseInt(values.SeasonID), BrandID: parseInt(values.BrandID), StyleID: parseInt(e.target.value), IsProduction: IsProduction }
                        LoadPOStyles(POStyle, index);
                    }
                }
            }
        } else if (index !== '' && subIndex !== '' && subChildIndex1 === '') {
            if (e === null) {
                if (subChildIndex2 === 'DestinationID') {
                    values.StyleLists[index].SKUInfo[subIndex].DestinationID = '';
                }

                else if (subChildIndex2 === "PackingSubPlanID") {
                    values.StyleLists[index].SKUInfo[subIndex].PackingPlanID = '';
                }


                else {
                    values.StyleLists[index].SKUInfo[subIndex].EndCustomerID = '';
                }
            } else {
                if (subChildIndex2 === 'DestinationID' || subChildIndex2 === 'EndCustomerID') {
                    if (e.target.value !== undefined) {
                        if (subChildIndex2 === 'DestinationID') {
                            if (e.target.value !== "0") {
                                values.StyleLists[index].SKUInfo[subIndex].DestinationID = e.target.value;
                            }
                            else {
                                values.StyleLists[index].SKUInfo[subIndex].DestinationID = 0;
                                //values.DestinationOption = 0;
                            }
                        } else {
                            values.StyleLists[index].SKUInfo[subIndex].EndCustomerID = parseInt(e.target.value);
                        }
                    }
                } else {
                    if (e.target.name === 'SKUID') {
                        let getImages = values.StyleLists[index].AppliedSKUs;
                        getImages.forEach((asImg, i) => {
                            if (parseInt(asImg.SKUID) === parseInt(e.target.value)) {
                                values.StyleLists[index].SKUInfo[subIndex].SKUFrontPicPath = asImg.SKUFrontPicPath;
                                values.StyleLists[index].SKUInfo[subIndex].SKUFrontPicName = asImg.SKUFrontPicName;
                                values.StyleLists[index].SKUInfo[subIndex].SKUID = parseInt(e.target.value);
                            } else if (e.target.value === "0") {
                                values.StyleLists[index].SKUInfo[subIndex].SKUFrontPicPath = "";
                                values.StyleLists[index].SKUInfo[subIndex].SKUFrontPicName = "Logo";
                                values.StyleLists[index].SKUInfo[subIndex].SKUID = parseInt(e.target.value);
                            }
                        })
                    }
                    if (e.target.name === 'Price' || e.target.name === 'Quantity') {
                        let ChkValue = parseFloat(e.target.value);
                        if (!ChkValue) {
                            if (ChkValue !== 0) {
                                e.target.value = '';
                            }
                        }

                        if (e.target.name === 'Price' && parseFloat(e.target.value) && e.target.value !== '') {
                            var re1 = /^([0-9]+[\.]?[0-9]?[0-9]?|[0-9]+)/g;
                            let val = re1.exec(e.target.value);
                            if (val !== null && e.target.value !== '') {
                                e.target.value = val[0];
                            }
                        }

                        values.StyleLists[index].SKUInfo[subIndex][e.target.name] = e.target.value;
                        let quantity; let price;
                        quantity = values.StyleLists[index].SKUInfo[subIndex]['Quantity'] === '' ? 0 : values.StyleLists[index].SKUInfo[subIndex]['Quantity'];
                        price = values.StyleLists[index].SKUInfo[subIndex]['Price'] === '' ? 0 : values.StyleLists[index].SKUInfo[subIndex]['Price'];

                        var multiplyvalue = parseInt(quantity) * parseFloat(price);
                        values.StyleLists[index].SKUInfo[subIndex]['Value'] = multiplyvalue;
                    }
                    // else if("EndCustomerID")
                    // {
                    //     if(e.target.value === 0)
                    //     {
                    //         values.StyleLists[index].SKUInfo[subIndex].EndCustomerID = '';
                    //     }
                    // }
                    // else if (e.target.name === 'POIDPONO')
                    //     if (re1.test(e.target.value)) { {
                    //     var re1 = /^[0-9\b]+$/;
                    //         values[e.target.name] = e.target.value;
                    //         values.StyleLists[index].SKUInfo[subIndex][e.target.name] = e.target.value;
                    //     } else {
                    //         Nodify('Warning!', 'warning', "Only numbers are allowed");
                    //     }
                    // }
                    else if (e.target.name === "POFocusPONo" || e.target.name === "POIDPONO") {
                        let inputText = '';
                        if (e.target.value.trim() !== '') {
                            inputText = e.target.value;
                        }
                        values.StyleLists[index].SKUInfo[subIndex][e.target.name] = inputText;
                        setInputFields(values);
                    }
                    else {
                        values.StyleLists[index].SKUInfo[subIndex][e.target.name] = e.target.value;
                    }

                }
            }
        } else if (index !== '' && subIndex !== '' && subChildIndex1 !== '') {
            values.StyleLists[index].SKUInfo[subIndex].Sizes[subChildIndex1][e.target.name] = e.target.value;
        }
        if (index !== '' || subIndex !== '' || subChildIndex1 !== '') {
            let totalvalue = 0; let totalqty = 0;
            values.StyleLists.forEach((StyleLength, l) => {
                if (StyleLength.IsDeleted !== 1) {
                    StyleLength.SKUInfo.forEach((assignTotalValue, m) => {
                        if (assignTotalValue.Value !== '' && assignTotalValue.IsDeleted !== 1) {
                            totalvalue += parseFloat(assignTotalValue.Value);
                            values.TotalValue = parseFloat(totalvalue).toFixed(2);
                        }
                        if (assignTotalValue.Quantity !== '' && parseInt(assignTotalValue.Quantity) !== 0 && assignTotalValue.IsDeleted !== 1) {
                            totalqty += parseInt(assignTotalValue.Quantity);
                            values.TotalQuantity = totalqty;
                        }
                    });
                }
            });
            setInputFields(values);
            //CalculateonChange();
        }
        setres(0);
    }

    const CalculateonChange = () => {
        const values = { ...inputFields };
        let totalvalue = 0; let totalqty = 0
        for (var m = 0; m < values.StyleLists.length; m++) {
            values.StyleLists[m].SKUInfo.forEach((assignTotalValue, i) => {
                if (assignTotalValue.Value !== '' && assignTotalValue.Quantity !== '') {
                    totalvalue = totalvalue + parseFloat(assignTotalValue.Value);
                    totalqty = totalqty + parseInt(assignTotalValue.Quantity);
                }
            });
        }
        values.TotalValue = parseFloat(totalvalue).toFixed(2);
        values.TotalQuantity = totalqty;
        setInputFields(values);
    }

    const CustomInput = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
                // style={{ border: submitted && props.value === '' ? '1px solid red' : '' }}
                style={{
                    border: (submitted && props.value === '') && (submitted && inputFields.ReceivedDateOption !== 1) ? '1px solid red' : ''
                }}
            />
        )
    }
    const CustomInputExfac = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
                style={{ border: submitted && props.value === '' ? '1px solid red' : '' }}
            />
        )
    }

    const CustomInputfac = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
                style={{ border: submitted && props.value === '' ? '1px solid red' : '' }}
            />
        )
    }
    const PopupExFacDate = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
                style={{ border: submitted && props.value === '' ? '1px solid red' : '' }}
            />
        )
    }

    const PopupReceivedDate = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
                style={{ border: submitted && props.value === '' ? '1px solid red' : '' }}
            />
        )
    }




    const Drag = (props) => {
        return <Draggable handle=".modal-title"><ModalDialog {...props} /></Draggable>
    }

    const handleChangeDate = (e, index, SubIndex, FieldName) => {
        const values = { ...inputFields };
        if (FieldName === "ReceivedOn") {
            if (e !== null) {
                setInputFields(inputs => ({ ...inputs, [FieldName]: e }));
            }
            else {
                setInputFields(inputs => ({ ...inputs, [FieldName]: '' }));
            }
        }
        else if (FieldName === "ReceivedDate"){
            if (e.target.value) {
                values.StyleLists[index].SKUInfo[SubIndex][e.target.name] = e.target.value;
            } else {
                values.StyleLists[index].SKUInfo[SubIndex][e.target.name] = '';
            }
            setInputFields(values);
        }
        else if (FieldName === "ExFacDate" || FieldName === "FacDate") {
            // if (e !== null) {
            //     values.StyleLists[index].SKUInfo[SubIndex][FieldName] = e;
            // }
            // else {
            //     values.StyleLists[index].SKUInfo[SubIndex][FieldName] = '';
            // }
            if (e.target.value) {
                values.StyleLists[index].SKUInfo[SubIndex][e.target.name] = e.target.value;
            } else {
                values.StyleLists[index].SKUInfo[SubIndex][e.target.name] = '';
            }
            setInputFields(values);
        }
        
        
        else if (FieldName === "PopupExFacDate") {
            if (e.target.value) {
                values.ExFacDate = e.target.value;
            } else {
                values.ExFacDate = '';
            }
            setInputFields(values);
        } else if (FieldName === "PopupFacDate") {
            if (e.target.value) {
                values.FacDate = e.target.value;
            } else {
                values.FacDate = '';
            }
            setInputFields(values);
        }
        else if (FieldName === "PopupReceivedDate") {
            if (e.target.value) {
                values.ReceivedDate = e.target.value;
            } else {
                values.ReceivedDate = '';
            }
            setInputFields(values);
        }
    }

    const onFocus = (e, field) => {
        var val = e.target.value;
        e.target.value = '';
        e.target.value = val;
    }

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/PurchaseOrderList')
        } else {
            window.location.reload();
        }
    }

    const AddStyleFields = (Operation, index) => {

        const values = { ...inputFields };
        let EmptyList;
        let count = index; count++;
        let getFinalSizes = [];

        for (var getStyle = 0; getStyle < getstyleList.length; getStyle++) {
            if (parseInt(values.StyleLists[index].StyleID) === parseInt(getstyleList[getStyle].value)) {
                // values.StyleLists[index].StyleNo = (getstyleList[getStyle].styleNo);
                let SplitID = getstyleList[getStyle].sizeID.split(",");
                let SplitName = getstyleList[getStyle].sizeName.split(",");
                SplitID.forEach((pushEachSize, i) => {
                    let assignField = SplitName[i];
                    getFinalSizes.push({ SizeID: pushEachSize, BindSizeName: SplitName[i], [assignField]: '', AssgignValue: '', IsDeleted: 0, IndexName: '' });
                })
            }
        }
        if (Operation === "Add") {
            let getCopy = values.StyleLists[index];
            EmptyList = {
                PurOrderStyleDetailID: 0, StyleID: 0, StyleName: '', StyleNo: '', IsDeleted: 0, ArrangeData: 0, IndexName: '', CheckALLSKU: 0, AppliedSKUs: [],
                SKUInfo: [{
                    PurchaseOrderSkuID: 0, SKUID: 0, SKUName: '', SKUFrontPicPath: '', SKUFrontPicName: '', Quantity: '', Price: '', Value: '', ShipmentMode: IsProduction === 1 ? '0' : '1', POIDPONO: '', POFocusPONo: '',
                    ExFacDate: '',ReceivedDate:'', FacDate: '', EndCustomerID: 0, EndCustomerName: '', DestinationID: 0, DestinationName: '', IsDeleted: 0, IndexName: '',
                    EnableComments: 0, EnterComment: '', SaveComment: false, CheckSKU: 0, OpenChkPopUP: 0,
                    Sizes: [], SizeHeading: [], SkuIndexName: '',
                    Comments: [], IndexCoun: 1, IsUsedTNA: false
                }],
                PurchaseOrderID: getCopy.PurchaseOrderID,
            }
        } else {
            let getCopy = values.StyleLists[index];
            let getSizes = values.StyleLists[index].SKUInfo;
            let EmptySku = []; let EmptySize = [{ passValue: [] }];

            getCopy.SKUInfo.forEach((getSku, i) => {
                EmptySku.push({
                    SKUID: getSku.SKUID, SKUName: getSku.SKUName, SKUFrontPicPath: getSku.SKUFrontPicPath, SKUFrontPicName: getSku.SKUFrontPicName, Quantity: getSku.Quantity,
                    Price: getSku.Price, Value: getSku.Value, ShipmentMode: getSku.ShipmentMode, POIDPONO: getSku.POIDPONO, POFocusPONo: getSku.POFocusPONo,
                    ExFacDate: getSku.ExFacDate,ReceivedDate: getSku.ReceivedDate, FacDate: getSku.FacDate, EndCustomerID: getSku.EndCustomerID, EndCustomerName: getSku.EndCustomerName, DestinationID: getSku.DestinationID, DestinationName: getSku.DestinationName,
                    IsDeleted: getSku.IsDeleted, IndexName: '', SkuIndexName: '',
                    EnableComments: getSku.EnableComments, EnterComment: getSku.EnterComment, SaveComment: getSku.SaveComment, CheckSKU: getSku.CheckSKU, OpenChkPopUP: getSku.OpenChkPopUP,
                    Comments: [], PackingPlanID: getSku.PackingPlanID,
                    Sizes: [], SizeHeading: i === 0 ? getFinalSizes : [],
                    PurchaseOrderSkuID: 0, IndexCoun: i + 1,
                    IsUsedTNA: false
                    // PurchaseOrderSkuID: getSku.PurchaseOrderSkuID === undefined ? 0 : getSku.PurchaseOrderSkuID,
                })
            });

            let Count = 0;
            getSizes.forEach((as1, i) => {
                EmptySize[Count] = EmptySize[Count] === undefined ? EmptySize[Count] = { passValue: [] } : { passValue: [] };
                as1.Sizes.forEach((as2, j) => {
                    EmptySize[Count].passValue.push({
                        SizeID: as2.SizeID, [as2.BindSizeName]: '', AssgignValue: as2.AssgignValue, IsDeleted: as2.IsDeleted,
                        IndexName: '', BindSizeName: as2.BindSizeName,
                    });
                });
                Count++;
            });

            if (EmptySize.length !== 0) {
                EmptySku.forEach((getSku, i) => {
                    EmptySku[i].Sizes = EmptySize[i].passValue;
                    // getSku.Sizes.push(EmptySize[i].passValue);
                    // getSku.Sizes = EmptySize;
                });
            }

            EmptyList = {
                StyleID: getCopy.StyleID, StyleName: getCopy.StyleName, StyleNo: getCopy.StyleNo, IsDeleted: getCopy.IsDeleted, ArrangeData: getCopy.ArrangeData, IndexName: '',
                CheckALLSKU: 0, SKUInfo: EmptySku, AppliedSKUs: getCopy.AppliedSKUs,
                // PurOrderStyleDetailID: getCopy.PurOrderStyleDetailID,
                PurOrderStyleDetailID: 0,
                PurchaseOrderID: getCopy.PurchaseOrderID,
            }
        }
        if (values.StyleLists[index].StyleID === 0) {
            setSubmitted(true);
            Nodify('Warning!', 'warning', "Kindly select Style Information");
        } else {
            setSubmitted(false);
            values.StyleLists.splice(count, 0, EmptyList);

            let totalvalue = 0; let totalqty = 0;
            values.StyleLists.forEach((StyleLength, l) => {
                if (StyleLength.IsDeleted !== 1) {
                    StyleLength.SKUInfo.forEach((assignTotalValue, m) => {
                        if (assignTotalValue.Value !== '' && assignTotalValue.IsDeleted !== 1) {
                            totalvalue += parseFloat(assignTotalValue.Value);
                            values.TotalValue = parseFloat(totalvalue).toFixed(2);
                        }
                        if (assignTotalValue.Quantity !== '' && parseInt(assignTotalValue.Quantity) !== 0 && assignTotalValue.IsDeleted !== 1) {
                            totalqty += parseInt(assignTotalValue.Quantity);
                            values.TotalQuantity = totalqty;
                        }
                    });
                }
            });
            setInputFields(values);
        }
    };

    const RemoveStyleFields = (index) => {
       ;
        const values = { ...inputFields };
        let PurOrderStyleDetailID = values.StyleLists[index].PurOrderStyleDetailID;
       
        if (PurOrderStyleDetailID && PurOrderStyleDetailID > 0) {
            let Params = {
                Operation: 2,
                PurOrderStyleDetailID: values.StyleLists[index].PurOrderStyleDetailID,
                PurchaseOrderSkuID: 0,
                SKUID: 0
            };
            PurchaseOrderService.CheckPOMappedItem(Params).then(res => {
                if (res.data.outputResult === "-2") {
                    let Title = "Warning!";
                    let message = "This  Purchase Order Style is already mapped.";
                    let type = "warning";
                    Nodify(Title, type, message);
                }
                else {
                    values.StyleLists.splice(index, 1);
                    let totalvalue = 0; let totalqty = 0;
                    values.StyleLists.forEach((StyleLength, l) => {
                        if (StyleLength.IsDeleted !== 1) {
                            StyleLength.SKUInfo.forEach((assignTotalValue, m) => {
                                if (assignTotalValue.Value !== '' && assignTotalValue.IsDeleted !== 1) {
                                    totalvalue += parseFloat(assignTotalValue.Value);
                                    values.TotalValue = parseFloat(totalvalue).toFixed(2);
                                }
                                if (assignTotalValue.Quantity !== '' && parseInt(assignTotalValue.Quantity) !== 0 && assignTotalValue.IsDeleted !== 1) {
                                    totalqty += parseInt(assignTotalValue.Quantity);
                                    values.TotalQuantity = totalqty;
                                }
                            });
                        }
                    });
                    setTimeout(() => { setInputFields(values); }, 200)
                }
            });
        } else {
            values.StyleLists.splice(index, 1);
            let totalvalue = 0; let totalqty = 0;
            values.StyleLists.forEach((StyleLength, l) => {
                if (StyleLength.IsDeleted !== 1) {
                    StyleLength.SKUInfo.forEach((assignTotalValue, m) => {
                        if (assignTotalValue.Value !== '' && assignTotalValue.IsDeleted !== 1) {
                            totalvalue += parseFloat(assignTotalValue.Value);
                            values.TotalValue = parseFloat(totalvalue).toFixed(2);
                        }
                        if (assignTotalValue.Quantity !== '' && parseInt(assignTotalValue.Quantity) !== 0 && assignTotalValue.IsDeleted !== 1) {
                            totalqty += parseInt(assignTotalValue.Quantity);
                            values.TotalQuantity = totalqty;
                        }
                    });
                }
            });
            setTimeout(() => { setInputFields(values); }, 200)
        }

    };

    const AddSKUFields = (Operation, index, subIndex) => {
        const values = { ...inputFields };
       
        let EmptyList;
        let count = subIndex; count++;
        let getFinalSizes = [];
        for (var getStyle = 0; getStyle < getstyleList.length; getStyle++) {
            if (parseInt(values.StyleLists[index].StyleID) === parseInt(getstyleList[getStyle].value)) {
                // values.StyleLists[index].StyleNo = (getstyleList[getStyle].styleNo);
                let SplitID = getstyleList[getStyle].sizeID.split(",");
                let SplitName = getstyleList[getStyle].sizeName.split(",");
                SplitID.forEach((pushEachSize, i) => {
                    let assignField = SplitName[i];
                    getFinalSizes.push({ SizeID: pushEachSize, [assignField]: '', AssgignValue: '', IsDeleted: 0, IndexName: '', BindSizeName: SplitName[i], });
                })
            }
        }

        if (Operation === "Add") {
            EmptyList = {
                SKUID: 0, SKUName: '', SKUFrontPicPath: '', SKUFrontPicName: '', Quantity: '', Price: '', Value: '', ShipmentMode: IsProduction === 1 ? '0' : '1', POIDPONO: '', POFocusPONo: '',
                ExFacDate: '',ReceivedDate:'', FacDate: '', EndCustomerID: 0, EndCustomerName: '', DestinationID: 0, DestinationName: '', IsDeleted: 0, IndexName: '',
                EnableComments: 0, EnterComment: '', SaveComment: false, CheckSKU: 0, OpenChkPopUP: 0, PurchaseOrderSkuID: 0,
                Sizes: getFinalSizes, SizeHeading: [], SkuIndexName: '',
                Comments: [], IndexCoun: 1, IsUsedTNA: false
            }

        } else {
            let getCopy = values.StyleLists[index].SKUInfo[subIndex];
            let EmptySize = [];

            getCopy.Sizes.forEach((as, i) => {
                EmptySize.push({ SizeID: as.SizeID, [as.BindSizeName]: '', AssgignValue: as.AssgignValue, IsDeleted: as.IsDeleted, IndexName: '', BindSizeName: as.BindSizeName, });
            })

            EmptyList = {
                SKUID: getCopy.SKUID, SKUName: getCopy.SKUName, SKUFrontPicPath: getCopy.SKUFrontPicPath, SKUFrontPicName: getCopy.SKUFrontPicName, Quantity: getCopy.Quantity, Price: getCopy.Price,
                Value: getCopy.Value, ShipmentMode: getCopy.ShipmentMode, POIDPONO: getCopy.POIDPONO, POFocusPONo: getCopy.POFocusPONo, ExFacDate: getCopy.ExFacDate,ReceivedDate:getCopy.ReceivedDate, FacDate: getCopy.FacDate, EndCustomerID: getCopy.EndCustomerID,
                EndCustomerName: getCopy.EndCustomerName, DestinationID: getCopy.DestinationID, DestinationName: getCopy.DestinationName, IsDeleted: getCopy.IsDeleted,
                IndexName: '', Comments: getCopy.Comments,
                EnableComments: getCopy.EnableComments, EnterComment: getCopy.EnterComment, SaveComment: false, CheckSKU: 0, OpenChkPopUP: 0,
                Sizes: EmptySize, SizeHeading: [], PackingPlanID: getCopy.PackingPlanID,
                // PurchaseOrderSkuID: getCopy.PurchaseOrderSkuID === undefined ? 0 : getCopy.PurchaseOrderSkuID,
                PurchaseOrderSkuID: 0, SkuIndexName: '', IndexCoun: getCopy.IndexCoun + 1, IsUsedTNA: false
            }
        }
        if (values.StyleLists[index].SKUInfo[subIndex].SKUFrontPicPath === '' || values.StyleLists[index].SKUInfo[subIndex].ExFacDate === '' || (inputFields.ReceivedDateOption ===1 && values.StyleLists[index].SKUInfo[subIndex].ReceivedDate === '')) {
            setSubmitted(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
        } else {
            setSubmitted(false);
            values.StyleLists[index].SKUInfo.splice(count, 0, EmptyList);
            let totalvalue = 0; let totalqty = 0;
            values.StyleLists.forEach((StyleLength, l) => {
                if (StyleLength.IsDeleted !== 1) {
                    let CountIndex = 0;
                    StyleLength.SKUInfo.forEach((assignTotalValue, m) => {
                        if (assignTotalValue.Value !== '' && assignTotalValue.IsDeleted !== 1) {
                            totalvalue += parseFloat(assignTotalValue.Value);
                            values.TotalValue = parseFloat(totalvalue).toFixed(2);
                        }
                        if (assignTotalValue.Quantity !== '' && parseInt(assignTotalValue.Quantity) !== 0 && assignTotalValue.IsDeleted !== 1) {
                            totalqty += parseInt(assignTotalValue.Quantity);
                            values.TotalQuantity = totalqty;
                        }
                        if (assignTotalValue.IsDeleted !== 1) {
                            CountIndex++;
                            assignTotalValue.IndexCoun = CountIndex;
                        }
                    });
                }
            });
            setInputFields(values);
            // LoadCalcCountWithValue(index, subIndex);
        }
    };

    const RemoveSKUFields = (index, subIndex) => {
        const values = { ...inputFields };
        if (values.StyleLists[index].SKUInfo[subIndex].IsUsedTNA) {
            let Params = {
                Operation: 1,
                PurOrderStyleDetailID: values.StyleLists[index].PurOrderStyleDetailID,
                PurchaseOrderSkuID: values.StyleLists[index].SKUInfo[subIndex].PurchaseOrderSkuID,
                SKUID: values.StyleLists[index].SKUInfo[subIndex].SKUID
            };
            PurchaseOrderService.CheckPOMappedItem(Params).then(res => {
                if (res.data.outputResult === "-2") {
                    let Title = "Warning!";
                    let message = "This  Purchase Order Sku is already mapped.";
                    let type = "warning";
                    Nodify(Title, type, message);
                }
                else {
                    values.StyleLists[index].SKUInfo[subIndex].IsDeleted = 1;
                    LoadCalcCountWithValue(index, subIndex);
                }
            });

        } else {
            values.StyleLists[index].SKUInfo[subIndex].IsDeleted = 1;
            LoadCalcCountWithValue(index, subIndex);
        }

    };

    function LoadCalcCountWithValue(index, subIndex) {
        const values = { ...inputFields }; let Count = 0;
        values.StyleLists[index].SKUInfo.forEach((AddIndexCount, i) => {
            if (AddIndexCount.IsDeleted !== 1) {
                Count++;
                AddIndexCount.IndexCoun = Count;
            }
        })
        let totalvalue = 0; let totalqty = 0;
        values.StyleLists.forEach((StyleLength, l) => {
            if (StyleLength.IsDeleted !== 1) {
                StyleLength.SKUInfo.forEach((assignTotalValue, m) => {
                    if (assignTotalValue.Value !== '' && assignTotalValue.IsDeleted !== 1) {
                        totalvalue += parseFloat(assignTotalValue.Value);
                    }
                    if (assignTotalValue.Quantity !== '' && parseInt(assignTotalValue.Quantity) !== 0 && assignTotalValue.IsDeleted !== 1) {
                        totalqty += parseInt(assignTotalValue.Quantity);
                    }
                });
            }
        });
        values.TotalValue = parseFloat(totalvalue).toFixed(2);
        values.TotalQuantity = totalqty;
        setInputFields(values);
    }

    const CalculateQtyandValue = () => {
        const values = { ...inputFields };
        let totalvalue = 0; let totalqty = 0;
        for (var m = 0; m < values.StyleLists.length; m++) {
            if (values.StyleLists[m].IsDeleted !== 1) {
                values.StyleLists[m].SKUInfo.forEach((assignTotalValue, m) => {
                    if (assignTotalValue.Value !== '' && assignTotalValue.IsDeleted !== 1) {
                        totalvalue += parseFloat(assignTotalValue.Value);
                        totalqty += parseInt(assignTotalValue.Quantity);
                    }
                });
            }
        }
        values.TotalValue = parseFloat(totalvalue).toFixed(2);
        values.TotalQuantity = totalqty;
        setInputFields(values);

    }

    let styles = {
        control: styles => ({ ...styles, border: '1px solid red', innerHeight: '100px', outerHeight: '100px' }),
    }

    const GetComments = (index, subIndex) => {
        setComment(false);
        const values = { ...inputFields };
        values.StyleLists[index].SKUInfo[subIndex].EnableComments = 1;
        // if (values.StyleLists[index].SKUInfo[subIndex].Comments.length === 0) {
        //     values.StyleLists[index].SKUInfo[subIndex].Comments[0] = { IsDeleted: 0, Item: '', IndexName: '', Date: new Date(), PurOrdSkuCommentsID: 0, PurchaseOrderID: 0 };
        // } else {
        //     let getLength = values.StyleLists[index].SKUInfo[subIndex].Comments.length;
        //     values.StyleLists[index].SKUInfo[subIndex].Comments[getLength] = { IsDeleted: 0, Item: '', IndexName: '', Date: new Date(), PurOrdSkuCommentsID: 0, PurchaseOrderID: 0 };
        // }
        setInputFields(values);
    };

    const handleFullClose = (index, subindex) => {
        const values = { ...inputFields };
        values.StyleLists[index].SKUInfo[subindex].EnableComments = 0;

        setTimeout(() => {
            setInputFields(values);
        }, 500)
    };

    const ClearCritical = (event, index, subindex) => {
        const values = { ...inputFields };
        values.StyleLists[index].SKUInfo[subindex].EnterComment = '';
        setInputFields(values);
    }

    const handleAllChecked = (e, index) => {
        const values = { ...inputFields };
        let chk = 0;
        if (e.target.checked === true) {
            chk = 1;
        }
        values.StyleLists[index].SKUInfo.forEach((setCond, val) => {
            values.StyleLists[index].CheckALLSKU = chk;
            setCond.CheckSKU = chk;
        })
        setInputFields(values);
    }

    const handleSingleCheck = (e, index, subindex) => {
        const values = { ...inputFields };
        let Count = 0;
        if (e.target.checked === true) {
            values.StyleLists[index].SKUInfo[subindex].CheckSKU = 1;
        } else {
            values.StyleLists[index].SKUInfo[subindex].CheckSKU = 0;
        }
        values.StyleLists[index].SKUInfo.forEach((setCond, val) => {
            if (setCond.CheckSKU === 1) {
                Count++;
            }
        })
        if (Count === values.StyleLists[index].SKUInfo.length) {
            values.StyleLists[index].CheckALLSKU = 1;
        } else {
            values.StyleLists[index].CheckALLSKU = 0;
        }
        setInputFields(values);
    }

    const Delcallback = (value) => {
        // if (value === true) { }
        // setID({ showPopupConfirm: false, Params: [] });
        //setPage("deleteClose");
        props.history.push({ pathname: "/PurchaseOrderList", state: { message: 'Add' } });
    }

    const PopUpChanges = (e, index, subindex) => {
        const values = { ...inputFields };
        let Count = 0;
        // values.StyleLists[index].SKUInfo.forEach((setCond, val) => {
        //     if (setCond.CheckSKU === 1) {
        //         Count++;
        //     }
        // })
        values.StyleLists.forEach((FrCond, val1) => {
            FrCond.SKUInfo.forEach((setCond, val) => {
                if (setCond.CheckSKU === 1) {
                    Count++;
                }
            })
        })

        if (Count > 0) {
            values.StyleLists[index].SKUInfo[subindex].OpenChkPopUP = 1;
            values.ShipmentMode = "";
            values.UnitPrice = '';
            values.ExFacDate = '';
            values.FacDate = '';
            values.ReceivedDate='';
            //values.FocusPONo = '';
            //values.IDPoNo = '';
            values.EndCustomer = '';
            values.Destination = '';
        }
        else {
            Nodify('Warning!', 'warning', "Please select atleast one SKU");
        }
        setInputFields(values);
    }

    const CloseCheckPopUp = (index, subindex) => {
        const values = { ...inputFields };
        values.StyleLists[index].SKUInfo[subindex].OpenChkPopUP = 0;
        setInputFields(values);
    }

    const SaveCheckPopUp = (e, index, subindex) => {
debugger
        const values = { ...inputFields };
        let qty;
        let price;
        values.StyleLists.forEach((frCond) => {
            frCond.SKUInfo.forEach((setCond) => {
                if (setCond.CheckSKU === 1) {
                   
                    setCond.Price = values.UnitPrice !== '' ? values.UnitPrice : setCond.Price;
                    setCond.ReceivedDate = values.ReceivedDate !== '' ? values.ReceivedDate : setCond.ReceivedDate;
                    setCond.ExFacDate = values.ExFacDate !== '' ? values.ExFacDate : setCond.ExFacDate;
                 
                    setCond.ReceivedDate = values.ReceivedDate !== '' ? values.ReceivedDate : setCond.ReceivedDate;
                    setCond.FacDate = values.FacDate !== '' ? values.FacDate : setCond.FacDate;
                    if(values.ShipmentMode !== '')
                     { setCond.ShipmentMode = values.ShipmentMode !== 0 ? values.ShipmentMode : setCond.ShipmentMode;}
                    
                    setCond.POIDPONO = values.IDPONO !== '' ? values.IDPONO : setCond.POIDPONO;
                    setCond.POFocusPONo = values.FocusPONO !== '' ? values.FocusPONO : setCond.POFocusPONo;
                    setCond.EndCustomerID = values.EndCustomer !== '' ? values.EndCustomer : setCond.EndCustomerID;
                    setCond.DestinationID = values.Destination !== '' ? values.Destination : setCond.DestinationID;
                    setCond.OpenChkPopUP = 0;
                    qty = setCond.Quantity !== '' ? setCond.Quantity : 0;
                    price = setCond.Price !== '' ? setCond.Price : 0;
                    setCond.Value = qty * parseFloat(price);
                    setCond.CheckSKU = 0;
                } else {
                    setCond.OpenChkPopUP = 0;
                }
            })
        })
        setReload(getReload + 1);
        setInputFields(values);
        CalculateQtyandValue();
        calculateGrandTotQtyandVal();
    }

    useEffect(() => {
        const values = { ...inputFields };
        setInputFields(values);
    }, [getReload])

    const HandleChangePopUp = (e, FieldName, index, subindex) => {
       
        const values = { ...inputFields }
        if (FieldName === 'ExFacDate' || FieldName === 'FacDate' ||FieldName==='ReceivedDate') {
            if (e !== null) {
                values[FieldName] = e;
            }
            else {
                values[FieldName] = '';
            }
        } else if (FieldName === 'EndCustomer' || FieldName === 'Destination') {
            if (e !== null) {
                values[FieldName] = e.value;
            }
            else {
                values[FieldName] = '';
            }
        } else {
            if (e.target.name === 'IDPONO') {
                let value = { ...getFocus }
                value.Ref = true; value.UP = false;
                value.FocusPONo = false;
                setFocus(value);
                // if (e.target.name === 'IDPONO') {
                //     var re1 = /^[0-9\b]+$/;
                //     if (re1.test(e.target.value)) {
                //         values[e.target.name] = e.target.value;
                //     } else {
                //         Nodify('Warning!', 'warning', "Only numbers are allowed");
                //     }
                // }
                values[e.target.name] = e.target.value;
            } if (e.target.name === 'FocusPONO') {
                let value = { ...getFocus }
                value.Ref = false; value.UP = false; value.FocusPONo = true;
                setFocus(value);
                // if (e.target.name === 'IDPONO') {
                //     var re1 = /^[0-9\b]+$/;
                //     if (re1.test(e.target.value)) {
                //         values[e.target.name] = e.target.value;
                //     } else {
                //         Nodify('Warning!', 'warning', "Only numbers are allowed");
                //     }
                // }
                values[e.target.name] = e.target.value;
            }

            else if (e.target.name === 'UnitPrice') {
                let value = { ...getFocus };
                value.Ref = false; value.UP = true;
                value.FocusPONo = false;
                setFocus(value);
                let ChkValue = parseFloat(e.target.value);
                if (!ChkValue) {
                    if (ChkValue !== 0) {
                        e.target.value = '';
                    }
                }
                if (e.target.name === 'UnitPrice' && parseFloat(e.target.value)) {
                    var re1 = /^([0-9]+[\.]?[0-9]?[0-9]?|[0-9]+)/g;
                    let val = re1.exec(e.target.value);
                    if (val !== null && e.target.value !== '') {
                        e.target.value = val[0];
                    }
                }
                values[e.target.name] = e.target.value;
            } else if (e.target.name === 'ShipmentMode') {
                values[e.target.name] = e.target.value;
            }
        }
        setInputFields(values);
    }

    const handleChangeSize = (e, parentIndex, subIndex, subChildIndex, fieldName) => {
        const values = { ...inputFields }
        if (values.StyleLists[parentIndex].SKUInfo[subIndex].SKUID === '0' || values.StyleLists[parentIndex].SKUInfo[subIndex].SKUID === 0) {
            Nodify('Warning!', 'warning', "Please select SKU");
        } else {
            e.target.value = e.target.value.replace(/\D/g, '');
            values.StyleLists[parentIndex].SKUInfo[subIndex].Sizes[subChildIndex][fieldName] = e.target.value;
            values.StyleLists[parentIndex].SKUInfo[subIndex].Sizes[subChildIndex].AssgignValue = e.target.value;
            setInputFields(values);
            calculateTotQtyandVal(parentIndex, subIndex);
        }
    }

    const calculateTotQtyandVal = (parentIndex, subIndex) => {
        const values = { ...inputFields }
        let totquantity = 0; let totalval = 0;
        values.StyleLists[parentIndex].SKUInfo[subIndex].Sizes.forEach((sizeval) => {
            if (sizeval.AssgignValue !== '') {
                totquantity = totquantity + parseInt(sizeval.AssgignValue);
            }
        })
        if (values.StyleLists[parentIndex].SKUInfo[subIndex].Price !== '') {
            var price = values.StyleLists[parentIndex].SKUInfo[subIndex].Price;
            values.StyleLists[parentIndex].SKUInfo[subIndex].Value = parseFloat(price) * totquantity;
        }
        values.StyleLists[parentIndex].SKUInfo[subIndex].Quantity = totquantity;
        setInputFields(values);
        calculateGrandTotQtyandVal();
    }

    const calculateGrandTotQtyandVal = () => {
        const values = { ...inputFields };
        let totalqty = 0; let totalval = 0;
        for (var m = 0; m < values.StyleLists.length; m++) {
            if (values.StyleLists[m].IsDeleted !== 1) {
                values.StyleLists[m].SKUInfo.forEach((assignTotalValue, m) => {
                    if (assignTotalValue.Quantity !== '' && assignTotalValue.IsDeleted !== 1) {
                        totalqty += parseInt(assignTotalValue.Quantity);
                    }
                    if (assignTotalValue.Value !== '' && assignTotalValue.IsDeleted !== 1) {
                        totalval += parseFloat(assignTotalValue.Value);
                    }
                });
            }
        }
        values.TotalQuantity = totalqty;
        values.TotalValue = parseFloat(totalval).toFixed(2);
        setInputFields(values);

    }

    const SavePurchaseOrder = (e) => {
       
        e.preventDefault();
        let Isvalid = true;
        let ChkValidSKU = 0;
        let Params = {};
        setDisableSave(true);
        const getInputValues = { ...inputFields };
        if (getInputValues.BuyerID === '' || getInputValues.SeasonID === '' || getInputValues.BrandID === '' || getInputValues.Quarter === '0' ||
             getInputValues.SupplierID === '' || getInputValues.CurrencyID === ''
        ) {
            setSubmitted(true);
            setDisableSave(false);
            Isvalid = false;
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
        } else {
            if (getInputValues.DestinationOption === 0) {
                if (getInputValues.DestinationID === '') {
                    setSubmitted(true);
                    setDisableSave(false);
                    if (Isvalid) { Nodify('Warning!', 'warning', "Please enter mandatory(*) fields"); }
                    Isvalid = false;
                }
            }
            if (getInputValues.IDPONOOption === 0) {
                if (getInputValues.IDPoNo === '') {
                    setSubmitted(true);
                    setDisableSave(false);
                    if (Isvalid) { Nodify('Warning!', 'warning', "Please enter mandatory(*) fields"); }
                    Isvalid = false;
                }
            }
            if (!isHideFocusPONo && getInputValues.IsFocusPONoOption === 0) {
                if (getInputValues.FocusPONo === '') {
                    setSubmitted(true);
                    setDisableSave(false);
                    if (Isvalid) { Nodify('Warning!', 'warning', "Please enter mandatory(*) fields"); }
                    Isvalid = false;
                }
            }

            if (getInputValues.EndCustomerOption === 0) {
                if (getInputValues.EndCustomerID === '') {
                    setSubmitted(true);
                    setDisableSave(false);
                    if (Isvalid) { Nodify('Warning!', 'warning', "Please enter mandatory(*) fields"); }
                    Isvalid = false;
                }
            }
            if (getInputValues.ReceivedDateOption === 0) {
                if (getInputValues.ReceivedOn=== '') {
                    setSubmitted(true);
                    setDisableSave(false);
                    if (Isvalid) { Nodify('Warning!', 'warning', "Please enter mandatory(*) fields"); }
                    Isvalid = false;
                }
            }
             
            if(getInputValues.ReceivedOn !== '') // For multiple option
            {
            let ChkRecvDate = new Date(getInputValues.ReceivedOn);
            if (ChkRecvDate.getFullYear().toString().length !== 4) {
                setSubmitted(true);
                setDisableSave(false);
                if (Isvalid) { Nodify('Warning!', 'warning', "Please enter Received On Date field correctly."); }
                Isvalid = false;
            }
           }
            Params = {
                Operation: parseInt(getInputValues.Operation), PurchaseOrderID: parseInt(getInputValues.PurchaseOrderID), BuyerID: parseInt(getInputValues.BuyerID), SeasonID: parseInt(getInputValues.SeasonID), BrandID: parseInt(getInputValues.BrandID),
                QuaterID: parseInt(getInputValues.Quarter),
                // RecievedOn: new Date(getInputValues.ReceivedOn.setDate(getInputValues.ReceivedOn.getDate() + 1)),
                RecievedOn:getInputValues.ReceivedOn === '' ? new Date() : new Date(getInputValues.ReceivedOn.setMinutes(getInputValues.ReceivedOn.getMinutes() + 370)),
                CurrencyID: getInputValues.CurrencyID !== '' ? parseInt(getInputValues.CurrencyID) : 0,
                TrimSupplierID: parseInt(getInputValues.SupplierID), IDPONOOption: parseInt(getInputValues.IDPONOOption),
                EndCustomer: getInputValues.EndCustomerID === '' ? 0 : parseInt(getInputValues.EndCustomerID),
                Destination: getInputValues.DestinationID === '' ? 0 : parseInt(getInputValues.DestinationID),
                IDPONO: (getInputValues.IDPoNo),
                FocusPONo: (getInputValues.FocusPONo),
                IsFocusPONoOption: getInputValues.IsFocusPONoOption === 1 ? true : false,
                EndCustomerOption: parseInt(getInputValues.EndCustomerOption),
                ReceivedDateOption:parseInt(getInputValues.ReceivedDateOption),
                DestinationOption: parseInt(getInputValues.DestinationOption),
                PackingPlanID: getInputValues.PackingPlanID === 0 || getInputValues.PackingPlanID === "" ? 0 : parseInt(getInputValues.PackingPlanID),
                ShowHideImage: parseInt(getInputValues.ShowHideImage), Createdby: currentUser.employeeinformationID, Remarks: (getInputValues.Remarks),
                TotalQuantity: parseFloat(getInputValues.TotalQuantity), TotalValue: parseFloat(getInputValues.TotalValue),
                TypPurchaseOrderStyleDetails: [],
                TypPurOrderSkuDetail: [],
                TypPurchaseOrderSkuComment: [],
                TypPurchaseOrderDocs: getFiles
            }

            getInputValues.StyleLists.forEach((ChkStyle, initial1) => {
                if (ChkStyle.StyleID === '0' || ChkStyle.StyleNo === '') {
                    setSubmitted(true);
                    setDisableSave(false);
                    if (Isvalid) { Nodify('Warning!', 'warning', "Please enter mandatory(*) fields"); }
                    Isvalid = false;
                } else {
                    let ChkSKUValue = 0;
                    ChkStyle.SKUInfo.forEach((chkSku, o) => {
                        if (chkSku.Quantity !== 0 && chkSku.Quantity !== '' && chkSku.IsDeleted !== 1 && chkSku.SKUID !== 0 && chkSku.SKUID !== '0') { // && chkSku.Value !== 0 && chkSku.Value !== '' && chkSku.Price !== 0 && chkSku.Price !== ''
                            ChkSKUValue++;
                        }
                    });

                    if (parseInt(ChkStyle.IsDeleted) !== 1 && ChkSKUValue > 0) {
                        Params.TypPurchaseOrderStyleDetails.push({
                            PurOrderStyleDetailID: isNaN(ChkStyle.PurOrderStyleDetailID) ? 0 : parseInt(ChkStyle.PurOrderStyleDetailID), PurchaseOrderID: parseInt(getInputValues.PurchaseOrderID), StyleID: parseInt(ChkStyle.StyleID), StyleNo: ChkStyle.StyleNo, IndexName: initial1.toString(),
                            IsDeleted: parseInt(ChkStyle.IsDeleted),
                        })
                        ChkStyle.SKUInfo.forEach((ChkSku, initial2) => {
                            let getDate = new Date(ChkSku.ExFacDate);
                            if (getDate.getFullYear().toString().length !== 4 && ChkSku.IsDeleted !== 1) {
                                setSubmitted(true);
                                setDisableSave(false);
                                if (Isvalid) { Nodify('Warning!', 'warning', "Please enter mandatory(*) fields"); }
                                Isvalid = false;
                            }
                            if(getInputValues.ReceivedDateOption ===1)
                            {
                            let getReceivedDate = new Date(ChkSku.ReceivedDate);
                            if (getReceivedDate.getFullYear().toString().length !== 4 && ChkSku.IsDeleted !== 1) {
                                setSubmitted(true);
                                setDisableSave(false);
                                if (Isvalid) { Nodify('Warning!', 'warning', "Please enter mandatory(*) fields"); }
                                Isvalid = false;
                            }
                        }
                            if (ChkSku.SKUID === 0 && ChkSku.IsDeleted !== 1) {
                                setSubmitted(true);
                                setDisableSave(false);
                                if (Isvalid) { Nodify('Warning!', 'warning', "Please enter mandatory(*) fields"); }
                                Isvalid = false;
                            }
                            if (parseInt(ChkSku.IsDeleted) !== 1 && ChkSku.Quantity !== '' && ChkSku.Quantity !== 0 && getDate.getFullYear().toString().length === 4) {
                                ChkValidSKU++;
                                if ((ChkSku.SKUID === '0' || ChkSku.ExFacDate === '') && ChkSku.IsDeleted !== 1) {
                                    setSubmitted(true);
                                    setDisableSave(false);
                                    if (Isvalid) { Nodify('Warning!', 'warning', "Please enter mandatory(*) fields"); }
                                    Isvalid = false;
                                } if (getInputValues.DestinationOption === 1 && ChkSku.IsDeleted !== 1) {
                                    if (ChkSku.DestinationID === 0) {
                                        setSubmitted(true);
                                        setDisableSave(false);
                                        if (Isvalid) { Nodify('Warning!', 'warning', "Please enter mandatory(*) fields"); }
                                        Isvalid = false;
                                    }
                                }
                                if (getInputValues.ReceivedDateOption === 1 && ChkSku.IsDeleted !== 1) {
                                    if (ChkSku.ReceivedDate === 0) {
                                        setSubmitted(true);
                                        setDisableSave(false);
                                        if (Isvalid) { Nodify('Warning!', 'warning', "Please enter mandatory(*) fields"); }
                                        Isvalid = false;
                                    }
                                }
                                if (getInputValues.IDPONOOption === 1 && ChkSku.IsDeleted !== 1) {
                                    if (ChkSku.POIDPONO === '') {
                                        setSubmitted(true);
                                        setDisableSave(false);
                                        if (Isvalid) { Nodify('Warning!', 'warning', "Please enter mandatory(*) fields"); }
                                        Isvalid = false;
                                    }
                                }

                                if (!isHideFocusPONo && getInputValues.IsFocusPONoOption === 1 && ChkSku.IsDeleted !== 1) {
                                    if (ChkSku.POFocusPONo === '') {
                                        setSubmitted(true);
                                        setDisableSave(false);
                                        if (Isvalid) { Nodify('Warning!', 'warning', "Please enter mandatory(*) fields"); }
                                        Isvalid = false;
                                    }
                                }
                                // if (getInputValues.ShowHidepacking === 1) {
                                //     if (ChkSku.PackingPlanID === 0 || ChkSku.PackingPlanID === null || ChkSku.PackingPlanID === '' || ChkSku.PackingPlanID === undefined) {
                                //         setSubmitted(true);
                                //         setDisableSave(false);
                                //         if (Isvalid) { Nodify('Warning!', 'warning', "Please enter mandatory(*) fields"); }
                                //         Isvalid = false;
                                //     }
                                // }

                                if (getInputValues.EndCustomerOption === 1) {
                                    if ((ChkSku.EndCustomerID === 0 || ChkSku.EndCustomerID === "0") && ChkSku.IsDeleted !== 1) {
                                        setSubmitted(true);
                                        setDisableSave(false);
                                        if (Isvalid) { Nodify('Warning!', 'warning', "Please enter mandatory(*) fields"); }
                                        Isvalid = false;
                                    }
                                }
                                //;
                                // if (getInputValues.ReceivedDateOption === 1) {
                                //     if ((ChkSku.ReceivedDate === 0 || ChkSku.ReceivedDate === "0") && ChkSku.IsDeleted !== 1) {
                                //         setSubmitted(true);
                                //         setDisableSave(false);
                                //         if (Isvalid) { Nodify('Warning!', 'warning', "Please enter mandatory(*) fields"); }
                                //         Isvalid = false;
                                //     }
                                // }




                                if (Isvalid) {
                                    let emptysizenames = ''; let emptysizeids = ''; let emptysizevalues = '';
                                    ChkSku.Sizes.forEach((chkSizes, initial3) => {
                                        if (initial3 === 0) {
                                            emptysizenames = chkSizes.BindSizeName;
                                            emptysizeids = chkSizes.SizeID;
                                            emptysizevalues = chkSizes.AssgignValue;
                                        } else {
                                            emptysizenames += ',' + chkSizes.BindSizeName;
                                            emptysizeids += ',' + chkSizes.SizeID;
                                            emptysizevalues += ',' + chkSizes.AssgignValue;
                                        }
                                    })
                                    if (ChkStyle.SKUInfo[initial2].Comments.length > 0) {
                                        ChkStyle.SKUInfo[initial2].Comments.forEach(element => {
                                            Params.TypPurchaseOrderSkuComment.push({
                                                PurOrdSkuCommentsID: element.PurOrdSkuCommentsID,
                                                PurOrdSkuComments: element.PurOrdSkuComments,
                                                IndexName: initial1.toString(),
                                                // SkuIndexName: element.SkuIndexName,
                                                SkuIndexName: initial2.toString(),
                                                IsDeleted: 0,
                                            });
                                        });
                                    }
                                    Params.TypPurOrderSkuDetail.push({
                                        PurchaseOrderSkuID: isNaN(ChkSku.PurchaseOrderSkuID) ? 0 : parseInt(ChkSku.PurchaseOrderSkuID),
                                        SKUName: parseInt(ChkSku.SKUID),
                                        SKUFrontPicName: ChkSku.SKUFrontPicName,
                                        SKUFrontPicPath: ChkSku.SKUFrontPicPath,
                                        RouteSkuSizes: emptysizenames,
                                        RouteSkuizesId: emptysizeids, RouteSkuSizesValue: emptysizevalues,
                                        Quantity: ChkSku.Quantity === '' ? 0 : parseFloat(ChkSku.Quantity), Price: ChkSku.Price === '' ? 0 : parseFloat(ChkSku.Price),
                                        Value: ChkSku.Value === '' ? 0 : parseFloat(ChkSku.Value),
                                        //Value: ChkSku.Value === '' ? 0 : parseFloat(ChkSku.Value),
                                        ShipmentMode: parseInt(ChkSku.ShipmentMode),
                                        POIDPONO: ChkSku.POIDPONO,
                                        POFocusPONo: ChkSku.POFocusPONo,
                                        ExFacDate: new Date(ChkSku.ExFacDate),
                                        Receiveddate: new Date(ChkSku.ReceivedDate),
                                        FacDate: new Date(ChkSku.FacDate),
                                        EndCustomerID: parseInt(ChkSku.EndCustomerID), DestinationID: parseInt(ChkSku.DestinationID), IsDeleted: parseInt(ChkSku.IsDeleted),
                                        IndexName: initial1.toString(),
                                        SkuIndexName: initial2.toString(),
                                        PackingPlanID: (ChkSku.PackingPlanID === null || ChkSku.PackingPlanID === 0 || ChkSku.PackingPlanID === undefined) ? 0 : parseInt(ChkSku.PackingPlanID)
                                    })
                                }
                            }
                        });
                    }
                    // else {
                    //     setSubmitted(true);
                    //     if (Isvalid) { Nodify('Warning!', 'warning', "Please enter mandatory(*) fields"); }
                    //     Isvalid = false;
                    // }
                }
            });
        }
       ;
        if (ChkValidSKU === 0) {
            setSubmitted(true);
            if (Isvalid) {
                let ChkQTY = 0;
                let ChkSKU = 0;
                let ChkExfac = 0;
                getInputValues.StyleLists.forEach((valida, i) => {
                    valida.SKUInfo.forEach((valida1, j) => {
                        if (valida1.SKUID !== 0) {
                            ChkSKU++;
                        }
                        if (valida1.Quantity !== "" && parseInt(valida1.Quantity) !== 0) {
                            ChkQTY++;
                        }
                        if (valida1.ExFacDate !== "") {
                            ChkExfac++;
                        }
                    });
                });
                if (ChkSKU === 0) {
                    setDisableSave(false);
                    Nodify('Warning!', 'warning', "Please enter atleast one one SKU row");
                } else if (ChkSKU > 0 && ChkExfac === 0) {
                    setDisableSave(false);
                    Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
                } else if (ChkSKU > 0 && ChkExfac > 0 && ChkQTY === 0) {
                    setDisableSave(false);
                    Nodify('Warning!', 'warning', "Please enter atleast minimum one quantity for one SKU row");
                } else {
                    setDisableSave(false);
                    Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
                }
            }
            Isvalid = false;
        }
        if (Isvalid && ChkValidSKU > 0) {
            let FinalCHKParamFrSKU = true;
            let ChkSKU = 0;
            let ChkExfac = 0;
            let ChkQTY = 0;
            getInputValues.StyleLists.forEach((valida, i) => {
                valida.SKUInfo.forEach((valida1, j) => {
                    if (parseInt(valida1.SKUID) === 0 && valida1.IsDeleted === 0) {
                        ChkSKU++;
                    }
                    if (valida1.Quantity === "" && valida1.IsDeleted === 0) {
                        ChkQTY++;
                    }
                    if (valida1.ExFacDate === "" && valida1.IsDeleted === 0) {
                        ChkExfac++;
                    }
                    // if (valida1.ReceivedDate === "" && valida1.IsDeleted === 0) {
                    //     ChkExfac++;
                    // }
                });
            });
            if (ChkSKU > 0 || ChkExfac > 0 || ChkQTY > 0) {
                setSubmitted(true);
                setDisableSave(false);
                if (ChkSKU > 0 || ChkExfac > 0) { Nodify('Warning!', 'warning', "Please enter mandatory(*) fields"); }
                else if (ChkQTY > 0) { Nodify('Warning!', 'warning', "Please enter atleast minimum one quantity for one SKU row"); }
                FinalCHKParamFrSKU = false;
            }
            if (FinalCHKParamFrSKU) {
                Params.IsProduction = IsProduction;
                PurchaseOrderService.InsertUpdatePurchaseOrderDetails(Params).then((res) => {
                    if (res.data.outputResult === "1") {
                        setDisableSave(false);
                        res.data.tabPage = getPage;
                        setID({ showPopupConfirm: true, Params: res.data });

                        //LoadPage(getPage);
                        // <SweetAlert
                        //  show={true}
                        //  warning
                        //  showCancel
                        //  confirmBtnText="Yes, Create TNA"
                        //  confirmBtnBsStyle="danger"
                        //  cancelBtnBsStyle="primary"
                        //  title="Are you create TNA ?"
                        //  //onConfirm={confirmTNAAlert(res.data)}
                        // // onCancel={(handleTNAClose)}
                        //  showCloseButton
                        //  focusCancelBtn>
                        // </SweetAlert>
                        //props.history.push({ pathname: "/PurchaseOrderList", state: { message: 'Add' } });

                    } else if (res.data.outputResult === "2") {
                        setDisableSave(false);
                        props.history.push({ pathname: "/PurchaseOrderList", state: { message: 'Edit' } });
                    } else if (res.data.outputResult === "-2") {
                        setDisableSave(false);
                        props.history.push({ pathname: "/PurchaseOrderList", state: { message: 'Check' } });
                    }
                }).catch(() => { })
                // .finally(() => { setres(0); });
            }
        }
    }

    const createTNA = () => {
        if (props.location.state) {
            const poId = props.location.state.params.purchaseOrderID;
            const seasonID = parseInt(inputFields.SeasonID);
            const brandID = parseInt(inputFields.BrandID);
            const supplierID = parseInt(inputFields.SupplierID);
            props.location.state = { params: { purchaseOrderID: poId, brandID: brandID, seasonID: seasonID, supplierID: supplierID } };
            props.history.push({ pathname: "/TNARegister", state: { params: { purchaseOrderID: props.location.state.params.purchaseOrderID, brandID: brandID, seasonID: seasonID, supplierID: supplierID } } });
        }
    }
    const confirmTNAAlert = (poParams) => {
        props.location.state = { params: { purchaseOrderID: parseInt(poParams.purchaseOrderID), brandID: poParams.OutputBrandID, seasonID: poParams.OutputSeasonID } };
        props.history.push({ pathname: "/TNARegister", state: { params: { purchaseOrderID: parseInt(poParams.purchaseOrderID), brandID: poParams.OutputBrandID, seasonID: poParams.OutputSeasonID } } });
    }

    const handleTNAClose = () => {
        props.history.push({ pathname: "/PurchaseOrderList", state: { message: 'Add' } });
    }

    const ImportStyle = () => {
        let POStyle = {
            EmployeeMenuPermissionID: currentUser.employeeMenuPermissionID, Operation: 1, BuyerID: parseInt(inputFields.BuyerID), SeasonID: parseInt(inputFields.SeasonID),
            BrandID: parseInt(inputFields.BrandID), StyleID: 0, IsProduction: IsProduction
        }
        if (inputFields.BuyerID === '' || inputFields.SeasonID === '' || inputFields.BrandID === '') {
            let MSG = '';
            if (inputFields.BuyerID === '') {
                MSG = 'Please select the buyer.';
            }
            else if (inputFields.SeasonID === '') {
                MSG = 'Please select the season.';
            }
            else if (inputFields.BrandID === '') {
                MSG = 'Please select the brand.';
            }
            Nodify('Warning!', 'warning', MSG);
        }
        else {

            setShowImportPopup(true);
            showLoader();
            PurchaseOrderService.LoadPOStyleList(POStyle).then((response) => {
                if (response.data) {
                    hideLoader();
                    let arrOptions = [];
                    // arrOptions(showLoader);
                    response.data.forEach(element => {
                        arrOptions.push({
                            value: element.value,
                            label: <div><img alt="Img" src={window.$APIBaseURL + 'Images' + element.styleFilePath} height="30px" width="30px" />  {element.label}  </div>,
                            subLabel: element.label + " " + element.styleNo
                        })
                    });
                    //setOptions(arrOptions);
                    setOptions(arrOptions.filter(o => BuyerBrandDetails.some(({ styleID, isAllInfo, isMapped, buyerId, brandId }) => o.value === styleID && buyerId === inputFields.BuyerID && brandId === inputFields.BrandID && (isAllInfo === 1 || isMapped === 1))));
                }
            }).catch(() => { hideLoader(); });
        }
    }

    const handleClose = (e) => {
        setShowImportPopup(false);
        setDisableImport(true);
        setImportValue("");
    };

    const [getImportStyleID, setImportStyleID] = useState([]);
    const handleImportChange = (event) => {
        setImportValue(event);
        setDisableImport(true);
        let styleIds = [];
        for (let size of event) {
            // if (selectedStyletoImport === '') { selectedStyletoImport = size.value.toString() }
            // else { selectedStyletoImport = selectedStyletoImport + "," + size.value }
            styleIds.push(size.value);
            setDisableImport(false);
        }
        setImportStyleID(styleIds);
    };

    const handleSaveImport = (e) => {
        let values = getImportStyleID;
        let Assignvalues = { ...inputFields };

        if (values.length === 0) {
            Nodify('Warning!', 'warning', 'Please select atleast one style');
        } else {
            for (var getValue = 0; getValue < values.length; getValue++) {
                for (var getStyle = 0; getStyle < getstyleList.length; getStyle++) {
                    if (parseInt(values[getValue]) === parseInt(getstyleList[getStyle].value)) {
                        let getLength = Assignvalues.StyleLists.length;

                        if (getLength === 1 && Assignvalues.StyleLists[0].StyleID === 0) {
                            getLength = 0;
                        } else {
                            Assignvalues.StyleLists.push({
                                StyleID: 0, StyleName: '', StyleNo: '', IsDeleted: 0, ArrangeData: 0, IndexName: '', CheckALLSKU: 0, AppliedSKUs: [],
                                PurchaseOrderID: 0, PurOrderStyleDetailID: 0,
                                SKUInfo: [{
                                    SKUID: 0, SKUName: '', SKUFrontPicPath: '', SKUFrontPicName: '', Quantity: '', Price: '', Value: '', ShipmentMode: IsProduction === 1 ? '0' : '1', POIDPONO: '', POFocusPONo: '',
                                    ExFacDate: '',ReceivedDate:'', FacDate: '', EndCustomerID: 0, EndCustomerName: '', DestinationID: 0, DestinationName: '', IsDeleted: 0, IndexName: '',
                                    EnableComments: 0, EnterComment: '', SaveComment: false, CheckSKU: 0, OpenChkPopUP: 0, PurchaseOrderSkuID: 0, PurchaseOrderID: 0,
                                    Sizes: [], SizeHeading: [],
                                    Comments: [], IndexCoun: 1,
                                }],
                            });
                        }

                        Assignvalues.StyleLists[getLength].StyleID = parseInt(values[getValue]);
                        Assignvalues.StyleLists[getLength].StyleNo = (getstyleList[getStyle].styleNo);
                        let SplitID = getstyleList[getStyle].sizeID.split(",");
                        let SplitName = getstyleList[getStyle].sizeName.split(",");
                        let getFinalSizes = [];
                        SplitID.forEach((pushEachSize, i) => {
                            let assignField = SplitName[i];
                            getFinalSizes.push({ SizeID: pushEachSize, BindSizeName: SplitName[i], [assignField]: '', AssgignValue: '', IsDeleted: 0, IndexName: '' });
                        })
                        Assignvalues.StyleLists[getLength].SKUInfo[0].SizeHeading = getFinalSizes;
                        Assignvalues.StyleLists[getLength].SKUInfo[0].Sizes = getFinalSizes;
                        localStorage.setItem('GetChkStyleImport', (true));
                        localStorage.setItem('TotalImport', (values.length));
                        let POStyle = { EmployeeMenuPermissionID: currentUser.employeeMenuPermissionID, Operation: 2, BuyerID: parseInt(Assignvalues.BuyerID), SeasonID: parseInt(Assignvalues.SeasonID), BrandID: parseInt(Assignvalues.BrandID), StyleID: parseInt(Assignvalues.StyleLists[getLength].StyleID), IsProduction: IsProduction }
                        LoadPOStyles(POStyle, getLength);
                        setImportValue();
                        setDisableImport(true);
                    }
                }
            }
        }
        setShowImportPopup(false);
    }


    const viewtoEdit = (e) => {
        SetButtonvisible("Edit");
        setHeader("Edit");
        SetButtonview("Hide");
    }

    const filevals = [...getFiles]
    const AppendFiles = async e => {
        const file = e.target.files[0];
        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword', "application/vnd.ms-excel",
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#FileUpload').val("");
            }
            else {
                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "PoDocUpload");
                try {
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                    filevals.push({
                        PurOrderFileID: 0,
                        FileName: file.name,
                        FilePath: "Images/PODocs/" + file.name,
                        IsDeleted: 0,
                        FileType: file.type
                    })
                    setFiles(filevals);
                    $('#FileUpload').val("");

                } catch (ex) {
                    console.log(ex);
                }
            }
        }
    }

    const RemoveFiles = async (e, index) => {
        filevals.splice(index, 1);
        setFiles(filevals);
    }

    const ViewFile = (event, filename) => { // event.preventDefault();
        setIsOpenimg(true);
        setImagename(filename);
    }

    const handleSave = (index, subindex, childindex, assignValue) => {
        const values = { ...inputFields };
        let IsValid = true;
        if (assignValue === '') {
            setComment(true); IsValid = false;
            Nodify('Warning!', 'warning', "Please enter comments");
        }
        if (IsValid) {
            if (values.StyleLists[index].SKUInfo[subindex].Comments.length !== 0) {
                if (values.StyleLists[index].SKUInfo[subindex].Comments[0].PurOrdSkuComments !== '') {
                    if (EditedCommentIndex !== '') {
                        values.StyleLists[index].SKUInfo[subindex].Comments[EditedCommentIndex].PurOrdSkuComments = assignValue;
                    }
                    else {
                        values.StyleLists[index].SKUInfo[subindex].Comments.push({
                            PurOrdSkuCommentsID: 0, PurOrdSkuComments: assignValue, IndexName: (index).toString(),
                            SkuIndexName: (subindex).toString(), IsDeleted: 0, Date: new Date(), Order: childindex
                        });
                    }
                }
                else {
                    values.StyleLists[index].SKUInfo[subindex].Comments[0] = {
                        PurOrdSkuCommentsID: 0, PurOrdSkuComments: assignValue, IndexName: (index).toString(),
                        SkuIndexName: (subindex).toString(), IsDeleted: 0, Date: new Date(), Order: childindex
                    };
                }
            }
            else {
                values.StyleLists[index].SKUInfo[subindex].Comments.push({
                    PurOrdSkuCommentsID: 0, PurOrdSkuComments: assignValue, IndexName: (index).toString(),
                    SkuIndexName: (subindex).toString(), IsDeleted: 0, Date: new Date(),
                    Order: childindex
                });
            }
            values.StyleLists[index].SKUInfo[subindex].EnterComment = '';
            values.StyleLists[index].SKUInfo[subindex].EnableComments = 0;

            setInputFields(values);
            setEditedCommentIndex('');
            Nodify('Success!', 'success', 'Purchase Order Comments saved Successfully');
        }
    };

    const EditComments = (index, SKUIndex, CmdIndex, Value) => {
        const values = { ...inputFields };
        values.StyleLists[index].SKUInfo[SKUIndex].EnterComment = Value;
        setEditedCommentIndex(CmdIndex);
        setInputFields(values);
    };

    const RemoveComments = (index, subChildIndex2, ChildIndex2) => {
        const values = { ...inputFields };
        // values.StyleLists[index].SKUInfo[subChildIndex2].Comments[ChildIndex2].IsDeleted = 1;
        values.StyleLists[index].SKUInfo[subChildIndex2].EnterComment = '';
        values.StyleLists[index].SKUInfo[subChildIndex2].Comments.splice(ChildIndex2, 1);
        setInputFields(values);
        Nodify('Success!', 'success', "Comments deleted successfully.");
    };

    const handleChangeSKUComments = (event, parentIndex, subIndex) => {
        const values = { ...inputFields };
        let inputText = '';
        if (event.target.value.trim() !== '') { inputText = event.target.value; }
        values.StyleLists[parentIndex].SKUInfo[subIndex].EnterComment = inputText;
        setInputFields(values);
    }

    const filterOption = (option, inputValue) => {
        let trimValue = inputValue.trim();
        if (trimValue === "") {
            return option;
        }
        return option.data.subLabel.toLowerCase().includes(trimValue.toLowerCase());
    };

    function convertUTCDateToLocalDate(date) {
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date;
    }

    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div>
                        {
                            isOpen && (
                                <Lightbox mainSrc={isOpen} alt="bg image" onCloseRequest={() => setIsOpen(false)} />
                            )
                        }
                    </div>
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">{getHeader} Purchase Order </h3>
                            <div className="buttons-preview" align="right">

                                <a className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                    <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                                </a>
                                <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                    <i className="fa fa-times"></i>&nbsp;Reset
                                </span>
                                {Buttonvisible !== "View" &&
                                    (<button type="submit" className="btn btn-success" onClick={(e) => SavePurchaseOrder(e)} disabled={getDisableSave}>

                                        <i className="fa fa-check right"></i> &nbsp;{getHeader === 'Add' ? 'Save' : 'Update'}
                                    </button>)
                                }
                                {Buttonvisible === "View" && activeMenu && activeMenu[0].isView === 1 ? '' : activeMenu && activeMenu[0].isView === 0 ? '' :
                                    (<button type="submit" style={{ display: activeMenu && activeMenu[0].isView === 0 ? '' : 'none' }}
                                        className="btn btn-info" onClick={viewtoEdit}>
                                        <i className="fa fa-edit"></i>&nbsp;Edit
                                    </button>)
                                }
                            </div>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <Form>
                                    {
                                        getres === 0 ?
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <div className='form-group'>
                                                                <label htmlFor="BuyerID">Buyer Name<span className="text-danger">*</span></label>
                                                                <span className='input-icon icon-right'>
                                                                    <Reactselect className="basic-single" name="BuyerID"
                                                                        id={"BuyerID"} isLoading={false}
                                                                        isClearable={true} isSearchable={true}
                                                                        onChange={(event) => handleChange(event, '', '', '', 'BuyerID')}
                                                                        value={getBuyerList.filter(function (option) {
                                                                            return option.value === inputFields.BuyerID;
                                                                        })}
                                                                        options={getBuyerList} autoFocus
                                                                        styles={submitted && inputFields.BuyerID === '' ? styles : ''}
                                                                        isDisabled={props.location.state && props.location.state.IsDisable}
                                                                        //isDisabled={false}
                                                                        placeholder="- Select Buyer -"
                                                                    ></Reactselect>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-3">
                                                            <div className='form-group'>
                                                                <label htmlFor="SeasonID" >Season<span className="text-danger">*</span></label>
                                                                <span className='input-icon icon-right'>
                                                                    <Reactselect className="basic-single" name="SeasonID"
                                                                        id={"SeasonID"} isLoading={false}
                                                                        isClearable={true} isSearchable={true}
                                                                        onChange={(event) => handleChange(event, '', '', '', 'SeasonID')}
                                                                        value={getSeasonList.filter(function (option) {
                                                                            return option.value === inputFields.SeasonID;
                                                                        })}
                                                                        options={getSeasonList}
                                                                        styles={submitted && inputFields.SeasonID === '' ? styles : ''}
                                                                        isDisabled={props.location.state && props.location.state.IsDisable}
                                                                        placeholder="- Select Season -"
                                                                    ></Reactselect>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-3">
                                                            <div className='form-group'>
                                                                <label htmlFor="Name" >Brand<span className="text-danger">*</span></label>
                                                                <span className='input-icon icon-right'>
                                                                    <Reactselect className="basic-single" name="BrandID"
                                                                        id={"BrandID"} isLoading={false}
                                                                        isClearable={true} isSearchable={true}
                                                                        onChange={(event) => handleChange(event, '', '', '', 'BrandID')}
                                                                        value={getBrandList.filter(function (option) {
                                                                            return option.value === inputFields.BrandID;
                                                                        })} options={getBrandList}
                                                                        styles={submitted && inputFields.BrandID === '' ? styles : ''}
                                                                        isDisabled={props.location.state && props.location.state.IsDisable}
                                                                        placeholder="- Select Brand -"
                                                                    ></Reactselect>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-group">
                                                                <label htmlFor={"Quarter"}>Quarter<span className="text-danger">*</span></label>
                                                                <span className="input-icon icon-right">
                                                                    <select id={"Quarter"} name="Quarter" className="form-select"
                                                                        value={parseInt(inputFields.Quarter)}
                                                                        onChange={(event) => handleChange(event, '', '', '', 'BrandID')}
                                                                        style={{ border: submitted && inputFields.Quarter === '0' ? '1px solid red' : '', color: inputFields.Quarter === '0' ? '#bfdea1' : '#000' }}
                                                                    >
                                                                        <option value="0" key="0" style={{ color: '#bfdea1' }}> - Select Quarter- </option>
                                                                        <option value="1" key="1">Q1</option>
                                                                        <option value="2" key="2">Q2</option>
                                                                        <option value="3" key="3">Q3</option>
                                                                        <option value="4" key="4">Q4</option>
                                                                        <option value="5" key="5">N.A</option>
                                                                    </select>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <div className="form-group">
                                                                <label htmlFor={"ReceivedOn"}>Received On<span className="text-danger">*</span></label>
                                                                {/* <span className="input-icon icon-right">
                                                            <DatePicker className="form-control" id={"ReceivedOn"}  //placeholder="Select Received On"
                                                                name="ReceivedOn" selected={inputFields.ReceivedOn}
                                                                dateFormat="dd/MM/yyyy" peekNextMonth
                                                                showMonthDropdown showYearDropdown
                                                                dropdownMode="scroll" autoComplete="off"
                                                                customInput={<CustomInput />}
                                                                styles={submitted && inputFields.ReceivedOn === '' ? styles : ''}
                                                                onChange={(event) => handleChangeDate(event, '', '', 'ReceivedOn')}
                                                                isClearable={inputFields.ReceivedOn === '' ? false : true}
                                                            /> </span> */}
                                                             <div className="icon-right PurchaseMargin">
                                                                        <span className="input-icon icon-right" style={{ zIndex: 0 }}>
                                                                            <input className="checkbox-slider checkbox-slider_StatusSingle colored-blue"
                                                                                id="ReceivedDateOption" name="ReceivedDateOption"
                                                                                value={inputFields.ReceivedDateOption}
                                                                                checked={inputFields.ReceivedDateOption === 1 ? true : false}
                                                                                autoComplete="off"
                                                                                onChange={event => handleChange(event, '', '', '', 'ReceivedDateOption')}
                                                                                type="checkbox">
                                                                            </input>
                                                                            <span className="text"> </span>
                                                                        </span>
                                                                    </div>
                                                                <DatePicker className="form-control" name="ReceivedOn"
                                                                    id={
                                                                        "ReceivedOn"
                                                                    }
                                                                    selected={
                                                                        inputFields.ReceivedOn
                                                                    }
                                                                    onChange={(event) => handleChangeDate(event, '', '', 'ReceivedOn')}
                                                                    dateFormat="dd/MM/yyyy"
                                                                    peekNextMonth
                                                                    //showMonthDropdown
                                                                    //showYearDropdown
                                                                    //style={{ border: submitted && inputFields.ReceivedOn === '' ? '1px solid red' : '', color: inputFields.ReceivedOn === '' ? '#bfdea1' : '#000', textTransform: "uppercase" }}
                                                                    onKeyDown={(e) => e.preventDefault()}
                                                                    dropdownMode="scroll"
                                                                    autoComplete="off"
                                                                    customInput={<CustomInput />}
                                                                    isClearable={inputFields.ReceivedOn === null ? false : true && inputFields.ReceivedDateOption !== 1}
                                                                    disabled={inputFields.ReceivedDateOption === 1 ? true : false}
                                                                    //disabled={true}
                                                                    styles={submitted && inputFields.ReceivedDateOption !== 1 && inputFields.ReceivedOn === '' || inputFields.ReceivedOn === 0 ? styles : ''}
                                                                />
                                                               
                                                              



                                                                {/* <input id="ReceivedOn" name="ReceivedOn"
                                                                    value={inputFields.ReceivedOn} placeholder="DD/MM/YYYY"
                                                                    onChange={(event) => handleChangeDate(event, '', '', 'ReceivedOn')}
                                                                    type="date" autoComplete="off" onKeyDown={(e) => e.preventDefault()}
                                                                    className={'form-control'}
                                                                    style={{ border: submitted && inputFields.ReceivedOn === '' ? '1px solid red' : '', color: inputFields.ReceivedOn === '' ? '#bfdea1' : '#000', textTransform: "uppercase" }}
                                                                />
                                                                <span class="text"></span> */}
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-3">
                                                            <div className='form-group'>
                                                                <label htmlFor="CurrencyID" >Currency</label><span className="text-danger">*</span>
                                                                <span className='input-icon icon-right'>
                                                                    <Reactselect className="basic-single" name="CurrencyID"
                                                                        id={"CurrencyID"} isDisabled={false}
                                                                        isLoading={false} isClearable={true}
                                                                        isSearchable={true}
                                                                        value={getCurrencyList.filter(function (option) {
                                                                            return option.value === inputFields.CurrencyID;
                                                                        })}
                                                                        options={getCurrencyList} onChange={(event) => handleChange(event, '', '', '', 'CurrencyID')}
                                                                        placeholder="- Select Currency -"
                                                                        styles={submitted && inputFields.CurrencyID === '' ? styles : ''}
                                                                    ></Reactselect>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-3">
                                                            <div className='form-group'>
                                                                <label htmlFor="SupplierID" >Supplier<span className="text-danger">*</span></label>
                                                                <span className='input-icon icon-right'>
                                                                    <Reactselect className="basic-single" name="SupplierID"
                                                                        id={"SupplierID"} isDisabled={false} isLoading={false}
                                                                        isClearable={true} isSearchable={true}
                                                                        value={getSupplierList.filter(function (option) {
                                                                            return option.value === inputFields.SupplierID;
                                                                        })} options={getSupplierList}
                                                                        onChange={(event) => handleChange(event, '', '', '', 'SupplierID')}
                                                                        styles={submitted && inputFields.SupplierID === '' ? styles : ''}
                                                                        placeholder="- Select Supplier -"
                                                                    ></Reactselect>
                                                                </span>
                                                            </div>
                                                        </div>


                                                        <div className="col-sm-3">
                                                            <div className='form-group'>
                                                                <label htmlFor="IDPoNo">{isHideFocusPONo ? 'PO/ID No' : 'PO/ID No'} <span className="text-danger">*</span></label>
                                                                <div className="icon-right PurchaseMargin">
                                                                    <span className="input-icon icon-right" style={{ zIndex: 0 }}>
                                                                        <input className="checkbox-slider checkbox-slider_StatusSingle colored-blue"
                                                                            id="IDPONOOption" name="IDPONOOption"
                                                                            value={inputFields.IDPONOOption}
                                                                            checked={inputFields.IDPONOOption === 1 ? true : false}
                                                                            autoComplete="off" onChange={event => handleChange(event, '', '', '')}
                                                                            type="checkbox">
                                                                        </input>
                                                                        <span className="text"></span>
                                                                    </span>
                                                                </div>
                                                                <span className="text"></span>
                                                                <span className='input-icon icon-right'>
                                                                    <input id="IDPoNo" name="IDPoNo" disabled={inputFields.IDPONOOption === 1}
                                                                        value={inputFields.IDPoNo} placeholder={isHideFocusPONo ? "Enter PO/ID No" : "Enter PO/ID No"}
                                                                        onChange={event => handleChange(event, '', '', '')}
                                                                        type="text" autoComplete="off" maxLength="100"
                                                                        className={'form-control'}
                                                                        style={{ border: submitted && inputFields.IDPONOOption !== 1 && inputFields.IDPoNo === '' ? '1px solid red' : '' }}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="row">
                                                            {!isHideFocusPONo && IsProduction === 2 &&
                                                                <div className="col-sm-3" >
                                                                    <div className='form-group'>
                                                                        <label htmlFor="IsFocusPONoOption">Focus PO No <span className="text-danger">*</span></label>
                                                                        <div className="icon-right PurchaseMargin">
                                                                            <span className="input-icon icon-right" style={{ zIndex: 0 }}>
                                                                                <input className="checkbox-slider checkbox-slider_StatusSingle colored-blue"
                                                                                    id="IsFocusPONoOption" name="IsFocusPONoOption"
                                                                                    value={inputFields.IsFocusPONoOption}
                                                                                    checked={inputFields.IsFocusPONoOption === 1 ? true : false}
                                                                                    autoComplete="off" onChange={event => handleChange(event, '', '', '')}
                                                                                    type="checkbox">
                                                                                </input>
                                                                                <span className="text"></span>
                                                                            </span>
                                                                        </div>
                                                                        <span className="text"></span>
                                                                        <span className='input-icon icon-right'>
                                                                            <input id="FocusPONo" name="FocusPONo" disabled={inputFields.IsFocusPONoOption === 1}
                                                                                value={inputFields.FocusPONo} placeholder="Enter Focus PO No"
                                                                                onChange={event => handleChange(event, '', '', '')}
                                                                                type="text" autoComplete="off" maxLength="100"
                                                                                className={'form-control'}
                                                                                style={{ border: submitted && inputFields.IsFocusPONoOption !== 1 && inputFields.FocusPONo === '' ? '1px solid red' : '' }}
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                            }



                                                            <div className="col-sm-3">
                                                                <div className='form-group'>
                                                                    <label htmlFor="EndCustomerID" >End Customer<span className="text-danger">*</span></label>
                                                                    <div className="icon-right PurchaseMargin">
                                                                        <span className="input-icon icon-right" style={{ zIndex: 0 }}>
                                                                            <input className="checkbox-slider checkbox-slider_StatusSingle colored-blue"
                                                                                id={"EndCustomerOption"} name="EndCustomerOption"
                                                                                value={inputFields.EndCustomerOption}
                                                                                checked={inputFields.EndCustomerOption === 1 ? true : false}
                                                                                autoComplete="off" onChange={event => handleChange(event, '', '', '')}
                                                                                type="checkbox">
                                                                            </input>
                                                                            <span className="text"></span>
                                                                        </span>
                                                                    </div>
                                                                    <span className='input-icon icon-right'>
                                                                        <Reactselect className="basic-single" name="EndCustomerID"
                                                                            id={"EndCustomerID"} isDisabled={inputFields.EndCustomerOption === 1 ? true : false}
                                                                            isLoading={false} isClearable={true}
                                                                            isSearchable={true}
                                                                            onChange={(event) => handleChange(event, '', '', '', 'EndCustomerID')}
                                                                            value={getEndCustomerList.filter(function (option) {
                                                                                return option.value === inputFields.EndCustomerID;
                                                                            })}
                                                                            placeholder="- Select End Customer -"
                                                                            options={getEndCustomerList}
                                                                            styles={submitted && inputFields.EndCustomerOption !== 1 && inputFields.EndCustomerID === '' || inputFields.EndCustomerID === 0 ? styles : ''}                                                                        ></Reactselect>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-3">
                                                                <div className='form-group'>
                                                                    <label htmlFor="PackingPlan" >Packing Plan</label>

                                                                    <span className='input-icon icon-right'>
                                                                        <Reactselect className="basic-single" name="PackingPlanID"
                                                                            id={"PackingPlanID"}
                                                                            isLoading={false} isClearable={true}
                                                                            isSearchable={true}
                                                                            onChange={(event) => handleChange(event, '', '', '', 'PackingPlanID')}
                                                                            value={getpackingoption.filter(function (option) {
                                                                                return option.value === inputFields.PackingPlanID;
                                                                            })}
                                                                            placeholder="- Select Packing Plan -"
                                                                            options={getpackingoption}
                                                                        //styles={submitted && inputFields.PackingPlanID !== 1 ? styles :'' }
                                                                        ></Reactselect>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-3">
                                                                <div className='form-group'>
                                                                    <label htmlFor="Destination" >Destination<span className="text-danger">*</span></label>
                                                                    <div className="icon-right PurchaseMargin">
                                                                        <span className="input-icon icon-right" style={{ zIndex: 0 }}>
                                                                            <input className="checkbox-slider checkbox-slider_StatusSingle colored-blue"
                                                                                id="DestinationOption" name="DestinationOption"
                                                                                value={inputFields.DestinationOption}
                                                                                checked={inputFields.DestinationOption === 1 ? true : false}
                                                                                autoComplete="off"
                                                                                onChange={event => handleChange(event, '', '', '', 'DestinationOption')}
                                                                                type="checkbox">
                                                                            </input>
                                                                            <span className="text"> </span>
                                                                        </span>
                                                                    </div>
                                                                    <span className='input-icon icon-right'>
                                                                        <Reactselect className="basic-single" name="DestinationID"
                                                                            id={"DestinationID"} isDisabled={inputFields.DestinationOption === 1 ? true : false}
                                                                            classNamePrefix="DestinationID"
                                                                            isLoading={false} isClearable={true}
                                                                            isSearchable={true}
                                                                            onChange={(event) => handleChange(event, '', '', '', 'DestinationID')}
                                                                            value={getcountryList.filter(function (option) {
                                                                                return option.value === inputFields.DestinationID;
                                                                            })}
                                                                            placeholder="- Select Destination -"
                                                                            options={getcountryList}
                                                                            styles={submitted && inputFields.DestinationOption !== 1 && inputFields.DestinationID === '' ? styles : ''}
                                                                        ></Reactselect>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <div className="form-group">
                                                                    <label htmlFor={"ShowHideImage"}>Show / Hide</label>
                                                                    <br />
                                                                    <span>
                                                                        <button title="Hide Header" type="button" className="btn btn-default btn-primary" onClick={handleShow}> <i className="glyphicon glyphicon-eye-close"></i></button>
                                                                    </span>
                                                                    {/* <span className="input-icon icon-right">
                                                                <select id={"ShowHideImage"} name="ShowHideImage" className="form-select"
                                                                    value={parseInt(inputFields.ShowHideImage)} onChange={event => handleChange(event, '', '', '')}
                                                                >
                                                                    <option value="1" key="1">Show</option>
                                                                    <option value="2" key="2">Hide</option>
                                                                </select>
                                                            </span> */}
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-1">
                                                                <div className="form-group">
                                                                    <label>Style Import</label>
                                                                    <h6><img className="width_manual" src={"assets/img/Export.png"} alt="Logo" onClick={ImportStyle} /></h6>
                                                                </div>
                                                                {loader}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <Modal show={show} backdrop="static" onHide={handleCloses} dialogClassName='customdialogPO'>
                                                            <Modal.Header closeButton>
                                                                <Modal.Title>Show Hide Image/Packing</Modal.Title>
                                                            </Modal.Header>
                                                            {/* , overflowY: "auto" */}
                                                            <Modal.Body style={{ maxHeight: "70vh" }}>
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className="row">
                                                                            <div className="row">


                                                                                <div className="col-lg-5">
                                                                                    <li className="listmodal">
                                                                                        <input
                                                                                            id="ShowHideImage" name="ShowHideImage"
                                                                                            value={inputFields.ShowHideImage}
                                                                                            checked={inputFields.ShowHideImage === 1 ? true : false}
                                                                                            onChange={event => handleChange(event, '', '', '', 'ShowHideImage')}
                                                                                            className="hidecheckbox" type="checkbox" />

                                                                                        &nbsp;<label htmlFor="podate">Show / Hide Image</label>
                                                                                    </li>

                                                                                    {/* <li className="listmodal disabled" onClick={() => CheckBoxHandler(1)}> <input className="hidecheckbox" type="checkbox" name="podate" checked={getHideID.Po} /> &nbsp;<label htmlFor="podate">PO Date</label> </li> */}
                                                                                </div>
                                                                                <div className="col-lg-5">
                                                                                    <li className="listmodal">
                                                                                        <input className="hidecheckbox" value={inputFields.ShowHidepacking}
                                                                                            checked={inputFields.ShowHidepacking === 1 ? true : false}
                                                                                            onChange={event => handleChangecheckbox(event)} type="checkbox" name="podate" />
                                                                                        &nbsp;<label htmlFor="podate">Packing Plan</label>
                                                                                    </li>

                                                                                    {/* <li className="listmodal" onClick={() => CheckBoxHandler(21)}> <input className="hidecheckbox" type="checkbox" name="podate" checked={getHideID.skuID} /> &nbsp;<label htmlFor="skuid">SKU ID</label> </li> */}
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Modal.Body>
                                                        </Modal>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-lg-12">
                                                    <div className="widget-header" style={{ paddingLeft: 0 }}>
                                                        <span className="widget-caption">Order Details</span>

                                                        {
                                                            // style = {{display: activeMenu[0].isAdd === 1 ? '' : 'none' }}
                                                            (getHeader === "Edit" && allstyleTNAcreated === false && !isHideTNACreate) ?
                                                                activeTNAMenu.length > 0 && activeTNAMenu[0].isAdd === 1 ?
                                                                    <span className="widget-caption createPO_TNA" onClick={createTNA}
                                                                    >Create TNA</span> : ""
                                                                : ''
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    inputFields.StyleLists.map((styleItem, parentIndex) => (
                                                        styleItem.IsDeleted === 0 ?
                                                            <>
                                                                <div className="col-sm-12 col-lg-12" style={{ marginTop: '12px' }}>
                                                                    <div className="row">

                                                                        <div className="col-lg-1" style={{ width: "40px" }}>
                                                                            <div className="form-group">
                                                                                <label>
                                                                                    <b> &nbsp;#</b>
                                                                                </label>
                                                                                <span className="input-icon icon-right">
                                                                                    <Input id="StyleNo" name="StyleNo"
                                                                                        value={parentIndex + 1}
                                                                                        // value={styleItem.IndexCounting}
                                                                                        type="text"
                                                                                        className={'form-control'}

                                                                                        style={{ width: "30px" }}
                                                                                    />
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-3">
                                                                            <div className="form-group">
                                                                                <label htmlFor={"StyleID" + parentIndex}>
                                                                                    Style Name <span className="text-danger">*</span>
                                                                                </label>
                                                                                <span className="input-icon icon-right">
                                                                                    <select id={"StyleID"} name="StyleID"
                                                                                        className="form-select" value={parseInt(styleItem.StyleID)}
                                                                                        onChange={event => handleChange(event, parentIndex, '', '')}
                                                                                        style={{
                                                                                            border: submitted && (styleItem.StyleID === 0 || styleItem.StyleID === '0') ? '1px solid red' : '',
                                                                                            color: parseInt(styleItem.StyleID) === 0 ? '#bfdea1' : '#000'
                                                                                        }}
                                                                                    >
                                                                                        <option value="0" style={{ color: '#bfdea1' }}>-Select Style Name-</option>
                                                                                        {
                                                                                            getstyleList.map(styleValue => (
                                                                                                <option key={styleValue.value} value={styleValue.value}>
                                                                                                    {styleValue.label}
                                                                                                </option>
                                                                                            ))
                                                                                        }
                                                                                    </select>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-3">
                                                                            <label htmlFor={"StyleNo"}>
                                                                                Style No <span className="text-danger">*</span>
                                                                            </label>
                                                                            <span className='input-icon icon-right'>
                                                                                <input id="StyleNo" name="StyleNo"
                                                                                    value={styleItem.StyleNo} placeholder="Enter Style No"
                                                                                    onChange={event => handleChange(event, parentIndex, '', '')}
                                                                                    type="text" autoComplete="off" maxLength="50"
                                                                                    className={'form-control'}
                                                                                    style={{ border: submitted && styleItem.StyleNo === '' ? '1px solid red' : '' }}
                                                                                    isDisabled={true}
                                                                                />
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-lg-4 alignRight" style={{ float: 'right', marginTop: '13px' }}>
                                                                            <div style={{ float: 'right', marginRight: '10px' }}>
                                                                                {Buttonview !== "View" &&
                                                                                    inputFields.StyleLists.filter(prod => prod.IsDeleted === 0).length > 1 &&
                                                                                    <>
                                                                                        {inputFields.StyleLists.filter(prod => prod.IsDeleted === 0).length !== 1 ?
                                                                                            <>
                                                                                                <div style={{ display: inputFields.StyleLists.filter(prod => prod.IsDeleted === 0).length === 1 ? "none" : "" }}>
                                                                                                    <span>
                                                                                                        <span className="btn btn-danger" style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none', float: 'right' }}

                                                                                                            title="Delete Style" onClick={() => RemoveStyleFields(parentIndex)}>
                                                                                                            <i className="fa fa-trash-o"></i>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </>
                                                                                            : ''
                                                                                        }
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                            <div style={{ float: 'right', marginRight: '10px' }}>
                                                                                {Buttonview !== "View" &&
                                                                                    inputFields.StyleLists.filter(prod => prod.IsDeleted === 0).length > 0 ?
                                                                                    <>
                                                                                        <div style={{ display: inputFields.StyleLists.filter(prod => prod.IsDeleted === 0).length === 0 ? "none" : "" }}>
                                                                                            <span>

                                                                                                <span className="btn btn-success"
                                                                                                    style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}
                                                                                                    title="Add Style" onClick={() => AddStyleFields('Add', parentIndex)}>
                                                                                                    <i className="fa fa-plus"></i>
                                                                                                </span>


                                                                                            </span>
                                                                                        </div>
                                                                                    </>
                                                                                    : ''
                                                                                }
                                                                            </div>
                                                                            <div style={{ float: 'right', marginRight: '10px' }}>
                                                                                {Buttonview !== "View" &&
                                                                                    inputFields.StyleLists.filter(prod => prod.IsDeleted === 0).length > 0 ?
                                                                                    <>
                                                                                        <div style={{ display: inputFields.StyleLists.filter(prod => prod.IsDeleted === 0).length === 0 ? "none" : "" }}>
                                                                                            <span>
                                                                                                <span style={{ pointerEvents: getstyleList.length > 0 ? '' : 'none', opacity: getstyleList.length > 0 ? '' : '0.6' }} className="btn btn-primary" title="Copy Style"
                                                                                                    onClick={() => AddStyleFields('Copy', parentIndex)}>
                                                                                                    <i className="fa fa-copy"></i>
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                    </>
                                                                                    : ''
                                                                                }
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-12">
                                                                    <div style={{ overflow: "auto", width: "100%", overflowX: "auto" }}>
                                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" style={{ width: "110%" }}>
                                                                            <thead className="text-center" style={{ whiteSpace: "noWrap" }}>
                                                                                <tr>
                                                                                    <th style={{ width: '10px' }}>#</th>
                                                                                    <th style={{ width: '280px' }}>SKU Name{inputFields.ShowHideImage === '1' || inputFields.ShowHideImage === 1 ?
                                                                                        <> / Image</>
                                                                                        : ''
                                                                                    } <span className="text-danger">*</span>
                                                                                    </th>
                                                                                    {
                                                                                        styleItem.SKUInfo.map((skuItem, subIndex) => (
                                                                                            <>
                                                                                                {
                                                                                                    skuItem.SizeHeading.map((SizeItem, subChildIndex) => (
                                                                                                        <th style={{ width: '130px' }}>
                                                                                                            {SizeItem.BindSizeName}
                                                                                                        </th>
                                                                                                    ))
                                                                                                }
                                                                                            </>
                                                                                        ))
                                                                                    }
                                                                                    <th style={{ width: '100px' }}>Quantity</th>
                                                                                    <th style={{ width: '100px' }}>Price</th>
                                                                                    <th style={{ width: '100px' }}>Value</th>
                                                                                    <th style={{ width: '100px' }}>Ship Mode<span className="text-danger">*</span></th>
                                                                                    {
                                                                                        inputFields.IDPONOOption === 1 ?
                                                                                            <th style={{ width: '50px' }}>{isHideFocusPONo ? "PO/ID No" : "PO/ID No"}<span className="text-danger">*</span></th>
                                                                                            : ''
                                                                                    }
                                                                                    {
                                                                                        inputFields.IsFocusPONoOption === 1 && !isHideFocusPONo ?
                                                                                            <th style={{ width: '50px' }}>{"Focus PO No"}<span className="text-danger">*</span></th>
                                                                                            : ''
                                                                                    }
                                                                                    {/* <th style={{ width: '50px' }}>Received Date<span className="text-danger">*</span></th> */}
                                                                                    {/* {
                                                                                         inputFields.ReceivedDateOption === 1 ?
                                                                                        <>
                                                                                            <th style={{ width: '50px' }}>Received Date<span className="text-danger">*</span></th>
                                                                                        </>
                                                                                            : ''
                                                                                    } */}
                                                                                    {
                                                                                        inputFields.ReceivedDateOption === 1 ?
                                                                                            <>
                                                                                                <th style={{ width: '50px' }}>Received Date<span className="text-danger">*</span></th>
                                                                                            </>
                                                                                            : ''
                                                                                    }
                                                                                    {/* <th style={{ width: '50px' }}>Received Date<span className="text-danger">*</span></th> */}
                                                                                    <th style={{ width: '50px' }}>Ex-Fac Date<span className="text-danger">*</span></th>
                                                                                    <th style={{ width: '50px' }}>Fac.Ex-Fac Confirm</th>
                                                                                    {
                                                                                        inputFields.EndCustomerOption === 1 ?
                                                                                            <>
                                                                                                <th style={{ width: '50px' }}>End Customer<span className="text-danger">*</span></th>
                                                                                            </>
                                                                                            : ''
                                                                                    }
                                                                                    {
                                                                                        inputFields.DestinationOption === 1 ?
                                                                                            <>
                                                                                                <th style={{ width: '50px' }}>Destination<span className="text-danger">*</span></th>
                                                                                            </>
                                                                                            : ''
                                                                                    }
                                                                                    {
                                                                                        inputFields.ShowHidepacking === 1 ?
                                                                                            <>
                                                                                                <th style={{ width: '50px' }}>Packing Plan</th>
                                                                                            </>
                                                                                            : ''
                                                                                    }

                                                                                    {Buttonview !== "View" &&
                                                                                        <th className="text-center Actionbutton fixed-column-header">Action</th>
                                                                                    }
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    styleItem.SKUInfo.map((skuItem, subIndex) => (
                                                                                        skuItem.IsDeleted === 0 && (skuItem.Quantity !== 0 || skuItem.Quantity !== '') ?
                                                                                            <>
                                                                                                <tr key={subIndex} style={{ display: skuItem.IsDeleted === 1 ? 'none' : '' }}>
                                                                                                    <td>{skuItem.IndexCoun}</td>
                                                                                                    <td style={{ padding: 0 }}>
                                                                                                        <table>
                                                                                                            <tbody>
                                                                                                                <tr>
                                                                                                                    <td>
                                                                                                                        <select id={"SKUID"} name="SKUID" className="form-select"
                                                                                                                            value={skuItem.SKUID}
                                                                                                                            onChange={event => handleChange(event, parentIndex, subIndex, '')}
                                                                                                                            style={{ border: submitted && parseInt(skuItem.SKUID) === 0 ? '1px solid red' : '', color: parseInt(skuItem.SKUID) === 0 ? '#bfdea1' : '#000' }}
                                                                                                                        // color: parseInt(skuItem.SKUID) === 0 ? '#bfdea1' : '#000'
                                                                                                                        >
                                                                                                                            <option value="0" style={{ color: '#bfdea1' }}>-Select SKU Name-</option>
                                                                                                                            {
                                                                                                                                styleItem.AppliedSKUs.map(SKUitem => (
                                                                                                                                    <option key={SKUitem.SKUID} value={SKUitem.SKUID}>
                                                                                                                                        {SKUitem.SKUName}
                                                                                                                                    </option>
                                                                                                                                ))
                                                                                                                            }
                                                                                                                        </select>
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        {
                                                                                                                            inputFields.ShowHideImage === '1' || inputFields.ShowHideImage === 1 ?
                                                                                                                                <>
                                                                                                                                    <img className="width_manual" style={{ float: "right" }}
                                                                                                                                        src={skuItem.SKUFrontPicPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + skuItem.SKUFrontPicPath}
                                                                                                                                        alt="Logo"
                                                                                                                                        onClick={
                                                                                                                                            () => setIsOpen(skuItem.SKUFrontPicPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + skuItem.SKUFrontPicPath)
                                                                                                                                        }
                                                                                                                                    />
                                                                                                                                </>
                                                                                                                                :
                                                                                                                                ''
                                                                                                                        }
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </td>
                                                                                                    {
                                                                                                        skuItem.Sizes.map((SizeItem, subChildIndex) => (
                                                                                                            SizeItem.IsDeleted === 0 ?
                                                                                                                <td>
                                                                                                                    <span className='input-icon icon-right'>
                                                                                                                        <input id="SizeName" name="SizeName"
                                                                                                                            // placeholder={SizeItem.BindSizeName}
                                                                                                                            value={SizeItem.AssgignValue}
                                                                                                                            // onblur={event => handleChangeSize(event, parentIndex, subIndex, subChildIndex, SizeItem.BindSizeName)}
                                                                                                                            onChange={event => handleChangeSize(event, parentIndex, subIndex, subChildIndex, SizeItem.BindSizeName)}
                                                                                                                            type="text" autoComplete="off" maxLength="5"
                                                                                                                            className={'form-control'}
                                                                                                                        />
                                                                                                                    </span>
                                                                                                                </td>
                                                                                                                :
                                                                                                                <td></td>
                                                                                                        ))
                                                                                                    }
                                                                                                    <td>
                                                                                                        <span className='input-icon icon-right'>
                                                                                                            <input id="Quantity" name="Quantity"
                                                                                                                value={skuItem.Quantity}
                                                                                                                onChange={event => handleChange(event, parentIndex, subIndex, '')}
                                                                                                                type="text" autoComplete="off" maxLength="50"
                                                                                                                className={'form-control'}
                                                                                                                disabled
                                                                                                            />
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <span className='input-icon icon-right'>
                                                                                                            <input id="Price" name="Price"
                                                                                                                value={skuItem.Price}
                                                                                                                onChange={event => handleChange(event, parentIndex, subIndex, '')}
                                                                                                                type="text" autoComplete="off" maxLength="15"
                                                                                                                className={'form-control'}
                                                                                                            />
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <span className='input-icon icon-right'>
                                                                                                            <input id="Value" name="Value"
                                                                                                                value={skuItem.Value} disabled="true"
                                                                                                                onChange={event => handleChange(event, parentIndex, subIndex, '')}
                                                                                                                type="text" autoComplete="off" maxLength="50"
                                                                                                                className={'form-control'}
                                                                                                            />
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <span className='input-icon icon-right'>
                                                                                                            {/* <Input id="ShipmentMode" name="ShipmentMode" placeholder="Enter Shipment Mode"
                                                                                                        value={skuItem.ShipmentMode}
                                                                                                        onChange={event => handleChange(event, parentIndex, subIndex, '')}
                                                                                                        type="text" autoComplete="off" maxLength="50"
                                                                                                        className={'form-control'}
                                                                                                    /> */}
                                                                                                            <span className="input-icon icon-right">
                                                                                                                <select id={"ShipmentMode"} name="ShipmentMode" className="form-select"
                                                                                                                    value={parseInt(skuItem.ShipmentMode)} onChange={event => handleChange(event, parentIndex, subIndex, '')}

                                                                                                                >
                                                                                                                    <option value="0" key="0">Air</option>
                                                                                                                    <option value="1" key="1">Sea</option>
                                                                                                                </select>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    {/* inputFields.EndCustomerOption === 1 || inputFields.DestinationOption === 1 ||  */}
                                                                                                    {
                                                                                                        inputFields.IDPONOOption === 1 ?
                                                                                                            <td>
                                                                                                                <span className='input-icon icon-right'>
                                                                                                                    <input id="POIDPONO" name="POIDPONO"
                                                                                                                        value={skuItem.POIDPONO}
                                                                                                                        onChange={event => handleChange(event, parentIndex, subIndex, '')}
                                                                                                                        type="text" autoComplete="off" maxLength="100"
                                                                                                                        className={'form-control'}
                                                                                                                        style={{ border: submitted && inputFields.IDPONOOption === 1 && skuItem.POIDPONO === '' ? '1px solid red' : '' }}
                                                                                                                    />
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            : ''
                                                                                                    }
                                                                                                    {
                                                                                                        inputFields.IsFocusPONoOption === 1 ?
                                                                                                            <td>
                                                                                                                <span className='input-icon icon-right'>
                                                                                                                    <Input id="POFocusPONo" name="POFocusPONo"
                                                                                                                        value={skuItem.POFocusPONo}
                                                                                                                        onChange={event => handleChange(event, parentIndex, subIndex, '')}
                                                                                                                        type="text" autoComplete="off" maxLength="100"
                                                                                                                        className={'form-control'}
                                                                                                                        style={{ border: submitted && inputFields.IsFocusPONoOption === 1 && skuItem.POFocusPONo === '' ? '1px solid red' : '' }}
                                                                                                                    />
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            : ''
                                                                                                    }
                                                                                                        {
                                                                                                        inputFields.ReceivedDateOption === 1 ?
                                                                                                            <>
                                                                                                    <td>
                                                                                                        <span className="input-icon icon-right">
                                                                                                            <input id="ReceivedDate" name="ReceivedDate"
                                                                                                                value={skuItem.ReceivedDate} placeholder="DD/MM/YYYY"
                                                                                                                onChange={(event) => handleChangeDate(event, parentIndex, subIndex, 'ReceivedDate')}
                                                                                                                type="date" autoComplete="off" onKeyDown={(e) => e.preventDefault()}
                                                                                                                className={'form-control'}
                                                                                                                style={{border: submitted && skuItem.ReceivedDate === '' ? '1px solid red' : '', color: skuItem.ReceivedDate === '' ? '#bfdea1' : '#000' }}
                                                                                                            />
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    </>:''
}
                                                                                                    
                                                                                                    <td>

                                                                                                        {/* <DatePicker className="form-control" name="ExFacDate"
                                                                                                            id={
                                                                                                                "ExFacDate"
                                                                                                            }
                                                                                                            selected={
                                                                                                                skuItem.ExFacDate
                                                                                                            }
                                                                                                            onChange={(event) => handleChangeDate(event, parentIndex, subIndex, 'ExFacDate')}
                                                                                                            dateFormat="dd/MM/yyyy"
                                                                                                            peekNextMonth
                                                                                                            //showMonthDropdown
                                                                                                            //showYearDropdown
                                                                                                            style={{ border: submitted && skuItem.ExFacDate === '' ? '1px solid red' : '', color: skuItem.ExFacDate === '' ? '#bfdea1' : '#000' }}
                                                                                                            onKeyDown={(e) => e.preventDefault()}
                                                                                                            dropdownMode="scroll"
                                                                                                            autoComplete="off"
                                                                                                            customInput={<CustomInputExfac />}
                                                                                                            isClearable={skuItem.ExFacDate === null ? false : true}
                                                                                                        /> */}






                                                                                                        <span className="input-icon icon-right">

                                                                                                            <input id="ExFacDate" name="ExFacDate"
                                                                                                                value={skuItem.ExFacDate} placeholder="DD/MM/YYYY"
                                                                                                                onChange={(event) => handleChangeDate(event, parentIndex, subIndex, 'ExFacDate')}
                                                                                                                type="date" autoComplete="off" onKeyDown={(e) => e.preventDefault()}
                                                                                                                className={'form-control'}
                                                                                                                style={{ border: submitted && skuItem.ExFacDate === '' ? '1px solid red' : '', color: skuItem.ExFacDate === '' ? '#bfdea1' : '#000' }}
                                                                                                            />
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {/* <DatePicker className="form-control" name="FacDate"
                                                                                                            id={
                                                                                                                "FacDate"
                                                                                                            }
                                                                                                            selected={
                                                                                                                skuItem.FacDate
                                                                                                            }
                                                                                                            onChange={(event) => handleChangeDate(event, parentIndex, subIndex, 'FacDate')}
                                                                                                            dateFormat="dd/MM/yyyy"
                                                                                                            peekNextMonth
                                                                                                            //showMonthDropdown
                                                                                                            //showYearDropdown
                                                                                                            style={{ border: submitted && skuItem.FacDate === '' ? '1px solid red' : '', color: skuItem.FacDate === '' ? '#bfdea1' : '#000' }}
                                                                                                            onKeyDown={(e) => e.preventDefault()}
                                                                                                            dropdownMode="scroll"
                                                                                                            autoComplete="off"
                                                                                                            customInput={<CustomInputfac />}
                                                                                                            isClearable={skuItem.FacDate === null ? false : true}
                                                                                                        /> */}

                                                                                                        <span className="input-icon icon-right">

                                                                                                            <input id="FacDate" name="FacDate"
                                                                                                                value={skuItem.FacDate} placeholder="DD/MM/YYYY"
                                                                                                                onChange={(event) => handleChangeDate(event, parentIndex, subIndex, 'FacDate')}
                                                                                                                type="date" autoComplete="off" onKeyDown={(e) => e.preventDefault()}
                                                                                                                className={'form-control'}
                                                                                                                style={{ color: skuItem.FacDate === '' ? '#bfdea1' : '#000' }}
                                                                                                            />
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    {/* || inputFields.DestinationOption === 1 || inputFields.IDPONOOption === 1 */}
                                                                                                    {
                                                                                                        inputFields.EndCustomerOption === 1 ?
                                                                                                            <>
                                                                                                                <td>
                                                                                                                    {/* <span className='input-icon icon-right' style={{ zIndex: 0 }}>
                                                                                                                <Reactselect className="basic-single" name="EndCustomerID"
                                                                                                                    id={"EndCustomerID"} isDisabled={false}
                                                                                                                    isLoading={false} isClearable={true}
                                                                                                                    isSearchable={true}
                                                                                                                    onChange={(event) => handleChange(event, parentIndex, subIndex, '', 'EndCustomerID')}
                                                                                                                    value={getEndCustomerList.filter(function (option) {
                                                                                                                        return option.value === skuItem.EndCustomerID;
                                                                                                                    })}
                                                                                                                    options={getEndCustomerList}
                                                                                                                    styles={submitted && inputFields.EndCustomerOption === 1 && skuItem.EndCustomerID === 0 ? styles : ''}
                                                                                                                ></Reactselect>
                                                                                                            </span> */}
                                                                                                                    <span className='input-icon icon-right'>
                                                                                                                        <select id={"EndCustomerID"} name="EndCustomerID" className="form-select"
                                                                                                                            value={skuItem.EndCustomerID}
                                                                                                                            onChange={event => handleChange(event, parentIndex, subIndex, '', 'EndCustomerID')}
                                                                                                                            style={{ border: submitted && skuItem.EndCustomerID === 0 ? '1px solid red' : '', color: parseInt(skuItem.EndCustomerID) === 0 ? '#bfdea1' : '#000' }}
                                                                                                                        // color: parseInt(skuItem.EndCustomerID) === 0 ? '#bfdea1' : '#000'
                                                                                                                        >
                                                                                                                            <option value="0" style={{ color: '#bfdea1' }}>-Select End Customer-</option>
                                                                                                                            {
                                                                                                                                getEndCustomerList.map(SKUitem => (
                                                                                                                                    <option key={SKUitem.value} value={SKUitem.value}>
                                                                                                                                        {SKUitem.name}
                                                                                                                                    </option>
                                                                                                                                ))
                                                                                                                            }
                                                                                                                        </select>
                                                                                                                    </span>
                                                                                                                </td>
                                                                                                            </> : ''
                                                                                                    }
                                                                                                    {
                                                                                                        inputFields.DestinationOption === 1 ?
                                                                                                            <>
                                                                                                                <td>
                                                                                                                    {/* <span className='input-icon icon-right'>
                                                                                                                <Reactselect className="basic-single" name="DestinationID"
                                                                                                                    id={"DestinationID"} isDisabled={false}
                                                                                                                    isLoading={false} isClearable={true}
                                                                                                                    isSearchable={true}
                                                                                                                    onChange={(event) => handleChange(event, parentIndex, subIndex, '', 'DestinationID')}
                                                                                                                    value={getcountryList.filter(function (option) {
                                                                                                                        return option.value === skuItem.DestinationID;
                                                                                                                    })}
                                                                                                                    options={getcountryList}
                                                                                                                    styles={submitted && inputFields.DestinationOption === 1 && skuItem.DestinationID === 0 ? styles : ''}
                                                                                                                ></Reactselect>
                                                                                                            </span> */}
                                                                                                                    <span className='input-icon icon-right'>
                                                                                                                        <select id={"DestinationID"} name="DestinationID" className="form-select"
                                                                                                                            value={skuItem.DestinationID}
                                                                                                                            onChange={event => handleChange(event, parentIndex, subIndex, '', 'DestinationID')}
                                                                                                                            tooltip={skuItem.DestinationID}
                                                                                                                            style={{ border: submitted && skuItem.DestinationID === 0 ? '1px solid red' : '', color: parseInt(skuItem.DestinationID) === 0 ? '#bfdea1' : '#000' }}
                                                                                                                        // color: parseInt(skuItem.DestinationID) === 0 ? '#bfdea1' : '#000'
                                                                                                                        >
                                                                                                                            <option value="0" style={{ color: '#bfdea1' }}>-Select Destination-</option>
                                                                                                                            {
                                                                                                                                getcountryList.map(SKUitem => (
                                                                                                                                    <option key={SKUitem.value} value={SKUitem.value}>
                                                                                                                                        {SKUitem.label}
                                                                                                                                    </option>
                                                                                                                                ))
                                                                                                                            }
                                                                                                                        </select>
                                                                                                                    </span>
                                                                                                                </td>
                                                                                                            </>
                                                                                                            : ''
                                                                                                    }
                                                                                                    {
                                                                                                        inputFields.ShowHidepacking === 1 ?
                                                                                                            <>
                                                                                                                <td >
                                                                                                                    {/* <span className='input-icon icon-right'>
                                                                                                                        <Reactselect className="basic-single" name="PackingPlanID"
                                                                                                                            id={"PackingPlanID"}
                                                                                                                            isLoading={false} isClearable={true}
                                                                                                                            isSearchable={true}
                                                                                                                            onChange={(event) => handleChange(event, parentIndex, subIndex, '', 'PackingSubPlanID')}
                                                                                                                            value={getpackingoption.filter(function (option) {
                                                                                                                                return option.value === skuItem.PackingPlanID
                                                                                                                            })}
                                                                                                                            placeholder="- Select Packing Plan -"
                                                                                                                            options={getpackingoption}
                                                                                                                        //styles={submitted && inputFields.EndCustomerOption !== 1 && inputFields.EndCustomerID === '' ? styles : ''}
                                                                                                                        ></Reactselect>
                                                                                                                    </span> */}
                                                                                                                    <span className='input-icon icon-right'>
                                                                                                                        <select id={"PackingPlanID"} name="PackingPlanID" className="form-select"
                                                                                                                            value={skuItem.PackingPlanID}
                                                                                                                            onChange={event => handleChange(event, parentIndex, subIndex, '', 'PackingSubPlanID')}
                                                                                                                            tooltip={skuItem.PackingPlanID}
                                                                                                                            styles={submitted && inputFields.EndCustomerOption !== 1 && inputFields.EndCustomerID === '' ? styles : ''}
                                                                                                                        // color: parseInt(skuItem.DestinationID) === 0 ? '#bfdea1' : '#000'
                                                                                                                        >
                                                                                                                            <option value="0" style={{ color: '#bfdea1' }}>-Select Packing Plan-</option>
                                                                                                                            {
                                                                                                                                getpackingoption.map(SKUitem => (
                                                                                                                                    <option key={SKUitem.value} value={SKUitem.value}>
                                                                                                                                        {SKUitem.label}
                                                                                                                                    </option>
                                                                                                                                ))
                                                                                                                            }
                                                                                                                        </select>
                                                                                                                    </span>
                                                                                                                </td>
                                                                                                            </>
                                                                                                            : ''

                                                                                                    }
                                                                                                    {Buttonview !== "View" &&
                                                                                                        <td className='Actionbutton fixed-column'>

                                                                                                            <table>
                                                                                                                <tbody>

                                                                                                                    <td style={{ padding: 0 }}>
                                                                                                                        {
                                                                                                                            styleItem.SKUInfo.filter(prod => prod.IsDeleted === 0).length > 0 ?
                                                                                                                                <>
                                                                                                                                    <div style={{ padding: '2px', display: styleItem.SKUInfo.filter(prod => prod.IsDeleted === 0).length === 0 ? "none" : "" }}>
                                                                                                                                        <span>
                                                                                                                                            <span className="btn btn-success"
                                                                                                                                                style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}
                                                                                                                                                title="Add SKU" onClick={() => AddSKUFields('Add', parentIndex, subIndex)}>
                                                                                                                                                <i className="fa fa-plus"></i>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                </>
                                                                                                                                : ''
                                                                                                                        }
                                                                                                                    </td>
                                                                                                                    <td style={{ padding: 0 }}>
                                                                                                                        {
                                                                                                                            styleItem.SKUInfo.filter(prod => prod.IsDeleted === 0).length > 0 ?
                                                                                                                                <>
                                                                                                                                    <div style={{ padding: '2px', display: styleItem.SKUInfo.filter(prod => prod.IsDeleted === 0).length === 0 ? "none" : "" }}>
                                                                                                                                        <span>
                                                                                                                                            <span className="btn btn-primary" title="Copy SKU" onClick={() => AddSKUFields('Copy', parentIndex, subIndex)}>
                                                                                                                                                <i className="fa fa-copy"></i>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                </>
                                                                                                                                : ''
                                                                                                                        }
                                                                                                                    </td>
                                                                                                                    <td style={{ padding: 0 }}>
                                                                                                                        {
                                                                                                                            styleItem.SKUInfo.filter(prod => prod.IsDeleted === 0).length > 1 &&
                                                                                                                            <>
                                                                                                                                {styleItem.SKUInfo.filter(prod => prod.IsDeleted === 0).length !== 1 ?
                                                                                                                                    <>
                                                                                                                                        <div style={{ padding: '2px', display: styleItem.SKUInfo.filter(prod => prod.IsDeleted === 0).length === 1 ? "none" : "" }}>
                                                                                                                                            <span>
                                                                                                                                                <span className="btn btn-danger" title="Delete SKU" style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}
                                                                                                                                                    onClick={() => RemoveSKUFields(parentIndex, subIndex)}>
                                                                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </div>
                                                                                                                                    </>
                                                                                                                                    : ''
                                                                                                                                }
                                                                                                                            </>
                                                                                                                        }
                                                                                                                    </td>
                                                                                                                    <td style={{ padding: 0 }}>
                                                                                                                        <span style={{ padding: '2px' }}>
                                                                                                                            <span className="btn" title="SKU Comments" onClick={() => GetComments(parentIndex, subIndex)}
                                                                                                                                style={{
                                                                                                                                    background: skuItem.Comments.length === 0 ? '#427fed' : skuItem.Comments.length !== 0 && skuItem.Comments[0].PurOrdSkuComments !== '' ? 'red' : '#427fed',
                                                                                                                                    color: 'white',
                                                                                                                                    padding: '3px 4px',
                                                                                                                                    borderRadius: '2px',
                                                                                                                                    border: skuItem.Comments.length === 0 ? '#427fed' : skuItem.Comments.length !== 0 && skuItem.Comments[0].PurOrdSkuComments !== '' ? 'red' : '#427fed',
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <i className="fa fa-comment"></i>
                                                                                                                            </span>

                                                                                                                        </span>

                                                                                                                        <Modal show={skuItem.EnableComments === 1} dialogAs={Drag} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleFullClose(parentIndex, subIndex)}>
                                                                                                                            <Modal.Header closeButton>
                                                                                                                                <Modal.Title>
                                                                                                                                    Comments
                                                                                                                                </Modal.Title>
                                                                                                                            </Modal.Header>
                                                                                                                            <Modal.Body>
                                                                                                                                <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                                                                    <thead>
                                                                                                                                        <tr>
                                                                                                                                            <th>Comments<span className="text-danger">*</span></th>
                                                                                                                                            <th style={{ width: '16%' }}>Date</th>
                                                                                                                                            <th style={{ width: '10%' }}>Action</th>
                                                                                                                                        </tr>
                                                                                                                                    </thead>
                                                                                                                                    <tbody>
                                                                                                                                        {
                                                                                                                                            skuItem.Comments.map((Commentitem, subChildIndex2) => {
                                                                                                                                                return (
                                                                                                                                                    Commentitem.PurOrdSkuComments !== '' ?
                                                                                                                                                        <tr>
                                                                                                                                                            <td>
                                                                                                                                                                {Commentitem.PurOrdSkuComments}
                                                                                                                                                            </td>
                                                                                                                                                            <td>
                                                                                                                                                                {Commentitem.Date.toLocaleDateString('en-US')}
                                                                                                                                                                &nbsp;
                                                                                                                                                                {Commentitem.Date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                                                                                                                                            </td>
                                                                                                                                                            <td>
                                                                                                                                                                {
                                                                                                                                                                    skuItem.Comments.filter(prod => prod.IsDeleted === 0) &&
                                                                                                                                                                    <>
                                                                                                                                                                        <span style={{ padding: '2px', display: Commentitem.PurOrdSkuComments === '' ? "none" : "", float: "right" }}>
                                                                                                                                                                            <span>
                                                                                                                                                                                <span className="btn btn-danger" title="Delete Comment" onClick={() => RemoveComments(parentIndex, subIndex, subChildIndex2)}>
                                                                                                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                                                                                                </span>
                                                                                                                                                                            </span>
                                                                                                                                                                        </span>
                                                                                                                                                                    </>
                                                                                                                                                                }
                                                                                                                                                                {
                                                                                                                                                                    <>
                                                                                                                                                                        <span style={{ padding: '2px', display: subChildIndex2 === (skuItem.Comments.length - 1) ? "" : "none", float: "right" }}>
                                                                                                                                                                            <span>
                                                                                                                                                                                <span className="btn btn-success" title="Edit Comment" onClick={() => EditComments(parentIndex, subIndex, subChildIndex2, Commentitem.PurOrdSkuComments)}>
                                                                                                                                                                                    <i className="fa fa-edit"></i>
                                                                                                                                                                                </span>
                                                                                                                                                                            </span>
                                                                                                                                                                        </span>
                                                                                                                                                                    </>
                                                                                                                                                                }
                                                                                                                                                            </td>
                                                                                                                                                        </tr>
                                                                                                                                                        : ''
                                                                                                                                                );
                                                                                                                                            })
                                                                                                                                        }
                                                                                                                                        <tr>
                                                                                                                                            <td colSpan="6">
                                                                                                                                                <textarea
                                                                                                                                                    rows="3"
                                                                                                                                                    placeholder="Enter comments here..."
                                                                                                                                                    autoFocus onFocus={onFocus} autoComplete="off"
                                                                                                                                                    maxLength="500" name="EnterComment"
                                                                                                                                                    id={"EnterComment"}
                                                                                                                                                    value={skuItem.EnterComment}
                                                                                                                                                    onChange={event => handleChangeSKUComments(event, parentIndex, subIndex)}
                                                                                                                                                    style={{ width: "100%", border: getComment && skuItem.EnterComment === '' ? '1px solid red' : '' }}>
                                                                                                                                                </textarea>
                                                                                                                                            </td>
                                                                                                                                        </tr>
                                                                                                                                    </tbody>
                                                                                                                                </table>
                                                                                                                            </Modal.Body>
                                                                                                                            <Modal.Footer>
                                                                                                                                <Button variant="success" onClick={() => handleSave(parentIndex, subIndex, skuItem.Comments.length, skuItem.EnterComment)}> {/* , skuItem.PurOrdSkuCommentsID, skuItem.PurchaseOrderID */}
                                                                                                                                    {getHeader === 'Add' ? 'Save' : 'Save'}
                                                                                                                                </Button>
                                                                                                                            </Modal.Footer>
                                                                                                                        </Modal>
                                                                                                                    </td>
                                                                                                                    <td
                                                                                                                        style={{ padding: '2px' }}>
                                                                                                                        <label style={{ marginTop: '8px' }}>
                                                                                                                            <input type="checkbox" name="CheckSKU"
                                                                                                                                isMulti onChange={event => handleSingleCheck(event, parentIndex, subIndex)}
                                                                                                                                checked={skuItem.CheckSKU === 1 ? true : false}
                                                                                                                                className="colored-blue clearOpacity" />
                                                                                                                            {Buttonview !== "View" &&
                                                                                                                                <span className="text"></span>
                                                                                                                            }
                                                                                                                        </label>

                                                                                                                    </td>

                                                                                                                    <td style={{ padding: '2px' }}>
                                                                                                                        <span>
                                                                                                                            {Buttonview !== "View" &&
                                                                                                                                <span className="btn btn-xs btn-warning" onClick={(e) => PopUpChanges(e, parentIndex, subIndex)}>
                                                                                                                                    <i className="fa fa-file-text-o" aria-hidden="true" title="Add Multiple Details"></i>
                                                                                                                                </span>
                                                                                                                            }
                                                                                                                        </span>
                                                                                                                    </td>
                                                                                                                    {
                                                                                                                        skuItem.OpenChkPopUP === 1 ?
                                                                                                                            <Modal show={skuItem.OpenChkPopUP === 1} dialogAs={Drag} size="lg" dragable backdrop="static" keyboard={false} onHide={() => CloseCheckPopUp(parentIndex, subIndex)}>
                                                                                                                                <Modal.Header closeButton>
                                                                                                                                    <Modal.Title>
                                                                                                                                        Add Details
                                                                                                                                    </Modal.Title>
                                                                                                                                </Modal.Header>
                                                                                                                                <Modal.Body>
                                                                                                                                    <div >
                                                                                                                                        <div className="row">
                                                                                                                                            <div className="col-sm-6 col-lg-6">
                                                                                                                                                <label htmlFor={"UnitPrice"}>Unit Price</label>
                                                                                                                                                <Input id="UnitPrice" name="UnitPrice"
                                                                                                                                                    value={inputFields.UnitPrice} placeholder="Enter Unit Price"
                                                                                                                                                    onChange={(event) => HandleChangePopUp(event, '', parentIndex, subIndex)}
                                                                                                                                                    type="text" autoComplete="off" maxLength="15"
                                                                                                                                                    className={'form-control'} autoFocus={getFocus.UP}
                                                                                                                                                />
                                                                                                                                            </div>
                                                                                                                                            <div className="col-sm-6 col-lg-6">
                                                                                                                                                <label htmlFor={"ShipmentMode"}>Shipment Mode</label>
                                                                                                                                                <span className="input-icon icon-right">
                                                                                                                                                    <select id={"ShipmentMode"} name="ShipmentMode" className="form-select"
                                                                                                                                                        value={parseInt(inputFields.ShipmentMode)} onChange={(event) => HandleChangePopUp(event, '', parentIndex, subIndex)}
                                                                                                                                                    >
                                                                                                                                                        <option value="" key="0">Select</option>
                                                                                                                                                        <option value="0" key="0">Air</option>
                                                                                                                                                        <option value="1" key="1">Sea</option>
                                                                                                                                                    </select>
                                                                                                                                                </span>
                                                                                                                                            </div>
                                                                                                                                            {
                                                                                                                                                inputFields.IDPONOOption === 1 ? <div className="col-sm-6 col-lg-6">
                                                                                                                                                    <label htmlFor={"IDPONO"}>PO/ID NO</label>
                                                                                                                                                    <Input id="IDPONO" name="IDPONO"
                                                                                                                                                        value={inputFields.IDPONO} placeholder="Enter PO/ID NO"
                                                                                                                                                        onChange={(event) => HandleChangePopUp(event, 'IDPONO', parentIndex, subIndex)}
                                                                                                                                                        type="text" autoComplete="off" maxLength="100"
                                                                                                                                                        className={'form-control'} autoFocus={getFocus.Ref}
                                                                                                                                                    />
                                                                                                                                                </div> : ""
                                                                                                                                            }

                                                                                                                                            {
                                                                                                                                                inputFields.IsFocusPONoOption === 1 ? <div className="col-sm-6 col-lg-6">
                                                                                                                                                    <label htmlFor={"FocusPONO"}>Focus PO No</label>
                                                                                                                                                    <Input id="FocusPONO" name="FocusPONO"
                                                                                                                                                        value={inputFields.FocusPONO} placeholder="Enter Focus PO NO"
                                                                                                                                                        onChange={(event) => HandleChangePopUp(event, 'FocusPONO', parentIndex, subIndex)}
                                                                                                                                                        type="text" autoComplete="off" maxLength="100"
                                                                                                                                                        className={'form-control'} autoFocus={getFocus.FocusPONO}
                                                                                                                                                    />
                                                                                                                                                </div> : ""
                                                                                                                                            }
                                                                                                                                            {

                                                                                                                                                inputFields.ReceivedDateOption === 1 ?
                                                                                                                                                    <div className="col-sm-6 col-lg-6">
                                                                                                                                                        <label htmlFor={"ReceivedDate"}>Received Date</label>
                                                                                                                                                        <span className="input-icon icon-right">
                                                                                                                                                            <input id="ReceivedDate" name="ReceivedDate"
                                                                                                                                                                value={inputFields.ReceivedDate} placeholder="DD/MM/YYYY"
                                                                                                                                                                onChange={(event) => handleChangeDate(event, parentIndex, subIndex, 'PopupReceivedDate')}
                                                                                                                                                                type="date" autoComplete="off"
                                                                                                                                                                className={'form-control'} onKeyDown={(e) => e.preventDefault()}
                                                                                                                                                                style={{ color: inputFields.ReceivedDate === '' ? '#bfdea1' : '#000' }}
                                                                                                                                                            />
                                                                                                                                                        </span>
                                                                                                                                                    </div> : ""
                                                                                                                                            }
                                                                                                                                            <div className="col-sm-6 col-lg-6">
                                                                                                                                                <label htmlFor={"ExFacDate"}>Ex-Factory Date</label>
                                                                                                                                                {/* <DatePicker className="form-control" name="ExFacDate"
                                                                                                                                                    id={
                                                                                                                                                        "ExFacDate"
                                                                                                                                                    }
                                                                                                                                                    selected={
                                                                                                                                                        inputFields.ExFacDate
                                                                                                                                                    }
                                                                                                                                                    onChange={(event) => handleChangeDate(event, parentIndex, subIndex, 'PopupExFacDate')}
                                                                                                                                                    dateFormat="dd/MM/yyyy"
                                                                                                                                                    peekNextMonth
                                                                                                                                                    //showMonthDropdown
                                                                                                                                                    //showYearDropdown
                                                                                                                                                    style={{ color: inputFields.ExFacDate === '' ? '#bfdea1' : '#000' }}
                                                                                                                                                    onKeyDown={(e) => e.preventDefault()}
                                                                                                                                                    dropdownMode="scroll"
                                                                                                                                                    autoComplete="off"
                                                                                                                                                    customInput={<PopupExFacDate />}
                                                                                                                                                    isClearable={inputFields.ExFacDate === null ? false : true}
                                                                                                                                                /> */}


                                                                                                                                                <span className="input-icon icon-right">
                                                                                                                                                    <input id="ExFacDate" name="ExFacDate"
                                                                                                                                                        value={inputFields.ExFacDate} placeholder="DD/MM/YYYY"
                                                                                                                                                        onChange={(event) => handleChangeDate(event, parentIndex, subIndex, 'PopupExFacDate')}
                                                                                                                                                        type="date" autoComplete="off"
                                                                                                                                                        className={'form-control'} onKeyDown={(e) => e.preventDefault()}
                                                                                                                                                        style={{ color: inputFields.ExFacDate === '' ? '#bfdea1' : '#000' }}
                                                                                                                                                    />
                                                                                                                                                </span>
                                                                                                                                            </div>
                                                                                                                                            <div className="col-sm-6 col-lg-6">
                                                                                                                                                <label htmlFor={"FacDate"}>Fac.Ex-Fac Confirm</label>
                                                                                                                                                {/* <DatePicker className="form-control" name="FacDate"
                                                                                                                                                    id={
                                                                                                                                                        "FacDate"
                                                                                                                                                    }
                                                                                                                                                    selected={
                                                                                                                                                        inputFields.FacDate
                                                                                                                                                    }
                                                                                                                                                    onChange={(event) => handleChangeDate(event, parentIndex, subIndex, 'PopupFacDate')}
                                                                                                                                                    dateFormat="dd/MM/yyyy"
                                                                                                                                                    peekNextMonth
                                                                                                                                                    //showMonthDropdown
                                                                                                                                                    //showYearDropdown
                                                                                                                                                    style={{ color: inputFields.FacDate === '' ? '#bfdea1' : '#000' }}
                                                                                                                                                    onKeyDown={(e) => e.preventDefault()}
                                                                                                                                                    dropdownMode="scroll"
                                                                                                                                                    autoComplete="off"
                                                                                                                                                    customInput={<PopupExFacDate />}
                                                                                                                                                    isClearable={inputFields.FacDate === null ? false : true}
                                                                                                                                                /> */}
                                                                                                                                                <span className="input-icon icon-right">
                                                                                                                                                    <input id="FacDate" name="FacDate"
                                                                                                                                                        value={inputFields.FacDate} placeholder="DD/MM/YYYY"
                                                                                                                                                        onChange={(event) => handleChangeDate(event, parentIndex, subIndex, 'PopupFacDate')}
                                                                                                                                                        type="date" autoComplete="off"
                                                                                                                                                        className={'form-control'} onKeyDown={(e) => e.preventDefault()}
                                                                                                                                                        style={{ color: inputFields.FacDate === '' ? '#bfdea1' : '#000' }}
                                                                                                                                                    />
                                                                                                                                                </span>
                                                                                                                                            </div>
                                                                                                                                            {

                                                                                                                                                inputFields.EndCustomerOption === 1 ?
                                                                                                                                                    <div className="col-sm-6 col-lg-6">
                                                                                                                                                        <label htmlFor={"EndCustomer"}>End Customer</label>
                                                                                                                                                        <span className='input-icon icon-right' style={{ zIndex: 0 }}>
                                                                                                                                                            <Reactselect className="basic-single" name="EndCustomer"
                                                                                                                                                                id={"EndCustomer"} isDisabled={false}
                                                                                                                                                                isLoading={false} isClearable={true}
                                                                                                                                                                isSearchable={true}
                                                                                                                                                                onChange={(event) => HandleChangePopUp(event, 'EndCustomer')}
                                                                                                                                                                value={getEndCustomerList.filter(function (option) {
                                                                                                                                                                    return option.value === inputFields.EndCustomer;
                                                                                                                                                                })}
                                                                                                                                                                placeholder="- Select End Customer -"
                                                                                                                                                                options={getEndCustomerList}
                                                                                                                                                            ></Reactselect>
                                                                                                                                                        </span>
                                                                                                                                                    </div> : ""
                                                                                                                                            }
                                                                                                                                            {

                                                                                                                                                inputFields.DestinationOption === 1 ?
                                                                                                                                                    <div className="col-sm-6 col-lg-6">
                                                                                                                                                        <label htmlFor={"Destination"}>Destination</label>
                                                                                                                                                        <span className='input-icon icon-right'>
                                                                                                                                                            <Reactselect className="basic-single" name="Destination"
                                                                                                                                                                id={"Destination"} isDisabled={false}
                                                                                                                                                                isLoading={false} isClearable={true}
                                                                                                                                                                isSearchable={true}
                                                                                                                                                                onChange={(event) => HandleChangePopUp(event, 'Destination')}
                                                                                                                                                                value={getcountryList.filter(function (option) {
                                                                                                                                                                    return option.value === inputFields.Destination;
                                                                                                                                                                })}
                                                                                                                                                                placeholder="- Select Destination -"
                                                                                                                                                                options={getcountryList}
                                                                                                                                                            ></Reactselect>
                                                                                                                                                        </span>
                                                                                                                                                    </div> : ""
                                                                                                                                            }
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </Modal.Body>
                                                                                                                                <Modal.Footer>
                                                                                                                                    <Button variant="success" onClick={() => SaveCheckPopUp(parentIndex, subIndex)}>
                                                                                                                                        {getHeader === 'Add' ? 'Ok' : 'Ok'}
                                                                                                                                    </Button>
                                                                                                                                    <Button variant="danger" onClick={() => CloseCheckPopUp(parentIndex, subIndex)}>
                                                                                                                                        {getHeader === 'Add' ? 'Cancel' : 'Cancel'}
                                                                                                                                    </Button>

                                                                                                                                </Modal.Footer>
                                                                                                                            </Modal>
                                                                                                                            : ''
                                                                                                                    }
                                                                                                                </tbody>
                                                                                                            </table>

                                                                                                        </td>
                                                                                                    }
                                                                                                </tr>
                                                                                            </>
                                                                                            : ''
                                                                                    ))
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : ''
                                                    ))
                                                }
                                                <div className="col-sm-12 col-lg-12">
                                                    <div className="row">
                                                        <div className="col-sm-7 col-lg-7">
                                                            <div className='form-group'>
                                                                <label htmlFor="Remarks">Remarks</label>
                                                                <textarea maxLength="300" name="Remarks" value={inputFields.Remarks} onChange={event => handleChange(event, '', '', '')} rows="4" style={{ width: "100%" }}></textarea>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-sm-2 col-lg-2" style={{ marginTop: '45px' }}>
                                                        </div> */}
                                                        <div className="col-sm-3 col-lg-3" style={{ marginTop: '15px' }}>
                                                            <div className="row">
                                                                <div className='col-sm-5' style={{ textAlign: 'left' }}>
                                                                    <h6 htmlFor="TotalQuantity" style={{ fontWeight: "bold" }}>Total Quantity&nbsp; &nbsp;:</h6>
                                                                    <h6 htmlFor="TotalValue" style={{ fontWeight: "bold" }}>Total Value &nbsp; &nbsp; &nbsp; &nbsp; :</h6>
                                                                </div>
                                                                <div className='col-sm-5'>
                                                                    <h6 style={{ textAlign: 'right', marginRight: "10px", color: "#53a93f", fontWeight: "600" }}>{inputFields.TotalQuantity} </h6>
                                                                    <h6 style={{ textAlign: 'right', marginRight: "10px", color: "#427fed", fontWeight: "600" }}>{getCurrencysymbol}&nbsp;{inputFields.TotalValue} </h6>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="col-sm-12 col-lg-12">
                                                            <div className="widget-header" style={{ paddingLeft: 0 }}>
                                                                <span className="lowCaseText widget-caption" style={{ fontSize: '14px' }}>File Upload <span style={{ color: 'red', fontSize: '14px' }} >(Upload Document Type: Excel, Word, PDF and Image)</span></span>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-lg-12">
                                                            <div className="row">
                                                                <div className="col-sm-8 col-lg-8">
                                                                    <div className='form-group'>
                                                                        <tr>
                                                                            <td> <input type="file" id="FileUpload" style={{ fontSize: '14px', display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }} onChange={(e) => AppendFiles(e)} accept=".jpg,.jpeg,.png,.pdf,.xlsx,.doc,.docx,.xls" ></input></td>
                                                                        </tr>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-12 col-sm-12">
                                                            <div className="row no-margin">
                                                                {
                                                                    getFiles.length !== 0 ?
                                                                        getFiles.map((files, fileindex) => (
                                                                            <div className="col-lg-4 col-sm-4 file_list_upload">
                                                                                <tr title={files.FileName}>
                                                                                    <td className="cut_text">
                                                                                        <span style={{ marginRight: '7px' }}>
                                                                                            {
                                                                                                files.FileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                    files.FileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.FileType === "application/vnd.ms-excel" ?
                                                                                                        <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                        files.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                            files.FileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                            <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                            }
                                                                                        </span>  {files.FileName}
                                                                                    </td>

                                                                                    <td className="button_style_uploda">
                                                                                        {
                                                                                            files.FileType === "application/pdf" || files.FileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                                                                || files.FileType === 'application/msword' || files.FileType === "application/vnd.ms-excel" ?
                                                                                                <a style={{ display: activeMenu && activeMenu[0].isView === 1 ? '' : 'none' }} type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + 'Images/PODocs/' + files.FileName} download><i class="fa fa-download"></i> </a> :
                                                                                                <i className="btn btn-info fa fa-eye" title="View Logo"
                                                                                                    style={{ display: activeMenu && activeMenu[0].isView === 1 ? '' : 'none' }}
                                                                                                    onClick={
                                                                                                        event => ViewFile(event, files.FileName)
                                                                                                    }></i>
                                                                                        }&nbsp;
                                                                                        {Buttonview !== "View" &&

                                                                                            <p title='Delete File'
                                                                                                style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}
                                                                                                onClick={(e) => RemoveFiles(e, fileindex)}
                                                                                                className="btn btn-danger btn-xs delete">
                                                                                                <i className="fa fa-trash-o"></i>

                                                                                            </p>
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                                {

                                                                                    <div> {
                                                                                        isOpenimg && (

                                                                                            <Lightbox mainSrc={window.$APIBaseURL + 'Images/PODocs/' + getImagename}
                                                                                                alt="bg image"
                                                                                                onCloseRequest={
                                                                                                    () => setIsOpenimg(false)
                                                                                                } />
                                                                                        )
                                                                                    } </div>
                                                                                }
                                                                            </div>
                                                                        ))
                                                                        : ''
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : ''
                                    }
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>
                        <a className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                            <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                        </a>
                        <span className="btn btn-danger" onClick={() => ResetOperation()}>
                            <i className="fa fa-times"></i>&nbsp;Reset
                        </span>
                        {Buttonvisible !== "View" &&
                            (<button type="submit" className="btn btn-success" onClick={(e) => SavePurchaseOrder(e)} disabled={getDisableSave}>

                                <i className="fa fa-check right"></i> &nbsp;{getHeader === 'Add' ? 'Save' : 'Update'}
                            </button>)
                        }
                        {Buttonvisible === "View" && activeMenu && activeMenu[0].isView === 1 ? '' : activeMenu && activeMenu[0].isView === 0 ? '' :
                            (<button type="submit" style={{ display: activeMenu && activeMenu[0].isView === 0 ? '' : 'none' }} className="btn btn-info" onClick={viewtoEdit}>
                                <i className="fa fa-edit"></i>&nbsp;Edit
                            </button>)
                        }
                    </div>
                </div>
            </div>
            <Modal show={ShowImportPopup} dialogAs={Drag} size="md" dragable backdrop="static" keyboard={false} onHide={(event) => handleClose(event)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {"Style Import"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> <div className="col-md-9">
                        <span className='input-icon icon-right'>
                            <Reactselect name="StyleImport"
                                id={
                                    "StyleImport"
                                }
                                className="basic-multi-select"
                                classNamePrefix="select"
                                isDisabled={false}
                                isLoading={false}
                                isClearable={true}
                                isSearchable={true}
                                isMulti
                                options={options}
                                menuPosition="unset"
                                value={getImportValue}
                                onChange={(event) => handleImportChange(event)}
                                filterOption={filterOption}
                            ></Reactselect>
                        </span>
                    </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={() => handleSaveImport()} disabled={getDisableImport}>
                        Import
                    </Button>
                </Modal.Footer>
            </Modal>
            {
                getres === 1 ? <Loader type="ThreeDots" color="#b6da94" height={80} width={80} timeout={9000} style={{ textAlign: "center" }} /> : ''
            }

            {
                getID.showPopupConfirm ? <ConfirmationPopup props={props} data={
                    getID.Params
                }
                    deleteCallback={Delcallback}
                    showpopup={true}
                /> : null
            }
        </div>
    );
};

export default PurchaseOrder;