import {
    YARNTYPE_LOADING,
    RETRIEVE_YARNTYPE,
    DELETE_YARNTYPE,
}

    from "./types";
import YarnTypeService from "../services/Master/YarnTypeService";

const YarnTypeLoading = (isStatus) => ({
    type: YARNTYPE_LOADING,
    payload: isStatus,
}

);

export const deleteYarnType = (yarnTypeID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_YARNTYPE,
            payload: yarnTypeID,
        }
        );
    }

    catch (err) {
        console.log(err);
    }
};

export const retrieveYarnType = (yarnTypeID) => async (dispatch) => {
    try {
        dispatch(YarnTypeLoading(true));
        const res = await YarnTypeService.GetYarnList(yarnTypeID);

        dispatch({
            type: RETRIEVE_YARNTYPE,
            payload: res.data,
        }

        );
        dispatch(YarnTypeLoading(false));
    }

    catch (err) {
        console.log(err);
        dispatch(YarnTypeLoading(false));
    }
}

    ;