import axios from "axios";
import authHeader from "../auth-header";

class ProgramService {
    LoadProgramList(StyleID) {
        return axios.get(window.$APIBaseURL + "Style/GetStyleProgramList?StyleID=" + StyleID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadFabricList(StyleID) {
        return axios.get(window.$APIBaseURL + "Style/GetStyleProgramFabricDetailsInfoList?StyleID=" + StyleID, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    
    LoadCriticalList(StyleID) {
        return axios.get(window.$APIBaseURL + "Style/GetStyleProgramCirticalDetailsInfoList?StyleID=" + StyleID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadStyleFabricDetailsList(StyleID) {
        return axios.get(window.$APIBaseURL + "Style/LoadStyleFabricDetailsList?StyleID=" + StyleID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetAllPrograms(StyleID){
        return axios.get(window.$APIBaseURL + "Style/GetAllPrograms?StyleID=" + StyleID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetTNALabDipAlreadyMapList(params) {
        return axios.get(window.$APIBaseURL + "Style/GetTNALabDipAlreadyMapList?StyleID="+params.StyleID+"&BuyerID="+params.BuyerID+"&BrandID="+params.BrandID+"&PantoneID=" + params.PantoneID+"&PantoneTypeID="+params.PantoneTypeID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

}

export default new ProgramService()