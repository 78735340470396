import React, { useState, useEffect, Fragment } from "react";
import ReactNotification from 'react-notifications-component';
import Nodify from "../../Common/ReactNotification"
import SearchRetain from "../../Common/SearchRetain";
import Reactselect from 'react-select';
import Packageimage from "../../../services/Master/PackageImage";
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";


function AddPackageimage(props) {
    const [buttonLoader, setButtonLoader] = useState(false);
    const [inputfeild, setinputfeild] = useState([{ PackageImageID: 0, PackingImageName: '', IsSkuimageID: 0, IsSkuimage: 'Ts' }]);
    const [submitted, setSubmitted] = useState(false);
    const [styleskuimage, setstyleskuimage] = useState([]);
    const [getExisting, setExisting] = useState([]);
    const { user: currentUser } = useSelector((state) => state.auth);


    const Values = [...inputfeild];
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),
    }

    useEffect(() => {

        Packageimage.GetPackageImageList(2, 0).then((res) => {
            if (res.data) {
                setstyleskuimage(res.data.commonDropdown)
            }
        })
        Packageimage.GetPackageImageList(1, 0).then((res) => {
            if (res.data) {
                setExisting(res.data.inputPackageimageList1)
            }
        })
    }, [])

    function handleAddFields(index) {
        if (Values[index].PackingImageName !== '') {
            Values.push({
                PackageImageID: 0, PackingImageName: '', IsSkuimageID: 0, IsSkuimage: ''
            });
            setSubmitted(false);
            setinputfeild(Values);
        } else {
            setSubmitted(true);
            Nodify('Warning!', 'warning', 'Please fill the mandantory(*) fields');
        }
    }

    function handleRemoveFields(index) {
        Values.splice(index, 1);
        setinputfeild(Values);
    }

    function CheckDuplicate(index) {
        let ExistingValue = !!getExisting.find(x => x.packingImageName.toLowerCase().trim() === Values[index].PackingImageName.toLowerCase().trim())
        if (ExistingValue) {
            Values[index].PackingImageName = '';
            Values[index].IsSkuimage = '';
            Values[index].IsSkuimageID = 0;
            setinputfeild(Values);
            Nodify('Warning!', 'warning', 'These details are already exists, please enter differently');
            return false;
        }
        for (var j = 0; j < (Values.length); j++) {
            if (j !== index) {
                if (Values[j].PackingImageName.toLowerCase().trim() === Values[index].PackingImageName.toLowerCase().trim()) {
                    Values[index].PackingImageName = '';
                    Values[index].IsSkuimage = '';
                    Values[index].IsSkuimageID = 0;
                    setinputfeild(Values);
                    Nodify('Warning!', 'warning', 'These details are already exists, please enter differently');
                    return false;
                }

            }
        }
    }

    function handleInputChange(index, event, FieldName) {
        let inputText = '';
        if (FieldName === "Packingimage") {
            if (event.target.value.trim() !== '') {
                inputText = event.target.value;
            }
            Values[index].PackingImageName = inputText;
        } else {
            if (event.target.checked === true) {
                Values[index].IsSkuimageID = 1
            } else {
                Values[index].IsSkuimageID = 0
            }
        }
        setinputfeild(Values);
    }


    function ResetOperation(e) {
        if (e === "Back") {
            props.history.push('/PackageImageList');
        } else {
            window.location.reload();
        }
    }

    function PageRedirect(Func) {
        props.history.push({ pathname: "/PackageImageList", state: { message: Func } });
    }

    function SavePackageImage() {
        setButtonLoader(true);
        let isvalid = true;
        if (Values[0].PackingImageName === '') {
            isvalid = false;
            setSubmitted(true);
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Please Atleast one Packing Image details');
        }
        else {
            for (var j = 0; j < (Values.length); j++) {
                if (Values[j].PackingImageName === '') {
                    isvalid = false;
                    setSubmitted(true);
                    setButtonLoader(false);
                    Nodify('Warning!', 'warning', 'Please fill the mandantory(*) fields');
                    return false;
                }
            }
        }
        if (isvalid) {
            let Packageimageinformation = []
            let inputmaster = { Packageimageinformation: Values, CreatedBy: currentUser.employeeinformationID }
            Packageimage.InsertUpdatePackageImageGrd(inputmaster).then((res) => {
                var page = "Remove";
                SearchRetain(page);

                let Func = 'Add';
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    PageRedirect(Func);
                }
                else if (res.data.outputResult === "0") {
                    setButtonLoader(false);
                    Nodify('Warning!', 'warning', "Error Occured!");
                }
            })
        }
    }

    return (
        <Fragment>
            <div className="page-body">
                <ReactNotification />
                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <div className="widget flat radius-bordered">
                            <div className="widget-header heading_box_style">
                                <h3 className="heading_style_common">Add Package Image Name</h3>
                                <div className="buttons-preview" align="right">
                                    <span className="btn btn-primary"
                                        onClick={
                                            () => ResetOperation("Back")
                                        }>
                                        <i className="fa fa-arrow-circle-left"></i>
                                        &nbsp;Back</span>
                                    <span className="btn btn-danger"
                                        onClick={
                                            () => ResetOperation()
                                        }>
                                        <i className="fa fa-times"></i>
                                        &nbsp;Reset</span>
                                    <button type="button" className="btn btn-success" disabled={buttonLoader}
                                        onClick={SavePackageImage}>

                                        <i className="fa fa-check right"></i>
                                        &nbsp;Save</button>
                                </div>
                            </div>
                            <div className="widget-body">
                                <div id="registration-form">
                                    <form>
                                        <div >
                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: "17%" }}>Package Image Name<span className="text-danger">*</span></th>
                                                        <th style={{ width: "17%" }}>Is Style SKU Image</th>
                                                        <th style={{ width: "10%" }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        inputfeild.map((InputValue, index) => (
                                                            <Fragment >
                                                                <tr>
                                                                    <td>
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            id={"PackingImageName"}
                                                                            name="PackingImageName"
                                                                            placeholder="Enter Packing Image Name"
                                                                            maxLength="50"
                                                                            autoComplete="off"
                                                                            value={InputValue.PackingImageName}
                                                                            onChange={event => handleInputChange(index, event, "Packingimage")}
                                                                            onBlur={() => CheckDuplicate(index)}
                                                                            style={{ border: submitted && InputValue.PackingImageName === "" ? '1px solid red' : '' }}
                                                                            autoFocus
                                                                        />
                                                                    </td>

                                                                    <td>
                                                                        {/* <Reactselect className="basic-single" name="Brand" id={"Brand"}
                                                                            isDisabled={false}
                                                                            isLoading={false}
                                                                            isSearchable={true}
                                                                            isClearable={true}
                                                                            autocomplete='off'
                                                                            onBlur={() => CheckDuplicate(index)}
                                                                            placeholder="Select  Is Style skuImage"
                                                                            styles={submitted && InputValue.IsSkuimageID === 0 ? styles : ''}
                                                                            onChange={event => handleInputChange(index, event, "Isstylesku")}
                                                                            options={styleskuimage}
                                                                            value={styleskuimage.filter(function (option) {
                                                                                return option.value === InputValue.IsSkuimageID;
                                                                            })}
                                                                        /> */}
                                                                        <label style={{ marginTop: '8px' }}>
                                                                            <input type="checkbox" name="IsSelected"
                                                                                onChange={event => handleInputChange(index, event, "Isstylesku")}
                                                                                value={InputValue.IsSkuimageID === 1 ? true : false}
                                                                                checked={InputValue.IsSkuimageID === 1 ? true : false}
                                                                                className="clearOpacity textdesign" />
                                                                            <span className="text"> </span>
                                                                        </label>
                                                                    </td>
                                                                    <td>
                                                                        <button type="button" className="btn btn-xs btn-success" title="Add packing Image"
                                                                            onClick={() => handleAddFields(index)}
                                                                        >
                                                                            <i className="fa fa-plus"></i>
                                                                        </button>
                                                                        &nbsp;
                                                                        {inputfeild.length !== 1 && (<button type="button"
                                                                            className="btn btn-xs btn-danger" title="Delete packing Image"
                                                                            onClick={() => handleRemoveFields(index)}
                                                                        >
                                                                            <i className="fa fa-trash-o"></i>
                                                                        </button>
                                                                        )}
                                                                    </td>
                                                                </tr>

                                                            </Fragment>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>
                            <span className="btn btn-primary"
                                onClick={
                                    () => ResetOperation("Back")
                                }>
                                <i className="fa fa-arrow-circle-left"></i>
                                &nbsp;Back</span>
                            <span className="btn btn-danger"
                                onClick={
                                    () => ResetOperation()
                                }>
                                <i className="fa fa-times"></i>
                                &nbsp;Reset</span>
                            <button type="button" className="btn btn-success" disabled={buttonLoader}
                                onClick={SavePackageImage}>

                                <i className="fa fa-check right"></i>
                                &nbsp;Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default AddPackageimage