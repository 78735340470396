import React, { useState, useEffect, Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Lightbox from "react-image-lightbox";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import Nodify from "../../../Common/ReactNotification"
import SweetAlertPopup from '../../../Common/SweetAlertPopup';
import { loadSupplierList } from "../../../../actions/tna";
import { loadTaskHolderList } from "../../../../actions/task";
import { GetOrderLapdipSubmissionApprovalList, clearLapdipSubmissionSubRow, GetOrderLapdipApprovalList, ReloadOrderLapdipApprovalList } from "../../../../actions/orderLabdip";
import { loadRouteDependencyList } from "../../../../actions/route";
import taskService from "../../../../services/Master/TaskService";
import TnaService from "../../../../services/TNA/TNAService";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Reactselect from 'react-select';
import { DateRangePicker } from 'react-date-range';
import moment from "moment";
import { Modal, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import LabdibSubmission from "../../../TNA/LabdibSubmission_Order";

function LabDipViewRequestTab(props) {
    //declaration
   
    const dispatch = useDispatch();
    const [loader, showLoader, hideLoader] = useFullPageLoader();

    //reducer
    const { user: currentUser } = useSelector((state) => state.auth);
    const labdipSubmissionList = useSelector((state) => state.orderLabdip.orderLabdipSubmissionList);
    const labdipSubmissionParams = useSelector((state) => state.orderLabdip.generalLabdipViewDetailParams);
    let isLoadingOrderLabdip = useSelector((state) => state.orderLabdip.isLoadingOrderLabdip);
    const getSupplierList = useSelector((state) => state.tna.drpSupplierList);
    let GLDRequestStatusList = useSelector((state) => state.orderLabdip.OLDRequestStatusList);
    const StaticTask = useSelector((state) => state.tna.StaticTask);
    // const drpRouteTaskOwnerList = useSelector((state) => state.route.drpRouteTaskOwnerList);

    //State
    const [isOpen, setIsOpen] = useState(false);
    const [currentExpand, setcurrentExpand] = useState({ currindex: "", currpantoneIdOrSwatchId: "" });
    const [MainFields, setMainFields] = useState({
        OrderLabdipTNAId: 0,
        OrderLabdipTNAProgramDetailId: 0,
        GenLabdipTNASubmissionAndApproval: [],
        OrdTNAAdditionalTaskDetails: [],
        CreatedBy: currentUser.employeeinformationID,
    });
    const MainValues = { ...MainFields };

    const [getLeaveDaysList, setLeaveDaysList] = useState([]);
    const [getStyleNameSearch, setgetStyleNameSearch] = useState("");
    const [getStyleNoSearch, setgetStyleNoSearch] = useState("");
    const [getIdPoSearch, setgetIdPoSearch] = useState("");

    const [getSearchReqValues, setSearchReqValues] = useState({
        Status: 0,
        SupplierId: 0,
        SearchTxt: "",
        StyleName: "",
        StyleNo: "",
        IdPoNo: "",
        stateDate: [
            {
                startDate: null,
                endDate: new Date(""),
                key: 'selection',
                isShow: false
            }
        ]

    });

    const SearchReqValues = { ...getSearchReqValues };

    const [Searchvalues, setSearchvalues] = useState({
        SupplierId: 0, SubmitStatus: 0
    });

    const [showmaunalclose, setshowmaunalclose] = useState(false);

    const [selectall, setselectall] = useState(false);
    const [MultiCloseDatas, setMultiCloseDatas] = useState([]);
    const [Manualclosedata, setManualclosedata] = useState({ ManualCloseComment: '', ManualCloseDate: '' });
    const [getComment, setComment] = useState(false);

    const [TNAId, setTNAId] = useState();
    const [LabdibFabricInfoId, setLabdibFabricInfoId] = useState();
    const [StyleId, setStyleId] = useState();
    const [TaskStatus, setTaskStatus] = useState();
    const [getLapmodal, setLapmodal] = useState(false);
    const [SwatchStatus, setSwatchStatus] = useState();

    // If check production or sampling
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProduction = activeMenus.IsProduction;
    // End

    let BuyerId = 0;
    let BrandId = 0;
    let SeasonId = 0;
    //get props params
    if (props.props.location.state !== undefined) {

        BuyerId = props.props.location.state.params.buyerId === undefined ? 0 : props.props.location.state.params.buyerId;
        BrandId = props.props.location.state.params.brandId === undefined ? 0 : props.props.location.state.params.brandId;
        SeasonId = props.props.location.state.params.seasonId === undefined ? 0 : props.props.location.state.params.seasonId;

    }

    useEffect(() => {
        if (!isLoadingOrderLabdip) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingOrderLabdip])
    useEffect(() => {
        dispatch(loadSupplierList());
        dispatch(loadTaskHolderList());
        dispatch(loadRouteDependencyList());
        taskService.getLoadLeaveDaysList().then((response) => {
            if (response.data) {
                setLeaveDaysList(response.data);
            }
        });
        searchLabDip();
    }, [])

    //#region  ------------------  search details --------------------------------
    const searchLabDip = () => {
        SearchReqValues.stateDate[0].isShow = false;
        setSearchReqValues(SearchReqValues);

        let StartDate = null;
        let EndDate = null;
        if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
        }
        if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
        }

        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, PantoneId: 0, Index: currentExpand.currindex,
            SearchText: SearchReqValues.SearchTxt, Operation: 2, Status: SearchReqValues.Status ? SearchReqValues.Status : null,
            SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
            StyleName: SearchReqValues.StyleName, StyleNo: SearchReqValues.StyleNo, IdPoNo: SearchReqValues.IdPoNo
            , IsProduction: IsProduction
        };
        dispatch(GetOrderLapdipSubmissionApprovalList(params));

        // let params = {
        //     BuyerId: labdipViewDetailParams.BuyerId, BrandId: labdipViewDetailParams.BrandId, SeasonId: labdipViewDetailParams.SeasonId,
        //     BuyerName: labdipViewDetailParams.BuyerName, BrandName: labdipViewDetailParams.BrandName, SeasonName: labdipViewDetailParams.SeasonName,
        //     PantoneId: 0, Index: 0, SearchText: SearchReqValues.SearchTxt, Status: SearchReqValues.Status ? SearchReqValues.Status : null, StartDate: StartDate, EndDate: EndDate
        // };
        // dispatch(getGeneralLapdipDetailsViewList(params));
        commonParentExpand();
    }
    const resetLabDip = () => {
       
        SearchReqValues.stateDate[0].startDate = null;
        SearchReqValues.stateDate[0].endDate = new Date("");
        SearchReqValues.stateDate[0].key = 'selection';
        SearchReqValues.stateDate[0].isShow = false;

        SearchReqValues.Status = 0;
        SearchReqValues.SupplierId = 0;
        SearchReqValues.SearchTxt = "";
        SearchReqValues.StyleName = "";
        SearchReqValues.StyleNo = "";
        SearchReqValues.IdPoNo = "";
        setSearchReqValues(SearchReqValues);
        searchLabDip();

    }

    const clearDateSelect = () => {
        SearchReqValues.stateDate[0].startDate = null;
        SearchReqValues.stateDate[0].endDate = new Date("");
        SearchReqValues.stateDate[0].key = 'selection';
        SearchReqValues.stateDate[0].isShow = false;

        setSearchReqValues(SearchReqValues);
    }
    const handleDateSelect = (event, isShow) => {
        SearchReqValues.stateDate[0].isShow = isShow;
        setSearchReqValues(SearchReqValues);
    }

    const handlePopupDateselection = (item) => {

        SearchReqValues.stateDate = [item.selection];
        setSearchReqValues(SearchReqValues);
    }

    const handleSearchChange = (e, FieldName) => {
       
        let inputText = '';
        if (FieldName === 'SearchTxt' || FieldName === 'StyleName' || FieldName === 'StyleNo' || FieldName === 'IdPoNo') {
            if (e && e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
        } else if (FieldName === 'Status' || FieldName === 'SupplierId') {
            if (e) {
                inputText = e.value
            }
        }

        if (FieldName === 'Status') {
            SearchReqValues.Status = inputText;
        }
        else if (FieldName === 'SupplierId') {
            SearchReqValues.SupplierId = inputText;
        } else if (FieldName === 'SearchTxt') {
            SearchReqValues.SearchTxt = inputText;
        } else if (FieldName === 'StyleName') {
            SearchReqValues.StyleName = inputText;
        }
        else if (FieldName === 'StyleNo') {
            SearchReqValues.StyleNo = inputText;
        }
        else if (FieldName === 'IdPoNo') {
            SearchReqValues.IdPoNo = inputText;
        }
        setSearchReqValues(SearchReqValues);

    }


    //#end  ------------------  search details end --------------------------------

    // #region  ------------------  Expanded Toggle --------------------------------

    const handleToggle = (index, pantoneIdOrSwatchId) => {
        let btnToggle = document.getElementById("btn-toggle1_" + index);
        let iconToggle = document.getElementById("icon-toggle1_" + index);
        let ariaExpanded = btnToggle.getAttribute('aria-expanded');
        if (ariaExpanded === 'false') {
            btnToggle.setAttribute('aria-expanded', 'true');
            iconToggle.className = iconToggle.className.replace("fa-plus-square-o", "fa-minus-square-o");
            let StartDate = null;
            let EndDate = null;
            if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
                StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
            }
            if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
                EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
            }
            let params = {
                BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, PantoneId: pantoneIdOrSwatchId, Index: index,
                SearchText: SearchReqValues.SearchTxt, Operation: 2, Status: SearchReqValues.Status ? SearchReqValues.Status : null, SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
                StyleName: SearchReqValues.StyleName, StyleNo: SearchReqValues.StyleNo, IdPoNo: SearchReqValues.IdPoNo, IsProduction: IsProduction
            };
            setcurrentExpand({ currindex: index, currpantoneIdOrSwatchId: pantoneIdOrSwatchId })
            dispatch(GetOrderLapdipSubmissionApprovalList(params));
        }
        else {

            btnToggle.setAttribute('aria-expanded', 'false');
            iconToggle.className = iconToggle.className.replace("fa-minus-square-o", "fa-plus-square-o");
            if (pantoneIdOrSwatchId) {
                dispatch(clearLapdipSubmissionSubRow(index));

            }
        }
    };
    const commonParentExpand = () => {
        let rowHiddens = document.querySelectorAll(".parentExpandRequestTNA");
        for (let row of rowHiddens) {
            row.setAttribute('aria-expanded', 'false');
        }
        let rowIconHiddens = document.querySelectorAll(".clscollapseexpandRequestTNA");
        for (let rowIcon of rowIconHiddens) {
            rowIcon.className = rowIcon.className.replace("fa-minus-square-o", "fa-plus-square-o");
        }
    }

    const [stateDate, setStateDate] = useState([
        {
            startDate: null,
            endDate: null,
            key: 'selection',
            isShow: false
        }
    ]);

    function convertUTCDateToLocalDate(date) {
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date;
    }

    const handleserach = () => {

    }

    //action releated functionalities
    //manual close

    const handleChildManualclose = (TaskName, TaskType, TaskID, ChildId, Status, ManualCloseComment, buyerid, brandid, seasonid, supplierid, index, subIndex) => {
       
        showLoader();
        setMultiCloseDatas([]);
        var manualclosedata = { ...Manualclosedata };
        manualclosedata.ManualCloseComment = "";
        manualclosedata.ManualCloseDate = ''
        setManualclosedata(manualclosedata)
        let Operation;
        if (TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
            Operation = 1;
        }
        else if (TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT) {
            Operation = 3;
        }
        else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
            Operation = 2;
        }
        else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL) {
            Operation = 4;
        }
        else if (TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
            Operation = 5;
        }

        else if (TaskName.toLowerCase() === StaticTask.SMS_APPROVAL) {
            Operation = 6;
        }
        else {
            Operation = 7;

        }


        if (Operation !== 7) { // check submission exists for approvals
            TnaService.GetTNAManualCloseSubmissionList(ChildId, TaskName).then((response) => {
                if (response.data.length !== 0) {

                    setshowmaunalclose(true);

                    //get manual close
                    let Operation;
                    if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
                        Operation = 1;
                    }
                    else if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                        || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                        Operation = 3;
                    }
                    else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
                        || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER
                        || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER) {
                        Operation = 2;
                    }
                    else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL) {
                        Operation = 4;
                    }
                    else if (TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
                        Operation = 5;
                    }
                    //|| TaskName.toLowerCase() === StaticTask.SMS_PURCHASE_ORDER
                    else if (TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || TaskName.toLowerCase() === StaticTask.SMS_APPROVAL
                        || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER
                        || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE || TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW) {
                        Operation = 6;
                    }
                    else if (TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH) {
                        Operation = 7;
                    }
                    else {
                        Operation = 8;
                    }


                    let params = {
                        BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId,
                        SupplierId: SearchReqValues.SupplierId, TaskType: TaskType, TaskNameId: TaskID,
                        TaskTodoInProgressCompletedStatus: 9, StartDate: null, EndDate: null,
                        Operation: Operation, ParentIdOrSubChildId: ChildId, IsProduction: IsProduction
                    };

                    TnaService.GetSubChildMultiTaskManualCloseList(params).then((response) => {
                        if (response.data) {
                            let data = response.data.map((item) => {

                                if (item.subTaskInfoId === ChildId) {
                                    item.isSelected = 1;

                                    return item;
                                } else {
                                    return item;
                                }
                            });
                            let isSelectAll = 0;
                            if (data) {
                                isSelectAll = !!!data.find(d => d.isSelected === 0);
                                isSelectAll = isSelectAll ? 1 : 0;
                            }
                            setselectall(isSelectAll);
                            if (Status !== 3) {
                                setMultiCloseDatas(data);
                            }
                            else {
                                setMultiCloseDatas(data.filter(x => x.subTaskInfoId === ChildId));
                            }

                        }


                    }).catch(() => { });
                }
                else {
                    Nodify('Warning!', 'warning', "Manual Submission not yet added for this task");
                    return false;
                }

            }).catch(() => { });
        }
        else {
            setshowmaunalclose(true);

            //get manual close
            let Operation;
            if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
                Operation = 1;
            }
            else if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
                || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                Operation = 3;
            }
            else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
                || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER
                || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER) {
                Operation = 2;
            }
            else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL) {
                Operation = 4;
            }
            else if (TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
                Operation = 5;
            }
            //|| TaskName.toLowerCase() === StaticTask.SMS_PURCHASE_ORDER
            else if (TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || TaskName.toLowerCase() === StaticTask.SMS_APPROVAL
                || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER
                || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE || TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW) {
                Operation = 6;
            }
            else if (TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH) {
                Operation = 7;
            }
            else {
                Operation = 8;
            }


            let params = {
                BuyerId: buyerid, BrandId: brandid, SeasonId: seasonid,
                SupplierId: supplierid, TaskType: TaskType, TaskNameId: TaskID,
                TaskTodoInProgressCompletedStatus: 9, StartDate: null, EndDate: null,
                Operation: Operation, ParentIdOrSubChildId: ChildId, IsProduction: IsProduction
            };

            TnaService.GetSubChildMultiTaskManualCloseList(params).then((response) => {
                if (response.data) {
                    let data = response.data.map((item) => {

                        if (item.subTaskInfoId === ChildId) {
                            item.isSelected = 1;

                            return item;
                        } else {
                            return item;
                        }
                    });
                    let isSelectAll = 0;
                    if (data) {
                        isSelectAll = !!!data.find(d => d.isSelected === 0);
                        isSelectAll = isSelectAll ? 1 : 0;
                    }
                    setselectall(isSelectAll);
                    if (Status !== 3) {
                        setMultiCloseDatas(data);
                    }
                    else {
                        setMultiCloseDatas(data.filter(x => x.subTaskInfoId === ChildId));
                    }
                    hideLoader();
                }


            }).catch(() => { hideLoader(); });
        }
        //hideLoader();
    };

    const handleChangeIsManualCloseComments = (event, feild) => {
        var manualclosedata = { ...Manualclosedata };
        let input = '';

        setComment(false);
        if (feild === "Comment") {
            if (event.target.value.trim() !== '') {
                input = event.target.value;
            }
            manualclosedata.ManualCloseComment = input;
        }
        else {
            manualclosedata.ManualCloseDate = event;
            // ManualCloseData.ManualCloseDate = event;
        }
        setManualclosedata(manualclosedata);
    }
    const CustomInputman = (props) => {

        return (
            <input
                className="form-control DatePickerCalenderHoliday min_width_Date"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                menuPosition="fixed"
                placeholder="DD/MM/YYYY"
                style={{ border: getComment && props.value === '' ? '1px solid red' : '' }}
            />
        )
    }

    const SelectAll = (event) => {
        let IsSelected = 0;

        if (event.target.checked === true) {
            IsSelected = 1;
            MultiCloseDatas.forEach(element => {
                element.isSelected = 1;
            })
        }
        else {
            IsSelected = 0;
            MultiCloseDatas.forEach(element => {
                element.isSelected = 0;
            })
        }
        setselectall(IsSelected);
    }

    const OnSelectRecords = (event, index) => {
        const value = [...MultiCloseDatas];

        if (event.target.checked === true) {
            value[index].isSelected = 1;
        }
        else {
            value[index].isSelected = 0;

        }

        let isSelectAll = 0;
        if (value) {
            isSelectAll = !!!value.find(d => d.isSelected === 0);
            isSelectAll = isSelectAll ? 1 : 0;
        }
        setselectall(isSelectAll);

        setMultiCloseDatas(value);
    }
    const SaveChildManulaCloseComments = () => {



        if (Manualclosedata.ManualCloseComment === '' || Manualclosedata.ManualCloseComment === undefined) {
            setComment(true);
            Nodify('Warning!', 'warning', "Please enter comments");
        }
        else if (Manualclosedata.ManualCloseDate === '') {
            setComment(true);
            Nodify('Warning!', 'warning', "Please enter Close Date");
        }
        else {
            // values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
            // values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StatusID = 3;
            // values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseDate = new Date();
            // values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildActualEndDate = new Date();
            // setInputFields(values);


            let ChildStatus = 1;


            let strValue = "";
            let selected = 0;
            for (let data of MultiCloseDatas) {
                if (strValue === "") {
                    if (data.isSelected === 1) {
                        strValue = data.subTaskInfoId.toString();
                        selected = selected + 1;
                    }
                } else {
                    if (data.isSelected === 1) {
                        strValue = strValue + "," + data.subTaskInfoId;
                    }
                }
            }
            if (selected === 0) {
                Nodify('Warning!', 'warning', "Select atleast one Task");
            }
            else {
                var ManualCloseData = { ...Manualclosedata };
                ManualCloseData.ManualCloseDate = new Date(Manualclosedata.ManualCloseDate);
                ManualCloseData.ManualCloseDate.setMinutes(ManualCloseData.ManualCloseDate.getMinutes() + 370);
                // ManualCloseData.ActualEndDate = new Date(values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseDate);
                // ManualCloseData.ActualEndDate.setMinutes(ManualCloseData.ManualCloseDate.getMinutes() + 370);
                // .setDate(values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseDate.getDate() + 1);
                ManualCloseData.ManualCloseComment = Manualclosedata.ManualCloseComment;
                ManualCloseData.IdList = strValue;
                ManualCloseData.TaskStatus = ChildStatus;
                ManualCloseData.CreatedBy = currentUser.employeeinformationID;
                TnaService.UpdateTaskManualCloseDetails(ManualCloseData).then(res => {
                    if (res.data.outputResult === "2") {
                        Nodify('Success!', 'success', 'Task Manually Closed Successfully');
                        setshowmaunalclose(false);
                        ReloadSubmission();
                        searchLabDip();
                        // handleToggle(currentExpand.currindex, currentExpand.currpantoneIdOrSwatchId);

                    }
                    else if (res.data.outputResult === "0") {
                        Nodify("Error!", "Error Occured!");
                    }
                });
            }

        }
    }

    //submission form

    const OpenLabdipSubmssionpopup = (TNAId, LabdibFabricInfoId, StyleId, StatusId, SwatchStatus, subField) => {
       ;
        setTNAId(TNAId);
        setLabdibFabricInfoId(LabdibFabricInfoId);
        setStyleId(StyleId);
        setTaskStatus(StatusId);
        // setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex });
        // setCommonSubmitApprovalParams({ SelectedDependency: SelectedDependency, TaskID: TaskID, IsSingleTask: IsSingleTask, hasAllDependencyStart: hasAllDependencyStart });
        setLapmodal(true);
        setSwatchStatus(SwatchStatus)
        //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;

    }

    const handleLabDip = () => {
        setLapmodal(false);
        // seteditLabdipSubmission(false);
        // setdeleteLabdipSubmission(false);
        // setLabdipswatchDownload(false);
        // setLabdipswatchUpload(false);
    };

    const LapdipSubmissionCallback = (value, getOrderinfo, Operation, SwatchStatus) => {
        setLapmodal(false);
        if (value === true) {

            ReloadSubmission();
            searchLabDip();
            //  handleToggle(currentExpand.currindex, currentExpand.currpantoneIdOrSwatchId);

        }
    }
    const handleCloseManualPopup = () => {
       
        var manualclosedata = { ...Manualclosedata };
        manualclosedata.ManualCloseComment = '';
        manualclosedata.ManualCloseDate = '';
        setManualclosedata(manualclosedata)
        setshowmaunalclose(false)
    }

    const ReloadSubmission = () => {
        let StartDate = null;
        let EndDate = null;
        if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
        }
        if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
        }
        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, PantoneId: 0, Index: '',
            // SearchText: (getSearch && getSearch.trim()), Operation: 2
            SearchText: SearchReqValues.SearchTxt, Operation: 2,
            StatusId: SearchReqValues.Status ? SearchReqValues.Status : null,
            SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate, IsProduction: IsProduction
        };

        let approvalparams = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, PantoneId: 0, Index: "",
            // SearchText: (getSearch && getSearch.trim()), Operation: 2
            SearchText: SearchReqValues.SearchTxt, Operation: 3,
            StatusId: SearchReqValues.Status ? SearchReqValues.Status : null,
            SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate, IsProduction: IsProduction
        };
        dispatch(ReloadOrderLapdipApprovalList(approvalparams));
        dispatch(GetOrderLapdipSubmissionApprovalList(params));


    }

    return (
        <Fragment>
            <div> {
                isOpen && (
                    <Lightbox
                        mainSrc={isOpen}
                        alt="bg image"

                        onCloseRequest={
                            () => setIsOpen(false)
                        } />
                )
            } </div>
            <div className="widget-body">
                <div id="registration-form">
                    <form >
                    <div className="col-sm-12">
                                <div className='col-md-2 min-height'>
                                    <div className='form-group'>
                                        <label>Pantone Code / Swatch Name </label>
                                        <span className='input-icon icon-right'>
                                            <input type="text" value={SearchReqValues.SearchTxt} onChange={event => handleSearchChange(event, "SearchTxt")} placeholder="Pantone Code / Swatch Name" className="form-control" />
                                        </span>
                                    </div>

                                </div>

                                <div className="col-md-2 min-height">
                                    <label htmlFor="Status">
                                        Style Name
                                    </label>
                                    <span className="input-icon icon-right">
                                        <input
                                            type="text"
                                            className="form-control StyleList"
                                            title={'Style Name'}
                                            placeholder={'Style Name'}
                                            value={SearchReqValues.StyleName}
                                            onChange={e => handleSearchChange(e, 'StyleName')}
                                        />
                                    </span>
                                </div>

                                <div className="col-md-2 min-height">
                                    <label htmlFor="Status">
                                        Style No
                                    </label>
                                    <span className="input-icon icon-right">
                                        <input
                                            type="text"
                                            className="form-control StyleList"
                                            title={'Style No'}
                                            placeholder={'Style No'}
                                            value={SearchReqValues.StyleNo}
                                            onChange={e => handleSearchChange(e, 'StyleNo')}
                                        />
                                    </span>
                                </div>





                                <div className="col-md-2 min-height">
                                    <label htmlFor="Status">
                                           ID/PO NO
                                    </label>
                                    <span className="input-icon icon-right">
                                        <input
                                            type="text"
                                            className="form-control StyleList"
                                            title={'PO/ID No'}
                                            placeholder={'ID/PO NO'}
                                            value={SearchReqValues.IdPoNo}
                                            onChange={e => handleSearchChange(e, 'IdPoNo')}
                                        />
                                    </span>

                                </div>
                          

                            

                                <div className="col-md-2 min-height">
                                    <div className="form-group">
                                        <label htmlFor="SupplierID">
                                            Supplier
                                            {/* <span className="text-danger">*</span> */}
                                        </label>
                                        <span className="input-icon icon-right">
                                            <Reactselect className="basic-single" name="SupplierId"
                                                id={
                                                    "SupplierId"
                                                }
                                                isLoading={false}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={event => handleSearchChange(event, 'SupplierId')}
                                                value={getSupplierList.filter(function (option) {
                                                    return option.value === SearchReqValues.SupplierId;

                                                })}
                                                options={getSupplierList}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                                            ></Reactselect>

                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-2 min-height">
                                    <div className="form-group">
                                        <label htmlFor="Status">
                                            Submit Status
                                        </label>
                                        <span className="input-icon icon-right">
                                            <Reactselect className="basic-single" name="Status"
                                                id={
                                                    "Status"
                                                }
                                                isLoading={false}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={event => handleSearchChange(event, 'Status')}
                                                value={GLDRequestStatusList.filter(function (option) {
                                                    return option.value === SearchReqValues.Status;
                                                })}
                                                options={GLDRequestStatusList}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                                            ></Reactselect>
                                        </span>
                                    </div>
                                </div>
                    
                                {/* <div className="row"> */}
                           
                                <div className="col-md-2 min-height">

                                    <div className="form-group">
                                        <label >Date Range <span className="rptDateRangeSpan" title="Clicking on the text box cause datepicker to appear and disappear">(Clicking on the text box...)</span> </label>
                                        <span className="input-icon icon-right">
                                            <input
                                                value={(SearchReqValues.stateDate[0].startDate === null ? '' : moment(SearchReqValues.stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') + ((SearchReqValues.stateDate[0].endDate === null || !moment(SearchReqValues.stateDate[0].endDate).isValid()) ? '' : moment(SearchReqValues.stateDate[0].endDate).format('DD/MM/YYYY'))}
                                                onClick={event => handleDateSelect(event, !SearchReqValues.stateDate[0].isShow)}
                                                //onBlur={event => handleDateBlur()}
                                                type="text"
                                                className={'form-control'}
                                                placeholder="Select Date"
                                            />
                                            <i className="fa fa-times" onClick={clearDateSelect} ></i>
                                        </span>
                                    </div>


                                    {SearchReqValues.stateDate[0].isShow &&
                                        <DateRangePicker
                                            //onChange={item => setStateDate([item.selection])}
                                            onChange={item => handlePopupDateselection(item)}
                                            showSelectionPreview={true}
                                            moveRangeOnFirstSelection={false}
                                            months={2}
                                            ranges={SearchReqValues.stateDate}
                                            showDateDisplay={false}
                                            direction="vertical"
                                            className={'TNAReportDatePicker'}

                                        />

                                    }

                                </div>
                                <div className="col-md-2 min-height" style={{ paddingTop: '29px' }}>

                                    <button type="button" className="btn btn-success" title="Search" onClick={event => searchLabDip()}
                                    >
                                        <i className="fa fa-search"></i>
                                    </button>
                                    &nbsp;&nbsp;
                                    <button type="button" className="btn btn-danger" title="Reset" onClick={resetLabDip}
                                    >
                                        <i className="fa fa-close"></i>
                                    </button>
                                </div>
                                {/* </div> */}
                            </div>
                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                            <thead>
                                <tr>
                                    <th className="fixed-column-header-first-tna" style={{ width: "35px" }}> </th>
                                    <th className="fixed-column-header-first-tna" style={{ width: "35px" }}> #</th>
                                   <th>Request Date</th>
                                    <th>Style Name</th>
                                    <th>Style No</th>
                                    <th>ID/PO NO</th>
                                    <th>Supplier</th>
                                    <th>Item Details (Fabric / Yarn)</th>
                                    <th>Task Holder / Follower</th>
                                    <th>Submit Target</th>
                                    <th>Submit Status</th>
                                    <th>AWB & Date</th>
                                    <th className="text-center fixed-column-header-Route">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    labdipSubmissionList && labdipSubmissionList.length > 0 ?
                                        <>
                                            {
                                                labdipSubmissionList && labdipSubmissionList.map((inputField, index) => (
                                                    <Fragment key={`${inputField}~${index}`}>
                                                        <tr class="parent" data-child={"parent_" + index}>
                                                            <td className="fixed-column-first-tna">

                                                                {
                                                                    <span id={"btn-toggle1_" + index} class="btn-toggle1 parentExpandRequestTNA" aria-expanded="false" onClick={() => handleToggle(index, inputField.pantoneIdOrSwatchId)}
                                                                        aria-labelledby="btn-toggle1 id-master">
                                                                        <i id={"icon-toggle1_" + index} class="fa fa-plus-square-o clsPointer clscollapseexpandRequestTNA"></i>
                                                                    </span>
                                                                }

                                                            </td>
                                                            <td>{index+1}</td>
                                                            <td>
                                                                <span className='input-icon icon-right'>
                                                                    {
                                                                        inputField.pantoneCode
                                                                    }
                                                                </span>
                                                            </td>
                                                            <td colSpan="10">
                                                                {inputField.imagePath ?
                                                                    <div className="trwidth_manual" style={{ textAlign: "center" }}>
                                                                        <img className="width_manual"
                                                                            src={inputField.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + inputField.imagePath}
                                                                            alt="Logo"
                                                                            onClick={
                                                                                () => setIsOpen(inputField.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + inputField.imagePath)
                                                                            }
                                                                        />
                                                                    </div> :
                                                                    <div style={{ background: inputField.pantoneColor, width: '120px', height: '25px', marginLeft: '20px', borderRadius: '2px', display: 'inline-block' }}> </div>
                                                                }
                                                            </td>

                                                        </tr>
                                                        {
                                                            inputField.pantoneSubList && inputField.pantoneSubList.map((subField, subIndex) => (

                                                                <Fragment key={`${subIndex}~${subIndex}`}>
                                                                    <tr class={"child_" + index + " parentChild_" + index + "_" + subIndex} >
                                                                        <td></td>
                                                                        <td className="fixed-column-first-tna" style={{paddingLeft:"17px"}}>
                                                                            {subIndex + 1}
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                <span> {moment(subField.requestedDate).format('DD/MM/YYYY')} </span>
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                <span> {subField.styleName} </span>
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                <span> {subField.styleNo} </span>
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                <span> {subField.idpono} </span>
                                                                            }
                                                                        </td>

                                                                        <td>
                                                                            {
                                                                                <span> {subField.supplierName} </span>
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                <span> {subField.itemDetails} </span>
                                                                            }
                                                                        </td>
                                                                        <td className="cut_text" title={subField.taskHolderFollowerNames}>
                                                                            {
                                                                                <span> {subField.taskHolderFollowerNames} </span>
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                <span> {moment(subField.submitTarget).format('DD/MM/YYYY')} </span>
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                <span> {subField.submitStatus} </span>
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                <span> {subField.awbName} - {(subField.awbDate === null || subField.awbDate === '') ? '' : moment(subField.awbDate).format('DD/MM/YYYY')} </span>
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {(subField.isShowSubmitButton === 1 || subField.isShowSubmitButton === 2) && subField.statusID !== 3 ?
                                                                                <Fragment>&nbsp;<button type="button" className={subField.manualCloseComment === '' || subField.manualCloseComment === null || subField.manualCloseComment === undefined ? "btn btn-xs btn-darkorange" : "btn btn-xs btn-success"} title="Manual Close"
                                                                                    onClick={() => handleChildManualclose(StaticTask.LAB_DIP_SUBMISSION, subField.taskType, subField.taskID, subField.childId, subField.statusID, subField.manualCloseComment, index, subIndex)}
                                                                                >
                                                                                    <i className="fa fa-user"></i>&nbsp;
                                                                                    <i className="fa fa-close"></i>
                                                                                </button>
                                                                                </Fragment>
                                                                                :
                                                                                <button type="button" className="btn btn-xs btn-success" title='Submitted' disabled>
                                                                                    <i className="fa fa-check"></i>
                                                                                </button>
                                                                            }




                                                                            &nbsp;
                                                                            {

                                                                                // TaskCloseCompleteInfo !== undefined && TaskCloseCompleteInfo.isAdd !== 0 &&

                                                                                subField.taskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION && subField.isShowSubmitButton === 2 && subField.statusID !== 3 ?
                                                                                    <button type="button" className="btn btn-xs btn-warning" title="Submit Form"
                                                                                        onClick={() => OpenLabdipSubmssionpopup(subField.tnaId, subField.childId,
                                                                                            subField.styleID, subField.statusID, subField.swatchStatus, subField
                                                                                        )}
                                                                                    >
                                                                                        <i className="fa fa-send"></i>
                                                                                    </button> :

                                                                                    subField.taskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION && subField.isShowSubmitButton === 0 ?
                                                                                        <button type="button" className="btn btn-xs btn-success" title='Already Approved' disabled>
                                                                                            <i className="fa fa-check"></i>
                                                                                        </button>
                                                                                        : ''

                                                                            }
                                                                        </td>

                                                                    </tr>

                                                                    {
                                                                        inputField.criticalList && inputField.criticalList.map((Crit) => (
                                                                            subField.programIndexName === Crit.programIndexName ?
                                                                                <tr class={"child_" + index + " parentChild_" + index + "_" + subIndex} >
                                                                                    <td colSpan="10">
                                                                                        <span style={{ color: 'red' }}>Critical : {Crit.criticalComment}</span>,&nbsp;
                                                                                        <span style={{ color: '#427fed' }}>Target to Close : {new Date(Crit.targettoClose).toLocaleDateString()}</span>,&nbsp;
                                                                                        <span style={{ color: '#00a65a' }}>Completed On : {Crit.completedOn !== '' ? new Date(Crit.completedOn).toLocaleDateString() : '-'}</span>
                                                                                    </td>

                                                                                </tr>
                                                                                : ''
                                                                        ))
                                                                    }
                                                                </Fragment>
                                                            ))
                                                        }

                                                    </Fragment>
                                                ))
                                            }
                                        </>
                                        :

                                        <tr><td colSpan="12" className='norecordfound'><span>No Records Found</span></td></tr>

                                }
                            </tbody>
                        </table>



                    </form>

                </div>


            </div>

            {getLapmodal === true ? (
                <Modal
                    show={getLapmodal === true}
                    size="xl"
                    dragable
                    backdrop="static"
                    keyboard={false}
                    dialogClassName="commonwidth" 
                    onHide={() => handleLabDip()}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {/* {
                                TaskStatus === 3 && editLabdipSubmission === false ?
                                    "View " : editLabdipSubmission === true ? 'Update ' : ''
                            } */}
                            Lab Dip Submission


                            {/* {
                                TaskStatus === 3 ?
                                    <>
                                        {
                                            (SwatchStatus === 0 || SwatchStatus === 1) ?
                                                <span title='Download Swatch Card' style={{ 'margin-left': '650px' }} onClick={() => setLabdipswatchDownload(true)}>
                                                    <i class="fa fa-lg fa-file-pdf-o" aria-hidden="true"></i>
                                                </span>
                                                : ''

                                        }
                                        &nbsp;

                                        {
                                            SwatchStatus === 1 ?
                                                <span title='Upload Submitted Swatch Card'>
                                                    <i class="fa fa-lg fa-upload" aria-hidden="true" onClick={() => setLabdipswatchUpload(true)}></i>
                                                </span>
                                                : ''

                                        }
                                        &nbsp;
                                        {
                                            SwatchStatus === 2 ?
                                                <span title='Edit Form' style={{ 'margin-left': '680px' }} onClick={() => seteditLabdipSubmission(true)}
                                                    className="btn btn-info btn-xs edit">
                                                    <i className="fa fa-edit"></i>
                                                </span> :
                                                <span title='Edit Form' onClick={() => seteditLabdipSubmission(true)}
                                                    className="btn btn-info btn-xs edit">
                                                    <i className="fa fa-edit"></i>
                                                </span>
                                        }

                                        &nbsp;
                                        <span title='Delete Form'
                                            className="btn btn-danger btn-xs delete" onClick={() => setdeleteLabdipSubmission(true)}>
                                            <i className="fa fa-trash-o"></i>
                                        </span>
                                    </> : ''
                            } */}




                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <LabdibSubmission props={props}
                            TNAId={TNAId}
                            StyleId={StyleId}
                            LabdibFabricInfoId={LabdibFabricInfoId}
                            LapdipSubmissionCallback={LapdipSubmissionCallback}
                            TaskStatus={TaskStatus}
                            /// commonParams={commonSubmitApprovalParams}
                            // Indexes={Indexes}
                            // values={values}
                            // editLabdipSubmission={editLabdipSubmission}
                            // deleteLabdipSubmission={deleteLabdipSubmission}
                            // LabdipswatchDownload={LabdipswatchDownload}
                            // LabdipswatchUpload={LabdipswatchUpload}
                            SwatchStatus={SwatchStatus}
                        > </LabdibSubmission>
                    </Modal.Body>
                    <Modal.Footer></Modal.Footer>
                </Modal>
            ) : (
                ""
            )}


            {
                showmaunalclose === true ?
                    <Modal show={showmaunalclose === true} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseManualPopup()}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Manual Close  : <span style={{textTransform: "capitalize"}}>{StaticTask.LAB_DIP_SUBMISSION}</span> &nbsp;
                                {/* {
                                                                                                    childTask.StatusID === 3 &&

                                                                                                    <button type="button" className="btn btn-xs btn-danger" title="Delete Manual Close" onClick={() => handledelete(index, subIndex, childIndex, childTask.TaskID, childTask.ChildId, childTask.TaskName)}>
                                                                                                        <i className="fa fa-trash-o"></i>
                                                                                                    </button>
                                                                                                } */}

                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                <thead>
                                    <tr>
                                        <th>Comments <span className="text-danger">*</span></th>
                                        <th>Close Date <span className="text-danger">*</span></th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td style={{ width: "600px" }} >
                                            <textarea
                                                rows="3"
                                                placeholder="Enter Comments here..."
                                                autoFocus onFocus={""} autoComplete="off"
                                                maxLength="500" name="EnterRemarks"
                                                id={"EnterRemarks"}
                                                value={Manualclosedata.ManualCloseComment}
                                                onChange={event => handleChangeIsManualCloseComments(event, "Comment")}
                                                style={{ width: "100%", border: getComment && Manualclosedata.ManualCloseComment === '' ? '1px solid red' : '' }}>

                                            </textarea>
                                        </td>
                                        <td>
                                            <DatePicker className="form-control" name="HolidayDate"
                                                id={
                                                    "HolidayDate"
                                                }
                                                selected={
                                                    Manualclosedata.ManualCloseDate
                                                }
                                                onChange={event => handleChangeIsManualCloseComments(event, "CommentDate")}
                                                dateFormat="dd/MM/yyyy"
                                                peekNextMonth
                                                showMonthDropdown
                                                // showYearDropdown
                                                dropdownMode="scroll"
                                                autoComplete="off"
                                                minDate={new Date()}
                                                maxDate={new Date()}
                                                // style={{ border: getComment && childTask.ManualCloseDate === '' ? '1px solid red' : '' }}
                                                customInput={<CustomInputman />}
                                            // isClearable={inputField.HolidayDate === '' ? false : true}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Style Name</th>
                                        <th>Style No</th>
                                        <th>PO/ID No</th>
                                        <th>Details</th>
                                        <th>Task Holder / Follower</th>
                                        <th>
                                            <label style={{ marginTop: '8px' }}>
                                                <input type="checkbox" name="IsSelected"

                                                    onChange={event => (SelectAll(event))}
                                                    checked={selectall === 1 ? true : false}
                                                    className="colored-blue clearOpacity" />
                                                <span className="text"></span>
                                            </label>&nbsp;&nbsp;&nbsp;
                                            {/* {StartStopInfo && StartStopInfo.isAdd !== 0 && inputField.SelectedDependency &&
                                                                                                                getSelectedDependencyMap(inputField.SelectedDependency) === false && childTask.StatusID !== 3 &&

                                                                                                                <button type="button" className={childTask.hasAllDependencyStart === 1 ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Dependency)"
                                                                                                                    onClick={() => OpenAllDependentStartPopup(index, subIndex, childIndex, childTask.hasAllDependencyStart, props.location.state.params.tnaid, inputField.TaskID, childTask.ChildId, inputField.IsSingleTask)}
                                                                                                                >
                                                                                                                    <i className={childTask.hasAllDependencyStart === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>

                                                                                                                </button>
                                                                                                            } */}
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {

                                        MultiCloseDatas.map((item, index) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <td>{item.styleName}</td>
                                                        <td>{item.styleNo}</td>
                                                        <td>{item.idPoNo}</td>
                                                        <td>{item.details}</td>
                                                        <td>{item.taskOwnerNameList}</td>
                                                        <td className="Actionbutton fixed-column">
                                                            <div style={{ width: "80px" }}>
                                                                {/* {


                                                                                                                                    (item.dependencyStartstop === 1 || (!inputField.SelectedDependency) || getSelectedDependencyMap(inputField.SelectedDependency) === true || item.isSelected === 1
                                                                                                                                    ) && */}
                                                                <label style={{ marginTop: '8px' }}>
                                                                    <input type="checkbox" name="IsSelected"
                                                                        onChange={event => (OnSelectRecords(event, index))}
                                                                        checked={item.isSelected === 1 ? true : false}
                                                                        className="colored-blue clearOpacity" />
                                                                    <span className="text"></span>
                                                                </label>
                                                                {/* }&nbsp;&nbsp;&nbsp; */}

                                                                {/* {
                                                                                                                                    StartStopInfo && StartStopInfo.isAdd !== 0 && inputField.SelectedDependency && getSelectedDependencyMap(inputField.SelectedDependency) === false && childTask.StatusID !== 3 &&

                                                                                                                                <button type="button" className={(item.dependencyStartstop === 1 || item.isSelected === 1) ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Dependency)"
                                                                                                                                // onClick={() => OpenDependentStartPopup(index, subIndex, childIndex, childTask.intDependency, props.location.state.params.tnaid, inputField.TaskID, childTask.ChildId, inputField.IsSingleTask)}
                                                                                                                                >
                                                                                                                                    <i className={item.dependencyStartstop === 1 || item.isSelected === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>

                                                                                                                                </button>
                                                                                                                                } */}
                                                            </div>

                                                        </td>
                                                    </tr>
                                                </>

                                            )
                                        })
                                    }

                                </tbody>
                            </table>
                        </Modal.Body>
                        <Modal.Footer>
                            {
                                // subField.StatusID !== 3 &&
                                <Button variant="success" onClick={() => SaveChildManulaCloseComments()}>
                                    Save
                                </Button>


                            }


                        </Modal.Footer>
                    </Modal> : ''
            }
            {loader}
        </Fragment>
    );
}

export default LabDipViewRequestTab;