import axios from "axios";
import authHeader from "../auth-header";

class SeasonService {
  InsertUpdateSeasonGrid = async(Params) => {
        return await axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateSeasonGrid',
            data:Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

     getSeasonList() {
        return axios.get(window.$APIBaseURL + "Master/GetSeasonList?SeasonID=0", {
            headers: {
                authorization: authHeader()
            }
        });
    }
 
     InsertUpdateSeason(Params) {
   return axios({
      method: 'post',
      url: window.$APIBaseURL + 'Master/InsertUpdateDelSeason',
      data: Params,
      headers: {
            authorization: authHeader()
          }
    });
  }
    }
export default new SeasonService()