import React, { useState, useEffect } from "react";
import Nodify from "../../../Common/ReactNotification";
import SearchRetain from "../../../Common/SearchRetain";
import ReactNotification from 'react-notifications-component';
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import { useSelector } from "react-redux";
import TestFieldService from './../../../../services/Master/testfieldservice';
import $ from "jquery";

const TestFieldEdit = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const [getTestFieldInfo, setTestFieldInfo] = useState({});
    const [buttonLoader, setButtonLoader] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [getExisting, setExisting] = useState([]);

    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    if (menulist !== undefined) {
        activeMenu = menulist.filter(i => i.menuName === "TestField");
    }
    //end enable menu

    function BindCommentValue(props) {
        let CommentInfoValue = { Operation: 2, ID: 0, TestField: '', Createdby: currentUser.employeeinformationID };
        if (props.location.state !== undefined) {
            if (props.location.state.params.testItemID !== 0) {
                CommentInfoValue.Operation = 2;
                CommentInfoValue.TestFieldID = props.location.state.params.testFieldID;
                CommentInfoValue.TestField = props.location.state.params.testField;
                CommentInfoValue.Createdby = currentUser.employeeinformationID;
                return CommentInfoValue;
            }
        }
    }

    const handleChange = (e) => {
        let Value = getTestFieldInfo;
        if (e.target.name === "TestField") {
            if (e.target.value.trim() !== '') {
                setSubmitted(true);
            }
            else {
                setSubmitted(false);
            }
            Value[e.target.name] = e.target.value;
        }
        setTestFieldInfo(Value);
    }

    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
    };

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/TestFieldList')
        } else {
            window.location.reload();
        }
    }

    const SaveTestField = (e) => {
        setSubmitted(true);
        setButtonLoader(true);
        e.preventDefault();
        let Value = getTestFieldInfo;
        if (Value.TestField.trim() === "") {
            setButtonLoader(false);
            Value.TestField = "";
            Nodify('Warning!', 'warning', 'Test Field is required');
        }
        if (Value.TestField.trim()) {
            Value.TestField = Value.TestField.trim();
            TestFieldService.UpdateTestField(getTestFieldInfo).then(res => {
                setSubmitted(false);
                var page = "Remove";
                SearchRetain(page);
                let Func = 'Add';
                if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    PageRedirect(Func);
                }
                setButtonLoader(false);
            }).catch((err => {
                setSubmitted(false);
            }));
        }
        setTestFieldInfo(Value);
    }

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/TestFieldList",
            state: {
                message: Func,
            },
        }
        );
    };

    const CheckDuplicate = () => {
        const values = { ...getTestFieldInfo };
        let isExisting = !!getExisting.find(d => d.testField.trim().toLowerCase() === values.TestField.trim().toLowerCase() && d.testFieldID !== props.location.state.params.testFieldID);
        if (isExisting) {
            values.TestField = '';
            setTestFieldInfo(values);
            Nodify('Warning!', 'warning', "This Test Field is already exist.");
            setSubmitted(true);
            return false;
        }
    }

    //Enter Error msg
    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                //SaveBuyerInfo(event);
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);

    useEffect(() => {
        let Commentinfo = BindCommentValue(props);
        setTestFieldInfo(Commentinfo);
    }, []);


    useEffect(() => {
        TestFieldService.GetTestFieldList().then((res => {
            if (res.data) {
                setExisting(res.data.item2);
            }
        }))
    }, [])


    return (
        <div className="page-body">
            <ReactNotification />

            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">
                                Edit Test Field
                            </h3>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <Form>
                                    <div className="row">
                                        <div className="form-group col-sm-4">
                                            <label htmlFor="">
                                                Test Field Name<span className="text-danger">*</span>
                                            </label>
                                            <span className="input-icon icon-right">
                                                <Input
                                                    placeholder={"Enter Test Field"}
                                                    id="TestField"
                                                    name="TestField"
                                                    value={getTestFieldInfo.TestField}
                                                    type="text"
                                                    maxLength="100"
                                                    autoComplete="off"
                                                    onChange={handleChange}
                                                    onBlur={CheckDuplicate}
                                                    className='form-control'
                                                    style={{
                                                        border: submitted && (!getTestFieldInfo.TestField) ? '1px solid red' : ''
                                                    }}
                                                    autoFocus
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div
                        className="buttons-preview"
                        align="right"
                        style={{ marginTop: "15px" }}
                    >
                        <div className="buttons-preview" align="right">
                            <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                            </span>
                            <span className="btn btn-danger" onClick={() => ResetOperation()}
                                style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}>
                                <i className="fa fa-times"></i>&nbsp;Reset
                            </span>
                            <button
                                type="submit"
                                className="btn btn-success"
                                onClick={SaveTestField}
                                disabled={buttonLoader}
                            >

                                <i className="fa fa-check right"></i> &nbsp;Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TestFieldEdit;