import {
    TNADETAILREPORT_LOADING,
    LOAD_TNADETAILREPORT_LIST
}
    from './types'
import ReportService from "../services/Report/ReportService";

const TnaDetailsLoadig = (isStatus) => ({
    type: TNADETAILREPORT_LOADING,
    payload: isStatus
})

export const LoadTnaDetailsreport = (Params) => async (dispatch) => {
    try {
        dispatch(TnaDetailsLoadig(true))
        const res = await ReportService.GetTNADetailsReportList(Params.IsProduction, Params.PoStartDate, Params.PoEndDate, Params.ExfacStartDate, Params.ExfacEndDate);
        dispatch({
            type: LOAD_TNADETAILREPORT_LIST,
            payload: res.data,
        });
        dispatch(TnaDetailsLoadig(false))
    } catch (err) {
        console.log(err);
        dispatch(TnaDetailsLoadig(false));
    }
    dispatch(TnaDetailsLoadig(false));
}