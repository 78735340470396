import {
  TNA_LOADING,
  TNA_PURCHASE_ORDER_LIST,
  RETRIEVE_DROPDOWN_BRAND,
  RETRIEVE_DROPDOWN_SEASON,
  RETRIEVE_DROPDOWN_SUPPLIER,
  TNA_PURCHASE_ORDER_STYLE_ID_LIST,
  RETRIEVE_DROPDOWN_TNA_ROUTE,
  TNA_ALL_ROUTE_INFO_LIST,
  TNA_DROPDOWN_ROUTE_ID,
  TNA_LIST,
  TNA_COMMENTSLIST,
  TNA_ATTACHMENTSLIST,
  TNA_DETIALLIST,
  TNA_REPORTLIST,
  TNA_VIEWLIST,
  TNA_TASK_OWNER_LIST,
  TNA_COMPANY_HOLIDAY_LIST,
  TNA_TASK_INPUT_FIELDS_LIST,
  TNA_DROPDOWN_SUPPLIER_LIST,
  TNA_DROPDOWN_ACTIVEBUYER_LIST,
  RETRIEVE_DROPDOWN_TNA_SUPPLIER_EMPLOYEE,
  NEW_PURCHASE_ORDER_SKU_LIST,
  UPDATE_NEW_PURCHASE_ORDER_SKU_LIST_CHECK_BOX,
  TNA_ADD_FINAL_INSPECTION,
  TNA_ADD_TESTING_TNA,
  LOAD_FABRIC_TESTING_DETAILS,
  TEST_UPDATE_COMMENT_REPORT_VIEW_ALL_CHECK_BOX,
  TEST_UPDATE_COMMENT_REPORT_VIEW_CHECK_BOX,
  LOAD_TEST_ENTRY_DETAILS
} from "./types";

import TNAService from "../services/TNA/TNAService";
import StyleService from "../services/Style/styleService";
import RouteService from "../services/Route/RouteService";
import PurchaseOrderService from "../services/PurchaseOrder/PurchaseOrderService";

const TNALoading = (isStatus) => ({
  type: TNA_LOADING,
  payload: isStatus,
});

export const loadTNAPurchaseOrderList = (params) => async (dispatch) => {
  try {
    dispatch(TNALoading(true));
    if ((params.brandId === 0 || params.seasonId === 0 || params.supplierId === 0) && params.purchaseOrderId === 0) {
      dispatch({
        type: TNA_PURCHASE_ORDER_LIST,
        payload: { list: [], brandId: 0, seasonId: 0, supplierId: 0 },
      });
    } else {
      const res = await TNAService.GetTNAPurchaseOrderList(params);
      dispatch({
        type: TNA_PURCHASE_ORDER_LIST,
        payload: { list: res.data, brandId: params.brandId, seasonId: params.seasonId, supplierId: params.supplierId },
      });
    }

    dispatch(TNALoading(false));

  } catch (err) {
    console.log(err);
    dispatch(TNALoading(false));
  }
};


// export const paginationChanges = (paginationDetails) => async (dispatch) => {
//         dispatch({
//             type: TNA_PURCHASE_ORDER_PAGINATION,
//             payload: paginationDetails,
//         });
// };

export const loadBrandThemeStoryEndCustomer = (Operation, BuyerID) => async (dispatch) => {
  try {
    if (Operation === 7 && BuyerID === 0) {
      const res = await StyleService.LoadBrandThemeStoryEndCustomer(Operation, BuyerID);
      dispatch({
        type: RETRIEVE_DROPDOWN_BRAND,
        payload: res.data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};


export const LoadTaskWiseBuyer = (Operation, IsProduction) => async (dispatch) => {
  try {

    const res = await StyleService.LoadTaskWiseBuyer(Operation, IsProduction);
    dispatch({
      // type: RETRIEVE_DROPDOWN_BRAND,
      type: Operation === 2 ? RETRIEVE_DROPDOWN_BRAND : Operation === 3 ? RETRIEVE_DROPDOWN_SEASON : RETRIEVE_DROPDOWN_SUPPLIER,
      payload: res.data,
    });

  } catch (err) {
    console.log(err);
  }
};

export const setPurOrderStyleIdList = (idList) => async (dispatch) => {
  try {
    dispatch({
      type: TNA_PURCHASE_ORDER_STYLE_ID_LIST,
      payload: idList,
    });
  } catch (err) {
    console.log(err);
  }
};


export const loadTNARouteList = (PurchaseOrderIDList, IsProduction) => async (dispatch) => {

  try {
    if (PurchaseOrderIDList === null || PurchaseOrderIDList === undefined || PurchaseOrderIDList === "") {
      dispatch({
        type: RETRIEVE_DROPDOWN_TNA_ROUTE,
        payload: [],
      });
    } else {
      const res = await TNAService.LoadTNARouteList(PurchaseOrderIDList, IsProduction);
      dispatch({
        type: RETRIEVE_DROPDOWN_TNA_ROUTE,
        payload: res.data,
      });
    }

  } catch (err) {
    console.log(err);
  }
};

export const loadTNASupplierEmployeeList = (getTNASupplierID) => async (dispatch) => {

  try {

    const res = await TNAService.LoadTNASupplierEmployeeList(getTNASupplierID);
    dispatch({
      type: RETRIEVE_DROPDOWN_TNA_SUPPLIER_EMPLOYEE,
      payload: res.data,
    });


  } catch (err) {
    console.log(err);
  }
};







// export const loadTNAList = () => async (dispatch) => {

//   try {
//     const res = await TNAService.LoadTNARouteList();
//     dispatch({
//       type: RETRIEVE_DROPDOWN_TNA_ROUTE,
//       payload: res.data,
//     });

//   } catch (err) {
//     console.log(err);
//   }
// };

export const loadTNAList = (params) => async (dispatch) => {
  try {
    dispatch(TNALoading(true));
    const res = await TNAService.LoadTNAList(params);

    dispatch({
      type: TNA_LIST,
      payload: res.data,
    });

    dispatch(TNALoading(false));
  } catch (err) {
    console.log(err);
    dispatch(TNALoading(false));
  }
};

export const loadTNACommentsList = (params) => async (dispatch) => {
  try {
    dispatch(TNALoading(true));
    const res = await TNAService.LoadTNACommentsList(params);

    dispatch({
      type: TNA_COMMENTSLIST,
      payload: res.data,
    });

    dispatch(TNALoading(false));
  } catch (err) {
    console.log(err);
    dispatch(TNALoading(false));
  }
};

export const loadTNAAttachmentsList = (params) => async (dispatch) => {
  try {
    dispatch(TNALoading(true));
    const res = await TNAService.LoadTNAAttachmentsList(params);

    dispatch({
      type: TNA_ATTACHMENTSLIST,
      payload: res.data,
    });

    dispatch(TNALoading(false));
  } catch (err) {
    console.log(err);
    dispatch(TNALoading(false));
  }
};

export const loadTNADetailList = (params) => async (dispatch) => {
  try {

    dispatch(TNALoading(true));
    const res = await TNAService.LoadTNADetailList(params);
    dispatch({
      type: TNA_DETIALLIST,
      payload: res.data,
    });

    dispatch(TNALoading(false));
  } catch (err) {
    console.log(err);
    dispatch(TNALoading(false));
  }
};


export const loadTNADetailActivateList = (params) => async (dispatch) => {
  let resData = "";
  await TNAService.LoadTNADetailList(params).then((res) => {
    resData = res.data;
    dispatch({
      type: TNA_DETIALLIST,
      payload: res.data,
    });

  }).catch((err) => { return Promise.reject(err); });
  return Promise.resolve(resData);
}

export const loadTNAReportList = (params) => async (dispatch) => {
  try {
    dispatch(TNALoading(true));

    if (params.BuyerId === 0 || params.BrandId === 0 || params.SeasonId === 0) {
      dispatch(TNALoading(false));

      dispatch({
        type: TNA_REPORTLIST,
        payload: { data: [], params: [] },
      });
    } else {
      const res = await TNAService.LoadTNAReportList(params);
      dispatch({
        type: TNA_REPORTLIST,
        payload: { data: res.data, params: params },
      });
    }
    dispatch(TNALoading(false));
  } catch (err) {
    console.log(err);
    dispatch(TNALoading(false));
  }
};

export const loadStylePurchaseOrderTNAList = (params) => async (dispatch) => {
  try {
    dispatch(TNALoading(true));
    const res = await TNAService.StylePurchaseOrderTNAList(params);

    dispatch({

      type: TNA_VIEWLIST,
      payload: res.data,
    });

    dispatch(TNALoading(false));
  } catch (err) {
    console.log(err);
    dispatch(TNALoading(false));
  }
};

export const getRouteInfoList = (RouteID, PurchaseOrderIDList) => async (dispatch) => {

  try {
    dispatch(TNALoading(true));
    if (RouteID > 0) {
      const res = await TNAService.GetTNARouteInformationList(RouteID, PurchaseOrderIDList);
      dispatch({
        type: TNA_ALL_ROUTE_INFO_LIST,
        payload: res.data,
      });
    } else {
      dispatch({
        type: TNA_ALL_ROUTE_INFO_LIST,
        payload: [],
      });
    }
    dispatch(TNALoading(false));
  } catch (err) {
    console.log(err);
    dispatch(TNALoading(false));
  }
};

export const loadTNATaskOwner = (TaskHolderID, BrandID, SupplierID, UserLoginID) => async (dispatch) => {
  let resData = [];
  try {
    if (TaskHolderID > 0) {
      const res = await TNAService.LoadTNATaskOwner({ TaskHolderID: TaskHolderID, BrandID: BrandID, SupplierID: SupplierID, UserLoginID: UserLoginID });
      dispatch({
        type: TNA_TASK_OWNER_LIST,
        payload: res.data,
      });
      resData = res.data;
    } else {
      dispatch({
        type: TNA_TASK_OWNER_LIST,
        payload: [],
      });
      resData = [];
    }
  } catch (err) {
    console.log(err);
  }
  return Promise.resolve(resData);
};


export const setTNADrpRouteID = (RouteID) => async (dispatch) => {

  dispatch({
    type: TNA_DROPDOWN_ROUTE_ID,
    payload: RouteID,
  });
};

export const getTNACompanyHolidayList = (BrandID, SupplierID, CompanyBuyerSupID) => async (dispatch) => {

  if (CompanyBuyerSupID > 0) {
    const res = await TNAService.getTNACompanyHolidayList({ BrandID: BrandID, SupplierID: SupplierID, CompanyBuyerSupID: CompanyBuyerSupID });
    dispatch({
      type: TNA_COMPANY_HOLIDAY_LIST,
      payload: res.data,
    });
  } else {
    dispatch({
      type: TNA_COMPANY_HOLIDAY_LIST,
      payload: [],
    });
  }
};

export const insertTNA = (Params) => async (dispatch) => {
  let resData = "";
  await TNAService.InsertTNA(Params).then((res) => {
    resData = res.data;
  }).catch((err) => { return Promise.reject(err); });
  return Promise.resolve(resData);
}

export const updateTNA = (Params) => async (dispatch) => {
  let resData = "";
  await TNAService.UpdateTNA(Params).then((res) => {
    resData = res.data;
  }).catch((err) => { return Promise.reject(err); });
  return Promise.resolve(resData);
}

export const InsertUpdateTNAProtoFitSmsSubmissionApproval = (Params) => async (dispatch) => {

  let resData = "";
  await TNAService.InsertUpdateTNAProtoFitSmsSubmissionApproval(Params).then((res) => {
    resData = res.data;
  }).catch((err) => { return Promise.reject(err); });
  return Promise.resolve(resData);
}

export const setTNATaskInputFields = (values) => async (dispatch) => {

  if (values.length > 0) {

    dispatch({
      type: TNA_TASK_INPUT_FIELDS_LIST,
      payload: values,
    });
  } else {
    dispatch({
      type: TNA_TASK_INPUT_FIELDS_LIST,
      payload: [],
    });
  }
};

export const loadSupplierList = () => async (dispatch) => {
  try {
    const res = await PurchaseOrderService.LoadSupplierList();
    dispatch({
      type: TNA_DROPDOWN_SUPPLIER_LIST,
      payload: res.data,
    });

  } catch (err) {
    console.log(err);
  }
};


export const loadTNAChildReferenceUnMappedList = (Params) => async (dispatch) => {

  let resData = "";
  await TNAService.LoadTNAChildReferenceUnMappedList(Params).then((res) => {
    resData = res.data;
  }).catch((err) => { return Promise.reject(err); });
  return Promise.resolve(resData);
}

export const getTNAEditTaskWiseChildList = (Params) => async (dispatch) => {

  let resData = "";
  await TNAService.GetTNAEditTaskWiseChildList(Params).then((res) => {
    resData = res.data;
  }).catch((err) => { return Promise.reject(err); });
  return Promise.resolve(resData);
}


export const loadActiveBuyerList = () => async (dispatch) => {
  try {
    const res = await StyleService.LoadBuyer(2);
    dispatch({
      type: TNA_DROPDOWN_ACTIVEBUYER_LIST,
      payload: res.data,
    });

  } catch (err) {
    console.log(err);
  }
};


export const loadNewPurchaseOrderSKUList = (TNAId, StyleId) => async (dispatch) => {

  dispatch(TNALoading(true));
  let resData = "";
  await TNAService.LoadNewPurchaseOrderSKUList(TNAId, StyleId).then((res) => {
    resData = res.data;
    dispatch(TNALoading(false));
    dispatch({
      type: NEW_PURCHASE_ORDER_SKU_LIST,
      payload: res.data,
    });
  }).catch((err) => {
    dispatch(TNALoading(false));
    dispatch({
      type: NEW_PURCHASE_ORDER_SKU_LIST,
      payload: [],
    });
    return Promise.reject(err);
  });
  dispatch(TNALoading(false));
  return Promise.resolve(resData);
}



export const getSkuAndStyleDetails = (Params) => async (dispatch) => {

  let resData = "";
  await TNAService.GetSkuAndStyleDetails(Params).then((res) => {
    resData = res.data;
  }).catch((err) => { return Promise.reject(err); });
  return Promise.resolve(resData);
}

export const updatePOSKUCheckBox = (Params) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_NEW_PURCHASE_ORDER_SKU_LIST_CHECK_BOX,
      payload: Params,
    });

  } catch (err) {
    console.log(err);
  }
};

export const getNewPOStyleListNotMappedTNA = (Params) => async (dispatch) => {
  dispatch(TNALoading(true));
  let resData = "";
  await TNAService.GetNewPOStyleListNotMappedTNA(Params).then((res) => {
    resData = res.data;
  }).catch((err) => {
    dispatch(TNALoading(false));
    return Promise.reject(err);
  });
  dispatch(TNALoading(false));
  return Promise.resolve(resData);
}

export const updateNewPOStyleINTNA = (Params) => async (dispatch) => {
  dispatch(TNALoading(true));
  let resData = "";
  await TNAService.UpdateNewPOStyleINTNA(Params).then((res) => {
    resData = res.data;
  }).catch((err) => {
    dispatch(TNALoading(false));
    return Promise.reject(err);
  });
  dispatch(TNALoading(false));
  return Promise.resolve(resData);
}

// export const GetSubChildMultExFactoryFormSKUList = (Params) => async (dispatch) => {
//   dispatch(TNALoading(true));
//   let resData = "";
//   await TNAService.GetSubChildMultExFactoryFormSKUList(Params).then((res) => {
//     resData = res.data;
//   }).catch((err) => {
//     dispatch(TNALoading(false));
//     return Promise.reject(err);
//   });
//   dispatch(TNALoading(false));
//   return Promise.resolve(resData);
//}


export const GetSubChildMultExFactoryFormSKUList = (Params) => async (dispatch) => {
  let res = await TNAService.GetSubChildMultExFactoryFormSKUList(Params);
  dispatch({
    type: TNA_ADD_FINAL_INSPECTION,
    payload: res.data,
  });
  return Promise.resolve(res.data);
};

export const GetTestingMultExFactorySKUList = (Params) => async (dispatch) => {
  let res = await TNAService.GetTestingMultExFactorySKUList(Params);
  dispatch({
    type: TNA_ADD_TESTING_TNA,
    payload: res.data,
  });
  return Promise.resolve(res.data);
};

export const GetTestDetailsReportList = (params) => async (dispatch) => {
 
  let res = await TNAService.GetTestDetailsReportList(params.Operation, params.IsProduction, params.TaskDetailsName, params.ExfacStartDate, params.ExfacEndDate, params.TestStartDate, params.TestEndDate, params.TNAStartDate, params.TNAEndDate);
  dispatch({
    type: LOAD_FABRIC_TESTING_DETAILS,
    payload: res.data,
  });
  return Promise.resolve(res.data);
};

export const GetTestEntryDetails = (IsProduction, Taskname, PoStartDate, PoEndDate, ApStartDate, ApEndDate, ApprovedStatus) => async (dispatch) => {
  let res = await TNAService.GetTestEntryDetailsReportList(IsProduction, Taskname, PoStartDate, PoEndDate, ApStartDate, ApEndDate, ApprovedStatus);
  dispatch({
    type: LOAD_TEST_ENTRY_DETAILS,
    payload: res.data,
  });
  return Promise.resolve(res.data);
};

export const UpdateTestCommentsreportViewAllCheckBox = (Params) => async (dispatch) => {
  dispatch(TNALoading(true));
  dispatch({
    type: TEST_UPDATE_COMMENT_REPORT_VIEW_ALL_CHECK_BOX,
    payload: Params,
  });
  dispatch(TNALoading(false));
};

export const UpdateTestCommentsreportViewCheckBox = (params) => async (dispatch) => {
  dispatch(TNALoading(true));
  dispatch({
    type: TEST_UPDATE_COMMENT_REPORT_VIEW_CHECK_BOX,
    payload: params,
  });

  dispatch(TNALoading(false));

};