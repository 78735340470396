import React, { Fragment, useState, useMemo, useEffect } from "react";
import FinalinspectionService from "../../../../services/FinalInspection/FinalinspectionService";
import useFullPageLoader from "../../../hooks/useFullPageLoader";

const FinalInspectionConclusion = ({ props, Conclusioncallback, rerenderdep }) => {
    const [getInputFields, setInputFields] = useState([]);
    const [getDefects, setDefects] = useState([]);
    const [getFindingRemarks, setFindingRemarks] = useState('');
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const inpsectionId = JSON.parse(localStorage.getItem("InspectionID"));

    useEffect(() => {
        showLoader();
        let ConsclusionList = [];
        let DefectList = [];
        // if (props.location.state.params.TaskStatus === 1 || props.location.state.params.TaskStatus === 2) {
        FinalinspectionService.GetFinalinspecConclusionList(1, props.location.state.params.TaskStatus === 1 ? inpsectionId : props.location.state.params.TNAInspectionID).then((res) => {
            res.data.outputConclusionList.map((x) => {
                ConsclusionList.push({
                    details: x.details,
                    result: x.resultName,
                    findingsRemarks: x.findingsRemarks
                })
            })
            setInputFields(ConsclusionList);
            hideLoader();
        })
        FinalinspectionService.GetFinalinspecConclusionList(2, props.location.state.params.TaskStatus === 1 ? inpsectionId : props.location.state.params.TNAInspectionID).then((res) => {
            res.data.outputAllowandFoundDefects.map((x) => {
                DefectList.push({
                    Details: x.defectsName,
                    Critical: x.critical,
                    Major: x.major,
                    Minor: x.minor
                })
            })
            setDefects(DefectList);
        })

        FinalinspectionService.GetTNAFinalinspecMainInfo(props.location.state.params.TaskStatus === 1 ? inpsectionId : props.location.state.params.TNAInspectionID, props.location.state.params.details.tnaid).then(async (res) => {
            if (res.data.finalInsMainList) {
                res.data.finalInsMainList.map((x) => {
                    setFindingRemarks(x.inspectorConclusion);
                })
            }
        })
    }, [rerenderdep])

    function handlechange(e) {
        let inputText = ""
        if (e.target.value.trim() !== '') {
            inputText = e.target.value;
        }
        setFindingRemarks(inputText);
        Conclusioncallback(inputText)
    }
    return (
        <Fragment>
            <div className="col-md-12">
                <div className="page-body">
                    <table className="table table-striped table-bordered table-hover dataTable no-footer">
                        <thead>
                            <tr>
                                <th style={{ width: '40%' }}>Details</th>
                                <th>Result</th>
                                <th>Details Name - Findings / Remarks</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                getInputFields.map((comment) => (
                                    <tr key={
                                        comment.index
                                    }>
                                        <td >
                                            {comment.details}
                                            {comment.details === 'AQL' ?
                                                <table className="table" style={{ width: '50%', marginTop: '10px', marginLeft: '10px', marginBottom: '10px' }}>
                                                    <thead style={{ background: 'white !important' }}>
                                                        <tr>
                                                            <th>Defects</th>
                                                            <th>Critical</th>
                                                            <th>Major</th>
                                                            <th>Minor</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {
                                                            getDefects.map((y) => (
                                                                <tr>
                                                                    <td>{y.Details}:</td>
                                                                    <td>{y.Critical}</td>
                                                                    <td>{y.Major}</td>
                                                                    <td>{y.Minor}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                                // getDefects.map((y) => (
                                                //     <div>
                                                //         {/* <tr><b>{y.Details}-Critical : {y.Critical},&nbsp;Major : {y.Major},&nbsp;Minor : {y.Minor}</b></tr> */}
                                                //     </div>
                                                // ))
                                                : ''}
                                        </td>
                                        <td className={comment.result === 'Not OK' ? 'OWtext-danger' : comment.result === 'Not Applicable' ? 'text-primary' : comment.result === 'Beyond AQL' ? 'OWtext-danger' : 'OWtext-success'}><b>{comment.result}</b></td>
                                        <td><b>{comment.findingsRemarks === '' ? '-' : comment.findingsRemarks}</b></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    <div className="row" style={{ marginTop: "15px" }}>
                        <div className="col-md-12">
                            <div className='form-group'>
                                <label htmlFor="txtInspectorsConclusion">Inspector's Conclusion</label>
                                <span className='input-icon icon-right'>
                                    <textarea value={getFindingRemarks} onChange={event => handlechange(event)} style={{ width: '100%' }}
                                        placeholder="Enter Inspector's Conclusion" id="txtInspectorsConclusion"
                                        name="inspectorsconclusion" autoComplete="off" maxLength="500" rows="5">
                                    </textarea>

                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {loader}
        </Fragment >
    )
};
export default FinalInspectionConclusion;