import {
    PACKAGEITEM_LOADING,
    RETRIEVE_PACKAGEITEM,
    DELETE_PACKAGEITEM,
} from "../actions/types";

const initialState = {
    isLoadingPackageItem: true,
    PackageItemList1: [],
    PackageItemEdit2: []
}
const PackageItemReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case PACKAGEITEM_LOADING:
            return {
                ...state,
                isLoadingPackageItem: payload
            }
        case RETRIEVE_PACKAGEITEM:
            return {
                ...state,
                PackageItemList1: payload.inputPackageItemList1,
                PackageItemEdit2: payload.inputPackageItemEdit2
            }
        case DELETE_PACKAGEITEM:
           
            const results = state.PackageItemList1.filter(c => c.packageItemID !== payload.PackageItemID);
            return {
                ...state,
                PackageItemList1: results
            }
        default:
            return state
    }

}
export default PackageItemReducer;