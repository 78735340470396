import React, { useState, useEffect, useRef, useCallback } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import 'react-notifications-component/dist/theme.css';
import ReactNotification from 'react-notifications-component';
import validator from 'validator'
import Nodify from "../../../../Common/ReactNotification";
import { createReportModuleCategory } from "../../../../../actions/reportmodulecategory";
import SearchRetain from "../../../../Common/SearchRetain";
import { useDispatch, useSelector } from "react-redux";


const EditReportModuleCategory = (props) => {

    const [ReportModulecategory, setInputs] = useState({ ReportModuleCategoryName: '' });
    const [submitted, setSubmitted] = useState(false);
    const [getReport, setOutputs] = useState({});
    const [ButtonName, setButtonName] = useState("Save");
    const [HeaderName, setHeaderName] = useState("Add");
    const [buttonLoader, setButtonLoader] = useState(false);
    const { user: currentUser } = useSelector((state) => state.auth);

    const dispatch = useDispatch();

    function addressAssign(props) {
        //  alert('Edit');
        let reportModuleCategoryValue = {
            ReportModuleCategoryID: 0,
            ReportModuleCategoryName: '',
            Operation: 1,
            Createdby: currentUser.employeeinformationID
        };

        Array.from(document.querySelectorAll("input")).forEach(input => (input.value = ""));
        if (props.location.state !== undefined && props.location.state.params.length !== 0) {
            if (props.location.state.params.addressTypeID !== "") {
                setButtonName("Update");
                setHeaderName("Edit");
                reportModuleCategoryValue.ReportModuleCategoryID = props.location.state.params.reportModuleCategoryID;
                reportModuleCategoryValue.ReportModuleCategoryName = validator.trim(props.location.state.params.reportModuleCategoryName);
                reportModuleCategoryValue.Operation = 2;
                return reportModuleCategoryValue;
            }
        } else {
            return reportModuleCategoryValue;

        }
    }

    useEffect(() => { // showLoader();
       
        let getReportAssign = addressAssign(props);
        setOutputs(getReportAssign)
    }, []);

    const saveReportModuleCategory = useCallback((e) => {
       ;
        setButtonLoader(true);
        e.preventDefault();
        if (getReport.ReportModuleCategoryName.trim() === '') {
            setButtonLoader(false);
            setSubmitted(true);
            Nodify('Warning!', 'warning', 'Report Module Category is required');
            // return false;
        }
        else {
            if (getReport.ReportModuleCategoryName.trim()) {
                getReport.ReportModuleCategoryName = getReport.ReportModuleCategoryName.trim();

                dispatch(createReportModuleCategory(getReport))
                    .then(data => {
                        var page = "Remove";
                        SearchRetain(page);
                        let Func = 'Add';
                        if (data.outputResult === "1") {
                            Func = 'Add';
                            PageRedirect(Func);
                        } else if (data.outputResult === "2") {
                            Func = 'Edit';
                            PageRedirect(Func);
                        } else if (data.outputResult === "-2") {
                            getReport.ReportModuleCategoryName = '';
                            setOutputs(getReport);
                            Nodify('Warning!', 'warning', 'Report Module Category name already Exist');
                        }
                        setButtonLoader(false);
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }
            else {
                setSubmitted(true);
            }
        }
    }, [getReport]);




    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/ReportModuleCategoryList",
            state: {
                message: Func
            }
        });
    }


    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({
            ...inputs,
            [name]: value
        }));
        let reportModuleCategoryValue = getReport;
        let inputText = '';
        if (e.target.value.trim() !== '') {
            inputText = e.target.value;
            setSubmitted(true);
        }
        else { setSubmitted(false); }
        reportModuleCategoryValue[e.target.name] = inputText;
        setOutputs(reportModuleCategoryValue);
    }



    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/ReportModuleCategoryList')
        } else {
            window.location.reload();
        }
    }


    const View = getReport.View;
    return (
        <div className="page-body">
            <ReactNotification />
            {
                View === undefined ? (
                    <div className="row">
                        <div className="col-lg-12 col-sm-12 col-xs-12">
                            <div className="widget flat radius-bordered">
                                <div className="widget-header heading_box_style">
                                    <h3 className="heading_style_common">{HeaderName} Report Module Category</h3>
                                </div>
                                <div className="widget-body">
                                    <div id="registration-form">
                                        <Form>

                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <div className="form-group">
                                                        {/* <Input name="AddressTypeID" className="form-control"
                                                            onChange={handleChange}
                                                            value={getReport.AddressTypeID}
                                                            type="number"
                                                            style={{ display: 'none' }} /> */}

                                                        <label htmlFor="ReportModuleCategoryName">Report Module Category<span className="text-danger">*</span></label>
                                                        <span className="input-icon icon-right">
                                                            <input type="text" name="ReportModuleCategoryName"
                                                                id="ReportModuleCategoryName"
                                                                value={getReport.ReportModuleCategoryName}
                                                                onChange={handleChange}
                                                                style={{ border: submitted && !getReport.ReportModuleCategoryName ? '1px solid red' : '' }}
                                                                maxLength="25"
                                                                className={
                                                                    'form-control' + (
                                                                        submitted && !getReport.ReportModuleCategoryName ? ' is-invalid' : ''
                                                                    )
                                                                }
                                                                autoFocus
                                                                placeholder="Report Module Category"
                                                            />
                                                            
                                                        </span>

                                                    </div>
                                                </div>
                                                <div className="clearfix"></div>
                                                <div className="col-sm-12">
                                                    <div className="buttons-preview" align="right">
                                                        <span className="btn btn-primary"
                                                            onClick={
                                                                () => ResetOperation("Back")
                                                            }>
                                                            <i className="fa fa-arrow-circle-left"></i>
                                                            &nbsp;Back</span>
                                                        <span className="btn btn-danger"
                                                            onClick={
                                                                () => ResetOperation()
                                                            }>
                                                            <i className="fa fa-times"></i>
                                                            &nbsp;Reset</span>
                                                        <button type="submit" 
                                                        onClick={saveReportModuleCategory} 
                                                        className="btn btn-success"
                                                            disabled={buttonLoader}
                                                        >

                                                            <i className="fa fa-check right"></i>
                                                            &nbsp;{
                                                                ButtonName
                                                            }</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                ) : (
                    <div className="navbar-expand-lg navbar-light">
                        <h3>Report Module Category</h3>
                        <div className="form-group">
                            <label name="ReportModuleCategoryName">
                                {
                                    getReport.Name
                                }</label>
                        </div>
                    </div>

                )
            }
            { }</div>
    );
};

export default EditReportModuleCategory;
