import React, { useState, useEffect, Fragment } from "react";
import ReactNotification from 'react-notifications-component';
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import 'react-notifications-component/dist/theme.css';
import Nodify from "../Common/ReactNotification";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Reactselect from 'react-select';
import StyleService from "../../services/Style/styleService";
import supplierService from "../../services/Master/SupplierService";
import CountryService from "../../services/Master/CityService";
import Lightbox from "react-image-lightbox"
import Draggable from 'react-draggable';
import ModalDialog from 'react-bootstrap/ModalDialog';
import { Modal, Button } from "react-bootstrap";
import PurchaseOrderService from "../../services/PurchaseOrder/PurchaseOrderService";
// import strikeoffService from "../../services/Style/StrikeoffService";
import axios from "axios";
import $ from "jquery";
import { useSelector } from "react-redux";
import { TableHeader, Pagination, Search, PageCount } from "../Datatable";
import TnaService from "../../services/TNA/TNAService";
import UploadImage from "../../components/Common/UploadImage"
import SweetAlertPopup from '../Common/SweetAlertPopup';
import { StrikeOffSubmissionApprovalId } from "../Common/PageActionNumber";
import html2canvas from 'html2canvas'
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import download from 'downloadjs'
import jsPDF from 'jspdf'
import useFullPageLoader from "../../hooks/useFullPageLoader";
import moment from "moment";

const StrikeoffSubmission = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);

    const [inputFields, setInputFields] = useState([{
        TNAId: 0, BrandID: 0, SeasonID: 0, SupplierID: 0, SubmittedDate: '', SendTo: '', SendThrough: 0,
        AWBName: '', AWBDate: '', SubmissionApprovalStatus: 0, StrikeoffCode: '', StrikeOffId: 0, StrikeoffName: '', Quality: '',
        StyleName: '', StyleNo: '', IDPONo: '', EndCustomer: '',
        StikeOffImage: '', StikeOffImagePath: '', StrikeOffDimensionID: 0, StrikeOffDimension: '', SwatchCardImageName: '',
        SwatchCardIamgePath: '', Remarks: '', Createdby: 0,
        TNAStrikeoffSubmittedOption: [], TNAStrikeoffColorApprovalComments: [],
        BuyerBrandSeasonName: '', AWBDateandNo: '', StrikeoffCodeandName: '', ApprovalDate: '', SubmitSentDetails: '', StrikOffReferenceID: 0,
        StrikeoffSubmissionApprovalID: 0, Approvedby: '', Result: '', CubeorBuyer: '', StrikeoffImage: ''
    }]);
    const values = [...inputFields]
    const [getOrderinfo, setOrderinfo] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [getHeader, setHeader] = useState("Add");
    const [Buttonvisible, SetButtonvisible] = useState("Show");

    const [isOpen, setIsOpen] = useState(false);
    const [ShowImportPopup, setShowImportPopup] = useState(false);
    const [getImagename, setImagename] = useState();
    const [getDisableImport, setDisableImport] = useState(true);
    const [expandState, setExpandState] = useState({});
    const [expandedRows, setExpandedRows] = useState([]);
    const [expandList, setexpandList] = useState({});
    const [getSubImageList, setSubImageList] = useState([]);
    const [getCurrimageindex, setCurrimageindex] = useState(0);
    const [fontcolor, setfontcolor] = useState("#000000");
    const [getApprovedOptions, setApprovedOptions] = useState([]);
    const [ApprovalList, setApprovalList] = useState([]);
    const [SubmittedOption, setSubmittedOption] = useState([]);
    const [ColorApprovalComments, setColorApprovalComments] = useState([]);
    const [StrikeoffDimension, setStrikeoffDimension] = useState([]);
    // const [OrderInfoMulticloseMainDetails, setOrderInfoMulticloseMainDetails] = useState([]);
    const [OrderInfoMainDetails, setOrderInfoMainDetails] = useState([]);
    //  const [MultiCloseDatas, setMultiCloseDatas] = useState([]);
    const [selectall, setselectall] = useState(false);
    // const [showMultiClosediv, setshowMultiClosediv] = useState();
    // const [showSubmissiondiv, setshowSubmissiondiv] = useState();

    const [expandStateMultiSelect, setExpandStateMultiSelect] = useState({});
    const [expandedRowsMultiSelect, setExpandedRowsMultiSelect] = useState([]);
    const [expandListMultiSelect, setexpandListMultiSelect] = useState({});
    const [getID, setID] = useState({ showPopupDelete: false, Params: {} });
    const [showswatchcardimages, setshowswatchcardimages] = useState([]);
    const [swatchcardimages, setswatchcardimages] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [minApprovalDate, setMinApprovalDate] = useState({});
    const ApprovedOption = [
        { key: '1', value: 'Approved' },
        { key: '2', value: 'Approved with Comments' },
        { key: '3', value: 'Rejected' },
        { key: '4', value: 'Hold' }
    ]
    // let styles = {
    //     control: styles => ({ ...styles, border: '1px solid red' }),
    // }
    let styles1 = {
        control: styles1 => ({ ...styles1 }),
    }

    let styles2 = {
        control: styles2 => ({ ...styles2, border: '1px solid red' }),

    }


    const headers = [
        { name: "Style Name", field: "StyleName", sortable: false },
        { name: "Style No", field: "StyleNo", sortable: false },
        { name: "PO/ID No", field: "IDNo", sortable: false },
        { name: "Details", field: "Detail", sortable: false },
        { name: "Task Holder/Follower", field: "", sortable: false },
        { name: "Action", field: "", sortable: false },

    ];

    // If check production or sampling
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProduction = activeMenus.IsProduction;
    if (props.props.location.pathname === '/Dashboard') { IsProduction = props.IsProductionDD }
    // End

    const [CurrentSwatchstatus, setCurrentSwatchstatus] = useState(0);

    useEffect(() => {
        setCurrentSwatchstatus(props.SwatchStatus);
        if (props.delteStrikeOffApproval === true) {
            TnaService.ViewStrikeoffSubmissionApprovalList(props.TNAStrikeOffRefInfoId, IsProduction).then((response) => {
                if (response.data) {
                    setOrderinfo(response.data.strikeoffSubmissionOrderInfoList);
                }
            }).catch(() => { });
            setID({ showPopupDelete: true, Params: props.TNAStrikeOffRefInfoId });
        }
        else if (props.editStrikeOffApproval === true) {

            let getDateValue = new Date(values[0].ApprovalDate);
            var dd = getDateValue.getDate();
            var mm = getDateValue.getMonth() + 1;
            var yyyy = getDateValue.getFullYear();

            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            values[0].ApprovalDate = getDateValue;
            setInputFields(values);

        }
        else if (props.StrikeoffswatchDownload === true) {
            TnaService.ViewStrikeoffSubmissionApprovalList(props.TNAStrikeOffRefInfoId, IsProduction).then((response) => {
                if (response.data) {
                    values[0].SwatchCardIamgePath = response.data.strikeoffSubmitApprovalList[0].swatchCardImagePath;
                    values[0].SwatchCardImageName = response.data.strikeoffSubmitApprovalList[0].swatchCardImageName;
                    setInputFields(values);
                }

            }).catch(() => { });
            downloadSwatchcard();
        }
        else if (props.StrikeoffswatchUpload === true) {
            $('#upload-button').trigger('click');

        }
        else {
            let Optionvalue = [];
            ApprovedOption.forEach(element => {
                let LabelColor = '';
                if (element.value === 'Approved') {
                    LabelColor = <><span style={{ color: "#00b050" }}><b>Approved</b></span></>
                }
                else if (element.value === 'Approved with Comments') {
                    LabelColor = <><span style={{ color: "#fca404" }}><b>Approved with Comments</b></span></>
                }
                else if (element.value === 'Rejected') {
                    LabelColor = <><span style={{ color: "#c40404" }}><b>Rejected</b></span></>
                }
                else if (element.value === 'Hold') {
                    LabelColor = <><span style={{ color: "#073763" }}><b>Hold</b></span></>
                }


                Optionvalue.push({
                    value: element.key,
                    label: LabelColor,
                    subLabel: element.value,
                })
            });

            setApprovedOptions(Optionvalue);
            TnaService.GetStrikeoffDimension(2, props.TNAStrikeOffRefInfoId, IsProduction).then((response) => {
                if (response.data) {
                    setStrikeoffDimension(response.data);
                }

            }).catch(() => { });

            if (props.TaskStatus !== 3) {
               
                //  setshowMultiClosediv(true);
                //   setshowSubmissiondiv(false);
                TnaService.StrikeoffSubmissionList(3, props.TNAStrikeOffRefInfoId, IsProduction).then((response) => {
                    if (response.data) {
                        values[0].StrikeoffSubmissionApprovalID = response.data[0].strikeoffSubmissionApprovalID;
                        values[0].TNAId = response.data[0].tnaId;
                        values[0].BrandID = response.data[0].brandID;
                        values[0].BuyerID = response.data[0].buyerID;
                        values[0].SeasonID = response.data[0].seasonID;
                        values[0].SupplierID = response.data[0].supplierID;
                        values[0].SupplierName = response.data[0].supplierName;
                        values[0].BuyerBrandSeasonName = response.data[0].buyerBrandSeasonName;
                        values[0].StyleID = response.data[0].styleId;
                        values[0].StyleName = response.data[0].styleName;
                        values[0].StyleNo = response.data[0].styleNo;
                        values[0].IdpoNo = response.data[0].idpoNo;
                        values[0].EndCustomer = response.data[0].endCustomerNameIdList;
                        values[0].StrikeOffId = response.data[0].strikeOffId;
                        values[0].StrikeoffCode = response.data[0].strikeOffCode;
                        values[0].StrikeoffName = response.data[0].strikeOffName;
                        values[0].SubmittedDate = new Date(response.data[0].submittedDate)
                        //setMinApprovalDate((moment(values[0].SubmittedDate).format("YYYY-MM-DD")).toString());
                        values[0].Quality = response.data[0].quality;
                        values[0].AWBName = response.data[0].awbName;
                        values[0].AWBDate = response.data[0].awbDate;
                        values[0].SendThrough = response.data[0].sendThroughId;
                        values[0].SendThroughName = response.data[0].sendThroughName;

                        let getDate = new Date(response.data[0].awbDate);
                        let dd = getDate.getDate();
                        let mm = getDate.getMonth() + 1;
                        let yyyy = getDate.getFullYear();

                        if (dd < 10) {
                            dd = '0' + dd;
                        }
                        if (mm < 10) {
                            mm = '0' + mm;
                        }
                        let coverted = dd + '/' + mm + '/' + yyyy;
                        values[0].SubmitSentDetails = response.data[0].sendThroughId === 1 ? response.data[0].sendThroughName : response.data[0].sendThroughName + ' ' + response.data[0].awbName + ' ' + (response.data[0].awbDate === "" ? '' : '[' + moment(response.data[0].awbDate).format('DD/MM/YYYY')+ ']');
                        values[0].SwatchCardImageName = props.SwatchStatus === 3 ? response.data[0].swatchCardImageName : '';
                        values[0].SwatchCardIamgePath = props.SwatchStatus === 3 ? response.data[0].swatchCardImagePath : '';
                        values[0].Remarks = response.data[0].remarks;
                        values[0].SendTo = response.data[0].sentTo;
                        values[0].StyleStrikeOffRefId = response.data[0].styleStrikeOffRefId;

                        values[0].StrikOffReferenceID = response.data[0].strikOffReferenceId;
                        values[0].TNAStrikeOffRefInfoId = props.TNAStrikeOffRefInfoId;
                        values[0].StrikeoffImage = response.data[0].strikeoffImage;
                        let images = [];
                        images = response.data[0].strikeOffImageList.split(',');
                        var StrikeoffImageIndex = images.indexOf(values[0].StrikeoffImage);
                        setSubImageList(images);
                        setCurrimageindex(StrikeoffImageIndex);

                        setInputFields(values);
                    }
                }).catch(() => { });

                TnaService.GetStrikeoffSubmissionSubmittedOption(2, props.SubmittedOptionListId).then((response) => {
                    if (response.data) {
                        setSubmittedOption(response.data);
                        values[0].TNAStrikeoffSubmittedOption = response.data;
                        setInputFields(values);
                    }

                }).catch(() => { });

                TnaService.GetStrikeoffColorAppComments(1, props.ColorAppCommentsId).then((response) => {
                    if (response.data) {
                        setColorApprovalComments(response.data);
                        values[0].TNAStrikeoffColorApprovalComments = response.data;
                        setInputFields(values);

                    }

                }).catch(() => { });

                // TnaService.GetStrikeoffSubmissionOrderInfoList(2, props.TNAStrikeOffRefInfoId).then((response) => {
                //     if (response.data) {
                //         setOrderinfo(response.data);

                //     }
                // }).catch(() => { });
                TnaService.GetStrikeoffSubmissionOrderInfoList(4, props.TNAStrikeOffRefInfoId, IsProduction).then((response) => {
                    if (response.data) {
                        let index = 0;
                        response.data.forEach(element => {
                            index = index + 1;
                            element.index = index;
                            if (element.tnaStrikeOffRefInfoId === props.TNAStrikeOffRefInfoId) {
                                element.isSelected = 1;
                            }

                        })

                        setOrderinfo(response.data);

                        let data = [...new Map(response.data.map(item =>
                            [item['strikeOffLogoId'], item])).values()];


                        setOrderInfoMainDetails(data);
                        handleExpandRowMultiSelect(response.data[0].strikeoffLogoPath, response.data);
                    }
                }).catch(() => { });
            }
            else {
                // setshowMultiClosediv(false);
                // setshowSubmissiondiv(true);
                TnaService.ViewStrikeoffSubmissionApprovalList(props.TNAStrikeOffRefInfoId, IsProduction).then((response) => {
                    if (response.data) {
                        values[0].TNAID = response.data.strikeoffSubmitApprovalList[0].tnaId;
                        values[0].BrandID = response.data.strikeoffSubmitApprovalList[0].brandID;
                        values[0].BuyerID = response.data.strikeoffSubmitApprovalList[0].buyerID;
                        values[0].SeasonID = response.data.strikeoffSubmitApprovalList[0].seasonID;
                        values[0].SupplierID = response.data.strikeoffSubmitApprovalList[0].supplierID;
                        values[0].SupplierName = response.data.strikeoffSubmitApprovalList[0].supplierName;
                        values[0].BuyerBrandSeasonName = response.data.strikeoffSubmitApprovalList[0].buyerBrandSeasonName;
                        values[0].StyleID = response.data.strikeoffSubmitApprovalList[0].styleId;
                        values[0].StyleName = response.data.strikeoffSubmitApprovalList[0].styleName;
                        values[0].StyleNo = response.data.strikeoffSubmitApprovalList[0].styleNo;
                        values[0].IdpoNo = response.data.strikeoffSubmitApprovalList[0].idpoNo;
                        values[0].EndCustomer = response.data.strikeoffSubmitApprovalList[0].endCustomerNameIdList;
                        values[0].StrikeoffCode = response.data.strikeoffSubmitApprovalList[0].strikeOffCode;
                        values[0].StrikeOffId = response.data.strikeoffSubmitApprovalList[0].strikeOffId;
                        values[0].StrikeoffName = response.data.strikeoffSubmitApprovalList[0].strikeOffName;
                        values[0].Quality = response.data.strikeoffSubmitApprovalList[0].quality;
                        values[0].StyleStrikeOffRefId = response.data.strikeoffSubmitApprovalList[0].styleStrikeOffRefId;
                        values[0].StrikOffReferenceID = response.data.strikeoffSubmitApprovalList[0].strikOffReferenceId;
                        values[0].SubmittedDate = new Date(response.data.strikeoffSubmitApprovalList[0].submittedDate)
                        //setMinApprovalDate((moment(values[0].SubmittedDate).format("YYYY-MM-DD")).toString());
                        values[0].SendThrough = response.data.strikeoffSubmitApprovalList[0].sendThrough;
                        values[0].SendTo = response.data.strikeoffSubmitApprovalList[0].sendTo;

                        values[0].SwatchCardIamgePath = props.SwatchStatus === 3 ? response.data.strikeoffSubmitApprovalList[0].swatchCardImagePath : '';
                        values[0].SwatchCardImageName = props.SwatchStatus === 3 ? response.data.strikeoffSubmitApprovalList[0].swatchCardImageName : '';
                        values[0].StrikeoffSubmissionApprovalID = response.data.strikeoffSubmitApprovalList[0].strikeoffSubmissionApprovalID;
                        //values[0].ApprovalDate = response.data.strikeoffSubmitApprovalList[0].approvalDate;
                        values[0].TNAStrikeOffRefInfoId = props.TNAStrikeOffRefInfoId;
                        values[0].StrikeoffImage = response.data.strikeoffSubmitApprovalList[0].strikeoffImage;
                        let getDateValue = new Date(response.data.strikeoffSubmitApprovalList[0].approvalDate);
                        var dd = getDateValue.getDate();
                        var mm = getDateValue.getMonth() + 1;
                        var yyyy = getDateValue.getFullYear();

                        if (dd < 10) {
                            dd = '0' + dd;
                        }
                        if (mm < 10) {
                            mm = '0' + mm;
                        }
                        let coverted = mm + '/' + dd + '/' + yyyy;


                        let getDate = new Date(response.data.strikeoffSubmitApprovalList[0].awbDate);
                        var dd = getDate.getDate();
                        var mm = getDate.getMonth() + 1;
                        var yyyy = getDate.getFullYear();

                        if (dd < 10) {
                            dd = '0' + dd;
                        }
                        if (mm < 10) {
                            mm = '0' + mm;
                        }
                        let covert = dd + '/' + mm + '/' + yyyy;





                        values[0].ApprovalDate = getDateValue;
                        values[0].ApprovalRemark = response.data.strikeoffSubmitApprovalList[0].approvalRemark;
                        values[0].SubmitSentDetails = response.data.strikeoffSubmitApprovalList[0].sendThrough === 1 ? response.data.strikeoffSubmitApprovalList[0].sendThroughName : response.data.strikeoffSubmitApprovalList[0].sendThroughName + ' '
                            + response.data.strikeoffSubmitApprovalList[0].awbName + ' ' + + (response.data.strikeoffSubmitApprovalList[0].awbDate === "" ? '/' : moment(response.data.strikeoffSubmitApprovalList[0].awbDate).format('DD/MM/YYYY'));;
                        values[0].Remarks = response.data.strikeoffSubmitApprovalList[0].remarks;
                        values[0].TNAStrikeoffSubmittedOption = response.data.strikeoffSubmittedOption;
                        values[0].TNAStrikeoffColorApprovalComments = response.data.strikeoffColorApprovalComments;
                        values[0].Approvedby = response.data.strikeoffSubmitApprovalList[0].approvedby;
                        values[0].Result = response.data.strikeoffSubmitApprovalList[0].approvalInfo;
                        if (response.data.strikeoffSubmitApprovalList[0].swatchCardImageName) {
                            let result = response.data.strikeoffSubmitApprovalList[0].swatchCardImageName.includes("Cube");
                            values[0].CubeorBuyer = result === true ? 'Cube' : 'Buyer';
                        }

                        let images = [];
                        images = response.data.strikeoffSubmitApprovalList[0].strikeOffImageList.split(',');
                        var StrikeoffImageIndex = images.indexOf(values[0].StrikeoffImage);
                        setSubImageList(images);
                        setCurrimageindex(StrikeoffImageIndex);

                        setInputFields(values);
                        let data = [...new Map(response.data.strikeoffSubmissionOrderInfoList.map(item =>
                            [item['strikeOffLogoId'], item])).values()];

                        setOrderInfoMainDetails(data);
                        setOrderinfo(response.data.strikeoffSubmissionOrderInfoList);
                        // handleExpandRow(values[0].StrikeOffId);

                    }
                }).catch(() => { });
            }
        }

        // setOrderinfo(getOrderinfo);
    }, [props.delteStrikeOffApproval === true || props.StrikeoffswatchDownload === true || props.StrikeoffswatchUpload === true || props.editStrikeOffApproval === true])
    useEffect(() => {
        setCurrentSwatchstatus(props.SwatchStatus);
        if (props.delteStrikeOffApproval === true) {
            TnaService.ViewStrikeoffSubmissionApprovalList(props.TNAStrikeOffRefInfoId, IsProduction).then((response) => {
                if (response.data) {
                    setOrderinfo(response.data.strikeoffSubmissionOrderInfoList);
                }
            }).catch(() => { });
            setID({ showPopupDelete: true, Params: props.TNAStrikeOffRefInfoId });
        }
        else if (props.editStrikeOffApproval === true) {

            let getDateValue = new Date(values[0].ApprovalDate);
            var dd = getDateValue.getDate();
            var mm = getDateValue.getMonth() + 1;
            var yyyy = getDateValue.getFullYear();

            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            values[0].ApprovalDate = getDateValue;
            setInputFields(values);

        }
        else if (props.StrikeoffswatchDownload === true) {
            TnaService.ViewStrikeoffSubmissionApprovalList(props.TNAStrikeOffRefInfoId, IsProduction).then((response) => {
                if (response.data) {
                    values[0].SwatchCardIamgePath = response.data.strikeoffSubmitApprovalList[0].swatchCardImagePath;
                    values[0].SwatchCardImageName = response.data.strikeoffSubmitApprovalList[0].swatchCardImageName;
                    setInputFields(values);
                }

            }).catch(() => { });
            downloadSwatchcard();
        }
        else if (props.StrikeoffswatchUpload === true) {
            $('#upload-button').trigger('click');

        }
    },[props.delteStrikeOffApproval === true &&  props.editStrikeOffApproval === true])
    const selector = useSelector((state) => state);
    const isSideBarEnable = selector.auth.isSideBarEnable;
    const isFullSideBarEnable = selector.auth.isFullSideBarEnable;
    const [tabStatus, setTabStatus] = useState(1);

    let BrowserWidth = isFullSideBarEnable
        ? isSideBarEnable
            ? $(window).width() - 130
            : $(window).width() + 50
        : $(window).width() + 100;

    let DynamicClassName = "col-lg-12 col-sm-12 col-xs-12";
    var ImgClassName = "upload-btn-wrapper2"


    const tabClick = (tab) => {
        setTabStatus(tab);
    }



    const handleChange = (event, feild) => {
       ;

        if (feild === "ApprovalDate") {
            if (event !== null) {
                if (new Date(event) < new Date(values[0].SubmittedDate)) {
                    Nodify("Warning!", "warning", "Approval Date should not lesser than Submit Date.");
                    values[0].ApprovalDate = '';
                }
                else {
                    values[0].ApprovalDate = event;
                }
            }

            else {
                values[0].ApprovalDate = '';
            }
            setInputFields(values);
        }
        else if (feild === "Remarks") {
            let input = '';
            if (event.target.value.trim() !== '') {
                input = event.target.value;
            }
            values[0].ApprovalRemark = input;
        }

        setInputFields(values);
    }
    const Delcallback = (value) => {
        if (value === true) {
            setID({ showPopupDelete: false, Params: {} });
            props.StrikeoffApprovalCallback(true, getOrderinfo, 'Delete');
        }
        else {
            setID({ showPopupDelete: false, Params: {} });
            props.StrikeoffApprovalCallback(false, '', 'Not A Delete', '')
        }
    }
    const handleSubmitoptionchange = (event, feild, index) => {
        let input = '';

        if (event !== null) {
            input = event.value;
            if (feild === "ApprovedOption") {

                if (input === "3") {
                    values[0].TNAStrikeoffSubmittedOption.forEach(element => {
                        element.approvedOptionName = "3";
                    })
                }
                else if (input === "4") {
                    values[0].TNAStrikeoffSubmittedOption.forEach(element => {
                        element.approvedOptionName = "4";
                    })
                }
                else {
                    values[0].TNAStrikeoffSubmittedOption.forEach(element => {
                        element.approvedOptionName = "";
                    })
                    values[0].TNAStrikeoffSubmittedOption[index].approvedOptionName = input;
                }
            }
        }
        else {
            values[0].TNAStrikeoffSubmittedOption.forEach(element => {
                element.approvedOptionName = "";
            })
        }

        setInputFields(values);


    }

    const handleExpandRowMultiSelect = (strikeoffLogoPath, list) => {
        setExpandedRowsMultiSelect([]);
        setexpandListMultiSelect(list.filter(x => x.strikeoffLogoPath === strikeoffLogoPath));
        let currentExpandedRows = expandedRowsMultiSelect;
        const isRowExpanded = currentExpandedRows.includes(strikeoffLogoPath);
        let obj = {};
        isRowExpanded ? (obj[strikeoffLogoPath] = false) : (obj[strikeoffLogoPath] = true);
        setExpandStateMultiSelect(obj);
        currentExpandedRows = [];
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== strikeoffLogoPath) :
            currentExpandedRows.concat(strikeoffLogoPath);
        setExpandedRowsMultiSelect(newExpandedRows);


    }
    const handleExpandRow = (StrikeoffId) => {
        setExpandedRows([]);
        setexpandList(getOrderinfo.filter(x => x.strikeoffId === StrikeoffId));
        let currentExpandedRows = expandedRows;
        const isRowExpanded = currentExpandedRows.includes(StrikeoffId);
        let obj = {};
        isRowExpanded ? (obj[StrikeoffId] = false) : (obj[StrikeoffId] = true);
        setExpandState(obj);
        currentExpandedRows = [];
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== StrikeoffId) :
            currentExpandedRows.concat(StrikeoffId);
        setExpandedRows(newExpandedRows);


    }

    const previmage = () => {
        if (getCurrimageindex === 0) {
            setCurrimageindex(0);
        }
        else {
            setCurrimageindex(getCurrimageindex - 1);
        }
    }

    const nextimage = () => {
        if (getCurrimageindex === getSubImageList.length - 1) {
            setCurrimageindex(getSubImageList.length - 1);
        }
        else {
            setCurrimageindex(getCurrimageindex + 1);
        }
    }
    const handleRemoveOrderinfo = (index) => {
        const value = [...getOrderinfo]
        value.splice(index, 1);
        setOrderinfo(value);

    }
    const OnChangeOrderinfo = (event, index) => {
        const orderinfo = [...getOrderinfo];
        let SelectedCount = 0;
        orderinfo.forEach(element => {
            if (element.SelectedDetail === 1) {
                SelectedCount = SelectedCount + 1;
            }
        })
        if (event.target.checked === true) {
            if (SelectedCount === 1) {
                orderinfo[index].SelectedDetail = 0;
                Nodify('Warning!', 'warning', 'Please select only one.');
            }
            else {
                orderinfo[index].SelectedDetail = 1;
            }
        }
        else {
            orderinfo[index].SelectedDetail = 0;
        }
        setOrderinfo(orderinfo);

        let SelectedDetail = '';
        let SelectedRef = '';

        orderinfo.forEach(element => {
            if (element.SelectedDetail === 1) {
                SelectedDetail = element.tnaStrikeOffRefInfoId;
                SelectedRef = element.strikeOffReferenceId;
            }
        })
        TnaService.GetStrikeoffDimension(2, SelectedDetail, IsProduction).then((response) => {
            if (response.data) {
                setStrikeoffDimension(response.data);
            }

        }).catch(() => { });

        values[0].StrikOffReferenceId = SelectedRef;
        setInputFields(values);
    }

    const SaveApproval = () => {
        let IsValid = true;
        if (values[0].ApprovalDate === '') {
            IsValid = false;

        }
        var approvecount = 0;
        values[0].TNAStrikeoffSubmittedOption.forEach(element => {
            // if (element.approvedOptionName === '0') {
            if (element.approvedOptionName !== '0' && element.approvedOptionName !== "") {
                approvecount = approvecount + 1;
            }
            // }
        })
        if (IsValid === false) {
            Nodify("Warning!", "warning", "Please fill the mandatory(*) fields.");
            setSubmitted(true);
        }
        else if (approvecount === 0) {
            Nodify("Warning!", "warning", "Please fill atleast one Approved Option.");
            setSubmitted(true);
        }
        else {
            values[0].SubmittedDate = new Date(values[0].SubmittedDate.setMinutes(values[0].SubmittedDate.getMinutes() + 370))
            values[0].ApprovalDate = new Date(values[0].ApprovalDate.setMinutes(values[0].ApprovalDate.getMinutes() + 370))
            values[0].SubmissionApprovalStatus = 2;
            values[0].Operation = props.props.location.pathname !== '/TNAEdit' ? 1 : props.editStrikeOffApproval === false || props.editStrikeOffApproval === undefined ? 1 : 2;
            values[0].TNAStrikeoffOrderInfo = getOrderinfo;
            values[0].Createdby = currentUser.employeeinformationID;
            TnaService.InsertUpdateStrikeoffSubmissionApproval(values[0]).then(res => {
                if (res.data.outputResult === "1") {
                    Nodify('Success!', 'success', 'Strike Off Approval Added Successfully');
                    props.StrikeoffApprovalCallback(true, getOrderinfo, 'Save', CurrentSwatchstatus,0)
                } else if (res.data.outputResult === "2") {
                    Nodify('Success!', 'success', 'Strike Off Approval Updated Successfully');
                    props.StrikeoffApprovalCallback(true, getOrderinfo, 'Update', CurrentSwatchstatus)
                }
                else if (res.data.outputResult === "0") {
                    Nodify("Error!", "Error Occured!");
                }
            });
        }

    }

    const OnchangeColorComments = (event, index) => {
        let input = '';
        if (event.target.value.trim() !== '') {
            input = event.target.value;
        }
        values[0].TNAStrikeoffColorApprovalComments[index].approvalComments = input;
        setInputFields(values);
    }

    // const OnSelectRecords = (event, index) => {
    //     const value = [...MultiCloseDatas];

    //     if (event.target.checked === true) {
    //         value[index].isSelected = 1;
    //     }
    //     else {
    //         value[index].isSelected = 0;
    //         setselectall(0);
    //     }
    //     setMultiCloseDatas(value);
    // }

    // const SelectAll = (event, StrikeoffLogoPath) => {
    //     let IsSelected = 0;

    //     if (event.target.checked === true) {
    //         IsSelected = 1;
    //         MultiCloseDatas.filter(x => x.strikeoffLogoPath === StrikeoffLogoPath).forEach(element => {
    //             element.isSelected = 1;
    //         })
    //     }
    //     else {
    //         IsSelected = 0;
    //         MultiCloseDatas.filter(x => x.strikeoffLogoPath === StrikeoffLogoPath).forEach(element => {
    //             element.isSelected = 0;
    //         })
    //     }
    //     setselectall(IsSelected);
    // }

    // const AddRows = () => {
    //     let FileteredRecords = MultiCloseDatas.filter(x => x.isSelected === 1);
    //     if (FileteredRecords.length === 0) {
    //         Nodify("Warning!", "warning", "Select atleast one row.");
    //     }
    //     else {
    //         setshowMultiClosediv(false);
    //         setshowSubmissiondiv(true);
    //         // let data = [...new Map(FileteredRecords.map(item =>
    //         //     [item['strikeoffId'], item])).values()];
    //         let index = 0;
    //         FileteredRecords.forEach(element => {
    //             index = index + 1;
    //             element.index = index;
    //         })


    //         let data = [...new Map(FileteredRecords.map(item =>
    //             [item['strikeOffLogoId'], item])).values()];

    //         setOrderInfoMainDetails(data);
    //         setOrderinfo(FileteredRecords);
    //         handleExpandRow(FileteredRecords[0].strikeoffLogoPath, FileteredRecords);
    //     }
    // }

    // const ShowHideDivs = () => {
    //     setshowMultiClosediv(true);
    //     setshowSubmissiondiv(false);
    // }

    const openFileUpload = () => {
        $('#upload-button').trigger('click');
    }

    const UploadImages = async (e) => {
        const files = e.target.files;
        var file = null;
        var Firstfile = files[0];
        const formData = new FormData();
        for (var i = 0; i < files.length; i++) {
            file = files[i];
            if (file !== null && file !== '' && file !== undefined) {
                const fileType = file['type'];
                const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png'];
                if (!validImageTypes.includes(fileType)) {
                    Nodify('Warning!', 'warning', 'Invalid file format selected');
                    $('#FileUpload').val("");
                }
                else {

                    formData.append("FormFile", file);
                    formData.append("FileName", "Approved_" + file.name);
                    formData.append("Page", "StrikeoffSwatchCardPdf");
                    try {
                        // const res = await axios.get(window.$APIBaseURL + "api/file/PdfImagesUPload", formData);
                        swatchcardimages.push({
                            src: "Images/StrikeoffSwatchCardPdf/Approved_" + file.name,
                            height: ''
                        })


                    } catch (ex) {
                        console.log(ex);
                    }
                }
            }
        }
        const res = await axios.post(window.$APIBaseURL + "api/file/StrikeoffSwatchCardUpload", formData);
        var FileName = Firstfile.name.substr(0, Firstfile.name.lastIndexOf('.'));
        values[0].SwatchCardImageName = "Approved_" + Firstfile.name;
        values[0].SwatchCardIamgePath = "Images/StrikeoffSwatchCardPdf/Approved_" + Firstfile.name;
        setInputFields(values);
        $('#FileUpload').val("");
        setshowswatchcardimages(true);
        UploadStrikeoffSwatchCardPdf(FileName);
    }

    const UploadStrikeoffSwatchCardPdf = (FileName) => {
        var timeout = 5000;
        showLoader();
        const doc = new jsPDF('p', 'pt', 'a4');
        const Page1 = document.getElementById("UploadPage1");
        html2canvas(Page1, { scale: 5 }).then(canvas => {
            var img = new Image();

            img.src = window.$APIBaseURL + swatchcardimages[0].src;
            const imgWidth = 595;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');



            if (document.getElementById("UploadPage2") !== null) {
                const Page2 = document.getElementById("UploadPage2");
                html2canvas(Page2, { scale: 5 }).then(canvas => {
                    var img = new Image();
                    img.src = window.$APIBaseURL + swatchcardimages[1].src;
                    const imgWidth = 595;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    doc.addPage();
                    doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');


                })
            }
            if (document.getElementById("UploadPage3") !== null) {
                const Page3 = document.getElementById("UploadPage3");
                html2canvas(Page3, { scale: 5 }).then(canvas => {
                    var img = new Image();
                    img.src = window.$APIBaseURL + swatchcardimages[2].src;
                    const imgWidth = 595;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    doc.addPage();
                    doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');


                })
            }
            if (document.getElementById("UploadPage4") !== null) {
                const Page4 = document.getElementById("UploadPage4");
                html2canvas(Page4, { scale: 5 }).then(canvas => {
                    var img = new Image();
                    img.src = window.$APIBaseURL + swatchcardimages[3].src;
                    const imgWidth = 595;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    doc.addPage();
                    doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');


                })
            }

            if (document.getElementById("UploadPage5") !== null) {
                const UploadPage5 = document.getElementById("UploadPage5");
                html2canvas(UploadPage5, { scale: 5 }).then(canvas => {
                    var img = new Image();
                    img.src = window.$APIBaseURL + swatchcardimages[4].src;
                    const imgWidth = 595;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    doc.addPage();
                    doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');


                })
            }

            if (document.getElementById("UploadPage6") !== null) {
                timeout = 10000;
                const UploadPage6 = document.getElementById("UploadPage6");
                html2canvas(UploadPage6, { scale: 5 }).then(canvas => {
                    var img = new Image();
                    img.src = window.$APIBaseURL + swatchcardimages[5].src;
                    const imgWidth = 595;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    doc.addPage();
                    doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');


                })
            }

            if (document.getElementById("UploadPage7") !== null) {
                const UploadPage7 = document.getElementById("UploadPage7");
                html2canvas(UploadPage7, { scale: 5 }).then(canvas => {
                    var img = new Image();
                    img.src = window.$APIBaseURL + swatchcardimages[6].src;
                    const imgWidth = 595;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    doc.addPage();
                    doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');


                })
            }

            if (document.getElementById("UploadPage8") !== null) {
                const UploadPage8 = document.getElementById("UploadPage8");
                html2canvas(UploadPage8, { scale: 5 }).then(canvas => {
                    var img = new Image();
                    img.src = window.$APIBaseURL + swatchcardimages[7].src;
                    const imgWidth = 595;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    doc.addPage();
                    doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');


                })
            }

            if (document.getElementById("UploadPage9") !== null) {
                const UploadPage9 = document.getElementById("UploadPage9");
                html2canvas(UploadPage9, { scale: 5 }).then(canvas => {
                    var img = new Image();
                    img.src = window.$APIBaseURL + swatchcardimages[8].src;
                    const imgWidth = 595;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    doc.addPage();
                    doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');


                })
            }

            if (document.getElementById("UploadPage10") !== null) {
                const UploadPage10 = document.getElementById("UploadPage10");
                html2canvas(UploadPage10, { scale: 5 }).then(canvas => {
                    var img = new Image();
                    img.src = window.$APIBaseURL + swatchcardimages[9].src;
                    const imgWidth = 595;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    doc.addPage();
                    doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');


                })
            }


            setTimeout(() => {

                //  var file = doc.output("blob");
                const file = new File([doc.output("blob")], "filename.pdf", { type: "image/jpg" });
                //  doc.save("LabdipSwatchCard_" + props.LabdibFabricInfoId + ".pdf");
                hideLoader();
                setshowswatchcardimages(false);

                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", "Approved_" + FileName + ".pdf");
                formData.append("Page", "StrikeoffSwatchCardPdf");
                const res = axios.post(window.$APIBaseURL + "api/file", formData);
                TnaService.UpdateStrikeoffSwatchStatus(props.TNAStrikeOffRefInfoId, 3, values[0].SwatchCardImageName, values[0].SwatchCardIamgePath, 2).then((res) => {
                    if (res.data) {
                        props.StrikeoffApprovalCallback(true, getOrderinfo, 'upload', res.data.outputResult)

                    }
                })
            }, timeout);

        });
    }

    const downloadSwatchcard = async () => {
        var FileName = values[0].SwatchCardImageName.substr(0, values[0].SwatchCardImageName.lastIndexOf('.'));
        const url = window.$APIBaseURL + 'Images/StrikeoffSwatchCardPdf/' + FileName + '.pdf'
        //  const url = window.$APIBaseURL + 'Images/LabdipSwatchCardPdf/' + FileName + '.pdf'
        const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())

        const pdfDoc = await PDFDocument.load(existingPdfBytes)
        const timesnewromanbold = await pdfDoc.embedFont(StandardFonts.TimesRomanBold);
        const timesnewroman = await pdfDoc.embedFont(StandardFonts.TimesRoman);
        const HelveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

        const pages = pdfDoc.getPages()
        var ResultText = [];
        if (values[0].Result.includes('with')) {
            ResultText = values[0].Result.split('with')
        }
        var approvalremarks = values[0].ApprovalRemark !== null ? values[0].ApprovalRemark : '';

        if (values[0].CubeorBuyer === 'Cube') {

            for (var i = 0; i < pages.length; i++) {
                pages[i].drawText('Approved on', {
                    x: 36,
                    y: 636,
                    size: 10,
                    font: timesnewromanbold,
                    color: rgb(0, 0, 0),
                })

                pages[i].drawText(" : " + values[0].ApprovalDate, {
                    x: 136,
                    y: 636,
                    size: 10,
                    font: timesnewroman,
                    color: rgb(0, 0, 0),
                })

                pages[i].drawText('Approved by', {
                    x: 195,
                    y: 636,
                    size: 10,
                    font: timesnewromanbold,
                    color: rgb(0, 0, 0),
                })

                pages[i].drawText(": " + values[0].Approvedby, {
                    x: 252,
                    y: 636,
                    size: 10,
                    font: timesnewroman,
                    color: rgb(0, 0, 0),
                })

                pages[i].drawText('Result', {
                    x: 370,
                    y: 636,
                    size: 10,
                    font: timesnewromanbold,
                    color: rgb(0, 0, 0),
                })

                // if (ResultText.length === 0) {
                pages[i].drawText(": " + values[0].Result, {
                    x: 400,
                    y: 636,
                    size: 10,
                    font: timesnewroman,
                    color: rgb(0, 0, 0),
                })
                //}
                // else {

                //     pages[i].drawText(": " + ResultText[0] + " with", {
                //         x: 370,
                //         y: 647,
                //         size: 10,
                //         font: timesnewroman,
                //         color: rgb(0, 0, 0),
                //     })

                //     pages[i].drawText(ResultText[1], {
                //         x: 370,
                //         y: 635,
                //         size: 10,
                //         font: timesnewroman,
                //         color: rgb(0, 0, 0),
                //     })

                // }

                pages[i].drawText(approvalremarks, {
                    x: 155,
                    y: 104,
                    size: 10,
                    font: timesnewroman,
                    color: rgb(0, 0, 0),
                })

                const jpgUrl = window.$APIBaseURL + 'Images/LabdipSwatchCardPdf/BlankSpace.png'
                const jpgImageBytes = await fetch(jpgUrl).then((res) => res.arrayBuffer())

                const jpgImage = await pdfDoc.embedPng(jpgImageBytes)
                const jpgDims = jpgImage.scale(0.5)

                pages[i].drawImage(jpgImage, {
                    x: 217,
                    y: 765,
                    width: 210,
                    height: 20
                })

                pages[i].drawText('STRIKE OFF APPROVAL FORM', {
                    x: 225,
                    y: 765,
                    size: 16,
                    font: HelveticaBold,
                    color: rgb(0, 0, 0),
                })
            }
        }
        else {
            for (var i = 0; i < pages.length; i++) {
                pages[i].drawText('Approved on  ', {
                    x: 30,
                    y: 630,
                    size: 11,
                    font: timesnewromanbold,
                    color: rgb(0, 0, 0),
                })

                pages[i].drawText(" : " + values[0].ApprovalDate, {
                    x: 95,
                    y: 630,
                    size: 11,
                    font: timesnewroman,
                    color: rgb(0, 0, 0),
                })

                pages[i].drawText('Approved by ', {
                    x: 160,
                    y: 630,
                    size: 11,
                    font: timesnewromanbold,
                    color: rgb(0, 0, 0),
                })

                pages[i].drawText(": " + values[0].Approvedby, {
                    x: 225,
                    y: 630,
                    size: 11,
                    font: timesnewroman,
                    color: rgb(0, 0, 0),
                })

                pages[i].drawText('Result', {
                    x: 335,
                    y: 630,
                    size: 11,
                    font: timesnewromanbold,
                    color: rgb(0, 0, 0),
                })

                pages[i].drawText(": " + values[0].Result, {
                    x: 370,
                    y: 630,
                    size: 11,
                    font: timesnewroman,
                    color: rgb(0, 0, 0),
                })

                const jpgUrl = window.$APIBaseURL + 'Images/LabdipSwatchCardPdf/BlankSpaceBlack.png'
                const jpgImageBytes = await fetch(jpgUrl).then((res) => res.arrayBuffer())

                const jpgImage = await pdfDoc.embedPng(jpgImageBytes)
                const jpgDims = jpgImage.scale(0.5)

                pages[i].drawImage(jpgImage, {
                    x: 250,
                    y: 788,
                    width: 200,
                    height: 30
                })

                pages[i].drawText('APPROVAL FORM', {
                    x: 250,
                    y: 788,
                    size: 20,
                    font: HelveticaBold,
                    color: rgb(1, 1, 1)
                })

                pages[i].drawText(approvalremarks, {
                    x: 115,
                    y: 93,
                    size: 11,
                    font: timesnewroman,
                    color: rgb(0, 0, 0)
                })
            }
        }

        const pdfBytes = await pdfDoc.save()
        download(pdfBytes, FileName + ".pdf", "application/pdf");
        window.open(url, "_blank");
    }

    const CustomInputApproval = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
                style={{
                    color:
                        props.value === ""
                            ? "#bfdea1"
                            : "#000",
                    border:
                        submitted &&
                            props.value === ""
                            ? "1px solid red"
                            : "",
                }}
            />
        )
    }



    return (

        <div id="registration-form">
            <Form>
                {

                    <>  <div className="row" style={{ overflowX: "hidden", height: "700px", padding: "1px", margin: "0px" }}>
                        <div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <div className='form-group'>
                                        <label htmlFor="BuyerID">Buyer-Season-Brand</label>
                                        <span className='input-icon icon-right'>
                                            <Input
                                                placeholder="BuyerSeasonBrand"
                                                className="form-control cut_text"
                                                title={inputFields[0].BuyerBrandSeasonName}
                                                name="BuyerSeasonBrand"
                                                id={
                                                    "BuyerSeasonBrand"
                                                }
                                                value={
                                                    inputFields[0].BuyerBrandSeasonName
                                                }
                                                disabled
                                            ></Input>
                                        </span>
                                    </div>
                                </div>

                                <div className="col-sm-3">
                                    <div className='form-group'>
                                        <label htmlFor="SupplierID" >Supplier</label>
                                        <span className='input-icon icon-right'>
                                            <Input
                                                placeholder="Supplier"
                                                className="form-control cut_text"
                                                title={inputFields[0].SupplierName}
                                                name="SupplierID"
                                                id={
                                                    "SupplierID"
                                                }
                                                value={
                                                    inputFields[0].SupplierName
                                                }
                                                disabled

                                            ></Input>
                                        </span>
                                    </div>
                                </div>

                                <div className="col-sm-3">
                                    <div className='form-group'>
                                        <label htmlFor="SubmittedDate" >Submit Date<span className="text-danger">*</span></label>
                                        <span className='input-icon icon-right'>

                                            <DatePicker className="form-control" name="SubmittedDate"
                                                id={
                                                    "SubmittedDate"
                                                }
                                                selected={
                                                    inputFields[0].SubmittedDate
                                                }

                                                dateFormat="dd/MM/yyyy"
                                                peekNextMonth

                                                // customInput={<CustomInput />}
                                                disabled
                                            />


                                            {/* <Input
                                                placeholder="SubmittedDate"
                                                className="form-control cut_text"
                                                title={inputFields[0].SubmittedDate}
                                                name="SubmittedDate"
                                                id={
                                                    "SubmittedDate"
                                                }
                                                value={
                                                    inputFields[0].SubmittedDate
                                                }
                                                disabled

                                            ></Input> */}

                                        </span>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label htmlFor={"Quarter"}>Approval Date<span className="text-danger">*</span></label>
                                        <span className="input-icon icon-right">
                                            {props.TaskStatus === 3 && (props.props.location.pathname) !== '/TNAEdit' ?
                                                <DatePicker className="form-control" name="SubmittedDate"
                                                    id={
                                                        "SubmittedDate"
                                                    }
                                                    selected={
                                                        inputFields[0].ApprovalDate
                                                    }

                                                    dateFormat="dd/MM/yyyy"
                                                    peekNextMonth

                                                    customInput={<CustomInputApproval />}
                                                    disabled
                                                /> :
                                                props.TaskStatus === 3 && props.editStrikeOffApproval === false ?
                                                    <DatePicker className="form-control" name="SubmittedDate"
                                                        id={
                                                            "SubmittedDate"
                                                        }
                                                        selected={
                                                            inputFields[0].ApprovalDate
                                                        }

                                                        dateFormat="dd/MM/yyyy"
                                                        peekNextMonth

                                                        customInput={<CustomInputApproval />}
                                                        disabled
                                                    />
                                                    :

                                                    <DatePicker className="form-control" name="ApprovalDate"
                                                        id={
                                                            "ApprovalDate"
                                                        }
                                                        selected={
                                                            inputFields[0].ApprovalDate === '' ? '' : inputFields[0].ApprovalDate
                                                        }
                                                        onChange={event => handleChange(event, 'ApprovalDate')}
                                                        dateFormat="dd/MM/yyyy"
                                                        peekNextMonth
                                                        style={{
                                                            color:
                                                                inputFields[0].ApprovalDate === ""
                                                                    ? "#bfdea1"
                                                                    : "#000",
                                                            border:
                                                                submitted &&
                                                                    inputFields[0].ApprovalDate === ""
                                                                    ? "1px solid red"
                                                                    : "",
                                                        }}
                                                        dropdownMode="scroll"
                                                        autoComplete="off"
                                                        customInput={<CustomInputApproval />}
                                                        isClearable={inputFields[0].ApprovalDate === null ? false : true}
                                                    />
                                                // <input id="ApprovalDate" name="ApprovalDate"
                                                //     value={inputFields[0].ApprovalDate}
                                                //     placeholder="DD/MM/YYYY"
                                                //     type="date"
                                                //     min={minApprovalDate}
                                                //     autoComplete="off"
                                                //     className={'form-control'}
                                                //     onKeyDown={(e) => e.preventDefault()}
                                                //     style={{
                                                //         color:
                                                //             inputFields[0].ApprovalDate === ""
                                                //                 ? "#bfdea1"
                                                //                 : "#000",
                                                //         border:
                                                //             submitted &&
                                                //                 inputFields[0].ApprovalDate === ""
                                                //                 ? "1px solid red"
                                                //                 : "",
                                                //     }}
                                                //     onChange={event => handleChange(event, 'ApprovalDate')}
                                                // />



                                            }


                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-3">
                                    <div className='form-group'>
                                        <label htmlFor="BuyerID">Style Name  <span className="text-danger">*</span></label>
                                        <span className='input-icon icon-right'>
                                            <Input
                                                placeholder="StyleName"
                                                className="form-control cut_text"
                                                title={inputFields[0].StyleName}
                                                name="StyleName"
                                                id={
                                                    "StyleName"
                                                }
                                                value={
                                                    inputFields[0].StyleName
                                                }
                                                disabled
                                            ></Input>
                                        </span>
                                    </div>
                                </div>

                                <div className="col-sm-3">
                                    <div className='form-group'>
                                        <label htmlFor="SeasonID" >Style No  <span className="text-danger">*</span></label>
                                        <span className='input-icon icon-right'>
                                            <Input
                                                placeholder="StyleNo"
                                                className="form-control cut_text"
                                                title={inputFields[0].StyleNo}
                                                name="StyleNo"
                                                id={
                                                    "StyleNo"
                                                }
                                                value={
                                                    inputFields[0].StyleNo
                                                }

                                                disabled
                                            ></Input>
                                        </span>
                                    </div>
                                </div>

                                <div className="col-sm-3">
                                    <div className='form-group'>
                                        <label htmlFor="Name" > PO/ID No  <span className="text-danger">*</span></label>
                                        <span className='input-icon icon-right'>
                                            <Input
                                                placeholder="PO/ID No"
                                                className="form-control cut_text"
                                                title={inputFields[0].IdpoNo}
                                                name="IDPONo"
                                                id={
                                                    "IDPONo"
                                                }
                                                value={
                                                    inputFields[0].IdpoNo
                                                }

                                                disabled
                                            ></Input>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label htmlFor={"EndCustomer"}>End Customer  <span className="text-danger">*</span></label>
                                        <span className="input-icon icon-right">

                                            <Input
                                                placeholder="End Customer"
                                                className="form-control cut_text"
                                                title={inputFields[0].EndCustomer}
                                                name="EndCustomer"
                                                id={
                                                    "EndCustomer"
                                                }
                                                value={
                                                    inputFields[0].EndCustomer
                                                }
                                                disabled

                                            ></Input>

                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">

                                <div className="col-sm-3">
                                    <div className='form-group'>
                                        <label htmlFor="Submitsentdetails" >Courier Information</label><span className="text-danger">*</span>
                                        <span className='input-icon icon-right'>
                                            <Input
                                                placeholder="Submit Sent Details"
                                                className="form-control cut_text"
                                                title={inputFields[0].SubmitSentDetails}
                                                name="Submitsentdetails"
                                                id={
                                                    "Submitsentdetails"
                                                }
                                                value={
                                                    inputFields[0].SubmitSentDetails
                                                }
                                                disabled

                                            ></Input>
                                        </span>
                                    </div>
                                </div>


                            </div>
                        </div>


                        <div className="row">
                            <div className="col-md-12">
                                <div className="tabbable">
                                    <ul className="nav nav-tabs nav-justified" id="myTab5">
                                        <li className="active" onClick={() => tabClick(1)}>
                                            <a data-toggle="tab" href="#idSubmitInformation">
                                                <h5>Approval Information</h5>
                                            </a>
                                        </li>
                                        <li className="" onClick={() => tabClick(2)} >
                                            <a data-toggle="tab" href="#idOrderInformation" >

                                                <h5>Order Information</h5>
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div id="idSubmitInformation" className="tab-pane in active" style={{ width: "100%" }}>
                                            <div className="row">
                                                <div className="col-sm-10">
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <div className='form-group'>
                                                                <label htmlFor="BuyerID">Stikeoff Code</label>
                                                                <span className='input-icon icon-right'>
                                                                    <Input
                                                                        placeholder="Stike off Code"
                                                                        className="form-control cut_text"
                                                                        title={inputFields[0].StrikeoffCode}
                                                                        name="StrikeoffCodeandName"
                                                                        id={
                                                                            "StrikeoffCodeandName"
                                                                        }
                                                                        value={
                                                                            inputFields[0].StrikeoffCode
                                                                        }
                                                                        disabled
                                                                    ></Input>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-3">
                                                            <div className='form-group'>
                                                                <label htmlFor="SeasonID" >Strikeoff Name</label>
                                                                <span className='input-icon icon-right'>
                                                                    <Input
                                                                        placeholder="Name"
                                                                        className="form-control cut_text"
                                                                        title={inputFields[0].StrikeoffName}
                                                                        name="StrikeName"
                                                                        id={
                                                                            "StrikeName"
                                                                        }
                                                                        value={
                                                                            inputFields[0].StrikeoffName
                                                                        }

                                                                        disabled
                                                                    ></Input>
                                                                </span>
                                                            </div>
                                                        </div>



                                                        <div className="col-sm-3">
                                                            <div className="form-group">
                                                                <label htmlFor={"Quarter"}>Quality</label>
                                                                <span className="input-icon icon-right">

                                                                    <Input
                                                                        placeholder="Quality"
                                                                        className="form-control cut_text"
                                                                        title={inputFields[0].Quality}
                                                                        name="StrikeOffQuality"
                                                                        id={
                                                                            "StrikeOffQuality"
                                                                        }
                                                                        value={
                                                                            inputFields[0].Quality
                                                                        }

                                                                        disabled
                                                                    ></Input>

                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-3">

                                                            <label htmlFor={"Quarter"} style={{marginLeft:"50px"}}>Stike off Image</label>
                                                            <br />
                                                            {/* <i className="fa fa-chevron-circle-left fa-2x" onClick={() => previmage()} style={{ cursor: "pointer" }}></i> */}
                                                            {
                                                                getSubImageList.length !== 0 ?
                                                                    <img src={window.$APIBaseURL + "Images/Style/Strikeoff/" + getSubImageList[getCurrimageindex]} alt="No Image" width="150px" height="150px" style={{ border: "2px solid black" }} />
                                                                    :
                                                                    <div align="center" style={{ width: "150px", height: "150px" }}>
                                                                        No Images Found
                                                                    </div>
                                                            }
                                                            {/* <i className="fa fa-chevron-circle-right fa-2x" onClick={() => nextimage()} style={{ cursor: "pointer" }}></i> */}




                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label htmlFor={"ReceivedOn"}>Strike Off Dimension<span className="text-danger">*</span></label>
                                                                <select id={"StrikOffReferenceID"} name="StrikOffReferenceID" className="form-select"
                                                                    value={inputFields[0].StrikOffReferenceID}
                                                                    style={{ border: submitted && inputFields[0].StrikOffReferenceID === 0 ? '1px solid red' : '' }}
                                                                    disabled
                                                                >
                                                                    <option value="0">-Select StrikeOff-</option>
                                                                    {
                                                                        StrikeoffDimension.map(item => (
                                                                            <option key={item.id} value={item.value}>
                                                                                {item.label}
                                                                            </option>
                                                                        ))
                                                                    }

                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-lg-12">
                                                        <div className="row">
                                                            <div className="col-sm-8 col-lg-8" style={{ paddingLeft: "0" }}>
                                                                <div className='form-group'>
                                                                    <label htmlFor="Remarks">Submitted Remarks</label>
                                                                    <textarea maxLength="300" name="Remarks" value={inputFields[0].Remarks}
                                                                        rows="4" style={{ width: "100%" }}
                                                                        disabled
                                                                    ></textarea>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div>
                                                        <table>
                                                            <tr>
                                                                <td><label>Submit Options</label> </td>
                                                                <td><label>Approval Submission Card
                                                                    <br></br>(Click to download)</label></td>
                                                            </tr>
                                                            <tr>
                                                                <td> <div className="col-sm-9" style={{ paddingLeft: "0" }}>
                                                                    <div className="form-group">


                                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup"
                                                                            style={{ width: "700px" }}>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th >Options <span className="text-danger">*</span></th>
                                                                                    <th>Supplier Reference No <span className="text-danger">*</span></th>
                                                                                    <th>Approved Option <span className="text-danger">*</span></th>

                                                                                </tr>

                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    inputFields[0].TNAStrikeoffSubmittedOption.map((item, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <span className="input-icon icon-right">
                                                                                                            <Input
                                                                                                                placeholder="Option Name"
                                                                                                                className="form-control"
                                                                                                                title={item.optionName}
                                                                                                                name="OptionName"
                                                                                                                id={
                                                                                                                    "OptionName"
                                                                                                                }
                                                                                                                value={
                                                                                                                    item.optionName
                                                                                                                }
                                                                                                                onChange={event => (handleSubmitoptionchange(event, 'OptionName', index))}
                                                                                                                disabled
                                                                                                            ></Input>


                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <span className='input-icon icon-right'>
                                                                                                            <Input
                                                                                                                placeholder=""
                                                                                                                className="form-control"
                                                                                                                title={item.supplierReferenceNo}
                                                                                                                name="Sendto"
                                                                                                                id={
                                                                                                                    "Sendto"
                                                                                                                }
                                                                                                                value={
                                                                                                                    item.supplierReferenceNo
                                                                                                                }
                                                                                                                onChange={event => (handleSubmitoptionchange(event, 'SupplierReferenceNo', index))}
                                                                                                                disabled
                                                                                                            ></Input>
                                                                                                        </span>

                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <span className='input-icon icon-right'>
                                                                                                            <Reactselect className="basic-single" name="TaskHolderOrFollower"
                                                                                                                id={
                                                                                                                    "TaskHolderOrFollower"
                                                                                                                }
                                                                                                                isLoading={false}
                                                                                                                isClearable={true}
                                                                                                                isSearchable={false}
                                                                                                                onChange={event => handleSubmitoptionchange(event, 'ApprovedOption', index)}
                                                                                                                value={getApprovedOptions.filter(function (option) {
                                                                                                                    return option.value === item.approvedOptionName;
                                                                                                                })}
                                                                                                                options={getApprovedOptions}
                                                                                                                // style={
                                                                                                                //     submitted && item.approvedOptionName === '0' ? styles : ''
                                                                                                                // }
                                                                                                                styles={
                                                                                                                    submitted && item.approvedOptionName === "0" ? styles2 : styles1
                                                                                                                }
                                                                                                                menuPosition="fixed"
                                                                                                                isDisabled={props.TaskStatus === 3 && (props.props.location.pathname !== '/TNAEdit') ? true : props.TaskStatus === 3 && props.editStrikeOffApproval === false ? true : false}
                                                                                                            ></Reactselect>
                                                                                                        </span>

                                                                                                    </td>

                                                                                                </tr>
                                                                                            </>

                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div></td>
                                                                <td>
                                                                    <div className={ImgClassName}
                                                                        htmlFor="upload-button"
                                                                    >

                                                                        {
                                                                            props.SwatchStatus === 3 ?
                                                                                <img
                                                                                    src={inputFields[0].SwatchCardIamgePath === "" || inputFields[0].SwatchCardIamgePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images/StrikeoffSwatchCardPdf/" + inputFields[0].SwatchCardImageName}
                                                                                    onClick={downloadSwatchcard}
                                                                                    alt="AltPicture" style={{ marginBottom: '5px', height: '200px', width: '200px', border: "1px solid #d5d5d5" }}
                                                                                />
                                                                                :
                                                                                <>
                                                                                    <img
                                                                                        src={"assets/img/emptyImage.jpg"}
                                                                                        // src={"assets/img/emptyImage.jpg"}
                                                                                        onClick={openFileUpload}
                                                                                        alt="AltPicture" style={{ marginBottom: '5px', height: '200px', width: '200px', border: "1px solid #d5d5d5" }} />
                                                                                    <input type="file" id="upload-button" accept=".jpg,.jpeg,.png" onChange={(event) => UploadImages(event)} value=''
                                                                                        disabled={(props.StrikeoffswatchUpload === true) ? false : true}
                                                                                        multiple
                                                                                    //disabled={props.TaskStatus === 3 && (props.editLabdipSubmission === false || chkDataExists === true) ? true : false}
                                                                                    />
                                                                                </>

                                                                        }

                                                                        {
                                                                            inputFields.SwatchImageFileName !== "assets/img/uploadimage.jpg" && (
                                                                                <div style={
                                                                                    {
                                                                                        marginTop: '8px',
                                                                                        float: 'right',
                                                                                        bottom: '10px',
                                                                                        position: 'relative',
                                                                                    }
                                                                                }>


                                                                                </div>
                                                                            )
                                                                        }

                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>




                                                        <div> {
                                                            isOpen && (

                                                                <Lightbox mainSrc={window.$APIBaseURL + 'Images/SwatchImages/' + getImagename}
                                                                    alt="bg image"
                                                                    onCloseRequest={
                                                                        () => setIsOpen(false)
                                                                    } />
                                                            )
                                                        } </div>

                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-group">
                                                                <label>Color Approval Comments</label>
                                                                {

                                                                    values[0].TNAStrikeoffColorApprovalComments.length !== 0 ?
                                                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                            <thead>
                                                                                <tr>
                                                                                    <td>Strike Off Colors</td>
                                                                                    <td>Approval Comments</td>

                                                                                </tr>

                                                                            </thead>

                                                                            <tbody>
                                                                                {
                                                                                    values[0].TNAStrikeoffColorApprovalComments.map((data, index) => (
                                                                                        <Fragment>

                                                                                            <tr>
                                                                                                <td>
                                                                                                    <div className="row">
                                                                                                        <div className="col-lg-7">
                                                                                                            {data.pantoneName}
                                                                                                        </div>
                                                                                                        <div className="col-lg-5">

                                                                                                            {
                                                                                                                data.pantoneName.includes("Others") !== true ?
                                                                                                                    <>
                                                                                                                        {/* <label htmlFor={"Quarter"}>Color / Image</label> */}
                                                                                                                        {
                                                                                                                            data.pantoneColor !== '' ?
                                                                                                                                <div style={{ background: data.pantoneColor, width: '100%', height: '34px', borderRadius: '2px' }}></div>

                                                                                                                                :
                                                                                                                                <div style={{ width: '100%', height: '34px', borderRadius: '2px' }}></div>
                                                                                                                        }
                                                                                                                    </> :
                                                                                                                    <>
                                                                                                                        <img src={data.pantoneColor === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + data.pantoneColor}
                                                                                                                            alt="Picture" style={{ marginBottom: '5px', height: '100px', width: '50%', border: "1px solid #d5d5d5" }}

                                                                                                                        />
                                                                                                                    </>
                                                                                                            }
                                                                                                            {/* <span className="Actionbutton">
                                                                                                                <div style={{ background: data.pantoneColor, width: '100%', height: '34px', borderRadius: '2px' }}></div>
                                                                                                            </span> */}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td>  <textarea maxLength="300" name="Remarks" value={data.approvalComments} rows="2" style={{ width: "100%" }}
                                                                                                    onChange={(e) => OnchangeColorComments(e, index)}
                                                                                                    disabled={props.TaskStatus === 3 ? true : false}
                                                                                                ></textarea></td>
                                                                                            </tr>

                                                                                        </Fragment>
                                                                                    ))

                                                                                }

                                                                            </tbody>
                                                                        </table>
                                                                        :
                                                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th >Strike Off Colors</th>
                                                                                    <th>Approval Comments</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                                                                            </tbody>
                                                                        </table>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="idOrderInformation" className="tab-pane" style={{ width: "100%" }}>

                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="dataTables_wrapper no-footer" style={{ overflowX: "auto" }}>
                                                        {
                                                            OrderInfoMainDetails.length !== 0 ?

                                                                <table className="table table-bordered">

                                                                    <tbody>

                                                                        {
                                                                            OrderInfoMainDetails.map(comment => (
                                                                                <Fragment>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <div className="row">
                                                                                                <div className="col-lg-8">
                                                                                                    <i
                                                                                                        className={
                                                                                                            expandState[comment.strikeoffId] ?
                                                                                                                'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                        }
                                                                                                        onClick={() => handleExpandRow(comment.strikeoffId, getOrderinfo)}
                                                                                                    ></i>
                                                                                                    <span className="BuyerPaddingLeft">
                                                                                                        {
                                                                                                            comment.strikeoffCode
                                                                                                        }
                                                                                                        -
                                                                                                        {
                                                                                                            comment.strikeoffName
                                                                                                        }
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div className="col-lg-4">
                                                                                                    <span className="Actionbutton">
                                                                                                        <img src={window.$APIBaseURL + "Images/Style/Strikeoff/" + comment.strikeoffLogoPath} height="50px" width="50px"></img>
                                                                                                    </span>
                                                                                                </div>

                                                                                            </div>

                                                                                        </td>

                                                                                    </tr>
                                                                                    <>
                                                                                        {
                                                                                            expandedRows.includes(comment.strikeoffId) ?
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                                            <TableHeader headers={headers}
                                                                                                            />
                                                                                                            <tbody>
                                                                                                                {
                                                                                                                    expandList.map((childdata, index) => (
                                                                                                                        <Fragment>

                                                                                                                            <tr>
                                                                                                                                <td>{childdata.styleName}</td>
                                                                                                                                <td>{childdata.styleNo}</td>
                                                                                                                                <td>{childdata.idpoNo}</td>
                                                                                                                                <td>{childdata.strikeoffDetailswithSize}</td>
                                                                                                                                <td>{childdata.taskOwnerNameList}</td>
                                                                                                                                <td className="Actionbutton fixed-column">
                                                                                                                                    {" "}
                                                                                                                                    <div style={{ width: "80px" }}>
                                                                                                                                        <label style={{ marginTop: '8px' }}>
                                                                                                                                            <input type="checkbox" name="SelectedDetail"

                                                                                                                                                onChange={event => (OnChangeOrderinfo(event, childdata.index - 1))}
                                                                                                                                                checked={childdata.SelectedDetail === 1 ? true : false}
                                                                                                                                                className="colored-blue clearOpacity"
                                                                                                                                                disabled={props.TaskStatus === 3 ? true : false} />
                                                                                                                                            <span className="text"></span>
                                                                                                                                        </label>
                                                                                                                                        &nbsp;&nbsp;

                                                                                                                                        <button
                                                                                                                                            type="button"
                                                                                                                                            className="btn  btn-xs  btn-danger"
                                                                                                                                            title="Delete StrikeOff"
                                                                                                                                            onClick={() =>
                                                                                                                                                handleRemoveOrderinfo(index)
                                                                                                                                            }
                                                                                                                                            disabled={props.TaskStatus === 3 ? true : false}
                                                                                                                                        >
                                                                                                                                            <i className="fa fa-trash-o"></i>
                                                                                                                                        </button>

                                                                                                                                    </div>
                                                                                                                                </td>
                                                                                                                            </tr>

                                                                                                                        </Fragment>
                                                                                                                    ))

                                                                                                                }

                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </td>
                                                                                                </tr> : null
                                                                                        }
                                                                                    </>
                                                                                </Fragment>
                                                                            ))
                                                                        }


                                                                    </tbody>
                                                                </table>
                                                                :
                                                                <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                                    <TableHeader headers={headers} />
                                                                    <tbody>
                                                                        <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                                                                    </tbody>
                                                                </table>
                                                        }
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div className="mt-10">
                            <div className="row">
                                <div className="col-sm-8 col-lg-8">
                                    <div className='form-group'>
                                        <label htmlFor="Remarks">Remarks</label>
                                        <textarea maxLength="300" name="Remarks" value={inputFields[0].ApprovalRemark}
                                            onChange={event => handleChange(event, 'Remarks')}
                                            rows="4" style={{ width: "100%" }}
                                            disabled={props.TaskStatus === 3 && (props.props.location.pathname !== '/TNAEdit' ? true : props.TaskStatus === 3 && props.editStrikeOffApproval === false) ? true : false}
                                        ></textarea>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                        <div className="row">
                            <div className="col-sm-12" align="right">
                                <button type="button" className="btn btn-success margin-Align" onClick={() => SaveApproval()}
                                    disabled={props.TaskStatus === 3 && (props.props.location.pathname !== '/TNAEdit') ? true : props.TaskStatus === 3 && props.editStrikeOffApproval === false ? true : false}
                                >

                                    <i className="fa fa-check right"></i> &nbsp;{(props.props.location.pathname !== '/TNAEdit') || props.editStrikeOffApproval === false ? 'Save' : 'Update'}
                                </button>
                            </div>
                        </div>
                        <Modal show={ShowImportPopup} size="md" dragable backdrop="static" keyboard={false} >
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    AWB / Date
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="success" disabled={getDisableImport}>
                                    Submit
                                </Button>
                            </Modal.Footer>
                        </Modal></>
                }

                {
                    getID.showPopupDelete ? <SweetAlertPopup data={
                        getID.Params
                    }
                        deleteCallback={Delcallback}
                        showpopup={true}
                        pageActionId={StrikeOffSubmissionApprovalId}
                        Msg={"StrikeOff Approval Deleted successfully."} /> : null
                }
                {
                    showswatchcardimages === true &&
                    swatchcardimages.map((images, index) => {
                        return (
                            <>
                                <div id={"UploadPage" + (index + 1)} style={{ width: '950px', height: '1350px' }}>
                                    <img
                                        src={window.$APIBaseURL + images.src}
                                        alt="No Data" />
                                </div>
                            </>
                        )
                    })
                }
            </Form>

            {loader}
        </div>

    );
};

export default StrikeoffSubmission;