import React from "react";

export const Check = React.forwardRef(({ indeterminate, ...rest }, ref) => {


  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
        <input type="checkbox"  className="colored-blue react-checkbox clearOpacity" ref={resolvedRef} {...rest} />
    </>
  );
});
