import {
    SUPPLIERINFO_LOADING,
    RETRIEVEINFO_SUPPLIER,
    DELETEINFO_SUPPLIER,
} from "../actions/types";

const initialState = {
    isLoadingSupplier: true,
    supplierList: [],
};

const supplierReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SUPPLIERINFO_LOADING:
            return {
                ...state,
                isLoadingSupplier: payload
            };
        case RETRIEVEINFO_SUPPLIER:
            return { ...state, supplierList: payload };
        case DELETEINFO_SUPPLIER:
            const results = state.supplierList.filter(c => c.companyBuyerSupID !== payload.companyBuyerSupID);
            return {
                ...state,
                supplierList: results
            };

        default:
            return state;
    }
};
export default supplierReducer;