import {
    CURRENCY_LOADING,
    RETRIEVE_CURRENCY,
    DELETE_CURRENCY,
} from "../actions/types";

const initialState = {
    isLoadingCurrency: true,
    currencyList: [],
};

const seasonReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case CURRENCY_LOADING:
            return {
                ...state,
                isLoadingCurrency: payload
            };
        case RETRIEVE_CURRENCY:
            return { ...state, currencyList: payload };
        case DELETE_CURRENCY:
            const results = state.currencyList.filter(c => c.currencyId !== payload.currencyId);
            return {
                ...state,
                currencyList: results
            };

        default:
            return state;
    }
};
export default seasonReducer;