import React, { useState, useEffect, Fragment, useMemo, useRef } from "react";
import ReactNotification from 'react-notifications-component';
import Form from "react-validation/build/form";
import Nodify from "../../../Common/ReactNotification"
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Reactselect from 'react-select';
import DatePicker from "react-datepicker";
import { loadTaskHolderList } from "../../../../actions/task";
import { loadSupplierList } from "../../../../actions/tna";
import { getGenLapdibTNAHolidayList, loadGLDTNATaskOwnerList } from "../../../../actions/generalLabdip";
import { getGenTNAStrikeOffReferenceList, removeStrikeOffReferenceByIndex, InsertGenralStrikeOffTNA } from "../../../../actions/generalStrikeOff";
import { loadRouteDependencyList } from "../../../../actions/route";
import taskService from "../../../../services/Master/TaskService";
import moment from "moment";

const SamGenStrikeOffAddTNA = (props) => {
    let BuyerId = 0;
    let BrandId = 0;
    let SeasonId = 0;
    let IsAll = 0;
    let StrikeOffReferenceList = [];
    let BuyerName = "";
    let BrandName = "";
    let SeasonName = "";

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                //SaveBuyerInfo(event);
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [])
    //get props params
    if (props.location.state !== undefined) {

        BuyerId = props.location.state.params.buyerId === undefined ? 0 : props.location.state.params.buyerId;
        BrandId = props.location.state.params.brandId === undefined ? 0 : props.location.state.params.brandId;
        SeasonId = props.location.state.params.seasonId === undefined ? 0 : props.location.state.params.seasonId;
        BuyerName = props.location.state.params.buyerName === undefined ? 0 : props.location.state.params.buyerName;
        BrandName = props.location.state.params.brandName === undefined ? 0 : props.location.state.params.brandName;
        SeasonName = props.location.state.params.seasonName === undefined ? 0 : props.location.state.params.seasonName;

        IsAll = props.location.state.params.isAllSelected === undefined ? 0 : props.location.state.params.isAllSelected;
        StrikeOffReferenceList = props.location.state.params.genStrikeOffReferenceList === undefined ? [] : props.location.state.params.genStrikeOffReferenceList;
    }
    //declaration
    const dispatch = useDispatch();
    const [loader, showLoader, hideLoader] = useFullPageLoader();

    //reducer
    let isLoadingGeneralStrikeOff = useSelector((state) => state.generalStrikeOff.isLoadingGeneralStrikeOff);

    const { user: currentUser } = useSelector((state) => state.auth);
    const getDependencyList = useSelector((state) => state.route.routeDependencyList);
    const taskHolderList = useSelector((state) => state.task.drpTaskHolderList);
    const drpSupplierList = useSelector((state) => state.tna.drpSupplierList);

    const StaticTask = useSelector((state) => state.tna.StaticTask);
    const genStrikeOffTNAReferenceList = useSelector((state) => state.generalStrikeOff.genStrikeOffTNAReferenceList);
    const genStrikeOffTNATaskList = useSelector((state) => state.generalStrikeOff.genStrikeOffTNATaskList);
    const genStrikeOffTNAAddtionalTaskList = useSelector((state) => state.generalStrikeOff.genStrikeOffTNAAddtionalTaskList);
    const genTNAHolidayList = useSelector((state) => state.generalLabdip.genLapdibTNAHolidayList);
    const drpGLDTNATaskOwnerList = useSelector((state) => state.generalLabdip.drpGLDTNATaskOwnerList);
    //state

    const [getTaskHolderList, setTaskHolderList] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [inputFields, setInputFields] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getLeaveDaysList, setLeaveDaysList] = useState([]);
    const [isCalculationLoad, setCalculationLoad] = useState(false);
    const selectInputRef = useRef();

    const [MainFields, setMainFields] = useState({
        SupplierIdList: '',
        GenStrikeOffId: 0,
        BuyerId: BuyerId,
        BrandId: BrandId,
        SeasonId: SeasonId,
        GenTNAStrikeOffReferenceList: [],
        GenStrikeOffTNASubmissionAndApproval: [],
        GenTNAAdditionalTaskDetails: [],
        CreatedBy: currentUser.employeeinformationID,
    });

    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),
    }

    const values = [...inputFields];
    const MainValues = { ...MainFields };

    useEffect(() => {
        if (!isLoadingGeneralStrikeOff) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingGeneralStrikeOff]);


    useEffect(() => {
        if (values.length > 0) {
            if (isCalculationLoad === true) {
                commonCalculation(false, false);
                setCalculationLoad(false);
            }
        }
    }, [isCalculationLoad === true]);


    // If check production or sampling
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
    let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal : activeMenus.IsProduction;
    // End

    useEffect(() => {
        dispatch(loadTaskHolderList());
        dispatch(loadRouteDependencyList());
        dispatch(loadSupplierList());

        let params = { BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, IsAll: (IsAll === true ? 1 : 0), GenTNAStrikeOffReferenceList: StrikeOffReferenceList, IsProduction: IsProduction }
        dispatch(getGenTNAStrikeOffReferenceList(params));
        taskService.getLoadLeaveDaysList().then((response) => {
            if (response.data) {
                setLeaveDaysList(response.data);
            }
        });

    }, []);

    useEffect(() => {
        let params = {
            BrandId: BrandId, SupplierIdList: MainValues.SupplierIdList, CompanyBuyerSupID: currentUser.companyBuyerSupID
        };

        dispatch(getGenLapdibTNAHolidayList(params));
    }, [MainValues.SupplierIdList]);


    useMemo(
        () => {

            let Optionvalue = [];
            taskHolderList && taskHolderList.map(element => {
                let LabelColor = '';
                if (element.label === 'Buyer - -') {
                    LabelColor = <><span style={{ color: "#0b0992" }}><b>Buyer</b></span></>
                }
                else if (element.label === 'Buyer - Cube') {
                    LabelColor = <><span style={{ color: "#0b0992" }}><b>Buyer</b></span> - <span style={{ color: "#00a65a" }}><b>Cube</b></span></>
                }
                else if (element.label === 'Buyer - Supplier') {
                    LabelColor = <><span style={{ color: "#0b0992" }}><b>Buyer</b></span> - <span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                }
                else if (element.label === 'Buyer - Cube,Supplier') {
                    LabelColor = <><span style={{ color: "#0b0992" }}><b>Buyer</b></span> - <span style={{ color: "#00a65a" }}><b>Cube</b></span>, <span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                }
                else if (element.label === 'Cube - -') {
                    LabelColor = <><span style={{ color: "#00a65a" }}><b>Cube</b></span></>
                }
                else if (element.label === 'Cube - Buyer') {
                    LabelColor = <><span style={{ color: "#00a65a" }}><b>Cube</b></span> - <span style={{ color: "#0b0992" }}><b>Buyer</b></span></>
                }
                else if (element.label === 'Cube - Supplier') {
                    LabelColor = <><span style={{ color: "#00a65a" }}><b>Cube</b></span> - <span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                }
                else if (element.label === 'Cube - Buyer,Supplier') {
                    LabelColor = <><span style={{ color: "#00a65a" }}><b>Cube</b></span> - <span style={{ color: "#0b0992" }}><b>Buyer</b></span>, <span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                }
                else if (element.label === 'Supplier - -') {
                    LabelColor = <><span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                }
                else if (element.label === 'Supplier - Buyer') {
                    LabelColor = <><span style={{ color: "#e27607" }}><b>Supplier</b></span> - <span style={{ color: "#0b0992" }}><b>Buyer</b></span></>
                }
                else if (element.label === 'Supplier - Cube') {
                    LabelColor = <><span style={{ color: "#e27607" }}><b>Supplier</b></span> - <span style={{ color: "#00a65a" }}><b>Cube</b></span></>
                }
                else if (element.label === 'Supplier - Buyer,Cube') {
                    LabelColor = <><span style={{ color: "#e27607" }}><b>Supplier</b></span> - <span style={{ color: "#0b0992" }}><b>Buyer</b></span>, <span style={{ color: "#00a65a" }}><b>Cube</b></span></>
                }

                Optionvalue.push({
                    value: element.value,
                    label: LabelColor,
                    subLabel: element.label,
                })
            });

            setTaskHolderList(Optionvalue);

        },
        [taskHolderList]
    );

    useMemo(
        () => {
            if (genStrikeOffTNATaskList && genStrikeOffTNATaskList.length > 0) {
                let List = genStrikeOffTNATaskList;
                // let additionalInfo = AllRouteInfo.additionalInfo;
                let TNAReferenceList = [];
                List.map((item, i) => {
                    let StartDate = new Date();
                    let EndDate = new Date();
                    let employee1 = "";
                    let employee2 = "";
                    let employee3 = "";

                    if (item.taskHolderOrFollowerName) {
                        let TaskHolderName = item.taskHolderOrFollowerName.split('-');
                        if (TaskHolderName[0] !== undefined) {
                            employee1 = TaskHolderName[0].trim();

                            if (TaskHolderName[1] !== undefined) {
                                let FollowerName = TaskHolderName[1].split(',');
                                if (FollowerName[0] !== undefined) {
                                    employee2 = FollowerName[0].trim();

                                    if (FollowerName[1] !== undefined) {
                                        employee3 = FollowerName[1].trim();
                                    } else {
                                        employee3 = "";
                                    }
                                } else {
                                    employee2 = "";
                                    employee3 = "";
                                }

                            } else {
                                employee2 = "";
                                employee3 = "";
                            }
                        }
                    }
                    let Duration = "1";
                    if (item.taskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT) {
                        Duration = "7";
                    } else if (item.taskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
                        Duration = "10";
                    }
                    let FollowupDetails = [];

                    if (genStrikeOffTNAAddtionalTaskList && genStrikeOffTNAAddtionalTaskList.length > 0) {
                        genStrikeOffTNAAddtionalTaskList.filter(x => x.indexName === item.indexName && x.taskInformationID === item.taskInformationID).map((element, j) => {
                            FollowupDetails.push({
                                GenStrikeOffTNAAddTaskId: 0,
                                TaskId: item.taskDetailsID,
                                TaskTypeId: item.taskType,
                                AdditonalTaskDetailsID: element.additonalTaskDetailsID,
                                AddtionalTaskName: element.addtionalTaskName,
                                IsTaskSelected: 1,
                                IndexName: i.toString()
                            })
                        });
                    }

                    TNAReferenceList.push({
                        TaskID: item.taskDetailsID,
                        TaskName: item.taskName,
                        TaskType: item.taskType,
                        TaskTypeName: item.taskTypeName,
                        EndDate: EndDate,
                        StartDate: StartDate,
                        PreStartDate: StartDate,
                        PreEndDate: EndDate,
                        Duration: Duration,
                        LogDays: 0,
                        AdditionalFollowup: FollowupDetails.length,
                        IsFollowupExist: 0,
                        IndexName: '0',
                        IsPopupOpen: 0,
                        IsSelectAll: 1,
                        FollowupDetails: FollowupDetails,
                        TaskHolderOrFollower: item.taskHolderOrFollower, //TaskHolder
                        TaskHolderOwnerTxtList: '',
                        TaskHolderOwnerIdList: '',
                        TaskHolderList: '',
                        titleEmployee1: employee1,
                        titleEmployee2: employee2,
                        titleEmployee3: employee3,
                        TaskHolderBuyer: '',
                        arrTaskHolderBuyer: [],
                        FollowerCube: '',
                        arrFollowerCube: [],
                        FollowerSupplier: '',
                        arrFollowerSupplier: [],
                        LeaveDays: item.leaveDays,
                        LeaveDaysTxt: item.leaveDayName,
                        IsTHLeaveDaysOpen: 0,
                        TaskHolderBuyerTxt: '',
                        FollowerCubeTxt: '',
                        FollowerSupplierTxt: '',
                        DependencyID: 1,
                        EndDateChange: 0,
                    });
                });

                setInputFields(TNAReferenceList);
                setCalculationLoad(true);
            } else {
                setInputFields([]);
            }

        },
        [genStrikeOffTNATaskList]
    );
    const ResetOperation = (e) => {
        if (e === "Back") {

            let Params = { buyerId: BuyerId, brandId: BrandId, seasonId: SeasonId, buyerName: BuyerName, brandName: BrandName, seasonName: SeasonName }
            props.location.state = { params: Params };
            props.history.push('/SamGenlStrikeOffView', { params: Params });
        } else {
            let params = { BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, IsAll: (IsAll === true ? 1 : 0), GenTNAStrikeOffReferenceList: StrikeOffReferenceList, IsProduction: IsProduction }
            dispatch(getGenTNAStrikeOffReferenceList(params));
            selectInputRef.current.select.clearValue();
        }
    }
    const handleChange = (e, index, FieldName) => {
        setSubmitted(false);
        let inputText = '';
        if (FieldName === "IsSelectAll") {
            if (e.target.checked) {
                for (var i = 0; i < values[index].FollowupDetails.length; i++) {
                    values[index].FollowupDetails[i].IsTaskSelected = 1;
                }
                values[index][FieldName] = 1;
            } else {
                for (var i = 0; i < values[index].FollowupDetails.length; i++) {
                    values[index].FollowupDetails[i].IsTaskSelected = 0;
                }
                values[index][FieldName] = 0;
            }

            values[index].AdditionalFollowup = values[index].FollowupDetails.filter(x => x.IsTaskSelected === 1).length;
            values[index].IsFollowupExist = values[index].FollowupDetails.length;

        } else if (FieldName === "TaskHolderOrFollower" || FieldName === "LeaveDays") {
            if (e != null) {

                inputText = parseInt(e.value);
                if (FieldName === "TaskHolderOrFollower") {
                    // setTaskHolderID(inputText);
                    showLoader();
                    values[index].TaskHolderOrFollowerTxt = e.subLabel && e.subLabel.replace(" - -", "");
                    let TaskHolderName = values[index].TaskHolderOrFollowerTxt && values[index].TaskHolderOrFollowerTxt.split('-');
                    if (TaskHolderName[0]) {
                        values[index].titleEmployee1 = TaskHolderName[0].trim();

                        if (TaskHolderName[1]) {
                            let FollowerName = TaskHolderName[1].split(',');
                            if (FollowerName[0]) {
                                values[index].titleEmployee2 = FollowerName[0].trim();

                                if (FollowerName[1]) {
                                    values[index].titleEmployee3 = FollowerName[1].trim();
                                } else {
                                    values[index].titleEmployee3 = "";
                                }
                            } else {
                                values[index].titleEmployee2 = "";
                                values[index].titleEmployee3 = "";
                            }

                        } else {
                            values[index].titleEmployee2 = "";
                            values[index].titleEmployee3 = "";
                        }
                        if (values[index].titleEmployee1 !== "Cube" && values[index].titleEmployee2 !== "Cube" && values[index].titleEmployee3 !== "Cube") {
                            dispatch(loadGLDTNATaskOwnerList(inputText, BuyerId, 0, MainValues.SupplierIdList))
                                .then(data => {
                                    if (data) {
                                        hideLoader();
                                    }
                                })
                                .catch(e => {
                                    console.log(e);
                                    hideLoader();

                                });
                        } else {
                            dispatch(loadGLDTNATaskOwnerList(inputText, BuyerId, currentUser.employeeinformationID, MainValues.SupplierIdList))
                                .then(data => {
                                    if (data) {
                                        hideLoader();
                                    }
                                })
                                .catch(e => {
                                    console.log(e);
                                    hideLoader();

                                });
                        }

                        // if (values[index].titleEmployee1 === "Supplier" || values[index].titleEmployee2 === "Supplier" || values[index].titleEmployee3 === "Supplier") {
                        //     if (drpRouteTaskOwnerList.supplierEmployeeList && drpRouteTaskOwnerList.supplierEmployeeList.length === 1) {
                        //         values[index].arrFollowerSupplier = [drpRouteTaskOwnerList.supplierEmployeeList[0]];
                        //         values[index].FollowerSupplier = drpRouteTaskOwnerList.supplierEmployeeList[0].value;
                        //     }
                        // }

                    } else {
                        values[index].titleEmployee1 = "";
                        values[index].titleEmployee2 = "";
                        values[index].titleEmployee3 = "";
                    }


                } else {
                    values[index].LeaveDaysTxt = e.label;
                }
            }
            else {
                inputText = 0;
                if (FieldName === "TaskHolderOrFollower") {
                    values[index].TaskHolderOrFollowerTxt = '';
                    values[index].titleEmployee1 = "";
                    values[index].titleEmployee2 = "";
                    values[index].titleEmployee3 = "";
                }
                values[index].LeaveDaysTxt = "";
            }
            values[index][FieldName] = inputText;
        }
        else if (FieldName === "Duration") {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            var checkNumber = inputText.replace(/\D/g, '').match('^[0-9]*$');
            inputText = checkNumber[0];

            if (parseInt(inputText) === 0) {
                inputText = '';
            }
            values[index][e.target.name] = inputText;
            if (values[index].EndDateChange === 1) {
                values[index].EndDateChange = 2;
            }
        }
        else if (FieldName === "LogDays") {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            var checkNumber = inputText.replace(/\D/g, '').match('^[0-9]*$');
            inputText = checkNumber[0];

            if (parseInt(inputText) === 0) {
                inputText = '';
            }
            values[index][e.target.name] = inputText;
        }
        else if (FieldName === "EndDate") {
            values[index].PreEndDate = values[index].EndDate;
            values[index].EndDate = e;
            if (values[index].EndDateChange === 0) {
                values[index].EndDateChange = 1;
            }

        }
        else if (FieldName === "TaskHolderBuyer" || FieldName === "FollowerCube" || FieldName === "FollowerSupplier") {

            let strValue = '';
            let strTxt = '';
            e.map((val) => {
                if (strValue === '') { strValue = val.value.toString(); strTxt = val.label.toString(); }
                else { strValue = strValue + "," + val.value; strTxt = strTxt + ", " + val.label.toString(); }
            });
            values[index]["arr" + FieldName] = e;
            values[index][FieldName] = strValue;
            values[index][FieldName + "Txt"] = strTxt;

        }
        else if (FieldName === "DependencyID") {
            let DependencyID = 0;
            let DependencyTxt = "";
            if (e != null) {
                DependencyID = parseInt(e.value);
            } else {
                DependencyID = 0;
            }

            values[index].DependencyID = DependencyID;
        }
        else {
            if (e.target.value.trim() !== '') {
                inputText = parseInt(e.value);
            }
            values[index][e.target.name] = inputText;
        }
        setInputFields(values);
        if (FieldName === "EndDate") {
            commonCalculation(false, true);
        } else if (FieldName === "Duration" || FieldName === "LogDays") {
            commonCalculation(true, false);
        } else if (FieldName === "DependencyID") {
            commonCalculation(false, false);
        }

    }

    const handleSupplierChange = (e, FieldName) => {
        let oldstrValue = MainValues.SupplierIdList;
        let strValue = '';
        if (e) {
            e.map((val) => {
                if (strValue === '') {
                    strValue = val.value.toString();
                }
                else { strValue = strValue + "," + val.value; }
            });
        }
        MainValues.SupplierIdList = strValue;
        setMainFields(MainValues);
        if (strValue != "") {
            oldstrValue = oldstrValue && oldstrValue.split(',');
            let newStrValue = strValue.split(',');

            if (oldstrValue.length > newStrValue.length) {
                values.map((inputField, index) => {
                    if (values[index].titleEmployee1 === "Supplier" || values[index].titleEmployee2 === "Supplier" || values[index].titleEmployee3 === "Supplier") {
                        values[index].FollowerSupplierTxt = "";
                        values[index].arrFollowerSupplier = [];
                    }

                });
            }

        }
        setInputFields(values);
        commonCalculation(false, false);

    }

    // #region ------------------  TaskHolderLeaveDays Start --------------------------------
    const handleFollowup = index => {
        if (values[index].FollowupDetails && values[index].FollowupDetails.length > 0) {
            values[index].IsPopupOpen = 1;
            setInputFields(values);
        } else {
            Nodify('Warning!', 'warning', 'No data in additional task');
        }
    };


    const handleTHLeaveDaysSave = (index) => {

        let Taskhoder = BindTaskHoldername(values, index);
        if (Taskhoder === "") {
            Nodify('Warning!', 'warning', 'Please select task holder');
        } else {
            values[index].IsTHLeaveDaysOpen = 0;
            setInputFields(values);

        }
    }

    const handleTHLeaveDaysClose = (index) => {
        BindTaskHoldername(values, index);
        values[index].IsTHLeaveDaysOpen = 0;
        setInputFields(values);


    }



    const handleTHLeaveDaysSaveNext = (index) => {
        let Taskhoder = BindTaskHoldername(values, index);
        if (Taskhoder === "") {
            Nodify('Warning!', 'warning', 'Please select task holder name');
        } else {
            values[index].IsTHLeaveDaysOpen = 0;
            // save Next instance

            index = index + 1;
            let totalValue = values.length - 1;

            if (totalValue >= index) {
                if (values[index].titleEmployee1 !== "Cube" && values[index].titleEmployee2 !== "Cube" && values[index].titleEmployee3 !== "Cube") {
                    dispatch(loadGLDTNATaskOwnerList(values[index].TaskHolderOrFollower, BuyerId, 0, MainValues.SupplierIdList));
                } else {
                    dispatch(loadGLDTNATaskOwnerList(values[index].TaskHolderOrFollower, BuyerId, currentUser.employeeinformationID, MainValues.SupplierIdList));
                }
                values[index].IsTHLeaveDaysOpen = 1;
                BindTaskHoldername(values, index);
                //end Next

            }
            else { values[index - 1].IsTHLeaveDaysOpen = 0; }
            setInputFields(values);
        }
    }

    function BindTaskHoldername(values, index) {

        let TxtList = "";
        let IdList = "";
        let Taskhoder = "";
        let titleEmployee1 = values[index].titleEmployee1;
        let titleEmployee2 = values[index].titleEmployee2;
        let titleEmployee3 = values[index].titleEmployee3;

        if (values[index].TaskHolderBuyer && (titleEmployee1 === "Buyer" || titleEmployee2 === "Buyer" || titleEmployee3 === "Buyer")) {
            TxtList = values[index].TaskHolderBuyerTxt;
            IdList = values[index].TaskHolderBuyer;
            //Taskhoder = values[index].TaskHolderBuyer;
        } else {
            values[index].TaskHolderBuyer = "";
            values[index].TaskHolderBuyerTxt = "";
            values[index].arrTaskHolderBuyer = [];
        }
        if (values[index].FollowerCube && (titleEmployee1 === "Cube" || titleEmployee2 === "Cube" || titleEmployee3 === "Cube")) {
            TxtList = TxtList === "" ? values[index].FollowerCubeTxt : TxtList + ", " + values[index].FollowerCubeTxt;
            IdList = IdList === "" ? values[index].FollowerCube : IdList + "," + values[index].FollowerCube;
        }
        else {
            values[index].FollowerCube = "";
            values[index].FollowerCubeTxt = "";
            values[index].arrFollowerCube = [];
        }
        if (values[index].FollowerSupplier && (titleEmployee1 === "Supplier" || titleEmployee2 === "Supplier" || titleEmployee3 === "Supplier")) {
            TxtList = TxtList === "" ? values[index].FollowerSupplierTxt : TxtList + ", " + values[index].FollowerSupplierTxt;
            IdList = IdList === "" ? values[index].FollowerSupplier : IdList + "," + values[index].FollowerSupplier;
        } else {
            values[index].FollowerSupplier = "";
            values[index].FollowerSupplierTxt = "";
            values[index].arrFollowerSupplier = [];
        }

        values[index].TaskHolderOwnerTxtList = TxtList;
        values[index].TaskHolderOwnerIdList = IdList;

        if (values[index].titleEmployee1 === 'Cube') {
            Taskhoder = values[index].FollowerCube;
        }
        else if (values[index].titleEmployee1 === 'Buyer') {
            Taskhoder = values[index].TaskHolderBuyer;
        }
        else if (values[index].titleEmployee1 === 'Supplier') { Taskhoder = values[index].FollowerSupplier; }
        return Taskhoder;
    }

    const handleTaskHolderLeaveDays = (index) => {
        if (MainValues.SupplierIdList.length === 0) {
            Nodify('Warning!', 'warning', 'Please select the supplier.');
        } else {


            if (values[index].titleEmployee1 !== "Cube" && values[index].titleEmployee2 !== "Cube" && values[index].titleEmployee3 !== "Cube") {
                dispatch(loadGLDTNATaskOwnerList(values[index].TaskHolderOrFollower, BuyerId, 0, MainValues.SupplierIdList))
                    .then(data => {
                        if (data) {

                        }
                        values[index].IsTHLeaveDaysOpen = 1;
                        setInputFields(values);
                    })
                    .catch(e => {
                        console.log(e);
                        setInputFields(values);
                    });
            } else {
                dispatch(loadGLDTNATaskOwnerList(values[index].TaskHolderOrFollower, BuyerId, currentUser.employeeinformationID, MainValues.SupplierIdList))
                    .then(data => {
                        if (data) {

                        }
                        values[index].IsTHLeaveDaysOpen = 1;
                        setInputFields(values);
                    })
                    .catch(e => {
                        console.log(e);
                        setInputFields(values);
                    });
            }
        }


    };

    // #end region ------------------  TaskHolderLeaveDays End --------------------------------


    //    ------------- **** Calculation Begin ***** --------------------------

    //Main Calculation Function
    const commonCalculation = (isDuration, isEndDate) => {
        values.map((Item, i) => {
            let Duration = (Item.Duration) ? parseInt(Item.Duration) > 0 ? parseInt(Item.Duration) - 1 : 0 : 0;
            let dependencyCount = Item.DependencyID ? 1 : 0;

            if (isEndDate) {
                let CheckDate = checkDateBetweenInHolidayAndWE(new Date(Item.EndDate), Item.LeaveDays, Item.titleEmployee1, "add");
                if (!isSameDay(new Date(Item.EndDate), CheckDate)) {
                    Nodify('Warning!', 'warning', 'You select the end date to leave day');
                }
            }


            if (((i === 0 || dependencyCount === 0) && isEndDate === false) || (isDuration === true && dependencyCount === 0)) {
                // Dependency Calculation Without
                let dbStartDate = new Date(Item.StartDate);
                let total = 0;
                //add Saturday,Sunday and Holiday Count
                dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, Item.LeaveDays, Item.titleEmployee1, "add");
                let EndDate = addDays(dbStartDate, Duration);
                //Total Saturday,Sunday and Holiday Count
                total = totalHolidayAndWE(dbStartDate, EndDate, Item.LeaveDays, Item.titleEmployee1);
                for (let l = 0; l < total; l++) {
                    EndDate = addDays(EndDate, 1);
                    EndDate = checkDateBetweenInHolidayAndWE(EndDate, Item.LeaveDays, Item.titleEmployee1, "add");
                }

                Item.StartDate = dbStartDate;
                Item.EndDate = EndDate;
                Item.PreStartDate = dbStartDate;
                Item.PreEndDate = EndDate;
                values[i] = Item;
            }
            else if ((i === 0 || dependencyCount === 0) || (isEndDate === true && dependencyCount === 0)) {

                let dbEndDate = Item.EndDate;
                let total = 0;
                //add Saturday,Sunday and Holiday Count
                dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, Item.LeaveDays, Item.titleEmployee1, "add");
                let StartDate = subDays(dbEndDate, Duration);
                //Total Saturday,Sunday and Holiday Count
                total = totalHolidayAndWE(StartDate, dbEndDate, Item.LeaveDays, Item.titleEmployee1);
                for (let l = 0; l < total; l++) {
                    StartDate = subDays(StartDate, 1);
                    StartDate = checkDateBetweenInHolidayAndWE(StartDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                }

                Item.StartDate = StartDate;
                Item.EndDate = dbEndDate;
                Item.PreStartDate = StartDate;
                Item.PreEndDate = dbEndDate;
                values[i] = Item;
            }
            else {
                //Dependency Calculation

                let dbEndDate = new Date();
                let dbStartDate = new Date();
                let total = 0;

                if (Item.DependencyID) {
                    //-------Single  Dependent Calculation----------------
                    //Get Dependent Name and Index

                    let indexVal = 1;
                    let dependencyVal = (Item.DependencyID === 1 ? "ES" : Item.DependencyID === 2 ? "SS" : Item.DependencyID === 3 ? "SE" : Item.DependencyID === 4 ? "EE" : "");

                    //EndDate Change Duration Increase and Decrease
                    if (isEndDate === true && Item.EndDateChange !== 2) {
                        let chkStartDate = Item.StartDate;
                        let chkEndDate = Item.EndDate;
                        chkStartDate.setHours(0, 0, 0, 0);
                        chkEndDate.setHours(0, 0, 0, 0);
                        if (chkStartDate <= chkEndDate) {
                            if (dependencyVal === "SS" || dependencyVal === "ES") {
                                let LastDate = checkDateBetweenInHolidayAndWE(new Date(Item.EndDate), Item.LeaveDays, Item.titleEmployee1, "add");
                                let diffDayCount = diffDays(new Date(Item.StartDate), LastDate);
                                let holidayTotal = totalHolidayAndWE(Item.StartDate, LastDate, Item.LeaveDays, Item.titleEmployee1);
                                diffDayCount = diffDayCount - holidayTotal;
                                Item.Duration = diffDayCount;
                                Duration = diffDayCount - 1;
                            }


                        } else {
                            Item.EndDate = Item.PreEndDate;
                            Nodify('Warning!', 'warning', 'End date should not be less than start date.');
                        }
                    }

                    // //Skip First Step in SE
                    // if (dependencyVal === "SE" && isEndDate === true) {
                    //     Item.EndDateChange = 2;
                    // }

                    //Check Dependency Value
                    if (dependencyVal === "SS") {
                        //---- Start-Start ---- DependencyID 2------

                        //Get Dependent Map StartDate
                        let preStartDate = values[indexVal - 1].StartDate;
                        let LogDate = new Date();

                        if (Item.EndDateChange === 2) {
                            //Manual Calculation function------(preStartDate + LogDays = StartDate))-------
                            dbEndDate = checkDateBetweenInHolidayAndWE(Item.EndDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                            dbStartDate = subDays(dbEndDate, Duration);
                            //Total Saturday,Sunday and Holiday Count
                            total = totalHolidayAndWE(dbStartDate, dbEndDate, Item.LeaveDays, Item.titleEmployee1);
                            for (let l = 0; l < total; l++) {
                                dbStartDate = subDays(dbStartDate, 1);
                                dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                            }
                            LogDate = preStartDate;

                            if (Item.LogDays) {
                                //LogDays Add
                                LogDate = addDays(LogDate, parseInt(Item.LogDays));
                                //LogDate = checkDateBetweenInHolidayAndWE(LogDate, Item.LeaveDays, "sub");
                            }
                        }
                        LogDate.setHours(0, 0, 0, 0);
                        dbStartDate.setHours(0, 0, 0, 0);
                        if (LogDate <= dbStartDate && Item.EndDateChange === 2) {
                        } else {
                            //Auto Calculation function
                            if (Item.LogDays) {
                                //LogDays Add
                                dbStartDate = addDays(preStartDate, parseInt(Item.LogDays));
                                dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, Item.LeaveDays, Item.titleEmployee1, "add");
                            } else {
                                //Calculation Saturday,Sunday and Holiday Count
                                dbStartDate = checkDateBetweenInHolidayAndWE(preStartDate, Item.LeaveDays, Item.titleEmployee1, "add");
                            }

                            dbEndDate = addDays(dbStartDate, Duration);
                            //Total Saturday,Sunday and Holiday Count
                            total = totalHolidayAndWE(dbStartDate, dbEndDate, Item.LeaveDays, Item.titleEmployee1);
                            for (let l = 0; l < total; l++) {
                                dbEndDate = addDays(dbEndDate, 1);
                                dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, Item.LeaveDays, Item.titleEmployee1, "add");
                            }
                        }

                        Item.PreStartDate = dbStartDate;
                        Item.PreEndDate = dbEndDate;
                        Item.EndDate = dbEndDate;
                        Item.StartDate = dbStartDate;

                        values[i] = Item;

                    } else if (dependencyVal === "SE") {  //---- Start-End ----DependencyID 3---

                        let preStartDate = subDays(values[indexVal - 1].StartDate, 1);
                        let LogDate = preStartDate;


                        if (isEndDate === true && Item.EndDateChange !== 2) {
                            if (Item.LogDays) {
                                //LogDays Add
                                LogDate = addDays(LogDate, parseInt(Item.LogDays));
                            }
                            LogDate.setHours(0, 0, 0, 0);
                            let newDate = checkDateBetweenInHolidayAndWE(new Date(Item.EndDate), Item.LeaveDays, Item.titleEmployee1, "add");
                            newDate.setHours(0, 0, 0, 0);
                            if (LogDate <= newDate) {
                                let LastDate = checkDateBetweenInHolidayAndWE(new Date(Item.EndDate), Item.LeaveDays, Item.titleEmployee1, "add");
                                let diffDayCount = diffDays(new Date(Item.StartDate), LastDate);

                                let holidayTotal = totalHolidayAndWE(Item.StartDate, LastDate, Item.LeaveDays, Item.titleEmployee1);
                                diffDayCount = diffDayCount - holidayTotal;
                                Item.Duration = diffDayCount;
                                // Duration = diffDayCount - 1;
                                dbStartDate = Item.StartDate;
                                dbEndDate = LastDate;
                                // Item.Duration = Duration;
                            } else {
                                dbStartDate = Item.StartDate;
                                dbEndDate = Item.PreEndDate;
                                Item.EndDate = Item.PreEndDate;
                            }
                        } else {

                            if (Item.EndDateChange === 2) {
                                //Manual Calculation function----------((preStartDate - 1) + LogDays = EndDate))-------
                                dbEndDate = checkDateBetweenInHolidayAndWE(Item.EndDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                                dbStartDate = subDays(dbEndDate, Duration);
                                //Total Saturday,Sunday and Holiday Count
                                total = totalHolidayAndWE(dbStartDate, dbEndDate, Item.LeaveDays, Item.titleEmployee1);
                                for (let l = 0; l < total; l++) {
                                    dbStartDate = subDays(dbStartDate, 1);
                                    dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                                }

                                if (Item.LogDays) {
                                    //LogDays Add
                                    LogDate = addDays(LogDate, parseInt(Item.LogDays));
                                    //LogDate = checkDateBetweenInHolidayAndWE(LogDate, Item.LeaveDays, "sub");
                                }
                            }

                            LogDate.setHours(0, 0, 0, 0);
                            dbEndDate.setHours(0, 0, 0, 0);
                            if (LogDate <= dbEndDate && Item.EndDateChange === 2) {
                                //Skip
                            } else {
                                //Auto Calculation function

                                if (Item.LogDays) {
                                    //LogDays Add
                                    dbEndDate = addDays(preStartDate, parseInt(Item.LogDays));
                                    dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, Item.LeaveDays, Item.titleEmployee1, "add");
                                } else {
                                    dbEndDate = checkDateBetweenInHolidayAndWE(preStartDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                                }

                                dbStartDate = subDays(dbEndDate, Duration);
                                //Total Saturday,Sunday and Holiday Count
                                total = totalHolidayAndWE(dbStartDate, dbEndDate, Item.LeaveDays, Item.titleEmployee1);
                                for (let l = 0; l < total; l++) {
                                    dbStartDate = subDays(dbStartDate, 1);
                                    dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                                }
                            }
                        }

                        Item.PreStartDate = dbStartDate;
                        Item.PreEndDate = dbEndDate;
                        Item.EndDate = dbEndDate;
                        Item.StartDate = dbStartDate;

                        values[i] = Item;

                    } else if (dependencyVal === "ES") {
                        //Manual Calculation function-------- End-Start ----DependencyID 1------

                        //Get Dependent Map StartDate
                        let preEndDate = values[indexVal - 1].EndDate;
                        let LogDate = new Date();

                        if (Item.EndDateChange === 2) {
                            // ---Manual Calculation function---------((preEndDate + 1) + LogDays = StartDate))-------
                            dbEndDate = checkDateBetweenInHolidayAndWE(Item.EndDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                            dbStartDate = subDays(dbEndDate, Duration);
                            //Total Saturday,Sunday and Holiday Count
                            total = totalHolidayAndWE(dbStartDate, dbEndDate, Item.LeaveDays, Item.titleEmployee1);
                            for (let l = 0; l < total; l++) {
                                dbStartDate = subDays(dbStartDate, 1);
                                dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                            }
                            LogDate = dbStartDate;

                            if (Item.LogDays) {
                                //LogDays Add
                                LogDate = subDays(LogDate, parseInt(Item.LogDays));
                                //LogDate = checkDateBetweenInHolidayAndWE(LogDate, Item.LeaveDays, "sub");
                            }
                        }
                        LogDate.setHours(0, 0, 0, 0);
                        preEndDate.setHours(0, 0, 0, 0);
                        if (preEndDate < LogDate && Item.EndDateChange === 2) {
                        } else {
                            //Auto Calculation function
                            dbStartDate = addDays(preEndDate, 1);

                            if (Item.LogDays) {
                                //LogDays Add
                                dbStartDate = addDays(dbStartDate, parseInt(Item.LogDays));
                            }
                            //Calculation Saturday,Sunday and Holiday Count
                            dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, Item.LeaveDays, Item.titleEmployee1, "add");
                            dbEndDate = addDays(dbStartDate, Duration);
                            //Total Saturday,Sunday and Holiday Count
                            total = totalHolidayAndWE(dbStartDate, dbEndDate, Item.LeaveDays, Item.titleEmployee1);
                            for (let l = 0; l < total; l++) {
                                dbEndDate = addDays(dbEndDate, 1);
                                dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, Item.LeaveDays, Item.titleEmployee1, "add");
                            }
                        }

                        Item.PreStartDate = dbStartDate;
                        Item.PreEndDate = dbEndDate;

                        Item.EndDate = dbEndDate;
                        Item.StartDate = dbStartDate;
                        values[i] = Item;

                    } else if (dependencyVal === "EE") {

                        //---- End-End --------DependencyID 4-----------((preEndDate) + LogDays = EndDate))-------
                        //Get Dependent Map StartDate
                        let preEndDate = values[indexVal - 1].EndDate;
                        let LogDate = preEndDate;


                        if (isEndDate === true && Item.EndDateChange !== 2) {
                            if (Item.LogDays) {
                                //LogDays Add
                                LogDate = addDays(LogDate, parseInt(Item.LogDays));
                            }
                            LogDate.setHours(0, 0, 0, 0);
                            let newDate = checkDateBetweenInHolidayAndWE(new Date(Item.EndDate), Item.LeaveDays, Item.titleEmployee1, "add");
                            newDate.setHours(0, 0, 0, 0);
                            if (LogDate <= newDate) {
                                let LastDate = checkDateBetweenInHolidayAndWE(new Date(Item.EndDate), Item.LeaveDays, Item.titleEmployee1, "add");
                                let diffDayCount = diffDays(new Date(Item.StartDate), LastDate);
                                let holidayTotal = totalHolidayAndWE(Item.StartDate, LastDate, Item.LeaveDays, Item.titleEmployee1);
                                diffDayCount = diffDayCount - holidayTotal;
                                Item.Duration = diffDayCount;
                                dbStartDate = Item.StartDate;
                                dbEndDate = LastDate;
                            } else {
                                dbStartDate = Item.StartDate;
                                dbEndDate = Item.PreEndDate;
                                Item.EndDate = Item.PreEndDate;
                            }
                        } else {

                            if (Item.EndDateChange === 2) {
                                // ---Manual Calculation function------
                                dbEndDate = checkDateBetweenInHolidayAndWE(Item.EndDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                                dbStartDate = subDays(dbEndDate, Duration);
                                //Total Saturday,Sunday and Holiday Count
                                total = totalHolidayAndWE(dbStartDate, dbEndDate, Item.LeaveDays, Item.titleEmployee1);
                                for (let l = 0; l < total; l++) {
                                    dbStartDate = subDays(dbStartDate, 1);
                                    dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                                }
                                LogDate = preEndDate;

                                if (Item.LogDays) {
                                    //LogDays Add
                                    LogDate = addDays(LogDate, parseInt(Item.LogDays));
                                    //LogDate = checkDateBetweenInHolidayAndWE(LogDate, Item.LeaveDays, "sub");
                                }
                            }
                            LogDate.setHours(0, 0, 0, 0);
                            dbEndDate.setHours(0, 0, 0, 0);
                            if (LogDate <= dbEndDate && Item.EndDateChange === 2) {
                            } else {
                                //Auto Calculation function
                                if (Item.LogDays) {
                                    //LogDays Add
                                    dbEndDate = addDays(preEndDate, parseInt(Item.LogDays));
                                    dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, Item.LeaveDays, Item.titleEmployee1, "add");
                                } else {
                                    dbEndDate = checkDateBetweenInHolidayAndWE(preEndDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                                }
                                dbStartDate = subDays(dbEndDate, Duration);
                                //Total Saturday,Sunday and Holiday Count
                                total = totalHolidayAndWE(dbStartDate, dbEndDate, Item.LeaveDays, Item.titleEmployee1);
                                for (let l = 0; l < total; l++) {
                                    dbStartDate = subDays(dbStartDate, 1);
                                    dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                                }
                            }
                        }

                        Item.EndDate = dbEndDate;
                        Item.StartDate = dbStartDate;
                        Item.PreStartDate = dbStartDate;
                        Item.PreEndDate = dbEndDate;

                        values[i] = Item;
                    }
                }
            }
        });
        setInputFields(values);
    }

    function isSameDay(d1, d2) {
        return d1.getFullYear() === d2.getFullYear() &&
            d1.getDate() === d2.getDate() &&
            d1.getMonth() === d2.getMonth();
    }

    function diffDays(date, otherDate) {
        return Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24)) + 1;
    }

    function addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    function subDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() - days);
        return result;
    }

    function totalHolidayAndWE(startDate, endDate, leaveDays, taskHolderName) {
        let totalSaturday = 0;
        let totalSunday = 0;
        let totalHoliday = 0;
        let total = 0;
        let format = "MM/DD/YYYY";
        // Saturday,Sunday and Holiday Count
        var currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            if (currentDate.getDay() === 0) {
                totalSunday++;
            }
            if (currentDate.getDay() === 6) {
                totalSaturday++;
            }
            if (leaveDays === 2 || leaveDays === 5 || leaveDays === 6 || leaveDays === 8) {
                var newDate = new Date(moment(currentDate, "DD/MM/YYYY").format("MM/DD/YYYY"));
                let holidayList = genTNAHolidayList.filter(d => d.taskHolderName === taskHolderName);

                let HolidayCount = holidayList.filter(d => new Date(moment(d.holidayDate, format)) <= newDate && newDate <= new Date(moment(d.holidayEndDate, format))).length;
                if (HolidayCount > 0) {
                    totalHoliday++;
                }
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }
        // Check LeaveDays base Saturday,Sunday and Holiday Count
        if (leaveDays === 2) {
            total = totalSaturday + totalSunday + totalHoliday;
        } else if (leaveDays === 3) {
            total = totalSaturday + totalSunday;
        } else if (leaveDays === 4) {
            total = totalSunday;
        } else if (leaveDays === 5) {
            total = totalHoliday;
        } else if (leaveDays === 6) {
            total = totalSunday + totalHoliday;
        } else if (leaveDays === 7) {
            total = totalSaturday;
        } else if (leaveDays === 8) {
            total = totalSaturday + totalHoliday;
        }
        return total;
    }

    function checkDateBetweenInHolidayAndWE(flyDate, leaveDays, taskHolderName, status) {
        let format = "MM/DD/YYYY";
        let checkDate = new Date(moment(flyDate, "DD/MM/YYYY").format("MM/DD/YYYY"));
        let holidayList = genTNAHolidayList.filter(d => d.taskHolderName === taskHolderName);
        let HolidayCount = holidayList.filter(d => new Date(moment(d.holidayDate, format)) <= checkDate && checkDate <= new Date(moment(d.holidayEndDate, format))).length;
        //ReCheck Date in Saturday,Sunday and Holiday Count
        if (flyDate.getDay() === 0 || flyDate.getDay() === 6 || HolidayCount > 0) {
            let i = 0;
            while (i <= 3) {
                //Weekend Date Check
                if (flyDate.getDay() === 0 && (leaveDays === 2 || leaveDays === 3 || leaveDays === 4 || leaveDays === 6)) {
                    status === "sub" ? flyDate = subDays(flyDate, 1) : flyDate = addDays(flyDate, 1);
                } else {
                    i++;
                }
                if (flyDate.getDay() === 6 && (leaveDays === 2 || leaveDays === 3 || leaveDays === 7 || leaveDays === 8)) {
                    status === "sub" ? flyDate = subDays(flyDate, 1) : flyDate = addDays(flyDate, 1);
                } else {
                    i++;
                }

                if (leaveDays === 2 || leaveDays === 5 || leaveDays === 6 || leaveDays === 8) {
                    let checkDate = new Date(moment(flyDate, "DD/MM/YYYY").format("MM/DD/YYYY"));
                    let HolidayCount = holidayList.filter(d => new Date(moment(d.holidayDate, format)) <= checkDate && checkDate <= new Date(moment(d.holidayEndDate, format))).length;
                    if (HolidayCount > 0) {
                        status === "sub" ? flyDate = subDays(flyDate, 1) : flyDate = addDays(flyDate, 1);
                    } else {
                        i++;
                    }
                } else {
                    i++;
                }

                i === 3 ? i = 4 : i = 0;
            }
        }
        return flyDate;
    }

    //    ------------- **** Calculation End ***** --------------------------

    function convertUTCDateToLocalDate(date) {
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date;
    }

    const handleAddTaskChange = (e, index, childIndex, FieldName) => {

        if (e.target.checked) {
            values[index].FollowupDetails[childIndex].IsTaskSelected = 1;
            let IsSelected = 1;
            let UnselectedRecords = values[index].FollowupDetails.filter(x => x.IsTaskSelected === 0);
            if (UnselectedRecords.length > 0) {
                IsSelected = 0;
            }
            values[index].IsSelectAll = IsSelected;
            values[index].AdditionalFollowup = values[index].FollowupDetails.filter(x => x.IsTaskSelected === 1).length;

        } else {
            values[index].FollowupDetails[childIndex].IsTaskSelected = 0;
            values[index].IsSelectAll = 0;
            values[index].AdditionalFollowup = values[index].FollowupDetails.filter(x => x.IsTaskSelected === 1).length;
        }
        setInputFields(values);
    }

    const filterOption = (option, inputValue) => {
        let trimValue = inputValue.trim();
        if (trimValue === "") {
            return option;
        }
        return option.data.subLabel.toLowerCase().includes(trimValue.toLowerCase());
    };

    const handleClose = (index) => {
        values[index].IsPopupOpen = 0;
        setInputFields(values);
    };


    const SaveTNA = (e) => {
        setButtonLoader(true);
        e.preventDefault();
        let IsValid = true;

        if (!MainValues.SupplierIdList) {
            IsValid = false;
            setButtonLoader(false);
            Nodify('Warning!', 'warning', "Please fill the mandatory(*) fields.");
            setSubmitted(true);
            IsValid = false;
            return false;
        }
        else {

            if (genStrikeOffTNAReferenceList.length === 0) {
                IsValid = false;
                setButtonLoader(false);
                Nodify('Warning!', 'warning', "Please fill the mandatory(*) fields.");
                setSubmitted(true);
                return false;
            } else {
                let StrikeOffReferenceList = [];
                if (genStrikeOffTNAReferenceList && genStrikeOffTNAReferenceList.length > 0) {
                    genStrikeOffTNAReferenceList.map((val) => {
                        StrikeOffReferenceList.push({
                            GenStrikeOffRefernceID: val.genStrikeOffRefernceID, GenStrikeOffLogoId: val.genStrikeOffLogoID,
                            GenStrikeOffMainId: val.genStrikeOffMainId, GenStrikeOffId: val.genStrikeOffId
                        });
                    });
                }

                MainValues.GenTNAStrikeOffReferenceList = StrikeOffReferenceList;

                inputFields.map((Info, i) => {
                    if (Info.Duration === '' || Info.TaskHolderOwnerIdList.length === 0 || Info.LeaveDays === 0) {
                        IsValid = false;
                        setButtonLoader(false);
                        Nodify('Warning!', 'warning', "Please fill the mandatory(*) fields.");
                        setSubmitted(true);
                        return false;
                    }
                    else {
                        let Status = 0;
                        if (Info.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT) {
                            Status = 1
                        } else if (Info.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
                            Status = 2
                        }

                        MainValues.GenStrikeOffTNASubmissionAndApproval.push({
                            GenralStrikeOffTNASubmissionAndApprovalId: 0,
                            TaskTypeID: Info.TaskType,
                            TaskID: Info.TaskID,
                            Duration: Info.Duration && parseInt(Info.Duration),
                            PlanStartDate: convertUTCDateToLocalDate(new Date(Info.StartDate)),
                            EndDate: convertUTCDateToLocalDate(new Date(Info.EndDate)),
                            DependencyID: Info.DependencyID,
                            LeaveDays: Info.LeaveDays,
                            LogDays: Info.LogDays && parseInt(Info.LogDays),
                            TaskOwner: Info.TaskHolderOwnerIdList,
                            TaskHolderID: Info.TaskHolderOrFollower,
                            TaskHolderIDList: Info.TaskHolderBuyer && Info.TaskHolderBuyer.toString(),
                            FollowerIDList1: Info.FollowerCube && Info.FollowerCube.toString(),
                            FollowerIDList2: Info.FollowerSupplier && Info.FollowerSupplier.toString(),
                            IndexName: i.toString(),
                            Status: Status
                        })

                        if (Info.FollowupDetails) {
                            Info.FollowupDetails.forEach((child, k) => {
                                child.IndexName = i.toString();
                                MainValues.GenTNAAdditionalTaskDetails.push(child);
                            });
                        }
                    }
                });

                if (IsValid) {
                    dispatch(InsertGenralStrikeOffTNA(MainValues))
                        .then(data => {
                            if (data.outputResult === "1") {
                                let Params = { buyerId: BuyerId, brandId: BrandId, seasonId: SeasonId, buyerName: BuyerName, brandName: BrandName, seasonName: SeasonName }
                                props.location.state = { params: Params };
                                props.history.push('/SamGenlStrikeOffView', { params: Params });
                            } else if (data.outputResult === "2") {

                            } else if (data.outputResult === "-1") {

                                Nodify('Warning!', 'warning', 'error');
                            }
                            setButtonLoader(false);
                        })
                        .catch(e => {
                            console.log(e);
                        });

                }
            }

        }
    }

    const deleteReference = (index) => {
        dispatch(removeStrikeOffReferenceByIndex(index));
    }

    const CustomInput = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                menuPosition="fixed"
                placeholder="DD/MM/YYYY"
            />
        )
    }

    return (
        <Fragment>
            <div className="widget-body">
                <ReactNotification />
                <Form>
                    <div className="row">
                        <div className="col-sm-4">
                            <div className='form-group'>
                                <label> Supplier</label><span className="text-danger">*</span>
                                <span className='input-icon icon-right'>
                                    <Reactselect className="basic-single" name="Supplier"
                                        id={
                                            "Supplier"
                                        }
                                        ref={selectInputRef}
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={true}
                                        isSearchable={true}
                                        isMulti
                                        onChange={event => handleSupplierChange(event, "Supplier")}
                                        options={drpSupplierList}
                                        styles={submitted && MainValues.SupplierIdList === "" ? styles : ''}
                                    // value={getSeasonList}
                                    ></Reactselect>
                                </span>

                            </div>
                        </div>
                    </div>

                    <div className="widget-header ">
                        <span className="widget-caption">Add TNA</span>

                    </div>
                    <div className="widget-body">
                        <div className="col-md-12" style={{ overflowX: "auto" }}>


                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable" style={{ width: "110vm" }}>
                                <thead>
                                    <tr>

                                        <th style={{ width: "220px" }}>Task Type</th>
                                        <th style={{ width: "300px" }}>Task Name</th>
                                        <th style={{ width: "100px" }}>Duration<span className="text-danger">*</span></th>
                                        <th style={{ width: "177px" }}>Start Date</th>
                                        <th style={{ width: "225px" }}>End Date</th>
                                        <th style={{ width: "190px" }}>Dependency</th>
                                        <th style={{ width: "120px" }}>Log Days</th>
                                        <th style={{ width: "220px" }}>Task Holder / Follower<span className="text-danger">*</span></th>
                                        <th style={{ width: "250px" }}>Leave Days<span className="text-danger">*</span></th>
                                        <th style={{ width: "90px" }} className="text-center fixed-column-header-Route">Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        inputFields.map((inputField, index) => (
                                            <Fragment>
                                                <tr>
                                                    <td>
                                                        <span title={inputField.TaskTypeName}>
                                                            
                                                            {inputField.TaskTypeName}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span title= {inputField.TaskName} >
                                                             
                                                            {inputField.TaskName}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className='input-icon icon-right'>
                                                            <input type="text"
                                                                placeholder="Duration"
                                                                id={"Duration" + index}
                                                                name="Duration"
                                                                value={inputField.Duration}
                                                                onChange={event => handleChange(event, index, "Duration")}
                                                                // onChange={event => handleChange(event, index, "Duration")}
                                                                // onBlur={() => CheckDuplicate(index, 'Duration')}
                                                                maxLength="3"
                                                                className="form-control"
                                                                style={{ border: submitted && !inputField.Duration ? '1px solid red' : '' }}
                                                            />
                                                        </span>
                                                    </td>
                                                    <td>

                                                        <span className="input-icon icon-right">
                                                            <DatePicker className="form-control"
                                                                name="StartDate"
                                                                id={
                                                                    "StartDate" + index
                                                                }
                                                                selected={
                                                                    inputField.StartDate
                                                                }
                                                                dateFormat="dd/MM/yyyy"
                                                                readOnly={true}

                                                            />

                                                        </span>
                                                    </td>
                                                    <td>

                                                        <span className="input-icon icon-right">
                                                            <DatePicker className="form-control"
                                                                name="EndDate"
                                                                id={
                                                                    "EndDate" + index
                                                                }
                                                                selected={
                                                                    inputField.EndDate
                                                                }
                                                                onChange={
                                                                    event => handleChange(event, index, "EndDate")
                                                                }
                                                                dateFormat="dd/MM/yyyy"
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="scroll"
                                                                autoComplete="off"
                                                                popperProps={{
                                                                    positionFixed: true // use this to make the popper position: fixed
                                                                }}
                                                                //menuPosition="fixed"
                                                                // minDate={inputField.HolidayDate === "" ? (new Date("01/01/" + getselectedDate)) : inputField.HolidayDate}
                                                                //maxDate={new Date("12/31/" + getselectedDate)}
                                                                customInput={<CustomInput />}
                                                            // isClearable={inputField.EndDate === '' ? false : true}
                                                            />

                                                        </span>
                                                    </td>
                                                    <td>

                                                        <span className='input-icon icon-right'>
                                                            {index !== 0 &&
                                                                <Reactselect className="basic-single" name="DependencyID"
                                                                    id={
                                                                        "DependencyID" + index
                                                                    }
                                                                    isLoading={false}
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    onChange={event => handleChange(event, index, "DependencyID")}
                                                                    value={getDependencyList.filter(function (option) {
                                                                        return option.value === inputField.DependencyID;
                                                                    })}
                                                                    options={getDependencyList}
                                                                    menuPosition="fixed"
                                                                ></Reactselect>
                                                            }

                                                        </span>

                                                    </td>
                                                    <td>
                                                        <span className='input-icon icon-right'>
                                                            {index !== 0 &&
                                                                <input type="text"
                                                                    placeholder="Log days"
                                                                    id={"LogDays" + index}
                                                                    name="LogDays"
                                                                    className="form-control"
                                                                    onChange={event => handleChange(event, index, "LogDays")}
                                                                    value={inputField.LogDays}
                                                                // onChange={event => handleChildChange(event, index, childIndex, "LogDays")}
                                                                // maxLength="3"
                                                                // className={'form-control' + (
                                                                //     submitted && !inputField.LogDays ? ' is-invalid' : ''
                                                                // )}
                                                                // disabled={ChildField.DependencyID === 0 ? true : false}
                                                                />
                                                            }
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span
                                                            className={'form-control fa fa-eye'} onClick={() => handleTaskHolderLeaveDays(index)}
                                                            style={{ width: "100%", paddingTop: "8px", float: "left", border: submitted && !inputField.TaskHolderOwnerIdList ? '1px solid red' : '' }}
                                                        >&nbsp;&nbsp;&nbsp;
                                                            {
                                                                getTaskHolderList.filter(d => d.value === inputField.TaskHolderOrFollower).map(task => {
                                                                    return task.label;
                                                                })
                                                            }
                                                        </span>



                                                    </td>
                                                    <td>
                                                        <input type="text"
                                                            value={inputField.LeaveDaysTxt}
                                                            title={inputField.LeaveDaysTxt}
                                                            onClick={() => handleTaskHolderLeaveDays(index)}
                                                            className={'form-control'}
                                                            style={{ width: "100%", float: "left", border: submitted && !inputField.LeaveDaysTxt ? '1px solid red' : '' }}
                                                        />

                                                    </td>

                                                    <td className="fixed-column-Route">

                                                        {
                                                            inputField.IsTHLeaveDaysOpen === 1 &&

                                                            <Modal show={inputField.IsTHLeaveDaysOpen === 1} size="lg" backdrop="static" keyboard={false} onHide={() => handleTHLeaveDaysClose(index)}>
                                                                <Modal.Header closeButton>
                                                                    <Modal.Title>
                                                                        Leave Days / In-charges : {inputField.TaskName}
                                                                    </Modal.Title>
                                                                </Modal.Header>
                                                                <Modal.Body>
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className='form-group'>
                                                                                <label> Task Holder / Follower </label>
                                                                                <span className='input-icon icon-right'>
                                                                                    <Reactselect className="basic-single" name="TaskHolderOrFollower"
                                                                                        id={"TaskHolderOrFollower"}
                                                                                        isLoading={false}
                                                                                        isClearable={true}
                                                                                        isSearchable={true}
                                                                                        onChange={event => handleChange(event, index, "TaskHolderOrFollower")}
                                                                                        value={getTaskHolderList.filter(function (option) {
                                                                                            return option.value === inputField.TaskHolderOrFollower;
                                                                                        })}
                                                                                        options={getTaskHolderList}
                                                                                        styles={submitted && inputField.TaskHolderOrFollower === 0 ? styles : ''}
                                                                                        menuPosition="fixed"
                                                                                        filterOption={filterOption}
                                                                                    ></Reactselect>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className='form-group'>
                                                                                <label>Leave Days</label>
                                                                                <span className='input-icon icon-right'>
                                                                                    <Reactselect className="basic-single" name="LeaveDays"
                                                                                        id={
                                                                                            "LeaveDays"
                                                                                        }
                                                                                        isLoading={false}
                                                                                        isClearable={true}
                                                                                        isSearchable={true}
                                                                                        onChange={event => handleChange(event, index, "LeaveDays")}
                                                                                        value={getLeaveDaysList.filter(function (option) {
                                                                                            return option.value === inputField.LeaveDays;
                                                                                        })}
                                                                                        options={getLeaveDaysList}
                                                                                        styles={submitted && inputField.LeaveDays === 0 ? styles : ''}
                                                                                        menuPosition="fixed"

                                                                                    ></Reactselect>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {inputField.titleEmployee1 && <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className='form-group'>
                                                                                <label htmlFor={inputField.titleEmployee1 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee1 === "Cube" ? "FollowerCube" : "FollowerSupplier"} >Task Holder - {inputField.titleEmployee1 + " Employee"}<span className="text-danger">*</span> </label>
                                                                                <span className='input-icon icon-right'>
                                                                                    <Reactselect className="basic-single" name={inputField.titleEmployee1 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee1 === "Cube" ? "FollowerCube" : "FollowerSupplier"}
                                                                                        isDisabled={false}
                                                                                        isLoading={false}
                                                                                        isClearable={true}
                                                                                        isSearchable={true}
                                                                                        isMulti
                                                                                        onChange={event => handleChange(event, index, inputField.titleEmployee1 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee1 === "Cube" ? "FollowerCube" : "FollowerSupplier")}
                                                                                        options={inputField.titleEmployee1 === "Buyer" ? drpGLDTNATaskOwnerList.buyerEmployeeList : inputField.titleEmployee1 === "Cube" ? drpGLDTNATaskOwnerList.cubeEmployeeList : drpGLDTNATaskOwnerList.supplierEmployeeList}
                                                                                        value={inputField.titleEmployee1 === "Buyer" ?
                                                                                            inputField.arrTaskHolderBuyer : inputField.titleEmployee1 === "Cube" ? inputField.arrFollowerCube : inputField.arrFollowerSupplier}
                                                                                    >  </Reactselect>

                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    }

                                                                    {
                                                                        inputField.titleEmployee2 && <div className="row">
                                                                            <div className="col-md-12">
                                                                                <div className='form-group'>
                                                                                    <label htmlFor={inputField.titleEmployee2 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee2 === "Cube" ? "FollowerCube" : "FollowerSupplier"} >Follower - {inputField.titleEmployee2 + " Employee"}</label>
                                                                                    <span className='input-icon icon-right'>
                                                                                        <Reactselect className="basic-single" name={inputField.titleEmployee2 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee2 === "Cube" ? "FollowerCube" : "FollowerSupplier"}
                                                                                            isDisabled={false}
                                                                                            isLoading={false}
                                                                                            isClearable={true}
                                                                                            isSearchable={true}
                                                                                            isMulti
                                                                                            onChange={event => handleChange(event, index, inputField.titleEmployee2 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee2 === "Cube" ? "FollowerCube" : "FollowerSupplier")}
                                                                                            options={inputField.titleEmployee2 === "Buyer" ? drpGLDTNATaskOwnerList.buyerEmployeeList : inputField.titleEmployee2 === "Cube" ? drpGLDTNATaskOwnerList.cubeEmployeeList : drpGLDTNATaskOwnerList.supplierEmployeeList}
                                                                                            value={inputField.titleEmployee2 === "Buyer" ? inputField.arrTaskHolderBuyer : inputField.titleEmployee2 === "Cube" ? inputField.arrFollowerCube : inputField.arrFollowerSupplier}
                                                                                        // options={IMPORTANCE}
                                                                                        // value={getStyleList.arrImportance}
                                                                                        //  styles={submitted && getStyleList.arrImportance === 0 ? styles : ''}
                                                                                        >  </Reactselect>

                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {inputField.titleEmployee3 && <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className='form-group'>
                                                                                <label htmlFor="FollowerSupplier" >Follower - {inputField.titleEmployee3 +" Employee"}</label>
                                                                                <span className='input-icon icon-right'>
                                                                                    <Reactselect className="basic-single" name={inputField.titleEmployee3 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee3 === "Cube" ? "FollowerCube" : "FollowerSupplier"}
                                                                                        isDisabled={false}
                                                                                        isLoading={false}
                                                                                        isClearable={true}
                                                                                        isSearchable={true}
                                                                                        isMulti
                                                                                        onChange={event => handleChange(event, index, inputField.titleEmployee3 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee3 === "Cube" ? "FollowerCube" : "FollowerSupplier")}
                                                                                        options={inputField.titleEmployee3 === "Buyer" ? drpGLDTNATaskOwnerList.buyerEmployeeList : inputField.titleEmployee3 === "Cube" ? drpGLDTNATaskOwnerList.cubeEmployeeList : drpGLDTNATaskOwnerList.supplierEmployeeList}
                                                                                        value={inputField.titleEmployee3 === "Buyer" ? inputField.arrTaskHolderBuyer : inputField.titleEmployee3 === "Cube" ? inputField.arrFollowerCube : inputField.arrFollowerSupplier}
                                                                                    // options={IMPORTANCE}
                                                                                    // value={getStyleList.arrImportance}
                                                                                    //  styles={submitted && getStyleList.arrImportance === 0 ? styles : ''}
                                                                                    >  </Reactselect>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    }



                                                                </Modal.Body>
                                                                <Modal.Footer>
                                                                    <Button variant="success" onClick={() => handleTHLeaveDaysSave(index)}>
                                                                        Save
                                                                    </Button>

                                                                    <Button variant="success" onClick={() => handleTHLeaveDaysSaveNext(index)}>
                                                                        Save & Next
                                                                    </Button>

                                                                </Modal.Footer>
                                                            </Modal>
                                                        }
                                                        {
                                                            inputField.IsPopupOpen === 1 &&

                                                            <Modal show={inputField.IsPopupOpen === 1} size="md" dragable backdrop="static" keyboard={false} onHide={() => handleClose(index)}>
                                                                <Modal.Header closeButton>
                                                                    <Modal.Title>Additional Task</Modal.Title>
                                                                </Modal.Header>
                                                                <Modal.Body>
                                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>#</th>
                                                                                <th>Task Name</th>
                                                                                <th> <div className="checkbox" style={{ marginTop: "0px" }}>
                                                                                    <label>
                                                                                        <input type="checkbox"
                                                                                            name="IsSelectAll"
                                                                                            id={"IsSelectAll_" + index}
                                                                                            isMulti
                                                                                            onChange={event => handleChange(event, index, "IsSelectAll")}
                                                                                            checked={inputField.IsSelectAll === 1 ? true : false}
                                                                                            className="colored-blue clearOpacity" />
                                                                                        <span className="text"></span>
                                                                                    </label>
                                                                                </div></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                inputField.FollowupDetails.map((ChildField, childIndex) => (
                                                                                    <Fragment key={
                                                                                        `${inputField}~${index}`
                                                                                    }>
                                                                                        <tr>
                                                                                            <td>{childIndex + 1}</td>
                                                                                            <td>
                                                                                                <input type="text"
                                                                                                    value={ChildField.AddtionalTaskName}
                                                                                                    className={'form-control'}
                                                                                                    disabled
                                                                                                />
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="checkbox" style={{ marginTop: "0px" }}>
                                                                                                    <label>
                                                                                                        <input type="checkbox"
                                                                                                            name="IsTaskSelected"
                                                                                                            id={"IsTaskSelected_" + index + "_" + childIndex}
                                                                                                            isMulti
                                                                                                            onChange={event => handleAddTaskChange(event, index, childIndex, "IsTaskSelected")}
                                                                                                            checked={ChildField.IsTaskSelected === 1 ? true : false}
                                                                                                            className="colored-blue clearOpacity" />
                                                                                                        <span className="text"></span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </Fragment>
                                                                                ))
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </Modal.Body>
                                                                <Modal.Footer>
                                                                    <Button variant="success" onClick={() => handleClose(index)}>
                                                                        Save
                                                                    </Button>
                                                                </Modal.Footer>
                                                            </Modal>
                                                        }

                                                        <button type="button" className="btn btn-xs btn-primary" title={"Additional Task"}
                                                            onClick={() => handleFollowup(index)}>
                                                            <b> {inputField.AdditionalFollowup} </b>
                                                            <i className="fa fa-tasks"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </Fragment>
                                        ))
                                    }
                                    {
                                        inputFields.length === 0 ?
                                            <tr>
                                                <td colSpan="10" className='norecordfound'><span>No Records Found</span></td>
                                            </tr> : ''
                                    }


                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="widget-header ">
                        <span className="widget-caption">Reference Information</span>

                    </div>
                    <div className="widget-body">
                        <div className="col-md-12" style={{ overflowX: "auto", minHeight: '100px' }}>


                            <table className="table table-striped table-bordered table-hover dataTable no-footer" style={{ width: "100%" }}>
                                <thead>
                                    <tr>

                                        <th >Strike Off Code / Name</th>
                                        <th >Image</th>
                                        <th >Received Date</th>
                                        {/* <th style={{ width: "450px" }}>Story</th>
                                        <th style={{ width: "450px" }}>Theme</th> */}
                                        <th >Details</th>
                                        <th>Purchase Type</th>
                                        {/* <th style={{ width: "450px" }}>Trim Supplier</th> */}
                                        <th >Approval Type</th>
                                        <th className="text-center fixed-column-header-Route">Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        genStrikeOffTNAReferenceList && genStrikeOffTNAReferenceList.length > 0 ?
                                            <>
                                                {
                                                    genStrikeOffTNAReferenceList.map((inputField, indexVal) => (
                                                        <Fragment>
                                                            <tr>
                                                                <td>
                                                                    <span>
                                                                        {inputField.strikeOff}
                                                                    </span>
                                                                </td>
                                                                <td>

                                                                    <div className="trwidth_manual" style={{ textAlign: "center" }}>
                                                                        <img className="width_manual"
                                                                            src={inputField.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images/Style/Strikeoff/" + inputField.imagePath}
                                                                            alt="Logo"
                                                                            onClick={
                                                                                () => setIsOpen(inputField.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images/Style/Strikeoff/" + inputField.imagePath)
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span>
                                                                    {moment(inputField.recievedDate).format('DD/MM/YYYY')}
                                                                        {/* {inputField.recievedDate} */}
                                                                    </span>
                                                                </td>
                                                                {/* <td>
                                                        <span>
                                                            {inputField.storyName}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span>
                                                            {inputField.themeName}
                                                        </span>
                                                    </td> */}
                                                                <td>
                                                                    <span>
                                                                        {inputField.details}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span>
                                                                        {inputField.purchaseTypeName}
                                                                    </span>
                                                                </td>
                                                                {/* <td>
                                                        <span>
                                                            {inputField.trimSupplierName}
                                                        </span>
                                                    </td> */}
                                                                <td>
                                                                    <span>
                                                                        {inputField.approvalTypeName}
                                                                    </span>
                                                                </td>
                                                                <td className="fixed-column-Route">
                                                                    <span title='Delete'
                                                                        className="btn btn-danger btn-xs delete" onClick={() => deleteReference(indexVal)} >
                                                                        <i className="fa fa-trash-o"></i>

                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    ))
                                                }
                                            </>
                                            :

                                            <tr><td colSpan="7" className='norecordfound'><span>No Direct New Approval Type based Strike off found </span></td></tr>

                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>
                                <span className="btn btn-primary"
                                    onClick={
                                        () => ResetOperation("Back")
                                    }
                                >
                                    <i className="fa fa-arrow-circle-left"></i>
                                    &nbsp;Back</span>
                                <span className="btn btn-danger"
                                    onClick={
                                        () => ResetOperation()
                                    }
                                >
                                    <i className="fa fa-times"></i>
                                    &nbsp;Reset</span>
                                {
                                    genStrikeOffTNAReferenceList && genStrikeOffTNAReferenceList.length > 0 &&
                                    <button type="button" disabled={buttonLoader} className="btn btn-success"
                                        onClick={SaveTNA}>
                                        <i className="fa fa-check right"></i>
                                        &nbsp;Save</button>
                                }

                            </div>
                        </div>
                    </div>
                </Form>
            </div >
            {loader}
        </Fragment >
    );
}

export default SamGenStrikeOffAddTNA;