import React, { useState, useEffect, useRef, Fragment } from "react";
import ReactNotification from 'react-notifications-component';
import Nodify from "../../Common/ReactNotification"
import SearchRetain from "../../Common/SearchRetain";
import PantoneService from "../../../services/Master/PantoneService";
import { useSelector } from "react-redux";

const AddPantoneType = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);

    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    if (menulist !== undefined) {
        activeMenu = menulist.filter(i => i.menuName === "PantoneType");
    }
    //end enable menu
    const EmptyInputFields =
        [{
            PantoneTypeID: 0,
            PantoneTypeName: '',
        }]

    const [inputFields, setInputFields] = useState(EmptyInputFields);
    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [ExistingList, setExistingList] = useState([]);
    useEffect(() => {
        PantoneService.getPantoneTypelist().then((response) => {
            if (response.data) {
                setExistingList(response.data);
            }
        });
    }, []);

    const values = [...inputFields];

    const handleAddFields = (index) => {

        if (values[index].PantoneTypeName.trim() !== '') {
            values.push({
                PantoneTypeID: 0,
                PantoneTypeName: ''
                // Status:1
            });
            setInputFields(values);
            setSubmitted(false);
        }
        else {
            Nodify('Warning!', 'warning', 'Please fill this pantone type mandatory fields.');
            setSubmitted(true);
        }
    };
    const handleInputChange = (index, event) => {

        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }
        values[index].PantoneTypeName = inputText;
        setInputFields(values);
    };

    const handleRemoveFields = index => {

        values.splice(index, 1);
        setInputFields(values);
    };

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/TypePantoneList');
        } else {
            // setInputFields(EmptyInputFields);
            // setSubmitted(false);
            // setButtonLoader(false);
            window.location.reload();

        }
    }
    const CheckOnblur = (index) => {

        //   PantoneService.getPantoneTypelist().then((response) => {
        if (ExistingList) {
            for (var i = 0; i < ExistingList.length; i++) {
                if (ExistingList[i].pantoneTypeName.trim().toLowerCase() === values[index].PantoneTypeName.trim().toLowerCase()) {
                    values[index].PantoneTypeName = '';
                    setInputFields(values);
                    Nodify('Warning!', 'warning', "This name is already exist.");
                    return false;
                }
            }
        }
        //   });

        for (var j = 0; j < values.length; j++) {
            if (index !== j) {
                if (values[j].PantoneTypeName !== '') {
                    if (values[index].PantoneTypeID === values[j].PantoneTypeID) {
                        if (values[index].PantoneTypeName.trim().toLowerCase() === values[j].PantoneTypeName.trim().toLowerCase()) {
                            values[index].PantoneTypeName = '';
                            setInputFields(values);
                            Nodify('Warning!', 'warning', 'Pantone Type already exist.');
                            return false;
                        }
                    }
                }
            }
        }
    }

    const ValidationPopup = (Msg) => {
        Nodify('Warning!', 'warning', Msg);
        setButtonLoader(false);
        return false;
    }

    const SavePantoneInfo = (e) => {
        setButtonLoader(true);
        e.preventDefault();

        if (values.length === 1 && values[0].PantoneTypeName === '') {
            setButtonLoader(false);
            setSubmitted(true);
            ValidationPopup("Please fill atleast one Pantone type.");
        }
        else {
            let Isvalid = false;
            for (var j = 0; j < values.length; j++) {
                if (values[j].PantoneTypeName === '') { Isvalid = true }
            }
            if (!Isvalid) {
                setSubmitted(false);
                let letPantoneType = {};
                letPantoneType.PantoneTypeInformation = values;
                letPantoneType.Createdby = currentUser.employeeinformationID;

                PantoneService.InsertUpdatePantoneTypeGrid(letPantoneType).then(res => {
                    var page = "Remove";
                    SearchRetain(page);
                    if (res.data.outputResult === "1") {
                        PageRedirect('Add');
                    }
                    else if (res.data.outputResult === "0") {
                        setButtonLoader(false);
                        ValidationPopup("Error Occured!");
                    }
                });
            }
            else {
                setButtonLoader(false);
                setSubmitted(true);
                ValidationPopup("Please fill this pantone type mandatory fields.");
            }
        }

    }

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/TypePantoneList",
            state: {
                message: Func
            }
        });
    }

    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">Add Pantone Type</h3>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <form>
                                    {
                                        inputFields.map((inputField, index) => (
                                            <Fragment key={
                                                `${inputField}~${index}`
                                            }>
                                                <div className="row row_left10">
                                                    <div className="col-sm-3 divder_style">
                                                        <div className="form-group">
                                                            {/* <label htmlFor={"PantoneTypeName" + index}>Pantone Type<span className="text-danger">*</span></label> */}

                                                            <span className="input-icon icon-right">
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"PantoneTypeName_" + index}
                                                                    name="PantoneTypeName"
                                                                    placeholder="Enter Pantone Type"
                                                                    maxLength="50"
                                                                    value={inputField.PantoneTypeName}
                                                                    onChange={event => handleInputChange(index, event)}
                                                                    style={{ border: submitted && !inputField.PantoneTypeName ? '1px solid red' : '' }}
                                                                    onBlur={event => CheckOnblur(index)}
                                                                    autoFocus
                                                                />
                                                            </span>

                                                        </div>


                                                    </div>
                                                    {/* <div className="form-group col-sm-3">
                                                          <span className="input-icon icon-right">
                                                                <input
                                                                    type="checkbox"
                                                                    className="checkbox-slider checkbox-slider_Status colored-blue"
                                                                    id={"BuyerInfoStatus_"+index}
                                                                    name="BuyerInfoStatus"
                                                                    value={
                                                                        inputField.Status
                                                                    }
                                                                    checked={inputField.Status === 1 ? true : false}
                                                                    autoComplete="off"
                                                                    onChange={
                                                                        event => handleInputChange(index, event)
                                                                    }
                                                                ></input>
                                                                <span className="text"></span>
                                                            </span>
                                                        </div> */}
                                                    <div className="col-sm-3 paddingstatic">
                                                        {
                                                            inputFields.length !== 1 && (
                                                                <div className="col-lg-1_own col-xs-12">
                                                                    <button type="button" className="btn btn-danger"
                                                                        style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}
                                                                        title="Delete Pantone Type" onClick={() => handleRemoveFields(index)}>
                                                                        <i className="fa fa-trash-o"></i>
                                                                    </button>
                                                                </div>
                                                            )}
                                                        {
                                                            inputFields.length === (index + 1) && (
                                                                <div className="col-lg-1_own col-xs-12">

                                                                    <button type="button" className="btn btn-success" title="Add Pantone Type" onClick={() => handleAddFields(index)}>
                                                                        <i className="fa fa-plus"></i>
                                                                    </button>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </Fragment>
                                        ))
                                    }
                                    <div className="col-sm-12">
                                        <div className="buttons-preview" align="right">
                                            <span className="btn btn-primary"
                                                onClick={
                                                    () => ResetOperation("Back")
                                                }>
                                                <i className="fa fa-arrow-circle-left"></i>
                                                &nbsp;Back</span>
                                            <span className="btn btn-danger"
                                                onClick={
                                                    () => ResetOperation()
                                                }>
                                                <i className="fa fa-times"></i>
                                                &nbsp;Reset</span>
                                            <button type="submit" className="btn btn-success"
                                                onClick={SavePantoneInfo}
                                                disabled={buttonLoader}
                                            >
                                                {/* {
                                                                buttonLoader === true ? <span className="fa fa-spinner"></span> : ""
                                                            } */}
                                                <i className="fa fa-check right"></i>
                                                &nbsp;Save</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddPantoneType;