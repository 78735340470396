import {
 QA_LOADING,
 RETRIEVE_QA,
 DELETE_QA
} from "../actions/types";

const initialState = {

 isLoadingQA: true,
 qaList: [],
};

const QAReducer = (state = initialState, action) => {
 const { type, payload } = action;
 switch (type) {
  case QA_LOADING:
   return {
    ...state,
    isLoadingitems: payload
   };

  case DELETE_QA:
   
   const results = state.qaList.filter(c => c.commentID !== payload.commentID);
   return {
    ...state,
    qaList: results
   };
  case RETRIEVE_QA:
   return { ...state, qaList: payload };
  default:
   return state;
 }

}

export default QAReducer;