import {
    GENERALTRIM_LOADING,
    RETRIEVE_GENERALTRIM,
    DELETE_GENERALTRIM,
    RETRIEVE_GENERAL_TRIM_DETAIL_VIEW_LIST,
    CLEAR_GENERAL_TRIM_DETAIL_VIEW_SUB_ROW,
    UPDATE_GENERAL_TRIM_DETAIL_VIEW_CHECK_BOX,
    NEW_TNA_GENERAL_TRIM_REFERENCE_LIST,
    REMOVE_TNA_GENERAL_TRIM_REFERENCE_LIST,
    UPDATE_GENERAL_TRIM_DETAIL_VIEW_ALL_CHECK_BOX,
    RETRIEVE_GENERAL_TRIM_SUBMISSION_LIST,
    RETRIEVE_GENERAL_TRIM_APPROVAL_LIST,
    CLEAR_GENERAL_TRIM_SUBMISSION_SUB_ROW,
    TNA_TASK_GENERAL_TRIM_SUBMISSION_AND_APPROVAL,
    CLEAR_GENERAL_TRIM_APPROVAL_SUB_ROW,
    RETRIEVE_GENERAL_TRIM_APPROVAL_VIEW_LIST,
    CLEAR_GENERAL_TRIM_APPROVAL_VIEW_SUB_ROW,
    UPDATE_GENERAL_TRIM_IS_APPROVAL_MANUAL_CLOSE_POPUP,
    GENERAL_TRIM_REFERENCE_STATUS_LIST,
    REMOVE_GENERAL_TRIM_REQUEST_VIEW_SUB_ROW
} from "./types";
import GeneralService from "../services/General/GeneralService";

const generalTrimLoading = (isStatus) => ({
    type: GENERALTRIM_LOADING,
    payload: isStatus,
});

export const deletegeneralTrim = (genTrimId) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_GENERALTRIM,
            payload: genTrimId,
        });

    } catch (err) {
        console.log(err);
    }
};



export const retrievegeneralTrim = (IsProduction) => async (dispatch) => {
    try {
        dispatch(generalTrimLoading(true));
        const res = await GeneralService.GetGeneralTrimList(IsProduction);
        dispatch({
            type: RETRIEVE_GENERALTRIM,
            payload: res.data,
        });
        dispatch(generalTrimLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(generalTrimLoading(false));
    }
};

export const getGeneralTrimDetailsViewList = (params) => async (dispatch) => {
    try {
        dispatch(generalTrimLoading(true));
        const res = await GeneralService.getGeneralTrimDetailsViewList(params);
        dispatch({
            type: RETRIEVE_GENERAL_TRIM_DETAIL_VIEW_LIST,
            payload: { data: res.data, params: params },
        });
        dispatch(generalTrimLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(generalTrimLoading(false));
    }
};

export const clearTrimDetailsViewSubRow = (index) => async (dispatch) => {
    dispatch(generalTrimLoading(true));
    dispatch({
        type: CLEAR_GENERAL_TRIM_DETAIL_VIEW_SUB_ROW,
        payload: index,
    });
    dispatch(generalTrimLoading(false));

};
export const UpdateGenTrimDetailsViewAllCheckBox= (isChecked) => async (dispatch) => {
    dispatch(generalTrimLoading(true));
    dispatch({
        type: UPDATE_GENERAL_TRIM_DETAIL_VIEW_ALL_CHECK_BOX,
        payload: isChecked,
    });
    dispatch(generalTrimLoading(false));

};

export const UpdateGenTrimDetailsViewCheckBox = (params) => async (dispatch) => {
    dispatch(generalTrimLoading(true));
    dispatch({
        type: UPDATE_GENERAL_TRIM_DETAIL_VIEW_CHECK_BOX,
        payload: params,
    });
    dispatch(generalTrimLoading(false));

};



export const getGenTNATrimReferenceList = (params) => async (dispatch) => {
    try {
        dispatch(generalTrimLoading(true));
        const res = await GeneralService.GetGenTNATrimReferenceList(params);
        dispatch({
            type: NEW_TNA_GENERAL_TRIM_REFERENCE_LIST,
            payload: res.data,
        });
        dispatch(generalTrimLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(generalTrimLoading(false));
    }
};


export const removeTrimReferenceByIndex = (index) => async (dispatch) => {

    try {
        dispatch(generalTrimLoading(true));
        dispatch({
            type: REMOVE_TNA_GENERAL_TRIM_REFERENCE_LIST,
            payload: index,
        });
        dispatch(generalTrimLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(generalTrimLoading(false));
    }
};


export const InsertGenralTrimTNA = (mainValues) => async (dispatch) => {
    let resData = "";
    dispatch(generalTrimLoading(true));
    await GeneralService.InsertGenralTrimTNA(mainValues).then((res) => {
        resData = res.data;
        dispatch(generalTrimLoading(false));
    }).catch((err) => {
        dispatch(generalTrimLoading(false));
        return Promise.reject(err);
    });
    return Promise.resolve(resData);
}



export const GetGeneralTrimSubmissionApprovalGridList = (params) => async (dispatch) => {
    try {
       dispatch(generalTrimLoading(true));
        const res = await GeneralService.GetGeneralTrimSubmissionApprovalGridList(params);
        dispatch({
            type: RETRIEVE_GENERAL_TRIM_SUBMISSION_LIST,
            payload: { data: res.data, params: params },
        });
         dispatch(generalTrimLoading(false));

    } catch (err) {
        console.log(err);
         dispatch(generalTrimLoading(false));
    }
};



export const UpdateGenralTrimTNA= (mainValues) => async (dispatch) => {
    let resData = "";
     dispatch(generalTrimLoading(true));
    await GeneralService.UpdateGenralTrimTNA(mainValues).then((res) => {
        resData = res.data;
         dispatch(generalTrimLoading(false));
    }).catch((err) => {
         dispatch(generalTrimLoading(false));
        return Promise.reject(err);
    });
    return Promise.resolve(resData);
}

export const clearTrimSubmissionSubRow = (index) => async (dispatch) => {
   dispatch(generalTrimLoading(true));
    dispatch({
        type: CLEAR_GENERAL_TRIM_SUBMISSION_SUB_ROW,
        payload: index,
    });
   dispatch(generalTrimLoading(false));

};

export const getGenTrimTNASubmissionAndApproval = (genTrimSupplierTNAId, genTrimDetailTNAId) => async (dispatch) => {
    let resData = "";
   
    dispatch(generalTrimLoading(true));
    await GeneralService.GetGenTrimTNATASKSubmissionAndApproval(genTrimSupplierTNAId, genTrimDetailTNAId).then((res) => {
        resData = res.data;
        dispatch({
            type: TNA_TASK_GENERAL_TRIM_SUBMISSION_AND_APPROVAL,
            payload: res.data,
        });
        dispatch(generalTrimLoading(false));
    }).catch((err) => {
        dispatch(generalTrimLoading(false));
        return Promise.reject(err);
    });
  return Promise.resolve(resData);
}


export const GetGeneralTrimApprovalList = (params) => async (dispatch) => {
    try {
       dispatch(generalTrimLoading(true));
        const res = await GeneralService.GetGeneralTrimSubmissionApprovalGridList(params);
        dispatch({
            type: RETRIEVE_GENERAL_TRIM_APPROVAL_LIST,
            payload: { data: res.data, params: params },
        });
         dispatch(generalTrimLoading(false));

    } catch (err) {
        console.log(err);
         dispatch(generalTrimLoading(false));
    }
};

export const clearTrimApprovalSubRow = (index) => async (dispatch) => {
    dispatch(generalTrimLoading(true));
    dispatch({
        type: CLEAR_GENERAL_TRIM_APPROVAL_SUB_ROW,
        payload: index,
    });
    dispatch(generalTrimLoading(false));

};

export const GetGeneralTrimApprovalViewList = (params) => async (dispatch) => {
    try {
        dispatch(generalTrimLoading(true));
        const res = await GeneralService.GetGeneralTrimSubmissionApprovalGridList(params);
        dispatch({
            type: RETRIEVE_GENERAL_TRIM_APPROVAL_VIEW_LIST,
            payload: { data: res.data, params: params },
        });
        dispatch(generalTrimLoading(false));

    } catch (err) {
        console.log(err);
        dispatch(generalTrimLoading(false));
    }
};

export const clearTrimApprovalViewSubRow = (index) => async (dispatch) => {
    dispatch(generalTrimLoading(true));
    dispatch({
        type: CLEAR_GENERAL_TRIM_APPROVAL_VIEW_SUB_ROW,
        payload: index,
    });
    dispatch(generalTrimLoading(false));

};


export const UpdateIsApprovalManualClosePopup = (index, subIndex, isOpen) => async (dispatch) => {
     dispatch(generalTrimLoading(true));
    dispatch({
        type: UPDATE_GENERAL_TRIM_IS_APPROVAL_MANUAL_CLOSE_POPUP,
        payload: {index, subIndex, isOpen},
    });
    dispatch(generalTrimLoading(false));

};

export const getGenTrimTNARefernceStatusList = (params) => async (dispatch) => {
    let resData = "";
    dispatch(generalTrimLoading(true));
    await GeneralService.GetGenTrimTNARefernceStatusList(params).then((res) => {
        resData = res.data;
        dispatch({
            type: GENERAL_TRIM_REFERENCE_STATUS_LIST,
            payload: res.data,
        });
        dispatch(generalTrimLoading(false));
    }).catch((err) => {
        dispatch(generalTrimLoading(false));
        return Promise.reject(err);
    });
    return Promise.resolve(resData);
   
};

export const RemoveTrimRequestRow =(params)=> async (dispatch) => {
    dispatch(generalTrimLoading(true));
    dispatch({
        type: REMOVE_GENERAL_TRIM_REQUEST_VIEW_SUB_ROW,
        payload: params,
    });
    dispatch(generalTrimLoading(false));

};


