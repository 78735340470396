import {
    CITY_LOADING,
    RETRIEVE_CITY,
    DELETE_CITY,
} from "../actions/types";

const initialState = {
    isLoadingCity: true,
    cityList: [],
};

const cityReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case CITY_LOADING:
            return {
                ...state,
                isLoadingCity: payload
            };
        case RETRIEVE_CITY:
            return { ...state, cityList: payload };
        case DELETE_CITY:
            const results = state.cityList.filter(c => c.cityID !== payload.cityID);
            return {
                ...state,
                cityList: results
            };

        default:
            return state;
    }
};
export default cityReducer;