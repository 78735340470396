import React, { useState, useEffect, Fragment } from "react";
import ReactNotification from 'react-notifications-component';
import Nodify from "../../Common/ReactNotification"
import SearchRetain from "../../Common/SearchRetain";
import Packageimage from "../../../services/Master/PackageImage";
import { useSelector } from "react-redux";
import CommonService from '../../../services/Common/CommonService';
const EditPackageImage = (props) => {
    const [getpackageImageList, setPackageImageList] = useState({});
    const [styleskuimage, setstyleskuimage] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const { user: currentUser } = useSelector((state) => state.auth);
    const [getLabedispaly, setLabedispaly] = useState(false);
    const Values = { ...getpackageImageList };
    function BindPackageimageValue(props) {
       
        let CurrencyInfoValue = { PackageImageID: 0, PackingImageName: '', IsSkuimageID: 0, IsSkuimage: '', CreatedBy: currentUser.employeeinformationID, };
        if (props.location.state !== undefined) {
            if (props.location.state.params.length !== 0) {
                if (props.location.state.params.currencyId !== 0) {
                    CurrencyInfoValue.PackageImageID = props.location.state.params.packageImageID;
                    CurrencyInfoValue.PackingImageName = props.location.state.params.packingImageName;
                    CurrencyInfoValue.IsSkuimageID = props.location.state.params.isSkuimageID;
                    CurrencyInfoValue.IsSkuimage = props.location.state.params.isSkuimage;
                    CurrencyInfoValue.CreatedBy = currentUser.employeeinformationID;
                    return CurrencyInfoValue;
                }
            }
        }
        else {
            return CurrencyInfoValue;
        }
    }
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),
    }
    useEffect(() => {
        let Currencyinfo = BindPackageimageValue(props);
        setPackageImageList(Currencyinfo);
    }, []);

    useEffect(() => {
        Packageimage.GetPackageImageList(2, 0).then((res) => {
            if (res.data) {
                setstyleskuimage(res.data.commonDropdown)
            }
        })
        let PackingParams = {
            Operation: 98,
            Id: props.location.state.params.packageImageID,
        }
        CommonService.CheckMappedItem(PackingParams).then(res => {
            if (res.data.outputResult === "-2") {
                setLabedispaly(true)
            }
        });
    }, [])

    function ResetOperation(e) {
        if (e === "Back") {
            props.history.push('/PackageImageList');
        } else {
            window.location.reload();
        }
    }

    function PageRedirect(Func) {
        props.history.push({ pathname: "/PackageImageList", state: { message: Func } });
    }

    function handleInputChange(e, FieldName) {
       
        let input = '';
        if (FieldName === "Packingimage") {
            if (e.target.value.trim() !== '') {
                input = e.target.value;
            }
            Values.PackingImageName = input;
        } else {
            if (e.target.checked === true) {
                Values.IsSkuimageID = 1
            } else {
                Values.IsSkuimageID = 0
            }
        }
        setPackageImageList(Values);
    }

    function UpdatePackageImage() {
        let isvalid = true;
        setButtonLoader(true);
        if (Values.PackingImageName === '') {
            isvalid = false;
            setSubmitted(true);
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Please fill the mandantory(*) fields');
            return false;
        }
        if (isvalid) {
           
            Packageimage.UpdatePackageImageGrd(Values).then((res) => {
                var page = "Remove";
                SearchRetain(page);
                let Func = 'Add';
                if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    PageRedirect(Func);
                }
                else if (res.data.outputResult === "-2") {
                    Values.PackingImageName = props.location.state.params.packingImageName;
                    setPackageImageList(Values);
                    setButtonLoader(false);
                    Nodify('Warning!', 'warning', "Packing Image Already exists!");
                }
            })
        }
    }
    return (
        <Fragment>
            <div className="page-body">
                <ReactNotification />
                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <div className="widget flat radius-bordered">
                            <div className="widget-header heading_box_style">
                                <h3 className="heading_style_common">Edit Package Image</h3>
                                <div className="buttons-preview" align="right">
                                    <span className="btn btn-primary"
                                        onClick={
                                            () => ResetOperation("Back")
                                        }>
                                        <i className="fa fa-arrow-circle-left"></i>
                                        &nbsp;Back</span>
                                    <span className="btn btn-danger"
                                        onClick={
                                            () => ResetOperation()
                                        }>
                                        <i className="fa fa-times"></i>
                                        &nbsp;Reset</span>
                                    <button type="button" className="btn btn-success" disabled={buttonLoader}
                                        onClick={UpdatePackageImage}>

                                        <i className="fa fa-check right"></i>
                                        &nbsp;Update</button>
                                </div>
                            </div>
                            <div className="widget-body">
                                <div id="registration-form">
                                    <form>
                                        <div >
                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: "10%" }}>Package Image Name<span className="text-danger">*</span></th>
                                                        <th style={{ width: "10%" }}>Is Style sku Image</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <Fragment >
                                                        <tr>
                                                            <td>
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"PackingImageName"}
                                                                    name="PackingImageName"
                                                                    placeholder="Enter Packing ImageName"
                                                                    maxLength="50"
                                                                    autoComplete="off"
                                                                    value={getpackageImageList.PackingImageName}
                                                                    onChange={event => handleInputChange(event, "Packingimage")}
                                                                    style={{ border: submitted && getpackageImageList.PackingImageName === "" ? '1px solid red' : '' }}
                                                                    autoFocus
                                                                />
                                                            </td>

                                                            <td>
                                                                {/* <Reactselect className="basic-single" name="Brand" id={"Brand"}
                                                                    isDisabled={false}
                                                                    isLoading={false}
                                                                    isSearchable={true}
                                                                    isClearable={true}
                                                                    autocomplete='off'
                                                                    placeholder="Select Brand Detail"
                                                                    styles={submitted && getpackageImageList.IsSkuimageID === 0 ? styles : ''}
                                                                    onChange={event => handleInputChange(event, "Isstylesku")}
                                                                    options={styleskuimage}
                                                                    value={styleskuimage.filter(function (option) {
                                                                        return option.value === getpackageImageList.IsSkuimageID;
                                                                    })}
                                                                /> */}
                                                                &nbsp; &nbsp; &nbsp;
                                                                <label style={{ marginTop: '8px' }}>
                                                                    <input type="checkbox" name="IsSelected"
                                                                        onChange={event => handleInputChange(event, "Isstylesku")}
                                                                        value={getpackageImageList.IsSkuimageID === 1 ? true : false}
                                                                        checked={getpackageImageList.IsSkuimageID === 1 ? true : false}
                                                                        disabled={getLabedispaly}
                                                                        className="clearOpacity textdesign" />
                                                                    <span className="text"> </span>
                                                                </label>
                                                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                                {getLabedispaly ?
                                                                    <span className="text-danger">
                                                                        Note: Oops! Can't select Checkbox, Because This particular one mapped in Package Template
                                                                    </span> : ''
                                                                }
                                                            </td>
                                                        </tr>
                                                    </Fragment>

                                                </tbody>
                                            </table>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>
                            <span className="btn btn-primary"
                                onClick={
                                    () => ResetOperation("Back")
                                }>
                                <i className="fa fa-arrow-circle-left"></i>
                                &nbsp;Back</span>
                            <span className="btn btn-danger"
                                onClick={
                                    () => ResetOperation()
                                }>
                                <i className="fa fa-times"></i>
                                &nbsp;Reset</span>
                            <button type="button" className="btn btn-success" disabled={buttonLoader}
                                onClick={UpdatePackageImage}>

                                <i className="fa fa-check right"></i>
                                &nbsp;Update</button>
                        </div>
                    </div>
                </div>
            </div >
        </Fragment >
    )

}

export default EditPackageImage