import React, { useState, useEffect, Fragment } from "react";
import ReactNotification from 'react-notifications-component';
import Nodify from "../Common/ReactNotification"
import SearchRetain from "../Common/SearchRetain";
import Reactselect from 'react-select';
import taskService from "../../services/Master/TaskService";
import { Modal, Button } from "react-bootstrap";
import routeService from "../../services/Route/RouteService";
import styleService from "../../services/Style/styleService";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import { useDispatch, useSelector } from "react-redux";
import { loadRouteTaskOwner } from "../../actions/route";

const RouteEdit = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const drpRouteTaskOwnerList = useSelector((state) => state.route.drpRouteTaskOwnerList);
    const hasView = props.location.state.params.Action === "View" ? true : false;
    //declaration
    const dispatch = useDispatch();

    // //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    if (menulist !== undefined) {
        activeMenu = menulist.filter(i => i.menuName === 'Route');
    }
    //End Enable Menu

     // If check production or sampling
     const activeMenus = useSelector((state) => state.auth.activeMenuList);
     let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
     let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal : activeMenus.IsProduction;
     // End

    const [MainFields, setMainFields] = useState([{
        Operation: 1,
        RouteInformationID: 0,
        BuyerID: 0,
        RouteName: '',
        RouteDetails: [],
        RouteDependencyDetails: [],
        RouteAddtionalTaskDetails: [],
        Createdby: currentUser.employeeinformationID,
    }]);
    const EmptyInputFields = [];
    const [inputFields, setInputFields] = useState(EmptyInputFields);
    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getTaskHolderList, setTaskHolderList] = useState([]);
    const [getLeaveDaysList, setLeaveDaysList] = useState([]);
    const [getTaskTypeList, setTaskTypeList] = useState([]);
    const [ExistingList, setExistingList] = useState([]);
    const [getAllTaskList, setAllTaskList] = useState([]);
    const [getDependencyList, setDependencyList] = useState([]);
    const [getBuyerList, setBuyerList] = useState([]);
    const [getAllRouteInfoList, setAllRouteInfoList] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [getHeaderName, addHeaderName] = useState("Edit");
    const [Depandencyrowno, setDepandencyrowno] = useState('');
    const [DepandencyTaskName, setDepandencTaskName] = useState('');
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),
    }
    let Size = "110px";

    const values = [...inputFields];
    const MainValues = [...MainFields];
    let tasknamelist = [...getAllTaskList];
    const reducerState = useSelector((state) => state);
    const BuyerBrandDetails = [] = reducerState.auth.UserMenuList.item6;
    let BuyerListOptions = [] = [];

    function BindRouteValue(props) {
        let RouteValue = {};
        if (props.location.state !== undefined) {
            if (props.location.state.params.length !== 0) {
                if (props.location.state.params.routeInformationID !== 0) {
                    if (props.location.state.params.Action === "Edit") {
                        RouteValue.Operation = 2;
                        RouteValue.RouteInformationID = props.location.state.params.routeInformationID;
                        RouteValue.BuyerID = props.location.state.params.buyerID;
                        RouteValue.RouteName = props.location.state.params.routeName;

                        addHeaderName("Edit");
                    }
                    else if (props.location.state.params.Action === "View") {
                        addHeaderName("View");
                        RouteValue.Operation = 2;
                        RouteValue.RouteInformationID = props.location.state.params.routeInformationID;
                        RouteValue.BuyerID = props.location.state.params.buyerID;
                        RouteValue.RouteName = props.location.state.params.routeName;

                    }
                    else {
                        addHeaderName("Export");
                        RouteValue.Operation = 1;
                        RouteValue.RouteInformationID = 0;
                        RouteValue.BuyerID = 0;
                        RouteValue.RouteName = '';

                    }
                    RouteValue.Createdby = currentUser.employeeinformationID;
                    RouteValue.RouteDetails = [];
                    RouteValue.RouteDependencyDetails = [];
                    RouteValue.RouteAddtionalTaskDetails = [];
                    return RouteValue;
                }
            }
        }
        else {
            return RouteValue;
        }
    }

    useEffect(() => {
        
        let Routeinfo = BindRouteValue(props);
        setMainFields([Routeinfo]);

        styleService.LoadBuyer(2).then((response) => {
            if (response.data) {
                BuyerListOptions = response.data;
                setBuyerList(BuyerListOptions.filter(o => BuyerBrandDetails.some(({ buyerId }) => o.id === buyerId)));
            }
        });

        taskService.getLoadTaskHolderList().then((response) => {
            if (response.data) {
                let Optionvalue = [];
                response.data.forEach(element => {
                    let LabelColor = '';
                    if (element.label === 'Buyer - -') {
                        LabelColor = <><span style={{ color: "#0b0992" }}><b>Buyer</b></span></>
                    }
                    else if (element.label === 'Buyer - Cube') {
                        LabelColor = <><span style={{ color: "#0b0992" }}><b>Buyer</b></span> - <span style={{ color: "#00a65a" }}><b>Cube</b></span></>
                    }
                    else if (element.label === 'Buyer - Supplier') {
                        LabelColor = <><span style={{ color: "#0b0992" }}><b>Buyer</b></span> - <span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                    }
                    else if (element.label === 'Buyer - Cube,Supplier') {
                        LabelColor = <><span style={{ color: "#0b0992" }}><b>Buyer</b></span> - <span style={{ color: "#00a65a" }}><b>Cube</b></span>, <span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                    }
                    else if (element.label === 'Cube - -') {
                        LabelColor = <><span style={{ color: "#00a65a" }}><b>Cube</b></span></>
                    }
                    else if (element.label === 'Cube - Buyer') {
                        LabelColor = <><span style={{ color: "#00a65a" }}><b>Cube</b></span> - <span style={{ color: "#0b0992" }}><b>Buyer</b></span></>
                    }
                    else if (element.label === 'Cube - Supplier') {
                        LabelColor = <><span style={{ color: "#00a65a" }}><b>Cube</b></span> - <span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                    }
                    else if (element.label === 'Cube - Buyer,Supplier') {
                        LabelColor = <><span style={{ color: "#00a65a" }}><b>Cube</b></span> - <span style={{ color: "#0b0992" }}><b>Buyer</b></span>, <span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                    }
                    else if (element.label === 'Supplier - -') {
                        LabelColor = <><span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                    }
                    else if (element.label === 'Supplier - Buyer') {
                        LabelColor = <><span style={{ color: "#e27607" }}><b>Supplier</b></span> - <span style={{ color: "#0b0992" }}><b>Buyer</b></span></>
                    }
                    else if (element.label === 'Supplier - Cube') {
                        LabelColor = <><span style={{ color: "#e27607" }}><b>Supplier</b></span> - <span style={{ color: "#00a65a" }}><b>Cube</b></span></>
                    }
                    else if (element.label === 'Supplier - Buyer,Cube') {
                        LabelColor = <><span style={{ color: "#e27607" }}><b>Supplier</b></span> - <span style={{ color: "#0b0992" }}><b>Buyer</b></span>, <span style={{ color: "#00a65a" }}><b>Cube</b></span></>
                    }

                    Optionvalue.push({
                        value: element.value,
                        label: LabelColor,
                        subLabel: element.label,
                    })
                });

                setTaskHolderList(Optionvalue);
            }
        });

        taskService.getLoadLeaveDaysList().then((response) => {
            if (response.data) {
                setLeaveDaysList(response.data);
            }
        });

        taskService.getLoadTaskTypeList(2).then((response) => {
            if (response.data) {
                setTaskTypeList(response.data);
            }
        });

        taskService.GetTaskDetailsList(0).then((response) => {
            if (response.data) {
                setExistingList(response.data);
            }
        });

        routeService.LoadTaskList(0).then((response) => {
            
            if (response.data) {
                if (IsProduction === 1) {
                    setAllTaskList(response.data.filter(x => x.taskOrderTypeName === "Sample" || x.taskOrderTypeName === "Sample & Production"));
                }
                else if (IsProduction === 2) {
                    setAllTaskList(response.data.filter(x => x.taskOrderTypeName === "Production" || x.taskOrderTypeName === "Sample & Production"));
                }
            }
        });

        routeService.LoadRouteDependencyList().then((response) => {
            if (response.data) {
                setDependencyList(response.data);
            }
        });
        showLoader();
        routeService.GetRouteInfoList(props.location.state.params.routeInformationID).then((response) => {
            if (response && response.data) {
                setAllRouteInfoList(response.data);

                let AllRouteInfo = response.data;

                let routeInfo = AllRouteInfo.routeInfo;
                let dependentInfo = AllRouteInfo.dependentInfo;
                let additionalInfo = AllRouteInfo.additionalInfo;

                if (AllRouteInfo) {
                    if (routeInfo) {
                        if (routeInfo.length > 0) {
                            routeInfo.forEach((route, i) => {
                                let RouteInfoId = 0;
                                let RouteId = 0;
                                let employee1 = "";
                                let employee2 = "";
                                let employee3 = "";
                                if (route.taskOwnerName && route.taskOwnerName) {
                                    let TaskHolderName = route.taskOwnerName.split('-');
                                    if (TaskHolderName && TaskHolderName[0]) {
                                        employee1 = TaskHolderName[0].trim();

                                        if (TaskHolderName && TaskHolderName[1]) {
                                            let FollowerName = TaskHolderName[1].split(',');
                                            if (FollowerName && FollowerName[0]) {
                                                employee2 = FollowerName[0].trim();

                                                if (FollowerName && FollowerName[1]) {
                                                    employee3 = FollowerName[1].trim();
                                                } else {
                                                    employee3 = "";
                                                }
                                            } else {
                                                employee2 = "";
                                                employee3 = "";
                                            }

                                        } else {
                                            employee2 = "";
                                            employee3 = "";
                                        }
                                    }
                                }

                                if (props.location.state.params.Action === "Edit") {
                                    RouteInfoId = route.routeInformationID;
                                    RouteId = route.routeID;
                                }
                                inputFields.push({
                                    RouteInformationID: RouteInfoId,
                                    RouteID: RouteId,
                                    TaskType: route.taskType,
                                    TaskName: route.taskNameID,
                                    strTaskName: route.taskName,
                                    Duration: route.duration,
                                    arrDependency: [],
                                    //TaskHolderOrFollower: route.taskHolderOrFollower,
                                    AdditionalFollowup: additionalInfo.filter(x => x.indexName === route.indexName && x.isTaskSelected === 1).length,
                                    IsFollowupExist: additionalInfo.filter(x => x.indexName === route.indexName).length,
                                    DependencyCount: dependentInfo.filter(x => x.indexName === route.indexName && x.dependencyID !== 0).length,
                                    IndexName: route.indexName,
                                    PreferenceId: route.preferenceId,
                                    IsPopupOpen: 0,
                                    IsDependencyOpen: 0,
                                    IsSelectAll: route.isSelectAll,
                                    FollowupDetails: [],
                                    SelectedDependency: route.selectedDependency,
                                    TaskHolderList: '',
                                    TaskHolderOrFollowerTxt: route.taskOwnerName,
                                    TaskHolderOrFollower: route.taskHolderOrFollower,
                                    TaskHolderOwnerTxtList: route.taskOwnerNames,
                                    TaskHolderIDList: props.location.state.params.Action === "Export" ? "" : route.taskHolderIDList,
                                    FollowerIDList1: route.followerIDList1,
                                    FollowerIDList2: route.followerIDList2,
                                    TaskHolderBuyer: props.location.state.params.Action === "Export" ? "" : route.taskHolderIDList,
                                    FollowerCube: route.followerIDList1,
                                    FollowerSupplier: route.followerIDList2,
                                    TaskHolderOwnerIdList: props.location.state.params.Action === "Export" ? (route.followerIDList1 ? (route.followerIDList1 + (route.followerIDList2 ? "," + route.followerIDList2 : "")) : route.followerIDList2) : route.taskOwner,
                                    //TaskHolderOwnerIdList: route.taskOwner,
                                    titleEmployee1: employee1,
                                    titleEmployee2: employee2,
                                    titleEmployee3: employee3,
                                    LeaveDays: route.leaveDays,
                                    IsTHFirstTime: 0,
                                    LeaveDaysTxt: route.leaveDayName,
                                    IsTHLeaveDaysOpen: 0,
                                    arrTaskHolderBuyer: [],
                                    arrFollowerCube: [],
                                    arrFollowerSupplier: [],
                                    TaskHolderBuyerTxt: '',
                                    FollowerCubeTxt: '',
                                    FollowerSupplierTxt: '',
                                });
                            });
                        }

                        if (dependentInfo.length > 0) {
                            dependentInfo.forEach((element, i) => {
                                let RouteInfoId = 0;
                                let DependencyId = 0;
                                if (props.location.state.params.Action === "Edit") {
                                    RouteInfoId = element.routeInformationID;
                                    DependencyId = element.routeDependencyID;
                                }
                                inputFields[parseInt(element.indexName)].arrDependency.push({
                                    RouteInformationID: RouteInfoId,
                                    RouteDependencyID: DependencyId,
                                    DependentTaskName: element.dependentTaskName,
                                    RouteTaskName: element.routeTaskName,
                                    DependencyID: element.dependencyID,
                                    LogDays: element.logDays,
                                    IndexName: element.indexName
                                })
                            })
                        }
                        if (additionalInfo.length > 0) {
                            inputFields.forEach((add, j) => {
                                additionalInfo.filter(x => x.indexName === add.IndexName).forEach((element, i) => {
                                    let RouteInfoId = 0;
                                    let AddtaskId = 0;
                                    if (props.location.state.params.Action === "Edit") {
                                        RouteInfoId = element.routeInformationID;
                                        AddtaskId = element.routeAddTaskID;
                                    }
                                    inputFields[j].FollowupDetails.push({
                                        RouteInformationID: RouteInfoId,
                                        RouteAddTaskID: AddtaskId,
                                        AdditonalTaskDetailsID: element.additonalTaskDetailsID,
                                        AddtionalTaskName: element.addtionalTaskName,
                                        IsTaskSelected: element.isTaskSelected,
                                        IndexName: element.indexName
                                    })
                                });

                            })
                        }
                        setInputFields(inputFields);

                        tasknamelist && tasknamelist.forEach((data, i) => {
                            tasknamelist[i].isdisabled = false;
                        })
                        inputFields.forEach((data, i) => {
                            var SelectedIndex = tasknamelist && tasknamelist.findIndex(x => x.value === data.TaskName);
                            if (SelectedIndex !== -1) {
                                tasknamelist[SelectedIndex].isdisabled = true;
                            }
                        })

                        hideLoader();
                    }
                }
            }
        });

    }, []);

    const handleAddFields = (index) => {
        let count = index;
        count++;
        let EmptyList = {};
        EmptyList =
        {
            RouteInformationID: 0,
            RouteID: 0,
            TaskType: 0,
            TaskName: 0,
            strTaskName: '',
            Duration: '',
            arrDependency: [],
            DependencyCount: 0,
            AdditionalFollowup: 0,
            IsFollowupExist: 0,
            IndexName: '',
            IsPopupOpen: 0,
            IsDependencyOpen: 0,
            IsSelectAll: 1,
            SelectedDependency: '',
            FollowupDetails: [],
            PreferenceId: 0,
            TaskHolderList: '',// TaskHolder Details And LeaveDays
            TaskHolderOrFollower: 0,
            TaskHolderOwnerTxtList: '',
            TaskHolderIDList: '',
            FollowerIDList1: '',
            FollowerIDList2: '',
            TaskHolderBuyer: '',
            FollowerCube: '',
            FollowerSupplier: '',
            TaskHolderOwnerIdList: '',
            titleEmployee1: '',
            titleEmployee2: '',
            titleEmployee3: '',
            LeaveDays: 1,
            IsTHFirstTime: 0,
            LeaveDaysTxt: 'None',
            IsTHLeaveDaysOpen: 0,
            arrTaskHolderBuyer: [],
            arrFollowerCube: [],
            arrFollowerSupplier: [],
            TaskHolderBuyerTxt: '',
            FollowerCubeTxt: '',
            FollowerSupplierTxt: '',
        }
        values.splice(count, 0, EmptyList);

        for (let i = 0; i < values.length; i++) {
            let TaskDependency = [];
            for (let j = 0; j < values.length; j++) {
                if (values[i].arrDependency.length > 0) {
                    let AlreadyExist = values[i].arrDependency.filter(x => x.DependentTaskName === values[j].TaskName);
                    let DependencyID = 0;
                    let LogDays = '';
                    if (AlreadyExist.length === 0) {
                        DependencyID = 0;
                        LogDays = '';
                    }
                    else {
                        DependencyID = AlreadyExist[0].DependencyID;
                        LogDays = AlreadyExist[0].LogDays;
                    }

                    TaskDependency.push(
                        {
                            RouteDependencyID: 0,
                            DependentTaskName: values[j].TaskName,
                            RouteTaskName: values[j].strTaskName,
                            DependencyID: DependencyID,
                            LogDays: LogDays,
                            IndexName: (i).toString()
                        }
                    )
                }
                else {
                    TaskDependency.push(
                        {
                            RouteDependencyID: 0,
                            DependentTaskName: values[j].TaskName,
                            RouteTaskName: values[j].strTaskName,
                            DependencyID: 0,
                            LogDays: '',
                            IndexName: (i).toString()
                        }
                    )
                }
            }
            values[i].arrDependency = [];

            values[i].arrDependency = TaskDependency;

            let DependencyShow = '';
            for (let k = 0; k < values[i].arrDependency.length; k++) {
                if (i !== k) {
                    let DependentID = values[i].arrDependency[k].DependencyID;
                    if (DependentID !== 0) {
                        if (DependencyShow === '') {
                            DependencyShow = (k + 1) + '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : DependentID === 4 ? "EE" : "")
                        }
                        else {
                            DependencyShow = DependencyShow + ',' + (k + 1) + '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : DependentID === 4 ? "EE" : "")
                        }
                    }
                }
            }
            values[i].SelectedDependency = DependencyShow;
            values[i].DependencyCount = values[i].arrDependency.filter(x => x.DependencyID !== 0).length;
            values[i].PreferenceId = i + 1;

        }

        // if (values.length > 0) {
        //     for (var z = 0; z < values.length; z++) {
        //         values[z].PreferenceId = z + 1;
        //     }
        // }

        setInputFields(values);
        setSubmitted(false);
    };

    const handleRemoveFields = index => {
        
        values.splice(index, 1);
        for (let j = 0; j < values.length; j++) {
            values[j].arrDependency.splice(index, 1);

            values[j].SelectedDependency = '';
            let DependencyShow = '';
            for (let i = 0; i < values[j].arrDependency.length; i++) {
                if (j !== i) {
                    let DependentID = values[j].arrDependency[i].DependencyID;
                    if (DependentID !== 0) {
                        if (DependencyShow === '') {
                            DependencyShow = (i + 1) + '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : "EE")
                        }
                        else {
                            DependencyShow = DependencyShow + ',' + (i + 1) + '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : "EE")
                        }
                    }
                }
            }
            values[j].SelectedDependency = DependencyShow;
        }

        if (values.length > 0) {
            for (var z = 0; z < values.length; z++) {
                values[z].PreferenceId = z + 1;
            }
        }

        setInputFields(values);
        DisabeSelectedTaskName();
    };

    const handleChange = (e, index, FieldName) => {
     

        setSubmitted(false);
        let inputText = '';
        if (FieldName === "IsSelectAll") {
            if (e.target.checked) {
                for (var i = 0; i < values[index].FollowupDetails.length; i++) {
                    values[index].FollowupDetails[i].IsTaskSelected = 1;
                }
                values[index][FieldName] = 1;
            } else {
                for (var i = 0; i < values[index].FollowupDetails.length; i++) {
                    values[index].FollowupDetails[i].IsTaskSelected = 0;
                }
                values[index][FieldName] = 0;
            }

            values[index].AdditionalFollowup = values[index].FollowupDetails.filter(x => x.IsTaskSelected === 1).length;
            values[index].IsFollowupExist = values[index].FollowupDetails.length;

        }
        else if (FieldName === "TaskType") {
            if (e != null) {
                inputText = parseInt(e.value);
                values[index].TaskName = 0;
                values[index].TaskHolderOrFollower = 0;
                values[index].TaskHolderOrFollowerTxt = '';
                values[index].LeaveDays = 0;
                values[index].LeaveDaysTxt = "";
                values[index].AdditionalFollowup = 0;
                values[index].IsFollowupExist = 0;
                values[index].strTaskName = '';
                values[index].FollowupDetails = [];
                var SelectedIndex = getTaskTypeList.findIndex(x => x.value === e.value);
                getTaskTypeList[SelectedIndex].isdisabled = true;
            }
            else {
                inputText = 0;
                values[index].TaskName = 0;
                values[index].TaskHolderOrFollower = 0;
                values[index].TaskHolderOrFollowerTxt = '';
                values[index].LeaveDays = 0;
                values[index].LeaveDaysTxt = "";
                values[index].AdditionalFollowup = 0;
                values[index].IsFollowupExist = 0;
                values[index].strTaskName = '';
                values[index].FollowupDetails = [];

            }
            values[index][FieldName] = inputText;
            DisabeSelectedTaskName();

        }
        else if (FieldName === "TaskName") {
            if (e != null) {
                inputText = parseInt(e.value);
                var RespectiveList = ExistingList.filter(x => x.taskDetailsID === e.value);
                let employee1 = "";
                let employee2 = "";
                let employee3 = "";

                if (RespectiveList[0].taskHolderName) {
                    let TaskHolderName = RespectiveList[0].taskHolderName.split('-');
                    if (TaskHolderName[0] !== undefined) {
                        employee1 = TaskHolderName[0].trim();

                        if (TaskHolderName[1] !== undefined) {
                            let FollowerName = TaskHolderName[1].split(',');
                            if (FollowerName[0] !== undefined) {
                                employee2 = FollowerName[0].trim();

                                if (FollowerName[1] !== undefined) {
                                    employee3 = FollowerName[1].trim();
                                } else {
                                    employee3 = "";
                                }
                            } else {
                                employee2 = "";
                                employee3 = "";
                            }

                        } else {
                            employee2 = "";
                            employee3 = "";
                        }
                    }
                }

                values[index].TaskHolderOrFollower = RespectiveList[0].taskHolderOrFollower;
                values[index].TaskHolderOrFollowerTxt = RespectiveList[0].taskHolderName && RespectiveList[0].taskHolderName.replace(" - -", "");
                values[index].LeaveDays = RespectiveList[0].leaveDays;
                values[index].LeaveDaysTxt = RespectiveList[0].leaveDayName;
                values[index].AdditionalFollowup = RespectiveList[0].additionalFollowup;
                values[index].IsFollowupExist = RespectiveList[0].additionalFollowup;
                values[index].titleEmployee1 = employee1;
                values[index].titleEmployee2 = employee2;
                values[index].titleEmployee3 = employee3;

                values[index].TaskHolderOwnerTxtList = "";
                values[index].TaskHolderIDList = "";
                values[index].FollowerIDList1 = "";
                values[index].FollowerIDList2 = "";
                values[index].TaskHolderBuyer = "";
                values[index].FollowerCube = "";
                values[index].FollowerSupplier = "";

                let Task_Value = getAllTaskList.filter(x => x.value === e.value)[0];

                values[index].strTaskName = Task_Value.label;
                values[index].TaskType = Task_Value.taskTypeID;

                taskService.GetAddtionalTaskDetailsList(0, Task_Value.taskInformationID).then((response) => {
                    if (response.data) {
                        values[index].FollowupDetails = [];
                        if (response.data.length !== 0) {
                            if (response.data.filter(x => x.indexName.toString() === Task_Value.indexName.toString()).length > 0) {
                                response.data.filter(x => x.indexName.toString() === Task_Value.indexName.toString()).forEach(element => {
                                    values[index].FollowupDetails.push({
                                        RouteAddTaskID: 0,
                                        AdditonalTaskDetailsID: element.additonalTaskDetailsID,
                                        AddtionalTaskName: element.addtionalTaskName,
                                        IsTaskSelected: 1,
                                        IndexName: index.toString()
                                    });
                                });
                            }
                            else {
                                values[index].AdditionalFollowup = 0;
                                values[index].IsFollowupExist = 0;
                                values[index].FollowupDetails = [];
                            }
                        }
                        else {
                            values[index].AdditionalFollowup = 0;
                            values[index].IsFollowupExist = 0;
                            values[index].FollowupDetails = [];
                        }
                        values[index][FieldName] = inputText;

                        for (var j = 0; j < values.length; j++) {
                            let MSG = '';
                            if (index !== j) {
                                if (values[index].TaskType !== 0) { // for avoid empty validation
                                    if (values[j].TaskType === values[index].TaskType &&
                                        values[j].TaskName === values[index].TaskName) {
                                        if (FieldName === "TaskName") {
                                            values[index].TaskName = 0;
                                            MSG = "This task name is already exist.";
                                        }
                                        setInputFields(values);
                                        Nodify('Warning!', 'warning', MSG);
                                        return false;
                                    }
                                }
                            }
                        }
                        setInputFields(values);
                    }
                });
            }
            else {
                inputText = 0;
                values[index].TaskType = 0;
                values[index].TaskHolderOrFollower = 0;
                values[index].TaskHolderOrFollowerTxt = '';
                values[index].LeaveDaysTxt = 'None';
                values[index].LeaveDays = 0;
                values[index].AdditionalFollowup = 0;
                values[index].IsFollowupExist = 0;
                values[index].strTaskName = '';
                values[index][FieldName] = inputText;
                values[index].FollowupDetails = [];
                for (let i = 0; i < values.length; i++) {
                    values[i].arrDependency.splice(index, 1);
                }
                setInputFields(values);

            }

            DisabeSelectedTaskName();

        }
        else if (FieldName === "TaskHolderOrFollower" || FieldName === "LeaveDays") {
            if (e != null) {
                inputText = parseInt(e.value);
                if (FieldName === "TaskHolderOrFollower") {
                    // setTaskHolderID(inputText);

                    values[index].TaskHolderOrFollowerTxt = e.subLabel && e.subLabel.replace(" - -", "");
                    let TaskHolderName = e.subLabel.split('-');
                    if (TaskHolderName[0] !== undefined) {
                        values[index].titleEmployee1 = TaskHolderName[0].trim();

                        if (TaskHolderName[1] !== undefined) {
                            let FollowerName = TaskHolderName[1].split(',');
                            if (FollowerName[0] !== undefined) {
                                values[index].titleEmployee2 = FollowerName[0].trim();

                                if (FollowerName[1] !== undefined) {
                                    values[index].titleEmployee3 = FollowerName[1].trim();
                                } else {
                                    values[index].titleEmployee3 = "";
                                }
                            } else {
                                values[index].titleEmployee2 = "";
                                values[index].titleEmployee3 = "";
                            }

                        } else {
                            values[index].titleEmployee2 = "";
                            values[index].titleEmployee3 = "";
                        }
                        if (values[index].titleEmployee1 !== "Cube" && values[index].titleEmployee2 !== "Cube" && values[index].titleEmployee3 !== "Cube") {
                            dispatch(loadRouteTaskOwner(inputText, MainValues[0].BuyerID, 0));
                        } else {
                            dispatch(loadRouteTaskOwner(inputText, MainValues[0].BuyerID, currentUser.employeeinformationID));
                        }

                    } else {
                        values[index].titleEmployee1 = "";
                        values[index].titleEmployee2 = "";
                        values[index].titleEmployee3 = "";
                    }


                } else {
                    values[index].LeaveDaysTxt = e.label;
                }
            }
            else {
                inputText = 0;
                if (FieldName === "TaskHolderOrFollower") {
                    values[index].TaskHolderOrFollowerTxt = '';
                    values[index].titleEmployee1 = "";
                    values[index].titleEmployee2 = "";
                    values[index].titleEmployee3 = "";
                }
                values[index].LeaveDaysTxt = "";
            }
            values[index][FieldName] = inputText;
        }
        else if (FieldName === "Duration") {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            var checkNumber = inputText.replace(/\D/g, '').match('^[0-9]*$');
            inputText = checkNumber[0];
            if (parseInt(inputText) === 0) {
                inputText = '';
            }
            values[index][e.target.name] = inputText;
        }
        else if (FieldName === "TaskHolderBuyer" || FieldName === "FollowerCube" || FieldName === "FollowerSupplier") {
            let strValue = '';
            let strTxt = '';
            e.map((val) => {
                if (strValue === '') { strValue = val.value.toString(); strTxt = val.label.toString(); }
                else { strValue = strValue + "," + val.value; strTxt = strTxt + ", " + val.label.toString(); }
            });
            values[index]["arr" + FieldName] = e;
            values[index][FieldName] = strValue;
            values[index][FieldName + "Txt"] = strTxt;
        }
        else {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            values[index][e.target.name] = inputText;
        }

        if (FieldName !== "TaskName") {
            setInputFields(values);
        }
    }

    const DisabeSelectedTaskName = () => {
       
        getAllTaskList.forEach((data, i) => {
            getAllTaskList[i].isdisabled = false;
        })

        values.forEach((data, i) => {
            var SelectedIndex = getAllTaskList.findIndex(x => x.label === data.strTaskName);
            if (SelectedIndex !== -1) {
                getAllTaskList[SelectedIndex].isdisabled = true;
            }
        })
    }

    const CheckDuplicate = (index, Field) => {

        for (var j = 0; j < values.length; j++) {
            let MSG = '';
            if (index !== j) {
                if (values[index].TaskType !== 0 && values[index].TaskName !== 0) { // for avoid empty validation
                    if (values[j].TaskType === values[index].TaskType &&
                        values[j].TaskName === values[index].TaskName) {
                        if (Field === "TaskName") {
                            values[index].TaskName = 0;
                            MSG = "This task name is already exist.";
                        }
                        else if (Field === "TaskType") {
                            values[index].TaskType = 0;
                            MSG = "This task type is already exist.";
                        }
                        setInputFields(values);
                        Nodify('Warning!', 'warning', MSG);
                        return false;
                    }
                }
            }
        }
    }

    const handleFollowup = index => {
        if (values[index].TaskName === 0) {
            Nodify('Warning!', 'warning', 'Please select the task name.');
        }
        else {
            if (values[index].IsFollowupExist === 0) {
                Nodify('Warning!', 'warning', "Additional task doesn't exist for this task.");
            }
            else {
                values[index].IsPopupOpen = 1;
                setInputFields(values);
            }
        }
    };

    const handleClose = (index) => {
        values[index].IsPopupOpen = 0;
        setInputFields(values);
    };

    const handleDependency = (index, DependencyTask,SelectedDependency) => {
       ;
        
        if (values[index].TaskName === 0) {
            Nodify('Warning!', 'warning', 'Please select the task name.');
        }
        else {
            values[index].IsDependencyOpen = 1;

            let TaskDependency = [];
            for (let i = 0; i < values.length; i++) {
                if (values[index].arrDependency.length > 0) {
                   ;
                    let AlreadyExist = values[index].arrDependency.filter(x => x.DependentTaskName === values[i].TaskName);
                    let DependencyID = 0;
                    let LogDays = '';
                    if (AlreadyExist.length === 0) {
                       
                        DependencyID = 0;
                        LogDays = '';
                    }
                    else {
                          DependencyID = AlreadyExist[0].DependencyID;
                          LogDays = AlreadyExist[0].LogDays;
                    }
                    TaskDependency.push(
                        {
                            RouteDependencyID: 0,
                            DependentTaskName: values[i].TaskName,
                            RouteTaskName: values[i].strTaskName,
                            DependencyID: DependencyID,
                            LogDays: LogDays,
                            IndexName: (index).toString()
                        }
                    )
                }
                else {
                    TaskDependency.push(
                        {
                            RouteDependencyID: 0,
                            DependentTaskName: values[i].TaskName,
                            RouteTaskName: values[i].strTaskName,
                            DependencyID: 0,
                            LogDays: '',
                            IndexName: (index).toString()
                        }
                    )
                }
            }
            values[index].arrDependency = [];

            values[index].arrDependency = TaskDependency;
            setDepandencTaskName(values[index].strTaskName);
            setDepandencyrowno(index + 1)
            setInputFields(values);
        }
    };

    const handleDependencyClose = (index) => {
        values[index].IsDependencyOpen = 0;
        setInputFields(values);
        Nodify('Success!', 'success', 'Dependency saved successfully.');
    };

    const  handleDependencyCloseSaveNext = (index) => {
        //values[index].IsDependencyOpen = 0;
        
        values[index].IsDependencyOpen = 0;
        // save Next instance
         index = index + 1;
        let totalValue = values.length - 1;
        if (totalValue >= index) {
            values[index].IsDependencyOpen = 1;
            setDepandencTaskName(values[index].strTaskName);
            setDepandencyrowno(index  + 1);
        }
        else { values[index - 1].IsDependencyOpen = 0; }
        setInputFields(values);
    };

    const handleAddTaskChange = (e, index, childIndex, FieldName) => {

        if (e.target.checked) {
            values[index].FollowupDetails[childIndex].IsTaskSelected = 1;
            let IsSelected = 1;
            let UnselectedRecords = values[index].FollowupDetails.filter(x => x.IsTaskSelected === 0);
            if (UnselectedRecords.length > 0) {
                IsSelected = 0;
            }
            values[index].IsSelectAll = IsSelected;
            values[index].AdditionalFollowup = values[index].FollowupDetails.filter(x => x.IsTaskSelected === 1).length;
        } else {
            values[index].FollowupDetails[childIndex].IsTaskSelected = 0;
            values[index].IsSelectAll = 0;
            values[index].AdditionalFollowup = values[index].FollowupDetails.filter(x => x.IsTaskSelected === 1).length;
        }
        setInputFields(values);

    }

    const handleChildChange = (e, index, childIndex, FieldName) => {
        setSubmitted(false);
        let inputText = '';
        var CheckchildAlreadymapped = false;
        if (FieldName === "DependentTaskName" || FieldName === "DependencyID") {
            if (e != null) {
                let SourceDep = values[childIndex].SelectedDependency;

                if (SourceDep !== '') {
                    //check source already mapped with child
                    CheckchildAlreadymapped = SourceDep.includes(index + 1);
                }
                if (CheckchildAlreadymapped === true) {
                    Nodify('Warning!', 'warning', 'Dependency  already mapped.');
                    return false;
                }
                else {
                    inputText = parseInt(e.value);
                }
            }
            else {
                inputText = 0;
                if (FieldName === "DependencyID") {
                    values[index].arrDependency[childIndex].LogDays = '';
                }
            }
            values[index].arrDependency[childIndex][FieldName] = inputText;
        }
        else {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
                var checkNumber = inputText.replace(/\D/g, '').match('^[0-9]*$');
                inputText = checkNumber[0];
            }
            if (parseInt(inputText) === 0) {
                inputText = '';
            }
            values[index].arrDependency[childIndex][e.target.name] = inputText;
        }
        if (FieldName === "DependencyID") {
            values[index].SelectedDependency = '';
            let DependencyShow = '';
            for (let i = 0; i < values[index].arrDependency.length; i++) {
                if (index !== i) {
                    let DependentID = values[index].arrDependency[i].DependencyID;
                    if (DependentID !== 0) {
                        if (DependencyShow === '') {
                            DependencyShow = (i + 1) + '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : DependentID === 4 ? "EE" : "")
                        }
                        else {
                            DependencyShow = DependencyShow + ',' + (i + 1) + '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : DependentID === 4 ? "EE" : "")
                        }
                    }
                }
            }
            values[index].SelectedDependency = DependencyShow;
        }
        values[index].DependencyCount = values[index].arrDependency.filter(x => x.DependencyID !== 0).length;

        setInputFields(values);
    }

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/RouteList",
            state: {
                message: Func
            }
        });
    }

    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
        return false;
    }

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/RouteList');
        } else {
            window.location.reload();
        }
    }

    const SaveRoute = (e) => {
        setButtonLoader(true);
        e.preventDefault();
        let IsValid = true;

        MainValues[0].RouteDetails = [];
        MainValues[0].RouteDependencyDetails = [];
        MainValues[0].RouteAddtionalTaskDetails = [];

        if (MainValues[0].BuyerID === 0 || MainValues[0].RouteName === '') {
            IsValid = false;
            setButtonLoader(false);
            Nodify('Warning!', 'warning', "Please fill the mandatory(*) fields.");
            setSubmitted(true);
            IsValid = false;
            return false;
        }
        else {
            MainValues[0].RouteName = MainValues[0].RouteName.trim();
            if (inputFields.length === 1 && (inputFields[0].TaskName === 0 || inputFields[0].Duration === '' || inputFields[0].TaskType === 0 || inputFields[0].TaskHolderOrFollower === 0 || inputFields[0].LeaveDays === 0)) {
                IsValid = false;
                setButtonLoader(false);
                setSubmitted(true);
                ValidationPopup("Please fill at least one task details.");
                return false;
            }
            else {
                inputFields.forEach((Info, i) => {
                    let titleEmployee1 = Info.titleEmployee1;
                    let TaskHolderOwners = []
                    if (titleEmployee1 === "Buyer") {
                        TaskHolderOwners = Info.TaskHolderBuyer;
                    } else if (titleEmployee1 === "Cube") {
                        TaskHolderOwners = Info.FollowerCube;
                    } else if (titleEmployee1 === "Supplier") {
                        TaskHolderOwners = Info.FollowerSupplier;
                    }


                    if (Info.TaskName === 0 || Info.TaskType === 0 || Info.Duration === '' || (!TaskHolderOwners) || Info.LeaveDays === 0) {
                        setButtonLoader(false);
                        if (IsValid === true) {
                            Nodify('Warning!', 'warning', "Please fill the mandatory(*) fields.");
                            IsValid = false;
                        }
                        setSubmitted(true);
                        return false;
                    }
                    else {
                        MainValues[0].RouteDetails.push({
                            RouteID: Info.RouteID,
                            TaskType: Info.TaskType,
                            TaskName: Info.TaskName,
                            Duration: Info.Duration,
                            TaskHolderID: Info.TaskHolderOrFollower,
                            LeaveDays: Info.LeaveDays,
                            IsSelectAll: Info.IsSelectAll,
                            SelectedDependency: Info.SelectedDependency,
                            PreferenceId: Info.PreferenceId,
                            IndexName: i.toString(),
                            TaskOwner: Info.TaskHolderOwnerIdList,
                            TaskHolderIDList: Info.TaskHolderBuyer,
                            FollowerIDList1: Info.FollowerCube,
                            FollowerIDList2: Info.FollowerSupplier,
                        })
                        if (Info.arrDependency) {
                            Info.arrDependency.forEach((child, j) => {
                                if (child.DependentTaskName !== 0) {
                                    child.IndexName = i.toString();
                                    MainValues[0].RouteDependencyDetails.push(child);
                                }
                            });
                        }
                        if (Info.FollowupDetails) {
                            Info.FollowupDetails.forEach((child, k) => {
                                child.IndexName = i.toString();
                                MainValues[0].RouteAddtionalTaskDetails.push(child);
                            });
                        }
                    }
                });
                if (IsValid) {
                    MainValues[0].IsProduction = IsProduction;
                    routeService.InsertUpdateRouteDetails(MainValues[0]).then(res => {
                        var page = "Remove";
                        SearchRetain(page);
                        let Func = 'Add';
                        if (res.data.outputResult === "1") {
                            Func = 'Add';
                            PageRedirect(Func);
                        } else if (res.data.outputResult === "2") {
                            Func = 'Edit';
                            PageRedirect(Func);
                        } else if (res.data.outputResult === "-2") {
                            setButtonLoader(false);
                            ValidationPopup("This Route name is already exist.");
                        }
                        else if (res.data.outputResult === "0") {
                            setButtonLoader(false);
                            ValidationPopup("Error Occured!");
                        }
                    });
                }
            }
        }
    }
    const handleRouteChange = (e, FieldName) => {
        if (FieldName === "RouteName") {
            if (e.target.value.trim() !== '') {
                MainValues[0].RouteName = e.target.value;
            }
            else {
                MainValues[0].RouteName = '';
            }
        }
        else if (FieldName === "BuyerID") {
            if (e !== null) {
                MainValues[0].BuyerID = parseInt(e.value);
            }
            else {
                MainValues[0].BuyerID = 0;
            }
            clearTaskHolderBuyer();
        }
        setMainFields(MainValues);
    }

    const clearTaskHolderBuyer = () => {
        values.map((inputField, index) => {
            values[index].TaskHolderIDList = "";
            values[index].TaskHolderBuyer = "";
            values[index].TaskHolderOwnerIdList = inputField.FollowerIDList1 ? (inputField.FollowerIDList1 + (inputField.FollowerIDList2 ? "," + inputField.FollowerIDList2 : "")) : inputField.FollowerIDList2;
        });
        setInputFields(values);
    }

    const filterOption = (option, inputValue) => {
        let trimValue = inputValue.trim();
        if (trimValue === "") {
            return option;
        }
        return option.data.subLabel.toLowerCase().includes(trimValue.toLowerCase());
    };

    const viewtoEdit = (e) => {
        addHeaderName("Edit");
    }


    // #region ------------------  TaskHolder LeaveDays Start --------------------------------

    const handleTaskHolderLeaveDays = (index, isTHFirstTime) => {
        setSubmitted(false);
        if (values[index].TaskID === 0) {
            Nodify('Warning!', 'warning', 'Please select the task name.');
        }
        else {

            if (values[index].titleEmployee1 !== "Cube" && values[index].titleEmployee2 !== "Cube" && values[index].titleEmployee3 !== "Cube") {

                dispatch(loadRouteTaskOwner(values[index].TaskHolderOrFollower, MainValues[0].BuyerID, 0))
                    .then(data => {
                        if (data != null) {
                            setTNATaskOwner(data, index, isTHFirstTime);
                        }
                        values[index].IsTHLeaveDaysOpen = 1;
                        setInputFields(values);
                    });
            } else {
                // dispatch(loadTNATaskOwner(values[index].TaskHolderOrFollower, currentUser.employeeinformationID));
                dispatch(loadRouteTaskOwner(values[index].TaskHolderOrFollower, MainValues[0].BuyerID, currentUser.employeeinformationID))
                    .then(data => {
                        if (data != null) {
                            setTNATaskOwner(data, index, isTHFirstTime);
                        }
                        values[index].IsTHLeaveDaysOpen = 1;
                        setInputFields(values);
                    });
            }
        }
    };

    function setTNATaskOwner(data, index, isTHFirstTime) {
        let titleEmployee1 = values[index].titleEmployee1;
        let titleEmployee2 = values[index].titleEmployee2;
        let titleEmployee3 = values[index].titleEmployee3;

        if (data.buyerEmployeeList && data.buyerEmployeeList.length > 0) {

            let taskHolderBuyer = values[index].TaskHolderIDList && data.buyerEmployeeList.filter(item => (values[index].TaskHolderIDList.split(',').includes(item.value.toString())));
            if (taskHolderBuyer && taskHolderBuyer.length > 0) {
                if (values[index].arrTaskHolderBuyer.length === 0 && isTHFirstTime === 0) {
                    values[index].arrTaskHolderBuyer = [...taskHolderBuyer];
                } else {
                    const isData = !!values[index].arrTaskHolderBuyer.filter(d => d.value === taskHolderBuyer.value);
                    if (isData === false && isTHFirstTime === 0) {
                        values[index].arrTaskHolderBuyer = [...values[index].arrTaskHolderBuyer, ...taskHolderBuyer];
                    }
                }

            }
        }
        if (data.cubeEmployeeList !== undefined) {
            let followerCube = values[index].FollowerIDList1 && data.cubeEmployeeList.filter(item => (values[index].FollowerIDList1.split(',').includes(item.value.toString())));
            if (followerCube && followerCube.length > 0) {
                if (values[index].arrFollowerCube.length === 0 && isTHFirstTime === 0) {
                    values[index].arrFollowerCube = [...followerCube];
                } else {
                    const isData = !!values[index].arrFollowerCube.filter(d => d.value === followerCube.value);
                    if (isData === false && isTHFirstTime === 0) {
                        values[index].arrFollowerCube = [...followerCube];
                    }
                }
            }
        }
        if (data.supplierEmployeeList !== undefined && (titleEmployee1 === "Supplier" || titleEmployee2 === "Supplier" || titleEmployee3 === "Supplier")) {
            let followerSupplier = values[index].FollowerIDList2 && data.supplierEmployeeList.filter(item => (values[index].FollowerIDList2.includes(item.value.toString())));
            if (followerSupplier && followerSupplier.length > 0) {
                if (values[index].arrFollowerSupplier.length === 0 && isTHFirstTime === 0) {
                    values[index].arrFollowerSupplier = [...followerSupplier];
                } else {
                    const isData = !!values[index].arrFollowerSupplier.filter(d => d.value === followerSupplier.value);
                    if (isData === false && isTHFirstTime === 0) {
                        values[index].arrFollowerSupplier = [...followerSupplier];
                    }
                }
            }
        }

        // if (isTHFirstTime === 0) {
        //     if (values[index].arrTaskHolderBuyer.length > 0) {
        //         let names = values[index].arrTaskHolderBuyer.map(x => x.label).join(",");
        //         values[index].TaskHolderBuyerTxt = names;
        //     }
        //     if (values[index].arrFollowerCube.length > 0) {
        //         let names = values[index].arrFollowerCube.map(x => x.label).join(",");
        //         values[index].FollowerCubeTxt = names;
        //     }
        //     if (values[index].arrFollowerSupplier.length > 0) {
        //         let names = values[index].arrFollowerSupplier.map(x => x.label).join(",");
        //         values[index].FollowerSupplierTxt = names;
        //     }
        // }
        values[index].IsTHFirstTime = 1;
    }

    const handleTHLeaveDaysSaveNext = (index) => {
        let Taskhoder = BindTaskHoldername(values, index);
        if (Taskhoder === "") {
            Nodify('Warning!', 'warning', 'Please select Task Holder name');
        } else {
            values[index].IsTHLeaveDaysOpen = 0;
            // save Next instance
            index = index + 1;
            let totalValue = values.length - 1;
            if (totalValue >= index) {
                handleTaskHolderLeaveDays(index, values[index].IsTHFirstTime)
            }
            else {
                values[index - 1].IsTHLeaveDaysOpen = 0;
                setInputFields(values);
            }
        }
    }

    const handleTHLeaveDaysClose = (index) => {
        BindTaskHoldername(values, index);
        values[index].IsTHLeaveDaysOpen = 0;
        setInputFields(values);
    }

    const handleTHLeaveDaysSave = (index) => {
        if (hasView) {
            values[index].IsTHLeaveDaysOpen = 0;
            setInputFields(values);
        } else {
            let Taskhoder = BindTaskHoldername(values, index);
            if (!Taskhoder) {
                setSubmitted(true);
                Nodify('Warning!', 'warning', 'Please select Task Holder name');
            } else {
                values[index].IsTHLeaveDaysOpen = 0;
                setInputFields(values);
            }
        }
    }

    function BindTaskHoldername(values, index) {
        let TxtList = "";
        let IdList = "";
        let Taskhoder = "";
        let titleEmployee1 = values[index].titleEmployee1;
        let titleEmployee2 = values[index].titleEmployee2;
        let titleEmployee3 = values[index].titleEmployee3;


        if (values[index].TaskHolderBuyer && (titleEmployee1 === "Buyer" || titleEmployee2 === "Buyer" || titleEmployee3 === "Buyer")) {
            //TxtList = values[index].TaskHolderBuyerTxt;
            IdList = values[index].TaskHolderBuyer;
            //Taskhoder = values[index].TaskHolderBuyer;
        } else {
            values[index].TaskHolderBuyer = "";
            values[index].arrTaskHolderBuyer = [];
        }
        if (values[index].FollowerCube && (titleEmployee1 === "Cube" || titleEmployee2 === "Cube" || titleEmployee3 === "Cube")) {
            //TxtList = TxtList === "" ? values[index].FollowerCubeTxt : TxtList + ", " + values[index].FollowerCubeTxt;
            IdList = IdList === "" ? values[index].FollowerCube : IdList + "," + values[index].FollowerCube;
        }
        else {
            values[index].FollowerCube = "";
            values[index].arrFollowerCube = [];
        }
        if (values[index].FollowerSupplier && (titleEmployee1 === "Supplier" || titleEmployee2 === "Supplier" || titleEmployee3 === "Supplier")) {
            //TxtList = TxtList === "" ? values[index].FollowerSupplierTxt : TxtList + ", " + values[index].FollowerSupplierTxt;
            IdList = IdList === "" ? values[index].FollowerSupplier : IdList + "," + values[index].FollowerSupplier;
        } else {
            values[index].FollowerSupplier = "";
            values[index].arrFollowerSupplier = [];
        }

        //values[index].TaskHolderOwnerTxtList = TxtList;
        values[index].TaskHolderOwnerIdList = IdList;


        if (values[index].titleEmployee1 === 'Cube') {
            Taskhoder = values[index].FollowerCube;
        }
        else if (values[index].titleEmployee1 === 'Buyer') {
            Taskhoder = values[index].TaskHolderBuyer;
        }
        else { Taskhoder = values[index].FollowerSupplier; }
        return Taskhoder;
    }

    const checkTaskHolderOwer = (index) => {
        let Taskhoder = "";
        if (values[index].titleEmployee1 === 'Cube') {
            Taskhoder = values[index].FollowerCube;
        }
        else if (values[index].titleEmployee1 === 'Buyer') {
            Taskhoder = values[index].TaskHolderBuyer;
        }
        else { Taskhoder = values[index].FollowerSupplier; }
        return Taskhoder;
    }

    // #end region ------------------  TaskHolder LeaveDays End --------------------------------



    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <div className="widget flat radius-bordered">
                            <div className="widget-header heading_box_style">
                                <h3 className="heading_style_common">{getHeaderName} Route</h3>
                                <div className="buttons-preview" align="right">
                                    <span className="btn btn-primary" title="Back to list"
                                        onClick={() => ResetOperation("Back")}>
                                        <i className="fa fa-arrow-circle-left"></i>
                                        &nbsp;Back</span>
                                    <span className="btn btn-danger" title="Reset Route"
                                        onClick={() => ResetOperation()}>
                                        <i className="fa fa-times"></i>
                                        &nbsp;Reset</span>
                                    {getHeaderName === "Edit" && (
                                        <button type="button" className="btn btn-success" disabled={buttonLoader} title="Update Route" onClick={SaveRoute}>
                                            <i className="fa fa-check right"></i>
                                            &nbsp;Update</button>
                                    )}
                                    {getHeaderName === "Export" && (
                                        <button type="button" className="btn btn-success" disabled={buttonLoader} title="Save Route" onClick={SaveRoute}>
                                            <i className="fa fa-check right"></i>
                                            &nbsp;Save</button>
                                    )}
                                    {getHeaderName === "View" && activeMenu[0].isView !== undefined && activeMenu[0].isView === 1 ? '' :
                                        activeMenu[0].isView !== undefined && activeMenu[0].isView === 0 ? '' :
                                            (
                                                <button type="button" style={{ display: activeMenu[0].isView !== undefined && activeMenu[0].isView === 0 ? '' : 'none' }} className="btn btn-success" onClick={viewtoEdit} title="Edit Route">
                                                    <i className="fa fa-edit right"></i>
                                                    &nbsp;Edit</button>
                                            )}
                                </div>
                            </div>

                            <div className="widget-body">
                                <div id="registration-form">
                                    <form>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className='form-group' style={{ marginLeft: "14px" }} >
                                                    <label htmlFor="BuyerID">Buyer<span className="text-danger">*</span></label>
                                                    <span className='input-icon icon-right'>
                                                        <Reactselect className="basic-single" name="BuyerID"
                                                            id={
                                                                "BuyerID"
                                                            }
                                                            isDisabled={hasView}
                                                            isLoading={false}
                                                            isClearable={false}
                                                            isSearchable={true}
                                                            onChange={event => handleRouteChange(event, 'BuyerID')}
                                                            value={getBuyerList.filter(function (option) {
                                                                return option.value === MainFields[0].BuyerID;
                                                            })}
                                                            options={getBuyerList}
                                                            autoFocus
                                                            styles={submitted && MainFields[0].BuyerID === 0 ? styles : ''}
                                                        ></Reactselect>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className='form-group'>
                                                    <label htmlFor="RouteName">Route<span className="text-danger">*</span></label>
                                                    <span className='input-icon icon-right'>
                                                        <input placeholder="Enter Route Name"
                                                            id="RouteName"
                                                            readOnly={hasView}
                                                            name="RouteName"
                                                            type="text"
                                                            autoComplete="off"
                                                            maxLength="50"
                                                            className={'form-control'}
                                                            value={MainFields[0].RouteName}
                                                            onChange={event => handleRouteChange(event, 'RouteName')}
                                                            style={{
                                                                border: submitted && MainFields[0].RouteName === '' ? '1px solid red' : ''
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-12 fixTableHead" style={{ overflow: "auto" }}> */}
                                        <div className="col-md-12" style={{ overflow: "auto" }}>
                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable" style={{ width: "110vm" }}>
                                                <thead>
                                                <tr>
                                                        <th style={{ width: "40px" }}> #</th>
                                                        <th style={{ width: "200px" }}>Task Type<span className="text-danger">*</span></th>
                                                        <th style={{ width: "320px" }}>Task Name<span className="text-danger">*</span></th>
                                                        <th style={{ width: "100px" }}>Duration<span className="text-danger">*</span></th>
                                                        <th style={{ width: "140px" }}>Dependency</th>
                                                        <th style={{ width: "180px" }}>Task Holder / Follower<span className="text-danger">*</span></th>
                                                        <th style={{ width: "180px" }}>Leave Days<span className="text-danger">*</span></th>
                                                        <th style={{ width: "110px" }} className="text-center fixed-column-header-Route">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        inputFields.map((inputField, index) => (
                                                            <Fragment key={
                                                                `${inputField}~${index}`
                                                            }>
                                                                <tr>
                                                                    <td>{index + 1}</td>
                                                                    <td>
                                                                        <span title={getTaskTypeList.filter(function (option) {
                                                                                    return option.value === inputField.TaskType;
                                                                                    })[0]?.label } className='input-icon icon-right'>
                                                                            <Reactselect className="basic-single" name="TaskType"
                                                                                id={
                                                                                    "TaskType" + index
                                                                                }
                                                                                isLoading={false}
                                                                                isDisabled={hasView}
                                                                                isClearable={true}
                                                                                isSearchable={true}
                                                                                onChange={event => handleChange(event, index, "TaskType")}
                                                                                onBlur={() => CheckDuplicate(index, 'TaskType')}
                                                                                value={getTaskTypeList.filter(function (option) {
                                                                                    return option.value === inputField.TaskType;
                                                                                })}
                                                                                options={getTaskTypeList}
                                                                                menuPosition="fixed"
                                                                                menuPlacement="auto"
                                                                                styles={submitted && inputField.TaskType === 0 ? styles : ''}
                                                                            ></Reactselect>
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span  title={getAllTaskList.filter(function (option) {
                                                                                    return option.value === inputField.TaskName;
                                                                                })[0]?.label} className='input-icon icon-right'>
                                                                            <Reactselect className="basic-single" name="TaskName"
                                                                                id={
                                                                                    "TaskName" + index
                                                                                }
                                                                                isLoading={false}
                                                                                isDisabled={hasView}
                                                                                isClearable={true}
                                                                                isSearchable={true}
                                                                                onChange={event => handleChange(event, index, "TaskName")}
                                                                                onBlur={() => CheckDuplicate(index, 'TaskName')}
                                                                                value={getAllTaskList.filter(function (option) {
                                                                                    return option.value === inputField.TaskName;
                                                                                })}
                                                                                options={inputField.TaskType === 0 ? getAllTaskList : getAllTaskList.filter(x => x.taskTypeID === inputField.TaskType)}
                                                                                isOptionDisabled={(option) => option.isdisabled}
                                                                                menuPosition="fixed"
                                                                                menuPlacement="auto"
                                                                                styles={submitted && inputField.TaskName === 0 ? styles : ''}
                                                                            ></Reactselect>
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span className='input-icon icon-right'>
                                                                            <input type="text"
                                                                                placeholder="Duration"
                                                                                id={"Duration" + index}
                                                                                name="Duration"
                                                                                readOnly={hasView}
                                                                                value={inputField.Duration}
                                                                                title={inputField.Duration}
                                                                                onChange={event => handleChange(event, index, "Duration")}
                                                                                onBlur={() => CheckDuplicate(index, 'Duration')}
                                                                                maxLength="3"
                                                                                className={'form-control' + (
                                                                                    submitted && !inputField.Duration ? ' is-invalid' : ''
                                                                                )}
                                                                                style={{ border: submitted && !inputField.Duration ? '1px solid red' : '' }}
                                                                            />
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            index === 0 ? '' :
                                                                                <input type="text"
                                                                                    value={inputField.SelectedDependency}
                                                                                    title={inputField.SelectedDependency}
                                                                                    onClick={() => handleDependency(index, 1,inputField.SelectedDependency)}
                                                                                    className={'form-control'}
                                                                                    style={{ width: inputField.DependencyCount === 0 ? "100%" : "85%", float: "left" }}
                                                                                />
                                                                        }
                                                                        {/* {
                                                                            index !== 0 ?
                                                                                inputField.DependencyCount !== 0 ?
                                                                                    <span className="spanDependencyCount" onClick={() => handleDependency(index, 1)}> {inputField.DependencyCount}</span> : ''
                                                                                : ''
                                                                        } */}
                                                                    </td>
                                                                    <td>
                                                                        <span
                                                                            className={'form-control fa fa-eye'} onClick={() => handleTaskHolderLeaveDays(index, inputField.IsTHFirstTime)}
                                                                            style={{ width: "100%", paddingTop: "8px", float: "left", border: submitted && !checkTaskHolderOwer(index) ? '1px solid red' : '' }}
                                                                        >&nbsp;&nbsp;&nbsp;
                                                                            {
                                                                                getTaskHolderList.filter(d => d.value === inputField.TaskHolderOrFollower).map(task => {
                                                                                    return task.label;
                                                                                })
                                                                            }
                                                                        </span>
                                                                        {/* <input type="text"
                                                                            value={inputField.TaskHolderOrFollowerTxt}
                                                                            title={inputField.TaskHolderOrFollowerTxt}
                                                                            onClick={() => handleTaskHolderLeaveDays(index, inputField.IsTHFirstTime)}
                                                                            className={'form-control'}
                                                                            //disabled={Buttonvisible === 'View' ? true : false}
                                                                            //style={{ width: "100%", float: "left" }}
                                                                            style={{ width: "100%", float: "left", border: submitted && !inputField.TaskHolderOwnerIdList ? '1px solid red' : '' }}
                                                                        /> */}
                                                                    </td>
                                                                    <td>
                                                                        {/* <Reactselect className="basic-single" name="LeaveDays"
                                                                            id={
                                                                                "LeaveDays"
                                                                            }
                                                                            isLoading={false}
                                                                            isClearable={true}
                                                                            isSearchable={true}
                                                                            onChange={event => handleChange(event, index, "LeaveDays")}
                                                                            value={getLeaveDaysList.filter(function (option) {
                                                                                return option.value === inputField.LeaveDays;
                                                                            })}
                                                                            options={getLeaveDaysList}
                                                                            styles={submitted && inputField.LeaveDays === 0 ? styles : ''}
                                                                            menuPosition="fixed"
                                                                            menuPlacement="auto"
                                                                        ></Reactselect> */}

                                                                        <input type="text"
                                                                            value={inputField.LeaveDaysTxt}
                                                                            title={inputField.LeaveDaysTxt}
                                                                            //disabled={Buttonvisible === 'View' ? true : false}
                                                                            onClick={() => handleTaskHolderLeaveDays(index, inputField.IsTHFirstTime)}
                                                                            className={'form-control'}
                                                                            style={{ width: "100%", float: "left" }}
                                                                        />
                                                                    </td>
                                                                    <td className="fixed-column-Route">
                                                                        {inputField.IsDependencyOpen === 1 &&

                                                                            <Modal show={inputField.IsDependencyOpen === 1} size="lg" backdrop="static" keyboard={false} onHide={() => handleDependencyClose(index)}>
                                                                                <Modal.Header closeButton>
                                                                                    <Modal.Title>
                                                                                        {/* Task Name : {inputField.strTaskName} */}
                                                                                        Dependency (Selected Task - {DepandencyTaskName}, Row no - {Depandencyrowno})
                                                                                    </Modal.Title>
                                                                                </Modal.Header>
                                                                                <Modal.Body className="dependency-overflow">
                                                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th style={{ width: "50px" }}>#</th>
                                                                                                <th style={{ width: "450px" }}>Task Name</th>
                                                                                                <th style={{ width: "200px" }}>Dependency</th>
                                                                                                <th style={{ width: "150px" }}>Log Days</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                inputField.arrDependency.map((ChildField, childIndex) => (
                                                                                                    <Fragment key={
                                                                                                        `${ChildField}~${childIndex}`
                                                                                                    }>
                                                                                                        <tr>
                                                                                                            <td>{childIndex + 1}</td>
                                                                                                            <td>{ChildField.RouteTaskName}</td>
                                                                                                            <td>
                                                                                                                <span className='input-icon icon-right'>
                                                                                                                    <Reactselect className="basic-single" name="DependencyID"
                                                                                                                        id={
                                                                                                                            "DependencyID" + index
                                                                                                                        }
                                                                                                                        isLoading={false}
                                                                                                                        isClearable={true}
                                                                                                                        isSearchable={true}
                                                                                                                        onChange={event => handleChildChange(event, index, childIndex, "DependencyID")}
                                                                                                                        value={getDependencyList.filter(function (option) {
                                                                                                                            return option.value === ChildField.DependencyID;
                                                                                                                        })}
                                                                                                                        options={getDependencyList}
                                                                                                                        isDisabled={(childIndex === index || hasView) ? true :
                                                                                                                            ChildField.RouteTaskName === '' ? true :
                                                                                                                                childIndex >= index ? true :
                                                                                                                                    false}
                                                                                                                    ></Reactselect>
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <span className='input-icon icon-right'>
                                                                                                                    <input type="text"
                                                                                                                        placeholder="    Log days"
                                                                                                                        id={"LogDays" + index}
                                                                                                                        name="LogDays"
                                                                                                                        value={ChildField.LogDays}
                                                                                                                        onChange={event => handleChildChange(event, index, childIndex, "LogDays")}
                                                                                                                        maxLength="3"
                                                                                                                        className={'form-control' + (
                                                                                                                            submitted && !inputField.LogDays ? ' is-invalid' : ''
                                                                                                                        )}
                                                                                                                        disabled={(ChildField.DependencyID === 0 || hasView) ? true : false}
                                                                                                                    />
                                                                                                                </span>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </Fragment>
                                                                                                ))
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </Modal.Body>
                                                                                <Modal.Footer>
                                                                                    {getHeaderName !== "View" && (
                                                                                        <Button variant="success" onClick={() => handleDependencyClose(index)}>
                                                                                            Save
                                                                                        </Button>
                                                                                        
                                                                                    )}
                                                                                    {getHeaderName !== "View" && (
                                                                                        <Button variant="success" onClick={() => handleDependencyCloseSaveNext(index)}>
                                                                                             Save & Next 
                                                                                        </Button>
                                                                                        
                                                                                    )}
                                                                                </Modal.Footer>
                                                                            </Modal>
                                                                        }
                                                                        {inputField.IsTHLeaveDaysOpen === 1 &&
                                                                            <Modal show={inputField.IsTHLeaveDaysOpen === 1} size="lg" backdrop="static" keyboard={false} onHide={() => handleTHLeaveDaysClose(index)}>
                                                                                <Modal.Header closeButton>
                                                                                    <Modal.Title>
                                                                                        Leave Days / In-charges - {inputField.strTaskName}
                                                                                    </Modal.Title>
                                                                                </Modal.Header>
                                                                                <Modal.Body>
                                                                                    <div className="row">
                                                                                        <div className="col-md-12">
                                                                                            <div className='form-group'>
                                                                                                <label> Task Holder / Follower </label>
                                                                                                <span className='input-icon icon-right'>
                                                                                                    <Reactselect className="basic-single" name="TaskHolderOrFollower"
                                                                                                        id={"TaskHolderOrFollower"}
                                                                                                        isDisabled={hasView}
                                                                                                        isLoading={false}
                                                                                                        isClearable={true}
                                                                                                        isSearchable={true}
                                                                                                        onChange={event => handleChange(event, index, "TaskHolderOrFollower")}
                                                                                                        value={getTaskHolderList.filter(function (option) {
                                                                                                            return option.value === inputField.TaskHolderOrFollower;
                                                                                                        })}
                                                                                                        options={getTaskHolderList}
                                                                                                        styles={submitted && inputField.TaskHolderOrFollower === 0 ? styles : ''}
                                                                                                        menuPosition="fixed"
                                                                                                        filterOption={filterOption}
                                                                                                    ></Reactselect>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-md-12">
                                                                                            <div className='form-group'>
                                                                                                <label>Leave Days</label>
                                                                                                <span className='input-icon icon-right'>
                                                                                                    <Reactselect className="basic-single" name="LeaveDays"
                                                                                                        id={
                                                                                                            "LeaveDays"
                                                                                                        }
                                                                                                        isLoading={false}
                                                                                                        isClearable={true}
                                                                                                        isDisabled={hasView}
                                                                                                        isSearchable={true}
                                                                                                        onChange={event => handleChange(event, index, "LeaveDays")}
                                                                                                        value={getLeaveDaysList.filter(function (option) {
                                                                                                            return option.value === inputField.LeaveDays;
                                                                                                        })}
                                                                                                        options={getLeaveDaysList}
                                                                                                        styles={submitted && inputField.LeaveDays === 0 ? styles : ''}
                                                                                                        menuPosition="fixed"

                                                                                                    ></Reactselect>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {inputField.titleEmployee1 && <div className="row">
                                                                                        <div className="col-md-12">
                                                                                            <div className='form-group'>
                                                                                                <label htmlFor={inputField.titleEmployee1 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee1 === "Cube" ? "FollowerCube" : "FollowerSupplier"} >Task Holder - {inputField.titleEmployee1 + " Employee"} <span className="text-danger">*</span></label>
                                                                                                <span className='input-icon icon-right'>
                                                                                                    <Reactselect className="basic-single" name={inputField.titleEmployee1 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee1 === "Cube" ? "FollowerCube" : "FollowerSupplier"}
                                                                                                        isDisabled={hasView}
                                                                                                        isLoading={false}
                                                                                                        isClearable={true}
                                                                                                        isSearchable={true}
                                                                                                        isMulti
                                                                                                        styles={submitted && (inputField.FollowerCubeTxt === "" || inputField.FollowerSupplierTxt === "") ? styles : ''}
                                                                                                        onChange={event => handleChange(event, index, inputField.titleEmployee1 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee1 === "Cube" ? "FollowerCube" : "FollowerSupplier")}
                                                                                                        options={inputField.titleEmployee1 === "Buyer" ? drpRouteTaskOwnerList.buyerEmployeeList : inputField.titleEmployee1 === "Cube" ? drpRouteTaskOwnerList.cubeEmployeeList : drpRouteTaskOwnerList.supplierEmployeeList}
                                                                                                        value={inputField.titleEmployee1 === "Buyer" ? drpRouteTaskOwnerList.buyerEmployeeList && inputField.arrTaskHolderBuyer : inputField.titleEmployee1 === "Cube" ? drpRouteTaskOwnerList.cubeEmployeeList && inputField.arrFollowerCube : drpRouteTaskOwnerList.supplierEmployeeList && inputField.arrFollowerSupplier}
                                                                                                    >  </Reactselect>

                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    }

                                                                                    {
                                                                                        inputField.titleEmployee2 && <div className="row">
                                                                                            <div className="col-md-12">
                                                                                                <div className='form-group'>
                                                                                                    <label htmlFor={inputField.titleEmployee2 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee2 === "Cube" ? "FollowerCube" : "FollowerSupplier"} >Follower - {inputField.titleEmployee2 + " Employee"}</label>
                                                                                                    < span className='input-icon icon-right'>
                                                                                                        <Reactselect className="basic-single" name={inputField.titleEmployee2 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee2 === "Cube" ? "FollowerCube" : "FollowerSupplier"}
                                                                                                            isDisabled={hasView}
                                                                                                            isLoading={false}
                                                                                                            isClearable={true}
                                                                                                            isSearchable={true}
                                                                                                            isMulti
                                                                                                            onChange={event => handleChange(event, index, inputField.titleEmployee2 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee2 === "Cube" ? "FollowerCube" : "FollowerSupplier")}
                                                                                                            options={inputField.titleEmployee2 === "Buyer" ? drpRouteTaskOwnerList.buyerEmployeeList : inputField.titleEmployee2 === "Cube" ? drpRouteTaskOwnerList.cubeEmployeeList : drpRouteTaskOwnerList.supplierEmployeeList}
                                                                                                            value={inputField.titleEmployee2 === "Buyer" ? inputField.arrTaskHolderBuyer : inputField.titleEmployee2 === "Cube" ? inputField.arrFollowerCube : inputField.arrFollowerSupplier}
                                                                                                        // options={IMPORTANCE}
                                                                                                        // value={getStyleList.arrImportance}
                                                                                                        //  styles={submitted && getStyleList.arrImportance === 0 ? styles : ''}
                                                                                                        >  </Reactselect>

                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                    {inputField.titleEmployee3 && <div className="row">
                                                                                        <div className="col-md-12">
                                                                                            <div className='form-group'>
                                                                                                <label htmlFor={inputField.titleEmployee3 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee3 === "Cube" ? "FollowerCube" : "FollowerSupplier"} >Follower - {inputField.titleEmployee3 + " Employee"}</label>
                                                                                                <span className='input-icon icon-right'>
                                                                                                    <Reactselect className="basic-single" name={inputField.titleEmployee3 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee3 === "Cube" ? "FollowerCube" : "FollowerSupplier"}
                                                                                                        isDisabled={hasView}
                                                                                                        isLoading={false}
                                                                                                        isClearable={true}
                                                                                                        isSearchable={true}
                                                                                                        isMulti
                                                                                                        onChange={event => handleChange(event, index, inputField.titleEmployee3 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee3 === "Cube" ? "FollowerCube" : "FollowerSupplier")}
                                                                                                        options={inputField.titleEmployee3 === "Buyer" ? drpRouteTaskOwnerList.buyerEmployeeList : inputField.titleEmployee3 === "Cube" ? drpRouteTaskOwnerList.cubeEmployeeList : drpRouteTaskOwnerList.supplierEmployeeList}
                                                                                                        value={inputField.titleEmployee3 === "Buyer" ? inputField.arrTaskHolderBuyer : inputField.titleEmployee3 === "Cube" ? inputField.arrFollowerCube : inputField.arrFollowerSupplier}
                                                                                                    // options={IMPORTANCE}
                                                                                                    // value={getStyleList.arrImportance}
                                                                                                    //  styles={submitted && getStyleList.arrImportance === 0 ? styles : ''}
                                                                                                    >  </Reactselect>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    }




                                                                                </Modal.Body>
                                                                                <Modal.Footer>
                                                                                    {!hasView &&
                                                                                        <>
                                                                                            <Button variant="success" onClick={() => handleTHLeaveDaysSave(index)}>
                                                                                                Save
                                                                                            </Button>
                                                                                            <Button variant="success" onClick={() => handleTHLeaveDaysSaveNext(index)}>
                                                                                                Save & Next
                                                                                            </Button>
                                                                                        </>
                                                                                    }


                                                                                </Modal.Footer>
                                                                            </Modal>
                                                                        }
                                                                        {inputField.IsPopupOpen === 1 &&
                                                                            <Modal show={inputField.IsPopupOpen === 1} size="md" dragable backdrop="static" keyboard={false} onHide={() => handleClose(index)}>
                                                                                <Modal.Header closeButton>
                                                                                    <Modal.Title>Additional Task</Modal.Title>
                                                                                </Modal.Header>
                                                                                <Modal.Body>
                                                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>#</th>
                                                                                                <th>Task Name</th>
                                                                                                {getHeaderName !== "View" &&
                                                                                                    <th> <div className="checkbox" style={{ marginTop: "0px" }}>
                                                                                                        <label>
                                                                                                            <input type="checkbox"
                                                                                                                name="IsSelectAll"
                                                                                                                id={"IsSelectAll_" + index}
                                                                                                                isMulti
                                                                                                                onChange={event => handleChange(event, index, "IsSelectAll")}
                                                                                                                checked={inputField.IsSelectAll === 1 ? true : false}
                                                                                                                className="colored-blue clearOpacity" />
                                                                                                            <span className="text"></span>
                                                                                                        </label>
                                                                                                    </div></th>
                                                                                                }
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                inputField.FollowupDetails.map((ChildField, childIndex) => (
                                                                                                    <Fragment key={
                                                                                                        `${inputField}~${index}`
                                                                                                    }>
                                                                                                        <tr>
                                                                                                            <td>{childIndex + 1}</td>
                                                                                                            <td>
                                                                                                                <input type="text"
                                                                                                                    value={ChildField.AddtionalTaskName}
                                                                                                                    className={'form-control'}
                                                                                                                    disabled
                                                                                                                />
                                                                                                            </td>
                                                                                                            {getHeaderName !== "View" &&
                                                                                                                <td>
                                                                                                                    <div className="checkbox" style={{ marginTop: "0px" }}>
                                                                                                                        <label>
                                                                                                                            <input type="checkbox"
                                                                                                                                name="IsTaskSelected"
                                                                                                                                id={"IsTaskSelected_" + index + "_" + childIndex}
                                                                                                                                isMulti
                                                                                                                                onChange={event => handleAddTaskChange(event, index, childIndex, "IsTaskSelected")}
                                                                                                                                checked={ChildField.IsTaskSelected === 1 ? true : false}
                                                                                                                                className="colored-blue clearOpacity"
                                                                                                                            />
                                                                                                                            <span className="text"></span>
                                                                                                                        </label>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            }
                                                                                                        </tr>
                                                                                                    </Fragment>
                                                                                                ))
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </Modal.Body>
                                                                                <Modal.Footer>
                                                                                    {getHeaderName !== "View" && (
                                                                                        <Button variant="success" onClick={() => handleClose(index)}> Save </Button>
                                                                                    )}
                                                                                </Modal.Footer>
                                                                            </Modal>
                                                                        }
                                                                        <button type="button" className="btn btn-xs btn-primary" title={(getHeaderName === "Edit" ? "Edit" : getHeaderName === "Export" ? "Add" : "View") + " Additional Task"}
                                                                            onClick={() => handleFollowup(index)}>
                                                                            <b> {inputField.AdditionalFollowup} </b>
                                                                            <i className="fa fa-tasks"></i>
                                                                        </button>
                                                                        &nbsp;
                                                                        {!hasView &&
                                                                            <button type="button" className="btn btn-xs btn-success"
                                                                                style={{ display: activeMenu[0].isAdd !== undefined && activeMenu[0].isAdd === 1 ? '' : 'none' }}
                                                                                title="Add Task"
                                                                                onClick={() => handleAddFields(index)}>
                                                                                <i className="fa fa-plus"></i>
                                                                            </button>
                                                                        }

                                                                        &nbsp;
                                                                        {(inputFields.length !== 1 && getHeaderName !== "View") && (
                                                                            <button type="button" className="btn btn-xs btn-danger" title="Delete Task"
                                                                                style={{ display: activeMenu[0].isDelete !== undefined && activeMenu[0].isDelete === 1 ? '' : 'none' }}
                                                                                onClick={() => handleRemoveFields(index)}>
                                                                                <i className="fa fa-trash-o"></i>
                                                                            </button>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            </Fragment>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>
                            <span className="btn btn-primary" title="Back to list" onClick={() => ResetOperation("Back")}>
                                <i className="fa fa-arrow-circle-left"></i>
                                &nbsp;Back</span>
                            <span className="btn btn-danger" title="Reset Route" onClick={() => ResetOperation()}>
                                <i className="fa fa-times"></i>
                                &nbsp;Reset</span>
                            {getHeaderName === "Edit" && (
                                <button type="button" className="btn btn-success" disabled={buttonLoader} title="Update Route" onClick={SaveRoute}>
                                    <i className="fa fa-check right"></i>
                                    &nbsp;Update</button>
                            )}
                            {getHeaderName === "Export" && (
                                <button type="button" className="btn btn-success" disabled={buttonLoader} title="Save Route" onClick={SaveRoute}>
                                    <i className="fa fa-check right"></i>
                                    &nbsp;Save</button>
                            )}
                            {getHeaderName === "View" && activeMenu[0].isView !== undefined && activeMenu[0].isView === 1 ? '' :
                                activeMenu[0].isView !== undefined && activeMenu[0].isView === 0 ? '' : (
                                    <button type="button" style={{ display: activeMenu[0].isView !== undefined && activeMenu[0].isView === 0 ? '' : 'none' }} className="btn btn-success" onClick={viewtoEdit} title="Edit Route">
                                        <i className="fa fa-edit right"></i>
                                        &nbsp;Edit</button>
                                )}
                        </div>
                    </div>
                </div>
            </div>
            {loader}
        </>
    )
};
export default RouteEdit;