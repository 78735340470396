import React from 'react';
import { useEffect, useMemo, useState, Fragment} from 'react';
import ReactNotification from 'react-notifications-component';
import { TableHeader, Pagination, Search, PageCount } from "../../../Datatable";
import { useDispatch, useSelector } from "react-redux";
import { retrieveTestName, retriveMappingList } from '../../../../actions/testname';
import SearchRetain from "../../../Common/SearchRetain";
import Nodify from "../../../Common/ReactNotification";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import { MapID } from "../../../Common/PageActionNumber";
import SweetAlertPopup from '../../../Common/SweetAlertPopup';
import tesitemservice from '../../../../services/Master/tesitemservice';
import moment from "moment";
const MappingMasterList = (props) => {

  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [getPlaceholder] = useState("Test Name");
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
  const [getTestitem, setTestitem] = useState([]);
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const dispatch = useDispatch();
  const [getID, setID] = useState({ showPopupDelete: false, Params: {} });
  let activeMenu;
  const menulist = useSelector((state) => state.common.MenuList.item3);
  const reducerState = useSelector((state) => state);
  const comments = reducerState.testname.MappingList.item1;
  let isLoadingMapping = reducerState.testname.isLoadingMapping;
  if (menulist !== undefined) {
    activeMenu = menulist.filter(i => i.menuName === "Mapping");
  }

  const headers = [
    { name: "#", field: "", sortable: false },
    { name: "Test Name", field: "testName", sortable: true },
    { name: "Test Task Name", field: "testTaskName", sortable: true },
    { name: "Created / Modified Date", field: "modifieddate", sortable: true },

    {
      name: "Action", field: "", sortable: false,   display: activeMenu && activeMenu[0].isEdit === 0 &&
      activeMenu[0].isDelete === 0 ? 'none' : ''
    },
  ];


  const pageRedirect = (MappingParams, redirect, page, TestitemParam) => {
    localStorage.setItem('Pagination', JSON.stringify({ CurrentPage: currentPage, Page: page, PageItems: ITEMS_PER_PAGE }));
    if (redirect === 'Add') {
      props.history.push(`/AddMapping`);
    } else if (redirect === 'Edit') {
      props.history.push(`/EditMapping`, { params: MappingParams, TestitemParam });
    } else {
      setID({ showPopupDelete: true, Params: MappingParams, TestitemParam });
    }
  };

  const Delcallback = (value) => {
    dispatch(retriveMappingList(1, 0, 0));
    setID({ showPopupDelete: false, Params: [] });
  }

  const commentsData = useMemo(() => {
    let computedComments = comments;
    if (computedComments !== undefined) {
      if (search) {
        computedComments = computedComments.filter(test => test.testName.toLowerCase().includes(search.toLowerCase()));
      }

      if (sorting.field) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        computedComments = computedComments.sort((a, b) => {
          const valueA = a[sorting.field];
          const valueB = b[sorting.field];
      
          // Check if both values are numeric
          const isNumericA = !isNaN(valueA);
          const isNumericB = !isNaN(valueB);
      
          // Check if both values are dates with time
          const isDateTimeA = !isNaN(Date.parse(valueA));
          const isDateTimeB = !isNaN(Date.parse(valueB));
      
          if (isNumericA && isNumericB) {
            // If both values are numeric, compare as numbers
            return reversed * (parseFloat(valueA) - parseFloat(valueB));
          } else if (isDateTimeA && isDateTimeB) {
            // If both values are dates with time, compare as dates
            return reversed * (new Date(valueA) - new Date(valueB));
          } else {
            // If one or both values are non-numeric and non-date with time, compare as strings
            return reversed * valueA.toString().localeCompare(valueB.toString());
          }
        });
      }



      // if (sorting.field) {
      //   const reversed = sorting.order === "asc" ? 1 : -1;



      //   computedComments = computedComments.sort((a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]));
      // }
      let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
      if (records.length === 0 && currentPage !== 1) {
        setCurrentPage(currentPage - 1);
      }
      setTotalItems(computedComments.length);
      return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
    }
  }, [
    comments,
    currentPage,
    search,
    sorting,
    ITEMS_PER_PAGE
  ]);

  useEffect(() => {
    tesitemservice.GetTestItemList().then((res => {
      let TestItem = []
      if (res.data) {
        res.data.item1.map((x, index) => {
          TestItem.push({
            value: x.testItemID,
            label: x.testItem
          })
        })
        setTestitem(TestItem)
      }
    }))
    dispatch(retriveMappingList(1, 0, 0));
    const state1 = props.location.state;
    if (state1 !== undefined && state1 != null) {
      let Msg;
      let Type = 'success';
      let title = 'Success!';
      if (state1.message === 'Edit') {
        Msg = 'Mapping updated successfully.';
      } else if (state1.message === 'Add') {
        Msg = 'Mapping added successfully.';
      } else {
        Msg = 'Error occured';
        Type = 'warning';
        title = 'Error!';
      }
      window.history.replaceState(null, '')
      Nodify(title, Type, Msg);
    }
    var page = getPlaceholder;
    var setConstraint = SearchRetain(page);
    if (setConstraint !== undefined) {
      setCurrentPage(setConstraint.CurrentPage);
      setITEMS_PER_PAGE(setConstraint.PageItems);
    }
  }, [dispatch]);

  useEffect(() => {
    if (!isLoadingMapping) {
      hideLoader();
    } else {
      showLoader();
    }
  }, [isLoadingMapping]);
  return (
    <>
      <div className="page-body">
        <ReactNotification />
        <div className="widget-header">
          <span className="widget-caption">Mapping List</span>
        </div>
        <div className="widget-body" style={
          { paddingBottom: '4%' }
        }>
          <div className="dataTables_wrapper form-inline no-footer">
            <div className="table-toolbar pull-left">
              <Search
                onSearch={
                  value => {
                    setSearch(value);
                    setCurrentPage(1);
                  }
                }
                placeholder={getPlaceholder} props={props} />
            </div>
            <div className="table-toolbar pull-right"  style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}>
              &nbsp;
              <span
                className="btn btn-primary" title='Add Mapping' style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }} onClick={() => pageRedirect("", 'Add')}>
                <i className="fa fa-plus"></i>&nbsp;Add
              </span>
            </div>
            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
              <TableHeader headers={headers} onSorting={(field, order) => setSorting({ field, order })} />
              <tbody>
                {
                  commentsData !== undefined && commentsData.map((comment,i) => (
                    <Fragment key={i}>
                    <tr >
                    <td style={{width:'25px'}}>  
                        { i + 1}
                    </td>
                      <td>{comment.testName}</td>
                      <td>{comment.testTaskName}</td>
                      <td>{comment.modifieddate}</td>

                      <td  style={{
                                                            display: activeMenu && activeMenu[0].isEdit === 0 &&
                                                                activeMenu[0].isDelete === 0 ? 'none' : ''
                                                        }}>
                        <span style={{ display: activeMenu && activeMenu[0].isEdit === 1 ? '' : 'none' }} title='Edit Test Name' className="btn btn-info btn-xs edit"
                          onClick={() => pageRedirect(comment, 'Edit', getPlaceholder, getTestitem)}>
                          <i className='fa fa-edit'></i>
                        </span>&nbsp;
                        <span style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }} title='Delete Test Name' className="btn btn-danger btn-xs delete" onClick={() => pageRedirect(comment, '', getPlaceholder, getTestitem)}>
                          <i className='fa fa-trash'></i>
                        </span>
                      </td>
                    </tr>
                    </Fragment>
                  ))
                }
                {
                  totalItems === 0 ?
                    <tr>
                      <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
                    </tr> : ''
                }
              </tbody>
            </table>
            <PageCount onPageCount={
              value => {
                setITEMS_PER_PAGE(value);
                setCurrentPage(1);
              }
            } />
            <Pagination total={totalItems}
              itemsPerPage={
                parseInt(ITEMS_PER_PAGE)
              }
              currentPage={currentPage}
              onPageChange={
                page => setCurrentPage(page)
              } />
          </div>
        </div>
        {
          getID.showPopupDelete ? <SweetAlertPopup data={
            getID.Params
          }
            deleteCallback={Delcallback}
            showpopup={true}
            pageActionId={MapID}
            Msg={"Mapping Detail deleted successfully."} /> : null
        }
      </div>
      {loader}
    </>
  )
}

export default MappingMasterList