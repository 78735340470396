export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const REQUESTOTP_FORGOTPASSWORD = "REQUESTOTP_FORGOTPASSWORD";
export const ERASE_FORGOTPASSWORD_OTP = "ERASE_FORGOTPASSWORD_OTP";

//MENUS
export const ACTIVE_MENUS = "ACTIVE_MENUS";
export const SIDE_BAR_MENU_VISIBLE = "SIDE_BAR_MENU_VISIBLE";
export const FULL_SIDE_BAR_MENU_VISIBLE = "FULL_SIDE_BAR_MENU_VISIBLE";

//BRANCH
export const BRANCH_LOADING = "BRANCH_LOADING";
export const CREATE_BRANCH = "CREATE_BRANCH";
export const RETRIEVE_BRANCH = "RETRIEVE_BRANCH";
export const RETRIEVE_DROPDOWN_BRANCH = "RETRIEVE_DROPDOWN_BRANCH";
export const UPDATE_BRANCH = "UPDATE_BRANCH";
export const DELETE_BRANCH = "DELETE_BRANCH";

//MESSAGE
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

//ROUTE
export const ROUTE_LOADING = "ROUTE_LOADING";
export const RETRIEVE_ROUTE = "RETRIEVE_ROUTE";
export const DELETE_ROUTE = "DELETE_ROUTE";
export const ROUTE_DEPENDENCY_DROPDOWN_LIST = "ROUTE_DEPENDENCY_DROPDOWN_LIST";
export const ROUTE_TASK_OWNER_LIST = "ROUTE_TASK_OWNER_LIST";

//PURCHASE ORDER
export const PURCHASEORDER_LOADING = "PURCHASEORDER_LOADING";
export const RETRIEVE_PURCHASEORDER = "RETRIEVE_PURCHASEORDER";
export const DELETE_PURCHASEORDER = "DELETE_PURCHASEORDER";

//STRIKEOFF
export const STRIKEOFF_LOADING = "STRIKEOFF_LOADING";
export const RETRIEVE_STRIKEOFF = "RETRIEVE_STRIKEOFF";
export const DELETE_STRIKEOFF = "DELETE_STRIKEOFF";

//TRIM
export const TRIM_LOADING = "TRIM_LOADING";
export const RETRIEVE_TRIM = "RETRIEVE_TRIM";
export const DELETE_TRIM = "DELETE_TRIM";

//TASKTYPE
export const TASKTYPE_LOADING = "TASKTYPE_LOADING";
export const RETRIEVE_TASKTYPE = "RETRIEVE_TASKTYPE";
export const DELETE_TASKTYPE = "DELETE_TASKTYPE";
export const TASKTYPE_DROPDOWN_LIST = "TASKTYPE_DROPDOWN_LIST";
export const TASKTYPE_DROPDOWN_MAP_LIST = "TASKTYPE_DROPDOWN_MAP_LIST";

//STYLE
export const STYLE_LOADING = "STYLE_LOADING";
export const RETRIEVE_STYLE = "RETRIEVE_STYLE";
export const RETRIEVE_COPYSTYLE = "RETRIEVE COPYSTYLE"
export const DELETE_STYLE = "DELETE_STYLE";

export const RETRIEVE_SKU_STYLE = "RETRIEVE_SKU_STYLE";
export const DELETE_SKU_STYLE = "DELETE_SKU_STYLE";

export const RETRIEVE_PROGRAM_STYLE = "RETRIEVE_PROGRAM_STYLE";
export const DELETE_PROGRAM_STYLE = "DELETE_PROGRAM_STYLE";
export const UPDATE_PROGRAM_GRID_LIST = "UPDATE_PROGRAM_GRID_LIST";

export const RETRIEVE_STRIKE_OFF_STYLE = "RETRIEVE_STRIKE_OFF_STYLE";
export const DELETE_STRIKE_OFF_STYLE = "DELETE_STRIKE_OFF_STYLE";
export const UPDATE_STRIKEOFF_MAP_LIST = "UPDATE_STRIKEOFF_MAP_LIST";


export const RETRIEVE_TRIM_STYLE = "RETRIEVE_TRIM_STYLE";
export const DELETE_TRIM_STYLE = "DELETE_TRIM_STYLE";
export const UPDATE_TRIM_MAP_LIST = "UPDATE_TRIM_MAP_LIST";

export const UPDATE_QUICK_STYLE_CREATE = "UPDATE_QUICK_STYLE_CREATE";


export const RETRIEVE_TNA_LABDIP_ALREADY_MAP_LIST = "RETRIEVE_TNA_LABDIP_ALREADY_MAP_LIST";
export const RETRIEVE_TNA_STRIKEOFF_ALREADY_MAP_LIST = "RETRIEVE_TNA_STRIKEOFF_ALREADY_MAP_LIST";
export const RETRIEVE_TNA_TRIM_ALREADY_MAP_LIST = "RETRIEVE_TNA_TRIM_ALREADY_MAP_LIST";

export const SEND_STYLE_MAIL = "SEND_STYLE_MAIL";
export const STYLE_EMAIL_EMPLOYEE_LIST = "STYLE_EMAIL_EMPLOYEE_LIST";
export const GET_STYLE_EMAIL_LIST = "GET_STYLE_EMAIL_LIST";
export const INSERT_STYLEMAIL_ATTACHMENT = "INSERT_STYLEMAIL_ATTACHMENT";
export const DELETE_MAIL_ATTACHMENT = "DELETE_MAIL_ATTACHMENT";


//TASK
export const TASK_LOADING = "TASK_LOADING";
export const RETRIEVE_TASK = "RETRIEVE_TASK";
export const TASK_ROW = "TASK_ROW";
export const DELETE_TASK = "DELETE_TASK";
export const TASK_DROPDOWN_LIST = "TASK_DROPDOWN_LIST";
export const ALL_TASK_DROPDOWN_LIST = "ALL_TASK_DROPDOWN_LIST";
export const TASK_HOLDER_DROPDOWN_LIST = "TASK_HOLDER_DROPDOWN_LIST";

//DEPARTMENT
export const DEPARTMENT_LOADING = "DEPARTMENT_LOADING";
export const RETRIEVE_DEPARTMENT = "RETRIEVE_DEPARTMENT";
export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT";

//ROLE
export const ROLE_LOADING = "ROLE_LOADING";
export const RETRIEVE_ROLE = "RETRIEVE_ROLE";
export const RETRIEVE_USERTYPE = "RETRIEVE_USERTYPE";
export const DELETE_ROLE = "DELETE_ROLE";

//USER PERMISSION
export const USER_LOADING = "USER_LOADING";
export const RETRIEVE_USER = "RETRIEVE_USER";
export const RETRIEVE_USERROLE = "RETRIEVE_USERROLE";
export const RETRIEVE_MENU = "RETRIEVE_MENU";
export const RETRIEVE_USERPERMISSON = "RETRIEVE_USERPERMISSON";
export const DELETE_USER = "DELETE_USER";
// export const EXISTING_MENU_BRAND_PERMISSION_LIST = "EXISTING_MENU_BRAND_PERMISSION_LIST";
export const EDIT_MENU_USER_PERMISSION_LIST = "EDIT_MENU_USER_PERMISSION_LIST";

//COMPANY
export const COMPANY_LOADING = "COMPANY_LOADING";
export const RETRIEVE_COMPANY = "RETRIEVE_COMPANY";
export const DELETE_COMPANY = "DELETE_COMPANY";

//BUYER
export const BUYER_LOADING = "BUYER_LOADING";
export const RETRIEVE_BUYER = "RETRIEVE_BUYER";
export const DELETE_BUYER = "DELETE_BUYER";

//BUYER INFO
export const BUYERINFO_LOADING = "BUYERINFO_LOADING";
export const RETRIEVEINFO_BUYER = "RETRIEVEINFO_BUYER";
export const DELETEINFO_BUYER = "DELETEINFO_BUYER";

//SUPPLIER INFO
export const SUPPLIERINFO_LOADING = "SUPPLIERINFO_LOADING";
export const RETRIEVEINFO_SUPPLIER = "RETRIEVEINFO_SUPPLIER";
export const DELETEINFO_SUPPLIER = "DELETEINFO_SUPPLIER";

//GENDER
export const GENDER_LOADING = "GENDER_LOADING";
export const RETRIEVE_GENDER = "RETRIEVE_GENDER";
export const DELETE_GENDER = "DELETE_GENDER";

//CURRENCY
export const CURRENCY_LOADING = "CURRENCY_LOADING";
export const RETRIEVE_CURRENCY = "RETRIEVE_CURRENCY";
export const DELETE_CURRENCY = "DELETE_CURRENCY";

//PANTONE
export const PANTONE_LOADING = "PANTONE_LOADING";
export const RETRIEVE_PANTONE = "RETRIEVE_PANTONE";
export const DELETE_PANTONE = "DELETE_PANTONE";

//PANTONE TYPE
export const PANTONETYPE_LOADING = "PANTONETYPE_LOADING";
export const RETRIEVE_PANTONETYPE = "RETRIEVE_PANTONETYPE";
export const DELETE_PANTONETYPE = "DELETE_PANTONETYPE";

//FABRIC TYPE
export const FABRICTYPE_LOADING = "FABRICTYPE_LOADING";
export const RETRIEVE_FABRICTYPE = "RETRIEVE_FABRICTYPE";
export const DELETE_FABRICTYPE = "DELETE_FABRICTYPE";

//YARNTYPE
export const YARNTYPE_LOADING = "YARNTYPE_LOADING";
export const RETRIEVE_YARNTYPE = "RETRIEVE_YARNTYPE";
export const DELETE_YARNTYPE = "DELETE_YARNTYPE";

//SIZE
export const SIZE_LOADING = "SIZE_LOADING";
export const RETRIEVE_SIZE = "RETRIEVE_SIZE";
export const DELETE_SIZE = "DELETE_SIZE";
export const DROPDOWN_SIZE_LIST = "DROPDOWN_SIZE_LIST";

//CITY
export const CITY_LOADING = "CITY_LOADING";
export const RETRIEVE_CITY = "RETRIEVE_CITY";
export const DELETE_CITY = "DELETE_CITY";

//GROUP TYPE
export const GROUPTYPE_LOADING = "GROUPTYPE_LOADING";
export const RETRIEVE_GROUPTYPE = "RETRIEVE_GROUPTYPE";
export const DELETE_GROUPTYPE = "DELETE_GROUPTYPE";

//CATEGORY
export const CATEGORY_LOADING = "CATEGORY_LOADING";
export const RETRIEVE_CATEGORY = "RETRIEVE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";

//STRIKEOFF TYPE
export const STRIKEOFFTYPE_LOADING = "STRIKEOFFTYPE_LOADING";
export const RETRIEVE_STRIKEOFFTYPE = "RETRIEVE_STRIKEOFFTYPE";
export const DELETE_STRIKEOFFTYPE = "DELETE_STRIKEOFFTYPE";

//TRIM TYPE
export const TRIMTYPE_LOADING = "TRIMTYPE_LOADING";
export const RETRIEVE_TRIMTYPE = "RETRIEVE_TRIMTYPE";
export const DELETE_TRIMTYPE = "DELETE_TRIMTYPE";

//MEASUREMENT POINT
export const MEASUREMENTPOINT_LOADING = "MEASUREMENTPOINT_LOADING";
export const RETRIEVE_MEASUREMENTPOINT = "RETRIEVE_MEASUREMENTPOINT";
export const DELETE_MEASUREMENTPOINT = "DELETE_MEASUREMENTPOINT";

//QA COMMENT POINT
export const QACOMMENTPOINT_LOADING = "QACOMMENTPOINT_LOADING";
export const RETRIEVE_QACOMMENTPOINT = "RETRIEVE_QACOMMENTPOINT";
export const DELETE_QACOMMENTPOINT = "DELETE_QACOMMENTPOINT";

//TEST NAME
export const TESTNAME_LOADING = "TEST_NAME_LOADING";
export const RETRIEVE_TESTNAME = "RETRIEVE_TESTNAME";
export const DELETE_TESTNAME = "DELETE_TESTNAME";


//BRAND WISE STANDARD MASTER
export const BRAND_WISE_STANDARD_MASTER_LOADING = "BRAND_WISE_STANDARD_MASTER_LOADING";
export const RETRIEVE_BRAND_WISE_STANDARD_MASTER = "RETRIEVE_BRAND_WISE_STANDARD_MASTER";
export const DELETE_BRAND_WISE_STANDARD_MASTER = "DELETE_BRAND_WISE_STANDARD_MASTER";


//MAPPING LIST
export const MAPPING_LOADING = "MAPPING_LOADING";
export const RETRIEVE_MAPPING = "RETRIEVE_MAPPING";
export const DELETE_MAPPING = "DELETE_MAPPING";

//TEST ITEM
export const TESTITEM_LOADING = "TESTITEM_LOADING";
export const RETRIEVE_TESTITEM = "RETRIEVE_TESTITEM";
export const DELETE_TESTITEM = "DELETE_TESTITEM";

//TEST FIELD
export const TESTFIELD_LOADING = "TESTFIELD_LOADING";
export const RETRIEVE_TESTFIELD = "RETRIEVE_TESTFIELD";
export const DELETE_TESTFIELD = "DELETE_TESTFIELD";

//STANDARD FIELD
export const TESTSTANDARD_LOADING = "TESTSTANDARD_LOADING";
export const RETRIEVE_TESTSTANDARD = "RETRIEVE_TESTSTANDARD";
export const DELETE_TESTSTANDARD = "DELETE_TESTSTANDARD";

//COLOR DEPTH
export const COLORDEPTH_LOADING = "COLORDEPTH_LOADING";
export const RETRIEVE_COLORDEPTH = "RETRIEVE_COLORDEPTH";
export const DELETE_COLORDEPTH = "DELETE_COLORDEPTH";

//ALIGNMENT
export const ALIGNMENT_LOADING = "ALIGNMENT_LOADING";
export const RETRIEVE_ALIGNMENT = "RETRIEVE_ALIGNMENT";
export const DELETE_ALIGNMENT = "DELETE_ALIGNMENT";


//SWATCH MASTER
export const SWATCH_LOADING = "SWATCH_LOADING";
export const RETRIEVE_SWATCH = "RETRIEVE_SWATCH";
export const DELETE_SWATCH = "DELETE_SWATCH";

//SEASON
export const SEASON_LOADING = "SEASON_LOADING";
export const CREATE_SEASON_SUCCESS = "CREATE_SEASON_SUCCESS";
export const UPDATE_SEASON_SUCCESS = "UPDATE_SEASON_SUCCESS";
export const RETRIEVE_SEASON = "RETRIEVE_SEASON";
export const DELETE_SEASON = "DELETE_SEASON";
//PROCESSTYPE
export const PROCESSTYPE_LOADING = "PROCESSTYPE_LOADING";
export const CREATE_PROCESSTYPE_SUCCESS = "CREATE_PROCESSTYPE_SUCCESS";
export const UPDATE_PROCESSTYPE_SUCCESS = "UPDATE_PROCESSTYPE_SUCCESS";
export const RETRIEVE_PROCESSTYPE = "RETRIEVE_PROCESSTYPE";
export const DELETE_PROCESSTYPE = "DELETE_PROCESSTYPE";

//FABRIC PART
export const FABRICPART_LOADING = "FABRICPART_LOADING";
export const RETRIEVE_FABRICPART = "RETRIEVE_FABRICPART";
export const DELETE_FABRICPART = "DELETE_FABRICPART";

//TNA
export const TNA_LOADING = "TNA_LOADING";
export const TNA_PURCHASE_ORDER_LIST = "TNA_PURCHASE_ORDER_LIST";
//export const TNA_PURCHASE_ORDER_PAGINATION = "TNA_PURCHASE_ORDER_PAGINATION";
export const RETRIEVE_DROPDOWN_BRAND = "RETRIEVE_DROPDOWN_BRAND";
export const RETRIEVE_DROPDOWN_SEASON = "RETRIEVE_DROPDOWN_SEASON";
export const RETRIEVE_DROPDOWN_SUPPLIER = "RETRIEVE_DROPDOWN_SUPPLIER";
export const TNA_PURCHASE_ORDER_STYLE_ID_LIST = "TNA_PURCHASE_ORDER_STYLE_ID_LIST";
export const RETRIEVE_DROPDOWN_TNA_ROUTE = "RETRIEVE_DROPDOWN_TNA_ROUTE";
export const TNA_ALL_ROUTE_INFO_LIST = "TNA_ALL_ROUTE_INFO_LIST";
export const TNA_DROPDOWN_ROUTE_ID = "TNA_DROPDOWN_ROUTE_ID";
export const TNA_LIST = "TNA_LIST";
export const TNA_COMMENTSLIST = "TNA_COMMENTSLIST";
export const TNA_ATTACHMENTSLIST = "TNA_ATTACHMENTSLIST";
export const TNA_DETIALLIST = "TNA_DETIALLIST";
export const TNA_REPORTLIST = "TNA_REPORTLIST";
export const TNA_TASK_OWNER_LIST = "TNA_TASK_OWNER_LIST";
export const TNA_COMPANY_HOLIDAY_LIST = "TNA_COMPANY_HOLIDAY_LIST";
export const TNA_TASK_INPUT_FIELDS_LIST = "TNA_TASK_INPUT_FIELDS_LIST";
export const TNA_DROPDOWN_SUPPLIER_LIST = "TNA_DROPDOWN_SUPPLIER_LIST";
export const TNA_DROPDOWN_ACTIVEBUYER_LIST = "TNA_DROPDOWN_ACTIVEBUYER_LIST";
export const TNA_VIEWLIST = "TNA_VIEWLIST";
export const RETRIEVE_DROPDOWN_TNA_SUPPLIER_EMPLOYEE = "RETRIEVE_DROPDOWN_TNA_SUPPLIER_EMPLOYEE";
export const NEW_PURCHASE_ORDER_SKU_LIST = "NEW_PURCHASE_ORDER_SKU_LIST";
export const UPDATE_NEW_PURCHASE_ORDER_SKU_LIST_CHECK_BOX = "UPDATE_NEW_PURCHASE_ORDER_SKU_LIST_CHECK_BOX";
//LEAVE DAYS
export const LEAVE_DAY_DROPDOWN_LIST = "LEAVE_DAY_DROPDOWN_LIST";


//SAMPLE REQUEST
export const SAMPLEREQ_LOADING = "SAMPLEREQ_LOADING";
export const RETRIEVE_SAMPLEREQ = "RETRIEVE_SAMPLEREQ";
export const DELETE_SAMPLEREQ = "DELETE_SAMPLEREQ";


//MASTERSIZE
export const MASTERSIZE_LOADING = "MASTERSIZE_LOADING";
export const CREATE_MASTERSIZE_SUCCESS = "CREATE_MASTERSIZE_SUCCESS";
export const UPDATE_MASTERSIZE_SUCCESS = "UPDATE_MASTERSIZE_SUCCESS";
export const RETRIEVE_MASTERSIZE = "RETRIEVE_MASTERSIZE";
export const DELETE_MASTERSIZE = "DELETE_MASTERSIZE";


//GENERAL LABDIP
export const GENERALLABDIP_LOADING = " GENERALLABDIP_LOADING";
export const RETRIEVE_GENERALLABDIP = "RETRIEVE_GENERALLABDIP";
export const DELETE_GENERALLABDIP = "DELETE_GENERALLABDIP";
export const RETRIEVE_GEN_LABDIP_ALREADY_MAP_LIST = "RETRIEVE_GEN_LABDIP_ALREADY_MAP_LIST";
export const UPDATE_GEN_PROGRAM_GRID_LIST = "UPDATE_GEN_PROGRAM_GRID_LIST";

//GENERAL LABDIP TABS
export const RETRIEVE_GENERAL_LABDIP_DETAIL_VIEW_LIST = "RETRIEVE_GENERAL_LABDIP_DETAIL_VIEW_LIST";
export const CLEAR_GENERAL_LABDIP_DETAIL_VIEW_SUB_ROW = "CLEAR_GENERAL_LABDIP_DETAIL_VIEW_SUB_ROW";
export const RETRIEVE_GENERAL_LABDIP_SUBMISSION_LIST = "RETRIEVE_GENERAL_LABDIP_SUBMISSION_LIST";
export const CLEAR_GENERAL_LABDIP_SUBMISSION_SUB_ROW = "CLEAR_GENERAL_LABDIP_SUBMISSION_SUB_ROW";
export const RETRIEVE_GENERAL_LABDIP_APPROVAL_LIST = "RETRIEVE_GENERAL_LABDIP_APPROVAL_LIST";
export const CLEAR_GENERAL_LABDIP_APPROVAL_SUB_ROW = "CLEAR_GENERAL_LABDIP_APPROVAL_SUB_ROW";
export const RETRIEVE_GENERAL_LABDIP_APPROVAL_VIEW_LIST = "RETRIEVE_GENERAL_LABDIP_APPROVAL_VIEW_LIST";
export const CLEAR_GENERAL_LABDIP_APPROVAL_VIEW_SUB_ROW = "CLEAR_GENERAL_LABDIP_APPROVAL_VIEW_SUB_ROW";


export const UPDATE_GENERAL_LABDIP_DETAIL_VIEW_CHECK_BOX = "UPDATE_GENERAL_LABDIP_DETAIL_VIEW_CHECK_BOX";
export const NEW_TNA_GENERAL_LABDIP_LIST = "NEW_TNA_GENERAL_LABDIP_LIST";
export const TNA_GENERAL_LABDIP_HOLIDAY_LIST = "TNA_GENERAL_LABDIP_HOLIDAY_LIST";
export const REMOVE_TNA_GENERAL_LABDIP_PANTONE_SWATCH_LIST = "REMOVE_TNA_GENERAL_LABDIP_PANTONE_SWATCH_LIST";
export const TNA_GENERAL_LABDIP_SUBMISSION_AND_APPROVAL = "TNA_GENERAL_LABDIP_SUBMISSION_AND_APPROVAL";
export const GENERAL_LABDIP_PROGRAM_STATUS_LIST = "GENERAL_LABDIP_PROGRAM_STATUS_LIST";
export const GLD_TNA_TASK_OWNER_LIST = "GLD_TNA_TASK_OWNER_LIST";
export const UPDATE_GENERAL_LAPDIP_DETAIL_VIEW_ALL_CHECK_BOX = "UPDATE_GENERAL_LAPDIP_DETAIL_VIEW_ALL_CHECK_BOX"

//GENERAL STRIKEOFF
export const GENERALSTRIKEOFF_LOADING = "GENERALSTRIKEOFF_LOADING";
export const RETRIEVE_GENERALSTRIKEOFF = "RETRIEVE_GENERALSTRIKEOFF";
export const DELETE_GENERALSTRIKEOFF = "DELETE_GENERALSTRIKEOFF";

//GENERAL STRIKEOFF TABS
export const RETRIEVE_GENERAL_STRIKEOFF_DETAIL_VIEW_LIST = "RETRIEVE_GENERAL_STRIKEOFF_DETAIL_VIEW_LIST";
export const CLEAR_GENERAL_STRIKEOFF_DETAIL_VIEW_SUB_ROW = "CLEAR_GENERAL_STRIKEOFF_DETAIL_VIEW_SUB_ROW";
export const UPDATE_GENERAL_STRIKEOFF_DETAIL_VIEW_CHECK_BOX = "UPDATE_GENERAL_STRIKEOFF_DETAIL_VIEW_CHECK_BOX";
export const NEW_TNA_GENERAL_STRIKEOFF_REFERENCE_LIST = "NEW_TNA_GENERAL_STRIKEOFF_REFERENCE_LIST";
export const REMOVE_TNA_GENERAL_STRIKEOFF_REFERENCE_LIST = "REMOVE_TNA_GENERAL_STRIKEOFF_REFERENCE_LIST";
export const UPDATE_GENERAL_STRIKEOFF_DETAIL_VIEW_ALL_CHECK_BOX = "UPDATE_GENERAL_STRIKEOFF_DETAIL_VIEW_ALL_CHECK_BOX";
export const RETRIEVE_GENERAL_STRIKEOFF_SUBMISSION_LIST = "RETRIEVE_GENERAL_STRIKEOFF_SUBMISSION_LIST";
export const RETRIEVE_GENERAL_STRIKEOFF_APPROVAL_LIST = "RETRIEVE_GENERAL_STRIKEOFF_APPROVAL_LIST";
export const CLEAR_GENERAL_STRIKEOFF_SUBMISSION_SUB_ROW = "CLEAR_GENERAL_STRIKEOFF_SUBMISSION_SUB_ROW";
export const TNA_TASK_GENERAL_STRIKEOFF_SUBMISSION_AND_APPROVAL = "TNA_TASK_GENERAL_STRIKEOFF_SUBMISSION_AND_APPROVAL";
export const CLEAR_GENERAL_STRIKEOFF_APPROVAL_SUB_ROW = "CLEAR_GENERAL_STRIKEOFF_APPROVAL_SUB_ROW";
export const RETRIEVE_GENERAL_STRIKEOFF_APPROVAL_VIEW_LIST = "RETRIEVE_GENERAL_STRIKEOFF_APPROVAL_VIEW_LIST";
export const CLEAR_GENERAL_STRIKEOFF_APPROVAL_VIEW_SUB_ROW = "CLEAR_GENERAL_STRIKEOFF_APPROVAL_VIEW_SUB_ROW";
export const UPDATE_GENERAL_STRIKEOFF_IS_APPROVAL_MANUAL_CLOSE_POPUP = "UPDATE_GENERAL_STRIKEOFF_IS_APPROVAL_MANUAL_CLOSE_POPUP";
export const GENERAL_STRIKEOFF_REFERENCE_STATUS_LIST = "GENERAL_STRIKEOFF_REFERENCE_STATUS_LIST";
export const REMOVE_GENERAL_STRIKEOFF_REQUEST_VIEW_SUB_ROW = "REMOVE_GENERAL_STRIKEOFF_REQUEST_VIEW_SUB_ROW";
//GENERAL TRIM
export const GENERALTRIM_LOADING = "GENERALTRIM_LOADING";
export const RETRIEVE_GENERALTRIM = "RETRIEVE_GENERALTRIM";
export const DELETE_GENERALTRIM = "DELETE_GENERALTRIM";

//GENERAL TNA REPORT
export const GENERAL_TNA_REPORT_LOADING = "GENERAL_TNA_REPORT_LOADING";
export const GENERAL_TNA_REPORT_LIST = "GENERAL_TNA_REPORT_LIST";
export const UPDATE_GENERAL_TNA_REPORT_IS_EXPANDED = "UPDATE_GENERAL_TNA_REPORT_IS_EXPANDED";
export const UPDATE_GENERAL_TNA_REPORT_PARENT_IS_EXPANDED = "UPDATE_GENERAL_TNA_REPORT_PARENT_IS_EXPANDED";
export const CLEAR_GENERAL_TNA_REPORT_LIST = "CLEAR_GENERAL_TNA_REPORT_LIST";

//GENERAL TRIM TABS
export const RETRIEVE_GENERAL_TRIM_DETAIL_VIEW_LIST = "RETRIEVE_GENERAL_TRIM_DETAIL_VIEW_LIST";
export const CLEAR_GENERAL_TRIM_DETAIL_VIEW_SUB_ROW = "CLEAR_GENERAL_TRIM_DETAIL_VIEW_SUB_ROW";
export const UPDATE_GENERAL_TRIM_DETAIL_VIEW_CHECK_BOX = "UPDATE_GENERAL_TRIM_DETAIL_VIEW_CHECK_BOX";
export const NEW_TNA_GENERAL_TRIM_REFERENCE_LIST = "NEW_TNA_GENERAL_TRIM_REFERENCE_LIST";
export const REMOVE_TNA_GENERAL_TRIM_REFERENCE_LIST = "REMOVE_TNA_GENERAL_TRIM_REFERENCE_LIST";
export const UPDATE_GENERAL_TRIM_DETAIL_VIEW_ALL_CHECK_BOX = "UPDATE_GENERAL_TRIM_DETAIL_VIEW_ALL_CHECK_BOX";
export const RETRIEVE_GENERAL_TRIM_SUBMISSION_LIST = "RETRIEVE_GENERAL_TRIM_SUBMISSION_LIST";
export const RETRIEVE_GENERAL_TRIM_APPROVAL_LIST = "RETRIEVE_GENERAL_TRIM_APPROVAL_LIST";
export const CLEAR_GENERAL_TRIM_SUBMISSION_SUB_ROW = "CLEAR_GENERAL_TRIM_SUBMISSION_SUB_ROW";
export const TNA_TASK_GENERAL_TRIM_SUBMISSION_AND_APPROVAL = "TNA_TASK_GENERAL_TRIM_SUBMISSION_AND_APPROVAL";
export const CLEAR_GENERAL_TRIM_APPROVAL_SUB_ROW = "CLEAR_GENERAL_TRIM_APPROVAL_SUB_ROW";
export const RETRIEVE_GENERAL_TRIM_APPROVAL_VIEW_LIST = "RETRIEVE_GENERAL_TRIM_APPROVAL_VIEW_LIST";
export const CLEAR_GENERAL_TRIM_APPROVAL_VIEW_SUB_ROW = "CLEAR_GENERAL_TRIM_APPROVAL_VIEW_SUB_ROW";
export const UPDATE_GENERAL_TRIM_IS_APPROVAL_MANUAL_CLOSE_POPUP = "UPDATE_GENERAL_TRIM_IS_APPROVAL_MANUAL_CLOSE_POPUP";
export const GENERAL_TRIM_REFERENCE_STATUS_LIST = "GENERAL_TRIM_REFERENCE_STATUS_LIST";
export const REMOVE_GENERAL_TRIM_REQUEST_VIEW_SUB_ROW = "REMOVE_GENERAL_TRIM_REQUEST_VIEW_SUB_ROW";

//PRODUCTION SAMPLES
export const SAMPLES_LOADING = "SAMPLES_LOADING";
export const SAMPLES_RELOADING = "SAMPLES_RELOADING";
export const RETRIEVE_SAMPLES = "RETRIEVE_SAMPLES";


//ORDER LABDIP
export const ORDERLABDIP_LOADING = "ORDERLABDIP_LOADING";
export const ORDERLABDIP_RELOADING = "ORDERLABDIP_RELOADING";
export const RETRIEVE_ORDERLABDIP = "RETRIEVE_ORDERLABDIP";
export const DELETE_ORDERLABDIP = "DELETE_ORDERLABDIP";
export const RETRIEVE_ORD_LABDIP_ALREADY_MAP_LIST = "RETRIEVE_ORD_LABDIP_ALREADY_MAP_LIST";
export const UPDATE_ORD_PROGRAM_GRID_LIST = "UPDATE_ORD_PROGRAM_GRID_LIST";

//ORDER LABDIP TABS
export const RETRIEVE_ORDER_LABDIP_DETAIL_VIEW_LIST = "RETRIEVE_ORDER_LABDIP_DETAIL_VIEW_LIST";
export const CLEAR_ORDER_LABDIP_DETAIL_VIEW_SUB_ROW = "CLEAR_ORDER_LABDIP_DETAIL_VIEW_SUB_ROW";
export const RETRIEVE_ORDER_LABDIP_SUBMISSION_LIST = "RETRIEVE_ORDER_LABDIP_SUBMISSION_LIST";
export const CLEAR_ORDER_LABDIP_SUBMISSION_SUB_ROW = "CLEAR_ORDER_LABDIP_SUBMISSION_SUB_ROW";
export const RETRIEVE_ORDER_LABDIP_APPROVAL_LIST = "RETRIEVE_ORDER_LABDIP_APPROVAL_LIST";
export const CLEAR_ORDER_LABDIP_APPROVAL_SUB_ROW = "CLEAR_ORDER_LABDIP_APPROVAL_SUB_ROW";
export const RETRIEVE_ORDER_LABDIP_APPROVAL_VIEW_LIST = "RETRIEVE_ORDER_LABDIP_APPROVAL_VIEW_LIST";
export const CLEAR_ORDER_LABDIP_APPROVAL_VIEW_SUB_ROW = "CLEAR_ORDER_LABDIP_APPROVAL_VIEW_SUB_ROW";


export const UPDATE_ORDER_LABDIP_DETAIL_VIEW_CHECK_BOX = "UPDATE_ORDER_LABDIP_DETAIL_VIEW_CHECK_BOX";
export const NEW_TNA_ORDER_LABDIP_LIST = "NEW_TNA_ORDER_LABDIP_LIST";
export const TNA_ORDER_LABDIP_HOLIDAY_LIST = "TNA_ORDER_LABDIP_HOLIDAY_LIST";
export const REMOVE_TNA_ORDER_LABDIP_PANTONE_SWATCH_LIST = "REMOVE_TNA_ORDER_LABDIP_PANTONE_SWATCH_LIST";
export const TNA_ORDER_LABDIP_SUBMISSION_AND_APPROVAL = "TNA_ORDER_LABDIP_SUBMISSION_AND_APPROVAL";
export const ORDER_LABDIP_PROGRAM_STATUS_LIST = "ORDER_LABDIP_PROGRAM_STATUS_LIST";
export const OLD_TNA_TASK_OWNER_LIST = "OLD_TNA_TASK_OWNER_LIST";
export const UPDATE_ORDER_LAPDIP_DETAIL_VIEW_ALL_CHECK_BOX = "UPDATE_ORDER_LAPDIP_DETAIL_VIEW_ALL_CHECK_BOX"

//ORDER TRIM
export const ORDER_TRIM_LOADING = " ORDER_TRIM_LOADING";
export const ORDER_TRIM_RELOADING = " ORDER_TRIM_RELOADING";
export const RETRIEVE_ORDER_TRIM = "RETRIEVE_ORDER_TRIM";
export const DELETE_ORDER_TRIM = "DELETE_ORDER_TRIM";

//ORDER TRIM TABS
export const RETRIEVE_ORDER_TRIM_DETAIL_VIEW_LIST = "RETRIEVE_ORDER_TRIM_DETAIL_VIEW_LIST";
export const CLEAR_ORDER_TRIM_DETAIL_VIEW_SUB_ROW = "CLEAR_ORDER_TRIM_DETAIL_VIEW_SUB_ROW";
export const RETRIEVE_ORDER_TRIM_SUBMISSION_LIST = "RETRIEVE_ORDER_TRIM_SUBMISSION_LIST";
export const TNA_TASK_ORDER_TRIM_SUBMISSION_AND_APPROVAL = "TNA_TASK_ORDER_TRIM_SUBMISSION_AND_APPROVAL";
export const CLEAR_ORDER_TRIM_SUBMISSION_SUB_ROW = "CLEAR_ORDER_TRIM_SUBMISSION_SUB_ROW";
export const RETRIEVE_ORDER_TRIM_APPROVAL_LIST = "RETRIEVE_ORDER_TRIM_APPROVAL_LIST";
export const CLEAR_ORDER_TRIM_APPROVAL_SUB_ROW = "CLEAR_ORDER_TRIM_APPROVAL_SUB_ROW";
export const CLEAR_ORDER_TRIM_APPROVAL_VIEW_SUB_ROW = "CLEAR_ORDER_TRIM_APPROVAL_VIEW_SUB_ROW";
export const RETRIEVE_ORDER_TRIM_APPROVAL_VIEW_LIST = "RETRIEVE_ORDER_TRIM_APPROVAL_VIEW_LIST";
//ORDER STRIKE_OFF
export const ORDER_STRIKE_OFF_LOADING = "ORDER_STRIKE_OFF_LOADING";
export const ORDER_STRIKE_OFF_RELOADING = "ORDER_STRIKE_OFF_RELOADING";
export const RETRIEVE_ORDER_STRIKE_OFF = "RETRIEVE_ORDER_STRIKE_OFF";
export const DELETE_ORDER_STRIKE_OFF = "DELETE_ORDER_STRIKE_OFF";

//ORDER STRIKE_OFF TABS
export const RETRIEVE_ORDER_STRIKE_OFF_DETAIL_VIEW_LIST = "RETRIEVE_ORDER_STRIKE_OFF_DETAIL_VIEW_LIST";
export const CLEAR_ORDER_STRIKE_OFF_DETAIL_VIEW_SUB_ROW = "CLEAR_ORDER_STRIKE_OFF_DETAIL_VIEW_SUB_ROW";
export const RETRIEVE_ORDER_STRIKEOFF_SUBMISSION_LIST = "RETRIEVE_ORDER_STRIKEOFF_SUBMISSION_LIST";
export const CLEAR_ORDER_STRIKEOFF_SUBMISSION_SUB_ROW = "CLEAR_ORDER_STRIKEOFF_SUBMISSION_SUB_ROW";
export const TNA_TASK_ORDER_STRIKEOFF_SUBMISSION_AND_APPROVAL = "TNA_TASK_ORDER_STRIKEOFF_SUBMISSION_AND_APPROVAL";
export const REMOVE_ORDER_STRIKEOFF_REQUEST_VIEW_SUB_ROW = 'REMOVE_ORDER_STRIKEOFF_REQUEST_VIEW_SUB_ROW';
export const RETRIEVE_ORDER_STRIKEOFF_APPROVAL_LIST = "RETRIEVE_ORDER_STRIKEOFF_APPROVAL_LIST";
export const CLEAR_ORDER_STRIKEOFF_APPROVAL_SUB_ROW = "CLEAR_ORDER_STRIKEOFF_APPROVAL_SUB_ROW";
export const UPDATE_ORDER_STRIKEOFF_IS_APPROVAL_MANUAL_CLOSE_POPUP = "UPDATE_ORDER_STRIKEOFF_IS_APPROVAL_MANUAL_CLOSE_POPUP";
export const CLEAR_ORDER_STRIKEOFF_APPROVAL_VIEW_SUB_ROW = "CLEAR_ORDER_STRIKEOFF_APPROVAL_VIEW_SUB_ROW";
export const RETRIEVE_ORDER_STRIKEOFF_APPROVAL_VIEW_LIST = "RETRIEVE_ORDER_STRIKEOFF_APPROVAL_VIEW_LIST";

//MENU
export const RETRIEVE_ALLMENU = "RETRIEVE_ALLMENU";
export const RETRIEVE_CUBEEMP = "RETRIEVE_CUBEEMP";
export const RETRIEVE_SUPEMP = "RETRIEVE_SUBEMP";

//CHART DATA
export const DASHBOARD_CHART_LOADING = "DASHBOARD_CHART_LOADING";
export const DASHBOARD_CHARTS = "DASHBOARD_CHARTS";

//COURIER
export const INSERT_UPDATE_COURIER_DETAILS = "INSERT_UPDATE_COURIER_DETAILS";
export const LOAD_COURIER_LIST = "LOAD_COURIER_LIST";
export const COURIER_LOADING = "COURIER_LOADING";
export const DELETE_COURIER = "DELETE_COURIER";
export const VIEW_COURIER_STATUS = "VIEW_COURIER_STATUS";

//AGENCY
export const LOAD_AGENCY_DETAILS = "LOAD_AGENCY_DETAILS";
export const AGENCY_LOADING = "AGENCY_LOADING";


//REPORT
export const REPORT_LOADING = "REPORT_LOADING";
export const LOAD_REPORT_LIST = "LOAD_REPORT_LIST";
export const UPDATE_COMMENT_REPORT_VIEW_ALL_CHECK_BOX = "UPDATE_COMMENT_REPORT_VIEW_ALL_CHECK_BOX"
export const UPDATE_COMMENT_REPORT_VIEW_CHECK_BOX = "UPDATE_COMMENT_REPORT_VIEW_CHECK_BOX"


// TNADETAILSREPORT
export const TNADETAILREPORT_LOADING = "TNADETAILREPORT_LOADING";
export const LOAD_TNADETAILREPORT_LIST = "LOAD_TNADETAILREPORT_LIST";

//STATE
export const STATE_LOADING = "STATE_LOADING";
export const RETRIEVE_STATE = "RETRIEVE_STATE";
export const DELETE_STATE = "DELETE_STATE";


//DEFECT LIST
export const DEFECT_LOADING = "DEFECT_LOADING";
export const RETRIEVE_DEFECT = "RETRIEVE_DEFECT";
export const DELETE_DEFECT = "DELETE_DEFECT";

//ITEM LIST
export const ITEMS_LOADING = "ITEMS_LOADING";
export const RETRIEVE_ITEMS = "RETRIEVE_ITEMS";
export const DELETE_ITEMS = "DELETE_ITEMS";


//QA LIST
export const QA_LOADING = "QA_LOADING";
export const RETRIEVE_QA = "RETRIEVE_QA";
export const DELETE_QA = "DELETE_QA";

//WORKMANSHIPITEM DETAILS
export const WORKMANSHIPITEM_LOADING = "WORKMANSHIPITEM_LOADING";
export const RETRIEVE_WORKMANSHIPITEM = "RETRIEVE_WORKMANSHIPITEM";
export const DELETE_WORKMANSHIPITEM = "DELETE_WORKMANSHIPITEM";

//WORKMANSHIP GROUPS
export const WORKMANSHIPGROUP_LOADING = "WORKMANSHIPITEM_LOADING";
export const RETRIEVE_WORKMANSHIPGROUP = "RETRIEVE_WORKMANSHIPGROUP";
export const DELETE_WORKMANSHIPGROUP = "DELETE_WORKMANSHIPGROUP";


//Template List
export const TEMPLATELIST_LOADING = "TEMPLATELIST_LOADING";
export const RETRIEVE_TEMPLATELIST = "RETRIEVE_TEMPLATELIST";
export const DELETE_TEMPLATELIST = "DELETE_TEMPLATELIST";
//WAREHOUSE
export const WAREHOUSE_LOADING = "WAREHOUSE_LOADING";
export const RETRIEVE_WAREHOUSE = "RETRIEVE_WAREHOUSE";
export const DELETE_WAREHOUSE = "DELETE_WAREHOUSE";

//PACKAGEITEM
export const PACKAGEITEM_LOADING = "PACKAGEITEM_LOADING";
export const RETRIEVE_PACKAGEITEM = "RETRIEVE_PACKAGEITEM";
export const DELETE_PACKAGEITEM = "DELETE_PACKAGEITEM";
//PACKAGEIMAGE
export const PACKAGEIMAGE_LOADING = "PACKAGEIMAGE_LOADING";
export const RETRIEVE_PACKAGEIMAGE = "RETRIEVE_PACKAGEIMAGE";
export const DELETE_PACKAGEIMAGE = "DELETE_PACKAGEIMAGE";
//PACKAGETEMPLATE
export const PACKAGETEMPLATE_LOADING = "PACKAGETEMPLATE_LOADING";
export const RETRIEVE_PACKAGETEMPLATE = "RETRIEVE_PACKAGETEMPLATE";

//INSPECTION_QC
export const QADETAILREPORT_LOADING = "QADETAILREPORT_LOADING";
export const LOAD_QADETAILREPORT_LIST = "LOAD_QADETAILREPORT_LIST";
export const LOAD_QAFINALINSPECTION_LIST = "LOAD_QAFINALINSPECTION_LIST";
export const LOAD_QAREFINALINSPECTION_LIST = "LOAD_QAREFINALINSPECTION_LIST";
export const QA_UPDATE_COMMENT_REPORT_VIEW_ALL_CHECK_BOX = "QA_UPDATE_COMMENT_REPORT_VIEW_ALL_CHECK_BOX";
export const QA_UPDATE_COMMENT_REPORT_VIEW_CHECK_BOX = "QA_UPDATE_COMMENT_REPORT_VIEW_CHECK_BOX"
//CSR DETAILS LIST
export const CSRDETAILS_LOADING = "CSRDETAILS_LOADING";
export const RETRIEVE_CSRDETAILS = "RETRIEVE_CSRDETAILS";
export const DELETE_CSRDETAILS = "DELETE_CSRDETAILS";

//CSR DROPDOWN LIST
export const CSRDROPDOWNDETAILS_LOADING = "CSRDROPDOWNDETAILS_LOADING";
export const RETRIEVE_CSRDROPDOWNDETAILS = "RETRIEVE_CSRDROPDOWNDETAILS";
export const DELETE_CSRDROPDOWNDETAILS = "DELETE_CSRDROPDOWNDETAILS";

//CSR TEMPLATE LIST
export const CSRTEMPLATEDETAILS_LOADING = "CSRTEMPLATEDETAILS_LOADING";
export const RETRIEVE_CSRTEMPLATEDETAILS = "RETRIEVE_CSRTEMPLATEDETAILS";
export const DELETE_CSRTEMPLATEDETAILS = "DELETE_CSRTEMPLATEDETAILS";

//AQLMASTER
export const AQLMASTER_LOADING = "AQLMASTER_LOADING";
export const RETRIEVE_AQLMASTER = "RETRIEVE_AQLMASTER";
export const DELETE_AQLMASTER = "DELETE_AQLMASTER";

//AQLFINALINSPECTION
export const AQLFINALINSPECTION_LOADING = "AQLFINALINSPECTION_LOADING";
export const RETRIEVE_AQLFINALINSPECTION = "RETRIEVE_AQLFINALINSPECTION";
export const DELETE_AQLFINALINSPECTION = "DELETE_AQLFINALINSPECTION";
//FINAL INSPECTION
export const TNA_ADD_FINAL_INSPECTION = "TNA_ADD_FINAL_INSPECTION";
//FOR TESTING
export const TNA_ADD_TESTING_TNA = "TNA_ADD_TESTING_TNA";
//Testing Report LIST
export const TESTITEMS_LOADING = "TESTITEMS_LOADING";
export const RETRIEVE_TESTITEMS = "RETRIEVE_TESTITEMS";
export const DELETE_TESTITEMS = "DELETE_TESTITEMS";

//Testing Report Template LIST
export const TESTTEMP_LOADING = "TESTTEMP_LOADING";
export const RETRIEVE_TESTTEMP = "RETRIEVE_TESTTEMP";
export const DELETE_TESTTEMP = "DELETE_TESTTEMP";



//BuyerCustomerWarehouse LIST
export const BUYCUSWAR_LOADING = "BUYCUSWAR_LOADING";
export const RETRIEVE_BUYCUSWAR = "RETRIEVE_BUYCUSWAR";
export const DELETE_BUYCUSWAR = "DELETE_BUYCUSWAR";

// FLDETAILSREPORT
export const FLDETAILREPORT_LOADING = "FLDETAILREPORT_LOADING";
export const LOAD_FLDETAILREPORT_LIST = "LOAD_FLDETAILREPORT_LIST";
export const LOAD_FINALINSPECTION_LIST = "LOAD_FINALINSPECTION_LIST";
export const LOAD_REFINALINSPECTION_LIST = "LOAD_REFINALINSPECTION_LIST";
export const FL_UPDATE_COMMENT_REPORT_VIEW_ALL_CHECK_BOX = "FL_UPDATE_COMMENT_REPORT_VIEW_ALL_CHECK_BOX";
export const FL_UPDATE_COMMENT_REPORT_VIEW_CHECK_BOX = "FL_UPDATE_COMMENT_REPORT_VIEW_CHECK_BOX";
//Order PRotoit Sms

export const ORDERPROTOFITSMS_LOADING = "ORDERPROTOFITSMS_LOADING";
export const RETRIEVE_ORDERPROTOFITSMS = "RETRIEVE_ORDERPROTOFITSMS";
export const DELETE_ORDERPROTOFITSMS = "DELETE_ORDERPROTOFITSMS";
export const RETRIEVE_ORDER_SAMPLES_DETAIL_VIEW_LIST = "RETRIEVE_ORDER_SAMPLES_DETAIL_VIEW_LIST";
export const CLEAR_ORDER_SAMPLES_DETAIL_VIEW_SUB_ROW = "CLEAR_ORDER_SAMPLES_DETAIL_VIEW_SUB_ROW";
export const RETRIEVE_ORDER_SAMPLES_REQUEST_VIEW_LIST = "RETRIEVE_ORDER_SAMPLES_REQUEST_VIEW_LIST";
export const RETRIEVE_ORDER_SAMPLES_SUBMISSION_VIEW_LIST = "RETRIEVE_ORDER_SAMPLES_SUBMISSION_VIEW_LIST";
export const RETRIEVE_ORDER_SAMPLES_APPROVAL_VIEW_LIST = "RETRIEVE_ORDER_SAMPLES_APPROVA_VIEW_LIST";

export const LOAD_FABRIC_TESTING_DETAILS = "LOAD_FABRIC_TESTING_DETAILS";
export const TEST_UPDATE_COMMENT_REPORT_VIEW_ALL_CHECK_BOX = "TEST_UPDATE_COMMENT_REPORT_VIEW_ALL_CHECK_BOX";
export const TEST_UPDATE_COMMENT_REPORT_VIEW_CHECK_BOX = "TEST_UPDATE_COMMENT_REPORT_VIEW_CHECK_BOX";


export const LOAD_TEST_ENTRY_DETAILS = "LOAD_TEST_ENTRY_DETAILS";


export const REPORTMODULECATEGORY_LOADING = "REPORTMODULECATEGORY_LOADING";
export const CREATE_REPORTMODULECATEGORY = "CREATE_REPORTMODULECATEGORY";
export const RETRIEVE_REPORTMODULECATEGORY = "RETRIEVE_REPORTMODULECATEGORY";
export const DELETE_REPORTMODULECATEGORY = "DELETE_REPORTMODULECATEGORY";


export const REPORTTEMPLATE_LOADING = "REPORTTEMPLATE_LOADING";
export const CREATE_REPORTTEMPLATE = "CREATE_REPORTTEMPLATE";
export const RETRIEVE_REPORTTEMPLATE = "RETRIEVE_REPORTTEMPLATE";
export const DELETE_REPORTTEMPLATE = "DELETE_REPORTTEMPLATE";
export const RETRIEVE_REPORTMODULECATEGORYDRPDOWNLIST = "RETRIEVE_REPORTMODULECATEGORYDRPDOWNLIST";


export const COLUMNINFORMATION_LOADING = "COLUMNINFORMATION_LOADING";
export const CREATE_COLUMNINFORMATION = "CREATE_COLUMNINFORMATION";
export const RETRIEVE_COLUMNINFORMATION = "RETRIEVE_COLUMNINFORMATION";
export const DELETE_COLUMNINFORMATION = "DELETE_COLUMNINFORMATION";
//export const RETRIEVE_REPORTMODULECATEGORYDRPDOWNLIST = "RETRIEVE_REPORTMODULECATEGORYDRPDOWNLIST";


// Schedule Report
export const SCHEDULEREPORT_LOADING = "SCHEDULEREPORT_LOADING";
export const RETRIEVE_SCHEDULEREPORT = 'RETRIEVE_SCHEDULEREPORT';