import React, { useState, useEffect } from "react";
import Nodify from "../../Common/ReactNotification";
import SearchRetain from "../../Common/SearchRetain";
import ReactNotification from 'react-notifications-component';
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import CurrencyService from "../../../services/Master/CurrencyService";
import { useSelector } from "react-redux";

const EditCurrency = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);

    const [getCurrencyInfo, setCurrencyInfo] = useState({});
    const [buttonLoader, setButtonLoader] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    function BindCurrencyValue(props) {
        let CurrencyInfoValue = { Operation: 2, CurrencyID: 0, CurrencyCode: '', CurrencyName: '', CurrencySymbol: '', Createdby: currentUser.employeeinformationID, };
        if (props.location.state !== undefined) {
            if (props.location.state.params.length !== 0) {
                if (props.location.state.params.currencyId !== 0) {
                    CurrencyInfoValue.Operation = 2;
                    CurrencyInfoValue.CurrencyID = props.location.state.params.currencyId;
                    CurrencyInfoValue.CurrencyName = props.location.state.params.currencyName;
                    CurrencyInfoValue.CurrencySymbol = props.location.state.params.currencySymbol;
                    CurrencyInfoValue.Createdby = currentUser.employeeinformationID;
                    return CurrencyInfoValue;
                }
            }
        }
        else {
            return CurrencyInfoValue;
        }
    }

    useEffect(() => {
        let Currencyinfo = BindCurrencyValue(props);
        setCurrencyInfo(Currencyinfo);
    }, []);


    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/CurrencyList",
            state: {
                message: Func,
            },
        }
        );
    };

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/CurrencyList')
        } else {
            window.location.reload();
        }
    }

    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
    };

    const handleChange = (e) => {
        let Value = getCurrencyInfo;
        if (e.target.name === "CurrencyName") {
            if (e.target.value.trim() !== '') {
                setSubmitted(true);
            }
            else {
                setSubmitted(false);
            }
            Value.CurrencyName = e.target.value;
        }
        if (e.target.name === "CurrencySymbol") {
            if (e.target.value.trim() !== '') {
                setSubmitted(true);
            }
            else {
                setSubmitted(false);
            }
            Value.CurrencySymbol = e.target.value;
        }
        setCurrencyInfo(Value);
    }

    const SaveCurrency = (e) => {
        setButtonLoader(true);
        e.preventDefault();

        if (getCurrencyInfo.CurrencyName.trim() === '' && getCurrencyInfo.CurrencySymbol.trim() === '') {
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Please fill the mandatory (*) fields.');
            return false;
        }
        else if (getCurrencyInfo.CurrencyName.trim() === '') {
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Currency Name is required');
            return false;
        }
        else if (getCurrencyInfo.CurrencySymbol.trim() === '') {
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Currency Symbol is required');
            return false;
        }
        if (getCurrencyInfo.CurrencyName.trim() && getCurrencyInfo.CurrencySymbol.trim()) {
            getCurrencyInfo.CurrencyName = getCurrencyInfo.CurrencyName.trim();
            getCurrencyInfo.CurrencySymbol = getCurrencyInfo.CurrencySymbol.trim();
            CurrencyService.InsertUpdateCurrency(getCurrencyInfo).then(res => {
                var page = "Remove";
                SearchRetain(page);
                let Func = 'Add';
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "-2") {
                    getCurrencyInfo.CurrencyName = '';
                    setCurrencyInfo(getCurrencyInfo);
                    Nodify('Warning!', 'warning', 'This Currency Name is already exist.');
                }
                setButtonLoader(false);
            });
        }
        else {
            setSubmitted(true);
        }
    }

    return (
        <div className="page-body">
            <ReactNotification />

            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">
                                Edit Currency
                            </h3>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <Form>
                                    <div className="row">

                                        <div className="form-group col-sm-4">
                                            <label htmlFor="CurrencyName">
                                                Currency Name<span className="text-danger">*</span>
                                            </label>
                                            <span className="input-icon icon-right">
                                                <Input
                                                    placeholder={"Enter Currency Name"}
                                                    id="CurrencyName"
                                                    name="CurrencyName"
                                                    value={getCurrencyInfo.CurrencyName}
                                                    type="text"
                                                    maxLength="50"
                                                    autoComplete="off"
                                                    onChange={handleChange}
                                                    className='form-control'
                                                    style={{ border: submitted && !getCurrencyInfo.CurrencyName ? '1px solid red' : '' }}
                                                    autoFocus
                                                />
                                            </span>

                                        </div>
                                        <div className="form-group col-sm-4">
                                            <label htmlFor="CurrencySymbol">
                                                Currency Symbol<span className="text-danger">*</span>
                                            </label>
                                            <span className="input-icon icon-right">
                                                <Input
                                                    placeholder={"Enter Currency Symbol"}
                                                    id="CurrencySymbol"
                                                    name="CurrencySymbol"
                                                    value={getCurrencyInfo.CurrencySymbol}
                                                    type="text"
                                                    maxLength="50"
                                                    autoComplete="off"
                                                    onChange={handleChange}
                                                    className='form-control'
                                                    style={{ border: submitted && !getCurrencyInfo.CurrencySymbol ? '1px solid red' : '' }}

                                                />
                                            </span>

                                        </div>
                                    </div>

                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div
                        className="buttons-preview"
                        align="right"
                        style={{ marginTop: "15px" }}
                    >
                        <div className="buttons-preview" align="right">
                            <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                            </span>
                            <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                <i className="fa fa-times"></i>&nbsp;Reset
                            </span>
                            <button
                                type="submit"
                                className="btn btn-success"
                                onClick={SaveCurrency}
                                disabled={buttonLoader}
                            >

                                <i className="fa fa-check right"></i>&nbsp;Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default EditCurrency;