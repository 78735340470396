import {
    REPORTTEMPLATE_LOADING,
    RETRIEVE_REPORTTEMPLATE,
    DELETE_REPORTTEMPLATE,
    RETRIEVE_REPORTMODULECATEGORY,
    RETRIEVE_REPORTMODULECATEGORYDRPDOWNLIST
  } from "./types";
import ReportTemplateService from "../services/Master/ReportTemplateService";

const reporttemplateLoading = (isStatus) => ({
    type: REPORTTEMPLATE_LOADING,
    payload: isStatus,
  });


  export const createReportTemplate = (getReport) => async (dispatch) => {
   
    let resData="";
     await ReportTemplateService.AddReportTemplate(getReport).then((res) => {
      resData=res.data;
                
    }).catch((err) => { return Promise.reject(err); });
    return Promise.resolve(resData);
    }
export const retrievereporttemplate = () => async (dispatch) => {
   ;
    try {
      
      dispatch(reporttemplateLoading(true));
    const  res = await ReportTemplateService.getReportTemplateList();
      dispatch({
      type: RETRIEVE_REPORTTEMPLATE,
      payload: res.data,
    });
    dispatch(reporttemplateLoading(false));
  } catch (err) {
    console.log(err);
    dispatch(reporttemplateLoading(false));
  }
  };

  export const deleteReporttemplate = (reportTemplateID) => async (dispatch) => {
   
    try {
      dispatch({
      type: DELETE_REPORTTEMPLATE,
      payload:reportTemplateID,
    });
  
  } catch (err) {
    console.log(err);
  }
  };


  export const retrieveReportModulecategorydropdownList = () => async (dispatch) => {
   ;
    try {
        dispatch(reporttemplateLoading(true));
        const res = await ReportTemplateService.getReportModulecategorydropdownList();
        dispatch({
            type: RETRIEVE_REPORTMODULECATEGORYDRPDOWNLIST,
            payload: res.data,
        });
        dispatch(reporttemplateLoading(false));
    } catch (err) {
        console.error('Error fetching dropdown data:', err);
        dispatch(reporttemplateLoading(false));
        throw err; // Optional: Rethrow the error to handle it in the component
    }
};