import {
    GENERALTRIM_LOADING,
    RETRIEVE_GENERALTRIM,
    DELETE_GENERALTRIM,
    RETRIEVE_GENERAL_TRIM_DETAIL_VIEW_LIST,
    CLEAR_GENERAL_TRIM_DETAIL_VIEW_SUB_ROW,
    UPDATE_GENERAL_TRIM_DETAIL_VIEW_CHECK_BOX,
    NEW_TNA_GENERAL_TRIM_REFERENCE_LIST,
    REMOVE_TNA_GENERAL_TRIM_REFERENCE_LIST,
    UPDATE_GENERAL_TRIM_DETAIL_VIEW_ALL_CHECK_BOX,
    TNA_TASK_GENERAL_TRIM_SUBMISSION_AND_APPROVAL,
    RETRIEVE_GENERAL_TRIM_SUBMISSION_LIST,
    CLEAR_GENERAL_TRIM_SUBMISSION_SUB_ROW,
    RETRIEVE_GENERAL_TRIM_APPROVAL_LIST,
    CLEAR_GENERAL_TRIM_APPROVAL_SUB_ROW,
    RETRIEVE_GENERAL_TRIM_APPROVAL_VIEW_LIST,
    CLEAR_GENERAL_TRIM_APPROVAL_VIEW_SUB_ROW,
    UPDATE_GENERAL_TRIM_IS_APPROVAL_MANUAL_CLOSE_POPUP,
    REMOVE_GENERAL_TRIM_REQUEST_VIEW_SUB_ROW
} from "../actions/types";

const initialState = {
    isLoadingGeneralTrim: true,
    generalTrimList: [],
    generalTrimViewDetailParams: {}, //**generalTrimViewDetail**
    generalTrimViewDetailList: [],
    genTrimTNAReferenceList: [],
    genTrimTNATaskList: [],
    genTrimTNAAddtionalTaskList: [],
    generalTrimSubmissionList: [],
    generalTrimSubmissionParams: {},
    editTNATaskTrimSubmissionList: [],
    generalTrimApprovalList: [],
    generalTrimApprovalParams: {},
    generalTrimApprovalViewList: [],
    generalTrimApprovalViewParams: {},

};

const generalTrimReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GENERALTRIM_LOADING:
            return {
                ...state,
                isLoadingGeneralTrim: payload
            };
        case RETRIEVE_GENERALTRIM:
            return { ...state, generalTrimList: payload };
        case DELETE_GENERALTRIM:
            const results = state.generalTrimList.filter(c => c.genTrimId !== payload.genTrimId);
            return {
                ...state,
                generalTrimList: results
            };
        case RETRIEVE_GENERAL_TRIM_DETAIL_VIEW_LIST:

            if (payload.params.TrimId === 0) {

                return {
                    ...state,
                    generalTrimViewDetailList: payload.data.trimList,
                    generalTrimViewDetailParams: payload.params
                };
            } else {

                let index = payload.params.Index;
                let resultSet = payload.data;
                //GeneralTrimViewDetail-(GSOVD)
                let GSOVDTrimList = [...state.generalTrimViewDetailList];
                GSOVDTrimList[index].trimReferenceList = resultSet.trimReferenceList ? resultSet.trimReferenceList : [];
                GSOVDTrimList[index].manualCommentList = resultSet.manualCommentList ? resultSet.manualCommentList : [];
                GSOVDTrimList[index].manualImageList = resultSet.manualImageList ? resultSet.manualImageList : [];

                // GSOVDTrimList[index].criticalList = resultSet.criticalList ? resultSet.criticalList : [];

                return {
                    ...state,
                    generalTrimViewDetailList: GSOVDTrimList,
                    generalTrimViewDetailParams: payload.params
                };
            }
        case CLEAR_GENERAL_TRIM_DETAIL_VIEW_SUB_ROW:
            let index = payload;
            //GeneralTrimViewDetail-(GSOVD)
            let GSOVDTrimList = [...state.generalTrimViewDetailList];
            GSOVDTrimList[index].trimReferenceList = [];
            GSOVDTrimList[index].manualCommentList = [];
            GSOVDTrimList[index].manualImageList = [];
            // GSOVDRefernceList[index].criticalList = [];

            return {
                ...state,
                generalTrimViewDetailList: GSOVDTrimList
            };

        case UPDATE_GENERAL_TRIM_DETAIL_VIEW_ALL_CHECK_BOX:
            let isCheck = payload;
            let List = [...state.generalTrimViewDetailList];
            List.map((item, Index) => {
                if (item && item.trimReferenceList) {
                    let ReferenceList = item.trimReferenceList;
                    ReferenceList && ReferenceList.map((item2, SubIndex) => {
                        ReferenceList[SubIndex].isSelected = isCheck;
                    });
                    List[Index].trimReferenceList = ReferenceList;
                }

            });
            return {
                ...state,
                generalTrimViewDetailList: List
            };

        case UPDATE_GENERAL_TRIM_DETAIL_VIEW_CHECK_BOX:
            let Index = payload.index;
            let SubIndex = payload.subIndex;
            let isChecked = payload.isChecked;

            let TrimList = [...state.generalTrimViewDetailList];
            let trimReferenceList = TrimList[Index].trimReferenceList;
            trimReferenceList[SubIndex].isSelected = isChecked;
            TrimList[Index].trimReferenceList = trimReferenceList;

            return {
                ...state,
                generalTrimViewDetailList: TrimList
            };

        case NEW_TNA_GENERAL_TRIM_REFERENCE_LIST:
            return {
                ...state,
                genTrimTNAReferenceList: payload.item1,
                genTrimTNATaskList: payload.item2,
                genTrimTNAAddtionalTaskList: payload.item3,
            };

        case REMOVE_TNA_GENERAL_TRIM_REFERENCE_LIST:
            let TrimTNAReferenceList = [...state.genTrimTNAReferenceList];
            TrimTNAReferenceList.splice(payload, 1);
            return {
                ...state,
                genTrimTNAReferenceList: TrimTNAReferenceList
            };
        case RETRIEVE_GENERAL_TRIM_SUBMISSION_LIST:
            if (payload.params.TrimId === 0) {

                return {
                    ...state,
                    generalTrimSubmissionList: payload.data.trimList,
                    generalTrimSubmissionParams: payload.params
                };
            } else {

                let index = payload.params.Index;
                let resultSet = payload.data;
                //GeneralTrimViewDetail-(GSOVD)
                let GSOVDTrimList = [...state.generalTrimSubmissionList];

                GSOVDTrimList[index].trimSubmitApprovalReferenceList = resultSet.trimSubmitApprovalReferenceList ? resultSet.trimSubmitApprovalReferenceList : [];
                // GSOVDTrimList[index].criticalList = resultSet.criticalList ? resultSet.criticalList : [];

                return {
                    ...state,
                    generalTrimSubmissionList: GSOVDTrimList,
                    generalTrimSubmissionParams: payload.params
                };
            }

        case CLEAR_GENERAL_TRIM_SUBMISSION_SUB_ROW:

            let indexVal = payload;
            //GeneralTrimViewDetail-(GSOVD)
            let GSOVD_TrimList = [...state.generalTrimSubmissionList];
            GSOVD_TrimList[indexVal].trimSubmitApprovalReferenceList = [];
            // GSOVDRefernceList[index].criticalList = [];

            return {
                ...state,
                generalTrimSubmissionList: GSOVD_TrimList
            };

        case TNA_TASK_GENERAL_TRIM_SUBMISSION_AND_APPROVAL:
            return {
                ...state,
                editTNATaskTrimSubmissionList: payload
            };
        case RETRIEVE_GENERAL_TRIM_APPROVAL_LIST:
            if (payload.params.TrimId === 0) {
                return {
                    ...state,
                    generalTrimApprovalList: payload.data.trimList,
                    generalTrimApprovalParams: payload.params
                };
            } else {

                let index = payload.params.Index;
                let resultSet = payload.data;

                let GSOVDTrimList = [...state.generalTrimApprovalList];

                GSOVDTrimList[index].trimSubmitApprovalReferenceList = resultSet.trimSubmitApprovalReferenceList ? resultSet.trimSubmitApprovalReferenceList : [];
                // GSOVDTrimList[index].criticalList = resultSet.criticalList ? resultSet.criticalList : [];

                return {
                    ...state,
                    generalTrimApprovalList: GSOVDTrimList,
                    generalTrimApprovalParams: payload.params
                };
            }

        case CLEAR_GENERAL_TRIM_APPROVAL_SUB_ROW:
            let ApprIndex = payload;
            let GSOA_TrimList = [...state.generalTrimApprovalList];
            GSOA_TrimList[ApprIndex].trimSubmitApprovalReferenceList = [];
            // GSOVDRefernceList[index].criticalList = [];
            return {
                ...state,
                generalTrimApprovalList: GSOA_TrimList
            };
        case RETRIEVE_GENERAL_TRIM_APPROVAL_VIEW_LIST:
            if (payload.params.TrimId === 0) {
                return {
                    ...state,
                    generalTrimApprovalViewList: payload.data.trimList,
                    generalTrimApprovalViewParams: payload.params
                };
            } else {

                let index = payload.params.Index;
                let resultSet = payload.data;

                let GSOVDTrimList = [...state.generalTrimApprovalViewList];

                GSOVDTrimList[index].trimSubmitApprovalReferenceList = resultSet.trimSubmitApprovalReferenceList ? resultSet.trimSubmitApprovalReferenceList : [];
                // GSOVDTrimList[index].criticalList = resultSet.criticalList ? resultSet.criticalList : [];

                return {
                    ...state,
                    generalTrimApprovalViewList: GSOVDTrimList,
                    generalTrimApprovalViewParams: payload.params
                };
            }
        case CLEAR_GENERAL_TRIM_APPROVAL_VIEW_SUB_ROW:

            let ViewIndex = payload;
            let GSOVA_TrimList = [...state.generalTrimApprovalViewList];
            GSOVA_TrimList[ViewIndex].trimSubmitApprovalReferenceList = [];
            // GSOVDRefernceList[index].criticalList = [];
            return {
                ...state,
                generalTrimApprovalViewList: GSOVA_TrimList
            };
        case UPDATE_GENERAL_TRIM_IS_APPROVAL_MANUAL_CLOSE_POPUP:
           ;
            let indexRow = payload.index;
            let subIndexRow = payload.subIndex;
            let isOpen = payload.isOpen;

            let TrimApprovalList = [...state.generalTrimApprovalList];
            let trimApprovalReferenceList = TrimApprovalList[indexRow].trimSubmitApprovalReferenceList;
            trimApprovalReferenceList[subIndexRow].isApprovalManualClosePopup = isOpen;
            TrimApprovalList[indexRow].trimSubmitApprovalReferenceList = trimApprovalReferenceList;

            return {
                ...state,
                generalTrimApprovalList: TrimApprovalList
            };
        case REMOVE_GENERAL_TRIM_REQUEST_VIEW_SUB_ROW:
            let CurrIndex = payload.Index;
            let parentTrimList = [...state.generalTrimSubmissionList];
            let TrimSubmitReference = parentTrimList[CurrIndex].trimSubmitApprovalReferenceList;
            TrimSubmitReference = TrimSubmitReference.filter(d => d.genTrimDetailTNAId !== payload.GenTrimDetailTNAId)
            if (TrimSubmitReference && TrimSubmitReference.length > 0) {
                parentTrimList[CurrIndex].trimSubmitApprovalReferenceList = TrimSubmitReference;
            } else {
                parentTrimList.splice(CurrIndex, 1);
            }
            return {
                ...state,
                generalTrimSubmissionList: parentTrimList,
            };
        default:
            return state;
    }
};
export default generalTrimReducer;