import React,{useState} from "react";



const CommonError = (probs) => {

  
    const [getCompanyList, setCompanyList] = useState({});
  

 
 return (
<>

<div className="page-body">
< img height="100%"width="100%" src="assets/img/PageNotFound.gif"   alt=""
/>


</div>
</>
      );
};

export default CommonError;
