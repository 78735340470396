import {
  ORDERPROTOFITSMS_LOADING,
  RETRIEVE_ORDERPROTOFITSMS,
  DELETE_ORDERPROTOFITSMS,
  RETRIEVE_ORDER_SAMPLES_DETAIL_VIEW_LIST,
  RETRIEVE_ORDER_SAMPLES_REQUEST_VIEW_LIST,
  RETRIEVE_ORDER_SAMPLES_SUBMISSION_VIEW_LIST,
  RETRIEVE_ORDER_SAMPLES_APPROVAL_VIEW_LIST
} from "../actions/types";

const OLDStatusList = [
  { value: 1, label: 'To Do', isdisabled: false },
  { value: 2, label: 'Requested', isdisabled: false },
  { value: 3, label: 'Submitted', isdisabled: false },
  { value: 4, label: 'Approved', isdisabled: false }
];

const OLDRequestStatusList = [

  { value: 1, label: 'Pending', isdisabled: false },
  { value: 2, label: 'Completed', isdisabled: false },
];


const initialState = {
  isLoadingProtoFitSms: true,
  protoFitSmsList: [],
  ordSampleList: [],
  orderSampleViewDetailList: [],
  orderSampleViewDetailParams: [],
  ordSampleRequestList: [],
  ordSampleSubmissionList: [],
  ordSampleApprovalList: [],
  OLDStatusList: OLDStatusList,
};



const orderProtoFitSmsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ORDERPROTOFITSMS_LOADING:
      return {
        ...state,
        isLoadingProtoFitSms: payload
      };

    case DELETE_ORDERPROTOFITSMS:

      const results = state.protoFitSmsList.filter(c => c.itemID !== payload.itemID);
      return {
        ...state,
        protoFitSmsList: results
      };
    case RETRIEVE_ORDERPROTOFITSMS:
      return { ...state, protoFitSmsList: payload };

    case RETRIEVE_ORDER_SAMPLES_DETAIL_VIEW_LIST:
      return {
        ...state,
        ordSampleList: payload.data.ordSampleList,
        orderSampleViewDetailList: payload.data.usAssignedOrdSampleList,
        orderSampleViewDetailParams: payload.params
      };

    case RETRIEVE_ORDER_SAMPLES_REQUEST_VIEW_LIST:

      return {
        ...state,
        ordSampleRequestList: payload.data.ordSampleList,
        orderSampleViewDetailParams: payload.params
      };

    case RETRIEVE_ORDER_SAMPLES_SUBMISSION_VIEW_LIST:
      return {
        ...state,
        ordSampleSubmissionList: payload.data.ordSampleList,
        orderSampleViewDetailParams: payload.params
      };
    case RETRIEVE_ORDER_SAMPLES_APPROVAL_VIEW_LIST:
      return {
        ...state,
        ordSampleApprovalList: payload.data.ordSampleList,
        orderSampleViewDetailParams: payload.params
      };



    default:
      return state;
  }

}


export default orderProtoFitSmsReducer;