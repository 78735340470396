import React, { useState, useEffect, useRef, Fragment } from "react";
import Nodify from "../../Common/ReactNotification";
import validator from 'validator'
import { useSelector } from "react-redux";
import ReactNotification from 'react-notifications-component';
import { TableHeader, Pagination, Search, PageCount } from "../../Datatable";
import TemplateService from "../../../services/Master/TemplateService";
import ItemService from "../../../services/Master/ItemService";
import SelectCombobox from 'react-select';
import SearchRetain from "../../Common/SearchRetain";


const AddTemplate = (props) => {
  const [getTempName, setTempName] = useState([{ Id: 0, Name: "", }]);
  const [Checked, setChecked] = useState(0)
  const [Checkedlist, setCheckedList] = useState(true)
  const [GetAllItemDetails, setAllItemDetails] = useState([{ Value: 0, label: '' }]);
  const [ItemList, setItemList] = useState([]);
  const [ExistingList, setExistingList] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);

  const headers = [
    { name: <span>Item Name<span className="text-danger">*</span></span>, field: "itemName", sortable: false },
    { name: "Action", field: "", sortable: false, },
  ];
  let styles = {
    control: styles => ({ ...styles, border: '1px solid red' }),

  }
  //Enter Error msg
  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        //SaveBuyerInfo(event);
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [])
  useEffect(() => {
    let itemmasterDetails = [];

    ItemService.getAllItemList().then((res) => {
      if (res.data) {
        res.data.map(x => {
          itemmasterDetails.push({
            label: x.itemName,
            value: x.itemID
          })
        })

        setItemList(itemmasterDetails)
      }
    })
  }, [])

  useEffect(() => {
   
    let checked = true;
    TemplateService.GettemplateList(1, 0).then((response) => {

      if (response.data) {
        checked = !!!response.data.templateList1.find(x => x.availableInfoStatus === 1);
        if (!checked) {
          setCheckedList(true)
        } else {
          setCheckedList(false)
        }
        setExistingList(response.data);

      }
    });
  }, []);

  const PageRedirect = (Func) => {
    props.history.push({ pathname: "/Template", state: { message: Func } });
  }


  const CheckDuplicate = (index) => {

    const values = [...getTempName];
    if (ExistingList.templateList1) {
      for (var i = 0; i < ExistingList.templateList1.length; i++) {
        if (ExistingList.templateList1[i].templatename.trim().toLowerCase() === values[index].Name.trim().toLowerCase()) {
          values[index].Name = '';
          setTempName(values);
          Nodify('Warning!', 'warning', "This Template is already exist.");
          return false;
        }
      }
    }
  }
  const handleAddFields = (event, index) => {
    setSubmitted(false)
    const values = [...GetAllItemDetails];


    if (values[index].Value !== 0 && values[index].Value !== undefined) {
      // Create a new row
      const newRow = { id: Date.now() };

      // Update the rows array
      setAllItemDetails((prevRows) => {
        // Insert the new row at the specified index
        const updatedRows = [...prevRows.slice(0, index + 1), newRow, ...prevRows.slice(index + 1)];

        // Increment the indices of rows following the insertion point
        const updatedRowsWithIds = updatedRows.map((row, i) => ({ ...row, id: i }));

        return updatedRowsWithIds;
      });
      setSubmitted(false);
    }
    else {
      Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
      setSubmitted(true);
    }
  };

  const handleRemoveFields = index => {
    const values = [...GetAllItemDetails];
    values.splice(index, 1);
    setAllItemDetails(values);
  };

  const handleOnSelect = (e, Feildname, index) => {
   
    if (e.target.checked === true) {
      setChecked(1)
    } else {
      setChecked(0)
    }

  }

  const handleDropdownChange = (event, index, FieldName) => {

    const values = [...GetAllItemDetails];


    if (FieldName === "ItemMasterDetails") {
      if (event !== null) {
        values[index].Value = event.value;
        values[index].label = event.label;
      } else {
        values[index].Value = 0;
        values[index].label = ''
      }
    }

    if (values.length > 1)
      for (var j = 0; j < (GetAllItemDetails.length); j++) {
        if (j !== index) {
          if (values[j].Value === values[index].Value) {
            values[index].Value = 0;
            setAllItemDetails(values);
            Nodify('Warning!', 'warning', 'This Item Name is already exist.');
            setSubmitted(true);
            return false;
          }
        }
      }
    setAllItemDetails(values);

    // let restone = GetAllItemDetails.map(
    //   function (elem) {
    //     return elem.label
    //   }
    // );
    // setnewlabel(restone)

  };

  const handleInputChange = (index, event) => {
    const values = [...getTempName];
    let inputText = '';
    if (event.target.value.trim() !== '') {
      inputText = event.target.value;
    }
    values[index].Name = inputText;
    setTempName(values);
  };


  const ResetOperation = (e) => {
    if (e === "Back") {
      props.history.push('/Template');
    } else {
      window.location.reload();
      // let getGroupList = GroupListing(props);
      // SetState(getGroupList)
    }
  }



  const SaveWorkmanGroup = (e) => {
   ;
    setButtonLoader(true);
    e.preventDefault();
    let str = ""
    let isvalid = true
    str = e.target.value
    if (getTempName[0].Name === '') {
      setButtonLoader(false);
      setSubmitted(true);
      Nodify('Warning!', 'warning', "Please enter the Template Name.");
    }
    else if (GetAllItemDetails.length === 1 && GetAllItemDetails[0].Value === 0) {
      setButtonLoader(false);
      setSubmitted(true);
      isvalid = false;
      Nodify('Warning!', 'warning', "Please fill atleast one item  detail.");
    } else {
      for (var i = 0; i < (GetAllItemDetails.length); i++) {

        if (GetAllItemDetails[i].Value === 0 || GetAllItemDetails[i].Value === undefined) {
          Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
          setSubmitted(true);
          isvalid = false
          setButtonLoader(false);
          return false;
        }
      }

    }
    if (isvalid) {
      let Itemid1 = []
      GetAllItemDetails.map(x => {
        Itemid1.push({
          Templateid: 0,
          Itemid: x.Value,
          Isdeleted: 0
        })
      })
      let Inputmaster = { Operation: 1, TemplateInfoID: 0, Templateid: getTempName[0].Id, Templatename: getTempName[0].Name, AvailableInfoStatus: Checked, templateInformation: Itemid1, CreatedBy: currentUser.employeeinformationID }

      TemplateService.InsertUpdatetemplate(Inputmaster).then((res) => {
        var page = "Remove";
        SearchRetain(page);

        let Func = 'Add';
        if (res.data.outputResult === "1") {
          Func = 'Add';
          PageRedirect(Func);
        } else if (res.data.outputResult === "2") {
          Func = 'Edit';
          PageRedirect(Func);
        }
        else if (res.data.outputResult === "0") {
          setButtonLoader(false);
          Nodify('Warning!', 'warning', "Error Occured!");
        }
      })
    }
  }





  return (
    <div className="page-body">
      <ReactNotification />
      <div className="row">
        <div className="col-lg-12 col-sm-12 col-xs-12">
          <div className="widget flat radius-bordered">
            <div className="widget-header heading_box_style">
              <h3 className="heading_style_common">Add Available Info Template</h3>
            </div>

            <div className="widget-body">
              <div className="row">
                <div className="col-lg-11">
                  <div className="card">
                    <label className="alert alert-info comment-report-note"><b>Note : </b>If you need to check particular Disabled checkbox, Please uncheck the selected checkbox were selected towards the template list</label>
                  </div>
                </div>
                {
                  getTempName.map((temp, index) => (
                    <div className="col-lg-4 margin-top-10">
                      <label>Template Name<span className="text-danger">*</span></label>
                      <div className="form-group">
                        <span className="input-icon icon-right">
                          <input type="text"
                            className="form-control"
                            id={"Name_" + index}
                            name="Name"
                            value={temp.Name}
                            onChange={(event) => handleInputChange(index, event)}
                            placeholder="Enter Template Name"
                            maxLength="100"
                            onBlur={() => CheckDuplicate(index)}
                            style={{ border: submitted && !temp.Name ? '1px solid red' : '' }}
                          />
                        </span>

                      </div>
                    </div>
                  ))
                }
                <div className="col-lg-4 margin-top-10">
                  <label></label>
                  <div className="form-group">
                    <label className="Ajustment"><span className="text"> </span></label>&nbsp;&nbsp;&nbsp;
                    <label style={{ marginTop: '8px' }}>
                      <input type="checkbox" name="IsSelected"
                        onChange={event => (handleOnSelect(event, 'IsSelected'))}
                        disabled={Checkedlist === true ? true : false}
                        value={Checked === 1 ? true : false}
                        checked={Checked === 1 ? true : false}
                        className="clearOpacity textdesign" />
                      <span className="text">Default </span>
                    </label>
                  </div>
                </div>

              </div>
              <div id="registration-form">
                <form>
                  {
                    <table className="table table-striped table-bordered table-hover dataTable no-footer margin-top-10" id="editabledatatable" style={{ width: "50%" }}>
                      <TableHeader headers={headers} />
                      <tbody>
                        {

                          GetAllItemDetails.map((inputfeild, index) => (
                            <tr>
                              <td>
                                <SelectCombobox className="basic-single" name="ItemMasterDetails" id={"ItemMasterDetails" + index}
                                  isDisabled={false}
                                  isLoading={false}
                                  isSearchable={true}
                                  isClearable={true}
                                  autocomplete='off'
                                  placeholder="Select Item Name"
                                  styles={submitted && (inputfeild.Value === 0 || inputfeild.Value === undefined) ? styles : ''}
                                  onChange={event => handleDropdownChange(event, index, "ItemMasterDetails")}
                                  options={ItemList}
                                  value={ItemList.filter(function (option) {
                                    return option.value === inputfeild.Value;
                                  })}
                                />
                              </td>
                              <td style={{ width: "13%" }}>
                                {/* {
                                  GetAllItemDetails.length === (index + 1) && ( */}
                                <span title='Add Template'
                                  onClick={(event) => handleAddFields(event, index)}
                                  className="btn btn-info btn-xs plus">
                                  <i className="fa fa-plus"></i>
                                </span>
                                {/* )
                                } */}
                                &nbsp;
                                {
                                  GetAllItemDetails.length !== 1 && (
                                    <span title='Delete Template'
                                      onClick={() => handleRemoveFields(index)}
                                      className="btn btn-danger btn-xs delete">
                                      <i className="fa fa-trash-o"></i>
                                    </span>
                                  )
                                }
                              </td>
                            </tr>
                          ))

                        }

                      </tbody>
                    </table>
                  }

                  <div className="col-sm-12">
                    <div className="buttons-preview" align="right">
                      <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                        <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                      </span>
                      <span className="btn btn-danger" onClick={() => ResetOperation()}>
                        <i className="fa fa-times"></i>&nbsp;Reset
                      </span>
                      <button type="button" className="btn btn-success" disabled={buttonLoader}
                        onClick={SaveWorkmanGroup}
                      >
                        <i className="fa fa-check right"></i>&nbsp;Save
                      </button>
                    </div>
                  </div>

                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddTemplate