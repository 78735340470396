import React, { useState, useEffect, useMemo,Fragment } from "react";
import ReactNotification from 'react-notifications-component';
import { TableHeader, Pagination, Search, PageCount } from "../../../Datatable";
import SweetAlertPopup from '../../../Common/SweetAlertPopup';
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import FabricService from "../../../../services/Master/FabricService";
import Nodify from "../../../Common/ReactNotification"
import SearchRetain from "../../../Common/SearchRetain";
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { FabricId } from "../../../Common/PageActionNumber";
import { retrieveFabricType } from "../../../../actions/fabricType";
import CommonService from '../../../../services/Common/CommonService';
import moment from 'moment';

const FabricList = (props) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    const [getPlaceholder] = useState("Group Type / Fabric Name / Content / GSM");
    const [getID, setID] = useState({ showPopupDelete: false, Params: {} });
    const [search, setSearch] = useState("");
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [sorting, setSorting] = useState({ field: "", order: "" });

    const dispatch = useDispatch();

    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.common.MenuList.item3);
    if (menulist !== undefined) { activeMenu = menulist.filter(i => i.menuName === "FabricType"); }
    // End Enable Menu
    //reducer
    const reducerState = useSelector((state) => state);
    const { user: currentUser } = reducerState.auth;
    const comments = reducerState.fabricType.fabricTypeList;
    let isLoadingFabricType = reducerState.fabricType.isLoadingFabricType;


    useEffect(() => {
        let FabricId = 0;
        dispatch(retrieveFabricType(FabricId, "F"));
        const state1 = props.location.state
        if (state1 !== undefined && state1 !== null) {
            let Msg;
            let Type = 'success'; // default, info, warning
            let title = 'Success!';
            if (state1.message === 'Edit') {
                Msg = 'Fabric Detail updated successfully.';
            } else if (state1.message === 'Add') {
                Msg = 'Fabric Detail added successfully.';
            } else {
                Msg = 'Error occured';
                Type = 'warning';
                title = 'Error!';
            }
            window.history.replaceState(null, '')
            Nodify(title, Type, Msg);
        }
        var page = getPlaceholder;
        var setConstraint = SearchRetain(page);
        if (setConstraint !== undefined) {
            setCurrentPage(setConstraint.CurrentPage);
            setITEMS_PER_PAGE(setConstraint.PageItems);
        }

    }, [dispatch]);

    useEffect(() => {
        if (!isLoadingFabricType) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingFabricType]);

    const headers = [
        {
            name: "#",
            field: "",
            sortable: false
        },
        {
            name: "Group Type",
            field: "groupName",
            sortable: true
        }, {
            name: "Fabric Name",
            field: "fabricDetailName",
            sortable: true
        }, {
            name: "Content",
            field: "content",
            sortable: true
        },
        {
            name: "GSM",
            field: "gsMorCounts",
            sortable: true
        }, {
            name: "Created Date",
            field: "createddate",
            sortable: true
        }, {
            name: "Action",
            field: "",
            sortable: false,
            display: activeMenu && activeMenu[0].isEdit === 0 &&
                activeMenu[0].isDelete === 0 ? 'none' : ''
        },
    ];

    const commentsData = useMemo(() => {
        let computedComments = comments;
        if (search) {
            computedComments = computedComments.filter(comment =>
                comment.groupName.toLowerCase().includes(search.toLowerCase())
                || comment.fabricDetailName.toLowerCase().includes(search.toLowerCase())
                || comment.content.toLowerCase().includes(search.toLowerCase())
                || comment.gsMorCounts.toLowerCase().includes(search.toLowerCase()));

        }
        setTotalItems(computedComments.length);
        // Sorting comments
        // if (sorting.field) {
        //     const reversed = sorting.order === "asc" ? 1 : -1;
        //     computedComments = computedComments.sort((a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]));
        // }
        // Sorting comments
    //     if (sorting.field) {
    //         const reversed = sorting.order === "asc" ? 1 : -1;
    //         computedComments = computedComments.sort((a, b) => {
    //           const valueA = a[sorting.field];
    //           const valueB = b[sorting.field];
            
    //           // Check if values are numeric
    //           const isNumericA = !isNaN(valueA);
    //           const isNumericB = !isNaN(valueB);
            
    //           if (isNumericA && isNumericB) {
    //             // If both values are numeric, compare as numbers
    //             return reversed * (parseFloat(valueA) - parseFloat(valueB));
    //           } else {
    //             // If one or both values are non-numeric, compare as strings
    //             return reversed * valueA.toString().localeCompare(valueB.toString());
    //           }
    //         });
              
    //   }
    if (sorting.field) {
        const reversed = sorting.order === 'asc' ? 1 : -1;
        computedComments = computedComments.sort((a, b) => {
          const valueA = a[sorting.field];
          const valueB = b[sorting.field];
      
          // Check if both values are numeric
          const isNumericA = !isNaN(valueA);
          const isNumericB = !isNaN(valueB);
      
          // Check if both values are valid date-time strings using moment
          const isDateTimeA = moment(valueA, moment.ISO_8601, true).isValid();
          const isDateTimeB = moment(valueB, moment.ISO_8601, true).isValid();
      
          if (isNumericA && isNumericB) {
            // If both values are numeric, compare as numbers
            return reversed * (parseFloat(valueA) - parseFloat(valueB));
          } else if (isDateTimeA && isDateTimeB) {
            // If both values are valid date-time strings, compare as dates
            return reversed * (moment(valueA).valueOf() - moment(valueB).valueOf());
          } else {
            // If one or both values are non-numeric and non-valid date-time strings, compare as strings
            return reversed * valueA.toString().localeCompare(valueB.toString());
          }
        });
      }


        // Current Page slice
        let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
        if (records.length === 0 && currentPage != 1) {
            setCurrentPage(currentPage - 1);
        }
        return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
    }, [
        comments,
        currentPage,
        search,
        sorting,
        ITEMS_PER_PAGE
    ]);

    const pageRedirect = (dataParams, redirect, page) => {
        localStorage.setItem('Pagination', JSON.stringify({ CurrentPage: currentPage, Page: page, PageItems: ITEMS_PER_PAGE }));
        if (redirect === 'Add') {
            props.history.push('/AddFabric', { params: [] });
        } else if (redirect === 'Edit') {
            props.history.push(`/EditFabric`, { params: dataParams });
        } else {
            let Params = {
                Operation: 15,
                Id: dataParams.fabricDetailsID,
            };
            CommonService.CheckMappedItem(Params).then(res => {
                if (res.data.outputResult === "-2") {
                    let Title = "Warning!";
                    let message = "This Fabric Detail is already mapped.";
                    let type = "warning";

                    Nodify(Title, type, message);
                }
                else {
                    setID({ showPopupDelete: true, Params: dataParams });
                }
            });
        }
    };

    if (!currentUser) {
        return <Redirect to="/login" />;
    }

    const Delcallback = (value) => {
        setID({ showPopupDelete: false, Params: [] });
    }

    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className="row">
                    <div className="col-xs-12 col-md-12">
                        <div className="widget">
                            <div className="widget-header ">
                                <span className="widget-caption">Fabric Details List</span>
                            </div>

                            <div className="widget-body"
                                style={
                                    { paddingBottom: '4%' }
                                }>
                                <div id="simpledatatable_wrapper" className="dataTables_wrapper form-inline no-footer">
                                    <div className="table-toolbar pull-left">
                                        <Search onSearch={
                                            value => {
                                                setSearch(value);
                                                setCurrentPage(1);
                                            }
                                        }
                                            placeholder={getPlaceholder} props={props} />
                                    </div>
                                    <div className="table-toolbar pull-right" style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}>
                                        &nbsp;
                                        <span className="btn btn-primary" onClick={() => pageRedirect('', 'Add', getPlaceholder)} title="Add Fabric Type">
                                            <i className="fa fa-plus"></i>&nbsp;Add
                                        </span>
                                    </div>
                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                        <TableHeader headers={headers}
                                            onSorting={
                                                (field, order) => setSorting({ field, order })
                                            } />
                                        <tbody>
                                            {
                                                commentsData.map((comment,i) => (
                                                    <Fragment key={i}>
                                                    <tr key={
                                                        comment.fabricDetailsID
                                                    }>
                                                         <td style={{width:'25px'}}>  
                                                                { i + 1}
                                                            </td>
                                                        <td>{
                                                            comment.groupName
                                                        }</td>
                                                        <td>{comment.fabricDetailName}</td>

                                                        <td>{comment.content}</td>

                                                        <td>{comment.gsMorCounts === '' ? "-" : comment.gsMorCounts === null ? "-" : comment.gsMorCounts + '-GSM'}</td>

                                                        <td>{
                                                            comment.createddate
                                                        }</td>
                                                        <td style={{
                                                            display: activeMenu && activeMenu[0].isEdit === 0 &&
                                                                activeMenu[0].isDelete === 0 ? 'none' : ''
                                                        }}>
                                                            <span style={{ display: activeMenu && activeMenu[0].isEdit === 1 ? '' : 'none' }} title='Edit Fabric Detail' onClick={() => pageRedirect(comment, 'Edit', getPlaceholder)}
                                                                className="btn btn-info btn-xs edit">
                                                                <i className="fa fa-edit"></i>
                                                            </span>
                                                            &nbsp;
                                                            <span style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }} title='Delete Fabric Detail' onClick={() => pageRedirect(comment, '', getPlaceholder)}
                                                                className="btn btn-danger btn-xs delete">
                                                                <i className="fa fa-trash-o"></i>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    </Fragment>
                                                ))

                                            }
                                            {
                                                totalItems === 0 ?
                                                    <tr>
                                                        <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
                                                    </tr> : ''
                                            }

                                        </tbody>
                                    </table>
                                    <PageCount onPageCount={
                                        value => {
                                            setITEMS_PER_PAGE(value);
                                            setCurrentPage(1);
                                        }
                                    } />
                                    <Pagination total={totalItems}
                                        itemsPerPage={
                                            parseInt(ITEMS_PER_PAGE)
                                        }
                                        currentPage={currentPage}
                                        onPageChange={
                                            page => setCurrentPage(page)
                                        } />
                                </div>
                            </div>
                            {
                                getID.showPopupDelete ? <SweetAlertPopup data={
                                    getID.Params
                                }
                                    deleteCallback={Delcallback}
                                    showpopup={true}
                                    pageActionId={FabricId}
                                    Msg={"Fabric Detail deleted successfully."} /> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
            {loader}
        </>
    );
};
export default FabricList;