import {
    DASHBOARD_CHARTS,
    DASHBOARD_CHART_LOADING
} from "../actions/types";

const initialState = {
    ChartStyleCount: [],
    isLoadingDashboard: true
};

const dashboardreducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case DASHBOARD_CHART_LOADING:
            return {
                ...state,
                isLoadingDashboard: payload
            };
        case DASHBOARD_CHARTS:
            return {
                ...state,
                ChartStyleCount: payload.item1
            };
        default:
            return state;
    }
};
export default dashboardreducer;