import axios from "axios";
import authHeader from "../auth-header";

class SizeService {
    LoadSizeList() {
        return axios.get(window.$APIBaseURL + "Master/LoadSizeList", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateSizeGrid = async(Params) => {
        return await axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateSizeGrid',
            data:Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

     InsertUpdateSize = async(Params) => {
        return await axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateSize',
            data:Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
    
    GetSizeList(SizeID) {
        return axios.get(window.$APIBaseURL + "Master/GetSizeList?SizeID="+SizeID, {
            headers: {
                authorization: authHeader()
            }
        });
    }
}

export default new SizeService();
