import React, { useState, useEffect, useRef, Fragment } from "react";
import ReactNotification from 'react-notifications-component';
import CityService from "../../services/Master/CityService";
import Nodify from "../../components/Common/ReactNotification"
import SearchRetain from "../../components/Common/SearchRetain";
import SelectCombobox from 'react-select';
import Form from "react-validation/build/form";
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
const CityPopup = ({ cityCallback, getCityList, showpopup, stateList, countryList }) => {
   
    const EmptyInputFields =
    {
        CityID: 0,
        CountryID: 0,
        StateID: 0,
        CityName: '',
    }
    const { user: currentUser } = useSelector((state) => state.auth);
    const [inputFields, setInputFields] = useState([EmptyInputFields]);
    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);


    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),

    }

    const values = [...inputFields];
    const handleInputChange = (event, FieldName) => {
        //setSubmitted(false);
        let inputText = '';

        if (FieldName === "StateID") {
            if (event !== null) {
                inputText = event.value;
                const state_Info = stateList.filter(x => x.value === event.value);
                values[0].CountryID = state_Info[0].countryID;
            }
            else {
                inputText = 0;
                values[0].CountryID = 0;
            }
            values[0].StateID = parseInt(inputText);
        } else if (FieldName === "CityName") {
            if (event.target.value.trim() !== '') {
                inputText = event.target.value;
            }
            values[0].CityName = inputText;
        } else if (FieldName === "CountryID") {
            if (event !== null) {
                inputText = event.value;
            }
            else {
                inputText = 0;
            }
            values[0].CountryID = parseInt(inputText);
        }
        setInputFields(values);
    }
    const CheckDuplicate = (Field) => {
       
        if (getCityList) {
            if (values[0].CityName !== '' && values[0].StateID !== 0 && values[0].CountryID !== 0) { // for avoid empty validation
                for (var i = 0; i < getCityList.length; i++) {
                    if (getCityList[i].stateId === values[0].StateID &&
                        getCityList[i].countryId === values[0].CountryID &&
                        getCityList[i].name.trim().toLowerCase() === values[0].CityName.trim().toLowerCase()) {
                        values[0].StateID = 0;
                        values[0].CountryID = 0;
                        values[0].CityName = '';
                        setInputFields(values);
                        Nodify('Warning!', 'warning', "This city is already exist.");
                        return false;
                    }
                }
            }
        }
    }

    const SaveCity = (e) => {
       ;
        setButtonLoader(true);
        e.preventDefault();
        let isvalid = true
        if (values.length === 1 && (values[0].CityName.trim() === '' || values[0].StateID === 0)) {
            setButtonLoader(false);
            setSubmitted(true);
            Nodify('Warning!', 'warning', "Please fill the Mandatory(*) fields.");
            isvalid = false
        }
        if (isvalid) {
            let cityListParam = {};
            cityListParam.CityInformation = values;
            cityListParam.Createdby = currentUser.employeeinformationID;
            CityService.InsertUpdateCityGrid(cityListParam).then(res => {
                var page = "Remove";
                SearchRetain(page);
                if (res.data.outputResult === "1") {
                    Nodify("Success!", "success", "Branch added successfully.");
                    cityCallback(true);
                } else if (res.data.outputResult === "0") {
                    setButtonLoader(false);
                    Nodify('Warning!', 'warning', "Error Occured!");
                }
            });
        }
    }
    return (
        <div id="registration-form">
            <Form>
                <div className="row">
                    <div className="form-group col-sm-12">
                        <div className='col-sm-4'>
                            <label htmlFor={
                                "CityName_"
                            }>City Name<span className="text-danger">*</span>
                            </label>
                            <input type="text"
                                className="form-control"
                                id={"CityName_"}
                                name="CityName"
                                placeholder="Enter City Name"
                                maxLength="50"
                                value={inputFields[0].CityName}
                                onChange={event => handleInputChange(event, "CityName")}
                                onBlur={() => CheckDuplicate('CityName')}
                                style={{ border: submitted && !inputFields[0].CityName ? '1px solid red' : '' }}
                                autoFocus
                            />
                        </div>
                        <div className='col-sm-4'>
                            <label htmlFor={
                                "StateID_"
                            }>State<span className="text-danger">*</span>
                            </label>
                            <SelectCombobox className="basic-single" name="StateID" id={"StateID_"}

                                isDisabled={false}
                                isLoading={false}
                                isSearchable={true}
                                isClearable={true}
                                styles={submitted && inputFields[0].StateID === 0 ? styles : ''
                                }
                                onChange={event => handleInputChange(event, "StateID")}
                                onBlur={() => CheckDuplicate('StateID')}
                                value={stateList.filter(function (option) {
                                    return option.value === inputFields[0].StateID;
                                })}

                                options={stateList}
                            />
                        </div>
                        <div className='col-sm-4'>
                            <label htmlFor={
                                "CountryID_"
                            }>Country<span className="text-danger">*</span>
                            </label>
                            <SelectCombobox className="basic-single" name="CountryID" id={"CountryID_"}

                                isDisabled={true}
                                isLoading={false}
                                isSearchable={true}
                                isClearable={true}
                                //styles={submitted && inputFields[0].CountryID === 0 ? styles : ''}
                                onChange={event => handleInputChange(event, "CountryID")}
                                onBlur={() => CheckDuplicate('CountryID')}
                                value={countryList.filter(function (option) {
                                    return option.value === inputFields[0].CountryID;
                                })}

                                options={countryList}
                            />
                        </div>
                    </div>
                </div>
                <div className="text-right">
                    <Button type="submit" className="btn btn-success"
                        onClick={SaveCity}
                        disabled={buttonLoader}>
                        <i className="fa fa-check"></i> &nbsp; Save
                    </Button>

                </div>
            </Form>
        </div>
    )
};
export default CityPopup;