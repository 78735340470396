import React, { useState, useEffect } from "react";
import Nodify from "../../../Common/ReactNotification"
import SearchRetain from "../../../Common/SearchRetain";
import ReactNotification from 'react-notifications-component';
import Form from "react-validation/build/form";
import SwatchMasterService from "../../../../services/Master/SwatchMasterService";
import { useSelector } from "react-redux";
import BuyerService from "../../../../services/Master/BuyerService";
import StyleService from "../../../../services/Style/styleService";
import axios from "axios";
import $ from "jquery";
import Lightbox from "react-image-lightbox"
import Input from "react-validation/build/input";
import Reactselect from 'react-select';
import Select from "react-validation/build/select";


const EditSwatchMaster = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const [getSwatchMasterInfo, setSwatchMasterInfo] = useState([]);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [getBuyerList, setBuyerList] = useState([]);
    const [getBrandList, setBrandList] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [getImagename, setImagename] = useState('');
    const [getBuyerid, setBuyerid] = useState(0);
    var ImgClassName = "upload-btn-wrapper2"


    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.common.MenuList.item3);
    if (menulist !== undefined) { activeMenu = menulist.filter(i => i.menuName === "Swatch"); }
    // End Enable Menu









    const BindSwatchValue = (props) => {
        let SwatchInfoValue = { Operation: 2, BuyerId: 0, BrandId: 0, SwatchName: '', SwatchImageFileName: '', SwatchImageFilePath: '', Createdby: currentUser.employeeinformationID, };
        if (props.location.state !== undefined) {
            if (props.location.state.params.length !== 0) {
                if (props.location.state.params.currencyId !== 0) {
                    SwatchInfoValue.Operation = 2;
                    SwatchInfoValue.SwatchMasterID = props.location.state.params.swatchMasterID;
                    SwatchInfoValue.BuyerId = props.location.state.params.buyerID;
                    SwatchInfoValue.BrandId = props.location.state.params.brandID;
                    SwatchInfoValue.SwatchName = props.location.state.params.swatchName;
                    SwatchInfoValue.SwatchImageFileName = props.location.state.params.swatchImageFileName;
                    SwatchInfoValue.SwatchImageFilePath = props.location.state.params.swatchImageFilePath;
                    SwatchInfoValue.Createdby = currentUser.employeeinformationID;
                    setBuyerid(props.location.state.params.buyerID);
                    if (props.location.state.params.swatchImageFileName !== '' && props.location.state.params.swatchImageFileName !== null) {
                        setImagename(props.location.state.params.swatchImageFilePath);
                    }
                    else {
                        setImagename('');
                    }
                    return SwatchInfoValue;
                }
            }
        }
        else {
            return SwatchInfoValue;
        }
    }

    useEffect(() => {
        BuyerService.LoadBuyerList(2).then((response) => {
            if (response.data) {
                setBuyerList(response.data);
            }
        }).catch(() => { });

        StyleService.LoadBrandThemeStoryEndCustomer(6, 0).then((response) => {
            if (response.data) {
                let BuyerInfoList = response.data;
                let respected_Brand = BuyerInfoList.filter(x => x.buyerMapID === 1);
                setBrandList(respected_Brand);
            }
        }).catch(() => { });


        setSwatchMasterInfo(BindSwatchValue(props));

    }, []);


    //Enter Error msg
    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                //SaveBuyerInfo(event);
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/SwatchMasterList",
            state: {
                message: Func,
            },
        }
        );
    };

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/SwatchMasterList')
        } else {
            window.location.reload();
        }
    }

    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
    };

    const handleChange = (event, Field) => {
        const values = getSwatchMasterInfo;
        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }
        if (event.target.name === "BuyerID") {
            values.BuyerId = parseInt(inputText);
            setBuyerid(values.BuyerId);

        } else if (event.target.name === "BrandID") {
            values.BrandId = parseInt(inputText);
        } else if (event.target.name === "SwatchName") {
            values.SwatchName = inputText;
        }

        setSwatchMasterInfo(values);
    }

    const UpdateSwatch = (e) => {

        setButtonLoader(true);
        e.preventDefault();

        if (getSwatchMasterInfo.BuyerId === 0 && getSwatchMasterInfo.BrandId === 0
            && getSwatchMasterInfo.SwatchName === '' && getSwatchMasterInfo.SwatchImageFileName === '') {
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Please fill the mandatory (*) fields.');
            setSubmitted(true);
            return false;
        }
        else if (getSwatchMasterInfo.BuyerId === 0) {
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Buyer is required');
            setSubmitted(true);
            return false;
        }
        else if (getSwatchMasterInfo.BrandId === 0) {
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Brand is required');
            setSubmitted(true);
            return false;
        }
        else if (getSwatchMasterInfo.SwatchName.trim() === '') {
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Swatch Name is required');
            setSubmitted(true);
            return false;
        }
        else if (getSwatchMasterInfo.SwatchImageFileName.trim() === '') {
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Swatch Image is required');
            setSubmitted(true);
            return false;
        }
        SwatchMasterService.InsertUpdateSwatchMaster(getSwatchMasterInfo).then(res => {
            var page = "Remove";
            SearchRetain(page);
            let Func = 'Add';
            if (res.data.outputResult === "1") {
                Func = 'Add';
                PageRedirect(Func);
            } else if (res.data.outputResult === "2") {
                Func = 'Edit';
                PageRedirect(Func);
            } else if (res.data.outputResult === "-2") {
                getSwatchMasterInfo.SwatchName = '';
                setSwatchMasterInfo(getSwatchMasterInfo);
                Nodify('Warning!', 'warning', 'This Swatch Name Combination is already exist.');
            }
            setButtonLoader(false);
        });

    }

    const openFileUpload = () => {
        $('#upload-button').trigger('click');
    }

    const UploadImages = async (e) => {
       ;
        const file = e.target.files[0];
        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png'];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#FileUpload').val("");
            }
            else {
                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "Swatch");
                try {
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                    getSwatchMasterInfo.SwatchImageFileName = file.name;
                    getSwatchMasterInfo.SwatchImageFilePath = "Images/SwatchImages/" + res.data + '_'  + file.name;
                    setSwatchMasterInfo(getSwatchMasterInfo);
                    $('#FileUpload').val("");
                    setImagename("Images/SwatchImages/" + res.data + '_'  + file.name);

                } catch (ex) {
                    console.log(ex);
                }
            }
        }
    }

    const ViewFile = (filename) => { // event.preventDefault();
        setIsOpen(true);
        // setImagename(filename);
    }

    return (
        <div className="page-body">
            <ReactNotification />

            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">
                                Edit Swatch
                            </h3>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <Form>
                                    <div className="row">

                                        <div className="col-sm-3 divder_style">
                                            <div className="form-group">
                                                <span className="input-icon icon-right">
                                                    <label htmlFor={"BuyerID"}>Buyer<span className="text-danger">*</span></label>
                                                    <Select id={"BuyerID"} name="BuyerID" className="form-select"
                                                        value={getSwatchMasterInfo.BuyerId} onChange={event => handleChange(event)}
                                                        style={{ border: submitted && getSwatchMasterInfo.BuyerId === 0 ? '1px solid red' : '' }}
                                                    >
                                                        <option value='0'>- Select Buyer -</option>
                                                        {
                                                            getBuyerList.map(item => (
                                                                <option key={item.id} value={item.id}>
                                                                    {item.name}
                                                                </option>
                                                            ))
                                                        }
                                                    </Select>
                                                    {/* <Reactselect className="basic-single" name="BuyerID"
                                                        id={"BuyerID"} isDisabled={false} isLoading={false}
                                                        isClearable={true} isSearchable={true}
                                                        onChange={(event) => handleChange(event, 'BuyerID')}
                                                        value={getBuyerList.filter(function (option) {
                                                            return option.id === getSwatchMasterInfo.BuyerId;
                                                        })}
                                                        options={getBuyerList} autoFocus
                                                        styles={submitted && getSwatchMasterInfo.BuyerID === '' ? '1px solid red' : ''}
                                                        placeholder="- Select Buyer -"
                                                    ></Reactselect> */}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="col-sm-3 divder_style">
                                            <div className="form-group">
                                                <span className="input-icon icon-right">
                                                    <label htmlFor={"BrandID"}>Brand<span className="text-danger">*</span></label>
                                                    <Select id={"BrandID"} name="BrandID"
                                                        className="form-select"
                                                        value={getSwatchMasterInfo.BrandId}
                                                        onChange={event => handleChange(event)}
                                                        style={{ border: submitted && getSwatchMasterInfo.BrandId === 0 ? '1px solid red' : '' }}
                                                    >
                                                        <option value='0'>- Select Brand -</option>
                                                        {
                                                            getBrandList.filter(x => x.buyerID === parseInt(getBuyerid)).map(items => (
                                                                <>
                                                                    <option key={items.id} value={items.id}>
                                                                        {items.name}
                                                                    </option>
                                                                </>
                                                            ))
                                                        }
                                                    </Select>
                                                    {/* <Reactselect className="basic-single" name="BrandID"
                                                        id={"BrandID"} isDisabled={false} isLoading={false}
                                                        isClearable={true} isSearchable={true}
                                                        onChange={(event) => handleChange(event, 'BrandID')}
                                                        value={getBrandList.filter(function (option) {
                                                            return option.id === getSwatchMasterInfo.BrandId;
                                                        })}
                                                        options={getBrandList.filter(x => x.buyerID === parseInt(getSwatchMasterInfo.BuyerId)).map(items => (
                                                            <>
                                                                <option key={items.id} value={items.id}>
                                                                    {items.name}
                                                                </option>
                                                            </>
                                                        ))} autoFocus
                                                        styles={submitted && getSwatchMasterInfo.BrandId === '' ? '1px solid red' : ''}
                                                        placeholder="- Select Brand -"
                                                    ></Reactselect> */}
                                                </span>
                                            </div>
                                        </div>



                                        <div className="col-sm-2 divder_style">
                                            <div className="form-group">
                                                <label htmlFor={"SwatchName"}>Swatch Name<span className="text-danger">*</span></label>
                                                <Input placeholder={"Enter Swatch Name"}
                                                    id={"SwatchName"} name="SwatchName"
                                                    value={getSwatchMasterInfo.SwatchName}
                                                    type="text" maxLength="75" autoComplete="off"
                                                    onChange={event => handleChange(event)}
                                                    className='form-control'
                                                    style={{ border: submitted && !getSwatchMasterInfo.SwatchName ? '1px solid red' : '' }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-sm-2 divder_style">
                                            <div className="form-group">
                                                <label htmlFor={"SwatchImageFilePath"}>Swatch Image<span className="text-danger">*</span></label>


                                                <div className={ImgClassName}
                                                    htmlFor="upload-button"
                                                >
                                                    <img src={getImagename === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + getImagename} onClick={openFileUpload}
                                                        alt="Picture" style={{ marginBottom: '5px', height: '100px', width: '50%', border: "1px solid #d5d5d5" }} />
                                                    <input type="file" id="upload-button" accept=".jpg,.jpeg,.png" onChange={(event) => UploadImages(event)} value='' />
                                                    {
                                                        getSwatchMasterInfo.SwatchImageFileName !== "assets/img/uploadimage.jpg" && (
                                                            <div style={
                                                                {
                                                                    marginTop: '8px',
                                                                    float: 'right',
                                                                    bottom: '10px',
                                                                    position: 'relative',
                                                                }
                                                            }>
                                                                {
                                                                    getImagename !== "" && getImagename !== "assets/img/uploadimage.jpg" ?
                                                                        <i className="btn btn-info fa fa-eye" title="View Logo"

                                                                            style={{ display: activeMenu && activeMenu[0].isView === 1 ? '' : 'none', marginLeft: "-16px", position: "absolute" }}
                                                                            onClick={
                                                                                event => ViewFile(event, getImagename)
                                                                            }></i>
                                                                        : ''

                                                                }
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        <div> {
                                                            isOpen && (

                                                                <Lightbox mainSrc={window.$APIBaseURL + getImagename}
                                                                    alt="bg image"
                                                                    onCloseRequest={
                                                                        () => setIsOpen(false)
                                                                    } />
                                                            )
                                                        } </div>
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div
                        className="buttons-preview"
                        align="right"
                        style={{ marginTop: "15px" }}
                    >
                        <div className="buttons-preview" align="right">
                            <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                            </span>
                            <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                <i className="fa fa-times"></i>&nbsp;Reset
                            </span>
                            <button
                                type="submit"
                                className="btn btn-success"
                                onClick={UpdateSwatch}
                                disabled={buttonLoader}
                            >

                                <i className="fa fa-check right"></i>&nbsp;Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default EditSwatchMaster;