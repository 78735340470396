import React, { useState, useEffect } from "react";
import SizeService from "../../../../services/Master/SizeService";
import Nodify from "../../../Common/ReactNotification";
import SearchRetain from "../../../Common/SearchRetain";
import ReactNotification from 'react-notifications-component';
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import Select from 'react-select';

const EditSize = (props) => {
    const [getSizeList, setSizeList] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [getSize, setSize] = useState({});
    const [buttonLoader, setButtonLoader] = useState(false);
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),
    }
    function BindSizeValue(props) {
        let SizeValue = {};
        debugger
        if (props.location.state !== undefined) {
            if (props.location.state.params.length !== 0) {
                if (props.location.state.params.sizeDetailID !== 0) {
                    SizeValue.Operation = 2;
                    SizeValue.SizeDetailID = props.location.state.params.sizeDetailID;
                    SizeValue.SizeID = props.location.state.params.sizeID;
                    SizeValue.GroupName = props.location.state.params.groupName;
                    SizeValue.Createdby = 1;
                    SizeValue.ReturnSizeID = props.location.state.params.ReturnSizeID;
                    return SizeValue;
                }
            }
        }
        else {
            return SizeValue;
        }
    }

    useEffect(() => {
        let SizeInfo = BindSizeValue(props);
        setSize(SizeInfo);

        SizeService.LoadSizeList().then((response) => {
            if (response.data) {
                let list = response.data;
                setSizeList(list);

            }
        }).catch(() => { });

    }, []);

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/SizeList",
            state: {
                message: Func,
            },
        }
        );
    };

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/SizeList')
        } else {
            window.location.reload();
        }
    }

    const handleChange = (e) => {
        setSubmitted(false);
        let Value = getSize;

        if (e.target.name === "GroupName") {
            let inputText = '';
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
                Value.GroupName = inputText.trim();
            }
            else {
                Value.GroupName = '';
            }
        }
        setSize(Value);
    }

    const handleInputChangeSize = (event) => {
        setSubmitted(false);
        SizeService.LoadSizeList().then((response) => {
            if (response.data) {
                let list = response.data;
                setSizeList(list);

            }
        }).catch(() => { });

        let Value = getSize;

        if (event.length === 0) {
            Value.ReturnSizeID = '';
            Value.SizeID = '';
        }
        else {
            Value.ReturnSizeID = event;
            let strValue = '';
            for (let size of event) {
                if (strValue === '') { strValue = size.value }
                else { strValue = strValue + "," + size.value }
            }
            Value.SizeID = strValue;
        }
        setSize(Value);
    }

    const SaveSize = (e) => {
        setButtonLoader(true);
        e.preventDefault();
        if (getSize.GroupName === '' || getSize.SizeID === '') {
            setButtonLoader(false);
            setSubmitted(true);
            Nodify('Warning!', 'warning', 'Please fill all mandatory(*) fields.');
            return false;
        }
        if (getSize.GroupName.trim()) {
            getSize.GroupName = getSize.GroupName.trim();
            let SizeParam = {};
            SizeParam.Operation = 2;
            SizeParam.SizeDetailsID = getSize.SizeDetailID;
            SizeParam.SizeID = getSize.SizeID.toString();
            SizeParam.GroupName = getSize.GroupName;
            SizeParam.Createdby = 1;
            SizeService.InsertUpdateSize(SizeParam).then(res => {
                var page = "Remove";
                SearchRetain(page);
                let Func = 'Add';
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "-2") {
                    let SizeInfo = BindSizeValue(props);
                    setSize(SizeInfo);
                    Nodify('Warning!', 'warning', 'Group name is already exist.');
                }
                setButtonLoader(false);
                setSubmitted(false);
            });
        }
        else {
            setSubmitted(true);
        }
    }

    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">
                                Edit Group Size
                            </h3>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <Form>
                                    <div className="row">

                                        <div className="form-group col-sm-4">
                                            <label htmlFor="GroupName">
                                                Group Name
                                                <span className="text-danger">*</span>
                                            </label>
                                            <span className="input-icon icon-right">
                                                <Input
                                                    placeholder={"Enter Category Name"}
                                                    id="GroupName"
                                                    name="GroupName"
                                                    value={getSize.GroupName}
                                                    type="text"
                                                    maxLength="50"
                                                    autoComplete="off"
                                                    onChange={handleChange}
                                                    className='form-control'
                                                    style={{ border: submitted && getSize.GroupName === '' ? '1px solid red' : '' }}
                                                    autoFocus
                                                />
                                            </span>

                                        </div>
                                        <div className="form-group col-sm-4">
                                            <label htmlFor="SizeID">
                                                Size Name
                                                <span className="text-danger">*</span>
                                            </label>
                                            <span className="input-icon icon-right">
                                                <Select className="basic-single" name="SizeID"
                                                    id={
                                                        "SizeID"
                                                    }
                                                    isDisabled={false}
                                                    isLoading={false}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    isMulti
                                                    styles={submitted && getSize.SizeID === '' ? styles : ''}
                                                    value={getSize.ReturnSizeID}
                                                    options={getSizeList}
                                                    onChange={
                                                        event => handleInputChangeSize(event)
                                                    }
                                                ></Select>
                                            </span>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div
                        className="buttons-preview"
                        align="right"
                        style={{ marginTop: "15px" }}
                    >
                        <div className="buttons-preview" align="right">
                            <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                            </span>
                            <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                <i className="fa fa-times"></i>&nbsp;Reset
                            </span>
                            <button
                                type="submit"
                                className="btn btn-success"
                                onClick={SaveSize}
                                disabled={buttonLoader}
                            >
                                <i className="fa fa-check right"></i> &nbsp;Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default EditSize;