import React, { useState, useMemo, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTable, useFilters, useSortBy, usePagination, useRowSelect } from "react-table";
import { Check } from "./Check";
import { setPurOrderStyleIdList } from "../../actions/tna";

const ReactDatatable = ({ columns, data, pageAction }) => {

  const [getCount, setCount] = useState(0);
  const dispatch = useDispatch();
  //reducer
  const purchaseOrderIDList = useSelector((state) => state.tna.purchaseOrderIDList);
  const selectedRowIndex = useSelector((state) => state.tna.selectedRowIndex);
  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter
    }),
    []
  );

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter }
  }) {
    const count = preFilteredRows.length;
    setCount(count);
    return (
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        className={'form-control'}
        placeholder={`Search ${count} records...`}
      />
    );
  }

  const filterTypes = useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      }
    }),
    []
  );


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    selectedFlatRows,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        selectedRowIds: selectedRowIndex
      },
      defaultColumn,
      filterTypes
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        ...columns,
        {
          id: "selection",
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div style={{ textAlign: "center" }}>
              <Check
                {...getToggleAllPageRowsSelectedProps()}
                isSelected={false}
              />
            </div>
          ),
          Cell: ({ row }) => {

            //  row.isSelected = row.original.isChecked === 1 ? true : false;
            // row.Checked = row.original.isSelected === 1 ? true : false;
            // row.toggleRowSelected(row.original.isSelected === 1 ? true : false );
            // row.getToggleRowSelectedProps. = row.original.isSelected === 1 ? true : false;
            //
            return (
              <div style={{ textAlign: "center" }}>
                <Check  {...row.getToggleRowSelectedProps({ set: true })} />
              </div>
            );
          },

        }

      ]);
    }

  );
  const { pageIndex, pageSize } = state;



  useMemo(
    () => {
      if (pageAction === "TNAPurchaseOrder") {
        let selectedIDs = selectedFlatRows.map(
          d => d.original.purOrderStyleDetailID);
        if (selectedIDs.length > 0) {
          dispatch(setPurOrderStyleIdList(selectedIDs));
        } else if (selectedIDs.length === 0 && purchaseOrderIDList !== undefined) {
          dispatch(setPurOrderStyleIdList(selectedIDs));
        }
      }

    },
    [selectedFlatRows]
  );

  return (
    <Fragment>
      <table className="table table-striped table-bordered table-hover dataTable reactDatatable no-footer" {...getTableProps()}>

        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th style={{ textAlign: "center" }} {...column.getHeaderProps()}>
                  <span {...column.getSortByToggleProps()}>
                    {column.render("Header")}
                    {
                      column.isSorted
                        ? column.isSortedDesc
                          ? ' ▼'
                          : ' ▲'
                        : ''
                    }</span>
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {
          getCount > 0 && pageOptions.length > 0 ? <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>

              );
            })}
          </tbody> :
            <tbody>
              <tr>
                <td colSpan={columns.length + 1} className='norecordfound'><span>No Records Found</span></td>
              </tr>
            </tbody>
        }
      </table>
      <div>
        <div className="table-toolbar pull-left">
          <select className="form-control input-sm"
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[5, 10, 25, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div className="table-toolbar pull-left" style={{ margin: "5px" }}>
          Showing {pageIndex + 1} to {pageOptions.length} of {getCount} Entries
        </div>
        <div className="table-toolbar pull-right">
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </button>{" "}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            Previous
          </button>{" "}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            Next
          </button>{" "}
          <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {">>"}
          </button>{" "}

          <span>
            | Go to page:{" "}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0;
                gotoPage(pageNumber);
              }}
              min="1"
              style={{ width: "50px" }}
            />
          </span>{" "}

        </div>
      </div>

    </Fragment>
  );
};

export default React.memo(ReactDatatable);
