import axios from "axios";
import authHeader from "../auth-header";
class WarehouseService {

    GetWarehouseList(Operation, Id) {//warehouse details
        return axios.get(window.$APIBaseURL + "Master/GetWarehouseList?Operation=" + Operation + '&CompBuySupID=' + Id, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    InsertWarehouseGrid(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertWarehouseGrid',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
    UpdateWarehouse = async (Params) => {
        return await axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/UpdateWarehouse',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
}
export default new WarehouseService