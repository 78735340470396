import React, { useState, useEffect, Fragment } from "react";
import ReactNotification from 'react-notifications-component';
import Nodify from "../../Common/ReactNotification"
import SearchRetain from "../../Common/SearchRetain";
import PantoneService from "../../../services/Master/PantoneService";
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss';
import { useSelector } from "react-redux";

const PantoneListRegister = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);

    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    if (menulist !== undefined) {
        activeMenu = menulist.filter(i => i.menuName === "Pantone");
    }
    //end enable menu

    const [colorColorPicker, setColorPicker] = useState(false);
    const [colorHexCode, setColorHexCode] = useState('');
    const EmptyInputFields = [{
        PantoneID: 0,
        PantoneType: 0,
        PantoneName: '',
        PantoneCode: '',
        PantoneColor: '',
        Operation: 1,
        Createdby: currentUser.employeeinformationID
    }]

    const [inputFields, setInputFields] = useState(EmptyInputFields);
    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getIndex, setIndex] = useState(0);
    const [PantonetypeList, setPantonetypeList] = useState([]);
    const [ExistingList, setExistingList] = useState([]);
    useEffect(() => {
        PantoneService.getPantonelist(3, 0, 0).then((response) => {
            if (response.data) {
                setExistingList(response.data);
            }
        });
    }, []);

    const values = [...inputFields];

    useEffect(() => {
        PantoneService.loadPantoneTypeList().then((response) => {
            if (response.data) {
                setPantonetypeList(response.data);
            }
        }).catch(() => { });
    }, []);

    const styles = reactCSS({
        'default': {
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                position: 'absolute',
                zIndex: '2',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });

    const handleClickColor = (index) => {
        setIndex(index);
        setColorPicker(true);
    }

    const handleCloseColor = () => {
        setColorPicker(false);
    }
    const handleAddFields = (index) => {
        setColorPicker(false);
        if (values[index].PantoneType > 0 && values[index].PantoneName.trim() !== '' && values[index].PantoneName.trim() !== '' && values[index].PantoneCode.trim() !== '' && values[index].PantoneColor.trim() !== '') {
            values.push({
                PantoneID: 0,
                PantoneType: 0,
                PantoneName: '',
                PantoneCode: '',
                PantoneColor: '#000',
                Operation: 1,
                Createdby: currentUser.employeeinformationID
            });
            setInputFields(values);
            setSubmitted(false);
        } else {
            Nodify('Warning!', 'warning', 'Please fill this pantone mandatory(*) fields.');
            setSubmitted(true);
        }
    };

    const handleInputChange = (index, event) => {
       ;
        if (event.hex === undefined) {
            let inputText = '';
            if (event.target.value.trim() !== '') {
                inputText = event.target.value;
            }
            if (event.target.name === "PantoneType") {
                if(event.target.value === "1206")
                {
                    Nodify('Warning!', 'warning', "Pantone Type is already exist.");
                }
                else 
                {
                    values[index].PantoneType = parseInt(inputText);
                }
                
            } else if (event.target.name === "PantoneName") {
                values[index].PantoneName = inputText;
            } else if (event.target.name === "PantoneCode") {
                values[index].PantoneCode = inputText;
            } else if (event.target.name === "PantoneColor") {
                values[index].PantoneColor = colorHexCode;
                setColorPicker(true);
            }
            setInputFields(values);
        }
        else {
            setColorHexCode(event.hex);
            values[index].PantoneColor = event.hex;
        }

    };

    const CheckOnblur = (index) => {

        if (ExistingList) {
            for (var i = 0; i < ExistingList.length; i++) {
                if (ExistingList[i].pantoneTypeID === values[index].PantoneType &&
                    ExistingList[i].pantoneName.trim().toLowerCase() === values[index].PantoneName.trim().toLowerCase()) {
                    values[index].PantoneName = '';
                    setInputFields(values);
                    Nodify('Warning!', 'warning', "Pantone name is already exist.");
                    return false;
                }
            }
        }


        for (var j = 0; j < values.length; j++) {
            if (index !== j) {
                if (values[j].PantoneName !== '') { // for avoid empty validation
                    if (values[index].PantoneID === values[j].PantoneID) {
                        if (values[index].PantoneName.trim().toLowerCase() === values[j].PantoneName.trim().toLowerCase()) {
                            values[index].PantoneName = '';
                            setInputFields(values);
                            Nodify('Warning!', 'warning', 'Pantone name is already exist.');
                            return false;
                        }
                    }
                }
            }
        }
    }

    const ValidationPopup = (Msg) => {
        Nodify('Warning!', 'warning', Msg);
        setButtonLoader(false);
        return false;
    }

    const handleRemoveFields = index => {
        const values = [...inputFields];
        values.splice(index, 1);
        setInputFields(values);
        setColorPicker(false);
    };

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/PantoneList');
        } else {
            // setInputFields(EmptyInputFields);
            // setSubmitted(false);
            // setButtonLoader(false);
            // setColorHexCode("#000");
            window.location.reload();
        }
    }

    const SavePantone = (e) => {
        setButtonLoader(true);
        e.preventDefault();
        if (values.length === 1 && values[0].PantoneType === 0 && values[0].PantoneName === '' && values[0].PantoneCode === '' && values[0].PantoneColor === '') {
            setButtonLoader(false);
            setSubmitted(true);
            ValidationPopup("Please fill the mandantory(*) fields.");
        } else {
            let Isvalid = false;
            for (var j = 0; j < values.length; j++) {
                if (values[j].PantoneType === 0 || values[j].PantoneName === '' || values[j].PantoneCode === '' || values[j].PantoneColor === '') {
                    Isvalid = true
                }
            }
            if (!Isvalid) {
                setSubmitted(false);
                let letPantoneType = {};
                letPantoneType.PantoneInformation = values;
                letPantoneType.Createdby = currentUser.employeeinformationID;
                PantoneService.InsertUpdatePantoneGrid(letPantoneType).then(res => {
                    var page = "Remove";
                    SearchRetain(page);
                    if (res.data.outputResult === "1") {
                        PageRedirect('Add');
                    } else if (res.data.outputResult === "0") {
                        setButtonLoader(false);
                        ValidationPopup("Error Occured!");
                    }
                });
            } else {
                setButtonLoader(false);
                setSubmitted(true);
                ValidationPopup("Please fill this pantone details mandatory fields.");
            }
        }
    }

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/PantoneList",
            state: {
                message: Func
            }
        });
    }

    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">Add Pantone</h3>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <form> {
                                    inputFields.map((inputField, index) => (
                                        <Fragment key={
                                            `${inputField}~${index}`
                                        }>

                                            <div className="row row_left10">
                                                <div className="col-sm-2 divder_style">
                                                    <div className="form-group">
                                                        <label htmlFor={
                                                            "PantoneType_" + index
                                                        }>Pantone Type<span className="text-danger">*</span>
                                                        </label>
                                                        <select id={
                                                            "PantoneType_" + index
                                                        }
                                                            name="PantoneType"
                                                            className="form-select"
                                                            value={
                                                                inputField.PantoneType
                                                            }
                                                            onChange={
                                                                event => handleInputChange(index, event)
                                                            }
                                                            style={
                                                                {
                                                                    border: submitted && !inputField.PantoneType ? '1px solid red' : ''
                                                                }
                                                            }>
                                                            <option value="0">- Select Pantone Type -</option>
                                                            {
                                                                PantonetypeList.map(item => (
                                                                    <option key={
                                                                        item.id
                                                                    }
                                                                        value={
                                                                            item.id
                                                                        }>
                                                                        {
                                                                            item.name
                                                                        } </option>
                                                                ))
                                                            } </select>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2 divder_style">
                                                    <div className="form-group">
                                                        <label htmlFor={
                                                            "PantoneName_" + index
                                                        }>Pantone Name<span className="text-danger">*</span>
                                                        </label>
                                                        <span className="input-icon icon-right">
                                                            <input type="text" className="form-control"
                                                                id={
                                                                    "PantoneName_" + index
                                                                }
                                                                name="PantoneName"
                                                                placeholder="Enter Pantone Name"
                                                                maxLength="50"
                                                                value={
                                                                    inputField.PantoneName
                                                                }
                                                                onChange={
                                                                    event => handleInputChange(index, event)
                                                                }
                                                                onBlur={
                                                                    event => CheckOnblur(index)
                                                                }
                                                                style={
                                                                    {
                                                                        border: submitted && !inputField.PantoneName ? '1px solid red' : ''
                                                                    }
                                                                }
                                                                autoFocus
                                                            />
                                                        </span>

                                                    </div>
                                                </div>
                                                <div className="col-sm-2 divder_style">
                                                    <div className="form-group">
                                                        <label htmlFor={
                                                            "PantoneCode_" + index
                                                        }>Pantone Code<span className="text-danger">*</span>
                                                        </label>
                                                        <span className="input-icon icon-right">
                                                            <input type="text" className="form-control"
                                                                id={
                                                                    "PantoneCode_" + index
                                                                }
                                                                name="PantoneCode"
                                                                placeholder="Enter Pantone Code"
                                                                maxLength="50"
                                                                value={
                                                                    inputField.PantoneCode
                                                                }
                                                                onChange={
                                                                    event => handleInputChange(index, event)
                                                                }
                                                                onBlur={
                                                                    event => CheckOnblur(index)
                                                                }
                                                                style={
                                                                    {
                                                                        border: submitted && !inputField.PantoneCode ? '1px solid red' : ''
                                                                    }
                                                                } />
                                                        </span>

                                                    </div>
                                                </div>
                                                <div className="col-sm-2 divder_style">
                                                    <div className="form-group">
                                                        <label htmlFor={"swatch_" + index} onClick={() => handleClickColor(index)}>
                                                            Pantone Color<span className="text-danger">*</span>
                                                        </label>
                                                        <div style={{ border: submitted && !inputField.PantoneColor ? '1px solid red' : '' }}>
                                                            <div id={"swatch_" + index} style={styles.swatch}
                                                                onClick={
                                                                    () => handleClickColor(index)
                                                                }

                                                            >
                                                                <div id={"Color_" + index} style={{
                                                                    width: '120px',
                                                                    height: '20px',
                                                                    borderRadius: '2px',
                                                                    background: inputField.PantoneColor,
                                                                }}

                                                                />
                                                            </div>
                                                            {(colorColorPicker && getIndex === index) ? <div id={"popover_" + index} style={styles.popover}>
                                                                <div style={styles.cover} onClick={handleCloseColor} />
                                                                <SketchPicker id={"Color1_" + index} color={inputField.PantoneColor}
                                                                    onChange={
                                                                        event => handleInputChange(index, event)
                                                                    }
                                                                />
                                                            </div> : null}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-3 paddingstatic">
                                                    {
                                                        inputFields.length !== 1 && (
                                                            <div className="col-lg-1_own col-xs-12">
                                                                <button type="button" className="btn btn-danger" title="Delete Pantone Detail"
                                                                    style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}
                                                                    onClick={
                                                                        () => handleRemoveFields(index)
                                                                    }>
                                                                    <i className="fa fa-trash-o"></i>
                                                                </button>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        inputFields.length === (index + 1) && (
                                                            <div className="col-lg-1_own col-xs-12">

                                                                <button type="button" className="btn btn-success" title="Add Pantone Detail"
                                                                    onClick={
                                                                        () => handleAddFields(index)
                                                                    }>
                                                                    <i className="fa fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        )
                                                    } </div>
                                            </div>
                                        </Fragment>
                                    ))
                                }
                                    <div className="col-sm-12">
                                        <div className="buttons-preview" align="right">
                                            <span className="btn btn-primary"
                                                onClick={
                                                    () => ResetOperation("Back")
                                                }>
                                                <i className="fa fa-arrow-circle-left"></i>
                                                &nbsp;Back</span>
                                            <span className="btn btn-danger"
                                                onClick={
                                                    () => ResetOperation()
                                                }>
                                                <i className="fa fa-times"></i>
                                                &nbsp;Reset</span>
                                            <button type="submit" className="btn btn-success"
                                                onClick={SavePantone}
                                                disabled={buttonLoader}>
                                                <i className="fa fa-check right"></i>
                                                &nbsp;Save</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PantoneListRegister;
