import axios from "axios";
import authHeader from "../auth-header";

class PantoneService {

    //      LoadBuyerList(Operation) {
    //         return axios.get(window.$APIBaseURL + "Master/LoadBuyerList?Operation="+Operation, {
    //             headers: {
    //                 authorization: authHeader()
    //             }
    //         });
    //     }
    //     LoadBuyerMapingList() {
    //         return axios.get(window.$APIBaseURL + "Master/LoadBuyerMapingList", {
    //             headers: {
    //                 authorization: authHeader()
    //             }
    //         });
    //     }


    //     getAllBuyerlist() {
    //         return axios.get(window.$APIBaseURL + "Master/GetBuyerList?BuyerID=0&Status=2", {
    //             headers: {
    //                 authorization: authHeader()
    //             }
    //         });
    //     }     

    //     BindSupplierList(CompanyBuyerSupID) {
    //         return axios.get(window.$APIBaseURL + "Master/GetTrimSupplierList?CompanyBuyerSupID=" + CompanyBuyerSupID , { // data: Params,
    //             headers: {
    //                 authorization: authHeader()
    //             }
    //         });
    //     }

    //     BindBankList(CompanyBuyerSupID) {
    //         return axios.get(window.$APIBaseURL + "Master/GetBankList?CompanyBuyerSupID=" + CompanyBuyerSupID , { // data: Params,
    //             headers: {
    //                 authorization: authHeader()
    //             }
    //         });
    //     }

    //  InsertUpdateBuyer(Params) {
    //         return axios({
    //             method: 'post',
    //             url: window.$APIBaseURL + 'Master/InsertUpdateBuyer',
    //             data: Params,
    //             headers: {
    //                 authorization: authHeader()
    //             }
    //         });
    //     }

    //      InsertUpdateBuyerInfo(Params) {
    //         return axios({
    //             method: 'post',
    //             url: window.$APIBaseURL + 'Master/InsertUpdateBuyerInfo',
    //             data: Params,
    //             headers: {
    //                 authorization: authHeader()
    //             }
    //         });
    //     }

    getPantoneTypelist() {
        return axios.get(window.$APIBaseURL + "Master/GetPantoneTypeList?PantoneTypeID=0&Status=2", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdatePantoneTypeGrid(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdatePantoneTypeGrid',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    AddPantoneType(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdatePantoneType',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    getPantonelist(Operation, PantoneTypeID, PantoneID) {
        return axios.get(window.$APIBaseURL + "Master/GetPantoneList?Operation=" + Operation + "&PantoneTypeID=" + PantoneTypeID + "&PantoneID=" + PantoneID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdatePantoneGrid(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdatePantoneGrid',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    AddPantone(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdatePantone',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    loadPantoneTypeList() {
        return axios.get(window.$APIBaseURL + "Master/LoadPantoneTypeList?PantoneType=0", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    loadPantoneTypeList() {
        return axios.get(window.$APIBaseURL + "Master/LoadPantoneTypeList?PantoneType=0", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadFabricPartList() {
        return axios.get(window.$APIBaseURL + "Style/LoadFabricPartList", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadPantoneList() {
        return axios.get(window.$APIBaseURL + "Style/LoadPantoneList", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadSwatchList(BuyerID, BrandID) {
        return axios.get(window.$APIBaseURL + "Style/LoadSwatchList?BuyerID=" + BuyerID + "&BrandID=" + BrandID, {
            headers: {
                authorization: authHeader()
            }
        });
    }



}
export default new PantoneService()
