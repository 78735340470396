import React, { useState, useEffect } from "react";
import Form from "react-validation/build/form";
import { login } from "../../actions/auth";
import useFullPageLoader from "../../hooks/useFullPageLoader";
// import { loadMenu } from "../../actions/common";
import { useDispatch, useSelector } from "react-redux";
import createHistory from 'history/createBrowserHistory';




//import { loadMenu } from "../../actions/common";


const Login = (props) => {

    const [inputs, setInputs] = useState({ username: '', password: '', MPIN: 0 });
    const [submitted, setSubmitted] = useState({ ReqVal: false, LoginVal: false });
    const { username, password, MPIN } = inputs;
    const [getPasswordUpdationMsg, setPasswordUpdationMsg] = useState("");
    const dispatch = useDispatch();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const { user: currentUser } = useSelector((state) => state.auth);


    function handleChange(e) {
        setPasswordUpdationMsg("");
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    useEffect(() => {
        const history = createHistory();
        const state1 = props.location.state
        if (state1 !== undefined && state1 !== null) {
            setPasswordUpdationMsg(state1.message);
            delete props.location.state.message;
            history.replace({ ...state1.location, state1 });
        }
    }, [])

    function handleSubmit(e) {
        setButtonLoader(true);
        e.preventDefault();
        // window.location.reload();
        showLoader();
        if (username && password) {
            // get return url from location state or default to home page
            dispatch(login(username, password, MPIN)).then((res) => {
                if (res.outputResult === "1") {
                    // dispatch(loadMenu(res.employeeinformationID)).then(data => {
                    //     props.history.push("/StyleList");
                    //     // window.location.reload();
                    // })
                    //     .catch(e => {
                    //         console.log(e);
                    //     });
                    setButtonLoader(false);
                    props.history.push("/Dashboard");
                    // For copy and paste the URL(Optional)  
                    window.location.reload();
                    //
                }
                else {

                    setButtonLoader(false);
                    setSubmitted({ LoginVal: true });
                }
                hideLoader();
            }).catch(() => {
                setButtonLoader(false);
                setSubmitted(false);
            });
        }
        else {
            setSubmitted({ ReqVal: true });
            hideLoader();
            setButtonLoader(false);
        }
    }
    const GotoForgotPassword = () => {
        props.history.push("/forgotpassword");
    }
    return (
        <section>
            <div className="login">
                <div className="container">
                    <div className="row login-box">
                        <div className="col-lg-7 bg-color-15 pad-0 none-992 bg-img">
                            <img src="assets/img/logo.png" className="logo_1" alt=""></img>
                            <p className="text_highlight">We are adding and updating information about hundreds of thousands of companies every day, and periodically add companies to the queue for being updated. You can ask for a company to be added to the front of the queue for updating, especially useful if the address, directors, or other critical information has changed.</p>
                        </div>
                        <div className="col-lg-5 pad-0 form-info">
                            <div className="form-section align-self-center">
                                <img src="assets/img/mobile_logo.png" className="mobile_logo" alt=""></img>
                                <h1>Welcome back !</h1>
                                <h3>Sign into your account</h3>
                                <span className="forgotpassword-validtaions">{getPasswordUpdationMsg}</span>
                                <div className="clearfix"></div>
                                <Form onSubmit={handleSubmit}>
                                    <div className="form-group form-box">
                                        <label>Email address</label>
                                        <input type="text" placeholder="Email" name="username"
                                            value={username}
                                            onChange={handleChange}
                                            className={
                                                'form-control' + (
                                                    submitted.ReqVal && !username ? ' is-invalid' : ''
                                                )
                                            } /> {
                                            submitted.ReqVal && !username && <div className="alert-danger">Username is required</div>
                                        } </div>
                                    <div className="form-group form-box clearfix">
                                        <label>Password</label>
                                        <input type={isRevealPwd ? "text" : "password"}
                                            name="password"
                                            placeholder="Password"
                                            value={password}
                                            onChange={handleChange}
                                            className={
                                                'form-control' + (
                                                    submitted.ReqVal && !password ? ' is-invalid' : ''
                                                )
                                            } />
                                        <i title={isRevealPwd ? "Hide password" : "Show password"}
                                            className={isRevealPwd ? "fa fa-eye-slash PasswordHideShow" : "fa fa-eye PasswordHideShow"}
                                            onClick={() => setIsRevealPwd(prevState => !prevState)}
                                        />
                                        {
                                            submitted.ReqVal && !password && <div className="alert-danger">Password is required</div>
                                        }

                                    </div>
                                    <div className="checkbox form-group clearfix">
                                        <a onClick={GotoForgotPassword} className="forgot-password">Forgot Password</a>
                                    </div>
                                    <div className="form-group clearfix">
                                        <button className="btn-md btn-theme btn-block" disabled={buttonLoader}>
                                            {buttonLoader === false ? <span>Log In</span> : <span>Processing...</span>}

                                        </button>
                                    </div>
                                    {
                                        // submitted.LoginVal && <div className="alert-danger"> Username Or Password  Incorrect</div>
                                        submitted.LoginVal && <div className="alert-danger"> User information is not found</div>
                                    }
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
};
export default Login;
