import React, { useState, useEffect, Fragment, useRef } from 'react';
import ReactNotification from 'react-notifications-component';
import Nodify from "../../../Common/ReactNotification";
import SearchRetain from "../../../Common/SearchRetain";
import { useSelector } from "react-redux";
import QACommentPointService from '../../../../services/Master/QACommentPointService';
import Input from "react-validation/build/input";
const QACommentPointRegister = (props) => {
    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    if (menulist !== undefined) {
        activeMenu = menulist.filter(i => i.menuName === "QACommentPoint");
    }
    //end enable menu
    const EmptyInputFields = { CommentID: 0, Comment: '' };
    const [inputFields, setInputFields] = useState([EmptyInputFields]);
    const [ExistingList, setExistingList] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [ButtonName, setButtonName] = useState("Save");

    const { user: currentUser } = useSelector((state) => state.auth);

    const QACommentGridList = [];
    const values = [...inputFields];

    useEffect(() => {
        QACommentPointService.GetQACommentPointList().then((response) => {
            if (response.data) {
                setExistingList(response.data);
            }
        });
    }, []);

    const handleRemoveFields = index => {
        const values = [...inputFields];
        values.splice(index, 1);
        setInputFields(values);
    }


    const handleInputChange = (index, event) => {
        const values = [...inputFields];
        let inputText = "";
        if (event.target.value.trim() !== "") {
            inputText = event.target.value;
        }
        values[index].Comment = inputText;
        setInputFields(values);
    }

    const handleAddFields = index => {
        const values = [...inputFields];
        if (values[index].Comment.trim() !== '') {
            values.push(EmptyInputFields);
            setInputFields(values);
            setSubmitted(false);
        }
        else {
            Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
            setSubmitted(true);
        }
    }

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/QACommentPointList');
        } else {
            window.location.reload();
        }
    }

    const InsertQCComment = (e) => {
        setButtonLoader(true);
        e.preventDefault();
        if (inputFields.length === 1 && inputFields[0].Comment === '') {
            setButtonLoader(false);
            setSubmitted(true);
            ValidationPopup("Please fill atleast one QC Comment Point.");
        }
        else {
            for (var i = 0; i < (inputFields.length); i++) {
                if (inputFields[i].Comment.trim() === '') {
                    Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
                    setSubmitted(true);
                    setButtonLoader(false);
                    return false;
                }
            }
            let Params = { Createdby: currentUser.employeeinformationID, QACommentPoint: inputFields };
            QACommentPointService.InsertQACommentPoints(Params).then(res => {
                var page = "Remove";
                SearchRetain(page);
                let Func = 'Add';
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "-2") {
                    setButtonLoader(false);
                    ValidationPopup("This QC Comment Point Name is already exist.");
                }
                else if (res.data.outputResult === "0") {
                    setButtonLoader(false);
                    ValidationPopup("Error Occured!");
                }
            });
        }
    }

    const CheckDuplicate = (index) => {
        const values = [...inputFields];
        if (ExistingList.item1) {
            for (var i = 0; i < ExistingList.item1.length; i++) {
                if (ExistingList.item1[i].comment.trim().toLowerCase() === values[index].Comment.trim().toLowerCase()) {
                    values[index].Comment = '';
                    setInputFields(values);
                    Nodify('Warning!', 'warning', "This QC Comment Point Name is already exist.");
                    return false;
                }
            }
            for (var i = 0; i < (inputFields.length); i++) {
                if (i !== index) {
                    if (inputFields[i].Comment.trim().toLowerCase() === inputFields[index].Comment.trim().toLowerCase()) {
                        values[index].Comment = "";
                        setInputFields(values);
                        Nodify('Warning!', 'warning', 'This QC Comment Point Name is already exist.');
                        return false;
                    }
                }
            }
        }
    }

    const PageRedirect = (Func) => {
        props.history.push({ pathname: "/QACommentPointList", state: { message: Func } });
    }
    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
        return false;
    }
    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <div className="widget flat radius-bordered">
                            <div className="widget-header heading_box_style">
                                <h3 className="heading_style_common">Add QA Commnet Point</h3>
                            </div>
                            <div className="widget-body">
                                <div id="registration-form">
                                    <form>
                                        {
                                            inputFields.map((inputField, index) => (
                                                <Fragment key={
                                                    `${inputField}~${index}`
                                                }>
                                                    <div className="row row_left10">
                                                        <div className="col-sm-4 divder_style">
                                                            <div className="form-group">
                                                                <label htmlFor={
                                                                    "Comment_" + index
                                                                }>Comment<span className="text-danger">*</span>
                                                                </label>
                                                                <span className="input-icon icon-right">
                                                                    <textarea type="text"
                                                                        autoFocus
                                                                        id="txtFindingsOrRemarks"
                                                                        name="findingsorremarks"
                                                                        placeholder="Enter Findings / Remarks"
                                                                        maxLength="300"
                                                                        value={inputField.Comment}
                                                                        onChange={event => handleInputChange(index, event)}
                                                                        style={{ height: "70px", width: "100%", border: submitted && !inputField.Comment ? '1px solid red' : '' }}
                                                                        onBlur={() => CheckDuplicate(index)}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-3 paddingstatic">
                                                            {
                                                                inputFields.length === (index + 1) && (
                                                                    <div className="col-lg-1_own col-xs-12">

                                                                        <button type="button" className="btn btn-success yarn" title="Add Agency detail" onClick={() => handleAddFields(index)}>
                                                                            <i className="fa fa-plus"></i>
                                                                        </button>
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                inputFields.length !== 1 && (
                                                                    <div className="col-lg-1_own col-xs-12">
                                                                        <button type="button" className="btn btn-danger yarn"
                                                                            style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}
                                                                            title="Delete Agency detail" onClick={() => handleRemoveFields(index)}>
                                                                            <i className="fa fa-trash-o"></i>
                                                                        </button>
                                                                    </div>
                                                                )}

                                                        </div>
                                                    </div>
                                                </Fragment>
                                            ))
                                        }
                                        <div className="col-sm-12">
                                            <div className="buttons-preview" align="right">
                                                <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                                    <i className="fa fa-arrow-circle-left"></i>
                                                    &nbsp;Back
                                                </span>
                                                <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                                    <i className="fa fa-times"></i>
                                                    &nbsp;Reset
                                                </span>
                                                <button type="button" className="btn btn-success" disabled={buttonLoader} onClick={InsertQCComment}>
                                                    <i className="fa fa-check right"></i>&nbsp;
                                                    {ButtonName}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default QACommentPointRegister;