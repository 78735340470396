import React, { useState, useEffect, useRef, useCallback } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import 'react-notifications-component/dist/theme.css';
import ReactNotification from 'react-notifications-component';
import validator from 'validator'
import Nodify from "../../Common/ReactNotification";
import SearchRetain from "../../Common/SearchRetain";
import { useDispatch, useSelector } from "react-redux";
import { createDefect } from "../../../actions/defect";

const DefectEdit = (props) => {
  const [Defect, setInputs] = useState({ DefectName: '' });
  const [submitted, setSubmitted] = useState(false);
  const [getDefect, setOutputs] = useState([]);
  const [ButtonName, setButtonName] = useState("Save");
  const [HeaderName, setHeaderName] = useState("Add");
  const [buttonLoader, setButtonLoader] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  //Enter Error msg
  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        //SaveBuyerInfo(event);
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [])
  function addressAssign(props) {
    //  alert('Edit');
    let addressValue = {
      DefectTypeID: 0,
      DefectName: '',
      Operation: 1,
      Createdby: currentUser.employeeinformationID
    };

    Array.from(document.querySelectorAll("input")).forEach(input => (input.value = ""));
    if (props.location.state !== undefined && props.location.state.params.length !== 0) {
      if (props.location.state.params.defectTypeID !== "") {
        setButtonName("Update");
        setHeaderName("Edit");
        addressValue.DefectTypeID = props.location.state.params.defectTypeID;
        addressValue.DefectName = validator.trim(props.location.state.params.defectName);
        addressValue.Operation = 2;
        return addressValue;
      }
    } else {
      return addressValue;

    }
  }


  useEffect(() => { // showLoader();
    let getAddressAssign = addressAssign(props);
    setOutputs(getAddressAssign)
  }, []);



  function handleChange(e) {

    const { name, value } = e.target;
    setInputs(inputs => ({
      ...inputs,
      [name]: value
    }));
    let addressValue = getDefect;
    let inputText = '';
    if (e.target.value.trim() !== '') {
      inputText = e.target.value;
      setSubmitted(true);
    }
    else { setSubmitted(false); }
    addressValue[e.target.name] = inputText;
    setOutputs(addressValue);
  }

  const saveDefect = useCallback((e) => {
debugger;
    setButtonLoader(true);
    e.preventDefault();
    if (getDefect.DefectName.trim() === '') {
      setButtonLoader(false);
      setSubmitted(true);
      Nodify('Warning!', 'warning', 'Defect Name is required');
      // return false;
    }
    else {
      if (getDefect.DefectName.trim()) {
        getDefect.DefectName = getDefect.DefectName.trim();

        dispatch(createDefect(getDefect))
          .then(data => {
            var page = "Remove";
            SearchRetain(page);
            let Func = 'Add';
            if (data.outputResult === "1") {
              Func = 'Add';
              PageRedirect(Func);
            } else if (data.outputResult === "2") {
              Func = 'Edit';
              PageRedirect(Func);
            } else if (data.outputResult === "0") {
              getDefect.DefectName = ''
              setOutputs(getDefect)
              Nodify('Warning!', 'warning', 'Defect name already Exist');
            }
            setButtonLoader(false);
          })
          .catch(e => {
            console.log(e);
          });
      }
      else {
        setSubmitted(true);
      }
    }
  }, [getDefect]);


  const PageRedirect = (Func) => {
    props.history.push({
      pathname: "/DefectsList",
      state: {
        message: Func
      }
    });
  }





  const ResetOperation = (e) => {
    if (e === "Back") {
      props.history.push('/DefectsList')
    } else {
      window.location.reload();
    }
  }





  return (
    <div className="page-body">
      <ReactNotification />
      <div className="row">
        <div className="col-lg-12 col-sm-12 col-xs-12">
          <div className="widget flat radius-bordered">
            <div className="widget-header heading_box_style">
              <h3 className="heading_style_common">Edit Defects Name</h3>
            </div>
            <div className="widget-body">
              <div id="registration-form">
                <Form>

                  <div className="row">
                    <div className="col-sm-3">
                      <div className="form-group">
                        {/* <Input name="DefectTypeID" className="form-control"
            onChange={handleChange}
            value={getDefect.DefectTypeID}
            type="number"
            style={{ display: 'none' }} /> */}

                        <label htmlFor="DefectName">Defects Name<span className="text-danger">*</span></label>
                        <span className="input-icon icon-right">
                          <input type="text" name="DefectName"
                            id="DefectName"
                            value={getDefect.DefectName}
                            onChange={handleChange}
                            maxLength="25"
                            className={'form-control'}
                            autoFocus
                            placeholder="Enter Defects Name"
                            style={{ border: submitted && getDefect.DefectName === "" ? '1px solid red' : "" }}
                          />
                        </span>


                      </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-sm-12">
                      <div className="buttons-preview" align="right">
                        <span className="btn btn-primary"
                          onClick={
                            () => ResetOperation("Back")
                          }>
                          <i className="fa fa-arrow-circle-left"></i>
                          &nbsp;Back</span>
                        <span className="btn btn-danger"
                          onClick={
                            () => ResetOperation()
                          }>
                          <i className="fa fa-times"></i>
                          &nbsp;Reset</span>
                        <button type="submit" onClick={saveDefect} className="btn btn-success"
                          disabled={buttonLoader}
                        >

                          <i className="fa fa-check right"></i>
                          &nbsp;{
                            ButtonName
                          }</button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DefectEdit