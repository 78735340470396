import {
    DEPARTMENT_LOADING,
    RETRIEVE_DEPARTMENT,
    DELETE_DEPARTMENT,
} from "../actions/types";

const initialState = {
    isLoadingDepartment: true,
    departmentList: [],
};

const departmentReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case DEPARTMENT_LOADING:
            return {
                ...state,
                isLoadingDepartment: payload
            };
        case RETRIEVE_DEPARTMENT:
            return { ...state, departmentList: payload };
        case DELETE_DEPARTMENT:
            const results = state.departmentList.filter(c => c.departmentID !== payload.departmentID);
            return {
                ...state,
                departmentList: results
            };

        default:
            return state;
    }
};
export default departmentReducer;