import {
    ORDER_TRIM_LOADING,
    ORDER_TRIM_RELOADING,
    RETRIEVE_ORDER_TRIM,
    DELETE_ORDER_TRIM,
    RETRIEVE_ORDER_TRIM_DETAIL_VIEW_LIST,
    CLEAR_ORDER_TRIM_DETAIL_VIEW_SUB_ROW,
    RETRIEVE_ORDER_TRIM_SUBMISSION_LIST,
    CLEAR_ORDER_TRIM_SUBMISSION_SUB_ROW,
    RETRIEVE_ORDER_TRIM_APPROVAL_LIST,
    CLEAR_ORDER_TRIM_APPROVAL_SUB_ROW,
    RETRIEVE_ORDER_TRIM_APPROVAL_VIEW_LIST,
    CLEAR_ORDER_TRIM_APPROVAL_VIEW_SUB_ROW

} from "./types";
import OrderService from "../services/Order/OrderService";

const orderTrimLoading = (isStatus) => ({
    type: ORDER_TRIM_LOADING,
    payload: isStatus,
});

const orderTrimReLoading = (isStatus) => ({
    type: ORDER_TRIM_RELOADING,
    payload: isStatus,
});

export const deleteorderTrim = (ordTrimId) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_ORDER_TRIM,
            payload: ordTrimId,
        });

    } catch (err) {
        console.log(err);
    }
};
//request Tab 
export const clearTrimSubmissionSubRow = (index) => async (dispatch) => {
    dispatch(orderTrimLoading(true));
    dispatch({
        type: CLEAR_ORDER_TRIM_SUBMISSION_SUB_ROW,
        payload: index,
    });
    dispatch(orderTrimLoading(false));

};
export const GetOrderTrimSubmissionApprovalGridList = (params) => async (dispatch) => {
    try {
        dispatch(orderTrimLoading(true));
        const res = await OrderService.GetOrderTrimSubmissionApprovalGridList(params);
        dispatch({
            type: RETRIEVE_ORDER_TRIM_SUBMISSION_LIST,
            payload: { data: res.data, params: params },
        });
        dispatch(orderTrimLoading(false));

    } catch (err) {
        console.log(err);
        dispatch(orderTrimLoading(false));
    }
};
//submission
export const GetOrderTrimApprovalList = (params) => async (dispatch) => {
    try {
        dispatch(orderTrimLoading(true));
        const res = await OrderService.GetOrderTrimSubmissionApprovalGridList(params);
        dispatch({
            type: RETRIEVE_ORDER_TRIM_APPROVAL_LIST,
            payload: { data: res.data, params: params },
        });
        dispatch(orderTrimLoading(false));

    } catch (err) {
        console.log(err);
        dispatch(orderTrimLoading(false));
    }
};

export const ReloadOrderTrimApprovalList = (params) => async (dispatch) => {
    try {
        dispatch(orderTrimReLoading(true));
        const res = await OrderService.GetOrderTrimSubmissionApprovalGridList(params);
        dispatch({
            type: RETRIEVE_ORDER_TRIM_APPROVAL_LIST,
            payload: { data: res.data, params: params },
        });
        dispatch(orderTrimReLoading(false));

    } catch (err) {
        console.log(err);
        dispatch(orderTrimReLoading(false));
    }
};

export const clearTrimApprovalSubRow = (index) => async (dispatch) => {
    dispatch(orderTrimLoading(true));
    dispatch({
        type: CLEAR_ORDER_TRIM_APPROVAL_SUB_ROW,
        payload: index,
    });
    dispatch(orderTrimLoading(false));

};
//end
//approval
export const GetOrderTrimApprovalViewList = (params) => async (dispatch) => {
    try {
        dispatch(orderTrimLoading(true));
        const res = await OrderService.GetOrderTrimSubmissionApprovalGridList(params);
        dispatch({
            type: RETRIEVE_ORDER_TRIM_APPROVAL_VIEW_LIST,
            payload: { data: res.data, params: params },
        });
        dispatch(orderTrimLoading(false));

    } catch (err) {
        console.log(err);
        dispatch(orderTrimLoading(false));
    }
};

export const ReloadOrderTrimApprovalViewList = (params) => async (dispatch) => {
    try {
        dispatch(orderTrimReLoading(true));
        const res = await OrderService.GetOrderTrimSubmissionApprovalGridList(params);
        dispatch({
            type: RETRIEVE_ORDER_TRIM_APPROVAL_VIEW_LIST,
            payload: { data: res.data, params: params },
        });
        dispatch(orderTrimReLoading(false));

    } catch (err) {
        console.log(err);
        dispatch(orderTrimReLoading(false));
    }
};

export const clearTrimApprovalViewSubRow = (index) => async (dispatch) => {
    dispatch(orderTrimLoading(true));
    dispatch({
        type: CLEAR_ORDER_TRIM_APPROVAL_VIEW_SUB_ROW,
        payload: index,
    });
    dispatch(orderTrimLoading(false));

};
//end
export const retrieveorderTrim = (IsProduction) => async (dispatch) => {

    try {
        dispatch(orderTrimLoading(true));
        const res = await OrderService.GetOrderTrimList(IsProduction);
        dispatch({
            type: RETRIEVE_ORDER_TRIM,
            payload: res.data,
        });
        dispatch(orderTrimLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(orderTrimLoading(false));
    }
};

export const getOrderTrimDetailsViewList = (params) => async (dispatch) => {
    try {
        dispatch(orderTrimLoading(true));
        const res = await OrderService.GetOrderTrimDetailsViewList(params);
        dispatch({
            type: RETRIEVE_ORDER_TRIM_DETAIL_VIEW_LIST,
            payload: { data: res.data, params: params },
        });
        dispatch(orderTrimLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(orderTrimLoading(false));
    }
};

export const clearTrimDetailsViewSubRow = (index) => async (dispatch) => {
    dispatch(orderTrimLoading(true));
    dispatch({
        type: CLEAR_ORDER_TRIM_DETAIL_VIEW_SUB_ROW,
        payload: index,
    });
    dispatch(orderTrimLoading(false));

};




