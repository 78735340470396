import axios from "axios";
import authHeader from "../auth-header";

class QCInspectionService {

    getCommonResultList(OperationID, SupplierID) {
        return axios.get(window.$APIBaseURL + "Qcinspection/getQCCommonResultList?Operation=" + OperationID + '&SupplierID=' + SupplierID, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    InsertQCInspectionGrid(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Qcinspection/InsertUpdateQCInspectionGrid',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
    getQcNotesList(OperationID, StyleID, AttachmentId) {
        return axios.get(window.$APIBaseURL + "Qcinspection/getQCNotestList?Operation=" + OperationID + '&StyleID=' + StyleID + '&AttachmentIndexID=' + AttachmentId, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    getQcReportList(Operation, StyleID, QCInspectionID) {
        return axios.get(window.$APIBaseURL + "Qcinspection/getQCPreviousReportList?Operation=" + Operation + '&StyleID=' + StyleID + '&QCInspectionID=' + QCInspectionID, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    //For Return all saved list
    getAllQcInfoList(Operation, TNAID, QCInspectionID) {
        return axios.get(window.$APIBaseURL + "Qcinspection/getAllQCList?Operation=" + Operation + '&TNAID=' + TNAID + '&QCInspectionID=' + QCInspectionID, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    GetTNAExfacQAReportList(SubTaskID) {
        return axios.get(window.$APIBaseURL + "Qcinspection/GetTNAExfacQAReportList?SubTaskID=" + SubTaskID, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    LoadPPmeetingDpList() {
        return axios.get(window.$APIBaseURL + "Qcinspection/LoadPPmeetingDpList", {
            headers: {
                authorization: authHeader()
            }
        });
    }
    GetPPMeetingTrimList(Operation, StyleID, SupplierID) {
        return axios.get(window.$APIBaseURL + "Qcinspection/GetPPMeetingTrimList?Operation=" + Operation + '&StyleID=' + StyleID + '&SupplierID=' + SupplierID, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    GetTNAPPApprovalList(StyleID) {
        return axios.get(window.$APIBaseURL + "Qcinspection/GetTNAPPApprovalList?StyleID=" + StyleID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

}
export default new QCInspectionService;