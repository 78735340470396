import React, { useState, useEffect } from "react";
import FabricService from "../../../../services/Master/FabricService";
import Nodify from "../../../Common/ReactNotification";
import SearchRetain from "../../../Common/SearchRetain";
import ReactNotification from 'react-notifications-component';
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import Select from "react-validation/build/select";

const EditFabric = (props) => {
    const [submitted, setSubmitted] = useState(false);
    const [getFabric, setFabric] = useState({});
    const [buttonLoader, setButtonLoader] = useState(false);

    function BindFabricValue(props) {
       ;
        let FabricValue = {};

        if (props.location.state !== undefined) {

            if (props.location.state.params.length !== 0) {
                if (props.location.state.params.fabricDetailsID !== 0) {
                    FabricValue.Operation = 2;
                    FabricValue.FabricDetailsID = props.location.state.params.fabricDetailsID;
                    FabricValue.FabricDetailName = props.location.state.params.fabricDetailName;
                    FabricValue.Content = props.location.state.params.content;
                    let split = props.location.state.params.gsMorCounts.split("-");
                    FabricValue.GSMorCounts = split[0];
                    FabricValue.FabricorYarn = props.location.state.params.fabricorYarn;
                    // CategoryValue.CategoryTypeID = props.location.state.params.categoryTypeID;
                    FabricValue.Createdby = 1;
                    return FabricValue;
                }

            }

        }
        else {
            return FabricValue;
        }

    }

    useEffect(() => {
        let FabricValue = BindFabricValue(props);
        setFabric(FabricValue);
    }, []);

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/YarnList",
            state: {
                message: Func,
            },
        }
        );
    };

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/YarnList')
        } else {
            window.location.reload();
        }
    }

    const handleChange = (e) => {
        let Value = { ...getFabric };
        let inputText = '';
        if (e.target.value.trim() !== '') {
            inputText = e.target.value;
        }
        if (e.target.name === "FabricDetailName") {
            if (e.target.value.trim() !== '') {
                setSubmitted(true);
            }
            else {
                setSubmitted(false);
            }
            Value.FabricDetailName = inputText;
        }
        else if (e.target.name === "Content") {
            if (e.target.value !== '') {
                setSubmitted(true);
            }
            else {
                setSubmitted(false);
            }
            Value.Content = inputText;
        }
        else if (e.target.name === "GSMorCounts") {
            if (inputText !== '') {
                var x1 = inputText.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})/);
                inputText = !x1[2] ? x1[1] : x1[1] + x1[2];
                setSubmitted(true);
            }
            else {
                setSubmitted(false);
            }
            Value.GSMorCounts = inputText;
        }
        setFabric(Value);
    }

    const SaveYarn = (e) => {

        setButtonLoader(true);
        e.preventDefault();
        if (getFabric.FabricDetailName === '' || getFabric.Content === '') {
            setButtonLoader(false);
            setSubmitted(true);
            Nodify('Warning!', 'warning', 'Please fill all mandatory(*) fields.');
            return false;
        }
        if (getFabric.FabricDetailName.trim()) {
            getFabric.FabricDetailName = getFabric.FabricDetailName.trim();
            getFabric.GSMorCounts = getFabric.GSMorCounts.trim();
            // fabricListParam.FabricInformation.FabricorYarn = "Y";
            FabricService.InsertUpdateFabric(getFabric).then(res => {
                var page = "Remove";
                SearchRetain(page);
                let Func = 'Add';
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "-2") {
                    let FabricInfo = BindFabricValue(props);
                    setFabric(FabricInfo);
                    Nodify('Warning!', 'warning', 'Yarn Name is already exist.');
                }
                setButtonLoader(false);
                setSubmitted(false);
            });
        }
        else {
            setSubmitted(true);
        }
    }

    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">
                                Edit Yarn
                            </h3>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <Form>
                                    <div className="row">
                                        <div className="form-group col-sm-3">
                                            <label htmlFor="FabricDetailName">
                                                Yarn Name
                                                <span className="text-danger">*</span>
                                            </label>
                                            <span className="input-icon icon-right">
                                                <Input
                                                    placeholder={"Enter Yarn Name"}
                                                    id="FabricDetailName"
                                                    name="FabricDetailName"
                                                    value={getFabric.FabricDetailName}
                                                    type="text"
                                                    maxLength="50"
                                                    autoComplete="off"
                                                    onChange={handleChange}
                                                    className='form-control'
                                                    style={{ border: submitted && getFabric.FabricDetailName === '' ? '1px solid red' : '' }}
                                                    autoFocus
                                                />
                                            </span>

                                        </div>

                                        <div className="form-group col-sm-3">
                                            <label htmlFor="Content">
                                                Content
                                                <span className="text-danger">*</span>
                                            </label>
                                            <span className="input-icon icon-right">
                                                <Input
                                                    placeholder={"Enter Content"}
                                                    id="Content"
                                                    name="Content"
                                                    value={getFabric.Content}
                                                    type="text"
                                                    maxLength="50"
                                                    autoComplete="off"
                                                    onChange={handleChange}
                                                    className='form-control'
                                                    style={{ border: submitted && getFabric.Content === '' ? '1px solid red' : '' }}

                                                />
                                            </span>

                                        </div>


                                        <div className="form-group col-sm-3">
                                            <label htmlFor="GSMorCounts">
                                                Counts
                                            </label>
                                            <span className="input-icon icon-right">
                                                <Input
                                                    placeholder={"Enter Counts"}
                                                    id="GSMorCounts"
                                                    name="GSMorCounts"
                                                    value={getFabric.GSMorCounts}
                                                    type="text"
                                                    maxLength="50"
                                                    autoComplete="off"
                                                    onChange={handleChange}
                                                    className='form-control'
                                                //style={{ border: submitted && getCategory.CategoryName === '' ? '1px solid red' : '' }}

                                                />
                                            </span>

                                        </div>


                                    </div>

                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div
                        className="buttons-preview"
                        align="right"
                        style={{ marginTop: "15px" }}
                    >
                        <div className="buttons-preview" align="right">
                            <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                            </span>
                            <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                <i className="fa fa-times"></i>&nbsp;Reset
                            </span>
                            <button
                                type="submit"
                                className="btn btn-success"
                                onClick={SaveYarn}
                                disabled={buttonLoader} >

                                <i className="fa fa-check right"></i> &nbsp;Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )

};

export default EditFabric;