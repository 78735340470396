import {
    FABRICTYPE_LOADING,
    RETRIEVE_FABRICTYPE,
    DELETE_FABRICTYPE,
} from "../actions/types";

const initialState = {
    isLoadingFabricType: true,
    fabricTypeList: [],
};

const fabricTypeReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case FABRICTYPE_LOADING:
            return {
                ...state,
                isLoadingFabricType: payload
            };
        case RETRIEVE_FABRICTYPE:
            return { ...state, fabricTypeList: payload };
        case DELETE_FABRICTYPE:
            const results = state.fabricTypeList.filter(c => c.fabricDetailsID !== payload.fabricDetailsID);
            return {
                ...state,
                fabricTypeList: results
            };

        default:
            return state;
    }
};
export default fabricTypeReducer;