import React, { useState, useCallback } from 'react';
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import { Modal, Button } from "react-bootstrap";
import Nodify from '../Common/ReactNotification';
import DepartmentService from "../../services/Master/DepartmentService"
import { useSelector } from "react-redux";

const DepartmentPopup = ({
    getDepartmentList,
    DepartmentCallback,
    showpopup,
}) => {

    const EmptyDepartmentInputFields =
        [{
            Id: 0,
            Name: "",
        }]
    const { user: currentUser } = useSelector((state) => state.auth);


    const [buttonLoader, setButtonLoader] = useState(false);
    const [DepartmentInputFields, setDepartmentInputFields] = useState(EmptyDepartmentInputFields);
    const [DepartmentList, setDepartmentList] = useState([]);
    const [DepartmentSubmitted, setDepartmentsubmitted] = useState(false);


    const handlePopupClose = () => {
        DepartmentCallback(false);
    }

    const CheckDuplicate = (index) => {
        const values = [...DepartmentInputFields];
        if (getDepartmentList) {
            for (var i = 0; i < getDepartmentList.length; i++) {
                if (getDepartmentList[i].label.trim().toLowerCase() === values[index].Name.trim().toLowerCase()) {
                    values[index].Name = '';
                    setDepartmentInputFields(values);
                    setDepartmentList({ Createdby: currentUser.employeeinformationID, MasterInformation: values });
                    Nodify('Warning!', 'warning', "This name is already exist.");
                    return false;
                }
            }
        }
    }

    const handleDepartmentChange = (event) => {
        const values = [...DepartmentInputFields];
        let inputText = "";

        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }
        values[0].Name = inputText;
        setDepartmentInputFields(values);
        setDepartmentList({ Createdby: currentUser.employeeinformationID, MasterInformation: values });
    }

    const SaveDepartment = (e) => {
        setButtonLoader(true);
        e.preventDefault();
        const Values = [...DepartmentInputFields];
        if (Values[0].Name.trim() === '') {
            setDepartmentsubmitted(false);
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Department name is required.');
        }
        if (Values[0].Name.trim()) {
            DepartmentService.InsertUpdateDepartmentGrid(DepartmentList).then(res => {
                if (res.data.outputResult === "1") {
                    Nodify("Success!", "success", " Department added successfully.");
                    DepartmentCallback(true);
                }
                else if (res.data.outputResult === "-2") {
                    Nodify('Warning!', 'warning', 'This name is already exist.');
                }
                setButtonLoader(true);
            });
        }
        else {
            setDepartmentsubmitted(true);
        }
    }
    return (
        <div id="registration-form">
            <Form>
                <div className="row">
                    <div className="form-group col-sm-12">
                        <label htmlFor="Department">
                            Department
                            <span className="text-danger">*</span>
                        </label>
                        <Input
                            placeholder={"Enter Department"}
                            id="Department"
                            name="Department"
                            type="text"
                            maxLength="50"
                            value={DepartmentInputFields[0].Name}
                            autoComplete="off"
                            onChange={handleDepartmentChange}
                            className='form-control'
                            onBlur={() => CheckDuplicate(0)}
                            style={{ border: DepartmentSubmitted && !DepartmentInputFields[0].Name ? '1px solid red' : '' }}
                            autoFocus
                        />
                    </div>
                </div>
                <div style={{ textAlign: "right" }}>
                    <Button type="button" className="btn btn-success" onClick={SaveDepartment} disabled={buttonLoader}>
                        <i className="fa fa-check right"></i> &nbsp; Save
                    </Button>
                </div>
            </Form>
        </div>
        //     //   </Modal.Body>
        //     //   <Modal.Footer>
        //     //     <Button  type="submit" className="btn btn-success" onClick={SaveGenderSize} disabled={buttonLoader}>
        //     //        <i className="fa fa-check right"></i> &nbsp; Save
        //     //     </Button>
        //     //   </Modal.Footer>
        // </Modal>
    )
};
export default DepartmentPopup;
