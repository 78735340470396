import axios from "axios";
import authHeader from "../auth-header";

class AlignmentService {
    InsertAlignmentMaster(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertAlignmentMaster',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    UpdateAlignmentMaster(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/UpdateAlignmentMaster',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadAlignmentTaskOrderDetails() {
        return axios.get(window.$APIBaseURL + "Master/LoadAlignmentTaskOrderDetails", {
            headers: {
                authorization: authHeader()
            }
        });
    }
}

export default new AlignmentService()