import axios from "axios";
import authHeader from "../auth-header";

class BrandWiseStandardService {
    GetRequiredResultOption() {
        return axios.get(window.$APIBaseURL + "Master/RequiredResultOptionList", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadMappedTestsForBrandwiseStandard() {
        return axios.get(window.$APIBaseURL + "Master/LoadMappedTestsForBrandwiseStandard", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadMappedTestStatus(TestID, BuyerID, BrandID, TestType) {
        return axios.get(window.$APIBaseURL + "Master/LoadMappedTestStatus?TestID=" + TestID + "&BuyerID=" + BuyerID + "&BrandID=" + BrandID + "&TestType=" + TestType, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertMappedTestStatus(data) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertMappedTestStatus',
            data: data,
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertBrandWiseStandardMaster(data) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertBrandWiseStandardMaster',
            data: data,
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadBrandWiseStandardMasterList() {
        return axios.get(window.$APIBaseURL + "Master/LoadBrandWiseStandardMasterList", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadBuyerBrandBasedBrandwiseStandard(BuyerID, BrandID, InternalRefID) {
        return axios.get(window.$APIBaseURL + "Master/LoadBuyerBrandBasedBrandwiseStandard?BuyerID=" + BuyerID + "&BrandID=" + BrandID + "&InternalRefID=" + InternalRefID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    UpdateBrandwiseStandardMaster(data) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/UpdateBrandWiseStandardMaster',
            data: data,
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertStandardQuickMaster(data) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertStandardQuickMaster',
            data: data,
            headers: {
                authorization: authHeader()
            }
        });
    }
}
export default new BrandWiseStandardService()