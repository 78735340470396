import React, { useState, useEffect, Fragment } from "react";
import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import Form from "react-validation/build/form";
import $ from "jquery";
import Nodify from "../../Common/ReactNotification";
import UploadImage from "../../Common/UploadImage";
import DocumentUpload from "../../Common/DocumentUpload";
import supplierService from "../../../services/Master/SupplierService";

const FactoryInfo = ({
    addressCallback,
    FactoryList,
    citylist,
    Id,
    Buttonview,
    Grouptype,
    getItemCategory,
    // productionList,
    // customerList,
    // policyList,
    // certificateList,
    // factoryPhotoList
}) => {
    const [inputFields, setInputFields] = useState([{
        FactoryInfoID: 0, FactoryName: "", FactorySize: "", EmployeesStrength: "", AnnualTurnOver: "", IndexName: "",
        ProductionCapacity: [{
            SupProductionID: 0, GroupType: "0", ItemCategory: "0", CapacityInPcs: "", LeadTimeInDays: "", ReOrderTimeInDays: "", IndexName: "",
            ItemNames: [], IsDeleted: 0,
        }],
        Customers: [{
            SupCustomerID: 0, CustomerName: "", ExportCountry: "0", CustomerTrunOver: "", ProductName: "", IndexName: "", IsDeleted: 0,
        }],
        Policies: [{ SupPolicyID: 0, FileName: "", FilePath: "", IndexName: "", IsDeleted: 0, ShowPlus: 1, }],
        Certificates: [{ SupCertificateID: 0, FileName: "", FilePath: "", IndexName: "", IsDeleted: 0, ShowPlus: 1, }],
        FactoryPhotos: [{ SupFactoryPhotoID: 0, FileName: "", FilePath: "", IndexName: "", IsDeleted: 0, ShowPlus: 1, }],
        IsDeleted: 0,
        FactoryInfoLogoName: "", FactoryInfoLogoPath: "",
    }]);
    const [submitted, setSubmitted] = useState({ Production: false, Customer: false, FactoryName: false });
    const [Buttonvisible, SetButtonvisible] = useState("Show");
    const countryList = citylist;
    const groupType = Grouptype;
    // const [getItemCategory, setItemCategory] = useState([]);

    let DynamicClassName = "col-lg-10 col-sm-8 col-xs-12"
    if (Buttonview === "View") {
        DynamicClassName = "col-lg-12 col-sm-12 col-xs-12"
    }

    useEffect(() => {

               if (FactoryList.factoryInfo !== undefined) {
            if (FactoryList.factoryInfo.length > 0) {
                var Count = 0;
                setInputFields([]);
                FactoryList.factoryInfo.forEach((factList, i) => {
                    inputFields.push({
                        FactoryInfoID: factList.factoryInfoID, FactoryName: factList.factoryName,
                        FactorySize: factList.factorySize !== 0 ? factList.factorySize : '', EmployeesStrength: factList.employeesStrength !== 0 ? factList.employeesStrength : '',
                        AnnualTurnOver: factList.annualTurnOver, IndexName: factList.indexName,
                        FactoryInfoLogoName: factList.factoryInfoLogoName, FactoryInfoLogoPath: factList.factoryInfoLogoPath,
                        IsDeleted: 0,
                        Customers: [],
                        ProductionCapacity: [],
                        Policies: [],
                        Certificates: [],
                        FactoryPhotos: [],
                    });
                })
            }
            if (FactoryList.productionCapacity.length > 0) {
                FactoryList.productionCapacity.forEach((prodList, i) => {
                    inputFields.forEach((factList, j) => {
                        if (factList.IndexName == prodList.indexName) {
                            let result = parseInt(prodList.itemCategory);
                           
                            inputFields[j].ProductionCapacity.push({
                                SupProductionID: prodList.supProductionID, GroupType: prodList.groupType, ItemCategory: prodList.itemCategory,
                                CapacityInPcs: prodList.capacityInPcs, LeadTimeInDays: prodList.leadTimeInDays, ReOrderTimeInDays: prodList.reOrderTimeInDays,
                                IndexName: prodList.indexName, CompBuySupID: prodList.compBuySupID, GroupName: prodList.groupName,
                                ItemCategoryName: prodList.itemCategoryName, IsDeleted: 0, //ShowPlus: 1,
                            })
                        }
                    });
                })
            }
            if (FactoryList.customer.length > 0) {
                FactoryList.customer.forEach((custList, i) => {
                    inputFields.forEach((factList, j) => {
                        if (factList.IndexName == custList.indexName) {
                            inputFields[j].Customers.push({
                                SupCustomerID: custList.supCustomerID, CustomerName: custList.customerName, ExportCountry: custList.exportCountry,
                                CustomerTrunOver: custList.customerTrunOver, ProductName: custList.productName, IndexName: custList.indexName,
                                IsDeleted: 0,
                            })
                        }
                    });
                })
            }
            if (FactoryList.policy.length > 0) {
                FactoryList.policy.forEach((PolList, i) => {
                    inputFields.forEach((factList, j) => {
                        if (factList.IndexName == PolList.indexName) {
                            inputFields[j].Policies.push({
                                SupPolicyID: PolList.supPolicyID, FileName: PolList.supPolicyFileName,
                                FilePath: PolList.supPolicyFilePath, IndexName: PolList.indexName,
                                CompBuySupID: PolList.compBuySupID, IsDeleted: 0, ShowPlus: 1,
                            })
                        }
                    });
                })
            }
            if (FactoryList.certificate.length > 0) {
                FactoryList.certificate.forEach((certList, i) => {
                    inputFields.forEach((factList, j) => {
                        if (factList.IndexName == certList.indexName) {
                            inputFields[j].Certificates.push({
                                SupCertificateID: certList.supCertificateID, FileName: certList.supCertificateFileName, FilePath: certList.supCertificateFilePath,
                                IndexName: certList.indexName, IsDeleted: 0,
                            })
                        }
                    });
                })
            }
            if (FactoryList.factoryPhoto.length > 0) {
                FactoryList.factoryPhoto.forEach((FacPhList, i) => {
                    inputFields.forEach((factList, j) => {
                        if (factList.IndexName == FacPhList.indexName) {
                            inputFields[j].FactoryPhotos.push({
                                SupFactoryPhotoID: FacPhList.supFactoryPhotoID, FileName: FacPhList.supFacPhotoFileName,
                                FilePath: FacPhList.supFacPhotoFilePath, IndexName: FacPhList.indexName, IsDeleted: 0,
                            })
                        }
                    });
                })
            }

            setInputFields(inputFields);

            if (Id !== 0) {
                if (FactoryList.length !== 0) {
                    const values = [...inputFields];
                    values.splice(0, 1);
                    setInputFields(values);
                }
            }
        }
    }, [FactoryList]);

    const handleInputChange = (event, MainIndex, List, SubIndex) => {
        const values = [...inputFields];
        if (List === '') {
            let resultValue = event.target.value;
            if (event.target.name === 'FactorySize' || event.target.name === 'EmployeesStrength') {
                resultValue = resultValue.replace(/\D/g, '');
                resultValue = resultValue !== '' ? parseInt(resultValue) : '';
            }
            values[MainIndex][event.target.name] = resultValue;
        } else if (List === 'ProductionCapacity') {
            let resultValue = event.target.value;
            if (event.target.name === 'GroupType') {
                if (resultValue !== "0")
                {
                    // supplierService.LoadCategoryList(parseInt(resultValue)).then((response) => {
                    //     if (response.data) {
                    //         setItemCategory(response.data);
                    //         //values[MainIndex].ProductionCapacity[SubIndex].ItemNames.push(response.data);
                    //     } //
                    // })
                }
                else { values[MainIndex].ProductionCapacity[SubIndex].ItemCategory = '0'; }
                resultValue = resultValue !== '0' ? parseInt(resultValue) : '0';
                values[MainIndex].ProductionCapacity[SubIndex][event.target.name] = resultValue;
            } else {
                resultValue = event.target.name === 'ItemCategory' ? parseInt(resultValue) : resultValue;
                values[MainIndex].ProductionCapacity[SubIndex][event.target.name] = resultValue;
                values[MainIndex].ProductionCapacity[SubIndex].IndexName = MainIndex.toString();
            }
        } else if (List === 'Customers') {
            let resultValue = event.target.value;
            if (event.target.name === 'ExportCountry') {
                resultValue = resultValue !== '0' ? parseInt(resultValue) : '0';
            } else if(event.target.name === 'CustomerName') {
                resultValue = resultValue.replace(/[^A-Za-z]/ig, '');
            }
            values[MainIndex].Customers[SubIndex][event.target.name] = resultValue;
            values[MainIndex].Customers[SubIndex].IndexName = MainIndex.toString();
        } else if (List === 'Policies') {
            values[MainIndex].Policies[SubIndex] = { FileName: event, FilePath: 'Docs/Policies', };
            values[MainIndex].Policies[SubIndex].IndexName = MainIndex.toString();
        } else if (List === 'Certificates') {
            values[MainIndex].Certificates[SubIndex] = { FileName: event, FilePath: "Docs/Certificates", };
            values[MainIndex].Certificates[SubIndex].IndexName = MainIndex.toString();
        } else if (List === 'FactoryPhotos') {
            values[MainIndex].FactoryPhotos[SubIndex] = { FileName: event, FilePath: "Images/FactoryPhotos", };
            values[MainIndex].FactoryPhotos[SubIndex].IndexName = MainIndex.toString();
        }
        values[MainIndex].IndexName = MainIndex.toString();
        setInputFields(values);
        addressCallback(values);
    };

    const Add = (Mainindex, List, Array, Subindex) => {
        const values = [...inputFields];
        var CheckValue1 = [...inputFields].filter(x => x.IsDeleted === 0);
        var CheckValue = CheckValue1[Mainindex];
        var Count = 0;

        if (Array === 'MainArray') {
            if (List.FactoryName === '') {
                Nodify('Warning!', 'warning', "Please fill the Factory Name field");
                setSubmitted({ FactoryName: true });
            } else {
                values.push({
                    FactoryInfoID: 0, FactoryName: "", FactorySize: "", EmployeesStrength: "", AnnualTurnOver: "",
                    ProductionCapacity: [{
                        SupProductionID: 0, GroupType: "0", ItemCategory: "0", CapacityInPcs: "", LeadTimeInDays: "", ReOrderTimeInDays: "", IndexName: "",
                        ItemNames: [], IsDeleted: 0,
                    }],
                    Customers: [{
                        SupCustomerID: 0, CustomerName: "", ExportCountry: "0", CustomerTrunOver: "",
                        ProductName: "", IndexName: "", IsDeleted: 0,
                    }],
                    Policies: [{ SupPolicyID: 0, FileName: "", FilePath: "", IndexName: "", IsDeleted: 0, }],
                    Certificates: [{ SupCertificateID: 0, FileName: "", FilePath: "", IndexName: "", IsDeleted: 0, }],
                    FactoryPhotos: [{ SupFactoryPhotoID: 0, FileName: "", FilePath: "", IndexName: "", IsDeleted: 0, }],
                    IsDeleted: 0, IndexName: "",
                    FactoryInfoLogoName: "", FactoryInfoLogoPath: "",
                });
                setSubmitted({ FactoryName: false });
            }
        } else if (Array === 'ProductionCapacity') {
            if (List.GroupType === "" || List.ItemCategory === "" || List.CapacityInPcs === "" || List.LeadTimeInDays === "" || List.ReOrderTimeInDays === "") {
                Nodify('Warning!', 'warning', "Please fill this Production Capacity empty fields.");
                setSubmitted({ Production: true });
            } else {
                values[Mainindex].ProductionCapacity.push({
                    SupProductionID: 0, GroupType: "0", ItemCategory: "0", CapacityInPcs: "", LeadTimeInDays: "", ReOrderTimeInDays: "", IndexName: "",
                    IsDeleted: 0,
                });
                setSubmitted({ Production: false });
            }
        } else if (Array === 'Customers') {
            if (List.CustomerName === "" || List.ExportCountry === '0' || List.CustomerTrunOver === "" || List.ProductName === "") {
                Nodify('Warning!', 'warning', "Please fill this Customers empty fields.");
                setSubmitted({ Customer: true });
            } else {
                values[Mainindex].Customers.push({
                    SupCustomerID: 0, CustomerName: "", ExportCountry: "0", CustomerTrunOver: "", ProductName: "", IndexName: "", IsDeleted: 0,
                });
                setSubmitted({ Customer: false });
            }
        } else if (Array === 'Policies') {
            values[Mainindex].Policies.push({ SupPolicyID: 0, FileName: "", FilePath: "", IsDeleted: 0, });
        } else if (Array === 'Certificates') {
            values[Mainindex].Certificates.push({ SupCertificateID: 0, FileName: "", FilePath: "", IsDeleted: 0, });
        } else if (Array === 'FactoryPhotos') {
            values[Mainindex].FactoryPhotos.push({ SupFactoryPhotoID: 0, FileName: "", FilePath: "", IsDeleted: 0, });
        }
        setInputFields(values);
    };

    const Remove = (Mainindex, List, Subindex, Page) => {
        const values = [...inputFields];
        var Count = 0; var Count1 = 0;
        if (Page === 'MainArray') {
            //values.splice(Mainindex, 1);
            values[Mainindex].IsDeleted = 1;
        } else {
            if (Page === 'ProductionCapacity') {
                values[Mainindex].ProductionCapacity[Subindex].IsDeleted = 1;
            }
            if (Page === 'Customers') {
                // values[Mainindex].Customers.splice(Subindex, 1) 
                values[Mainindex].Customers[Subindex].IsDeleted = 1;
            }
            if (Page === 'Policies') {
                // values[Mainindex].Policies.splice(Subindex, 1) 
                values[Mainindex].Policies[Subindex].IsDeleted = 1;
            }
            if (Page === 'Certificates') {
                //values[Mainindex].Certificates.splice(Subindex, 1) 
                values[Mainindex].Certificates.IsDeleted = 1;
            }
            if (Page === 'FactoryPhotos') {
                //values[Mainindex].FactoryPhotos.splice(Subindex, 1)
                values[Mainindex].FactoryPhotos[Subindex].IsDeleted = 1;
            }
        }
        setInputFields(values);
        addressCallback(values);
    }

    const AddEmployeeback = (value, index) => {
        const values = [...inputFields];
        let profile = value !== '' ? "/Suplier/" : '';
        values[index].FactoryInfoLogoName = value;
        values[index].FactoryInfoLogoPath = profile + value;
        setInputFields(values);
        addressCallback(values);
    }

    return (
        <>
            <div className="row">
                {
                    inputFields.map((item, i) => (
                        <Fragment key={
                            `${item}~${i}`
                        }>
                            <div className={DynamicClassName} style={{ display: item.IsDeleted === 1 ? "none" : "block" }}>
                                <div className="widget flat radius-bordered">
                                    <div className="panel-group accordion" id="accordion">
                                        <div className="panel panel-default">
                                            <div className="panel-heading ">
                                                <h4 className="mb-0 panel-title">
                                                    <a className="btn btn-link font_wight" data-toggle="collapse" data-parent="accordion"
                                                        href={
                                                            "#collapseFacInfo" + i
                                                        }>
                                                        {
                                                            item.FactoryInfoID === 0 ? 'New Factory Information' : item.FactoryName
                                                        }
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id={"collapseFacInfo" + i}
                                                className={item.FactoryInfoID === 0 ? 'panel-collapse collapse in' : 'panel-collapse collapse'}>
                                                <div className="widget-body">
                                                    <div id="registration-form">
                                                        <Form role="form">
                                                            <div className="row">
                                                                <div className="col-lg-8">
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <label htmlFor="FactoryName">Factory Name<span className="text-danger">*</span></label>
                                                                            <span className='input-icon icon-right'>
                                                                                <input placeholder="Enter Factory Name" id="FactoryName" name="FactoryName" //className="form-control"
                                                                                    value={item.FactoryName}
                                                                                    type="text" autoComplete="off"
                                                                                    onChange={event => handleInputChange(event, i, '', 0)}
                                                                                    maxLength="50"
                                                                                    autoFocus
                                                                                    className={'form-control' + (
                                                                                        submitted.Supplier && !item.FactoryName ? ' is-invalid' : ''
                                                                                    )}
                                                                                    style={{ border: submitted.FactoryName && item.FactoryName === '' ? '1px solid red' : '' }}
                                                                                />
                                                                            </span>
                                                                        </div>

                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor="ReferenceName">Factory Size</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input placeholder="Enter Factory Size" id="FactorySize" name="FactorySize" // className="form-control"
                                                                                        value={item.FactorySize} type="text"
                                                                                        maxLength="50" autoComplete="off"
                                                                                        onChange={event => handleInputChange(event, i, '', 0)}
                                                                                        className={'form-control' + (
                                                                                            submitted.Reference && !item.FactorySize ? ' is-invalid' : ''
                                                                                        )}
                                                                                    />
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor="EmployeesStrength">Employees Strength</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input placeholder="Enter Employees Strength" id="EmployeesStrength" name="EmployeesStrength"
                                                                                        value={item.EmployeesStrength} type="text"
                                                                                        maxLength="50" autoComplete="off" onChange={event => handleInputChange(event, i, '', 0)}
                                                                                        className='form-control'
                                                                                    />
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <div className="form-group">
                                                                                    <label htmlFor={"AnnualTurnOver"}>Annual Turn Over</label>
                                                                                    <input placeholder="Enter Annual Turn Over" id="AnnualTurnOver" name="AnnualTurnOver"
                                                                                        value={item.AnnualTurnOver} type="text"
                                                                                        maxLength="50" autoComplete="off" onChange={event => handleInputChange(event, i, '', 0)}
                                                                                        className='form-control'
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="FactoryLogo">Factory Logo</label>
                                                                        <span className="text-danger">(Click on the image to upload new)</span><br />
                                                                        <UploadImage UploadCallback={(e) => AddEmployeeback(e, i)} PageName='Suplier' filePath={item.FactoryInfoLogoPath} Buttonview={Buttonview} />
                                                                    </div>
                                                                </div>

                                                                <div className="row row_left10">
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group">
                                                                            <label>Production Capacity</label>
                                                                            <table className="table table-bordered">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Group Type<span className="text-danger">*</span></th>
                                                                                        <th>Item Category<span className="text-danger">*</span></th>
                                                                                        <th>Capacity in Pcs<span className="text-danger">*</span></th>
                                                                                        <th>Lead Time In Days<span className="text-danger">*</span></th>
                                                                                        <th>Reorder Time in Days<span className="text-danger">*</span></th>
                                                                                        <th>Action</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        item.ProductionCapacity.map((pItem, j) => {
                                                                                            return (
                                                                                                pItem.IsDeleted === 0 ?
                                                                                                    <>
                                                                                                        <tr key={j}>
                                                                                                            <td>
                                                                                                                <select id={"GroupType"} name="GroupType" className="form-select"
                                                                                                                    value={pItem.GroupType}
                                                                                                                    onChange={event => handleInputChange(event, i, 'ProductionCapacity', j)}
                                                                                                                    style={{ border: submitted.Production && pItem.GroupType == 0 ? '1px solid red' : '' }}
                                                                                                                >
                                                                                                                    <option value="0">-Select Group-</option>
                                                                                                                    {
                                                                                                                        groupType.map(item => (
                                                                                                                            <option key={item.id} value={item.id}>
                                                                                                                                {item.name}
                                                                                                                            </option>
                                                                                                                        ))
                                                                                                                    }
                                                                                                                </select>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <select id={"ItemCategory"} name="ItemCategory" className="form-select"
                                                                                                                    value={pItem.ItemCategory}
                                                                                                                    onChange={event => handleInputChange(event, i, 'ProductionCapacity', j)}
                                                                                                                    style={{ border: submitted.Production && pItem.ItemCategory == 0 ? '1px solid red' : '' }}
                                                                                                                >
                                                                                                                    <option value="0">-Select Category-</option>
                                                                                                                    {
                                                                                                                        getItemCategory.filter(x=>x.groupTypeID===pItem.GroupType).map(item => (
                                                                                                                            <option key={item.id} value={item.id}>
                                                                                                                                {item.name}
                                                                                                                            </option>
                                                                                                                        ))
                                                                                                                    }
                                                                                                                </select>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <input placeholder="Enter Capacity Pcs" id="CapacityInPcs" name="CapacityInPcs"
                                                                                                                    value={pItem.CapacityInPcs} type="text" onChange={event => handleInputChange(event, i, 'ProductionCapacity', j)}
                                                                                                                    maxLength="50" autoComplete="off" className='form-control'
                                                                                                                    style={{ border: submitted.Production && !pItem.CapacityInPcs ? '1px solid red' : '' }}
                                                                                                                />
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <input placeholder="Enter Lead Time" id="LeadTimeInDays" name="LeadTimeInDays"
                                                                                                                    value={pItem.LeadTimeInDays} type="text" onChange={event => handleInputChange(event, i, 'ProductionCapacity', j)}
                                                                                                                    maxLength="50" autoComplete="off" className='form-control'
                                                                                                                    style={{ border: submitted.Production && !pItem.LeadTimeInDays ? '1px solid red' : '' }}
                                                                                                                />
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <input placeholder="Enter ReOrder Time" id="ReOrderTimeInDays" name="ReOrderTimeInDays"
                                                                                                                    value={pItem.ReOrderTimeInDays} type="text" onChange={event => handleInputChange(event, i, 'ProductionCapacity', j)}
                                                                                                                    maxLength="50" autoComplete="off" className='form-control'
                                                                                                                    style={{ border: submitted.Production && !pItem.ReOrderTimeInDays ? '1px solid red' : '' }}
                                                                                                                />
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <span title='Add Production Capacity'
                                                                                                                    className="btn btn-success btn-xs plus"
                                                                                                                    onClick={() => Add(i, pItem, 'ProductionCapacity', j)}
                                                                                                                >
                                                                                                                    <i className="fa fa-plus"></i>
                                                                                                                </span>

                                                                                                                {item.ProductionCapacity.filter(prod => prod.IsDeleted === 0).length > 0 &&
                                                                                                                    <>
                                                                                                                        {item.ProductionCapacity.filter(prod => prod.IsDeleted === 0).length !== 1 ?
                                                                                                                            <>
                                                                                                                                <span title='Delete Production Capacity' onClick={() => Remove(i, pItem, j, 'ProductionCapacity')}
                                                                                                                                    className="btn btn-danger btn-xs delete"
                                                                                                                                    style={{ display: item.ProductionCapacity.filter(prod => prod.IsDeleted === 0) ? '' : 'none' }}
                                                                                                                                >
                                                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                                                </span>
                                                                                                                            </>
                                                                                                                            : ''
                                                                                                                        }
                                                                                                                    </>
                                                                                                                }
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                    : ''
                                                                                            );
                                                                                        })
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row row_left10">
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group">
                                                                            <label>Customers</label>
                                                                            <table className="table table-bordered">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Customer<span className="text-danger">*</span></th>
                                                                                        <th>Export Country<span className="text-danger">*</span></th>
                                                                                        <th>Turn Over<span className="text-danger">*</span></th>
                                                                                        <th>Product<span className="text-danger">*</span></th>
                                                                                        <th>Action</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        item.Customers.map((pItem, j) => {
                                                                                            return (
                                                                                                pItem.IsDeleted === 0 ?
                                                                                                    <>
                                                                                                        <tr key={j}>
                                                                                                            <td>
                                                                                                                <input placeholder="Enter Customer" id="Customer" name="CustomerName"
                                                                                                                    value={pItem.CustomerName} type="text" onChange={event => handleInputChange(event, i, 'Customers', j)}
                                                                                                                    maxLength="50" autoComplete="off" className='form-control'
                                                                                                                    style={{ border: submitted.Customer && pItem.CustomerName === '' ? '1px solid red' : '' }}
                                                                                                                />
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <select id={"ExportCountry"} name="ExportCountry" className="form-select"
                                                                                                                    value={pItem.ExportCountry}
                                                                                                                    onChange={event => handleInputChange(event, i, 'Customers', j)}
                                                                                                                    style={{ border: submitted.Customer && pItem.ExportCountry == 0 ? '1px solid red' : '' }}
                                                                                                                >
                                                                                                                    <option value="0">- Select Country -</option>
                                                                                                                    {
                                                                                                                        countryList.map(item => (
                                                                                                                            <option key={item.value} value={item.value}>
                                                                                                                                {item.label}
                                                                                                                            </option>
                                                                                                                        ))
                                                                                                                    }
                                                                                                                </select>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <input placeholder="Enter Turn Over" id="CustomerTrunOver" name="CustomerTrunOver"
                                                                                                                    value={pItem.CustomerTrunOver} type="text" onChange={event => handleInputChange(event, i, 'Customers', j)}
                                                                                                                    maxLength="50" autoComplete="off" className='form-control'
                                                                                                                    style={{ border: submitted.Customer && pItem.CustomerTrunOver === '' ? '1px solid red' : '' }}
                                                                                                                />
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <input placeholder="Enter Product" id="ProductName" name="ProductName"
                                                                                                                    value={pItem.ProductName} type="text" onChange={event => handleInputChange(event, i, 'Customers', j)}
                                                                                                                    maxLength="50" autoComplete="off" className='form-control'
                                                                                                                    style={{ border: submitted.Customer && pItem.ProductName === '' ? '1px solid red' : '' }}
                                                                                                                />
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <span title='Add Customer'
                                                                                                                    onClick={() => Add(i, pItem, 'Customers', j)}
                                                                                                                    className="btn btn-success btn-xs plus"
                                                                                                                >
                                                                                                                    <i className="fa fa-plus"></i>
                                                                                                                </span>

                                                                                                                {item.Customers.filter(prod => prod.IsDeleted === 0).length > 0 &&
                                                                                                                    <>
                                                                                                                        {item.Customers.filter(prod => prod.IsDeleted === 0).length !== 1 ?
                                                                                                                            <>
                                                                                                                                <span title='Delete Customer' onClick={() => Remove(i, pItem, j, "Customers")}
                                                                                                                                    className="btn btn-danger btn-xs delete"
                                                                                                                                    style={{ display: item.Customers.filter(prod => prod.IsDeleted === 0) ? '' : 'none' }}
                                                                                                                                >
                                                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                                                </span>
                                                                                                                            </>
                                                                                                                            : ''
                                                                                                                        }
                                                                                                                    </>
                                                                                                                }
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                    : ''
                                                                                            );
                                                                                        })
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group">
                                                                            <div className="col-lg-6">
                                                                                <label>Policies</label>
                                                                            </div>
                                                                            <div className="col-lg-6" style={{ textAlign: "right", paddingTop: '3px' }}>
                                                                                <span title='Add Policies' onClick={() => Add(i, '', 'Policies', 0)}
                                                                                    className="btn btn-success btn-xs plus" style={{ textAlign: "center" }}
                                                                                >
                                                                                    <i className="fa fa-plus"></i>
                                                                                </span>
                                                                            </div>
                                                                            <div className="clearfix"></div>
                                                                            <div className="row row_left10">
                                                                                {
                                                                                    item.Policies.map((pitem, j) => {
                                                                                        return (
                                                                                            pitem.IsDeleted === 0 ?
                                                                                                <>
                                                                                                    <div className="col-lg-4">
                                                                                                        <div className="row">
                                                                                                            <div className="col-lg-12">
                                                                                                                <div className="well with-header">
                                                                                                                    <DocumentUpload key={j} UploadCallback={event => handleInputChange(event, i, 'Policies', j)} name="Policies" PageName='Policies' filePath={pitem.FilePath} PlaceName={pitem.FileName} Buttonview={Buttonvisible} SelectUpload="Document" />
                                                                                                                </div>
                                                                                                                {item.Policies.filter(prod => prod.IsDeleted === 0).length > 0 &&
                                                                                                                    <>
                                                                                                                        {item.Policies.filter(prod => prod.IsDeleted === 0).length !== 1 ?
                                                                                                                            <>
                                                                                                                                <span title='Delete Policies' onClick={() => Remove(i, pitem, j, "Policies")}                                                                                                                                    
                                                                                                                                    className="btn btn-danger btn-xs delete"
                                                                                                                                >
                                                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                                                </span>
                                                                                                                            </>
                                                                                                                            : ''
                                                                                                                        }
                                                                                                                    </>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                                : ''
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group">
                                                                            <div className="col-lg-6">
                                                                                <label>Certificates</label>
                                                                            </div>
                                                                            <div className="col-lg-6" style={{ textAlign: "right", paddingTop: '3px' }}>
                                                                                <span title='Add Certificates' onClick={() => Add(i, '', 'Certificates', 0)}
                                                                                    className="btn btn-success btn-xs plus"
                                                                                >
                                                                                    <i className="fa fa-plus"></i>
                                                                                </span>
                                                                            </div>
                                                                            {
                                                                                item.Certificates.map((pitem, j) => {
                                                                                    return (
                                                                                        pitem.IsDeleted === 0 ?
                                                                                            <>
                                                                                                <div className="col-lg-4">
                                                                                                    <div className="row">
                                                                                                        <div className="col-lg-12">
                                                                                                            <div className="well with-header">
                                                                                                                <DocumentUpload key={j} UploadCallback={event => handleInputChange(event, i, 'Certificates', j)} name="Certificates" PageName='Certificates' filePath={pitem.FilePath} PlaceName={pitem.FileName} Buttonview={Buttonvisible} SelectUpload="Document" />
                                                                                                            </div>

                                                                                                            {item.Certificates.filter(prod => prod.IsDeleted === 0).length > 0 &&
                                                                                                                <>
                                                                                                                    {item.Certificates.filter(prod => prod.IsDeleted === 0).length !== 1 ?
                                                                                                                        <>
                                                                                                                            <span title='Delete Certificates' onClick={() => Remove(i, pitem, j, 'Certificates')}
                                                                                                                                className="btn btn-danger btn-xs delete"
                                                                                                                            >
                                                                                                                                <i className="fa fa-trash-o"></i>
                                                                                                                            </span>
                                                                                                                        </>
                                                                                                                        : ''
                                                                                                                    }
                                                                                                                </>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                            : ''
                                                                                    );
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group">
                                                                            <div className="col-lg-6">
                                                                                <label>Factory Photos</label>
                                                                            </div>
                                                                            <div className="col-lg-6" style={{ textAlign: "right", paddingTop: '3px' }}>
                                                                                <span title='Add Factory Photos' onClick={() => Add(i, '', 'FactoryPhotos', 0)}
                                                                                    className="btn btn-success btn-xs plus"
                                                                                >
                                                                                    <i className="fa fa-plus"></i>
                                                                                </span>
                                                                            </div>
                                                                            <div className="clearfix"></div>
                                                                            <div className="row row_left10">
                                                                                {
                                                                                    item.FactoryPhotos.map((pitem, j) => {
                                                                                        return (
                                                                                            pitem.IsDeleted === 0 ?
                                                                                                <>
                                                                                                    <div className="col-lg-4">
                                                                                                        <DocumentUpload key={j} UploadCallback={event => handleInputChange(event, i, 'FactoryPhotos', j)} name="FactoryPhotos" PageName='FactoryPhotos' filePath={pitem.FilePath} PlaceName={pitem.FileName} Buttonview={Buttonvisible} SelectUpload="Image" />

                                                                                                        {item.FactoryPhotos.filter(prod => prod.IsDeleted === 0).length > 0 &&
                                                                                                            <>
                                                                                                                {item.FactoryPhotos.filter(prod => prod.IsDeleted === 0).length !== 1 ?
                                                                                                                    <>
                                                                                                                        <span title='Delete Factory Photos' onClick={() => Remove(i, pitem, j, 'FactoryPhotos')}
                                                                                                                            className="btn btn-danger btn-xs delete"
                                                                                                                            style={{ display: item.FactoryPhotos.length === 1 ? 'none' : '', textAlign: 'left' }}
                                                                                                                        >
                                                                                                                            <i className="fa fa-trash-o"></i>
                                                                                                                        </span>
                                                                                                                    </>
                                                                                                                    : ''
                                                                                                                }
                                                                                                            </>
                                                                                                        }
                                                                                                    </div>
                                                                                                </>
                                                                                                : ''
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                Buttonview !== "View" && <>
                                    <div className="col-lg-1_own col-xs-12">
                                        <span style={
                                            {
                                                display: (inputFields.length === 1 || item.IsDeleted === 1 ? "none" : "")
                                            }
                                        }>
                                            <button className="btn btn-danger" title="Delete Factory Information"
                                                onClick={
                                                    () => Remove(i, item, 0, 'MainArray')
                                                }>
                                                <i className="fa fa-trash-o"></i>
                                            </button>
                                        </span>
                                    </div>
                                    <div className="col-lg-1_own col-xs-12">
                                        <span>
                                            <span style={
                                                {
                                                    display: (inputFields.length === (i + 1) ? "" : "none")
                                                }
                                            }>
                                                <button className="btn btn-success" title="Add Factory Information"
                                                    onClick={
                                                        () => Add(i, item, 'MainArray', 0, (inputFields.filter(x => x.IsDeleted === 0).length - 1))
                                                    }>
                                                    <i className="fa fa-plus"></i>
                                                </button>
                                            </span>

                                        </span>
                                    </div>
                                </>
                            }
                        </Fragment>
                    ))
                }
            </div>
        </>
    );
};

export default FactoryInfo;