import {
    PROCESSTYPE_LOADING,
    CREATE_PROCESSTYPE_SUCCESS,
    UPDATE_PROCESSTYPE_SUCCESS,
    RETRIEVE_PROCESSTYPE,
    DELETE_PROCESSTYPE,
} from "./types";
import ProcessTypeService from "../services/Master/ProcessTypeService";
import moment from "moment";

const ProcessTypeLoading = (isStatus) => ({
    type: PROCESSTYPE_LOADING,
    payload: isStatus,
});

export const deleteProcessType = (ProcessTypeID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_PROCESSTYPE,
            payload: ProcessTypeID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrieveProcessType = (ProcessTypeID) => async (dispatch) => {
   
    try {
        dispatch(ProcessTypeLoading(true));
        const res = await ProcessTypeService.getProcessTypeList(ProcessTypeID);

        dispatch({
            type: RETRIEVE_PROCESSTYPE,
            payload: res.data,
        });
        dispatch(ProcessTypeLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(ProcessTypeLoading(false));
    }
};

export const insertUpdateProcessTypeGrid = (getProcessTypeList) => async (dispatch) => {
    let resData = "";
    await ProcessTypeService.InsertUpdateProcessTypeGrid(getProcessTypeList).then((res) => {
       
        resData = res.data;
        if (resData.item2.outputResult === "1") {
            dispatch({ type: CREATE_PROCESSTYPE_SUCCESS, payload: resData.item1 });
        }

    }).catch((err) => { return Promise.reject(err); });
    return Promise.resolve(resData.item2);
}

export const updateProcessType = (getProcessTypeInfo) => async (dispatch) => {
    let resData = "";
    await ProcessTypeService.InsertUpdateProcessType(getProcessTypeInfo).then((res) => {
        resData = res.data;
        if (resData.outputResult === "2") {
            let CurrentDate = new Date();
            dispatch({
                type: UPDATE_PROCESSTYPE_SUCCESS, payload: {
                    getProcessTypeInfo: getProcessTypeInfo, CurrentDate: moment(CurrentDate).format("MM/DD/YYYY hh:mm A")
                }
            });
        }

    }).catch((err) => { return Promise.reject(err); });
    return Promise.resolve(resData);
}