import React, { useState, useEffect, Fragment } from "react";
import 'react-notifications-component/dist/theme.css';
import Form from "react-validation/build/form";
import $ from "jquery";
import { emailExp } from "../../Common/RegExpression";
import Nodify from "../../Common/ReactNotification"
// import BrandEndCustomerStoryTheme from "/quickMaster/_BrandEndCustomerStoryTheme";
import BranchPopup from "../../quickMaster/_Branch"
import BranchService from "../../../services/Master/Companyservice";
import { useSelector } from "react-redux";
import axios from 'axios';
import { Modal, Button } from "react-bootstrap";
import GeoLocation from './../GeoLocation';
import CityPopup from "../../quickMaster/_City";
const CompanyAddress = ({
    addressCallback,
    IsValidationCallback,
    citylist,
    branchlist,
    AddressList,
    Id,
    Buttonview,
    MenuPermission,
    AddressInfoQuick,
    PageName,
    stateList,
    countryList

}) => {
    const [inputFields, setInputFields] = useState([{
        AddressInformationID: 0,
        AddressType: 0,
        Country: 0,
        CompanyState: 0,
        City: 0,
        PrimaryContactNumber: '',
        SecondaryContactNumber: '',
        PrimaryEmailAddress: '',
        SecondaryEmailAddress: '',
        PostalCode: '',
        Website: '',
        AddressLine1: '',
        AddressLine2: '',
        Latitude: '',
        Longitude: '',
        StateName: '',
        CountryName: '',
        IsDeleted: 0
    }]);
    const handlePopupClose = () => {
        setQuickMasterName('');
    };
    const handleCityPopupClose = () => {
        setQuickMasterCity('');
    };

    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    if (menulist !== undefined) {
        if (PageName === 'company') {
            activeMenu = menulist.filter(i => i.menuName === 'Company');
        }
        else if (PageName === 'Buyer') {
            activeMenu = menulist.filter(i => i.menuName === "BuyerList");
        }
        else if (PageName === 'Supplier') {
            activeMenu = menulist.filter(i => i.menuName === "Supplier");
        }
    }
    //end enable menu
    const cityList = citylist;
    const [submitted, setSubmitted] = useState(false);
    const [getQuickMasterName, setQuickMasterName] = useState('');
    const [getQuickMasterCity, setQuickMasterCity] = useState('');
    const [branchList, setBranchList] = useState(branchlist);
    const [cityListpop, setCityListpop] = useState(citylist);
    const [getIsShowGeoLocModal, setIsShowGeoLocModal] = useState(false);
    const [getCurrentLocation, setCurrentLocation] = useState({
        Latitude: '',
        Longitude: ''
    });
    const [getMapIndex, setMapIndex] = useState(0);
    var CurrentLatLong = { ...getCurrentLocation };
    let DynamicClassName = "col-lg-10 col-sm-8 col-xs-12"

    if (Buttonview === "View") {
        DynamicClassName = "col-lg-12 col-sm-12 col-xs-12"
    }
    useEffect(() => {
        BranchService.loadAddressTypeList().then((response) => {
            if (response.data) {
                setBranchList(response.data);
            }
        }).catch(() => { });

        // RoleService.LoadRoleList().then((response) => {
        //     if (response.data) {
        //         setRoleList(response.data);
        //     }
        // }).catch(() => { });

    }, []);

    useEffect(() => {

        if (AddressList.length !== 0) {
            let emptyarray = [];
            setInputFields(emptyarray);
            //addressCallback()
        }
        let Addressinput = [];
        AddressList.forEach(AddressList => {
            Addressinput.push({
                AddressInformationID: AddressList.addressInformationID,
                AddressTypeName: AddressList.addressTypeName,
                AddressType: AddressList.addressTypeID,
                Country: AddressList.countryID,
                CompanyState: AddressList.stateID,
                City: AddressList.cityID,
                PrimaryContactNumber: AddressList.primaryContactNumber,
                SecondaryContactNumber: AddressList.secondaryContactNumber,
                PrimaryEmailAddress: AddressList.primaryEmailAddress,
                SecondaryEmailAddress: AddressList.secondaryEmailAddress,
                PostalCode: AddressList.postalCode,
                Website: AddressList.website,
                TestWeb: !AddressList.website.includes('http') ? AddressList.website : "",
                AddressLine1: AddressList.addressLine1,
                AddressLine2: AddressList.addressLine2,
                Latitude: AddressList.latitude,
                Longitude: AddressList.longitude,
                StateName: AddressList.stateName + " / " + AddressList.countryName,
                CountryName: AddressList.countryName,
                IsDeleted: AddressList.isDeleted === undefined ? 0 : AddressList.isDeleted,
            });
            setInputFields(Addressinput);
            addressCallback(Addressinput)
        })
        // if (Id !== 0) {
        //     if (AddressList.length !== 0) {
        //         const values = [...inputFields];
        //         values.splice(0, 1);
        //         setInputFields(values);
        //         addressCallback(values)
        //     }
        // }
    }, [AddressList]);

    useEffect(() => {
        setSubmitted(IsValidationCallback);
    }, [IsValidationCallback === true]);

    const CheckDuplicate = (index) => {
        const values = [...inputFields];
        for (var i = 0; i < (inputFields.length); i++) {
            if (i !== index) {
                if (inputFields[i].PrimaryContactNumber.trim() === inputFields[index].PrimaryContactNumber.trim()) {
                    values[index].PrimaryContactNumber = "";
                    setInputFields(values);
                    addressCallback(values);
                    Nodify('Warning!', 'warning', 'This Mobilenumber is already exist.');
                    return false;
                }
                else if (inputFields[i].PrimaryEmailAddress.trim() === inputFields[index].PrimaryEmailAddress.trim()) {
                    values[index].PrimaryEmailAddress = "";
                    setInputFields(values);
                    addressCallback(values);
                    Nodify('Warning!', 'warning', 'This Email is already exist.');
                    return false;
                }
            }
        }
    }

    const handleInputChange = async (index, event) => {
        const values = [...inputFields];
        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }

        if (event.target.name === "AddressType") {
            values[index].AddressType = parseInt(event.target.value);
            for (var i = 0; i < (inputFields.length); i++) {
                if (i !== index) {
                    if (values[i].IsDeleted === 0) {
                        if (values[i].AddressType === values[index].AddressType) {
                            values[index].AddressType = "0";
                            setInputFields(values);
                            addressCallback(values);
                            Nodify('Warning!', 'warning', 'Branch already Exist.');
                        }
                    }
                }
            }
        } else if (event.target.name === "City") {
            values[index].City = parseInt(event.target.value);
            let CityList = cityList;
            CityList = CityList.filter(city => city.id === parseInt(event.target.value));
            values[index].CompanyState = 0;
            values[index].Country = 0;

            values[index].StateName = '';
            values[index].CountryName = '';

            if (CityList.length > 0) {
                values[index].CompanyState = parseInt(CityList[0].stateId);
                values[index].Country = parseInt(CityList[0].countryId);

                values[index].StateName = (CityList[0].stateName + " / " + CityList[0].countryName);
                values[index].CountryName = (CityList[0].countryName);
                //lat long reference https://dev.to/shimjudavid/usegeoposition-hook-custom-react-hook-to-grab-latitude-and-longitude-from-a-given-address-5am2
                const res = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${CityList[0].name + ' ' + values[index].StateName}&key=AIzaSyDHgipImLEiJaEMyXtrq6XP0XJEKYIRbK0`);
                if (res.data.results.length != 0) {
                    values[index].Latitude = (res.data.results[0].geometry.location.lat).toString();
                    values[index].Longitude = (res.data.results[0].geometry.location.lng).toString();
                    CurrentLatLong.Latitude = values[index].Latitude;
                    CurrentLatLong.Longitude = values[index].Longitude;
                    setCurrentLocation(CurrentLatLong);
                    setMapIndex(index);
                    setIsShowGeoLocModal(true);
                }
                else {
                    values[index].Latitude = "";
                    values[index].Longitude = "";
                }
            }
            else {
                values[index].Latitude = "";
                values[index].Longitude = "";
            }
        } else if (event.target.name === "PrimaryContactNumber") { // Add mask and Restrict String Values

            // var x = inputText.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            // inputText = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');

            values[index].PrimaryContactNumber = inputText;
        } else if (event.target.name === "SecondaryContactNumber") {
            values[index].SecondaryContactNumber = inputText;
        } else if (event.target.name === "PrimaryEmailAddress") {
            values[index].PrimaryEmailAddress = inputText;
        } else if (event.target.name === "SecondaryEmailAddress") {
            values[index].SecondaryEmailAddress = inputText;
        } else if (event.target.name === "PostalCode") {

            // add Mask and Restrict String values
            // var x1 = inputText.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})/);
            // inputText = !x1[2] ? x1[1] : x1[1] + ' ' + x1[2];
            inputText = event.target.value
            values[index].PostalCode = inputText;
        } else if (event.target.name === "Website") {
            values[index].Website = inputText;
        } else if (event.target.name === "AddressLine1") {
            values[index].AddressLine1 = inputText;
        }
        else if (event.target.name === "Latitude") {
            values[index].Latitude = inputText;
        }
        else if (event.target.name === "Longitude") {
            values[index].Longitude = inputText;
        }
        else {
            values[index].AddressLine2 = inputText;
        }
        setInputFields(values);
        addressCallback(values);
    };

    const handleGeoLocClose = () => {
        setIsShowGeoLocModal(false);
    }

    const OpenMapModal = (index) => {
        const values = [...inputFields];
        CurrentLatLong.Latitude = values[index].Latitude;
        CurrentLatLong.Longitude = values[index].Longitude;
        setCurrentLocation(CurrentLatLong);
        setMapIndex(index);
        setIsShowGeoLocModal(true);
    }

    const handleAddFields = (index) => {
        const values = [...inputFields];
        var CheckValue1 = [...inputFields].filter(x => x.IsDeleted === 0);
        var CheckValue = CheckValue1[index];
        if (CheckValue.AddressType > 0 && CheckValue.City > 0 && CheckValue.PrimaryContactNumber !== "" && CheckValue.PostalCode !== "" && CheckValue.AddressLine1 !== "") {
            let msg = "";
            if (CheckValue.PrimaryEmailAddress !== "") {
                if (emailExp.test(CheckValue.PrimaryEmailAddress) === false) {
                    msg = "Email address is not valid";

                }
            }
            // else if (CheckValue.PrimaryContactNumber.length !== 20) {
            //     msg = "Contact number is not valid";

            // } else if (CheckValue.PostalCode.length !== 20) {
            //     msg = "Postal code is not valid";

            // }
            if (msg !== "") {
                Nodify('Warning!', 'warning', msg);

            } else {
                setSubmitted(false);
                values.push({
                    AddressInformationID: 0,
                    AddressType: 0,
                    Country: 0,
                    CompanyState: 0,
                    City: 0,
                    PrimaryContactNumber: '',
                    SecondaryContactNumber: '',
                    PrimaryEmailAddress: '',
                    SecondaryEmailAddress: '',
                    PostalCode: '',
                    Website: '',
                    AddressLine1: '',
                    AddressLine2: '',
                    Latitude: '',
                    Longitude: '',
                    IsDeleted: 0
                });
                setInputFields(values);
                setSubmitted(false);
            }


        } else {
            Nodify('Warning!', 'warning', 'Please fill this address mandatory(*) fields.');
            setSubmitted(true);
        }
    };

    const handleRemoveFields = index => {
        const values = [...inputFields];
        if (values[index].AddressInformationID === 0) {
            values.splice(index, 1);
        }
        else {
            values[index].IsDeleted = 1;
        }
        setInputFields(values);
        addressCallback(values);
    };

    const branchCallback = (value) => {
        if (value === true) {
            BranchService.loadAddressTypeList().then((response) => {
                if (response.data) {
                    setBranchList(response.data);
                } else {
                }
            })
                .catch(() => { });
            setQuickMasterName('');
        } else {
            setQuickMasterName('');
        }

    }
    const cityCallback = () => {
        addressCallback(true)
        setQuickMasterCity('')
    }
    const callback = (count) => {
        let values = [...inputFields];
        values[getMapIndex].Latitude = count.Latitude;
        values[getMapIndex].Longitude = count.Longitude;
        setInputFields(values);
        addressCallback(values);
        setIsShowGeoLocModal(false);
    }

    return (
        <> {
            inputFields.map((inputField, index) => (
                <Fragment key={
                    `${inputField}~${index}`
                }>
                    <div className={DynamicClassName}
                        style={
                            {
                                display: inputField.IsDeleted === 1 ? "none" : "block"
                            }
                        }>
                        <div className="widget flat radius-bordered">

                            <div className="panel-group accordion" id="accordion">
                                <div className="panel panel-default">
                                    <div className="panel-heading ">
                                        <h4 className="mb-0 panel-title">
                                            <a className="btn btn-link font_wight" data-toggle="collapse" data-parent="accordion"
                                                href={
                                                    "#collapseAdd_" + index
                                                }>
                                                {
                                                    inputField.AddressTypeName === undefined ? 'New Address' : inputField.AddressTypeName
                                                } </a>


                                        </h4>


                                    </div>
                                    <div id={
                                        "collapseAdd_" + index
                                    }
                                        className={inputField.AddressTypeName === undefined ? 'panel-collapse collapse in' : 'panel-collapse collapse'}>
                                        <div className="widget-body">


                                            <div id="registration-form">
                                                <Form role="form">
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-group">

                                                                <label htmlFor={
                                                                    "AddressType_" + index
                                                                }>
                                                                    {
                                                                        AddressInfoQuick.length > 0 && AddressInfoQuick[0].isQuick === 1 &&
                                                                        Buttonview !== "View" &&
                                                                        <button type='button' className="glyphicon glyphicon-plus AddPlusIcon"
                                                                            onClick={() => setQuickMasterName('Branch Name')} title="Add Branch Name"></button>
                                                                    }
                                                                    Branch Name<span className="text-danger">*</span>
                                                                </label>
                                                                <select id={
                                                                    "AddressType_" + index
                                                                }
                                                                    name="AddressType"
                                                                    className="form-select"
                                                                    value={
                                                                        inputField.AddressType
                                                                    }
                                                                    onChange={
                                                                        event => handleInputChange(index, event)
                                                                    }
                                                                    style={
                                                                        {
                                                                            border: submitted && !inputField.AddressType ? '1px solid red' : ''
                                                                        }
                                                                    }>
                                                                    <option value="0">- Select Branch -</option>
                                                                    {
                                                                        branchList.map(item => (
                                                                            <option key={
                                                                                item.id
                                                                            }
                                                                                value={
                                                                                    item.id
                                                                                }>
                                                                                {
                                                                                    item.name
                                                                                } </option>
                                                                        ))
                                                                    } </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label htmlFor={
                                                                    "AddressLine1_" + index
                                                                }>

                                                                    Address Line 1
                                                                    <span className="text-danger">*</span>
                                                                </label>
                                                                <span className="input-icon icon-right">
                                                                    <textarea type="text"

                                                                        id={
                                                                            "AddressLine1_" + index
                                                                        }
                                                                        name="AddressLine1"
                                                                        placeholder="Enter Address Line 1"
                                                                        maxLength="300"
                                                                        value={
                                                                            inputField.AddressLine1
                                                                        }
                                                                        onChange={
                                                                            event => handleInputChange(index, event)
                                                                        }

                                                                        style={
                                                                            {
                                                                                height: "70px", width: "100%",
                                                                                border: submitted && !inputField.AddressLine1 ? '1px solid red' : ''
                                                                            }
                                                                        } />
                                                                </span>
                                                            </div>

                                                        </div>

                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label htmlFor={
                                                                    "AddressLine2_" + index
                                                                }>

                                                                    Address Line 2</label>
                                                                <span className="input-icon icon-right">
                                                                    <textarea type="text"
                                                                        id={
                                                                            "AddressLine2_" + index
                                                                        }
                                                                        name="AddressLine2"
                                                                        placeholder="Enter Address Line 2"
                                                                        maxLength="300"
                                                                        value={
                                                                            inputField.AddressLine2
                                                                        }
                                                                        onChange={
                                                                            event => handleInputChange(index, event)
                                                                        }
                                                                        style={
                                                                            {
                                                                                height: "70px", width: "100%",
                                                                            }}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>


                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                {/* <button type='button' className="glyphicon glyphicon-plus AddPlusIcon"
                                                                    onClick={() => setQuickMasterCity('City')} title="Add City"></button> */}

                                                                <label htmlFor={
                                                                    "City_" + index
                                                                }>
                                                                    City / State / Country<span className="text-danger">*</span>
                                                                </label>
                                                                <span className="input-icon icon-right">
                                                                    <select id={
                                                                        "City_" + index
                                                                    }
                                                                        name="City"
                                                                        className="form-select"
                                                                        value={
                                                                            inputField.City
                                                                        }
                                                                        onChange={
                                                                            event => handleInputChange(index, event)
                                                                        }
                                                                        style={
                                                                            {
                                                                                border: submitted && !inputField.City ? '1px solid red' : ''
                                                                            }
                                                                        }>
                                                                        <option value="0">- Select City -</option>
                                                                        {
                                                                            cityList.map(item => (
                                                                                <option key={
                                                                                    item.id
                                                                                }
                                                                                    value={
                                                                                        item.id
                                                                                    }>
                                                                                    {
                                                                                        item.name
                                                                                    } </option>
                                                                            ))
                                                                        } </select>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4" style={{display:'none'}}>

                                                            <div className="form-group">
                                                                <label htmlFor={
                                                                    "CompanyState_" + index
                                                                }>
                                                                    State / Country<span className="text-danger">*</span>
                                                                </label>
                                                                <span className="input-icon icon-right">
                                                                    <input type="text"
                                                                        className="form-control"
                                                                        id={"CompanyState_" + index}
                                                                        name="CompanyState"
                                                                        placeholder="State / Country"
                                                                        maxLength="50"
                                                                        value={inputField.StateName}
                                                                        disabled
                                                                        tabIndex="-1"
                                                                        style={
                                                                            {
                                                                                border: submitted && !inputField.StateName ? '1px solid red' : ''
                                                                            }}
                                                                    />
                                                                </span>
                                                            </div>

                                                            {/* <div className="col-sm-6">
                                                            <div className="form-group">
                                                                  <label htmlFor={
                                                                    "Country_" + index
                                                                }>Country<span className="text-danger">*</span>
                                                                </label>
                                                                                <span className="input-icon icon-right">
                                                                    <input type="text"
                                                                        className="form-control"
                                                                        id={"Country_" + index}
                                                                        name="Country"
                                                                        placeholder="Country"
                                                                        maxLength="50"
                                                                        value={inputField.CountryName}
                                                                        disabled
                                                                        tabIndex="-1"
                                                                        style={
                                                                            {
                                                                                border: submitted && !inputField.CountryName ? '1px solid red' : ''
                                                                            }
                                                                    } />
                                                                </span>
                                                            </div>
                                                        </div> */}
                                                        </div>

                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label htmlFor={
                                                                    "Website_" + index
                                                                }>Website</label>
                                                                <span className="info">&nbsp;(https(or)http://google.com)</span>
                                                                <span className="input-icon icon-right">
                                                                    <input type="text" className="form-control"
                                                                        id={
                                                                            "Website_" + index
                                                                        }
                                                                        name="Website"
                                                                        maxLength="50"
                                                                        placeholder="Enter Website"
                                                                        value={
                                                                            inputField.Website
                                                                        }
                                                                        onChange={
                                                                            event => handleInputChange(index, event)
                                                                        } />
                                                                    {
                                                                        // inputField.Website.includes('http:') ? <a className="weblink info" target="_blank" rel="noopener noreferrer"
                                                                        //     href={
                                                                        //         inputField.Website
                                                                        //     }>
                                                                        //     <i className="fa fa-external-link" aria-hidden="true"></i>
                                                                        // </a> :
                                                                        //     inputField.Website.includes('https:') ? <a className="weblink info" target="_blank" rel="noopener noreferrer"
                                                                        //     href={
                                                                        //         inputField.Website
                                                                        //     }>
                                                                        //     <i className="fa fa-external-link" aria-hidden="true"></i>
                                                                        // </a> : ""

                                                                        inputField.Website.includes('http://') || inputField.Website.includes('https://') ?
                                                                            <a className="weblink info" target="_blank" rel="noopener noreferrer"
                                                                                href={
                                                                                    inputField.Website
                                                                                }>
                                                                                <i className="fa fa-external-link" aria-hidden="true"></i>
                                                                            </a> : ""
                                                                    }

                                                                    {
                                                                        inputField.TestWeb !== "" && inputField.AddressInformationID > 0 ? <a className="weblink info" target="_blank" rel="noopener noreferrer"
                                                                            href={
                                                                                "/Error404"
                                                                            }>
                                                                            <i clasclassNames="fa fa-external-link" aria-hidden="true"></i>
                                                                        </a> : ""
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label htmlFor={
                                                                    "PostalCode_" + index
                                                                }>
                                                                    Postal Code<span className="text-danger">*</span>
                                                                </label>
                                                                <span className="input-icon icon-right">
                                                                    <input type="text" className="form-control"
                                                                        id={
                                                                            "PostalCode_" + index
                                                                        }
                                                                        name="PostalCode"
                                                                        placeholder="Enter Postal Code"
                                                                        maxLength="20"
                                                                        value={
                                                                            inputField.PostalCode
                                                                        }
                                                                        onChange={
                                                                            event => handleInputChange(index, event)
                                                                        }
                                                                        style={
                                                                            {
                                                                                border: submitted && !inputField.PostalCode ? '1px solid red' : ''
                                                                            }
                                                                        } />
                                                                </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label htmlFor={
                                                                    "Latitude_" + index
                                                                }>
                                                                    Latitude</label>
                                                                <span className="input-icon icon-right">
                                                                    <input type="text" className="form-control"
                                                                        id={
                                                                            "Latitude_" + index
                                                                        }
                                                                        name="Latitude"
                                                                        placeholder="Enter Latitude"
                                                                        maxLength="50"
                                                                        value={
                                                                            inputField.Latitude
                                                                        }
                                                                        onChange={
                                                                            event => handleInputChange(index, event)
                                                                        } />
                                                                    <i
                                                                        className={"fa fa-map-marker PasswordHideShow"}
                                                                        onClick={
                                                                            event => OpenMapModal(index)
                                                                        }
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label htmlFor={
                                                                    "Longitude_" + index
                                                                }>
                                                                    Longitude</label>
                                                                <span className="input-icon icon-right">
                                                                    <input type="text" className="form-control"
                                                                        id={
                                                                            "Longitude_" + index
                                                                        }
                                                                        name="Longitude"
                                                                        placeholder="Enter Longitude"
                                                                        maxLength="50"
                                                                        value={
                                                                            inputField.Longitude
                                                                        }
                                                                        onChange={
                                                                            event => handleInputChange(index, event)
                                                                        } />
                                                                    <i
                                                                        className={"fa fa-map-marker PasswordHideShow"}
                                                                        onClick={
                                                                            event => OpenMapModal(index)
                                                                        }
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label htmlFor={
                                                                    "PrimaryContactNumber_" + index
                                                                }>
                                                                    Contact Number<span className="text-danger">*</span>
                                                                </label>
                                                                <span className="input-icon icon-right">
                                                                    <input type="text" className="form-control"
                                                                        id={
                                                                            "PrimaryContactNumber_" + index
                                                                        }
                                                                        name="PrimaryContactNumber"
                                                                        placeholder="Enter Contact Number"
                                                                        maxLength="20"
                                                                        value={
                                                                            inputField.PrimaryContactNumber
                                                                        }
                                                                        onChange={
                                                                            event => handleInputChange(index, event)
                                                                        }
                                                                        onBlur={() => CheckDuplicate(index)}
                                                                        style={
                                                                            {
                                                                                border: submitted && !inputField.PrimaryContactNumber ? '1px solid red' : ''
                                                                            }
                                                                        } />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">

                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label htmlFor={
                                                                    "PrimaryEmailAddress_" + index
                                                                }>
                                                                    Email Address {/* <span className="text-danger">*</span> */} </label>
                                                                <span className="input-icon icon-right">
                                                                    <input type="email" className="form-control"
                                                                        id={
                                                                            "PrimaryEmailAddress_" + index
                                                                        }
                                                                        name="PrimaryEmailAddress"
                                                                        placeholder="Enter Email Address"
                                                                        maxLength="50"
                                                                        value={
                                                                            inputField.PrimaryEmailAddress
                                                                        }
                                                                        onChange={
                                                                            event => handleInputChange(index, event)
                                                                        }
                                                                        onBlur={() => CheckDuplicate(index)}

                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>

                                                      
                                                    </div>
                                                    {getQuickMasterName === 'Branch Name' ? <Modal show={getQuickMasterName} size="md" dragable backdrop="static" keyboard={false} onHide={() => handlePopupClose()}>
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>Add {getQuickMasterName}</Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>

                                                            {
                                                                getQuickMasterName !== '' ?
                                                                    getQuickMasterName === 'Branch Name' ?
                                                                        <BranchPopup
                                                                            branchCallback={branchCallback}
                                                                            getBranchList={branchList}
                                                                            showpopup={true}


                                                                        /> : null
                                                                    : ''
                                                            }
                                                        </Modal.Body>
                                                    </Modal>
                                                        : ''}
                                                    {getQuickMasterCity === "City" ?
                                                        <Modal show={getQuickMasterCity} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handleCityPopupClose()}>
                                                            <Modal.Header closeButton>
                                                                <Modal.Title>Add {getQuickMasterCity}</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>

                                                                {
                                                                    getQuickMasterCity === "City" ?
                                                                        <CityPopup
                                                                            cityCallback={cityCallback}
                                                                            getCityList={cityList}
                                                                            showpopup={true}
                                                                            stateList={stateList}
                                                                            countryList={countryList}
                                                                        /> : ''
                                                                }
                                                            </Modal.Body>
                                                        </Modal>
                                                        : ''}
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {
                    Buttonview !== "View" && <>
                        <div className="col-lg-1_own col-xs-12">
                            <span style={
                                {
                                    display: (inputFields.length === 1 ? "none" : "")
                                }
                            }>
                                <button className="btn btn-danger" title="Delete Address"
                                    onClick={
                                        () => handleRemoveFields(index)
                                }>
                                    <i className="fa fa-trash-o"></i>
                                </button>
                            </span>
                        </div>
                        <div className="col-lg-1_own col-xs-12">
                            <span>
                                <span style={
                                    {
                                        display: (inputFields.length === (index + 1) ? "" : "none")
                                    }
                                }>
                                    <button className="btn btn-success" title="Add Address"
                                        onClick={
                                            () => handleAddFields(index)
                                    }>
                                        <i className="fa fa-plus"></i>
                                    </button>
                                </span>

                            </span>
                        </div>
                    </>
                    } */}
                    {
                        MenuPermission && MenuPermission.isAdd === 1 &&
                        Buttonview !== "View" && <div className="col-lg-1_own col-xs-12"
                            style={
                                {
                                    display: inputField.IsDeleted === 1 ? "none" : "block"
                                }
                            } >
                            <span>
                                <span style={
                                    {
                                        display: inputField.IsDeleted === 1 ? "none" : (inputFields.filter(x => x.IsDeleted === 0).length === 1 ? "none" : "")
                                    }
                                }>
                                    {
                                        MenuPermission && MenuPermission.isDelete === 1 &&

                                        <button className="btn btn-danger" title="Delete Address"
                                            style={{ display: MenuPermission && MenuPermission.isDelete === 1 ? '' : 'none' }}
                                            onClick={
                                                () => handleRemoveFields(index)
                                            }>
                                            <i className="fa fa-trash-o"></i>
                                        </button>
                                    }
                                </span>
                            </span>
                        </div>
                    }
                </Fragment>
            ))
        }
            {
                MenuPermission && MenuPermission.isAdd === 1 &&

                Buttonview !== "View" &&
                inputFields.filter(x => x.IsDeleted === 0).map((inputField1, index) => (

                    inputFields.filter(x => x.IsDeleted === 0).length === (index + 1) && (
                        <div className="col-lg-1_own col-xs-12">
                            <span>
                                <button className="btn btn-success" title="Add Address"
                                    style={{ display: MenuPermission && MenuPermission.isAdd === 1 ? '' : 'none' }}
                                    onClick={
                                        () => handleAddFields(inputFields.filter(x => x.IsDeleted === 0).length - 1)
                                    }>
                                    <i className="fa fa-plus"></i>
                                </button>
                            </span>
                        </div>
                    )
                ))
            }
            <Modal show={getIsShowGeoLocModal} size="xl" backdrop="static" keyboard={false} onHide={() => handleGeoLocClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Pin Loctaion
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="googlemodelsize">
                    <div>
                        <GeoLocation LatLng={getCurrentLocation} parentCallback={callback} />
                    </div>
                </Modal.Body>
                <Modal.Footer>


                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CompanyAddress;