import {
    TESTSTANDARD_LOADING,
    RETRIEVE_TESTSTANDARD,
    DELETE_TESTSTANDARD,
} from "./types";

import TestStandardService from './../services/Master/TestStandardService';

const TestStandardLoading = (isStatus) => ({
    type: TESTSTANDARD_LOADING,
    payload: isStatus,
});

export const deleteTestStandard = (TestStandard) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_TESTSTANDARD,
            payload: TestStandard,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrieveTestStandard = (standardID) => async (dispatch) => {
    try {
        dispatch(TestStandardLoading(true));
        const res = await TestStandardService.GetTestStandardList(standardID);
        dispatch({
            type: RETRIEVE_TESTSTANDARD,
            payload: res.data,
        });
        dispatch(TestStandardLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(TestStandardLoading(false));
    }
};