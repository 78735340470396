import {
    RETRIEVE_ALLMENU,
    RETRIEVE_CUBEEMP,
    RETRIEVE_SUPEMP,
} from "../actions/types";
const storeMenuList = JSON.parse(localStorage.getItem("UserMenuList"));
const initialState = {
    isLoadingBranche: true,
    MenuList:storeMenuList,
    CubeList: [],
    SupList: []
};

const commonReducer = (state = initialState, action) => {
    const { type, payload } = action;

  switch (type) {
      case RETRIEVE_ALLMENU:
         return { ...state, MenuList: payload };
      case RETRIEVE_CUBEEMP:
          return { ...state, CubeList: payload };
      case RETRIEVE_SUPEMP:
          return { ...state, SupList: payload };
      default:
        return state;
    }
};
export default commonReducer;