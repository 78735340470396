import {
 TESTTEMP_LOADING,
 RETRIEVE_TESTTEMP,
 DELETE_TESTTEMP,
} from "./types";

import TestRepTemplService from "../services/Master/TestingReortTemplateService";



const TestRepTemplistsLoading = (isStatus) => ({
 type: TESTTEMP_LOADING,
 payload: isStatus,
});

export const deletetestreptemp = (tempID) => async (dispatch) => {

 try {
  dispatch({
   type: DELETE_TESTTEMP,
   payload: tempID,
  });

 } catch (err) {
  console.log(err);
 }
};

export const retrievetestreptemplist = (Operation, Id) => async (dispatch) => {

 try {
  dispatch(TestRepTemplistsLoading(true));
  const res = await TestRepTemplService.getTestReportTempDetails(Operation, Id);
  dispatch({
   type: RETRIEVE_TESTTEMP,
   payload: res.data,
  });
  dispatch(TestRepTemplistsLoading(false));
 } catch (err) {
  console.log(err);
  dispatch(TestRepTemplistsLoading(false));
 }
};