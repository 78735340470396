import React from 'react';
import { useState, useEffect, Fragment, useMemo } from "react";
import ReactNotification from 'react-notifications-component';
import { retrieveTask } from '../../../../actions/task';
import { useDispatch, useSelector } from "react-redux";
import Reactselect from 'react-select';
import { select } from 'react-validation/build/select';
import Nodify from '../../../Common/ReactNotification';
import TestService from '../../../../services/Master/TestService';
import TaskService from '../../../../services/Master/TaskService';
import AlignmentService from '../../../../services/Master/Alignment';
import SearchRetain from '../../../Common/SearchRetain';
import { retrieveAlignment } from '../../../../actions/alignment';

const AlignmentAdd = (props) => {
  let styles = {
    control: styles => ({ ...styles, border: '1px solid red', innerHeight: '100px', outerHeight: '100px' }),
  }
  let styles1 = {
    control: styles => ({ ...styles, innerHeight: '100px', outerHeight: '100px' }),
  }
  const [getTestTask, setTestTask] = useState([]);
  const [getTestAlignment, setTestAlignment] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [getExisting, setExisting] = useState([]);
  const [buttonLoader, setButtonLoader] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  //reducer
  const dispatch = useDispatch();
  const reducerState = useSelector((state) => state);
  const commentsTask = reducerState.AlignmentReducer.AlignmentList.item1;

  const [inputFields, setInputFields] = useState([{
    TaskID: 0,
    TaskIDForEdit: [],
    AlignmentOrder: '',
    AlignmentOrderForEdit: [],
    TestTypeID: 0
  }]);

  useEffect(() => {
    // dispatch(retrieveTask(0))
    TestService.GetTestNameList(1, 0, 0).then((res => {
      let TestAlignment = []
      if (res.data) {
        res.data.testNameList.map((x, index) => {
          TestAlignment.push({
            value: x.testNameID,
            id: x.testNameID,
            label: x.testName,
            name: x.testName,
            // TaskID: parseInt(x.taskID)
            TaskID: x.taskID
          })
        })
        setTestAlignment(TestAlignment);
      }
    }))

    TaskService.GetTaskDetailsList(0).then((res => {
      let TaskData = []
      if (res.data) {
        let TestingTask = res.data.filter((data => {
          if (data.taskTypeName == "Testing") {
            return data;
          }

        }

        ))

        TestingTask.map((data => {
          TaskData.push({
            value: data.taskDetailsID
            , id: data.taskDetailsID
            , label: data.taskName + " - " + data.taskOrderTypeName
            , name: data.taskName + " - " + data.taskOrderTypeName
            , taskName: data.taskName
          })

        }));
        setTestTask(TaskData);
      }

    }))


  }, [])


  function handleSelect(event, FieldName, index) {
   
    let value = [...inputFields];

    if (event !== null) {

      if (event.taskName === "Trims Test") {
        value[index].TestTypeID = 5
      }
      else if (event.taskName === "Strike off Test") {
        value[index].TestTypeID = 4
      }
      else if (event.taskName === "FABRIC TEST") {
        value[index].TestTypeID = 1
      }
      else if (event.taskName === "YARN TEST") {
        value[index].TestTypeID = 2
      }
      else if (event.taskName === "GARMENT TEST") {
        value[index].TestTypeID = 3
      }

      if (FieldName === "TaskID") {
        if (event != null) {
          value[index].TaskID = event.value;
          value[index].TaskIDForEdit = event;
        }
        else {
          value[index].TaskID = 0;
          value[index].TaskIDForEdit = [];
          value[index].AlignmentOrder = "";
          value[index].AlignmentOrderForEdit = [];
        }

      }
      else if (FieldName === 'AlignmentOrder') {
        let Testalignment = "";
        for (let Alignment of event) {
          if (Testalignment === "") {
            Testalignment = Alignment.value.toString()
          } else {
            Testalignment = Testalignment + "," + Alignment.value;
          }
        }
        value[index].AlignmentOrder = Testalignment
        value[index].AlignmentOrderForEdit = event;
      }
    }
    else {
      if (FieldName === "TaskID") {
        value[index].TaskID = 0;
        value[index].TaskIDForEdit = [];
      }
    }



    setInputFields(value);
  }

  const AddChildGrid = (index, subindex) => {
    setSubmitted(true);
    const values = [...inputFields];
    if ((!values[index].TaskID || !values[index].AlignmentOrder)) {
      Nodify('Warning!', 'warning', 'Please fill all mandatory(*) fields.');
    } else {
      values.push({
        TaskID: 0,
        TaskIDForEdit: [],
        AlignmentOrder: '',
        AlignmentOrderForEdit: [],
        TestTypeID: 0
      });
      setSubmitted(false);
      setInputFields(values);
    }
  }

  const RemoveChildGrid = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    // values.splice(index, 1);
    setInputFields(values);
  }


  const ResetOperation = (e) => {
    if (e === "Back") {
      props.history.push('/AlignmentList')
    } else {
      window.location.reload();
    }
  }

  const SaveMappingMaster = (e) => {
    setSubmitted(true);
    const values = [...inputFields];
    setButtonLoader(true);
    e.preventDefault();
    for (var i = 0; i < values.length; i++) {
      if ((values[i].TaskID === 0 || !values[i].AlignmentOrder)) {
        Nodify('Warning!', 'warning', 'Please fill all mandatory(*) fields.');
        return false;
      }
      if (values.length != 0) {

      }
    }
    let AlignmentDetails = values.map(alignment => {
      return {
        ID: 0
        , TaskID: alignment.TaskID
        , AlignmentOrder: alignment.AlignmentOrder
        , TestTypeID: alignment.TestTypeID
      }
    })
    let Params = {
      ID: 0
      , AlignmentDetails: AlignmentDetails
      , CreatedBy: currentUser.employeeinformationID
    }
    AlignmentService.InsertAlignmentMaster(Params).then(response => {
      setSubmitted(false);
      var page = "Remove";
      SearchRetain(page);
      let Func = 'Add';
      if (response.data.outputResult === "1") {
        Func = 'Add';
        PageRedirect(Func);
      }
      else if (response.data.outputResult === "0") {
        setButtonLoader(false);
        ValidationPopup("Error Occured!");
      }
    }).catch(err => {
      setSubmitted(false);
    })
  }

  const ValidationPopup = (Msg) => {
    setButtonLoader(false);
    Nodify('Warning!', 'warning', Msg);
    return false;
  }

  const CheckDuplicate = (index, Field) => {
    const values = [...inputFields];
    let isExisting = !!getExisting.find((d, dindex) => d.taskID === values[index].TaskID);
    if (isExisting) {
      setSubmitted(true);
      values[index].TaskID = '';
      values[index].TaskIDForEdit = [];
      setInputFields(values);
      Nodify('Warning!', 'warning', "This Alignment is already exist.");
      return false;
    }
    else {
      setSubmitted(false);
    }
    for (var j = 0; j < (inputFields.length); j++) {
      if (j !== index) {
        if (values[j].TaskID === values[index].TaskID) {
          values[index].TaskID = 0;
          values[index].TaskIDForEdit = 0;
          values[index].AlignmentOrder = "";
          values[index].AlignmentOrderForEdit = [];
          setInputFields(values);
          Nodify('Warning!', 'warning', 'This Test Field is already exist.');
          setSubmitted(true);
          return false;
        }
      }
    }
  }

  function find_duplicate_in_array(arra1) {
    var object = {};
    var result = [];

    arra1.forEach(function (item) {
      if (!object[item])
        object[item] = 0;
      object[item] += 1;
    })

    for (var prop in object) {
      if (object[prop] >= 2) {
        result.push(prop);
      }
    }
    return result;
  }

  const PageRedirect = (Func) => {
    props.history.push({ pathname: "/AlignmentList", state: { message: Func } });
  }

  useEffect(() => {
    dispatch(retrieveAlignment());
  }, [dispatch]);


  useEffect(() => {
    if (commentsTask != undefined && commentsTask.length > 0) {
      setExisting(commentsTask);
    }
  }, [commentsTask]);

  return (
    <>
      <div className="page-body">
        <ReactNotification />
        <div className="row">
          <div className="col-lg-12 col-sm-12 col-xs-12">
            <div className="widget flat radius-bordered">
              <div className="widget-header heading_box_style">
                <h3 className="heading_style_common">Add Test Details Appearance Alignment </h3>
              </div>
              <div className="widget-body">
                <div id="registration-form">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-lg-12">
                          <form>
                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                              <thead>
                                <tr>
                                  <th style={{ width: '25%' }}>Test Task Name<span className='text-danger'>*</span></th>

                                  <th style={{ width: '25%' }}>Testing Appearance Alignment Order<span className='text-danger'>*</span></th>

                                  <th style={{ width: '25%' }}>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  inputFields.map((inputfield, index) => (
                                    <>
                                      <tr>
                                        <td>
                                          <Reactselect className="basic-single"
                                            name={"ddlTestTask"}
                                            id={"ddlTestTask"}
                                            isDisabled={false}
                                            isLoading={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            ///isMulti
                                            menuPosition="fixed"
                                            onBlur={() => CheckDuplicate(index, 'TaskID')}
                                            onChange={event => handleSelect(event, 'TaskID', index)}
                                            options={getTestTask}
                                            value={inputfield.TaskIDForEdit}
                                            styles={submitted && inputfield.TaskIDForEdit == "" ? styles : styles1}
                                          ></Reactselect>
                                        </td>
                                        <td>
                                          <Reactselect
                                            // options={getTestAlignment.filter(x => x.TaskID === inputfield.TaskID)}
                                            options={getTestAlignment.filter(x => {
                                              return x.TaskID.split(',').includes(inputfield.TaskID.toString())
                                            })}
                                            placeholder="Select Order"
                                            value={inputfield.AlignmentOrderForEdit}
                                            onBlur={() => CheckDuplicate(index, 'AlignmentOrder')}
                                            onChange={event => handleSelect(event, 'AlignmentOrder', index)}
                                            isMulti
                                            styles={submitted && inputfield.AlignmentOrderForEdit == "" ? styles : styles1}
                                          />

                                        </td>
                                        <td>
                                          <div>
                                            {inputFields.length === (index + 1) && <button type="button"
                                              className="btn btn-xs btn-success" title="Add Test Name" onClick={event => AddChildGrid(index)}>
                                              <i className="fa fa-plus"></i>
                                            </button>}{inputFields.length !== 1 &&
                                              <button type="button" style={{ marginLeft: '5px' }}
                                                className="btn btn-xs btn-danger" title="Remove Test Name" onClick={event => { RemoveChildGrid(index) }}>
                                                <i className="fa fa-trash"></i>
                                              </button>}
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  ))
                                }
                              </tbody>
                            </table>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12" style={{ marginTop: '10px' }}>
                    <div className="buttons-preview" align="right">
                      <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                        <i className="fa fa-arrow-circle-left"></i>
                        &nbsp;Back
                      </span>
                      <span className="btn btn-danger" onClick={() => ResetOperation()}>
                        <i className="fa fa-times"></i>
                        &nbsp;Reset
                      </span>
                      <button type="button" className="btn btn-success" onClick={SaveMappingMaster}>
                        <i className="fa fa-check right"></i>&nbsp;
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AlignmentAdd