import {
    BUYERINFO_LOADING,
    RETRIEVEINFO_BUYER,
    DELETEINFO_BUYER,
} from "../actions/types";

const initialState = {
    isLoadingBuyerinfo: true,
    buyerinfoList: [],
};

const buyerinfoReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case BUYERINFO_LOADING:
            return {
                ...state,
                isLoadingBuyerinfo: payload
            };
        case RETRIEVEINFO_BUYER:
            return { ...state, buyerinfoList: payload };
        case DELETEINFO_BUYER:
            const results = state.buyerinfoList.filter(c => c.buyerMapItemID !== payload.buyerMapItemID);
            return {
                ...state,
                buyerinfoList: results
            };

        default:
            return state;
    }
};
export default buyerinfoReducer;