import axios from "axios";
import authHeader from "../auth-header";

const LoadAgencyDetails = () => {
    return axios({
        method: 'get',
        url: window.$APIBaseURL + 'Master/AgencyList',
        headers: {
            authorization: authHeader()
        }
    });
}

const InsertAgencyDetails = (data) => {
    return axios({
        method: 'post',
        url: window.$APIBaseURL + 'Master/InsertAgencyInfo',
        data: data,
        headers: {
            authorization: authHeader()
        }
    });
}

const UpdateAgencyDetails = (data) => {
    return axios({
        method: 'post',
        url: window.$APIBaseURL + 'Master/UpdateAgencyInfo',
        data: data,
        headers: {
            authorization: authHeader()
        }
    });
}

const agencyService = {
    LoadAgencyDetails,
    InsertAgencyDetails,
    UpdateAgencyDetails
};

export default agencyService;