import React, { useState, useEffect } from 'react';
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import { Button } from "react-bootstrap";
import CategoryService from "../../services/Master/CategoryService";
import Nodify from "../Common/ReactNotification";
import { useSelector } from "react-redux";

const CategoryPopup = ({
    getCategoryList,
    categoryCallback,
    showpopup,
    GroupTypeID,
    GroupTypeName
}) => {
    const { user: currentUser } = useSelector((state) => state.auth);

    const EmptyCategoryInputFields =
        [{
            CategoryID: 0,
            GroupTypeID: GroupTypeID,
            CategoryName: '',
            CategoryTypeID: 0
        }]

    const [buttonLoader, setButtonLoader] = useState(false);
    const [categoryInputFields, setCategoryInputFields] = useState(EmptyCategoryInputFields);
    const [categoryList, setCategoryList] = useState([]);
    const [getCategoryTypeList, setCategoryTypeList] = useState([]);
    const [CategorySubmitted, setCategorySubmitted] = useState(false);

    useEffect(() => {
        CategoryService.LoadCategoryTypeList().then((response) => {
            if (response.data) {
                setCategoryTypeList(response.data);
            }
        }).catch(() => { });

    }, []);

    const handlePopupClose = () => {
        categoryCallback(false);
    };

    const CheckDuplicate = (index, Field) => {
        const values = [...categoryInputFields];
        if (getCategoryList) {
            if (values[index].CategoryName !== '' && values[index].GroupTypeID !== 0 && values[index].CategoryTypeID !== 0) { // for avoid empty validation
                for (var i = 0; i < getCategoryList.length; i++) {
                    if (getCategoryList[i].groupTypeID === values[index].GroupTypeID &&
                        getCategoryList[i].categoryTypeID === values[index].CategoryTypeID &&
                        getCategoryList[i].name.trim().toLowerCase() === values[index].CategoryName.trim().toLowerCase()) {
                        values[index].CategoryTypeID = 0;
                        values[index].CategoryName = '';
                        setCategoryInputFields(values);
                        setCategoryList({ Createdby: currentUser.employeeinformationID, CategoryInformation: values });
                        Nodify('Warning!', 'warning', "This Category Information is already exist.");
                        return false;
                    }
                }
            }
        }
    }
    const handleInputChange = (index, event) => {
        const values = [...categoryInputFields];
        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }
        if (event.target.name === "GroupTypeID") {
            values[index].GroupTypeID = parseInt(inputText);
        } else if (event.target.name === "CategoryName") {
            values[index].CategoryName = inputText;
        } else if (event.target.name === "CategoryTypeID") {
            values[index].CategoryTypeID = parseInt(inputText);
        }
        setCategoryInputFields(values);
        setCategoryList({ Createdby: currentUser.employeeinformationID, CategoryInformation: values });
    }


    const SaveCategory = (e) => {
        setButtonLoader(true);
        e.preventDefault();
        const values = [...categoryInputFields];
        if (values[0].CategoryName.trim() === '' && values[0].CategoryTypeID === 0) {
            setCategorySubmitted(false);
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Category name and Category Type is required.');
        }
        else if (values[0].CategoryName.trim() === '') {
            setCategorySubmitted(false);
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Category name is required.');
        }
        else if (values[0].CategoryTypeID === 0) {
            setCategorySubmitted(false);
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Category Type is required.');
        }
        if (values[0].CategoryName.trim() && values[0].CategoryTypeID) {
            CategoryService.InsertUpdateCategoryGrid(categoryList).then(res => {
                if (res.data.outputResult === "1") {
                    Nodify("Success!", "success", "Category added successfully.");
                    categoryCallback(true);
                    // groupTypeCallback(true);
                } else if (res.data.outputResult === "-2") {

                    Nodify('Warning!', 'warning', 'This name is already exist.');
                }
                setButtonLoader(false);
            });
        }
        else {
            setCategorySubmitted(true);
        }
    }

    return (
        // <Modal show={showpopup} size="md" dragable backdrop="static" keyboard={false} onHide={() => handlePopupClose()}>
        //     <Modal.Header closeButton>
        //         <Modal.Title>Group Type Name : {GroupTypeName}</Modal.Title>
        //     </Modal.Header>
        //     <Modal.Body>
        <div id="registration-form">
            <Form>
                <div className="row">
                    <div className="form-group col-sm-12">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="CategoryName">
                                    Category Name
                                    <span className="text-danger">*</span>
                                </label>
                                <Input
                                    placeholder={"Enter Category Name"}
                                    id="CategoryName"
                                    name="CategoryName"
                                    type="text"
                                    maxLength="50"
                                    value={categoryInputFields[0].CategoryName}
                                    autoComplete="off"
                                    onChange={event => handleInputChange(0, event)}
                                    className='form-control'
                                    onBlur={() => CheckDuplicate(0, 'CategoryName')}
                                    style={{ border: CategorySubmitted && !categoryInputFields[0].CategoryName ? '1px solid red' : '' }}
                                    autoFocus
                                />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="CategoryTypeID">
                                    Category Type
                                    <span className="text-danger">*</span>
                                </label>
                                <select id={
                                    "CategoryTypeID"
                                }
                                    name="CategoryTypeID"
                                    className="form-select"
                                    value={
                                        categoryInputFields[0].CategoryTypeID
                                    }
                                    onChange={event => handleInputChange(0, event)}
                                    onBlur={() => CheckDuplicate(0, 'CategoryTypeID')}
                                    style={{ border: CategorySubmitted && categoryInputFields[0].CategoryTypeID === 0 ? '1px solid red' : '' }}
                                >
                                    <option value='0'>- Select Category Type -</option>
                                    {
                                        getCategoryTypeList.map(item => (
                                            <option key={
                                                item.id
                                            }
                                                value={
                                                    item.id
                                                }>
                                                {
                                                    item.name
                                                } </option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <br />
                <div style={{ textAlign: "right" }}>
                    <Button type="button" className="btn btn-success" onClick={SaveCategory} disabled={buttonLoader}>
                        <i className="fa fa-check right"></i> &nbsp; Save
                    </Button>
                </div>
            </Form>
        </div>
        //     </Modal.Body>
        //     <Modal.Footer>
        //         <Button type="submit" className="btn btn-success" onClick={SaveGroupType} disabled={buttonLoader}>
        //             <i className="fa fa-check right"></i> &nbsp; Save
        //         </Button>
        //     </Modal.Footer>
        // </Modal>
    )
};

export default CategoryPopup;