import {
    ORDER_TRIM_LOADING,
    ORDER_TRIM_RELOADING,
    RETRIEVE_ORDER_TRIM,
    DELETE_ORDER_TRIM,
    RETRIEVE_ORDER_TRIM_DETAIL_VIEW_LIST,
    CLEAR_ORDER_TRIM_DETAIL_VIEW_SUB_ROW,
    RETRIEVE_ORDER_TRIM_SUBMISSION_LIST,
    TNA_TASK_ORDER_TRIM_SUBMISSION_AND_APPROVAL,
    CLEAR_ORDER_TRIM_SUBMISSION_SUB_ROW,
    RETRIEVE_ORDER_TRIM_APPROVAL_LIST,
    CLEAR_ORDER_TRIM_APPROVAL_SUB_ROW,
    CLEAR_ORDER_TRIM_APPROVAL_VIEW_SUB_ROW,
    RETRIEVE_ORDER_TRIM_APPROVAL_VIEW_LIST
} from "../actions/types";
const OLDStatusList = [
    { value: 1, label: 'To Do', isdisabled: false },
    { value: 2, label: 'Requested', isdisabled: false },
    { value: 3, label: 'Submitted', isdisabled: false },
    { value: 4, label: 'Approved', isdisabled: false }
];
const OLDRequestStatusList = [

    { value: 1, label: 'Pending', isdisabled: false },
    { value: 2, label: 'Completed', isdisabled: false },
];
const initialState = {
    isLoadingOrderTrim: true,
    isReLoadingOrderTrim: true,
    orderTrimList: [],
    orderTrimViewDetailParams: {}, //**orderLabdipViewDetail**
    orderTrimViewDetailList: [], //OrderLabDipViewDetail-(GLDVD)
    orderTrimSubmissionList: [],
    orderTrimSubmissionParams: {},
    orderTrimApprovalList: [],
    orderTrimApprovalParams: {},
    orderTrimApprovalViewList: [],
    orderTrimApprovalViewParams: {},
    OLDStatusList: OLDStatusList,
    OLDRequestStatusList: OLDRequestStatusList,
    // GLDVDPantoneSubList: [],
    // GLDVDCriticalList: [],
};

const orderTrimReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case ORDER_TRIM_LOADING:
            return {
                ...state,
                isLoadingOrderTrim: payload
            };
        case ORDER_TRIM_RELOADING:
            return {
                ...state,
                isReLoadingOrderTrim: payload
            };
        case RETRIEVE_ORDER_TRIM:
            return { ...state, orderTrimList: payload };
        case DELETE_ORDER_TRIM:
            const results = state.orderTrimList.filter(c => c.genTrimId !== payload.genTrimId);
            return {
                ...state,
                orderTrimList: results
            };
        // case RETRIEVE_ORDER_TRIM_DETAIL_VIEW_LIST:

        //     if (payload.params.PantoneId === 0) {

        //         return {
        //             ...state,
        //             orderTrimViewDetailList: payload.data.pantoneCodeList,
        //             orderTrimViewDetailParams: payload.params
        //         };
        //     } else {

        //         let index = payload.params.Index;
        //         let resultSet = payload.data;
        //         //OrderLabDipViewDetail-(GLDVD)
        //         let GLDVDPantoneCodeList = [...state.orderTrimViewDetailList];


        //         GLDVDPantoneCodeList[index].pantoneSubList = resultSet.pantoneSubList ? resultSet.pantoneSubList : [];
        //         GLDVDPantoneCodeList[index].criticalList = resultSet.criticalList ? resultSet.criticalList : [];

        //         return {
        //             ...state,
        //             orderTrimViewDetailList: GLDVDPantoneCodeList,
        //             orderTrimViewDetailParams: payload.params
        //         };
        //     }

        //Trim Details
        case RETRIEVE_ORDER_TRIM_DETAIL_VIEW_LIST:

            if (payload.params.TrimId === 0) {

                return {
                    ...state,
                    orderTrimViewDetailList: payload.data.trimList,
                    orderTrimViewDetailParams: payload.params
                };
            } else {

                let index = payload.params.Index;
                let resultSet = payload.data;
                //GeneralTrimViewDetail-(GSOVD)
                let GSOVDTrimList = [...state.orderTrimViewDetailList];
                GSOVDTrimList[index].trimReferenceList = resultSet.trimReferenceList ? resultSet.trimReferenceList : [];
                GSOVDTrimList[index].manualCommentList = resultSet.manualCommentList ? resultSet.manualCommentList : [];
                GSOVDTrimList[index].manualImageList = resultSet.manualImageList ? resultSet.manualImageList : [];
                GSOVDTrimList[index].existingList = resultSet.existingList ? resultSet.existingList : [];
                // GSOVDTrimList[index].criticalList = resultSet.criticalList ? resultSet.criticalList : [];

                return {
                    ...state,
                    orderTrimViewDetailList: GSOVDTrimList,
                    orderTrimViewDetailParams: payload.params
                };
            }
        //submission
        case RETRIEVE_ORDER_TRIM_APPROVAL_LIST:
            if (payload.params.TrimId === 0) {
                return {
                    ...state,
                    orderTrimApprovalList: payload.data.trimList,
                    orderTrimApprovalParams: payload.params
                };
            } else {

                let index = payload.params.Index;
                let resultSet = payload.data;

                let GSOVDTrimList = [...state.orderTrimApprovalList];

                GSOVDTrimList[index].trimSubmitApprovalReferenceList = resultSet.trimSubmitApprovalReferenceList ? resultSet.trimSubmitApprovalReferenceList : [];
                // GSOVDTrimList[index].criticalList = resultSet.criticalList ? resultSet.criticalList : [];

                return {
                    ...state,
                    orderTrimApprovalList: GSOVDTrimList,
                    orderTrimApprovalParams: payload.params
                };
            }
        case CLEAR_ORDER_TRIM_APPROVAL_SUB_ROW:
            let ApprIndex = payload;
            let GSOA_TrimList = [...state.orderTrimApprovalList];
            GSOA_TrimList[ApprIndex].trimSubmitApprovalReferenceList = [];
            // GSOVDRefernceList[index].criticalList = [];
            return {
                ...state,
                orderTrimApprovalList: GSOA_TrimList
            };
        //request Tab
        case RETRIEVE_ORDER_TRIM_SUBMISSION_LIST:
            if (payload.params.TrimId === 0) {

                return {
                    ...state,
                    orderTrimSubmissionList: payload.data.trimList,
                    orderTrimSubmissionParams: payload.params
                };
            } else {

                let index = payload.params.Index;
                let resultSet = payload.data;
                //GeneralTrimViewDetail-(GSOVD)
                let GSOVDTrimList = [...state.orderTrimSubmissionList];

                GSOVDTrimList[index].trimSubmitApprovalReferenceList = resultSet.trimSubmitApprovalReferenceList ? resultSet.trimSubmitApprovalReferenceList : [];
                // GSOVDTrimList[index].criticalList = resultSet.criticalList ? resultSet.criticalList : [];

                return {
                    ...state,
                    orderTrimSubmissionList: GSOVDTrimList,
                    orderTrimSubmissionParams: payload.params
                };
            }
        case TNA_TASK_ORDER_TRIM_SUBMISSION_AND_APPROVAL:
            return {
                ...state,
                editTNATaskTrimSubmissionList: payload
            };
        case CLEAR_ORDER_TRIM_SUBMISSION_SUB_ROW:

            let indexVal = payload;
            //GeneralTrimViewDetail-(GSOVD)
            let GSOVD_TrimList = [...state.orderTrimSubmissionList];
            GSOVD_TrimList[indexVal].trimSubmitApprovalReferenceList = [];
            // GSOVDRefernceList[index].criticalList = [];

            return {
                ...state,
                orderTrimSubmissionList: GSOVD_TrimList
            };
        //end Request
        //approval 
        case RETRIEVE_ORDER_TRIM_APPROVAL_VIEW_LIST:
            if (payload.params.TrimId === 0) {
                return {
                    ...state,
                    orderTrimApprovalViewList: payload.data.trimList,
                    orderTrimApprovalViewParams: payload.params
                };
            } else {

                let index = payload.params.Index;
                let resultSet = payload.data;

                let GSOVDTrimList = [...state.orderTrimApprovalViewList];

                GSOVDTrimList[index].trimSubmitApprovalReferenceList = resultSet.trimSubmitApprovalReferenceList ? resultSet.trimSubmitApprovalReferenceList : [];
                // GSOVDTrimList[index].criticalList = resultSet.criticalList ? resultSet.criticalList : [];

                return {
                    ...state,
                    orderTrimApprovalViewList: GSOVDTrimList,
                    orderTrimApprovalViewParams: payload.params
                };
            }
        case CLEAR_ORDER_TRIM_APPROVAL_VIEW_SUB_ROW:

            let ViewIndex = payload;
            let GSOVA_TrimList = [...state.orderTrimApprovalViewList];
            GSOVA_TrimList[ViewIndex].trimSubmitApprovalReferenceList = [];
            // GSOVDRefernceList[index].criticalList = [];
            return {
                ...state,
                orderTrimApprovalViewList: GSOVA_TrimList
            };
        //approval end
        case CLEAR_ORDER_TRIM_DETAIL_VIEW_SUB_ROW:
            let index = payload;
            //OrderLabDipViewDetail-(GLDVD)
            let GLDVDPantoneCodeList = [...state.orderTrimViewDetailList];
            GLDVDPantoneCodeList[index].trimReferenceList = [];
            GLDVDPantoneCodeList[index].criticalList = [];
            GLDVDPantoneCodeList[index].manualImageList = [];
            return {
                ...state,
                orderTrimViewDetailList: GLDVDPantoneCodeList
            };

        default:
            return state;
    }
};
export default orderTrimReducer;