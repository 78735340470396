import React, { useState, useEffect, Fragment } from "react";
import 'react-notifications-component/dist/theme.css';
import Form from "react-validation/build/form";
import Nodify from "../../Common/ReactNotification";
import Reactselect from 'react-select';
import CategoryService from "../../../services/Master/CategoryService";
import MeasurementService from "../../../services/Style/measurementService";
import $ from "jquery";
import CommonLoader from "../../Common/CommonLoader";
import readXlsxFile from 'read-excel-file'
import { useSelector } from "react-redux";
import MeasurementPointPopup from "../../quickMaster/_MeasurementPoint";
import { Modal, Button } from "react-bootstrap";
import Select from "react-validation/build/select";
//import Select from 'react-select';
import Input from "react-validation/build/input";
import validator from 'validator'
import styleService from "../../../services//Style/styleService";

const SampleReqAdd = ({ props, SampletreqList, SamplereqCallback, SKUDetails, setSizeListmeasurement, IsValidationCallback, ExistingSamplereq, SampleInfo }) => {
    const [inputFields, setInputFields] = useState([
        {
            StyleSampleRequestID: 0, SampleRequestID: 0, SkuColorNameIds: '', SizeIdQtyIds: '', SizeandQty: '', SkuColorNameIdsEdit: ''
        }
    ]);

    const [getsizeqty, setsizeqty] = useState([]);
    const [getCurrentIndex, setCurrentIndex] = useState();
    const [getsampleName, setsampleName] = useState();
    const [submitted, setSubmitted] = useState(false);
    const [getHeader, setHeader] = useState("Add");
    const [submittedpopup, setSubmittedpopup] = useState(false);
    const [getSkulist, setSkulist] = useState([]);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [ButtonName, setButtonName] = useState("Save");
   ;
    const values = [...inputFields];
    const [getmodalpopup, setmodalpopup] = useState(false);
    const [getSampleList, setSampleList] = useState([]);
    const [getExistinglist, setExistinglist] = useState([]);
    const [getGradingSize, setGradingSize] = useState([]);
    const [getNewSreqID, setNewSreqID] = useState([]);

    const [CantAddRecords, setCantAddRecords] = useState(false);
    //const[getSample,setSample]=useState();

    let styles1 = {
        control: styles1 => ({ ...styles1, width: "350px" }),
    }

    let styles2 = {
        control: styles2 => ({ ...styles2, width: "350px", border: '1px solid red' }),
    }
    let ExistingSampleReqId = 0;

    // If check production or sampling
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProduction = activeMenus.IsProduction;
    // End

    //sathish
    useEffect(() => {
       ;
        var styleid = props.location.state.params.styleID;

        styleService.GetSamplereqList(1, styleid, 0).then((response) => {
            if (response.data.length > 0) {
                setExistinglist(response.data);
                if (response.data.length === 6) {
                    setCantAddRecords(true);
                }
            }
            else {
                setCantAddRecords(false);
            }
        });

        styleService.LoadSampleRequestList(IsProduction).then((response) => {
            if (response.data) {
                setSampleList(response.data);
            }
        }).catch(() => { });

        // styleService.GetSamplereqList(1, styleid, 0).then((response) => {
        //     if (response.data) {
        //         setExistinglist(response.data);

        //         if (response.data.length === 1) {
        //             ExistingSampleReqId = response.data[0].sampleRequestID;
        //             styleService.LoadSampleRequestList().then((response) => {
        //                 if (response.data) {
        //                     setSampleList(response.data);
        //                     if (SampletreqList === undefined) {
        //                         //  var GetSampleReqId = response.data.filter(x => x.value !== ExistingSampleReqId)[0].value;
        //                         // values[0].SampleRequestID = GetSampleReqId;
        //                         setInputFields(values);
        //                         SamplereqCallback(values);
        //                     }

        //                 }
        //             }).catch(() => { });
        //         }
        //         else {
        //             if (SampletreqList === undefined || getExistinglist.length === 6) {
        //                 // setCantAddRecords(true);
        //             }

        //             styleService.LoadSampleRequestList().then((response) => {
        //                 if (response.data) {
        //                     setSampleList(response.data);
        //                     if (SampletreqList === undefined) {
        //                         //  var GetSampleReqId = response.data[0].value;
        //                         //   values[0].SampleRequestID = GetSampleReqId;
        //                         setInputFields(values);
        //                         SamplereqCallback(values);
        //                     }
        //                 }
        //             }).catch(() => { });

        //         }

        //         if (response.data.length === 0) {
        //             setCantAddRecords(false);
        //         }

        //     }
        //     else {
        //         setCantAddRecords(false);
        //     }
        // });



       ;
        var intialcolor = { label: "Any Color", value: 0 };
        if (SKUDetails.length > 0) {
            if (SKUDetails[0].value !== 0) { SKUDetails.splice(0, 0, intialcolor); }
        } else { SKUDetails.splice(0, 0, intialcolor); }
        setSkulist(SKUDetails);
        var intialsize = { label: "Any Size", value: 0 };
        //   getsizeqty[0].Sizename = "Any Size";
        if (setSizeListmeasurement.length > 0) {
            if (setSizeListmeasurement[0].value !== 0) { setSizeListmeasurement.splice(0, 0, intialsize); }
        } else { setSizeListmeasurement.splice(0, 0, intialsize); }
        setGradingSize(setSizeListmeasurement);

        setSizeListmeasurement.forEach(element => {
            getsizeqty.push({ Size: element.value, Qty: '', Sizename: element.label })
        })

        if (SampletreqList !== undefined && SampletreqList.styleSampleRequestID !== undefined) {
            setHeader("Edit");
            setCantAddRecords(false);
            styleService.GetSamplereqList(1, 0, SampletreqList.styleSampleRequestID).then((response) => {
                if (response.data) {
                    values[0].SampleRequestID = response.data[0].sampleRequestID;
                    values[0].StyleSampleRequestID = response.data[0].styleSampleRequestID;
                    values[0].SizeIdQtyIds = response.data[0].sizeIdQtyIds;
                    values[0].SizeandQty = response.data[0].sizeNamesandQty;
                    values[0].SkuColorNameIds = response.data[0].skuColorNameIds;
                    let arr = response.data[0].skuColorNameIds.split(',');
                    let arrVal = [];
                    for (let size of arr) {
                        let res = SKUDetails.filter(x => x.value === parseInt(size));
                        arrVal.push({
                            value: res[0].value,
                            label: res[0].label
                        });
                    }
                    values[0].SkuColorNameIdsEdit = arrVal;
                    setInputFields(values);
                    SamplereqCallback(values);
                }
            });




            styleService.GetSizeqtyListStylerequest(2, 0, SampletreqList.styleSampleRequestID).then((response) => {
                if (response.data) {

                    var data = response.data;
                    // setSizeListmeasurement.forEach(element => {
                    //     getsizeqty.push({ Size: element.value, Qty: '', Sizename: element.label })
                    // })

                    // data.forEach(element => {
                    //     element.Size = element.sizeId
                    //     element.Qty = element.qty
                    //     element.Sizename = element.sizeName
                    // });
                    let index;
                    data.forEach(element => {
                        const index = getsizeqty.findIndex(obj => obj.Sizename === element.sizeName);
                        getsizeqty[index].Qty = element.qty;
                    });
                    setsizeqty(getsizeqty);

                }
            });

        }


    }, []);



    useEffect(() => {
        setSubmitted(IsValidationCallback);
    }, [IsValidationCallback === true])




    let DynamicClassName = "col-lg-12 col-sm-12 col-xs-12";





    const handlepopupchange = (index, event, Feildname) => {
        const sizeqty = [...getsizeqty]
        // if (event.target.value.trim() !== '') {
        //     inputText = event.target.value;
        // }
        if (Feildname === "SizeId") {
            if (event) {
                sizeqty[index].Size = event.value;
                sizeqty[index].Sizename = event.label;
            }
            else {
                sizeqty[index].Size = "";
                sizeqty[index].Sizename = "";
            }

        } else {
            // var re1 = /^([0-9]+[\.]?[0-9]?[0-9]?|[0-9]+)/g;
            // let val = re1.exec(event.target.value);
            // sizeqty[index].Qty = "";
            // if (val !== null && event.target.value !== "") {
            //     sizeqty[index].Qty = val[0];
            // }
            sizeqty[index].Qty = event.target.value.replace(/\D/g, '');
        }
        sizeqty[index].ParentIndex = getCurrentIndex;
        setSubmittedpopup(false);
        setsizeqty(sizeqty);
        setmodalpopup(true);
    }

    function RestrictFirstZero(e) {
       ;
        if (e.target.value.length == 0 && e.which == 48) {
            e.preventDefault();
            return false;
        }
    };

    const handlePopupClose = () => {
        setmodalpopup(false);
    }

    const controlOverflow = () => {

    }
    const openpopup = (index, SampleRequestID) => {
        let value = [...getsizeqty]

        var SplitbyComma = [];
        var SplitbyHypen = [];
        var Sizename = '';

        if (values[index].SizeandQty !== '') {
            value.forEach(element => {
                element.Qty = ''
            })
            SplitbyComma = values[index].SizeandQty.split(",");
            SplitbyComma.forEach((element, index) => {
                SplitbyHypen.push({ Sizename: SplitbyComma[index].split("-") })
            })

            SplitbyHypen.forEach(element => {
                if (element.Sizename.length === 2) {
                    const rowindex = getsizeqty.findIndex(obj => obj.Sizename === element.Sizename[0].trim());
                    getsizeqty[rowindex].Qty = element.Sizename[1].trim();
                }
                else {
                    for (var i = 0; i <= element.Sizename.length - 2; i++) {
                        if (i !== element.Sizename.length - 2) {
                            Sizename = Sizename + element.Sizename[i] + "-"
                        }
                        else {
                            Sizename = Sizename + element.Sizename[i]

                        }

                    }
                    const rowindex = getsizeqty.findIndex(obj => obj.Sizename === Sizename.trim());
                    getsizeqty[rowindex].Qty = element.Sizename[element.Sizename.length - 1].trim();
                    Sizename = '';
                    // Sizename = Sizename.trimEnd('-')
                }

            });
            setsizeqty(getsizeqty);
        }
        else {
            value.forEach(element => {
                element.Qty = ''
            })
        }

        // const index = getsizeqty.findIndex(obj => obj.Sizename === element.sizeName);
        let name = SampleRequestID === 1 ? 'Proto Sample' : 'Fit Sample';
        setsampleName(name)
        setCurrentIndex(index);
        setmodalpopup(true);
    }
    const savesizeqty = (e, index) => {
        var isvalid = true;
        var EnteredCount = 0;

        getsizeqty.forEach((element, index) => {
            if (getsizeqty[index].Qty !== '') {
                EnteredCount = EnteredCount + 1;
            }
        })

        if (EnteredCount !== 0) {

            let sizeandqtyfordisplay = "";
            let sizeandqtyfordb = "";
            getsizeqty.filter(x => x.Qty !== '').map((item) => {
                if (sizeandqtyfordb === "") {
                    sizeandqtyfordb = item.Size + "-" + item.Qty;
                } else {
                    sizeandqtyfordb = sizeandqtyfordb + "," + item.Size + "-" + item.Qty;
                }

            });
            getsizeqty.filter(x => x.Qty !== '').map((item) => {
                if (sizeandqtyfordisplay === "") {
                    sizeandqtyfordisplay = item.Sizename + "-" + item.Qty;
                } else {
                    sizeandqtyfordisplay = sizeandqtyfordisplay + "," + item.Sizename + "-" + item.Qty;
                }

            });

            values[getCurrentIndex].SizeandQty = sizeandqtyfordisplay;
            values[getCurrentIndex].SizeIdQtyIds = sizeandqtyfordb;
            setInputFields(values);
            setmodalpopup(false);
        }
        else {
            Nodify("Warning!", "warning", "Please fill atleast one quantity.");
            setSubmittedpopup(true);
        }



    }

    //sathish
    const handleSampleChanges = (index, event) => {
        const values = [...inputFields];
        let inputText = "";
        if (event.target.value.trim() !== "") {
            inputText = event.target.value;
        }
        if (event.target.name === "Sample") {
            values[index].SampleRequestID = parseInt(inputText);
        }
        else if (event.target.name === "SizeandQty") {
            values[index].SizeandQty = inputText;
        }
        setInputFields(values);
        SamplereqCallback(values);
    };

    const handlemultidd = (event, index) => {
        if (event.length === 0) {
            values[index].SkuColorNameIdsEdit = "";
            values[index].SkuColorNameIds = "";
        } else {
            let strValue = "";
            for (let Sku of event) {
                if (strValue === "") {
                    strValue = Sku.value.toString();
                } else {
                    strValue = strValue + "," + Sku.value;
                }
            }
            values[index].SkuColorNameIds = strValue;
            values[index].SkuColorNameIdsEdit = event;
        }

        setInputFields(values);
        SamplereqCallback(values);
    }
    const handleRemoveSample = (index) => {
        const values = [...inputFields];
        values.splice(index, 1);
        setInputFields(values);
        SamplereqCallback(values);
    };
    const handleAddSample = (index) => {
        const values = [...inputFields];
        if (
            values[index].SampleRequestID !== "" &&
            values[index].SkuColorNameIds !== "" &&
            values[index].SizeandQty !== ""
        ) {
            // var NewRowSampleReqID = getSampleList.filter(x => x.value !== values[index].SampleRequestID)[0].value;
            //  setNewSreqID(NewRowSampleReqID);
            values.push({ StyleSampleRequestID: 0, SampleRequestID: 0, SkuColorNameIds: "", SizeIdQtyIds: "", SkuColorNameIdsEdit: '', SizeandQty: "" });
            setInputFields(values);
            SamplereqCallback(values);
            setSubmitted(false);
        } else {
            Nodify("Warning!", "warning", "Please fill the mandatory(*) fields.");
            setSubmitted(true);
        }
    };
    const CheckDuplicate = (index, Field) => {
        const values = [...inputFields];
        if (getExistinglist) {
            if (values[index].SampleRequestID !== "") {
                // for avoid empty validation
                for (var i = 0; i < getExistinglist.length; i++) {
                    if (
                        getExistinglist[i].sampleRequestID ===
                        values[index].SampleRequestID
                    ) {
                        // var NewRowSampleReqID = getSampleList.filter(x => x.value !== values[0].SampleRequestID)[0].value;
                        values[index].SampleRequestID = 0;
                        setInputFields(values);
                        Nodify(
                            "Warning!",
                            "warning",
                            "This Sample Request is already exist."
                        );
                        return false;
                    }
                }
            }
        }

        for (var j = 0; j < inputFields.length; j++) {
            if (j !== index) {
                if (inputFields[j].SampleRequestID === inputFields[index].SampleRequestID) {
                    values[index].SampleRequestID = 0;
                    setInputFields(values);
                    Nodify("Warning!", "warning", "This Sample Request is already exist.");
                    return false;
                }
            }
        }
    };

    return (
        <>
            <div className="row">
                <Fragment>
                    <div className={DynamicClassName}>
                        <div className="widget flat radius-bordered">
                            <div className="panel-group accordion" id="accordion">
                                <div className="panel panel-default">
                                    <div className="panel-heading ">
                                        <h4 className="mb-0 panel-title">
                                            <a className="btn btn-link font_wight" data-toggle="collapse" data-parent="accordion" href={"#collapsePrograme_"}>
                                                {getHeader}  Sample Request
                                            </a>
                                        </h4>
                                    </div>
                                    <div id={"collapsePrograme_"} className={getHeader !== "ADD" ? 'panel-collapse collapse in' : 'panel-collapse collapse in'}>
                                        <div className="widget-body">
                                            <div id="registration-form">
                                                <Form role="form">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="dataTables_wrapper no-footer" style={{ overflowX: "auto" }}>
                                                                {
                                                                    CantAddRecords === true && getHeader === 'Add' ?
                                                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                                            <thead className="text-center">
                                                                                <tr>
                                                                                    <th className="text-center">Sample<span className="text-danger">*</span></th>
                                                                                    <th className="text-center ">SKU<span className="text-danger">*</span></th>
                                                                                    <th className="text-center "> Size and Qty<span className="text-danger">*</span></th>
                                                                                    {
                                                                                        getHeader === 'Add' ?
                                                                                            <th className="text-center Actionbutton fixed-column-header"
                                                                                                style={{ display: SampleInfo && SampleInfo.isAdd !== 1 && SampleInfo.isDelete === 0 ? 'none' : '' }}
                                                                                            >Action</th> : ''
                                                                                    }
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr><td colSpan="8" className='norecordfound'><span>Sample Requests already added</span></td></tr>
                                                                            </tbody>
                                                                        </table> :

                                                                        <table className="table table-bordered">
                                                                            <thead className="text-center">
                                                                                <tr>
                                                                                    <th className="text-center">Sample<span className="text-danger">*</span></th>
                                                                                    <th className="text-center ">SKU<span className="text-danger">*</span></th>
                                                                                    <th className="text-center "> Size and Qty<span className="text-danger">*</span></th>
                                                                                    {
                                                                                        getHeader === 'Add' ?
                                                                                            <th className="text-center Actionbutton fixed-column-header"
                                                                                                style={{
                                                                                                    display: inputFields.length === 1 && getExistinglist.length === 6 ? 'none' : ''
                                                                                                }}
                                                                                            >Action</th> : ''
                                                                                    }
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    inputFields.map((item, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <tr key={index}>
                                                                                                    <td className=''>
                                                                                                        <select
                                                                                                            id={"Sample" + index}
                                                                                                            name={"Sample"}
                                                                                                            className="form-select"
                                                                                                            value={item.SampleRequestID}
                                                                                                            onChange={(event) =>
                                                                                                                handleSampleChanges(index, event)
                                                                                                            }
                                                                                                            onBlur={() => CheckDuplicate(index, 'Sample')}
                                                                                                            style={{ border: submitted && item.SampleRequestID === 0 ? '1px solid red' : '' }}
                                                                                                            disabled={getHeader === 'Add' ? false : true}
                                                                                                        >

                                                                                                            {getSampleList.map((item) => (
                                                                                                                <option key={item.id} value={item.id}>
                                                                                                                    {item.name}
                                                                                                                </option>
                                                                                                            ))}
                                                                                                        </select>
                                                                                                    </td>


                                                                                                    <td className=''>
                                                                                                        <Reactselect
                                                                                                            className="basic-single"
                                                                                                            name={"Sku"}
                                                                                                            id={
                                                                                                                "Sku" + index
                                                                                                            }
                                                                                                            // value={item.MeasurementPoints}
                                                                                                            isDisabled={false}
                                                                                                            isLoading={false}
                                                                                                            isClearable={true}
                                                                                                            isSearchable={true}
                                                                                                            options={getSkulist}
                                                                                                            value={item.SkuColorNameIdsEdit}
                                                                                                            // value={getSkulist.filter(function (option) {
                                                                                                            //     return option.value === item.SkuColorNameIds;
                                                                                                            // })}
                                                                                                            isMulti
                                                                                                            onChange={event => handlemultidd(event, index, 'Sku')}
                                                                                                            onMenuOpen={event => controlOverflow(event, index, 'Sku')}
                                                                                                            menuPosition="fixed"
                                                                                                            styles={submitted && item.SkuColorNameIdsEdit === '' ? styles2 : styles1}
                                                                                                        />

                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <Input
                                                                                                            placeholder="Size and Qty"
                                                                                                            className="form-control cut_text"
                                                                                                            title={item.SizeandQty}
                                                                                                            name="SizeandQty"
                                                                                                            id={
                                                                                                                "SizeandQty" + index
                                                                                                            }
                                                                                                            value={
                                                                                                                item.SizeandQty
                                                                                                            }
                                                                                                            onKeyDown={(e) => e.preventDefault()}

                                                                                                            //onBlur={event => handlechange(event, index, "SizeandQty")}
                                                                                                            style={{ width: "300px", border: submitted && item.SizeandQty === '' ? '1px solid red' : '' }}
                                                                                                            onClick={() => openpopup(index, item.SampleRequestID)}
                                                                                                        ></Input>
                                                                                                    </td>
                                                                                                    {IsProduction === 2 ? <>{inputFields.length < 6 && getExistinglist.length < 6 && getHeader === "Add" ?
                                                                                                        <td className="Actionbutton fixed-column">
                                                                                                            {" "}
                                                                                                            <div style={{ width: "80px" }}>
                                                                                                                {/* {(IsProduction === 1 && getSampleList.length >= index+1) ? */}
                                                                                                                <>{(inputFields.length === (index + 1)) && (
                                                                                                                    <button style={{ display: SampleInfo && SampleInfo.isAdd === 1 ? '' : 'none' }}
                                                                                                                        type="button"
                                                                                                                        className="btn  btn-xs  btn-success"
                                                                                                                        title="Add Sample Request"
                                                                                                                        onClick={() =>
                                                                                                                            handleAddSample(index)
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <i className="fa fa-plus"></i>
                                                                                                                    </button>
                                                                                                                )}</>
                                                                                                                &nbsp;
                                                                                                                {inputFields.length !== 1 && (
                                                                                                                    <button style={{ display: SampleInfo && SampleInfo.isDelete === 1 ? '' : 'none' }}
                                                                                                                        type="button"
                                                                                                                        className="btn  btn-xs  btn-danger"
                                                                                                                        title="Delete Sample Request"
                                                                                                                        onClick={() =>
                                                                                                                            handleRemoveSample(index)
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <i className="fa fa-trash-o"></i>
                                                                                                                    </button>
                                                                                                                )}

                                                                                                            </div>
                                                                                                        </td>
                                                                                                        : inputFields.length !== 1 ?
                                                                                                            < td style={{ display: SampleInfo && SampleInfo.isAdd !== 1 && SampleInfo.isDelete === 0 ? 'none' : '' }} className="Actionbutton fixed-column">
                                                                                                                <button style={{ display: SampleInfo && SampleInfo.isDelete === 1 ? '' : 'none' }}
                                                                                                                    type="button"
                                                                                                                    className="btn  btn-xs  btn-danger"
                                                                                                                    title="Delete Sample Request"
                                                                                                                    onClick={() =>
                                                                                                                        handleRemoveSample(index)
                                                                                                                    }
                                                                                                                >
                                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                                </button>
                                                                                                            </td>
                                                                                                            : ''


                                                                                                    }</> : <>{inputFields.length < 2 && getExistinglist.length < 2 && getHeader === "Add" ?
                                                                                                        <td className="Actionbutton fixed-column">
                                                                                                            {" "}
                                                                                                            <div style={{ width: "80px" }}>
                                                                                                                {inputFields.length !== 1 && (
                                                                                                                    <button style={{ display: SampleInfo && SampleInfo.isDelete === 1 ? '' : 'none' }}
                                                                                                                        type="button"
                                                                                                                        className="btn  btn-xs  btn-danger"
                                                                                                                        title="Delete Sample Request"
                                                                                                                        onClick={() =>
                                                                                                                            handleRemoveSample(index)
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <i className="fa fa-trash-o"></i>
                                                                                                                    </button>
                                                                                                                )}
                                                                                                                &nbsp;
                                                                                                                {/* {(IsProduction === 1 && getSampleList.length >= index+1) ? */}
                                                                                                                <>{(inputFields.length === (index + 1)) && (
                                                                                                                    <button style={{ display: SampleInfo && SampleInfo.isAdd === 1 ? '' : 'none' }}
                                                                                                                        type="button"
                                                                                                                        className="btn  btn-xs  btn-success"
                                                                                                                        title="Add Sample Request"
                                                                                                                        onClick={() =>
                                                                                                                            handleAddSample(index)
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <i className="fa fa-plus"></i>
                                                                                                                    </button>
                                                                                                                )}</>
                                                                                                                {/* ? : ''} */}

                                                                                                            </div>
                                                                                                        </td>
                                                                                                        : inputFields.length !== 1 ?
                                                                                                            < td style={{ display: SampleInfo && SampleInfo.isAdd !== 1 && SampleInfo.isDelete === 0 ? 'none' : '' }} className="Actionbutton fixed-column">
                                                                                                                <button style={{ display: SampleInfo && SampleInfo.isDelete === 1 ? '' : 'none' }}
                                                                                                                    type="button"
                                                                                                                    className="btn  btn-xs  btn-danger"
                                                                                                                    title="Delete Sample Request"
                                                                                                                    onClick={() =>
                                                                                                                        handleRemoveSample(index)
                                                                                                                    }
                                                                                                                >
                                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                                </button>
                                                                                                            </td>
                                                                                                            : ''


                                                                                                    }</>}

                                                                                                </tr>

                                                                                            </>

                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                }




                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        getmodalpopup === true ?

                                                            <Modal show={getmodalpopup === true} size="md" dragable backdrop="static" keyboard={false} onHide={() => handlePopupClose()}
                                                            >


                                                                <Modal.Header closeButton>
                                                                    <Modal.Title>Sample Size and Qty - {getsampleName} </Modal.Title>
                                                                </Modal.Header>
                                                                <Modal.Body style={{ overflow: "auto", height: "650px" }} align="center">
                                                                    <table style={{ width: "550px" }} className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                                        <thead>
                                                                            <tr>
                                                                                <th style={{ width: "350px" }}>Sizes</th>
                                                                                <th style={{ width: "160px" }}>Qty<span className="text-danger">*</span></th>
                                                                                {/* <th styles={{ width: "200px" }}>Action</th> */}
                                                                            </tr>

                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                getsizeqty.map((item, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span className="input-icon icon-right">
                                                                                                        {/* <Reactselect
                                                                                                            className="basic-single"
                                                                                                            name={"SizeId"}
                                                                                                            id={
                                                                                                                "SizeId" + index
                                                                                                            }
                                                                                                            // value={item.MeasurementPoints}
                                                                                                            isDisabled={false}
                                                                                                            isLoading={false}
                                                                                                            isClearable={true}
                                                                                                            isSearchable={true}
                                                                                                            options={getGradingSize}
                                                                                                            value={getGradingSize.filter(function (option) {
                                                                                                                return option.value === item.Size;
                                                                                                            })}
                                                                                                            // value={item.Size}
                                                                                                            onChange={
                                                                                                                event => handlepopupchange(index, event, 'SizeId')
                                                                                                            }
                                                                                                            onBlur={() => CheckSizeDuplicate(index, 'SizeId')}

                                                                                                            styles={submittedpopup && item.Size === '' ? styles2 : styles1}
                                                                                                        /> */}
                                                                                                        {item.Sizename}


                                                                                                    </span>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <span className='input-icon icon-right'>
                                                                                                        <input type="text"
                                                                                                            placeholder="Qty"
                                                                                                            id={"Qty"}
                                                                                                            name="Qty"
                                                                                                            onChange={event => handlepopupchange(index, event, 'Qty')}
                                                                                                            value={item.Qty}
                                                                                                            style={{ border: submittedpopup && !item.Qty ? '1px solid red' : '' }}
                                                                                                            autoFocus
                                                                                                            maxLength="10"
                                                                                                            className={'form-control'}
                                                                                                            onKeyPress={event => RestrictFirstZero(event)}
                                                                                                        // disabled={ChildField.DependencyID === 0 ? true : false}
                                                                                                        />
                                                                                                    </span>

                                                                                                </td>
                                                                                                {/* <td className="Actionbutton fixed-column">
                                                                                                    {" "}
                                                                                                    <div style={{ width: "80px" }}>
                                                                                                        {getsizeqty.length !== 1 && (
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                className="btn  btn-xs  btn-danger"
                                                                                                                title="Delete SampleRequest"
                                                                                                                onClick={() =>
                                                                                                                    handleRemoveFields(index)
                                                                                                                }
                                                                                                            >
                                                                                                                <i className="fa fa-trash-o"></i>
                                                                                                            </button>
                                                                                                        )}
                                                                                                        &nbsp;
                                                                                                        {getsizeqty.length === index + 1 && (
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                className="btn  btn-xs  btn-success"
                                                                                                                title="Add SampleRequest"
                                                                                                                onClick={() =>
                                                                                                                    handleAddField(index)
                                                                                                                }
                                                                                                            >
                                                                                                                <i className="fa fa-plus"></i>
                                                                                                            </button>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </td> */}
                                                                                            </tr>
                                                                                        </>

                                                                                    )
                                                                                })
                                                                            }
                                                                        </tbody>
                                                                    </table>


                                                                </Modal.Body>
                                                                <Modal.Footer>
                                                                    <button type="button" className="btn btn-success" disabled={buttonLoader} onClick={savesizeqty}>
                                                                        <i className="fa fa-check right"></i>&nbsp;
                                                                        {ButtonName}
                                                                    </button>
                                                                </Modal.Footer>

                                                            </Modal> : ''
                                                    }
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            </div>
        </>
    );
}
export default SampleReqAdd;
