import React, { useState, useEffect, Fragment } from "react";
import ReactNotification from 'react-notifications-component';
import Nodify from "../../Common/ReactNotification"
import SearchRetain from "../../Common/SearchRetain";
import PackageItemService from "../../../services/Master/Packageitem";
import CategoryService from "../../../services/Master/CategoryService";
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";

const EditPackageItem = (props) => {
   
    const { user: currentUser } = useSelector((state) => state.auth);
    let PackageItemInfoID = props.location.state === undefined ? 0 : props.location.state.params.packageItemInfoID;

    const EmptyInputFields = [{
        PackageItemID: 0,
        PackageItemName: '',
        IndexName: '0',
        IsSelected: 0,
        Dimension: []
    }]
    const [inputFields, setInputFields] = useState(EmptyInputFields);
    const [Dimension, setDimension] = useState(false);
    const [getUOMType, setUOMType] = useState([]);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [submittedCS, setSubmittedCS] = useState(false);
    const [Existing, setExisting] = useState([]);

    function BindTaskValue(props) {
       ;
        let TaskValue = {};
        if (props.location.state !== undefined) {
            if (props.location.state.params.length !== 0) {
                if (props.location.state.params.PackageItemID !== 0) {
                    //  TaskValue.TaskInformationID = props.location.state.params.taskInformationID;
                    TaskValue.PackageItemID = props.location.state.params.packageItemID;
                    TaskValue.PackageItemName = props.location.state.params.packageItemName;
                    TaskValue.IndexName = props.location.state.params.indexName;
                    TaskValue.IsSelected = props.location.state.params.isSelected;
                    TaskValue.Dimension = [];
                    return TaskValue;
                }
            }
        }
        else {
            return TaskValue;
        }
    }
    //Enter Error msg
    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                //SaveBuyerInfo(event);
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);

    useEffect(() => {
       ;
        let checkDuplist = [];
        PackageItemService.GetPackageItemList(1, 0, 0).then((res) => {
            if (res.data) {
                checkDuplist = res.data.inputPackageItemList1.filter(x => x.packageItemID !== props.location.state.params.packageItemID)
            }
            setExisting(checkDuplist)
        })
        PackageItemService.GetPackageItemList(2, 0, PackageItemInfoID).then((res) => {
            if (res.data) {
                let Taskinfo = BindTaskValue(props);
                if (res.data.inputPackageItemEdit2.length !== 0) {
                    res.data.inputPackageItemEdit2.filter(x => x.mainIndexName === props.location.state.params.indexName && x.packageItemInfoID === PackageItemInfoID).forEach(x => {
                        Taskinfo.Dimension.push({
                            DimensionID: x.dimensionID,
                            Height: x.height,
                            Width: x.width,
                            Length: x.length,
                            UOM: x.uom,
                            MainIndexName: x.mainIndexName,
                            ChildIndexName: x.childIndexName
                        })
                    })
                } else {
                    Taskinfo.Dimension.push({
                        DimensionID: 0,
                        Height: '',
                        Width: '',
                        Length: '',
                        UOM: 0,
                        MainIndexName: props.location.state.params.indexName,
                        ChildIndexName: '0'
                    })
                }
                setInputFields([Taskinfo])
                // setExisting(res.data.inputPackageItemEdit2)
            }
        })

        CategoryService.LoadUOMTypeList().then((response) => {

            if (response.data) {
                setUOMType(response.data);
            }
        }).catch(() => { });
    }, [])

    const handleDimension = (index) => {
        setDimension(true)
    }

    const setDimensionpop = (index) => {
        setDimension(false)
    }

    const handleClose = (index) => {
        const values = [...inputFields];
        let isvalid = true;
        for (var j = 0; j < values[index].Dimension.length; j++) {
            if (values[index].Dimension[j].Height === '' || values[index].Dimension[j].Width === '' ||
                values[index].Dimension[j].Length === '' || values[index].Dimension[j].UOM === 0) {
                isvalid = false;
                setSubmittedCS(true);
                Nodify('Warning!', 'warning', 'Please fill the mandantory(*) fields');
            }
        }
        if (isvalid) {
            values[index].closeCheck = 1;
            Nodify('Success!', 'success', 'Dimension Saved successfully');
            setDimension(false)
        }
        setInputFields(values);
    }

    const handleChildRemoveFields = (index, childindex) => {
       
        const values = [...inputFields];
        // let avoiddup;
        // avoiddup = values[index].Dimension.findIndex(x => x.Isdeleted !== 1)
        //values[index].Dimension[childindex].Isdeleted = 1;
        values[index].Dimension.splice(childindex, 1);
        setInputFields(values);
    }

    const handleChildAddFields = (index, childindex) => {
       
        setSubmittedCS(false);
        const values = [...inputFields];
        if (values[index].Dimension[childindex].Height === '' || values[index].Dimension[childindex].Width === '' ||
            values[index].Dimension[childindex].Length === '' || values[index].Dimension[childindex].UOM === 0) {
            setSubmittedCS(true)
            Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.')
        }
        else {
            values[index].Dimension.push({
                DimensionID: 0,
                Height: '',
                Width: '',
                Length: '',
                UOM: 0,
                MainIndexName: index.toString(),
                ChildIndexName: (childindex + 1).toString(),
                Isdeleted: 0,
            })
        }
        setInputFields(values)
    }

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/Package');
        } else {
            window.location.reload();
        }
    }
    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/Package",
            state: {
                message: Func
            }
        });
    }

    // const CheckDuplicate = (index, Feildname) => {
    //    ;
    //     const values = [...inputFields];
    //     let isExisting = !!Existing.find(d => d.packageItemName.trim().toLowerCase() === values[index].PackageItemName.trim().toLowerCase())
    //     if (isExisting) {
    //         values[index].PackageItemName = props.location.state.params.packageItemName;
    //         setInputFields(values);
    //         Nodify('Warning!', 'warning', "This PackageItem Name is already exist.");
    //         return false;
    //     }
    // }
    const handleInputChange = (index, event, FieldName) => {
       
        const values = [...inputFields];
        if (FieldName === "PackageItem") {
            if (event !== null) {
                values[index].PackageItemName = event.currentTarget.value
            } else {
                values[index].PackageItemName = ''
            }
        }
        setInputFields(values)
    }

    const handleChildInputChange = (index, childindex, event, Feildname) => {
       
        const values = [...inputFields];
        let avoiddup;
        if (values[index].Dimension[childindex].Isdeleted !== undefined && values[index].Dimension[childindex].Isdeleted === 1) {
            avoiddup = values[index].Dimension.findIndex(x => x.Isdeleted !== 1)
        } else {
            avoiddup = childindex
        }
        let inputtext = '';
        if (event.target.value !== '') {
            inputtext = event.target.value.trim();
        }
        if (Feildname === "Height") {
            if (event.currentTarget.value !== '') {
                values[index].Dimension[avoiddup].Height = inputtext
            } else {
                values[index].Dimension[avoiddup].Height = '';
            }

        }
        else if (Feildname === "Width") {
            if (event.currentTarget.value !== '') {
                values[index].Dimension[avoiddup].Width = inputtext
            } else {
                values[index].Dimension[avoiddup].Width = '';
            }

        }
        else if (Feildname === "Length") {
            if (event.currentTarget.value !== '') {
                values[index].Dimension[avoiddup].Length = inputtext
            } else {
                values[index].Dimension[avoiddup].Length = '';
            }

        } else {
            if (event.currentTarget.value !== '') {
                values[index].Dimension[avoiddup].UOM = parseInt(event.currentTarget.value);
            } else {
                values[index].Dimension[avoiddup].UOM = 0;
            }
        }
        setInputFields(values)
    }

    const handleOnSelect = (e, Feildname, index) => {
       
        const values = [...inputFields]
        if (e.target.checked === true) {
            values[index].IsSelected = 1
        } else {
            values[index].IsSelected = 0
        }
        setInputFields(values)
    }

    const SavepackageItem = (e) => {
       
        setButtonLoader(true);
        let IsValid = true;
        e.preventDefault();
        if (inputFields.length === 1 && inputFields[0].PackageItemName === '') {
            setButtonLoader(false);
            setSubmitted(true);
            IsValid = false;
            Nodify('Warning!', 'warning', "Please fill the mandatory(*) fields.");
        }
        if (inputFields[0].IsSelected === 1) {
            for (var j = 0; j < inputFields[0].Dimension.length; j++) {
                if (inputFields[0].Dimension[j].Height === '' || inputFields[0].Dimension[j].Width === '' ||
                    inputFields[0].Dimension[j].Length === '' || inputFields[0].Dimension[j].UOM === 0) {
                    setButtonLoader(false);
                    setSubmittedCS(true);
                    IsValid = false;
                    Nodify('Warning!', 'warning', 'Please fill The Carton Dimension Popup.');
                    return false;
                }
            }
        }
        if (IsValid) {
            const GetTaskDetails = { Operation: 2, PackageItemInfoID: props.location.state.params.packageItemInfoID, PackageInformation: [], DimensionInformation: [], Createdby: currentUser.employeeinformationID };
            inputFields.forEach((Info, i) => {
                GetTaskDetails.PackageInformation.push({
                    PackageItemID: Info.PackageItemID,
                    PackageItemName: Info.PackageItemName,
                    IndexName: Info.IndexName.toString(),
                    IsSelected: Info.IsSelected
                })
                if (Info.Dimension) {
                    Info.Dimension.forEach((child, j) => {
                        if (inputFields[i].Dimension[j].Height !== '' || inputFields[i].Dimension[j].Width !== '' ||
                            inputFields[i].Dimension[j].Length !== '' || inputFields[i].Dimension[j].UOM !== 0) {
                            GetTaskDetails.DimensionInformation.push({
                                DimensionID: child.DimensionID,
                                Height: child.Height,
                                Width: child.Width,
                                Length: child.Length,
                                UOM: parseInt(child.UOM),
                                MainIndexName: Info.IndexName.toString(),
                                ChildIndexName: j.toString(),
                                Isdeleted: child.Isdeleted === undefined ? 0 : child.Isdeleted
                            })
                        }
                    })
                }
            })
            //console.log(GetTaskDetails)
            PackageItemService.InsertUpdatePackageItemGrd(GetTaskDetails).then((res) => {
               
                var page = "Remove";
                SearchRetain(page);
                let Func = 'Add';
                if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "-2") {
                    setButtonLoader(false);
                    Nodify('Warning!', 'warning', "This name is already exist.");
                }
                else if (res.data.outputResult === "0") {
                    setButtonLoader(false);
                    Nodify('Warning!', 'warning', "Error Occured!");
                }
            })
        }
    }
    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <div className="widget flat radius-bordered">
                            <div className="widget-header heading_box_style">
                                <h3 className="heading_style_common">Edit Package Item</h3>
                            </div>
                            <div className="widget-body">
                                <div id="registration-form">
                                    {
                                        <form>
                                            <div >
                                                <table className="table table-striped table-bordered table-hover dataTable no-footer margin-top-10" id="editabledatatable" style={{ width: "70%" }}>

                                                    <thead>
                                                        <tr>
                                                            <th colspan="2">Package Item Name<span className="text-danger">*</span></th>
                                                            {inputFields[0].IsSelected === 1 ? <th>Action</th> : ''}
                                                        </tr>
                                                    </thead>
                                                    {
                                                        inputFields.map((inputField, index) => (
                                                            <tbody>

                                                                <tr>
                                                                    <td style={{ width: "63%" }}>
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            id={"PackageItem"}
                                                                            name="PackageItem"
                                                                            placeholder="Enter Package Item"
                                                                            maxLength="100"
                                                                            value={inputField.PackageItemName}
                                                                            onChange={event => handleInputChange(index, event, "PackageItem")}
                                                                            //onBlur={() => CheckDuplicate(index)}
                                                                            style={{ border: submitted && inputField.PackageItemName === "" ? '1px solid red' : '' }}
                                                                            autoFocus
                                                                        />
                                                                    </td>
                                                                    <td >
                                                                        <label className="Ajustment"><span className="text">Is Carton Box </span></label>&nbsp;&nbsp;&nbsp;
                                                                        <label style={{ marginTop: '8px' }}>
                                                                            <input type="checkbox" name="IsSelected"
                                                                                onChange={event => (handleOnSelect(event, 'IsSelected', index))}
                                                                                value={inputField.IsSelected === 1 ? true : false}
                                                                                checked={inputField.IsSelected === 1 ? true : false}
                                                                                className="clearOpacity textdesign"
                                                                                disabled={true}
                                                                            />
                                                                            <span className="text"> </span>
                                                                        </label>
                                                                    </td>
                                                                    {
                                                                        inputFields[index].IsSelected === 1 ?
                                                                            <td >
                                                                                {
                                                                                    Dimension === true ?
                                                                                        <Modal show={Dimension === true} size="lg" dragable backdrop="static" keyboard={false} onHide={() => setDimensionpop(index)}>
                                                                                            <Modal.Header closeButton>
                                                                                                <Modal.Title>Add Carton Dimension</Modal.Title>
                                                                                            </Modal.Header>
                                                                                            <Modal.Body style={{ overflow: "auto", height: "650px" }} align="center">
                                                                                                <div className="col-md-12" style={{ overflowX: "auto", minHeight: '100px' }}>
                                                                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                                                                                        <thead>
                                                                                                            <tr>
                                                                                                                <th className="new" style={{ width: "220px" }}>Height<span className="text-danger">*</span></th>
                                                                                                                <th style={{ width: "220px" }}>Width<span className="text-danger">*</span></th>
                                                                                                                <th style={{ width: "220px" }}>Length<span className="text-danger">*</span></th>
                                                                                                                <th style={{ width: "220px" }}>Uom<span className="text-danger">*</span></th>
                                                                                                                <th style={{ width: "150px" }}>Action</th>
                                                                                                            </tr>

                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            {
                                                                                                                inputField.Dimension.filter(x => x.Isdeleted !== 1).map((childInput, childindex) => (
                                                                                                                    <>
                                                                                                                        <tr>
                                                                                                                            <td style={{ width: "230px" }}>
                                                                                                                                <input
                                                                                                                                    className="form-control"
                                                                                                                                    id={"Height"}
                                                                                                                                    name="Height"
                                                                                                                                    placeholder="Enter Height"
                                                                                                                                    maxLength="50"
                                                                                                                                    value={childInput.Height}
                                                                                                                                    autocomplete="off"
                                                                                                                                    onChange={event => handleChildInputChange(index, childindex, event, "Height")}
                                                                                                                                    //onBlur={() => CheckDuplicate(index, 'Buyer')}
                                                                                                                                    style={{ border: submittedCS && childInput.Height === '' ? '1px solid red' : '' }}
                                                                                                                                    autoFocus />
                                                                                                                            </td>
                                                                                                                            <td style={{ width: "230px" }}>
                                                                                                                                <input
                                                                                                                                    className="form-control"
                                                                                                                                    id={"Width"}
                                                                                                                                    name="Width"
                                                                                                                                    placeholder="Enter Width"
                                                                                                                                    maxLength="50"
                                                                                                                                    value={childInput.Width}
                                                                                                                                    autocomplete="off"
                                                                                                                                    onChange={event => handleChildInputChange(index, childindex, event, "Width")}
                                                                                                                                    //onBlur={() => CheckDuplicate(index, 'Buyer')}
                                                                                                                                    style={{ border: submittedCS && childInput.Width === '' ? '1px solid red' : '' }}
                                                                                                                                    autoFocus />
                                                                                                                            </td>
                                                                                                                            <td style={{ width: "230px" }}>
                                                                                                                                <input
                                                                                                                                    className="form-control"
                                                                                                                                    id={"Length"}
                                                                                                                                    name="Length"
                                                                                                                                    placeholder="Enter Length"
                                                                                                                                    maxLength="50"
                                                                                                                                    value={childInput.Length}
                                                                                                                                    autocomplete="off"
                                                                                                                                    onChange={event => handleChildInputChange(index, childindex, event, "Length")}
                                                                                                                                    //onBlur={() => CheckDuplicate(index, 'Buyer')}
                                                                                                                                    style={{ border: submittedCS && childInput.Length === '' ? '1px solid red' : '' }}
                                                                                                                                    autoFocus />
                                                                                                                            </td>
                                                                                                                            <td style={{ width: "387px" }}>
                                                                                                                                <span className="input-icon icon-right">
                                                                                                                                    <select id={"Uom"}
                                                                                                                                        name={"Uom"}
                                                                                                                                        className="form-select"
                                                                                                                                        value={childInput.UOM}
                                                                                                                                        onChange={event => handleChildInputChange(index, childindex, event, "Uom")}
                                                                                                                                        style={{ border: submittedCS && childInput.UOM === 0 ? '1px solid red' : '' }}
                                                                                                                                    >
                                                                                                                                        <option value="0">-Select UOM-</option>
                                                                                                                                        {
                                                                                                                                            getUOMType.map(item1 => (
                                                                                                                                                <option key={item1.index}
                                                                                                                                                    value={item1.id}>
                                                                                                                                                    {item1.name}
                                                                                                                                                </option>
                                                                                                                                            ))
                                                                                                                                        }
                                                                                                                                    </select>
                                                                                                                                </span>
                                                                                                                            </td>
                                                                                                                            <td style={{ width: "220px" }}>
                                                                                                                                {inputFields[index].Dimension.filter(x => x.Isdeleted !== 1).length !== 1 && (
                                                                                                                                    <span title='Delete Package Item' onClick={() => handleChildRemoveFields(index, childindex)}
                                                                                                                                        className="btn btn-danger btn-xs delete">
                                                                                                                                        <i className="fa fa-trash-o"></i>
                                                                                                                                    </span>
                                                                                                                                )

                                                                                                                                }
                                                                                                                                &nbsp; &nbsp;
                                                                                                                                {inputFields[index].Dimension.length === (childindex + 1) && (
                                                                                                                                    <span title='Add Package Item' onClick={() => handleChildAddFields(index, childindex)}
                                                                                                                                        className="btn btn-info btn-xs plus">
                                                                                                                                        <i className="fa fa-plus"></i>
                                                                                                                                    </span>
                                                                                                                                )}

                                                                                                                            </td>
                                                                                                                        </tr>

                                                                                                                    </>
                                                                                                                ))
                                                                                                            }

                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>

                                                                                            </Modal.Body>
                                                                                            <Modal.Footer>
                                                                                                <Button variant="success" onClick={() => handleClose(index, "Save")} >
                                                                                                    Save
                                                                                                </Button>
                                                                                            </Modal.Footer>

                                                                                        </Modal> : ''
                                                                                }
                                                                                {inputFields[index].IsSelected === 1 ?
                                                                                    <span title='Dimension' style={{ marginTop: '5px', marginLeft: '15px' }} onClick={() => handleDimension(index)}
                                                                                        className="btn btn-danger btn-xs delete">
                                                                                        <i className="fa fa-fire"></i>
                                                                                    </span> : ''
                                                                                }
                                                                            </td>
                                                                            : ''
                                                                    }
                                                                </tr>

                                                            </tbody>
                                                        ))
                                                    }
                                                </table>
                                            </div>
                                            <div className="col-sm-12 margin-top-20">
                                                <div className="buttons-preview" align="right">
                                                    <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                                        <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                                                    </span>
                                                    <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                                        <i className="fa fa-times"></i>&nbsp;Reset
                                                    </span>
                                                    <button type="button" className="btn btn-success" disabled={buttonLoader} onClick={SavepackageItem}>
                                                        <i className="fa fa-check right"></i>&nbsp;Update
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    }
                                </div >
                            </div >
                        </div >
                    </div >
                </div >
            </div >
        </>
    )
}
export default EditPackageItem;