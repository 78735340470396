import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'react-notifications-component/dist/theme.css';
import Form from "react-validation/build/form";
import Nodify from "../../Common/ReactNotification";
import DocumentUpload from "../../Common/DocumentUpload";
//import DocumentUpload from "../../Common/UploadImage";

const SKU = ({ props, SKUList, SKUCallback, StyleInfo, IsValidationCallback, SkuInfo }) => {
    const [inputFields, setInputFields] = useState([{
        SKUID: 0, SKUName: '', SKUFrontPicName: "", SKUFrontPicPath: StyleInfo.styleLogoPath,
        SKUBackPicName: "", SKUBackPicPath: "", AdditionalPicName1: "", AdditionalPicPath1: "",
        AdditionalPicName2: "", AdditionalPicPath2: "", AdditionalPicName3: "", AdditionalPicPath3: "",
        AdditionalPicName4: "", AdditionalPicPath4: "", PickStylePic: 1, IsDeleted: 0, ShowAddButton: 1,
    }]);
   ;
    const [submitted, setSubmitted] = useState(false);
    const [Buttonvisible, SetButtonvisible] = useState("Show");
    const [getHeader, setHeader] = useState("Add");
    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item5);
    if (menulist !== undefined) { activeMenu = menulist.filter(i => i.menuName === "StyleSKU"); }

    //ExistingList Values
    const reducerState = useSelector((state) => state);
    const ExistingList = reducerState.style.SKUList;

    // End Enable Menu
    useEffect(() => {
        setSubmitted(false);
        if (SKUList !== undefined && SKUList.length === undefined) {
            setInputFields([]);
            setHeader("Edit")
            if (SKUList.SKUName !== '') {
                inputFields.splice(0, 1);
                inputFields.push({
                    SKUID: SKUList.skuID, SKUName: SKUList.skuName, SKUFrontPicName: SKUList.skuFrontPicName, SKUFrontPicPath: SKUList.skuFrontPicPath,
                    SKUBackPicName: SKUList.skuBackPicName, SKUBackPicPath: SKUList.skuBackPicPath, AdditionalPicName1: SKUList.additionalPicName1, AdditionalPicPath1: SKUList.additionalPicPath1,
                    AdditionalPicName2: SKUList.additionalPicName2, AdditionalPicPath2: SKUList.additionalPicPath2, AdditionalPicName3: SKUList.additionalPicName3, AdditionalPicPath3: SKUList.additionalPicPath3,
                    AdditionalPicName4: SKUList.additionalPicName4, AdditionalPicPath4: SKUList.additionalPicPath4, PickStylePic: SKUList.pickStylePic, IsDeleted: 0,
                    ShowAddButton: 1,
                });
            }
        }
        setInputFields(inputFields);
        SKUCallback(inputFields);
    }, []); //SKUList

    useEffect(() => {
        setSubmitted(IsValidationCallback);
    }, [IsValidationCallback === true]);

    let DynamicClassName = "col-lg-12 col-sm-12 col-xs-12";

    const handleInputChange = (event, index) => {
       
        const values = [...inputFields];
        if (event.target.name === 'PickStylePic') {
            let result = 0;
            if (event.target.checked) {
                result = 1;
                values[index].SKUFrontPicPath = StyleInfo.styleLogoPath;
            } else {
                values[index].SKUFrontPicPath = "";
            }
            values[index][event.target.name] = result;
        } else {
            let inputText = '';
            if (event.target.value.trim() !== '') {
                inputText = event.target.value;
            }
            values[index][event.target.name] = inputText;
        }
        setInputFields(values);
        SKUCallback(values);
    };

    const handleAddFields = (index) => {
       
        const values = [...inputFields];
        setSubmitted(false);
        if (values[index].SKUName === '') {
            setTimeout(() => {
                Nodify('Warning!', 'warning', "Please fill the SKU Name field.");
            }, 1000)
            setSubmitted(true);
        } else {
            values[index].ShowAddButton = 0;
            values.push({
                SKUID: 0, SKUName: '', SKUFrontPicName: "", SKUFrontPicPath: StyleInfo.styleLogoPath,
                SKUBackPicName: "", SKUBackPicPath: "", AdditionalPicName1: "", AdditionalPicPath1: "",
                AdditionalPicName2: "", AdditionalPicPath2: "", AdditionalPicName3: "", AdditionalPicPath3: "",
                AdditionalPicName4: "", AdditionalPicPath4: "", PickStylePic: 1, IsDeleted: 0, ShowAddButton: 1,
            })
            setInputFields(values);
            SKUCallback(values);
        }
    };

    const handleRemoveFields = (index) => {
        const values = [...inputFields];
        values[index].IsDeleted = 1;
        let exit = true;
        let getCount = values.length; getCount--;
        for (var i = getCount; i >= 0; i--) {
            if (exit && values[i].IsDeleted === 0) {
                exit = false;
                values[i].ShowAddButton = 1;
            }
        }
        setInputFields(values);
        SKUCallback(values);
    };

     //Enter Error msg
    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                 event.preventDefault();
             }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);

    const AddSKUCallback = (value, index, FuncArray) => {
       ;
        const values = [...inputFields];
        let profile = value !== '' ? "/Style/SKU/" : '';
        if (FuncArray === 'FrontPic') {
            values[index].SKUFrontPicName = value;
            values[index].SKUFrontPicPath = profile + value;
            let result = StyleInfo.styleLogoPath !== values[index].SKUFrontPicPath || value === "" ? 0 : 1;
            if (value === "") { values[index].PickStylePic = result; } else { values[index].PickStylePic = result; };
        } else if (FuncArray === 'BackPic') {
            values[index].SKUBackPicName = value;
            values[index].SKUBackPicPath = profile + value;
        } else if (FuncArray === 'Pic1') {
            values[index].AdditionalPicName1 = value;
            values[index].AdditionalPicPath1 = profile + value;
        } else if (FuncArray === 'Pic2') {
            values[index].AdditionalPicName2 = value;
            values[index].AdditionalPicPath2 = profile + value;
        } else if (FuncArray === 'Pic3') {
            values[index].AdditionalPicName3 = value;
            values[index].AdditionalPicPath3 = profile + value;
        } else if (FuncArray === 'Pic4') {
            values[index].AdditionalPicName4 = value;
            values[index].AdditionalPicPath4 = profile + value;
        }
        setInputFields(values);
        SKUCallback(values);
    }


    const CheckDuplicate = (index) =>{
       ;
        const values = [...inputFields];
       
        if (ExistingList) {
            for (var i = 0; i < ExistingList.item1.length; i++) {
                if (ExistingList.item1[i].skuName.trim().toLowerCase() === values[index].SKUName.trim().toLowerCase() && ExistingList.item1[i].skuID !== values[index].SKUID) {
                    
                     values[index].SKUName = '';
                    setInputFields(values);
                    Nodify('Warning!', 'warning', "This name is already exist.");
                    return false;
                
                }
            }
        }
    }
    return (
        <>
            <div className="row">
                <div className={DynamicClassName}>
                    <div className="widget flat radius-bordered">
                        <div className="panel-group accordion" id="accordion">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    <h4 className="mb-0 panel-title">
                                        <a className="btn btn-link font_wight" data-toggle="collapse" data-parent="accordion"
                                            href={"#collapseSKU_"}>
                                            {getHeader} SKU
                                        </a>
                                    </h4>
                                </div>
                                <div id={"collapseSKU_"} className={'panel-collapse collapse in'}>
                                    <div className="widget-body">
                                        <div id="registration-form">
                                            <Form role="form">
                                                <div className="row row_left10">
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <table className="table table-bordered">
                                                                <thead className="text-center">
                                                                    <tr>
                                                                        <th className="word_warp_padding text-center">SKU Name<span className="text-danger">*</span></th>
                                                                        <th className="text-center">Front Pic<span className="text-danger">*</span></th>
                                                                        <th className="text-center">Back Pic</th>
                                                                        <th className="text-center">Additional Pic</th>
                                                                        {
                                                                            getHeader === 'Add' ?
                                                                                <th className="text-center">Action</th> : ''
                                                                        }
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        inputFields.map((item, index) => {
                                                                            return (
                                                                                item.IsDeleted === 0 ?
                                                                                    <>
                                                                                        <tr key={index}>
                                                                                            <td>
                                                                                                <input placeholder="Enter SKU Name" id="SKUName" name="SKUName"
                                                                                                    value={item.SKUName} type="text" onChange={event => handleInputChange(event, index)}
                                                                                                    onBlur={() => CheckDuplicate(index)}
                                                                                                    maxLength="50" autoComplete="off" className='form-control'
                                                                                                    style={{ border: submitted && item.SKUName === '' ? '1px solid red' : '' }}
                                                                                                />
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="upload_btn_wrapper2">
                                                                                                    <DocumentUpload UploadCallback={(e) => AddSKUCallback(e, index, 'FrontPic')} PageName='Style/SKU' filePath={item.SKUFrontPicPath} Buttonview={Buttonvisible} ShowDiv="1" SkuInfo={SkuInfo} />
                                                                                                </div>
                                                                                                <div className="checkbox">
                                                                                                    <label>
                                                                                                        <input type="checkbox" name="PickStylePic" onChange={event => handleInputChange(event, index)} checked={item.PickStylePic === 1 ? true : false} className="colored-blue clearOpacity" />
                                                                                                        <span className="text">Pick Style Pic</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <DocumentUpload UploadCallback={(e) => AddSKUCallback(e, index, 'BackPic')} PageName='Style/SKU' filePath={item.SKUBackPicPath} Buttonview={Buttonvisible} ShowDiv="1" SkuInfo={SkuInfo} />
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="col-lg-12">
                                                                                                    <div className="row">
                                                                                                        <div className="col-lg-3">
                                                                                                            <DocumentUpload UploadCallback={(e) => AddSKUCallback(e, index, 'Pic1')} PageName='Style/SKU' filePath={item.AdditionalPicPath1} Buttonview={Buttonvisible} ShowDiv="1" SkuInfo={SkuInfo} />
                                                                                                        </div>
                                                                                                        <div className="col-lg-3">
                                                                                                            <DocumentUpload UploadCallback={(e) => AddSKUCallback(e, index, 'Pic2')} PageName='Style/SKU' filePath={item.AdditionalPicPath2} Buttonview={Buttonvisible} ShowDiv="1" SkuInfo={SkuInfo} />
                                                                                                        </div>
                                                                                                        <div className="col-lg-3">
                                                                                                            <DocumentUpload UploadCallback={(e) => AddSKUCallback(e, index, 'Pic3')} PageName='Style/SKU' filePath={item.AdditionalPicPath3} Buttonview={Buttonvisible} ShowDiv="1" SkuInfo={SkuInfo} />
                                                                                                        </div>
                                                                                                        <div className="col-lg-3">
                                                                                                            <DocumentUpload UploadCallback={(e) => AddSKUCallback(e, index, 'Pic4')} PageName='Style/SKU' filePath={item.AdditionalPicPath4} Buttonview={Buttonvisible} ShowDiv="1" SkuInfo={SkuInfo} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </td>
                                                                                            {
                                                                                                getHeader === 'Add' ?
                                                                                                    <td>
                                                                                                        <table>
                                                                                                            <tbody>
                                                                                                                <tr>
                                                                                                                    <td style={{ paddingRight: '2px', paddingLeft: 0 }}>
                                                                                                                        {
                                                                                                                            item.ShowAddButton === 1 ?
                                                                                                                                <>
                                                                                                                                    {SkuInfo && SkuInfo.isAdd === 1 &&
                                                                                                                                        <span type="button" className="btn  btn-xs  btn-success" title="Add SKU"
                                                                                                                                            onClick={() => handleAddFields(index)}>
                                                                                                                                            <i className="fa fa-plus"></i>
                                                                                                                                        </span>
                                                                                                                                    }
                                                                                                                                </>
                                                                                                                                : ''
                                                                                                                        }
                                                                                                                    </td>
                                                                                                                    <td style={{ paddingRight: 0, paddingLeft: 0 }}>
                                                                                                                        {
                                                                                                                            inputFields.filter(prod => prod.IsDeleted === 0).length > 0 &&
                                                                                                                            <>
                                                                                                                                {
                                                                                                                                    inputFields.filter(prod => prod.IsDeleted === 0).length !== 1 ?
                                                                                                                                        <>
                                                                                                                                            {SkuInfo !== undefined && SkuInfo.isDelete === 1 &&
                                                                                                                                                <span title='Delete SKU' onClick={() => handleRemoveFields(index)}
                                                                                                                                                    className="btn btn-danger btn-xs delete"
                                                                                                                                                    style={{ display: inputFields.filter(prod => prod.IsDeleted === 0) ? '' : 'none' }}
                                                                                                                                                >
                                                                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                                                                </span>
                                                                                                                                            }
                                                                                                                                        </>
                                                                                                                                        : ''
                                                                                                                                }
                                                                                                                            </>
                                                                                                                        }
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </td>
                                                                                                    : ''
                                                                                            }
                                                                                        </tr>
                                                                                    </>
                                                                                    : ''
                                                                            );
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SKU;