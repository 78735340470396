import {
    QADETAILREPORT_LOADING,
    LOAD_QADETAILREPORT_LIST,
    LOAD_QAFINALINSPECTION_LIST,
    LOAD_QAREFINALINSPECTION_LIST,
    QA_UPDATE_COMMENT_REPORT_VIEW_ALL_CHECK_BOX,
    QA_UPDATE_COMMENT_REPORT_VIEW_CHECK_BOX
} from "../actions/types"

const initialState = {
    QADetailsReportList: [],
    QAInspectionList: [],
    ReQAInspectionList: [],
    isLoadingQADetailsReport: true,
};

const inspectionQCReducer = (state = initialState, action) => {

    const { type, payload } = action
    switch (type) {
        case QADETAILREPORT_LOADING:
            return {
                ...state,
                isLoadingQADetailsReport: payload
            };
        case LOAD_QADETAILREPORT_LIST:
            return {
                ...state,
                QADetailsReportList: payload
            }
        case LOAD_QAFINALINSPECTION_LIST:
            return {
                ...state,
                QAInspectionList: payload
            }
        case LOAD_QAREFINALINSPECTION_LIST:
            return {
                ...state,
                ReQAInspectionList: payload
            }

        case QA_UPDATE_COMMENT_REPORT_VIEW_ALL_CHECK_BOX:

            let isCheck = payload;
            let List = state.QADetailsReportList;


            let Totallitem = List && List.item1;
            if (isCheck) {
                Totallitem && Totallitem.filter(x => x.isSelected === true).map((item, Index) => {
                    item.isSelected = isCheck
                });
                List.item1 = [...Totallitem];
            } else {
                Totallitem && Totallitem.filter(x => x.isSelected === false).map((item, Index) => {
                    item.isSelected = isCheck
                });
                List.item1 = Totallitem;
            }

            // List = List
            return {
                ...state,
                QADetailsReportList: List
            };
        case QA_UPDATE_COMMENT_REPORT_VIEW_CHECK_BOX:
            let Index = payload.index;
            let isChecked = payload.isChecked;
            let Comments = state.QADetailsReportList;
            let Totallitems = Comments && Comments.item1
            Totallitems[Index].isSelected = payload.isChecked;
            Comments.item1 = Totallitems;

            return {
                ...state,
                QADetailsReportList: Comments
            };


        default:
            return state;
    }
}
export default inspectionQCReducer;
