import {
    TASKTYPE_LOADING,
    RETRIEVE_TASKTYPE,
    TASKTYPE_DROPDOWN_LIST,
    TASKTYPE_DROPDOWN_MAP_LIST,
    DELETE_TASKTYPE,
} from "../actions/types";

const initialState = {
    isLoadingtaskType: true,
    taskTypeList: [],
    drpTaskTypeList: [],
    drpTaskTypeMapList: [],
};

const taskTypeReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case TASKTYPE_LOADING:
            return {
                ...state,
                isLoadingtaskType: payload
            };
        case RETRIEVE_TASKTYPE:
            return { ...state, taskTypeList: payload };
        case TASKTYPE_DROPDOWN_MAP_LIST:
            return { ...state, drpTaskTypeMapList: payload };
        case TASKTYPE_DROPDOWN_LIST:
             return { ...state, drpTaskTypeList: payload };
        case DELETE_TASKTYPE:
            const results = state.taskTypeList.filter(c => c.taskTypeId !== payload.taskTypeId);
            const drpResults = state.drpTaskTypeList.filter(c => c.id !== payload.taskTypeId);
             const drpMapResults = state.drpTaskTypeMapList.filter(c => c.id !== payload.taskTypeId);
            return {
                ...state,
                taskTypeList: results,
                drpTaskTypeList: drpResults,
                drpTaskTypeMapList:drpMapResults
            };

        default:
            return state;
    }
};
export default taskTypeReducer;