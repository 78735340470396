import {
    SWATCH_LOADING,
    RETRIEVE_SWATCH,
    DELETE_SWATCH,
} from "../actions/types";

const initialState = {
    isLoadingSwatch: true,
    SwatchList: [],
};

const swatchReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SWATCH_LOADING:
            return {
                ...state,
                isLoadingSwatch: payload
            };
        case RETRIEVE_SWATCH:
            return { ...state, SwatchList: payload };
        case DELETE_SWATCH:
            const results = state.SwatchList.filter(c => c.swatchMasterID !== payload.swatchMasterID);
            return {
                ...state,
                SwatchList: results
            };

        default:
            return state;
    }
};
export default swatchReducer;