import React, { useState, useEffect, useMemo } from "react";
import ReactNotification from 'react-notifications-component';
import { TableHeader, Pagination, Search, PageCount } from "../../Datatable";
import { useDispatch, useSelector } from "react-redux";
import { retrieveworkmanshipGrp } from "../../../actions/workmangroup"
import CommonService from '../../../services/Common/CommonService';
import Nodify from "../../Common/ReactNotification";
import SearchRetain from "../../Common/SearchRetain";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import SweetAlertPopup from '../../Common/SweetAlertPopup';
import { Redirect } from 'react-router-dom';
import WorkmanshipItem from "../../../services/Master/WorkmanshiGroup";
import WorkmanshiGroup from "../../../services/Master/WorkmanshiGroup";
import SelectCombobox from 'react-select';
const EditWorkmanGroup = (props) => {
   
    const [WorkmandetList, setWorkmandetList] = useState([]);// for workmanitem list
    const [categoryTypeList, setcategoryTypeList] = useState([]);// for cagetgory list
    const [buttonLoader, setButtonLoader] = useState(false);
    const [workmanmatchList, setworkmanmatchList] = useState([]);// For cagetgoryID based matched value workmanitemID and Workmanitemname
    const [submitted, setSubmitted] = useState(false);
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [getWorkmanshipGp, SetWorkmanshipGp] = useState([{ Value: 0, label: '' }]);//For handle changes uses
    const { user: currentUser } = useSelector((state) => state.auth);
    useEffect(() => {
        let Workmanshidetails = [];
        let Groupandcattype = [];
        let workmanmatchList = [];
       
        WorkmanshiGroup.GetworkmanshipGroupList(2, props.location.state.params.categoryID).then((res) => {
           
            if (res.data) {
                res.data.workmanshipgroupEdit2.map(x => {
                    workmanmatchList.push({
                        label: x.workmanItemName,
                        value: x.workmanshipItemID,
                        Isdeleted: 0,
                        workmanshipGroupID: x.workmanshipGroupID
                    })
                })
                setworkmanmatchList(workmanmatchList)
            }
        }, [])
        WorkmanshipItem.GetWorkmanshipItemList(0).then((res) => {
           
            if (res.data) {
                res.data.map(x => {
                    Workmanshidetails.push({
                        label: x.workmanItemName,
                        value: x.workmanshipItemID
                    })
                })

                setWorkmandetList(Workmanshidetails)
            }
        })

        WorkmanshipItem.LoadWorkmanGroupList(props.location.state.params.categoryID).then((res) => {
           
            if (res.data) {
                res.data.map(x => {
                    Groupandcattype.push({
                        label: x.label,
                        value: x.value
                    })
                })

                setcategoryTypeList(Groupandcattype)
            }
        })

    }, [])
    const handleRemoveFields = (index, value) => {
       
        const values = [...workmanmatchList]
        // if (values[index].workmanshipGroupID !== undefined && values[index].workmanshipGroupID !== 0) {
        //     values[index].Isdeleted = 1;
        // } else {
        //     values.splice(index, 1);
        // }

        let Params = {
            Operation: 135,
            Id: value.value,
        }
        CommonService.CheckMappedItem(Params).then(res => {
            if (res.data.outputResult === "-2") {
                let Title = "Warning!";
                let message = "This item is already mapped.";
                let type = "warning";
                Nodify(Title, type, message);
            }
            else {
                values.splice(index, 1);
            }

            setworkmanmatchList(values)
        });
    };
    const handleAddFields = (index) => {
       
        const values = [...workmanmatchList];
        if (values[index].value !== 0 && values[index].value !== undefined) {
            // Create a new row
            const newRow = { id: Date.now() };

            // Update the rows array
            setworkmanmatchList((prevRows) => {
                // Insert the new row at the specified index
                const updatedRows = [...prevRows.slice(0, index + 1), newRow, ...prevRows.slice(index + 1)];

                // Increment the indices of rows following the insertion point
                const updatedRowsWithIds = updatedRows.map((row, i) => ({ ...row, id: i }));

                return updatedRowsWithIds;
            });
            setSubmitted(false);
            // values.push({ value: 0, label: "" });
            // setmatchingList(values);
        }
        else {
            Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
            setSubmitted(true);
        }
    };
    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/WorkmanshipGroupList');
        } else {
            window.location.reload();
            // let getGroupList = GroupListing(props);
            // SetState(getGroupList)
        }
    }
    const headers = [
        { name: <span>Name<span className="text-danger">*</span></span>, field: "workmanItemName", sortable: false },
        {
            name: "Action", field: "", sortable: false,
            // display: workmanmatchList.length === 1 ? "none" : "",
            width: "13%"
        },
    ];

    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),

    }
    const handleInputChange = (index, e, FieldName) => {
       
        const values = [...workmanmatchList]
        if (FieldName === "Workmanshipdetail") {
            if (e !== null) {
                values[index].value = e.value
                values[index].label = e.label
            }
            else {
                values[index].value = 0
                values[index].label = ''
            }
        }
        if (values.length > 1)
            for (var j = 0; j < (workmanmatchList.length); j++) {
                if (j !== index) {
                    if (values[j].value === values[index].value) {
                        values[index].value = 0;
                        setworkmanmatchList(values);
                        Nodify('Warning!', 'warning', 'This Workmanship Detail is already exist.');
                        setSubmitted(true);
                        return false;
                    }
                }
            }
        setworkmanmatchList(values)
    }
    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/WorkmanshipGroupList",
            state: {
                message: Func,
            },
        }
        );
    };
    const SaveWorkmanGroup = (e) => {
        setButtonLoader(true);
        e.preventDefault();
        let isvalid = true;
       
        for (var i = 0; i < (workmanmatchList.length); i++) {
            if (workmanmatchList[i].value === 0 || workmanmatchList[i].value === undefined) {
                Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
                setSubmitted(true);
                setButtonLoader(false);
                isvalid = false
                return false;
            }
        }
        if (isvalid) {
            let workmanshipGrpEditID = []
            workmanmatchList.map((x) => {
                workmanshipGrpEditID.push({
                    workmanshipGroupID: x.workmanshipGroupID === undefined ? 0 : x.workmanshipGroupID,
                    workmanshipItemID: x.value,
                    Isdeleted: x.Isdeleted === undefined ? 0 : x.Isdeleted
                })
            })
            let InputMaster = { Operation: 2, CategoryID: categoryTypeList[0].value, CategoryName: categoryTypeList[0].label, workmanshipGrpInformation: workmanshipGrpEditID, Createdby: currentUser.employeeinformationID }
            // console.log(InputMaster);
            WorkmanshiGroup.UpdateWorkmanshipGrp(InputMaster).then(res => {
                var page = "Remove";
                SearchRetain(page);
                let Func = 'Add';
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    PageRedirect(Func);
                }
                setButtonLoader(false);
            });
        }
    }
    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">Edit Workmanship Template</h3>
                        </div>

                        <div className="widget-body">
                            <div className="row">
                                <div className="col-lg-12 margin-top-10">
                                    <label>Category Template<span className="text-danger">*</span></label>
                                </div>
                                <div className="col-sm-3">
                                    <SelectCombobox className="basic-single" name="Category" id={"Category"}
                                        isDisabled={true}
                                        isLoading={false}
                                        isSearchable={true}
                                        isClearable={true}
                                        autocomplete='off'
                                        //onChange={event => handleInputCategoryChange(event, "Category")}
                                        //styles={submitted && getCategoryGp === undefined ? styles : ''}
                                        //options={categoryTypeList}
                                        // value={categoryTypeList.filter(function (option) {
                                        //     return option.value === getCategoryGp.value;
                                        // })}
                                        value={categoryTypeList}
                                    />
                                </div>
                            </div>
                            <div id="registration-form">
                                <form>
                                    {
                                        <table className="table table-striped table-bordered table-hover dataTable no-footer margin-top-10" id="editabledatatable" style={{ width: "50%" }}>
                                            <TableHeader headers={headers} onSorting={(field, order) => setSorting({ field, order })} />
                                            <tbody>
                                                {workmanmatchList.filter(x => x.Isdeleted !== 1).map((inputfeild, index) => (
                                                    <tr>
                                                        <td>
                                                            <SelectCombobox className="basic-single" name="Workmanshipdetail" id={"Workmanshipdetail" + index}
                                                                isDisabled={false}
                                                                isLoading={false}
                                                                isSearchable={true}
                                                                isClearable={true}
                                                                autocomplete='off'
                                                                placeholder="Select Workmanship Detail"
                                                                styles={submitted && (inputfeild.value === 0 || inputfeild.value === undefined) ? styles : ''}
                                                                onChange={event => handleInputChange(index, event, "Workmanshipdetail")}
                                                                options={WorkmandetList}
                                                                value={WorkmandetList.filter(function (option) {
                                                                    return option.value === inputfeild.value;
                                                                })}
                                                            //value={inputfeild}
                                                            />
                                                        </td>

                                                        <td style={{ width: "13%" }}>
                                                            {
                                                                workmanmatchList.filter(x => x.Isdeleted !== 1).length !== 1 && (
                                                                    <span title='Delete Workmanship Group' onClick={() => handleRemoveFields(index, inputfeild)}
                                                                        className="btn btn-danger btn-xs delete">
                                                                        <i className="fa fa-trash-o"></i>
                                                                    </span>
                                                                )

                                                            }
                                                            &nbsp;
                                                            {/* {
                                                                workmanmatchList.filter(x => x.Isdeleted !== 1).length === (index + 1) && ( */}
                                                            <span title='Add Workmanship Group'
                                                                onClick={() => handleAddFields(index)}
                                                                className="btn btn-info btn-xs plus">
                                                                <i className="fa fa-plus"></i>
                                                            </span>
                                                            {/* )
                                                            } */}
                                                            {/* {workmanmatchList.length} */}
                                                        </td>
                                                        {/* <span> {index}</span> */}
                                                    </tr>

                                                ))

                                                }
                                            </tbody>
                                        </table>
                                    }
                                    <div className="col-sm-12">
                                        <div className="buttons-preview" align="right">
                                            <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                                <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                                            </span>
                                            <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                                <i className="fa fa-times"></i>&nbsp;Reset
                                            </span>
                                            <button type="button" className="btn btn-success" disabled={buttonLoader} onClick={SaveWorkmanGroup}>
                                                <i className="fa fa-check right"></i>&nbsp;Update
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditWorkmanGroup;