import {
    TASK_LOADING,
    RETRIEVE_TASK,
    TASK_ROW,
    DELETE_TASK,
    TASK_DROPDOWN_LIST,
    ALL_TASK_DROPDOWN_LIST,
    TASK_HOLDER_DROPDOWN_LIST
} from "../actions/types";

const initialState = {
    isLoadingTask: true,
    taskList: [],
    taskRow: [],
    drpAllTaskList: [],
    drpTaskList: [],
    drpTaskHolderList:[]
};

const taskReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case TASK_LOADING:
            return {
                ...state,
                isLoadingTask: payload
            };
        case RETRIEVE_TASK:
            return { ...state, taskList: payload };
         case TASK_ROW:
            return { ...state, taskRow: payload };
        case TASK_DROPDOWN_LIST:
            return { ...state, drpTaskList: payload };
        case ALL_TASK_DROPDOWN_LIST:
            return { ...state, drpAllTaskList: payload };
        case DELETE_TASK:
            const results = state.taskList.filter(c => c.taskDetailsID !== payload.taskDetailsID);
            const taskList = state.drpTaskList.filter(c => c.id !== payload.taskDetailsID);
             const allTaskList = state.drpAllTaskList.filter(c => c.id !== payload.taskDetailsID);
            return {
                ...state,
                taskList: results,
                drpTaskList: taskList,
                drpAllTaskList:allTaskList
            };
        case TASK_HOLDER_DROPDOWN_LIST:
            
             return { ...state, drpTaskHolderList: payload };
        default:
            return state;
    }
};
export default taskReducer;