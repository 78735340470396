import axios from "axios";
import authHeader from "../auth-header";

class ColumnInformationService {

  getColumnInformationList(Operation, ModuleId) {
    return axios.get(window.$APIBaseURL + "Master/GetColumnInformationList?Operation=" + Operation + "&ModuleID=" + ModuleId, {
      headers: {
        authorization: authHeader()
      }
    });
  }
  GetScReporttempInfoList(ReporttempID, ReportTempIDList) {
    return axios.get(window.$APIBaseURL + "Master/GetScReporttempInfoList?ReporttempID=" + ReporttempID + "&ReportTempIDList=" + ReportTempIDList, {
      headers: {
        authorization: authHeader()
      }
    });
  }


  loadModuleInformationList() {
    return axios.get(window.$APIBaseURL + "Master/LoadModuleInformationList?ModuleID=0", {
      headers: {
        authorization: authHeader()
      }
    });
  }
  insertUpdateColumnInformationGrid(Params) {
    return axios({
      method: 'post',
      url: window.$APIBaseURL + 'Master/InsertUpdateColumnInformationGrid',
      data: Params,
      headers: {
        authorization: authHeader()
      }
    });
  }
  AddColumnInformation(Params) {
    return axios({
      method: 'post',
      url: window.$APIBaseURL + 'Master/InsertUpdateDelColumnInformation',
      data: Params,
      headers: {
        authorization: authHeader()
      }
    });
  }


}
export default new ColumnInformationService()