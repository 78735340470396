import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  ACTIVE_MENUS,
  SIDE_BAR_MENU_VISIBLE,
  FULL_SIDE_BAR_MENU_VISIBLE,
  RETRIEVE_ALLMENU,
  REQUESTOTP_FORGOTPASSWORD,
  ERASE_FORGOTPASSWORD_OTP//commonReducer
} from "./types";

import AuthService from "../services/auth.service";

export const register = (username, email, password) => (dispatch) => {
  return AuthService.register(username, email, password).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const login = (username, password, MPIN) => (dispatch) => {
  return AuthService.login(username, password, MPIN).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });

      //commonReducer
      dispatch({
        type: RETRIEVE_ALLMENU,
        payload: data,
      });
      return Promise.resolve(data.item4);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
        //payload: message,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};

export const activeMenus = (activeMenus) => (dispatch) => {
  dispatch({
    type: ACTIVE_MENUS,
    payload: activeMenus,
  });
};

export const sidebarMenuVisible = (isVisible) => (dispatch) => {
  dispatch({
    type: SIDE_BAR_MENU_VISIBLE,
    payload: isVisible,
  });
};

export const fullSidebarMenuVisible = (isVisible) => (dispatch) => {
  dispatch({
    type: FULL_SIDE_BAR_MENU_VISIBLE,
    payload: isVisible,
  });
};

export const OTPForForgotPassword = (data) => async (dispatch) => {
  let res = await AuthService.RequestOTPforForgotPassword(data);
  dispatch({
    type: REQUESTOTP_FORGOTPASSWORD,
    payload: res.data,
  });
  return Promise.resolve(res.data);
};

export const UpdateForgotPassword = (data) => async (dispatch) => {
  const res = await AuthService.UpdateNewPassword(data);
  return Promise.resolve(res.data);
};

export const EraseforgotPasswordOTP = () => async (dispatch) => {
  dispatch({
    type: ERASE_FORGOTPASSWORD_OTP,
    payload: {},
  });
};


