import React, { useState, useEffect, Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetSubChildMultExFactoryFormSKUList } from './../../../../actions/tna';
import TNAService from "../../../../services/TNA/TNAService";
import Nodify from "../../../Common/ReactNotification";
const AddInspection = ({ props, skuSelectData, finalInspectionList, commonSubmitApprovalParams, FinalInspectionCallback, SubTaskInfo }) => {
    ;
    const [selectall, setselectall] = useState(false);
    const [MultiCloseDatas, setMultiCloseDatas] = useState([]);
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
    const dispatch = useDispatch();
    const reducerState = useSelector((state) => state);
    const TNAAddFinalInspectionList = reducerState.tna.tnaAddfinalInspection !== undefined ? reducerState.tna.tnaAddfinalInspection : [];
    let StartStopInfo;
    let activeMenu;
    let activeMenuSub;
    if (menulist !== undefined && menulistSub.length > 0) {
        activeMenu = menulist.filter(i => i.menuName === 'TNA');
        if (activeMenu.length > 0) {
            activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);
            if (activeMenuSub.length > 0) {
                for (let index = 0; index < activeMenuSub.length; index++) {
                    if (activeMenuSub[index].menuName === 'TNAstartstop') {
                        StartStopInfo = activeMenuSub[index];
                    }
                }
            }
        }
    }

    useEffect(() => {
        ;
        // TNAService.GetSubChildMultExFactoryFormSKUList(finalInspectionList).then((res) => {
        //     if (res.data) {

        //     }
        // })
        dispatch(GetSubChildMultExFactoryFormSKUList(finalInspectionList));
    }, []);



    const SelectAllExFactory = (event) => {

        let IsSelected = 0;
        if (event.target.checked === true) {
            IsSelected = 1;
            TNAAddFinalInspectionList.filter(x => x.balanceQuantity !== "0" && x.dependencyStartstop === 1).forEach(element => {
                element.isSelected = 1;
            })
            MultiSelecteddate(TNAAddFinalInspectionList)
        }
        else {
            IsSelected = 0;
            TNAAddFinalInspectionList.forEach(element => {
                element.isSelected = 0;
            });
        }
        setselectall(IsSelected);
        MultiSelecteddate(TNAAddFinalInspectionList)
    }

    const OnSelectExFactoryRecords = (event, index) => {

        const value = [...MultiCloseDatas];

        // if (value[index].dependencyStartstop === 1) {
        if (event.target.checked === true) {
            value[index].isSelected = 1;
            MultiSelecteddate(value)
        }
        else {
            value[index].isSelected = 0;

        }
        // }
        // else {
        //  Nodify('Warning!', 'warning', 'please Stop the dependency in relevant TNA');
        //}
        let isSelectAll = 0;
        if (value) {
            isSelectAll = !!!value.find(d => d.isSelected === 0);
            isSelectAll = isSelectAll ? 1 : 0;
        }
        setselectall(isSelectAll);
        setMultiCloseDatas(value);

    }
    const MultiSelecteddate = (value) => {

        FinalInspectionCallback(value);
    }

    function getSelectedDependencyMap(SelectedDependency) {
        let Dependency = true;
        // if (SelectedDependency) {
        //     let arrstatus = [];
        //     arrstatus = SelectedDependency.split(',');
        //     if (arrstatus.length > 0) {
        //         arrstatus.map(element => {
        //             let val = [];
        //             val = element.split('-');
        //             let valIndex = finalInspectionList[parseInt(val[0]) - 1].statusID;
        //             if (valIndex !== 3) {
        //                 Dependency = false;
        //             }
        //         });
        //     }
        // }
        return Dependency;
    }
    // useMemo(() => {

    //     let computedComments = TNAAddFinalInspectionList;
    //     computedComments.map((res, index) => {
    //         if (res.skuID === skuSelectData) {
    //             res.isSelected = 1;
    //             return res;
    //         } else {
    //             return res;
    //         }
    //     })

    //     setMultiCloseDatas(computedComments)
    //     FinalInspectionCallback(computedComments)
    //     return computedComments;

    // }, [TNAAddFinalInspectionList]);


    useMemo(() => {
        if (TNAAddFinalInspectionList?.length > 0) {
            TNAAddFinalInspectionList.forEach((r, i) => {
                SubTaskInfo?.forEach(c => {
                    if (r.subTaskInfoId === c?.ChildId) {
                        TNAAddFinalInspectionList[i] = {
                            ...TNAAddFinalInspectionList[i],
                            IsDepandency: c.IsDepandency,
                            Depandency: c.Depandency,
                            dependencyStartstop: c.intDependency
                        };
                    }
                });
            });

            let computedComments = [...TNAAddFinalInspectionList]; // create a new array to avoid mutating state directly
            debugger
            computedComments.forEach(element => {
                if (skuSelectData?.toString() === element.purchaseOrderSkuID?.toString() && finalInspectionList.TnaId === element.tnaId) {
                    element.isSelected = 1;
                }
            });


            setMultiCloseDatas(computedComments)
            FinalInspectionCallback(computedComments)
            return computedComments;


        }
    }, [TNAAddFinalInspectionList, SubTaskInfo]);
    return (
        <Fragment>
            <div className="inspection" style={{ height: "70vh", overflowX: "scroll", overflowY: "scroll", width: "100%" }}>
                <label className="alert alert-info comment-report-note"><b>Note : </b>Added comments report factory info, shown here!</label>
                <table className="table table-bordered">
                    <thead>
                        <tr style={{ whiteSpace: "nowrap" }}>
                            {/* <th>Style Name</th> */}
                            <th>Style No</th>
                            <th>SKU ID</th>
                            <th>SKU Name</th>
                            <th>PO/ID No</th>
                            {finalInspectionList.BuyerName === 'Focus International' ? < th > FO PO No</th> : ''}
                            <th>Factory</th>
                            <th>Total Order Qty</th>
                            <th>Bal. for Inspection</th>
                            <th>Task Holder / Follower</th>
                            <th>
                                <label style={{ marginTop: '8px' }}>
                                    <input type="checkbox" name="IsSelected"

                                        onChange={event => (SelectAllExFactory(event))}
                                        checked={selectall === 1 ? true : false}
                                        className="colored-blue clearOpacity" />
                                    <span className="text"></span>
                                </label>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            MultiCloseDatas.length !== 0 && MultiCloseDatas.map((item, index) => {
                                return (
                                    <>
                                        <tr>
                                            {/* <td>{item.styleName}</td> */}
                                            <td>{item.styleNo}</td>
                                            <td>{item.skuID}</td>
                                            <td>{item.skuName}</td>
                                            <td>{item.idNo}</td>
                                            {finalInspectionList.BuyerName === 'Focus International' ? <td>{item.poNo}</td> : ''}
                                            <td >{item.factory === null ? '-' : item.factory}</td>
                                            <td>{item.orderQuantity}</td>
                                            <td>{item.balanceQuantity}</td>
                                            <td className="cut_textInTNA" title={item.taskOwnerNameList} >{item.taskOwnerNameList}</td>
                                            {/* <td className="Actionbutton">
                                                <div style={{ width: "80px" }}>
                                                    {
                                                        (item.dependencyStartstop === 1 || (!finalInspectionList.selectedDependency) || getSelectedDependencyMap(finalInspectionList.selectedDependency) === true || item.isSelected === 1
                                                        ) &&
                                                        <label style={{ marginTop: '8px' }}>
                                                            <input type="checkbox" name="IsSelected"
                                                                disabled={item.balanceQuantity === "0" ? true : false}
                                                                onChange={event => (OnSelectExFactoryRecords(event, index))}
                                                                checked={item.isSelected === 1 ? true : false}
                                                                className="colored-blue clearOpacity" />
                                                            <span className="text"></span>
                                                        </label>
                                                    }&nbsp;&nbsp;&nbsp;
                                                </div>
                                            </td> */}
                                            {props.location.pathname === '/TNAEdit' ?
                                                // <td className="Actionbutton">
                                                //     <div style={{ width: "80px" }}>
                                                //         {
                                                //             // (item.dependencyStartstop === 1 || (!QCfinalInspectionList.selectedDependency) || getSelectedDependencyMap(QCfinalInspectionList.selectedDependency) === true || item.isSelected === 1
                                                //             (QCfinalInspectionList.selectedDependency !== '' && item.dependencyStartstop === 1
                                                //             ) ?
                                                //                 <label style={{ marginTop: '8px' }}>
                                                //                     <input type="checkbox" name="IsSelected"
                                                //                         //   disabled={item.balanceQuantity === "0" ? true : false}
                                                //                         onChange={event => (onSelectExFactoryRecords(event, index, item))}
                                                //                         checked={item.isSelected === 1 ? true : false}
                                                //                         className="colored-blue clearOpacity" />
                                                //                     <span className="text"></span>
                                                //                 </label>
                                                //                 :
                                                //                 QCfinalInspectionList.selectedDependency === '' ?
                                                //                     <label style={{ marginTop: '8px' }}>
                                                //                         <input type="checkbox" name="IsSelected"
                                                //                             //   disabled={item.balanceQuantity === "0" ? true : false}
                                                //                             onChange={event => (onSelectExFactoryRecords(event, index, item))}
                                                //                             checked={item.isSelected === 1 ? true : false}
                                                //                             className="colored-blue clearOpacity" />
                                                //                         <span className="text"></span>
                                                //                     </label>
                                                //                     : ''

                                                //         }  &nbsp;&nbsp;&nbsp; {


                                                //             // StartStopInfo && StartStopInfo.isAdd !== 0 &&
                                                //             // inputField.SelectedDependency && getSelectedDependencyMap(inputField.SelectedDependency) === false && childTask.StatusID !== 3 &&
                                                //             item.Depandency !== '' && QCfinalInspectionList.selectedDependency !== '' &&
                                                //             <button type="button" className={(item.dependencyStartstop === 1) ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Dependency)"
                                                //             // onClick={() => OpenDependentStartPopup(index, subIndex, childIndex, item.dependencyStartstop, props.location.state.params.tnaid, inputField.TaskID, childTask.ChildId, inputField.IsSingleTask)}
                                                //             >
                                                //                 <i className={item.dependencyStartstop === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>

                                                //             </button>
                                                //         }

                                                //     </div>
                                                // </td>

                                                <td className="Actionbutton fixed-column">
                                                    {


                                                        // (item.dependencyStartstop === 1 || (!inputField.SelectedDependency) || getSelectedDependencyMap(inputField.SelectedDependency) === true || item.isSelected === 1
                                                        //  ) &&
                                                        (item.Depandency !== '' && item.dependencyStartstop === 1) ?
                                                            <label style={{ marginTop: '8px' }}>
                                                                <input type="checkbox" name="IsSelected"
                                                                    onChange={event => (OnSelectExFactoryRecords(event, index, item))}
                                                                    checked={item.isSelected === 1 ? true : false}
                                                                    className="colored-blue h clearOpacity" />
                                                                <span className="text"></span>
                                                            </label>
                                                            :
                                                            item.Depandency === '' ?
                                                                <label style={{ marginTop: '8px' }}>
                                                                    <input type="checkbox" name="IsSelected"
                                                                        onChange={event => (OnSelectExFactoryRecords(event, index, item))}
                                                                        checked={item.isSelected === 1 ? true : false}
                                                                        className="colored-blue hd clearOpacity" />
                                                                    <span className="text"></span>
                                                                </label> : ''
                                                    }&nbsp;&nbsp;&nbsp;

                                                    {
                                                        // StartStopInfo && StartStopInfo.isAdd !== 0 &&
                                                        // inputField.SelectedDependency && getSelectedDependencyMap(inputField.SelectedDependency) === false && childTask.StatusID !== 3 &&
                                                        item.Depandency !== '' &&
                                                        <button type="button" className={(item.dependencyStartstop === 1) ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Dependency)"
                                                        // onClick={() => OpenDependentStartPopup(index, subIndex, childIndex, item.dependencyStartstop, props.location.state.params.tnaid, inputField.TaskID, childTask.ChildId, inputField.IsSingleTask)}
                                                        >
                                                            <i className={item.dependencyStartstop === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>

                                                        </button>
                                                    }

                                                </td>

                                                :
                                                <td className="Actionbutton">
                                                    <label style={{ marginTop: '8px' }}>
                                                        <input type="checkbox"
                                                            name="IsSelected"
                                                            onChange={event => OnSelectExFactoryRecords(event, index, item)}
                                                            checked={item.isSelected === 1}
                                                            className="colored-blue clearOpacity" />
                                                        <span className="text"></span>
                                                    </label>
                                                </td>}

                                        </tr>
                                        {MultiCloseDatas.length === 0 ?
                                            < tr >
                                                <td colSpan="11" className='norecordfound'><span>No Records Found</span></td>
                                            </tr > : ''}
                                    </>

                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </Fragment >
    )
};
export default AddInspection;