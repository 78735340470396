import {
    GROUPTYPE_LOADING,
    RETRIEVE_GROUPTYPE,
    DELETE_GROUPTYPE,
} from "../actions/types";

const initialState = {
    isLoadingGrouptype: true,
    groupTypeList: [],
};

const taskReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GROUPTYPE_LOADING:
            return {
                ...state,
                isLoadingGrouptype: payload
            };
        case RETRIEVE_GROUPTYPE:
            return { ...state, groupTypeList: payload };
        case DELETE_GROUPTYPE:
            const results = state.groupTypeList.filter(c => c.groupTypeID !== payload.groupTypeID);
            return {
                ...state,
                groupTypeList: results
            };

        default:
            return state;
    }
};
export default taskReducer;