import {
    AQLMASTER_LOADING,
    RETRIEVE_AQLMASTER,
    DELETE_AQLMASTER
} from "./types";


import AqlService from "../services/Master/AqlService";


const AqlMasterLoading = (isStatus) => ({
    type: AQLMASTER_LOADING,
    payload: isStatus,
});

// export const createDefect = (getDefect) => async (dispatch) => {

//  let resData = "";
//  await DefectService.AddDefect(getDefect).then((res) => {
//   resData = res.data;

//  }).catch((err) => { return Promise.reject(err); });
//  return Promise.resolve(resData);
// }


// export const deleteDefect = (defectTypeID) => async (dispatch) => {
//  try {
//   dispatch({
//    type: DELETE_DEFECT,
//    payload: defectTypeID,
//   });

//  } catch (err) {
//   console.log(err);
//  }
// };


export const retrieveAqlMaster = (Id) => async (dispatch) => {
    try {

        dispatch(AqlMasterLoading(true));
        const res = await AqlService.getAllAqlList(Id);
        dispatch({
            type: RETRIEVE_AQLMASTER,
            payload: res.data,
        });
        dispatch(AqlMasterLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(AqlMasterLoading(false));
    }
};
