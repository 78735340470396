import React, { Fragment, useState, useMemo, useEffect } from "react";
import Reactselect from 'react-select';
import FinalinspectionService from "../../../../services/FinalInspection/FinalinspectionService";
import Nodify from "../../../Common/ReactNotification";
import QcInspection from '../../../../services/QCInspection/QcInspection';
const PPWorkmanshipList = ({ props, WorkmanhsipContList, trimvalidation }) => {
    const [getWorkmanshipValue, setWorkmanshipValue] = useState([]);
    const [getCommonResult, setCommonResult] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const QcinpsectionId = JSON.parse(localStorage.getItem("QCInspectionID"));
    useEffect(() => { setSubmitted(trimvalidation) }, [trimvalidation === true])
    useEffect(() => {
        let StyleAndColorEdit = [];
        if (props.location.state.params.TaskStatus === 2) {
            QcInspection.getAllQcInfoList(4, props.location.state.params.details.tnaid, props.location.state.params.TaskStatus === 1 ? QcinpsectionId : props.location.state.params.TNAQCInspectionID).then((res) => {
               
                if (res.data.qcWorkManshiDetails.length > 0) {
                    res.data.qcWorkManshiDetails.map((x, index) => {
                        StyleAndColorEdit.push({
                            TNAQCInspecPPworkmanshipID: x.tnaQcPPWorkManshipID,
                            workmanshipName: x.workmanshipName,
                            WorkmanResultID: x.workmanResultID,
                            WorkmanFindingsRemarks: x.workmanshipNotes,
                            WorkmanMainIndex: index,
                        })
                    })
                    setWorkmanshipValue(StyleAndColorEdit);
                    WorkmanhsipContList(StyleAndColorEdit);
                } else {
                    FinalinspectionService.getWorkmantempList(props.location.state.params.styleID).then((res) => {
                        if (res.data) {
                            res.data.map((x, index) => {
                                StyleAndColorEdit.push({
                                    TNAQCInspecPPworkmanshipID: 0,
                                    workmanshipName: x.workmanItemName,
                                    WorkmanResultID: 0,
                                    WorkmanFindingsRemarks: '',
                                    WorkmanMainIndex: index,
                                })
                            })
                            setWorkmanshipValue(StyleAndColorEdit);
                            WorkmanhsipContList(StyleAndColorEdit);
                        }
                    })
                }

            })
        } else {
            if (QcinpsectionId !== null) {
                QcInspection.getAllQcInfoList(4, props.location.state.params.details.tnaid, props.location.state.params.TaskStatus === 1 ? QcinpsectionId : props.location.state.params.TNAQCInspectionID).then((res) => {
                   
                    if (res.data.qcWorkManshiDetails.length > 0) {
                        res.data.qcWorkManshiDetails.map((x, index) => {
                            StyleAndColorEdit.push({
                                TNAQCInspecPPworkmanshipID: x.tnaQcPPWorkManshipID,
                                workmanshipName: x.workmanshipName,
                                WorkmanResultID: x.workmanResultID,
                                WorkmanFindingsRemarks: x.workmanshipNotes,
                                WorkmanMainIndex: index,
                            })
                        })
                        setWorkmanshipValue(StyleAndColorEdit);
                        WorkmanhsipContList(StyleAndColorEdit);
                    } else {
                        FinalinspectionService.getWorkmantempList(props.location.state.params.styleID).then((res) => {
                            if (res.data) {
                                res.data.map((x, index) => {
                                    StyleAndColorEdit.push({
                                        TNAQCInspecPPworkmanshipID: 0,
                                        workmanshipName: x.workmanItemName,
                                        WorkmanResultID: 0,
                                        WorkmanFindingsRemarks: '',
                                        WorkmanMainIndex: index,
                                    })
                                })
                                setWorkmanshipValue(StyleAndColorEdit);
                                WorkmanhsipContList(StyleAndColorEdit);
                            }
                        })
                    }

                })
            } else {
                FinalinspectionService.getWorkmantempList(props.location.state.params.styleID).then((res) => {
                    if (res.data) {
                        res.data.map((x, index) => {
                            StyleAndColorEdit.push({
                                TNAQCInspecPPworkmanshipID: 0,
                                workmanshipName: x.workmanItemName,
                                WorkmanResultID: 0,
                                WorkmanFindingsRemarks: '',
                                WorkmanMainIndex: index,
                            })
                        })
                        setWorkmanshipValue(StyleAndColorEdit);
                        WorkmanhsipContList(StyleAndColorEdit);
                    }
                })
            }
        }

        var MeetingConclusionList = [];
        QcInspection.LoadPPmeetingDpList().then((res) => {
            if (res.data) {
                res.data.workmanconsDpList.map((x) => {
                    MeetingConclusionList.push({
                        value: x.ppWorkmanshipDpId,
                        label: x.ppWorkmanshipDpName
                    })
                })
                setCommonResult(MeetingConclusionList);
            }
        })
    }, [])

    let styles = {
        control: styles => ({ ...styles, border: '1px solid red', innerHeight: '100px', outerHeight: '100px' }),
    }
    function handleCommonResultInputChange(index, e, FeildName) {
       
        const values = [...getWorkmanshipValue];
        let inputText = ""
        if (FeildName === "WorkmanshipResult") {
            if (e !== null) {
                values[index].WorkmanResultID = e.value;
                values[index].WorkmanResultName = e.label;
            } else {
                values[index].WorkmanResultID = 0;
                values[index].WorkmanResultName = '';
            }
        } else {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            values[index].WorkmanFindingsRemarks = inputText;
        }
        setWorkmanshipValue(values);
        WorkmanhsipContList(values);
    }
    return (
        <table className="table table-striped table-bordered table-hover dataTable no-footer">
            <thead>
                <tr>
                    <th className="InsMainInfoDetailsCol">Details</th>
                    <th className="InsMainInfoResultsCol">Sewing Operation<span className="text-danger">*</span></th>
                    <th className="InsMainInfoRemarksCol">Notes</th>
                </tr>
            </thead>
            <tbody>
                {
                    getWorkmanshipValue.map((comment, index) => (
                        <Fragment>
                            <tr key={
                                comment.index
                            }>
                                <td title={comment.workmanshipName} className="cut_text">
                                    <b>{comment.workmanshipName}</b></td>
                                <td>   <Reactselect className="basic-single" name="Result"
                                    id={
                                        "Result" + index
                                    }
                                    isDisabled={props.location.state.params.Action === 'View' ? true : false}
                                    isLoading={false}
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={(event) => handleCommonResultInputChange(index, event, 'WorkmanshipResult')}
                                    options={getCommonResult}
                                    styles={submitted && comment.WorkmanResultID === 0 ? styles : ''}
                                    value={getCommonResult.filter(function (option) {
                                        return option.value === comment.WorkmanResultID;
                                    })}

                                ></Reactselect></td>
                                <td><input type="text" className="form-control" name={index + "_txtRemarks"}
                                    id={index + "_txtRemarks"}
                                    maxLength="500"
                                    disabled={props.location.state.params.Action === 'View' ? true : false}
                                    style={{ border: submitted && (comment.WorkmanResultID === 2 && comment.WorkmanFindingsRemarks === '') ? '1px solid red' : '' }}
                                    value={comment.WorkmanFindingsRemarks} autoComplete="off"
                                    onChange={(event) => handleCommonResultInputChange(index, event, 'FindingRemarks')} /></td>
                            </tr>
                        </Fragment>
                    ))
                }
                {
                    getWorkmanshipValue.length === 0 ?
                        <tr>
                            <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
                        </tr> : ''
                }
            </tbody>
        </table>
    )
}

export default PPWorkmanshipList