import {
    WAREHOUSE_LOADING,
    RETRIEVE_WAREHOUSE,
    DELETE_WAREHOUSE,
} from "./types";
import Warehouse from "../services/Master/WarehouseService";

const Warehouseloading = (isStatus) => ({
    type: WAREHOUSE_LOADING,
    payload: isStatus,
});

export const deleteWarehouse = (compBuySupID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_WAREHOUSE,
            payload: compBuySupID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrieveWarehouse = (Operation, Id) => async (dispatch) => {
    try {
        dispatch(Warehouseloading(true));
        const res = await Warehouse.GetWarehouseList(Operation, Id);
        dispatch({
            type: RETRIEVE_WAREHOUSE,
            payload: res.data,
        });
        dispatch(Warehouseloading(false));
    } catch (err) {
        console.log(err);
        dispatch(Warehouseloading(false));
    }
};