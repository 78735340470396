import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import $ from "jquery";
import axios from "axios";
import Lightbox from "react-image-lightbox"
// import {store} from 'react-notifications-component';
//import {Nodify} from '..';
//import Nodify from "../../Common/ReactNotification";
import Nodify from "./ReactNotification"


const EmployeeUploadImage = ({ UploadCallback, PageName, filePath, Buttonview }) => {
    const [fileName, setfileName] = useState();
    const [image, setImage] = useState({ preview: "assets/img/uploadimage.jpg", raw: "" });
    const [isOpen, setIsOpen] = useState(false);


    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    if (menulist !== undefined) {

        //main logo
        if (PageName === 'BuyerLogo') {
            activeMenu = menulist.filter(i => i.menuName === "BuyerList");
        }

        else if (PageName === 'SupplierLogo') {
            activeMenu = menulist.filter(i => i.menuName === "Supplier");

        }
        //Employee Logo
        else if (PageName === 'Company') {
            activeMenu = menulist.filter(i => i.menuName === "Company");

        }
        else if (PageName === 'Buyer') {
            activeMenu = menulist.filter(i => i.menuName === "BuyerList");

        }
        else if (PageName === 'Supplier') {
            activeMenu = menulist.filter(i => i.menuName === "Supplier");

        }
    }

    // End Enable Menu

    let Height = "200px";
    if (PageName === "StyleHistoryPic") {
        Height = "120px";
    }
    else if (PageName === "Style") {
        Height = "250px";
    }


    var ImgClassName = "upload-btn-wrapper"
    if (PageName === "Profile") {
        ImgClassName = "upload-btn-wrapper_EMP"
    }

    let filePathName = "assets/img/uploadimage.jpg";
    useEffect(() => {
        if (filePath !== "" && filePath !== undefined && filePath !== null) {
            filePathName = window.$APIBaseURL + "Images" + filePath;
            setImage({ preview: filePathName });
            let SplitfilePath = filePath.split('/');
            setfileName(SplitfilePath[2].length > 32 ? SplitfilePath[2].substr(33) : SplitfilePath[2].substr(5));
        }
        else {
            setImage({ preview: "assets/img/uploadimage.jpg", raw: "" });
        }
    }, [filePath]);


    const handleChange = async e => {
        const file = e.target.files[0];
        if (file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png'];
            if (!validImageTypes.includes(fileType)) {
                setfileName("No file chosen");
                Nodify('Warning!', 'warning', 'Invalid file format selected');
            } else {
                if (e.target.files.length) {
                    setImage({
                        preview: URL.createObjectURL(e.target.files[0]),
                        raw: e.target.files[0]
                    });

                }
                const file = e.target.files[0];
                if (file !== null && file !== '' && file !== undefined) {

                    const formData = new FormData();
                    formData.append("FormFile", file);
                    formData.append("FileName", file.name);
                    formData.append("Page", PageName);
                    try {
                        const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                        UploadCallback(res.data + '_' + file.name);
                        //for Preview
                        if (PageName === "BuyerLogo" || PageName === 'SupplierLogo') {
                            filePathName = window.$APIBaseURL + "Images/" + PageName + "/" + file.name;
                        }
                        else {
                            filePathName = window.$APIBaseURL + "Images/" + PageName + file.name;
                        }
                        console.log(res);
                        //end Preview
                    } catch (ex) {
                        console.log(ex);
                    }
                }
            }
        }
    };

    const openFileUpload = () => {
        if (Buttonview !== "View") {
            $('#upload-button').trigger('click');
        }
    }
    const ViewFile = (event) => { // event.preventDefault();
        setIsOpen(true);
    }

    const resetFile = (event) => {
        event.preventDefault();
        $('#upload-button').val("");
        setImage({ preview: "assets/img/uploadimage.jpg", raw: "" })
        UploadCallback("");
        setfileName("No file chosen");
    }
    return (<div className={ImgClassName}
        htmlFor="upload-button"
        title={fileName}>
        {/* title="Accept only (jpg,jpeg,png)" */}
        {/* {image.preview ? ( */}
        <img src={
            image.preview
        }
            onClick={openFileUpload}
            alt="Picture"
            style={
                {
                    // width: "100%",
                    // height: "180px",
                    // padding: "10px",
                    marginBottom: '45px',
                    height: Height,
                    padding: '10px',
                    border: "1px solid #d5d5d5",
                    // width: "100%"
                }
            } />
        {
            Buttonview !== "View" && <input type="file" id="upload-button" accept=".jpg,.jpeg,.png"
                // style={{ display: "none" }}
                onChange={handleChange} value='' />
        }
        {
            image.preview !== "assets/img/uploadimage.jpg" && (
                <div style={
                    {
                        // marginTop: "8px",
                        // float: "right"
                        marginTop: '8px',
                        float: 'right',
                        bottom: '0px',
                        position: 'absolute',
                        width: '100%',
                        textAlign: 'center'
                    }
                }>
                    <i className="btn btn-info fa fa-eye" title="View Logo"
                        style={{ display: activeMenu && activeMenu[0].isView === 1 ? '' : 'none' }}
                        onClick={
                            event => ViewFile(event)
                        }></i>
                    &nbsp; {
                        Buttonview !== "View" && <i className="btn btn-danger fa fa-trash-o" title="Remove File"
                            style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}
                            onClick={
                                event => resetFile(event)
                            }></i>
                    } </div>


            )
        }
        {

            <div> {
                isOpen && (
                    <Lightbox mainSrc={image.preview}
                        alt="bg image"
                        onCloseRequest={
                            () => setIsOpen(false)
                        } />
                )
            } </div>
        } </div>
    );
};
export default EmployeeUploadImage;
