import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
const Search = ({ onSearch, placeholder, props }) => {
    const [search, setSearch] = useState("");
    const location = useLocation();
    // If check production or sampling
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProduction = activeMenus.IsProduction;
    // End
    const WidthSize =
        location.pathname.match(/^\/FabricList/) ? "360px" :
            location.pathname.match(/^\/PantoneList/) ? "260px" :
                location.pathname.match(/^\/MapBuyer/) ? "400px" :
                    location.pathname.match(/^\/StyleList/) ? "100%" :
                        location.pathname.match(/^\/StrikeOffList/) ? "570px" :
                            // location.pathname.match(/^\/CurrencyList/) ? "222px" :
                            location.pathname.match(/^\/TrimList/) ? "650px" :
                                location.pathname.match(/^\/PurchaseOrderList/) ? "450px" :
                                    location.pathname.match(/^\/ListTask/) ? "400px" :
                                        location.pathname.match(/^\/SamGenlLabdipList/) ? "400px" : "240px";
    useEffect(() => {
        const storageData = JSON.parse(localStorage.getItem("Search"));
        if (storageData) {
            if (storageData.Page === props.location.pathname) {
                setSearch(storageData.Searchvalue);
                onSearch(storageData.Searchvalue);
            } else {
                localStorage.removeItem('Search');
            }
        } else {
            setSearch("");
            onSearch("");
        }
    }, [IsProduction]);

    const onInputChange = value => {
        let input = '';
        if (value.trim() !== '') {
            input = value;
        }
        setSearch(input);
        onSearch(input);
        var Data = { Searchvalue: input, Page: props.location.pathname }
        localStorage.setItem('Search', JSON.stringify(Data));
    };

    return (

        <input
            type="text"
            className="form-control"
            style={{ width: WidthSize }}
            title={placeholder}
            placeholder={placeholder}
            value={search}
            onChange={e => onInputChange(e.target.value)}
        />

    );
};




export default Search;
