import {
    PROCESSTYPE_LOADING,
    CREATE_PROCESSTYPE_SUCCESS,
    UPDATE_PROCESSTYPE_SUCCESS,
    RETRIEVE_PROCESSTYPE,
    DELETE_PROCESSTYPE,
} from "../actions/types";
import moment from "moment";
const initialState = {
    isLoadingProcessType: true,
    ProcessTypeList: [],
    lastProcessTypeID: 0,
};

const ProcessTypeReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case PROCESSTYPE_LOADING:
            return {
                ...state,
                isLoadingProcessType: payload
            };
        case RETRIEVE_PROCESSTYPE:

            // let getProcessTypeList = payload;
            // getProcessTypeList = getProcessTypeList && getProcessTypeList.sort((a, b) => (a["processTypeName"].toString().toLowerCase() > b["processTypeName"].toString().toLowerCase() ? 1 : -1));
            // let ProcessTypeID = getProcessTypeList.length > 0 ? getProcessTypeList[getProcessTypeList.length - 1].value : 0;
            return { ...state, ProcessTypeList: payload };
        case DELETE_PROCESSTYPE:

            const results = state.ProcessTypeList.filter(c => c.processTypeID !== payload.processTypeID);

            return {
                ...state,
                ProcessTypeList: results
            };
        case CREATE_PROCESSTYPE_SUCCESS:
            const newRows = payload;
            let ProcessTypeResults = [...state.ProcessTypeList, ...newRows];
            //Order by descending ( -1 : 1)
            ProcessTypeResults = ProcessTypeResults.sort((a, b) => (a["ProcessTypeID"] > b["ProcessTypeID"] ? -1 : 1));
            return {
                ...state,
                ProcessTypeList: ProcessTypeResults
            };
        case UPDATE_PROCESSTYPE_SUCCESS:
            const newValue = payload.getProcessTypeInfo;
            const currentDate = payload.CurrentDate;
            let ProcessTypeResultList = [...state.ProcessTypeList];
            let index = ProcessTypeResultList.findIndex(x => x.ProcessTypeID === newValue.ProcessTypeID);
            ProcessTypeResultList[index].ProcessTypeName = newValue.ProcessTypeName;
            ProcessTypeResultList[index].modifiedDate = currentDate;
            ProcessTypeResultList = ProcessTypeResultList.sort((a, b) => (moment(new Date(a["modifiedDate"])) > moment(new Date(b["modifiedDate"])) ? -1 : 1));
            return {
                ...state,
                ProcessTypeList: ProcessTypeResultList
            };
        default:
            return state;
    }
};
export default ProcessTypeReducer;