import {
    WORKMANSHIPGROUP_LOADING,
    RETRIEVE_WORKMANSHIPGROUP,
    DELETE_WORKMANSHIPGROUP,
} from "../actions/types";

const initialState = {
    isLoadingWorkmanship: true,
    WorkmanshipGroup: [],
    WorkmanshipGroupEdit: []
}
const WorkmanGroupReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case WORKMANSHIPGROUP_LOADING:
            return {
                ...state,
                isLoadingWorkmanship: payload
            };
        case RETRIEVE_WORKMANSHIPGROUP:
            return {
                ...state,
                WorkmanshipGroup: payload.workmanshipgroup1,
                WorkmanshipGroupEdit: payload.workmanshipgroupEdit2
            }
        case DELETE_WORKMANSHIPGROUP:
            let results = state.WorkmanshipGroup.filter(c => c.categoryID !== payload.workmanshipItemID);
            return {
                ...state,
                WorkmanshipItem: results
            }
        default:
            return state

    }

}
export default WorkmanGroupReducer