import React, { useState, useEffect, useRef, Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import Lightbox from "react-image-lightbox";
import { GetOrderLapdipApprovalViewList, clearLapdipApprovalViewSubRow } from "../../../../actions/orderLabdip";
import { loadSupplierList } from "../../../../actions/tna";
// import CommonService from "../../../../../services/Common/CommonService";
import Nodify from "../../../Common/ReactNotification"
//import SweetAlertPopup from '../../../../Common/SweetAlertPopup';
//import { GenLabdipApprovalId } from "../../../../Common/PageActionNumber";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import moment from "moment";
import Reactselect from 'react-select';
import { Modal, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import GeneralService from "../../../../services/General/GeneralService"
import input from "react-validation/build/input";
import $, { get } from "jquery";
import axios from "axios";
//import { TableHeader, Pagination, Search, PageCount } from "../../../../Datatable";
//import SweetAlert from 'react-bootstrap-sweetalert';

const LabTestsApprovalView = (props) => {

    //declaration
    const dispatch = useDispatch();
    const [loader, showLoader, hideLoader] = useFullPageLoader();

    //reducer
    const labdipApprovalList = useSelector((state) => state.orderLabdip.orderLabdipApprovalViewList);
    //const labdipSubmissionParams = useSelector((state) => state.generalLabdip.generalLabdipViewDetailParams);
    let isLoadingOrderLabdip = useSelector((state) => state.orderLabdip.isLoadingOrderLabdip);
    const getSupplierList = useSelector((state) => state.tna.drpSupplierList);

    // let activeMenu;
    // let activeMenuSub;
    // let LapDipApprovalTab;
    // const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    // const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
    // if (menulist !== undefined && menulistSub.length > 0) {
    //     activeMenu = menulist.filter(i => i.menuName === 'LabDip');
    //     if (activeMenu.length > 0) {
    //         activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);

    //         if (activeMenuSub.length > 0) {

    //             for (let index = 0; index < activeMenuSub.length; index++) {
    //                 if (activeMenuSub[index].menuName === "GeneralLabdipApproval") {
    //                     LapDipApprovalTab = activeMenuSub[index];
    //                 }
    //             }
    //         }
    //     }
    // }
    //state

    const [isOpen, setIsOpen] = useState(false);
    const [currentExpand, setcurrentExpand] = useState({ currindex: "", currpantoneIdOrSwatchId: "" });
    const [getStyleNameSearch, setgetStyleNameSearch] = useState("");
    const [getStyleNoSearch, setgetStyleNoSearch] = useState("");
    const [getIdPoSearch, setgetIdPoSearch] = useState("");
    const [inputFields, setInputFields] = useState([{
        BuyerBrandSeasonName: '',
        styleName: '',
        StyleNo: '',
        Supplier: '',
        SubmitDate: '',
        SendTo: "0",
        SendThrough: 0,
        EndCustomer: "",
        AWBName: "", AWBDate: "", AWBDateandNo: "",
        PantoneID: 0, PantoneCode: "", RefColorName: "",
        PantoneNameOrSwatchName: "", PantoneColorOrOrSwatchImage: "", PantoneTypeName: "",
        FabricID: 0, SwatchCardImageName: "", SwatchCardIamgePath: "", Remarks: "", CreatedBy: 0,
        GenralLabdipTNASubmissionAndApprovalId: 0,
        ApprovalDate: '', ApprovalRemark: '',
        GeneralTNALabdibSubmittedOption: []
    }]);
    const values = [...inputFields]

    const [getSearchReqValues, setSearchReqValues] = useState({
        Status: 2,
        SupplierId: 0,
        SearchTxt: "",
        StyleName: "",
        StyleNo: "",
        IdPoNo: "",
        stateDate: [
            {
                startDate: null,
                endDate: new Date(""),
                key: 'selection',
                isShow: false
            }
        ]

    });


    const SearchReqValues = { ...getSearchReqValues };

    const headers = [
        { name: "Story", field: "Story", sortable: false },
        { name: "Theme", field: "Theme", sortable: false },
        { name: "Details", field: "Details", sortable: false },
        { name: "Task Holder / Follower", field: "", sortable: false },
        // { name: "Action", field: "", sortable: false },

    ];

    let BuyerId = 0;
    let BrandId = 0;
    let SeasonId = 0;
    //get props params
    if (props.props.location.state !== undefined) {

        BuyerId = props.props.location.state.params.buyerId === undefined ? 0 : props.props.location.state.params.buyerId;
        BrandId = props.props.location.state.params.brandId === undefined ? 0 : props.props.location.state.params.brandId;
        SeasonId = props.props.location.state.params.seasonId === undefined ? 0 : props.props.location.state.params.seasonId;

    }

    useEffect(() => {
        if (!isLoadingOrderLabdip) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingOrderLabdip])

    useEffect(() => {

        dispatch(loadSupplierList());
        searchLabDip();

    }, [])


    //#region  ------------------  search details --------------------------------
    const searchLabDip = () => {
        SearchReqValues.stateDate[0].isShow = false;
        setSearchReqValues(SearchReqValues);

        let StartDate = null;
        let EndDate = null;
        if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
        }
        if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
        }

        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, PantoneId: 0, Index: currentExpand.currindex,
            SearchText: SearchReqValues.SearchTxt, Operation: 4, Status: SearchReqValues.Status ? SearchReqValues.Status : null,
            SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
            StyleName: SearchReqValues.StyleName, StyleNo: SearchReqValues.StyleNo, IdPoNo: SearchReqValues.IdPoNo
        };
        dispatch(GetOrderLapdipApprovalViewList(params));
        commonParentExpand();
    }

    const resetLabDip = () => {
        SearchReqValues.stateDate[0].startDate = null;
        SearchReqValues.stateDate[0].endDate = new Date("");
        SearchReqValues.stateDate[0].key = 'selection';
        SearchReqValues.stateDate[0].isShow = false;

        SearchReqValues.Status = 2;
        SearchReqValues.SupplierId = 0;
        SearchReqValues.SearchTxt = "";
        SearchReqValues.StyleName = "";
        SearchReqValues.StyleNo = "";
        SearchReqValues.IdPoNo = "";
        setSearchReqValues(SearchReqValues);
        searchLabDip();

    }


    const clearDateSelect = () => {
        SearchReqValues.stateDate[0].startDate = null;
        SearchReqValues.stateDate[0].endDate = new Date("");
        SearchReqValues.stateDate[0].key = 'selection';
        SearchReqValues.stateDate[0].isShow = false;

        setSearchReqValues(SearchReqValues);
    }
    const handleDateSelect = (event, isShow) => {

        SearchReqValues.stateDate[0].isShow = isShow;
        setSearchReqValues(SearchReqValues);
    }

    const handlePopupDateselection = (item) => {

        SearchReqValues.stateDate = [item.selection];
        setSearchReqValues(SearchReqValues);
    }

    const handleSearchChange = (e, FieldName) => {

        let inputText = '';
        if (FieldName === 'SearchTxt' || FieldName === 'StyleName' || FieldName === 'StyleNo' || FieldName === 'IdPoNo') {
            if (e && e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
        } else if (FieldName === 'Status' || FieldName === 'SupplierId') {
            if (e) {
                inputText = e.value
            }
        }

        if (FieldName === 'Status') {
            SearchReqValues.Status = 2;
        }
        else if (FieldName === 'SupplierId') {
            SearchReqValues.SupplierId = inputText;
        } else if (FieldName === 'SearchTxt') {
            SearchReqValues.SearchTxt = inputText;
        } else if (FieldName === 'StyleName') {
            SearchReqValues.StyleName = inputText;
        }
        else if (FieldName === 'StyleNo') {
            SearchReqValues.StyleNo = inputText;
        }
        else if (FieldName === 'IdPoNo') {
            SearchReqValues.IdPoNo = inputText;
        }
        setSearchReqValues(SearchReqValues);

    }

    function convertUTCDateToLocalDate(date) {
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date;
    }

    //#end  ------------------  search details end --------------------------------





    // #region  ------------------  Expanded Toggle --------------------------------

    const handleToggle = (index, pantoneIdOrSwatchId) => {
        let btnToggle = document.getElementById("btn-toggle3_" + index);
        let iconToggle = document.getElementById("icon-toggle3_" + index);

        let ariaExpanded = btnToggle.getAttribute('aria-expanded');
        if (ariaExpanded === 'false') {
            btnToggle.setAttribute('aria-expanded', 'true');
            iconToggle.className = iconToggle.className.replace("fa-plus-square-o", "fa-minus-square-o");
            setcurrentExpand({ currindex: index, currpantoneIdOrSwatchId: pantoneIdOrSwatchId });
            let StartDate = null;
            let EndDate = null;
            if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
                StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
            }
            if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
                EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
            }

            let params = {
                BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, PantoneId: pantoneIdOrSwatchId, Index: index,
                SearchText: SearchReqValues.SearchTxt, Operation: 4, Status: SearchReqValues.Status ? SearchReqValues.Status : null,
                SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
                StyleName: SearchReqValues.StyleName, StyleNo: SearchReqValues.StyleNo, IdPoNo: SearchReqValues.IdPoNo
            };

            dispatch(GetOrderLapdipApprovalViewList(params));

            // GeneralService.GetGeneralLapdipSubmissionApprovalList(params).then((response) => {
            //     if (response.data) {
            //         setApprovalList(response.data.pantoneCodeList);
            //         let SubList = [...ApprovalList];
            //         SubList[index].pantoneSubList = response.data.generalLabdipPantoneSubInfo ? response.data.generalLabdipPantoneSubInfo : [];
            //         SubList[index].criticalList = response.data.criticalList ? response.data.criticalList : [];
            //         setApprovalList(SubList);
            //     }
            // }).catch(() => { });
        }
        else {

            btnToggle.setAttribute('aria-expanded', 'false');
            iconToggle.className = iconToggle.className.replace("fa-minus-square-o", "fa-plus-square-o");
            if (pantoneIdOrSwatchId) {
                dispatch(clearLapdipApprovalViewSubRow(index));
            }
        }
    };


    const commonParentExpand = () => {
        let rowHiddens = document.querySelectorAll(".parentExpand");
        for (let row of rowHiddens) {
            row.setAttribute('aria-expanded', 'false');
        }
        let rowIconHiddens = document.querySelectorAll(".clscollapseexpandTNA");
        for (let rowIcon of rowIconHiddens) {
            rowIcon.className = rowIcon.className.replace("fa-minus-square-o", "fa-plus-square-o");
        }
    }

    const handleserach = () => {

    }
    return (
        <Fragment>
            <div> {
                isOpen && (
                    <Lightbox
                        mainSrc={isOpen}
                        alt="bg image"

                        onCloseRequest={
                            () => setIsOpen(false)
                        } />
                )
            } </div>
            <div className="widget-body">
                <div id="registration-form">
                    <form>
                        <div className="col-sm-12">

                            <div className="row">
                                <div className="col-sm-3">
                                    <div className='form-group'>
                                        <label>Pantone Code / Swatch Name </label>
                                        <span className='input-icon icon-right'>
                                            <input type="text" value={SearchReqValues.SearchTxt} onChange={event => handleSearchChange(event, "SearchTxt")} placeholder="Pantone Code / Swatch Name" className="form-control" />

                                        </span>

                                    </div>

                                </div>

                                <div className="col-md-3">
                                    <label htmlFor="Status">
                                        Style Name
                                    </label>
                                    <span className="input-icon icon-right">
                                        <input
                                            type="text"
                                            className="form-control StyleList"
                                            title={'Style Name'}
                                            placeholder={'Style Name'}
                                            value={SearchReqValues.StyleName}
                                            onChange={e => handleSearchChange(e, 'StyleName')}
                                        />
                                    </span>
                                </div>

                                <div className="col-md-3">
                                    <label htmlFor="Status">
                                        Style No
                                    </label>
                                    <span className="input-icon icon-right">
                                        <input
                                            type="text"
                                            className="form-control StyleList"
                                            title={'Style No'}
                                            placeholder={'Style No'}
                                            value={SearchReqValues.StyleNo}
                                            onChange={e => handleSearchChange(e, 'StyleNo')}
                                        />
                                    </span>
                                </div>





                                <div className="col-md-3">
                                    <label htmlFor="Status">
                                           PO/ID No
                                    </label>
                                    <span className="input-icon icon-right">
                                        <input
                                            type="text"
                                            className="form-control StyleList"
                                            title={'Id Po No'}
                                            placeholder={'PO/ID No'}
                                            value={SearchReqValues.Idpono}
                                            onChange={e => handleSearchChange(e, 'IdPoNo')}
                                        />
                                    </span>

                                </div>
                            </div>

                            <div className="row">

                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label htmlFor="SupplierID">
                                            Supplier
                                            {/* <span className="text-danger">*</span> */}
                                        </label>
                                        <span className="input-icon icon-right">
                                            <Reactselect className="basic-single" name="SupplierId"
                                                id={
                                                    "SupplierId"
                                                }
                                                isLoading={false}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={event => handleSearchChange(event, 'SupplierId')}
                                                value={getSupplierList.filter(function (option) {
                                                    return option.value === SearchReqValues.SupplierId;

                                                })}
                                                options={getSupplierList}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                                            ></Reactselect>

                                        </span>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label >Date Range <span className="rptDateRangeSpan">(Clicking on the text box cause the datepicker to appear and disappear)</span> </label>
                                        <span className="input-icon icon-right">
                                            <input
                                                value={(SearchReqValues.stateDate[0].startDate === null ? '' : moment(SearchReqValues.stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') + ((SearchReqValues.stateDate[0].endDate === null || !moment(SearchReqValues.stateDate[0].endDate).isValid()) ? '' : moment(SearchReqValues.stateDate[0].endDate).format('DD/MM/YYYY'))}
                                                onClick={event => handleDateSelect(event, !SearchReqValues.stateDate[0].isShow)}
                                                //onBlur={event => handleDateBlur()}
                                                type="text"
                                                className={'form-control'}
                                                placeholder="Select Date"
                                            />
                                            <i className="fa fa-times" onClick={clearDateSelect} ></i>
                                        </span>
                                    </div>


                                    {SearchReqValues.stateDate[0].isShow &&
                                        <DateRangePicker
                                            //onChange={item => setStateDate([item.selection])}
                                            onChange={item => handlePopupDateselection(item)}
                                            showSelectionPreview={true}
                                            moveRangeOnFirstSelection={false}
                                            months={2}
                                            ranges={SearchReqValues.stateDate}
                                            showDateDisplay={false}
                                            direction="vertical"
                                            className={'TNAReportDatePicker'}

                                        />

                                    }
                                </div>
                                <div className="col-sm-1" style={{ marginTop: '30px' }}>
                                    <div className="form-group">
                                        <button type="button" className="btn btn-success" title="Search" onClick={event => searchLabDip()}
                                        >
                                            <i className="fa fa-search"></i>
                                        </button>
                                        &nbsp;&nbsp;
                                        <button type="button" className="btn btn-danger" title="Reset" onClick={resetLabDip}
                                        >
                                            <i className="fa fa-close"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>


                        </div>


                        <div className="col-md-12" style={{ overflowX: "auto", minHeight: '100px' }}>
                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                <thead>
                                    <tr>
                                        <th className="fixed-column-header-first-tna" style={{ width: "35px" }}> #</th>
                                        <th>Approved Date</th>
                                        <th>Style Name</th>
                                        <th>Style No</th>
                                        <th>PO/ID No</th>
                                        <th>Supplier</th>
                                        <th>Item Details (Fabric / Yarn)</th>
                                        <th>Task Holder / Follower</th>
                                        <th>Approval Target</th>
                                        <th>Approval Info</th>
                                        <th>Submission AWB #</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        labdipApprovalList && labdipApprovalList.length > 0 ?
                                            <>
                                                {
                                                    labdipApprovalList && labdipApprovalList.map((inputField, index) => (
                                                        <Fragment key={`${inputField}~${index}`}>
                                                            <tr class="parent" data-child={"parent_" + index}>
                                                                <td className="fixed-column-first-tna">

                                                                    {
                                                                        <span id={"btn-toggle3_" + index} class="btn-toggle3 parentExpand" aria-expanded="false" onClick={() => handleToggle(index, inputField.pantoneIdOrSwatchId)} aria-labelledby="btn-toggle3 id-master">
                                                                            <i id={"icon-toggle3_" + index} class="fa fa-plus-square-o clsPointer clscollapseexpandTNA"></i>
                                                                        </span>
                                                                    }

                                                                </td>
                                                                <td>
                                                                    <span className='input-icon icon-right'>
                                                                        {
                                                                            inputField.pantoneCode
                                                                        }
                                                                    </span>
                                                                </td>
                                                                <td colSpan="10">
                                                                    {inputField.imagePath ?
                                                                        <div className="trwidth_manual" style={{ textAlign: "center" }}>
                                                                            <img className="width_manual"
                                                                                src={inputField.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + inputField.imagePath}
                                                                                alt="Logo"
                                                                                onClick={
                                                                                    () => setIsOpen(inputField.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + inputField.imagePath)
                                                                                }
                                                                            />
                                                                        </div> :
                                                                        <div style={{ background: inputField.pantoneColor, width: '120px', height: '25px', marginLeft: '20px', borderRadius: '2px', display: 'inline-block' }}> </div>
                                                                    }
                                                                </td>

                                                            </tr>
                                                            {
                                                                inputField.pantoneSubList && inputField.pantoneSubList.map((subField, subIndex) => (

                                                                    <Fragment key={`${subIndex}~${subIndex}`}>
                                                                        <tr class={"child_" + index + " parentChild_" + index + "_" + subIndex} >
                                                                            <td className="fixed-column-first-tna">
                                                                                {subIndex + 1}
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.approvalDate} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.styleName} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.styleNo} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.idpono} </span>
                                                                                }
                                                                            </td>

                                                                            <td>
                                                                                {
                                                                                    <span> {subField.supplierName} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.itemDetails} </span>
                                                                                }
                                                                            </td>
                                                                            <td className="cut_text" title={subField.taskHolderFollowerNames}>
                                                                                {
                                                                                    <span> {subField.taskHolderFollowerNames} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.approvalTarget} </span>
                                                                                }
                                                                            </td>
                                                                            {/* <td>
                                                                                {
                                                                                    <span> {subField.approvalStatus} </span>
                                                                                }
                                                                            </td> */}
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.approvalInfo} </span>
                                                                                }
                                                                            </td>

                                                                            <td>
                                                                                {
                                                                                    <span> {subField.awbName} - {subField.awbDate} </span>
                                                                                }
                                                                            </td>




                                                                        </tr>
                                                                        {
                                                                            inputField.criticalList && inputField.criticalList.map((Crit) => (
                                                                                subField.programIndexName === Crit.programIndexName ?
                                                                                    <tr class={"child_" + index + " parentChild_" + index + "_" + subIndex} >
                                                                                        <td colSpan="10">
                                                                                            <span style={{ color: 'red' }}>Critical : {Crit.criticalComment}</span>,&nbsp;
                                                                                            <span style={{ color: '#427fed' }}>Target to Close : {new Date(Crit.targettoClose).toLocaleDateString()}</span>,&nbsp;
                                                                                            <span style={{ color: '#00a65a' }}>Completed On : {Crit.completedOn !== '' ? new Date(Crit.completedOn).toLocaleDateString() : '-'}</span>
                                                                                        </td>

                                                                                    </tr>
                                                                                    : ''
                                                                            ))
                                                                        }
                                                                    </Fragment>
                                                                ))
                                                            }

                                                        </Fragment>
                                                    ))
                                                }
                                            </>
                                            :

                                            <tr><td colSpan="11" className='norecordfound'><span>No Records Found</span></td></tr>

                                    }
                                </tbody>
                            </table>
                        </div>
                    </form>

                </div>

            </div >

            {loader}
        </Fragment >

    )
}

export default LabTestsApprovalView;