import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import { logout, sidebarMenuVisible } from "../../actions/auth";
import Lightbox from "react-image-lightbox";

const Header = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  if (!currentUser) {
    dispatch(logout());

    // return <Redirect to="/login"/>;
  }

  const logOut = () => {
    dispatch(logout());
  };

  const SideBarhandleClick = (e) => {
    var b = $("#sidebar").hasClass("menu-compact");
    if (!$("#sidebar").is(":visible")) $("#sidebar").toggleClass("hide");

    $("#sidebar").toggleClass("menu-compact");
    $(".sidebar-collapse").toggleClass("active");
    b = $("#sidebar").hasClass("menu-compact");

    if ($(".sidebar-menu").closest("div").hasClass("slimScrollDiv")) {
      $(".sidebar-menu").slimScroll({ destroy: true });
      $(".sidebar-menu").attr("style", "");
    }
    if (b) {
      $(".open > .submenu").removeClass("open");
    } else {
      if ($(".page-sidebar").hasClass("sidebar-fixed")) {
        $(".sidebar-menu").slimscroll({ height: "auto", size: "3px" });
      }
    }

    dispatch(sidebarMenuVisible(!b));
  };

  return (





    <div className="navbar">
      <div className="navbar-inner">
        <div className="navbar-container">
          <div className="navbar-header pull-left">
            {/* <span target="-blank" className="navbar-brand">
              <small>
                <img
                  src="assets/img/logo-icube.png"
                  alt=""
                  style={{ cursor: "default" }}
                />
              </small>
            </span> */}

            <div
              className="sidebar-collapse"
              id="sidebar-collapse"
              onClick={SideBarhandleClick}
            >
              <i className="collapse-icon fa fa-bars"></i>
            </div>
            <div className="navbar-account"></div>
          </div>
        </div>
        <div className="navbar-header pull-right postion_relative_1">
          <div className="navbar-account">
            <ul className="account-area">
              <li>
                <a
                  className="login-area dropdown-toggle"
                  data-toggle="dropdown"
                >
                  <div className="avatar profile_photo" title="View your public profile">
                    <img
                      src={
                        currentUser.empProfilePicPath === ""
                          ? "assets/img/emptyImage.jpg"
                          : window.$APIBaseURL +
                          "Images" +
                          currentUser.empProfilePicPath
                      }
                      alt="Logo"
                      onClick={() =>
                        setIsOpen(
                          currentUser.empProfilePicPath === ""
                            ? "assets/img/emptyImage.jpg"
                            : window.$APIBaseURL +
                            "Images" +
                            currentUser.empProfilePicPath
                        )
                      }
                    />
                  </div>
                  <section>
                    <h2>
                      <span className="profile profile_photo">
                        <span>
                          {currentUser.empFirstName +
                            " " +
                            currentUser.empLastName}
                        </span>
                      </span>
                    </h2>
                  </section>
                </a>
              </li>
            </ul>
            <div className="setting">
              <a
                id="btn-setting"
                title="Log Out"
                href="/Login"
                onClick={logOut}
              >
                <i className="icon glyphicon glyphicon glyphicon-log-out"></i>
              </a>
            </div>
          </div>
        </div>

        <div>
          {isOpen && (
            <Lightbox
              mainSrc={isOpen}
              alt="bg image"
              onCloseRequest={() => setIsOpen(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
