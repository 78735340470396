import React, { useState, useEffect, useMemo } from "react";
import ReactNotification from 'react-notifications-component';
import { TableHeader, Pagination, Search, PageCount } from "../../Datatable";
import { useDispatch, useSelector } from "react-redux";
import { retrieveCsrtemplatelist } from "../../../actions/csrTemplate"
import CommonService from '../../../services/Common/CommonService';
import Nodify from "../../Common/ReactNotification";
import SearchRetain from "../../Common/SearchRetain";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import SweetAlertPopup from '../../Common/SweetAlertPopup';
import { Redirect } from 'react-router-dom';
import CsrTemplateService from "../../../services/Master/CsrTemplateService";
import SelectCombobox from 'react-select';
import CsrService from "../../../services/Master/CsrService";

const CSRTemplateEdit = (props) => {
  const [ItemList, setItemList] = useState([]);// for Item list
  const [TemplateList, setTemplateList] = useState([]);// for Template list
  const [getnewitemId, setnewitemId] = useState([]);
  const [buttonLoader, setButtonLoader] = useState(false);

  const [matchingList, setmatchingList] = useState([{ Value: 0, label: '', ValueOne: 0, labelOne: '' }]);// For TemplateID based matched value ItemID and ItemNAme
  const [getHeaderBuyerDetails, setHeaderBuyerDetails] = useState([]);// For Header buyer Details
  const [getHeaderBrandDetails, setHeaderBrandDetails] = useState([]);// For Header brand Details


  const [submitted, setSubmitted] = useState(false);
  const [getnewlabel, setnewlabel] = useState([]);
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const { user: currentUser } = useSelector((state) => state.auth);

  const options = [

    { value: 1, label: 'Drop Down' },
    { value: 2, label: 'Text Box' },
  ]

  useEffect(() => {
    let CSRDetails = [];
    let CSRBuyerDetail = [];
    let CSRBrandDetail = [];
    let CSRTemplateMatchList = [];

    CsrService.getAllCSRDetails(0).then((res) => {
      if (res.data) {
        res.data.map(x => {
          CSRDetails.push({
            label: x.itemName,
            value: x.itemID
          })
        })
        setItemList(CSRDetails)
      }
    })

    CsrTemplateService.getAllCSRTemplateDetails(2, props.location.state.params.csrTemplateInfoID).then(async (res) => {
     ;
      if (res.data) {
        res.data.csrTemplateEdit2.map(x => {
          CSRTemplateMatchList.push({
            label: x.itemName,
            Value: x.itemid,
            Isdeleted: 0,
            ValueOne: x.inputid,
            labelOne: x.inputName,
            CommonID: x.commonID,
            CsrTemplateInfoID: x.csrTemplateInfoID
          })
        })
        setmatchingList(CSRTemplateMatchList)
      }

      if (res.data) {
        res.data.csrTemplateEdit2.map(x => {
          CSRBuyerDetail.push({
            value: x.buyerID,
            label: x.buyerName,
          })
        })
        setHeaderBuyerDetails(CSRBuyerDetail)
      }
      if (res.data) {
        res.data.csrTemplateEdit2.map(x => {
          CSRBrandDetail.push({
            value: x.brandID,
            label: x.brandName,

          })
        })
        setHeaderBrandDetails(CSRBrandDetail)
      }

    }, [])

    // CsrTemplateService.LoadCSRTemplateItemList(props.location.state.params.brandID).then((res) => {

    //  if (res.data) {
    //   res.data.map(x => {
    //    CSRTemplateDetail.push({
    //     label: x.label,
    //     value: x.value,
    //     Isdeleted: 0
    //    })
    //   })
    //   setTemplateList(CSRTemplateDetail)
    //  }
    // })

  }, [])



  const handleRemoveFields = (index, value) => {
   
    const values = [...matchingList]
    let Params = {
      Operation: 137,
      Id: value.Value,
    }
    CommonService.CheckMappedItem(Params).then(res => {
      if (res.data.outputResult === "-2") {
        let Title = "Warning!";
        let message = "This item is already mapped.";
        let type = "warning";
        Nodify(Title, type, message);
      }
      else {
        values.splice(index, 1);
      }

      setmatchingList(values)
    });









  };


  const handleAddFields = (index) => {
   
    const values = [...matchingList];
    if (values[index].Value !== 0 && values[index].Value !== undefined) {
      // Create a new row
      const newRow = { id: Date.now() };

      // Update the rows array
      setmatchingList((prevRows) => {
        // Insert the new row at the specified index
        const updatedRows = [...prevRows.slice(0, index + 1), newRow, ...prevRows.slice(index + 1)];

        // Increment the indices of rows following the insertion point
        const updatedRowsWithIds = updatedRows.map((row, i) => ({ ...row, id: i }));

        return updatedRowsWithIds;
      });
      setSubmitted(false);
    }
    else {
      Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
      setSubmitted(true);
    }
  };


  const ResetOperation = (e) => {
    if (e === "Back") {
      props.history.push('/CSRTemplateList');
    } else {
      window.location.reload();
    }
  }
  const headers = [
    { name: <span>Name<span className="text-danger">*</span></span>, field: "itemName", sortable: false },
    { name: < span >Input Type<span className="text-danger">*</span></span>, field: "itemName", sortable: false },
    {
      name: "Action", field: "", sortable: false,

    },
  ];

  let styles = {
    control: styles => ({ ...styles, border: '1px solid red' }),

  }



  const handleInputChange = (e, index, FieldName) => {

    const values = [...matchingList]
    if (FieldName === "ItemName") {
      if (e !== null) {
        values[index].Value = e.value
        values[index].label = e.label
      }
      else {
        values[index].Value = 0
        values[index].label = ''
      }
    }
    if (FieldName === "InputType") {
      if (e !== null) {
        values[index].ValueOne = e.value;
        values[index].labelOne = e.label;
      } else {
        values[index].ValueOne = 0;
        values[index].labelOne = ''
      }
    }
    setmatchingList(values)
   ;
    if (values.length > 1)
      for (var j = 0; j < (matchingList.length); j++) {
        if (j !== index) {
          if (values[j].Value === values[index].Value && values[j].ValueOne === values[index].ValueOne) {
            values[index].ValueOne = 0;
            values[index].Value = 0;
            setmatchingList(values);
            Nodify('Warning!', 'warning', 'This Input Type is already exist in Template.');
            setSubmitted(true);
            return false;
          }
        }
      }

  }
  const PageRedirect = (Func) => {
    props.history.push({
      pathname: "/CSRTemplateList",
      state: {
        message: Func,
      },
    }
    );
  };

  const SaveTemplateDetials = (e) => {
   ;
    setButtonLoader(true);
    e.preventDefault();
    let isvalid = true;
    for (var i = 0; i < (matchingList.length); i++) {
      if ((matchingList[i].Value === 0 || matchingList[i].Value === undefined) || (matchingList[i].ValueOne === 0 || matchingList[i].ValueOne === undefined)) {
        Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
        setSubmitted(true);
        isvalid = false
        setButtonLoader(false);
        return false;
      }
    }
    if (isvalid) {
      let TemplateEditID = []
      matchingList.map((x) => {
        TemplateEditID.push({
          CommonID: x.CommonID,
          Itemid: x.Value,
          Inputid: x.ValueOne,
          Isdeleted: x.Isdeleted,
          InputName: x.labelOne,
        })
      })
      setnewitemId(TemplateEditID)

      let InputMaster = { Operation: 2, CsrTemplateInfoID: props.location.state.params.csrTemplateInfoID, BuyerID: props.location.state.params.buyerID, BrandID: props.location.state.params.brandID, templateInformation: TemplateEditID, Createdby: currentUser.employeeinformationID }
      CsrTemplateService.UpdateDeleteCSRTemplateDetails(InputMaster).then(res => {

        var page = "Remove";
        SearchRetain(page);
        let Func = 'Add';
        if (res.data.outputResult === "1") {
          Func = 'Add';
          PageRedirect(Func);
        } else if (res.data.outputResult === "2") {
          Func = 'Edit';
          PageRedirect(Func);
        }
        setButtonLoader(false);
      });
    }
  }


  return (
    <div className="page-body">
      <ReactNotification />
      <div className="row">
        <div className="col-lg-12 col-sm-12 col-xs-12">
          <div className="widget flat radius-bordered">
            <div className="widget-header heading_box_style">
              <h3 className="heading_style_common">Edit CSR Template</h3>
            </div>

            <div className="widget-body">
              <div className="row">
                <div className="col-lg-3">
                  <label>Buyer Name<span className="text-danger">*</span></label>
                  <SelectCombobox className="basic-single" name="Buyer" id={"Buyer"}
                    isDisabled={true}
                    isLoading={false}
                    isSearchable={true}
                    isClearable={true}
                    autocomplete='off'
                    placeholder="Select Buyer Detail"
                    value={getHeaderBuyerDetails}

                  />
                </div>
                <div className="col-lg-3">
                  <label>Brand Name<span className="text-danger">*</span></label>
                  <SelectCombobox className="basic-single" name="Brand" id={"Brand"}
                    isDisabled={true}
                    isLoading={false}
                    isSearchable={true}
                    isClearable={true}
                    autocomplete='off'
                    placeholder="Select Brand Detail"
                    value={getHeaderBrandDetails}
                  />
                </div>

              </div>
              <div id="registration-form">
                <form>
                  {
                    <table className="table table-striped table-bordered table-hover dataTable no-footer margin-top-10" id="editabledatatable" style={{ width: "50%" }}>
                      <TableHeader headers={headers} onSorting={(field, order) => setSorting({ field, order })} />
                      <tbody>
                        {matchingList.filter(x => x.Isdeleted !== 1).map((inputfeild, index) => (
                          <tr>
                            <td>
                              <SelectCombobox className="basic-single" name="ItemName" id={"ItemName" + index}
                                isDisabled={false}
                                isLoading={false}
                                isSearchable={true}
                                isClearable={true}
                                autocomplete='off'
                                placeholder="Select Item Detail"
                                styles={submitted && inputfeild.Value === 0 ? styles : ''}
                                onChange={e => handleInputChange(e, index, "ItemName")}
                                options={ItemList}
                                value={ItemList.filter(function (option) {
                                  return option.value === inputfeild.Value;
                                })}
                              //value={inputfeild}
                              />
                            </td>
                            <td>
                              <SelectCombobox className="basic-single" name="InputType" id={"InputType" + index}
                                isDisabled={false}
                                isLoading={false}
                                isSearchable={true}
                                isClearable={true}
                                autocomplete='off'
                                placeholder="Select InputType"
                                styles={submitted && (inputfeild.ValueOne === 0 || inputfeild.ValueOne === undefined) ? styles : ''}
                                onChange={e => handleInputChange(e, index, "InputType")}
                                options={options}
                                value={options.filter(function (option) {
                                  return option.value === inputfeild.ValueOne;
                                })}
                              //value={inputfeild}
                              />
                            </td>

                            <td>
                              {/* {
                                matchingList.filter(x => x.Isdeleted !== 1).length === (index + 1) && ( */}
                              <span title='Add CSR Template'
                                onClick={() => handleAddFields(index)}
                                className="btn btn-info btn-xs plus">
                                <i className="fa fa-plus"></i>
                              </span>
                              {/* )

                              } */}
                              &nbsp;
                              {
                                matchingList.filter(x => x.Isdeleted !== 1).length !== 1 && (
                                  <span title='Delete CSR Template' onClick={() => handleRemoveFields(index, inputfeild)}
                                    className="btn btn-danger btn-xs delete">
                                    <i className="fa fa-trash-o"></i>
                                  </span>
                                )

                              }
                              {/* {matchi.length} */}
                            </td>
                            {/* <span> {index}</span> */}
                          </tr>

                        ))

                        }
                      </tbody>
                    </table>
                  }

                  <div className="col-sm-12">
                    <div className="buttons-preview" align="right">
                      <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                        <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                      </span>
                      <span className="btn btn-danger" onClick={() => ResetOperation()}>
                        <i className="fa fa-times"></i>&nbsp;Reset
                      </span>
                      <button type="button" className="btn btn-success" disabled={buttonLoader}
                        onClick={SaveTemplateDetials}
                      >
                        <i className="fa fa-check right"></i>&nbsp;Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CSRTemplateEdit