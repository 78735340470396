import React, { useState } from 'react';
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import { Button } from "react-bootstrap";
import GenderService from "../../services/Master/GenderService";
import Nodify from "../Common/ReactNotification";
import { useSelector } from "react-redux";

const GenderPopup = ({
    getGenderList,
    genderCallback,
    showpopup,
}) => {
    const EmptyGenderInputFields =
        [{
            GenderSizeID: 0,
            GenderSizeName: '',
        }]
    const { user: currentUser } = useSelector((state) => state.auth);

    const [buttonLoader, setButtonLoader] = useState(false);
    const [genderInputFields, setGenderInputFields] = useState(EmptyGenderInputFields);
    const [genderList, setgenderList] = useState([]);
    const [show, setShow] = useState(true);
    const [GenderSubmitted, setGenderSubmitted] = useState(false);

    const handlePopupClose = () => {
        genderCallback(false);
    };

    const CheckDuplicate = (index) => {
        const values = [...genderInputFields];
        if (getGenderList) {
            for (var i = 0; i < getGenderList.length; i++) {
                if (getGenderList[i].label.trim().toLowerCase() === values[index].GenderSizeName.trim().toLowerCase()) {
                    values[index].GenderSizeName = '';
                    // values[index].Id=0;
                    setGenderInputFields(values);
                    setgenderList({ Createdby: currentUser.employeeinformationID, GenderInformation: values });
                    Nodify('Warning!', 'warning', "This name is already exist.");
                    return false;
                }
            }
        }
    }

    const handleGroupTypeChange = (event) => {
        setGenderSubmitted(false);
        const values = [...genderInputFields];
        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }
        values[0].GenderSizeName = inputText;
        setGenderInputFields(values);
        setgenderList({ Createdby: currentUser.employeeinformationID, GenderInformation: values });
    }

    const SaveGenderSize = (e) => {
        setButtonLoader(true);
        e.preventDefault();
        const values = [...genderInputFields];
        if (values[0].GenderSizeName.trim() === '') {
            setGenderSubmitted(false);
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Gender  name is required.');
        }
        if (values[0].GenderSizeName.trim()) {
            GenderService.InsertUpdateGenderSizeGrid(genderList).then(res => {
                if (res.data.outputResult === "1") {
                    Nodify("Success!", "success", "Gender added successfully.");
                    genderCallback(true);
                } else if (res.data.outputResult === "-2") {

                    Nodify('Warning!', 'warning', 'This name is already exist.');
                }
                setButtonLoader(false);
            });
        }
        else {
            setGenderSubmitted(true);
        }
    }

    return (
        // <Modal show={showpopup} size="md" dragable backdrop="static" keyboard={false} onHide={() => handlePopupClose()}>
        //     <Modal.Header closeButton>
        //     <Modal.Title>Add Gender</Modal.Title>
        //     </Modal.Header>
        //       <Modal.Body>
        <div id="registration-form">
            <Form>
                <div className="row">
                    <div className="form-group col-sm-12">
                        <label htmlFor="Gender">
                            Gender
                            <span className="text-danger">*</span>
                        </label>
                        <Input
                            placeholder={"Enter Gender"}
                            id="Gender"
                            name="Gender"
                            type="text"
                            maxLength="50"
                            value={genderInputFields[0].GenderSizeName}
                            autoComplete="off"
                            onChange={handleGroupTypeChange}
                            className='form-control'
                            onBlur={() => CheckDuplicate(0)}
                            style={{ border: GenderSubmitted && !genderInputFields[0].Name ? '1px solid red' : '' }}
                            autoFocus
                        />
                    </div>
                </div>
                <div style={{ textAlign: "right" }}>
                    <Button type="button" className="btn btn-success" onClick={SaveGenderSize} disabled={buttonLoader}>
                        <i className="fa fa-check right"></i> &nbsp; Save
                    </Button>
                </div>
            </Form>
        </div>
        //     //   </Modal.Body>
        //     //   <Modal.Footer>
        //     //     <Button  type="submit" className="btn btn-success" onClick={SaveGenderSize} disabled={buttonLoader}>
        //     //        <i className="fa fa-check right"></i> &nbsp; Save
        //     //     </Button>
        //     //   </Modal.Footer>
        // </Modal>
    )
};

export default GenderPopup;