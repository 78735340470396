import {

  REPORTTEMPLATE_LOADING,
  CREATE_REPORTTEMPLATE,
  RETRIEVE_REPORTTEMPLATE,
  DELETE_REPORTTEMPLATE,
  RETRIEVE_REPORTMODULECATEGORYDRPDOWNLIST
} from "../actions/types";

const initialState = {
  isLoadingreporttemplate: true,
  reporttemplates: [],
};

const reporttemplateReducer = (state = initialState, action) => {
 ;
  const { type, payload } = action;
  switch (type) {
    case REPORTTEMPLATE_LOADING:
      return {
        ...state,
        isLoadingreporttemplate: payload
      };
    case CREATE_REPORTTEMPLATE:
      return { ...state, reporttemplates: payload };

    case DELETE_REPORTTEMPLATE:
      const results = state.reporttemplates.filter(c => c.reportTemplateID !== payload.reportTemplateID);
      // const results=state.reportmodulecategorys;
      return {
        ...state,
        reporttemplates: results
      };
    case RETRIEVE_REPORTTEMPLATE:
      return { ...state, reporttemplates: payload };


    case RETRIEVE_REPORTMODULECATEGORYDRPDOWNLIST:
      return {
        ...state,
        reporttemplates: payload
      };


    default:
      return state;
  }
};
export default reporttemplateReducer;