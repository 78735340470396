import {store} from 'react-notifications-component';
 const Nodify = (title,type,message) =>
{
 store.addNotification({
        title: title,
        message: message,
        type: type,
        insert: "top",
        container: "top-right",
        animationIn: [
            "animate__animated", "animate__fadeIn"
        ],
        animationOut: [
            "animate__animated", "animate__fadeOut"
        ],
        dismiss: {
            duration: 2000,
            onScreen: true,
        }
    });
    return false;

}

export default Nodify;

