// import axios from "axios";
// import authHeader from "../auth-header";
// class AddressTypeService {
//   getAllAddressType() {
//     return axios.get(window.$APIBaseURL  + "Master/GetAddressTypeList?AddressTypeID=0", {
//       headers: {
//         authorization: authHeader()
//       }
//     });
//   }

//   AddAddress(Params) {
//
//    return axios({
//       method: 'post',
//       url: window.$APIBaseURL + 'Master/InsertUpdateDelAddressType',
//       data: Params,
//       headers: {
//             authorization: authHeader()
//           }
//     });
//   }
// }

// export default new AddressTypeService()


// //import http from "../http-common";

// // import axios from "axios";
// // import authHeader from "../auth-header";

// // const  getAllAddressType() {
// //     return axios.get(window.$APIBaseURL  + "Master/GetAddressTypeList?AddressTypeID=0", {
// //       headers: {
// //         authorization: authHeader()
// //       }
// //     });
// // }

// // const getAllAddressType = () => {
// //   return axios.get(window.$APIBaseURL  + "Master/GetAddressTypeList?AddressTypeID=0", {
// //       headers: {
// //         authorization: authHeader()
// //       }
// //     });
// // };

// // const AddAddress = Params => {
// //
// //    return axios({
// //     method: 'post',
// //     url: window.$APIBaseURL + 'Master/InsertUpdateDelAddressType',
// //     data: Params,
// //     headers: {
// //       authorization: authHeader()
// //     }
// //   });
// // };


// // const AddAddress = (Params) =>{
// //
// //   return axios({
// //     method: 'post',
// //     url: window.$APIBaseURL + 'Master/InsertUpdateDelAddressType',
// //     data: Params,
// //     headers: {
// //       authorization: authHeader()
// //     }
// //   });
// // }



// // const getAll = () => {
// //   return http.get("/tutorials");
// // };

// // const get = id => {
// //   return http.get(`/tutorials/${id}`);
// // };

// // const create = data => {
// //   return http.post("/tutorials", data);
// // };

// // const update = (id, data) => {
// //   return http.put(`/tutorials/${id}`, data);
// // };

// // const remove = id => {
// //   return http.delete(`/tutorials/${id}`);
// // };

// // const removeAll = () => {
// //   return http.delete(`/tutorials`);
// // };

// // const findByTitle = title => {
// //   return http.get(`/tutorials?title=${title}`);
// // };

// // const branchService = {
// //   getAllAddressType,
// //   AddAddress,

// // };

// // export default branchService;

import axios from "axios";
import authHeader from "../auth-header";
class AddressTypeService {
  getAllAddressType() {
    return axios.get(window.$APIBaseURL  + "Master/GetAddressTypeList?AddressTypeID=0", {
      headers: {
        authorization: authHeader()
      }
    });
  }

      InsertUpdateBranchGrid(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateBranchGrid',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

  AddAddress(Params) {
  return axios({
      method: 'post',
      url: window.$APIBaseURL + 'Master/InsertUpdateDelAddressType',
      data: Params,
      headers: {
            authorization: authHeader()
          }
    });
  }
}

export default new AddressTypeService()