import {
    FABRICTYPE_LOADING,
    RETRIEVE_FABRICTYPE,
    DELETE_FABRICTYPE,
} from "./types";

import FabricService from "../services/Master/FabricService";

const fabricTypeLoading = (isStatus) => ({
    type: FABRICTYPE_LOADING,
    payload: isStatus,
});

export const deleteFabricType = (fabricDetailsID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_FABRICTYPE,
            payload: fabricDetailsID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrieveFabricType = (fabricDetailsID, FabricorYarn) => async (dispatch) => {
    try {
        dispatch(fabricTypeLoading(true));
        const res = await FabricService.GetFabricList(fabricDetailsID, FabricorYarn);
        dispatch({
            type: RETRIEVE_FABRICTYPE,
            payload: res.data,
        });
        dispatch(fabricTypeLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(fabricTypeLoading(false));
    }
};