import React from "react";
import { useSelector } from "react-redux";
import HeaderButton from "../Layout/HeaderButton";
import { Redirect, NavLink } from 'react-router-dom';
const BreadCrumbs = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const activeMenus = useSelector((state) => state.auth.activeMenuList);
  if (!currentUser) {
    return <Redirect to="/login" />;
  }
  return (
    <div className="page-breadcrumbs">
      <ul className="breadcrumb">
        <li><span>{activeMenus.ParentDisplayName}</span></li>
        {activeMenus.ChildDisplayName !== '' && (
          <li>
            {activeMenus.SubChildDisplayName !== '' ?
              <span>{activeMenus.ChildDisplayName}</span>
              :
              <NavLink to={activeMenus.ChildURLPath}>
                <span>{activeMenus.ChildDisplayName}</span>
              </NavLink>
            }
          </li>
        )}
        {activeMenus.SubChildDisplayName !== '' && (
          <li>
            <NavLink to={activeMenus.SubChildURLPath}>
              <span>{activeMenus.SubChildDisplayName}</span>
            </NavLink>
          </li>
        )}
      </ul>
      <HeaderButton></HeaderButton>
    </div>
  );
};
export default BreadCrumbs;