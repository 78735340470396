import React, { useState, useEffect, useRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactNotification from 'react-notifications-component';
import Nodify from "../../../Common/ReactNotification"
import MasterSizeService from "../../../../services/Master/MasterSizeService";
import SearchRetain from "../../../Common/SearchRetain";
import { insertUpdateMasterSizeGrid } from "../../../../actions/MasterSize";

const MasterSizeAdd = (props) => {

    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    if (menulist !== undefined) {
        activeMenu = menulist.filter(i => i.menuName === "Size");
    }
    //end enable menu

    const EmptyInputFields =
        [{
            Id: 0,
            Name: '',
        }]
    const [inputFields, setInputFields] = useState(EmptyInputFields);
    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getMasterSizeList, setMasterSizeList] = useState([EmptyInputFields]);
    //const [ExistingList, setExistingList] = useState([]);

    const dispatch = useDispatch();
    // reducer
    const reducerState = useSelector((state) => state);
    const { user: currentUser } = reducerState.auth;
    const ExistingList = reducerState.mastersize.MasterSizeList;
    let isLoadingMasterSize = reducerState.mastersize.isLoadingMasterSize;

    useEffect(() => {
        MasterSizeService.getMasterSizeList().then((response) => {
            if (response.data) {
                // setExistingList(response.data);
            }
        });
    }, []);

    const handleAddFields = (index) => {
        const values = [...inputFields];
        if (values[index].Name.trim() !== '') {
            values.push({
                Id: 0,
                Name: '',
            });
            setInputFields(values);
            setSubmitted(false);
        }
        else {
            Nodify('Warning!', 'warning', 'Please fill the empty text fields.');
            setSubmitted(true);
        }
    };

    const handleInputChange = (index, event) => {

        const values = [...inputFields];
        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }
        values[index].Name = inputText;
        setInputFields(values);
        setMasterSizeList({ Createdby: currentUser.employeeinformationID, MasterSizeInformation: values });
    };

    const handleRemoveFields = index => {
        const values = [...inputFields];
        values.splice(index, 1);
        setInputFields(values);
        setMasterSizeList({ Createdby: currentUser.employeeinformationID, MasterSizeInformation: values });
    };

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/MasterSizeList');
        } else {
            //   setInputFields(EmptyInputFields);
            // setSubmitted(false);
            // setButtonLoader(false);
            window.location.reload();
        }
    }

    const CheckDuplicate = (index) => {
        const values = [...inputFields];
        // ProcessTypeService.getProcessTypeList().then((response) => {
        if (ExistingList) {
            for (var i = 0; i < ExistingList.length; i++) {
                if (ExistingList[i].sizeNames.trim().toLowerCase() === values[index].Name.trim().toLowerCase()) {
                    values[index].Name = '';
                    setInputFields(values);
                    Nodify('Warning!', 'warning', "This name is already exist.");

                    return false;
                }
            }
        }
        //  });

        for (var i = 0; i < (inputFields.length); i++) {
            if (i !== index) {
                if (inputFields[i].Name.trim().toLowerCase() === inputFields[index].Name.trim().toLowerCase()) {
                    values[index].Name = "";
                    setInputFields(values);
                    Nodify('Warning!', 'warning', 'This name is already exist.');
                    return false;
                }
            }
        }
    }
    //Enter Error msg
    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                // SaveBuyerInfo(event);
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);
    const SaveMasterSizeList = (e) => {

        setButtonLoader(true);
        e.preventDefault();

        if (inputFields.length === 1 && inputFields[0].Name === '') {
            setButtonLoader(false);
            setSubmitted(true);
            ValidationPopup("Please fill atleast one Size.");
        }
        else {

            for (var i = 0; i < (inputFields.length); i++) {
                if (inputFields[i].Name.trim() === '') {
                    Nodify('Warning!', 'warning', 'Please fill the empty text fields.');
                    setSubmitted(true);
                    setButtonLoader(false);
                    return false;
                }

            }

            MasterSizeService.InsertUpdateMasterSizeGrid(getMasterSizeList).then(res => {
                var page = "Remove";
                SearchRetain(page);
                let Func = 'Add';
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "-2") {
                    setButtonLoader(false);
                    ValidationPopup("This name is already exist.");
                }
                setButtonLoader(false);
            })

                .catch(e => {
                    console.log(e);
                });
        }
    }

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/MasterSizeList",
            state: {
                message: Func
            }
        });
    }

    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
        return false;
    }

    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">Add Size</h3>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <form>
                                    {
                                        inputFields.map((inputField, index) => (
                                            <Fragment key={
                                                `${inputField}~${index}`
                                            }>
                                                <div className="row row_left10">
                                                    <div className="col-sm-3 divder_style">
                                                        <div className="form-group">
                                                            {/* <label htmlFor={"SeasonName_" + index}>Season Name<span className="text-danger">*</span></label> */}

                                                            <span className="input-icon icon-right">
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"MasterSizeName_" + index}
                                                                    name="MasterSizeName"
                                                                    placeholder="Enter Size"
                                                                    maxLength="150"
                                                                    value={inputField.Name}
                                                                    onChange={event => handleInputChange(index, event)}
                                                                    onBlur={() => CheckDuplicate(index)}
                                                                    style={{ border: submitted && !inputField.Name ? '1px solid red' : '' }}
                                                                    autoFocus
                                                                />
                                                            </span>

                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3 paddingstatic">
                                                        {
                                                            inputFields.length !== 1 && (
                                                                <div className="col-lg-1_own col-xs-12">
                                                                    <button type="button" className="btn btn-danger"
                                                                        style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}
                                                                        title="Delete Size" onClick={() => handleRemoveFields(index)}>
                                                                        <i className="fa fa-trash-o"></i>
                                                                    </button>
                                                                </div>
                                                            )}
                                                        {
                                                            inputFields.length === (index + 1) && (
                                                                <div className="col-lg-1_own col-xs-12">

                                                                    <button type="button" className="btn btn-success" title="Add Size" onClick={() => handleAddFields(index)}>
                                                                        <i className="fa fa-plus"></i>
                                                                    </button>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </Fragment>
                                        ))
                                    }
                                    <div className="col-sm-12">
                                        <div className="buttons-preview" align="right">
                                            <span className="btn btn-primary"
                                                onClick={
                                                    () => ResetOperation("Back")
                                                }>
                                                <i className="fa fa-arrow-circle-left"></i>
                                                &nbsp;Back</span>
                                            <span className="btn btn-danger"
                                                onClick={
                                                    () => ResetOperation()
                                                }>
                                                <i className="fa fa-times"></i>
                                                &nbsp;Reset</span>
                                            <button type="button" className="btn btn-success" disabled={buttonLoader}
                                                onClick={SaveMasterSizeList}>

                                                <i className="fa fa-check right"></i>
                                                &nbsp;Save</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MasterSizeAdd;