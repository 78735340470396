import React, { useState, useEffect, Fragment } from 'react';
import QcInspection from '../../../services/QCInspection/QcInspection';
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import SKUService from "../../../services/Style/SKUService";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";
import ProtoFitSMSApproval from '../../TNA/ProtoFitSMSApproval';
import Lightbox from "react-image-lightbox"
const AddQcNotes = ({ props, setrenderval }) => {
    const [getQcNotesInputFeild, setQcNotesInputFeild] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [getSKUSampleApproval, setSKUSampleApproval] = useState([]);
    const [getParentTask, setParentTask] = useState();
    const [getViewSamplesApproval, setViewSamplesApproval] = useState({ isShow: false, value: '' });
    const [getAddOrViewAttachment, setAddOrViewAttachment] = useState({ isShow: false, index: null });
    const [fileList, setFileList] = useState([]);
    const [getImagename, setImagename] = useState();
    const [isOpenimg, setIsOpenimg] = useState(false);

    useEffect(() => {
        showLoader();
        let Multiselecteddate = [];
        Multiselecteddate = props.location.state.params.multiselecteddatas.filter(x => x.isSelected === 1);
        let QcNotesList = [];
        QcInspection.getQcNotesList(1, props.location.state.params.styleID, 0).then((res) => {
            if (res.data.qcNotesListDetails) {
                res.data.qcNotesListDetails.map((x) => {
                    //if (Multiselecteddate.filter(m => m.refInspecSKUID === x.skuid).length > 0) {
                    QcNotesList.push({
                        CommentsName: x.qcComments,
                        IndexID: x.indexID
                    })
                    //}
                })
            }
            setQcNotesInputFeild(QcNotesList);
            hideLoader();
        })
        SKUService.LoadSKUSamplesApprovalList(props.location.state.params.styleID).then((response) => {
            if (response.data) {
                setSKUSampleApproval(response.data);
            }
        }).catch(() => { hideLoader(); });
    }, [setrenderval])

    const ViewSamplesApproval = (SampleApproval) => {
        showLoader();
        setParentTask(SampleApproval.taskName);
        setViewSamplesApproval({ isShow: true, value: SampleApproval });
        hideLoader();
    }

    const handleCloseSamplesApproval = () => {
        setViewSamplesApproval({ isShow: false, value: null });

    }
    const handleCloseQCNotesFormPopup = index => {
        //  setAddOrViewAttachment(false);
        setAddOrViewAttachment({ isShow: false, index: null });

    }
    function OpenFileList(params) {
        showLoader();
        setAddOrViewAttachment({ isShow: true, index: 1 });
        let QcNotesFileList = [];
        QcInspection.getQcNotesList(2, props.location.state.params.styleID, params.IndexID).then((res) => {
            if (res.data.qcNotesListFileDetails) {
                res.data.qcNotesListFileDetails.map((x) => {
                    QcNotesFileList.push({
                        AttachmentID: x.attachmentID,
                        fileName: x.fileName,
                        filePath: x.filePath,
                        fileType: x.fileType
                    })

                })
            }
            setFileList(QcNotesFileList);
            hideLoader();
        })
    }

    const ViewFile = (index, filename) => {
        setImagename(filename);
        setIsOpenimg(true);
    }

    return (
        <div className='row'>
            <div className='col-lg-12'>
                <b style={{color:"#00a65a"}}>Custom Comments</b>
                <table className="table table-striped table-bordered table-hover dataTable no-footer">
                    <thead>
                        <tr>
                            <th>Custom Notes</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getQcNotesInputFeild.map((QcInput, index) => (
                            <tr>
                                <td>
                                    {QcInput.CommentsName}
                                </td>
                                <td>
                                    <a className="washcareView" title='View Attachment' onClick={() => OpenFileList(QcInput)}>
                                        <i className="btn btn-info fa fa-eye"></i>
                                    </a>&nbsp;
                                    {/* <buttton className='btn btn-link' onClick={() => OpenFileList(QcInput)}>View Attachment</buttton> */}
                                </td>
                            </tr>
                        ))
                        }
                        {
                            getQcNotesInputFeild.length === 0 ?
                                <tr>
                                    <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
                                </tr> : ''
                        }
                    </tbody>
                </table>
                <br />
                <br />
                {
                    getAddOrViewAttachment.isShow && <>
                        <Modal dialogClassName="CreatePopup" show={getAddOrViewAttachment.isShow} size="md" dragable backdrop="static" keyboard={false}
                            onHide={() => handleCloseQCNotesFormPopup()}>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    QA Notes Attachment
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="row">
                                    {
                                        <div className="col-lg-12 col-sm-12">
                                            <div className="row no-margin">
                                                {
                                                    fileList.length !== 0 && fileList.map((files, fileindex) => (
                                                        files.fileType === "image/jpeg" || files.fileType === "image/jpg" || files.fileType === "image/png" ||
                                                            files.fileType === "image/gif" ?
                                                            <>
                                                                <div className="float-Left_washcare file_Image_uploads">

                                                                    <div className="client_tumblineWashcare">
                                                                        <img className="width_manual1"
                                                                            src={window.$APIBaseURL + 'Images/Style/QCNotesUpload/' + files.fileName}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <a className="washcareView" style={{ marginLeft: '115px' }} title='View QC Notes' onClick={() => ViewFile(fileindex, files.fileName)}>
                                                                            <i className="btn btn-info fa fa-eye"></i>
                                                                        </a>&nbsp;
                                                                        {/* <a title='Remove QC Notes' onClick={() => RemoveImages(getAddOrViewAttachment.index, fileindex)}>
                                                                            <i className="btn btn-danger fa fa-trash-o"></i>
                                                                        </a> */}
                                                                    </div>
                                                                </div>
                                                                {
                                                                    < div > {
                                                                        isOpenimg && (

                                                                            <Lightbox mainSrc={window.$APIBaseURL + 'Images/Style/QCNotesUpload/' + getImagename}
                                                                                alt="bg image"
                                                                                onCloseRequest={
                                                                                    () => setIsOpenimg(false)
                                                                                } />
                                                                        )
                                                                    } </div>
                                                                }
                                                            </>
                                                            : ""
                                                    ))

                                                }
                                                {
                                                    fileList.length === 0 ?
                                                    <div style={{textAlign: 'center', color: '#df5138'}} > No Records Found</div>
                                                     : ''
                                                }
                                            </div>
                                        </div>
                                    }
                                    {
                                        <div className="col-lg-12 col-sm-12">
                                            <div className="row no-margin">
                                                {
                                                    fileList.length !== 0 && fileList.map((files, fileindex) => (
                                                        files.fileType === "application/pdf" || files.fileType === "application/msword" || files.fileType === "application/vnd.ms-excel"
                                                            || files.fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ?
                                                            <>
                                                                <div className="col-lg-4 col-sm-4 file_list_upload">
                                                                    <tr title={files.fileName}>
                                                                        <td className="cut_textInTNA">
                                                                            <span style={{ marginRight: '6px' }}>
                                                                                {
                                                                                    files.fileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                        files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                            <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                            files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                files.fileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                }
                                                                                {
                                                                                    files.fileType === "image/jpeg" || files.fileType === "image/jpg" || files.fileType === "image/png" ||
                                                                                        files.fileType === "image/gif" ? <i class="fa fa-picture-o fa-lg image_icon_style"></i> : ''
                                                                                }
                                                                            </span>  {files.fileName}
                                                                        </td><td className="button_style_uploda">
                                                                            {/* {Buttonview !== "View" &&

                                                                                <p title='Delete File'
                                                                                    onClick={() => RemoveImages(getAddOrViewAttachment.index, fileindex)}
                                                                                    className="btn btn-danger btn-xs delete">
                                                                                    <i className="fa fa-trash-o"></i>

                                                                                </p>
                                                                            } */}
                                                                            &nbsp;
                                                                            {
                                                                                files.fileType === "application/pdf" || files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                                                                                    files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                                                    || files.fileType === 'application/msword' || files.fileType === "application/vnd.ms-excel" ?
                                                                                    <a type="button"
                                                                                        title="Download" class="btn btn-xs btn-primary" target="_blank"
                                                                                        href={window.$APIBaseURL + 'Images/Style/QCNotesUpload/' + files.fileName} download><i class="fa fa-download"></i> </a> : ''
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                </div>
                                                            </>
                                                            : ""
                                                    ))
                                                }
                                                {/* {
                                                    fileList.length === 0 ?
                                                        <tr>
                                                            <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
                                                        </tr> : ''
                                                } */}
                                            </div>
                                        </div>
                                    }
                                </div>

                            </Modal.Body>
                            <Modal.Footer>
                                <button class="btn btn-xs btn-danger" onClick={() => handleCloseQCNotesFormPopup()}>close</button>
                            </Modal.Footer>
                        </Modal>
                    </>
                }

            </div>

            <div className='col-lg-12'>
                <b style={{color:"#00a65a"}}>Sample Comments <span style={{color:"#f4b400 "}}>(TNA ID - Task Name - SKU - Approval Status on (Date) by (User))</span></b>
                <table className="table table-striped table-bordered table-hover dataTable no-footer">
                    <thead>
                        <tr>
                            <th>Sample Details</th>
                            <th>Form / Manual Close</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            getSKUSampleApproval.length !== 0 ?
                                getSKUSampleApproval.map((SampleApproval, index) => (
                                    <>
                                        <tr>
                                            <td>

                                                {SampleApproval.manualCloseComment === '' ?
                                                    <>


                                                        {SampleApproval.sampleDetails} - {SampleApproval.skuName === null ? <b> <span class='primary'>Any Color</span></b> : <b><span class='primary'>{SampleApproval.skuName}</span></b>} - <b>{SampleApproval.approvedStatus === 'Rejected' ? <span class='danger'>{SampleApproval.approvedStatus}</span> : <span class='success'>{SampleApproval.approvedStatus}</span>} </b>
                                                        On {moment(SampleApproval.approvedDate).format("DD/MM/YYYY")} By {SampleApproval.approvedBy}

                                                    </> : <>{SampleApproval.sampleDetails} - {SampleApproval.skuName === null ? <b><span class='primary'>Any Color</span></b> : <b><span class='primary'>{SampleApproval.skuName}</span></b>} </>}


                                                {/* {SampleApproval.approvedStatus} { SampleApproval.approvedDate}- {SampleApproval.approvedBy} */}

                                                {/* {SampleApproval.manualCloseComment === '' &&
                                                                                <>
                                                                                 {SampleApproval.sampleDetails} - {SampleApproval.skuName === null ? 'Any Color' : SampleApproval.skuName} - {SampleApproval.approvedStatus - SampleApproval.approvedDate - SampleApproval.approvedBy}
                                                                                 </>
                                                                                } */}


                                            </td>
                                            <td>
                                                {SampleApproval.manualCloseComment === '' ? 'Form' : 'Manual Close'}

                                            </td>
                                            <td>

                                                <button type="button" onClick={() => ViewSamplesApproval(SampleApproval)}
                                                    className="btn btn-xs btn-warning" title="View">
                                                    <i className="fa fa-eye"></i>
                                                </button>

                                            </td>
                                        </tr>
                                    </>
                                ))
                                : <tr>
                                    <td colSpan="3" className='norecordfound'><span>No Records Found</span></td>
                                </tr>
                        }
                    </tbody>
                </table>
                {
                    getViewSamplesApproval.isShow && <>
                        <Modal dialogClassName="CreatePopup" show={getViewSamplesApproval.isShow} size="md" dragable backdrop="static" keyboard={false}
                            onHide={() => handleCloseSamplesApproval()}>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    View Approval Result
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="row">

                                    <div className="col-md-12">
                                        {getViewSamplesApproval.value.manualCloseComment !== '' ?
                                            <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                                <thead>
                                                    <tr>
                                                        <th>ManualClose Comment</th>
                                                        <th>ManualClose Date</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {getViewSamplesApproval.value.manualCloseComment}
                                                        </td>
                                                        <td>
                                                            {moment(getViewSamplesApproval.value.manualCloseDate).format("DD/MM/YYYY")}
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                            : ''
                                        }
                                        {getViewSamplesApproval.value.manualCloseComment === '' ?
                                            <div>
                                                <ProtoFitSMSApproval props={props}
                                                    ParentTask={getParentTask}
                                                    TaskStatus={3}
                                                    TNAProtoFitId={getViewSamplesApproval.value.sampleDetailsID}
                                                ></ProtoFitSMSApproval>
                                            </div>
                                            : ''
                                        }
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button class="btn btn-xs btn-danger" onClick={() => handleCloseSamplesApproval()}>close</button>
                            </Modal.Footer>
                        </Modal>
                    </>
                }
                <br />
                <br />
                <b style={{color:"#00a65a"}}>Test Reports</b>
                <table className="table table-striped table-bordered table-hover dataTable no-footer">
                    <thead>
                        <tr>
                            <th>Test Reports</th>
                            {/* //<th>Action</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Working in progress</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {
                < div > {
                    isOpenimg && (

                        <Lightbox mainSrc={window.$APIBaseURL + 'Images/Style/QCNotesUpload/' + getImagename}
                            alt="bg image"
                            onCloseRequest={
                                () => setIsOpenimg(false)
                            } />
                    )
                } </div>
            }
            {loader}
        </div>


    )
}

export default AddQcNotes