import {
 BUYCUSWAR_LOADING,
 RETRIEVE_BUYCUSWAR,
 DELETE_BUYCUSWAR,
} from "../actions/types";

const initialState = {
 isLoadingList: true,
 buyCusWarList: [],
 buyCusWarListEdit: [],
}

const BuyCusWarehouseReducer = (state = initialState, action) => {
 const { type, payload } = action
 switch (type) {
  case BUYCUSWAR_LOADING:
   return {
    ...state,
    isLoadingList: payload
   };
  case RETRIEVE_BUYCUSWAR:
  ;
   return {
    ...state,
    buyCusWarList: payload.item1,
    CusList: payload.item2,
    buyCusWarListEdit: payload.item3
   }
  case DELETE_BUYCUSWAR:

   let results = state.buyCusWarList.filter(c => c.buyerID !== payload.buyerID);
   return {
    ...state,
    buyCusWarList: results
   }
  default:
   return state

 }

}
export default BuyCusWarehouseReducer