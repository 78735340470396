import {
    STATE_LOADING,
    RETRIEVE_STATE,
    DELETE_STATE,
} from "../actions/types";

const initialState = {
    isLoadingState: true,
    StateList: [],
};

const StateReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case STATE_LOADING:
            return {
                ...state,
                isLoadingState: payload
            };
        case RETRIEVE_STATE:
            return { ...state, StateList: payload };
        case DELETE_STATE:
            const results = state.StateList.filter(c => c.stateId !== payload.StateID);
            return {
                ...state,
                StateList: results
            };

        default:
            return state;
    }
};
export default StateReducer;