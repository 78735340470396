import React, { useState, useEffect, useRef, Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import Lightbox from "react-image-lightbox";
import TnaService from "../../../../services/TNA/TNAService";
import DatePicker from "react-datepicker";
import StrikeOffSubmission from "../../../TNA/StrikeoffSubmission_Order";
import Nodify from "../../../Common/ReactNotification"
import { getOrderSamplesRequestViewList, clearSamplesDetailsViewSubRow, getReloadOrderSamplesRequestViewList, getReloadOrderSamplesSubmissionViewList } from "../../../../actions/orderProtoFitSms";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Reactselect from 'react-select';
import { Modal, Button } from "react-bootstrap";
import { loadRouteDependencyList } from "../../../../actions/route";
import taskService from "../../../../services/Master/TaskService";
import { DateRangePicker } from 'react-date-range';
import moment from "moment";
import { loadTaskHolderList } from "../../../../actions/task";
import { TableHeader, Pagination, Search, PageCount } from "../../../Datatable";
import ProtoFitSMSSubmissionOrder from "../../../TNA/ProtoFitSMSSubmission_Order";
const ProtoFitViewRequestTab = (props) => {

  const dispatch = useDispatch();
  const [loader, showLoader, hideLoader] = useFullPageLoader();


  const { user: currentUser } = useSelector((state) => state.auth);
  
 
  const ordSampleList = useSelector((state) => state.orderProtoFitSms.ordSampleRequestList);
  let isLoadingProtoFitSms = useSelector((state) => state.orderProtoFitSms.isLoadingProtoFitSms);
  const getSupplierList = useSelector((state) => state.tna.drpSupplierList);
  let GLDRequestStatusList = useSelector((state) => state.orderStrikeOff.OLDRequestStatusList);
  const StaticTask = useSelector((state) => state.tna.StaticTask);
  const [Indexes, setIndexes] = useState({ Index: 0, SubIndex: 0, ChildIndex: 0 });

  const [Manualclosedata, setManualclosedata] = useState({ ManualCloseComment: '', ManualCloseDate: '' });
  const [selectall, setselectall] = useState(false);
  const [MultiCloseDatas, setMultiCloseDatas] = useState([]);
  const [showmaunalclose, setshowmaunalclose] = useState(false);
  const [manualtask, setmanualtask] = useState(false);

  const [TNAProtoFitId, setTNAProtoFitId] = useState();
  const [ParentTask, setParentTask] = useState('');
  const [ChildTask, setChildTask] = useState('');
  const [TaskStatus, setTaskStatus] = useState();
  const [TNAId, setTNAId] = useState();
  const [StyleId, setStyleId] = useState();
  const [getSampleSubModalpopup, setSampleSubModalpopup] = useState(false);
  const [editProtoFitSmsSubmission, seteditProtoFitSmsSubmission] = useState(false);
  const [delteProtoFitSmsSubmission, setdeleteProtoFitSmsSubmission] = useState(false);
  const [getexistsubmission, setexistsubmission] = useState(false);
  const [protofitsmsDisable, setprotofitsmsDisable] = useState(false);
  const [getComment, setComment] = useState(false);
  const [getCommentManualclose, setCommentManualclose] = useState(false);
  const [MultiManualCloseFeilds, setMultiManualCloseFeilds] = useState({ IdList: '', TaskStatus: 0, ManualCloseDate: '', ManualCloseComment: '', Createdby: 1 });
  // If check production or sampling
  const activeMenus = useSelector((state) => state.auth.activeMenuList);
  // let IsProduction = activeMenus.IsProduction;

  let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
  let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal : activeMenus.IsProduction;


  let activeMenu;
  const menulist = useSelector((state) => state.auth.UserMenuList.item3);
  activeMenu = menulist.filter(i => i.menuName === "Samples")






  const headers = [
    { name: "Style Name", field: "StyleName", sortable: false },
    { name: "Style No", field: "StyleNo", sortable: false },
    { name: "PO/ID No", field: "IDPo", sortable: false },
    { name: "Details", field: "Details", sortable: false },
    { name: "Task Holder / Follower", field: "", sortable: false },
    { name: "Action", field: "", sortable: false, display: activeMenu && activeMenu[0].isEdit === 0 &&
      activeMenu[0].isDelete === 0 ? 'none' : '' },
  ];


  let BuyerId = 0;
  let BrandId = 0;
  let SeasonId = 0;

  //get props params
  if (props.props.location.state !== undefined) {

    BuyerId = props.props.location.state.params.buyerId === undefined ? 0 : props.props.location.state.params.buyerId;
    BrandId = props.props.location.state.params.brandId === undefined ? 0 : props.props.location.state.params.brandId;
    SeasonId = props.props.location.state.params.seasonId === undefined ? 0 : props.props.location.state.params.seasonId;

  }




  useEffect(() => {
    if (!isLoadingProtoFitSms) {
      hideLoader();
    } else {
      showLoader();
    }
  }, [isLoadingProtoFitSms])

  const [getSearchReqValues, setSearchReqValues] = useState({
    StatusId: 0,
    SupplierId: 0,
    SearchTxt: "",
    StyleName: "",
    StyleNo: "",
    IdPoNo: "",
    Samplecolor: "",
    SampleName: '',
    stateDate: [
      {
        startDate: null,
        endDate: new Date(""),
        key: 'selection',
        isShow: false
      }
    ]

  });


  const SearchReqValues = { ...getSearchReqValues };

  useEffect(() => {
    searchSamples();
  }, [])







  const handleSearchChange = (e, FieldName) => {

    let inputText = '';
    if (FieldName === 'SearchTxt' || FieldName === 'Samplecolor' || FieldName === 'SampleName' || FieldName === 'StyleName' || FieldName === 'StyleNo' || FieldName === 'IdPoNo') {
      if (e && e.target.value.trim() !== '') {
        inputText = e.target.value;
      }
    } else if (FieldName === 'Status' || FieldName === 'SupplierId') {
      if (e) {
        inputText = e.value
      }
    }
    if (FieldName === 'Status') {
      SearchReqValues.StatusId = inputText;
    }
    else if (FieldName === 'SupplierId') {
      SearchReqValues.SupplierId = inputText;
    } else if (FieldName === 'SearchTxt') {
      SearchReqValues.SearchTxt = inputText;
    } else if (FieldName === 'StyleName') {
      SearchReqValues.StyleName = inputText;
    }
    else if (FieldName === 'StyleNo') {
      SearchReqValues.StyleNo = inputText;
    }
    else if (FieldName === 'IdPoNo') {
      SearchReqValues.IdPoNo = inputText;
    }
    else if (FieldName === 'Samplecolor') {
      SearchReqValues.Samplecolor = inputText;
    }
    else if (FieldName === 'SampleName') {
      SearchReqValues.SampleName = inputText;
    }
    setSearchReqValues(SearchReqValues);

  }

  const resetSamples = () => {
    SearchReqValues.stateDate[0].startDate = null;
    SearchReqValues.stateDate[0].endDate = new Date("");
    SearchReqValues.stateDate[0].key = 'selection';
    SearchReqValues.stateDate[0].isShow = false;

    SearchReqValues.StatusId = 0;
    SearchReqValues.SupplierId = 0;
    SearchReqValues.SearchTxt = "";
    SearchReqValues.StyleName = "";
    SearchReqValues.StyleNo = "";
    SearchReqValues.IdPoNo = "";
    SearchReqValues.SampleName = "";
    SearchReqValues.Samplecolor = "";
    setSearchReqValues(SearchReqValues);
    searchSamples();

  }


  const clearDateSelect = () => {
    SearchReqValues.stateDate[0].startDate = null;
    SearchReqValues.stateDate[0].endDate = new Date("");
    SearchReqValues.stateDate[0].key = 'selection';
    SearchReqValues.stateDate[0].isShow = false;

    setSearchReqValues(SearchReqValues);
  }
  const handleDateSelect = (event, isShow) => {

    SearchReqValues.stateDate[0].isShow = isShow;
    setSearchReqValues(SearchReqValues);
  }

  const handlePopupDateselection = (item) => {

    SearchReqValues.stateDate = [item.selection];
    setSearchReqValues(SearchReqValues);
  }

  function convertUTCDateToLocalDate(date) {
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return date;
  }


  const searchSamples = () => {

    SearchReqValues.stateDate[0].isShow = false;
    setSearchReqValues(SearchReqValues);

    let StartDate = null;
    let EndDate = null;
    if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
      StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
    }
    if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
      EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
    }

    let params = {
      BuyerId: BuyerId,
      BrandId: BrandId,
      SeasonId: SeasonId,
      Operation: 2,
      StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
      SupplierId: SearchReqValues.SupplierId,
      Samplecolor: SearchReqValues.Samplecolor,
      SampleName: SearchReqValues.SampleName,
      StartDate: StartDate,
      EndDate: EndDate,
      StyleName: SearchReqValues.StyleName,
      StyleNo: SearchReqValues.StyleNo,
      IdPoNo: SearchReqValues.IdPoNo
      , IsProduction: IsProduction
    };
    dispatch(getOrderSamplesRequestViewList(params));
  }

  const handleChildManualclose = async (TaskName, TaskType, TaskID, ChildId, Status, ManualCloseComment, buyerid, brandid, seasonid, supplierid) => {

    showLoader();
    var manualclosedata = { ...Manualclosedata };
    manualclosedata.ManualCloseComment = "";
    manualclosedata.ManualCloseDate = ''
    setManualclosedata(manualclosedata)
    let Operation;
    if (TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
      Operation = 1;
    }
    else if (TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT) {
      Operation = 3;
    }
    else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
      Operation = 2;
    }
    else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL) {
      Operation = 4;
    }
    else if (TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
      Operation = 5;
    }
    else if (TaskName.toLowerCase() === StaticTask.SMS_APPROVAL) {
      Operation = 6;
    }
    else if (TaskName.toLowerCase() === StaticTask.SIZE_SET_APPROVAL) {
      Operation = 8;
    }
    else if (TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL) {
      Operation = 9;
    }
    else if (TaskName.toLowerCase() === StaticTask.SHIPMENT_APPROVAL) {
      Operation = 10;
    }
    else if (TaskName.toLowerCase() === StaticTask.PHOTO_APPROVAL) {
      Operation = 11;
    }
    else {
      Operation = 7;
    }
    if (Operation !== 7) { // check submission exists for approvals
      TnaService.GetTNAManualCloseSubmissionList(ChildId, TaskName).then((response) => {
        if (response.data.length !== 0) {
          setshowmaunalclose(true);
          setmanualtask(TaskName)

          //get manual close
          let Operation;
          if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
            Operation = 1;
          }
          else if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
            || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
            Operation = 3;
          }
          else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
            || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER) {
            Operation = 2;
          }
          else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL) {
            Operation = 4;
          }
          else if (TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
            Operation = 5;
          }
          //|| TaskName.toLowerCase() === StaticTask.SMS_PURCHASE_ORDER
          else if (TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || TaskName.toLowerCase() === StaticTask.SMS_APPROVAL
            || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER
            || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE || TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW
            || TaskName.toLowerCase() === StaticTask.SIZE_SET_SUBMISSION || TaskName.toLowerCase() === StaticTask.SIZE_SET_APPROVAL
            || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_SUBMISSION || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL
            || TaskName.toLowerCase() === StaticTask.SHIPMENT_SUBMISSION || TaskName.toLowerCase() === StaticTask.SHIPMENT_APPROVAL
            || TaskName.toLowerCase() === StaticTask.PHOTO_SUBMISSION || TaskName.toLowerCase() === StaticTask.PHOTO_APPROVAL) {
            Operation = 6;
          }
          else if (TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH) {
            Operation = 7;
          }
          else {
            Operation = 8;
          }


          let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId,
            SupplierId: SearchReqValues.SupplierId, TaskType: TaskType, TaskNameId: TaskID,
            TaskTodoInProgressCompletedStatus: 9, StartDate: null, EndDate: null,
            Operation: Operation, ParentIdOrSubChildId: ChildId
          };

          TnaService.GetSubChildMultiTaskManualCloseList(params).then((response) => {
            if (response.data) {
              let manindex = 1;
              let data = response.data.map((item) => {
                item.index = manindex;
                manindex = manindex + 1;

                if (item.subTaskInfoId === ChildId) {
                  item.isSelected = 1;

                  return item;
                } else {
                  return item;
                }
              });
              let isSelectAll = 0;
              if (data) {
                isSelectAll = !!!data.find(d => d.isSelected === 0);
                isSelectAll = isSelectAll ? 1 : 0;
              }
              setselectall(isSelectAll);
              if (Status !== 3) {
                setMultiCloseDatas(data);

              }
              else {
                setMultiCloseDatas(data.filter(x => x.subTaskInfoId === ChildId));

              }

            }


          }).catch(() => { });
        }
        else {
          Nodify('Warning!', 'warning', "Manual Submission not yet added for this task");
          return false;
        }

      }).catch(() => { });
    }
    else {
      setshowmaunalclose(true);
      setmanualtask(TaskName)

      //get manual close
      let Operation;
      if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
        Operation = 1;
      }
      else if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
        || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
        Operation = 3;
      }
      else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
        || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER
        || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER) {
        Operation = 2;
      }
      else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL) {
        Operation = 4;
      }
      else if (TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
        Operation = 5;
      }
      //|| TaskName.toLowerCase() === StaticTask.SMS_PURCHASE_ORDER
      else if (TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || TaskName.toLowerCase() === StaticTask.SMS_APPROVAL
        || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER
        || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE || TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW
        || TaskName.toLowerCase() === StaticTask.SIZE_SET_SUBMISSION || TaskName.toLowerCase() === StaticTask.SIZE_SET_APPROVAL
        || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_SUBMISSION || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL
        || TaskName.toLowerCase() === StaticTask.SHIPMENT_SUBMISSION || TaskName.toLowerCase() === StaticTask.SHIPMENT_APPROVAL
        || TaskName.toLowerCase() === StaticTask.PHOTO_SUBMISSION || TaskName.toLowerCase() === StaticTask.PHOTO_APPROVAL) {
        Operation = 6;
      }
      else if (TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH) {
        Operation = 7;
      }
      else {
        Operation = 8;
      }


      let params = {
        BuyerId: buyerid, BrandId: brandid, SeasonId: seasonid,
        SupplierId: supplierid, TaskType: TaskType, TaskNameId: TaskID,
        TaskTodoInProgressCompletedStatus: 9, StartDate: null, EndDate: null,
        Operation: Operation, ParentIdOrSubChildId: ChildId
      };

      await TnaService.GetSubChildMultiTaskManualCloseList(params).then((response) => {
        if (response.data) {
          let manindex = 1;
          let data = response.data.map((item) => {

            item.index = manindex;
            manindex = manindex + 1;

            if (item.subTaskInfoId === ChildId) {
              item.isSelected = 1;

              return item;
            } else {
              return item;
            }
          });
          let isSelectAll = 0;
          if (data) {
            isSelectAll = !!!data.find(d => d.isSelected === 0);
            isSelectAll = isSelectAll ? 1 : 0;
          }
          setselectall(isSelectAll);
          if (Status !== 3) {
            setMultiCloseDatas(data);

          }
          else {
            setMultiCloseDatas(data.filter(x => x.subTaskInfoId === ChildId));

          }
          hideLoader();
        }
        hideLoader();

      }).catch(() => { hideLoader(); });
    }

  };

  const OpenProtoFitSamplepopup = async (TNAId, TNAProtoFitId, index, subIndex, childIndex, ChildTaskName, ParentTask, Styleid, StatusId, arrDependency, intDependency) => {
    //check all depandent tasks closed
    setTNAId(TNAId);
    setTNAProtoFitId(TNAProtoFitId);
    setChildTask(ChildTaskName);
    setParentTask(ParentTask);
    setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    setStyleId(Styleid);
    setTaskStatus(StatusId);
    setSampleSubModalpopup(true);

    //}


  }
  const handleprotoSubApp = () => {
    setSampleSubModalpopup(false);
    seteditProtoFitSmsSubmission(false);
    setdeleteProtoFitSmsSubmission(false);
  }

  const ProtoFitSMSSubmissionCallback = (value, Operation) => {


    if (value === true) {
      setSampleSubModalpopup(false);
      setdeleteProtoFitSmsSubmission(false);
      seteditProtoFitSmsSubmission(false);

      ReloadSubmission();
      searchTrim();
      // handleToggle(currentExpand.currindex, currentExpand.currTrimId, currentExpand.currColorGroupID);
    }
  }

  const handleCloseManualPopup = () => {
    var manualclosedata = { ...Manualclosedata };
    manualclosedata.ManualCloseComment = '';
    manualclosedata.ManualCloseDate = '';
    setManualclosedata(manualclosedata)
    setshowmaunalclose(false)
  }
  const handleChangeIsManualCloseComments = (event, feild) => {
    var manualclosedata = { ...Manualclosedata };
    let input = '';

    setComment(false);
    if (feild === "Comment") {
      if (event.target.value.trim() !== '') {
        input = event.target.value;
      }
      manualclosedata.ManualCloseComment = input;
    }
    else {
      setCommentManualclose(false)
      manualclosedata.ManualCloseDate = event;
      // ManualCloseData.ManualCloseDate = event;
    }
    setManualclosedata(manualclosedata);
  }
  const CustomInputman = (props) => {

    return (
      <input
        className="form-control DatePickerCalenderHoliday min_width_Date"
        onClick={props.onClick}
        value={props.value}
        type="text"
        readOnly={true}
        menuPosition="fixed"
        placeholder="DD/MM/YYYY"
        style={{ border: getCommentManualclose && props.value === '' ? '1px solid red' : '' }}
      />
    )
  }
  const SelectAll = (event) => {
    let IsSelected = 0;

    if (event.target.checked === true) {
      IsSelected = 1;
      MultiCloseDatas.forEach(element => {
        element.isSelected = 1;
      })
    }
    else {
      IsSelected = 0;
      MultiCloseDatas.forEach(element => {
        element.isSelected = 0;
      })
    }
    setselectall(IsSelected);
  }

  const OnSelectRecords = (event, index) => {
    const value = [...MultiCloseDatas];

    if (event.target.checked === true) {
      value[index].isSelected = 1;
    }
    else {
      value[index].isSelected = 0;

    }

    let isSelectAll = 0;
    if (value) {
      isSelectAll = !!!value.find(d => d.isSelected === 0);
      isSelectAll = isSelectAll ? 1 : 0;
    }
    setselectall(isSelectAll);

    setMultiCloseDatas(value);
  }
  const SaveChildManulaCloseComments = (TaskName) => {



    if (Manualclosedata.ManualCloseComment === '' || Manualclosedata.ManualCloseComment === undefined || Manualclosedata.ManualCloseDate === '') {
      setComment(true);
      setCommentManualclose(true)
      Nodify('Warning!', 'warning', "Please fill the mandatory * fields");
    }
    // else if (Manualclosedata.ManualCloseDate === '') {
    //   setCommentManualclose(true);
    //   Nodify('Warning!', 'warning', "Please enter Close Date");
    // }
    else {

      let ChildStatus = 0;
      if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
        ChildStatus = 1;
      }
      else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
        TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
        TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
        ChildStatus = 2;

      } else if (TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER ||
        TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT ||
        TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER ||
        TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {

        ChildStatus = 3;

      } else if (TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE ||
        TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || TaskName.toLowerCase() === StaticTask.SMS_APPROVAL || TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH
        || TaskName.toLowerCase() === StaticTask.SIZE_SET_SUBMISSION || TaskName.toLowerCase() === StaticTask.SIZE_SET_APPROVAL
        || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_SUBMISSION || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL
        || TaskName.toLowerCase() === StaticTask.SHIPMENT_SUBMISSION || TaskName.toLowerCase() === StaticTask.SHIPMENT_APPROVAL
        || TaskName.toLowerCase() === StaticTask.PHOTO_SUBMISSION || TaskName.toLowerCase() === StaticTask.PHOTO_APPROVAL) {
        ChildStatus = 4;
      }
      else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL ||
        TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
        ChildStatus = 5;
      }
      else if (TaskName.toLowerCase() === StaticTask.STRIKEOFF_TEST) {
        ChildStatus = 8;
      }
      else if (TaskName.toLowerCase() === StaticTask.TRIMS_TEST) {
        ChildStatus = 9;
      }
      //let ChildStatus = 5;


      let strValue = "";
      let selected = 0;
      for (let data of MultiCloseDatas) {
        if (strValue === "") {
          if (data.isSelected === 1) {
            strValue = data.subTaskInfoId.toString();
            selected = selected + 1;
          }
        } else {
          if (data.isSelected === 1) {
            strValue = strValue + "," + data.subTaskInfoId;
          }
        }
      }
      if (selected === 0) {
        Nodify('Warning!', 'warning', "Select atleast one Task");
      }
      else {
        var ManualCloseData = { ...Manualclosedata };
        ManualCloseData.ManualCloseDate = new Date(Manualclosedata.ManualCloseDate);
        ManualCloseData.ManualCloseDate.setMinutes(ManualCloseData.ManualCloseDate.getMinutes() + 370);
        // ManualCloseData.ActualEndDate = new Date(values[index].SubTaskList[subIndex].subFieldList[childIndex].ManualCloseDate);
        // ManualCloseData.ActualEndDate.setMinutes(ManualCloseData.ManualCloseDate.getMinutes() + 370);
        // .setDate(values[index].SubTaskList[subIndex].subFieldList[childIndex].ManualCloseDate.getDate() + 1);
        ManualCloseData.ManualCloseComment = Manualclosedata.ManualCloseComment;
        ManualCloseData.IdList = strValue;
        ManualCloseData.TaskStatus = ChildStatus;
        ManualCloseData.CreatedBy = currentUser.employeeinformationID;
        TnaService.UpdateTaskManualCloseDetails(ManualCloseData).then(res => {
          if (res.data.outputResult === "2") {
            Nodify('Success!', 'success', 'Task Manually Closed Successfully');
            setshowmaunalclose(false);

            ReloadSubmission();
            searchTrim();
            // handleToggle(currentExpand.currindex, currentExpand.currTrimId, currentExpand.currColorGroupID);

          }
          else if (res.data.outputResult === "0") {
            Nodify("Error!", "Error Occured!");
          }
        });
      }

    }
  }


  const ReloadSubmission = () => {

    let StartDate = null;
    let EndDate = null;
    if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
      StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
    }
    if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
      EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
    }
    let params = {
      BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId,
      Operation: 2,
      Samplecolor: SearchReqValues.Samplecolor,
      StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
      SupplierId: SearchReqValues.SupplierId,
      StartDate: StartDate, EndDate: EndDate,
      StyleName: SearchReqValues.StyleName,
      StyleNo: SearchReqValues.StyleNo,
      IdPoNo: SearchReqValues.IdPoNo,
      IsProduction: IsProduction
    };

    let approvalparams = {
      BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId,
      Operation: 3,
      Samplecolor: SearchReqValues.Samplecolor,
      StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
      SupplierId: SearchReqValues.SupplierId,
      StartDate: StartDate, EndDate: EndDate,
      StyleName: SearchReqValues.StyleName,
      StyleNo: SearchReqValues.StyleNo,
      IdPoNo: SearchReqValues.IdPoNo,
      IsProduction: IsProduction
    };
    dispatch(getReloadOrderSamplesSubmissionViewList(approvalparams));
    dispatch(getOrderSamplesRequestViewList(params));


  }

  const searchTrim = () => {
    SearchReqValues.stateDate[0].isShow = false;
    setSearchReqValues(SearchReqValues);

    let StartDate = null;
    let EndDate = null;
    if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
      StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
    }
    if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
      EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
    }

    let params = {
      BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId,
      Operation: 2,
      Samplecolor: SearchReqValues.Samplecolor,
      StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
      SupplierId: SearchReqValues.SupplierId,
      StartDate: StartDate, EndDate: EndDate,
      StyleName: SearchReqValues.StyleName,
      StyleNo: SearchReqValues.StyleNo,
      IdPoNo: SearchReqValues.IdPoNo,
      IsProduction: IsProduction
    };
    dispatch(getOrderSamplesRequestViewList(params));

    // let params = {
    //     BuyerId: TrimViewDetailParams.BuyerId, BrandId: TrimViewDetailParams.BrandId, SeasonId: TrimViewDetailParams.SeasonId,
    //     BuyerName: TrimViewDetailParams.BuyerName, BrandName: TrimViewDetailParams.BrandName, SeasonName: TrimViewDetailParams.SeasonName,
    //     PantoneId: 0, Index: 0, SearchText: SearchReqValues.SearchTxt, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, StartDate: StartDate, EndDate: EndDate
    // };
    // dispatch(getGeneralTrimDetailsViewList(params));
    // commonParentExpand();
  }


  return (
    <div className="widget-body">
      <div id="registration-form">
        <form>


          <div className="col-sm-12">
            {/* <div className="row"> */}

              <div className="col-md-2">
                <label htmlFor="Status">
                  Style Name
                </label>
                <span className="input-icon icon-right">
                  <input
                    type="text"
                    className="form-control StyleList"
                    title={'Style Name'}
                    placeholder={'Style Name'}
                    value={SearchReqValues.StyleName}
                    onChange={e => handleSearchChange(e, 'StyleName')}
                  />
                </span>
              </div>

              <div className="col-md-2">
                <label htmlFor="Status">
                  Style No
                </label>
                <span className="input-icon icon-right">
                  <input
                    type="text"
                    className="form-control StyleList"
                    title={'Style No'}
                    placeholder={'Style No'}
                    value={SearchReqValues.StyleNo}
                    onChange={e => handleSearchChange(e, 'StyleNo')}
                  />
                </span>
              </div>

              <div className="col-md-2">
                <label htmlFor="Status">
                  Sample Color
                </label>
                <span className="input-icon icon-right">
                  <input
                    type="text"
                    className="form-control StyleList"
                    title={'Samplecolor'}
                    placeholder={'Samplecolor'}
                    value={SearchReqValues.Samplecolor}
                    onChange={e => handleSearchChange(e, 'Samplecolor')}
                  />
                </span>

              </div>



              <div className="col-md-2">
                <label htmlFor="Status">
                  ID/PO NO
                </label>
                <span className="input-icon icon-right">
                  <input
                    type="text"
                    className="form-control StyleList"
                    title={'PO/ID No'}
                    placeholder={'ID/PO NO'}
                    value={SearchReqValues.IdPoNo}
                    onChange={e => handleSearchChange(e, 'IdPoNo')}
                  />
                </span>

              </div>
            {/* </div> */}
            {/* <div className="row"> */}
              <div className="col-md-2">
                <label htmlFor="Status">
                  Sample Name
                </label>
                <span className="input-icon icon-right">
                  <input
                    type="text"
                    className="form-control StyleList"
                    title={'SampleName'}
                    placeholder={'SampleName'}
                    value={SearchReqValues.SampleName}
                    onChange={e => handleSearchChange(e, 'SampleName')}
                  />
                </span>

              </div>

              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="SupplierID">
                    Supplier
                    {/* <span className="text-danger">*</span> */}
                  </label>
                  <span className="input-icon icon-right">
                    <Reactselect className="basic-single" name="SupplierId"
                      id={
                        "SupplierId"
                      }
                      isLoading={false}
                      isClearable={true}
                      isSearchable={true}
                      onChange={event => handleSearchChange(event, 'SupplierId')}
                      value={getSupplierList.filter(function (option) {
                        return option.value === SearchReqValues.SupplierId;

                      })}
                      options={getSupplierList}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                    ></Reactselect>

                  </span>
                </div>
              </div>
              </div>
              <div className="col-sm-12">
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="Status">
                    Submit Status
                  </label>
                  <span className="input-icon icon-right">
                    <Reactselect className="basic-single" name="Status"
                      id={
                        "Status"
                      }
                      isLoading={false}
                      isClearable={true}
                      isSearchable={true}
                      onChange={event => handleSearchChange(event, 'Status')}
                      value={GLDRequestStatusList.filter(function (option) {
                        return option.value === SearchReqValues.StatusId;
                      })}
                      options={GLDRequestStatusList}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                    ></Reactselect>
                  </span>
                </div>
              </div>

            {/* </div> */}
            <div className="row">
              <div className="col-md-2">

                <div className="form-group">
                <label >Date Range <span className="rptDateRangeSpan" title="Clicking on the text box cause datepicker to appear and disappear">(Clicking on the text box...)</span> </label>
                  <span className="input-icon icon-right">
                    <input
                      value={(SearchReqValues.stateDate[0].startDate === null ? '' : moment(SearchReqValues.stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') + ((SearchReqValues.stateDate[0].endDate === null || !moment(SearchReqValues.stateDate[0].endDate).isValid()) ? '' : moment(SearchReqValues.stateDate[0].endDate).format('DD/MM/YYYY'))}
                      onClick={event => handleDateSelect(event, !SearchReqValues.stateDate[0].isShow)}
                      //onBlur={event => handleDateBlur()}
                      type="text"
                      className={'form-control OrdProtofit'}
                      placeholder="Select Date"
                    />
                    <i className="fa fa-times OrdProtofit" onClick={clearDateSelect} ></i>
                  </span>
                </div>


                {SearchReqValues.stateDate[0].isShow &&
                  <DateRangePicker
                    //onChange={item => setStateDate([item.selection])}
                    onChange={item => handlePopupDateselection(item)}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={SearchReqValues.stateDate}
                    showDateDisplay={false}
                    direction="vertical"
                    className={'TNAReportDatePicker'}

                  />

                }

              </div>
              <div className="col-md-2" style={{ paddingTop: '29px' }}>

                <button type="button" className="btn btn-success" title="Search" onClick={event => searchSamples()}
                >
                  <i className="fa fa-search"></i>
                </button>
                &nbsp;&nbsp;
                <button type="button" className="btn btn-danger" title="Reset" onClick={resetSamples}
                >
                  <i className="fa fa-close"></i>
                </button> 
              </div>
            </div>
          </div>
          

          <div className="col-md-12" style={{ overflowX: "auto", minHeight: '100px' }}>
            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
              <thead>
                <tr>
                  <th className="fixed-column-header-first-tna col-action-or-other" style={{ width: "15px" }}> #</th>
                  <th className="col-action-or-other">Requested Date</th>
                  <th className="col-action-or-other">Style Name</th>
                  <th className="col-action-or-other">SKU Name</th>
                  <th className="col-action-or-other">Style No</th>
                  <th className="col-action-or-other">ID/PO NO</th>
                  <th className="col-action-or-other">Supplier</th>
                  <th className="col-action-or-other">Sample Name</th>
                  <th className="col-action-or-other">Details (size & qty)</th>
                  <th className="col-action-or-other">Task Holder / Follower</th>
                  <th className="col-action-or-other">Submit Target</th>
                  <th className="col-action-or-other">AWB & Date</th>
                  <th className="col-action-or-other" style={{ display: activeMenu && activeMenu[0].isEdit === 0 &&
                  activeMenu[0].isDelete === 0 ? 'none' : ''}}>Action</th>

                </tr>
              </thead>
              <tbody>
                <Fragment>


                  <>
                    {
                      ordSampleList.length > 0 ?

                        ordSampleList.map((subField, index) => (
                          <tr>
                            <td>
                              {index + 1}
                            </td>
                            <td>
                              {/* {subField.requestedDate} */}
                             {moment(subField.requestedDate).format('DD/MM/YYYY')}
                            </td>
                            <td>
                              {subField.styleName}
                            </td>
                            <td>
                              {subField.sampleColor}
                            </td>
                            <td>
                              {subField.styleNo}
                            </td>
                            <td>
                              {subField.poNo}
                            </td>
                            <td>
                              {subField.supplierName}
                            </td>
                            <td>
                              {subField.sampleRequestName}
                            </td>
                            <td>
                              {subField.sizeQty}
                            </td>
                            <td>
                              {subField.follower}
                            </td>
                            <td>
                              {moment(subField.submitTarget).format('DD/MM/YYYY')}

                            </td>
                            <td>{
                              subField.awbDate === null ? "-" : subField.awbDate
                                ||
                                subField.awbDate === '' ? "-" : subField.awbDate
                            }

                            </td>
                            <td style={{ display: activeMenu && activeMenu[0].isEdit === 1 ? '' : 'none' }}>
                              {(subField.isShowSubmitButton === 1 || subField.isShowSubmitButton === 2) && subField.statusID !== 3 ?
                                <Fragment>&nbsp;<button type="button" className={subField.manualCloseComment === '' || subField.manualCloseComment === null || subField.manualCloseComment === undefined ? "btn btn-xs btn-darkorange" : "btn btn-xs btn-success"} title="Manual Close"
                                  onClick={() => handleChildManualclose(subField.taskName, subField.taskTypeId, subField.taskId, subField.childId, subField.statusID, subField.manualCloseComment)}
                                >
                                  <i className="fa fa-user"></i>&nbsp;
                                  <i className="fa fa-close"></i>
                                </button>
                                </Fragment>
                                :
                                <button type="button" className="btn btn-xs btn-success" title='Submitted' disabled>
                                  <i className="fa fa-check"></i>
                                </button>
                              }
                              &nbsp;

                              {
                                // TaskCloseCompleteInfo !== undefined && TaskCloseCompleteInfo.isAdd !== 0 &&
                                (subField.taskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || subField.taskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || subField.taskName.toLowerCase() === StaticTask.SIZE_SET_SUBMISSION || subField.taskName.toLowerCase() === StaticTask.PRE_PRODUCTION_SUBMISSION || subField.taskName.toLowerCase() === StaticTask.SHIPMENT_SUBMISSION || subField.taskName.toLowerCase() === StaticTask.PHOTO_SUBMISSION) && subField.isShowSubmitButton === 1 && subField.statusID !== 3 ?
                                  <button type="button" className="btn btn-xs btn-warning" title="Submit Form"
                                    onClick={() => OpenProtoFitSamplepopup(subField.tnaId, subField.childId, index,
                                      index, index, subField.sampleRequestName, subField.taskName, subField.styleId, subField.statusID, subField.arrDependency, subField.intDependency)}
                                  >
                                    <i className="fa fa-send"></i>
                                  </button> :
                                  (subField.taskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL || subField.taskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL || subField.taskName.toLowerCase() === StaticTask.SIZE_SET_APPROVAL || subField.taskName.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL || subField.taskName.toLowerCase() === StaticTask.SHIPMENT_APPROVAL || subField.taskName.toLowerCase() === StaticTask.PHOTO_APPROVAL) && subField.isShowSubmitButton === 0 ?
                                    <button type="button" className="btn btn-xs btn-success" title='Already Approved' disabled>
                                      <i className="fa fa-check"></i>
                                    </button>
                                    : ''

                              }
                            </td>

                          </tr>
                        ))
                        :
                        <tr><td colSpan="13" className='norecordfound'><span>No Records Found</span></td></tr>
                    }
                  </>


                </Fragment>
              </tbody>
            </table>

          </div>
        </form>

      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>

          </div>
        </div>
      </div>

      {loader}


      {
        getSampleSubModalpopup === true ?

          <Modal dialogClassName="commonwidth" show={getSampleSubModalpopup === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handleprotoSubApp(false)}>
            <Modal.Header closeButton>
              <Modal.Title>
                {
                  TaskStatus === 3 && editProtoFitSmsSubmission === false ?
                    "View " : (editProtoFitSmsSubmission === true && getexistsubmission === false) ? 'Update ' : ''
                }
                {ParentTask}
                {
                  TaskStatus === 3 ?
                    <>
                      <span title='Edit Form' onClick={() => seteditProtoFitSmsSubmission(true)}
                        style={{
                          pointerEvents: protofitsmsDisable === true ? "none" : '', opacity: protofitsmsDisable === true ? '0.6' : '',
                          marginLeft: ParentTask === "Shipment Sample Submission" ? '620px' :
                            ParentTask === "Size Set Sample Submission" ? '620px' :
                              ParentTask === "Pre Production Sample Submission" ? '570px' : '800px'
                        }}
                        className="btn btn-info btn-xs edit">
                        <i className="fa fa-edit"></i>
                      </span>
                      &nbsp;
                      <span title='Delete Form'
                        style={{ pointerEvents: protofitsmsDisable === true ? "none" : '', opacity: protofitsmsDisable === true ? '0.6' : '' }}
                        className="btn btn-danger btn-xs delete" onClick={() => setdeleteProtoFitSmsSubmission(true)}>
                        <i className="fa fa-trash-o"></i>
                      </span>
                    </> : ''
                }
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ProtoFitSMSSubmissionOrder props={props}
                ProtoFitSMSSubmissionCallback={ProtoFitSMSSubmissionCallback}
                TNAId={TNAId}
                TNAProtoFitId={TNAProtoFitId}
                ChildTask={ChildTask}
                ParentTask={ParentTask}
                StyleId={StyleId}
                TaskStatus={TaskStatus}
                editProtoFitSmsSubmission={editProtoFitSmsSubmission}
                delteProtoFitSmsSubmission={delteProtoFitSmsSubmission}
              ></ProtoFitSMSSubmissionOrder>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>

          </Modal> : ''
      }




      {
        showmaunalclose === true ?
          <Modal show={showmaunalclose === true} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseManualPopup()}>
            <Modal.Header closeButton>
              <Modal.Title>
                Manual Closes  : {manualtask}

              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                <thead>
                  <tr>
                    <th>Comments <span className="text-danger">*</span></th>
                    <th>Close Date <span className="text-danger">*</span></th>
                  </tr>
                </thead>
                <tbody>

                  <tr>
                    <td style={{ width: "600px" }} >
                      <textarea
                        rows="3"
                        placeholder="Enter Comments here..."
                        autoFocus onFocus={""} autoComplete="off"
                        maxLength="500" name="EnterRemarks"
                        id={"EnterRemarks"}
                        value={Manualclosedata.ManualCloseComment}
                        onChange={event => handleChangeIsManualCloseComments(event, "Comment")}
                        style={{ width: "100%", border: getComment && Manualclosedata.ManualCloseComment === '' ? '1px solid red' : '' }}
                      >

                      </textarea>
                    </td>
                    <td>
                      <DatePicker className="form-control" name="HolidayDate"
                        id={
                          "HolidayDate"
                        }
                        selected={
                          Manualclosedata.ManualCloseDate
                        }
                        onChange={event => handleChangeIsManualCloseComments(event, "CommentDate")}
                        dateFormat="dd/MM/yyyy"
                        peekNextMonth
                        showMonthDropdown
                        // showYearDropdown
                        dropdownMode="scroll"
                        autoComplete="off"
                        minDate={new Date()}
                        maxDate={new Date()}
                        style={{ border: getCommentManualclose && Manualclosedata.ManualCloseDate === '' ? '1px solid red' : '' }}
                        customInput={<CustomInputman />}
                      // isClearable={inputField.HolidayDate === '' ? false : true}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              {
                MultiCloseDatas.length !== 0 ?

                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>StyleName</th>
                        <th>StyleNo</th>
                        <th>PO/ID No</th>
                        <th>SKU Name</th>
                        <th>Task Holder / Follower</th>
                        <th>
                          <label style={{ marginTop: '8px' }}>
                            <input type="checkbox" name="IsSelected"

                              onChange={event => (SelectAll(event))}
                              checked={selectall === 1 ? true : false}
                              className="colored-blue clearOpacity" />
                            <span className="text"></span>
                          </label>&nbsp;&nbsp;&nbsp;



                        </th>

                      </tr>
                    </thead>
                    <tbody>
                      {

                        MultiCloseDatas.map((item, index) => {
                          return (
                            <>
                              <tr>
                                <td>{item.styleName}</td>
                                <td>{item.styleNo}</td>
                                <td>{item.idPoNo}</td>
                                <td>{item.details}</td>
                                <td>{item.taskOwnerNameList}</td>
                                <td className="Actionbutton fixed-column">
                                  <div style={{ width: "80px" }}>
                                    {


                                      // (item.dependencyStartstop === 1 || (!inputField.SelectedDependency) || getSelectedDependencyMap(inputField.SelectedDependency) === true || item.isSelected === 1
                                      //  ) &&
                                      //  item.IsDepandency === 0 &&
                                      <label style={{ marginTop: '8px' }}>
                                        <input type="checkbox" name="IsSelected"
                                          onChange={event => (OnSelectRecords(event, index))}
                                          checked={item.isSelected === 1 ? true : false}
                                          className="colored-blue clearOpacity" />
                                        <span className="text"></span>
                                      </label>
                                    }&nbsp;&nbsp;&nbsp;

                                    {/* {
                                      // StartStopInfo && StartStopInfo.isAdd !== 0 &&
                                      // inputField.SelectedDependency && getSelectedDependencyMap(inputField.SelectedDependency) === false && childTask.StatusID !== 3 &&
                                      item.Depandency !== '' &&
                                      <button type="button" className={(item.dependencyStartstop === 1 || item.isSelected === 1) ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Dependency)"
                                      // onClick={() => OpenDependentStartPopup(index, subIndex, childIndex, childTask.intDependency, props.location.state.params.tnaid, inputField.TaskID, childTask.ChildId, inputField.IsSingleTask)}
                                      >
                                        <i className={item.dependencyStartstop === 1 || item.isSelected === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>

                                      </button>
                                    } */}
                                  </div>

                                </td>
                              </tr>
                            </>

                          )
                        })
                      }

                    </tbody>
                  </table>
                  :
                  <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                    <TableHeader headers={headers} />
                    <tbody>
                      <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                    </tbody>
                  </table>


              }
            </Modal.Body>
            <Modal.Footer>
              {
                //  subField.StatusID !== 3 &&
                <Button variant="success" onClick={() => SaveChildManulaCloseComments(manualtask)}>
                  Save
                </Button>


              }


            </Modal.Footer>
          </Modal> : ''
      }
    </div >
  )
}

export default ProtoFitViewRequestTab