import {
    PANTONETYPE_LOADING,
    RETRIEVE_PANTONETYPE,
    DELETE_PANTONETYPE,
} from "./types";
import PantoneTypeService from "../services/Master/PantoneService";

const pantoneTypeLoading = (isStatus) => ({
    type: PANTONETYPE_LOADING,
    payload: isStatus,
});

export const deletePantoneType = (pantoneTypeID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_PANTONETYPE,
            payload: pantoneTypeID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrievePantoneType = (pantoneTypeID) => async (dispatch) => {
    try {
        dispatch(pantoneTypeLoading(true));
        const res = await PantoneTypeService.getPantoneTypelist(pantoneTypeID);
        dispatch({
            type: RETRIEVE_PANTONETYPE,
            payload: res.data,
        });
        dispatch(pantoneTypeLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(pantoneTypeLoading(false));
    }
};