import {
    ORDER_STRIKE_OFF_LOADING,
    ORDER_STRIKE_OFF_RELOADING,
    RETRIEVE_ORDER_STRIKE_OFF,
    DELETE_ORDER_STRIKE_OFF,
    RETRIEVE_ORDER_STRIKE_OFF_DETAIL_VIEW_LIST,
    CLEAR_ORDER_STRIKE_OFF_DETAIL_VIEW_SUB_ROW,
    RETRIEVE_ORDER_STRIKEOFF_SUBMISSION_LIST,
    CLEAR_ORDER_STRIKEOFF_SUBMISSION_SUB_ROW,
    RETRIEVE_ORDER_STRIKEOFF_APPROVAL_LIST,
    CLEAR_ORDER_STRIKEOFF_APPROVAL_SUB_ROW,
    RETRIEVE_ORDER_STRIKEOFF_APPROVAL_VIEW_LIST,
    CLEAR_ORDER_STRIKEOFF_APPROVAL_VIEW_SUB_ROW
} from "./types";

import OrderService from "../services/Order/OrderService";

const orderStrikeOffLoading = (isStatus) => ({
    type: ORDER_STRIKE_OFF_LOADING,
    payload: isStatus,
});

const orderStrikeOffReLoading = (isStatus) => ({
    type: ORDER_STRIKE_OFF_RELOADING,
    payload: isStatus,
});

export const deleteorderStrikeOff = (ordStrikeOffId) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_ORDER_STRIKE_OFF,
            payload: ordStrikeOffId,
        });

    } catch (err) {
        console.log(err);
    }
};
export const GetOrderStrikeOffApprovalViewList = (params) => async (dispatch) => {
    try {
        dispatch(orderStrikeOffLoading(true));
        const res = await OrderService.GetOrderStrikeOffSubmissionApprovalGridList(params);
        dispatch({
            type: RETRIEVE_ORDER_STRIKEOFF_APPROVAL_VIEW_LIST,
            payload: { data: res.data, params: params },
        });
        dispatch(orderStrikeOffLoading(false));

    } catch (err) {
        console.log(err);
        dispatch(orderStrikeOffLoading(false));
    }
};

export const ReloadOrderStrikeOffApprovalViewList = (params) => async (dispatch) => {
    try {
        dispatch(orderStrikeOffReLoading(true));
        const res = await OrderService.GetOrderStrikeOffSubmissionApprovalGridList(params);
        dispatch({
            type: RETRIEVE_ORDER_STRIKEOFF_APPROVAL_VIEW_LIST,
            payload: { data: res.data, params: params },
        });
        dispatch(orderStrikeOffReLoading(false));

    } catch (err) {
        console.log(err);
        dispatch(orderStrikeOffReLoading(false));
    }
};
export const clearStrikeOffApprovalViewSubRow = (index) => async (dispatch) => {
    dispatch(orderStrikeOffLoading(true));
    dispatch({
        type: CLEAR_ORDER_STRIKEOFF_APPROVAL_VIEW_SUB_ROW,
        payload: index,
    });
    dispatch(orderStrikeOffLoading(false));

};
export const clearStrikeOffSubmissionSubRow = (index) => async (dispatch) => {
    dispatch(orderStrikeOffLoading(true));
    dispatch({
        type: CLEAR_ORDER_STRIKEOFF_SUBMISSION_SUB_ROW,
        payload: index,
    });
    dispatch(orderStrikeOffLoading(false));

};

export const clearStrikeOffApprovalSubRow = (index) => async (dispatch) => {
    dispatch(orderStrikeOffLoading(true));
    dispatch({
        type: CLEAR_ORDER_STRIKEOFF_APPROVAL_SUB_ROW,
        payload: index,
    });
    dispatch(orderStrikeOffLoading(false));

};
export const GetOrderStrikeOffApprovalList = (params) => async (dispatch) => {
    try {
        dispatch(orderStrikeOffLoading(true));
        const res = await OrderService.GetOrderStrikeOffSubmissionApprovalGridList(params);
        dispatch({
            type: RETRIEVE_ORDER_STRIKEOFF_APPROVAL_LIST,
            payload: { data: res.data, params: params },
        });
        dispatch(orderStrikeOffLoading(false));

    } catch (err) {
        console.log(err);
        dispatch(orderStrikeOffLoading(false));
    }
};

export const ReloadOrderStrikeOffApprovalList = (params) => async (dispatch) => {
    try {
        dispatch(orderStrikeOffReLoading(true));
        const res = await OrderService.GetOrderStrikeOffSubmissionApprovalGridList(params);
        dispatch({
            type: RETRIEVE_ORDER_STRIKEOFF_APPROVAL_LIST,
            payload: { data: res.data, params: params },
        });
        dispatch(orderStrikeOffReLoading(false));

    } catch (err) {
        console.log(err);
        dispatch(orderStrikeOffReLoading(false));
    }
};
export const retrieveorderStrikeOff = (IsProduction) => async (dispatch) => {

    try {
        dispatch(orderStrikeOffLoading(true));
        const res = await OrderService.GetOrderStrikeOffList(IsProduction);
        dispatch({
            type: RETRIEVE_ORDER_STRIKE_OFF,
            payload: res.data,
        });
        dispatch(orderStrikeOffLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(orderStrikeOffLoading(false));
    }
};
//strikeoff details
export const getOrderStrikeOffDetailsViewList = (params) => async (dispatch) => {
    try {
        dispatch(orderStrikeOffLoading(true));
        const res = await OrderService.GetOrderStrikeOffDetailsViewList(params);
        dispatch({
            type: RETRIEVE_ORDER_STRIKE_OFF_DETAIL_VIEW_LIST,
            payload: { data: res.data, params: params },
        });
        dispatch(orderStrikeOffLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(orderStrikeOffLoading(false));
    }
};
//Request
export const GetOrderStrikeOffSubmissionApprovalGridList = (params) => async (dispatch) => {
    try {
        dispatch(orderStrikeOffLoading(true));
        const res = await OrderService.GetOrderStrikeOffSubmissionApprovalGridList(params);
        dispatch({
            type: RETRIEVE_ORDER_STRIKEOFF_SUBMISSION_LIST,
            payload: { data: res.data, params: params },
        });
        dispatch(orderStrikeOffLoading(false));

    } catch (err) {
        console.log(err);
        dispatch(orderStrikeOffLoading(false));
    }
};
export const clearStrikeOffDetailsViewSubRow = (index) => async (dispatch) => {
    dispatch(orderStrikeOffLoading(true));
    dispatch({
        type: CLEAR_ORDER_STRIKE_OFF_DETAIL_VIEW_SUB_ROW,
        payload: index,
    });
    dispatch(orderStrikeOffLoading(false));

};




