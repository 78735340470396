import React, { useState, useEffect, useRef, Fragment } from "react";
import { useSelector } from "react-redux";
import Nodify from "../../Common/ReactNotification";
import validator from 'validator'
import ReactNotification from 'react-notifications-component';
import CsrService from "../../../services/Master/CsrService";


const CSRDropdownAdd = (props) => {


  const [submitted, setSubmitted] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [GetItem, SetItem] = useState([{ Id: 0, Name: "", }]);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [ExistingList, setExistingList] = useState([]);
  const [ButtonName, setButtonName] = useState("Save");
  const [HeaderName, setHeaderName] = useState("Add");

  const handleInputChange = (index, event) => {

    const values = [...GetItem];
    let inputText = '';
    if (event.target.value.trim() !== '') {
      inputText = event.target.value;
    }
    values[index].Name = inputText;
    SetItem(values);
  };

  //Enter Error msg
  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        //SaveBuyerInfo(event);
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [])
  const handleAddFields = (index) => {
    const values = [...GetItem];
    if (values[index].Name.trim() !== '') {
      values.push({ Id: 0, Name: "", });
      SetItem(values);
      setSubmitted(false);
    }
    else {
      Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
      setSubmitted(true);
    }
  };

  const handleRemoveFields = index => {
    const values = [...GetItem];
    values.splice(index, 1);
    SetItem(values);
  };

  useEffect(() => {
    CsrService.getAllCSRDropDownDetails().then((response) => {
      if (response.data) {
        setExistingList(response.data);
      }
    });
  }, []);


  const SaveItemDetails = (e) => {


    e.preventDefault();
    if (GetItem.length === 1 && GetItem[0].Name === '') {
      setButtonLoader(false);
      setSubmitted(true);
      ValidationPopup("Please fill atleast one Item Name.");
    }
    else {
      for (var i = 0; i < (GetItem.length); i++) {
        if (GetItem[i].Name.trim() === '') {
          Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
          setSubmitted(true);
          setButtonLoader(false);
          return false;
        }
      }
      let ItemInfo = GetItem;
      let InputItemMaster = { Createdby: currentUser.employeeinformationID, ItemInfo: GetItem };

      CsrService.InsertCSRDropdownDetails(InputItemMaster).then(res => {

        let Func = 'Add';
        if (res.data.outputResult === "1") {
          Func = 'Add';
          PageRedirect(Func);
        } else if (res.data.outputResult === "2") {
          Func = 'Edit';
          PageRedirect(Func);
        } else if (res.data.outputResult === "-2") {
          setButtonLoader(false);
          ValidationPopup("This Item is already exist.");
        }
        else if (res.data.outputResult === "0") {
          setButtonLoader(false);
          ValidationPopup("Error Occured!");
        }
      })
    }


  }


  const CheckDuplicate = (index) => {

    const values = [...GetItem];
    if (ExistingList) {
      for (var i = 0; i < ExistingList.length; i++) {
        if (ExistingList[i].itemName.trim().toLowerCase() === values[index].Name.trim().toLowerCase()) {
          values[index].Name = '';
          SetItem(values);
          Nodify('Warning!', 'warning', "This Item is already exist.");
          return false;
        }
      }
    }

    for (var i = 0; i < (GetItem.length); i++) {
      if (i !== index) {
        if (GetItem[i].Name.trim().toLowerCase() === GetItem[index].Name.trim().toLowerCase()) {
          values[index].Name = "";
          SetItem(values);
          Nodify('Warning!', 'warning', 'This Item is already exist.');
          return false;
        }
      }
    }
  }
  const PageRedirect = (Func) => {
    props.history.push({ pathname: "/CSRDrpDetailsList", state: { message: Func } });
  }

  const ValidationPopup = (Msg) => {
    setButtonLoader(false);
    Nodify('Warning!', 'warning', Msg);
    return false;
  }

  const ResetOperation = (e) => {
    if (e === "Back") {
      props.history.push('/CSRDrpDetailsList');
    } else {
      window.location.reload()
    }
  }








  return (
    <div className="page-body">
      <ReactNotification />
      <div className="row">
        <div className="col-lg-12 col-sm-12 col-xs-12">
          <div className="widget flat radius-bordered">

            <div className="widget-header heading_box_style">
              <h3 className="heading_style_common">Add CSR Dropdown Item</h3>
            </div>
            <div className="widget-body">
              <div id="registration-form">
                <form>
                  {
                    GetItem.map((itm, index) => (
                      <div className="row row_left10">
                        <div className="col-sm-3 divder_style">
                          <div className="form-group">
                            <span className="input-icon icon-right">
                              <input type="text"
                                className="form-control"
                                id={"Name_" + index}
                                name="Name"
                                value={itm.Name}
                                onChange={(event) => handleInputChange(index, event)}
                                placeholder="Enter CSR Dropdown Item Name"
                                maxLength="100"
                                onBlur={() => CheckDuplicate(index)}
                                style={{ border: submitted && !itm.Name ? '1px solid red' : '' }}
                              />
                            </span>

                          </div>

                        </div>
                        <div className="col-sm-3 paddingstatic">

                          {
                            GetItem.length !== 1 && (
                              <div className="col-lg-1_own col-xs-12">
                                <button type="button" className="btn btn-danger"
                                  title="Delete Item"
                                  onClick={() => handleRemoveFields(index)}
                                >
                                  <i className="fa fa-trash-o"></i>
                                </button>
                              </div>
                            )}



                          {
                            GetItem.length === (index + 1) && (
                              <div className="col-lg-1_own col-xs-12">
                                <button type="button" className="btn btn-success" title="Add Item Name"
                                  onClick={() => handleAddFields(index)}
                                >
                                  <i className="fa fa-plus"></i>
                                </button>
                              </div>
                            )}


                        </div>
                      </div>
                    ))

                  }

                  <div className="col-sm-12">
                    <div className="buttons-preview" align="right">
                      <span className="btn btn-primary"
                        onClick={() => ResetOperation("Back")}
                      >
                        <i className="fa fa-arrow-circle-left"></i>
                        &nbsp;Back
                      </span>
                      <span className="btn btn-danger"
                        onClick={() => ResetOperation()}
                      >
                        <i className="fa fa-times"></i>
                        &nbsp;Reset
                      </span>
                      <button type="button" className="btn btn-success"
                        disabled={buttonLoader}
                        onClick={SaveItemDetails}
                      >
                        <i className="fa fa-check right"></i>&nbsp;
                        {ButtonName}

                      </button>
                    </div>
                  </div>

                </form>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default CSRDropdownAdd