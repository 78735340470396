import {
    TASK_LOADING,
    RETRIEVE_TASK,
    ALL_TASK_DROPDOWN_LIST,
    TASK_DROPDOWN_LIST,
    TASK_ROW,
    DELETE_TASK,
    TASK_HOLDER_DROPDOWN_LIST
} from "./types";

import TaskService from "../services/Master/TaskService";
import RouteService from "../services/Route/RouteService";

const taskLoading = (isStatus) => ({
    type: TASK_LOADING,
    payload: isStatus,
});

export const deleteTask = (taskDetailsID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_TASK,
            payload: taskDetailsID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrieveTask = (TaskId) => async (dispatch) => {
    try {
        dispatch(taskLoading(true));
        const res = await TaskService.GetTaskDetailsList(TaskId);
        if (TaskId === 0) {
            dispatch({
                type: RETRIEVE_TASK,
                payload: res.data,
            });
        } else {
            dispatch({
                type: TASK_ROW,
                payload: res.data,
            });
        }

        dispatch(taskLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(taskLoading(false));
    }
};

export const loadTaskList = (TaskType) => async (dispatch) => {
    try {
        const res = await RouteService.LoadTaskList(TaskType);

        if (TaskType === 0) {
            dispatch({
                type: ALL_TASK_DROPDOWN_LIST,
                payload: res.data,
            });
        } else {
            dispatch({
                type: TASK_DROPDOWN_LIST,
                payload: res.data,
            });
        }

    } catch (err) {
        console.log(err);
    }
};


export const loadTaskHolderList = () => async (dispatch) => {
    try {
        const res = await TaskService.getLoadTaskHolderList();
        dispatch({
            type: TASK_HOLDER_DROPDOWN_LIST,
            payload: res.data,
        });

    } catch (err) {
        console.log(err);
    }
};
