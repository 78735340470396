import {
    PACKAGEIMAGE_LOADING,
    RETRIEVE_PACKAGEIMAGE,
    DELETE_PACKAGEIMAGE,
} from "../actions/types";

const initialState = {
    isLoadingPackageImage: true,
    PackageImageList: []
}
const PackageImageReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case PACKAGEIMAGE_LOADING:
            return {
                ...state,
                isLoadingPackageImage: payload
            }
        case RETRIEVE_PACKAGEIMAGE:
           
            return {
                ...state,
                PackageImageList: payload.inputPackageimageList1,
            }
        case DELETE_PACKAGEIMAGE:
           
            const results = state.PackageImageList.filter(c => c.packagImageID !== payload.PackageImageID);
            return {
                ...state,
                PackageImageList: results
            }
        default:
            return state
    }

}
export default PackageImageReducer;