import React, { useState, useEffect } from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import axios from 'axios';

const mapStyles = {
    width: '1000px',
    height: '500px',
    position: 'static'
};

const GeoLocation = (props, { parentCallback }) => {
    const [getGeolocation, setGeolocation] = useState({
        Location: '',
        Latitude: props.LatLng.Latitude,
        Longitude: props.LatLng.Longitude
    })
    var locationDetails = {
        ...getGeolocation
    };
    var state = {
        showingInfoWindow: false,
        activeMarker: {},
        selectedPlace: {},
    };

    // const onMarkerClick = async (props, marker, e) => {
    //     locationDetails.Latitude = e.latLng.lat().toString();
    //     locationDetails.Longitude = e.latLng.lng().toString();
    //     setGeolocation(locationDetails);
    // }

    const onMarkerDrag = (map, maps, e) => {
        locationDetails.Latitude = e.latLng.lat().toString();
        locationDetails.Longitude = e.latLng.lng().toString();
        setGeolocation(locationDetails);
    }

    const FixLocation = () => {
        props.parentCallback(locationDetails);
    }

    useEffect(() => {
        var geocoder;
        geocoder = new props.google.maps.Geocoder();
        var latlng = new props.google.maps.LatLng(getGeolocation.Latitude, getGeolocation.Longitude);
        geocoder.geocode(
            { 'latLng': latlng },
            function (results, status) {
                if (status == props.google.maps.GeocoderStatus.OK) {
                    if (results[0]) {
                        locationDetails.Location = results[0].formatted_address;
                        setGeolocation(locationDetails);
                    }
                    else {
                        locationDetails.Location = "Address not found.";
                        setGeolocation(locationDetails);
                    }
                }
                else {
                }
            }
        );
    }, [getGeolocation])
    return (
        <>
            <button onClick={FixLocation} className="btn btn-sm btn-success pinlocation-btn">Pin Location</button>
            <b>Latitude:</b>&nbsp;{getGeolocation.Latitude}<br />
            <b>Longitude:</b>&nbsp;{getGeolocation.Longitude}<br />
            <b>Location:</b>&nbsp;{getGeolocation.Location}<br />

            <Map className="margin-top-20"
                // onClick={onMarkerClick}
                keyboardShortcuts={false}
                google={props.google}
                zoom={16}
                style={mapStyles}
                initialCenter={
                    {
                        lat: getGeolocation.Latitude,
                        lng: getGeolocation.Longitude
                    }
                }
                center={{
                    lat: getGeolocation.Latitude,
                    lng: getGeolocation.Longitude
                }}
            >

                <Marker
                    draggable
                    onDragend={onMarkerDrag}
                    position={{
                        lat: getGeolocation.Latitude,
                        lng: getGeolocation.Longitude
                    }}>
                </Marker>
            </Map>
        </>
    )
}
//export default GeoLocation;

export default GoogleApiWrapper({
    apiKey: 'AIzaSyDHgipImLEiJaEMyXtrq6XP0XJEKYIRbK0'
})(GeoLocation);