import {
    PACKAGETEMPLATE_LOADING,
    RETRIEVE_PACKAGETEMPLATE
} from "../actions/types";

const initialState = {
    isLoadingPackageTemplate: true,
    PackagTemplateList: []
}
const PackageTemplateReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case PACKAGETEMPLATE_LOADING:
            return {
                ...state,
                isLoadingPackageTemplate: payload
            }
        case RETRIEVE_PACKAGETEMPLATE:
           
            return {
                ...state,
                PackagTemplateList: payload,
            }

        default:
            return state
    }

}
export default PackageTemplateReducer;