import React from 'react';
import ReactNotification from 'react-notifications-component';
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import { useEffect, useMemo, useState,Fragment } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { TableHeader, Pagination, Search, PageCount } from "../../../Datatable";
import { retrieveColorDepth } from '../../../../actions/colordepth';
import Nodify from "../../../Common/ReactNotification";
import SearchRetain from "../../../Common/SearchRetain";
import SweetAlertPopup from '../../../Common/SweetAlertPopup';
import { ColorDepth } from "../../../Common/PageActionNumber";
import moment from 'moment';


const ColorDepthList = (props) => {
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    const [getPlaceholder] = useState("Color Depth");
    const [getID, setID] = useState({ showPopupDelete: false, Params: {} });
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    //reducer
    const reducerState = useSelector((state) => state);
    const comments = reducerState.ColorDepthReducer.ColorDepthList.item1;
    let isLoadingTColorDepth = reducerState.ColorDepthReducer.isLoadingColorDepth;
    const dispatch = useDispatch();
    //menu rights
    let activeMenu;
    const menulist = useSelector((state) => state.common.MenuList.item3);
    if (menulist !== undefined) {
        activeMenu = menulist.filter(i => i.menuName === "ColorDepth");
    }
    const headers = [
        { name: "#", field: "", sortable: false },
        { name: "Color Depth", field: "colorDepth", sortable: true },
        { name: "Created / Modified By On Date", field: "createdDate", sortable: true },
        {
            name: "Action", field: "", sortable: false,
            display: activeMenu && activeMenu[0].isEdit === 0 &&
                activeMenu[0].isDelete === 0 ? 'none' : ''
        },
    ];


    const pageRedirect = (ColorDepthParams, redirect, page) => {
        localStorage.setItem('Pagination', JSON.stringify({ CurrentPage: currentPage, Page: page, PageItems: ITEMS_PER_PAGE }));
        if (redirect === 'Add') {
            props.history.push(`/ColorDepthRegister`);
        }
        else if (redirect === 'Edit') {
            props.history.push(`/ColorDepthEdit`, { params: ColorDepthParams });
        } else {
            setID({ showPopupDelete: true, Params: ColorDepthParams });
        }
    };
    const commentsData = useMemo(() => {
        let computedComments = comments;
        if (computedComments != undefined) {
            if (search) {
                computedComments = computedComments.filter(comment => comment.colorDepth.toLowerCase().includes(search.toLowerCase()));
            }
            // if (sorting.field) {
            //     const reversed = sorting.order === "asc" ? 1 : -1;
            //     computedComments = computedComments.sort((a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]));
            // }
            if (sorting.field) {
                const reversed = sorting.order === 'asc' ? 1 : -1;
                computedComments = computedComments.sort((a, b) => {
                  const valueA = a[sorting.field];
                  const valueB = b[sorting.field];
              
                  // Check if both values are numeric
                  const isNumericA = !isNaN(valueA);
                  const isNumericB = !isNaN(valueB);
              
                  // Check if both values are valid date-time strings using moment
                  const isDateTimeA = moment(valueA, moment.ISO_8601, true).isValid();
                  const isDateTimeB = moment(valueB, moment.ISO_8601, true).isValid();
              
                  if (isNumericA && isNumericB) {
                    // If both values are numeric, compare as numbers
                    return reversed * (parseFloat(valueA) - parseFloat(valueB));
                  } else if (isDateTimeA && isDateTimeB) {
                    // If both values are valid date-time strings, compare as dates
                    return reversed * (moment(valueA).valueOf() - moment(valueB).valueOf());
                  } else {
                    // If one or both values are non-numeric and non-valid date-time strings, compare as strings
                    return reversed * valueA.toString().localeCompare(valueB.toString());
                  }
                });
              }
            let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
            if (records.length === 0 && currentPage != 1) {
                setCurrentPage(currentPage - 1);
            }
            setTotalItems(computedComments.length);
            return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
        }
    }, [
        comments,
        currentPage,
        search,
        sorting,
        ITEMS_PER_PAGE
    ]);

    const Delcallback = (value) => {
        dispatch(retrieveColorDepth());
        setID({ showPopupDelete: false, Params: [] });
    }

    useEffect(() => {
        dispatch(retrieveColorDepth());
        const state1 = props.location.state;
        if (state1 !== undefined && state1 != null) {
            let Msg;
            let Type = 'success';
            let title = 'Success!';
            if (state1.message === 'Edit') {
                Msg = 'Color Depth updated successfully.';
            } else if (state1.message === 'Add') {
                Msg = 'Color Depth added successfully.';
            } else {
                Msg = 'Error occured';
                Type = 'warning';
                title = 'Error!';
            }
            window.history.replaceState(null, '')
            Nodify(title, Type, Msg);
        }
        var page = getPlaceholder;
        var setConstraint = SearchRetain(page);
        if (setConstraint !== undefined) {
            setCurrentPage(setConstraint.CurrentPage);
            setITEMS_PER_PAGE(setConstraint.PageItems);
        }
    }, [dispatch]);

    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className="widget-header">
                    <span className="widget-caption">Color Depth List</span>
                </div>
                <div className="widget-body" style={
                    { paddingBottom: '4%' }
                }>
                    <div className="dataTables_wrapper form-inline no-footer">
                        <div className="table-toolbar pull-left">
                            <Search
                                onSearch={
                                    value => {
                                        setSearch(value);
                                        setCurrentPage(1);
                                    }
                                }
                                placeholder={getPlaceholder} props={props} />
                        </div>
                        <div className="table-toolbar pull-right" style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}>
                            &nbsp;
                            <span
                                className="btn btn-primary" style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }} title='Add Color Depth'
                                onClick={() => pageRedirect("", 'Add')}>
                                <i className="fa fa-plus"></i>&nbsp;Add
                            </span>
                        </div>
                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                            <TableHeader headers={headers} onSorting={(field, order) => setSorting({ field, order })} />
                            <tbody>
                                {
                                    commentsData != undefined && commentsData.map((item,i) => (
                                        <Fragment key={i}>
                                        <tr key={item.colorDepthID}>
                                            <td style={{width:'25px'}}>  
                                                { i + 1}
                                            </td>
                                            <td style={{ width: '70%' }}>{item.colorDepth}</td>
                                            <td className='cut_text'>{item.createdByName != null && item.createdByName != undefined ? item.createdByName + " On " + item.createdDate : ""}</td>
                                            <td style={{
                                                width: '10%',
                                                display: activeMenu && activeMenu[0].isEdit === 0 &&
                                                    activeMenu[0].isDelete === 0 ? 'none' : ''
                                            }}>
                                                <span style={{ display: activeMenu && activeMenu[0].isEdit === 1 ? '' : 'none' }} title='Edit Test Item'
                                                    onClick={() => pageRedirect(item, 'Edit', getPlaceholder)}
                                                    className="btn btn-info btn-xs edit">
                                                    <i className="fa fa-edit"></i>
                                                </span>
                                                &nbsp;
                                                <span style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }} title='Delete Test Item'
                                                    onClick={() => pageRedirect(item, '', getPlaceholder)}
                                                    className="btn btn-danger btn-xs delete">
                                                    <i className="fa fa-trash-o"></i>
                                                </span>
                                            </td>
                                        </tr>
                                        </Fragment>
                                    ))
                                }
                                {
                                    totalItems === 0 ?
                                        <tr>
                                            <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
                                        </tr> : ''
                                }
                            </tbody>
                        </table>
                        <PageCount onPageCount={
                            value => {
                                setITEMS_PER_PAGE(value);
                                setCurrentPage(1);
                            }
                        } />
                        <Pagination total={totalItems}
                            itemsPerPage={
                                parseInt(ITEMS_PER_PAGE)
                            }
                            currentPage={currentPage}
                            onPageChange={
                                page => setCurrentPage(page)
                            } />
                    </div>
                </div>
                {
                    getID.showPopupDelete ? <SweetAlertPopup data={
                        getID.Params
                    }
                        deleteCallback={Delcallback}
                        showpopup={true}
                        pageActionId={ColorDepth}
                        Msg={"Color Depth deleted successfully."} /> : null
                }
            </div>
            {loader}
        </>
    )
}
export default ColorDepthList;