import {
    BUYERINFO_LOADING,
    RETRIEVEINFO_BUYER,
    DELETEINFO_BUYER,
} from "./types";

import BuyerService from "../services/Master/BuyerService";

const buyerinfoLoading = (isStatus) => ({
    type: BUYERINFO_LOADING,
    payload: isStatus,
});

export const deleteBuyerinfo = (buyerMapItemID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETEINFO_BUYER,
            payload: buyerMapItemID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrieveBuyerinfo = () => async (dispatch) => {
    try {
        dispatch(buyerinfoLoading(true));
        const res = await BuyerService.GetBuyerInfoList(1, 0, 0);
        dispatch({
            type: RETRIEVEINFO_BUYER,
            payload: res.data,
        });
        dispatch(buyerinfoLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(buyerinfoLoading(false));
    }
};