import {
 TESTITEMS_LOADING,
 RETRIEVE_TESTITEMS,
 DELETE_TESTITEMS
}

 from "../actions/types"

const initialState = {

 isLoadingitems: true,
 testingitemsList: [],
};

const ItemReducer = (state = initialState, action) => {
 const { type, payload } = action;
 switch (type) {
  case TESTITEMS_LOADING:
   return {
    ...state,
    isLoadingitems: payload
   };

  case DELETE_TESTITEMS:

   const results = state.testingitemsList.filter(c => c.itemID !== payload.TestingItemID);
   return {
    ...state,
    testingitemsList: results
   };
  case RETRIEVE_TESTITEMS:
   return { ...state, testingitemsList: payload };
  default:
   return state;
 }

}

export default ItemReducer;