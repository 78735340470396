import {
    WAREHOUSE_LOADING,
    RETRIEVE_WAREHOUSE,
    DELETE_WAREHOUSE,
} from "../actions/types";

const initialState = {
    isLoadingWarehouse: true,
    Warehouselist: [],
    WarehouseEdit: []
}

const WarehouseReducer = (state = initialState, action) => {
    const { type, payload } = action
     switch (type) {
        case WAREHOUSE_LOADING:
            return {
                ...state,
                isLoadingWarehouse: payload
            };
        case RETRIEVE_WAREHOUSE:
            return {
                ...state,
                Warehouselist: payload.warehouselst,
                WarehouseEdit: payload.warehouseEdt

            }

        case DELETE_WAREHOUSE:
            let results = state.Warehouselist.filter(c => c.compBuySupID !== payload.compBuySupID);
            return {
                ...state,
                Warehouselist: results
            }
        default:
            return state

    }

}
export default WarehouseReducer