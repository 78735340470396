import React, { useState, useEffect } from "react";
import Nodify from "../../Common/ReactNotification";
import SearchRetain from "../../Common/SearchRetain";
import ReactNotification from 'react-notifications-component';
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import CityService from "../../../services/Master/CityService";
import StateService from "../../../services/Master/StateService"
import { useSelector } from "react-redux";
import SelectCombobox from 'react-select';

const EditState = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);

    const [getStateInfo, setStateInfo] = useState({});
    const [buttonLoader, setButtonLoader] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [countryList, setcountryList] = useState([]);

    function BindCurrencyValue(props) {
        let StateInfoValue = { Operation: 2, StateID: 0, StateName: '', CountryID: 0, Createdby: currentUser.employeeinformationID, };
        if (props.location.state !== undefined) {
            if (props.location.state.params.length !== 0) {
                if (props.location.state.params.stateID !== 0) {
                    StateInfoValue.Operation = 2;
                    StateInfoValue.StateID = props.location.state.params.stateID;
                    StateInfoValue.StateName = props.location.state.params.stateName;
                    StateInfoValue.CountryID = props.location.state.params.countryID;
                    StateInfoValue.Createdby = currentUser.employeeinformationID;
                    return StateInfoValue;
                }
            }
        }
        else {
            return StateInfoValue;
        }
    }
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),

    }
    useEffect(() => {
        let StateInfo = BindCurrencyValue(props);
        setStateInfo(StateInfo);
       
        let countrylist = []
        CityService.loadCountryList().then((response) => {
            if (response.data) {
                response.data.map((x) => {
                    countrylist.push({
                        label: x.label,
                        value: x.value
                    })
                })
                setcountryList(countrylist);
            }
        }).catch(() => { });
    }, []);


    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/StateList",
            state: {
                message: Func,
            },
        }
        );
    };

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/StateList')
        } else {
            window.location.reload();
        }
    }

    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
    };

    const handleChange = (e, FieldName) => {
       
        let Value = { ...getStateInfo };
        if (FieldName === "StateName") {
            if (e.target.value.trim() !== '') {
                setSubmitted(true);
            }
            else {
                setSubmitted(false);
            }
            Value.StateName = e.target.value;
        }
        if (FieldName === "CountryID") {
            // if (e.value !== 0) {
            //     setSubmitted(true);
            // }
            // else {
            //     setSubmitted(false);
            // }
            // Value.CountryID = e.value;
            if (e !== null) {
                Value.CountryID = e.value;
            }
            else {
                Value.CountryID = 0;

            }
        }
        setStateInfo(Value);
    }

    const SaveState = (e) => {
        e.preventDefault();

       
        if (getStateInfo.StateName.trim() === '' && getStateInfo.CountryID === 0) {
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Please fill the mandatory (*) fields.');
            return false;
        }
        // else if (getStateInfo.StateName.trim() === '') {
        //     setButtonLoader(false);
        //     Nodify('Warning!', 'warning', 'State Name is required');
        //     return false;
        // }
        // else if (getStateInfo.CountryID === 0) {
        //     setButtonLoader(false);
        //     Nodify('Warning!', 'warning', 'Please select Country');
        //     return false;
        // }
        //setButtonLoader(true);
        if (getStateInfo.StateName.trim() && getStateInfo.CountryID) {
            getStateInfo.StateName = getStateInfo.StateName.trim();
            getStateInfo.CountryID = getStateInfo.CountryID;
            StateService.InsertUpdateState(getStateInfo).then(res => {
                var page = "Remove";
                SearchRetain(page);
                let Func = 'Add';
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "-2") {
                    getStateInfo.StateName = '';
                    setStateInfo(getStateInfo);
                    Nodify('Warning!', 'warning', 'This State is already exist.');
                }
                setButtonLoader(false);
            });
        }
        else {
            setSubmitted(true);
        }
    }

    return (
        <div className="page-body">
            <ReactNotification />

            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">
                                Edit State/Country
                            </h3>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <Form>


                                    <div className="row">

                                        <div className="form-group col-sm-4">
                                            <label htmlFor="StateName">
                                                State Name<span className="text-danger">*</span>
                                            </label>
                                            <span className="input-icon icon-right">
                                                <Input
                                                    placeholder={"Enter State Name"}
                                                    id="StateName"
                                                    name="StateName"
                                                    value={getStateInfo.StateName}
                                                    type="text"
                                                    maxLength="50"
                                                    autoComplete="off"
                                                    onChange={event => handleChange(event, "StateName")}
                                                    className='form-control'
                                                    style={{ border: submitted && !getStateInfo.StateName ? '1px solid red' : '' }}
                                                    autoFocus
                                                />
                                            </span>

                                        </div>
                                        <div className="form-group col-sm-4">
                                            <label htmlFor="CountryID">
                                                Country<span className="text-danger">*</span>
                                            </label>
                                            <span className="input-icon icon-right">
                                                <SelectCombobox className="basic-single" name="CountryID" id={"CountryID"}

                                                    isDisabled={false}
                                                    isLoading={false}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    autocomplete='off'
                                                    styles={submitted && getStateInfo.CountryID === 0 ? styles : ''
                                                    }

                                                    onChange={event => handleChange(event, "CountryID")}
                                                    //onBlur={() => CheckDuplicate(index, 'StateID')}
                                                    value={countryList.filter(function (option) {
                                                        return option.value === getStateInfo.CountryID;
                                                    })}
                                                    options={countryList}
                                                />
                                            </span>

                                        </div>
                                    </div>

                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div
                        className="buttons-preview"
                        align="right"
                        style={{ marginTop: "15px" }}
                    >
                        <div className="buttons-preview" align="right">
                            <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                            </span>
                            <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                <i className="fa fa-times"></i>&nbsp;Reset
                            </span>
                            <button
                                type="submit"
                                className="btn btn-success"
                                onClick={SaveState}
                                disabled={buttonLoader}
                            >

                                <i className="fa fa-check right"></i>&nbsp;Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default EditState;