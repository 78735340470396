import React, { useState, useEffect, useMemo,Fragment } from "react";
import { TableHeader, Pagination, Search, PageCount } from "../../../Datatable";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import SweetAlertPopup from '../../../Common/SweetAlertPopup';
import { GroupTypeId } from "../../../Common/PageActionNumber";
import { Redirect } from 'react-router-dom';
//import { useSelector } from "react-redux";
import Nodify from "../../../Common/ReactNotification"
import SearchRetain from "../../../Common/SearchRetain";
import groupTypeService from "../../../../services/Master/GroupTypeService";
import CommonService from '../../../../services/Common/CommonService';
import { useDispatch, useSelector } from "react-redux";
import { retrieveGrouptype } from "../../../../actions/grouptype"

const GroupTypeList = (props) => {
    //const [comments, setComments] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [getID, setID] = useState({ showPopupDelete: false, Params: {} });
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    // const { user: currentUser } = useSelector((state) => state.auth);
    const [getPlaceholder] = useState("Group Type Name");

    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.common.MenuList.item3);
    if (menulist !== undefined) { activeMenu = menulist.filter(i => i.menuName === "GroupType"); }
    // End Enable Menu
    const dispatch = useDispatch();

    //reducer
    const reducerState = useSelector((state) => state);
    const { user: currentUser } = reducerState.auth;
    const comments = reducerState.grouptype.groupTypeList;
    let isLoadingGrouptype = reducerState.grouptype.isLoadingGrouptype;

    const headers = [
        { name: "#", field: "", sortable: false },
        { name: "Group Type Name", field: "groupName", sortable: true },
        {
            name: "Action",
            field: "",
            sortable: false,
            display: activeMenu && activeMenu[0].isEdit === 0 &&
                activeMenu[0].isDelete === 0 ? 'none' : ''
        },
    ];

    const commentsData = useMemo(() => {
        let computedComments = comments;
        if (search) {
            computedComments = computedComments.filter(comment => comment.groupName.toLowerCase().includes(search.toLowerCase()));
        }
        setTotalItems(computedComments.length);

        // Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort((a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]));
        }

        // Current Page slice
        let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
        if (records.length === 0 && currentPage != 1) {
            setCurrentPage(currentPage - 1);
        }
        return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
    }, [
        comments,
        currentPage,
        search,
        sorting,
        ITEMS_PER_PAGE
    ]);

    useEffect(() => {
        // showLoader();
        // setTimeout(() => {
        //     groupTypeService.GetGroupTypeList().then((response) => {
        //         if (response.data) {
        //             setComments(response.data);
        dispatch(retrieveGrouptype());
        const state1 = props.location.state
        if (state1 !== undefined && state1 != null) {
            let Msg;
            let Type = 'success';
            let title = 'Success!';
            if (state1.message === 'Edit') {
                Msg = 'Group Type Name updated successfully.';
            } else if (state1.message === 'Add') {
                Msg = 'Group Type Name added successfully.';
            } else {
                Msg = 'Error occured';
                Type = 'warning';
                title = 'Error!';
            }
            window.history.replaceState(null, '')
            Nodify(title, Type, Msg);
        }
        var page = getPlaceholder;
        var setConstraint = SearchRetain(page);
        if (setConstraint !== undefined) {
            setCurrentPage(setConstraint.CurrentPage);
            setITEMS_PER_PAGE(setConstraint.PageItems);
        }
        //         }
        //     }).catch(() => { }).finally(() => { hideLoader(); });
        // }, 500)
    }, [dispatch]);

    useEffect(() => {
        if (!isLoadingGrouptype) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingGrouptype]);

    const pageRedirect = (dataParams, redirect, page) => {
        localStorage.setItem('Pagination', JSON.stringify({ CurrentPage: currentPage, Page: page, PageItems: ITEMS_PER_PAGE }));
        if (redirect === 'Add') {
            props.history.push('/AddGroupType', { params: [] });
        } else if (redirect === 'Edit') {
            props.history.push(`/EditGroupType`, { params: dataParams });
        } else {
            let Params = {
                Operation: 9,
                Id: dataParams.groupTypeID,
            };
            CommonService.CheckMappedItem(Params).then(res => {
                if (res.data.outputResult === "-2") {
                    let Title = "Warning!";
                    let message = "This Group type name is already mapped.";
                    let type = "warning";
                    Nodify(Title, type, message);
                }
                else {
                    setID({ showPopupDelete: true, Params: dataParams });
                }
            });
        }
    };

    if (!currentUser) {
        return <Redirect to="/login" />;
    }

    const Delcallback = (value) => {
        // if (value === true) {
        //     groupTypeService.GetGroupTypeList().then((response) => {
        //         if (response.data) {
        //             setComments(response.data);

        //         }
        //     });
        // }
        setID({ showPopupDelete: false, Params: [] });
    }

    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className="row">
                    <div className="col-xs-12 col-md-12">
                        <div className="widget">
                            <div className="widget-header ">
                                <span className="widget-caption">Group Type List</span>
                            </div>
                            <div className="widget-body" style={{ paddingBottom: '4%' }}>
                                <div id="simpledatatable_wrapper" className="dataTables_wrapper form-inline no-footer">
                                    <div className="table-toolbar pull-left">
                                        <Search onSearch={
                                            value => {
                                                setSearch(value);
                                                setCurrentPage(1);
                                            }
                                        }
                                            placeholder={getPlaceholder} props={props} />

                                    </div>
                                    <div className="table-toolbar pull-right" style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}>
                                        &nbsp;
                                        <span className="btn btn-primary" onClick={() => pageRedirect('', 'Add', getPlaceholder)} title="Add Group Type">
                                            <i className="fa fa-plus"></i>&nbsp;Add
                                        </span>
                                    </div>

                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                        <TableHeader headers={headers} onSorting={(field, order) => setSorting({ field, order })} />
                                        <tbody>
                                            {
                                                commentsData.map((comment,i) => (
                                                    <Fragment key={i}>
                                                        <tr key={comment.groupTypeID}>
                                                            <td style={{width:'25px'}}>  
                                                                { i + 1}
                                                            </td>
                                                            <td>{comment.groupName}</td>
                                                            <td style={{ display: activeMenu && activeMenu[0].isEdit === 0 && activeMenu[0].isDelete === 0 ? 'none' : '' }}>
                                                                <span style={{ display: activeMenu && activeMenu[0].isEdit === 1 ? '' : 'none' }} title='Edit Group Type' onClick={() => pageRedirect(comment, 'Edit', getPlaceholder)}
                                                                    className="btn btn-info btn-xs edit">
                                                                    <i className="fa fa-edit"></i>
                                                                </span>
                                                                &nbsp;
                                                                <span style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }} title='Delete Group Type' onClick={() => pageRedirect(comment, '', getPlaceholder)}
                                                                    className="btn btn-danger btn-xs delete">
                                                                    <i className="fa fa-trash-o"></i>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </Fragment>
                                                ))
                                            }
                                            {
                                                totalItems === 0 ?
                                                    <tr>
                                                        <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
                                                    </tr> : ''
                                            }
                                        </tbody>
                                    </table>

                                    <PageCount onPageCount={
                                        value => {
                                            setITEMS_PER_PAGE(value);
                                            setCurrentPage(1);
                                        }
                                    } />
                                    <Pagination total={totalItems}
                                        itemsPerPage={
                                            parseInt(ITEMS_PER_PAGE)
                                        }
                                        currentPage={currentPage}
                                        onPageChange={
                                            page => setCurrentPage(page)
                                        } />
                                </div>
                            </div>

                            {
                                getID.showPopupDelete ? <SweetAlertPopup data={
                                    getID.Params
                                }
                                    deleteCallback={Delcallback}
                                    showpopup={true}
                                    pageActionId={GroupTypeId}
                                    Msg={"Group type name deleted successfully."} /> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
            {loader}
        </>
    );
};

export default GroupTypeList;