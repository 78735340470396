

import {
    TESTITEM_LOADING,
    RETRIEVE_TESTITEM,
    DELETE_TESTITEM,
} from "./types";

import tesitemservice from "../services/Master/tesitemservice";

const TestItemLoading = (isStatus) => ({
    type: TESTITEM_LOADING,
    payload: isStatus,
});

export const deleteTestItem = (testItem) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_TESTITEM,
            payload: testItem,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrieveTestItem = () => async (dispatch) => {
    try {
        dispatch(TestItemLoading(true));
        const res = await tesitemservice.GetTestItemList();
        dispatch({
            type: RETRIEVE_TESTITEM,
            payload: res.data,
        });
        dispatch(TestItemLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(TestItemLoading(false));
    }
};