import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CKEditor } from 'ckeditor4-react';
import { Col, Row } from 'react-bootstrap';
import Reactselect from 'react-select';
import { getStyleEmailList, sendStyleMail, getstyleEmailEmpList, insertEmailAttachment } from "../../../actions/style";
import Nodify from "../../Common/ReactNotification"
import ReactNotification from 'react-notifications-component';
import Loader from "react-loader-spinner";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import $ from "jquery";
import Lightbox from "react-image-lightbox"
import axios from "axios";
import SweetAlertPopup from '../../../components/Common/SweetAlertPopup';
import { EmailAttachment } from "../../../components/Common/PageActionNumber";


const StyleMail = ({ props, StyleInfo, MailRedirect, EmailList }) => {
    const [getres, setres] = useState(0);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const MailList = useSelector((state) => state.style.StyleEmailList);
    const MailSenderAuth = useSelector((state) => state.auth.user);
    const [getFormattedEmpList, setFormattedEmpList] = useState([]);
    const [getSubmitted, setSubmitted] = useState(false);
    const [getHeader, setHeader] = useState('Compose Email');
    const [getContentHeader, setContentHeader] = useState('Content');
    const [getEmployeeProfilePicListForDDL, setEmployeeProfilePicListForDDL] = useState([]);
    const [getDDLValueForEmpList, setDDLValueForEmpList] = useState([]);
    const [getAttachmentFiles, setAttachmentFiles] = useState([]);
    const [IsAttachmentSucced, setIsAttachmentSucced] = useState(false);
    const [getAttachmentIsOpenimg, setAttachmentIsOpenimg] = useState(false);
    const [getAttachmentImagename, setAttachmentImagename] = useState();
    const [getReplyAttachments, setReplyAttachments] = useState([]);
    const [getRecentlyAttachedMail, setRecentlyAttachedMail] = useState([]);

    const [getID, setID] = useState(0);
    var EmployeeProfilePicListForDDL = [...getEmployeeProfilePicListForDDL];
    var ReplyAttachments = [...getReplyAttachments];


    const [getMailerDetails, setMailerDetails] = useState([{
        FirstName: MailSenderAuth.empFirstName,
        LastName: MailSenderAuth.empLastName,
        Address: MailSenderAuth.empAddressLine1,
        MobileNumber: MailSenderAuth.empPrimaryContactNumber,
        MailSender: MailSenderAuth.empUserName,
        MailContent: "",
        StyleID: StyleInfo.styleID,
        MailReceiver: '',
        MailReceiverID: '',
        Subject: '',
        ParentID: '',
        ToMailInformation: [],
        Attachment: [],
        CreatedBy: MailSenderAuth.employeeinformationID
    }]);
    let values = [...getMailerDetails];
    var Attachmentfilevals = [...getAttachmentFiles];
    const handleContentChange = (value, fieldType) => {
        let inputText = '';
        if (fieldType === "content") {
            let values = [...getMailerDetails];
            //values[0].ToMailInformation = undefined;
            // if (value.editor.getData().trim() !== '') {
            values[0].MailContent = value.editor.getData().trim();
            // }
            // values[0].MailContent = inputText;
            setMailerDetails(values);
        }
        if (fieldType === "subject") {
            if (value.target.value.trim()) {
                inputText = value.target.value;
            }
            values[0].Subject = inputText;
            setMailerDetails(values);
        }
    }

    const handleEmployeeChange = (value) => {
        EmployeeProfilePicListForDDL = [];
        values[0].MailReceiverID = value.map(a => a.value);
        values[0].MailReceiver = value.map(a => a.mailID);
        values[0].ToMailInformation = value.map(a => a.mailID);
        values.ToMailInformation = value;
        if (getSubmitted == true && value.length > 0) {
            setSubmitted(false);
        }
        else if (getSubmitted == true && value.length == - 0) {
            setSubmitted(true);
        }
        getDDLValueForEmpList.filter(function (option) {
            values.map((value) => {
                if (value.MailReceiverID.includes(option.employeeID)) {
                    EmployeeProfilePicListForDDL.push({
                        profilePic: option.sendToProfilePic,
                        userMail: option.emailID
                    });
                }

            });
            setEmployeeProfilePicListForDDL(EmployeeProfilePicListForDDL);
        })
        setMailerDetails(values);
    }

    const dispatch = useDispatch();
    useEffect(() => {

        dispatch(getstyleEmailEmpList(StyleInfo.styleID)).then((res) => {
            let arr = [];
            if (EmailList !== undefined) {
                var AdminSender = res.filter(i => i.mailID.toString() === EmailList.id);
                var EmployeeSender = res.filter(i => i.mailID === 0);
                const MergeAdminAndEmployee = [...AdminSender, ...EmployeeSender];
                res = MergeAdminAndEmployee;
            }
            else {
                showLoader();
                var EmployeeSender = res.filter(i => i.mailID === 0);
                res = EmployeeSender;
            }
            setDDLValueForEmpList(res);
            res.filter(function (option) {
                arr.push({
                    value: option.employeeID,
                    label: option.name,
                    mailID: option.emailID
                });
            })
            setFormattedEmpList(arr);
            setres(1);
            //For Clear initial load files to set timer condition
            setTimeout(waitAndshow, 3000);
            function waitAndshow() {
                hideLoader();
            }
        }).catch(() => {
            hideLoader();
        });
    }, [StyleInfo.styleID]);

    useEffect(() => {
        if (EmailList != undefined && getFormattedEmpList.length > 0) {
            setContentHeader("Reply");
            setres(0);
            setHeader('View');
            values[0].ParentID = EmailList.id;
            var EmployeeID = EmailList.employeeID.split(',').map(function (item) {
                return ({
                    value: parseInt(item)
                });
            });
            let arr = [];
            EmployeeID.map((EmpID, index) => {
                getFormattedEmpList.map((EmpList, index) => {
                    if (EmpList.value == EmpID.value) {
                        arr.push({
                            value: EmpList.value,
                            label: EmpList.label,
                            mailID: EmpList.mailID
                        });
                    }
                });
            });
            values[0].ToMailInformation = arr;
            var mailer = MailList.item2.filter(i => i.masterID.toString() === EmailList.id);
            EmployeeProfilePicListForDDL.push({
                profilePic: mailer[0].senderProfilePic,
                userMail: mailer[0].senderMailID
            });
            // getMailerDetails
            values.ToMailInformation = [{
                value: mailer[0].senderInfoID,
                label: mailer[0].sentBy,
                mailID: mailer[0].senderMailID
            }];
            values[0].ToMailInformation = [{
                value: mailer[0].senderInfoID,
                label: mailer[0].sentBy,
                mailID: mailer[0].senderMailID
            }];
            values[0].MailReceiverID = [mailer[0].senderInfoID];
            values[0].MailReceiver = [mailer[0].senderMailID.toString()];
            setEmployeeProfilePicListForDDL(EmployeeProfilePicListForDDL);
            values[0].Subject = EmailList.subject;
            setMailerDetails(values);
            setHeader(values[0].Subject);
            setres(1);
        }
    }, [EmailList != undefined && getFormattedEmpList.length != 0]);

    useEffect(() => {

        if (EmailList === undefined) {
            showLoader();
            dispatch(getStyleEmailList(parseInt(0)));
        }
        else {
            dispatch(getStyleEmailList(parseInt(values[0].StyleID)));
        }
    }, [IsAttachmentSucced]);

    const SendStyleMail = () => {
        const values = [...getMailerDetails];

        if (EmailList !== undefined) {
            values[0].Attachment = getReplyAttachments;
            setMailerDetails(values);
        }
        showLoader();
        if (values[0].MailContent.trim() !== "" && values[0].MailReceiverID != "" && values[0].Subject.trim() != "") {
            if (EmailList !== undefined) {
                values[0].ToMailInformation = values[0].ToMailInformation.map(a => a.mailID);
            }
            dispatch(sendStyleMail(getMailerDetails[0])).then((res) => {
                if (res.data === 1) {
                    setReplyAttachments([]);
                    props.location.state.params.EmailNotification = true;
                    MailRedirect('LISTEmail');
                }
                else { Nodify('Warning!', 'warning', 'Mail Failed.'); }
            });
            setSubmitted(false);
        } else {
            Nodify("Warning!", "warning", "Please fill the mandatory(*) fields.");
            setSubmitted(true);
            hideLoader();
        }
    }

    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),
    }

    const AppendFiles = async e => {
        var file = e.target.files[0];
        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword', "application/vnd.ms-excel",
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#FileUpload').val("");
            }
            else {
                setIsAttachmentSucced(false);
                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "EmailAttachment");
                try {
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                    if (res.status === 201) {
                        Attachmentfilevals = [];
                        Attachmentfilevals.push({
                            styleID: values[0].StyleID,
                            attachmentName: res.data + "_" + file.name,
                            attachmentPath: "Images/Style/Email/" + res.data + "_" + file.name,
                            createdBy: MailSenderAuth.employeeinformationID,
                            masterMailID: EmailList !== undefined ? parseInt(EmailList.id) : 0,
                            fileType: fileType,
                        })
                        ReplyAttachments.push({
                            styleID: values[0].StyleID,
                            attachmentName: res.data + "_" + file.name,
                            attachmentPath: "Images/Style/Email/" + res.data + "_" + file.name,
                            createdBy: MailSenderAuth.employeeinformationID,
                            masterMailID: EmailList !== undefined ? parseInt(EmailList.id) : 0,
                            fileType: fileType,
                        })
                        setReplyAttachments(ReplyAttachments);
                    }

                    if (EmailList !== undefined) {
                        setMailerDetails(values);
                        setAttachmentFiles(Attachmentfilevals);
                        dispatch(insertEmailAttachment(Attachmentfilevals)).then((res) => {
                            if (res.outputResult === "1") {
                                setRecentlyAttachedMail(Attachmentfilevals);
                                setIsAttachmentSucced(true);
                            }
                        })
                    }
                    else {
                        getMailerDetails[0].Attachment.length === 0 ? values[0].Attachment = Attachmentfilevals : values[0].Attachment.push(Attachmentfilevals[0]);
                        MailList.item3 = getMailerDetails[0].Attachment;
                        setMailerDetails(values);
                    }
                    $('#FileUpload').val("");
                } catch (ex) {
                    console.log(ex);
                }
            }
        }
    }


    const ViewAttachmentFile = (event, filename) => {
        setAttachmentIsOpenimg(true);
        setAttachmentImagename(filename);
    }

    const RemoveFiles = (data, index) => {
        if (EmailList === undefined) {
            getMailerDetails[0].Attachment.splice(index, 1);
            values[0].Attachment = getMailerDetails[0].Attachment
            MailList.item3 = getMailerDetails[0].Attachment;
            setMailerDetails(values);
        }
        else {
            setID({ showPopupDelete: true, Params: data });
        }
    }

    const Delcallback = () => {
        setID({ showPopupDelete: false, Params: [] });
    }

    return (
        <>
            {/* <ReactNotification /> */}
            <div className="widget flat radius-bordered">
                <div className="panel-group accordion" id="accordion">
                    <div className="panel panel-default">
                        <div className="panel-heading ">
                            <h4 className="mb-0 panel-title">
                                <a className="btn btn-link font_wight" data-toggle="collapse" data-parent="accordion" href={"#collapsePrograme_"}>
                                    {getHeader}
                                </a>
                            </h4>
                        </div>
                        {
                            getID.showPopupDelete ?
                                <SweetAlertPopup data={getID.Params}
                                    deleteCallback={Delcallback}
                                    showpopup={true}
                                    pageActionId={EmailAttachment}
                                    Msg={"Attachment deleted successfully."} /> : null
                        }
                        <div className="widget-body">
                            {getres === 1 ? <div id="registration-form">
                                {EmailList !== undefined && <Row className='margin-top-20'>
                                    <>
                                        <Col md={1}></Col>
                                        <Col md={12}>
                                            <table className='table MailHistoryTable'>
                                                <tbody>
                                                    {MailList.item2.map(mail => {
                                                        if (mail.masterID == EmailList.id) {
                                                            return (
                                                                <tr>
                                                                    <td className='MailCommentUserIconTD'>
                                                                        < SimpleReactLightbox >
                                                                            <SRLWrapper >
                                                                                <img className="userMailCommentIcon margin-top-20 margin-left-10"
                                                                                    src={window.$APIBaseURL + 'Images' + mail.senderProfilePic}
                                                                                />
                                                                                {/* <img className=" userMailCommentIcon margin-top-20 margin-left-10"
                                                                                    src={"http://icubeapisqa.sgssys.info/" + 'Images' + mail.senderProfilePic}
                                                                                /> */}
                                                                            </SRLWrapper >
                                                                        </SimpleReactLightbox>
                                                                    </td>
                                                                    <td>
                                                                        <div className="col-md-12">
                                                                            <label className='CommentornameBG'><b>Mail From : </b>{mail.sentBy},<br />{mail.sentOn}</label>
                                                                            <label className='text-danger ChatMailTos'><b>Mail To :</b> {mail.sentTO.split(",").join(", ")}</label>
                                                                        </div>
                                                                        {
                                                                            mail.receiverProfilePic.split(',').reverse().map((pic, index) => {

                                                                                return (<>
                                                                                    < SimpleReactLightbox >
                                                                                        <SRLWrapper >
                                                                                            <img className="userMailCommentIcon pinlocation-btn margin-left-10"
                                                                                                src={window.$APIBaseURL + 'Images' + pic}
                                                                                            />
                                                                                            {/* <img className="userMailCommentIcon pinlocation-btn margin-left-10"
                                                                                                src={"http://plm.cubefashions.com//" + 'Images' + pic}
                                                                                            /> */}
                                                                                        </SRLWrapper >
                                                                                    </SimpleReactLightbox>

                                                                                </>
                                                                                )
                                                                            })
                                                                        }
                                                                        <br />
                                                                        <span>
                                                                            <div class="col-md-12">
                                                                                <span className='ChatMailTopa' dangerouslySetInnerHTML={{ __html: mail.mailContent }}></span>
                                                                            </div>
                                                                            {
                                                                                MailList.item3.map((mailattachment, attachindex) => {
                                                                                    return (
                                                                                        <div>
                                                                                            {mail.mailReferenceID === mailattachment.mailReferenceID &&
                                                                                                <div>
                                                                                                    <div class="col-lg-4 col-sm-4 file_list_upload">
                                                                                                        <tr title={mailattachment.attachmentName.split('_')[1]}>
                                                                                                            <td className="cut_text">
                                                                                                                <span style={{ marginRight: '7px' }}>
                                                                                                                    {
                                                                                                                        mailattachment.fileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                                            mailattachment.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || mailattachment.fileType === "application/vnd.ms-excel" ?
                                                                                                                                <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                                                mailattachment.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                                                    mailattachment.fileType === 'application/msword' ? <i class="fa fa-file-word-o words_icon" aria-hidden="true"></i> :
                                                                                                                                    <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                                                    }
                                                                                                                </span>{mailattachment.attachmentName.split('_')[1]}
                                                                                                            </td>
                                                                                                            <td className="button_style_uploda">
                                                                                                                {
                                                                                                                    mailattachment.fileType === "application/pdf" || mailattachment.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                                                                                                                        mailattachment.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                                                                                        || mailattachment.fileType === 'application/msword' || mailattachment.fileType === "application/vnd.ms-excel" ?
                                                                                                                        <a type="button"
                                                                                                                            title="Download" class="btn btn-xs btn-primary" target="_blank"
                                                                                                                            href={window.$APIBaseURL + 'Images/Style/Email/' + mailattachment.attachmentName} download><i class="fa fa-download"></i> </a> :

                                                                                                                        <i className="btn btn-info fa fa-eye" title="View Logo"
                                                                                                                            onClick={
                                                                                                                                event => ViewAttachmentFile(event, mailattachment.attachmentName)
                                                                                                                            }></i>
                                                                                                                }
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        {
                                                                                                            <div> {
                                                                                                                getAttachmentIsOpenimg && (
                                                                                                                    <Lightbox mainSrc={window.$APIBaseURL + 'Images/Style/Email/' + getAttachmentImagename}
                                                                                                                        alt="bg image"
                                                                                                                        onCloseRequest={
                                                                                                                            () => setAttachmentIsOpenimg(false)
                                                                                                                        } />
                                                                                                                )
                                                                                                            } </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            }

                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    })}

                                                </tbody>
                                            </table>
                                        </Col>
                                    </>
                                </Row>}
                                {/* <div class="col-lg-12 col-sm-12 margin-top-10">
                                    <div class="row no-margin">
                                        <Row>
                                            <Col md={12}>
                                                {
                                                    MailList.item3.map((mail, fileindex) => {
                                                        if (EmailList && EmailList.action === 'VIEWMAIL') {
                                                            return (
                                                                mail.masterMailID === parseInt(EmailList.id) &&
                                                                <div class="col-lg-4 col-sm-4 file_list_upload">
                                                                    <tr title={mail.attachmentName.split('_')[1]}>
                                                                        <td className="cut_text">
                                                                            <span style={{ marginRight: '7px' }}>
                                                                                {
                                                                                    mail.fileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                        mail.FileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || mail.FileType === "application/vnd.ms-excel" ?
                                                                                            <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                            mail.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                mail.FileType === 'application/msword' ? <i class="fa fa-file-word-o words_icon" aria-hidden="true"></i> :
                                                                                                <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                }
                                                                            </span>{mail.attachmentName.split('_')[1]}
                                                                        </td>
                                                                        <td className="button_style_uploda">
                                                                            {
                                                                                mail.fileType === "application/pdf" || mail.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                                                                                    mail.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                                                    || mail.fileType === 'application/msword' || mail.fileType === "application/vnd.ms-excel" ?
                                                                                    <a type="button"
                                                                                        title="Download" class="btn btn-xs btn-primary" target="_blank"
                                                                                        href={window.$APIBaseURL + 'Images/Style/Email/' + mail.attachmentName} download><i class="fa fa-download"></i> </a> :

                                                                                    <i className="btn btn-info fa fa-eye" title="View Logo"
                                                                                        onClick={
                                                                                            event => ViewAttachmentFile(event, mail.attachmentName)
                                                                                        }></i>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                    {
                                                                        <div> {
                                                                            getAttachmentIsOpenimg && (
                                                                                <Lightbox mainSrc={window.$APIBaseURL + 'Images/Style/Email/' + getAttachmentImagename}
                                                                                    alt="bg image"
                                                                                    onCloseRequest={
                                                                                        () => setAttachmentIsOpenimg(false)
                                                                                    } />
                                                                            )
                                                                        } </div>
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </div> */}
                                <div className='margin-top-30'>
                                    {(EmailList === undefined || EmailList.action === "EDITMAIL") && <Row>

                                        {EmailList === undefined && <Col md={4}>
                                            <div className='form-group' style={{ marginLeft: "9px" }}>
                                                <label>Subject <span className='text-danger'>*</span></label>
                                                <input type='text' className='form-control' id='txtSubject' name='subject'
                                                    value={getMailerDetails[0].Subject}
                                                    onChange={(value) => {
                                                        handleContentChange(value, 'subject')
                                                    }} disabled={EmailList !== undefined}
                                                    maxLength="200"
                                                    style={{
                                                        border: getSubmitted && getMailerDetails[0].Subject.trim() === '' ? '1px solid red' : ''
                                                    }}></input>
                                            </div>
                                        </Col>}
                                        <Col md={EmailList == undefined ? 4 : 8}></Col>
                                        <Col md={4}>
                                            <div className='form-group'>
                                                <label htmlFor="BuyerID">Send To<span className="text-danger">*</span></label>
                                                <span className='input-icon icon-right'>
                                                    <Reactselect className="basic-single" name="SendTo"
                                                        id={
                                                            "SendTo"
                                                        }
                                                        isDisabled={false}
                                                        isLoading={false}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        options={getFormattedEmpList}
                                                        value={getMailerDetails.ToMailInformation}
                                                        onChange={event => handleEmployeeChange(event)}
                                                        autoFocus
                                                        isMulti
                                                        styles={getSubmitted && (!getMailerDetails[0].ToMailInformation || getMailerDetails[0].ToMailInformation.length == 0) ? styles : ''}
                                                    ></Reactselect>
                                                </span>
                                            </div>
                                            {getEmployeeProfilePicListForDDL.length > 0 &&
                                                getEmployeeProfilePicListForDDL.map((value) => {
                                                    if (value) {
                                                        return (
                                                            <>
                                                                < SimpleReactLightbox>
                                                                    <SRLWrapper>
                                                                        <img className="col-md-6 userMailCommentIcon margin-top-20 margin-left-10"
                                                                            src={window.$APIBaseURL + 'Images' + value.profilePic}
                                                                        />
                                                                        {/* <img className="userMailCommentIcon pinlocation-btn margin-left-10"
                                                                            src={"http://icubeapisqa.sgssys.info/Images/" + value.profilePic}
                                                                        /> */}
                                                                    </SRLWrapper >
                                                                </SimpleReactLightbox>
                                                            </>
                                                        )
                                                    }
                                                })
                                            }
                                        </Col>
                                    </Row>}
                                </div>
                                {(EmailList === undefined || EmailList.action === 'EDITMAIL') && <Row>
                                    <Col md={12}>
                                        <div className='form-group'>
                                            <label htmlFor="txtMailContent">{getContentHeader}<span className="text-danger">*</span></label>
                                            <CKEditor style={{
                                                border: getSubmitted && (getMailerDetails[0].MailContent.trim() === '&nbsp;' || getMailerDetails[0].MailContent.trim() === '') ? '1px solid red' : ''
                                            }}
                                                id={"txtMailContent"}
                                                initData={''}
                                                config={{
                                                    toolbarGroups: [
                                                        { name: 'clipboard', groups: ['clipboard', 'undo'] },
                                                        { name: 'basicstyles', groups: ['basicstyles', 'list'] },
                                                        '/',
                                                    ]
                                                }}
                                                onInstanceReady={(event) => {

                                                }}

                                                onChange={(value) => {
                                                    handleContentChange(value, 'content')
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>}
                                {(EmailList === undefined) && <Row>
                                    <Col md={12}>
                                        <div className='form-group'>
                                            <label htmlFor='FileUpload'>Attach files:</label>
                                            <input type="file" id="FileUpload"
                                                onChange={(e) => AppendFiles(e)} accept=".jpg,.jpeg,.png,.pdf,.xlsx,.doc,.docx,.xls" ></input>
                                        </div>
                                    </Col>
                                </Row>}
                                <div class="col-lg-12 col-sm-12 margin-top-10">
                                    <div class="row no-margin">
                                        {((EmailList === undefined) && (MailList.item3)) && <Row>
                                            <Col md={12}>
                                                {
                                                    MailList.item3.map((mail, attachindex) => {
                                                        if (EmailList === undefined) {
                                                            return (
                                                                <div class="col-lg-4 col-sm-4 file_list_upload">
                                                                    <tr title={mail.attachmentName.split('_')[1]}>
                                                                        <td className="cut_text">
                                                                            <span style={{ marginRight: '7px' }}>

                                                                                {
                                                                                    mail.fileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                        mail.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || mail.fileType === "application/vnd.ms-excel" ?
                                                                                            <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                            mail.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                mail.fileType === 'application/msword' ? <i class="fa fa-file-word-o words_icon" aria-hidden="true"></i> :
                                                                                                <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                }
                                                                            </span>{mail.attachmentName.split('_')[1]}
                                                                        </td>
                                                                        <td className="button_style_uploda">
                                                                            {
                                                                                mail.fileType === "application/pdf" || mail.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                                                                                    mail.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                                                    || mail.fileType === 'application/msword' || mail.fileType === "application/vnd.ms-excel" ?
                                                                                    <a type="button"
                                                                                        title="Download" class="btn btn-xs btn-primary" target="_blank"
                                                                                        href={window.$APIBaseURL + 'Images/Style/Email/' + mail.attachmentName} download><i class="fa fa-download"></i> </a> :

                                                                                    <i className="btn btn-info fa fa-eye" title="View Logo"
                                                                                        onClick={
                                                                                            event => ViewAttachmentFile(event, mail.attachmentName)
                                                                                        }></i>
                                                                            }&nbsp;
                                                                            <p title='Delete File'
                                                                                className="btn btn-danger btn-xs delete margin-right-5" onClick={(e) => RemoveFiles(mail, attachindex)}>
                                                                                <i className="fa fa-trash-o"></i>
                                                                            </p>

                                                                        </td>
                                                                    </tr>
                                                                    {
                                                                        <div> {
                                                                            getAttachmentIsOpenimg && (
                                                                                <Lightbox mainSrc={window.$APIBaseURL + 'Images/Style/Email/' + getAttachmentImagename}
                                                                                    alt="bg image"
                                                                                    onCloseRequest={
                                                                                        () => setAttachmentIsOpenimg(false)
                                                                                    } />
                                                                            )
                                                                        } </div>
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </Col>
                                        </Row>}
                                    </div>
                                </div>
                                {(EmailList && EmailList.action === "EDITMAIL") && <Row>
                                    <Col md={12}>
                                        <div className='form-group'>
                                            <label htmlFor='FileUpload'>Attach files:</label>
                                            <input type="file" id="FileUpload"
                                                onChange={(e) => AppendFiles(e)} accept=".jpg,.jpeg,.png,.pdf,.xlsx,.doc,.docx,.xls" ></input>
                                        </div>
                                    </Col>
                                </Row>}

                                <div class="col-lg-12 col-sm-12 margin-top-10">
                                    <div class="row no-margin">
                                        {((EmailList && EmailList.action === "EDITMAIL") && (MailList.item3)) && <Row>
                                            <Col md={12}>
                                                {
                                                    MailList.item3.map((mail, attachindex) => {
                                                        return (
                                                            <div>{
                                                                (ReplyAttachments.length > 0 && (mail.mailReferenceID == 0) && ReplyAttachments.filter((data) => { return data.attachmentName == (mail.attachmentName) }).length > 0) &&
                                                                <div class="col-lg-4 col-sm-4 file_list_upload">
                                                                    <tr title={mail.attachmentName.split('_')[1]}>
                                                                        <td className="cut_text">
                                                                            <span style={{ marginRight: '7px' }}>
                                                                                {
                                                                                    mail.fileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                        mail.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || mail.fileType === "application/vnd.ms-excel" ?
                                                                                            <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                            mail.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                mail.fileType === 'application/msword' ? <i class="fa fa-file-word-o words_icon" aria-hidden="true"></i> :
                                                                                                <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                }
                                                                            </span>{mail.attachmentName.split('_')[1]}
                                                                        </td>
                                                                        <td className="button_style_uploda">
                                                                            {
                                                                                mail.fileType === "application/pdf" || mail.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                                                                                    mail.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                                                    || mail.fileType === 'application/msword' || mail.fileType === "application/vnd.ms-excel" ?
                                                                                    <a type="button"
                                                                                        title="Download" class="btn btn-xs btn-primary" target="_blank"
                                                                                        href={window.$APIBaseURL + 'Images/Style/Email/' + mail.attachmentName} download><i class="fa fa-download"></i> </a> :

                                                                                    <i className="btn btn-info fa fa-eye" title="View Logo"
                                                                                        onClick={
                                                                                            event => ViewAttachmentFile(event, mail.attachmentName)
                                                                                        }></i>
                                                                            }&nbsp;
                                                                            <p title='Delete File'
                                                                                className="btn btn-danger btn-xs delete margin-right-5" onClick={(e) => RemoveFiles(mail, attachindex)}>
                                                                                <i className="fa fa-trash-o"></i>
                                                                            </p>

                                                                        </td>
                                                                    </tr>
                                                                </div>}
                                                                {
                                                                    <div> {
                                                                        getAttachmentIsOpenimg && (
                                                                            <Lightbox mainSrc={window.$APIBaseURL + 'Images/Style/Email/' + getAttachmentImagename}
                                                                                alt="bg image"
                                                                                onCloseRequest={
                                                                                    () => setAttachmentIsOpenimg(false)
                                                                                } />
                                                                        )
                                                                    } </div>
                                                                }
                                                            </div>
                                                        )

                                                    })
                                                }
                                            </Col>
                                        </Row>}
                                    </div>
                                </div>

                                {(EmailList === undefined || EmailList.action === "EDITMAIL") && <Row>
                                    <Col md={12} className="fc-rtl mt-5">
                                        <button type="button" className='btn btn-success' onClick={SendStyleMail} >
                                            <i className='fa fa-envelope'></i>&nbsp; &nbsp;
                                            {EmailList === undefined ? <span>Send</span> : <span>Reply</span>}
                                        </button>
                                    </Col>
                                </Row>}
                            </div> : ''

                            }
                            {/* {
                                getID.showPopupDelete ?
                                    <SweetAlertPopup data={getID.Params}
                                        deleteCallback={Delcallback}
                                        showpopup={true}
                                        pageActionId={EmailAttachment}
                                        Msg={"Attachment deleted successfully."} /> : null
                            } */}
                        </div>
                        {getres === 0 ? <Loader type="ThreeDots" color="#b6da94" height={80} width={80} timeout={2000} style={{ textAlign: "center" }} /> : ''}
                    </div>
                </div>

            </div>
            {loader}
        </>
    )
}
export default StyleMail;