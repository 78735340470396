import React, { useState, useEffect, useMemo } from 'react';
import { TableHeader } from "../../Datatable";
import { useSelector, useDispatch } from 'react-redux';
import { getStyleEmailList } from "../../../actions/style";
import Loader from "react-loader-spinner";
import moment from "moment";
import { DateRangePicker } from 'react-date-range';

import Nodify from "../../Common/ReactNotification"
import ReactNotification from 'react-notifications-component';


const StyleEmailList = ({ props, MailRedirect, StyleInfo }) => {

    const dispatch = useDispatch();
    const [getEmailReqList, setEmailReqList] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);
    const [expandState, setExpandState] = useState({});
    const [getMailChildrenList, setMailChildrenList] = useState({});
    const [getres, setres] = useState(0);
    const [sorting, setSorting] = useState({ field: "", order: "" });
    var MailSearchStartDate = new Date();
    const MailList = useSelector((state) => state.style.StyleEmailList);
    const [getMailSearchDateRange, setMailSearchDateRange] = useState([
        {
            startDate: MailSearchStartDate.setDate(MailSearchStartDate.getDate() - 30),
            endDate: new Date(),
            key: 'selection',
            isShow: false
        }
    ]);

    useEffect(() => {
        if (props.location.state && props.location.state.params.EmailNotification === true) {
            Nodify('success!', 'success', 'Mail sent.');
        }
        setres(0);
        dispatch(getStyleEmailList(StyleInfo.styleID)).then((res) => {
            setEmailReqList(res.item1);
            if (res.item1.length === 0) {
                setres(1);
            }

        }).catch(() => {
        });
    }, [StyleInfo.styleID]);

    const MailListData = useMemo(() => {
        let MailListData = MailList.item1;
        if (MailListData !== undefined) {
            MailListData = MailListData.filter(mail => {
                //debugger;
                //const inputDateFormat = 'DD/MM/YYYY hh:mm A';
                // const inputDateFormat = 'MM/DD/YYYY';
                // const outputDateFormat = 'L LT';
                // const inputDateFormat = 'DD/MM/YYYY hh:mm A';
                // const outputDateFormat = 'MM/DD/YYYY';
                // const MailSentOn = moment(mail.sentOn, inputDateFormat).format(outputDateFormat);
                // //const MailSentOn = moment(new Date(mail.sentOn)).format('L');
                // const SearchStartDate = moment(new Date(getMailSearchDateRange[0].startDate)).format('L');
                // const SearchEndDate = moment(new Date(getMailSearchDateRange[0].endDate)).format('L');
                // if (SearchStartDate <= MailSentOn && SearchEndDate >= MailSentOn) {
                //     return mail;
                // }
                // //return mail;

                const inputDateFormat = 'DD/MM/YYYY hh:mm A';
const outputDateFormat = 'MM/DD/YYYY';

const mailSentOn = moment(mail.sentOn, inputDateFormat).format(outputDateFormat);
const searchStartDate = moment(getMailSearchDateRange[0].startDate).format(outputDateFormat);
const searchEndDate = moment(getMailSearchDateRange[0].endDate).format(outputDateFormat);

const mailSentOnDate = moment(mailSentOn, outputDateFormat);
const searchStartDateDate = moment(searchStartDate, outputDateFormat);
const searchEndDateDate = moment(searchEndDate, outputDateFormat);

if (searchStartDateDate.isSameOrBefore(mailSentOnDate) && searchEndDateDate.isSameOrAfter(mailSentOnDate)) {
    return mail;
}
            })
        }
        // if (sorting.field) {
        //     const reversed = sorting.order === "asc" ? 1 : -1;
        //     MailListData = MailListData.sort((a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]));
        // }
        else {
            setres(1);
            MailListData = [];

        }
        return MailListData;
    }, [MailList.item1, getMailSearchDateRange, sorting]);

    //Table Headers
    const headers = [
        { name: "Subject", field: "subject", sortable: false },
        { name: "Created Date", field: "sentOn", sortable: false },
        { name: "Action", field: "" }
    ];

    const pageRedirect = (Params, redirect) => {
        if (redirect === 'Edit') {
            let value = { data: Params, Page: 'EDITMAIL' }
            MailRedirect(value);
        }
        if (redirect === 'View') {
            let value = { data: Params, Page: 'VIEWMAIL' }
            MailRedirect(value);
        }

    }

    const handleDateSelect = (event, isShow) => {
        let MailSearchDate = [...getMailSearchDateRange];
        MailSearchDate[0].isShow = isShow;
        setMailSearchDateRange(MailSearchDate);
    }
    const clearDateSelect = () => {
        const emptychartDateRange = [
            {
                startDate: null,
                endDate: new Date(""),
                key: 'selection',
                isShow: false
            }];
        setMailSearchDateRange(emptychartDateRange);
    }
    const handleExpandRow = (id, content) => {
        // setExpandedRows([]);
        let MailChildren = [];
        MailList.item2.map((mail) => {
            //if (id === mail.masterID.toString() && mail.mailContent !== content) {
            if (id === mail.masterID.toString()) {
                MailChildren.push(mail);
            }
        })
        setMailChildrenList(MailChildren);
        // Expand row Start
        let currentExpandedRows = expandedRows;
        const isRowExpanded = currentExpandedRows.includes(id);
        let obj = {};
        isRowExpanded ? (obj[id] = false) : (obj[id] = true);
        setExpandState(obj);
        currentExpandedRows = [];
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== id) :
            currentExpandedRows.concat(id);
        setExpandedRows(newExpandedRows);
        // Expand row End


    }
    return (
        <>
            {/* <ReactNotification /> */}
            <div className="row">
                <div className="col-xs-12 col-md-12">
                    <div className="widget">
                        <div className="widget-body">
                            <div className='row'>
                                <div className='col-md-5'>
                                    <label >Date Range <span className="rptDateRangeSpan">(Clicking on the text box will cause the date picker to appear and disappear)</span> </label>
                                    <span className='input-icon icon-right  mb-5'>
                                        <span className="input-icon icon-right">
                                            <input
                                                value={(getMailSearchDateRange[0].startDate === null ? '' : moment(getMailSearchDateRange[0].startDate).format('DD/MM/YYYY') + ' To ') + ((getMailSearchDateRange[0].endDate === null || !moment(getMailSearchDateRange[0].endDate).isValid()) ? '' : moment(getMailSearchDateRange[0].endDate).format('DD/MM/YYYY'))}
                                                onClick={event => handleDateSelect(event, !getMailSearchDateRange[0].isShow)}
                                                type="text"
                                                className={'form-control style-email-datepicker'}
                                                placeholder="Select Date"
                                            />
                                           <i className="fa fa-times style-email-fa-times" onClick={clearDateSelect}  style={{ marginTop: '3px',width: '50px',right: '240px' }}></i>
                                        </span>
                                        <span className='input-icon icon-right  mb-5'>
                                            <br />

                                            {getMailSearchDateRange[0].isShow && <DateRangePicker
                                                onChange={item => setMailSearchDateRange([item.selection])}
                                                showSelectionPreview={true}
                                                moveRangeOnFirstSelection={false}
                                                months={2}
                                                ranges={getMailSearchDateRange}
                                                showDateDisplay={false}
                                                direction="vertical"
                                                className={'ChartDateRangePicker'}
                                            />}

                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div className="dataTables_wrapper form-inline no-footer">
                                <div className="table-toolbar pull-left"></div>
                                {
                                    MailListData.length > 0 ?
                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                            <TableHeader headers={headers} onSorting={
                                                (field, order) => setSorting({ field, order })
                                            } />
                                            <tbody>
                                                {
                                                    MailListData.map(mail => (
                                                        <>
                                                            <tr>

                                                                <td ><i className={
                                                                    expandState[mail.id] ?
                                                                        'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                }

                                                                    onClick={event => handleExpandRow(mail.id, mail.mailContent)}></i>&nbsp;{mail.subject}</td>
                                                                <td>{mail.sentOn}</td>
                                                                <td>
                                                                    <span title='View mail'
                                                                        className="btn btn-secondary btn-xs edit" onClick={() => pageRedirect(mail, 'View')}>
                                                                        <i className="fa fa-eye "></i>
                                                                    </span>{' '}
                                                                    <span title='Edit mail'
                                                                        className="btn btn-success btn-xs edit" onClick={() => pageRedirect(mail, 'Edit')}>
                                                                        <i className="fa fa-reply "></i>
                                                                    </span>

                                                                </td>
                                                            </tr>
                                                            <>
                                                                {
                                                                    expandedRows[0] === mail.id &&
                                                                    <>
                                                                        {
                                                                            <>
                                                                                {
                                                                                    getMailChildrenList.length > 0 && getMailChildrenList.map(mail => {

                                                                                        return (
                                                                                            <tr>
                                                                                                <td colSpan={3} className='mailGridChat' >
                                                                                                    <label className='CommentornameBG'><b>Mail From : </b>{mail.sentBy}<br />{mail.sentOn}</label>
                                                                                                    <label className='text-danger ChatMailTos'><b>Mail To :</b> {mail.sentTO.split(",").join(", ")}</label>
                                                                                                    <br />
                                                                                                    <span className='ChatMailTopa' dangerouslySetInnerHTML={{ __html: mail.mailContent }}></span>
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    })

                                                                                }
                                                                                {getMailChildrenList.length === 0 &&
                                                                                    <tr><td colSpan="3" className='norecordfound'><span>No Records Found</span></td></tr>
                                                                                }
                                                                            </>
                                                                        }
                                                                    </>
                                                                }
                                                            </>
                                                        </>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                        :
                                        <>
                                            {
                                                MailListData.length === 0 ?
                                                    <>
                                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                            <TableHeader headers={headers} />
                                                            <tbody>
                                                                <tr><td colSpan="4" className='norecordfound'><span>No Records Found</span></td></tr>
                                                            </tbody>
                                                        </table>
                                                    </>
                                                    : ''
                                            }
                                            {getres === 0 ? <Loader type="ThreeDots" color="#b6da94" height={80} width={80} timeout={2000} style={{ textAlign: "center" }} /> : ''}
                                        </>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default StyleEmailList;