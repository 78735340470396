import {
 TEMPLATELIST_LOADING,
 RETRIEVE_TEMPLATELIST,
 DELETE_TEMPLATELIST,
} from "../actions/types";

const initialState = {
 isLoadingtemplate: true,
 templatelist: [],
 templateedit: [],
}
const TemplateReducer = (state = initialState, action) => {
 const { type, payload } = action
 switch (type) {
  case TEMPLATELIST_LOADING:
   return {
    ...state,
    isLoadingtemplate: payload
   };
  case RETRIEVE_TEMPLATELIST:
   return {
    ...state,
    templatelist: payload.templateList1,
    templateedit: payload.templateEdit2
   }
  case DELETE_TEMPLATELIST:
    
     let results = state.templatelist.filter(c => c.templateInfoID !== payload.templateInfoID);
   return {
    ...state,
    templatelist: results
   }
  default:
   return state

 }

}
export default TemplateReducer