import {
  TESTTEMP_LOADING,
  RETRIEVE_TESTTEMP,
  DELETE_TESTTEMP,
} from "../actions/types";

const initialState = {
  isLoadingtemplate: true,
  testreptemplist: [],
  testreptemplistedit: [],
}

const TestRepTempReducer = (state = initialState, action) => {
 const { type, payload } = action
 switch (type) {
  case TESTTEMP_LOADING:
   return {
    ...state,
    isLoadingtemplate: payload
   };
  case RETRIEVE_TESTTEMP:
   
   return {
    ...state,
    testreptemplist: payload.testRepTemp,
    testreptemplistedit: payload.testRepTempEdit
   }
  case DELETE_TESTTEMP:

   let results = state.testreptemplist.filter(c => c.brandID !== payload.brandID);
   return {
    ...state,
    testreptemplist: results
   }
  default:
   return state
 }

}
export default TestRepTempReducer