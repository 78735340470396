import {
    PURCHASEORDER_LOADING,
    RETRIEVE_PURCHASEORDER,
    DELETE_PURCHASEORDER,
} from "./types";

import PurchaseOrderService from "../services/PurchaseOrder/PurchaseOrderService";

const purchaseorderLoading = (isStatus) => ({
    type: PURCHASEORDER_LOADING,
    payload: isStatus,
});

export const deletePurchaseOrder = (purchaseOrderID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_PURCHASEORDER,
            payload: purchaseOrderID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrievePurchaseOrder = (purchaseOrderID, IsProduction) => async (dispatch) => {
    try {
        dispatch(purchaseorderLoading(true));
        const res = await PurchaseOrderService.GetPurchaseOrderList(purchaseOrderID, IsProduction);
        dispatch({
            type: RETRIEVE_PURCHASEORDER,
            payload: res.data,
        });
        dispatch(purchaseorderLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(purchaseorderLoading(false));
    }
};