import axios from "axios";
import authHeader from "../auth-header";

class RoleService {
    GetRoleList(roleID) {
           
            return axios.get(window.$APIBaseURL + "Master/GetRoleList?RoleID=" + roleID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetUserType() {
        return axios.get(window.$APIBaseURL + "Master/LoadTableTypeList", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    

    InsertUpdateRoleGrid(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateRoleGrid',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

      InsertUpdateRole(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateDelRole',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }


    LoadRoleList(UserTypeID) {
        return axios.get(window.$APIBaseURL + "Master/LoadRoleList?TableType=" + UserTypeID, {
           headers: {
                authorization: authHeader()
            }
        });
    }
}

export default new RoleService();