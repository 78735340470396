import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import { Modal, Button } from "react-bootstrap";
import ProcessTypeService from "../../services/Master/ProcessTypeService";
import Nodify from "../Common/ReactNotification";
import { insertUpdateProcessTypeGrid } from "../../actions/ProcessType";

const ProcessTypePopup = ({
    getProcessTypeList,
    processTypeCallback,
    showpopup,
}) => {

    const EmptyProcessTypeInputFields =
        [{
            ProcessTypeID: 0,
            ProcessTypeName: '',
        }]

    const [buttonLoader, setButtonLoader] = useState(false);
    const [processTypeInputFields, setProcessTypeInputFields] = useState(EmptyProcessTypeInputFields);
    const [processTypeList, setProcessTypeList] = useState([]);
    const [show, setShow] = useState(true);
    const [processTypeSubmitted, setProcessTypeSubmitted] = useState(false);

    const dispatch = useDispatch();
    //reducer
    const reducerState = useSelector((state) => state);
    const { user: currentUser } = reducerState.auth;
    //const getProcessTypeList = reducerState.processType.processTypeList;

    const handlePopupClose = () => {
        processTypeCallback(false);
    };

    const CheckDuplicate = (index) => {
       
        const values = [...processTypeInputFields];
        if (getProcessTypeList) {
            let ExistingProcessType = !!getProcessTypeList.find(d => d.name.trim().toLowerCase() === values[index].ProcessTypeName.trim().toLowerCase());
            if (ExistingProcessType) {
                values[index].ProcessTypeName = '';
                setProcessTypeInputFields(values);
                setProcessTypeList({ Createdby: currentUser.employeeinformationID, ProcessTypeInformation: values });
                Nodify('Warning!', 'warning', "This name is already exist.");
                return false;
            }

            // for (var i = 0; i < getprocessTypeList.length; i++) {
            //     if (getprocessTypeList[i].label.trim().toLowerCase() === values[index].ProcessTypeName.trim().toLowerCase()) {
            //         values[index].ProcessTypeName = '';
            //         setProcessTypeInputFields(values);
            //         setProcessTypeList({ Createdby: currentUser.employeeinformationID, ProcessTypeInformation: values });
            //         Nodify('Warning!', 'warning', "This name is already exist.");
            //         return false;
            //     }
            // }
        }
    }

    const handleProcessTypeChange = (event) => {
        setProcessTypeSubmitted(false);
        const values = [...processTypeInputFields];
        let inputText = '';
        // if (event.target.value.trim() !== '') {
        //     inputText = event.target.value.trim();
        // }
        if (event.target.value !== '') {
            inputText = event.target.value;
        }
        values[0].ProcessTypeName = inputText;
        setProcessTypeInputFields(values);
        setProcessTypeList({ Createdby: currentUser.employeeinformationID, ProcessTypeInformation: values });
    }

    const SaveProcessType = (e) => {
       
        setButtonLoader(true);
        e.preventDefault();
        const values = [...processTypeInputFields];
        if (values[0].ProcessTypeName.trim() === '') {
            setProcessTypeSubmitted(false);
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Process Type  is required.');
        }
        if (values[0].ProcessTypeName.trim()) {
            ProcessTypeService.InsertUpdateProcessTypeGrid(processTypeList).then(res => {

                if (res.data.outputResult === "1") {
                    Nodify("Success!", "success", "Process Type added successfully.");
                    processTypeCallback(true);
                } else if (res.data.outputResult === "-2") {
                    Nodify('Warning!', 'warning', 'This name is already exist.');
                }
                setButtonLoader(false);
            })
                .catch(e => {
                    console.log(e);
                });
        }
        else {
            setProcessTypeSubmitted(true);
        }
    }

    return (
        // <Modal show={showpopup} size="md" dragable backdrop="static" keyboard={false} onHide={() => handlePopupClose()}>
        //     <Modal.Header closeButton>
        //     <Modal.Title>Add Season</Modal.Title>
        //     </Modal.Header>
        //       <Modal.Body>
        <div id="registration-form">
            <Form>
                <div className="row">
                    <div className="form-group col-sm-12">
                        <label htmlFor="ProcessTypeName">
                            Process Type
                            <span className="text-danger">*</span>
                        </label>
                        <Input
                            placeholder={"Enter Process Type"}
                            id="ProcessTypeName"
                            name="ProcessTypeName"
                            type="text"
                            maxLength="150"
                            value={processTypeInputFields[0].ProcessTypeName}
                            autoComplete="off"
                            onChange={handleProcessTypeChange}
                            className='form-control'
                            onBlur={() => CheckDuplicate(0)}
                            style={{ border: processTypeSubmitted && !processTypeInputFields.ProcessTypeName ? '1px solid red' : '' }}
                            autoFocus
                        />
                    </div>
                </div>
                <br />
                <div style={{ textAlign: "right" }}>
                    <Button type="button" className="btn btn-success" onClick={SaveProcessType} disabled={buttonLoader}>
                        <i className="fa fa-check right"></i> &nbsp; Save
                    </Button>
                </div>
            </Form>
        </div>

        //   </Modal.Body>
        //   <Modal.Footer>
        //     <Button  type="submit" className="btn btn-success" onClick={SaveSeason} disabled={buttonLoader}>
        //        <i className="fa fa-check right"></i> &nbsp; Save
        //     </Button>
        //   </Modal.Footer>
        // </Modal>
    )
};

export default ProcessTypePopup;