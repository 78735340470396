import {
    COURIER_LOADING,
    LOAD_COURIER_LIST,
    VIEW_COURIER_STATUS
} from "./types";
import courierService from "../services/courier.service";

const CourierLoading = (isStatus) => ({
    type: COURIER_LOADING,
    payload: isStatus,
});


export const LoadCourierDetails = () => async (dispatch) => {
    let Courierlist = [];
    try {
        dispatch(CourierLoading(true));
        const res = await courierService.LoadCourierDetails();
        Courierlist = res.data;
        dispatch({
            type: LOAD_COURIER_LIST,
            payload: res.data,
        });
        dispatch(CourierLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(CourierLoading(false));
    }
    dispatch(CourierLoading(false));
    return Promise.resolve(Courierlist);
}

export const InsertUpdateCourierDetails = (courierDetails) => async (dispatch) => {
    return courierService.InsertUpdateCourierDetails(courierDetails).then(
        (data) => {
            return Promise.resolve(data);
        }
    );
}

export const DeleteCourierDetails = (Operation, ID) => async (dispatch) => {
    return courierService.DeleteCourierDetails(Operation, ID).then(
        (data) => {
            return Promise.resolve(data);
        }
    );
}

export const ViewCourierStatus = (ID) => async (dispatch) => {
    dispatch(CourierLoading(true));
    try {
        const res = await courierService.LoadActualCourierList(ID);
        dispatch(CourierLoading(false));
        return Promise.resolve(res);
    } catch (err) {
        dispatch(CourierLoading(false));
        return Promise.resolve(err);
    }
}
