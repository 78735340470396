import {
    YARNTYPE_LOADING,
    RETRIEVE_YARNTYPE,
    DELETE_YARNTYPE,
} from "../actions/types";

const initialState = {
    isLoadingYarnType: true,
    yarnTypeList: [],
};

const YarnTypeReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case YARNTYPE_LOADING:
            return {
                ...state,
                isLoadingYarnType: payload
            };
        case RETRIEVE_YARNTYPE:
            return { ...state, yarnTypeList: payload };
        case DELETE_YARNTYPE:
            const results = state.yarnTypeList.filter(c => c.yarnTypeID !== payload.yarnTypeID);
            return {
                ...state,
                yarnTypeList: results
            };

        default:
            return state;
    }
};
export default YarnTypeReducer;