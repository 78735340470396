import axios from "axios";
import authHeader from "../auth-header";

class ProcessTypeService {
  InsertUpdateProcessTypeGrid = async(Params) => {
        return await axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateProcessTypeGrid',
            data:Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

     getProcessTypeList() {
        return axios.get(window.$APIBaseURL + "Master/GetProcessTypeList?ProcessTypeID=0", {
            headers: {
                authorization: authHeader()
            }
        });
    }
 
     InsertUpdateProcessType(Params) {
   return axios({
      method: 'post',
      url: window.$APIBaseURL + 'Master/InsertUpdateProcessType',
      data: Params,
      headers: {
            authorization: authHeader()
          }
    });
  }
    }
export default new ProcessTypeService()