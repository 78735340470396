import {
 ITEMS_LOADING,
 RETRIEVE_ITEMS,
 DELETE_ITEMS
} from "../actions/types";

const initialState = {

 isLoadingitems: true,
 itemsList: [],
};

const ItemReducer = (state = initialState, action) => {
 const { type, payload } = action;
 switch (type) {
  case ITEMS_LOADING:
   return {
    ...state,
    isLoadingitems: payload
   };

  case DELETE_ITEMS:
   
   const results = state.itemsList.filter(c => c.itemID !== payload.itemID);
   return {
    ...state,
    itemsList: results
   };
  case RETRIEVE_ITEMS:
   return { ...state, itemsList: payload };
  default:
   return state;
 }

}

export default ItemReducer;