import {
    STRIKEOFF_LOADING,
    RETRIEVE_STRIKEOFF,
    DELETE_STRIKEOFF,
} from "../actions/types";

const initialState = {
    isLoadingStrikeoff: true,
    strikeOffList: [],
};

const strikeoffReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case STRIKEOFF_LOADING:
            return {
                ...state,
                isLoadingStrikeoff: payload
            };
        case RETRIEVE_STRIKEOFF:
           
            let Strikeoff = [...payload]
            Strikeoff.filter(x => x.buyerAndBrandName === null).map((y) => {
                if (y) {
                    if (y.buyerAndBrandName === null || y.buyerAndBrandName === undefined) {
                        y.buyerAndBrandName = "";
                    }
                }
            })
            return { ...state, strikeOffList: Strikeoff };
        case DELETE_STRIKEOFF:
            const results = state.strikeOffList.filter(c => c.strikeOffID !== payload.strikeOffID);
            return {
                ...state,
                strikeOffList: results
            };

        default:
            return state;
    }
};
export default strikeoffReducer;