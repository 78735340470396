import React, { useState, useEffect, useDebugValue } from "react";
import 'react-notifications-component/dist/theme.css';
import UploadImage from "../Common/UploadImage";
import Lightbox from "react-image-lightbox"

const CommonStyle = ({ props, Style }) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
    }, []);

    return (
        <>
            <div> {
                isOpen && (
                    <Lightbox
                        mainSrc={isOpen}
                        alt="bg image"

                        onCloseRequest={
                            () => setIsOpen(false)
                        } />
                )
            } </div>
            {Style !== undefined && (
                <div className="col-lg-12">
                    <div className="row style-row no_padding">
                        <div className="col-lg-10 ">
                            <div>
                                <div>
                                    <div class="style-item">
                                        <div class="title-bg"><label htmlFor="BuyerInfo"><b>Buyer Info:</b> </label></div>
                                        <div class="" style={{ marginLeft: "-25px" }}><span> {Style.buyerName} - {Style.seasonName} - {Style.brandName}</span></div>
                                    </div>

                                    <div class="style-item">
                                        <div class="title-bg"><label htmlFor="StyleName"><b>Style Name:</b> </label></div>
                                        <div class="" style={{ marginLeft: "-25px" }}><span>{Style.styleName}</span></div>
                                    </div>

                                    <div class="style-item">
                                        <div class="title-bg"><label htmlFor="StyleNo"><b>Style No:</b></label></div>
                                        <div class="" style={{ marginLeft: "-25px" }}><span>{Style.styleNo}</span></div>
                                    </div>
                                    {Style.stroryName !== '' && (
                                        <div class="style-item">
                                            <div class="title-bg"><label htmlFor="Story"><b>Story:</b> </label></div>
                                            <div class="" style={{ marginLeft: "-25px" }}><span>{Style.stroryName}</span></div>
                                        </div>
                                    )}
                                    {Style.themeName !== '' && (
                                        <div class="style-item">
                                            <div class="title-bg"><label><b>Theme:</b> </label> </div>
                                            <div class="" style={{ marginLeft: "-25px" }}><span>{Style.themeName}</span></div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <img className="img-fluid"
                                src={Style.styleLogoPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images" + Style.styleLogoPath}
                                alt="Style Pic"
                                onClick={() => setIsOpen(Style.styleLogoPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images" + Style.styleLogoPath)}
                                style={{ height: '60px', float: "right", marginBottom: "5px" }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default CommonStyle;