import {
    ORDERLABDIP_LOADING,
    ORDERLABDIP_RELOADING,
    RETRIEVE_ORDERLABDIP,
    DELETE_ORDERLABDIP,
    RETRIEVE_ORDER_LABDIP_DETAIL_VIEW_LIST,
    CLEAR_ORDER_LABDIP_DETAIL_VIEW_SUB_ROW,
    RETRIEVE_ORD_LABDIP_ALREADY_MAP_LIST,
    UPDATE_ORD_PROGRAM_GRID_LIST,
    RETRIEVE_ORDER_LABDIP_SUBMISSION_LIST,
    CLEAR_ORDER_LABDIP_SUBMISSION_SUB_ROW,
    RETRIEVE_ORDER_LABDIP_APPROVAL_LIST,
    CLEAR_ORDER_LABDIP_APPROVAL_SUB_ROW,
    RETRIEVE_ORDER_LABDIP_APPROVAL_VIEW_LIST,
    CLEAR_ORDER_LABDIP_APPROVAL_VIEW_SUB_ROW,
    UPDATE_ORDER_LABDIP_DETAIL_VIEW_CHECK_BOX,
    NEW_TNA_ORDER_LABDIP_LIST,
    TNA_ORDER_LABDIP_HOLIDAY_LIST,
    REMOVE_TNA_ORDER_LABDIP_PANTONE_SWATCH_LIST,
    TNA_ORDER_LABDIP_SUBMISSION_AND_APPROVAL,
    ORDER_LABDIP_PROGRAM_STATUS_LIST,
    OLD_TNA_TASK_OWNER_LIST,
    UPDATE_ORDER_LAPDIP_DETAIL_VIEW_ALL_CHECK_BOX
} from "./types";
import OrderService from "../services/Order/OrderService";

const orderLabdipLoading = (isStatus) => ({
    type: ORDERLABDIP_LOADING,
    payload: isStatus,
});

const orderLabdipReLoading = (isStatus) => ({
    type: ORDERLABDIP_RELOADING,
    payload: isStatus,
});

// export const deleteorderLabdip = (ordLabdipId) => async (dispatch) => {
//     try {
//         dispatch({
//             type: DELETE_ORDERLABDIP,
//             payload: ordLabdipId,
//         });

//     } catch (err) {
//         console.log(err);
//     }
// };



export const retrieveorderLabdip = (IsProduction) => async (dispatch) => {
    try {
        dispatch(orderLabdipLoading(true));
        const res = await OrderService.GetOrderLabdipList(IsProduction);
        dispatch({
            type: RETRIEVE_ORDERLABDIP,
            payload: res.data,
        });
        dispatch(orderLabdipLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(orderLabdipLoading(false));
    }
};
//labdipDetailsLabdip
export const getOrderLapdipDetailsViewList = (params) => async (dispatch) => {
    try {
        dispatch(orderLabdipLoading(true));
        const res = await OrderService.GetOrderLapdipDetailsViewList(params);
        dispatch({
            type: RETRIEVE_ORDER_LABDIP_DETAIL_VIEW_LIST,
            payload: { data: res.data, params: params },
        });
        dispatch(orderLabdipLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(orderLabdipLoading(false));
    }
};
//Request Labdip
export const GetOrderLapdipSubmissionApprovalList = (params) => async (dispatch) => {
    try {
        dispatch(orderLabdipLoading(true));
        const res = await OrderService.GetOrderLapdipSubmissionApprovalList(params);
        dispatch({
            type: RETRIEVE_ORDER_LABDIP_SUBMISSION_LIST,
            payload: { data: res.data, params: params },
        });
        dispatch(orderLabdipLoading(false));

    } catch (err) {
        console.log(err);
        dispatch(orderLabdipLoading(false));
    }
};
//Submiison Labdip
export const GetOrderLapdipApprovalList = (params) => async (dispatch) => {
    try {
        dispatch(orderLabdipLoading(true));
        const res = await OrderService.GetOrderLapdipSubmissionApprovalList(params);
        dispatch({
            type: RETRIEVE_ORDER_LABDIP_APPROVAL_LIST,
            payload: { data: res.data, params: params },
        });
        dispatch(orderLabdipLoading(false));

    } catch (err) {
        console.log(err);
        dispatch(orderLabdipLoading(false));
    }
};



export const ReloadOrderLapdipApprovalList = (params) => async (dispatch) => {
    try {
        dispatch(orderLabdipReLoading(true));
        const res = await OrderService.GetOrderLapdipSubmissionApprovalList(params);
        dispatch({
            type: RETRIEVE_ORDER_LABDIP_APPROVAL_LIST,
            payload: { data: res.data, params: params },
        });
        dispatch(orderLabdipReLoading(false));

    } catch (err) {
        console.log(err);
        dispatch(orderLabdipReLoading(false));
    }
};

//approval labdip
export const GetOrderLapdipApprovalViewList = (params) => async (dispatch) => {
    try {
        dispatch(orderLabdipLoading(true));
        const res = await OrderService.GetOrderLapdipSubmissionApprovalList(params);
        dispatch({
            type: RETRIEVE_ORDER_LABDIP_APPROVAL_VIEW_LIST,
            payload: { data: res.data, params: params },
        });
        dispatch(orderLabdipLoading(false));

    } catch (err) {
        console.log(err);
        dispatch(orderLabdipLoading(false));
    }
};

export const ReloadOrderLapdipApprovalViewList = (params) => async (dispatch) => {
    try {
        dispatch(orderLabdipReLoading(true));
        const res = await OrderService.GetOrderLapdipSubmissionApprovalList(params);
        dispatch({
            type: RETRIEVE_ORDER_LABDIP_APPROVAL_VIEW_LIST,
            payload: { data: res.data, params: params },
        });
        dispatch(orderLabdipReLoading(false));

    } catch (err) {
        console.log(err);
        dispatch(orderLabdipReLoading(false));
    }
};

//--------------------end labdip--------------------------
export const clearLapdipDetailsViewSubRow = (index) => async (dispatch) => {
    dispatch(orderLabdipLoading(true));
    dispatch({
        type: CLEAR_ORDER_LABDIP_DETAIL_VIEW_SUB_ROW,
        payload: index,
    });
    dispatch(orderLabdipLoading(false));

};

export const getOrdLabDipAlreadyMapList = (params) => async (dispatch) => {

    let isSuccess = false;
    try {
        dispatch(orderLabdipLoading(true));
        const res = await OrderService.GetOrdLabDipAlreadyMapList(params);
        dispatch({

            type: RETRIEVE_ORD_LABDIP_ALREADY_MAP_LIST,
            payload: res.data,
        });
        isSuccess = res.data.length > 0 ? true : false;
        dispatch(orderLabdipLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(orderLabdipLoading(false));
        return Promise.reject(err);
    }
    return Promise.resolve(isSuccess);
};



export const clearLapdipSubmissionSubRow = (index) => async (dispatch) => {
    dispatch(orderLabdipLoading(true));
    dispatch({
        type: CLEAR_ORDER_LABDIP_SUBMISSION_SUB_ROW,
        payload: index,
    });
    dispatch(orderLabdipLoading(false));

};


export const clearLapdipApprovalSubRow = (index) => async (dispatch) => {
    dispatch(orderLabdipLoading(true));
    dispatch({
        type: CLEAR_ORDER_LABDIP_APPROVAL_SUB_ROW,
        payload: index,
    });
    dispatch(orderLabdipLoading(false));

};



export const clearLapdipApprovalViewSubRow = (index) => async (dispatch) => {
    dispatch(orderLabdipLoading(true));
    dispatch({
        type: CLEAR_ORDER_LABDIP_APPROVAL_VIEW_SUB_ROW,
        payload: index,
    });
    dispatch(orderLabdipLoading(false));

};

// export const UpdateOrdLapdipDetailsViewAllCheckBox= (isChecked) => async (dispatch) => {

//     dispatch(orderLabdipLoading(true));
//     dispatch({
//         type: UPDATE_ORDER_LAPDIP_DETAIL_VIEW_ALL_CHECK_BOX,
//         payload: isChecked,
//     });
//     dispatch(orderLabdipLoading(false));

// };
// export const UpdateOrdLapdipDetailsViewCheckBox = (params) => async (dispatch) => {
//     dispatch(orderLabdipLoading(true));
//     dispatch({
//         type: UPDATE_ORDER_LABDIP_DETAIL_VIEW_CHECK_BOX,
//         payload: params,
//     });
//     dispatch(orderLabdipLoading(false));

// };




export const getOrdLapdibPantoneOrSwatchList = (params) => async (dispatch) => {
    try {
        dispatch(orderLabdipLoading(true));
        const res = await OrderService.GetOrdLapdibPantoneOrSwatchList(params);
        dispatch({
            type: NEW_TNA_ORDER_LABDIP_LIST,
            payload: res.data,
        });
        dispatch(orderLabdipLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(orderLabdipLoading(false));
    }
};

export const updateProgramGridList = (index, labdibSubmissionApprovalID) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_ORD_PROGRAM_GRID_LIST,
            payload: { index: index, labdibSubmissionApprovalID: labdibSubmissionApprovalID },
        });

    } catch (err) {
        console.log(err);
    }
};



export const getOrdLapdibTNAHolidayList = (params) => async (dispatch) => {
    try {
        dispatch(orderLabdipLoading(true));
        const res = await OrderService.GetOrdLapdibTNAHolidayList(params);
        dispatch({
            type: TNA_ORDER_LABDIP_HOLIDAY_LIST,
            payload: res.data,
        });
        dispatch(orderLabdipLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(orderLabdipLoading(false));
    }
};

// export const removePantoneSwatchByIndex = (index) => async (dispatch) => {

//     try {
//         dispatch(orderLabdipLoading(true));
//         dispatch({
//             type: REMOVE_TNA_ORDER_LABDIP_PANTONE_SWATCH_LIST,
//             payload: index,
//         });
//         dispatch(orderLabdipLoading(false));
//     } catch (err) {
//         console.log(err);
//         dispatch(orderLabdipLoading(false));
//     }
// };

// export const InsertOrderLabdipTNA = (mainValues) => async (dispatch) => {
//     let resData = "";
//     dispatch(orderLabdipLoading(true));
//     await OrderService.InsertOrderLabdipTNA(mainValues).then((res) => {
//         resData = res.data;
//         dispatch(orderLabdipLoading(false));
//     }).catch((err) => {
//         dispatch(orderLabdipLoading(false));
//         return Promise.reject(err);
//     });
//     return Promise.resolve(resData);
// }

// export const UpdateOrderLabdipTNA= (mainValues) => async (dispatch) => {
//     let resData = "";
//     dispatch(orderLabdipLoading(true));
//     await OrderService.UpdateOrderLabdipTNA(mainValues).then((res) => {
//         resData = res.data;
//         dispatch(orderLabdipLoading(false));
//     }).catch((err) => {
//         dispatch(orderLabdipLoading(false));
//         return Promise.reject(err);
//     });
//     return Promise.resolve(resData);
// }


export const getOrdLabdipTNASubmissionAndApproval = (orderLabdipTNAId, orderLabdipTNAProgramDetailId) => async (dispatch) => {
    let resData = "";

    dispatch(orderLabdipLoading(true));
    await OrderService.GetOrdLabdipTNASubmissionAndApproval(orderLabdipTNAId, orderLabdipTNAProgramDetailId).then((res) => {
        resData = res.data;
        dispatch({
            type: TNA_ORDER_LABDIP_SUBMISSION_AND_APPROVAL,
            payload: res.data,
        });
        dispatch(orderLabdipLoading(false));
    }).catch((err) => {
        dispatch(orderLabdipLoading(false));
        return Promise.reject(err);
    });
    return Promise.resolve(resData);
}


export const getOrderLapdipTNAProgramStatusList = (params) => async (dispatch) => {
    let resData = "";
    dispatch(orderLabdipLoading(true));
    await OrderService.GetOrdLapdipTNAProgramStatusList(params).then((res) => {
        resData = res.data;
        dispatch({
            type: ORDER_LABDIP_PROGRAM_STATUS_LIST,
            payload: res.data,
        });
        dispatch(orderLabdipLoading(false));
    }).catch((err) => {
        dispatch(orderLabdipLoading(false));
        return Promise.reject(err);
    });
    return Promise.resolve(resData);

};

export const loadOLDTNATaskOwnerList = (TaskHolderID, BuyerID, UserLoginID, SupplierIdList) => async (dispatch) => {
    let resData = [];
    try {
        if (TaskHolderID > 0) {
            const res = await OrderService.LoadOLDTNATaskOwnerList({ TaskHolderID: TaskHolderID, BuyerID: BuyerID, UserLoginID: UserLoginID, SupplierIdList: SupplierIdList });
            dispatch({
                type: OLD_TNA_TASK_OWNER_LIST,
                payload: res.data,
            });
            resData = res.data;
        } else {
            dispatch({
                type: OLD_TNA_TASK_OWNER_LIST,
                payload: [],
            });
            resData = [];
        }
    } catch (err) {
        console.log(err);
    }
    return Promise.resolve(resData);
};







