import React, { useState, useEffect, useRef, Fragment } from "react";
import Nodify from "../../Common/ReactNotification";
import { useSelector } from "react-redux";
import ReactNotification from 'react-notifications-component';
import BuyerService from "../../../services/Master/BuyerService";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import SelectCombobox from 'react-select';
import SearchRetain from "../../Common/SearchRetain";
import styleService from "../../../services/Style/styleService"
import packingTem from "../../../services/Master/packingtemservice"
import packingImage from "../../../services/Master/PackageImage"
import Reactselect from 'react-select';
import axios from "axios";
import $ from "jquery";
import Lightbox from "react-image-lightbox"
import PackingItemmaster from '../../quickMaster/_PackingItemQuick';
import PackingImage from '../../quickMaster/Packingimage';
import { Modal, Button } from "react-bootstrap";
import CommonService from "../../../services/Common/CommonService";
import ConfirmationPopup from "../../Common/ConfirmationPopup";
const EditPackingTemplate = (props) => {
    const [GetAllItemDetails, setAllItemDetails] = useState({ ComTempID: 0, BuyerID: 0, BuyerName: '', BrandID: 0, BrandName: '', TypeName: '' });
    const [InputFields, setInputFields] = useState([]);
    const [InputPackingImage, setInputPackingImage] = useState([]);
    const [getBuyerDetails, setBuyerDetails] = useState([]);
    const [getBuyerBrandDetails, setBuyerBrandDetails] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [getPackingItem, setPackingItem] = useState([]);
    const [getPackingimage, setPackingimage] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [submittedPCI, setSubmittedPCi] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [getImagename, setImagename] = useState();
    const [getPcImagename, setPcImagename] = useState();
    const [isOpenPcimage, setIsOpenPcimage] = useState(false);
    const [Modalpopup, setModalPopup] = useState(false);
    const [ModalpopupName, setModalPopupName] = useState('');
    const [Modalpopup2, setModalPopup2] = useState(false);
    const [ModalpopupName2, setModalPopupName2] = useState('');
    const values = [...InputFields];
    const Valuesimage = [...InputPackingImage];
    const { user: currentUser } = useSelector((state) => state.auth);
    const [getPage, setPage] = useState([]);
    const [getID, setID] = useState({ showPopupConfirm: false, Params: {} }); 

    function BindPackageimageValue(props) {

        let PackingInfoValue = { ComTempID: 0, BuyerID: 0, BuyerName: '', BrandID: 0, BrandName: '', TypeName: '' };
        if (props.location.state !== undefined) {
            if (props.location.state.params.length !== 0) {
                if (props.location.state.params.ComTempID !== 0) {
                    PackingInfoValue.ComTempID = props.location.state.params.comTempID;
                    PackingInfoValue.BuyerID = props.location.state.params.buyerID;
                    PackingInfoValue.BuyerName = props.location.state.params.buyerName;
                    PackingInfoValue.BrandID = props.location.state.params.brandID;
                    PackingInfoValue.BrandName = props.location.state.params.brandName;
                    PackingInfoValue.TypeName = props.location.state.params.typeName;
                    return PackingInfoValue;
                }
            }
        }
        else {
            return PackingInfoValue;
        }
    }
    useEffect(() => {
        let PackingTemplateinfo = BindPackageimageValue(props);
        setAllItemDetails(PackingTemplateinfo)
    }, [])
    useEffect(() => {
        showLoader();
        let BuyerDetail = [];
        let respected_Brand = [];
        BuyerService.LoadBuyerList(2).then((res) => {
            if (res.data) {
                res.data.map(x => {
                    BuyerDetail.push({
                        label: x.name,
                        value: x.id
                    })
                })

                setBuyerDetails(BuyerDetail)
            }
        })
        let packingTemItem = [];
        packingTem.GetPackageTemplateList(props.location.state.params.comTempID, 2).then((res) => {

            if (res.data) {
                res.data.map(x => {
                    packingTemItem.push({
                        PackageTemplateID: x.packageTemplateID,
                        PackItem: x.packItem,
                        PackItemName: x.packItemName,
                        FileName: x.fileName,
                        Filetype: x.filetype,
                        FilePath: x.filePath,
                        FileIndex: x.fileIndex
                    })
                })

                setInputFields(packingTemItem)
                hideLoader();
            }
        })
        let packingTemImage = [];
        packingTem.GetPackageTemplateList(props.location.state.params.comTempID, 3).then((res) => {

            if (res.data) {
                res.data.map(x => {
                    packingTemImage.push({
                        PackageTemplateImageID: x.packageTemplateImageID,
                        PackImage: x.packImage,
                        PackimageName: x.packimageName,
                        FileName: x.pciFileName,
                        Filetype: x.pciFiletype,
                        FilePath: x.pciFilePath,
                        FileIndex: x.pciFileIndex,
                        getImageCheck: x.getImageCheck
                    })
                })

                setInputPackingImage(packingTemImage)
            }
        })
        let PackingItem = [];
        packingTem.LoadPackageItem().then((res) => {
            if (res.data) {
                res.data.map((x) => {
                    PackingItem.push({
                        label: x.label,
                        value: x.value
                    })
                })
                setPackingItem(PackingItem);
            }
        })
        let Packingimage = [];
        packingTem.LoadPackageImage().then((res) => {
            if (res.data) {
                res.data.map((x) => {
                    Packingimage.push({
                        label: x.label,
                        value: x.value
                    })
                })
                setPackingimage(Packingimage);
            }
        })
        styleService.LoadBrandThemeStoryEndCustomer(6, 0).then((res) => {
            if (res.data) {
                respected_Brand = res.data.filter(x => (x.buyerID === props.location.state.params.buyerID) && (x.buyerMapID === 1));
            }
            setBuyerBrandDetails(respected_Brand);
        })

    }, [])


    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),

    }

    function handlePopupClose() {
        setModalPopup(false)
        setModalPopupName('')
        setModalPopup2(false)
        setModalPopupName2('')
    }


    var ImgClassName = "upload-btn-wrapper2"
    function handleAddFields(index) {
        setSubmitted(false)
        let isvalid = true;
        if (values[index].PackItem === 0 || values[index].PackItem === undefined) {
            isvalid = false;
            setSubmitted(true);
            Nodify('Warning!', 'warning', 'Please fill the mandantory(*) fields');
        }
        else if (values[index].FileName === '' || values[index].FileName === undefined) {
            isvalid = false;
            Nodify('Warning!', 'warning', 'Please Upload The image');
        }
        if (isvalid) {
            // Create a new row
            const newRow = { id: Date.now() };

            // Update the rows array
            setInputFields((prevRows) => {
                // Insert the new row at the specified index
                const updatedRows = [...prevRows.slice(0, index + 1), newRow, ...prevRows.slice(index + 1)];

                // Increment the indices of rows following the insertion point
                const updatedRowsWithIds = updatedRows.map((row, i) => ({ ...row, id: i }));

                return updatedRowsWithIds;
            });
            setSubmitted(false);
            // values.push({ PackageTemplateID: 0, PackItem: 0, PackItemName: '', FileName: '', Filetype: '', FilePath: '', FileIndex: '' })
        }
        //setInputFields(values);
    }

    function handleMasterPackageImagetmp() {
        setModalPopup2(true);
        setModalPopupName2('Package Image')
    }

    function handleMasterPackagetmplate(params) {
       
        setModalPopup(true);
        setModalPopupName('Package Item')
    }

    function handleRemoveFields(index) {
        values.splice(index, 1);
        setInputFields(values);
    }

    function CheckChildDuplicate(index, event, FieldName) {

        for (var j = 0; j < (values.length); j++) {
            if (j !== index) {
                if (values[j].PackItem === values[index].PackItem) {
                    values[index].PackItem = 0;
                    values[index].PackItemName = '';
                    setSubmitted(true)
                    setInputFields(values);
                    Nodify('Warning!', 'warning', 'These details are already exists');
                    return false;
                }

            }
        }
    }

    function CheckPCIDuplicate(index) {

        for (var j = 0; j < (Valuesimage.length); j++) {
            if (j !== index) {
                if (Valuesimage[j].PackImage === Valuesimage[index].PackImage) {
                    Valuesimage[index].PackImage = 0;
                    Valuesimage[index].PackimageName = '';
                    Valuesimage[index].getImageCheck = '0';
                    setSubmittedPCi(true)
                    setInputPackingImage(Valuesimage);
                    Nodify('Warning!', 'warning', 'These details are already exists');
                    return false;
                }

            }
        }
    }

    function PackageImageCallback(value) {
        if (value === true) {
            let Packingimage = [];
            packingTem.LoadPackageImage().then((res) => {
                if (res.data) {
                    res.data.map((x) => {
                        Packingimage.push({
                            label: x.label,
                            value: x.value
                        })
                    })
                    setPackingimage(Packingimage);
                }
            })
            setModalPopup2(false)
        }
        else {
            setModalPopup(false)
        }
    }

    function PackageCallback(value) {
        if (value === true) {
            let PackingItem = [];
            packingTem.LoadPackageItem().then((res) => {
                if (res.data) {
                    res.data.map((x) => {
                        PackingItem.push({
                            label: x.label,
                            value: x.value
                        })
                    })
                    setPackingItem(PackingItem);
                }
            })
            setModalPopup(false)
        } else {
            setModalPopup(false)
        }
    }


    function handleInputChange(index, event, FieldName) {
        if (FieldName === "PackItem") {
            if (event !== null) {
                values[index].PackItem = event.value;
                values[index].PackItemName = event.label;
            } else {
                values[index].PackItem = 0;
                values[index].PackItemName = '';
            }
        }
        setInputFields(values);
    }

    function openFileUpload() {
        $('#upload-button').trigger('click');
    }

    function ViewFile(event, filename, index) {
        // event.preventDefault();
        setIsOpen(true);
        setImagename(filename);
    }

    function deleteFile(index) {
        values[index].FileName = '';
        values[index].FilePath = "";
        values[index].FileIndex = '';
        values[index].Filetype = '';
        setInputFields(values);
    }

    //PCI Stands For Packing Image Identity for Tab 2
    function handleRemoveFieldsPCI(index, value) {
       
        let Params = {
            Operation: 139,
            Id: value.PackImage,
        }

        CommonService.CheckMappedItem(Params).then(res => {
            if (res.data.outputResult === "-2") {
                let Title = "Warning!";
                let message = "This item is already mapped.";
                let type = "warning";
                Nodify(Title, type, message);
            }
            else {
                Valuesimage.splice(index, 1);
            }

            setInputPackingImage(Valuesimage);
        });
    }

    function handleAddFieldsPCI(index) {

        setSubmittedPCi(false)
        let isvalid = true;
        if (Valuesimage[index].PackImage === 0) {
            isvalid = false;
            setSubmittedPCi(true);
            Nodify('Warning!', 'warning', 'Please fill the mandantory(*) fields');
        }
        else if (Valuesimage[index].getImageCheck === "2" && (Valuesimage[index].FileName === '' || Valuesimage[index].FileName === undefined)) {
            isvalid = false;
            Nodify('Warning!', 'warning', 'Please Upload The image');
        }
        if (isvalid) {
            // Create a new row
            const newRow = { id: Date.now() };

            // Update the rows array
            setInputPackingImage((prevRows) => {
                // Insert the new row at the specified index
                const updatedRows = [...prevRows.slice(0, index + 1), newRow, ...prevRows.slice(index + 1)];

                // Increment the indices of rows following the insertion point
                const updatedRowsWithIds = updatedRows.map((row, i) => ({ ...row, id: i }));

                return updatedRowsWithIds;
            });
            setSubmitted(false);
            //Valuesimage.push({ PackageTemplateImageID: 0, PackImage: 0, PackimageName: '', FileName: '', Filetype: '', FilePath: '', FileIndex: '' })
        }
    }

    async function UploadImages(index, e) {
        ;
        const file = e.target.files[0];
        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png'];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#FileUpload').val("");
            }

            else {
                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "PackingTemplate");
                try {
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                    values[index].FileName = file.name;
                    values[index].FilePath = "Images/PackingTemplate/" + file.name;
                    values[index].FileIndex = index.toString();
                    values[index].Filetype = file.type;
                    setInputFields(values);
                    $('#FileUpload').val("");

                } catch (ex) {
                    console.log(ex);
                }
            }
        }
    }

    function openFileUpload2() {
        $('#upload-button2').trigger('click');
    }

    function ViewFilePCI(event, filename, index) {
        setIsOpenPcimage(true);
        setPcImagename(filename);
    }
    function deleteFilePCI(index) {
        Valuesimage[index].FileName = '';
        Valuesimage[index].FilePath = "";
        Valuesimage[index].FileIndex = '';
        Valuesimage[index].Filetype = '';
        setInputPackingImage(Valuesimage);
    }

    function handleInputPackImageChange(index, event, FieldName) {

        if (FieldName === "PackImage") {
            if (event !== null) {
                Valuesimage[index].PackImage = event.value;
                Valuesimage[index].PackimageName = event.label;
                CheckPCIImage(event, index)
            } else {
                Valuesimage[index].PackImage = 0;
                Valuesimage[index].PackimageName = '';
                Valuesimage[index].getImageCheck = '0';
                setInputPackingImage(Valuesimage);
            }

        }
        //setInputPackingImage(Valuesimage);
    }

    async function UploadImages2(index, e) {
        const file = e.target.files[0];
        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png'];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#FileUpload').val("");
            }

            else {
                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "PackingTemplate2");
                try {
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                    Valuesimage[index].FileName = file.name;
                    Valuesimage[index].FilePath = "Images/PackingTemplate2/" + res.data + '_' + file.name;
                    Valuesimage[index].FileIndex = index.toString();
                    Valuesimage[index].Filetype = file.type;
                    setInputPackingImage(Valuesimage);
                    $('#FileUpload').val("");

                } catch (ex) {
                    console.log(ex);
                }
            }
        }
    }

    async function CheckPCIImage(event, index) {

        let isvalid = true;
        await packingImage.GetPackageImageList(1, event.value).then((res) => {
            if (res.data) {
                isvalid = res.data.inputPackageimageList1.filter(x => x.isSkuimageID === 1)
            }
            if (isvalid.length > 0) {
                Valuesimage[index].getImageCheck = "1";
                setInputPackingImage(Valuesimage);
            } else {
                Valuesimage[index].getImageCheck = "2";
                setInputPackingImage(Valuesimage);
            }
        })
    }

    function ResetOperation(e) {
        if (e === "Back") {
            props.history.push('/PackageGroup');
        } else {
            window.location.reload();
        }
    }

    function PageRedirect(Func) {
        props.history.push({ pathname: "/PackageGroup", state: { message: Func } });
    }
    const Delcallback = (value) => {
        if (value === true) {
            setID({ showPopupConfirm: false, Params: [] });
            
        } 
    }

    function SavePackingTemplate() {
        let PacktemIteminformation = [];
        let Packtemimageinformation = [];
        setButtonLoader(true);
        let isvalid = true;
        if (GetAllItemDetails.BuyerID === 0 || GetAllItemDetails.BrandID === 0 || GetAllItemDetails.TypeName === '') {
            setButtonLoader(false);
            setSubmitted(true);
            isvalid = false;
            Nodify('Warning!', 'warning', 'Please fill This mandantory(*) fields.');
            return false;
        }
        for (var i = 0; i < values.length; i++) {
            if ((values[i].PackItem === 0 || values[i].PackItem === undefined) || (values[i].FileName === '' || values[i].FileName === undefined)) {
                setButtonLoader(false);
                setSubmitted(true);
                isvalid = false;
                Nodify('Warning!', 'warning', 'Please fill This mandantory(*) fields In Pack Item Tab1.');
                return false;
            }
        }
        for (var i = 0; i < Valuesimage.length; i++) {
            if (Valuesimage[i].PackImage === 0 || (Valuesimage[i].getImageCheck === "2" && (Valuesimage[i].FileName === '' || Valuesimage[i].FileName === undefined))) {
                setButtonLoader(false);
                setSubmittedPCi(true);
                isvalid = false;
                Nodify('Warning!', 'warning', 'Please fill This mandantory(*) fields In Required Packing Images.');
                return false;
            }
        }
        if (isvalid) {

            let inputMaster = {
                Operation: 2, PackingComID: props.location.state.params.comTempID,
                BuyerID: GetAllItemDetails.BuyerID, BuyerName: GetAllItemDetails.BuyerName, BrandID: GetAllItemDetails.BrandID
                , BrandName: GetAllItemDetails.BrandName, TypeName: GetAllItemDetails.TypeName, PacktemIteminformation: values,
                Packtemimageinformation: Valuesimage, CreatedBy: currentUser.employeeinformationID
            }
            packingTem.InsertUpdatePackageTemplateGrd(inputMaster).then((res) => {
                var page = "Remove";
                let params = { tabPage: "EditPackageTemplate", Func: "Edit" };
                SearchRetain(page);

                let Func = 'Add';
                res.data.tabPage = getPage; 
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    setID({ showPopupConfirm: true, Params: params, });
                } else if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    setID({ showPopupConfirm: true, Params:params });
                }
                else if (res.data.outputResult === "0") {
                    
                    Nodify('Warning!', 'warning', "Error Occured!");
                }
                res.data.Func = Func;
                setButtonLoader(false);
            })
        }

    }
    return (
        <Fragment>
            <div className="page-body">
                <ReactNotification />
                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <div className="widget flat radius-bordered">
                            <div className="widget-header heading_box_style">
                                <h3 className="heading_style_common">Edit Package Template</h3>
                            </div>

                            <div className="widget-body">
                                <div className="row">


                                    <div className="col-lg-3">
                                        <label>Buyer<span className="text-danger">*</span></label>
                                        <SelectCombobox className="BuyerPackingTemplate" name="Buyer" id={"BuyerPackingTemplate"}
                                            isDisabled={true}
                                            isLoading={false}
                                            isSearchable={true}
                                            isClearable={true}
                                            autocomplete='off'
                                            placeholder="Select Buyer Detail"
                                            styles={submitted && GetAllItemDetails.BuyerID === 0 ? styles : ''}
                                            //onChange={event => handleDropdownChange(event, "Buyer")}
                                            options={getBuyerDetails}
                                            value={getBuyerDetails.filter(function (option) {
                                                return option.value === GetAllItemDetails.BuyerID;
                                            })}

                                        />
                                    </div>
                                    <div className="col-lg-3">
                                        <label>Brand<span className="text-danger">*</span></label>
                                        <SelectCombobox className="BuyerPackingTemplate" name="Brand" id={"BuyerPackingTemplate"}
                                            isDisabled={true}
                                            isLoading={false}
                                            isSearchable={true}
                                            isClearable={true}
                                            autocomplete='off'
                                            //onBlur={() => CheckDuplicate(index)}
                                            placeholder="Select Brand Detail"
                                            styles={submitted && GetAllItemDetails.BrandID === 0 ? styles : ''}
                                            //onChange={event => handleDropdownChange(event, "Brand")}
                                            options={getBuyerBrandDetails}
                                            value={getBuyerBrandDetails.filter(function (option) {
                                                return option.value === GetAllItemDetails.BrandID;
                                            })}

                                        />
                                    </div>
                                    <div className="col-lg-3">
                                        <label>Packing Plan Name<span className="text-danger">*</span></label>
                                        <input type="text"
                                            className="form-control"
                                            id={"TypeName"}
                                            name="TypeName"
                                            placeholder="Enter Packing Plan Name"
                                            maxLength="50"
                                            autoComplete="off"
                                            value={GetAllItemDetails.TypeName}
                                            //onChange={event => handleDropdownChange(event, "TypeName")}
                                            //onBlur={() => CheckDuplicate(index)}
                                            style={{ border: submitted && GetAllItemDetails.TypeName === "" ? '1px solid red' : '' }}
                                            autoFocus
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <br />

                            </div>

                        </div>
                    </div>
                </div>


                <div className="row" style={{ marginTop: '0%' }}>
                    <div className="col-md-12">
                        <div className="tabbable">
                            <ul className="nav nav-tabs nav-justified" id="myTab5">
                                <li className="active">
                                    <a data-toggle="tab" href="#idSubmitInformation">
                                        <h5>Package Item</h5>
                                    </a>
                                </li>

                                <li className="" >
                                    <a data-toggle="tab" href="#idOrderInformation" >
                                        <h5>Required Package Images</h5>
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div id="idSubmitInformation" className="tab-pane in active" style={{ width: "100%" }}>
                                    <form>
                                        <div >
                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: "17%" }}><button type='button' className="glyphicon glyphicon-plus AddPlusIconTemplate"
                                                            onClick={() => handleMasterPackagetmplate()}
                                                            title="Add Size">
                                                        </button>Package Item Name<span className="text-danger">*</span></th>
                                                        <th style={{ width: "17%" }}>Image<span className="text-danger">*</span></th>
                                                        <th style={{ width: "10%" }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        InputFields.map((InputValue, index) => (<Fragment >
                                                            <tr>
                                                                <td>
                                                                    <Reactselect className="basic-single" name="Brand" id={"Brand"}
                                                                        isDisabled={false}
                                                                        isLoading={false}
                                                                        isSearchable={true}
                                                                        isClearable={true}
                                                                        autocomplete='off'
                                                                        onBlur={() => CheckChildDuplicate(index)}
                                                                        placeholder="Select Pack Item"
                                                                        styles={submitted && (InputValue.PackItem === 0 || InputValue.PackItem === undefined) ? styles : ''}
                                                                        onChange={event => handleInputChange(index, event, "PackItem")}
                                                                        options={getPackingItem}
                                                                        value={getPackingItem.filter(function (option) {
                                                                            return option.value === InputValue.PackItem;
                                                                        })}
                                                                    />
                                                                </td>

                                                                <td>
                                                                    <div className="form-group">
                                                                        <div className={ImgClassName}
                                                                            htmlFor="upload-button"
                                                                        >
                                                                            <img src={(InputValue.FilePath === "" || InputValue.FilePath === undefined) ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + InputValue.FilePath} onClick={openFileUpload}
                                                                                title={'new'} alt="Picture" style={{ marginBottom: '5px', height: '100px', width: '20%', border: "1px solid #d5d5d5" }} />
                                                                            <input type="file" id="upload-button" accept=".jpg,.jpeg,.png" onChange={(event) => UploadImages(index, event)} value='' title={InputValue.FileName} />
                                                                            {
                                                                                InputValue.FileName !== "assets/img/uploadimage.jpg" && (
                                                                                    <div style={
                                                                                        {
                                                                                            marginTop: '8px',
                                                                                            float: 'right',
                                                                                            bottom: '10px',
                                                                                            position: 'relative',
                                                                                        }
                                                                                    }>
                                                                                        {
                                                                                            InputValue.FilePath !== "" && InputValue.FilePath !== "assets/img/uploadimage.jpg" ?
                                                                                                <i className="btn btn-info fa fa-eye Packing" title="View Logo"
                                                                                                    onClick={
                                                                                                        event => ViewFile(event, InputValue.FilePath, index)
                                                                                                    }></i>
                                                                                                : ''

                                                                                        }
                                                                                        {InputValue.FilePath !== "" && InputValue.FilePath !== "assets/img/uploadimage.jpg" ?
                                                                                            <i className="btn btn-danger fa fa-trash-o Packingdel" title="Delete Logo"
                                                                                                onClick={
                                                                                                    event => deleteFile(index)
                                                                                                }></i>
                                                                                            : ''
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                            }

                                                                        </div>

                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {InputFields.length !== 1 && (
                                                                        <button type="button"
                                                                            className="btn btn-xs btn-danger" title="Delete packing Image"
                                                                            onClick={() => handleRemoveFields(index)}
                                                                        >
                                                                            <i className="fa fa-trash-o"></i>
                                                                        </button>
                                                                    )}
                                                                    &nbsp;
                                                                    {/* {InputFields.length === (index + 1) && ( */}
                                                                    <button type="button" className="btn btn-xs btn-success" title="Add packing Image"
                                                                        onClick={() => handleAddFields(index)}
                                                                    >
                                                                        <i className="fa fa-plus"></i>
                                                                    </button>
                                                                    {/* )} */}
                                                                </td>
                                                            </tr>
                                                            {
                                                                <Modal dialogClassName="modalpackitem" show={Modalpopup} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handlePopupClose()}>
                                                                    <Modal.Header closeButton>
                                                                        <Modal.Title>Add {ModalpopupName}</Modal.Title>
                                                                    </Modal.Header>
                                                                    <Modal.Body style={{ height: '564px', overflowY: 'scroll', overflowX: 'hidden' }}>
                                                                        <PackingItemmaster
                                                                            props={props}
                                                                            packagecallback={PackageCallback}
                                                                        />
                                                                    </Modal.Body>
                                                                </Modal>
                                                            }
                                                        </Fragment>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </form>
                                </div>
                                <div id="idOrderInformation" className="tab-pane" style={{ width: "100%" }}>
                                    <form>
                                        <div >
                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: "17%" }}><button type='button' className="glyphicon glyphicon-plus AddPlusIconTemplate"
                                                            onClick={() => handleMasterPackageImagetmp()}
                                                            title="Add Size">
                                                        </button>Package Image Name<span className="text-danger">*</span></th>
                                                        <th style={{ width: "17%" }}>Image<span className="text-danger">*</span></th>
                                                        <th style={{ width: "10%" }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        InputPackingImage.map((InputValue, index) => (<Fragment >
                                                            <tr>
                                                                <td>
                                                                    <Reactselect className="basic-single" name="Brand" id={"Brand"}
                                                                        isDisabled={false}
                                                                        isLoading={false}
                                                                        isSearchable={true}
                                                                        isClearable={true}
                                                                        autocomplete='off'
                                                                        onBlur={() => CheckPCIDuplicate(index)}
                                                                        placeholder="Select Pack Item"
                                                                        styles={submittedPCI && (InputValue.PackImage === 0 || InputValue.PackImage === undefined) ? styles : ''}
                                                                        onChange={event => handleInputPackImageChange(index, event, "PackImage")}
                                                                        options={getPackingimage}
                                                                        value={getPackingimage.filter(function (option) {
                                                                            return option.value === InputValue.PackImage;
                                                                        })}
                                                                    />
                                                                </td>

                                                                <td>
                                                                    {InputValue.getImageCheck === '2' ? <div className="form-group">
                                                                        <div className={ImgClassName}
                                                                            htmlFor="upload-button2"
                                                                        >
                                                                            <img src={(InputValue.FilePath === "" || InputValue.FilePath === undefined) ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + InputValue.FilePath} onClick={openFileUpload2}
                                                                                title={InputValue.FileName} alt="Picture" style={{ marginBottom: '5px', height: '100px', width: '20%', border: "1px solid #d5d5d5" }} />
                                                                            <input type="file" id="upload-button2" accept=".jpg,.jpeg,.png" onChange={(event) => UploadImages2(index, event)} value='' title={InputValue.FileName} />
                                                                            {
                                                                                InputValue.FileName !== "assets/img/uploadimage.jpg" && (
                                                                                    <div style={
                                                                                        {
                                                                                            marginTop: '8px',
                                                                                            float: 'right',
                                                                                            bottom: '10px',
                                                                                            position: 'relative',
                                                                                        }
                                                                                    }>
                                                                                        {
                                                                                            InputValue.FilePath !== "" && InputValue.FilePath !== "assets/img/uploadimage.jpg" ?
                                                                                                <i className="btn btn-info fa fa-eye Packing" title="View Logo"
                                                                                                    onClick={
                                                                                                        event => ViewFilePCI(event, InputValue.FilePath, index)
                                                                                                    }></i>
                                                                                                : ''

                                                                                        }
                                                                                        {InputValue.FilePath !== "" && InputValue.FilePath !== "assets/img/uploadimage.jpg" ?
                                                                                            <i className="btn btn-danger fa fa-trash-o Packingdel" title="Delete Logo"
                                                                                                onClick={
                                                                                                    event => deleteFilePCI(index)
                                                                                                }></i>
                                                                                            : ''
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                            }

                                                                        </div>

                                                                    </div> : InputValue.getImageCheck === '1' ? <span className="text-danger">Info: SKU Front and Back images showed in the Package image on Final Inspection.</span> : ''
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {InputPackingImage.length !== 1 && (<button type="button"
                                                                        className="btn btn-xs btn-danger" title="Delete packing Images"
                                                                        onClick={() => handleRemoveFieldsPCI(index, InputValue)}
                                                                    >
                                                                        <i className="fa fa-trash-o"></i>
                                                                    </button>
                                                                    )}
                                                                    &nbsp;
                                                                    {/* {InputPackingImage.length === (index + 1) && ( */}
                                                                    <button type="button" className="btn btn-xs btn-success" title="Add packing Image"
                                                                        onClick={() => handleAddFieldsPCI(index)}
                                                                    >
                                                                        <i className="fa fa-plus"></i>
                                                                    </button>
                                                                    {/* )} */}
                                                                </td>
                                                            </tr>
                                                            {
                                                                <Modal dialogClassName="modalpackitem" show={Modalpopup2} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handlePopupClose()}>
                                                                    <Modal.Header closeButton>
                                                                        <Modal.Title>Add {ModalpopupName2}</Modal.Title>
                                                                    </Modal.Header>
                                                                    <Modal.Body style={{ height: '264px', overflowY: 'scroll', overflowX: 'hidden' }}>
                                                                        <PackingImage
                                                                            packageImagecallback={PackageImageCallback}
                                                                        />
                                                                    </Modal.Body>
                                                                </Modal>
                                                            }
                                                        </Fragment>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div> {
                    isOpen && (

                        <Lightbox mainSrc={window.$APIBaseURL + getImagename}
                            alt="bg image"
                            onCloseRequest={
                                () => setIsOpen(false)
                            } />
                    )
                } </div>
                <div> {
                    isOpenPcimage && (

                        <Lightbox mainSrc={window.$APIBaseURL + getPcImagename}
                            alt="bg image"
                            onCloseRequest={
                                () => setIsOpenPcimage(false)
                            } />
                    )
                } </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>
                            <span className="btn btn-primary"
                                onClick={
                                    () => ResetOperation("Back")
                                }>
                                <i className="fa fa-arrow-circle-left"></i>
                                &nbsp;Back</span>
                            <span className="btn btn-danger"
                                onClick={
                                    () => ResetOperation()
                                }>
                                <i className="fa fa-times"></i>
                                &nbsp;Reset</span>
                            <button type="button" className="btn btn-success" disabled={buttonLoader}
                                onClick={SavePackingTemplate}>

                                <i className="fa fa-check right"></i>
                                &nbsp;Update</button>
                        </div>
                    </div>
                </div>
                {
                    getID.showPopupConfirm ? <ConfirmationPopup props={props} data={
                        getID.Params
                    }
                        deleteCallback={Delcallback}
                        showpopup={true}
                    /> : null
                }
            </div>
            {loader}
        </Fragment>
    )
}

export default EditPackingTemplate