import React, { useState, useEffect, useMemo } from "react";
import ReactNotification from 'react-notifications-component';
import { TableHeader, Pagination, Search, PageCount } from "../../Datatable";
import { useDispatch, useSelector } from "react-redux";
import CommonService from '../../../services/Common/CommonService';
import Nodify from "../../Common/ReactNotification";
import SearchRetain from "../../Common/SearchRetain";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import SweetAlertPopup from '../../Common/SweetAlertPopup';
import { Redirect } from 'react-router-dom';
import TestingReortTemplateService from "../../../services/Master/TestingReortTemplateService";
import SelectCombobox from 'react-select';
import TestingReportDetail from "../../../services/Master/TestingReportDetail";
import BuyCusWarService from "../../../services/Master/BuyCusWarService";
import styleService from "../../../services/Style/styleService"
import Warehouse from "../../../services/Master/WarehouseService"

import axios from "axios";
import $ from "jquery";
import Lightbox from "react-image-lightbox"

const BuyCusWarehouseEdit = (props) => {
 const [buttonLoader, setButtonLoader] = useState(false);
 const [getBuyCusWarehouseList, SetBuyCusWarhouseList] = useState([]);// For TemplateID based matched value ItemID and ItemNAme
 const [getHeaderBuyerDetails, setHeaderBuyerDetails] = useState([]);// For Header buyer Details
 const [submitted, setSubmitted] = useState(false);
 const [getEndCustomerDetails, setEndCustomerdetails] = useState([]);
 const [sorting, setSorting] = useState({ field: "", order: "" });
 const { user: currentUser } = useSelector((state) => state.auth);
 const [getwarehouse, setwarehouse] = useState([]);
 const [isOpenimg, setIsOpenimg] = useState(false);
 const [getImagename, setImagename] = useState();

 const headers = [
  { name: <span>Warehouse Name<span className="text-danger">*</span></span>, sortable: false },
  { name: <span>Warehouse Image<span className="text-danger">*</span></span>, sortable: false },
  {
   name: "Action", field: "", sortable: false,
   width: "13%"
  },
 ];


 useEffect(() => {
  let cuswarehouseinformation = [];


  BuyCusWarService.getBuyCusWarList(2, props.location.state.params.buyerID).then(async (res) => {
  ;

   if (res.data) {

    res.data.item3.map((y, index) => {
     cuswarehouseinformation.push({
      CustomerID: y.customerID,
      CustomerName: y.customerName,
      WarehouseDetailslist: []
     })

     res.data.item2.filter(x => x.customerID === y.customerID).map((x, i) => {
      cuswarehouseinformation[index].WarehouseDetailslist.push({
       buyCusWarID: x.buyCusWarID,
       warehouseID: x.warehouseID,
       warehousename: x.warehouseName,
       fileName: x.fileName,
       filePath: x.filePath,
       fileType: x.fileType,
       fileIndex: x.fileIndex,
      })


     })

    })

   }




   SetBuyCusWarhouseList(cuswarehouseinformation)

   let buyerdetail = []

   if (res.data) {

    res.data.item1.map((x) => {
     buyerdetail.push({
      Value: x.buyerID,
      label: x.buyerName,
     })
    })
   }
   setHeaderBuyerDetails(buyerdetail)

  }, [])


  styleService.LoadBrandThemeStoryEndCustomer(5, props.location.state.params.buyerID).then((res) => {
  ;
   if (res.data) {
    let CustomerList = res.data;
    let respected_Customer = CustomerList.filter(x => (x.buyerMapID === 2));
    setEndCustomerdetails(respected_Customer);
   }
  })

  Warehouse.GetWarehouseList(2, props.location.state.params.buyerID).then((res) => {
   let finalwarehouse = []
   if (res.data) {
    res.data.warehouseEdt.map(x => {
     finalwarehouse.push({
      label: x.warehousename,
      value: x.warehouseID
     })
    })
    setwarehouse(finalwarehouse)
   }
  })

 }, [])

 const handleAddCusFields = (event, index) => {
 ;
  const values = [...getBuyCusWarehouseList];
  if (values[index].CustomerID !== 0) {
   values.push({
    CustomerID: 0,
    CustomerName: "",
    WarehouseDetailslist: [{
     buyCusWarID: 0,
     warehouseID: 0,
     warehousename: "",
     fileName: "",
     filePath: "",
     fileType: "",
     fileIndex: "",
    }]
   });
   SetBuyCusWarhouseList(values);
   setSubmitted(false);
  }
  else {
   Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
   setSubmitted(true);
  }
 };








 const handleAddFields = (event, index, childIndex) => {
 ;
  const values = [...getBuyCusWarehouseList];

  values[childIndex].WarehouseDetailslist.push({
   buyCusWarID: 0,
   warehouseID: 0,
   warehousename: "",
   fileName: "",
   filePath: "",
   fileType: "",
   fileIndex: "",
  });
  SetBuyCusWarhouseList(values);
  setSubmitted(false);

 }




 const handleRemoveCusFields = (index, Childindex) => {
 ;
  const values = [...getBuyCusWarehouseList];
  values.splice(index, 1);
  SetBuyCusWarhouseList(values);
 };

 const handleRemoveFields = (index, childindex) => {
 ;

  const values = [...getBuyCusWarehouseList]
  values[index].WarehouseDetailslist.splice(childindex, 1)
  SetBuyCusWarhouseList(values)

 };
 const ResetOperation = (e) => {
  if (e === "Back") {
   props.history.push('/BCWList');
  } else {
   window.location.reload();
  }
 }


 let styles = {
  control: styles => ({ ...styles, border: '1px solid red' }),

 }


 const ViewFile = (event, filename) => { // event.preventDefault();
  setIsOpenimg(true);
  setImagename(filename);
 }
 const filevals = [...getBuyCusWarehouseList]
 const RemoveImages = async (index, Childindex) => {

debugger;
  filevals[index].WarehouseDetailslist[Childindex].fileName = ""
  filevals[index].WarehouseDetailslist[Childindex].fileType = ""
  filevals[index].WarehouseDetailslist[Childindex].filePath = ""
  filevals[index].WarehouseDetailslist[Childindex].fileIndex = ""

  SetBuyCusWarhouseList(filevals);
 }




 const AppendFiles = async (e, index, childIndex) => {
 ;
  let data = [...getBuyCusWarehouseList]
  const file = e.target.files[0];
  if (file !== null && file !== '' && file !== undefined) {
   const fileType = file['type'];
   const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword', "application/vnd.ms-excel",
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
   if (!validImageTypes.includes(fileType)) {
    Nodify('Warning!', 'warning', 'Invalid file format selected');
    $('#FileUpload').val("");
   }
   else {
    const formData = new FormData();
    formData.append("FormFile", file);
    formData.append("FileName", file.name);
    formData.append("Page", "ReportStatus");
    try {
     const res = await axios.post(window.$APIBaseURL + "api/file", formData);
     data[index].WarehouseDetailslist[childIndex].fileName = res.data + "_" + file.name
     data[index].WarehouseDetailslist[childIndex].filePath = "Images/Report/" + file.name
     data[index].WarehouseDetailslist[childIndex].fileType = file.type
     data[index].WarehouseDetailslist[childIndex].fileIndex = index.toString()
     SetBuyCusWarhouseList(data);
     $('#FileUpload').val("");
    } catch (ex) {
     console.log(ex);
    }
   }
  }
 }

 const handleDropdownChange = (event, FieldName, index) => {
 ;
  const values = [...getBuyCusWarehouseList]
  let inputText = ""
  if (FieldName === "Customer") {
   if (event !== null) {
    values[index].CustomerID = event.value;
    values[index].CustomerName = event.label;
    inputText = event.value
   } else {
    values[index].CustomerID = 0;
    values[index].CustomerName = ''
   }

  ;
   if (values.length > 1)
    for (var j = 0; j < (getBuyCusWarehouseList.length); j++) {
     if (j !== index) {
      if (values[j].CustomerID === values[index].CustomerID) {

       values[index].CustomerID = 0;
       SetBuyCusWarhouseList(values);
       Nodify('Warning!', 'warning', 'This Customer is already exist in this list.');
       return false;
      }

     }
    }
  }
  SetBuyCusWarhouseList(values)
 };







 const handleInputChange = (e, index, FieldName, childIndex) => {
 ;
  const values = [...getBuyCusWarehouseList]
  if (FieldName === "WarehouseName") {
   if (e !== null) {
    values[index].WarehouseDetailslist[childIndex].warehouseID = e.value
    values[index].WarehouseDetailslist[childIndex].warehousename = e.label
   }
   else {
    values[index].WarehouseDetailslist[childIndex].warehouseID = 0
    values[index].WarehouseDetailslist[childIndex].warehousename = ''
   }
  }
  SetBuyCusWarhouseList(values)

  if (values[index].WarehouseDetailslist.length >= 1)
   for (var j = 0; j < (getBuyCusWarehouseList[index].WarehouseDetailslist.length); j++) {
    if (j !== childIndex) {
     if (values[index].WarehouseDetailslist[j].warehouseID === values[index].WarehouseDetailslist[childIndex].warehouseID) {

      values[index].WarehouseDetailslist[childIndex].warehouseID = 0;
      values[index].WarehouseDetailslist[childIndex].warehousename = "";
      SetBuyCusWarhouseList(values);
      Nodify('Warning!', 'warning', 'This warehouse is already exist in this list.');
      setSubmitted(true);
      return false;
     }

    }
   }

 }
 const PageRedirect = (Func) => {
  props.history.push({
   pathname: "/BCWList",
   state: {
    message: Func,
   },
  }
  );
 };


 const SaveTemplateDetials = (e) => {
 ;
  setButtonLoader(true);
  e.preventDefault();
  for (var i = 0; i < (getBuyCusWarehouseList.length); i++) {
   if (getBuyCusWarehouseList[i].CustomerID === 0) {
    Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
    setSubmitted(true);
    setButtonLoader(false);
    return false;
   }
  }

  let cuswarehouseinformation = [];

  getBuyCusWarehouseList.map((Info, i) => {
   Info.WarehouseDetailslist.map((x) => {
    cuswarehouseinformation.push({

     CustomerID: Info.CustomerID,
     CustomerName: Info.CustomerName,
     buyCusWarID: x.buyCusWarID,
     warehouseID: x.warehouseID,
     warehousename: x.warehousename,
     fileName: x.fileName,
     filePath: x.filePath,
     fileType: x.fileType,
     fileIndex: x.fileIndex,
    });
   });

  }
  );


 ;
  for (var j = 0; j < (cuswarehouseinformation.length); j++) {
   if (cuswarehouseinformation[j].warehouseID === 0 || cuswarehouseinformation[j].fileName === "") {
    Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
    setSubmitted(true);
    setButtonLoader(false);
    return false;
   }
  }


  let InputMaster = { Operation: 2, BuyerID: props.location.state.params.buyerID, BuyerName: props.location.state.params.buyerName, cusWarehouseinfo: cuswarehouseinformation, Createdby: currentUser.employeeinformationID }
  BuyCusWarService.InsertBuyCusWarehouse(InputMaster).then(res => {

   var page = "Remove";
   SearchRetain(page);
   let Func = 'Add';
   if (res.data.outputResult === "1") {
    Func = 'Add';
    PageRedirect(Func);
   } else if (res.data.outputResult === "2") {
    Func = 'Edit';
    PageRedirect(Func);
   }
   setButtonLoader(false);
  });

 }



 return (
  <div className="page-body">
   <ReactNotification />
   <div className="row">
    <div className="col-lg-12 col-sm-12 col-xs-12">
     <div className="widget flat radius-bordered">
      <div className="widget-header heading_box_style">
             <h3 className="heading_style_common">Edit Buyer Customer Warehouse </h3>
      </div>

      <div className="widget-body">
       <div className="row">
        <div className="col-lg-3">
         <label>Buyer Name<span className="text-danger">*</span></label>
         <SelectCombobox className="basic-single" name="Buyer" id={"Buyer"}
          isDisabled={true}
          isLoading={false}
          isSearchable={true}
          isClearable={true}
          autocomplete='off'
          placeholder="Select Buyer Detail"
          value={getHeaderBuyerDetails}

         />
        </div>
       </div>
       {
        getBuyCusWarehouseList.map((inputfeild, index) => (
         <div className="row" style={{ marginTop: "1%" }}>
          <div className="col-lg-3">
           <label>Customer Name<span className="text-danger">*</span></label>
           <SelectCombobox className="basic-single" name="Customer" id={"Customer" + index}


            //isDisabled={inputfeild.CustomerID === 0 ? false : true}
            isLoading={false}
            isSearchable={true}
            isClearable={true}
            autocomplete='off'
            placeholder="Select Customer Name"
            styles={submitted && inputfeild.CustomerID === 0 ? styles : ''}
            onChange={event => handleDropdownChange(event, "Customer", index)}
            options={getEndCustomerDetails}
            value={getEndCustomerDetails.filter(function (option) {
             return option.value === inputfeild.CustomerID;
            })}
           />



          </div>
          <div className="col-lg-3" style={{ marginTop: "2%" }}>

              <span className="btn btn-info" onClick={(event) => handleAddCusFields(event, index)}><i className="fa fa-plus"></i></span>
           &nbsp;
           &nbsp;
           { getBuyCusWarehouseList.length !==1 && (
                <span className="btn btn-danger" onClick={(event) => handleRemoveCusFields(index)}><i className="fa fa-trash-o"></i></span>
           )}
          </div>
          <div id="registration-form">
           <form>

            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable" style={{ width: "50%", marginLeft: "1%",marginTop:"5%" }}>
             <TableHeader headers={headers} onSorting={(field, order) => setSorting({ field, order })} />
             <tbody>
              {
               inputfeild.WarehouseDetailslist.map((inputwo, childindex) => (
                <tr>
                 <td style={{ width: "50%" }}>
                  <SelectCombobox className="basic-single" name="ItemName" id={"ItemName" + childindex}
                   isDisabled={false}
                   isLoading={false}
                   isSearchable={true}
                   isClearable={true}
                   autocomplete='off'
                       placeholder="Select Warehouse Name"
                   styles={submitted && inputwo.warehouseID === 0 ? styles : ''}
                   onChange={e => handleInputChange(e, index, "WarehouseName", childindex,)}
                   options={getwarehouse}
                   value={getwarehouse.filter(function (option) {
                    return option.value === inputwo.warehouseID;
                   })}
                  />
                 </td>

                   <td style={{ width: "50%" }}>
                  <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                   <tbody>
                    <tr>
                     <div className="col-sm-12 col-lg-12">
                      <div style={{ paddingLeft: 0 }}>
                       <span className="widget-caption" style={{ fontSize: '14px' }}>File Upload <span style={{ color: 'red', fontSize: '14px' }}>(Upload Document Type: Image Only)</span></span>
                      </div>
                     </div>
                     < div className="col-sm-12 col-lg-12">
                      <div className="row">
                       <div className="col-sm-8 col-lg-8">
                        <div className='form-group'>
                         <tr>
                          <td> <input type="file" id="FileUpload" style={{ fontSize: '14px' }} onChange={(e) => AppendFiles(e, index, childindex)} accept=".jpg,.jpeg,.png" ></input></td>
                         </tr>
                        </div>
                       </div>
                      </div>
                     </div>
                     <div className="col-lg-12 col-sm-12">
                      <div className="row no-margin">
                       {
                        inputwo.fileName !== '' ?
                         // manitem.Files.filter(x => x.IsDeleted === 0) &&
                         <> {
                          <div className="col-lg-8 col-sm-8 file_list_upload" style={{ width: "100%" }}>
                           <tr title={inputwo.fileName}>
                            <td className="cut_textInTNA">
                             <span style={{ marginRight: '7px' }}>
                              {
                               inputwo.fileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                inputwo.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                 <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                 inputwo.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                  inputwo.fileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                  <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                              }
                             </span>  {inputwo.fileName.substr(33)}
                            </td>

                            <td className="button_style_uploda">


                                           {
                                             inputwo.FileType === "application/pdf" || inputwo.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || inputwo.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || inputwo.fileType === 'application/msword' ?
                                               <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + 'Images/Report/' + inputwo.fileName} download><i class="fa fa-download"></i> </a> :


                                               <i style={{ lineHeight: "20px" }} className="btn btn-info fa fa-eye" title="View Image"

                                                 onClick={
                                                   event => ViewFile(event, inputwo.fileName)
                                                 }></i>
                                           }
                                           &nbsp;
                             <p title='Delete File' onClick={(e) => RemoveImages(index, childindex)}
                              className="btn btn-danger btn-xs delete">
                              <i className="fa fa-trash-o"></i>

                             </p>


                            </td>
                           </tr>



                           {

                            <div> {
                             isOpenimg && (

                              <Lightbox mainSrc={window.$APIBaseURL + 'Images/Report/' + getImagename}
                               alt="bg image"
                               onCloseRequest={
                                () => setIsOpenimg(false)
                               } />
                             )
                            } </div>
                           }
                          </div>
                         }
                         </>
                         : ''

                       }
                      </div>
                     </div>
                    </tr>
                   </tbody>
                  </table>
                 </td>
                 <td style={{ display: inputfeild.WarehouseDetailslist.length === 0 ? "none" : "", width: "13%" }}>
                  {
                   inputfeild.WarehouseDetailslist.length === (childindex + 1) && (
                    <span title='Add Warehouse'
                     onClick={(event) => handleAddFields(event, childindex, index)}
                     className="btn btn-info btn-xs plus">
                     <i className="fa fa-plus"></i>
                    </span>
                   )
                  }
                  &nbsp;

                  {
                   inputfeild.WarehouseDetailslist.length !== 1 && (
                    <span title='Delete Warehouse' onClick={() => handleRemoveFields(index, childindex)}
                     className="btn btn-danger btn-xs delete">
                     <i className="fa fa-trash-o"></i>
                    </span>

                   )
                  }
                 </td>

                </tr>
               ))

              }



             </tbody>
            </table>



           </form>
          </div>
         </div>




        ))



       }


      </div>
      <div className="col-sm-12">
       <div className="buttons-preview" align="right">
        <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
         <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
        </span>
        <span className="btn btn-danger" onClick={() => ResetOperation()}>
         <i className="fa fa-times"></i>&nbsp;Reset
        </span>
        <button type="button" className="btn btn-success" disabled={buttonLoader}
         onClick={SaveTemplateDetials}
        >
         <i className="fa fa-check right"></i>&nbsp;Update
        </button>
       </div>
      </div>
     </div>
    </div>
   </div>
  </div >

 )
}

export default BuyCusWarehouseEdit