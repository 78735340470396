import axios from "axios";
import authHeader from "../auth-header";

class ReportService {
    GetCommentsStyleList(Operation, IsProduction, PoStartDate, PoEndDate, ExfacStartDate, ExfacEndDate) {
       
        return axios.get(window.$APIBaseURL + "Report/CommentsReportListMulti?Operation=" + Operation + "&IsProduction=" + IsProduction
            + "&PoStartDate=" + PoStartDate + "&PoEndDate=" + PoEndDate + "&ExfacStartDate=" + ExfacStartDate + "&ExfacEndDate=" + ExfacEndDate,
            {
                headers: {
                    authorization: authHeader()
                }
            });
    }
    GetReportStatusList(StatusID) {
        return axios.get(window.$APIBaseURL + "Report/GetReportStatusList?StatusID=" + StatusID, {
            headers: {
                authorization: authHeader()
            }
        })
    }
    InsertUpdatereportComment(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Report/InsertUpdatereportComment',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
    GetPurchaseOrderSkuReportComments(Id, Operation) {
        return axios.get(window.$APIBaseURL + "Report/GetPurchaseOrderSkuReportComments?PurchaseOrderCommentsID=" + Id + "&Operation=" + Operation,
            {
                headers: {
                    authorization: authHeader()
                }
            });
    }
    GetCommentsReportExFactory(SupplierID, Operation, PurchaseOrderCommentsID) {
        return axios.get(window.$APIBaseURL + "Report/GetCommentsReportExFactory?SupplierID=" + SupplierID + "&Opertation=" + Operation + "&PurchaseOrderCommentsID=" + PurchaseOrderCommentsID,
            {
                headers: {
                    authorization: authHeader()
                }
            });
    }

    //OrderStatus
    InsertUpdatereportOrderStatus(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Report/InsertUpdatereportOrderStatus',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
    InsertUpdatereportExfactory(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Report/InsertUpdatereportExfactory',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
    //TNADetailsreport
    GetTNADetailsReportList(IsProduction, PoStartDate, PoEndDate, ExfacStartDate, ExfacEndDate) {
        return axios.get(window.$APIBaseURL + "Report/GetTNADetailsReportList1?IsProduction=" + IsProduction + "&PoStartDate=" + PoStartDate + "&PoEndDate=" + PoEndDate + "&ExfacStartDate=" + ExfacStartDate + "&ExfacEndDate=" + ExfacEndDate,
            {
                headers: {
                    authorization: authHeader()
                }
            });
    }


    GetPurchaseOrderSizeQty(Id) {
        return axios.get(window.$APIBaseURL + "Report/GetPurchaseOrderSizeQty?PurchaseOrderSkuID=" + Id,
            {
                headers: {
                    authorization: authHeader()
                }
            });
    }

    GetPurchaseOrderSKUQty(Id) {
        return axios.get(window.$APIBaseURL + "Report/GetPurchaseOrderSKUQty?FinalInspectionID=" + Id,
            {
                headers: {
                    authorization: authHeader()
                }
            });
    }



}
export default new ReportService();