import {
    STRIKEOFF_LOADING,
    RETRIEVE_STRIKEOFF,
    DELETE_STRIKEOFF,
} from "./types";

import StrikeoffService from "../services/Style/StrikeoffService";

const strikeoffLoading = (isStatus) => ({
    type: STRIKEOFF_LOADING,
    payload: isStatus,
});

export const deleteStrikeoff = (strikeOffID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_STRIKEOFF,
            payload: strikeOffID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrieveStrikeoff = (StrikeoffId) => async (dispatch) => {
    try {
        dispatch(strikeoffLoading(true));
        const res = await StrikeoffService.GetStrikeOffLogoInformationList(StrikeoffId);
        dispatch({
            type: RETRIEVE_STRIKEOFF,
            payload: res.data,
        });
        dispatch(strikeoffLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(strikeoffLoading(false));
    }
};