import React, { useState, useEffect, useRef, Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactNotification from 'react-notifications-component';
import Nodify from "../Common/ReactNotification"

import TNARoute from "../TNA/TNARoute";
import TNAPurchaseOrder from "../TNA/TNAPurchaseOrder";
import { retrieveSeason } from "../../actions/season";
import { LoadTaskWiseBuyer, loadBrandThemeStoryEndCustomer, loadSupplierList } from "../../actions/tna";
//import { LoadTaskWiseBuyer } from "../../services/Style/styleService";

const TNARegister = (props) => {

    const dispatch = useDispatch();
    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [tabStatus, setTabStatus] = useState(1);

    let purchaseOrderID = 0;
    let brandID = 0;
    let seasonID = 0;
    let supplierID = 0;

    if (props.location.state !== undefined) {
        purchaseOrderID = props.location.state.params.purchaseOrderID === undefined ? 0 : props.location.state.params.purchaseOrderID;
        brandID = props.location.state.params.brandID === undefined ? 0 : props.location.state.params.brandID;
        seasonID = props.location.state.params.seasonID === undefined ? 0 : props.location.state.params.seasonID;
        supplierID = props.location.state.params.supplierID === undefined ? 0 : props.location.state.params.supplierID;
    }

    //reducer
    const purchaseOrderIDList = useSelector((state) => state.tna.purchaseOrderIDList);
    //const getSeasonList = useSelector((state) => state.season.seasonList);
    const getBrandList = useSelector((state) => state.tna.drpBrandList);
    const getSeasonList = useSelector((state) => state.tna.drpSeasonList);
    const getSupplierList = useSelector((state) => state.tna.drpSupplierList);

    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),
    }

    // If check production or sampling

    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
    let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal : activeMenus.IsProduction;
    // End
    //

    useEffect(() => {
        //dispatch(retrieveSeason(0));
        //TNA Operation 7
        //dispatch(loadBrandThemeStoryEndCustomer(7, 0));
        dispatch(LoadTaskWiseBuyer(2, IsProduction)); // Buyer
        dispatch(LoadTaskWiseBuyer(3, IsProduction)); // Season
        dispatch(LoadTaskWiseBuyer(4, IsProduction)); // Supplier
        //dispatch(loadSupplierList());

    }, []);

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/TNAList",
            state: {
                message: Func
            }
        });
    }

    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
        return false;
    }

    const ResetOperation = (e) => {
        if (e === "Back") {
            if (props.location.state.params.purchaseOrderID && props.location.state.params.purchaseOrderID > 0) {
                props.history.push('/PurchaseOrderList');
            } else {
                props.history.push('/TNAList');
            }

        } else {
            window.location.reload();
        }
    }

    const CallBackTNAAdd = (page) => {
        props.history.push({
            pathname: "/TNAList",
            state: {
                message: page
            }
        });
    }

    const tabClick = (tab) => {
        setTabStatus(tab);
    }

    return (
        <Fragment>
            <div className="page-body">
                <ReactNotification />
                        
        <div className="row">
            <div className="col-lg-12 col-sm-12 col-xs-12">
                <div className="widget flat radius-bordered">
                    <div className="widget-header heading_box_style">
                        <h3 className="heading_style_common">Add TNA</h3>
                        <div className="row">
                            <div className="col-sm-12">
                            <div className="text-right">
                            

                                    <span className="btn btn-primary"
                                        onClick={() => ResetOperation("Back")}
                                    >
                                        <i className="fa fa-arrow-circle-left"></i>
                                        &nbsp;Back
                                    </span>
                                    &nbsp;
                                    <span className="btn btn-danger"
                                        onClick={() => ResetOperation()}
                                    >
                                        <i className="fa fa-times"></i>
                                        &nbsp;Reset
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


                            <div className="widget-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="tabbable">
                                            <ul className="nav nav-tabs nav-justified" id="myTab5">
                                                <li className="active" onClick={() => tabClick(1)}>
                                                    <a data-toggle="tab" href="#idPurchaseOrder">
                                                        <h5>Purchase Order Info</h5>
                                                    </a>
                                                </li>
                                                <li className="" onClick={() => tabClick(purchaseOrderIDList.length > 0 ? 2 : 1)} style={{ pointerEvents: purchaseOrderIDList.length > 0 ? '' : 'none', opacity: purchaseOrderIDList.length > 0 ? '' : '0.6' }}>
                                                    <a data-toggle="tab" href="#idRouteApply" >
                                                        <h5>Route Apply</h5>
                                                    </a>
                                                </li>
                                            </ul>
                                            <div className="tab-content">
                                                <div id="idPurchaseOrder" className="tab-pane in active" style={{ width: "100%" }}>
                                                    {
                                                        getSeasonList.length > 0 && getBrandList.length > 0 && getSupplierList.length > 0 && <TNAPurchaseOrder PurchaseOrderID={purchaseOrderID} BrandID={brandID} SeasonID={seasonID} SupplierID={supplierID} />
                                                    }
                                                </div>
                                                <div id="idRouteApply" className="tab-pane" style={{ width: "100%" }}>
                                                    {
                                                        (purchaseOrderIDList.length > 0 && tabStatus === 2) ? <TNARoute PurchaseOrderIDList={purchaseOrderIDList} CallBackTNAAdd={CallBackTNAAdd} ResetOperation={ResetOperation} /> : ""
                                                    }

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                 
                {/* <div className="row">
                    <div className="col-sm-12">
                        <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>
                            <span className="btn btn-primary"
                                onClick={
                                    () => ResetOperation("Back")
                                }
                            >
                                <i className="fa fa-arrow-circle-left"></i>
                                &nbsp;Back</span>
                            <span className="btn btn-danger"
                                onClick={
                                    () => ResetOperation()
                                }
                            >
                                <i className="fa fa-times"></i>
                                &nbsp;Reset</span>
                            <button type="button" className="btn btn-success" 
                                onClick={SaveTNA}>

                                <i className="fa fa-check right"></i>
                                &nbsp;Save</button>
                        </div>
                    </div>
                </div> */}
            </div>
        </Fragment>
    )
};
export default TNARegister;