import React, { useState, useEffect, useMemo } from "react";
import ReactNotification from 'react-notifications-component';
import { TableHeader, Pagination, Search, PageCount } from "../../Datatable";
import { useDispatch, useSelector } from "react-redux";
import CommonService from '../../../services/Common/CommonService';
import Nodify from "../../Common/ReactNotification";
import SearchRetain from "../../Common/SearchRetain";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import SweetAlertPopup from '../../Common/SweetAlertPopup';
import { Redirect } from 'react-router-dom';
import TestingReortTemplateService from "../../../services/Master/TestingReortTemplateService";
import SelectCombobox from 'react-select';
import TestingReportDetail from "../../../services/Master/TestingReportDetail";


const TestingReportTemplateEdit = (props) => {
       const [ItemList, setItemList] = useState([]);// for Item list
       const [buttonLoader, setButtonLoader] = useState(false);
       const [matchingList, setmatchingList] = useState([]);// For TemplateID based matched value ItemID and ItemNAme
       const [getHeaderBuyerDetails, setHeaderBuyerDetails] = useState([]);// For Header buyer Details
       const [getHeaderBrandDetails, setHeaderBrandDetails] = useState([]);// For Header brand Details
       const [getHeaderTempName, setHeaderTempName] = useState("");// For Header tempname Details
       const [submitted, setSubmitted] = useState(false);
       const [sorting, setSorting] = useState({ field: "", order: "" });
       const { user: currentUser } = useSelector((state) => state.auth);


       useEffect(() => {
              let ItemDetails = [];
              let BuyerDetail = [];
              let BrandDetail = [];
              let TemplateMatchList = [];

              TestingReportDetail.getAllTestingItemList(0).then((res) => {
                     if (res.data) {
                            res.data.map(x => {
                                   ItemDetails.push({
                                          label: x.itemName,
                                          value: x.itemID
                                   })
                            })
                            setItemList(ItemDetails)
                     }
              })

              TestingReortTemplateService.getTestReportTempDetails(2, props.location.state.params.tempID).then(async (res) => {
                    ;
                     if (res.data) {
                            res.data.testRepTempEdit.map(x => {
                                   TemplateMatchList.push({
                                          label: x.itemName,
                                          value: x.itemid,
                                          Isdeleted: 0,
                                          CommonID: x.commonID,
                                          TempID: x.tempID,

                                   })
                            })
                            setmatchingList(TemplateMatchList)
                     }

                     if (res.data) {
                            res.data.testRepTempEdit.map(x => {
                                   BuyerDetail.push({
                                          value: x.buyerID,
                                          label: x.buyerName,
                                   })
                            })
                            setHeaderBuyerDetails(BuyerDetail)
                     }
                     if (res.data) {
                            res.data.testRepTempEdit.map(x => {
                                   BrandDetail.push({
                                          value: x.brandID,
                                          label: x.brandName,

                                   })
                            })
                            setHeaderBrandDetails(BrandDetail)
                     }

                     if (res.data) {
                            res.data.testRepTempEdit.map(x => (
                                   setHeaderTempName(x.tempName)
                            ))

                     }

              }, [])

       }, [])

       const handleRemoveFields = (index, value) => {
             
              const values = [...matchingList]


              let Params = {
                     Operation: 138,
                     Id: value.value,
              }
              CommonService.CheckMappedItem(Params).then(res => {
                     if (res.data.outputResult === "-2") {
                            let Title = "Warning!";
                            let message = "This item is already mapped.";
                            let type = "warning";
                            Nodify(Title, type, message);
                     }
                     else {
                            values.splice(index, 1);
                     }

                     setmatchingList(values)
              });





       };


       const handleAddFields = (index) => {
             
              const values = [...matchingList];
              if (values[index].value !== 0 && values[index].value !== undefined) {
                     // Create a new row
                     const newRow = { id: Date.now() };

                     // Update the rows array
                     setmatchingList((prevRows) => {
                            // Insert the new row at the specified index
                            const updatedRows = [...prevRows.slice(0, index + 1), newRow, ...prevRows.slice(index + 1)];

                            // Increment the indices of rows following the insertion point
                            const updatedRowsWithIds = updatedRows.map((row, i) => ({ ...row, id: i }));

                            return updatedRowsWithIds;
                     });
                     setSubmitted(false);
                     // values.push({ value: 0, label: "" });
                     // setmatchingList(values);
              }
              else {
                     Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
                     setSubmitted(true);
              }
       };






       const ResetOperation = (e) => {
              if (e === "Back") {
                     props.history.push('/TestingTemplateList');
              } else {
                     window.location.reload();
              }
       }
       const headers = [
              { name: <span>Testing Item Name<span className="text-danger">*</span></span>, field: "itemName", sortable: false },
              {
                     name: "Action", field: "", sortable: false,

              },
       ];

       let styles = {
              control: styles => ({ ...styles, border: '1px solid red' }),

       }

       const handleInputChange = (e, index, FieldName) => {

              const values = [...matchingList]
              if (FieldName === "ItemName") {
                     if (e !== null) {
                            values[index].value = e.value
                            values[index].label = e.label
                     }
                     else {
                            values[index].value = 0
                            values[index].label = ''
                     }
              }
              setmatchingList(values)

              if (values.length > 1)
                     for (var j = 0; j < (matchingList.length); j++) {
                            if (j !== index) {
                                   if (values[j].value === values[index].value) {
                                          values[index].value = 0;
                                          values[index].label = '';
                                          setmatchingList(values);
                                          Nodify('Warning!', 'warning', 'This Item is already exist in Template.');
                                          setSubmitted(true);
                                          return false;
                                   }
                            }
                     }


       }
       const PageRedirect = (Func) => {
              props.history.push({
                     pathname: "/TestingTemplateList",
                     state: {
                            message: Func,
                     },
              }
              );
       };


       const SaveTemplateDetials = (e) => {
             ;
              setButtonLoader(true);
              e.preventDefault();
              let isvalid = true;
              for (var i = 0; i < (matchingList.length); i++) {
                     if (matchingList[i].value === 0 || matchingList[i].value === undefined) {
                            Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
                            setSubmitted(true);
                            isvalid = false
                            setButtonLoader(false);
                            return false;
                     }
              }

              let TemplateEditID = []
              matchingList.map((x) => {
                     TemplateEditID.push({
                            CommonID: x.CommonID === undefined ? 0 : x.CommonID,
                            Itemid: x.value,
                            Isdeleted: x.Isdeleted === undefined ? 0 : x.Isdeleted,
                     })
              })
              if (isvalid) {
                     let InputMaster = { Operation: 2, TempID: props.location.state.params.tempID, TempName: props.location.state.params.tempName, BuyerID: props.location.state.params.buyerID, BrandID: props.location.state.params.brandID, testreptempInformation: TemplateEditID, Createdby: currentUser.employeeinformationID }
                     TestingReortTemplateService.UpdateDeleteTestReportTempDetails(InputMaster).then(res => {

                            var page = "Remove";
                            SearchRetain(page);
                            let Func = 'Add';
                            if (res.data.outputResult === "1") {
                                   Func = 'Add';
                                   PageRedirect(Func);
                            } else if (res.data.outputResult === "2") {
                                   Func = 'Edit';
                                   PageRedirect(Func);
                            }
                            setButtonLoader(false);
                     });
              }

       }







       return (
              <div className="page-body">
                     <ReactNotification />
                     <div className="row">
                            <div className="col-lg-12 col-sm-12 col-xs-12">
                                   <div className="widget flat radius-bordered">
                                          <div className="widget-header heading_box_style">
                                                 <h3 className="heading_style_common">Edit Testing Template</h3>
                                          </div>

                                          <div className="widget-body">
                                                 <div className="row">
                                                        <div className="col-lg-3">
                                                               <label>Buyer Name<span className="text-danger">*</span></label>
                                                               <SelectCombobox className="basic-single" name="Buyer" id={"Buyer"}
                                                                      isDisabled={true}
                                                                      isLoading={false}
                                                                      isSearchable={true}
                                                                      isClearable={true}
                                                                      autocomplete='off'
                                                                      placeholder="Select Buyer Detail"
                                                                      value={getHeaderBuyerDetails}

                                                               />
                                                        </div>
                                                        <div className="col-lg-3">
                                                               <label>Brand Name<span className="text-danger">*</span></label>
                                                               <SelectCombobox className="basic-single" name="Brand" id={"Brand"}
                                                                      isDisabled={true}
                                                                      isLoading={false}
                                                                      isSearchable={true}
                                                                      isClearable={true}
                                                                      autocomplete='off'
                                                                      placeholder="Select Brand Detail"
                                                                      value={getHeaderBrandDetails}
                                                               />
                                                        </div>


                                                        <div className="col-lg-3">
                                                               <label htmlFor="TemplateName">Template Name<span className="text-danger">*</span></label>
                                                               <span className="input-icon icon-right">
                                                                      <input type="text" name="TemplateName"
                                                                             id="TemplateName"
                                                                             className="form-control"
                                                                             value={getHeaderTempName}
                                                                             maxLength="25"
                                                                      />
                                                               </span>
                                                        </div>


                                                 </div>
                                                 <div id="registration-form">
                                                        <form>
                                                               {
                                                                      <table className="table table-striped table-bordered table-hover dataTable no-footer margin-top-10" id="editabledatatable" style={{ width: "50%" }}>
                                                                             <TableHeader headers={headers} onSorting={(field, order) => setSorting({ field, order })} />
                                                                             <tbody>
                                                                                    {matchingList.filter(x => x.Isdeleted !== 1).map((inputfeild, index) => (
                                                                                           <tr>
                                                                                                  <td>
                                                                                                         <SelectCombobox className="basic-single" name="ItemName" id={"ItemName" + index}
                                                                                                                isDisabled={false}
                                                                                                                isLoading={false}
                                                                                                                isSearchable={true}
                                                                                                                isClearable={true}
                                                                                                                autocomplete='off'
                                                                                                                placeholder="Select Item Detail"
                                                                                                                styles={submitted && inputfeild.value === undefined ? styles : ''}
                                                                                                                onChange={e => handleInputChange(e, index, "ItemName")}
                                                                                                                options={ItemList}
                                                                                                                value={matchingList.filter(function (option) {
                                                                                                                       return option.value === inputfeild.value;
                                                                                                                })}
                                                                                                         //value={inputfeild}
                                                                                                         />
                                                                                                  </td>


                                                                                                  <td >

                                                                                                         {/* {
                                                                                                                matchingList.filter(x => x.Isdeleted !== 1).length === (index + 1) && ( */}
                                                                                                         <span title='Add Testing Report'
                                                                                                                onClick={() => handleAddFields(index)}
                                                                                                                className="btn btn-info btn-xs plus">
                                                                                                                <i className="fa fa-plus"></i>
                                                                                                         </span>
                                                                                                         {/* )

                                                                                                         } */}
                                                                                                         &nbsp;
                                                                                                         {
                                                                                                                matchingList.filter(x => x.Isdeleted !== 1).length !== 1 && (
                                                                                                                       <span title='Delete Testing Report' onClick={() => handleRemoveFields(index, inputfeild)}
                                                                                                                              className="btn btn-danger btn-xs delete">
                                                                                                                              <i className="fa fa-trash-o"></i>
                                                                                                                       </span>
                                                                                                                )

                                                                                                         }

                                                                                                  </td>

                                                                                           </tr>

                                                                                    ))

                                                                                    }
                                                                             </tbody>
                                                                      </table>
                                                               }

                                                               <div className="col-sm-12">
                                                                      <div className="buttons-preview" align="right">
                                                                             <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                                                                    <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                                                                             </span>
                                                                             <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                                                                    <i className="fa fa-times"></i>&nbsp;Reset
                                                                             </span>
                                                                             <button type="button" className="btn btn-success" disabled={buttonLoader}
                                                                                    onClick={SaveTemplateDetials}
                                                                             >
                                                                                    <i className="fa fa-check right"></i>&nbsp;Update
                                                                             </button>
                                                                      </div>
                                                               </div>
                                                        </form>
                                                 </div>
                                          </div>
                                   </div>
                            </div>
                     </div >
              </div >
       )
}

export default TestingReportTemplateEdit