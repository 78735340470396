import {
  GENERAL_TNA_REPORT_LOADING,
  GENERAL_TNA_REPORT_LIST,
  UPDATE_GENERAL_TNA_REPORT_IS_EXPANDED,
  UPDATE_GENERAL_TNA_REPORT_PARENT_IS_EXPANDED,
  CLEAR_GENERAL_TNA_REPORT_LIST
} from "./types";
import GeneralService from "../services/General/GeneralService";

const generalTNAReportLoading = (isStatus) => ({
  type: GENERAL_TNA_REPORT_LOADING,
  payload: isStatus,
});

export const loadGenTNAReportList = (params) => async (dispatch) => {
  try {
    dispatch(generalTNAReportLoading(true));

    if (params.BuyerId === 0 || params.BrandId === 0 || params.SeasonId === 0) {
      dispatch(generalTNAReportLoading(false));

      dispatch({
        type: GENERAL_TNA_REPORT_LIST,
        payload: { data: [], params: [] },
      });
    } else {
      const res = await GeneralService.LoadGenTNAReportList(params);
      dispatch({
        type: GENERAL_TNA_REPORT_LIST,
        payload: { data: res.data, params: params },
      });
    }
    dispatch(generalTNAReportLoading(false));
  } catch (err) {
    console.log(err);
    dispatch(generalTNAReportLoading(false));
  }
};


export const updateGenTNAReportIsExpanded = (expandedParams) => async (dispatch) => {
  dispatch(generalTNAReportLoading(true));
  dispatch({
    type: UPDATE_GENERAL_TNA_REPORT_IS_EXPANDED,
    payload: expandedParams,
  });
  dispatch(generalTNAReportLoading(false));
};

export const updateGenTNAReportParentIsExpanded = (expandedParams) => async (dispatch) => {
  dispatch(generalTNAReportLoading(true));
  dispatch({
    type: UPDATE_GENERAL_TNA_REPORT_PARENT_IS_EXPANDED,
    payload: expandedParams,
  });
  dispatch(generalTNAReportLoading(false));
};

export const clearGenTNAReportList = (params) => async (dispatch) => {
  dispatch(generalTNAReportLoading(true));
  dispatch({
    type: CLEAR_GENERAL_TNA_REPORT_LIST,
    payload: params,
  });
  dispatch(generalTNAReportLoading(false));
};







