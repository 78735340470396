import React, { useState, useEffect, Fragment, Suspense } from "react";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import "react-notifications-component/dist/theme.css";
import Nodify from "../Common/ReactNotification";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Lightbox from "react-image-lightbox";
import { Modal, Button } from "react-bootstrap";
import $, { get } from "jquery";
import { useSelector } from "react-redux";
import { TableHeader, Pagination, Search, PageCount } from "../Datatable";
import axios from "axios";
import Reactselect from 'react-select';
import TnaService from "../../services/TNA/TNAService";
import Fabric from "../quickMaster/_Fabric";
import { useDispatch } from "react-redux";
import { InsertUpdateTNAProtoFitSmsSubmissionApproval } from "../../actions/tna";
import { ProtoFitSMSSubmissionApprovalId } from '../Common/PageActionNumber';
import SweetAlertPopup from "../Common/SweetAlertPopup";
import moment from "moment";
import Draggable from 'react-draggable';
import ModalDialog from 'react-bootstrap/ModalDialog';
const ProtoFitSMSApproval = (props) => {
    const dispatch = useDispatch();
    const { user: currentUser } = useSelector((state) => state.auth);
    const [Buttonview, SetButtonview] = useState("Hide");
    const [submitted, setSubmitted] = useState(false);
    const [getHeader, setHeader] = useState("Add");
    const [getDisableSave, setDisableSave] = useState(false);    //new implement
    const [getImagename, setImagename] = useState();
    const [getFiles, setFiles] = useState([]);
    const [isOpenimg, setIsOpenimg] = useState(false);
    const [result, setresult] = useState([]);
    const [getID, setID] = useState({ showPopupDelete: false, Params: {} });

    const [submittedpopup, setSubmittedpopup] = useState(false);
    const [IsFabricExpanded, setIsFabricExpanded] = useState(false);
    const [IsColorExpanded, setIsColorExpanded] = useState(false);
    const [IsStrikeoffExpanded, setIsStrikeoffExpanded] = useState(false);
    const [IsTrimsExpanded, setIsTrimsExpanded] = useState(false);
    const [IsMessureComment, setMessureComment] = useState(false);
    const [IsConstructionComments, setIsConstructionComments] = useState(false);
    const [IsUnbrandComments, setIsUnbrandComments] = useState(false);
    const [IsApplicationComments, setIsApplicationComments] = useState(false);
    const [IsSRComments, setIsSRComments] = useState(false);
    const [IsQCComments, setIsQCComments] = useState(false);
    const [IsAmendmentsComments, setIsAmendmentsComments] = useState(false);
    const [IsActionComments, setIsActionComments] = useState(false);
    const [IsTestingComments, setIsTestingComments] = useState(false);
    const [IsPackagingComments, setIsPackagingComments] = useState(false);
    const [IsAdditionalComments, setIsAdditionalComments] = useState(false);

    const [IsMeasurementExpanded, setIsMeasurementExpanded] = useState(false);

    const [IsFabricSelected, setIsFabricSelected] = useState(false);
    const [IsColorSelected, setIsColorSelected] = useState(false);
    const [IsStrikeoffSelected, setIsStrikeoffSelected] = useState(false);
    const [IsTrimsSelected, setIsTrimsSelected] = useState(false);
    const [IsMeasurementSelected, setIsMeasurementSelected] = useState(false);
    const [EditedCommentIndex, setEditedCommentIndex] = useState('');
    const [getComment, setComment] = useState(false);

    const [getSubImageList, setSubImageList] = useState([]);
    const [getCurrimageindex, setCurrimageindex] = useState(0);
    const [minApprovalDate, setMinApprovalDate] = useState({});
    const [getCommentIndex, setCommentIndex] = useState();

    const filevals = [...getFiles]
    let DynamicClassName = "col-lg-12 col-sm-12 col-xs-12";
    const [inputFields, setInputFields] = useState([{
        BuyerBrandSeasonName: '', SupplierName: '', StyleName: '', StyleNo: '', SkuName: '', IdpoNo: '', TNAProtoFitOrSmsId: 0,
        SendTo: '', SendThrough: 0, AWBName: '', AWBDate: '', AWBDateandNo: '', SubmittedFor: '', SubmittedForEdit: '',
        SizeIdQtyIds: '', SizeandQty: '', ProtoFitSmsSubmissionId: 0, SubmittedDate: '', GarmentSizeQty: '', OverAllResult: '', ApprovalDate: '', TNAId: 0,
        Fabric: [],
        Color: [],
        Strikeoff: [],
        Trim: [],
        AdditionalComments: [{
            CommentName: 'Measurement Comments',
            Comment: ''
        },
        {
            CommentName: 'Construction Comments',
            Comment: ''
        },
        {
            CommentName: 'Unbranded Trim Comments',
            Comment: ''
        },

        {
            CommentName: 'Application Comments',
            Comment: ''
        },
        {
            CommentName: 'Special Requirement Comments',
            Comment: ''
        },
        {
            CommentName: 'QC Note',
            Comment: ''
        },
        {
            CommentName: 'Amendments',
            Comment: ''
        },
        {
            CommentName: 'Action Comments',
            Comment: ''
        },
        {
            CommentName: 'Testing Comments',
            Comment: ''
        },
        {
            CommentName: 'Packaging Comments',
            Comment: ''
        },
        {
            CommentName: 'Additional comments',
            Comment: ''
        }
        ],

        Measurement: { Files: [] }
    }]);
    const values = [...inputFields]


    let styles1 = {
        control: styles1 => ({ ...styles1, width: "350px" }),
    }

    let styles2 = {
        control: styles2 => ({ ...styles2, width: "350px", border: '1px solid red' }),
    }

    const Submittedforoptions = [
        { value: '1', label: 'Fabric' },
        { value: '2', label: 'Color' },
        { value: '3', label: 'Strikeoff' },
        { value: '4', label: 'Trims' },
        { value: '5', label: 'Measurement' }
    ]

    const OverallResult = [
        { key: '1', value: 'Approved' },
        { key: '2', value: 'Approved with Comments' },
        { key: '3', value: 'Rejected' }
    ]
    useEffect(() => {
       
        if (props.delteProtoFitSmsApproval === true) {
            setID({ showPopupDelete: true, Params: props.TNAProtoFitId });
        }
        else if (props.editProtoFitSmsApproval === true) {

            let getDateValue = new Date(values[0].ApprovalDate);
            var dd = getDateValue.getDate();
            var mm = getDateValue.getMonth() + 1;
            var yyyy = getDateValue.getFullYear();

            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            values[0].ApprovalDate = getDateValue;
            setInputFields(values);

        }
        else {
            var ParentTask = props.ParentTask;
            var Operation = 0;
            let ViewOperation = 0;

            if (ParentTask === "Proto Sample Submission") {
                Operation = 1;
                ViewOperation = 1;
            }
            else if (ParentTask === "Proto Sample Approval") {
                Operation = 2;
                ViewOperation = 2;
            }
            else if (ParentTask === "Fit Sample Submission") {
                Operation = 3;
                ViewOperation = 3;
            }
            else if (ParentTask === "Fit Sample Approval") {
                Operation = 4;
                ViewOperation = 4;
            }
            else if (ParentTask === "SMS Submission"
                || ParentTask === "Size Set Sample Submission"
                || ParentTask === "Pre Production Sample Submission"
                || ParentTask === "Shipment Sample Submission"
                || ParentTask === "Photo Sample Submission"
            ) {
                Operation = 5;
                ViewOperation = 5;
            }
            else if (ParentTask === "SMS Approval"
                || ParentTask === "Size Set Sample Approval"
                || ParentTask === "Pre Production Sample Approval"
                || ParentTask === "Shipment Sample Approval"
                || ParentTask === "Photo Sample Approval") {
                Operation = 6;
                ViewOperation = 6;
            }

            let Optionvalue = [];
            OverallResult.forEach(element => {
                let LabelColor = '';
                if (element.value === 'Approved') {
                    LabelColor = <><span style={{ color: "#00b050" }}><b>Approved</b></span></>
                }
                else if (element.value === 'Approved with Comments') {
                    LabelColor = <><span style={{ color: "#fca404" }}><b>Approved with Comments</b></span></>
                }
                else if (element.value === 'Rejected') {
                    LabelColor = <><span style={{ color: "#c40404" }}><b>Rejected</b></span></>
                }



                Optionvalue.push({
                    value: element.key,
                    label: LabelColor,
                    subLabel: element.value,
                })
            });

            setresult(Optionvalue);

            if (props.TaskStatus !== 3) {
                TnaService.GetProtoFitSMSApprovalList(Operation, props.TNAProtoFitId).then(async (response) => {
                   ;
                    if (response.data) {
                        values[0].SupplierName = response.data.protoFitSMSBasicDetails[0].supplierName;
                        values[0].BuyerBrandSeasonName = response.data.protoFitSMSBasicDetails[0].buyerBrandSeasonName;
                        values[0].StyleID = response.data.protoFitSMSBasicDetails[0].styleId;
                        values[0].StyleName = response.data.protoFitSMSBasicDetails[0].styleName;
                        values[0].StyleNo = response.data.protoFitSMSBasicDetails[0].styleNo;
                        values[0].IdpoNo = response.data.protoFitSMSBasicDetails[0].idpoNo;
                        values[0].SkuName = response.data.protoFitSMSBasicDetails[0].skuName;
                        values[0].SizeandQty = response.data.protoFitSMSBasicDetails[0].sizeQtyNameList;
                        values[0].SizeIdQtyIds = response.data.protoFitSMSBasicDetails[0].sizeQtyIDList;
                        values[0].SubmitSentDetails = response.data.protoFitSMSBasicDetails[0].sendThroughName + ' ' + response.data.protoFitSMSBasicDetails[0].awbName + ' ' + (response.data.protoFitSMSBasicDetails[0].awbDate === "" ? "" : '[' + moment(response.data.protoFitSMSBasicDetails[0].awbDate).format('DD/MM/YYYY') + ']');
                        values[0].SubmittedDate = new Date(response.data.protoFitSMSBasicDetails[0].submittedDate);
                        //setMinApprovalDate((moment(values[0].SubmittedDate).format("YYYY-MM-DD")).toString());
                        values[0].SubmittedFor = response.data.protoFitSMSBasicDetails[0].submitFor;
                        values[0].BuyerId = response.data.protoFitSMSBasicDetails[0].buyerID;
                        values[0].BrandId = response.data.protoFitSMSBasicDetails[0].brandID;
                        values[0].SeasonId = response.data.protoFitSMSBasicDetails[0].seasonID;
                        values[0].SupplierId = response.data.protoFitSMSBasicDetails[0].supplierID;
                        values[0].SkuId = response.data.protoFitSMSBasicDetails[0].skuId;
                        values[0].TaskOwnerIdList = response.data.protoFitSMSBasicDetails[0].taskOwnerIdList;
                        values[0].SendThrough = response.data.protoFitSMSBasicDetails[0].sendThrough;
                        values[0].SendTo = response.data.protoFitSMSBasicDetails[0].sentTo;
                        values[0].AWBDate = response.data.protoFitSMSBasicDetails[0].awbDate;
                        values[0].AWBName = response.data.protoFitSMSBasicDetails[0].awbName;
                        values[0].TNAId = response.data.protoFitSMSBasicDetails[0].tnaId;
                        values[0].TNAProtoFitOrSmsId = props.TNAProtoFitId;
                        // values[0].Fabric[0].fabricComments = [];
                        // values[0].Color[0].colorComments = [];
                        // values[0].Strikeoff[0].strikeoffComments = [];
                        // values[0].Trim[0].trimComments = [];


                        let images = [];
                        images = response.data.protoFitSMSBasicDetails[0].skuImageList.split(',');
                        setSubImageList(images);
                        setCurrimageindex(0);
                        if (response.data.fabricDetails.length > 0) {
                            await response.data.fabricDetails.map((element, index) => {
                                // values[0].Fabric[index].fabricComments = [];
                                values[0].Fabric.push({
                                    fabricID: element.fabricID,
                                    fabricDetailName: element.fabricDetailName,
                                    quality: element.quality,
                                    value: element.value,
                                    label: element.label,
                                    fabricIndexName: element.fabricIndexName,
                                    fabricComments: [],
                                    subfabricComment: response.data.fabricComments.length > 0 ? response.data.fabricComments : []

                                })
                            })

                        }

                        if (response.data.colorDetails.length > 0) {
                            await response.data.colorDetails.map((element, index) => {
                                // values[0].Fabric[index].fabricComments = [];
                                values[0].Color.push({
                                    pantoneID: element.pantoneID,
                                    pantoneDetails: element.pantoneDetails,
                                    pantoneColorOrSwatchImage: element.pantoneColorOrSwatchImage,
                                    quality: element.quality,
                                    value: element.value,
                                    label: element.label,
                                    colorIndexName: element.colorIndexName,
                                    colorComments: [],
                                    subcolorComment: response.data.colorComments.length > 0 ? response.data.colorComments : []
                                })
                            })

                        }

                        if (response.data.strikeoffDetails.length > 0) {
                            await response.data.strikeoffDetails.map((element, index) => {
                                // values[0].Fabric[index].fabricComments = [];
                                values[0].Strikeoff.push({
                                    strikeOffLogoID: element.strikeOffLogoID,
                                    stikeOffCodeandName: element.stikeOffCodeandName,
                                    strikeoffLogoPath: element.strikeoffLogoPath,
                                    quality: element.quality,
                                    value: element.value,
                                    label: element.label,
                                    strikeoffIndexName: element.strikeoffIndexName,
                                    strikeoffComments: [],
                                    substrikeOffComment: response.data.strikeoffComments.length > 0 ? response.data.strikeoffComments : []
                                })
                            })

                        }

                        if (response.data.trimDetails.length > 0) {
                            await response.data.trimDetails.map((element, index) => {
                                // values[0].Fabric[index].fabricComments = [];
                                values[0].Trim.push({
                                    trimLogoInfoID: element.trimLogoInfoID,
                                    trimCodeandName: element.trimCodeandName,
                                    trimLogoPath: element.trimLogoPath,
                                    quality: element.quality,
                                    value: element.value,
                                    label: element.label,
                                    trimIndexName: element.trimIndexName,
                                    trimComments: [],
                                    subtrimComment: response.data.trimComments.length > 0 ? response.data.trimComments : []
                                })
                            })

                        }
                        if (response.data.measurementFileInformation.length > 0) {
                            await response.data.measurementFileInformation.map((docs, i) => {
                                values[0].Measurement.Files.push({
                                    FileID: docs.fileID,
                                    FileName: docs.fileName,
                                    FilePath: docs.filePath,
                                    FileType: docs.fileType
                                })
                            });
                        }
                        // setFiles(filevals);


                        let arr = response.data.protoFitSMSBasicDetails[0].submitFor.split(',');
                        let arrVal = [];
                        for (let size of arr) {
                            let res = Submittedforoptions.filter(x => x.value === size);
                            arrVal.push({
                                value: res[0].value,
                                label: res[0].label
                            });
                        }
                        values[0].SubmittedForEdit = arrVal;


                        setInputFields(values);
                        ExpandAll();


                    }
                }).catch(() => { });
            }
            else {
                (async () => {
                    await TnaService.ViewProtoFitSMSSubmissionApprovalList(ViewOperation, props.TNAProtoFitId).then(async (response) => {
                       ;
                        if (response.data) {

                            values[0].SupplierName = response.data.protoFitSMSBasicDetails[0].supplierName;
                            values[0].BuyerBrandSeasonName = response.data.protoFitSMSBasicDetails[0].buyerBrandSeasonName;
                            values[0].StyleID = response.data.protoFitSMSBasicDetails[0].styleId;
                            values[0].StyleName = response.data.protoFitSMSBasicDetails[0].styleName;
                            values[0].StyleNo = response.data.protoFitSMSBasicDetails[0].styleNo;
                            values[0].IdpoNo = response.data.protoFitSMSBasicDetails[0].idpoNo;
                            values[0].SkuName = response.data.protoFitSMSBasicDetails[0].skuName;
                            values[0].SizeandQty = response.data.protoFitSMSBasicDetails[0].sizeQtyNameList;
                            values[0].SizeIdQtyIds = response.data.protoFitSMSBasicDetails[0].sizeQtyIDList;
                            values[0].SubmitSentDetails = response.data.protoFitSMSBasicDetails[0].sendThroughName + ' ' + response.data.protoFitSMSBasicDetails[0].awbName + ' ' + (response.data.protoFitSMSBasicDetails[0].awbDate === "" ? " " : '[' + moment(response.data.protoFitSMSBasicDetails[0].awbDate).format('DD/MM/YYYY') + ']')
                            values[0].SubmittedDate = new Date(response.data.protoFitSMSBasicDetails[0].submittedDate);
                            //setMinApprovalDate((moment(values[0].SubmittedDate).format("YYYY-MM-DD")).toString());
                            values[0].SubmittedFor = response.data.protoFitSMSBasicDetails[0].submitFor;
                            values[0].BuyerId = response.data.protoFitSMSBasicDetails[0].buyerID;
                            values[0].BrandId = response.data.protoFitSMSBasicDetails[0].brandID;
                            values[0].SeasonId = response.data.protoFitSMSBasicDetails[0].seasonID;
                            values[0].SupplierId = response.data.protoFitSMSBasicDetails[0].supplierID;
                            values[0].SkuId = response.data.protoFitSMSBasicDetails[0].skuId;
                            values[0].TaskOwnerIdList = response.data.protoFitSMSBasicDetails[0].taskOwnerIdList;
                            values[0].SendThrough = response.data.protoFitSMSBasicDetails[0].sendThrough;
                            values[0].AWBDate = response.data.protoFitSMSBasicDetails[0].awbDate;
                            values[0].AWBName = response.data.protoFitSMSBasicDetails[0].awbName;
                            values[0].TNAId = response.data.protoFitSMSBasicDetails[0].tnaId;
                            values[0].TNAProtoFitOrSmsId = props.TNAProtoFitId;
                            values[0].ProtoFitSmsSubmissionId = response.data.protoFitSMSBasicDetails[0].protoFitSmsSubmissionId;
                            //values[0].ApprovalDate = response.data.protoFitSMSBasicDetails[0].approvalDate;
                            let getDateValue = new Date(response.data.protoFitSMSBasicDetails[0].approvalDate);
                            var dd = getDateValue.getDate();
                            var mm = getDateValue.getMonth() + 1;
                            var yyyy = getDateValue.getFullYear();

                            if (dd < 10) {
                                dd = '0' + dd;
                            }
                            if (mm < 10) {
                                mm = '0' + mm;
                            }
                            let coverted = mm + '/' + dd + '/' + yyyy;
                            values[0].ApprovalDate = getDateValue;
                            var overallresult = response.data.protoFitSMSBasicDetails[0].overAllResult;
                            values[0].OverAllResult = overallresult === 1 ? "1" : overallresult === 2 ? "2" : "3";

                            let images = [];
                            images = response.data.protoFitSMSBasicDetails[0].skuImageList.split(',');
                            setSubImageList(images);
                            setCurrimageindex(0);
                            // values[0].Fabric[0].fabricComments = [];
                            // values[0].Color[0].colorComments = [];
                            // values[0].Strikeoff[0].strikeoffComments = [];
                            // values[0].Trim[0].trimComments = [];
                            values[0].AdditionalComments = [];

                            response.data.tnaProtoFitOtherComments.map((x, index) => {
                                values[0].AdditionalComments.push({
                                    Comment: x.comment,
                                    CommentName: x.commentName
                                })
                            })
                            values[0].Fabric = [];
                            if (response.data.fabricDetails.length > 0) {
                                await response.data.fabricDetails.map((element, index) => {
                                    // values[0].Fabric[index].fabricComments = [];
                                    values[0].Fabric.push({
                                        fabricID: element.fabricID,
                                        fabricDetailName: element.fabricDetailName,
                                        quality: element.quality,
                                        protoFitSmsFabricId: element.protoFitSmsFabricId,
                                        value: element.value,
                                        label: element.label,
                                        fabricIndexName: element.fabricIndexName,
                                        fabricComments: response.data.fabricComments.length > 0 ? response.data.fabricComments : [],
                                        subfabricComment: response.data.subFabricComments.length > 0 ? response.data.subFabricComments : []

                                    })
                                })

                            }
                            values[0].Color = [];
                            if (response.data.colorDetails.length > 0) {
                                await response.data.colorDetails.map((element, index) => {
                                    // values[0].Fabric[index].fabricComments = [];
                                    values[0].Color.push({
                                        pantoneID: element.pantoneID,
                                        pantoneDetails: element.pantoneDetails,
                                        pantoneColorOrSwatchImage: element.pantoneColorOrSwatchImage,
                                        protoFitSmsColorId: element.protoFitSmsColorId,

                                        quality: element.quality,
                                        value: element.value,
                                        label: element.label,
                                        colorIndexName: element.colorIndexName,
                                        colorComments: response.data.colorComments.length > 0 ? response.data.colorComments : [],
                                        subcolorComment: response.data.subColorComments.length > 0 ? response.data.subColorComments : []
                                    })
                                })

                            }
                            values[0].Strikeoff = []
                            if (response.data.strikeoffDetails.length > 0) {
                                await response.data.strikeoffDetails.map((element, index) => {
                                    // values[0].Fabric[index].fabricComments = [];
                                    values[0].Strikeoff.push({
                                        strikeOffLogoID: element.strikeOffLogoID,
                                        stikeOffCodeandName: element.stikeOffCodeandName,
                                        protoFitSmsStrikeOffId: element.protoFitSmsStrikeOffId,
                                        strikeoffLogoPath: element.strikeoffLogoPath,
                                        quality: element.quality,
                                        value: element.value,
                                        label: element.label,
                                        strikeoffIndexName: element.strikeoffIndexName,
                                        strikeoffComments: response.data.strikeoffComments.length > 0 ? response.data.strikeoffComments : [],
                                        substrikeOffComment: response.data.subStrikeOffComments.length > 0 ? response.data.subStrikeOffComments : []
                                    })
                                })

                            }
                            values[0].Trim = [];
                            if (response.data.trimDetails.length > 0) {
                                await response.data.trimDetails.map((element, index) => {
                                    // values[0].Fabric[index].fabricComments = [];
                                    values[0].Trim.push({
                                        trimLogoInfoID: element.trimLogoInfoID,
                                        trimCodeandName: element.trimCodeandName,
                                        ProtoFitSmsTrimId: element.ProtoFitSmsTrimId,
                                        trimLogoPath: element.trimLogoPath,
                                        quality: element.quality,
                                        value: element.value,
                                        label: element.label,
                                        trimIndexName: element.trimIndexName,
                                        trimComments: response.data.trimComments.length > 0 ? response.data.trimComments : [],
                                        subtrimComment: response.data.subTrimComments.length > 0 ? response.data.subTrimComments : []
                                    })
                                })

                            }
                            values[0].Measurement.Files = [];
                            if (response.data.measurementFileInformation.length > 0) {
                                await response.data.measurementFileInformation.map((docs, i) => {
                                    values[0].Measurement.Files.push({
                                        FileID: docs.fileID,
                                        FileName: docs.fileName,
                                        FilePath: docs.filePath,
                                        FileType: docs.fileType
                                    })
                                });
                            }
                            // setFiles(filevals);


                            let arr = response.data.protoFitSMSBasicDetails[0].submitFor.split(',');
                            let arrVal = [];
                            for (let size of arr) {
                                let res = Submittedforoptions.filter(x => x.value === size);
                                arrVal.push({
                                    value: res[0].value,
                                    label: res[0].label
                                });
                            }
                            values[0].SubmittedForEdit = arrVal;


                            setInputFields(values);
                            ExpandAll();


                        }
                    }).catch(() => { });
                })();
            }
        }
    }, [props.delteProtoFitSmsApproval === true || props.editProtoFitSmsApproval === true])

    useEffect(() => {
        if (props.delteProtoFitSmsApproval === true) {
            setID({ showPopupDelete: true, Params: props.TNAProtoFitId });
        }
        else if (props.editProtoFitSmsApproval === true) {

            let getDateValue = new Date(values[0].ApprovalDate);
            var dd = getDateValue.getDate();
            var mm = getDateValue.getMonth() + 1;
            var yyyy = getDateValue.getFullYear();

            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            values[0].ApprovalDate = getDateValue;
            setInputFields(values);

        }
    }, [props.delteProtoFitSmsApproval === true && props.editProtoFitSmsApproval === true])
    // new Implement


    const headers = [
        { name: "Details", field: "StyleName", sortable: false },
        { name: "Quality", field: "StyleNo", sortable: false, widthsize: '20%' },
        { name: "Action", field: "", sortable: false, widthsize: '10%' },
    ];




    const previmage = () => {
        if (getCurrimageindex === 0) {
            setCurrimageindex(0);
        }
        else {
            setCurrimageindex(getCurrimageindex - 1);
        }
    }

    const nextimage = () => {
        if (getCurrimageindex === getSubImageList.length - 1) {
            setCurrimageindex(getSubImageList.length - 1);
        }
        else {
            setCurrimageindex(getCurrimageindex + 1);
        }
    }


    // const handleChange = (event, feild) => {
    //     const data = [...inputFields];
    //     let input = '';

    //     if (feild === "ApprovalDate") {
    //         values[0].ApprovalDate = event.target.value;
    //     }
    //     else if (feild === "Result") {
    //         values[0].OverAllResult = event.value;
    //     }
    //     setInputFields(data);
    // }
    const handleKeyDown = (event) => {
        if (event.key === " ") {
          const value = event.target.value;
          const selectionStart = event.target.selectionStart;
          const selectionEnd = event.target.selectionEnd;
      
          // Check if space is pressed at the beginning or if there's a consecutive space
          if (selectionStart === 0 || value[selectionStart - 1] === " " || value[selectionEnd] === " ") {
            event.preventDefault(); // Prevent adding another space
          }
        } else if (event.key === "Backspace") {
          const value = event.target.value;
          const selectionStart = event.target.selectionStart;
      
          // Check if backspace is pressed at the beginning and there's a space
          if (selectionStart === 0 && value[selectionStart] === " ") {
            event.preventDefault(); // Prevent deleting the space at the beginning
          }
        }
      }
      

    const handleCommentsChange = (event, feild) => {
       
        const values = [...inputFields];
        if (feild === "Measurement Comments") {
            values[0].AdditionalComments[0].Comment = event.target.value
        }
        else if (feild === "Construction Comments") {
            values[0].AdditionalComments[1].Comment = event.target.value
        }
        else if (feild === "Unbranded Trim Comments") {
            values[0].AdditionalComments[2].Comment = event.target.value
        }
        else if (feild === "Application Comments") {
            values[0].AdditionalComments[3].Comment = event.target.value
        }
        else if (feild === "Special Requirement Comments") {
            values[0].AdditionalComments[4].Comment = event.target.value
        }
        else if (feild === "QC Note") {
            values[0].AdditionalComments[5].Comment = event.target.value
        }
        else if (feild === "Amendments") {
            values[0].AdditionalComments[6].Comment = event.target.value
        }
        else if (feild === "Action Comments") {
            values[0].AdditionalComments[7].Comment = event.target.value
        }
        else if (feild === "Testing Comments") {
            values[0].AdditionalComments[8].Comment = event.target.value
        }
        else if (feild === "Packaging Comments") {
            values[0].AdditionalComments[9].Comment = event.target.value
        }
        else if (feild === "Additional comments") {
            values[0].AdditionalComments[10].Comment = event.target.value
        }

        setInputFields(values)
    }



    const handleChange = (event, feild) => {
        const values = [...inputFields];

        // if (event.target.value !== '') {
        //     input = event.target.value;
        // }
        // if (feild === "ApprovalDate") {
        //     if (new Date(input) < new Date(values[0].SubmittedDate)) {
        //         Nodify("Warning!", "warning", "Approval Date should not lesser than Submit Date.");
        //         values[0].ApprovalDate = "";
        //     }
        //     else {
        //         values[0].ApprovalDate = input;
        //     }
        //     setInputFields(data);

        // }

        // if (event !== null) {
        if (feild === "ApprovalDate") {
            //  input = event.target.value;
            if (event !== null) {
                if (new Date(event) < new Date(values[0].SubmittedDate)) {
                    Nodify("Warning!", "warning", "Approval Date should not lesser than Submit Date.");
                    values[0].ApprovalDate = ''
                }
                else {
                    values[0].ApprovalDate = event;
                }
            }
            else {
                values[0].ApprovalDate = ''
            }

            // values[0].ApprovalDate = event.target.value;
        }
        else if (feild === "Result") {
            let input = '';
            input = event.value;
            values[0].OverAllResult = input;
        }

        //// }
        // else {
        //      values[0].OverAllResult = '';
        // }


        setInputFields(values);
    }



    const ExpandAll = () => {
        // const Sample = [...SubmittedforDetails]

        if (values[0].SubmittedForEdit !== "") {
            values[0].SubmittedForEdit.forEach(element => {
                if (element.label === "Fabric") {
                    setIsFabricSelected(true);
                    setIsFabricExpanded(true);
                }
                if (element.label === "Color") {
                    setIsColorSelected(true);
                    setIsColorExpanded(true);
                }

                if (element.label === "Strikeoff") {
                    setIsStrikeoffSelected(true);
                    setIsStrikeoffExpanded(true);
                }

                if (element.label === "Trims") {
                    setIsTrimsSelected(true);
                    setIsTrimsExpanded(true);
                }
                if (element.label === "Measurement") {
                    setIsMeasurementSelected(true);
                    setIsMeasurementExpanded(true);
                }
                if (values[0].AdditionalComments[0].Comment.length > 0) {
                    setMessureComment(true)
                }
                if (values[0].AdditionalComments[1].Comment.length > 0) {
                    setIsConstructionComments(true)
                }
                if (values[0].AdditionalComments[2].Comment.length > 0) {
                    setIsUnbrandComments(true)
                }
                if (values[0].AdditionalComments[4].Comment.length > 0) {
                    setIsSRComments(true)
                }
                if (values[0].AdditionalComments[5].Comment.length > 0) {
                    setIsQCComments(true)
                }
                if (values[0].AdditionalComments[6].Comment.length > 0) {
                    setIsAmendmentsComments(true)
                }
                if (values[0].AdditionalComments[7].Comment.length > 0) {
                    setIsActionComments(true)
                }
                if (values[0].AdditionalComments[8].Comment.length > 0) {
                    setIsTestingComments(true)
                }
                if (values[0].AdditionalComments[9].Comment.length > 0) {
                    setIsPackagingComments(true)
                }
                if (values[0].AdditionalComments[10].Comment.length > 0) {
                    setIsAdditionalComments(true)
                }
                if (values[0].AdditionalComments[3].Comment.length > 0) {
                    setIsApplicationComments(true)
                }
            });

        }
        else {
            Nodify("Warning!", "warning", "Please select Any Dropdownfields.");
            // setSubmittedpopup(true);
        }
        // Sample.push({ Details: 0, Quality: 0, SubmitFor: "Fabric" });
        // SetsubmittedforS
    }

    const Comments = (index, Submitfordata) => {

        setComment(false);
        let Valueindex = ''
        if (Submitfordata === "Fabric") {
            values[0].Fabric[index].EnableComments = 1;
            Valueindex = index.toString()
        }
        else if (Submitfordata === "Color") {
            values[0].Color[index].EnableComments = 1;
            Valueindex = index.toString()
        }
        else if (Submitfordata === "Strikeoff") {
            values[0].Strikeoff[index].EnableComments = 1;
            Valueindex = index.toString()
        }
        else if (Submitfordata === "Trim") {
            values[0].Trim[index].EnableComments = 1;
            Valueindex = index.toString()
        }
        setInputFields(values);
        setCommentIndex(Valueindex)

    }

    const EditComments = (index, CmdIndex, Value, SubmitFor) => {
        if (SubmitFor === "Fabric") {
            values[0].Fabric[index].EnterComment = Value;
        }
        else if (SubmitFor === "Color") {
            values[0].Color[index].EnterComment = Value;
        }
        else if (SubmitFor === "Strikeoff") {
            values[0].Strikeoff[index].EnterComment = Value;
        }
        else if (SubmitFor === "Trim") {
            values[0].Trim[index].EnterComment = Value;
        }
        setEditedCommentIndex(CmdIndex);
        setInputFields(values);
    };

    const RemoveComments = (index, subChildIndex2, SubmitFor) => {
        if (SubmitFor === "Fabric") {
            values[0].Fabric[index].fabricComments.splice(subChildIndex2, 1);
        }
        else if (SubmitFor === "Color") {
            values[0].Color[index].colorComments.splice(subChildIndex2, 1);
        }
        else if (SubmitFor === "Strikeoff") {
            values[0].Strikeoff[index].strikeoffComments.splice(subChildIndex2, 1);
        }
        else if (SubmitFor === "Trim") {
            values[0].Trim[index].trimComments.splice(subChildIndex2, 1);
        }
        setInputFields(values);
    };

    const handleFullClose = (parentIndex, SubmitFor) => {
        if (SubmitFor === "Fabric") {
            values[0].Fabric[parentIndex].EnableComments = 0;
            values[0].Fabric[parentIndex].EnterComment = '';
        }
        else if (SubmitFor === "Color") {
            values[0].Color[parentIndex].EnableComments = 0;
            values[0].Color[parentIndex].EnterComment = '';
        }
        else if (SubmitFor === "Strikeoff") {
            values[0].Strikeoff[parentIndex].EnableComments = 0;
            values[0].Strikeoff[parentIndex].EnterComment = '';
        }
        else if (SubmitFor === "Trim") {
            values[0].Trim[parentIndex].EnableComments = 0;
            values[0].Trim[parentIndex].EnterComment = '';
        }
        setInputFields(values);
    }

    const onFocus = (e, field) => {
        var val = e.target.value;
        e.target.value = '';
        e.target.value = val;
    }

    const Drag = (props) => {
        return <Draggable handle=".modal-title"><ModalDialog {...props} /></Draggable>
    }

    const handleChangeComments = (event, parentIndex, SubmitFor) => {
        let inputText = ""
        //inputText = event.target.value.trim();
        if (SubmitFor === "Fabric") {
            if (event.target.value.trim() !== "") {
                inputText = event.target.value;
            }
            values[0].Fabric[parentIndex].EnterComment = inputText;
        }
        else if (SubmitFor === "Color") {
            if (event.target.value.trim() !== "") {
                inputText = event.target.value;
            }
            values[0].Color[parentIndex].EnterComment = inputText;

        }
        else if (SubmitFor === "Strikeoff") {
            if (event.target.value.trim() !== "") {
                inputText = event.target.value;

            }
            values[0].Strikeoff[parentIndex].EnterComment = inputText;


        }
        else if (SubmitFor === "Trim") {
            if (event.target.value.trim() !== "") {
                inputText = event.target.value;
            }
            values[0].Trim[parentIndex].EnterComment = inputText;


        }
        setInputFields(values);

    }

    const handleSaveComment = (index, assignValue, SubmitFor) => {
        let IsValid = true;
        if (assignValue === '' || assignValue === undefined) {
            setComment(true); IsValid = false;
            Nodify('Warning!', 'warning', "Please enter comments");
        }
        if (IsValid) {
            if (SubmitFor === "Fabric") {
                if (values[0].Fabric[index].EnterComment === '') {
                    // Nodify('Warning!', 'warning', 'Invalid file format selected');
                    setComment(true);
                }
                else {

                    if (values[0].Fabric[index].fabricComments && values[0].Fabric[index].fabricComments.length !== 0) {
                        if (values[0].Fabric[index].fabricComments[0].comments !== '') {
                            if (EditedCommentIndex !== '') {
                                values[0].Fabric[index].fabricComments[EditedCommentIndex].comments = assignValue;
                            }
                            else {
                                values[0].Fabric[index].fabricComments.push({
                                    protoFitSmsFabricCommentId: 0, comments: assignValue,
                                    fabricIndexName: (index).toString(),
                                    commentDate: props.props.TaskStatus === 1 || props.editProtoFitSmsApproval === false ? new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                                        + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) :
                                        new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                                        + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                                });
                            }
                        }
                        else {
                            values[0].Fabric[index].fabricComments[0] = {
                                protoFitSmsFabricCommentId: 0, comments: assignValue,
                                fabricIndexName: (index).toString(),
                                commentDate: props.TaskStatus === 1 || props.editProtoFitSmsApproval === false ? new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                                    + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) :
                                    new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                                    + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                            };
                        }
                    }
                    else {
                        values[0].Fabric[index].fabricComments.push({
                            protoFitSmsFabricCommentId: 0, comments: assignValue,
                            fabricIndexName: (index).toString(),
                            commentDate: props.TaskStatus === 1 || props.editProtoFitSmsApproval === false ? new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                                + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) :
                                new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                                + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                        });
                    }
                }

                setComment(true);
                values[0].Fabric[index].EnterComment = '';
                values[0].Fabric[index].EnableComments = 0;
            }

            if (SubmitFor === "Color") {
                if (values[0].Color[index].EnterComment === '') {
                    // Nodify('Warning!', 'warning', 'Invalid file format selected');
                    setComment(true);
                } else {
                    if (values[0].Color[index].colorComments.length !== 0) {
                        if (values[0].Color[index].colorComments[0].comments !== '') {
                            if (EditedCommentIndex !== '') {
                                values[0].Color[index].colorComments[EditedCommentIndex].comments = assignValue;
                            }
                            else {
                                values[0].Color[index].colorComments.push({
                                    protoFitSmsColorCommentId: 0, comments: assignValue,
                                    colorIndexName: (index).toString(),
                                    commentDate: props.TaskStatus === 1 || props.editProtoFitSmsApproval === false ? new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                                        + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) :
                                        new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                                        + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                                });
                            }
                        }
                        else {
                            values[0].Color[index].colorComments[0] = {
                                protoFitSmsColorCommentId: 0, comments: assignValue,
                                colorIndexName: (index).toString(),
                                commentDate: props.TaskStatus === 1 || props.editProtoFitSmsApproval === false ? new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                                    + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) :
                                    new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                                    + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                            };
                        }
                    }
                    else {
                        values[0].Color[index].colorComments.push({
                            protoFitSmsColorCommentId: 0, comments: assignValue,
                            colorIndexName: (index).toString(),
                            commentDate: props.TaskStatus === 1 || props.editProtoFitSmsApproval === false ? new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                                + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) :
                                new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                                + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })

                        });
                    }
                }
                setComment(true);
                values[0].Color[index].EnterComment = '';
                values[0].Color[index].EnableComments = 0;
            }
            if (SubmitFor === "Strikeoff") {
                if (values[0].Strikeoff[index].EnterComment === '') {
                    // Nodify('Warning!', 'warning', 'Invalid file format selected');
                    setComment(true);
                } else {
                    if (values[0].Strikeoff[index].strikeoffComments.length !== 0) {
                        if (values[0].Strikeoff[index].strikeoffComments[0].comments !== '') {
                            if (EditedCommentIndex !== '') {
                                values[0].Strikeoff[index].strikeoffComments[EditedCommentIndex].comments = assignValue;
                            }
                            else {
                                values[0].Strikeoff[index].strikeoffComments.push({
                                    protoFitSmsStrikeoffCommentId: 0, comments: assignValue,
                                    strikeoffIndexName: (index).toString(),
                                    commentDate: props.TaskStatus === 1 || props.editProtoFitSmsApproval === false ? new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                                        + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) :
                                        new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                                        + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                                });
                            }
                        }
                        else {
                            values[0].Strikeoff[index].strikeoffComments[0] = {
                                protoFitSmsStrikeoffCommentId: 0, comments: assignValue,
                                strikeoffIndexName: (index).toString(),
                                commentDate: props.TaskStatus === 1 || props.editProtoFitSmsApproval === false ? new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                                    + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) :
                                    new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                                    + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                            };
                        }
                    }
                    else {
                        values[0].Strikeoff[index].strikeoffComments.push({
                            protoFitSmsStrikeoffCommentId: 0, comments: assignValue,
                            strikeoffIndexName: (index).toString(),
                            commentDate: props.TaskStatus === 1 || props.editProtoFitSmsApproval === false ? new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                                + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) :
                                new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                                + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })

                        });
                    }
                }
                setComment(true);
                values[0].Strikeoff[index].EnterComment = '';
                values[0].Strikeoff[index].EnableComments = 0;
            }
            if (SubmitFor === "Trim") {
                if (values[0].Trim[index].EnterComment === '') {
                    // Nodify('Warning!', 'warning', 'Invalid file format selected');
                    setComment(true);
                } else {
                    if (values[0].Trim[index].trimComments.length !== 0) {
                        if (values[0].Trim[index].trimComments[0].comments !== '') {
                            if (EditedCommentIndex !== '') {
                                values[0].Trim[index].trimComments[EditedCommentIndex].comments = assignValue;
                            }
                            else {
                                values[0].Trim[index].trimComments.push({
                                    protoFitSmsTrimCommentId: 0, comments: assignValue,
                                    trimIndexName: (index).toString(),
                                    commentDate: props.TaskStatus === 1 || props.editProtoFitSmsApproval === false ? new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                                        + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) :
                                        new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                                        + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                                });
                            }
                        }
                        else {
                            values[0].Trim[index].trimComments[0] = {
                                protoFitSmsTrimCommentId: 0, comments: assignValue,
                                trimIndexName: (index).toString(),
                                commentDate: props.TaskStatus === 1 || props.editProtoFitSmsApproval === false ? new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                                    + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) :
                                    new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                                    + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                            };
                        }
                    }
                    else {
                        values[0].Trim[index].trimComments.push({
                            protoFitSmsTrimCommentId: 0, comments: assignValue,
                            trimIndexName: (index).toString(),
                            commentDate: props.TaskStatus === 1 || props.editProtoFitSmsApproval === false ? new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                                + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) :
                                new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
                                + ' ' + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })

                        });
                    }
                }
                setComment(true);
                values[0].Trim[index].EnterComment = '';
                values[0].Trim[index].EnableComments = 0;

            }
        }

        setInputFields(values);
        setEditedCommentIndex('');
        setSubmitted(true)


    };


    const handleExpandRow = (submitfor) => {
        if (submitfor === "Fabric") {
            if (IsFabricExpanded === true) {
                setIsFabricExpanded(false);
            }
            else {
                setIsFabricExpanded(true);
            }
        }
        if (submitfor === "Color") {
            if (IsColorExpanded === true) {
                setIsColorExpanded(false);
            }
            else {
                setIsColorExpanded(true);
            }
        }
        if (submitfor === "Strikeoff") {
            if (IsStrikeoffExpanded === true) {
                setIsStrikeoffExpanded(false);
            }
            else {
                setIsStrikeoffExpanded(true);
            }
        }
        if (submitfor === "Trims") {
            if (IsTrimsExpanded === true) {
                setIsTrimsExpanded(false);
            }
            else {
                setIsTrimsExpanded(true);
            }
        }
        if (submitfor === "Measurement") {
            if (IsMeasurementExpanded === true) {
                setIsMeasurementExpanded(false);
            }
            else {
                setIsMeasurementExpanded(true);
            }
        }
        if (submitfor === "Measurement Comments") {
            if (IsMessureComment === true) {
                setMessureComment(false);
            }
            else {
                setMessureComment(true);
            }
        }
        if (submitfor === "Construction Comments") {
            if (IsConstructionComments === true) {
                setIsConstructionComments(false);
            }
            else {
                setIsConstructionComments(true);
            }

        }
        if (submitfor === "Unbranded Trim Comments") {
            if (IsUnbrandComments === true) {
                setIsUnbrandComments(false);
            }
            else {
                setIsUnbrandComments(true);
            }

        }
        if (submitfor === "Application Comments") {
            if (IsApplicationComments === true) {
                setIsApplicationComments(false);
            }
            else {
                setIsApplicationComments(true);
            }

        }

        if (submitfor === "Special Requirement Comments") {
            if (IsSRComments === true) {
                setIsSRComments(false);
            }
            else {
                setIsSRComments(true);
            }

        }
        if (submitfor === "QC Note") {
            if (IsQCComments === true) {
                setIsQCComments(false);
            }
            else {
                setIsQCComments(true);
            }

        }
        if (submitfor === "Amendments") {
            if (IsAmendmentsComments === true) {
                setIsAmendmentsComments(false);
            }
            else {
                setIsAmendmentsComments(true);
            }

        }
        if (submitfor === "Action Comments") {
            if (IsActionComments === true) {
                setIsActionComments(false);
            }
            else {
                setIsActionComments(true);
            }

        }
        if (submitfor === "Testing Comments") {
            if (IsTestingComments === true) {
                setIsTestingComments(false);
            }
            else {
                setIsTestingComments(true);
            }

        }
        if (submitfor === "Packaging Comments") {
            if (IsPackagingComments === true) {
                setIsPackagingComments(false);
            }
            else {
                setIsPackagingComments(true);
            }

        }
        if (submitfor === "Additional comments") {
            if (IsAdditionalComments === true) {
                setIsAdditionalComments(false);
            }
            else {
                setIsAdditionalComments(true);
            }

        }









    }





    const ViewFile = (event, filename) => { // event.preventDefault();
        setIsOpenimg(true);
        setImagename(filename);
    }

    const MainFields = [{
        Operation: 0,
        TNAId: props.TNAId,
        ProtoFitSmsSubmissionId: 0,
        BuyerId: 0,
        BrandId: 0,
        SeasonId: 0,
        SupplierId: 0,
        StyleId: 0,
        StyleName: '',
        StyleCode: '',
        SkuId: 0,
        IdPoNos: '',
        TaskOwnerIdList: '',
        SendThrough: 0,
        SendTo: 0,
        AWBName: '',
        AWBDate: '',
        SubmitFor: '',
        GarmentSizeandQty: '',
        ProtoFitSmsStatus: 0,
        SubmittedDate: '',
        ApprovedDate: '',
        TNAProtoFitSmsFabricInformation: [],
        TNAProtoFitSmsPantoneColorsInformation: [],
        TNAProtoFitSmsStrikeOffInformation: [],
        TNAProtoFitSmsTrimInformation: [],
        TNAProtoFitOtherComments: [],
        TNAProtoFitSmsFabricCommentsInformation: [],
        TNAProtoFitSmsPantoneColorCommentsInformation: [],
        TNAProtoFitSmsStrikeoffCommentsInformation: [],
        TNAProtoFitSmsTrimCommentsInformation: [],
        TNAProtoFitOrSmsId: props.TNAProtoFitId,
        TaskName: props.TNATaskName,
        OverAllResult: '',
        CreatedBy: currentUser.employeeinformationID,
    }];
    // const Delcallback = (value) => {
    //     setID({ showPopupDelete: false, Params: {} });
    //     props.ProtoFitAprovalCallback(true, 'Delete');
    // }
    const Delcallback = (value) => {

        if (value === true) {
            setID({ showPopupDelete: false, Params: {} });
            props.ProtoFitAprovalCallback(true, 'Delete');
        }
        else {
            setID({ showPopupDelete: false, Params: {} });
            props.ProtoFitAprovalCallback(false, 'Not A Delete')
        }
        //setID({ showPopupDelete: false, Params: {} });
    }
    //Save
    const SaveSubmission = () => {
        if (values[0].ApprovalDate === '' || values[0].OverAllResult === '') {
            Nodify("Warning!", "warning", "Please fill the mandatory(*) fields.");
            setSubmitted(true);
            setSubmittedpopup(true)
        }
        else {

            MainFields[0].TNAProtoFitSmsFabricInformation = [];
            MainFields[0].TNAProtoFitSmsPantoneColorsInformation = [];
            MainFields[0].TNAProtoFitSmsStrikeOffInformation = [];
            MainFields[0].TNAProtoFitSmsTrimInformation = [];

            MainFields[0].TNAProtoFitSmsFabricCommentsInformation = [];
            MainFields[0].TNAProtoFitSmsPantoneColorCommentsInformation = [];
            MainFields[0].TNAProtoFitSmsStrikeoffCommentsInformation = [];
            MainFields[0].TNAProtoFitSmsTrimCommentsInformation = [];

            MainFields[0].TNAProtoFitSMSMeasurementFileInformation = [];
            MainFields[0].TNAProtoFitOtherComments = []

            MainFields[0].Operation = props.props.location.pathname !== '/TNAEdit' ? 1 : props.editProtoFitSmsApproval === false ? 1 : 2;
            // MainFields[0].Operation = props.editProtoFitSmsApproval === false ? 1 : 2;
            MainFields[0].StyleId = values[0].StyleID;
            MainFields[0].StyleName = values[0].StyleName;
            MainFields[0].StyleCode = values[0].StyleNo;
            MainFields[0].IdPoNos = values[0].IdpoNo;
            MainFields[0].SendThrough = values[0].SendThrough;
            MainFields[0].SentTo = values[0].SendTo;
            MainFields[0].AWBName = values[0].AWBName;
            MainFields[0].AWBDate = values[0].AWBDate === '' ? null : values[0].AWBDate;
            MainFields[0].SubmitFor = values[0].SubmittedFor;
            MainFields[0].GarmentSizeandQty = values[0].SizeIdQtyIds;
            MainFields[0].SubmittedDate = new Date(values[0].SubmittedDate);
            MainFields[0].ApprovedDate = new Date(values[0].ApprovalDate.setMinutes(values[0].ApprovalDate.getMinutes() + 370));
            MainFields[0].BuyerId = values[0].BuyerId;
            MainFields[0].BrandId = values[0].BrandId;
            MainFields[0].SeasonId = values[0].SeasonId;
            MainFields[0].SupplierId = values[0].SupplierId;
            MainFields[0].SkuId = values[0].SkuId;
            MainFields[0].TaskOwnerIdList = values[0].TaskOwnerIdList;
            MainFields[0].TaskName = props.ParentTask;
            MainFields[0].OverAllResult = parseInt(values[0].OverAllResult);
            MainFields[0].TNAId = values[0].TNAId;
            MainFields[0].TNAProtoFitOrSmsId = props.TNAProtoFitId;
            MainFields[0].ProtoFitSmsSubmissionId = values[0].ProtoFitSmsSubmissionId;
            MainFields[0].TNAProtoFitOtherComments = values[0].AdditionalComments

            values[0].SubmittedForEdit && values[0].SubmittedForEdit.map((Item, i) => {
                if (Item.label === "Fabric") {
                    values[0].Fabric && values[0].Fabric.map((item, f) => {

                        MainFields[0].TNAProtoFitSmsFabricInformation.push({
                            ProtoFitSmsFabricId: item.protoFitSmsFabricId,
                            FabricId: parseInt(item.fabricID),
                            Quality: (item.quality === '' || item.quality === undefined) ? null : parseInt(item.quality),
                            FabricIndexName: f.toString(),
                            ProtoFitSmsFabricStatus: 0,
                        });

                        item.fabricComments && item.fabricComments.filter(x => x.fabricIndexName === item.fabricIndexName).map((item2, j) => {
                            MainFields[0].TNAProtoFitSmsFabricCommentsInformation.push({
                                ProtoFitSmsFabricCommentId: item2.protoFitSmsFabricCommentId,
                                Comments: item2.comments,
                                FabricIndexName: item2.fabricIndexName
                            });
                        });

                    });
                } else if (Item.label === "Color") {
                    values[0].Color && values[0].Color.map((item, c) => {
                        MainFields[0].TNAProtoFitSmsPantoneColorsInformation.push({
                            ProtoFitSmsColorId: item.protoFitSmsColorId,
                            PantoneId: parseInt(item.pantoneID),
                            Quality: (item.quality === '' || item.quality === undefined) ? null : parseInt(item.quality),
                            ColorIndexName: c.toString(),
                            ProtoFitSmsPantoneStatus: 0,
                        });

                        item.colorComments && item.colorComments.filter(x => x.colorIndexName === item.colorIndexName).map((item2, j) => {
                            MainFields[0].TNAProtoFitSmsPantoneColorCommentsInformation.push({
                                ProtoFitSmsColorCommentId: item2.protoFitSmsColorCommentId,
                                Comments: item2.comments,
                                ColorIndexName: item2.colorIndexName
                            });
                        });
                    });

                } else if (Item.label === "Strikeoff") {
                    values[0].Strikeoff && values[0].Strikeoff.map((item, s) => {
                        MainFields[0].TNAProtoFitSmsStrikeOffInformation.push({
                            ProtoFitSmsStrikeOffId: item.protoFitSmsStrikeOffId,
                            StrikeOffLogoID: parseInt(item.strikeOffLogoID),
                            Quality: (item.quality === '' || item.quality === undefined) ? null : parseInt(item.quality),
                            StrikeOffIndexName: s.toString(),
                            ProtoFitSmsStrikeOffStatus: 0,
                        });

                        item.strikeoffComments && item.strikeoffComments.filter(x => x.strikeoffIndexName === item.strikeoffIndexName).map((item2, j) => {
                            MainFields[0].TNAProtoFitSmsStrikeoffCommentsInformation.push({
                                ProtoFitSmsStrikeoffCommentId: item2.protoFitSmsStrikeoffCommentId,
                                Comments: item2.comments,
                                StrikeoffIndexName: item2.strikeoffIndexName
                            });
                        });
                    });
                } else if (Item.label === "Trims") {
                    values[0].Trim && values[0].Trim.map((item, t) => {
                        MainFields[0].TNAProtoFitSmsTrimInformation.push({
                            ProtoFitSmsTrimId: item.protoFitSmsTrimId,
                            TrimLogoID: parseInt(item.trimLogoInfoID),
                            Quality: (item.quality === '' || item.quality === undefined) ? null : parseInt(item.quality),
                            TrimIndexName: t.toString(),
                            ProtoFitSmsTrimStatus: 0,
                        });

                        item.trimComments && item.trimComments.filter(x => x.trimIndexName === item.trimIndexName).map((item2, j) => {
                            MainFields[0].TNAProtoFitSmsTrimCommentsInformation.push({
                                ProtoFitSmsTrimCommentId: item2.protoFitSmsTrimCommentId,
                                Comments: item2.comments,
                                TrimIndexName: item2.trimIndexName
                            });
                        });
                    });

                } else if (Item.label === "Measurement") {
                    MainFields[0].TNAProtoFitSMSMeasurementFileInformation = values[0].Measurement.Files;
                }

            });

            //Fabric


            // values[0].SubmissionApprovalStatus = 1;

            dispatch(InsertUpdateTNAProtoFitSmsSubmissionApproval(MainFields[0]))
                .then(data => {
                    if (data.outputResult === "1") {
                        Nodify('Success!', 'success', MainFields[0].TaskName + ' Added Successfully')
                        //Nodify('Success!', 'success', MainFields[0].TaskName === "SMS Approval" ? 'SMS Approval Added Successfully' : MainFields[0].TaskName === "Proto Sample Approval" ? "Proto Sample Approval Added Successfully" : 'Fit Sample Approval Added Successfully');
                        props.ProtoFitAprovalCallback(true, 'Save',0)
                    }
                    else if (data.outputResult === "2") {
                        Nodify('Success!', 'success', MainFields[0].TaskName + ' Updated Successfully')
                        //Nodify('Success!', 'success', MainFields[0].TaskName === "SMS Approval" ? 'SMS Approval Updated Successfully' : MainFields[0].TaskName === "Proto Sample Approval" ? "Proto Sample Approval Updated Successfully" : 'Fit Sample Approval Updated Successfully');
                        props.ProtoFitAprovalCallback(true, 'Update')
                    }
                    else if (data.outputResult === "-1") {
                        Nodify("Warning!", "warning", MainFields[0].TaskName + ' name already Exist')
                        //Nodify('Success!', 'success', MainFields[0].TaskName === "SMS Approval" ? 'SMS Approval name already Exist' : MainFields[0].TaskName === "Proto Sample Approval" ? "Proto Sample Approval name already Exist" : 'Fit Sample Approval name already Exist');
                    }
                })
                .catch(e => {
                    console.log(e);
                });


        }

    }

    const CustomInputApproval = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
                style={{
                    border: submittedpopup && props.value === '' ? '1px solid red' : '', textTransform: "uppercase"
                }}
            />
        )
    }



    return (

        <>
            <div className="row">
                <Fragment>
                    <div className={DynamicClassName}>
                        <div className="widget flat radius-bordered">
                            <div className="panel-group accordion" id="accordion">
                                <div className="panel panel-default">

                                    <div id={"collapsePrograme_"} className={getHeader !== "ADD" ? 'panel-collapse collapse in' : 'panel-collapse collapse in'}>
                                        <div className="widget-body">
                                            <div id="registration-form">
                                                <Form>

                                                    <div className="row" style={{ overflowX: "hidden", height: "630px", paddingRight: "10px", paddingLeft: "10px" }} >
                                                        <div className="row"
                                                        // style={{ pointerEvents: props.TaskStatus === 3 ? "none" : '' }}
                                                        >
                                                            <div className="col-sm-12">
                                                                <div className="row">
                                                                    <div className="col-sm-3">
                                                                        <div className='form-group'>
                                                                            <label htmlFor="BuyerID">Buyer-Season-Brand</label>
                                                                            <span className='input-icon icon-right'>
                                                                                <Input
                                                                                    placeholder="Buyer-Season-Brand"
                                                                                    className="form-control"
                                                                                    title={inputFields[0].BuyerBrandSeasonName}
                                                                                    name="Buyer-Season-Brand"
                                                                                    id={
                                                                                        "Buyer-Season-Brand"
                                                                                    }
                                                                                    value={
                                                                                        inputFields[0].BuyerBrandSeasonName
                                                                                    }
                                                                                    disabled
                                                                                ></Input>
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-3">
                                                                        <div className='form-group'>
                                                                            <label htmlFor="SupplierID">Supplier</label>
                                                                            <span className='input-icon icon-right'>
                                                                                <Input
                                                                                    placeholder="Supplier"
                                                                                    className="form-control"
                                                                                    title={inputFields[0].SupplierName}
                                                                                    name="SupplierID"
                                                                                    id={
                                                                                        "SupplierID"
                                                                                    }
                                                                                    value={
                                                                                        inputFields[0].SupplierName
                                                                                    }
                                                                                    disabled

                                                                                ></Input>
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-3">
                                                                        <div className='form-group'>
                                                                            <label htmlFor="BuyerID">Style Name-Style No</label>
                                                                            <span className='input-icon icon-right'>
                                                                                <Input
                                                                                    placeholder="Style Name"
                                                                                    className="form-control"
                                                                                    title={inputFields[0].StyleName}
                                                                                    name="StyleName"
                                                                                    id={
                                                                                        "StyleName"
                                                                                    }
                                                                                    value={inputFields[0].StyleName + '-' + inputFields[0].StyleNo}
                                                                                    disabled
                                                                                ></Input>
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor={"Sendthrough"}>Courier Information<span className="text-danger">*</span></label>
                                                                            <Input
                                                                                placeholder="Courier Information"
                                                                                className="form-control"
                                                                                title={inputFields[0].SubmitSentDetails}
                                                                                name="IDNo"
                                                                                id={
                                                                                    "IDNo"
                                                                                }
                                                                                value={
                                                                                    inputFields[0].SubmitSentDetails
                                                                                }

                                                                                disabled
                                                                            ></Input>




                                                                        </div>
                                                                    </div>

                                                                </div>



                                                                <div className="row">
                                                                    <div className="col-sm-3">
                                                                        <div className='form-group'>
                                                                            <label htmlFor="Name" >PO/ID No</label>
                                                                            <span className='input-icon icon-right'>
                                                                                <Input
                                                                                    placeholder="PO/ID No"
                                                                                    className="form-control"
                                                                                    title={inputFields[0].IdpoNo}
                                                                                    name="IDNo"
                                                                                    id={
                                                                                        "IDNo"
                                                                                    }
                                                                                    value={
                                                                                        inputFields[0].IdpoNo
                                                                                    }

                                                                                    disabled
                                                                                ></Input>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor={"SendToID"}>Submitted Date<span className="text-danger">*</span></label>
                                                                            <span className="input-icon icon-right">
                                                                                <DatePicker className="form-control" name="SubmittedDate"
                                                                                    id={
                                                                                        "SubmittedDate"
                                                                                    }
                                                                                    selected={
                                                                                        inputFields[0].SubmittedDate === '' ? '' : inputFields[0].SubmittedDate
                                                                                    }

                                                                                    dateFormat="dd/MM/yyyy"
                                                                                    peekNextMonth

                                                                                    //customInput={<CustomInputApproval />}
                                                                                    disabled
                                                                                />

                                                                                {/* <Input
                                                                                    placeholder="ID/Po No"
                                                                                    className="form-control"
                                                                                    title={inputFields[0].SubmittedDate}
                                                                                    name="IDNo"
                                                                                    id={
                                                                                        "IDNo"
                                                                                    }
                                                                                    value={
                                                                                        inputFields[0].SubmittedDate
                                                                                    }

                                                                                    disabled
                                                                                ></Input> */}
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor={"SendToID"}>SKU name<span className="text-danger">*</span></label>
                                                                            <span className="input-icon icon-right">

                                                                                <Input
                                                                                    placeholder="SKU Name"
                                                                                    className="form-control"
                                                                                    title={inputFields[0].SkuName}
                                                                                    name="SendTo"
                                                                                    id={
                                                                                        "SendTo"
                                                                                    }
                                                                                    value={
                                                                                        inputFields[0].SkuName
                                                                                    }
                                                                                    disabled

                                                                                ></Input>

                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor={"SubmitFor"}>Submit For</label>
                                                                            <span className="input-icon icon-right">

                                                                                <Reactselect className="basic-single" name="SubmitFor"
                                                                                    id={
                                                                                        "SubmitFor"
                                                                                    }
                                                                                    isDisabled={true}
                                                                                    isLoading={false}
                                                                                    isClearable={true}
                                                                                    isSearchable={true}
                                                                                    isMulti
                                                                                    value={inputFields[0].SubmittedForEdit}
                                                                                    options={Submittedforoptions}

                                                                                />

                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-3">
                                                                        <div className='form-group'>
                                                                            <label htmlFor="Name" >Sample Size and Qty</label>
                                                                            <span className='input-icon icon-right'>
                                                                                <input
                                                                                    placeholder="Sample Size and Qty"
                                                                                    className="form-control"
                                                                                    name="PO/ID No"
                                                                                    id={
                                                                                        "ID/Po No"
                                                                                    }
                                                                                    value={
                                                                                        inputFields[0].SizeandQty
                                                                                    }
                                                                                    title={ inputFields[0].SizeandQty}
                                                                                    disabled
                                                                                ></input>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor={"SubmitFor"}>Approval Date<span className="text-danger">*</span></label>
                                                                            <span className="input-icon icon-right">
                                                                                {
                                                                                    props.props.location.pathname !== '/TNAEdit' && props.TaskStatus === 3 ?
                                                                                        <DatePicker className="form-control" name="SubmittedDate"
                                                                                            id={
                                                                                                "SubmittedDate"
                                                                                            }
                                                                                            selected={
                                                                                                inputFields[0].ApprovalDate
                                                                                            }

                                                                                            dateFormat="dd/MM/yyyy"
                                                                                            peekNextMonth

                                                                                            customInput={<CustomInputApproval />}
                                                                                            disabled
                                                                                        />
                                                                                        :
                                                                                        props.TaskStatus === 3 && props.editProtoFitSmsApproval === false ?
                                                                                            <DatePicker className="form-control" name="SubmittedDate"
                                                                                                id={
                                                                                                    "SubmittedDate"
                                                                                                }
                                                                                                selected={
                                                                                                    inputFields[0].ApprovalDate === '' ? '' : inputFields[0].ApprovalDate
                                                                                                }

                                                                                                dateFormat="dd/MM/yyyy"
                                                                                                peekNextMonth

                                                                                                customInput={<CustomInputApproval />}
                                                                                                disabled
                                                                                            />
                                                                                            :

                                                                                            <DatePicker className="form-control" name="ApprovalDate"
                                                                                                id={
                                                                                                    "ApprovalDate"
                                                                                                }
                                                                                                selected={
                                                                                                    inputFields[0].ApprovalDate
                                                                                                }
                                                                                                onChange={event => handleChange(event, 'ApprovalDate')}
                                                                                                dateFormat="dd/MM/yyyy"
                                                                                                peekNextMonth
                                                                                                style={{
                                                                                                    color:
                                                                                                        inputFields[0].ApprovalDate === ""
                                                                                                            ? "#bfdea1"
                                                                                                            : "#000",
                                                                                                    border:
                                                                                                        submitted &&
                                                                                                            inputFields[0].ApprovalDate === ""
                                                                                                            ? "1px solid red"
                                                                                                            : "",
                                                                                                }}
                                                                                                dropdownMode="scroll"
                                                                                                autoComplete="off"
                                                                                                customInput={<CustomInputApproval />}
                                                                                                isClearable={inputFields[0].ApprovalDate === null ? false : true}
                                                                                            />





                                                                                    // <input id="ApprovalDate" name="ApprovalDate"
                                                                                    //     value={inputFields[0].ApprovalDate} placeholder="DD/MM/YYYY"
                                                                                    //     type="date" autoComplete="off"
                                                                                    //     min={minApprovalDate}
                                                                                    //     className={'form-control'} onKeyDown={(e) => e.preventDefault()}
                                                                                    //     style={{
                                                                                    //         border: submitted && inputFields[0].ApprovalDate === '' ? '1px solid red' : '', textTransform: "uppercase"
                                                                                    //     }}
                                                                                    //     onChange={event => handleChange(event, 'ApprovalDate')}
                                                                                    // />


                                                                                }


                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-3" style={{ marginTop: "-56px" }}>
                                                                        <div className="form-group">
                                                                            <td>
                                                                                <div
                                                                                    htmlFor="upload-button"
                                                                                >
                                                                                    <i className="fa fa-chevron-circle-left fa-2x" onClick={() => previmage()} style={{ cursor: "pointer" }}></i>
                                                                                    {
                                                                                        getSubImageList.length !== 0 ?
                                                                                            <img src={window.$APIBaseURL + "Images" + getSubImageList[getCurrimageindex]} alt="No Image" width="130px" height="130px" style={{ border: "2px solid black" }} />
                                                                                            :
                                                                                            <div align="center" style={{ width: "130px", height: "130px" }}>
                                                                                                No Images Found
                                                                                            </div>
                                                                                    }
                                                                                    <i className="fa fa-chevron-circle-right fa-2x" onClick={() => nextimage()} style={{ cursor: "pointer" }}></i>


                                                                                </div>
                                                                            </td>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                                {/* <div className="row">
                                                                <div className="col-sm-3">
                                                                    <div className="form-group">
                                                                        <span type="button" className="btn btn-success" onClick={() => ExpandAll()}>ExpandAll</span>&nbsp;
                                                                        <span className="btn btn-success" onClick={() => CollapseAll()}>CollapseAll</span>
                                                                    </div>
                                                                </div>

                                                            </div> */}



                                                                <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">

                                                                    <tbody> {

                                                                        <Fragment>
                                                                            {
                                                                                IsFabricSelected === true ?
                                                                                    <Fragment>
                                                                                        <tr>
                                                                                            <td style={{ width: "200px" }}>
                                                                                                <i
                                                                                                    className={
                                                                                                        IsFabricExpanded === true ?
                                                                                                            'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                    }
                                                                                                    onClick={event => handleExpandRow("Fabric")}

                                                                                                ></i>
                                                                                                Fabric</td>

                                                                                        </tr>

                                                                                        {
                                                                                            IsFabricExpanded === true ?
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                                            <TableHeader headers={headers} />
                                                                                                            <tbody>

                                                                                                                {
                                                                                                                    inputFields[0].Fabric &&
                                                                                                                        inputFields[0].Fabric.length > 0 ?
                                                                                                                        inputFields[0].Fabric.map((item, index) => (
                                                                                                                            <Fragment>

                                                                                                                                <tr>

                                                                                                                                    <td>
                                                                                                                                        <div className="row">
                                                                                                                                            <div className="col-lg-8">
                                                                                                                                                <select id={"Details"} name="Details" className="form-select"
                                                                                                                                                    value={item.value}
                                                                                                                                                    disabled
                                                                                                                                                    style={{ border: submitted && item.value === 0 ? '1px solid red' : '' }}
                                                                                                                                                >
                                                                                                                                                    <option value="0">-Select Details-</option>
                                                                                                                                                    {
                                                                                                                                                        inputFields[0].Fabric.map(item => (
                                                                                                                                                            <option value={item.value}>
                                                                                                                                                                {item.label}
                                                                                                                                                            </option>
                                                                                                                                                        ))
                                                                                                                                                    }
                                                                                                                                                </select>
                                                                                                                                            </div>

                                                                                                                                        </div>


                                                                                                                                    </td>
                                                                                                                                    <td><select id={"Quality"} name="Quality" className="form-select"
                                                                                                                                        value={item.quality}
                                                                                                                                        disabled
                                                                                                                                        style={{ border: submitted && item.quality === "0" ? '1px solid red' : '' }}>
                                                                                                                                        <option value="0" key="0" style={{ color: '#bfdea1' }}> - Select Quality- </option>
                                                                                                                                        <option value="1" key="1">Original</option>
                                                                                                                                        <option value="2" key="2">Available</option>'
                                                                                                                                        <option value="3" key="3">Not Attach</option>
                                                                                                                                    </select></td>
                                                                                                                                    <td style={{ padding: '15px' }}>
                                                                                                                                        <span className={item.fabricComments && item.fabricComments.length !== 0 ? 'btn btn-xs btn-danger' : 'btn btn-xs btn-primary'} title="Comments"
                                                                                                                                            disabled={props.TaskStatus === 3 && (props.editProtoFitSmsApproval === false)
                                                                                                                                                ? true : (((props.props.location.pathname === '/TNAReport' || props.props.location.pathname === '/Dashboard') && props.TaskStatus === 3)) || (props.props.location.pathname === '/StyleListRegister') ? true : false}
                                                                                                                                            onClick={() => Comments(index, 'Fabric')}
                                                                                                                                            style={{
                                                                                                                                                pointerEvents: props.props.location.pathname === '/TNAReport' && props.TaskStatus === 3 ? 'none' :
                                                                                                                                                    props.TaskStatus === 3 && (props.editProtoFitSmsApproval === false)
                                                                                                                                                        ? 'none' : '', opacity: props.props.location.pathname === '/TNAReport' && props.TaskStatus === 3 ? '0.6' :
                                                                                                                                                            props.TaskStatus === 3 && (props.editProtoFitSmsApproval === false)
                                                                                                                                                                ? '0.6' : '',
                                                                                                                                                background: item.fabricComments && item.fabricComments.length === 1 ? 'red' : item.fabricComments && item.fabricComments.length === 1 ? 'red' : '#427fed',
                                                                                                                                                color: 'white',
                                                                                                                                                padding: '3px 4px',
                                                                                                                                                borderRadius: '2px',
                                                                                                                                                border: item.fabricComments && item.fabricComments.length === 1 ? 'red' : item.fabricComments && item.fabricComments.length === 1 ? 'red' : '#427fed',
                                                                                                                                            }}

                                                                                                                                        >
                                                                                                                                            <i className="fa fa-comment"></i>
                                                                                                                                        </span>
                                                                                                                                        {props.props.location.pathname !== '/StyleListRegister' &&
                                                                                                                                            item.EnableComments === 1 ?
                                                                                                                                            <Modal show={item.EnableComments === 1} dialogAs={Drag} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleFullClose(index, "Fabric")}>
                                                                                                                                                <Modal.Header closeButton>
                                                                                                                                                    <Modal.Title>
                                                                                                                                                        Comments
                                                                                                                                                    </Modal.Title>
                                                                                                                                                </Modal.Header>
                                                                                                                                                <Modal.Body>

                                                                                                                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                                                                                        <thead>
                                                                                                                                                            <tr>
                                                                                                                                                                <th>Submission Comments : Fabric</th>

                                                                                                                                                            </tr>
                                                                                                                                                        </thead>
                                                                                                                                                        <tbody>

                                                                                                                                                            {

                                                                                                                                                                item.subfabricComment.filter(x => x.fabricIndexName === getCommentIndex).map((commetdata, index) => (
                                                                                                                                                                    <tr>
                                                                                                                                                                        <td >
                                                                                                                                                                            {commetdata.comments}
                                                                                                                                                                        </td>

                                                                                                                                                                    </tr>
                                                                                                                                                                ))

                                                                                                                                                            }
                                                                                                                                                        </tbody>
                                                                                                                                                        {
                                                                                                                                                            item.subfabricComment.length === 0 ?
                                                                                                                                                                <tr>
                                                                                                                                                                    <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
                                                                                                                                                                </tr> : ''
                                                                                                                                                        }
                                                                                                                                                    </table>
                                                                                                                                                    <br />


                                                                                                                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                                                                                        <thead>
                                                                                                                                                            <tr>
                                                                                                                                                                <th>Approval Comments : Fabric<span className="text-danger">*</span></th>
                                                                                                                                                                <th style={{ width: '16%' }}>Date</th>
                                                                                                                                                                <th style={{ width: '7%' }}>Action</th>
                                                                                                                                                            </tr>
                                                                                                                                                        </thead>
                                                                                                                                                        <tbody>
                                                                                                                                                            {
                                                                                                                                                                item.fabricComments && item.fabricComments.filter(x => x.fabricIndexName === getCommentIndex).map((Commentitem, subChildIndex2) => {
                                                                                                                                                                    return (
                                                                                                                                                                        Commentitem.comments !== '' ?
                                                                                                                                                                            <tr>
                                                                                                                                                                                <td>
                                                                                                                                                                                    {Commentitem.comments}
                                                                                                                                                                                </td>
                                                                                                                                                                                <td key={subChildIndex2}>
                                                                                                                                                                                    {moment(Commentitem.commentDate).format('DD/MM/YYYY')}
                                                                                                                                                                                    {/* {new Date(Commentitem.commentDate).toLocaleDateString('en-US')}

                                                                                                                                                                                                    &nbsp;
                                                                                                                                                                                                    {new Date(Commentitem.commentDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} */}
                                                                                                                                                                                </td>
                                                                                                                                                                                <td>
                                                                                                                                                                                    {
                                                                                                                                                                                        <>
                                                                                                                                                                                            <span style={{ padding: '2px', display: subChildIndex2 === (item.fabricComments.length - 1) ? "" : "none", float: "right" }}>
                                                                                                                                                                                                <span>
                                                                                                                                                                                                    <span className="btn btn-success" title="Edit Comment" onClick={() => EditComments(index, subChildIndex2, Commentitem.comments, 'Fabric')}>
                                                                                                                                                                                                        <i className="fa fa-edit"></i>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </>
                                                                                                                                                                                    }
                                                                                                                                                                                    {

                                                                                                                                                                                        <span style={{ padding: '2px', display: Commentitem.comments === '' ? "none" : "", float: "right" }}>
                                                                                                                                                                                            <span>
                                                                                                                                                                                                <span className="btn btn-danger" title="Delete Comment" onClick={() => RemoveComments(index, subChildIndex2, 'Fabric')}>
                                                                                                                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>

                                                                                                                                                                                    }
                                                                                                                                                                                </td>
                                                                                                                                                                            </tr>
                                                                                                                                                                            : ''
                                                                                                                                                                    );
                                                                                                                                                                })
                                                                                                                                                            }
                                                                                                                                                            <tr>
                                                                                                                                                                <td colSpan="6">
                                                                                                                                                                    <textarea
                                                                                                                                                                        rows="3"
                                                                                                                                                                        placeholder="Enter comments here..."
                                                                                                                                                                        autoFocus onFocus={onFocus} autoComplete="off"
                                                                                                                                                                        maxLength="500" name="EnterComment"
                                                                                                                                                                        id={"EnterComment"}
                                                                                                                                                                        value={item.EnterComment}
                                                                                                                                                                        onChange={event => handleChangeComments(event, index, "Fabric")}
                                                                                                                                                                        // style={{ width: "100%", border: getComment && item.EnterComment === '' ? '1px solid red' : '' }}
                                                                                                                                                                        style={{ width: "100%", border: getComment && (item.EnterComment === '' || item.EnterComment === undefined) ? '1px solid red' : '' }}
                                                                                                                                                                    >
                                                                                                                                                                    </textarea>
                                                                                                                                                                </td>
                                                                                                                                                            </tr>
                                                                                                                                                        </tbody>
                                                                                                                                                    </table>
                                                                                                                                                </Modal.Body>
                                                                                                                                                <Modal.Footer>
                                                                                                                                                    <Button variant="success" onClick={() => handleSaveComment(index, item.EnterComment, 'Fabric')}> {/* , skuItem.PurOrdSkuCommentsID, skuItem.PurchaseOrderID */}
                                                                                                                                                        {getHeader === 'Add' ? 'Save' : 'Update'}
                                                                                                                                                    </Button>
                                                                                                                                                </Modal.Footer>
                                                                                                                                            </Modal>
                                                                                                                                            : ''
                                                                                                                                        }
                                                                                                                                    </td>

                                                                                                                                </tr>
                                                                                                                                {
                                                                                                                                    item.fabricComments.length > 0 ?
                                                                                                                                        item.fabricComments.filter(x => x.fabricIndexName === item.fabricIndexName).map((commetdata, index) => (
                                                                                                                                            <tr>
                                                                                                                                                <td colSpan={3}><span style={{ color: "#7C1034" }}>Comments: {commetdata.comments}</span>
                                                                                                                                                    &nbsp;
                                                                                                                                                    <span style={{ color: "#00933B" }}>Date : {moment(commetdata.commentDate).format('DD/MM/YYYY')}</span>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                        )) : ''
                                                                                                                                }

                                                                                                                            </Fragment>
                                                                                                                        )) :

                                                                                                                        <tr ><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>


                                                                                                                }

                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </td>
                                                                                                </tr> : ''
                                                                                        }
                                                                                    </Fragment>
                                                                                    : ''
                                                                            }

                                                                            {
                                                                                IsColorSelected === true ?
                                                                                    <Fragment>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <i
                                                                                                    className={
                                                                                                        IsColorExpanded === true ?
                                                                                                            'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                    }
                                                                                                    onClick={event => handleExpandRow("Color")}

                                                                                                ></i>
                                                                                                Color</td>

                                                                                        </tr>

                                                                                        {
                                                                                            IsColorExpanded === true ?
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                                            <TableHeader headers={headers} />
                                                                                                            <tbody>
                                                                                                                {
                                                                                                                    inputFields[0].Color &&
                                                                                                                        inputFields[0].Color.length > 0 ?
                                                                                                                        inputFields[0].Color.map((item, index) => (
                                                                                                                            <Fragment>

                                                                                                                                <tr>
                                                                                                                                    <td>
                                                                                                                                        <div className="row">
                                                                                                                                            <div className="col-lg-8">
                                                                                                                                                <select id={"Details"} name="Details" className="form-select"
                                                                                                                                                    value={item.value}

                                                                                                                                                    style={{ border: submitted && item.value === 0 ? '1px solid red' : '' }}
                                                                                                                                                    disabled
                                                                                                                                                >
                                                                                                                                                    <option value="0">-Select Details-</option>
                                                                                                                                                    {
                                                                                                                                                        inputFields[0].Color.map(item => (
                                                                                                                                                            <option value={item.value}>
                                                                                                                                                                {item.label}
                                                                                                                                                            </option>
                                                                                                                                                        ))
                                                                                                                                                    }
                                                                                                                                                </select>
                                                                                                                                            </div>
                                                                                                                                            <div className="col-lg-4">
                                                                                                                                                <span className="Actionbutton">
                                                                                                                                                    <div style={{ background: item.pantoneColorOrSwatchImage, width: '100%', height: '34px', borderRadius: '2px' }}></div>
                                                                                                                                                </span>
                                                                                                                                            </div>
                                                                                                                                        </div>


                                                                                                                                    </td>
                                                                                                                                    <td><select id={"Quality"} name="Quality" className="form-select"
                                                                                                                                        value={item.quality}
                                                                                                                                        disabled
                                                                                                                                        style={{ border: submitted && item.quality === "0" ? '1px solid red' : '' }}>
                                                                                                                                        <option value="0" key="0" style={{ color: '#bfdea1' }}> - Select Quality- </option>
                                                                                                                                        <option value="1" key="1">Original</option>
                                                                                                                                        <option value="2" key="2">Available</option>'
                                                                                                                                        <option value="3" key="3">Not Attach</option>
                                                                                                                                    </select></td>
                                                                                                                                    <td style={{ padding: '15px' }}>
                                                                                                                                        <span style={{ padding: '2px' }}>
                                                                                                                                            <span className={item.colorComments && item.colorComments.length !== 0 ? 'btn btn-xs btn-danger' : 'btn btn-xs btn-primary'} title="Comments" onClick={() => Comments(index, 'Color')}
                                                                                                                                                disabled={props.TaskStatus === 3 && (props.editProtoFitSmsApproval === false)
                                                                                                                                                    ? true : (props.props.location.pathname === '/TNAReport' || props.props.location.pathname === '/Dashboard') && props.TaskStatus === 3 ? true : false}
                                                                                                                                                style={{
                                                                                                                                                    pointerEvents: props.props.location.pathname === '/TNAReport' && props.TaskStatus === 3 ? 'none' :
                                                                                                                                                        props.TaskStatus === 3 && (props.editProtoFitSmsApproval === false)
                                                                                                                                                            ? 'none' : '', opacity: props.props.location.pathname === '/TNAReport' && props.TaskStatus === 3 ? '0.6' :
                                                                                                                                                                props.TaskStatus === 3 && (props.editProtoFitSmsApproval === false)
                                                                                                                                                                    ? '0.6' : '',
                                                                                                                                                    background: item.colorComments.length === 0 ? '#427fed' : item.colorComments.length !== 0 ? 'red' : '#427fed',
                                                                                                                                                    color: 'white',
                                                                                                                                                    padding: '3px 4px',
                                                                                                                                                    borderRadius: '2px',
                                                                                                                                                    border: item.colorComments.length === 0 ? '#427fed' : item.colorComments.length !== 0 ? 'red' : '#427fed',
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <i className="fa fa-comment"></i>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                        {
                                                                                                                                            item.EnableComments === 1 ?
                                                                                                                                                <Modal show={item.EnableComments === 1} dialogAs={Drag} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleFullClose(index, 'Color')}>
                                                                                                                                                    <Modal.Header closeButton>
                                                                                                                                                        <Modal.Title>
                                                                                                                                                            Comments
                                                                                                                                                        </Modal.Title>
                                                                                                                                                    </Modal.Header>
                                                                                                                                                    <Modal.Body>
                                                                                                                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                                                                                            <thead>
                                                                                                                                                                <tr>
                                                                                                                                                                    <th>Submission Comments : Color</th>

                                                                                                                                                                </tr>
                                                                                                                                                            </thead>
                                                                                                                                                            <tbody>

                                                                                                                                                                {

                                                                                                                                                                    item.subcolorComment.filter(x => x.colorIndexName === getCommentIndex).map((commetdata, index) => (
                                                                                                                                                                        <tr>
                                                                                                                                                                            <td >{commetdata.comments}
                                                                                                                                                                            </td>

                                                                                                                                                                        </tr>
                                                                                                                                                                    ))


                                                                                                                                                                }
                                                                                                                                                            </tbody>
                                                                                                                                                        </table>
                                                                                                                                                        <br />



                                                                                                                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                                                                                            <thead>
                                                                                                                                                                <tr>
                                                                                                                                                                    <th>Approval Comments : Color<span className="text-danger">*</span></th>
                                                                                                                                                                    <th style={{ width: '16%' }}>Date</th>
                                                                                                                                                                    <th style={{ width: '7%' }}>Action</th>
                                                                                                                                                                </tr>
                                                                                                                                                            </thead>
                                                                                                                                                            <tbody>
                                                                                                                                                                {
                                                                                                                                                                    item.colorComments.filter(x => x.colorIndexName === getCommentIndex).map((Commentitem, subChildIndex2) => {
                                                                                                                                                                        return (
                                                                                                                                                                            Commentitem.comments !== '' ?
                                                                                                                                                                                <tr>
                                                                                                                                                                                    <td>
                                                                                                                                                                                        {Commentitem.comments}
                                                                                                                                                                                    </td>
                                                                                                                                                                                    <td>
                                                                                                                                                                                        {moment(Commentitem.commentDate).format('DD/MM/YYYY')}
                                                                                                                                                                                        {/* {new Date(Commentitem.commentDate).toLocaleDateString('en-US')}

                                                                                                                                                                                                    &nbsp;
                                                                                                                                                                                                    {new Date(Commentitem.commentDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} */}
                                                                                                                                                                                    </td>
                                                                                                                                                                                    <td>
                                                                                                                                                                                        {
                                                                                                                                                                                            <>
                                                                                                                                                                                                <span style={{ padding: '2px', display: subChildIndex2 === (item.colorComments.length - 1) ? "" : "none", float: "right" }}>
                                                                                                                                                                                                    <span>
                                                                                                                                                                                                        <span className="btn btn-success" title="Edit Comment" onClick={() => EditComments(index, subChildIndex2, Commentitem.comments, 'Color')}>
                                                                                                                                                                                                            <i className="fa fa-edit"></i>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </>
                                                                                                                                                                                        }
                                                                                                                                                                                        {

                                                                                                                                                                                            <span style={{ padding: '2px', display: Commentitem.comments === '' ? "none" : "", float: "right" }}>
                                                                                                                                                                                                <span>
                                                                                                                                                                                                    <span className="btn btn-danger" title="Delete Comment" onClick={() => RemoveComments(index, subChildIndex2, 'Color')}>
                                                                                                                                                                                                        <i className="fa fa-trash-o"></i>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>

                                                                                                                                                                                        }
                                                                                                                                                                                    </td>
                                                                                                                                                                                </tr>
                                                                                                                                                                                : ''
                                                                                                                                                                        );
                                                                                                                                                                    })
                                                                                                                                                                }
                                                                                                                                                                <tr>
                                                                                                                                                                    <td colSpan="6">
                                                                                                                                                                        <textarea
                                                                                                                                                                            rows="3"
                                                                                                                                                                            placeholder="Enter comments here..."
                                                                                                                                                                            autoFocus onFocus={onFocus} autoComplete="off"
                                                                                                                                                                            maxLength="500" name="EnterComment"
                                                                                                                                                                            id={"EnterComment"}
                                                                                                                                                                            value={item.EnterComment}
                                                                                                                                                                            onChange={event => handleChangeComments(event, index, 'Color')}
                                                                                                                                                                            // style={{ width: "100%", border: getComment && item.EnterComment === '' ? '1px solid red' : '' }}
                                                                                                                                                                            style={{ width: "100%", border: getComment && (item.EnterComment === '' || item.EnterComment === undefined) ? '1px solid red' : '' }}
                                                                                                                                                                        >
                                                                                                                                                                        </textarea>
                                                                                                                                                                    </td>
                                                                                                                                                                </tr>
                                                                                                                                                            </tbody>
                                                                                                                                                        </table>
                                                                                                                                                    </Modal.Body>
                                                                                                                                                    <Modal.Footer>
                                                                                                                                                        <Button variant="success" onClick={() => handleSaveComment(index, item.EnterComment, 'Color')}> {/* , skuItem.PurOrdSkuCommentsID, skuItem.PurchaseOrderID */}
                                                                                                                                                            {getHeader === 'Add' ? 'Save' : 'Update'}
                                                                                                                                                        </Button>
                                                                                                                                                    </Modal.Footer>
                                                                                                                                                </Modal>
                                                                                                                                                : ''
                                                                                                                                        }
                                                                                                                                    </td>

                                                                                                                                </tr>
                                                                                                                                {
                                                                                                                                    item.colorComments.length > 0 ?
                                                                                                                                        item.colorComments.filter(x => x.colorIndexName === item.colorIndexName).map((commetdata, index) => (
                                                                                                                                            <tr>
                                                                                                                                                <td colSpan={3}>Comments: {commetdata.comments}
                                                                                                                                                    &nbsp;
                                                                                                                                                    Date : {moment(commetdata.commentDate).format('DD/MM/YYYY')}
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                        )) : ''
                                                                                                                                }
                                                                                                                            </Fragment>
                                                                                                                        ))
                                                                                                                        :
                                                                                                                        <tr ><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>


                                                                                                                }

                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </td>
                                                                                                </tr> : ''
                                                                                        }
                                                                                    </Fragment>
                                                                                    : ''
                                                                            }
                                                                            {
                                                                                IsStrikeoffSelected === true ?
                                                                                    <Fragment>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <i
                                                                                                    className={
                                                                                                        IsStrikeoffExpanded === true ?
                                                                                                            'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                    }
                                                                                                    onClick={event => handleExpandRow("Strikeoff")}

                                                                                                ></i>
                                                                                                Strikeoff</td>

                                                                                        </tr>

                                                                                        {
                                                                                            IsStrikeoffExpanded === true ?
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                                            <TableHeader headers={headers} />
                                                                                                            <tbody>
                                                                                                                {
                                                                                                                    inputFields[0].Strikeoff &&
                                                                                                                        inputFields[0].Strikeoff.length > 0 ?
                                                                                                                        inputFields[0].Strikeoff.map((item, index) => (
                                                                                                                            <Fragment>

                                                                                                                                <tr>
                                                                                                                                    <td>
                                                                                                                                        <div className="row">
                                                                                                                                            <div className="col-lg-8">
                                                                                                                                                <select id={"Details"} name="Details" className="form-select"
                                                                                                                                                    value={item.value}
                                                                                                                                                    disabled
                                                                                                                                                    style={{ border: submitted && item.value === 0 ? '1px solid red' : '' }}
                                                                                                                                                >
                                                                                                                                                    <option value="0">-Select Details-</option>
                                                                                                                                                    {
                                                                                                                                                        inputFields[0].Strikeoff.map(item => (
                                                                                                                                                            <option value={item.value}>
                                                                                                                                                                {item.label}
                                                                                                                                                            </option>
                                                                                                                                                        ))
                                                                                                                                                    }
                                                                                                                                                </select>
                                                                                                                                            </div>
                                                                                                                                            <div className="col-lg-4">
                                                                                                                                                <span className="Actionbutton">
                                                                                                                                                    <img src={window.$APIBaseURL + "Images/Style/Strikeoff/" + item.strikeoffLogoPath} height="50px" width="50px"></img>
                                                                                                                                                </span>
                                                                                                                                            </div>
                                                                                                                                        </div>


                                                                                                                                    </td>
                                                                                                                                    <td><select id={"Quality"} name="Quality" className="form-select"
                                                                                                                                        value={item.quality}
                                                                                                                                        disabled
                                                                                                                                    // style={{ border: submitted && item.quality === 0 ? '1px solid red' : '' }}
                                                                                                                                    >
                                                                                                                                        <option value="0" key="0" style={{ color: '#bfdea1' }}> - Select Quality- </option>
                                                                                                                                        <option value="1" key="1">Original</option>
                                                                                                                                        <option value="2" key="2">Available</option>'
                                                                                                                                        <option value="3" key="3">Not Attach</option>
                                                                                                                                    </select></td>
                                                                                                                                    <td style={{ padding: '15px' }}>
                                                                                                                                        <span style={{ padding: '2px' }}>
                                                                                                                                            <span className={item.strikeoffComments && item.strikeoffComments.length !== 0 ? 'btn btn-xs btn-danger' : 'btn btn-xs btn-primary'} title="Comments" onClick={() => Comments(index, 'Strikeoff')}
                                                                                                                                                disabled={props.TaskStatus === 3 && (props.editProtoFitSmsApproval === false)
                                                                                                                                                    ? true : (props.props.location.pathname === '/TNAReport' || props.props.location.pathname === '/Dashboard') && props.TaskStatus === 3 ? true : false}
                                                                                                                                                style={{
                                                                                                                                                    pointerEvents: props.props.location.pathname === '/TNAReport' && props.TaskStatus === 3 ? 'none' :
                                                                                                                                                        props.TaskStatus === 3 && (props.editProtoFitSmsApproval === false)
                                                                                                                                                            ? 'none' : '', opacity: props.props.location.pathname === '/TNAReport' && props.TaskStatus === 3 ? '0.6' :
                                                                                                                                                                props.TaskStatus === 3 && (props.editProtoFitSmsApproval === false)
                                                                                                                                                                    ? '0.6' : '',
                                                                                                                                                    background: item.strikeoffComments.length === 0 ? '#427fed' : item.strikeoffComments.length !== 0 ? 'red' : '#427fed',
                                                                                                                                                    color: 'white',
                                                                                                                                                    padding: '3px 4px',
                                                                                                                                                    borderRadius: '2px',
                                                                                                                                                    border: item.strikeoffComments.length === 0 ? '#427fed' : item.strikeoffComments.length !== 0 ? 'red' : '#427fed',
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <i className="fa fa-comment"></i>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                        {
                                                                                                                                            item.EnableComments === 1 ?
                                                                                                                                                <Modal show={item.EnableComments === 1} dialogAs={Drag} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleFullClose(index, 'Strikeoff')}>
                                                                                                                                                    <Modal.Header closeButton>
                                                                                                                                                        <Modal.Title>
                                                                                                                                                            Comments
                                                                                                                                                        </Modal.Title>
                                                                                                                                                    </Modal.Header>
                                                                                                                                                    <Modal.Body>
                                                                                                                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                                                                                            <thead>
                                                                                                                                                                <tr>
                                                                                                                                                                    <th> Submission Comments : Strike Off</th>

                                                                                                                                                                </tr>
                                                                                                                                                            </thead>
                                                                                                                                                            <tbody>

                                                                                                                                                                {

                                                                                                                                                                    item.substrikeOffComment.filter(x => x.strikeoffIndexName === getCommentIndex).map((commetdata, index) => (
                                                                                                                                                                        <tr>
                                                                                                                                                                            <td >{commetdata.comments}
                                                                                                                                                                            </td>

                                                                                                                                                                        </tr>
                                                                                                                                                                    ))



                                                                                                                                                                }
                                                                                                                                                            </tbody>
                                                                                                                                                        </table>
                                                                                                                                                        <br />
                                                                                                                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                                                                                            <thead>
                                                                                                                                                                <tr>
                                                                                                                                                                    <th>Approval Comments : Strike Off<span className="text-danger">*</span></th>
                                                                                                                                                                    <th style={{ width: '16%' }}>Date</th>
                                                                                                                                                                    <th style={{ width: '7%' }}>Action</th>
                                                                                                                                                                </tr>
                                                                                                                                                            </thead>
                                                                                                                                                            <tbody>
                                                                                                                                                                {
                                                                                                                                                                    item.strikeoffComments.filter(x => x.strikeoffIndexName === getCommentIndex).map((Commentitem, subChildIndex2) => {
                                                                                                                                                                        return (
                                                                                                                                                                            Commentitem.comments !== '' ?
                                                                                                                                                                                <tr>
                                                                                                                                                                                    <td>
                                                                                                                                                                                        {Commentitem.comments}
                                                                                                                                                                                    </td>
                                                                                                                                                                                    <td>
                                                                                                                                                                                        {moment(Commentitem.commentDate).format('DD/MM/YYYY')}
                                                                                                                                                                                        {/* {new Date(Commentitem.commentDate).toLocaleDateString('en-US')}

                                                                                                                                                                                                    &nbsp;
                                                                                                                                                                                                    {new Date(Commentitem.commentDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} */}
                                                                                                                                                                                    </td>
                                                                                                                                                                                    <td>
                                                                                                                                                                                        {
                                                                                                                                                                                            <>
                                                                                                                                                                                                <span style={{ padding: '2px', display: subChildIndex2 === (item.strikeoffComments.length - 1) ? "" : "none", float: "right" }}>
                                                                                                                                                                                                    <span>
                                                                                                                                                                                                        <span className="btn btn-success" title="Edit Comment" onClick={() => EditComments(index, subChildIndex2, Commentitem.comments, 'Strikeoff')}>
                                                                                                                                                                                                            <i className="fa fa-edit"></i>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </>
                                                                                                                                                                                        }
                                                                                                                                                                                        {

                                                                                                                                                                                            <span style={{ padding: '2px', display: Commentitem.comments === '' ? "none" : "", float: "right" }}>
                                                                                                                                                                                                <span>
                                                                                                                                                                                                    <span className="btn btn-danger" title="Delete Comment" onClick={() => RemoveComments(index, subChildIndex2, 'Strikeoff')}>
                                                                                                                                                                                                        <i className="fa fa-trash-o"></i>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>

                                                                                                                                                                                        }
                                                                                                                                                                                    </td>
                                                                                                                                                                                </tr>
                                                                                                                                                                                : ''
                                                                                                                                                                        );
                                                                                                                                                                    })
                                                                                                                                                                }
                                                                                                                                                                <tr>
                                                                                                                                                                    <td colSpan="6">
                                                                                                                                                                        <textarea
                                                                                                                                                                            rows="3"
                                                                                                                                                                            placeholder="Enter comments here..."
                                                                                                                                                                            autoFocus onFocus={onFocus} autoComplete="off"
                                                                                                                                                                            maxLength="500" name="EnterComment"
                                                                                                                                                                            id={"EnterComment"}
                                                                                                                                                                            value={item.EnterComment}
                                                                                                                                                                            onChange={event => handleChangeComments(event, index, 'Strikeoff')}
                                                                                                                                                                            // style={{ width: "100%", border: getComment && item.EnterComment === '' ? '1px solid red' : '' }}
                                                                                                                                                                            style={{ width: "100%", border: getComment && (item.EnterComment === '' || item.EnterComment === undefined) ? '1px solid red' : '' }}
                                                                                                                                                                        >
                                                                                                                                                                        </textarea>
                                                                                                                                                                    </td>
                                                                                                                                                                </tr>
                                                                                                                                                            </tbody>
                                                                                                                                                        </table>
                                                                                                                                                    </Modal.Body>
                                                                                                                                                    <Modal.Footer>
                                                                                                                                                        <Button variant="success" onClick={() => handleSaveComment(index, item.EnterComment, 'Strikeoff')}> {/* , skuItem.PurOrdSkuCommentsID, skuItem.PurchaseOrderID */}
                                                                                                                                                            {getHeader === 'Add' ? 'Save' : 'Update'}
                                                                                                                                                        </Button>
                                                                                                                                                    </Modal.Footer>
                                                                                                                                                </Modal>
                                                                                                                                                : ''
                                                                                                                                        }
                                                                                                                                    </td>

                                                                                                                                </tr>

                                                                                                                                {
                                                                                                                                    item.strikeoffComments.length > 0 ?
                                                                                                                                        item.strikeoffComments.filter(x => x.strikeoffIndexName === item.strikeoffIndexName).map((commetdata, index) => (
                                                                                                                                            <tr>
                                                                                                                                                <td colSpan={3}>Comments: {commetdata.comments}
                                                                                                                                                    &nbsp;
                                                                                                                                                    Date : {moment(commetdata.commentDate).format('DD/MM/YYYY')}
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                        )) : ''
                                                                                                                                }
                                                                                                                            </Fragment>
                                                                                                                        )) :

                                                                                                                        <tr ><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>


                                                                                                                }

                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </td>
                                                                                                </tr> : ''
                                                                                        }
                                                                                    </Fragment>
                                                                                    : ''
                                                                            }
                                                                            {
                                                                                IsTrimsSelected === true ?
                                                                                    <Fragment>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <i
                                                                                                    className={
                                                                                                        IsTrimsExpanded === true ?
                                                                                                            'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                    }
                                                                                                    onClick={event => handleExpandRow("Trims")}

                                                                                                ></i>
                                                                                                Trims</td>

                                                                                        </tr>

                                                                                        {
                                                                                            IsTrimsExpanded === true ?
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                                            <TableHeader headers={headers} />
                                                                                                            <tbody>
                                                                                                                {
                                                                                                                    inputFields[0].Trim &&
                                                                                                                        inputFields[0].Trim.length > 0 ?
                                                                                                                        inputFields[0].Trim.map((item, index) => (
                                                                                                                            <Fragment>

                                                                                                                                <tr>
                                                                                                                                    <td>
                                                                                                                                        <div className="row">
                                                                                                                                            <div className="col-lg-8">
                                                                                                                                                <select id={"Details"} name="Details" className="form-select"
                                                                                                                                                    value={item.value}
                                                                                                                                                    disabled
                                                                                                                                                    style={{ border: submitted && item.value === 0 ? '1px solid red' : '' }}
                                                                                                                                                >
                                                                                                                                                    <option value="0">-Select Details-</option>
                                                                                                                                                    {
                                                                                                                                                        inputFields[0].Trim.map(item => (
                                                                                                                                                            <option value={item.value}>
                                                                                                                                                                {item.label}
                                                                                                                                                            </option>
                                                                                                                                                        ))
                                                                                                                                                    }
                                                                                                                                                </select>
                                                                                                                                            </div>
                                                                                                                                            <div className="col-lg-4">
                                                                                                                                                <span className="Actionbutton">
                                                                                                                                                    <img src={window.$APIBaseURL + "Images/Style/Trim/" + item.trimLogoPath} height="50px" width="50px"></img>
                                                                                                                                                </span>
                                                                                                                                            </div>
                                                                                                                                        </div>


                                                                                                                                    </td>
                                                                                                                                    <td><select id={"Quality"} name="Quality" className="form-select"
                                                                                                                                        value={item.quality}
                                                                                                                                        disabled
                                                                                                                                        style={{ border: submitted && item.quality === "0" ? '1px solid red' : '' }}>
                                                                                                                                        <option value="0" key="0" style={{ color: '#bfdea1' }}> - Select Quality- </option>
                                                                                                                                        <option value="1" key="1">Original</option>
                                                                                                                                        <option value="2" key="2">Available</option>'
                                                                                                                                        <option value="3" key="3">Not Attach</option>
                                                                                                                                    </select></td>
                                                                                                                                    <td style={{ padding: '15px' }}>
                                                                                                                                        <span style={{ padding: '2px' }}>
                                                                                                                                            <span className={item.trimComments && item.trimComments.length !== 0 ? 'btn btn-xs btn-danger' : 'btn btn-xs btn-primary'} title="Comments" onClick={() => Comments(index, 'Trim')}
                                                                                                                                                disabled={props.TaskStatus === 3 && (props.editProtoFitSmsApproval === false)
                                                                                                                                                    ? true : (props.props.location.pathname === '/TNAReport' || props.props.location.pathname === '/Dashboard') && props.TaskStatus === 3 ? true : false}
                                                                                                                                                style={{
                                                                                                                                                    pointerEvents: props.props.location.pathname === '/TNAReport' && props.TaskStatus === 3 ? 'none' :
                                                                                                                                                        props.TaskStatus === 3 && (props.editProtoFitSmsApproval === false)
                                                                                                                                                            ? 'none' : '', opacity: props.props.location.pathname === '/TNAReport' && props.TaskStatus === 3 ? '0.6' :
                                                                                                                                                                props.TaskStatus === 3 && (props.editProtoFitSmsApproval === false)
                                                                                                                                                                    ? '0.6' : '',
                                                                                                                                                    background: item.trimComments.length === 0 ? '#427fed' : item.trimComments.length !== 0 ? 'red' : '#427fed',
                                                                                                                                                    color: 'white',
                                                                                                                                                    padding: '3px 4px',
                                                                                                                                                    borderRadius: '2px',
                                                                                                                                                    border: item.trimComments.length === 0 ? '#427fed' : item.trimComments.length !== 0 ? 'red' : '#427fed',
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <i className="fa fa-comment"></i>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                        {
                                                                                                                                            item.EnableComments === 1 ?
                                                                                                                                                <Modal show={item.EnableComments === 1} dialogAs={Drag} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleFullClose(index, 'Trim')}>
                                                                                                                                                    <Modal.Header closeButton>
                                                                                                                                                        <Modal.Title>
                                                                                                                                                            Comments
                                                                                                                                                        </Modal.Title>
                                                                                                                                                    </Modal.Header>
                                                                                                                                                    <Modal.Body>
                                                                                                                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                                                                                            <thead>
                                                                                                                                                                <tr>
                                                                                                                                                                    <th> Submission Comments : Trim</th>

                                                                                                                                                                </tr>
                                                                                                                                                            </thead>
                                                                                                                                                            <tbody>

                                                                                                                                                                {

                                                                                                                                                                    item.subtrimComment.filter(x => x.trimIndexName === getCommentIndex).map((commetdata, index) => (
                                                                                                                                                                        <tr>
                                                                                                                                                                            <td >
                                                                                                                                                                                {commetdata.comments}
                                                                                                                                                                            </td>

                                                                                                                                                                        </tr>
                                                                                                                                                                    ))

                                                                                                                                                                }
                                                                                                                                                            </tbody>
                                                                                                                                                        </table>
                                                                                                                                                        <br />
                                                                                                                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                                                                                            <thead>
                                                                                                                                                                <tr>
                                                                                                                                                                    <th>Approval Comments : Trim<span className="text-danger">*</span></th>
                                                                                                                                                                    <th style={{ width: '16%' }}>Date</th>
                                                                                                                                                                    <th style={{ width: '7%' }}>Action</th>
                                                                                                                                                                </tr>
                                                                                                                                                            </thead>
                                                                                                                                                            <tbody>
                                                                                                                                                                {
                                                                                                                                                                    item.trimComments.filter(x => x.trimIndexName === getCommentIndex).map((Commentitem, subChildIndex2) => {
                                                                                                                                                                        return (
                                                                                                                                                                            Commentitem.comments !== '' ?
                                                                                                                                                                                <tr>
                                                                                                                                                                                    <td>
                                                                                                                                                                                        {Commentitem.comments}
                                                                                                                                                                                    </td>
                                                                                                                                                                                    <td>
                                                                                                                                                                                        {moment(Commentitem.commentDate).format('DD/MM/YYYY')}
                                                                                                                                                                                        {/* {new Date(Commentitem.commentDate).toLocaleDateString('en-US')}

                                                                                                                                                                                                    &nbsp;
                                                                                                                                                                                                    {new Date(Commentitem.commentDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} */}
                                                                                                                                                                                    </td>
                                                                                                                                                                                    <td>
                                                                                                                                                                                        {
                                                                                                                                                                                            <>
                                                                                                                                                                                                <span style={{ padding: '2px', display: subChildIndex2 === (item.trimComments.length - 1) ? "" : "none", float: "right" }}>
                                                                                                                                                                                                    <span>
                                                                                                                                                                                                        <span className="btn btn-success" title="Edit Comment" onClick={() => EditComments(index, subChildIndex2, Commentitem.comments, 'Trim')}>
                                                                                                                                                                                                            <i className="fa fa-edit"></i>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </>
                                                                                                                                                                                        }
                                                                                                                                                                                        {

                                                                                                                                                                                            <span style={{ padding: '2px', display: Commentitem.comments === '' ? "none" : "", float: "right" }}>
                                                                                                                                                                                                <span>
                                                                                                                                                                                                    <span className="btn btn-danger" title="Delete Comment" onClick={() => RemoveComments(index, subChildIndex2, 'Trim')}>
                                                                                                                                                                                                        <i className="fa fa-trash-o"></i>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>

                                                                                                                                                                                        }
                                                                                                                                                                                    </td>
                                                                                                                                                                                </tr>
                                                                                                                                                                                : ''
                                                                                                                                                                        );
                                                                                                                                                                    })
                                                                                                                                                                }
                                                                                                                                                                <tr>
                                                                                                                                                                    <td colSpan="6">
                                                                                                                                                                        <textarea
                                                                                                                                                                            rows="3"
                                                                                                                                                                            placeholder="Enter comments here..."
                                                                                                                                                                            autoFocus onFocus={onFocus} autoComplete="off"
                                                                                                                                                                            maxLength="500" name="EnterComment"
                                                                                                                                                                            id={"EnterComment"}
                                                                                                                                                                            value={item.EnterComment}
                                                                                                                                                                            onChange={event => handleChangeComments(event, index, 'Trim')}
                                                                                                                                                                            // style={{ width: "100%", border: getComment && item.EnterComment === '' ? '1px solid red' : '' }}
                                                                                                                                                                            style={{ width: "100%", border: getComment && (item.EnterComment === '' || item.EnterComment === undefined) ? '1px solid red' : '' }}
                                                                                                                                                                        >
                                                                                                                                                                        </textarea>
                                                                                                                                                                    </td>
                                                                                                                                                                </tr>
                                                                                                                                                            </tbody>
                                                                                                                                                        </table>
                                                                                                                                                    </Modal.Body>
                                                                                                                                                    <Modal.Footer>
                                                                                                                                                        <Button variant="success" onClick={() => handleSaveComment(index, item.EnterComment, 'Trim')}> {/* , skuItem.PurOrdSkuCommentsID, skuItem.PurchaseOrderID */}
                                                                                                                                                            {getHeader === 'Add' ? 'Save' : 'Update'}
                                                                                                                                                        </Button>
                                                                                                                                                    </Modal.Footer>
                                                                                                                                                </Modal>
                                                                                                                                                : ''
                                                                                                                                        }
                                                                                                                                    </td>

                                                                                                                                </tr>

                                                                                                                                {
                                                                                                                                    item.trimComments.length > 0 ?
                                                                                                                                        item.trimComments.filter(x => x.trimIndexName === item.trimIndexName).map((commetdata, index) => (
                                                                                                                                            <tr>
                                                                                                                                                <td colSpan={3}>Comments: {commetdata.comments}
                                                                                                                                                    &nbsp;
                                                                                                                                                    Date : {moment(commetdata.commentDate).format('DD/MM/YYYY')}
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                        )) : ''
                                                                                                                                }
                                                                                                                            </Fragment>
                                                                                                                        )) :

                                                                                                                        <tr ><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>

                                                                                                                }

                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </td>
                                                                                                </tr> : ''
                                                                                        }
                                                                                    </Fragment>
                                                                                    : ''
                                                                            }
                                                                            {
                                                                                IsMeasurementSelected === true ?
                                                                                    <Fragment>
                                                                                        <tr>
                                                                                            <td style={{ width: "200px" }}>
                                                                                                <i
                                                                                                    className={
                                                                                                        IsMeasurementExpanded === true ?
                                                                                                            'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                    }
                                                                                                    onClick={event => handleExpandRow("Measurement")}

                                                                                                ></i>
                                                                                                Measurement</td>

                                                                                        </tr>

                                                                                        {
                                                                                            IsMeasurementExpanded === true ?
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                                            {/* <TableHeader headers={headers} /> */}
                                                                                                            <tbody>

                                                                                                                {
                                                                                                                    inputFields[0].Measurement &&
                                                                                                                        inputFields[0].Measurement.length > 0 ?
                                                                                                                        inputFields[0].Measurement.map((item, index) => (
                                                                                                                            <Fragment>

                                                                                                                                <tr>
                                                                                                                                    <td>

                                                                                                                                    </td>

                                                                                                                                </tr>


                                                                                                                            </Fragment>
                                                                                                                        )) :
                                                                                                                        <tbody>
                                                                                                                            <tr>
                                                                                                                                <div className="col-sm-12 col-lg-12">
                                                                                                                                    <div className="widget-header" style={{ paddingLeft: 0 }}>
                                                                                                                                        <span className="lowCaseText widget-caption" style={{ fontSize: '14px' }}>File Upload <span style={{ color: 'red', fontSize: '14px' }}>(Upload Document Type: Excel, Word, PDF and Image)</span></span>
                                                                                                                                    </div>
                                                                                                                                </div>

                                                                                                                                <div className="col-lg-12 col-sm-12">
                                                                                                                                    <div className="row no-margin">
                                                                                                                                        {
                                                                                                                                            inputFields[0].Measurement.Files.length !== 0 ?
                                                                                                                                                inputFields[0].Measurement.Files.map((files, fileindex) => (
                                                                                                                                                    <div className="col-lg-4 col-sm-4 file_list_upload">
                                                                                                                                                        <tr title={files.FileName}>
                                                                                                                                                            <td className="cut_textInProto">
                                                                                                                                                                <span style={{ marginRight: '7px' }}>
                                                                                                                                                                    {
                                                                                                                                                                        files.FileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                                                                                            files.FileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                                                                                                                <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                                                                                                files.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                                                                                                    files.FileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                                                                                                    <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                                                                                                    }
                                                                                                                                                                </span>  {files.FileName}
                                                                                                                                                            </td>

                                                                                                                                                            <td className="button_style_uploda">

                                                                                                                                                                {
                                                                                                                                                                    files.FileType === "application/pdf" || files.FileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files.FileType === 'application/msword' ?
                                                                                                                                                                        <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + 'Images/PODocs/' + files.FileName} download><i class="fa fa-download"></i> </a> :
                                                                                                                                                                        <i className="btn btn-info fa fa-eye" title="View Logo"
                                                                                                                                                                            onClick={
                                                                                                                                                                                event => ViewFile(event, files.FileName)
                                                                                                                                                                            }></i>
                                                                                                                                                                }
                                                                                                                                                            </td>
                                                                                                                                                        </tr>
                                                                                                                                                        {

                                                                                                                                                            <div> {
                                                                                                                                                                isOpenimg && (

                                                                                                                                                                    <Lightbox mainSrc={window.$APIBaseURL + 'Images/TNAImages/' + getImagename}
                                                                                                                                                                        alt="bg image"
                                                                                                                                                                        onCloseRequest={
                                                                                                                                                                            () => setIsOpenimg(false)
                                                                                                                                                                        } />
                                                                                                                                                                )
                                                                                                                                                            } </div>
                                                                                                                                                        }
                                                                                                                                                    </div>
                                                                                                                                                ))
                                                                                                                                                : ''
                                                                                                                                        }
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </tr>
                                                                                                                        </tbody>

                                                                                                                }

                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </td>
                                                                                                </tr> : ''
                                                                                        }
                                                                                    </Fragment>
                                                                                    : ''
                                                                            }
                                                                            {
                                                                                <Fragment>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <i
                                                                                                className={
                                                                                                    IsMessureComment === true ?
                                                                                                        'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                }
                                                                                                onClick={event => handleExpandRow("Measurement Comments")}

                                                                                            ></i>
                                                                                            Measurement Comments</td>

                                                                                    </tr>

                                                                                    {
                                                                                        IsMessureComment === true ?
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <textarea disabled={props.TaskStatus === 3 && props.editProtoFitSmsApproval === false ? true : false} name="" id="" value={values[0].AdditionalComments[0].Comment} cols="153" rows="3" onChange={event => handleCommentsChange(event, 'Measurement Comments')} onKeyDown={handleKeyDown}></textarea>
                                                                                                </td>
                                                                                            </tr> : ''
                                                                                    }
                                                                                </Fragment>

                                                                            }
                                                                            {
                                                                                <Fragment>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <i
                                                                                                className={
                                                                                                    IsConstructionComments === true ?
                                                                                                        'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                }
                                                                                                onClick={event => handleExpandRow("Construction Comments")}

                                                                                            ></i>
                                                                                            Workmanship / Construction Comments</td>

                                                                                    </tr>

                                                                                    {
                                                                                        IsConstructionComments === true ?
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <textarea onKeyDown={handleKeyDown} disabled={props.TaskStatus === 3 && props.editProtoFitSmsApproval === false ? true : false} name="" id="" value={values[0].AdditionalComments[1].Comment} cols="153" rows="3" onChange={event => handleCommentsChange(event, 'Construction Comments')}></textarea>
                                                                                                </td>
                                                                                            </tr> : ''
                                                                                    }
                                                                                </Fragment>

                                                                            }
                                                                            {
                                                                                <Fragment>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <i
                                                                                                className={
                                                                                                    IsUnbrandComments === true ?
                                                                                                        'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                }
                                                                                                onClick={event => handleExpandRow("Unbranded Trim Comments")}

                                                                                            ></i>
                                                                                            Unbranded Trim Comments</td>

                                                                                    </tr>

                                                                                    {
                                                                                        IsUnbrandComments === true ?
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <textarea onKeyDown={handleKeyDown} disabled={props.TaskStatus === 3 && props.editProtoFitSmsApproval === false ? true : false} name="" id="" value={values[0].AdditionalComments[2].Comment} cols="153" rows="3" onChange={event => handleCommentsChange(event, 'Unbranded Trim Comments')}></textarea>
                                                                                                </td>
                                                                                            </tr> : ''
                                                                                    }
                                                                                </Fragment>


                                                                            }
                                                                            {
                                                                                <Fragment>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <i
                                                                                                className={
                                                                                                    IsApplicationComments === true ?
                                                                                                        'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                }
                                                                                                onClick={event => handleExpandRow("Application Comments")}

                                                                                            ></i>
                                                                                            Application Comments (Washing / Stitching)</td>

                                                                                    </tr>

                                                                                    {
                                                                                        IsApplicationComments === true ?
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <textarea onKeyDown={handleKeyDown} disabled={props.TaskStatus === 3 && props.editProtoFitSmsApproval === false ? true : false} name="" id="" value={values[0].AdditionalComments[3].Comment} cols="153" rows="3" onChange={event => handleCommentsChange(event, 'Application Comments')}></textarea>
                                                                                                </td>
                                                                                            </tr> : ''
                                                                                    }
                                                                                </Fragment>
                                                                            }
                                                                            {
                                                                                <Fragment>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <i
                                                                                                className={
                                                                                                    IsSRComments === true ?
                                                                                                        'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                }
                                                                                                onClick={event => handleExpandRow("Special Requirement Comments")}

                                                                                            ></i>
                                                                                            Special Requirement Comments</td>

                                                                                    </tr>

                                                                                    {
                                                                                        IsSRComments === true ?
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <textarea onKeyDown={handleKeyDown} disabled={props.TaskStatus === 3 && props.editProtoFitSmsApproval === false ? true : false} name="" id="" value={values[0].AdditionalComments[4].Comment} cols="153" rows="3" onChange={event => handleCommentsChange(event, 'Special Requirement Comments')}></textarea>
                                                                                                </td>
                                                                                            </tr> : ''
                                                                                    }
                                                                                </Fragment>
                                                                            }
                                                                            {
                                                                                <Fragment>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <i
                                                                                                className={
                                                                                                    IsQCComments === true ?
                                                                                                        'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                }
                                                                                                onClick={event => handleExpandRow("QC Note")}

                                                                                            ></i>
                                                                                            QC Note</td>

                                                                                    </tr>

                                                                                    {
                                                                                        IsQCComments === true ?
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <textarea  onKeyDown={handleKeyDown} disabled={props.TaskStatus === 3 && props.editProtoFitSmsApproval === false ? true : false} name="" id="" value={values[0].AdditionalComments[5].Comment} cols="153" rows="3" onChange={event => handleCommentsChange(event, 'QC Note')}></textarea>
                                                                                                </td>
                                                                                            </tr> : ''
                                                                                    }
                                                                                </Fragment>
                                                                            }
                                                                            {
                                                                                <Fragment>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <i
                                                                                                className={
                                                                                                    IsAmendmentsComments === true ?
                                                                                                        'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                }
                                                                                                onClick={event => handleExpandRow("Amendments")}

                                                                                            ></i>
                                                                                            Amendments</td>

                                                                                    </tr>

                                                                                    {
                                                                                        IsAmendmentsComments === true ?
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <textarea onKeyDown={handleKeyDown}  disabled={props.TaskStatus === 3 && props.editProtoFitSmsApproval === false ? true : false} name="" id="" value={values[0].AdditionalComments[6].Comment} cols="153" rows="3" onChange={event => handleCommentsChange(event, 'Amendments')}></textarea>
                                                                                                </td>
                                                                                            </tr> : ''
                                                                                    }
                                                                                </Fragment>
                                                                            }
                                                                            {
                                                                                <Fragment>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <i
                                                                                                className={
                                                                                                    IsActionComments === true ?
                                                                                                        'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                }
                                                                                                onClick={event => handleExpandRow("Action Comments")}

                                                                                            ></i>
                                                                                            Action Comments</td>

                                                                                    </tr>

                                                                                    {
                                                                                        IsActionComments === true ?
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <textarea onKeyDown={handleKeyDown} disabled={props.TaskStatus === 3 && props.editProtoFitSmsApproval === false ? true : false} name="" id="" value={values[0].AdditionalComments[7].Comment} cols="153" rows="3" onChange={event => handleCommentsChange(event, 'Action Comments')}></textarea>
                                                                                                </td>
                                                                                            </tr> : ''
                                                                                    }
                                                                                </Fragment>
                                                                            }
                                                                            {
                                                                                <Fragment>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <i
                                                                                                className={
                                                                                                    IsTestingComments === true ?
                                                                                                        'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                }
                                                                                                onClick={event => handleExpandRow("Testing Comments")}

                                                                                            ></i>
                                                                                            Testing Comments</td>

                                                                                    </tr>

                                                                                    {
                                                                                        IsTestingComments === true ?
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <textarea  onKeyDown={handleKeyDown} disabled={props.TaskStatus === 3 && props.editProtoFitSmsApproval === false ? true : false} name="" id="" value={values[0].AdditionalComments[8].Comment} cols="153" rows="3" onChange={event => handleCommentsChange(event, 'Testing Comments')}></textarea>
                                                                                                </td>
                                                                                            </tr> : ''
                                                                                    }
                                                                                </Fragment>
                                                                            }
                                                                            {
                                                                                <Fragment>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <i
                                                                                                className={
                                                                                                    IsPackagingComments === true ?
                                                                                                        'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                }
                                                                                                onClick={event => handleExpandRow("Packaging Comments")}

                                                                                            ></i>
                                                                                            Packaging Comments</td>

                                                                                    </tr>

                                                                                    {
                                                                                        IsPackagingComments === true ?
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <textarea  onKeyDown={handleKeyDown} disabled={props.TaskStatus === 3 && props.editProtoFitSmsApproval === false ? true : false} name="" id="" value={values[0].AdditionalComments[9].Comment} cols="153" rows="3" onChange={event => handleCommentsChange(event, 'Packaging Comments')}></textarea>
                                                                                                </td>
                                                                                            </tr> : ''
                                                                                    }
                                                                                </Fragment>
                                                                            }
                                                                            {
                                                                                <Fragment>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <i
                                                                                                className={
                                                                                                    IsAdditionalComments === true ?
                                                                                                        'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                }
                                                                                                onClick={event => handleExpandRow("Additional comments")}

                                                                                            ></i>
                                                                                            Additional comments</td>

                                                                                    </tr>

                                                                                    {
                                                                                        IsAdditionalComments === true ?
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <textarea onKeyDown={handleKeyDown}  disabled={props.TaskStatus === 3 && props.editProtoFitSmsApproval === false ? true : false} name="" id="" value={values[0].AdditionalComments[10].Comment} cols="153" rows="3" onChange={event => handleCommentsChange(event, 'Additional comments')}></textarea>
                                                                                                </td>
                                                                                            </tr> : ''
                                                                                    }
                                                                                </Fragment>
                                                                            }

                                                                        </Fragment>

                                                                    } </tbody>
                                                                </table>
                                                                <br />
                                                                <div className="row">
                                                                    <div className="col-sm-3">
                                                                        <div className='form-group'>
                                                                            <label htmlFor="Name" >Overall Result<span className="text-danger">*</span></label>
                                                                            <span className='input-icon icon-right dummy'>
                                                                                <Reactselect className="basic-single" name="TaskHolderOrFollower"
                                                                                    id={
                                                                                        "TaskHolderOrFollower"
                                                                                    }
                                                                                    isLoading={false}
                                                                                    isClearable={true}
                                                                                    isSearchable={true}
                                                                                    onChange={event => handleChange(event, 'Result')}
                                                                                    value={result.filter(function (option) {
                                                                                        return option.value === inputFields[0].OverAllResult;
                                                                                    })}
                                                                                    options={result}
                                                                                    // styles={{
                                                                                    //     width: "200px",
                                                                                    //     border: submitted && inputFields[0].OverAllResult === '' ? styles2 : styles1

                                                                                    // }}
                                                                                    styles={
                                                                                        submitted && inputFields[0].OverAllResult === "" ? styles2 : styles1
                                                                                    }
                                                                                    //isDisabled={props.TaskStatus === 3 && props.editProtoFitSmsApproval === true}

                                                                                    menuPosition="fixed"

                                                                                ></Reactselect>

                                                                            </span>
                                                                        </div>
                                                                    </div>


                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                    <br></br>
                                                    {props.props.location.pathname != "/StyleListRegister" && <div className="row">
                                                        <div className="col-sm-12">
                                                            <button type="button" className="btn btn-success pull-right"

                                                                style={{
                                                                    display: props.TaskStatus === 3 && props.editProtoFitSmsApproval === false
                                                                        ? true : props.props.location.pathname !== '/TNAEdit' && props.TaskStatus === 3 ? 'none' : ''
                                                                }}
                                                                // disabled={props.TaskStatus === 3 && props.editProtoFitSmsApproval === false
                                                                //     ? true : props.props.location.pathname !== '/TNAEdit' && props.TaskStatus === 3 ? true : false}
                                                                onClick={() => SaveSubmission()}>

                                                                <i className="fa fa-check right"></i> &nbsp; {props.props.location.pathname !== '/TNAEdit' ? 'Save' : props.editProtoFitSmsApproval === false ? 'Save' : 'Update'}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    }

                                                    {
                                                        getID.showPopupDelete ? <SweetAlertPopup data={
                                                            getID.Params
                                                        }
                                                            deleteCallback={Delcallback}
                                                            showpopup={true}
                                                            pageActionId={ProtoFitSMSSubmissionApprovalId}
                                                            Msg={props.ParentTask + ' Deleted Successfully'} /> : null
                                                    }

                                                </Form>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </Fragment >
            </div >
        </>


    );
};

export default React.memo(ProtoFitSMSApproval);
