import axios from "axios";
import authHeader from "../auth-header";

class SKUService {
    LoadSKUList(StyleID) {
        return axios.get(window.$APIBaseURL + "Style/GetStyleSkuList?StyleID=" + StyleID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadSKUSamplesApprovalList(StyleID) {
        return axios.get(window.$APIBaseURL + "Style/GetStyleSamplesApprovalList?StyleID=" + StyleID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadSkuNameList(StyleID) {
        return axios.get(window.$APIBaseURL + "Style/LoadSkuNameList?StyleID=" + StyleID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    UpdateSKU(SKU){
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Style/InsertUpdateStyle',
            data: SKU,
            headers: {
                authorization: authHeader()
            }
        });
    }
}

export default new SKUService()