

import {
    TESTFIELD_LOADING,
    RETRIEVE_TESTFIELD,
    DELETE_TESTFIELD,
} from "./types";

import TestFieldService from "../services/Master/testfieldservice";

const TestFieldLoading = (isStatus) => ({
    type: TESTFIELD_LOADING,
    payload: isStatus,
});

export const deleteTestField = (testField) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_TESTFIELD,
            payload: testField,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrieveTestField = () => async (dispatch) => {
    try {
        dispatch(TestFieldLoading(true));
        const res = await TestFieldService.GetTestFieldList();
        dispatch({
            type: RETRIEVE_TESTFIELD,
            payload: res.data,
        });
        dispatch(TestFieldLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(TestFieldLoading(false));
    }
};