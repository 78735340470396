import {
    TESTNAME_LOADING,
    RETRIEVE_TESTNAME,
    DELETE_TESTNAME,
    RETRIEVE_MAPPING,
    MAPPING_LOADING,
    DELETE_MAPPING

} from "./types";
import TestServices from './../services/Master/TestService';

const TestNameLoading = (isStatus) => ({
    type: TESTNAME_LOADING,
    payload: isStatus,
});
const MappingLoading = (isStatus) => ({
    type: MAPPING_LOADING,
    payload: isStatus,
});
export const deleteTestName = (TestName) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_TESTNAME,
            payload: TestName,
        });

    } catch (err) {
        console.log(err);
    }
};


export const deleteMap = (CommonID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_MAPPING,
            payload: CommonID,
        });

    } catch (err) {
        console.log(err);
    }
};





export const retrieveTestName = (Operation, TaskId, TestNameId) => async (dispatch) => {
    try {
        dispatch(TestNameLoading(true));
        const res = await TestServices.GetTestNameList(Operation, TaskId, TestNameId);
        dispatch({
            type: RETRIEVE_TESTNAME,
            payload: res.data,
        });
        dispatch(TestNameLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(TestNameLoading(false));
    }
};


export const retriveMappingList = (Operation, MappingID) => async (dispatch) => {
    try {
        dispatch(MappingLoading(true));
        const res = await TestServices.GetMappingMasterList(Operation, MappingID);
        dispatch({
            type: RETRIEVE_MAPPING,
            payload: res.data,
        });
        dispatch(MappingLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(MappingLoading(false));
    }
};