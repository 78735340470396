import {
    STYLE_LOADING,
    RETRIEVE_STYLE,
    RETRIEVE_COPYSTYLE,
    DELETE_STYLE,
    RETRIEVE_SKU_STYLE,
    DELETE_SKU_STYLE,
    RETRIEVE_PROGRAM_STYLE,
    DELETE_PROGRAM_STYLE,
    RETRIEVE_STRIKE_OFF_STYLE,
    DELETE_STRIKE_OFF_STYLE,
    RETRIEVE_TRIM_STYLE,
    DELETE_TRIM_STYLE,
    RETRIEVE_TNA_LABDIP_ALREADY_MAP_LIST,
    RETRIEVE_TNA_STRIKEOFF_ALREADY_MAP_LIST,
    RETRIEVE_TNA_TRIM_ALREADY_MAP_LIST,
    UPDATE_PROGRAM_GRID_LIST,
    UPDATE_STRIKEOFF_MAP_LIST,
    UPDATE_TRIM_MAP_LIST,
    UPDATE_QUICK_STYLE_CREATE,
    STYLE_EMAIL_EMPLOYEE_LIST,
    GET_STYLE_EMAIL_LIST,
    DELETE_MAIL_ATTACHMENT
} from "../actions/types";

const initialState = {
    isLoadingStyle: true,
    styleList: [],
    copyStyleList: [],
    SKUList: [],
    programGridList: [],
    strikeOffList: { colorGroup: [], color: [] },
    trimList: { colorGroup: [], color: [] },
    TNALabDipMapList: [],
    TNAStrikeOffMapList: [],
    TNATrimMapList: [],
    allLabDipMapApproval: [],
    QuickStyleCreateDetail: { isQuickStyle: false, LastStyle: {} },
    EmailEmployeeList: [],
    StyleEmailList: []
};

const styleReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case STYLE_LOADING:
            return {
                ...state,
                isLoadingStyle: payload
            };
        case RETRIEVE_STYLE:
            return { ...state, styleList: payload };
        case RETRIEVE_COPYSTYLE:
            return { ...state, copyStyleList: payload }
        case DELETE_STYLE:
            const results = state.styleList.filter(c => c.styleID !== payload.styleID);
            return {
                ...state,
                styleList: results
            };
        case RETRIEVE_SKU_STYLE:
            return { ...state, SKUList: payload };
        case DELETE_SKU_STYLE:
            const resultsSKU = state.SKUList.filter(c => c.skuID !== payload.SKUID);
            return {
                ...state,
                SKUList: resultsSKU
            };
        case RETRIEVE_PROGRAM_STYLE:
            let Program = payload.program;
            let Critical = payload.critical;
            let Manual = payload.manual;
            let ManualFiles = payload.manualfiles;
            let LabDipMapApproval = payload.commonApproval;

            Program.forEach((pro, i) => {
                pro.IsCriticals = [{
                    ProgramCirticalInfoID: 0, IsCritical: '', RemindMe: 0, CheckSave: false,
                    TargettoClose: '', CompletedOn: '', IsDeleted: 0, IndexName: pro.indexName, //pro.indexName
                }];

                pro.IsManual = [{ StyleProgramManualId: 0, IsManual: '', ManualCloseComment: '', ManualCloseDate: '', CheckSave: false, StyleProgramIndexName: '', Files: [] }]
                Critical.forEach((crit, k) => {
                    if (crit.indexName === pro.indexName) {
                        if (pro.IsCriticals.length === 1 && pro.IsCriticals[0].IsCritical === '') {
                            pro.IsCriticals = [{
                                ProgramCirticalInfoID: crit.programCirticalInfoID, IsCritical: crit.isCritical, RemindMe: crit.remindMe,
                                CheckSave: true, TargettoClose: new Date(crit.targettoClose), CompletedOn: (crit.completedOn === undefined || crit.completedOn === "01/01/1900") ? '' : new Date(crit.completedOn), IsDeleted: 0,
                                IndexName: pro.indexName, //pro.indexName
                            }];
                        } else {
                            pro.IsCriticals.push({
                                ProgramCirticalInfoID: crit.programCirticalInfoID, IsCritical: crit.isCritical, RemindMe: crit.remindMe, CheckSave: true,
                                TargettoClose: new Date(crit.targettoClose), CompletedOn: crit.CompletedOn === undefined ? '' : new Date(crit.completedOn), IsDeleted: 0,
                                IndexName: pro.indexName, //pro.indexName
                            });
                        }
                    }
                });

                Manual.forEach((man, k) => {
                    if (man.styleProgramIndexName === pro.indexName) {
                        if (pro.IsManual.length === 1 && pro.IsManual[0].ManualCloseComment === '') {
                            pro.IsManual = [{
                                StyleProgramManualId: man.styleProgramManualId, ManualCloseComment: man.manualCloseComment, ManualCloseDate: new Date(man.manualCloseDate),
                                CheckSave: true, StyleProgramIndexName: pro.indexName, Files: [] //pro.indexName
                            }];
                        } else {
                            pro.IsManual.push({
                                StyleProgramManualId: man.styleProgramManualId, ManualCloseComment: man.manualCloseComment, ManualCloseDate: new Date(man.manualCloseDate),
                                CheckSave: true, StyleProgramIndexName: pro.indexName, Files: []
                            });
                        }

                        if (ManualFiles.length !== 0) {
                            ManualFiles.forEach((mf, m) => {
                                if (mf.styleProgramIndexName === pro.indexName) {
                                    pro.IsManual[0].Files[m] = mf
                                }
                            })
                            //pro.IsManual[0].Files = ManualFiles;
                        }

                    }
                });
            });

            return { ...state, programGridList: Program, allLabDipMapApproval: LabDipMapApproval };
        case DELETE_PROGRAM_STYLE:
            const resultsProgram = state.programGridList.filter(c => c.styleProgramID !== payload.styleProgramID);
            return {
                ...state,
                programGridList: resultsProgram
            };
        case UPDATE_PROGRAM_GRID_LIST:
            let index = payload.index;
            let labDibFabricInfoId = payload.labDibFabricInfoId;
            let updateProgram = state.programGridList;
            updateProgram[index].labDibFabricInfoId = labDibFabricInfoId
            return {
                ...state,
                programGridList: updateProgram
            };
        case RETRIEVE_STRIKE_OFF_STYLE:

            return { ...state, strikeOffList: payload };
        case DELETE_STRIKE_OFF_STYLE:
            const resultsColorGroup = state.strikeOffList.colorGroup.filter(c => c.styleStrikeOffPrintColorID !== payload.styleStrikeOffPrintColorID);
            const resultsStrikeOff = { colorGroup: resultsColorGroup, color: state.strikeOffList.color }
            return {
                ...state,
                strikeOffList: resultsStrikeOff
            };
        case RETRIEVE_TRIM_STYLE:

            return { ...state, trimList: payload };
        case DELETE_TRIM_STYLE:
            const resultsColorGp = state.trimList.colorGroup.filter(c => c.styleTrimLogoInfoID !== payload.styleTrimLogoInfoID);
            const resultsTrim = { colorGroup: resultsColorGp, color: state.trimList.color }
            return {
                ...state,
                trimList: resultsTrim
            };
        case RETRIEVE_TNA_LABDIP_ALREADY_MAP_LIST:
            return {
                ...state,
                TNALabDipMapList: payload
            };
        case RETRIEVE_TNA_STRIKEOFF_ALREADY_MAP_LIST:
            return {
                ...state,
                TNAStrikeOffMapList: payload
            };
        case RETRIEVE_TNA_TRIM_ALREADY_MAP_LIST:
            return {
                ...state,
                TNATrimMapList: payload
            };
        case UPDATE_STRIKEOFF_MAP_LIST:

            let mapindexstrikeoff = payload.index;
            let selectedstrikeoff = payload.selected;
            let updateStrikeoff = state.TNAStrikeOffMapList;
            updateStrikeoff.forEach(Element => {
                Element.isSelected = 0;
            })
            updateStrikeoff[mapindexstrikeoff].isSelected = selectedstrikeoff
            return {
                ...state,
                TNAStrikeOffMapList: updateStrikeoff
            };
        case UPDATE_TRIM_MAP_LIST:

            let mapindextrim = payload.index;
            let selectedtrim = payload.selected;
            let updateTrim = state.TNATrimMapList;
            updateTrim.forEach(Element => {
                Element.isSelected = 0;
            })
            updateTrim[mapindextrim].isSelected = selectedtrim
            return {
                ...state,
                TNATrimMapList: updateTrim
            };
        case UPDATE_QUICK_STYLE_CREATE:
            let QuickStyle = { isQuickStyle: payload.isQuickStyle, LastStyle: payload.LastStyle }
            return {
                ...state,
                QuickStyleCreateDetail: { ...QuickStyle }
            };
        case STYLE_EMAIL_EMPLOYEE_LIST:
            return {
                ...state,
                EmailEmployeeList: payload
            };
        case GET_STYLE_EMAIL_LIST:
            return {
                ...state,
                StyleEmailList: payload
            };
        case DELETE_MAIL_ATTACHMENT:
           ;
            var List = state.StyleEmailList;
            List.item3 = List.item3.filter(c => c.attachmentID !== payload.attachmentId);
            return {
                ...state,
                StyleEmailList: List
            };
        default:
            return state;

    }
};
export default styleReducer;