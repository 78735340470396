import axios from "axios";
import authHeader from "../auth-header";

class TaskService {
    getLoadTaskHolderList() {
        return axios.get(window.$APIBaseURL + "Master/LoadTaskHolderList", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    getLoadLeaveDaysList() {
        return axios.get(window.$APIBaseURL + "Master/LoadLeaveDaysList", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    getLoadTaskTypeList(Operation) {
        return axios.get(window.$APIBaseURL + "Master/LoadTaskTypeList?Operation=" + Operation, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    getLoadTaskOrderTypeList() {
        return axios.get(window.$APIBaseURL + "Master/LoadTaskOrderTypeList", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateTaskDetails(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateTaskDetails',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetTaskDetailsList(TaskDetailsID) {
        return axios.get(window.$APIBaseURL + "Master/GetTaskDetailsList?TaskDetailsID=" + TaskDetailsID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetAddtionalTaskDetailsList(AdditonalTaskDetailsID, TaskInformationID) {
        return axios.get(window.$APIBaseURL + "Master/GetAddtionalTaskDetailsList?AdditonalTaskDetailsID=" + AdditonalTaskDetailsID + '&TaskInformationID=' + TaskInformationID, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    GetDetailTypeList(DetailTypeID) {
        return axios.get(window.$APIBaseURL + "Master/GetDetailTypeList?DetailTypeID=" + DetailTypeID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

}
export default new TaskService();