import React, { useState, useEffect, useRef, Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactNotification from 'react-notifications-component';
import TrimViewDetailTab from "./TrimViewDetailTab";
import TrimViewRequestTab from "./TrimViewRequestTab";
import TrimViewSubmissionTab from "./TrimViewSubmissionTab";
import TrimViewApprovalTab from "./TrimViewApprovalTab";
import { getGeneralTrimDetailsViewList } from "../../../../actions/generalTrim";

const SamGenlTrimView = (props) => {
    const dispatch = useDispatch();
    let BuyerId = 0;
    let BrandId = 0;
    let SeasonId = 0;
    let TrimId = 0;

    let BuyerName = "";
    let BrandName = "";
    let SeasonName = "";

    //get props params
    if (props.location.state !== undefined) {
        BuyerId = props.location.state.params.buyerId === undefined ? 0 : props.location.state.params.buyerId;
        BrandId = props.location.state.params.brandId === undefined ? 0 : props.location.state.params.brandId;
        SeasonId = props.location.state.params.seasonId === undefined ? 0 : props.location.state.params.seasonId;

        BuyerName = props.location.state.params.buyerName === undefined ? 0 : props.location.state.params.buyerName;
        BrandName = props.location.state.params.brandName === undefined ? 0 : props.location.state.params.brandName;
        SeasonName = props.location.state.params.seasonName === undefined ? 0 : props.location.state.params.seasonName;
    }

    //status
    const [tabStatus, setTabStatus] = useState(1);

    //Tabs
    const tabClick = (tab) => {
        setTabStatus(tab);
    }
    let activeMenu;
    let activeMenuSub;
    let TrimDetailTab;
   ;
    let TrimRequestTab;
    let TrimSubmissionTab;
    let TrimApprovalTab;

    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
    if (menulist !== undefined && menulistSub.length > 0) {
        activeMenu = menulist.filter(i => i.menuName === 'TrimList');
        if (activeMenu.length > 0) {
            activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);
            if (activeMenuSub.length > 0) {
                for (let index = 0; index < activeMenuSub.length; index++) {
                    if (activeMenuSub[index].menuName === "GeneralTrimDetails") {
                        TrimDetailTab = activeMenuSub[index];
                    }
                    else if (activeMenuSub[index].menuName === "GeneralTrimRequest") {
                        TrimRequestTab = activeMenuSub[index];

                    }
                    else if (activeMenuSub[index].menuName === 'GeneralTrimSubmission') {
                        TrimSubmissionTab = activeMenuSub[index];

                    }
                    else if (activeMenuSub[index].menuName === 'GeneralTrimApproval') {
                        TrimApprovalTab = activeMenuSub[index];

                    }
                }
            }
        }
    }

    // If check production or sampling
 const activeMenus = useSelector((state) => state.auth.activeMenuList);
 let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
 let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal:activeMenus.IsProduction;
 // End

    useEffect(() => {
        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, TrimId: TrimId, ColorGroupID: 0,
            BuyerName: BuyerName, BrandName: BrandName, SeasonName: SeasonName, Index: 0, SearchText: "",
            StatusId: null, ApprovalTypeId: null, StartDate: null, EndDate: null,IsProduction:IsProduction
        };
        dispatch(getGeneralTrimDetailsViewList(params));

    }, []);



    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/SamGenlTrimList');

        } else {

        }
    }

    return (
        <Fragment>
            <div className="page-body">
                <ReactNotification />
                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <div className="widget flat radius-bordered">
                            <div className="widget-header heading_box_style">
                                <h3 className="heading_style_common">Trim View </h3>
                                <h5 className="heading_style_common"> &nbsp;&nbsp; ({BuyerName + " - " + SeasonName + " - " + BrandName})  </h5>
                                <div className="buttons-preview" align="right">
                                    <span className="btn btn-primary"
                                        onClick={
                                            () => ResetOperation("Back")
                                        }
                                    >
                                        <i className="fa fa-arrow-circle-left"></i>
                                        &nbsp;Back</span>
                                </div>
                            </div>

                            <div className="widget-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="tabbable">
                                            <ul className="nav nav-tabs nav-justified" id="myTab5">
                                                {/* {TrimDetailTab !== undefined && TrimDetailTab.isList === 1 && */}
                                                <li className="active" onClick={() => tabClick(1)}>
                                                    <a data-toggle="tab" href="#detailTab">
                                                        <h5>Details</h5>
                                                    </a>
                                                </li>
                                                {/* }
                                                {TrimRequestTab !== undefined && TrimRequestTab.isList === 1 && */}
                                                <li className="" onClick={() => tabClick(2)} >
                                                    <a data-toggle="tab" href="#requestTab">
                                                        <h5>Request TNA (Edit)</h5>
                                                    </a>
                                                </li>
                                                {/* }
                                                {TrimSubmissionTab !== undefined && TrimSubmissionTab.isList === 1 && */}
                                                <li className="" onClick={() => tabClick(3)} >
                                                    <a data-toggle="tab" href="#submissionTab">
                                                        <h5>Submitted</h5>
                                                    </a>
                                                </li>
                                                {/* }
                                                {TrimApprovalTab !== undefined && TrimApprovalTab.isList === 1 && */}
                                                <li className="" onClick={() => tabClick(4)} >
                                                    <a data-toggle="tab" href="#approvalTab">
                                                        <h5>Approved</h5>
                                                    </a>
                                                </li>
                                                {/* } */}
                                            </ul>
                                            <div className="tab-content">
                                                <div id="detailTab" className="tab-pane in active" style={{ width: "100%" }}>
                                                    {TrimDetailTab && TrimDetailTab.isList === 1 ?
                                                        <TrimViewDetailTab
                                                            props={props} />  : <span><h3 className="red">User has access denied. Please contact the administrator! </h3></span>
                                                    }
                                                </div>
                                                <div id="requestTab" className="tab-pane" style={{ width: "100%" }}>
                                                    {TrimRequestTab && TrimRequestTab.isList === 1 ?
                                                        <TrimViewRequestTab props={props} /> : <span><h3 className="red">User has access denied. Please contact the administrator! </h3></span>
                                                    }

                                                </div>
                                                <div id="submissionTab" className="tab-pane" style={{ width: "100%" }}>
                                                    {TrimSubmissionTab && TrimSubmissionTab.isList === 1 ?
                                                        <TrimViewSubmissionTab props={props} /> : <span><h3 className="red">User has access denied. Please contact the administrator! </h3></span>
                                                  }


                                                </div>
                                                <div id="approvalTab" className="tab-pane" style={{ width: "100%" }}>
                                                    {TrimApprovalTab && TrimApprovalTab.isList === 1 ?
                                                        <TrimViewApprovalTab props={props} />  : <span><h3 className="red">User has access denied. Please contact the administrator! </h3></span>
                                                    }

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </Fragment>
    )

};
export default SamGenlTrimView;