import {
    LOAD_AGENCY_DETAILS,
    AGENCY_LOADING
} from "../actions/types";

const initialState = {
    AgencyList: [],
    isLoadingAgency: true,
    AgencyType: []
};

const agencyReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case AGENCY_LOADING:
            return {
                ...state,
                isLoadingAgency: payload
            };
        case LOAD_AGENCY_DETAILS:
            return {
                ...state,
                AgencyType: payload.item1,
                AgencyList: payload.item2
            };
        default:
            return state;
    }
}

export default agencyReducer;