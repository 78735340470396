import {
    GENERALSTRIKEOFF_LOADING,
    RETRIEVE_GENERALSTRIKEOFF,
    DELETE_GENERALSTRIKEOFF,
    RETRIEVE_GENERAL_STRIKEOFF_DETAIL_VIEW_LIST,
    CLEAR_GENERAL_STRIKEOFF_DETAIL_VIEW_SUB_ROW,
    UPDATE_GENERAL_STRIKEOFF_DETAIL_VIEW_CHECK_BOX,
    NEW_TNA_GENERAL_STRIKEOFF_REFERENCE_LIST,
    REMOVE_TNA_GENERAL_STRIKEOFF_REFERENCE_LIST,
    UPDATE_GENERAL_STRIKEOFF_DETAIL_VIEW_ALL_CHECK_BOX,
    RETRIEVE_GENERAL_STRIKEOFF_SUBMISSION_LIST,
    RETRIEVE_GENERAL_STRIKEOFF_APPROVAL_LIST,
    CLEAR_GENERAL_STRIKEOFF_SUBMISSION_SUB_ROW,
    TNA_TASK_GENERAL_STRIKEOFF_SUBMISSION_AND_APPROVAL,
    CLEAR_GENERAL_STRIKEOFF_APPROVAL_SUB_ROW,
    RETRIEVE_GENERAL_STRIKEOFF_APPROVAL_VIEW_LIST,
    CLEAR_GENERAL_STRIKEOFF_APPROVAL_VIEW_SUB_ROW,
    UPDATE_GENERAL_STRIKEOFF_IS_APPROVAL_MANUAL_CLOSE_POPUP,
    GENERAL_STRIKEOFF_REFERENCE_STATUS_LIST,
    REMOVE_GENERAL_STRIKEOFF_REQUEST_VIEW_SUB_ROW
} from "./types";
import GeneralService from "../services/General/GeneralService";

const generalStrikeOffLoading = (isStatus) => ({
    type: GENERALSTRIKEOFF_LOADING,
    payload: isStatus,
});

export const deletegeneralStrikeOff = (genStrikeOffId) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_GENERALSTRIKEOFF,
            payload: genStrikeOffId,
        });

    } catch (err) {
        console.log(err);
    }
};



export const retrievegeneralStrikeOff = (IsProduction) => async (dispatch) => {
    try {
        dispatch(generalStrikeOffLoading(true));
        const res = await GeneralService.GetGeneralStrikeOffList(IsProduction);
        dispatch({
            type: RETRIEVE_GENERALSTRIKEOFF,
            payload: res.data,
        });
        dispatch(generalStrikeOffLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(generalStrikeOffLoading(false));
    }
};

export const getGeneralStrikeOffDetailsViewList = (params) => async (dispatch) => {
    try {
        dispatch(generalStrikeOffLoading(true));
        const res = await GeneralService.getGeneralStrikeOffDetailsViewList(params);
        dispatch({
            type: RETRIEVE_GENERAL_STRIKEOFF_DETAIL_VIEW_LIST,
            payload: { data: res.data, params: params },
        });
        dispatch(generalStrikeOffLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(generalStrikeOffLoading(false));
    }
};

export const clearStrikeOffDetailsViewSubRow = (index) => async (dispatch) => {
    dispatch(generalStrikeOffLoading(true));
    dispatch({
        type: CLEAR_GENERAL_STRIKEOFF_DETAIL_VIEW_SUB_ROW,
        payload: index,
    });
    dispatch(generalStrikeOffLoading(false));

};
export const UpdateGenStrikeOffDetailsViewAllCheckBox = (isChecked) => async (dispatch) => {
    dispatch(generalStrikeOffLoading(true));
    dispatch({
        type: UPDATE_GENERAL_STRIKEOFF_DETAIL_VIEW_ALL_CHECK_BOX,
        payload: isChecked,
    });
    dispatch(generalStrikeOffLoading(false));

};

export const UpdateGenStrikeOffDetailsViewCheckBox = (params) => async (dispatch) => {
    dispatch(generalStrikeOffLoading(true));
    dispatch({
        type: UPDATE_GENERAL_STRIKEOFF_DETAIL_VIEW_CHECK_BOX,
        payload: params,
    });
    dispatch(generalStrikeOffLoading(false));

};



export const getGenTNAStrikeOffReferenceList = (params) => async (dispatch) => {
    try {
        dispatch(generalStrikeOffLoading(true));
        const res = await GeneralService.GetGenTNAStrikeOffReferenceList(params);
        dispatch({
            type: NEW_TNA_GENERAL_STRIKEOFF_REFERENCE_LIST,
            payload: res.data,
        });
        dispatch(generalStrikeOffLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(generalStrikeOffLoading(false));
    }
};


export const removeStrikeOffReferenceByIndex = (index) => async (dispatch) => {

    try {
        dispatch(generalStrikeOffLoading(true));
        dispatch({
            type: REMOVE_TNA_GENERAL_STRIKEOFF_REFERENCE_LIST,
            payload: index,
        });
        dispatch(generalStrikeOffLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(generalStrikeOffLoading(false));
    }
};


export const InsertGenralStrikeOffTNA = (mainValues) => async (dispatch) => {
    let resData = "";
    dispatch(generalStrikeOffLoading(true));
    await GeneralService.InsertGenralStrikeOffTNA(mainValues).then((res) => {
        resData = res.data;
        dispatch(generalStrikeOffLoading(false));
    }).catch((err) => {
        dispatch(generalStrikeOffLoading(false));
        return Promise.reject(err);
    });
    return Promise.resolve(resData);
}



export const GetGeneralStrikeOffSubmissionApprovalGridList = (params) => async (dispatch) => {
    try {
        dispatch(generalStrikeOffLoading(true));
        const res = await GeneralService.GetGeneralStrikeOffSubmissionApprovalGridList(params);
        dispatch({
            type: RETRIEVE_GENERAL_STRIKEOFF_SUBMISSION_LIST,
            payload: { data: res.data, params: params },
        });
        dispatch(generalStrikeOffLoading(false));

    } catch (err) {
        console.log(err);
        dispatch(generalStrikeOffLoading(false));
    }
};



export const UpdateGenralStrikeOffTNA = (mainValues) => async (dispatch) => {
    let resData = "";
    dispatch(generalStrikeOffLoading(true));
    await GeneralService.UpdateGenralStrikeOffTNA(mainValues).then((res) => {
        resData = res.data;
        dispatch(generalStrikeOffLoading(false));
    }).catch((err) => {
        dispatch(generalStrikeOffLoading(false));
        return Promise.reject(err);
    });
    return Promise.resolve(resData);
}

export const clearStrikeOffSubmissionSubRow = (index) => async (dispatch) => {
    dispatch(generalStrikeOffLoading(true));
    dispatch({
        type: CLEAR_GENERAL_STRIKEOFF_SUBMISSION_SUB_ROW,
        payload: index,
    });
    dispatch(generalStrikeOffLoading(false));

};

export const getGenStrikeOffTNASubmissionAndApproval = (genStrikeOffSupplierTNAId, genStrikeOffDetailTNAId) => async (dispatch) => {
    let resData = "";

    dispatch(generalStrikeOffLoading(true));
    await GeneralService.GetGenStrikeOffTNATASKSubmissionAndApproval(genStrikeOffSupplierTNAId, genStrikeOffDetailTNAId).then((res) => {
        resData = res.data;
        dispatch({
            type: TNA_TASK_GENERAL_STRIKEOFF_SUBMISSION_AND_APPROVAL,
            payload: res.data,
        });
        dispatch(generalStrikeOffLoading(false));
    }).catch((err) => {
        dispatch(generalStrikeOffLoading(false));
        return Promise.reject(err);
    });
    return Promise.resolve(resData);
}


export const GetGeneralStrikeOffApprovalList = (params) => async (dispatch) => {
    try {
        dispatch(generalStrikeOffLoading(true));
        const res = await GeneralService.GetGeneralStrikeOffSubmissionApprovalGridList(params);
        dispatch({
            type: RETRIEVE_GENERAL_STRIKEOFF_APPROVAL_LIST,
            payload: { data: res.data, params: params },
        });
        dispatch(generalStrikeOffLoading(false));

    } catch (err) {
        console.log(err);
        dispatch(generalStrikeOffLoading(false));
    }
};

export const clearStrikeOffApprovalSubRow = (index) => async (dispatch) => {
    dispatch(generalStrikeOffLoading(true));
    dispatch({
        type: CLEAR_GENERAL_STRIKEOFF_APPROVAL_SUB_ROW,
        payload: index,
    });
    dispatch(generalStrikeOffLoading(false));

};

export const GetGeneralStrikeOffApprovalViewList = (params) => async (dispatch) => {
    try {
        dispatch(generalStrikeOffLoading(true));
        const res = await GeneralService.GetGeneralStrikeOffSubmissionApprovalGridList(params);
        dispatch({
            type: RETRIEVE_GENERAL_STRIKEOFF_APPROVAL_VIEW_LIST,
            payload: { data: res.data, params: params },
        });
        dispatch(generalStrikeOffLoading(false));

    } catch (err) {
        console.log(err);
        dispatch(generalStrikeOffLoading(false));
    }
};

export const clearStrikeOffApprovalViewSubRow = (index) => async (dispatch) => {
    dispatch(generalStrikeOffLoading(true));
    dispatch({
        type: CLEAR_GENERAL_STRIKEOFF_APPROVAL_VIEW_SUB_ROW,
        payload: index,
    });
    dispatch(generalStrikeOffLoading(false));

};

export const RemoveStrikeOffRequestRow = (params) => async (dispatch) => {
    dispatch(generalStrikeOffLoading(true));
    dispatch({
        type: REMOVE_GENERAL_STRIKEOFF_REQUEST_VIEW_SUB_ROW,
        payload: params,
    });
    dispatch(generalStrikeOffLoading(false));

};


export const UpdateIsApprovalManualClosePopup = (index, subIndex, isOpen) => async (dispatch) => {
    dispatch(generalStrikeOffLoading(true));
    dispatch({
        type: UPDATE_GENERAL_STRIKEOFF_IS_APPROVAL_MANUAL_CLOSE_POPUP,
        payload: { index, subIndex, isOpen },
    });
    dispatch(generalStrikeOffLoading(false));

};

export const getGenStrikeOffTNARefernceStatusList = (params) => async (dispatch) => {
    let resData = "";
    dispatch(generalStrikeOffLoading(true));
    await GeneralService.GetGenStrikeOffTNARefernceStatusList(params).then((res) => {
        resData = res.data;
        dispatch({
            type: GENERAL_STRIKEOFF_REFERENCE_STATUS_LIST,
            payload: res.data,
        });
        dispatch(generalStrikeOffLoading(false));
    }).catch((err) => {
        dispatch(generalStrikeOffLoading(false));
        return Promise.reject(err);
    });
    return Promise.resolve(resData);

};


