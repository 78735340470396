import React, { useState, useEffect, Fragment } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import axios from 'axios';

const mapStyles = {
    width: '1000px',
    height: '500px',
    position: 'static'
};

const UserLocation = (props, { parentCallback }) => {
   
    const [getGeolocation, setGeolocation] = useState({
        Location: '',
        Latitude: props.LatLng.Latitude,
        Longitude: props.LatLng.Longitude
    })
    var locationDetails = {
        ...getGeolocation
    };
    var state = {
        showingInfoWindow: false,
        activeMarker: {},
        selectedPlace: {},
    };



    useEffect(() => {
        var geocoder;
        geocoder = new props.google.maps.Geocoder();
        var latlng = new props.google.maps.LatLng(getGeolocation.Latitude, getGeolocation.Longitude);
        geocoder.geocode(
            { 'latLng': latlng },
            function (results, status) {
                if (status == props.google.maps.GeocoderStatus.OK) {
                    if (results[0]) {
                        locationDetails.Location = results[0].formatted_address;
                        setGeolocation(locationDetails);
                    }
                    else {
                        locationDetails.Location = "Address not found.";
                        setGeolocation(locationDetails);
                    }
                }
                else {
                }
            }
        );
    }, [getGeolocation])

    return (
        <Fragment>
            <b>Latitude:</b>&nbsp;{getGeolocation.Latitude}<br />
            <b>Longitude:</b>&nbsp;{getGeolocation.Longitude}<br />
            <b>Location:</b>&nbsp;{getGeolocation.Location}<br />

            <Map className="margin-top-20"
                // onClick={onMarkerClick}
                keyboardShortcuts={false}
                google={props.google}
                zoom={16}
                style={mapStyles}
                initialCenter={
                    {
                        lat: getGeolocation.Latitude,
                        lng: getGeolocation.Longitude
                    }
                }
                center={{
                    lat: getGeolocation.Latitude,
                    lng: getGeolocation.Longitude
                }}
            >

                <Marker
                    //draggable
                    //onDragend={onMarkerDrag}
                    position={{
                        lat: getGeolocation.Latitude,
                        lng: getGeolocation.Longitude
                    }}>
                </Marker>
            </Map>
        </Fragment>
    )
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyDHgipImLEiJaEMyXtrq6XP0XJEKYIRbK0'
})(UserLocation);