import {
 DEFECT_LOADING,
 RETRIEVE_DEFECT,
 DELETE_DEFECT
} from "./types";

import DefectService from "../services/Master/DefectService";


const DefectLoading = (isStatus) => ({
 type: DEFECT_LOADING,
 payload: isStatus,
});

export const createDefect = (getDefect) => async (dispatch) => {
 
let resData="";
 await DefectService.AddDefect(getDefect).then((res) => {
  resData=res.data;

}).catch((err) => { return Promise.reject(err); });
return Promise.resolve(resData);
}


export const deleteDefect = (defectTypeID) => async (dispatch) => {
 try {
  dispatch({
   type: DELETE_DEFECT,
   payload: defectTypeID,
  });

 } catch (err) {
  console.log(err);
 }
};


export const retrieveDefect = () => async (dispatch) => {
 try {

  dispatch(DefectLoading(true));
  const res = await DefectService.getAllDefectList();
  dispatch({
   type: RETRIEVE_DEFECT,
   payload: res.data,
  });
  dispatch(DefectLoading(false));
 } catch (err) {
  console.log(err);
  dispatch(DefectLoading(false));
 }
};
