import {
    GENERALLABDIP_LOADING,
    RETRIEVE_GENERALLABDIP,
    DELETE_GENERALLABDIP,
    RETRIEVE_GENERAL_LABDIP_DETAIL_VIEW_LIST,
    CLEAR_GENERAL_LABDIP_DETAIL_VIEW_SUB_ROW,
    RETRIEVE_GEN_LABDIP_ALREADY_MAP_LIST,
    UPDATE_GEN_PROGRAM_GRID_LIST,
    RETRIEVE_GENERAL_LABDIP_SUBMISSION_LIST,
    CLEAR_GENERAL_LABDIP_SUBMISSION_SUB_ROW,
    RETRIEVE_GENERAL_LABDIP_APPROVAL_LIST,
    CLEAR_GENERAL_LABDIP_APPROVAL_SUB_ROW,
    RETRIEVE_GENERAL_LABDIP_APPROVAL_VIEW_LIST,
    CLEAR_GENERAL_LABDIP_APPROVAL_VIEW_SUB_ROW,
    UPDATE_GENERAL_LABDIP_DETAIL_VIEW_CHECK_BOX,
    NEW_TNA_GENERAL_LABDIP_LIST,
    TNA_GENERAL_LABDIP_HOLIDAY_LIST,
    REMOVE_TNA_GENERAL_LABDIP_PANTONE_SWATCH_LIST,
    TNA_GENERAL_LABDIP_SUBMISSION_AND_APPROVAL,
    GENERAL_LABDIP_PROGRAM_STATUS_LIST,
    GLD_TNA_TASK_OWNER_LIST,
    UPDATE_GENERAL_LAPDIP_DETAIL_VIEW_ALL_CHECK_BOX
} from "./types";
import GeneralService from "../services/General/GeneralService";

const generalLabdipLoading = (isStatus) => ({
    type: GENERALLABDIP_LOADING,
    payload: isStatus,
});

export const deletegeneralLabdip = (genLabdipId) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_GENERALLABDIP,
            payload: genLabdipId,
        });

    } catch (err) {
        console.log(err);
    }
};



export const retrievegeneralLabdip = (IsProduction) => async (dispatch) => {
    try {
        dispatch(generalLabdipLoading(true));
        const res = await GeneralService.GetGeneralLabdipList(IsProduction);
        dispatch({
            type: RETRIEVE_GENERALLABDIP,
            payload: res.data,
        });
        dispatch(generalLabdipLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(generalLabdipLoading(false));
    }
};

export const getGeneralLapdipDetailsViewList = (params) => async (dispatch) => {
    try {
        dispatch(generalLabdipLoading(true));
        const res = await GeneralService.GetGeneralLapdipDetailsViewList(params);
        dispatch({
            type: RETRIEVE_GENERAL_LABDIP_DETAIL_VIEW_LIST,
            payload: { data: res.data, params: params },
        });
        dispatch(generalLabdipLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(generalLabdipLoading(false));
    }
};

export const clearLapdipDetailsViewSubRow = (index) => async (dispatch) => {
    dispatch(generalLabdipLoading(true));
    dispatch({
        type: CLEAR_GENERAL_LABDIP_DETAIL_VIEW_SUB_ROW,
        payload: index,
    });
    dispatch(generalLabdipLoading(false));

};

export const getGenLabDipAlreadyMapList = (params) => async (dispatch) => {

    let isSuccess = false;
    try {
        dispatch(generalLabdipLoading(true));
        const res = await GeneralService.GetGenLabDipAlreadyMapList(params);
        dispatch({

            type: RETRIEVE_GEN_LABDIP_ALREADY_MAP_LIST,
            payload: res.data,
        });
        isSuccess = res.data.length > 0 ? true : false;
        dispatch(generalLabdipLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(generalLabdipLoading(false));
        return Promise.reject(err);
    }
    return Promise.resolve(isSuccess);
};

export const GetGeneralLapdipSubmissionApprovalList = (params) => async (dispatch) => {
    try {
        dispatch(generalLabdipLoading(true));
        const res = await GeneralService.GetGeneralLapdipSubmissionApprovalList(params);
        dispatch({
            type: RETRIEVE_GENERAL_LABDIP_SUBMISSION_LIST,
            payload: { data: res.data, params: params },
        });
        // dispatch(generalLabdipLoading(false));

    } catch (err) {
        console.log(err);
        dispatch(generalLabdipLoading(false));
    }
};

export const clearLapdipSubmissionSubRow = (index) => async (dispatch) => {
    dispatch(generalLabdipLoading(true));
    dispatch({
        type: CLEAR_GENERAL_LABDIP_SUBMISSION_SUB_ROW,
        payload: index,
    });
    dispatch(generalLabdipLoading(false));

};

export const GetGeneralLapdipApprovalList = (params) => async (dispatch) => {
    try {
        dispatch(generalLabdipLoading(true));
        const res = await GeneralService.GetGeneralLapdipSubmissionApprovalList(params);
        dispatch({
            type: RETRIEVE_GENERAL_LABDIP_APPROVAL_LIST,
            payload: { data: res.data, params: params },
        });
        dispatch(generalLabdipLoading(false));

    } catch (err) {
        console.log(err);
        dispatch(generalLabdipLoading(false));
    }
};

export const clearLapdipApprovalSubRow = (index) => async (dispatch) => {
    dispatch(generalLabdipLoading(true));
    dispatch({
        type: CLEAR_GENERAL_LABDIP_APPROVAL_SUB_ROW,
        payload: index,
    });
    dispatch(generalLabdipLoading(false));

};

export const GetGeneralLapdipApprovalViewList = (params) => async (dispatch) => {
    try {
        dispatch(generalLabdipLoading(true));
        const res = await GeneralService.GetGeneralLapdipSubmissionApprovalList(params);
        dispatch({
            type: RETRIEVE_GENERAL_LABDIP_APPROVAL_VIEW_LIST,
            payload: { data: res.data, params: params },
        });
        dispatch(generalLabdipLoading(false));

    } catch (err) {
        console.log(err);
        dispatch(generalLabdipLoading(false));
    }
};

export const clearLapdipApprovalViewSubRow = (index) => async (dispatch) => {
    dispatch(generalLabdipLoading(true));
    dispatch({
        type: CLEAR_GENERAL_LABDIP_APPROVAL_VIEW_SUB_ROW,
        payload: index,
    });
    dispatch(generalLabdipLoading(false));

};

export const UpdateGenLapdipDetailsViewAllCheckBox = (isChecked) => async (dispatch) => {

    dispatch(generalLabdipLoading(true));
    dispatch({
        type: UPDATE_GENERAL_LAPDIP_DETAIL_VIEW_ALL_CHECK_BOX,
        payload: isChecked,
    });
    dispatch(generalLabdipLoading(false));

};
export const UpdateGenLapdipDetailsViewCheckBox = (params) => async (dispatch) => {
    dispatch(generalLabdipLoading(true));
    dispatch({
        type: UPDATE_GENERAL_LABDIP_DETAIL_VIEW_CHECK_BOX,
        payload: params,
    });
    dispatch(generalLabdipLoading(false));

};




export const getGenLapdibPantoneOrSwatchList = (params) => async (dispatch) => {
    try {
        dispatch(generalLabdipLoading(true));
        const res = await GeneralService.GetGenLapdibPantoneOrSwatchList(params);
        dispatch({
            type: NEW_TNA_GENERAL_LABDIP_LIST,
            payload: res.data,
        });
        dispatch(generalLabdipLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(generalLabdipLoading(false));
    }
};

export const updateProgramGridList = (index, labdibSubmissionApprovalID) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_GEN_PROGRAM_GRID_LIST,
            payload: { index: index, labdibSubmissionApprovalID: labdibSubmissionApprovalID },
        });

    } catch (err) {
        console.log(err);
    }
};



export const getGenLapdibTNAHolidayList = (params) => async (dispatch) => {
    try {
        dispatch(generalLabdipLoading(true));
        const res = await GeneralService.GetGenLapdibTNAHolidayList(params);
        dispatch({
            type: TNA_GENERAL_LABDIP_HOLIDAY_LIST,
            payload: res.data,
        });
        dispatch(generalLabdipLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(generalLabdipLoading(false));
    }
};

export const removePantoneSwatchByIndex = (index) => async (dispatch) => {

    try {
        dispatch(generalLabdipLoading(true));
        dispatch({
            type: REMOVE_TNA_GENERAL_LABDIP_PANTONE_SWATCH_LIST,
            payload: index,
        });
        dispatch(generalLabdipLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(generalLabdipLoading(false));
    }
};

export const InsertGenralLabdipTNA = (mainValues) => async (dispatch) => {
    let resData = "";
    dispatch(generalLabdipLoading(true));
    await GeneralService.InsertGenralLabdipTNA(mainValues).then((res) => {
        resData = res.data;
        dispatch(generalLabdipLoading(false));
    }).catch((err) => {
        dispatch(generalLabdipLoading(false));
        return Promise.reject(err);
    });
    return Promise.resolve(resData);
}

export const UpdateGenralLabdipTNA = (mainValues) => async (dispatch) => {
    let resData = "";
    dispatch(generalLabdipLoading(true));
    await GeneralService.UpdateGenralLabdipTNA(mainValues).then((res) => {
        resData = res.data;
        dispatch(generalLabdipLoading(false));
    }).catch((err) => {
        dispatch(generalLabdipLoading(false));
        return Promise.reject(err);
    });
    return Promise.resolve(resData);
}


export const getGenLabdipTNASubmissionAndApproval = (genralLabdipTNAId, genralLabdipTNAProgramDetailId) => async (dispatch) => {
    let resData = "";

    dispatch(generalLabdipLoading(true));
    await GeneralService.GetGenLabdipTNASubmissionAndApproval(genralLabdipTNAId, genralLabdipTNAProgramDetailId).then((res) => {
        resData = res.data;
        dispatch({
            type: TNA_GENERAL_LABDIP_SUBMISSION_AND_APPROVAL,
            payload: res.data,
        });
        dispatch(generalLabdipLoading(false));
    }).catch((err) => {
        dispatch(generalLabdipLoading(false));
        return Promise.reject(err);
    });
    return Promise.resolve(resData);
}


export const getGenLapdipTNAProgramStatusList = (params) => async (dispatch) => {
    let resData = "";
    dispatch(generalLabdipLoading(true));
    await GeneralService.GetGenLapdipTNAProgramStatusList(params).then((res) => {
        resData = res.data;
        dispatch({
            type: GENERAL_LABDIP_PROGRAM_STATUS_LIST,
            payload: res.data,
        });
        dispatch(generalLabdipLoading(false));
    }).catch((err) => {
        dispatch(generalLabdipLoading(false));
        return Promise.reject(err);
    });
    return Promise.resolve(resData);

};

export const loadGLDTNATaskOwnerList = (TaskHolderID, BuyerID, UserLoginID, SupplierIdList) => async (dispatch) => {
    let resData = [];
    try {
        if (TaskHolderID > 0) {
            const res = await GeneralService.LoadGLDTNATaskOwnerList({ TaskHolderID: TaskHolderID, BuyerID: BuyerID, UserLoginID: UserLoginID, SupplierIdList: SupplierIdList });
            dispatch({
                type: GLD_TNA_TASK_OWNER_LIST,
                payload: res.data,
            });
            resData = res.data;
        } else {
            dispatch({
                type: GLD_TNA_TASK_OWNER_LIST,
                payload: [],
            });
            resData = [];
        }
    } catch (err) {
        console.log(err);
    }
    return Promise.resolve(resData);
};





