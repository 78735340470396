import React, { useState, useEffect, useMemo } from "react";
import ReactNotification from 'react-notifications-component';
import { TableHeader, Pagination, Search, PageCount } from "../../Datatable";
import { useDispatch, useSelector } from "react-redux";
import Nodify from "../../Common/ReactNotification";
import SearchRetain from "../../Common/SearchRetain";
import warehouse from "../../../services/Master/WarehouseService";
import WorkmanshiGroup from "../../../services/Master/WorkmanshiGroup";
import CommonService from '../../../services/Common/CommonService';
import SelectCombobox from 'react-select';
const Editwarehouse = (props) => {
   
    const [WorkmandetList, setWorkmandetList] = useState([]);// for workmanitem list
    const [BuyerList, setBuyerList] = useState([]);// for cagetgory list
    const [buttonLoader, setButtonLoader] = useState(false);
    const [warehouseList, setWarehouseList] = useState([]);// For comBuySupID based matched value warehouseID and warehousename
    const [submitted, setSubmitted] = useState(false);
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [getWorkmanshipGp, SetWorkmanshipGp] = useState([{ Value: 0, label: '' }]);//For handle changes uses
    const { user: currentUser } = useSelector((state) => state.auth);
    const [getID, setID] = useState({ showPopupDelete: false, Params: {} });
    useEffect(() => {
       
        let CombuysupID = [];
        warehouse.GetWarehouseList(2, props.location.state.params.compBuySupID).then((res) => {
            if (res.data) {
                setWarehouseList(res.data.warehouseEdt)
            }
        })
        warehouse.GetWarehouseList(1, props.location.state.params.compBuySupID).then((res) => {
            if (res.data) {
                res.data.warehouselst.map((x) => {
                    CombuysupID.push({
                        value: x.compBuySupID,
                        label: x.buyerName
                    })
                })
                setBuyerList(CombuysupID)
            }
        })
    }, [])
    const handleRemoveFields = (index, value) => {
       
        const values = [...warehouseList]
        // if (values[index].compBuySupID !== 0 && values[index].compBuySupID !== undefined) {
        //     values[index].Isdeleted = 1;
        // } else {
        //     values.splice(index, 1);
        // }
        let Params = {
            Operation: 96,
            Id: value.warehouseID,
        }
        CommonService.CheckMappedItem(Params).then(res => {
            if (res.data.outputResult === "-2") {
                let Title = "Warning!";
                let message = "This Warehouse is already mapped.";
                let type = "warning";
                Nodify(Title, type, message);
            }
            else {
                values.splice(index, 1);
            }

            setWarehouseList(values)
        });
        //values.splice(index, 1);


    };
    const handleAddFields = (index) => {
       
        const values = [...warehouseList];
        if (values[index].warehousename !== "") {
            values.push({ warehouseID: 0, warehousename: "" });
            setWarehouseList(values);
            setSubmitted(false);
        }
        else {
            Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
            setSubmitted(true);
        }

    };
    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/WarehouseList');
        } else {
            window.location.reload();

        }
    }
    const headers = [
        { name: <span> Warehouse Name<span className="text-danger">*</span></span>, field: "workmanItemName", sortable: false },
        {
            name: "Action", field: "", sortable: false,
            width: "13%"
        },
    ];

    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),

    }
    const handleInputChange = (index, e, FieldName) => {
       
        const values = [...warehouseList]
        let inputtext = ''
        if (FieldName === "Warehouse") {

            if (e.currentTarget.value.trim() !== '') {
                inputtext = e.currentTarget.value;
            }


            values[index].warehousename = inputtext
        }
        setWarehouseList(values)
    }
    const CheckDuplicate = (index, Field) => {
       
        const values = [...warehouseList];
        for (var j = 0; j < (warehouseList.length); j++) {
            if (j !== index) {
                if (values[j].warehousename.trim() === values[index].warehousename.trim()
                ) {
                    values[index].warehousename = "";
                    setWarehouseList(values);
                    Nodify('Warning!', 'warning', 'This Warehouse Name is already exist.');
                    return false;
                }
            }
        }
    }
    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/WarehouseList",
            state: {
                message: Func,
            },
        }
        );
    };
    const SaveWarehouse = (e) => {
        setButtonLoader(true);
        e.preventDefault();
       
        for (var i = 0; i < (warehouseList.length); i++) {
            if (warehouseList[i].warehousename === "") {
                Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
                setSubmitted(true);
                setButtonLoader(false);
                return false;
            }
        }
        let WarehouseType = []
        warehouseList.map((x) => {
            WarehouseType.push({
                WarehouseID: x.warehouseID,
                Warehousename: x.warehousename,
                Isdeleted: x.Isdeleted !== undefined ? x.Isdeleted : 0
            })
        })
        let InputMaster = { Operation: 2, CompBuySupID: BuyerList[0].value, name: BuyerList[0].label, WarehouseInformation: WarehouseType, Createdby: currentUser.employeeinformationID }
        warehouse.UpdateWarehouse(InputMaster).then(res => {
            var page = "Remove";
            SearchRetain(page);
            let Func = 'Add';
            if (res.data.outputResult === "1") {
                Func = 'Add';
                PageRedirect(Func);
            } else if (res.data.outputResult === "2") {
                Func = 'Edit';
                PageRedirect(Func);
            }
            setButtonLoader(false);
        });

    }
    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">Edit Warehouse </h3>
                        </div>

                        <div className="widget-body">
                            <div className="row">
                                <div className="col-lg-12 margin-top-10">
                                    <label>Buyer<span className="text-danger">*</span></label>
                                </div>
                                <div className="col-sm-3">
                                    <SelectCombobox className="basic-single" name="Buyer" id={"Buyer"}
                                        isDisabled={true}
                                        isLoading={false}
                                        isSearchable={true}
                                        isClearable={true}
                                        autocomplete='off'
                                        //onChange={event => handleInputCategoryChange(event, "Category")}
                                        //styles={submitted && getCategoryGp === undefined ? styles : ''}
                                        //options={categoryTypeList}
                                        // value={categoryTypeList.filter(function (option) {
                                        //     return option.value === getCategoryGp.value;
                                        // })}
                                        value={BuyerList}
                                    />
                                </div>
                            </div>
                            <div id="registration-form">
                                <form>
                                    {
                                        <table className="table table-striped table-bordered table-hover dataTable no-footer margin-top-10" id="editabledatatable" style={{ width: "50%" }}>
                                            <TableHeader headers={headers} onSorting={(field, order) => setSorting({ field, order })} />
                                            <tbody>
                                                {warehouseList.filter(x => x.Isdeleted !== 1).map((inputfeild, index) => (
                                                    <tr>
                                                        <td>
                                                            <input type="text"
                                                                className="form-control"
                                                                id={"StateName" + index}
                                                                name="StateName"
                                                                placeholder="Enter State Name"
                                                                maxLength="50"
                                                                autoComplete="off"
                                                                value={inputfeild.warehousename}
                                                                onChange={event => handleInputChange(index, event, "Warehouse")}
                                                                onBlur={() => CheckDuplicate(index, 'Warehouse')}
                                                                style={{ border: submitted && inputfeild.warehousename === "" ? '1px solid red' : '' }}
                                                                autoFocus
                                                            />
                                                        </td>

                                                        <td style={{ width: "13%" }}>
                                                            {
                                                                warehouseList.filter(x => x.Isdeleted !== 1).length !== 1 && (
                                                                    <span title='Delete Warehosue' onClick={() => handleRemoveFields(index, inputfeild)}
                                                                        className="btn btn-danger btn-xs delete">
                                                                        <i className="fa fa-trash-o"></i>
                                                                    </span>
                                                                )

                                                            }
                                                            &nbsp;
                                                            {
                                                                warehouseList.filter(x => x.Isdeleted !== 1).length === (index + 1) && (
                                                                    <span title='Add Warehouse'
                                                                        onClick={() => handleAddFields(index)}
                                                                        className="btn btn-info btn-xs plus">
                                                                        <i className="fa fa-plus"></i>
                                                                    </span>
                                                                )
                                                            }
                                                            {/* {workmanmatchList.length} */}
                                                        </td>
                                                        {/* <span> {index}</span> */}
                                                    </tr>

                                                ))

                                                }
                                            </tbody>
                                        </table>
                                    }
                                    <div className="col-sm-12">
                                        <div className="buttons-preview" align="right">
                                            <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                                <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                                            </span>
                                            <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                                <i className="fa fa-times"></i>&nbsp;Reset
                                            </span>
                                            <button type="button" className="btn btn-success" disabled={buttonLoader} onClick={SaveWarehouse}>
                                                <i className="fa fa-check right"></i>&nbsp;Update
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Editwarehouse