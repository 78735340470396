import UploadImage from "../../Common/EmployeeUploadImage"
import React, { useState, useEffect, useRef } from "react";
import ReactNotification from 'react-notifications-component';
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import BuyerService from "../../../services/Master/BuyerService";
import companyService from "../../../services/Master/Companyservice";
import 'react-notifications-component/dist/theme.css';
import Address from "../CommonComponent/Address";
import Employee from "../CommonComponent/Employee";
import Holiday from "../CommonComponent/HoliDay";
import TrimSupplier from "../CommonComponent/TrimSupplier";
import Bank from "../CommonComponent/Bank";
import validator from 'validator'
import Nodify from "../../Common/ReactNotification"
import { emailExp } from "../../Common/RegExpression";
import { BuyerModuleId } from "../../Common/ModuleID";
import { useSelector } from "react-redux";
import SearchRetain from "../../Common/SearchRetain";
import CommonService from '../../../services/Common/CommonService';
import CityService from "../../../services/Master/CityService";
import ConfirmationPopup from "../../Common/ConfirmationPopup";


const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)
const BuyerRegister = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const [getPage, setPage] = useState([]);
    const [refershstate, setrefershstate] = useState(0)
    const [getID, setID] = useState({ showPopupConfirm: false, Params: {} });
    // //Enable Menu
    let activeMenu;
    let activeMenuSub;
    let AddressInfo;
    let EmployeeInfo;
    let TrimSupplierInfo;
    let HolidayInfo;
    let BankInfo;
    let AddressInfoQuick;
    let EmployeeInfoQuick = [];
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
    if (menulist !== undefined && menulistSub.length > 0) {
        activeMenu = menulist.filter(i => i.menuName === "BuyerList");
        if (activeMenu.length > 0) {
            activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);
            if (activeMenuSub.length > 0) {
                for (let index = 0; index < activeMenuSub.length; index++) {
                    if (activeMenuSub[index].menuName === "BuyerAddress") {

                        AddressInfo = activeMenuSub[index];
                        AddressInfoQuick = menulist.filter(i => i.menuName === 'Branch');
                    }
                    else if (activeMenuSub[index].menuName === "BuyerEmployee") {
                        EmployeeInfo = activeMenuSub[index];
                        EmployeeInfoQuick.Role = menulist.filter(i => i.menuName === 'Role')
                        EmployeeInfoQuick.Department = menulist.filter(i => i.menuName === 'Department')
                    }
                    else if (activeMenuSub[index].menuName === "BuyerHoliday") {
                        HolidayInfo = activeMenuSub[index];

                    }
                    else if (activeMenuSub[index].menuName === "BuyerTrimSupplier") {
                        TrimSupplierInfo = activeMenuSub[index];

                    }
                    else if (activeMenuSub[index].menuName === "BuyerBank") {
                        BankInfo = activeMenuSub[index];

                    }

                }
            }
        }
    }


    // // End Enable Menu

    const [getBuyerList, setBuyerList] = useState({});
    const [getBuyerAddressList, setBuyerAddressList] = useState({});
    const [AddressList, setAddressList] = useState([]);
    const [getBuyerEmployeeList, setBuyerEmployeeList] = useState({});
    const [EmployeeList, setEmployeeList] = useState([]);
    const [getBuyerHolidayList, setBuyerHolidayList] = useState({});
    const [HolidayList, setHolidayList] = useState([]);
    const [stateList, setstateList] = useState([]);
    const [countryList, setcountryList] = useState([]);
    const [ButtonName, setButtonName] = useState("Save");
    const [submitted, setSubmitted] = useState({ Buyer: false, BuyerReference: false });
    const [checked, setChecked] = useState({ BuyerStatus: true, CompanyAdmin: true, AlreadyMapped: false });
    const [getHeaderName, addHeaderName] = useState("Add");
    const [getstate_BuyerID, setstate_BuyerID] = useState(0);
    const [Buttonvisible, SetButtonvisible] = useState("Show");
    const [cityList, setcityList] = useState([]);
    const [BranchList, setBranchList] = useState([]);
    const [genderList, setgenderList] = useState([]);
    const [getBuyerTrimSupplierList, setBuyerTrimSupplierList] = useState({});
    const [SupplierList, setSupplierList] = useState([]);
    const [getBuyerBankList, setBuyerBankList] = useState({});
    const [BankList, setBankList] = useState([]);
    const [buttonLoader, setButtonLoader] = useState(false);
    //const [getID, setID] = useState(0);

    const [IsParentCallback, setIsParentCallback] = useState(false);
    const [IsEmpParentCallback, setIsEmpParentCallback] = useState(false);
    const [IsHolidayParentCallback, setIsHolidayParentCallback] = useState(false);
    const [IsTrimSupplierParentCallback, setIsEmpTrimSupplierParentCallback] = useState(false);
    const [IsBankParentCallback, setIsEmpBankParentCallback] = useState(false);
    const inputRef = useRef();


    function SetBuyer(props) {
        let BuyerValue = {
            Operation: 1,
            BuyerID: 0,
            Name: '',
            ReferenceName: '',
            LogoName: '',
            LogoPath: '',
            Status: 1,
            Tabletype: BuyerModuleId,
            Createdby: currentUser.employeeinformationID,
            IsActiveBuyerMapped: 0
        };
       
        setPage('BuyerRegister');
        if (CombuysubId !== null) {
            setstate_BuyerID(CombuysubId);
            setButtonName("Update");
            BuyerValue.Operation = 2;
            BuyerValue.BuyerID = CombuysubId;
            BuyerValue.Name = validator.trim(props[0].name);
            BuyerValue.ReferenceName = validator.trim(props[0].referenceName);
            BuyerValue.LogoName = props[0].logoName;
            BuyerValue.LogoPath = props[0].logoPath;
            BuyerValue.Status = props[0].activeStatus;
            BuyerValue.Tabletype = props[0].tabletype;
            BuyerValue.IsActiveBuyerMapped = props[0].isActiveBuyerMapped;

            SetButtonvisible(props[0].action);
            if (BuyerValue.Status === 1) {
                let Params = {
                    Operation: 3,
                    Id: BuyerValue.BuyerID,
                };
                CommonService.CheckMappedItem(Params).then(res => {
                    if (res.data.outputResult === "-2") {
                        setChecked({ BuyerStatus: true, AlreadyMapped: true });
                    }
                    else {
                        setChecked({ BuyerStatus: true, AlreadyMapped: false });
                    }
                });

            } else {
                setChecked({
                    BuyerStatus: !checked
                });
            }
            if (CombuysubId) {
                addHeaderName("ADD");
            } else {
                addHeaderName("Edit");
            }
            return BuyerValue;

        }
        else if (props.location.state !== undefined && (props.location.state.params.action === 'Edit' || props.location.state.params.action === 'View')) {
            if (props.location.state.params.length !== 0) {
                if (props.location.state.params.companyBuyerSupID !== "") {
                    setstate_BuyerID(props.location.state.params.companyBuyerSupID);
                    setButtonName("Update");
                    BuyerValue.Operation = 2;
                    BuyerValue.BuyerID = props.location.state.params.companyBuyerSupID;
                    BuyerValue.Name = validator.trim(props.location.state.params.name);
                    BuyerValue.ReferenceName = validator.trim(props.location.state.params.referenceName);
                    BuyerValue.LogoName = props.location.state.params.logoName;
                    BuyerValue.LogoPath = props.location.state.params.logoPath;
                    BuyerValue.Status = props.location.state.params.activeStatus;
                    BuyerValue.Tabletype = props.location.state.params.tabletype;
                    BuyerValue.IsActiveBuyerMapped = props.location.state.params.isActiveBuyerMapped;

                    SetButtonvisible(props.location.state.params.action);
                    if (BuyerValue.Status === 1) {
                        let Params = {
                            Operation: 3,
                            Id: BuyerValue.BuyerID,
                        };
                        CommonService.CheckMappedItem(Params).then(res => {
                            if (res.data.outputResult === "-2") {
                                setChecked({ BuyerStatus: true, AlreadyMapped: true });
                            }
                            else {
                                setChecked({ BuyerStatus: true, AlreadyMapped: false });
                            }
                        });

                    } else {
                        setChecked({
                            BuyerStatus: !checked
                        });
                    }
                    if (props.location.state.params.action !== 'Edit') {
                        addHeaderName("View");
                    } else {
                        addHeaderName("Edit");
                    }
                    return BuyerValue;
                }
            }
        } else {
            return BuyerValue;
        }
    }



    // useEffect(() => {
    //     const listener = event => {
    //         if (event.code === "Enter" || event.code === "NumpadEnter") {
    //             event.preventDefault();
    //             //go to save function
    //         }
    //     };
    //     document.addEventListener("keydown", listener);
    //     return () => {
    //         document.removeEventListener("keydown", listener);
    //     };
    // }, []);
    const CombuysubId = JSON.parse(localStorage.getItem("CompanySubbuyID"));
    useEffect(() => {
       
        // Buyer Information
        if (props.location.state.params.action === 'Edit') {
            let getBuyer = SetBuyer(props);
            setBuyerList(getBuyer)
        } else {
            if (CombuysubId !== null) {
                companyService.getAllCompanyBuyerSupList(CombuysubId, 3).then(async (res) => {
                    if (res.data) {
                        let getBuyer = await SetBuyer(res.data);
                        setBuyerList(getBuyer)
                    }
                })
            } else {
                let getBuyer = SetBuyer(props);
                setBuyerList(getBuyer)

            }
        }

        companyService.loadCityList().then((response) => {
            if (response.data) {
                setcityList(response.data);
            }
        }).catch(() => { });

        companyService.loadAddressTypeList().then((response) => {
            if (response.data) {
                setBranchList(response.data);
            }
        }).catch(() => { });

        companyService.loadGenderList().then((response) => {
            if (response.data) {
                setgenderList(response.data);
            }
        }).catch(() => { });
        CityService.loadStateList().then((response) => {
            if (response.data) {
                setstateList(response.data);
            }
        }).catch(() => { });

        CityService.loadCountryList().then((response) => {
            if (response.data) {
                setcountryList(response.data);
            }
        }).catch(() => { });
        // AddressList
        if (props.location.state && props.location.state.params.companyBuyerSupID !== undefined && (props.location.state.params.action === 'Edit' || props.location.state.params.action === 'View')) {
            companyService.BindAddressList(props.location.state.params.companyBuyerSupID).then((response) => {
                if (response.data) {
                    setAddressList(response.data);
                }
            }).catch(() => { });

            companyService.BindEmployeeList(props.location.state.params.companyBuyerSupID).then((response) => {
                if (response.data) {
                    setEmployeeList(response.data);
                }
            }).catch(() => { });

            companyService.BindHolidayList(props.location.state.params.companyBuyerSupID, new Date().getFullYear()).then((response) => {
                if (response.data) {
                    setHolidayList(response.data);
                }
            }).catch(() => { });

            BuyerService.BindSupplierList(props.location.state.params.companyBuyerSupID).then((response) => {
                if (response.data) {
                    setSupplierList(response.data);
                }
            }).catch(() => { });

            BuyerService.BindBankList(props.location.state.params.companyBuyerSupID).then((response) => {
                if (response.data) {
                    setBankList(response.data);
                }
            }).catch(() => { });

        } else if (props.location.state.params.action === 'Add' && CombuysubId !== null) {
            companyService.BindAddressList(CombuysubId).then((response) => {
                if (response.data) {
                    setAddressList(response.data);
                }
            }).catch(() => { });

            companyService.BindEmployeeList(CombuysubId).then((response) => {
                if (response.data) {
                    setEmployeeList(response.data);
                }
            }).catch(() => { });

            companyService.BindHolidayList(CombuysubId, new Date().getFullYear()).then((response) => {
                if (response.data) {
                    setHolidayList(response.data);
                }
            }).catch(() => { });

            BuyerService.BindSupplierList(CombuysubId).then((response) => {
                if (response.data) {
                    setSupplierList(response.data);
                }
            }).catch(() => { });

            BuyerService.BindBankList(CombuysubId).then((response) => {
                if (response.data) {
                    setBankList(response.data);
                }
            }).catch(() => { });
        }

    }, [refershstate]);

    const AddBuyerback = (value) => {
        getBuyerList.LogoName = value;
    }

    const Addcallback = async (value) => {
        setBuyerAddressList(value);
        await companyService.loadCityList().then((response) => {
            if (response.data) {
                setcityList(response.data);
            }
        }).catch(() => { });
        //setIsParentCallback(false);
        //setIsEmpParentCallback(false);
    }

    const Empcallback = (value) => {
        setBuyerEmployeeList(value);
        //setIsEmpParentCallback(false);
    }

    const Holidaycallback = (value) => {
        setBuyerHolidayList(value);
        //setIsHolidayParentCallback(false);
    }

    const SupplierCallback = (value) => {
        setBuyerTrimSupplierList(value);
        //setIsEmpTrimSupplierParentCallback(false);
    }

    const BankCallback = (value) => {
        setBuyerBankList(value);
        //setIsEmpBankParentCallback(false);
    }

    const [supplierInputFields, setSupplierInputFields] = useState([{
        TrimSupplierID: 0,
        SupplierName: '',
        SupplierItems: '',
        SupplierAddress1: '',
        SupplierCity: 0,
        SupplierState: 0,
        SupplierCountry: 0,
        SupplierPostalCode: '',
        SupplierPrimaryEmail: '',
        SupplierSecondaryEmail: '',
        SupplierPrimaryContactNo: '',
        SupplierSecondaryContactNo: ''
    }]);

    const [bankInputFields, setBankInputFields] = useState([{
        BankID: 0,
        BankName: '',
        BranchName: '',
        BranchCode: '',
        BankAccountNumber: '',
        BankAccountType: 0,
        BankAddress: '',
        BankCity: 0,
        BankState: 0,
        BankCountry: 0,
        BankPrimaryEmail: '',
        BankSecondaryEmail: '',
        BankPrimaryContactNo: '',
        BankSecondaryContactNo: '',
    }]);

    const viewtoEdit = (e) => {
        SetButtonvisible("Edit");
        addHeaderName("EDIT");
    }

    const handleChange = (e) => {
        let BuyerValue = getBuyerList;
        let inputText = '';
        if (e.target.value.trim() !== '') {
            inputText = e.target.value;
            setSubmitted(true);
        }
        if (e.target.name === "StateID" || e.target.name === "CountryID") {
            BuyerValue[e.target.name] = parseInt(e.target.value)
        }
        else {
            if (e.target.type === 'checkbox') {
                if (!e.target.checked) {
                    BuyerValue[e.target.name] = 0;
                    if (checked.AlreadyMapped === true) {
                        ValidationPopup("Buyer Name is Mapped.");
                        BuyerValue[e.target.name] = 1;
                    }
                } else {
                    BuyerValue[e.target.name] = 1;
                }
                setChecked({ ...checked, BuyerStatus: BuyerValue[e.target.name] });
            }
            else {
                if (e.target.name === "Name") {
                    if (submitted.BuyerReference === true) {
                        setSubmitted({ Buyer: false, BuyerReference: true });
                    }
                    else {
                        setSubmitted({ Buyer: false, BuyerReference: false });
                    }
                }
                else if (e.target.name === "ReferenceName") {
                    if (submitted.Buyer === true) {
                        setSubmitted({ Buyer: true, BuyerReference: false });
                    }
                    else {
                        setSubmitted({ Buyer: false, BuyerReference: false });
                    }
                }
                BuyerValue[e.target.name] = inputText
            }
            setBuyerList(BuyerValue);
        }
    }

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/Buyer",
            state: { message: Func }
        });
    }

    const Delcallback = (value) => {
        if (value === true) {
            setID({ showPopupConfirm: false, Params: [] });
            //setPage("deleteClose");
        } else {
            let x = Math.floor((Math.random() * 100) + 1);
            setrefershstate(x);
            setID({ showPopupConfirm: false, Params: [] });
        }
    }

    const [branchInputFields, setBranchInputFields] = useState([{
        AddressInformationID: 0,
        AddressType: 0,
        Country: 0,
        CompanyState: 0,
        City: 0,
        PrimaryContactNumber: '',
        SecondaryContactNumber: '',
        PrimaryEmailAddress: '',
        SecondaryEmailAddress: '',
        PostalCode: '',
        Website: '',
        AddressLine1: '',
        AddressLine2: '',
        Latitude: '',
        Longitude: '',
        IsDeleted: 0
    }]);

    const [HolidayInputFields, setHolidayInputFields] = useState([{
        HolidayInformationID: 0,
        HolidayName: '',
        HolidayDate: '',
        HolidayEndDate: '',
        IsDeleted: 0
        // HolidayDate: new Date()
    }]);

    const [EmpInputFields, setEmpInputFields] = useState([{
        EmployeeinformationID: 0,
        EmpFirstName: '',
        EmpLastName: '',
        EmpDateofBirth: '',
        EmpGender: 0,
        EmpDateofJoin: '',
        EmpDepartmentID: 0,
        EmpRoleID: 0,
        EmpUserName: '',
        EmpPassword: '',
        ConfirmPassword: '',
        EmpCompanyAdministrator: 0,
        EmpAddressLine1: '',
        EmpAddressLine2: '',
        EmpCity: 0,
        EmpState: 0,
        EmpCountry: 0,
        EmpPostalCode: '',
        EmpPrimaryContactNumber: '',
        EmpSecondaryContactNumber: '',
        EmpProfilePicName: '',
        EmpProfilePicPath: '',
        ActiveStatus: 1,
        IsDeleted: 0,
    }]);

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/Buyer')
            localStorage.removeItem("CompanySubbuyID");
        } else {
            // props.history.push('/BuyerRegister')
            window.location.reload();
        }
    }




    //buyerregister

    const SaveBuyer = (e) => {
        setButtonLoader(true);

        let IsValid = true;
        let ValidationMsg = "";
        setSubmitted({ Buyer: true, BuyerReference: true });
        e.preventDefault();
        if (getBuyerList.Name.trim() === '' && getBuyerList.ReferenceName.trim() === '') {
            setButtonLoader(false);
            ValidationPopup("Please fill all mandatory (*) fields.");
        }
        else if (getBuyerList.Name.trim() === '') {
            setButtonLoader(false);
            ValidationPopup("Buyer Name is required.");
        }
        else if (getBuyerList.ReferenceName.trim() === '') {
            setButtonLoader(false);
            ValidationPopup("Reference Name is required.");
        }

        if (getBuyerList.Name !== '' && getBuyerList.ReferenceName !== '') {
            getBuyerList.Name = getBuyerList.Name.trim();
            getBuyerList.ReferenceName = getBuyerList.ReferenceName.trim();
            if (getBuyerAddressList.length === undefined) {
                getBuyerList.CompanyInformation = branchInputFields;
            }
            else {

                for (var index = 0; index < getBuyerAddressList.length; index++) {
                    if (getBuyerAddressList[index].AddressType === 0
                        && getBuyerAddressList[index].City === 0
                        && getBuyerAddressList[index].PrimaryContactNumber === ""
                        && getBuyerAddressList[index].PostalCode === ""
                        && getBuyerAddressList[index].AddressLine1 === ""
                        && getBuyerAddressList[index].AddressLine2 === ""
                        && getBuyerAddressList[index].PrimaryEmailAddress === ""
                        && getBuyerAddressList[index].Website === "") {
                        // getBuyerAddressList[index].IsDeleted = 1;
                    }

                    else if (getBuyerAddressList[index].AddressType > 0 && getBuyerAddressList[index].City > 0 && getBuyerAddressList[index].AddressLine1 !== '' && getBuyerAddressList[index].PrimaryContactNumber !== "" && getBuyerAddressList[index].PostalCode !== "") {
                        getBuyerAddressList[index].AddressLine1 = getBuyerAddressList[index].AddressLine1.trim();
                        getBuyerAddressList[index].AddressLine2 = getBuyerAddressList[index].AddressLine2.trim();
                        getBuyerAddressList[index].PrimaryEmailAddress = getBuyerAddressList[index].PrimaryEmailAddress.trim();
                        getBuyerAddressList[index].Website = getBuyerAddressList[index].Website.trim();


                        if (getBuyerAddressList[index].PrimaryEmailAddress !== "") {
                            if (emailExp.test(getBuyerAddressList[index].PrimaryEmailAddress) === false) {
                                IsValid = false;
                                if (getBuyerAddressList[index].AddressTypeName !== undefined) {
                                    ValidationMsg = "Email address is not valid in Address tab (" + getBuyerAddressList[index].AddressTypeName + ")";
                                }
                                else {
                                    ValidationMsg = "Email address is not valid in Address tab";
                                }
                                setButtonLoader(false);
                                ValidationPopup(ValidationMsg);
                                setIsParentCallback(true);
                                getBuyerAddressList[index].PrimaryEmailAddress = '';
                                return false;
                            }
                        }
                        // if (getBuyerAddressList[index].PrimaryContactNumber.length !== 20) {
                        //     IsValid = false;
                        //     if (getBuyerAddressList[index].AddressTypeName !== undefined) {
                        //         ValidationMsg = "Contact number is not valid in Address tab (" + getBuyerAddressList[index].AddressTypeName + ")";
                        //     }
                        //     else {
                        //         ValidationMsg = "Contact number is not valid in Address tab";
                        //     }
                        //     setButtonLoader(false);
                        //     ValidationPopup(ValidationMsg);
                        //     setIsParentCallback(true);
                        //     return false;

                        // }
                        // if (getBuyerAddressList[index].PostalCode.length !== 20) {
                        //     IsValid = false;
                        //     if (getBuyerAddressList[index].AddressTypeName !== undefined) {
                        //         ValidationMsg = "Postal code is not valid in Address tab (" + getBuyerAddressList[index].AddressTypeName + ")";
                        //     }
                        //     else {
                        //         ValidationMsg = "Postal code is not valid in Address tab";
                        //     }
                        //     setButtonLoader(false);
                        //     ValidationPopup(ValidationMsg);
                        //     setIsParentCallback(true);
                        //     return false;
                        // }
                    }
                    else {
                        IsValid = false;
                        if (getBuyerAddressList[index].AddressTypeName !== undefined) {
                            ValidationMsg = "Please fill all mandatory (*) fields in Address tab (" + getBuyerAddressList[index].AddressTypeName + ")";
                        }
                        else {
                            ValidationMsg = "Please fill all mandatory (*) fields in Address tab";
                        }
                        setButtonLoader(false);
                        ValidationPopup(ValidationMsg);
                        setIsParentCallback(true);
                        return false;
                    }
                }
                getBuyerList.CompanyInformation = getBuyerAddressList;
            }

            if (getBuyerEmployeeList.length === undefined) {
                getBuyerList.EmployeeInformation = EmpInputFields;
            }
            else {
                for (var index = 0; index < getBuyerEmployeeList.length; index++) {
                    if (getBuyerEmployeeList[index].EmpFirstName === ''
                        && getBuyerEmployeeList[index].EmpLastName === ''
                        && getBuyerEmployeeList[index].EmpGender === 0
                        && (getBuyerEmployeeList[index].EmpDateofBirth === '' || getBuyerEmployeeList[index].EmpDateofBirth === null)
                        && (getBuyerEmployeeList[index].EmpDateofJoin === '' || getBuyerEmployeeList[index].EmpDateofJoin === null)
                        && getBuyerEmployeeList[index].EmpDepartmentID === 0
                        && getBuyerEmployeeList[index].EmpRoleID === 0
                        && getBuyerEmployeeList[index].EmpAddressLine1 === ''
                        && getBuyerEmployeeList[index].EmpAddressLine2 === ''
                        && getBuyerEmployeeList[index].EmpCity === 0
                        && getBuyerEmployeeList[index].EmpCompanyAdministrator === 0
                        && getBuyerEmployeeList[index].ActiveStatus === 1
                        && getBuyerEmployeeList[index].EmpPostalCode === ''
                        && getBuyerEmployeeList[index].EmpPrimaryContactNumber === ''
                        && getBuyerEmployeeList[index].EmpUserName === ''
                        && getBuyerEmployeeList[index].EmpPassword === ''
                        && getBuyerEmployeeList[index].ConfirmPassword === '') {
                        //  getBuyerEmployeeList[index].IsDeleted = 1;
                    }
                    else if (getBuyerEmployeeList[index].EmpFirstName && getBuyerEmployeeList[index].EmpLastName && getBuyerEmployeeList[index].EmpGender && getBuyerEmployeeList[index].EmpRoleID && getBuyerEmployeeList[index].EmpAddressLine1 && getBuyerEmployeeList[index].EmpCity && getBuyerEmployeeList[index].EmpPostalCode && getBuyerEmployeeList[index].EmpPrimaryContactNumber && getBuyerEmployeeList[index].EmpUserName && getBuyerEmployeeList[index].EmpPassword && getBuyerEmployeeList[index].ConfirmPassword) {
                        getBuyerEmployeeList[index].EmpFirstName = getBuyerEmployeeList[index].EmpFirstName.trim();
                        getBuyerEmployeeList[index].EmpLastName = getBuyerEmployeeList[index].EmpLastName.trim();
                        getBuyerEmployeeList[index].EmpAddressLine1 = getBuyerEmployeeList[index].EmpAddressLine1 && getBuyerEmployeeList[index].EmpAddressLine1.trim();
                        getBuyerEmployeeList[index].EmpAddressLine2 = getBuyerEmployeeList[index].EmpAddressLine2 && getBuyerEmployeeList[index].EmpAddressLine2.trim();
                        getBuyerEmployeeList[index].EmpUserName = getBuyerEmployeeList[index].EmpUserName && getBuyerEmployeeList[index].EmpUserName.trim();
                        getBuyerEmployeeList[index].EmpPassword = getBuyerEmployeeList[index].EmpPassword && getBuyerEmployeeList[index].EmpPassword.trim();
                        getBuyerEmployeeList[index].ConfirmPassword = getBuyerEmployeeList[index].ConfirmPassword && getBuyerEmployeeList[index].ConfirmPassword.trim();

                        if (getBuyerEmployeeList[index].EmpPassword && getBuyerEmployeeList[index].EmpPassword.length < 8) {
                            IsValid = false;
                            ValidationMsg = "Password should be above 7 characters for (" + getBuyerEmployeeList[index].EmpFirstName + " " + getBuyerEmployeeList[index].EmpLastName + ")";
                            setButtonLoader(false);
                            ValidationPopup(ValidationMsg);
                            setIsEmpParentCallback(true);
                            return false;
                        }
                        if (getBuyerEmployeeList[index].EmpPassword !== getBuyerEmployeeList[index].ConfirmPassword) {
                            IsValid = false;
                            ValidationMsg = "Password and Confirm password is must be same for (" + getBuyerEmployeeList[index].EmpFirstName + " " + getBuyerEmployeeList[index].EmpLastName + ")";
                            setButtonLoader(false);
                            ValidationPopup(ValidationMsg);
                            setIsEmpParentCallback(true);
                            return false;
                        }
                        if (emailExp.test(getBuyerEmployeeList[index].EmpUserName) === false) {
                            IsValid = false;
                            ValidationMsg = "Email Address is not valid in Employee Tab (" + getBuyerEmployeeList[index].EmpFirstName + " " + getBuyerEmployeeList[index].EmpLastName + ")";
                            setButtonLoader(false);
                            ValidationPopup(ValidationMsg);
                            setIsEmpParentCallback(true);
                            getBuyerEmployeeList[index].EmpUserName = '';
                            return false;
                        }
                        // if (getBuyerEmployeeList[index].EmpPrimaryContactNumber.length !== 20) {
                        //     IsValid = false;
                        //     ValidationMsg = "Contact number is not valid in Employee Tab (" + getBuyerEmployeeList[index].EmpFirstName + " " + getBuyerEmployeeList[index].EmpLastName + ")";
                        //     setButtonLoader(false);
                        //     ValidationPopup(ValidationMsg);
                        //     setIsEmpParentCallback(true);
                        //     return false;
                        // }
                        // if (getBuyerEmployeeList[index].EmpPostalCode.length !== 20) {
                        //     IsValid = false;
                        //     ValidationMsg = "Postal code is not valid in Employee Tab (" + getBuyerEmployeeList[index].EmpFirstName + " " + getBuyerEmployeeList[index].EmpLastName + ")";
                        //     setButtonLoader(false);
                        //     ValidationPopup(ValidationMsg);
                        //     setIsEmpParentCallback(true);
                        //     return false;
                        // }
                    }
                    else {
                        IsValid = false;
                        ValidationMsg = "Please fill all mandatory (*) fields in Employee tab (" + getBuyerEmployeeList[index].EmpFirstName + " " + getBuyerEmployeeList[index].EmpLastName + ")";
                        setButtonLoader(false);
                        ValidationPopup(ValidationMsg);
                        setIsEmpParentCallback(true);
                        return false;
                    }
                    if (getBuyerEmployeeList[index] !== undefined) {
                        if (getBuyerEmployeeList[index].EmpDateofBirth !== "" && getBuyerEmployeeList[index].EmpDateofBirth !== null && getBuyerEmployeeList[index].EmpDateofBirth !== undefined) {
                            getBuyerEmployeeList[index].EmpDateofBirth.setDate(getBuyerEmployeeList[index].EmpDateofBirth.getDate() + 1);
                        }
                        if (getBuyerEmployeeList[index].EmpDateofJoin !== "" && getBuyerEmployeeList[index].EmpDateofJoin !== null && getBuyerEmployeeList[index].EmpDateofJoin !== undefined) {
                            getBuyerEmployeeList[index].EmpDateofJoin.setDate(getBuyerEmployeeList[index].EmpDateofJoin.getDate() + 1);
                        }
                    }
                }
                getBuyerList.EmployeeInformation = getBuyerEmployeeList;
            }

            if (getBuyerTrimSupplierList.length === undefined) {
                getBuyerList.TrimSupplierInformation = supplierInputFields;
            }
            else {
                for (var index = 0; index < getBuyerTrimSupplierList.length; index++) {
                    if (getBuyerTrimSupplierList[index].SupplierName === ''
                        && getBuyerTrimSupplierList[index].SupplierItems === ''
                        && getBuyerTrimSupplierList[index].SupplierAddress1 === ''
                        && getBuyerTrimSupplierList[index].SupplierAddress2 === ''
                        && getBuyerTrimSupplierList[index].SupplierCity === 0
                        && getBuyerTrimSupplierList[index].SupplierPrimaryContactNo === ''
                        && getBuyerTrimSupplierList[index].SupplierPostalCode === ''
                        && getBuyerTrimSupplierList[index].SupplierPrimaryEmail === '') {
                        //   getBuyerTrimSupplierList[index].IsDeleted = 1;
                    }
                    else if (getBuyerTrimSupplierList[index].SupplierName && getBuyerTrimSupplierList[index].SupplierItems && getBuyerTrimSupplierList[index].SupplierAddress1 && getBuyerTrimSupplierList[index].SupplierCity && getBuyerTrimSupplierList[index].SupplierPrimaryContactNo && getBuyerTrimSupplierList[index].SupplierPostalCode) {
                        getBuyerTrimSupplierList[index].SupplierName = getBuyerTrimSupplierList[index].SupplierName.trim();
                        getBuyerTrimSupplierList[index].SupplierItems = getBuyerTrimSupplierList[index].SupplierItems.trim();
                        getBuyerTrimSupplierList[index].SupplierAddress1 = getBuyerTrimSupplierList[index].SupplierAddress1.trim();
                        getBuyerTrimSupplierList[index].SupplierAddress2 = getBuyerTrimSupplierList[index].SupplierAddress2.trim();
                        getBuyerTrimSupplierList[index].SupplierPrimaryEmail = getBuyerTrimSupplierList[index].SupplierPrimaryEmail.trim();
                        if (getBuyerTrimSupplierList[index].SupplierPrimaryEmail !== "") {
                            if (emailExp.test(getBuyerTrimSupplierList[index].SupplierPrimaryEmail) === false) {
                                IsValid = false;
                                ValidationMsg = "Email Address is not valid in Trim Supplier Tab (" + getBuyerTrimSupplierList[index].SupplierName + ")";
                                setButtonLoader(false);
                                ValidationPopup(ValidationMsg);
                                setIsEmpTrimSupplierParentCallback(true);
                                return false;
                            }
                        }

                        // if (getBuyerTrimSupplierList[index].SupplierPrimaryContactNo.length !== 20) {
                        //     IsValid = false;
                        //     ValidationMsg = "Contact number is not valid in Trim Supplier Tab (" + getBuyerTrimSupplierList[index].SupplierName + ")";
                        //     setButtonLoader(false);
                        //     ValidationPopup(ValidationMsg);
                        //     setIsEmpTrimSupplierParentCallback(true);
                        //     return false;
                        // }
                        // if (getBuyerTrimSupplierList[index].SupplierPostalCode.length !== 20) {
                        //     IsValid = false;
                        //     ValidationMsg = "Postal code is not valid in Trim Supplier Tab (" + getBuyerTrimSupplierList[index].SupplierName + ")";
                        //     setButtonLoader(false);
                        //     ValidationPopup(ValidationMsg);
                        //     setIsEmpTrimSupplierParentCallback(true);
                        //     return false;
                        // }
                    }
                    else {
                        IsValid = false;
                        ValidationMsg = "Please fill all mandatory (*) fields in Trim Supplier tab (" + getBuyerTrimSupplierList[index].SupplierName + ")";
                        setButtonLoader(false);
                        ValidationPopup(ValidationMsg);
                        setIsEmpTrimSupplierParentCallback(true);
                        return false;
                    }
                }
                getBuyerList.TrimSupplierInformation = getBuyerTrimSupplierList;
            }

            if (getBuyerBankList.length === undefined) {
                getBuyerList.BankInformation = bankInputFields;
            }
            else {
                for (var index = 0; index < getBuyerBankList.length; index++) {
                    if (getBuyerBankList[index].BankName === ''
                        && getBuyerBankList[index].BranchName === ''
                        && getBuyerBankList[index].BankAccountNumber === ''
                        && getBuyerBankList[index].BranchCode === ''
                        && getBuyerBankList[index].BankAccountType === 0
                        && getBuyerBankList[index].BankAddress === ''
                        && getBuyerBankList[index].BankAddress2 === ''
                        && getBuyerBankList[index].BankCity === 0
                        && getBuyerBankList[index].BankPrimaryContactNo === ''
                        && getBuyerBankList[index].BankPrimaryEmail === '') {
                        //   getBuyerBankList[index].IsDeleted = 1;
                    }
                    else if (getBuyerBankList[index].BankName && getBuyerBankList[index].BranchName && getBuyerBankList[index].BankAccountNumber && getBuyerBankList[index].BranchCode && getBuyerBankList[index].BankAccountType && getBuyerBankList[index].BankAddress && getBuyerBankList[index].BankCity && getBuyerBankList[index].BankPrimaryContactNo) {
                        getBuyerBankList[index].BankName = getBuyerBankList[index].BankName.trim();
                        getBuyerBankList[index].BranchName = getBuyerBankList[index].BranchName.trim();
                        getBuyerBankList[index].BranchCode = getBuyerBankList[index].BranchCode.trim();
                        getBuyerBankList[index].BankAddress = getBuyerBankList[index].BankAddress.trim();
                        getBuyerBankList[index].BankAddress2 = getBuyerBankList[index].BankAddress2.trim();
                        getBuyerBankList[index].BankPrimaryEmail = getBuyerBankList[index].BankPrimaryEmail.trim();

                        for (var k = 0; k < getBuyerBankList.length; k++) {
                            if (getBuyerBankList[k].BankAccountNumber === getBuyerBankList[index].BankAccountNumber && index !== k) {
                                IsValid = false;
                                ValidationMsg = "Bank Account Number is not valid in Bank Tab (" + getBuyerBankList[index].BankName + ")";
                                setButtonLoader(false);
                                ValidationPopup(ValidationMsg);
                                setIsEmpBankParentCallback(true);
                                return false;
                            }
                        }

                        if (getBuyerBankList[index].BankPrimaryEmail !== "") {
                            if (emailExp.test(getBuyerBankList[index].BankPrimaryEmail) === false) {
                                IsValid = false;
                                ValidationMsg = "Email Address is not valid in Bank Tab (" + getBuyerBankList[index].BankName + ")";
                                setButtonLoader(false);
                                ValidationPopup(ValidationMsg);
                                setIsEmpBankParentCallback(true);
                                return false;
                            }
                        }
                        // if (getBuyerBankList[index].BankPrimaryContactNo.length !== 20) {
                        //     IsValid = false;
                        //     ValidationMsg = "Contact number is not valid in Bank Tab (" + getBuyerBankList[index].BankName + ")";
                        //     setButtonLoader(false);
                        //     ValidationPopup(ValidationMsg);
                        //     setIsEmpBankParentCallback(true);
                        //     return false;
                        // }
                    }
                    else {
                        IsValid = false;
                        ValidationMsg = "Please fill all mandatory (*) fields in Bank tab (" + getBuyerBankList[index].BankName + ")";
                        setButtonLoader(false);
                        ValidationPopup(ValidationMsg);
                        setIsEmpBankParentCallback(true);
                        return false;
                    }
                }
                getBuyerList.BankInformation = getBuyerBankList;
            }

            if (getBuyerHolidayList.length === undefined) {
                getBuyerList.HolidayInformation = HolidayInputFields;
            }
            else {
                for (var index = 0; index < getBuyerHolidayList.length; index++) {
                    var IsEmpty = getBuyerHolidayList.filter((x => ((x.HolidayName === '' || x.HolidayDate === null) && x.IsDeleted === 0)));
                    if (IsEmpty.length !== 0) {
                        if (IsEmpty[0].HolidayName === '' || IsEmpty[0].HolidayDate === null) { }
                        else {
                            IsValid = false;
                            ValidationMsg = "Please fill all mandatory (*) fields in Holiday tab (" + getBuyerHolidayList[index].HolidayName + ")";
                            setButtonLoader(false);
                            ValidationPopup(ValidationMsg);
                            setIsHolidayParentCallback(true);
                            return false;
                        }
                    }
                    if (getBuyerHolidayList[index].HolidayName === ''
                        && (getBuyerHolidayList[index].HolidayDate === '' || getBuyerHolidayList[index].HolidayDate === null)
                        && (getBuyerHolidayList[index].HolidayEndDate === '' || getBuyerHolidayList[index].HolidayEndDate === null)) {
                        //    getBuyerHolidayList[index].IsDeleted = 1;
                    }
                    else if (getBuyerHolidayList[index].HolidayName && getBuyerHolidayList[index].HolidayDate) {
                        getBuyerHolidayList[index].HolidayName = getBuyerHolidayList[index].HolidayName.trim();
                        getBuyerHolidayList[index].HolidayDate.setDate(getBuyerHolidayList[index].HolidayDate.getDate() + 1);
                        if (getBuyerHolidayList[index].HolidayEndDate !== "" && getBuyerHolidayList[index].HolidayEndDate !== null && getBuyerHolidayList[index].HolidayEndDate !== undefined) {
                            getBuyerHolidayList[index].HolidayEndDate.setDate(getBuyerHolidayList[index].HolidayEndDate.getDate() + 1);
                        }
                    }
                    else {
                        IsValid = false;
                        ValidationMsg = "Please fill all mandatory (*) fields in Holiday tab (" + getBuyerHolidayList[index].HolidayName + ")";
                        setButtonLoader(false);
                        ValidationPopup(ValidationMsg);
                        setIsHolidayParentCallback(true);
                        return false;
                    }
                }
                getBuyerList.HolidayInformation = getBuyerHolidayList;
            }
            if (IsValid) {

                getBuyerList.LogoPath = '';
                if (getBuyerList.LogoName !== '' && getBuyerList.LogoName !== undefined) {
                    let val = "/BuyerLogo/" + getBuyerList.LogoName;
                    getBuyerList.LogoPath = val;

                }
                BuyerService.InsertUpdateBuyer(getBuyerList).then(res => {
                    var page = "Remove";
                    SearchRetain(page);
                    let Func = 'Add';

                    res.data.tabPage = getPage;
                    if (res.data.outputResult === "1") {
                        Func = 'Add';
                        setID({ showPopupConfirm: true, Params: res.data });
                        //PageRedirect(Func);
                        localStorage.setItem('CompanySubbuyID', (res.data.outputResultStyleID));
                    } else if (res.data.outputResult === "2") {
                        Func = 'Edit';
                        setID({ showPopupConfirm: true, Params: res.data });
                        // PageRedirect(Func);
                    } else if (res.data.outputResult === "-2") {
                        //setButtonLoader(false);
                        ValidationPopup("Buyer name already exists!");
                    }
                    else if (res.data.outputResult === "0") {
                        //setButtonLoader(false);
                        ValidationPopup("Error Occured!");
                    }
                    res.data.Func = Func;
                    setButtonLoader(false);

                });
            }
        }
        else {
            scrollToRef(inputRef);
        }
    };


    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
        return false;
    }
    return (
        <div className="page-body">
            <ReactNotification />

            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">{getHeaderName} Buyer</h3>
                            <div className="buttons-preview" align="right">
                                <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                    <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                                </span>
                                <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                    <i className="fa fa-times"></i>&nbsp;Reset
                                </span>

                                {Buttonvisible !== "View" &&
                                    (<button type="submit" className="btn btn-success" onClick={SaveBuyer}
                                        disabled={buttonLoader}>
                                        <i className="fa fa-check right"></i> &nbsp;{ButtonName}
                                    </button>)
                                }
                                {Buttonvisible === "View" && activeMenu[0].isView !== undefined && activeMenu[0].isView === 1 ? '' :
                                    activeMenu[0].isView !== undefined && activeMenu[0].isView === 0 ? '' :
                                        (<button type="submit" style={{ display: activeMenu[0].isView !== undefined && activeMenu[0].isView === 0 ? '' : 'none' }} className="btn btn-info" onClick={viewtoEdit}>
                                            <i className="fa fa-edit"></i>&nbsp;Edit
                                        </button>)
                                }
                            </div>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <Form>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className='form-group'>
                                                <label htmlFor="Name">Buyer Name<span className="text-danger">*</span></label>
                                                <span className='input-icon icon-right'>
                                                    <Input placeholder="Enter Buyer Name "
                                                        id="Name"
                                                        name="Name" //className="form-control"
                                                        value={getBuyerList.Name}
                                                        type="text"
                                                        autoComplete="off"
                                                        onChange={handleChange}
                                                        maxLength="50"
                                                        autoFocus
                                                        ref={inputRef}
                                                        className={'form-control' + (
                                                            submitted.Buyer && !getBuyerList.Name ? ' is-invalid' : ''
                                                        )}
                                                        style={{ border: submitted.Buyer && !getBuyerList.Name ? '1px solid red' : '' }}
                                                    />
                                                </span>
                                                {/* {
                                                    submitted.Buyer && !getBuyerList.Name && <div className="alert-danger">Buyer Name is required</div>
                                                } */}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="ReferenceName">Reference Name <span className="text-danger">*</span> </label>
                                                <span className="input-icon icon-right">
                                                    <Input placeholder="Enter Reference Name"
                                                        id="ReferenceName" name="ReferenceName" // className="form-control"
                                                        value={getBuyerList.ReferenceName}
                                                        type="text"
                                                        maxLength="50"
                                                        autoComplete="off"
                                                        onChange={handleChange}
                                                        ref={inputRef}
                                                        className={'form-control' + (
                                                            submitted.BuyerReference && !getBuyerList.ReferenceName ? ' is-invalid' : ''
                                                        )}
                                                        style={{ border: submitted.BuyerReference && !getBuyerList.ReferenceName ? '1px solid red' : '' }}
                                                    />
                                                </span>
                                                {/* {
                                                    submitted.BuyerReference && !getBuyerList.ReferenceName && <div className="alert-danger">Buyer Reference Name is required</div>
                                                } */}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="Status">Status</label>
                                                <span className="input-icon icon-right">
                                                    <input className="checkbox-slider checkbox-slider_Status colored-blue" id="Status" name="Status"
                                                        value={getBuyerList.Status} checked={checked.BuyerStatus}
                                                        autoComplete="off" onChange={handleChange} type="checkbox">
                                                    </input>
                                                    <span className="text"></span>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-sm-5 pull-right">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail2">Buyer Logo</label>
                                                <span className="text-danger">(Click on the image to upload new)</span><br />
                                                <UploadImage UploadCallback={AddBuyerback} PageName='BuyerLogo' filePath={getBuyerList.LogoPath} Buttonview={Buttonvisible} />
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="tabbable">
                        <ul className="nav nav-tabs nav-justified" id="myTab5">

                            <li className='active'>
                                <a data-toggle="tab" href="#idBuyerAddress">
                                    <h5>Address</h5>
                                </a>
                            </li>


                            <li className='' >
                                <a data-toggle="tab" href="#idBuyerEmployee">
                                    <h5>Employee</h5>
                                </a>
                            </li>


                            <li className=''>
                                <a data-toggle="tab" href="#idBuyerHoliday">
                                    <h5>Holiday</h5>
                                </a>
                            </li>


                            <li className=''>
                                <a data-toggle="tab" href="#idBuyerTrimSupplier">
                                    <h5>Trim Supplier</h5>
                                </a>
                            </li>


                            <li className=''>
                                <a data-toggle="tab" href="#idBuyerBank">
                                    <h5>Bank</h5>
                                </a>
                            </li>

                        </ul>

                        <div className="tab-content">

                            <div id="idBuyerAddress" className='tab-pane in active' style={{ width: "100%" }}>
                                {AddressInfo && AddressInfo.isList === 1 ?
                                    <Address addressCallback={Addcallback}
                                        IsValidationCallback={IsParentCallback}
                                        citylist={cityList}
                                        branchlist={BranchList}
                                        AddressList={AddressList}
                                        Id={getstate_BuyerID}
                                        Buttonview={Buttonvisible}
                                        MenuPermission={AddressInfo}
                                        AddressInfoQuick={AddressInfoQuick}
                                        PageName={"Buyer"}
                                        stateList={stateList}
                                        countryList={countryList}
                                    />
                                    : <span><h3 className="red">User has access denied. Please contact the administrator! </h3></span>
                                }
                            </div>


                            <div id="idBuyerEmployee" className='tab-pane' style={{ width: "100%" }}>
                                {EmployeeInfo && EmployeeInfo.isList === 1 ?
                                    <Employee employeeCallback={Empcallback}
                                        IsValidationCallback={IsEmpParentCallback}
                                        citylist={cityList}
                                        genderlist={genderList}
                                        EmployeeList={EmployeeList}
                                        Id={getstate_BuyerID}
                                        Buttonview={Buttonvisible}
                                        PageName={"Buyer"}
                                        MenuPermission={EmployeeInfo}
                                        props={props}
                                        EmployeeInfoQuick={EmployeeInfoQuick}

                                    />
                                    : <span><h3 className="red">User has access denied. Please contact the administrator! </h3></span>
                                }
                            </div>

                            <div id="idBuyerHoliday" className='tab-pane' style={{ width: "100%" }}>
                                {HolidayInfo && HolidayInfo.isList === 1 ?
                                    <Holiday holidayCallback={Holidaycallback}
                                        IsValidationCallback={IsHolidayParentCallback}
                                        HolidayList={HolidayList}
                                        Id={getstate_BuyerID}
                                        Buttonview={Buttonvisible}
                                        MenuPermission={HolidayInfo}
                                        PageName={"Buyer"}
                                    />
                                    : <span><h3 className="red">User has access denied. Please contact the administrator! </h3></span>
                                }
                            </div>


                            <div id="idBuyerTrimSupplier" className='tab-pane' style={{ width: "100%" }}>
                                {TrimSupplierInfo && TrimSupplierInfo.isList === 1 ?
                                    <TrimSupplier SupplierCallback={SupplierCallback}
                                        IsValidationCallback={IsTrimSupplierParentCallback}
                                        citylist={cityList}
                                        SupplierList={SupplierList}
                                        Id={getstate_BuyerID}
                                        Buttonview={Buttonvisible}
                                        MenuPermission={TrimSupplierInfo}
                                        PageName={"Buyer"}
                                    />
                                    : <span><h3 className="red">User has access denied. Please contact the administrator! </h3></span>
                                }
                            </div>



                            <div id="idBuyerBank" className='tab-pane' style={{ width: "100%" }}>
                                {BankInfo && BankInfo.isList === 1 ?
                                    <Bank BankCallback={BankCallback}
                                        IsValidationCallback={IsBankParentCallback}
                                        citylist={cityList}
                                        BankList={BankList}
                                        Id={getstate_BuyerID}
                                        Buttonview={Buttonvisible}
                                        MenuPermission={BankInfo}
                                        PageName={"Buyer"}
                                    />
                                    : <span><h3 className="red">User has access denied. Please contact the administrator! </h3></span>
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>
                        <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                            <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                        </span>
                        <span className="btn btn-danger" onClick={() => ResetOperation()}>
                            <i className="fa fa-times"></i>&nbsp;Reset
                        </span>

                        {Buttonvisible !== "View" &&
                            (<button type="submit" className="btn btn-success" onClick={SaveBuyer} disabled={buttonLoader}>
                                <i className="fa fa-check right"></i> &nbsp;{ButtonName}
                            </button>)
                        }

                        {Buttonvisible === "View" && activeMenu[0].isView !== undefined && activeMenu[0].isView === 1 ? '' :
                            activeMenu[0].isView !== undefined && activeMenu[0].isView === 0 ? '' :
                                (<button type="submit" style={{ display: activeMenu[0].isView !== undefined && activeMenu[0].isView === 0 ? '' : 'none' }} className="btn btn-info" onClick={viewtoEdit}>
                                    <i className="fa fa-edit"></i>&nbsp;Edit
                                </button>)
                        }
                    </div>
                </div>
            </div>
            {
                getID.showPopupConfirm ? <ConfirmationPopup props={props} data={
                    getID.Params
                }
                    deleteCallback={Delcallback}
                    showpopup={true}
                /> : null
            }

        </div>
    );
};


export default BuyerRegister;