import React, { useState, useEffect } from "react";
import $ from "jquery";
import axios from "axios";
import Lightbox from "react-image-lightbox"
import Nodify from "../Common/ReactNotification"

const DocumentUpload = ({ UploadCallback, PageName, filePath, Buttonview, ShowDiv, SkuInfo }) => {

    const [fileName, setfileName] = useState();
    const [image, setImage] = useState({ preview: "assets/img/uploadimage.jpg", raw: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [FilePath, setFilePath] = useState();
    const [getDeletePath, setDeletePath] = useState();


    var ImgClassName = "upload-btn-wrapper2"
    if (PageName === "Profile") {
        ImgClassName = "upload-btn-wrapper_EMP2"
    }

    let filePathName = "assets/img/uploadimage.jpg";
    useEffect(() => {

        if (filePath !== "" && filePath !== undefined && filePath !== null) {
            filePathName = window.$APIBaseURL + "Images" + filePath;
            setFilePath(filePathName);
            setImage({ preview: filePathName });
            let SplitfilePath = filePath.split('/');
            setfileName(SplitfilePath[2].length > 32 ? SplitfilePath[2].substr(33) : SplitfilePath[2].substr(5));
        }
        else {
            setImage({ preview: "assets/img/uploadimage.jpg", raw: "" });
        }
    }, [filePath]);


    const handleChangeInput = async e => {
        e.target.files = undefined;
        if (e.target.files === undefined) {
            $('#upload-button').trigger('click');
            e.target.files = [{
                config: {
                    url: window.$APIBaseURL + "api/file", method: "post", data: FormData, headers: { Accept: "application/json, text/plain, */*" },
                    transformRequest: Array(1), xsrfCookieName: "XSRF-TOKEN", xsrfHeaderName: "X-XSRF-TOKEN"
                },
                data: "",
                headers: {},
            }]
        } else {
            ;
            const file = e.target.files[0];
            if (file !== undefined) {
                const fileType = file['type'];
                if (fileType !== undefined) {
                    const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png'];
                    if (!validImageTypes.includes(fileType)) {
                        setfileName("No file chosen");
                        Nodify('Warning!', 'warning', 'Invalid file format selected');
                    } else {
                        if (e.target.files.length) {
                            setImage({
                                preview: URL.createObjectURL(e.target.files[0]),
                                raw: e.target.files[0]
                            });
                        }
                        const file = e.target.files[0];
                        if (file !== null && file !== '' && file !== undefined) {
                            const formData = new FormData();
                            formData.append("FormFile", file);
                            formData.append("FileName", file.name);
                            formData.append("Page", PageName);
                            try {
                                const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                                UploadCallback(res.data + "_" + file.name);

                                //for Preview
                                filePathName = window.$APIBaseURL + "Images/" + PageName + "/" + res.data + "_" + file.name;
                                setFilePath(filePathName);
                                setDeletePath({ img: e.target.files[0], file: filePathName });
                                console.log(res);
                            } catch (ex) {
                                console.log(ex);
                            }
                        }
                    }
                } else {
                    setImage({
                        preview: URL.createObjectURL(getDeletePath.img),
                        raw: getDeletePath.img
                    });
                    const file = getDeletePath.img;
                    if (file !== null && file !== '' && file !== undefined) {
                        const formData = new FormData();
                        formData.append("FormFile", file);
                        formData.append("FileName", file.name);
                        formData.append("Page", PageName);
                        try {
                            const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                           // UploadCallback(file.name);
                            UploadCallback(res.data + "_" + file.name);
                            filePathName = window.$APIBaseURL + "Images/" + PageName + "/" + res.data + "_" + file.name;
                            setFilePath(filePathName);
                            setDeletePath({ img: e.target.files[0], file: filePathName });
                            console.log(res);
                        } catch (ex) {
                            console.log(ex);
                        }
                    }
                }
            }
        }
    };

    const openFileUpload = async e => {
        if (e.target.files === undefined) {
            $('#upload-button').trigger('click');
            e.target.files = [{
                config: {
                    url: window.$APIBaseURL + "api/file", method: "post", data: FormData, headers: { Accept: "application/json, text/plain, */*" },
                    transformRequest: Array(1), xsrfCookieName: "XSRF-TOKEN", xsrfHeaderName: "X-XSRF-TOKEN"
                },
                data: "",
                headers: {},
            }]
        } else {
            const file = e.target.files[0];
            if (file !== undefined) {
                const fileType = file['type'];
                if (fileType !== undefined) {
                    const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png'];
                    if (!validImageTypes.includes(fileType)) {
                        setfileName("No file chosen");
                        Nodify('Warning!', 'warning', 'Invalid file format selected');
                    } else {
                        if (e.target.files.length) {
                            setImage({
                                preview: URL.createObjectURL(e.target.files[0]),
                                raw: e.target.files[0]
                            });
                        }
                        const file = e.target.files[0];
                        if (file !== null && file !== '' && file !== undefined) {
                            const formData = new FormData();
                            formData.append("FormFile", file);
                            formData.append("FileName", file.name);
                            formData.append("Page", PageName);
                            try {
                                const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                                UploadCallback(file.name);

                                //for Preview
                                filePathName = window.$APIBaseURL + "Images/" + PageName + "/" + file.name;
                                setFilePath(filePathName);
                                console.log(res);
                            } catch (ex) {
                                console.log(ex);
                            }
                        }
                    }
                }
            }

        }
    }

    const ViewFile = (event) => {
        setIsOpen(true);

    }

    const resetFile = (event) => {
        // event.preventDefault();
        // $('#upload-button').val("");
        setImage({ preview: "assets/img/uploadimage.jpg", raw: "" })
        UploadCallback("");
        setfileName("No file chosen");
    }

    return (
        <>
            {ShowDiv === "1" ?
                <div className={ImgClassName}
                    htmlFor="upload-button"
                    title={fileName}>
                    <img src={image.preview} onClick={openFileUpload}
                        alt="Picture" style={{ width: "100%", marginBottom: '5px', height: '123px', padding: '10px', border: "1px solid #d5d5d5" }} />
                    <input type="file" id="upload-button" accept=".jpg,.jpeg,.png" onChange={handleChangeInput} value='' />
                    {
                        image.preview !== "assets/img/uploadimage.jpg" && (
                            <><div style={
                                {
                                    marginTop: '8px',
                                    float: 'right',
                                    bottom: '10px',
                                    position: 'relative',
                                }
                            }></div>
                                <div className="btn-action-center">
                                    {SkuInfo && SkuInfo.isView === 1 &&
                                        <i className="btn btn-info fa fa-eye" title="View Logo"
                                            onClick={
                                                event => ViewFile(event)
                                            }></i>
                                    }
                                    &nbsp;
                                    {
                                        Buttonview !== "View" && SkuInfo !== undefined && SkuInfo.isDelete === 1 && <i className="btn btn-danger fa fa-trash-o" title="Remove File"
                                            onClick={
                                                event => resetFile(event)
                                            }></i>
                                    }
                                </div>
                            </>
                        )
                    }
                    {
                        <div> {
                            isOpen && (
                                <Lightbox mainSrc={FilePath}
                                    alt="bg image"
                                    onCloseRequest={
                                        () => setIsOpen(false)
                                    } />
                            )
                        } </div>
                    }
                </div>
                : ''
            }
        </>
    );
};
export default DocumentUpload;