import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import $ from "jquery";
import Nodify from "../../../Common/ReactNotification";
import Lightbox from "react-image-lightbox";
import TNAService from "../../../../services/TNA/TNAService";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { Modal } from "react-bootstrap";
import ReactCompareImage from 'react-compare-image'; //https://www.npmjs.com/package/react-compare-image

const TestPhotos = ({ props, CallTestPhotos, IsSaveFailed, InspectionID }) => {
    var ImgClassName = "upload-btn-wrapper2";
    const [getWashDetails, setWashDetails] = useState([]);
    const WashValues = [...getWashDetails];
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [washTypeSubmitted, setwashTypeSubmitted] = useState(false);
    const [getImagename, setImagename] = useState();
    const [isOpenimg, setIsOpenimg] = useState(false);
    const [getImagesToCompare, setImagesToCompare] = useState([]);
    const ComparableImages = [...getImagesToCompare];
    const [getComparerViewMode, setComparerViewMode] = useState({ isShow: false });

    const handleExpandRow = (id, i) => {
        WashValues[i].isExpand == 1 ? WashValues[i].isExpand = 0 : WashValues[i].isExpand = 1;
        setWashDetails(WashValues);
    }

    const handleWashTypeChange = (e, i) => {
        let inputText = '';
        if (e.target.value.trim() !== '') {
            inputText = e.target.value;
        }
        WashValues[i].washName = inputText;
        setWashDetails(WashValues);
        CallTestPhotos(WashValues);
    }

    const handletitleChange = (e, i, pi) => {
        let inputText = '';
        if (e.target.value.trim() !== '') {
            inputText = e.target.value;
        }
        WashValues[i].photoDetails[pi].title = inputText;
        setWashDetails(WashValues);
        CallTestPhotos(WashValues);
    }

    const AddNewTestPhoto = (e, i, pi) => {
        for (var j = 0; j < WashValues[i].photoDetails.length; j++) {
            if (WashValues[i].photoDetails[j].photoIndexID == pi + 1) {
                WashValues[i].photoDetails[j].photoIndexID = WashValues[i].photoDetails[j].photoIndexID + 1;
            }
        }
        WashValues[i].photoDetails.splice((pi + 1), 0,
            {
                id: 0
                , washTypeID: i
                , title: ""
                , fileName: ""
                , filePath: ""
                , fileType: ""
                , preview: "assets/img/uploadimage.jpg"
                , raw: ""
                , photoIndexID: pi + 1
                , isCheckedToCompare: 0
            }
        );
        setWashDetails(WashValues);
        CallTestPhotos(WashValues);
    }

    const ClearTestPhoto = (e, i, pi) => {
        WashValues[i].photoDetails[pi].fileName = "";
        WashValues[i].photoDetails[pi].filePath = "";
        WashValues[i].photoDetails[pi].fileType = "";
        WashValues[i].photoDetails[pi].preview = "assets/img/uploadimage.jpg";
        WashValues[i].photoDetails[pi].raw = "";
        setWashDetails(WashValues);
        CallTestPhotos(WashValues);
    }

    const RemoveTestPhoto = (e, i, pi) => {
        WashValues[i].photoDetails.splice(pi, 1);
        setWashDetails(WashValues);
        CallTestPhotos(WashValues);
    }

    const DeleteWashType = (e, i) => {
        WashValues.splice(i, 1);
        setWashDetails(WashValues);
        CallTestPhotos(WashValues);
    }

    const AddNewAfterWash = (e, i) => {
        setwashTypeSubmitted(true);
        for (var j = 0; j < getWashDetails[i].photoDetails.length; j++) {
            let title = getWashDetails[i].photoDetails[j].title;
            let fileName = getWashDetails[i].photoDetails[j].fileName;
            if (!title != "" || !fileName) {
                Nodify('Warning!', 'warning', 'Please fill all mandatory(*) fields.');
                return false;
            }
        }
        WashValues.push({
            Id: 0
            , washTypeID: i + 1
            , washName: ''
            , indexID: 0
            , photoDetails: [
                {
                    id: 0
                    , washTypeID: i + 1
                    , title: ""
                    , fileName: ""
                    , filePath: ""
                    , fileType: ""
                    , preview: "assets/img/uploadimage.jpg"
                    , raw: ""
                    , photoIndexID: 0
                    , isCheckedToCompare: 0
                }
                , {
                    id: 0
                    , washTypeID: i + 1
                    , title: ""
                    , fileName: ""
                    , filePath: ""
                    , fileType: ""
                    , preview: "assets/img/uploadimage.jpg"
                    , raw: ""
                    , photoIndexID: 1
                    , isCheckedToCompare: 0
                }
                , {
                    id: 0
                    , washTypeID: i + 1
                    , title: ""
                    , fileName: ""
                    , filePath: ""
                    , fileType: ""
                    , preview: "assets/img/uploadimage.jpg"
                    , raw: ""
                    , photoIndexID: 2
                    , isCheckedToCompare: 0
                }
                , {
                    id: 0
                    , washTypeID: i + 1
                    , title: ""
                    , fileName: ""
                    , filePath: ""
                    , fileType: ""
                    , preview: "assets/img/uploadimage.jpg"
                    , raw: ""
                    , photoIndexID: 3
                    , isCheckedToCompare: 0
                }
            ]
        });
        setWashDetails(WashValues);
        CallTestPhotos(WashValues);
        setwashTypeSubmitted(false);
    }

    const openFileUpload = async (e, i, washType) => {
        if (e.target.files === undefined) {
            $('#upload-button').trigger('click');
        }
    }

    const handleChangeInput = async (e, i, pi, washType) => {
        const file = e.target.files[0];
        if (file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png'];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
            }
            else {
                if (e.target.files.length) {
                    WashValues[i].photoDetails[pi].preview = URL.createObjectURL(e.target.files[0]);
                    WashValues[i].photoDetails[pi].fileName = e.target.files[0].name;
                    WashValues[i].photoDetails[pi].raw = e.target.files[0];
                    setWashDetails(WashValues);
                }
                const file = e.target.files[0];
                if (file !== null && file !== '' && file !== undefined) {
                    const formData = new FormData();
                    formData.append("FormFile", file);
                    formData.append("FileName", file.name);
                    formData.append("Page", "TestPhotos");
                    try {
                        const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                        WashValues[i].photoDetails[pi].fileName = res.data + "_" + file.name;
                        WashValues[i].photoDetails[pi].filePath = "Images/Testing/TestPhotos/" + file.name;
                        WashValues[i].photoDetails[pi].fileType = file.type;

                        setWashDetails(WashValues);
                        CallTestPhotos(WashValues);
                    } catch (ex) {
                        console.log(ex);
                    }
                }
            }
        }
    }

    const ViewFile = (i, pi, filename) => {
        setImagename(filename);
        setIsOpenimg(true);
    }

    const ChooseImageToCompare = (e, src, i, pi) => {
        if (e.target.checked) {
            ComparableImages.push(src);
            setImagesToCompare(ComparableImages);
            WashValues[i].photoDetails[pi].isCheckedToCompare = 1;
            if (ComparableImages.length > 1) {
                setComparerViewMode({ isShow: true });
            }
            setWashDetails(WashValues);
        }
        else {
            WashValues[i].photoDetails[pi].isCheckedToCompare = 0;
            setWashDetails(WashValues);
            if (ComparableImages != undefined && ComparableImages.length > 0) {
                setImagesToCompare(ComparableImages.filter((data) => {
                    return data != src;
                }));
            }
            else {
                setImagesToCompare([]);
            }
        }
    }

    const handleCloseComparer = () => {
        for (var i = 0; i < WashValues.length; i++) {
            let PhotoDetails = WashValues[i].photoDetails;
            for (var j = 0; j < PhotoDetails.length; j++) {
                PhotoDetails[j].isCheckedToCompare = 0;
                $("#chkComparer_" + j + "_" + i).prop("checked", false);
            }
        }
        setWashDetails(WashValues);
        setImagesToCompare([]);
        setComparerViewMode({ isShow: false });
    }

    // useEffect(() => {
    //     showLoader();
    //     debugger;
    //     if ((InspectionID != undefined && InspectionID.isLoad > 0 && parseInt(InspectionID.StyleID) > 0) && (props.location.state.params.pageType != "AddInspection")) {
    //         TNAService.getAllQcInfoList(1, 0, parseInt(InspectionID.StyleID)).then((res) => {
    //             let WashValuesOnSave = [];
    //             if (res.data != undefined && res.data.washTypeNames.length > 0) {
    //                 res.data.washTypeNames.forEach(element => {
    //                     WashValuesOnSave.push({ Id: element.washID, washtypeID: element.washTypeID, washName: element.washName });
    //                 });
    //                 WashValuesOnSave.forEach(photo => {
    //                     photo.photoDetails = res.data.washTypePhotos.filter((photodetails => {
    //                         photodetails.preview = window.$APIBaseURL + 'Images/Testing/TestPhotos/' + photodetails.fileName;
    //                         return photodetails.washTypeID === photo.washtypeID;
    //                     }));
    //                     return photo;
    //                 });
    //                 setWashDetails(WashValuesOnSave);
    //                 CallTestPhotos(WashValuesOnSave);
    //                 hideLoader();
    //             }
    //             else {
    //                 hideLoader();
    //                 let WashDetails = [{
    //                     Id: 1
    //                     , washTypeID: 0
    //                     , washName: 'Before Wash'
    //                     , indexID: 0
    //                     , isExpand: 0
    //                     , photoDetails: [
    //                         {
    //                             id: 0
    //                             , washTypeID: 0
    //                             , title: ""
    //                             , fileName: ""
    //                             , filePath: ""
    //                             , fileType: ""
    //                             , preview: "assets/img/uploadimage.jpg"
    //                             , raw: ""
    //                             , photoIndexID: 0
    //                             , isCheckedToCompare: 0
    //                         }
    //                         , {
    //                             id: 0
    //                             , washTypeID: 0
    //                             , title: ""
    //                             , fileName: ""
    //                             , filePath: ""
    //                             , fileType: ""
    //                             , preview: "assets/img/uploadimage.jpg"
    //                             , raw: ""
    //                             , photoIndexID: 1
    //                             , isCheckedToCompare: 0
    //                         }
    //                         , {
    //                             id: 0
    //                             , washTypeID: 0
    //                             , title: ""
    //                             , fileName: ""
    //                             , filePath: ""
    //                             , fileType: ""
    //                             , preview: "assets/img/uploadimage.jpg"
    //                             , raw: ""
    //                             , photoIndexID: 2
    //                             , isCheckedToCompare: 0
    //                         }
    //                         , {
    //                             id: 0
    //                             , washTypeID: 0
    //                             , title: ""
    //                             , fileName: ""
    //                             , filePath: ""
    //                             , fileType: ""
    //                             , preview: "assets/img/uploadimage.jpg"
    //                             , raw: ""
    //                             , photoIndexID: 3
    //                             , isCheckedToCompare: 0
    //                         }
    //                     ]
    //                 }
    //                     , {
    //                     Id: 2
    //                     , washTypeID: 1
    //                     , washName: 'After Wash'
    //                     , indexID: 0
    //                     , isExpand: 0
    //                     , photoDetails: [
    //                         {
    //                             id: 0
    //                             , washTypeID: 1
    //                             , title: ""
    //                             , fileName: ""
    //                             , filePath: ""
    //                             , fileType: ""
    //                             , preview: "assets/img/uploadimage.jpg"
    //                             , raw: ""
    //                             , photoIndexID: 0
    //                             , isCheckedToCompare: 0
    //                         }
    //                         , {
    //                             id: 0
    //                             , washTypeID: 1
    //                             , title: ""
    //                             , fileName: ""
    //                             , filePath: ""
    //                             , fileType: ""
    //                             , preview: "assets/img/uploadimage.jpg"
    //                             , raw: ""
    //                             , photoIndexID: 1
    //                             , isCheckedToCompare: 0
    //                         }
    //                         , {
    //                             id: 0
    //                             , washTypeID: 1
    //                             , title: ""
    //                             , fileName: ""
    //                             , filePath: ""
    //                             , fileType: ""
    //                             , preview: "assets/img/uploadimage.jpg"
    //                             , raw: ""
    //                             , photoIndexID: 2
    //                             , isCheckedToCompare: 0
    //                         }
    //                         , {
    //                             id: 0
    //                             , washTypeID: 1
    //                             , title: ""
    //                             , fileName: ""
    //                             , filePath: ""
    //                             , fileType: ""
    //                             , preview: "assets/img/uploadimage.jpg"
    //                             , raw: ""
    //                             , photoIndexID: 3
    //                             , isCheckedToCompare: 0
    //                         }
    //                     ]
    //                 }];
    //                 setWashDetails(WashDetails);
    //                 CallTestPhotos(WashDetails);
    //                 hideLoader();
    //             }
    //         });
    //     }
    //     else if ((InspectionID != undefined && InspectionID.isLoad == 0 && parseInt(InspectionID.StyleID) == 0) || (props.location.state.params.pageType = "EditInspection")) {
    //         if (props.location.state.params.TnaID > 0 && props.location.state.params.TestingInspectionID > 0) {
    //             TNAService.getAllQcInfoList(1, 0, props.location.state.params.TestingInspectionID).then((res) => {
    //                 let WashValuesOnSave = [];
    //                 if (res.data != undefined && res.data.washTypeNames.length > 0) {
    //                     res.data.washTypeNames.forEach(element => {
    //                         WashValuesOnSave.push({ Id: element.washID, washtypeID: element.washTypeID, washName: element.washName });
    //                     });
    //                     WashValuesOnSave.forEach(photo => {
    //                         photo.photoDetails = res.data.washTypePhotos.filter((photodetails => {
    //                             photodetails.preview = window.$APIBaseURL + 'Images/Testing/TestPhotos/' + photodetails.fileName;
    //                             return photodetails.washTypeID === photo.washtypeID;
    //                         }));
    //                         return photo;
    //                     });
    //                     setWashDetails(WashValuesOnSave);
    //                     CallTestPhotos(WashValuesOnSave);
    //                     hideLoader();
    //                 }
    //                 else {
    //                     hideLoader();
    //                     let WashDetails = [{
    //                         Id: 1
    //                         , washTypeID: 0
    //                         , washName: 'Before Wash'
    //                         , indexID: 0
    //                         , isExpand: 0
    //                         , photoDetails: [
    //                             {
    //                                 id: 0
    //                                 , washTypeID: 0
    //                                 , title: ""
    //                                 , fileName: ""
    //                                 , filePath: ""
    //                                 , fileType: ""
    //                                 , preview: "assets/img/uploadimage.jpg"
    //                                 , raw: ""
    //                                 , photoIndexID: 0
    //                                 , isCheckedToCompare: 0
    //                             }
    //                             , {
    //                                 id: 0
    //                                 , washTypeID: 0
    //                                 , title: ""
    //                                 , fileName: ""
    //                                 , filePath: ""
    //                                 , fileType: ""
    //                                 , preview: "assets/img/uploadimage.jpg"
    //                                 , raw: ""
    //                                 , photoIndexID: 1
    //                                 , isCheckedToCompare: 0
    //                             }
    //                             , {
    //                                 id: 0
    //                                 , washTypeID: 0
    //                                 , title: ""
    //                                 , fileName: ""
    //                                 , filePath: ""
    //                                 , fileType: ""
    //                                 , preview: "assets/img/uploadimage.jpg"
    //                                 , raw: ""
    //                                 , photoIndexID: 2
    //                                 , isCheckedToCompare: 0
    //                             }
    //                             , {
    //                                 id: 0
    //                                 , washTypeID: 0
    //                                 , title: ""
    //                                 , fileName: ""
    //                                 , filePath: ""
    //                                 , fileType: ""
    //                                 , preview: "assets/img/uploadimage.jpg"
    //                                 , raw: ""
    //                                 , photoIndexID: 3
    //                                 , isCheckedToCompare: 0
    //                             }
    //                         ]
    //                     }
    //                         , {
    //                         Id: 2
    //                         , washTypeID: 1
    //                         , washName: 'After Wash'
    //                         , indexID: 0
    //                         , isExpand: 0
    //                         , photoDetails: [
    //                             {
    //                                 id: 0
    //                                 , washTypeID: 1
    //                                 , title: ""
    //                                 , fileName: ""
    //                                 , filePath: ""
    //                                 , fileType: ""
    //                                 , preview: "assets/img/uploadimage.jpg"
    //                                 , raw: ""
    //                                 , photoIndexID: 0
    //                                 , isCheckedToCompare: 0
    //                             }
    //                             , {
    //                                 id: 0
    //                                 , washTypeID: 1
    //                                 , title: ""
    //                                 , fileName: ""
    //                                 , filePath: ""
    //                                 , fileType: ""
    //                                 , preview: "assets/img/uploadimage.jpg"
    //                                 , raw: ""
    //                                 , photoIndexID: 1
    //                                 , isCheckedToCompare: 0
    //                             }
    //                             , {
    //                                 id: 0
    //                                 , washTypeID: 1
    //                                 , title: ""
    //                                 , fileName: ""
    //                                 , filePath: ""
    //                                 , fileType: ""
    //                                 , preview: "assets/img/uploadimage.jpg"
    //                                 , raw: ""
    //                                 , photoIndexID: 2
    //                                 , isCheckedToCompare: 0
    //                             }
    //                             , {
    //                                 id: 0
    //                                 , washTypeID: 1
    //                                 , title: ""
    //                                 , fileName: ""
    //                                 , filePath: ""
    //                                 , fileType: ""
    //                                 , preview: "assets/img/uploadimage.jpg"
    //                                 , raw: ""
    //                                 , photoIndexID: 3
    //                                 , isCheckedToCompare: 0
    //                             }
    //                         ]
    //                     }];
    //                     setWashDetails(WashDetails);
    //                     CallTestPhotos(WashDetails);
    //                     hideLoader();
    //                 }
    //             });
    //         }
    //         else if (props.location.state.params.TnaID > 0 && props.location.state.params.TestingInspectionID == undefined) {
    //             hideLoader();
    //             let WashDetails = [{
    //                 Id: 1
    //                 , washTypeID: 0
    //                 , washName: 'Before Wash'
    //                 , indexID: 0
    //                 , isExpand: 0
    //                 , photoDetails: [
    //                     {
    //                         id: 0
    //                         , washTypeID: 0
    //                         , title: ""
    //                         , fileName: ""
    //                         , filePath: ""
    //                         , fileType: ""
    //                         , preview: "assets/img/uploadimage.jpg"
    //                         , raw: ""
    //                         , photoIndexID: 0
    //                         , isCheckedToCompare: 0
    //                     }
    //                     , {
    //                         id: 0
    //                         , washTypeID: 0
    //                         , title: ""
    //                         , fileName: ""
    //                         , filePath: ""
    //                         , fileType: ""
    //                         , preview: "assets/img/uploadimage.jpg"
    //                         , raw: ""
    //                         , photoIndexID: 1
    //                         , isCheckedToCompare: 0
    //                     }
    //                     , {
    //                         id: 0
    //                         , washTypeID: 0
    //                         , title: ""
    //                         , fileName: ""
    //                         , filePath: ""
    //                         , fileType: ""
    //                         , preview: "assets/img/uploadimage.jpg"
    //                         , raw: ""
    //                         , photoIndexID: 2
    //                         , isCheckedToCompare: 0
    //                     }
    //                     , {
    //                         id: 0
    //                         , washTypeID: 0
    //                         , title: ""
    //                         , fileName: ""
    //                         , filePath: ""
    //                         , fileType: ""
    //                         , preview: "assets/img/uploadimage.jpg"
    //                         , raw: ""
    //                         , photoIndexID: 3
    //                         , isCheckedToCompare: 0
    //                     }
    //                 ]
    //             }
    //                 , {
    //                 Id: 2
    //                 , washTypeID: 1
    //                 , washName: 'After Wash'
    //                 , indexID: 0
    //                 , isExpand: 0
    //                 , photoDetails: [
    //                     {
    //                         id: 0
    //                         , washTypeID: 1
    //                         , title: ""
    //                         , fileName: ""
    //                         , filePath: ""
    //                         , fileType: ""
    //                         , preview: "assets/img/uploadimage.jpg"
    //                         , raw: ""
    //                         , photoIndexID: 0
    //                         , isCheckedToCompare: 0
    //                     }
    //                     , {
    //                         id: 0
    //                         , washTypeID: 1
    //                         , title: ""
    //                         , fileName: ""
    //                         , filePath: ""
    //                         , fileType: ""
    //                         , preview: "assets/img/uploadimage.jpg"
    //                         , raw: ""
    //                         , photoIndexID: 1
    //                         , isCheckedToCompare: 0
    //                     }
    //                     , {
    //                         id: 0
    //                         , washTypeID: 1
    //                         , title: ""
    //                         , fileName: ""
    //                         , filePath: ""
    //                         , fileType: ""
    //                         , preview: "assets/img/uploadimage.jpg"
    //                         , raw: ""
    //                         , photoIndexID: 2
    //                         , isCheckedToCompare: 0
    //                     }
    //                     , {
    //                         id: 0
    //                         , washTypeID: 1
    //                         , title: ""
    //                         , fileName: ""
    //                         , filePath: ""
    //                         , fileType: ""
    //                         , preview: "assets/img/uploadimage.jpg"
    //                         , raw: ""
    //                         , photoIndexID: 3
    //                         , isCheckedToCompare: 0
    //                     }
    //                 ]
    //             }];
    //             setWashDetails(WashDetails);
    //             CallTestPhotos(WashDetails);
    //             hideLoader();
    //         }
    //     }
    // }, [InspectionID != undefined && InspectionID])
    useEffect(() => {
        const createDefaultWashDetails = () => {
            return [{
                Id: 1,
                washTypeID: 0,
                washName: 'Before Wash',
                indexID: 0,
                isExpand: 0,
                photoDetails: Array.from({ length: 4 }, (_, index) => ({
                    id: 0,
                    washTypeID: 0,
                    title: "",
                    fileName: "",
                    filePath: "",
                    fileType: "",
                    preview: "assets/img/uploadimage.jpg",
                    raw: "",
                    photoIndexID: index,
                    isCheckedToCompare: 0
                }))
            }, {
                Id: 2,
                washTypeID: 1,
                washName: 'After Wash',
                indexID: 0,
                isExpand: 0,
                photoDetails: Array.from({ length: 4 }, (_, index) => ({
                    id: 0,
                    washTypeID: 1,
                    title: "",
                    fileName: "",
                    filePath: "",
                    fileType: "",
                    preview: "assets/img/uploadimage.jpg",
                    raw: "",
                    photoIndexID: index,
                    isCheckedToCompare: 0
                }))
            }];
        };

        const processWashData = (res) => {
            let WashValuesOnSave = [];
            if (res.data && res.data.washTypeNames.length > 0) {
                res.data.washTypeNames.forEach(element => {
                    WashValuesOnSave.push({ Id: element.washID, washtypeID: element.washTypeID, washName: element.washName });
                });

                WashValuesOnSave.forEach(photo => {
                    photo.photoDetails = res.data.washTypePhotos.filter(photodetails => {
                        photodetails.preview = window.$APIBaseURL + 'Images/Testing/TestPhotos/' + photodetails.fileName;
                        return photodetails.washTypeID === photo.washtypeID;
                    });
                });

                setWashDetails(WashValuesOnSave);
                CallTestPhotos(WashValuesOnSave);
            } else {
                let defaultWashDetails = createDefaultWashDetails();
                setWashDetails(defaultWashDetails);
                CallTestPhotos(defaultWashDetails);
            }
        };

        const fetchWashDetails = (styleId) => {
            showLoader();
            TNAService.getAllQcInfoList(1, 0, styleId).then(processWashData).finally(() => hideLoader());
        };
        debugger
        if (InspectionID && parseInt(InspectionID.StyleID) > 0 && props.location.state.params.TaskStatus === 1) {
            fetchWashDetails(parseInt(InspectionID.StyleID));
        } else if (props.location.state.params.TaskStatus === 2) {
            if (props.location.state.params.TestingInspectionID) {
                fetchWashDetails(props.location.state.params.TestingInspectionID);
            }
        }
    }, [InspectionID]);

    return (
        <Fragment>
            <div className="page-body">
                <div className="row">

                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                        <tbody>
                            {
                                getWashDetails != undefined && getWashDetails.map((wash, index) => (
                                    <Fragment>
                                        <tr aria-colspan={2}>
                                            <td>
                                                <i className={wash.isExpand ?
                                                    'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'}
                                                    onClick={event => handleExpandRow(wash.Id, index)}></i>&nbsp;&nbsp;&nbsp;
                                                <input type="text"
                                                    className="custom_formcontrol"
                                                    value={wash.washName}
                                                    placeholder="Enter Wash Name"
                                                    onChange={event => { handleWashTypeChange(event, index) }}
                                                    style={{ border: ((washTypeSubmitted || IsSaveFailed) && !wash.washName) ? "1px solid red" : "" }}
                                                    disabled={wash.washTypeID == 0 ? true : false} />
                                                &nbsp;&nbsp;
                                                {wash.washTypeID != 0 ?
                                                    <>
                                                        {getWashDetails.length === (index + 1) && <button className="btn btn-xs btn-success" onClick={event => { AddNewAfterWash(event, index) }}><i className="fa fa-plus"></i></button>}
                                                        {index != 0 && (getWashDetails.length > 2) && < button className="btn btn-xs btn-danger" style={{ marginLeft: '5px' }} onClick={event => { DeleteWashType(event, index) }}><i className="fa fa-trash"></i></button>}</> : ""}
                                            </td>
                                        </tr>
                                        {
                                            wash.isExpand ? <tr>
                                                <td>
                                                    {
                                                        getWashDetails[index].photoDetails != undefined && getWashDetails[index].photoDetails.map((photo, photoindex) => (
                                                            <Fragment>
                                                                <div className="col-md-2" style={{ marginTop: '5px', marginBottom: '5px' }}>
                                                                    <div className={ImgClassName}>
                                                                        <img src={photo.preview}
                                                                            onChange={event => openFileUpload(event, index, photoindex, photo.washTypeID)}
                                                                            style={{
                                                                                width: '100%'
                                                                                , height: '170px'
                                                                                //, border: ((washTypeSubmitted || IsSaveFailed) && (!photo.fileName) ? "1px solid red" : "")
                                                                            }}
                                                                            alt="Picture" />

                                                                        <input type="text"
                                                                            className="form-control"
                                                                            value={photo.title}
                                                                            placeholder="Enter Title"
                                                                            onChange={event => { handletitleChange(event, index, photoindex) }}
                                                                            style={{
                                                                                //border: ((washTypeSubmitted || IsSaveFailed) && !photo.title) ? "1px solid red" : ""
                                                                                marginTop: '5px'
                                                                            }}
                                                                        />
                                                                        <input type="file" id="upload-button" accept=".jpg,.jpeg,.png" value='' style={{ padding: "75px !important", height: "80% !important" }}
                                                                            onChange={event => handleChangeInput(event, index, photoindex, photo.washTypeID)} />
                                                                    </div>
                                                                    <div style={{ marginTop: '20px', textAlign: 'center' }}>
                                                                        <button type="button" class="btn btn-xs btn-success" title="Add Photo" onClick={event => AddNewTestPhoto(event, index, photoindex)}><i class="fa fa-plus"></i></button>&nbsp;
                                                                        <button type="button" class="btn btn-xs btn-warning" title="View Photo"
                                                                            onClick={() => ViewFile(index, photoindex, photo.fileName)}><i class="fa fa-eye"></i></button>&nbsp;
                                                                        <button type="button" class="btn btn-xs btn-primary" title="Clear Photo" onClick={event => ClearTestPhoto(event, index, photoindex)}><i class="fa fa-times"></i></button>&nbsp;
                                                                        {props.location.state.params.pageType === 'ViewInspection' ? null :
                                                                            (getWashDetails[index].photoDetails.length !== 1 &&
                                                                                <button type="button" className="btn btn-xs btn-danger" title="Remove Photo" onClick={event => RemoveTestPhoto(event, index, photoindex)}>
                                                                                    <i className="fa fa-trash-o"></i>
                                                                                </button>
                                                                            )
                                                                        }

                                                                        &nbsp;
                                                                        <label style={{ marginTop: '8px' }}>
                                                                            <input type="checkbox" name="IsSelected"
                                                                                id={"chkComparer_" + photoindex + "_" + index}
                                                                                value={photo.isCheckedToCompare == 1 ? true : false}
                                                                                onChange={event => { ChooseImageToCompare(event, photo.preview, index, photoindex) }}
                                                                                className="colored-blue clearOpacity" />
                                                                            <span className="text"></span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </Fragment>
                                                        ))
                                                    }
                                                </td>
                                            </tr> : ""
                                        }
                                        {
                                            getComparerViewMode &&
                                            <Modal dialogClassName="" show={getComparerViewMode.isShow} size="md" dragable backdrop="static" keyboard={false}
                                            >
                                                <Modal.Header closeButton onHide={() => handleCloseComparer()}>
                                                    <Modal.Title>
                                                        Compare Test Photos
                                                    </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div style={{ width: '100%' }}>
                                                                <ReactCompareImage
                                                                    aspectRatio="wider"
                                                                    leftImage={getImagesToCompare[0]}
                                                                    rightImage={getImagesToCompare[1]} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <button type="button" class="btn btn-danger" onClick={() => handleCloseComparer()}><i className='fa fa-times'></i>
                                                        &nbsp;Close</button>
                                                </Modal.Footer>
                                            </Modal>
                                        }
                                    </Fragment>
                                ))
                            }
                        </tbody>
                    </table>
                    {
                        < div > {
                            isOpenimg && (
                                <Lightbox mainSrc={window.$APIBaseURL + 'Images/Testing/TestPhotos/' + getImagename}
                                    alt="bg image"
                                    onCloseRequest={
                                        () => setIsOpenimg(false)
                                    } />
                            )
                        } </div>
                    }
                </div>
            </div >
            {loader}
        </Fragment >
    )
}
export default TestPhotos