import {
 CSRDETAILS_LOADING,
 RETRIEVE_CSRDETAILS,
 DELETE_CSRDETAILS,
 CSRDROPDOWNDETAILS_LOADING,
 RETRIEVE_CSRDROPDOWNDETAILS,
 DELETE_CSRDROPDOWNDETAILS
}

 from "./types"


import CsrService from "../services/Master/CsrService"

const csrDetailsLoading = (isStatus) => ({
 type: CSRDETAILS_LOADING,
 payload: isStatus,
});



export const UpdateCsrDetails = (GetItem) => async (dispatch) => {
 
 let resData = "";
 await CsrService.UpdateDeleteCSRDetails(GetItem).then((res) => {
  resData = res.data;

 }).catch((err) => { return Promise.reject(err); });
 return Promise.resolve(resData);
}



export const deleteCsrDetails = (itemID) => async (dispatch) => {

 try {
  dispatch({
   type: DELETE_CSRDETAILS,
   payload: itemID,
  });

 } catch (err) {
  console.log(err);
 }
};


export const retriveCsrDetails = () => async (dispatch) => {
 try {
  dispatch(csrDetailsLoading(true));
  const res = await CsrService.getAllCSRDetails();
  dispatch({
   type: RETRIEVE_CSRDETAILS,
   payload: res.data,
  });
  dispatch(csrDetailsLoading(false));
 } catch (err) {
  console.log(err);
  dispatch(csrDetailsLoading(false));
 }
};

////////////////////////////////////////////////drodown details /////////////////////////////////////
const csrDropdownDetailsLoading = (isStatus) => ({
 type: CSRDROPDOWNDETAILS_LOADING,
 payload: isStatus,
});



export const UpdateCsrDropdownDetails = (GetItem) => async (dispatch) => {

 let resData = "";
 await CsrService.UpdateDeleteCSRDropdownDetails(GetItem).then((res) => {
  resData = res.data;

 }).catch((err) => { return Promise.reject(err); });
 return Promise.resolve(resData);
}


export const deleteCsrDropdownDetails = (itemID) => async (dispatch) => {

 try {
  dispatch({
   type: DELETE_CSRDROPDOWNDETAILS,
   payload: itemID,
  });

 } catch (err) {
  console.log(err);
 }
};


export const retriveCsrDropdownDetails = () => async (dispatch) => {
 try {
  dispatch(csrDropdownDetailsLoading(true));
  const res = await CsrService.getAllCSRDropDownDetails();
  dispatch({
   type: RETRIEVE_CSRDROPDOWNDETAILS,
   payload: res.data,
  });
  dispatch(csrDropdownDetailsLoading(false));
 } catch (err) {
  console.log(err);
  dispatch(csrDropdownDetailsLoading(false));
 }
};