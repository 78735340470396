import React, { Fragment, useMemo, useState, useEffect } from "react";
import FinalinspecUpload from "../../../Common/FinalinspecUpload";
import FinalinspectionService from "../../../../services/FinalInspection/FinalinspectionService";
import Reactselect from 'react-select';
import Nodify from "../../../Common/ReactNotification";
import AQLList from '../../../../services/Master/AqlService';
import Lightbox from "react-image-lightbox"
import { data } from "jquery";
import { Modal, Button, ModalBody } from "react-bootstrap";
import DefectService from "../../../../services/Master/DefectService";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { useSelector } from "react-redux";
import validator from 'validator'

const FinalInspectionDefects = ({ props, InspectionID, majorminlvl, DefectCallback, Summarycallback, FindingRemarksCalls, Savevalidationcallback, rerenderdep }) => {
    const EmptyInputFields =
        [{
            TNAFinalInspecDefectID: 0,
            DefectID: 0,
            DefectName: '',
            FoundArea: '',
            DefectType: 0,
            CountID: 1,
            DefectMainindex: 0,
            ImageList: [{
                TNAFinalInspecDefectSubID: 0,
                DefectMainindex: 0,
                DefectChildindex: 0,
                UploadedImagePath1: '',
                UploadedImagePath2: '',
            }],
        }]
    const [ExistingList, setExistingList] = useState([]);
    const [submitted, setSubmitteds] = useState(false);
    const { user: currentUser } = useSelector((state) => state.auth);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getDefect, setDefect] = useState([{ Id: 0, Name: "", }]);
    const [getDefectPopup, setDefectPopup] = useState(false);
    const [inputField, setInputFields] = useState(EmptyInputFields);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [SummaryField, setSummaryField] = useState([
        { TNAFinalInspecDefectSummaryID: 0, details: 'Allowed Defects ', critical: 0, major: 0, minor: 0, result: '-' }
        , { TNAFinalInspecDefectSummaryID: 0, details: 'Found Defects', critical: 0, major: 0, minor: 0, result: '-' }])
    const [DefectsExpandState, setDefectsState] = useState({});
    const [DefectsExpandedRows, setDefectsExpandedRows] = useState([]);
    const [defectValue, setdefectValue] = useState([]);
    const [defectType, setdefectType] = useState([]);
    const [Submitted, setSubmitted] = useState(false);
    const [FindingRemarks, setFindingRemarks] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [getImagename, setImagename] = useState();

    const [getStyleInfo, setStyleInfo] = useState([]);

    const values = [...inputField]
    const Summaryvalues = [...SummaryField]
    const inpsectionId = JSON.parse(localStorage.getItem("InspectionID"));
    useEffect(() => {
        setSubmitted(Savevalidationcallback);
    }, [Savevalidationcallback === true]);

    const customStyles = {
        option: (styles, { data }) => {
            return {
                ...styles,
                color: data.value === 1 ? 'green' : data.value === 2 ? "Red" : "Blue"

            };
        }
    }


    useEffect(() => {
        (async () => {
            if (props.location.state.params.TaskStatus === 1) {
               
                showLoader();
                let DefectValue = [];
                let defacttype = [];
                let AqlList = [];


                await FinalinspectionService.GetTNAFinalinspecDefactList(1, 0).then((res) => {
                    if (res.data) {
                        res.data.map((x) => {
                            DefectValue.push({
                                value: x.defectTypeID,
                                label: x.defectName
                            })
                        });
                        setdefectValue(DefectValue);
                    }
                })
                await FinalinspectionService.GetTNAFinalinspecDefactList(2, 0).then((res) => {
                    if (res.data) {
                        res.data.map((x) => {
                            let LabelColor = '';
                            if (x.defectID === 1) {
                                LabelColor = <><span style={{ color: "blue" }}><b>Minor</b></span></>
                            }
                            else if (x.defectID === 2) {
                                LabelColor = <><span style={{ color: "orange" }}><b>Major</b></span></>
                            }
                            else if (x.defectID === 3) {
                                LabelColor = <><span style={{ color: "Red" }}><b>Critical</b></span></>
                            }

                            defacttype.push({
                                value: x.defectID,
                                label: LabelColor,
                                subLabel: x.value,
                            })
                            // defacttype.push({
                            //     value: x.defectID,
                            //     label: x.defectLevel
                            // })
                        })
                    }
                    setdefectType(defacttype);
                })
                var uniqueimages
                let Inputtext = [];
                let Majorlvl = {};
                let Minorlvl = {};
                await AQLList.getAllAqlList(0).then(async (res) => {

                    await FinalinspectionService.GetTNAFinalinspecMainInfo(inpsectionId, props.location.state.params.details.tnaid).then(async (res1) => {
                        if (res.data) {
                            uniqueimages = parseInt(res1.data.finalInsQuantityInfoList[0].totalOfferedQty)
                        }
                        if (res.data) {
                            await res.data.map((x) => {
                                AqlList.push({
                                    InspectionLevel: x.inspectionLevel,
                                    LotSizeFromm: parseInt(x.lotSizeFromm),
                                    LotSizeTo: parseInt(x.lotSizeTo),
                                    SampleSize: parseInt(x.sampleSize),
                                    AQL: parseInt(x.aql),
                                    Accept: parseInt(x.accept),
                                })
                            })
                            Inputtext = AqlList.filter(x => x.LotSizeFromm <= uniqueimages && x.LotSizeTo >= uniqueimages)
                            Majorlvl = Inputtext.find(x => x.AQL === parseInt(majorminlvl[0].Major))
                            Minorlvl = Inputtext.find(x => x.AQL === parseInt(majorminlvl[0].Minor))
                            Summaryvalues[0].major = Majorlvl === undefined ? 0 : Majorlvl.Accept;
                            Summaryvalues[0].minor = Minorlvl === undefined ? 0 : Minorlvl.Accept;
                            Summaryvalues[1].result = '-'
                        }
                        setSummaryField(Summaryvalues)
                        Summarycallback(Summaryvalues);
                    })
                })


                let DefectlistData = []
                let DefectSummary = [];
                let inputText = '';
                //if (inpsectionId !== null) {
                await FinalinspectionService.GetTNAFinalinspecMainInfo(inpsectionId, props.location.state.params.details.tnaid).then(async (res) => {

                    if (res.data.finalInsDefectTabList.length > 0) {
                        await res.data.finalInsDefectTabList.map((y, index) => {

                            DefectlistData.push({
                                TNAFinalInspecDefectID: y.tnaFinalInspecDefectID,
                                DefectID: y.defectID,
                                DefectName: y.defectName,
                                FoundArea: y.foundArea,
                                DefectType: y.defectType,
                                CountID: y.countID,
                                DefectMainindex: y.defectMainindex,
                                DefectFindingRemarks: '',
                                ImageList: []
                            })
                            // var uniqueimages = [...new Map(res.data.finalInsDefectTabList.map(item =>
                            //     [item['defectFindingRemarks'], item])).values()];
                            inputText = res.data.finalInsDefectTabList[0].defectFindingRemarks
                            res.data.finalInsDefectSubList.filter(x => x.defectMainindex === y.defectMainindex).map((x, i) => {
                                DefectlistData[index].ImageList.push({
                                    TNAFinalInspecDefectSubID: x.tnaFinalInspecDefectSubID,
                                    DefectMainindex: x.defectMainindex,
                                    DefectChildindex: x.defectChildindex,
                                    UploadedImagePath1: x.uploadedImagePath1,
                                    UploadedImagePath2: x.uploadedImagePath2
                                })
                            })

                        })
                        await res.data.finalInsDefectSummaryTab.map((s, n) => {
                            DefectSummary.push({
                                TNAFinalInspecDefectSummaryID: s.tnaFinalInspecDefectSummaryID,
                                details: s.details,
                                critical: s.critical,
                                major: s.major,
                                minor: s.minor,
                                result: s.result
                            })
                        })
                        setInputFields(DefectlistData);
                        setFindingRemarks(inputText)
                        FindingRemarksCalls(inputText);
                        setSummaryField(DefectSummary);
                        Summarycallback(DefectSummary)
                        //SummaryCalculation(DefectlistData, 0)
                        DefectCallback(DefectlistData);
                        hideLoader();
                    }
                    else {
                        for (let i = 1; i <= 4; i++) {
                            values.push({
                                TNAFinalInspecDefectID: 0,
                                DefectID: 0,
                                DefectName: '',
                                FoundArea: '',
                                DefectType: 0,
                                CountID: 1,
                                DefectMainindex: i,
                                ImageList: [{
                                    TNAFinalInspecDefectSubID: 0,
                                    DefectMainindex: i,
                                    DefectChildindex: i,
                                    UploadedImagePath1: '',
                                    UploadedImagePath2: '',
                                }],
                            })
                        }
                        setInputFields(values);
                        DefectCallback(values);
                        Summarycallback(SummaryField);
                        FindingRemarksCalls(FindingRemarks);
                        hideLoader();
                    }
                })
                //}
            }
        })();
    }, [rerenderdep])

    useEffect(() => {
        (async () => {
            if (props.location.state.params.TaskStatus === 2) {
                showLoader();
                let DefectValue = [];
                let defacttype = [];
                let AqlList = [];


                await FinalinspectionService.GetTNAFinalinspecDefactList(1, 0).then((res) => {
                    if (res.data) {
                        res.data.map((x) => {
                            DefectValue.push({
                                value: x.defectTypeID,
                                label: x.defectName
                            })
                        })
                        setdefectValue(DefectValue);
                    }
                })
                await FinalinspectionService.GetTNAFinalinspecDefactList(2, 0).then((res) => {
                    if (res.data) {
                        res.data.map((x) => {
                           
                            let LabelColor = '';
                            if (x.defectID === 1) {
                                LabelColor = <><span style={{ color: "blue" }}><b>Minor</b></span></>
                            }
                            else if (x.defectID === 2) {
                                LabelColor = <><span style={{ color: "orange" }}><b>Major</b></span></>
                            }
                            else if (x.defectID === 3) {
                                LabelColor = <><span style={{ color: "Red" }}><b>Critical</b></span></>
                            }

                            defacttype.push({
                                value: x.defectID,
                                label: LabelColor,
                                subLabel: x.value,
                            })
                            // defacttype.push({
                            //     value: x.defectID,
                            //     label: x.defectLevel
                            // })
                        })
                    }
                    setdefectType(defacttype);
                })
                var uniqueimages
                let Inputtext = [];
                let Majorlvl = {};
                let Minorlvl = {};
                await AQLList.getAllAqlList(0).then(async (res) => {

                    await FinalinspectionService.GetTNAFinalinspecMainInfo(props.location.state.params.TNAInspectionID, props.location.state.params.details.tnaid).then(async (res1) => {
                        if (res.data) {
                            uniqueimages = parseInt(res1.data.finalInsQuantityInfoList[0].totalOfferedQty)
                        }
                        if (res.data) {
                            await res.data.map((x) => {
                                AqlList.push({
                                    InspectionLevel: x.inspectionLevel,
                                    LotSizeFromm: parseInt(x.lotSizeFromm),
                                    LotSizeTo: parseInt(x.lotSizeTo),
                                    SampleSize: parseInt(x.sampleSize),
                                    AQL: parseInt(x.aql),
                                    Accept: parseInt(x.accept),
                                })
                            })
                            Inputtext = AqlList.filter(x => x.LotSizeFromm <= uniqueimages && x.LotSizeTo >= uniqueimages)
                            Majorlvl = Inputtext.find(x => x.AQL === parseInt(majorminlvl[0].Major))
                            Minorlvl = Inputtext.find(x => x.AQL === parseInt(majorminlvl[0].Minor))
                            Summaryvalues[0].major = Majorlvl === undefined ? 0 : Majorlvl.Accept;
                            Summaryvalues[0].minor = Minorlvl === undefined ? 0 : Minorlvl.Accept;
                        }
                        setSummaryField(Summaryvalues)
                        Summarycallback(Summaryvalues);
                    })
                })


                let DefectlistData = []
                let inputText = '';
                await FinalinspectionService.GetTNAFinalinspecMainInfo(props.location.state.params.TNAInspectionID, props.location.state.params.details.tnaid).then(async (res) => {

                    if (res.data.finalInsDefectTabList.length > 0) {
                        await res.data.finalInsDefectTabList.map((y, index) => {

                            DefectlistData.push({
                                TNAFinalInspecDefectID: y.tnaFinalInspecDefectID,
                                DefectID: y.defectID,
                                DefectName: y.defectName,
                                FoundArea: y.foundArea,
                                DefectType: y.defectType,
                                CountID: y.countID,
                                DefectMainindex: y.defectMainindex,
                                DefectFindingRemarks: '',
                                ImageList: []
                            })
                            // var uniqueimages = [...new Map(res.data.finalInsDefectTabList.map(item =>
                            //     [item['defectFindingRemarks'], item])).values()];
                            inputText = res.data.finalInsDefectTabList[0].defectFindingRemarks
                            res.data.finalInsDefectSubList.filter(x => x.defectMainindex === y.defectMainindex).map((x, i) => {
                                DefectlistData[index].ImageList.push({
                                    TNAFinalInspecDefectSubID: x.tnaFinalInspecDefectSubID,
                                    DefectMainindex: x.defectMainindex,
                                    DefectChildindex: x.defectChildindex,
                                    UploadedImagePath1: x.uploadedImagePath1,
                                    UploadedImagePath2: x.uploadedImagePath2
                                })
                            })

                        })
                        setInputFields(DefectlistData);
                        setFindingRemarks(inputText)
                        FindingRemarksCalls(inputText);
                        SummaryCalculation(DefectlistData, 0)
                        DefectCallback(DefectlistData);
                        hideLoader();
                    } else {
                        for (let i = 1; i <= 4; i++) {
                            values.push({
                                TNAFinalInspecDefectID: 0,
                                DefectID: 0,
                                DefectName: '',
                                FoundArea: '',
                                DefectType: 0,
                                CountID: 1,
                                DefectMainindex: i,
                                ImageList: [{
                                    TNAFinalInspecDefectSubID: 0,
                                    DefectMainindex: i,
                                    DefectChildindex: i,
                                    UploadedImagePath1: '',
                                    UploadedImagePath2: '',
                                }],
                            })
                        }
                        setInputFields(values);
                        DefectCallback(values);
                        Summarycallback(SummaryField);
                        FindingRemarksCalls(FindingRemarks);
                        hideLoader();
                    }

                })

            }
        })();
    }, [rerenderdep])
    // let styles = {
    //     control: styles => ({ ...styles, border: '1px solid red' }),

    // }
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red', innerHeight: '100px', outerHeight: '100px' }),
    }

    function handleClosePopup() {

        setDefectPopup(false)
    }

    function OpenPopup() {
        setDefect([{ Id: 0, Name: "" }])
        setDefectPopup(true)
        DefectService.getAllDefectList().then((response) => {
            if (response.data) {
                setExistingList(response.data);
            }
        });
    }
    function SetreloadDropDown() {
        let DefectValue = [];
        FinalinspectionService.GetTNAFinalinspecDefactList(1, 0).then((res) => {
            if (res.data) {
                res.data.map((x) => {
                    DefectValue.push({
                        value: x.defectTypeID,
                        label: x.defectName
                    })
                })
                setdefectValue(DefectValue);
            }
        })
    }
    const handleInputChange = (index, event) => {

        const values = [...getDefect];
        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }
        values[index].Name = inputText;
        setDefect(values);
    };

    const handleAddFields = (index) => {
        const values = [...getDefect];
        if (values[index].Name.trim() !== '') {
            values.push({ Id: 0, Name: "", });
            setDefect(values);
            setSubmitteds(false);
        }
        else {
            Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
            setSubmitteds(true);
        }
    };

    const handleRemoveFields = index => {
        const values = [...getDefect];
        values.splice(index, 1);
        setDefect(values);
    };

    const SaveDefect = (e) => {

        e.preventDefault();
        if (getDefect.length === 1 && getDefect[0].Name === '') {
            setButtonLoader(false);
            setSubmitteds(true);
            ValidationPopup("Please fill atleast one Defect Name.");
        }
        else {
            for (var i = 0; i < (getDefect.length); i++) {
                if (getDefect[i].Name.trim() === '') {
                    Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
                    setSubmitteds(true);
                    setButtonLoader(false);
                    return false;
                }
            }
            let DefectInfo = getDefect;
            let InputDefectMaster = { Createdby: currentUser.employeeinformationID, DefectInfo: getDefect };

            DefectService.InsertUpdateDefect(InputDefectMaster).then(res => {
                let Func = 'Add';
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    setDefectPopup(false)
                    SetreloadDropDown()
                    getDefect[0].Id = 0
                    getDefect[0].Name = ''
                    //window.location.reload()
                } else if (res.data.outputResult === "2") {
                    Func = 'Edit';

                }
                else if (res.data.outputResult === "0") {
                    setButtonLoader(false);
                    ValidationPopup("Error Occured!");
                }
            })
        }


    }

    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
        return false;
    }
    const handleDefectsExpandRow = (rowNumber) => {
        setDefectsExpandedRows([]);
        let currentExpandedRows;
        let isRowExpanded = false;
        currentExpandedRows = DefectsExpandedRows;
        isRowExpanded = currentExpandedRows.includes(rowNumber);
        let obj = {};
        isRowExpanded ? (obj[rowNumber] = false) : (obj[rowNumber] = true);
        setDefectsState(obj);
        currentExpandedRows = [];
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== rowNumber) :
            currentExpandedRows.concat(rowNumber);
        setDefectsExpandedRows(newExpandedRows);
    }
    const CheckDuplicate = (index) => {

        const values = [...getDefect];
        if (ExistingList) {
            for (var i = 0; i < ExistingList.length; i++) {
                if (ExistingList[i].defectName.trim().toLowerCase() === values[index].Name.trim().toLowerCase()) {
                    values[index].Name = '';
                    setDefect(values);
                    Nodify('Warning!', 'warning', "This Defect is already exist.");
                    return false;
                }
            }
        }
    }
    function handlechangeAdd(e, index) {

        if (values[index].DefectID === 0 || values[index].DefectType === 0) {
            Nodify('Warning!', 'warning', "Please fill mandatory fields");
            setSubmitted(true);
        } else {
            values.push({
                TNAFinalInspecDefectID: 0,
                DefectID: 0,
                DefectName: '',
                FoundArea: '',
                DefectType: 0,
                CountID: 1,
                DefectMainindex: index + 1,
                ImageList: [{
                    TNAFinalInspecDefectSubID: 0,
                    DefectMainindex: index + 1,
                    DefectChildindex: 0,
                    UploadedImagePath1: '',
                    UploadedImagePath2: '',
                }]
            })
        }
        setInputFields(values)
        DefectCallback(values)
    }

    function handleAddFieldsImages(index, childindex) {

        let isvalid = true;
        if (values[index].ImageList[childindex].UploadedImagePath1 === "" || values[index].ImageList[childindex].UploadedImagePath2 === '') {
            isvalid = false;
            Nodify('Warning!', 'warning', 'Please Upload the Image');
        }

        if (isvalid) {
            values[index].ImageList.push({
                TNAFinalInspecDefectSubID: 0, UploadedImagePath1: "", UploadedImagePath2: "", MainIndex: index, DefectChildindex: childindex + 1
            })
        }
        setInputFields(values);
        DefectCallback(values)
    }

    function handleRemoveFieldsImages(index, childindex, length) {
        if (length === 1) {
            //values[index].ImageList[childindex].UploadedImagePath1 = "";
            values[index].ImageList[childindex].UploadedImagePath2 = '';
        } else {
            values[index].ImageList.splice(childindex, 1);
        }
        setInputFields(values);
        DefectCallback(values)
    }

    function ViewFile(event, filename, index) {

        // event.preventDefault();
        setIsOpen(true);
        setImagename(filename);
    }
    function handleRemoveFeilds(e, index) {
        values.splice(index, 1)
        setInputFields(values);
        DefectCallback(values)
        SummaryCalculation(values, index)
    }
    const deleteFile = (index, childindex) => {
        values[index].ImageList[childindex].UploadedImagePath1 = "";
        setInputFields(values);
        DefectCallback(values)
    }

    const deleteFile2 = (index, childindex) => {
        values[index].ImageList[childindex].UploadedImagePath2 = "";
        setInputFields(values);
        DefectCallback(values)
    }

    const ImageCallback = (value, index, childindex, FeildName) => {
        let profile = values !== '' ? "/Style/SKU/" : '';
        if (FeildName === 'Image1') {
            values[index].ImageList[childindex].MainIndex = index;
            values[index].ImageList[childindex].UploadedImagePath1 = profile + value;
        } else {
            values[index].ImageList[childindex].MainIndex = index;
            values[index].ImageList[childindex].UploadedImagePath2 = profile + value;
        }
        setInputFields(values)
        DefectCallback(values)
    }
    function handleCommonResultInputChange(index, event, FeildName) {
       
        var inputText = "";
        if (FeildName === 'DefectValue') {
            if (event !== null) {
                values[index].DefectID = event.value;
                values[index].DefectName = event.label;
            } else {
                values[index].DefectID = 0;
                values[index].DefectName = '';
            }
        }
        else if (FeildName === "FoundArea") {
            if (event.target.value.trim() !== '') {
                inputText = event.target.value;
            }
            values[index].FoundArea = inputText;
        }
        else if (FeildName === 'DefectType') {
            if (event !== null) {
                values[index].DefectType = event.value;
            } else {
                values[index].DefectType = 0;
            }
            SummaryCalculation(values, index)
        }
        else if (FeildName === 'Count') {
            if (event.target.value !== '0' && event.target.value !== '') {
                values[index].CountID = parseInt(event.target.value);
            } else {
                values[index].CountID = 1;
            }
            SummaryCalculation(values, index)
        }
        setInputFields(values);

    }

    function increment(index, e) {

        values[index].CountID += 1;
        setInputFields(values);
        SummaryCalculation(values, index)
    }

    function decrement(index, e) {

        if (values[index].CountID > 1) {
            values[index].CountID -= 1;
        } else {
            values[index].CountID = 1;
        }
        setInputFields(values)
        SummaryCalculation(values, index)
    }

    function SummaryCalculation(values, index) {
       
        let Major = 0;
        let minor = 0;
        let Çritical = 0;
        values.filter(x => x.DefectType === 2).map(x => {
            if (Major === 0) {
                Major = x.CountID;
            } else {
                Major = Major + x.CountID
            }

        })
        values.filter(x => x.DefectType === 1).map(x => {
            if (minor === 0) {
                minor = x.CountID;
            } else {
                minor = minor + x.CountID
            }

        })
        values.filter(x => x.DefectType === 3).map(x => {
            if (Çritical === 0) {
                Çritical = x.CountID;
            } else {
                Çritical = Çritical + x.CountID
            }

        })
        //Summaryvalues[0].TNAFinalInspecDefectSummaryID = Summaryvalues[0].TNAFinalInspecDefectSummaryID;
        //Summaryvalues[1].TNAFinalInspecDefectSummaryID = Summaryvalues[1].TNAFinalInspecDefectSummaryID
        Summaryvalues[1].major = Major;
        Summaryvalues[1].minor = minor;
        Summaryvalues[1].critical = Çritical;
        if (Summaryvalues[1].major <= Summaryvalues[0].major && Summaryvalues[1].minor <= Summaryvalues[0].minor && Summaryvalues[1].critical <= Summaryvalues[0].critical) {
            Summaryvalues[1].result = "Within AQL";
        } else {
            Summaryvalues[1].result = "Beyond AQL";
        }
        setSummaryField(Summaryvalues)
        Summarycallback(Summaryvalues);
    }

    function handleInputQuantitySub(e) {
        let inputText = ""
        if (e.target.value.trim() !== '') {
            inputText = e.target.value;

        }
        setFindingRemarks(inputText);
        FindingRemarksCalls(inputText)
    }
    return (
        <Fragment>
            <div className="page-body">
                <div className="row mt-5">
                    <table className="table table-striped table-bordered table-hover dataTable no-footer">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th style={{ width: '25vw' }}>
                                    <button type='button' className="glyphicon glyphicon-plus AddPlusIconTemplate" style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}
                                        onClick={() => OpenPopup()}
                                        title="Add Size">
                                    </button>Defects<span className="text-danger">*</span>
                                </th>
                                {/* <th>Defects<span className="text-danger">*</span>
                                    &nbsp;&nbsp;&nbsp;&nbsp; <i onClick={OpenPopup} type="button" id="" className="bnt btn-sm btn-warning">
                                        <i className="fa fa-plus"></i></i></th> */}
                                <th style={{ width: '14vw' }}>Found Area</th>
                                <th>Defect Type<span className="text-danger">*</span></th>
                                <th style={{ textAlign: 'center' }}>Count<span className="text-danger">*</span></th>
                                <th style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                inputField.map((comment, index) => (
                                    <Fragment>
                                        <tr key={
                                            index
                                        }>
                                            <td><i
                                                className={
                                                    DefectsExpandState[index] ?
                                                        'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                } onClick={event => handleDefectsExpandRow(index)}
                                            ></i>&nbsp;
                                                {index + 1}</td>
                                            <td><Reactselect className="basic-single" name="supplier"
                                                id={
                                                    "ddlSupplier"
                                                }
                                                isDisabled={props.location.state.params.Action === 'View' ? true : false}
                                                isLoading={false}
                                                isClearable={true}
                                                isSearchable={true}
                                                options={defectValue}
                                                styles={Submitted && comment.DefectID === 0 ? styles : ''}
                                                onChange={(event) => handleCommonResultInputChange(index, event, 'DefectValue')}
                                                value={defectValue.filter(function (option) {
                                                    return option.value === comment.DefectID;
                                                })}
                                            ></Reactselect></td>
                                            <td><input type="text" className="form-control" name={"_txtRemarks"}
                                                id={"_txtRemarks"}
                                                value={comment.FoundArea}
                                                placeholder="Found Area"
                                                autoComplete="off"
                                                disabled={props.location.state.params.Action === 'View' ? true : false}
                                                onChange={(event) => handleCommonResultInputChange(index, event, 'FoundArea')}
                                            />
                                            </td>
                                            <td><Reactselect className="basic-single" name="supplier"
                                                id={
                                                    "ddlSupplier"
                                                }
                                                isDisabled={props.location.state.params.Action === 'View' ? true : false}
                                                isLoading={false}
                                                isClearable={true}
                                                isSearchable={true}
                                                options={defectType}
                                                styles={Submitted && comment.DefectType === 0 ? styles : ''}
                                                onChange={(event) => handleCommonResultInputChange(index, event, 'DefectType')}
                                                value={defectType.filter(function (option) {
                                                    return option.value === comment.DefectType;
                                                })}
                                            ></Reactselect></td>
                                            <td className="adjustwidth" >
                                                {/* <div className="col-md-1" style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}>
                                                    <i type="button" id="txtNumber" className="btn bt-md btn-warning" onClick={(event) => increment(index, event)}><i className="fa fa-plus"></i></i>
                                                </div>
                                                <div className="col-md-5">
                                                    <input type="number" className="form-control" name={"_txtRemarks"}
                                                        id={"_txtRemarks"} min="1"
                                                        value={comment.CountID}
                                                        autoComplete="off"
                                                        disabled={props.location.state.params.Action === 'View' ? true : false}
                                                        onChange={(event) => handleCommonResultInputChange(index, event, 'Count')}
                                                    />
                                                </div>
                                                <div className="col-md-1" style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}>
                                                    <i type="button" id="txtNumber" className="bnt btn-sm btn-danger" onClick={(event) => decrement(index, event)}><i className="fa fa-minus"></i></i>
                                                </div> */}
                                                <td className="PM_CounterTD">
                                                    <div class="PM_quantity">
                                                        <button type="button" class="btn PM_quantity__minus"
                                                            style={{ height: '36px', display: props.location.state.params.Action === 'View' ? 'none' : '' }}
                                                            onClick={(event) => increment(index, event)}>
                                                            <i className="fa fa-plus"></i>
                                                        </button>
                                                        <input type="number" className="form-control PM_quantity__input" name={"_txtRemarks"}
                                                            id={"_txtRemarks"} min="1"
                                                            value={comment.CountID}
                                                            autoComplete="off"
                                                            disabled={props.location.state.params.Action === 'View' ? true : false}
                                                            onChange={(event) => handleCommonResultInputChange(index, event, 'Count')}
                                                        />
                                                        <button type="button" class="btn PM_quantity__plus"
                                                            style={{ height: '36px', display: props.location.state.params.Action === 'View' ? 'none' : '' }}
                                                            onClick={(event) => decrement(index, event)}>
                                                            <i className="fa fa-minus"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </td>

                                            <td style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }} >


                                                {inputField.length === (index + 1) && <button type="button" className="btn btn-sm btn-success" title="Add" onClick={event => handlechangeAdd(event, index)}>
                                                    <i className="fa fa-plus"></i>
                                                </button>}&nbsp;
                                                <button disabled={inputField.length === 1 ? true : false} type="button" className="btn btn-sm btn-danger" title="Delete" onClick={event => handleRemoveFeilds(event, index)}>
                                                    <i className="fa fa-trash"></i>
                                                </button>&nbsp;
                                            </td>
                                        </tr>
                                        <>
                                            {
                                                DefectsExpandedRows.includes(index) ?
                                                    <tr>
                                                        <td colspan="6">
                                                            <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                <tbody>
                                                                    {
                                                                        comment.ImageList.map((child, childindex) => (
                                                                            <Fragment>
                                                                                <tr>
                                                                                    <td className="table-width-50">
                                                                                        <div className="row">
                                                                                            <div className="row">
                                                                                                <div className="text-center">
                                                                                                    <span className="text-danger">Click on the image to upload new</span><br /><br />
                                                                                                    <div style={{ width: "50%", marginLeft: "20rem" }}>
                                                                                                        <FinalinspecUpload UploadCallback={(e) => ImageCallback(e, index, childindex, 'Image1')} PageName='Style/SKU' ShowDiv="1" filePath={child.UploadedImagePath1} />
                                                                                                    </div>
                                                                                                    {child.UploadedImagePath1 !== '' ? <div className="row" style={{ position: "relative", left: "340px", bottom: "155px" }}>
                                                                                                        <div className="col-md-7">
                                                                                                            <i className="btn btn-danger fa fa-trash-o FloatRight" title="Delete Logo" style={{ float: "right", padding: '6px 7px 6px 8px' }}
                                                                                                                onClick={event => deleteFile(index, childindex, "UploadedImagePath1")}></i>
                                                                                                            <i className="btn btn-info fa fa-eye FloatRight" title="View Logo" style={{ marginRight: "6px", float: "right", padding: '6px 6px' }}
                                                                                                                onClick={
                                                                                                                    event => ViewFile(event, child.UploadedImagePath1, index)
                                                                                                                }></i>
                                                                                                        </div>
                                                                                                    </div> : ''}
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                    </td>

                                                                                    <td className="table-width-50">

                                                                                        <div className="row">
                                                                                            <div className="text-center">
                                                                                                <span className="text-danger">Click on the image to upload new</span><br /><br />
                                                                                                <div style={{ width: "50%", marginLeft: "20rem" }}>
                                                                                                    <FinalinspecUpload UploadCallback={(e) => ImageCallback(e, index, childindex, 'Image2')} PageName='Style/SKU' ShowDiv="1" filePath={child.UploadedImagePath2} />
                                                                                                </div>
                                                                                            </div>
                                                                                            {child.UploadedImagePath2 !== '' ? <div className="row">
                                                                                                <div className="col-md-7">
                                                                                                    {/* <i className="btn btn-danger fa fa-trash-o FloatRight" title="Delete Logo"
                                                                                                        onClick={event => deleteFile2(index, childindex, "UploadedImagePath2")}></i>&nbsp; */}

                                                                                                </div>
                                                                                            </div> : ''}
                                                                                        </div>
                                                                                        <span className="TnaFinaspecwrkmans" style={{ left: "500px" }}>
                                                                                            {props.location.state.params.Action !== 'View' ? <button type="button"
                                                                                                style={{ display: inputField[index].ImageList.length < 2 ? '' : 'none' }} className="btn btn-xs btn-success" title="Add Defect Image"
                                                                                                onClick={() => handleAddFieldsImages(index, childindex)}
                                                                                            >
                                                                                                <i className="fa fa-plus"></i>
                                                                                            </button> : ''}
                                                                                            &nbsp;
                                                                                            {child.UploadedImagePath2 !== '' ? <button onClick={event => ViewFile(event, child.UploadedImagePath2, index, childindex)} style={{ padding: "5px 6px", width: "3.5%" }} className="btn btn-info fa fa-eye">
                                                                                                <i title="View Logo" style={{ marginRight: "6px" }}
                                                                                                ></i>
                                                                                            </button> : ''}
                                                                                            &nbsp;
                                                                                            <button type="button"
                                                                                                className="btn btn-xs btn-danger" title="Delete Defect Image"
                                                                                                onClick={() => handleRemoveFieldsImages(index, childindex, inputField[index].ImageList.length)}
                                                                                                style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}
                                                                                            >
                                                                                                <i className="fa fa-trash-o"></i>
                                                                                            </button>
                                                                                        </span>

                                                                                    </td>

                                                                                </tr>
                                                                            </Fragment>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr> : ""
                                            }</>
                                    </Fragment>
                                ))
                            }
                        </tbody>
                    </table>
                    {
                        getDefectPopup === true ? <Modal show={getDefectPopup} size="md" dragable backdrop="static" keyboard={false} onHide={() => handleClosePopup()}>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    Defect Master&nbsp;
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form>
                                    {
                                        getDefect.map((x, index) => (

                                            <div className="row row_left10">
                                                <div className="col-sm-12 divder_style">
                                                    <div className="form-group">
                                                        <span className="input-icon icon-right">
                                                            <input type="text"
                                                                className="form-control"
                                                                id={"Name_" + index}
                                                                name="Name"
                                                                value={x.Name}
                                                                onChange={(event) => handleInputChange(index, event)}
                                                                placeholder="Enter Defect Name"
                                                                maxLength="100"
                                                                onBlur={() => CheckDuplicate(index)}
                                                                style={{ border: submitted && !x.Name ? '1px solid red' : '' }}
                                                            />
                                                        </span>

                                                    </div>

                                                </div>
                                                {/* <div className="col-sm-3 paddingstatic">
                                                    {
                                                        getDefect.length !== 1 && (
                                                            <div className="col-lg-1_own col-xs-12">
                                                                <button type="button" className="btn btn-danger"
                                                                    title="Delete Defect"
                                                                    onClick={() => handleRemoveFields(index)}>
                                                                    <i className="fa fa-trash-o"></i>
                                                                </button>
                                                            </div>
                                                        )
                                                    }

                                                    {
                                                        getDefect.length === (index + 1) && (
                                                            <div className="col-lg-1_own col-xs-12">
                                                                <button type="button" className="btn btn-success" title="Add Defect Name" onClick={() => handleAddFields(index)}>
                                                                    <i className="fa fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        )
                                                    }
                                                </div> */}
                                            </div>
                                        ))
                                    }
                                </form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="success" onClick={SaveDefect} >
                                    {/* onClick={() => AddExFactoryForm()} */}
                                    Save
                                </Button>
                            </Modal.Footer>
                        </Modal> : ''
                    }

                    <br />
                    <h3 className="heading_style_common clr-themegreen mt-5">Summary</h3>
                    <table className="table table-striped table-bordered table-hover dataTable no-footer">
                        <thead>
                            <tr>
                                <th>Details</th>
                                <th>Critical</th>
                                <th>Major</th>
                                <th>Minor</th>
                                <th className="text-center">Result</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                SummaryField.map((summary, index) => (
                                    <Fragment>
                                        <tr key={
                                            summary.index
                                        }>
                                            <td>{summary.details}</td>
                                            <td>{summary.critical}</td>
                                            <td>{summary.major}</td>
                                            <td>{summary.minor}</td>
                                            {index == 0 && <td rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                                {
                                                    SummaryField[1].result === "Within AQL" ?
                                                        <span class="text-success" style={{ fontSize: '20px' }}><b>Within AQL</b></span> :
                                                        SummaryField[1].result === "Beyond AQL" ?
                                                            <span class="text-danger" style={{ fontSize: '20px' }}><b>Beyond AQL</b></span> :
                                                            '-'}</td>}
                                        </tr>

                                    </Fragment>
                                ))
                            }
                        </tbody>
                    </table>

                </div>
                <br />
                <div className="row">
                    <h3 className="heading_style_common clr-themegreen mt-5" >Finding / Remarks</h3>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <textarea name="findingsandremarks" className="col-lg-12" id="txtfindingsandremarks" rows="5" style={{ width: '100%' }}
                            value={FindingRemarks} onChange={event => handleInputQuantitySub(event, 'FindingsRemarks')}></textarea>
                    </div>
                </div>
            </div>
            <div> {
                isOpen && (

                    <Lightbox mainSrc={window.$APIBaseURL + 'Images' + getImagename}
                        alt="bg image"
                        onCloseRequest={
                            () => setIsOpen(false)
                        } />
                )
            } </div>
            {loader}
        </Fragment >
    )
};
export default React.memo(FinalInspectionDefects);