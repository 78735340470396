import React, { useState, useEffect, Fragment } from "react";
import ReactNotification from 'react-notifications-component';
import FabricService from "../../../../services/Master/FabricService";
import Nodify from "../../../Common/ReactNotification"
import SearchRetain from "../../../Common/SearchRetain";
import { useSelector } from "react-redux";

const AddFabric = (props) => {
    const EmptyInputFields = { FabricDetailsID: 0, GroupTypeID: 0, FabricDetailName: '', Content: '', GSMorCounts: '', FabricorYarn: '' };
    const [inputFields, setInputFields] = useState([EmptyInputFields]);
    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    // const [getFabricList, setFabricList] = useState([]);
    const [getGroupTypeList, setGroupTypeList] = useState([]);
    const [ExistingList, setExistingList] = useState([]);
    const values = [...inputFields];



    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    if (menulist !== undefined) {
        activeMenu = menulist.filter(i => i.menuName === "FabricType");
    }
    //end enable menu

    useEffect(() => {
        FabricService.LoadGroupTYpe().then((response) => {
            if (response.data) {
                setGroupTypeList(response.data);
            }
        }).catch(() => { });

        // FabricService.LoadFabricList().then((response) => {
        //     if (response.data) {
        //         setFabricList(response.data);
        //     }
        // }).catch(() => { });

        FabricService.GetFabricList(0, 'F').then((response) => {
            if (response.data) {
                setExistingList(response.data);
            }
        });
    }, []);

    const handleAddFields = (index) => {
        if (values[index].FabricDetailName !== '' && values[index].GroupTypeID !== 0 && values[index].Content !== '') {
            values.push(EmptyInputFields);
            setInputFields(values);
            setSubmitted(false);
        } else {
            Nodify('Warning!', 'warning', 'Please fill this mandatory(*) fields.');
            setSubmitted(true);
        }
    }

    const handleInputChange = (index, event) => {
        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }
        if (event.target.name === "GroupTypeID") {
            values[index].GroupTypeID = parseInt(inputText);
        } else if (event.target.name === "FabricDetailName") {
            values[index].FabricDetailName = inputText;
        } else if (event.target.name === "Content") {
            values[index].Content = inputText;

        } else if (event.target.name === "GSMorCounts") {
            // var x1 = inputText.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})/);
            // inputText = !x1[2] ? x1[1] : x1[1] + x1[2];
            values[index].GSMorCounts = inputText;
        }
        setInputFields(values);
    }

    const handleRemoveFields = index => {
        values.splice(index, 1);
        setInputFields(values);
    }

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/FabricList');
        } else {
            window.location.reload();
        }
    }

    const CheckDuplicate = (index, Field) => {
        if (ExistingList.length !== 0) {
            if (values[index].FabricDetailName !== '' && values[index].GroupTypeID !== 0 && values[index].Content !== '') {
                for (var i = 0; i < ExistingList.length; i++) {
                    let GSMorCounts = values[index].GSMorCounts;
                    if (ExistingList[i].groupTypeID === values[index].GroupTypeID
                        && ExistingList[i].fabricDetailName.trim().toLowerCase() === values[index].FabricDetailName.trim().toLowerCase()
                        && ExistingList[i].content.trim().toLowerCase() === values[index].Content.trim().toLowerCase()
                        && ExistingList[i].gsMorCounts.trim().toLowerCase() === GSMorCounts.trim().toLowerCase()
                    ) {
                        values[index].GroupTypeID = 0;
                        values[index].FabricDetailName = '';
                        values[index].Content = '';
                        values[index].GSMorCounts = '';
                        setInputFields(values);
                        Nodify('Warning!', 'warning', "This fabric name is already exist.");
                        return false;
                    }
                }
            }
        }

        for (var j = 0; j < values.length; j++) {
            if (index !== j) {
                if (values[index].FabricDetailName !== '' && values[index].GroupTypeID !== 0 && values[index].Content !== '') { // for avoid empty validation
                    if (values[j].GroupTypeID === values[index].GroupTypeID
                        && values[j].FabricDetailName.trim().toLowerCase() === values[index].FabricDetailName.trim().toLowerCase()
                        && values[j].Content === values[index].Content
                        && values[j].GSMorCounts === values[index].GSMorCounts
                    ) {
                        values[index].GroupTypeID = 0;
                        values[index].Content = '';
                        values[index].FabricDetailName = '';
                        values[index].GSMorCounts = '';
                        setInputFields(values);
                        Nodify('Warning!', 'warning', 'This fabric name is already exist.');
                        return false;
                    }
                }
            }
        }
    }

    const SaveFabricList = (e) => {
        setButtonLoader(true);
        e.preventDefault();
        if (values.length === 1 && (values[0].GroupTypeID === 0 || values[0].FabricDetailName === '' || values[0].Content === '')) {
            setButtonLoader(false);
            setSubmitted(true);
            ValidationPopup("Please fill atleast one Fabric detail.");
        } else {
            let Isvalid = false;
            for (var j = 0; j < values.length; j++) {
                if (values[j].GroupTypeID === 0 || values[j].FabricDetailName === '' || values[j].Content === '') {
                    Isvalid = true
                }
                values[j].GSMorCounts = values[j].GSMorCounts;
            }
            if (!Isvalid) {
                setSubmitted(false);
                let fabricListParam = {};
                fabricListParam.FabricInformation = values;
                values.map(element => {
                    element.FabricorYarn = "F";
                })
                // fabricListParam.FabricInformation.FabricorYarn = "F";
                fabricListParam.Createdby = 1;
                FabricService.InsertUpdateFabricGrid(fabricListParam).then(res => {
                    var page = "Remove";
                    SearchRetain(page);
                    if (res.data.outputResult === "1") {
                        PageRedirect('Add');
                    } else if (res.data.outputResult === "0") {
                        setButtonLoader(false);
                        ValidationPopup("Error Occured!");
                    }
                });
            } else {
                setButtonLoader(false);
                setSubmitted(true);
                ValidationPopup("Please fill all mandatory(*) fields.");
            }
        }
    }

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/FabricList",
            state: {
                message: Func
            }
        });
    }

    const ValidationPopup = (Msg) => {
        Nodify('Warning!', 'warning', Msg);
        setButtonLoader(false);
        return false;
    }

    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">Add Fabric Details</h3>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <form>
                                    {
                                        inputFields.map((inputField, index) => (
                                            <Fragment key={
                                                `${inputField}~${index}`
                                            }>
                                                <div className="row row_left10">
                                                    <div className="col-sm-3 divder_style">
                                                        <div className="form-group">
                                                            <label htmlFor={
                                                                "GroupTypeID_" + index
                                                            }>Group Type<span className="text-danger">*</span>
                                                            </label>
                                                            <span className="input-icon icon-right">
                                                                <select id={
                                                                    "GroupTypeID_" + index
                                                                }
                                                                    name="GroupTypeID"
                                                                    className="form-select"
                                                                    value={
                                                                        inputField.GroupTypeID
                                                                    }
                                                                    onChange={
                                                                        event => handleInputChange(index, event)
                                                                    }
                                                                    onBlur={() => CheckDuplicate(index, 'GroupTypeID')}
                                                                    style={{ border: submitted && inputField.GroupTypeID === 0 ? '1px solid red' : '' }}
                                                                >
                                                                    <option value='0'>- Select Group Type -</option>
                                                                    {
                                                                        getGroupTypeList.map(item => (
                                                                            <option key={
                                                                                item.id
                                                                            }
                                                                                value={
                                                                                    item.id
                                                                                }>
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-2 divder_style">
                                                        <div className="form-group">
                                                            <label htmlFor={
                                                                "FabricDetailName_" + index
                                                            }>Fabric Name<span className="text-danger">*</span>
                                                            </label>
                                                            <span className="input-icon icon-right">
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"FabricDetailName_" + index}
                                                                    name="FabricDetailName"
                                                                    placeholder="Enter Fabric Name"
                                                                    maxLength="50"
                                                                    value={inputField.FabricDetailName}
                                                                    onChange={event => handleInputChange(index, event)}
                                                                    onBlur={() => CheckDuplicate(index, 'FabricDetailName')}
                                                                    style={{ border: submitted && !inputField.FabricDetailName ? '1px solid red' : '' }}
                                                                    autoFocus
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-2 divder_style">
                                                        <div className="form-group">
                                                            <label htmlFor={
                                                                "Content_" + index
                                                            }>Content<span className="text-danger">*</span>
                                                            </label>
                                                            <span className="input-icon icon-right">
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"Content_" + index}
                                                                    name="Content"
                                                                    placeholder="Enter Content"
                                                                    maxLength="50"
                                                                    value={inputField.Content}
                                                                    onChange={event => handleInputChange(index, event)}
                                                                    onBlur={() => CheckDuplicate(index, 'Content')}
                                                                    style={{ border: submitted && !inputField.Content ? '1px solid red' : '' }}

                                                                />
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-2 divder_style">
                                                        <div className="form-group">
                                                            <label htmlFor={
                                                                "GSM" + index
                                                            }>GSM
                                                            </label>
                                                            <span className="input-icon icon-right">
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"GSM" + index}
                                                                    name="GSMorCounts"
                                                                    placeholder="Enter GSM"
                                                                    maxLength="50"
                                                                    value={inputField.GSMorCounts}
                                                                    onChange={event => handleInputChange(index, event)}
                                                                    onBlur={() => CheckDuplicate(index, 'GSMorCounts')}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-3 paddingstatic">
                                                        {
                                                            inputFields.length !== 1 && (
                                                                <div className="col-lg-1_own col-xs-12">
                                                                    <button type="button" className="btn btn-danger fabric"
                                                                        style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}
                                                                        title="Delete Fabric detail" onClick={() => handleRemoveFields(index)}>
                                                                        <i className="fa fa-trash-o"></i>
                                                                    </button>
                                                                </div>
                                                            )}
                                                        {
                                                            inputFields.length === (index + 1) && (
                                                                <div className="col-lg-1_own col-xs-12">

                                                                    <button type="button" className="btn btn-success fabric" title="Add Fabric detail" onClick={() => handleAddFields(index)}>
                                                                        <i className="fa fa-plus"></i>
                                                                    </button>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </Fragment>
                                        ))
                                    }
                                    <div className="col-sm-12">
                                        <div className="buttons-preview" align="right">
                                            <span className="btn btn-primary"
                                                onClick={
                                                    () => ResetOperation("Back")
                                                }>
                                                <i className="fa fa-arrow-circle-left"></i>
                                                &nbsp;Back</span>
                                            <span className="btn btn-danger"
                                                onClick={
                                                    () => ResetOperation()
                                                }>
                                                <i className="fa fa-times"></i>
                                                &nbsp;Reset</span>
                                            <button type="button" className="btn btn-success"
                                                onClick={SaveFabricList}
                                                disabled={buttonLoader}>
                                                <i className="fa fa-check right"></i>
                                                &nbsp;Save</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AddFabric;