import {
    PANTONE_LOADING,
    RETRIEVE_PANTONE,
    DELETE_PANTONE,
} from "../actions/types";

const initialState = {
    isLoadingPantone: true,
    pantoneList: [],
};

const pantoneReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case PANTONE_LOADING:
            return {
                ...state,
                isLoadingPantone: payload
            };
        case RETRIEVE_PANTONE:
            return { ...state, pantoneList: payload };
        case DELETE_PANTONE:
            const results = state.pantoneList.filter(c => c.pantoneID !== payload.pantoneID);
            return {
                ...state,
                pantoneList: results
            };

        default:
            return state;
    }
};
export default pantoneReducer;