import {
    COMPANY_LOADING,
    RETRIEVE_COMPANY,
    DELETE_COMPANY
} from "../actions/types";

const initialState = {
    isLoadingCompany: true,
    companyList: [],
};

const CompanyReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case COMPANY_LOADING:
            return {
                ...state,
                isLoadingCompany: payload
            };
        case RETRIEVE_COMPANY:
            return { ...state, companyList: payload };
        case DELETE_COMPANY:
            const results = state.companyList.filter(c => c.companyBuyerSupID !== payload.companyBuyerSupID);
            return {
                ...state,
                companyList: results
            };

        default:
            return state;
    }
};
export default CompanyReducer;