import React, { useState, useEffect } from 'react';
import 'react-notifications-component/dist/theme.css';
import Loader from "react-loader-spinner";

const CommonLoader = (props) => {


    useEffect(() => {
        
    }, []);

    return(
        <>
            <Loader type="ThreeDots" color="#b6da94" height={80} width={80} timeout={5000} style={{ textAlign: "center" }} />
        </>
    )

};

export default CommonLoader;