import React, { useState, useEffect, useMemo, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-validation/build/form";
import BrandWiseStandardService from './../../../../services/Master/Brandwisestandard';
import styleService from '../../../../services/Style/styleService';
import Reactselect from 'react-select';
import StyleService from "./../../../../services/Style/styleService";
import DatePicker from "react-datepicker";
import EditBrandStandardFabric from './EditBrandwiseMaster/EditBrandStandardFabric';
import EditBrandStandardYarn from './EditBrandwiseMaster/EditBrandStandardYarn';
import EditBrandStandardGarment from './EditBrandwiseMaster/EditBrandStandardGarment';
import EditBrandStandardStrikeOff from './EditBrandwiseMaster/EditBrandStandardStrikeOff';
import EditBrandStandardTrims from './EditBrandwiseMaster/EditBrandStandardTrims';
import { retrieveGender } from '../../../../actions/gender';
import { retrieveColorDepth } from '../../../../actions/colordepth';
import { retrieveTestField } from '../../../../actions/testfield';
import Nodify from "../../../Common/ReactNotification";
import ReactNotification from 'react-notifications-component';
import SearchRetain from "../../../Common/SearchRetain";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import ConfirmationPopup from "../../../Common/ConfirmationPopup";
import Masterservice from './../../../../services/Master/FabricService';
import { data } from "jquery";
const BrandwiseEdit = (props) => {
    const EmptyInputFields = {
        BrandStandardID: 0
        , BuyerID: 0
        , BrandID: 0
        , InternalRefID: null
        , BuyerVersionDate: ''
        , ReceivedDate: ''
        , EffectFromDate: ''
    };

    const CustomInput = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
            />
        )
    }


    let styles = {
        control: styles => ({ ...styles, border: '1px solid red', innerHeight: '100px', outerHeight: '100px' }),
    }
    let styles1 = {
        control: styles => ({ ...styles, innerHeight: '100px', outerHeight: '100px' }),
    }

    let BuyerListOptions = [] = [];

    const [inputFields, setInputFields] = useState([EmptyInputFields]);
    const [getCurrentBuyerID, setCurrentBuyerID] = useState(null);
    const [getCurrentBrandID, setCurrentBrandID] = useState(null);
    const Value = [...inputFields];
    const [getBuyerOptions, setBuyerOptions] = useState([]);
    const [getBrandOptions, setBrandOptions] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [getFabricTestNames, setFabricTestNames] = useState([]);
    const [getFabricAndGroupDetails, setFabricAndGroupDetails] = useState([]);
    const [getStandardAndGenderDetails, setStandardAndGenderDetails] = useState([]);
    const [getFabricOptions, setFabricOptions] = useState([]);
    const [getStandardOptions, setStandardOptions] = useState([]);
    const [getGenderOptions, setGenderOptions] = useState([]);
    const reducerState = useSelector((state) => state);
    const dispatch = useDispatch();
    const commentsGender = reducerState.gender.genderList;
    const commentsColorDepth = reducerState.ColorDepthReducer.ColorDepthList.item1;
    const commentsTestField = reducerState.testfield.TestFieldNameList.item1;
    const [getColorDepthOptions, setColorDepthOptions] = useState([]);
    const [getFieldOptions, setFieldOptions] = useState([]);
    const [getInternalGridDetails, setInternalGridDetails] = useState([]);
    const [getIsReloadColorDepthOfParent, setIsReloadColorDepthOfParent] = useState({});
    const [getIsReloadTestFieldOfParent, setIsReloadTestFieldOfParent] = useState({});
    const [getIsReloadTestStandardOfParent, gstIsReloadTestStandardOfParent] = useState({});
    const [getFabricTabData, setFabricTabData] = useState([]);
    const [getStrikeOffTabData, setStrikeOffTabData] = useState([]);
    const [getYarnTabData, setYarnTabData] = useState([]);
    const [getGarmentTabData, setGarmentTabData] = useState([]);
    const [getTrimsTabData, setTrimsTabData] = useState([]);
    const [getCurrentDataID, setCurrentDataID] = useState([]);
    const { user: currentUser } = useSelector((state) => state.auth);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [getAllTabData, setAllTabData] = useState([]);
    const [getisParentSaveFailed, setisParentSaveFailed] = useState([]);
    const [getActionMode, setActionMode] = useState(null);
    const [getCurrentTab, setCurrentTab] = useState('FABRIC');
    const [getGarmaentdpData, setGarmaentdpData] = useState([]);
    const [getStrikeoffDpData, setStrikeoffDpData] = useState([]);
    const BuyerBrandDetails = [] = reducerState.auth.UserMenuList.item6;
    const [getPage, setPage] = useState([]);
    const [getID, setID] = useState({ showPopupConfirm: false, Params: {} });
    const [getResultTypeGradeOptions, setResultTypeGradeOptions] = useState([
        { id: 1, value: 1, label: "5", name: "5" }
        , { id: 2, value: 2, label: "4-5", name: "4-5" }
        , { id: 3, value: 3, label: "4", name: "4" }
        , { id: 4, value: 4, label: "3-4", name: "3-4" }
        , { id: 5, value: 5, label: "3", name: "3" }
        , { id: 6, value: 6, label: "2-3", name: "2-3" }
        , { id: 7, value: 7, label: "2", name: "2" }
        , { id: 8, value: 8, label: "1-2", name: "1-2" }
        , { id: 9, value: 9, label: "1", name: "1" }
        , { id: 10, value: 10, label: "0-1", name: "0-1" }
        , { id: 11, value: 11, label: "No Changes", name: "No Changes" }
        , { id: 12, value: 12, label: "Changes Observed", name: "Changes Observed" }
    ]);
    useEffect(() => {
        if (commentsGender != undefined && commentsGender.length != 0) {
            let computedGenderOptions = commentsGender.map((data => {
                data.id = data.genderSizeID;
                data.value = data.genderSizeID;
                data.label = data.genderSizeName;
                data.name = data.genderSizeName;
                return { id: data.id, name: data.name, label: data.label, value: data.value };
            }));
            setGenderOptions(computedGenderOptions);
        }
    }, [commentsGender]);


    useEffect(() => {
        if (commentsColorDepth != undefined && commentsColorDepth.length != 0) {
            let computedColorDepthOptions = commentsColorDepth.map((data => {
                data.id = data.colorDepthID;
                data.value = data.colorDepthID;
                data.label = data.colorDepth;
                data.name = data.colorDepth;
                return { id: data.id, name: data.name, label: data.label, value: data.value };
            }));
            setColorDepthOptions(computedColorDepthOptions);
        }
    }, [commentsColorDepth]);

    useEffect(() => {
        if (commentsTestField != undefined && commentsTestField.length != 0) {
            let computedFieldOptions = commentsTestField.map((data => {
                data.id = data.testNameID;
                data.value = data.testFieldID;
                data.label = data.testFieldIDName;
                data.name = data.testName;
                return { id: data.id, name: data.name, label: data.label, value: data.value };
            }));
            setFieldOptions(computedFieldOptions);
        }
    }, [commentsTestField]);
    useEffect(() => {
        (async () => {
            await Masterservice.LoadMasterbrandWiseTypeList(1, 0, 0).then((res) => {
                if (res.data) {
                    setGarmaentdpData(res.data)
                }
            })

        })();
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (props.location.state?.params) {
                    setActionMode(props.location.state.params.Action);
                    showLoader();

                    const BuyerResponse = await styleService.LoadBuyer(2);
                    if (BuyerResponse.data) {
                        const BuyerListOptions = BuyerResponse.data;
                        const filteredBuyerOptions = BuyerListOptions.filter(o =>
                            BuyerBrandDetails.some(({ buyerId }) => o.id === buyerId)
                        );
                        setBuyerOptions(filteredBuyerOptions);

                        const BrandResponse = await StyleService.LoadBrandThemeStoryEndCustomer(5, props.location.state.params.buyerID);
                        if (BrandResponse.data) {
                            setBrandOptions(BrandResponse.data);

                            const standardResponse = await BrandWiseStandardService.LoadMappedTestsForBrandwiseStandard();
                            if (standardResponse.data) {
                                setStandardOptions(standardResponse.data.item2);

                                const brandWiseStandardRes = await BrandWiseStandardService.LoadBuyerBrandBasedBrandwiseStandard(
                                    props.location.state.params.buyerID,
                                    props.location.state.params.brandID,
                                    props.location.state.params.internalRefID
                                );
                                // Extract and set state for Buyer, Brand, and other details
                                const { item1: standardDetails, item2: fabricTests, item3: fabricGroup, item4: fabricOptions, item5: standardAndGender, item6: internalGrid } = brandWiseStandardRes.data;
                                const firstDetail = standardDetails[0];

                                setCurrentDataID(firstDetail.id);
                                setCurrentBuyerID(firstDetail.buyerID);
                                setCurrentBrandID(firstDetail.brandID);

                                // Use the functional form to update state
                                setInputFields((prev) => {
                                    const updatedValue = {
                                        ...prev[0],
                                        BuyerID: firstDetail.buyerID,
                                        BuyerIDForEdit: BuyerResponse.data.filter(buyer => buyer.id === props.location.state.params.buyerID),
                                        BrandID: firstDetail.brandID,
                                        BrandIDForEdit: BrandResponse.data.filter(brand => brand.id === props.location.state.params.brandID),
                                        InternalRefID: firstDetail.internalRefID,
                                        BuyerVersionDate: firstDetail.buyerVersionAndDate,
                                        ReceivedDate: parseValidDate(firstDetail.receivedOn, 'Received Date'),
                                        EffectFromDate: parseValidDate(firstDetail.effectFromDate, 'Effect From Date'),
                                    };
                                    return [updatedValue];
                                });

                                setFabricTestNames(fabricTests);
                                setFabricOptions(fabricOptions);
                                setStandardAndGenderDetails(standardAndGender);
                                setInternalGridDetails(internalGrid);
                                setFabricAndGroupDetails(fabricGroup);

                                setFabricTabData(createFormattedData(fabricGroup, standardAndGender, internalGrid, fabricTests, 1));
                                setYarnTabData(createFormattedData(fabricGroup, standardAndGender, internalGrid, fabricTests, 2));
                                setGarmentTabData(createFormattedData(fabricGroup, standardAndGender, internalGrid, fabricTests, 3));
                                setStrikeOffTabData(createFormattedData(fabricGroup, standardAndGender, internalGrid, fabricTests, 4));
                                setTrimsTabData(createFormattedData(fabricGroup, standardAndGender, internalGrid, fabricTests, 5));
                            }
                        }

                    }
                }
            } catch (err) {
                console.error(err);
            } finally {
                hideLoader();
            }
        };

        fetchData();
    }, [props.location.state]);

    // Helper functions
    const createFormattedData = (groupData, genderData, internalData, tests, testType) => {
        debugger
        const filteredGroupData = groupData.filter(fabric => fabric.testType === testType);
        const filteredGenderData = genderData.filter(fabric => fabric.testType === testType);
        const filteredInternalData = internalData.filter(fabric => fabric.testTypeID === testType);

        const formattedData = filteredGroupData.map(fabric => ({
            testID: fabric.testID,
            testType: fabric.testType,
            parentFields: [{
                ID: fabric.id,
                TestType: parseInt(fabric.testType),
                TestID: fabric.testID,
                CreatedOn: fabric.createdOn === '' ? new Date() : parseValidDate(fabric.createdOn, 'Created On') || 'No Date Available',
                Group: fabric.group,
                FabricDetails: fabric.fabricDetails,
                FabricIndexID: fabric.fabricIndexID,
                Parentindex: fabric.parentIndex,
                StandardResultRequirement: filteredGenderData.filter(gender => gender.fabricIndexID === fabric.fabricIndexID).map(gender => ({
                    ID: gender.id,
                    TestID: gender.testID,
                    CreatedOn: gender.createdOn === '' ? new Date() : parseValidDate(gender.createdOn),
                    EffectFromDate: gender.effectFromDate === '' ? '' : parseValidDate(gender.effectFromDate),
                    StandardID: gender.standard,
                    GenderID: gender.genderID,
                    FabricDetailID: gender.fabricDetailID,
                    FabricIndexID: gender.fabricIndexID,
                    Parentindex: gender.parentIndex,
                    StandardIndexID: gender.standardIndexID,
                    GenderIndexID: gender.genderIndexID,
                    TestType: parseInt(gender.testType),
                    InternalGrid: filteredInternalData.filter(internal =>
                        internal.testID === gender.testID &&
                        // internal.fabricDetailID === gender.fabricDetailID &&  // Commented as per your request
                        internal.fabricIndexID === gender.fabricIndexID &&
                        internal.standardIndexID === gender.standardIndexID
                    ).map(internal => ({
                        ID: internal.id,
                        FabricDetailID: internal.fabricDetailID,
                        StandardID: internal.standardID,
                        TestID: internal.testID,
                        ColorDepth: internal.colorDepth,
                        Field: internal.field,
                        ResultType: internal.resultType,
                        MinResult: internal.minResult.toString(),
                        MaxResult: internal.maxResult.toString(),
                        MinResultForEdit: internal.resultType === 3
                            ? getResultTypeGradeOptions.filter(option => option.id === parseInt(internal.minResult))
                            : [],
                        FabricIndexID: internal.fabricIndexID,
                        Parentindex: internal.parentIndex,
                        StandardIndexID: internal.standardIndexID,
                        GenderIndexID: internal.genderIndexID,
                        InternalIndexID: internal.internalIndexID,
                        TestTypeID: internal.testTypeID
                    }))
                }))

            }]
        }));

        const unmappedData = tests.filter(test => parseInt(test.testType) === testType && test.isMapped === 0);
        const unmappedFormattedData = unmappedData.map((garment, index) => ({
            testID: garment.testID,
            testType: garment.testType,
            testName: garment.testName,
            parentFields: [{
                ID: 0,
                TestType: testType,
                TestID: garment.testID,
                CreatedOn: "",
                Group: "",
                FabricDetails: "",
                FabricIndexID: index,
                Parentindex: 0,
                StandardResultRequirement: [{
                    ID: 0,
                    TestID: garment.testID,
                    CreatedOn: new Date(),
                    EffectFromDate: "",
                    StandardID: "",
                    GenderID: "",
                    FabricDetailID: 0,
                    FabricIndexID: index,
                    StandardIndexID: 0,
                    GenderIndexID: 0,
                    Parentindex: 0,
                    TestType: testType,
                    InternalGrid: [{
                        ID: 0,
                        FabricDetailID: 0,
                        StandardID: "",
                        TestID: garment.testID,
                        ColorDepth: "",
                        Field: "",
                        ResultType: "",
                        MinResult: "",
                        MinResultForEdit: [],
                        MaxResult: "",
                        FabricIndexID: index,
                        StandardIndexID: 0,
                        GenderIndexID: "",
                        InternalIndexID: 0,
                        Parentindex: 0,
                        TestTypeID: testType
                    }]
                }]
            }]
        }));

        return [...formattedData, ...unmappedFormattedData];
    };

    const parseValidDate = (dateValue, fieldName = '') => {
        // If the dateValue is null, undefined, or empty, log a warning and return null
        if (!dateValue || dateValue === '') {
            console.warn(`Date is null, empty, or invalid for field: ${fieldName}`);
            return null; // Return null for undefined, null, or empty string values
        }

        // Try parsing the date
        const parsedDate = new Date(dateValue);

        // Check if the parsed date is valid
        if (isNaN(parsedDate.getTime())) {
            console.warn(`Invalid Date format for field: ${fieldName}, value: ${dateValue}`);
            return null; // Return null if the date is invalid
        }

        // If valid, return the parsed Date object
        return parsedDate;
    };

    const formatDates = (data) => data.map(item => ({
        ...item,
        createdOn: new Date(item.createdOn),
        effectFromDate: new Date(item.effectFromDate)
    }));

    useEffect(() => {
        let GenderSizeID = 0;
        dispatch(retrieveGender(GenderSizeID));
        dispatch(retrieveColorDepth());
        dispatch(retrieveTestField());
    }, [dispatch])

    useEffect(() => {
        dispatch(retrieveColorDepth());
    }, [dispatch, getIsReloadColorDepthOfParent]);

    useEffect(() => {
        dispatch(retrieveTestField());
    }, [dispatch, getIsReloadTestFieldOfParent]);

    useEffect(() => {
        BrandWiseStandardService.LoadMappedTestsForBrandwiseStandard().then((response) => {
            if (response.data) {
                setStandardOptions((response.data.item2));
            }
        }
        )
    }, [dispatch, getIsReloadTestStandardOfParent]);

    const handleChangeDate = (index, e, FieldName) => {
        let Value = [...inputFields];
        if (FieldName === "ReceivedDate") {
            if (e !== null) {
                Value[0].ReceivedDate = e;
            }
            else {
                Value[0].ReceivedDate = null;
            }
            Value[0].ReceivedDate = e;
        }
        if (FieldName === "EffectFromDate") {
            if (e !== null) {
                Value[0].EffectFromDate = e;
            }
            else {
                Value[0].EffectFromDate = null;
            }
            Value[0].EffectFromDate = e;
        }
        setInputFields(Value);
    }

    const ParentStatusUpdationCallBack = (testNameID, statusID) => {
        let Values = [...getFabricTestNames];
        Values.forEach(element => {
            if (element.testID == testNameID) {
                element.status = statusID
            }
        });
        // setFabricTestNames(Values);
    }

    const AddedColorDepthData = (isColorValueUpdated) => {
        setIsReloadColorDepthOfParent(isColorValueUpdated);
    }

    const AddedTestFieldData = (isFieldValueUpdated) => {
        setIsReloadTestFieldOfParent(isFieldValueUpdated);
    }

    const AddedTestStandardData = (isFieldValueUpdated) => {
        gstIsReloadTestStandardOfParent(isFieldValueUpdated);
    }

    const SubmitFabricDataCallBack = (value) => {
        setFabricTabData(value);
    }

    const SubmitYarnDataCallBack = (value) => {
        let FabricDetails = value.filter(data => {
            if (data.testType == 1) {
                return data.parentFields;
            }
        })
        setYarnTabData(value);
    }

    const SubmitGarmentDataCallBack = (value) => {
        setGarmentTabData(value);
    }

    const SubmitStrikeOffDataCallBack = (value) => {
        setStrikeOffTabData(value);
    }

    const SubmitTrimsDataCallBack = (value) => {
        setTrimsTabData(value);
    }

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/BrandWiseStandardList')
        } else {
            window.location.reload();
        }
    }

    const handleTextBoxChange = (e) => {
        let Values = [...inputFields];
        let inputText = '';
        if (e.target.value.trim() !== '') {
            inputText = e.target.value;
        }
        Value[0].BuyerVersionDate = inputText;
        setInputFields(Values);
    }



    const validateFieldsByTab = (activeTab, CheckMandoryFields) => {
        let tabData, tabName;
        // Define the testType for each tab
        switch (activeTab) {
            case 'FABRIC':
                tabData = CheckMandoryFields.filter(data => data.testType.toString() === "1");
                tabName = 'FABRIC';
                break;
            case 'YARN':
                tabData = CheckMandoryFields.filter(data => data.testType.toString() === "2");
                tabName = 'YARN';
                break;
            case 'GARMENT':
                tabData = CheckMandoryFields.filter(data => data.testType.toString() === "3");
                tabName = 'GARMENT';
                break;
            case 'STRIKE OFF':
                tabData = CheckMandoryFields.filter(data => data.testType.toString() === "4");
                tabName = 'STRIKE OFF';
                break;
            case 'TRIMS':
                tabData = CheckMandoryFields.filter(data => data.testType.toString() === "5");
                tabName = 'TRIMS';
                break;
            default:
                return true; // If no active tab, assume valid
        }

        // If there's no data for the active tab, return true (no validation needed)
        if (!tabData || tabData.length === 0) {
            return true;
        }

        // Iterate through the data and validate fields for the current tab
        for (let l = 0; l < tabData.length; l++) {
            for (let i = 0; i < tabData[l].parentFields.length; i++) {
                const fabricDetails = tabData[l].parentFields;
                const standardDetails = fabricDetails[i].StandardResultRequirement;

                // Check mandatory fields in parent fields
                if (!fabricDetails[i].Group || !fabricDetails[i].CreatedOn || !fabricDetails[i].FabricDetails) {
                    Nodify('Warning!', 'warning', `Please fill the mandatory(*) fields in ${tabName} tab - ${tabData[l].testName}.`);
                    return false;
                }

                // Check mandatory fields in standard result requirements
                for (let j = 0; j < standardDetails.length; j++) {
                    const internalDetails = standardDetails[j].InternalGrid;

                    if (!standardDetails[j].CreatedOn || !standardDetails[j].EffectFromDate || !standardDetails[j].GenderID || !standardDetails[j].StandardID) {
                        Nodify('Warning!', 'warning', `Please fill the mandatory(*) fields in ${tabName} tab - ${tabData[l].testName}.`);
                        return false;
                    }

                    // Check mandatory fields in internal grid
                    for (let k = 0; k < internalDetails.length; k++) {
                        if (!internalDetails[k].ColorDepth || !internalDetails[k].Field || !internalDetails[k].ResultType || !internalDetails[k].MinResult ||
                            ((internalDetails[k].ResultType !== 3 && internalDetails[k].ResultType !== 4) && !internalDetails[k].MaxResult)) {
                            Nodify('Warning!', 'warning', `Please fill the mandatory(*) fields in ${tabName} tab - ${tabData[l].testName}.`);
                            return false;
                        }

                    }
                }
            }
        }
        return true;
    };

    const UpdateBrandWiseStandardMaster = (e) => {
        debugger
        setSubmitted(true);
        setButtonLoader(true);
        showLoader();

        // Step 1: Validate common fields
        if (!inputFields[0].BuyerVersionDate || !inputFields[0].ReceivedDate || !inputFields[0].EffectFromDate) {
            Nodify('Warning!', 'warning', 'Please fill all mandatory(*) fields.');
            hideLoader();
            setButtonLoader(false);
            return false;
        }

        // Step 2: Validate only the active tab

        let CheckMandoryFields = [];
        switch (getCurrentTab) {
            case 'FABRIC':
                CheckMandoryFields = [...getFabricTabData];
                break;
            case 'YARN':
                CheckMandoryFields = [...getYarnTabData];
                break;
            case 'GARMENT':
                CheckMandoryFields = [...getGarmentTabData];
                break;
            case 'STRIKE OFF':
                CheckMandoryFields = [...getStrikeOffTabData];
                break;
            case 'TRIMS':
                CheckMandoryFields = [...getTrimsTabData];
                break;
            default:
                break;
        }
        if (!validateFieldsByTab(getCurrentTab, CheckMandoryFields)) {
            hideLoader();
            setButtonLoader(false);
            setisParentSaveFailed(true);
            return false;
        }
        // Step 3: Gather the necessary data for the active tab and update
        let fabricsData = [];
        let genderData = [];
        let internalData = [];
        let getAllTabData = [];

        if (getFabricTabData.length > 0) {
            Array.prototype.push.apply(getAllTabData, getFabricTabData); // Retain previously saved FABRIC data
        }
        if (getYarnTabData.length > 0) {
            Array.prototype.push.apply(getAllTabData, getYarnTabData); // Retain previously saved YARN data
        }
        if (getGarmentTabData.length > 0) {
            Array.prototype.push.apply(getAllTabData, getGarmentTabData); // Retain previously saved GARMENT data
        }
        if (getStrikeOffTabData.length > 0) {
            Array.prototype.push.apply(getAllTabData, getStrikeOffTabData); // Retain previously saved STRIKE OFF data
        }
        if (getTrimsTabData.length > 0) {
            Array.prototype.push.apply(getAllTabData, getTrimsTabData); // Retain previously saved TRIMS data
        }

        collectFabricData(getAllTabData, fabricsData, genderData, internalData);

        let ReceivedOn = new Date(inputFields[0].ReceivedDate);
        ReceivedOn.setMinutes(ReceivedOn.getMinutes() + 370);
        let EffectFromDate = new Date(inputFields[0].EffectFromDate);
        EffectFromDate.setMinutes(EffectFromDate.getMinutes() + 370);

        // Prepare parameters for the API request
        let Params = {
            StandardMaster: {
                ID: getCurrentDataID,
                BuyerID: getCurrentBuyerID,
                BrandID: getCurrentBrandID,
                BuyerVersionAndDate: inputFields[0].BuyerVersionDate,
                ReceivedOn: ReceivedOn,
                EffectFromDate: EffectFromDate,
                ModifiedBy: currentUser.employeeinformationID
            },
            UpdateBrandwiseStandardFabricDetails: fabricsData,
            UpdateBrandwiseStandardGenderDetails: genderData,
            UpdateBrandwiseStandardInternalDetails: internalData
        };

        // API call to update
        BrandWiseStandardService.UpdateBrandwiseStandardMaster(Params).then((res) => {
            hideLoader();
            setSubmitted(false);
            var page = "Remove";
            let params = { tabPage: "BrandwiseEdit", Func: "Edit" };
            // setRedirectConfirmation({ showPopupConfirm: true, Params: params });
            SearchRetain(page);
            let Func = 'Add';
            res.data.tabPage = getPage;
            if (res.data.outputResult === "1") {
                Func = 'Add';
                setID({ showPopupConfirm: true, Params: params, });
                PageRedirect(Func);
            } else if (res.data.outputResult === "2") {
                Func = 'Edit';
                setID({ showPopupConfirm: true, Params: params });
                PageRedirect(Func);
            }
            res.data.Func = Func;
            setButtonLoader(false);

        }).catch((err) => {
            setSubmitted(false);
            hideLoader();
            setButtonLoader(false);
        });
        setSubmitted(false);
        hideLoader();
        setButtonLoader(false);

    };


    //Data collection functions for each tab (this example is for fabric, you can replicate for others)
    const collectFabricData = (tabData, fabricsData, genderData, internalData) => {
        const defaultDate = new Date('1970-01-01T19:10:00');


        // Create a set to track unique InternalGrid IDs
        const internalDataSet = new Set();
        const genderDataSet = new Set();

        for (let m = 0; m < tabData.length; m++) {
            for (let i = 0; i < tabData[m].parentFields.length; i++) {
                fabricsData.push({
                    ID: tabData[m].parentFields[i].ID,
                    TestType: parseInt(tabData[m].testType),
                    TestID: tabData[m].testID,
                    CreatedOn: tabData[m].parentFields[i].CreatedOn || null,
                    Group: tabData[m].parentFields[i].Group,
                    FabricDetails: tabData[m].parentFields[i].FabricDetails,
                    FabricIndexID: tabData[m].parentFields[i].FabricIndexID,
                    ParentIndex: tabData[m].parentFields[i].Parentindex,
                });

                for (let j = 0; j < tabData[m].parentFields[i].StandardResultRequirement.length; j++) {
                    const genderDataItem = tabData[m].parentFields[i].StandardResultRequirement[j];
                    // Check if this ID has already been added to genderData
                    if (genderDataItem.ID === 0 || (!genderDataSet.has(genderDataItem.ID) && genderDataItem.ID > 0)) {
                        genderData.push({
                            ID: tabData[m].parentFields[i].StandardResultRequirement[j].ID || 0,
                            TestID: tabData[m].parentFields[i].StandardResultRequirement[j].TestID,
                            CreatedOn: tabData[m].parentFields[i].StandardResultRequirement[j].CreatedOn,
                            EffectFromDate: tabData[m].parentFields[i].StandardResultRequirement[j].EffectFromDate || null,
                            Standard: tabData[m].parentFields[i].StandardResultRequirement[j].StandardID || '',
                            GenderID: tabData[m].parentFields[i].StandardResultRequirement[j].GenderID || '',
                            FabricDetailID: tabData[m].parentFields[i].StandardResultRequirement[j].FabricDetailID,
                            FabricIndexID: tabData[m].parentFields[i].StandardResultRequirement[j].FabricIndexID,
                            StandardIndexID: tabData[m].parentFields[i].StandardResultRequirement[j].StandardIndexID,
                            TestType: tabData[m].parentFields[i].StandardResultRequirement[j].TestType,
                            ParentIndex: tabData[m].parentFields[i].StandardResultRequirement[j].Parentindex
                        });
                        genderDataSet.add(genderDataItem.ID);
                    }

                    for (let k = 0; k < tabData[m].parentFields[i].StandardResultRequirement[j].InternalGrid.length; k++) {
                        const internalGridItem = tabData[m].parentFields[i].StandardResultRequirement[j].InternalGrid[k];

                        // Check if this ID has already been added
                        if (internalGridItem.ID === 0 || (!internalDataSet.has(internalGridItem.ID) && internalGridItem.ID > 0)) {
                            internalData.push({
                                ID: internalGridItem.ID || 0,
                                FabricDetailID: internalGridItem.FabricDetailID,
                                StandardID: internalGridItem.StandardID || 0,
                                TestID: internalGridItem.TestID,
                                ColorDepth: internalGridItem.ColorDepth || '',
                                Field: internalGridItem.Field || '',
                                ResultType: internalGridItem.ResultType || 0,
                                MinResult: internalGridItem.MinResult.toString(),
                                MaxResult: internalGridItem.MaxResult.toString(),
                                FabricIndexID: tabData[m].parentFields[i].StandardResultRequirement[j].FabricIndexID,
                                StandardIndexID: tabData[m].parentFields[i].StandardResultRequirement[j].StandardIndexID,
                                GenderIndexID: tabData[m].parentFields[i].StandardResultRequirement[j].GenderIndexID || 0,
                                InternalIndexID: parseInt(internalGridItem.InternalIndexID),
                                TestTypeID: internalGridItem.TestTypeID,
                                ParentIndex: internalGridItem.Parentindex
                            });

                            // Add the ID to the set to prevent duplicates
                            internalDataSet.add(internalGridItem.ID);
                        }
                    }
                }
            }
        }
    };

    const Delcallback = (value) => {
        if (value === true) {
            setID({ showPopupConfirm: false, Params: [] });

        }
    }

    const redirectionConfirmation = (value) => {
        window.location.reload();
    }

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/BrandWiseStandardList",
            state: {
                message: Func,
            },
        }
        );
    };


    const TabRedirection = (tab) => {
        setCurrentTab(tab);
    }
    // useEffect(() => {
    // }, [getFabricTabData && getFabricTabData.length > 0]);

    // useEffect(() => {
    // }, [getYarnTabData && getYarnTabData.length > 0]);

    // useEffect(() => {
    // }, [getGarmentTabData && getGarmentTabData.length > 0]);

    // useEffect(() => {
    // }, [getStrikeOffTabData && getStrikeOffTabData.length > 0]);

    // useEffect(() => {
    // }, [getTrimsTabData && getTrimsTabData.length > 0]);

    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <div className="widget flat radius-bordered">
                            <div className="widget-header heading_box_style">
                                <h3 className="heading_style_common"> {getActionMode} Brand Wise Standard</h3>
                                <div className="buttons-preview" align="right">
                                    <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                        <i className="fa fa-arrow-circle-left"></i>
                                        &nbsp;Back
                                    </span>
                                    {getActionMode != "View" && <><span className="btn btn-danger" onClick={() => ResetOperation()}>
                                        <i className="fa fa-times"></i>
                                        &nbsp;Reset
                                    </span>
                                        <button type="button" className="btn btn-success" onClick={UpdateBrandWiseStandardMaster} disabled={buttonLoader}>
                                            <i className="fa fa-check right"></i>&nbsp;
                                            Update
                                        </button></>}
                                </div>
                            </div>
                            <div className="widget-body">
                                <div id="registration-form">
                                    <Form>
                                        {
                                            inputFields.map((inputField, index) => (
                                                <>
                                                    <div className="row row_left10">
                                                        <div className="col-md-2 divder_style">
                                                            <div className="form-group">
                                                                <label>Buyer<span className="text-danger">*</span>
                                                                </label>
                                                                <Reactselect className="basic-single" name="BuyerID"
                                                                    id={
                                                                        "BuyerID"
                                                                    }
                                                                    isDisabled={true}
                                                                    isLoading={false}
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    value={inputField.BuyerIDForEdit}
                                                                    options={getBuyerOptions}
                                                                    autoFocus
                                                                    styles={submitted && inputField.BuyerID === 0 ? styles : styles1}

                                                                ></Reactselect>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2 divder_style">
                                                            <div className="form-group">
                                                                <label>Brand<span className="text-danger">*</span>
                                                                </label>
                                                                <Reactselect className="basic-single" name="BuyerID"
                                                                    id={
                                                                        "BrandID"
                                                                    }
                                                                    isDisabled={true}
                                                                    isLoading={false}
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    // onChange={handleBrandChange}
                                                                    value={inputField.BrandIDForEdit}
                                                                    options={getBrandOptions}
                                                                    styles={submitted && inputField.BrandID === 0 ? styles : styles1}

                                                                ></Reactselect>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2 divder_style">
                                                            <div className="form-group">
                                                                <label>Internal Ref No.<span className="text-danger">*</span>
                                                                </label>
                                                                <span className="input-icon icon-right">
                                                                    <input type='test' className='form-control' name="buyerversiondate"
                                                                        id="buyerversiondate" disabled={true}
                                                                        autoComplete="off" maxLength="200" value={inputField.InternalRefID} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2 divder_style">
                                                            <div className="form-group">
                                                                <label>Buyer Version and Date<span className="text-danger">*</span>
                                                                </label>
                                                                <span className="input-icon icon-right">
                                                                    <input type='test' className='form-control' name="buyerversiondate"
                                                                        style={{ border: submitted && !inputField.BuyerVersionDate ? '1px solid red' : '' }}
                                                                        id="buyerversiondate" disabled={false} onChange={handleTextBoxChange}
                                                                        autoComplete="off" maxLength="200" value={inputField.BuyerVersionDate} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2 divder_style">
                                                            <div className="form-group">
                                                                <label>Received Date<span className="text-danger">*</span>
                                                                </label>
                                                                <span className="input-icon icon-right"
                                                                    style={{ border: submitted && !inputField.ReceivedDate ? '1px solid red' : '' }}>
                                                                    <DatePicker className="form-control" name="ReceivedDate"
                                                                        id={
                                                                            "ReceivedDate"
                                                                        }
                                                                        selected={
                                                                            inputField.ReceivedDate
                                                                        }
                                                                        onChange={
                                                                            event => handleChangeDate(index, event, "ReceivedDate")
                                                                        }
                                                                        dateFormat="dd/MM/yyyy"
                                                                        peekNextMonth
                                                                        dropdownMode="scroll"
                                                                        autoComplete="off"
                                                                        customInput={<CustomInput />}
                                                                        isClearable={inputField.ReceivedDate === null ? false : true}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2 divder_style">
                                                            <div className="form-group">
                                                                <label>Effect From Date<span className="text-danger">*</span>
                                                                </label>
                                                                <span className="input-icon icon-right"
                                                                    style={{ border: submitted && !inputField.EffectFromDate ? '1px solid red' : '' }}>
                                                                    <DatePicker className="form-control" name="ReceivedDate"
                                                                        id={
                                                                            "EffectFromDate"
                                                                        }
                                                                        selected={
                                                                            inputField.EffectFromDate
                                                                        }
                                                                        onChange={
                                                                            event => handleChangeDate(index, event, "EffectFromDate")
                                                                        }
                                                                        dateFormat="dd/MM/yyyy"
                                                                        peekNextMonth
                                                                        dropdownMode="scroll"
                                                                        autoComplete="off"
                                                                        customInput={<CustomInput />}
                                                                        isClearable={inputField.EffectFromDate === null ? false : true}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ))
                                        }
                                    </Form>
                                    <div className='col-md-12' style={{ marginTop: '15px' }}>
                                        <div className="tabbable" style={{ width: "100%" }}>
                                            <ul className="nav nav-tabs nav-justified" id="myTab5" >
                                                <li className="active" id="liFabric" style={{ zIndex: '0' }}>
                                                    <a data-toggle="tab" href="#idFabric" id="aidfabric" onClick={() => TabRedirection('FABRIC')}>
                                                        <h5>FABRIC</h5>
                                                    </a>
                                                </li>
                                                <li className="" id="liYarn" >
                                                    <a data-toggle="tab" href="#idYarn" id="aidyarn" style={{ zIndex: '0' }} onClick={() => TabRedirection('YARN')}>
                                                        <h5>YARN</h5>
                                                    </a>
                                                </li>

                                                <li className="" id="liGarment">
                                                    <a data-toggle="tab" href="#idGarment" id="aidgarment" style={{ zIndex: '0' }} onClick={() => TabRedirection('GARMENT')}>
                                                        <h5>GARMENT</h5>
                                                    </a>
                                                </li>
                                                <li className="" id="liStikrOff">
                                                    <a data-toggle="tab" href="#idStrikeOff" id="aidstrikeoff" style={{ zIndex: '0' }} onClick={() => TabRedirection('STRIKE OFF')}>
                                                        <h5>STRIKE OFF</h5>
                                                    </a>
                                                </li>
                                                <li className="" id="liTrims">
                                                    <a data-toggle="tab" href="#idTrims" id="aidtrims" style={{ zIndex: '0' }} onClick={() => TabRedirection('TRIMS')}>
                                                        <h5>TRIMS</h5>
                                                    </a>
                                                </li>
                                            </ul>
                                            <div className="tab-content Dashboard" style={{ padding: "0px", marginBottom: '20px' }}>
                                                <div id="idFabric" className="active tab-pane col-lg-12" style={{ padding: "0px" }} >
                                                    <EditBrandStandardFabric props={props} StandardOptions={getStandardOptions}
                                                        GenderOptions={getGenderOptions} ColorDepthOptions={getColorDepthOptions}
                                                        FieldOptions={getFieldOptions}
                                                        FabricTestNameList={getFabricTestNames}
                                                        FabricOptions={getFabricOptions} FabricAndGroupDetails={getFabricAndGroupDetails}
                                                        StandardAndGenderDetails={getStandardAndGenderDetails} InteralGridDetails={getInternalGridDetails}
                                                        StatusUpdationCallBack={ParentStatusUpdationCallBack} ColorDepthParentCall={AddedColorDepthData}
                                                        TestFieldParentCall={AddedTestFieldData} BuyerID={getCurrentBuyerID} BrandID={getCurrentBrandID}
                                                        SubmitFabricDataCallBack={SubmitFabricDataCallBack} TestStandardParentCall={AddedTestStandardData}
                                                        IsSaveFailed={getisParentSaveFailed} ActionMode={getActionMode}
                                                    ></EditBrandStandardFabric>
                                                </div>
                                                <div id="idYarn" className="tab-pane col-lg-12" style={{ padding: "0px" }} >
                                                    <EditBrandStandardYarn props={props} StandardOptions={getStandardOptions}
                                                        GenderOptions={getGenderOptions} ColorDepthOptions={getColorDepthOptions}
                                                        FieldOptions={getFieldOptions}
                                                        YarnTestNameList={getFabricTestNames}
                                                        FabricOptions={getFabricOptions} FabricAndGroupDetails={getFabricAndGroupDetails}
                                                        StandardAndGenderDetails={getStandardAndGenderDetails} InteralGridDetails={getInternalGridDetails}
                                                        StatusUpdationCallBack={ParentStatusUpdationCallBack} ColorDepthParentCall={AddedColorDepthData}
                                                        TestFieldParentCall={AddedTestFieldData} BuyerID={getCurrentBuyerID} BrandID={getCurrentBrandID}
                                                        SubmitYarnDataCallBack={SubmitYarnDataCallBack} TestStandardParentCall={AddedTestStandardData}
                                                        IsSaveFailed={getisParentSaveFailed} ActionMode={getActionMode}></EditBrandStandardYarn>
                                                </div>
                                                <div id="idGarment" className="tab-pane col-lg-12" style={{ padding: "0px" }} >
                                                    <EditBrandStandardGarment props={props} StandardOptions={getStandardOptions}
                                                        GenderOptions={getGenderOptions} ColorDepthOptions={getColorDepthOptions}
                                                        FieldOptions={getFieldOptions}
                                                        GarmentTestNameList={getFabricTestNames}
                                                        FabricOptions={getGarmaentdpData} FabricAndGroupDetails={getFabricAndGroupDetails}
                                                        StandardAndGenderDetails={getStandardAndGenderDetails} InteralGridDetails={getInternalGridDetails}
                                                        StatusUpdationCallBack={ParentStatusUpdationCallBack} ColorDepthParentCall={AddedColorDepthData}
                                                        TestFieldParentCall={AddedTestFieldData} BuyerID={getCurrentBuyerID} BrandID={getCurrentBrandID}
                                                        SubmitGarmentDataCallBack={SubmitGarmentDataCallBack} TestStandardParentCall={AddedTestStandardData}
                                                        IsSaveFailed={getisParentSaveFailed} ActionMode={getActionMode}></EditBrandStandardGarment>
                                                </div>
                                                <div id="idStrikeOff" className="tab-pane col-lg-12" style={{ padding: "0px" }} >
                                                    <EditBrandStandardStrikeOff props={props} StandardOptions={getStandardOptions}
                                                        GenderOptions={getGenderOptions} ColorDepthOptions={getColorDepthOptions}
                                                        FieldOptions={getFieldOptions}
                                                        StrikeOffTestNameList={getFabricTestNames}
                                                        FabricOptions={getStrikeoffDpData} FabricAndGroupDetails={getFabricAndGroupDetails}
                                                        StandardAndGenderDetails={getStandardAndGenderDetails} InteralGridDetails={getInternalGridDetails}
                                                        StatusUpdationCallBack={ParentStatusUpdationCallBack} ColorDepthParentCall={AddedColorDepthData}
                                                        TestFieldParentCall={AddedTestFieldData} BuyerID={getCurrentBuyerID} BrandID={getCurrentBrandID}
                                                        SubmitStrikeOffDataCallBack={SubmitStrikeOffDataCallBack} TestStandardParentCall={AddedTestStandardData}
                                                        IsSaveFailed={getisParentSaveFailed} ActionMode={getActionMode}></EditBrandStandardStrikeOff>
                                                </div>
                                                <div id="idTrims" className="tab-pane col-lg-12" style={{ padding: "0px" }} >
                                                    <EditBrandStandardTrims props={props} StandardOptions={getStandardOptions}
                                                        GenderOptions={getGenderOptions} ColorDepthOptions={getColorDepthOptions}
                                                        FieldOptions={getFieldOptions}
                                                        TrimsTestNameList={getFabricTestNames}
                                                        FabricOptions={getFabricOptions} FabricAndGroupDetails={getFabricAndGroupDetails}
                                                        StandardAndGenderDetails={getStandardAndGenderDetails} InteralGridDetails={getInternalGridDetails}
                                                        StatusUpdationCallBack={ParentStatusUpdationCallBack} ColorDepthParentCall={AddedColorDepthData}
                                                        TestFieldParentCall={AddedTestFieldData} BuyerID={getCurrentBuyerID} BrandID={getCurrentBrandID}
                                                        SubmitTrimsDataCallBack={SubmitTrimsDataCallBack} TestStandardParentCall={AddedTestStandardData}
                                                        IsSaveFailed={getisParentSaveFailed} ActionMode={getActionMode}></EditBrandStandardTrims>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="" style={{ marginTop: '10px' }}>
                                        <div className="buttons-preview" align="right">
                                            <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                                <i className="fa fa-arrow-circle-left"></i>
                                                &nbsp;Back
                                            </span>
                                            {getActionMode != "View" && <><span className="btn btn-danger" onClick={() => ResetOperation()}>
                                                <i className="fa fa-times"></i>
                                                &nbsp;Reset
                                            </span>
                                                <button type="button" className="btn btn-success" onClick={UpdateBrandWiseStandardMaster} disabled={buttonLoader}>
                                                    <i className="fa fa-check right"></i>&nbsp;
                                                    Update
                                                </button></>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                getID.showPopupConfirm ? <ConfirmationPopup props={props} data={
                                    getID.Params
                                }
                                    deleteCallback={Delcallback}
                                    showpopup={true}
                                /> : null
                            }
                        </div>
                    </div>
                </div>

            </div>
            {loader}
        </>
    )
}
export default BrandwiseEdit;