import {
    MASTERSIZE_LOADING,
    CREATE_MASTERSIZE_SUCCESS,
    UPDATE_MASTERSIZE_SUCCESS,
    RETRIEVE_MASTERSIZE,
    DELETE_MASTERSIZE,
} from "../actions/types";
import moment from "moment";
const initialState = {
    isLoadingMasterSize: true,
    MasterSizeList: [],
    lastMasterSizeID: 0,
};

const MasterSizeReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case MASTERSIZE_LOADING:
            return {
                ...state,
                isLoadingMasterSize: payload
            };
        case RETRIEVE_MASTERSIZE:

            // let getMasterSizeList = payload;
            // getMasterSizeList = getMasterSizeList && getMasterSizeList.sort((a, b) => (a["masterSizeName"].toString().toLowerCase() > b["masterSizeName"].toString().toLowerCase() ? 1 : -1));
            // let MasterSizeID = getMasterSizeList.length > 0 ? getMasterSizeList[getMasterSizeList.length - 1].value : 0;
            return { ...state, MasterSizeList: payload };
        case DELETE_MASTERSIZE:

            const results = state.MasterSizeList.filter(c => c.sizeID !== payload.MasterSizeID);

            return {
                ...state,
                MasterSizeList: results
            };
        case CREATE_MASTERSIZE_SUCCESS:
            const newRows = payload;
            let MasterSizeResults = [...state.MasterSizeList, ...newRows];
            //Order by descending ( -1 : 1)
            MasterSizeResults = MasterSizeResults.sort((a, b) => (a["SizeID"] > b["SizeID"] ? -1 : 1));
            return {
                ...state,
                MasterSizeList: MasterSizeResults
            };
        case UPDATE_MASTERSIZE_SUCCESS:
            const newValue = payload.getMasterSizeInfo;
            const currentDate = payload.CurrentDate;
            let MasterSizeResultList = [...state.MasterSizeList];
            var index = MasterSizeResultList.findIndex(x => x.MasterSizeID === newValue.MasterSizeID);
            MasterSizeResultList[index].MasterSizeName = newValue.MasterSizeName;
            MasterSizeResultList[index].modifiedDate = currentDate;
            MasterSizeResultList = MasterSizeResultList.sort((a, b) => (moment(new Date(a["modifiedDate"])) > moment(new Date(b["modifiedDate"])) ? -1 : 1));
            return {
                ...state,
                MasterSizeList: MasterSizeResultList
            };
        default:
            return state;
    }
};
export default MasterSizeReducer;