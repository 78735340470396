import { combineReducers } from "redux";
import auth from "./auth";
//import branch from "../../../Master/";
import branch from "./branch";
import route from "./route";
import purchaseorder from "./purchaseorder";
import strikeoff from "./strikeoffMaster";
import trim from "./trimMaster";
import taskType from "./taskType";
import style from "./style";
import task from "./task";
import role from "./role";
import department from "./department";
import company from "./company";
import buyer from "./buyer";
import buyerinfo from "./buyerinfo";
import supplier from "./supplier";
import season from "./season";
import gender from "./gender";
import currency from "./currency";
import pantone from "./pantone";
import pantoneType from "./pantoneType";
import fabricType from "./fabricType";
import size from "./size";
import city from "./city";
import grouptype from "./grouptype";
import category from "./category";
import strikeofftype from "./strikeofftype";
import trimtype from "./trimtype";
import measurementpoint from "./measurementpoint";
import swatch from "./swatch";
import fabricpart from "./fabricpart";
import tna from "./tna";
import samplereq from "./samplereq";
import leaveDay from "./leaveDay";
import userpermission from "./userpermission";
import processtype from "./ProcessType";
import mastersize from "./MasterSize";
import generalLabdip from "./generalLabdip";
import generalStrikeOff from "./generalStrikeOff";
import generalTrim from "./generalTrim";
import orderLabdip from "./orderLabdip";
import common from "./common";
import orderTrim from "./orderTrim";
import orderStrikeOff from "./orderStrikeOff";
import dashboardreducer from "./chart";
import courierReducer from "./courier";
import agencyReducer from './agency';
import yarnType from './yarnType';
import generalTNAReport from './generalTNAReport'
import report from './report'
import tnadetails from "./tnadetails";
import state from './state'
import defects from './defect'
import item from './item'
import qa from './qa'
import Workmanshipdetails from './workmanshipitem'
import WorkmanGroup from './workmanGroup'
import template from './template'
import Warehouse from './warehouse'
import PackageItem from './packageitem'
import inspectionQC from './inspectionQC'
import { CsrDetailsReducer, CsrdropdownDetailsReducer } from './csrDetail'
import CSRTemplateReducer from "./csrTemplate";
import aql from './aql';
import Samples from './Samples';
import AQLFinalInspection from './aqlfinalinspection';
import testingreport from './testingreport';
import TestRepTempReducer from './testreptemp';
import BuyCusWarehouseReducer from './buyCusWar';

import PackageImage from "./packageimage";
import PackageTemplateReducer from "./packingTemplate";
import fldetails from "./fldetails";
import orderProtoFitSms from "./orderProtoFitSms";
import QACommentpoint from "./qacommentpoint";
import testname from './testname';
import testitem from './testitem';
import testfield from './testfield';
import TestStandardReducer from "./teststandard";
import ColorDepthReducer from './colordepth';
import BrandWiseStandardReducer from './brandwisestandard';
import AlignmentReducer from './alignment';
import reportmodulecategory from './reportmodulecategory';
import reporttemplate from './reporttemplate';
import columninformation from './columninformation';
import ScheduleReport from "./scheduleReport";

export default combineReducers({
  auth,
  common,
  branch,
  route,
  purchaseorder,
  strikeoff,
  trim,
  taskType,
  style,
  task,
  role,
  department,
  company,
  buyer,
  buyerinfo,
  supplier,
  season,
  gender,
  currency,
  pantone,
  pantoneType,
  fabricType,
  size,
  city,
  grouptype,
  category,
  strikeofftype,
  trimtype,
  measurementpoint,
  swatch,
  fabricpart,
  tna,
  samplereq,
  leaveDay,
  userpermission,
  processtype,
  mastersize,
  generalLabdip,
  generalStrikeOff,
  generalTrim,
  orderLabdip,
  orderTrim,
  orderStrikeOff,
  dashboardreducer,
  courierReducer,
  yarnType,
  agencyReducer,
  generalTNAReport,
  report,
  tnadetails,
  state,
  defects,
  item,
  qa,
  Workmanshipdetails,
  WorkmanGroup,
  Warehouse,
  PackageItem,
  template,
  Warehouse,
  inspectionQC,
  CsrDetailsReducer,
  CsrdropdownDetailsReducer,
  CSRTemplateReducer,
  aql,
  Samples,
  testingreport,
  TestRepTempReducer,
  BuyCusWarehouseReducer
  , AQLFinalInspection
  , testingreport,
  TestRepTempReducer
  , PackageImage,
  PackageTemplateReducer,
  fldetails,
  orderProtoFitSms,
  QACommentpoint,
  testname,
  testitem,
  testfield,
  TestStandardReducer,
  ColorDepthReducer,
  BrandWiseStandardReducer,
  AlignmentReducer,
  reportmodulecategory,
  reporttemplate,
  columninformation
  , ScheduleReport
});
