import React, { useState, useEffect, Fragment, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { updatePOSKUCheckBox } from "../../actions/tna";

const PurchaseOrderSKUPopup = (props) => {

    //reducer
    const NewPurchaseOrderSKUList = useSelector((state) => state.tna.newPurchaseOrderSKUList);
    const POSkuIsAllChecked = useSelector((state) => state.tna.POSkuIsAllChecked);
    //declaration
    const dispatch = useDispatch();



    //function
    const handleSKUSelect = (e, index, selectAll) => {
        let isChecked = false;
        if (e.target.checked) {
            isChecked = true;
        }
        dispatch(updatePOSKUCheckBox({ index: index, isChecked: isChecked, selectAll: selectAll }));
    }


    return (
        <>
            <Modal show={props.IsPOSKUPopup} size="lg" dragable backdrop="static" keyboard={false} onHide={() => props.handleClosePOSKUPopupCallback()}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        New Purchase Order SKU List
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Sku ID</th>
                                <th>SKU Name</th>
                                <th>PO/ID No</th>

                                <th>Customer</th>
                                <th>Qty</th>
                                <th>Ex-Factory</th>
                                <th>
                                    <div className="checkbox" style={{ marginTop: "0px" }}>
                                        <label>
                                            <input type="checkbox"
                                                isMulti
                                                onChange={event => handleSKUSelect(event, 0, true)}
                                                value={POSkuIsAllChecked}
                                                checked={POSkuIsAllChecked}
                                                // checked={inputField.IsSelectAll === 1 ? true : false}
                                                className="colored-blue clearOpacity"
                                            />
                                            <span className="text"></span>
                                        </label>
                                    </div></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                NewPurchaseOrderSKUList && NewPurchaseOrderSKUList.map((Field, Index) => (
                                    <Fragment key={
                                        `${Field.purchaseOrderSkuId}`
                                    }>
                                        <tr>
                                            <td>{Field.purchaseOrderSkuId}</td>
                                            <td>{Field.skuName}</td>

                                            <td>
                                                {
                                                    Field.buyerName === "Focus International" ? Field.focusPono : Field.idpono
                                                }

                                            </td>
                                            <td>{Field.endCustomer}</td>
                                            <td>{Field.quantity}</td>
                                            <td>{Field.exFacDate}</td>
                                            <td>
                                                <label>
                                                    <input type="checkbox" name="isChecked"
                                                        Name
                                                        onChange={event => handleSKUSelect(event, Index, false)}
                                                        value={Field.isChecked}
                                                        checked={Field.isChecked}
                                                        className="clearOpacity textdesign" />

                                                    <span className="text"></span>
                                                </label>

                                            </td>
                                        </tr>
                                    </Fragment>
                                ))
                            }

                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={() => props.handleAddPOSKUCallback()}>
                        Add
                    </Button>

                </Modal.Footer>

            </Modal>
        </>
    );
};

export default PurchaseOrderSKUPopup;