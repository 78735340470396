import {
    CITY_LOADING,
    RETRIEVE_CITY,
    DELETE_CITY,
} from "./types";
import CityService from "../services/Master/CityService";

const cityLoading = (isStatus) => ({
    type: CITY_LOADING,
    payload: isStatus,
});

export const deleteCity = (cityID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_CITY,
            payload: cityID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrieveCity = (cityID) => async (dispatch) => {
    try {
        dispatch(cityLoading(true));
        const res = await CityService.getCityList(cityID);
        dispatch({
            type: RETRIEVE_CITY,
            payload: res.data,
        });
        dispatch(cityLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(cityLoading(false));
    }
};