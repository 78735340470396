import React, { useState, useEffect, Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadStylePurchaseOrderTNAList, loadTNATaskOwner, getTNACompanyHolidayList, updateTNA, loadTNAChildReferenceUnMappedList, getTNAEditTaskWiseChildList } from "../../../actions/tna";
import Reactselect from 'react-select';
import { Modal, Button } from "react-bootstrap";
import taskService from "../../../services/Master/TaskService";
import Nodify from "../../Common/ReactNotification";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { retrieveTask, loadTaskList, loadTaskHolderList } from "../../../actions/task";
import { loadTaskTypeList } from "../../../actions/taskType";
import { loadLeaveDaysList } from "../../../actions/leaveDay";
import { loadRouteDependencyList } from "../../../actions/route";
//import DatePicker from "react-datepicker";
import moment from "moment";
import Lightbox from "react-image-lightbox";
import axios from "axios";
import $ from "jquery";
import { getSkuAndStyleDetails } from "../../../actions/tna";
import TrimSubmission from "../../TNA/TrimSubmission";
import TrimApproval from "../../TNA/TrimApproval";
import LabdibSubmission from "../../TNA/LabdibSubmission";
import LabdibApproval from "../../TNA/LabdibApproval";
import StrikeOffSubmission from "../../TNA/StrikeOffSubmission";
import StrikeApproval from "../../TNA/StrikeApproval";
import ProtoFitSMSSubmission from "../../TNA/ProtoFitSMSSubmission";
import ProtoFitSMSApproval from "../../TNA/ProtoFitSMSApproval";
import AfterWashList from "../../TNA/AppearanceAfterWash";
import ReactNotification from 'react-notifications-component';
import TnaService from "../../../services/TNA/TNAService";
import { loadTNADetailList } from "../../../actions/tna";
import TNAService from "../../../services/TNA/TNAService";
//import Loader from "react-loader-spinner";
//import { loadStylePurchaseOrderTNAList } from '../../../actions/tna';
const ViewTNA = (props, TNAViewInfo) => {
    const AllRouteInfo = useSelector((state) => state.tna.TNAViewList);
    const getTaskTypeList = useSelector((state) => state.taskType.drpTaskTypeMapList);
    const { user: currentUser } = useSelector((state) => state.auth);
    const getLeaveDaysList = useSelector((state) => state.leaveDay.drpLeaveDaysList);
    const ExistingList = useSelector((state) => state.task.taskList);
    const getAllTaskList = useSelector((state) => state.task.drpAllTaskList);
    const getDependencyList = useSelector((state) => state.route.routeDependencyList);
    const taskHolderList = useSelector((state) => state.task.drpTaskHolderList);
    const drpTNATaskOwnerList = useSelector((state) => state.tna.drpTNATaskOwnerList);
    const getCompanyHolidayList = useSelector((state) => state.tna.TNACompanyHolidayList);
    const isLoadingTNA = useSelector((state) => state.tna.isLoadingTNA);
    const getTNAStatusList = useSelector((state) => state.tna.TNAStatusList);
    const TNAMultiTask = useSelector((state) => state.tna.TNAMultiTask);
    const StaticTask = useSelector((state) => state.tna.StaticTask);
    const [getTrimmodalpopup, setTrimmodalpopup] = useState(false);
    const [getTrimApprovalpopup, setTrimApprovalpopup] = useState(false);
    const [getLapmodal, setLapmodal] = useState(false);
    const [getOpenLabdipApppopup, setOpenLabdipApppopup] = useState(false);
    const [strikeoffModalpopup, setStrikeOffmodalpopup] = useState(false);
    const [getstrikeoffApppopup, setStrikeOffApppopup] = useState(false);
    const [getSampleSubModalpopup, setSampleSubModalpopup] = useState(false);
    const [getSampleAppModalpopup, setSampleAppModalpopup] = useState(false);
    const [getApperancemodalpopup, setApperancemodalpopup] = useState(false);
    const [isSkuAndStyleDetailPopup, setSkuAndStyleDetailPopup] = useState(false);
    const [SkuAndStyleDetails, setSkuAndStyleDetails] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isLastEdit, setLastEdit] = useState(props.Action !== undefined ? props.props.location.state.params.creationStatus === 1 ? false : true
        : props.location.state.params.creationStatus === 1 ? false : true);
    const getTNABrandID = props.Action !== undefined ? props.props.location.state.params.brandID : props.location.state.params.brandID;
    const getTNASupplierID = props.Action !== undefined ? props.props.location.state.params.supplierID : props.location.state.params.supplierId;
    //let Buttonvisible = props.location.state.params.action;
    //declaration
    const dispatch = useDispatch();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),
    }
    // const [getres, setres] = useState(0);

    //State
    const [MainFields, setMainFields] = useState([{
        TNAId: props.Action !== undefined ? props.props.location.state.params.tnaid : props.location.state.params.tnaid,
        CreationStatus: props.Action !== undefined ? props.props.location.state.params.creationStatus === 2 ? 3 : 2 :
            props.location.state.params.creationStatus === 2 ? 3 : 2,
        TNARouteApplyDetails: [],
        TNADependencyDetails: [],
        TNAAdditionalTaskDetails: [],
        TNAChildInformation: [],
        TNASubChildInformation: [],
        TNADocs: [],
        TNACommentsInformation: [],
        CreatedBy: currentUser.employeeinformationID,
    }]);


    const EmptyInputFields = [];
    const [inputFields, setInputFields] = useState(EmptyInputFields);
    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getTaskHolderList, setTaskHolderList] = useState([]);
    const [getHeaderName, addHeaderName] = useState("Edit");
    //gowtham

    const [getComment, setComment] = useState(false);
    const [EditedCommentIndex, setEditedCommentIndex] = useState('');
    const [getFiles, setFiles] = useState([]);
    const [ViewSupplierID, setViewSupplierID] = useState([]);
    const [isOpenimg, setIsOpenimg] = useState(false);
    const [getImagename, setImagename] = useState();
    const [getTaskIndexFileupload, setTaskIndexFileupload] = useState();
    const [getTNAtaskIdFileupload, setTNAtaskIdFileupload] = useState();
    const [getCurrentChildTask, setCurrentChildTask] = useState({ value: 0, lablel: '' });
    const [manualApprovalData, setmanualApprovalData] = useState({ manualCloseComment: '', files: [] });
    const [existingApprovalData, setexistingApprovalData] = useState([]);

    // useEffect(() => {
    //     showLoader();
    //     if (props.Action === undefined) {
    //         dispatch(loadTNADetailList({ TNAId: props.location.state.params.tnaid }));
    //         setAllRouteInfo(AllData);
    //         LoadTNAData(AllData);
    //         hideLoader();
    //     }
    //     else {
    //         var styleID = props.Action !== undefined ? props.props.location.state.params.styleID : props.location.state.params.styleId;
    //         if (props.Action !== undefined) {
    //             TnaService.StylePurchaseOrderTNAList(styleID, 0).then((response) => {
    //                 if (response.data) {
    //                     setAllRouteInfo(response.data);
    //                     LoadTNAData(response.data);
    //                     hideLoader();
    //                 }
    //             })
    //         }
    //         else {
    //             setAllRouteInfo(AllData);
    //             LoadTNAData(AllData);
    //             hideLoader();
    //         }
    //     }

    // }, []);

    // useEffect(() => {
    //     // var styleID = props.Action !== undefined ? props.props.location.state.params.styleID : props.location.state.params.styleId;
    //     // if (props.Action !== undefined) {
    //     //     TnaService.StylePurchaseOrderTNAList(styleID, 0).then((response) => {
    //     //         if (response.data) {
    //     //             setAllRouteInfo(response.data);
    //     //             LoadTNAData(response.data);
    //     //             hideLoader();
    //     //         }
    //     //     })
    //     // }
    //     // else {
    //     //     setAllRouteInfo(AllData);
    //     //     LoadTNAData(AllData);
    //     //     hideLoader();
    //     // }
    // }, []
    // )

    useEffect(() => {
        showLoader();
        dispatch(loadStylePurchaseOrderTNAList({ StyleID: props.props.location.state.params.styleID, PurOrdId: 0 }));
        TNAService.GetSupplierID(props.props.location.state.params.styleID).then((res) => {

            if (res.data.length > 0) {
                setViewSupplierID(res.data[0].supplierID)
            }
        })

    }, []);

    // useMemo(
    //     () => {
    //         if (AllRouteInfo.tnaDetailList !== undefined) {

    //             let routeInfo = AllRouteInfo.tnaDetailList;
    //             let dependentInfo = AllRouteInfo.tnaDetailDependencyList;
    //             let additionalInfo = AllRouteInfo.tnaDetailTaskList;
    //             let parentChildList = AllRouteInfo.tnaParentChildList;
    //             let childList = AllRouteInfo.tnaChildList;
    //             let Comments = AllRouteInfo.tnaCommentsList;
    //             let Attachments = AllRouteInfo.tnaAttachmentsList;

    //             let routeInformation = [];
    //             if (AllRouteInfo !== undefined) {
    //                 showLoader();
    //                 if (routeInfo !== undefined) {
    //                     if (routeInfo.length > 0) {
    //                         //setInputFields([]);

    //                         routeInfo.map((route, i) => {

    //                             let employee1 = "";
    //                             let employee2 = "";
    //                             let employee3 = "";

    //                             if (route.taskHolderName !== null && route.taskHolderName !== undefined) {
    //                                 let TaskHolderName = route.taskHolderName.split('-');
    //                                 if (TaskHolderName[0] !== undefined) {
    //                                     employee1 = TaskHolderName[0].trim();

    //                                     if (TaskHolderName[1] !== undefined) {
    //                                         let FollowerName = TaskHolderName[1].split(',');
    //                                         if (FollowerName[0] !== undefined) {
    //                                             employee2 = FollowerName[0].trim();

    //                                             if (FollowerName[1] !== undefined) {
    //                                                 employee3 = FollowerName[1].trim();
    //                                             } else {
    //                                                 employee3 = "";
    //                                             }
    //                                         } else {
    //                                             employee2 = "";
    //                                             employee3 = "";
    //                                         }

    //                                     } else {
    //                                         employee2 = "";
    //                                         employee3 = "";
    //                                     }
    //                                 }
    //                             }


    //                             let StartDate = new Date(route.planStartDate);
    //                             StartDate.setMinutes(StartDate.getMinutes() + 370);
    //                             let EndDate = new Date(route.endDate);
    //                             EndDate.setMinutes(EndDate.getMinutes() + 370);
    //                             let ImageUrl = "";

    //                             if (route.taskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || route.taskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER || route.taskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || route.taskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || route.taskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
    //                                 ImageUrl = 'Images/Style/Strikeoff/';
    //                             } else if (route.taskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || route.taskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || route.taskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || route.taskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || route.taskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || route.taskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER || route.taskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
    //                                 ImageUrl = 'Images/Style/Trim/';
    //                             }


    //                             let IsSingleTask = !!TNASingleTask.find(element => element.toLowerCase() === route.taskName.toLowerCase());
    //                             routeInformation.push({
    //                                 TNARouteApplyID: route.tnaRouteApplyID,
    //                                 TaskFieldEnable: 0,
    //                                 TaskType: route.taskType,
    //                                 TaskID: route.taskNameID,
    //                                 strTaskName: route.taskName,
    //                                 EndDate: EndDate,
    //                                 StartDate: StartDate,
    //                                 Duration: route.duration,
    //                                 intDependency: parseInt(route.dependencyStartstop),
    //                                 arrDependency: [],
    //                                 arrComment: [],
    //                                 arrAttachment: [],
    //                                 arrManual: [],
    //                                 ManualComments: route.manualCloseComment,
    //                                 //ManualCloseDate: route.ManualCloseDate,
    //                                 CommentsCount: route.taskCommentsCount,
    //                                 AttachmentCount: route.taskDocumentsCount,
    //                                 TaskHolderList: '',
    //                                 TaskHolderOrFollower: route.taskHolderID,
    //                                 TaskHolderOwnerTxtList: route.taskOwnerNames,
    //                                 TaskHolderIDList: route.taskHolderIDList,
    //                                 FollowerIDList1: route.followerIDList1,
    //                                 FollowerIDList2: route.followerIDList2,
    //                                 TaskHolderBuyer: route.taskHolderIDList,
    //                                 FollowerCube: route.followerIDList1,
    //                                 FollowerSupplier: route.followerIDList2,
    //                                 TaskHolderOwnerIdList: route.taskOwner,
    //                                 titleEmployee1: employee1,
    //                                 titleEmployee2: employee2,
    //                                 titleEmployee3: employee3,
    //                                 LeaveDays: route.leaveDays,
    //                                 IsTHFirstTime: 0,
    //                                 LeaveDaysTxt: route.leaveDayName,
    //                                 AdditionalFollowup: additionalInfo.filter(x => x.indexName === route.indexName && x.isTaskSelected === 1).length,
    //                                 IsFollowupExist: additionalInfo.filter(x => x.indexName === route.indexName).length,
    //                                 DependencyCount: dependentInfo.filter(x => x.indexName === route.indexName && x.dependencyID !== 0).length,
    //                                 IndexName: route.indexName,
    //                                 //PreferenceId: route.preferenceId,
    //                                 IsPopupOpen: 0,
    //                                 IsDependencyOpen: 0,
    //                                 IsTHLeaveDaysOpen: 0,
    //                                 IsComment: 0,
    //                                 IsAttachment: 0,
    //                                 IsSelectAll: route.isSelectAll,
    //                                 FollowupDetails: [],
    //                                 SelectedDependency: route.dependency,
    //                                 arrTaskHolderBuyer: [],
    //                                 arrFollowerCube: [],
    //                                 arrFollowerSupplier: [],
    //                                 TaskHolderBuyerTxt: '',
    //                                 FollowerCubeTxt: '',
    //                                 FollowerSupplierTxt: '',
    //                                 IsSingleTask: IsSingleTask,
    //                                 IsNewRow: true,
    //                                 StatusID: route.routeApplyStatus === 0 ? 1 : route.routeApplyStatus,
    //                                 AWBStatus: route.awbStatus,
    //                                 ActualEndDate: route.actualEndDate,
    //                                 ManualCloseDate: route.actualEndDate,
    //                                 SubTaskList: []
    //                             });


    //                             if (IsSingleTask === false) {
    //                                 parentChildList && parentChildList.filter(d => d.taskID === route.taskNameID).map((element, p) => {
    //                                     routeInformation[i].SubTaskList.push({
    //                                         ParentId: element.parentId,
    //                                         TaskID: element.taskID,
    //                                         MainId: element.mainId,
    //                                         Code: element.code,
    //                                         Color: element.color,
    //                                         ColorOrName: element.colorOrName,
    //                                         ImagePath: element.imagePath,
    //                                         ImageUrl: ImageUrl,
    //                                         StrikeOffOrTrimInfoId: element.strikeOffIdOrTrimInfoId,
    //                                         IsExpanded: false,
    //                                         // ParentIndex: p,
    //                                         ChildTaskList: []
    //                                     })

    //                                     childList && childList.filter(d => d.taskID === route.taskNameID && d.mainId === element.mainId).map((child, c) => {
    //                                         let childStartDate = isLastEdit ? new Date(child.planStartDate) : new Date(route.planStartDate);
    //                                         childStartDate.setMinutes(childStartDate.getMinutes() + 370);
    //                                         let childEndDate = isLastEdit ? new Date(child.endDate) : new Date(route.endDate);
    //                                         childEndDate.setMinutes(childEndDate.getMinutes() + 370);

    //                                         routeInformation[i].SubTaskList[p].ChildTaskList.push({
    //                                             ChildId: child.childId,
    //                                             SubId: child.subId,
    //                                             Name: child.name,
    //                                             // TaskName: child.taskName,
    //                                             TaskName: route.taskName,
    //                                             TaskID: child.taskID,
    //                                             MainId: child.mainId,
    //                                             Duration: isLastEdit ? child.duration : route.duration,
    //                                             EndDate: childEndDate,
    //                                             StartDate: childStartDate,
    //                                             // EndDate: isLastEdit ? moment(child.endDate).toDate() : moment(child.endDate).toDate(),
    //                                             // StartDate: isLastEdit ? moment(child.planStartDate).toDate() : moment(child.planStartDate).toDate(),
    //                                             // arrDependency: [],
    //                                             TaskHolderList: '',
    //                                             TaskHolderOrFollower: route.taskHolderID,
    //                                             TaskHolderOwnerTxtList: route.taskOwnerNames,
    //                                             TaskHolderIDList: route.taskHolderIDList,
    //                                             FollowerIDList1: route.followerIDList1,
    //                                             FollowerIDList2: route.followerIDList2,
    //                                             TaskHolderBuyer: route.taskHolderIDList,
    //                                             FollowerCube: route.followerIDList1,
    //                                             FollowerSupplier: route.followerIDList2,
    //                                             arrTaskHolderBuyer: [],
    //                                             arrFollowerCube: [],
    //                                             arrFollowerSupplier: [],
    //                                             TaskHolderBuyerTxt: '',
    //                                             FollowerCubeTxt: '',
    //                                             FollowerSupplierTxt: '',
    //                                             TaskHolderOwnerIdList: '',
    //                                             titleEmployee1: employee1,
    //                                             titleEmployee2: employee2,
    //                                             titleEmployee3: employee3,
    //                                             IsTHFirstTime: 0,
    //                                             DependencyCount: dependentInfo.filter(x => x.indexName === route.indexName && x.dependencyID !== 0).length,
    //                                             // IsDependencyOpen: 0,
    //                                             // IsTHLeaveDaysOpen: 0,
    //                                             SelectedDependency: route.dependency,
    //                                             LeaveDays: '',
    //                                             LeaveDaysTxt: '',
    //                                             StatusID: isLastEdit ? child.routeApplyStatus : 1,
    //                                             AWBStatus: '',
    //                                             IsAddChildTaskOpen: 0,
    //                                             StrikeOffOrTrimInfoId: child.strikeOffIdOrTrimInfoId,
    //                                             // ParentIndex: p,
    //                                             // ChildIndex: c,
    //                                             drpChildTaskList: [],
    //                                             IsManualClose: 0,
    //                                             ManualCloseComment: child.manualCloseComment,
    //                                             //ManualCloseDate: child.manualCloseDate,
    //                                             IsShowSubmitButton: child.isShowSubmitButton,
    //                                             IsShowApprovalButton: child.isShowApprovalButton,
    //                                             ManualCloseTaskwise: child.ManualClose,
    //                                             ChildActualEndDate: child.actualEndDate,
    //                                             ManualCloseDate: child.actualEndDate,

    //                                         });
    //                                     });


    //                                 });
    //                             }

    //                         });
    //                     }

    //                     //For empty child list have remove parent task
    //                     for (let index = 0; index < routeInformation.length; index++) {
    //                         //const element = routeInformation[index];
    //                         if (routeInformation[index].SubTaskList.length === 0 && routeInformation[index].IsSingleTask === false) {
    //                             routeInformation.splice(index, 1);
    //                             index = index - 1;
    //                         }

    //                     }

    //                     if (dependentInfo && dependentInfo.length > 0) {
    //                         dependentInfo.map((element, i) => {
    //                             let DependencyId = 0;
    //                             DependencyId = element.tnaDependencyID;
    //                             try {
    //                                 routeInformation && routeInformation[parseInt(element.indexName)].arrDependency && routeInformation[parseInt(element.indexName)].arrDependency.push({
    //                                     TNADependencyID: DependencyId,
    //                                     DependentTaskName: element.dependentTaskName,
    //                                     TNATaskName: element.tnaTaskName,
    //                                     DependencyID: element.dependencyID,
    //                                     LogDays: element.logDays,
    //                                     IndexName: element.indexName
    //                                 })
    //                             } catch
    //                             { }
    //                         })
    //                     }

    //                     if (additionalInfo.length > 0) {
    //                         routeInformation.map((add, j) => {
    //                             additionalInfo.filter(x => x.indexName === add.IndexName).map((element, i) => {

    //                                 routeInformation[j].FollowupDetails.push({
    //                                     TNAAddTaskID: element.tnaAddTaskID,
    //                                     AdditonalTaskDetailsID: element.additonalTaskDetailsID,
    //                                     AddtionalTaskName: element.addtionalTaskName,
    //                                     IsTaskSelected: element.isTaskSelected,
    //                                     IndexName: element.indexName
    //                                 })
    //                             });

    //                         })
    //                     }

    //                     if (Comments.length > 0) {
    //                         routeInformation.map((add, j) => {
    //                             Comments.filter(x => x.taskIndexName === add.IndexName).map((element, i) => {
    //                                 //
    //                                 routeInformation[j].arrComment.push({
    //                                     TNACommentsID: element.tnaCommentsID,
    //                                     TNAId: element.tnaId,
    //                                     TNAComments: element.tnaComments,
    //                                     IndexName: element.indexName,
    //                                     TaskIndexName: element.taskIndexName,
    //                                     //Date1: element.createdDate.toString(),
    //                                     Date: new Date(element.createdDate),
    //                                     //Date: element.createdDate.toLocaleDateString('en-US'),
    //                                     //ModifiedDate: element.modifiedDate
    //                                 })
    //                             });

    //                         })
    //                     }

    //                     if (Attachments.length > 0) {
    //                         //setFiles([]);
    //                         routeInformation.map((add, j) => {
    //                             Attachments.filter(x => x.taskIndexName === add.IndexName).map((element, i) => {
    //
    //                                 routeInformation[j].arrAttachment.push({
    //                                     TNAFileID: element.tnaFileID,
    //                                     TNAId: element.tnaId,
    //                                     FileName: element.fileName,
    //                                     FilePath: element.filePath,
    //                                     FileType: element.fileType,
    //                                     IndexName: element.indexName,
    //                                     TaskIndexName: parseInt(element.taskIndexName),
    //                                     Date1: element.createdDate,
    //                                     //Date: element.createdDate.toLocaleDateString('en-US'),
    //                                     ModifiedDate: element.modifiedDate
    //                                 })



    //                                 // if (getFiles.length > 0)
    //                                 // {
    //                                 //     getFiles.filter(x => x.taskIndexName === add.IndexName).map((element, i) => {

    //                                 //         getFiles.push({
    //                                 //             TNAFileID: element.tnaFileID,
    //                                 //             TNAId: element.tnaId,
    //                                 //             FileName: element.fileName,
    //                                 //             FilePath: element.filePath,
    //                                 //             FileType: element.fileType,
    //                                 //             IndexName: element.indexName,
    //                                 //             TaskIndexName: element.taskIndexName,
    //                                 //             Date1: element.createdDate,
    //                                 //             //Date: element.createdDate.toLocaleDateString('en-US'),
    //                                 //             ModifiedDate: element.modifiedDate
    //                                 //         })
    //                                 //     })

    //                                 // }
    //                                 // else
    //                                 // {
    //                                 //     getFiles.push({
    //                                 //         TNAFileID: element.tnaFileID,
    //                                 //         TNAId: element.tnaId,
    //                                 //         FileName: element.fileName,
    //                                 //         FilePath: element.filePath,
    //                                 //         FileType: element.fileType,
    //                                 //         IndexName: element.indexName,
    //                                 //         TaskIndexName: element.taskIndexName,
    //                                 //         Date1: element.createdDate,
    //                                 //         //Date: element.createdDate.toLocaleDateString('en-US'),
    //                                 //         ModifiedDate: element.modifiedDate
    //                                 //     })

    //                                 // }
    //                             });

    //                         })
    //                     }



    //                     setInputFields(routeInformation);
    //                     hideLoader();
    //                     // if (Comments.length > 0) {
    //                     //     routeInformation.map((add, c) => {
    //                     //
    //                     //         Comments.filter(x => x.indexName === add.IndexName).map((element, i) => {
    //                     //
    //                     //             routeInformation[c].arrComment.push({
    //                     //                 TNACommentsID: element.tnaCommentsID,
    //                     //                 TNAId: element.tnaId,
    //                     //                 TNAComments: element.tnaComments,
    //                     //                 IndexName: element.indexName,
    //                     //                 TaskIndexName: element.taskIndexName,
    //                     //                 CreatedDate: element.createdDate,
    //                     //                 ModifiedDate: element.modifiedDate
    //                     //             })
    //                     //         })
    //                     //     })
    //                     //    setInputFields(routeInformation);
    //                     // } else { setInputFields(routeInformation); }
    //                 } else { setInputFields(routeInformation); hideLoader(); }
    //             } else {
    //                 hideLoader();
    //             }
    //         } else {
    //             hideLoader();
    //         }
    //     },
    //     [AllRouteInfo.tnaViewList]
    // );

    // useMemo(
    //     () => {
    //         if (AllRouteInfo.tnaDetailList !== undefined) {

    //             let routeInfo = AllRouteInfo.tnaDetailList;
    //             let dependentInfo = AllRouteInfo.tnaDetailDependencyList;
    //             let additionalInfo = AllRouteInfo.tnaDetailTaskList;
    //             let parentChildList = AllRouteInfo.tnaParentChildList;
    //             let childList = AllRouteInfo.tnaChildList;
    //             let Comments = AllRouteInfo.tnaCommentsList;
    //             let Attachments = AllRouteInfo.tnaAttachmentsList;

    //             let routeInformation = [];
    //             if (AllRouteInfo !== undefined) {
    //                 showLoader();
    //                 if (routeInfo !== undefined) {
    //                     if (routeInfo.length > 0) {
    //                         //setInputFields([]);

    //                         routeInfo.map((route, i) => {

    //                             let employee1 = "";
    //                             let employee2 = "";
    //                             let employee3 = "";

    //                             if (route.taskHolderName !== null && route.taskHolderName !== undefined) {
    //                                 let TaskHolderName = route.taskHolderName.split('-');
    //                                 if (TaskHolderName[0] !== undefined) {
    //                                     employee1 = TaskHolderName[0].trim();

    //                                     if (TaskHolderName[1] !== undefined) {
    //                                         let FollowerName = TaskHolderName[1].split(',');
    //                                         if (FollowerName[0] !== undefined) {
    //                                             employee2 = FollowerName[0].trim();

    //                                             if (FollowerName[1] !== undefined) {
    //                                                 employee3 = FollowerName[1].trim();
    //                                             } else {
    //                                                 employee3 = "";
    //                                             }
    //                                         } else {
    //                                             employee2 = "";
    //                                             employee3 = "";
    //                                         }

    //                                     } else {
    //                                         employee2 = "";
    //                                         employee3 = "";
    //                                     }
    //                                 }
    //                             }


    //                             // let StartDate = new Date(route.planStartDate);
    //                             // StartDate.setMinutes(StartDate.getMinutes() + 370);
    //                             // let EndDate = new Date(route.endDate);
    //                             // EndDate.setMinutes(EndDate.getMinutes() + 370);
    //                             let ImageUrl = "";

    //                             if (route.taskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || route.taskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER || route.taskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || route.taskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || route.taskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
    //                                 ImageUrl = 'Images/Style/Strikeoff/';
    //                             } else if (route.taskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || route.taskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || route.taskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || route.taskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || route.taskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || route.taskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER || route.taskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
    //                                 ImageUrl = 'Images/Style/Trim/';
    //                             }

    //                             let EndDate = route.endDate;
    //                             let ActualEndDate = route.actualEndDate;


    //                             let IsSingleTask = !!!TNAMultiTask.find(element => element.toLowerCase() === route.taskName.toLowerCase());
    //                             routeInformation.push({
    //                                 TNARouteApplyID: route.tnaRouteApplyID,
    //                                 TaskFieldEnable: 0,
    //                                 TaskType: route.taskType,
    //                                 TaskID: route.taskNameID,
    //                                 strTaskName: route.taskName,
    //                                 EndDate: new Date(route.endDate),
    //                                 StrEndDate: EndDate,
    //                                 StartDate: new Date(route.planStartDate),
    //                                 Duration: route.duration,
    //                                 intDependency: parseInt(route.dependencyStartstop),
    //                                 arrDependency: [],
    //                                 arrComment: [],
    //                                 arrAttachment: [],
    //                                 arrManual: [],
    //                                 ManualComments: route.manualCloseComment,
    //                                 //ManualCloseDate: route.ManualCloseDate,
    //                                 CommentsCount: route.taskCommentsCount,
    //                                 AttachmentCount: route.taskDocumentsCount,
    //                                 TaskHolderList: '',
    //                                 TaskHolderOrFollower: route.taskHolderID,
    //                                 TaskHolderOwnerTxtList: route.taskOwnerNames,
    //                                 TaskHolderIDList: route.taskHolderIDList,
    //                                 FollowerIDList1: route.followerIDList1,
    //                                 FollowerIDList2: route.followerIDList2,
    //                                 TaskHolderBuyer: route.taskHolderIDList,
    //                                 FollowerCube: route.followerIDList1,
    //                                 FollowerSupplier: route.followerIDList2,
    //                                 TaskHolderOwnerIdList: route.taskOwner,
    //                                 titleEmployee1: employee1,
    //                                 titleEmployee2: employee2,
    //                                 titleEmployee3: employee3,
    //                                 LeaveDays: route.leaveDays,
    //                                 IsTHFirstTime: 0,
    //                                 LeaveDaysTxt: route.leaveDayName,
    //                                 AdditionalFollowup: additionalInfo.filter(x => x.indexName === route.indexName && x.isTaskSelected === 1).length,
    //                                 IsFollowupExist: additionalInfo.filter(x => x.indexName === route.indexName).length,
    //                                 DependencyCount: dependentInfo.filter(x => x.indexName === route.indexName && x.dependencyID !== 0).length,
    //                                 IndexName: route.indexName,
    //                                 //PreferenceId: route.preferenceId,
    //                                 IsPopupOpen: 0,
    //                                 IsDependencyOpen: 0,
    //                                 IsTHLeaveDaysOpen: 0,
    //                                 IsComment: 0,
    //                                 IsAttachment: 0,
    //                                 IsSelectAll: route.isSelectAll,
    //                                 FollowupDetails: [],
    //                                 SelectedDependency: route.dependency,
    //                                 arrTaskHolderBuyer: [],
    //                                 arrFollowerCube: [],
    //                                 arrFollowerSupplier: [],
    //                                 TaskHolderBuyerTxt: '',
    //                                 FollowerCubeTxt: '',
    //                                 FollowerSupplierTxt: '',
    //                                 IsSingleTask: IsSingleTask,
    //                                 IsNewRow: true,
    //                                 StatusID: route.routeApplyStatus === 0 ? 1 : route.routeApplyStatus,
    //                                 // StatusName: Statusname,
    //                                 AWBStatus: route.awbStatus,
    //                                 ActualEndDate: route.actualEndDate,
    //                                 StrActualEndDate: ActualEndDate,
    //                                 ManualCloseDate: route.actualEndDate,
    //                                 SubTaskList: [],
    //                                 TaskTypeName: route.taskTypeName,
    //                                 Dependency: route.dependency
    //                             });


    //                             if (IsSingleTask === false) {
    //                                 parentChildList && parentChildList.filter(d => d.taskID === route.taskNameID).map((element, p) => {
    //                                     routeInformation[i].SubTaskList.push({
    //                                         ParentId: element.parentId,
    //                                         TaskID: element.taskID,
    //                                         MainId: element.mainId,
    //                                         Code: element.code,
    //                                         Color: element.color,
    //                                         ColorOrName: element.colorOrName,
    //                                         ImagePath: element.imagePath,
    //                                         ImageUrl: ImageUrl,
    //                                         StrikeOffOrTrimInfoId: element.strikeOffIdOrTrimInfoId,
    //                                         IsExpanded: false,
    //                                         // ParentIndex: p,
    //                                         ChildTaskList: []
    //                                     })

    //                                     childList && childList.filter(d => d.taskID === route.taskNameID && d.mainId === element.mainId).map((child, c) => {
    //                                         // let childStartDate = isLastEdit ? new Date(child.planStartDate) : new Date(route.planStartDate);
    //                                         // childStartDate.setMinutes(childStartDate.getMinutes() + 370);
    //                                         // let childEndDate = isLastEdit ? new Date(child.endDate) : new Date(route.endDate);
    //                                         // childEndDate.setMinutes(childEndDate.getMinutes() + 370);

    //                                         let EndDate = child.endDate;
    //                                         let ActualEndDate = child.actualEndDate;

    //                                         routeInformation[i].SubTaskList[p].ChildTaskList.push({
    //                                             ChildId: child.childId,
    //                                             SubId: child.subId,
    //                                             Name: child.name,
    //                                             // TaskName: child.taskName,
    //                                             TaskName: route.taskName,
    //                                             TaskID: child.taskID,
    //                                             MainId: child.mainId,
    //                                             StrEndDate: EndDate,
    //                                             Duration: isLastEdit ? child.duration : route.duration,
    //                                             EndDate: isLastEdit ? new Date(child.endDate) : new Date(route.endDate),
    //                                             StartDate: isLastEdit ? new Date(child.planStartDate) : new Date(route.planStartDate),
    //                                             // EndDate: isLastEdit ? moment(child.endDate).toDate() : moment(child.endDate).toDate(),
    //                                             // StartDate: isLastEdit ? moment(child.planStartDate).toDate() : moment(child.planStartDate).toDate(),
    //                                             // arrDependency: [],
    //                                             TaskHolderList: '',
    //                                             TaskHolderOrFollower: route.taskHolderID,
    //                                             TaskHolderOwnerTxtList: route.taskOwnerNames,
    //                                             TaskHolderIDList: route.taskHolderIDList,
    //                                             FollowerIDList1: route.followerIDList1,
    //                                             FollowerIDList2: route.followerIDList2,
    //                                             TaskHolderBuyer: route.taskHolderIDList,
    //                                             FollowerCube: route.followerIDList1,
    //                                             FollowerSupplier: route.followerIDList2,
    //                                             arrTaskHolderBuyer: [],
    //                                             arrFollowerCube: [],
    //                                             arrFollowerSupplier: [],
    //                                             TaskHolderBuyerTxt: '',
    //                                             FollowerCubeTxt: '',
    //                                             FollowerSupplierTxt: '',
    //                                             TaskHolderOwnerIdList: '',
    //                                             titleEmployee1: employee1,
    //                                             titleEmployee2: employee2,
    //                                             titleEmployee3: employee3,
    //                                             IsTHFirstTime: 0,
    //                                             DependencyCount: dependentInfo.filter(x => x.indexName === route.indexName && x.dependencyID !== 0).length,
    //                                             // IsDependencyOpen: 0,
    //                                             // IsTHLeaveDaysOpen: 0,
    //                                             SelectedDependency: route.dependency,
    //                                             LeaveDays: '',
    //                                             LeaveDaysTxt: route.leaveDayName,
    //                                             StatusID: isLastEdit ? child.routeApplyStatus : 1,
    //                                             AWBStatus: '',
    //                                             IsAddChildTaskOpen: 0,
    //                                             StrikeOffOrTrimInfoId: child.strikeOffIdOrTrimInfoId,
    //                                             // ParentIndex: p,
    //                                             // ChildIndex: c,
    //                                             drpChildTaskList: [],
    //                                             IsManualClose: 0,
    //                                             ManualCloseComment: child.manualCloseComment,
    //                                             //ManualCloseDate: child.manualCloseDate,
    //                                             StrActualEndDate: ActualEndDate,
    //                                             IsShowSubmitButton: child.isShowSubmitButton,
    //                                             IsShowApprovalButton: child.isShowApprovalButton,
    //                                             ManualCloseTaskwise: child.ManualClose,
    //                                             ChildActualEndDate: child.actualEndDate,
    //                                             ManualCloseDate: child.actualEndDate,
    //                                             ManualApprovalType: 0,
    //                                             ExistingApprovalType: 0,
    //                                             SKUIdList: child.skuIdList,
    //                                             StyleStrikeOffGarmentSizeIdList: child.styleStrikeOffGarmentSizeIdList,
    //                                             TNAId: route.tnaId
    //                                         });
    //                                     });


    //                                 });
    //                             }

    //                             // if (routeInformation[i].SubTaskList.length === 0 && IsSingleTask === false) {
    //                             //     routeInformation.splice(i, 1);
    //                             // }

    //                         });
    //                     }

    //                     //For empty child list have remove parent task
    //                     for (let index = 0; index < routeInformation.length; index++) {
    //                         //const element = routeInformation[index];

    //                         if (routeInformation[index].SubTaskList.length === 0 && routeInformation[index].IsSingleTask === false) {
    //                             routeInformation.splice(index, 1);
    //                             index = index - 1;
    //                         }

    //                     }

    //                     if (dependentInfo && dependentInfo.length > 0) {
    //                         dependentInfo.map((element, i) => {
    //                             let DependencyId = 0;
    //                             DependencyId = element.tnaDependencyID;
    //                             try {
    //                                 routeInformation && routeInformation[parseInt(element.indexName)].arrDependency && routeInformation[parseInt(element.indexName)].arrDependency.push({
    //                                     TNADependencyID: DependencyId,
    //                                     DependentTaskName: element.dependentTaskName,
    //                                     TNATaskName: element.tnaTaskName,
    //                                     DependencyID: element.dependencyID,
    //                                     LogDays: element.logDays,
    //                                     IndexName: element.indexName
    //                                 })
    //                             } catch
    //                             { }


    //                         })
    //                     }

    //                     if (additionalInfo.length > 0) {
    //                         routeInformation.map((add, j) => {
    //                             additionalInfo.filter(x => x.indexName === add.IndexName).map((element, i) => {

    //                                 routeInformation[j].FollowupDetails.push({
    //                                     TNAAddTaskID: element.tnaAddTaskID,
    //                                     AdditonalTaskDetailsID: element.additonalTaskDetailsID,
    //                                     AddtionalTaskName: element.addtionalTaskName,
    //                                     IsTaskSelected: element.isTaskSelected,
    //                                     IndexName: element.indexName
    //                                 })
    //                             });

    //                         })
    //                     }

    //                     if (Comments.length > 0) {
    //                         routeInformation.map((add, j) => {
    //                             Comments.filter(x => x.taskIndexName === add.IndexName).map((element, i) => {
    //                                 //
    //                                 routeInformation[j].arrComment.push({
    //                                     TNACommentsID: element.tnaCommentsID,
    //                                     TNAId: element.tnaId,
    //                                     TNAComments: element.tnaComments,
    //                                     IndexName: element.indexName,
    //                                     TaskIndexName: element.taskIndexName,
    //                                     //Date1: element.createdDate.toString(),
    //                                     Date: new Date(element.createdDate),
    //                                     //Date: element.createdDate.toLocaleDateString('en-US'),
    //                                     //ModifiedDate: element.modifiedDate
    //                                 })
    //                             });

    //                         })
    //                     }

    //                     if (Attachments.length > 0) {
    //                         //setFiles([]);
    //                         routeInformation.map((add, j) => {
    //                             Attachments.filter(x => x.taskIndexName === add.IndexName).map((element, i) => {

    //                                 routeInformation[j].arrAttachment.push({
    //                                     TNAFileID: element.tnaFileID,
    //                                     TNAId: element.tnaId,
    //                                     FileName: element.fileName,
    //                                     FilePath: element.filePath,
    //                                     FileType: element.fileType,
    //                                     IndexName: element.indexName,
    //                                     TaskIndexName: parseInt(element.taskIndexName),
    //                                     Date1: element.createdDate,
    //                                     //Date: element.createdDate.toLocaleDateString('en-US'),
    //                                     ModifiedDate: element.modifiedDate
    //                                 })




    //                             });

    //                         })
    //                     }



    //                     setInputFields(routeInformation);

    //                     hideLoader();

    //                 } else { setInputFields(routeInformation); hideLoader(); }
    //             } else {
    //                 hideLoader();
    //             }
    //         } else {
    //             hideLoader();
    //         }
    //     },
    //     [AllRouteInfo.tnaDetailList]
    // );
    useMemo(() => {
        if (AllRouteInfo.tnaDetailList !== undefined) {

            let routeInfo = AllRouteInfo.tnaDetailList;
            let dependentInfo = AllRouteInfo.tnaDetailDependencyList;
            let additionalInfo = AllRouteInfo.tnaDetailTaskList;
            let parentChildList = AllRouteInfo.tnaParentChildList;
            let childList = AllRouteInfo.tnaChildList;
            let Comments = AllRouteInfo.tnaCommentsList;
            let Attachments = AllRouteInfo.tnaAttachmentsList;

            let routeInformation = [];
            if (AllRouteInfo !== undefined) {
                showLoader();
                if (routeInfo !== undefined) {
                    if (routeInfo.length > 0) {
                        //setInputFields([]);

                        routeInfo.map((route, i) => {

                            let employee1 = "";
                            let employee2 = "";
                            let employee3 = "";

                            if (route.taskHolderName !== null && route.taskHolderName !== undefined) {
                                let TaskHolderName = route.taskHolderName.split('-');
                                if (TaskHolderName[0] !== undefined) {
                                    employee1 = TaskHolderName[0].trim();

                                    if (TaskHolderName[1] !== undefined) {
                                        let FollowerName = TaskHolderName[1].split(',');
                                        if (FollowerName[0] !== undefined) {
                                            employee2 = FollowerName[0].trim();

                                            if (FollowerName[1] !== undefined) {
                                                employee3 = FollowerName[1].trim();
                                            } else {
                                                employee3 = "";
                                            }
                                        } else {
                                            employee2 = "";
                                            employee3 = "";
                                        }

                                    } else {
                                        employee2 = "";
                                        employee3 = "";
                                    }
                                }
                            }


                            // let StartDate = new Date(route.planStartDate);
                            // StartDate.setMinutes(StartDate.getMinutes() + 370);
                            // let EndDate = new Date(route.endDate);
                            // EndDate.setMinutes(EndDate.getMinutes() + 370);
                            let ImageUrl = "";

                            if (route.taskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || route.taskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER || route.taskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || route.taskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || route.taskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT || route.taskName.toLowerCase() === StaticTask.STRIKEOFF_TEST) {
                                ImageUrl = 'Images/Style/Strikeoff/';
                            } else if (route.taskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || route.taskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || route.taskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || route.taskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || route.taskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || route.taskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER || route.taskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER || route.taskName.toLowerCase() === StaticTask.TRIMS_TEST) {
                                ImageUrl = 'Images/Style/Trim/';
                            }


                            let EndDate = route.endDate;
                            let ActualEndDate = route.actualEndDate;

                            let IsSingleTask = !!!TNAMultiTask.find(element => element.toLowerCase() === route.taskName.toLowerCase());
                            routeInformation.push({
                                TNARouteApplyID: route.tnaRouteApplyID,
                                TaskFieldEnable: 0,
                                TaskType: route.taskType,
                                TaskID: route.taskNameID,
                                strTaskName: route.taskName,
                                EndDate: new Date(route.endDate),
                                StrEndDate: EndDate,
                                StartDate: new Date(route.planStartDate),
                                StrStartDate: route.planStartDate,
                                Duration: route.duration,
                                intDependency: parseInt(route.dependencyStartstop),
                                arrDependency: [],
                                arrComment: [],
                                arrAttachment: [],
                                arrManual: [],
                                ManualComments: route.manualCloseComment,
                                //ManualCloseDate: route.ManualCloseDate,
                                CommentsCount: route.taskCommentsCount,
                                AttachmentCount: route.taskDocumentsCount,
                                TaskHolderList: '',
                                TaskHolderOrFollower: route.taskHolderID,
                                TaskHolderOwnerTxtList: route.taskOwnerNames,
                                TaskHolderIDList: route.taskHolderIDList,

                                FollowerIDList1: route.followerIDList1,
                                FollowerIDList2: route.followerIDList2,
                                TaskHolderBuyer: route.taskHolderIDList,
                                FollowerCube: route.followerIDList1,
                                FollowerSupplier: route.followerIDList2,
                                TaskHolderOwnerIdList: route.taskOwner,
                                titleEmployee1: employee1,
                                titleEmployee2: employee2,
                                titleEmployee3: employee3,
                                LeaveDays: route.leaveDays,
                                IsTHFirstTime: 0,
                                LeaveDaysTxt: route.leaveDayName,
                                AdditionalFollowup: additionalInfo.filter(x => x.indexName === route.indexName && x.isTaskSelected === 1).length,
                                IsFollowupExist: additionalInfo.filter(x => x.indexName === route.indexName).length,
                                DependencyCount: dependentInfo.filter(x => x.indexName === route.indexName && x.dependencyID !== 0).length,
                                IndexName: route.indexName,
                                //PreferenceId: route.preferenceId,
                                IsPopupOpen: 0,
                                IsDependencyOpen: 0,
                                IsTHLeaveDaysOpen: 0,
                                IsComment: 0,
                                IsAttachment: 0,
                                IsSelectAll: route.isSelectAll,
                                FollowupDetails: [],
                                SelectedDependency: route.dependency,
                                arrTaskHolderBuyer: [],
                                arrFollowerCube: [],
                                arrFollowerSupplier: [],
                                TaskHolderBuyerTxt: '',
                                FollowerCubeTxt: '',
                                FollowerSupplierTxt: '',
                                IsSingleTask: IsSingleTask,
                                IsNewRow: true,
                                StatusID: route.routeApplyStatus === 0 ? 1 : route.routeApplyStatus,
                                // StatusName: Statusname,
                                AWBStatus: route.awbStatus,
                                ActualEndDate: route.actualEndDate,
                                StrActualEndDate: ActualEndDate,
                                ManualCloseDate: route.actualEndDate,
                                SubTaskList: [],
                                TaskTypeName: route.taskTypeName,
                                Dependency: route.dependency,
                                IDuration: route.duration,
                                IEndDate: route.endDate,
                                IStartDate: route.planStartDate,
                                RevicePopup: 0,
                                IsReviced: route.isReviced,
                                DependencyDate: route.dependencyDate,
                                DependencyComment: route.dependencyComment,
                                IsRescheduled: route.isRescheduled,
                                IsDependencyStartPopup: false,
                                ReviceSelected: 0,
                                RescheduleSelected: 0,
                                ReschedulePopup: 0,
                                MainRevicedetails:
                                {
                                    TNATaskReviceId: 0, TNAId: 0, TaskDetailId: 0, ReviceDate: '',
                                    ReviceComment: '', TaskName: '', Operation: 0,
                                    StartDate: '', EndDate: '', Duration: 0, IsInitialData: 0,
                                    Createdby: 0, Type: ''
                                },
                                MainRescheduledetails:
                                {
                                    TNATaskRescheduleId: 0, TNAId: 0, TaskDetailId: 0, RescheduleDate: '',
                                    RescheduleComment: '', TaskName: '', Operation: 0,
                                    StartDate: '', EndDate: '', Duration: 0, IsInitialData: 0,
                                    Createdby: 0, Type: ''
                                },
                                ReviceAdded: 0,
                                RescheduleAdded: 0,
                                DetailTypeID: route.detailTypeID
                                //TNAId:route.tnaId,


                            });


                            if (IsSingleTask === false) {
                                parentChildList && parentChildList.filter(d => d.taskID === route.taskNameID).map((element, p) => {
                                    routeInformation[i].SubTaskList.push({
                                        ParentId: element.parentId,
                                        TaskID: element.taskID,
                                        MainId: element.mainId,
                                        Code: element.code,
                                        Color: element.color,
                                        ColorOrName: element.colorOrName,
                                        ImagePath: element.imagePath,
                                        ImageUrl: ImageUrl,
                                        StrikeOffOrTrimInfoId: element.strikeOffIdOrTrimInfoId,
                                        IsExpanded: false,
                                        // ParentIndex: p,
                                        ChildTaskList: []
                                    })

                                    childList && childList.filter(d => d.taskID === route.taskNameID && d.mainId === element.mainId).map((child, c) => {
                                        // let childStartDate = isLastEdit ? new Date(child.planStartDate) : new Date(route.planStartDate);
                                        // childStartDate.setMinutes(childStartDate.getMinutes() + 370);
                                        // let childEndDate = isLastEdit ? new Date(child.endDate) : new Date(route.endDate);
                                        // childEndDate.setMinutes(childEndDate.getMinutes() + 370);

                                        let EndDate = child.endDate;
                                        let ActualEndDate = child.actualEndDate;

                                        routeInformation[i].SubTaskList[p].ChildTaskList.push({
                                            ChildId: child.childId,
                                            SubId: child.subId,
                                            Name: child.name,
                                            // TaskName: child.taskName,
                                            TaskName: route.taskName,
                                            TaskID: child.taskID,
                                            MainId: child.mainId,
                                            StrEndDate: EndDate,
                                            StrStartDate: route.planStartDate,
                                            Duration: isLastEdit ? child.duration : route.duration,
                                            EndDate: isLastEdit ? new Date(child.endDate) : new Date(route.endDate),
                                            StartDate: isLastEdit ? new Date(child.planStartDate) : new Date(route.planStartDate),
                                            // EndDate: isLastEdit ? moment(child.endDate).toDate() : moment(child.endDate).toDate(),
                                            // StartDate: isLastEdit ? moment(child.planStartDate).toDate() : moment(child.planStartDate).toDate(),
                                            // arrDependency: [],
                                            TaskHolderList: '',
                                            TaskHolderOrFollower: route.taskHolderID,
                                            TaskHolderOwnerTxtList: route.taskOwnerNames,
                                            TaskHolderIDList: route.taskHolderIDList,
                                            FollowerIDList1: route.followerIDList1,
                                            FollowerIDList2: route.followerIDList2,
                                            TaskHolderBuyer: route.taskHolderIDList,
                                            FollowerCube: route.followerIDList1,
                                            FollowerSupplier: route.followerIDList2,
                                            arrTaskHolderBuyer: [],
                                            arrFollowerCube: [],
                                            arrFollowerSupplier: [],
                                            TaskHolderBuyerTxt: '',
                                            FollowerCubeTxt: '',
                                            FollowerSupplierTxt: '',
                                            TaskHolderOwnerIdList: '',
                                            titleEmployee1: employee1,
                                            titleEmployee2: employee2,
                                            titleEmployee3: employee3,
                                            IsTHFirstTime: 0,
                                            DependencyCount: dependentInfo.filter(x => x.indexName === route.indexName && x.dependencyID !== 0).length,
                                            // IsDependencyOpen: 0,
                                            // IsTHLeaveDaysOpen: 0,
                                            SelectedDependency: route.dependency,
                                            LeaveDays: '',
                                            LeaveDaysTxt: route.leaveDayName,
                                            StatusID: isLastEdit ? child.routeApplyStatus : 1,
                                            AWBStatus: '',
                                            IsAddChildTaskOpen: 0,
                                            StrikeOffOrTrimInfoId: child.strikeOffIdOrTrimInfoId,
                                            // ParentIndex: p,
                                            // ChildIndex: c,
                                            drpChildTaskList: [],
                                            IsManualClose: 0,
                                            ManualCloseComment: child.manualCloseComment,
                                            //ManualCloseDate: child.manualCloseDate,
                                            StrActualEndDate: ActualEndDate,
                                            IsShowSubmitButton: child.isShowSubmitButton,
                                            IsShowApprovalButton: child.isShowApprovalButton,
                                            ManualCloseTaskwise: child.ManualClose,
                                            ChildActualEndDate: child.actualEndDate,
                                            ManualCloseDate: child.actualEndDate === null || child.actualEndDate === "" ? '' : new Date(child.actualEndDate),
                                            RevicePopup: 0,
                                            IsReviced: child.isReviced,
                                            intDependency: child.dependencyStartstop,
                                            DependencyDate: child.dependencyDate,
                                            DependencyComment: child.dependencyComment,
                                            IsRescheduled: child.isRescheduled,
                                            IsDependencyStartPopup: false,
                                            IDuration: isLastEdit ? child.duration : route.duration,
                                            IEndDate: isLastEdit ? new Date(child.endDate) : new Date(route.endDate),
                                            IStartDate: isLastEdit ? new Date(child.planStartDate) : new Date(route.planStartDate),
                                            ReviceAdded: 0,
                                            RescheduleAdded: 0,
                                            ReviceSelected: 0,
                                            RescheduleSelected: 0,
                                            ReschedulePopup: 0,
                                            SubRevicedetails:
                                            {
                                                TNATaskReviceId: 0, TNAId: 0, TaskDetailId: 0, ReviceDate: '',
                                                ReviceComment: '', TaskName: '', Operation: 0,
                                                StartDate: '', EndDate: '', Duration: 0, IsInitialData: 0,
                                                Createdby: 0, Type: ''
                                            },
                                            SubRescheduledetails:
                                            {
                                                TNATaskRescheduleId: 0, TNAId: 0, TaskDetailId: 0, RescheduleDate: '',
                                                RescheduleComment: '', TaskName: '', Operation: 0,
                                                StartDate: '', EndDate: '', Duration: 0, IsInitialData: 0,
                                                Createdby: 0, Type: ''
                                            },
                                            SwatchStatus: child.swatchStatus,
                                            SKUIdList: child.skuIdList,
                                            StyleStrikeOffGarmentSizeIdList: child.styleStrikeOffGarmentSizeIdList,
                                            PurchaseOrderSkuID: child.purchaseOrderSkuID,
                                            TNAId: route.tnaId,

                                        });
                                    });


                                });
                            }

                            // if (routeInformation[i].SubTaskList.length === 0 && IsSingleTask === false) {
                            //     routeInformation.splice(i, 1);
                            // }

                        });
                    }

                    //For empty child list have remove parent task
                    // for (let index = 0; index < routeInformation.length; index++) {
                    //     //const element = routeInformation[index];
                    //
                    //     if (routeInformation[index].SubTaskList.length === 0 && routeInformation[index].IsSingleTask === false) {
                    //         routeInformation.splice(index, 1);
                    //         index = index - 1;
                    //     }

                    // }

                    if (dependentInfo && dependentInfo.length > 0) {
                        dependentInfo.map((element, i) => {
                            let DependencyId = 0;
                            DependencyId = element.tnaDependencyID;
                            try {
                                routeInformation && routeInformation[parseInt(element.indexName)].arrDependency && routeInformation[parseInt(element.indexName)].arrDependency.push({
                                    TNADependencyID: DependencyId,
                                    DependentTaskName: element.dependentTaskName,
                                    TNATaskName: element.tnaTaskName,
                                    DependencyID: element.dependencyID,
                                    LogDays: element.logDays,
                                    IndexName: element.indexName
                                })
                            } catch
                            { }


                        })
                    }

                    if (additionalInfo.length > 0) {
                        routeInformation.map((add, j) => {
                            additionalInfo.filter(x => x.indexName === add.IndexName).map((element, i) => {

                                routeInformation[j].FollowupDetails.push({
                                    TNAAddTaskID: element.tnaAddTaskID,
                                    AdditonalTaskDetailsID: element.additonalTaskDetailsID,
                                    AddtionalTaskName: element.addtionalTaskName,
                                    IsTaskSelected: element.isTaskSelected,
                                    IndexName: element.indexName
                                })
                            });

                        })
                    }

                    if (Comments.length > 0) {
                        routeInformation.map((add, j) => {
                            Comments.filter(x => x.taskIndexName === add.IndexName).map((element, i) => {
                                //
                                routeInformation[j].arrComment.push({
                                    TNACommentsID: element.tnaCommentsID,
                                    TNAId: element.tnaId,
                                    TNAComments: element.tnaComments,
                                    IndexName: element.indexName,
                                    TaskIndexName: element.taskIndexName,
                                    //Date1: element.createdDate.toString(),
                                    Date: new Date(element.createdDate),
                                    //Date: element.createdDate.toLocaleDateString('en-US'),
                                    //ModifiedDate: element.modifiedDate
                                })
                            });

                        })
                    }

                    if (Attachments.length > 0) {
                        //setFiles([]);
                        routeInformation.map((add, j) => {
                            Attachments.filter(x => x.taskIndexName === add.IndexName).map((element, i) => {

                                routeInformation[j].arrAttachment.push({
                                    TNAFileID: element.tnaFileID,
                                    TNAId: element.tnaId,
                                    FileName: element.fileName,
                                    FilePath: element.filePath,
                                    FileType: element.fileType,
                                    IndexName: element.indexName,
                                    TaskIndexName: parseInt(element.taskIndexName),
                                    Date1: element.createdDate,
                                    //Date: element.createdDate.toLocaleDateString('en-US'),
                                    ModifiedDate: element.modifiedDate
                                })




                            });

                        })
                    }
                    setInputFields(routeInformation);
                    hideLoader();

                } else { setInputFields(routeInformation); hideLoader(); }
            } else {
                hideLoader();
            }
        } else {
            hideLoader();
        }
    },
        [AllRouteInfo.tnaDetailList]
    );
    const values = [...inputFields];
    const MainValues = [...MainFields];

    useEffect(() => {
        if (!isLoadingTNA) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingTNA])




    useMemo(
        () => {

            let Optionvalue = [];
            taskHolderList && taskHolderList.map(element => {
                let LabelColor = '';
                if (element.label === 'Buyer - -') {
                    LabelColor = <><span style={{ color: "#0b0992" }}><b>Buyer</b></span></>
                }
                else if (element.label === 'Buyer - Cube') {
                    LabelColor = <><span style={{ color: "#0b0992" }}><b>Buyer</b></span> - <span style={{ color: "#00a65a" }}><b>Cube</b></span></>
                }
                else if (element.label === 'Buyer - Supplier') {
                    LabelColor = <><span style={{ color: "#0b0992" }}><b>Buyer</b></span> - <span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                }
                else if (element.label === 'Buyer - Cube,Supplier') {
                    LabelColor = <><span style={{ color: "#0b0992" }}><b>Buyer</b></span> - <span style={{ color: "#00a65a" }}><b>Cube</b></span>, <span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                }
                else if (element.label === 'Cube - -') {
                    LabelColor = <><span style={{ color: "#00a65a" }}><b>Cube</b></span></>
                }
                else if (element.label === 'Cube - Buyer') {
                    LabelColor = <><span style={{ color: "#00a65a" }}><b>Cube</b></span> - <span style={{ color: "#0b0992" }}><b>Buyer</b></span></>
                }
                else if (element.label === 'Cube - Supplier') {
                    LabelColor = <><span style={{ color: "#00a65a" }}><b>Cube</b></span> - <span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                }
                else if (element.label === 'Cube - Buyer,Supplier') {
                    LabelColor = <><span style={{ color: "#00a65a" }}><b>Cube</b></span> - <span style={{ color: "#0b0992" }}><b>Buyer</b></span>, <span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                }
                else if (element.label === 'Supplier - -') {
                    LabelColor = <><span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                }
                else if (element.label === 'Supplier - Buyer') {
                    LabelColor = <><span style={{ color: "#e27607" }}><b>Supplier</b></span> - <span style={{ color: "#0b0992" }}><b>Buyer</b></span></>
                }
                else if (element.label === 'Supplier - Cube') {
                    LabelColor = <><span style={{ color: "#e27607" }}><b>Supplier</b></span> - <span style={{ color: "#00a65a" }}><b>Cube</b></span></>
                }
                else if (element.label === 'Supplier - Buyer,Cube') {
                    LabelColor = <><span style={{ color: "#e27607" }}><b>Supplier</b></span> - <span style={{ color: "#0b0992" }}><b>Buyer</b></span>, <span style={{ color: "#00a65a" }}><b>Cube</b></span></>
                }

                Optionvalue.push({
                    value: element.value,
                    label: LabelColor,
                    subLabel: element.label,
                })
            });

            setTaskHolderList(Optionvalue);

        },
        [taskHolderList]
    );




    //sathish 12/11/21
    // useEffect(() => {
    //     LoadTNABasedValues(parseInt(props.location.state.params.buyerID));
    //         TNAService.GetSelectedTNA(props.location.state.params.TNAID).then((response) => {
    //             if (response.data) {
    //                  let DocsTNA = response.data.getTNA;
    //                   if (DocsTNA.length > 0) {
    //                     DocsTNA.forEach((docs, i) => {
    //                         filevals.push({

    //                             FileName: docs.fileName,
    //                             FilePath: docs.filePath,
    //                             FileType: docs.fileType,
    //                             IsDeleted: 0
    //                         })
    //                     });
    //                     setFiles(filevals);
    //                 }
    //                 setInputFields(values);
    //             }
    //         })
    //             .catch(() => { })
    //             .finally(() => { setres(0); });
    //     }
    // ,[]);



    useEffect(() => {
        // dispatch(loadTNARouteList(PurchaseOrderIDList));
        if (getTaskTypeList.length === 0) {
            dispatch(loadTaskTypeList(2));
        }
        if (getLeaveDaysList.length === 0) {
            dispatch(loadLeaveDaysList());
        }

        if (ExistingList.length === 0) {
            dispatch(retrieveTask(0));
        }

        if (getAllTaskList.length === 0) {
            dispatch(loadTaskList(0));
        }

        if (getDependencyList.length === 0) {
            dispatch(loadRouteDependencyList());
        }

        if (taskHolderList === undefined || taskHolderList.length === 0) {

            dispatch(loadTaskHolderList());
        }

        if (getCompanyHolidayList.length === 0) {
            dispatch(getTNACompanyHolidayList(currentUser.companyBuyerSupID));
        }

    }, []);


    const handleAddFields = (index) => {

        let count = index;
        count++;
        let EmptyList = {};
        EmptyList =
        {
            TNARouteApplyID: 0,
            TaskFieldEnable: 1,
            TaskType: 0,
            TaskID: 0,
            strTaskName: '',
            EndDate: new Date(),
            StartDate: new Date(),
            Duration: 1,
            intDependency: 0,
            arrDependency: [],
            arrComment: [],
            arrManual: [],
            ManualComments: '',
            CommentsCount: 0,
            AttachmentCount: 0,
            TaskHolderList: '',
            TaskHolderOrFollower: 0,
            TaskHolderOwnerTxtList: '',
            TaskHolderIDList: '',
            FollowerIDList1: '',
            FollowerIDList2: '',
            TaskHolderBuyer: '',
            FollowerCube: '',
            FollowerSupplier: '',
            TaskHolderOwnerIdList: '',
            titleEmployee1: '',
            titleEmployee2: '',
            titleEmployee3: '',
            LeaveDays: 1,
            IsTHFirstTime: 0,
            LeaveDaysTxt: 'None',
            AdditionalFollowup: 0,
            IsFollowupExist: 0,
            DependencyCount: 0,
            IndexName: 0,
            IsPopupOpen: 0,
            IsDependencyOpen: 0,
            IsTHLeaveDaysOpen: 0,
            IsComment: 0,
            IsAttachment: 0,
            IsSelectAll: 1,
            FollowupDetails: [],
            SelectedDependency: '',
            arrTaskHolderBuyer: [],
            arrFollowerCube: [],
            arrFollowerSupplier: [],
            TaskHolderBuyerTxt: '',
            FollowerCubeTxt: '',
            FollowerSupplierTxt: '',
            IsSingleTask: true,
            IsNewRow: false,
            StatusID: 1,
            AWBStatus: '',
            SubTaskList: []
        }

        values.splice(count, 0, EmptyList);

        values.map((Item, i) => {
            //for (let i = 0; i < values.length; i++) {
            let TaskDependency = [];

            values.map((Item2) => {
                // for (let j = 0; j < values.length; j++) {
                if (Item.arrDependency.length > 0) {

                    let AlreadyExist = Item.arrDependency.filter(x => x.DependentTaskName === Item2.TaskID);
                    let DependencyID = 0;
                    let LogDays = '';
                    if (AlreadyExist.length === 0) {
                        DependencyID = 0;
                        LogDays = '';
                    }
                    else {
                        DependencyID = AlreadyExist[0].DependencyID;
                        LogDays = AlreadyExist[0].LogDays;
                    }

                    TaskDependency.push(
                        {
                            TNADependencyID: 0,
                            DependentTaskName: Item2.TaskID,
                            TNATaskName: Item2.strTaskName,
                            DependencyID: DependencyID,
                            LogDays: LogDays,
                            IndexName: (i).toString()
                        }
                    )
                }
                else {
                    TaskDependency.push(
                        {
                            TNADependencyID: 0,
                            DependentTaskName: Item2.TaskID,
                            TNATaskName: Item2.strTaskName,
                            DependencyID: 0,
                            LogDays: '',
                            IndexName: (i).toString()
                        }
                    )
                }
            });
            Item.arrDependency = [];

            Item.arrDependency = TaskDependency;

            let DependencyShow = '';
            Item.arrDependency.map((Item3, k) => {
                // for (let k = 0; k < values[i].arrDependency.length; k++) {
                if (i !== k) {
                    let DependentID = Item3.DependencyID;
                    if (DependentID !== 0) {
                        if (DependencyShow === '') {
                            DependencyShow = (k + 1) + '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : DependentID === 4 ? "EE" : "")
                        }
                        else {
                            DependencyShow = DependencyShow + ',' + (k + 1) + '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : DependentID === 4 ? "EE" : "")
                        }
                    }
                }
            });

            Item.SelectedDependency = DependencyShow;
            Item.DependencyCount = Item.arrDependency.filter(x => x.DependencyID !== 0).length;
            //Item.PreferenceId = i + 1;

            values[i] = Item;

        });

        // if (values.length > 0) {
        //     for (var z = 0; z < values.length; z++) {
        //         values[z].PreferenceId = z + 1;
        //     }
        // }

        setInputFields(values);
        setSubmitted(false);
    };

    // #region  ------------------  Expanded Toggle --------------------------------

    const handleToggle = (index) => {
        var btnToggle = document.getElementById("btn-toggle_" + index);
        var iconToggle = document.getElementById("icon-toggle_" + index);
        var rowHiddens = document.querySelectorAll(".parent_" + index);
        //var rowChildHiddens = document.querySelectorAll(".child_" + index);

        var ariaExpanded = btnToggle.getAttribute('aria-expanded');
        if (ariaExpanded === 'false') {
            let i = 0;
            for (let row of rowHiddens) {
                row.className = row.className.replace("hidden", " ");
                values[index].SubTaskList[i].IsExpanded = true;
                i++;
            }
            btnToggle.setAttribute('aria-expanded', 'true');
            iconToggle.className = iconToggle.className.replace("fa-plus-square-o", "fa-minus-square-o");
            setInputFields(values);
        }
        else {
            let i = 0;
            for (let row of rowHiddens) {
                if (!row.className.includes("hidden")) {
                    row.className = row.className + ' hidden';

                    values[index].SubTaskList[i].IsExpanded = false;
                }
                i++;
            }
            // for (let row2 of rowChildHiddens) {
            //
            //     if (!row2.className.includes("hidden")) {
            //         row2.className = row2.className + ' hidden';
            //         try {
            //             let dataChild = row2.classList[1];
            //         var btnToggleChild = document.getElementById("btn-toggle_" + dataChild);
            //         var iconToggleChild = document.getElementById("icon-toggle_" + dataChild);
            //             btnToggleChild.setAttribute('aria-expanded', 'false');

            //             iconToggleChild.className = iconToggleChild.className.replace("fa-minus-square-o", "fa-plus-square-o");
            //         } catch { }
            //     }
            // }
            btnToggle.setAttribute('aria-expanded', 'false');
            iconToggle.className = iconToggle.className.replace("fa-minus-square-o", "fa-plus-square-o");
            setInputFields(values);
        }
    };

    const handleChildToggle = (index, childIndex) => {
        var btnToggle = document.getElementById("btn-toggle_parentChild_" + index + "_" + childIndex);
        var iconToggle = document.getElementById("icon-toggle_parentChild_" + index + "_" + childIndex);
        var rowHiddens = document.querySelectorAll(".parentChild_" + index + "_" + childIndex);
        var ariaExpanded = btnToggle.getAttribute('aria-expanded');
        if (ariaExpanded === 'false') {
            values[index].SubTaskList[childIndex].IsExpanded = true;

            // for (let row of rowHiddens) {
            //     row.className = row.className.replace("hidden", " ");
            // }
            btnToggle.setAttribute('aria-expanded', 'true');
            iconToggle.className = iconToggle.className.replace("fa-plus-square-o", "fa-minus-square-o");
        }
        else {
            // for (let row of rowHiddens) {
            //     if (!row.className.includes("hidden")) {
            //         row.className = row.className + ' hidden';
            //     }
            // }

            values[index].SubTaskList[childIndex].IsExpanded = false;

            btnToggle.setAttribute('aria-expanded', 'false');
            iconToggle.className = iconToggle.className.replace("fa-minus-square-o", "fa-plus-square-o");
        }
        setInputFields(values);
    };

    // #endregion  ------------------  Expanded Toggle End --------------------------------

    //Gowtham
    const handleChangeTNAComments = (event, index) => {
        //  const values = { ...inputFields };

        let input = '';

        if (event.target.name === 'Comments') {
            if (event.target.value.trim() !== '') {
                input = event.target.value;
            }
            values[index].Comments = input;

        }
        else {
            values[index].ManualComments = input;
            values[index].ManualCloseDate = new Date();

        }

        setInputFields(values);

    }
    const handleSave = (index, Childindex, assignValue, TaskID) => {
        let IsValid = true;
        if (assignValue === '' || assignValue === undefined) {
            setComment(true); IsValid = false;
            Nodify('Warning!', 'warning', "Please enter comments");
        }
        if (IsValid) {
            if (values[index].Comments.length !== 0) {
                if (values[index].Comments !== '') {
                    if (EditedCommentIndex !== '') {
                        values[index].arrComment[Childindex - 1].TNAComments = assignValue;
                    }
                    else {
                        values[index].arrComment.push({
                            TNACommentsID: 0, TaskNameId: TaskID, TNAComments: assignValue, IndexName: values[index].arrComment.length.toString(), TaskIndexName: (index).toString(),
                            Date: new Date(),

                        });
                    }
                }
                else {
                    values[index].arrComment = {
                        TNACommentsID: 0, TaskNameId: TaskID, TNAComments: assignValue, IndexName: values[index].arrComment.length.toString(), TaskIndexName: (index).toString(),
                        Date: new Date(),
                    };
                }
            }
            else {
                values[index].arrComments.push({
                    TNACommentsID: 0, TaskNameId: TaskID, TNAComments: assignValue, IndexName: values[index].arrComment.length.toString(), TaskIndexName: (index).toString(),
                    Date: new Date(),
                });
            }
            values[index].Comments = '';
            values[index].IsComment = 0;
            values[index].CommentsCount = values[index].arrComment.length;
            setInputFields(values);
            setEditedCommentIndex('');
        }
    };

    const handleRemoveFields = index => {
        values.splice(index, 1);
        values.map((Item, j) => {
            //for (let j = 0; j < values.length; j++) {
            Item.arrDependency.splice(index, 1);

            Item.SelectedDependency = '';
            let DependencyShow = '';
            Item.arrDependency.map((Item2, i) => {
                //for (let i = 0; i < values[j].arrDependency.length; i++) {
                if (j !== i) {
                    let DependentID = Item2.DependencyID;
                    if (DependentID !== 0) {
                        if (DependencyShow === '') {
                            DependencyShow = (i + 1) + '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : "EE")
                        }
                        else {
                            DependencyShow = DependencyShow + ',' + (i + 1) + '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : "EE")
                        }
                    }
                }
            });
            Item.SelectedDependency = DependencyShow;
            Item.DependencyCount = Item.arrDependency.filter(x => x.DependencyID !== 0).length;
            values[j] = Item;
        });

        setInputFields(values);
        commonCalculation(false, false);
        DisabeSelectedTaskName();
    };

    const handleChangeChild = (e, index, subIndex, childIndex, FieldName) => {
        setSubmitted(false);
        let inputText = '';

        if (FieldName === "Duration") {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            var checkNumber = inputText.replace(/\D/g, '').match('^[0-9]*$');
            inputText = checkNumber[0];
            if (parseInt(inputText) === 0) {
                inputText = '';
            }
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].Duration = inputText;
            if (inputText !== '') {
                let durationVal = parseInt(inputText);
                let parentDuration = values[index].Duration;
                if (durationVal > parseInt(parentDuration)) {
                    values[index].Duration = durationVal;
                }
            }

        }
        else if (FieldName === "EndDate") {
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].EndDate = e;
        }
        else if (FieldName === "StatusID") {
            if (e != null) {
                inputText = parseInt(e.value);
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StatusID = inputText;
            }
        }
        else {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex][e.target.name] = inputText;
        }

        if (FieldName !== "TaskName") {
            setInputFields(values);
            if (FieldName === "EndDate") {
                commonCalculation(false, true);
            }
        }
    }

    const handleChange = (e, index, FieldName) => {


        setSubmitted(false);
        let inputText = '';
        if (FieldName === "IsSelectAll") {
            if (e.target.checked) {
                values[index].FollowupDetails.map((Item, i) => {
                    //for (var i = 0; i < values[index].FollowupDetails.length; i++) {
                    values[index].FollowupDetails[i].IsTaskSelected = 1;
                });

                values[index][FieldName] = 1;
            } else {
                values[index].FollowupDetails.map((Item, i) => {
                    //for (var i = 0; i < values[index].FollowupDetails.length; i++) {
                    values[index].FollowupDetails[i].IsTaskSelected = 0;
                });
                values[index][FieldName] = 0;

            }

            values[index].AdditionalFollowup = values[index].FollowupDetails.filter(x => x.IsTaskSelected === 1).length;
            values[index].IsFollowupExist = values[index].FollowupDetails.length;

        }
        else if (FieldName === "TaskType") {
            if (e != null) {
                inputText = parseInt(e.value);
                values[index].TaskID = 0;
                values[index].TaskHolderOrFollower = 0;
                values[index].TaskHolderOrFollowerTxt = '';
                values[index].LeaveDays = 0;
                values[index].LeaveDaysTxt = "";
                values[index].AdditionalFollowup = 0;
                values[index].IsFollowupExist = 0;
                values[index].strTaskName = '';
                values[index].FollowupDetails = [];
                var SelectedIndex = getTaskTypeList.findIndex(x => x.value === e.value);
                getTaskTypeList[SelectedIndex].isdisabled = true;
            }
            else {
                inputText = 0;
                values[index].TaskID = 0;
                values[index].TaskHolderOrFollower = 0;
                values[index].TaskHolderOrFollowerTxt = '';
                values[index].LeaveDays = 0;
                values[index].LeaveDaysTxt = "";
                values[index].AdditionalFollowup = 0;
                values[index].IsFollowupExist = 0;
                values[index].strTaskName = '';
                values[index].FollowupDetails = [];

            }
            values[index][FieldName] = inputText;
            DisabeSelectedTaskName();

        }
        else if (FieldName === "TaskName") {
            showLoader();
            if (e != null) {
                inputText = parseInt(e.value);
                let routeInfo = AllRouteInfo.tnaDetailList;
                let additionalInfo = AllRouteInfo.tnaDetailTaskList;
                let parentChildList = AllRouteInfo.tnaParentChildList;
                let childList = AllRouteInfo.tnaChildList;
                let IsSingleTask = !!!TNAMultiTask.find(element => element.toLowerCase() === e.label.toLowerCase());
                const existingTask = routeInfo.filter(d => d.taskNameID === inputText);

                let Task_Value = getAllTaskList.filter(x => x.value === e.value)[0];
                values[index].TaskFieldEnable = 0;
                values[index].TaskType = Task_Value.taskTypeID;
                values[index].TaskID = inputText;
                values[index].strTaskName = e.label;

                if (existingTask && existingTask.length > 0) {
                    let existingEmployee1 = "";
                    let existingEmployee2 = "";
                    let existingEmployee3 = "";

                    if (existingTask.taskHolderName !== null && existingTask.taskHolderName !== undefined) {
                        let TaskHolderName = existingTask.taskHolderName.split('-');
                        if (TaskHolderName[0] !== undefined) {
                            existingEmployee1 = TaskHolderName[0].trim();

                            if (TaskHolderName[1] !== undefined) {
                                let FollowerName = TaskHolderName[1].split(',');
                                if (FollowerName[0] !== undefined) {
                                    existingEmployee2 = FollowerName[0].trim();

                                    if (FollowerName[1] !== undefined) {
                                        existingEmployee3 = FollowerName[1].trim();
                                    } else {
                                        existingEmployee3 = "";
                                    }
                                } else {
                                    existingEmployee2 = "";
                                    existingEmployee3 = "";
                                }

                            } else {
                                existingEmployee2 = "";
                                existingEmployee3 = "";
                            }
                        }
                    }

                    values[index].Duration = existingTask.duration && existingTask.duration.toString();
                    values[index].TaskHolderOrFollower = existingTask.taskHolderID;
                    values[index].TaskHolderOwnerTxtList = existingTask.taskOwnerNames;
                    values[index].TaskHolderIDList = existingTask.taskHolderIDList;
                    values[index].FollowerIDList1 = existingTask.followerIDList1;
                    values[index].FollowerIDList2 = existingTask.followerIDList2;
                    values[index].TaskHolderBuyer = existingTask.taskHolderIDList;
                    values[index].FollowerCube = existingTask.followerIDList1;
                    values[index].FollowerSupplier = existingTask.followerIDList2;
                    values[index].titleEmployee1 = existingEmployee1;
                    values[index].titleEmployee2 = existingEmployee2;
                    values[index].titleEmployee3 = existingEmployee3;
                    values[index].LeaveDays = existingTask.leaveDays;
                    values[index].LeaveDaysTxt = existingTask.leaveDayName;
                    values[index].AdditionalFollowup = additionalInfo.filter(x => x.indexName === existingTask.indexName && x.isTaskSelected === 1).length;
                    values[index].IsFollowupExist = additionalInfo.filter(x => x.indexName === existingTask.indexName).length;
                    values[index].DependencyCount = additionalInfo.filter(x => x.indexName === existingTask.indexName && x.dependencyID !== 0).length;
                    // values[index].PreferenceId = existingTask.preferenceId;
                    values[index].IsSelectAll = existingTask.isSelectAll;
                    values[index].SelectedDependency = existingTask.dependency;
                    values[index].IsSingleTask = IsSingleTask;

                    if (IsSingleTask === false) {
                        loadNewParentAndChild(parentChildList, childList, existingTask, index, existingEmployee1, existingEmployee2, existingEmployee3, true);

                    }
                    loadAddtionalTaskDetailsList(index, Task_Value, FieldName, inputText);

                    setInputFields(values);
                    hideLoader();
                } else {

                    var RespectiveList = ExistingList.filter(x => x.taskDetailsID === e.value);
                    let employee1 = "";
                    let employee2 = "";
                    let employee3 = "";

                    if (RespectiveList[0].taskHolderName !== null && RespectiveList[0].taskHolderName !== undefined) {
                        let TaskHolderName = RespectiveList[0].taskHolderName.split('-');
                        if (TaskHolderName[0] !== undefined) {
                            employee1 = TaskHolderName[0].trim();

                            if (TaskHolderName[1] !== undefined) {
                                let FollowerName = TaskHolderName[1].split(',');
                                if (FollowerName[0] !== undefined) {
                                    employee2 = FollowerName[0].trim();

                                    if (FollowerName[1] !== undefined) {
                                        employee3 = FollowerName[1].trim();
                                    } else {
                                        employee3 = "";
                                    }
                                } else {
                                    employee2 = "";
                                    employee3 = "";
                                }

                            } else {
                                employee2 = "";
                                employee3 = "";
                            }
                        }
                    }


                    values[index].TaskHolderOrFollower = RespectiveList[0].taskHolderOrFollower;
                    values[index].TaskHolderOrFollowerTxt = '';
                    values[index].LeaveDays = RespectiveList[0].leaveDays;
                    values[index].LeaveDaysTxt = RespectiveList[0].leaveDayName;
                    values[index].AdditionalFollowup = RespectiveList[0].additionalFollowup;
                    values[index].IsFollowupExist = RespectiveList[0].additionalFollowup;
                    values[index].titleEmployee1 = employee1;
                    values[index].titleEmployee2 = employee2;
                    values[index].titleEmployee3 = employee3;
                    values[index].IsSingleTask = IsSingleTask;
                    values[index].Duration = "1";

                    values[index].TaskHolderOwnerTxtList = "";
                    values[index].TaskHolderIDList = "";
                    values[index].FollowerIDList1 = "";
                    values[index].FollowerIDList2 = "";
                    values[index].TaskHolderBuyer = "";
                    values[index].FollowerCube = "";
                    values[index].FollowerSupplier = "";
                    values[index].DependencyCount = 0;
                    values[index].IsSelectAll = "";
                    values[index].SelectedDependency = "";

                    loadAddtionalTaskDetailsList(index, Task_Value, FieldName, inputText);

                    if (!IsSingleTask) {
                        dispatch(getTNAEditTaskWiseChildList({ TNAId: props.location.state.params.tnaid, TaskName: e.label }))
                            .then(data => {
                                if (data != null) {
                                    let newParentChildList = data.tnaParentChildList;
                                    let newChildList = data.tnaChildList;
                                    loadNewParentAndChild(newParentChildList, newChildList, RespectiveList[0], index, employee1, employee2, employee3, false);

                                    setInputFields(values);
                                    hideLoader();
                                }
                            });
                    } else {
                        setInputFields(values);
                        hideLoader();
                    }
                }


            }
            else {
                inputText = 0;
                values[index].TaskType = 0;
                values[index].TaskHolderOrFollower = 0;
                values[index].TaskHolderOrFollowerTxt = '';
                values[index].LeaveDays = 0;
                values[index].LeaveDaysTxt = '';
                values[index].AdditionalFollowup = 0;
                values[index].IsFollowupExist = 0;
                values[index].strTaskName = '';
                values[index][FieldName] = inputText;
                values[index].FollowupDetails = [];

                values.map((Item, i) => {
                    //for (let i = 0; i < values.length; i++) {
                    Item.arrDependency.splice(index, 1);
                    values[i] = Item;
                });
                setInputFields(values);
                hideLoader();
            }
            DisabeSelectedTaskName();
        }
        else if (FieldName === "TaskHolderOrFollower" || FieldName === "LeaveDays") {
            if (e != null) {
                inputText = parseInt(e.value);
                if (FieldName === "TaskHolderOrFollower") {
                    // setTaskHolderID(inputText);

                    values[index].TaskHolderOrFollowerTxt = e.subLabel;
                    let TaskHolderName = e.subLabel.split('-');
                    if (TaskHolderName[0] !== undefined) {
                        values[index].titleEmployee1 = TaskHolderName[0].trim();

                        if (TaskHolderName[1] !== undefined) {
                            let FollowerName = TaskHolderName[1].split(',');
                            if (FollowerName[0] !== undefined) {
                                values[index].titleEmployee2 = FollowerName[0].trim();

                                if (FollowerName[1] !== undefined) {
                                    values[index].titleEmployee3 = FollowerName[1].trim();
                                } else {
                                    values[index].titleEmployee3 = "";
                                }
                            } else {
                                values[index].titleEmployee2 = "";
                                values[index].titleEmployee3 = "";
                            }

                        } else {
                            values[index].titleEmployee2 = "";
                            values[index].titleEmployee3 = "";
                        }
                        if (values[index].titleEmployee1 !== "Cube" && values[index].titleEmployee2 !== "Cube" && values[index].titleEmployee3 !== "Cube") {
                            dispatch(loadTNATaskOwner(inputText, getTNABrandID, getTNASupplierID, 0));
                        } else {
                            dispatch(loadTNATaskOwner(inputText, getTNABrandID, getTNASupplierID, currentUser.employeeinformationID));
                        }

                    } else {
                        values[index].titleEmployee1 = "";
                        values[index].titleEmployee2 = "";
                        values[index].titleEmployee3 = "";
                    }


                } else {
                    values[index].LeaveDaysTxt = e.label;
                }
            }
            else {
                inputText = 0;
                if (FieldName === "TaskHolderOrFollower") {
                    values[index].TaskHolderOrFollowerTxt = '';
                    values[index].titleEmployee1 = "";
                    values[index].titleEmployee2 = "";
                    values[index].titleEmployee3 = "";
                }
                values[index].LeaveDaysTxt = "";
            }
            values[index][FieldName] = inputText;
        }
        else if (FieldName === "Duration") {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            var checkNumber = inputText.replace(/\D/g, '').match('^[0-9]*$');
            inputText = checkNumber[0];
            if (parseInt(inputText) === 0) {
                inputText = '';
            }
            values[index][e.target.name] = inputText;
            if (values[index].IsSingleTask === false) {
                values[index].SubTaskList && values[index].SubTaskList.map((Item2, p) => {
                    Item2.ChildTaskList && Item2.ChildTaskList.map((Item3, c) => {
                        values[index].SubTaskList[p].ChildTaskList[c].Duration = inputText;
                    });
                });
            }
        }
        else if (FieldName === "EndDate") {
            values[index].EndDate = e;

        }
        else if (FieldName === "StatusID") {
            if (e != null) {
                inputText = parseInt(e.value);
                values[index].StatusID = inputText;
            }

        }
        else if (FieldName === "TaskHolderBuyer" || FieldName === "FollowerCube" || FieldName === "FollowerSupplier") {
            let strValue = '';
            let strTxt = '';
            e.map((val) => {
                if (strValue === '') { strValue = val.value.toString(); strTxt = val.label.toString(); }
                else { strValue = strValue + "," + val.value; strTxt = strTxt + ", " + val.label.toString(); }
            });
            values[index]["arr" + FieldName] = e;
            values[index][FieldName] = strValue;
            values[index][FieldName + "Txt"] = strTxt;
        }
        else {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            values[index][e.target.name] = inputText;
        }

        if (FieldName !== "TaskName") {
            setInputFields(values);
            if (FieldName === "EndDate") {
                commonCalculation(false, true);
            }

        }
    }

    function loadNewParentAndChild(parentChildList, childList, existingTask, index, existingEmployee1, existingEmployee2, existingEmployee3, isExisting) {
        values[index].SubTaskList = [];
        parentChildList && parentChildList.filter(d => d.taskID === existingTask.taskDetailsID).map((element, p) => {

            values[index].SubTaskList.push({
                ParentId: 0,
                TaskID: element.taskID,
                MainId: element.mainId,
                Code: element.code,
                Color: element.color,
                ColorOrName: element.colorOrName,
                StrikeOffOrTrimInfoId: element.strikeOffIdOrTrimInfoId,
                ImagePath: element.imagePath,
                IsExpanded: false,
                // ParentIndex: p,
                // TaskIndex: TaskIndex,
                ChildTaskList: []
            })
            childList && childList.filter(d => d.taskID === existingTask.taskDetailsID && d.mainId === element.mainId).map((child, c) => {
                values[index].SubTaskList[p].ChildTaskList.push({
                    ChildId: isExisting ? isLastEdit ? child.childId : 0 : 0,
                    SubId: child.subId,
                    Name: child.name,
                    TaskName: child.taskName,
                    TaskID: child.taskID,
                    MainId: child.mainId,
                    Duration: isExisting ? isLastEdit ? child.duration : existingTask.duration : 1,
                    EndDate: isExisting ? isLastEdit ? new Date(child.endDate) : new Date(existingTask.endDate) : new Date(),
                    StartDate: isExisting ? isLastEdit ? new Date(child.planStartDate) : new Date(existingTask.planStartDate) : new Date(),
                    arrDependency: [],
                    TaskHolderList: '',
                    TaskHolderOrFollower: isExisting ? existingTask.taskHolderID : existingTask.taskHolderOrFollower,
                    TaskHolderOwnerTxtList: isExisting ? existingTask.taskOwnerNames : "",
                    TaskHolderIDList: isExisting ? existingTask.taskHolderIDList : "",
                    FollowerIDList1: isExisting ? existingTask.followerIDList1 : "",
                    FollowerIDList2: isExisting ? existingTask.followerIDList2 : "",
                    TaskHolderBuyer: isExisting ? existingTask.taskHolderIDList : "",
                    FollowerCube: isExisting ? existingTask.followerIDList1 : "",
                    FollowerSupplier: isExisting ? existingTask.followerIDList2 : "",
                    arrTaskHolderBuyer: [],
                    arrFollowerCube: [],
                    arrFollowerSupplier: [],
                    TaskHolderBuyerTxt: '',
                    FollowerCubeTxt: '',
                    FollowerSupplierTxt: '',
                    TaskHolderOwnerIdList: '',
                    titleEmployee1: existingEmployee1,
                    titleEmployee2: existingEmployee2,
                    titleEmployee3: existingEmployee3,
                    IsTHFirstTime: 0,
                    DependencyCount: 0,
                    // IsDependencyOpen: 0,
                    // IsTHLeaveDaysOpen: 0,
                    SelectedDependency: '',
                    LeaveDays: isExisting ? "" : existingTask.leaveDays,
                    LeaveDaysTxt: isExisting ? "" : existingTask.leaveDayName,
                    StatusID: isExisting ? isLastEdit ? child.childId : 1 : 1,
                    AWBStatus: '',
                    IsAddChildTaskOpen: 0,
                    // ParentIndex: p,
                    // ChildIndex: c,
                    StrikeOffOrTrimInfoId: child.strikeOffIdOrTrimInfoId,
                    // TaskIndex: 0,
                    drpChildTaskList: [],
                    IsManualClose: 0,
                    ManualCloseComment: ''
                });
            });
        });

    }

    function loadAddtionalTaskDetailsList(index, Task_Value, FieldName, inputText) {
        taskService.GetAddtionalTaskDetailsList(0, Task_Value.taskInformationID).then((response) => {

            if (response.data) {
                values[index].FollowupDetails = [];
                if (response.data.length !== 0) {
                    if (response.data.filter(x => x.indexName.toString() === Task_Value.indexName.toString()).length > 0) {
                        response.data.filter(x => x.indexName.toString() === Task_Value.indexName.toString()).map(element => {
                            values[index].FollowupDetails.push({
                                TNAAddTaskID: 0,
                                AdditonalTaskDetailsID: element.additonalTaskDetailsID,
                                AddtionalTaskName: element.addtionalTaskName,
                                IsTaskSelected: 1,
                                IndexName: index.toString()
                            });
                        });
                    }
                    else {
                        values[index].AdditionalFollowup = 0;
                        values[index].IsFollowupExist = 0;
                        values[index].FollowupDetails = [];
                    }
                }
                else {
                    values[index].AdditionalFollowup = 0;
                    values[index].IsFollowupExist = 0;
                    values[index].FollowupDetails = [];
                }
                values[index][FieldName] = inputText;

                values.map((Item, j) => {
                    // for (var j = 0; j < values.length; j++) {
                    let MSG = '';
                    if (index !== j) {
                        if (values[index].TaskType !== 0) { // for avoid empty validation
                            if (Item.TaskType === values[index].TaskType &&
                                Item.TaskID === values[index].TaskID) {
                                if (FieldName === "TaskName") {
                                    values[index].TaskID = 0;
                                    MSG = "This task name is already exist.";
                                }
                                values[j] = Item;
                                setInputFields(values);
                                Nodify('Warning!', 'warning', MSG);
                                return false;
                            }
                        }
                    }
                    values[j] = Item;
                });

            } else {

            }
        });
    }

    //sathish
    const filevals = [...getFiles]
    const AppendFiles = async (e) => {
        const file = e.target.files[0];
        let a = getTaskIndexFileupload;
        let b = getTNAtaskIdFileupload;

        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#TNAFileUpload').val("");
            }
            else {
                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "TNADocUpload");
                try {
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                    if (res.statusText === "Created") { }
                    filevals.push({
                        TNAFileID: 0,
                        TaskNameId: b,
                        FileName: file.name,
                        FilePath: "Images/TNARelatedDocs/" + file.name,
                        FileType: file.type,
                        IndexName: (filevals.length + 1).toString(),
                        TaskIndexName: a,
                    })
                    setFiles(filevals);
                    values[a].arrAttachment = filevals.filter(x => x.TaskIndexName === a);
                    values[a].AttachmentCount = filevals.filter(x => x.TaskIndexName === a).length;
                    setInputFields(values);

                    $('#TNAFileUpload').val("");

                } catch (ex) {
                    console.log(ex);
                }
            }
        }
    }
    const RemoveFiles = async (e, index) => {
        filevals.splice(index, 1);
        values[index].arrAttachment.splice(index, 1);
        values[index].AttachmentCount = filevals.length;
        setInputFields(values);
        setFiles(filevals);
    }
    const ViewFile = (event, filename) => { // event.preventDefault();
        setIsOpenimg(true);
        setImagename(filename);
    }

    const DisabeSelectedTaskName = () => {
        getAllTaskList.forEach((data, i) => {
            getAllTaskList[i].isdisabled = false;
        })

        values.forEach((data, i) => {
            var SelectedIndex = getAllTaskList.findIndex(x => x.value === data.TaskID);
            if (SelectedIndex !== -1) {
                getAllTaskList[SelectedIndex].isdisabled = true;
            }
        })
    }

    const CheckDuplicate = (index, Field) => {

        if (Field === "Duration") {
            commonCalculation(true, false);
        } else {
            for (var j = 0; j < values.length; j++) {
                let MSG = '';
                if (index !== j) {
                    if (values[index].TaskType !== 0 && values[index].TaskID !== 0) { // for avoid empty validation
                        if (values[j].TaskType === values[index].TaskType &&
                            values[j].TaskID === values[index].TaskID) {
                            if (Field === "TaskName") {
                                values[index].TaskID = 0;
                                MSG = "This task name is already exist.";
                            }
                            else if (Field === "TaskType") {
                                values[index].TaskType = 0;
                                MSG = "This task type is already exist.";
                            }
                            setInputFields(values);
                            Nodify('Warning!', 'warning', MSG);
                            return false;
                        }
                    }
                }
            }
        }
    }

    const CheckChildDuplicate = (index, subIndex, childIndex, Field) => {

        if (Field === "Duration") {
            commonCalculation(true, false);
        }

    }

    const handleFollowup = index => {
        if (values[index].TaskID === 0) {
            Nodify('Warning!', 'warning', 'Please select the task name.');
        }
        else {
            if (values[index].IsFollowupExist === 0) {
                Nodify('Warning!', 'warning', "Additional task doesn't exist for this task.");
            }
            else {
                values[index].IsPopupOpen = 1;
                setInputFields(values);
            }
        }
    };

    const handleClose = (index) => {
        values[index].IsPopupOpen = 0;
        setInputFields(values);
    };


    const handleComment = (index, CommentsCount, TnaId, TaskId) => {

        if (values[index].TaskID === 0) {
            Nodify('Warning!', 'warning', 'Please select the task name.');
        }
        else {
            // if (CommentsCount !== 0) {
            //     TnaService.LoadTNACommentsList({ TnaId, TaskId }).then((response3) => {
            //         if (response3.data) {
            //             values[index].arrComment = [];
            //             values[index].arrComment = response3.data;
            //             values[index].IsComment = 1;
            //             setTaskIndexFileupload(index);
            //             setTNAtaskIdFileupload(values[index].TaskID);
            //             setInputFields(values);

            //         }

            //     });
            // }
            // else {
            //     values[index].IsComment = 1;
            //     setTaskIndexFileupload(index);
            //     setTNAtaskIdFileupload(values[index].TaskID);
            //     setInputFields(values);

            // }
            values[index].IsComment = 1;
            setTaskIndexFileupload(index);
            setTNAtaskIdFileupload(values[index].TaskID);
            setInputFields(values);
        }
    };

    const handleCloseComment = (index) => {

        values[index].IsComment = 0;
        setInputFields(values);
    };

    // const TaskIndexFileupload = '';
    // const TNAtaskIdFileupload = '';


    const handleDependent = (index, value) => {

        values[index].intDependency = value === 0 ? 1 : 0;
        setInputFields(values);
    }


    const handleAttachment = (index) => {
        if (values[index].TaskID === 0) {
            Nodify('Warning!', 'warning', 'Please select the task name.');
        }
        else {

            values[index].IsAttachment = 1;
            setTaskIndexFileupload(index);
            setTNAtaskIdFileupload(values[index].TaskID);
            setFiles(values[index].arrAttachment);
            // getFiles.push({
            //     TNAFileID: element.tnaFileID,
            //     TNAId: element.tnaId,
            //     FileName: element.fileName,
            //     FilePath: element.filePath,
            //     FileType: element.fileType,
            //     IndexName: element.indexName,
            //     TaskIndexName: element.taskIndexName,
            //     Date1: element.createdDate,
            //     //Date: element.createdDate.toLocaleDateString('en-US'),
            //     ModifiedDate: element.modifiedDate
            // })
            setInputFields(values);
        }
    };

    const handleCloseAttachment = (index) => {
        values[index].IsAttachment = 0;
        setInputFields(values);
    };



    // #region ------------------  TaskHolderLeaveDays Start --------------------------------


    const handleTaskHolderLeaveDays = (index, isTHFirstTime) => {

        showLoader();
        if (values[index].TaskID === 0) {
            Nodify('Warning!', 'warning', 'Please select the task name.');
        }
        else {

            if (values[index].titleEmployee1 !== "Cube" && values[index].titleEmployee2 !== "Cube" && values[index].titleEmployee3 !== "Cube") {
                // dispatch(loadTNATaskOwner(values[index].TaskHolderOrFollower, 0));
                dispatch(loadTNATaskOwner(values[index].TaskHolderOrFollower, getTNABrandID, ViewSupplierID, 0))
                    .then(data => {
                        if (data != null) {
                            setTNATaskOwner(data, index, isTHFirstTime);
                        }
                        values[index].IsTHLeaveDaysOpen = 1;
                        setInputFields(values);
                        hideLoader();
                    });
            } else {
                // dispatch(loadTNATaskOwner(values[index].TaskHolderOrFollower, currentUser.employeeinformationID));
                dispatch(loadTNATaskOwner(values[index].TaskHolderOrFollower, getTNABrandID, ViewSupplierID, currentUser.employeeinformationID))
                    .then(data => {
                        if (data != null) {
                            setTNATaskOwner(data, index, isTHFirstTime);
                        }
                        values[index].IsTHLeaveDaysOpen = 1;
                        setInputFields(values);
                        hideLoader();
                    });
            }
        }
    };
    function setTNATaskOwner(data, index, isTHFirstTime) {

        if (data.buyerEmployeeList && data.buyerEmployeeList.length > 0) {

            let taskHolderBuyer = values[index].TaskHolderIDList && data.buyerEmployeeList.filter(item => (values[index].TaskHolderIDList.split(',').includes(item.value.toString())));
            if (taskHolderBuyer.length > 0) {

                if (values[index].arrTaskHolderBuyer.length === 0 && isTHFirstTime === 0) {
                    values[index].arrTaskHolderBuyer = [...taskHolderBuyer];
                } else {
                    const isData = !!values[index].arrTaskHolderBuyer.filter(d => d.value === taskHolderBuyer.value);
                    if (isData === false && isTHFirstTime === 0) {
                        values[index].arrTaskHolderBuyer = [...values[index].arrTaskHolderBuyer, ...taskHolderBuyer];
                    }
                }

            }
        }
        if (data.cubeEmployeeList !== undefined) {
            let followerCube = values[index].FollowerIDList1 && data.cubeEmployeeList.filter(item => (values[index].FollowerIDList1.split(',').includes(item.value.toString())));
            if (followerCube.length > 0) {
                if (values[index].arrFollowerCube.length === 0 && isTHFirstTime === 0) {
                    values[index].arrFollowerCube = [...followerCube];
                } else {
                    const isData = !!values[index].arrFollowerCube.filter(d => d.value === followerCube.value);
                    if (isData === false && isTHFirstTime === 0) {
                        values[index].arrFollowerCube = [...followerCube];
                    }
                }
            }
        }
        if (data.supplierEmployeeList !== undefined) {
            let followerSupplier = values[index].FollowerIDList2 && data.supplierEmployeeList.filter(item => (values[index].FollowerIDList2.includes(item.value.toString())));
            if (followerSupplier.length > 0) {
                if (values[index].arrFollowerSupplier.length === 0 && isTHFirstTime === 0) {
                    values[index].arrFollowerSupplier = [...followerSupplier];
                } else {
                    const isData = !!values[index].arrFollowerSupplier.filter(d => d.value === followerSupplier.value);
                    if (isData === false && isTHFirstTime === 0) {
                        values[index].arrFollowerSupplier = [...followerSupplier];
                    }
                }
            }
        }

        if (isTHFirstTime === 0) {
            if (values[index].arrTaskHolderBuyer.length > 0) {
                let names = values[index].arrTaskHolderBuyer.map(x => x.label).join(",");
                values[index].TaskHolderBuyerTxt = names;
            }
            if (values[index].arrFollowerCube.length > 0) {
                let names = values[index].arrFollowerCube.map(x => x.label).join(",");
                values[index].FollowerCubeTxt = names;
            }
            if (values[index].arrFollowerSupplier.length > 0) {
                let names = values[index].arrFollowerSupplier.map(x => x.label).join(",");
                values[index].FollowerSupplierTxt = names;
            }
        }
        values[index].IsTHFirstTime = 1;
    }
    // const handleTaskHolderLeaveDays = (index, isTHFirstTime) => {
    //    
    //     showLoader();
    //     if (values[index].TaskID === 0) {
    //         Nodify('Warning!', 'warning', 'Please select the task name.');
    //     }
    //     else {

    //         if (values[index].titleEmployee1 !== "Cube" && values[index].titleEmployee2 !== "Cube" && values[index].titleEmployee3 !== "Cube") {
    //             // dispatch(loadTNATaskOwner(values[index].TaskHolderOrFollower, 0));
    //             dispatch(loadTNATaskOwner(values[index].TaskHolderOrFollower, getTNABrandID, getTNASupplierID, 0))
    //                 .then(data => {
    //                     if (data != null) {
    //                         setTNATaskOwner(data, index, isTHFirstTime);
    //                     }
    //                     values[index].IsTHLeaveDaysOpen = 1;
    //                     setInputFields(values);
    //                     hideLoader();
    //                 });
    //         } else {
    //             // dispatch(loadTNATaskOwner(values[index].TaskHolderOrFollower, currentUser.employeeinformationID));
    //             dispatch(loadTNATaskOwner(values[index].TaskHolderOrFollower, getTNABrandID, getTNASupplierID, currentUser.employeeinformationID))
    //                 .then(data => {
    //                     if (data != null) {
    //                         setTNATaskOwner(data, index, isTHFirstTime);
    //                     }
    //                     values[index].IsTHLeaveDaysOpen = 1;
    //                     setInputFields(values);
    //                     hideLoader();
    //                 });
    //         }
    //     }
    // };

    // function setTNATaskOwner(data, index, isTHFirstTime) {
    //    
    //     if (data.buyerEmployeeList && data.buyerEmployeeList.length > 0) {

    //         let taskHolderBuyer = values[index].TaskHolderIDList && data.buyerEmployeeList.filter(item => (values[index].TaskHolderIDList.split(',').includes(item.value.toString())));
    //         if (taskHolderBuyer.length > 0) {

    //             if (values[index].arrTaskHolderBuyer.length === 0 && isTHFirstTime === 0) {
    //                 values[index].arrTaskHolderBuyer = [...taskHolderBuyer];
    //             } else {
    //                 const isData = !!values[index].arrTaskHolderBuyer.filter(d => d.value === taskHolderBuyer.value);
    //                 if (isData === false && isTHFirstTime === 0) {
    //                     values[index].arrTaskHolderBuyer = [...values[index].arrTaskHolderBuyer, ...taskHolderBuyer];
    //                 }
    //             }

    //         }
    //     }
    //     if (data.cubeEmployeeList !== undefined) {
    //         let followerCube = values[index].FollowerIDList1 && data.cubeEmployeeList.filter(item => (values[index].FollowerIDList1.split(',').includes(item.value.toString())));
    //         if (followerCube.length > 0) {
    //             if (values[index].arrFollowerCube.length === 0 && isTHFirstTime === 0) {
    //                 values[index].arrFollowerCube = [...followerCube];
    //             } else {
    //                 const isData = !!values[index].arrFollowerCube.filter(d => d.value === followerCube.value);
    //                 if (isData === false && isTHFirstTime === 0) {
    //                     values[index].arrFollowerCube = [...followerCube];
    //                 }
    //             }
    //         }
    //     }
    //     if (data.supplierEmployeeList !== undefined) {
    //         let followerSupplier = values[index].FollowerIDList2 && data.supplierEmployeeList.filter(item => (values[index].FollowerIDList2.includes(item.value.toString())));
    //         if (followerSupplier.length > 0) {
    //             if (values[index].arrFollowerSupplier.length === 0 && isTHFirstTime === 0) {
    //                 values[index].arrFollowerSupplier = [...followerSupplier];
    //             } else {
    //                 const isData = !!values[index].arrFollowerSupplier.filter(d => d.value === followerSupplier.value);
    //                 if (isData === false && isTHFirstTime === 0) {
    //                     values[index].arrFollowerSupplier = [...followerSupplier];
    //                 }
    //             }
    //         }
    //     }

    //     if (isTHFirstTime === 0) {
    //         if (values[index].arrTaskHolderBuyer.length > 0) {
    //             let names = values[index].arrTaskHolderBuyer.map(x => x.label).join(",");
    //             values[index].TaskHolderBuyerTxt = names;
    //         }
    //         if (values[index].arrFollowerCube.length > 0) {
    //             let names = values[index].arrFollowerCube.map(x => x.label).join(",");
    //             values[index].FollowerCubeTxt = names;
    //         }
    //         if (values[index].arrFollowerSupplier.length > 0) {
    //             let names = values[index].arrFollowerSupplier.map(x => x.label).join(",");
    //             values[index].FollowerSupplierTxt = names;
    //         }
    //     }
    //     values[index].IsTHFirstTime = 1;
    // }

    // const handleTaskHolderLeaveDaysChild = (index, subIndex, childIndex, isTHFirstTime) => {
    //     if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].titleEmployee1 !== "Cube" && values[index].SubTaskList[subIndex].ChildTaskList[childIndex].titleEmployee2 !== "Cube" && values[index].SubTaskList[subIndex].ChildTaskList[childIndex].titleEmployee3 !== "Cube") {
    //         // dispatch(loadTNATaskOwner(values[index].TaskHolderOrFollower, 0));
    //         dispatch(loadTNATaskOwner(values[index].SubTaskList[subIndex].ChildTaskList[childIndex].TaskHolderOrFollower, 0))
    //             .then(data => {

    //                 if (data != null) {
    //                     setTNATaskOwnerChild(data, index, subIndex, childIndex, isTHFirstTime);
    //                 }
    //                 values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsTHLeaveDaysOpen = 1;
    //                 setInputFields(values);
    //             });
    //     } else {
    //         // dispatch(loadTNATaskOwner(values[index].TaskHolderOrFollower, currentUser.employeeinformationID));
    //         dispatch(loadTNATaskOwner(values[index].SubTaskList[subIndex].ChildTaskList[childIndex].TaskHolderOrFollower, currentUser.employeeinformationID))
    //             .then(data => {

    //                 if (data != null) {
    //                     setTNATaskOwnerChild(data, index, subIndex, childIndex, isTHFirstTime);
    //                 }
    //                 values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsTHLeaveDaysOpen = 1;
    //                 setInputFields(values);
    //             });
    //     }

    // };

    // function setTNATaskOwnerChild(data, index, subIndex, childIndex, isTHFirstTime) {
    //     if (data.buyerEmployeeList.length > 0) {

    //         let taskHolderBuyer = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].TaskHolderIDList && data.buyerEmployeeList.filter(item => (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].TaskHolderIDList.split(',').includes(item.value.toString())));
    //         if (taskHolderBuyer.length > 0) {

    //             if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrTaskHolderBuyer.length === 0 && isTHFirstTime === 0) {

    //                 values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrTaskHolderBuyer = [...taskHolderBuyer];

    //             } else {
    //                 const isData = !!values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrTaskHolderBuyer.filter(d => d.value === taskHolderBuyer.value);
    //                 if (!isData && isTHFirstTime === 0) {
    //                     values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrTaskHolderBuyer = [...values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrTaskHolderBuyer, ...taskHolderBuyer];
    //                 }
    //             }

    //         }
    //     }
    //     if (data.cubeEmployeeList.length > 0) {
    //         let followerCube = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerIDList1 && data.cubeEmployeeList.filter(item => (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerIDList1.split(',').includes(item.value.toString())));
    //         if (followerCube.length > 0) {
    //             if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrFollowerCube.length === 0 && isTHFirstTime === 0) {
    //                 values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrFollowerCube = [...followerCube];
    //             } else {
    //                 const isData = !!values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrFollowerCube.filter(d => d.value === followerCube.value);
    //                 if (!isData && isTHFirstTime === 0) {
    //                     values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrFollowerCube = [...followerCube];
    //                 }
    //             }
    //         }
    //     }
    //     if (data.supplierEmployeeList.length > 0) {
    //         let followerSupplier = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerIDList2 && data.supplierEmployeeList.filter(item => (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerIDList2.includes(item.value.toString())));
    //         if (followerSupplier.length > 0) {
    //             if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrFollowerSupplier.length === 0 && isTHFirstTime === 0) {
    //                 values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrFollowerSupplier = [...followerSupplier];
    //             } else {
    //                 const isData = !!values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrFollowerSupplier.filter(d => d.value === followerSupplier.value);
    //                 if (!isData && isTHFirstTime === 0) {
    //                     values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrFollowerSupplier = [...followerSupplier];
    //                 }
    //             }
    //         }
    //     }

    //     if (isTHFirstTime === 0) {
    //         if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrTaskHolderBuyer.length > 0) {
    //             let names = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrTaskHolderBuyer.map(x => x.label).join(",");
    //             values[index].SubTaskList[subIndex].ChildTaskList[childIndex].TaskHolderBuyerTxt = names;
    //         }
    //         if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrFollowerCube.length > 0) {
    //             let names = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrFollowerCube.map(x => x.label).join(",");
    //             values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerCubeTxt = names;
    //         }
    //         if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrFollowerSupplier.length > 0) {
    //             let names = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrFollowerSupplier.map(x => x.label).join(",");
    //             values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerSupplierTxt = names;
    //         }
    //     }

    //     values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsTHFirstTime = 1;
    // }

    const handleTHLeaveDaysClose = (index) => {
        values[index].IsTHLeaveDaysOpen = 0;
        let TxtList = "";
        let IdList = "";
        if (values[index].TaskHolderBuyer !== '') {
            TxtList = values[index].TaskHolderBuyerTxt;
            IdList = values[index].TaskHolderBuyer;
        }
        if (values[index].FollowerCube !== '') {
            TxtList = TxtList === "" ? values[index].FollowerCubeTxt : TxtList + ", " + values[index].FollowerCubeTxt;
            IdList = IdList === "" ? values[index].FollowerCube : IdList + "," + values[index].FollowerCube;
        }
        if (values[index].FollowerSupplier !== '') {
            TxtList = TxtList === "" ? values[index].FollowerSupplierTxt : TxtList + ", " + values[index].FollowerSupplierTxt;
            IdList = IdList === "" ? values[index].FollowerSupplier : IdList + "," + values[index].FollowerSupplier;
        }

        values[index].TaskHolderOwnerTxtList = TxtList;
        values[index].TaskHolderOwnerIdList = IdList;

        setInputFields(values);
        //commonCalculation(false, false);
    }
    // const handleTHLeaveDaysChildClose = (index, subIndex, childIndex) => {

    //     values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsTHLeaveDaysOpen = 0;
    //     let TxtList = "";
    //     let IdList = "";
    //     if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].TaskHolderBuyer !== '') {
    //         TxtList = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].TaskHolderBuyerTxt;
    //         IdList = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].TaskHolderBuyer;
    //     }
    //     if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerCube !== '') {
    //         TxtList = TxtList === "" ? values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerCubeTxt : TxtList + ", " + values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerCubeTxt;
    //         IdList = IdList === "" ? values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerCube : IdList + "," + values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerCube;
    //     }
    //     if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerSupplier !== '') {
    //         TxtList = TxtList === "" ? values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerSupplierTxt : TxtList + ", " + values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerSupplierTxt;
    //         IdList = IdList === "" ? values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerSupplier : IdList + "," + values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerSupplier;
    //     }

    //     values[index].SubTaskList[subIndex].ChildTaskList[childIndex].TaskHolderOwnerTxtList = TxtList;
    //     values[index].SubTaskList[subIndex].ChildTaskList[childIndex].TaskHolderOwnerIdList = IdList;

    //     setInputFields(values);
    //     // commonCalculation();
    // }


    // #endregion ------------------  TaskHolderLeaveDays End --------------------------------

    //#region ------------------  Dependency Start ---------------------------------------

    const handleDependency = (index) => {
        if (values[index].TaskID === 0) {
            Nodify('Warning!', 'warning', 'Please select the task name.');
        }
        else {
            values[index].IsDependencyOpen = 1;
            let TaskDependency = [];
            values.map((Item) => {

                if (values[index].arrDependency && values[index].arrDependency.length > 0) {
                    let AlreadyExist = values[index].arrDependency.filter(x => x.DependentTaskName === Item.TaskID);
                    let DependencyID = 0;
                    let LogDays = '';
                    if (AlreadyExist.length === 0) {
                        DependencyID = 0;
                        LogDays = '';
                    }
                    else {
                        DependencyID = AlreadyExist[0].DependencyID;
                        LogDays = AlreadyExist[0].LogDays;
                    }
                    TaskDependency.push(
                        {
                            TNADependencyID: 0,
                            DependentTaskName: Item.TaskID,
                            TNATaskName: Item.strTaskName,
                            DependencyID: DependencyID,
                            LogDays: LogDays,
                            IndexName: (index).toString()
                        }
                    )
                }
                else {
                    TaskDependency.push(
                        {
                            TNADependencyID: 0,
                            DependentTaskName: Item.TaskID,
                            TNATaskName: Item.strTaskName,
                            DependencyID: 0,
                            LogDays: '',
                            IndexName: (index).toString()
                        }
                    )
                }

            });

            values[index].arrDependency = [];
            values[index].arrDependency = TaskDependency;
            setInputFields(values);
        }
    };

    const handleDependencyClose = (index) => {
        values[index].IsDependencyOpen = 0;
        setInputFields(values);

        commonCalculation(false, false);
    };

    //#endregion ------------------  Dependency End --------------------------------


    // #region  ------------------  Child Start --------------------------------


    // Child Change
    const handleChildChange = (e, index, childIndex, FieldName) => {
        setSubmitted(false);
        let inputText = '';
        if (FieldName === "DependentTaskName" || FieldName === "DependencyID") {
            if (e != null) {
                inputText = parseInt(e.value);
            }
            else {
                inputText = 0;
                if (FieldName === "DependencyID") {
                    values[index].arrDependency[childIndex].LogDays = '';
                }
            }
            values[index].arrDependency[childIndex][FieldName] = inputText;
        }

        else {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
                var checkNumber = inputText.replace(/\D/g, '').match('^[0-9]*$');
                inputText = checkNumber[0];
            }
            if (parseInt(inputText) === 0) {
                inputText = '';
            }
            values[index].arrDependency[childIndex][e.target.name] = inputText;
        }
        if (FieldName === "DependencyID") {
            values[index].SelectedDependency = '';
            let DependencyShow = '';
            for (let i = 0; i < values[index].arrDependency.length; i++) {
                if (index !== i) {
                    let DependentID = values[index].arrDependency[i].DependencyID;
                    if (DependentID !== 0) {
                        if (DependencyShow === '') {
                            DependencyShow = (i + 1) + '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : DependentID === 4 ? "EE" : "")
                        }
                        else {
                            DependencyShow = DependencyShow + ',' + (i + 1) + '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : DependentID === 4 ? "EE" : "")
                        }
                    }
                }
            }
            values[index].SelectedDependency = DependencyShow;
        }
        values[index].DependencyCount = values[index].arrDependency.filter(x => x.DependencyID !== 0).length;

        setInputFields(values);
    }

    // Child Add Popup
    const handleChildAddFieldsClick = (index, subIndex, childIndex, taskName, subParentId) => {

        let referenceIdList = values[index].SubTaskList[subIndex].ChildTaskList && values[index].SubTaskList[subIndex].ChildTaskList.map(x => x.SubId).join(",");
        dispatch(loadTNAChildReferenceUnMappedList({ TaskName: taskName, TNAId: props.location.state.params.tnaid, SubParentId: subParentId, ReferenceIdList: referenceIdList }))
            .then(data => {
                if (data != null) {
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].drpChildTaskList = data;
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsAddChildTaskOpen = 1;
                    setInputFields(values);
                }
            });

        //setCurrentChildTask({ value: 0, label: '' });
    }

    // Child Add Popup Close
    const handleAddChildTaskClose = (index, subIndex, childIndex) => {
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsAddChildTaskOpen = 0;
        setInputFields(values);
    }


    // Child Add Popup Change
    const handleChangeChildTask = (e) => {
        if (e != null) {
            let inputVal = parseInt(e.value);
            setCurrentChildTask({ value: inputVal, label: e.label });
        } else {
            setCurrentChildTask({ value: 0, label: '' });
        }
    }

    // Child Add Popup Save New Row
    const handleAddRowChildTask = (index, subIndex, childIndex) => {

        if (getCurrentChildTask.value !== "" && getCurrentChildTask.value > 0) {
            let parentRow = values[index];
            let childRow = values[index].SubTaskList[subIndex].ChildTaskList[childIndex];
            let count = childIndex;
            count++;
            let EmptyChildList = {};
            values[index].SubTaskList[subIndex].IsNewRow = true;

            EmptyChildList =
            {
                SubId: getCurrentChildTask.value,
                Name: getCurrentChildTask.label,
                TaskName: childRow.TaskName,
                TaskID: childRow.TaskID,
                MainId: childRow.MainId,
                Duration: parentRow.Duration,
                EndDate: parentRow.EndDate,
                StartDate: parentRow.StartDate,
                // arrDependency: parentRow.arrDependency,
                TaskHolderList: '',
                TaskHolderOrFollower: childRow.TaskHolderOrFollower,
                TaskHolderOwnerTxtList: childRow.TaskHolderOwnerTxtList,
                TaskHolderIDList: childRow.TaskHolderIDList,
                FollowerIDList1: childRow.FollowerIDList1,
                FollowerIDList2: childRow.FollowerIDList2,
                TaskHolderBuyer: childRow.TaskHolderBuyer,
                FollowerCube: childRow.FollowerCube,
                FollowerSupplier: childRow.FollowerSupplier,
                arrTaskHolderBuyer: [],
                arrFollowerCube: [],
                arrFollowerSupplier: [],
                TaskHolderBuyerTxt: '',
                FollowerCubeTxt: '',
                FollowerSupplierTxt: '',
                TaskHolderOwnerIdList: '',
                titleEmployee1: childRow.titleEmployee1,
                titleEmployee2: childRow.titleEmployee2,
                titleEmployee3: childRow.titleEmployee3,
                IsTHFirstTime: 0,
                DependencyCount: 0,
                // IsDependencyOpen: 0,
                // IsTHLeaveDaysOpen: 0,
                SelectedDependency: parentRow.SelectedDependency,
                LeaveDays: '',
                LeaveDaysTxt: '',
                StatusID: 1,
                AWBStatus: '',
                IsAddChildTaskOpen: 0,
                StrikeOffOrTrimInfoId: childRow.StrikeOffOrTrimInfoId,
                drpChildTaskList: [],
                IsManualClose: 0,
                ManualCloseComment: ''
            }
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsAddChildTaskOpen = 0;
            values[index].SubTaskList[subIndex].ChildTaskList.splice(count, 0, EmptyChildList);
            setInputFields(values);
        } else {
            Nodify('Warning!', 'warning', "Please select any one");
        }

    }

    const handleChildRowRemoveFields = (index, subIndex, childIndex) => {
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsAddChildTaskOpen = 0;
        values[index].SubTaskList[subIndex].ChildTaskList.splice(childIndex, 1);
        // let TaskIndex = 0;
        // values.map((parent, i) => {
        //     if (parent.IsSingleTask) {
        //         TaskIndex++;
        //     }
        //     values[i].IndexName = i.toString();
        //     values[i].TaskIndex = TaskIndex;
        //     if (parent.IsSingleTask === false) {
        //         parent.SubTaskList && parent.SubTaskList.map((parentChild, j) => {
        //             parentChild.ChildTaskList && parentChild.ChildTaskList.map((child, k) => {
        //                 TaskIndex++;
        //                 values[i].SubTaskList[j].ChildTaskList[k].ParentIndex = j.toString();
        //                 values[i].SubTaskList[j].ChildTaskList[k].ChildIndex = k.toString();
        //                 values[i].SubTaskList[j].ChildTaskList[k].TaskIndex = TaskIndex;

        //             })
        //         })
        //     }
        // });


        setInputFields(values);
        //SetCount(1);
    }

    // #endregion ------------------  Child Add Fields End --------------------------------

    const handleAddTaskChange = (e, index, childIndex, FieldName) => {

        if (e.target.checked) {
            values[index].FollowupDetails[childIndex].IsTaskSelected = 1;
            let IsSelected = 1;
            let UnselectedRecords = values[index].FollowupDetails.filter(x => x.IsTaskSelected === 0);
            if (UnselectedRecords.length > 0) {
                IsSelected = 0;
            }
            values[index].IsSelectAll = IsSelected;
            values[index].AdditionalFollowup = values[index].FollowupDetails.filter(x => x.IsTaskSelected === 1).length;
        } else {
            values[index].FollowupDetails[childIndex].IsTaskSelected = 0;
            values[index].IsSelectAll = 0;
            values[index].AdditionalFollowup = values[index].FollowupDetails.filter(x => x.IsTaskSelected === 1).length;
        }
        setInputFields(values);

    }



    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
        return false;
    }

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/TNAList');
        } else {
            window.location.reload();
        }
    }





    const filterOption = (option, inputValue) => {
        let trimValue = inputValue.trim();
        if (trimValue === "") {
            return option;
        }

        return option.data.subLabel.toLowerCase().includes(trimValue.toLowerCase());
    };


    const CustomInput = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                menuPosition="fixed"
                placeholder="DD/MM/YYYY"
            />
        )
    }


    const UpdateTNA = (e) => {
        setButtonLoader(true);
        e.preventDefault();
        let IsValid = true;


        MainValues[0].TNARouteApplyDetails = [];
        MainValues[0].TNADependencyDetails = [];
        MainValues[0].TNAAdditionalTaskDetails = [];
        MainValues[0].TNAChildInformation = [];
        MainValues[0].TNASubChildInformation = [];
        MainValues[0].TNADocs = [];
        MainValues[0].TNAComments = [];


        // MainValues[0].CreationStatus = 2;
        MainValues[0].TNAId = props.location.state.params.tnaid;


        inputFields.map((Info, i) => {
            if (Info.TaskID === 0 || Info.TaskType === 0 || Info.Duration === '') {
                setButtonLoader(false);
                if (IsValid === true) {
                    Nodify('Warning!', 'warning', "Please fill the mandatory(*) fields.");
                    IsValid = false;
                }
                setSubmitted(true);
                return false;
            }
            else {
                MainValues[0].TNARouteApplyDetails.push({
                    TNARouteApplyID: Info.TNARouteApplyID,
                    TaskType: Info.TaskType,
                    TaskNameID: Info.TaskID,
                    //Duration: Info.Duration,
                    Duration: Info.Duration.toString(),
                    PlanStartDate: Info.StartDate,
                    EndDate: Info.EndDate,
                    Dependency: Info.SelectedDependency,
                    TaskOwner: Info.TaskHolderOwnerIdList,
                    LeaveDays: Info.LeaveDays,
                    RouteApplyStatus: Info.StatusID,
                    TaskHolderID: Info.TaskHolderOrFollower,
                    TaskHolderIDList: Info.TaskHolderBuyer,
                    FollowerIDList1: Info.FollowerCube,
                    FollowerIDList2: Info.FollowerSupplier,
                    IndexName: i.toString(),
                    AWBStatus: Info.AWBStatus,
                    DependencyStartstop: Info.intDependency,
                    // ManualCloseComment: Info.arrManual !== undefined && Info.arrManual.length > 0 ? Info.arrManual[0].TNATaskComments : '',
                    // ManualCloseDate: Info.arrManual !== undefined && Info.arrManual.length > 0 ? Info.arrManual[0].Date : null,
                    ManualCloseComment: Info.ManualComments,
                    ManualCloseDate: Info.ManualCloseDate,
                    //ManualCloseDate: Info.arrManual !== undefined && Info.arrManual.length > 0 ? Info.arrManual[0].Date : null,


                });

                let TaskName = Info.strTaskName;
                if (Info.arrDependency && Info.arrDependency.length > 0) {
                    const arrDependency = Info.arrDependency.filter(d => d.TNATaskName !== '');
                    // MainValues[0].TNADependencyDetails = [...MainValues[0].TNADependencyDetails, ...arrDependency];
                    Info.arrDependency.forEach((child, j) => {
                        if (child.TNATaskName !== '') {
                            child.IndexName = i.toString();
                            MainValues[0].TNADependencyDetails.push(child);
                        }
                    });
                }

                if (Info.FollowupDetails && Info.FollowupDetails.length > 0) {
                    // MainValues[0].TNAAdditionalTaskDetails = [...MainValues[0].TNAAdditionalTaskDetails, ...Info.FollowupDetails];
                    Info.FollowupDetails.forEach((child, k) => {
                        child.IndexName = i.toString();
                        MainValues[0].TNAAdditionalTaskDetails.push(child);
                    });
                }
                if (Info.arrAttachment) {

                    Info.arrAttachment.forEach((child) => {

                        child.TaskIndexName = child.TaskIndexName.toString();
                        MainValues[0].TNADocs.push(child);
                    });
                    //MainValues[0].TNADocs = [...MainValues[0].TNADocs, ...Info.arrAttachment];
                }

                if (Info.arrComment) {
                    MainValues[0].TNAComments = [...MainValues[0].TNAComments, ...Info.arrComment];
                }


                let ChildStatus = 0;
                let isTwoTreeView = false;
                if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
                    ChildStatus = 1;
                }
                else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                    TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                    TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
                    ChildStatus = 2;

                } else if (TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER ||
                    TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT ||
                    TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER ||
                    TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {

                    ChildStatus = 3;

                } else if (TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE ||
                    TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || TaskName.toLowerCase() === StaticTask.SMS_APPROVAL || TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH) {
                    ChildStatus = 4;
                }
                else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL ||
                    TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
                    ChildStatus = 5;
                }
                if (TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER ||
                    TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE || TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION ||
                    TaskName.toLowerCase() === StaticTask.SMS_APPROVAL || TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH ||
                    TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
                    isTwoTreeView = true;
                }

                if (Info.IsSingleTask === false) {

                    Info.SubTaskList && Info.SubTaskList.map((Sub, j) => {

                        if (isTwoTreeView === false) {
                            MainValues[0].TNAChildInformation.push({
                                Id: (Sub.ParentId === null || Sub.ParentId === undefined) ? 0 : Sub.ParentId,
                                TaskNameID: Sub.TaskID,
                                ChildId: Sub.MainId,
                                TaskIndexName: i.toString(),
                                ChildIndexName: j.toString(),
                                StrikeOffOrTrimLogoId: (Sub.StrikeOffOrTrimInfoId === undefined || Sub.StrikeOffOrTrimInfoId === null) ? 0 : parseInt(Sub.StrikeOffOrTrimInfoId),
                                ChildStatus: ChildStatus,
                            });
                        }

                        Sub.ChildTaskList && Sub.ChildTaskList.map((Child, k) => {
                            if (Child.Duration === '') {
                                setButtonLoader(false);
                                if (IsValid === true) {
                                    Nodify('Warning!', 'warning', "Please fill the mandatory(*) fields.");
                                    IsValid = false;
                                }
                                setSubmitted(true);
                                return false;
                            } else {
                                MainValues[0].TNASubChildInformation.push({
                                    Id: (Child.ChildId === null || Child.ChildId === undefined) ? 0 : Child.ChildId,
                                    TaskNameID: Child.TaskID,
                                    SubChildId: Child.SubId,
                                    FabricOrStrikeOffOrTrimId: (Child.StrikeOffOrTrimInfoId === undefined || Child.StrikeOffOrTrimInfoId === null) ? 0 : parseInt(Child.StrikeOffOrTrimInfoId),
                                    Duration: Child.Duration.toString(),
                                    PlanStartDate: Child.StartDate,
                                    EndDate: Child.EndDate,
                                    RouteApplyStatus: Child.StatusID,
                                    TaskIndexName: i.toString(),
                                    ChildIndexName: j.toString(),
                                    SubChildIndexName: k.toString(),
                                    SubChildStatus: ChildStatus,
                                    ManualCloseComment: Child.ManualCloseComment,
                                    ManualCloseDate: Child.ManualCloseDate,
                                });
                            }


                        });
                    });

                }

            }
        });

        if (IsValid) {

            dispatch(updateTNA(MainValues[0]))
                .then(data => {
                    if (data.outputResult === "2") {
                        //props.CallBackTNAAdd('Edit');
                        // props.history.push('/TNAList');
                        props.history.push({
                            pathname: "/TNAList",
                            state: {
                                message: "Edit"
                            }
                        });

                    }

                    else if (data.outputResult === "-1") {

                        Nodify('Warning!', 'warning', 'TNA name already Exist');
                    }
                    setButtonLoader(false);
                })
                .catch(e => {
                    console.log(e);
                    setButtonLoader(false);
                });
        }
    }

    // #region   ------------- **** Calculation Begin ***** --------------------------

    //Main Calculation Function
    const commonCalculation = (isDuration, isEndDate) => {
        values.map((Item, i) => {
            // Parent Calculation call
            commonCalculationParentAndChild(Item, Item, i, 0, 0, true, isDuration, isEndDate);
            Item.SubTaskList && Item.SubTaskList.map((Item2, p) => {
                Item2.ChildTaskList && Item2.ChildTaskList.map((Item3, c) => {
                    // Child Calculation call
                    commonCalculationParentAndChild(Item, Item3, i, p, c, false, isDuration, isEndDate);
                });
            });
        });
        console.log(values);
        setInputFields(values);
    }

    function commonCalculationParentAndChild(parentItem, commonItem, i, p, c, isParent, isDuration, isEndDate) {

        let LeaveDays = parentItem.LeaveDays;
        let dependencyCount = parentItem.DependencyCount;
        let SelectedDependency = parentItem.SelectedDependency;

        let Duration = (commonItem.Duration !== "" && commonItem.Duration !== undefined) ? parseInt(commonItem.Duration) > 0 ? parseInt(commonItem.Duration) - 1 : 0 : 0;
        if (((i === 0 || dependencyCount === 0) && isEndDate === false) || (isDuration === true && dependencyCount === 0)) {
            // Dependency Calculation Without
            let dbStartDate = new Date();
            let total = 0;
            //add Saturday,Sunday and Holiday Count
            dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, LeaveDays, "add");
            let EndDate = addDays(dbStartDate, Duration);
            //Total Saturday,Sunday and Holiday Count
            total = totalHolidayAndWE(dbStartDate, EndDate, LeaveDays);
            let flyEndtDate = addDays(EndDate, total);
            //sub Saturday,Sunday and Holiday Count
            flyEndtDate = checkDateBetweenInHolidayAndWE(flyEndtDate, LeaveDays, "add");
            commonItem.StartDate = dbStartDate;
            commonItem.EndDate = flyEndtDate;
            if (isParent) {
                values[i] = commonItem;
            } else {
                values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
            }

        }
        else if ((i === 0 || dependencyCount === 0) || (isEndDate === true && dependencyCount === 0)) {

            let dbEndDate = commonItem.EndDate;
            let total = 0;
            //add Saturday,Sunday and Holiday Count
            dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, LeaveDays, "add");
            let StartDate = subDays(dbEndDate, Duration);
            //Total Saturday,Sunday and Holiday Count
            total = totalHolidayAndWE(StartDate, dbEndDate, LeaveDays);
            let flyStartDate = subDays(StartDate, total);
            //sub Saturday,Sunday and Holiday Count
            flyStartDate = checkDateBetweenInHolidayAndWE(flyStartDate, LeaveDays, "sub");
            commonItem.StartDate = flyStartDate;
            commonItem.EndDate = dbEndDate;
            if (isParent) {
                values[i] = commonItem;
            } else {
                values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
            }

        }
        else {
            //Dependency Calculation
            let dependency = SelectedDependency;

            let dbEndDate = new Date();
            let dbStartDate = new Date();
            let total = 0;
            if (dependencyCount > 1) {
                //Multiple Dependency Calculation

                let dependencyMult = [];
                let splitDepList = dependency.split(',');
                splitDepList.map((depItem, d) => {
                    addMultDependencyDate(values, parentItem, depItem, Duration, dependencyMult)
                });

                const sorted = dependencyMult.slice().sort((a, b) => b.Date - a.Date);
                let rowSorted = sorted[0];
                dbEndDate = sorted[0].Date;
                let lastRow = sorted.filter(d => d.depItem === rowSorted.depItem && d.Date !== dbEndDate);
                if (lastRow.length > 0) {
                    dbStartDate = lastRow[0].Date;
                } else {
                    dbStartDate = dbEndDate;
                }

                commonItem.StartDate = dbStartDate;
                commonItem.EndDate = dbEndDate;
                if (isParent) {
                    values[i] = commonItem;
                } else {
                    values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                }
            } else if (dependencyCount === 1 && dependency.length !== 0) {

                let splitVal = dependency.split('-');
                let indexVal = splitVal[0].trim();
                let dependencyVal = splitVal[1].trim();

                if (dependencyVal === "SS") {
                    //---- Start-Start ----
                    dbStartDate = values[indexVal - 1].StartDate;
                    let LogDays = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 2);
                    if (LogDays && LogDays.length > 0) {
                        //LogDays Add
                        LogDays = LogDays[0].LogDays;
                        if (LogDays !== "") {
                            dbStartDate = addDays(dbStartDate, parseInt(LogDays));
                        }
                    }
                    //Calculation Saturday,Sunday and Holiday Count
                    dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, LeaveDays, "add");
                    dbEndDate = addDays(dbStartDate, Duration);
                    //Total Saturday,Sunday and Holiday Count
                    total = totalHolidayAndWE(dbStartDate, dbEndDate, LeaveDays);
                    let flyEndDate = addDays(dbEndDate, total);
                    //Calculation Saturday,Sunday and Holiday Count
                    flyEndDate = checkDateBetweenInHolidayAndWE(flyEndDate, LeaveDays, "add");
                    commonItem.EndDate = flyEndDate;
                    commonItem.StartDate = dbStartDate;
                    if (isParent) {
                        values[i] = commonItem;
                    } else {
                        values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                    }

                } else if (dependencyVal === "SE") {
                    //---- Start-End ----
                    dbEndDate = subDays(values[indexVal - 1].StartDate, 1);
                    let LogDays = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 3);
                    if (LogDays && LogDays.length > 0) {
                        //LogDays Add
                        LogDays = LogDays[0].LogDays;
                        if (LogDays !== "") {
                            dbEndDate = addDays(dbEndDate, parseInt(LogDays));
                            dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, LeaveDays, "add");
                        }
                    } else {
                        dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, LeaveDays, "sub");
                    }
                    dbStartDate = subDays(dbEndDate, Duration);
                    //Total Saturday,Sunday and Holiday Count
                    total = totalHolidayAndWE(dbStartDate, dbEndDate, LeaveDays);
                    let flyStartDate = subDays(dbStartDate, total);
                    //Calculation Saturday,Sunday and Holiday Count
                    flyStartDate = checkDateBetweenInHolidayAndWE(flyStartDate, LeaveDays, "sub");
                    commonItem.EndDate = dbEndDate;
                    commonItem.StartDate = flyStartDate;
                    if (isParent) {
                        values[i] = commonItem;
                    } else {
                        values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                    }

                } else if (dependencyVal === "ES") {

                    //---- End-Start ----
                    dbStartDate = addDays(values[indexVal - 1].EndDate, 1);
                    let LogDays = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 1);
                    if (LogDays && LogDays.length > 0) {
                        //LogDays Add
                        LogDays = LogDays[0].LogDays;
                        if (LogDays !== "") {
                            dbStartDate = addDays(dbStartDate, parseInt(LogDays));
                        }
                    }
                    //Calculation Saturday,Sunday and Holiday Count
                    dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, LeaveDays, "add");
                    dbEndDate = addDays(dbStartDate, Duration);
                    //Total Saturday,Sunday and Holiday Count
                    total = totalHolidayAndWE(dbStartDate, dbEndDate, LeaveDays);
                    let flyEndDate = addDays(dbEndDate, total);
                    //Calculation Saturday,Sunday and Holiday Count
                    flyEndDate = checkDateBetweenInHolidayAndWE(flyEndDate, LeaveDays, "add");
                    commonItem.EndDate = flyEndDate;
                    commonItem.StartDate = dbStartDate;
                    if (isParent) {
                        values[i] = commonItem;
                    } else {
                        values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                    }

                } else if (dependencyVal === "EE") {
                    //---- End-End ----
                    dbEndDate = values[indexVal - 1].EndDate;
                    let LogDays = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 4);
                    if (LogDays && LogDays.length > 0) {
                        //LogDays Add
                        LogDays = LogDays[0].LogDays;
                        if (LogDays !== "") {
                            dbEndDate = addDays(dbEndDate, parseInt(LogDays));
                            dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, LeaveDays, "add");
                        }
                    } else {
                        dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, LeaveDays, "sub");
                    }
                    dbStartDate = subDays(dbEndDate, Duration);
                    //Total Saturday,Sunday and Holiday Count
                    total = totalHolidayAndWE(dbStartDate, dbEndDate, LeaveDays);
                    let flyStartDate = subDays(dbStartDate, total);
                    //Calculation Saturday,Sunday and Holiday Count
                    flyStartDate = checkDateBetweenInHolidayAndWE(flyStartDate, LeaveDays, "sub");
                    commonItem.EndDate = dbEndDate;
                    commonItem.StartDate = flyStartDate;
                    if (isParent) {
                        values[i] = commonItem;
                    } else {
                        values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                    }
                }
            }
        }
    }



    //----Manual Close-------
    // const handleManualclose = (index) => {
    //     if (values[index].TaskID === 0) {
    //         Nodify('Warning!', 'warning', 'Please select the task name.');
    //     }
    //     else {
    //         values[index].IsManual = 1;
    //         values[index].StatusID = 3;
    //         setTaskIndexFileupload(index);
    //         setTNAtaskIdFileupload(values[index].TaskID);
    //         // TaskIndexFileupload = index;
    //         // TNAtaskIdFileupload = values[index].TaskName;
    //         setInputFields(values);
    //     }
    //};

    const handleManualclose = (index) => {
        if (values[index].TaskID === 0) {
            Nodify('Warning!', 'warning', 'Please select the task name.');
        }
        else {

            if (values[index].intDependency === 0) {
                let Dependency = false;
                if (values[index].SelectedDependency.length > 0) {
                    let arrstatus = [];
                    arrstatus = values[index].SelectedDependency.split(',');
                    if (arrstatus.length > 0) {
                        arrstatus.forEach(element => {
                            let val = [];
                            val = element.split('-');
                            let valIndex = values[parseInt(val[0]) - 1].StatusID
                            //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StatusID
                            if (valIndex !== 3) {
                                Dependency = true;
                            }


                        });
                    }
                    if (Dependency) {
                        // setComment(true);
                        Nodify('Warning!', 'warning', "Please close dependency task");
                    }
                    else { values[index].IsManual = 1; }
                } else {
                    values[index].IsManual = 1;

                }
                setInputFields(values);

            }
            else {
                values[index].IsManual = 1;
                setInputFields(values);

            }

        }
    };

    const handleCloseManual = (index) => {

        values[index].IsManual = 0;
        setInputFields(values);
    };

    const handleManual = (index, assignValue) => {

        if (assignValue === '' || assignValue === undefined) {
            setComment(true);
            Nodify('Warning!', 'warning', "Please enter comments");
        }
        else {
            values[index].IsManualClose = 0;
            values[index].StatusID = 3;
            values[index].ManualCloseComment = assignValue;
            values[index].ManualCloseDate = new Date();
            values[index].ActualEndDate = new Date();

            values[index].Manual = '';
            values[index].IsManual = 0;
            setInputFields(values);

        }

    }
    const handleSkuAndStyleDetails = (SubId, MainId, StrikeOffIdOrTrimInfoId, strTaskName, SKUIdList, StyleStrikeOffGarmentSizeIdList, TnaId) => {

        showLoader();
        if (SKUIdList) {
            dispatch(getSkuAndStyleDetails({
                TNAId: TnaId, SubId: SubId,
                MainId: MainId, StrikeOffIdOrTrimInfoId: StrikeOffIdOrTrimInfoId,
                TaskName: strTaskName, StyleId: props.props.location.state.params.styleID
                , SKUIdList: SKUIdList, StyleStrikeOffGarmentSizeIdList: StyleStrikeOffGarmentSizeIdList

            }))
                .then(data => {
                    if (data) {
                        setSkuAndStyleDetailPopup(true);
                        setSkuAndStyleDetails(data);
                        hideLoader();
                    }
                });
        } else {
            hideLoader();
            let Title = "Warning!";
            let message = "No SKU in " + strTaskName;
            let type = "warning";
            Nodify(Title, type, message);
        }
    };

    const hideSkuAndStyleDetail = () => {
        setSkuAndStyleDetailPopup(false);
    }
    // const handleManual = (index, assignValue) => {
    //     let IsValid = true;
    //     if (assignValue === '' || assignValue === undefined) {
    //         setComment(true); IsValid = false;
    //         Nodify('Warning!', 'warning', "Please enter comments");
    //     }
    //     if (IsValid) {
    //         if (values[index].Manual && values[index].Manual.length !== 0) {
    //             if (values[index].Manual !== '') {
    //                 values[index].arrManual.push({
    //                     TNATaskComments: assignValue, TNAIndexName: (index).toString(),
    //                     IsDeleted: 0, Date: new Date(), IndexName: values[index].arrManual.length,
    //                 })
    //             }
    //             else {
    //                 values[index].arrManual = {
    //                     TNATaskCommentsID: 0, TNATaskComments: assignValue, TNAIndexName: (index).toString(),
    //                     IsDeleted: 0, Date: new Date(), IndexName: values[index].arrManual.length,

    //                 };
    //             }
    //         }
    //         else {
    //             values[index].arrManual.push({
    //                 TNATaskComments: assignValue, TNAIndexName: (index).toString(),
    //                 IsDeleted: 0, Date: new Date(), IndexName: values[index].arrManual.length,

    //             });
    //         }
    //         values[index].Manual = '';
    //         values[index].IsManual = 0;
    //         setInputFields(values);

    //     }

    //     // setInputFields(values);
    // };

    //Multiple Dependency Date add
    function addMultDependencyDate(values, parentItem, depItem, Duration, dependencyMult) {
        let splitVal = depItem.split('-');
        let indexVal = splitVal[0].trim();
        let dependencyVal = splitVal[1].trim();
        let total = 0;
        let dbEndDate = new Date();
        let dbStartDate = new Date();
        if (dependencyVal === "SS") {
            //---- Start-Start ----
            dbStartDate = values[indexVal - 1].StartDate;
            let LogDays = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 2);
            if (LogDays && LogDays.length > 0) {
                //LogDays Add
                LogDays = LogDays[0].LogDays;
                if (LogDays !== "") {
                    dbStartDate = addDays(dbStartDate, parseInt(LogDays));
                }
            }
            //Calculation Saturday,Sunday and Holiday Count
            dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, parentItem.LeaveDays, "add");
            dbEndDate = addDays(dbStartDate, Duration);
            //Total Saturday,Sunday and Holiday Count
            total = totalHolidayAndWE(dbStartDate, dbEndDate, parentItem.LeaveDays);
            let flyEndDate = addDays(dbEndDate, total);
            //Calculation Saturday,Sunday and Holiday Count
            flyEndDate = checkDateBetweenInHolidayAndWE(flyEndDate, parentItem.LeaveDays, "add");
            dependencyMult.push({ Date: dbStartDate, depItem: depItem });
            dependencyMult.push({ Date: flyEndDate, depItem: depItem });

        } else if (dependencyVal === "SE") {
            //---- Start-End ----
            dbEndDate = subDays(values[indexVal - 1].StartDate, 1);
            let LogDays = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 3);
            if (LogDays && LogDays.length > 0) {
                //LogDays Add
                LogDays = LogDays[0].LogDays;
                if (LogDays !== "") {
                    dbEndDate = addDays(dbEndDate, parseInt(LogDays));
                    dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, parentItem.LeaveDays, "add");
                }
            } else {
                dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, parentItem.LeaveDays, "sub");
            }
            dbStartDate = subDays(dbEndDate, Duration);
            //Total Saturday,Sunday and Holiday Count
            total = totalHolidayAndWE(dbStartDate, dbEndDate, parentItem.LeaveDays);
            let flyStartDate = subDays(dbStartDate, total);
            //Calculation Saturday,Sunday and Holiday Count
            flyStartDate = checkDateBetweenInHolidayAndWE(flyStartDate, parentItem.LeaveDays, "sub");

            dependencyMult.push({ Date: flyStartDate, depItem: depItem });
            dependencyMult.push({ Date: dbEndDate, depItem: depItem });

        } else if (dependencyVal === "ES") {
            //---- End-Start ----
            dbStartDate = addDays(values[indexVal - 1].EndDate, 1);
            let LogDays = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 1);
            if (LogDays && LogDays.length > 0) {
                //LogDays Add
                LogDays = LogDays[0].LogDays;
                if (LogDays !== "") {
                    dbStartDate = addDays(dbStartDate, parseInt(LogDays));
                }
            }
            //Calculation Saturday,Sunday and Holiday Count
            dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, parentItem.LeaveDays, "add");
            dbEndDate = addDays(dbStartDate, Duration);
            //Total Saturday,Sunday and Holiday Count
            total = totalHolidayAndWE(dbStartDate, dbEndDate, parentItem.LeaveDays);
            let flyEndDate = addDays(dbEndDate, total);
            //Calculation Saturday,Sunday and Holiday Count
            flyEndDate = checkDateBetweenInHolidayAndWE(flyEndDate, parentItem.LeaveDays, "add");
            dependencyMult.push({ Date: dbStartDate, depItem: depItem });
            dependencyMult.push({ Date: flyEndDate, depItem: depItem });

        } else if (dependencyVal === "EE") {
            //---- End-End ----
            dbEndDate = values[indexVal - 1].EndDate;
            let LogDays = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 4);
            if (LogDays && LogDays.length > 0) {
                //LogDays Add
                LogDays = LogDays[0].LogDays;
                if (LogDays !== "") {
                    dbEndDate = addDays(dbEndDate, parseInt(LogDays));
                    dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, parentItem.LeaveDays, "add");
                }
            } else {
                dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, parentItem.LeaveDays, "sub");
            }
            dbStartDate = subDays(dbEndDate, Duration);
            //Total Saturday,Sunday and Holiday Count
            total = totalHolidayAndWE(dbStartDate, dbEndDate, parentItem.LeaveDays);
            let flyStartDate = subDays(dbStartDate, total);
            //Calculation Saturday,Sunday and Holiday Count
            flyStartDate = checkDateBetweenInHolidayAndWE(flyStartDate, parentItem.LeaveDays, "sub");
            dependencyMult.push({ Date: flyStartDate, depItem: depItem });
            dependencyMult.push({ Date: dbEndDate, depItem: depItem });
        }
    }



    function addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    function subDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() - days);
        return result;
    }

    function totalHolidayAndWE(startDate, endDate, leaveDays) {
        let totalSaturday = 0;
        let totalSunday = 0;
        let totalHoliday = 0;
        let total = 0;
        let format = "MM/DD/YYYY";
        // Saturday,Sunday and Holiday Count
        var currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            if (currentDate.getDay() === 0) {
                totalSunday++;
            }
            if (currentDate.getDay() === 6) {
                totalSaturday++;
            }
            if (leaveDays === 2 || leaveDays === 5 || leaveDays === 6 || leaveDays === 8) {
                var newDate = new Date(moment(currentDate, "DD/MM/YYYY").format("MM/DD/YYYY"));
                let HolidayCount = getCompanyHolidayList.filter(d => new Date(moment(d.holidayDate, format)) <= newDate && newDate <= new Date(moment(d.holidayEndDate, format))).length;
                if (HolidayCount > 0) {
                    totalHoliday++;
                }
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }
        // Check LeaveDays base Saturday,Sunday and Holiday Count
        if (leaveDays === 2) {
            total = totalSaturday + totalSunday + totalHoliday;
        } else if (leaveDays === 3) {
            total = totalSaturday + totalSunday;
        } else if (leaveDays === 4) {
            total = totalSunday;
        } else if (leaveDays === 5) {
            total = totalHoliday;
        } else if (leaveDays === 6) {
            total = totalSunday + totalHoliday;
        } else if (leaveDays === 7) {
            total = totalSaturday;
        } else if (leaveDays === 8) {
            total = totalSaturday + totalHoliday;
        }
        return total;
    }

    function checkDateBetweenInHolidayAndWE(flyDate, leaveDays, status) {
        let format = "MM/DD/YYYY";
        let checkDate = new Date(moment(flyDate, "DD/MM/YYYY").format("MM/DD/YYYY"));
        let HolidayCount = getCompanyHolidayList.filter(d => new Date(moment(d.holidayDate, format)) <= checkDate && checkDate <= new Date(moment(d.holidayEndDate, format))).length;
        //ReCheck Date in Saturday,Sunday and Holiday Count
        if (flyDate.getDay() === 0 || flyDate.getDay() === 6 || HolidayCount > 0) {
            let i = 0;
            while (i <= 3) {
                //Weekend Date Check
                if (flyDate.getDay() === 0 && (leaveDays === 2 || leaveDays === 3 || leaveDays === 4 || leaveDays === 6)) {
                    status === "sub" ? flyDate = subDays(flyDate, 1) : flyDate = addDays(flyDate, 1);
                } else {
                    i++;
                }
                if (flyDate.getDay() === 6 && (leaveDays === 2 || leaveDays === 3 || leaveDays === 7 || leaveDays === 8)) {
                    status === "sub" ? flyDate = subDays(flyDate, 1) : flyDate = addDays(flyDate, 1);
                } else {
                    i++;
                }

                if (leaveDays === 2 || leaveDays === 5 || leaveDays === 6 || leaveDays === 8) {
                    let checkDate = new Date(moment(flyDate, "DD/MM/YYYY").format("MM/DD/YYYY"));
                    let HolidayCount = getCompanyHolidayList.filter(d => new Date(moment(d.holidayDate, format)) <= checkDate && checkDate <= new Date(moment(d.holidayEndDate, format))).length;
                    if (HolidayCount > 0) {
                        status === "sub" ? flyDate = subDays(flyDate, 1) : flyDate = addDays(flyDate, 1);
                    } else {
                        i++;
                    }
                } else {
                    i++;
                }

                i === 3 ? i = 4 : i = 0;
            }
        }
        return flyDate;
    }

    // #endregion   ------------- **** Calculation End ***** --------------------------


    const handletrimmPopupClose = () => {
        setTrimmodalpopup(false);
    }
    const handleTrimappPopupClose = () => {
        setTrimApprovalpopup(false);
    }
    const handleLabDipApp = () => {

        setOpenLabdipApppopup(false);

    }
    const handleStrikeOffApp = () => {
        setStrikeOffApppopup(false);
    }
    const handleprotoSubApp = () => {
        setSampleSubModalpopup(false);
    }
    const handleprotofitApp = () => {
        setSampleAppModalpopup(false);
    }
    const HandleAfterWashList = () => {
        setApperancemodalpopup(false);
    }


    const [TNAId, setTNAId] = useState();
    const [StyleId, setStyleId] = useState();
    const [LabdibFabricInfoId, setLabdibFabricInfoId] = useState();
    const [TNAStrikeOffRefInfoId, setTNAStrikeOffRefInfoId] = useState();
    const [TNATrimRefInfoId, setTNATrimRefInfoId] = useState();
    const [SubmittedOptionListId, setSubmittedOptionListId] = useState();
    const [ColorAppCommentsId, setColorAppCommentsId] = useState();
    const [TNAProtoFitId, setTNAProtoFitId] = useState();
    const [TNAStrTaskName, setTNATaskName] = useState('');
    const [AnyColorSku, setAnyColorSku] = useState();

    const [AppareanceTestId, setAppareanceTestId] = useState();
    const [Indexes, setIndexes] = useState({ Index: 0, SubIndex: 0, ChildIndex: 0 });


    const OpenLabdipSubmssionpopup = (TNAId, LabdibFabricInfoId, StyleId, index, subIndex, childIndex) => {
        if (values[index].intDependency === 0) {
            let Dependency = false;
            if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.length > 0) {
                let arrstatus = [];
                arrstatus = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.split(',');
                if (arrstatus.length > 0) {
                    arrstatus.forEach(element => {
                        let val = [];
                        val = element.split('-');
                        let valIndex = values[parseInt(val[0]) - 1].StatusID
                        if (valIndex !== 3) {
                            Dependency = true;
                        }
                    });
                }
                if (Dependency) {
                    Nodify('Warning!', 'warning', "Please close dependency task");
                }
                else {
                    // values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
                    setTNAId(TNAId);
                    setLabdibFabricInfoId(LabdibFabricInfoId);
                    setStyleId(StyleId)
                    setLapmodal(true);
                    setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                }
            } else {
                //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
                setTNAId(TNAId);
                setLabdibFabricInfoId(LabdibFabricInfoId);
                setStyleId(StyleId)
                setLapmodal(true);
                setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
            }
        }
        else {
            setTNAId(TNAId);
            setLabdibFabricInfoId(LabdibFabricInfoId);
            setStyleId(StyleId)
            setLapmodal(true);
            setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
            //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;

        }

    }

    const OpenLabdipApprovalpopup = (LabdibFabricInfoId, StyleId, index, subIndex, childIndex) => {
        if (values[index].intDependency === 0) {
            let Dependency = false;
            if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.length > 0) {
                let arrstatus = [];
                arrstatus = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.split(',');
                if (arrstatus.length > 0) {
                    arrstatus.forEach(element => {
                        let val = [];
                        val = element.split('-');
                        let valIndex = values[parseInt(val[0]) - 1].StatusID
                        if (valIndex !== 3) {
                            Dependency = true;
                        }
                    });
                }
                if (Dependency) {
                    Nodify('Warning!', 'warning', "Please close dependency task");
                }
                else {
                    // values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
                    setLabdibFabricInfoId(LabdibFabricInfoId);
                    setSubmittedOptionListId(LabdibFabricInfoId);
                    setStyleId(StyleId)
                    setOpenLabdipApppopup(true);
                    setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                }
            } else {
                //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
                setLabdibFabricInfoId(LabdibFabricInfoId);
                setSubmittedOptionListId(LabdibFabricInfoId);
                setStyleId(StyleId)
                setOpenLabdipApppopup(true);
                setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
            }
        }
        else {
            setLabdibFabricInfoId(LabdibFabricInfoId);
            setSubmittedOptionListId(LabdibFabricInfoId);
            setStyleId(StyleId)
            setOpenLabdipApppopup(true);
            setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })

        }

    }

    // const OpenLabdipApprovalpopup = (LabdibFabricInfoId, StyleId, index, subIndex, childIndex) => {
    //     setLabdibFabricInfoId(LabdibFabricInfoId);
    //     setSubmittedOptionListId(LabdibFabricInfoId);
    //     setStyleId(StyleId)
    //     setOpenLabdipApppopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }

    const OpenStrikeoffSubmssionpopup = (TNAId, TNAStrikeOffRefInfoId, index, subIndex, childIndex) => {
        if (values[index].intDependency === 0) {
            let Dependency = false;
            if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.length > 0) {
                let arrstatus = [];
                arrstatus = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.split(',');
                if (arrstatus.length > 0) {
                    arrstatus.forEach(element => {
                        let val = [];
                        val = element.split('-');
                        let valIndex = values[parseInt(val[0]) - 1].StatusID
                        if (valIndex !== 3) {
                            Dependency = true;
                        }
                    });
                }
                if (Dependency) {
                    Nodify('Warning!', 'warning', "Please close dependency task");
                }
                else {
                    setTNAId(TNAId);
                    setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
                    setStrikeOffmodalpopup(true);
                    setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                }
            } else {
                setTNAId(TNAId);
                setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
                setStrikeOffmodalpopup(true);
                setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
            }
        }
        else {
            setTNAId(TNAId);
            setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
            setStrikeOffmodalpopup(true);
            setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })

        }

    }

    // const OpenStrikeoffSubmssionpopup = (TNAId, TNAStrikeOffRefInfoId, index, subIndex, childIndex) => {
    //     setTNAId(TNAId);
    //     setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
    //     setStrikeOffmodalpopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }

    const OpenStrikeoffApprovalpopup = (TNAStrikeOffRefInfoId, index, subIndex, childIndex) => {
        if (values[index].intDependency === 0) {
            let Dependency = false;
            if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.length > 0) {
                let arrstatus = [];
                arrstatus = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.split(',');
                if (arrstatus.length > 0) {
                    arrstatus.forEach(element => {
                        let val = [];
                        val = element.split('-');
                        let valIndex = values[parseInt(val[0]) - 1].StatusID
                        if (valIndex !== 3) {
                            Dependency = true;
                        }
                    });
                }
                if (Dependency) {
                    Nodify('Warning!', 'warning', "Please close dependency task");
                }
                else {
                    setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
                    setSubmittedOptionListId(TNAStrikeOffRefInfoId);
                    setColorAppCommentsId(TNAStrikeOffRefInfoId);
                    setStrikeOffApppopup(true);
                    setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                }
            } else {
                setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
                setSubmittedOptionListId(TNAStrikeOffRefInfoId);
                setColorAppCommentsId(TNAStrikeOffRefInfoId);
                setStrikeOffApppopup(true);
                setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
            }
        }
        else {
            setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
            setSubmittedOptionListId(TNAStrikeOffRefInfoId);
            setColorAppCommentsId(TNAStrikeOffRefInfoId);
            setStrikeOffApppopup(true);
            setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })

        }

    }

    // const OpenStrikeoffApprovalpopup = (TNAStrikeOffRefInfoId, index, subIndex, childIndex) => {
    //     setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
    //     setSubmittedOptionListId(TNAStrikeOffRefInfoId);
    //     setColorAppCommentsId(TNAStrikeOffRefInfoId);
    //     setStrikeOffApppopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }

    const OpenTrimSubmssionpopup = (TNAId, TNATrimRefInfoId, index, subIndex, childIndex) => {
        if (values[index].intDependency === 0) {
            let Dependency = false;
            if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.length > 0) {
                let arrstatus = [];
                arrstatus = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.split(',');
                if (arrstatus.length > 0) {
                    arrstatus.forEach(element => {
                        let val = [];
                        val = element.split('-');
                        let valIndex = values[parseInt(val[0]) - 1].StatusID
                        if (valIndex !== 3) {
                            Dependency = true;
                        }
                    });
                }
                if (Dependency) {
                    Nodify('Warning!', 'warning', "Please close dependency task");
                }
                else {
                    setTNAId(TNAId);
                    setTNATrimRefInfoId(TNATrimRefInfoId);
                    setTrimmodalpopup(true);
                    setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                }
            } else {
                setTNAId(TNAId);
                setTNATrimRefInfoId(TNATrimRefInfoId);
                setTrimmodalpopup(true);
                setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
            }
        }
        else {
            setTNAId(TNAId);
            setTNATrimRefInfoId(TNATrimRefInfoId);
            setTrimmodalpopup(true);
            setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })

        }

    }

    // const OpenTrimSubmssionpopup = (TNAId, TNATrimRefInfoId, index, subIndex, childIndex) => {
    //     setTNAId(TNAId);
    //     setTNATrimRefInfoId(TNATrimRefInfoId);
    //     setTrimmodalpopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }

    const OpenTrimApprovalpopup = (TNATrimRefInfoId, index, subIndex, childIndex) => {
        if (values[index].intDependency === 0) {
            let Dependency = false;
            if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.length > 0) {
                let arrstatus = [];
                arrstatus = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.split(',');
                if (arrstatus.length > 0) {
                    arrstatus.forEach(element => {
                        let val = [];
                        val = element.split('-');
                        let valIndex = values[parseInt(val[0]) - 1].StatusID
                        if (valIndex !== 3) {
                            Dependency = true;
                        }
                    });
                }
                if (Dependency) {
                    Nodify('Warning!', 'warning', "Please close dependency task");
                }
                else {
                    setTNATrimRefInfoId(TNATrimRefInfoId);
                    setSubmittedOptionListId(TNATrimRefInfoId);
                    setColorAppCommentsId(TNATrimRefInfoId);
                    setTrimApprovalpopup(true);
                    setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                }
            } else {
                setTNATrimRefInfoId(TNATrimRefInfoId);
                setSubmittedOptionListId(TNATrimRefInfoId);
                setColorAppCommentsId(TNATrimRefInfoId);
                setTrimApprovalpopup(true);
                setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
            }
        }
        else {
            setTNATrimRefInfoId(TNATrimRefInfoId);
            setSubmittedOptionListId(TNATrimRefInfoId);
            setColorAppCommentsId(TNATrimRefInfoId);
            setTrimApprovalpopup(true);
            setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })

        }

    }



    // const OpenTrimApprovalpopup = (TNATrimRefInfoId, index, subIndex, childIndex) => {
    //     setTNATrimRefInfoId(TNATrimRefInfoId);
    //     setSubmittedOptionListId(TNATrimRefInfoId);
    //     setColorAppCommentsId(TNATrimRefInfoId);
    //     setTrimApprovalpopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }

    const OpenProtoFitSamplepopup = (TNAId, TNAProtoFitId, index, subIndex, childIndex, TaskName) => {
        if (values[index].intDependency === 0) {
            let Dependency = false;
            if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.length > 0) {
                let arrstatus = [];
                arrstatus = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.split(',');
                if (arrstatus.length > 0) {
                    arrstatus.forEach(element => {
                        let val = [];
                        val = element.split('-');
                        let valIndex = values[parseInt(val[0]) - 1].StatusID
                        if (valIndex !== 3) {
                            Dependency = true;
                        }
                    });
                }
                if (Dependency) {
                    Nodify('Warning!', 'warning', "Please close dependency task");
                }
                else {
                    setTNAId(TNAId);
                    setTNAProtoFitId(TNAProtoFitId);
                    setTNATaskName(TaskName);
                    setSampleSubModalpopup(true);
                    setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                }
            } else {
                setTNAId(TNAId);
                setTNAProtoFitId(TNAProtoFitId);
                setTNATaskName(TaskName);
                setSampleSubModalpopup(true);
                setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
            }
        }
        else {
            setTNAId(TNAId);
            setTNAProtoFitId(TNAProtoFitId);
            setTNATaskName(TaskName);
            setSampleSubModalpopup(true);
            setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })

        }

    }

    // const OpenProtoFitSamplepopup = (TNAId, TNAProtoFitId, index, subIndex, childIndex, TaskName) => {
    //     setTNAId(TNAId);
    //     setTNAProtoFitId(TNAProtoFitId);
    //     setTNATaskName(TaskName);
    //     setSampleSubModalpopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }


    const OpenProtoFitApprovalpopup = (TNAProtoFitId, index, subIndex, childIndex) => {
        if (values[index].intDependency === 0) {
            let Dependency = false;
            if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.length > 0) {
                let arrstatus = [];
                arrstatus = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.split(',');
                if (arrstatus.length > 0) {
                    arrstatus.forEach(element => {
                        let val = [];
                        val = element.split('-');
                        let valIndex = values[parseInt(val[0]) - 1].StatusID
                        if (valIndex !== 3) {
                            Dependency = true;
                        }
                    });
                }
                if (Dependency) {
                    Nodify('Warning!', 'warning', "Please close dependency task");
                }
                else {
                    setTNAProtoFitId(TNAProtoFitId);
                    setSampleAppModalpopup(true);
                    setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                }
            } else {
                setTNAProtoFitId(TNAProtoFitId);
                setSampleAppModalpopup(true);
                setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
            }
        }
        else {
            setTNAProtoFitId(TNAProtoFitId);
            setSampleAppModalpopup(true);
            setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
        }

    }
    // const OpenProtoFitApprovalpopup = (TNAProtoFitId, index, subIndex, childIndex) => {
    //     setTNAProtoFitId(TNAProtoFitId);
    //     setSampleAppModalpopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }

    const OpenAppearenceAfterWashpopup = (TNAId, AppareanceTestId, StyleId, index, subIndex, childIndex) => {
        if (values[index].intDependency === 0) {
            let Dependency = false;
            if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.length > 0) {
                let arrstatus = [];
                arrstatus = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.split(',');
                if (arrstatus.length > 0) {
                    arrstatus.forEach(element => {
                        let val = [];
                        val = element.split('-');
                        let valIndex = values[parseInt(val[0]) - 1].StatusID
                        if (valIndex !== 3) {
                            Dependency = true;
                        }
                    });
                }
                if (Dependency) {
                    Nodify('Warning!', 'warning', "Please close dependency task");
                }
                else {
                    setTNAId(TNAId);
                    setAppareanceTestId(AppareanceTestId);
                    setStyleId(StyleId)
                    setApperancemodalpopup(true);
                    setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                }
            } else {
                setTNAId(TNAId);
                setAppareanceTestId(AppareanceTestId);
                setStyleId(StyleId)
                setApperancemodalpopup(true);
                setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
            }
        }
        else {
            setTNAId(TNAId);
            setAppareanceTestId(AppareanceTestId);
            setStyleId(StyleId)
            setApperancemodalpopup(true);
            setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
        }

    }

    // const OpenAppearenceAfterWashpopup = (TNAId, AppareanceTestId, StyleId, index, subIndex, childIndex) => {
    //     setTNAId(TNAId);
    //     setAppareanceTestId(AppareanceTestId);
    //     setStyleId(StyleId)
    //     setApperancemodalpopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }


    // const OpenLabdipSubmssionpopup = (TNAId, LabdibFabricInfoId, StyleId, index, subIndex, childIndex) => {
    //     setTNAId(TNAId);
    //     setLabdibFabricInfoId(LabdibFabricInfoId);
    //     setStyleId(StyleId)
    //     setLapmodal(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }

    // const OpenLabdipSubmssionpopup = (TNAId, LabdibFabricInfoId, StyleId, index, subIndex, childIndex) => {
    //     if (values[index].intDependency === 0) {
    //         let Dependency = false;
    //         if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.length > 0) {
    //             let arrstatus = [];
    //             arrstatus = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.split(',');
    //             if (arrstatus.length > 0) {
    //                 arrstatus.forEach(element => {
    //                     let val = [];
    //                     val = element.split('-');
    //                     let valIndex = values[parseInt(val[0]) - 1].StatusID
    //                     if (valIndex !== 3) {
    //                         Dependency = true;
    //                     }
    //                 });
    //             }
    //             if (Dependency) {
    //                 Nodify('Warning!', 'warning', "Please close dependency task");
    //             }
    //             else {
    //                 // values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
    //                 setTNAId(TNAId);
    //                 setLabdibFabricInfoId(LabdibFabricInfoId);
    //                 setStyleId(StyleId)
    //                 setLapmodal(true);
    //                 setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    //             }
    //         } else {
    //             //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
    //             setTNAId(TNAId);
    //             setLabdibFabricInfoId(LabdibFabricInfoId);
    //             setStyleId(StyleId)
    //             setLapmodal(true);
    //             setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    //         }
    //     }
    //     else {
    //         setTNAId(TNAId);
    //         setLabdibFabricInfoId(LabdibFabricInfoId);
    //         setStyleId(StyleId)
    //         setLapmodal(true);
    //         setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    //         //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;

    //     }

    // }

    // const OpenLabdipApprovalpopup = (LabdibFabricInfoId, StyleId, index, subIndex, childIndex) => {
    //     setLabdibFabricInfoId(LabdibFabricInfoId);
    //     setSubmittedOptionListId(LabdibFabricInfoId);
    //     setStyleId(StyleId)
    //     setOpenLabdipApppopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }

    // const OpenStrikeoffSubmssionpopup = (TNAId, TNAStrikeOffRefInfoId, index, subIndex, childIndex) => {
    //     setTNAId(TNAId);
    //     setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
    //     setStrikeOffmodalpopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }

    // const OpenStrikeoffApprovalpopup = (TNAStrikeOffRefInfoId, index, subIndex, childIndex) => {
    //     setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
    //     setSubmittedOptionListId(TNAStrikeOffRefInfoId);
    //     setColorAppCommentsId(TNAStrikeOffRefInfoId);
    //     setStrikeOffApppopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }

    // const OpenTrimSubmssionpopup = (TNAId, TNATrimRefInfoId, index, subIndex, childIndex) => {
    //     setTNAId(TNAId);
    //     setTNATrimRefInfoId(TNATrimRefInfoId);
    //     setTrimmodalpopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }

    // const OpenTrimApprovalpopup = (TNATrimRefInfoId, index, subIndex, childIndex) => {
    //     setTNATrimRefInfoId(TNATrimRefInfoId);
    //     setSubmittedOptionListId(TNATrimRefInfoId);
    //     setColorAppCommentsId(TNATrimRefInfoId);
    //     setTrimApprovalpopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }

    // const OpenProtoFitSamplepopup = (TNAId, TNAProtoFitId, index, subIndex, childIndex, TaskName) => {
    //     setTNAId(TNAId);
    //     setTNAProtoFitId(TNAProtoFitId);
    //     setTNATaskName(TaskName);
    //     setSampleSubModalpopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }
    // const OpenProtoFitApprovalpopup = (TNAProtoFitId, index, subIndex, childIndex) => {
    //     setTNAProtoFitId(TNAProtoFitId);
    //     setSampleAppModalpopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }

    // const OpenAppearenceAfterWashpopup = (TNAId, AppareanceTestId, StyleId, index, subIndex, childIndex) => {
    //     setTNAId(TNAId);
    //     setAppareanceTestId(AppareanceTestId);
    //     setStyleId(StyleId)
    //     setApperancemodalpopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }
    const handleLabDip = () => {
        setLapmodal(false);
    };

    const LapdipSubmissionCallback = (value) => {
        if (value === true) {
            setLapmodal(false);
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].IsShowSubmitButton = 0;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StatusID = 3;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ManualCloseDate = new Date();
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate = new Date();
            setInputFields(values);
        }
    }

    const LapdipApprovalCallback = (value) => {
        if (value === true) {
            setOpenLabdipApppopup(false);
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].IsShowApprovalButton = 0;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StatusID = 3;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ManualCloseDate = new Date();
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate = new Date();
            setInputFields(values);
        }
    }

    const StrikeoffSubmissionCallback = (value) => {
        if (value === true) {
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].IsShowSubmitButton = 0;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StatusID = 3;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ManualCloseDate = new Date();
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate = new Date();

            setInputFields(values);
            setStrikeOffmodalpopup(false);
        }
    }

    const StrikeoffApprovalCallback = (value) => {
        if (value === true) {
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].IsShowApprovalButton = 0;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StatusID = 3;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ManualCloseDate = new Date();
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate = new Date();
            setInputFields(values);
            setStrikeOffApppopup(false);
        }
    }

    const TrimSubmissionCallback = (value) => {
        if (value === true) {
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].IsShowSubmitButton = 0;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StatusID = 3;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ManualCloseDate = new Date();
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate = new Date();

            setInputFields(values);
            setTrimmodalpopup(false);
        }
    }

    const TrimApprovalCallback = (value) => {
        if (value === true) {
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].IsShowApprovalButton = 0;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StatusID = 3;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ManualCloseDate = new Date();
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate = new Date();
            setInputFields(values);
            setTrimApprovalpopup(false);
        }
    }
    const ProtoFitSMSSubmissionCallback = (value) => {
        if (value === true) {
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StatusID = 3;
            setInputFields(values);
            setSampleSubModalpopup(false);
        }
    }

    const ProtoFitAprovalCallback = (value) => {
        if (value === true) {
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StatusID = 3;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ManualCloseDate = new Date();
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate = new Date();
            setInputFields(values);
            setSampleAppModalpopup(false);
        }
    }
    const AppareanceTestSubmissionCallback = (value) => {
        if (value === true) {
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StatusID = 3;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ManualCloseDate = new Date();
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate = new Date();
            setInputFields(values);
            setApperancemodalpopup(false);
        }
    }

    const EditComments = (index, Value, Childindex) => {

        values[index].Comments = Value;
        //values.Comments = Value;
        setEditedCommentIndex(Childindex);
        setInputFields(values);
    }

    const RemoveComments = (index, ChildIndex2) => {
        values[index].arrComment.splice(ChildIndex2, 1);
        values[index].CommentsCount = values[index].arrComment.length;
        setInputFields(values);


    }

    const handleChildManualclose = (index, subIndex, childIndex) => {
        //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
        //setInputFields(values);

        if (values[index].intDependency === 0) {
            let Dependency = false;
            if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.length > 0) {
                let arrstatus = [];
                arrstatus = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.split(',');
                if (arrstatus.length > 0) {
                    arrstatus.forEach(element => {
                        let val = [];
                        val = element.split('-');
                        let valIndex = values[parseInt(val[0]) - 1].StatusID
                        if (valIndex !== 3) {
                            Dependency = true;
                        }
                    });
                }
                if (Dependency) {
                    Nodify('Warning!', 'warning', "Please close dependency task");
                }
                else { values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1; setInputFields(values); }
            } else {
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
                setInputFields(values);
            }
        }
        else {
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
            setInputFields(values);

        }
        //setInputFields(values);
    };

    const handleCloseManualPopup = (index, subIndex, childIndex) => {
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
        setInputFields(values);
    }

    const handleChangeIsManualCloseComments = (event, index, subIndex, childIndex) => {

        setComment(false);

        let input = '';
        if (event.target.value.trim() !== '') {
            input = event.target.value;
        }
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseComment = input;
        setInputFields(values);
    }

    const SaveChildManulaCloseComments = (index, subIndex, childIndex, value) => {

        if (value === '' || value === undefined) {
            setComment(true);
            Nodify('Warning!', 'warning', "Please enter comments");
        }
        else {
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StatusID = 3;
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseDate = new Date();
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildActualEndDate = new Date();
            setInputFields(values);
        }

    }

    const showManualApproval = (index, subIndex, childIndex, childId, TaskName) => {
        let Operation;
        if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
            Operation = 1;
        }
        else if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
            || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
            Operation = 3;
        }
        else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
            || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER) {
            Operation = 2;
        }

        TnaService.getManualApproval(childId, Operation).then((response) => {
            if (response.data) {
                setmanualApprovalData(response.data);
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualApprovalType = 1;
                setInputFields(values);
            }
            else {
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualApprovalType = 1;
                setInputFields(values);
            }
        }).catch(() => { });
    }

    const hideManualApproval = (index, subIndex, childIndex) => {
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualApprovalType = 0;
        setInputFields(values);
    }

    const showExistingApproval = (index, subIndex, childIndex, childId, TaskName) => {
        let Operation;
        if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
            Operation = 1;
        }
        else if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
            || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
            Operation = 3;
        }
        else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
            || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER) {
            Operation = 2;
        }

        TnaService.getExistingApproval(childId, Operation).then((response) => {
            if (response.data) {
                setexistingApprovalData(response.data);
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ExistingApprovalType = 1;
                setInputFields(values);
            }
            else {
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ExistingApprovalType = 1;
                setInputFields(values);
            }
        }).catch(() => { });
    }

    const hideExistingApproval = (index, subIndex, childIndex) => {
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ExistingApprovalType = 0;
        setInputFields(values);
    }


    return (

        <Fragment>
            <ReactNotification />
            <div> {
                isOpen && (
                    <Lightbox
                        mainSrc={isOpen}
                        alt="bg image"

                        onCloseRequest={
                            () => setIsOpen(false)
                        } />
                )
            } </div>
            <div className="row">
                <div className="col-xs-12 col-md-12">
                    <div className="widget">
                        <div className="widget-body">
                            {props.Action === undefined ?
                                <div className="row">
                                    <div className="col-xs-12 col-md-12">
                                        <div className="flat radius-bordered">
                                            <div className="widget-header heading_box_style">


                                                <h3 className="heading_style_common">View TNA</h3>
                                                <div className="buttons-preview" align="right">
                                                    <span className="btn btn-primary"
                                                        onClick={
                                                            () => ResetOperation("Back")
                                                        }
                                                    >
                                                        <i className="fa fa-arrow-circle-left"></i>
                                                        &nbsp;Back</span>

                                                    <span className="btn btn-danger"
                                                        onClick={
                                                            () => ResetOperation()
                                                        } >
                                                        <i className="fa fa-times"></i>
                                                        &nbsp;Reset</span>


                                                    <button type="button" className="btn btn-success"
                                                        onClick={UpdateTNA}
                                                    >
                                                        <i className="fa fa-check right"></i>
                                                        &nbsp;{isLastEdit ? 'Update' : 'Save'}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ''
                            }
                            <div className="widget-body">
                                <div id="registration-form">
                                    <form>

                                        <div className="col-md-12" style={{ overflowX: "auto", minHeight: '100px' }}>
                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable" style={{ width: "130%" }}>
                                                <thead>
                                                    <tr >
                                                        <th style={{ width: "125px" }} className="fixed-column-header-first-tna"> #</th>
                                                        <th style={{ width: "450px" }}>Task Type<span className="text-danger">*</span></th>
                                                        <th style={{ width: "1000px" }}>Task Name<span className="text-danger">*</span></th>
                                                        <th style={{ width: "200px" }}>Plan End Date</th>
                                                        <th style={{ width: "200px" }}>Actual End Date</th>
                                                        <th style={{ width: "320px" }}>Dependency</th>
                                                        <th style={{ width: "493px" }}>Task Holder / Follower</th>
                                                        <th style={{ width: "280px" }}>Leave Days</th>
                                                        <th style={{ width: "360px" }}>Status</th>
                                                        <th style={{ width: "620px" }}>AWB & Status</th>
                                                        {/* <th style={{ width: "750px" }} className="text-center fixed-column-header-Route">Action</th> */}

                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    {

                                                        inputFields.map((inputField, index) => (
                                                            <Fragment key={`${inputField}~${index}`}>

                                                                <tr class="parent" data-child={"parent_" + index}>
                                                                    <td className="fixed-column-first-tna">
                                                                        {index + 1}&nbsp;
                                                                        {
                                                                            !inputField.IsSingleTask && <span id={"btn-toggle_" + index} class="btn-toggle " aria-expanded="false" onClick={() => handleToggle(index)} aria-labelledby="btn-toggle id-master">
                                                                                <i id={"icon-toggle_" + index} class="fa fa-plus-square-o clsPointer clscollapseexpandTNA"></i>
                                                                            </span>
                                                                        }

                                                                    </td>
                                                                    <td>
                                                                        <span className='input-icon icon-right'>
                                                                            {/* <Reactselect className="basic-single" name="TaskType"
                                                                                id={
                                                                                    "TaskType" + index
                                                                                }
                                                                                isLoading={false}
                                                                                isClearable={true}
                                                                                isSearchable={true}
                                                                                onChange={event => handleChange(event, index, "TaskType")}
                                                                                onBlur={() => CheckDuplicate(index, 'TaskType')}
                                                                                value={getTaskTypeList.filter(function (option) {
                                                                                    return option.value === inputField.TaskType;
                                                                                })}
                                                                                options={getTaskTypeList}
                                                                                menuPosition="fixed"
                                                                                isDisabled={inputField.IsNewRow}
                                                                                styles={submitted && inputField.TaskType === 0 ? styles : ''}
                                                                            ></Reactselect> */}
                                                                            {
                                                                                inputField.TaskTypeName
                                                                            }
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span className='input-icon icon-right'>
                                                                            {/* <Reactselect className="basic-single" name="TaskName"
                                                                                id={
                                                                                    "TaskName" + index
                                                                                }
                                                                                isLoading={false}
                                                                                isClearable={true}
                                                                                isSearchable={true}
                                                                                onChange={event => handleChange(event, index, "TaskName")}
                                                                                onBlur={() => CheckDuplicate(index, 'TaskName')}
                                                                                value={getAllTaskList.filter(function (option) {
                                                                                    return option.value === inputField.TaskID;
                                                                                })}
                                                                                options={inputField.TaskType === 0 ? getAllTaskList : getAllTaskList.filter(x => x.taskTypeID === inputField.TaskType)}
                                                                                isOptionDisabled={(option) => option.isdisabled}
                                                                                menuPosition="fixed"
                                                                                isDisabled={inputField.IsNewRow}
                                                                                styles={submitted && inputField.TaskID === 0 ? styles : ''}
                                                                            ></Reactselect> */}
                                                                            {
                                                                                inputField.strTaskName
                                                                            }
                                                                        </span>
                                                                    </td>
                                                                    <td>

                                                                        <span className="input-icon icon-right">
                                                                            {
                                                                                inputField.StrEndDate
                                                                            }

                                                                        </span>
                                                                    </td>


                                                                    <td>
                                                                        <span className="input-icon icon-right">
                                                                            {
                                                                                inputField.StrActualEndDate
                                                                            }

                                                                        </span>
                                                                    </td>


                                                                    <td>

                                                                        {
                                                                            inputField.Dependency
                                                                        }

                                                                    </td>
                                                                    <td >
                                                                        {/* <input type="text"
                                                                            value={inputField.TaskHolderOwnerTxtList}
                                                                            title={inputField.TaskHolderOwnerTxtList}
                                                                            onClick={() => handleTaskHolderLeaveDays(index, inputField.IsTHFirstTime)}
                                                                            className={'form-control'}
                                                                            disabled={true}
                                                                            //disabled={Buttonvisible === 'View' ? true : false}
                                                                            //style={{ width: "100%", float: "left" }}
                                                                            style={{ width: "100%", float: "left", border: submitted && !inputField.TaskHolderOwnerTxtList ? '1px solid red' : '' }}
                                                                        /> */}
                                                                        <i class="fa fa-eye" title="Task Holder" onClick={() => handleTaskHolderLeaveDays(index, inputField.IsTHFirstTime)}></i>&nbsp;&nbsp;&nbsp;
                                                                        {
                                                                            <span>
                                                                                {
                                                                                    getTaskHolderList.filter(d => d.value === inputField.TaskHolderOrFollower).map(task => {
                                                                                        return task.label;
                                                                                    })
                                                                                }
                                                                            </span>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {/* <input type="text"
                                                                            value={inputField.LeaveDaysTxt}
                                                                            title={inputField.LeaveDaysTxt}
                                                                            //disabled={Buttonvisible === 'View' ? true : false}
                                                                            onClick={() => handleTaskHolderLeaveDays(index, inputField.IsTHFirstTime)}
                                                                            className={'form-control'}
                                                                            disabled={true}
                                                                            style={{ width: "100%", float: "left" }}
                                                                        /> */}
                                                                        {inputField.LeaveDaysTxt}
                                                                    </td>
                                                                    <td>
                                                                        <span className='input-icon icon-right'>
                                                                            {/* {inputField.StatusName} */}
                                                                            <Reactselect className="basic-single" name="StatusID"
                                                                                id={
                                                                                    "Status" + index
                                                                                }
                                                                                isLoading={false}
                                                                                isClearable={true}
                                                                                isSearchable={true}
                                                                                onChange={event => handleChange(event, index, "StatusID")}
                                                                                value={getTNAStatusList.filter(function (option) {
                                                                                    return option.value === inputField.StatusID;
                                                                                })}
                                                                                // isDisabled={inputField.StatusID === 3 ? true : inputField.IsSingleTask === false ? true : false}
                                                                                options={getTNAStatusList}
                                                                                //   isOptionDisabled={(option) => option.isdisabled}
                                                                                isDisabled={true}
                                                                                menuPosition="fixed"
                                                                            ></Reactselect>
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span className='input-icon icon-right'>
                                                                            <input type="text"
                                                                                placeholder="AWB & Status"
                                                                                id={"AWBStatus" + index}
                                                                                name="AWBStatus"
                                                                                value={inputField.AWBStatus}
                                                                                onChange={event => handleChange(event, index, "AWBStatus")}
                                                                                className={'form-control' + (
                                                                                    submitted && !inputField.AWBStatus ? ' is-invalid' : ''
                                                                                )}
                                                                            />
                                                                        </span>
                                                                    </td>


                                                                    {inputField.IsDependencyOpen === 1 &&

                                                                        <Modal show={inputField.IsDependencyOpen === 1} size="lg" backdrop="static" keyboard={false} onHide={() => handleDependencyClose(index)}>
                                                                            <Modal.Header closeButton>
                                                                                <Modal.Title>
                                                                                    {/* Task Name : {inputField.strTaskName} */}
                                                                                    Dependency
                                                                                </Modal.Title>
                                                                            </Modal.Header>
                                                                            <Modal.Body>
                                                                                <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th style={{ width: "50px" }}>#</th>
                                                                                            <th style={{ width: "450px" }}>Task Name</th>
                                                                                            <th style={{ width: "200px" }}>Dependency</th>
                                                                                            <th style={{ width: "150px" }}>Log Days</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            inputField.arrDependency && inputField.arrDependency.map((ChildField, childIndex) => (
                                                                                                <Fragment key={
                                                                                                    `${ChildField}~${childIndex}`
                                                                                                }>
                                                                                                    <tr>
                                                                                                        <td>{childIndex + 1}</td>
                                                                                                        <td>{ChildField.TNATaskName}</td>
                                                                                                        <td>
                                                                                                            <span className='input-icon icon-right'>
                                                                                                                <Reactselect className="basic-single" name="DependencyID"
                                                                                                                    // id={
                                                                                                                    //     "DependencyID" + TaskIndex
                                                                                                                    // }
                                                                                                                    isLoading={false}
                                                                                                                    isClearable={true}
                                                                                                                    isSearchable={true}
                                                                                                                    onChange={event => handleChildChange(event, index, childIndex, "DependencyID")}
                                                                                                                    value={getDependencyList.filter(function (option) {
                                                                                                                        return option.value === ChildField.DependencyID;
                                                                                                                    })}
                                                                                                                    options={getDependencyList}
                                                                                                                    isDisabled={childIndex === index ? true : ChildField.TNATaskName === '' ? true : false}
                                                                                                                ></Reactselect>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <span className='input-icon icon-right'>
                                                                                                                <input type="text"
                                                                                                                    placeholder="    Log days"
                                                                                                                    id={"LogDays" + index}
                                                                                                                    name="LogDays"
                                                                                                                    value={ChildField.LogDays}
                                                                                                                    onChange={event => handleChildChange(event, index, childIndex, "LogDays")}
                                                                                                                    maxLength="3"
                                                                                                                    className={'form-control' + (
                                                                                                                        submitted && !inputField.LogDays ? ' is-invalid' : ''
                                                                                                                    )}
                                                                                                                    disabled={ChildField.DependencyID === 0 ? true : false}
                                                                                                                />
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </Fragment>
                                                                                            ))
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </Modal.Body>
                                                                            <Modal.Footer>
                                                                                <Button variant="success" onClick={() => handleDependencyClose(index)}>
                                                                                    Save
                                                                                </Button>

                                                                            </Modal.Footer>
                                                                        </Modal>
                                                                    }

                                                                    {/* {inputField.IsTHLeaveDaysOpen === 1 &&
                                                                        <Modal show={inputField.IsTHLeaveDaysOpen === 1} size="lg" backdrop="static" keyboard={false} onHide={() => handleTHLeaveDaysClose(index)}>
                                                                            <Modal.Header closeButton>
                                                                                <Modal.Title>
                                                                                    Leave Days / In-charges - {inputField.strTaskName}
                                                                                </Modal.Title>
                                                                            </Modal.Header>
                                                                            <Modal.Body>
                                                                                <div className="row">
                                                                                    <div className="col-md-12">
                                                                                        <div className='form-group'>
                                                                                            <label> Task Holder / Follower </label>
                                                                                            <span className='input-icon icon-right'>
                                                                                                <Reactselect className="basic-single" name="TaskHolderOrFollower"
                                                                                                    id={"TaskHolderOrFollower"}
                                                                                                    isLoading={false}
                                                                                                    //isClearable={true}
                                                                                                    isSearchable={true}
                                                                                                    //onChange={event => handleChange(event, index, "TaskHolderOrFollower")}
                                                                                                    value={getTaskHolderList.filter(function (option) {
                                                                                                        return option.value === inputField.TaskHolderOrFollower;
                                                                                                    })}
                                                                                                    //options={getTaskHolderList}
                                                                                                    //styles={submitted && inputField.TaskHolderOrFollower === 0 ? styles : ''}
                                                                                                    menuPosition="fixed"
                                                                                                    filterOption={filterOption}
                                                                                                ></Reactselect>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-md-12">
                                                                                        <div className='form-group'>
                                                                                            <label>Leave Days</label>
                                                                                            <span className='input-icon icon-right'>
                                                                                                <Reactselect className="basic-single" name="LeaveDays"
                                                                                                    id={
                                                                                                        "LeaveDays"
                                                                                                    }
                                                                                                    isLoading={false}
                                                                                                    // isClearable={true}
                                                                                                    isSearchable={true}
                                                                                                    //onChange={event => handleChange(event, index, "LeaveDays")}
                                                                                                    value={getLeaveDaysList.filter(function (option) {
                                                                                                        return option.value === inputField.LeaveDays;
                                                                                                    })}
                                                                                                    //options={getLeaveDaysList}
                                                                                                    //styles={submitted && inputField.LeaveDays === 0 ? styles : ''}
                                                                                                    menuPosition="fixed"

                                                                                                ></Reactselect>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-md-12">
                                                                                        <div className='form-group'>
                                                                                            <label htmlFor={inputField.titleEmployee1 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee1 === "Cube" ? "FollowerCube" : "FollowerSupplier"} >Task Holder - {inputField.titleEmployee1} </label>
                                                                                            <span className='input-icon icon-right'>
                                                                                                <Reactselect className="basic-single" name={inputField.titleEmployee1 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee1 === "Cube" ? "FollowerCube" : "FollowerSupplier"}
                                                                                                    isDisabled={false}
                                                                                                    isLoading={false}
                                                                                                    //isClearable={true}
                                                                                                    isSearchable={true}
                                                                                                    isMulti
                                                                                                    //onChange={event => handleChange(event, index, inputField.titleEmployee1 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee1 === "Cube" ? "FollowerCube" : "FollowerSupplier")}
                                                                                                    // options={inputField.titleEmployee1 === "Buyer" ? drpTNATaskOwnerList.buyerEmployeeList : inputField.titleEmployee1 === "Cube" ? drpTNATaskOwnerList.cubeEmployeeList : drpTNATaskOwnerList.supplierEmployeeList}
                                                                                                    value={inputField.titleEmployee1 === "Buyer" ? drpTNATaskOwnerList.buyerEmployeeList && inputField.arrTaskHolderBuyer : inputField.titleEmployee1 === "Cube" ? drpTNATaskOwnerList.cubeEmployeeList && inputField.arrFollowerCube : drpTNATaskOwnerList.supplierEmployeeList && inputField.arrFollowerSupplier}
                                                                                                >  </Reactselect>

                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                                <div className="row">
                                                                                    <div className="col-md-12">
                                                                                        <div className='form-group'>
                                                                                            <label htmlFor={inputField.titleEmployee2 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee2 === "Cube" ? "FollowerCube" : "FollowerSupplier"} >Follower - {inputField.titleEmployee2}</label>
                                                                                            <span className='input-icon icon-right'>
                                                                                                <Reactselect className="basic-single" name={inputField.titleEmployee2 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee2 === "Cube" ? "FollowerCube" : "FollowerSupplier"}
                                                                                                    isDisabled={false}
                                                                                                    isLoading={false}
                                                                                                    //isClearable={true}
                                                                                                    isSearchable={true}
                                                                                                    isMulti
                                                                                                    //onChange={event => handleChange(event, index, inputField.titleEmployee2 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee2 === "Cube" ? "FollowerCube" : "FollowerSupplier")}
                                                                                                    //options={inputField.titleEmployee2 === "Buyer" ? drpTNATaskOwnerList.buyerEmployeeList : inputField.titleEmployee2 === "Cube" ? drpTNATaskOwnerList.cubeEmployeeList : drpTNATaskOwnerList.supplierEmployeeList}
                                                                                                    value={inputField.titleEmployee2 === "Buyer" ? inputField.arrTaskHolderBuyer : inputField.titleEmployee2 === "Cube" ? inputField.arrFollowerCube : inputField.arrFollowerSupplier}
                                                                                                // options={IMPORTANCE}
                                                                                                // value={getStyleList.arrImportance}
                                                                                                //  styles={submitted && getStyleList.arrImportance === 0 ? styles : ''}
                                                                                                >  </Reactselect>

                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {<div className="row">
                                                                                    <div className="col-md-12">
                                                                                        <div className='form-group'>
                                                                                            <label htmlFor="FollowerSupplier" >Follower - {inputField.titleEmployee3}</label>
                                                                                            <span className='input-icon icon-right'>
                                                                                                <Reactselect className="basic-single" name={inputField.titleEmployee3 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee3 === "Cube" ? "FollowerCube" : "FollowerSupplier"}
                                                                                                    isDisabled={false}
                                                                                                    isLoading={false}
                                                                                                    //isClearable={true}
                                                                                                    isSearchable={true}
                                                                                                    isMulti
                                                                                                    // onChange={event => handleChange(event, index, inputField.titleEmployee3 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee3 === "Cube" ? "FollowerCube" : "FollowerSupplier")}
                                                                                                    //options={inputField.titleEmployee3 === "Buyer" ? drpTNATaskOwnerList.buyerEmployeeList : inputField.titleEmployee3 === "Cube" ? drpTNATaskOwnerList.cubeEmployeeList : drpTNATaskOwnerList.supplierEmployeeList}
                                                                                                    value={inputField.titleEmployee3 === "Buyer" ? inputField.arrTaskHolderBuyer : inputField.titleEmployee3 === "Cube" ? inputField.arrFollowerCube : inputField.arrFollowerSupplier}
                                                                                                // options={IMPORTANCE}
                                                                                                // value={getStyleList.arrImportance}
                                                                                                //  styles={submitted && getStyleList.arrImportance === 0 ? styles : ''}
                                                                                                >  </Reactselect>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                }
                                                                            </Modal.Body>
                                                                            <Modal.Footer>
                                                                                <Button variant="danger" onClick={() => handleTHLeaveDaysClose(index)}>
                                                                                    <i class="fa fa-close"></i>&nbsp;Close
                                                                                </Button>

                                                                            </Modal.Footer>
                                                                        </Modal>
                                                                    } */}
                                                                    {inputField.IsTHLeaveDaysOpen === 1 &&
                                                                        <Modal show={inputField.IsTHLeaveDaysOpen === 1} size="lg" backdrop="static" keyboard={false} onHide={() => handleTHLeaveDaysClose(index)}>
                                                                            <Modal.Header closeButton>
                                                                                <Modal.Title>
                                                                                    Leave Days / In-charges - {inputField.strTaskName}
                                                                                </Modal.Title>
                                                                            </Modal.Header>
                                                                            <Modal.Body>
                                                                                <div className="row">
                                                                                    <div className="col-md-12">
                                                                                        <div className='form-group'>
                                                                                            <label> Task Holder / Follower  </label>
                                                                                            <span className='input-icon icon-right'>
                                                                                                <Reactselect className="basic-single" name="TaskHolderOrFollower"
                                                                                                    id={"TaskHolderOrFollower"}
                                                                                                    isLoading={false}
                                                                                                    isClearable={false}
                                                                                                    isSearchable={true}
                                                                                                    value={getTaskHolderList.filter(function (option) {
                                                                                                        return option.value === inputField.TaskHolderOrFollower;
                                                                                                    })}
                                                                                                    menuPosition="fixed"
                                                                                                //filterOption={filterOption}
                                                                                                //isDisabled={true}
                                                                                                ></Reactselect>

                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-md-12">
                                                                                        <div className='form-group'>
                                                                                            <label>Leave Days</label>
                                                                                            <span className='input-icon icon-right'>
                                                                                                <Reactselect className="basic-single" name="LeaveDays"
                                                                                                    id={
                                                                                                        "LeaveDays"
                                                                                                    }
                                                                                                    isLoading={false}
                                                                                                    isClearable={false}
                                                                                                    isSearchable={true}
                                                                                                    value={getLeaveDaysList.filter(function (option) {
                                                                                                        return option.value === inputField.LeaveDays;
                                                                                                    })}
                                                                                                    //isDisabled={true}
                                                                                                    // options={getLeaveDaysList}
                                                                                                    // styles={submitted && inputField.LeaveDays === 0 ? styles : ''}
                                                                                                    menuPosition="fixed"
                                                                                                ></Reactselect>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {inputField.titleEmployee1 && <div className="row">
                                                                                    <div className="col-md-12">
                                                                                        <div className='form-group'>
                                                                                            <label htmlFor={inputField.titleEmployee1 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee1 === "Cube" ? "FollowerCube" : "FollowerSupplier"} >Task Holder - {inputField.titleEmployee1 + " Employee"}<span className="text-danger">*</span> </label>
                                                                                            <span className='input-icon icon-right'>
                                                                                                <Reactselect className="basic-single" name={inputField.titleEmployee1 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee1 === "Cube" ? "FollowerCube" : "FollowerSupplier"}
                                                                                                    //isDisabled={true}
                                                                                                    isLoading={false}
                                                                                                    isClearable={false}
                                                                                                    isSearchable={true}
                                                                                                    isMulti
                                                                                                    //onChange={event => handleChange(event, index, inputField.titleEmployee1 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee1 === "Cube" ? "FollowerCube" : "FollowerSupplier")}
                                                                                                    //options={inputField.titleEmployee1 === "Buyer" ? drpTNATaskOwnerList.buyerEmployeeList : inputField.titleEmployee1 === "Cube" ? drpTNATaskOwnerList.cubeEmployeeList : drpTNATaskOwnerList.supplierEmployeeList}
                                                                                                    value={inputField.titleEmployee1 === "Buyer" ? drpTNATaskOwnerList.buyerEmployeeList && inputField.arrTaskHolderBuyer : inputField.titleEmployee1 === "Cube" ? drpTNATaskOwnerList.cubeEmployeeList && inputField.arrFollowerCube : drpTNATaskOwnerList.supplierEmployeeList && inputField.arrFollowerSupplier}
                                                                                                >  </Reactselect>

                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                }

                                                                                {
                                                                                    inputField.titleEmployee2 && <div className="row">
                                                                                        <div className="col-md-12">
                                                                                            <div className='form-group'>
                                                                                                <label htmlFor={inputField.titleEmployee2 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee2 === "Cube" ? "FollowerCube" : "FollowerSupplier"} >Follower - {inputField.titleEmployee2 + " Employee"}</label>
                                                                                                <span className='input-icon icon-right'>
                                                                                                    <Reactselect className="basic-single" name={inputField.titleEmployee2 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee2 === "Cube" ? "FollowerCube" : "FollowerSupplier"}
                                                                                                        //isDisabled={true}
                                                                                                        isLoading={false}
                                                                                                        isClearable={false}
                                                                                                        isSearchable={true}
                                                                                                        isMulti
                                                                                                        //onChange={event => handleChange(event, index, inputField.titleEmployee2 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee2 === "Cube" ? "FollowerCube" : "FollowerSupplier")}
                                                                                                        //options={inputField.titleEmployee2 === "Buyer" ? drpTNATaskOwnerList.buyerEmployeeList : inputField.titleEmployee2 === "Cube" ? drpTNATaskOwnerList.cubeEmployeeList : drpTNATaskOwnerList.supplierEmployeeList}
                                                                                                        value={inputField.titleEmployee2 === "Buyer" ? inputField.arrTaskHolderBuyer : inputField.titleEmployee2 === "Cube" ? inputField.arrFollowerCube : inputField.arrFollowerSupplier}
                                                                                                    // options={IMPORTANCE}
                                                                                                    // value={getStyleList.arrImportance}
                                                                                                    //  styles={submitted && getStyleList.arrImportance === 0 ? styles : ''}
                                                                                                    >  </Reactselect>

                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                                {inputField.titleEmployee3 && <div className="row">
                                                                                    <div className="col-md-12">
                                                                                        <div className='form-group'>
                                                                                            <label htmlFor="FollowerSupplier" >Follower - {inputField.titleEmployee3}</label>
                                                                                            <span className='input-icon icon-right'>
                                                                                                <Reactselect className="basic-single" name={inputField.titleEmployee3 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee3 === "Cube" ? "FollowerCube" : "FollowerSupplier"}
                                                                                                    isDisabled={false}
                                                                                                    isLoading={false}
                                                                                                    isClearable={true}
                                                                                                    isSearchable={true}
                                                                                                    isMulti
                                                                                                    //onChange={event => handleChange(event, index, inputField.titleEmployee3 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee3 === "Cube" ? "FollowerCube" : "FollowerSupplier")}
                                                                                                    //options={inputField.titleEmployee3 === "Buyer" ? drpTNATaskOwnerList.buyerEmployeeList : inputField.titleEmployee3 === "Cube" ? drpTNATaskOwnerList.cubeEmployeeList : drpTNATaskOwnerList.supplierEmployeeList}
                                                                                                    value={inputField.titleEmployee3 === "Buyer" ? inputField.arrTaskHolderBuyer : inputField.titleEmployee3 === "Cube" ? inputField.arrFollowerCube : inputField.arrFollowerSupplier}
                                                                                                // options={IMPORTANCE}
                                                                                                // value={getStyleList.arrImportance}
                                                                                                //  styles={submitted && getStyleList.arrImportance === 0 ? styles : ''}
                                                                                                >  </Reactselect>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                }

                                                                            </Modal.Body>
                                                                            <Modal.Footer>
                                                                                <Button variant="danger" onClick={() => handleTHLeaveDaysClose(index)}>
                                                                                    <i class="fa fa-close"></i>&nbsp;Close
                                                                                </Button>

                                                                            </Modal.Footer>
                                                                        </Modal>
                                                                    }
                                                                    {

                                                                        inputField.IsPopupOpen === 1 &&


                                                                        <Modal show={inputField.IsPopupOpen === 1} size="md" backdrop="static" keyboard={false} onHide={() => handleClose(index)}>
                                                                            <Modal.Header closeButton>
                                                                                <Modal.Title>Additional Task</Modal.Title>
                                                                            </Modal.Header>
                                                                            <Modal.Body>
                                                                                <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>#</th>
                                                                                            <th>Task Name</th>
                                                                                            <th> <div className="checkbox" style={{ marginTop: "0px" }}>
                                                                                                <label>
                                                                                                    <input type="checkbox"
                                                                                                        name="IsSelectAll"
                                                                                                        id={"IsSelectAll_" + index}
                                                                                                        isMulti
                                                                                                        onChange={event => handleChange(event, index, "IsSelectAll")}
                                                                                                        checked={inputField.IsSelectAll === 1 ? true : false}
                                                                                                        className="colored-blue clearOpacity"
                                                                                                        disabled={inputField.TaskFieldEnable === 0 ? true : false}
                                                                                                    />
                                                                                                    <span className="text"></span>
                                                                                                </label>
                                                                                            </div></th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            inputField.FollowupDetails && inputField.FollowupDetails.map((ChildField, childIndex) => (
                                                                                                <Fragment key={
                                                                                                    `${inputField.FollowupDetails}~${childIndex}`
                                                                                                }>
                                                                                                    <tr>
                                                                                                        <td>{childIndex + 1}</td>
                                                                                                        <td>
                                                                                                            <input type="text"
                                                                                                                value={ChildField.AddtionalTaskName}
                                                                                                                className={'form-control'}
                                                                                                                disabled
                                                                                                            />
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <div className="checkbox" style={{ marginTop: "0px" }}>
                                                                                                                <label>
                                                                                                                    <input type="checkbox"
                                                                                                                        name="IsTaskSelected"
                                                                                                                        id={"IsTaskSelected_" + index + "_" + childIndex}
                                                                                                                        isMulti
                                                                                                                        onChange={event => handleAddTaskChange(event, index, childIndex, "IsTaskSelected")}
                                                                                                                        checked={ChildField.IsTaskSelected === 1 ? true : false}
                                                                                                                        className="colored-blue clearOpacity"
                                                                                                                        disabled={inputField.TaskFieldEnable === 0 ? true : false}
                                                                                                                    />
                                                                                                                    <span className="text"></span>
                                                                                                                </label>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </Fragment>
                                                                                            ))
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </Modal.Body>
                                                                            <Modal.Footer>

                                                                                <Button disabled={inputField.TaskFieldEnable === 0 ? true : false} variant="success" onClick={() => handleClose(index)}> Save </Button>

                                                                            </Modal.Footer>
                                                                        </Modal>
                                                                    }

                                                                    {/* Gowtham */}
                                                                    {

                                                                        inputField.IsComment === 1 &&
                                                                        <Modal show={inputField.IsComment === 1} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handleCloseComment(index)}>
                                                                            <Modal.Header closeButton>
                                                                                <Modal.Title>Comments</Modal.Title>
                                                                            </Modal.Header>
                                                                            <Modal.Body>
                                                                                <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>Comments</th>
                                                                                            <th style={{ width: '16%' }}>Date</th>
                                                                                            <th style={{ width: '10%' }}>Action</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            inputField.arrComment && inputField.arrComment.map((commentId, index) => {
                                                                                                return (
                                                                                                    commentId.TNAComments !== '' ?
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                {commentId.TNAComments}
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                {commentId.Date && commentId.Date.toLocaleDateString('en-US')}
                                                                                                                {/* &nbsp;
                                                                                                                    {commentId.Date && commentId.Date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} */}
                                                                                                                {/* {commentId.Date} */}
                                                                                                                {/* {commentId.Date && commentId.Date.toLocaleDateString('en-US')}
                                                                                                                    &nbsp;
                                                                                                                    {commentId.Date && commentId.Date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} */}
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                {
                                                                                                                    <Fragment>
                                                                                                                        <span style={{ padding: '0px', display: index === (inputField.arrComment.length - 1) ? "" : "none", float: "" }}>

                                                                                                                            <span>
                                                                                                                                <span className="btn btn-success" title="Edit Comment" onClick={() => EditComments(commentId.TaskIndexName, commentId.TNAComments, inputField.arrComment.length)}>
                                                                                                                                    <i className="fa fa-edit"></i>
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                    </Fragment>
                                                                                                                }
                                                                                                                &nbsp;

                                                                                                                {


                                                                                                                    <Fragment>
                                                                                                                        <span>
                                                                                                                            <span>
                                                                                                                                <span className="btn btn-danger" title="Delete Comment" onClick={() => RemoveComments(commentId.TaskIndexName, index)}>
                                                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                    </Fragment>
                                                                                                                }
                                                                                                            </td>
                                                                                                        </tr> : ''
                                                                                                );
                                                                                            })

                                                                                        }
                                                                                        <tr>
                                                                                            <td colSpan="6">
                                                                                                <textarea
                                                                                                    rows="3"
                                                                                                    placeholder="Enter comments here..."
                                                                                                    autoFocus onFocus={""} autoComplete="off"
                                                                                                    maxLength="500" name="Comments"
                                                                                                    id={"Comments"}
                                                                                                    value={inputField.Comments}
                                                                                                    onChange={event => handleChangeTNAComments(event, index)}
                                                                                                    style={{ width: "100%", border: getComment && inputField.Comments === '' ? '1px solid red' : '' }}>

                                                                                                </textarea>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </Modal.Body>
                                                                            <Modal.Footer>

                                                                                {/* <Button variant="success" onClick={() => handleSave(index, inputField.arrComment.length, inputField.Comments)}>
                                                                                    Save
                                                                                </Button> */}

                                                                                <Button variant="success" onClick={() => handleSave(index, inputField.arrComment.length, inputField.Comments, inputField.TaskID)}> {/* , skuItem.PurOrdSkuCommentsID, skuItem.PurchaseOrderID */}
                                                                                    Save
                                                                                </Button>

                                                                            </Modal.Footer>
                                                                        </Modal>
                                                                    }
                                                                    {/* Sathish */}
                                                                    {

                                                                        inputField.IsAttachment === 1 &&
                                                                        <Modal show={inputField.IsAttachment === 1} size="lg" backdrop="static" keyboard={false} onHide={() => handleCloseAttachment(index)}>
                                                                            <Modal.Header closeButton>
                                                                                <Modal.Title> <span className="widget-caption" style={{ fontSize: '14px' }}>File  Upload <span style={{ color: 'red', fontSize: '14px' }}>(Upload Document Type: Excel, Word, PDF and Image)</span></span></Modal.Title>
                                                                            </Modal.Header>
                                                                            <Modal.Body>
                                                                                <div className="row">
                                                                                    <div className="col-sm-12 col-lg-12">

                                                                                        <div className="row">
                                                                                            <div className="col-sm-8 col-lg-8">
                                                                                                <div className='form-group'>
                                                                                                    <tr>
                                                                                                        <td> <input type="file" id="TNAFileUpload" style={{ fontSize: '14px' }}
                                                                                                            onChange={(e) => AppendFiles(e)}
                                                                                                            accept=".jpg,.jpeg,.png,.pdf,.xlsx,.doc,.docx" ></input></td>
                                                                                                    </tr>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="col-lg-12 col-sm-12">
                                                                                            <div className="row no-margin">
                                                                                                {
                                                                                                    getFiles.length !== 0 ?
                                                                                                        getFiles.map((files, fileindex) => (
                                                                                                            files.TaskIndexName === getTaskIndexFileupload &&
                                                                                                            <div className="col-lg-4 col-sm-4 file_list_upload">
                                                                                                                <tr title={files.FileName}>
                                                                                                                    <td className="cut_textInTNA">
                                                                                                                        <span style={{ marginRight: '7px' }}>
                                                                                                                            {
                                                                                                                                files.FileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                                                    files.FileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                                                                        <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                                                        files.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                                                            files.FileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                                                            <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                                                            }
                                                                                                                        </span>  {files.FileName}
                                                                                                                    </td>
                                                                                                                    <td className="button_style_uploda"><p title='Delete File' onClick={(e) => RemoveFiles(e, fileindex)}
                                                                                                                        className="btn btn-danger btn-xs delete">
                                                                                                                        <i className="fa fa-trash-o"></i>
                                                                                                                    </p>
                                                                                                                        &nbsp;
                                                                                                                        {
                                                                                                                            files.FileType === "application/pdf" || files.FileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files.FileType === 'application/msword' ?
                                                                                                                                <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + 'Images/TNARelatedDocs/' + files.FileName} download><i class="fa fa-download"></i> </a> :
                                                                                                                                <i className="btn btn-info fa fa-eye" title="View Logo"
                                                                                                                                    onClick={
                                                                                                                                        event => ViewFile(event, files.FileName)
                                                                                                                                    }></i>
                                                                                                                        }
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                                {


                                                                                                                    <div> {
                                                                                                                        isOpenimg && (

                                                                                                                            <Lightbox mainSrc={window.$APIBaseURL + 'Images/TNARelatedDocs/' + getImagename}
                                                                                                                                alt="bg image"
                                                                                                                                onCloseRequest={
                                                                                                                                    () => setIsOpenimg(false)
                                                                                                                                } />
                                                                                                                        )
                                                                                                                    } </div>
                                                                                                                }
                                                                                                            </div>

                                                                                                        ))
                                                                                                        : ''
                                                                                                }
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>



                                                                            </Modal.Body>
                                                                            <Modal.Footer>

                                                                                <Button variant="success" onClick={() => handleCloseAttachment(index)}> Save </Button>

                                                                            </Modal.Footer>
                                                                        </Modal>
                                                                    }
                                                                    {

                                                                        inputField.IsManual === 1 &&

                                                                        <Modal show={inputField.IsManual === 1} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseManual(index)}>
                                                                            <Modal.Header closeButton>
                                                                                <Modal.Title>Manual Close</Modal.Title>
                                                                            </Modal.Header>
                                                                            <Modal.Body>
                                                                                <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>Comments</th>

                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>

                                                                                        <tr>
                                                                                            <td colSpan="6">
                                                                                                <textarea
                                                                                                    rows="3"
                                                                                                    placeholder="Enter Comments here..."
                                                                                                    autoFocus onFocus={""} autoComplete="off"
                                                                                                    maxLength="500" name="ManualComments"
                                                                                                    id={"ManualComments"}
                                                                                                    value={inputField.ManualComments}
                                                                                                    onChange={event => handleChangeTNAComments(event, index)}
                                                                                                    style={{ width: "100%", border: getComment && inputField.ManualComments === '' ? '1px solid red' : '' }}>

                                                                                                </textarea>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>


                                                                            </Modal.Body>
                                                                            <Modal.Footer>
                                                                                {
                                                                                    inputField.StatusID !== 3 &&

                                                                                    <Button variant="success" onClick={() => handleManual(index, inputField.ManualComments)}> Save </Button>
                                                                                }

                                                                            </Modal.Footer>
                                                                        </Modal>
                                                                    }



                                                                    {/* <button type="button" className="btn btn-xs btn-primary" title={(getHeaderName === "Edit" ? "Edit" : getHeaderName === "Export" ? "Add" : "View") + " Additional Task"}
                                                                            onClick={() => handleFollowup(index)}>
                                                                            <b> {inputField.AdditionalFollowup} </b>
                                                                            <i className="fa fa-tasks"></i>
                                                                        </button>

                                                                        &nbsp;


                                                                        <button type="button" className="btn btn-xs btn-magenta" title="Comments" onClick={() => handleComment(index, inputField.CommentsCount, props.location.state.params.tnaid, inputField.TaskID)}>
                                                                            <b> {inputField.CommentsCount} </b>
                                                                            <i className="fa fa-comment"></i>
                                                                        </button>
                                                                        &nbsp;

                                                                        <button type="button" className="btn btn-xs btn-blue" title="Attachments" onClick={() => handleAttachment(index)}
                                                                        >
                                                                            <b> {inputField.AttachmentCount} </b>
                                                                            <i className="fa fa-upload"></i>
                                                                        </button>
                                                                        &nbsp;

                                                                        <button type="button" className={inputField.intDependency === 0 ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Depedency)"
                                                                            onClick={() => handleDependent(index, inputField.intDependency)}
                                                                        >


                                                                            <i className={inputField.intDependency === 0 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>

                                                                        </button>

                                                                        {isLastEdit && inputField.IsSingleTask ?

                                                                            <Fragment>&nbsp;<button type="button" className={inputField.ManualComments === '' || inputField.ManualComments === null ? "btn btn-xs btn-darkorange" : "btn btn-xs btn-success"} title="Manual Close"
                                                                                onClick={() => handleManualclose(index)}
                                                                            >

                                                                                <i className="fa fa-user"></i>&nbsp;
                                                                                <i className="fa fa-close"></i>
                                                                            </button>
                                                                            </Fragment>
                                                                            : ''
                                                                        }*/}

                                                                    {/* <td className="fixed-column-Route">
                                                                    </td> */}
                                                                </tr>
                                                                {

                                                                    inputField.SubTaskList && inputField.SubTaskList.map((subTask, subIndex) => (

                                                                        <Fragment key={
                                                                            `${subTask}~${subIndex}`
                                                                        }>
                                                                            <tr class={"parentChild parent_" + index + " hidden"} data-child={"parentChild_" + index + "_" + subIndex} >
                                                                                <td className="fixed-column-first-tna">
                                                                                    <span id={"btn-toggle_parentChild_" + index + "_" + subIndex} class="btn-toggle " aria-expanded={subTask.IsExpanded} onClick={() => handleChildToggle(index, subIndex)} >
                                                                                        <i id={"icon-toggle_parentChild_" + index + "_" + subIndex} class={subTask.IsExpanded ? "fa fa-minus-square-o clsPointer clscollapseexpand-child" : "fa fa-plus-square-o clsPointer clscollapseexpand-child"}></i>
                                                                                    </span>
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        subTask.MainId > 0 ? <span> {subTask.Code} </span> : <span> {inputField.strTaskName} </span>
                                                                                    }

                                                                                </td>
                                                                                <td colSpan="7">
                                                                                    {subTask.MainId > 0 &&
                                                                                        (subTask.ColorOrName === "" || subTask.ColorOrName === null) ?
                                                                                        <div className="trwidth_manual" style={{ textAlign: "center" }}>
                                                                                            <img className="width_manual"
                                                                                                src={subTask.ImagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + subTask.ImageUrl + subTask.ImagePath}
                                                                                                alt="Logo"
                                                                                                onClick={
                                                                                                    () => setIsOpen(subTask.ImagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + subTask.ImageUrl + subTask.ImagePath)
                                                                                                }
                                                                                            />
                                                                                        </div> :
                                                                                        <div style={{ background: subTask.ColorOrName, width: '120px', height: '25px', marginLeft: '20px', borderRadius: '2px', display: 'inline-block' }}> </div>

                                                                                    }


                                                                                </td>

                                                                                <td className="fixed-column-Route"></td>

                                                                            </tr>

                                                                            {
                                                                                subTask.ChildTaskList && subTask.ChildTaskList.map((childTask, childIndex) => (

                                                                                    <Fragment key={
                                                                                        `${childTask}~${childIndex}`
                                                                                    }>
                                                                                        <tr class={subTask.IsExpanded ? "child_" + index + " parentChild_" + index + "_" + subIndex + " " : "child_" + index + " parentChild_" + index + "_" + subIndex + " hidden"} >

                                                                                            <td className="fixed-column-first-tna">{childIndex + 1}</td>
                                                                                            <td colSpan="2">
                                                                                                {
                                                                                                    inputField.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || inputField.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                                                                                                        inputField.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || inputField.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                                                                                                        inputField.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT || inputField.strTaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER ||
                                                                                                        inputField.strTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || inputField.strTaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT ||
                                                                                                        inputField.strTaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || inputField.strTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER ||
                                                                                                        inputField.strTaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER || inputField.strTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                                                                                                        inputField.strTaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || inputField.strTaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL
                                                                                                        ?
                                                                                                        <>

                                                                                                            <i class="fa fa-eye" title="SKU & Other Info" onClick={() => handleSkuAndStyleDetails(childTask.SubId, childTask.MainId, childTask.StrikeOffOrTrimInfoId, inputField.strTaskName, childTask.SKUIdList, childTask.StyleStrikeOffGarmentSizeIdList, childTask.TNAId)}></i>
                                                                                                            <span>{" " + childTask.Name}</span>
                                                                                                        </>

                                                                                                        : childTask.Name
                                                                                                }
                                                                                                {/* {childTask.Name} */}
                                                                                            </td>





                                                                                            <td>

                                                                                                <span className="input-icon icon-right">
                                                                                                    {/* <DatePicker className="form-control"
                                                                                                        name="ChildEnddate"
                                                                                                        id={
                                                                                                            "ChildEnddate" + index + '_' + subIndex + '_' + childIndex
                                                                                                        }

                                                                                                        selected={Date.parse(moment(childTask.EndDate, 'MM/DD/YYYY').toISOString())}
                                                                                                        onChange={
                                                                                                            event => handleChangeChild(event, index, subIndex, childIndex, "EndDate")
                                                                                                        }
                                                                                                        dateFormat="dd/MM/yyyy"
                                                                                                        peekNextMonth
                                                                                                        showMonthDropdown
                                                                                                        showYearDropdown
                                                                                                        dropdownMode="scroll"
                                                                                                        autoComplete="off"
                                                                                                        menuPosition="fixed"
                                                                                                        readOnly={true}
                                                                                                    //readOnly={Buttonvisible === 'View' ? true : false}
                                                                                                    // customInput={<CustomInput />}
                                                                                                    /> */}{
                                                                                                        childTask.StrEndDate
                                                                                                    }

                                                                                                </span>
                                                                                            </td>


                                                                                            <td>

                                                                                                <span className="input-icon icon-right">
                                                                                                    {/* <DatePicker className="form-control"
                                                                                                        name="ChildActualEndDate"
                                                                                                        id={
                                                                                                            "ChildActualEndDate" + index + '_' + subIndex + '_' + childIndex
                                                                                                        }
                                                                                                        selected={Date.parse(moment(childTask.ChildActualEndDate, 'MM/DD/YYYY').toISOString())}
                                                                                                        dateFormat="dd/MM/yyyy"
                                                                                                        readOnly={true}

                                                                                                    /> */}
                                                                                                    {
                                                                                                        childTask.StrActualEndDate === "Manual" ?
                                                                                                            <p><a style={{ cursor: "pointer" }} onClick={event => showManualApproval(index, subIndex, childIndex,
                                                                                                                childTask.SubId, childTask.TaskName)}>{childTask.StrActualEndDate}</a></p> :
                                                                                                            childTask.StrActualEndDate === "Existing" ?
                                                                                                                <p><a style={{ cursor: "pointer" }} onClick={event => showExistingApproval(index, subIndex, childIndex,
                                                                                                                    childTask.SubId, childTask.TaskName)}>{childTask.StrActualEndDate}</a></p> :
                                                                                                                childTask.StrActualEndDate
                                                                                                    }

                                                                                                    {
                                                                                                        childTask.ManualApprovalType === 1 && (
                                                                                                            <>
                                                                                                                <Modal show={childTask.ManualApprovalType} size="lg" dragable backdrop="static" keyboard={false} onHide={() => hideManualApproval(index, subIndex, childIndex)}>
                                                                                                                    <Modal.Header closeButton>
                                                                                                                        <Modal.Title>
                                                                                                                            Manual Approval Information
                                                                                                                        </Modal.Title>
                                                                                                                    </Modal.Header>
                                                                                                                    <Modal.Body>

                                                                                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">

                                                                                                                            <thead>
                                                                                                                                <tr>
                                                                                                                                    <th>Comments</th>
                                                                                                                                </tr>
                                                                                                                            </thead>
                                                                                                                            <tbody>

                                                                                                                                <tr>
                                                                                                                                    <td >
                                                                                                                                        <textarea
                                                                                                                                            rows="3"
                                                                                                                                            placeholder="Enter Comments here..."
                                                                                                                                            autoFocus onFocus={""} autoComplete="off"
                                                                                                                                            maxLength="500" name="EnterRemarks"
                                                                                                                                            id={"EnterRemarks"}
                                                                                                                                            value={manualApprovalData.manualCloseComment}
                                                                                                                                            style={{ width: "100%" }}
                                                                                                                                        >
                                                                                                                                        </textarea>
                                                                                                                                    </td>

                                                                                                                                </tr>

                                                                                                                                <tr>
                                                                                                                                    <td colSpan={2}>
                                                                                                                                        <table className="table table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                                                                            <tbody>  <tr>

                                                                                                                                                <div className="col-lg-12 col-sm-12">
                                                                                                                                                    <div className="row no-margin">
                                                                                                                                                        {
                                                                                                                                                            manualApprovalData.files && manualApprovalData.files.length !== 0 ?
                                                                                                                                                                manualApprovalData.files.map((files, fileindex) => (
                                                                                                                                                                    <> {
                                                                                                                                                                        <div className="col-lg-4 col-sm-4 file_list_upload">
                                                                                                                                                                            <tr title={files.fileName}>
                                                                                                                                                                                <td className="cut_textInTNA">
                                                                                                                                                                                    <span style={{ marginRight: '7px' }}>
                                                                                                                                                                                        {
                                                                                                                                                                                            files.fileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                                                                                                                files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                                                                                                                                    <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                                                                                                                    files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                                                                                                                        files.fileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                                                                                                                        <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                                                                                                                        }
                                                                                                                                                                                    </span>  {files.fileName}
                                                                                                                                                                                </td>

                                                                                                                                                                                <td className="button_style_uploda">

                                                                                                                                                                                    &nbsp;
                                                                                                                                                                                    {
                                                                                                                                                                                        files.FileType === "application/pdf" || files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files.fileType === 'application/msword' ?
                                                                                                                                                                                            <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + 'Images/TNAImages/' + files.fileName} download><i class="fa fa-download"></i> </a> :
                                                                                                                                                                                            <i className="btn btn-info fa fa-eye" title="View Logo"
                                                                                                                                                                                                onClick={
                                                                                                                                                                                                    event => ViewFile(event, files.fileName)
                                                                                                                                                                                                }></i>
                                                                                                                                                                                    }
                                                                                                                                                                                </td>
                                                                                                                                                                            </tr>



                                                                                                                                                                            {

                                                                                                                                                                                <div> {
                                                                                                                                                                                    isOpenimg && (

                                                                                                                                                                                        <Lightbox mainSrc={window.$APIBaseURL + 'Images/TNAImages/' + getImagename}
                                                                                                                                                                                            alt="bg image"
                                                                                                                                                                                            onCloseRequest={
                                                                                                                                                                                                () => setIsOpenimg(false)
                                                                                                                                                                                            } />
                                                                                                                                                                                    )
                                                                                                                                                                                } </div>
                                                                                                                                                                            }
                                                                                                                                                                        </div>
                                                                                                                                                                    }
                                                                                                                                                                    </>
                                                                                                                                                                ))
                                                                                                                                                                : 'No Files Found'
                                                                                                                                                        }
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            </tr>
                                                                                                                                            </tbody>


                                                                                                                                        </table>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </tbody>
                                                                                                                        </table>
                                                                                                                    </Modal.Body>

                                                                                                                </Modal>
                                                                                                            </>
                                                                                                        )
                                                                                                    }

                                                                                                    {
                                                                                                        childTask.ExistingApprovalType === 1 ?
                                                                                                            <Modal show={childTask.ExistingApprovalType === 1} size="lg" dragable backdrop="static" keyboard={false} onHide={() => hideExistingApproval(index, subIndex, childIndex)}>
                                                                                                                <Modal.Header closeButton>
                                                                                                                    <Modal.Title>
                                                                                                                        Existing Approval Information
                                                                                                                    </Modal.Title>
                                                                                                                </Modal.Header>
                                                                                                                <Modal.Body>

                                                                                                                    <table className="table table-bordered">
                                                                                                                        <thead>
                                                                                                                            <tr>
                                                                                                                                <th>Season Name</th>
                                                                                                                                <th>Approval Date</th>
                                                                                                                                <th>Details</th>
                                                                                                                                <th>Approved By</th>


                                                                                                                            </tr>
                                                                                                                        </thead>
                                                                                                                        <tbody>
                                                                                                                            {
                                                                                                                                existingApprovalData && existingApprovalData.length !== 0 ?
                                                                                                                                    <>

                                                                                                                                        <tr>
                                                                                                                                            <td>{existingApprovalData[0].seasonName}</td>
                                                                                                                                            <td>{existingApprovalData[0].approvalDate}</td>
                                                                                                                                            <td>{existingApprovalData[0].details}</td>
                                                                                                                                            <td>{existingApprovalData[0].approvedBy}</td>

                                                                                                                                        </tr>
                                                                                                                                    </> :
                                                                                                                                    <tr>
                                                                                                                                        <td colSpan={4}>
                                                                                                                                            <div align="center"> <span>No Records Found</span></div>
                                                                                                                                        </td>
                                                                                                                                    </tr>
                                                                                                                            }

                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </Modal.Body>

                                                                                                            </Modal> : ''
                                                                                                    }

                                                                                                </span>
                                                                                            </td>

                                                                                            <td>
                                                                                                {/* {index === 0 ? '' : <input type="text"
                                                                                                    value={inputField.SelectedDependency}
                                                                                                    title={inputField.SelectedDependency}
                                                                                                    //onClick={() => handleDependency(index,subIndex, childIndex, false)}
                                                                                                    className={'form-control'}
                                                                                                    readOnly={true}
                                                                                                    style={{ width: "100%", float: "left" }}
                                                                                                />
                                                                                                } */}
                                                                                                {
                                                                                                    childTask.SelectedDependency
                                                                                                }
                                                                                            </td>
                                                                                            <td>
                                                                                                {/* <input type="text"
                                                                                                    value={inputField.TaskHolderOwnerTxtList}
                                                                                                    title={inputField.TaskHolderOwnerTxtList}
                                                                                                    //onClick={() => handleTaskHolderLeaveDaysChild(index, subIndex, childIndex, childTask.IsTHFirstTime)}
                                                                                                    className={'form-control'}
                                                                                                    readOnly={true}
                                                                                                    //style={{ width: "100%", float: "left" }}
                                                                                                    style={{ width: "100%", float: "left", border: submitted && !childTask.TaskHolderOwnerTxtList ? '1px solid red' : '' }}
                                                                                                /> */}

                                                                                                {
                                                                                                    <span>
                                                                                                        {
                                                                                                            getTaskHolderList.filter(d => d.value === inputField.TaskHolderOrFollower).map(task => {
                                                                                                                return task.label;
                                                                                                            })
                                                                                                        }
                                                                                                    </span>
                                                                                                }
                                                                                            </td>
                                                                                            <td>
                                                                                                {/* <input type="text"
                                                                                                    value={inputField.LeaveDaysTxt}
                                                                                                    title={inputField.LeaveDaysTxt}
                                                                                                    //onClick={() => handleTaskHolderLeaveDaysChild(index, subIndex, childIndex, childTask.IsTHFirstTime)}
                                                                                                    className={'form-control'}
                                                                                                    readOnly={true}
                                                                                                    style={{ width: "100%", float: "left" }}
                                                                                                /> */}
                                                                                                {
                                                                                                    childTask.LeaveDaysTxt
                                                                                                }

                                                                                            </td>
                                                                                            <td>
                                                                                                <span className='input-icon icon-right'>
                                                                                                    <Reactselect className="basic-single" name="StatusID"
                                                                                                        // id={
                                                                                                        //     "Status" + index
                                                                                                        // }
                                                                                                        isLoading={false}
                                                                                                        isClearable={true}
                                                                                                        isSearchable={true}
                                                                                                        onChange={event => handleChangeChild(event, index, subIndex, childIndex, "StatusID")}
                                                                                                        value={getTNAStatusList.filter(function (option) {
                                                                                                            return option.value === childTask.StatusID;
                                                                                                        })}
                                                                                                        options={getTNAStatusList}
                                                                                                        isDisabled={true}
                                                                                                        menuPosition="fixed"
                                                                                                    ></Reactselect>
                                                                                                </span>
                                                                                            </td>
                                                                                            <td>
                                                                                                <span className='input-icon icon-right'>
                                                                                                    <input type="text"
                                                                                                        placeholder="AWB & Status"
                                                                                                        // id={"AWBStatus" + index}
                                                                                                        // name="AWBStatus"
                                                                                                        value={childTask.AWBStatus}
                                                                                                        //onChange={event => handleChangeChild(event, index, subIndex, childIndex, "AWBStatus")}
                                                                                                        className={'form-control' + (
                                                                                                            submitted && !childTask.AWBStatus ? ' is-invalid' : ''
                                                                                                        )}
                                                                                                        readOnly={true}
                                                                                                    />
                                                                                                </span>
                                                                                            </td>
                                                                                            {/* <td className="fixed-column-Route">
                                                                                            </td> */}

                                                                                            {/* <Modal show={childTask.IsTHLeaveDaysOpen === 1} size="lg" backdrop="static" keyboard={false} onHide={() => handleTHLeaveDaysChildClose(index, subIndex, childIndex,)}>
                                                                                                    <Modal.Header closeButton>
                                                                                                        <Modal.Title>
                                                                                                            Leave Days
                                                                                                        </Modal.Title>
                                                                                                    </Modal.Header>
                                                                                                    <Modal.Body>
                                                                                                        <div className="row">
                                                                                                            <div className="col-md-12">
                                                                                                                <div className='form-group'>
                                                                                                                    <label> Task Holder / Follower </label>
                                                                                                                    <span className='input-icon icon-right'>
                                                                                                                        <Reactselect className="basic-single"
                                                                                                                            //name="TaskHolderOrFollower"
                                                                                                                            // id={"TaskHolderOrFollower"}
                                                                                                                            isLoading={false}
                                                                                                                            isClearable={true}
                                                                                                                            isSearchable={true}
                                                                                                                            onChange={event => handleChangeChild(event, index, subIndex, childIndex, "TaskHolderOrFollower")}
                                                                                                                            value={getTaskHolderList.filter(function (option) {
                                                                                                                                return option.value === childTask.TaskHolderOrFollower;
                                                                                                                            })}
                                                                                                                            options={getTaskHolderList}
                                                                                                                            styles={submitted && childTask.TaskHolderOrFollower === 0 ? styles : ''}
                                                                                                                            menuPosition="fixed"
                                                                                                                            filterOption={filterOption}
                                                                                                                        ></Reactselect>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="row">
                                                                                                            <div className="col-md-12">
                                                                                                                <div className='form-group'>
                                                                                                                    <label>Leave Days</label>
                                                                                                                    <span className='input-icon icon-right'>
                                                                                                                        <Reactselect className="basic-single"
                                                                                                                            //                     name="LeaveDays"
                                                                                                                            // id={
                                                                                                                            //     "LeaveDays"
                                                                                                                            // }
                                                                                                                            isLoading={false}
                                                                                                                            isClearable={true}
                                                                                                                            isSearchable={true}
                                                                                                                            onChange={event => handleChangeChild(event, index, subIndex, childIndex, "LeaveDays")}
                                                                                                                            value={getLeaveDaysList.filter(function (option) {
                                                                                                                                return option.value === childTask.LeaveDays;
                                                                                                                            })}
                                                                                                                            options={getLeaveDaysList}
                                                                                                                            styles={submitted && childTask.LeaveDays === 0 ? styles : ''}
                                                                                                                            menuPosition="fixed"

                                                                                                                        ></Reactselect>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        {childTask.titleEmployee1 && <div className="row">
                                                                                                            <div className="col-md-12">
                                                                                                                <div className='form-group'>
                                                                                                                    <label htmlFor={childTask.titleEmployee1 === "Buyer" ? 'TaskHolderBuyer' : childTask.titleEmployee1 === "Cube" ? "FollowerCube" : "FollowerSupplier"} >Task Holder - {childTask.titleEmployee1} </label>
                                                                                                                    <span className='input-icon icon-right'>
                                                                                                                        <Reactselect className="basic-single" name={childTask.titleEmployee1 === "Buyer" ? 'TaskHolderBuyer' : childTask.titleEmployee1 === "Cube" ? "FollowerCube" : "FollowerSupplier"}
                                                                                                                            isDisabled={false}
                                                                                                                            isLoading={false}
                                                                                                                            isClearable={true}
                                                                                                                            isSearchable={true}
                                                                                                                            isMulti
                                                                                                                            onChange={event => handleChangeChild(event, index, subIndex, childIndex, childTask.titleEmployee1 === "Buyer" ? 'TaskHolderBuyer' : childTask.titleEmployee1 === "Cube" ? "FollowerCube" : "FollowerSupplier")}
                                                                                                                            options={childTask.titleEmployee1 === "Buyer" ? drpTNATaskOwnerList.buyerEmployeeList : childTask.titleEmployee1 === "Cube" ? drpTNATaskOwnerList.cubeEmployeeList : drpTNATaskOwnerList.supplierEmployeeList}
                                                                                                                            value={childTask.titleEmployee1 === "Buyer" ? drpTNATaskOwnerList.buyerEmployeeList && childTask.arrTaskHolderBuyer : childTask.titleEmployee1 === "Cube" ? drpTNATaskOwnerList.cubeEmployeeList && childTask.arrFollowerCube : drpTNATaskOwnerList.supplierEmployeeList && childTask.arrFollowerSupplier}
                                                                                                                        >  </Reactselect>

                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        }

                                                                                                        {
                                                                                                            childTask.titleEmployee2 && <div className="row">
                                                                                                                <div className="col-md-12">
                                                                                                                    <div className='form-group'>
                                                                                                                        <label htmlFor={childTask.titleEmployee2 === "Buyer" ? 'TaskHolderBuyer' : childTask.titleEmployee2 === "Cube" ? "FollowerCube" : "FollowerSupplier"} >Follower - {childTask.titleEmployee2}</label>
                                                                                                                        <span className='input-icon icon-right'>
                                                                                                                            <Reactselect className="basic-single" name={childTask.titleEmployee2 === "Buyer" ? 'TaskHolderBuyer' : childTask.titleEmployee2 === "Cube" ? "FollowerCube" : "FollowerSupplier"}
                                                                                                                                isDisabled={false}
                                                                                                                                isLoading={false}
                                                                                                                                isClearable={true}
                                                                                                                                isSearchable={true}
                                                                                                                                isMulti
                                                                                                                                onChange={event => handleChangeChild(event, index, subIndex, childIndex, childTask.titleEmployee2 === "Buyer" ? 'TaskHolderBuyer' : childTask.titleEmployee2 === "Cube" ? "FollowerCube" : "FollowerSupplier")}
                                                                                                                                options={childTask.titleEmployee2 === "Buyer" ? drpTNATaskOwnerList.buyerEmployeeList : childTask.titleEmployee2 === "Cube" ? drpTNATaskOwnerList.cubeEmployeeList : drpTNATaskOwnerList.supplierEmployeeList}
                                                                                                                                value={childTask.titleEmployee2 === "Buyer" ? childTask.arrTaskHolderBuyer : childTask.titleEmployee2 === "Cube" ? childTask.arrFollowerCube : childTask.arrFollowerSupplier}
                                                                                                                            // options={IMPORTANCE}
                                                                                                                            // value={getStyleList.arrImportance}
                                                                                                                            //  styles={submitted && getStyleList.arrImportance === 0 ? styles : ''}
                                                                                                                            >  </Reactselect>

                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        }
                                                                                                        {childTask.titleEmployee3 && <div className="row">
                                                                                                            <div className="col-md-12">
                                                                                                                <div className='form-group'>
                                                                                                                    <label htmlFor="FollowerSupplier" >Follower - {childTask.titleEmployee3}</label>
                                                                                                                    <span className='input-icon icon-right'>
                                                                                                                        <Reactselect className="basic-single" name={childTask.titleEmployee3 === "Buyer" ? 'TaskHolderBuyer' : childTask.titleEmployee3 === "Cube" ? "FollowerCube" : "FollowerSupplier"}
                                                                                                                            isDisabled={false}
                                                                                                                            isLoading={false}
                                                                                                                            isClearable={true}
                                                                                                                            isSearchable={true}
                                                                                                                            isMulti
                                                                                                                            onChange={event => handleChangeChild(event, index, subIndex, childIndex, childTask.titleEmployee3 === "Buyer" ? 'TaskHolderBuyer' : childTask.titleEmployee3 === "Cube" ? "FollowerCube" : "FollowerSupplier")}
                                                                                                                            options={childTask.titleEmployee3 === "Buyer" ? drpTNATaskOwnerList.buyerEmployeeList : childTask.titleEmployee3 === "Cube" ? drpTNATaskOwnerList.cubeEmployeeList : drpTNATaskOwnerList.supplierEmployeeList}
                                                                                                                            value={childTask.titleEmployee3 === "Buyer" ? childTask.arrTaskHolderBuyer : childTask.titleEmployee3 === "Cube" ? childTask.arrFollowerCube : childTask.arrFollowerSupplier}

                                                                                                                        >  </Reactselect>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        }

                                                                                                    </Modal.Body>
                                                                                                    <Modal.Footer>
                                                                                                        <Button variant="success" onClick={() => handleTHLeaveDaysChildClose(index, subIndex, childIndex)}>
                                                                                                            Save
                                                                                                        </Button>

                                                                                                    </Modal.Footer>
                                                                                                </Modal> */}
                                                                                            {childTask.IsAddChildTaskOpen === 1 &&
                                                                                                <Modal show={childTask.IsAddChildTaskOpen === 1} size="lg" backdrop="static" keyboard={false} onHide={() => handleAddChildTaskClose(index, subIndex, childIndex,)}>
                                                                                                    <Modal.Header closeButton>
                                                                                                        <Modal.Title>
                                                                                                            Add Task
                                                                                                        </Modal.Title>
                                                                                                    </Modal.Header>
                                                                                                    <Modal.Body>
                                                                                                        <div className="row">
                                                                                                            <div className="col-md-12">
                                                                                                                <div className='form-group'>
                                                                                                                    <label> Task </label>
                                                                                                                    <span className='input-icon icon-right'>
                                                                                                                        <Reactselect className="basic-single"
                                                                                                                            isLoading={false}
                                                                                                                            isClearable={true}
                                                                                                                            isSearchable={true}
                                                                                                                            onChange={event => handleChangeChildTask(event)}
                                                                                                                            // value={childTask.filter(function (option) {
                                                                                                                            //     return option.value === childTask.subId;
                                                                                                                            // })}
                                                                                                                            options={childTask.drpChildTaskList}
                                                                                                                            // styles={childTask.TaskHolderOrFollower === 0 ? styles : ''}
                                                                                                                            menuPosition="fixed"
                                                                                                                            filterOption={filterOption}
                                                                                                                        ></Reactselect>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>





                                                                                                    </Modal.Body>
                                                                                                    <Modal.Footer>
                                                                                                        <Button variant="success" onClick={() => handleAddRowChildTask(index, subIndex, childIndex)}>
                                                                                                            Add
                                                                                                        </Button>

                                                                                                    </Modal.Footer>
                                                                                                </Modal>
                                                                                            }

                                                                                            {/* <button type="button" className="btn btn-xs btn-magenta" title="Comments" onClick={() => handleComment(index)}>
                                                                                                    <i className="fa fa-comment"></i>
                                                                                                </button>

                                                                                                &nbsp;  <button type="button" className="btn btn-xs btn-blue" title="Attachments" onClick={() => handleAttachment(index)}
                                                                                                >
                                                                                                    <i className="fa fa-upload"></i>
                                                                                                </button>
                                                                                                 &nbsp;  <button type="button" className="btn btn-xs btn-success" title="Depedency Start and Stop"
                                                                                                    onClick={() => handleDependent(index, "Start")}
                                                                                                >
                                                                                                    <i className="fa fa-thumbs-o-up"></i>

                                                                                                </button> */}

                                                                                            &nbsp;
                                                                                            {
                                                                                                isLastEdit === true ?
                                                                                                    <>



                                                                                                        {
                                                                                                            childTask.IsManualClose === 1 ?
                                                                                                                <Modal show={childTask.IsManualClose === 1} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseManualPopup(index, subIndex, childIndex)}>
                                                                                                                    <Modal.Header closeButton>
                                                                                                                        <Modal.Title>
                                                                                                                            Manual Close
                                                                                                                        </Modal.Title>
                                                                                                                    </Modal.Header>
                                                                                                                    <Modal.Body>
                                                                                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                                                            <thead>
                                                                                                                                <tr>
                                                                                                                                    <th>Comments</th>

                                                                                                                                </tr>
                                                                                                                            </thead>
                                                                                                                            <tbody>

                                                                                                                                <tr>
                                                                                                                                    <td colSpan="6">
                                                                                                                                        <textarea
                                                                                                                                            rows="3"
                                                                                                                                            placeholder="Enter Comments here..."
                                                                                                                                            autoFocus onFocus={""} autoComplete="off"
                                                                                                                                            maxLength="500" name="EnterRemarks"
                                                                                                                                            id={"EnterRemarks"}
                                                                                                                                            value={childTask.ManualCloseComment}
                                                                                                                                            onChange={event => handleChangeIsManualCloseComments(event, index, subIndex, childIndex)}
                                                                                                                                            style={{ width: "100%", border: getComment && childTask.ManualCloseComment === '' ? '1px solid red' : '' }}>

                                                                                                                                        </textarea>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </tbody>
                                                                                                                        </table>
                                                                                                                    </Modal.Body>
                                                                                                                    <Modal.Footer>
                                                                                                                        {
                                                                                                                            childTask.StatusID !== 3 &&
                                                                                                                            <Button variant="success" onClick={() => SaveChildManulaCloseComments(index, subIndex, childIndex, childTask.ManualCloseComment)}>
                                                                                                                                Save
                                                                                                                            </Button>


                                                                                                                        }


                                                                                                                    </Modal.Footer>
                                                                                                                </Modal> : ''
                                                                                                        }

                                                                                                        {/* {(childTask.IsShowSubmitButton === 1 || childTask.IsShowSubmitButton === 2) && childTask.StatusID !== 3 ?
                                                                                                                <Fragment>&nbsp;<button type="button" className={childTask.ManualCloseComment === '' || childTask.ManualCloseComment === null || childTask.ManualCloseComment === undefined ? "btn btn-xs btn-success" : "btn btn-xs btn-darkorange"} title="Manual Close"
                                                                                                                    onClick={() => handleChildManualclose(index, subIndex, childIndex)}
                                                                                                                >
                                                                                                                    <i className="fa fa-user"></i>&nbsp;
                                                                                                                    <i className="fa fa-close"></i>
                                                                                                                </button>
                                                                                                                </Fragment>
                                                                                                                : (childTask.ManualCloseComment !== '' && childTask.ManualCloseComment !== null && childTask.ManualCloseComment !== undefined) && childTask.StatusID === 3 ?
                                                                                                                    <button type="button" className={childTask.ManualCloseComment === '' || childTask.ManualCloseComment === null || childTask.ManualCloseComment === undefined ? "btn btn-xs btn-success" : "btn btn-xs btn-darkorange"} title="Manual Close"
                                                                                                                        onClick={() => handleChildManualclose(index, subIndex, childIndex)}
                                                                                                                    >
                                                                                                                        <i className="fa fa-user"></i>&nbsp;
                                                                                                                        <i className="fa fa-close"></i>
                                                                                                                    </button>
                                                                                                                    : ''
                                                                                                            }


                                                                                                            &nbsp; */}
                                                                                                        {/* {

                                                                                                                childTask.TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION && childTask.IsShowSubmitButton === 2 && childTask.StatusID !== 3 ?
                                                                                                                    <button type="button" className="btn btn-xs btn-warning" title="Submit Form"
                                                                                                                        onClick={() => OpenLabdipSubmssionpopup(props.location.state.params.tnaid, childTask.ChildId,
                                                                                                                            props.location.state.params.styleId, index, subIndex, childIndex
                                                                                                                        )}
                                                                                                                    >
                                                                                                                        <i className="fa fa-send"></i>
                                                                                                                    </button> :
                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION && childTask.StatusID === 3 && childTask.IsShowSubmitButton !== 0 ?
                                                                                                                        <button type="button" className="btn btn-xs btn-success"
                                                                                                                            disabled
                                                                                                                        >
                                                                                                                            <i className="fa fa-send"></i>
                                                                                                                        </button> :
                                                                                                                        childTask.TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION && childTask.IsShowSubmitButton === 0 ?
                                                                                                                            <button type="button" className="btn btn-xs btn-warning" title='Already Updated' disabled>
                                                                                                                                <i className="fa fa-check-square-o"></i>
                                                                                                                            </button>
                                                                                                                            : ''

                                                                                                            }
                                                                                                            {childTask.TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL && childTask.IsShowSubmitButton === 2 && childTask.StatusID !== 3 ?

                                                                                                                <button type="button" className="btn btn-xs btn-warning" title="Approve Form"
                                                                                                                    onClick={() => OpenLabdipApprovalpopup(childTask.ChildId, props.location.state.params.styleId
                                                                                                                        , index, subIndex, childIndex)}
                                                                                                                >
                                                                                                                    <i className="fa fa-check"></i>
                                                                                                                </button> :
                                                                                                                childTask.TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL && childTask.StatusID === 3 && childTask.IsShowSubmitButton !== 0 ?
                                                                                                                    <button type="button" className="btn btn-xs btn-success"
                                                                                                                        disabled
                                                                                                                    >
                                                                                                                        <i className="fa fa-check"></i>
                                                                                                                    </button> :
                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL && childTask.IsShowSubmitButton === 0 ?
                                                                                                                        <button type="button" className="btn btn-xs btn-warning" title='Already Updated' disabled>
                                                                                                                            <i className="fa fa-check-square-o"></i>
                                                                                                                        </button>
                                                                                                                        : ''

                                                                                                            }
                                                                                                            {childTask.TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT && childTask.IsShowSubmitButton === 2 && childTask.StatusID !== 3 ?
                                                                                                                <button type="button" className="btn btn-xs btn-warning" title="Submit Form"
                                                                                                                    onClick={() => OpenTrimSubmssionpopup(props.location.state.params.tnaid, childTask.ChildId
                                                                                                                        , index, subIndex, childIndex)}
                                                                                                                >
                                                                                                                    <i className="fa fa-send"></i>
                                                                                                                </button> :
                                                                                                                childTask.TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT && childTask.StatusID === 3 && childTask.IsShowSubmitButton !== 0 ?
                                                                                                                    <button type="button" className="btn btn-xs btn-success"
                                                                                                                        disabled

                                                                                                                    >
                                                                                                                        <i className="fa fa-send"></i>
                                                                                                                    </button>
                                                                                                                    :
                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT && childTask.IsShowSubmitButton === 0 ?
                                                                                                                        <button type="button" className="btn btn-xs btn-warning" title='Already Updated' disabled>
                                                                                                                            <i className="fa fa-check-square-o"></i>
                                                                                                                        </button>
                                                                                                                        : ''

                                                                                                            }
                                                                                                            {childTask.TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT && childTask.IsShowSubmitButton === 2 && childTask.StatusID !== 3 ?
                                                                                                                <button type="button" className="btn btn-xs btn-warning" title="Approve Form"
                                                                                                                    onClick={() => OpenTrimApprovalpopup(childTask.ChildId
                                                                                                                        , index, subIndex, childIndex)}
                                                                                                                >
                                                                                                                    <i className="fa fa-check"></i>
                                                                                                                </button> :
                                                                                                                childTask.TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT && childTask.StatusID === 3 && childTask.IsShowSubmitButton !== 0 ?
                                                                                                                    <button type="button" className="btn btn-xs btn-success"
                                                                                                                        disabled
                                                                                                                    >
                                                                                                                        <i className="fa fa-check"></i>
                                                                                                                    </button> :
                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT && childTask.IsShowSubmitButton === 0 ?
                                                                                                                        <button type="button" className="btn btn-xs btn-warning" title='Already Updated' disabled>
                                                                                                                            <i className="fa fa-check-square-o"></i>
                                                                                                                        </button>
                                                                                                                        : ''

                                                                                                            }
                                                                                                            {childTask.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT && childTask.IsShowSubmitButton === 2 && childTask.StatusID !== 3 ?
                                                                                                                <button type="button" className="btn btn-xs btn-warning" title="Submit Form"
                                                                                                                    onClick={() => OpenStrikeoffSubmssionpopup(props.location.state.params.tnaid, childTask.ChildId
                                                                                                                        , index, subIndex, childIndex)}
                                                                                                                >
                                                                                                                    <i className="fa fa-send"></i>
                                                                                                                </button> :
                                                                                                                childTask.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT && childTask.StatusID === 3 && childTask.IsShowSubmitButton !== 0 ?
                                                                                                                    <button type="button" className="btn btn-xs btn-success"
                                                                                                                        disabled

                                                                                                                    >
                                                                                                                        <i className="fa fa-send"></i>
                                                                                                                    </button> :
                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT && childTask.IsShowSubmitButton === 0 ?
                                                                                                                        <button type="button" className="btn btn-xs btn-warning" title='Already Updated' disabled>
                                                                                                                            <i className="fa fa-check-square-o"></i>
                                                                                                                        </button>
                                                                                                                        : ''

                                                                                                            }
                                                                                                            {childTask.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT && childTask.IsShowSubmitButton === 2 && childTask.StatusID !== 3 ?
                                                                                                                <button type="button" className="btn btn-xs btn-warning" title="Approve Form"
                                                                                                                    onClick={() => OpenStrikeoffApprovalpopup(childTask.ChildId
                                                                                                                        , index, subIndex, childIndex)}
                                                                                                                >
                                                                                                                    <i className="fa fa-check"></i>
                                                                                                                </button> :
                                                                                                                childTask.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT && childTask.StatusID === 3 && childTask.IsShowSubmitButton !== 0 ?
                                                                                                                    <button type="button" className="btn btn-xs btn-success"
                                                                                                                        disabled
                                                                                                                    >
                                                                                                                        <i className="fa fa-check"></i>
                                                                                                                    </button> :
                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT && childTask.IsShowSubmitButton === 0 ?
                                                                                                                        <button type="button" className="btn btn-xs btn-warning" title='Already Updated' disabled>
                                                                                                                            <i className="fa fa-check-square-o"></i>
                                                                                                                        </button>
                                                                                                                        : ''

                                                                                                            }


                                                                                                            {(childTask.TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || childTask.TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || childTask.TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || childTask.TaskName.toLowerCase() === StaticTask.SMS_APPROVAL) && childTask.StatusID !== 3 ?
                                                                                                                <button type="button" className="btn btn-xs btn-warning" title="Submit Form"
                                                                                                                    onClick={() => OpenProtoFitSamplepopup(props.location.state.params.tnaid, childTask.ChildId, index, subIndex, childIndex, inputField.strTaskName)}
                                                                                                                >
                                                                                                                    <i className="fa fa-send"></i>
                                                                                                                </button>
                                                                                                                :
                                                                                                                (childTask.TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || childTask.TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || childTask.TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || childTask.TaskName.toLowerCase() === StaticTask.SMS_APPROVAL) && childTask.StatusID === 3 && childTask.IsShowSubmitButton !== 0 ?
                                                                                                                    <button type="button" className="btn btn-xs btn-success"
                                                                                                                        disabled
                                                                                                                    >
                                                                                                                        <i className="fa fa-send"></i>
                                                                                                                    </button>

                                                                                                                    :
                                                                                                                    (childTask.TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || childTask.TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || childTask.TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || childTask.TaskName.toLowerCase() === StaticTask.SMS_APPROVAL) && childTask.IsShowSubmitButton === 0 ?
                                                                                                                        <button type="button" className="btn btn-xs btn-warning" title='Already Updated' disabled>
                                                                                                                            <i className="fa fa-check-square-o"></i>
                                                                                                                        </button>
                                                                                                                        : ''

                                                                                                            }



                                                                                                            {(childTask.TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL || childTask.TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL || childTask.TaskName.toLowerCase() === StaticTask.SMS_APPROVAL) && childTask.IsShowSubmitButton === 2 && childTask.StatusID !== 3 ?
                                                                                                                <button type="button" className="btn btn-xs btn-warning" title="Approval Form"
                                                                                                                    onClick={() => OpenProtoFitApprovalpopup(childTask.ChildId, index, subIndex, childIndex, inputField.strTaskName, childTask.Name)}
                                                                                                                >
                                                                                                                    <i className="fa fa-check"></i>
                                                                                                                </button>
                                                                                                                :
                                                                                                                (childTask.TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL || childTask.TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL || childTask.TaskName.toLowerCase() === StaticTask.SMS_APPROVAL) && childTask.StatusID === 3 && childTask.IsShowSubmitButton !== 0 ?
                                                                                                                    <button type="button" className="btn btn-xs btn-success"
                                                                                                                        disabled
                                                                                                                    >
                                                                                                                        <i className="fa fa-check"></i>
                                                                                                                    </button>

                                                                                                                    :
                                                                                                                    (childTask.TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL || childTask.TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL || childTask.TaskName.toLowerCase() === StaticTask.SMS_APPROVAL) && childTask.IsShowSubmitButton === 0 ?
                                                                                                                        <button type="button" className="btn btn-xs btn-warning" title='Already Updated' disabled>
                                                                                                                            <i className="fa fa-check-square-o"></i>
                                                                                                                        </button>
                                                                                                                        : ''

                                                                                                            }
                                                                                                            {childTask.TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH && childTask.IsShowSubmitButton === 2 && childTask.StatusID !== 3 ?
                                                                                                                <button type="button" className="btn btn-xs btn-warning" title="Submit Form"
                                                                                                                    onClick={() => OpenAppearenceAfterWashpopup(props.location.state.params.tnaid, childTask.ChildId, props.location.state.params.styleId,
                                                                                                                        index, subIndex, childIndex)}
                                                                                                                >
                                                                                                                    <i className="fa fa-send"></i>
                                                                                                                </button>
                                                                                                                :
                                                                                                                childTask.TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH && childTask.StatusID === 3 && childTask.IsShowSubmitButton !== 0 ?
                                                                                                                    <button type="button" className="btn btn-xs btn-success"
                                                                                                                        disabled
                                                                                                                    >
                                                                                                                        <i className="fa fa-send"></i>
                                                                                                                    </button>

                                                                                                                    :
                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH && childTask.IsShowSubmitButton === 0 ?
                                                                                                                        <button type="button" className="btn btn-xs btn-warning" title='Already Updated' disabled>
                                                                                                                            <i className="fa fa-check-square-o"></i>
                                                                                                                        </button>
                                                                                                                        : ''

                                                                                                            } */}
                                                                                                    </>

                                                                                                    : ''
                                                                                            }

                                                                                        </tr>
                                                                                    </Fragment>
                                                                                ))
                                                                            }
                                                                        </Fragment>

                                                                    ))

                                                                }

                                                            </Fragment>
                                                        ))
                                                    }
                                                    {
                                                        inputFields.length === 0 ?
                                                            <tr>
                                                                <td colSpan="10" className='norecordfound'><span>No Records Found</span></td>
                                                                {/* {
                                                                    inputFields.length === 0 ?
                                                                        <tr>
                                                                             <td colSpan="11" className='norecordfound'><span>No Records Found</span></td>

                                                                        </tr> : ''
                                                                } */}
                                                            </tr> : ''
                                                    }
                                                </tbody>
                                            </table>
                                            {/* {getres === 0 ? <Loader type="ThreeDots" color="#b6da94" height={80} width={80} timeout={2000} style={{ textAlign: "center" }} /> : ''} */}
                                        </div>
                                    </form>

                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col-sm-12">
                                    <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>

                                        <span className="btn btn-primary"
                                            onClick={
                                                () => ResetOperation("Back")
                                            }
                                        >
                                            <i className="fa fa-arrow-circle-left"></i>
                                            &nbsp;Back</span>

                                        <span className="btn btn-danger"
                                            onClick={
                                                () => ResetOperation()
                                            } >
                                            <i className="fa fa-times"></i>
                                            &nbsp;Reset</span>


                                        <button type="button" className="btn btn-success"
                                            onClick={UpdateTNA}
                                        >
                                            <i className="fa fa-check right"></i>
                                            &nbsp;{isLastEdit ? 'Update' : 'Save'}</button>

                                    </div>
                                </div>
                            </div> */}


                            {getLapmodal === true ? (
                                <Modal
                                    show={getLapmodal === true}
                                    size="xl"
                                    dragable
                                    backdrop="static"
                                    keyboard={false}
                                    onHide={() => handleLabDip()}
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>Lab Dip  Submission</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <LabdibSubmission props={props}
                                            TNAId={TNAId}
                                            StyleId={StyleId}
                                            LabdibFabricInfoId={LabdibFabricInfoId}
                                            LapdipSubmissionCallback={LapdipSubmissionCallback}
                                        > </LabdibSubmission>
                                    </Modal.Body>
                                    <Modal.Footer></Modal.Footer>
                                </Modal>
                            ) : (
                                ""
                            )}
                            {getOpenLabdipApppopup === true ? (
                                <Modal
                                    show={getOpenLabdipApppopup === true}
                                    size="xl"
                                    dragable
                                    backdrop="static"
                                    keyboard={false}
                                    onHide={() => handleLabDipApp()}
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>Lab Dip  Approval</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <LabdibApproval props={props}
                                            LapdipApprovalCallback={LapdipApprovalCallback}
                                            StyleId={StyleId}
                                            LabdibFabricInfoId={LabdibFabricInfoId}
                                            SubmittedOptionListId={SubmittedOptionListId}
                                        ></LabdibApproval>
                                    </Modal.Body>
                                    <Modal.Footer></Modal.Footer>
                                </Modal>
                            ) : (
                                ""
                            )}
                            {
                                strikeoffModalpopup === true ?

                                    <Modal show={strikeoffModalpopup === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => setStrikeOffmodalpopup(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Strike Off submission </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <StrikeOffSubmission props={props}
                                                StrikeoffSubmissionCallback={StrikeoffSubmissionCallback}
                                                TNAId={TNAId}
                                                TNAStrikeOffRefInfoId={TNAStrikeOffRefInfoId}
                                            ></StrikeOffSubmission>
                                        </Modal.Body>
                                        <Modal.Footer>

                                        </Modal.Footer>

                                    </Modal> : ''
                            }
                            {
                                getstrikeoffApppopup === true ?

                                    <Modal show={getstrikeoffApppopup === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handleStrikeOffApp(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Strike Off Approval </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <StrikeApproval props={props}
                                                StrikeoffApprovalCallback={StrikeoffApprovalCallback}
                                                TNAStrikeOffRefInfoId={TNAStrikeOffRefInfoId}
                                                SubmittedOptionListId={SubmittedOptionListId}
                                                ColorAppCommentsId={ColorAppCommentsId}
                                            ></StrikeApproval>
                                        </Modal.Body>
                                        <Modal.Footer>

                                        </Modal.Footer>

                                    </Modal> : ''
                            }

                            {
                                getTrimmodalpopup === true ?

                                    <Modal show={getTrimmodalpopup === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handletrimmPopupClose()}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Trim Submission </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            <TrimSubmission props={props}
                                                TrimSubmissionCallback={TrimSubmissionCallback}
                                                TNAId={TNAId}
                                                TNATrimRefInfoId={TNATrimRefInfoId}
                                            ></TrimSubmission>


                                        </Modal.Body>
                                        <Modal.Footer>

                                        </Modal.Footer>

                                    </Modal> : ''
                            }
                            {
                                getTrimApprovalpopup === true ?

                                    <Modal show={getTrimApprovalpopup === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handleTrimappPopupClose()}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Trim Approval </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            <TrimApproval props={props}
                                                TrimApprovalCallback={TrimApprovalCallback}
                                                TNATrimRefInfoId={TNATrimRefInfoId}
                                                SubmittedOptionListId={SubmittedOptionListId}
                                                ColorAppCommentsId={ColorAppCommentsId}
                                            ></TrimApproval>

                                        </Modal.Body>
                                        <Modal.Footer>

                                        </Modal.Footer>

                                    </Modal> : ''
                            }

                            {
                                getSampleSubModalpopup === true ?

                                    <Modal show={getSampleSubModalpopup === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handleprotoSubApp(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Proto Fit SMS Submission </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <ProtoFitSMSSubmission props={props}
                                                ProtoFitSMSSubmissionCallback={ProtoFitSMSSubmissionCallback}
                                                TNAId={TNAId}
                                                TNAProtoFitId={TNAProtoFitId}
                                                TNATaskName={TNAStrTaskName}
                                            ></ProtoFitSMSSubmission>
                                        </Modal.Body>
                                        <Modal.Footer>

                                        </Modal.Footer>

                                    </Modal> : ''
                            }
                            {
                                getSampleAppModalpopup === true ?

                                    <Modal show={getSampleAppModalpopup === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handleprotofitApp(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>ProtoFitSMSApproval </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <ProtoFitSMSApproval props={props}
                                                ProtoFitSMSSubmissionCallback={ProtoFitSMSSubmissionCallback}
                                                TNAId={TNAId}
                                                TNAProtoFitId={TNAProtoFitId}
                                                TNATaskName={TNAStrTaskName}
                                            ></ProtoFitSMSApproval>
                                        </Modal.Body>
                                        <Modal.Footer>

                                        </Modal.Footer>

                                    </Modal> : ''
                            }
                            {
                                getApperancemodalpopup === true ?

                                    <Modal show={getApperancemodalpopup === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => HandleAfterWashList(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Appearance After Wash</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <AfterWashList props={props}
                                                AppareanceTestSubmissionCallback={AppareanceTestSubmissionCallback}
                                                TNAId={TNAId}
                                                StyleId={StyleId}
                                                AppareanceTestId={AppareanceTestId}
                                            ></AfterWashList>
                                        </Modal.Body>
                                        <Modal.Footer>

                                        </Modal.Footer>

                                    </Modal> : ''

                            }
                            {
                                isSkuAndStyleDetailPopup &&
                                <Modal show={isSkuAndStyleDetailPopup} size="lg" dragable backdrop="static" keyboard={false} onHide={() => hideSkuAndStyleDetail()}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Sku And Size Details
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>SKU Name</th>
                                                    <th>Size Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{SkuAndStyleDetails.skuNameList}</td>
                                                    <td>{SkuAndStyleDetails.sizeNameList ? SkuAndStyleDetails.sizeNameList : " - "}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="button" className="btn btn-danger" onClick={hideSkuAndStyleDetail}>
                                            <i className="fa fa-times"></i>&nbsp;
                                            Close
                                        </button>
                                    </Modal.Footer>
                                </Modal>
                            }
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>
            {loader}
        </Fragment>


    );
}
export default ViewTNA;
