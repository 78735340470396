import React, { useState, useEffect, useMemo, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactNotification from 'react-notifications-component';
import Form from "react-validation/build/form";
import Reactselect from 'react-select';
import RouteService from "./../../../../services/Route/RouteService";
import Nodify from '../../../Common/ReactNotification';
import TestService from '../../../../services/Master/TestService';
import SearchRetain from "../../../Common/SearchRetain";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";

const TestNameEdit = (props) => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const { user: currentUser } = useSelector((state) => state.auth);
    const [getTestNameInfo, setTestNameInfo] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [getIsTestNameDuplicated, setIsTestNameDuplicated] = useState(false);
    const [getTaskOption, setTaskOption] = useState([]);
    const [getTypeOption, setTypeOption] = useState([]);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getExisting, setExisting] = useState([]);
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red', innerHeight: '100px', outerHeight: '100px' }),
    }
    let styles1 = {
        control: styles => ({ ...styles, innerHeight: '100px', outerHeight: '100px' }),
    }
    //reducer
    const reducerState = useSelector((state) => state);
    const comments = reducerState.task.taskList;
    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    if (menulist !== undefined) {
        activeMenu = menulist.filter(i => i.menuName === "TestName");
    }
    //end enable menu
    const handleChangeTask = (event, index) => {
        let values = [...getTestNameInfo];
        if (event.length === 0) {
            values[0].TaskID = "";
            values[0].TaskIDforEdit = '';
        } else {
            let taskValue = "";
            for (let task of event) {
                if (taskValue === "") {
                    taskValue = task.value.toString();
                } else {
                    taskValue = taskValue + "," + task.value;
                }
            }
            values[0].TaskID = taskValue;
            values[0].TaskIDforEdit = event;
        }
        setTestNameInfo(values);
    }


    const handleChangeType = (event) => {
        {
            let values = [...getTestNameInfo];
            if (event.length === 0) {
                values[0].TypeID = "";
                values[0].TypeIDforEdit = '';
            } else {
                let typeValue = "";
                for (let type of event) {
                    if (typeValue === "") {
                        typeValue = type.value.toString();
                    } else {
                        typeValue = typeValue + "," + type.value;
                    }
                }
                values[0].TypeID = typeValue;
                values[0].TypeIDforEdit = event;
            }
            setTestNameInfo(values);
        }
    }

    const handleTestNameChange = (event) => {
        let Value = [...getTestNameInfo];
        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }
        Value[0].TestName = inputText;
        setTestNameInfo(Value);
    }


    const SaveTestName = (e) => {
        setButtonLoader(true);
        setSubmitted(true);
        e.preventDefault();
        if (getTestNameInfo[0].TestName.trim() === ''
            || (getTestNameInfo[0].TaskIDforEdit == null || getTestNameInfo[0].TaskIDforEdit == undefined || getTestNameInfo[0].TaskIDforEdit == "" || getTestNameInfo[0].TaskIDforEdit.length == 0)
            || (getTestNameInfo[0].TypeIDforEdit == null || getTestNameInfo[0].TypeIDforEdit == undefined || getTestNameInfo[0].TypeIDforEdit == "" || getTestNameInfo[0].TypeIDforEdit.length == 0)) {
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
        }
        else if (getTestNameInfo[0].TestName.trim()) {
            getTestNameInfo[0].TestName = getTestNameInfo[0].TestName.trim();
            let params = {
                TestNameID: getTestNameInfo[0].TestNameID
                , TestName: getTestNameInfo[0].TestName.trim()
                , TaskID: getTestNameInfo[0].TaskID
                , TypeID: getTestNameInfo[0].TypeID
                , ModifiedBy: currentUser.employeeinformationID
            }
            TestService.UpdateTestName(params).then(res => {
                setSubmitted(false);
                var page = "Remove";
                SearchRetain(page);
                let Func = 'Add';
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    PageRedirect(Func);
                }
                setButtonLoader(false);
            }, (err => {
                setSubmitted(false);
            }));
        }
    }

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/TestNameList",
            state: {
                message: Func,
            },
        }
        );
    };

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/TestNameList')
        } else {
            window.location.reload();
        }
    }

    const CheckDuplicate = (index) => {
        const values = [...getTestNameInfo];
        setIsTestNameDuplicated(true);
        let isExisting = !!getExisting.find(d => d.testName.trim().toLowerCase() === values[index].TestName.trim().toLowerCase() && d.testNameID !== props.location.state.params.testNameID);
        if (isExisting) {
            values[index].TestName = '';
            setTestNameInfo(values);
            Nodify('Warning!', 'warning', "This Test Name is already exist.");
            return false;
        }
        else {
            setIsTestNameDuplicated(false);
        }
        for (var j = 0; j < (getTestNameInfo.length); j++) {
            if (j !== index) {
                if (values[j].TestName.trim().toLowerCase() === values[index].TestName.trim().toLowerCase()) {
                    values[index].TestName = '';
                    setTestNameInfo(values);
                    Nodify('Warning!', 'warning', 'This Test Name is already exist.');
                    setSubmitted(true);
                    return false;
                }
                else {
                    setIsTestNameDuplicated(false);
                }
            }
            else {
                setIsTestNameDuplicated(false);
            }
        }
    }

    function BindCommentValue(props) {
        let CommentInfoValue = [{
            Operation: 2
            , TestNameID: 0
            , TestName: ''
            , Createdby: currentUser.employeeinformationID
            , TaskIDforEdit: ''
            , TaskID: ''
            , TypeIDforEdit: ''
            , TypeID: ''
        }];
        if (props.location.state !== undefined) {
            if (props.location.state.params.testNameID !== 0) {
                CommentInfoValue[0].Operation = 2;
                CommentInfoValue[0].TestNameID = props.location.state.params.testNameID;
                CommentInfoValue[0].TestName = props.location.state.params.testName;
                CommentInfoValue[0].TaskID = props.location.state.params.taskID;
                CommentInfoValue[0].TypeID = props.location.state.params.typeID;
                CommentInfoValue[0].TaskIDforEdit = "";
                CommentInfoValue[0].TypeIDforEdit = "";
                return CommentInfoValue;
            }
        }
    }

    useEffect(() => {
        TestService.GetTestNameList(1, 0, 0).then((res => {
            if (res.data) {
                setExisting(res.data.testNameList);
            }
        }))
    }, [])

    useEffect(() => {
        let Commentinfo = BindCommentValue(props);

        RouteService.LoadTaskList("0").then((data => {
           
            showLoader();
            let comments = data.data;
            if (data.data.length > 0) {
                let TestingType = comments.filter((data => {
                    if (data.taskTypeName == "Testing") {
                        return data;
                    }
                }));
                let computedTaskOptions = TestingType.map((data => {
                    data.id = data.value;
                    data.value = data.value;
                    data.label = data.label + " - " + data.taskOrderTypeName;
                    data.name = data.label + " - " + data.taskOrderTypeName;
                    return { id: data.id, name: data.name, label: data.label, value: data.value };
                }));
                setTaskOption(computedTaskOptions);
                let TypeOptions = [{ id: 1, value: 1, label: 'Internal', name: 'Internal' }
                    , { id: 2, value: 2, label: 'External', name: 'External' }];
                if (props.location.state !== undefined) {
                   
                    if (props.location.state.params.testNameID !== 0) {
                        Commentinfo[0].TaskIDforEdit = computedTaskOptions.filter((data => {
                            if (props.location.state.params.taskID.split(",").includes(data.id.toString())) {
                                return data;
                            }
                        }))
                        Commentinfo[0].TypeIDforEdit = TypeOptions.filter((data => {
                            if (props.location.state.params.typeID.split(",").includes(data.id.toString())) {
                                return data;
                            }
                        }))
                        setTypeOption(TypeOptions);
                    }
                }
            }
            hideLoader();
        }), (err) => {
            hideLoader();
        })
        setTestNameInfo(Commentinfo);
    }, [props.location.state]);
    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <div className="widget flat radius-bordered">
                            <div className="widget-header heading_box_style">
                                <h3 className="heading_style_common">
                                    Edit Test Name
                                </h3>
                            </div>
                            <div className="widget-body">
                                <div id="registration-form">
                                    <Form>
                                        {
                                            getTestNameInfo.length > 0 && getTestNameInfo.map((inputField, index) => (
                                                <Fragment key={
                                                    `${inputField}~${index}`
                                                }>
                                                    <div className="col-md-4">
                                                        <label> Test Name<span className="text-danger">*</span></label>
                                                        <input type='text' className='form-control' onChange={handleTestNameChange}
                                                            onBlur={() => CheckDuplicate(index)}
                                                            style={{ border: (getIsTestNameDuplicated || submitted) && !inputField.TestName ? '1px solid red' : '' }} name="testName"
                                                            id="testName" placeholder="Enter Test Name" value={inputField.TestName}
                                                            autoComplete="off" maxLength="100" />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label htmlFor="MeasurePointName">
                                                            Test Task Name<span className="text-danger">*</span>
                                                        </label>
                                                        <Reactselect className="basic-single"
                                                            name="ddlTestTask"
                                                            id="ddlTestTask"
                                                            isDisabled={false}
                                                            isLoading={false}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            isMulti
                                                            menuPosition="fixed"
                                                            onChange={event => handleChangeTask(event)}
                                                            options={getTaskOption}
                                                            value={inputField.TaskIDforEdit}
                                                            styles={submitted && (inputField.TaskIDforEdit == "" || inputField.TaskIDforEdit.length == 0) ? styles : styles1}
                                                        >
                                                        </Reactselect>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label htmlFor="MeasurePointName">
                                                            Test Type <span className="text-danger">*</span>
                                                        </label>
                                                        <Reactselect className="basic-single"
                                                            name="ddlTestType"
                                                            id="ddlTestType"
                                                            isDisabled={false}
                                                            isLoading={false}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            isMulti
                                                            menuPosition="fixed"
                                                            onChange={event => handleChangeType(event)}
                                                            options={getTypeOption}
                                                            value={inputField.TypeIDforEdit}
                                                            styles={submitted && (inputField.TypeIDforEdit == "" || inputField.TypeIDforEdit.length == 0) ? styles : styles1}
                                                        >
                                                        </Reactselect>
                                                    </div>
                                                </Fragment>
                                            ))
                                        }
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div
                            className="buttons-preview"
                            align="right"
                            style={{ marginTop: "15px" }}
                        >
                            <div className="buttons-preview" align="right">
                                <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                    <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                                </span>
                                <span className="btn btn-danger" onClick={() => ResetOperation()}
                                    style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}>
                                    <i className="fa fa-times"></i>&nbsp;Reset
                                </span>
                                <button
                                    type="submit"
                                    className="btn btn-success"
                                    onClick={SaveTestName}
                                    disabled={buttonLoader}
                                >
                                    <i className="fa fa-check right"></i> &nbsp;Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loader}
        </>
    )
}
export default TestNameEdit;