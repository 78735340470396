import React, { useState, useEffect, useRef, Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import Lightbox from "react-image-lightbox";
import { GetOrderTrimApprovalViewList, clearTrimApprovalViewSubRow } from "../../../../actions/orderTrim";
import { loadSupplierList } from "../../../../actions/tna";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import moment from "moment";
import Reactselect from 'react-select';
import { Modal, Button } from "react-bootstrap";
import TnaService from "../../../../services/TNA/TNAService";

import TrimApproval from "../../../TNA/TrimApproval_Order";
import DatePicker from "react-datepicker";
import Nodify from "../../../Common/ReactNotification"
import { TNAManualCloseChildTrim } from '../../../Common/PageActionNumber'
import SweetAlertPopup from "../../../Common/SweetAlertPopup";
const TrimViewApprovalTab = (props) => {
    //declaration
    const dispatch = useDispatch();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [getStyleNameSearch, setgetStyleNameSearch] = useState("");
    const [getStyleNoSearch, setgetStyleNoSearch] = useState("");
    const [getIdPoSearch, setgetIdPoSearch] = useState("");
    //reducer
    const TrimApprovalList = useSelector((state) => state.orderTrim.orderTrimApprovalViewList);
    //const TrimSubmissionParams = useSelector((state) => state.generalTrim.generalTrimViewDetailParams);
    let isLoadingOrderTrim = useSelector((state) => state.orderTrim.isLoadingOrderTrim);
    let isReLoadingOrderTrim = useSelector((state) => state.orderTrim.isReLoadingOrderTrim);
    const getSupplierList = useSelector((state) => state.tna.drpSupplierList);
    const StaticTask = useSelector((state) => state.tna.StaticTask);
    let activeMenu;
    let activeMenuSub;
    let TrimApprovalTab;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
    if (menulist !== undefined && menulistSub.length > 0) {
        activeMenu = menulist.filter(i => i.menuName === 'Trim');
        if (activeMenu.length > 0) {
            activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);

            if (activeMenuSub.length > 0) {

                for (let index = 0; index < activeMenuSub.length; index++) {
                    if (activeMenuSub[index].menuName === "GeneralTrimApproval") {
                        TrimApprovalTab = activeMenuSub[index];
                    }
                }
            }
        }
    }
    //state

    const [getID, setID] = useState(0);

    const [isOpen, setIsOpen] = useState(false);
    const [currentExpand, setcurrentExpand] = useState({ currindex: "", currTrimId: "", currColorGroupID: "" });

    const [getSearchReqValues, setSearchReqValues] = useState({
        StatusId: 2,
        SupplierId: 0,
        SearchTxt: "",
        StyleName: "",
        StyleNo: "",
        IdPoNo: "",
        stateDate: [
            {
                startDate: null,
                endDate: new Date(""),
                key: 'selection',
                isShow: false
            }
        ]

    });


    const SearchReqValues = { ...getSearchReqValues };



    const [Searchvalues, setSearchvalues] = useState({
        SupplierId: 0, approvalStatus: 0
    });
    let BuyerId = 0;
    let BrandId = 0;
    let SeasonId = 0;
    //get props params
    if (props.props.location.state !== undefined) {

        BuyerId = props.props.location.state.params.buyerId === undefined ? 0 : props.props.location.state.params.buyerId;
        BrandId = props.props.location.state.params.brandId === undefined ? 0 : props.props.location.state.params.brandId;
        SeasonId = props.props.location.state.params.seasonId === undefined ? 0 : props.props.location.state.params.seasonId;

    }

    const [showmaunalclose, setshowmaunalclose] = useState(false);


    const [MultiCloseDatas, setMultiCloseDatas] = useState([]);
    const [Manualclosedata, setManualclosedata] = useState({ ManualCloseComment: '', ManualCloseDate: '' });
    const [manualcloseheader, setmanualcloseheader] = useState("");
    const [getComment, setComment] = useState(false);
    const [selectall, setselectall] = useState(false);
    const [getsID, setsID] = useState(0);
    const [ChildId, setChildId] = useState({ ChildId: 0, Status: 0 });
    const [TNAId, setTNAId] = useState();
    const [TNATrimRefInfoId, setTNATrimRefInfoId] = useState();
    const [TaskStatus, setTaskStatus] = useState();
    const [SwatchStatus, setSwatchStatus] = useState();
    const [TrimModalpopup, setTrimmodalpopup] = useState(false);

    const [SubmittedOptionListId, setSubmittedOptionListId] = useState();
    const [ColorAppCommentsId, setColorAppCommentsId] = useState();
    const [getTrimApppopup, setTrimApppopup] = useState(false);
    const [editTrimApproval, seteditTrimApproval] = useState(false);
    const [deleteTrimApproval, setdeleteTrimApproval] = useState(false);
    const [TrimswatchDownload, setTrimswatchDownload] = useState(false);
    const [TrimswatchUpload, setTrimswatchUpload] = useState(false);
    // If check production or sampling
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProduction = activeMenus.IsProduction;
    // End


    useEffect(() => {
        if (!isLoadingOrderTrim) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingOrderTrim])

    useEffect(() => {

        dispatch(loadSupplierList());
        searchTrim();

    }, [])

    useEffect(() => {
        commonParentExpand();
    }, [isReLoadingOrderTrim])

    //#region  ------------------  search details --------------------------------
    const searchTrim = () => {
        SearchReqValues.stateDate[0].isShow = false;
        setSearchReqValues(SearchReqValues);

        let StartDate = null;
        let EndDate = null;
        if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
        }
        if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
        }

        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, TrimId: 0, ColorGroupID: 0, Index: currentExpand.currindex,
            SearchText: SearchReqValues.SearchTxt, Operation: 4, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
            StyleName: SearchReqValues.StyleName, StyleNo: SearchReqValues.StyleNo, IdPoNo: SearchReqValues.IdPoNo
            , IsProduction: IsProduction
        };
        dispatch(GetOrderTrimApprovalViewList(params));
        commonParentExpand();
    }
    const commonParentExpand = () => {
        let rowHiddens = document.querySelectorAll(".parentExpand");
        for (let row of rowHiddens) {
            row.setAttribute('aria-expanded', 'false');
        }
        let rowIconHiddens = document.querySelectorAll(".clscollapseexpandTNA");
        for (let rowIcon of rowIconHiddens) {
            rowIcon.className = rowIcon.className.replace("fa-minus-square-o", "fa-plus-square-o");
        }
    }

    const resetTrim = () => {
        SearchReqValues.stateDate[0].startDate = null;
        SearchReqValues.stateDate[0].endDate = new Date("");
        SearchReqValues.stateDate[0].key = 'selection';
        SearchReqValues.stateDate[0].isShow = false;

        SearchReqValues.StatusId = 2;
        SearchReqValues.SupplierId = 0;
        SearchReqValues.SearchTxt = "";
        SearchReqValues.StyleName = "";
        SearchReqValues.StyleNo = "";
        SearchReqValues.IdPoNo = "";
        setSearchReqValues(SearchReqValues);
        searchTrim();

    }


    const clearDateSelect = () => {
        SearchReqValues.stateDate[0].startDate = null;
        SearchReqValues.stateDate[0].endDate = new Date("");
        SearchReqValues.stateDate[0].key = 'selection';
        SearchReqValues.stateDate[0].isShow = false;

        setSearchReqValues(SearchReqValues);
    }
    const handleDateSelect = (event, isShow) => {

        SearchReqValues.stateDate[0].isShow = isShow;
        setSearchReqValues(SearchReqValues);
    }

    const handlePopupDateselection = (item) => {

        SearchReqValues.stateDate = [item.selection];
        setSearchReqValues(SearchReqValues);
    }

    const handleSearchChange = (e, FieldName) => {

        let inputText = '';
        if (FieldName === 'SearchTxt' || FieldName === 'StyleName' || FieldName === 'StyleNo' || FieldName === 'IdPoNo') {
            if (e && e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
        } else if (FieldName === 'Status' || FieldName === 'SupplierId') {
            if (e) {
                inputText = e.value
            }
        }

        if (FieldName === 'Status') {
            SearchReqValues.StatusId = 2;
        }
        else if (FieldName === 'SupplierId') {
            SearchReqValues.SupplierId = inputText;
        } else if (FieldName === 'SearchTxt') {
            SearchReqValues.SearchTxt = inputText;
        } else if (FieldName === 'StyleName') {
            SearchReqValues.StyleName = inputText;
        }
        else if (FieldName === 'StyleNo') {
            SearchReqValues.StyleNo = inputText;
        }
        else if (FieldName === 'IdPoNo') {
            SearchReqValues.IdPoNo = inputText;
        }
        setSearchReqValues(SearchReqValues);

    }

    function convertUTCDateToLocalDate(date) {
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date;
    }

    //#end  ------------------  search details end --------------------------------





    // #region  ------------------  Expanded Toggle --------------------------------

    const handleToggle = (index, TrimId, ColorGroupID) => {
        let btnToggle = document.getElementById("btn-toggle3_" + index);
        let iconToggle = document.getElementById("icon-toggle3_" + index);

        let ariaExpanded = btnToggle.getAttribute('aria-expanded');
        if (ariaExpanded === 'false') {
            btnToggle.setAttribute('aria-expanded', 'true');
            iconToggle.className = iconToggle.className.replace("fa-plus-square-o", "fa-minus-square-o");
            setcurrentExpand({ currindex: index, currTrimId: TrimId, currColorGroupID: ColorGroupID })
            let StartDate = null;
            let EndDate = null;
            if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
                StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
            }
            if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
                EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
            }

            let params = {
                BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, TrimId: TrimId, ColorGroupID: ColorGroupID, Index: index,
                SearchText: SearchReqValues.SearchTxt, Operation: 4, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
                StyleName: SearchReqValues.StyleName, StyleNo: SearchReqValues.StyleNo, IdPoNo: SearchReqValues.IdPoNo, IsProduction: IsProduction
            };

            dispatch(GetOrderTrimApprovalViewList(params));

            // GeneralService.GetGeneralTrimSubmissionApprovalList(params).then((response) => {
            //     if (response.data) {
            //         setApprovalList(response.data.pantoneCodeList);
            //         let SubList = [...ApprovalList];
            //         SubList[index].pantoneSubList = response.data.generalTrimPantoneSubInfo ? response.data.generalTrimPantoneSubInfo : [];
            //         SubList[index].criticalList = response.data.criticalList ? response.data.criticalList : [];
            //         setApprovalList(SubList);
            //     }
            // }).catch(() => { });
        }
        else {

            btnToggle.setAttribute('aria-expanded', 'false');
            iconToggle.className = iconToggle.className.replace("fa-minus-square-o", "fa-plus-square-o");
            if (TrimId) {
                dispatch(clearTrimApprovalViewSubRow(index));
            }
        }
    };



    const handleserach = () => {

    }

    const handledelete = () => {
       ;
        let Value = [];
        //  Value.TNAId = props.location.state.params.tnaid;
        Value.Id = ChildId.ChildId;
        setshowmaunalclose(true);
        setsID({ TNAManualClose: TNAManualCloseChildTrim, showPopupDelete: true, Params: Value });
    }


    const DeleteCallback = (value) => {
        if (value === true) {
            setsID({ showPopupDelete: false, Params: [] });
            setshowmaunalclose(false)
            searchTrim();
        } else {
            setsID({ showPopupDelete: false, Params: [] })
            setshowmaunalclose(true);
        }
    }
    const handleChildManualclose = async (TaskName, TaskType, TaskID, ChildId, Status, Comment, CommentDate) => {
        showLoader();
        setChildId({ ChildId: ChildId, Status: Status });
        var mandata = { ...Manualclosedata }
        mandata.ManualCloseComment = Comment;
        mandata.ManualCloseDate = new Date(CommentDate);
        setManualclosedata(mandata);

        setshowmaunalclose(true);

        // let Operation;
        // if (TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
        //     Operation = 1;
        // }
        // else if (TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT) {
        //     Operation = 3;
        // }
        // else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
        //     Operation = 2;
        // }
        // else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL) {
        //     Operation = 4;
        // }
        // else if (TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
        //     Operation = 5;
        // }

        // else if (TaskName.toLowerCase() === StaticTask.SMS_APPROVAL) {
        //     Operation = 6;
        // }
        // else {
        //     Operation = 7;

        // }


        // if (Operation !== 7) { // check submission exists for approvals
        //     TnaService.GetTNAManualCloseSubmissionList(ChildId, TaskName).then((response) => {
        //         if (response.data.length !== 0) {

        //             setshowmaunalclose(true);

        //             //get manual close
        //             let Operation;
        //             if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
        //                 Operation = 1;
        //             }
        //             else if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
        //                 || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
        //                 || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
        //                 || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
        //                 Operation = 3;
        //             }
        //             else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
        //                 || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER
        //                 || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER) {
        //                 Operation = 2;
        //             }
        //             else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL) {
        //                 Operation = 4;
        //             }
        //             else if (TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
        //                 Operation = 5;
        //             }
        //             //|| TaskName.toLowerCase() === StaticTask.SMS_PURCHASE_ORDER
        //             else if (TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || TaskName.toLowerCase() === StaticTask.SMS_APPROVAL
        //                 || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER
        //                 || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE || TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW) {
        //                 Operation = 6;
        //             }
        //             else if (TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH) {
        //                 Operation = 7;
        //             }
        //             else {
        //                 Operation = 8;
        //             }


        //             let params = {
        //                 BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId,
        //                 SupplierId: SearchReqValues.SupplierId, TaskType: TaskType, TaskNameId: TaskID,
        //                 TaskTodoInProgressCompletedStatus: 9, StartDate: null, EndDate: null,
        //                 Operation: Operation, ParentIdOrSubChildId: ChildId
        //             };

        //             TnaService.GetSubChildMultiTaskManualCloseList(params).then((response) => {
        //                 if (response.data) {
        //                     let data = response.data.map((item) => {

        //                         if (item.subTaskInfoId === ChildId) {
        //                             item.isSelected = 1;

        //                             return item;
        //                         } else {
        //                             return item;
        //                         }
        //                     });
        //                     let isSelectAll = 0;
        //                     if (data) {
        //                         isSelectAll = !!!data.find(d => d.isSelected === 0);
        //                         isSelectAll = isSelectAll ? 1 : 0;
        //                     }
        //                     setselectall(isSelectAll);
        //                     if (Status !== 3) {
        //                         setMultiCloseDatas(data);
        //                     }
        //                     else {
        //                         setMultiCloseDatas(data.filter(x => x.subTaskInfoId === ChildId));
        //                     }

        //                 }


        //             }).catch(() => { });
        //         }
        //         else {
        //             Nodify('Warning!', 'warning', "Manual Submission not yet added for this task");
        //             return false;
        //         }

        //     }).catch(() => { });
        // }

        setshowmaunalclose(true);

        //get manual close
        let Operation;
        if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
            Operation = 1;
        }
        else if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
            || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
            Operation = 3;
        }
        else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
            || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER) {
            Operation = 2;
        }
        else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL) {
            Operation = 4;
        }
        else if (TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
            Operation = 5;
        }
        //|| TaskName.toLowerCase() === StaticTask.SMS_PURCHASE_ORDER
        else if (TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || TaskName.toLowerCase() === StaticTask.SMS_APPROVAL
            || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER
            || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE || TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW) {
            Operation = 6;
        }
        else if (TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH) {
            Operation = 7;
        }
        else {
            Operation = 8;
        }


        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId,
            SupplierId: SearchReqValues.SupplierId, TaskType: TaskType, TaskNameId: TaskID,
            TaskTodoInProgressCompletedStatus: 9, StartDate: null, EndDate: null,
            Operation: Operation, ParentIdOrSubChildId: ChildId
        };

        await TnaService.GetSubChildMultiTaskManualCloseList(params).then((response) => {
            if (response.data) {
                let data = response.data.map((item) => {

                    if (item.subTaskInfoId === ChildId) {
                        item.isSelected = 1;

                        return item;
                    } else {
                        return item;
                    }
                });
                let isSelectAll = 0;
                if (data) {
                    isSelectAll = !!!data.find(d => d.isSelected === 0);
                    isSelectAll = isSelectAll ? 1 : 0;
                }
                setselectall(isSelectAll);
                if (Status !== 3) {
                    setMultiCloseDatas(data);
                }
                else {
                    setMultiCloseDatas(data.filter(x => x.subTaskInfoId === ChildId));
                }
                hideLoader();
            }
            hideLoader();

        }).catch(() => { hideLoader(); });


    };

    const handleChangeIsManualCloseComments = (event, feild) => {
        var manualclosedata = { ...Manualclosedata };
        let input = '';

        setComment(false);
        if (feild === "Comment") {
            if (event.target.value.trim() !== '') {
                input = event.target.value;
            }
            manualclosedata.ManualCloseComment = input;
        }
        else {
            manualclosedata.ManualCloseDate = event;
            // ManualCloseData.ManualCloseDate = event;
        }
        setManualclosedata(manualclosedata);
    }
    const CustomInputman = (props) => {

        return (
            <input
                className="form-control DatePickerCalenderHoliday min_width_Date"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                menuPosition="fixed"
                placeholder="DD/MM/YYYY"
                style={{ border: getComment && props.value === '' ? '1px solid red' : '' }}
            />
        )
    }


    const ReloadApproval = () => {
        let StartDate = null;
        let EndDate = null;
        if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
        }
        if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
        }

        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, TrimId: 0, ColorGroupID: 0, Index: currentExpand.currindex,
            SearchText: SearchReqValues.SearchTxt, Operation: 4, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
            StyleName: SearchReqValues.StyleName, StyleNo: SearchReqValues.StyleNo, IdPoNo: SearchReqValues.IdPoNo, IsProduction: IsProduction
        };
        dispatch(GetOrderTrimApprovalViewList(params));
    }


    const OpenTrimApprovalpopup = (TNATrimRefInfoId, StatusId, SwatchStatus) => {
       ;
        TnaService.TrimSubmissionList(3, TNATrimRefInfoId, IsProduction).then((response) => {
            if (response.data.length !== 0) {

                setTNATrimRefInfoId(TNATrimRefInfoId);
                setSubmittedOptionListId(TNATrimRefInfoId);
                setColorAppCommentsId(TNATrimRefInfoId);
                setTaskStatus(StatusId);
                setSwatchStatus(SwatchStatus)
                //  setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                setTrimApppopup(true);
            }
            else {
                Nodify('Warning!', 'warning', "Submission not yet added for this task");
                return false;
            }

        }).catch(() => { });

    }

    const handleTrimApp = () => {
        setTrimApppopup(false);
        seteditTrimApproval(false);
        setdeleteTrimApproval(false);
        setTrimswatchDownload(false);
        setTrimswatchUpload(false);

    }

    const TrimApprovalCallback = (value, getOrderinfo, Operation, SwatchStatus) => {

        if (value === true) {
            setTrimApppopup(false);
            seteditTrimApproval(false);
            setdeleteTrimApproval(false);


            ReloadApproval();
            setTrimswatchDownload(false);
            setTrimswatchUpload(false);
            searchTrim();
            // handleToggle(currentExpand.currindex, currentExpand.currTrimId, currentExpand.currColorGroupID);
        }
    }


    return (
        <Fragment>
            <div> {
                isOpen && (
                    <Lightbox
                        mainSrc={isOpen}
                        alt="bg image"

                        onCloseRequest={
                            () => setIsOpen(false)
                        } />
                )
            } </div>
            <div className="widget-body">
                <div id="registration-form">
                    <form>
                    <div className="col-sm-12">

                            
<div className="col-md-2">
    <div className='form-group'>
        <label>Trim Code / Name </label>
        <span className='input-icon icon-right'>
            <input type="text" value={SearchReqValues.SearchTxt} onChange={event => handleSearchChange(event, "SearchTxt")} placeholder="Trim Code / Name" className="form-control" />

        </span>

    </div>

</div>
<div className="col-md-2">
    <label htmlFor="Status">
        Style Name
    </label>
    <span className="input-icon icon-right">
        <input
            type="text"
            className="form-control StyleList"
            title={'Style Name'}
            placeholder={'Style Name'}
            value={SearchReqValues.StyleName}
            onChange={e => handleSearchChange(e, 'StyleName')}
        />
    </span>
</div>

<div className="col-md-2">
    <label htmlFor="Status">
        Style No
    </label>
    <span className="input-icon icon-right">
        <input
            type="text"
            className="form-control StyleList"
            title={'Style No'}
            placeholder={'Style No'}
            value={SearchReqValues.StyleNo}
            onChange={e => handleSearchChange(e, 'StyleNo')}
        />
    </span>
</div>
<div className="col-md-2">

    <div className="form-group">
        <label >Date Range <span className="rptDateRangeSpan" title="Clicking on the text box cause the datepicker to appear and disappear">(Clicking on the text box...)</span> </label>
        <span className="input-icon icon-right">
            <input
                value={(SearchReqValues.stateDate[0].startDate === null ? '' : moment(SearchReqValues.stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') + ((SearchReqValues.stateDate[0].endDate === null || !moment(SearchReqValues.stateDate[0].endDate).isValid()) ? '' : moment(SearchReqValues.stateDate[0].endDate).format('DD/MM/YYYY'))}
                onClick={event => handleDateSelect(event, !SearchReqValues.stateDate[0].isShow)}
                //onBlur={event => handleDateBlur()}
                type="text"
                className={'form-control'}
                placeholder="Select Date"
            />
            <i className="fa fa-times" onClick={clearDateSelect} ></i>
        </span>
    </div>


    {SearchReqValues.stateDate[0].isShow &&
        <DateRangePicker
            //onChange={item => setStateDate([item.selection])}
            onChange={item => handlePopupDateselection(item)}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={SearchReqValues.stateDate}
            showDateDisplay={false}
            direction="vertical"
            className={'TNAReportDatePicker'}

        />

    }

</div>
<div className="col-md-2">
    <label htmlFor="Status">
              ID/PO NO
    </label>
    <span className="input-icon icon-right">
        <input
            type="text"
            className="form-control StyleList"
            title={'Id Po No'}
            placeholder={'ID/PO NO'}
            value={SearchReqValues.IdPoNo}
            onChange={e => handleSearchChange(e, 'IdPoNo')}
        />
    </span>

</div>


<div className="col-md-2">
    <div className="form-group">
        <label htmlFor="SupplierID">
            Supplier
            {/* <span className="text-danger">*</span> */}
        </label>
        <span className="input-icon icon-right">
            <Reactselect className="basic-single" name="SupplierId"
                id={
                    "SupplierId"
                }
                isLoading={false}
                isClearable={true}
                isSearchable={true}
                onChange={event => handleSearchChange(event, 'SupplierId')}
                value={getSupplierList.filter(function (option) {
                    return option.value === SearchReqValues.SupplierId;

                })}
                options={getSupplierList}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
            ></Reactselect>

        </span>
    </div>
</div>





{/* <div className="col-md-2">&nbsp;</div>
<div className="col-md-2">&nbsp;</div>
<div className="col-md-2">&nbsp;</div>
<div className="col-md-2">&nbsp;</div>
<div className="col-md-2">&nbsp;</div> */}
<div className="col-md-2" style={{ marginTop: '30px' }}>

    <div className="form-group">
        <button type="button" className="btn btn-success" title="Search" onClick={event => searchTrim()}
        >
            <i className="fa fa-search"></i>
        </button>
        &nbsp;&nbsp;
        <button type="button" className="btn btn-danger" title="Reset" onClick={resetTrim}
        >
            <i className="fa fa-close"></i>
        </button>
    </div>
</div>
</div>



                        <div className="col-md-12" style={{ overflowX: "auto", minHeight: '100px' }}>
                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                <thead>
                                    <tr>
                                        <th className="fixed-column-header-first-tna col-action-or-other" style={{ width: "35px" }}></th>
                                        <th className="fixed-column-header-first-tna col-action-or-other" style={{ width: "35px" }}> #</th>
                                        <th className="col-action-or-other">Approved Date</th>
                                        <th className="col-action-or-other">Style Name</th>
                                        <th className="col-action-or-other">Style No</th>
                                        <th className="col-action-or-other">ID/PO NO</th>
                                        <th className="col-action-or-other">Supplier</th>
                                        <th className="col-action-or-other">Details</th>
                                        <th>Task Holder / Follower</th>
                                        <th className="col-action-or-other">Approval Target</th>
                                        <th className="col-action-or-other">Approval Info</th>
                                        <th className="col-action-or-other">Submission AWB #</th>
                                        <th className="text-center fixed-column-header-Route col-action-or-other">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        TrimApprovalList && TrimApprovalList.length > 0 ?
                                            <>
                                                {
                                                    TrimApprovalList && TrimApprovalList.map((inputField, index) => (
                                                        <Fragment key={`${inputField}~${index}`}>
                                                            <tr class="parent" data-child={"parent_" + index}>
                                                                <td className="fixed-column-first-tna">

                                                                    {
                                                                        <span id={"btn-toggle3_" + index} class="btn-toggle3 parentExpand" aria-expanded="false" onClick={() => handleToggle(index, inputField.trimId, inputField.colorGroupID)} aria-labelledby="btn-toggle3 id-master">
                                                                            <i id={"icon-toggle3_" + index} class="fa fa-plus-square-o clsPointer clscollapseexpandTNA"></i>
                                                                        </span>
                                                                    }

                                                                </td>
                                                                <td>{index+1}</td>
                                                                <td>
                                                                    <span className='input-icon icon-right'>
                                                                        {
                                                                            inputField.trim
                                                                        }
                                                                    </span>
                                                                </td>
                                                               
                                                                <td colSpan="10">

                                                                    <div className="trwidth_manual" style={{ textAlign: "center" }}>
                                                                        <img className="width_manual"
                                                                            src={inputField.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images/Style/Trim/" + inputField.imagePath}
                                                                            alt="Logo"
                                                                            onClick={
                                                                                () => setIsOpen(inputField.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images/Style/Trim/" + inputField.imagePath)
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>


                                                            </tr>
                                                            {
                                                                inputField.trimSubmitApprovalReferenceList && inputField.trimSubmitApprovalReferenceList.map((subField, subIndex) => (

                                                                    <Fragment key={`${subIndex}~${subIndex}`}>
                                                                        <tr class={"child_" + index + " parentChild_" + index + "_" + subIndex} >
                                                                            <td></td>
                                                                            <td className="fixed-column-first-tna" style={{paddingLeft:"15px"}}>
                                                                                {subIndex + 1}
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {moment(subField.approvalDate).format('DD/MM/YYYY')} </span>
                                                                                }
                                                                            </td>
                                                                            <td className="cut_text" title={subField.styleName}>
                                                                                {
                                                                                    <span> {subField.styleName} </span>
                                                                                }
                                                                            </td>
                                                                            <td className="cut_text" title={subField.styleNo}>
                                                                                {
                                                                                    <span> {subField.styleNo} </span>
                                                                                }
                                                                            </td>
                                                                            <td className="cut_text" title={subField.idpono}>
                                                                                {
                                                                                    <span> {subField.idpono} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.supplierName} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.details} </span>
                                                                                }
                                                                            </td>
                                                                            <td className="cut_text" title={subField.followerName}>
                                                                                {
                                                                                    <span> {subField.followerName} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {moment(subField.approvalTarget).format('DD/MM/YYYY')} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    // <span> {subField.approvalInfo} </span>
                                                                                    <span> {subField.approvalInfo ? subField.approvalInfo : "-"} </span>
                                                                                }
                                                                            </td>

                                                                            <td>
                                                                                {
                                                                                    <span> {subField.awbName} - {(subField.awbDate === null || subField.awbDate === '') ? '' : moment(subField.awbDate).format('DD/MM/YYYY')} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    (subField.manualCloseComment !== '' && subField.manualCloseComment !== null && subField.manualCloseComment !== undefined) && subField.statusID === 3 ?
                                                                                        <button type="button" className={subField.manualCloseComment === '' || subField.manualCloseComment === null || subField.manualCloseComment === undefined ? "btn btn-xs btn-darkorange" : "btn btn-xs btn-success"}
                                                                                            title="View Approved Manual Close" onClick={() => handleChildManualclose(StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT, subField.taskType, subField.taskID, subField.childId, subField.statusID,
                                                                                                subField.manualCloseComment, subField.manualCloseDate)}
                                                                                        >
                                                                                            <i className="fa fa-user"></i>&nbsp;
                                                                                            <i className="fa fa-close"></i>
                                                                                        </button>
                                                                                        : ''
                                                                                }

                                                                                {
                                                                                    subField.taskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT && subField.statusID === 3 && subField.isShowSubmitButton !== 0
                                                                                        && (subField.childActualEndDate !== 'Manual' && subField.childActualEndDate !== 'Existing') && subField.manualCloseComment === '' ?
                                                                                        <button type="button" className="btn btn-xs btn-success" title='View Approval'
                                                                                            onClick={() => OpenTrimApprovalpopup(subField.childId,
                                                                                                subField.statusID, subField.swatchStatus)}
                                                                                        >
                                                                                            <i className="fa fa-eye "></i>
                                                                                        </button> : ''
                                                                                }
                                                                            </td>



                                                                        </tr>

                                                                    </Fragment>
                                                                ))
                                                            }

                                                        </Fragment>
                                                    ))
                                                }
                                            </>
                                            :

                                            <tr><td colSpan="12" className='norecordfound'><span>No Records Found</span></td></tr>

                                    }
                                </tbody>
                            </table>
                        </div>
                    </form>

                </div>

            </div >


            {loader}

            {
                getTrimApppopup === true ?

                    <Modal dialogClassName="commonwidth" show={getTrimApppopup === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handleTrimApp(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {
                                    TaskStatus === 3 && editTrimApproval === false ?
                                        "View " : editTrimApproval === true ? 'Update ' : ''

                                }
                                Trim Approval
                                {
                                    TaskStatus === 3 ?
                                        <>
                                            {
                                                SwatchStatus !== 3 ?
                                                    <span title='Upload Approved Swatch Card' style={{ 'margin-left': '830px' }}>
                                                        <i class="fa fa-lg fa-upload" aria-hidden="true" onClick={() => setTrimswatchUpload(true)}></i>
                                                    </span>
                                                    : ''


                                            }
                                            &nbsp;
                                            {
                                                SwatchStatus === 3 ?
                                                    <span title='Edit Form' style={{ 'margin-left': '700px' }} onClick={() => seteditTrimApproval(true)}
                                                        className="btn btn-info btn-xs edit">
                                                        <i className="fa fa-edit"></i>
                                                    </span> :
                                                    <span title='Edit Form' onClick={() => seteditTrimApproval(true)}
                                                        className="btn btn-info btn-xs edit">
                                                        <i className="fa fa-edit"></i>
                                                    </span>

                                            }

                                            {/* <span title='Edit Form' style={{ 'margin-left': '700px' }} onClick={() => seteditTrimApproval(true)}
                                                className="btn btn-info btn-xs edit">
                                                <i className="fa fa-edit"></i>
                                            </span> */}
                                            &nbsp;
                                            <span title='Delete Form'
                                                className="btn btn-danger btn-xs delete" onClick={() => setdeleteTrimApproval(true)}>
                                                <i className="fa fa-trash-o"></i>
                                            </span>
                                        </> : ''
                                }
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <TrimApproval props={props}
                                TrimApprovalCallback={TrimApprovalCallback}
                                TNATrimRefInfoId={TNATrimRefInfoId}
                                SubmittedOptionListId={SubmittedOptionListId}
                                ColorAppCommentsId={ColorAppCommentsId}
                                TaskStatus={TaskStatus}
                                editTrimApproval={editTrimApproval}
                                deleteTrimApproval={deleteTrimApproval}
                                TrimswatchDownload={TrimswatchDownload}
                                TrimswatchUpload={TrimswatchUpload}
                                SwatchStatus={SwatchStatus}
                            ></TrimApproval>
                        </Modal.Body>
                        <Modal.Footer>

                        </Modal.Footer>

                    </Modal> : ''
            }

            {
                showmaunalclose === true ?
                    <Modal show={showmaunalclose === true} size="lg" dragable backdrop="static" keyboard={false} onHide={() => setshowmaunalclose(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                View Manual Close : Trim Approval(Local Development)
                                &nbsp; &nbsp;
                                {
                                    ChildId.Status === 3 &&
                                    <button type="button" className="btn btn-xs btn-danger" title="Delete Manual Close" onClick={() => handledelete()}>
                                        <i className="fa fa-trash-o"></i>
                                    </button>
                                }

                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                <thead>
                                    <tr>
                                        <th>Comments <span className="text-danger">*</span></th>
                                        <th>Close Date <span className="text-danger">*</span></th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td style={{ width: "600px" }} >
                                            <textarea
                                                rows="3"
                                                placeholder="Enter Comments here..."
                                                autoFocus onFocus={""} autoComplete="off"
                                                maxLength="500" name="EnterRemarks"
                                                id={"EnterRemarks"}
                                                value={Manualclosedata.ManualCloseComment}
                                                onChange={event => handleChangeIsManualCloseComments(event, "Comment")}
                                                style={{ width: "100%", border: getComment && Manualclosedata.ManualCloseComment === '' ? '1px solid red' : '' }}
                                                disabled
                                            >

                                            </textarea>
                                        </td>
                                        <td>
                                            <DatePicker className="form-control" name="HolidayDate"
                                                id={
                                                    "HolidayDate"
                                                }
                                                selected={
                                                    Manualclosedata.ManualCloseDate
                                                }
                                                onChange={event => handleChangeIsManualCloseComments(event, "CommentDate")}
                                                dateFormat="dd/MM/yyyy"
                                                peekNextMonth
                                                showMonthDropdown
                                                // showYearDropdown
                                                dropdownMode="scroll"
                                                autoComplete="off"
                                                minDate={new Date()}
                                                maxDate={new Date()}
                                                // style={{ border: getComment && subField.ManualCloseDate === '' ? '1px solid red' : '' }}
                                                customInput={<CustomInputman />}
                                                // isClearable={inputField.HolidayDate === '' ? false : true}
                                                disabled
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Style Name</th>
                                        <th>Style No</th>
                                        <th>ID Po</th>
                                        <th>Details</th>
                                        <th>Task Holder / Follower</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    {

                                        MultiCloseDatas.map((item, index) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <td>{item.styleName}</td>
                                                        <td>{item.styleNo}</td>
                                                        <td>{item.idPoNo}</td>
                                                        <td>{item.details}</td>
                                                        <td>{item.taskOwnerNameList}</td>

                                                    </tr>
                                                </>

                                            )
                                        })
                                    }

                                </tbody>
                            </table>
                        </Modal.Body>
                        <Modal.Footer>
                            {/* {
                                subField.statusID !== 3 &&
                                <Button variant="success">
                                    Save
                                </Button>


                            } */}


                        </Modal.Footer>
                    </Modal> : ''
            }
            {getsID.showPopupDelete ? <SweetAlertPopup

                // data={''}
                data={
                    getsID.Params
                }
                deleteCallback={DeleteCallback}
                showpopup={true}
                pageActionId={getsID.TNAManualClose}
                Msg={'Deleted Successfully'}
            /> : null}
        </Fragment >

    )
}

export default React.memo(TrimViewApprovalTab);