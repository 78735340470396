import {
    TESTITEMS_LOADING,
    RETRIEVE_TESTITEMS,
    DELETE_TESTITEMS
}

    from "./types"


import TestingReportDetail from "../services/Master/TestingReportDetail"

const itemLoading = (isStatus) => ({
    type: TESTITEMS_LOADING,
    payload: isStatus,
});

export const createItem = (getItem) => async (dispatch) => {

    let resData = "";
    await TestingReportDetail.AddTestingItem(getItem).then((res) => {
        resData = res.data;

    }).catch((err) => { return Promise.reject(err); });
    return Promise.resolve(resData);
}


export const deleteTestingItem = (TestingItemID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_TESTITEMS,
            payload: TestingItemID,
        });

    } catch (err) {
        console.log(err);
    }
};


export const retriveItem = () => async (dispatch) => {
    try {
        dispatch(itemLoading(true));
        const res = await TestingReportDetail.getAllTestingItemList();
        dispatch({
            type: RETRIEVE_TESTITEMS,
            payload: res.data,
        });
        dispatch(itemLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(itemLoading(false));
    }
};
