import input from "react-validation/build/input";
import React, { useState, Fragment, useEffect } from "react";
import Form from "react-validation/build/form";
import "react-notifications-component/dist/theme.css";
import DatePicker from "react-datepicker";
import Nodify from "../Common/ReactNotification";
import "react-datepicker/dist/react-datepicker.css";
import Lightbox from "react-image-lightbox";
import $ from "jquery";
import { useSelector } from "react-redux";
import Reactselect from 'react-select';
import StrikeoffService from "../../services/Style/StrikeoffService";
import TnaService from "../../services/TNA/TNAService";
import axios from "axios";
import SweetAlertPopup from '../Common/SweetAlertPopup';
import { AppareanceTestId } from "../Common/PageActionNumber";
const AfterWash = (props) => {
  const { user: currentUser } = useSelector((state) => state.auth);


  const [submitted, setSubmitted] = useState(false);
  const [getHeader, setHeader] = useState("Add");
  const [getFiles, setFiles] = useState([]);
  const [isOpenimg, setIsOpenimg] = useState(false);
  const [getImagename, setImagename] = useState();
  const [showawb, setshowawb] = useState(false);
  //const [getOrderinfo, setOrderinfo] = useState([]);
  const [ShowPopup, setShowPopup] = useState(false);
  const [reult, setreult] = useState([]);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [getSizeListmeasurement, setSizeListmeasurement] = useState([]);
  const [SaveMode, setSaveMode] = useState([]);
  const [getID, setID] = useState({ showPopupDelete: false, Params: {} });


  //AddExpand

  const [IsCatogory, setCatogory] = useState(true);


  const [inputFields, setInputFields] = useState([{
    BrandID: 0, SeasonID: 0, BuyerID: 0, SupplierID: 0, SkuID: 0, BuyerBrandSeasonName: '',
    SupplierName: '', StyleName: '', StyleNo: '', SkuName: '', IdpoNo: 0, GarmentSize: 0,
    SupplierRefNo: '', TestStartDate: '', TestEndDate: '', Noofwashes: 0, WashingCondition: 0, AWBDateandNo: '',
    DryerCondition: 0, SkuImagePath: '', LabComments: '', Remarks: '', TNASmsInfoId: 0,
    ApperanceFormInfoId: 0,

    Category: [{
      CategoryType: 1,
      AppearanceAfterWash: [{
        TestName: 'Appearance After Wash', Standard: 'BS EN ISO 5077:2008/BS EN ISO 6330:2012-4M', Conclusion: '',
        AppearanceAfterWashDetails: [
          { Detail: 'Colour Change', Required: 0, Observation: 0, CategoryType: 1, Result: 0, RequiredVal: '' },
          { Detail: 'Cross Staining', Required: 0, Observation: 0, CategoryType: 1, Result: 0, RequiredVal: '' },
          { Detail: 'Pilling', Required: 10, Observation: 0, CategoryType: 1, Result: 0, RequiredVal: 'No Change(s)' },
          { Detail: 'Print Durability', Required: 10, Observation: 0, CategoryType: 1, Result: 0, RequiredVal: 'No Change(s)' },
          { Detail: 'Seam Performance', Required: 10, Observation: 0, CategoryType: 1, Result: 0, RequiredVal: 'No Change(s)' },
          { Detail: 'Accessories, Care Label', Required: 10, Observation: 0, CategoryType: 1, Result: 0, RequiredVal: 'No Change(s)' },
          { Detail: 'Shape', Required: 10, Observation: 0, CategoryType: 1, Result: 0, RequiredVal: 'No Change(s)' }
        ]

      }]
      ,
      DimensionalStablitytoWashing: [{
        TestName: 'Dimensional Stablity to Washing', Standard: 'BS EN ISO 5077:2008 / BS EN ISO 3759:2011 /BS EN ISO 6330:2012 -4M',
        Required: 5, Conclusion: '',
        DimensionalStablitytoWashingDetails: [
          { Detail: 'Length (Central Back)', BeforeWash: '', AfterWash: '', Changein: '', CategoryType: 1, Result: 0 },
          { Detail: 'Length (Front at HPS)', BeforeWash: '', AfterWash: '', Changein: '', CategoryType: 1, Result: 0 },
          { Detail: 'Chest', BeforeWash: '', AfterWash: '', Changein: '', CategoryType: 1, Result: 0 },
          { Detail: 'Hem', BeforeWash: '', AfterWash: '', Changein: '', CategoryType: 1, Result: 0 },
          { Detail: 'Sleeve Length', BeforeWash: '', AfterWash: '', Changein: '', CategoryType: 1, Result: 0 },
        ]

      }],
      Spirality: [{
        TestName: 'Spirality', Standard: 'BS EN ISO 5077:2008 / BS EN ISO 3759:2011 / BS EN ISO 6330:2012 - 4M',
        Required: 5, Conclusion: '',
        SpiralityDetails: [
          { Detail: 'Arm Hole to Hem Width', BeforeWash: '', BeforeWashChangein: '', AfterWash: '', AfterWashChangein: '', CategoryType: 1, Result: 0 },
          { Detail: 'Deviation', BeforeWash: '', BeforeWashChangein: '', AfterWash: '', AfterWashChangein: '', CategoryType: 1, Result: 0 },
        ]

      }]
    }]


  }]);




  const [Emptyfeilds, setEmptyfeilds] = useState([{
    BrandID: 0, SeasonID: 0, BuyerID: 0, SupplierID: 0, SkuID: 0, BuyerBrandSeasonName: '',
    SupplierName: '', StyleName: '', StyleNo: '', SkuName: '', IdpoNo: 0, GarmentSize: 0,
    SupplierRefNo: '', TestStartDate: '', TestEndDate: '', Noofwashes: 0, WashingCondition: 0, AWBDateandNo: '',
    DryerCondition: 0, SkuImagePath: '', LabComments: '', Remarks: '', TNASmsInfoId: 0,
    Category: []
  }]);




  const values = [...inputFields]
  const filevals = [...getFiles]

  const Result = [
    { key: 1, value: 'PASS' },
    { key: 2, value: 'FAIL' },

  ]

  const Required = [
    { value: 1, label: '5 (Grade)' },
    { value: 2, label: '4-5 (Grade)' },
    { value: 3, label: '4 (Grade)' },
    { value: 4, label: '3-4 (Grade)' },
    { value: 5, label: '3 (Grade)' },
    { value: 6, label: '2-3 (Grade)' },
    { value: 7, label: '2 (Grade)' },
    { value: 8, label: '1-2 (Grade)' },
    { value: 9, label: '1 (Grade)' },
    { value: 10, label: 'No Change(s)' }

  ]

  const Observation = [
    { value: 1, label: '5 (Grade)' },
    { value: 2, label: '4-5 (Grade)' },
    { value: 3, label: '4 (Grade)' },
    { value: 4, label: '3-4 (Grade)' },
    { value: 5, label: '3 (Grade)' },
    { value: 6, label: '2-3 (Grade)' },
    { value: 7, label: '2 (Grade)' },
    { value: 8, label: '1-2 (Grade)' },
    { value: 9, label: '1 (Grade)' },
    { value: 10, label: 'No Change(s)' },
    { value: 11, label: 'Changes Observed' }

  ]

  let styles1 = {
    control: styles1 => ({ ...styles1 }),
  }

  let styles2 = {
    control: styles2 => ({ ...styles2, border: '1px solid red' }),
  }

  useEffect(() => {
    if (props.deleteAppearanceAfterWash === true) {
      setID({ showPopupDelete: true, Params: props.AppareanceTestId });
    }
    else if (props.editAppearanceAfterWash === true) {

      let getDateValue = new Date(values[0].TestStartDate);
      var dd = getDateValue.getDate();
      var mm = getDateValue.getMonth() + 1;
      var yyyy = getDateValue.getFullYear();

      if (dd < 10) {
        dd = '0' + dd;
      }
      if (mm < 10) {
        mm = '0' + mm;
      }
      values[0].TestStartDate = getDateValue;

      let getendDateValue = new Date(values[0].TestEndDate);
      var edd = getendDateValue.getDate();
      var emm = getendDateValue.getMonth() + 1;
      var eyyyy = getendDateValue.getFullYear();
     
      if (edd < 10) {
        edd = '0' + edd;
      }
      if (emm < 10) {
        emm = '0' + emm;
      }
      values[0].TestEndDate = getendDateValue;

      setInputFields(values);

    }
    else {


      let Optionvalue = [];
      Result.forEach(element => {
        let LabelColor = '';
        if (element.value === 'PASS') {
          LabelColor = <><span style={{ color: "#00b050" }}><b>PASS</b></span></>
        }
        else if (element.value === 'FAIL') {
          LabelColor = <><span style={{ color: "#c40404" }}><b>FAIL</b></span></>
        }
        Optionvalue.push({
          value: element.key,
          label: LabelColor,
          subLabel: element.value,
        })
      });

      setreult(Optionvalue);

      StrikeoffService.LoadGroupSizeDetailsList(props.StyleId).then((response) => {
        if (response.data) {
          setSizeListmeasurement(response.data);

        }
      }).catch(() => { });

      TnaService.GetEditAppearenceTestInfo(props.AppareanceTestId).then((response) => {
        if (response.data) {
          if (response.data.apperanceTestBasicDetails.length === 0) {
            setSaveMode('Save');
            TnaService.AppearenceTestList(props.AppareanceTestId).then((response) => {
              if (response.data) {
                values[0].TNAID = response.data[0].tnaId;
                values[0].BrandID = response.data[0].brandID;
                values[0].BuyerID = response.data[0].buyerID;
                values[0].SeasonID = response.data[0].seasonID;
                values[0].SupplierID = response.data[0].supplierID;
                values[0].SupplierName = response.data[0].supplierName;
                values[0].BuyerBrandSeasonName = response.data[0].buyerBrandSeasonName;
                values[0].StyleID = response.data[0].styleId;
                values[0].StyleName = response.data[0].styleName;
                values[0].StyleNo = response.data[0].styleNo;
                values[0].IdpoNo = response.data[0].idpoNo;
                values[0].SkuID = response.data[0].skuId;
                values[0].SkuName = response.data[0].skuName;
                values[0].SkuImagePath = response.data[0].skuImagePath;
                values[0].TNASmsInfoId = props.AppareanceTestId;

                setInputFields(values);
              }
            }).catch(() => { });
          }
          else {
            // if (response.data.apperanceTestBasicDetails[0].testEndDate !== '') {
            setSaveMode('Edit');
            // }

           ;
            Emptyfeilds[0].TNAID = response.data.apperanceTestBasicDetails[0].tnaid;
            Emptyfeilds[0].BrandID = response.data.apperanceTestBasicDetails[0].brandID;
            Emptyfeilds[0].BuyerID = response.data.apperanceTestBasicDetails[0].buyerID;
            Emptyfeilds[0].SeasonID = response.data.apperanceTestBasicDetails[0].seasonID;
            Emptyfeilds[0].SupplierID = response.data.apperanceTestBasicDetails[0].supplierID;
            Emptyfeilds[0].SupplierName = response.data.apperanceTestBasicDetails[0].supplierName;
            Emptyfeilds[0].BuyerBrandSeasonName = response.data.apperanceTestBasicDetails[0].buyerBrandSeasonName;
            Emptyfeilds[0].StyleID = response.data.apperanceTestBasicDetails[0].styleId;
            Emptyfeilds[0].StyleName = response.data.apperanceTestBasicDetails[0].styleName;
            Emptyfeilds[0].StyleNo = response.data.apperanceTestBasicDetails[0].styleNo;
            Emptyfeilds[0].IdpoNo = response.data.apperanceTestBasicDetails[0].idPoNo;
            Emptyfeilds[0].SkuID = response.data.apperanceTestBasicDetails[0].skuId;
            Emptyfeilds[0].SkuName = response.data.apperanceTestBasicDetails[0].skuName;
            Emptyfeilds[0].SkuImagePath = response.data.apperanceTestBasicDetails[0].skuImagePath;
            Emptyfeilds[0].TNASmsInfoId = props.AppareanceTestId;
            Emptyfeilds[0].GarmentSize = response.data.apperanceTestBasicDetails[0].garmentSize;
            Emptyfeilds[0].SupplierRefNo = response.data.apperanceTestBasicDetails[0].supplierRefNo;
            let startdate = new Date(response.data.apperanceTestBasicDetails[0].testStartDate);

            var dd = startdate.getDate();
            var mm = startdate.getMonth() + 1;
            var yyyy = startdate.getFullYear();

            if (dd < 10) {
              dd = '0' + dd;
            }
            if (mm < 10) {
              mm = '0' + mm;
            }
            // let coverted = yyyy + '-' + mm + '-' + dd;
            let coverted = mm + '/' + dd + '/' + yyyy;



            Emptyfeilds[0].TestStartDate = startdate;
            if (response.data.apperanceTestBasicDetails[0].testEndDate !== null) {
              let enddate = new Date(response.data.apperanceTestBasicDetails[0].testEndDate);
              var edd = enddate.getDate();
              var emm = enddate.getMonth() + 1;
              var eyyyy = enddate.getFullYear();

              if (edd < 10) {
                edd = '0' + edd;
              }
              if (emm < 10) {
                emm = '0' + emm;
              }
              // let covertedenddate = eyyyy + '-' + emm + '-' + edd;
              let covertedenddate = emm + '/' + edd + '/' + eyyyy;
              Emptyfeilds[0].TestEndDate = enddate;
            }
            else {

            }

            Emptyfeilds[0].Noofwashes = response.data.apperanceTestBasicDetails[0].noOfWashes;
            Emptyfeilds[0].WashingCondition = response.data.apperanceTestBasicDetails[0].washingCondition;
            Emptyfeilds[0].DryerCondition = response.data.apperanceTestBasicDetails[0].dryerCondition;
            Emptyfeilds[0].LabComments = response.data.apperanceTestBasicDetails[0].labComments;
            Emptyfeilds[0].ApperanceFormInfoId = response.data.apperanceTestBasicDetails[0].apperanceFormInfoId;
            Emptyfeilds[0].Remarks = response.data.apperanceTestBasicDetails[0].remarks;



            if (response.data.tnaApperanceTestCategoryTypes.length > 0) {
              response.data.tnaApperanceTestCategoryTypes.forEach((catelement, catindex) => {
                Emptyfeilds[0].Category.push({
                  CategoryType: catelement.categoryType
                })
                Emptyfeilds[0].Category[catindex].AppearanceAfterWash = [];
                if (response.data.tnaApperanceTestInformation.length !== 0) {
                  response.data.tnaApperanceTestInformation.filter(x => x.categoryType === catelement.categoryType).forEach((element, testinfoindex) => {
                    Emptyfeilds[0].Category[catindex].AppearanceAfterWash.push({
                      ApperanceTestId: element.apperanceTestId,
                      TestName: element.testName,
                      Standard: element.testStandard,
                      Conclusion: element.conclusion,
                    })
                    Emptyfeilds[0].Category[catindex].AppearanceAfterWash[testinfoindex].AppearanceAfterWashDetails = [];
                    if (response.data.tnaApperanceTestDetailInformation.length !== 0) {
                      response.data.tnaApperanceTestDetailInformation.filter(x => x.categoryType === element.categoryType).forEach(element => {
                        Emptyfeilds[0].Category[catindex].AppearanceAfterWash[testinfoindex].AppearanceAfterWashDetails.push({
                          ApperanceTestDetailId: element.apperanceTestDetailId,
                          Detail: element.detailsName,
                          Required: element.detailsRequired,
                          Observation: element.observation,
                          CategoryType: catelement.categoryType
                        })
                      })
                    }
                  })
                }
                Emptyfeilds[0].Category[catindex].DimensionalStablitytoWashing = [];
                if (response.data.tnaDimensionalStablityInformation.length !== 0) {
                  response.data.tnaDimensionalStablityInformation.filter(x => x.categoryType === catelement.categoryType).forEach((element, testinfoindex) => {
                    Emptyfeilds[0].Category[catindex].DimensionalStablitytoWashing.push({
                      DimensionalInfoId: element.dimensionalInfoId,
                      TestName: element.testName,
                      Standard: element.testStandard,
                      Required: element.detailsRequired,
                      Conclusion: element.conclusion,
                    })
                    Emptyfeilds[0].Category[catindex].DimensionalStablitytoWashing[testinfoindex].DimensionalStablitytoWashingDetails = [];
                    if (response.data.tnaDimensionalStablityDetailInformation.length !== 0) {
                      response.data.tnaDimensionalStablityDetailInformation.filter(x => x.categoryType === element.categoryType).forEach(element => {
                        Emptyfeilds[0].Category[catindex].DimensionalStablitytoWashing[testinfoindex].DimensionalStablitytoWashingDetails.push({
                          DimensionalDetailId: element.dimensionalDetailId,
                          Detail: element.detailsName,
                          BeforeWash: element.beforeWash,
                          AfterWash: element.afterWash === 0 ? '' : element.afterWash,
                          Changein: element.changeIn === 0 ? 0 : element.changeIn,
                          CategoryType: catelement.categoryType
                        })
                      })
                    }
                  })
                }
                Emptyfeilds[0].Category[catindex].Spirality = [];
                if (response.data.tnaSpiralityInformation.length !== 0) {
                  response.data.tnaSpiralityInformation.filter(x => x.categoryType === catelement.categoryType).forEach((element, testinfoindex) => {
                    Emptyfeilds[0].Category[catindex].Spirality.push({
                      SpiralityInfoId: element.spiralityInfoId,
                      TestName: element.testName,
                      Standard: element.testStandard,
                      Required: element.detailsRequired,
                      Conclusion: element.conclusion,
                    })
                    Emptyfeilds[0].Category[catindex].Spirality[testinfoindex].SpiralityDetails = [];
                    if (response.data.tnaSpiralityDetailInformation.length !== 0) {
                      response.data.tnaSpiralityDetailInformation.filter(x => x.categoryType === element.categoryType).forEach(element => {
                        Emptyfeilds[0].Category[catindex].Spirality[testinfoindex].SpiralityDetails.push({
                          SpiralityDetailId: element.spiralityDetailId,
                          Detail: element.detailsName,
                          BeforeWash: element.beforeWash,
                          BeforeWashChangein: element.beforeWashChangeIn,
                          AfterWash: element.afterWash === 0 ? '' : element.afterWash,
                          AfterWashChangein: element.afterWashChangeIn === 0 ? '' : element.afterWashChangeIn,
                          CategoryType: catelement.categoryType
                        })
                      })
                    }
                  })
                }


                if (response.data.tnaApperanceFileInformation.length !== 0) {
                  response.data.tnaApperanceFileInformation.forEach((element) => {
                    filevals.push({
                      ApperanceFileID: element.apperanceFileID,
                      FileName: element.fileName,
                      FilePath: element.filePath,
                      FileType: element.fileType
                    })
                    setFiles(filevals);

                  })
                }
              })

            }

            setInputFields(Emptyfeilds);
          }
        }

      }).catch(() => { });
    }


  }, [props.deleteAppearanceAfterWash === true || props.editAppearanceAfterWash === true])

  let DynamicClassName = "col-lg-12 col-sm-12 col-xs-12";




  const AppendFiles = async e => {
    const file = e.target.files[0];
    if (file !== null && file !== '' && file !== undefined) {
      const fileType = file['type'];
      const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword', "application/vnd.ms-excel",
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
      if (!validImageTypes.includes(fileType)) {
        Nodify('Warning!', 'warning', 'Invalid file format selected');
        $('#FileUpload').val("");
      }
      else {
        const formData = new FormData();
        formData.append("FormFile", file);
        formData.append("FileName", file.name);
        formData.append("Page", "TNA");
        try {
          const res = await axios.post(window.$APIBaseURL + "api/file", formData);
          filevals.push({
            ApperanceFileID: 0,
            FileName: file.name,
            FilePath: "Images/TNAImages/" + file.name,
            FileType: file.type
          })
          setFiles(filevals);
          $('#FileUpload').val("");

        } catch (ex) {
          console.log(ex);
        }
      }
    }
  }

  const Addcategory = (index) => {

    const sample = [...inputFields]
    sample[index].Category.push({
      CategoryType: 0, AppearanceAfterWash: [], DimensionalStablitytoWashing: [], Spirality: []
    })
    setInputFields(sample);

  }
  const handleChangedate = (event, feild) => { }
  // const handleChangedate = (event, feild) => {
  //  
  //   if (feild === "TestStartDate") {
  //     if (new Date(event.target.value) >= new Date(values[0].TestEndDate)) {
  //       values[0].TestEndDate = '';
  //     }

  //   }

  //   setInputFields(values);
  // }

  const handleChangeBasicDetails = (event, feild) => {
    //;8
   ;
    let input = '';


    if (feild === "GarmentSize") {
      if (event.target.value.trim() !== '') {
        input = event.target.value;
      }
      values[0].GarmentSize = parseInt(input);;
    }
    else if (feild === "SupplierRef") {
      if (event.target.value.trim() !== '') {
        input = event.target.value;
      }
      values[0].SupplierRefNo = input;
    }
    else if (feild === "TestStartDate") {
      if (new Date(event) >= new Date(values[0].TestEndDate)) {
        values[0].TestEndDate = '';
        values[0].TestStartDate = event;
      } else {
        values[0].TestStartDate = event;
      }
    }
    // else if (feild === "TestEndDate") {
    //   values[0].TestEndDate = input;
    // }

    else if (feild === "TestEndDate") {
      if (new Date(event) >= new Date(values[0].TestStartDate)) {

        values[0].TestEndDate = event;
      }
      else {
        values[0].TestEndDate = '';
        Nodify('Warning!', 'warning', 'End Date Should not less than Start Date.');
        //return false;
      }
    }
    //   else if (feild === "TestEndDate" && values[0].TestEndDate < values[0].TestStartDate ){
    //   Nodify("Warning!", "warning", "End Date Should not less than Start Date1.");
    // }




    else if (feild === "Noofwashes") {
      if (event.target.value.trim() !== '') {
        input = event.target.value;
      }
      values[0].Noofwashes = parseInt(input);;
    }
    else if (feild === "WashingCondition") {
      if (event.target.value.trim() !== '') {
        input = event.target.value;
      }
      values[0].WashingCondition = parseInt(input);;
    }
    else if (feild === "DryerCondition") {
      if (event.target.value.trim() !== '') {
        input = event.target.value;
      }
      values[0].DryerCondition = parseInt(input);
    }
    else if (feild === "LabComments") {
      if (event.target.value.trim() !== '') {
        input = event.target.value;
      }
      values[0].LabComments = input;
    }
    else if (feild === "Remarks") {
      if (event.target.value.trim() !== '') {
        input = event.target.value;
      }
      values[0].Remarks = input;
    }


    setInputFields(values);
  }

  const handlechangeCategory = (event, index) => {
   ;
    setSubmitted(false);
    // let inputText = '';
    const sample = [...inputFields]
    let Isvalid = true;
    for (var j = 0; j < (sample[0].Category.length); j++) {
      if (j !== index) {
        if (parseInt(sample[0].Category[j].CategoryType) === parseInt(event.target.value)
        ) {
          Isvalid = false;
        }
      }
    }
    sample[0].Category[index].CategoryType = event.target.value;
    if (Isvalid) {
      if (event.target.value === "1") {
        sample[0].Category[index].AppearanceAfterWash = [];

        sample[0].Category[index].AppearanceAfterWash.push(
          {
            TestName: 'Appearance After Wash', Standard: 'BS EN ISO 5077:2008/BS EN ISO 6330:2012-4M', Conclusion: '',
            AppearanceAfterWashDetails: [
              { Detail: 'Colour Change', Required: '', Observation: '', CategoryType: 1, Result: 0, RequiredVal: '' },
              { Detail: 'Cross Staining', Required: '', Observation: '', CategoryType: 1, Result: 0, RequiredVal: '' },
              { Detail: 'Pilling', Required: 10, Observation: '', CategoryType: 1, Result: 0, RequiredVal: 'No Change(s)' },
              { Detail: 'Print Durability', Required: 10, Observation: '', CategoryType: 1, Result: 0, RequiredVal: 'No Change(s)' },
              { Detail: 'Seam Performance', Required: 10, Observation: '', CategoryType: 1, Result: 0, RequiredVal: 'No Change(s)' },
              { Detail: 'Accessories, Care Label', Required: 10, Observation: '', CategoryType: 1, Result: 0, RequiredVal: 'No Change(s)' },
              { Detail: 'Shape', Required: 10, Observation: '', CategoryType: 1, Result: 0, RequiredVal: 'No Change(s)' }
            ]
          }

        )

        sample[0].Category[index].DimensionalStablitytoWashing = [];
        sample[0].Category[index].DimensionalStablitytoWashing.push(
          {
            TestName: 'Dimensional Stablity to Washing', Standard: 'BS EN ISO 5077:2008 / BS EN ISO 3759:2011 /BS EN ISO 6330:2012 -4M',
            Required: 5, Conclusion: '',
            DimensionalStablitytoWashingDetails: [
              { Detail: 'Length (Central Back)', BeforeWash: '', AfterWash: '', Changein: '', CategoryType: 1, Result: 0 },
              { Detail: 'Length (Front at HPS)', BeforeWash: '', AfterWash: '', Changein: '', CategoryType: 1, Result: 0 },
              { Detail: 'Chest', BeforeWash: '', AfterWash: '', Changein: '', CategoryType: 1, Result: 0 },
              { Detail: 'Hem', BeforeWash: '', AfterWash: '', Changein: '', CategoryType: 1, Result: 0 },
              { Detail: 'Sleeve Length', BeforeWash: '', AfterWash: '', Changein: '', CategoryType: 1, Result: 0 },
            ]
          }

        )
        sample[0].Category[index].Spirality = [];
        sample[0].Category[index].Spirality.push(
          {
            TestName: 'Spirality', Standard: 'BS EN ISO 5077:2008 / BS EN ISO 3759:2011 / BS EN ISO 6330:2012 - 4M',
            Required: 5, Conclusion: '',
            SpiralityDetails: [
              { Detail: 'Arm Hole to Hem Width', BeforeWash: '', BeforeWashChangein: '', AfterWash: '', AfterWashChangein: '', CategoryType: 1, Result: 0 },
              { Detail: 'Deviation', BeforeWash: '', BeforeWashChangein: '', AfterWash: '', AfterWashChangein: '', CategoryType: 1, Result: 0 },
            ]
          }

        )
      }

      else if (event.target.value === "2") {
        sample[0].Category[index].AppearanceAfterWash = [];

        sample[0].Category[index].AppearanceAfterWash.push(
          {
            TestName: 'Appearance After Wash', Standard: 'BS EN ISO 5077:2008/BS EN ISO 6330:2012-4M', Conclusion: 0,
            AppearanceAfterWashDetails: [
              { Detail: 'Colour Change', Required: 0, Observation: 0, CategoryType: 2, Result: 0 },
              { Detail: 'Cross Staining', Required: 0, Observation: 0, CategoryType: 2, Result: 0 },
              { Detail: 'Pilling', Required: 9, Observation: 0, CategoryType: 2, Result: 0 },
              { Detail: 'Print Durability', Required: 9, Observation: 0, CategoryType: 2, Result: 0 },
              { Detail: 'Seam Performance', Required: 9, Observation: 0, CategoryType: 2, Result: 0 },
              { Detail: 'Accessories, Care Label', Required: 9, Observation: 0, CategoryType: 2, Result: 0 },
              { Detail: 'Shape', Required: 9, Observation: 0, CategoryType: 2, Result: 0 }
            ]
          }

        )

        sample[0].Category[index].DimensionalStablitytoWashing = [];
        sample[0].Category[index].DimensionalStablitytoWashing.push(
          {
            TestName: 'Dimensional Stablity to Washing', Standard: 'BS EN ISO 5077:2008 / BS EN ISO 3759:2011 /BS EN ISO 6330:2012 -4M',
            Required: 5, Conclusion: 0,
            DimensionalStablitytoWashingDetails: [
              { Detail: 'Waist', BeforeWash: '', AfterWash: '', Changein: '', CategoryType: 2, Result: 0 },
              { Detail: 'Front Rise', BeforeWash: '', AfterWash: '', Changein: '', CategoryType: 2, Result: 0 },
              { Detail: 'Back Rise', BeforeWash: '', AfterWash: '', Changein: '', CategoryType: 2, Result: 0 },
              { Detail: 'Inner Leg', BeforeWash: '', AfterWash: '', Changein: '', CategoryType: 2, Result: 0 },
              { Detail: 'Leg Open', BeforeWash: '', AfterWash: '', Changein: '', CategoryType: 2, Result: 0 },
            ]
          }

        )
        sample[0].Category[index].Spirality = [];
        sample[0].Category[index].Spirality.push(
          {
            TestName: 'Spirality', Standard: 'BS EN ISO 5077:2008 / BS EN ISO 3759:2011 / BS EN ISO 6330:2012 - 4M',
            Required: 5, Conclusion: 0,
            SpiralityDetails: [
              { Detail: 'Inseam to leg open', BeforeWash: '', BeforeWashChangein: '', AfterWash: '', AfterWashChangein: '', CategoryType: 2, Result: 0 },
              { Detail: 'Deviation', BeforeWash: '', BeforeWashChangein: '', AfterWash: '', AfterWashChangein: '', CategoryType: 2, Result: 0 },
            ]
          }

        )
      }
      else if (event.target.value === "3") {
        sample[0].Category[index].AppearanceAfterWash = [];
        sample[0].Category[index].AppearanceAfterWash.push(
          {
            TestName: 'Appearance After Wash', Standard: 'BS EN ISO 5077:2008/BS EN ISO 6330:2012-4M', Conclusion: 0,
            AppearanceAfterWashDetails: [
              { Detail: 'Colour Change', Required: 0, Observation: 0, CategoryType: 3, Result: 0 },
              { Detail: 'Cross Staining', Required: 0, Observation: 0, CategoryType: 3, Result: 0 },
              { Detail: 'Pilling', Required: 9, Observation: 0, CategoryType: 3, Result: 0 },
              { Detail: 'Print Durability', Required: 9, Observation: 0, CategoryType: 3, Result: 0 },
              { Detail: 'Seam Performance', Required: 9, Observation: 0, CategoryType: 3, Result: 0 },
              { Detail: 'Accessories, Care Label', Required: 9, Observation: 0, CategoryType: 3, Result: 0 },
              { Detail: 'Shape', Required: 9, Observation: 0, CategoryType: 3, Result: 0 }
            ]
          }

        )
        sample[0].Category[index].DimensionalStablitytoWashing = [];
        sample[0].Category[index].DimensionalStablitytoWashing.push(
          {
            TestName: 'Dimensional Stablity to Washing', Standard: 'BS EN ISO 5077:2008 / BS EN ISO 3759:2011 /BS EN ISO 6330:2012 -4M',
            Required: 5, Conclusion: 0,
            DimensionalStablitytoWashingDetails: [
              { Detail: 'Length (Central Back)', BeforeWash: '', AfterWash: '', Changein: '', CategoryType: 3, Result: 0 },
              { Detail: 'Length (Front at HPS)', BeforeWash: '', AfterWash: '', Changein: '', CategoryType: 3, Result: 0 },
              { Detail: 'Chest', BeforeWash: '', AfterWash: '', Changein: '', CategoryType: 3, Result: 0 },
              { Detail: 'Hem', BeforeWash: '', AfterWash: '', Changein: '', CategoryType: 3, Result: 0 },
              { Detail: 'Sleeve Length', BeforeWash: '', AfterWash: '', Changein: '', CategoryType: 3, Result: 0 },
              { Detail: 'Waist', BeforeWash: '', AfterWash: '', Changein: '', CategoryType: 3, Result: 0 },
              { Detail: 'Front Rise', BeforeWash: '', AfterWash: '', Changein: '', CategoryType: 3, Result: 0 },
              { Detail: 'Back Rise', BeforeWash: '', AfterWash: '', Changein: '', CategoryType: 3, Result: 0 },
              { Detail: 'Inner Leg', BeforeWash: '', AfterWash: '', Changein: '', CategoryType: 3, Result: 0 },
              { Detail: 'Leg Open', BeforeWash: '', AfterWash: '', Changein: '', CategoryType: 3, Result: 0 },
            ]
          }

        )
        sample[0].Category[index].Spirality = [];
        sample[0].Category[index].Spirality.push(
          {
            TestName: 'Spirality', Standard: 'BS EN ISO 5077:2008 / BS EN ISO 3759:2011 / BS EN ISO 6330:2012 - 4M',
            Required: 5, Conclusion: 0,
            SpiralityDetails: [
              { Detail: 'Arm Hole to Bottom', BeforeWash: '', BeforeWashChangein: '', AfterWash: '', AfterWashChangein: '', CategoryType: 3, Result: 0 },
              { Detail: 'Deviation', BeforeWash: '', BeforeWashChangein: '', AfterWash: '', AfterWashChangein: '', CategoryType: 3, Result: 0 },

            ]
          }
        )
      }
      else if (event.target.value === "4") {
        sample[0].Category[index].AppearanceAfterWash = [];
        sample[0].Category[index].AppearanceAfterWash.push(
          {
            TestName: 'Appearance After Wash', Standard: 'BS EN ISO 5077:2008/BS EN ISO 6330:2012-4M', Conclusion: 0,
            AppearanceAfterWashDetails: [
              { Detail: 'Colour Change', Required: 0, Observation: 0, CategoryType: 4, Result: 0 },
              { Detail: 'Cross Staining', Required: 0, Observation: 0, CategoryType: 4, Result: 0 },
              { Detail: 'Pilling', Required: 9, Observation: 0, CategoryType: 4, Result: 0 },
              { Detail: 'Print Durability', Required: 9, Observation: 0, CategoryType: 4, Result: 0 },
              { Detail: 'Seam Performance', Required: 9, Observation: 0, CategoryType: 4, Result: 0 },
              { Detail: 'Accessories, Care Label', Required: 9, Observation: 0, CategoryType: 4, Result: 0 },
              { Detail: 'Shape', Required: 9, Observation: 0, CategoryType: 4, Result: 0 }
            ]
          }

        )
        sample[0].Category[index].DimensionalStablitytoWashing = [];
        sample[0].Category[index].DimensionalStablitytoWashing.push(
          {
            TestName: 'Dimensional Stablity to Washing', Standard: 'BS EN ISO 5077:2008 / BS EN ISO 3759:2011 /BS EN ISO 6330:2012 -4M',
            Required: 5, Conclusion: 0,
            DimensionalStablitytoWashingDetails: [
              { Detail: 'Length (Central Back)', BeforeWash: '', AfterWash: '', Changein: '', CategoryType: 4, Result: 0 },
              { Detail: 'Length (Front at HPS)', BeforeWash: '', AfterWash: '', Changein: '', CategoryType: 4, Result: 0 },
              { Detail: 'Chest', BeforeWash: '', AfterWash: '', Changein: '', CategoryType: 4, Result: 0 },
              { Detail: 'Hem', BeforeWash: '', AfterWash: '', Changein: '', CategoryType: 4, Result: 0 },
              { Detail: 'Sleeve Length', BeforeWash: '', AfterWash: '', Changein: '', CategoryType: 4, Result: 0 },
            ]
          }

        )
        sample[0].Category[index].Spirality = [];
        sample[0].Category[index].Spirality.push(
          {
            TestName: 'Spirality', Standard: 'BS EN ISO 5077:2008 / BS EN ISO 3759:2011 / BS EN ISO 6330:2012 - 4M',
            Required: 5, Conclusion: 0,
            SpiralityDetails: [
              { Detail: 'Arm Hole to Hem Width', BeforeWash: '', BeforeWashChangein: '', AfterWash: '', AfterWashChangein: '', CategoryType: 4, Result: 0 },
              { Detail: 'Deviation', BeforeWash: '', BeforeWashChangein: '', AfterWash: '', AfterWashChangein: '', CategoryType: 4, Result: 0 },

            ]
          }
        )
      }
      else {
        sample[0].Category[index].AppearanceAfterWash = [];
        sample[0].Category[index].AppearanceAfterWash.push(
          {
            TestName: 'Appearance After Wash', Standard: 'BS EN ISO 5077:2008/BS EN ISO 6330:2012-4M', Conclusion: 0,
            AppearanceAfterWashDetails: [
              { Detail: 'Colour Change', Required: 0, Observation: 0, CategoryType: 5, Result: 0 },
              { Detail: 'Cross Staining', Required: 0, Observation: 0, CategoryType: 5, Result: 0 },
              { Detail: 'Pilling', Required: 9, Observation: 0, CategoryType: 5, Result: 0 },
              { Detail: 'Print Durability', Required: 9, Observation: 0, CategoryType: 5, Result: 0 },
              { Detail: 'Seam Performance', Required: 9, Observation: 0, CategoryType: 5, Result: 0 },
              { Detail: 'Accessories, Care Label', Required: 9, Observation: 0, CategoryType: 5, Result: 0 },
              { Detail: 'Shape', Required: 9, Observation: 0, CategoryType: 5, Result: 0 }
            ]
          }

        )
        sample[0].Category[index].DimensionalStablitytoWashing = [];
        sample[0].Category[index].DimensionalStablitytoWashing.push(
          {
            TestName: 'Dimensional Stablity to Washing', Standard: 'BS EN ISO 5077:2008 / BS EN ISO 3759:2011 /BS EN ISO 6330:2012 -4M',
            Required: 5, Conclusion: 0,
            DimensionalStablitytoWashingDetails: [
              { Detail: 'Waist', BeforeWash: '', AfterWash: '', Changein: '', CategoryType: 5, Result: 0 },
              { Detail: 'Front Rise', BeforeWash: '', AfterWash: '', Changein: '', CategoryType: 5, Result: 0 },
              { Detail: 'Back Rise', BeforeWash: '', AfterWash: '', Changein: '', CategoryType: 5, Result: 0 },
              { Detail: 'Inner Leg', BeforeWash: '', AfterWash: '', Changein: '', CategoryType: 5, Result: 0 },
              { Detail: 'Leg Open', BeforeWash: '', AfterWash: '', Changein: '', CategoryType: 5, Result: 0 },
            ]
          }

        )
        sample[0].Category[index].Spirality = [];
        sample[0].Category[index].Spirality.push(
          {
            TestName: 'Spirality', Standard: 'BS EN ISO 5077:2008 / BS EN ISO 3759:2011 / BS EN ISO 6330:2012 - 4M',
            Required: 5, Conclusion: 0,
            SpiralityDetails: [
              { Detail: 'Inseam to leg open', BeforeWash: '', BeforeWashChangein: '', AfterWash: '', AfterWashChangein: '', CategoryType: 5, Result: 0 },
              { Detail: 'Deviation', BeforeWash: '', BeforeWashChangein: '', AfterWash: '', AfterWashChangein: '', CategoryType: 5, Result: 0 },
            ]
          }

        )
      }

    }
    else {
      sample[0].Category[index].CategoryType = "";
      sample[0].Category[index].AppearanceAfterWash = [];
      sample[0].Category[index].DimensionalStablitytoWashing = [];
      sample[0].Category[index].Spirality = [];
      setInputFields(sample);
      Nodify('Warning!', 'warning', 'This Category is already exist.');
      return false;
    }
    setInputFields(sample);
  }

  const handlechangeAppearanceAfterWash = (e, parentindex, childindex, subchildindex, feild) => {
    if (e != null) {
      const values = [...inputFields]
      let inputtext = e.value;
      if (feild === "Required") {

        values[0].Category[parentindex].AppearanceAfterWash[childindex].AppearanceAfterWashDetails[subchildindex].Required = inputtext;
        values[0].Category[parentindex].AppearanceAfterWash[childindex].AppearanceAfterWashDetails[subchildindex].RequiredVal =
          e.label === "4-5 (Grade)" ? "4.5" : e.label === "3-4 (Grade)" ? "3.5" : e.label === "2-3 (Grade)" ? "2.5" :
            e.label === "1-2 (Grade)" ? "1.5" : e.label === "1 (Grade)" ? "1" : e.label === "2 (Grade)" ? "2" :
              e.label === "3 (Grade)" ? "3" : e.label === "4 (Grade)" ? "4" : e.label === "5 (Grade)" ? "5" : "0";;

        values[0].Category[parentindex].AppearanceAfterWash[childindex].AppearanceAfterWashDetails[subchildindex].Observation = '';

      }
      else if (feild === "Observation") {

        if (values[0].Category[parentindex].AppearanceAfterWash[childindex].AppearanceAfterWashDetails[subchildindex].Required !== 0) {
          if (values[0].Category[parentindex].AppearanceAfterWash[childindex].AppearanceAfterWashDetails[subchildindex].Required === 10) {
            if (inputtext === 10 || inputtext === 11) {
              values[0].Category[parentindex].AppearanceAfterWash[childindex].AppearanceAfterWashDetails[subchildindex].Observation = inputtext;
            }
            if (values[0].Category[parentindex].AppearanceAfterWash[childindex].AppearanceAfterWashDetails[subchildindex].Required ===
              inputtext
            ) {
              values[0].Category[parentindex].AppearanceAfterWash[childindex].AppearanceAfterWashDetails[subchildindex].Result = 1;
            }
            else {
              values[0].Category[parentindex].AppearanceAfterWash[childindex].AppearanceAfterWashDetails[subchildindex].Result = 0;
            }
            //  values[0].Category[parentindex].AppearanceAfterWash[childindex].AppearanceAfterWashDetails[subchildindex].Result = 1;
          }
          else {
            if (inputtext !== 10 && inputtext !== 11) {
              values[0].Category[parentindex].AppearanceAfterWash[childindex].AppearanceAfterWashDetails[subchildindex].Observation = inputtext;
              values[0].Category[parentindex].AppearanceAfterWash[childindex].AppearanceAfterWashDetails[subchildindex].Result = 1;
              let observed = e.label === "4-5 (Grade)" ? "4.5" : e.label === "3-4 (Grade)" ? "3.5" : e.label === "2-3 (Grade)" ? "2.5" :
                e.label === "1-2 (Grade)" ? "1.5" : e.label === "1 (Grade)" ? "1" : e.label === "2 (Grade)" ? "2" :
                  e.label === "3 (Grade)" ? "3" : e.label === "4 (Grade)" ? "4" : e.label === "5 (Grade)" ? "5" : "0";
              let required = values[0].Category[parentindex].AppearanceAfterWash[childindex].AppearanceAfterWashDetails[subchildindex].RequiredVal;

              if (parseFloat(observed) >= parseFloat(required)) {
                values[0].Category[parentindex].AppearanceAfterWash[childindex].AppearanceAfterWashDetails[subchildindex].Result = 1;
              }
              else {
                values[0].Category[parentindex].AppearanceAfterWash[childindex].AppearanceAfterWashDetails[subchildindex].Result = 0;
              }

            }
          }

        }
        else {
          Nodify('Warning!', 'warning', 'Select Required First.');
          return false;
        }
      }
      else if (feild === "Conclusion") {
        values[0].Category[parentindex].AppearanceAfterWash[childindex].Conclusion = e.value;
      }
      setInputFields(values);
      if (feild !== "Conclusion") {
        ApperaenceTestResult(parentindex, childindex);
      }
    }
    else {
      if (feild === "Required") {
        values[0].Category[parentindex].AppearanceAfterWash[childindex].AppearanceAfterWashDetails[subchildindex].Required = '';
        values[0].Category[parentindex].AppearanceAfterWash[childindex].AppearanceAfterWashDetails[subchildindex].RequiredVal = '';
      }
      else if (feild === "Observation") {
        values[0].Category[parentindex].AppearanceAfterWash[childindex].AppearanceAfterWashDetails[subchildindex].Observation = '';
      }
      else if (feild === "Conclusion") {
        values[0].Category[parentindex].AppearanceAfterWash[childindex].Conclusion = '';
      }
      setInputFields(values);
      if (feild !== "Conclusion") {
        ApperaenceTestResult(parentindex, childindex);
      }
    }

  }

  const ApperaenceTestResult = (parentindex, childindex) => {
    let fail = 0; let checkallRequiredfilled = true; let checkallObservationfilled = true;
    values[0].Category[parentindex].AppearanceAfterWash[childindex].AppearanceAfterWashDetails.forEach(element => {
      if (element.Required === 0) {
        checkallRequiredfilled = false;
      }
      if (element.Observation === 0) {
        checkallObservationfilled = false;
      }
    })
    if (checkallRequiredfilled !== false && checkallObservationfilled !== false) {
      values[0].Category[parentindex].AppearanceAfterWash[childindex].AppearanceAfterWashDetails.forEach(element => {
        if (element.Result === 0) {
          fail = 1;
        }
      })

      if (fail === 0) {
        values[0].Category[parentindex].AppearanceAfterWash[childindex].Conclusion = 1;
      }
      else {
        values[0].Category[parentindex].AppearanceAfterWash[childindex].Conclusion = 2;
      }

    }
    else {
      values[0].Category[parentindex].AppearanceAfterWash[childindex].Conclusion = '';
    }
    setInputFields(values);
  }

  const handlechangeDimensionalStablitytoWashing = (e, parentindex, childindex, subchildindex, feild) => {
    const values = [...inputFields]
    // var regex = /^[0-9 ]+$/;
    let isValid = true;
    if (feild === "BeforeWash") {
      if (isValid) {
        let inputtext = e.target.value;

        var re1 = /^([0-9]+[\.]?[0-9]?[0-9]?|[0-9]+)/g;
        let val = re1.exec(inputtext);
        values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].DimensionalStablitytoWashingDetails[subchildindex].BeforeWash = '';
        if (val !== null && e.target.value !== "") {
          values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].DimensionalStablitytoWashingDetails[subchildindex].BeforeWash = val[0];

          if (values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].DimensionalStablitytoWashingDetails[subchildindex].AfterWash !== '') {
            let changeinpercentage = 0;
            changeinpercentage = (values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].DimensionalStablitytoWashingDetails[subchildindex].AfterWash -
              values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].DimensionalStablitytoWashingDetails[subchildindex].BeforeWash) * 100 /
              values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].DimensionalStablitytoWashingDetails[subchildindex].BeforeWash;

            values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].DimensionalStablitytoWashingDetails[subchildindex].Changein = Math.round(changeinpercentage).toFixed(1);

            //result
            let required = values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].Required === '' ? 0 :
              values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].Required;

            if (parseFloat(values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].DimensionalStablitytoWashingDetails[subchildindex].Changein) <= parseFloat(required)) {
              values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].DimensionalStablitytoWashingDetails[subchildindex].Result = 1;
            }
            else {
              values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].DimensionalStablitytoWashingDetails[subchildindex].Result = 0;
            }
          }
        }
        else {
          values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].DimensionalStablitytoWashingDetails[subchildindex].Changein = '';
        }


      }
    }
    else if (feild === "AfterWash") {
      let inputtext = e.target.value;
      let re1 = /^([0-9]+[\.]?[0-9]?[0-9]?|[0-9]+)/g;
      let val = re1.exec(inputtext);
      values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].DimensionalStablitytoWashingDetails[subchildindex].AfterWash = '';
      if (val !== null && e.target.value !== "") {
        if (values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].DimensionalStablitytoWashingDetails[subchildindex].BeforeWash !== '') {
          values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].DimensionalStablitytoWashingDetails[subchildindex].AfterWash = val[0];

          let changeinpercentage = 0;

          changeinpercentage = (values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].DimensionalStablitytoWashingDetails[subchildindex].AfterWash -
            values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].DimensionalStablitytoWashingDetails[subchildindex].BeforeWash) * 100 /
            values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].DimensionalStablitytoWashingDetails[subchildindex].BeforeWash;

          values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].DimensionalStablitytoWashingDetails[subchildindex].Changein = Math.round(changeinpercentage).toFixed(1);

          //result
          let required = values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].Required === '' ? 0 :
            values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].Required;

          if (parseFloat(values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].DimensionalStablitytoWashingDetails[subchildindex].Changein) <= parseFloat(required)) {
            values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].DimensionalStablitytoWashingDetails[subchildindex].Result = 1;
          }
          else {
            values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].DimensionalStablitytoWashingDetails[subchildindex].Result = 0;
          }


        }
        else {
          values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].DimensionalStablitytoWashingDetails[subchildindex].AfterWash = '';
          setInputFields(values);
          Nodify('Warning!', 'warning', 'Enter Before wash.');
          return false;
        }
      }
      else {
        values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].DimensionalStablitytoWashingDetails[subchildindex].Changein = '';
      }
    }

    else if (feild === "Required") {
      let inputtext = e.target.value;
      let re1 = /^([0-9]+[\.]?[0-9]?[0-9]?|[0-9]+)/g;
      let val = re1.exec(inputtext);
      values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].Required = '';
      if (val !== null && e.target.value !== "") {
        values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].Required = val[0];

        //result

        let required = values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].Required;
        values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].DimensionalStablitytoWashingDetails.forEach((element, index) => {
          if (parseFloat(element.Changein) === parseFloat(required) || parseFloat(element.Changein) <= parseFloat(required)) {
            values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].DimensionalStablitytoWashingDetails[index].Result = 1;
          }
          else {
            values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].DimensionalStablitytoWashingDetails[index].Result = 0;
          }
        })
      }

    }
    else if (feild === "Conclusion") {
      if (e != null) {
        values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].Conclusion = e.value;
      }
      else {
        values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].Conclusion = '';
      }
    }
    setInputFields(values);
    if (feild !== "Conclusion") {
      DimensionStabilityTestResult(parentindex, childindex)
    }
  }

  const DimensionStabilityTestResult = (parentindex, childindex) => {
    let fail = 0; let checkallBeforewashfilled = true; let checkallAfterwashfilled = true;
    if (values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].Required !== '') {
      values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].DimensionalStablitytoWashingDetails.forEach(element => {
        if (element.BeforeWash === '') {
          checkallBeforewashfilled = false;
        }
        if (element.AfterWash === '') {
          checkallAfterwashfilled = false;
        }
      })
      if (checkallBeforewashfilled !== false && checkallAfterwashfilled !== false) {
        values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].DimensionalStablitytoWashingDetails.forEach(element => {
          if (element.Result === 0) {
            fail = 1;
          }
        })

        if (fail === 0) {
          values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].Conclusion = 1;
        }
        else {
          values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].Conclusion = 2;
        }

      }
      else {
        values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].Conclusion = "";
      }
    }
    else {
      values[0].Category[parentindex].DimensionalStablitytoWashing[childindex].Conclusion = "";
    }
    setInputFields(values);
  }

  const handlechangeSpirality = (e, parentindex, childindex, subchildindex, feild) => {
    const values = [...inputFields]

    // var regex = /^[0-9 ]+$/;
    let isValid = true;
    // var regex = /^[0-9.]+$/;
    // let isValid = regex.test(inputtext);
    if (feild === "BeforeWash") {
      let inputtext = e.target.value;

      let re1 = /^([0-9]+[\.]?[0-9]?[0-9]?|[0-9]+)/g;
      let val = re1.exec(inputtext);
      values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex].BeforeWash = '';
      if (val !== null && e.target.value !== "") {
        values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex].BeforeWash = val[0];
        if (subchildindex === 0) {
          if (values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex + 1].BeforeWash !== '') {
            let changein = (values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex + 1].BeforeWash /
              values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex].BeforeWash) * 100;
            values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex + 1].BeforeWashChangein = Math.round(changein).toFixed(1);

            //result
            let afterwashchangein = values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex + 1].afterwashchangein;

            if (parseFloat(afterwashchangein) <= parseFloat(values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex + 1].BeforeWashChangein)) {
              values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex + 1].Conclusion = 1;
            }
            else {
              values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex + 1].Conclusion = 0;
            }


          }
        }
        else {
          if (values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex - 1].BeforeWash !== '') {

            let changein = (values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex].BeforeWash /
              values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex - 1].BeforeWash) * 100;
            values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex].BeforeWashChangein = Math.round(changein).toFixed(1);

            let afterwashchangein = values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex].afterwashchangein;

            //result
            if (parseFloat(afterwashchangein) <= parseFloat(values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex].BeforeWashChangein)) {
              values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex].Conclusion = 1;
            }
            else {
              values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex].Conclusion = 0;
            }



          }
        }
      }
      else {
        if (subchildindex === 0) {
          values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex + 1].BeforeWashChangein = '';
        }
        else {
          values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex].BeforeWashChangein = '';
        }
      }
    }

    else if (feild === "AfterWash") {
      let inputtext = e.target.value;

      let re1 = /^([0-9]+[\.]?[0-9]?[0-9]?|[0-9]+)/g;
      let val = re1.exec(inputtext);
      values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex].AfterWash = '';
      if (val !== null && e.target.value !== "") {
        values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex].AfterWash = val[0];

        if (subchildindex === 0) {
          if (values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex + 1].AfterWash !== '') {

            let changein = (values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex + 1].AfterWash /
              values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex].AfterWash) * 100;
            values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex + 1].AfterWashChangein = Math.round(changein).toFixed(1);

            //result
            let beforeWashChangein = values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex + 1].BeforeWashChangein;

            if (parseFloat(beforeWashChangein) >= parseFloat(values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex + 1].AfterWashChangein)) {
              values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex + 1].Result = 1;
            }
            else {
              values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex + 1].Result = 0;
            }



          }
        }
        else {
          if (values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex - 1].AfterWash !== '') {
            let changein = (values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex].AfterWash /
              values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex - 1].AfterWash) * 100;
            values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex].AfterWashChangein = Math.round(changein).toFixed(1);

            //result
            let beforeWashChangein = values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex].BeforeWashChangein;

            if (parseFloat(beforeWashChangein) >= parseFloat(values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex].AfterWashChangein)) {
              values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex].Result = 1;
            }
            else {
              values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex].Result = 0;
            }


          }
        }
      }
      else {
        if (subchildindex === 0) {
          values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex + 1].AfterWashChangein = '';
        }
        else {
          values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex].AfterWashChangein = '';
        }
      }
    }

    else if (feild === "Required") {
      let inputtext = e.target.value;

      let re1 = /^([0-9]+[\.]?[0-9]?[0-9]?|[0-9]+)/g;
      let val = re1.exec(inputtext);
      values[0].Category[parentindex].Spirality[childindex].Required = '';
      if (val !== null && e.target.value !== "") {
        values[0].Category[parentindex].Spirality[childindex].Required = val[0];
      }

    }
    else if (feild === "Conclusion") {
      if (e != null) {
        values[0].Category[parentindex].Spirality[childindex].Conclusion = e.value;
      }
      else {
        values[0].Category[parentindex].Spirality[childindex].Conclusion = '';
      }
    }
    setInputFields(values);
    if (feild !== "Required" && feild !== "Conclusion") {
      SpiralityTestResult(parentindex, childindex, subchildindex);
    }
  }

  const SpiralityTestResult = (parentindex, childindex, subchildindex) => {
    let fail = 0; let checkallBeforewashfilled; let checkallAfterwashfilled;
    values[0].Category[parentindex].Spirality[childindex].SpiralityDetails.forEach(element => {
      if (element.BeforeWash === '') {
        checkallBeforewashfilled = 0;
      }
      if (element.AfterWash === '') {
        checkallAfterwashfilled = 0;
      }
    })
    if (checkallBeforewashfilled !== 0 && checkallAfterwashfilled !== 0) {
      if (subchildindex === 0) {
        if (values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex + 1].Result === 1) {
          values[0].Category[parentindex].Spirality[childindex + 1].Conclusion = 1;
        }
        else {
          values[0].Category[parentindex].Spirality[childindex + 1].Conclusion = 2;
        }
      }
      else {
        if (values[0].Category[parentindex].Spirality[childindex].SpiralityDetails[subchildindex].Result === 1) {
          values[0].Category[parentindex].Spirality[childindex].Conclusion = 1;
        }
        else {
          values[0].Category[parentindex].Spirality[childindex].Conclusion = 2;
        }
      }

    }
    else {
      values[0].Category[parentindex].Spirality[childindex].Conclusion = "";
    }
    setInputFields(values);
  }


  const DeleteCategory = (index, catindex) => {
    //
    const sample = [...inputFields]
    sample[index].Category.splice(catindex, 1);
    setInputFields(sample);
  }


  const ViewFile = (event, filename) => { // event.preventDefault();
    setIsOpenimg(true);
    setImagename(filename);
  }

  const RemoveFiles = async (e, index) => {
    filevals.splice(index, 1);
    setFiles(filevals);
  }



  const SaveSubmission = () => {
   ;
    let valid = true;
    // values[0].Category.map((element, index) => {
    //   element.DimensionalStablitytoWashing.map(detail => {
    //     if (detail.Required === '') {
    //       valid = false;
    //     }
    //   })
    //   element.DimensionalStablitytoWashing[0].DimensionalStablitytoWashingDetails.map(detail => {
    //     if (detail.BeforeWash === '') {
    //       valid = false;
    //     }
    //   })
    //   element.Spirality[0].SpiralityDetails.map(detail => {
    //     if (detail.BeforeWash === '') {
    //       valid = false;
    //     }
    //   })

    // })
    if (values[0].Category[0].DimensionalStablitytoWashing[0].Required === '' || values[0].Category[0].DimensionalStablitytoWashing[0].Required === undefined
    ) {
      valid = false;
    }

    if (values[0].Category[0].DimensionalStablitytoWashing) {
      values[0].Category[0].DimensionalStablitytoWashing[0].DimensionalStablitytoWashingDetails.forEach((element, Index) => {
        if (element.BeforeWash === '' || element.BeforeWash === undefined) {
          valid = false;
        }
      });
    }


    if (values[0].Category[0].Spirality[0].SpiralityDetails[0].BeforeWash === '' || values[0].Category[0].Spirality[0].SpiralityDetails[0].BeforeWash === undefined) {
      valid = false;
    }



   
    if (values[0].GarmentSize === 0 || values[0].SupplierRefNo === "" || values[0].TestStartDate === '' || values[0].TestStartDate === null ||
      values[0].Noofwashes === 0 || values[0].WashingCondition === 0 || values[0].DryerCondition === 0
      || values[0].TestEndDate === null || (SaveMode === 'Edit' && values[0].TestEndDate === '')) {
      Nodify("Warning!", "warning", "Please fill the mandatory(*) fields.");
      setSubmitted(true);
    }
    else if (values[0].TestEndDate !== '' && (values[0].LabComments === '' || values[0].LabComments === '0')) {
      Nodify("Warning!", "warning", "LabComments should be Fill while End Date is Given.");
    }
    else if (valid === false) {
      Nodify("Warning!", "warning", "Please fill the mandatory(*) fields in Test Details Section.");
      setSubmitted(true);
    }
    else if (values[0].TestEndDate !== "" && values[0].TestEndDate < values[0].TestStartDate) {
      Nodify("Warning!", "warning", "End Date Should not less than Start Date1.");
    }

    else {
      setbuttonLoader(true)
      if (values[0].TestStartDate !== '' || values[0].TestStartDate !== null) {
        values[0].TestStartDate = new Date(values[0].TestStartDate.setMinutes(values[0].TestStartDate.getMinutes() + 370))
      }
      else if (SaveMode === 'Edit') {
        values[0].TestEndDate = new Date(values[0].TestEndDate.setMinutes(values[0].TestEndDate.getMinutes() + 370))
      }

      values[0].ApperanceFormInfoId = SaveMode !== 'Edit' ? 0 : values[0].ApperanceFormInfoId;
      values[0].Operation = SaveMode !== 'Edit' ? 1 : 2;
      values[0].TNAApperanceFileInformation = filevals;
      values[0].LabComments = values[0].LabComments === '' ? '' : values[0].LabComments;
      values[0].TNAApperanceTestInformation = [];
      values[0].TNAApperanceTestDetailInformation = [];
      values[0].TNADimensionalStablityInformation = [];
      values[0].TNADimensionalStablityDetailInformation = [];
      values[0].TNASpiralityInformation = [];
      values[0].TNASpiralityDetailInformation = [];

      values[0].Category.forEach((element, index) => {
        values[0].TNAApperanceTestInformation.push({
          ApperanceTestId: SaveMode !== 'Edit' ? 0 : element.AppearanceAfterWash[0].ApperanceTestId,
          CategoryType: parseInt(element.CategoryType),
          TestName: element.AppearanceAfterWash[0].TestName,
          TestStandard: element.AppearanceAfterWash[0].Standard,
          Conclusion: element.AppearanceAfterWash[0].Conclusion === '' ? 0 : parseInt(element.AppearanceAfterWash[0].Conclusion),
        })


        element.AppearanceAfterWash[0].AppearanceAfterWashDetails.forEach(element => {
          values[0].TNAApperanceTestDetailInformation.push({
            ApperanceTestDetailId: SaveMode !== 'Edit' ? 0 : element.ApperanceTestDetailId,
            CategoryType: element.CategoryType,
            DetailsName: element.Detail,
            DetailsRequired: element.Required === '' ? 0 : parseInt(element.Required),
            Observation: element.Observation === '' ? 0 : parseInt(element.Observation)
          });
        })

        values[0].TNADimensionalStablityInformation.push({
          DimensionalInfoId: SaveMode !== 'Edit' ? 0 : element.DimensionalStablitytoWashing[0].DimensionalInfoId,
          CategoryType: parseInt(element.CategoryType),
          TestName: element.DimensionalStablitytoWashing[0].TestName,
          TestStandard: element.DimensionalStablitytoWashing[0].Standard,
          DetailsRequired: element.DimensionalStablitytoWashing[0].Required === '' ? 0 : parseInt(element.DimensionalStablitytoWashing[0].Required),
          Conclusion: element.DimensionalStablitytoWashing[0].Conclusion === '' ? 0 : parseInt(element.DimensionalStablitytoWashing[0].Conclusion),
        })


        element.DimensionalStablitytoWashing[0].DimensionalStablitytoWashingDetails.forEach(element => {
          values[0].TNADimensionalStablityDetailInformation.push({
            DimensionalDetailId: SaveMode !== 'Edit' ? 0 : element.DimensionalDetailId,
            CategoryType: element.CategoryType,
            DetailsName: element.Detail,
            BeforeWash: element.BeforeWash === '' ? 0 : parseFloat(element.BeforeWash),
            AfterWash: element.AfterWash === '' ? 0 : parseFloat(element.AfterWash),
            ChangeIn: element.Changein === '' ? 0 : parseFloat(element.Changein)
          });
        })

        values[0].TNASpiralityInformation.push({
          SpiralityInfoId: SaveMode !== 'Edit' ? 0 : element.Spirality[0].SpiralityInfoId,
          CategoryType: parseInt(element.CategoryType),
          TestName: element.Spirality[0].TestName,
          TestStandard: element.Spirality[0].Standard,
          DetailsRequired: element.Spirality[0].Required,
          Conclusion: element.Spirality[0].Conclusion === '' ? 0 : parseInt(element.Spirality[0].Conclusion),
        })


        element.Spirality[0].SpiralityDetails.forEach(element => {
          values[0].TNASpiralityDetailInformation.push({
            SpiralityDetailId: SaveMode !== 'Edit' ? 0 : element.SpiralityDetailId,
            CategoryType: element.CategoryType,
            DetailsName: element.Detail,
            BeforeWash: element.BeforeWash === '' ? 0 : parseFloat(element.BeforeWash),
            BeforeWashChangeIn: element.BeforeWashChangein === '' ? 0 : parseFloat(element.BeforeWashChangein),
            AfterWash: element.AfterWash === '' ? 0 : parseFloat(element.AfterWash),
            AfterWashChangeIn: element.AfterWashChangein === '' ? 0 : parseFloat(element.AfterWashChangein),
          });
        })

      })
      TnaService.InsertUpdateAppearenceTest(values[0]).then(res => {
        if (res.data.outputResult === "1") {
          Nodify('Success!', 'success', 'Appearance Submission Added Successfully');
          props.AppareanceTestSubmissionCallback(values[0].TestEndDate !== '' ? true : false, 'Save',0)
        } else if (res.data.outputResult === "2") {
          Nodify('Success!', 'success', 'Appearance Submission Updated Successfully');
          props.AppareanceTestSubmissionCallback(values[0].TestEndDate !== '' ? true : false, 'Update')
        }
        else if (res.data.outputResult === "0") {
          Nodify("Error!", "Error Occured!");
        }
      });
    }

  }

  const Delcallback = (value) => {
   
    if (value === true) {
      setID({ showPopupDelete: false, Params: {} });
      props.AppareanceTestSubmissionCallback(values[0].TestEndDate !== '' ? true : false, 'Delete')
    }
    else {
      setID({ showPopupDelete: false, Params: {} });
      props.AppareanceTestSubmissionCallback(false, 'notaDelete')
    }
  }

  const CustomInput = (props) => {
    return (
      <input
        className="form-control DatePickerCalenderHoliday"
        onClick={props.onClick}
        value={props.value}
        type="text"
        readOnly={true}
        placeholder="DD/MM/YYYY"
        style={{
          border: submitted && props.value === "" ? "1px solid red" : "",
          color:
            props.value === ""
              ? "#bfdea1"
              : "#000"

          , textTransform: "uppercase"
        }}
      />
    )
  }
  const CustomInputEndDate = (props) => {
    return (
      <input
        className="form-control DatePickerCalenderHoliday"
        onClick={props.onClick}
        value={props.value}
        type="text"
        readOnly={true}
        placeholder="DD/MM/YYYY"
        style={{
          border: submitted && SaveMode === 'Edit' && props.value === "" ? "1px solid red" : "",
          color:
            props.value === ""
              ? "#bfdea1"
              : "#000"

          , textTransform: "uppercase"
        }}
      />
    )
  }


  return (
    <>
      <div id="registration-form">
        <form action="">
          <div className="row" style={{ overflowX: "hidden", height: "700px", paddingRight: "1px", margin: "0px" }} >
            <div className="row" >
              {
                inputFields.map((item, index) => (
                  < Fragment key={`${item}~${index}`}>
                    <div className={DynamicClassName}>
                      <div className="widget flat radius-bordered">
                        <div className="panel-group accordion" id="accordion">
                          <div className="panel panel-default">
                            <div
                              id={"collapsePrograme_"}
                              className={
                                getHeader !== "ADD"
                                  ? "panel-collapse collapse in"
                                  : "panel-collapse collapse in"
                              }
                            >
                              <div className="widget-body">
                                <div id="registration-form">
                                  <Form>
                                    <div className="row">
                                      <div className="col-sm-12">
                                        <div className="row">
                                          <div className="col-sm-3">
                                            <div className="form-group">
                                              <label htmlFor="BuyerID">
                                                Buyer-Season-Brand
                                                {/* <span className="text-danger">*</span> */}
                                              </label>
                                              <span className="input-icon icon-right">
                                                <input
                                                  placeholder="Buyer-Season-Brand"
                                                  className="form-control"
                                                  title={inputFields[0].BuyerBrandSeasonName}
                                                  name=" Buyer-Season-Brand"
                                                  id={" Buyer-Season-Brand"}
                                                  value={inputFields[0].BuyerBrandSeasonName}
                                                  disabled


                                                ></input>
                                              </span>
                                            </div>
                                          </div>

                                          <div className="col-sm-3">
                                            <div className="form-group">
                                              <label htmlFor="SupplierID">
                                                Supplier
                                                {/* <span className="text-danger">*</span> */}
                                              </label>
                                              <span className="input-icon icon-right">
                                                <input
                                                  placeholder="Supplier"
                                                  className="form-control"
                                                  title={inputFields[0].SupplierName}
                                                  name="SupplierID"
                                                  id={"SupplierID"}
                                                  value={inputFields[0].SupplierName}
                                                  disabled

                                                ></input>
                                              </span>
                                            </div>
                                          </div>


                                          <div className="col-sm-3">
                                            <div className="form-group">
                                              <label htmlFor={"StyleNameStyleCode"}>
                                                Style Name - Style Code
                                                <span className="text-danger">*</span>
                                              </label>
                                              <span className="input-icon icon-right">
                                                <input
                                                  placeholder="Style Name - Style Code"
                                                  className="form-control"
                                                  name="StyleNameStyleCode"
                                                  id={"StyleNameStyleCode"}
                                                  value={inputFields[0].StyleName + '-' + inputFields[0].StyleNo}
                                                  title={inputFields[0].StyleName + '-' + inputFields[0].StyleNo}

                                                  disabled
                                                ></input>
                                              </span>
                                            </div>
                                          </div>
                                          <div className="col-sm-3">
                                            <div className="form-group">
                                              <label htmlFor={"SkuName"}>
                                                SKU Name
                                                <span className="text-danger">*</span>
                                              </label>
                                              <span className="input-icon icon-right">
                                                <input
                                                  placeholder="Sku Name"
                                                  className="form-control"
                                                  name="SkuName"
                                                  id={"SkuName"}
                                                  value={inputFields[0].SkuName}
                                                  title={inputFields[0].SkuName}

                                                  disabled
                                                ></input>
                                              </span>
                                            </div>
                                          </div>
                                          <div className="col-sm-3">
                                            <div className="form-group">
                                              <label htmlFor={"IDNo"}>
                                                ID No.
                                                <span className="text-danger">*</span>
                                              </label>
                                              <span className="input-icon icon-right">
                                                <input
                                                  placeholder="IDNo"
                                                  className="form-control"
                                                  name="IDNo"
                                                  id={"IDNo"}
                                                  value={inputFields[0].IdpoNo}
                                                  title={inputFields[0].IdpoNo}
                                                  disabled
                                                ></input>
                                              </span>
                                            </div>
                                          </div>
                                          <div className="col-sm-3">
                                            <div className="form-group">
                                              <label htmlFor={"GarmentSize"}>
                                                Garment Size
                                                <span className="text-danger">*</span>
                                              </label>
                                              <select id={"GarmentSize"} name="GarmentSize" className="form-select"
                                                value={inputFields[0].GarmentSize}
                                                title={inputFields[0].GarmentSize}
                                                onChange={event => handleChangeBasicDetails(event, 'GarmentSize')}
                                                style={{ border: submitted && inputFields[0].GarmentSize === 0 ? '1px solid red' : '' }}
                                                disabled={SaveMode === 'Edit' && props.editAppearanceAfterWash === false ? true : false}
                                              >
                                                <option value="0" key="0" style={{ color: '#bfdea1' }}> - Select Garment Size- </option>
                                                {
                                                  getSizeListmeasurement.map(item => (
                                                    <option key={item.id} value={item.value}>
                                                      {item.label}
                                                    </option>
                                                  ))
                                                }

                                              </select>
                                            </div>
                                          </div>
                                          <div className="col-sm-3">
                                            <div className="form-group">
                                              <label htmlFor={"SupplierRef"}>
                                                Supplier Ref. No.
                                                <span className="text-danger">*</span>
                                              </label>
                                              <span className="input-icon icon-right">
                                                <input
                                                  placeholder="Supplier Ref No"
                                                  className="form-control"
                                                  name="SupplierRefNo"
                                                  id={"SupplierRefNo"}
                                                  autocomplete="off"
                                                  value={inputFields[0].SupplierRefNo}
                                                  title={inputFields[0].SupplierRefNo}
                                                  style={{ border: submitted && inputFields[0].SupplierRefNo === "" ? "1px solid red" : "" }}
                                                  onChange={event => handleChangeBasicDetails(event, 'SupplierRef')}
                                                  disabled={SaveMode === 'Edit' && props.editAppearanceAfterWash === false ? true : false}
                                                ></input>
                                              </span>
                                            </div>
                                          </div>
                                          <div className="col-sm-3">
                                            <div className="form-group" style={{ marginLeft: "43px" }}>
                                              <img
                                                src={inputFields[0].SkuImagePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images" + inputFields[0].SkuImagePath}

                                                alt="Picture" style={{ marginBottom: '5px', height: '130px', width: '130px', border: "1px solid #d5d5d5" }} />

                                            </div>
                                          </div>

                                          <div className="col-sm-3" style={{ marginTop: "-65px" }}>
                                            <div className="form-group">
                                              <label htmlFor={"washes"}>No of Wash(es)<span className="text-danger">*</span></label>
                                              <select id={"Noofwashes"} name="Noofwashes" className="form-select"
                                                value={inputFields[0].Noofwashes}
                                                title={inputFields[0].Noofwashes}
                                                onChange={event => handleChangeBasicDetails(event, 'Noofwashes')}
                                                style={{ border: submitted && inputFields[0].Noofwashes === 0 ? '1px solid red' : '' }}
                                                disabled={SaveMode === 'Edit' && props.editAppearanceAfterWash === false ? true : false}
                                              >
                                                <option value="0" key="0" style={{ color: '#bfdea1' }}> - Select washes- </option>
                                                <option value="1" key="1">1</option>
                                                <option value="2" key="2">2</option>
                                                <option value="3" key="3">3</option>
                                                <option value="4" key="4">4</option>
                                                <option value="5" key="5">5</option>
                                                <option value="6" key="6">6</option>
                                                <option value="7" key="7">7</option>
                                                <option value="8" key="8">8</option>
                                                <option value="9" key="9">9</option>
                                                <option value="10" key="10">10</option>
                                                <option value="11" key="11">11</option>
                                                <option value="12" key="12">12</option>
                                                <option value="13" key="13">13</option>
                                                <option value="14" key="14">14</option>
                                                <option value="15" key="15">15</option>
                                                <option value="16" key="16">16</option>
                                                <option value="17" key="17">17</option>
                                                <option value="18" key="18">18</option>
                                                <option value="19" key="19">19</option>
                                                <option value="20" key="20">20</option>
                                                <option value="21" key="21">21</option>
                                                <option value="22" key="22">22</option>
                                                <option value="23" key="23">23</option>
                                                <option value="24" key="24">24</option>
                                                <option value="25" key="25">25</option>
                                                <option value="26" key="26">26</option>
                                                <option value="27" key="27">27</option>
                                                <option value="28" key="28">28</option>
                                                <option value="29" key="29">29</option>
                                                <option value="30" key="30">30</option>
                                                <option value="31" key="31">31</option>
                                                <option value="32" key="32">32</option>
                                                <option value="33" key="33">33</option>
                                                <option value="34" key="34">34</option>
                                                <option value="35" key="35">35</option>
                                                <option value="36" key="36">36</option>
                                                <option value="37" key="37">37</option>
                                                <option value="38" key="38">38</option>
                                                <option value="39" key="39">39</option>
                                                <option value="40" key="40">40</option>
                                                <option value="41" key="41">41</option>
                                                <option value="42" key="42">42</option>
                                                <option value="43" key="43">43</option>
                                                <option value="44" key="44">44</option>
                                                <option value="45" key="45">45</option>
                                                <option value="46" key="46">46</option>
                                                <option value="47" key="47">47</option>
                                                <option value="48" key="48">48</option>
                                                <option value="49" key="49">49</option>
                                                <option value="50" key="50">50</option>

                                              </select>
                                            </div>
                                          </div>
                                          <div className="col-sm-3" style={{ marginTop: "-65px" }}>
                                            <div className="form-group">
                                              <label htmlFor={"WashingCondition"}>Washing Condition<span className="text-danger">*</span></label>
                                              <select id={"WashingCondition"} name="WashingCondition" className="form-select"
                                                value={inputFields[0].WashingCondition}
                                                
                                                onChange={event => handleChangeBasicDetails(event, 'WashingCondition')}
                                                style={{ border: submitted && inputFields[0].WashingCondition === 0 ? '1px solid red' : '' }}
                                                disabled={SaveMode === 'Edit' && props.editAppearanceAfterWash === false ? true : false}
                                              >
                                                <option value="0" key="0" style={{ color: '#bfdea1' }}> - Select Washing Condition- </option>
                                                <option value="1" key="1">Normal Wash</option>
                                                <option value="2" key="2">Hot Wash</option>
                                              </select>
                                            </div>
                                          </div>
                                          <div className="col-sm-3" style={{ marginTop: "-65px" }}>
                                            <div className="form-group">
                                              <label htmlFor={"DryerCondition"}>Dryer Condition<span className="text-danger">*</span></label>
                                              <select id={"DryerCondition"} name="DryerCondition" className="form-select"
                                                value={inputFields[0].DryerCondition}
                                                
                                                onChange={event => handleChangeBasicDetails(event, 'DryerCondition')}
                                                style={{ border: submitted && inputFields[0].DryerCondition === 0 ? '1px solid red' : '' }}
                                                disabled={SaveMode === 'Edit' && props.editAppearanceAfterWash === false ? true : false}
                                              >
                                                <option value="0" key="0" style={{ color: '#bfdea1' }}> - Select Dryer Condition- </option>
                                                <option value="1" key="1">Flat Dry</option>
                                                <option value="2" key="2">Tumble Dry</option>
                                                <option value="3" key="3">Line Dry</option>
                                              </select>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-sm-3">
                                            <div className="form-group">
                                              <label htmlFor={"TestStartDate"}>
                                                Test Start
                                                <span className="text-danger">*</span>
                                              </label>
                                              <span className="input-icon icon-right">
                                                {
                                                  SaveMode === 'Edit' && (props.editAppearanceAfterWash === false || props.editAppearanceAfterWash === undefined) ?

                                                    <DatePicker className="form-control" name="TestStartDate"
                                                      id={
                                                        "TestStartDate"
                                                      }
                                                      selected={
                                                        inputFields[0].TestStartDate
                                                      }

                                                      dateFormat="dd/MM/yyyy"
                                                      peekNextMonth
                                                      dropdownMode="scroll"
                                                      autoComplete="off"
                                                      customInput={<CustomInput />}
                                                      disabled
                                                    />
                                                    // <input
                                                    //   placeholder="Supplier"
                                                    //   className="form-control"
                                                    //   title={inputFields[0].TestStartDate}
                                                    //   name="SupplierID"
                                                    //   id={"SupplierID"}
                                                    //   value={inputFields[0].TestStartDate}
                                                    //   disabled

                                                    // ></input>
                                                    :


                                                    <DatePicker className="form-control" name="TestStartDate"
                                                      id={
                                                        "TestStartDate"
                                                      }
                                                      selected={
                                                        inputFields[0].TestStartDate
                                                      }
                                                      onChange={event => handleChangeBasicDetails(event, 'TestStartDate')}
                                                      dateFormat="dd/MM/yyyy"
                                                      peekNextMonth
                                                      style={{
                                                        border: submitted && inputFields[0].TestStartDate === "" ? "1px solid red" : "",
                                                        color:
                                                          inputFields[0].TestStartDate === ""
                                                            ? "#bfdea1"
                                                            : "#000"

                                                        , textTransform: "uppercase"
                                                      }}
                                                      dropdownMode="scroll"
                                                      autoComplete="off"
                                                      customInput={<CustomInput />}
                                                      isClearable={inputFields[0].TestStartDate === null ? false : true}
                                                      onBlur={event => handleChangedate(event, 'TestStartDate')}
                                                      disabled={SaveMode === 'Edit' && props.editAppearanceAfterWash === false ? true : false}
                                                    />





                                                  // <input
                                                  //   id="TestStartDate"
                                                  //   name="TestStartDate"
                                                  //   value={inputFields[0].TestStartDate}
                                                  //   placeholder="DD/MM/YYYY"
                                                  //   type="date"
                                                  //   autoComplete="off"
                                                  //   className={"form-control"}
                                                  //   onKeyDown={(e) => e.preventDefault()}
                                                  //   style={{
                                                  //     border: submitted && inputFields[0].TestStartDate === "" ? "1px solid red" : "",
                                                  //     color:
                                                  //       inputFields[0].TestStartDate === ""
                                                  //         ? "#bfdea1"
                                                  //         : "#000"

                                                  //     , textTransform: "uppercase"
                                                  //   }}
                                                  //   onBlur={event => handleChangedate(event, 'TestStartDate')}
                                                  //   onChange={event => handleChangeBasicDetails(event, 'TestStartDate')}
                                                  //   disabled={SaveMode === 'Edit' && props.editAppearanceAfterWash === false ? true : false}
                                                  // />

                                                }



                                              </span>

                                            </div>
                                          </div>
                                          <div className="col-sm-3">
                                            <div className="form-group">
                                              <label htmlFor={"TestEndDate"}>
                                                End Date
                                                {
                                                  SaveMode === 'Edit' ?
                                                    <span className="text-danger">*</span> : ''
                                                }

                                              </label>
                                              <span className="input-icon icon-right">
                                                {
                                                  props.TaskStatus === 3 && (props.editAppearanceAfterWash === false || props.editAppearanceAfterWash === undefined) ?
                                                    <DatePicker className="form-control" name="TestEndDate"
                                                      id={
                                                        "TestEndDate"
                                                      }
                                                      selected={
                                                        inputFields[0].TestEndDate
                                                      }

                                                      dateFormat="dd/MM/yyyy"
                                                      peekNextMonth
                                                      dropdownMode="scroll"
                                                      autoComplete="off"
                                                      customInput={<CustomInputEndDate />}
                                                      disabled
                                                    />
                                                    :
                                                    <DatePicker className="form-control" name="TestEndDate"
                                                      id={
                                                        "TestEndDate"
                                                      }
                                                      selected={
                                                        inputFields[0].TestEndDate
                                                      }
                                                      onChange={event => handleChangeBasicDetails(event, 'TestEndDate')}
                                                      dateFormat="dd/MM/yyyy"
                                                      peekNextMonth

                                                      dropdownMode="scroll"
                                                      autoComplete="off"
                                                      customInput={<CustomInputEndDate />}
                                                      isClearable={inputFields[0].TestEndDate === null ? false : true}
                                                      onBlur={event => handleChangedate(event, 'TestEndDate')}
                                                      disabled={props.TaskStatus === 3 && props.editAppearanceAfterWash === false ? true : false}
                                                    />

                                                  // <input
                                                  //   id="TestEndDate"
                                                  //   name="TestEndDate"
                                                  //   value={inputFields[0].TestEndDate}
                                                  //   placeholder="DD/MM/YYYY"
                                                  //   type="date"
                                                  //   autoComplete="off"
                                                  //   className={"form-control"}
                                                  //   onKeyDown={(e) => e.preventDefault()}
                                                  //   style={{
                                                  //     border: submitted && SaveMode === 'Edit' && inputFields[0].TestEndDate === "" ? "1px solid red" : "",
                                                  //     color:
                                                  //       inputFields[0].TestEndDate === ""
                                                  //         ? "#bfdea1"
                                                  //         : "#000",
                                                  //     textTransform: "uppercase"
                                                  //   }}

                                                  //   onChange={event => handleChangeBasicDetails(event, 'TestEndDate')}

                                                  //   disabled={props.TaskStatus === 3 && props.editAppearanceAfterWash === false ? true : false}
                                                  // />

                                                }
                                              </span>
                                            </div>
                                          </div>
                                        </div>



                                      </div>
                                      {
                                        item.Category.map((parentdata, parentindex) => (
                                          <div className="row">
                                            <div className="col-lg-12 margin-0">
                                              <div id="idSubmitInformation" className="tab-pane in active" style={{ width: "100%" }}>
                                                {
                                                  IsCatogory === true ?
                                                    <>
                                                      <div className="row">
                                                        <div className="col-sm-12">

                                                          <div className="row">
                                                            <div className="col-sm-3">
                                                              <div className="form-group Marign_Red" style={{ marginLeft: "15px" }}>
                                                                <label htmlFor={"CategoryType"}>Category Type<span className="text-danger">*</span></label>

                                                                <select id={"CategoryType"} name="CategoryType" className="form-select"
                                                                  value={parentdata.CategoryType}
                                                                 
                                                                  onChange={event => handlechangeCategory(event, parentindex)}
                                                                  // onBlur={(event) => CheckSizeDuplicate(event, catindex, 'CategoryType')}
                                                                  style={{ border: submitted && parentdata.CategoryType === '0' ? '1px solid red' : '', width: "103%" }}
                                                                  disabled={SaveMode === 'Edit' && props.editAppearanceAfterWash === false ? true : false}
                                                                >
                                                                  <option value="0" key="0" style={{ color: '#bfdea1' }}> - Select Category Type- </option>
                                                                  <option value="1" key="1">Top</option>
                                                                  <option value="2" key="2">Bottom</option>
                                                                  <option value="3" key="3">Onesie</option>
                                                                  <option value="4" key="4">Top (Setpack)</option>
                                                                  <option value="5" key="5">Bottom (Setpack)</option>
                                                                </select>

                                                              </div>

                                                            </div>

                                                            {/* <div className="col-lg-1_own col-xs-12" > */}

                                                            {
                                                              item.Category.length === (parentindex + 1) && (
                                                                <button type="button"
                                                                  disabled={SaveMode === 'Edit' && props.editAppearanceAfterWash === false ? true : false}
                                                                  className="btn btn-success margin_even"
                                                                  title="Add Category Type"
                                                                  onClick={() =>
                                                                    Addcategory(index, parentindex)}>
                                                                  <i className="fa fa-plus"></i>
                                                                </button>
                                                              )}
                                                            &nbsp;

                                                            {item.Category.length !== 1 && (
                                                              <button
                                                                disabled={SaveMode === 'Edit' && props.editAppearanceAfterWash === false ? true : false}
                                                                type="button"
                                                                className="btn  btn-xs  btn-danger margin_even"
                                                                title="Delete Category Type"
                                                                onClick={() =>
                                                                  DeleteCategory(index, parentindex)
                                                                }
                                                              >
                                                                <i className="fa fa-trash-o"></i>
                                                              </button>
                                                            )}
                                                            {/* </div> */}

                                                          </div>

                                                          {
                                                            parentdata.AppearanceAfterWash.map((childdata, childindex) => (
                                                              <>
                                                                <div className="col-sm-6 ">
                                                                  <div className="form-group">
                                                                    <label htmlFor={"Spirality"}>
                                                                      Test Name
                                                                      <span className="text-danger">*</span>
                                                                    </label>
                                                                    <span className="input-icon icon-right">
                                                                      <input
                                                                        placeholder="TestName"
                                                                        className="form-control"
                                                                        name="TestName"
                                                                        id={"TestName"}
                                                                        value={childdata.TestName}
                                                                        title={childdata.TestName}
                                                                        style={{ border: submitted && childdata.TestName === "" ? "1px solid red" : "" }}
                                                                        // onChange={event => handleAppTest(event, 'TestName')}
                                                                        disabled
                                                                      ></input>
                                                                    </span>
                                                                  </div>



                                                                </div>
                                                                <div className="col-sm-6 ">
                                                                  <div className="form-group">
                                                                    <label htmlFor={"Standard"}>
                                                                      Standard
                                                                      <span className="text-danger">*</span>
                                                                    </label>
                                                                    <span className="input-icon icon-right">
                                                                      <input
                                                                        placeholder="BS"
                                                                        className="form-control"
                                                                        name="Standard"
                                                                        id={"Standard"}
                                                                        value={childdata.Standard}
                                                                        title={childdata.Standard}
                                                                        style={{ border: submitted && childdata.Standard === "" ? "1px solid red" : "" }}
                                                                        // onChange={event => handleAppStandard(event, 'Standard')}
                                                                        disabled
                                                                      ></input>
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                                <div className="row">
                                                                  <div className="col-lg-12">
                                                                    <table>
                                                                      <tr>
                                                                        <td>
                                                                          <div className="">
                                                                            <div className="">
                                                                              <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup"
                                                                                style={{ width: "1110px" }}>
                                                                                <thead>
                                                                                  <tr>
                                                                                    <th >Detail</th>
                                                                                    <th>Required in %</th>
                                                                                    <th >Observation</th>
                                                                                    {/* <th>Conclusion</th> */}
                                                                                  </tr>

                                                                                </thead>
                                                                                <tbody>
                                                                                  <tr></tr>

                                                                                  {

                                                                                    childdata.AppearanceAfterWashDetails.map((subchilddata, subchildindex) => (

                                                                                      <>
                                                                                        <tr>
                                                                                          <td>
                                                                                            <span className="input-icon icon-right">
                                                                                              {subchilddata.Detail}


                                                                                            </span>
                                                                                          </td>
                                                                                          <td>
                                                                                            <span className='input-icon icon-right'>

                                                                                              <Reactselect className="basic-single" name="TaskHolderOrFollower"
                                                                                                id={
                                                                                                  "Required"
                                                                                                }
                                                                                                isLoading={false}
                                                                                                isClearable={true}
                                                                                                isSearchable={true}
                                                                                                onChange={e => handlechangeAppearanceAfterWash(e, parentindex, childindex, subchildindex, "Required")}
                                                                                                value={Required.filter(function (option) {
                                                                                                  return option.value === subchilddata.Required;
                                                                                                })}
                                                                                                options={Required}
                                                                                                styles={{
                                                                                                  width: "200px",
                                                                                                  border: submitted && subchilddata.Required === '' ? styles2 : styles1

                                                                                                }}

                                                                                                isDisabled={SaveMode === 'Edit' && props.editAppearanceAfterWash === false ? true : false}
                                                                                              ></Reactselect>

                                                                                            </span>

                                                                                          </td>
                                                                                          <td>
                                                                                            <span className='input-icon icon-right'>

                                                                                              <Reactselect className="basic-single" name="TaskHolderOrFollower"
                                                                                                id={
                                                                                                  "Required"
                                                                                                }
                                                                                                isLoading={false}
                                                                                                isClearable={true}
                                                                                                isSearchable={true}
                                                                                                onChange={e => handlechangeAppearanceAfterWash(e, parentindex, childindex, subchildindex, "Observation")}
                                                                                                value={Observation.filter(function (option) {
                                                                                                  return option.value === subchilddata.Observation;
                                                                                                })}
                                                                                                options={Observation}
                                                                                                styles={{
                                                                                                  width: "200px",
                                                                                                  border: submitted && subchilddata.Observation === '' ? styles2 : styles1

                                                                                                }}

                                                                                                isDisabled={SaveMode === 'Edit' && props.editAppearanceAfterWash === false ? true : false}
                                                                                              ></Reactselect>


                                                                                            </span>

                                                                                          </td>



                                                                                        </tr>
                                                                                      </>

                                                                                    ))
                                                                                  }
                                                                                </tbody>

                                                                              </table>
                                                                            </div>

                                                                            <div className="row">
                                                                              <div className="col-sm-4">
                                                                                <div className="form-group">

                                                                                  <label for="Conclusion">Conclusion</label>

                                                                                  <Reactselect className="basic-single" name="TaskHolderOrFollower"
                                                                                    id={
                                                                                      "Conclusion"
                                                                                    }
                                                                                    isLoading={false}
                                                                                    isClearable={true}
                                                                                    isSearchable={true}
                                                                                    onChange={e => handlechangeAppearanceAfterWash(e, parentindex, childindex, 0, "Conclusion")}
                                                                                    value={reult.filter(function (option) {
                                                                                      return option.value === childdata.Conclusion;
                                                                                    })}
                                                                                    options={reult}
                                                                                    styles={{
                                                                                      width: "200px",
                                                                                      border: submitted && childdata.Conclusion === '' ? styles2 : styles1

                                                                                    }}
                                                                                    menuPosition="fixed"
                                                                                    isDisabled={SaveMode === 'Edit' && props.editAppearanceAfterWash === false ? true : false}
                                                                                  ></Reactselect>


                                                                                </div>
                                                                              </div>
                                                                            </div>

                                                                            {/* <div className="row">
                                                                    <div className="col-sm-4">
                                                                      <div className="">
                                                                        <div className="Margin_ReactLabel">

                                                                          <label for="Conclusion">Conclusion</label>
                                                                        </div>
                                                                        <div>
                                                                          <Reactselect className="basic-single margin_20" name="TaskHolderOrFollower"
                                                                            id={
                                                                              "Conclusion"
                                                                            }
                                                                            isLoading={false}
                                                                            isClearable={true}
                                                                            isSearchable={true}
                                                                            onChange={e => handlechangeAppearanceAfterWash(e, parentindex, childindex, 0, "Conclusion")}
                                                                            value={reult.filter(function (option) {
                                                                              return option.value === childdata.Conclusion;
                                                                            })}
                                                                            options={reult}
                                                                            styles={{
                                                                              // width: "200px",
                                                                              border: submitted && childdata.Conclusion === '' ? styles2 : styles1

                                                                            }}
                                                                            menuPosition="fixed"

                                                                          ></Reactselect>
                                                                        </div>



                                                                      </div>
                                                                    </div>
                                                                  </div> */}
                                                                          </div>

                                                                        </td>

                                                                      </tr>

                                                                    </table>
                                                                  </div>
                                                                </div>




                                                              </>
                                                            ))
                                                          }



                                                          <br />
                                                          {
                                                            parentdata.DimensionalStablitytoWashing.map((childdata, childindex) => (
                                                              <>
                                                                <div className="col-sm-6 ">
                                                                  <div className="form-group">
                                                                    <label htmlFor={"Spirality"}>
                                                                      Test Name
                                                                      <span className="text-danger">*</span>
                                                                    </label>
                                                                    <span className="input-icon icon-right">
                                                                      <input
                                                                        placeholder="TestName"
                                                                        className="form-control"
                                                                        name="TestName"
                                                                        id={"TestName"}
                                                                        value={childdata.TestName}
                                                                        style={{ border: submitted && childdata.TestName === "" ? "1px solid red" : "" }}
                                                                        // onChange={event => handleDimTestName(event, 'Spirality')}
                                                                        disabled
                                                                      ></input>
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                                <div className="col-sm-6 ">
                                                                  <div className="form-group">
                                                                    <label htmlFor={"Standard"}>
                                                                      Standard
                                                                      <span className="text-danger">*</span>
                                                                    </label>
                                                                    <span className="input-icon icon-right">
                                                                      <input
                                                                        placeholder="BS"
                                                                        className="form-control"
                                                                        name="Standard"
                                                                        id={"Standard"}
                                                                        value={childdata.Standard}
                                                                        style={{ border: submitted && childdata.Standard === "" ? "1px solid red" : "" }}
                                                                        // onChange={event => handleDimStandard(event, 'Standard')}
                                                                        disabled
                                                                      ></input>
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                                <div className="row">
                                                                  <div className="col-lg-12">
                                                                    <table>
                                                                      <tr>
                                                                        <td> <div className="">
                                                                          <div className="">
                                                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup"
                                                                              style={{ width: "1110px" }}>
                                                                              <thead>
                                                                                <tr>
                                                                                  <th >Detail</th>
                                                                                  <th>Before Wash<span className="text-danger">*</span></th>
                                                                                  <th >After Wash</th>
                                                                                  <th >Change in %</th>
                                                                                </tr>

                                                                              </thead>
                                                                              <tbody>
                                                                                <tr></tr>

                                                                                {
                                                                                  childdata.DimensionalStablitytoWashingDetails.map((subchilddata, subchildindex) => (
                                                                                    <>
                                                                                      <tr>
                                                                                        <td>
                                                                                          <span className="input-icon icon-right">
                                                                                            {subchilddata.Detail}


                                                                                          </span>
                                                                                        </td>
                                                                                        <td>
                                                                                          <span className='input-icon icon-right'>
                                                                                            <input
                                                                                              placeholder="Before Wash"
                                                                                              className="form-control"
                                                                                              name="BeforeWash"
                                                                                              id={"BeforeWash"}
                                                                                              autocomplete="off"
                                                                                              maxLength='20'
                                                                                              value={subchilddata.BeforeWash}
                                                                                              style={{ border: submitted && subchilddata.BeforeWash === "" ? "1px solid red" : "" }}
                                                                                              onChange={e => handlechangeDimensionalStablitytoWashing(e, parentindex, childindex, subchildindex, "BeforeWash")}
                                                                                              disabled={SaveMode === 'Edit' && props.editAppearanceAfterWash === false ? true : false}
                                                                                            ></input>
                                                                                          </span>

                                                                                        </td>
                                                                                        <td>
                                                                                          <span className='input-icon icon-right'>
                                                                                            <input
                                                                                              placeholder="After Wash"
                                                                                              className="form-control"
                                                                                              name="AfterWash"
                                                                                              id={"AfterWash"}
                                                                                              maxLength='20'
                                                                                              autocomplete="off"
                                                                                              value={subchilddata.AfterWash}
                                                                                              disabled={SaveMode === 'Edit' && props.editAppearanceAfterWash === false ? true : false}
                                                                                              onChange={e => handlechangeDimensionalStablitytoWashing(e, parentindex, childindex, subchildindex, "AfterWash")}
                                                                                            ></input>
                                                                                          </span>

                                                                                        </td>

                                                                                        <td>
                                                                                          <span className='input-icon icon-right'>
                                                                                            <input
                                                                                              placeholder="Change in %"
                                                                                              className="form-control"
                                                                                              name="Changein"
                                                                                              id={"Changein"}
                                                                                              value={subchilddata.Changein}

                                                                                              disabled
                                                                                            ></input>
                                                                                          </span>

                                                                                        </td>

                                                                                      </tr>
                                                                                    </>

                                                                                  ))
                                                                                }
                                                                              </tbody>
                                                                            </table>
                                                                            <br />
                                                                            <div className="row">
                                                                              <div className="col-sm-4">

                                                                                <div className="form-group">
                                                                                  <label for="Required">Required in %<span className="text-danger">*</span></label>
                                                                                  <input type="Required" class="form-control" id="Required "
                                                                                    value={childdata.Required} placeholder="Required" name="Required "
                                                                                    onChange={e => handlechangeDimensionalStablitytoWashing(e, parentindex, childindex, 0, "Required")}
                                                                                    disabled={SaveMode === 'Edit' && props.editAppearanceAfterWash === false ? true : false}
                                                                                  ></input>
                                                                                </div>
                                                                              </div>

                                                                              <div className="col-sm-4">
                                                                                <div className="form-group">
                                                                                  <label for="Conclusion">Conclusion</label>

                                                                                  <Reactselect className="basic-single" name="TaskHolderOrFollower"
                                                                                    id={
                                                                                      "Conclusion"
                                                                                    }
                                                                                    isLoading={false}
                                                                                    isClearable={true}
                                                                                    isSearchable={true}
                                                                                    onChange={e => handlechangeDimensionalStablitytoWashing(e, parentindex, childindex, 0, "Conclusion")}
                                                                                    value={reult.filter(function (option) {
                                                                                      return option.value === childdata.Conclusion;
                                                                                    })}
                                                                                    options={reult}
                                                                                    styles={{
                                                                                      width: "200px",
                                                                                      border: submitted && childdata.Conclusion === '' ? styles2 : styles1

                                                                                    }}
                                                                                    menuPosition="fixed"
                                                                                    isDisabled={SaveMode === 'Edit' && props.editAppearanceAfterWash === false ? true : false}

                                                                                  ></Reactselect>

                                                                                </div>

                                                                              </div>
                                                                            </div>

                                                                          </div>
                                                                        </div>
                                                                        </td>
                                                                      </tr>
                                                                    </table>
                                                                  </div>
                                                                </div>



                                                              </>
                                                            ))
                                                          }

                                                          <br />
                                                          {
                                                            parentdata.Spirality.map((childdata, childindex) => (
                                                              <>
                                                                <div className="col-sm-6 ">
                                                                  <div className="form-group">
                                                                    <label htmlFor={"Spirality"}>
                                                                      Test Name
                                                                      <span className="text-danger">*</span>
                                                                    </label>
                                                                    <span className="input-icon icon-right">
                                                                      <input
                                                                        placeholder="TestName"
                                                                        className="form-control"
                                                                        name="TestName"
                                                                        id={"TestName"}
                                                                        value={childdata.TestName}
                                                                        style={{ border: submitted && childdata.TestName === "" ? "1px solid red" : "" }}
                                                                        // onChange={event => handleSprialTest(event, 'TestName')}
                                                                        disabled
                                                                      ></input>
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                                <div className="col-sm-6 ">
                                                                  <div className="form-group">
                                                                    <label htmlFor={"Standard"}>
                                                                      Standard
                                                                      <span className="text-danger">*</span>
                                                                    </label>
                                                                    <span className="input-icon icon-right">
                                                                      <input
                                                                        placeholder="BS"
                                                                        className="form-control"
                                                                        name="Standard"
                                                                        id={"Standard"}
                                                                        value={childdata.Standard}
                                                                        style={{ border: submitted && childdata.Standard === "" ? "1px solid red" : "" }}
                                                                        // onChange={event => handleSprialStandard(event, 'Standard')}
                                                                        disabled
                                                                      ></input>
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                                <div className="row">
                                                                  <div className="col-lg-12">
                                                                    <table>
                                                                      <tr>
                                                                        <td> <div className="">
                                                                          <div className="">
                                                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup"
                                                                              style={{ width: "1110px" }}>
                                                                              <thead>
                                                                                <tr>
                                                                                  <th >
                                                                                    Detail</th>
                                                                                  <th>Before Wash <span className="text-danger">*</span></th>
                                                                                  <th>Change in %</th>
                                                                                  <th >After Wash</th>
                                                                                  <th >Change in %</th>

                                                                                </tr>

                                                                              </thead>
                                                                              <tbody>
                                                                                <tr></tr>

                                                                                {

                                                                                  childdata.SpiralityDetails.map((subchilddata, subchildindex) => (
                                                                                    <>
                                                                                      <tr>
                                                                                        <td>
                                                                                          <span className="input-icon icon-right">
                                                                                            {subchilddata.Detail}


                                                                                          </span>
                                                                                        </td>
                                                                                        <td>
                                                                                          <span className='input-icon icon-right'>
                                                                                            <input
                                                                                              placeholder="Before Wash"
                                                                                              className="form-control"
                                                                                              name="BeforeWash"
                                                                                              id={"BeforeWash"}
                                                                                              autocomplete="off"
                                                                                              maxLength='20'
                                                                                              value={subchilddata.BeforeWash}
                                                                                              style={{ border: submitted && subchilddata.BeforeWash === "" ? "1px solid red" : "" }}
                                                                                              onChange={e => handlechangeSpirality(e, parentindex, childindex, subchildindex, "BeforeWash")}
                                                                                              disabled={SaveMode === 'Edit' && props.editAppearanceAfterWash === false ? true : false}
                                                                                            ></input>
                                                                                          </span>
                                                                                        </td>
                                                                                        <td>
                                                                                          {
                                                                                            subchildindex === 1 ?

                                                                                              <span className='input-icon icon-right'>
                                                                                                <input
                                                                                                  placeholder="Change in %"
                                                                                                  className="form-control"
                                                                                                  name="Changein"
                                                                                                  id={"Changein"}
                                                                                                  value={subchilddata.BeforeWashChangein}

                                                                                                  disabled
                                                                                                ></input>
                                                                                              </span>

                                                                                              : ''
                                                                                          }
                                                                                        </td>


                                                                                        <td>
                                                                                          <span className='input-icon icon-right'>
                                                                                            <input
                                                                                              placeholder="After Wash"
                                                                                              className="form-control"
                                                                                              name="AfterWash"
                                                                                              id={"AfterWash"}
                                                                                              autocomplete="off"
                                                                                              value={subchilddata.AfterWash}
                                                                                              maxLength='20'
                                                                                              onChange={e => handlechangeSpirality(e, parentindex, childindex, subchildindex, "AfterWash")}
                                                                                              disabled={SaveMode === 'Edit' && props.editAppearanceAfterWash === false ? true : false}
                                                                                            ></input>
                                                                                          </span>
                                                                                        </td>
                                                                                        <td>
                                                                                          {
                                                                                            subchildindex === 1 ?
                                                                                              <span className='input-icon icon-right'>
                                                                                                <input
                                                                                                  placeholder="Change in %"
                                                                                                  className="form-control"
                                                                                                  name="Changein"
                                                                                                  id={"Changein"}
                                                                                                  value={subchilddata.AfterWashChangein}


                                                                                                  disabled
                                                                                                ></input>
                                                                                              </span>
                                                                                              : ''
                                                                                          }
                                                                                        </td>
                                                                                      </tr>
                                                                                    </>

                                                                                  ))
                                                                                }
                                                                              </tbody>
                                                                            </table>
                                                                            <br />
                                                                            <div className="row">
                                                                              <div className="col-sm-4">
                                                                                <div className="form-group">
                                                                                  <label for="Required">Required in %</label>
                                                                                  <input type="Required" class="form-control" id="Required "
                                                                                    value={childdata.Required} placeholder="Required" name="Required "
                                                                                    onChange={e => handlechangeSpirality(e, parentindex, childindex, 0, "Required")}
                                                                                    disabled={SaveMode === 'Edit' && props.editAppearanceAfterWash === false ? true : false}
                                                                                  ></input>
                                                                                </div>
                                                                              </div>
                                                                              <div className="col-sm-4">
                                                                                <div className="form-group">
                                                                                  <label for="Conclusion">Conclusion</label>


                                                                                  <Reactselect className="basic-single" name="TaskHolderOrFollower"
                                                                                    id={
                                                                                      "Conclusion"
                                                                                    }
                                                                                    isLoading={false}
                                                                                    isClearable={true}
                                                                                    isSearchable={true}
                                                                                    onChange={e => handlechangeSpirality(e, parentindex, childindex, 0, "Conclusion")}
                                                                                    value={reult.filter(function (option) {
                                                                                      return option.value === childdata.Conclusion;
                                                                                    })}
                                                                                    options={reult}
                                                                                    styles={{
                                                                                      width: "200px",
                                                                                      border: submitted && childdata.Conclusion === '' ? styles2 : styles1

                                                                                    }}
                                                                                    menuPosition="fixed"
                                                                                    isDisabled={SaveMode === 'Edit' && props.editAppearanceAfterWash === false ? true : false}
                                                                                  ></Reactselect>

                                                                                </div>
                                                                              </div>
                                                                            </div>

                                                                          </div>
                                                                        </div>

                                                                        </td>
                                                                      </tr>
                                                                    </table>
                                                                  </div>
                                                                </div>

                                                              </>
                                                            ))
                                                          }

                                                        </div>
                                                      </div>
                                                    </> : ''

                                                }
                                              </div>
                                            </div>
                                          </div>
                                        ))
                                      }
                                      {/* <div className="col-sm-12 col-lg-12">
                                    <div className="row">
                                      <div className="col-sm-8 col-lg-6">
                                        <div className='form-group'>
                                          <label htmlFor="Remarks">Lab Comments</label>

                                          <select id={"CategoryType"} name="CategoryType" className="form-select"
                                            value={inputFields[0].LabComments}
                                            onChange={event => handleChangeBasicDetails(event, 'LabComments')}
                                            disabled={props.TaskStatus === 3 && props.editAppearanceAfterWash === false ? true : false}
                                          // onBlur={(event) => CheckSizeDuplicate(event, catindex, 'CategoryType')}
                                          //  style={{ border: submitted && inputFields[0].LabComments === '0' ? '1px solid red' : '' }}
                                          >
                                            <option value="0" key="0" style={{ color: '#bfdea1' }}> - Select LabComments- </option>
                                            <option value="1" key="1">Satisfactory</option>
                                            <option value="2" key="2">Non-Satisfactory</option>

                                          </select>


                                        </div>
                                      </div>

                                    </div>
                                  </div> */}
                                      <div className="col-sm-12 col-lg-12">
                                        <div className="row">
                                          <div className="col-sm-8 col-lg-6">
                                            <div className='form-group'>
                                              <label htmlFor="Remarks">Lab Comments {
                                                SaveMode === 'Edit' ?
                                                  <span className="text-danger">*</span> : ''
                                              } </label>

                                              <select id={"CategoryType"} name="CategoryType" className="form-select"
                                                value={inputFields[0].LabComments}
                                                style={{
                                                  border:
                                                    inputFields[0].TestEndDate !== "" && (inputFields[0].LabComments === "" || inputFields[0].LabComments === "0") ? "1px solid red" : ''
                                                }}
                                                // style={{
                                                //   border: submitted && SaveMode === 'Edit' && inputFields[0].TestEndDate === "" || submitted && SaveMode === 'Edit' && inputFields[0].TestEndDate !== "" && inputFields[0].LabComments === "0"  ? "1px solid red" : "",
                                                // }}
                                                onChange={event => handleChangeBasicDetails(event, 'LabComments')}
                                                disabled={props.TaskStatus === 3 && props.editAppearanceAfterWash === false ? true : false}
                                              // onBlur={(event) => CheckSizeDuplicate(event, catindex, 'CategoryType')}


                                              >
                                                <option value="0" key="0" style={{ color: '#bfdea1' }}> - Select Lab Comments- </option>
                                                <option value="1" key="1">Satisfactory</option>
                                                <option value="2" key="2">Non-Satisfactory</option>

                                              </select>


                                            </div>
                                          </div>

                                        </div>
                                      </div>
                                      <div className="col-sm-12 col-lg-12">
                                        <div className="row">
                                          <div className="col-sm-8 col-lg-8">
                                            <div className='form-group'>
                                              <label htmlFor="Remarks">Remarks</label>
                                              <textarea maxLength="300" name="Remarks" value={inputFields[0].Remarks}
                                                onChange={event => handleChangeBasicDetails(event, 'Remarks')}
                                                rows="4" style={{ width: "100%" }}
                                                disabled={SaveMode === 'Edit' && props.editAppearanceAfterWash === false ? true : false}
                                              ></textarea>
                                            </div>
                                          </div>

                                        </div>
                                      </div>
                                      <div className="col-sm-12 col-lg-12">
                                        <div className="widget-header" style={{ paddingLeft: 0 }}>
                                          <span className="lowCaseText widget-caption" style={{ fontSize: '14px' }}>File Upload <span style={{ color: 'red', fontSize: '14px' }}>(Upload Document Type: Excel, Word, PDF and Image)</span></span>
                                        </div>
                                      </div>
                                      <div className="col-sm-12 col-lg-12">
                                        <div className="row">
                                          <div className="col-sm-8 col-lg-8">
                                            <div className='form-group'>
                                              <tr>
                                                <td> <input type="file" id="FileUpload" style={{ fontSize: '14px' }}
                                                  disabled={SaveMode === 'Edit' && props.editAppearanceAfterWash === false ? true : false}
                                                  onChange={(e) => AppendFiles(e)} accept=".jpg,.jpeg,.png,.pdf,.xlsx,.doc,.docx,.xls" ></input></td>
                                              </tr>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-lg-12 col-sm-12">
                                        <div className="row no-margin">
                                          {
                                            getFiles.length !== 0 ?
                                              getFiles.map((files, fileindex) => (
                                                <div className="col-lg-4 col-sm-4 file_list_upload">
                                                  <tr title={files.FileName}>
                                                    <td className="cut_textInTNA">
                                                      <span style={{ marginRight: '7px' }}>
                                                        {
                                                          files.FileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                            files.FileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.FileType === "application/vnd.ms-excel" ?
                                                              <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                              files.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                files.FileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                        }
                                                      </span>  {files.FileName}
                                                    </td>
                                                    <td className="button_style_uploda">
                                                    {
                                                        files.FileType === "application/pdf" || files.FileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                          || files.FileType === 'application/msword' || files.FileType === "application/vnd.ms-excel" ?
                                                          <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + 'Images/TNAImages/' + files.FileName} download><i class="fa fa-download"></i> </a> :
                                                          <i className="btn btn-info fa fa-eye" title="View Logo"
                                                            onClick={
                                                              event => ViewFile(event, files.FileName)
                                                            }></i>
                                                      }
                                                     
                                                      &nbsp;
                                                      {/* {
                                                    files.FileType === "application/pdf" || files.FileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                                                      files.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files.FileType === 'application/msword'
                                                      || files.FileType === "application/vnd.ms-excel" ?
                                                      <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + 'Images/TNAImages/' + files.FileName} download><i class="fa fa-download"></i> </a> :
                                                      <i className="btn btn-info fa fa-eye" title="View Logo"
                                                        onClick={
                                                          event => ViewFile(event, files.FileName)
                                                        }></i>
                                                  } */}
                                                      
                                                       <p title='Delete File' onClick={(e) => RemoveFiles(e, fileindex)}
                                                      className="btn btn-danger btn-xs delete">
                                                      <i className="fa fa-trash-o"></i>
                                                    </p>
                                                    </td>
                                                  </tr>
                                                  {

                                                    <div> {
                                                      isOpenimg && (

                                                        <Lightbox mainSrc={window.$APIBaseURL + 'Images/TNAImages/' + getImagename}
                                                          alt="bg image"
                                                          onCloseRequest={
                                                            () => setIsOpenimg(false)
                                                          } />
                                                      )
                                                    } </div>
                                                  }
                                                </div>
                                              ))
                                              : ''
                                          }
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-sm-12" align="right">
                                          <button type="button" className="btn btn-success margin-Align" onClick={() => SaveSubmission()}
                                            disabled={(props.props.location.pathname === "/TNAReport" && props.TaskStatus === 3) ? true : props.editAppearanceAfterWash === false && props.TaskStatus === 3 || buttonLoader ? true : false}>

                                            <i className="fa fa-check right"></i> &nbsp;{props.editAppearanceAfterWash === true ? 'Update' : getHeader !== 'Add' ? 'Update' : 'Save'}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </Form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ))
              }
            </div>
          </div>
          {
            getID.showPopupDelete ? <SweetAlertPopup data={
              getID.Params
            }
              deleteCallback={Delcallback}
              showpopup={true}
              pageActionId={AppareanceTestId}
              Msg={"Apperance after wash Deleted successfully."} /> : null
          }
        </form>

      </div>

    </>
  );
}
export default AfterWash;
