import React, { useState, useEffect, useMemo, useRef,Fragment } from "react";
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import SearchRetain from "../../Common/SearchRetain";
import { TableHeader, Pagination, Search, PageCount } from "../../Datatable";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from 'react-router-dom';
import strikeoffService from "../../../services/Style/StrikeoffService";
import Nodify from "../../Common/ReactNotification";
import { MasterStrikeOffID } from "../../Common/PageActionNumber";
import SweetAlertPopup from '../../Common/SweetAlertPopup';
import Lightbox from "react-image-lightbox";
import CommonService from '../../../services/Common/CommonService';
import { retrieveStrikeoff } from "../../../actions/strikeoffMaster";

const MasterStrikeOffList = (props) => {
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    const [getPlaceholder] = useState("Buyer/ Brand / Strike Off Name / Code / Quality / Purchase Type / Trim Supplier");
    const [getID, setID] = useState(0);
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [expandState, setExpandState] = useState({});
    const [expandedRows, setExpandedRows] = useState([]);
    const [expandRefList, setexpandRefList] = useState({});
    const [expandColorList, setexpandColorList] = useState({});
    const [isOpen, setIsOpen] = useState(false)
    const [CriticalList, setCriticalList] = useState([]);

    const dispatch = useDispatch();


    //reducer
    const reducerState = useSelector((state) => state);
    const { user: currentUser } = reducerState.auth;

    const comments = reducerState.strikeoff.strikeOffList;

    let isLoadingStrikeoff = reducerState.strikeoff.isLoadingStrikeoff;

    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.common.MenuList.item3);
    if (menulist !== undefined) { activeMenu = menulist.filter(i => i.menuName === "StrikeoffMaster"); }
    // End Enable Menu


    useEffect(() => {
        let StrikeoffId = 0;
        dispatch(retrieveStrikeoff(StrikeoffId));
        const state1 = props.location.state;

        if (state1 !== undefined && state1 !== null) {
            let Msg;
            let Type = 'success'; // default, info, warning
            let title = 'Success!';
            if (state1.message === 'Edit') {
                Msg = 'Strike Off updated successfully.';
            } else if (state1.message === 'Add') {
                Msg = 'Strike Off added successfully.';
            } else {
                Msg = 'Error occured';
                Type = 'warning';
                title = 'Error!';
            }
            window.history.replaceState(null, '')
            Nodify(title, Type, Msg);
        }
        var page = getPlaceholder;
        var setConstraint = SearchRetain(page);
        if (setConstraint !== undefined) {
            setCurrentPage(setConstraint.CurrentPage);
            setITEMS_PER_PAGE(setConstraint.PageItems);
        }

        strikeoffService.GetStrikeOffPrintColorList(0).then((response1) => {
            if (response1.data) {
                setCriticalList(response1.data);
            }
        });

    }, [dispatch]);

    useEffect(() => {
        if (!isLoadingStrikeoff) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingStrikeoff]);

    const headers = [
        {
            name: "#",
            field: "",
            sortable: false,
            widthsize: "1%"
        },
        {
            name: "Buyer / Brand Name",
            sortable: true,
            field: "buyerAndBrandName",
            widthsize: "10%"
        },
        {
            name: "Strike Off Image",
            sortable: false,
            widthsize: "10%"
        },
        {
            name: "Strike Off Name",
            field: "strikeOffName",
            sortable: true,
            widthsize: "13%"
        }, {
            name: "Strike Off Code",
            field: "strikeOffCode",
            sortable: true,
            widthsize: "13%"
        },
        // {
        //     name: "Buyer Reference No",
        //     field: "buyerReferenceNo",
        //     sortable: true,
        //     widthsize: "14%"
        // },
        // {
        //     name: "Quality",
        //     field: "quality",
        //     sortable: true,
        //     widthsize: "12%"
        // },
        {
            name: "Purchase Type",
            field: "purchaseTypeName",
            sortable: true,
            widthsize: "13%"
        },
        {
            name: "Trim Supplier",
            field: "trimSupplierName",
            sortable: true,
            widthsize: "13%"
        },
        {
            name: "Action",
            sortable: false,
            widthsize: "10%",
            display: activeMenu[0].isEdit === 0 && activeMenu[0].isDelete === 0 ? 'none' : ''
        },
    ];

    const childHeaders_Ref = [
        {
            name: "Height",
            sortable: false
        },
        {
            name: "Width",
            sortable: false
        },
        {
            name: "UOM",
            sortable: false
        },
    ];

    const commentsData = useMemo(() => {
        let computedComments = comments;
        if (search) {
            computedComments = computedComments.filter(comment => comment.strikeOffName.toLowerCase().includes(search.toLowerCase())
                || comment.strikeOffCode.toLowerCase().includes(search.toLowerCase())
                || comment.buyerReferenceNo.toLowerCase().includes(search.toLowerCase())
                || comment.quality.toLowerCase().includes(search.toLowerCase())
                || comment.purchaseTypeName.toLowerCase().includes(search.toLowerCase())
                || comment.trimSupplierName.toLowerCase().includes(search.toLowerCase())
                || comment.buyerName.toLowerCase().includes(search.toLowerCase())
                || comment.brandName.toLowerCase().includes(search.toLowerCase())
            );
        }
        setTotalItems(computedComments.length);
        // Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort((a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]));
        }
        // Current Page slice
        let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
        if (records.length === 0 && currentPage != 1) {
            setCurrentPage(currentPage - 1);
        }
        return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
    }, [
        comments,
        currentPage,
        search,
        sorting,
        ITEMS_PER_PAGE
    ]);


    const pageRedirect = (Params, redirect, page) => {
        localStorage.setItem('Pagination', JSON.stringify({ CurrentPage: currentPage, Page: page, PageItems: ITEMS_PER_PAGE }));
        if (redirect === 'Add') {
            props.history.push('/AddStrikeOff');
        }
        else if (redirect === 'Edit') {
            props.history.push(`/AddStrikeOff`, { params: Params });
        }
        else {
            let dataParams = {
                Operation: MasterStrikeOffID,
                Id: Params.strikeOffID,
            };
            CommonService.CheckMappedItem(dataParams).then(res => {
                if (res.data.outputResult === "-2") {
                    let Title = "Warning!";
                    let message = "This Strike Off is already mapped.";
                    let type = "warning";

                    Nodify(Title, type, message);
                }
                else {
                    setID({ showPopupDelete: true, Params: Params });
                }
            });
        }
    };

    const handleExpandRow = (StrikeOffID) => {
        setExpandedRows([]);
        // showLoader();
        // setTimeout(() => {
        strikeoffService.GetStrikeOffReferenceList(StrikeOffID).then((response) => {
            if (response.data) {
                setexpandRefList(response.data);
                strikeoffService.BindStrikeOffTabInfo(StrikeOffID).then((response) => {
                    if (response.data) {
                        setexpandColorList(response.data);
                        // Expand row Start
                        let currentExpandedRows = expandedRows;
                        const isRowExpanded = currentExpandedRows.includes(StrikeOffID);
                        let obj = {};
                        isRowExpanded ? (obj[StrikeOffID] = false) : (obj[StrikeOffID] = true);
                        setExpandState(obj);
                        currentExpandedRows = [];
                        const newExpandedRows = isRowExpanded ?
                            currentExpandedRows.filter(id => id !== StrikeOffID) :
                            currentExpandedRows.concat(StrikeOffID);
                        setExpandedRows(newExpandedRows);
                        // hideLoader();
                        // Expand row End
                    }
                });
            }
        });
        // }, 400);
    }

    const Delcallback = (value) => {

        setID({ showPopupDelete: false, Params: [] });
    }

    if (!currentUser) {
        return <Redirect to="/login" />;
    }

    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className="row">
                    <div className="col-xs-12 col-md-12">
                        <div className="widget">
                            <div className="widget-header ">
                                <span className="widget-caption">Strike Off List </span>
                            </div>
                            <div> {
                                isOpen && (
                                    <Lightbox
                                        mainSrc={isOpen}
                                        alt="bg image"

                                        onCloseRequest={
                                            () => setIsOpen(false)
                                        } />
                                )
                            } </div>
                            <div className="widget-body">
                                <div className="dataTables_wrapper form-inline no-footer">
                                    <div className="table-toolbar pull-left">
                                        <Search onSearch={
                                            value => {
                                                setSearch(value);
                                                setCurrentPage(1);
                                            }
                                        }
                                            placeholder={getPlaceholder}
                                            props={props}
                                        />

                                    </div>
                                    <div className="table-toolbar pull-right" style={{ display: activeMenu[0].isAdd === 1 ? '' : 'none' }}>
                                        &nbsp;
                                        <a className="btn btn-primary" onClick={() => pageRedirect('', 'Add', getPlaceholder)} title="Add Strike Off">
                                            <i className="fa fa-plus"></i>&nbsp;Add
                                        </a>
                                    </div>

                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                        <TableHeader headers={headers}
                                            onSorting={
                                                (field, order) => setSorting({ field, order })
                                            } />
                                        <tbody>
                                            {
                                                commentsData.map((comment,i) => (
                                                    <Fragment key={i}>
                                                    <>
                                                        <tr key={
                                                            comment.strikeOffID
                                                        }>
                                                            {/* <td> <i
                                                                className={
                                                                    expandState[comment.strikeoffID] ?
                                                                        'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                }
                                                                onClick={event => handleExpandRow(comment.strikeoffID)}
                                                            ></i></td> */}
                                                            <td style={{width:'25px'}}>  
                                                                  { i + 1}
                                                            </td>
                                                            <td>

                                                                <span>
                                                                {comment.buyerAndBrandName
                                                                    ? comment.buyerAndBrandName.replace(/-/g, '/')
                                                                    : '-'
                                                                }
                                                                </span>
                                                            </td>
                                                            <td className="trwidth_manual" style={{ textAlign: "center" }}>

                                                                {
                                                                    < img className="width_manual"
                                                                        src={comment.strikeoffLogoName === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images/Style/Strikeoff/' + comment.strikeoffLogoName}
                                                                        alt="Logo"

                                                                        onClick={
                                                                            () => setIsOpen(comment.strikeoffLogoName === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images/Style/Strikeoff/' + comment.strikeoffLogoName)
                                                                        }
                                                                    />
                                                                }</td>

                                                            <td>

                                                                <span>
                                                                    {comment.strikeOffName}
                                                                </span>
                                                            </td>
                                                            <td>{comment.strikeOffCode}</td>
                                                            {/* <td>{comment.buyerReferenceNo}</td>
                                                            <td>{comment.quality}</td> */}
                                                            <td>{comment.purchaseTypeName}</td>
                                                            <td>{comment.purchaseType === 1 ? comment.trimSupplierName : "-"}</td>

                                                            <td style={{ display: activeMenu[0].isEdit === 0 && activeMenu[0].isDelete === 0 ? 'none' : '' }}>
                                                                <span style={{ display: activeMenu[0].isEdit === 1 ? '' : 'none' }} title='Edit Strike Off' onClick={() => pageRedirect(comment, 'Edit', getPlaceholder)}
                                                                    className="btn btn-info btn-xs edit">
                                                                    <i className="fa fa-edit"></i>
                                                                </span>
                                                                &nbsp;
                                                                <span style={{ display: activeMenu[0].isDelete === 1 ? '' : 'none' }} title='Delete Strike Off' onClick={() => pageRedirect(comment, '', getPlaceholder)}
                                                                    className="btn btn-danger btn-xs delete">
                                                                    <i className="fa fa-trash-o"></i>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        {CriticalList.filter(x => x.strikeOffID === comment.strikeOffID && x.indexName === comment.indexName)[0] !== undefined && (
                                                            CriticalList.filter(x => x.strikeOffID === comment.strikeOffID && x.indexName === comment.indexName)[0].length !== 0 && (
                                                                <>
                                                                    {
                                                                        CriticalList.filter(x => x.strikeOffID === comment.strikeOffID && x.indexName === comment.indexName).map((critical, indexValue) => (
                                                                            (critical.notes !== '' || critical.targettoClose !== null || critical.completedOn !== null) && (
                                                                                <tr>
                                                                                    <td colSpan="6">

                                                                                        <span style={{ color: 'red' }}>{"Critical : " + (critical.strPantoneName + ' ' + critical.pantoneTypeName + ' - ' + critical.notes)} ,</span>
                                                                                        <span style={{ color: '#427fed' }}> Target to Close : {critical.targettoClose === null ? '-' : critical.targettoClose} ,</span>
                                                                                        <span style={{ color: '#00a65a' }}> Completed On : {critical.completedOn === null ? '-' : critical.completedOn}</span>
                                                                                        <br />
                                                                                    </td>
                                                                                </tr>

                                                                            )
                                                                        ))
                                                                    }
                                                                </>
                                                            )
                                                        )}
                                                    </>
                                                    </Fragment>
                                                ))
                                            }

                                            {
                                                totalItems === 0 ?
                                                    <tr>
                                                        <td colSpan="7" className='norecordfound'><span>No Records Found</span></td>
                                                    </tr> : ''
                                            }
                                        </tbody>
                                    </table>

                                    <PageCount onPageCount={
                                        value => {
                                            setITEMS_PER_PAGE(value);
                                            setCurrentPage(1);
                                        }
                                    } />
                                    <Pagination total={totalItems}
                                        itemsPerPage={
                                            parseInt(ITEMS_PER_PAGE)
                                        }
                                        currentPage={currentPage}
                                        onPageChange={
                                            page => setCurrentPage(page)
                                        } />
                                </div>
                            </div>
                            {
                                getID.showPopupDelete ? <SweetAlertPopup data={
                                    getID.Params
                                }
                                    deleteCallback={Delcallback}
                                    showpopup={true}
                                    pageActionId={MasterStrikeOffID}
                                    Msg={"Strike Off deleted successfully."} /> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
            {loader}
        </>
    )
};
export default MasterStrikeOffList;