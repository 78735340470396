import React, { useState, useEffect, useRef, Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactNotification from 'react-notifications-component';
import PPMeetingTab from './PPMeetingTab';
import SizeSetCheckTab from './SizeSetCheckTab';
import PilotRunTab from './PilotrunTab';
import InLineMidLineTab from './InLinemidLineTab';
import { Modal, Button } from "react-bootstrap";
import { LoadQADetailsreport } from "../../../../actions/inspectionQC";
import moment from "moment";
const InspectionQCMain = (props) => {
   ;
    const [tabName, setTabName] = useState("In and Mid Line Inspection");
    var defaultExStartDate = new Date();
    const [stateExDate, setStateExDate] = useState([
        {

            startDate: defaultExStartDate.setDate(defaultExStartDate.getDate() - 90),
            endDate: new Date(),
            key: 'selection',
            isShow: false
        }
    ]);

    // If check production or sampling
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
    let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal : activeMenus.IsProduction;
    const dispatch = useDispatch();
    useEffect(() => {
        setTabName("In and Mid Line Inspection")



        if (props.location.state != undefined && (props.location.state === null || props.location.state.params.TaskType === 'In and Mid Line Inspection')) {

            ActivateInAndMidTab();
        }
        else if (props.location.state != undefined && (props.location.state === null || props.location.state.params.TaskType === "Pilot Run")) {
            ActivatePilotTab()
        }
        else if (props.location.state != undefined && (props.location.state === null || props.location.state.params.TaskType === "Size Set Check")) {
            ActivatesizeTab()
        }
        else if (props.location.state != undefined && (props.location.state === null || props.location.state.params.TaskType === "PP Meeting")) {
            ActivatePPTab()
        }
        else {
            ActivateInAndMidTab();
            setTabName("In and Mid Line Inspection")
        }


        let StartExDate = null;
        let EndExDate = null;
        if (moment(stateExDate[0].startDate).isValid()) {
            StartExDate = moment(stateExDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateExDate[0].endDate).isValid()) {
            EndExDate = moment(stateExDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let paramss = {
            IsProduction: IsProduction,
            TaskDetailsName: 'In and Mid Line Inspection'
            , ExfacStartDate: StartExDate
            , ExfacEndDate: EndExDate
            , TNAStartDate: StartExDate
            , TNAEndDate: EndExDate
        }
        dispatch(LoadQADetailsreport(paramss));
    }, [IsProduction]);
    //props.location.state.params.taskdetailname = tabName;

    function TabClick(Tab) {
        setTabName(Tab);
        //props.location.state.params.taskdetailname = Tab;
    }


    const ActivateInAndMidTab = () => {
        document.getElementById("liInandMid").className = "active";
        var lipilot = document.getElementById("lipilot");
        lipilot.classList.remove("active");
        var lisize = document.getElementById("lisize");
        lisize.classList.remove("active");
        var lipp = document.getElementById("lipp");
        lipp.classList.remove("active");

        document.getElementById("detailTab").className = "tab-pane col-lg-12 active";
        document.getElementById("inspectionTab").className = "tab-pane col-lg-12";
        document.getElementById("summaryTab1").className = "tab-pane col-lg-12";
        document.getElementById("summaryTab2").className = "tab-pane col-lg-12";
    }
    const ActivatePilotTab = () => {
        document.getElementById("lipilot").className = "active";
        var liInandMid = document.getElementById("liInandMid");
        liInandMid.classList.remove("active");
        var lisize = document.getElementById("lisize");
        lisize.classList.remove("active");
        var lipp = document.getElementById("lipp");
        lipp.classList.remove("active");

        document.getElementById("detailTab").className = "tab-pane col-lg-12 active";
        document.getElementById("inspectionTab").className = "tab-pane col-lg-12";
        document.getElementById("summaryTab1").className = "tab-pane col-lg-12";
        document.getElementById("summaryTab2").className = "tab-pane col-lg-12";
    }

    const ActivatesizeTab = () => {
        document.getElementById("lisize").className = "active";
        var liInandMid = document.getElementById("liInandMid");
        liInandMid.classList.remove("active");
        var lipilot = document.getElementById("lipilot");
        lipilot.classList.remove("active");
        var lipp = document.getElementById("lipp");
        lipp.classList.remove("active");

        document.getElementById("detailTab").className = "tab-pane col-lg-12 active";
        document.getElementById("inspectionTab").className = "tab-pane col-lg-12";
        document.getElementById("summaryTab1").className = "tab-pane col-lg-12";
        document.getElementById("summaryTab2").className = "tab-pane col-lg-12";
    }
    const ActivatePPTab = () => {
        document.getElementById("lipp").className = "active";
        var liInandMid = document.getElementById("liInandMid");
        liInandMid.classList.remove("active");
        var lisize = document.getElementById("lisize");
        lisize.classList.remove("active");
        var lipilot = document.getElementById("lipilot");
        lipilot.classList.remove("active");

        document.getElementById("detailTab").className = "tab-pane col-lg-12 active";
        document.getElementById("inspectionTab").className = "tab-pane col-lg-12";
        document.getElementById("summaryTab1").className = "tab-pane col-lg-12";
        document.getElementById("summaryTab2").className = "tab-pane col-lg-12";
    }




    return (
        <Fragment>
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="tabbable">
                                        <ul className="nav nav-tabs nav-justified" id="myTab5">

                                            <li className="" id="liInandMid">
                                                <a data-toggle="tab" href="#detailTab" onClick={event => TabClick("In and Mid Line Inspection", event)}>
                                                    <h5>In and Mid Line Inspection</h5>
                                                </a>
                                            </li>
                                            <li className="" id="lipilot" >
                                                <a data-toggle="tab" href="#inspectionTab" onClick={event => TabClick("Pilot Run", event)}>
                                                    <h5>Pilot Run</h5>
                                                </a>
                                            </li>
                                            <li className="" id="lisize" >
                                                <a data-toggle="tab" href="#summaryTab1" onClick={event => TabClick("Size Set Check", event)}>
                                                    <h5>Size Set Check</h5>
                                                </a>
                                            </li>
                                            <li className="" id="lipp" >
                                                <a data-toggle="tab" href="#summaryTab2" onClick={event => TabClick("PP Meeting", event)}>
                                                    <h5>PP Meeting</h5>
                                                </a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div id="detailTab" className="tab-pane in active" style={{ width: "100%" }}>
                                                {tabName === 'In and Mid Line Inspection' && <InLineMidLineTab props={props} TaskName={tabName} />}
                                            </div>
                                            <div id="inspectionTab" className="tab-pane" style={{ width: "100%" }}>
                                                {tabName === 'Pilot Run' && <PilotRunTab props={props} TaskName={tabName} />}
                                            </div>
                                            <div id="summaryTab1" className="tab-pane" style={{ width: "100%" }}>
                                                {tabName === 'Size Set Check' && <SizeSetCheckTab props={props} TaskName={tabName} />}
                                            </div>
                                            <div id="summaryTab2" className="tab-pane" style={{ width: "100%" }}>
                                                {tabName === 'PP Meeting' && <PPMeetingTab props={props} TaskName={tabName} />}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )

};
export default InspectionQCMain;