import axios from "axios";
import authHeader from "../auth-header";


class TestingReportTemplateServices {

 getTestReportTempDetails(Operation, Id) { //Buyer and Brand Details get
  return axios.get(window.$APIBaseURL + "Master/getTestReportTempDetails?Operation=" + Operation + '&TempID=' + Id, {
   headers: {
    authorization: authHeader()
   }
  });
 }


 InsertTestReportTempDetails(Params) {

  return axios({
   method: 'post',
   url: window.$APIBaseURL + 'Master/InsertTestReportTempDetails',
   data: Params,
   headers: {
    authorization: authHeader()
   }
  });
 }

 UpdateDeleteTestReportTempDetails(Params) {
  return axios({
   method: 'post',
   url: window.$APIBaseURL + 'Master/UpdateDeleteTestReportTempDetails',
   data: Params,
   headers: {
    authorization: authHeader()
   }
  });
 }


}


export default new TestingReportTemplateServices()

