import React, { useState, useEffect, Fragment } from "react";
import Form, { form } from "react-validation/build/form";
import PantoneService from "../../../services/Master/PantoneService";
import Select from "react-validation/build/select";
import ProgramService from "../../../services/Style/ProgramService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Nodify from "../../Common/ReactNotification";
import Draggable from 'react-draggable';
import ModalDialog from 'react-bootstrap/ModalDialog';
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import PantoneTypePopup from "../../quickMaster/_PantoneType";
import PantonePopup from "../../quickMaster/_Pantone";
import SwatchPopup from "../../quickMaster/_Swatch";
import Lightbox from "react-image-lightbox"
import ProcessTypePopup from "../../quickMaster/_ProcessType";
import styleService from "../../../services/Style/styleService";
import Reactselect from 'react-select';
import axios from "axios";
import { getTNALabDipAlreadyMapList, updateProgramGridList } from "../../../actions/style";
import $ from "jquery";


const Program = ({ props, ProgramList, ProgramCallback, SKUDetails, FabricList, ClearSKU, IsValidationCallback, ProgramInfo,
    Buttonviews, ProgramInfoQuick }) => {

    ;

    const [inputFields, setInputFields] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [submittedPop, setSubmittedPop] = useState(false);
    const [getPopUpValidation, setPopUpValidation] = useState(false);
    const [getHeader, setHeader] = useState();
    const [getSKUList, setSKUList] = useState(SKUDetails);
    const [getPantoneType, setPantoneType] = useState([]);
    const [getPantone, setPantone] = useState([]);
    const [getFabricPart, setFabricPart] = useState([]);
    const [getFabric, setFabric] = useState();
    const [getSKU, setSKU] = useState();
    const [getApprovalType, setApprovalType] = useState([{ ID: 1, Name: 'Direct New' }, { ID: 2, Name: 'Manual' }, { ID: 3, Name: 'Existing' }]);
    const [getSwatch, setSwatch] = useState([]);
    const [AllPantonetypeList, setAllPantonetypeList] = useState([]);
    const [getSwatchSelected, setSwatchSelected] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [getImagename, setImagename] = useState();
    const [isProcesstypePopupOpen, setProcessTypePopupOpen] = useState(false);
    const [getProcessTypeList, setProcessTypeList] = useState([]);
    const [getMaxidProcessType, setMaxidProcessType] = useState(0);
    const [newFilestate, setNewFileState] = useState([])

    const dispatch = useDispatch();

    // const [exitingApprovalTypePop, setExitingApprovalTypePop] = useState({ IsPopup: false, PantoneID: 0, PantoneTypeID:0});

    //#region Popup Initialization
    const [isPantonetypePopupOpen, setIsPantonetypePopupOpen] = useState(false);
    const [isPantonePopupOpen, setIsPantonePopupOpen] = useState(false);
    const [isSwatchPopupOpen, setIsSwatchPopupOpen] = useState(false);
    // const [exitingApprovalType, setExitingApprovalType] = useState([]);

    //reducer

    const { user: currentUser } = useSelector((state) => state.auth);
    const reducerState = useSelector((state) => state.style);
    const getProgramList = reducerState.programGridList;
    const TNALabDipMapList = reducerState.TNALabDipMapList;
    const allLabDipMapApproval = reducerState.allLabDipMapApproval;

    const [getFiles, setFiles] = useState([]);
    const [isOpenimg, setIsOpenimg] = useState(false);
    const [Buttonview, SetButtonview] = useState("Hide");
    const values = [...inputFields];

    let styles1 = {
        control: styles1 => ({ ...styles1, width: "230px" }),
    }

    let styles2 = {
        control: styles2 => ({ ...styles2, width: "230", border: '1px solid red' }),
    }

    function LoadProgram() {

        setHeader("Add");
        let AssignSKU = [];
        // ADD- COMBINATION(AllProgramList) AND NEW ADD(getSKUList)
        let arr;
        let arrVal = []; let SkuidforEdit;
        if (getProgramList.length > 0 && ProgramList === undefined) {

            let getPantoneName = getPantoneType.filter(x => x.pantoneTypeID === parseInt(ProgramList.pantoneTypeID));
            getProgramList.forEach((program, i) => {
                program.IsCriticals.forEach((RemInd, j) => { RemInd.IndexName = ''; });
                program.IsCriticals[0].IndexName = '';
                arr = program.skuid.toString().split(',');
                for (let sku of arr) {
                    let res = getSKUList.filter(x => x.value === parseInt(sku));
                    if (res.length !== 0) {
                        arrVal.push({
                            value: res[0].value,
                            label: res[0].label
                        });
                    }
                }
                SkuidforEdit = arrVal;
                arrVal = [];
                values.push({
                    StyleProgramID: program.styleProgramID, SKUID: program.skuid.toString(), SkuidforEdit: SkuidforEdit, SKUName: program.skuName, PantoneTypeID: program.pantoneTypeID,
                    PantoneID: program.pantoneID, PantoneColor: program.pantoneColor, RefColorName: program.refColorName, BuyerRefNo: '',
                    SwatchImageID: program.swatchImageID, SwatchImagePath: program.swatchImagePath, IndexName: '', //program.indexName,
                    ProcessTypeId: program.processTypeId, ApprovalTypeID: program.approvalTypeID, ApprovalTypeName: '',
                    IsDeleted: 0, Remark: '', Critical: parseInt(program.cirtical), PantoneCode: program.pantoneCode,
                    DontShow: 1, FabricID: program.fabricID, FabricPartID: program.fabricPartID,
                    IsCriticals: program.IsCriticals === undefined ? [{
                        ProgramCirticalInfoID: 0, IsCritical: '', RemindMe: 0, CheckSave: false,
                        TargettoClose: '', CompletedOn: '', IsDeleted: 0, IndexName: '', //program.indexName
                    }] : program.IsCriticals,
                    IsManual: program.IsManual === undefined ?
                        [{ StyleProgramManualId: 0, IsManual: '', ManualCloseComment: '', ManualCloseDate: '', IsDeleted: 0, CheckSave: false, StyleProgramIndexName: '', Files: [] }]
                        : program.IsManual,
                    PantoneTypeName: getPantoneName.length !== 0 ? getPantoneName[0].pantoneTypeName : '',
                    ExitingApprovalTypePop: { IsOpen: false },
                    labdibSubmissionApprovalID: program.labdibSubmissionApprovalID
                    //ExitingApprovalType: []

                });
            });

            var existingarrval = []; var staticexistingarrval = [];
            getProgramList.forEach((program, j) => {

                arr = program.skuid.toString().split(',');
                for (let sku of arr) {
                    let res = getSKUList.filter(x => x.value === parseInt(sku));
                    if (existingarrval.length !== 0) {
                        if (res.length > 0 && existingarrval.filter(x => x.value === res[0].value).length === 0) {

                            existingarrval.push({
                                value: res[0].value,
                                label: res[0].label
                            });

                            staticexistingarrval.push({
                                value: res[0].value,
                                label: res[0].label
                            });

                        }
                    }
                    else {
                        if (res.length !== 0) {
                            existingarrval.push({
                                value: res[0].value,
                                label: res[0].label
                            });
                            staticexistingarrval.push({
                                value: res[0].value,
                                label: res[0].label
                            });
                        }
                    }
                }
            })
            getSKUList.forEach((sku, i) => {
                if (sku.value !== 0) {
                    AssignSKU.push({ SKUID: sku.id, SKUName: sku.name });
                    arrVal = [];
                    ;
                    let res = AssignSKU.filter(x => x.SKUID === parseInt(sku.id));
                    arrVal.push({
                        value: res[0].SKUID,
                        label: res[0].SKUName
                    });
                    SkuidforEdit = arrVal;
                }



                getProgramList.forEach((program, j) => {

                    // arr = program.skuid.split(',');
                    // for (let sku of arr) {
                    //     let res = getSKUList.filter(x => x.value === parseInt(sku));
                    //     //if (existingarrval.filter(x => x.value === res[0].value).length === 0) {
                    //     existingarrval.push({
                    //         value: res[0].value,
                    //         label: res[0].label
                    //     });
                    //     //  }
                    // }


                    if (program.DontShow !== 1 && program.SKUID !== sku.id) {
                        let Count = 0;
                        existingarrval.forEach((val, i) => {
                            if (val.value === sku.id) {
                                Count++;
                            }
                        });
                        if (Count === 0) {

                            //let AssignIndex = values.length;
                            values.push({
                                StyleProgramID: 0, SKUID: sku.id.toString(), SkuidforEdit: SkuidforEdit, SKUName: sku.name, PantoneTypeID: 0, PantoneID: 0, PantoneColor: '', PantoneCode: '',
                                RefColorName: "", BuyerRefNo: '', SwatchImageID: 0, SwatchImagePath: '', IndexName: '', //AssignIndex.toString(),
                                ProcessTypeId: 0, ApprovalTypeID: 0, ApprovalTypeName: '', IsDeleted: 0, Remark: '', Critical: 0,
                                DontShow: 0,
                                IsCriticals: [{
                                    ProgramCirticalInfoID: 0, IsCritical: '', RemindMe: 0, CheckSave: false,
                                    TargettoClose: '', CompletedOn: '', IsDeleted: 0, IndexName: '', //AssignIndex.toString()
                                }],
                                IsManual: [{ StyleProgramManualId: 0, IsManual: '', IsDeleted: 0, ManualCloseComment: '', ManualCloseDate: '', CheckSave: false, StyleProgramIndexName: '', Files: [] }],
                                FabricID: 0, FabricPartID: 0,
                                PantoneTypeName: '',
                                ExitingApprovalTypePop: { IsOpen: false },
                                labdibSubmissionApprovalID: program.labdibSubmissionApprovalID
                                // ExitingApprovalType: []
                            });
                            existingarrval.push({
                                value: SkuidforEdit[0].value,
                                label: SkuidforEdit[0].label
                            });
                            // existingarrval = [];
                        } else {
                            // existingarrval = [];
                            Count = 0;
                        }
                    }
                });
            });

            if (staticexistingarrval.length >= getSKUList.length) {
                values.push({
                    StyleProgramID: 0, SKUID: '', SkuidforEdit: [], SKUName: '', PantoneTypeID: 0, PantoneID: 0, PantoneColor: '', PantoneCode: '',
                    RefColorName: "", BuyerRefNo: '', SwatchImageID: 0, SwatchImagePath: '', IndexName: '', //AssignIndex.toString(),
                    ProcessTypeId: 0, ApprovalTypeID: 0, ApprovalTypeName: '', IsDeleted: 0, Remark: '', Critical: 0,
                    DontShow: 0,
                    IsCriticals: [{
                        ProgramCirticalInfoID: 0, IsCritical: '', RemindMe: 0, CheckSave: false,
                        TargettoClose: '', CompletedOn: '', IsDeleted: 0, IndexName: '', //AssignIndex.toString()
                    }],
                    IsManual: [{ StyleProgramManualId: 0, IsManual: '', ManualCloseComment: '', IsDeleted: 0, ManualCloseDate: '', CheckSave: false, StyleProgramIndexName: '', Files: [] }],
                    FabricID: 0, FabricPartID: 0, PantoneTypeName: '',
                    ExitingApprovalTypePop: { IsOpen: false },

                    //ExitingApprovalType: []
                });
            }

        } else {
            //NEW ADD
            if (getProgramList.length === 0 && ProgramList === undefined) {
                let getSKU = [];

                ClearSKU.forEach((sku, i) => {
                    getSKU.push({
                        id: sku.skuID === undefined ? sku.SKUID : sku.skuID,
                        name: sku.skuName === undefined ? sku.SKUName : sku.skuName
                    })
                });


                getSKU.forEach((sku, i) => {
                    AssignSKU.push({ SKUID: sku.id, SKUName: sku.name });
                    arrVal = [];

                    let res = AssignSKU.filter(x => x.SKUID === parseInt(sku.id));
                    arrVal.push({
                        value: res[0].SKUID,
                        label: res[0].SKUName
                    });
                    SkuidforEdit = arrVal;
                    values.push({
                        StyleProgramID: 0, SKUID: sku.id.toString(), SkuidforEdit: SkuidforEdit, SKUName: sku.name, PantoneTypeID: 0, PantoneID: 0, PantoneColor: '', PantoneCode: '',
                        RefColorName: "", SwatchImageID: 0, SwatchImagePath: '', IndexName: '',
                        ProcessTypeId: 0, ApprovalTypeID: 0, ApprovalTypeName: '', IsDeleted: 0, Critical: 0, Manual: 0, DontShow: 0,
                        FabricID: 0, FabricPartID: 0,
                        BuyerRefNo: '', Remark: '',
                        IsCriticals: [{ IsCritical: '', CompletedOn: '', RemindMe: 0, TargettoClose: '', CheckSave: false, IsDeleted: 0, IndexName: '' }],
                        IsManual: [{ IsManual: '', ManualCloseComment: '', ManualCloseDate: '', IsDeleted: 0, CheckSave: false, StyleProgramIndexName: '', Files: [] }],
                        PantoneTypeName: '',
                        ExitingApprovalTypePop: { IsOpen: false },
                        labdibSubmissionApprovalID: 0
                        //ExitingApprovalType: []
                    });
                });
                if (values[0].IsManual.length === 0) {

                    values[0].IsManual[0].Files.map((x, index) => {
                        values[0].IsManual[0].Files[index].ManualIndex = index;
                    })
                }

            }
        }

        // EDIT- PROGRAMLIST
        if (ProgramList !== undefined) {
            ;
            setHeader("Edit");
            if (ProgramList.styleProgramID !== 0) {
                AssignSKU.push({ SKUID: ProgramList.skuid, SKUName: ProgramList.skuName, });

                // arr = ProgramList.skuid.split(',');
                // for (let sku of arr) {
                //     let res = getSKUList.filter(x => x.id === parseInt(sku));
                //     if (res.length !== 0) {
                //         arrVal.push({
                //             value: res[0].value,
                //             label: res[0].label
                //         });
                //     }
                //     else {
                //         let arrs = ProgramList.skuName.split(',');
                //         for (let skuna of arrs) {
                //             res = getSKUList.filter(x => x.name === skuna)
                //             arrVal.push({
                //                 value: res[0].value,
                //                 label: res[0].label
                //             });
                //         }
                //     }
                // }

                let arrs = ProgramList.skuName.split(',');
                ProgramList.skuid = '';
                for (let skuna of arrs) {
                    ;
                    let res = getSKUList.filter(x => x.name === skuna.toString().trim())
                    arrVal.push({
                        value: res[0]?.value,
                        label: res[0]?.label
                    });
                    if (ProgramList.skuid === '') {
                        ProgramList.skuid = res[0]?.value;
                    }
                    else {
                        ProgramList.skuid = ProgramList.skuid + ',' + res[0]?.value;
                    }
                }
                SkuidforEdit = arrVal;
                let getPantoneName = ProgramList.pantoneTypeName;
                values.push({
                    StyleProgramID: ProgramList.styleProgramID, SKUID: ProgramList?.skuid?.toString(), SkuidforEdit: SkuidforEdit, SKUName: ProgramList.skuName, PantoneTypeID: ProgramList.pantoneTypeID,
                    PantoneID: ProgramList.pantoneID, PantoneColor: ProgramList.pantoneColor, RefColorName: ProgramList.refColorName,
                    SwatchImageID: ProgramList.swatchImageID, SwatchImagePath: ProgramList.swatchImagePath, IndexName: ProgramList.indexName, //ProgramList.indexName,
                    ProcessTypeId: ProgramList.processTypeId, ApprovalTypeID: ProgramList.approvalTypeID, ApprovalTypeName: ProgramList.approvalTypeName,
                    IsDeleted: 0,
                    PantoneCode: ProgramList.pantoneCode, DontShow: 0,
                    //IsCriticals: [{ IsCritical: ProgramList.IsCritical, CompletedOn: new Date(ProgramList.CompletedOn), RemindMe: parseInt(ProgramList.RemindMe), TargettoClose: new Date(ProgramList.TargettoClose), IndexName: (ProgramList.IndexName).toString() }],
                    IsCriticals: ProgramList.IsCriticals === undefined ? [{
                        ProgramCirticalInfoID: 0, IsCritical: '', RemindMe: 0, CheckSave: false,
                        TargettoClose: '', CompletedOn: '', IsDeleted: 0, IndexName: ProgramList.indexName, //ProgramList.indexName
                    }] : ProgramList.IsCriticals,
                    IsManual: ProgramList.IsManual === undefined ?
                        [{ StyleProgramManualId: 0, IsManual: '', ManualCloseComment: '', IsDeleted: 0, ManualCloseDate: '', CheckSave: false, StyleProgramIndexName: '', Files: [] }]
                        : ProgramList.IsManual,
                    FabricID: ProgramList.fabricID, FabricPartID: ProgramList.fabricPartID,
                    BuyerRefNo: '', Remark: '',
                    PantoneTypeName: getPantoneName === 'Others' ? getPantoneName : '',
                    ExitingApprovalTypePop: { IsOpen: false },
                    labdibSubmissionApprovalID: ProgramList.labdibSubmissionApprovalID,

                });
                setSwatchSelected(getPantoneName === 'Others' ? true : false)
            }

            // if (values[0].IsManual.length !== 0) {
            //    
            //     values[0].IsManual[0].Files.map((x, index) => {
            //         values[0].IsManual[0].Files[index].ManualIndex = index;
            //     })
            // }

        }

        setSKU(AssignSKU);
        setInputFields(values);
        ProgramCallback(values);
    }


    useEffect(() => {
        PantoneService.getPantonelist(1, 0, 0).then((response) => {
            if (response.data) {
                setPantoneType(response.data);
            }
        });

        PantoneService.loadPantoneTypeList().then((response) => {
            if (response.data) {
                setAllPantonetypeList(response.data);
            }
        }).catch(() => { });

        PantoneService.LoadFabricPartList().then((response) => {
            if (response.data) {
                setFabricPart(response.data);
            }
        });

        PantoneService.LoadPantoneList().then((response) => {
            if (response.data) {
                setPantone(response.data);
            }
        });
        styleService.LoadProcessType()
            .then((response) => {
                if (response.data) {
                    setProcessTypeList(response.data);
                } else {
                }
            })
            .catch(() => { });
        let BuyerID = props.location.state.params.buyerID;
        let BrandID = props.location.state.params.brandID;
        PantoneService.LoadSwatchList(BuyerID, BrandID).then((response) => {
            if (response.data.length > 0) {
                setSwatch(response.data);
            }
        });

        setFabric(FabricList);
        setSKUList(SKUDetails);
        ;

        // SKUService.LoadSkuNameList(props.location.state.params.styleID).then((response) => {
        //     if (response.data) {
        //         setSKUList(response.data);
        //     }
        // }).catch(() => { });



        LoadProgram();
    }, []);

    useEffect(() => {
        setSubmitted(IsValidationCallback);
    }, [IsValidationCallback === true]);

    const Drag = (props) => {
        return <Draggable handle=".modal-title"><ModalDialog {...props} /></Draggable>
    }

    // let DynamicClassName = getHeader === 'ADD' ? "col-lg-10 col-sm-8 col-xs-12" : "col-lg-12 col-sm-12 col-xs-12";
    let DynamicClassName = getHeader === 'Add' ? "col-lg-12 col-sm-12 col-xs-12" : "col-lg-12 col-sm-12 col-xs-12";

    const handleSku = (event, index) => {
        if (event.length === 0) {
            values[index].SKUID = "";
            values[index].SkuidforEdit = '';
            // values[index].SkuColorNameIds = "";
        } else {
            let strValue = "";
            for (let Sku of event) {
                if (strValue === "") {
                    strValue = Sku.value.toString();
                } else {
                    strValue = strValue + "," + Sku.value;
                }
            }
            values[index].SKUID = strValue;
            values[index].SkuidforEdit = event;
        }

        setInputFields(values);
    }
    const handleInputChange = (event, index, subindex) => {
        ;
        // ResetMaxId("")
        setMaxidProcessType(0);
        const values = [...inputFields];
        if (event.target.name === 'PantoneID') {
            values[index][event.target.name] = parseInt(event.target.value);
            values[index].PantoneColor = ""
            if (values[index].PantoneTypeName !== "Others") {
                for (var getColor = 0; getColor < getPantone.length; getColor++) {
                    if (parseInt(event.target.value) === getPantone[getColor].pantoneID) {
                        values[index].PantoneColor = (getPantone[getColor].pantoneColor);
                    }
                }
            }
            else {
                var Swatch = getSwatch.filter(x => x.id === parseInt(values[index].PantoneID));
                if (Swatch.length !== 0) {
                    values[index].PantoneColor = Swatch[0].label;
                }
            }

            values[index].ApprovalTypeID = 0;
            values[index].labdibSubmissionApprovalID = 0;
        } else if (event.target.name === 'PantoneTypeID' || event.target.name === 'ApprovalTypeID' ||
            event.target.name === 'FabricID' || event.target.name === 'FabricPartID' || event.target.name === 'ProcessTypeId') {
            values[index][event.target.name] = parseInt(event.target.value);
            if (event.target.name === "PantoneTypeID") {

                var Pantonetype = getPantoneType.filter(x => x.pantoneTypeID === parseInt(event.target.value));

                if (Pantonetype.length > 0) {

                    values[index].PantoneTypeName = Pantonetype[0].pantoneTypeName;

                    values[index].PantoneID = "";
                    values[index].PantoneColor = "";

                    var BuyerID = props.location.state.params.buyerID;
                    var BrandID = props.location.state.params.brandID;


                    if (Pantonetype[0].pantoneTypeName === "Others") {
                        if (BuyerID === 0 || BrandID === 0) {
                            values[index].PantoneTypeID = 0;
                            values[index].PantoneID = "";
                            values[index].PantoneColor = "";
                            Nodify("Warning!", "warning", "  Select Buyer and Brand");
                            // setSubmitted(true);
                            return false;
                        }
                        else {
                            // values[index].PantoneTypeID = 0;
                            values[index].PantoneID = "";
                            values[index].PantoneColor = "";
                            //ResetMaxId("ProcessTypeId");
                            PantoneService.LoadSwatchList(BuyerID, BrandID).then((response) => {
                                if (response.data) {
                                    setSwatch(response.data);
                                }
                            });

                            setSwatchSelected(true);
                        }
                    }
                    else {
                        setSwatchSelected(false);
                    }

                    values[index].ApprovalTypeID = 0;
                    values[index].labdibSubmissionApprovalID = 0;
                }
                else {
                    values[index].PantoneColor = "";
                    values[index].PantoneID = "";
                }

            }
            else if (event.target.name === 'ApprovalTypeID') {

                if (event.target.value !== '2') {
                    if (values[index].IsManual !== undefined) {
                        values[index].IsManual[0].ManualCloseComment = '';
                        //values[index].IsManual[0].Files = [];
                        values[index].IsManual.map((element, i) => {
                            element.Files = [];
                            //values[index].IsManual[0].Files[i].fileName = '';
                        });
                        values[index].IsManual[0].IsDeleted = 1;
                    }
                }
                else {
                    if (event.target.value === '2') {
                        if (values[index].IsManual !== undefined && values[index].IsManual.length !== 0) {
                            values[index].IsManual[0].IsDeleted = 0;
                        }
                    }
                }
            }
            // else if (event.target.name === 'IsCritical') {
            //     values[index].IsCriticals[subindex].IsCritical = event.target.value;
            // } else if (event.target.name === 'RemindMe') {
            //     values[index].IsCriticals[subindex].RemindMe = parseInt(event.target.value);
            // }
        }
        else if (event.target.name !== 'SKUID') {
            let inputText = '';
            if (event.target.value.trim() !== '') {
                inputText = event.target.value;
            }
            values[index][event.target.name] = inputText;
        }
        setInputFields(values);
        ProgramCallback(values);
    };

    const handleChangeIsManualCloseComments = (event, index, feild, subindex) => {
        const values = [...inputFields];

        let inputText = '';


        if (feild === 'Comment') {
            if (event.target.value.trim() !== '') { inputText = event.target.value; }
            if (ProgramList !== undefined && getHeader === "Edit") {
                values[index].IsManual[subindex].ManualCloseComment = inputText;
                values[index].IsManual[subindex].StyleProgramIndexName = (ProgramList !== undefined && getHeader === "Edit") ? values[index].IndexName : 0;
            }
            else {
                values[index].IsManual[subindex].ManualCloseComment = inputText;
            }
        } else {
            values[index].IsManual[subindex].ManualCloseDate = event;
        }
        setInputFields(values);
        ProgramCallback(values);
    }

    const RemoveFiles = async (e, index, fileindex, manindex, styleProgramManualFilelId, styleProgramIndexName, styleProgramFileIndexName) => {

        //values[index].IsManual[0].Files.splice(fileindex, 1);
        if (ProgramList !== undefined && getHeader === "Edit") {
            // const indexs = values[index].IsManual[manindex].Files.filter(obj => obj.styleProgramManualFilelId === styleProgramManualFilelId)
            // values[index].IsManual[manindex].Files[indexs].IsDeleted = 1;
            const Mainindexs = values[index].IsManual[0].Files.filter(obj => obj.styleProgramIndexName === styleProgramIndexName);
            const storeindex = newFilestate.filter(x => x.styleProgramManualFilelId !== 0 && x.IsDeleted !== 1)
            if (storeindex.length === Mainindexs.length) {
                newFilestate[fileindex].IsDeleted = 1
                values[index].IsManual[manindex].Files = newFilestate
            } else {
                // let tempindex = 0;
                // inputFields.map((v, vi) => {
                //    
                //     v.IsManual[0].Files.map((x, indexs) => {
                //         inputFields[vi].IsManual[0].Files[indexs].ManualIndex = tempindex;
                //         tempindex++;
                //     })
                // })
                const indexs = values[index].IsManual[0].Files.findIndex(obj => obj.styleProgramManualFilelId === styleProgramManualFilelId && obj.styleProgramFileIndexName === styleProgramFileIndexName);
                values[index].IsManual[0].Files[indexs].IsDeleted = 1;
            }

            setInputFields(values);
        }
        else {
            //const indexs = values[index].IsManual[manindex].Files.filter(obj => obj.styleProgramManualFilelId === Id)[0].ManualIndex;
            // values[index].IsManual[manindex].Files[ManualIndex].IsDeleted = 1;
            values[index].IsManual[0].Files.splice(fileindex, 1);
        }
        setFiles(values[index].IsManual[0].Files);
        setInputFields(values);
    }

    const ViewFile = (event, filename) => { // event.preventDefault();
        setIsOpenimg(true);
        setImagename(filename);
    }

    const filevals = [...getFiles]
    const [getFileIndex, setFileIndex] = useState();
    const AppendFiles = async (e, index, subindex) => {

        setFileIndex(index);
        const file = e.target.files[0];
        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png'];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#FileUpload').val("");
            }
            else {

                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "TNA");
                try {
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                    values[index].IsManual[subindex].Files.push({
                        styleProgramManualFilelId: 0,
                        fileName: file.name,
                        filePath: "Images/TNAImages/" + file.name,
                        fileType: file.type,
                        // styleProgramIndexName: index.toString(),
                        styleProgramIndexName: (ProgramList !== undefined && getHeader === "Edit") ? values[index].IndexName : index.toString(),
                        styleProgramFileIndexName: values[index].IsManual[subindex].Files.length.toString(),
                        IsDeleted: 0
                        //setFileIndex(index.toString())
                    })
                    values[index].IsManual[subindex].Files.map((x, indexs) => {
                        values[index].IsManual[subindex].Files[indexs].ManualIndex = indexs;
                    })
                    //values[index].IsManual[subindex].Files = filevals;
                    setInputFields(values);
                    setFiles(filevals);
                    $('#FileUpload').val("");

                } catch (ex) {
                    console.log(ex);
                }
            }
        }
    }

    const handleChangeCritical = (index, e, FieldName, SubIndex) => {
        ;
        let inputText = '';

        const values = [...inputFields];
        if (FieldName === 'IsCritical') {
            if (e.target.value.trim() !== '') { inputText = e.target.value; }
            if (e !== null) {
                values[index].IsCriticals[SubIndex].IsCritical = inputText;
            }
            else {
                values[index].IsCriticals[SubIndex].IsCritical = '';
            }
        } else if (FieldName === 'RemindMe') {
            if (e.target.value.trim() !== '') { inputText = e.target.value; }
            if (e !== null) {
                values[index].IsCriticals[SubIndex].RemindMe = parseInt(inputText);
            }
            else {
                values[index].IsCriticals[SubIndex].RemindMe = 0;
            }
        }
        else if (FieldName === "TargettoClose") {
            if (e !== null) {
                values[index].IsCriticals[SubIndex].TargettoClose = e;
            }
            else {
                values[index].IsCriticals[SubIndex].TargettoClose = '';
            }
        }
        else if (FieldName === "CompletedOn") {
            if (e !== null) {
                values[index].IsCriticals[SubIndex].CompletedOn = e;
            }
            else {
                values[index].IsCriticals[SubIndex].CompletedOn = '';
            }
        }
        setInputFields(values);
        ProgramCallback(values);
    }

    const CustomInput = (props) => {
        return (
            <>
                <input
                    className="form-control DatePickerCalenderHoliday"
                    onClick={props.onClick}
                    value={props.value}
                    type="text"
                    readOnly={true}
                    placeholder="DD/MM/YYYY"
                    style={{ border: getPopUpValidation === true && props.id === 'TargettoClose' && props.value === '' ? '1px solid red' : '' }}
                />
            </>
        )
    }

    const handleAddFields = (Operation, index) => {

        const values = [...inputFields];
        setSubmitted(false);
        let CheckCritical = true;

        if (values[index].Critical === 1 && values[index].IsCriticals[0].IsCritical === '') {
            CheckCritical = false;
        }

        if (CheckCritical) {
            if (values[index].PantoneTypeID === 0 || values[index].PantoneID === 0 || values[index].PantoneColor === '' ||
                values[index].ProcessTypeId === 0 //values[index].RefColorName === '' ||
            ) {
                setSubmitted(true);
            } else {
                let getCopy = values[index];
                let AssCriticals = [];
                if (getCopy.IsCriticals[0].IsCritical === '') {
                    AssCriticals = [{
                        ProgramCirticalInfoID: 0, IsCritical: '', RemindMe: 0, CheckSave: false,
                        TargettoClose: '', CompletedOn: '', IsDeleted: 0, IndexName: '',
                    }]
                } else {
                    AssCriticals = [{
                        ProgramCirticalInfoID: getCopy.IsCriticals[0].ProgramCirticalInfoID, IsCritical: getCopy.IsCriticals[0].IsCritical,
                        RemindMe: parseInt(getCopy.IsCriticals[0].RemindMe), CheckSave: false,
                        TargettoClose: getCopy.IsCriticals[0].TargettoClose, CompletedOn: getCopy.IsCriticals[0].CompletedOn,
                        IsDeleted: 0, IndexName: '',
                    }]
                }

                let CopyManual = [];
                if (getCopy.IsManual[0].ManualCloseComment === '') {
                    CopyManual = [{ StyleProgramManualId: 0, IsManual: '', ManualCloseComment: '', IsDeleted: 0, ManualCloseDate: '', CheckSave: false, StyleProgramIndexName: '', Files: [] }]
                } else {

                    CopyManual = [{
                        StyleProgramManualId: getCopy.IsManual[0].StyleProgramManualId, IsManual: getCopy.IsManual[0].IsManual,
                        ManualCloseComment: getCopy.IsManual[0].ManualCloseComment, ManualCloseDate: getCopy.IsManual[0].ManualCloseDate,
                        CheckSave: false, StyleProgramIndexName: '', IsDeleted: 0, Files: getCopy.IsManual[0].Files
                    }]
                }
                let count = index;
                count++;
                let EmptyList = {};
                if (Operation === 'Copy') {

                    EmptyList = {
                        StyleProgramID: 0, SKUID: getCopy.SKUID, SKUName: getCopy.SKUName, PantoneTypeID: parseInt(getCopy.PantoneTypeID),
                        PantoneID: parseInt(getCopy.PantoneID), PantoneColor: getCopy.PantoneColor, RefColorName: getCopy.RefColorName, BuyerRefNo: getCopy.BuyerRefNo,
                        SwatchImageID: getCopy.SwatchImageID, SwatchImagePath: getCopy.SwatchImagePath, ProcessTypeId: getCopy.ProcessTypeId, ApprovalTypeID: getCopy.ApprovalTypeID,
                        ApprovalTypeName: '', IsDeleted: 0, Remark: getCopy.Remark, Critical: parseInt(getCopy.Critical),
                        FabricPartID: parseInt(getCopy.FabricPartID), FabricID: parseInt(getCopy.FabricID),
                        IsCriticals: AssCriticals, IndexName: '', //count.toString(),
                        IsManual: CopyManual,
                        DontShow: 0, PantoneTypeName: getCopy.PantoneTypeName,
                        ExitingApprovalTypePop: { IsOpen: false },
                        labdibSubmissionApprovalID: getCopy.labdibSubmissionApprovalID,
                    }
                    EmptyList.IsCriticals[0].IndexName = '' //count.toString(),;
                    EmptyList.IsManual[0].StyleProgramIndexName = ''
                } else {
                    EmptyList = {
                        StyleProgramID: 0, SKUID: getCopy.SKUID, SKUName: getCopy.SKUName, PantoneTypeID: 0,
                        PantoneID: 0, PantoneColor: '', RefColorName: '', BuyerRefNo: '',
                        SwatchImageID: 0, SwatchImagePath: '', ProcessTypeId: 0, ApprovalTypeID: 0,
                        ApprovalTypeName: '', IsDeleted: 0, Remark: getCopy.Remark, Critical: 0,
                        FabricPartID: 0, FabricID: 0,
                        IsCriticals: [{ ProgramCirticalInfoID: 0, IsCritical: '', RemindMe: 0, TargettoClose: '', CompletedOn: '', CheckSave: false, IsDeleted: 0, IndexName: count.toString() }],
                        IsManual: [{ StyleProgramManualId: 0, IsManual: '', ManualCloseComment: '', ManualCloseDate: '', IsDeleted: 0, CheckSave: false, StyleProgramIndexName: count.toString(), Files: [] }],
                        DontShow: 0, IndexName: '',
                        ExitingApprovalTypePop: { IsOpen: false },
                        labdibSubmissionApprovalID: 0, //count.toString(),
                    }
                }
                values.splice(count, 0, EmptyList);
                setInputFields(values);
                ProgramCallback(values);
            }
        } else {
            setSubmitted(true);
        }
    };

    const handleRemoveFields = (index) => {
        const values = [...inputFields];
        values[index].IsDeleted = 1;
        values[index].IsManual[0].ManualCloseComment = '';
        setInputFields(values);
        ProgramCallback(values);
    };

    const handleFullClose = (index) => {
        const values = [...inputFields];
        if (values[index].IsCriticals[0].CheckSave === false) {
            values[index].IsCriticals[0].IsCritical = '';
            values[index].IsCriticals[0].RemindMe = 0;
            values[index].IsCriticals[0].TargettoClose = '';
        }
        values[index].Critical = 0;
        setInputFields(values);
        ProgramCallback(values);
        setPopUpValidation(false);
    };



    const handleClose = (index) => {
        const values = [...inputFields];
        let CheckCritical = true;
        values[index].IsCriticals.forEach((Chk, i) => {
            if (Chk.IsCritical === '' || Chk.RemindMe === 0 || Chk.TargettoClose === '') {
                CheckCritical = false;
                setPopUpValidation(true);
                Nodify('Warning!', 'warning', "Please fill the mandatory(*) fields.");
            }
        });
        if (CheckCritical) {
            values[index].IsCriticals[0].CheckSave = true;
            values[index].Critical = 0;
            setInputFields(values);
            ProgramCallback(values);
            setPopUpValidation(false);
            Nodify('Success!', 'success', "Comments Saved Successfully");
        }
    };

    const handleCloseManualPopup = (index) => {

        if (getHeader !== "Edit") {
            if (values[index].IsManual[0].IsSaved === 1) {
                values[index].Manual = 0;
            }
            else {
                values[index].IsManual[0].ManualCloseComment = '';
                values[index].IsManual[0].Files = [];
                values[index].Manual = 0;

            }
        }
        else {
            if (values[index].IsManual[0].StyleProgramManualId !== 0 || values[index].IsManual[0].IsSaved === 1) {
                values[index].Manual = 0;
            }
            else {
                values[index].IsManual[0].ManualCloseComment = '';
                values[index].IsManual[0].Files = [];
                values[index].Manual = 0;

            }

        }
        // if (values[index].approvalTypeID !== '2' && values[index].IsManual[0].StyleProgramManualId === 0) {
        //     values[index].IsManual[0].ManualCloseComment = '';
        //     values[index].IsManual[0].Files = [];
        // }
        // values[index].Manual = 0;
        // if (getHeader === "Edit") {
        //     values[index].IsManual[0].ManualCloseComment = '';
        //     values[index].IsManual[0].Files = [];
        //     // values[index].IsManual[0].IsDeleted = 1;
        //     // values[index].IsManual[0].Files
        //     values[index].Manual = 0;
        // } else {
        //     values[index].Manual = 0;
        //     if (values[index].IsManual[0].ManualCloseComment === '') {
        //         values[index].IsManual[0].Files = [];
        //         values[index].ApprovalTypeID = 0;
        //     } else {
        //         values[index].IsManual[0].ManualCloseComment = '';
        //         values[index].IsManual[0].Files = [];
        //         values[index].Manual = 0;
        //     }
        // }
        setInputFields(values);
        ProgramCallback(values);
        setPopUpValidation(false);

    };

    const ClearManual = (index) => {

        values[index].IsManual[0].ManualCloseDate = '';
        values[index].IsManual[0].ManualCloseComment = '';
        values[index].IsManual[0].Files = [];
        values[index].IsManual[0].IsDeleted = 1;
        values[index].IsManual[0].IsSaved = 0;
        //values[index].ApprovalTypeID = 0;
        setInputFields(values);
        ProgramCallback(values);

    }

    const handleSaveManual = (index) => {
        ;
        values[index].Manual = 0;

        if (values[index].IsManual[0].ManualCloseComment === '') {
            Nodify("Warning!", "warning", "Please enter comments");
            setSubmittedPop(true);
            values[index].Manual = 1;
            return false;
        }
        else {
            values[index].IsManual[0].IsSaved = 1;
        }
        setInputFields(values);
        ProgramCallback(values);
        // setPopUpValidation(false);

    };



    const ClearCritical = (event, index, subindex) => {

        const values = [...inputFields];
        values[index].IsCriticals[subindex].IsCritical = '';
        values[index].IsCriticals[subindex].TargettoClose = '';
        values[index].IsCriticals[subindex].CompletedOn = '';
        values[index].IsCriticals[subindex].RemindMe = 0;
        values[index].IsCriticals[subindex].IsDeleted = 1;
        values[index].IsCriticals[subindex].CheckSave = false

        setPopUpValidation(false);
        setInputFields(values);
        ProgramCallback(values);
        Nodify('Success!', 'success', 'Comment deleted successfully');
    }

    const ShowCritical = (index) => {
        const values = [...inputFields];
        values[index].Critical = 1;
        setInputFields(values);
        ProgramCallback(values);
    }

    const showManualClose = (index, StyleProgramID) => {
        ;
        setNewFileState([]);
        let FileDeleteId = [];
        const values = [...inputFields];
        values[index].Manual = 1;
        var newfileid = values.filter(x => x.StyleProgramID === StyleProgramID)
        newfileid[0].IsManual[0].Files.map(element => {
            FileDeleteId.push({
                styleProgramManualFilelId: element.styleProgramManualFilelId,
                fileName: element.fileName,
                filePath: element.filePath,
                fileType: element.fileType,
                styleProgramIndexName: element.styleProgramIndexName,
                styleProgramFileIndexName: element.styleProgramFileIndexName,
                IsDeleted: 0
            })
            setNewFileState(FileDeleteId)
        })
        setSubmittedPop(false);
        setFileIndex(index);
        setInputFields(values);
        ProgramCallback(values);
    }

    // LabDip Map List  //

    const getTNALabDipMapList = (index, pantoneID, pantoneTypeID) => {
        let BuyerID = props.location.state.params.buyerID;
        let BrandID = props.location.state.params.brandID;
        let StyleID = props.location.state.params.styleID;
        let params = { StyleID: StyleID, BuyerID: BuyerID, BrandID: BrandID, PantoneID: pantoneID, PantoneTypeID: pantoneTypeID }
        dispatch(getTNALabDipAlreadyMapList(params))
            .then(isSuccess => {
                if (isSuccess) {
                    values[index].ExitingApprovalTypePop = { IsOpen: true };
                    setInputFields(values);
                } else {
                    Nodify("Warning!", "warning", "This data not approved");
                }


            })
            .catch(e => {
                console.log(e);
            });
    }

    const handleCloseLapDipMapPopup = (index) => {
        values[index].ExitingApprovalTypePop = { IsOpen: false };
        setInputFields(values);

    };

    const saveLapDipMap = (index) => {
        values[index].ExitingApprovalTypePop = { IsOpen: false };
        setInputFields(values);
        ProgramCallback(values);
    }

    const OnSelectRecords = (event, index, labdibSubmissionApprovalID) => {
        //
        if (event.target.checked === true) {

        } else {
            labdibSubmissionApprovalID = 0;
        }
        dispatch(updateProgramGridList(index, labdibSubmissionApprovalID));
        values[index].labdibSubmissionApprovalID = labdibSubmissionApprovalID;
        setInputFields(values);
    }

    const onFocus = (e) => {
        var val = e.target.value;
        e.target.value = '';
        e.target.value = val;
    }

    let style = {
        borderRadius: '0', backgroundClip: 'padding-box', boxShadow: '0 0 40px rgb(0 0 0 / 50%)', color: '#000000',
        backgroundColor: '#ffffff', border: 'rgba(0, 0, 0, 0)', width: '1050px'
    }

    const handleAddPantoneType = () => {
        setIsPantonetypePopupOpen(true);
    };

    const handleAddPantone = () => {
        setIsPantonePopupOpen(true);
    };

    const handleAddSwatch = () => {
        setIsSwatchPopupOpen(true);
    }

    const handleProcessType = () => {
        setProcessTypePopupOpen(true);
    }

    const ResetMaxId = (FieldName) => {
        if (FieldName === "ProcessTypeId") {
            setMaxidProcessType(0);
        }

    }
    //#region PopupCallBack
    const pantoneTypeCallback = (value) => {
        if (value === true) {
            PantoneService.getPantonelist(1, 0, 0).then((response) => {
                if (response.data) {
                    setPantoneType(response.data);
                }
            });

            PantoneService.loadPantoneTypeList().then((response) => {
                if (response.data) {
                    setAllPantonetypeList(response.data);
                }
            }).catch(() => { });

            setIsPantonetypePopupOpen(false);
        } else {
            setIsPantonetypePopupOpen(false);
        }
    };
    const processTypeCallback = (value) => {
        if (value === true) {

            styleService.LoadProcessType().then((response) => {
                if (response.data) {
                    setProcessTypeList(response.data);
                    // setProcessTypeList(response.data);
                    var ProcessType = response.data;
                    var maxid = ProcessType.reduce((max, data) => Math.max(max, data.value), ProcessType[0].value);
                    setMaxidProcessType(maxid);
                    values[0].ProcessTypeId = maxid;
                    setInputFields(values);
                }
                else {
                }
            }).catch(() => { });

            setProcessTypePopupOpen(false);
        } else {
            setProcessTypePopupOpen(false);
        }
    }
    const pantoneCallback = (value) => {
        if (value === true) {

            PantoneService.getPantonelist(1, 0, 0).then((response) => {
                if (response.data) {
                    setPantoneType(response.data);
                }
            });

            PantoneService.LoadPantoneList().then((response) => {
                if (response.data) {
                    setPantone(response.data);
                }
            });

            setIsPantonePopupOpen(false);
        } else {
            setIsPantonePopupOpen(false);
        }
    };

    const swatchCallback = (value) => {
        if (value === true) {
            var BuyerID = props.location.state.params.buyerID;
            var BrandID = props.location.state.params.brandID;
            PantoneService.LoadSwatchList(BuyerID, BrandID).then((response) => {
                if (response.data.length > 0) {
                    setSwatch(response.data);
                }
            }).catch(() => { });

            setIsSwatchPopupOpen(false);
        } else {
            setIsSwatchPopupOpen(false);
        }
    };

    //#endregion Popup Callback




    return (
        <>
            <div className="row">
                <Fragment>
                    <div className={DynamicClassName}>
                        <div className="widget flat radius-bordered">
                            <div className="panel-group accordion" id="accordion">
                                <div className="panel panel-default">
                                    <div className="panel-heading ">
                                        <h4 className="mb-0 panel-title">
                                            <a className="btn btn-link font_wight" data-toggle="collapse" data-parent="accordion" href={"#collapsePrograme_"}>
                                                {getHeader}  Program
                                            </a>
                                        </h4>
                                    </div>
                                    <div id={"collapsePrograme_"} className={getHeader !== "Add" ? 'panel-collapse collapse in' : 'panel-collapse collapse in'}>
                                        <div className="widget-body">
                                            <div id="registration-form">
                                                <Form role="form">
                                                    <div className="">
                                                        <div className="dataTables_wrapper no-footer" style={{ overflowX: 'auto' }}>
                                                            <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable" style={{ width: "110vm" }}>
                                                                <thead className="text-center">
                                                                    <tr>
                                                                        <th style={{ width: "140px" }}>SKU<span className="text-danger">*</span></th>
                                                                        <th style={{ width: "200px" }}>
                                                                            {ProgramInfoQuick.PantoneType.length > 0 && ProgramInfoQuick.PantoneType[0].isQuick === 1 && Buttonviews !== "View" &&
                                                                                <button type='button' className="glyphicon glyphicon-plus AddPlusIcon" style={{ backgroundColor: "#222d32" }}
                                                                                    onClick={() => handleAddPantoneType()} title="Add Pantone Type">
                                                                                </button>
                                                                            }
                                                                            Pantone Type<span className="text-danger">*</span></th>
                                                                        <th style={{ width: "275px" }}>

                                                                            {
                                                                                getSwatchSelected === false ?
                                                                                    ProgramInfoQuick.Pantone.length > 0 && ProgramInfoQuick.Pantone[0].isQuick === 1 && Buttonviews !== "View" &&
                                                                                    <button type='button' className="glyphicon glyphicon-plus AddPlusIcon" style={{ backgroundColor: "#222d32" }}
                                                                                        onClick={() => handleAddPantone()} title="Pantone Code">
                                                                                    </button> : ''
                                                                            }
                                                                            Pantone Code /

                                                                            {ProgramInfoQuick.Swatch.length > 0 && ProgramInfoQuick.Swatch[0].isQuick === 1 && Buttonviews !== "View" &&
                                                                                getSwatchSelected === true ?

                                                                                <button type='button' className="glyphicon glyphicon-plus AddPlusIcon" style={{ backgroundColor: "#222d32" }}
                                                                                    onClick={() => handleAddSwatch()} title="Add Swatch">
                                                                                </button> : ''
                                                                            }

                                                                            &nbsp;Swatch Name<span className="text-danger">*</span></th>
                                                                        <th style={{ width: "183px" }}>Color / Swatch Image<span className="text-danger">*</span></th>
                                                                        <th style={{ width: "178px" }}>Ref Color Name</th>
                                                                        <th style={{ width: "195px" }}>Fabric<span className="text-danger">*</span></th>
                                                                        {/* <th style={{ width: "300px" }}>Fabric Part<span className="text-danger">*</span></th> */}
                                                                        <th style={{ width: "200px" }}>
                                                                            {ProgramInfoQuick.ProcessType.length > 0 && ProgramInfoQuick.ProcessType[0].isQuick === 1 && Buttonviews !== "View" &&
                                                                                <button type='button' className="glyphicon glyphicon-plus AddPlusIcon" style={{ backgroundColor: "#222d32" }}
                                                                                    onClick={() => handleProcessType()}
                                                                                    title="Add ProcessType">
                                                                                </button>
                                                                            }
                                                                            Process Type<span className="text-danger">*</span></th>
                                                                        <th style={{ width: "173px" }}>Approval Type <span className="text-danger">*</span> </th>
                                                                        <th style={{ width: "40px" }}>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                {
                                                                    inputFields.map((item, index) => (
                                                                        <tbody>
                                                                            <tr key={index} style={{ display: item.IsDeleted === 1 || item.DontShow === 1 ? 'none' : '' }}>
                                                                                <td style={{ width: "140px" }}>


                                                                                    <span className="input-icon icon-right">
                                                                                        {/* <select id={"SKUID"} name="SKUID" className="form-select"
                                                                                            value={item.SKUID}
                                                                                            onChange={event => handleInputChange(event, index, '')}
                                                                                            style={{ border: submitted && item.SKUID === 0 ? '1px solid red' : '' }}
                                                                                        >
                                                                                            <option value="0">-Select SKU-</option>
                                                                                            {
                                                                                                getSKU.map(SKUitem => (
                                                                                                    <option key={SKUitem.SKUID} value={SKUitem.SKUID}>
                                                                                                        {SKUitem.SKUName}
                                                                                                    </option>
                                                                                                ))
                                                                                            }
                                                                                        </select> */}

                                                                                        <Reactselect
                                                                                            className="basic-single"
                                                                                            name={"Sku"}
                                                                                            id={
                                                                                                "Sent To"
                                                                                            }
                                                                                            // value={item.MeasurementPoints}
                                                                                            //isDisabled={false}
                                                                                            isLoading={false}
                                                                                            isClearable={true}
                                                                                            isSearchable={true}
                                                                                            options={getSKUList}
                                                                                            value={item.SkuidforEdit}
                                                                                            isMulti
                                                                                            onChange={event => handleSku(event, index, '')}
                                                                                            menuPosition="fixed"
                                                                                            styles={submitted && item.SkuidforEdit === "" ? styles2 : styles1}
                                                                                        // readOnly
                                                                                        // isDisabled={props.TaskStatus === 3 && (props.editLabdipSubmission === false || chkDataExists === true) ? true : false}
                                                                                        />
                                                                                    </span>
                                                                                </td>
                                                                                <td style={{ width: "200px" }}>
                                                                                    <select id={"PantoneTypeID"} name="PantoneTypeID" className="form-select"
                                                                                        value={item.PantoneTypeID}
                                                                                        onChange={event => handleInputChange(event, index, '')}
                                                                                        style={{ border: submitted && item.PantoneTypeID === 0 ? '1px solid red' : '' }}
                                                                                    >
                                                                                        <option value="0">-Select Pantone Type-</option>
                                                                                        {
                                                                                            getPantoneType.map(item => (
                                                                                                <option key={item.pantoneTypeID} value={item.pantoneTypeID}>
                                                                                                    {item.pantoneTypeName}
                                                                                                </option>
                                                                                            ))
                                                                                        }
                                                                                    </select>
                                                                                </td>
                                                                                <td style={{ width: "275px" }}>
                                                                                    {
                                                                                        item.PantoneTypeName !== "Others" ?
                                                                                            <span className="input-icon icon-right">
                                                                                                <select id={"PantoneID"} name="PantoneID" className="form-select"
                                                                                                    value={item.PantoneID}
                                                                                                    onChange={event => handleInputChange(event, index, '')}
                                                                                                    style={{ border: submitted && item.PantoneID === 0 ? '1px solid red' : '' }}
                                                                                                >
                                                                                                    <option value="0">-Select Pantone Code-</option>
                                                                                                    {
                                                                                                        getPantone.filter(x => x.pantoneTypeId === parseInt(item.PantoneTypeID)).map(items => (
                                                                                                            <>
                                                                                                                <option key={items.pantoneID} value={items.pantoneID} style={{ background: 'url' + (items.pantoneColor) + '96% / 15% no-repeat', width: '120px', height: '34px', borderRadius: '2px' }}>
                                                                                                                    {/* style={{ background: (items.pantoneColor)96% / 15% no-repeat, color: '#fff', width: '120px', height: '34px', borderRadius: '2px' }} */}
                                                                                                                    {items.pantoneCode}  &nbsp;
                                                                                                                </option>
                                                                                                            </>
                                                                                                        ))
                                                                                                    }
                                                                                                </select>
                                                                                                <span className="text"></span>
                                                                                            </span>
                                                                                            :
                                                                                            <span className="input-icon icon-right">
                                                                                                <select id={"PantoneID"} name="PantoneID" className="form-select"
                                                                                                    value={item.PantoneID}
                                                                                                    onChange={event => handleInputChange(event, index, '')}
                                                                                                    style={{ border: submitted && item.PantoneID === 0 ? '1px solid red' : '' }}
                                                                                                >
                                                                                                    <option value="0">-Select Swatch-</option>
                                                                                                    {
                                                                                                        getSwatch.map(item => (
                                                                                                            <option key={item.id} value={item.id}>
                                                                                                                {item.name}
                                                                                                            </option>
                                                                                                        ))
                                                                                                    }
                                                                                                </select>
                                                                                                <span className="text"></span>
                                                                                            </span>
                                                                                    }

                                                                                </td>
                                                                                <td style={{ width: "183px" }}>
                                                                                    {
                                                                                        item.PantoneTypeName !== "Others" ?
                                                                                            <div className="form-group">
                                                                                                {/* <img src={getImagename === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + getImagename} onClick={openFileUpload}
                                                                                                    alt="Picture" style={{ marginBottom: '5px', height: '100px', width: '50%', padding: '10px', border: "1px solid #d5d5d5" }} /> */}
                                                                                                {
                                                                                                    item.PantoneColor !== '' ?
                                                                                                        <div style={{ background: item.PantoneColor, width: '100%', height: '34px', borderRadius: '2px' }}></div>
                                                                                                        :
                                                                                                        ''
                                                                                                }
                                                                                            </div>
                                                                                            :
                                                                                            <img src={item.PantoneColor === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + item.PantoneColor}
                                                                                                alt="Picture" style={{ marginBottom: '5px', height: '100px', width: '50%', border: "1px solid #d5d5d5" }}
                                                                                                onClick={
                                                                                                    event => ViewFile(event, item.PantoneColor)} />
                                                                                    }

                                                                                </td>
                                                                                <td style={{ width: "178px" }}>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input placeholder="Enter Ref Color Name" id="RefColorName" name="RefColorName"
                                                                                            value={item.RefColorName} type="text" onChange={event => handleInputChange(event, index, '')}
                                                                                            maxLength="50" autoComplete="off" className='form-control'
                                                                                        />
                                                                                        <span className="text"></span>
                                                                                    </span>
                                                                                </td>
                                                                                <td style={{ width: "195px" }}>
                                                                                    <span className="input-icon icon-right">
                                                                                        <select id={"FabricID" + index} name="FabricID" className="form-select"
                                                                                            value={item.FabricID}
                                                                                            title={getFabric.filter(function (option) {
                                                                                                return option.value === item.FabricID;
                                                                                            })[0]?.label}

                                                                                            onChange={event => handleInputChange(event, index)}
                                                                                            style={{ border: submitted && item.FabricID === 0 ? '1px solid red' : '' }}
                                                                                        >
                                                                                            <option value="0">-Select Fabric-</option>
                                                                                            {
                                                                                                getFabric.map(item => (
                                                                                                    <option key={item.id} value={item.value}>
                                                                                                        {item.label}
                                                                                                    </option>
                                                                                                ))
                                                                                            }
                                                                                        </select>
                                                                                    </span>
                                                                                </td>
                                                                                {/* <td>
                                                                                    <span className="input-icon icon-right">
                                                                                        <select id={"FabricPartID" + index} name="FabricPartID" className="form-select"
                                                                                            value={item.FabricPartID}
                                                                                            onChange={event => handleInputChange(event, index)}
                                                                                            style={{ border: submitted && item.FabricPartID === 0 ? '1px solid red' : '' }}
                                                                                        >
                                                                                            <option value="0">-Select Fabric Part-</option>
                                                                                            {
                                                                                                getFabricPart.map(item => (
                                                                                                    <option key={item.id} value={item.id}>
                                                                                                        {item.name}
                                                                                                    </option>
                                                                                                ))
                                                                                            }
                                                                                        </select>
                                                                                    </span>
                                                                                </td> */}
                                                                                <td style={{ width: "200px" }}>
                                                                                    <span className="input-icon icon-right">
                                                                                        <span className="input-icon icon-right">
                                                                                            <select id={"ProcessTypeId" + index} name="ProcessTypeId" className="form-select"
                                                                                                // value={item.ProcessTypeId}
                                                                                                value={getMaxidProcessType !== 0 ? getMaxidProcessType : item.ProcessTypeId}
                                                                                                title={getProcessTypeList.filter(function (option) {
                                                                                                    return option.value === item.ProcessTypeId;
                                                                                                })[0]?.label}
                                                                                                onChange={event => handleInputChange(event, index)}
                                                                                                style={{ border: submitted && item.ProcessTypeId === 0 ? '1px solid red' : '' }}
                                                                                            >
                                                                                                <option value="0">-Select Process Type-</option>
                                                                                                {
                                                                                                    getProcessTypeList.map(item => (
                                                                                                        <option key={item.id} value={item.id}>
                                                                                                            {item.name}
                                                                                                        </option>
                                                                                                    ))
                                                                                                }
                                                                                            </select>
                                                                                            {/* <Reactselect className="basic-single" name="ProcessTypeID"
                                                                                                id={
                                                                                                    "ProcessTypeID"
                                                                                                }

                                                                                                isLoading={false}
                                                                                                isClearable={true}
                                                                                                isSearchable={true}
                                                                                                // value={getStyleList.BuyerEmployeeID}
                                                                                                onChange={event => handleInputChange(event, 'ProcessTypeId')}
                                                                                                options={getProcessTypeList}
                                                                                                value={getProcessTypeList.filter(function (option) {
                                                                                                    return option.value === item.ProcessTypeId;
                                                                                                })}
                                                                                            // styles={submitted && getStyleList.ProcessTypeID === 0 ? styles : ''}

                                                                                            ></Reactselect> */}

                                                                                        </span>
                                                                                    </span>
                                                                                </td>
                                                                                <td style={{ width: "173px" }}>


                                                                                    <span className="input-icon icon-right">

                                                                                        <select id={"ApprovalTypeID"} name="ApprovalTypeID" className="form-select"
                                                                                            value={item.ApprovalTypeID}
                                                                                            onChange={event => handleInputChange(event, index, '')}
                                                                                            style={{ border: submitted && item.ApprovalTypeID === 0 ? '1px solid red' : '' }}
                                                                                        >
                                                                                            <option value="0">-Select Approval Type-</option>
                                                                                            {
                                                                                                getApprovalType.map(item => (
                                                                                                    <option key={item.ID} value={item.ID}>
                                                                                                        {item.Name}
                                                                                                    </option>
                                                                                                ))
                                                                                            }
                                                                                        </select>

                                                                                    </span>
                                                                                </td>
                                                                                <td style={{ width: "40px" }}>
                                                                                    <tr>
                                                                                        <td style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                                                            {
                                                                                                item.ApprovalTypeID === 2 &&

                                                                                                (item.IsManual[0].ManualCloseComment === '' ?
                                                                                                    <button type="button" className="btn btn-xs btn-darkorange" title="Manual"
                                                                                                        onClick={() => showManualClose(index, item.StyleProgramID)}
                                                                                                    >
                                                                                                        <i className="fa fa-user"></i>&nbsp;
                                                                                                        <i className="fa fa-close"></i>
                                                                                                    </button>
                                                                                                    :
                                                                                                    <button type="button" className="btn btn-xs btn-success" title="View Manual Data"
                                                                                                        onClick={() => showManualClose(index, item.StyleProgramID)}
                                                                                                    >
                                                                                                        <i className="fa fa-user"></i>&nbsp;
                                                                                                        <i className="fa fa-close"></i>
                                                                                                    </button>)

                                                                                            }

                                                                                            {
                                                                                                item.ApprovalTypeID === 3 &&

                                                                                                // (item.IsManual[0].ManualCloseComment === '' ?fa fa-retweet
                                                                                                <button type="button" className={item.labdibSubmissionApprovalID === 0 || item.labdibSubmissionApprovalID === null ? "btn btn-xs btn-darkorange" : "btn btn-xs btn-success"} title="Existing Approval"
                                                                                                    onClick={() => getTNALabDipMapList(index, item.PantoneID, item.PantoneTypeID)}
                                                                                                >
                                                                                                    <i className="fa fa-retweet"></i>

                                                                                                </button>
                                                                                                // :
                                                                                                // <button type="button" className="btn btn-xs btn-success" title="View Manual Data"
                                                                                                //     onClick={() => showManualClose(index)}
                                                                                                // >
                                                                                                //     <i className="fa fa-user"></i>&nbsp;
                                                                                                //     <i className="fa fa-close"></i>
                                                                                                // </button>)

                                                                                            }
                                                                                        </td>

                                                                                        <td style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                                                            {item.IsDeleted === 0 && getHeader !== 'Edit' ?
                                                                                                <>
                                                                                                    {inputFields.filter(prod => prod.IsDeleted === 0 && prod.DontShow === 0).length > 0 ?
                                                                                                        <>
                                                                                                            <div className="col-lg-1 col-xs-12" style={{ display: inputFields.filter(prod => prod.IsDeleted === 0 && prod.DontShow === 0).length === 0 ? "none" : "", padding: '2px' }}>
                                                                                                                <span>
                                                                                                                    <span className="btn btn-primary" title="Copy Program" onClick={() => handleAddFields('Copy', index)}>
                                                                                                                        <i className="fa fa-copy"></i>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </>
                                                                                                        : ''
                                                                                                    }
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    {inputFields.filter(prod => prod.IsDeleted === 0 && prod.DontShow === 0).length > 0 ?
                                                                                                        <>
                                                                                                            <div className="col-lg-1 col-xs-12" style={{ display: inputFields.filter(prod => prod.IsDeleted === 0 && prod.DontShow === 0).length === 0 ? "none" : "", padding: '2px' }}>
                                                                                                                <span>
                                                                                                                    <span className="btn btn-primary" title="Copy Program" onClick={() => handleAddFields('Copy', index)}>
                                                                                                                        <i className="fa fa-copy"></i>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </>
                                                                                                        : ''
                                                                                                    }
                                                                                                </>
                                                                                            }

                                                                                        </td>
                                                                                        <td style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                                                            {
                                                                                                <div className="col-lg-1 col-xs-12" style={{ padding: '2px' }}>
                                                                                                    <span className={item.IsCriticals[0].IsCritical !== '' && item.IsCriticals[0].TargettoClose !== '' && item.IsCriticals[0].RemindMe !== 0 ? 'btn btn-xs btn-danger' : 'btn btn-xs btn-primary'} title="Critical" onClick={() => ShowCritical(index)}
                                                                                                    // style={{
                                                                                                    //     background:
                                                                                                    //         item.IsCriticals[0].IsCritical !== '' && item.IsCriticals[0].TargettoClose !== '' && item.IsCriticals[0].RemindMe !== 0 ? 'btn btn-xs btn-danger' : 'btn btn-xs btn-primary',
                                                                                                    //     color: 'white',
                                                                                                    //     // padding: '2.5px 5px',
                                                                                                    //     // borderRadius: '2px',
                                                                                                    //     // border: item.IsCriticals[0].IsCritical === '' && item.IsCriticals[0].TargettoClose === '' && item.IsCriticals[0].RemindMe === 0 ? '#427fed' //: 'red',
                                                                                                    //     //     : item.IsCriticals[0].IsCritical !== '' && item.IsCriticals[0].TargettoClose !== '' && item.IsCriticals[0].RemindMe !== 0 ? 'red' : '#427fed',
                                                                                                    // }}
                                                                                                    >
                                                                                                        {/* <span className={item.IsCriticals[0].IsCritical !== "" || item.IsCriticals[0].TargettoClose !== null || item.IsCriticals[0].CompletedOn !== null
                                                                                                            || item.IsCriticals[0].RemindMe !== 0 ?
                                                                                                            "btn btn-xs btn-danger" : "btn btn-xs btn-primary"} title="Critical" onClick={() => ShowCritical(index)}
                                                                                                            item.IsCriticals[0].IsCritical === '' && item.IsCriticals[0].TargettoClose === '' && item.IsCriticals[0].RemindMe === 0 ? 'btn btn-xs btn-primary'*/}
                                                                                                        <i class="fa fa-exclamation-triangle"></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                            }
                                                                                        </td>
                                                                                        <td style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                                                            {item.IsDeleted === 0 && getHeader !== 'Edit' ?
                                                                                                <>
                                                                                                    {inputFields.filter(prod => prod.IsDeleted === 0 && prod.DontShow === 0).length > 0 ?
                                                                                                        <>
                                                                                                            <div className="col-lg-1 col-xs-12" style={{ display: inputFields.filter(prod => prod.IsDeleted === 0 && prod.DontShow === 0).length === 0 ? "none" : "", padding: '2px' }}>
                                                                                                                {ProgramInfo && ProgramInfo.isAdd === 1 &&
                                                                                                                    <span>
                                                                                                                        <span className="btn btn-success" title="Add Program" onClick={() => handleAddFields('Add', index)}>
                                                                                                                            <i className="fa fa-plus"></i>
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </>
                                                                                                        : ''
                                                                                                    }
                                                                                                </>
                                                                                                : ''
                                                                                                // <>
                                                                                                //     {inputFields.filter(prod => prod.IsDeleted === 0 && prod.DontShow === 0).length > 0 ?
                                                                                                //         <>
                                                                                                //             <div className="col-lg-1 col-xs-12" style={{ display: inputFields.filter(prod => prod.IsDeleted === 0 && prod.DontShow === 0).length === 0 ? "none" : "", padding: '2px' }}>
                                                                                                //                 {ProgramInfo && ProgramInfo.isAdd === 1 &&
                                                                                                //                     <span>
                                                                                                //                         <span className="btn btn-success" title="Add Program" onClick={() => handleAddFields('Add', index)}>
                                                                                                //                             <i className="fa fa-plus"></i>
                                                                                                //                         </span>
                                                                                                //                     </span>
                                                                                                //                 }
                                                                                                //             </div>
                                                                                                //         </>
                                                                                                //         : ''
                                                                                                //     }
                                                                                                // </>
                                                                                            }
                                                                                        </td>
                                                                                        <td style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                                                            {item.IsDeleted === 0 && getHeader !== 'Edit' ?
                                                                                                <>
                                                                                                    {
                                                                                                        inputFields.filter(prod => prod.IsDeleted === 0 && item.DontShow === 0).length > 1 &&
                                                                                                        <>
                                                                                                            {inputFields.filter(prod => prod.IsDeleted === 0 && prod.DontShow === 0).length !== 1 ?
                                                                                                                <>
                                                                                                                    <div className="col-lg-1 col-xs-12" style={{ display: inputFields.filter(prod => prod.IsDeleted === 0).length === 1 ? "none" : "", padding: '2px' }}>
                                                                                                                        {ProgramInfo && ProgramInfo.isDelete === 1 &&
                                                                                                                            <span>
                                                                                                                                <span className="btn btn-danger" title="Delete Program" onClick={() => handleRemoveFields(index)}
                                                                                                                                //  style={{ display: inputFields.filter(prod => prod.IsDeleted === 0 && prod.DontShow === 0).length === 1 ? "none" : "" }}
                                                                                                                                >
                                                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        }

                                                                                                                    </div>
                                                                                                                </>
                                                                                                                : ''
                                                                                                            }
                                                                                                        </>
                                                                                                    }
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    {
                                                                                                        inputFields.filter(prod => prod.IsDeleted === 0 && item.DontShow === 0).length > 1 &&
                                                                                                        <>
                                                                                                            {inputFields.filter(prod => prod.IsDeleted === 0 && prod.DontShow === 0).length !== 1 ?
                                                                                                                <>
                                                                                                                    <div className="col-lg-1 col-xs-12" style={{ display: inputFields.filter(prod => prod.IsDeleted === 0).length === 1 ? "none" : "", padding: '2px' }}>
                                                                                                                        {ProgramInfo && ProgramInfo.isDelete === 1 &&
                                                                                                                            <span>
                                                                                                                                <span className="btn btn-danger" title="Delete Program" onClick={() => handleRemoveFields(index)}
                                                                                                                                //  style={{ display: inputFields.filter(prod => prod.IsDeleted === 0 && prod.DontShow === 0).length === 1 ? "none" : "" }}
                                                                                                                                >
                                                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        }

                                                                                                                    </div>
                                                                                                                </>
                                                                                                                : ''
                                                                                                            }
                                                                                                        </>
                                                                                                    }
                                                                                                </>
                                                                                            }
                                                                                        </td>

                                                                                    </tr>

                                                                                    {
                                                                                        item.Critical === 1 && item.DontShow === 0 && (
                                                                                            <>
                                                                                                <Modal show={item.Critical === 1 && item.DontShow === 0} dialogAs={Drag} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handleFullClose(index)}>
                                                                                                    <Modal.Header closeButton>
                                                                                                        <Modal.Title>
                                                                                                            {"Critical"}
                                                                                                        </Modal.Title>
                                                                                                    </Modal.Header>
                                                                                                    <Modal.Body>
                                                                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                                            {
                                                                                                                item.IsCriticals.map((Crititem, k) => {
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            <thead>
                                                                                                                                <tr>
                                                                                                                                    <th colSpan="6" style={{ textAlign: "center" }}>Comments<span className="text-danger">*</span>
                                                                                                                                        <button type="button" className="btn btn-xs btn-danger pull-right"
                                                                                                                                            style={{ display: Crititem.CheckSave === true ? 'block' : 'none' }}
                                                                                                                                            onClick={event => ClearCritical(event, index, k)}
                                                                                                                                            title="Clear Critical" ><i className="fa fa-trash-o"></i>
                                                                                                                                        </button>
                                                                                                                                    </th>
                                                                                                                                </tr>
                                                                                                                            </thead>
                                                                                                                            <tbody>
                                                                                                                                <tr>
                                                                                                                                    <td colSpan="6">
                                                                                                                                        <textarea placeholder="Enter comments here..."
                                                                                                                                            autoFocus onFocus={onFocus}
                                                                                                                                            maxLength="500" name="IsCritical"
                                                                                                                                            id={"IsCritical"}
                                                                                                                                            value={Crititem.IsCritical}
                                                                                                                                            onChange={event => handleChangeCritical(index, event, 'IsCritical', k)}
                                                                                                                                            rows="4" style={{ width: "100%", border: getPopUpValidation === true && Crititem.IsCritical === '' ? '1px solid red' : '' }}>
                                                                                                                                        </textarea>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <td>Target to Close<span className="text-danger">*</span></td>
                                                                                                                                    <td>
                                                                                                                                        <DatePicker className="form-control" name="TargettoClose" id={"TargettoClose"}
                                                                                                                                            selected={Crititem.TargettoClose}
                                                                                                                                            onChange={event => handleChangeCritical(index, event, 'TargettoClose', k)}
                                                                                                                                            dateFormat="dd/MM/yyyy"
                                                                                                                                            peekNextMonth
                                                                                                                                            showMonthDropdown
                                                                                                                                            showYearDropdown
                                                                                                                                            dropdownMode="scroll"
                                                                                                                                            autoComplete="off"
                                                                                                                                            customInput={<CustomInput />}
                                                                                                                                            isClearable={Crititem.TargettoClose === '' ? false : true}
                                                                                                                                            style={{ border: getPopUpValidation === true && Crititem.TargettoClose === '' ? '1px solid red' : '' }}
                                                                                                                                        /></td>
                                                                                                                                    <td>Remind Me<span className="text-danger">*</span></td>
                                                                                                                                    <td>
                                                                                                                                        <Select className="form-select" name="RemindMe" id={"RemindMe" + k}
                                                                                                                                            value={Crititem.RemindMe}

                                                                                                                                            onChange={event => handleChangeCritical(index, event, "RemindMe", k)}
                                                                                                                                            style={{ border: getPopUpValidation === true && Crititem.RemindMe === 0 ? '1px solid red' : '' }}
                                                                                                                                        >
                                                                                                                                            <option value="0">- select -</option>
                                                                                                                                            <option value="1">Current Date Reaches Target Date </option>
                                                                                                                                            <option value="2">One Time in a Day, until complete</option>
                                                                                                                                        </Select>
                                                                                                                                    </td>

                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <td>Completed On</td>
                                                                                                                                    <td>
                                                                                                                                        <DatePicker className="form-control" name="CompletedOn"
                                                                                                                                            id={"CompletedOn"} selected={Crititem.CompletedOn}
                                                                                                                                            onChange={event => handleChangeCritical(index, event, 'CompletedOn', k)}
                                                                                                                                            dateFormat="dd/MM/yyyy"
                                                                                                                                            peekNextMonth
                                                                                                                                            showMonthDropdown
                                                                                                                                            showYearDropdown
                                                                                                                                            dropdownMode="scroll"
                                                                                                                                            autoComplete="off"
                                                                                                                                            customInput={<CustomInput />}
                                                                                                                                            isClearable={Crititem.CompletedOn === '' ? false : true}
                                                                                                                                        />
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </tbody>
                                                                                                                        </>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </table>
                                                                                                    </Modal.Body>
                                                                                                    <Modal.Footer>
                                                                                                        <Button variant="success" onClick={() => handleClose(index)}>
                                                                                                            {getHeader === 'Add' ? 'Save' : 'Update'}
                                                                                                        </Button>
                                                                                                    </Modal.Footer>
                                                                                                </Modal>
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                    {
                                                                                        item.Manual === 1 && (
                                                                                            <>
                                                                                                <Modal show={item.Manual === 1} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseManualPopup(index)}>
                                                                                                    <Modal.Header closeButton>
                                                                                                        <Modal.Title>
                                                                                                            Manual Approval Information
                                                                                                        </Modal.Title>
                                                                                                    </Modal.Header>
                                                                                                    <Modal.Body>

                                                                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                                            {
                                                                                                                item.IsManual.map((manitem, k) => {
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            <thead>
                                                                                                                                <tr>
                                                                                                                                    <th>Comments <span className="text-danger">*</span>
                                                                                                                                        <button type="button" className="btn btn-xs btn-danger pull-right"
                                                                                                                                            style={{ display: ProgramInfo && ProgramInfo.isDelete === 1 ? '' : 'none' }}
                                                                                                                                            onClick={event => ClearManual(index)}
                                                                                                                                            title="Clear Critical" ><i className="fa fa-trash-o"></i>
                                                                                                                                        </button>
                                                                                                                                    </th>
                                                                                                                                    {/* <th>Date <span className="text-danger">*</span></th> */}

                                                                                                                                </tr>
                                                                                                                            </thead>
                                                                                                                            <tbody>

                                                                                                                                <tr>
                                                                                                                                    <td >
                                                                                                                                        <textarea
                                                                                                                                            //style={{ width: "100%" }}
                                                                                                                                            style={{ width: "100%", border: submittedPop && manitem.ManualCloseComment === '' ? '1px solid red' : '' }}
                                                                                                                                            rows="3"
                                                                                                                                            placeholder="Enter Comments here..."
                                                                                                                                            autoFocus onFocus={""} autoComplete="off"
                                                                                                                                            maxLength="500" name="EnterRemarks"
                                                                                                                                            id={"EnterRemarks"}
                                                                                                                                            value={manitem.ManualCloseComment}
                                                                                                                                            onChange={event => handleChangeIsManualCloseComments(event, index, "Comment", k)}


                                                                                                                                        >

                                                                                                                                        </textarea>
                                                                                                                                    </td>
                                                                                                                                    {/* <td >
                                                                                                                                        <DatePicker className="form-control" name="HolidayDate"
                                                                                                                                            id={
                                                                                                                                                "HolidayDate" + index
                                                                                                                                            }
                                                                                                                                            selected={
                                                                                                                                                manitem.ManualCloseDate
                                                                                                                                            }
                                                                                                                                            onChange={event => handleChangeIsManualCloseComments(event, index, "CommentDate", k)}
                                                                                                                                            dateFormat="dd/MM/yyyy"
                                                                                                                                            peekNextMonth
                                                                                                                                            showMonthDropdown
                                                                                                                                            // showYearDropdown
                                                                                                                                            dropdownMode="scroll"
                                                                                                                                            autoComplete="off"
                                                                                                                                            // minDate={new Date()}
                                                                                                                                            // maxDate={new Date()}
                                                                                                                                            customInput={<CustomInput />}

                                                                                                                                        />


                                                                                                                                    </td> */}
                                                                                                                                </tr>

                                                                                                                                <tr>
                                                                                                                                    <td colSpan={2}>
                                                                                                                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                                                                            <tbody>  <tr>
                                                                                                                                                <div className="col-sm-12 col-lg-12">
                                                                                                                                                    <div style={{ paddingLeft: 0 }}>
                                                                                                                                                        <span className="widget-caption" style={{ fontSize: '14px' }}>File Upload <span style={{ color: 'red', fontSize: '14px' }}>(Upload Document Type: Image Only)</span></span>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                                <div className="col-sm-12 col-lg-12">
                                                                                                                                                    <div className="row">
                                                                                                                                                        <div className="col-sm-8 col-lg-8">
                                                                                                                                                            <div className='form-group'>
                                                                                                                                                                <tr>
                                                                                                                                                                    <td> <input type="file" id="FileUpload" style={{ fontSize: '14px' }} onChange={(e) => AppendFiles(e, index, k)} accept=".jpg,.jpeg,.png" ></input></td>
                                                                                                                                                                </tr>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                                <div className="col-lg-12 col-sm-12">
                                                                                                                                                    <div className="row no-margin">
                                                                                                                                                        {
                                                                                                                                                            manitem.Files && manitem.Files.length !== 0 ?
                                                                                                                                                                manitem.Files.filter(x => x.IsDeleted !== 1).map((files, fileindex) => (
                                                                                                                                                                    // manitem.Files.filter(x => x.IsDeleted === 0) &&
                                                                                                                                                                    <> {
                                                                                                                                                                        <div className="col-lg-4 col-sm-4 file_list_upload">
                                                                                                                                                                            <tr title={files.fileName}>
                                                                                                                                                                                <td className="cut_textInTNA">
                                                                                                                                                                                    <span style={{ marginRight: '7px' }}>
                                                                                                                                                                                        {
                                                                                                                                                                                            files.fileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                                                                                                                files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                                                                                                                                    <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                                                                                                                    files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                                                                                                                        files.fileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                                                                                                                        <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                                                                                                                        }
                                                                                                                                                                                    </span>  {files.fileName}
                                                                                                                                                                                </td>

                                                                                                                                                                                <td className="button_style_uploda">
                                                                                                                                                                                    {
                                                                                                                                                                                        files.FileType === "application/pdf" || files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files.fileType === 'application/msword' ?
                                                                                                                                                                                            <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + 'Images/TNAImages/' + files.fileName} download><i class="fa fa-download"></i> </a> :


                                                                                                                                                                                            <i className="btn btn-info fa fa-eye" title="View Image"
                                                                                                                                                                                                style={{ display: ProgramInfo && ProgramInfo.isView === 1 ? '' : 'none' }}
                                                                                                                                                                                                onClick={
                                                                                                                                                                                                    event => ViewFile(event, files.fileName)
                                                                                                                                                                                                }></i>
                                                                                                                                                                                    }
                                                                                                                                                                                    &nbsp;

                                                                                                                                                                                    {Buttonview !== "View" &&
                                                                                                                                                                                        <p style={{ display: ProgramInfo && ProgramInfo.isDelete === 1 ? '' : 'none' }} title='Delete File' onClick={(e) => RemoveFiles(e, index, fileindex, k, files.styleProgramManualFilelId, files.styleProgramIndexName, files.styleProgramFileIndexName)}
                                                                                                                                                                                            className="btn btn-danger btn-xs delete">
                                                                                                                                                                                            <i className="fa fa-trash-o"></i>

                                                                                                                                                                                        </p>
                                                                                                                                                                                    }
                                                                                                                                                                                </td>
                                                                                                                                                                            </tr>



                                                                                                                                                                            {

                                                                                                                                                                                <div> {
                                                                                                                                                                                    isOpenimg && (

                                                                                                                                                                                        <Lightbox mainSrc={window.$APIBaseURL + 'Images/TNAImages/' + getImagename}
                                                                                                                                                                                            alt="bg image"
                                                                                                                                                                                            onCloseRequest={
                                                                                                                                                                                                () => setIsOpenimg(false)
                                                                                                                                                                                            } />
                                                                                                                                                                                    )
                                                                                                                                                                                } </div>
                                                                                                                                                                            }
                                                                                                                                                                        </div>
                                                                                                                                                                    }
                                                                                                                                                                    </>
                                                                                                                                                                ))
                                                                                                                                                                : ''
                                                                                                                                                        }
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            </tr>
                                                                                                                                            </tbody>


                                                                                                                                        </table>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </tbody>
                                                                                                                        </>
                                                                                                                    )
                                                                                                                })
                                                                                                            }



                                                                                                        </table>


                                                                                                    </Modal.Body>
                                                                                                    <Modal.Footer>
                                                                                                        {

                                                                                                            <Button variant="success" onClick={() => handleSaveManual(index)}>
                                                                                                                Save
                                                                                                            </Button>


                                                                                                        }


                                                                                                    </Modal.Footer>
                                                                                                </Modal>
                                                                                            </>
                                                                                        )
                                                                                    }

                                                                                    {
                                                                                        item.ExitingApprovalTypePop.IsOpen === true ?
                                                                                            <Modal show={item.ExitingApprovalTypePop.IsOpen === true} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseLapDipMapPopup(index)}>
                                                                                                <Modal.Header closeButton>
                                                                                                    <Modal.Title>
                                                                                                        Existing Approval Information
                                                                                                    </Modal.Title>
                                                                                                </Modal.Header>
                                                                                                <Modal.Body>

                                                                                                    <table className="table table-bordered">
                                                                                                        <thead>
                                                                                                            <tr>
                                                                                                                <th>Season Name</th>
                                                                                                                <th>Approval Date</th>
                                                                                                                <th>Details</th>
                                                                                                                <th>Approved By</th>
                                                                                                                <th>
                                                                                                                </th>

                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            {

                                                                                                                TNALabDipMapList.map((Exiting, ExitingIndex) => {
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            <tr>
                                                                                                                                <td>{Exiting.seasonName}</td>
                                                                                                                                <td>{Exiting.approvalDate}</td>
                                                                                                                                <td>{Exiting.details}</td>
                                                                                                                                <td>{Exiting.approvedBy}</td>
                                                                                                                                <td className="Actionbutton fixed-column">
                                                                                                                                    <div style={{ width: "80px" }}>
                                                                                                                                        <label style={{ marginTop: '8px' }}>
                                                                                                                                            <input type="checkbox" name="IsSelected"
                                                                                                                                                onChange={event => (OnSelectRecords(event, index, Exiting.commonApprovalId))}
                                                                                                                                                checked={(item.labdibSubmissionApprovalID === Exiting.commonApprovalId && Exiting.commonApprovalId !== 0) ? true : false}
                                                                                                                                                className="colored-blue clearOpacity" />
                                                                                                                                            <span className="text"></span>
                                                                                                                                        </label>

                                                                                                                                    </div>

                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                        </>

                                                                                                                    )
                                                                                                                })
                                                                                                            }

                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </Modal.Body>
                                                                                                <Modal.Footer>
                                                                                                    <Button variant="success" onClick={() => saveLapDipMap(index)}>
                                                                                                        Save
                                                                                                    </Button>

                                                                                                </Modal.Footer>
                                                                                            </Modal> : ''
                                                                                    }


                                                                                </td>
                                                                            </tr>
                                                                            {
                                                                                item.IsCriticals.map((Crit, j) => {
                                                                                    return (
                                                                                        Crit.IsDeleted === 0 && Crit.IsCritical !== '' && Crit.TargettoClose !== '' && Crit.RemindMe !== 0 && item.DontShow === 0 ?

                                                                                            <tr>
                                                                                                <td colSpan="10">
                                                                                                    <span style={{ color: 'red' }}>Critical : {Crit.IsCritical}</span>,&nbsp;
                                                                                                    <span style={{ color: '#427fed' }}>Target to Close : {new Date(Crit.TargettoClose).toLocaleDateString()}</span>,&nbsp;
                                                                                                    <span style={{ color: '#00a65a' }}>Completed On : {Crit.CompletedOn !== '' ? new Date(Crit.CompletedOn).toLocaleDateString() : '-'}</span>
                                                                                                </td>
                                                                                            </tr>

                                                                                            : ''
                                                                                    );
                                                                                })
                                                                            }

                                                                            {
                                                                                item.IsManual.map((man, j) => {
                                                                                    return (
                                                                                        man.ManualCloseComment !== '' && item.DontShow === 0 && item.ApprovalTypeID === 2 ?
                                                                                            <tr>
                                                                                                <td colSpan="10">
                                                                                                    <div className="row-1">
                                                                                                        <span><b>Manual Comment :</b> {man.ManualCloseComment}</span>
                                                                                                    </div>


                                                                                                    {/* <span style={{ color: '#427fed' }}>Date : {new Date(man.ManualCloseDate).toLocaleDateString()}</span> */}
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            {

                                                                                                                man.Files && man.Files.length !== 0 ?
                                                                                                                    getHeader === "Add" ?
                                                                                                                        man.Files.filter(x => x.IsDeleted !== 1).map((files, fileindex) => (
                                                                                                                            <div className="col-md-2 file_list_uploads fixed_width">
                                                                                                                                <tr title={files.fileName}>
                                                                                                                                    File
                                                                                                                                    <td className="cut_textInTNA">
                                                                                                                                        <span style={{ marginRight: '7px' }}>
                                                                                                                                            {
                                                                                                                                                files.fileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                                                                    files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                                                                                        <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                                                                        files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                                                                            files.fileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                                                                            <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                                                                            }
                                                                                                                                        </span>  {files.fileName}
                                                                                                                                    </td>

                                                                                                                                    <td>

                                                                                                                                        {
                                                                                                                                            files.fileType === "application/pdf" || files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files.fileType === 'application/msword' ?
                                                                                                                                                <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + 'Images/TNAImages/' + files.fileName} download><i class="fa fa-download"></i> </a> :
                                                                                                                                                <i className="btn btn-info fa fa-eye" title="View Logo"
                                                                                                                                                    style={{ display: ProgramInfo && ProgramInfo.isView === 1 ? '' : 'none' }}
                                                                                                                                                    onClick={
                                                                                                                                                        event => ViewFile(event, files.fileName)
                                                                                                                                                    }></i>
                                                                                                                                        }
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                                {

                                                                                                                                    <div> {
                                                                                                                                        isOpenimg && (

                                                                                                                                            <Lightbox mainSrc={window.$APIBaseURL + 'Images/TNAImages/' + getImagename}
                                                                                                                                                alt="bg image"
                                                                                                                                                onCloseRequest={
                                                                                                                                                    () => setIsOpenimg(false)
                                                                                                                                                } />
                                                                                                                                        )
                                                                                                                                    } </div>
                                                                                                                                }
                                                                                                                            </div>


                                                                                                                        ))
                                                                                                                        :
                                                                                                                        // item.IsManual[0].Files.filter(x => x.styleProgramIndexName === man.StyleProgramIndexName).map((files, fileindex) => (
                                                                                                                        man.Files.filter(x => x.IsDeleted !== 1).map((files, fileindex) => (


                                                                                                                            <div className="col-md-2 file_list_uploads fixed_width">
                                                                                                                                <tr title={files.fileName}>
                                                                                                                                    File
                                                                                                                                    <td className="cut_textInTNA">
                                                                                                                                        <span style={{ marginRight: '7px' }}>
                                                                                                                                            {
                                                                                                                                                files.fileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                                                                    files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                                                                                        <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                                                                        files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                                                                            files.fileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                                                                            <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                                                                            }
                                                                                                                                        </span>  {files.fileName}
                                                                                                                                    </td>

                                                                                                                                    <td>

                                                                                                                                        {
                                                                                                                                            files.fileType === "application/pdf" || files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files.fileType === 'application/msword' ?
                                                                                                                                                <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + 'Images/TNAImages/' + files.fileName} download><i class="fa fa-download"></i> </a> :
                                                                                                                                                <i className="btn btn-info fa fa-eye" title="View Logo"
                                                                                                                                                    style={{ display: ProgramInfo && ProgramInfo.isView === 1 ? '' : 'none' }}
                                                                                                                                                    onClick={
                                                                                                                                                        event => ViewFile(event, files.fileName)
                                                                                                                                                    }></i>
                                                                                                                                        }
                                                                                                                                    </td>
                                                                                                                                </tr>


                                                                                                                                {

                                                                                                                                    <div> {
                                                                                                                                        isOpenimg && (

                                                                                                                                            <Lightbox mainSrc={window.$APIBaseURL + 'Images/TNAImages/' + getImagename}
                                                                                                                                                alt="bg image"
                                                                                                                                                onCloseRequest={
                                                                                                                                                    () => setIsOpenimg(false)
                                                                                                                                                } />
                                                                                                                                        )
                                                                                                                                    } </div>
                                                                                                                                }
                                                                                                                            </div>


                                                                                                                        ))
                                                                                                                    : ''


                                                                                                            }
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <span>

                                                                                                    </span>

                                                                                                </td>
                                                                                            </tr>
                                                                                            : ''



                                                                                    );


                                                                                })
                                                                            }


                                                                            {item.ApprovalTypeID === 3 && item.DontShow === 0 &&
                                                                                TNALabDipMapList && TNALabDipMapList.length > 0 ? TNALabDipMapList.filter(x => x.commonApprovalId === item.labdibSubmissionApprovalID).map((exist, j) => {
                                                                                    return (
                                                                                        <tr>
                                                                                            <td colSpan="10">
                                                                                                <span><b>Season Name : </b> {exist.seasonName} &nbsp;</span>
                                                                                                <span><b>Approval Date : </b>  {new Date(exist.approvalDate).toLocaleDateString()} &nbsp;</span>
                                                                                                <span><b>Details : </b> {exist.details} &nbsp;</span>
                                                                                                <span><b>Approved By : </b> {exist.approvedBy}</span>

                                                                                            </td>
                                                                                        </tr>

                                                                                    )
                                                                                }) :
                                                                                item.ApprovalTypeID === 3 && item.DontShow === 0 && allLabDipMapApproval && allLabDipMapApproval.filter(x => x.commonApprovalId === item.labdibSubmissionApprovalID && x.styleProgramID === item.StyleProgramID).map((exist, j) => {
                                                                                    return (
                                                                                        <tr>
                                                                                            <td colSpan="10">
                                                                                                <span><b>Season Name : </b> {exist.seasonName} &nbsp;</span>
                                                                                                <span><b>Approval Date : </b>  {new Date(exist.approvalDate).toLocaleDateString()} &nbsp;</span>
                                                                                                <span><b>Details : </b> {exist.details} &nbsp;</span>
                                                                                                <span><b>Approved By : </b> {exist.approvedBy}</span>

                                                                                            </td>
                                                                                        </tr>

                                                                                    );

                                                                                })
                                                                            }

                                                                        </tbody>
                                                                    ))
                                                                }
                                                            </table>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {isPantonetypePopupOpen ? (
                            <PantoneTypePopup
                                getPantoneTypeList={AllPantonetypeList}
                                pantoneTypeCallback={pantoneTypeCallback}
                                showpopup={true}
                            />
                        ) : null}
                        {isPantonePopupOpen ? (
                            <PantonePopup
                                getPantoneList={getPantone}
                                getPantoneTypeList={AllPantonetypeList}
                                pantoneCallback={pantoneCallback}
                                showpopup={true}
                            />
                        ) : null}
                        {isSwatchPopupOpen ? (
                            <SwatchPopup
                                SwatchList={getSwatch}
                                swatchCallback={swatchCallback}
                                showpopup={true}
                                BuyerID={props.location.state.params.buyerID}
                                BrandID={props.location.state.params.brandID}
                            />
                        ) : null}
                        {isProcesstypePopupOpen ? (
                            <Modal show={isProcesstypePopupOpen === true} size="md" dragable backdrop="static" keyboard={false} onHide={() => setProcessTypePopupOpen(false)}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Add Process Type</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>

                                    <ProcessTypePopup
                                        getProcessTypeList={getProcessTypeList}
                                        LoadProcessTypeList={getProcessTypeList}
                                        processTypeCallback={processTypeCallback}
                                        showpopup={true}
                                    />
                                </Modal.Body>

                            </Modal>
                        ) : ''
                        }
                        <div> {
                            isOpen && (

                                <Lightbox mainSrc={window.$APIBaseURL + getImagename}
                                    alt="bg image"
                                    onCloseRequest={
                                        () => setIsOpen(false)
                                    } />
                            )
                        } </div>
                    </div>
                </Fragment>
            </div>
        </>
    );
};

export default React.memo(Program);