import {
    REPORT_LOADING,
    LOAD_REPORT_LIST,
    UPDATE_COMMENT_REPORT_VIEW_ALL_CHECK_BOX,
    UPDATE_COMMENT_REPORT_VIEW_CHECK_BOX

} from "./types";

import ReportService from "../services/Report/ReportService";

const ReportLoading = (isStatus) => ({
    type: REPORT_LOADING,
    payload: isStatus,
});

// export const retrieveStyle = (StyleId) => async (dispatch) => {
//     try {
//         dispatch(styleLoading(true));
//         const res = await StyleService.GetStyleList(StyleId);
//         dispatch({
//             type: RETRIEVE_STYLE,
//             payload: res.data,
//         });
//         dispatch(styleLoading(false));
//     } catch (err) {
//         console.log(err);
//         dispatch(styleLoading(false));
//     }
// };


export const LoadCommentsReportDetails = (Params) => async (dispatch) => {
    //let Reportlist = [];
    try {
        dispatch(ReportLoading(true));
        const res = await ReportService.GetCommentsStyleList(Params.Operation, Params.IsProduction, Params.PoStartDate, Params.PoEndDate, Params.ExfacStartDate, Params.ExfacEndDate);
        //Reportlist = res.data;
        dispatch({
            type: LOAD_REPORT_LIST,
            payload: res.data,
        });
        dispatch(ReportLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(ReportLoading(false));
    }
    dispatch(ReportLoading(false));
    //return Promise.resolve(Reportlist);
}
export const UpdateCommentsreportViewAllCheckBox = (isChecked) => async (dispatch) => {

    dispatch(ReportLoading(true));
    dispatch({
        type: UPDATE_COMMENT_REPORT_VIEW_ALL_CHECK_BOX,
        payload: isChecked,
    });
    dispatch(ReportLoading(false));

};
export const UpdateCommentsreportViewCheckBox = (params) => async (dispatch) => {
    dispatch(ReportLoading(true));
    dispatch({
        type: UPDATE_COMMENT_REPORT_VIEW_CHECK_BOX,
        payload: params,
    });

    dispatch(ReportLoading(false));

};

