import React, { useState, useEffect, useMemo } from 'react'
import QcInspection from '../../../services/QCInspection/QcInspection';
import moment from "moment";
import { Modal, Button } from "react-bootstrap";
import Lightbox from "react-image-lightbox"
import useFullPageLoader from "../../../hooks/useFullPageLoader";
const ListPreviousReports = ({ props }) => {
    const [getActiontakenList, setActiontakenList] = useState([]);
    const [getReportsList, setReportsList] = useState([]);
    const [getopenpopup, setopenpopup] = useState({ isShow: false, index: null });
    const [getopenReportpopup, setopenReportpopup] = useState({ isShow: false, index: null });
    const [sortColumn, setSortColumn] = useState(''); // The column to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // Sorting order (asc or desc)
    const [getImagename, setImagename] = useState();
    const [isOpenimg, setIsOpenimg] = useState(false);
    const [loader, showLoader, hideLoader] = useFullPageLoader();

    const QcinpsectionId = JSON.parse(localStorage.getItem("QCInspectionID"));

    useEffect(() => {
        showLoader();
        let QcReportLsit = [];
        let QcReportimages = [];
        QcInspection.getQcReportList(1, props.location.state.params.styleID, 0).then((res) => {
           
            if (res.data.qcPreviousreportListDetails) {
                res.data.qcPreviousreportListDetails.map((x, index) => {
                    QcReportLsit.push({
                        ReportDate: x.createdDate,
                        Factory: x.addressTypeName,
                        Report: x.reportType,
                        Comments: x.comment,
                        CreatedBy: x.createdBy,
                        ImagesList: []
                    })
                    res.data.qcPreviousImageListDetails.filter(ne => ne.mainindex === x.commentMainIndex && ne.tnaqcInspectionID === x.tnaqcInspectionID).map((x, yindex) => {
                        QcReportLsit[index].ImagesList.push({
                            Uploadpath1: x.uploadedImagePath1,
                            Uploadpath2: x.uploadedImagePath2,
                        })
                    })
                })
                setReportsList(QcReportLsit);
                hideLoader();
            }
        })
        QcInspection.getQcReportList(2, props.location.state.params.styleID, props.location.state.params.TaskStatus === 1 ? QcinpsectionId : props.location.state.params.TNAQCInspectionID).then((res) => {
           
            if (res.data.qcPreviousreportListDetails) {
                res.data.qcPreviousreportListDetails.map((x, index) => {
                    QcReportimages.push({
                        ReportDate: x.createdDate,
                        Factory: x.addressTypeName,
                        Report: x.reportType,
                        Comments: x.comment,
                        CreatedBy: x.createdBy,
                        QcConclusion: x.qcConclusion,
                        ImagesList: []
                    })
                    res.data.qcPreviousImageListDetails.filter(ne => ne.mainindex === x.commentMainIndex && ne.tnaqcInspectionID === x.tnaqcInspectionID).map((x, yindex) => {
                        QcReportimages[index].ImagesList.push({
                            Uploadpath1: x.uploadedImagePath1,
                            Uploadpath2: x.uploadedImagePath2,
                        })
                    })
                })
                setActiontakenList(QcReportimages);
                hideLoader();
            }
        })
    }, [])

    const sortedData = [...getReportsList].sort((a, b) => {
       
        if (sortColumn) {
            // Compare the values in the selected column for sorting
            const valueA = a[sortColumn];
            const valueB = b[sortColumn];

            if (sortOrder === 'asc') {
                return valueA.localeCompare(valueB); // Ascending order
            } else {
                return valueB.localeCompare(valueA); // Descending order
            }
        }
        return 0; // No sorting criteria selected
    });

    // Functions to handle sorting
    const handleSort = (column) => {
       
        // If the same column is clicked again, reverse the sorting order
        if (column === sortColumn) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortOrder('asc');
        }
    };

    function ViewReportList(index, input) {
       
        setopenpopup({ isShow: true, index: index })
    }
    const handleCloseQCNotesFormPopup = index => {
        //  setAddOrViewAttachment(false);
        setopenpopup({ isShow: false, index: null })

    }
    function ViewReportImagesList(index) {
        setopenReportpopup({ isShow: true, index: index })
    }
    function handleCloseQCReportimage() {
        setopenReportpopup({ isShow: false, index: null })
    }
    const ViewFile = (index, filename) => {
       
        setImagename(filename);
        setIsOpenimg(true);
    }
    return (
        <div className="row">
            <div className='col-lg-12'>
                <div className="panel-group accordion" id="acc_trims_Info">
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h4 className="panel-title">
                                <a className="accordion-toggle collapsed clr-themegreen" data-toggle="collapse" data-parent="#acc_trims_Info" href={"#collapse_acc_trims_Info"}>
                                    Action to be Taken
                                </a>
                            </h4>
                        </div>
                        <div id={"collapse_acc_trims_Info"} className="panel-collapse collapse">
                            <div className="" >
                                <div className="">
                                    {
                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                            <thead>
                                                <tr>
                                                    <th onClick={() => handleSort('ReportDate')}>Date</th>
                                                    <th onClick={() => handleSort('Factory')}>Factory</th>
                                                    <th onClick={() => handleSort('Report')}>Report</th>
                                                    <th onClick={() => handleSort('Comments')}>Comments(SKU)</th>
                                                    <th onClick={() => handleSort('CreatedBy')}>Created / Updated By</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            {
                                                sortedData && sortedData.map((input, index) => (
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                {moment(input.ReportDate).format('DD/MM/YYYY')}
                                                            </td>
                                                            <td>
                                                                {input.Factory}
                                                            </td>
                                                            <td>
                                                                {input.Report}
                                                            </td>
                                                            {/* <td>
                                                                {input.Comments}
                                                            </td> */}
                                                             <td>
                                                                {input.Comments && input.Comments.replace(/,/g, ', ')}
                                                            </td>
                                                            <td>
                                                                {input.CreatedBy}
                                                            </td>
                                                            <td>
                                                                <button type="button" onClick={() => ViewReportList(index, input)}
                                                                    className="btn btn-xs btn-warning" title="View">
                                                                    <i className="fa fa-eye"></i>
                                                                </button>

                                                            </td>
                                                        </tr>
                                                        {
                                                            getopenpopup.isShow && <>
                                                                <Modal dialogClassName="CreatePopup" show={getopenpopup.isShow} size="md" dragable backdrop="static" keyboard={false}
                                                                    onHide={() => handleCloseQCNotesFormPopup()}>
                                                                    <Modal.Header closeButton>
                                                                        <Modal.Title>
                                                                            QA Previous Report Attachment
                                                                        </Modal.Title>
                                                                    </Modal.Header>
                                                                    <Modal.Body>
                                                                        <div className="row">
                                                                            {
                                                                                <div className="col-lg-12 col-sm-12">
                                                                                    <div className="row no-margin">
                                                                                        <div className='col-md-7'>
                                                                                            {
                                                                                                sortedData[getopenpopup.index].ImagesList.length !== 0 && sortedData[getopenpopup.index].ImagesList.map((files, fileindex) => (
                                                                                                    <>

                                                                                                        <div className='row'>
                                                                                                            <div className='col-lg-6' style={{ display: files.Uploadpath1 === '' ? 'none' : '' }}>
                                                                                                                <div className="client_tumblineWashcare">
                                                                                                                    <img className="width_manual1"
                                                                                                                        src={window.$APIBaseURL + 'Images/' + files.Uploadpath1}
                                                                                                                    />
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <a className="washcareView" style={{ marginLeft: '115px' }} title='View QC Notes' onClick={() => ViewFile(fileindex, files.Uploadpath1)}>
                                                                                                                        <i className="btn btn-info fa fa-eye"></i>
                                                                                                                    </a>&nbsp;
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className='col-lg-6' style={{ display: files.Uploadpath2 === '' ? 'none' : '' }}>
                                                                                                                <div className="client_tumblineWashcare">
                                                                                                                    <img className="width_manual1"
                                                                                                                        src={window.$APIBaseURL + 'Images/' + files.Uploadpath2}
                                                                                                                    />
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <a className="washcareView" style={{ marginLeft: '115px' }} title='View QC Notes' onClick={() => ViewFile(fileindex, files.Uploadpath2)}>
                                                                                                                        <i className="btn btn-info fa fa-eye"></i>
                                                                                                                    </a>&nbsp;
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>


                                                                                                        {
                                                                                                            < div > {
                                                                                                                isOpenimg && (

                                                                                                                    <Lightbox mainSrc={window.$APIBaseURL + 'Images/' + getImagename}
                                                                                                                        alt="bg image"
                                                                                                                        onCloseRequest={
                                                                                                                            () => setIsOpenimg(false)
                                                                                                                        } />
                                                                                                                )
                                                                                                            } </div>
                                                                                                        }
                                                                                                    </>

                                                                                                ))

                                                                                            }
                                                                                            {
                                                                                                sortedData[getopenpopup.index].ImagesList[0].Uploadpath1 === '' && sortedData[getopenpopup.index].ImagesList[0].Uploadpath2 === '' ?
                                                                                                    
                                                                                                <p style={{textAlign :'right', color:'#df5138'}}>No Records Found</p>
                                                                                                     : ''
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {/* {sortedData[getopenpopup.index].ImagesList[0].Uploadpath1 !== '' ? 'emty' : '1'} */}
                                                                        </div>

                                                                    </Modal.Body>
                                                                    <Modal.Footer>
                                                                        <button class="btn btn-xs btn-danger" onClick={() => handleCloseQCNotesFormPopup()}>close</button>
                                                                    </Modal.Footer>
                                                                </Modal>
                                                            </>

                                                        }
                                                    </tbody>

                                                ))}
                                            {
                                                getReportsList.length === 0 ?
                                                    <tr>
                                                        <td colSpan="12" className='norecordfound'><span>No Records Found</span></td>
                                                    </tr> : ''
                                            }
                                        </table>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div className="panel-group accordion" id="acc_trims_Info">
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h4 className="panel-title">
                                <a className="accordion-toggle collapsed clr-themegreen" data-toggle="collapse" data-parent="#acc_trims_Info" href={"#collapse_acc_trims_Info1"}>
                                    Reports
                                </a>
                            </h4>
                        </div>
                        <div id={"collapse_acc_trims_Info1"} className="panel-collapse collapse">
                            <div className="" >
                                <div className="">
                                    {
                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                            <thead>
                                                <tr>
                                                    <th onClick={() => handleSort('ReportDate')}>Date</th>
                                                    <th onClick={() => handleSort('Report')}>Report.No</th>
                                                    <th onClick={() => handleSort('Factory')}>Factory</th>
                                                    <th onClick={() => handleSort('Report')}>Report</th>
                                                    <th onClick={() => handleSort('Commentsreport')}>Comments(SKU)</th>
                                                    {props.location.state.params.TaskType !== "PP Meeting" &&
                                                        props.location.state.params.TaskType !== "In and Mid Line Inspection" ? <th onClick={() => handleSort('Comments')}>QC Conclusion</th> : ''}
                                                    <th onClick={() => handleSort('CreatedBy')}>Created / Updated By</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {getActiontakenList.map((Act, index) => (
                                                    <tr>
                                                        <td>
                                                            {moment(Act.ReportDate).format('DD/MM/YYYY')}
                                                        </td>
                                                        <td>
                                                            {props.location.state.params.TaskStatus === 1 ? QcinpsectionId : props.location.state.params.TNAQCInspectionID}
                                                        </td>
                                                        <td>
                                                            {Act.Factory}
                                                        </td>
                                                        <td>
                                                            {Act.Report}
                                                        </td>
                                                        <td>
                                                            {Act.Comments}
                                                        </td>
                                                        {
                                                            props.location.state.params.TaskType !== "PP Meeting" &&
                                                                props.location.state.params.TaskType !== "In and Mid Line Inspection" ? <td>
                                                                {Act.QcConclusion}
                                                            </td> : ''}
                                                        <td>
                                                            {Act.CreatedBy}
                                                        </td>
                                                        <td>
                                                            <button type="button" onClick={() => ViewReportImagesList(index)}
                                                                className="btn btn-xs btn-warning" title="View">
                                                                <i className="fa fa-eye"></i>
                                                            </button>
                                                        </td>
                                                        {
                                                            getopenReportpopup.isShow && <>
                                                                <Modal dialogClassName="CreatePopup" show={getopenReportpopup.isShow} size="md" dragable backdrop="static" keyboard={false}
                                                                    onHide={() => handleCloseQCReportimage()}>
                                                                    <Modal.Header closeButton>
                                                                        <Modal.Title>
                                                                            QA Previous Report Attachment
                                                                        </Modal.Title>
                                                                    </Modal.Header>
                                                                    <Modal.Body>
                                                                        <div className="row">
                                                                            {
                                                                                <div className="col-lg-12 col-sm-12">
                                                                                    <div className="row no-margin">
                                                                                        <div className='col-md-7'>
                                                                                            {
                                                                                                getActiontakenList[getopenReportpopup.index].ImagesList.length !== 0 && getActiontakenList[getopenReportpopup.index].ImagesList.map((files, fileindex) => (
                                                                                                    <>
                                                                                                        {/* <div className="float-Left_washcare file_Image_uploads"> */}
                                                                                                        <div className='row'>
                                                                                                            <div className='col-lg-6' style={{ display: files.Uploadpath1 === '' ? 'none' : '' }}>
                                                                                                                <div className="client_tumblineWashcare">
                                                                                                                    <img className="width_manual1"
                                                                                                                        src={window.$APIBaseURL + 'Images/' + files.Uploadpath1}
                                                                                                                    />
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <a className="washcareView" style={{ marginLeft: '115px' }} title='View QC Notes' onClick={() => ViewFile(fileindex, files.Uploadpath1)}>
                                                                                                                        <i className="btn btn-info fa fa-eye"></i>
                                                                                                                    </a>&nbsp;
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className='col-lg-6' style={{ display: files.Uploadpath2 === '' ? 'none' : '' }}>
                                                                                                                <div className="client_tumblineWashcare">
                                                                                                                    <img className="width_manual1"
                                                                                                                        src={window.$APIBaseURL + 'Images/' + files.Uploadpath2}
                                                                                                                    />
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <a className="washcareView" style={{ marginLeft: '115px' }} title='View QC Notes' onClick={() => ViewFile(fileindex, files.Uploadpath2)}>
                                                                                                                        <i className="btn btn-info fa fa-eye"></i>
                                                                                                                    </a>&nbsp;
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        {/* </div> */}
                                                                                                        {
                                                                                                            < div > {
                                                                                                                isOpenimg && (

                                                                                                                    <Lightbox mainSrc={window.$APIBaseURL + 'Images/' + getImagename}
                                                                                                                        alt="bg image"
                                                                                                                        onCloseRequest={
                                                                                                                            () => setIsOpenimg(false)
                                                                                                                        } />
                                                                                                                )
                                                                                                            } </div>
                                                                                                        }
                                                                                                    </>
                                                                                                ))

                                                                                            }
                                                                                            {
                                                                                                sortedData[getopenpopup.index].ImagesList[0].Uploadpath1 === '' && sortedData[getopenpopup.index].ImagesList[0].Uploadpath2 === '' ?
                                                                                                    <tr>
                                                                                                        <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
                                                                                                    </tr> : ''
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>

                                                                    </Modal.Body>
                                                                    <Modal.Footer>
                                                                        <button class="btn btn-xs btn-danger" onClick={() => handleCloseQCReportimage()}>close</button>
                                                                    </Modal.Footer>
                                                                </Modal>
                                                            </>

                                                        }
                                                    </tr>
                                                ))}
                                                {
                                                    getActiontakenList.length === 0 ?
                                                        <tr>
                                                            <td colSpan="12" className='norecordfound'><span>No Records Found</span></td>
                                                        </tr> : ''
                                                }
                                            </tbody>
                                        </table>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loader}
        </div >
    )

}

export default ListPreviousReports  