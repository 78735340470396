import React, { useState, useEffect, useMemo,Fragment } from "react";
import { TableHeader, Pagination, Search, PageCount } from "../../Datatable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import SweetAlertPopup from '../../Common/SweetAlertPopup';
import { Redirect } from 'react-router-dom';
import Nodify from "../../Common/ReactNotification"
import SearchRetain from "../../Common/SearchRetain";
import CommonService from '../../../services/Common/CommonService';
import { retrievetemplatelist } from "../../../actions/template";
import { useDispatch, useSelector } from "react-redux";
import { templateInfoID } from "../../Common/PageActionNumber";
import moment from 'moment';

const TemplateList = (props) => {

  const [getPlaceholder] = useState("Template Name");
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [getID, setID] = useState({ showPopupDelete: false, Params: {} });
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
  const dispatch = useDispatch();




  const reducerState = useSelector((state) => state);
  const { user: currentUser } = reducerState.auth;

  const comments = reducerState.template.templatelist;

  let isLoadingtemplate = reducerState.template.isLoadingtemplate;

  //Enable Menu
  let activeMenu;
  const menulist = useSelector((state) => state.common.MenuList.item3);
  if (menulist !== undefined) { activeMenu = menulist.filter(i => i.menuName === "Template"); }
  // End Enable Menu

  useEffect(() => {

    dispatch(retrievetemplatelist(1, 0));
    const state1 = props.location.state
    if (state1 !== undefined && state1 !== null) {
      let Msg;
      let Type = 'success'; // default, info, warning
      let title = 'Success!';
      if (state1.message === 'Edit') {
        Msg = 'Available Info Template updated successfully.';
        // Type = 'default';
      } else if (state1.message === 'Add') {
        Msg = 'Available Info Template added successfully.';
      } else {
        Msg = 'Error occured';
        Type = 'warning';
        title = 'Error!';
      }
      window.history.replaceState(null, '')
      Nodify(title, Type, Msg);
    }
    var page = getPlaceholder;
    var setConstraint = SearchRetain(page);
    if (setConstraint !== undefined) {
      setCurrentPage(setConstraint.CurrentPage);
      setITEMS_PER_PAGE(setConstraint.PageItems);
    }

  }, [dispatch])

  useEffect(() => {
    if (!isLoadingtemplate) {
      hideLoader();
    } else {
      showLoader();
    }
  }, [isLoadingtemplate])


  const headers = [
    {
      name: "#",
      field: "",
      sortable: false
    },
    {
      name: "Template Name",
      field: "templatename",
      sortable: true
    }, {
      name: "Created Date",
      field: "CreatedDate",
      sortable: true
    },
    {
      name: "Action",
      field: "",
      sortable: false,
      display: activeMenu && activeMenu[0].isEdit === 0 &&
        activeMenu[0].isDelete === 0 ? 'none' : ''
    },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(comment => comment.templatename.toLowerCase().includes(search.toLowerCase()));
    }
    setTotalItems(computedComments.length);

    // Sorting comments
    // if (sorting.field) {
    //   const reversed = sorting.order === "asc" ? 1 : -1;
    //   computedComments = computedComments.sort((a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]));
    // }
    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1;
      computedComments = computedComments.sort((a, b) => {
        const valueA = a[sorting.field];
        const valueB = b[sorting.field];
    
        // Check if both values are numeric
        const isNumericA = !isNaN(valueA);
        const isNumericB = !isNaN(valueB);
    
        // Check if both values are valid date-time strings using moment
        const isDateTimeA = moment(valueA, moment.ISO_8601, true).isValid();
        const isDateTimeB = moment(valueB, moment.ISO_8601, true).isValid();
    
        if (isNumericA && isNumericB) {
          // If both values are numeric, compare as numbers
          return reversed * (parseFloat(valueA) - parseFloat(valueB));
        } else if (isDateTimeA && isDateTimeB) {
          // If both values are valid date-time strings, compare as dates
          return reversed * (moment(valueA).valueOf() - moment(valueB).valueOf());
        } else {
          // If one or both values are non-numeric and non-valid date-time strings, compare as strings
          return reversed * (valueA?.toString() ?? '').localeCompare(valueB?.toString() ?? '');
        }
      });
    }
    
    // Current Page slice
    let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
    if (records.length === 0 && currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
    return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
  }, [
    comments,
    currentPage,
    search,
    sorting,
    ITEMS_PER_PAGE
  ]);




  const pageRedirect = (dataParams, redirect) => {
   
    if (redirect === 'Add') {
      props.history.push('/Templateadd', { params: [] });
    }
    else if (redirect === 'Edit') {
      props.history.push(`/TemplateEdit`, { params: dataParams });
    }
    else {
      let Params = {
        Operation: 76,
        Id: dataParams.templateInfoID,
      }
      CommonService.CheckMappedItem(Params).then(res => {
        if (res.data.outputResult === "-2") {
          let Title = "Warning!";
          let message = "This template details is already mapped.";
          let type = "warning";
          Nodify(Title, type, message);
        }
        else {
          setID({ showPopupDelete: true, Params: dataParams });
        }
      });
    }
    // else {
    //   let Params = {
    //     Operation: 76,
    //     Id: dataParams.templateInfoID,
    //   }
    //   setID({ showPopupDelete: true, Params: dataParams });
    // }
  }
  const Delcallback = (value) => {
    if (value === true) {
      dispatch(retrievetemplatelist(1, 0));
    }
    setID({ showPopupDelete: false, Params: [] });
  }
  if (!currentUser) {
    return <Redirect to="/login" />;
  }


  return (
    <>

      <div className="page-body">
        <ReactNotification />
        <div className="row">

          <div className="widget">
            <div className="widget-header ">
              <span className="widget-caption">Available Info Template List</span>
            </div>
            <div className="widget-body">
              <div id="simpledatatable_wrapper" className="dataTables_wrapper form-inline no-footer">
                <div className="table-toolbar pull-left">
                  <Search onSearch={
                    value => {
                      setSearch(value);
                      setCurrentPage(1);
                    }
                  }

                    placeholder={getPlaceholder} props={props} />
                </div>
                <div className="table-toolbar pull-right" style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}>

                  &nbsp;
                  <span className="btn btn-primary" onClick={() => pageRedirect('', 'Add')} title="Add Available Info Template">
                    <i className="fa fa-plus"></i>&nbsp;Add
                  </span>
                </div>

                <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                  <TableHeader headers={headers}
                    onSorting={
                      (field, order) => setSorting({ field, order })
                    } />
                  <tbody>
                    {
                      commentsData.map((comment,i) => (
                        <Fragment key={i}>
                        <tr key={
                          comment.TemplateInfoID
                        }>
                          <td style={{width:'25px'}}>  
                              { i + 1}
                          </td>
                          <td>{
                            comment.templatename
                          } &nbsp;{comment.availableInfoStatus === 1 ? <img src="assets/img/bookmark.png" width={"1%"} alt="" title="Default" /> : ''}</td>
                          <td>{
                            comment.createdDate
                          }</td>
                          <td style={{
                            display: activeMenu && activeMenu[0].isEdit === 0 &&
                              activeMenu[0].isDelete === 0 ? 'none' : ''
                          }}>
                            <span style={{ display: activeMenu && activeMenu[0].isEdit === 1 ? '' : 'none' }} title='Edit Template' onClick={() => pageRedirect(comment, 'Edit', getPlaceholder)}
                              className="btn btn-info btn-xs edit">
                              <i className="fa fa-edit"></i>
                            </span>
                            &nbsp;
                            <span style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }} title='Delete Template' onClick={() => pageRedirect(comment, '', getPlaceholder)}
                              className="btn btn-danger btn-xs delete">
                              <i className="fa fa-trash-o"></i>
                            </span>
                          </td>
                        </tr>
                        </Fragment>
                      ))
                     

                    }
                    {
                      totalItems === 0 ?
                        <tr>
                          <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
                        </tr> : ''
                    }

                  </tbody>
                </table>

                <PageCount onPageCount={
                  value => {
                    setITEMS_PER_PAGE(value);
                    setCurrentPage(1);
                  }
                } />
                <Pagination total={totalItems}
                  itemsPerPage={
                    parseInt(ITEMS_PER_PAGE)
                  }
                  currentPage={currentPage}
                  onPageChange={
                    page => setCurrentPage(page)
                  } />

              </div>
            </div>
            {
              getID.showPopupDelete ? <SweetAlertPopup data={
                getID.Params
              }
                deleteCallback={Delcallback}
                showpopup={true}
                pageActionId={templateInfoID}
                Msg={"Available Info Template deleted successfully."} /> : null
            }
          </div>

        </div>

      </div>
    </>
  )

}

export default TemplateList