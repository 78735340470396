import React, { useState, useEffect, useRef, Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import Lightbox from "react-image-lightbox";
import { GetOrderStrikeOffApprovalList, clearStrikeOffApprovalSubRow, GetOrderStrikeOffApprovalViewList, ReloadOrderStrikeOffApprovalViewList } from "../../../../actions/orderStrikeOff";
import { loadSupplierList, getSkuAndStyleDetails } from "../../../../actions/tna";
import CommonService from "../../../../services/Common/CommonService";
import Nodify from "../../../Common/ReactNotification"
import SweetAlertPopup from '../../../Common/SweetAlertPopup';
import { TNAManualCloseChildStrikeOff } from "../../../Common/PageActionNumber";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import moment from "moment";
import Reactselect from 'react-select';
import { Modal, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import GeneralService from "../../../../services/General/GeneralService";
import input from "react-validation/build/input";
import { TableHeader, Pagination, Search, PageCount } from "../../../Datatable";
import SweetAlert from 'react-bootstrap-sweetalert';
import $, { get } from "jquery";
import axios from "axios";
import TnaService from "../../../../services/TNA/TNAService";
import StrikeOffSubmission from "../../../TNA/StrikeOffSubmission";
import StrikeApproval from "../../../TNA/StrikeoffApproval_Order";


const StrikeOffViewSubmissionTab = (props) => {

    //declaration
    const dispatch = useDispatch();
    const [loader, showLoader, hideLoader] = useFullPageLoader();

    //reducer
    const StrikeOffApprovalList = useSelector((state) => state.orderStrikeOff.orderStrikeOffApprovalList);
    //const StrikeOffSubmissionParams = useSelector((state) => state.generalStrikeOff.generalStrikeOffViewDetailParams);
    let isLoadingOrderStrikeOff = useSelector((state) => state.orderStrikeOff.isLoadingOrderStrikeOff);
    let isReLoadingOrderStrikeOff = useSelector((state) => state.orderStrikeOff.isReLoadingOrderStrikeOff);
    const getSupplierList = useSelector((state) => state.tna.drpSupplierList);
    let GLDRequestStatusList = useSelector((state) => state.orderStrikeOff.OLDRequestStatusList);
    const sizeList = useSelector((state) => state.size.drpSizeList);
    const StaticTask = useSelector((state) => state.tna.StaticTask);
    const { user: currentUser } = useSelector((state) => state.auth);
    //state
    const [getID, setID] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [currentExpand, setcurrentExpand] = useState({ currindex: "", currStrikeOffId: "", currColorGroupID: "" });
    const [getComment, setComment] = useState(false);
    const [selectall, setselectall] = useState(false);
    // const [showManual, setshowManual] = useState(false);
    const [showSubmitManual, setSubmitshowManual] = useState(false);
    const [ManualInfo, setManualInfo] = useState([{ IdList: '', manualCloseDate: '', manualCloseComment: '', operation: 0 }]);
    const [ApprovalManualInfo, setApprovalManualInfo] = useState([{ approvalManualCloseDate: '', approvalManualCloseComment: '' }]);
    const [getSizeList, setSizeList] = useState([]);
    const [showSizePopup, setShowSizePopup] = useState({ isPopup: false, detail: '' });
    const [getStyleNameSearch, setgetStyleNameSearch] = useState("");
    const [getStyleNoSearch, setgetStyleNoSearch] = useState("");
    const [getIdPoSearch, setgetIdPoSearch] = useState("");

    const [StrikeoffswatchDownload, setStrikeoffswatchDownload] = useState(false);
    const [StrikeoffswatchUpload, setStrikeoffswatchUpload] = useState(false);
    const [ChildId, setChildId] = useState({ ChildId: 0, Status: 0 });
    const [getsID, setsID] = useState(0);
    const [getSearchReqValues, setSearchReqValues] = useState({
        StatusId: 0,
        SupplierId: 0,
        SearchTxt: "",
        StyleName: "",
        StyleNo: "",
        IdPoNo: "",
        stateDate: [
            {
                startDate: null,
                endDate: new Date(""),
                key: 'selection',
                isShow: false
            }
        ]

    });

    useEffect(() => {
        if (!isLoadingOrderStrikeOff) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingOrderStrikeOff])

    useEffect(() => {

        dispatch(loadSupplierList());
        searchStrikeOff();

    }, [])

    useEffect(() => {
        commonParentExpand();
    }, [isReLoadingOrderStrikeOff])

    let activeMenu;
    let activeMenuSub;
    let StrikeOffSubmissionTab;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
    if (menulist !== undefined && menulistSub.length > 0) {
        activeMenu = menulist.filter(i => i.menuName === 'StrikeOff');
        if (activeMenu.length > 0) {
            activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);


            if (activeMenuSub.length > 0) {

                for (let index = 0; index < activeMenuSub.length; index++) {
                    if (activeMenuSub[index].menuName === "GeneralStrikeOffSubmission") {
                        StrikeOffSubmissionTab = activeMenuSub[index];
                    }
                }
            }
        }
    }


    const SearchReqValues = { ...getSearchReqValues };

    const [Searchvalues, setSearchvalues] = useState({
        SupplierId: 0, approvalStatus: 0
    });
    let BuyerId = 0;
    let BrandId = 0;
    let SeasonId = 0;
    //get props params
    if (props.props.location.state !== undefined) {

        BuyerId = props.props.location.state.params.buyerId === undefined ? 0 : props.props.location.state.params.buyerId;
        BrandId = props.props.location.state.params.brandId === undefined ? 0 : props.props.location.state.params.brandId;
        SeasonId = props.props.location.state.params.seasonId === undefined ? 0 : props.props.location.state.params.seasonId;

    }


    const [showmaunalclose, setshowmaunalclose] = useState(false);

    const [MultiCloseDatas, setMultiCloseDatas] = useState([]);
    const [Manualclosedata, setManualclosedata] = useState({ ManualCloseComment: '', ManualCloseDate: '' });
    const [manualcloseheader, setmanualcloseheader] = useState("");

    const [TNAId, setTNAId] = useState();
    const [TNAStrikeOffRefInfoId, setTNAStrikeOffRefInfoId] = useState();
    const [TaskStatus, setTaskStatus] = useState();
    const [SwatchStatus, setSwatchStatus] = useState();
    const [strikeoffModalpopup, setStrikeOffmodalpopup] = useState(false);

    const [SubmittedOptionListId, setSubmittedOptionListId] = useState();
    const [ColorAppCommentsId, setColorAppCommentsId] = useState();
    const [getstrikeoffApppopup, setStrikeOffApppopup] = useState(false);
    const [editStrikeOffSubmission, seteditStrikeOffSubmission] = useState(false);
    const [delteStrikeOffSubmission, setdeleteStrikeOffSubmission] = useState(false);
    const [editStrikeOffApproval, seteditStrikeOffApproval] = useState(false);
    const [delteStrikeOffApproval, setdeleteStrikeOffApproval] = useState(false);

    // If check production or sampling
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProduction = activeMenus.IsProduction;
    // End

    //#region  ------------------  search details --------------------------------
    const commonParentExpand = () => {
        let rowHiddens = document.querySelectorAll(".parentExpandSubmitTNA");
        for (let row of rowHiddens) {
            row.setAttribute('aria-expanded', 'false');
        }
        let rowIconHiddens = document.querySelectorAll(".clscollapseexpandSubmitTNA");
        for (let rowIcon of rowIconHiddens) {
            rowIcon.className = rowIcon.className.replace("fa-minus-square-o", "fa-plus-square-o");
        }
    }
    const searchStrikeOff = () => {
        SearchReqValues.stateDate[0].isShow = false;
        setSearchReqValues(SearchReqValues);

        let StartDate = null;
        let EndDate = null;
        if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
        }
        if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
        }

        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, StrikeOffId: 0, ColorGroupID: 0, Index: currentExpand.currindex,
            SearchText: SearchReqValues.SearchTxt, Operation: 3, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
            SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
            StyleName: SearchReqValues.StyleName, StyleNo: SearchReqValues.StyleNo, IdPoNo: SearchReqValues.IdPoNo
            , IsProduction: IsProduction
        };
        dispatch(GetOrderStrikeOffApprovalList(params));

        // let params = {
        //     BuyerId: StrikeOffViewDetailParams.BuyerId, BrandId: StrikeOffViewDetailParams.BrandId, SeasonId: StrikeOffViewDetailParams.SeasonId,
        //     BuyerName: StrikeOffViewDetailParams.BuyerName, BrandName: StrikeOffViewDetailParams.BrandName, SeasonName: StrikeOffViewDetailParams.SeasonName,
        //     PantoneId: 0, Index: 0, SearchText: SearchReqValues.SearchTxt, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, StartDate: StartDate, EndDate: EndDate
        // };
        // dispatch(getGeneralStrikeOffDetailsViewList(params));
        commonParentExpand();
    }
    const resetStrikeOff = () => {
        SearchReqValues.stateDate[0].startDate = null;
        SearchReqValues.stateDate[0].endDate = new Date("");
        SearchReqValues.stateDate[0].key = 'selection';
        SearchReqValues.stateDate[0].isShow = false;

        SearchReqValues.StatusId = 0;
        SearchReqValues.SupplierId = 0;
        SearchReqValues.SearchTxt = "";
        SearchReqValues.StyleName = "";
        SearchReqValues.StyleNo = "";
        SearchReqValues.IdPoNo = "";
        setSearchReqValues(SearchReqValues);
        searchStrikeOff();

    }


    const clearDateSelect = () => {
        SearchReqValues.stateDate[0].startDate = null;
        SearchReqValues.stateDate[0].endDate = new Date("");
        SearchReqValues.stateDate[0].key = 'selection';
        SearchReqValues.stateDate[0].isShow = false;

        setSearchReqValues(SearchReqValues);
    }
    const handleDateSelect = (event, isShow) => {

        SearchReqValues.stateDate[0].isShow = isShow;
        setSearchReqValues(SearchReqValues);
    }
    const handleserach = () => {

    }
    const handlePopupDateselection = (item) => {

        SearchReqValues.stateDate = [item.selection];
        setSearchReqValues(SearchReqValues);
    }

    const handleSearchChange = (e, FieldName) => {

        let inputText = '';
        if (FieldName === 'SearchTxt' || FieldName === 'StyleName' || FieldName === 'StyleNo' || FieldName === 'IdPoNo') {
            if (e && e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
        } else if (FieldName === 'Status' || FieldName === 'SupplierId') {
            if (e) {
                inputText = e.value
            }
        }

        if (FieldName === 'Status') {
            SearchReqValues.StatusId = inputText;
        }
        else if (FieldName === 'SupplierId') {
            SearchReqValues.SupplierId = inputText;
        } else if (FieldName === 'SearchTxt') {
            SearchReqValues.SearchTxt = inputText;
        } else if (FieldName === 'StyleName') {
            SearchReqValues.StyleName = inputText;
        }
        else if (FieldName === 'StyleNo') {
            SearchReqValues.StyleNo = inputText;
        }
        else if (FieldName === 'IdPoNo') {
            SearchReqValues.IdPoNo = inputText;
        }
        setSearchReqValues(SearchReqValues);

    }

    function convertUTCDateToLocalDate(date) {
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date;
    }

    //#end  ------------------  search details end --------------------------------


    // #region  ------------------  Expanded Toggle --------------------------------

    const handleToggle = (index, StrikeOffId, ColorGroupID) => {
        let btnToggle = document.getElementById("btn-toggle2_" + index);
        let iconToggle = document.getElementById("icon-toggle2_" + index);

        let ariaExpanded = btnToggle.getAttribute('aria-expanded');
        if (ariaExpanded === 'false') {
            btnToggle.setAttribute('aria-expanded', 'true');
            iconToggle.className = iconToggle.className.replace("fa-plus-square-o", "fa-minus-square-o");

            setcurrentExpand({ currindex: index, currStrikeOffId: StrikeOffId, currColorGroupID: ColorGroupID })
            // searchText();
            // dispatch(GetGeneralStrikeOffSubmissionApprovalList(params));
            let StartDate = null;
            let EndDate = null;
            if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
                StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
            }
            if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
                EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
            }
            let params = {
                BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, StrikeOffId: StrikeOffId, ColorGroupID: ColorGroupID, Index: index,
                SearchText: SearchReqValues.SearchTxt, Operation: 3, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
                SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
                StyleName: SearchReqValues.StyleName, StyleNo: SearchReqValues.StyleNo, IdPoNo: SearchReqValues.IdPoNo
                , IsProduction: IsProduction
            };

            dispatch(GetOrderStrikeOffApprovalList(params));

        }
        else {

            btnToggle.setAttribute('aria-expanded', 'false');
            iconToggle.className = iconToggle.className.replace("fa-minus-square-o", "fa-plus-square-o");
            if (StrikeOffId) {
                dispatch(clearStrikeOffApprovalSubRow(index));
            }
        }
    };

    const handleStyleDetails = (detail, strikeOffGarmentSize, skuidlist, TaskName) => {


        // let sizes;
        // var sizeFilter = [];

        // if (strikeOffGarmentSize.includes(',')) {
        //     sizes = strikeOffGarmentSize.split(',');

        //     sizeList.map(item => {
        //         sizes.map(s => {
        //             if (item.value === parseInt(s)) {
        //                 sizeFilter.push({ label: item.label })
        //             }
        //         })
        //     })
        // }
        // else {
        //     sizes = sizeList.filter(x => x.value === parseInt(strikeOffGarmentSize))[0].label;
        //     sizeFilter.push({ label: sizes })
        // }



        // // const sizeFilter = getSizeList.filter(d => strikeOffGarmentSize.includes(d.value));
        // setSizeList(sizeFilter);
        // setShowSizePopup({ isPopup: true, detail: detail });

        if (skuidlist) {
            dispatch(getSkuAndStyleDetails({
                TNAId: 0, SubId: 0,
                MainId: 0, StrikeOffIdOrTrimInfoId: 0,
                TaskName: TaskName, StyleId: 0
                , SKUIdList: skuidlist, StyleStrikeOffGarmentSizeIdList: strikeOffGarmentSize

            }))
                .then(data => {
                    if (data) {
                        setShowSizePopup({ isPopup: true, detail: detail });
                        setSizeList(data);
                    }
                });
        } else {
            let Title = "Warning!";
            let message = "No SKU in " + TaskName;
            let type = "warning";
            Nodify(Title, type, message);
        }

    }

    const handleSizePopupClose = () => {
        setShowSizePopup({ isPopup: false, detail: '' });
    }



    //action releated functionalities
    //manual close


    const ViewManulClose = (TaskName, TaskType, TaskID, ChildId, Status, Comment, CommentDate) => {

        showLoader();
        setChildId({ ChildId: ChildId, Status: Status });
        var mandata = { ...Manualclosedata }
        mandata.ManualCloseComment = Comment;
        mandata.ManualCloseDate = new Date(CommentDate);
        setManualclosedata(mandata);
        setmanualcloseheader('View Submitted')

        let Operation;
        if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
            Operation = 1;
        }
        else if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
            || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
            Operation = 3;
        }
        else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
            || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER) {
            Operation = 2;
        }
        else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL) {
            Operation = 4;
        }
        else if (TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
            Operation = 5;
        }
        //|| TaskName.toLowerCase() === StaticTask.SMS_PURCHASE_ORDER
        else if (TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || TaskName.toLowerCase() === StaticTask.SMS_APPROVAL
            || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER
            || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE || TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW) {
            Operation = 6;
        }
        else if (TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH) {
            Operation = 7;
        }
        else {
            Operation = 8;
        }


        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId,
            SupplierId: SearchReqValues.SupplierId, TaskType: TaskType, TaskNameId: TaskID,
            TaskTodoInProgressCompletedStatus: 9, StartDate: null, EndDate: null,
            Operation: Operation, ParentIdOrSubChildId: ChildId
        };

        TnaService.GetSubChildMultiTaskManualCloseList(params).then((response) => {
            if (response.data) {
                let data = response.data.map((item) => {

                    if (item.subTaskInfoId === ChildId) {
                        item.isSelected = 1;

                        return item;
                    } else {
                        return item;
                    }
                });
                let isSelectAll = 0;
                if (data) {
                    isSelectAll = !!!data.find(d => d.isSelected === 0);
                    isSelectAll = isSelectAll ? 1 : 0;
                }
                setselectall(isSelectAll);
                setMultiCloseDatas(data.filter(x => x.subTaskInfoId === ChildId));
                hideLoader();
            }
            hideLoader();

        }).catch(() => { hideLoader(); });
        setshowmaunalclose(true);
    }

    const handleChildManualclose = async (TaskName, TaskType, TaskID, ChildId, Status, ManualCloseComment, buyerid, brandid, seasonid, supplierid) => {
       
        showLoader();
        setmanualcloseheader('Approval')
        var mandata = { ...Manualclosedata }
        mandata.ManualCloseComment = '';
        mandata.ManualCloseDate = '';
        setManualclosedata(mandata);
        let Operation;
        if (TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
            Operation = 1;
        }
        else if (TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT) {
            Operation = 3;
        }
        else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
            Operation = 2;
        }
        else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL) {
            Operation = 4;
        }
        else if (TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
            Operation = 5;
        }

        else if (TaskName.toLowerCase() === StaticTask.SMS_APPROVAL) {
            Operation = 6;
        }
        else {
            Operation = 7;

        }


        if (Operation !== 7) { // check submission exists for approvals
            await TnaService.GetTNAManualCloseSubmissionList(ChildId, TaskName).then(async (response) => {
                if (response.data.length !== 0) {

                    setshowmaunalclose(true);

                    //get manual close
                    let Operation;
                    if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
                        Operation = 1;
                    }
                    else if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                        || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                        Operation = 3;
                    }
                    else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
                        || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER
                        || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER) {
                        Operation = 2;
                    }
                    else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL) {
                        Operation = 4;
                    }
                    else if (TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
                        Operation = 5;
                    }
                    //|| TaskName.toLowerCase() === StaticTask.SMS_PURCHASE_ORDER
                    else if (TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || TaskName.toLowerCase() === StaticTask.SMS_APPROVAL
                        || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER
                        || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE || TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW) {
                        Operation = 6;
                    }
                    else if (TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH) {
                        Operation = 7;
                    }
                    else {
                        Operation = 8;
                    }


                    let params = {
                        BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId,
                        SupplierId: SearchReqValues.SupplierId, TaskType: TaskType, TaskNameId: TaskID,
                        TaskTodoInProgressCompletedStatus: 9, StartDate: null, EndDate: null,
                        Operation: Operation, ParentIdOrSubChildId: ChildId
                    };

                    await TnaService.GetSubChildMultiTaskManualCloseList(params).then((response) => {
                        if (response.data) {
                            let data = response.data.map((item) => {

                                if (item.subTaskInfoId === ChildId) {
                                    item.isSelected = 1;

                                    return item;
                                } else {
                                    return item;
                                }
                            });
                            let isSelectAll = 0;
                            if (data) {
                                isSelectAll = !!!data.find(d => d.isSelected === 0);
                                isSelectAll = isSelectAll ? 1 : 0;
                            }
                            setselectall(isSelectAll);
                            if (Status !== 3) {
                                setMultiCloseDatas(data);
                            }
                            else {
                                setMultiCloseDatas(data.filter(x => x.subTaskInfoId === ChildId));
                            }
                            hideLoader();
                        }

                        hideLoader();
                    }).catch(() => { hideLoader(); });
                }
                else {
                    Nodify('Warning!', 'warning', "Manual Submission not yet added for this task");
                    return false;
                }

            }).catch(() => { });
        }
        else {
            setshowmaunalclose(true);

            //get manual close
            let Operation;
            if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
                Operation = 1;
            }
            else if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
                || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                Operation = 3;
            }
            else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
                || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER
                || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER) {
                Operation = 2;
            }
            else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL) {
                Operation = 4;
            }
            else if (TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
                Operation = 5;
            }
            //|| TaskName.toLowerCase() === StaticTask.SMS_PURCHASE_ORDER
            else if (TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || TaskName.toLowerCase() === StaticTask.SMS_APPROVAL
                || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER
                || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE || TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW) {
                Operation = 6;
            }
            else if (TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH) {
                Operation = 7;
            }
            else {
                Operation = 8;
            }


            let params = {
                BuyerId: buyerid, BrandId: brandid, SeasonId: seasonid,
                SupplierId: supplierid, TaskType: TaskType, TaskNameId: TaskID,
                TaskTodoInProgressCompletedStatus: 9, StartDate: null, EndDate: null,
                Operation: Operation, ParentIdOrSubChildId: ChildId
            };

            TnaService.GetSubChildMultiTaskManualCloseList(params).then((response) => {
                if (response.data) {
                    let data = response.data.map((item) => {

                        if (item.subTaskInfoId === ChildId) {
                            item.isSelected = 1;

                            return item;
                        } else {
                            return item;
                        }
                    });
                    let isSelectAll = 0;
                    if (data) {
                        isSelectAll = !!!data.find(d => d.isSelected === 0);
                        isSelectAll = isSelectAll ? 1 : 0;
                    }
                    setselectall(isSelectAll);
                    if (Status !== 3) {
                        setMultiCloseDatas(data);
                    }
                    else {
                        setMultiCloseDatas(data.filter(x => x.subTaskInfoId === ChildId));
                    }

                }


            }).catch(() => { });
        }

    };

    const handledelete = () => {
        let Value = [];
        let Params = {
            Operation: 53,
            Id: ChildId.ChildId,
        };
        CommonService.CheckMappedItem(Params).then(res => {
            if (res.data.outputResult === "-2") {
                // setchkDataExists(true);
                let Title = "Warning!";
                let message = "Approval exists against this Submission.";
                let type = "warning";
                Nodify(Title, type, message);
            }
            else {
                setshowmaunalclose(true);
                // Value.TNAId = props.location.state.params.tnaid;
                Value.Id = ChildId.ChildId;
                setsID({ TNAManualClose: TNAManualCloseChildStrikeOff, showPopupDelete: true, Params: Value });
            }
        })


    }
    const DeleteCallback = (value) => {
        if (value === true) {
            setsID({ showPopupDelete: false, Params: [] });
            setshowmaunalclose(false);
            searchStrikeOff();
        } else {
            setsID({ showPopupDelete: false, Params: [] })
            setshowmaunalclose(true);
        }

    }
    const handleChangeIsManualCloseComments = (event, feild) => {
        var manualclosedata = { ...Manualclosedata };
        let input = '';

        setComment(false);
        if (feild === "Comment") {
            if (event.target.value.trim() !== '') {
                input = event.target.value;
            }
            manualclosedata.ManualCloseComment = input;
        }
        else {
            manualclosedata.ManualCloseDate = event;
            // ManualCloseData.ManualCloseDate = event;
        }
        setManualclosedata(manualclosedata);
    }
    const CustomInputman = (props) => {

        return (
            <input
                className="form-control DatePickerCalenderHoliday min_width_Date"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                menuPosition="fixed"
                placeholder="DD/MM/YYYY"
                style={{ border: getComment && props.value === '' ? '1px solid red' : '' }}
            />
        )
    }

    const SelectAll = (event) => {
        let IsSelected = 0;

        if (event.target.checked === true) {
            IsSelected = 1;
            MultiCloseDatas.forEach(element => {
                element.isSelected = 1;
            })
        }
        else {
            IsSelected = 0;
            MultiCloseDatas.forEach(element => {
                element.isSelected = 0;
            })
        }
        setselectall(IsSelected);
    }

    const OnSelectRecords = (event, index) => {
        const value = [...MultiCloseDatas];

        if (event.target.checked === true) {
            value[index].isSelected = 1;
        }
        else {
            value[index].isSelected = 0;

        }

        let isSelectAll = 0;
        if (value) {
            isSelectAll = !!!value.find(d => d.isSelected === 0);
            isSelectAll = isSelectAll ? 1 : 0;
        }
        setselectall(isSelectAll);

        setMultiCloseDatas(value);
    }
    const SaveChildManulaCloseComments = () => {



        if (Manualclosedata.ManualCloseComment === '' || Manualclosedata.ManualCloseComment === undefined) {
            setComment(true);
            Nodify('Warning!', 'warning', "Please enter comments");
        }
        else if (Manualclosedata.ManualCloseDate === '') {
            setComment(true);
            Nodify('Warning!', 'warning', "Please enter Close Date");
        }
        else {
            // values[index].SubTaskList[subIndex].subFieldList[childIndex].IsManualClose = 0;
            // values[index].SubTaskList[subIndex].subFieldList[childIndex].StatusID = 3;
            // values[index].SubTaskList[subIndex].subFieldList[childIndex].ManualCloseDate = new Date();
            // values[index].SubTaskList[subIndex].subFieldList[childIndex].ChildActualEndDate = new Date();
            // setInputFields(values);


            let ChildStatus = 2;


            let strValue = "";
            let selected = 0;
            for (let data of MultiCloseDatas) {
                if (strValue === "") {
                    if (data.isSelected === 1) {
                        strValue = data.subTaskInfoId.toString();
                        selected = selected + 1;
                    }
                } else {
                    if (data.isSelected === 1) {
                        strValue = strValue + "," + data.subTaskInfoId;
                    }
                }
            }
            if (selected === 0) {
                Nodify('Warning!', 'warning', "Select atleast one Task");
            }
            else {
                var ManualCloseData = { ...Manualclosedata };
                ManualCloseData.ManualCloseDate = new Date(Manualclosedata.ManualCloseDate);
                ManualCloseData.ManualCloseDate.setMinutes(ManualCloseData.ManualCloseDate.getMinutes() + 370);
                // ManualCloseData.ActualEndDate = new Date(values[index].SubTaskList[subIndex].subFieldList[childIndex].ManualCloseDate);
                // ManualCloseData.ActualEndDate.setMinutes(ManualCloseData.ManualCloseDate.getMinutes() + 370);
                // .setDate(values[index].SubTaskList[subIndex].subFieldList[childIndex].ManualCloseDate.getDate() + 1);
                ManualCloseData.ManualCloseComment = Manualclosedata.ManualCloseComment;
                ManualCloseData.IdList = strValue;
                ManualCloseData.TaskStatus = ChildStatus;
                ManualCloseData.CreatedBy = currentUser.employeeinformationID;;
                TnaService.UpdateTaskManualCloseDetails(ManualCloseData).then(res => {
                    if (res.data.outputResult === "2") {
                        Nodify('Success!', 'success', 'Manual Comment Added Successfully for Submission');
                        setshowmaunalclose(false);

                        ReloadApproval();
                        searchStrikeOff();
                        // handleToggle(currentExpand.currindex, currentExpand.currStrikeOffId, currentExpand.currColorGroupID);


                    }
                    else if (res.data.outputResult === "0") {
                        Nodify("Error!", "Error Occured!");
                    }
                });
            }

        }
    }

    const ReloadApproval = () => {
        let StartDate = null;
        let EndDate = null;
        if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
        }
        if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
        }
        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, StrikeOffId: 0, ColorGroupID: 0, Index: currentExpand.currindex,
            SearchText: SearchReqValues.SearchTxt, Operation: 3, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
            SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
            StyleName: SearchReqValues.StyleName, StyleNo: SearchReqValues.StyleNo, IdPoNo: SearchReqValues.IdPoNo, IsProduction: IsProduction
        };

        let approvalviewparams = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, StrikeOffId: 0, ColorGroupID: 0, Index: currentExpand.currindex,
            SearchText: SearchReqValues.SearchTxt, Operation: 4, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
            StyleName: SearchReqValues.StyleName, StyleNo: SearchReqValues.StyleNo, IdPoNo: SearchReqValues.IdPoNo, IsProduction: IsProduction
        };

        dispatch(GetOrderStrikeOffApprovalList(params));
        dispatch(ReloadOrderStrikeOffApprovalViewList(approvalviewparams));
    }

    const OpenStrikeoffSubmssionpopup = (TNAId, TNAStrikeOffRefInfoId, StatusId, SwatchStatus) => {
        setTNAId(TNAId);
        setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
        setTaskStatus(StatusId);
        setSwatchStatus(SwatchStatus)
        setStrikeOffmodalpopup(true);
    }

    const OpenStrikeoffApprovalpopup = (TNAStrikeOffRefInfoId, StatusId, SwatchStatus, ApprovalStatus) => {

        if (ApprovalStatus === 'Pending') {
            StatusId = 1;
        }
        TnaService.StrikeoffSubmissionList(3, TNAStrikeOffRefInfoId, IsProduction).then((response) => {
            if (response.data.length !== 0) {

                setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
                setSubmittedOptionListId(TNAStrikeOffRefInfoId);
                setColorAppCommentsId(TNAStrikeOffRefInfoId);
                setTaskStatus(StatusId);
                setSwatchStatus(SwatchStatus)
                //  setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                setStrikeOffApppopup(true);
            }
            else {
                Nodify('Warning!', 'warning', "Submission not yet added for this task");
                return false;
            }

        }).catch(() => { });

    }

    const handleStrikeoffSub = () => {
        setStrikeOffmodalpopup(false)
        seteditStrikeOffSubmission(false);
        setdeleteStrikeOffSubmission(false);
        setStrikeoffswatchDownload(false);
        setStrikeoffswatchUpload(false);
    }

    const StrikeoffSubmissionCallback = (value, getOrderinfo, Operation, SwatchStatus) => {

        if (value === true) {
            setStrikeOffmodalpopup(false)
            seteditStrikeOffSubmission(false);
            setdeleteStrikeOffSubmission(false);

            ReloadApproval();
            //  handleToggle(currentExpand.currindex, currentExpand.currStrikeOffId, currentExpand.currColorGroupID);

            setStrikeoffswatchDownload(false);
            setStrikeoffswatchUpload(false);
            searchStrikeOff();
        }
    }

    const handleStrikeOffApp = () => {
        setStrikeOffApppopup(false);

    }

    const StrikeoffApprovalCallback = (value, getOrderinfo, Operation, SwatchStatus) => {

        if (value === true) {
            setStrikeOffApppopup(false);
            searchStrikeOff();
            ReloadApproval();
            handleToggle(currentExpand.currindex, currentExpand.currStrikeOffId, currentExpand.currColorGroupID);

        }
    }

    return (
        <Fragment>
            <div> {
                isOpen && (
                    <Lightbox
                        mainSrc={isOpen}
                        alt="bg image"

                        onCloseRequest={
                            () => setIsOpen(false)
                        } />
                )
            } </div>
            <div className="widget-body">
                <div id="registration-form">
                    <form>

                    <div className="col-sm-12">
                            
                            <div className="col-md-2">
                                <div className='form-group'>
                                    <span className='input-icon icon-right'>
                                        <label>Strike Off Code / Name</label>
                                        <input type="text" value={SearchReqValues.SearchTxt} onChange={event => handleSearchChange(event, "SearchTxt")} placeholder="Strike Off Code / Name" className="form-control" />
                                    </span>

                                </div>

                            </div>
                            <div className="col-md-2">
                                <label htmlFor="Status">
                                    Style Name
                                </label>
                                <span className="input-icon icon-right">
                                    <input
                                        type="text"
                                        className="form-control StyleList"
                                        title={'Style Name'}
                                        placeholder={'Style Name'}
                                        value={SearchReqValues.StyleName}
                                        onChange={e => handleSearchChange(e, 'StyleName')}
                                    />
                                </span>
                            </div>

                            <div className="col-md-2">
                                <label htmlFor="Status">
                                    Style No
                                </label>
                                <span className="input-icon icon-right">
                                    <input
                                        type="text"
                                        className="form-control StyleList"
                                        title={'Style No'}
                                        placeholder={'Style No'}
                                        value={SearchReqValues.StyleNo}
                                        onChange={e => handleSearchChange(e, 'StyleNo')}
                                    />
                                </span>
                            </div>





                            <div className="col-md-2">
                                <label htmlFor="Status">
                                      ID/PO NO
                                </label>
                                <span className="input-icon icon-right">
                                    <input
                                        type="text"
                                        className="form-control StyleList"
                                        title={'Id Po No'}
                                        placeholder={'ID/PO NO'}
                                        value={SearchReqValues.IdPoNo}
                                        onChange={e => handleSearchChange(e, 'IdPoNo')}
                                    />
                                </span>

                            </div>

                        

                            <div className="col-md-2">
                                <div className="form-group">
                                    <label htmlFor="SupplierID">
                                        Supplier
                                        {/* <span className="text-danger">*</span> */}
                                    </label>
                                    <span className="input-icon icon-right">
                                        <Reactselect className="basic-single" name="SupplierId"
                                            id={
                                                "SupplierId"
                                            }
                                            isLoading={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={event => handleSearchChange(event, 'SupplierId')}
                                            value={getSupplierList.filter(function (option) {
                                                return option.value === SearchReqValues.SupplierId;

                                            })}
                                            options={getSupplierList}
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                                        ></Reactselect>

                                    </span>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label htmlFor="Status">
                                        Approval Status
                                    </label>
                                    <span className="input-icon icon-right">
                                        <Reactselect className="basic-single" name="Status"
                                            id={
                                                "Status"
                                            }
                                            isLoading={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={event => handleSearchChange(event, 'Status')}
                                            value={GLDRequestStatusList.filter(function (option) {
                                                return option.value === SearchReqValues.StatusId;
                                            })}
                                            options={GLDRequestStatusList}
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                                        ></Reactselect>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="col-md-2">

                                <div className="form-group">
                                    <label >Date Range <span className="rptDateRangeSpan" title="Clicking on the text box cause the datepicker to appear and disappear">(Clicking on the text box...)</span> </label>
                                    <span className="input-icon icon-right">
                                        <input
                                            value={(SearchReqValues.stateDate[0].startDate === null ? '' : moment(SearchReqValues.stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') + ((SearchReqValues.stateDate[0].endDate === null || !moment(SearchReqValues.stateDate[0].endDate).isValid()) ? '' : moment(SearchReqValues.stateDate[0].endDate).format('DD/MM/YYYY'))}
                                            onClick={event => handleDateSelect(event, !SearchReqValues.stateDate[0].isShow)}
                                            //onBlur={event => handleDateBlur()}
                                            type="text"
                                            className={'form-control'}
                                            placeholder="Select Date"
                                        />
                                        <i className="fa fa-times" onClick={clearDateSelect} ></i>
                                    </span>
                                </div>


                                {SearchReqValues.stateDate[0].isShow &&
                                    <DateRangePicker
                                        //onChange={item => setStateDate([item.selection])}
                                        onChange={item => handlePopupDateselection(item)}
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        months={2}
                                        ranges={SearchReqValues.stateDate}
                                        showDateDisplay={false}
                                        direction="vertical"
                                        className={'TNAReportDatePicker'}

                                    />

                                }

                            </div>
                            <div className="col-md-2" style={{ paddingTop: '29px' }}>

                                <button type="button" className="btn btn-success" title="Search" onClick={event => searchStrikeOff()}
                                >
                                    <i className="fa fa-search"></i>
                                </button>
                                &nbsp;&nbsp;
                                <button type="button" className="btn btn-danger" title="Reset" onClick={resetStrikeOff}
                                >
                                    <i className="fa fa-close"></i>
                                </button>
                            </div>
                            {/* <div className="col-sm-4">
                                <div className="form-group">
                                    <label htmlFor="Status">
                                        Approval Status
                                    </label>
                                    <span className="input-icon icon-right">
                                        <Reactselect className="basic-single" name="Status"
                                            id={
                                                "Status"
                                            }
                                            isLoading={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={event => handleSearchChange(event, 'Status')}
                                            value={GLDRequestStatusList.filter(function (option) {
                                                return option.value === SearchReqValues.StatusId;
                                            })}
                                            options={GLDRequestStatusList}
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                                        ></Reactselect>
                                    </span>
                                </div>
                            </div> */}
                    </div>

                        <div className="col-md-12" style={{ overflowX: "auto", minHeight: '100px' }}>
                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable" >
                                <thead>
                                    <tr>
                                        <th className="fixed-column-header-first-tna col-action-or-other" style={{ width: "35px" }}> </th>
                                        <th className="fixed-column-header-first-tna col-action-or-other" style={{ width: "35px" }}> #</th>
                                        <th className="col-action-or-other">Submitted Date</th>
                                        <th className="col-action-or-other">StyleName</th>
                                        <th className="col-action-or-other">StyleNo</th>
                                        <th className="col-action-or-other">ID/PO NO</th>
                                        <th className="col-action-or-other">Supplier</th>
                                        <th className="col-action-or-other">Details</th>
                                        <th className="col-action-or-other">Task Holder / Follower</th>
                                        <th className="col-action-or-other">Approval Target</th>
                                        <th className="col-action-or-other">Approval Status</th>
                                        <th className="col-action-or-other">AWB & Date</th>
                                        <th className="text-center fixed-column-header-Route col-action-or-other">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        StrikeOffApprovalList && StrikeOffApprovalList.length > 0 ?
                                            <>
                                                {
                                                    StrikeOffApprovalList && StrikeOffApprovalList.map((inputField, index) => (
                                                        <Fragment key={`${inputField}~${index}`}>
                                                            <tr class="parent" data-child={"parent_" + index}>
                                                                <td className="fixed-column-first-tna">

                                                                    {
                                                                        <span id={"btn-toggle2_" + index} class="btn-toggle2 parentExpandSubmitTNA" aria-expanded="false" onClick={() => handleToggle(index, inputField.strikeOffId, inputField.colorGroupID)} aria-labelledby="btn-toggle2 id-master">
                                                                            <i id={"icon-toggle2_" + index} class="fa fa-plus-square-o clsPointer clscollapseexpandSubmitTNA"></i>
                                                                        </span>
                                                                    }

                                                                </td>
                                                                <td>{index+1}</td>
                                                                <td>
                                                                    <span className='input-icon icon-right'>
                                                                        {
                                                                            inputField.strikeOff
                                                                        }
                                                                    </span>
                                                                </td>
                                                                <td colSpan="12">
                                                                    <div className="trwidth_manual" style={{ textAlign: "center" }}>
                                                                        <img className="width_manual"
                                                                            src={inputField.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images/Style/Strikeoff/" + inputField.imagePath}
                                                                            alt="Logo"
                                                                            onClick={
                                                                                () => setIsOpen(inputField.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images/Style/Strikeoff/" + inputField.imagePath)
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                            {
                                                                inputField.strikeOffSubmitApprovalReferenceList && inputField.strikeOffSubmitApprovalReferenceList.map((subField, subIndex) => (

                                                                    <Fragment key={`${subIndex}~${subIndex}`}>
                                                                        <tr class={"child_" + index + " parentChild_" + index + "_" + subIndex} >
                                                                            <td></td>
                                                                            <td className="fixed-column-first-tna"style={{paddingLeft:"16px"}} >
                                                                                {subIndex + 1}
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {moment(subField.submittedDate).format('DD/MM/YYYY')} </span>
                                                                                }
                                                                            </td>
                                                                            <td className="cut_text" title={subField.styleName}>
                                                                                {
                                                                                    <span> {subField.styleName} </span>
                                                                                }
                                                                            </td>
                                                                            <td className="cut_text" title={subField.styleNo}>
                                                                                {
                                                                                    <span> {subField.styleNo} </span>
                                                                                }
                                                                            </td>
                                                                            <td className="cut_text" title={subField.idpono}>
                                                                                {
                                                                                    <span> {subField.idpono} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.supplierName} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.details}
                                                                                        {
                                                                                            subField.strikeOffGarmentSize &&
                                                                                            <span onClick={() => handleStyleDetails(subField.details, subField.strikeOffGarmentSize, subField.skuIdList, StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT)} className="text-primary"> {"Size Details"} </span>
                                                                                        }
                                                                                    </span>
                                                                                }
                                                                            </td>

                                                                            <td className="cut_text" title={subField.taskHolderFollowerNames}>
                                                                                {
                                                                                    <span> {subField.taskHolderFollowerNames} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {moment(subField.approvalTarget).format('DD/MM/YYYY')} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.approvalStatus} </span>
                                                                                }
                                                                            </td>

                                                                            <td>
                                                                                {
                                                                                    <span> {subField.awbName} - {(subField.awbDate === null || subField.awbDate === '') ? '' : moment(subField.awbDate).format('DD/MM/YYYY')} </span>
                                                                                }
                                                                            </td>
                                                                            <td style={{ whiteSpace: "nowrap" }}>
                                                                                {
                                                                                    (subField.manualCloseComment !== '' && subField.manualCloseComment !== null && subField.manualCloseComment !== undefined) && subField.statusID === 3 ?
                                                                                        <button type="button" className={subField.manualCloseComment === '' || subField.manualCloseComment === null || subField.manualCloseComment === undefined ? "btn btn-xs btn-darkorange" : "btn btn-xs btn-success"} title="View Submitted Manual Close"
                                                                                            onClick={() => ViewManulClose(StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT, subField.taskType, subField.taskID, subField.childId, subField.statusID,
                                                                                                subField.manualCloseComment, subField.manualCloseDate)}
                                                                                        >
                                                                                            <i className="fa fa-user"></i>&nbsp;
                                                                                            <i className="fa fa-close"></i>
                                                                                        </button>
                                                                                        : ''
                                                                                }
                                                                                &nbsp;
                                                                                {subField.isShowApprovalButton === 1 && subField.manualCloseComment !== '' && (subField.approvalManualCloseComment === '' || subField.approvalManualCloseComment === null) ?
                                                                                    <Fragment>&nbsp;<button type="button" className={subField.approvalManualCloseComment === '' || subField.approvalManualCloseComment === null || subField.approvalManualCloseComment === undefined ? "btn btn-xs btn-darkorange" : "btn btn-xs btn-success"} title="Approval Manual Close"
                                                                                        onClick={() => handleChildManualclose(StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT, subField.taskType, subField.approvalTaskId, subField.approvalChildId, subField.statusID, subField.manualCloseComment)}
                                                                                    >
                                                                                        <i className="fa fa-user"></i>&nbsp;
                                                                                        <i className="fa fa-close"></i>
                                                                                    </button>
                                                                                    </Fragment>
                                                                                    : ''
                                                                                }
                                                                                &nbsp;



                                                                                {
                                                                                    subField.taskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT && subField.statusID === 3
                                                                                        && (subField.childActualEndDate !== 'Manual' && subField.childActualEndDate !== 'Existing') && subField.manualCloseComment === '' ?
                                                                                        <button type="button" className="btn btn-xs btn-success" title='View Submission'
                                                                                            onClick={() => OpenStrikeoffSubmssionpopup(subField.tnaId, subField.childId,
                                                                                                subField.statusID, subField.swatchStatus)}
                                                                                        >
                                                                                            <i className="fa fa-eye "></i>
                                                                                        </button> : ''
                                                                                }
                                                                                &nbsp;
                                                                                {
                                                                                    subField.isShowApprovalButton === 1 && subField.manualCloseComment === '' ?
                                                                                        <button type="button" className="btn btn-xs btn-warning" title="Approve Form"
                                                                                            onClick={() => OpenStrikeoffApprovalpopup(subField.childId,
                                                                                                subField.approvalStatusID, subField.swatchStatus, subField.approvalStatus)}
                                                                                        >
                                                                                            <i className="fa fa-check"></i>
                                                                                        </button> : ''
                                                                                }
                                                                            </td>

                                                                        </tr>

                                                                    </Fragment>
                                                                ))
                                                            }

                                                        </Fragment>
                                                    ))
                                                }
                                            </>
                                            :

                                            <tr><td colSpan="12" className='norecordfound'><span>No Records Found</span></td></tr>

                                    }
                                </tbody>
                            </table>
                        </div>
                    </form>

                </div>

            </div >

            {
                //View Size
                showSizePopup.isPopup === true ?
                    <Modal show={showSizePopup.isPopup === true} dialogClassName="modal-lg" dragable backdrop="static" keyboard={false} onHide={() => handleSizePopupClose()} >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                View Size
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="col-md-12">

                                <table className="table table-striped table-bordered table-hover dataTable no-footer" >
                                    <thead>
                                        <tr>
                                            <th>Detail</th>
                                            <th>Size Name</th>
                                            <th>Sku Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            // getSizeList && getSizeList.map((inputField) => (
                                            <Fragment>
                                                <tr>
                                                    <td>
                                                        <span>
                                                            {showSizePopup.detail}

                                                        </span>
                                                    </td>
                                                    {/* <td>
                                                            <span>
                                                                {inputField.label}
                                                            </span>
                                                        </td> */}
                                                    <td>{getSizeList.sizeNameList}</td>
                                                    <td>{getSizeList.skuNameList}</td>
                                                </tr>
                                            </Fragment>
                                            // ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="col-md-12" style={{ paddingTop: '8px' }}>
                                <Button variant="success" onClick={() => handleSizePopupClose()}>
                                    Close
                                </Button>
                            </div>
                        </Modal.Footer>
                    </Modal> : ''
            }
            {loader}


            {
                strikeoffModalpopup === true ?

                    <Modal show={strikeoffModalpopup === true}
                        size="xl" dragable backdrop="static"
                        keyboard={false}
                        dialogClassName="commonwidth"
                        onHide={() => handleStrikeoffSub()}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {
                                    TaskStatus === 3 && editStrikeOffSubmission === false ?
                                        "View " : editStrikeOffSubmission === true ? 'Update ' : ''
                                }

                                Strike Off submission
                                {
                                    TaskStatus === 3 ?
                                        <>

                                            {
                                                (SwatchStatus === 0 || SwatchStatus === 1) ?
                                                    <span title='Download Swatch Card' style={{ 'margin-left': '782px' }} onClick={() => setStrikeoffswatchDownload(true)}>
                                                        <i class="fa fa-lg fa-file-pdf-o" aria-hidden="true"></i>
                                                    </span>
                                                    : ''

                                            }
                                            &nbsp;

                                            {
                                                SwatchStatus === 1 ?
                                                    <span title='Upload Submitted Swatch Card'>
                                                        <i class="fa fa-lg fa-upload" aria-hidden="true" onClick={() => setStrikeoffswatchUpload(true)}></i>
                                                    </span>
                                                    : ''

                                            }
                                            &nbsp;
                                            {
                                                SwatchStatus === 2 ?
                                                    <span title='Edit Form' style={{ 'margin-left': '680px' }} onClick={() => seteditStrikeOffSubmission(true)}
                                                        className="btn btn-info btn-xs edit">
                                                        <i className="fa fa-edit"></i>
                                                    </span> :
                                                    <span title='Edit Form' onClick={() => seteditStrikeOffSubmission(true)}
                                                        className="btn btn-info btn-xs edit">
                                                        <i className="fa fa-edit"></i>
                                                    </span>
                                            }
                                            {/* <span title='Edit Form' style={{ 'margin-left': '680px' }} onClick={() => seteditStrikeOffSubmission(true)}
                                                className="btn btn-info btn-xs edit">
                                                <i className="fa fa-edit"></i>
                                            </span> */}
                                            &nbsp;
                                            <span title='Delete Form'
                                                className="btn btn-danger btn-xs delete" onClick={() => setdeleteStrikeOffSubmission(true)}>
                                                <i className="fa fa-trash-o"></i>
                                            </span>
                                        </> : ''
                                }
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <StrikeOffSubmission props={props}
                                StrikeoffSubmissionCallback={StrikeoffSubmissionCallback}
                                TNAId={TNAId}
                                TNAStrikeOffRefInfoId={TNAStrikeOffRefInfoId}
                                TaskStatus={TaskStatus}
                                editStrikeOffSubmission={editStrikeOffSubmission}
                                deleteStrikeOffSubmission={delteStrikeOffSubmission}
                                StrikeoffswatchDownload={StrikeoffswatchDownload}
                                StrikeoffswatchUpload={StrikeoffswatchUpload}
                                SwatchStatus={SwatchStatus}
                            ></StrikeOffSubmission>
                        </Modal.Body>
                        <Modal.Footer>

                        </Modal.Footer>

                    </Modal> : ''
            }

            {
                getstrikeoffApppopup === true ?

                    <Modal dialogClassName="commonwidth" show={getstrikeoffApppopup === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handleStrikeOffApp(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {/* {
                                    TaskStatus === 3 && editStrikeOffApproval === false ?
                                        "View " : editStrikeOffApproval === true ? 'Update ' : ''

                                } */}
                                Strike Off Approval
                                {/* {
                                    TaskStatus === 3 ?
                                        <>
                                            {
                                                SwatchStatus !== 3 ?
                                                    <span title='Upload Approved Swatch Card' style={{ 'margin-left': '670px' }}>
                                                        <i class="fa fa-lg fa-upload" aria-hidden="true" onClick={() => setStrikeoffswatchUpload(true)}></i>
                                                    </span>
                                                    : ''


                                            }
                                            {
                                                SwatchStatus === 3 ?
                                                    <span title='Edit Form' style={{ 'margin-left': '700px' }} onClick={() => seteditStrikeOffApproval(true)}
                                                        className="btn btn-info btn-xs edit">
                                                        <i className="fa fa-edit"></i>
                                                    </span> :
                                                    <span title='Edit Form' onClick={() => seteditStrikeOffApproval(true)}
                                                        className="btn btn-info btn-xs edit">
                                                        <i className="fa fa-edit"></i>
                                                    </span>

                                            }


                                            &nbsp;
                                            <span title='Delete Form'
                                                className="btn btn-danger btn-xs delete" onClick={() => setdeleteStrikeOffApproval(true)}>
                                                <i className="fa fa-trash-o"></i>
                                            </span>
                                        </> : ''
                                } */}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <StrikeApproval props={props}
                                StrikeoffApprovalCallback={StrikeoffApprovalCallback}
                                TNAStrikeOffRefInfoId={TNAStrikeOffRefInfoId}
                                SubmittedOptionListId={SubmittedOptionListId}
                                ColorAppCommentsId={ColorAppCommentsId}
                                TaskStatus={TaskStatus}
                                editStrikeOffApproval={editStrikeOffApproval}
                                delteStrikeOffApproval={delteStrikeOffApproval}
                                // StrikeoffswatchDownload={StrikeoffswatchDownload}
                                // StrikeoffswatchUpload={StrikeoffswatchUpload}
                                SwatchStatus={SwatchStatus}
                            ></StrikeApproval>
                        </Modal.Body>
                        <Modal.Footer>

                        </Modal.Footer>

                    </Modal> : ''
            }


            {
                showmaunalclose === true ?
                    <Modal show={showmaunalclose === true} size="lg" dragable backdrop="static" keyboard={false} onHide={() => setshowmaunalclose(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {manualcloseheader === "View Manual Close" ? (
                                    <span>
                                        {manualcloseheader} : Strike off Submission (Local Development) &nbsp;
                                    </span>
                                ) : (
                                    <span>
                                        {manualcloseheader} : Strike off Approval (Local Development)
                                    </span>
                                )}

                                {manualcloseheader !== "Manual Close" && manualcloseheader !== "Approval"?
                                    <button type="button" className="btn btn-xs btn-danger" title="Delete Manual Close" onClick={() => handledelete()}>
                                        <i className="fa fa-trash-o"></i>
                                    </button> : ''
                                }
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                <thead>
                                    <tr>
                                        <th>Comments <span className="text-danger">*</span></th>
                                        <th>Close Date <span className="text-danger">*</span></th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td style={{ width: "600px" }} >
                                            <textarea
                                                rows="3"
                                                placeholder="Enter Comments here..."
                                                autoFocus onFocus={""} autoComplete="off"
                                                maxLength="500" name="EnterRemarks"
                                                id={"EnterRemarks"}
                                                value={Manualclosedata.ManualCloseComment}
                                                onChange={event => handleChangeIsManualCloseComments(event, "Comment")}
                                                style={{ width: "100%", border: getComment && Manualclosedata.ManualCloseComment === '' ? '1px solid red' : '' }}
                                                disabled={manualcloseheader === 'View Submitted' ? true : false}
                                            >
                                            </textarea>
                                        </td>
                                        <td>
                                            <DatePicker className="form-control" name="HolidayDate"
                                                id={
                                                    "HolidayDate"
                                                }
                                                selected={
                                                    Manualclosedata.ManualCloseDate
                                                }
                                                onChange={event => handleChangeIsManualCloseComments(event, "CommentDate")}
                                                dateFormat="dd/MM/yyyy"
                                                peekNextMonth
                                                showMonthDropdown
                                                // showYearDropdown
                                                dropdownMode="scroll"
                                                autoComplete="off"
                                                minDate={new Date()}
                                                maxDate={new Date()}
                                                // style={{ border: getComment && subField.ManualCloseDate === '' ? '1px solid red' : '' }}
                                                customInput={<CustomInputman />}
                                                // isClearable={inputField.HolidayDate === '' ? false : true}
                                                disabled={manualcloseheader === 'View Submitted' ? true : false}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>StyleName</th>
                                        <th>StyleNo</th>
                                        <th>ID Po</th>
                                        <th>Details</th>
                                        <th>Task Holder / Follower</th>
                                        <th>
                                            <label style={{ marginTop: '8px' }}>
                                                <input type="checkbox" name="IsSelected"

                                                    onChange={event => (SelectAll(event))}
                                                    checked={selectall === 1 ? true : false}
                                                    className="colored-blue clearOpacity" />
                                                <span className="text"></span>
                                            </label>&nbsp;&nbsp;&nbsp;
                                            {/* {StartStopInfo && StartStopInfo.isAdd !== 0 && inputField.SelectedDependency &&
                                                                                                                getSelectedDependencyMap(inputField.SelectedDependency) === false && subField.statusID !== 3 &&

                                                                                                                <button type="button" className={subField.hasAllDependencyStart === 1 ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Dependency)"
                                                                                                                    onClick={() => OpenAllDependentStartPopup(index, subIndex, childIndex, subField.hasAllDependencyStart, props.location.state.params.tnaid, inputField.TaskID, subField.ChildId, inputField.IsSingleTask)}
                                                                                                                >
                                                                                                                    <i className={subField.hasAllDependencyStart === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>

                                                                                                                </button>
                                                                                                            } */}
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {

                                        MultiCloseDatas.map((item, index) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <td>{item.styleName}</td>
                                                        <td>{item.styleNo}</td>
                                                        <td>{item.idPoNo}</td>
                                                        <td>{item.details}</td>
                                                        <td>{item.taskOwnerNameList}</td>
                                                        <td className="Actionbutton fixed-column">
                                                            <div style={{ width: "80px" }}>
                                                                {/* {


                                                                                                                                    (item.dependencyStartstop === 1 || (!inputField.SelectedDependency) || getSelectedDependencyMap(inputField.SelectedDependency) === true || item.isSelected === 1
                                                                                                                                    ) && */}
                                                                <label style={{ marginTop: '8px' }}>
                                                                    <input type="checkbox" name="IsSelected"
                                                                        onChange={event => (OnSelectRecords(event, index))}
                                                                        checked={item.isSelected === 1 ? true : false}
                                                                        className="colored-blue clearOpacity" />
                                                                    <span className="text"></span>
                                                                </label>
                                                                {/* }&nbsp;&nbsp;&nbsp; */}

                                                                {/* {
                                                                                                                                    StartStopInfo && StartStopInfo.isAdd !== 0 && inputField.SelectedDependency && getSelectedDependencyMap(inputField.SelectedDependency) === false && subField.statusID !== 3 &&
                                                                                                                                }
                                                                                                                                    <button type="button" className={(item.dependencyStartstop === 1 || item.isSelected === 1) ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Dependency)"
                                                                                                                                    // onClick={() => OpenDependentStartPopup(index, subIndex, childIndex, subField.intDependency, props.location.state.params.tnaid, inputField.TaskID, subField.ChildId, inputField.IsSingleTask)}
                                                                                                                                    >
                                                                                                                                        <i className={item.dependencyStartstop === 1 || item.isSelected === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>

                                                                                                                                    </button>
                                                                                                                                     }  */}
                                                            </div>

                                                        </td>
                                                    </tr>
                                                </>

                                            )
                                        })
                                    }

                                </tbody>
                            </table>
                        </Modal.Body>
                        <Modal.Footer>
                            {
                                manualcloseheader !== 'View Submitted' &&
                                <Button variant="success" onClick={() => SaveChildManulaCloseComments()}>
                                    Save
                                </Button>


                            }


                        </Modal.Footer>
                    </Modal> : ''
            }
            {getsID.showPopupDelete ? <SweetAlertPopup

                // data={''}
                data={
                    getsID.Params
                }
                deleteCallback={DeleteCallback}
                showpopup={true}
                pageActionId={getsID.TNAManualClose}
                Msg={'Manual Close Submission Deleted Successfully'}
            /> : null}
        </Fragment >

    )
}

export default React.memo(StrikeOffViewSubmissionTab);