import {
  COLUMNINFORMATION_LOADING,
  CREATE_COLUMNINFORMATION,
  RETRIEVE_COLUMNINFORMATION,
  DELETE_COLUMNINFORMATION
} from "../actions/types";

const initialState = {
  isLoadingcolumninformation: true,
  columninformations: [],
};

const columninformationReducer = (state = initialState, action) => {
 ;
  const { type, payload } = action;
  switch (type) {
    case COLUMNINFORMATION_LOADING:
      return {
        ...state,
        isLoadingcolumninformation: payload
      };
    case CREATE_COLUMNINFORMATION:
      return { ...state, columninformations: payload };

    case DELETE_COLUMNINFORMATION:
     
      const results = state.columninformations.filter(c => c.moduleID !== payload.columnInformationID);

      return {
        ...state,
        columninformations: results
      };
    case RETRIEVE_COLUMNINFORMATION:
      var uniqueimagesMSD = [...new Map(payload.outputColumnInformationshowList.map(item =>
        [item['moduleID'], item])).values()];
      return { ...state, columninformations: uniqueimagesMSD };



    default:
      return state;
  }
};
export default columninformationReducer;