import React from 'react';
import { useEffect, useMemo, useState, Fragment } from 'react';
import ReactNotification from 'react-notifications-component';
import { TableHeader, Pagination, Search, PageCount } from "../../../Datatable";
import { useDispatch, useSelector } from "react-redux";
import { retrieveTestItem } from '../../../../actions/testitem';
import Lightbox from "react-image-lightbox";
import Nodify from "../../../Common/ReactNotification";
import SearchRetain from "../../../Common/SearchRetain";
import SweetAlertPopup from '../../../Common/SweetAlertPopup';
import { TestItem } from "../../../Common/PageActionNumber";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import { retrieveTestStandard } from '../../../../actions/teststandard';
import { Modal, Button } from "react-bootstrap";
import { TestStandard } from "../../../Common/PageActionNumber";
import moment from 'moment';
const TestStandardList = (props) => {
    const [search, setSearch] = useState("");
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [getPlaceholder] = useState("Test Name");
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [getID, setID] = useState({ showPopupDelete: false, Params: {} });
    const [expandState, setExpandState] = useState({});
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [getTestComment, setTestComment] = useState({});
    const [getTestAttachment, setTestAttachment] = useState({});
    const [getTestHistory, setTestHistory] = useState({});
    const [expandedRows, setExpandedRows] = useState([]);
    const [isOpenimg, setIsOpenimg] = useState(false);
    const [getImagename, setImagename] = useState();
    const [getAttachmentViewMode, setAttachmentViewMode] = useState({ isShow: false, parentindex: null, childindex: null });
    const [getHistoryViewMode, setHistoryViewMode] = useState({ isShow: false, parentindex: null, childindex: null });
    const dispatch = useDispatch();
    //reducer
    const reducerState = useSelector((state) => state);
    const comments = reducerState.TestStandardReducer.TestStandardList;
    const StandardTestNameList = reducerState.TestStandardReducer.TestStandardList.item1 == undefined ? [] : reducerState.TestStandardReducer.TestStandardList.item1;
    const StandardTestCommentList = reducerState.TestStandardReducer.TestStandardList.item2 == undefined ? [] : reducerState.TestStandardReducer.TestStandardList.item2;
    const StandardTestAttachmentList = reducerState.TestStandardReducer.TestStandardList.item3 == undefined ? [] : reducerState.TestStandardReducer.TestStandardList.item3;
    let isLoadingTestStandard = reducerState.TestStandardReducer.isLoadingTestStandard;
    //menu right
    let activeMenu;
    const menulist = useSelector((state) => state.common.MenuList.item3);
    let isLoadingTestName = reducerState.testname.isLoadingTestName;
    if (menulist !== undefined) {
        activeMenu = menulist.filter(i => i.menuName === "TestStandard");
    }

    const headers = [
        { name: "#", field: "", sortable: false },
        { name: "Test Name", field: "testName", sortable: true },
        { name: "Created / Modified By On Date", field: "createdDate", sortable: true },
        {
            name: "Action", field: "", sortable: false,
            display: activeMenu && activeMenu[0].isEdit === 0 &&
                activeMenu[0].isDelete === 0 ? 'none' : ''
        },
    ];

    const commentsData = useMemo(() => {
        if (comments.item1) {
            let computedComments = comments.item1.filter((data => {
                return data.status != 0
            }));
            if (computedComments != undefined) {
                if (search) {
                    computedComments = computedComments.filter(test => test.testName.toLowerCase().includes(search.toLowerCase()));
                }
                // if (sorting.field) {
                //     const reversed = sorting.order === "asc" ? 1 : -1;
                //     computedComments = computedComments.sort((a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]));
                // }
                if (sorting.field) {
                    const reversed = sorting.order === 'asc' ? 1 : -1;
                    computedComments = computedComments.sort((a, b) => {
                      const valueA = a[sorting.field];
                      const valueB = b[sorting.field];
                  
                      // Check if both values are numeric
                      const isNumericA = !isNaN(valueA);
                      const isNumericB = !isNaN(valueB);
                  
                      // Check if both values are valid date-time strings using moment
                      const isDateTimeA = moment(valueA, moment.ISO_8601, true).isValid();
                      const isDateTimeB = moment(valueB, moment.ISO_8601, true).isValid();
                  
                      if (isNumericA && isNumericB) {
                        // If both values are numeric, compare as numbers
                        return reversed * (parseFloat(valueA) - parseFloat(valueB));
                      } else if (isDateTimeA && isDateTimeB) {
                        // If both values are valid date-time strings, compare as dates
                        return reversed * (moment(valueA).valueOf() - moment(valueB).valueOf());
                      } else {
                        // If one or both values are non-numeric and non-valid date-time strings, compare as strings
                        return reversed * valueA.toString().localeCompare(valueB.toString());
                      }
                    });
                  }
                let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
                if (records.length === 0 && currentPage != 1) {
                    setCurrentPage(currentPage - 1);
                }
                setTotalItems(computedComments.length);
                return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
            }
        }
    }, [
        comments,
        currentPage,
        search,
        sorting,
        ITEMS_PER_PAGE
    ]);

    const pageRedirect = (TestStandardParams, redirect, page) => {
        localStorage.setItem('Pagination', JSON.stringify({ CurrentPage: currentPage, Page: page, PageItems: ITEMS_PER_PAGE }));
        if (redirect === 'Add') {
            props.history.push(`/TestStandardRegister`);
        }
        else if (redirect === 'Edit') {
            props.history.push(`/TestStandardEdit`, { params: TestStandardParams });
        } else {
            setID({ showPopupDelete: true, Params: TestStandardParams });
        }
    };

    const handleExpandRow = (id, content) => {
        let TestComment = [];
        if (StandardTestCommentList != undefined && StandardTestCommentList.length > 0) {
            StandardTestCommentList.map((comment, index) => {
                let AttachmentCount = StandardTestAttachmentList.filter((data => {
                    return data.standardID == id && data.commentIndexID == comment.commentIndexID;
                }))
                comment.AttachmentCount = AttachmentCount.length;
                if (id == comment.standardID) {
                    TestComment.push(comment);
                }
            });
            setTestComment(TestComment);
            let currentExpandedRows = expandedRows;
            const isRowExpanded = currentExpandedRows.includes(id);
            let obj = {};
            isRowExpanded ? (obj[id] = false) : (obj[id] = true);
            setExpandState(obj);
            currentExpandedRows = [];
            const newExpandedRows = isRowExpanded ?
                currentExpandedRows.filter(id => id !== id) :
                currentExpandedRows.concat(id);
            setExpandedRows(newExpandedRows);
        }
    }

    const DisplayAttachment = (attachment) => {
        let TestAttachment = [];
        TestAttachment = StandardTestAttachmentList.filter((data => {
            return data.standardID == attachment.standardID && data.commentIndexID == attachment.commentIndexID;
        }))
        setTestAttachment(TestAttachment);
        setAttachmentViewMode({ isShow: true, parentindex: null, childindex: null });
    }
    const HideAttachment = () => {
        let TestAttachment = [];
        setTestAttachment(TestAttachment);
        setAttachmentViewMode({ isShow: false, parentindex: null, childindex: null });
    }

    const ViewAttachmentFile = (filename) => {
        setImagename(filename);
        setIsOpenimg(true);
    }

    const ViewAttachmentHistory = (standardID) => {
        let TestHistory = [];
        TestHistory = StandardTestCommentList.filter((data => {
            data.Attachment = StandardTestAttachmentList.filter((attachment => {
                return attachment.standardID == standardID & data.commentIndexID == attachment.commentIndexID;
            }))
            return data.standardID == standardID;
        }))
        setTestHistory(TestHistory);
        setHistoryViewMode({ isShow: true, parentindex: null, childindex: null });
    }

    const HideAttachmentHistory = (standardID) => {
        setHistoryViewMode({ isShow: false, parentindex: null, childindex: null });
    }

    const Delcallback = (value) => {
        dispatch(retrieveTestStandard(0));
        setID({ showPopupDelete: false, Params: [] });
    }

    useEffect(() => {
        dispatch(retrieveTestStandard(0));
        const state1 = props.location.state;
        if (state1 !== undefined && state1 != null) {
            let Msg;
            let Type = 'success';
            let title = 'Success!';
            if (state1.message === 'Edit') {
                Msg = 'Test Standard updated successfully.';
            } else if (state1.message === 'Add') {
                Msg = 'Test Standard added successfully.';
            } else {
                Msg = 'Error occured';
                Type = 'warning';
                title = 'Error!';
            }
            window.history.replaceState(null, '')
            Nodify(title, Type, Msg);
        }
        var page = getPlaceholder;
        var setConstraint = SearchRetain(page);
        if (setConstraint !== undefined) {
            setCurrentPage(setConstraint.CurrentPage);
            setITEMS_PER_PAGE(setConstraint.PageItems);
        }
    }, [dispatch]);

    useEffect(() => {
        if (!isLoadingTestStandard) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingTestStandard]);

    return (
        <>
            {/* <h2 className='text-info'>Work in progress</h2> */}
            <div className="page-body">
                <ReactNotification />
                <div className="widget-header">
                    <span className="widget-caption">Test Standard List</span>
                </div>
                <div className="widget-body" style={
                    { paddingBottom: '4%' }
                }>
                    <div className="dataTables_wrapper form-inline no-footer">
                        <div className="table-toolbar pull-left">
                            <Search
                                onSearch={
                                    value => {
                                        setSearch(value);
                                        setCurrentPage(1);
                                    }
                                }
                                placeholder={getPlaceholder} props={props} />
                        </div>
                        <div className="table-toolbar pull-right" style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}>
                            &nbsp;
                            <span
                                className="btn btn-primary" title='Add Test Standard'
                                onClick={() => pageRedirect("", 'Add')} style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }} >
                                <i className="fa fa-plus"></i>&nbsp;Add
                            </span>
                        </div>
                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                            <TableHeader headers={headers} onSorting={(field, order) => setSorting({ field, order })} />
                            <tbody>
                                {
                                    commentsData != undefined && commentsData.map((comment,i) => (
                                        <Fragment key={i}>
                                        <>
                                            <tr>
                                                <td style={{width:'25px'}}>  
                                                    { i + 1}
                                                </td>
                                                <td>
                                                    <i className={
                                                        expandState[comment.standardID] ?
                                                            'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                    } onClick={event => handleExpandRow(comment.standardID, comment.testName)}></i>&nbsp;{comment.testName}
                                                </td>
                                                <td>{comment.createdByName != null && comment.createdByName != undefined ? comment.createdByName + " On " + comment.createdDate : ""}</td>
                                                <td style={{
                                                    display: activeMenu && activeMenu[0].isEdit === 0 &&
                                                        activeMenu[0].isDelete === 0 ? 'none' : ''
                                                }}>
                                                    <span style={{ display: activeMenu && activeMenu[0].isEdit === 1 ? '' : 'none' }}
                                                        title='Edit Test Standard' className="btn btn-info btn-xs edit"
                                                        onClick={() => pageRedirect(comment, 'Edit', getPlaceholder)}>
                                                        <i className='fa fa-edit'></i>
                                                    </span>&nbsp;
                                                    <span title='Delete Test Standard'
                                                        style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}
                                                        className="btn btn-danger btn-xs delete"
                                                        onClick={() => pageRedirect(comment, '', getPlaceholder)}>
                                                        <i className='fa fa-trash'></i>
                                                    </span>&nbsp;
                                                    <span title='View History' className="btn btn-primary btn-xs" onClick={() => ViewAttachmentHistory(comment.standardID)}>
                                                        <i className='fa fa-list'></i>
                                                    </span>
                                                </td>
                                            </tr>
                                            {
                                                expandedRows[0] === comment.standardID ? <tr>
                                                    <td colSpan={3}>
                                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                            <thead>
                                                                <tr>
                                                                    <th>Standard</th>
                                                                    <th>Created / Modified By On Date</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    getTestComment.length > 0 && getTestComment.map(item => {
                                                                        return (
                                                                            <tr>
                                                                                <td>{item.comment}</td>
                                                                                <td>{item.createdByName} On {item.createdDate}</td>
                                                                                <td>
                                                                                    {/* <span title='History' className="btn btn-primary btn-xs" >
                                                                                        <i className='fa fa-list'></i>
                                                                                    </span>&nbsp; */}
                                                                                    <span title='Attachments' className="btn btn-warning btn-xs"
                                                                                        onClick={event => DisplayAttachment(item)}>
                                                                                        <i className='fa fa-paperclip'></i>
                                                                                        &nbsp;{item.AttachmentCount > 0 ? item.AttachmentCount : ""}
                                                                                    </span>
                                                                                </td>
                                                                                {
                                                                                    getAttachmentViewMode && getAttachmentViewMode.isShow &&
                                                                                    <Modal dialogClassName="CreatePopup" show={getAttachmentViewMode.isShow} size="sm" dragable backdrop="static" keyboard={false}
                                                                                    >
                                                                                        <Modal.Header closeButton onHide={() => HideAttachment()}>
                                                                                            <Modal.Title>
                                                                                                Test Standard Attachment
                                                                                            </Modal.Title>
                                                                                        </Modal.Header>
                                                                                        <Modal.Body>
                                                                                            <div className="row">
                                                                                                <div className="col-sm-12 col-lg-12">
                                                                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable" style={{ marginTop: '1%' }}>
                                                                                                        <thead>
                                                                                                            <tr>
                                                                                                                <th>
                                                                                                                    File Name
                                                                                                                </th>
                                                                                                                <th>
                                                                                                                    Created / Modified By On Date
                                                                                                                </th>
                                                                                                                <th>
                                                                                                                    Action
                                                                                                                </th>
                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            {
                                                                                                                getTestAttachment.length > 0 ? getTestAttachment.map(attachment => {
                                                                                                                    return (
                                                                                                                        <tr>
                                                                                                                            <td>
                                                                                                                                {attachment.fileName.split('_')[1]}
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                {attachment.createdByName != null && attachment.createdByName != "" ? attachment.createdByName + " On " + attachment.createdDate : ""}

                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                {(attachment.fileType === "image/jpeg" || attachment.fileType === "image/jpg" || attachment.fileType === "image/png" ||
                                                                                                                                    attachment.fileType === "image/gif") ? <p title='View' onClick={() => ViewAttachmentFile(attachment.fileName)}
                                                                                                                                        className="btn btn-success btn-xs delete">
                                                                                                                                    <i className="fa fa-eye"></i>
                                                                                                                                </p> : ""}&nbsp;

                                                                                                                                {(attachment.fileType === "application/pdf" || attachment.fileType === "application/msword" || attachment.fileType === "application/vnd.ms-excel"
                                                                                                                                    || attachment.fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                                                                                                                                    attachment.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") ?
                                                                                                                                    <a type="button"
                                                                                                                                        title="Download" class="btn btn-xs btn-primary" target="_blank"
                                                                                                                                        href={window.$APIBaseURL + 'Images/Master/TestStandard/' + attachment.fileName} download>
                                                                                                                                        <i class="fa fa-download"></i> </a> : ""}&nbsp;

                                                                                                                            </td>
                                                                                                                            {
                                                                                                                                < div > {
                                                                                                                                    isOpenimg && (

                                                                                                                                        <Lightbox mainSrc={window.$APIBaseURL + 'Images/Master/TestStandard/' + getImagename}
                                                                                                                                            alt="bg image"
                                                                                                                                            onCloseRequest={
                                                                                                                                                () => setIsOpenimg(false)
                                                                                                                                            } />
                                                                                                                                    )
                                                                                                                                } </div>
                                                                                                                            }
                                                                                                                        </tr>
                                                                                                                    )
                                                                                                                }) : <Fragment>
                                                                                                                    <tr>
                                                                                                                        <td colSpan="3" className='norecordfound'><span>No Records Found</span></td>
                                                                                                                    </tr>
                                                                                                                </Fragment>
                                                                                                            }
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Modal.Body>
                                                                                        <Modal.Footer>
                                                                                            <button type="button" class="btn btn-danger" onClick={() => HideAttachment()}><i className='fa fa-times'></i>
                                                                                                &nbsp;Close</button>
                                                                                        </Modal.Footer>
                                                                                    </Modal>
                                                                                }

                                                                            </tr>

                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr> : ""
                                            }
                                        </>
                                        </Fragment>
                                    ))
                                }
                                {
                                    getHistoryViewMode && getHistoryViewMode.isShow &&
                                    <Modal dialogClassName="CreatePopup" show={getHistoryViewMode.isShow} size="sm" dragable backdrop="static" keyboard={false}
                                    >
                                        <Modal.Header closeButton onHide={() => HideAttachmentHistory()}>
                                            <Modal.Title>
                                                Test Standard Attachment History
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="row">
                                                <div className="col-sm-12 col-lg-12">
                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable" style={{ marginTop: '1%' }}>
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    Standard
                                                                </th>
                                                                <th>
                                                                    Created / Modified By On Date
                                                                </th>
                                                                <th>
                                                                    Attachment Count
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                getTestHistory.length > 0 && getTestHistory.map(history => {
                                                                    return (
                                                                        <tr>
                                                                            <td>{history.comment}</td>
                                                                            <td>{history.createdByName != null && history.createdByName != undefined ? history.createdByName + " On " + history.createdDate : ""}</td>
                                                                            <td>{history.Attachment.length}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <button type="button" class="btn btn-danger" onClick={() => HideAttachmentHistory()}><i className='fa fa-times'></i>
                                                &nbsp;Close</button>
                                        </Modal.Footer>
                                    </Modal>
                                }
                                {
                                    totalItems === 0 ?
                                        <tr>
                                            <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
                                        </tr> : ''
                                }
                            </tbody>
                        </table>
                        <PageCount onPageCount={
                            value => {
                                setITEMS_PER_PAGE(value);
                                setCurrentPage(1);
                            }
                        } />
                        <Pagination total={totalItems}
                            itemsPerPage={
                                parseInt(ITEMS_PER_PAGE)
                            }
                            currentPage={currentPage}
                            onPageChange={
                                page => setCurrentPage(page)
                            } />
                    </div>

                </div>
                {
                    getID.showPopupDelete ? <SweetAlertPopup data={
                        getID.Params
                    }
                        deleteCallback={Delcallback}
                        showpopup={true}
                        pageActionId={TestStandard}
                        Msg={"Test Standard deleted successfully."} /> : null
                }
            </div >
            {loader}
        </>
    )
}
export default TestStandardList;