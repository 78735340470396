import React, { Fragment, useState, useMemo, useEffect } from "react";
import Reactselect from 'react-select';
import FinalinspectionService from "../../../services/FinalInspection/FinalinspectionService";
import FinalinspecUpload from "../../Common/FinalinspecUpload";
import Lightbox from "react-image-lightbox"
import Nodify from "../../Common/ReactNotification";

const FinalWorkmanshiptemp = ({ props, BasicDetails, WorkmanshipCallback, IsValidationCallback }) => {

    const [getWorkmanshipValue, setWorkmanshipValue] = useState([]);
    const [workmanshipExpandState, setworkmanshipExpandState] = useState({});
    const [workmanshipexpandedRows, setworkmanshipExpandedRows] = useState([]);
    const [getCommonResult, setCommonResult] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [getImagename, setImagename] = useState();
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        setSubmitted(IsValidationCallback);
    }, [IsValidationCallback === true]);

    const inpsectionId = JSON.parse(localStorage.getItem("InspectionID"));
    useEffect(() => {
        if (props.location.state.params.ParentTask === "ProductionFLDetails") {
            BasicDetails.styleID = props.location.state.params.NewDetails.styleID
        }
        if (props.location.state.params.TaskStatus === 1) {
            let StyleAndColorEdit = [];
            if (inpsectionId !== null) {
                FinalinspectionService.GetTNAFinalinspecMainInfo(inpsectionId, props.location.state.params.details.tnaid).then((res) => {
                    if (res.data.finalInsWorkmanshiList.length > 0) {
                        res.data.finalInsWorkmanshiList.map((y, index) => {
                            StyleAndColorEdit.push({
                                TNAFinalInspecworkmanshipID: y.tnaFinalInspecworkmanshipID,
                                WorkmanResultID: y.result,
                                WorkmanFindingsRemarks: y.findingsRemarks,
                                workmanshipName: y.workmanshipName,
                                WorkmanMainIndex: y.workmanshipMainindex,
                                ImagesList: []
                            })

                            res.data.finalInsWorkmanshipSubList.filter(x => x.workmanshipMainindex === y.workmanshipMainindex).map((x, i) => {
                                StyleAndColorEdit[index].ImagesList.push({
                                    TNAFinalInspecworkmanshipSubID: x.tnaFinalInspecworkmanshipSubID,
                                    UploadedImagePath1: x.uploadedImagePath1,
                                    UploadedImagePath2: x.uploadedImagePath2,
                                    MainIndex: x.workmanshipMainindex
                                })
                            })

                        })
                        setWorkmanshipValue(StyleAndColorEdit)
                        WorkmanshipCallback(StyleAndColorEdit)
                    } else {
                        FinalinspectionService.getWorkmantempList(BasicDetails.styleID).then((res) => {
                            if (res.data) {
                                res.data.map((x, index) => {
                                    StyleAndColorEdit.push({
                                        TNAFinalInspecworkmanshipID: 0,
                                        workmanshipName: x.workmanItemName,
                                        WorkmanResultID: 0,
                                        WorkmanFindingsRemarks: '',
                                        WorkmanMainIndex: index,
                                        ImagesList: []
                                    })
                                    StyleAndColorEdit[index].ImagesList.push({
                                        TNAFinalInspecworkmanshipSubID: 0,
                                        UploadedImagePath1: "",
                                        UploadedImagePath2: "",
                                        MainIndex: index
                                    })
                                })

                            }
                            setWorkmanshipValue(StyleAndColorEdit)
                            WorkmanshipCallback(StyleAndColorEdit)
                        })
                    }

                })
            } else {
                let StyleAndColorEdit = [];
                if (props.location.state.params.TaskStatus === 1) {
                    FinalinspectionService.getWorkmantempList(BasicDetails.styleID).then((res) => {

                        if (res.data) {
                            res.data.map((x, index) => {
                                StyleAndColorEdit.push({
                                    TNAFinalInspecworkmanshipID: 0,
                                    workmanshipName: x.workmanItemName,
                                    WorkmanResultID: 0,
                                    WorkmanFindingsRemarks: '',
                                    WorkmanMainIndex: index,
                                    ImagesList: []
                                })
                                StyleAndColorEdit[index].ImagesList.push({
                                    TNAFinalInspecworkmanshipSubID: 0,
                                    UploadedImagePath1: "",
                                    UploadedImagePath2: "",
                                    MainIndex: index
                                })
                            })

                        }
                        setWorkmanshipValue(StyleAndColorEdit)
                        WorkmanshipCallback(StyleAndColorEdit)
                    })

                }
            }
        }
    }, [inpsectionId !== null && props.location.state.params.TaskStatus === 1])

    // useEffect(() => {
    //     let StyleAndColorEdit = [];
    //     if (props.location.state.params.TaskStatus === 1) {
    //         FinalinspectionService.getWorkmantempList(BasicDetails.styleID).then((res) => {
    //             if (res.data) {
    //                 res.data.map((x, index) => {
    //                     StyleAndColorEdit.push({
    //                         TNAFinalInspecworkmanshipID: 0,
    //                         workmanshipName: x.workmanItemName,
    //                         WorkmanResultID: 0,
    //                         WorkmanFindingsRemarks: '',
    //                         WorkmanMainIndex: index,
    //                         ImagesList: []
    //                     })
    //                     StyleAndColorEdit[index].ImagesList.push({
    //                         TNAFinalInspecworkmanshipSubID: 0,
    //                         UploadedImagePath1: "",
    //                         UploadedImagePath2: "",
    //                         MainIndex: index
    //                     })
    //                 })

    //             }
    //             setWorkmanshipValue(StyleAndColorEdit)
    //             WorkmanshipCallback(StyleAndColorEdit)
    //         })

    //     }
    // }, [inpsectionId === null])

    useEffect(() => {
        if (props.location.state.params.ParentTask === "ProductionFLDetails") {
            BasicDetails.styleID = props.location.state.params.NewDetails.styleID
        }
        // let CommonResult = [];
        // FinalinspectionService.getCommonResultList(0).then((res) => {

        //     if (res.data) {
        //         res.data.map((x) => {
        //             CommonResult.push({
        //                 value: x.resultID,
        //                 label: x.resultName
        //             })
        //         })
        //         setCommonResult(CommonResult);
        //     }
        // })
        let CommonResult = [];
        FinalinspectionService.getCommonResultList(0).then((res) => {
            if (res.data) {
                res.data.map((element) => {

                    let LabelColor = '';
                    if (element.resultID === 1) {
                        LabelColor = <><span style={{ color: "green" }}><b>Ok</b></span></>
                    }
                    else if (element.resultID === 2) {
                        LabelColor = <><span style={{ color: "Red" }}><b>Not ok</b></span></>
                    }
                    else if (element.resultID === 3) {
                        LabelColor = <><span style={{ color: "blue" }}><b>Not Applicable</b></span></>
                    }

                    CommonResult.push({
                        value: element.resultID,
                        label: LabelColor,
                        subLabel: element.value,
                    })
                })
                setCommonResult(CommonResult);
            }
        })
        let StyleAndColorEdit = [];
        if (props.location.state.params.TaskStatus === 2) {
            FinalinspectionService.GetTNAFinalinspecMainInfo(props.location.state.params.TNAInspectionID, props.location.state.params.details.tnaid).then((res) => {
                if (res.data.finalInsWorkmanshiList.length > 0) {
                    res.data.finalInsWorkmanshiList.map((y, index) => {

                        StyleAndColorEdit.push({
                            TNAFinalInspecworkmanshipID: y.tnaFinalInspecworkmanshipID,
                            WorkmanResultID: y.result,
                            WorkmanFindingsRemarks: y.findingsRemarks,
                            workmanshipName: y.workmanshipName,
                            WorkmanMainIndex: y.workmanshipMainindex,
                            ImagesList: []
                        })

                        res.data.finalInsWorkmanshipSubList.filter(x => x.workmanshipMainindex === y.workmanshipMainindex).map((x, i) => {
                            StyleAndColorEdit[index].ImagesList.push({
                                TNAFinalInspecworkmanshipSubID: x.tnaFinalInspecworkmanshipSubID,
                                UploadedImagePath1: x.uploadedImagePath1,
                                UploadedImagePath2: x.uploadedImagePath2,
                                MainIndex: x.workmanshipMainindex
                            })
                        })

                    })
                    WorkmanshipCallback(StyleAndColorEdit)
                    setWorkmanshipValue(StyleAndColorEdit)

                } else {
                    FinalinspectionService.getWorkmantempList(BasicDetails.styleID).then((res) => {

                        if (res.data) {
                            res.data.map((x, index) => {
                                StyleAndColorEdit.push({
                                    TNAFinalInspecworkmanshipID: 0,
                                    workmanshipName: x.workmanItemName,
                                    WorkmanResultID: 0,
                                    WorkmanFindingsRemarks: '',
                                    WorkmanMainIndex: index,
                                    ImagesList: []
                                })
                                StyleAndColorEdit[index].ImagesList.push({
                                    TNAFinalInspecworkmanshipSubID: 0,
                                    UploadedImagePath1: "",
                                    UploadedImagePath2: "",
                                    MainIndex: index
                                })
                            })

                        }
                        setWorkmanshipValue(StyleAndColorEdit)
                        WorkmanshipCallback(StyleAndColorEdit)
                    })
                }
            })
        }
    }, [props.location.state.params.TaskStatus === 2])

    let styles = {
        control: styles => ({ ...styles, border: '1px solid red', innerHeight: '100px', outerHeight: '100px' }),
    }
    const handleWorkmanshipExpandRow = (rowNumber) => {
        setworkmanshipExpandedRows([]);
        let currentExpandedRows;
        let isRowExpanded = false;
        currentExpandedRows = workmanshipexpandedRows;
        isRowExpanded = currentExpandedRows.includes(rowNumber);
        let obj = {};
        isRowExpanded ? (obj[rowNumber] = false) : (obj[rowNumber] = true);
        setworkmanshipExpandState(obj);
        currentExpandedRows = [];
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== rowNumber) :
            currentExpandedRows.concat(rowNumber);
        setworkmanshipExpandedRows(newExpandedRows);
    }

    const ImageCallback = (value, index, childindex, FeildName) => {

        var imagevalues = [...getWorkmanshipValue]
        let profile = imagevalues !== '' ? "/Style/SKU/" : '';
        if (FeildName === 'Image1') {
            imagevalues[index].ImagesList[childindex].MainIndex = index;
            imagevalues[index].ImagesList[childindex].UploadedImagePath1 = profile + value;
        } else {
            imagevalues[index].ImagesList[childindex].MainIndex = index;
            imagevalues[index].ImagesList[childindex].UploadedImagePath2 = profile + value;
        }
        //imagevalues[index].UploadedImage = value
        setWorkmanshipValue(imagevalues)
        //handleCommonResultInputChange()
    }

    const handleCommonResultInputChange = (index, e, FeildName) => {

        const values = [...getWorkmanshipValue];
        let inputText = ""
        if (FeildName === "WorkmanshipResult") {
            if (e !== null) {
                values[index].WorkmanResultID = e.value;
                values[index].WorkmanResultName = e.label;
                values[index].WorkmanMainIndex = index;
            } else {
                values[index].WorkmanResultID = 0;
                values[index].WorkmanResultName = '';
            }
        } else {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            values[index].WorkmanFindingsRemarks = inputText;
        }
        setWorkmanshipValue(values);
        WorkmanshipCallback(values)
    };

    function ViewFile(event, filename, index) {

        // event.preventDefault();
        setIsOpen(true);
        setImagename(filename);
    }

    const deleteFile = (index, childindex) => {
        var imagevalues = [...getWorkmanshipValue]
        imagevalues[index].ImagesList[childindex].UploadedImagePath1 = "";
        setWorkmanshipValue(imagevalues);
        WorkmanshipCallback(imagevalues)
    }

    const deleteFile2 = (index, childindex) => {
        var imagevalues = [...getWorkmanshipValue]
        imagevalues[index].ImagesList[childindex].UploadedImagePath2 = "";
        setWorkmanshipValue(imagevalues);
        WorkmanshipCallback(imagevalues)
    }

    function handleAddFields(index, childindex) {
        var imagevalues = [...getWorkmanshipValue]
        let isvalid = true;
        if (imagevalues[index].ImagesList[childindex].UploadedImagePath1 === "" || imagevalues[index].ImagesList[childindex].UploadedImagePath2 === '') {
            isvalid = false;
            Nodify('Warning!', 'warning', 'Please Upload the Image');
        }

        if (isvalid) {
            imagevalues[index].ImagesList.push({ UploadedImagePath1: "", UploadedImagePath2: "", MainIndex: index })
        }
        setWorkmanshipValue(imagevalues);
        WorkmanshipCallback(imagevalues)
    }

    function handleRemoveFields(index, childindex, length) {

        var imagevalues = [...getWorkmanshipValue]
        if (length === 1) {
            //imagevalues[index].ImagesList[childindex].UploadedImagePath1 = "";
            imagevalues[index].ImagesList[childindex].UploadedImagePath2 = "";
        } else {
            imagevalues[index].ImagesList.splice(childindex, 1);
        }
        setWorkmanshipValue(imagevalues);
        WorkmanshipCallback(imagevalues)
    }
    return (
        <table className="table table-striped table-bordered table-hover dataTable no-footer">
            <thead>
                <tr>
                    <th className="InsMainInfoDetailsCol">Details</th>
                    <th className="InsMainInfoResultsCol">Workmanship Result<span className="text-danger"> *</span></th>
                    <th className="InsMainInfoRemarksCol">Finding / Remarks<span className="text-danger"> *</span></th>
                </tr>
            </thead>
            <tbody>
                {
                    getWorkmanshipValue.map((comment, index) => (
                        <Fragment>
                            <tr key={
                                comment.index
                            }>
                                <td title={comment.workmanshipName} className="cut_text">
                                    {<i
                                        className={
                                            workmanshipExpandState[index] ?
                                                'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                        } onClick={event => handleWorkmanshipExpandRow(index)}
                                    ></i>}&nbsp;
                                    <b>{comment.workmanshipName}</b></td>
                                <td>   <Reactselect className="basic-single" name="Result"
                                    id={
                                        "Result" + index
                                    }
                                    isDisabled={props.location.state.params.Action === 'View' ? true : false}
                                    isLoading={false}
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={(event) => handleCommonResultInputChange(index, event, 'WorkmanshipResult')}
                                    options={getCommonResult}
                                    styles={submitted && comment.WorkmanResultID === 0 ? styles : ''}
                                    value={getCommonResult.filter(function (option) {
                                        return option.value === comment.WorkmanResultID;
                                    })}

                                ></Reactselect></td>
                                <td><input type="text" className="form-control" name={index + "_txtRemarks"}
                                    id={index + "_txtRemarks"}
                                    maxLength="200"
                                    disabled={props.location.state.params.Action === 'View' ? true : false}
                                    style={{ border: submitted && (comment.WorkmanResultID === 2 && comment.WorkmanFindingsRemarks === '') ? '1px solid red' : '' }}
                                    value={comment.WorkmanFindingsRemarks} autoComplete="off"
                                    onChange={(event) => handleCommonResultInputChange(index, event, 'FindingRemarks')} /></td>
                            </tr><>
                                {
                                    workmanshipexpandedRows.includes(index) ?
                                        <tr>
                                            <td colspan="3">
                                                <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                    <tbody>
                                                        {
                                                            comment.ImagesList.map((child, childindex) => (
                                                                <Fragment>
                                                                    <tr>
                                                                        <td className="table-width-40">
                                                                            <div className="row">
                                                                                {/* <div className="col-md-6">
                                                                                    {<span>Expected:{child.expected}</span>}
                                                                                </div> */}
                                                                                <div className="row">
                                                                                    <div className="text-center">
                                                                                        <span className="text-danger">Click on the image to upload new</span><br /><br />
                                                                                        <div style={{ width: "50%", marginLeft: "20rem" }}>
                                                                                            <FinalinspecUpload UploadCallback={(e) => ImageCallback(e, index, childindex, 'Image1')} PageName='Style/SKU' ShowDiv="1" filePath={child.UploadedImagePath1} />
                                                                                        </div>
                                                                                        {child.UploadedImagePath1 !== '' ? <div className="row" style={{ position: "relative", left: "114px", bottom: "155px" }}>
                                                                                            <div className="col-md-7">
                                                                                                <i className="btn btn-danger fa fa-trash-o" title="Delete Logo" style={{ float: "right", padding: '6px 7px 6px 8px' }}
                                                                                                    onClick={event => deleteFile(index, childindex, "UploadedImagePath1")}></i>
                                                                                                <i className="btn btn-info fa fa-eye" title="View Logo" style={{ marginRight: "6px", float: "right", padding: '6px 6px' }}
                                                                                                    onClick={
                                                                                                        event => ViewFile(event, child.UploadedImagePath1, index)
                                                                                                    }></i>
                                                                                            </div>
                                                                                        </div> : ''}
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        <td className="table-width-60">

                                                                            <div className="row">
                                                                                <div className="text-center col-lg-6">
                                                                                    <span className="text-danger">Click on the image to upload new</span><br /><br />
                                                                                    <div >
                                                                                        <FinalinspecUpload UploadCallback={(e) => ImageCallback(e, index, childindex, 'Image2')} PageName='Style/SKU' ShowDiv="1" filePath={child.UploadedImagePath2} />
                                                                                    </div>
                                                                                    {child.UploadedImagePath2 !== '' ? <div className="row">
                                                                                        <div className="col-md-7">
                                                                                            {/* <i className="btn btn-danger fa fa-trash-o" title="Delete Logo" style={{ float: "right" }}
                                                                                                onClick={event => deleteFile2(index, childindex, "UploadedImagePath2")}></i>&nbsp; */}

                                                                                        </div>
                                                                                    </div> : ''}
                                                                                </div>

                                                                            </div>
                                                                            <span className="TnaFinaspecwrkmans" style={{ left: "335px" }}>
                                                                                {child.UploadedImagePath2 !== '' ? <button className="btn btn-info fa fa-eye" style={{ padding: "5px 6px", width: "3.5%" }} onClick={event => ViewFile(event, child.UploadedImagePath2, index, childindex)}>
                                                                                    <i title="View Logo" style={{ marginRight: "8px" }}
                                                                                    ></i>
                                                                                </button> : ''}
                                                                                &nbsp;

                                                                                {props.location.state.params.Action !== 'View' ? <button type="button" style={{ display: getWorkmanshipValue[index].ImagesList.length < 2 ? '' : 'none' }} className="btn btn-xs btn-success" title="Add packing Image"
                                                                                    onClick={() => handleAddFields(index, childindex)}
                                                                                >
                                                                                    <i className="fa fa-plus"></i>
                                                                                </button> : ''}
                                                                                &nbsp;
                                                                                <button type="button"
                                                                                    className="btn btn-xs btn-danger" title="Delete packing Image" style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}
                                                                                    onClick={() => handleRemoveFields(index, childindex, getWorkmanshipValue[index].ImagesList.length)}
                                                                                >
                                                                                    <i className="fa fa-trash-o"></i>
                                                                                </button>
                                                                            </span>

                                                                        </td>

                                                                    </tr>
                                                                </Fragment>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr> : ""
                                }</>
                            <div> {
                                isOpen && (

                                    <Lightbox mainSrc={window.$APIBaseURL + 'Images' + getImagename}
                                        alt="bg image"
                                        onCloseRequest={
                                            () => setIsOpen(false)
                                        } />
                                )
                            } </div>
                        </Fragment>
                    ))
                }
                {
                    getWorkmanshipValue.length === 0 ?
                        <tr>
                            <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
                        </tr> : ''
                }
            </tbody>
        </table>
    )
}

export default FinalWorkmanshiptemp;