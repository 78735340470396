import React, { Fragment, useState, useMemo, useEffect } from "react";
import Reactselect from 'react-select';
import FinalinspectionService from "../../../services/FinalInspection/FinalinspectionService";
import FinalinspecUpload from "../../Common/FinalinspecUpload";
import Lightbox from "react-image-lightbox"
import Nodify from "../../Common/ReactNotification";

const Csr = ({ props, BasicDetails, CSRCallback, IsValidationCallback }) => {

  const [getCSRValue, setCSRValue] = useState([]);
  const [CSRExpandState, setCSRExpandState] = useState({});
  const [CSRexpandedRows, setCSRExpandedRows] = useState([]);
  const [getCommonResult, setCommonResult] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [getImagename, setImagename] = useState();
  const [submitted, setSubmitted] = useState(false);
  useEffect(() => {
    setSubmitted(IsValidationCallback);
  }, [IsValidationCallback === true]);
  const inpsectionId = JSON.parse(localStorage.getItem("InspectionID"));
  useEffect(() => {

    if (props.location.state.params.ParentTask === "ProductionFLDetails") {
      BasicDetails.styleID = props.location.state.params.NewDetails.styleID
      BasicDetails.buyerID = props.location.state.params.NewDetails.buyerID
      BasicDetails.brandID = props.location.state.params.NewDetails.brandID
    }
    if (props.location.state.params.TaskStatus === 1) {
      let CSREdit = [];

      if (inpsectionId !== null) {
        FinalinspectionService.GetTNAFinalinspecMainInfo(inpsectionId, props.location.state.params.details.tnaid).then((res) => {

          if (res.data.finalInsCSRList.length > 0) {

            res.data.finalInsCSRList.map((y, index) => {
              CSREdit.push({
                TNAFinalInspecCSRID: y.tnaFinalInspecCSRID,
                ResultID: y.resultID,
                ResultName: y.resultName,
                InputType: y.inputType,
                FindingsRemarks: y.findingsRemarks,
                ItemName: y.csrDetails,
                CSRMainindex: y.testReportMainindex,
                ImagesList: []
              })
              res.data.finalInsCSRSubList.filter(x => x.csrMainindex === y.csrMainindex).map((x, i) => {
                CSREdit[index].ImagesList.push({
                  TNAFinalInspecCSRSubID: x.tnaFinalInspecCSRSubID,
                  UploadedImagePath1: x.uploadedImagePath1,
                  UploadedImagePath2: x.uploadedImagePath2,
                  MainIndex: x.mainIndex
                })
              })
            })
            CSRCallback(CSREdit)
            setCSRValue(CSREdit)
          } else {
            let CSRSaveData = [];
            FinalinspectionService.GetTNAFinalinspecTestingReportList(2, BasicDetails.buyerID, BasicDetails.brandID).then((res) => {

              if (res.data) {
                res.data.map((x, index) => {
                  CSRSaveData.push({
                    InputType: x.inputType,
                    TNAFinalInspecCSRID: 0,
                    ItemName: x.itemName,
                    ResultID: 0,
                    ResultName: '',
                    FindingsRemarks: '',
                    CSRMainindex: index,
                    ImagesList: []
                  })
                  CSRSaveData[index].ImagesList.push({
                    TNAFinalInspecCSRSubID: 0,
                    UploadedImagePath1: "",
                    UploadedImagePath2: "",
                    MainIndex: index
                  })
                })

              }
              setCSRValue(CSRSaveData)
              CSRCallback(CSRSaveData)
            })
          }

        })
      } else {
        let CSRSaveData = [];
        FinalinspectionService.GetTNAFinalinspecTestingReportList(2, BasicDetails.buyerID, BasicDetails.brandID).then((res) => {

          if (res.data) {
            res.data.map((x, index) => {
              CSRSaveData.push({
                InputType: x.inputType,
                TNAFinalInspecCSRID: 0,
                ItemName: x.itemName,
                ResultID: 0,
                ResultName: '',
                FindingsRemarks: '',
                CSRMainindex: index,
                ImagesList: []
              })
              CSRSaveData[index].ImagesList.push({
                TNAFinalInspecCSRSubID: 0,
                UploadedImagePath1: "",
                UploadedImagePath2: "",
                MainIndex: index
              })
            })

          }
          setCSRValue(CSRSaveData)
          CSRCallback(CSRSaveData)
        })
      }
    }
    // let CommonResult = [];
    // FinalinspectionService.getCommonResultList(0).then((res) => {

    //   if (res.data) {
    //     res.data.map((x) => {
    //       CommonResult.push({
    //         value: x.resultID,
    //         label: x.resultName
    //       })
    //     })
    //     setCommonResult(CommonResult);
    //   }
    // })
    let CommonResult = [];
    FinalinspectionService.getCSRList(0).then((res) => {
      // if (res.data) {
      //   res.data.map((element) => {
      //     CommonResult.push({
      //       value: element.itemID,
      //       label: element.itemName
      //     })
      //   })
      //   setCommonResult(CommonResult);
      // }
      if (res.data) {
        res.data.map((element) => {

          let LabelColor = '';
          if (element.itemName === 'Yes') {
            LabelColor = <><span style={{ color: "green" }}><b>Yes</b></span></>
          }
          else if (element.itemName === 'No') {
            LabelColor = <><span style={{ color: "Red" }}><b>No</b></span></>
          }
          else if (element.itemName === 'Ok') {
            LabelColor = <><span style={{ color: "green" }}><b>Ok</b></span></>
          }
          else if (element.itemName === 'Not Ok') {
            LabelColor = <><span style={{ color: "Red" }}><b>Not Ok</b></span></>
          }
          else {
            LabelColor = <><span style={{ color: "black" }}><b>{element.itemName}</b></span></>
          }

          CommonResult.push({
            value: element.itemID,
            label: LabelColor,
            subLabel: element.itemName,
          })
        })
        setCommonResult(CommonResult);
      }
    })
  }, [inpsectionId !== null && props.location.state.params.TaskStatus === 1])

  // useEffect(() => {
  //   if (props.location.state.params.TaskStatus === 1) {
  //     let CSRSaveData = [];
  //     FinalinspectionService.GetTNAFinalinspecTestingReportList(2, BasicDetails.buyerID, BasicDetails.brandID).then((res) => {
  //       if (res.data) {
  //         res.data.map((x, index) => {
  //           CSRSaveData.push({
  //             TNAFinalInspecCSRID: 0,
  //             ItemName: x.itemName,
  //             ResultID: 0,
  //             FindingsRemarks: '',
  //             CSRMainindex: index,
  //             ImagesList: []
  //           })
  //           CSRSaveData[index].ImagesList.push({
  //             TNAFinalInspecCSRSubID: 0,
  //             UploadedImagePath1: "",
  //             UploadedImagePath2: "",
  //             MainIndex: index
  //           })
  //         })

  //       }
  //       setCSRValue(CSRSaveData)
  //       CSRCallback(CSRSaveData)
  //     })
  //   }
  // }, [inpsectionId !== null])

  useEffect(() => {
    if (props.location.state.params.ParentTask === "ProductionFLDetails") {
      BasicDetails.styleID = props.location.state.params.NewDetails.styleID
      BasicDetails.buyerID = props.location.state.params.NewDetails.buyerID
      BasicDetails.brandID = props.location.state.params.NewDetails.brandID
    }
    let CSREdit = [];
    if (props.location.state.params.TaskStatus === 2) {
      FinalinspectionService.GetTNAFinalinspecMainInfo(props.location.state.params.TNAInspectionID, props.location.state.params.details.tnaid).then((res) => {
        if (res.data.finalInsCSRList.length > 0) {
          res.data.finalInsCSRList.map((y, index) => {
            ;
            CSREdit.push({
              TNAFinalInspecCSRID: y.tnaFinalInspecCSRID,
              ResultID: y.resultID,
              ResultName: y.resultName,
              InputType: y.inputType,
              FindingsRemarks: y.findingsRemarks,
              ItemName: y.csrDetails,
              CSRMainindex: y.testReportMainindex,
              ImagesList: []
            })
            res.data.finalInsCSRSubList.filter(x => x.csrMainindex === y.csrMainindex).map((x, i) => {
              CSREdit[index].ImagesList.push({
                TNAFinalInspecCSRSubID: x.tnaFinalInspecCSRSubID,
                UploadedImagePath1: x.uploadedImagePath1,
                UploadedImagePath2: x.uploadedImagePath2,
                MainIndex: x.mainIndex
              })
            })
          })
          setCSRValue(CSREdit)
          CSRCallback(CSREdit)
        } else {
          let CSRSaveData = [];
          FinalinspectionService.GetTNAFinalinspecTestingReportList(2, BasicDetails.buyerID, BasicDetails.brandID).then((res) => {

            if (res.data) {
              res.data.map((x, index) => {
                CSRSaveData.push({
                  InputType: x.inputType,
                  TNAFinalInspecCSRID: 0,
                  ItemName: x.itemName,
                  ResultID: 0,
                  ResultName: '',
                  FindingsRemarks: '',
                  CSRMainindex: index,
                  ImagesList: []
                })
                CSRSaveData[index].ImagesList.push({
                  TNAFinalInspecCSRSubID: 0,
                  UploadedImagePath1: "",
                  UploadedImagePath2: "",
                  MainIndex: index
                })
              })

            }
            setCSRValue(CSRSaveData)
            CSRCallback(CSRSaveData)
          })
        }
      })
    }
    // let CommonResult = [];
    // FinalinspectionService.getCommonResultList(0).then((res) => {

    //   if (res.data) {
    //     res.data.map((x) => {
    //       CommonResult.push({
    //         value: x.resultID,
    //         label: x.resultName
    //       })
    //     })
    //     setCommonResult(CommonResult);
    //   }
    // })
    let CommonResult = [];
    FinalinspectionService.getCSRList(0).then((res) => {
     
      if (res.data) {
        res.data.map((element) => {

          let LabelColor = '';
          if (element.itemName === 'Yes') {
            LabelColor = <><span style={{ color: "green" }}><b>Yes</b></span></>
          }
          else if (element.itemName === 'No') {
            LabelColor = <><span style={{ color: "Red" }}><b>No</b></span></>
          }
          else if (element.itemName === 'Ok') {
            LabelColor = <><span style={{ color: "green" }}><b>Ok</b></span></>
          }
          else if (element.itemName === 'Not Ok') {
            LabelColor = <><span style={{ color: "Red" }}><b>Not Ok</b></span></>
          }
          else {
            LabelColor = <><span style={{ color: "black" }}><b>{element.itemName}</b></span></>
          }

          CommonResult.push({
            value: element.itemID,
            label: LabelColor,
            subLabel: element.itemName,
          })
        })
        setCommonResult(CommonResult);
      }
    })
  }, [props.location.state.params.TaskStatus === 2])
  let styles = {
    control: styles => ({ ...styles, border: '1px solid red', innerHeight: '100px', outerHeight: '100px' }),
  }
  const ImageCallback = (value, index, childindex, FeildName) => {

    var imagevalues = [...getCSRValue]
    let profile = imagevalues !== '' ? "/Style/SKU/" : '';
    if (FeildName === 'Image1') {
      imagevalues[index].ImagesList[childindex].MainIndex = index;
      imagevalues[index].ImagesList[childindex].UploadedImagePath1 = profile + value;
    } else {
      imagevalues[index].ImagesList[childindex].MainIndex = index;
      imagevalues[index].ImagesList[childindex].UploadedImagePath2 = profile + value;
    }
    setCSRValue(imagevalues)
  }

  const deleteFile = (index, childindex) => {
    var imagevalues = [...getCSRValue]
    imagevalues[index].ImagesList[childindex].UploadedImagePath1 = "";
    setCSRValue(imagevalues);
    CSRCallback(imagevalues)
  }
  const deleteFile2 = (index, childindex) => {
    var imagevalues = [...getCSRValue]
    imagevalues[index].ImagesList[childindex].UploadedImagePath2 = "";
    setCSRValue(imagevalues);
    CSRCallback(imagevalues)
  }

  function ViewFile(event, filename, index) {

    // event.preventDefault();
    setIsOpen(true);
    setImagename(filename);
  }

  const handleCommonResultInputChange = (index, e, FeildName) => {
   
    const values = [...getCSRValue];
    let inputText = ""
    if (FeildName === "CSR") {
      if (e !== null) {
        values[index].ResultID = e.value;
        values[index].ResultName = e.subLabel;
        values[index].CSRMainindex = index;
      } else {
        values[index].ResultID = 0;
        values[index].ResultName = '';
      }
    }

    else if (FeildName === "CSRName") {
      if (e !== null) {

        values[index].ResultName = e.target.value;
        values[index].CSRMainindex = index;
      } else {

        values[index].ResultName = '';
      }
    }

    else {
      if (e.target.value.trim() !== '') {
        inputText = e.target.value;
      }
      values[index].FindingsRemarks = inputText;
    }
    setCSRValue(values);
    CSRCallback(values)
  };
  const handleCSRExpandRow = (rowNumber) => {
    setCSRExpandedRows([]);
    let currentExpandedRows;
    let isRowExpanded = false;
    currentExpandedRows = CSRexpandedRows;
    isRowExpanded = currentExpandedRows.includes(rowNumber);
    let obj = {};
    isRowExpanded ? (obj[rowNumber] = false) : (obj[rowNumber] = true);
    setCSRExpandState(obj);
    currentExpandedRows = [];
    const newExpandedRows = isRowExpanded ?
      currentExpandedRows.filter(id => id !== rowNumber) :
      currentExpandedRows.concat(rowNumber);
    setCSRExpandedRows(newExpandedRows);
  }

  function handleAddFields(index, childindex) {
    var imagevalues = [...getCSRValue]
    let isvalid = true;
    if (imagevalues[index].ImagesList[childindex].UploadedImagePath1 === "" || imagevalues[index].ImagesList[childindex].UploadedImagePath2 === '') {
      isvalid = false;
      Nodify('Warning!', 'warning', 'Please Upload the Image');
    }

    if (isvalid) {
      imagevalues[index].ImagesList.push({ UploadedImagePath1: "", UploadedImagePath2: "", MainIndex: index })
    }
    setCSRValue(imagevalues);
    CSRCallback(imagevalues)
  }
  function handleRemoveFields(index, childindex, length) {

    var imagevalues = [...getCSRValue]
    if (length === 1) {
      //imagevalues[index].ImagesList[childindex].UploadedImagePath1 = "";
      imagevalues[index].ImagesList[childindex].UploadedImagePath2 = '';
    } else {
      imagevalues[index].ImagesList.splice(childindex, 1);
    }
    setCSRValue(imagevalues);
    CSRCallback(imagevalues)
  }


  return (
    <table className="table table-striped table-bordered table-hover dataTable no-footer">
      <thead>
        <tr>
          <th className="InsMainInfoDetailsCol">Details</th>
          <th className="InsMainInfoResultsCol">CSR Result<span className="text-danger"> *</span></th>
          <th className="InsMainInfoRemarksCol">Finding / Remarks<span className="text-danger"> *</span></th>
        </tr>
      </thead>
      <tbody>
        {
          getCSRValue.map((comment, index) => (
            <Fragment>
              <tr key={
                comment.index
              }>
                <td title={comment.ItemName} className="cut_text">
                  {<i
                    className={
                      CSRExpandState[index] ?
                        'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                    } onClick={event => handleCSRExpandRow(index)}
                  ></i>}&nbsp;
                  <b>{comment.ItemName}</b></td>
                <td>
                  {
                    comment.InputType === 1 ?

                      <Reactselect className="basic-single" name="Result"
                        id={
                          "Result" + index
                        }

                        isDisabled={props.location.state.params.Action === 'View' ? true : false}
                        isLoading={false}
                        isClearable={true}
                        isSearchable={true}
                        onChange={(event) => handleCommonResultInputChange(index, event, 'CSR')}
                        options={getCommonResult}
                        styles={submitted && comment.ResultID === 0 ? styles : ''}
                        value={getCommonResult.filter(function (option) {
                          return option.value === comment.ResultID;
                        })}

                      ></Reactselect>

                      : <input type="text" className="form-control" name={index + "_txtRemarks"}
                        id={index + "_txtRemarks"}
                        maxLength="200"
                        disabled={props.location.state.params.Action === 'View' ? true : false}
                        value={comment.ResultName} autoComplete="off"
                        style={{ border: submitted && (comment.ResultName === '') ? '1px solid red' : '' }}
                        onChange={(event) => handleCommonResultInputChange(index, event, 'CSRName')} />
                  }


                </td>
                <td><input type="text" className="form-control" name={index + "_txtRemarks"}
                  id={index + "_txtRemarks"}
                  maxLength="200"
                  value={comment.FindingsRemarks} autoComplete="off"
                  style={{ border: submitted && (comment.ResultName === 'Not Ok' && comment.FindingsRemarks === '') ? '1px solid red' : '' }}
                  onChange={(event) => handleCommonResultInputChange(index, event, 'FindingRemarks')} /></td>
              </tr><>
                {
                  CSRexpandedRows.includes(index) ?
                    <tr>
                      <td colspan="3">
                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                          <tbody>
                            {
                              comment.ImagesList.map((child, childindex) => (
                                <Fragment>
                                  <tr>
                                    <td className="table-width-40">
                                      <div className="row">
                                        {/* <div className="col-md-6">
                                                                                    {<span>Expected:{child.expected}</span>}
                                                                                </div> */}
                                        <div className="row">
                                          <div className="text-center">
                                            <span className="text-danger">Click on the image to upload new</span><br /><br />
                                            <div style={{ width: "50%", marginLeft: "20rem" }}>
                                              <FinalinspecUpload UploadCallback={(e) => ImageCallback(e, index, childindex, 'Image1')} PageName='Style/SKU' ShowDiv="1" filePath={child.UploadedImagePath1} />
                                            </div>
                                            {child.UploadedImagePath1 !== '' ? <div className="row" style={{ position: "relative", left: "114px", bottom: "155px" }}>
                                              <div className="col-md-7">
                                                <i className="btn btn-danger fa fa-trash-o" title="Delete Logo" style={{ float: "right", padding: '6px 7px 6px 8px' }}
                                                  onClick={event => deleteFile(index, childindex, "UploadedImagePath1")}></i>
                                                <i className="btn btn-info fa fa-eye" title="View Logo" style={{ marginRight: "6px", float: "right", padding: '6px 6px' }}
                                                  onClick={
                                                    event => ViewFile(event, child.UploadedImagePath1, index)
                                                  }></i>
                                              </div>
                                            </div> : ''}
                                          </div>
                                        </div>
                                      </div>
                                    </td>

                                    <td className="table-width-60">

                                      <div className="row">
                                        <div className="text-center col-lg-6">
                                          <span className="text-danger">Click on the image to upload new</span><br /><br />
                                          <div >
                                            <FinalinspecUpload UploadCallback={(e) => ImageCallback(e, index, childindex, 'Image2')} PageName='Style/SKU' ShowDiv="1" filePath={child.UploadedImagePath2} />
                                          </div>
                                          {child.UploadedImagePath2 !== '' ? <div className="row">
                                            <div className="col-md-7">
                                              {/* <i className="btn btn-danger fa fa-trash-o" title="Delete Logo" style={{ float: "right" }}
                                                onClick={event => deleteFile2(index, childindex, "UploadedImagePath1")}></i> */}

                                            </div>
                                          </div> : ''}
                                        </div>
                                      </div>
                                      <span className="TnaFinaspecwrkmans" style={{ left: "335px" }}>
                                        {child.UploadedImagePath2 !== '' ? <button className="btn btn-info fa fa-eye" style={{ padding: "5px 6px", width: "3.5%" }} onClick={
                                          event => ViewFile(event, child.UploadedImagePath2, index)
                                        }>
                                          <i title="View Logo" style={{ marginRight: "6px", float: "right" }}
                                          ></i>
                                        </button> : ''}
                                        &nbsp;
                                        {props.location.state.params.Action !== 'View' ? <button type="button" style={{ display: getCSRValue[index].ImagesList.length < 2 ? '' : 'none' }} className="btn btn-xs btn-success" title="Add packing Image"
                                          onClick={() => handleAddFields(index, childindex)}
                                        >
                                          <i className="fa fa-plus"></i>
                                        </button> : ''}
                                        &nbsp;
                                        <button type="button"
                                          className="btn btn-xs btn-danger" title="Delete packing Image"
                                          onClick={() => handleRemoveFields(index, childindex, getCSRValue[index].ImagesList.length)}
                                          style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}
                                        >
                                          <i className="fa fa-trash-o"></i>
                                        </button>
                                      </span>
                                    </td>
                                  </tr>
                                </Fragment>
                              ))
                            }
                          </tbody>
                        </table>
                      </td>
                    </tr> : ""
                }</>
              <div> {
                isOpen && (

                  <Lightbox mainSrc={window.$APIBaseURL + 'Images' + getImagename}
                    alt="bg image"
                    onCloseRequest={
                      () => setIsOpen(false)
                    } />
                )
              } </div>
            </Fragment>
          ))
        }
        {
          getCSRValue.length === 0 ?
            <tr>
              <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
            </tr> : ''
        }
      </tbody>
    </table>
  )
}

export default Csr