import {
    LOAD_COURIER_LIST,
    COURIER_LOADING
} from "../actions/types";

const initialState = {
    CourierList: [],
    isLoadingCourier: true,
    AgencyList: []
};

const courierReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case COURIER_LOADING:
            return {
                ...state,
                isLoadingCourier: payload
            };
        case LOAD_COURIER_LIST:
            return {
                ...state,
                CourierList: payload.item1,
                AgencyList: payload.item2
            };
        default:
            return state;
    }
}
export default courierReducer;