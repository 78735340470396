import {
    MEASUREMENTPOINT_LOADING,
    RETRIEVE_MEASUREMENTPOINT,
    DELETE_MEASUREMENTPOINT,
} from "../actions/types";

const initialState = {
    isLoadingMeasurementpoint: true,
    MeasurementpointList: [],
};

const MeasurementpointReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case MEASUREMENTPOINT_LOADING:
            return {
                ...state,
                isLoadingMeasurementpoint: payload
            };
        case RETRIEVE_MEASUREMENTPOINT:
            return { ...state, MeasurementpointList: payload };
        case DELETE_MEASUREMENTPOINT:
            const results = state.MeasurementpointList.filter(c => c.measurePointID !== payload.measurePointID);
            return {
                ...state,
                MeasurementpointList: results
            };

        default:
            return state;
    }
};
export default MeasurementpointReducer;