import {
    COLORDEPTH_LOADING,
    RETRIEVE_COLORDEPTH,
    DELETE_COLORDEPTH,
} from "../actions/types";

const initialState = {
    isLoadingColorDepth: true,
    ColorDepthList: [],
};

const ColorDepthReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case COLORDEPTH_LOADING:
            return {
                ...state,
                isLoadingColorDepth: payload
            };
        case RETRIEVE_COLORDEPTH:
            return { ...state, ColorDepthList: payload };
        case DELETE_COLORDEPTH:
           ;
            const results = state.ColorDepthList.filter(c => c.id !== payload.QCCommentPoint);
            return {
                ...state,
                ColorDepthList: results
            };

        default:
            return state;
    }
};
export default ColorDepthReducer;