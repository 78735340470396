import {
    TESTNAME_LOADING,
    RETRIEVE_TESTNAME,
    DELETE_TESTNAME,MAPPING_LOADING,RETRIEVE_MAPPING,DELETE_MAPPING
} from "../actions/types";

const initialState = {
    isLoadingTestName: true,
    isLoadingMapping:true,
    TestNameList: [],
    MappingList:[],
    MappingEditList:[]
};

const TestNameReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case TESTNAME_LOADING:
            return {
                ...state,
                isLoadingTestName: payload
            };
        case RETRIEVE_TESTNAME:
            return { ...state, TestNameList: payload };
        case DELETE_TESTNAME:
            const results = state.TestNameList.filter(c => c.id !== payload.QCCommentPoint);
            return {
                ...state,
                TestNameList: results
            };

        case MAPPING_LOADING:
            return {
                ...state,
                isLoadingMapping: payload
            };

        case RETRIEVE_MAPPING:
            return { ...state, MappingList: payload };

        case DELETE_TESTNAME:
            const resultss = state.MappingList.filter(c => c.id !== payload.QCCommentPoint);
            return {
                ...state,
                MappingList: resultss
            };
        default:
            return state;
    }








};
export default TestNameReducer;