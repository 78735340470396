import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'react-notifications-component/dist/theme.css';
import { TableHeader } from "../../Datatable";
import SweetAlertPopup from '../../Common/SweetAlertPopup';
import Lightbox from "react-image-lightbox"
import { SkuID } from "../../Common/PageActionNumber";
import SKUService from "../../../services/Style/SKUService";
import CommonService from "../../../services/Common/CommonService";
import StyleService from "../../../services/Style/styleService";
import { Modal, Button } from "react-bootstrap";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { loadSKUListByStyleID } from "../../../actions/style";

const SKUList = ({
    props,
    skuredirect,
    ValidationCallBack,
    StyleInfo,
    SkuInfo
}) => {
    useEffect(()=>{
        let Page = localStorage.getItem("Listpage")
        if(Page){
            setListTile(Page) 
        }
    },[])

    
debugger;
    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item5);
    if (menulist !== undefined) { activeMenu = menulist.filter(i => i.menuName === "StyleSKU"); }

    // End Enable Menu
    // const [inputFields, setInputFields] = useState([]);

    const [getID, setID] = useState(0);
    const [isOpen, setIsOpen] = useState(false)
    let StyleID = 0;
    try {
        StyleID = StyleInfo && StyleInfo.styleID;
    }
    catch {

        StyleID = StyleInfo && StyleInfo.StyleID;
    }

    const [ListTile, setListTile] = useState("Tile");
    const [getSubImageList, setSubImageList] = useState([]);
    const [getisOpenSlider, setisOpenSlider] = useState(false);
    const [getCurrimageindex, setCurrimageindex] = useState(0);
    const [getSkuname, setSkuname] = useState();


    const dispatch = useDispatch();
    //reducer
    const reducerState = useSelector((state) => state);
    //const inputFields = reducerState.style.SKUList;
    const inputFields = reducerState.style.SKUList.item1 !== undefined ? reducerState.style.SKUList.item1 : reducerState.style.SKUList;
    let ImageArray = [];


    // if (inputFields.length > 0) {
    //     if (!inputFields[0].ImageArray) {
    //         const skuImg = reducerState.style.SKUList.item2;
    //         for (let index = 0; index < inputFields.length; index++) {
    //             ImageArray = skuImg.filter(d => d.skuID === inputFields[index].skuID);
    //             inputFields[index].ImageArray = ImageArray;
    //         }
    //     }

    // }

    if (inputFields.length > 0) {
        if (!inputFields[0].ImageArray) {
            const skuImg = reducerState.style.SKUList.item2;
            for (let index = 0; index < inputFields.length; index++) {
                ImageArray = skuImg.filter(d => d.skuID === inputFields[index].skuID);
                let imgsa = ImageArray.filter(d => d.skuID !== 0 || d.skuID !== null)

                let count = 0;
                let Total = 0;
                let Index = 0;
                for (let Imgval = 0; Imgval < imgsa.length; Imgval++) {

                    if (imgsa[Imgval].front === 1) {
                        Total = imgsa.filter(d => d.skuID === imgsa[Imgval].skuID).length;
                        count = 1;
                        Index = count;
                        ImageArray[Imgval].IndexTotal = '[' + Index + ' - ' + Total + ']';
                    }
                    else {
                        // ImageArray[Imgval].Index = count
                        Index = count;
                        ImageArray[Imgval].IndexTotal = Index === 0 ? '' : '[' + Index + ' - ' + Total + ']';
                    }
                    count = count + 1;
                }

                inputFields[index].ImageArray = ImageArray;

            }
        }
    }



    const a = { backgroundColor: "black" }


    // if (!SkuInfo)
    // {
    //     SkuInfo.isEdit = 1;
    //     SkuInfo.isDelete = 1;

    //     }
    const headers = [
        { name: "#", field: "", sortable: false, widthsize: "1%" },
        { name: "SKU Name", field: "", sortable: false, widthsize: "10%" },
        { name: "Front Pic", field: "", sortable: false, widthsize: "10%" },
        { name: "Back Pic", field: "", sortable: false, widthsize: "10%" },
        { name: "Additional Pic", field: "", sortable: false, widthsize: "10%" },
        { name: "Action", field: "", sortable: false, widthsize: "10%", display: SkuInfo.isEdit === 0 && SkuInfo.isDelete === 0 ? 'none' : '' },
    ];


    // function LoadSKUFields(SKULists) {
    //     let SKU = [];
    //     SKULists.forEach((getSKU, i) => {
    //         SKU.push({
    //             SKUName: getSKU.skuName === undefined ? getSKU.SKUName : getSKU.skuName, SKUID: getSKU.skuID === undefined ? getSKU.SKUID : getSKU.skuID,
    //             IsDeleted: 0,
    //             SKUFrontPicName: getSKU.skuFrontPicName === undefined ? getSKU.SKUFrontPicName : getSKU.skuFrontPicName, SKUFrontPicPath: getSKU.skuFrontPicPath === undefined ? getSKU.SKUFrontPicPath : getSKU.skuFrontPicPath,
    //             SKUBackPicName: getSKU.skuBackPicName === undefined ? getSKU.SKUBackPicName : getSKU.skuBackPicName, SKUBackPicPath: getSKU.skuBackPicPath === undefined ? getSKU.SKUBackPicPath : getSKU.skuBackPicPath,
    //             AdditionalPicName1: getSKU.additionalPicName1 === undefined ? getSKU.AdditionalPicName1 : getSKU.additionalPicName1, additionalPicPath1: getSKU.additionalPicPath1 === undefined ? getSKU.additionalPicPath1 : getSKU.additionalPicPath1,
    //             AdditionalPicName2: getSKU.additionalPicName2 === undefined ? getSKU.AdditionalPicName2 : getSKU.additionalPicName2, additionalPicPath2: getSKU.additionalPicPath2 === undefined ? getSKU.additionalPicPath2 : getSKU.additionalPicPath2,
    //             AdditionalPicName3: getSKU.additionalPicName3 === undefined ? getSKU.AdditionalPicName3 : getSKU.additionalPicName3, additionalPicPath3: getSKU.additionalPicPath3 === undefined ? getSKU.additionalPicPath3 : getSKU.additionalPicPath3,
    //             AdditionalPicName4: getSKU.additionalPicName4 === undefined ? getSKU.AdditionalPicName4 : getSKU.additionalPicName4, additionalPicPath4: getSKU.additionalPicPath4 === undefined ? getSKU.additionalPicPath4 : getSKU.additionalPicPath4,
    //             PickStylePic: getSKU.pickStylePic === undefined ? getSKU.PickStylePic : getSKU.pickStylePic,
    //         });
    //     })
    //     return SKU;
    // }

    // useEffect(() => {
    //     if (SKUList.length > 0) {
    //         setInputFields([]);
    //         let SKU = LoadSKUFields(SKUList);
    //         setInputFields(SKU);
    //     }
    // }, [SKUList]);

    const pageRedirect = (Params, redirect, page) => {
        if (redirect === 'Edit') {
            let value = { data: Params, Page: 'ADDSKU' }
            skuredirect(value);
        }
        else {
            let Param = {
                Operation: 19,
                Id: Params.skuID,
            };
            CommonService.CheckMappedItem(Param).then(res => {
                if (res.data.outputResult === "-2") {
                    let message = "This SKU is already mapped (Program/StrikeOff/Trim).";
                    ValidationCallBack(message);
                }
                else {
                   ;
                    //dispatch(loadSKUListByStyleID(StyleID));
                    setID({ showPopupDelete: true, Params: Params });
                }
            });
        }
    };

    const ListType = () => {
        setListTile("Tile");
        localStorage.setItem('Listpage','Tile')
    }

    const TileListType = () => {
        setListTile("List");
        localStorage.setItem('Listpage','List')
    }

    const Delcallback = (value) => {
        //  if (value === true) {
           ;
        setID({ showPopupDelete: false, Params: [] });
        dispatch(loadSKUListByStyleID(StyleID));
        
        // SKUService.LoadSkuNameList(StyleID).then((response) => {
        //     if (response.data) {
        //         setSkuname(response.data);
        //     }
        // }).catch(() => { });
        

        // SKUService.LoadSkuNameList(StyleID).then((response) => {
        //     if (response.data) {
        //         setSKUProgram(response.data);
        //     }
        // }).catch(() => { hideLoader(); });

        //     SKUService.LoadSKUList(StyleID).then((response) => {
        //         if (response.data) {
        //             setInputFields([]);
        //             let SKU = LoadSKUFields(response.data);
        //             SKUList = SKU;
        //             setInputFields(SKU);
        //             //SKUCallback(SKU);
        //         }
        //     }).catch(() => { });

        // } else if (value === false) {
        //     setID({ showPopupDelete: false, Params: [] });
        // }
    }

    const OpenSlider = (SkuID, Skuname, skuimage) => {
        StyleService.LoadSubImgList(SkuID).then((response2) => {
            if (response2.data) {
                setSkuname(Skuname);
                var data = response2.data;
                data.forEach(element => {
                    element.url = window.$APIBaseURL + "Images" + element.url
                    element.url = element.url.replaceAll(" ", "%20")
                });
                data.splice(0, 0, { url: skuimage });
                setSubImageList(data);
                setisOpenSlider(true);
            }
        }).catch(() => { });

    }



    const ClosePopup = () => {
        setSubImageList([]);
        setisOpenSlider(false);
    }

    const previmage = () => {
        if (getCurrimageindex === 0) {
            setCurrimageindex(0);
        }
        else {
            setCurrimageindex(getCurrimageindex - 1);
        }
    }

    const nextimage = () => {
        if (getCurrimageindex === getSubImageList.length - 1) {
            setCurrimageindex(getSubImageList.length - 1);
        }
        else {
            setCurrimageindex(getCurrimageindex + 1);
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-xs-12 col-md-12">
                    <div className="widget">
                        <div>
                            {
                                isOpen && (
                                    <Lightbox mainSrc={isOpen} alt="bg image" onCloseRequest={() => setIsOpen(false)} />
                                )
                            }
                        </div>

                        <div className="row">
                            <div className="col-xs-12 col-md-12">
                                {
                                    ListTile === "List" &&
                                    <div className="widget-body">
                                        <div className="dataTables_wrapper form-inline no-footer">
                                            <div className="row">
                                                <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                    <TableHeader headers={headers} />
                                                    <tbody>
                                                        {
                                                            inputFields.map((comment,index) => (
                                                                <tr>
                                                                    <td>{index+1}</td>
                                                                    <td className="trwidth_manual">
                                                                        {comment.skuName}
                                                                    </td>
                                                                    <td>
                                                                        {/* {
                                                                            <img className="width_manual"
                                                                                src={comment.skuFrontPicPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.skuFrontPicPath}
                                                                                alt="Logo"
                                                                                // onClick={() => setIsOpen(comment.skuFrontPicPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.skuFrontPicPath)}
                                                                                //onClick={() => OpenSlider(comment.skuID, comment.skuName, comment.skuFrontPicPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.skuFrontPicPath)}
                                                                            />
                                                                        } */}
                                                                        < SimpleReactLightbox >
                                                                            <SRLWrapper >
                                                                                {
                                                                                    comment.ImageArray.map(imgInfo => (
                                                                                        < img className="width_manual" style={{ display: imgInfo.front === 1 ? '' : 'none' }}
                                                                                            src={imgInfo.imagePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + imgInfo.imagePath}
                                                                                            alt={imgInfo.skuName}
                                                                                        //alt={!imgInfo.skuID ? 'STYLE : ' + imgInfo.skuName + " " + imgInfo.IndexTotal : 'SKU : ' + imgInfo.skuName + " " + imgInfo.IndexTotal}
                                                                                        // onClick={() => setIsOpen(comment.styleLogoPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.styleLogoPath)}
                                                                                        // comment.styleID, comment.styleLogoPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.styleLogoPath)}
                                                                                        // onClick={() => OpenSlider(comment.styleID, comment.styleLogoPath, comment.imagePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.imagePath)}
                                                                                        />
                                                                                    ))
                                                                                }
                                                                            </SRLWrapper>
                                                                        </SimpleReactLightbox>
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            comment.skuBackPicPath === "" ?
                                                                                <h6 className="text-danger" style={{ textAlign: "center" }}>No Image Found</h6>
                                                                                :
                                                                                < SimpleReactLightbox >
                                                                                    <SRLWrapper >
                                                                                        <img className="width_manual"
                                                                                            src={comment.skuBackPicPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.skuBackPicPath}
                                                                                            alt="Logo"
                                                                                        // onClick={
                                                                                        //     () => setIsOpen(comment.skuBackPicPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.skuBackPicPath)
                                                                                        // }
                                                                                        />
                                                                                    </SRLWrapper>
                                                                                </SimpleReactLightbox>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            comment.additionalPicPath1 === "" && comment.additionalPicPath2 === "" && comment.additionalPicPath3 === "" && comment.additionalPicPath4 === "" ?
                                                                                <h6 className="text-danger" style={{ textAlign: "center" }}>No Image Found</h6>
                                                                                :
                                                                                <>
                                                                                    <div className="col-lg-12">
                                                                                        <div className="row">
                                                                                            <div className="col-lg-3" style={{ display: comment.additionalPicPath1 === "" ? 'none' : '' }}>
                                                                                                {
                                                                                                    comment.additionalPicPath1 === "" ?
                                                                                                        <span className="text-danger">No Image Found</span>
                                                                                                        :
                                                                                                        < SimpleReactLightbox >
                                                                                                            <SRLWrapper >

                                                                                                                <img className="width_manual"
                                                                                                                    src={comment.additionalPicPath1 === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.additionalPicPath1}
                                                                                                                    alt={comment.skuName}
                                                                                                                // onClick={
                                                                                                                //     () => setIsOpen(comment.additionalPicPath1 === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.additionalPicPath1)
                                                                                                                // }
                                                                                                                />
                                                                                                            </SRLWrapper>
                                                                                                        </SimpleReactLightbox>
                                                                                                }
                                                                                            </div>
                                                                                            <div className="col-lg-3" style={{ display: comment.additionalPicPath2 === "" ? 'none' : '' }}>
                                                                                                {
                                                                                                    comment.additionalPicPath2 === "" ?
                                                                                                        <span className="text-danger">No Image Found</span>
                                                                                                        :
                                                                                                        < SimpleReactLightbox >
                                                                                                            <SRLWrapper >
                                                                                                                <img className="width_manual"
                                                                                                                    src={comment.additionalPicPath2 === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.additionalPicPath2}
                                                                                                                    alt={comment.skuName}
                                                                                                                // onClick={
                                                                                                                //     () => setIsOpen(comment.additionalPicPath2 === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.additionalPicPath2)
                                                                                                                // }
                                                                                                                />
                                                                                                            </SRLWrapper>
                                                                                                        </SimpleReactLightbox>
                                                                                                }
                                                                                            </div>
                                                                                            <div className="col-lg-3" style={{ display: comment.additionalPicPath3 === "" ? 'none' : '' }}>
                                                                                                {
                                                                                                    comment.additionalPicPath3 === "" ?
                                                                                                        <span className="text-danger">No Image Found</span>
                                                                                                        :
                                                                                                        < SimpleReactLightbox >
                                                                                                            <SRLWrapper >
                                                                                                                <img className="width_manual"
                                                                                                                    src={comment.additionalPicPath3 === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.additionalPicPath3}
                                                                                                                    alt={comment.skuName}
                                                                                                                // onClick={
                                                                                                                //     () => setIsOpen(comment.additionalPicPath3 === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.additionalPicPath3)
                                                                                                                // }
                                                                                                                />
                                                                                                            </SRLWrapper>
                                                                                                        </SimpleReactLightbox>
                                                                                                }
                                                                                            </div>
                                                                                            <div className="col-lg-3" style={{ display: comment.additionalPicPath4 === "" ? 'none' : '' }}>
                                                                                                {
                                                                                                    comment.additionalPicPath4 === "" ?
                                                                                                        <span className="text-danger">No Image Found</span>
                                                                                                        :
                                                                                                        < SimpleReactLightbox >
                                                                                                            <SRLWrapper >
                                                                                                                <img className="width_manual"
                                                                                                                    src={comment.additionalPicPath4 === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.additionalPicPath4}
                                                                                                                    alt={comment.skuName}
                                                                                                                // onClick={
                                                                                                                //     () => setIsOpen(comment.additionalPicPath4 === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.additionalPicPath4)
                                                                                                                // }
                                                                                                                />
                                                                                                            </SRLWrapper>
                                                                                                        </SimpleReactLightbox>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                        }
                                                                    </td>
                                                                    <td style={{ display: SkuInfo && SkuInfo.isEdit === 0 && SkuInfo.isDelete === 0 ? 'none' : '' }}>
                                                                        {SkuInfo && SkuInfo.isEdit === 1 &&
                                                                            <span title='Edit SKU' style={{ display: activeMenu && activeMenu[0].isEdit === 1 ? '' : 'none' }} onClick={() => pageRedirect(comment, 'Edit')}
                                                                                className="btn btn-success btn-xs edit">
                                                                                <i className="fa fa-edit "></i>
                                                                            </span>
                                                                        }
                                                                        &nbsp;
                                                                        {SkuInfo && SkuInfo.isDelete === 1 && activeMenu && activeMenu[0].isDelete === 1 &&
                                                                            <span title='Delete SKU' style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }} onClick={() => pageRedirect(comment, '')}
                                                                                className="btn btn-danger btn-xs delete">
                                                                                <i className="fa fa-trash-o"></i>
                                                                            </span>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                        {
                                                            inputFields.length === 0 ?
                                                                <tr>
                                                                    <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
                                                                </tr> : ''
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {
                                    ListTile === "Tile" &&
                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                        <tbody>
                                            {
                                                inputFields.map(comment => (
                                                    <div className="col-sm-4 col-md-2" style={{ width: "20%" }}>
                                                        <div className="client_tumblineSKU1">
                                                            < SimpleReactLightbox >
                                                                <SRLWrapper >
                                                                    {
                                                                        comment.ImageArray.map(imgInfo => (
                                                                            <img className="width_manual1" style={{ display: imgInfo.front === 1 ? '' : 'none' }}
                                                                                src={imgInfo.imagePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + imgInfo.imagePath}
                                                                                //alt={imgInfo.skuName}
                                                                                alt={!imgInfo.skuID ? 'STYLE : ' + imgInfo.skuName + " " + imgInfo.IndexTotal : 'SKU : ' + imgInfo.skuName + " " + imgInfo.IndexTotal}
                                                                            // onClick={() => setIsOpen(comment.styleLogoPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.styleLogoPath)}
                                                                            // comment.styleID, comment.styleLogoPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.styleLogoPath)}
                                                                            // onClick={() => OpenSlider(comment.styleID, comment.styleLogoPath, comment.imagePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.imagePath)}
                                                                            />
                                                                        ))
                                                                    }
                                                                </SRLWrapper>
                                                            </SimpleReactLightbox>
                                                            {/* <img className="width_manual1"
                                                                src={comment.skuFrontPicPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.skuFrontPicPath}
                                                                alt="Logo"
                                                                // onClick={() => setIsOpen(comment.skuFrontPicPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.skuFrontPicPath)}
                                                                //onClick={() => OpenSlider(comment.skuID, comment.skuName, comment.skuFrontPicPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.skuFrontPicPath)}
                                                            /> */}

                                                            <table className="table_thum" style={{ width: '100%' }}>
                                                                <tr className="table_rwo">
                                                                    <td title={comment.skuName} className="bold_text width_20 sku_textoverflow" style={{ textAlign: 'center' }}>
                                                                        <span> {comment.skuName}</span>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                            <div className="btn-action-center">
                                                                {SkuInfo !== undefined && SkuInfo.isEdit === 1 &&

                                                                    <p title='Edit SKU' onClick={() => pageRedirect(comment, 'Edit')}
                                                                        className="btn btn-success btn-xs edit">
                                                                        <i className="fa fa-edit "></i>
                                                                    </p>
                                                                }
                                                                &nbsp;
                                                                {SkuInfo !== undefined && SkuInfo.isDelete === 1 &&
                                                                    <p title='Delete SKU' onClick={() => pageRedirect(comment, '')}
                                                                        className="btn btn-danger btn-xs delete">
                                                                        <i className="fa fa-trash-o"></i>
                                                                    </p>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            {
                                                inputFields.length === 0 ?
                                                    <h6 align="center" className='norecordfound'>No Records Found</h6>
                                                    : ''
                                            }
                                        </tbody>
                                    </table>
                                }

                                {
                                    getID.showPopupDelete ? <SweetAlertPopup data={getID.Params}
                                        deleteCallback={Delcallback}
                                        showpopup={true}
                                        pageActionId={SkuID}
                                        Msg={"SKU information deleted successfully"}
                                    /> : null
                                }
                            </div>

                            <div>
                                <div className="overight_style">
                                    {/* <span className="widget-caption">List</span> */}
                                    {
                                        ListTile === "List" &&
                                        <span title='Tile' onClick={ListType}>
                                            <i className="menu-icon fa fa-th font_size"></i>
                                        </span>
                                    }
                                    {
                                        ListTile === "Tile" &&
                                        <span title='List' onClick={TileListType}>
                                            <i className="menu-icon fa fa-list font_size"></i>
                                        </span>
                                    }
                                </div>
                            </div>

                            {
                                getisOpenSlider === true ?
                                    <Modal
                                        show={getisOpenSlider}
                                        size="md"
                                        dragable
                                        backdrop="static"
                                        keyboard={false}
                                        onHide={() => ClosePopup()}
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title>Image Slider</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <table>
                                                <tr>
                                                    {/* Gowtham */}
                                                    <td>SKU Name : <span style={{ fontWeight: "bold" }}> {getSkuname} </span></td>


                                                </tr>
                                                <tr>
                                                    <td style={{ align: "center", fontWeight: "bold" }}>Images</td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <tr>
                                                            <td style={{ marginLeft: "32px !important" }}> <i className="fa fa-chevron-circle-left fa-3x" onClick={() => previmage()} style={{ cursor: "pointer" }}></i></td>
                                                            <td>
                                                                {
                                                                    getSubImageList.length !== 0 ?

                                                                        <img src={getSubImageList[getCurrimageindex].url} alt="test" width="425px" height="425px" style={{ border: "3px solid black" }} />
                                                                        :
                                                                        <div align="center" style={{ width: "425px", height: "425px" }}>
                                                                            No Images Found
                                                                        </div>
                                                                }

                                                            </td>
                                                            <td>  <i className="fa fa-chevron-circle-right fa-3x" onClick={() => nextimage()} style={{ cursor: "pointer" }}></i></td>
                                                        </tr>



                                                    </td>

                                                </tr>
                                            </table>


                                        </Modal.Body>
                                        <Modal.Footer>

                                        </Modal.Footer>
                                    </Modal>

                                    : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SKUList;