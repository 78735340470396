import React, { useState, useEffect, useRef } from 'react';
import ReactNotification from 'react-notifications-component';
import Reactselect from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import agencyService from "../../../../services/Master/Agency.service";
import { LoadAgencyDetails } from './../../../../actions/agency';
import Nodify from "../../../Common/ReactNotification"


const EditAgency = (props) => {
    const dispatch = useDispatch();
    const AgencyDDL = useSelector((state) => state.agencyReducer.AgencyType);
    const AgencyGridList = useSelector((state) => state.agencyReducer.AgencyList);
    const [ExistingList, setExistingList] = useState([]);
    const [getSubmitted, setSubmitted] = useState(false);
    const [getAgencyDetails, setAgencyDetails] = useState({
        ID: 0,
        AgencyTypeID: 0,
        AgencyName: '',
        ModifiedBy: 0
    });
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),
    }
    const AgencyTypeRef = useRef(null);
    let AgencyArrayValues = { ...getAgencyDetails };

    useEffect(() => {
        dispatch(LoadAgencyDetails());
        if (props.location.state !== undefined) {
            if (props.location.state.params.length !== 0) {
                if (props.location.state.params.id !== "") {
                    let getAgency = SetAgency(props);
                    setAgencyDetails(getAgency);
                }
            }
        }
        else {
        }
    }, []);

    useEffect(() => {
        dispatch(LoadAgencyDetails());
    }, []);

    useEffect(() => {
        setExistingList(AgencyGridList);
    }, [AgencyGridList]);

    const CheckDuplicate = (index) => {
        if (ExistingList.length !== 0) {
            if (AgencyArrayValues.AgencyName !== '' && AgencyArrayValues.AgencyTypeID !== 0) {
                for (var i = 0; i < ExistingList.length; i++) {
                    if (ExistingList[i].agencyName.trim().toLowerCase() === AgencyArrayValues.AgencyName.trim().toLowerCase()
                        && ExistingList[i].agencyTypeID === AgencyArrayValues.AgencyTypeID
                        && ExistingList[i].id !== AgencyArrayValues.ID

                    ) {
                        let getAgency = SetAgency(props);
                        setAgencyDetails(getAgency);
                        Nodify('Warning!', 'warning', "Agency name already exist.");
                        return false;
                    }
                }
            }
        }
    }

    function SetAgency(props) {
        let AgencyValue = {
            ID: 0,
            AgencyTypeID: 0,
            AgencyName: '',
            ModifiedBy: 0
        };
        if (props.location.state !== undefined) {
            if (props.location.state.params.length !== 0) {
                if (props.location.state.params.id !== "") {
                    AgencyValue.ID = props.location.state.params.id;
                    AgencyValue.AgencyTypeID = props.location.state.params.agencyTypeID;
                    AgencyValue.AgencyName = props.location.state.params.agencyName;
                    return AgencyValue;
                }
            }
        }
    }

    const handleAgencyTypeChange = (agency) => {
        if (agency !== null) {
            AgencyArrayValues.AgencyTypeID = agency.id;
            setAgencyDetails(AgencyArrayValues);
        }
        else {
            AgencyArrayValues.AgencyTypeID = 0;
            setAgencyDetails(AgencyArrayValues);
        }
    }

    const handleAgencyNameChange = (agency) => {
        AgencyArrayValues.AgencyName = agency.target.value;
        setAgencyDetails(AgencyArrayValues);
    }

    const UpdateAgency = () => {
        setSubmitted(true);
        if (getAgencyDetails.AgencyName.trim() !== "" && getAgencyDetails.AgencyTypeID !== 0) {
            agencyService.UpdateAgencyDetails(getAgencyDetails).then(res => {
                setSubmitted(false);
                let Func = 'Edit';
                if (res.data.outputResult === "1") {
                    Func = 'Edit';
                    PageRedirect(Func);
                }
                else if (res.data.outputResult === "0") {
                    Nodify("Warning", "warning", "Agency name already exist.");
                }
            });
        }
        else {
            Nodify("Warning", "warning", "Please fill all mandatory(*) fields.");
        }
    }

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/AgencyList",
            state: {
                message: Func,
            },
        }
        );
    };

    const ResetOperation = (e) => {
        if (e === "Back") {
            PageRedirect("View");
        } else {
            window.location.reload();
        }
    }

    const FocusAgencyType = () => {
        AgencyTypeRef.current.focus();
    }

    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <div className="widget flat radius-bordered">
                            <div className="widget-header heading_box_style">
                                <h3 className="heading_style_common">Edit Agency</h3>
                            </div>
                            <div className="widget-body">
                                <div id="registration-form">
                                    <div className="row row_left10">
                                        <div className="col-sm-3 divder_style">
                                            <div className="form-group">
                                                <label htmlFor="txtAgencyName">Agency Name<span className="text-danger">*</span>
                                                </label>
                                                <span className="input-icon icon-right">
                                                    <input type="text"
                                                        className="form-control"
                                                        id="txtAgencyName"
                                                        name="agencyname"
                                                        placeholder="Enter Agency Name"
                                                        maxLength="50"
                                                        onBlur={(event) => CheckDuplicate(event)}
                                                        value={AgencyArrayValues.AgencyName}
                                                        onChange={event => handleAgencyNameChange(event)}
                                                        style={{ border: getSubmitted && AgencyArrayValues.AgencyName === '' ? '1px solid red' : '' }}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-sm-3 divder_style">
                                            <div className="form-group">
                                                <label htmlFor="AgencyType" onClick={FocusAgencyType}>Agency Type<span className="text-danger">*</span>
                                                </label>
                                                <span className="input-icon icon-right">
                                                    <Reactselect className="basic-single" name="AgencyType"
                                                        id="AgencyType"
                                                        isDisabled={false}
                                                        isLoading={false}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        options={AgencyDDL}
                                                        ref={AgencyTypeRef}
                                                        onChange={event => handleAgencyTypeChange(event)}
                                                        onBlur={(event) => CheckDuplicate(event)}
                                                        value={AgencyDDL.filter(function (option) {
                                                            return option.value === AgencyArrayValues.AgencyTypeID;
                                                        })}
                                                        styles={getSubmitted && AgencyArrayValues.AgencyTypeID === 0 ? styles : ''}
                                                    ></Reactselect>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="col-sm-12">
                                            <div className="buttons-preview" align="right">
                                                <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                                    <i className="fa fa-arrow-circle-left"></i>
                                                    &nbsp;Back</span>
                                                <span className="btn btn-danger" onClick={() => ResetOperation("Reset")}>
                                                    <i className="fa fa-times"></i>
                                                    &nbsp;Reset</span>
                                                <button type="button" className="btn btn-success" onClick={UpdateAgency}>
                                                    <i className="fa fa-check right"></i>
                                                    &nbsp;Update</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditAgency;