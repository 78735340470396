import axios from "axios";
import authHeader from "../auth-header";

class InspectionQCService {

    //FLDetailsreport
    GetQADetailsReportList(IsProduction, TaskDetailsName, ExfacStartDate, ExfacEndDate, TNAStartDate, TNAEndDate) {
        return axios.get(window.$APIBaseURL + "Qcinspection/GetQADetailsReportList?IsProduction=" + IsProduction + "&TaskDetailsName=" + TaskDetailsName +
            "&ExfacStartDate=" + ExfacStartDate + "&ExfacEndDate=" + ExfacEndDate + "&TNAStartDate=" + TNAStartDate + "&TNAEndDate=" +
            TNAEndDate,
            {
                headers: {
                    authorization: authHeader()
                }
            });
    }
    //FinalInspectionList - Inspection Entry (Main Module)
    GetQAInspectionList(IsProduction, Taskname, PoStartDate, PoEndDate, ApStartDate, ApEndDate, ApprovedStatus) {
        return axios.get(window.$APIBaseURL + "Qcinspection/GetQAInspectionList?IsProduction=" + IsProduction + '&Taskname=' + Taskname + "&PoStartDate=" + PoStartDate + "&PoEndDate=" + PoEndDate + "&ApStartDate=" + ApStartDate + "&ApEndDate=" + ApEndDate + "&ApprovedStatus=" + ApprovedStatus,
            {
                headers: {
                    authorization: authHeader()
                }
            });
    }

    //FinalInspectionList - ReInspection (Main Module)
    GetReQAInspectionList(IsProduction, Taskname, PoStartDate, PoEndDate, ApStartDate, ApEndDate, ApprovedStatus) {
        return axios.get(window.$APIBaseURL + "Qcinspection/GetQAInspectionList?IsProduction=" + IsProduction + '&Taskname=' + Taskname + "&PoStartDate=" + PoStartDate + "&PoEndDate=" + PoEndDate + "&ApStartDate=" + ApStartDate + "&ApEndDate=" + ApEndDate + "&ApprovedStatus=" + ApprovedStatus,
            {
                headers: {
                    authorization: authHeader()
                }
            });
    }
    InsertUpdateQAreportComment(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Qcinspection/InsertUpdateQAreportComment',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    //FL CommentReportList
    GetQAPurchaseOrderSkuReportComments(Id, taskname) {
        return axios.get(window.$APIBaseURL + "Qcinspection/GetQAPurchaseOrderSkuReportComments?PurchaseOrderCommentsID=" + Id + '&TaskType=' + taskname,
            {
                headers: {
                    authorization: authHeader()
                }
            });
    }
    //Approval For Inspection (ploit run,size set)
    InsertUpdateApproval(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Qcinspection/InsertUpdateQApproval',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
    //Approval For Inspection(PP and IN and MId)
    InsertUpdateApprovalReview(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Qcinspection/InsertUpdateQApprovalReview',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
    GetQAApprovalHistory(QAInspectionID) {
        return axios.get(window.$APIBaseURL + "Qcinspection/GetQAApprovalHistory?QAInspectionID=" + QAInspectionID,
            {
                headers: {
                    authorization: authHeader()
                }
            });
    }
    GetQAApprovalList1(QAInspectionID) {
        return axios.get(window.$APIBaseURL + "Qcinspection/GetQAApprovalList1?QAInspectionID=" + QAInspectionID,
            {
                headers: {
                    authorization: authHeader()
                }
            });
    }
    //REinspectionComment
    InsertUpdateReinspectionComment(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Qcinspection/InsertUpdateReinspectionCommentQA',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
    GetReinspectionCommentsList(QAInspectionID, taskname) {
        return axios.get(window.$APIBaseURL + "Qcinspection/GetReinspectionCommentsList?QCInspectionID=" + QAInspectionID + '&TaskName=' + taskname,
            {
                headers: {
                    authorization: authHeader()
                }
            });
    }
    GetQAReInspectionDetailList(Operation, QAInspectionID, TNAId, TasKname) {
        return axios.get(window.$APIBaseURL + "Qcinspection/GetReInspectionDetailList?Operation=" + Operation + "&QAInspectionID=" + QAInspectionID + "&TNAId=" + TNAId + '&TaskName=' + TasKname, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    GetPurchaseOrderSKUQty(finalInspectionID) {
        return axios.get(window.$APIBaseURL + "Qcinspection/GetPurchaseOrderSKUQty?QCInspectionID=" + finalInspectionID, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    GetInspectionandApprovalList(Operation, Id, Taskname) {
        return axios.get(window.$APIBaseURL + "Qcinspection/GetQAInspectionandApprovalList?Operation=" + Operation + "&PoSKUID=" + Id + '&Taskname=' + Taskname,
            {
                headers: {
                    authorization: authHeader()
                }
            });
    }
}
export default new InspectionQCService()