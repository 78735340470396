import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import strikeoffService from "../../../services/Style/StrikeoffService";
import Lightbox from "react-image-lightbox"
import { Modal, Button } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { StyleStrikeOffId } from "../../Common/PageActionNumber";
import { loadStrikeoffListByStyleID } from "../../../actions/style";
import SweetAlertPopup from '../../Common/SweetAlertPopup';
import axios from "axios";
import $ from "jquery";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import CommonService from '../../../services/Common/CommonService';
import Nodify from "../../Common/ReactNotification"
const StrikeOff = ({
    ParentCallBack,
    StyleInformation,
    StyleId,
    StrikeInfo

}) => {

    //const [comments, setComments] = useState({ colorGroup: [], color: [] });
    const [getPopupValue, setPopupValue] = useState([{ Notes: '', TargettoClose: '', CompletedOn: '', RemindMe: '' }]);
    const [getShow, setShow] = useState(false);
    const [isOpen, setIsOpen] = useState(false)
    const [ShowLoader, setShowLoader] = useState(false);
    // const [totalItems, setTotalItems] = useState(0);
    const [getID, setID] = useState(0);
    // const [loader, showLoader, hideLoader] = useFullPageLoader();
    const dispatch = useDispatch();
    //reducer

    const reducerState = useSelector((state) => state);
    const comments = reducerState.style.strikeOffList;
    const [isOpenimg, setIsOpenimg] = useState(false);
    const [getImagename, setImagename] = useState();

    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),
    }


    useEffect(() => {
        if (StyleId !== 0) {
            dispatch(loadStrikeoffListByStyleID(StyleId));
        }
    }, [StyleId !== 0]);

    const GetMasterDetails = (value) => {
        ParentCallBack(value);
    }

    const handleClose = () => {
        setShow(false);
    };

    const ShowCritical = (notes, targettoclose, completedon, remindme) => {
        setShow(true);
        getPopupValue[0].Notes = notes;
        getPopupValue[0].TargettoClose = targettoclose;
        getPopupValue[0].CompletedOn = completedon;
        getPopupValue[0].RemindMe = remindme;
        setPopupValue(getPopupValue);
    }

    const RedirecttoMaster = () => {
        ParentCallBack("AddMaster");
    }

    const pageRedirect = (dataParams) => {
        ;
        let Params = {
            Operation: 25,
            Id: dataParams.styleStrikeOffPrintColorID,
        };
        CommonService.CheckMappedItem(Params).then(res => {
            if (res.data.outputResult === "-2") {
                let Title = "Warning!";
                let message = "This StrikeOff is already mapped.";
                let type = "warning";

                Nodify(Title, type, message);
            }
            else {
                setID({ showPopupDelete: true, Params: dataParams });
            }
        });
    }
    // setID({ showPopupDelete: true, Params: Params });


    const Delcallback = (value) => {
        setID({ showPopupDelete: false, Params: [] });
        dispatch(loadStrikeoffListByStyleID(StyleId));
    }
    const ViewFile = (event, filename) => { // event.preventDefault();
        setIsOpenimg(true);
        setImagename(filename);
    }

    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                        <div className="widget-header SkuList">
                            <span className="widget-caption">Strike Off List</span>
                        </div>
                    </div>
                    {StrikeInfo && StrikeInfo.isAdd === 1 &&
                        <div className="pull-right">
                            <a onClick={RedirecttoMaster} type="button" className="btn btn-success">
                                <i className="fa fa-plus"></i>
                                &nbsp;  Add Master
                            </a>
                            &nbsp;
                            <button onClick={() => GetMasterDetails(comments.colorGroup.length !== 0 ? "Edit" : "Add")}
                                type="button" className="btn btn-primary "
                            // style={{ disabled: comments.length > 0 ? true : false }}
                            //disabled={comments.length === 0 && comments.colorGroup.length === 0 ? true : false}
                            >
                                <i className="fa fa-check-square"></i>
                                &nbsp;  Pick from Master
                            </button>
                            &nbsp;
                            {/* {getStyleStrikeoffList.length !== 0 && (
                            <button onClick={() => GetMasterDetails("Edit")} type="button" className="btn btn-warning">
                                <i className="fa fa-edit"></i>
                                &nbsp;  Edit
                            </button>
                        )} */}
                        </div>
                    }
                </div>
            </div>
            <div> {
                isOpen && (
                    <Lightbox
                        mainSrc={isOpen}
                        alt="bg image"

                        onCloseRequest={
                            () => setIsOpen(false)
                        } />
                )
            } </div>
            <div className="row">
                {comments.colorGroup.filter(x => x.styleStrikeOffSkuNames !== null && x.styleStrikeOffSkuNames !== '').length === 0 && (
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <div className="widget flat radius-bordered">
                            <div className="panel-group accordion" id="accordion">
                                <div className="panel panel-default">
                                    <div className="panel-heading ">
                                        <h4 className="mb-0 panel-title">
                                            <a className="btn btn-link font_wight" data-toggle="collapse" data-parent="accordion" >
                                                {
                                                    "Strike Off Info"
                                                }
                                            </a>
                                        </h4>
                                    </div>
                                    <div className={'panel-collapse collapse in'}>
                                        <div className="widget-body">
                                            <div id="registration-form">
                                                <div className="col-sm-12">
                                                    <div className="row">
                                                        {ShowLoader === false && (
                                                            <center>
                                                                <h4 className="text-danger">No Strike Off information mapped yet.</h4>
                                                            </center>
                                                        )}
                                                        <Loader show={ShowLoader}
                                                            type="ThreeDots" color="#00BFFF" height={80} width={80} timeout={500} style={{ textAlign: "center" }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {comments.colorGroup.filter(x => x.styleStrikeOffSkuNames !== null && x.styleStrikeOffSkuNames !== '').length !== 0 && (
                    <div className="col-md-12">
                        <div className="widget-body">
                            <div className="dataTables_wrapper form-inline no-footer">
                                <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th style={{ width: "12%" }}>Strike Off Image</th>
                                            <th>SKU's</th>
                                            <th>Strike Off Name</th>
                                            <th>Strike Off Code</th>
                                            <th>Purchase Type</th>
                                            <th>Trim Supplier</th>
                                            <th>Approval Type</th>
                                            <th style={{ display: StrikeInfo && StrikeInfo.isDelete === 1 ? '' : 'none' }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            comments.colorGroup.filter(x => x.styleStrikeOffSkuNames !== null && x.styleStrikeOffSkuNames !== '').map((comment, index) => (
                                                <>
                                                    <>
                                                        <tr key={
                                                            comment.strikeOffID
                                                        }>
                                                            <td>{index+1}</td>
                                                            <td className="trwidth_manual" style={{ textAlign: "center" }}>

                                                                {
                                                                    < img className="width_manual"
                                                                        src={comment.strikeoffLogoName === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images/Style/Strikeoff/' + comment.strikeoffLogoName}
                                                                        alt="Logo"

                                                                        onClick={
                                                                            () => setIsOpen(comment.strikeoffLogoName === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images/Style/Strikeoff/' + comment.strikeoffLogoName)
                                                                        }
                                                                    />
                                                                }</td>
                                                            <td>{comment.styleStrikeOffSkuNames}</td>
                                                            <td>

                                                                <span>
                                                                    {comment.strikeOffName}
                                                                </span>
                                                            </td>
                                                            <td>{comment.strikeOffCode}</td>
                                                            <td>{comment.purchaseTypeName}</td>
                                                            <td>{comment.trimSupplierName === '' ? "-" : comment.purchaseType === 1 ? comment.trimSupplierName : "-"}</td>
                                                            <td>{comment.approvalTypeName === '' ? "-" : comment.approvalTypeName === null ? "-" : comment.approvalTypeName}</td>

                                                            <td style={{ display: StrikeInfo && StrikeInfo.isDelete === 1 ? '' : 'none' }}>
                                                                <span title='Delete Strike Off' onClick={() => pageRedirect(comment)}
                                                                    className="btn btn-danger btn-xs delete">
                                                                    <i className="fa fa-trash-o"></i>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        {

                                                            comments.color.filter(x => x.strikeOffID === comment.strikeOffID && x.indexName === comment.indexName).length !== 0 && (
                                                                <>
                                                                    {
                                                                        comments.color.filter(x => x.strikeOffID === comment.strikeOffID && x.indexName === comment.indexName).map((critical, indexValue) => (
                                                                            critical.notes !== '' && (
                                                                                <tr>
                                                                                    <td colSpan="8">
                                                                                        <span style={{ color: 'red' }}>{"Critical : " + (critical.strPantoneName + ' ' + critical.pantoneTypeName + ' - ' + critical.notes)} ,</span>
                                                                                        <span style={{ color: '#427fed' }}> Target to Close : {critical.targettoClose === null ? '-' : critical.targettoClose} ,</span>
                                                                                        <span style={{ color: '#00a65a' }}> Completed On : {critical.completedOn === null ? '-' : critical.completedOn}</span>
                                                                                        <br />
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        ))
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            comments.manual.filter(x => x.strikeOffId === comment.strikeOffID).map((man, j) => {
                                                                return (
                                                                    man.ManualCloseComment !== '' && comment.approvalTypeName === 'Manual' ?
                                                                        <tr>
                                                                            <td colSpan={10}>
                                                                                <div className="row-1">
                                                                                <span><b>Manual Comment :</b>  {man.manualCloseComment}</span>
                                                                                </div>
                                                                                {/* <span style={{ color: '#427fed' }}>Date : {new Date(man.manualCloseDate).toLocaleDateString()}</span> */}
                                                                                {
                                                                                    comments.manualFiles.length !== 0 ?
                                                                                        comments.manualFiles.filter(x => x.styleStrikeOffIndexName === man.styleStrikeOffIndexName).map((files, fileindex) => (
                                                                                            <div className="col-lg-4 col-sm-4 file_list_upload">
                                                                                                <tr title={files.fileName}>
                                                                                                    File :
                                                                                                    <td>
                                                                                                        <span style={{ marginRight: '7px' }}>
                                                                                                            {
                                                                                                                files.fileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                                    files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                                                        <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                                        files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                                            files.fileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                                            <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                                            }
                                                                                                        </span>  {files.fileName}
                                                                                                    </td>

                                                                                                    <td>

                                                                                                        {
                                                                                                            files.fileType === "application/pdf" || files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files.fileType === 'application/msword' ?
                                                                                                                <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + 'Images/TNAImages/' + files.fileName} download><i class="fa fa-download"></i> </a> :

                                                                                                                <i className="btn btn-info fa fa-eye" title="View Logo"
                                                                                                                    style={{ display: StrikeInfo && StrikeInfo.isView === 1 ? '' : 'none' }}
                                                                                                                onClick={
                                                                                                                        event => ViewFile(event, files.fileName)
                                                                                                                    }></i>
                                                                                                        }
                                                                                                    </td>
                                                                                                </tr>
                                                                                                {

                                                                                                    <div> {
                                                                                                        isOpenimg && (

                                                                                                            <Lightbox mainSrc={window.$APIBaseURL + 'Images/TNAImages/' + getImagename}
                                                                                                                alt="bg image"
                                                                                                                onCloseRequest={
                                                                                                                    () => setIsOpenimg(false)
                                                                                                                } />
                                                                                                        )
                                                                                                    } </div>
                                                                                                }
                                                                                            </div>
                                                                                        ))
                                                                                        : ''


                                                                                }
                                                                            </td>

                                                                        </tr>
                                                                        : ''



                                                                );


                                                            })
                                                        }

                                                        {
                                                            comments.commonApproval.map((man, j) => {
                                                                return (
                                                                    comment.approvalTypeName === 'Existing' ?
                                                                        <tr>
                                                                            <td colSpan="10">
                                                                                <span><b>Mapped Strikeoff Detail </b> &nbsp;</span>
                                                                                <br />
                                                                                <span><b>Season Name : </b> {man.seasonName} &nbsp;</span>
                                                                                <span><b>Approval Date : </b>  {new Date(man.approvalDate).toLocaleDateString()}&nbsp;</span>
                                                                                <span><b>Details : </b> {man.details}&nbsp;</span>
                                                                                <span><b>Approved By : </b> {man.approvedBy}&nbsp;</span>

                                                                            </td>
                                                                        </tr>
                                                                        : ''



                                                                );


                                                            })
                                                        }
                                                    </>
                                                </>
                                            ))
                                        }
                                        {
                                            comments.length === 0 ?
                                                <tr>
                                                    <td colSpan="7" className='norecordfound'><span>No Records Found</span></td>
                                                </tr> : ''
                                        }
                                    </tbody>
                                </table>


                            </div>
                            {
                                getID.showPopupDelete ? <SweetAlertPopup data={
                                    getID.Params
                                }
                                    deleteCallback={Delcallback}
                                    showpopup={true}
                                    pageActionId={StyleStrikeOffId}
                                    Msg={"Strike Off deleted successfully, please wait for Record Refresh."}

                                /> : null
                            }
                        </div>
                    </div>
                )}
            </div>
            {getShow &&
                <Modal show={getShow} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleClose()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Critical</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                            <thead>
                                <tr>
                                    <th colSpan="4" style={{ textAlign: "center" }}>Critical
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan="4">
                                        <textarea
                                            placeholder="Enter comments here..."
                                            maxLength="2000"
                                            value={
                                                getPopupValue[0].Notes
                                            }
                                            disabled
                                            rows="4" style={{ width: "100%" }}></textarea>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Target to Close</td>
                                    <td>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            maxLength="50"
                                            className={'form-control'}
                                            value={getPopupValue[0].TargettoClose}
                                            disabled
                                        />
                                    </td>
                                    <td>Remind Me</td>
                                    <td>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            maxLength="50"
                                            className={'form-control'}
                                            value={getPopupValue[0].RemindMe === 1 ? "Current Date Reaches Target Date" : getPopupValue[0].RemindMe === 2 ? "One Time in a Day, until complete" : ""}
                                            disabled
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Completed On</td>
                                    <td>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            maxLength="50"
                                            className={'form-control'}
                                            value={getPopupValue[0].CompletedOn}
                                            disabled
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => handleClose()}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
            {/* {loader} */}
        </>
    )
};
export default StrikeOff;