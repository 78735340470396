import {
    TESTFIELD_LOADING,
    RETRIEVE_TESTFIELD,
    DELETE_TESTFIELD,
} from "../actions/types";

const initialState = {
    isLoadingTestField: true,
    TestFieldNameList: [],
};

const TestFieldReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case TESTFIELD_LOADING:
            return {
                ...state,
                isLoadingTestField: payload
            };
        case RETRIEVE_TESTFIELD:
            return { ...state, TestFieldNameList: payload };
        case DELETE_TESTFIELD:
           ;
            const results = state.TestFieldNameList.filter(c => c.id !== payload.QCCommentPoint);
            return {
                ...state,
                TestFieldNameList: results
            };

        default:
            return state;
    }
};
export default TestFieldReducer;