import axios from "axios";
import authHeader from "../auth-header";

class TestStandardService {

    GetTestStandardList(StandardID) {
        return axios.get(window.$APIBaseURL + "Master/LoadTestStandardInfo?StandardID=" + StandardID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    AddTestStandard(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertTestStandardInfo',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    UpdateTestStandard(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/UpdateTestStandardInfo',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
}

export default new TestStandardService()