import React, { useState, useEffect, useRef, useCallback } from "react";
import Form from "react-validation/build/form";
import 'react-notifications-component/dist/theme.css';
import ReactNotification from 'react-notifications-component';
import validator from 'validator'
import Nodify from "../../Common/ReactNotification";
import SearchRetain from "../../Common/SearchRetain";
import { useDispatch, useSelector } from "react-redux";
import { createItem } from "../../../actions/testingreport";


const TestingReportDetailEdit = (props) => {



 const [Item, setItem] = useState({ ItemName: '' });
 const [submitted, setSubmitted] = useState(false);
 const [getItem, setOutputs] = useState([]);
 const [ButtonName, setButtonName] = useState("Save");
 const [HeaderName, setHeaderName] = useState("Add");
 const [buttonLoader, setButtonLoader] = useState(false);
 const { user: currentUser } = useSelector((state) => state.auth);

 const dispatch = useDispatch();
 //Enter Error msg
 useEffect(() => {
  const listener = event => {
   if (event.code === "Enter" || event.code === "NumpadEnter") {
    event.preventDefault();
    //SaveBuyerInfo(event);
   }
  };
  document.addEventListener("keydown", listener);
  return () => {
   document.removeEventListener("keydown", listener);
  };
 }, [])

 function ItemsAssign(props) {

  let ItemValue = {
   ItemID: 0,
   ItemName: '',
   Operation: 1,
   Createdby: currentUser.employeeinformationID
  };


  Array.from(document.querySelectorAll("input")).forEach(input => (input.value = ""));
  if (props.location.state !== undefined && props.location.state.params.length !== 0) {
   if (props.location.state.params.itemID !== "") {
    setButtonName("Update");
    setHeaderName("Edit");
    ItemValue.ItemID = props.location.state.params.itemID;
    ItemValue.ItemName = validator.trim(props.location.state.params.itemName);
    ItemValue.Operation = 2;
    return ItemValue;
   }
  } else {
   return ItemValue;

  }

 }


 useEffect(() => {
  let getItems = ItemsAssign(props);
  setOutputs(getItems)
 }, []);

 function handleChange(e) {

  const { name, value } = e.target;
  setItem(inputs => ({
   ...inputs,
   [name]: value
  }));
  let ItemsValue = getItem;
  let inputText = '';
  if (e.target.value.trim() !== '') {
   inputText = e.target.value;
   setSubmitted(true);
  }
  else { setSubmitted(false); }
  ItemsValue[e.target.name] = inputText;
  setOutputs(ItemsValue);
 }


 const saveItems = useCallback((e) => {

  setButtonLoader(true);
  e.preventDefault();
  if (getItem.ItemName.trim() === '') {
   setButtonLoader(false);
   setSubmitted(true);
   Nodify('Warning!', 'warning', 'Item name is required');
   // return false;
  }
  else {
   if (getItem.ItemName.trim()) {
    getItem.ItemName = getItem.ItemName.trim();

    dispatch(createItem(getItem))
     .then(data => {
      var page = "Remove";
      SearchRetain(page);
      let Func = 'Add';
      if (data.outputResult === "1") {
       Func = 'Add';
       PageRedirect(Func);
      } else if (data.outputResult === "2") {
       Func = 'Edit';
       PageRedirect(Func);
      } else if (data.outputResult === "0") {
       getItem.ItemName = ''
       setOutputs(getItem)
       Nodify('Warning!', 'warning', 'Item name is already Exist');
      }
      setButtonLoader(false);
     })
     .catch(e => {
      console.log(e);
     });
   }
   else {
    setSubmitted(true);
   }
  }
 }, [getItem]);


 const PageRedirect = (Func) => {
  props.history.push({
   pathname: "/TestingItemList",
   state: {
    message: Func
   }
  });
 }


 const ResetOperation = (e) => {
  if (e === "Back") {
   props.history.push('/TestingItemList')
  } else {
   window.location.reload();
  }
 }










  return (
   <div className="page-body">
    <ReactNotification />
    <div className="row">
     <div className="col-lg-12 col-sm-12 col-xs-12">
      <div className="widget flat radius-bordered">
       <div className="widget-header heading_box_style">
        <h3 className="heading_style_common">Edit Testing Item</h3>
       </div>
       <div className="widget-body">
        <div id="registration-form">
         <Form>

          <div className="row">
           <div className="col-sm-3">
            <div className="form-group">
             <label htmlFor="ItemName">Testing Item Name<span className="text-danger">*</span></label>
             <span className="input-icon icon-right">
              <input type="text" name="ItemName"
               id="ItemName"
               value={getItem.ItemName}
               onChange={handleChange}
               maxLength="25"
               className={
                'form-control'
               }
               //submitted && !getItem.ItemName ? ' is-invalid' : ''
               style={{ border: submitted && getItem.ItemName === "" ? '1px solid red' : '' }}
               autoFocus
               placeholder="Enter Item Name"
              />
             </span>


            </div>
           </div>
           <div className="clearfix"></div>
           <div className="col-sm-12">
            <div className="buttons-preview" align="right">
             <span className="btn btn-primary"
              onClick={
               () => ResetOperation("Back")
              }>
              <i className="fa fa-arrow-circle-left"></i>
              &nbsp;Back</span>
             <span className="btn btn-danger"
              onClick={
               () => ResetOperation()
              }>
              <i className="fa fa-times"></i>
              &nbsp;Reset</span>
             <button type="submit" onClick={saveItems} className="btn btn-success"
              disabled={buttonLoader}
             >

              <i className="fa fa-check right"></i>
              &nbsp;{
               ButtonName
              }</button>
            </div>
           </div>
          </div>
         </Form>
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>
  )
}

export default TestingReportDetailEdit