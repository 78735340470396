import React, { useState, useEffect } from "react";
import Form from "react-validation/build/form";
import PantoneService from "../../../services/Master/PantoneService";
import 'react-notifications-component/dist/theme.css';
import ReactNotification from 'react-notifications-component';
import $ from "jquery";
import validator from 'validator'
import Nodify from "../../Common/ReactNotification";
import SearchRetain from "../../Common/SearchRetain";
import Input from "react-validation/build/input";
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss'


const PantoneRegister = (props) => {
    const [submitted, setSubmitted] = useState(false);
    const [getAddress, setOutputs] = useState({});
    const [ButtonName, setButtonName] = useState("Save");
    const [HeaderName, setHeaderName] = useState("Add");
    const [buttonLoader, setButtonLoader] = useState(false);
    const [PantonetypeList, setPantonetypeList] = useState([]);

    const [colorColorPicker, setColorPicker] = useState(false);
    const [colorHexCode, setColorHexCode] = useState('#000');
    const [ExistingList, setExistingList] = useState([]);
    useEffect(() => {
        PantoneService.getPantonelist(3, 0, 0).then((response) => {
            if (response.data) {
                setExistingList(response.data);
            }
        });
    }, []);

    function pantoneAssign(props) {
        let pantoneAssign = {};

        Array.from(document.querySelectorAll("input")).forEach(input => (input.value = ""));

        if (props.location.state.params.length !== 0) {
            if (props.location.state.params.pantoneID !== "") {
                setButtonName("Update");
                setHeaderName("Edit");
                pantoneAssign.PantoneID = props.location.state.params.pantoneID;
                pantoneAssign.PantoneType = props.location.state.params.pantoneTypeID;
                pantoneAssign.PantoneName = validator.trim(props.location.state.params.pantoneName);
                pantoneAssign.PantoneCode = validator.trim(props.location.state.params.pantoneCode);
                pantoneAssign.PantoneColor = validator.trim(props.location.state.params.pantoneColor);
                setColorHexCode(pantoneAssign.PantoneColor);
                pantoneAssign.Operation = 2;
                pantoneAssign.Createdby = 2;
                return pantoneAssign;
            }
        }
        else {
            return pantoneAssign;
        }
    }

    useEffect(() => {

        let getAddressAssign = pantoneAssign(props);
        setOutputs(getAddressAssign);

        async function getPantoneType() {
            PantoneService.loadPantoneTypeList().then((response) => {
                if (response.data) {
                    setPantonetypeList(response.data);
                }
            }).catch(() => { });
        }
        getPantoneType();


    }, []);

    const handlePantone = (e) => {
        setButtonLoader(true);
        e.preventDefault();
        if (getAddress.PantoneType === 0 || getAddress.PantoneName.trim() === '' || getAddress.PantoneCode.trim() === '' || getAddress.PantoneColor.trim() === '') {
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Pantone detail is required');
        }
        else {
            PantoneService.AddPantone(getAddress).then(res => {
                var page = "Remove";
                SearchRetain(page);
                let Func = 'Add';
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "-2") {
                    $("#AddressTypeName").val("");
                    Nodify('Warning!', 'warning', 'Pantone detail already Exist');
                }
                setButtonLoader(false);
            });
        }
    };

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/PantoneList",
            state: {
                message: Func
            }
        });
    }

    const styles = reactCSS({
        'default': {
            color: {
                width: '120px',
                height: '20px',
                borderRadius: '2px',
                background: `${colorHexCode}`,
            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                position: 'absolute',
                zIndex: '2',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });
    //const Values = getAddress;
    const handleClickColor = (index) => {
        setColorPicker(true);
    }

    const handleCloseColor = () => {
        setColorPicker(false);
    }

    const handleChange = (event) => {
        //let Values = (...getAddress);

        if (event.hex === undefined) {
            let inputText = '';
            if (event.target.value.trim() !== '') {
                inputText = event.target.value;
            }
            if (event.target.name === "PantoneType") {
                getAddress.PantoneType = parseInt(inputText);
                // async function getPantoneType() {
                //     PantoneService.loadPantoneTypeList().then((response) => {
                //        if (response.data) {
                //            setPantonetypeList(response.data);
                //        }
                //      }).catch(() => { });
                //    }
                //    getPantoneType();
                //getAddress.pantoneType = parseInt(inputText);
                //getAddress[0].PantoneType = parseInt(inputText);
            } else if (event.target.name === "PantoneName") {
                getAddress.PantoneName = inputText;
            } else if (event.target.name === "PantoneCode") {
                getAddress.PantoneCode = inputText;
            } else if (event.target.name === "PantoneColor") {
                setColorHexCode(colorHexCode)
                getAddress.PantoneColor = colorHexCode;
                setColorPicker(true);
                // Values.PantoneColor = inputText;
            }
            //getAddress[0].PantoneType=
            setOutputs(getAddress);
        }
        else {
            setColorHexCode(event.hex)
            getAddress.PantoneColor = event.hex;
        }
    };

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/PantoneList')
        } else {
            // setSubmitted(false);
            // let getAddressAssign = pantoneAssign(props);
            // setOutputs(getAddressAssign);
            // setButtonLoader(false);
            window.location.reload();
        }
    }
    return (
        <div className="page-body">
            <ReactNotification />
            {
                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <div className="widget flat radius-bordered">
                            <div className="widget-header heading_box_style">
                                <h3 className="heading_style_common">{HeaderName} Pantone Detail</h3>
                            </div>
                            <div className="widget-body">
                                <div id="registration-form">
                                    <Form onSubmit={handlePantone}>
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <div className="form-group">
                                                    <label htmlFor={
                                                        "PantoneType"
                                                    }>Pantone Type<span className="text-danger">*</span>
                                                    </label>
                                                    <select disabled id={
                                                        "PantoneType"
                                                    }
                                                        name="PantoneType"
                                                        className="form-select"
                                                        onChange={
                                                            event => handleChange(event)
                                                        }
                                                        value={
                                                            getAddress.PantoneType
                                                        }
                                                        //onChange={handleChange}
                                                        style={
                                                            {
                                                                border: submitted && !getAddress.PantoneType ? '1px solid red' : ''
                                                            }
                                                        }>
                                                        <option value="0">- Select Type -</option>
                                                        {
                                                            PantonetypeList.map(item => (
                                                                <option key={
                                                                    item.id
                                                                }
                                                                    value={
                                                                        item.id
                                                                    }>
                                                                    {
                                                                        item.name
                                                                    } </option>
                                                            ))
                                                        } </select>
                                                </div>
                                            </div>




                                            <div className="col-sm-3">
                                                <div className="form-group">
                                                    <label htmlFor="PantoneName">Pantone Name<span className="text-danger">*</span></label>
                                                    <span className="input-icon icon-right">
                                                        <Input
                                                            placeholder={"Enter Pantone Name"}
                                                            id="PantoneName"
                                                            name="PantoneName"
                                                            value={getAddress.PantoneName}
                                                            type="text"
                                                            maxLength="50"
                                                            autoComplete="off"
                                                            onChange={handleChange}
                                                            // onBlur={CheckOnblur}
                                                            className='form-control'
                                                            autoFocus
                                                            style={{ border: submitted && !getAddress.PantoneName ? '1px solid red' : '' }} />
                                                    </span>

                                                </div>
                                            </div>

                                            <div className="col-sm-3">
                                                <div className="form-group">
                                                    <label htmlFor="PantoneCode">Pantone Code<span className="text-danger">*</span></label>
                                                    <span className="input-icon icon-right">
                                                        <Input
                                                            placeholder={"Enter Pantone Code"}
                                                            id="PantoneCode"
                                                            name="PantoneCode"
                                                            value={getAddress.PantoneCode}
                                                            type="text"
                                                            maxLength="50"
                                                            autoComplete="off"
                                                            onChange={handleChange}
                                                            className='form-control'
                                                            style={{ border: submitted && !getAddress.PantoneCode ? '1px solid red' : '' }}
                                                        /></span>

                                                </div>
                                            </div>

                                            <div className="col-sm-3">
                                                <div className="form-group">
                                                    <label htmlFor="PantoneColor">Pantone Color<span className="text-danger">*</span></label>
                                                    {/* <span className="input-icon icon-right">
                                                        <input
                                                         style={{background: getAddress.PantoneColor,width: '120px',height: '34px',
                                                         borderRadius: '2px',cursor:"pointer"}}
                                                            //placeholder={"Enter Pantone Color"}
                                                            id="PantoneColor"
                                                            name="PantoneColor"
                                                            //value={getAddress.PantoneColor}
                                                            type="text"
                                                            //maxLength="50"
                                                            //autoComplete="off"
                                                            // onClick={
                                                            //     event => handleInputChange(index, event)
                                                            // }
                                                            readOnly
                                                            onClick={handleChange}
                                                           // className='form-control'
                                                            //style={{ border: submitted && !getAddress.PantoneColor ? '1px solid red' : '' }}
                                                        /></span> */}

                                                    <div>
                                                        <div style={styles.swatch}
                                                            //onClick={handleClickColor()}
                                                            onClick={handleClickColor}>
                                                            <div style={styles.color} />
                                                        </div>
                                                        {colorColorPicker ? <div style={styles.popover}>
                                                            <div style={styles.cover} onClick={handleCloseColor} />
                                                            <SketchPicker color={getAddress.PantoneColor}
                                                                // onChange={ handleChangeColor(index) }
                                                                onChange={handleChange} />
                                                        </div> : null}

                                                    </div>

                                                </div>
                                            </div>
                                            {/* { colorColorPicker && (
                                                            <SketchPicker color={colorHexCode} id={
                                                                "Color"
                                                            } name="ColorPicker"
                                                             // onClick={handleChange}
                                                                // onChange={
                                                                //     event => handleChange(index, event)
                                                                // }
                                                                />
                                                          )
                                                        }  */}

                                            <div className="clearfix"></div>
                                            <div className="col-sm-12">
                                                <div className="buttons-preview" align="right">
                                                    <span className="btn btn-primary"
                                                        onClick={
                                                            () => ResetOperation("Back")
                                                        }>
                                                        <i className="fa fa-arrow-circle-left"></i>
                                                        &nbsp;Back</span>
                                                    <span className="btn btn-danger"
                                                        onClick={
                                                            () => ResetOperation()
                                                        }>
                                                        <i className="fa fa-times"></i>
                                                        &nbsp;Reset</span>
                                                    <button type="submit" className="btn btn-success"
                                                        disabled={buttonLoader}>

                                                        <i className="fa fa-check right"></i>
                                                        &nbsp;{
                                                            ButtonName
                                                        }</button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            { }
        </div>
    );
};

export default PantoneRegister;
