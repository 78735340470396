import {
    STATE_LOADING,
    RETRIEVE_STATE,
    DELETE_STATE,
} from "./types";
import StateService from "../services/Master/StateService";

const StateLoading = (isStatus) => ({
    type: STATE_LOADING,
    payload: isStatus,
});

export const deleteState = (StateID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_STATE,
            payload: StateID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrieveState = (StateID) => async (dispatch) => {
    try {
        dispatch(StateLoading(true));
        const res = await StateService.GetStateList(StateID);
        dispatch({
            type: RETRIEVE_STATE,
            payload: res.data,
        });
        dispatch(StateLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(StateLoading(false));
    }
};