import axios from "axios";

const register = (username, email, password) => {
  return axios.post(window.$APIBaseURL + "users/signup", {
    username,
    email,
    password,
  });
};
const login = (username, password, MPIN) => {

  return axios
    .post(window.$APIBaseURL + "users/authenticate?", {
      username,
      password,
      MPIN
    })
    .then((response) => {
      // if (response.data.accessToken) {
      if (response.data.item4.token) {
        localStorage.setItem("user", JSON.stringify(response.data.item4));
        localStorage.setItem("UserMenuList", JSON.stringify(response.data));
      }
      //.post(API_URL + "Login1?username=" + username + "&password=" + password)
      // .then((response) => {
      //   if (response.data.accessToken) {
      //     localStorage.setItem("user", JSON.stringify(response.data));
      //   }

      return response.data;
    });
};

const logout = () => {
  // localStorage.removeItem("user");
  // localStorage.removeItem("UserMenuList");
  localStorage.clear();
};

const RequestOTPforForgotPassword = (data) => {
  return axios({
    method: 'post',
    url: window.$APIBaseURL + 'users/VerifyMailForForgotPassword',
    data: data
  });
}

const UpdateNewPassword = (data) => {
  return axios({
    method: 'post',
    url: window.$APIBaseURL + 'users/UpdateForgotPassword',
    data: data
  });
}

// export default {
//   register,
//   login,
//   logout,
// };

const authService = {
  register,
  login,
  logout,
  RequestOTPforForgotPassword,
  UpdateNewPassword
};

export default authService;
