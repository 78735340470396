import React, { useState, useEffect, useRef, Fragment } from "react";
import Nodify from "../../Common/ReactNotification";
import { useSelector } from "react-redux";
import ReactNotification from 'react-notifications-component';
import { TableHeader, Pagination, Search, PageCount } from "../../Datatable";
import BuyerService from "../../../services/Master/BuyerService"
import SelectCombobox from 'react-select';
import SearchRetain from "../../Common/SearchRetain";
import BuyCusWarehouse from "../../../services/Master/BuyCusWarService";
import styleService from "../../../services/Style/styleService"
import Warehouse from "../../../services/Master/WarehouseService"
import axios from "axios";
import $ from "jquery";
import Lightbox from "react-image-lightbox"


const BuyCusWarehouseAdd = (props) => {
  const [GetItem, SetItem] = useState([{
    CustomerID: 0,
    CustomerName: "",
    WarehouseDetails: [{
      BuyCusWarID: 0,
      WarehouseID: 0,
      WarehouseName: '',
      fileName: "",
      filePath: "",
      fileType: "",
      fileIndex: ""
    }]

  }]);
  const [GetbuyerDetails, setbuyerDetails] = useState([{ Value: 0, label: '' }]);
  const [isOpenimg, setIsOpenimg] = useState(false);
  const [getImagename, setImagename] = useState();
  const [getheaderBuyerDetails, setheaderBuyerDetails] = useState([]);
  const [getwarehouse, setwarehouse] = useState([]);
  const [getEndCustomerDetails, setEndCustomerdetails] = useState([]);
  const [ExistingList, setExistingList] = useState([]);
  const [getExistingBuyerData, setExistingBuyerData] = useState([]);

  const [submitted, setSubmitted] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);

  const headers = [
    { name: <span>Warehouse Name<span className="text-danger">*</span></span>, field: "itemName", sortable: false },
    { name: <span>Warehouse Code on Marking<span className="text-danger">*</span></span>, field: "itemName", sortable: false },
    { name: "Action", field: "", sortable: false, },
  ];

  let styles = {
    control: styles => ({ ...styles, border: '1px solid red' }),

  }

  useEffect(() => {
    let BuyerDetail = [];
    BuyerService.LoadBuyerList(2).then((res) => {
      if (res.data) {
        res.data.map(x => {
          BuyerDetail.push({
            label: x.name,
            value: x.id
          })
        })
        setheaderBuyerDetails(BuyerDetail)
      }
    })
    let cuswarehouseinformation = [];
    BuyCusWarehouse.getBuyCusWarList(2, 0).then(async (res) => {
     ;

      res.data.item1.map((buyerid, index) => {
        setExistingBuyerData(res.data.item1)
      })

      if (res.data) {

        res.data.item3.map((y, index) => {
          cuswarehouseinformation.push({
            CustomerID: y.customerID,
            CustomerName: y.customerName,
            WarehouseDetailslist: []
          })

          res.data.item2.filter(x => x.customerID === y.customerID).map((x, i) => {
            cuswarehouseinformation[index].WarehouseDetailslist.push({
              buyCusWarID: x.buyCusWarID,
              warehouseID: x.warehouseID,
              warehousename: x.warehouseName,
              fileName: x.fileName,
              filePath: x.filePath,
              fileType: x.fileType,
              fileIndex: x.fileIndex,
            })


          })

        })

      }
      setExistingList(cuswarehouseinformation)


    }, [])




  }, [])





  const PageRedirect = (Func) => {
    props.history.push({ pathname: "/BCWList", state: { message: Func } });
  }




  const handleAddCusFields = (event, index) => {
   ;
    const values = [...GetItem];
    if (values[index].CustomerID !== 0) {
      values.push({
        CustomerID: 0,
        CustomerName: "",
        WarehouseDetails: [{
          BuyCusWarID: 0,
          WarehouseID: 0,
          WarehouseName: '',
          fileName: "",
          filePath: "",
          fileType: "",
          fileIndex: ""
        }]
      });
      SetItem(values);
      setSubmitted(false);
    }
    else {
      Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
      setSubmitted(true);
    }
  };

  const handleAddFields = (event, index, Childindex) => {
   ;
    const values = [...GetItem];
    if (values[index].WarehouseDetails[Childindex].WarehouseID !== 0 && values[index].WarehouseDetails[Childindex].fileName !== "") {
      values[index].WarehouseDetails.push({
        BuyCusWarID: 0,
        WarehouseID: 0,
        WarehouseName: "",
        fileName: "",
        filePath: "",
        fileType: "",
        fileIndex: ""
      });
      SetItem(values);
      setSubmitted(false);
    }


    else {
      Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
      setSubmitted(true);
    }
  };

  const handleRemoveFields = (index, Childindex) => {
   ;
    const values = [...GetItem];
    values[index].WarehouseDetails.splice(Childindex, 1)
    //values[index].splice(index, 1);
    SetItem(values);
  };

  const handleRemoveCusFields = (index, Childindex) => {
   ;
    const values = [...GetItem];
    //values[index].WarehouseDetails.splice(Childindex, 1)
    values.splice(index, 1);
    SetItem(values);
  };

  const handleDropdownChange = (event, FieldName, index) => {
    const values = [...GetbuyerDetails];
    const valuesone = [...GetItem]
    let inputText = '';
    if (FieldName === "Buyer") {
      if (event !== null) {
        values[0].Value = event.value;
        values[0].label = event.label;
        inputText = event.value
      } else {
        values[0].Value = 0;
        values[0].label = ''
      }
      styleService.LoadBrandThemeStoryEndCustomer(5, inputText).then((res) => {
        if (res.data) {
          let CustomerList = res.data;
          let respected_Customer = CustomerList.filter(x => (x.buyerMapID === 2));
          setEndCustomerdetails(respected_Customer);
        }
      })

      Warehouse.GetWarehouseList(2, inputText).then((res) => {
        let finalwarehouse = []
        if (res.data) {
          res.data.warehouseEdt.map(x => {
            finalwarehouse.push({
              label: x.warehousename,
              value: x.warehouseID
            })
          })
          setwarehouse(finalwarehouse)
        }
      })

     ;
      if (getExistingBuyerData.length >= 1)
        for (var j = 0; j < (getExistingBuyerData.length); j++) {

          if (values[0].Value === getExistingBuyerData[j].buyerID) {

            values[0].Value = 0;
            values[0].label = "";
            setbuyerDetails(values);
            Nodify('Warning!', 'warning', 'This Buyer is already exist.');

            return false;
          }
        }
    }

    if (FieldName === "Customer") {
      if (event !== null) {
        valuesone[index].CustomerID = event.value;
        valuesone[index].CustomerName = event.label;
        inputText = event.value
      } else {
        valuesone[index].CustomerID = 0;
        valuesone[index].CustomerName = ''
      }

     ;
      if (valuesone.length > 1)
        for (var j = 0; j < (GetItem.length); j++) {
          if (j !== index) {
            if (valuesone[j].CustomerID === valuesone[index].CustomerID) {

              valuesone[index].CustomerID = 0;
              SetItem(valuesone);
              Nodify('Warning!', 'warning', 'This Customer is already exist in this list.');
              return false;
            }

          }
        }
    }
    setbuyerDetails(values);
    SetItem(valuesone)



  };

  const ViewFile = (event, filename) => { // event.preventDefault();
    setIsOpenimg(true);
    setImagename(filename);
  }
  const filevals = [...GetItem]
  const RemoveImages = async (index, Childindex, files, ReportManualFileId) => {


    filevals[index].WarehouseDetails[Childindex].fileName = ""

    SetItem(filevals);
  }


  const AppendFiles = async (e, index, childIndex) => {
   ;
    let data = [...GetItem]
    const file = e.target.files[0];
    if (file !== null && file !== '' && file !== undefined) {
      const fileType = file['type'];
      const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword', "application/vnd.ms-excel",
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
      if (!validImageTypes.includes(fileType)) {
        Nodify('Warning!', 'warning', 'Invalid file format selected');
        $('#FileUpload').val("");
      }
      else {
        const formData = new FormData();
        formData.append("FormFile", file);
        formData.append("FileName", file.name);
        formData.append("Page", "ReportStatus");
        try {
          const res = await axios.post(window.$APIBaseURL + "api/file", formData);
          data[index].WarehouseDetails[childIndex].fileName = res.data + "_" + file.name
          data[index].WarehouseDetails[childIndex].filePath = "Images/Report/" + file.name
          data[index].WarehouseDetails[childIndex].fileType = file.type
          data[index].WarehouseDetails[childIndex].fileIndex = index.toString()



          SetItem(data);
          $('#FileUpload').val("");
        } catch (ex) {
          console.log(ex);
        }
      }
    }
  }


  const handleInputItemchange = (event, index, FieldName, childIndex) => {
   ;
    const values = [...GetItem];
    const allitem = [...GetbuyerDetails]

    if (FieldName === "Item") {
      if (event !== null) {
        values[index].WarehouseDetails[childIndex].WarehouseID = event.value;
        values[index].WarehouseDetails[childIndex].WarehouseName = event.label;
      } else {
        values[index].WarehouseDetails[childIndex].WarehouseID = 0;
        values[index].WarehouseDetails[childIndex].WarehouseName = ''
      }
    }


    SetItem(values);
   ;
    if (values[index].WarehouseDetails.length >= 1)
      for (var j = 0; j < (GetItem[index].WarehouseDetails.length); j++) {
        if (j !== childIndex) {
          if (values[index].WarehouseDetails[j].WarehouseID === values[index].WarehouseDetails[childIndex].WarehouseID) {

            values[index].WarehouseDetails[childIndex].WarehouseID = 0;
            values[index].WarehouseDetails[childIndex].WarehouseName = "";
            SetItem(values);
            Nodify('Warning!', 'warning', 'This warehouse is already exist in this list.');
            setSubmitted(true);
            return false;
          }

        }
      }
    setbuyerDetails(allitem);

    SetItem(values);
  };

  const ResetOperation = (e) => {
    if (e === "Back") {
      props.history.push('/BCWList');
    } else {
      window.location.reload();
    }
  }

  const SaveWorkmanGroup = (e) => {
    setButtonLoader(true);
    e.preventDefault();
    for (var i = 0; i < (GetItem.length); i++) {
      if (GetItem[i].CustomerID === 0) {
        Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
        setSubmitted(true);
        setButtonLoader(false);
        return false;
      }
    }

    let cuswarehouseinformation = [];

    GetItem.map((Info, i) => {
      Info.WarehouseDetails.map((x) => {
        cuswarehouseinformation.push({
          BuyCusWarID: x.BuyCusWarID,
          CustomerID: Info.CustomerID,
          CustomerName: Info.CustomerName,
          warehouseID: x.WarehouseID,
          warehousename: x.WarehouseName,
          fileName: x.fileName,
          filePath: x.filePath,
          fileType: x.fileType,
          fileIndex: x.fileIndex,
        });
      });
    }
    );

   ;
    for (var j = 0; j < (cuswarehouseinformation.length); j++) {
      if (cuswarehouseinformation[j].warehouseID === 0 || cuswarehouseinformation[j].fileName === "") {
        Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
        setSubmitted(true);
        setButtonLoader(false);
        return false;
      }
    }



    //let finalvalues ={Operation:1,}
    let Inputmaster = { Operation: 1, BuyerID: GetbuyerDetails[0].Value, BuyerName: GetbuyerDetails[0].label, cusWarehouseinfo: cuswarehouseinformation, CreatedBy: currentUser.employeeinformationID }
    BuyCusWarehouse.InsertBuyCusWarehouse(Inputmaster).then((res) => {
      var page = "Remove";
      SearchRetain(page);

      let Func = 'Add';
      if (res.data.outputResult === "1") {
        Func = 'Add';
        PageRedirect(Func);
      } else if (res.data.outputResult === "2") {
        Func = 'Edit';
        PageRedirect(Func);
      }
      else if (res.data.outputResult === "0") {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', "Error Occured!");
      }
    })

  }



  return (
    <div className="page-body">
      <ReactNotification />
      <div className="row">
        <div className="col-lg-12 col-sm-12 col-xs-12">
          <div className="widget flat radius-bordered">
            <div className="widget-header heading_box_style">
              <h3 className="heading_style_common">Add Buyer Customer Warehouse Code</h3>
            </div>

            <div className="widget-body">
              <div className="row">
                {GetbuyerDetails.map((data, index) => (
                  <div className="col-lg-3">
                    <label>Buyer Name<span className="text-danger">*</span></label>
                    <SelectCombobox className="basic-single" name="Buyer" id={"Buyer"}
                      isDisabled={false}
                      isLoading={false}
                      isSearchable={true}
                      isClearable={true}
                      autocomplete='off'
                      placeholder="Select Buyer Name"
                      styles={submitted && data.Value === 0 ? styles : ''}
                      onChange={event => handleDropdownChange(event, "Buyer", index)}
                      options={getheaderBuyerDetails}
                      value={getheaderBuyerDetails.filter(function (option) {
                        return option.value === data.Value;
                      })}

                    />
                  </div>
                ))

                }





              </div>
              {
                GetItem.map((inputfeild, index) => (
                  <div className="row" style={{ marginTop: "1%" }}>
                    <div className="col-lg-3">
                      <label>Customer<span className="text-danger">*</span></label>
                      <SelectCombobox className="basic-single" name="Customer" id={"Customer" + index}
                        isDisabled={false}
                        isLoading={false}
                        isSearchable={true}
                        isClearable={true}
                        autocomplete='off'
                        placeholder="Select Customer Name"
                        styles={submitted && inputfeild.CustomerID === 0 ? styles : ''}
                        onChange={event => handleDropdownChange(event, "Customer", index)}
                        options={getEndCustomerDetails}
                        value={getEndCustomerDetails.filter(function (option) {
                          return option.value === inputfeild.CustomerID;
                        })}
                      />
                    </div>
                    <div className="col-lg-3" style={{ marginTop: "2%" }}>

                      <span className="btn btn-info" onClick={(event) => handleAddCusFields(event, index)}><i className="fa fa-plus"></i></span>
                      &nbsp;
                      &nbsp;
                      {
                        GetItem.length !== 1 && (
                          <span className="btn btn-danger" onClick={(event) => handleRemoveCusFields(index)}  ><i className="fa fa-trash-o"></i></span>
                        )
                      }


                    </div>
                    <div className="row" >
                      <div className="col-lg-12" style={{ marginLeft: "16px" }}>
                        <div id="registration-form">
                          <form>
                            {
                              <table className="table table-striped table-bordered table-hover dataTable no-footer margin-top-10" id="editabledatatable" style={{ width: "50%" }}>
                                <TableHeader headers={headers} />
                                <tbody>
                                  {
                                    inputfeild.WarehouseDetails.map((ChildInput, Childindex) => (

                                      <tr>
                                        <td style={{ width: "50%" }}>
                                          <SelectCombobox className="basic-single" name="Item" id={"Item" + index}
                                            isDisabled={false}
                                            isLoading={false}
                                            isSearchable={true}
                                            isClearable={true}
                                            autocomplete='off'
                                            placeholder="Select Warehouse Name"
                                            styles={submitted && ChildInput.WarehouseID === 0 ? styles : ''}
                                            onChange={event => handleInputItemchange(event, index, "Item", Childindex)}
                                            options={getwarehouse}
                                            value={getwarehouse.filter(function (option) {
                                              return option.value === ChildInput.WarehouseID;
                                            })}
                                          />
                                        </td>

                                        <td style={{ width: "50%" }}>
                                          <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                            <tbody>
                                              <tr>
                                                <div className="col-sm-12 col-lg-12">
                                                  <div style={{ paddingLeft: 0 }}>
                                                    <span className="widget-caption" style={{ fontSize: '14px' }}>File Upload <span style={{ color: 'red', fontSize: '14px' }}>(Upload Document Type: Image Only)</span></span>
                                                  </div>
                                                </div>
                                                < div className="col-sm-12 col-lg-12">
                                                  <div className="row">
                                                    <div className="col-sm-8 col-lg-8">
                                                      <div className='form-group'>
                                                        <tr>
                                                          <td> <input type="file" id="FileUpload" style={{ fontSize: '14px' }} onChange={(e) => AppendFiles(e, index, Childindex)} accept=".jpg,.jpeg,.png" ></input></td>
                                                        </tr>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-lg-12 col-sm-12">
                                                  <div className="row no-margin">
                                                    {
                                                      ChildInput.fileName !== '' ?
                                                        // manitem.Files.filter(x => x.IsDeleted === 0) &&
                                                        <> {
                                                          <div className="col-lg-8 col-sm-8 file_list_upload" style={{ width: "100%" }}>
                                                            <tr title={ChildInput.fileName}>
                                                              <td className="cut_textInTNA">
                                                                <span style={{ marginRight: '7px' }}>
                                                                  {
                                                                    ChildInput.fileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                      ChildInput.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                        <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                        ChildInput.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                          ChildInput.fileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                          <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                  }
                                                                </span>  {ChildInput.fileName.substr(33)}
                                                              </td>

                                                              <td className="button_style_uploda">


                                                                {
                                                                  ChildInput.FileType === "application/pdf" || ChildInput.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || ChildInput.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || ChildInput.fileType === 'application/msword' ?
                                                                    <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + 'Images/Report/' + ChildInput.fileName} download><i class="fa fa-download"></i> </a> :


                                                                    <i style={{ lineHeight: "20px" }} className="btn btn-info fa fa-eye" title="View Image"

                                                                      onClick={
                                                                        event => ViewFile(event, ChildInput.fileName)
                                                                      }></i>
                                                                }
                                                                &nbsp;
                                                                <p title='Delete File' onClick={(e) => RemoveImages(index, Childindex)}
                                                                  className="btn btn-danger btn-xs delete">
                                                                  <i className="fa fa-trash-o"></i>

                                                                </p>


                                                              </td>
                                                            </tr>



                                                            {

                                                              <div> {
                                                                isOpenimg && (

                                                                  <Lightbox mainSrc={window.$APIBaseURL + 'Images/Report/' + getImagename}
                                                                    alt="bg image"
                                                                    onCloseRequest={
                                                                      () => setIsOpenimg(false)
                                                                    } />
                                                                )
                                                              } </div>
                                                            }
                                                          </div>
                                                        }
                                                        </>
                                                        : ''

                                                    }
                                                  </div>
                                                </div>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>

                                        <td style={{ width: "13%" }}>
                                          {
                                            inputfeild.WarehouseDetails.length === (Childindex + 1) && (
                                              <span title='Add ItemMasterDetails Group'
                                                onClick={(event) => handleAddFields(event, index, Childindex)}
                                                className="btn btn-info btn-xs plus">
                                                <i className="fa fa-plus"></i>
                                              </span>
                                            )
                                          }
                                          &nbsp;
                                          {
                                            inputfeild.WarehouseDetails.length !== 1 && (
                                              <span title='Delete Workmanship Group'
                                                onClick={() => handleRemoveFields(index, Childindex)}
                                                className="btn btn-danger btn-xs delete">
                                                <i className="fa fa-trash-o"></i>
                                              </span>
                                            )
                                          }
                                        </td>
                                      </tr>
                                    ))

                                  }

                                </tbody>
                              </table>
                            }


                          </form>

                        </div>
                      </div>
                    </div>



                  </div>

                ))
              }

              <div className="col-sm-12">
                <div className="buttons-preview" align="right">
                  <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                    <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                  </span>
                  <span className="btn btn-danger" onClick={() => ResetOperation()}>
                    <i className="fa fa-times"></i>&nbsp;Reset
                  </span>
                  <button type="button" className="btn btn-success" disabled={buttonLoader}
                    onClick={SaveWorkmanGroup}
                  >
                    <i className="fa fa-check right"></i>&nbsp;Save
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BuyCusWarehouseAdd