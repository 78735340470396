import {
    CATEGORY_LOADING,
    RETRIEVE_CATEGORY,
    DELETE_CATEGORY,
} from "../actions/types";

const initialState = {
    isLoadingCategory: true,
    categoryList: [],
};

const categoryReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case CATEGORY_LOADING:
            return {
                ...state,
                isLoadingCategory: payload
            };
        case RETRIEVE_CATEGORY:
            return { ...state, categoryList: payload };
        case DELETE_CATEGORY:
            const results = state.categoryList.filter(c => c.categoryID !== payload.categoryID);
            return {
                ...state,
                categoryList: results
            };

        default:
            return state;
    }
};
export default categoryReducer;