import {
 AQLMASTER_LOADING,
 RETRIEVE_AQLMASTER,
 DELETE_AQLMASTER
} from "../actions/types";

const initialState = {

 isLoadingAQL: true,
 aqlList: [],
};

const AqlReducer = (state = initialState, action) => {
 const { type, payload } = action;
 switch (type) {
  case AQLMASTER_LOADING:
   return {
    ...state,
    isLoadingAQL: payload
   };
  // case CREATE_BRANCH:
  //  return { ...state, branches: payload };

  case DELETE_AQLMASTER:

   const results = state.aqlList.filter(c => c.SNo !== payload.SNo);
   return {
    ...state,
    aqlList: results
   };
  case RETRIEVE_AQLMASTER:
  ;
   return { ...state, aqlList: payload };


  default:
   return state;
 }

}

export default AqlReducer;